/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any; }
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any; }
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any; }
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any; }
  /** The `GeoJSON` scalar type represents GeoJSON values as specified by[RFC 7946](https://tools.ietf.org/html/rfc7946). */
  GeoJSON: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any; }
};

export type Action = Node & {
  /** Reads and enables pagination through a set of `ActionAssignee`. */
  actionAssignees: Array<ActionAssignee>;
  /** Reads and enables pagination through a set of `ActionStatusUpdate`. */
  actionStatusUpdates: Array<ActionStatusUpdate>;
  /** Reads and enables pagination through a set of `ActionStatusUpdate`. */
  actionStatusUpdatesConnection: ActionStatusUpdatesConnection;
  actionTemplateId: Maybe<Scalars['Int']['output']>;
  assignAllContacts: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedActionIdAndCallId: ActionCallsByFeedActionIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedActionIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedActionIdAndCommentId: ActionCommentsByFeedActionIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileActionIdAndCommentId: ActionCommentsByFileActionIdAndCommentIdManyToManyConnection;
  /** Reads a single `Company` that is related to this `Action`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedActionIdAndCreatedByContactId: ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedActionIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileActionIdAndCreatedByContactId: ActionContactsByFileActionIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileActionIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  /** Reads a single `MainUser` that is related to this `Action`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedActionIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedActionIdAndParentId: ActionFeedsByFeedActionIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedActionIdAndFileId: ActionFilesByFeedActionIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedActionIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileActionIdAndValueFileId: ActionFilesByFileActionIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileActionIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileActionIdAndFormId: ActionFormsByFileActionIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileActionIdAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  isTemplate: Scalars['Boolean']['output'];
  jobId: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedActionIdAndTaskId: ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `Action`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `ProjectStage` that is related to this `Action`. */
  projectStage: Maybe<ProjectStage>;
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileActionIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedActionIdAndProjectId: ActionProjectsByFeedActionIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedActionIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileActionIdAndProjectId: ActionProjectsByFileActionIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileActionIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedActionIdAndReminderId: ActionRemindersByFeedActionIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedActionIdAndReminderIdList: Array<Reminder>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedActionIdAndSmsActivityIdList: Array<SmsActivity>;
  status: ActionStatus;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedActionIdAndSystemId: ActionSystemsByFeedActionIdAndSystemIdManyToManyConnection;
  title: Maybe<Scalars['String']['output']>;
  type: ActionType;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedActionIdAndCreatedBy: ActionUsersByFeedActionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileActionIdAndCreatedBy: ActionUsersByFileActionIdAndCreatedByManyToManyConnection;
  uuid: Scalars['UUID']['output'];
};


export type ActionActionAssigneesArgs = {
  condition: InputMaybe<ActionAssigneeCondition>;
  filter: InputMaybe<ActionAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionAssigneesOrderBy>>;
};


export type ActionActionStatusUpdatesArgs = {
  condition: InputMaybe<ActionStatusUpdateCondition>;
  filter: InputMaybe<ActionStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionActionStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionStatusUpdateCondition>;
  filter: InputMaybe<ActionStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionCallsByFeedActionIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ActionCallsByFeedActionIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ActionCommentsByFeedActionIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ActionCommentsByFileActionIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ActionContactsByFeedActionIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ActionContactsByFeedActionIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ActionContactsByFileActionIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ActionContactsByFileActionIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ActionEmailMessagesByFeedActionIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type ActionFeedsByFeedActionIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ActionFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ActionFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFilesByFeedActionIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFilesByFeedActionIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFilesByFileActionIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFilesByFileActionIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ActionFormsByFileActionIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ActionFormsByFileActionIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ActionPrivilegedTasksByFeedActionIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ActionProjectStagesByFileActionIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ActionProjectsByFeedActionIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ActionProjectsByFeedActionIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ActionProjectsByFileActionIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ActionProjectsByFileActionIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ActionRemindersByFeedActionIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type ActionRemindersByFeedActionIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ActionSmsActivitiesByFeedActionIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ActionSystemsByFeedActionIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ActionUsersByFeedActionIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ActionUsersByFileActionIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type ActionAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ActionArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ActionAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ActionDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ActionMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ActionMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ActionStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ActionStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ActionSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ActionVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ActionVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Action` object types. */
export type ActionAggregatesFilter = {
  /** Array aggregation aggregate over matching `Action` objects. */
  arrayAgg: InputMaybe<ActionArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Action` objects. */
  average: InputMaybe<ActionAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Action` objects. */
  distinctCount: InputMaybe<ActionDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Action` object to be included within the aggregate. */
  filter: InputMaybe<ActionFilter>;
  /** Maximum aggregate over matching `Action` objects. */
  max: InputMaybe<ActionMaxAggregateFilter>;
  /** Minimum aggregate over matching `Action` objects. */
  min: InputMaybe<ActionMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Action` objects. */
  stddevPopulation: InputMaybe<ActionStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Action` objects. */
  stddevSample: InputMaybe<ActionStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Action` objects. */
  sum: InputMaybe<ActionSumAggregateFilter>;
  /** Population variance aggregate over matching `Action` objects. */
  variancePopulation: InputMaybe<ActionVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Action` objects. */
  varianceSample: InputMaybe<ActionVarianceSampleAggregateFilter>;
};

export type ActionArrayAggAggregateFilter = {
  actionTemplateId: InputMaybe<IntListFilter>;
  assignAllContacts: InputMaybe<BooleanListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isTemplate: InputMaybe<BooleanListFilter>;
  jobId: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
  projectStageId: InputMaybe<IntListFilter>;
  title: InputMaybe<StringListFilter>;
};

export type ActionArrayAggAggregates = {
  /** Array aggregation of actionTemplateId across the matching connection */
  actionTemplateId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of assignAllContacts across the matching connection */
  assignAllContacts: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isTemplate across the matching connection */
  isTemplate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jobId across the matching connection */
  jobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectStageId across the matching connection */
  projectStageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of title across the matching connection */
  title: Array<Maybe<Scalars['String']['output']>>;
};


export type ActionArrayAggAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesAssignAllContactsArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionArrayAggAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

export type ActionAssignee = Node & {
  /** Reads a single `Action` that is related to this `ActionAssignee`. */
  action: Maybe<Action>;
  actionId: Scalars['Int']['output'];
  /** Reads a single `Contact` that is related to this `ActionAssignee`. */
  contact: Maybe<Contact>;
  contactId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/** A filter to be used against aggregates of `ActionAssignee` object types. */
export type ActionAssigneeAggregatesFilter = {
  /** Array aggregation aggregate over matching `ActionAssignee` objects. */
  arrayAgg: InputMaybe<ActionAssigneeArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ActionAssignee` objects. */
  average: InputMaybe<ActionAssigneeAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ActionAssignee` objects. */
  distinctCount: InputMaybe<ActionAssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ActionAssignee` object to be included within the aggregate. */
  filter: InputMaybe<ActionAssigneeFilter>;
  /** Maximum aggregate over matching `ActionAssignee` objects. */
  max: InputMaybe<ActionAssigneeMaxAggregateFilter>;
  /** Minimum aggregate over matching `ActionAssignee` objects. */
  min: InputMaybe<ActionAssigneeMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ActionAssignee` objects. */
  stddevPopulation: InputMaybe<ActionAssigneeStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ActionAssignee` objects. */
  stddevSample: InputMaybe<ActionAssigneeStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ActionAssignee` objects. */
  sum: InputMaybe<ActionAssigneeSumAggregateFilter>;
  /** Population variance aggregate over matching `ActionAssignee` objects. */
  variancePopulation: InputMaybe<ActionAssigneeVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ActionAssignee` objects. */
  varianceSample: InputMaybe<ActionAssigneeVarianceSampleAggregateFilter>;
};

export type ActionAssigneeArrayAggAggregateFilter = {
  actionId: InputMaybe<IntListFilter>;
  contactId: InputMaybe<IntListFilter>;
};

export type ActionAssigneeAverageAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ActionAssignee` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ActionAssigneeCondition = {
  /** Checks for equality with the object’s `actionId` field. */
  actionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId: InputMaybe<Scalars['Int']['input']>;
};

export type ActionAssigneeDistinctCountAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  contactId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ActionAssignee` object types. All fields are combined with a logical ‘and.’ */
export type ActionAssigneeFilter = {
  /** Filter by the object’s `action` relation. */
  action: InputMaybe<ActionFilter>;
  /** Filter by the object’s `actionId` field. */
  actionId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ActionAssigneeFilter>>;
  /** Filter by the object’s `contact` relation. */
  contact: InputMaybe<ContactFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ActionAssigneeFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ActionAssigneeFilter>>;
};

export type ActionAssigneeMaxAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  contactId: InputMaybe<IntFilter>;
};

export type ActionAssigneeMinAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  contactId: InputMaybe<IntFilter>;
};

export type ActionAssigneeStddevPopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
};

export type ActionAssigneeStddevSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
};

export type ActionAssigneeSumAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  contactId: InputMaybe<BigIntFilter>;
};

export type ActionAssigneeVariancePopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
};

export type ActionAssigneeVarianceSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ActionAssignee`. */
export enum ActionAssigneesOrderBy {
  ActionIdAsc = 'ACTION_ID_ASC',
  ActionIdDesc = 'ACTION_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ActionAverageAggregateFilter = {
  actionTemplateId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type ActionAverageAggregates = {
  /** Mean average of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionAverageAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionAverageAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type ActionCallsByFeedActionIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionCallsByFeedActionIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type ActionCallsByFeedActionIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type ActionCallsByFeedActionIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type ActionCallsByFeedActionIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ActionCommentsByFeedActionIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionCommentsByFeedActionIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ActionCommentsByFeedActionIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type ActionCommentsByFeedActionIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type ActionCommentsByFeedActionIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type ActionCommentsByFileActionIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionCommentsByFileActionIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type ActionCommentsByFileActionIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type ActionCommentsByFileActionIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ActionCommentsByFileActionIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ActionCommentsByFileActionIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `Action` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ActionCondition = {
  /** Checks for equality with the object’s `actionTemplateId` field. */
  actionTemplateId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `assignAllContacts` field. */
  assignAllContacts: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectStageId` field. */
  projectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ActionStatus>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<ActionType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type ActionContactsByFeedActionIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type ActionContactsByFileActionIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionContactsByFileActionIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type ActionContactsByFileActionIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type ActionContactsByFileActionIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ActionContactsByFileActionIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ActionContactsByFileActionIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ActionDistinctCountAggregateFilter = {
  actionTemplateId: InputMaybe<BigIntFilter>;
  assignAllContacts: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completedAt: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  dueDate: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isTemplate: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
};

export type ActionDistinctCountAggregates = {
  /** Distinct count of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of assignAllContacts across the matching connection */
  assignAllContacts: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completedAt across the matching connection */
  completedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dueDate across the matching connection */
  dueDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
};


export type ActionDistinctCountAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesAssignAllContactsArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ActionFeedsByFeedActionIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionFeedsByFeedActionIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ActionFeedsByFeedActionIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type ActionFeedsByFeedActionIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type ActionFeedsByFeedActionIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type ActionFilesByFeedActionIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionFilesByFeedActionIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type ActionFilesByFeedActionIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type ActionFilesByFeedActionIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type ActionFilesByFeedActionIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type ActionFilesByFileActionIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionFilesByFileActionIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type ActionFilesByFileActionIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type ActionFilesByFileActionIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ActionFilesByFileActionIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ActionFilesByFileActionIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `Action` object types. All fields are combined with a logical ‘and.’ */
export type ActionFilter = {
  /** Filter by the object’s `actionAssignees` relation. */
  actionAssignees: InputMaybe<ActionToManyActionAssigneeFilter>;
  /** Some related `actionAssignees` exist. */
  actionAssigneesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `actionStatusUpdates` relation. */
  actionStatusUpdates: InputMaybe<ActionToManyActionStatusUpdateFilter>;
  /** Some related `actionStatusUpdates` exist. */
  actionStatusUpdatesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `actionTemplateId` field. */
  actionTemplateId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ActionFilter>>;
  /** Filter by the object’s `assignAllContacts` field. */
  assignAllContacts: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feeds` relation. */
  feeds: InputMaybe<ActionToManyFeedFilter>;
  /** Some related `feeds` exist. */
  feedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `files` relation. */
  files: InputMaybe<ActionToManyFileFilter>;
  /** Some related `files` exist. */
  filesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ActionFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ActionFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectStage` relation. */
  projectStage: InputMaybe<ProjectStageFilter>;
  /** A related `projectStage` exists. */
  projectStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageId` field. */
  projectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ActionStatusFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<ActionTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type ActionFormsByFileActionIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionFormsByFileActionIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type ActionFormsByFileActionIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type ActionFormsByFileActionIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ActionFormsByFileActionIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ActionFormsByFileActionIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Grouping methods for `Action` for usage during aggregation. */
export enum ActionGroupBy {
  ActionTemplateId = 'ACTION_TEMPLATE_ID',
  AssignAllContacts = 'ASSIGN_ALL_CONTACTS',
  CompanyId = 'COMPANY_ID',
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CompletedAtTruncatedToMonth = 'COMPLETED_AT_TRUNCATED_TO_MONTH',
  CompletedAtTruncatedToQuarter = 'COMPLETED_AT_TRUNCATED_TO_QUARTER',
  CompletedAtTruncatedToWeek = 'COMPLETED_AT_TRUNCATED_TO_WEEK',
  CompletedAtTruncatedToYear = 'COMPLETED_AT_TRUNCATED_TO_YEAR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  DueDate = 'DUE_DATE',
  DueDateTruncatedToDay = 'DUE_DATE_TRUNCATED_TO_DAY',
  DueDateTruncatedToHour = 'DUE_DATE_TRUNCATED_TO_HOUR',
  DueDateTruncatedToMonth = 'DUE_DATE_TRUNCATED_TO_MONTH',
  DueDateTruncatedToQuarter = 'DUE_DATE_TRUNCATED_TO_QUARTER',
  DueDateTruncatedToWeek = 'DUE_DATE_TRUNCATED_TO_WEEK',
  DueDateTruncatedToYear = 'DUE_DATE_TRUNCATED_TO_YEAR',
  IsTemplate = 'IS_TEMPLATE',
  JobId = 'JOB_ID',
  ProjectId = 'PROJECT_ID',
  ProjectStageId = 'PROJECT_STAGE_ID',
  Status = 'STATUS',
  Title = 'TITLE',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ActionHavingArrayAggInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingAverageInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingDistinctCountInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Action` aggregates. */
export type ActionHavingInput = {
  AND: InputMaybe<Array<ActionHavingInput>>;
  OR: InputMaybe<Array<ActionHavingInput>>;
  arrayAgg: InputMaybe<ActionHavingArrayAggInput>;
  average: InputMaybe<ActionHavingAverageInput>;
  distinctCount: InputMaybe<ActionHavingDistinctCountInput>;
  max: InputMaybe<ActionHavingMaxInput>;
  min: InputMaybe<ActionHavingMinInput>;
  stddevPopulation: InputMaybe<ActionHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ActionHavingStddevSampleInput>;
  sum: InputMaybe<ActionHavingSumInput>;
  variancePopulation: InputMaybe<ActionHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ActionHavingVarianceSampleInput>;
};

export type ActionHavingMaxInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingMinInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingStddevPopulationInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingStddevSampleInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingSumInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingVariancePopulationInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionHavingVarianceSampleInput = {
  actionTemplateId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ActionMaxAggregateFilter = {
  actionTemplateId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ActionMaxAggregates = {
  /** Maximum of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ActionMaxAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

export type ActionMinAggregateFilter = {
  actionTemplateId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ActionMinAggregates = {
  /** Minimum of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ActionMinAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ActionPrivilegedTasksByFeedActionIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type ActionProjectsByFeedActionIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionProjectsByFeedActionIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type ActionProjectsByFeedActionIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type ActionProjectsByFeedActionIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type ActionProjectsByFeedActionIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type ActionProjectsByFileActionIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionProjectsByFileActionIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type ActionProjectsByFileActionIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type ActionProjectsByFileActionIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ActionProjectsByFileActionIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ActionProjectsByFileActionIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ActionRemindersByFeedActionIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionRemindersByFeedActionIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ActionRemindersByFeedActionIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ActionRemindersByFeedActionIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ActionRemindersByFeedActionIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export enum ActionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InReview = 'IN_REVIEW',
  Requested = 'REQUESTED'
}

/** A filter to be used against ActionStatus fields. All fields are combined with a logical ‘and.’ */
export type ActionStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ActionStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ActionStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ActionStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ActionStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ActionStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ActionStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ActionStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ActionStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ActionStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ActionStatus>>;
};

export type ActionStatusUpdate = Node & {
  /** Reads a single `Action` that is related to this `ActionStatusUpdate`. */
  action: Maybe<Action>;
  actionId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: ActionStatus;
};

export type ActionStatusUpdateAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ActionStatusUpdateArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ActionStatusUpdateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ActionStatusUpdateDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ActionStatusUpdateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ActionStatusUpdateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ActionStatusUpdateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ActionStatusUpdateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ActionStatusUpdateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ActionStatusUpdateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ActionStatusUpdateVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ActionStatusUpdate` object types. */
export type ActionStatusUpdateAggregatesFilter = {
  /** Array aggregation aggregate over matching `ActionStatusUpdate` objects. */
  arrayAgg: InputMaybe<ActionStatusUpdateArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ActionStatusUpdate` objects. */
  average: InputMaybe<ActionStatusUpdateAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ActionStatusUpdate` objects. */
  distinctCount: InputMaybe<ActionStatusUpdateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ActionStatusUpdate` object to be included within the aggregate. */
  filter: InputMaybe<ActionStatusUpdateFilter>;
  /** Maximum aggregate over matching `ActionStatusUpdate` objects. */
  max: InputMaybe<ActionStatusUpdateMaxAggregateFilter>;
  /** Minimum aggregate over matching `ActionStatusUpdate` objects. */
  min: InputMaybe<ActionStatusUpdateMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ActionStatusUpdate` objects. */
  stddevPopulation: InputMaybe<ActionStatusUpdateStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ActionStatusUpdate` objects. */
  stddevSample: InputMaybe<ActionStatusUpdateStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ActionStatusUpdate` objects. */
  sum: InputMaybe<ActionStatusUpdateSumAggregateFilter>;
  /** Population variance aggregate over matching `ActionStatusUpdate` objects. */
  variancePopulation: InputMaybe<ActionStatusUpdateVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ActionStatusUpdate` objects. */
  varianceSample: InputMaybe<ActionStatusUpdateVarianceSampleAggregateFilter>;
};

export type ActionStatusUpdateArrayAggAggregateFilter = {
  actionId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type ActionStatusUpdateArrayAggAggregates = {
  /** Array aggregation of actionId across the matching connection */
  actionId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
};


export type ActionStatusUpdateArrayAggAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateAverageAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ActionStatusUpdateAverageAggregates = {
  /** Mean average of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStatusUpdateAverageAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

/**
 * A condition to be used against `ActionStatusUpdate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ActionStatusUpdateCondition = {
  /** Checks for equality with the object’s `actionId` field. */
  actionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ActionStatus>;
};

export type ActionStatusUpdateDistinctCountAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
};

export type ActionStatusUpdateDistinctCountAggregates = {
  /** Distinct count of actionId across the matching connection */
  actionId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
};


export type ActionStatusUpdateDistinctCountAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

/** A filter to be used against `ActionStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ActionStatusUpdateFilter = {
  /** Filter by the object’s `action` relation. */
  action: InputMaybe<ActionFilter>;
  /** Filter by the object’s `actionId` field. */
  actionId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ActionStatusUpdateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ActionStatusUpdateFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ActionStatusUpdateFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ActionStatusFilter>;
};

/** Grouping methods for `ActionStatusUpdate` for usage during aggregation. */
export enum ActionStatusUpdateGroupBy {
  ActionId = 'ACTION_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Status = 'STATUS'
}

export type ActionStatusUpdateHavingArrayAggInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingAverageInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingDistinctCountInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ActionStatusUpdate` aggregates. */
export type ActionStatusUpdateHavingInput = {
  AND: InputMaybe<Array<ActionStatusUpdateHavingInput>>;
  OR: InputMaybe<Array<ActionStatusUpdateHavingInput>>;
  arrayAgg: InputMaybe<ActionStatusUpdateHavingArrayAggInput>;
  average: InputMaybe<ActionStatusUpdateHavingAverageInput>;
  distinctCount: InputMaybe<ActionStatusUpdateHavingDistinctCountInput>;
  max: InputMaybe<ActionStatusUpdateHavingMaxInput>;
  min: InputMaybe<ActionStatusUpdateHavingMinInput>;
  stddevPopulation: InputMaybe<ActionStatusUpdateHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ActionStatusUpdateHavingStddevSampleInput>;
  sum: InputMaybe<ActionStatusUpdateHavingSumInput>;
  variancePopulation: InputMaybe<ActionStatusUpdateHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ActionStatusUpdateHavingVarianceSampleInput>;
};

export type ActionStatusUpdateHavingMaxInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingMinInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingStddevPopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingStddevSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingSumInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingVariancePopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateHavingVarianceSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
};

export type ActionStatusUpdateMaxAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
};

export type ActionStatusUpdateMaxAggregates = {
  /** Maximum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
};


export type ActionStatusUpdateMaxAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateMinAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
};

export type ActionStatusUpdateMinAggregates = {
  /** Minimum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
};


export type ActionStatusUpdateMinAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateStddevPopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ActionStatusUpdateStddevPopulationAggregates = {
  /** Population standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStatusUpdateStddevPopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateStddevSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ActionStatusUpdateStddevSampleAggregates = {
  /** Sample standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStatusUpdateStddevSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateSumAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type ActionStatusUpdateSumAggregates = {
  /** Sum of actionId across the matching connection */
  actionId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type ActionStatusUpdateSumAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateVariancePopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ActionStatusUpdateVariancePopulationAggregates = {
  /** Population variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStatusUpdateVariancePopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

export type ActionStatusUpdateVarianceSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ActionStatusUpdateVarianceSampleAggregates = {
  /** Sample variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStatusUpdateVarianceSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


export type ActionStatusUpdateVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};

/** A connection to a list of `ActionStatusUpdate` values. */
export type ActionStatusUpdatesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionStatusUpdateAggregates>;
  /** A list of edges which contains the `ActionStatusUpdate` and cursor to aid in pagination. */
  edges: Array<ActionStatusUpdatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionStatusUpdateAggregates>>;
  /** A list of `ActionStatusUpdate` objects. */
  nodes: Array<Maybe<ActionStatusUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActionStatusUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ActionStatusUpdate` values. */
export type ActionStatusUpdatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionStatusUpdateGroupBy>;
  having: InputMaybe<ActionStatusUpdateHavingInput>;
};

/** A `ActionStatusUpdate` edge in the connection. */
export type ActionStatusUpdatesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ActionStatusUpdate` at the end of the edge. */
  node: Maybe<ActionStatusUpdate>;
};

/** Methods to use when ordering `ActionStatusUpdate`. */
export enum ActionStatusUpdatesOrderBy {
  ActionIdAsc = 'ACTION_ID_ASC',
  ActionIdDesc = 'ACTION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export type ActionStddevPopulationAggregateFilter = {
  actionTemplateId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type ActionStddevPopulationAggregates = {
  /** Population standard deviation of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStddevPopulationAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevPopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

export type ActionStddevSampleAggregateFilter = {
  actionTemplateId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type ActionStddevSampleAggregates = {
  /** Sample standard deviation of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionStddevSampleAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionStddevSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

export type ActionSumAggregateFilter = {
  actionTemplateId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
};

export type ActionSumAggregates = {
  /** Sum of actionTemplateId across the matching connection */
  actionTemplateId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of projectStageId across the matching connection */
  projectStageId: Scalars['BigInt']['output'];
};


export type ActionSumAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionSumAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type ActionSystemsByFeedActionIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionSystemsByFeedActionIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type ActionSystemsByFeedActionIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type ActionSystemsByFeedActionIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type ActionSystemsByFeedActionIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `ActionAssignee` object types. All fields are combined with a logical ‘and.’ */
export type ActionToManyActionAssigneeFilter = {
  /** Aggregates across related `ActionAssignee` match the filter criteria. */
  aggregates: InputMaybe<ActionAssigneeAggregatesFilter>;
  /** Every related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionAssigneeFilter>;
  /** No related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionAssigneeFilter>;
  /** Some related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionAssigneeFilter>;
};

/** A filter to be used against many `ActionStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ActionToManyActionStatusUpdateFilter = {
  /** Aggregates across related `ActionStatusUpdate` match the filter criteria. */
  aggregates: InputMaybe<ActionStatusUpdateAggregatesFilter>;
  /** Every related `ActionStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionStatusUpdateFilter>;
  /** No related `ActionStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionStatusUpdateFilter>;
  /** Some related `ActionStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionStatusUpdateFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type ActionToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ActionToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

export enum ActionType {
  DocUpload = 'DOC_UPLOAD'
}

/** A filter to be used against ActionType fields. All fields are combined with a logical ‘and.’ */
export type ActionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ActionType>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ActionType>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ActionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ActionType>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ActionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ActionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ActionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ActionType>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ActionType>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ActionType>>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type ActionUsersByFeedActionIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ActionUsersByFeedActionIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type ActionUsersByFeedActionIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type ActionUsersByFeedActionIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type ActionUsersByFeedActionIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type ActionUsersByFileActionIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ActionUsersByFileActionIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type ActionUsersByFileActionIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type ActionUsersByFileActionIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ActionUsersByFileActionIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ActionUsersByFileActionIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ActionVariancePopulationAggregateFilter = {
  actionTemplateId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type ActionVariancePopulationAggregates = {
  /** Population variance of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionVariancePopulationAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVariancePopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

export type ActionVarianceSampleAggregateFilter = {
  actionTemplateId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type ActionVarianceSampleAggregates = {
  /** Sample variance of actionTemplateId across the matching connection */
  actionTemplateId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ActionVarianceSampleAggregatesActionTemplateIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ActionVarianceSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};

/** A connection to a list of `Action` values. */
export type ActionsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action` and cursor to aid in pagination. */
  edges: Array<ActionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values. */
export type ActionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection. */
export type ActionsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};

/** Methods to use when ordering `Action`. */
export enum ActionsOrderBy {
  ActionAssigneesConnectionArrayAggActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  ActionAssigneesConnectionArrayAggActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  ActionAssigneesConnectionArrayAggContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ActionAssigneesConnectionArrayAggContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ActionAssigneesConnectionAverageActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  ActionAssigneesConnectionAverageActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  ActionAssigneesConnectionAverageContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ActionAssigneesConnectionAverageContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ActionAssigneesConnectionCountAsc = 'ACTION_ASSIGNEES_CONNECTION_COUNT_ASC',
  ActionAssigneesConnectionCountDesc = 'ACTION_ASSIGNEES_CONNECTION_COUNT_DESC',
  ActionAssigneesConnectionDistinctCountActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  ActionAssigneesConnectionDistinctCountActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  ActionAssigneesConnectionDistinctCountContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ActionAssigneesConnectionDistinctCountContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ActionAssigneesConnectionMaxActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MAX_ACTION_ID_ASC',
  ActionAssigneesConnectionMaxActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MAX_ACTION_ID_DESC',
  ActionAssigneesConnectionMaxContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MAX_CONTACT_ID_ASC',
  ActionAssigneesConnectionMaxContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MAX_CONTACT_ID_DESC',
  ActionAssigneesConnectionMinActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MIN_ACTION_ID_ASC',
  ActionAssigneesConnectionMinActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MIN_ACTION_ID_DESC',
  ActionAssigneesConnectionMinContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MIN_CONTACT_ID_ASC',
  ActionAssigneesConnectionMinContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MIN_CONTACT_ID_DESC',
  ActionAssigneesConnectionStddevPopulationActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  ActionAssigneesConnectionStddevPopulationActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  ActionAssigneesConnectionStddevPopulationContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ActionAssigneesConnectionStddevPopulationContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ActionAssigneesConnectionStddevSampleActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  ActionAssigneesConnectionStddevSampleActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  ActionAssigneesConnectionStddevSampleContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ActionAssigneesConnectionStddevSampleContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ActionAssigneesConnectionSumActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_SUM_ACTION_ID_ASC',
  ActionAssigneesConnectionSumActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_SUM_ACTION_ID_DESC',
  ActionAssigneesConnectionSumContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_SUM_CONTACT_ID_ASC',
  ActionAssigneesConnectionSumContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_SUM_CONTACT_ID_DESC',
  ActionAssigneesConnectionVariancePopulationActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  ActionAssigneesConnectionVariancePopulationActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  ActionAssigneesConnectionVariancePopulationContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ActionAssigneesConnectionVariancePopulationContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ActionAssigneesConnectionVarianceSampleActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  ActionAssigneesConnectionVarianceSampleActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  ActionAssigneesConnectionVarianceSampleContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ActionAssigneesConnectionVarianceSampleContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ActionStatusUpdatesConnectionArrayAggActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionArrayAggActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionArrayAggCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionArrayAggCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionArrayAggIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_ASC',
  ActionStatusUpdatesConnectionArrayAggIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_DESC',
  ActionStatusUpdatesConnectionArrayAggStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ActionStatusUpdatesConnectionArrayAggStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ActionStatusUpdatesConnectionAverageActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionAverageActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionAverageCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionAverageCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionAverageIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_ID_ASC',
  ActionStatusUpdatesConnectionAverageIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_ID_DESC',
  ActionStatusUpdatesConnectionAverageStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_ASC',
  ActionStatusUpdatesConnectionAverageStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_DESC',
  ActionStatusUpdatesConnectionCountAsc = 'ACTION_STATUS_UPDATES_CONNECTION_COUNT_ASC',
  ActionStatusUpdatesConnectionCountDesc = 'ACTION_STATUS_UPDATES_CONNECTION_COUNT_DESC',
  ActionStatusUpdatesConnectionDistinctCountActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionDistinctCountActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionDistinctCountCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionDistinctCountCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionDistinctCountIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ActionStatusUpdatesConnectionDistinctCountIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ActionStatusUpdatesConnectionDistinctCountStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ActionStatusUpdatesConnectionDistinctCountStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ActionStatusUpdatesConnectionMaxActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionMaxActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionMaxCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionMaxCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionMaxIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_ID_ASC',
  ActionStatusUpdatesConnectionMaxIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_ID_DESC',
  ActionStatusUpdatesConnectionMaxStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_STATUS_ASC',
  ActionStatusUpdatesConnectionMaxStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MAX_STATUS_DESC',
  ActionStatusUpdatesConnectionMinActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionMinActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionMinCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionMinCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionMinIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_ID_ASC',
  ActionStatusUpdatesConnectionMinIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_ID_DESC',
  ActionStatusUpdatesConnectionMinStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_STATUS_ASC',
  ActionStatusUpdatesConnectionMinStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_MIN_STATUS_DESC',
  ActionStatusUpdatesConnectionStddevPopulationActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionStddevPopulationActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionStddevPopulationCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionStddevPopulationCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionStddevPopulationIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ActionStatusUpdatesConnectionStddevPopulationIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ActionStatusUpdatesConnectionStddevPopulationStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ActionStatusUpdatesConnectionStddevPopulationStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ActionStatusUpdatesConnectionStddevSampleActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionStddevSampleActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionStddevSampleCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionStddevSampleCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionStddevSampleIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ActionStatusUpdatesConnectionStddevSampleIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ActionStatusUpdatesConnectionStddevSampleStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ActionStatusUpdatesConnectionStddevSampleStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ActionStatusUpdatesConnectionSumActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionSumActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionSumCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionSumCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionSumIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_ID_ASC',
  ActionStatusUpdatesConnectionSumIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_ID_DESC',
  ActionStatusUpdatesConnectionSumStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_STATUS_ASC',
  ActionStatusUpdatesConnectionSumStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_SUM_STATUS_DESC',
  ActionStatusUpdatesConnectionVariancePopulationActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionVariancePopulationActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionVariancePopulationCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionVariancePopulationCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionVariancePopulationIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ActionStatusUpdatesConnectionVariancePopulationIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ActionStatusUpdatesConnectionVariancePopulationStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ActionStatusUpdatesConnectionVariancePopulationStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ActionStatusUpdatesConnectionVarianceSampleActionIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  ActionStatusUpdatesConnectionVarianceSampleActionIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  ActionStatusUpdatesConnectionVarianceSampleCreatedAtAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ActionStatusUpdatesConnectionVarianceSampleCreatedAtDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ActionStatusUpdatesConnectionVarianceSampleIdAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ActionStatusUpdatesConnectionVarianceSampleIdDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ActionStatusUpdatesConnectionVarianceSampleStatusAsc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ActionStatusUpdatesConnectionVarianceSampleStatusDesc = 'ACTION_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ActionTemplateIdAsc = 'ACTION_TEMPLATE_ID_ASC',
  ActionTemplateIdDesc = 'ACTION_TEMPLATE_ID_DESC',
  AssignAllContactsAsc = 'ASSIGN_ALL_CONTACTS_ASC',
  AssignAllContactsDesc = 'ASSIGN_ALL_CONTACTS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DueDateAsc = 'DUE_DATE_ASC',
  DueDateDesc = 'DUE_DATE_DESC',
  FeedsConnectionArrayAggActionIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsConnectionArrayAggActionIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsConnectionArrayAggCallIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsConnectionArrayAggCallIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsConnectionArrayAggCommentAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsConnectionArrayAggCommentDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsConnectionArrayAggCommentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsConnectionArrayAggCommentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsConnectionArrayAggCompanyIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsConnectionArrayAggCompanyIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsConnectionArrayAggCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsConnectionArrayAggCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsConnectionArrayAggCreatedByAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionArrayAggCreatedByDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsConnectionArrayAggCreatedByTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsConnectionArrayAggCreatedByTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggEmailMessageIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionArrayAggEmailMessageIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionArrayAggEventAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsConnectionArrayAggEventDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsConnectionArrayAggEventTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsConnectionArrayAggEventTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsConnectionArrayAggFileIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsConnectionArrayAggFileIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsConnectionArrayAggIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsConnectionArrayAggIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsConnectionArrayAggParentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsConnectionArrayAggParentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsConnectionArrayAggPayloadAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsConnectionArrayAggPayloadDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsConnectionArrayAggProjectIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsConnectionArrayAggProjectIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsConnectionArrayAggReminderIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsConnectionArrayAggReminderIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsConnectionArrayAggRetriedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsConnectionArrayAggRetriedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsConnectionArrayAggRetryableAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsConnectionArrayAggRetryableDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsConnectionArrayAggRetryCountAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsConnectionArrayAggRetryCountDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsConnectionArrayAggSearchTextAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsConnectionArrayAggSearchTextDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsConnectionArrayAggSmsActivityIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionArrayAggSmsActivityIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionArrayAggSystemIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsConnectionArrayAggSystemIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsConnectionArrayAggTaskIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsConnectionArrayAggTaskIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionArrayAggWorkflowIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsConnectionArrayAggWorkflowIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsConnectionAverageActionIdAsc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsConnectionAverageActionIdDesc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsConnectionAverageCallIdAsc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsConnectionAverageCallIdDesc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsConnectionAverageCommentAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsConnectionAverageCommentDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsConnectionAverageCommentIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsConnectionAverageCommentIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsConnectionAverageCompanyIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsConnectionAverageCompanyIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsConnectionAverageCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsConnectionAverageCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsConnectionAverageCreatedByAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsConnectionAverageCreatedByContactIdAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionAverageCreatedByContactIdDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionAverageCreatedByDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsConnectionAverageCreatedByTypeAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsConnectionAverageCreatedByTypeDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsConnectionAverageEmailMessageIdAsc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionAverageEmailMessageIdDesc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionAverageEventAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsConnectionAverageEventDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsConnectionAverageEventTypeAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsConnectionAverageEventTypeDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsConnectionAverageFileIdAsc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsConnectionAverageFileIdDesc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsConnectionAverageIdAsc = 'FEEDS_CONNECTION_AVERAGE_ID_ASC',
  FeedsConnectionAverageIdDesc = 'FEEDS_CONNECTION_AVERAGE_ID_DESC',
  FeedsConnectionAverageParentIdAsc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsConnectionAverageParentIdDesc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsConnectionAveragePayloadAsc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsConnectionAveragePayloadDesc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsConnectionAverageProjectIdAsc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsConnectionAverageProjectIdDesc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsConnectionAverageReminderIdAsc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsConnectionAverageReminderIdDesc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsConnectionAverageRetriedAtAsc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsConnectionAverageRetriedAtDesc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsConnectionAverageRetryableAsc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsConnectionAverageRetryableDesc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsConnectionAverageRetryCountAsc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsConnectionAverageRetryCountDesc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsConnectionAverageSearchTextAsc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsConnectionAverageSearchTextDesc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsConnectionAverageSmsActivityIdAsc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionAverageSmsActivityIdDesc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionAverageSystemIdAsc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsConnectionAverageSystemIdDesc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsConnectionAverageTaskIdAsc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsConnectionAverageTaskIdDesc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsConnectionAverageVirtualCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionAverageVirtualCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionAverageWorkflowIdAsc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsConnectionAverageWorkflowIdDesc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsConnectionCountAsc = 'FEEDS_CONNECTION_COUNT_ASC',
  FeedsConnectionCountDesc = 'FEEDS_CONNECTION_COUNT_DESC',
  FeedsConnectionDistinctCountActionIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsConnectionDistinctCountActionIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsConnectionDistinctCountCallIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsConnectionDistinctCountCallIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsConnectionDistinctCountCommentAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsConnectionDistinctCountCommentDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsConnectionDistinctCountCommentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsConnectionDistinctCountCommentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsConnectionDistinctCountCompanyIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsConnectionDistinctCountCompanyIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsConnectionDistinctCountCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsConnectionDistinctCountCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsConnectionDistinctCountCreatedByAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionDistinctCountCreatedByDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionDistinctCountEventAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsConnectionDistinctCountEventDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsConnectionDistinctCountEventTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsConnectionDistinctCountEventTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsConnectionDistinctCountFileIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsConnectionDistinctCountFileIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsConnectionDistinctCountIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsConnectionDistinctCountIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsConnectionDistinctCountParentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsConnectionDistinctCountParentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsConnectionDistinctCountPayloadAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsConnectionDistinctCountPayloadDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsConnectionDistinctCountProjectIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsConnectionDistinctCountProjectIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsConnectionDistinctCountReminderIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsConnectionDistinctCountReminderIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsConnectionDistinctCountRetriedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsConnectionDistinctCountRetriedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsConnectionDistinctCountRetryableAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsConnectionDistinctCountRetryableDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsConnectionDistinctCountRetryCountAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsConnectionDistinctCountRetryCountDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsConnectionDistinctCountSearchTextAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsConnectionDistinctCountSearchTextDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionDistinctCountSystemIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsConnectionDistinctCountSystemIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsConnectionDistinctCountTaskIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsConnectionDistinctCountTaskIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionDistinctCountWorkflowIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsConnectionDistinctCountWorkflowIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsConnectionMaxActionIdAsc = 'FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsConnectionMaxActionIdDesc = 'FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsConnectionMaxCallIdAsc = 'FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  FeedsConnectionMaxCallIdDesc = 'FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  FeedsConnectionMaxCommentAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ASC',
  FeedsConnectionMaxCommentDesc = 'FEEDS_CONNECTION_MAX_COMMENT_DESC',
  FeedsConnectionMaxCommentIdAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsConnectionMaxCommentIdDesc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsConnectionMaxCompanyIdAsc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsConnectionMaxCompanyIdDesc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsConnectionMaxCreatedAtAsc = 'FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsConnectionMaxCreatedAtDesc = 'FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsConnectionMaxCreatedByAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsConnectionMaxCreatedByContactIdAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMaxCreatedByContactIdDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMaxCreatedByDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsConnectionMaxCreatedByTypeAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsConnectionMaxCreatedByTypeDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsConnectionMaxEmailMessageIdAsc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMaxEmailMessageIdDesc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMaxEventAsc = 'FEEDS_CONNECTION_MAX_EVENT_ASC',
  FeedsConnectionMaxEventDesc = 'FEEDS_CONNECTION_MAX_EVENT_DESC',
  FeedsConnectionMaxEventTypeAsc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsConnectionMaxEventTypeDesc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsConnectionMaxFileIdAsc = 'FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  FeedsConnectionMaxFileIdDesc = 'FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  FeedsConnectionMaxIdAsc = 'FEEDS_CONNECTION_MAX_ID_ASC',
  FeedsConnectionMaxIdDesc = 'FEEDS_CONNECTION_MAX_ID_DESC',
  FeedsConnectionMaxParentIdAsc = 'FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsConnectionMaxParentIdDesc = 'FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsConnectionMaxPayloadAsc = 'FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsConnectionMaxPayloadDesc = 'FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsConnectionMaxProjectIdAsc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsConnectionMaxProjectIdDesc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsConnectionMaxReminderIdAsc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsConnectionMaxReminderIdDesc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsConnectionMaxRetriedAtAsc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsConnectionMaxRetriedAtDesc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsConnectionMaxRetryableAsc = 'FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsConnectionMaxRetryableDesc = 'FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsConnectionMaxRetryCountAsc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsConnectionMaxRetryCountDesc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsConnectionMaxSearchTextAsc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsConnectionMaxSearchTextDesc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsConnectionMaxSmsActivityIdAsc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMaxSmsActivityIdDesc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMaxSystemIdAsc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsConnectionMaxSystemIdDesc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsConnectionMaxTaskIdAsc = 'FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  FeedsConnectionMaxTaskIdDesc = 'FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  FeedsConnectionMaxVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMaxVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMaxWorkflowIdAsc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsConnectionMaxWorkflowIdDesc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsConnectionMinActionIdAsc = 'FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsConnectionMinActionIdDesc = 'FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsConnectionMinCallIdAsc = 'FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  FeedsConnectionMinCallIdDesc = 'FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  FeedsConnectionMinCommentAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ASC',
  FeedsConnectionMinCommentDesc = 'FEEDS_CONNECTION_MIN_COMMENT_DESC',
  FeedsConnectionMinCommentIdAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsConnectionMinCommentIdDesc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsConnectionMinCompanyIdAsc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsConnectionMinCompanyIdDesc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsConnectionMinCreatedAtAsc = 'FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsConnectionMinCreatedAtDesc = 'FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsConnectionMinCreatedByAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsConnectionMinCreatedByContactIdAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMinCreatedByContactIdDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMinCreatedByDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsConnectionMinCreatedByTypeAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsConnectionMinCreatedByTypeDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsConnectionMinEmailMessageIdAsc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMinEmailMessageIdDesc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMinEventAsc = 'FEEDS_CONNECTION_MIN_EVENT_ASC',
  FeedsConnectionMinEventDesc = 'FEEDS_CONNECTION_MIN_EVENT_DESC',
  FeedsConnectionMinEventTypeAsc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsConnectionMinEventTypeDesc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsConnectionMinFileIdAsc = 'FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  FeedsConnectionMinFileIdDesc = 'FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  FeedsConnectionMinIdAsc = 'FEEDS_CONNECTION_MIN_ID_ASC',
  FeedsConnectionMinIdDesc = 'FEEDS_CONNECTION_MIN_ID_DESC',
  FeedsConnectionMinParentIdAsc = 'FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsConnectionMinParentIdDesc = 'FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsConnectionMinPayloadAsc = 'FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsConnectionMinPayloadDesc = 'FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsConnectionMinProjectIdAsc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsConnectionMinProjectIdDesc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsConnectionMinReminderIdAsc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsConnectionMinReminderIdDesc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsConnectionMinRetriedAtAsc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsConnectionMinRetriedAtDesc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsConnectionMinRetryableAsc = 'FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsConnectionMinRetryableDesc = 'FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsConnectionMinRetryCountAsc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsConnectionMinRetryCountDesc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsConnectionMinSearchTextAsc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsConnectionMinSearchTextDesc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsConnectionMinSmsActivityIdAsc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMinSmsActivityIdDesc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMinSystemIdAsc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsConnectionMinSystemIdDesc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsConnectionMinTaskIdAsc = 'FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  FeedsConnectionMinTaskIdDesc = 'FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  FeedsConnectionMinVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMinVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMinWorkflowIdAsc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsConnectionMinWorkflowIdDesc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsConnectionStddevPopulationActionIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsConnectionStddevPopulationActionIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsConnectionStddevPopulationCallIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsConnectionStddevPopulationCallIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsConnectionStddevPopulationCommentAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsConnectionStddevPopulationCommentDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsConnectionStddevPopulationCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionStddevPopulationCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionStddevPopulationCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionStddevPopulationCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionStddevPopulationCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevPopulationCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevPopulationEventAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsConnectionStddevPopulationEventDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsConnectionStddevPopulationEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionStddevPopulationEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionStddevPopulationFileIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsConnectionStddevPopulationFileIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsConnectionStddevPopulationIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsConnectionStddevPopulationIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsConnectionStddevPopulationParentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsConnectionStddevPopulationParentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsConnectionStddevPopulationPayloadAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsConnectionStddevPopulationPayloadDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsConnectionStddevPopulationProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionStddevPopulationProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionStddevPopulationReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionStddevPopulationReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionStddevPopulationRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionStddevPopulationRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionStddevPopulationRetryableAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsConnectionStddevPopulationRetryableDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsConnectionStddevPopulationRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionStddevPopulationRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionStddevPopulationSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionStddevPopulationSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevPopulationSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionStddevPopulationSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionStddevPopulationTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsConnectionStddevPopulationTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionStddevSampleActionIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionStddevSampleActionIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionStddevSampleCallIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsConnectionStddevSampleCallIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsConnectionStddevSampleCommentAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsConnectionStddevSampleCommentDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsConnectionStddevSampleCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionStddevSampleCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionStddevSampleCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionStddevSampleCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionStddevSampleCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionStddevSampleCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionStddevSampleCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevSampleCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevSampleEventAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsConnectionStddevSampleEventDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsConnectionStddevSampleEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionStddevSampleEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionStddevSampleFileIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsConnectionStddevSampleFileIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsConnectionStddevSampleIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsConnectionStddevSampleIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsConnectionStddevSampleParentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionStddevSampleParentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionStddevSamplePayloadAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionStddevSamplePayloadDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionStddevSampleProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionStddevSampleProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionStddevSampleReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionStddevSampleReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionStddevSampleRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionStddevSampleRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionStddevSampleRetryableAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionStddevSampleRetryableDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionStddevSampleRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionStddevSampleRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionStddevSampleSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionStddevSampleSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevSampleSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionStddevSampleSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionStddevSampleTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsConnectionStddevSampleTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevSampleWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionStddevSampleWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsConnectionSumActionIdAsc = 'FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsConnectionSumActionIdDesc = 'FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsConnectionSumCallIdAsc = 'FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  FeedsConnectionSumCallIdDesc = 'FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  FeedsConnectionSumCommentAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ASC',
  FeedsConnectionSumCommentDesc = 'FEEDS_CONNECTION_SUM_COMMENT_DESC',
  FeedsConnectionSumCommentIdAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsConnectionSumCommentIdDesc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsConnectionSumCompanyIdAsc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsConnectionSumCompanyIdDesc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsConnectionSumCreatedAtAsc = 'FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsConnectionSumCreatedAtDesc = 'FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsConnectionSumCreatedByAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsConnectionSumCreatedByContactIdAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionSumCreatedByContactIdDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionSumCreatedByDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsConnectionSumCreatedByTypeAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsConnectionSumCreatedByTypeDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsConnectionSumEmailMessageIdAsc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionSumEmailMessageIdDesc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionSumEventAsc = 'FEEDS_CONNECTION_SUM_EVENT_ASC',
  FeedsConnectionSumEventDesc = 'FEEDS_CONNECTION_SUM_EVENT_DESC',
  FeedsConnectionSumEventTypeAsc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsConnectionSumEventTypeDesc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsConnectionSumFileIdAsc = 'FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  FeedsConnectionSumFileIdDesc = 'FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  FeedsConnectionSumIdAsc = 'FEEDS_CONNECTION_SUM_ID_ASC',
  FeedsConnectionSumIdDesc = 'FEEDS_CONNECTION_SUM_ID_DESC',
  FeedsConnectionSumParentIdAsc = 'FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsConnectionSumParentIdDesc = 'FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsConnectionSumPayloadAsc = 'FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsConnectionSumPayloadDesc = 'FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsConnectionSumProjectIdAsc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsConnectionSumProjectIdDesc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsConnectionSumReminderIdAsc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsConnectionSumReminderIdDesc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsConnectionSumRetriedAtAsc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsConnectionSumRetriedAtDesc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsConnectionSumRetryableAsc = 'FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsConnectionSumRetryableDesc = 'FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsConnectionSumRetryCountAsc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsConnectionSumRetryCountDesc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsConnectionSumSearchTextAsc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsConnectionSumSearchTextDesc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsConnectionSumSmsActivityIdAsc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionSumSmsActivityIdDesc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionSumSystemIdAsc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsConnectionSumSystemIdDesc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsConnectionSumTaskIdAsc = 'FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  FeedsConnectionSumTaskIdDesc = 'FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  FeedsConnectionSumVirtualCreatedAtAsc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionSumVirtualCreatedAtDesc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionSumWorkflowIdAsc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsConnectionSumWorkflowIdDesc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsConnectionVariancePopulationActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsConnectionVariancePopulationActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsConnectionVariancePopulationCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsConnectionVariancePopulationCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsConnectionVariancePopulationCommentAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsConnectionVariancePopulationCommentDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsConnectionVariancePopulationCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionVariancePopulationCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionVariancePopulationCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionVariancePopulationCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionVariancePopulationCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVariancePopulationCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVariancePopulationEventAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsConnectionVariancePopulationEventDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsConnectionVariancePopulationEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionVariancePopulationEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionVariancePopulationFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsConnectionVariancePopulationFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsConnectionVariancePopulationIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsConnectionVariancePopulationIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsConnectionVariancePopulationParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsConnectionVariancePopulationParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsConnectionVariancePopulationPayloadAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsConnectionVariancePopulationPayloadDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsConnectionVariancePopulationProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionVariancePopulationProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionVariancePopulationReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionVariancePopulationReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionVariancePopulationRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionVariancePopulationRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionVariancePopulationRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsConnectionVariancePopulationRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsConnectionVariancePopulationRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionVariancePopulationRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionVariancePopulationSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionVariancePopulationSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVariancePopulationSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionVariancePopulationSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionVariancePopulationTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsConnectionVariancePopulationTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionVarianceSampleActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionVarianceSampleActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionVarianceSampleCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsConnectionVarianceSampleCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsConnectionVarianceSampleCommentAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsConnectionVarianceSampleCommentDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsConnectionVarianceSampleCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionVarianceSampleCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionVarianceSampleCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionVarianceSampleCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionVarianceSampleCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVarianceSampleCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVarianceSampleEventAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsConnectionVarianceSampleEventDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsConnectionVarianceSampleEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionVarianceSampleEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionVarianceSampleFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsConnectionVarianceSampleFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsConnectionVarianceSampleIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsConnectionVarianceSampleIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsConnectionVarianceSampleParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionVarianceSampleParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionVarianceSamplePayloadAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionVarianceSamplePayloadDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionVarianceSampleProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionVarianceSampleProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionVarianceSampleReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionVarianceSampleReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionVarianceSampleRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionVarianceSampleRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionVarianceSampleRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionVarianceSampleRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionVarianceSampleRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionVarianceSampleRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionVarianceSampleSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionVarianceSampleSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVarianceSampleSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionVarianceSampleSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionVarianceSampleTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsConnectionVarianceSampleTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FilesConnectionArrayAggActionIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesConnectionArrayAggActionIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesConnectionArrayAggActionItemIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesConnectionArrayAggActionItemIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesConnectionArrayAggAnnotationsAsc = 'FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesConnectionArrayAggAnnotationsDesc = 'FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesConnectionArrayAggCommentIdAsc = 'FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesConnectionArrayAggCommentIdDesc = 'FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesConnectionArrayAggCompanyIdAsc = 'FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesConnectionArrayAggCompanyIdDesc = 'FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesConnectionArrayAggCreatedAtAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesConnectionArrayAggCreatedAtDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesConnectionArrayAggCreatedByAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesConnectionArrayAggCreatedByContactIdAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionArrayAggCreatedByContactIdDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionArrayAggCreatedByDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesConnectionArrayAggCreatedByTypeAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesConnectionArrayAggCreatedByTypeDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesConnectionArrayAggDownloadUrlAsc = 'FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesConnectionArrayAggDownloadUrlDesc = 'FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesConnectionArrayAggFormIdAsc = 'FILES_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesConnectionArrayAggFormIdDesc = 'FILES_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesConnectionArrayAggIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesConnectionArrayAggIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesConnectionArrayAggIsArchivedAsc = 'FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesConnectionArrayAggIsArchivedDesc = 'FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesConnectionArrayAggIsFormAsc = 'FILES_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesConnectionArrayAggIsFormDesc = 'FILES_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesConnectionArrayAggMetaDataAsc = 'FILES_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesConnectionArrayAggMetaDataDesc = 'FILES_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesConnectionArrayAggModifiedByAsc = 'FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesConnectionArrayAggModifiedByDesc = 'FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesConnectionArrayAggNameAsc = 'FILES_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesConnectionArrayAggNameDesc = 'FILES_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesConnectionArrayAggOriginAsc = 'FILES_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesConnectionArrayAggOriginDesc = 'FILES_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesConnectionArrayAggPrivilegeAllAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesConnectionArrayAggPrivilegeAllDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesConnectionArrayAggPrivilegeAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesConnectionArrayAggPrivilegeDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesConnectionArrayAggPrivilegeOwnAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesConnectionArrayAggPrivilegeOwnDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesConnectionArrayAggPrivilegeTeamAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesConnectionArrayAggPrivilegeTeamDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesConnectionArrayAggProcessingStatusAsc = 'FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesConnectionArrayAggProcessingStatusDesc = 'FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionArrayAggProjectColumnValueIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionArrayAggProjectColumnValueIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionArrayAggProjectIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesConnectionArrayAggProjectIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesConnectionArrayAggSharedWithPortalAsc = 'FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesConnectionArrayAggSharedWithPortalDesc = 'FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesConnectionArrayAggSizeInBytesAsc = 'FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesConnectionArrayAggSizeInBytesDesc = 'FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesConnectionArrayAggStageIdAsc = 'FILES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesConnectionArrayAggStageIdDesc = 'FILES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesConnectionArrayAggTypeAsc = 'FILES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesConnectionArrayAggTypeDesc = 'FILES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesConnectionArrayAggUpdatedAtAsc = 'FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesConnectionArrayAggUpdatedAtDesc = 'FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesConnectionArrayAggUuidAsc = 'FILES_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesConnectionArrayAggUuidDesc = 'FILES_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesConnectionArrayAggValueFileIdAsc = 'FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesConnectionArrayAggValueFileIdDesc = 'FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesConnectionAverageActionIdAsc = 'FILES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesConnectionAverageActionIdDesc = 'FILES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesConnectionAverageActionItemIdAsc = 'FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesConnectionAverageActionItemIdDesc = 'FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesConnectionAverageAnnotationsAsc = 'FILES_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesConnectionAverageAnnotationsDesc = 'FILES_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesConnectionAverageCommentIdAsc = 'FILES_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesConnectionAverageCommentIdDesc = 'FILES_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesConnectionAverageCompanyIdAsc = 'FILES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesConnectionAverageCompanyIdDesc = 'FILES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesConnectionAverageCreatedAtAsc = 'FILES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesConnectionAverageCreatedAtDesc = 'FILES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesConnectionAverageCreatedByAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesConnectionAverageCreatedByContactIdAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionAverageCreatedByContactIdDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionAverageCreatedByDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesConnectionAverageCreatedByTypeAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesConnectionAverageCreatedByTypeDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesConnectionAverageDownloadUrlAsc = 'FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesConnectionAverageDownloadUrlDesc = 'FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesConnectionAverageFormIdAsc = 'FILES_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesConnectionAverageFormIdDesc = 'FILES_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesConnectionAverageIdAsc = 'FILES_CONNECTION_AVERAGE_ID_ASC',
  FilesConnectionAverageIdDesc = 'FILES_CONNECTION_AVERAGE_ID_DESC',
  FilesConnectionAverageIsArchivedAsc = 'FILES_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesConnectionAverageIsArchivedDesc = 'FILES_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesConnectionAverageIsFormAsc = 'FILES_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesConnectionAverageIsFormDesc = 'FILES_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesConnectionAverageMetaDataAsc = 'FILES_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesConnectionAverageMetaDataDesc = 'FILES_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesConnectionAverageModifiedByAsc = 'FILES_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesConnectionAverageModifiedByDesc = 'FILES_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesConnectionAverageNameAsc = 'FILES_CONNECTION_AVERAGE_NAME_ASC',
  FilesConnectionAverageNameDesc = 'FILES_CONNECTION_AVERAGE_NAME_DESC',
  FilesConnectionAverageOriginAsc = 'FILES_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesConnectionAverageOriginDesc = 'FILES_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesConnectionAveragePrivilegeAllAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesConnectionAveragePrivilegeAllDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesConnectionAveragePrivilegeAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesConnectionAveragePrivilegeDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesConnectionAveragePrivilegeOwnAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesConnectionAveragePrivilegeOwnDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesConnectionAveragePrivilegeTeamAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesConnectionAveragePrivilegeTeamDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesConnectionAverageProcessingStatusAsc = 'FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesConnectionAverageProcessingStatusDesc = 'FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesConnectionAverageProjectColumnValueFileIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionAverageProjectColumnValueFileIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionAverageProjectColumnValueIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionAverageProjectColumnValueIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionAverageProjectIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesConnectionAverageProjectIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesConnectionAverageSharedWithPortalAsc = 'FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionAverageSharedWithPortalDesc = 'FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionAverageSizeInBytesAsc = 'FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesConnectionAverageSizeInBytesDesc = 'FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesConnectionAverageStageIdAsc = 'FILES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesConnectionAverageStageIdDesc = 'FILES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesConnectionAverageTypeAsc = 'FILES_CONNECTION_AVERAGE_TYPE_ASC',
  FilesConnectionAverageTypeDesc = 'FILES_CONNECTION_AVERAGE_TYPE_DESC',
  FilesConnectionAverageUpdatedAtAsc = 'FILES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesConnectionAverageUpdatedAtDesc = 'FILES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesConnectionAverageUuidAsc = 'FILES_CONNECTION_AVERAGE_UUID_ASC',
  FilesConnectionAverageUuidDesc = 'FILES_CONNECTION_AVERAGE_UUID_DESC',
  FilesConnectionAverageValueFileIdAsc = 'FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesConnectionAverageValueFileIdDesc = 'FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesConnectionCountAsc = 'FILES_CONNECTION_COUNT_ASC',
  FilesConnectionCountDesc = 'FILES_CONNECTION_COUNT_DESC',
  FilesConnectionDistinctCountActionIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesConnectionDistinctCountActionIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesConnectionDistinctCountActionItemIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesConnectionDistinctCountActionItemIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesConnectionDistinctCountAnnotationsAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesConnectionDistinctCountAnnotationsDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesConnectionDistinctCountCommentIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesConnectionDistinctCountCommentIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesConnectionDistinctCountCompanyIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesConnectionDistinctCountCompanyIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesConnectionDistinctCountCreatedAtAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesConnectionDistinctCountCreatedAtDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesConnectionDistinctCountCreatedByAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesConnectionDistinctCountCreatedByContactIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionDistinctCountCreatedByContactIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionDistinctCountCreatedByDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesConnectionDistinctCountCreatedByTypeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesConnectionDistinctCountCreatedByTypeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesConnectionDistinctCountDownloadUrlAsc = 'FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesConnectionDistinctCountDownloadUrlDesc = 'FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesConnectionDistinctCountFormIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesConnectionDistinctCountFormIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesConnectionDistinctCountIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesConnectionDistinctCountIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesConnectionDistinctCountIsArchivedAsc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesConnectionDistinctCountIsArchivedDesc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesConnectionDistinctCountIsFormAsc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesConnectionDistinctCountIsFormDesc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesConnectionDistinctCountMetaDataAsc = 'FILES_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesConnectionDistinctCountMetaDataDesc = 'FILES_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesConnectionDistinctCountModifiedByAsc = 'FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesConnectionDistinctCountModifiedByDesc = 'FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesConnectionDistinctCountNameAsc = 'FILES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesConnectionDistinctCountNameDesc = 'FILES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesConnectionDistinctCountOriginAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesConnectionDistinctCountOriginDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesConnectionDistinctCountPrivilegeAllAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesConnectionDistinctCountPrivilegeAllDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesConnectionDistinctCountPrivilegeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesConnectionDistinctCountPrivilegeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesConnectionDistinctCountPrivilegeOwnAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesConnectionDistinctCountPrivilegeOwnDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesConnectionDistinctCountPrivilegeTeamAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesConnectionDistinctCountPrivilegeTeamDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesConnectionDistinctCountProcessingStatusAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesConnectionDistinctCountProcessingStatusDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionDistinctCountProjectIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesConnectionDistinctCountProjectIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesConnectionDistinctCountSharedWithPortalAsc = 'FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesConnectionDistinctCountSharedWithPortalDesc = 'FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesConnectionDistinctCountSizeInBytesAsc = 'FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesConnectionDistinctCountSizeInBytesDesc = 'FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesConnectionDistinctCountStageIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesConnectionDistinctCountStageIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesConnectionDistinctCountTypeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesConnectionDistinctCountTypeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesConnectionDistinctCountUpdatedAtAsc = 'FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesConnectionDistinctCountUpdatedAtDesc = 'FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesConnectionDistinctCountUuidAsc = 'FILES_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesConnectionDistinctCountUuidDesc = 'FILES_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesConnectionDistinctCountValueFileIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesConnectionDistinctCountValueFileIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesConnectionMaxActionIdAsc = 'FILES_CONNECTION_MAX_ACTION_ID_ASC',
  FilesConnectionMaxActionIdDesc = 'FILES_CONNECTION_MAX_ACTION_ID_DESC',
  FilesConnectionMaxActionItemIdAsc = 'FILES_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesConnectionMaxActionItemIdDesc = 'FILES_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesConnectionMaxAnnotationsAsc = 'FILES_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesConnectionMaxAnnotationsDesc = 'FILES_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesConnectionMaxCommentIdAsc = 'FILES_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesConnectionMaxCommentIdDesc = 'FILES_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesConnectionMaxCompanyIdAsc = 'FILES_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesConnectionMaxCompanyIdDesc = 'FILES_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesConnectionMaxCreatedAtAsc = 'FILES_CONNECTION_MAX_CREATED_AT_ASC',
  FilesConnectionMaxCreatedAtDesc = 'FILES_CONNECTION_MAX_CREATED_AT_DESC',
  FilesConnectionMaxCreatedByAsc = 'FILES_CONNECTION_MAX_CREATED_BY_ASC',
  FilesConnectionMaxCreatedByContactIdAsc = 'FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionMaxCreatedByContactIdDesc = 'FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionMaxCreatedByDesc = 'FILES_CONNECTION_MAX_CREATED_BY_DESC',
  FilesConnectionMaxCreatedByTypeAsc = 'FILES_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesConnectionMaxCreatedByTypeDesc = 'FILES_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesConnectionMaxDownloadUrlAsc = 'FILES_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesConnectionMaxDownloadUrlDesc = 'FILES_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesConnectionMaxFormIdAsc = 'FILES_CONNECTION_MAX_FORM_ID_ASC',
  FilesConnectionMaxFormIdDesc = 'FILES_CONNECTION_MAX_FORM_ID_DESC',
  FilesConnectionMaxIdAsc = 'FILES_CONNECTION_MAX_ID_ASC',
  FilesConnectionMaxIdDesc = 'FILES_CONNECTION_MAX_ID_DESC',
  FilesConnectionMaxIsArchivedAsc = 'FILES_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesConnectionMaxIsArchivedDesc = 'FILES_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesConnectionMaxIsFormAsc = 'FILES_CONNECTION_MAX_IS_FORM_ASC',
  FilesConnectionMaxIsFormDesc = 'FILES_CONNECTION_MAX_IS_FORM_DESC',
  FilesConnectionMaxMetaDataAsc = 'FILES_CONNECTION_MAX_META_DATA_ASC',
  FilesConnectionMaxMetaDataDesc = 'FILES_CONNECTION_MAX_META_DATA_DESC',
  FilesConnectionMaxModifiedByAsc = 'FILES_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesConnectionMaxModifiedByDesc = 'FILES_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesConnectionMaxNameAsc = 'FILES_CONNECTION_MAX_NAME_ASC',
  FilesConnectionMaxNameDesc = 'FILES_CONNECTION_MAX_NAME_DESC',
  FilesConnectionMaxOriginAsc = 'FILES_CONNECTION_MAX_ORIGIN_ASC',
  FilesConnectionMaxOriginDesc = 'FILES_CONNECTION_MAX_ORIGIN_DESC',
  FilesConnectionMaxPrivilegeAllAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesConnectionMaxPrivilegeAllDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesConnectionMaxPrivilegeAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesConnectionMaxPrivilegeDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesConnectionMaxPrivilegeOwnAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesConnectionMaxPrivilegeOwnDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesConnectionMaxPrivilegeTeamAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesConnectionMaxPrivilegeTeamDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesConnectionMaxProcessingStatusAsc = 'FILES_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesConnectionMaxProcessingStatusDesc = 'FILES_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesConnectionMaxProjectColumnValueFileIdAsc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionMaxProjectColumnValueFileIdDesc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionMaxProjectColumnValueIdAsc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionMaxProjectColumnValueIdDesc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionMaxProjectIdAsc = 'FILES_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesConnectionMaxProjectIdDesc = 'FILES_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesConnectionMaxSharedWithPortalAsc = 'FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesConnectionMaxSharedWithPortalDesc = 'FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesConnectionMaxSizeInBytesAsc = 'FILES_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesConnectionMaxSizeInBytesDesc = 'FILES_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesConnectionMaxStageIdAsc = 'FILES_CONNECTION_MAX_STAGE_ID_ASC',
  FilesConnectionMaxStageIdDesc = 'FILES_CONNECTION_MAX_STAGE_ID_DESC',
  FilesConnectionMaxTypeAsc = 'FILES_CONNECTION_MAX_TYPE_ASC',
  FilesConnectionMaxTypeDesc = 'FILES_CONNECTION_MAX_TYPE_DESC',
  FilesConnectionMaxUpdatedAtAsc = 'FILES_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesConnectionMaxUpdatedAtDesc = 'FILES_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesConnectionMaxUuidAsc = 'FILES_CONNECTION_MAX_UUID_ASC',
  FilesConnectionMaxUuidDesc = 'FILES_CONNECTION_MAX_UUID_DESC',
  FilesConnectionMaxValueFileIdAsc = 'FILES_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesConnectionMaxValueFileIdDesc = 'FILES_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesConnectionMinActionIdAsc = 'FILES_CONNECTION_MIN_ACTION_ID_ASC',
  FilesConnectionMinActionIdDesc = 'FILES_CONNECTION_MIN_ACTION_ID_DESC',
  FilesConnectionMinActionItemIdAsc = 'FILES_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesConnectionMinActionItemIdDesc = 'FILES_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesConnectionMinAnnotationsAsc = 'FILES_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesConnectionMinAnnotationsDesc = 'FILES_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesConnectionMinCommentIdAsc = 'FILES_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesConnectionMinCommentIdDesc = 'FILES_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesConnectionMinCompanyIdAsc = 'FILES_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesConnectionMinCompanyIdDesc = 'FILES_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesConnectionMinCreatedAtAsc = 'FILES_CONNECTION_MIN_CREATED_AT_ASC',
  FilesConnectionMinCreatedAtDesc = 'FILES_CONNECTION_MIN_CREATED_AT_DESC',
  FilesConnectionMinCreatedByAsc = 'FILES_CONNECTION_MIN_CREATED_BY_ASC',
  FilesConnectionMinCreatedByContactIdAsc = 'FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionMinCreatedByContactIdDesc = 'FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionMinCreatedByDesc = 'FILES_CONNECTION_MIN_CREATED_BY_DESC',
  FilesConnectionMinCreatedByTypeAsc = 'FILES_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesConnectionMinCreatedByTypeDesc = 'FILES_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesConnectionMinDownloadUrlAsc = 'FILES_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesConnectionMinDownloadUrlDesc = 'FILES_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesConnectionMinFormIdAsc = 'FILES_CONNECTION_MIN_FORM_ID_ASC',
  FilesConnectionMinFormIdDesc = 'FILES_CONNECTION_MIN_FORM_ID_DESC',
  FilesConnectionMinIdAsc = 'FILES_CONNECTION_MIN_ID_ASC',
  FilesConnectionMinIdDesc = 'FILES_CONNECTION_MIN_ID_DESC',
  FilesConnectionMinIsArchivedAsc = 'FILES_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesConnectionMinIsArchivedDesc = 'FILES_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesConnectionMinIsFormAsc = 'FILES_CONNECTION_MIN_IS_FORM_ASC',
  FilesConnectionMinIsFormDesc = 'FILES_CONNECTION_MIN_IS_FORM_DESC',
  FilesConnectionMinMetaDataAsc = 'FILES_CONNECTION_MIN_META_DATA_ASC',
  FilesConnectionMinMetaDataDesc = 'FILES_CONNECTION_MIN_META_DATA_DESC',
  FilesConnectionMinModifiedByAsc = 'FILES_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesConnectionMinModifiedByDesc = 'FILES_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesConnectionMinNameAsc = 'FILES_CONNECTION_MIN_NAME_ASC',
  FilesConnectionMinNameDesc = 'FILES_CONNECTION_MIN_NAME_DESC',
  FilesConnectionMinOriginAsc = 'FILES_CONNECTION_MIN_ORIGIN_ASC',
  FilesConnectionMinOriginDesc = 'FILES_CONNECTION_MIN_ORIGIN_DESC',
  FilesConnectionMinPrivilegeAllAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesConnectionMinPrivilegeAllDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesConnectionMinPrivilegeAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesConnectionMinPrivilegeDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesConnectionMinPrivilegeOwnAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesConnectionMinPrivilegeOwnDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesConnectionMinPrivilegeTeamAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesConnectionMinPrivilegeTeamDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesConnectionMinProcessingStatusAsc = 'FILES_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesConnectionMinProcessingStatusDesc = 'FILES_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesConnectionMinProjectColumnValueFileIdAsc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionMinProjectColumnValueFileIdDesc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionMinProjectColumnValueIdAsc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionMinProjectColumnValueIdDesc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionMinProjectIdAsc = 'FILES_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesConnectionMinProjectIdDesc = 'FILES_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesConnectionMinSharedWithPortalAsc = 'FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesConnectionMinSharedWithPortalDesc = 'FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesConnectionMinSizeInBytesAsc = 'FILES_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesConnectionMinSizeInBytesDesc = 'FILES_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesConnectionMinStageIdAsc = 'FILES_CONNECTION_MIN_STAGE_ID_ASC',
  FilesConnectionMinStageIdDesc = 'FILES_CONNECTION_MIN_STAGE_ID_DESC',
  FilesConnectionMinTypeAsc = 'FILES_CONNECTION_MIN_TYPE_ASC',
  FilesConnectionMinTypeDesc = 'FILES_CONNECTION_MIN_TYPE_DESC',
  FilesConnectionMinUpdatedAtAsc = 'FILES_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesConnectionMinUpdatedAtDesc = 'FILES_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesConnectionMinUuidAsc = 'FILES_CONNECTION_MIN_UUID_ASC',
  FilesConnectionMinUuidDesc = 'FILES_CONNECTION_MIN_UUID_DESC',
  FilesConnectionMinValueFileIdAsc = 'FILES_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesConnectionMinValueFileIdDesc = 'FILES_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevPopulationActionIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesConnectionStddevPopulationActionIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesConnectionStddevPopulationActionItemIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesConnectionStddevPopulationActionItemIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesConnectionStddevPopulationAnnotationsAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesConnectionStddevPopulationAnnotationsDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesConnectionStddevPopulationCommentIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesConnectionStddevPopulationCommentIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesConnectionStddevPopulationCompanyIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesConnectionStddevPopulationCompanyIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesConnectionStddevPopulationCreatedAtAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesConnectionStddevPopulationCreatedAtDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesConnectionStddevPopulationCreatedByAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionStddevPopulationCreatedByDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesConnectionStddevPopulationCreatedByTypeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesConnectionStddevPopulationCreatedByTypeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesConnectionStddevPopulationDownloadUrlAsc = 'FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesConnectionStddevPopulationDownloadUrlDesc = 'FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesConnectionStddevPopulationFormIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesConnectionStddevPopulationFormIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesConnectionStddevPopulationIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesConnectionStddevPopulationIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesConnectionStddevPopulationIsArchivedAsc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesConnectionStddevPopulationIsArchivedDesc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesConnectionStddevPopulationIsFormAsc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesConnectionStddevPopulationIsFormDesc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesConnectionStddevPopulationMetaDataAsc = 'FILES_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesConnectionStddevPopulationMetaDataDesc = 'FILES_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesConnectionStddevPopulationModifiedByAsc = 'FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesConnectionStddevPopulationModifiedByDesc = 'FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesConnectionStddevPopulationNameAsc = 'FILES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesConnectionStddevPopulationNameDesc = 'FILES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesConnectionStddevPopulationOriginAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesConnectionStddevPopulationOriginDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesConnectionStddevPopulationPrivilegeAllAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesConnectionStddevPopulationPrivilegeAllDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesConnectionStddevPopulationPrivilegeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesConnectionStddevPopulationPrivilegeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesConnectionStddevPopulationProcessingStatusAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesConnectionStddevPopulationProcessingStatusDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionStddevPopulationProjectIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesConnectionStddevPopulationProjectIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesConnectionStddevPopulationSharedWithPortalAsc = 'FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesConnectionStddevPopulationSharedWithPortalDesc = 'FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesConnectionStddevPopulationSizeInBytesAsc = 'FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesConnectionStddevPopulationSizeInBytesDesc = 'FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesConnectionStddevPopulationStageIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesConnectionStddevPopulationStageIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesConnectionStddevPopulationTypeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesConnectionStddevPopulationTypeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesConnectionStddevPopulationUpdatedAtAsc = 'FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesConnectionStddevPopulationUpdatedAtDesc = 'FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesConnectionStddevPopulationUuidAsc = 'FILES_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesConnectionStddevPopulationUuidDesc = 'FILES_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesConnectionStddevPopulationValueFileIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesConnectionStddevPopulationValueFileIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesConnectionStddevSampleActionIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesConnectionStddevSampleActionIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesConnectionStddevSampleActionItemIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesConnectionStddevSampleActionItemIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesConnectionStddevSampleAnnotationsAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesConnectionStddevSampleAnnotationsDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesConnectionStddevSampleCommentIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesConnectionStddevSampleCommentIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesConnectionStddevSampleCompanyIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesConnectionStddevSampleCompanyIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesConnectionStddevSampleCreatedAtAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesConnectionStddevSampleCreatedAtDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesConnectionStddevSampleCreatedByAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesConnectionStddevSampleCreatedByContactIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionStddevSampleCreatedByContactIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionStddevSampleCreatedByDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesConnectionStddevSampleCreatedByTypeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesConnectionStddevSampleCreatedByTypeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesConnectionStddevSampleDownloadUrlAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesConnectionStddevSampleDownloadUrlDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesConnectionStddevSampleFormIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesConnectionStddevSampleFormIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesConnectionStddevSampleIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesConnectionStddevSampleIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesConnectionStddevSampleIsArchivedAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesConnectionStddevSampleIsArchivedDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesConnectionStddevSampleIsFormAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesConnectionStddevSampleIsFormDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesConnectionStddevSampleMetaDataAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesConnectionStddevSampleMetaDataDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesConnectionStddevSampleModifiedByAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesConnectionStddevSampleModifiedByDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesConnectionStddevSampleNameAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesConnectionStddevSampleNameDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesConnectionStddevSampleOriginAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesConnectionStddevSampleOriginDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesConnectionStddevSamplePrivilegeAllAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesConnectionStddevSamplePrivilegeAllDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesConnectionStddevSamplePrivilegeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesConnectionStddevSamplePrivilegeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesConnectionStddevSamplePrivilegeOwnAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesConnectionStddevSamplePrivilegeOwnDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesConnectionStddevSamplePrivilegeTeamAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesConnectionStddevSamplePrivilegeTeamDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesConnectionStddevSampleProcessingStatusAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesConnectionStddevSampleProcessingStatusDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionStddevSampleProjectIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesConnectionStddevSampleProjectIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesConnectionStddevSampleSharedWithPortalAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionStddevSampleSharedWithPortalDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionStddevSampleSizeInBytesAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesConnectionStddevSampleSizeInBytesDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesConnectionStddevSampleStageIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesConnectionStddevSampleStageIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesConnectionStddevSampleTypeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesConnectionStddevSampleTypeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesConnectionStddevSampleUpdatedAtAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesConnectionStddevSampleUpdatedAtDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesConnectionStddevSampleUuidAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesConnectionStddevSampleUuidDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesConnectionStddevSampleValueFileIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesConnectionStddevSampleValueFileIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesConnectionSumActionIdAsc = 'FILES_CONNECTION_SUM_ACTION_ID_ASC',
  FilesConnectionSumActionIdDesc = 'FILES_CONNECTION_SUM_ACTION_ID_DESC',
  FilesConnectionSumActionItemIdAsc = 'FILES_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesConnectionSumActionItemIdDesc = 'FILES_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesConnectionSumAnnotationsAsc = 'FILES_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesConnectionSumAnnotationsDesc = 'FILES_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesConnectionSumCommentIdAsc = 'FILES_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesConnectionSumCommentIdDesc = 'FILES_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesConnectionSumCompanyIdAsc = 'FILES_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesConnectionSumCompanyIdDesc = 'FILES_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesConnectionSumCreatedAtAsc = 'FILES_CONNECTION_SUM_CREATED_AT_ASC',
  FilesConnectionSumCreatedAtDesc = 'FILES_CONNECTION_SUM_CREATED_AT_DESC',
  FilesConnectionSumCreatedByAsc = 'FILES_CONNECTION_SUM_CREATED_BY_ASC',
  FilesConnectionSumCreatedByContactIdAsc = 'FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionSumCreatedByContactIdDesc = 'FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionSumCreatedByDesc = 'FILES_CONNECTION_SUM_CREATED_BY_DESC',
  FilesConnectionSumCreatedByTypeAsc = 'FILES_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesConnectionSumCreatedByTypeDesc = 'FILES_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesConnectionSumDownloadUrlAsc = 'FILES_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesConnectionSumDownloadUrlDesc = 'FILES_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesConnectionSumFormIdAsc = 'FILES_CONNECTION_SUM_FORM_ID_ASC',
  FilesConnectionSumFormIdDesc = 'FILES_CONNECTION_SUM_FORM_ID_DESC',
  FilesConnectionSumIdAsc = 'FILES_CONNECTION_SUM_ID_ASC',
  FilesConnectionSumIdDesc = 'FILES_CONNECTION_SUM_ID_DESC',
  FilesConnectionSumIsArchivedAsc = 'FILES_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesConnectionSumIsArchivedDesc = 'FILES_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesConnectionSumIsFormAsc = 'FILES_CONNECTION_SUM_IS_FORM_ASC',
  FilesConnectionSumIsFormDesc = 'FILES_CONNECTION_SUM_IS_FORM_DESC',
  FilesConnectionSumMetaDataAsc = 'FILES_CONNECTION_SUM_META_DATA_ASC',
  FilesConnectionSumMetaDataDesc = 'FILES_CONNECTION_SUM_META_DATA_DESC',
  FilesConnectionSumModifiedByAsc = 'FILES_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesConnectionSumModifiedByDesc = 'FILES_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesConnectionSumNameAsc = 'FILES_CONNECTION_SUM_NAME_ASC',
  FilesConnectionSumNameDesc = 'FILES_CONNECTION_SUM_NAME_DESC',
  FilesConnectionSumOriginAsc = 'FILES_CONNECTION_SUM_ORIGIN_ASC',
  FilesConnectionSumOriginDesc = 'FILES_CONNECTION_SUM_ORIGIN_DESC',
  FilesConnectionSumPrivilegeAllAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesConnectionSumPrivilegeAllDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesConnectionSumPrivilegeAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesConnectionSumPrivilegeDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesConnectionSumPrivilegeOwnAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesConnectionSumPrivilegeOwnDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesConnectionSumPrivilegeTeamAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesConnectionSumPrivilegeTeamDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesConnectionSumProcessingStatusAsc = 'FILES_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesConnectionSumProcessingStatusDesc = 'FILES_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesConnectionSumProjectColumnValueFileIdAsc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionSumProjectColumnValueFileIdDesc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionSumProjectColumnValueIdAsc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionSumProjectColumnValueIdDesc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionSumProjectIdAsc = 'FILES_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesConnectionSumProjectIdDesc = 'FILES_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesConnectionSumSharedWithPortalAsc = 'FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesConnectionSumSharedWithPortalDesc = 'FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesConnectionSumSizeInBytesAsc = 'FILES_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesConnectionSumSizeInBytesDesc = 'FILES_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesConnectionSumStageIdAsc = 'FILES_CONNECTION_SUM_STAGE_ID_ASC',
  FilesConnectionSumStageIdDesc = 'FILES_CONNECTION_SUM_STAGE_ID_DESC',
  FilesConnectionSumTypeAsc = 'FILES_CONNECTION_SUM_TYPE_ASC',
  FilesConnectionSumTypeDesc = 'FILES_CONNECTION_SUM_TYPE_DESC',
  FilesConnectionSumUpdatedAtAsc = 'FILES_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesConnectionSumUpdatedAtDesc = 'FILES_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesConnectionSumUuidAsc = 'FILES_CONNECTION_SUM_UUID_ASC',
  FilesConnectionSumUuidDesc = 'FILES_CONNECTION_SUM_UUID_DESC',
  FilesConnectionSumValueFileIdAsc = 'FILES_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesConnectionSumValueFileIdDesc = 'FILES_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesConnectionVariancePopulationActionIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesConnectionVariancePopulationActionIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesConnectionVariancePopulationActionItemIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesConnectionVariancePopulationActionItemIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesConnectionVariancePopulationAnnotationsAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesConnectionVariancePopulationAnnotationsDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesConnectionVariancePopulationCommentIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesConnectionVariancePopulationCommentIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesConnectionVariancePopulationCompanyIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesConnectionVariancePopulationCompanyIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesConnectionVariancePopulationCreatedAtAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesConnectionVariancePopulationCreatedAtDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesConnectionVariancePopulationCreatedByAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionVariancePopulationCreatedByDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesConnectionVariancePopulationCreatedByTypeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesConnectionVariancePopulationCreatedByTypeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesConnectionVariancePopulationDownloadUrlAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesConnectionVariancePopulationDownloadUrlDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesConnectionVariancePopulationFormIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesConnectionVariancePopulationFormIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesConnectionVariancePopulationIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesConnectionVariancePopulationIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesConnectionVariancePopulationIsArchivedAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesConnectionVariancePopulationIsArchivedDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesConnectionVariancePopulationIsFormAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesConnectionVariancePopulationIsFormDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesConnectionVariancePopulationMetaDataAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesConnectionVariancePopulationMetaDataDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesConnectionVariancePopulationModifiedByAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesConnectionVariancePopulationModifiedByDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesConnectionVariancePopulationNameAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesConnectionVariancePopulationNameDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesConnectionVariancePopulationOriginAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesConnectionVariancePopulationOriginDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesConnectionVariancePopulationPrivilegeAllAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesConnectionVariancePopulationPrivilegeAllDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesConnectionVariancePopulationPrivilegeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesConnectionVariancePopulationPrivilegeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesConnectionVariancePopulationProcessingStatusAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesConnectionVariancePopulationProcessingStatusDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionVariancePopulationProjectIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesConnectionVariancePopulationProjectIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesConnectionVariancePopulationSharedWithPortalAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesConnectionVariancePopulationSharedWithPortalDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesConnectionVariancePopulationSizeInBytesAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesConnectionVariancePopulationSizeInBytesDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesConnectionVariancePopulationStageIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesConnectionVariancePopulationStageIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesConnectionVariancePopulationTypeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesConnectionVariancePopulationTypeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesConnectionVariancePopulationUpdatedAtAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesConnectionVariancePopulationUpdatedAtDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesConnectionVariancePopulationUuidAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesConnectionVariancePopulationUuidDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesConnectionVariancePopulationValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesConnectionVariancePopulationValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesConnectionVarianceSampleActionIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesConnectionVarianceSampleActionIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesConnectionVarianceSampleActionItemIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesConnectionVarianceSampleActionItemIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesConnectionVarianceSampleAnnotationsAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesConnectionVarianceSampleAnnotationsDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesConnectionVarianceSampleCommentIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesConnectionVarianceSampleCommentIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesConnectionVarianceSampleCompanyIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesConnectionVarianceSampleCompanyIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesConnectionVarianceSampleCreatedAtAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesConnectionVarianceSampleCreatedAtDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesConnectionVarianceSampleCreatedByAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionVarianceSampleCreatedByDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesConnectionVarianceSampleCreatedByTypeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesConnectionVarianceSampleCreatedByTypeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesConnectionVarianceSampleDownloadUrlAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesConnectionVarianceSampleDownloadUrlDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesConnectionVarianceSampleFormIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesConnectionVarianceSampleFormIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesConnectionVarianceSampleIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesConnectionVarianceSampleIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesConnectionVarianceSampleIsArchivedAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesConnectionVarianceSampleIsArchivedDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesConnectionVarianceSampleIsFormAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesConnectionVarianceSampleIsFormDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesConnectionVarianceSampleMetaDataAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesConnectionVarianceSampleMetaDataDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesConnectionVarianceSampleModifiedByAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesConnectionVarianceSampleModifiedByDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesConnectionVarianceSampleNameAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesConnectionVarianceSampleNameDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesConnectionVarianceSampleOriginAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesConnectionVarianceSampleOriginDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesConnectionVarianceSamplePrivilegeAllAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesConnectionVarianceSamplePrivilegeAllDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesConnectionVarianceSamplePrivilegeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesConnectionVarianceSamplePrivilegeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesConnectionVarianceSampleProcessingStatusAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesConnectionVarianceSampleProcessingStatusDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionVarianceSampleProjectIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesConnectionVarianceSampleProjectIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesConnectionVarianceSampleSharedWithPortalAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionVarianceSampleSharedWithPortalDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionVarianceSampleSizeInBytesAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesConnectionVarianceSampleSizeInBytesDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesConnectionVarianceSampleStageIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesConnectionVarianceSampleStageIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesConnectionVarianceSampleTypeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesConnectionVarianceSampleTypeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesConnectionVarianceSampleUpdatedAtAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesConnectionVarianceSampleUpdatedAtDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesConnectionVarianceSampleUuidAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesConnectionVarianceSampleUuidDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesConnectionVarianceSampleValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesConnectionVarianceSampleValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsTemplateAsc = 'IS_TEMPLATE_ASC',
  IsTemplateDesc = 'IS_TEMPLATE_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectStageIdAsc = 'PROJECT_STAGE_ID_ASC',
  ProjectStageIdDesc = 'PROJECT_STAGE_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

export enum AlertStatus {
  Active = 'ACTIVE',
  Dismissed = 'DISMISSED',
  Recovered = 'RECOVERED'
}

/** A filter to be used against AlertStatus fields. All fields are combined with a logical ‘and.’ */
export type AlertStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<AlertStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<AlertStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<AlertStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<AlertStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<AlertStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<AlertStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<AlertStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<AlertStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<AlertStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<AlertStatus>>;
};

export type Analytic = Node & {
  args: Scalars['JSON']['output'];
  /** Reads a single `Company` that is related to this `Analytic`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `Analytic`. */
  createdByUser: Maybe<MainUser>;
  /** Reads a single `Dashboard` that is related to this `Analytic`. */
  dashboard: Maybe<Dashboard>;
  dashboardId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};

/** A filter to be used against aggregates of `Analytic` object types. */
export type AnalyticAggregatesFilter = {
  /** Array aggregation aggregate over matching `Analytic` objects. */
  arrayAgg: InputMaybe<AnalyticArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Analytic` objects. */
  average: InputMaybe<AnalyticAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Analytic` objects. */
  distinctCount: InputMaybe<AnalyticDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Analytic` object to be included within the aggregate. */
  filter: InputMaybe<AnalyticFilter>;
  /** Maximum aggregate over matching `Analytic` objects. */
  max: InputMaybe<AnalyticMaxAggregateFilter>;
  /** Minimum aggregate over matching `Analytic` objects. */
  min: InputMaybe<AnalyticMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Analytic` objects. */
  stddevPopulation: InputMaybe<AnalyticStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Analytic` objects. */
  stddevSample: InputMaybe<AnalyticStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Analytic` objects. */
  sum: InputMaybe<AnalyticSumAggregateFilter>;
  /** Population variance aggregate over matching `Analytic` objects. */
  variancePopulation: InputMaybe<AnalyticVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Analytic` objects. */
  varianceSample: InputMaybe<AnalyticVarianceSampleAggregateFilter>;
};

export type AnalyticArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  dashboardId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type AnalyticAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  dashboardId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `Analytic` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AnalyticCondition = {
  /** Checks for equality with the object’s `args` field. */
  args: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `dashboardId` field. */
  dashboardId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `subtitle` field. */
  subtitle: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type AnalyticDistinctCountAggregateFilter = {
  args: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  dashboardId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  subtitle: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type AnalyticFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<AnalyticFilter>>;
  /** Filter by the object’s `args` field. */
  args: InputMaybe<JsonFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboard` relation. */
  dashboard: InputMaybe<DashboardFilter>;
  /** Filter by the object’s `dashboardId` field. */
  dashboardId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<AnalyticFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<AnalyticFilter>>;
  /** Filter by the object’s `subtitle` field. */
  subtitle: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type AnalyticMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dashboardId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type AnalyticMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dashboardId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type AnalyticStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  dashboardId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type AnalyticStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  dashboardId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type AnalyticSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  dashboardId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type AnalyticVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  dashboardId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type AnalyticVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  dashboardId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Analytic`. */
export enum AnalyticsOrderBy {
  ArgsAsc = 'ARGS_ASC',
  ArgsDesc = 'ARGS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DashboardIdAsc = 'DASHBOARD_ID_ASC',
  DashboardIdDesc = 'DASHBOARD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SubtitleAsc = 'SUBTITLE_ASC',
  SubtitleDesc = 'SUBTITLE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Assignee = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `ProjectMember` that is related to this `Assignee`. */
  projectMember: Maybe<ProjectMember>;
  projectMemberId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `PrivilegedTask` that is related to this `Assignee`. */
  task: Maybe<PrivilegedTask>;
  taskId: Scalars['Int']['output'];
  /** Reads a single `TaskReport` that is related to this `Assignee`. */
  task_report_id: Maybe<TaskReport>;
  /** Reads a single `MainUser` that is related to this `Assignee`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `Assignee` object types. */
export type AssigneeAggregatesFilter = {
  /** Array aggregation aggregate over matching `Assignee` objects. */
  arrayAgg: InputMaybe<AssigneeArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Assignee` objects. */
  average: InputMaybe<AssigneeAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Assignee` objects. */
  distinctCount: InputMaybe<AssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Assignee` object to be included within the aggregate. */
  filter: InputMaybe<AssigneeFilter>;
  /** Maximum aggregate over matching `Assignee` objects. */
  max: InputMaybe<AssigneeMaxAggregateFilter>;
  /** Minimum aggregate over matching `Assignee` objects. */
  min: InputMaybe<AssigneeMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Assignee` objects. */
  stddevPopulation: InputMaybe<AssigneeStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Assignee` objects. */
  stddevSample: InputMaybe<AssigneeStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Assignee` objects. */
  sum: InputMaybe<AssigneeSumAggregateFilter>;
  /** Population variance aggregate over matching `Assignee` objects. */
  variancePopulation: InputMaybe<AssigneeVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Assignee` objects. */
  varianceSample: InputMaybe<AssigneeVarianceSampleAggregateFilter>;
};

export type AssigneeArrayAggAggregateFilter = {
  projectMemberId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type AssigneeAverageAggregateFilter = {
  projectMemberId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `Assignee` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AssigneeCondition = {
  /** Checks for equality with the object’s `projectMemberId` field. */
  projectMemberId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type AssigneeDistinctCountAggregateFilter = {
  projectMemberId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Assignee` object types. All fields are combined with a logical ‘and.’ */
export type AssigneeFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<AssigneeFilter>>;
  /** Negates the expression. */
  not: InputMaybe<AssigneeFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<AssigneeFilter>>;
  /** Filter by the object’s `projectMember` relation. */
  projectMember: InputMaybe<ProjectMemberFilter>;
  /** A related `projectMember` exists. */
  projectMemberExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectMemberId` field. */
  projectMemberId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task_report_id` relation. */
  task_report_id: InputMaybe<TaskReportFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type AssigneeMaxAggregateFilter = {
  projectMemberId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type AssigneeMinAggregateFilter = {
  projectMemberId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type AssigneeStddevPopulationAggregateFilter = {
  projectMemberId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type AssigneeStddevSampleAggregateFilter = {
  projectMemberId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type AssigneeSumAggregateFilter = {
  projectMemberId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type AssigneeVariancePopulationAggregateFilter = {
  projectMemberId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type AssigneeVarianceSampleAggregateFilter = {
  projectMemberId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Assignee`. */
export enum AssigneesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectMemberIdAsc = 'PROJECT_MEMBER_ID_ASC',
  ProjectMemberIdDesc = 'PROJECT_MEMBER_ID_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['BigFloat']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['BigFloat']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['BigFloat']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['BigFloat']['input']>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['BigInt']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['BigInt']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['BigInt']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['BigInt']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type Blueprint = Node & {
  /** Reads and enables pagination through a set of `BlueprintProjectStage`. */
  blueprintProjectStages: Array<BlueprintProjectStage>;
  /** Reads and enables pagination through a set of `BlueprintTimeslouse`. */
  blueprintTimeslice: Array<BlueprintTimeslouse>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectBlueprintIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportBlueprintIdAndCompanyIdList: Array<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectBlueprintIdAndCreatedByContactId: BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectBlueprintIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectBlueprintIdAndReferrerContactId: BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectBlueprintIdAndReferrerContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `Blueprint`. */
  createdByUser: Maybe<User>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectBlueprintIdAndJurisdictionId: BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectBlueprintIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportBlueprintIdAndJurisdictionId: BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportBlueprintIdAndJurisdictionIdList: Array<Jurisdiction>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportBlueprintIdAndParentProjectId: BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportBlueprintIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelines: Array<ProjectStageTimeline>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesConnection: ProjectStageTimelinesConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectBlueprintIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportBlueprintIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportBlueprintIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectStageTimelineBlueprintIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectBlueprintIdAndParentProjectId: BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectBlueprintIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportBlueprintIdAndProjectId: BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportBlueprintIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageTimelineBlueprintIdAndProjectId: BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageTimelineBlueprintIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportBlueprintIdAndTeamId: BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportBlueprintIdAndTeamIdList: Array<Team>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectBlueprintIdAndCreatedBy: BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectBlueprintIdAndOwnerId: BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectBlueprintIdAndProjectManagerId: BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectBlueprintIdAndSalesRepId: BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndCreatedBy: BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndOwnerId: BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndProjectManagerId: BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndSalesRepId: BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndUserId: BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportBlueprintIdAndWorkerValue: BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyConnection;
};


export type BlueprintBlueprintProjectStagesArgs = {
  condition: InputMaybe<BlueprintProjectStageCondition>;
  filter: InputMaybe<BlueprintProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintProjectStagesOrderBy>>;
};


export type BlueprintBlueprintTimesliceArgs = {
  condition: InputMaybe<BlueprintTimeslouseCondition>;
  filter: InputMaybe<BlueprintTimeslouseFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTimesliceOrderBy>>;
};


export type BlueprintCompaniesByProjectBlueprintIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type BlueprintCompaniesByProjectReportBlueprintIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type BlueprintProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type BlueprintProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type BlueprintProjectStageTimelinesArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type BlueprintProjectStageTimelinesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type BlueprintProjectStagesByProjectBlueprintIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type BlueprintProjectStagesByProjectReportBlueprintIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type BlueprintProjectStagesByProjectReportBlueprintIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type BlueprintProjectStagesByProjectStageTimelineBlueprintIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type BlueprintProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type BlueprintUsersByProjectBlueprintIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectBlueprintIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A filter to be used against aggregates of `Blueprint` object types. */
export type BlueprintAggregatesFilter = {
  /** Array aggregation aggregate over matching `Blueprint` objects. */
  arrayAgg: InputMaybe<BlueprintArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Blueprint` objects. */
  average: InputMaybe<BlueprintAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Blueprint` objects. */
  distinctCount: InputMaybe<BlueprintDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Blueprint` object to be included within the aggregate. */
  filter: InputMaybe<BlueprintFilter>;
  /** Maximum aggregate over matching `Blueprint` objects. */
  max: InputMaybe<BlueprintMaxAggregateFilter>;
  /** Minimum aggregate over matching `Blueprint` objects. */
  min: InputMaybe<BlueprintMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Blueprint` objects. */
  stddevPopulation: InputMaybe<BlueprintStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Blueprint` objects. */
  stddevSample: InputMaybe<BlueprintStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Blueprint` objects. */
  sum: InputMaybe<BlueprintSumAggregateFilter>;
  /** Population variance aggregate over matching `Blueprint` objects. */
  variancePopulation: InputMaybe<BlueprintVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Blueprint` objects. */
  varianceSample: InputMaybe<BlueprintVarianceSampleAggregateFilter>;
};

export type BlueprintArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type BlueprintAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `Blueprint` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BlueprintCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type BlueprintContactsByProjectBlueprintIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type BlueprintDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Blueprint` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<BlueprintFilter>>;
  /** Filter by the object’s `blueprintProjectStages` relation. */
  blueprintProjectStages: InputMaybe<BlueprintToManyBlueprintProjectStageFilter>;
  /** Some related `blueprintProjectStages` exist. */
  blueprintProjectStagesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintTimeslice` relation. */
  blueprintTimeslice: InputMaybe<BlueprintToManyBlueprintTimeslouseFilter>;
  /** Some related `blueprintTimeslice` exist. */
  blueprintTimesliceExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<BlueprintFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<BlueprintFilter>>;
  /** Filter by the object’s `projectReports` relation. */
  projectReports: InputMaybe<BlueprintToManyProjectReportFilter>;
  /** Some related `projectReports` exist. */
  projectReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageTimelines` relation. */
  projectStageTimelines: InputMaybe<BlueprintToManyProjectStageTimelineFilter>;
  /** Some related `projectStageTimelines` exist. */
  projectStageTimelinesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projects` relation. */
  projects: InputMaybe<BlueprintToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type BlueprintJurisdictionsByProjectBlueprintIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type BlueprintJurisdictionsByProjectReportBlueprintIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type BlueprintMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectReportsByProjectReportBlueprintIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type BlueprintProjectStage = Node & {
  /** Reads a single `Blueprint` that is related to this `BlueprintProjectStage`. */
  blueprint: Maybe<Blueprint>;
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `BlueprintTask`. */
  blueprintTasks: Array<BlueprintTask>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `BlueprintProjectStage`. */
  createdByUser: Maybe<User>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  /** Reads a single `ProjectStage` that is related to this `BlueprintProjectStage`. */
  projectStage: Maybe<ProjectStage>;
  projectStageId: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type BlueprintProjectStageBlueprintTasksArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};

/** A filter to be used against aggregates of `BlueprintProjectStage` object types. */
export type BlueprintProjectStageAggregatesFilter = {
  /** Array aggregation aggregate over matching `BlueprintProjectStage` objects. */
  arrayAgg: InputMaybe<BlueprintProjectStageArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `BlueprintProjectStage` objects. */
  average: InputMaybe<BlueprintProjectStageAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BlueprintProjectStage` objects. */
  distinctCount: InputMaybe<BlueprintProjectStageDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BlueprintProjectStage` object to be included within the aggregate. */
  filter: InputMaybe<BlueprintProjectStageFilter>;
  /** Maximum aggregate over matching `BlueprintProjectStage` objects. */
  max: InputMaybe<BlueprintProjectStageMaxAggregateFilter>;
  /** Minimum aggregate over matching `BlueprintProjectStage` objects. */
  min: InputMaybe<BlueprintProjectStageMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BlueprintProjectStage` objects. */
  stddevPopulation: InputMaybe<BlueprintProjectStageStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BlueprintProjectStage` objects. */
  stddevSample: InputMaybe<BlueprintProjectStageStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BlueprintProjectStage` objects. */
  sum: InputMaybe<BlueprintProjectStageSumAggregateFilter>;
  /** Population variance aggregate over matching `BlueprintProjectStage` objects. */
  variancePopulation: InputMaybe<BlueprintProjectStageVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BlueprintProjectStage` objects. */
  varianceSample: InputMaybe<BlueprintProjectStageVarianceSampleAggregateFilter>;
};

export type BlueprintProjectStageArrayAggAggregateFilter = {
  blueprintId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  projectStageId: InputMaybe<IntListFilter>;
};

export type BlueprintProjectStageAverageAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `BlueprintProjectStage` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BlueprintProjectStageCondition = {
  /** Checks for equality with the object’s `blueprintId` field. */
  blueprintId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectStageId` field. */
  projectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type BlueprintProjectStageDistinctCountAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `BlueprintProjectStage` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintProjectStageFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<BlueprintProjectStageFilter>>;
  /** Filter by the object’s `blueprint` relation. */
  blueprint: InputMaybe<BlueprintFilter>;
  /** A related `blueprint` exists. */
  blueprintExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintId` field. */
  blueprintId: InputMaybe<IntFilter>;
  /** Filter by the object’s `blueprintTasks` relation. */
  blueprintTasks: InputMaybe<BlueprintProjectStageToManyBlueprintTaskFilter>;
  /** Some related `blueprintTasks` exist. */
  blueprintTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<BlueprintProjectStageFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<BlueprintProjectStageFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectStage` relation. */
  projectStage: InputMaybe<ProjectStageFilter>;
  /** A related `projectStage` exists. */
  projectStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageId` field. */
  projectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintProjectStageMaxAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintProjectStageMinAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintProjectStageStddevPopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type BlueprintProjectStageStddevSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type BlueprintProjectStageSumAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintProjectStageToManyBlueprintTaskFilter = {
  /** Aggregates across related `BlueprintTask` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTaskAggregatesFilter>;
  /** Every related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTaskFilter>;
  /** No related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTaskFilter>;
  /** Some related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTaskFilter>;
};

export type BlueprintProjectStageVariancePopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

export type BlueprintProjectStageVarianceSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `BlueprintProjectStage`. */
export enum BlueprintProjectStagesOrderBy {
  BlueprintIdAsc = 'BLUEPRINT_ID_ASC',
  BlueprintIdDesc = 'BLUEPRINT_ID_DESC',
  BlueprintTasksConnectionArrayAggBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionArrayAggBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintTasksConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintTasksConnectionArrayAggCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintTasksConnectionArrayAggCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintTasksConnectionArrayAggIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintTasksConnectionArrayAggIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintTasksConnectionArrayAggSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_SLICE_ID_ASC',
  BlueprintTasksConnectionArrayAggSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_SLICE_ID_DESC',
  BlueprintTasksConnectionArrayAggTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  BlueprintTasksConnectionArrayAggTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  BlueprintTasksConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintTasksConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintTasksConnectionAverageBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionAverageBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionAverageCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintTasksConnectionAverageCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintTasksConnectionAverageCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintTasksConnectionAverageCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintTasksConnectionAverageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_ID_ASC',
  BlueprintTasksConnectionAverageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_ID_DESC',
  BlueprintTasksConnectionAverageSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_SLICE_ID_ASC',
  BlueprintTasksConnectionAverageSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_SLICE_ID_DESC',
  BlueprintTasksConnectionAverageTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_TASK_ID_ASC',
  BlueprintTasksConnectionAverageTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_TASK_ID_DESC',
  BlueprintTasksConnectionAverageUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintTasksConnectionAverageUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintTasksConnectionCountAsc = 'BLUEPRINT_TASKS_CONNECTION_COUNT_ASC',
  BlueprintTasksConnectionCountDesc = 'BLUEPRINT_TASKS_CONNECTION_COUNT_DESC',
  BlueprintTasksConnectionDistinctCountBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionDistinctCountBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintTasksConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintTasksConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintTasksConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintTasksConnectionDistinctCountIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintTasksConnectionDistinctCountIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintTasksConnectionDistinctCountSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_SLICE_ID_ASC',
  BlueprintTasksConnectionDistinctCountSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_SLICE_ID_DESC',
  BlueprintTasksConnectionDistinctCountTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  BlueprintTasksConnectionDistinctCountTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  BlueprintTasksConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintTasksConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintTasksConnectionMaxBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionMaxBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionMaxCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintTasksConnectionMaxCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintTasksConnectionMaxCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintTasksConnectionMaxCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintTasksConnectionMaxIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_ID_ASC',
  BlueprintTasksConnectionMaxIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_ID_DESC',
  BlueprintTasksConnectionMaxSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_SLICE_ID_ASC',
  BlueprintTasksConnectionMaxSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_SLICE_ID_DESC',
  BlueprintTasksConnectionMaxTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_TASK_ID_ASC',
  BlueprintTasksConnectionMaxTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_TASK_ID_DESC',
  BlueprintTasksConnectionMaxUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintTasksConnectionMaxUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintTasksConnectionMinBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionMinBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionMinCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintTasksConnectionMinCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintTasksConnectionMinCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintTasksConnectionMinCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintTasksConnectionMinIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_ID_ASC',
  BlueprintTasksConnectionMinIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_ID_DESC',
  BlueprintTasksConnectionMinSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_SLICE_ID_ASC',
  BlueprintTasksConnectionMinSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_SLICE_ID_DESC',
  BlueprintTasksConnectionMinTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_TASK_ID_ASC',
  BlueprintTasksConnectionMinTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_TASK_ID_DESC',
  BlueprintTasksConnectionMinUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintTasksConnectionMinUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintTasksConnectionStddevPopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionStddevPopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintTasksConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintTasksConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintTasksConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintTasksConnectionStddevPopulationIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintTasksConnectionStddevPopulationIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintTasksConnectionStddevPopulationSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_SLICE_ID_ASC',
  BlueprintTasksConnectionStddevPopulationSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_SLICE_ID_DESC',
  BlueprintTasksConnectionStddevPopulationTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  BlueprintTasksConnectionStddevPopulationTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  BlueprintTasksConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksConnectionStddevSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionStddevSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksConnectionStddevSampleIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintTasksConnectionStddevSampleIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintTasksConnectionStddevSampleSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksConnectionStddevSampleSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksConnectionStddevSampleTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  BlueprintTasksConnectionStddevSampleTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  BlueprintTasksConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintTasksConnectionSumBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionSumBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionSumCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintTasksConnectionSumCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintTasksConnectionSumCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintTasksConnectionSumCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintTasksConnectionSumIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_ID_ASC',
  BlueprintTasksConnectionSumIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_ID_DESC',
  BlueprintTasksConnectionSumSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_SLICE_ID_ASC',
  BlueprintTasksConnectionSumSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_SLICE_ID_DESC',
  BlueprintTasksConnectionSumTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_TASK_ID_ASC',
  BlueprintTasksConnectionSumTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_TASK_ID_DESC',
  BlueprintTasksConnectionSumUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintTasksConnectionSumUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintTasksConnectionVariancePopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionVariancePopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintTasksConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintTasksConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintTasksConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintTasksConnectionVariancePopulationIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintTasksConnectionVariancePopulationIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintTasksConnectionVariancePopulationSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_SLICE_ID_ASC',
  BlueprintTasksConnectionVariancePopulationSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_SLICE_ID_DESC',
  BlueprintTasksConnectionVariancePopulationTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  BlueprintTasksConnectionVariancePopulationTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  BlueprintTasksConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksConnectionVarianceSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksConnectionVarianceSampleIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  BlueprintTasksConnectionVarianceSampleTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  BlueprintTasksConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectStageIdAsc = 'PROJECT_STAGE_ID_ASC',
  ProjectStageIdDesc = 'PROJECT_STAGE_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `Project` values, with data from `Project`. */
export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type BlueprintProjectsByProjectBlueprintIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type BlueprintProjectsByProjectReportBlueprintIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectStageTimeline`. */
export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectStageTimeline`, and the cursor to aid in pagination. */
  edges: Array<BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectStageTimeline`. */
export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectId: Array<ProjectStageTimeline>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectIdConnection: ProjectStageTimelinesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyEdgeProjectStageTimelinesByProjectIdArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type BlueprintProjectsByProjectStageTimelineBlueprintIdAndProjectIdManyToManyEdgeProjectStageTimelinesByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type BlueprintStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type BlueprintStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type BlueprintSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type BlueprintTask = Node & {
  /** Reads a single `BlueprintProjectStage` that is related to this `BlueprintTask`. */
  blueprintProjectStage: Maybe<BlueprintProjectStage>;
  blueprintProjectStageId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `BlueprintTask`. */
  createdByUser: Maybe<User>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PrivilegedTask` that is related to this `BlueprintTask`. */
  privilegedTaskId: Maybe<PrivilegedTask>;
  /** Reads a single `BlueprintTimeslouse` that is related to this `BlueprintTask`. */
  slice: Maybe<BlueprintTimeslouse>;
  sliceId: Scalars['Int']['output'];
  /** Reads a single `Task` that is related to this `BlueprintTask`. */
  task: Maybe<Task>;
  taskId: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};

/** A filter to be used against aggregates of `BlueprintTask` object types. */
export type BlueprintTaskAggregatesFilter = {
  /** Array aggregation aggregate over matching `BlueprintTask` objects. */
  arrayAgg: InputMaybe<BlueprintTaskArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `BlueprintTask` objects. */
  average: InputMaybe<BlueprintTaskAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BlueprintTask` objects. */
  distinctCount: InputMaybe<BlueprintTaskDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BlueprintTask` object to be included within the aggregate. */
  filter: InputMaybe<BlueprintTaskFilter>;
  /** Maximum aggregate over matching `BlueprintTask` objects. */
  max: InputMaybe<BlueprintTaskMaxAggregateFilter>;
  /** Minimum aggregate over matching `BlueprintTask` objects. */
  min: InputMaybe<BlueprintTaskMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BlueprintTask` objects. */
  stddevPopulation: InputMaybe<BlueprintTaskStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BlueprintTask` objects. */
  stddevSample: InputMaybe<BlueprintTaskStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BlueprintTask` objects. */
  sum: InputMaybe<BlueprintTaskSumAggregateFilter>;
  /** Population variance aggregate over matching `BlueprintTask` objects. */
  variancePopulation: InputMaybe<BlueprintTaskVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BlueprintTask` objects. */
  varianceSample: InputMaybe<BlueprintTaskVarianceSampleAggregateFilter>;
};

export type BlueprintTaskArrayAggAggregateFilter = {
  blueprintProjectStageId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  sliceId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type BlueprintTaskAverageAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  sliceId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `BlueprintTask` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BlueprintTaskCondition = {
  /** Checks for equality with the object’s `blueprintProjectStageId` field. */
  blueprintProjectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `sliceId` field. */
  sliceId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type BlueprintTaskDistinctCountAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  sliceId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintTaskFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<BlueprintTaskFilter>>;
  /** Filter by the object’s `blueprintProjectStage` relation. */
  blueprintProjectStage: InputMaybe<BlueprintProjectStageFilter>;
  /** A related `blueprintProjectStage` exists. */
  blueprintProjectStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintProjectStageId` field. */
  blueprintProjectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<BlueprintTaskFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<BlueprintTaskFilter>>;
  /** Filter by the object’s `privilegedTaskId` relation. */
  privilegedTaskId: InputMaybe<PrivilegedTaskFilter>;
  /** A related `privilegedTaskId` exists. */
  privilegedTaskIdExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `slice` relation. */
  slice: InputMaybe<BlueprintTimeslouseFilter>;
  /** Filter by the object’s `sliceId` field. */
  sliceId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTaskMaxAggregateFilter = {
  blueprintProjectStageId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  sliceId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTaskMinAggregateFilter = {
  blueprintProjectStageId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  sliceId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTaskStddevPopulationAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  sliceId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type BlueprintTaskStddevSampleAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  sliceId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type BlueprintTaskSumAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  sliceId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type BlueprintTaskVariancePopulationAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  sliceId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type BlueprintTaskVarianceSampleAggregateFilter = {
  blueprintProjectStageId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  sliceId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `BlueprintTask`. */
export enum BlueprintTasksOrderBy {
  BlueprintProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SliceIdAsc = 'SLICE_ID_ASC',
  SliceIdDesc = 'SLICE_ID_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type BlueprintTeamsByProjectReportBlueprintIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** Methods to use when ordering `BlueprintTimeslouse`. */
export enum BlueprintTimesliceOrderBy {
  BlueprintIdAsc = 'BLUEPRINT_ID_ASC',
  BlueprintIdDesc = 'BLUEPRINT_ID_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionAverageBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionAverageBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionAverageCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionAverageCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionAverageCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionAverageCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionAverageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionAverageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionAverageSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionAverageSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionAverageTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionAverageTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionAverageUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionAverageUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionCountAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_COUNT_ASC',
  BlueprintTasksBySliceIdConnectionCountDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_COUNT_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionMaxBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionMaxBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionMaxCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionMaxCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionMaxCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionMaxCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionMaxIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_ID_ASC',
  BlueprintTasksBySliceIdConnectionMaxIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_ID_DESC',
  BlueprintTasksBySliceIdConnectionMaxSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionMaxSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionMaxTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionMaxTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionMaxUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionMaxUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionMinBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionMinBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionMinCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionMinCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionMinCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionMinCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionMinIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_ID_ASC',
  BlueprintTasksBySliceIdConnectionMinIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_ID_DESC',
  BlueprintTasksBySliceIdConnectionMinSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionMinSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionMinTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionMinTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionMinUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionMinUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionSumBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionSumBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionSumCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionSumCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionSumCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionSumCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionSumIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_ID_ASC',
  BlueprintTasksBySliceIdConnectionSumIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_ID_DESC',
  BlueprintTasksBySliceIdConnectionSumSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionSumSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionSumTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionSumTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionSumUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionSumUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleSliceIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleSliceIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleTaskIdAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleTaskIdDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  BlueprintTasksBySliceIdConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksBySliceIdConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_SLICE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type BlueprintTimeslouse = Node & {
  /** Reads a single `Blueprint` that is related to this `BlueprintTimeslouse`. */
  blueprint: Maybe<Blueprint>;
  blueprintId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `BlueprintTask`. */
  blueprintTasksBySliceId: Array<BlueprintTask>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type BlueprintTimeslouseBlueprintTasksBySliceIdArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};

/** A filter to be used against aggregates of `BlueprintTimeslouse` object types. */
export type BlueprintTimeslouseAggregatesFilter = {
  /** Array aggregation aggregate over matching `BlueprintTimeslouse` objects. */
  arrayAgg: InputMaybe<BlueprintTimeslouseArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `BlueprintTimeslouse` objects. */
  average: InputMaybe<BlueprintTimeslouseAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BlueprintTimeslouse` objects. */
  distinctCount: InputMaybe<BlueprintTimeslouseDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BlueprintTimeslouse` object to be included within the aggregate. */
  filter: InputMaybe<BlueprintTimeslouseFilter>;
  /** Maximum aggregate over matching `BlueprintTimeslouse` objects. */
  max: InputMaybe<BlueprintTimeslouseMaxAggregateFilter>;
  /** Minimum aggregate over matching `BlueprintTimeslouse` objects. */
  min: InputMaybe<BlueprintTimeslouseMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BlueprintTimeslouse` objects. */
  stddevPopulation: InputMaybe<BlueprintTimeslouseStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BlueprintTimeslouse` objects. */
  stddevSample: InputMaybe<BlueprintTimeslouseStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BlueprintTimeslouse` objects. */
  sum: InputMaybe<BlueprintTimeslouseSumAggregateFilter>;
  /** Population variance aggregate over matching `BlueprintTimeslouse` objects. */
  variancePopulation: InputMaybe<BlueprintTimeslouseVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BlueprintTimeslouse` objects. */
  varianceSample: InputMaybe<BlueprintTimeslouseVarianceSampleAggregateFilter>;
};

export type BlueprintTimeslouseArrayAggAggregateFilter = {
  blueprintId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
};

export type BlueprintTimeslouseAverageAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `BlueprintTimeslouse` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BlueprintTimeslouseCondition = {
  /** Checks for equality with the object’s `blueprintId` field. */
  blueprintId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type BlueprintTimeslouseDistinctCountAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `BlueprintTimeslouse` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintTimeslouseFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<BlueprintTimeslouseFilter>>;
  /** Filter by the object’s `blueprint` relation. */
  blueprint: InputMaybe<BlueprintFilter>;
  /** Filter by the object’s `blueprintId` field. */
  blueprintId: InputMaybe<IntFilter>;
  /** Filter by the object’s `blueprintTasksBySliceId` relation. */
  blueprintTasksBySliceId: InputMaybe<BlueprintTimeslouseToManyBlueprintTaskFilter>;
  /** Some related `blueprintTasksBySliceId` exist. */
  blueprintTasksBySliceIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<BlueprintTimeslouseFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<BlueprintTimeslouseFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTimeslouseMaxAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTimeslouseMinAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type BlueprintTimeslouseStddevPopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type BlueprintTimeslouseStddevSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type BlueprintTimeslouseSumAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintTimeslouseToManyBlueprintTaskFilter = {
  /** Aggregates across related `BlueprintTask` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTaskAggregatesFilter>;
  /** Every related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTaskFilter>;
  /** No related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTaskFilter>;
  /** Some related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTaskFilter>;
};

export type BlueprintTimeslouseVariancePopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type BlueprintTimeslouseVarianceSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

/** A filter to be used against many `BlueprintProjectStage` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintToManyBlueprintProjectStageFilter = {
  /** Aggregates across related `BlueprintProjectStage` match the filter criteria. */
  aggregates: InputMaybe<BlueprintProjectStageAggregatesFilter>;
  /** Every related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintProjectStageFilter>;
  /** No related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintProjectStageFilter>;
  /** Some related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintProjectStageFilter>;
};

/** A filter to be used against many `BlueprintTimeslouse` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintToManyBlueprintTimeslouseFilter = {
  /** Aggregates across related `BlueprintTimeslouse` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTimeslouseAggregatesFilter>;
  /** Every related `BlueprintTimeslouse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTimeslouseFilter>;
  /** No related `BlueprintTimeslouse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTimeslouseFilter>;
  /** Some related `BlueprintTimeslouse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTimeslouseFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `ProjectStageTimeline` object types. All fields are combined with a logical ‘and.’ */
export type BlueprintToManyProjectStageTimelineFilter = {
  /** Aggregates across related `ProjectStageTimeline` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageTimelineAggregatesFilter>;
  /** Every related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageTimelineFilter>;
  /** No related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageTimelineFilter>;
  /** Some related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageTimelineFilter>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type BlueprintUsersByProjectBlueprintIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type BlueprintUsersByProjectReportBlueprintIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type BlueprintVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type BlueprintVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Blueprint`. */
export enum BlueprintsOrderBy {
  BlueprintProjectStagesConnectionArrayAggBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionArrayAggCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionArrayAggCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionArrayAggIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_POSITION_ASC',
  BlueprintProjectStagesConnectionArrayAggPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_POSITION_DESC',
  BlueprintProjectStagesConnectionArrayAggProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionAverageBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionAverageBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionAverageCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionAverageCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionAverageCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionAverageCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionAverageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_ID_ASC',
  BlueprintProjectStagesConnectionAverageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_ID_DESC',
  BlueprintProjectStagesConnectionAveragePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_POSITION_ASC',
  BlueprintProjectStagesConnectionAveragePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_POSITION_DESC',
  BlueprintProjectStagesConnectionAverageProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionAverageProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionAverageUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionAverageUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionCountAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_COUNT_ASC',
  BlueprintProjectStagesConnectionCountDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_COUNT_DESC',
  BlueprintProjectStagesConnectionDistinctCountBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionDistinctCountIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  BlueprintProjectStagesConnectionDistinctCountPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  BlueprintProjectStagesConnectionDistinctCountProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionMaxBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionMaxBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionMaxCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionMaxCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionMaxCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionMaxCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionMaxIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_ID_ASC',
  BlueprintProjectStagesConnectionMaxIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_ID_DESC',
  BlueprintProjectStagesConnectionMaxPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_POSITION_ASC',
  BlueprintProjectStagesConnectionMaxPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_POSITION_DESC',
  BlueprintProjectStagesConnectionMaxProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionMaxProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionMaxUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionMaxUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionMinBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionMinBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionMinCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionMinCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionMinCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionMinCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionMinIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_ID_ASC',
  BlueprintProjectStagesConnectionMinIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_ID_DESC',
  BlueprintProjectStagesConnectionMinPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_POSITION_ASC',
  BlueprintProjectStagesConnectionMinPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_POSITION_DESC',
  BlueprintProjectStagesConnectionMinProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionMinProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionMinUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionMinUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevPopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionStddevPopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  BlueprintProjectStagesConnectionStddevPopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  BlueprintProjectStagesConnectionStddevPopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionStddevSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintProjectStagesConnectionStddevSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesConnectionStddevSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesConnectionStddevSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionSumBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionSumBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionSumCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionSumCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionSumCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionSumCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionSumIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_ID_ASC',
  BlueprintProjectStagesConnectionSumIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_ID_DESC',
  BlueprintProjectStagesConnectionSumPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_POSITION_ASC',
  BlueprintProjectStagesConnectionSumPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_POSITION_DESC',
  BlueprintProjectStagesConnectionSumProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionSumProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionSumUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionSumUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionVariancePopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionVariancePopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  BlueprintProjectStagesConnectionVariancePopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  BlueprintProjectStagesConnectionVariancePopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionVarianceSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionVarianceSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesConnectionVarianceSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesConnectionVarianceSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionArrayAggBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionArrayAggBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintTimesliceConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintTimesliceConnectionArrayAggIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintTimesliceConnectionArrayAggIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintTimesliceConnectionArrayAggPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_POSITION_ASC',
  BlueprintTimesliceConnectionArrayAggPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_POSITION_DESC',
  BlueprintTimesliceConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionAverageBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionAverageBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionAverageCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintTimesliceConnectionAverageCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintTimesliceConnectionAverageIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_ID_ASC',
  BlueprintTimesliceConnectionAverageIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_ID_DESC',
  BlueprintTimesliceConnectionAveragePositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_POSITION_ASC',
  BlueprintTimesliceConnectionAveragePositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_POSITION_DESC',
  BlueprintTimesliceConnectionAverageUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionAverageUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionCountAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_COUNT_ASC',
  BlueprintTimesliceConnectionCountDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_COUNT_DESC',
  BlueprintTimesliceConnectionDistinctCountBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionDistinctCountBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintTimesliceConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintTimesliceConnectionDistinctCountIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintTimesliceConnectionDistinctCountIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintTimesliceConnectionDistinctCountPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  BlueprintTimesliceConnectionDistinctCountPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  BlueprintTimesliceConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionMaxBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionMaxBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionMaxCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintTimesliceConnectionMaxCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintTimesliceConnectionMaxIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_ID_ASC',
  BlueprintTimesliceConnectionMaxIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_ID_DESC',
  BlueprintTimesliceConnectionMaxPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_POSITION_ASC',
  BlueprintTimesliceConnectionMaxPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_POSITION_DESC',
  BlueprintTimesliceConnectionMaxUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionMaxUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionMinBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionMinBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionMinCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintTimesliceConnectionMinCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintTimesliceConnectionMinIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_ID_ASC',
  BlueprintTimesliceConnectionMinIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_ID_DESC',
  BlueprintTimesliceConnectionMinPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_POSITION_ASC',
  BlueprintTimesliceConnectionMinPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_POSITION_DESC',
  BlueprintTimesliceConnectionMinUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionMinUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionStddevPopulationBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionStddevPopulationBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintTimesliceConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintTimesliceConnectionStddevPopulationIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintTimesliceConnectionStddevPopulationIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintTimesliceConnectionStddevPopulationPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  BlueprintTimesliceConnectionStddevPopulationPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  BlueprintTimesliceConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionStddevSampleBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionStddevSampleBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintTimesliceConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintTimesliceConnectionStddevSampleIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintTimesliceConnectionStddevSampleIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintTimesliceConnectionStddevSamplePositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  BlueprintTimesliceConnectionStddevSamplePositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  BlueprintTimesliceConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionSumBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionSumBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionSumCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintTimesliceConnectionSumCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintTimesliceConnectionSumIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_ID_ASC',
  BlueprintTimesliceConnectionSumIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_ID_DESC',
  BlueprintTimesliceConnectionSumPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_POSITION_ASC',
  BlueprintTimesliceConnectionSumPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_POSITION_DESC',
  BlueprintTimesliceConnectionSumUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionSumUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionVariancePopulationBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionVariancePopulationBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintTimesliceConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintTimesliceConnectionVariancePopulationIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintTimesliceConnectionVariancePopulationIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintTimesliceConnectionVariancePopulationPositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  BlueprintTimesliceConnectionVariancePopulationPositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  BlueprintTimesliceConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintTimesliceConnectionVarianceSampleBlueprintIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintTimesliceConnectionVarianceSampleBlueprintIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintTimesliceConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintTimesliceConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintTimesliceConnectionVarianceSampleIdAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintTimesliceConnectionVarianceSampleIdDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintTimesliceConnectionVarianceSamplePositionAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  BlueprintTimesliceConnectionVarianceSamplePositionDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  BlueprintTimesliceConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintTimesliceConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_TIMESLICE_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectsConnectionArrayAggAccountStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsConnectionArrayAggAccountStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsConnectionArrayAggAccountTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsConnectionArrayAggAccountTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsConnectionArrayAggAddressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsConnectionArrayAggAddressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionArrayAggAddressPrettyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsConnectionArrayAggAddressPrettyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsConnectionArrayAggAhjAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsConnectionArrayAggAhjDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsConnectionArrayAggBlueprintIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsConnectionArrayAggBlueprintIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsConnectionArrayAggCityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsConnectionArrayAggCityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsConnectionArrayAggCompanyIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsConnectionArrayAggCompanyIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsConnectionArrayAggCompletionDateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsConnectionArrayAggCompletionDateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsConnectionArrayAggCompletionTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsConnectionArrayAggCompletionTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionArrayAggConfidenceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsConnectionArrayAggConfidenceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsConnectionArrayAggCreatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsConnectionArrayAggCreatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsConnectionArrayAggCreatedByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggCreatedByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsConnectionArrayAggDealSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsConnectionArrayAggDealSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsConnectionArrayAggDealValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsConnectionArrayAggDealValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionArrayAggDescriptionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsConnectionArrayAggDescriptionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsConnectionArrayAggFreshProjectAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsConnectionArrayAggFreshProjectDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsConnectionArrayAggGeoLocationAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsConnectionArrayAggGeoLocationDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionArrayAggIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsConnectionArrayAggIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsConnectionArrayAggImageUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsConnectionArrayAggImageUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsConnectionArrayAggIsActiveAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsConnectionArrayAggIsActiveDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsConnectionArrayAggIsCompletedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsConnectionArrayAggIsCompletedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsConnectionArrayAggLastActivityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsConnectionArrayAggLastActivityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggOverdueByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsConnectionArrayAggOverdueByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsConnectionArrayAggOwnerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsConnectionArrayAggOwnerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsConnectionArrayAggParentProjectIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionArrayAggParentProjectIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionArrayAggPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsConnectionArrayAggPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionArrayAggProgressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsConnectionArrayAggProgressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionArrayAggProjectSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsConnectionArrayAggProjectSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsConnectionArrayAggProjectValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsConnectionArrayAggProjectValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggRequestStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsConnectionArrayAggRequestStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsConnectionArrayAggRevenueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsConnectionArrayAggRevenueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsConnectionArrayAggSalesRepIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsConnectionArrayAggSalesRepIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSourceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsConnectionArrayAggSourceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsConnectionArrayAggStageIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsConnectionArrayAggStageIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsConnectionArrayAggStateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsConnectionArrayAggStateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsConnectionArrayAggStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsConnectionArrayAggStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsConnectionArrayAggStreetAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsConnectionArrayAggStreetDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsConnectionArrayAggTitleAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsConnectionArrayAggTitleDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsConnectionArrayAggTotalCostAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsConnectionArrayAggTotalCostDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalPriceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsConnectionArrayAggTotalPriceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsConnectionArrayAggTradesAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsConnectionArrayAggTradesDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsConnectionArrayAggTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsConnectionArrayAggTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsConnectionArrayAggUidAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsConnectionArrayAggUidDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsConnectionArrayAggUpdatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsConnectionArrayAggUpdatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsConnectionArrayAggZipcodeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsConnectionArrayAggZipcodeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsConnectionAverageAccountStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionAverageAccountStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionAverageAccountTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionAverageAccountTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionAverageAddressAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsConnectionAverageAddressDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsConnectionAverageAddressNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionAverageAddressNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionAverageAddressPrettyAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionAverageAddressPrettyDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionAverageAhjAsc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsConnectionAverageAhjDesc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsConnectionAverageBlueprintIdAsc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsConnectionAverageBlueprintIdDesc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsConnectionAverageCityAsc = 'PROJECTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsConnectionAverageCityDesc = 'PROJECTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsConnectionAverageCompanyIdAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsConnectionAverageCompanyIdDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsConnectionAverageCompletionDateAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsConnectionAverageCompletionDateDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsConnectionAverageCompletionTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsConnectionAverageCompletionTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsConnectionAverageCompletionTimeInSAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionAverageCompletionTimeInSDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionAverageConfidenceAsc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsConnectionAverageConfidenceDesc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsConnectionAverageCreatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsConnectionAverageCreatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsConnectionAverageCreatedByAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsConnectionAverageCreatedByContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionAverageCreatedByContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionAverageCreatedByDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsConnectionAverageCreatedByTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionAverageCreatedByTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionAverageDealSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsConnectionAverageDealSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsConnectionAverageDealValueAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsConnectionAverageDealValueDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsConnectionAverageDealValueWeightedAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionAverageDealValueWeightedDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionAverageDescriptionAsc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsConnectionAverageDescriptionDesc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsConnectionAverageFreshProjectAsc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsConnectionAverageFreshProjectDesc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsConnectionAverageGeoLocationAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsConnectionAverageGeoLocationDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionAverageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectsConnectionAverageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectsConnectionAverageImageUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsConnectionAverageImageUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsConnectionAverageIsActiveAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsConnectionAverageIsActiveDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsConnectionAverageIsCompletedAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsConnectionAverageIsCompletedDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsConnectionAverageJurisdictionIdAsc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsConnectionAverageJurisdictionIdDesc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsConnectionAverageLastActivityAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsConnectionAverageLastActivityDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageOverdueByAsc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsConnectionAverageOverdueByDesc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsConnectionAverageOwnerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsConnectionAverageOwnerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsConnectionAverageParentProjectIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionAverageParentProjectIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionAveragePhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsConnectionAveragePhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsConnectionAveragePrimaryEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionAveragePrimaryEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionAveragePrimaryPhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsConnectionAveragePrimaryPhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsConnectionAveragePrivilegeAllAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionAveragePrivilegeAllDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionAveragePrivilegeOwnAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionAveragePrivilegeOwnDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionAveragePrivilegeTeamAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionAveragePrivilegeTeamDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionAverageProgressAsc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsConnectionAverageProgressDesc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsConnectionAverageProjectManagerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionAverageProjectManagerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionAverageProjectSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsConnectionAverageProjectSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsConnectionAverageProjectValueAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsConnectionAverageProjectValueDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsConnectionAverageReferrerContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionAverageReferrerContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionAverageRequestStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsConnectionAverageRequestStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsConnectionAverageRevenueAsc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsConnectionAverageRevenueDesc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsConnectionAverageSalesRepIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsConnectionAverageSalesRepIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSourceAsc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsConnectionAverageSourceDesc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsConnectionAverageStageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsConnectionAverageStageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsConnectionAverageStateAsc = 'PROJECTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsConnectionAverageStateDesc = 'PROJECTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsConnectionAverageStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsConnectionAverageStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsConnectionAverageStreetAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsConnectionAverageStreetDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsConnectionAverageStreetViewUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsConnectionAverageStreetViewUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsConnectionAverageTitleAsc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsConnectionAverageTitleDesc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsConnectionAverageTotalCostAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsConnectionAverageTotalCostDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalPriceAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsConnectionAverageTotalPriceDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsConnectionAverageTradesAsc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsConnectionAverageTradesDesc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsConnectionAverageTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsConnectionAverageTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsConnectionAverageUidAsc = 'PROJECTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectsConnectionAverageUidDesc = 'PROJECTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectsConnectionAverageUpdatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsConnectionAverageUpdatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsConnectionAverageZipcodeAsc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsConnectionAverageZipcodeDesc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsConnectionCountAsc = 'PROJECTS_CONNECTION_COUNT_ASC',
  ProjectsConnectionCountDesc = 'PROJECTS_CONNECTION_COUNT_DESC',
  ProjectsConnectionDistinctCountAccountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountAccountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountAccountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountAccountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountAddressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsConnectionDistinctCountAddressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsConnectionDistinctCountAhjAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsConnectionDistinctCountAhjDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsConnectionDistinctCountCityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsConnectionDistinctCountCityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsConnectionDistinctCountCompanyIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsConnectionDistinctCountCompanyIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsConnectionDistinctCountCompletionDateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsConnectionDistinctCountCompletionDateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionDistinctCountConfidenceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsConnectionDistinctCountConfidenceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsConnectionDistinctCountCreatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsConnectionDistinctCountCreatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsConnectionDistinctCountCreatedByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountCreatedByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsConnectionDistinctCountDealSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsConnectionDistinctCountDealSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsConnectionDistinctCountDealValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsConnectionDistinctCountDealValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionDistinctCountDescriptionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsConnectionDistinctCountDescriptionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsConnectionDistinctCountFreshProjectAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsConnectionDistinctCountFreshProjectDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsConnectionDistinctCountGeoLocationAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsConnectionDistinctCountGeoLocationDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionDistinctCountIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsConnectionDistinctCountIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsConnectionDistinctCountImageUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsConnectionDistinctCountImageUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsConnectionDistinctCountIsActiveAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsConnectionDistinctCountIsActiveDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsConnectionDistinctCountIsCompletedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsConnectionDistinctCountIsCompletedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsConnectionDistinctCountLastActivityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsConnectionDistinctCountLastActivityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountOverdueByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsConnectionDistinctCountOverdueByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsConnectionDistinctCountOwnerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsConnectionDistinctCountOwnerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionDistinctCountPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsConnectionDistinctCountPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionDistinctCountProgressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsConnectionDistinctCountProgressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionDistinctCountProjectSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsConnectionDistinctCountProjectSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsConnectionDistinctCountProjectValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsConnectionDistinctCountProjectValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountRequestStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsConnectionDistinctCountRequestStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsConnectionDistinctCountRevenueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsConnectionDistinctCountRevenueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSourceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsConnectionDistinctCountSourceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsConnectionDistinctCountStageIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsConnectionDistinctCountStageIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsConnectionDistinctCountStateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsConnectionDistinctCountStateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsConnectionDistinctCountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountStreetAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsConnectionDistinctCountStreetDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsConnectionDistinctCountTitleAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsConnectionDistinctCountTitleDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsConnectionDistinctCountTotalCostAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsConnectionDistinctCountTotalCostDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalPriceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsConnectionDistinctCountTotalPriceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsConnectionDistinctCountTradesAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsConnectionDistinctCountTradesDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsConnectionDistinctCountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountUidAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsConnectionDistinctCountUidDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsConnectionDistinctCountZipcodeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsConnectionDistinctCountZipcodeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsConnectionMaxAccountStatusAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMaxAccountStatusDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMaxAccountTypeAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMaxAccountTypeDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMaxAddressAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsConnectionMaxAddressDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsConnectionMaxAddressNormalizedAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMaxAddressNormalizedDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMaxAddressPrettyAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMaxAddressPrettyDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMaxAhjAsc = 'PROJECTS_CONNECTION_MAX_AHJ_ASC',
  ProjectsConnectionMaxAhjDesc = 'PROJECTS_CONNECTION_MAX_AHJ_DESC',
  ProjectsConnectionMaxBlueprintIdAsc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsConnectionMaxBlueprintIdDesc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsConnectionMaxCityAsc = 'PROJECTS_CONNECTION_MAX_CITY_ASC',
  ProjectsConnectionMaxCityDesc = 'PROJECTS_CONNECTION_MAX_CITY_DESC',
  ProjectsConnectionMaxCompanyIdAsc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsConnectionMaxCompanyIdDesc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsConnectionMaxCompletionDateAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsConnectionMaxCompletionDateDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsConnectionMaxCompletionTimeAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsConnectionMaxCompletionTimeDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsConnectionMaxCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMaxCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMaxConfidenceAsc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsConnectionMaxConfidenceDesc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsConnectionMaxCreatedAtAsc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsConnectionMaxCreatedAtDesc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsConnectionMaxCreatedByAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsConnectionMaxCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMaxCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMaxCreatedByDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsConnectionMaxCreatedByTypeAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMaxCreatedByTypeDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMaxDealSizeAsc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsConnectionMaxDealSizeDesc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsConnectionMaxDealValueAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsConnectionMaxDealValueDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsConnectionMaxDealValueWeightedAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMaxDealValueWeightedDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMaxDescriptionAsc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsConnectionMaxDescriptionDesc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsConnectionMaxFreshProjectAsc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsConnectionMaxFreshProjectDesc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsConnectionMaxGeoLocationAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsConnectionMaxGeoLocationDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMaxIdAsc = 'PROJECTS_CONNECTION_MAX_ID_ASC',
  ProjectsConnectionMaxIdDesc = 'PROJECTS_CONNECTION_MAX_ID_DESC',
  ProjectsConnectionMaxImageUrlAsc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsConnectionMaxImageUrlDesc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsConnectionMaxIsActiveAsc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsConnectionMaxIsActiveDesc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsConnectionMaxIsCompletedAsc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsConnectionMaxIsCompletedDesc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsConnectionMaxJurisdictionIdAsc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsConnectionMaxJurisdictionIdDesc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsConnectionMaxLastActivityAsc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsConnectionMaxLastActivityDesc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxOverdueByAsc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsConnectionMaxOverdueByDesc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsConnectionMaxOwnerIdAsc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsConnectionMaxOwnerIdDesc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsConnectionMaxParentProjectIdAsc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMaxParentProjectIdDesc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMaxPhoneAsc = 'PROJECTS_CONNECTION_MAX_PHONE_ASC',
  ProjectsConnectionMaxPhoneDesc = 'PROJECTS_CONNECTION_MAX_PHONE_DESC',
  ProjectsConnectionMaxPrimaryEmailAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMaxPrimaryEmailDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMaxPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsConnectionMaxPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsConnectionMaxPrivilegeAllAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMaxPrivilegeAllDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMaxPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMaxPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMaxPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMaxPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMaxProgressAsc = 'PROJECTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsConnectionMaxProgressDesc = 'PROJECTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsConnectionMaxProjectManagerIdAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMaxProjectManagerIdDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMaxProjectSizeAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsConnectionMaxProjectSizeDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsConnectionMaxProjectValueAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsConnectionMaxProjectValueDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsConnectionMaxReferrerContactIdAsc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMaxReferrerContactIdDesc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMaxRequestStatusAsc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsConnectionMaxRequestStatusDesc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsConnectionMaxRevenueAsc = 'PROJECTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectsConnectionMaxRevenueDesc = 'PROJECTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectsConnectionMaxSalesRepIdAsc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsConnectionMaxSalesRepIdDesc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSourceAsc = 'PROJECTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectsConnectionMaxSourceDesc = 'PROJECTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectsConnectionMaxStageIdAsc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsConnectionMaxStageIdDesc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsConnectionMaxStateAsc = 'PROJECTS_CONNECTION_MAX_STATE_ASC',
  ProjectsConnectionMaxStateDesc = 'PROJECTS_CONNECTION_MAX_STATE_DESC',
  ProjectsConnectionMaxStatusAsc = 'PROJECTS_CONNECTION_MAX_STATUS_ASC',
  ProjectsConnectionMaxStatusDesc = 'PROJECTS_CONNECTION_MAX_STATUS_DESC',
  ProjectsConnectionMaxStreetAsc = 'PROJECTS_CONNECTION_MAX_STREET_ASC',
  ProjectsConnectionMaxStreetDesc = 'PROJECTS_CONNECTION_MAX_STREET_DESC',
  ProjectsConnectionMaxStreetViewUrlAsc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsConnectionMaxStreetViewUrlDesc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsConnectionMaxTitleAsc = 'PROJECTS_CONNECTION_MAX_TITLE_ASC',
  ProjectsConnectionMaxTitleDesc = 'PROJECTS_CONNECTION_MAX_TITLE_DESC',
  ProjectsConnectionMaxTotalCostAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsConnectionMaxTotalCostDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalPriceAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsConnectionMaxTotalPriceDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsConnectionMaxTradesAsc = 'PROJECTS_CONNECTION_MAX_TRADES_ASC',
  ProjectsConnectionMaxTradesDesc = 'PROJECTS_CONNECTION_MAX_TRADES_DESC',
  ProjectsConnectionMaxTypeAsc = 'PROJECTS_CONNECTION_MAX_TYPE_ASC',
  ProjectsConnectionMaxTypeDesc = 'PROJECTS_CONNECTION_MAX_TYPE_DESC',
  ProjectsConnectionMaxUidAsc = 'PROJECTS_CONNECTION_MAX_UID_ASC',
  ProjectsConnectionMaxUidDesc = 'PROJECTS_CONNECTION_MAX_UID_DESC',
  ProjectsConnectionMaxUpdatedAtAsc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsConnectionMaxUpdatedAtDesc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsConnectionMaxZipcodeAsc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsConnectionMaxZipcodeDesc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsConnectionMinAccountStatusAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMinAccountStatusDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMinAccountTypeAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMinAccountTypeDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMinAddressAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsConnectionMinAddressDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsConnectionMinAddressNormalizedAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMinAddressNormalizedDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMinAddressPrettyAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMinAddressPrettyDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMinAhjAsc = 'PROJECTS_CONNECTION_MIN_AHJ_ASC',
  ProjectsConnectionMinAhjDesc = 'PROJECTS_CONNECTION_MIN_AHJ_DESC',
  ProjectsConnectionMinBlueprintIdAsc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsConnectionMinBlueprintIdDesc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsConnectionMinCityAsc = 'PROJECTS_CONNECTION_MIN_CITY_ASC',
  ProjectsConnectionMinCityDesc = 'PROJECTS_CONNECTION_MIN_CITY_DESC',
  ProjectsConnectionMinCompanyIdAsc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsConnectionMinCompanyIdDesc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsConnectionMinCompletionDateAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsConnectionMinCompletionDateDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsConnectionMinCompletionTimeAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsConnectionMinCompletionTimeDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsConnectionMinCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMinCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMinConfidenceAsc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsConnectionMinConfidenceDesc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsConnectionMinCreatedAtAsc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsConnectionMinCreatedAtDesc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsConnectionMinCreatedByAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsConnectionMinCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMinCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMinCreatedByDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsConnectionMinCreatedByTypeAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMinCreatedByTypeDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMinDealSizeAsc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsConnectionMinDealSizeDesc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsConnectionMinDealValueAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsConnectionMinDealValueDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsConnectionMinDealValueWeightedAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMinDealValueWeightedDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMinDescriptionAsc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsConnectionMinDescriptionDesc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsConnectionMinFreshProjectAsc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsConnectionMinFreshProjectDesc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsConnectionMinGeoLocationAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsConnectionMinGeoLocationDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsConnectionMinGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMinGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMinHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMinHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMinIdAsc = 'PROJECTS_CONNECTION_MIN_ID_ASC',
  ProjectsConnectionMinIdDesc = 'PROJECTS_CONNECTION_MIN_ID_DESC',
  ProjectsConnectionMinImageUrlAsc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsConnectionMinImageUrlDesc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsConnectionMinIsActiveAsc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsConnectionMinIsActiveDesc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsConnectionMinIsCompletedAsc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsConnectionMinIsCompletedDesc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsConnectionMinJurisdictionIdAsc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsConnectionMinJurisdictionIdDesc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsConnectionMinLastActivityAsc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsConnectionMinLastActivityDesc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinOverdueByAsc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsConnectionMinOverdueByDesc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsConnectionMinOwnerIdAsc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsConnectionMinOwnerIdDesc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsConnectionMinParentProjectIdAsc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMinParentProjectIdDesc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMinPhoneAsc = 'PROJECTS_CONNECTION_MIN_PHONE_ASC',
  ProjectsConnectionMinPhoneDesc = 'PROJECTS_CONNECTION_MIN_PHONE_DESC',
  ProjectsConnectionMinPrimaryEmailAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMinPrimaryEmailDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMinPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsConnectionMinPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsConnectionMinPrivilegeAllAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMinPrivilegeAllDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMinPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMinPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMinPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMinPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMinProgressAsc = 'PROJECTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsConnectionMinProgressDesc = 'PROJECTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsConnectionMinProjectManagerIdAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMinProjectManagerIdDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMinProjectSizeAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsConnectionMinProjectSizeDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsConnectionMinProjectValueAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsConnectionMinProjectValueDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsConnectionMinReferrerContactIdAsc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMinReferrerContactIdDesc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMinRequestStatusAsc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsConnectionMinRequestStatusDesc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsConnectionMinRevenueAsc = 'PROJECTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectsConnectionMinRevenueDesc = 'PROJECTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectsConnectionMinSalesRepIdAsc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsConnectionMinSalesRepIdDesc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSourceAsc = 'PROJECTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectsConnectionMinSourceDesc = 'PROJECTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectsConnectionMinStageIdAsc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsConnectionMinStageIdDesc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsConnectionMinStateAsc = 'PROJECTS_CONNECTION_MIN_STATE_ASC',
  ProjectsConnectionMinStateDesc = 'PROJECTS_CONNECTION_MIN_STATE_DESC',
  ProjectsConnectionMinStatusAsc = 'PROJECTS_CONNECTION_MIN_STATUS_ASC',
  ProjectsConnectionMinStatusDesc = 'PROJECTS_CONNECTION_MIN_STATUS_DESC',
  ProjectsConnectionMinStreetAsc = 'PROJECTS_CONNECTION_MIN_STREET_ASC',
  ProjectsConnectionMinStreetDesc = 'PROJECTS_CONNECTION_MIN_STREET_DESC',
  ProjectsConnectionMinStreetViewUrlAsc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsConnectionMinStreetViewUrlDesc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsConnectionMinTitleAsc = 'PROJECTS_CONNECTION_MIN_TITLE_ASC',
  ProjectsConnectionMinTitleDesc = 'PROJECTS_CONNECTION_MIN_TITLE_DESC',
  ProjectsConnectionMinTotalCostAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsConnectionMinTotalCostDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalPriceAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsConnectionMinTotalPriceDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsConnectionMinTradesAsc = 'PROJECTS_CONNECTION_MIN_TRADES_ASC',
  ProjectsConnectionMinTradesDesc = 'PROJECTS_CONNECTION_MIN_TRADES_DESC',
  ProjectsConnectionMinTypeAsc = 'PROJECTS_CONNECTION_MIN_TYPE_ASC',
  ProjectsConnectionMinTypeDesc = 'PROJECTS_CONNECTION_MIN_TYPE_DESC',
  ProjectsConnectionMinUidAsc = 'PROJECTS_CONNECTION_MIN_UID_ASC',
  ProjectsConnectionMinUidDesc = 'PROJECTS_CONNECTION_MIN_UID_DESC',
  ProjectsConnectionMinUpdatedAtAsc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsConnectionMinUpdatedAtDesc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsConnectionMinZipcodeAsc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsConnectionMinZipcodeDesc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevPopulationAddressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsConnectionStddevPopulationAddressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevPopulationAhjAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsConnectionStddevPopulationAhjDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevPopulationCityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsConnectionStddevPopulationCityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevPopulationConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionStddevPopulationConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionStddevPopulationCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevPopulationDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionStddevPopulationDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionStddevPopulationDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionStddevPopulationDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevPopulationDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionStddevPopulationDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevPopulationIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsConnectionStddevPopulationIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsConnectionStddevPopulationImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionStddevPopulationImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionStddevPopulationIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionStddevPopulationIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevPopulationLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevPopulationLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionStddevPopulationOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevPopulationPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsConnectionStddevPopulationPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevPopulationProgressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsConnectionStddevPopulationProgressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevPopulationProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevPopulationProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevPopulationRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsConnectionStddevPopulationRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSourceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsConnectionStddevPopulationSourceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsConnectionStddevPopulationStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionStddevPopulationStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionStddevPopulationStateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsConnectionStddevPopulationStateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsConnectionStddevPopulationStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsConnectionStddevPopulationStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsConnectionStddevPopulationStreetAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsConnectionStddevPopulationStreetDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevPopulationTitleAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsConnectionStddevPopulationTitleDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsConnectionStddevPopulationTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionStddevPopulationTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevPopulationTradesAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsConnectionStddevPopulationTradesDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsConnectionStddevPopulationTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsConnectionStddevPopulationTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsConnectionStddevPopulationUidAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsConnectionStddevPopulationUidDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionStddevPopulationZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionStddevPopulationZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionStddevSampleAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevSampleAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevSampleAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevSampleAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevSampleAddressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionStddevSampleAddressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevSampleAhjAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsConnectionStddevSampleAhjDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevSampleCityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsConnectionStddevSampleCityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsConnectionStddevSampleCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionStddevSampleCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionStddevSampleCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevSampleCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevSampleConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionStddevSampleConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionStddevSampleCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionStddevSampleCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionStddevSampleCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevSampleDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionStddevSampleDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionStddevSampleDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionStddevSampleDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevSampleDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionStddevSampleDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionStddevSampleFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevSampleFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevSampleGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionStddevSampleGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevSampleIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsConnectionStddevSampleIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsConnectionStddevSampleImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionStddevSampleImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionStddevSampleIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionStddevSampleIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionStddevSampleIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionStddevSampleIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevSampleLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevSampleLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionStddevSampleOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionStddevSampleOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionStddevSampleOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevSamplePhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsConnectionStddevSamplePhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevSampleProgressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionStddevSampleProgressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevSampleProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevSampleProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevSampleProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevSampleProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevSampleRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevSampleRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsConnectionStddevSampleRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSourceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsConnectionStddevSampleSourceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsConnectionStddevSampleStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionStddevSampleStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionStddevSampleStateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsConnectionStddevSampleStateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsConnectionStddevSampleStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsConnectionStddevSampleStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsConnectionStddevSampleStreetAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsConnectionStddevSampleStreetDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevSampleTitleAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsConnectionStddevSampleTitleDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsConnectionStddevSampleTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionStddevSampleTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevSampleTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevSampleTradesAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsConnectionStddevSampleTradesDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsConnectionStddevSampleTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsConnectionStddevSampleTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsConnectionStddevSampleUidAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsConnectionStddevSampleUidDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionStddevSampleZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionStddevSampleZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsConnectionSumAccountStatusAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsConnectionSumAccountStatusDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsConnectionSumAccountTypeAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsConnectionSumAccountTypeDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsConnectionSumAddressAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsConnectionSumAddressDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsConnectionSumAddressNormalizedAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionSumAddressNormalizedDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionSumAddressPrettyAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsConnectionSumAddressPrettyDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsConnectionSumAhjAsc = 'PROJECTS_CONNECTION_SUM_AHJ_ASC',
  ProjectsConnectionSumAhjDesc = 'PROJECTS_CONNECTION_SUM_AHJ_DESC',
  ProjectsConnectionSumBlueprintIdAsc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsConnectionSumBlueprintIdDesc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsConnectionSumCityAsc = 'PROJECTS_CONNECTION_SUM_CITY_ASC',
  ProjectsConnectionSumCityDesc = 'PROJECTS_CONNECTION_SUM_CITY_DESC',
  ProjectsConnectionSumCompanyIdAsc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsConnectionSumCompanyIdDesc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsConnectionSumCompletionDateAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsConnectionSumCompletionDateDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsConnectionSumCompletionTimeAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsConnectionSumCompletionTimeDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsConnectionSumCompletionTimeInSAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionSumCompletionTimeInSDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionSumConfidenceAsc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsConnectionSumConfidenceDesc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsConnectionSumCreatedAtAsc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsConnectionSumCreatedAtDesc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsConnectionSumCreatedByAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsConnectionSumCreatedByContactIdAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionSumCreatedByContactIdDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionSumCreatedByDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsConnectionSumCreatedByTypeAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsConnectionSumCreatedByTypeDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsConnectionSumDealSizeAsc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsConnectionSumDealSizeDesc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsConnectionSumDealValueAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsConnectionSumDealValueDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsConnectionSumDealValueWeightedAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionSumDealValueWeightedDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionSumDescriptionAsc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsConnectionSumDescriptionDesc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsConnectionSumFreshProjectAsc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsConnectionSumFreshProjectDesc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsConnectionSumGeoLocationAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsConnectionSumGeoLocationDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsConnectionSumGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionSumGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionSumHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionSumHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionSumIdAsc = 'PROJECTS_CONNECTION_SUM_ID_ASC',
  ProjectsConnectionSumIdDesc = 'PROJECTS_CONNECTION_SUM_ID_DESC',
  ProjectsConnectionSumImageUrlAsc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsConnectionSumImageUrlDesc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsConnectionSumIsActiveAsc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsConnectionSumIsActiveDesc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsConnectionSumIsCompletedAsc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsConnectionSumIsCompletedDesc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsConnectionSumJurisdictionIdAsc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsConnectionSumJurisdictionIdDesc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsConnectionSumLastActivityAsc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsConnectionSumLastActivityDesc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumOverdueByAsc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsConnectionSumOverdueByDesc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsConnectionSumOwnerIdAsc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsConnectionSumOwnerIdDesc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsConnectionSumParentProjectIdAsc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionSumParentProjectIdDesc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionSumPhoneAsc = 'PROJECTS_CONNECTION_SUM_PHONE_ASC',
  ProjectsConnectionSumPhoneDesc = 'PROJECTS_CONNECTION_SUM_PHONE_DESC',
  ProjectsConnectionSumPrimaryEmailAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsConnectionSumPrimaryEmailDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsConnectionSumPrimaryPhoneAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsConnectionSumPrimaryPhoneDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsConnectionSumPrivilegeAllAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsConnectionSumPrivilegeAllDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsConnectionSumPrivilegeOwnAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsConnectionSumPrivilegeOwnDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsConnectionSumPrivilegeTeamAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionSumPrivilegeTeamDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionSumProgressAsc = 'PROJECTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsConnectionSumProgressDesc = 'PROJECTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsConnectionSumProjectManagerIdAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionSumProjectManagerIdDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionSumProjectSizeAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsConnectionSumProjectSizeDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsConnectionSumProjectValueAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsConnectionSumProjectValueDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsConnectionSumReferrerContactIdAsc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionSumReferrerContactIdDesc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionSumRequestStatusAsc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsConnectionSumRequestStatusDesc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsConnectionSumRevenueAsc = 'PROJECTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectsConnectionSumRevenueDesc = 'PROJECTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectsConnectionSumSalesRepIdAsc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsConnectionSumSalesRepIdDesc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSourceAsc = 'PROJECTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectsConnectionSumSourceDesc = 'PROJECTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectsConnectionSumStageIdAsc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsConnectionSumStageIdDesc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsConnectionSumStateAsc = 'PROJECTS_CONNECTION_SUM_STATE_ASC',
  ProjectsConnectionSumStateDesc = 'PROJECTS_CONNECTION_SUM_STATE_DESC',
  ProjectsConnectionSumStatusAsc = 'PROJECTS_CONNECTION_SUM_STATUS_ASC',
  ProjectsConnectionSumStatusDesc = 'PROJECTS_CONNECTION_SUM_STATUS_DESC',
  ProjectsConnectionSumStreetAsc = 'PROJECTS_CONNECTION_SUM_STREET_ASC',
  ProjectsConnectionSumStreetDesc = 'PROJECTS_CONNECTION_SUM_STREET_DESC',
  ProjectsConnectionSumStreetViewUrlAsc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsConnectionSumStreetViewUrlDesc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsConnectionSumTitleAsc = 'PROJECTS_CONNECTION_SUM_TITLE_ASC',
  ProjectsConnectionSumTitleDesc = 'PROJECTS_CONNECTION_SUM_TITLE_DESC',
  ProjectsConnectionSumTotalCostAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsConnectionSumTotalCostDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalPriceAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsConnectionSumTotalPriceDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsConnectionSumTradesAsc = 'PROJECTS_CONNECTION_SUM_TRADES_ASC',
  ProjectsConnectionSumTradesDesc = 'PROJECTS_CONNECTION_SUM_TRADES_DESC',
  ProjectsConnectionSumTypeAsc = 'PROJECTS_CONNECTION_SUM_TYPE_ASC',
  ProjectsConnectionSumTypeDesc = 'PROJECTS_CONNECTION_SUM_TYPE_DESC',
  ProjectsConnectionSumUidAsc = 'PROJECTS_CONNECTION_SUM_UID_ASC',
  ProjectsConnectionSumUidDesc = 'PROJECTS_CONNECTION_SUM_UID_DESC',
  ProjectsConnectionSumUpdatedAtAsc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsConnectionSumUpdatedAtDesc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsConnectionSumZipcodeAsc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsConnectionSumZipcodeDesc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVariancePopulationAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsConnectionVariancePopulationAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVariancePopulationAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsConnectionVariancePopulationAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionVariancePopulationCityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsConnectionVariancePopulationCityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVariancePopulationConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionVariancePopulationConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionVariancePopulationCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVariancePopulationDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionVariancePopulationDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionVariancePopulationDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionVariancePopulationDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVariancePopulationDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionVariancePopulationDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVariancePopulationIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsConnectionVariancePopulationIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsConnectionVariancePopulationImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionVariancePopulationImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionVariancePopulationIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionVariancePopulationIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionVariancePopulationLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionVariancePopulationLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionVariancePopulationOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVariancePopulationPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsConnectionVariancePopulationPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVariancePopulationProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsConnectionVariancePopulationProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionVariancePopulationProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionVariancePopulationProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionVariancePopulationRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsConnectionVariancePopulationRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsConnectionVariancePopulationSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsConnectionVariancePopulationStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionVariancePopulationStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionVariancePopulationStateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsConnectionVariancePopulationStateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsConnectionVariancePopulationStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsConnectionVariancePopulationStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsConnectionVariancePopulationStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsConnectionVariancePopulationStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionVariancePopulationTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsConnectionVariancePopulationTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsConnectionVariancePopulationTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionVariancePopulationTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionVariancePopulationTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsConnectionVariancePopulationTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsConnectionVariancePopulationTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsConnectionVariancePopulationTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsConnectionVariancePopulationUidAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsConnectionVariancePopulationUidDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionVariancePopulationZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionVariancePopulationZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVarianceSampleAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionVarianceSampleAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVarianceSampleAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsConnectionVarianceSampleAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionVarianceSampleCityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsConnectionVarianceSampleCityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVarianceSampleConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionVarianceSampleConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionVarianceSampleCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVarianceSampleDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionVarianceSampleDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionVarianceSampleDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionVarianceSampleDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVarianceSampleDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionVarianceSampleDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVarianceSampleIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsConnectionVarianceSampleIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsConnectionVarianceSampleImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionVarianceSampleImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionVarianceSampleIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionVarianceSampleIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionVarianceSampleLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionVarianceSampleLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionVarianceSampleOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVarianceSamplePhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsConnectionVarianceSamplePhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVarianceSampleProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionVarianceSampleProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionVarianceSampleProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionVarianceSampleProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionVarianceSampleRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsConnectionVarianceSampleRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsConnectionVarianceSampleSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsConnectionVarianceSampleStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionVarianceSampleStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionVarianceSampleStateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsConnectionVarianceSampleStateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsConnectionVarianceSampleStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsConnectionVarianceSampleStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsConnectionVarianceSampleStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsConnectionVarianceSampleStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionVarianceSampleTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsConnectionVarianceSampleTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsConnectionVarianceSampleTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionVarianceSampleTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionVarianceSampleTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsConnectionVarianceSampleTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsConnectionVarianceSampleTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsConnectionVarianceSampleTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsConnectionVarianceSampleUidAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsConnectionVarianceSampleUidDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionVarianceSampleZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionVarianceSampleZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionArrayAggCityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsConnectionArrayAggCityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionArrayAggIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsConnectionArrayAggIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggStateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsConnectionArrayAggStateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsConnectionArrayAggUidAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsConnectionArrayAggUidDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionAverageAddressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsConnectionAverageAddressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionAverageAhjAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsConnectionAverageAhjDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionAverageCityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsConnectionAverageCityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsConnectionAverageDealValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsConnectionAverageDealValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionAverageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsConnectionAverageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionAveragePhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsConnectionAveragePhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionAverageProductNameAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionAverageProductNameDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionAverageProgressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsConnectionAverageProgressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionAverageRevenueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsConnectionAverageRevenueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSourceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsConnectionAverageSourceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsConnectionAverageStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageStateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsConnectionAverageStateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsConnectionAverageStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsConnectionAverageStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsConnectionAverageStreetAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsConnectionAverageStreetDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsConnectionAverageTextValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsConnectionAverageTextValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTitleAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsConnectionAverageTitleDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionAverageTradesAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsConnectionAverageTradesDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsConnectionAverageTradeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsConnectionAverageTradeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsConnectionAverageTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsConnectionAverageTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsConnectionAverageUidAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsConnectionAverageUidDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsConnectionAverageUserIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsConnectionAverageUserIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsConnectionCountAsc = 'PROJECT_REPORTS_CONNECTION_COUNT_ASC',
  ProjectReportsConnectionCountDesc = 'PROJECT_REPORTS_CONNECTION_COUNT_DESC',
  ProjectReportsConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMaxAddressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsConnectionMaxAddressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMaxAhjAsc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsConnectionMaxAhjDesc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMaxCityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_ASC',
  ProjectReportsConnectionMaxCityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_DESC',
  ProjectReportsConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsConnectionMaxDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsConnectionMaxDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMaxIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_ASC',
  ProjectReportsConnectionMaxIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_DESC',
  ProjectReportsConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsConnectionMaxPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMaxProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMaxProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMaxProgressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsConnectionMaxProgressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMaxRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsConnectionMaxRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSourceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsConnectionMaxSourceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsConnectionMaxStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsConnectionMaxStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsConnectionMaxStateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_ASC',
  ProjectReportsConnectionMaxStateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_DESC',
  ProjectReportsConnectionMaxStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsConnectionMaxStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsConnectionMaxStreetAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_ASC',
  ProjectReportsConnectionMaxStreetDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_DESC',
  ProjectReportsConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsConnectionMaxTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsConnectionMaxTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTitleAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsConnectionMaxTitleDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMaxTradesAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsConnectionMaxTradesDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsConnectionMaxTradeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsConnectionMaxTradeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsConnectionMaxTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsConnectionMaxTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsConnectionMaxUidAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_ASC',
  ProjectReportsConnectionMaxUidDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_DESC',
  ProjectReportsConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsConnectionMaxUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsConnectionMaxUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMinAddressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsConnectionMinAddressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMinAhjAsc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsConnectionMinAhjDesc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMinCityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_ASC',
  ProjectReportsConnectionMinCityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_DESC',
  ProjectReportsConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMinConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsConnectionMinConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsConnectionMinCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMinCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMinDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsConnectionMinDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsConnectionMinDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsConnectionMinDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMinDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsConnectionMinDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMinIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_ASC',
  ProjectReportsConnectionMinIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_DESC',
  ProjectReportsConnectionMinImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsConnectionMinImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsConnectionMinIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsConnectionMinIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMinLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMinLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMinNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMinOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsConnectionMinOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMinPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsConnectionMinPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMinProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMinProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMinProgressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsConnectionMinProgressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsConnectionMinProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsConnectionMinProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMinProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMinProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMinRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsConnectionMinRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSourceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsConnectionMinSourceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsConnectionMinStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsConnectionMinStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsConnectionMinStateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_ASC',
  ProjectReportsConnectionMinStateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_DESC',
  ProjectReportsConnectionMinStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsConnectionMinStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsConnectionMinStreetAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_ASC',
  ProjectReportsConnectionMinStreetDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_DESC',
  ProjectReportsConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMinTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsConnectionMinTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsConnectionMinTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsConnectionMinTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMinTitleAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsConnectionMinTitleDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsConnectionMinTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsConnectionMinTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMinTradesAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsConnectionMinTradesDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsConnectionMinTradeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsConnectionMinTradeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsConnectionMinTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsConnectionMinTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsConnectionMinUidAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_ASC',
  ProjectReportsConnectionMinUidDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_DESC',
  ProjectReportsConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsConnectionMinUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsConnectionMinUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsConnectionMinZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsConnectionMinZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionSumAddressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsConnectionSumAddressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionSumAhjAsc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsConnectionSumAhjDesc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionSumCityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_ASC',
  ProjectReportsConnectionSumCityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_DESC',
  ProjectReportsConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionSumConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsConnectionSumConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsConnectionSumCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionSumCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionSumDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsConnectionSumDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsConnectionSumDealValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsConnectionSumDealValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionSumDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsConnectionSumDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionSumIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_ASC',
  ProjectReportsConnectionSumIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_DESC',
  ProjectReportsConnectionSumImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsConnectionSumImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsConnectionSumIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsConnectionSumIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsConnectionSumLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionSumLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionSumNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionSumOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsConnectionSumOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionSumPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsConnectionSumPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionSumProductNameAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsConnectionSumProductNameDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsConnectionSumProgressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsConnectionSumProgressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsConnectionSumProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsConnectionSumProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsConnectionSumProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsConnectionSumProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsConnectionSumRevenueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsConnectionSumRevenueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSourceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsConnectionSumSourceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsConnectionSumStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsConnectionSumStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsConnectionSumStateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_ASC',
  ProjectReportsConnectionSumStateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_DESC',
  ProjectReportsConnectionSumStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsConnectionSumStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsConnectionSumStreetAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_ASC',
  ProjectReportsConnectionSumStreetDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_DESC',
  ProjectReportsConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionSumTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsConnectionSumTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsConnectionSumTextValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsConnectionSumTextValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionSumTitleAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsConnectionSumTitleDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsConnectionSumTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsConnectionSumTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsConnectionSumTradesAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsConnectionSumTradesDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsConnectionSumTradeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsConnectionSumTradeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsConnectionSumTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsConnectionSumTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsConnectionSumUidAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_ASC',
  ProjectReportsConnectionSumUidDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_DESC',
  ProjectReportsConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsConnectionSumUserIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsConnectionSumUserIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsConnectionSumZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsConnectionSumZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectStageTimelinesConnectionArrayAggBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionArrayAggBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionArrayAggCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_COUNT_ASC',
  ProjectStageTimelinesConnectionArrayAggCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_COUNT_DESC',
  ProjectStageTimelinesConnectionArrayAggEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionArrayAggEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionArrayAggIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionArrayAggIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionArrayAggSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionArrayAggSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionArrayAggStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionArrayAggStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionAverageBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionAverageBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionAverageCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_COUNT_ASC',
  ProjectStageTimelinesConnectionAverageCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_COUNT_DESC',
  ProjectStageTimelinesConnectionAverageEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionAverageEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionAverageIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionAverageIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionAverageProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionAverageProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionAverageSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionAverageSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionAverageStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionAverageStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionAverageStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionAverageStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_COUNT_ASC',
  ProjectStageTimelinesConnectionCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_COUNT_DESC',
  ProjectStageTimelinesConnectionDistinctCountBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionDistinctCountBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionDistinctCountCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  ProjectStageTimelinesConnectionDistinctCountCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  ProjectStageTimelinesConnectionDistinctCountEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionDistinctCountEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionDistinctCountIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionDistinctCountIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionDistinctCountSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionDistinctCountSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionDistinctCountStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionDistinctCountStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionMaxBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionMaxBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionMaxCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_COUNT_ASC',
  ProjectStageTimelinesConnectionMaxCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_COUNT_DESC',
  ProjectStageTimelinesConnectionMaxEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionMaxEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionMaxIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionMaxIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionMaxProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionMaxProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionMaxSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionMaxSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionMaxStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionMaxStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionMaxStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionMaxStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MAX_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionMinBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionMinBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionMinCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_COUNT_ASC',
  ProjectStageTimelinesConnectionMinCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_COUNT_DESC',
  ProjectStageTimelinesConnectionMinEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionMinEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionMinIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionMinIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionMinProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionMinProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionMinSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionMinSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionMinStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionMinStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionMinStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionMinStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_MIN_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionStddevPopulationCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  ProjectStageTimelinesConnectionStddevPopulationCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  ProjectStageTimelinesConnectionStddevPopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionStddevPopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionStddevPopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionStddevPopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionStddevPopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionStddevPopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionStddevPopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionStddevPopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionStddevSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionStddevSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionStddevSampleCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesConnectionStddevSampleCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesConnectionStddevSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionStddevSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionStddevSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionStddevSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionStddevSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionStddevSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionStddevSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionStddevSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionSumBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionSumBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionSumCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_COUNT_ASC',
  ProjectStageTimelinesConnectionSumCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_COUNT_DESC',
  ProjectStageTimelinesConnectionSumEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionSumEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionSumIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionSumIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionSumProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionSumProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionSumSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionSumSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionSumStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionSumStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionSumStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionSumStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_SUM_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionVariancePopulationCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  ProjectStageTimelinesConnectionVariancePopulationCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  ProjectStageTimelinesConnectionVariancePopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionVariancePopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionVariancePopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionVariancePopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionVariancePopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionVariancePopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionVariancePopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionVariancePopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesConnectionVarianceSampleCountAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesConnectionVarianceSampleCountDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesConnectionVarianceSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesConnectionVarianceSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesConnectionVarianceSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesConnectionVarianceSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesConnectionVarianceSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesConnectionVarianceSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesConnectionVarianceSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesConnectionVarianceSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** A filter to be used against Boolean List fields. All fields are combined with a logical ‘and.’ */
export type BooleanListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan: InputMaybe<Scalars['Boolean']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan: InputMaybe<Scalars['Boolean']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo: InputMaybe<Scalars['Boolean']['input']>;
  /** Contained by the specified list of values. */
  containedBy: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Contains the specified list of values. */
  contains: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
};

export type Call = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCallIdAndActionId: CallActionsByFeedCallIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCallIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedCallIdAndCommentId: CallCommentsByFeedCallIdAndCommentIdManyToManyConnection;
  /** Reads a single `Company` that is related to this `Call`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCallIdAndCreatedByContactId: CallContactsByFeedCallIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCallIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `Call`. */
  createdByUser: Maybe<MainUser>;
  disposition: Maybe<CallDisposition>;
  durationInMs: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedCallIdAndEmailMessageIdList: Array<EmailMessage>;
  endTime: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedCallIdAndParentId: CallFeedsByFeedCallIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCallIdAndFileId: CallFilesByFeedCallIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCallIdAndFileIdList: Array<File>;
  /** Reads a single `Contact` that is related to this `Call`. */
  fromContact: Maybe<Contact>;
  fromContactId: Maybe<Scalars['Int']['output']>;
  fromNumber: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isInbound: Maybe<Scalars['Boolean']['output']>;
  missedCount: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  note: Maybe<Scalars['String']['output']>;
  outcome: Maybe<CallOutcome>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedCallIdAndTaskId: CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyConnection;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCallIdAndProjectId: CallProjectsByFeedCallIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCallIdAndProjectIdList: Array<Project>;
  reason: Maybe<CallReason>;
  recordingUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCallIdAndReminderId: CallRemindersByFeedCallIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCallIdAndReminderIdList: Array<Reminder>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedCallIdAndSmsActivityIdList: Array<SmsActivity>;
  startTime: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedCallIdAndSystemId: CallSystemsByFeedCallIdAndSystemIdManyToManyConnection;
  /** Reads a single `Contact` that is related to this `Call`. */
  toContact: Maybe<Contact>;
  toContactId: Maybe<Scalars['Int']['output']>;
  toNumber: Maybe<Scalars['String']['output']>;
  transcriptUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedCallIdAndCreatedBy: CallUsersByFeedCallIdAndCreatedByManyToManyConnection;
};


export type CallActionsByFeedCallIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type CallActionsByFeedCallIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type CallCommentsByFeedCallIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type CallContactsByFeedCallIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type CallContactsByFeedCallIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type CallEmailMessagesByFeedCallIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type CallFeedsByFeedCallIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type CallFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type CallFilesByFeedCallIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type CallFilesByFeedCallIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type CallPrivilegedTasksByFeedCallIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CallProjectsByFeedCallIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CallProjectsByFeedCallIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CallRemindersByFeedCallIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type CallRemindersByFeedCallIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type CallSmsActivitiesByFeedCallIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type CallSystemsByFeedCallIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type CallUsersByFeedCallIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type CallActionsByFeedCallIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallActionsByFeedCallIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type CallActionsByFeedCallIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type CallActionsByFeedCallIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type CallActionsByFeedCallIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type CallAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CallArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CallAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CallDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CallMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CallMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CallStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CallStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CallSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CallVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CallVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Call` object types. */
export type CallAggregatesFilter = {
  /** Array aggregation aggregate over matching `Call` objects. */
  arrayAgg: InputMaybe<CallArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Call` objects. */
  average: InputMaybe<CallAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Call` objects. */
  distinctCount: InputMaybe<CallDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Call` object to be included within the aggregate. */
  filter: InputMaybe<CallFilter>;
  /** Maximum aggregate over matching `Call` objects. */
  max: InputMaybe<CallMaxAggregateFilter>;
  /** Minimum aggregate over matching `Call` objects. */
  min: InputMaybe<CallMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Call` objects. */
  stddevPopulation: InputMaybe<CallStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Call` objects. */
  stddevSample: InputMaybe<CallStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Call` objects. */
  sum: InputMaybe<CallSumAggregateFilter>;
  /** Population variance aggregate over matching `Call` objects. */
  variancePopulation: InputMaybe<CallVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Call` objects. */
  varianceSample: InputMaybe<CallVarianceSampleAggregateFilter>;
};

export type CallArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  durationInMs: InputMaybe<IntListFilter>;
  fromContactId: InputMaybe<IntListFilter>;
  fromNumber: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isInbound: InputMaybe<BooleanListFilter>;
  missedCount: InputMaybe<IntListFilter>;
  note: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
  recordingUrl: InputMaybe<StringListFilter>;
  toContactId: InputMaybe<IntListFilter>;
  toNumber: InputMaybe<StringListFilter>;
  transcriptUrl: InputMaybe<StringListFilter>;
};

export type CallArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of durationInMs across the matching connection */
  durationInMs: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of fromContactId across the matching connection */
  fromContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of fromNumber across the matching connection */
  fromNumber: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isInbound across the matching connection */
  isInbound: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of missedCount across the matching connection */
  missedCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of note across the matching connection */
  note: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of recordingUrl across the matching connection */
  recordingUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of toContactId across the matching connection */
  toContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of toNumber across the matching connection */
  toNumber: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of transcriptUrl across the matching connection */
  transcriptUrl: Array<Maybe<Scalars['String']['output']>>;
};


export type CallArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesFromNumberArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesIsInboundArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesNoteArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesRecordingUrlArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesToNumberArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallArrayAggAggregatesTranscriptUrlArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export type CallAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  durationInMs: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  missedCount: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
};

export type CallAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type CallAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallAverageAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type CallCommentsByFeedCallIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallCommentsByFeedCallIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type CallCommentsByFeedCallIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type CallCommentsByFeedCallIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type CallCommentsByFeedCallIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A condition to be used against `Call` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CallCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `disposition` field. */
  disposition: InputMaybe<CallDisposition>;
  /** Checks for equality with the object’s `durationInMs` field. */
  durationInMs: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `fromContactId` field. */
  fromContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `fromNumber` field. */
  fromNumber: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isInbound` field. */
  isInbound: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `missedCount` field. */
  missedCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `note` field. */
  note: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `outcome` field. */
  outcome: InputMaybe<CallOutcome>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `reason` field. */
  reason: InputMaybe<CallReason>;
  /** Checks for equality with the object’s `recordingUrl` field. */
  recordingUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `toContactId` field. */
  toContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `toNumber` field. */
  toNumber: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `transcriptUrl` field. */
  transcriptUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type CallContactsByFeedCallIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallContactsByFeedCallIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type CallContactsByFeedCallIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type CallContactsByFeedCallIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type CallContactsByFeedCallIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export enum CallDisposition {
  Escalation = 'ESCALATION',
  FollowUp = 'FOLLOW_UP',
  InfoProvided = 'INFO_PROVIDED',
  IssueResolved = 'ISSUE_RESOLVED',
  NoAction = 'NO_ACTION',
  Other = 'OTHER',
  Unresponsive = 'UNRESPONSIVE',
  VisitScheduled = 'VISIT_SCHEDULED'
}

/** A filter to be used against CallDisposition fields. All fields are combined with a logical ‘and.’ */
export type CallDispositionFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CallDisposition>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CallDisposition>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CallDisposition>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CallDisposition>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CallDisposition>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CallDisposition>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CallDisposition>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CallDisposition>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CallDisposition>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CallDisposition>>;
};

export type CallDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  disposition: InputMaybe<BigIntFilter>;
  durationInMs: InputMaybe<BigIntFilter>;
  endTime: InputMaybe<BigIntFilter>;
  fromContactId: InputMaybe<BigIntFilter>;
  fromNumber: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isInbound: InputMaybe<BigIntFilter>;
  missedCount: InputMaybe<BigIntFilter>;
  note: InputMaybe<BigIntFilter>;
  outcome: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  reason: InputMaybe<BigIntFilter>;
  recordingUrl: InputMaybe<BigIntFilter>;
  startTime: InputMaybe<BigIntFilter>;
  toContactId: InputMaybe<BigIntFilter>;
  toNumber: InputMaybe<BigIntFilter>;
  transcriptUrl: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CallDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of disposition across the matching connection */
  disposition: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endTime across the matching connection */
  endTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fromNumber across the matching connection */
  fromNumber: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isInbound across the matching connection */
  isInbound: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of note across the matching connection */
  note: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of outcome across the matching connection */
  outcome: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reason across the matching connection */
  reason: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of recordingUrl across the matching connection */
  recordingUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startTime across the matching connection */
  startTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of toNumber across the matching connection */
  toNumber: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of transcriptUrl across the matching connection */
  transcriptUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CallDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesDispositionArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesEndTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesFromNumberArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesIsInboundArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesNoteArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesOutcomeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesReasonArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesRecordingUrlArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesStartTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesToNumberArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesTranscriptUrlArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type CallFeedsByFeedCallIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallFeedsByFeedCallIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type CallFeedsByFeedCallIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type CallFeedsByFeedCallIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type CallFeedsByFeedCallIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type CallFilesByFeedCallIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallFilesByFeedCallIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type CallFilesByFeedCallIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type CallFilesByFeedCallIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type CallFilesByFeedCallIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `Call` object types. All fields are combined with a logical ‘and.’ */
export type CallFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CallFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `disposition` field. */
  disposition: InputMaybe<CallDispositionFilter>;
  /** Filter by the object’s `durationInMs` field. */
  durationInMs: InputMaybe<IntFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feeds` relation. */
  feeds: InputMaybe<CallToManyFeedFilter>;
  /** Some related `feeds` exist. */
  feedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromContact` relation. */
  fromContact: InputMaybe<ContactFilter>;
  /** A related `fromContact` exists. */
  fromContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromContactId` field. */
  fromContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `fromNumber` field. */
  fromNumber: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isInbound` field. */
  isInbound: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `missedCount` field. */
  missedCount: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<CallFilter>;
  /** Filter by the object’s `note` field. */
  note: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CallFilter>>;
  /** Filter by the object’s `outcome` field. */
  outcome: InputMaybe<CallOutcomeFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `reason` field. */
  reason: InputMaybe<CallReasonFilter>;
  /** Filter by the object’s `recordingUrl` field. */
  recordingUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `toContact` relation. */
  toContact: InputMaybe<ContactFilter>;
  /** A related `toContact` exists. */
  toContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `toContactId` field. */
  toContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `toNumber` field. */
  toNumber: InputMaybe<StringFilter>;
  /** Filter by the object’s `transcriptUrl` field. */
  transcriptUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Call` for usage during aggregation. */
export enum CallGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Disposition = 'DISPOSITION',
  DurationInMs = 'DURATION_IN_MS',
  EndTime = 'END_TIME',
  EndTimeTruncatedToDay = 'END_TIME_TRUNCATED_TO_DAY',
  EndTimeTruncatedToHour = 'END_TIME_TRUNCATED_TO_HOUR',
  EndTimeTruncatedToMonth = 'END_TIME_TRUNCATED_TO_MONTH',
  EndTimeTruncatedToQuarter = 'END_TIME_TRUNCATED_TO_QUARTER',
  EndTimeTruncatedToWeek = 'END_TIME_TRUNCATED_TO_WEEK',
  EndTimeTruncatedToYear = 'END_TIME_TRUNCATED_TO_YEAR',
  FromContactId = 'FROM_CONTACT_ID',
  FromNumber = 'FROM_NUMBER',
  IsInbound = 'IS_INBOUND',
  MissedCount = 'MISSED_COUNT',
  Note = 'NOTE',
  Outcome = 'OUTCOME',
  ProjectId = 'PROJECT_ID',
  Reason = 'REASON',
  RecordingUrl = 'RECORDING_URL',
  StartTime = 'START_TIME',
  StartTimeTruncatedToDay = 'START_TIME_TRUNCATED_TO_DAY',
  StartTimeTruncatedToHour = 'START_TIME_TRUNCATED_TO_HOUR',
  StartTimeTruncatedToMonth = 'START_TIME_TRUNCATED_TO_MONTH',
  StartTimeTruncatedToQuarter = 'START_TIME_TRUNCATED_TO_QUARTER',
  StartTimeTruncatedToWeek = 'START_TIME_TRUNCATED_TO_WEEK',
  StartTimeTruncatedToYear = 'START_TIME_TRUNCATED_TO_YEAR',
  ToContactId = 'TO_CONTACT_ID',
  ToNumber = 'TO_NUMBER',
  TranscriptUrl = 'TRANSCRIPT_URL',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CallHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Call` aggregates. */
export type CallHavingInput = {
  AND: InputMaybe<Array<CallHavingInput>>;
  OR: InputMaybe<Array<CallHavingInput>>;
  arrayAgg: InputMaybe<CallHavingArrayAggInput>;
  average: InputMaybe<CallHavingAverageInput>;
  distinctCount: InputMaybe<CallHavingDistinctCountInput>;
  max: InputMaybe<CallHavingMaxInput>;
  min: InputMaybe<CallHavingMinInput>;
  stddevPopulation: InputMaybe<CallHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CallHavingStddevSampleInput>;
  sum: InputMaybe<CallHavingSumInput>;
  variancePopulation: InputMaybe<CallHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CallHavingVarianceSampleInput>;
};

export type CallHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  durationInMs: InputMaybe<HavingIntFilter>;
  endTime: InputMaybe<HavingDatetimeFilter>;
  fromContactId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  missedCount: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  startTime: InputMaybe<HavingDatetimeFilter>;
  toContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CallMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  durationInMs: InputMaybe<IntFilter>;
  endTime: InputMaybe<DatetimeFilter>;
  fromContactId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  missedCount: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  startTime: InputMaybe<DatetimeFilter>;
  toContactId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CallMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['Int']['output']>;
  /** Maximum of endTime across the matching connection */
  endTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of missedCount across the matching connection */
  missedCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startTime across the matching connection */
  startTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of toContactId across the matching connection */
  toContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CallMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesEndTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesStartTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export type CallMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  durationInMs: InputMaybe<IntFilter>;
  endTime: InputMaybe<DatetimeFilter>;
  fromContactId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  missedCount: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  startTime: InputMaybe<DatetimeFilter>;
  toContactId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CallMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['Int']['output']>;
  /** Minimum of endTime across the matching connection */
  endTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of missedCount across the matching connection */
  missedCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startTime across the matching connection */
  startTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of toContactId across the matching connection */
  toContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CallMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesEndTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesStartTimeArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export enum CallOutcome {
  Answered = 'ANSWERED',
  Missed = 'MISSED'
}

/** A filter to be used against CallOutcome fields. All fields are combined with a logical ‘and.’ */
export type CallOutcomeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CallOutcome>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CallOutcome>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CallOutcome>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CallOutcome>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CallOutcome>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CallOutcome>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CallOutcome>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CallOutcome>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CallOutcome>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CallOutcome>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type CallPrivilegedTasksByFeedCallIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type CallProjectsByFeedCallIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallProjectsByFeedCallIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type CallProjectsByFeedCallIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type CallProjectsByFeedCallIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type CallProjectsByFeedCallIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export enum CallReason {
  Accounting = 'ACCOUNTING',
  Feedback = 'FEEDBACK',
  Other = 'OTHER',
  Process = 'PROCESS',
  Product = 'PRODUCT',
  RevenueOpportunity = 'REVENUE_OPPORTUNITY',
  Service = 'SERVICE'
}

/** A filter to be used against CallReason fields. All fields are combined with a logical ‘and.’ */
export type CallReasonFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CallReason>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CallReason>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CallReason>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CallReason>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CallReason>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CallReason>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CallReason>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CallReason>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CallReason>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CallReason>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type CallRemindersByFeedCallIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallRemindersByFeedCallIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type CallRemindersByFeedCallIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type CallRemindersByFeedCallIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type CallRemindersByFeedCallIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type CallStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  durationInMs: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  missedCount: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
};

export type CallStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type CallStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevPopulationAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export type CallStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  durationInMs: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  missedCount: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
};

export type CallStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type CallStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallStddevSampleAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export type CallSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  durationInMs: InputMaybe<BigIntFilter>;
  fromContactId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  missedCount: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  toContactId: InputMaybe<BigIntFilter>;
};

export type CallSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of durationInMs across the matching connection */
  durationInMs: Scalars['BigInt']['output'];
  /** Sum of fromContactId across the matching connection */
  fromContactId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of missedCount across the matching connection */
  missedCount: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of toContactId across the matching connection */
  toContactId: Scalars['BigInt']['output'];
};


export type CallSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallSumAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type CallSystemsByFeedCallIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallSystemsByFeedCallIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type CallSystemsByFeedCallIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type CallSystemsByFeedCallIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type CallSystemsByFeedCallIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type CallToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type CallUsersByFeedCallIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CallUsersByFeedCallIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type CallUsersByFeedCallIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type CallUsersByFeedCallIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type CallUsersByFeedCallIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type CallVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  durationInMs: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  missedCount: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
};

export type CallVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type CallVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVariancePopulationAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

export type CallVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  durationInMs: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  missedCount: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
};

export type CallVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of durationInMs across the matching connection */
  durationInMs: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of fromContactId across the matching connection */
  fromContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of missedCount across the matching connection */
  missedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of toContactId across the matching connection */
  toContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type CallVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesDurationInMsArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesFromContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesMissedCountArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CallVarianceSampleAggregatesToContactIdArgs = {
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};

/** A connection to a list of `Call` values. */
export type CallsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call` and cursor to aid in pagination. */
  edges: Array<CallsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values. */
export type CallsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection. */
export type CallsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};

/** Methods to use when ordering `Call`. */
export enum CallsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DispositionAsc = 'DISPOSITION_ASC',
  DispositionDesc = 'DISPOSITION_DESC',
  DurationInMsAsc = 'DURATION_IN_MS_ASC',
  DurationInMsDesc = 'DURATION_IN_MS_DESC',
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  FeedsConnectionArrayAggActionIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsConnectionArrayAggActionIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsConnectionArrayAggCallIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsConnectionArrayAggCallIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsConnectionArrayAggCommentAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsConnectionArrayAggCommentDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsConnectionArrayAggCommentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsConnectionArrayAggCommentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsConnectionArrayAggCompanyIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsConnectionArrayAggCompanyIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsConnectionArrayAggCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsConnectionArrayAggCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsConnectionArrayAggCreatedByAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionArrayAggCreatedByDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsConnectionArrayAggCreatedByTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsConnectionArrayAggCreatedByTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggEmailMessageIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionArrayAggEmailMessageIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionArrayAggEventAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsConnectionArrayAggEventDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsConnectionArrayAggEventTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsConnectionArrayAggEventTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsConnectionArrayAggFileIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsConnectionArrayAggFileIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsConnectionArrayAggIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsConnectionArrayAggIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsConnectionArrayAggParentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsConnectionArrayAggParentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsConnectionArrayAggPayloadAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsConnectionArrayAggPayloadDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsConnectionArrayAggProjectIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsConnectionArrayAggProjectIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsConnectionArrayAggReminderIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsConnectionArrayAggReminderIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsConnectionArrayAggRetriedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsConnectionArrayAggRetriedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsConnectionArrayAggRetryableAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsConnectionArrayAggRetryableDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsConnectionArrayAggRetryCountAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsConnectionArrayAggRetryCountDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsConnectionArrayAggSearchTextAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsConnectionArrayAggSearchTextDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsConnectionArrayAggSmsActivityIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionArrayAggSmsActivityIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionArrayAggSystemIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsConnectionArrayAggSystemIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsConnectionArrayAggTaskIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsConnectionArrayAggTaskIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionArrayAggWorkflowIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsConnectionArrayAggWorkflowIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsConnectionAverageActionIdAsc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsConnectionAverageActionIdDesc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsConnectionAverageCallIdAsc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsConnectionAverageCallIdDesc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsConnectionAverageCommentAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsConnectionAverageCommentDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsConnectionAverageCommentIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsConnectionAverageCommentIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsConnectionAverageCompanyIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsConnectionAverageCompanyIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsConnectionAverageCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsConnectionAverageCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsConnectionAverageCreatedByAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsConnectionAverageCreatedByContactIdAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionAverageCreatedByContactIdDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionAverageCreatedByDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsConnectionAverageCreatedByTypeAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsConnectionAverageCreatedByTypeDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsConnectionAverageEmailMessageIdAsc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionAverageEmailMessageIdDesc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionAverageEventAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsConnectionAverageEventDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsConnectionAverageEventTypeAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsConnectionAverageEventTypeDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsConnectionAverageFileIdAsc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsConnectionAverageFileIdDesc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsConnectionAverageIdAsc = 'FEEDS_CONNECTION_AVERAGE_ID_ASC',
  FeedsConnectionAverageIdDesc = 'FEEDS_CONNECTION_AVERAGE_ID_DESC',
  FeedsConnectionAverageParentIdAsc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsConnectionAverageParentIdDesc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsConnectionAveragePayloadAsc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsConnectionAveragePayloadDesc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsConnectionAverageProjectIdAsc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsConnectionAverageProjectIdDesc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsConnectionAverageReminderIdAsc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsConnectionAverageReminderIdDesc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsConnectionAverageRetriedAtAsc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsConnectionAverageRetriedAtDesc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsConnectionAverageRetryableAsc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsConnectionAverageRetryableDesc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsConnectionAverageRetryCountAsc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsConnectionAverageRetryCountDesc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsConnectionAverageSearchTextAsc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsConnectionAverageSearchTextDesc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsConnectionAverageSmsActivityIdAsc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionAverageSmsActivityIdDesc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionAverageSystemIdAsc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsConnectionAverageSystemIdDesc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsConnectionAverageTaskIdAsc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsConnectionAverageTaskIdDesc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsConnectionAverageVirtualCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionAverageVirtualCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionAverageWorkflowIdAsc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsConnectionAverageWorkflowIdDesc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsConnectionCountAsc = 'FEEDS_CONNECTION_COUNT_ASC',
  FeedsConnectionCountDesc = 'FEEDS_CONNECTION_COUNT_DESC',
  FeedsConnectionDistinctCountActionIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsConnectionDistinctCountActionIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsConnectionDistinctCountCallIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsConnectionDistinctCountCallIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsConnectionDistinctCountCommentAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsConnectionDistinctCountCommentDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsConnectionDistinctCountCommentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsConnectionDistinctCountCommentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsConnectionDistinctCountCompanyIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsConnectionDistinctCountCompanyIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsConnectionDistinctCountCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsConnectionDistinctCountCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsConnectionDistinctCountCreatedByAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionDistinctCountCreatedByDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionDistinctCountEventAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsConnectionDistinctCountEventDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsConnectionDistinctCountEventTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsConnectionDistinctCountEventTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsConnectionDistinctCountFileIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsConnectionDistinctCountFileIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsConnectionDistinctCountIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsConnectionDistinctCountIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsConnectionDistinctCountParentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsConnectionDistinctCountParentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsConnectionDistinctCountPayloadAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsConnectionDistinctCountPayloadDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsConnectionDistinctCountProjectIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsConnectionDistinctCountProjectIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsConnectionDistinctCountReminderIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsConnectionDistinctCountReminderIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsConnectionDistinctCountRetriedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsConnectionDistinctCountRetriedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsConnectionDistinctCountRetryableAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsConnectionDistinctCountRetryableDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsConnectionDistinctCountRetryCountAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsConnectionDistinctCountRetryCountDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsConnectionDistinctCountSearchTextAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsConnectionDistinctCountSearchTextDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionDistinctCountSystemIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsConnectionDistinctCountSystemIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsConnectionDistinctCountTaskIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsConnectionDistinctCountTaskIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionDistinctCountWorkflowIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsConnectionDistinctCountWorkflowIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsConnectionMaxActionIdAsc = 'FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsConnectionMaxActionIdDesc = 'FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsConnectionMaxCallIdAsc = 'FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  FeedsConnectionMaxCallIdDesc = 'FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  FeedsConnectionMaxCommentAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ASC',
  FeedsConnectionMaxCommentDesc = 'FEEDS_CONNECTION_MAX_COMMENT_DESC',
  FeedsConnectionMaxCommentIdAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsConnectionMaxCommentIdDesc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsConnectionMaxCompanyIdAsc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsConnectionMaxCompanyIdDesc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsConnectionMaxCreatedAtAsc = 'FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsConnectionMaxCreatedAtDesc = 'FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsConnectionMaxCreatedByAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsConnectionMaxCreatedByContactIdAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMaxCreatedByContactIdDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMaxCreatedByDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsConnectionMaxCreatedByTypeAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsConnectionMaxCreatedByTypeDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsConnectionMaxEmailMessageIdAsc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMaxEmailMessageIdDesc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMaxEventAsc = 'FEEDS_CONNECTION_MAX_EVENT_ASC',
  FeedsConnectionMaxEventDesc = 'FEEDS_CONNECTION_MAX_EVENT_DESC',
  FeedsConnectionMaxEventTypeAsc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsConnectionMaxEventTypeDesc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsConnectionMaxFileIdAsc = 'FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  FeedsConnectionMaxFileIdDesc = 'FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  FeedsConnectionMaxIdAsc = 'FEEDS_CONNECTION_MAX_ID_ASC',
  FeedsConnectionMaxIdDesc = 'FEEDS_CONNECTION_MAX_ID_DESC',
  FeedsConnectionMaxParentIdAsc = 'FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsConnectionMaxParentIdDesc = 'FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsConnectionMaxPayloadAsc = 'FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsConnectionMaxPayloadDesc = 'FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsConnectionMaxProjectIdAsc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsConnectionMaxProjectIdDesc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsConnectionMaxReminderIdAsc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsConnectionMaxReminderIdDesc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsConnectionMaxRetriedAtAsc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsConnectionMaxRetriedAtDesc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsConnectionMaxRetryableAsc = 'FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsConnectionMaxRetryableDesc = 'FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsConnectionMaxRetryCountAsc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsConnectionMaxRetryCountDesc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsConnectionMaxSearchTextAsc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsConnectionMaxSearchTextDesc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsConnectionMaxSmsActivityIdAsc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMaxSmsActivityIdDesc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMaxSystemIdAsc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsConnectionMaxSystemIdDesc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsConnectionMaxTaskIdAsc = 'FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  FeedsConnectionMaxTaskIdDesc = 'FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  FeedsConnectionMaxVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMaxVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMaxWorkflowIdAsc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsConnectionMaxWorkflowIdDesc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsConnectionMinActionIdAsc = 'FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsConnectionMinActionIdDesc = 'FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsConnectionMinCallIdAsc = 'FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  FeedsConnectionMinCallIdDesc = 'FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  FeedsConnectionMinCommentAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ASC',
  FeedsConnectionMinCommentDesc = 'FEEDS_CONNECTION_MIN_COMMENT_DESC',
  FeedsConnectionMinCommentIdAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsConnectionMinCommentIdDesc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsConnectionMinCompanyIdAsc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsConnectionMinCompanyIdDesc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsConnectionMinCreatedAtAsc = 'FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsConnectionMinCreatedAtDesc = 'FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsConnectionMinCreatedByAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsConnectionMinCreatedByContactIdAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMinCreatedByContactIdDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMinCreatedByDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsConnectionMinCreatedByTypeAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsConnectionMinCreatedByTypeDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsConnectionMinEmailMessageIdAsc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMinEmailMessageIdDesc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMinEventAsc = 'FEEDS_CONNECTION_MIN_EVENT_ASC',
  FeedsConnectionMinEventDesc = 'FEEDS_CONNECTION_MIN_EVENT_DESC',
  FeedsConnectionMinEventTypeAsc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsConnectionMinEventTypeDesc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsConnectionMinFileIdAsc = 'FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  FeedsConnectionMinFileIdDesc = 'FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  FeedsConnectionMinIdAsc = 'FEEDS_CONNECTION_MIN_ID_ASC',
  FeedsConnectionMinIdDesc = 'FEEDS_CONNECTION_MIN_ID_DESC',
  FeedsConnectionMinParentIdAsc = 'FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsConnectionMinParentIdDesc = 'FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsConnectionMinPayloadAsc = 'FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsConnectionMinPayloadDesc = 'FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsConnectionMinProjectIdAsc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsConnectionMinProjectIdDesc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsConnectionMinReminderIdAsc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsConnectionMinReminderIdDesc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsConnectionMinRetriedAtAsc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsConnectionMinRetriedAtDesc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsConnectionMinRetryableAsc = 'FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsConnectionMinRetryableDesc = 'FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsConnectionMinRetryCountAsc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsConnectionMinRetryCountDesc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsConnectionMinSearchTextAsc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsConnectionMinSearchTextDesc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsConnectionMinSmsActivityIdAsc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMinSmsActivityIdDesc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMinSystemIdAsc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsConnectionMinSystemIdDesc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsConnectionMinTaskIdAsc = 'FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  FeedsConnectionMinTaskIdDesc = 'FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  FeedsConnectionMinVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMinVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMinWorkflowIdAsc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsConnectionMinWorkflowIdDesc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsConnectionStddevPopulationActionIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsConnectionStddevPopulationActionIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsConnectionStddevPopulationCallIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsConnectionStddevPopulationCallIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsConnectionStddevPopulationCommentAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsConnectionStddevPopulationCommentDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsConnectionStddevPopulationCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionStddevPopulationCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionStddevPopulationCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionStddevPopulationCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionStddevPopulationCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevPopulationCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevPopulationEventAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsConnectionStddevPopulationEventDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsConnectionStddevPopulationEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionStddevPopulationEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionStddevPopulationFileIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsConnectionStddevPopulationFileIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsConnectionStddevPopulationIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsConnectionStddevPopulationIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsConnectionStddevPopulationParentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsConnectionStddevPopulationParentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsConnectionStddevPopulationPayloadAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsConnectionStddevPopulationPayloadDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsConnectionStddevPopulationProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionStddevPopulationProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionStddevPopulationReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionStddevPopulationReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionStddevPopulationRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionStddevPopulationRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionStddevPopulationRetryableAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsConnectionStddevPopulationRetryableDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsConnectionStddevPopulationRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionStddevPopulationRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionStddevPopulationSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionStddevPopulationSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevPopulationSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionStddevPopulationSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionStddevPopulationTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsConnectionStddevPopulationTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionStddevSampleActionIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionStddevSampleActionIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionStddevSampleCallIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsConnectionStddevSampleCallIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsConnectionStddevSampleCommentAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsConnectionStddevSampleCommentDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsConnectionStddevSampleCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionStddevSampleCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionStddevSampleCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionStddevSampleCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionStddevSampleCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionStddevSampleCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionStddevSampleCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevSampleCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevSampleEventAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsConnectionStddevSampleEventDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsConnectionStddevSampleEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionStddevSampleEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionStddevSampleFileIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsConnectionStddevSampleFileIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsConnectionStddevSampleIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsConnectionStddevSampleIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsConnectionStddevSampleParentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionStddevSampleParentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionStddevSamplePayloadAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionStddevSamplePayloadDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionStddevSampleProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionStddevSampleProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionStddevSampleReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionStddevSampleReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionStddevSampleRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionStddevSampleRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionStddevSampleRetryableAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionStddevSampleRetryableDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionStddevSampleRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionStddevSampleRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionStddevSampleSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionStddevSampleSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevSampleSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionStddevSampleSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionStddevSampleTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsConnectionStddevSampleTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevSampleWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionStddevSampleWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsConnectionSumActionIdAsc = 'FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsConnectionSumActionIdDesc = 'FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsConnectionSumCallIdAsc = 'FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  FeedsConnectionSumCallIdDesc = 'FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  FeedsConnectionSumCommentAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ASC',
  FeedsConnectionSumCommentDesc = 'FEEDS_CONNECTION_SUM_COMMENT_DESC',
  FeedsConnectionSumCommentIdAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsConnectionSumCommentIdDesc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsConnectionSumCompanyIdAsc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsConnectionSumCompanyIdDesc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsConnectionSumCreatedAtAsc = 'FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsConnectionSumCreatedAtDesc = 'FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsConnectionSumCreatedByAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsConnectionSumCreatedByContactIdAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionSumCreatedByContactIdDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionSumCreatedByDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsConnectionSumCreatedByTypeAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsConnectionSumCreatedByTypeDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsConnectionSumEmailMessageIdAsc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionSumEmailMessageIdDesc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionSumEventAsc = 'FEEDS_CONNECTION_SUM_EVENT_ASC',
  FeedsConnectionSumEventDesc = 'FEEDS_CONNECTION_SUM_EVENT_DESC',
  FeedsConnectionSumEventTypeAsc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsConnectionSumEventTypeDesc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsConnectionSumFileIdAsc = 'FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  FeedsConnectionSumFileIdDesc = 'FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  FeedsConnectionSumIdAsc = 'FEEDS_CONNECTION_SUM_ID_ASC',
  FeedsConnectionSumIdDesc = 'FEEDS_CONNECTION_SUM_ID_DESC',
  FeedsConnectionSumParentIdAsc = 'FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsConnectionSumParentIdDesc = 'FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsConnectionSumPayloadAsc = 'FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsConnectionSumPayloadDesc = 'FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsConnectionSumProjectIdAsc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsConnectionSumProjectIdDesc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsConnectionSumReminderIdAsc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsConnectionSumReminderIdDesc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsConnectionSumRetriedAtAsc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsConnectionSumRetriedAtDesc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsConnectionSumRetryableAsc = 'FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsConnectionSumRetryableDesc = 'FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsConnectionSumRetryCountAsc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsConnectionSumRetryCountDesc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsConnectionSumSearchTextAsc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsConnectionSumSearchTextDesc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsConnectionSumSmsActivityIdAsc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionSumSmsActivityIdDesc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionSumSystemIdAsc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsConnectionSumSystemIdDesc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsConnectionSumTaskIdAsc = 'FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  FeedsConnectionSumTaskIdDesc = 'FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  FeedsConnectionSumVirtualCreatedAtAsc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionSumVirtualCreatedAtDesc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionSumWorkflowIdAsc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsConnectionSumWorkflowIdDesc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsConnectionVariancePopulationActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsConnectionVariancePopulationActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsConnectionVariancePopulationCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsConnectionVariancePopulationCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsConnectionVariancePopulationCommentAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsConnectionVariancePopulationCommentDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsConnectionVariancePopulationCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionVariancePopulationCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionVariancePopulationCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionVariancePopulationCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionVariancePopulationCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVariancePopulationCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVariancePopulationEventAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsConnectionVariancePopulationEventDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsConnectionVariancePopulationEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionVariancePopulationEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionVariancePopulationFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsConnectionVariancePopulationFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsConnectionVariancePopulationIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsConnectionVariancePopulationIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsConnectionVariancePopulationParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsConnectionVariancePopulationParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsConnectionVariancePopulationPayloadAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsConnectionVariancePopulationPayloadDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsConnectionVariancePopulationProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionVariancePopulationProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionVariancePopulationReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionVariancePopulationReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionVariancePopulationRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionVariancePopulationRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionVariancePopulationRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsConnectionVariancePopulationRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsConnectionVariancePopulationRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionVariancePopulationRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionVariancePopulationSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionVariancePopulationSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVariancePopulationSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionVariancePopulationSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionVariancePopulationTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsConnectionVariancePopulationTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionVarianceSampleActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionVarianceSampleActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionVarianceSampleCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsConnectionVarianceSampleCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsConnectionVarianceSampleCommentAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsConnectionVarianceSampleCommentDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsConnectionVarianceSampleCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionVarianceSampleCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionVarianceSampleCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionVarianceSampleCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionVarianceSampleCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVarianceSampleCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVarianceSampleEventAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsConnectionVarianceSampleEventDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsConnectionVarianceSampleEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionVarianceSampleEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionVarianceSampleFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsConnectionVarianceSampleFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsConnectionVarianceSampleIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsConnectionVarianceSampleIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsConnectionVarianceSampleParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionVarianceSampleParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionVarianceSamplePayloadAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionVarianceSamplePayloadDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionVarianceSampleProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionVarianceSampleProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionVarianceSampleReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionVarianceSampleReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionVarianceSampleRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionVarianceSampleRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionVarianceSampleRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionVarianceSampleRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionVarianceSampleRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionVarianceSampleRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionVarianceSampleSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionVarianceSampleSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVarianceSampleSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionVarianceSampleSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionVarianceSampleTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsConnectionVarianceSampleTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FromContactIdAsc = 'FROM_CONTACT_ID_ASC',
  FromContactIdDesc = 'FROM_CONTACT_ID_DESC',
  FromNumberAsc = 'FROM_NUMBER_ASC',
  FromNumberDesc = 'FROM_NUMBER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsInboundAsc = 'IS_INBOUND_ASC',
  IsInboundDesc = 'IS_INBOUND_DESC',
  MissedCountAsc = 'MISSED_COUNT_ASC',
  MissedCountDesc = 'MISSED_COUNT_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  OutcomeAsc = 'OUTCOME_ASC',
  OutcomeDesc = 'OUTCOME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  RecordingUrlAsc = 'RECORDING_URL_ASC',
  RecordingUrlDesc = 'RECORDING_URL_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  ToContactIdAsc = 'TO_CONTACT_ID_ASC',
  ToContactIdDesc = 'TO_CONTACT_ID_DESC',
  ToNumberAsc = 'TO_NUMBER_ASC',
  ToNumberDesc = 'TO_NUMBER_DESC',
  TranscriptUrlAsc = 'TRANSCRIPT_URL_ASC',
  TranscriptUrlDesc = 'TRANSCRIPT_URL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CatalogItem = Node & {
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByMasterItemId: Array<CatalogItem>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByMasterItemIdConnection: CatalogItemsConnection;
  /** Reads a single `Dictionary` that is related to this `CatalogItem`. */
  category: Maybe<Dictionary>;
  categoryId: Maybe<Scalars['Int']['output']>;
  code: Maybe<Scalars['String']['output']>;
  /** Reads a single `Company` that is related to this `CatalogItem`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `CatalogItem`. */
  createdByUser: Maybe<MainUser>;
  datasheet: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Reads a single `File` that is related to this `CatalogItem`. */
  image: Maybe<File>;
  imageId: Maybe<Scalars['Int']['output']>;
  isArchived: Scalars['Boolean']['output'];
  isCustom: Scalars['Boolean']['output'];
  /** Reads and enables pagination through a set of `LineItem`. */
  lineItems: Array<LineItem>;
  manufacturer: Maybe<Scalars['String']['output']>;
  /** Reads a single `CatalogItem` that is related to this `CatalogItem`. */
  masterItem: Maybe<CatalogItem>;
  masterItemId: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  sku: Maybe<Scalars['String']['output']>;
  spec: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
};


export type CatalogItemCatalogItemsByMasterItemIdArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemCatalogItemsByMasterItemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemLineItemsArgs = {
  condition: InputMaybe<LineItemCondition>;
  filter: InputMaybe<LineItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LineItemsOrderBy>>;
};

export type CatalogItemAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CatalogItemArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CatalogItemAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CatalogItemDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CatalogItemMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CatalogItemMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CatalogItemStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CatalogItemStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CatalogItemSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CatalogItemVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CatalogItemVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `CatalogItem` object types. */
export type CatalogItemAggregatesFilter = {
  /** Array aggregation aggregate over matching `CatalogItem` objects. */
  arrayAgg: InputMaybe<CatalogItemArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CatalogItem` objects. */
  average: InputMaybe<CatalogItemAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CatalogItem` objects. */
  distinctCount: InputMaybe<CatalogItemDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CatalogItem` object to be included within the aggregate. */
  filter: InputMaybe<CatalogItemFilter>;
  /** Maximum aggregate over matching `CatalogItem` objects. */
  max: InputMaybe<CatalogItemMaxAggregateFilter>;
  /** Minimum aggregate over matching `CatalogItem` objects. */
  min: InputMaybe<CatalogItemMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CatalogItem` objects. */
  stddevPopulation: InputMaybe<CatalogItemStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CatalogItem` objects. */
  stddevSample: InputMaybe<CatalogItemStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CatalogItem` objects. */
  sum: InputMaybe<CatalogItemSumAggregateFilter>;
  /** Population variance aggregate over matching `CatalogItem` objects. */
  variancePopulation: InputMaybe<CatalogItemVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CatalogItem` objects. */
  varianceSample: InputMaybe<CatalogItemVarianceSampleAggregateFilter>;
};

export type CatalogItemArrayAggAggregateFilter = {
  categoryId: InputMaybe<IntListFilter>;
  code: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  cost: InputMaybe<FloatListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  datasheet: InputMaybe<StringListFilter>;
  description: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  imageId: InputMaybe<IntListFilter>;
  isArchived: InputMaybe<BooleanListFilter>;
  isCustom: InputMaybe<BooleanListFilter>;
  manufacturer: InputMaybe<StringListFilter>;
  masterItemId: InputMaybe<IntListFilter>;
  name: InputMaybe<StringListFilter>;
  price: InputMaybe<FloatListFilter>;
  sku: InputMaybe<StringListFilter>;
  type: InputMaybe<StringListFilter>;
};

export type CatalogItemArrayAggAggregates = {
  /** Array aggregation of categoryId across the matching connection */
  categoryId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of code across the matching connection */
  code: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of cost across the matching connection */
  cost: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of datasheet across the matching connection */
  datasheet: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of imageId across the matching connection */
  imageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isArchived across the matching connection */
  isArchived: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCustom across the matching connection */
  isCustom: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of manufacturer across the matching connection */
  manufacturer: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of masterItemId across the matching connection */
  masterItemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of price across the matching connection */
  price: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of sku across the matching connection */
  sku: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
};


export type CatalogItemArrayAggAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesCodeArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesDatasheetArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesIsCustomArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesManufacturerArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesSkuArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemAverageAggregateFilter = {
  categoryId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  imageId: InputMaybe<BigFloatFilter>;
  masterItemId: InputMaybe<BigFloatFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemAverageAggregates = {
  /** Mean average of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of imageId across the matching connection */
  imageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
};


export type CatalogItemAverageAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemAverageAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

/**
 * A condition to be used against `CatalogItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CatalogItemCondition = {
  /** Checks for equality with the object’s `categoryId` field. */
  categoryId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `code` field. */
  code: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `cost` field. */
  cost: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `datasheet` field. */
  datasheet: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `imageId` field. */
  imageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCustom` field. */
  isCustom: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `manufacturer` field. */
  manufacturer: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `masterItemId` field. */
  masterItemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `price` field. */
  price: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `sku` field. */
  sku: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `spec` field. */
  spec: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CatalogItemDistinctCountAggregateFilter = {
  categoryId: InputMaybe<BigIntFilter>;
  code: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  cost: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  datasheet: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  imageId: InputMaybe<BigIntFilter>;
  isArchived: InputMaybe<BigIntFilter>;
  isCustom: InputMaybe<BigIntFilter>;
  manufacturer: InputMaybe<BigIntFilter>;
  masterItemId: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  price: InputMaybe<BigIntFilter>;
  sku: InputMaybe<BigIntFilter>;
  spec: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CatalogItemDistinctCountAggregates = {
  /** Distinct count of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of code across the matching connection */
  code: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of cost across the matching connection */
  cost: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of datasheet across the matching connection */
  datasheet: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of imageId across the matching connection */
  imageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isArchived across the matching connection */
  isArchived: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCustom across the matching connection */
  isCustom: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of manufacturer across the matching connection */
  manufacturer: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of price across the matching connection */
  price: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sku across the matching connection */
  sku: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of spec across the matching connection */
  spec: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CatalogItemDistinctCountAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesCodeArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesDatasheetArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesIsCustomArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesManufacturerArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesSkuArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesSpecArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

/** A filter to be used against `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type CatalogItemFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CatalogItemFilter>>;
  /** Filter by the object’s `catalogItemsByMasterItemId` relation. */
  catalogItemsByMasterItemId: InputMaybe<CatalogItemToManyCatalogItemFilter>;
  /** Some related `catalogItemsByMasterItemId` exist. */
  catalogItemsByMasterItemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `category` relation. */
  category: InputMaybe<DictionaryFilter>;
  /** A related `category` exists. */
  categoryExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `categoryId` field. */
  categoryId: InputMaybe<IntFilter>;
  /** Filter by the object’s `code` field. */
  code: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `cost` field. */
  cost: InputMaybe<FloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `datasheet` field. */
  datasheet: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `image` relation. */
  image: InputMaybe<FileFilter>;
  /** A related `image` exists. */
  imageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `imageId` field. */
  imageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCustom` field. */
  isCustom: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lineItems` relation. */
  lineItems: InputMaybe<CatalogItemToManyLineItemFilter>;
  /** Some related `lineItems` exist. */
  lineItemsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `manufacturer` field. */
  manufacturer: InputMaybe<StringFilter>;
  /** Filter by the object’s `masterItem` relation. */
  masterItem: InputMaybe<CatalogItemFilter>;
  /** A related `masterItem` exists. */
  masterItemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `masterItemId` field. */
  masterItemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<CatalogItemFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CatalogItemFilter>>;
  /** Filter by the object’s `price` field. */
  price: InputMaybe<FloatFilter>;
  /** Filter by the object’s `sku` field. */
  sku: InputMaybe<StringFilter>;
  /** Filter by the object’s `spec` field. */
  spec: InputMaybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CatalogItem` for usage during aggregation. */
export enum CatalogItemGroupBy {
  CategoryId = 'CATEGORY_ID',
  Code = 'CODE',
  CompanyId = 'COMPANY_ID',
  Cost = 'COST',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Datasheet = 'DATASHEET',
  Description = 'DESCRIPTION',
  ImageId = 'IMAGE_ID',
  IsArchived = 'IS_ARCHIVED',
  IsCustom = 'IS_CUSTOM',
  Manufacturer = 'MANUFACTURER',
  MasterItemId = 'MASTER_ITEM_ID',
  Name = 'NAME',
  Price = 'PRICE',
  Sku = 'SKU',
  Spec = 'SPEC',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CatalogItemHavingArrayAggInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingAverageInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingDistinctCountInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CatalogItem` aggregates. */
export type CatalogItemHavingInput = {
  AND: InputMaybe<Array<CatalogItemHavingInput>>;
  OR: InputMaybe<Array<CatalogItemHavingInput>>;
  arrayAgg: InputMaybe<CatalogItemHavingArrayAggInput>;
  average: InputMaybe<CatalogItemHavingAverageInput>;
  distinctCount: InputMaybe<CatalogItemHavingDistinctCountInput>;
  max: InputMaybe<CatalogItemHavingMaxInput>;
  min: InputMaybe<CatalogItemHavingMinInput>;
  stddevPopulation: InputMaybe<CatalogItemHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CatalogItemHavingStddevSampleInput>;
  sum: InputMaybe<CatalogItemHavingSumInput>;
  variancePopulation: InputMaybe<CatalogItemHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CatalogItemHavingVarianceSampleInput>;
};

export type CatalogItemHavingMaxInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingMinInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingStddevPopulationInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingStddevSampleInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingSumInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingVariancePopulationInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemHavingVarianceSampleInput = {
  categoryId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  cost: InputMaybe<HavingFloatFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  imageId: InputMaybe<HavingIntFilter>;
  masterItemId: InputMaybe<HavingIntFilter>;
  price: InputMaybe<HavingFloatFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CatalogItemMaxAggregateFilter = {
  categoryId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  cost: InputMaybe<FloatFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  imageId: InputMaybe<IntFilter>;
  masterItemId: InputMaybe<IntFilter>;
  price: InputMaybe<FloatFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CatalogItemMaxAggregates = {
  /** Maximum of categoryId across the matching connection */
  categoryId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of imageId across the matching connection */
  imageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CatalogItemMaxAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemMinAggregateFilter = {
  categoryId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  cost: InputMaybe<FloatFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  imageId: InputMaybe<IntFilter>;
  masterItemId: InputMaybe<IntFilter>;
  price: InputMaybe<FloatFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CatalogItemMinAggregates = {
  /** Minimum of categoryId across the matching connection */
  categoryId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of imageId across the matching connection */
  imageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CatalogItemMinAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemStddevPopulationAggregateFilter = {
  categoryId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  imageId: InputMaybe<BigFloatFilter>;
  masterItemId: InputMaybe<BigFloatFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemStddevPopulationAggregates = {
  /** Population standard deviation of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of imageId across the matching connection */
  imageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
};


export type CatalogItemStddevPopulationAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevPopulationAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemStddevSampleAggregateFilter = {
  categoryId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  imageId: InputMaybe<BigFloatFilter>;
  masterItemId: InputMaybe<BigFloatFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemStddevSampleAggregates = {
  /** Sample standard deviation of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of imageId across the matching connection */
  imageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
};


export type CatalogItemStddevSampleAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemStddevSampleAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemSumAggregateFilter = {
  categoryId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  imageId: InputMaybe<BigIntFilter>;
  masterItemId: InputMaybe<BigIntFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemSumAggregates = {
  /** Sum of categoryId across the matching connection */
  categoryId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of cost across the matching connection */
  cost: Scalars['Float']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of imageId across the matching connection */
  imageId: Scalars['BigInt']['output'];
  /** Sum of masterItemId across the matching connection */
  masterItemId: Scalars['BigInt']['output'];
  /** Sum of price across the matching connection */
  price: Scalars['Float']['output'];
};


export type CatalogItemSumAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemSumAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

/** A filter to be used against many `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type CatalogItemToManyCatalogItemFilter = {
  /** Aggregates across related `CatalogItem` match the filter criteria. */
  aggregates: InputMaybe<CatalogItemAggregatesFilter>;
  /** Every related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CatalogItemFilter>;
  /** No related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CatalogItemFilter>;
  /** Some related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CatalogItemFilter>;
};

/** A filter to be used against many `LineItem` object types. All fields are combined with a logical ‘and.’ */
export type CatalogItemToManyLineItemFilter = {
  /** Aggregates across related `LineItem` match the filter criteria. */
  aggregates: InputMaybe<LineItemAggregatesFilter>;
  /** Every related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<LineItemFilter>;
  /** No related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<LineItemFilter>;
  /** Some related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<LineItemFilter>;
};

export type CatalogItemVariancePopulationAggregateFilter = {
  categoryId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  imageId: InputMaybe<BigFloatFilter>;
  masterItemId: InputMaybe<BigFloatFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemVariancePopulationAggregates = {
  /** Population variance of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of imageId across the matching connection */
  imageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
};


export type CatalogItemVariancePopulationAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVariancePopulationAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

export type CatalogItemVarianceSampleAggregateFilter = {
  categoryId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  cost: InputMaybe<FloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  imageId: InputMaybe<BigFloatFilter>;
  masterItemId: InputMaybe<BigFloatFilter>;
  price: InputMaybe<FloatFilter>;
};

export type CatalogItemVarianceSampleAggregates = {
  /** Sample variance of categoryId across the matching connection */
  categoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of cost across the matching connection */
  cost: Maybe<Scalars['Float']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of imageId across the matching connection */
  imageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of masterItemId across the matching connection */
  masterItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of price across the matching connection */
  price: Maybe<Scalars['Float']['output']>;
};


export type CatalogItemVarianceSampleAggregatesCategoryIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesCostArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesImageIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesMasterItemIdArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CatalogItemVarianceSampleAggregatesPriceArgs = {
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};

/** A connection to a list of `CatalogItem` values. */
export type CatalogItemsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CatalogItemAggregates>;
  /** A list of edges which contains the `CatalogItem` and cursor to aid in pagination. */
  edges: Array<CatalogItemsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CatalogItemAggregates>>;
  /** A list of `CatalogItem` objects. */
  nodes: Array<Maybe<CatalogItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CatalogItem` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CatalogItem` values. */
export type CatalogItemsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CatalogItemGroupBy>;
  having: InputMaybe<CatalogItemHavingInput>;
};

/** A `CatalogItem` edge in the connection. */
export type CatalogItemsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `CatalogItem` at the end of the edge. */
  node: Maybe<CatalogItem>;
};

/** Methods to use when ordering `CatalogItem`. */
export enum CatalogItemsOrderBy {
  CatalogItemsByMasterItemIdConnectionArrayAggCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_COST_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_COST_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionArrayAggUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionArrayAggUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_COST_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_COST_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionAverageCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionAverageCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionAverageDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionAverageDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionAverageDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionAverageDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionAverageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionAverageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionAverageImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionAverageImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionAverageIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionAverageIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionAverageIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionAverageIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionAverageManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionAverageManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionAverageMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionAverageMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionAverageNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionAverageNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionAveragePriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionAveragePriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionAverageSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionAverageSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionAverageSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionAverageSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionAverageTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionAverageTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionAverageUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionAverageUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionCountAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_COUNT_ASC',
  CatalogItemsByMasterItemIdConnectionCountDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_COUNT_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_COST_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_COST_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionDistinctCountUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionDistinctCountUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_COST_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_COST_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionMaxCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionMaxCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionMaxDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionMaxDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionMaxDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionMaxDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionMaxIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMaxIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMaxImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMaxImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMaxIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionMaxIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionMaxIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionMaxIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionMaxManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionMaxManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionMaxMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMaxMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMaxNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionMaxNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionMaxPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionMaxPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionMaxSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionMaxSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionMaxSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionMaxSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionMaxTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionMaxTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionMaxUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionMaxUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionMinCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMinCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMinCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionMinCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionMinCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMinCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMinCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_COST_ASC',
  CatalogItemsByMasterItemIdConnectionMinCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_COST_DESC',
  CatalogItemsByMasterItemIdConnectionMinCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionMinCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionMinCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionMinCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionMinDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionMinDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionMinDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionMinDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionMinIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMinIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMinImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMinImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMinIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionMinIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionMinIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionMinIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionMinManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionMinManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionMinMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionMinMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionMinNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionMinNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionMinPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionMinPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionMinSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionMinSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionMinSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionMinSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionMinTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionMinTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionMinUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionMinUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_COST_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_COST_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionStddevPopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_COST_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_COST_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSamplePriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSamplePriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionStddevSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionStddevSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionSumCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionSumCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionSumCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionSumCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionSumCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionSumCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionSumCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_COST_ASC',
  CatalogItemsByMasterItemIdConnectionSumCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_COST_DESC',
  CatalogItemsByMasterItemIdConnectionSumCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionSumCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionSumCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionSumCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionSumDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionSumDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionSumDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionSumDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionSumIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionSumIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionSumImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionSumImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionSumIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionSumIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionSumIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionSumIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionSumManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionSumManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionSumMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionSumMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionSumNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionSumNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionSumPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionSumPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionSumSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionSumSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionSumSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionSumSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionSumTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionSumTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionSumUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionSumUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_COST_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_COST_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationPriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationPriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionVariancePopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCodeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CODE_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCodeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CODE_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCostAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_COST_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCostDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_COST_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCreatedByAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleCreatedByDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleDatasheetAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleDatasheetDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleDescriptionAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleDescriptionDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleImageIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleImageIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIsCustomAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleIsCustomDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleManufacturerAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleManufacturerDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleNameAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleNameDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSamplePriceAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSamplePriceDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleSkuAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_SKU_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleSkuDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_SKU_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleSpecAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleSpecDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleTypeAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleTypeDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByMasterItemIdConnectionVarianceSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_MASTER_ITEM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CostAsc = 'COST_ASC',
  CostDesc = 'COST_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DatasheetAsc = 'DATASHEET_ASC',
  DatasheetDesc = 'DATASHEET_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageIdAsc = 'IMAGE_ID_ASC',
  ImageIdDesc = 'IMAGE_ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsCustomAsc = 'IS_CUSTOM_ASC',
  IsCustomDesc = 'IS_CUSTOM_DESC',
  LineItemsConnectionArrayAggCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionArrayAggCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionArrayAggCreatedAtAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  LineItemsConnectionArrayAggCreatedAtDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  LineItemsConnectionArrayAggCreatedByAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  LineItemsConnectionArrayAggCreatedByDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  LineItemsConnectionArrayAggDescriptionAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  LineItemsConnectionArrayAggDescriptionDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  LineItemsConnectionArrayAggIdAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_ID_ASC',
  LineItemsConnectionArrayAggIdDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_ID_DESC',
  LineItemsConnectionArrayAggProjectIdAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  LineItemsConnectionArrayAggProjectIdDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  LineItemsConnectionArrayAggQuantityAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_QUANTITY_ASC',
  LineItemsConnectionArrayAggQuantityDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_QUANTITY_DESC',
  LineItemsConnectionArrayAggRevenueAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  LineItemsConnectionArrayAggRevenueDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  LineItemsConnectionArrayAggTotalCostAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  LineItemsConnectionArrayAggTotalCostDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  LineItemsConnectionArrayAggTotalPriceAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  LineItemsConnectionArrayAggTotalPriceDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  LineItemsConnectionArrayAggUnitCostAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UNIT_COST_ASC',
  LineItemsConnectionArrayAggUnitCostDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UNIT_COST_DESC',
  LineItemsConnectionArrayAggUnitPriceAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UNIT_PRICE_ASC',
  LineItemsConnectionArrayAggUnitPriceDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UNIT_PRICE_DESC',
  LineItemsConnectionArrayAggUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  LineItemsConnectionArrayAggUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  LineItemsConnectionArrayAggUpdatedByAsc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_BY_ASC',
  LineItemsConnectionArrayAggUpdatedByDesc = 'LINE_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_BY_DESC',
  LineItemsConnectionAverageCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionAverageCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionAverageCreatedAtAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  LineItemsConnectionAverageCreatedAtDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  LineItemsConnectionAverageCreatedByAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  LineItemsConnectionAverageCreatedByDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  LineItemsConnectionAverageDescriptionAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  LineItemsConnectionAverageDescriptionDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  LineItemsConnectionAverageIdAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_ID_ASC',
  LineItemsConnectionAverageIdDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_ID_DESC',
  LineItemsConnectionAverageProjectIdAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  LineItemsConnectionAverageProjectIdDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  LineItemsConnectionAverageQuantityAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_QUANTITY_ASC',
  LineItemsConnectionAverageQuantityDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_QUANTITY_DESC',
  LineItemsConnectionAverageRevenueAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_REVENUE_ASC',
  LineItemsConnectionAverageRevenueDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_REVENUE_DESC',
  LineItemsConnectionAverageTotalCostAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  LineItemsConnectionAverageTotalCostDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  LineItemsConnectionAverageTotalPriceAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  LineItemsConnectionAverageTotalPriceDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  LineItemsConnectionAverageUnitCostAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_UNIT_COST_ASC',
  LineItemsConnectionAverageUnitCostDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_UNIT_COST_DESC',
  LineItemsConnectionAverageUnitPriceAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_UNIT_PRICE_ASC',
  LineItemsConnectionAverageUnitPriceDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_UNIT_PRICE_DESC',
  LineItemsConnectionAverageUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  LineItemsConnectionAverageUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  LineItemsConnectionAverageUpdatedByAsc = 'LINE_ITEMS_CONNECTION_AVERAGE_UPDATED_BY_ASC',
  LineItemsConnectionAverageUpdatedByDesc = 'LINE_ITEMS_CONNECTION_AVERAGE_UPDATED_BY_DESC',
  LineItemsConnectionCountAsc = 'LINE_ITEMS_CONNECTION_COUNT_ASC',
  LineItemsConnectionCountDesc = 'LINE_ITEMS_CONNECTION_COUNT_DESC',
  LineItemsConnectionDistinctCountCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionDistinctCountCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionDistinctCountCreatedAtAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  LineItemsConnectionDistinctCountCreatedAtDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  LineItemsConnectionDistinctCountCreatedByAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  LineItemsConnectionDistinctCountCreatedByDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  LineItemsConnectionDistinctCountDescriptionAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  LineItemsConnectionDistinctCountDescriptionDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  LineItemsConnectionDistinctCountIdAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  LineItemsConnectionDistinctCountIdDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  LineItemsConnectionDistinctCountProjectIdAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  LineItemsConnectionDistinctCountProjectIdDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  LineItemsConnectionDistinctCountQuantityAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_QUANTITY_ASC',
  LineItemsConnectionDistinctCountQuantityDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_QUANTITY_DESC',
  LineItemsConnectionDistinctCountRevenueAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  LineItemsConnectionDistinctCountRevenueDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  LineItemsConnectionDistinctCountTotalCostAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  LineItemsConnectionDistinctCountTotalCostDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  LineItemsConnectionDistinctCountTotalPriceAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  LineItemsConnectionDistinctCountTotalPriceDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  LineItemsConnectionDistinctCountUnitCostAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UNIT_COST_ASC',
  LineItemsConnectionDistinctCountUnitCostDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UNIT_COST_DESC',
  LineItemsConnectionDistinctCountUnitPriceAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_ASC',
  LineItemsConnectionDistinctCountUnitPriceDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_DESC',
  LineItemsConnectionDistinctCountUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  LineItemsConnectionDistinctCountUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  LineItemsConnectionDistinctCountUpdatedByAsc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_BY_ASC',
  LineItemsConnectionDistinctCountUpdatedByDesc = 'LINE_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_BY_DESC',
  LineItemsConnectionMaxCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_MAX_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionMaxCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_MAX_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionMaxCreatedAtAsc = 'LINE_ITEMS_CONNECTION_MAX_CREATED_AT_ASC',
  LineItemsConnectionMaxCreatedAtDesc = 'LINE_ITEMS_CONNECTION_MAX_CREATED_AT_DESC',
  LineItemsConnectionMaxCreatedByAsc = 'LINE_ITEMS_CONNECTION_MAX_CREATED_BY_ASC',
  LineItemsConnectionMaxCreatedByDesc = 'LINE_ITEMS_CONNECTION_MAX_CREATED_BY_DESC',
  LineItemsConnectionMaxDescriptionAsc = 'LINE_ITEMS_CONNECTION_MAX_DESCRIPTION_ASC',
  LineItemsConnectionMaxDescriptionDesc = 'LINE_ITEMS_CONNECTION_MAX_DESCRIPTION_DESC',
  LineItemsConnectionMaxIdAsc = 'LINE_ITEMS_CONNECTION_MAX_ID_ASC',
  LineItemsConnectionMaxIdDesc = 'LINE_ITEMS_CONNECTION_MAX_ID_DESC',
  LineItemsConnectionMaxProjectIdAsc = 'LINE_ITEMS_CONNECTION_MAX_PROJECT_ID_ASC',
  LineItemsConnectionMaxProjectIdDesc = 'LINE_ITEMS_CONNECTION_MAX_PROJECT_ID_DESC',
  LineItemsConnectionMaxQuantityAsc = 'LINE_ITEMS_CONNECTION_MAX_QUANTITY_ASC',
  LineItemsConnectionMaxQuantityDesc = 'LINE_ITEMS_CONNECTION_MAX_QUANTITY_DESC',
  LineItemsConnectionMaxRevenueAsc = 'LINE_ITEMS_CONNECTION_MAX_REVENUE_ASC',
  LineItemsConnectionMaxRevenueDesc = 'LINE_ITEMS_CONNECTION_MAX_REVENUE_DESC',
  LineItemsConnectionMaxTotalCostAsc = 'LINE_ITEMS_CONNECTION_MAX_TOTAL_COST_ASC',
  LineItemsConnectionMaxTotalCostDesc = 'LINE_ITEMS_CONNECTION_MAX_TOTAL_COST_DESC',
  LineItemsConnectionMaxTotalPriceAsc = 'LINE_ITEMS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  LineItemsConnectionMaxTotalPriceDesc = 'LINE_ITEMS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  LineItemsConnectionMaxUnitCostAsc = 'LINE_ITEMS_CONNECTION_MAX_UNIT_COST_ASC',
  LineItemsConnectionMaxUnitCostDesc = 'LINE_ITEMS_CONNECTION_MAX_UNIT_COST_DESC',
  LineItemsConnectionMaxUnitPriceAsc = 'LINE_ITEMS_CONNECTION_MAX_UNIT_PRICE_ASC',
  LineItemsConnectionMaxUnitPriceDesc = 'LINE_ITEMS_CONNECTION_MAX_UNIT_PRICE_DESC',
  LineItemsConnectionMaxUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_MAX_UPDATED_AT_ASC',
  LineItemsConnectionMaxUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_MAX_UPDATED_AT_DESC',
  LineItemsConnectionMaxUpdatedByAsc = 'LINE_ITEMS_CONNECTION_MAX_UPDATED_BY_ASC',
  LineItemsConnectionMaxUpdatedByDesc = 'LINE_ITEMS_CONNECTION_MAX_UPDATED_BY_DESC',
  LineItemsConnectionMinCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_MIN_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionMinCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_MIN_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionMinCreatedAtAsc = 'LINE_ITEMS_CONNECTION_MIN_CREATED_AT_ASC',
  LineItemsConnectionMinCreatedAtDesc = 'LINE_ITEMS_CONNECTION_MIN_CREATED_AT_DESC',
  LineItemsConnectionMinCreatedByAsc = 'LINE_ITEMS_CONNECTION_MIN_CREATED_BY_ASC',
  LineItemsConnectionMinCreatedByDesc = 'LINE_ITEMS_CONNECTION_MIN_CREATED_BY_DESC',
  LineItemsConnectionMinDescriptionAsc = 'LINE_ITEMS_CONNECTION_MIN_DESCRIPTION_ASC',
  LineItemsConnectionMinDescriptionDesc = 'LINE_ITEMS_CONNECTION_MIN_DESCRIPTION_DESC',
  LineItemsConnectionMinIdAsc = 'LINE_ITEMS_CONNECTION_MIN_ID_ASC',
  LineItemsConnectionMinIdDesc = 'LINE_ITEMS_CONNECTION_MIN_ID_DESC',
  LineItemsConnectionMinProjectIdAsc = 'LINE_ITEMS_CONNECTION_MIN_PROJECT_ID_ASC',
  LineItemsConnectionMinProjectIdDesc = 'LINE_ITEMS_CONNECTION_MIN_PROJECT_ID_DESC',
  LineItemsConnectionMinQuantityAsc = 'LINE_ITEMS_CONNECTION_MIN_QUANTITY_ASC',
  LineItemsConnectionMinQuantityDesc = 'LINE_ITEMS_CONNECTION_MIN_QUANTITY_DESC',
  LineItemsConnectionMinRevenueAsc = 'LINE_ITEMS_CONNECTION_MIN_REVENUE_ASC',
  LineItemsConnectionMinRevenueDesc = 'LINE_ITEMS_CONNECTION_MIN_REVENUE_DESC',
  LineItemsConnectionMinTotalCostAsc = 'LINE_ITEMS_CONNECTION_MIN_TOTAL_COST_ASC',
  LineItemsConnectionMinTotalCostDesc = 'LINE_ITEMS_CONNECTION_MIN_TOTAL_COST_DESC',
  LineItemsConnectionMinTotalPriceAsc = 'LINE_ITEMS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  LineItemsConnectionMinTotalPriceDesc = 'LINE_ITEMS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  LineItemsConnectionMinUnitCostAsc = 'LINE_ITEMS_CONNECTION_MIN_UNIT_COST_ASC',
  LineItemsConnectionMinUnitCostDesc = 'LINE_ITEMS_CONNECTION_MIN_UNIT_COST_DESC',
  LineItemsConnectionMinUnitPriceAsc = 'LINE_ITEMS_CONNECTION_MIN_UNIT_PRICE_ASC',
  LineItemsConnectionMinUnitPriceDesc = 'LINE_ITEMS_CONNECTION_MIN_UNIT_PRICE_DESC',
  LineItemsConnectionMinUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_MIN_UPDATED_AT_ASC',
  LineItemsConnectionMinUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_MIN_UPDATED_AT_DESC',
  LineItemsConnectionMinUpdatedByAsc = 'LINE_ITEMS_CONNECTION_MIN_UPDATED_BY_ASC',
  LineItemsConnectionMinUpdatedByDesc = 'LINE_ITEMS_CONNECTION_MIN_UPDATED_BY_DESC',
  LineItemsConnectionStddevPopulationCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionStddevPopulationCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionStddevPopulationCreatedAtAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  LineItemsConnectionStddevPopulationCreatedAtDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  LineItemsConnectionStddevPopulationCreatedByAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  LineItemsConnectionStddevPopulationCreatedByDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  LineItemsConnectionStddevPopulationDescriptionAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  LineItemsConnectionStddevPopulationDescriptionDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  LineItemsConnectionStddevPopulationIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  LineItemsConnectionStddevPopulationIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  LineItemsConnectionStddevPopulationProjectIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  LineItemsConnectionStddevPopulationProjectIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  LineItemsConnectionStddevPopulationQuantityAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_QUANTITY_ASC',
  LineItemsConnectionStddevPopulationQuantityDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_QUANTITY_DESC',
  LineItemsConnectionStddevPopulationRevenueAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  LineItemsConnectionStddevPopulationRevenueDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  LineItemsConnectionStddevPopulationTotalCostAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  LineItemsConnectionStddevPopulationTotalCostDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  LineItemsConnectionStddevPopulationTotalPriceAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  LineItemsConnectionStddevPopulationTotalPriceDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  LineItemsConnectionStddevPopulationUnitCostAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UNIT_COST_ASC',
  LineItemsConnectionStddevPopulationUnitCostDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UNIT_COST_DESC',
  LineItemsConnectionStddevPopulationUnitPriceAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_ASC',
  LineItemsConnectionStddevPopulationUnitPriceDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_DESC',
  LineItemsConnectionStddevPopulationUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  LineItemsConnectionStddevPopulationUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  LineItemsConnectionStddevPopulationUpdatedByAsc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_BY_ASC',
  LineItemsConnectionStddevPopulationUpdatedByDesc = 'LINE_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_BY_DESC',
  LineItemsConnectionStddevSampleCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionStddevSampleCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionStddevSampleCreatedAtAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  LineItemsConnectionStddevSampleCreatedAtDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  LineItemsConnectionStddevSampleCreatedByAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  LineItemsConnectionStddevSampleCreatedByDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  LineItemsConnectionStddevSampleDescriptionAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  LineItemsConnectionStddevSampleDescriptionDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  LineItemsConnectionStddevSampleIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  LineItemsConnectionStddevSampleIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  LineItemsConnectionStddevSampleProjectIdAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  LineItemsConnectionStddevSampleProjectIdDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  LineItemsConnectionStddevSampleQuantityAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_QUANTITY_ASC',
  LineItemsConnectionStddevSampleQuantityDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_QUANTITY_DESC',
  LineItemsConnectionStddevSampleRevenueAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  LineItemsConnectionStddevSampleRevenueDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  LineItemsConnectionStddevSampleTotalCostAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  LineItemsConnectionStddevSampleTotalCostDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  LineItemsConnectionStddevSampleTotalPriceAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsConnectionStddevSampleTotalPriceDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsConnectionStddevSampleUnitCostAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UNIT_COST_ASC',
  LineItemsConnectionStddevSampleUnitCostDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UNIT_COST_DESC',
  LineItemsConnectionStddevSampleUnitPriceAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_ASC',
  LineItemsConnectionStddevSampleUnitPriceDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_DESC',
  LineItemsConnectionStddevSampleUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LineItemsConnectionStddevSampleUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LineItemsConnectionStddevSampleUpdatedByAsc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_ASC',
  LineItemsConnectionStddevSampleUpdatedByDesc = 'LINE_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_DESC',
  LineItemsConnectionSumCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_SUM_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionSumCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_SUM_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionSumCreatedAtAsc = 'LINE_ITEMS_CONNECTION_SUM_CREATED_AT_ASC',
  LineItemsConnectionSumCreatedAtDesc = 'LINE_ITEMS_CONNECTION_SUM_CREATED_AT_DESC',
  LineItemsConnectionSumCreatedByAsc = 'LINE_ITEMS_CONNECTION_SUM_CREATED_BY_ASC',
  LineItemsConnectionSumCreatedByDesc = 'LINE_ITEMS_CONNECTION_SUM_CREATED_BY_DESC',
  LineItemsConnectionSumDescriptionAsc = 'LINE_ITEMS_CONNECTION_SUM_DESCRIPTION_ASC',
  LineItemsConnectionSumDescriptionDesc = 'LINE_ITEMS_CONNECTION_SUM_DESCRIPTION_DESC',
  LineItemsConnectionSumIdAsc = 'LINE_ITEMS_CONNECTION_SUM_ID_ASC',
  LineItemsConnectionSumIdDesc = 'LINE_ITEMS_CONNECTION_SUM_ID_DESC',
  LineItemsConnectionSumProjectIdAsc = 'LINE_ITEMS_CONNECTION_SUM_PROJECT_ID_ASC',
  LineItemsConnectionSumProjectIdDesc = 'LINE_ITEMS_CONNECTION_SUM_PROJECT_ID_DESC',
  LineItemsConnectionSumQuantityAsc = 'LINE_ITEMS_CONNECTION_SUM_QUANTITY_ASC',
  LineItemsConnectionSumQuantityDesc = 'LINE_ITEMS_CONNECTION_SUM_QUANTITY_DESC',
  LineItemsConnectionSumRevenueAsc = 'LINE_ITEMS_CONNECTION_SUM_REVENUE_ASC',
  LineItemsConnectionSumRevenueDesc = 'LINE_ITEMS_CONNECTION_SUM_REVENUE_DESC',
  LineItemsConnectionSumTotalCostAsc = 'LINE_ITEMS_CONNECTION_SUM_TOTAL_COST_ASC',
  LineItemsConnectionSumTotalCostDesc = 'LINE_ITEMS_CONNECTION_SUM_TOTAL_COST_DESC',
  LineItemsConnectionSumTotalPriceAsc = 'LINE_ITEMS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  LineItemsConnectionSumTotalPriceDesc = 'LINE_ITEMS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  LineItemsConnectionSumUnitCostAsc = 'LINE_ITEMS_CONNECTION_SUM_UNIT_COST_ASC',
  LineItemsConnectionSumUnitCostDesc = 'LINE_ITEMS_CONNECTION_SUM_UNIT_COST_DESC',
  LineItemsConnectionSumUnitPriceAsc = 'LINE_ITEMS_CONNECTION_SUM_UNIT_PRICE_ASC',
  LineItemsConnectionSumUnitPriceDesc = 'LINE_ITEMS_CONNECTION_SUM_UNIT_PRICE_DESC',
  LineItemsConnectionSumUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_SUM_UPDATED_AT_ASC',
  LineItemsConnectionSumUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_SUM_UPDATED_AT_DESC',
  LineItemsConnectionSumUpdatedByAsc = 'LINE_ITEMS_CONNECTION_SUM_UPDATED_BY_ASC',
  LineItemsConnectionSumUpdatedByDesc = 'LINE_ITEMS_CONNECTION_SUM_UPDATED_BY_DESC',
  LineItemsConnectionVariancePopulationCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionVariancePopulationCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionVariancePopulationCreatedAtAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  LineItemsConnectionVariancePopulationCreatedAtDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  LineItemsConnectionVariancePopulationCreatedByAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  LineItemsConnectionVariancePopulationCreatedByDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  LineItemsConnectionVariancePopulationDescriptionAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  LineItemsConnectionVariancePopulationDescriptionDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  LineItemsConnectionVariancePopulationIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  LineItemsConnectionVariancePopulationIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  LineItemsConnectionVariancePopulationProjectIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  LineItemsConnectionVariancePopulationProjectIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  LineItemsConnectionVariancePopulationQuantityAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_QUANTITY_ASC',
  LineItemsConnectionVariancePopulationQuantityDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_QUANTITY_DESC',
  LineItemsConnectionVariancePopulationRevenueAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  LineItemsConnectionVariancePopulationRevenueDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  LineItemsConnectionVariancePopulationTotalCostAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  LineItemsConnectionVariancePopulationTotalCostDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  LineItemsConnectionVariancePopulationTotalPriceAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  LineItemsConnectionVariancePopulationTotalPriceDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  LineItemsConnectionVariancePopulationUnitCostAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UNIT_COST_ASC',
  LineItemsConnectionVariancePopulationUnitCostDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UNIT_COST_DESC',
  LineItemsConnectionVariancePopulationUnitPriceAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_ASC',
  LineItemsConnectionVariancePopulationUnitPriceDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_DESC',
  LineItemsConnectionVariancePopulationUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LineItemsConnectionVariancePopulationUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LineItemsConnectionVariancePopulationUpdatedByAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_ASC',
  LineItemsConnectionVariancePopulationUpdatedByDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_DESC',
  LineItemsConnectionVarianceSampleCatalogItemIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsConnectionVarianceSampleCatalogItemIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsConnectionVarianceSampleCreatedAtAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LineItemsConnectionVarianceSampleCreatedAtDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LineItemsConnectionVarianceSampleCreatedByAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  LineItemsConnectionVarianceSampleCreatedByDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  LineItemsConnectionVarianceSampleDescriptionAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  LineItemsConnectionVarianceSampleDescriptionDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  LineItemsConnectionVarianceSampleIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  LineItemsConnectionVarianceSampleIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  LineItemsConnectionVarianceSampleProjectIdAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  LineItemsConnectionVarianceSampleProjectIdDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  LineItemsConnectionVarianceSampleQuantityAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_QUANTITY_ASC',
  LineItemsConnectionVarianceSampleQuantityDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_QUANTITY_DESC',
  LineItemsConnectionVarianceSampleRevenueAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  LineItemsConnectionVarianceSampleRevenueDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  LineItemsConnectionVarianceSampleTotalCostAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  LineItemsConnectionVarianceSampleTotalCostDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  LineItemsConnectionVarianceSampleTotalPriceAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsConnectionVarianceSampleTotalPriceDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsConnectionVarianceSampleUnitCostAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_ASC',
  LineItemsConnectionVarianceSampleUnitCostDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_DESC',
  LineItemsConnectionVarianceSampleUnitPriceAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_ASC',
  LineItemsConnectionVarianceSampleUnitPriceDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_DESC',
  LineItemsConnectionVarianceSampleUpdatedAtAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LineItemsConnectionVarianceSampleUpdatedAtDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LineItemsConnectionVarianceSampleUpdatedByAsc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_ASC',
  LineItemsConnectionVarianceSampleUpdatedByDesc = 'LINE_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_DESC',
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  MasterItemIdAsc = 'MASTER_ITEM_ID_ASC',
  MasterItemIdDesc = 'MASTER_ITEM_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SkuAsc = 'SKU_ASC',
  SkuDesc = 'SKU_DESC',
  SpecAsc = 'SPEC_ASC',
  SpecDesc = 'SPEC_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ColumnGroup = Node & {
  /** Reads and enables pagination through a set of `ColumnToGroup`. */
  columnToGroupsByGroupId: Array<ColumnToGroup>;
  /** Reads and enables pagination through a set of `ColumnToGroup`. */
  columnToGroupsByGroupIdConnection: ColumnToGroupsConnection;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  scope: Scalars['String']['output'];
  /** Reads a single `ProjectStage` that is related to this `ColumnGroup`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type ColumnGroupColumnToGroupsByGroupIdArgs = {
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnGroupColumnToGroupsByGroupIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnGroupAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ColumnGroupArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ColumnGroupAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ColumnGroupDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ColumnGroupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ColumnGroupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ColumnGroupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ColumnGroupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ColumnGroupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ColumnGroupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ColumnGroupVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ColumnGroup` object types. */
export type ColumnGroupAggregatesFilter = {
  /** Array aggregation aggregate over matching `ColumnGroup` objects. */
  arrayAgg: InputMaybe<ColumnGroupArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ColumnGroup` objects. */
  average: InputMaybe<ColumnGroupAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ColumnGroup` objects. */
  distinctCount: InputMaybe<ColumnGroupDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ColumnGroup` object to be included within the aggregate. */
  filter: InputMaybe<ColumnGroupFilter>;
  /** Maximum aggregate over matching `ColumnGroup` objects. */
  max: InputMaybe<ColumnGroupMaxAggregateFilter>;
  /** Minimum aggregate over matching `ColumnGroup` objects. */
  min: InputMaybe<ColumnGroupMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ColumnGroup` objects. */
  stddevPopulation: InputMaybe<ColumnGroupStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ColumnGroup` objects. */
  stddevSample: InputMaybe<ColumnGroupStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ColumnGroup` objects. */
  sum: InputMaybe<ColumnGroupSumAggregateFilter>;
  /** Population variance aggregate over matching `ColumnGroup` objects. */
  variancePopulation: InputMaybe<ColumnGroupVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ColumnGroup` objects. */
  varianceSample: InputMaybe<ColumnGroupVarianceSampleAggregateFilter>;
};

export type ColumnGroupArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  scope: InputMaybe<StringListFilter>;
  stageId: InputMaybe<IntListFilter>;
};

export type ColumnGroupArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of scope across the matching connection */
  scope: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
};


export type ColumnGroupArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupArrayAggAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ColumnGroupAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnGroupAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

/**
 * A condition to be used against `ColumnGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ColumnGroupCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type ColumnGroupDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type ColumnGroupDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scope across the matching connection */
  scope: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ColumnGroupDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

/** A filter to be used against `ColumnGroup` object types. All fields are combined with a logical ‘and.’ */
export type ColumnGroupFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ColumnGroupFilter>>;
  /** Filter by the object’s `columnToGroupsByGroupId` relation. */
  columnToGroupsByGroupId: InputMaybe<ColumnGroupToManyColumnToGroupFilter>;
  /** Some related `columnToGroupsByGroupId` exist. */
  columnToGroupsByGroupIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ColumnGroupFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ColumnGroupFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ColumnGroup` for usage during aggregation. */
export enum ColumnGroupGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Name = 'NAME',
  Position = 'POSITION',
  Scope = 'SCOPE',
  StageId = 'STAGE_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ColumnGroupHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ColumnGroup` aggregates. */
export type ColumnGroupHavingInput = {
  AND: InputMaybe<Array<ColumnGroupHavingInput>>;
  OR: InputMaybe<Array<ColumnGroupHavingInput>>;
  arrayAgg: InputMaybe<ColumnGroupHavingArrayAggInput>;
  average: InputMaybe<ColumnGroupHavingAverageInput>;
  distinctCount: InputMaybe<ColumnGroupHavingDistinctCountInput>;
  max: InputMaybe<ColumnGroupHavingMaxInput>;
  min: InputMaybe<ColumnGroupHavingMinInput>;
  stddevPopulation: InputMaybe<ColumnGroupHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ColumnGroupHavingStddevSampleInput>;
  sum: InputMaybe<ColumnGroupHavingSumInput>;
  variancePopulation: InputMaybe<ColumnGroupHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ColumnGroupHavingVarianceSampleInput>;
};

export type ColumnGroupHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnGroupMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ColumnGroupMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ColumnGroupMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ColumnGroupMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ColumnGroupMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ColumnGroupStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnGroupStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ColumnGroupStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnGroupStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
};

export type ColumnGroupSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
};


export type ColumnGroupSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

/** A filter to be used against many `ColumnToGroup` object types. All fields are combined with a logical ‘and.’ */
export type ColumnGroupToManyColumnToGroupFilter = {
  /** Aggregates across related `ColumnToGroup` match the filter criteria. */
  aggregates: InputMaybe<ColumnToGroupAggregatesFilter>;
  /** Every related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ColumnToGroupFilter>;
  /** No related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ColumnToGroupFilter>;
  /** Some related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ColumnToGroupFilter>;
};

export type ColumnGroupVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ColumnGroupVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnGroupVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

export type ColumnGroupVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ColumnGroupVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnGroupVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ColumnGroupVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};

/** A connection to a list of `ColumnGroup` values. */
export type ColumnGroupsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ColumnGroupAggregates>;
  /** A list of edges which contains the `ColumnGroup` and cursor to aid in pagination. */
  edges: Array<ColumnGroupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ColumnGroupAggregates>>;
  /** A list of `ColumnGroup` objects. */
  nodes: Array<Maybe<ColumnGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ColumnGroup` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ColumnGroup` values. */
export type ColumnGroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ColumnGroupGroupBy>;
  having: InputMaybe<ColumnGroupHavingInput>;
};

/** A `ColumnGroup` edge in the connection. */
export type ColumnGroupsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ColumnGroup` at the end of the edge. */
  node: Maybe<ColumnGroup>;
};

/** Methods to use when ordering `ColumnGroup`. */
export enum ColumnGroupsOrderBy {
  ColumnToGroupsByGroupIdConnectionArrayAggColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionArrayAggUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionArrayAggUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionAverageColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionAverageColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionAverageCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionAverageCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionAverageCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionAverageCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionAverageGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionAverageGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionAverageIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_ID_ASC',
  ColumnToGroupsByGroupIdConnectionAverageIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_ID_DESC',
  ColumnToGroupsByGroupIdConnectionAveragePositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionAveragePositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionAverageScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionAverageScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionAverageUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionAverageUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionCountAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_COUNT_ASC',
  ColumnToGroupsByGroupIdConnectionCountDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_COUNT_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionDistinctCountUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionDistinctCountUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionMaxColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMaxColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMaxCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMaxCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMaxCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionMaxCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionMaxGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMaxGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMaxIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMaxIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMaxPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionMaxPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionMaxScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionMaxScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionMaxUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionMaxUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionMinColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMinColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMinCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMinCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMinCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionMinCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionMinGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMinGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMinIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionMinIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionMinPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionMinPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionMinScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionMinScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionMinUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionMinUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionStddevPopulationUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSamplePositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSamplePositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionStddevSampleUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionStddevSampleUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionSumColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionSumColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionSumCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionSumCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionSumCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionSumCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionSumGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionSumGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionSumIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_ID_ASC',
  ColumnToGroupsByGroupIdConnectionSumIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_ID_DESC',
  ColumnToGroupsByGroupIdConnectionSumPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionSumPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionSumScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionSumScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionSumUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionSumUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationPositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationPositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionVariancePopulationUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleColumnIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleColumnIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleGroupIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleGroupIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleIdAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleIdDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSamplePositionAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSamplePositionDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleScopeAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleScopeDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ColumnToGroupsByGroupIdConnectionVarianceSampleUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_GROUP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ColumnToGroup = Node & {
  /** Reads a single `ProjectProperty` that is related to this `ColumnToGroup`. */
  column: Maybe<ProjectProperty>;
  columnId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  /** Reads a single `ColumnGroup` that is related to this `ColumnToGroup`. */
  group: Maybe<ColumnGroup>;
  groupId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  scope: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
};

export type ColumnToGroupAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ColumnToGroupArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ColumnToGroupAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ColumnToGroupDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ColumnToGroupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ColumnToGroupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ColumnToGroupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ColumnToGroupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ColumnToGroupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ColumnToGroupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ColumnToGroupVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ColumnToGroup` object types. */
export type ColumnToGroupAggregatesFilter = {
  /** Array aggregation aggregate over matching `ColumnToGroup` objects. */
  arrayAgg: InputMaybe<ColumnToGroupArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ColumnToGroup` objects. */
  average: InputMaybe<ColumnToGroupAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ColumnToGroup` objects. */
  distinctCount: InputMaybe<ColumnToGroupDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ColumnToGroup` object to be included within the aggregate. */
  filter: InputMaybe<ColumnToGroupFilter>;
  /** Maximum aggregate over matching `ColumnToGroup` objects. */
  max: InputMaybe<ColumnToGroupMaxAggregateFilter>;
  /** Minimum aggregate over matching `ColumnToGroup` objects. */
  min: InputMaybe<ColumnToGroupMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ColumnToGroup` objects. */
  stddevPopulation: InputMaybe<ColumnToGroupStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ColumnToGroup` objects. */
  stddevSample: InputMaybe<ColumnToGroupStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ColumnToGroup` objects. */
  sum: InputMaybe<ColumnToGroupSumAggregateFilter>;
  /** Population variance aggregate over matching `ColumnToGroup` objects. */
  variancePopulation: InputMaybe<ColumnToGroupVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ColumnToGroup` objects. */
  varianceSample: InputMaybe<ColumnToGroupVarianceSampleAggregateFilter>;
};

export type ColumnToGroupArrayAggAggregateFilter = {
  columnId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  groupId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  scope: InputMaybe<StringListFilter>;
};

export type ColumnToGroupArrayAggAggregates = {
  /** Array aggregation of columnId across the matching connection */
  columnId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of groupId across the matching connection */
  groupId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of scope across the matching connection */
  scope: Array<Maybe<Scalars['String']['output']>>;
};


export type ColumnToGroupArrayAggAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupArrayAggAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupArrayAggAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupAverageAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  groupId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type ColumnToGroupAverageAggregates = {
  /** Mean average of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of groupId across the matching connection */
  groupId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnToGroupAverageAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupAverageAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

/**
 * A condition to be used against `ColumnToGroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ColumnToGroupCondition = {
  /** Checks for equality with the object’s `columnId` field. */
  columnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type ColumnToGroupDistinctCountAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  groupId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type ColumnToGroupDistinctCountAggregates = {
  /** Distinct count of columnId across the matching connection */
  columnId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of groupId across the matching connection */
  groupId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scope across the matching connection */
  scope: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ColumnToGroupDistinctCountAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

/** A filter to be used against `ColumnToGroup` object types. All fields are combined with a logical ‘and.’ */
export type ColumnToGroupFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ColumnToGroupFilter>>;
  /** Filter by the object’s `column` relation. */
  column: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `columnId` field. */
  columnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `group` relation. */
  group: InputMaybe<ColumnGroupFilter>;
  /** A related `group` exists. */
  groupExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `groupId` field. */
  groupId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ColumnToGroupFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ColumnToGroupFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ColumnToGroup` for usage during aggregation. */
export enum ColumnToGroupGroupBy {
  ColumnId = 'COLUMN_ID',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  GroupId = 'GROUP_ID',
  Position = 'POSITION',
  Scope = 'SCOPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ColumnToGroupHavingArrayAggInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingAverageInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingDistinctCountInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ColumnToGroup` aggregates. */
export type ColumnToGroupHavingInput = {
  AND: InputMaybe<Array<ColumnToGroupHavingInput>>;
  OR: InputMaybe<Array<ColumnToGroupHavingInput>>;
  arrayAgg: InputMaybe<ColumnToGroupHavingArrayAggInput>;
  average: InputMaybe<ColumnToGroupHavingAverageInput>;
  distinctCount: InputMaybe<ColumnToGroupHavingDistinctCountInput>;
  max: InputMaybe<ColumnToGroupHavingMaxInput>;
  min: InputMaybe<ColumnToGroupHavingMinInput>;
  stddevPopulation: InputMaybe<ColumnToGroupHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ColumnToGroupHavingStddevSampleInput>;
  sum: InputMaybe<ColumnToGroupHavingSumInput>;
  variancePopulation: InputMaybe<ColumnToGroupHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ColumnToGroupHavingVarianceSampleInput>;
};

export type ColumnToGroupHavingMaxInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingMinInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingStddevPopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingStddevSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingSumInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingVariancePopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupHavingVarianceSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  groupId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ColumnToGroupMaxAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  groupId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ColumnToGroupMaxAggregates = {
  /** Maximum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of groupId across the matching connection */
  groupId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ColumnToGroupMaxAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupMinAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  groupId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ColumnToGroupMinAggregates = {
  /** Minimum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of groupId across the matching connection */
  groupId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ColumnToGroupMinAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupStddevPopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  groupId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type ColumnToGroupStddevPopulationAggregates = {
  /** Population standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of groupId across the matching connection */
  groupId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnToGroupStddevPopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevPopulationAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupStddevSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  groupId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type ColumnToGroupStddevSampleAggregates = {
  /** Sample standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of groupId across the matching connection */
  groupId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnToGroupStddevSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevSampleAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupSumAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  groupId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
};

export type ColumnToGroupSumAggregates = {
  /** Sum of columnId across the matching connection */
  columnId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of groupId across the matching connection */
  groupId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
};


export type ColumnToGroupSumAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupSumAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupVariancePopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  groupId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type ColumnToGroupVariancePopulationAggregates = {
  /** Population variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of groupId across the matching connection */
  groupId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnToGroupVariancePopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVariancePopulationAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

export type ColumnToGroupVarianceSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  groupId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type ColumnToGroupVarianceSampleAggregates = {
  /** Sample variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of groupId across the matching connection */
  groupId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type ColumnToGroupVarianceSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVarianceSampleAggregatesGroupIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ColumnToGroupVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};

/** A connection to a list of `ColumnToGroup` values. */
export type ColumnToGroupsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ColumnToGroupAggregates>;
  /** A list of edges which contains the `ColumnToGroup` and cursor to aid in pagination. */
  edges: Array<ColumnToGroupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ColumnToGroupAggregates>>;
  /** A list of `ColumnToGroup` objects. */
  nodes: Array<Maybe<ColumnToGroup>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ColumnToGroup` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ColumnToGroup` values. */
export type ColumnToGroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ColumnToGroupGroupBy>;
  having: InputMaybe<ColumnToGroupHavingInput>;
};

/** A `ColumnToGroup` edge in the connection. */
export type ColumnToGroupsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ColumnToGroup` at the end of the edge. */
  node: Maybe<ColumnToGroup>;
};

/** Methods to use when ordering `ColumnToGroup`. */
export enum ColumnToGroupsOrderBy {
  ColumnIdAsc = 'COLUMN_ID_ASC',
  ColumnIdDesc = 'COLUMN_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Comment = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCommentIdAndActionId: CommentActionsByFeedCommentIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCommentIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCommentIdAndActionId: CommentActionsByFileCommentIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCommentIdAndActionIdList: Array<Action>;
  additional: Maybe<Scalars['JSON']['output']>;
  annotationId: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCommentIdAndCallId: CommentCallsByFeedCommentIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCommentIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  childCommentsConnection: CommentsConnection;
  comment: Scalars['String']['output'];
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCommentIdAndCreatedByContactId: CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCommentIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileCommentIdAndCreatedByContactId: CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileCommentIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `Comment`. */
  createdByContact: Maybe<Contact>;
  createdByContactId: Maybe<Scalars['Int']['output']>;
  createdByType: CommentCreatedBy;
  /** Reads a single `MainUser` that is related to this `Comment`. */
  createdByUser: Maybe<MainUser>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedCommentIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedCommentIdAndParentId: CommentFeedsByFeedCommentIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** Reads a single `File` that is related to this `Comment`. */
  file: Maybe<File>;
  fileId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCommentIdAndFileId: CommentFilesByFeedCommentIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCommentIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCommentIdAndValueFileId: CommentFilesByFileCommentIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCommentIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCommentIdAndFormId: CommentFormsByFileCommentIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCommentIdAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  isPinned: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Comment` that is related to this `Comment`. */
  parent: Maybe<Comment>;
  parentId: Maybe<Scalars['Int']['output']>;
  pinnedAt: Maybe<Scalars['Datetime']['output']>;
  previousId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedCommentIdAndTaskId: CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `Comment`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileCommentIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCommentIdAndProjectId: CommentProjectsByFeedCommentIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCommentIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCommentIdAndProjectId: CommentProjectsByFileCommentIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCommentIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCommentIdAndReminderId: CommentRemindersByFeedCommentIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCommentIdAndReminderIdList: Array<Reminder>;
  scope: CommentScope;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedCommentIdAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads a single `System` that is related to this `Comment`. */
  system: Maybe<System>;
  systemId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedCommentIdAndSystemId: CommentSystemsByFeedCommentIdAndSystemIdManyToManyConnection;
  /** Reads a single `PrivilegedTask` that is related to this `Comment`. */
  task: Maybe<PrivilegedTask>;
  taskId: Maybe<Scalars['Int']['output']>;
  type: CommentType;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedCommentIdAndCreatedBy: CommentUsersByFeedCommentIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileCommentIdAndCreatedBy: CommentUsersByFileCommentIdAndCreatedByManyToManyConnection;
  x: Maybe<Scalars['Int']['output']>;
  y: Maybe<Scalars['Int']['output']>;
};


export type CommentActionsByFeedCommentIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type CommentActionsByFeedCommentIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type CommentActionsByFileCommentIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type CommentActionsByFileCommentIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type CommentCallsByFeedCommentIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type CommentCallsByFeedCommentIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CommentChildCommentsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentContactsByFeedCommentIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type CommentContactsByFeedCommentIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type CommentContactsByFileCommentIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type CommentContactsByFileCommentIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type CommentEmailMessagesByFeedCommentIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type CommentFeedsByFeedCommentIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type CommentFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type CommentFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFilesByFeedCommentIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFilesByFeedCommentIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFilesByFileCommentIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFilesByFileCommentIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type CommentFormsByFileCommentIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type CommentFormsByFileCommentIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type CommentPrivilegedTasksByFeedCommentIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CommentProjectStagesByFileCommentIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CommentProjectsByFeedCommentIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CommentProjectsByFeedCommentIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CommentProjectsByFileCommentIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CommentProjectsByFileCommentIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CommentRemindersByFeedCommentIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type CommentRemindersByFeedCommentIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type CommentSmsActivitiesByFeedCommentIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type CommentSystemsByFeedCommentIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type CommentUsersByFeedCommentIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CommentUsersByFileCommentIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type CommentActionsByFeedCommentIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentActionsByFeedCommentIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type CommentActionsByFeedCommentIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type CommentActionsByFeedCommentIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type CommentActionsByFeedCommentIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type CommentActionsByFileCommentIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentActionsByFileCommentIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type CommentActionsByFileCommentIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type CommentActionsByFileCommentIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type CommentActionsByFileCommentIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type CommentActionsByFileCommentIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type CommentAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CommentArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CommentAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CommentDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CommentMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CommentMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CommentStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CommentStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CommentSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CommentVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CommentVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Comment` object types. */
export type CommentAggregatesFilter = {
  /** Array aggregation aggregate over matching `Comment` objects. */
  arrayAgg: InputMaybe<CommentArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Comment` objects. */
  average: InputMaybe<CommentAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Comment` objects. */
  distinctCount: InputMaybe<CommentDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Comment` object to be included within the aggregate. */
  filter: InputMaybe<CommentFilter>;
  /** Maximum aggregate over matching `Comment` objects. */
  max: InputMaybe<CommentMaxAggregateFilter>;
  /** Minimum aggregate over matching `Comment` objects. */
  min: InputMaybe<CommentMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Comment` objects. */
  stddevPopulation: InputMaybe<CommentStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Comment` objects. */
  stddevSample: InputMaybe<CommentStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Comment` objects. */
  sum: InputMaybe<CommentSumAggregateFilter>;
  /** Population variance aggregate over matching `Comment` objects. */
  variancePopulation: InputMaybe<CommentVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Comment` objects. */
  varianceSample: InputMaybe<CommentVarianceSampleAggregateFilter>;
};

export type CommentArrayAggAggregateFilter = {
  annotationId: InputMaybe<StringListFilter>;
  comment: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  createdByContactId: InputMaybe<IntListFilter>;
  fileId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isPinned: InputMaybe<BooleanListFilter>;
  parentId: InputMaybe<IntListFilter>;
  previousId: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  systemId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
  x: InputMaybe<IntListFilter>;
  y: InputMaybe<IntListFilter>;
};

export type CommentArrayAggAggregates = {
  /** Array aggregation of annotationId across the matching connection */
  annotationId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of comment across the matching connection */
  comment: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdByContactId across the matching connection */
  createdByContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of fileId across the matching connection */
  fileId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isPinned across the matching connection */
  isPinned: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of parentId across the matching connection */
  parentId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of previousId across the matching connection */
  previousId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of x across the matching connection */
  x: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of y across the matching connection */
  y: Array<Maybe<Scalars['Int']['output']>>;
};


export type CommentArrayAggAggregatesAnnotationIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesCommentArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesIsPinnedArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentArrayAggAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

export type CommentAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  previousId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  x: InputMaybe<BigFloatFilter>;
  y: InputMaybe<BigFloatFilter>;
};

export type CommentAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of previousId across the matching connection */
  previousId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of x across the matching connection */
  x: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of y across the matching connection */
  y: Maybe<Scalars['BigFloat']['output']>;
};


export type CommentAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentAverageAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type CommentCallsByFeedCommentIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentCallsByFeedCommentIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type CommentCallsByFeedCommentIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type CommentCallsByFeedCommentIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type CommentCallsByFeedCommentIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A condition to be used against `Comment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CommentCondition = {
  /** Checks for equality with the object’s `additional` field. */
  additional: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `annotationId` field. */
  annotationId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `comment` field. */
  comment: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isPinned` field. */
  isPinned: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `pinnedAt` field. */
  pinnedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `previousId` field. */
  previousId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<CommentScope>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<CommentType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `x` field. */
  x: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `y` field. */
  y: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type CommentContactsByFeedCommentIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type CommentContactsByFileCommentIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export enum CommentCreatedBy {
  Contact = 'CONTACT',
  User = 'USER'
}

/** A filter to be used against CommentCreatedBy fields. All fields are combined with a logical ‘and.’ */
export type CommentCreatedByFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CommentCreatedBy>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CommentCreatedBy>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CommentCreatedBy>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CommentCreatedBy>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CommentCreatedBy>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CommentCreatedBy>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CommentCreatedBy>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CommentCreatedBy>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CommentCreatedBy>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CommentCreatedBy>>;
};

export type CommentDistinctCountAggregateFilter = {
  additional: InputMaybe<BigIntFilter>;
  annotationId: InputMaybe<BigIntFilter>;
  comment: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  createdByType: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isPinned: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  pinnedAt: InputMaybe<BigIntFilter>;
  previousId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  x: InputMaybe<BigIntFilter>;
  y: InputMaybe<BigIntFilter>;
};

export type CommentDistinctCountAggregates = {
  /** Distinct count of additional across the matching connection */
  additional: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of annotationId across the matching connection */
  annotationId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of comment across the matching connection */
  comment: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByType across the matching connection */
  createdByType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileId across the matching connection */
  fileId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isPinned across the matching connection */
  isPinned: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentId across the matching connection */
  parentId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of pinnedAt across the matching connection */
  pinnedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of previousId across the matching connection */
  previousId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scope across the matching connection */
  scope: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of x across the matching connection */
  x: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of y across the matching connection */
  y: Maybe<Scalars['BigInt']['output']>;
};


export type CommentDistinctCountAggregatesAdditionalArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesAnnotationIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCommentArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesCreatedByTypeArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesIsPinnedArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesPinnedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentDistinctCountAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type CommentFeedsByFeedCommentIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentFeedsByFeedCommentIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type CommentFeedsByFeedCommentIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type CommentFeedsByFeedCommentIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type CommentFeedsByFeedCommentIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type CommentFilesByFeedCommentIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentFilesByFeedCommentIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type CommentFilesByFeedCommentIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type CommentFilesByFeedCommentIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type CommentFilesByFeedCommentIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type CommentFilesByFileCommentIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentFilesByFileCommentIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type CommentFilesByFileCommentIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type CommentFilesByFileCommentIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type CommentFilesByFileCommentIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type CommentFilesByFileCommentIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `Comment` object types. All fields are combined with a logical ‘and.’ */
export type CommentFilter = {
  /** Filter by the object’s `additional` field. */
  additional: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CommentFilter>>;
  /** Filter by the object’s `annotationId` field. */
  annotationId: InputMaybe<StringFilter>;
  /** Filter by the object’s `childComments` relation. */
  childComments: InputMaybe<CommentToManyCommentFilter>;
  /** Some related `childComments` exist. */
  childCommentsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `comment` field. */
  comment: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByContact` relation. */
  createdByContact: InputMaybe<ContactFilter>;
  /** A related `createdByContact` exists. */
  createdByContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feeds` relation. */
  feeds: InputMaybe<CommentToManyFeedFilter>;
  /** Some related `feeds` exist. */
  feedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `file` relation. */
  file: InputMaybe<FileFilter>;
  /** A related `file` exists. */
  fileExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fileId` field. */
  fileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `files` relation. */
  files: InputMaybe<CommentToManyFileFilter>;
  /** Some related `files` exist. */
  filesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPinned` field. */
  isPinned: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<CommentFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CommentFilter>>;
  /** Filter by the object’s `parent` relation. */
  parent: InputMaybe<CommentFilter>;
  /** A related `parent` exists. */
  parentExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentId` field. */
  parentId: InputMaybe<IntFilter>;
  /** Filter by the object’s `pinnedAt` field. */
  pinnedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `previousId` field. */
  previousId: InputMaybe<IntFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<CommentScopeFilter>;
  /** Filter by the object’s `system` relation. */
  system: InputMaybe<SystemFilter>;
  /** A related `system` exists. */
  systemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<CommentTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `x` field. */
  x: InputMaybe<IntFilter>;
  /** Filter by the object’s `y` field. */
  y: InputMaybe<IntFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type CommentFormsByFileCommentIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentFormsByFileCommentIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type CommentFormsByFileCommentIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type CommentFormsByFileCommentIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type CommentFormsByFileCommentIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type CommentFormsByFileCommentIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Grouping methods for `Comment` for usage during aggregation. */
export enum CommentGroupBy {
  Additional = 'ADDITIONAL',
  AnnotationId = 'ANNOTATION_ID',
  Comment = 'COMMENT',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedByContactId = 'CREATED_BY_CONTACT_ID',
  CreatedByType = 'CREATED_BY_TYPE',
  FileId = 'FILE_ID',
  IsPinned = 'IS_PINNED',
  ParentId = 'PARENT_ID',
  PinnedAt = 'PINNED_AT',
  PinnedAtTruncatedToDay = 'PINNED_AT_TRUNCATED_TO_DAY',
  PinnedAtTruncatedToHour = 'PINNED_AT_TRUNCATED_TO_HOUR',
  PinnedAtTruncatedToMonth = 'PINNED_AT_TRUNCATED_TO_MONTH',
  PinnedAtTruncatedToQuarter = 'PINNED_AT_TRUNCATED_TO_QUARTER',
  PinnedAtTruncatedToWeek = 'PINNED_AT_TRUNCATED_TO_WEEK',
  PinnedAtTruncatedToYear = 'PINNED_AT_TRUNCATED_TO_YEAR',
  PreviousId = 'PREVIOUS_ID',
  ProjectId = 'PROJECT_ID',
  Scope = 'SCOPE',
  SystemId = 'SYSTEM_ID',
  TaskId = 'TASK_ID',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  X = 'X',
  Y = 'Y'
}

export type CommentHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Comment` aggregates. */
export type CommentHavingInput = {
  AND: InputMaybe<Array<CommentHavingInput>>;
  OR: InputMaybe<Array<CommentHavingInput>>;
  arrayAgg: InputMaybe<CommentHavingArrayAggInput>;
  average: InputMaybe<CommentHavingAverageInput>;
  distinctCount: InputMaybe<CommentHavingDistinctCountInput>;
  max: InputMaybe<CommentHavingMaxInput>;
  min: InputMaybe<CommentHavingMinInput>;
  stddevPopulation: InputMaybe<CommentHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CommentHavingStddevSampleInput>;
  sum: InputMaybe<CommentHavingSumInput>;
  variancePopulation: InputMaybe<CommentHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CommentHavingVarianceSampleInput>;
};

export type CommentHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  pinnedAt: InputMaybe<HavingDatetimeFilter>;
  previousId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  x: InputMaybe<HavingIntFilter>;
  y: InputMaybe<HavingIntFilter>;
};

export type CommentMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  pinnedAt: InputMaybe<DatetimeFilter>;
  previousId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  x: InputMaybe<IntFilter>;
  y: InputMaybe<IntFilter>;
};

export type CommentMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Maximum of pinnedAt across the matching connection */
  pinnedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of previousId across the matching connection */
  previousId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of x across the matching connection */
  x: Maybe<Scalars['Int']['output']>;
  /** Maximum of y across the matching connection */
  y: Maybe<Scalars['Int']['output']>;
};


export type CommentMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesPinnedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMaxAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

export type CommentMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  pinnedAt: InputMaybe<DatetimeFilter>;
  previousId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  x: InputMaybe<IntFilter>;
  y: InputMaybe<IntFilter>;
};

export type CommentMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Minimum of pinnedAt across the matching connection */
  pinnedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of previousId across the matching connection */
  previousId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of x across the matching connection */
  x: Maybe<Scalars['Int']['output']>;
  /** Minimum of y across the matching connection */
  y: Maybe<Scalars['Int']['output']>;
};


export type CommentMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesPinnedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentMinAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type CommentPrivilegedTasksByFeedCommentIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type CommentProjectsByFeedCommentIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentProjectsByFeedCommentIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type CommentProjectsByFeedCommentIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type CommentProjectsByFeedCommentIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type CommentProjectsByFeedCommentIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type CommentProjectsByFileCommentIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentProjectsByFileCommentIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type CommentProjectsByFileCommentIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type CommentProjectsByFileCommentIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type CommentProjectsByFileCommentIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type CommentProjectsByFileCommentIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type CommentRemindersByFeedCommentIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentRemindersByFeedCommentIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type CommentRemindersByFeedCommentIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type CommentRemindersByFeedCommentIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type CommentRemindersByFeedCommentIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export enum CommentScope {
  Internal = 'INTERNAL',
  PortalCommunication = 'PORTAL_COMMUNICATION'
}

/** A filter to be used against CommentScope fields. All fields are combined with a logical ‘and.’ */
export type CommentScopeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CommentScope>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CommentScope>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CommentScope>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CommentScope>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CommentScope>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CommentScope>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CommentScope>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CommentScope>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CommentScope>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CommentScope>>;
};

export type CommentStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  previousId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  x: InputMaybe<BigFloatFilter>;
  y: InputMaybe<BigFloatFilter>;
};

export type CommentStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of previousId across the matching connection */
  previousId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of x across the matching connection */
  x: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of y across the matching connection */
  y: Maybe<Scalars['BigFloat']['output']>;
};


export type CommentStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevPopulationAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

export type CommentStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  previousId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  x: InputMaybe<BigFloatFilter>;
  y: InputMaybe<BigFloatFilter>;
};

export type CommentStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of previousId across the matching connection */
  previousId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of x across the matching connection */
  x: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of y across the matching connection */
  y: Maybe<Scalars['BigFloat']['output']>;
};


export type CommentStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentStddevSampleAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

export type CommentSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  previousId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  x: InputMaybe<BigIntFilter>;
  y: InputMaybe<BigIntFilter>;
};

export type CommentSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of createdByContactId across the matching connection */
  createdByContactId: Scalars['BigInt']['output'];
  /** Sum of fileId across the matching connection */
  fileId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt']['output'];
  /** Sum of previousId across the matching connection */
  previousId: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
  /** Sum of x across the matching connection */
  x: Scalars['BigInt']['output'];
  /** Sum of y across the matching connection */
  y: Scalars['BigInt']['output'];
};


export type CommentSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentSumAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type CommentSystemsByFeedCommentIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentSystemsByFeedCommentIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type CommentSystemsByFeedCommentIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type CommentSystemsByFeedCommentIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type CommentSystemsByFeedCommentIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type CommentToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type CommentToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type CommentToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

export enum CommentType {
  File = 'FILE',
  Project = 'PROJECT',
  System = 'SYSTEM',
  Task = 'TASK'
}

/** A filter to be used against CommentType fields. All fields are combined with a logical ‘and.’ */
export type CommentTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CommentType>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CommentType>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CommentType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CommentType>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CommentType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CommentType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CommentType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CommentType>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CommentType>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CommentType>>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type CommentUsersByFeedCommentIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<CommentUsersByFeedCommentIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type CommentUsersByFeedCommentIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type CommentUsersByFeedCommentIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type CommentUsersByFeedCommentIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type CommentUsersByFileCommentIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<CommentUsersByFileCommentIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type CommentUsersByFileCommentIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type CommentUsersByFileCommentIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type CommentUsersByFileCommentIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type CommentUsersByFileCommentIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type CommentVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  previousId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  x: InputMaybe<BigFloatFilter>;
  y: InputMaybe<BigFloatFilter>;
};

export type CommentVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of previousId across the matching connection */
  previousId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of x across the matching connection */
  x: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of y across the matching connection */
  y: Maybe<Scalars['BigFloat']['output']>;
};


export type CommentVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVariancePopulationAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

export type CommentVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  previousId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  x: InputMaybe<BigFloatFilter>;
  y: InputMaybe<BigFloatFilter>;
};

export type CommentVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of previousId across the matching connection */
  previousId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of x across the matching connection */
  x: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of y across the matching connection */
  y: Maybe<Scalars['BigFloat']['output']>;
};


export type CommentVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesPreviousIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesXArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};


export type CommentVarianceSampleAggregatesYArgs = {
  orderBy: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Comment` values. */
export type CommentsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment` and cursor to aid in pagination. */
  edges: Array<CommentsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values. */
export type CommentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection. */
export type CommentsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};

/** Methods to use when ordering `Comment`. */
export enum CommentsOrderBy {
  AdditionalAsc = 'ADDITIONAL_ASC',
  AdditionalDesc = 'ADDITIONAL_DESC',
  AnnotationIdAsc = 'ANNOTATION_ID_ASC',
  AnnotationIdDesc = 'ANNOTATION_ID_DESC',
  ChildCommentsConnectionArrayAggAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  ChildCommentsConnectionArrayAggAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  ChildCommentsConnectionArrayAggAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  ChildCommentsConnectionArrayAggAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  ChildCommentsConnectionArrayAggCommentAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  ChildCommentsConnectionArrayAggCommentDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  ChildCommentsConnectionArrayAggCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ChildCommentsConnectionArrayAggCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ChildCommentsConnectionArrayAggCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ChildCommentsConnectionArrayAggCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ChildCommentsConnectionArrayAggCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ChildCommentsConnectionArrayAggCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionArrayAggCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionArrayAggCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ChildCommentsConnectionArrayAggCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionArrayAggCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionArrayAggFileIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  ChildCommentsConnectionArrayAggFileIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  ChildCommentsConnectionArrayAggIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ChildCommentsConnectionArrayAggIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ChildCommentsConnectionArrayAggIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  ChildCommentsConnectionArrayAggIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  ChildCommentsConnectionArrayAggParentIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  ChildCommentsConnectionArrayAggParentIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  ChildCommentsConnectionArrayAggPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  ChildCommentsConnectionArrayAggPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  ChildCommentsConnectionArrayAggPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  ChildCommentsConnectionArrayAggPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  ChildCommentsConnectionArrayAggProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ChildCommentsConnectionArrayAggProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ChildCommentsConnectionArrayAggScopeAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ChildCommentsConnectionArrayAggScopeDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ChildCommentsConnectionArrayAggSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  ChildCommentsConnectionArrayAggSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  ChildCommentsConnectionArrayAggTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  ChildCommentsConnectionArrayAggTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  ChildCommentsConnectionArrayAggTypeAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ChildCommentsConnectionArrayAggTypeDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ChildCommentsConnectionArrayAggUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ChildCommentsConnectionArrayAggUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ChildCommentsConnectionArrayAggXAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_X_ASC',
  ChildCommentsConnectionArrayAggXDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_X_DESC',
  ChildCommentsConnectionArrayAggYAsc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_Y_ASC',
  ChildCommentsConnectionArrayAggYDesc = 'CHILD_COMMENTS_CONNECTION_ARRAY_AGG_Y_DESC',
  ChildCommentsConnectionAverageAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  ChildCommentsConnectionAverageAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  ChildCommentsConnectionAverageAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  ChildCommentsConnectionAverageAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  ChildCommentsConnectionAverageCommentAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_COMMENT_ASC',
  ChildCommentsConnectionAverageCommentDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_COMMENT_DESC',
  ChildCommentsConnectionAverageCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ChildCommentsConnectionAverageCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ChildCommentsConnectionAverageCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ChildCommentsConnectionAverageCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ChildCommentsConnectionAverageCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ChildCommentsConnectionAverageCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionAverageCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionAverageCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ChildCommentsConnectionAverageCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionAverageCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionAverageFileIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_FILE_ID_ASC',
  ChildCommentsConnectionAverageFileIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_FILE_ID_DESC',
  ChildCommentsConnectionAverageIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ID_ASC',
  ChildCommentsConnectionAverageIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_ID_DESC',
  ChildCommentsConnectionAverageIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_IS_PINNED_ASC',
  ChildCommentsConnectionAverageIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_IS_PINNED_DESC',
  ChildCommentsConnectionAverageParentIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  ChildCommentsConnectionAverageParentIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  ChildCommentsConnectionAveragePinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PINNED_AT_ASC',
  ChildCommentsConnectionAveragePinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PINNED_AT_DESC',
  ChildCommentsConnectionAveragePreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  ChildCommentsConnectionAveragePreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  ChildCommentsConnectionAverageProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ChildCommentsConnectionAverageProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ChildCommentsConnectionAverageScopeAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_SCOPE_ASC',
  ChildCommentsConnectionAverageScopeDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_SCOPE_DESC',
  ChildCommentsConnectionAverageSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  ChildCommentsConnectionAverageSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  ChildCommentsConnectionAverageTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_TASK_ID_ASC',
  ChildCommentsConnectionAverageTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_TASK_ID_DESC',
  ChildCommentsConnectionAverageTypeAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_TYPE_ASC',
  ChildCommentsConnectionAverageTypeDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_TYPE_DESC',
  ChildCommentsConnectionAverageUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ChildCommentsConnectionAverageUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ChildCommentsConnectionAverageXAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_X_ASC',
  ChildCommentsConnectionAverageXDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_X_DESC',
  ChildCommentsConnectionAverageYAsc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_Y_ASC',
  ChildCommentsConnectionAverageYDesc = 'CHILD_COMMENTS_CONNECTION_AVERAGE_Y_DESC',
  ChildCommentsConnectionCountAsc = 'CHILD_COMMENTS_CONNECTION_COUNT_ASC',
  ChildCommentsConnectionCountDesc = 'CHILD_COMMENTS_CONNECTION_COUNT_DESC',
  ChildCommentsConnectionDistinctCountAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  ChildCommentsConnectionDistinctCountAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  ChildCommentsConnectionDistinctCountAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  ChildCommentsConnectionDistinctCountAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  ChildCommentsConnectionDistinctCountCommentAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  ChildCommentsConnectionDistinctCountCommentDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  ChildCommentsConnectionDistinctCountCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ChildCommentsConnectionDistinctCountCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ChildCommentsConnectionDistinctCountCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ChildCommentsConnectionDistinctCountCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ChildCommentsConnectionDistinctCountCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ChildCommentsConnectionDistinctCountCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionDistinctCountCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionDistinctCountCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ChildCommentsConnectionDistinctCountCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionDistinctCountCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionDistinctCountFileIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  ChildCommentsConnectionDistinctCountFileIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  ChildCommentsConnectionDistinctCountIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ChildCommentsConnectionDistinctCountIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ChildCommentsConnectionDistinctCountIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  ChildCommentsConnectionDistinctCountIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  ChildCommentsConnectionDistinctCountParentIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  ChildCommentsConnectionDistinctCountParentIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  ChildCommentsConnectionDistinctCountPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  ChildCommentsConnectionDistinctCountPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  ChildCommentsConnectionDistinctCountPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  ChildCommentsConnectionDistinctCountPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  ChildCommentsConnectionDistinctCountProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ChildCommentsConnectionDistinctCountProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ChildCommentsConnectionDistinctCountScopeAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ChildCommentsConnectionDistinctCountScopeDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ChildCommentsConnectionDistinctCountSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  ChildCommentsConnectionDistinctCountSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  ChildCommentsConnectionDistinctCountTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  ChildCommentsConnectionDistinctCountTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  ChildCommentsConnectionDistinctCountTypeAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ChildCommentsConnectionDistinctCountTypeDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ChildCommentsConnectionDistinctCountUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ChildCommentsConnectionDistinctCountUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ChildCommentsConnectionDistinctCountXAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_X_ASC',
  ChildCommentsConnectionDistinctCountXDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_X_DESC',
  ChildCommentsConnectionDistinctCountYAsc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_Y_ASC',
  ChildCommentsConnectionDistinctCountYDesc = 'CHILD_COMMENTS_CONNECTION_DISTINCT_COUNT_Y_DESC',
  ChildCommentsConnectionMaxAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_MAX_ADDITIONAL_ASC',
  ChildCommentsConnectionMaxAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_MAX_ADDITIONAL_DESC',
  ChildCommentsConnectionMaxAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_ANNOTATION_ID_ASC',
  ChildCommentsConnectionMaxAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_ANNOTATION_ID_DESC',
  ChildCommentsConnectionMaxCommentAsc = 'CHILD_COMMENTS_CONNECTION_MAX_COMMENT_ASC',
  ChildCommentsConnectionMaxCommentDesc = 'CHILD_COMMENTS_CONNECTION_MAX_COMMENT_DESC',
  ChildCommentsConnectionMaxCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ChildCommentsConnectionMaxCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ChildCommentsConnectionMaxCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_AT_ASC',
  ChildCommentsConnectionMaxCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_AT_DESC',
  ChildCommentsConnectionMaxCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_ASC',
  ChildCommentsConnectionMaxCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionMaxCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionMaxCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_DESC',
  ChildCommentsConnectionMaxCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionMaxCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionMaxFileIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_FILE_ID_ASC',
  ChildCommentsConnectionMaxFileIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_FILE_ID_DESC',
  ChildCommentsConnectionMaxIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_ID_ASC',
  ChildCommentsConnectionMaxIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_ID_DESC',
  ChildCommentsConnectionMaxIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_MAX_IS_PINNED_ASC',
  ChildCommentsConnectionMaxIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_MAX_IS_PINNED_DESC',
  ChildCommentsConnectionMaxParentIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_PARENT_ID_ASC',
  ChildCommentsConnectionMaxParentIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_PARENT_ID_DESC',
  ChildCommentsConnectionMaxPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_MAX_PINNED_AT_ASC',
  ChildCommentsConnectionMaxPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_MAX_PINNED_AT_DESC',
  ChildCommentsConnectionMaxPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_PREVIOUS_ID_ASC',
  ChildCommentsConnectionMaxPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_PREVIOUS_ID_DESC',
  ChildCommentsConnectionMaxProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ChildCommentsConnectionMaxProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ChildCommentsConnectionMaxScopeAsc = 'CHILD_COMMENTS_CONNECTION_MAX_SCOPE_ASC',
  ChildCommentsConnectionMaxScopeDesc = 'CHILD_COMMENTS_CONNECTION_MAX_SCOPE_DESC',
  ChildCommentsConnectionMaxSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_SYSTEM_ID_ASC',
  ChildCommentsConnectionMaxSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_SYSTEM_ID_DESC',
  ChildCommentsConnectionMaxTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_MAX_TASK_ID_ASC',
  ChildCommentsConnectionMaxTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_MAX_TASK_ID_DESC',
  ChildCommentsConnectionMaxTypeAsc = 'CHILD_COMMENTS_CONNECTION_MAX_TYPE_ASC',
  ChildCommentsConnectionMaxTypeDesc = 'CHILD_COMMENTS_CONNECTION_MAX_TYPE_DESC',
  ChildCommentsConnectionMaxUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ChildCommentsConnectionMaxUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ChildCommentsConnectionMaxXAsc = 'CHILD_COMMENTS_CONNECTION_MAX_X_ASC',
  ChildCommentsConnectionMaxXDesc = 'CHILD_COMMENTS_CONNECTION_MAX_X_DESC',
  ChildCommentsConnectionMaxYAsc = 'CHILD_COMMENTS_CONNECTION_MAX_Y_ASC',
  ChildCommentsConnectionMaxYDesc = 'CHILD_COMMENTS_CONNECTION_MAX_Y_DESC',
  ChildCommentsConnectionMinAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_MIN_ADDITIONAL_ASC',
  ChildCommentsConnectionMinAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_MIN_ADDITIONAL_DESC',
  ChildCommentsConnectionMinAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_ANNOTATION_ID_ASC',
  ChildCommentsConnectionMinAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_ANNOTATION_ID_DESC',
  ChildCommentsConnectionMinCommentAsc = 'CHILD_COMMENTS_CONNECTION_MIN_COMMENT_ASC',
  ChildCommentsConnectionMinCommentDesc = 'CHILD_COMMENTS_CONNECTION_MIN_COMMENT_DESC',
  ChildCommentsConnectionMinCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ChildCommentsConnectionMinCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ChildCommentsConnectionMinCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_AT_ASC',
  ChildCommentsConnectionMinCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_AT_DESC',
  ChildCommentsConnectionMinCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_ASC',
  ChildCommentsConnectionMinCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionMinCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionMinCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_DESC',
  ChildCommentsConnectionMinCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionMinCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionMinFileIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_FILE_ID_ASC',
  ChildCommentsConnectionMinFileIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_FILE_ID_DESC',
  ChildCommentsConnectionMinIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_ID_ASC',
  ChildCommentsConnectionMinIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_ID_DESC',
  ChildCommentsConnectionMinIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_MIN_IS_PINNED_ASC',
  ChildCommentsConnectionMinIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_MIN_IS_PINNED_DESC',
  ChildCommentsConnectionMinParentIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_PARENT_ID_ASC',
  ChildCommentsConnectionMinParentIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_PARENT_ID_DESC',
  ChildCommentsConnectionMinPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_MIN_PINNED_AT_ASC',
  ChildCommentsConnectionMinPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_MIN_PINNED_AT_DESC',
  ChildCommentsConnectionMinPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_PREVIOUS_ID_ASC',
  ChildCommentsConnectionMinPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_PREVIOUS_ID_DESC',
  ChildCommentsConnectionMinProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ChildCommentsConnectionMinProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ChildCommentsConnectionMinScopeAsc = 'CHILD_COMMENTS_CONNECTION_MIN_SCOPE_ASC',
  ChildCommentsConnectionMinScopeDesc = 'CHILD_COMMENTS_CONNECTION_MIN_SCOPE_DESC',
  ChildCommentsConnectionMinSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_SYSTEM_ID_ASC',
  ChildCommentsConnectionMinSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_SYSTEM_ID_DESC',
  ChildCommentsConnectionMinTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_MIN_TASK_ID_ASC',
  ChildCommentsConnectionMinTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_MIN_TASK_ID_DESC',
  ChildCommentsConnectionMinTypeAsc = 'CHILD_COMMENTS_CONNECTION_MIN_TYPE_ASC',
  ChildCommentsConnectionMinTypeDesc = 'CHILD_COMMENTS_CONNECTION_MIN_TYPE_DESC',
  ChildCommentsConnectionMinUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ChildCommentsConnectionMinUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ChildCommentsConnectionMinXAsc = 'CHILD_COMMENTS_CONNECTION_MIN_X_ASC',
  ChildCommentsConnectionMinXDesc = 'CHILD_COMMENTS_CONNECTION_MIN_X_DESC',
  ChildCommentsConnectionMinYAsc = 'CHILD_COMMENTS_CONNECTION_MIN_Y_ASC',
  ChildCommentsConnectionMinYDesc = 'CHILD_COMMENTS_CONNECTION_MIN_Y_DESC',
  ChildCommentsConnectionStddevPopulationAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  ChildCommentsConnectionStddevPopulationAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  ChildCommentsConnectionStddevPopulationAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  ChildCommentsConnectionStddevPopulationAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  ChildCommentsConnectionStddevPopulationCommentAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  ChildCommentsConnectionStddevPopulationCommentDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  ChildCommentsConnectionStddevPopulationCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ChildCommentsConnectionStddevPopulationCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ChildCommentsConnectionStddevPopulationCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ChildCommentsConnectionStddevPopulationCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ChildCommentsConnectionStddevPopulationCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ChildCommentsConnectionStddevPopulationCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionStddevPopulationCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionStddevPopulationCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ChildCommentsConnectionStddevPopulationCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionStddevPopulationCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionStddevPopulationFileIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  ChildCommentsConnectionStddevPopulationFileIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  ChildCommentsConnectionStddevPopulationIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ChildCommentsConnectionStddevPopulationIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ChildCommentsConnectionStddevPopulationIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  ChildCommentsConnectionStddevPopulationIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  ChildCommentsConnectionStddevPopulationParentIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  ChildCommentsConnectionStddevPopulationParentIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  ChildCommentsConnectionStddevPopulationPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  ChildCommentsConnectionStddevPopulationPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  ChildCommentsConnectionStddevPopulationPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  ChildCommentsConnectionStddevPopulationPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  ChildCommentsConnectionStddevPopulationProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ChildCommentsConnectionStddevPopulationProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ChildCommentsConnectionStddevPopulationScopeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ChildCommentsConnectionStddevPopulationScopeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ChildCommentsConnectionStddevPopulationSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  ChildCommentsConnectionStddevPopulationSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  ChildCommentsConnectionStddevPopulationTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  ChildCommentsConnectionStddevPopulationTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  ChildCommentsConnectionStddevPopulationTypeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ChildCommentsConnectionStddevPopulationTypeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ChildCommentsConnectionStddevPopulationUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ChildCommentsConnectionStddevPopulationUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ChildCommentsConnectionStddevPopulationXAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_X_ASC',
  ChildCommentsConnectionStddevPopulationXDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_X_DESC',
  ChildCommentsConnectionStddevPopulationYAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_Y_ASC',
  ChildCommentsConnectionStddevPopulationYDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_POPULATION_Y_DESC',
  ChildCommentsConnectionStddevSampleAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  ChildCommentsConnectionStddevSampleAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  ChildCommentsConnectionStddevSampleAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  ChildCommentsConnectionStddevSampleAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  ChildCommentsConnectionStddevSampleCommentAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  ChildCommentsConnectionStddevSampleCommentDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  ChildCommentsConnectionStddevSampleCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ChildCommentsConnectionStddevSampleCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ChildCommentsConnectionStddevSampleCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ChildCommentsConnectionStddevSampleCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ChildCommentsConnectionStddevSampleCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ChildCommentsConnectionStddevSampleCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionStddevSampleCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionStddevSampleCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ChildCommentsConnectionStddevSampleCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionStddevSampleCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionStddevSampleFileIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  ChildCommentsConnectionStddevSampleFileIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  ChildCommentsConnectionStddevSampleIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ChildCommentsConnectionStddevSampleIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ChildCommentsConnectionStddevSampleIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  ChildCommentsConnectionStddevSampleIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  ChildCommentsConnectionStddevSampleParentIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  ChildCommentsConnectionStddevSampleParentIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  ChildCommentsConnectionStddevSamplePinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  ChildCommentsConnectionStddevSamplePinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  ChildCommentsConnectionStddevSamplePreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  ChildCommentsConnectionStddevSamplePreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  ChildCommentsConnectionStddevSampleProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ChildCommentsConnectionStddevSampleProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ChildCommentsConnectionStddevSampleScopeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ChildCommentsConnectionStddevSampleScopeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ChildCommentsConnectionStddevSampleSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  ChildCommentsConnectionStddevSampleSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  ChildCommentsConnectionStddevSampleTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  ChildCommentsConnectionStddevSampleTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  ChildCommentsConnectionStddevSampleTypeAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ChildCommentsConnectionStddevSampleTypeDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ChildCommentsConnectionStddevSampleUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ChildCommentsConnectionStddevSampleUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ChildCommentsConnectionStddevSampleXAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_X_ASC',
  ChildCommentsConnectionStddevSampleXDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_X_DESC',
  ChildCommentsConnectionStddevSampleYAsc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  ChildCommentsConnectionStddevSampleYDesc = 'CHILD_COMMENTS_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  ChildCommentsConnectionSumAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_SUM_ADDITIONAL_ASC',
  ChildCommentsConnectionSumAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_SUM_ADDITIONAL_DESC',
  ChildCommentsConnectionSumAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_ANNOTATION_ID_ASC',
  ChildCommentsConnectionSumAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_ANNOTATION_ID_DESC',
  ChildCommentsConnectionSumCommentAsc = 'CHILD_COMMENTS_CONNECTION_SUM_COMMENT_ASC',
  ChildCommentsConnectionSumCommentDesc = 'CHILD_COMMENTS_CONNECTION_SUM_COMMENT_DESC',
  ChildCommentsConnectionSumCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ChildCommentsConnectionSumCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ChildCommentsConnectionSumCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_AT_ASC',
  ChildCommentsConnectionSumCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_AT_DESC',
  ChildCommentsConnectionSumCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_ASC',
  ChildCommentsConnectionSumCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionSumCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionSumCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_DESC',
  ChildCommentsConnectionSumCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionSumCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionSumFileIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_FILE_ID_ASC',
  ChildCommentsConnectionSumFileIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_FILE_ID_DESC',
  ChildCommentsConnectionSumIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_ID_ASC',
  ChildCommentsConnectionSumIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_ID_DESC',
  ChildCommentsConnectionSumIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_SUM_IS_PINNED_ASC',
  ChildCommentsConnectionSumIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_SUM_IS_PINNED_DESC',
  ChildCommentsConnectionSumParentIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_PARENT_ID_ASC',
  ChildCommentsConnectionSumParentIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_PARENT_ID_DESC',
  ChildCommentsConnectionSumPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_SUM_PINNED_AT_ASC',
  ChildCommentsConnectionSumPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_SUM_PINNED_AT_DESC',
  ChildCommentsConnectionSumPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_PREVIOUS_ID_ASC',
  ChildCommentsConnectionSumPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_PREVIOUS_ID_DESC',
  ChildCommentsConnectionSumProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ChildCommentsConnectionSumProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ChildCommentsConnectionSumScopeAsc = 'CHILD_COMMENTS_CONNECTION_SUM_SCOPE_ASC',
  ChildCommentsConnectionSumScopeDesc = 'CHILD_COMMENTS_CONNECTION_SUM_SCOPE_DESC',
  ChildCommentsConnectionSumSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_SYSTEM_ID_ASC',
  ChildCommentsConnectionSumSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_SYSTEM_ID_DESC',
  ChildCommentsConnectionSumTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_SUM_TASK_ID_ASC',
  ChildCommentsConnectionSumTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_SUM_TASK_ID_DESC',
  ChildCommentsConnectionSumTypeAsc = 'CHILD_COMMENTS_CONNECTION_SUM_TYPE_ASC',
  ChildCommentsConnectionSumTypeDesc = 'CHILD_COMMENTS_CONNECTION_SUM_TYPE_DESC',
  ChildCommentsConnectionSumUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ChildCommentsConnectionSumUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ChildCommentsConnectionSumXAsc = 'CHILD_COMMENTS_CONNECTION_SUM_X_ASC',
  ChildCommentsConnectionSumXDesc = 'CHILD_COMMENTS_CONNECTION_SUM_X_DESC',
  ChildCommentsConnectionSumYAsc = 'CHILD_COMMENTS_CONNECTION_SUM_Y_ASC',
  ChildCommentsConnectionSumYDesc = 'CHILD_COMMENTS_CONNECTION_SUM_Y_DESC',
  ChildCommentsConnectionVariancePopulationAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  ChildCommentsConnectionVariancePopulationAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  ChildCommentsConnectionVariancePopulationAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  ChildCommentsConnectionVariancePopulationAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  ChildCommentsConnectionVariancePopulationCommentAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  ChildCommentsConnectionVariancePopulationCommentDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  ChildCommentsConnectionVariancePopulationCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ChildCommentsConnectionVariancePopulationCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ChildCommentsConnectionVariancePopulationCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ChildCommentsConnectionVariancePopulationCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ChildCommentsConnectionVariancePopulationCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ChildCommentsConnectionVariancePopulationCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionVariancePopulationCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionVariancePopulationCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ChildCommentsConnectionVariancePopulationCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionVariancePopulationCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionVariancePopulationFileIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  ChildCommentsConnectionVariancePopulationFileIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  ChildCommentsConnectionVariancePopulationIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ChildCommentsConnectionVariancePopulationIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ChildCommentsConnectionVariancePopulationIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  ChildCommentsConnectionVariancePopulationIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  ChildCommentsConnectionVariancePopulationParentIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  ChildCommentsConnectionVariancePopulationParentIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  ChildCommentsConnectionVariancePopulationPinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  ChildCommentsConnectionVariancePopulationPinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  ChildCommentsConnectionVariancePopulationPreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  ChildCommentsConnectionVariancePopulationPreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  ChildCommentsConnectionVariancePopulationProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ChildCommentsConnectionVariancePopulationProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ChildCommentsConnectionVariancePopulationScopeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ChildCommentsConnectionVariancePopulationScopeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ChildCommentsConnectionVariancePopulationSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  ChildCommentsConnectionVariancePopulationSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  ChildCommentsConnectionVariancePopulationTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  ChildCommentsConnectionVariancePopulationTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  ChildCommentsConnectionVariancePopulationTypeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ChildCommentsConnectionVariancePopulationTypeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ChildCommentsConnectionVariancePopulationUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ChildCommentsConnectionVariancePopulationUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ChildCommentsConnectionVariancePopulationXAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_X_ASC',
  ChildCommentsConnectionVariancePopulationXDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_X_DESC',
  ChildCommentsConnectionVariancePopulationYAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  ChildCommentsConnectionVariancePopulationYDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  ChildCommentsConnectionVarianceSampleAdditionalAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  ChildCommentsConnectionVarianceSampleAdditionalDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  ChildCommentsConnectionVarianceSampleAnnotationIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  ChildCommentsConnectionVarianceSampleAnnotationIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  ChildCommentsConnectionVarianceSampleCommentAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  ChildCommentsConnectionVarianceSampleCommentDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  ChildCommentsConnectionVarianceSampleCompanyIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ChildCommentsConnectionVarianceSampleCompanyIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ChildCommentsConnectionVarianceSampleCreatedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ChildCommentsConnectionVarianceSampleCreatedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ChildCommentsConnectionVarianceSampleCreatedByAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ChildCommentsConnectionVarianceSampleCreatedByContactIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ChildCommentsConnectionVarianceSampleCreatedByContactIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ChildCommentsConnectionVarianceSampleCreatedByDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ChildCommentsConnectionVarianceSampleCreatedByTypeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ChildCommentsConnectionVarianceSampleCreatedByTypeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ChildCommentsConnectionVarianceSampleFileIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  ChildCommentsConnectionVarianceSampleFileIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  ChildCommentsConnectionVarianceSampleIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ChildCommentsConnectionVarianceSampleIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ChildCommentsConnectionVarianceSampleIsPinnedAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  ChildCommentsConnectionVarianceSampleIsPinnedDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  ChildCommentsConnectionVarianceSampleParentIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ChildCommentsConnectionVarianceSampleParentIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  ChildCommentsConnectionVarianceSamplePinnedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  ChildCommentsConnectionVarianceSamplePinnedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  ChildCommentsConnectionVarianceSamplePreviousIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  ChildCommentsConnectionVarianceSamplePreviousIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  ChildCommentsConnectionVarianceSampleProjectIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ChildCommentsConnectionVarianceSampleProjectIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ChildCommentsConnectionVarianceSampleScopeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ChildCommentsConnectionVarianceSampleScopeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ChildCommentsConnectionVarianceSampleSystemIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  ChildCommentsConnectionVarianceSampleSystemIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  ChildCommentsConnectionVarianceSampleTaskIdAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  ChildCommentsConnectionVarianceSampleTaskIdDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  ChildCommentsConnectionVarianceSampleTypeAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ChildCommentsConnectionVarianceSampleTypeDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ChildCommentsConnectionVarianceSampleUpdatedAtAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ChildCommentsConnectionVarianceSampleUpdatedAtDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ChildCommentsConnectionVarianceSampleXAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  ChildCommentsConnectionVarianceSampleXDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  ChildCommentsConnectionVarianceSampleYAsc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  ChildCommentsConnectionVarianceSampleYDesc = 'CHILD_COMMENTS_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByContactIdAsc = 'CREATED_BY_CONTACT_ID_ASC',
  CreatedByContactIdDesc = 'CREATED_BY_CONTACT_ID_DESC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedByTypeAsc = 'CREATED_BY_TYPE_ASC',
  CreatedByTypeDesc = 'CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggActionIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsConnectionArrayAggActionIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsConnectionArrayAggCallIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsConnectionArrayAggCallIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsConnectionArrayAggCommentAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsConnectionArrayAggCommentDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsConnectionArrayAggCommentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsConnectionArrayAggCommentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsConnectionArrayAggCompanyIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsConnectionArrayAggCompanyIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsConnectionArrayAggCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsConnectionArrayAggCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsConnectionArrayAggCreatedByAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionArrayAggCreatedByDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsConnectionArrayAggCreatedByTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsConnectionArrayAggCreatedByTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggEmailMessageIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionArrayAggEmailMessageIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionArrayAggEventAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsConnectionArrayAggEventDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsConnectionArrayAggEventTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsConnectionArrayAggEventTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsConnectionArrayAggFileIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsConnectionArrayAggFileIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsConnectionArrayAggIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsConnectionArrayAggIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsConnectionArrayAggParentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsConnectionArrayAggParentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsConnectionArrayAggPayloadAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsConnectionArrayAggPayloadDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsConnectionArrayAggProjectIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsConnectionArrayAggProjectIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsConnectionArrayAggReminderIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsConnectionArrayAggReminderIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsConnectionArrayAggRetriedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsConnectionArrayAggRetriedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsConnectionArrayAggRetryableAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsConnectionArrayAggRetryableDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsConnectionArrayAggRetryCountAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsConnectionArrayAggRetryCountDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsConnectionArrayAggSearchTextAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsConnectionArrayAggSearchTextDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsConnectionArrayAggSmsActivityIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionArrayAggSmsActivityIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionArrayAggSystemIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsConnectionArrayAggSystemIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsConnectionArrayAggTaskIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsConnectionArrayAggTaskIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionArrayAggWorkflowIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsConnectionArrayAggWorkflowIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsConnectionAverageActionIdAsc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsConnectionAverageActionIdDesc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsConnectionAverageCallIdAsc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsConnectionAverageCallIdDesc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsConnectionAverageCommentAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsConnectionAverageCommentDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsConnectionAverageCommentIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsConnectionAverageCommentIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsConnectionAverageCompanyIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsConnectionAverageCompanyIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsConnectionAverageCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsConnectionAverageCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsConnectionAverageCreatedByAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsConnectionAverageCreatedByContactIdAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionAverageCreatedByContactIdDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionAverageCreatedByDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsConnectionAverageCreatedByTypeAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsConnectionAverageCreatedByTypeDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsConnectionAverageEmailMessageIdAsc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionAverageEmailMessageIdDesc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionAverageEventAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsConnectionAverageEventDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsConnectionAverageEventTypeAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsConnectionAverageEventTypeDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsConnectionAverageFileIdAsc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsConnectionAverageFileIdDesc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsConnectionAverageIdAsc = 'FEEDS_CONNECTION_AVERAGE_ID_ASC',
  FeedsConnectionAverageIdDesc = 'FEEDS_CONNECTION_AVERAGE_ID_DESC',
  FeedsConnectionAverageParentIdAsc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsConnectionAverageParentIdDesc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsConnectionAveragePayloadAsc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsConnectionAveragePayloadDesc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsConnectionAverageProjectIdAsc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsConnectionAverageProjectIdDesc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsConnectionAverageReminderIdAsc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsConnectionAverageReminderIdDesc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsConnectionAverageRetriedAtAsc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsConnectionAverageRetriedAtDesc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsConnectionAverageRetryableAsc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsConnectionAverageRetryableDesc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsConnectionAverageRetryCountAsc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsConnectionAverageRetryCountDesc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsConnectionAverageSearchTextAsc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsConnectionAverageSearchTextDesc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsConnectionAverageSmsActivityIdAsc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionAverageSmsActivityIdDesc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionAverageSystemIdAsc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsConnectionAverageSystemIdDesc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsConnectionAverageTaskIdAsc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsConnectionAverageTaskIdDesc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsConnectionAverageVirtualCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionAverageVirtualCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionAverageWorkflowIdAsc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsConnectionAverageWorkflowIdDesc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsConnectionCountAsc = 'FEEDS_CONNECTION_COUNT_ASC',
  FeedsConnectionCountDesc = 'FEEDS_CONNECTION_COUNT_DESC',
  FeedsConnectionDistinctCountActionIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsConnectionDistinctCountActionIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsConnectionDistinctCountCallIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsConnectionDistinctCountCallIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsConnectionDistinctCountCommentAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsConnectionDistinctCountCommentDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsConnectionDistinctCountCommentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsConnectionDistinctCountCommentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsConnectionDistinctCountCompanyIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsConnectionDistinctCountCompanyIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsConnectionDistinctCountCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsConnectionDistinctCountCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsConnectionDistinctCountCreatedByAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionDistinctCountCreatedByDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionDistinctCountEventAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsConnectionDistinctCountEventDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsConnectionDistinctCountEventTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsConnectionDistinctCountEventTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsConnectionDistinctCountFileIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsConnectionDistinctCountFileIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsConnectionDistinctCountIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsConnectionDistinctCountIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsConnectionDistinctCountParentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsConnectionDistinctCountParentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsConnectionDistinctCountPayloadAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsConnectionDistinctCountPayloadDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsConnectionDistinctCountProjectIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsConnectionDistinctCountProjectIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsConnectionDistinctCountReminderIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsConnectionDistinctCountReminderIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsConnectionDistinctCountRetriedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsConnectionDistinctCountRetriedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsConnectionDistinctCountRetryableAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsConnectionDistinctCountRetryableDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsConnectionDistinctCountRetryCountAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsConnectionDistinctCountRetryCountDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsConnectionDistinctCountSearchTextAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsConnectionDistinctCountSearchTextDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionDistinctCountSystemIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsConnectionDistinctCountSystemIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsConnectionDistinctCountTaskIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsConnectionDistinctCountTaskIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionDistinctCountWorkflowIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsConnectionDistinctCountWorkflowIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsConnectionMaxActionIdAsc = 'FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsConnectionMaxActionIdDesc = 'FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsConnectionMaxCallIdAsc = 'FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  FeedsConnectionMaxCallIdDesc = 'FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  FeedsConnectionMaxCommentAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ASC',
  FeedsConnectionMaxCommentDesc = 'FEEDS_CONNECTION_MAX_COMMENT_DESC',
  FeedsConnectionMaxCommentIdAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsConnectionMaxCommentIdDesc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsConnectionMaxCompanyIdAsc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsConnectionMaxCompanyIdDesc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsConnectionMaxCreatedAtAsc = 'FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsConnectionMaxCreatedAtDesc = 'FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsConnectionMaxCreatedByAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsConnectionMaxCreatedByContactIdAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMaxCreatedByContactIdDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMaxCreatedByDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsConnectionMaxCreatedByTypeAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsConnectionMaxCreatedByTypeDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsConnectionMaxEmailMessageIdAsc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMaxEmailMessageIdDesc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMaxEventAsc = 'FEEDS_CONNECTION_MAX_EVENT_ASC',
  FeedsConnectionMaxEventDesc = 'FEEDS_CONNECTION_MAX_EVENT_DESC',
  FeedsConnectionMaxEventTypeAsc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsConnectionMaxEventTypeDesc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsConnectionMaxFileIdAsc = 'FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  FeedsConnectionMaxFileIdDesc = 'FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  FeedsConnectionMaxIdAsc = 'FEEDS_CONNECTION_MAX_ID_ASC',
  FeedsConnectionMaxIdDesc = 'FEEDS_CONNECTION_MAX_ID_DESC',
  FeedsConnectionMaxParentIdAsc = 'FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsConnectionMaxParentIdDesc = 'FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsConnectionMaxPayloadAsc = 'FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsConnectionMaxPayloadDesc = 'FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsConnectionMaxProjectIdAsc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsConnectionMaxProjectIdDesc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsConnectionMaxReminderIdAsc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsConnectionMaxReminderIdDesc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsConnectionMaxRetriedAtAsc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsConnectionMaxRetriedAtDesc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsConnectionMaxRetryableAsc = 'FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsConnectionMaxRetryableDesc = 'FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsConnectionMaxRetryCountAsc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsConnectionMaxRetryCountDesc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsConnectionMaxSearchTextAsc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsConnectionMaxSearchTextDesc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsConnectionMaxSmsActivityIdAsc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMaxSmsActivityIdDesc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMaxSystemIdAsc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsConnectionMaxSystemIdDesc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsConnectionMaxTaskIdAsc = 'FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  FeedsConnectionMaxTaskIdDesc = 'FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  FeedsConnectionMaxVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMaxVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMaxWorkflowIdAsc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsConnectionMaxWorkflowIdDesc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsConnectionMinActionIdAsc = 'FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsConnectionMinActionIdDesc = 'FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsConnectionMinCallIdAsc = 'FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  FeedsConnectionMinCallIdDesc = 'FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  FeedsConnectionMinCommentAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ASC',
  FeedsConnectionMinCommentDesc = 'FEEDS_CONNECTION_MIN_COMMENT_DESC',
  FeedsConnectionMinCommentIdAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsConnectionMinCommentIdDesc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsConnectionMinCompanyIdAsc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsConnectionMinCompanyIdDesc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsConnectionMinCreatedAtAsc = 'FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsConnectionMinCreatedAtDesc = 'FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsConnectionMinCreatedByAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsConnectionMinCreatedByContactIdAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMinCreatedByContactIdDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMinCreatedByDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsConnectionMinCreatedByTypeAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsConnectionMinCreatedByTypeDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsConnectionMinEmailMessageIdAsc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMinEmailMessageIdDesc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMinEventAsc = 'FEEDS_CONNECTION_MIN_EVENT_ASC',
  FeedsConnectionMinEventDesc = 'FEEDS_CONNECTION_MIN_EVENT_DESC',
  FeedsConnectionMinEventTypeAsc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsConnectionMinEventTypeDesc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsConnectionMinFileIdAsc = 'FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  FeedsConnectionMinFileIdDesc = 'FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  FeedsConnectionMinIdAsc = 'FEEDS_CONNECTION_MIN_ID_ASC',
  FeedsConnectionMinIdDesc = 'FEEDS_CONNECTION_MIN_ID_DESC',
  FeedsConnectionMinParentIdAsc = 'FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsConnectionMinParentIdDesc = 'FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsConnectionMinPayloadAsc = 'FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsConnectionMinPayloadDesc = 'FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsConnectionMinProjectIdAsc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsConnectionMinProjectIdDesc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsConnectionMinReminderIdAsc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsConnectionMinReminderIdDesc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsConnectionMinRetriedAtAsc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsConnectionMinRetriedAtDesc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsConnectionMinRetryableAsc = 'FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsConnectionMinRetryableDesc = 'FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsConnectionMinRetryCountAsc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsConnectionMinRetryCountDesc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsConnectionMinSearchTextAsc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsConnectionMinSearchTextDesc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsConnectionMinSmsActivityIdAsc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMinSmsActivityIdDesc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMinSystemIdAsc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsConnectionMinSystemIdDesc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsConnectionMinTaskIdAsc = 'FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  FeedsConnectionMinTaskIdDesc = 'FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  FeedsConnectionMinVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMinVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMinWorkflowIdAsc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsConnectionMinWorkflowIdDesc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsConnectionStddevPopulationActionIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsConnectionStddevPopulationActionIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsConnectionStddevPopulationCallIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsConnectionStddevPopulationCallIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsConnectionStddevPopulationCommentAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsConnectionStddevPopulationCommentDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsConnectionStddevPopulationCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionStddevPopulationCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionStddevPopulationCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionStddevPopulationCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionStddevPopulationCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevPopulationCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevPopulationEventAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsConnectionStddevPopulationEventDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsConnectionStddevPopulationEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionStddevPopulationEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionStddevPopulationFileIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsConnectionStddevPopulationFileIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsConnectionStddevPopulationIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsConnectionStddevPopulationIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsConnectionStddevPopulationParentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsConnectionStddevPopulationParentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsConnectionStddevPopulationPayloadAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsConnectionStddevPopulationPayloadDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsConnectionStddevPopulationProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionStddevPopulationProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionStddevPopulationReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionStddevPopulationReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionStddevPopulationRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionStddevPopulationRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionStddevPopulationRetryableAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsConnectionStddevPopulationRetryableDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsConnectionStddevPopulationRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionStddevPopulationRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionStddevPopulationSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionStddevPopulationSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevPopulationSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionStddevPopulationSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionStddevPopulationTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsConnectionStddevPopulationTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionStddevSampleActionIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionStddevSampleActionIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionStddevSampleCallIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsConnectionStddevSampleCallIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsConnectionStddevSampleCommentAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsConnectionStddevSampleCommentDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsConnectionStddevSampleCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionStddevSampleCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionStddevSampleCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionStddevSampleCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionStddevSampleCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionStddevSampleCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionStddevSampleCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevSampleCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevSampleEventAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsConnectionStddevSampleEventDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsConnectionStddevSampleEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionStddevSampleEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionStddevSampleFileIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsConnectionStddevSampleFileIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsConnectionStddevSampleIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsConnectionStddevSampleIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsConnectionStddevSampleParentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionStddevSampleParentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionStddevSamplePayloadAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionStddevSamplePayloadDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionStddevSampleProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionStddevSampleProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionStddevSampleReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionStddevSampleReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionStddevSampleRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionStddevSampleRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionStddevSampleRetryableAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionStddevSampleRetryableDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionStddevSampleRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionStddevSampleRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionStddevSampleSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionStddevSampleSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevSampleSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionStddevSampleSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionStddevSampleTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsConnectionStddevSampleTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevSampleWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionStddevSampleWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsConnectionSumActionIdAsc = 'FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsConnectionSumActionIdDesc = 'FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsConnectionSumCallIdAsc = 'FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  FeedsConnectionSumCallIdDesc = 'FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  FeedsConnectionSumCommentAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ASC',
  FeedsConnectionSumCommentDesc = 'FEEDS_CONNECTION_SUM_COMMENT_DESC',
  FeedsConnectionSumCommentIdAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsConnectionSumCommentIdDesc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsConnectionSumCompanyIdAsc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsConnectionSumCompanyIdDesc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsConnectionSumCreatedAtAsc = 'FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsConnectionSumCreatedAtDesc = 'FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsConnectionSumCreatedByAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsConnectionSumCreatedByContactIdAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionSumCreatedByContactIdDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionSumCreatedByDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsConnectionSumCreatedByTypeAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsConnectionSumCreatedByTypeDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsConnectionSumEmailMessageIdAsc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionSumEmailMessageIdDesc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionSumEventAsc = 'FEEDS_CONNECTION_SUM_EVENT_ASC',
  FeedsConnectionSumEventDesc = 'FEEDS_CONNECTION_SUM_EVENT_DESC',
  FeedsConnectionSumEventTypeAsc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsConnectionSumEventTypeDesc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsConnectionSumFileIdAsc = 'FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  FeedsConnectionSumFileIdDesc = 'FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  FeedsConnectionSumIdAsc = 'FEEDS_CONNECTION_SUM_ID_ASC',
  FeedsConnectionSumIdDesc = 'FEEDS_CONNECTION_SUM_ID_DESC',
  FeedsConnectionSumParentIdAsc = 'FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsConnectionSumParentIdDesc = 'FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsConnectionSumPayloadAsc = 'FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsConnectionSumPayloadDesc = 'FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsConnectionSumProjectIdAsc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsConnectionSumProjectIdDesc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsConnectionSumReminderIdAsc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsConnectionSumReminderIdDesc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsConnectionSumRetriedAtAsc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsConnectionSumRetriedAtDesc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsConnectionSumRetryableAsc = 'FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsConnectionSumRetryableDesc = 'FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsConnectionSumRetryCountAsc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsConnectionSumRetryCountDesc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsConnectionSumSearchTextAsc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsConnectionSumSearchTextDesc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsConnectionSumSmsActivityIdAsc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionSumSmsActivityIdDesc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionSumSystemIdAsc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsConnectionSumSystemIdDesc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsConnectionSumTaskIdAsc = 'FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  FeedsConnectionSumTaskIdDesc = 'FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  FeedsConnectionSumVirtualCreatedAtAsc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionSumVirtualCreatedAtDesc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionSumWorkflowIdAsc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsConnectionSumWorkflowIdDesc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsConnectionVariancePopulationActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsConnectionVariancePopulationActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsConnectionVariancePopulationCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsConnectionVariancePopulationCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsConnectionVariancePopulationCommentAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsConnectionVariancePopulationCommentDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsConnectionVariancePopulationCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionVariancePopulationCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionVariancePopulationCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionVariancePopulationCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionVariancePopulationCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVariancePopulationCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVariancePopulationEventAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsConnectionVariancePopulationEventDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsConnectionVariancePopulationEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionVariancePopulationEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionVariancePopulationFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsConnectionVariancePopulationFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsConnectionVariancePopulationIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsConnectionVariancePopulationIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsConnectionVariancePopulationParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsConnectionVariancePopulationParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsConnectionVariancePopulationPayloadAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsConnectionVariancePopulationPayloadDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsConnectionVariancePopulationProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionVariancePopulationProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionVariancePopulationReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionVariancePopulationReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionVariancePopulationRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionVariancePopulationRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionVariancePopulationRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsConnectionVariancePopulationRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsConnectionVariancePopulationRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionVariancePopulationRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionVariancePopulationSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionVariancePopulationSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVariancePopulationSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionVariancePopulationSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionVariancePopulationTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsConnectionVariancePopulationTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionVarianceSampleActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionVarianceSampleActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionVarianceSampleCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsConnectionVarianceSampleCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsConnectionVarianceSampleCommentAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsConnectionVarianceSampleCommentDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsConnectionVarianceSampleCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionVarianceSampleCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionVarianceSampleCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionVarianceSampleCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionVarianceSampleCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVarianceSampleCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVarianceSampleEventAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsConnectionVarianceSampleEventDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsConnectionVarianceSampleEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionVarianceSampleEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionVarianceSampleFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsConnectionVarianceSampleFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsConnectionVarianceSampleIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsConnectionVarianceSampleIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsConnectionVarianceSampleParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionVarianceSampleParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionVarianceSamplePayloadAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionVarianceSamplePayloadDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionVarianceSampleProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionVarianceSampleProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionVarianceSampleReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionVarianceSampleReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionVarianceSampleRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionVarianceSampleRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionVarianceSampleRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionVarianceSampleRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionVarianceSampleRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionVarianceSampleRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionVarianceSampleSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionVarianceSampleSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVarianceSampleSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionVarianceSampleSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionVarianceSampleTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsConnectionVarianceSampleTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FilesConnectionArrayAggActionIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesConnectionArrayAggActionIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesConnectionArrayAggActionItemIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesConnectionArrayAggActionItemIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesConnectionArrayAggAnnotationsAsc = 'FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesConnectionArrayAggAnnotationsDesc = 'FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesConnectionArrayAggCommentIdAsc = 'FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesConnectionArrayAggCommentIdDesc = 'FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesConnectionArrayAggCompanyIdAsc = 'FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesConnectionArrayAggCompanyIdDesc = 'FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesConnectionArrayAggCreatedAtAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesConnectionArrayAggCreatedAtDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesConnectionArrayAggCreatedByAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesConnectionArrayAggCreatedByContactIdAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionArrayAggCreatedByContactIdDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionArrayAggCreatedByDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesConnectionArrayAggCreatedByTypeAsc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesConnectionArrayAggCreatedByTypeDesc = 'FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesConnectionArrayAggDownloadUrlAsc = 'FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesConnectionArrayAggDownloadUrlDesc = 'FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesConnectionArrayAggFormIdAsc = 'FILES_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesConnectionArrayAggFormIdDesc = 'FILES_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesConnectionArrayAggIdAsc = 'FILES_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesConnectionArrayAggIdDesc = 'FILES_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesConnectionArrayAggIsArchivedAsc = 'FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesConnectionArrayAggIsArchivedDesc = 'FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesConnectionArrayAggIsFormAsc = 'FILES_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesConnectionArrayAggIsFormDesc = 'FILES_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesConnectionArrayAggMetaDataAsc = 'FILES_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesConnectionArrayAggMetaDataDesc = 'FILES_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesConnectionArrayAggModifiedByAsc = 'FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesConnectionArrayAggModifiedByDesc = 'FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesConnectionArrayAggNameAsc = 'FILES_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesConnectionArrayAggNameDesc = 'FILES_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesConnectionArrayAggOriginAsc = 'FILES_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesConnectionArrayAggOriginDesc = 'FILES_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesConnectionArrayAggPrivilegeAllAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesConnectionArrayAggPrivilegeAllDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesConnectionArrayAggPrivilegeAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesConnectionArrayAggPrivilegeDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesConnectionArrayAggPrivilegeOwnAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesConnectionArrayAggPrivilegeOwnDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesConnectionArrayAggPrivilegeTeamAsc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesConnectionArrayAggPrivilegeTeamDesc = 'FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesConnectionArrayAggProcessingStatusAsc = 'FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesConnectionArrayAggProcessingStatusDesc = 'FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionArrayAggProjectColumnValueIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionArrayAggProjectColumnValueIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionArrayAggProjectIdAsc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesConnectionArrayAggProjectIdDesc = 'FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesConnectionArrayAggSharedWithPortalAsc = 'FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesConnectionArrayAggSharedWithPortalDesc = 'FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesConnectionArrayAggSizeInBytesAsc = 'FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesConnectionArrayAggSizeInBytesDesc = 'FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesConnectionArrayAggStageIdAsc = 'FILES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesConnectionArrayAggStageIdDesc = 'FILES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesConnectionArrayAggTypeAsc = 'FILES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesConnectionArrayAggTypeDesc = 'FILES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesConnectionArrayAggUpdatedAtAsc = 'FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesConnectionArrayAggUpdatedAtDesc = 'FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesConnectionArrayAggUuidAsc = 'FILES_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesConnectionArrayAggUuidDesc = 'FILES_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesConnectionArrayAggValueFileIdAsc = 'FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesConnectionArrayAggValueFileIdDesc = 'FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesConnectionAverageActionIdAsc = 'FILES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesConnectionAverageActionIdDesc = 'FILES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesConnectionAverageActionItemIdAsc = 'FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesConnectionAverageActionItemIdDesc = 'FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesConnectionAverageAnnotationsAsc = 'FILES_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesConnectionAverageAnnotationsDesc = 'FILES_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesConnectionAverageCommentIdAsc = 'FILES_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesConnectionAverageCommentIdDesc = 'FILES_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesConnectionAverageCompanyIdAsc = 'FILES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesConnectionAverageCompanyIdDesc = 'FILES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesConnectionAverageCreatedAtAsc = 'FILES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesConnectionAverageCreatedAtDesc = 'FILES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesConnectionAverageCreatedByAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesConnectionAverageCreatedByContactIdAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionAverageCreatedByContactIdDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionAverageCreatedByDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesConnectionAverageCreatedByTypeAsc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesConnectionAverageCreatedByTypeDesc = 'FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesConnectionAverageDownloadUrlAsc = 'FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesConnectionAverageDownloadUrlDesc = 'FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesConnectionAverageFormIdAsc = 'FILES_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesConnectionAverageFormIdDesc = 'FILES_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesConnectionAverageIdAsc = 'FILES_CONNECTION_AVERAGE_ID_ASC',
  FilesConnectionAverageIdDesc = 'FILES_CONNECTION_AVERAGE_ID_DESC',
  FilesConnectionAverageIsArchivedAsc = 'FILES_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesConnectionAverageIsArchivedDesc = 'FILES_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesConnectionAverageIsFormAsc = 'FILES_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesConnectionAverageIsFormDesc = 'FILES_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesConnectionAverageMetaDataAsc = 'FILES_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesConnectionAverageMetaDataDesc = 'FILES_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesConnectionAverageModifiedByAsc = 'FILES_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesConnectionAverageModifiedByDesc = 'FILES_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesConnectionAverageNameAsc = 'FILES_CONNECTION_AVERAGE_NAME_ASC',
  FilesConnectionAverageNameDesc = 'FILES_CONNECTION_AVERAGE_NAME_DESC',
  FilesConnectionAverageOriginAsc = 'FILES_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesConnectionAverageOriginDesc = 'FILES_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesConnectionAveragePrivilegeAllAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesConnectionAveragePrivilegeAllDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesConnectionAveragePrivilegeAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesConnectionAveragePrivilegeDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesConnectionAveragePrivilegeOwnAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesConnectionAveragePrivilegeOwnDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesConnectionAveragePrivilegeTeamAsc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesConnectionAveragePrivilegeTeamDesc = 'FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesConnectionAverageProcessingStatusAsc = 'FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesConnectionAverageProcessingStatusDesc = 'FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesConnectionAverageProjectColumnValueFileIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionAverageProjectColumnValueFileIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionAverageProjectColumnValueIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionAverageProjectColumnValueIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionAverageProjectIdAsc = 'FILES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesConnectionAverageProjectIdDesc = 'FILES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesConnectionAverageSharedWithPortalAsc = 'FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionAverageSharedWithPortalDesc = 'FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionAverageSizeInBytesAsc = 'FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesConnectionAverageSizeInBytesDesc = 'FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesConnectionAverageStageIdAsc = 'FILES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesConnectionAverageStageIdDesc = 'FILES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesConnectionAverageTypeAsc = 'FILES_CONNECTION_AVERAGE_TYPE_ASC',
  FilesConnectionAverageTypeDesc = 'FILES_CONNECTION_AVERAGE_TYPE_DESC',
  FilesConnectionAverageUpdatedAtAsc = 'FILES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesConnectionAverageUpdatedAtDesc = 'FILES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesConnectionAverageUuidAsc = 'FILES_CONNECTION_AVERAGE_UUID_ASC',
  FilesConnectionAverageUuidDesc = 'FILES_CONNECTION_AVERAGE_UUID_DESC',
  FilesConnectionAverageValueFileIdAsc = 'FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesConnectionAverageValueFileIdDesc = 'FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesConnectionCountAsc = 'FILES_CONNECTION_COUNT_ASC',
  FilesConnectionCountDesc = 'FILES_CONNECTION_COUNT_DESC',
  FilesConnectionDistinctCountActionIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesConnectionDistinctCountActionIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesConnectionDistinctCountActionItemIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesConnectionDistinctCountActionItemIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesConnectionDistinctCountAnnotationsAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesConnectionDistinctCountAnnotationsDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesConnectionDistinctCountCommentIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesConnectionDistinctCountCommentIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesConnectionDistinctCountCompanyIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesConnectionDistinctCountCompanyIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesConnectionDistinctCountCreatedAtAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesConnectionDistinctCountCreatedAtDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesConnectionDistinctCountCreatedByAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesConnectionDistinctCountCreatedByContactIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionDistinctCountCreatedByContactIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionDistinctCountCreatedByDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesConnectionDistinctCountCreatedByTypeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesConnectionDistinctCountCreatedByTypeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesConnectionDistinctCountDownloadUrlAsc = 'FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesConnectionDistinctCountDownloadUrlDesc = 'FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesConnectionDistinctCountFormIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesConnectionDistinctCountFormIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesConnectionDistinctCountIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesConnectionDistinctCountIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesConnectionDistinctCountIsArchivedAsc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesConnectionDistinctCountIsArchivedDesc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesConnectionDistinctCountIsFormAsc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesConnectionDistinctCountIsFormDesc = 'FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesConnectionDistinctCountMetaDataAsc = 'FILES_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesConnectionDistinctCountMetaDataDesc = 'FILES_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesConnectionDistinctCountModifiedByAsc = 'FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesConnectionDistinctCountModifiedByDesc = 'FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesConnectionDistinctCountNameAsc = 'FILES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesConnectionDistinctCountNameDesc = 'FILES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesConnectionDistinctCountOriginAsc = 'FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesConnectionDistinctCountOriginDesc = 'FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesConnectionDistinctCountPrivilegeAllAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesConnectionDistinctCountPrivilegeAllDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesConnectionDistinctCountPrivilegeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesConnectionDistinctCountPrivilegeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesConnectionDistinctCountPrivilegeOwnAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesConnectionDistinctCountPrivilegeOwnDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesConnectionDistinctCountPrivilegeTeamAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesConnectionDistinctCountPrivilegeTeamDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesConnectionDistinctCountProcessingStatusAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesConnectionDistinctCountProcessingStatusDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionDistinctCountProjectIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesConnectionDistinctCountProjectIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesConnectionDistinctCountSharedWithPortalAsc = 'FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesConnectionDistinctCountSharedWithPortalDesc = 'FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesConnectionDistinctCountSizeInBytesAsc = 'FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesConnectionDistinctCountSizeInBytesDesc = 'FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesConnectionDistinctCountStageIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesConnectionDistinctCountStageIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesConnectionDistinctCountTypeAsc = 'FILES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesConnectionDistinctCountTypeDesc = 'FILES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesConnectionDistinctCountUpdatedAtAsc = 'FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesConnectionDistinctCountUpdatedAtDesc = 'FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesConnectionDistinctCountUuidAsc = 'FILES_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesConnectionDistinctCountUuidDesc = 'FILES_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesConnectionDistinctCountValueFileIdAsc = 'FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesConnectionDistinctCountValueFileIdDesc = 'FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesConnectionMaxActionIdAsc = 'FILES_CONNECTION_MAX_ACTION_ID_ASC',
  FilesConnectionMaxActionIdDesc = 'FILES_CONNECTION_MAX_ACTION_ID_DESC',
  FilesConnectionMaxActionItemIdAsc = 'FILES_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesConnectionMaxActionItemIdDesc = 'FILES_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesConnectionMaxAnnotationsAsc = 'FILES_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesConnectionMaxAnnotationsDesc = 'FILES_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesConnectionMaxCommentIdAsc = 'FILES_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesConnectionMaxCommentIdDesc = 'FILES_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesConnectionMaxCompanyIdAsc = 'FILES_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesConnectionMaxCompanyIdDesc = 'FILES_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesConnectionMaxCreatedAtAsc = 'FILES_CONNECTION_MAX_CREATED_AT_ASC',
  FilesConnectionMaxCreatedAtDesc = 'FILES_CONNECTION_MAX_CREATED_AT_DESC',
  FilesConnectionMaxCreatedByAsc = 'FILES_CONNECTION_MAX_CREATED_BY_ASC',
  FilesConnectionMaxCreatedByContactIdAsc = 'FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionMaxCreatedByContactIdDesc = 'FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionMaxCreatedByDesc = 'FILES_CONNECTION_MAX_CREATED_BY_DESC',
  FilesConnectionMaxCreatedByTypeAsc = 'FILES_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesConnectionMaxCreatedByTypeDesc = 'FILES_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesConnectionMaxDownloadUrlAsc = 'FILES_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesConnectionMaxDownloadUrlDesc = 'FILES_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesConnectionMaxFormIdAsc = 'FILES_CONNECTION_MAX_FORM_ID_ASC',
  FilesConnectionMaxFormIdDesc = 'FILES_CONNECTION_MAX_FORM_ID_DESC',
  FilesConnectionMaxIdAsc = 'FILES_CONNECTION_MAX_ID_ASC',
  FilesConnectionMaxIdDesc = 'FILES_CONNECTION_MAX_ID_DESC',
  FilesConnectionMaxIsArchivedAsc = 'FILES_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesConnectionMaxIsArchivedDesc = 'FILES_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesConnectionMaxIsFormAsc = 'FILES_CONNECTION_MAX_IS_FORM_ASC',
  FilesConnectionMaxIsFormDesc = 'FILES_CONNECTION_MAX_IS_FORM_DESC',
  FilesConnectionMaxMetaDataAsc = 'FILES_CONNECTION_MAX_META_DATA_ASC',
  FilesConnectionMaxMetaDataDesc = 'FILES_CONNECTION_MAX_META_DATA_DESC',
  FilesConnectionMaxModifiedByAsc = 'FILES_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesConnectionMaxModifiedByDesc = 'FILES_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesConnectionMaxNameAsc = 'FILES_CONNECTION_MAX_NAME_ASC',
  FilesConnectionMaxNameDesc = 'FILES_CONNECTION_MAX_NAME_DESC',
  FilesConnectionMaxOriginAsc = 'FILES_CONNECTION_MAX_ORIGIN_ASC',
  FilesConnectionMaxOriginDesc = 'FILES_CONNECTION_MAX_ORIGIN_DESC',
  FilesConnectionMaxPrivilegeAllAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesConnectionMaxPrivilegeAllDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesConnectionMaxPrivilegeAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesConnectionMaxPrivilegeDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesConnectionMaxPrivilegeOwnAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesConnectionMaxPrivilegeOwnDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesConnectionMaxPrivilegeTeamAsc = 'FILES_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesConnectionMaxPrivilegeTeamDesc = 'FILES_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesConnectionMaxProcessingStatusAsc = 'FILES_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesConnectionMaxProcessingStatusDesc = 'FILES_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesConnectionMaxProjectColumnValueFileIdAsc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionMaxProjectColumnValueFileIdDesc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionMaxProjectColumnValueIdAsc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionMaxProjectColumnValueIdDesc = 'FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionMaxProjectIdAsc = 'FILES_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesConnectionMaxProjectIdDesc = 'FILES_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesConnectionMaxSharedWithPortalAsc = 'FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesConnectionMaxSharedWithPortalDesc = 'FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesConnectionMaxSizeInBytesAsc = 'FILES_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesConnectionMaxSizeInBytesDesc = 'FILES_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesConnectionMaxStageIdAsc = 'FILES_CONNECTION_MAX_STAGE_ID_ASC',
  FilesConnectionMaxStageIdDesc = 'FILES_CONNECTION_MAX_STAGE_ID_DESC',
  FilesConnectionMaxTypeAsc = 'FILES_CONNECTION_MAX_TYPE_ASC',
  FilesConnectionMaxTypeDesc = 'FILES_CONNECTION_MAX_TYPE_DESC',
  FilesConnectionMaxUpdatedAtAsc = 'FILES_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesConnectionMaxUpdatedAtDesc = 'FILES_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesConnectionMaxUuidAsc = 'FILES_CONNECTION_MAX_UUID_ASC',
  FilesConnectionMaxUuidDesc = 'FILES_CONNECTION_MAX_UUID_DESC',
  FilesConnectionMaxValueFileIdAsc = 'FILES_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesConnectionMaxValueFileIdDesc = 'FILES_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesConnectionMinActionIdAsc = 'FILES_CONNECTION_MIN_ACTION_ID_ASC',
  FilesConnectionMinActionIdDesc = 'FILES_CONNECTION_MIN_ACTION_ID_DESC',
  FilesConnectionMinActionItemIdAsc = 'FILES_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesConnectionMinActionItemIdDesc = 'FILES_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesConnectionMinAnnotationsAsc = 'FILES_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesConnectionMinAnnotationsDesc = 'FILES_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesConnectionMinCommentIdAsc = 'FILES_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesConnectionMinCommentIdDesc = 'FILES_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesConnectionMinCompanyIdAsc = 'FILES_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesConnectionMinCompanyIdDesc = 'FILES_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesConnectionMinCreatedAtAsc = 'FILES_CONNECTION_MIN_CREATED_AT_ASC',
  FilesConnectionMinCreatedAtDesc = 'FILES_CONNECTION_MIN_CREATED_AT_DESC',
  FilesConnectionMinCreatedByAsc = 'FILES_CONNECTION_MIN_CREATED_BY_ASC',
  FilesConnectionMinCreatedByContactIdAsc = 'FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionMinCreatedByContactIdDesc = 'FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionMinCreatedByDesc = 'FILES_CONNECTION_MIN_CREATED_BY_DESC',
  FilesConnectionMinCreatedByTypeAsc = 'FILES_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesConnectionMinCreatedByTypeDesc = 'FILES_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesConnectionMinDownloadUrlAsc = 'FILES_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesConnectionMinDownloadUrlDesc = 'FILES_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesConnectionMinFormIdAsc = 'FILES_CONNECTION_MIN_FORM_ID_ASC',
  FilesConnectionMinFormIdDesc = 'FILES_CONNECTION_MIN_FORM_ID_DESC',
  FilesConnectionMinIdAsc = 'FILES_CONNECTION_MIN_ID_ASC',
  FilesConnectionMinIdDesc = 'FILES_CONNECTION_MIN_ID_DESC',
  FilesConnectionMinIsArchivedAsc = 'FILES_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesConnectionMinIsArchivedDesc = 'FILES_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesConnectionMinIsFormAsc = 'FILES_CONNECTION_MIN_IS_FORM_ASC',
  FilesConnectionMinIsFormDesc = 'FILES_CONNECTION_MIN_IS_FORM_DESC',
  FilesConnectionMinMetaDataAsc = 'FILES_CONNECTION_MIN_META_DATA_ASC',
  FilesConnectionMinMetaDataDesc = 'FILES_CONNECTION_MIN_META_DATA_DESC',
  FilesConnectionMinModifiedByAsc = 'FILES_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesConnectionMinModifiedByDesc = 'FILES_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesConnectionMinNameAsc = 'FILES_CONNECTION_MIN_NAME_ASC',
  FilesConnectionMinNameDesc = 'FILES_CONNECTION_MIN_NAME_DESC',
  FilesConnectionMinOriginAsc = 'FILES_CONNECTION_MIN_ORIGIN_ASC',
  FilesConnectionMinOriginDesc = 'FILES_CONNECTION_MIN_ORIGIN_DESC',
  FilesConnectionMinPrivilegeAllAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesConnectionMinPrivilegeAllDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesConnectionMinPrivilegeAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesConnectionMinPrivilegeDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesConnectionMinPrivilegeOwnAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesConnectionMinPrivilegeOwnDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesConnectionMinPrivilegeTeamAsc = 'FILES_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesConnectionMinPrivilegeTeamDesc = 'FILES_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesConnectionMinProcessingStatusAsc = 'FILES_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesConnectionMinProcessingStatusDesc = 'FILES_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesConnectionMinProjectColumnValueFileIdAsc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionMinProjectColumnValueFileIdDesc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionMinProjectColumnValueIdAsc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionMinProjectColumnValueIdDesc = 'FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionMinProjectIdAsc = 'FILES_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesConnectionMinProjectIdDesc = 'FILES_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesConnectionMinSharedWithPortalAsc = 'FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesConnectionMinSharedWithPortalDesc = 'FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesConnectionMinSizeInBytesAsc = 'FILES_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesConnectionMinSizeInBytesDesc = 'FILES_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesConnectionMinStageIdAsc = 'FILES_CONNECTION_MIN_STAGE_ID_ASC',
  FilesConnectionMinStageIdDesc = 'FILES_CONNECTION_MIN_STAGE_ID_DESC',
  FilesConnectionMinTypeAsc = 'FILES_CONNECTION_MIN_TYPE_ASC',
  FilesConnectionMinTypeDesc = 'FILES_CONNECTION_MIN_TYPE_DESC',
  FilesConnectionMinUpdatedAtAsc = 'FILES_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesConnectionMinUpdatedAtDesc = 'FILES_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesConnectionMinUuidAsc = 'FILES_CONNECTION_MIN_UUID_ASC',
  FilesConnectionMinUuidDesc = 'FILES_CONNECTION_MIN_UUID_DESC',
  FilesConnectionMinValueFileIdAsc = 'FILES_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesConnectionMinValueFileIdDesc = 'FILES_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevPopulationActionIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesConnectionStddevPopulationActionIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesConnectionStddevPopulationActionItemIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesConnectionStddevPopulationActionItemIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesConnectionStddevPopulationAnnotationsAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesConnectionStddevPopulationAnnotationsDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesConnectionStddevPopulationCommentIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesConnectionStddevPopulationCommentIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesConnectionStddevPopulationCompanyIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesConnectionStddevPopulationCompanyIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesConnectionStddevPopulationCreatedAtAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesConnectionStddevPopulationCreatedAtDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesConnectionStddevPopulationCreatedByAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionStddevPopulationCreatedByDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesConnectionStddevPopulationCreatedByTypeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesConnectionStddevPopulationCreatedByTypeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesConnectionStddevPopulationDownloadUrlAsc = 'FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesConnectionStddevPopulationDownloadUrlDesc = 'FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesConnectionStddevPopulationFormIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesConnectionStddevPopulationFormIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesConnectionStddevPopulationIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesConnectionStddevPopulationIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesConnectionStddevPopulationIsArchivedAsc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesConnectionStddevPopulationIsArchivedDesc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesConnectionStddevPopulationIsFormAsc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesConnectionStddevPopulationIsFormDesc = 'FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesConnectionStddevPopulationMetaDataAsc = 'FILES_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesConnectionStddevPopulationMetaDataDesc = 'FILES_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesConnectionStddevPopulationModifiedByAsc = 'FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesConnectionStddevPopulationModifiedByDesc = 'FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesConnectionStddevPopulationNameAsc = 'FILES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesConnectionStddevPopulationNameDesc = 'FILES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesConnectionStddevPopulationOriginAsc = 'FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesConnectionStddevPopulationOriginDesc = 'FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesConnectionStddevPopulationPrivilegeAllAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesConnectionStddevPopulationPrivilegeAllDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesConnectionStddevPopulationPrivilegeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesConnectionStddevPopulationPrivilegeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesConnectionStddevPopulationProcessingStatusAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesConnectionStddevPopulationProcessingStatusDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionStddevPopulationProjectIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesConnectionStddevPopulationProjectIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesConnectionStddevPopulationSharedWithPortalAsc = 'FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesConnectionStddevPopulationSharedWithPortalDesc = 'FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesConnectionStddevPopulationSizeInBytesAsc = 'FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesConnectionStddevPopulationSizeInBytesDesc = 'FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesConnectionStddevPopulationStageIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesConnectionStddevPopulationStageIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesConnectionStddevPopulationTypeAsc = 'FILES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesConnectionStddevPopulationTypeDesc = 'FILES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesConnectionStddevPopulationUpdatedAtAsc = 'FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesConnectionStddevPopulationUpdatedAtDesc = 'FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesConnectionStddevPopulationUuidAsc = 'FILES_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesConnectionStddevPopulationUuidDesc = 'FILES_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesConnectionStddevPopulationValueFileIdAsc = 'FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesConnectionStddevPopulationValueFileIdDesc = 'FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesConnectionStddevSampleActionIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesConnectionStddevSampleActionIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesConnectionStddevSampleActionItemIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesConnectionStddevSampleActionItemIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesConnectionStddevSampleAnnotationsAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesConnectionStddevSampleAnnotationsDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesConnectionStddevSampleCommentIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesConnectionStddevSampleCommentIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesConnectionStddevSampleCompanyIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesConnectionStddevSampleCompanyIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesConnectionStddevSampleCreatedAtAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesConnectionStddevSampleCreatedAtDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesConnectionStddevSampleCreatedByAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesConnectionStddevSampleCreatedByContactIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionStddevSampleCreatedByContactIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionStddevSampleCreatedByDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesConnectionStddevSampleCreatedByTypeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesConnectionStddevSampleCreatedByTypeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesConnectionStddevSampleDownloadUrlAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesConnectionStddevSampleDownloadUrlDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesConnectionStddevSampleFormIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesConnectionStddevSampleFormIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesConnectionStddevSampleIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesConnectionStddevSampleIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesConnectionStddevSampleIsArchivedAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesConnectionStddevSampleIsArchivedDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesConnectionStddevSampleIsFormAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesConnectionStddevSampleIsFormDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesConnectionStddevSampleMetaDataAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesConnectionStddevSampleMetaDataDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesConnectionStddevSampleModifiedByAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesConnectionStddevSampleModifiedByDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesConnectionStddevSampleNameAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesConnectionStddevSampleNameDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesConnectionStddevSampleOriginAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesConnectionStddevSampleOriginDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesConnectionStddevSamplePrivilegeAllAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesConnectionStddevSamplePrivilegeAllDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesConnectionStddevSamplePrivilegeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesConnectionStddevSamplePrivilegeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesConnectionStddevSamplePrivilegeOwnAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesConnectionStddevSamplePrivilegeOwnDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesConnectionStddevSamplePrivilegeTeamAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesConnectionStddevSamplePrivilegeTeamDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesConnectionStddevSampleProcessingStatusAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesConnectionStddevSampleProcessingStatusDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionStddevSampleProjectIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesConnectionStddevSampleProjectIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesConnectionStddevSampleSharedWithPortalAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionStddevSampleSharedWithPortalDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionStddevSampleSizeInBytesAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesConnectionStddevSampleSizeInBytesDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesConnectionStddevSampleStageIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesConnectionStddevSampleStageIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesConnectionStddevSampleTypeAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesConnectionStddevSampleTypeDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesConnectionStddevSampleUpdatedAtAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesConnectionStddevSampleUpdatedAtDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesConnectionStddevSampleUuidAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesConnectionStddevSampleUuidDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesConnectionStddevSampleValueFileIdAsc = 'FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesConnectionStddevSampleValueFileIdDesc = 'FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesConnectionSumActionIdAsc = 'FILES_CONNECTION_SUM_ACTION_ID_ASC',
  FilesConnectionSumActionIdDesc = 'FILES_CONNECTION_SUM_ACTION_ID_DESC',
  FilesConnectionSumActionItemIdAsc = 'FILES_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesConnectionSumActionItemIdDesc = 'FILES_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesConnectionSumAnnotationsAsc = 'FILES_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesConnectionSumAnnotationsDesc = 'FILES_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesConnectionSumCommentIdAsc = 'FILES_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesConnectionSumCommentIdDesc = 'FILES_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesConnectionSumCompanyIdAsc = 'FILES_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesConnectionSumCompanyIdDesc = 'FILES_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesConnectionSumCreatedAtAsc = 'FILES_CONNECTION_SUM_CREATED_AT_ASC',
  FilesConnectionSumCreatedAtDesc = 'FILES_CONNECTION_SUM_CREATED_AT_DESC',
  FilesConnectionSumCreatedByAsc = 'FILES_CONNECTION_SUM_CREATED_BY_ASC',
  FilesConnectionSumCreatedByContactIdAsc = 'FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionSumCreatedByContactIdDesc = 'FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionSumCreatedByDesc = 'FILES_CONNECTION_SUM_CREATED_BY_DESC',
  FilesConnectionSumCreatedByTypeAsc = 'FILES_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesConnectionSumCreatedByTypeDesc = 'FILES_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesConnectionSumDownloadUrlAsc = 'FILES_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesConnectionSumDownloadUrlDesc = 'FILES_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesConnectionSumFormIdAsc = 'FILES_CONNECTION_SUM_FORM_ID_ASC',
  FilesConnectionSumFormIdDesc = 'FILES_CONNECTION_SUM_FORM_ID_DESC',
  FilesConnectionSumIdAsc = 'FILES_CONNECTION_SUM_ID_ASC',
  FilesConnectionSumIdDesc = 'FILES_CONNECTION_SUM_ID_DESC',
  FilesConnectionSumIsArchivedAsc = 'FILES_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesConnectionSumIsArchivedDesc = 'FILES_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesConnectionSumIsFormAsc = 'FILES_CONNECTION_SUM_IS_FORM_ASC',
  FilesConnectionSumIsFormDesc = 'FILES_CONNECTION_SUM_IS_FORM_DESC',
  FilesConnectionSumMetaDataAsc = 'FILES_CONNECTION_SUM_META_DATA_ASC',
  FilesConnectionSumMetaDataDesc = 'FILES_CONNECTION_SUM_META_DATA_DESC',
  FilesConnectionSumModifiedByAsc = 'FILES_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesConnectionSumModifiedByDesc = 'FILES_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesConnectionSumNameAsc = 'FILES_CONNECTION_SUM_NAME_ASC',
  FilesConnectionSumNameDesc = 'FILES_CONNECTION_SUM_NAME_DESC',
  FilesConnectionSumOriginAsc = 'FILES_CONNECTION_SUM_ORIGIN_ASC',
  FilesConnectionSumOriginDesc = 'FILES_CONNECTION_SUM_ORIGIN_DESC',
  FilesConnectionSumPrivilegeAllAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesConnectionSumPrivilegeAllDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesConnectionSumPrivilegeAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesConnectionSumPrivilegeDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesConnectionSumPrivilegeOwnAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesConnectionSumPrivilegeOwnDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesConnectionSumPrivilegeTeamAsc = 'FILES_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesConnectionSumPrivilegeTeamDesc = 'FILES_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesConnectionSumProcessingStatusAsc = 'FILES_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesConnectionSumProcessingStatusDesc = 'FILES_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesConnectionSumProjectColumnValueFileIdAsc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionSumProjectColumnValueFileIdDesc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionSumProjectColumnValueIdAsc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionSumProjectColumnValueIdDesc = 'FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionSumProjectIdAsc = 'FILES_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesConnectionSumProjectIdDesc = 'FILES_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesConnectionSumSharedWithPortalAsc = 'FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesConnectionSumSharedWithPortalDesc = 'FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesConnectionSumSizeInBytesAsc = 'FILES_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesConnectionSumSizeInBytesDesc = 'FILES_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesConnectionSumStageIdAsc = 'FILES_CONNECTION_SUM_STAGE_ID_ASC',
  FilesConnectionSumStageIdDesc = 'FILES_CONNECTION_SUM_STAGE_ID_DESC',
  FilesConnectionSumTypeAsc = 'FILES_CONNECTION_SUM_TYPE_ASC',
  FilesConnectionSumTypeDesc = 'FILES_CONNECTION_SUM_TYPE_DESC',
  FilesConnectionSumUpdatedAtAsc = 'FILES_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesConnectionSumUpdatedAtDesc = 'FILES_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesConnectionSumUuidAsc = 'FILES_CONNECTION_SUM_UUID_ASC',
  FilesConnectionSumUuidDesc = 'FILES_CONNECTION_SUM_UUID_DESC',
  FilesConnectionSumValueFileIdAsc = 'FILES_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesConnectionSumValueFileIdDesc = 'FILES_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesConnectionVariancePopulationActionIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesConnectionVariancePopulationActionIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesConnectionVariancePopulationActionItemIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesConnectionVariancePopulationActionItemIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesConnectionVariancePopulationAnnotationsAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesConnectionVariancePopulationAnnotationsDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesConnectionVariancePopulationCommentIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesConnectionVariancePopulationCommentIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesConnectionVariancePopulationCompanyIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesConnectionVariancePopulationCompanyIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesConnectionVariancePopulationCreatedAtAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesConnectionVariancePopulationCreatedAtDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesConnectionVariancePopulationCreatedByAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionVariancePopulationCreatedByDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesConnectionVariancePopulationCreatedByTypeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesConnectionVariancePopulationCreatedByTypeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesConnectionVariancePopulationDownloadUrlAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesConnectionVariancePopulationDownloadUrlDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesConnectionVariancePopulationFormIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesConnectionVariancePopulationFormIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesConnectionVariancePopulationIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesConnectionVariancePopulationIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesConnectionVariancePopulationIsArchivedAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesConnectionVariancePopulationIsArchivedDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesConnectionVariancePopulationIsFormAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesConnectionVariancePopulationIsFormDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesConnectionVariancePopulationMetaDataAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesConnectionVariancePopulationMetaDataDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesConnectionVariancePopulationModifiedByAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesConnectionVariancePopulationModifiedByDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesConnectionVariancePopulationNameAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesConnectionVariancePopulationNameDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesConnectionVariancePopulationOriginAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesConnectionVariancePopulationOriginDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesConnectionVariancePopulationPrivilegeAllAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesConnectionVariancePopulationPrivilegeAllDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesConnectionVariancePopulationPrivilegeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesConnectionVariancePopulationPrivilegeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesConnectionVariancePopulationProcessingStatusAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesConnectionVariancePopulationProcessingStatusDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionVariancePopulationProjectIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesConnectionVariancePopulationProjectIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesConnectionVariancePopulationSharedWithPortalAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesConnectionVariancePopulationSharedWithPortalDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesConnectionVariancePopulationSizeInBytesAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesConnectionVariancePopulationSizeInBytesDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesConnectionVariancePopulationStageIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesConnectionVariancePopulationStageIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesConnectionVariancePopulationTypeAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesConnectionVariancePopulationTypeDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesConnectionVariancePopulationUpdatedAtAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesConnectionVariancePopulationUpdatedAtDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesConnectionVariancePopulationUuidAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesConnectionVariancePopulationUuidDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesConnectionVariancePopulationValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesConnectionVariancePopulationValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesConnectionVarianceSampleActionIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesConnectionVarianceSampleActionIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesConnectionVarianceSampleActionItemIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesConnectionVarianceSampleActionItemIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesConnectionVarianceSampleAnnotationsAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesConnectionVarianceSampleAnnotationsDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesConnectionVarianceSampleCommentIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesConnectionVarianceSampleCommentIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesConnectionVarianceSampleCompanyIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesConnectionVarianceSampleCompanyIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesConnectionVarianceSampleCreatedAtAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesConnectionVarianceSampleCreatedAtDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesConnectionVarianceSampleCreatedByAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesConnectionVarianceSampleCreatedByDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesConnectionVarianceSampleCreatedByTypeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesConnectionVarianceSampleCreatedByTypeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesConnectionVarianceSampleDownloadUrlAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesConnectionVarianceSampleDownloadUrlDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesConnectionVarianceSampleFormIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesConnectionVarianceSampleFormIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesConnectionVarianceSampleIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesConnectionVarianceSampleIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesConnectionVarianceSampleIsArchivedAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesConnectionVarianceSampleIsArchivedDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesConnectionVarianceSampleIsFormAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesConnectionVarianceSampleIsFormDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesConnectionVarianceSampleMetaDataAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesConnectionVarianceSampleMetaDataDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesConnectionVarianceSampleModifiedByAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesConnectionVarianceSampleModifiedByDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesConnectionVarianceSampleNameAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesConnectionVarianceSampleNameDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesConnectionVarianceSampleOriginAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesConnectionVarianceSampleOriginDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesConnectionVarianceSamplePrivilegeAllAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesConnectionVarianceSamplePrivilegeAllDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesConnectionVarianceSamplePrivilegeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesConnectionVarianceSamplePrivilegeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesConnectionVarianceSampleProcessingStatusAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesConnectionVarianceSampleProcessingStatusDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesConnectionVarianceSampleProjectIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesConnectionVarianceSampleProjectIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesConnectionVarianceSampleSharedWithPortalAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesConnectionVarianceSampleSharedWithPortalDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesConnectionVarianceSampleSizeInBytesAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesConnectionVarianceSampleSizeInBytesDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesConnectionVarianceSampleStageIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesConnectionVarianceSampleStageIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesConnectionVarianceSampleTypeAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesConnectionVarianceSampleTypeDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesConnectionVarianceSampleUpdatedAtAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesConnectionVarianceSampleUpdatedAtDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesConnectionVarianceSampleUuidAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesConnectionVarianceSampleUuidDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesConnectionVarianceSampleValueFileIdAsc = 'FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesConnectionVarianceSampleValueFileIdDesc = 'FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPinnedAsc = 'IS_PINNED_ASC',
  IsPinnedDesc = 'IS_PINNED_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PinnedAtAsc = 'PINNED_AT_ASC',
  PinnedAtDesc = 'PINNED_AT_DESC',
  PreviousIdAsc = 'PREVIOUS_ID_ASC',
  PreviousIdDesc = 'PREVIOUS_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  XAsc = 'X_ASC',
  XDesc = 'X_DESC',
  YAsc = 'Y_ASC',
  YDesc = 'Y_DESC'
}

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  ActionsConnectionArrayAggActionTemplateIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionArrayAggActionTemplateIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionArrayAggAssignAllContactsAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionArrayAggAssignAllContactsDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionArrayAggCompanyIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ActionsConnectionArrayAggCompanyIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ActionsConnectionArrayAggCompletedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  ActionsConnectionArrayAggCompletedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  ActionsConnectionArrayAggCreatedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ActionsConnectionArrayAggCreatedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ActionsConnectionArrayAggCreatedByAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ActionsConnectionArrayAggCreatedByDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ActionsConnectionArrayAggDescriptionAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ActionsConnectionArrayAggDescriptionDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ActionsConnectionArrayAggDueDateAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  ActionsConnectionArrayAggDueDateDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  ActionsConnectionArrayAggIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  ActionsConnectionArrayAggIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  ActionsConnectionArrayAggIsTemplateAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  ActionsConnectionArrayAggIsTemplateDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  ActionsConnectionArrayAggJobIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ActionsConnectionArrayAggJobIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ActionsConnectionArrayAggProjectIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ActionsConnectionArrayAggProjectIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ActionsConnectionArrayAggProjectStageIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  ActionsConnectionArrayAggProjectStageIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  ActionsConnectionArrayAggStatusAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ActionsConnectionArrayAggStatusDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ActionsConnectionArrayAggTitleAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ActionsConnectionArrayAggTitleDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ActionsConnectionArrayAggTypeAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ActionsConnectionArrayAggTypeDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ActionsConnectionArrayAggUpdatedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ActionsConnectionArrayAggUpdatedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ActionsConnectionArrayAggUuidAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_UUID_ASC',
  ActionsConnectionArrayAggUuidDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_UUID_DESC',
  ActionsConnectionAverageActionTemplateIdAsc = 'ACTIONS_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionAverageActionTemplateIdDesc = 'ACTIONS_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionAverageAssignAllContactsAsc = 'ACTIONS_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionAverageAssignAllContactsDesc = 'ACTIONS_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionAverageCompanyIdAsc = 'ACTIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ActionsConnectionAverageCompanyIdDesc = 'ACTIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ActionsConnectionAverageCompletedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  ActionsConnectionAverageCompletedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  ActionsConnectionAverageCreatedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ActionsConnectionAverageCreatedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ActionsConnectionAverageCreatedByAsc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ActionsConnectionAverageCreatedByDesc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ActionsConnectionAverageDescriptionAsc = 'ACTIONS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ActionsConnectionAverageDescriptionDesc = 'ACTIONS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ActionsConnectionAverageDueDateAsc = 'ACTIONS_CONNECTION_AVERAGE_DUE_DATE_ASC',
  ActionsConnectionAverageDueDateDesc = 'ACTIONS_CONNECTION_AVERAGE_DUE_DATE_DESC',
  ActionsConnectionAverageIdAsc = 'ACTIONS_CONNECTION_AVERAGE_ID_ASC',
  ActionsConnectionAverageIdDesc = 'ACTIONS_CONNECTION_AVERAGE_ID_DESC',
  ActionsConnectionAverageIsTemplateAsc = 'ACTIONS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  ActionsConnectionAverageIsTemplateDesc = 'ACTIONS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  ActionsConnectionAverageJobIdAsc = 'ACTIONS_CONNECTION_AVERAGE_JOB_ID_ASC',
  ActionsConnectionAverageJobIdDesc = 'ACTIONS_CONNECTION_AVERAGE_JOB_ID_DESC',
  ActionsConnectionAverageProjectIdAsc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ActionsConnectionAverageProjectIdDesc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ActionsConnectionAverageProjectStageIdAsc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionAverageProjectStageIdDesc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionAverageStatusAsc = 'ACTIONS_CONNECTION_AVERAGE_STATUS_ASC',
  ActionsConnectionAverageStatusDesc = 'ACTIONS_CONNECTION_AVERAGE_STATUS_DESC',
  ActionsConnectionAverageTitleAsc = 'ACTIONS_CONNECTION_AVERAGE_TITLE_ASC',
  ActionsConnectionAverageTitleDesc = 'ACTIONS_CONNECTION_AVERAGE_TITLE_DESC',
  ActionsConnectionAverageTypeAsc = 'ACTIONS_CONNECTION_AVERAGE_TYPE_ASC',
  ActionsConnectionAverageTypeDesc = 'ACTIONS_CONNECTION_AVERAGE_TYPE_DESC',
  ActionsConnectionAverageUpdatedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ActionsConnectionAverageUpdatedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ActionsConnectionAverageUuidAsc = 'ACTIONS_CONNECTION_AVERAGE_UUID_ASC',
  ActionsConnectionAverageUuidDesc = 'ACTIONS_CONNECTION_AVERAGE_UUID_DESC',
  ActionsConnectionCountAsc = 'ACTIONS_CONNECTION_COUNT_ASC',
  ActionsConnectionCountDesc = 'ACTIONS_CONNECTION_COUNT_DESC',
  ActionsConnectionDistinctCountActionTemplateIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionDistinctCountActionTemplateIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionDistinctCountAssignAllContactsAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionDistinctCountAssignAllContactsDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionDistinctCountCompanyIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ActionsConnectionDistinctCountCompanyIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ActionsConnectionDistinctCountCompletedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  ActionsConnectionDistinctCountCompletedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  ActionsConnectionDistinctCountCreatedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ActionsConnectionDistinctCountCreatedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ActionsConnectionDistinctCountCreatedByAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ActionsConnectionDistinctCountCreatedByDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ActionsConnectionDistinctCountDescriptionAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ActionsConnectionDistinctCountDescriptionDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ActionsConnectionDistinctCountDueDateAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  ActionsConnectionDistinctCountDueDateDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  ActionsConnectionDistinctCountIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ActionsConnectionDistinctCountIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ActionsConnectionDistinctCountIsTemplateAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  ActionsConnectionDistinctCountIsTemplateDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  ActionsConnectionDistinctCountJobIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ActionsConnectionDistinctCountJobIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ActionsConnectionDistinctCountProjectIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ActionsConnectionDistinctCountProjectIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ActionsConnectionDistinctCountProjectStageIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  ActionsConnectionDistinctCountProjectStageIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  ActionsConnectionDistinctCountStatusAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ActionsConnectionDistinctCountStatusDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ActionsConnectionDistinctCountTitleAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ActionsConnectionDistinctCountTitleDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ActionsConnectionDistinctCountTypeAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ActionsConnectionDistinctCountTypeDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ActionsConnectionDistinctCountUpdatedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ActionsConnectionDistinctCountUpdatedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ActionsConnectionDistinctCountUuidAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  ActionsConnectionDistinctCountUuidDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  ActionsConnectionMaxActionTemplateIdAsc = 'ACTIONS_CONNECTION_MAX_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionMaxActionTemplateIdDesc = 'ACTIONS_CONNECTION_MAX_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionMaxAssignAllContactsAsc = 'ACTIONS_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionMaxAssignAllContactsDesc = 'ACTIONS_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionMaxCompanyIdAsc = 'ACTIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  ActionsConnectionMaxCompanyIdDesc = 'ACTIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  ActionsConnectionMaxCompletedAtAsc = 'ACTIONS_CONNECTION_MAX_COMPLETED_AT_ASC',
  ActionsConnectionMaxCompletedAtDesc = 'ACTIONS_CONNECTION_MAX_COMPLETED_AT_DESC',
  ActionsConnectionMaxCreatedAtAsc = 'ACTIONS_CONNECTION_MAX_CREATED_AT_ASC',
  ActionsConnectionMaxCreatedAtDesc = 'ACTIONS_CONNECTION_MAX_CREATED_AT_DESC',
  ActionsConnectionMaxCreatedByAsc = 'ACTIONS_CONNECTION_MAX_CREATED_BY_ASC',
  ActionsConnectionMaxCreatedByDesc = 'ACTIONS_CONNECTION_MAX_CREATED_BY_DESC',
  ActionsConnectionMaxDescriptionAsc = 'ACTIONS_CONNECTION_MAX_DESCRIPTION_ASC',
  ActionsConnectionMaxDescriptionDesc = 'ACTIONS_CONNECTION_MAX_DESCRIPTION_DESC',
  ActionsConnectionMaxDueDateAsc = 'ACTIONS_CONNECTION_MAX_DUE_DATE_ASC',
  ActionsConnectionMaxDueDateDesc = 'ACTIONS_CONNECTION_MAX_DUE_DATE_DESC',
  ActionsConnectionMaxIdAsc = 'ACTIONS_CONNECTION_MAX_ID_ASC',
  ActionsConnectionMaxIdDesc = 'ACTIONS_CONNECTION_MAX_ID_DESC',
  ActionsConnectionMaxIsTemplateAsc = 'ACTIONS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  ActionsConnectionMaxIsTemplateDesc = 'ACTIONS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  ActionsConnectionMaxJobIdAsc = 'ACTIONS_CONNECTION_MAX_JOB_ID_ASC',
  ActionsConnectionMaxJobIdDesc = 'ACTIONS_CONNECTION_MAX_JOB_ID_DESC',
  ActionsConnectionMaxProjectIdAsc = 'ACTIONS_CONNECTION_MAX_PROJECT_ID_ASC',
  ActionsConnectionMaxProjectIdDesc = 'ACTIONS_CONNECTION_MAX_PROJECT_ID_DESC',
  ActionsConnectionMaxProjectStageIdAsc = 'ACTIONS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  ActionsConnectionMaxProjectStageIdDesc = 'ACTIONS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  ActionsConnectionMaxStatusAsc = 'ACTIONS_CONNECTION_MAX_STATUS_ASC',
  ActionsConnectionMaxStatusDesc = 'ACTIONS_CONNECTION_MAX_STATUS_DESC',
  ActionsConnectionMaxTitleAsc = 'ACTIONS_CONNECTION_MAX_TITLE_ASC',
  ActionsConnectionMaxTitleDesc = 'ACTIONS_CONNECTION_MAX_TITLE_DESC',
  ActionsConnectionMaxTypeAsc = 'ACTIONS_CONNECTION_MAX_TYPE_ASC',
  ActionsConnectionMaxTypeDesc = 'ACTIONS_CONNECTION_MAX_TYPE_DESC',
  ActionsConnectionMaxUpdatedAtAsc = 'ACTIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  ActionsConnectionMaxUpdatedAtDesc = 'ACTIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  ActionsConnectionMaxUuidAsc = 'ACTIONS_CONNECTION_MAX_UUID_ASC',
  ActionsConnectionMaxUuidDesc = 'ACTIONS_CONNECTION_MAX_UUID_DESC',
  ActionsConnectionMinActionTemplateIdAsc = 'ACTIONS_CONNECTION_MIN_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionMinActionTemplateIdDesc = 'ACTIONS_CONNECTION_MIN_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionMinAssignAllContactsAsc = 'ACTIONS_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionMinAssignAllContactsDesc = 'ACTIONS_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionMinCompanyIdAsc = 'ACTIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  ActionsConnectionMinCompanyIdDesc = 'ACTIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  ActionsConnectionMinCompletedAtAsc = 'ACTIONS_CONNECTION_MIN_COMPLETED_AT_ASC',
  ActionsConnectionMinCompletedAtDesc = 'ACTIONS_CONNECTION_MIN_COMPLETED_AT_DESC',
  ActionsConnectionMinCreatedAtAsc = 'ACTIONS_CONNECTION_MIN_CREATED_AT_ASC',
  ActionsConnectionMinCreatedAtDesc = 'ACTIONS_CONNECTION_MIN_CREATED_AT_DESC',
  ActionsConnectionMinCreatedByAsc = 'ACTIONS_CONNECTION_MIN_CREATED_BY_ASC',
  ActionsConnectionMinCreatedByDesc = 'ACTIONS_CONNECTION_MIN_CREATED_BY_DESC',
  ActionsConnectionMinDescriptionAsc = 'ACTIONS_CONNECTION_MIN_DESCRIPTION_ASC',
  ActionsConnectionMinDescriptionDesc = 'ACTIONS_CONNECTION_MIN_DESCRIPTION_DESC',
  ActionsConnectionMinDueDateAsc = 'ACTIONS_CONNECTION_MIN_DUE_DATE_ASC',
  ActionsConnectionMinDueDateDesc = 'ACTIONS_CONNECTION_MIN_DUE_DATE_DESC',
  ActionsConnectionMinIdAsc = 'ACTIONS_CONNECTION_MIN_ID_ASC',
  ActionsConnectionMinIdDesc = 'ACTIONS_CONNECTION_MIN_ID_DESC',
  ActionsConnectionMinIsTemplateAsc = 'ACTIONS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  ActionsConnectionMinIsTemplateDesc = 'ACTIONS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  ActionsConnectionMinJobIdAsc = 'ACTIONS_CONNECTION_MIN_JOB_ID_ASC',
  ActionsConnectionMinJobIdDesc = 'ACTIONS_CONNECTION_MIN_JOB_ID_DESC',
  ActionsConnectionMinProjectIdAsc = 'ACTIONS_CONNECTION_MIN_PROJECT_ID_ASC',
  ActionsConnectionMinProjectIdDesc = 'ACTIONS_CONNECTION_MIN_PROJECT_ID_DESC',
  ActionsConnectionMinProjectStageIdAsc = 'ACTIONS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  ActionsConnectionMinProjectStageIdDesc = 'ACTIONS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  ActionsConnectionMinStatusAsc = 'ACTIONS_CONNECTION_MIN_STATUS_ASC',
  ActionsConnectionMinStatusDesc = 'ACTIONS_CONNECTION_MIN_STATUS_DESC',
  ActionsConnectionMinTitleAsc = 'ACTIONS_CONNECTION_MIN_TITLE_ASC',
  ActionsConnectionMinTitleDesc = 'ACTIONS_CONNECTION_MIN_TITLE_DESC',
  ActionsConnectionMinTypeAsc = 'ACTIONS_CONNECTION_MIN_TYPE_ASC',
  ActionsConnectionMinTypeDesc = 'ACTIONS_CONNECTION_MIN_TYPE_DESC',
  ActionsConnectionMinUpdatedAtAsc = 'ACTIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  ActionsConnectionMinUpdatedAtDesc = 'ACTIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  ActionsConnectionMinUuidAsc = 'ACTIONS_CONNECTION_MIN_UUID_ASC',
  ActionsConnectionMinUuidDesc = 'ACTIONS_CONNECTION_MIN_UUID_DESC',
  ActionsConnectionStddevPopulationActionTemplateIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionStddevPopulationActionTemplateIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionStddevPopulationAssignAllContactsAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionStddevPopulationAssignAllContactsDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionStddevPopulationCompanyIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ActionsConnectionStddevPopulationCompanyIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ActionsConnectionStddevPopulationCompletedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  ActionsConnectionStddevPopulationCompletedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  ActionsConnectionStddevPopulationCreatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ActionsConnectionStddevPopulationCreatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ActionsConnectionStddevPopulationCreatedByAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ActionsConnectionStddevPopulationCreatedByDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ActionsConnectionStddevPopulationDescriptionAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ActionsConnectionStddevPopulationDescriptionDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ActionsConnectionStddevPopulationDueDateAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  ActionsConnectionStddevPopulationDueDateDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  ActionsConnectionStddevPopulationIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ActionsConnectionStddevPopulationIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ActionsConnectionStddevPopulationIsTemplateAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  ActionsConnectionStddevPopulationIsTemplateDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  ActionsConnectionStddevPopulationJobIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ActionsConnectionStddevPopulationJobIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ActionsConnectionStddevPopulationProjectIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ActionsConnectionStddevPopulationProjectIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ActionsConnectionStddevPopulationProjectStageIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsConnectionStddevPopulationProjectStageIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsConnectionStddevPopulationStatusAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ActionsConnectionStddevPopulationStatusDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ActionsConnectionStddevPopulationTitleAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ActionsConnectionStddevPopulationTitleDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ActionsConnectionStddevPopulationTypeAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ActionsConnectionStddevPopulationTypeDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ActionsConnectionStddevPopulationUpdatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ActionsConnectionStddevPopulationUpdatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ActionsConnectionStddevPopulationUuidAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  ActionsConnectionStddevPopulationUuidDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  ActionsConnectionStddevSampleActionTemplateIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionStddevSampleActionTemplateIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionStddevSampleAssignAllContactsAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionStddevSampleAssignAllContactsDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionStddevSampleCompanyIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ActionsConnectionStddevSampleCompanyIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ActionsConnectionStddevSampleCompletedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  ActionsConnectionStddevSampleCompletedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  ActionsConnectionStddevSampleCreatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ActionsConnectionStddevSampleCreatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ActionsConnectionStddevSampleCreatedByAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ActionsConnectionStddevSampleCreatedByDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ActionsConnectionStddevSampleDescriptionAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ActionsConnectionStddevSampleDescriptionDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ActionsConnectionStddevSampleDueDateAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  ActionsConnectionStddevSampleDueDateDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  ActionsConnectionStddevSampleIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ActionsConnectionStddevSampleIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ActionsConnectionStddevSampleIsTemplateAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  ActionsConnectionStddevSampleIsTemplateDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  ActionsConnectionStddevSampleJobIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ActionsConnectionStddevSampleJobIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ActionsConnectionStddevSampleProjectIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ActionsConnectionStddevSampleProjectIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ActionsConnectionStddevSampleProjectStageIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionStddevSampleProjectStageIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionStddevSampleStatusAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ActionsConnectionStddevSampleStatusDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ActionsConnectionStddevSampleTitleAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ActionsConnectionStddevSampleTitleDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ActionsConnectionStddevSampleTypeAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ActionsConnectionStddevSampleTypeDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ActionsConnectionStddevSampleUpdatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ActionsConnectionStddevSampleUpdatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ActionsConnectionStddevSampleUuidAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  ActionsConnectionStddevSampleUuidDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  ActionsConnectionSumActionTemplateIdAsc = 'ACTIONS_CONNECTION_SUM_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionSumActionTemplateIdDesc = 'ACTIONS_CONNECTION_SUM_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionSumAssignAllContactsAsc = 'ACTIONS_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionSumAssignAllContactsDesc = 'ACTIONS_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionSumCompanyIdAsc = 'ACTIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  ActionsConnectionSumCompanyIdDesc = 'ACTIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  ActionsConnectionSumCompletedAtAsc = 'ACTIONS_CONNECTION_SUM_COMPLETED_AT_ASC',
  ActionsConnectionSumCompletedAtDesc = 'ACTIONS_CONNECTION_SUM_COMPLETED_AT_DESC',
  ActionsConnectionSumCreatedAtAsc = 'ACTIONS_CONNECTION_SUM_CREATED_AT_ASC',
  ActionsConnectionSumCreatedAtDesc = 'ACTIONS_CONNECTION_SUM_CREATED_AT_DESC',
  ActionsConnectionSumCreatedByAsc = 'ACTIONS_CONNECTION_SUM_CREATED_BY_ASC',
  ActionsConnectionSumCreatedByDesc = 'ACTIONS_CONNECTION_SUM_CREATED_BY_DESC',
  ActionsConnectionSumDescriptionAsc = 'ACTIONS_CONNECTION_SUM_DESCRIPTION_ASC',
  ActionsConnectionSumDescriptionDesc = 'ACTIONS_CONNECTION_SUM_DESCRIPTION_DESC',
  ActionsConnectionSumDueDateAsc = 'ACTIONS_CONNECTION_SUM_DUE_DATE_ASC',
  ActionsConnectionSumDueDateDesc = 'ACTIONS_CONNECTION_SUM_DUE_DATE_DESC',
  ActionsConnectionSumIdAsc = 'ACTIONS_CONNECTION_SUM_ID_ASC',
  ActionsConnectionSumIdDesc = 'ACTIONS_CONNECTION_SUM_ID_DESC',
  ActionsConnectionSumIsTemplateAsc = 'ACTIONS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  ActionsConnectionSumIsTemplateDesc = 'ACTIONS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  ActionsConnectionSumJobIdAsc = 'ACTIONS_CONNECTION_SUM_JOB_ID_ASC',
  ActionsConnectionSumJobIdDesc = 'ACTIONS_CONNECTION_SUM_JOB_ID_DESC',
  ActionsConnectionSumProjectIdAsc = 'ACTIONS_CONNECTION_SUM_PROJECT_ID_ASC',
  ActionsConnectionSumProjectIdDesc = 'ACTIONS_CONNECTION_SUM_PROJECT_ID_DESC',
  ActionsConnectionSumProjectStageIdAsc = 'ACTIONS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  ActionsConnectionSumProjectStageIdDesc = 'ACTIONS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  ActionsConnectionSumStatusAsc = 'ACTIONS_CONNECTION_SUM_STATUS_ASC',
  ActionsConnectionSumStatusDesc = 'ACTIONS_CONNECTION_SUM_STATUS_DESC',
  ActionsConnectionSumTitleAsc = 'ACTIONS_CONNECTION_SUM_TITLE_ASC',
  ActionsConnectionSumTitleDesc = 'ACTIONS_CONNECTION_SUM_TITLE_DESC',
  ActionsConnectionSumTypeAsc = 'ACTIONS_CONNECTION_SUM_TYPE_ASC',
  ActionsConnectionSumTypeDesc = 'ACTIONS_CONNECTION_SUM_TYPE_DESC',
  ActionsConnectionSumUpdatedAtAsc = 'ACTIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  ActionsConnectionSumUpdatedAtDesc = 'ACTIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  ActionsConnectionSumUuidAsc = 'ACTIONS_CONNECTION_SUM_UUID_ASC',
  ActionsConnectionSumUuidDesc = 'ACTIONS_CONNECTION_SUM_UUID_DESC',
  ActionsConnectionVariancePopulationActionTemplateIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionVariancePopulationActionTemplateIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionVariancePopulationAssignAllContactsAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionVariancePopulationAssignAllContactsDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionVariancePopulationCompanyIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ActionsConnectionVariancePopulationCompanyIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ActionsConnectionVariancePopulationCompletedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  ActionsConnectionVariancePopulationCompletedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  ActionsConnectionVariancePopulationCreatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ActionsConnectionVariancePopulationCreatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ActionsConnectionVariancePopulationCreatedByAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ActionsConnectionVariancePopulationCreatedByDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ActionsConnectionVariancePopulationDescriptionAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ActionsConnectionVariancePopulationDescriptionDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ActionsConnectionVariancePopulationDueDateAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  ActionsConnectionVariancePopulationDueDateDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  ActionsConnectionVariancePopulationIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ActionsConnectionVariancePopulationIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ActionsConnectionVariancePopulationIsTemplateAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  ActionsConnectionVariancePopulationIsTemplateDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  ActionsConnectionVariancePopulationJobIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ActionsConnectionVariancePopulationJobIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ActionsConnectionVariancePopulationProjectIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ActionsConnectionVariancePopulationProjectIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ActionsConnectionVariancePopulationProjectStageIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsConnectionVariancePopulationProjectStageIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsConnectionVariancePopulationStatusAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ActionsConnectionVariancePopulationStatusDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ActionsConnectionVariancePopulationTitleAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ActionsConnectionVariancePopulationTitleDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ActionsConnectionVariancePopulationTypeAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ActionsConnectionVariancePopulationTypeDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ActionsConnectionVariancePopulationUpdatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ActionsConnectionVariancePopulationUpdatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ActionsConnectionVariancePopulationUuidAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  ActionsConnectionVariancePopulationUuidDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  ActionsConnectionVarianceSampleActionTemplateIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionVarianceSampleActionTemplateIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionVarianceSampleAssignAllContactsAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionVarianceSampleAssignAllContactsDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionVarianceSampleCompanyIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ActionsConnectionVarianceSampleCompanyIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ActionsConnectionVarianceSampleCompletedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  ActionsConnectionVarianceSampleCompletedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  ActionsConnectionVarianceSampleCreatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ActionsConnectionVarianceSampleCreatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ActionsConnectionVarianceSampleCreatedByAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ActionsConnectionVarianceSampleCreatedByDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ActionsConnectionVarianceSampleDescriptionAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ActionsConnectionVarianceSampleDescriptionDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ActionsConnectionVarianceSampleDueDateAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  ActionsConnectionVarianceSampleDueDateDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  ActionsConnectionVarianceSampleIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ActionsConnectionVarianceSampleIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ActionsConnectionVarianceSampleIsTemplateAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  ActionsConnectionVarianceSampleIsTemplateDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  ActionsConnectionVarianceSampleJobIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ActionsConnectionVarianceSampleJobIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ActionsConnectionVarianceSampleProjectIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ActionsConnectionVarianceSampleProjectIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ActionsConnectionVarianceSampleProjectStageIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionVarianceSampleProjectStageIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionVarianceSampleStatusAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ActionsConnectionVarianceSampleStatusDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ActionsConnectionVarianceSampleTitleAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ActionsConnectionVarianceSampleTitleDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ActionsConnectionVarianceSampleTypeAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ActionsConnectionVarianceSampleTypeDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ActionsConnectionVarianceSampleUpdatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ActionsConnectionVarianceSampleUpdatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ActionsConnectionVarianceSampleUuidAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  ActionsConnectionVarianceSampleUuidDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AnalyticsConnectionArrayAggArgsAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_ARGS_ASC',
  AnalyticsConnectionArrayAggArgsDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_ARGS_DESC',
  AnalyticsConnectionArrayAggCompanyIdAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  AnalyticsConnectionArrayAggCompanyIdDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  AnalyticsConnectionArrayAggCreatedAtAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  AnalyticsConnectionArrayAggCreatedAtDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  AnalyticsConnectionArrayAggCreatedByAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  AnalyticsConnectionArrayAggCreatedByDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  AnalyticsConnectionArrayAggDashboardIdAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  AnalyticsConnectionArrayAggDashboardIdDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  AnalyticsConnectionArrayAggIdAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_ID_ASC',
  AnalyticsConnectionArrayAggIdDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_ID_DESC',
  AnalyticsConnectionArrayAggSubtitleAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_SUBTITLE_ASC',
  AnalyticsConnectionArrayAggSubtitleDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_SUBTITLE_DESC',
  AnalyticsConnectionArrayAggTitleAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  AnalyticsConnectionArrayAggTitleDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  AnalyticsConnectionArrayAggTypeAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  AnalyticsConnectionArrayAggTypeDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  AnalyticsConnectionArrayAggUpdatedAtAsc = 'ANALYTICS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  AnalyticsConnectionArrayAggUpdatedAtDesc = 'ANALYTICS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  AnalyticsConnectionAverageArgsAsc = 'ANALYTICS_CONNECTION_AVERAGE_ARGS_ASC',
  AnalyticsConnectionAverageArgsDesc = 'ANALYTICS_CONNECTION_AVERAGE_ARGS_DESC',
  AnalyticsConnectionAverageCompanyIdAsc = 'ANALYTICS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  AnalyticsConnectionAverageCompanyIdDesc = 'ANALYTICS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  AnalyticsConnectionAverageCreatedAtAsc = 'ANALYTICS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  AnalyticsConnectionAverageCreatedAtDesc = 'ANALYTICS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  AnalyticsConnectionAverageCreatedByAsc = 'ANALYTICS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  AnalyticsConnectionAverageCreatedByDesc = 'ANALYTICS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  AnalyticsConnectionAverageDashboardIdAsc = 'ANALYTICS_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  AnalyticsConnectionAverageDashboardIdDesc = 'ANALYTICS_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  AnalyticsConnectionAverageIdAsc = 'ANALYTICS_CONNECTION_AVERAGE_ID_ASC',
  AnalyticsConnectionAverageIdDesc = 'ANALYTICS_CONNECTION_AVERAGE_ID_DESC',
  AnalyticsConnectionAverageSubtitleAsc = 'ANALYTICS_CONNECTION_AVERAGE_SUBTITLE_ASC',
  AnalyticsConnectionAverageSubtitleDesc = 'ANALYTICS_CONNECTION_AVERAGE_SUBTITLE_DESC',
  AnalyticsConnectionAverageTitleAsc = 'ANALYTICS_CONNECTION_AVERAGE_TITLE_ASC',
  AnalyticsConnectionAverageTitleDesc = 'ANALYTICS_CONNECTION_AVERAGE_TITLE_DESC',
  AnalyticsConnectionAverageTypeAsc = 'ANALYTICS_CONNECTION_AVERAGE_TYPE_ASC',
  AnalyticsConnectionAverageTypeDesc = 'ANALYTICS_CONNECTION_AVERAGE_TYPE_DESC',
  AnalyticsConnectionAverageUpdatedAtAsc = 'ANALYTICS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  AnalyticsConnectionAverageUpdatedAtDesc = 'ANALYTICS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  AnalyticsConnectionCountAsc = 'ANALYTICS_CONNECTION_COUNT_ASC',
  AnalyticsConnectionCountDesc = 'ANALYTICS_CONNECTION_COUNT_DESC',
  AnalyticsConnectionDistinctCountArgsAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_ARGS_ASC',
  AnalyticsConnectionDistinctCountArgsDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_ARGS_DESC',
  AnalyticsConnectionDistinctCountCompanyIdAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  AnalyticsConnectionDistinctCountCompanyIdDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  AnalyticsConnectionDistinctCountCreatedAtAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  AnalyticsConnectionDistinctCountCreatedAtDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  AnalyticsConnectionDistinctCountCreatedByAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  AnalyticsConnectionDistinctCountCreatedByDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  AnalyticsConnectionDistinctCountDashboardIdAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  AnalyticsConnectionDistinctCountDashboardIdDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  AnalyticsConnectionDistinctCountIdAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  AnalyticsConnectionDistinctCountIdDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  AnalyticsConnectionDistinctCountSubtitleAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_SUBTITLE_ASC',
  AnalyticsConnectionDistinctCountSubtitleDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_SUBTITLE_DESC',
  AnalyticsConnectionDistinctCountTitleAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  AnalyticsConnectionDistinctCountTitleDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  AnalyticsConnectionDistinctCountTypeAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  AnalyticsConnectionDistinctCountTypeDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  AnalyticsConnectionDistinctCountUpdatedAtAsc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnalyticsConnectionDistinctCountUpdatedAtDesc = 'ANALYTICS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnalyticsConnectionMaxArgsAsc = 'ANALYTICS_CONNECTION_MAX_ARGS_ASC',
  AnalyticsConnectionMaxArgsDesc = 'ANALYTICS_CONNECTION_MAX_ARGS_DESC',
  AnalyticsConnectionMaxCompanyIdAsc = 'ANALYTICS_CONNECTION_MAX_COMPANY_ID_ASC',
  AnalyticsConnectionMaxCompanyIdDesc = 'ANALYTICS_CONNECTION_MAX_COMPANY_ID_DESC',
  AnalyticsConnectionMaxCreatedAtAsc = 'ANALYTICS_CONNECTION_MAX_CREATED_AT_ASC',
  AnalyticsConnectionMaxCreatedAtDesc = 'ANALYTICS_CONNECTION_MAX_CREATED_AT_DESC',
  AnalyticsConnectionMaxCreatedByAsc = 'ANALYTICS_CONNECTION_MAX_CREATED_BY_ASC',
  AnalyticsConnectionMaxCreatedByDesc = 'ANALYTICS_CONNECTION_MAX_CREATED_BY_DESC',
  AnalyticsConnectionMaxDashboardIdAsc = 'ANALYTICS_CONNECTION_MAX_DASHBOARD_ID_ASC',
  AnalyticsConnectionMaxDashboardIdDesc = 'ANALYTICS_CONNECTION_MAX_DASHBOARD_ID_DESC',
  AnalyticsConnectionMaxIdAsc = 'ANALYTICS_CONNECTION_MAX_ID_ASC',
  AnalyticsConnectionMaxIdDesc = 'ANALYTICS_CONNECTION_MAX_ID_DESC',
  AnalyticsConnectionMaxSubtitleAsc = 'ANALYTICS_CONNECTION_MAX_SUBTITLE_ASC',
  AnalyticsConnectionMaxSubtitleDesc = 'ANALYTICS_CONNECTION_MAX_SUBTITLE_DESC',
  AnalyticsConnectionMaxTitleAsc = 'ANALYTICS_CONNECTION_MAX_TITLE_ASC',
  AnalyticsConnectionMaxTitleDesc = 'ANALYTICS_CONNECTION_MAX_TITLE_DESC',
  AnalyticsConnectionMaxTypeAsc = 'ANALYTICS_CONNECTION_MAX_TYPE_ASC',
  AnalyticsConnectionMaxTypeDesc = 'ANALYTICS_CONNECTION_MAX_TYPE_DESC',
  AnalyticsConnectionMaxUpdatedAtAsc = 'ANALYTICS_CONNECTION_MAX_UPDATED_AT_ASC',
  AnalyticsConnectionMaxUpdatedAtDesc = 'ANALYTICS_CONNECTION_MAX_UPDATED_AT_DESC',
  AnalyticsConnectionMinArgsAsc = 'ANALYTICS_CONNECTION_MIN_ARGS_ASC',
  AnalyticsConnectionMinArgsDesc = 'ANALYTICS_CONNECTION_MIN_ARGS_DESC',
  AnalyticsConnectionMinCompanyIdAsc = 'ANALYTICS_CONNECTION_MIN_COMPANY_ID_ASC',
  AnalyticsConnectionMinCompanyIdDesc = 'ANALYTICS_CONNECTION_MIN_COMPANY_ID_DESC',
  AnalyticsConnectionMinCreatedAtAsc = 'ANALYTICS_CONNECTION_MIN_CREATED_AT_ASC',
  AnalyticsConnectionMinCreatedAtDesc = 'ANALYTICS_CONNECTION_MIN_CREATED_AT_DESC',
  AnalyticsConnectionMinCreatedByAsc = 'ANALYTICS_CONNECTION_MIN_CREATED_BY_ASC',
  AnalyticsConnectionMinCreatedByDesc = 'ANALYTICS_CONNECTION_MIN_CREATED_BY_DESC',
  AnalyticsConnectionMinDashboardIdAsc = 'ANALYTICS_CONNECTION_MIN_DASHBOARD_ID_ASC',
  AnalyticsConnectionMinDashboardIdDesc = 'ANALYTICS_CONNECTION_MIN_DASHBOARD_ID_DESC',
  AnalyticsConnectionMinIdAsc = 'ANALYTICS_CONNECTION_MIN_ID_ASC',
  AnalyticsConnectionMinIdDesc = 'ANALYTICS_CONNECTION_MIN_ID_DESC',
  AnalyticsConnectionMinSubtitleAsc = 'ANALYTICS_CONNECTION_MIN_SUBTITLE_ASC',
  AnalyticsConnectionMinSubtitleDesc = 'ANALYTICS_CONNECTION_MIN_SUBTITLE_DESC',
  AnalyticsConnectionMinTitleAsc = 'ANALYTICS_CONNECTION_MIN_TITLE_ASC',
  AnalyticsConnectionMinTitleDesc = 'ANALYTICS_CONNECTION_MIN_TITLE_DESC',
  AnalyticsConnectionMinTypeAsc = 'ANALYTICS_CONNECTION_MIN_TYPE_ASC',
  AnalyticsConnectionMinTypeDesc = 'ANALYTICS_CONNECTION_MIN_TYPE_DESC',
  AnalyticsConnectionMinUpdatedAtAsc = 'ANALYTICS_CONNECTION_MIN_UPDATED_AT_ASC',
  AnalyticsConnectionMinUpdatedAtDesc = 'ANALYTICS_CONNECTION_MIN_UPDATED_AT_DESC',
  AnalyticsConnectionStddevPopulationArgsAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_ARGS_ASC',
  AnalyticsConnectionStddevPopulationArgsDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_ARGS_DESC',
  AnalyticsConnectionStddevPopulationCompanyIdAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  AnalyticsConnectionStddevPopulationCompanyIdDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  AnalyticsConnectionStddevPopulationCreatedAtAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  AnalyticsConnectionStddevPopulationCreatedAtDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  AnalyticsConnectionStddevPopulationCreatedByAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  AnalyticsConnectionStddevPopulationCreatedByDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  AnalyticsConnectionStddevPopulationDashboardIdAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsConnectionStddevPopulationDashboardIdDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsConnectionStddevPopulationIdAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  AnalyticsConnectionStddevPopulationIdDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  AnalyticsConnectionStddevPopulationSubtitleAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_SUBTITLE_ASC',
  AnalyticsConnectionStddevPopulationSubtitleDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_SUBTITLE_DESC',
  AnalyticsConnectionStddevPopulationTitleAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  AnalyticsConnectionStddevPopulationTitleDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  AnalyticsConnectionStddevPopulationTypeAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  AnalyticsConnectionStddevPopulationTypeDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  AnalyticsConnectionStddevPopulationUpdatedAtAsc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnalyticsConnectionStddevPopulationUpdatedAtDesc = 'ANALYTICS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnalyticsConnectionStddevSampleArgsAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_ARGS_ASC',
  AnalyticsConnectionStddevSampleArgsDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_ARGS_DESC',
  AnalyticsConnectionStddevSampleCompanyIdAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  AnalyticsConnectionStddevSampleCompanyIdDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  AnalyticsConnectionStddevSampleCreatedAtAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnalyticsConnectionStddevSampleCreatedAtDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnalyticsConnectionStddevSampleCreatedByAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  AnalyticsConnectionStddevSampleCreatedByDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  AnalyticsConnectionStddevSampleDashboardIdAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsConnectionStddevSampleDashboardIdDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsConnectionStddevSampleIdAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  AnalyticsConnectionStddevSampleIdDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  AnalyticsConnectionStddevSampleSubtitleAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_SUBTITLE_ASC',
  AnalyticsConnectionStddevSampleSubtitleDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_SUBTITLE_DESC',
  AnalyticsConnectionStddevSampleTitleAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  AnalyticsConnectionStddevSampleTitleDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  AnalyticsConnectionStddevSampleTypeAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  AnalyticsConnectionStddevSampleTypeDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  AnalyticsConnectionStddevSampleUpdatedAtAsc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnalyticsConnectionStddevSampleUpdatedAtDesc = 'ANALYTICS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnalyticsConnectionSumArgsAsc = 'ANALYTICS_CONNECTION_SUM_ARGS_ASC',
  AnalyticsConnectionSumArgsDesc = 'ANALYTICS_CONNECTION_SUM_ARGS_DESC',
  AnalyticsConnectionSumCompanyIdAsc = 'ANALYTICS_CONNECTION_SUM_COMPANY_ID_ASC',
  AnalyticsConnectionSumCompanyIdDesc = 'ANALYTICS_CONNECTION_SUM_COMPANY_ID_DESC',
  AnalyticsConnectionSumCreatedAtAsc = 'ANALYTICS_CONNECTION_SUM_CREATED_AT_ASC',
  AnalyticsConnectionSumCreatedAtDesc = 'ANALYTICS_CONNECTION_SUM_CREATED_AT_DESC',
  AnalyticsConnectionSumCreatedByAsc = 'ANALYTICS_CONNECTION_SUM_CREATED_BY_ASC',
  AnalyticsConnectionSumCreatedByDesc = 'ANALYTICS_CONNECTION_SUM_CREATED_BY_DESC',
  AnalyticsConnectionSumDashboardIdAsc = 'ANALYTICS_CONNECTION_SUM_DASHBOARD_ID_ASC',
  AnalyticsConnectionSumDashboardIdDesc = 'ANALYTICS_CONNECTION_SUM_DASHBOARD_ID_DESC',
  AnalyticsConnectionSumIdAsc = 'ANALYTICS_CONNECTION_SUM_ID_ASC',
  AnalyticsConnectionSumIdDesc = 'ANALYTICS_CONNECTION_SUM_ID_DESC',
  AnalyticsConnectionSumSubtitleAsc = 'ANALYTICS_CONNECTION_SUM_SUBTITLE_ASC',
  AnalyticsConnectionSumSubtitleDesc = 'ANALYTICS_CONNECTION_SUM_SUBTITLE_DESC',
  AnalyticsConnectionSumTitleAsc = 'ANALYTICS_CONNECTION_SUM_TITLE_ASC',
  AnalyticsConnectionSumTitleDesc = 'ANALYTICS_CONNECTION_SUM_TITLE_DESC',
  AnalyticsConnectionSumTypeAsc = 'ANALYTICS_CONNECTION_SUM_TYPE_ASC',
  AnalyticsConnectionSumTypeDesc = 'ANALYTICS_CONNECTION_SUM_TYPE_DESC',
  AnalyticsConnectionSumUpdatedAtAsc = 'ANALYTICS_CONNECTION_SUM_UPDATED_AT_ASC',
  AnalyticsConnectionSumUpdatedAtDesc = 'ANALYTICS_CONNECTION_SUM_UPDATED_AT_DESC',
  AnalyticsConnectionVariancePopulationArgsAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_ARGS_ASC',
  AnalyticsConnectionVariancePopulationArgsDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_ARGS_DESC',
  AnalyticsConnectionVariancePopulationCompanyIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  AnalyticsConnectionVariancePopulationCompanyIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  AnalyticsConnectionVariancePopulationCreatedAtAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnalyticsConnectionVariancePopulationCreatedAtDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnalyticsConnectionVariancePopulationCreatedByAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  AnalyticsConnectionVariancePopulationCreatedByDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  AnalyticsConnectionVariancePopulationDashboardIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsConnectionVariancePopulationDashboardIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsConnectionVariancePopulationIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  AnalyticsConnectionVariancePopulationIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  AnalyticsConnectionVariancePopulationSubtitleAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_SUBTITLE_ASC',
  AnalyticsConnectionVariancePopulationSubtitleDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_SUBTITLE_DESC',
  AnalyticsConnectionVariancePopulationTitleAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  AnalyticsConnectionVariancePopulationTitleDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  AnalyticsConnectionVariancePopulationTypeAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  AnalyticsConnectionVariancePopulationTypeDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  AnalyticsConnectionVariancePopulationUpdatedAtAsc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnalyticsConnectionVariancePopulationUpdatedAtDesc = 'ANALYTICS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnalyticsConnectionVarianceSampleArgsAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_ARGS_ASC',
  AnalyticsConnectionVarianceSampleArgsDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_ARGS_DESC',
  AnalyticsConnectionVarianceSampleCompanyIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  AnalyticsConnectionVarianceSampleCompanyIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  AnalyticsConnectionVarianceSampleCreatedAtAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnalyticsConnectionVarianceSampleCreatedAtDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnalyticsConnectionVarianceSampleCreatedByAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  AnalyticsConnectionVarianceSampleCreatedByDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  AnalyticsConnectionVarianceSampleDashboardIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsConnectionVarianceSampleDashboardIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsConnectionVarianceSampleIdAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  AnalyticsConnectionVarianceSampleIdDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  AnalyticsConnectionVarianceSampleSubtitleAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_ASC',
  AnalyticsConnectionVarianceSampleSubtitleDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_DESC',
  AnalyticsConnectionVarianceSampleTitleAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  AnalyticsConnectionVarianceSampleTitleDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  AnalyticsConnectionVarianceSampleTypeAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  AnalyticsConnectionVarianceSampleTypeDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  AnalyticsConnectionVarianceSampleUpdatedAtAsc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnalyticsConnectionVarianceSampleUpdatedAtDesc = 'ANALYTICS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CallsConnectionArrayAggCompanyIdAsc = 'CALLS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CallsConnectionArrayAggCompanyIdDesc = 'CALLS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CallsConnectionArrayAggCreatedAtAsc = 'CALLS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CallsConnectionArrayAggCreatedAtDesc = 'CALLS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CallsConnectionArrayAggCreatedByAsc = 'CALLS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CallsConnectionArrayAggCreatedByDesc = 'CALLS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CallsConnectionArrayAggDispositionAsc = 'CALLS_CONNECTION_ARRAY_AGG_DISPOSITION_ASC',
  CallsConnectionArrayAggDispositionDesc = 'CALLS_CONNECTION_ARRAY_AGG_DISPOSITION_DESC',
  CallsConnectionArrayAggDurationInMsAsc = 'CALLS_CONNECTION_ARRAY_AGG_DURATION_IN_MS_ASC',
  CallsConnectionArrayAggDurationInMsDesc = 'CALLS_CONNECTION_ARRAY_AGG_DURATION_IN_MS_DESC',
  CallsConnectionArrayAggEndTimeAsc = 'CALLS_CONNECTION_ARRAY_AGG_END_TIME_ASC',
  CallsConnectionArrayAggEndTimeDesc = 'CALLS_CONNECTION_ARRAY_AGG_END_TIME_DESC',
  CallsConnectionArrayAggFromContactIdAsc = 'CALLS_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  CallsConnectionArrayAggFromContactIdDesc = 'CALLS_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  CallsConnectionArrayAggFromNumberAsc = 'CALLS_CONNECTION_ARRAY_AGG_FROM_NUMBER_ASC',
  CallsConnectionArrayAggFromNumberDesc = 'CALLS_CONNECTION_ARRAY_AGG_FROM_NUMBER_DESC',
  CallsConnectionArrayAggIdAsc = 'CALLS_CONNECTION_ARRAY_AGG_ID_ASC',
  CallsConnectionArrayAggIdDesc = 'CALLS_CONNECTION_ARRAY_AGG_ID_DESC',
  CallsConnectionArrayAggIsInboundAsc = 'CALLS_CONNECTION_ARRAY_AGG_IS_INBOUND_ASC',
  CallsConnectionArrayAggIsInboundDesc = 'CALLS_CONNECTION_ARRAY_AGG_IS_INBOUND_DESC',
  CallsConnectionArrayAggMissedCountAsc = 'CALLS_CONNECTION_ARRAY_AGG_MISSED_COUNT_ASC',
  CallsConnectionArrayAggMissedCountDesc = 'CALLS_CONNECTION_ARRAY_AGG_MISSED_COUNT_DESC',
  CallsConnectionArrayAggNoteAsc = 'CALLS_CONNECTION_ARRAY_AGG_NOTE_ASC',
  CallsConnectionArrayAggNoteDesc = 'CALLS_CONNECTION_ARRAY_AGG_NOTE_DESC',
  CallsConnectionArrayAggOutcomeAsc = 'CALLS_CONNECTION_ARRAY_AGG_OUTCOME_ASC',
  CallsConnectionArrayAggOutcomeDesc = 'CALLS_CONNECTION_ARRAY_AGG_OUTCOME_DESC',
  CallsConnectionArrayAggProjectIdAsc = 'CALLS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CallsConnectionArrayAggProjectIdDesc = 'CALLS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CallsConnectionArrayAggReasonAsc = 'CALLS_CONNECTION_ARRAY_AGG_REASON_ASC',
  CallsConnectionArrayAggReasonDesc = 'CALLS_CONNECTION_ARRAY_AGG_REASON_DESC',
  CallsConnectionArrayAggRecordingUrlAsc = 'CALLS_CONNECTION_ARRAY_AGG_RECORDING_URL_ASC',
  CallsConnectionArrayAggRecordingUrlDesc = 'CALLS_CONNECTION_ARRAY_AGG_RECORDING_URL_DESC',
  CallsConnectionArrayAggStartTimeAsc = 'CALLS_CONNECTION_ARRAY_AGG_START_TIME_ASC',
  CallsConnectionArrayAggStartTimeDesc = 'CALLS_CONNECTION_ARRAY_AGG_START_TIME_DESC',
  CallsConnectionArrayAggToContactIdAsc = 'CALLS_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  CallsConnectionArrayAggToContactIdDesc = 'CALLS_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  CallsConnectionArrayAggToNumberAsc = 'CALLS_CONNECTION_ARRAY_AGG_TO_NUMBER_ASC',
  CallsConnectionArrayAggToNumberDesc = 'CALLS_CONNECTION_ARRAY_AGG_TO_NUMBER_DESC',
  CallsConnectionArrayAggTranscriptUrlAsc = 'CALLS_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_ASC',
  CallsConnectionArrayAggTranscriptUrlDesc = 'CALLS_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_DESC',
  CallsConnectionArrayAggUpdatedAtAsc = 'CALLS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CallsConnectionArrayAggUpdatedAtDesc = 'CALLS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CallsConnectionAverageCompanyIdAsc = 'CALLS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CallsConnectionAverageCompanyIdDesc = 'CALLS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CallsConnectionAverageCreatedAtAsc = 'CALLS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CallsConnectionAverageCreatedAtDesc = 'CALLS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CallsConnectionAverageCreatedByAsc = 'CALLS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CallsConnectionAverageCreatedByDesc = 'CALLS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CallsConnectionAverageDispositionAsc = 'CALLS_CONNECTION_AVERAGE_DISPOSITION_ASC',
  CallsConnectionAverageDispositionDesc = 'CALLS_CONNECTION_AVERAGE_DISPOSITION_DESC',
  CallsConnectionAverageDurationInMsAsc = 'CALLS_CONNECTION_AVERAGE_DURATION_IN_MS_ASC',
  CallsConnectionAverageDurationInMsDesc = 'CALLS_CONNECTION_AVERAGE_DURATION_IN_MS_DESC',
  CallsConnectionAverageEndTimeAsc = 'CALLS_CONNECTION_AVERAGE_END_TIME_ASC',
  CallsConnectionAverageEndTimeDesc = 'CALLS_CONNECTION_AVERAGE_END_TIME_DESC',
  CallsConnectionAverageFromContactIdAsc = 'CALLS_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  CallsConnectionAverageFromContactIdDesc = 'CALLS_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  CallsConnectionAverageFromNumberAsc = 'CALLS_CONNECTION_AVERAGE_FROM_NUMBER_ASC',
  CallsConnectionAverageFromNumberDesc = 'CALLS_CONNECTION_AVERAGE_FROM_NUMBER_DESC',
  CallsConnectionAverageIdAsc = 'CALLS_CONNECTION_AVERAGE_ID_ASC',
  CallsConnectionAverageIdDesc = 'CALLS_CONNECTION_AVERAGE_ID_DESC',
  CallsConnectionAverageIsInboundAsc = 'CALLS_CONNECTION_AVERAGE_IS_INBOUND_ASC',
  CallsConnectionAverageIsInboundDesc = 'CALLS_CONNECTION_AVERAGE_IS_INBOUND_DESC',
  CallsConnectionAverageMissedCountAsc = 'CALLS_CONNECTION_AVERAGE_MISSED_COUNT_ASC',
  CallsConnectionAverageMissedCountDesc = 'CALLS_CONNECTION_AVERAGE_MISSED_COUNT_DESC',
  CallsConnectionAverageNoteAsc = 'CALLS_CONNECTION_AVERAGE_NOTE_ASC',
  CallsConnectionAverageNoteDesc = 'CALLS_CONNECTION_AVERAGE_NOTE_DESC',
  CallsConnectionAverageOutcomeAsc = 'CALLS_CONNECTION_AVERAGE_OUTCOME_ASC',
  CallsConnectionAverageOutcomeDesc = 'CALLS_CONNECTION_AVERAGE_OUTCOME_DESC',
  CallsConnectionAverageProjectIdAsc = 'CALLS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CallsConnectionAverageProjectIdDesc = 'CALLS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CallsConnectionAverageReasonAsc = 'CALLS_CONNECTION_AVERAGE_REASON_ASC',
  CallsConnectionAverageReasonDesc = 'CALLS_CONNECTION_AVERAGE_REASON_DESC',
  CallsConnectionAverageRecordingUrlAsc = 'CALLS_CONNECTION_AVERAGE_RECORDING_URL_ASC',
  CallsConnectionAverageRecordingUrlDesc = 'CALLS_CONNECTION_AVERAGE_RECORDING_URL_DESC',
  CallsConnectionAverageStartTimeAsc = 'CALLS_CONNECTION_AVERAGE_START_TIME_ASC',
  CallsConnectionAverageStartTimeDesc = 'CALLS_CONNECTION_AVERAGE_START_TIME_DESC',
  CallsConnectionAverageToContactIdAsc = 'CALLS_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  CallsConnectionAverageToContactIdDesc = 'CALLS_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  CallsConnectionAverageToNumberAsc = 'CALLS_CONNECTION_AVERAGE_TO_NUMBER_ASC',
  CallsConnectionAverageToNumberDesc = 'CALLS_CONNECTION_AVERAGE_TO_NUMBER_DESC',
  CallsConnectionAverageTranscriptUrlAsc = 'CALLS_CONNECTION_AVERAGE_TRANSCRIPT_URL_ASC',
  CallsConnectionAverageTranscriptUrlDesc = 'CALLS_CONNECTION_AVERAGE_TRANSCRIPT_URL_DESC',
  CallsConnectionAverageUpdatedAtAsc = 'CALLS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CallsConnectionAverageUpdatedAtDesc = 'CALLS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CallsConnectionCountAsc = 'CALLS_CONNECTION_COUNT_ASC',
  CallsConnectionCountDesc = 'CALLS_CONNECTION_COUNT_DESC',
  CallsConnectionDistinctCountCompanyIdAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CallsConnectionDistinctCountCompanyIdDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CallsConnectionDistinctCountCreatedAtAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CallsConnectionDistinctCountCreatedAtDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CallsConnectionDistinctCountCreatedByAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CallsConnectionDistinctCountCreatedByDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CallsConnectionDistinctCountDispositionAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_DISPOSITION_ASC',
  CallsConnectionDistinctCountDispositionDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_DISPOSITION_DESC',
  CallsConnectionDistinctCountDurationInMsAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_ASC',
  CallsConnectionDistinctCountDurationInMsDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_DESC',
  CallsConnectionDistinctCountEndTimeAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_END_TIME_ASC',
  CallsConnectionDistinctCountEndTimeDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_END_TIME_DESC',
  CallsConnectionDistinctCountFromContactIdAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  CallsConnectionDistinctCountFromContactIdDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  CallsConnectionDistinctCountFromNumberAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_ASC',
  CallsConnectionDistinctCountFromNumberDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_DESC',
  CallsConnectionDistinctCountIdAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CallsConnectionDistinctCountIdDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CallsConnectionDistinctCountIsInboundAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_IS_INBOUND_ASC',
  CallsConnectionDistinctCountIsInboundDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_IS_INBOUND_DESC',
  CallsConnectionDistinctCountMissedCountAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_ASC',
  CallsConnectionDistinctCountMissedCountDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_DESC',
  CallsConnectionDistinctCountNoteAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_NOTE_ASC',
  CallsConnectionDistinctCountNoteDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_NOTE_DESC',
  CallsConnectionDistinctCountOutcomeAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_OUTCOME_ASC',
  CallsConnectionDistinctCountOutcomeDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_OUTCOME_DESC',
  CallsConnectionDistinctCountProjectIdAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CallsConnectionDistinctCountProjectIdDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CallsConnectionDistinctCountReasonAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_REASON_ASC',
  CallsConnectionDistinctCountReasonDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_REASON_DESC',
  CallsConnectionDistinctCountRecordingUrlAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_RECORDING_URL_ASC',
  CallsConnectionDistinctCountRecordingUrlDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_RECORDING_URL_DESC',
  CallsConnectionDistinctCountStartTimeAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_START_TIME_ASC',
  CallsConnectionDistinctCountStartTimeDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_START_TIME_DESC',
  CallsConnectionDistinctCountToContactIdAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  CallsConnectionDistinctCountToContactIdDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  CallsConnectionDistinctCountToNumberAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_TO_NUMBER_ASC',
  CallsConnectionDistinctCountToNumberDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_TO_NUMBER_DESC',
  CallsConnectionDistinctCountTranscriptUrlAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_ASC',
  CallsConnectionDistinctCountTranscriptUrlDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_DESC',
  CallsConnectionDistinctCountUpdatedAtAsc = 'CALLS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CallsConnectionDistinctCountUpdatedAtDesc = 'CALLS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CallsConnectionMaxCompanyIdAsc = 'CALLS_CONNECTION_MAX_COMPANY_ID_ASC',
  CallsConnectionMaxCompanyIdDesc = 'CALLS_CONNECTION_MAX_COMPANY_ID_DESC',
  CallsConnectionMaxCreatedAtAsc = 'CALLS_CONNECTION_MAX_CREATED_AT_ASC',
  CallsConnectionMaxCreatedAtDesc = 'CALLS_CONNECTION_MAX_CREATED_AT_DESC',
  CallsConnectionMaxCreatedByAsc = 'CALLS_CONNECTION_MAX_CREATED_BY_ASC',
  CallsConnectionMaxCreatedByDesc = 'CALLS_CONNECTION_MAX_CREATED_BY_DESC',
  CallsConnectionMaxDispositionAsc = 'CALLS_CONNECTION_MAX_DISPOSITION_ASC',
  CallsConnectionMaxDispositionDesc = 'CALLS_CONNECTION_MAX_DISPOSITION_DESC',
  CallsConnectionMaxDurationInMsAsc = 'CALLS_CONNECTION_MAX_DURATION_IN_MS_ASC',
  CallsConnectionMaxDurationInMsDesc = 'CALLS_CONNECTION_MAX_DURATION_IN_MS_DESC',
  CallsConnectionMaxEndTimeAsc = 'CALLS_CONNECTION_MAX_END_TIME_ASC',
  CallsConnectionMaxEndTimeDesc = 'CALLS_CONNECTION_MAX_END_TIME_DESC',
  CallsConnectionMaxFromContactIdAsc = 'CALLS_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  CallsConnectionMaxFromContactIdDesc = 'CALLS_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  CallsConnectionMaxFromNumberAsc = 'CALLS_CONNECTION_MAX_FROM_NUMBER_ASC',
  CallsConnectionMaxFromNumberDesc = 'CALLS_CONNECTION_MAX_FROM_NUMBER_DESC',
  CallsConnectionMaxIdAsc = 'CALLS_CONNECTION_MAX_ID_ASC',
  CallsConnectionMaxIdDesc = 'CALLS_CONNECTION_MAX_ID_DESC',
  CallsConnectionMaxIsInboundAsc = 'CALLS_CONNECTION_MAX_IS_INBOUND_ASC',
  CallsConnectionMaxIsInboundDesc = 'CALLS_CONNECTION_MAX_IS_INBOUND_DESC',
  CallsConnectionMaxMissedCountAsc = 'CALLS_CONNECTION_MAX_MISSED_COUNT_ASC',
  CallsConnectionMaxMissedCountDesc = 'CALLS_CONNECTION_MAX_MISSED_COUNT_DESC',
  CallsConnectionMaxNoteAsc = 'CALLS_CONNECTION_MAX_NOTE_ASC',
  CallsConnectionMaxNoteDesc = 'CALLS_CONNECTION_MAX_NOTE_DESC',
  CallsConnectionMaxOutcomeAsc = 'CALLS_CONNECTION_MAX_OUTCOME_ASC',
  CallsConnectionMaxOutcomeDesc = 'CALLS_CONNECTION_MAX_OUTCOME_DESC',
  CallsConnectionMaxProjectIdAsc = 'CALLS_CONNECTION_MAX_PROJECT_ID_ASC',
  CallsConnectionMaxProjectIdDesc = 'CALLS_CONNECTION_MAX_PROJECT_ID_DESC',
  CallsConnectionMaxReasonAsc = 'CALLS_CONNECTION_MAX_REASON_ASC',
  CallsConnectionMaxReasonDesc = 'CALLS_CONNECTION_MAX_REASON_DESC',
  CallsConnectionMaxRecordingUrlAsc = 'CALLS_CONNECTION_MAX_RECORDING_URL_ASC',
  CallsConnectionMaxRecordingUrlDesc = 'CALLS_CONNECTION_MAX_RECORDING_URL_DESC',
  CallsConnectionMaxStartTimeAsc = 'CALLS_CONNECTION_MAX_START_TIME_ASC',
  CallsConnectionMaxStartTimeDesc = 'CALLS_CONNECTION_MAX_START_TIME_DESC',
  CallsConnectionMaxToContactIdAsc = 'CALLS_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  CallsConnectionMaxToContactIdDesc = 'CALLS_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  CallsConnectionMaxToNumberAsc = 'CALLS_CONNECTION_MAX_TO_NUMBER_ASC',
  CallsConnectionMaxToNumberDesc = 'CALLS_CONNECTION_MAX_TO_NUMBER_DESC',
  CallsConnectionMaxTranscriptUrlAsc = 'CALLS_CONNECTION_MAX_TRANSCRIPT_URL_ASC',
  CallsConnectionMaxTranscriptUrlDesc = 'CALLS_CONNECTION_MAX_TRANSCRIPT_URL_DESC',
  CallsConnectionMaxUpdatedAtAsc = 'CALLS_CONNECTION_MAX_UPDATED_AT_ASC',
  CallsConnectionMaxUpdatedAtDesc = 'CALLS_CONNECTION_MAX_UPDATED_AT_DESC',
  CallsConnectionMinCompanyIdAsc = 'CALLS_CONNECTION_MIN_COMPANY_ID_ASC',
  CallsConnectionMinCompanyIdDesc = 'CALLS_CONNECTION_MIN_COMPANY_ID_DESC',
  CallsConnectionMinCreatedAtAsc = 'CALLS_CONNECTION_MIN_CREATED_AT_ASC',
  CallsConnectionMinCreatedAtDesc = 'CALLS_CONNECTION_MIN_CREATED_AT_DESC',
  CallsConnectionMinCreatedByAsc = 'CALLS_CONNECTION_MIN_CREATED_BY_ASC',
  CallsConnectionMinCreatedByDesc = 'CALLS_CONNECTION_MIN_CREATED_BY_DESC',
  CallsConnectionMinDispositionAsc = 'CALLS_CONNECTION_MIN_DISPOSITION_ASC',
  CallsConnectionMinDispositionDesc = 'CALLS_CONNECTION_MIN_DISPOSITION_DESC',
  CallsConnectionMinDurationInMsAsc = 'CALLS_CONNECTION_MIN_DURATION_IN_MS_ASC',
  CallsConnectionMinDurationInMsDesc = 'CALLS_CONNECTION_MIN_DURATION_IN_MS_DESC',
  CallsConnectionMinEndTimeAsc = 'CALLS_CONNECTION_MIN_END_TIME_ASC',
  CallsConnectionMinEndTimeDesc = 'CALLS_CONNECTION_MIN_END_TIME_DESC',
  CallsConnectionMinFromContactIdAsc = 'CALLS_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  CallsConnectionMinFromContactIdDesc = 'CALLS_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  CallsConnectionMinFromNumberAsc = 'CALLS_CONNECTION_MIN_FROM_NUMBER_ASC',
  CallsConnectionMinFromNumberDesc = 'CALLS_CONNECTION_MIN_FROM_NUMBER_DESC',
  CallsConnectionMinIdAsc = 'CALLS_CONNECTION_MIN_ID_ASC',
  CallsConnectionMinIdDesc = 'CALLS_CONNECTION_MIN_ID_DESC',
  CallsConnectionMinIsInboundAsc = 'CALLS_CONNECTION_MIN_IS_INBOUND_ASC',
  CallsConnectionMinIsInboundDesc = 'CALLS_CONNECTION_MIN_IS_INBOUND_DESC',
  CallsConnectionMinMissedCountAsc = 'CALLS_CONNECTION_MIN_MISSED_COUNT_ASC',
  CallsConnectionMinMissedCountDesc = 'CALLS_CONNECTION_MIN_MISSED_COUNT_DESC',
  CallsConnectionMinNoteAsc = 'CALLS_CONNECTION_MIN_NOTE_ASC',
  CallsConnectionMinNoteDesc = 'CALLS_CONNECTION_MIN_NOTE_DESC',
  CallsConnectionMinOutcomeAsc = 'CALLS_CONNECTION_MIN_OUTCOME_ASC',
  CallsConnectionMinOutcomeDesc = 'CALLS_CONNECTION_MIN_OUTCOME_DESC',
  CallsConnectionMinProjectIdAsc = 'CALLS_CONNECTION_MIN_PROJECT_ID_ASC',
  CallsConnectionMinProjectIdDesc = 'CALLS_CONNECTION_MIN_PROJECT_ID_DESC',
  CallsConnectionMinReasonAsc = 'CALLS_CONNECTION_MIN_REASON_ASC',
  CallsConnectionMinReasonDesc = 'CALLS_CONNECTION_MIN_REASON_DESC',
  CallsConnectionMinRecordingUrlAsc = 'CALLS_CONNECTION_MIN_RECORDING_URL_ASC',
  CallsConnectionMinRecordingUrlDesc = 'CALLS_CONNECTION_MIN_RECORDING_URL_DESC',
  CallsConnectionMinStartTimeAsc = 'CALLS_CONNECTION_MIN_START_TIME_ASC',
  CallsConnectionMinStartTimeDesc = 'CALLS_CONNECTION_MIN_START_TIME_DESC',
  CallsConnectionMinToContactIdAsc = 'CALLS_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  CallsConnectionMinToContactIdDesc = 'CALLS_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  CallsConnectionMinToNumberAsc = 'CALLS_CONNECTION_MIN_TO_NUMBER_ASC',
  CallsConnectionMinToNumberDesc = 'CALLS_CONNECTION_MIN_TO_NUMBER_DESC',
  CallsConnectionMinTranscriptUrlAsc = 'CALLS_CONNECTION_MIN_TRANSCRIPT_URL_ASC',
  CallsConnectionMinTranscriptUrlDesc = 'CALLS_CONNECTION_MIN_TRANSCRIPT_URL_DESC',
  CallsConnectionMinUpdatedAtAsc = 'CALLS_CONNECTION_MIN_UPDATED_AT_ASC',
  CallsConnectionMinUpdatedAtDesc = 'CALLS_CONNECTION_MIN_UPDATED_AT_DESC',
  CallsConnectionStddevPopulationCompanyIdAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CallsConnectionStddevPopulationCompanyIdDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CallsConnectionStddevPopulationCreatedAtAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CallsConnectionStddevPopulationCreatedAtDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CallsConnectionStddevPopulationCreatedByAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CallsConnectionStddevPopulationCreatedByDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CallsConnectionStddevPopulationDispositionAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_DISPOSITION_ASC',
  CallsConnectionStddevPopulationDispositionDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_DISPOSITION_DESC',
  CallsConnectionStddevPopulationDurationInMsAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_ASC',
  CallsConnectionStddevPopulationDurationInMsDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_DESC',
  CallsConnectionStddevPopulationEndTimeAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_END_TIME_ASC',
  CallsConnectionStddevPopulationEndTimeDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_END_TIME_DESC',
  CallsConnectionStddevPopulationFromContactIdAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  CallsConnectionStddevPopulationFromContactIdDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  CallsConnectionStddevPopulationFromNumberAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_ASC',
  CallsConnectionStddevPopulationFromNumberDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_DESC',
  CallsConnectionStddevPopulationIdAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CallsConnectionStddevPopulationIdDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CallsConnectionStddevPopulationIsInboundAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_IS_INBOUND_ASC',
  CallsConnectionStddevPopulationIsInboundDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_IS_INBOUND_DESC',
  CallsConnectionStddevPopulationMissedCountAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_ASC',
  CallsConnectionStddevPopulationMissedCountDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_DESC',
  CallsConnectionStddevPopulationNoteAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_NOTE_ASC',
  CallsConnectionStddevPopulationNoteDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_NOTE_DESC',
  CallsConnectionStddevPopulationOutcomeAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_OUTCOME_ASC',
  CallsConnectionStddevPopulationOutcomeDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_OUTCOME_DESC',
  CallsConnectionStddevPopulationProjectIdAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CallsConnectionStddevPopulationProjectIdDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CallsConnectionStddevPopulationReasonAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_REASON_ASC',
  CallsConnectionStddevPopulationReasonDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_REASON_DESC',
  CallsConnectionStddevPopulationRecordingUrlAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_RECORDING_URL_ASC',
  CallsConnectionStddevPopulationRecordingUrlDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_RECORDING_URL_DESC',
  CallsConnectionStddevPopulationStartTimeAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_START_TIME_ASC',
  CallsConnectionStddevPopulationStartTimeDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_START_TIME_DESC',
  CallsConnectionStddevPopulationToContactIdAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  CallsConnectionStddevPopulationToContactIdDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  CallsConnectionStddevPopulationToNumberAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_TO_NUMBER_ASC',
  CallsConnectionStddevPopulationToNumberDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_TO_NUMBER_DESC',
  CallsConnectionStddevPopulationTranscriptUrlAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_ASC',
  CallsConnectionStddevPopulationTranscriptUrlDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_DESC',
  CallsConnectionStddevPopulationUpdatedAtAsc = 'CALLS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CallsConnectionStddevPopulationUpdatedAtDesc = 'CALLS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CallsConnectionStddevSampleCompanyIdAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CallsConnectionStddevSampleCompanyIdDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CallsConnectionStddevSampleCreatedAtAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CallsConnectionStddevSampleCreatedAtDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CallsConnectionStddevSampleCreatedByAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CallsConnectionStddevSampleCreatedByDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CallsConnectionStddevSampleDispositionAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_DISPOSITION_ASC',
  CallsConnectionStddevSampleDispositionDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_DISPOSITION_DESC',
  CallsConnectionStddevSampleDurationInMsAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_ASC',
  CallsConnectionStddevSampleDurationInMsDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_DESC',
  CallsConnectionStddevSampleEndTimeAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_END_TIME_ASC',
  CallsConnectionStddevSampleEndTimeDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_END_TIME_DESC',
  CallsConnectionStddevSampleFromContactIdAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsConnectionStddevSampleFromContactIdDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsConnectionStddevSampleFromNumberAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_ASC',
  CallsConnectionStddevSampleFromNumberDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_DESC',
  CallsConnectionStddevSampleIdAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CallsConnectionStddevSampleIdDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CallsConnectionStddevSampleIsInboundAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_ASC',
  CallsConnectionStddevSampleIsInboundDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_DESC',
  CallsConnectionStddevSampleMissedCountAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_ASC',
  CallsConnectionStddevSampleMissedCountDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_DESC',
  CallsConnectionStddevSampleNoteAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_NOTE_ASC',
  CallsConnectionStddevSampleNoteDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_NOTE_DESC',
  CallsConnectionStddevSampleOutcomeAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_OUTCOME_ASC',
  CallsConnectionStddevSampleOutcomeDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_OUTCOME_DESC',
  CallsConnectionStddevSampleProjectIdAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CallsConnectionStddevSampleProjectIdDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CallsConnectionStddevSampleReasonAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_REASON_ASC',
  CallsConnectionStddevSampleReasonDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_REASON_DESC',
  CallsConnectionStddevSampleRecordingUrlAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_ASC',
  CallsConnectionStddevSampleRecordingUrlDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_DESC',
  CallsConnectionStddevSampleStartTimeAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_START_TIME_ASC',
  CallsConnectionStddevSampleStartTimeDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_START_TIME_DESC',
  CallsConnectionStddevSampleToContactIdAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  CallsConnectionStddevSampleToContactIdDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  CallsConnectionStddevSampleToNumberAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_ASC',
  CallsConnectionStddevSampleToNumberDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_DESC',
  CallsConnectionStddevSampleTranscriptUrlAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsConnectionStddevSampleTranscriptUrlDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsConnectionStddevSampleUpdatedAtAsc = 'CALLS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CallsConnectionStddevSampleUpdatedAtDesc = 'CALLS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CallsConnectionSumCompanyIdAsc = 'CALLS_CONNECTION_SUM_COMPANY_ID_ASC',
  CallsConnectionSumCompanyIdDesc = 'CALLS_CONNECTION_SUM_COMPANY_ID_DESC',
  CallsConnectionSumCreatedAtAsc = 'CALLS_CONNECTION_SUM_CREATED_AT_ASC',
  CallsConnectionSumCreatedAtDesc = 'CALLS_CONNECTION_SUM_CREATED_AT_DESC',
  CallsConnectionSumCreatedByAsc = 'CALLS_CONNECTION_SUM_CREATED_BY_ASC',
  CallsConnectionSumCreatedByDesc = 'CALLS_CONNECTION_SUM_CREATED_BY_DESC',
  CallsConnectionSumDispositionAsc = 'CALLS_CONNECTION_SUM_DISPOSITION_ASC',
  CallsConnectionSumDispositionDesc = 'CALLS_CONNECTION_SUM_DISPOSITION_DESC',
  CallsConnectionSumDurationInMsAsc = 'CALLS_CONNECTION_SUM_DURATION_IN_MS_ASC',
  CallsConnectionSumDurationInMsDesc = 'CALLS_CONNECTION_SUM_DURATION_IN_MS_DESC',
  CallsConnectionSumEndTimeAsc = 'CALLS_CONNECTION_SUM_END_TIME_ASC',
  CallsConnectionSumEndTimeDesc = 'CALLS_CONNECTION_SUM_END_TIME_DESC',
  CallsConnectionSumFromContactIdAsc = 'CALLS_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  CallsConnectionSumFromContactIdDesc = 'CALLS_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  CallsConnectionSumFromNumberAsc = 'CALLS_CONNECTION_SUM_FROM_NUMBER_ASC',
  CallsConnectionSumFromNumberDesc = 'CALLS_CONNECTION_SUM_FROM_NUMBER_DESC',
  CallsConnectionSumIdAsc = 'CALLS_CONNECTION_SUM_ID_ASC',
  CallsConnectionSumIdDesc = 'CALLS_CONNECTION_SUM_ID_DESC',
  CallsConnectionSumIsInboundAsc = 'CALLS_CONNECTION_SUM_IS_INBOUND_ASC',
  CallsConnectionSumIsInboundDesc = 'CALLS_CONNECTION_SUM_IS_INBOUND_DESC',
  CallsConnectionSumMissedCountAsc = 'CALLS_CONNECTION_SUM_MISSED_COUNT_ASC',
  CallsConnectionSumMissedCountDesc = 'CALLS_CONNECTION_SUM_MISSED_COUNT_DESC',
  CallsConnectionSumNoteAsc = 'CALLS_CONNECTION_SUM_NOTE_ASC',
  CallsConnectionSumNoteDesc = 'CALLS_CONNECTION_SUM_NOTE_DESC',
  CallsConnectionSumOutcomeAsc = 'CALLS_CONNECTION_SUM_OUTCOME_ASC',
  CallsConnectionSumOutcomeDesc = 'CALLS_CONNECTION_SUM_OUTCOME_DESC',
  CallsConnectionSumProjectIdAsc = 'CALLS_CONNECTION_SUM_PROJECT_ID_ASC',
  CallsConnectionSumProjectIdDesc = 'CALLS_CONNECTION_SUM_PROJECT_ID_DESC',
  CallsConnectionSumReasonAsc = 'CALLS_CONNECTION_SUM_REASON_ASC',
  CallsConnectionSumReasonDesc = 'CALLS_CONNECTION_SUM_REASON_DESC',
  CallsConnectionSumRecordingUrlAsc = 'CALLS_CONNECTION_SUM_RECORDING_URL_ASC',
  CallsConnectionSumRecordingUrlDesc = 'CALLS_CONNECTION_SUM_RECORDING_URL_DESC',
  CallsConnectionSumStartTimeAsc = 'CALLS_CONNECTION_SUM_START_TIME_ASC',
  CallsConnectionSumStartTimeDesc = 'CALLS_CONNECTION_SUM_START_TIME_DESC',
  CallsConnectionSumToContactIdAsc = 'CALLS_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  CallsConnectionSumToContactIdDesc = 'CALLS_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  CallsConnectionSumToNumberAsc = 'CALLS_CONNECTION_SUM_TO_NUMBER_ASC',
  CallsConnectionSumToNumberDesc = 'CALLS_CONNECTION_SUM_TO_NUMBER_DESC',
  CallsConnectionSumTranscriptUrlAsc = 'CALLS_CONNECTION_SUM_TRANSCRIPT_URL_ASC',
  CallsConnectionSumTranscriptUrlDesc = 'CALLS_CONNECTION_SUM_TRANSCRIPT_URL_DESC',
  CallsConnectionSumUpdatedAtAsc = 'CALLS_CONNECTION_SUM_UPDATED_AT_ASC',
  CallsConnectionSumUpdatedAtDesc = 'CALLS_CONNECTION_SUM_UPDATED_AT_DESC',
  CallsConnectionVariancePopulationCompanyIdAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CallsConnectionVariancePopulationCompanyIdDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CallsConnectionVariancePopulationCreatedAtAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CallsConnectionVariancePopulationCreatedAtDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CallsConnectionVariancePopulationCreatedByAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CallsConnectionVariancePopulationCreatedByDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CallsConnectionVariancePopulationDispositionAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_DISPOSITION_ASC',
  CallsConnectionVariancePopulationDispositionDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_DISPOSITION_DESC',
  CallsConnectionVariancePopulationDurationInMsAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_ASC',
  CallsConnectionVariancePopulationDurationInMsDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_DESC',
  CallsConnectionVariancePopulationEndTimeAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_END_TIME_ASC',
  CallsConnectionVariancePopulationEndTimeDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_END_TIME_DESC',
  CallsConnectionVariancePopulationFromContactIdAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  CallsConnectionVariancePopulationFromContactIdDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  CallsConnectionVariancePopulationFromNumberAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_ASC',
  CallsConnectionVariancePopulationFromNumberDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_DESC',
  CallsConnectionVariancePopulationIdAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CallsConnectionVariancePopulationIdDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CallsConnectionVariancePopulationIsInboundAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_ASC',
  CallsConnectionVariancePopulationIsInboundDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_DESC',
  CallsConnectionVariancePopulationMissedCountAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_ASC',
  CallsConnectionVariancePopulationMissedCountDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_DESC',
  CallsConnectionVariancePopulationNoteAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_NOTE_ASC',
  CallsConnectionVariancePopulationNoteDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_NOTE_DESC',
  CallsConnectionVariancePopulationOutcomeAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_OUTCOME_ASC',
  CallsConnectionVariancePopulationOutcomeDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_OUTCOME_DESC',
  CallsConnectionVariancePopulationProjectIdAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CallsConnectionVariancePopulationProjectIdDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CallsConnectionVariancePopulationReasonAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_REASON_ASC',
  CallsConnectionVariancePopulationReasonDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_REASON_DESC',
  CallsConnectionVariancePopulationRecordingUrlAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_ASC',
  CallsConnectionVariancePopulationRecordingUrlDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_DESC',
  CallsConnectionVariancePopulationStartTimeAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_START_TIME_ASC',
  CallsConnectionVariancePopulationStartTimeDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_START_TIME_DESC',
  CallsConnectionVariancePopulationToContactIdAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  CallsConnectionVariancePopulationToContactIdDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  CallsConnectionVariancePopulationToNumberAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_ASC',
  CallsConnectionVariancePopulationToNumberDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_DESC',
  CallsConnectionVariancePopulationTranscriptUrlAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_ASC',
  CallsConnectionVariancePopulationTranscriptUrlDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_DESC',
  CallsConnectionVariancePopulationUpdatedAtAsc = 'CALLS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CallsConnectionVariancePopulationUpdatedAtDesc = 'CALLS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CallsConnectionVarianceSampleCompanyIdAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CallsConnectionVarianceSampleCompanyIdDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CallsConnectionVarianceSampleCreatedAtAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CallsConnectionVarianceSampleCreatedAtDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CallsConnectionVarianceSampleCreatedByAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CallsConnectionVarianceSampleCreatedByDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CallsConnectionVarianceSampleDispositionAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_ASC',
  CallsConnectionVarianceSampleDispositionDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_DESC',
  CallsConnectionVarianceSampleDurationInMsAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_ASC',
  CallsConnectionVarianceSampleDurationInMsDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_DESC',
  CallsConnectionVarianceSampleEndTimeAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_END_TIME_ASC',
  CallsConnectionVarianceSampleEndTimeDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_END_TIME_DESC',
  CallsConnectionVarianceSampleFromContactIdAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsConnectionVarianceSampleFromContactIdDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsConnectionVarianceSampleFromNumberAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_ASC',
  CallsConnectionVarianceSampleFromNumberDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_DESC',
  CallsConnectionVarianceSampleIdAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CallsConnectionVarianceSampleIdDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CallsConnectionVarianceSampleIsInboundAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_ASC',
  CallsConnectionVarianceSampleIsInboundDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_DESC',
  CallsConnectionVarianceSampleMissedCountAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_ASC',
  CallsConnectionVarianceSampleMissedCountDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_DESC',
  CallsConnectionVarianceSampleNoteAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_NOTE_ASC',
  CallsConnectionVarianceSampleNoteDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_NOTE_DESC',
  CallsConnectionVarianceSampleOutcomeAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_OUTCOME_ASC',
  CallsConnectionVarianceSampleOutcomeDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_OUTCOME_DESC',
  CallsConnectionVarianceSampleProjectIdAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CallsConnectionVarianceSampleProjectIdDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CallsConnectionVarianceSampleReasonAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_REASON_ASC',
  CallsConnectionVarianceSampleReasonDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_REASON_DESC',
  CallsConnectionVarianceSampleRecordingUrlAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_ASC',
  CallsConnectionVarianceSampleRecordingUrlDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_DESC',
  CallsConnectionVarianceSampleStartTimeAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_START_TIME_ASC',
  CallsConnectionVarianceSampleStartTimeDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_START_TIME_DESC',
  CallsConnectionVarianceSampleToContactIdAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  CallsConnectionVarianceSampleToContactIdDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  CallsConnectionVarianceSampleToNumberAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_ASC',
  CallsConnectionVarianceSampleToNumberDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_DESC',
  CallsConnectionVarianceSampleTranscriptUrlAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsConnectionVarianceSampleTranscriptUrlDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsConnectionVarianceSampleUpdatedAtAsc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CallsConnectionVarianceSampleUpdatedAtDesc = 'CALLS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsConnectionArrayAggCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CATEGORY_ID_ASC',
  CatalogItemsConnectionArrayAggCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CATEGORY_ID_DESC',
  CatalogItemsConnectionArrayAggCodeAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CODE_ASC',
  CatalogItemsConnectionArrayAggCodeDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CODE_DESC',
  CatalogItemsConnectionArrayAggCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CatalogItemsConnectionArrayAggCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CatalogItemsConnectionArrayAggCostAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_COST_ASC',
  CatalogItemsConnectionArrayAggCostDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_COST_DESC',
  CatalogItemsConnectionArrayAggCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CatalogItemsConnectionArrayAggCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CatalogItemsConnectionArrayAggCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CatalogItemsConnectionArrayAggCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CatalogItemsConnectionArrayAggDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_DATASHEET_ASC',
  CatalogItemsConnectionArrayAggDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_DATASHEET_DESC',
  CatalogItemsConnectionArrayAggDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  CatalogItemsConnectionArrayAggDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  CatalogItemsConnectionArrayAggIdAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_ID_ASC',
  CatalogItemsConnectionArrayAggIdDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_ID_DESC',
  CatalogItemsConnectionArrayAggImageIdAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IMAGE_ID_ASC',
  CatalogItemsConnectionArrayAggImageIdDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IMAGE_ID_DESC',
  CatalogItemsConnectionArrayAggIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  CatalogItemsConnectionArrayAggIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  CatalogItemsConnectionArrayAggIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IS_CUSTOM_ASC',
  CatalogItemsConnectionArrayAggIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_IS_CUSTOM_DESC',
  CatalogItemsConnectionArrayAggManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_MANUFACTURER_ASC',
  CatalogItemsConnectionArrayAggManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_MANUFACTURER_DESC',
  CatalogItemsConnectionArrayAggMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionArrayAggMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionArrayAggNameAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_NAME_ASC',
  CatalogItemsConnectionArrayAggNameDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_NAME_DESC',
  CatalogItemsConnectionArrayAggPriceAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_PRICE_ASC',
  CatalogItemsConnectionArrayAggPriceDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_PRICE_DESC',
  CatalogItemsConnectionArrayAggSkuAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_SKU_ASC',
  CatalogItemsConnectionArrayAggSkuDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_SKU_DESC',
  CatalogItemsConnectionArrayAggSpecAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_SPEC_ASC',
  CatalogItemsConnectionArrayAggSpecDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_SPEC_DESC',
  CatalogItemsConnectionArrayAggTypeAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CatalogItemsConnectionArrayAggTypeDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CatalogItemsConnectionArrayAggUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CatalogItemsConnectionArrayAggUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CatalogItemsConnectionAverageCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CATEGORY_ID_ASC',
  CatalogItemsConnectionAverageCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CATEGORY_ID_DESC',
  CatalogItemsConnectionAverageCodeAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CODE_ASC',
  CatalogItemsConnectionAverageCodeDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CODE_DESC',
  CatalogItemsConnectionAverageCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CatalogItemsConnectionAverageCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CatalogItemsConnectionAverageCostAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_COST_ASC',
  CatalogItemsConnectionAverageCostDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_COST_DESC',
  CatalogItemsConnectionAverageCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CatalogItemsConnectionAverageCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CatalogItemsConnectionAverageCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CatalogItemsConnectionAverageCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CatalogItemsConnectionAverageDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_DATASHEET_ASC',
  CatalogItemsConnectionAverageDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_DATASHEET_DESC',
  CatalogItemsConnectionAverageDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  CatalogItemsConnectionAverageDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  CatalogItemsConnectionAverageIdAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_ID_ASC',
  CatalogItemsConnectionAverageIdDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_ID_DESC',
  CatalogItemsConnectionAverageImageIdAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IMAGE_ID_ASC',
  CatalogItemsConnectionAverageImageIdDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IMAGE_ID_DESC',
  CatalogItemsConnectionAverageIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  CatalogItemsConnectionAverageIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  CatalogItemsConnectionAverageIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IS_CUSTOM_ASC',
  CatalogItemsConnectionAverageIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_IS_CUSTOM_DESC',
  CatalogItemsConnectionAverageManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_MANUFACTURER_ASC',
  CatalogItemsConnectionAverageManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_MANUFACTURER_DESC',
  CatalogItemsConnectionAverageMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionAverageMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionAverageNameAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_NAME_ASC',
  CatalogItemsConnectionAverageNameDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_NAME_DESC',
  CatalogItemsConnectionAveragePriceAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_PRICE_ASC',
  CatalogItemsConnectionAveragePriceDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_PRICE_DESC',
  CatalogItemsConnectionAverageSkuAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_SKU_ASC',
  CatalogItemsConnectionAverageSkuDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_SKU_DESC',
  CatalogItemsConnectionAverageSpecAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_SPEC_ASC',
  CatalogItemsConnectionAverageSpecDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_SPEC_DESC',
  CatalogItemsConnectionAverageTypeAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_TYPE_ASC',
  CatalogItemsConnectionAverageTypeDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_TYPE_DESC',
  CatalogItemsConnectionAverageUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CatalogItemsConnectionAverageUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CatalogItemsConnectionCountAsc = 'CATALOG_ITEMS_CONNECTION_COUNT_ASC',
  CatalogItemsConnectionCountDesc = 'CATALOG_ITEMS_CONNECTION_COUNT_DESC',
  CatalogItemsConnectionDistinctCountCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_ASC',
  CatalogItemsConnectionDistinctCountCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_DESC',
  CatalogItemsConnectionDistinctCountCodeAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CODE_ASC',
  CatalogItemsConnectionDistinctCountCodeDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CODE_DESC',
  CatalogItemsConnectionDistinctCountCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CatalogItemsConnectionDistinctCountCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CatalogItemsConnectionDistinctCountCostAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_COST_ASC',
  CatalogItemsConnectionDistinctCountCostDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_COST_DESC',
  CatalogItemsConnectionDistinctCountCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CatalogItemsConnectionDistinctCountCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CatalogItemsConnectionDistinctCountCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CatalogItemsConnectionDistinctCountCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CatalogItemsConnectionDistinctCountDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_DATASHEET_ASC',
  CatalogItemsConnectionDistinctCountDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_DATASHEET_DESC',
  CatalogItemsConnectionDistinctCountDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  CatalogItemsConnectionDistinctCountDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  CatalogItemsConnectionDistinctCountIdAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CatalogItemsConnectionDistinctCountIdDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CatalogItemsConnectionDistinctCountImageIdAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IMAGE_ID_ASC',
  CatalogItemsConnectionDistinctCountImageIdDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IMAGE_ID_DESC',
  CatalogItemsConnectionDistinctCountIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  CatalogItemsConnectionDistinctCountIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  CatalogItemsConnectionDistinctCountIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_ASC',
  CatalogItemsConnectionDistinctCountIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_DESC',
  CatalogItemsConnectionDistinctCountManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_MANUFACTURER_ASC',
  CatalogItemsConnectionDistinctCountManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_MANUFACTURER_DESC',
  CatalogItemsConnectionDistinctCountMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionDistinctCountMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionDistinctCountNameAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  CatalogItemsConnectionDistinctCountNameDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  CatalogItemsConnectionDistinctCountPriceAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_PRICE_ASC',
  CatalogItemsConnectionDistinctCountPriceDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_PRICE_DESC',
  CatalogItemsConnectionDistinctCountSkuAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_SKU_ASC',
  CatalogItemsConnectionDistinctCountSkuDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_SKU_DESC',
  CatalogItemsConnectionDistinctCountSpecAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_SPEC_ASC',
  CatalogItemsConnectionDistinctCountSpecDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_SPEC_DESC',
  CatalogItemsConnectionDistinctCountTypeAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CatalogItemsConnectionDistinctCountTypeDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CatalogItemsConnectionDistinctCountUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CatalogItemsConnectionDistinctCountUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CatalogItemsConnectionMaxCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_MAX_CATEGORY_ID_ASC',
  CatalogItemsConnectionMaxCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_MAX_CATEGORY_ID_DESC',
  CatalogItemsConnectionMaxCodeAsc = 'CATALOG_ITEMS_CONNECTION_MAX_CODE_ASC',
  CatalogItemsConnectionMaxCodeDesc = 'CATALOG_ITEMS_CONNECTION_MAX_CODE_DESC',
  CatalogItemsConnectionMaxCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_MAX_COMPANY_ID_ASC',
  CatalogItemsConnectionMaxCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_MAX_COMPANY_ID_DESC',
  CatalogItemsConnectionMaxCostAsc = 'CATALOG_ITEMS_CONNECTION_MAX_COST_ASC',
  CatalogItemsConnectionMaxCostDesc = 'CATALOG_ITEMS_CONNECTION_MAX_COST_DESC',
  CatalogItemsConnectionMaxCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_MAX_CREATED_AT_ASC',
  CatalogItemsConnectionMaxCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_MAX_CREATED_AT_DESC',
  CatalogItemsConnectionMaxCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_MAX_CREATED_BY_ASC',
  CatalogItemsConnectionMaxCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_MAX_CREATED_BY_DESC',
  CatalogItemsConnectionMaxDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_MAX_DATASHEET_ASC',
  CatalogItemsConnectionMaxDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_MAX_DATASHEET_DESC',
  CatalogItemsConnectionMaxDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_MAX_DESCRIPTION_ASC',
  CatalogItemsConnectionMaxDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_MAX_DESCRIPTION_DESC',
  CatalogItemsConnectionMaxIdAsc = 'CATALOG_ITEMS_CONNECTION_MAX_ID_ASC',
  CatalogItemsConnectionMaxIdDesc = 'CATALOG_ITEMS_CONNECTION_MAX_ID_DESC',
  CatalogItemsConnectionMaxImageIdAsc = 'CATALOG_ITEMS_CONNECTION_MAX_IMAGE_ID_ASC',
  CatalogItemsConnectionMaxImageIdDesc = 'CATALOG_ITEMS_CONNECTION_MAX_IMAGE_ID_DESC',
  CatalogItemsConnectionMaxIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  CatalogItemsConnectionMaxIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  CatalogItemsConnectionMaxIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_MAX_IS_CUSTOM_ASC',
  CatalogItemsConnectionMaxIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_MAX_IS_CUSTOM_DESC',
  CatalogItemsConnectionMaxManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_MAX_MANUFACTURER_ASC',
  CatalogItemsConnectionMaxManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_MAX_MANUFACTURER_DESC',
  CatalogItemsConnectionMaxMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_MAX_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionMaxMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_MAX_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionMaxNameAsc = 'CATALOG_ITEMS_CONNECTION_MAX_NAME_ASC',
  CatalogItemsConnectionMaxNameDesc = 'CATALOG_ITEMS_CONNECTION_MAX_NAME_DESC',
  CatalogItemsConnectionMaxPriceAsc = 'CATALOG_ITEMS_CONNECTION_MAX_PRICE_ASC',
  CatalogItemsConnectionMaxPriceDesc = 'CATALOG_ITEMS_CONNECTION_MAX_PRICE_DESC',
  CatalogItemsConnectionMaxSkuAsc = 'CATALOG_ITEMS_CONNECTION_MAX_SKU_ASC',
  CatalogItemsConnectionMaxSkuDesc = 'CATALOG_ITEMS_CONNECTION_MAX_SKU_DESC',
  CatalogItemsConnectionMaxSpecAsc = 'CATALOG_ITEMS_CONNECTION_MAX_SPEC_ASC',
  CatalogItemsConnectionMaxSpecDesc = 'CATALOG_ITEMS_CONNECTION_MAX_SPEC_DESC',
  CatalogItemsConnectionMaxTypeAsc = 'CATALOG_ITEMS_CONNECTION_MAX_TYPE_ASC',
  CatalogItemsConnectionMaxTypeDesc = 'CATALOG_ITEMS_CONNECTION_MAX_TYPE_DESC',
  CatalogItemsConnectionMaxUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_MAX_UPDATED_AT_ASC',
  CatalogItemsConnectionMaxUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_MAX_UPDATED_AT_DESC',
  CatalogItemsConnectionMinCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_MIN_CATEGORY_ID_ASC',
  CatalogItemsConnectionMinCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_MIN_CATEGORY_ID_DESC',
  CatalogItemsConnectionMinCodeAsc = 'CATALOG_ITEMS_CONNECTION_MIN_CODE_ASC',
  CatalogItemsConnectionMinCodeDesc = 'CATALOG_ITEMS_CONNECTION_MIN_CODE_DESC',
  CatalogItemsConnectionMinCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_MIN_COMPANY_ID_ASC',
  CatalogItemsConnectionMinCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_MIN_COMPANY_ID_DESC',
  CatalogItemsConnectionMinCostAsc = 'CATALOG_ITEMS_CONNECTION_MIN_COST_ASC',
  CatalogItemsConnectionMinCostDesc = 'CATALOG_ITEMS_CONNECTION_MIN_COST_DESC',
  CatalogItemsConnectionMinCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_MIN_CREATED_AT_ASC',
  CatalogItemsConnectionMinCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_MIN_CREATED_AT_DESC',
  CatalogItemsConnectionMinCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_MIN_CREATED_BY_ASC',
  CatalogItemsConnectionMinCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_MIN_CREATED_BY_DESC',
  CatalogItemsConnectionMinDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_MIN_DATASHEET_ASC',
  CatalogItemsConnectionMinDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_MIN_DATASHEET_DESC',
  CatalogItemsConnectionMinDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_MIN_DESCRIPTION_ASC',
  CatalogItemsConnectionMinDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_MIN_DESCRIPTION_DESC',
  CatalogItemsConnectionMinIdAsc = 'CATALOG_ITEMS_CONNECTION_MIN_ID_ASC',
  CatalogItemsConnectionMinIdDesc = 'CATALOG_ITEMS_CONNECTION_MIN_ID_DESC',
  CatalogItemsConnectionMinImageIdAsc = 'CATALOG_ITEMS_CONNECTION_MIN_IMAGE_ID_ASC',
  CatalogItemsConnectionMinImageIdDesc = 'CATALOG_ITEMS_CONNECTION_MIN_IMAGE_ID_DESC',
  CatalogItemsConnectionMinIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  CatalogItemsConnectionMinIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  CatalogItemsConnectionMinIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_MIN_IS_CUSTOM_ASC',
  CatalogItemsConnectionMinIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_MIN_IS_CUSTOM_DESC',
  CatalogItemsConnectionMinManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_MIN_MANUFACTURER_ASC',
  CatalogItemsConnectionMinManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_MIN_MANUFACTURER_DESC',
  CatalogItemsConnectionMinMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_MIN_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionMinMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_MIN_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionMinNameAsc = 'CATALOG_ITEMS_CONNECTION_MIN_NAME_ASC',
  CatalogItemsConnectionMinNameDesc = 'CATALOG_ITEMS_CONNECTION_MIN_NAME_DESC',
  CatalogItemsConnectionMinPriceAsc = 'CATALOG_ITEMS_CONNECTION_MIN_PRICE_ASC',
  CatalogItemsConnectionMinPriceDesc = 'CATALOG_ITEMS_CONNECTION_MIN_PRICE_DESC',
  CatalogItemsConnectionMinSkuAsc = 'CATALOG_ITEMS_CONNECTION_MIN_SKU_ASC',
  CatalogItemsConnectionMinSkuDesc = 'CATALOG_ITEMS_CONNECTION_MIN_SKU_DESC',
  CatalogItemsConnectionMinSpecAsc = 'CATALOG_ITEMS_CONNECTION_MIN_SPEC_ASC',
  CatalogItemsConnectionMinSpecDesc = 'CATALOG_ITEMS_CONNECTION_MIN_SPEC_DESC',
  CatalogItemsConnectionMinTypeAsc = 'CATALOG_ITEMS_CONNECTION_MIN_TYPE_ASC',
  CatalogItemsConnectionMinTypeDesc = 'CATALOG_ITEMS_CONNECTION_MIN_TYPE_DESC',
  CatalogItemsConnectionMinUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_MIN_UPDATED_AT_ASC',
  CatalogItemsConnectionMinUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_MIN_UPDATED_AT_DESC',
  CatalogItemsConnectionStddevPopulationCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsConnectionStddevPopulationCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsConnectionStddevPopulationCodeAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CODE_ASC',
  CatalogItemsConnectionStddevPopulationCodeDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CODE_DESC',
  CatalogItemsConnectionStddevPopulationCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CatalogItemsConnectionStddevPopulationCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CatalogItemsConnectionStddevPopulationCostAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_COST_ASC',
  CatalogItemsConnectionStddevPopulationCostDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_COST_DESC',
  CatalogItemsConnectionStddevPopulationCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CatalogItemsConnectionStddevPopulationCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CatalogItemsConnectionStddevPopulationCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CatalogItemsConnectionStddevPopulationCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CatalogItemsConnectionStddevPopulationDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_DATASHEET_ASC',
  CatalogItemsConnectionStddevPopulationDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_DATASHEET_DESC',
  CatalogItemsConnectionStddevPopulationDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  CatalogItemsConnectionStddevPopulationDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  CatalogItemsConnectionStddevPopulationIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CatalogItemsConnectionStddevPopulationIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CatalogItemsConnectionStddevPopulationImageIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IMAGE_ID_ASC',
  CatalogItemsConnectionStddevPopulationImageIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IMAGE_ID_DESC',
  CatalogItemsConnectionStddevPopulationIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsConnectionStddevPopulationIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsConnectionStddevPopulationIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsConnectionStddevPopulationIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsConnectionStddevPopulationManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_MANUFACTURER_ASC',
  CatalogItemsConnectionStddevPopulationManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_MANUFACTURER_DESC',
  CatalogItemsConnectionStddevPopulationMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionStddevPopulationMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionStddevPopulationNameAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  CatalogItemsConnectionStddevPopulationNameDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  CatalogItemsConnectionStddevPopulationPriceAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_PRICE_ASC',
  CatalogItemsConnectionStddevPopulationPriceDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_PRICE_DESC',
  CatalogItemsConnectionStddevPopulationSkuAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_SKU_ASC',
  CatalogItemsConnectionStddevPopulationSkuDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_SKU_DESC',
  CatalogItemsConnectionStddevPopulationSpecAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_SPEC_ASC',
  CatalogItemsConnectionStddevPopulationSpecDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_SPEC_DESC',
  CatalogItemsConnectionStddevPopulationTypeAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CatalogItemsConnectionStddevPopulationTypeDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CatalogItemsConnectionStddevPopulationUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CatalogItemsConnectionStddevPopulationUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CatalogItemsConnectionStddevSampleCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsConnectionStddevSampleCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsConnectionStddevSampleCodeAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CODE_ASC',
  CatalogItemsConnectionStddevSampleCodeDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CODE_DESC',
  CatalogItemsConnectionStddevSampleCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsConnectionStddevSampleCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsConnectionStddevSampleCostAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_COST_ASC',
  CatalogItemsConnectionStddevSampleCostDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_COST_DESC',
  CatalogItemsConnectionStddevSampleCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CatalogItemsConnectionStddevSampleCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CatalogItemsConnectionStddevSampleCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CatalogItemsConnectionStddevSampleCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CatalogItemsConnectionStddevSampleDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_DATASHEET_ASC',
  CatalogItemsConnectionStddevSampleDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_DATASHEET_DESC',
  CatalogItemsConnectionStddevSampleDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsConnectionStddevSampleDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsConnectionStddevSampleIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CatalogItemsConnectionStddevSampleIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CatalogItemsConnectionStddevSampleImageIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsConnectionStddevSampleImageIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsConnectionStddevSampleIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsConnectionStddevSampleIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsConnectionStddevSampleIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsConnectionStddevSampleIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsConnectionStddevSampleManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsConnectionStddevSampleManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsConnectionStddevSampleMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionStddevSampleMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionStddevSampleNameAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  CatalogItemsConnectionStddevSampleNameDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  CatalogItemsConnectionStddevSamplePriceAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_PRICE_ASC',
  CatalogItemsConnectionStddevSamplePriceDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_PRICE_DESC',
  CatalogItemsConnectionStddevSampleSkuAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_SKU_ASC',
  CatalogItemsConnectionStddevSampleSkuDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_SKU_DESC',
  CatalogItemsConnectionStddevSampleSpecAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_SPEC_ASC',
  CatalogItemsConnectionStddevSampleSpecDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_SPEC_DESC',
  CatalogItemsConnectionStddevSampleTypeAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CatalogItemsConnectionStddevSampleTypeDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CatalogItemsConnectionStddevSampleUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsConnectionStddevSampleUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsConnectionSumCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_SUM_CATEGORY_ID_ASC',
  CatalogItemsConnectionSumCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_SUM_CATEGORY_ID_DESC',
  CatalogItemsConnectionSumCodeAsc = 'CATALOG_ITEMS_CONNECTION_SUM_CODE_ASC',
  CatalogItemsConnectionSumCodeDesc = 'CATALOG_ITEMS_CONNECTION_SUM_CODE_DESC',
  CatalogItemsConnectionSumCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_SUM_COMPANY_ID_ASC',
  CatalogItemsConnectionSumCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_SUM_COMPANY_ID_DESC',
  CatalogItemsConnectionSumCostAsc = 'CATALOG_ITEMS_CONNECTION_SUM_COST_ASC',
  CatalogItemsConnectionSumCostDesc = 'CATALOG_ITEMS_CONNECTION_SUM_COST_DESC',
  CatalogItemsConnectionSumCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_SUM_CREATED_AT_ASC',
  CatalogItemsConnectionSumCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_SUM_CREATED_AT_DESC',
  CatalogItemsConnectionSumCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_SUM_CREATED_BY_ASC',
  CatalogItemsConnectionSumCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_SUM_CREATED_BY_DESC',
  CatalogItemsConnectionSumDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_SUM_DATASHEET_ASC',
  CatalogItemsConnectionSumDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_SUM_DATASHEET_DESC',
  CatalogItemsConnectionSumDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_SUM_DESCRIPTION_ASC',
  CatalogItemsConnectionSumDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_SUM_DESCRIPTION_DESC',
  CatalogItemsConnectionSumIdAsc = 'CATALOG_ITEMS_CONNECTION_SUM_ID_ASC',
  CatalogItemsConnectionSumIdDesc = 'CATALOG_ITEMS_CONNECTION_SUM_ID_DESC',
  CatalogItemsConnectionSumImageIdAsc = 'CATALOG_ITEMS_CONNECTION_SUM_IMAGE_ID_ASC',
  CatalogItemsConnectionSumImageIdDesc = 'CATALOG_ITEMS_CONNECTION_SUM_IMAGE_ID_DESC',
  CatalogItemsConnectionSumIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  CatalogItemsConnectionSumIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  CatalogItemsConnectionSumIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_SUM_IS_CUSTOM_ASC',
  CatalogItemsConnectionSumIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_SUM_IS_CUSTOM_DESC',
  CatalogItemsConnectionSumManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_SUM_MANUFACTURER_ASC',
  CatalogItemsConnectionSumManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_SUM_MANUFACTURER_DESC',
  CatalogItemsConnectionSumMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_SUM_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionSumMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_SUM_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionSumNameAsc = 'CATALOG_ITEMS_CONNECTION_SUM_NAME_ASC',
  CatalogItemsConnectionSumNameDesc = 'CATALOG_ITEMS_CONNECTION_SUM_NAME_DESC',
  CatalogItemsConnectionSumPriceAsc = 'CATALOG_ITEMS_CONNECTION_SUM_PRICE_ASC',
  CatalogItemsConnectionSumPriceDesc = 'CATALOG_ITEMS_CONNECTION_SUM_PRICE_DESC',
  CatalogItemsConnectionSumSkuAsc = 'CATALOG_ITEMS_CONNECTION_SUM_SKU_ASC',
  CatalogItemsConnectionSumSkuDesc = 'CATALOG_ITEMS_CONNECTION_SUM_SKU_DESC',
  CatalogItemsConnectionSumSpecAsc = 'CATALOG_ITEMS_CONNECTION_SUM_SPEC_ASC',
  CatalogItemsConnectionSumSpecDesc = 'CATALOG_ITEMS_CONNECTION_SUM_SPEC_DESC',
  CatalogItemsConnectionSumTypeAsc = 'CATALOG_ITEMS_CONNECTION_SUM_TYPE_ASC',
  CatalogItemsConnectionSumTypeDesc = 'CATALOG_ITEMS_CONNECTION_SUM_TYPE_DESC',
  CatalogItemsConnectionSumUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_SUM_UPDATED_AT_ASC',
  CatalogItemsConnectionSumUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_SUM_UPDATED_AT_DESC',
  CatalogItemsConnectionVariancePopulationCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsConnectionVariancePopulationCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsConnectionVariancePopulationCodeAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CODE_ASC',
  CatalogItemsConnectionVariancePopulationCodeDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CODE_DESC',
  CatalogItemsConnectionVariancePopulationCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CatalogItemsConnectionVariancePopulationCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CatalogItemsConnectionVariancePopulationCostAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_COST_ASC',
  CatalogItemsConnectionVariancePopulationCostDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_COST_DESC',
  CatalogItemsConnectionVariancePopulationCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CatalogItemsConnectionVariancePopulationCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CatalogItemsConnectionVariancePopulationCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CatalogItemsConnectionVariancePopulationCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CatalogItemsConnectionVariancePopulationDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_DATASHEET_ASC',
  CatalogItemsConnectionVariancePopulationDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_DATASHEET_DESC',
  CatalogItemsConnectionVariancePopulationDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CatalogItemsConnectionVariancePopulationDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CatalogItemsConnectionVariancePopulationIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CatalogItemsConnectionVariancePopulationIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CatalogItemsConnectionVariancePopulationImageIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_ASC',
  CatalogItemsConnectionVariancePopulationImageIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_DESC',
  CatalogItemsConnectionVariancePopulationIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsConnectionVariancePopulationIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsConnectionVariancePopulationIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsConnectionVariancePopulationIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsConnectionVariancePopulationManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CatalogItemsConnectionVariancePopulationManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CatalogItemsConnectionVariancePopulationMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionVariancePopulationMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionVariancePopulationNameAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  CatalogItemsConnectionVariancePopulationNameDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  CatalogItemsConnectionVariancePopulationPriceAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_PRICE_ASC',
  CatalogItemsConnectionVariancePopulationPriceDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_PRICE_DESC',
  CatalogItemsConnectionVariancePopulationSkuAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_SKU_ASC',
  CatalogItemsConnectionVariancePopulationSkuDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_SKU_DESC',
  CatalogItemsConnectionVariancePopulationSpecAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_SPEC_ASC',
  CatalogItemsConnectionVariancePopulationSpecDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_SPEC_DESC',
  CatalogItemsConnectionVariancePopulationTypeAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CatalogItemsConnectionVariancePopulationTypeDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CatalogItemsConnectionVariancePopulationUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CatalogItemsConnectionVariancePopulationUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CatalogItemsConnectionVarianceSampleCategoryIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsConnectionVarianceSampleCategoryIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsConnectionVarianceSampleCodeAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CODE_ASC',
  CatalogItemsConnectionVarianceSampleCodeDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CODE_DESC',
  CatalogItemsConnectionVarianceSampleCompanyIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsConnectionVarianceSampleCompanyIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsConnectionVarianceSampleCostAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_COST_ASC',
  CatalogItemsConnectionVarianceSampleCostDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_COST_DESC',
  CatalogItemsConnectionVarianceSampleCreatedAtAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CatalogItemsConnectionVarianceSampleCreatedAtDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CatalogItemsConnectionVarianceSampleCreatedByAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CatalogItemsConnectionVarianceSampleCreatedByDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CatalogItemsConnectionVarianceSampleDatasheetAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_DATASHEET_ASC',
  CatalogItemsConnectionVarianceSampleDatasheetDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_DATASHEET_DESC',
  CatalogItemsConnectionVarianceSampleDescriptionAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsConnectionVarianceSampleDescriptionDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsConnectionVarianceSampleIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CatalogItemsConnectionVarianceSampleIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CatalogItemsConnectionVarianceSampleImageIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsConnectionVarianceSampleImageIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsConnectionVarianceSampleIsArchivedAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsConnectionVarianceSampleIsArchivedDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsConnectionVarianceSampleIsCustomAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsConnectionVarianceSampleIsCustomDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsConnectionVarianceSampleManufacturerAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsConnectionVarianceSampleManufacturerDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsConnectionVarianceSampleMasterItemIdAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsConnectionVarianceSampleMasterItemIdDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsConnectionVarianceSampleNameAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  CatalogItemsConnectionVarianceSampleNameDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  CatalogItemsConnectionVarianceSamplePriceAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_PRICE_ASC',
  CatalogItemsConnectionVarianceSamplePriceDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_PRICE_DESC',
  CatalogItemsConnectionVarianceSampleSkuAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_SKU_ASC',
  CatalogItemsConnectionVarianceSampleSkuDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_SKU_DESC',
  CatalogItemsConnectionVarianceSampleSpecAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_SPEC_ASC',
  CatalogItemsConnectionVarianceSampleSpecDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_SPEC_DESC',
  CatalogItemsConnectionVarianceSampleTypeAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CatalogItemsConnectionVarianceSampleTypeDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CatalogItemsConnectionVarianceSampleUpdatedAtAsc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsConnectionVarianceSampleUpdatedAtDesc = 'CATALOG_ITEMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionArrayAggEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionArrayAggEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionArrayAggEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionArrayAggEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionArrayAggEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionArrayAggEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionArrayAggEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  CompanyNotificationSettingsConnectionArrayAggEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  CompanyNotificationSettingsConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionArrayAggInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionArrayAggInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionArrayAggOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionArrayAggPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionArrayAggPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionArrayAggSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionArrayAggSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionArrayAggSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionArrayAggSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionAverageEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionAverageEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionAverageEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionAverageEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionAverageEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionAverageEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionAverageEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_ENABLED_ASC',
  CompanyNotificationSettingsConnectionAverageEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_ENABLED_DESC',
  CompanyNotificationSettingsConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionAverageInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionAverageInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionAverageOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionAverageOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionAveragePushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionAveragePushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionAverageSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionAverageSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionAverageSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionAverageSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionDistinctCountInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionDistinctCountInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionDistinctCountPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionDistinctCountSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionDistinctCountSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionDistinctCountSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionMaxEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMaxEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMaxEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionMaxEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionMaxEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionMaxEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionMaxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMaxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionMaxInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMaxInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionMaxOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionMaxOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionMaxPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMaxPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMaxSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMaxSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMaxSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionMaxSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionMinEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMinEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMinEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionMinEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionMinEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionMinEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionMinEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMinEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionMinInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMinInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMinOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionMinOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionMinOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionMinOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionMinOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionMinOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionMinOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionMinOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionMinOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionMinOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionMinOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionMinOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionMinOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionMinOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionMinOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionMinOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionMinPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMinPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMinSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionMinSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionMinSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionMinSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionStddevSampleInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevSampleInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionStddevSamplePushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevSamplePushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevSampleSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionStddevSampleSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionStddevSampleSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionSumEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionSumEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionSumEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionSumEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionSumEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionSumEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionSumEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_ENABLED_ASC',
  CompanyNotificationSettingsConnectionSumEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_ENABLED_DESC',
  CompanyNotificationSettingsConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionSumInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionSumInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionSumOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionSumOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionSumOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionSumOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionSumOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionSumOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionSumOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionSumOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionSumOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionSumOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionSumOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionSumOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionSumOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionSumOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionSumOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionSumOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionSumPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionSumPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionSumSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionSumSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionSumSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionSumSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationPushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationPushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailNotificationSubjectAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_NOTIFICATION_SUBJECT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailNotificationSubjectDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_NOTIFICATION_SUBJECT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEmailNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EMAIL_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleInboxEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_INBOX_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleInboxEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_INBOX_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_EMAIL_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_EMAIL_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_SMS_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_SMS_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_TIME_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_TIME_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_UNIT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_1UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_1_UNIT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2EmailAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_EMAIL_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2EmailDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_EMAIL_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2SmsAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_SMS_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2SmsDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_SMS_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2TimeAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_TIME_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2TimeDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_TIME_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2UnitAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_UNIT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleOffset_2UnitDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_OFFSET_2_UNIT_DESC',
  CompanyNotificationSettingsConnectionVarianceSamplePushEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_PUSH_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVarianceSamplePushEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_PUSH_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleSmsEnabledAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_SMS_ENABLED_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleSmsEnabledDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_SMS_ENABLED_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleSmsNotificationTextAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_SMS_NOTIFICATION_TEXT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleSmsNotificationTextDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_SMS_NOTIFICATION_TEXT_DESC',
  CompanyNotificationSettingsConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyPhonesConnectionArrayAggAliasAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_ALIAS_ASC',
  CompanyPhonesConnectionArrayAggAliasDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_ALIAS_DESC',
  CompanyPhonesConnectionArrayAggCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyPhonesConnectionArrayAggCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyPhonesConnectionArrayAggCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyPhonesConnectionArrayAggCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyPhonesConnectionArrayAggCreatedByAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CompanyPhonesConnectionArrayAggCreatedByDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CompanyPhonesConnectionArrayAggForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionArrayAggForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionArrayAggIdAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_ID_ASC',
  CompanyPhonesConnectionArrayAggIdDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_ID_DESC',
  CompanyPhonesConnectionArrayAggIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionArrayAggIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionArrayAggPhoneAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_PHONE_ASC',
  CompanyPhonesConnectionArrayAggPhoneDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_PHONE_DESC',
  CompanyPhonesConnectionArrayAggTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  CompanyPhonesConnectionArrayAggTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  CompanyPhonesConnectionArrayAggUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyPhonesConnectionArrayAggUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyPhonesConnectionAverageAliasAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_ALIAS_ASC',
  CompanyPhonesConnectionAverageAliasDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_ALIAS_DESC',
  CompanyPhonesConnectionAverageCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyPhonesConnectionAverageCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyPhonesConnectionAverageCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyPhonesConnectionAverageCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyPhonesConnectionAverageCreatedByAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CompanyPhonesConnectionAverageCreatedByDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CompanyPhonesConnectionAverageForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionAverageForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionAverageIdAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_ID_ASC',
  CompanyPhonesConnectionAverageIdDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_ID_DESC',
  CompanyPhonesConnectionAverageIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionAverageIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionAveragePhoneAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_PHONE_ASC',
  CompanyPhonesConnectionAveragePhoneDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_PHONE_DESC',
  CompanyPhonesConnectionAverageTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  CompanyPhonesConnectionAverageTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  CompanyPhonesConnectionAverageUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyPhonesConnectionAverageUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyPhonesConnectionCountAsc = 'COMPANY_PHONES_CONNECTION_COUNT_ASC',
  CompanyPhonesConnectionCountDesc = 'COMPANY_PHONES_CONNECTION_COUNT_DESC',
  CompanyPhonesConnectionDistinctCountAliasAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_ALIAS_ASC',
  CompanyPhonesConnectionDistinctCountAliasDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_ALIAS_DESC',
  CompanyPhonesConnectionDistinctCountCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyPhonesConnectionDistinctCountCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyPhonesConnectionDistinctCountCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyPhonesConnectionDistinctCountCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyPhonesConnectionDistinctCountCreatedByAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CompanyPhonesConnectionDistinctCountCreatedByDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CompanyPhonesConnectionDistinctCountForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionDistinctCountForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionDistinctCountIdAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CompanyPhonesConnectionDistinctCountIdDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CompanyPhonesConnectionDistinctCountIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionDistinctCountIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionDistinctCountPhoneAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  CompanyPhonesConnectionDistinctCountPhoneDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  CompanyPhonesConnectionDistinctCountTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  CompanyPhonesConnectionDistinctCountTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  CompanyPhonesConnectionDistinctCountUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyPhonesConnectionDistinctCountUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyPhonesConnectionMaxAliasAsc = 'COMPANY_PHONES_CONNECTION_MAX_ALIAS_ASC',
  CompanyPhonesConnectionMaxAliasDesc = 'COMPANY_PHONES_CONNECTION_MAX_ALIAS_DESC',
  CompanyPhonesConnectionMaxCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyPhonesConnectionMaxCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyPhonesConnectionMaxCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyPhonesConnectionMaxCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyPhonesConnectionMaxCreatedByAsc = 'COMPANY_PHONES_CONNECTION_MAX_CREATED_BY_ASC',
  CompanyPhonesConnectionMaxCreatedByDesc = 'COMPANY_PHONES_CONNECTION_MAX_CREATED_BY_DESC',
  CompanyPhonesConnectionMaxForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_MAX_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionMaxForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_MAX_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionMaxIdAsc = 'COMPANY_PHONES_CONNECTION_MAX_ID_ASC',
  CompanyPhonesConnectionMaxIdDesc = 'COMPANY_PHONES_CONNECTION_MAX_ID_DESC',
  CompanyPhonesConnectionMaxIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_MAX_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionMaxIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_MAX_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionMaxPhoneAsc = 'COMPANY_PHONES_CONNECTION_MAX_PHONE_ASC',
  CompanyPhonesConnectionMaxPhoneDesc = 'COMPANY_PHONES_CONNECTION_MAX_PHONE_DESC',
  CompanyPhonesConnectionMaxTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_MAX_TWILIO_SID_ASC',
  CompanyPhonesConnectionMaxTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_MAX_TWILIO_SID_DESC',
  CompanyPhonesConnectionMaxUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyPhonesConnectionMaxUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyPhonesConnectionMinAliasAsc = 'COMPANY_PHONES_CONNECTION_MIN_ALIAS_ASC',
  CompanyPhonesConnectionMinAliasDesc = 'COMPANY_PHONES_CONNECTION_MIN_ALIAS_DESC',
  CompanyPhonesConnectionMinCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyPhonesConnectionMinCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyPhonesConnectionMinCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyPhonesConnectionMinCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyPhonesConnectionMinCreatedByAsc = 'COMPANY_PHONES_CONNECTION_MIN_CREATED_BY_ASC',
  CompanyPhonesConnectionMinCreatedByDesc = 'COMPANY_PHONES_CONNECTION_MIN_CREATED_BY_DESC',
  CompanyPhonesConnectionMinForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_MIN_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionMinForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_MIN_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionMinIdAsc = 'COMPANY_PHONES_CONNECTION_MIN_ID_ASC',
  CompanyPhonesConnectionMinIdDesc = 'COMPANY_PHONES_CONNECTION_MIN_ID_DESC',
  CompanyPhonesConnectionMinIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_MIN_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionMinIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_MIN_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionMinPhoneAsc = 'COMPANY_PHONES_CONNECTION_MIN_PHONE_ASC',
  CompanyPhonesConnectionMinPhoneDesc = 'COMPANY_PHONES_CONNECTION_MIN_PHONE_DESC',
  CompanyPhonesConnectionMinTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_MIN_TWILIO_SID_ASC',
  CompanyPhonesConnectionMinTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_MIN_TWILIO_SID_DESC',
  CompanyPhonesConnectionMinUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyPhonesConnectionMinUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyPhonesConnectionStddevPopulationAliasAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_ALIAS_ASC',
  CompanyPhonesConnectionStddevPopulationAliasDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_ALIAS_DESC',
  CompanyPhonesConnectionStddevPopulationCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyPhonesConnectionStddevPopulationCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyPhonesConnectionStddevPopulationCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyPhonesConnectionStddevPopulationCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyPhonesConnectionStddevPopulationCreatedByAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CompanyPhonesConnectionStddevPopulationCreatedByDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CompanyPhonesConnectionStddevPopulationForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionStddevPopulationForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionStddevPopulationIdAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CompanyPhonesConnectionStddevPopulationIdDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CompanyPhonesConnectionStddevPopulationIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionStddevPopulationIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionStddevPopulationPhoneAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  CompanyPhonesConnectionStddevPopulationPhoneDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  CompanyPhonesConnectionStddevPopulationTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  CompanyPhonesConnectionStddevPopulationTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  CompanyPhonesConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyPhonesConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyPhonesConnectionStddevSampleAliasAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_ALIAS_ASC',
  CompanyPhonesConnectionStddevSampleAliasDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_ALIAS_DESC',
  CompanyPhonesConnectionStddevSampleCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyPhonesConnectionStddevSampleCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyPhonesConnectionStddevSampleCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyPhonesConnectionStddevSampleCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyPhonesConnectionStddevSampleCreatedByAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CompanyPhonesConnectionStddevSampleCreatedByDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CompanyPhonesConnectionStddevSampleForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionStddevSampleForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionStddevSampleIdAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CompanyPhonesConnectionStddevSampleIdDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CompanyPhonesConnectionStddevSampleIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionStddevSampleIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionStddevSamplePhoneAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  CompanyPhonesConnectionStddevSamplePhoneDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  CompanyPhonesConnectionStddevSampleTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  CompanyPhonesConnectionStddevSampleTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  CompanyPhonesConnectionStddevSampleUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyPhonesConnectionStddevSampleUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyPhonesConnectionSumAliasAsc = 'COMPANY_PHONES_CONNECTION_SUM_ALIAS_ASC',
  CompanyPhonesConnectionSumAliasDesc = 'COMPANY_PHONES_CONNECTION_SUM_ALIAS_DESC',
  CompanyPhonesConnectionSumCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyPhonesConnectionSumCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyPhonesConnectionSumCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyPhonesConnectionSumCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyPhonesConnectionSumCreatedByAsc = 'COMPANY_PHONES_CONNECTION_SUM_CREATED_BY_ASC',
  CompanyPhonesConnectionSumCreatedByDesc = 'COMPANY_PHONES_CONNECTION_SUM_CREATED_BY_DESC',
  CompanyPhonesConnectionSumForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_SUM_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionSumForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_SUM_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionSumIdAsc = 'COMPANY_PHONES_CONNECTION_SUM_ID_ASC',
  CompanyPhonesConnectionSumIdDesc = 'COMPANY_PHONES_CONNECTION_SUM_ID_DESC',
  CompanyPhonesConnectionSumIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_SUM_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionSumIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_SUM_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionSumPhoneAsc = 'COMPANY_PHONES_CONNECTION_SUM_PHONE_ASC',
  CompanyPhonesConnectionSumPhoneDesc = 'COMPANY_PHONES_CONNECTION_SUM_PHONE_DESC',
  CompanyPhonesConnectionSumTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_SUM_TWILIO_SID_ASC',
  CompanyPhonesConnectionSumTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_SUM_TWILIO_SID_DESC',
  CompanyPhonesConnectionSumUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyPhonesConnectionSumUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyPhonesConnectionVariancePopulationAliasAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_ALIAS_ASC',
  CompanyPhonesConnectionVariancePopulationAliasDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_ALIAS_DESC',
  CompanyPhonesConnectionVariancePopulationCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyPhonesConnectionVariancePopulationCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyPhonesConnectionVariancePopulationCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyPhonesConnectionVariancePopulationCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyPhonesConnectionVariancePopulationCreatedByAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CompanyPhonesConnectionVariancePopulationCreatedByDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CompanyPhonesConnectionVariancePopulationForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionVariancePopulationForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionVariancePopulationIdAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CompanyPhonesConnectionVariancePopulationIdDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CompanyPhonesConnectionVariancePopulationIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionVariancePopulationIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionVariancePopulationPhoneAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  CompanyPhonesConnectionVariancePopulationPhoneDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  CompanyPhonesConnectionVariancePopulationTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  CompanyPhonesConnectionVariancePopulationTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  CompanyPhonesConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyPhonesConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyPhonesConnectionVarianceSampleAliasAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_ALIAS_ASC',
  CompanyPhonesConnectionVarianceSampleAliasDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_ALIAS_DESC',
  CompanyPhonesConnectionVarianceSampleCompanyIdAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyPhonesConnectionVarianceSampleCompanyIdDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyPhonesConnectionVarianceSampleCreatedAtAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyPhonesConnectionVarianceSampleCreatedAtDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyPhonesConnectionVarianceSampleCreatedByAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CompanyPhonesConnectionVarianceSampleCreatedByDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CompanyPhonesConnectionVarianceSampleForwardingPhoneAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_FORWARDING_PHONE_ASC',
  CompanyPhonesConnectionVarianceSampleForwardingPhoneDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_FORWARDING_PHONE_DESC',
  CompanyPhonesConnectionVarianceSampleIdAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CompanyPhonesConnectionVarianceSampleIdDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CompanyPhonesConnectionVarianceSampleIsPermanentlyDeletedAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesConnectionVarianceSampleIsPermanentlyDeletedDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesConnectionVarianceSamplePhoneAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  CompanyPhonesConnectionVarianceSamplePhoneDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  CompanyPhonesConnectionVarianceSampleTwilioSidAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  CompanyPhonesConnectionVarianceSampleTwilioSidDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  CompanyPhonesConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyPhonesConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_PHONES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersConnectionArrayAggCompanyIdAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyUsersConnectionArrayAggCompanyIdDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyUsersConnectionArrayAggCreatedAtAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyUsersConnectionArrayAggCreatedAtDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyUsersConnectionArrayAggIdAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_ID_ASC',
  CompanyUsersConnectionArrayAggIdDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_ID_DESC',
  CompanyUsersConnectionArrayAggInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionArrayAggInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionArrayAggIsActiveAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  CompanyUsersConnectionArrayAggIsActiveDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  CompanyUsersConnectionArrayAggIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionArrayAggIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionArrayAggIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_CONFIRMED_ASC',
  CompanyUsersConnectionArrayAggIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_IS_CONFIRMED_DESC',
  CompanyUsersConnectionArrayAggUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyUsersConnectionArrayAggUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyUsersConnectionArrayAggUserIdAsc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  CompanyUsersConnectionArrayAggUserIdDesc = 'COMPANY_USERS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  CompanyUsersConnectionAverageCompanyIdAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyUsersConnectionAverageCompanyIdDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyUsersConnectionAverageCreatedAtAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyUsersConnectionAverageCreatedAtDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyUsersConnectionAverageIdAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_ID_ASC',
  CompanyUsersConnectionAverageIdDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_ID_DESC',
  CompanyUsersConnectionAverageInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionAverageInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionAverageIsActiveAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  CompanyUsersConnectionAverageIsActiveDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  CompanyUsersConnectionAverageIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionAverageIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionAverageIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_CONFIRMED_ASC',
  CompanyUsersConnectionAverageIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_IS_CONFIRMED_DESC',
  CompanyUsersConnectionAverageUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyUsersConnectionAverageUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyUsersConnectionAverageUserIdAsc = 'COMPANY_USERS_CONNECTION_AVERAGE_USER_ID_ASC',
  CompanyUsersConnectionAverageUserIdDesc = 'COMPANY_USERS_CONNECTION_AVERAGE_USER_ID_DESC',
  CompanyUsersConnectionCountAsc = 'COMPANY_USERS_CONNECTION_COUNT_ASC',
  CompanyUsersConnectionCountDesc = 'COMPANY_USERS_CONNECTION_COUNT_DESC',
  CompanyUsersConnectionDistinctCountCompanyIdAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyUsersConnectionDistinctCountCompanyIdDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyUsersConnectionDistinctCountCreatedAtAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyUsersConnectionDistinctCountCreatedAtDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyUsersConnectionDistinctCountIdAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CompanyUsersConnectionDistinctCountIdDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CompanyUsersConnectionDistinctCountInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionDistinctCountInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionDistinctCountIsActiveAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  CompanyUsersConnectionDistinctCountIsActiveDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  CompanyUsersConnectionDistinctCountIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionDistinctCountIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionDistinctCountIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_CONFIRMED_ASC',
  CompanyUsersConnectionDistinctCountIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_IS_CONFIRMED_DESC',
  CompanyUsersConnectionDistinctCountUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyUsersConnectionDistinctCountUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyUsersConnectionDistinctCountUserIdAsc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  CompanyUsersConnectionDistinctCountUserIdDesc = 'COMPANY_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  CompanyUsersConnectionMaxCompanyIdAsc = 'COMPANY_USERS_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyUsersConnectionMaxCompanyIdDesc = 'COMPANY_USERS_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyUsersConnectionMaxCreatedAtAsc = 'COMPANY_USERS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyUsersConnectionMaxCreatedAtDesc = 'COMPANY_USERS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyUsersConnectionMaxIdAsc = 'COMPANY_USERS_CONNECTION_MAX_ID_ASC',
  CompanyUsersConnectionMaxIdDesc = 'COMPANY_USERS_CONNECTION_MAX_ID_DESC',
  CompanyUsersConnectionMaxInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_MAX_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionMaxInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_MAX_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionMaxIsActiveAsc = 'COMPANY_USERS_CONNECTION_MAX_IS_ACTIVE_ASC',
  CompanyUsersConnectionMaxIsActiveDesc = 'COMPANY_USERS_CONNECTION_MAX_IS_ACTIVE_DESC',
  CompanyUsersConnectionMaxIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_MAX_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionMaxIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_MAX_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionMaxIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_MAX_IS_CONFIRMED_ASC',
  CompanyUsersConnectionMaxIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_MAX_IS_CONFIRMED_DESC',
  CompanyUsersConnectionMaxUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyUsersConnectionMaxUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyUsersConnectionMaxUserIdAsc = 'COMPANY_USERS_CONNECTION_MAX_USER_ID_ASC',
  CompanyUsersConnectionMaxUserIdDesc = 'COMPANY_USERS_CONNECTION_MAX_USER_ID_DESC',
  CompanyUsersConnectionMinCompanyIdAsc = 'COMPANY_USERS_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyUsersConnectionMinCompanyIdDesc = 'COMPANY_USERS_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyUsersConnectionMinCreatedAtAsc = 'COMPANY_USERS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyUsersConnectionMinCreatedAtDesc = 'COMPANY_USERS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyUsersConnectionMinIdAsc = 'COMPANY_USERS_CONNECTION_MIN_ID_ASC',
  CompanyUsersConnectionMinIdDesc = 'COMPANY_USERS_CONNECTION_MIN_ID_DESC',
  CompanyUsersConnectionMinInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_MIN_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionMinInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_MIN_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionMinIsActiveAsc = 'COMPANY_USERS_CONNECTION_MIN_IS_ACTIVE_ASC',
  CompanyUsersConnectionMinIsActiveDesc = 'COMPANY_USERS_CONNECTION_MIN_IS_ACTIVE_DESC',
  CompanyUsersConnectionMinIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_MIN_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionMinIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_MIN_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionMinIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_MIN_IS_CONFIRMED_ASC',
  CompanyUsersConnectionMinIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_MIN_IS_CONFIRMED_DESC',
  CompanyUsersConnectionMinUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyUsersConnectionMinUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyUsersConnectionMinUserIdAsc = 'COMPANY_USERS_CONNECTION_MIN_USER_ID_ASC',
  CompanyUsersConnectionMinUserIdDesc = 'COMPANY_USERS_CONNECTION_MIN_USER_ID_DESC',
  CompanyUsersConnectionStddevPopulationCompanyIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyUsersConnectionStddevPopulationCompanyIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyUsersConnectionStddevPopulationCreatedAtAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyUsersConnectionStddevPopulationCreatedAtDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyUsersConnectionStddevPopulationIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CompanyUsersConnectionStddevPopulationIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CompanyUsersConnectionStddevPopulationInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionStddevPopulationInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionStddevPopulationIsActiveAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  CompanyUsersConnectionStddevPopulationIsActiveDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  CompanyUsersConnectionStddevPopulationIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionStddevPopulationIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionStddevPopulationIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_CONFIRMED_ASC',
  CompanyUsersConnectionStddevPopulationIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_IS_CONFIRMED_DESC',
  CompanyUsersConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyUsersConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyUsersConnectionStddevPopulationUserIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  CompanyUsersConnectionStddevPopulationUserIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  CompanyUsersConnectionStddevSampleCompanyIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersConnectionStddevSampleCompanyIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersConnectionStddevSampleCreatedAtAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyUsersConnectionStddevSampleCreatedAtDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyUsersConnectionStddevSampleIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CompanyUsersConnectionStddevSampleIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CompanyUsersConnectionStddevSampleInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionStddevSampleInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionStddevSampleIsActiveAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  CompanyUsersConnectionStddevSampleIsActiveDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  CompanyUsersConnectionStddevSampleIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionStddevSampleIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionStddevSampleIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_CONFIRMED_ASC',
  CompanyUsersConnectionStddevSampleIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_IS_CONFIRMED_DESC',
  CompanyUsersConnectionStddevSampleUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersConnectionStddevSampleUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersConnectionStddevSampleUserIdAsc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyUsersConnectionStddevSampleUserIdDesc = 'COMPANY_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyUsersConnectionSumCompanyIdAsc = 'COMPANY_USERS_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyUsersConnectionSumCompanyIdDesc = 'COMPANY_USERS_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyUsersConnectionSumCreatedAtAsc = 'COMPANY_USERS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyUsersConnectionSumCreatedAtDesc = 'COMPANY_USERS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyUsersConnectionSumIdAsc = 'COMPANY_USERS_CONNECTION_SUM_ID_ASC',
  CompanyUsersConnectionSumIdDesc = 'COMPANY_USERS_CONNECTION_SUM_ID_DESC',
  CompanyUsersConnectionSumInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_SUM_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionSumInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_SUM_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionSumIsActiveAsc = 'COMPANY_USERS_CONNECTION_SUM_IS_ACTIVE_ASC',
  CompanyUsersConnectionSumIsActiveDesc = 'COMPANY_USERS_CONNECTION_SUM_IS_ACTIVE_DESC',
  CompanyUsersConnectionSumIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_SUM_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionSumIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_SUM_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionSumIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_SUM_IS_CONFIRMED_ASC',
  CompanyUsersConnectionSumIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_SUM_IS_CONFIRMED_DESC',
  CompanyUsersConnectionSumUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyUsersConnectionSumUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyUsersConnectionSumUserIdAsc = 'COMPANY_USERS_CONNECTION_SUM_USER_ID_ASC',
  CompanyUsersConnectionSumUserIdDesc = 'COMPANY_USERS_CONNECTION_SUM_USER_ID_DESC',
  CompanyUsersConnectionVariancePopulationCompanyIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyUsersConnectionVariancePopulationCompanyIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyUsersConnectionVariancePopulationCreatedAtAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyUsersConnectionVariancePopulationCreatedAtDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyUsersConnectionVariancePopulationIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CompanyUsersConnectionVariancePopulationIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CompanyUsersConnectionVariancePopulationInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionVariancePopulationInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionVariancePopulationIsActiveAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  CompanyUsersConnectionVariancePopulationIsActiveDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  CompanyUsersConnectionVariancePopulationIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionVariancePopulationIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionVariancePopulationIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_CONFIRMED_ASC',
  CompanyUsersConnectionVariancePopulationIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_IS_CONFIRMED_DESC',
  CompanyUsersConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyUsersConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyUsersConnectionVariancePopulationUserIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyUsersConnectionVariancePopulationUserIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyUsersConnectionVarianceSampleCompanyIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersConnectionVarianceSampleCompanyIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersConnectionVarianceSampleCreatedAtAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyUsersConnectionVarianceSampleCreatedAtDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyUsersConnectionVarianceSampleIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CompanyUsersConnectionVarianceSampleIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CompanyUsersConnectionVarianceSampleInvitationTokenAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_INVITATION_TOKEN_ASC',
  CompanyUsersConnectionVarianceSampleInvitationTokenDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_INVITATION_TOKEN_DESC',
  CompanyUsersConnectionVarianceSampleIsActiveAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  CompanyUsersConnectionVarianceSampleIsActiveDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  CompanyUsersConnectionVarianceSampleIsCollaboratorAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_COLLABORATOR_ASC',
  CompanyUsersConnectionVarianceSampleIsCollaboratorDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_COLLABORATOR_DESC',
  CompanyUsersConnectionVarianceSampleIsConfirmedAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_CONFIRMED_ASC',
  CompanyUsersConnectionVarianceSampleIsConfirmedDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_IS_CONFIRMED_DESC',
  CompanyUsersConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersConnectionVarianceSampleUserIdAsc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyUsersConnectionVarianceSampleUserIdDesc = 'COMPANY_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomerPortalEnabledAsc = 'CUSTOMER_PORTAL_ENABLED_ASC',
  CustomerPortalEnabledDesc = 'CUSTOMER_PORTAL_ENABLED_DESC',
  DashboardsConnectionArrayAggCompanyIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  DashboardsConnectionArrayAggCompanyIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  DashboardsConnectionArrayAggCreatedAtAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DashboardsConnectionArrayAggCreatedAtDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DashboardsConnectionArrayAggCreatedByAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  DashboardsConnectionArrayAggCreatedByDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  DashboardsConnectionArrayAggCustomizationAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CUSTOMIZATION_ASC',
  DashboardsConnectionArrayAggCustomizationDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CUSTOMIZATION_DESC',
  DashboardsConnectionArrayAggDescriptionAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  DashboardsConnectionArrayAggDescriptionDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  DashboardsConnectionArrayAggEmojiAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  DashboardsConnectionArrayAggEmojiDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  DashboardsConnectionArrayAggFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionArrayAggFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionArrayAggIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_ID_ASC',
  DashboardsConnectionArrayAggIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_ID_DESC',
  DashboardsConnectionArrayAggLayoutAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_LAYOUT_ASC',
  DashboardsConnectionArrayAggLayoutDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_LAYOUT_DESC',
  DashboardsConnectionArrayAggNameAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_NAME_ASC',
  DashboardsConnectionArrayAggNameDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_NAME_DESC',
  DashboardsConnectionArrayAggPrivilegeAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  DashboardsConnectionArrayAggPrivilegeDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  DashboardsConnectionArrayAggScopeAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  DashboardsConnectionArrayAggScopeDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  DashboardsConnectionArrayAggUpdatedAtAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  DashboardsConnectionArrayAggUpdatedAtDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  DashboardsConnectionAverageCompanyIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  DashboardsConnectionAverageCompanyIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  DashboardsConnectionAverageCreatedAtAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DashboardsConnectionAverageCreatedAtDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DashboardsConnectionAverageCreatedByAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  DashboardsConnectionAverageCreatedByDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  DashboardsConnectionAverageCustomizationAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CUSTOMIZATION_ASC',
  DashboardsConnectionAverageCustomizationDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CUSTOMIZATION_DESC',
  DashboardsConnectionAverageDescriptionAsc = 'DASHBOARDS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  DashboardsConnectionAverageDescriptionDesc = 'DASHBOARDS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  DashboardsConnectionAverageEmojiAsc = 'DASHBOARDS_CONNECTION_AVERAGE_EMOJI_ASC',
  DashboardsConnectionAverageEmojiDesc = 'DASHBOARDS_CONNECTION_AVERAGE_EMOJI_DESC',
  DashboardsConnectionAverageFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionAverageFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionAverageIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_ID_ASC',
  DashboardsConnectionAverageIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_ID_DESC',
  DashboardsConnectionAverageLayoutAsc = 'DASHBOARDS_CONNECTION_AVERAGE_LAYOUT_ASC',
  DashboardsConnectionAverageLayoutDesc = 'DASHBOARDS_CONNECTION_AVERAGE_LAYOUT_DESC',
  DashboardsConnectionAverageNameAsc = 'DASHBOARDS_CONNECTION_AVERAGE_NAME_ASC',
  DashboardsConnectionAverageNameDesc = 'DASHBOARDS_CONNECTION_AVERAGE_NAME_DESC',
  DashboardsConnectionAveragePrivilegeAsc = 'DASHBOARDS_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  DashboardsConnectionAveragePrivilegeDesc = 'DASHBOARDS_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  DashboardsConnectionAverageScopeAsc = 'DASHBOARDS_CONNECTION_AVERAGE_SCOPE_ASC',
  DashboardsConnectionAverageScopeDesc = 'DASHBOARDS_CONNECTION_AVERAGE_SCOPE_DESC',
  DashboardsConnectionAverageUpdatedAtAsc = 'DASHBOARDS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  DashboardsConnectionAverageUpdatedAtDesc = 'DASHBOARDS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  DashboardsConnectionCountAsc = 'DASHBOARDS_CONNECTION_COUNT_ASC',
  DashboardsConnectionCountDesc = 'DASHBOARDS_CONNECTION_COUNT_DESC',
  DashboardsConnectionDistinctCountCompanyIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  DashboardsConnectionDistinctCountCompanyIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  DashboardsConnectionDistinctCountCreatedAtAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DashboardsConnectionDistinctCountCreatedAtDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DashboardsConnectionDistinctCountCreatedByAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  DashboardsConnectionDistinctCountCreatedByDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  DashboardsConnectionDistinctCountCustomizationAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_ASC',
  DashboardsConnectionDistinctCountCustomizationDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_DESC',
  DashboardsConnectionDistinctCountDescriptionAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  DashboardsConnectionDistinctCountDescriptionDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  DashboardsConnectionDistinctCountEmojiAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  DashboardsConnectionDistinctCountEmojiDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  DashboardsConnectionDistinctCountFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionDistinctCountFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionDistinctCountIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  DashboardsConnectionDistinctCountIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  DashboardsConnectionDistinctCountLayoutAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_LAYOUT_ASC',
  DashboardsConnectionDistinctCountLayoutDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_LAYOUT_DESC',
  DashboardsConnectionDistinctCountNameAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  DashboardsConnectionDistinctCountNameDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  DashboardsConnectionDistinctCountPrivilegeAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  DashboardsConnectionDistinctCountPrivilegeDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  DashboardsConnectionDistinctCountScopeAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  DashboardsConnectionDistinctCountScopeDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  DashboardsConnectionDistinctCountUpdatedAtAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  DashboardsConnectionDistinctCountUpdatedAtDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  DashboardsConnectionMaxCompanyIdAsc = 'DASHBOARDS_CONNECTION_MAX_COMPANY_ID_ASC',
  DashboardsConnectionMaxCompanyIdDesc = 'DASHBOARDS_CONNECTION_MAX_COMPANY_ID_DESC',
  DashboardsConnectionMaxCreatedAtAsc = 'DASHBOARDS_CONNECTION_MAX_CREATED_AT_ASC',
  DashboardsConnectionMaxCreatedAtDesc = 'DASHBOARDS_CONNECTION_MAX_CREATED_AT_DESC',
  DashboardsConnectionMaxCreatedByAsc = 'DASHBOARDS_CONNECTION_MAX_CREATED_BY_ASC',
  DashboardsConnectionMaxCreatedByDesc = 'DASHBOARDS_CONNECTION_MAX_CREATED_BY_DESC',
  DashboardsConnectionMaxCustomizationAsc = 'DASHBOARDS_CONNECTION_MAX_CUSTOMIZATION_ASC',
  DashboardsConnectionMaxCustomizationDesc = 'DASHBOARDS_CONNECTION_MAX_CUSTOMIZATION_DESC',
  DashboardsConnectionMaxDescriptionAsc = 'DASHBOARDS_CONNECTION_MAX_DESCRIPTION_ASC',
  DashboardsConnectionMaxDescriptionDesc = 'DASHBOARDS_CONNECTION_MAX_DESCRIPTION_DESC',
  DashboardsConnectionMaxEmojiAsc = 'DASHBOARDS_CONNECTION_MAX_EMOJI_ASC',
  DashboardsConnectionMaxEmojiDesc = 'DASHBOARDS_CONNECTION_MAX_EMOJI_DESC',
  DashboardsConnectionMaxFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionMaxFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionMaxIdAsc = 'DASHBOARDS_CONNECTION_MAX_ID_ASC',
  DashboardsConnectionMaxIdDesc = 'DASHBOARDS_CONNECTION_MAX_ID_DESC',
  DashboardsConnectionMaxLayoutAsc = 'DASHBOARDS_CONNECTION_MAX_LAYOUT_ASC',
  DashboardsConnectionMaxLayoutDesc = 'DASHBOARDS_CONNECTION_MAX_LAYOUT_DESC',
  DashboardsConnectionMaxNameAsc = 'DASHBOARDS_CONNECTION_MAX_NAME_ASC',
  DashboardsConnectionMaxNameDesc = 'DASHBOARDS_CONNECTION_MAX_NAME_DESC',
  DashboardsConnectionMaxPrivilegeAsc = 'DASHBOARDS_CONNECTION_MAX_PRIVILEGE_ASC',
  DashboardsConnectionMaxPrivilegeDesc = 'DASHBOARDS_CONNECTION_MAX_PRIVILEGE_DESC',
  DashboardsConnectionMaxScopeAsc = 'DASHBOARDS_CONNECTION_MAX_SCOPE_ASC',
  DashboardsConnectionMaxScopeDesc = 'DASHBOARDS_CONNECTION_MAX_SCOPE_DESC',
  DashboardsConnectionMaxUpdatedAtAsc = 'DASHBOARDS_CONNECTION_MAX_UPDATED_AT_ASC',
  DashboardsConnectionMaxUpdatedAtDesc = 'DASHBOARDS_CONNECTION_MAX_UPDATED_AT_DESC',
  DashboardsConnectionMinCompanyIdAsc = 'DASHBOARDS_CONNECTION_MIN_COMPANY_ID_ASC',
  DashboardsConnectionMinCompanyIdDesc = 'DASHBOARDS_CONNECTION_MIN_COMPANY_ID_DESC',
  DashboardsConnectionMinCreatedAtAsc = 'DASHBOARDS_CONNECTION_MIN_CREATED_AT_ASC',
  DashboardsConnectionMinCreatedAtDesc = 'DASHBOARDS_CONNECTION_MIN_CREATED_AT_DESC',
  DashboardsConnectionMinCreatedByAsc = 'DASHBOARDS_CONNECTION_MIN_CREATED_BY_ASC',
  DashboardsConnectionMinCreatedByDesc = 'DASHBOARDS_CONNECTION_MIN_CREATED_BY_DESC',
  DashboardsConnectionMinCustomizationAsc = 'DASHBOARDS_CONNECTION_MIN_CUSTOMIZATION_ASC',
  DashboardsConnectionMinCustomizationDesc = 'DASHBOARDS_CONNECTION_MIN_CUSTOMIZATION_DESC',
  DashboardsConnectionMinDescriptionAsc = 'DASHBOARDS_CONNECTION_MIN_DESCRIPTION_ASC',
  DashboardsConnectionMinDescriptionDesc = 'DASHBOARDS_CONNECTION_MIN_DESCRIPTION_DESC',
  DashboardsConnectionMinEmojiAsc = 'DASHBOARDS_CONNECTION_MIN_EMOJI_ASC',
  DashboardsConnectionMinEmojiDesc = 'DASHBOARDS_CONNECTION_MIN_EMOJI_DESC',
  DashboardsConnectionMinFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionMinFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionMinIdAsc = 'DASHBOARDS_CONNECTION_MIN_ID_ASC',
  DashboardsConnectionMinIdDesc = 'DASHBOARDS_CONNECTION_MIN_ID_DESC',
  DashboardsConnectionMinLayoutAsc = 'DASHBOARDS_CONNECTION_MIN_LAYOUT_ASC',
  DashboardsConnectionMinLayoutDesc = 'DASHBOARDS_CONNECTION_MIN_LAYOUT_DESC',
  DashboardsConnectionMinNameAsc = 'DASHBOARDS_CONNECTION_MIN_NAME_ASC',
  DashboardsConnectionMinNameDesc = 'DASHBOARDS_CONNECTION_MIN_NAME_DESC',
  DashboardsConnectionMinPrivilegeAsc = 'DASHBOARDS_CONNECTION_MIN_PRIVILEGE_ASC',
  DashboardsConnectionMinPrivilegeDesc = 'DASHBOARDS_CONNECTION_MIN_PRIVILEGE_DESC',
  DashboardsConnectionMinScopeAsc = 'DASHBOARDS_CONNECTION_MIN_SCOPE_ASC',
  DashboardsConnectionMinScopeDesc = 'DASHBOARDS_CONNECTION_MIN_SCOPE_DESC',
  DashboardsConnectionMinUpdatedAtAsc = 'DASHBOARDS_CONNECTION_MIN_UPDATED_AT_ASC',
  DashboardsConnectionMinUpdatedAtDesc = 'DASHBOARDS_CONNECTION_MIN_UPDATED_AT_DESC',
  DashboardsConnectionStddevPopulationCompanyIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  DashboardsConnectionStddevPopulationCompanyIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  DashboardsConnectionStddevPopulationCreatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DashboardsConnectionStddevPopulationCreatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DashboardsConnectionStddevPopulationCreatedByAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  DashboardsConnectionStddevPopulationCreatedByDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  DashboardsConnectionStddevPopulationCustomizationAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_ASC',
  DashboardsConnectionStddevPopulationCustomizationDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_DESC',
  DashboardsConnectionStddevPopulationDescriptionAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  DashboardsConnectionStddevPopulationDescriptionDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  DashboardsConnectionStddevPopulationEmojiAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  DashboardsConnectionStddevPopulationEmojiDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  DashboardsConnectionStddevPopulationFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionStddevPopulationFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionStddevPopulationIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  DashboardsConnectionStddevPopulationIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  DashboardsConnectionStddevPopulationLayoutAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_LAYOUT_ASC',
  DashboardsConnectionStddevPopulationLayoutDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_LAYOUT_DESC',
  DashboardsConnectionStddevPopulationNameAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  DashboardsConnectionStddevPopulationNameDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  DashboardsConnectionStddevPopulationPrivilegeAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  DashboardsConnectionStddevPopulationPrivilegeDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  DashboardsConnectionStddevPopulationScopeAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  DashboardsConnectionStddevPopulationScopeDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  DashboardsConnectionStddevPopulationUpdatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  DashboardsConnectionStddevPopulationUpdatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  DashboardsConnectionStddevSampleCompanyIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DashboardsConnectionStddevSampleCompanyIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DashboardsConnectionStddevSampleCreatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DashboardsConnectionStddevSampleCreatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DashboardsConnectionStddevSampleCreatedByAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  DashboardsConnectionStddevSampleCreatedByDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  DashboardsConnectionStddevSampleCustomizationAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsConnectionStddevSampleCustomizationDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsConnectionStddevSampleDescriptionAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DashboardsConnectionStddevSampleDescriptionDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DashboardsConnectionStddevSampleEmojiAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  DashboardsConnectionStddevSampleEmojiDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  DashboardsConnectionStddevSampleFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionStddevSampleFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionStddevSampleIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  DashboardsConnectionStddevSampleIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  DashboardsConnectionStddevSampleLayoutAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_LAYOUT_ASC',
  DashboardsConnectionStddevSampleLayoutDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_LAYOUT_DESC',
  DashboardsConnectionStddevSampleNameAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  DashboardsConnectionStddevSampleNameDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  DashboardsConnectionStddevSamplePrivilegeAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  DashboardsConnectionStddevSamplePrivilegeDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  DashboardsConnectionStddevSampleScopeAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  DashboardsConnectionStddevSampleScopeDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  DashboardsConnectionStddevSampleUpdatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DashboardsConnectionStddevSampleUpdatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DashboardsConnectionSumCompanyIdAsc = 'DASHBOARDS_CONNECTION_SUM_COMPANY_ID_ASC',
  DashboardsConnectionSumCompanyIdDesc = 'DASHBOARDS_CONNECTION_SUM_COMPANY_ID_DESC',
  DashboardsConnectionSumCreatedAtAsc = 'DASHBOARDS_CONNECTION_SUM_CREATED_AT_ASC',
  DashboardsConnectionSumCreatedAtDesc = 'DASHBOARDS_CONNECTION_SUM_CREATED_AT_DESC',
  DashboardsConnectionSumCreatedByAsc = 'DASHBOARDS_CONNECTION_SUM_CREATED_BY_ASC',
  DashboardsConnectionSumCreatedByDesc = 'DASHBOARDS_CONNECTION_SUM_CREATED_BY_DESC',
  DashboardsConnectionSumCustomizationAsc = 'DASHBOARDS_CONNECTION_SUM_CUSTOMIZATION_ASC',
  DashboardsConnectionSumCustomizationDesc = 'DASHBOARDS_CONNECTION_SUM_CUSTOMIZATION_DESC',
  DashboardsConnectionSumDescriptionAsc = 'DASHBOARDS_CONNECTION_SUM_DESCRIPTION_ASC',
  DashboardsConnectionSumDescriptionDesc = 'DASHBOARDS_CONNECTION_SUM_DESCRIPTION_DESC',
  DashboardsConnectionSumEmojiAsc = 'DASHBOARDS_CONNECTION_SUM_EMOJI_ASC',
  DashboardsConnectionSumEmojiDesc = 'DASHBOARDS_CONNECTION_SUM_EMOJI_DESC',
  DashboardsConnectionSumFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionSumFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionSumIdAsc = 'DASHBOARDS_CONNECTION_SUM_ID_ASC',
  DashboardsConnectionSumIdDesc = 'DASHBOARDS_CONNECTION_SUM_ID_DESC',
  DashboardsConnectionSumLayoutAsc = 'DASHBOARDS_CONNECTION_SUM_LAYOUT_ASC',
  DashboardsConnectionSumLayoutDesc = 'DASHBOARDS_CONNECTION_SUM_LAYOUT_DESC',
  DashboardsConnectionSumNameAsc = 'DASHBOARDS_CONNECTION_SUM_NAME_ASC',
  DashboardsConnectionSumNameDesc = 'DASHBOARDS_CONNECTION_SUM_NAME_DESC',
  DashboardsConnectionSumPrivilegeAsc = 'DASHBOARDS_CONNECTION_SUM_PRIVILEGE_ASC',
  DashboardsConnectionSumPrivilegeDesc = 'DASHBOARDS_CONNECTION_SUM_PRIVILEGE_DESC',
  DashboardsConnectionSumScopeAsc = 'DASHBOARDS_CONNECTION_SUM_SCOPE_ASC',
  DashboardsConnectionSumScopeDesc = 'DASHBOARDS_CONNECTION_SUM_SCOPE_DESC',
  DashboardsConnectionSumUpdatedAtAsc = 'DASHBOARDS_CONNECTION_SUM_UPDATED_AT_ASC',
  DashboardsConnectionSumUpdatedAtDesc = 'DASHBOARDS_CONNECTION_SUM_UPDATED_AT_DESC',
  DashboardsConnectionVariancePopulationCompanyIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DashboardsConnectionVariancePopulationCompanyIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DashboardsConnectionVariancePopulationCreatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DashboardsConnectionVariancePopulationCreatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DashboardsConnectionVariancePopulationCreatedByAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  DashboardsConnectionVariancePopulationCreatedByDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  DashboardsConnectionVariancePopulationCustomizationAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_ASC',
  DashboardsConnectionVariancePopulationCustomizationDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_DESC',
  DashboardsConnectionVariancePopulationDescriptionAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DashboardsConnectionVariancePopulationDescriptionDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DashboardsConnectionVariancePopulationEmojiAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  DashboardsConnectionVariancePopulationEmojiDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  DashboardsConnectionVariancePopulationFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionVariancePopulationFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionVariancePopulationIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  DashboardsConnectionVariancePopulationIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  DashboardsConnectionVariancePopulationLayoutAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_LAYOUT_ASC',
  DashboardsConnectionVariancePopulationLayoutDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_LAYOUT_DESC',
  DashboardsConnectionVariancePopulationNameAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  DashboardsConnectionVariancePopulationNameDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  DashboardsConnectionVariancePopulationPrivilegeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  DashboardsConnectionVariancePopulationPrivilegeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  DashboardsConnectionVariancePopulationScopeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  DashboardsConnectionVariancePopulationScopeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  DashboardsConnectionVariancePopulationUpdatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DashboardsConnectionVariancePopulationUpdatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DashboardsConnectionVarianceSampleCompanyIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DashboardsConnectionVarianceSampleCompanyIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DashboardsConnectionVarianceSampleCreatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DashboardsConnectionVarianceSampleCreatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DashboardsConnectionVarianceSampleCreatedByAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  DashboardsConnectionVarianceSampleCreatedByDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  DashboardsConnectionVarianceSampleCustomizationAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsConnectionVarianceSampleCustomizationDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsConnectionVarianceSampleDescriptionAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DashboardsConnectionVarianceSampleDescriptionDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DashboardsConnectionVarianceSampleEmojiAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  DashboardsConnectionVarianceSampleEmojiDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  DashboardsConnectionVarianceSampleFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionVarianceSampleFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionVarianceSampleIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  DashboardsConnectionVarianceSampleIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  DashboardsConnectionVarianceSampleLayoutAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_LAYOUT_ASC',
  DashboardsConnectionVarianceSampleLayoutDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_LAYOUT_DESC',
  DashboardsConnectionVarianceSampleNameAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  DashboardsConnectionVarianceSampleNameDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  DashboardsConnectionVarianceSamplePrivilegeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  DashboardsConnectionVarianceSamplePrivilegeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  DashboardsConnectionVarianceSampleScopeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  DashboardsConnectionVarianceSampleScopeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  DashboardsConnectionVarianceSampleUpdatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DashboardsConnectionVarianceSampleUpdatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmailAccountsConnectionArrayAggAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_ALIAS_ASC',
  EmailAccountsConnectionArrayAggAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_ALIAS_DESC',
  EmailAccountsConnectionArrayAggCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  EmailAccountsConnectionArrayAggCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  EmailAccountsConnectionArrayAggCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  EmailAccountsConnectionArrayAggCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  EmailAccountsConnectionArrayAggEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionArrayAggEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionArrayAggIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_ID_ASC',
  EmailAccountsConnectionArrayAggIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_ID_DESC',
  EmailAccountsConnectionArrayAggNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionArrayAggNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionArrayAggNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionArrayAggNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionArrayAggNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionArrayAggNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionArrayAggNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_STATUS_ASC',
  EmailAccountsConnectionArrayAggNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_STATUS_DESC',
  EmailAccountsConnectionArrayAggNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionArrayAggNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionArrayAggNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionArrayAggNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionArrayAggProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_PROVIDER_ASC',
  EmailAccountsConnectionArrayAggProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_PROVIDER_DESC',
  EmailAccountsConnectionArrayAggStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  EmailAccountsConnectionArrayAggStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  EmailAccountsConnectionArrayAggStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_STATUS_SINCE_ASC',
  EmailAccountsConnectionArrayAggStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_ARRAY_AGG_STATUS_SINCE_DESC',
  EmailAccountsConnectionAverageAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_ALIAS_ASC',
  EmailAccountsConnectionAverageAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_ALIAS_DESC',
  EmailAccountsConnectionAverageCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  EmailAccountsConnectionAverageCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  EmailAccountsConnectionAverageCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  EmailAccountsConnectionAverageCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  EmailAccountsConnectionAverageEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionAverageEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionAverageIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_ID_ASC',
  EmailAccountsConnectionAverageIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_ID_DESC',
  EmailAccountsConnectionAverageNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionAverageNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionAverageNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionAverageNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionAverageNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionAverageNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionAverageNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_STATUS_ASC',
  EmailAccountsConnectionAverageNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_STATUS_DESC',
  EmailAccountsConnectionAverageNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionAverageNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionAverageNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionAverageNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionAverageProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_PROVIDER_ASC',
  EmailAccountsConnectionAverageProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_PROVIDER_DESC',
  EmailAccountsConnectionAverageStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_STATUS_ASC',
  EmailAccountsConnectionAverageStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_STATUS_DESC',
  EmailAccountsConnectionAverageStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_STATUS_SINCE_ASC',
  EmailAccountsConnectionAverageStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_AVERAGE_STATUS_SINCE_DESC',
  EmailAccountsConnectionCountAsc = 'EMAIL_ACCOUNTS_CONNECTION_COUNT_ASC',
  EmailAccountsConnectionCountDesc = 'EMAIL_ACCOUNTS_CONNECTION_COUNT_DESC',
  EmailAccountsConnectionDistinctCountAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_ALIAS_ASC',
  EmailAccountsConnectionDistinctCountAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_ALIAS_DESC',
  EmailAccountsConnectionDistinctCountCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailAccountsConnectionDistinctCountCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailAccountsConnectionDistinctCountCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  EmailAccountsConnectionDistinctCountCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  EmailAccountsConnectionDistinctCountEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionDistinctCountEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionDistinctCountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  EmailAccountsConnectionDistinctCountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  EmailAccountsConnectionDistinctCountNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionDistinctCountNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionDistinctCountNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionDistinctCountNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionDistinctCountNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionDistinctCountNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionDistinctCountNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_STATUS_ASC',
  EmailAccountsConnectionDistinctCountNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_STATUS_DESC',
  EmailAccountsConnectionDistinctCountNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionDistinctCountNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionDistinctCountNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionDistinctCountNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionDistinctCountProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_PROVIDER_ASC',
  EmailAccountsConnectionDistinctCountProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_PROVIDER_DESC',
  EmailAccountsConnectionDistinctCountStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  EmailAccountsConnectionDistinctCountStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  EmailAccountsConnectionDistinctCountStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_STATUS_SINCE_ASC',
  EmailAccountsConnectionDistinctCountStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_DISTINCT_COUNT_STATUS_SINCE_DESC',
  EmailAccountsConnectionMaxAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_ALIAS_ASC',
  EmailAccountsConnectionMaxAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_ALIAS_DESC',
  EmailAccountsConnectionMaxCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_COMPANY_ID_ASC',
  EmailAccountsConnectionMaxCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_COMPANY_ID_DESC',
  EmailAccountsConnectionMaxCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_CREATED_AT_ASC',
  EmailAccountsConnectionMaxCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_CREATED_AT_DESC',
  EmailAccountsConnectionMaxEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionMaxEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionMaxIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_ID_ASC',
  EmailAccountsConnectionMaxIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_ID_DESC',
  EmailAccountsConnectionMaxNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionMaxNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionMaxNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionMaxNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionMaxNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionMaxNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionMaxNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_STATUS_ASC',
  EmailAccountsConnectionMaxNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_STATUS_DESC',
  EmailAccountsConnectionMaxNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionMaxNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionMaxNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionMaxNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionMaxProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_PROVIDER_ASC',
  EmailAccountsConnectionMaxProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_PROVIDER_DESC',
  EmailAccountsConnectionMaxStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_STATUS_ASC',
  EmailAccountsConnectionMaxStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_STATUS_DESC',
  EmailAccountsConnectionMaxStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_STATUS_SINCE_ASC',
  EmailAccountsConnectionMaxStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_MAX_STATUS_SINCE_DESC',
  EmailAccountsConnectionMinAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_ALIAS_ASC',
  EmailAccountsConnectionMinAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_ALIAS_DESC',
  EmailAccountsConnectionMinCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_COMPANY_ID_ASC',
  EmailAccountsConnectionMinCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_COMPANY_ID_DESC',
  EmailAccountsConnectionMinCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_CREATED_AT_ASC',
  EmailAccountsConnectionMinCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_CREATED_AT_DESC',
  EmailAccountsConnectionMinEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionMinEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionMinIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_ID_ASC',
  EmailAccountsConnectionMinIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_ID_DESC',
  EmailAccountsConnectionMinNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionMinNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionMinNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionMinNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionMinNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionMinNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionMinNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_STATUS_ASC',
  EmailAccountsConnectionMinNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_STATUS_DESC',
  EmailAccountsConnectionMinNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionMinNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionMinNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionMinNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionMinProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_PROVIDER_ASC',
  EmailAccountsConnectionMinProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_PROVIDER_DESC',
  EmailAccountsConnectionMinStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_STATUS_ASC',
  EmailAccountsConnectionMinStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_STATUS_DESC',
  EmailAccountsConnectionMinStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_STATUS_SINCE_ASC',
  EmailAccountsConnectionMinStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_MIN_STATUS_SINCE_DESC',
  EmailAccountsConnectionStddevPopulationAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_ALIAS_ASC',
  EmailAccountsConnectionStddevPopulationAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_ALIAS_DESC',
  EmailAccountsConnectionStddevPopulationCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailAccountsConnectionStddevPopulationCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailAccountsConnectionStddevPopulationCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  EmailAccountsConnectionStddevPopulationCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  EmailAccountsConnectionStddevPopulationEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionStddevPopulationEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionStddevPopulationIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  EmailAccountsConnectionStddevPopulationIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  EmailAccountsConnectionStddevPopulationNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionStddevPopulationNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionStddevPopulationNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionStddevPopulationNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionStddevPopulationNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionStddevPopulationNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionStddevPopulationNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_STATUS_ASC',
  EmailAccountsConnectionStddevPopulationNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_STATUS_DESC',
  EmailAccountsConnectionStddevPopulationNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionStddevPopulationNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionStddevPopulationNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionStddevPopulationNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionStddevPopulationProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_PROVIDER_ASC',
  EmailAccountsConnectionStddevPopulationProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_PROVIDER_DESC',
  EmailAccountsConnectionStddevPopulationStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  EmailAccountsConnectionStddevPopulationStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  EmailAccountsConnectionStddevPopulationStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_STATUS_SINCE_ASC',
  EmailAccountsConnectionStddevPopulationStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_POPULATION_STATUS_SINCE_DESC',
  EmailAccountsConnectionStddevSampleAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_ALIAS_ASC',
  EmailAccountsConnectionStddevSampleAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_ALIAS_DESC',
  EmailAccountsConnectionStddevSampleCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailAccountsConnectionStddevSampleCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailAccountsConnectionStddevSampleCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmailAccountsConnectionStddevSampleCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmailAccountsConnectionStddevSampleEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionStddevSampleEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionStddevSampleIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  EmailAccountsConnectionStddevSampleIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  EmailAccountsConnectionStddevSampleNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionStddevSampleNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionStddevSampleNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionStddevSampleNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionStddevSampleNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionStddevSampleNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionStddevSampleNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_STATUS_ASC',
  EmailAccountsConnectionStddevSampleNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_STATUS_DESC',
  EmailAccountsConnectionStddevSampleNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionStddevSampleNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionStddevSampleNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionStddevSampleNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionStddevSampleProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ASC',
  EmailAccountsConnectionStddevSampleProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_PROVIDER_DESC',
  EmailAccountsConnectionStddevSampleStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  EmailAccountsConnectionStddevSampleStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  EmailAccountsConnectionStddevSampleStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_STATUS_SINCE_ASC',
  EmailAccountsConnectionStddevSampleStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_STDDEV_SAMPLE_STATUS_SINCE_DESC',
  EmailAccountsConnectionSumAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_ALIAS_ASC',
  EmailAccountsConnectionSumAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_ALIAS_DESC',
  EmailAccountsConnectionSumCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_COMPANY_ID_ASC',
  EmailAccountsConnectionSumCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_COMPANY_ID_DESC',
  EmailAccountsConnectionSumCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_CREATED_AT_ASC',
  EmailAccountsConnectionSumCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_CREATED_AT_DESC',
  EmailAccountsConnectionSumEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionSumEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionSumIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_ID_ASC',
  EmailAccountsConnectionSumIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_ID_DESC',
  EmailAccountsConnectionSumNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionSumNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionSumNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionSumNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionSumNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionSumNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionSumNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_STATUS_ASC',
  EmailAccountsConnectionSumNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_STATUS_DESC',
  EmailAccountsConnectionSumNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionSumNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionSumNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionSumNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionSumProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_PROVIDER_ASC',
  EmailAccountsConnectionSumProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_PROVIDER_DESC',
  EmailAccountsConnectionSumStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_STATUS_ASC',
  EmailAccountsConnectionSumStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_STATUS_DESC',
  EmailAccountsConnectionSumStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_STATUS_SINCE_ASC',
  EmailAccountsConnectionSumStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_SUM_STATUS_SINCE_DESC',
  EmailAccountsConnectionVariancePopulationAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_ALIAS_ASC',
  EmailAccountsConnectionVariancePopulationAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_ALIAS_DESC',
  EmailAccountsConnectionVariancePopulationCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailAccountsConnectionVariancePopulationCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailAccountsConnectionVariancePopulationCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmailAccountsConnectionVariancePopulationCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmailAccountsConnectionVariancePopulationEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionVariancePopulationEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionVariancePopulationIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  EmailAccountsConnectionVariancePopulationIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  EmailAccountsConnectionVariancePopulationNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionVariancePopulationNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionVariancePopulationNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionVariancePopulationNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionVariancePopulationNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionVariancePopulationNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionVariancePopulationNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_STATUS_ASC',
  EmailAccountsConnectionVariancePopulationNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_STATUS_DESC',
  EmailAccountsConnectionVariancePopulationNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionVariancePopulationNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionVariancePopulationNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionVariancePopulationNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionVariancePopulationProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ASC',
  EmailAccountsConnectionVariancePopulationProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_PROVIDER_DESC',
  EmailAccountsConnectionVariancePopulationStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  EmailAccountsConnectionVariancePopulationStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  EmailAccountsConnectionVariancePopulationStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_STATUS_SINCE_ASC',
  EmailAccountsConnectionVariancePopulationStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_POPULATION_STATUS_SINCE_DESC',
  EmailAccountsConnectionVarianceSampleAliasAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_ALIAS_ASC',
  EmailAccountsConnectionVarianceSampleAliasDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_ALIAS_DESC',
  EmailAccountsConnectionVarianceSampleCompanyIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailAccountsConnectionVarianceSampleCompanyIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailAccountsConnectionVarianceSampleCreatedAtAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmailAccountsConnectionVarianceSampleCreatedAtDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmailAccountsConnectionVarianceSampleEmailAddressAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ADDRESS_ASC',
  EmailAccountsConnectionVarianceSampleEmailAddressDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ADDRESS_DESC',
  EmailAccountsConnectionVarianceSampleIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  EmailAccountsConnectionVarianceSampleIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  EmailAccountsConnectionVarianceSampleNylasAccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_ACCOUNT_ID_ASC',
  EmailAccountsConnectionVarianceSampleNylasAccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_ACCOUNT_ID_DESC',
  EmailAccountsConnectionVarianceSampleNylasClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_CLIENT_ID_ASC',
  EmailAccountsConnectionVarianceSampleNylasClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_CLIENT_ID_DESC',
  EmailAccountsConnectionVarianceSampleNylasProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_PROVIDER_ASC',
  EmailAccountsConnectionVarianceSampleNylasProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_PROVIDER_DESC',
  EmailAccountsConnectionVarianceSampleNylasStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_STATUS_ASC',
  EmailAccountsConnectionVarianceSampleNylasStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_STATUS_DESC',
  EmailAccountsConnectionVarianceSampleNylasV2AccountIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_V2_ACCOUNT_ID_ASC',
  EmailAccountsConnectionVarianceSampleNylasV2AccountIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_V2_ACCOUNT_ID_DESC',
  EmailAccountsConnectionVarianceSampleNylasV2ClientIdAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_V2_CLIENT_ID_ASC',
  EmailAccountsConnectionVarianceSampleNylasV2ClientIdDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_NYLAS_V2_CLIENT_ID_DESC',
  EmailAccountsConnectionVarianceSampleProviderAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ASC',
  EmailAccountsConnectionVarianceSampleProviderDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_DESC',
  EmailAccountsConnectionVarianceSampleStatusAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  EmailAccountsConnectionVarianceSampleStatusDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  EmailAccountsConnectionVarianceSampleStatusSinceAsc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_STATUS_SINCE_ASC',
  EmailAccountsConnectionVarianceSampleStatusSinceDesc = 'EMAIL_ACCOUNTS_CONNECTION_VARIANCE_SAMPLE_STATUS_SINCE_DESC',
  EmailFooterAsc = 'EMAIL_FOOTER_ASC',
  EmailFooterDesc = 'EMAIL_FOOTER_DESC',
  EmailHeaderAsc = 'EMAIL_HEADER_ASC',
  EmailHeaderDesc = 'EMAIL_HEADER_DESC',
  FiltersConnectionArrayAggCompanyIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FiltersConnectionArrayAggCompanyIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FiltersConnectionArrayAggConditionsAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CONDITIONS_ASC',
  FiltersConnectionArrayAggConditionsDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CONDITIONS_DESC',
  FiltersConnectionArrayAggCreatedAtAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FiltersConnectionArrayAggCreatedAtDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FiltersConnectionArrayAggCreatedByAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FiltersConnectionArrayAggCreatedByDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FiltersConnectionArrayAggEmojiAsc = 'FILTERS_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  FiltersConnectionArrayAggEmojiDesc = 'FILTERS_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  FiltersConnectionArrayAggFilterFolderIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  FiltersConnectionArrayAggFilterFolderIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  FiltersConnectionArrayAggIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_ID_ASC',
  FiltersConnectionArrayAggIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_ID_DESC',
  FiltersConnectionArrayAggIsDefaultAsc = 'FILTERS_CONNECTION_ARRAY_AGG_IS_DEFAULT_ASC',
  FiltersConnectionArrayAggIsDefaultDesc = 'FILTERS_CONNECTION_ARRAY_AGG_IS_DEFAULT_DESC',
  FiltersConnectionArrayAggNameAsc = 'FILTERS_CONNECTION_ARRAY_AGG_NAME_ASC',
  FiltersConnectionArrayAggNameDesc = 'FILTERS_CONNECTION_ARRAY_AGG_NAME_DESC',
  FiltersConnectionArrayAggScopeAsc = 'FILTERS_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  FiltersConnectionArrayAggScopeDesc = 'FILTERS_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  FiltersConnectionArrayAggTypeAsc = 'FILTERS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FiltersConnectionArrayAggTypeDesc = 'FILTERS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FiltersConnectionArrayAggUpdatedAtAsc = 'FILTERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FiltersConnectionArrayAggUpdatedAtDesc = 'FILTERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FiltersConnectionAverageCompanyIdAsc = 'FILTERS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FiltersConnectionAverageCompanyIdDesc = 'FILTERS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FiltersConnectionAverageConditionsAsc = 'FILTERS_CONNECTION_AVERAGE_CONDITIONS_ASC',
  FiltersConnectionAverageConditionsDesc = 'FILTERS_CONNECTION_AVERAGE_CONDITIONS_DESC',
  FiltersConnectionAverageCreatedAtAsc = 'FILTERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FiltersConnectionAverageCreatedAtDesc = 'FILTERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FiltersConnectionAverageCreatedByAsc = 'FILTERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FiltersConnectionAverageCreatedByDesc = 'FILTERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FiltersConnectionAverageEmojiAsc = 'FILTERS_CONNECTION_AVERAGE_EMOJI_ASC',
  FiltersConnectionAverageEmojiDesc = 'FILTERS_CONNECTION_AVERAGE_EMOJI_DESC',
  FiltersConnectionAverageFilterFolderIdAsc = 'FILTERS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionAverageFilterFolderIdDesc = 'FILTERS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionAverageIdAsc = 'FILTERS_CONNECTION_AVERAGE_ID_ASC',
  FiltersConnectionAverageIdDesc = 'FILTERS_CONNECTION_AVERAGE_ID_DESC',
  FiltersConnectionAverageIsDefaultAsc = 'FILTERS_CONNECTION_AVERAGE_IS_DEFAULT_ASC',
  FiltersConnectionAverageIsDefaultDesc = 'FILTERS_CONNECTION_AVERAGE_IS_DEFAULT_DESC',
  FiltersConnectionAverageNameAsc = 'FILTERS_CONNECTION_AVERAGE_NAME_ASC',
  FiltersConnectionAverageNameDesc = 'FILTERS_CONNECTION_AVERAGE_NAME_DESC',
  FiltersConnectionAverageScopeAsc = 'FILTERS_CONNECTION_AVERAGE_SCOPE_ASC',
  FiltersConnectionAverageScopeDesc = 'FILTERS_CONNECTION_AVERAGE_SCOPE_DESC',
  FiltersConnectionAverageTypeAsc = 'FILTERS_CONNECTION_AVERAGE_TYPE_ASC',
  FiltersConnectionAverageTypeDesc = 'FILTERS_CONNECTION_AVERAGE_TYPE_DESC',
  FiltersConnectionAverageUpdatedAtAsc = 'FILTERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FiltersConnectionAverageUpdatedAtDesc = 'FILTERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FiltersConnectionCountAsc = 'FILTERS_CONNECTION_COUNT_ASC',
  FiltersConnectionCountDesc = 'FILTERS_CONNECTION_COUNT_DESC',
  FiltersConnectionDistinctCountCompanyIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FiltersConnectionDistinctCountCompanyIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FiltersConnectionDistinctCountConditionsAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CONDITIONS_ASC',
  FiltersConnectionDistinctCountConditionsDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CONDITIONS_DESC',
  FiltersConnectionDistinctCountCreatedAtAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FiltersConnectionDistinctCountCreatedAtDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FiltersConnectionDistinctCountCreatedByAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FiltersConnectionDistinctCountCreatedByDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FiltersConnectionDistinctCountEmojiAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  FiltersConnectionDistinctCountEmojiDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  FiltersConnectionDistinctCountFilterFolderIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  FiltersConnectionDistinctCountFilterFolderIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  FiltersConnectionDistinctCountIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FiltersConnectionDistinctCountIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FiltersConnectionDistinctCountIsDefaultAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_ASC',
  FiltersConnectionDistinctCountIsDefaultDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_DESC',
  FiltersConnectionDistinctCountNameAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FiltersConnectionDistinctCountNameDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FiltersConnectionDistinctCountScopeAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  FiltersConnectionDistinctCountScopeDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  FiltersConnectionDistinctCountTypeAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FiltersConnectionDistinctCountTypeDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FiltersConnectionDistinctCountUpdatedAtAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FiltersConnectionDistinctCountUpdatedAtDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FiltersConnectionMaxCompanyIdAsc = 'FILTERS_CONNECTION_MAX_COMPANY_ID_ASC',
  FiltersConnectionMaxCompanyIdDesc = 'FILTERS_CONNECTION_MAX_COMPANY_ID_DESC',
  FiltersConnectionMaxConditionsAsc = 'FILTERS_CONNECTION_MAX_CONDITIONS_ASC',
  FiltersConnectionMaxConditionsDesc = 'FILTERS_CONNECTION_MAX_CONDITIONS_DESC',
  FiltersConnectionMaxCreatedAtAsc = 'FILTERS_CONNECTION_MAX_CREATED_AT_ASC',
  FiltersConnectionMaxCreatedAtDesc = 'FILTERS_CONNECTION_MAX_CREATED_AT_DESC',
  FiltersConnectionMaxCreatedByAsc = 'FILTERS_CONNECTION_MAX_CREATED_BY_ASC',
  FiltersConnectionMaxCreatedByDesc = 'FILTERS_CONNECTION_MAX_CREATED_BY_DESC',
  FiltersConnectionMaxEmojiAsc = 'FILTERS_CONNECTION_MAX_EMOJI_ASC',
  FiltersConnectionMaxEmojiDesc = 'FILTERS_CONNECTION_MAX_EMOJI_DESC',
  FiltersConnectionMaxFilterFolderIdAsc = 'FILTERS_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  FiltersConnectionMaxFilterFolderIdDesc = 'FILTERS_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  FiltersConnectionMaxIdAsc = 'FILTERS_CONNECTION_MAX_ID_ASC',
  FiltersConnectionMaxIdDesc = 'FILTERS_CONNECTION_MAX_ID_DESC',
  FiltersConnectionMaxIsDefaultAsc = 'FILTERS_CONNECTION_MAX_IS_DEFAULT_ASC',
  FiltersConnectionMaxIsDefaultDesc = 'FILTERS_CONNECTION_MAX_IS_DEFAULT_DESC',
  FiltersConnectionMaxNameAsc = 'FILTERS_CONNECTION_MAX_NAME_ASC',
  FiltersConnectionMaxNameDesc = 'FILTERS_CONNECTION_MAX_NAME_DESC',
  FiltersConnectionMaxScopeAsc = 'FILTERS_CONNECTION_MAX_SCOPE_ASC',
  FiltersConnectionMaxScopeDesc = 'FILTERS_CONNECTION_MAX_SCOPE_DESC',
  FiltersConnectionMaxTypeAsc = 'FILTERS_CONNECTION_MAX_TYPE_ASC',
  FiltersConnectionMaxTypeDesc = 'FILTERS_CONNECTION_MAX_TYPE_DESC',
  FiltersConnectionMaxUpdatedAtAsc = 'FILTERS_CONNECTION_MAX_UPDATED_AT_ASC',
  FiltersConnectionMaxUpdatedAtDesc = 'FILTERS_CONNECTION_MAX_UPDATED_AT_DESC',
  FiltersConnectionMinCompanyIdAsc = 'FILTERS_CONNECTION_MIN_COMPANY_ID_ASC',
  FiltersConnectionMinCompanyIdDesc = 'FILTERS_CONNECTION_MIN_COMPANY_ID_DESC',
  FiltersConnectionMinConditionsAsc = 'FILTERS_CONNECTION_MIN_CONDITIONS_ASC',
  FiltersConnectionMinConditionsDesc = 'FILTERS_CONNECTION_MIN_CONDITIONS_DESC',
  FiltersConnectionMinCreatedAtAsc = 'FILTERS_CONNECTION_MIN_CREATED_AT_ASC',
  FiltersConnectionMinCreatedAtDesc = 'FILTERS_CONNECTION_MIN_CREATED_AT_DESC',
  FiltersConnectionMinCreatedByAsc = 'FILTERS_CONNECTION_MIN_CREATED_BY_ASC',
  FiltersConnectionMinCreatedByDesc = 'FILTERS_CONNECTION_MIN_CREATED_BY_DESC',
  FiltersConnectionMinEmojiAsc = 'FILTERS_CONNECTION_MIN_EMOJI_ASC',
  FiltersConnectionMinEmojiDesc = 'FILTERS_CONNECTION_MIN_EMOJI_DESC',
  FiltersConnectionMinFilterFolderIdAsc = 'FILTERS_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  FiltersConnectionMinFilterFolderIdDesc = 'FILTERS_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  FiltersConnectionMinIdAsc = 'FILTERS_CONNECTION_MIN_ID_ASC',
  FiltersConnectionMinIdDesc = 'FILTERS_CONNECTION_MIN_ID_DESC',
  FiltersConnectionMinIsDefaultAsc = 'FILTERS_CONNECTION_MIN_IS_DEFAULT_ASC',
  FiltersConnectionMinIsDefaultDesc = 'FILTERS_CONNECTION_MIN_IS_DEFAULT_DESC',
  FiltersConnectionMinNameAsc = 'FILTERS_CONNECTION_MIN_NAME_ASC',
  FiltersConnectionMinNameDesc = 'FILTERS_CONNECTION_MIN_NAME_DESC',
  FiltersConnectionMinScopeAsc = 'FILTERS_CONNECTION_MIN_SCOPE_ASC',
  FiltersConnectionMinScopeDesc = 'FILTERS_CONNECTION_MIN_SCOPE_DESC',
  FiltersConnectionMinTypeAsc = 'FILTERS_CONNECTION_MIN_TYPE_ASC',
  FiltersConnectionMinTypeDesc = 'FILTERS_CONNECTION_MIN_TYPE_DESC',
  FiltersConnectionMinUpdatedAtAsc = 'FILTERS_CONNECTION_MIN_UPDATED_AT_ASC',
  FiltersConnectionMinUpdatedAtDesc = 'FILTERS_CONNECTION_MIN_UPDATED_AT_DESC',
  FiltersConnectionStddevPopulationCompanyIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FiltersConnectionStddevPopulationCompanyIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FiltersConnectionStddevPopulationConditionsAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CONDITIONS_ASC',
  FiltersConnectionStddevPopulationConditionsDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CONDITIONS_DESC',
  FiltersConnectionStddevPopulationCreatedAtAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FiltersConnectionStddevPopulationCreatedAtDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FiltersConnectionStddevPopulationCreatedByAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FiltersConnectionStddevPopulationCreatedByDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FiltersConnectionStddevPopulationEmojiAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  FiltersConnectionStddevPopulationEmojiDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  FiltersConnectionStddevPopulationFilterFolderIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersConnectionStddevPopulationFilterFolderIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersConnectionStddevPopulationIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FiltersConnectionStddevPopulationIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FiltersConnectionStddevPopulationIsDefaultAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_ASC',
  FiltersConnectionStddevPopulationIsDefaultDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_DESC',
  FiltersConnectionStddevPopulationNameAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FiltersConnectionStddevPopulationNameDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FiltersConnectionStddevPopulationScopeAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  FiltersConnectionStddevPopulationScopeDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  FiltersConnectionStddevPopulationTypeAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FiltersConnectionStddevPopulationTypeDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FiltersConnectionStddevPopulationUpdatedAtAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FiltersConnectionStddevPopulationUpdatedAtDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FiltersConnectionStddevSampleCompanyIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FiltersConnectionStddevSampleCompanyIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FiltersConnectionStddevSampleConditionsAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CONDITIONS_ASC',
  FiltersConnectionStddevSampleConditionsDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CONDITIONS_DESC',
  FiltersConnectionStddevSampleCreatedAtAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FiltersConnectionStddevSampleCreatedAtDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FiltersConnectionStddevSampleCreatedByAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FiltersConnectionStddevSampleCreatedByDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FiltersConnectionStddevSampleEmojiAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  FiltersConnectionStddevSampleEmojiDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  FiltersConnectionStddevSampleFilterFolderIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionStddevSampleFilterFolderIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionStddevSampleIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FiltersConnectionStddevSampleIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FiltersConnectionStddevSampleIsDefaultAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_ASC',
  FiltersConnectionStddevSampleIsDefaultDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_DESC',
  FiltersConnectionStddevSampleNameAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FiltersConnectionStddevSampleNameDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FiltersConnectionStddevSampleScopeAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  FiltersConnectionStddevSampleScopeDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  FiltersConnectionStddevSampleTypeAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FiltersConnectionStddevSampleTypeDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FiltersConnectionStddevSampleUpdatedAtAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FiltersConnectionStddevSampleUpdatedAtDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FiltersConnectionSumCompanyIdAsc = 'FILTERS_CONNECTION_SUM_COMPANY_ID_ASC',
  FiltersConnectionSumCompanyIdDesc = 'FILTERS_CONNECTION_SUM_COMPANY_ID_DESC',
  FiltersConnectionSumConditionsAsc = 'FILTERS_CONNECTION_SUM_CONDITIONS_ASC',
  FiltersConnectionSumConditionsDesc = 'FILTERS_CONNECTION_SUM_CONDITIONS_DESC',
  FiltersConnectionSumCreatedAtAsc = 'FILTERS_CONNECTION_SUM_CREATED_AT_ASC',
  FiltersConnectionSumCreatedAtDesc = 'FILTERS_CONNECTION_SUM_CREATED_AT_DESC',
  FiltersConnectionSumCreatedByAsc = 'FILTERS_CONNECTION_SUM_CREATED_BY_ASC',
  FiltersConnectionSumCreatedByDesc = 'FILTERS_CONNECTION_SUM_CREATED_BY_DESC',
  FiltersConnectionSumEmojiAsc = 'FILTERS_CONNECTION_SUM_EMOJI_ASC',
  FiltersConnectionSumEmojiDesc = 'FILTERS_CONNECTION_SUM_EMOJI_DESC',
  FiltersConnectionSumFilterFolderIdAsc = 'FILTERS_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  FiltersConnectionSumFilterFolderIdDesc = 'FILTERS_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  FiltersConnectionSumIdAsc = 'FILTERS_CONNECTION_SUM_ID_ASC',
  FiltersConnectionSumIdDesc = 'FILTERS_CONNECTION_SUM_ID_DESC',
  FiltersConnectionSumIsDefaultAsc = 'FILTERS_CONNECTION_SUM_IS_DEFAULT_ASC',
  FiltersConnectionSumIsDefaultDesc = 'FILTERS_CONNECTION_SUM_IS_DEFAULT_DESC',
  FiltersConnectionSumNameAsc = 'FILTERS_CONNECTION_SUM_NAME_ASC',
  FiltersConnectionSumNameDesc = 'FILTERS_CONNECTION_SUM_NAME_DESC',
  FiltersConnectionSumScopeAsc = 'FILTERS_CONNECTION_SUM_SCOPE_ASC',
  FiltersConnectionSumScopeDesc = 'FILTERS_CONNECTION_SUM_SCOPE_DESC',
  FiltersConnectionSumTypeAsc = 'FILTERS_CONNECTION_SUM_TYPE_ASC',
  FiltersConnectionSumTypeDesc = 'FILTERS_CONNECTION_SUM_TYPE_DESC',
  FiltersConnectionSumUpdatedAtAsc = 'FILTERS_CONNECTION_SUM_UPDATED_AT_ASC',
  FiltersConnectionSumUpdatedAtDesc = 'FILTERS_CONNECTION_SUM_UPDATED_AT_DESC',
  FiltersConnectionVariancePopulationCompanyIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FiltersConnectionVariancePopulationCompanyIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FiltersConnectionVariancePopulationConditionsAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CONDITIONS_ASC',
  FiltersConnectionVariancePopulationConditionsDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CONDITIONS_DESC',
  FiltersConnectionVariancePopulationCreatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FiltersConnectionVariancePopulationCreatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FiltersConnectionVariancePopulationCreatedByAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FiltersConnectionVariancePopulationCreatedByDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FiltersConnectionVariancePopulationEmojiAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  FiltersConnectionVariancePopulationEmojiDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  FiltersConnectionVariancePopulationFilterFolderIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersConnectionVariancePopulationFilterFolderIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersConnectionVariancePopulationIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FiltersConnectionVariancePopulationIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FiltersConnectionVariancePopulationIsDefaultAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_ASC',
  FiltersConnectionVariancePopulationIsDefaultDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_DESC',
  FiltersConnectionVariancePopulationNameAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FiltersConnectionVariancePopulationNameDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FiltersConnectionVariancePopulationScopeAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  FiltersConnectionVariancePopulationScopeDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  FiltersConnectionVariancePopulationTypeAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FiltersConnectionVariancePopulationTypeDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FiltersConnectionVariancePopulationUpdatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FiltersConnectionVariancePopulationUpdatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FiltersConnectionVarianceSampleCompanyIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FiltersConnectionVarianceSampleCompanyIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FiltersConnectionVarianceSampleConditionsAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_ASC',
  FiltersConnectionVarianceSampleConditionsDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_DESC',
  FiltersConnectionVarianceSampleCreatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FiltersConnectionVarianceSampleCreatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FiltersConnectionVarianceSampleCreatedByAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FiltersConnectionVarianceSampleCreatedByDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FiltersConnectionVarianceSampleEmojiAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  FiltersConnectionVarianceSampleEmojiDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  FiltersConnectionVarianceSampleFilterFolderIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionVarianceSampleFilterFolderIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionVarianceSampleIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FiltersConnectionVarianceSampleIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FiltersConnectionVarianceSampleIsDefaultAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_ASC',
  FiltersConnectionVarianceSampleIsDefaultDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_DESC',
  FiltersConnectionVarianceSampleNameAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FiltersConnectionVarianceSampleNameDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FiltersConnectionVarianceSampleScopeAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  FiltersConnectionVarianceSampleScopeDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  FiltersConnectionVarianceSampleTypeAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FiltersConnectionVarianceSampleTypeDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FiltersConnectionVarianceSampleUpdatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FiltersConnectionVarianceSampleUpdatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersConnectionArrayAggCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilterFoldersConnectionArrayAggCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilterFoldersConnectionArrayAggCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilterFoldersConnectionArrayAggCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilterFoldersConnectionArrayAggCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilterFoldersConnectionArrayAggCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilterFoldersConnectionArrayAggIdAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_ID_ASC',
  FilterFoldersConnectionArrayAggIdDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_ID_DESC',
  FilterFoldersConnectionArrayAggNameAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilterFoldersConnectionArrayAggNameDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilterFoldersConnectionArrayAggUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilterFoldersConnectionArrayAggUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilterFoldersConnectionArrayAggUuidAsc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilterFoldersConnectionArrayAggUuidDesc = 'FILTER_FOLDERS_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilterFoldersConnectionAverageCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilterFoldersConnectionAverageCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilterFoldersConnectionAverageCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilterFoldersConnectionAverageCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilterFoldersConnectionAverageCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilterFoldersConnectionAverageCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilterFoldersConnectionAverageIdAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_ID_ASC',
  FilterFoldersConnectionAverageIdDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_ID_DESC',
  FilterFoldersConnectionAverageNameAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_NAME_ASC',
  FilterFoldersConnectionAverageNameDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_NAME_DESC',
  FilterFoldersConnectionAverageUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilterFoldersConnectionAverageUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilterFoldersConnectionAverageUuidAsc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_UUID_ASC',
  FilterFoldersConnectionAverageUuidDesc = 'FILTER_FOLDERS_CONNECTION_AVERAGE_UUID_DESC',
  FilterFoldersConnectionCountAsc = 'FILTER_FOLDERS_CONNECTION_COUNT_ASC',
  FilterFoldersConnectionCountDesc = 'FILTER_FOLDERS_CONNECTION_COUNT_DESC',
  FilterFoldersConnectionDistinctCountCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilterFoldersConnectionDistinctCountCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilterFoldersConnectionDistinctCountCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilterFoldersConnectionDistinctCountCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilterFoldersConnectionDistinctCountCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilterFoldersConnectionDistinctCountCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilterFoldersConnectionDistinctCountIdAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilterFoldersConnectionDistinctCountIdDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilterFoldersConnectionDistinctCountNameAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilterFoldersConnectionDistinctCountNameDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilterFoldersConnectionDistinctCountUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilterFoldersConnectionDistinctCountUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilterFoldersConnectionDistinctCountUuidAsc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilterFoldersConnectionDistinctCountUuidDesc = 'FILTER_FOLDERS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilterFoldersConnectionMaxCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_MAX_COMPANY_ID_ASC',
  FilterFoldersConnectionMaxCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_MAX_COMPANY_ID_DESC',
  FilterFoldersConnectionMaxCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_MAX_CREATED_AT_ASC',
  FilterFoldersConnectionMaxCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_MAX_CREATED_AT_DESC',
  FilterFoldersConnectionMaxCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_MAX_CREATED_BY_ASC',
  FilterFoldersConnectionMaxCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_MAX_CREATED_BY_DESC',
  FilterFoldersConnectionMaxIdAsc = 'FILTER_FOLDERS_CONNECTION_MAX_ID_ASC',
  FilterFoldersConnectionMaxIdDesc = 'FILTER_FOLDERS_CONNECTION_MAX_ID_DESC',
  FilterFoldersConnectionMaxNameAsc = 'FILTER_FOLDERS_CONNECTION_MAX_NAME_ASC',
  FilterFoldersConnectionMaxNameDesc = 'FILTER_FOLDERS_CONNECTION_MAX_NAME_DESC',
  FilterFoldersConnectionMaxUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_MAX_UPDATED_AT_ASC',
  FilterFoldersConnectionMaxUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_MAX_UPDATED_AT_DESC',
  FilterFoldersConnectionMaxUuidAsc = 'FILTER_FOLDERS_CONNECTION_MAX_UUID_ASC',
  FilterFoldersConnectionMaxUuidDesc = 'FILTER_FOLDERS_CONNECTION_MAX_UUID_DESC',
  FilterFoldersConnectionMinCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_MIN_COMPANY_ID_ASC',
  FilterFoldersConnectionMinCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_MIN_COMPANY_ID_DESC',
  FilterFoldersConnectionMinCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_MIN_CREATED_AT_ASC',
  FilterFoldersConnectionMinCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_MIN_CREATED_AT_DESC',
  FilterFoldersConnectionMinCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_MIN_CREATED_BY_ASC',
  FilterFoldersConnectionMinCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_MIN_CREATED_BY_DESC',
  FilterFoldersConnectionMinIdAsc = 'FILTER_FOLDERS_CONNECTION_MIN_ID_ASC',
  FilterFoldersConnectionMinIdDesc = 'FILTER_FOLDERS_CONNECTION_MIN_ID_DESC',
  FilterFoldersConnectionMinNameAsc = 'FILTER_FOLDERS_CONNECTION_MIN_NAME_ASC',
  FilterFoldersConnectionMinNameDesc = 'FILTER_FOLDERS_CONNECTION_MIN_NAME_DESC',
  FilterFoldersConnectionMinUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_MIN_UPDATED_AT_ASC',
  FilterFoldersConnectionMinUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_MIN_UPDATED_AT_DESC',
  FilterFoldersConnectionMinUuidAsc = 'FILTER_FOLDERS_CONNECTION_MIN_UUID_ASC',
  FilterFoldersConnectionMinUuidDesc = 'FILTER_FOLDERS_CONNECTION_MIN_UUID_DESC',
  FilterFoldersConnectionStddevPopulationCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilterFoldersConnectionStddevPopulationCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilterFoldersConnectionStddevPopulationCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilterFoldersConnectionStddevPopulationCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilterFoldersConnectionStddevPopulationCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilterFoldersConnectionStddevPopulationCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilterFoldersConnectionStddevPopulationIdAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilterFoldersConnectionStddevPopulationIdDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilterFoldersConnectionStddevPopulationNameAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilterFoldersConnectionStddevPopulationNameDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilterFoldersConnectionStddevPopulationUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilterFoldersConnectionStddevPopulationUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilterFoldersConnectionStddevPopulationUuidAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilterFoldersConnectionStddevPopulationUuidDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilterFoldersConnectionStddevSampleCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilterFoldersConnectionStddevSampleCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilterFoldersConnectionStddevSampleCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilterFoldersConnectionStddevSampleCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilterFoldersConnectionStddevSampleCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilterFoldersConnectionStddevSampleCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilterFoldersConnectionStddevSampleIdAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilterFoldersConnectionStddevSampleIdDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilterFoldersConnectionStddevSampleNameAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilterFoldersConnectionStddevSampleNameDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilterFoldersConnectionStddevSampleUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilterFoldersConnectionStddevSampleUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersConnectionStddevSampleUuidAsc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilterFoldersConnectionStddevSampleUuidDesc = 'FILTER_FOLDERS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilterFoldersConnectionSumCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_SUM_COMPANY_ID_ASC',
  FilterFoldersConnectionSumCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_SUM_COMPANY_ID_DESC',
  FilterFoldersConnectionSumCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_SUM_CREATED_AT_ASC',
  FilterFoldersConnectionSumCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_SUM_CREATED_AT_DESC',
  FilterFoldersConnectionSumCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_SUM_CREATED_BY_ASC',
  FilterFoldersConnectionSumCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_SUM_CREATED_BY_DESC',
  FilterFoldersConnectionSumIdAsc = 'FILTER_FOLDERS_CONNECTION_SUM_ID_ASC',
  FilterFoldersConnectionSumIdDesc = 'FILTER_FOLDERS_CONNECTION_SUM_ID_DESC',
  FilterFoldersConnectionSumNameAsc = 'FILTER_FOLDERS_CONNECTION_SUM_NAME_ASC',
  FilterFoldersConnectionSumNameDesc = 'FILTER_FOLDERS_CONNECTION_SUM_NAME_DESC',
  FilterFoldersConnectionSumUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_SUM_UPDATED_AT_ASC',
  FilterFoldersConnectionSumUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_SUM_UPDATED_AT_DESC',
  FilterFoldersConnectionSumUuidAsc = 'FILTER_FOLDERS_CONNECTION_SUM_UUID_ASC',
  FilterFoldersConnectionSumUuidDesc = 'FILTER_FOLDERS_CONNECTION_SUM_UUID_DESC',
  FilterFoldersConnectionVariancePopulationCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilterFoldersConnectionVariancePopulationCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilterFoldersConnectionVariancePopulationCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilterFoldersConnectionVariancePopulationCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilterFoldersConnectionVariancePopulationCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilterFoldersConnectionVariancePopulationCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilterFoldersConnectionVariancePopulationIdAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilterFoldersConnectionVariancePopulationIdDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilterFoldersConnectionVariancePopulationNameAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilterFoldersConnectionVariancePopulationNameDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilterFoldersConnectionVariancePopulationUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilterFoldersConnectionVariancePopulationUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilterFoldersConnectionVariancePopulationUuidAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilterFoldersConnectionVariancePopulationUuidDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilterFoldersConnectionVarianceSampleCompanyIdAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilterFoldersConnectionVarianceSampleCompanyIdDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilterFoldersConnectionVarianceSampleCreatedAtAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilterFoldersConnectionVarianceSampleCreatedAtDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilterFoldersConnectionVarianceSampleCreatedByAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilterFoldersConnectionVarianceSampleCreatedByDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilterFoldersConnectionVarianceSampleIdAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilterFoldersConnectionVarianceSampleIdDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilterFoldersConnectionVarianceSampleNameAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilterFoldersConnectionVarianceSampleNameDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilterFoldersConnectionVarianceSampleUpdatedAtAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilterFoldersConnectionVarianceSampleUpdatedAtDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersConnectionVarianceSampleUuidAsc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilterFoldersConnectionVarianceSampleUuidDesc = 'FILTER_FOLDERS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  GeolocationUserSettingsConnectionArrayAggCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionArrayAggCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionArrayAggEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  GeolocationUserSettingsConnectionArrayAggEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  GeolocationUserSettingsConnectionArrayAggIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionAverageCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionAverageCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionAverageCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionAverageCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionAverageEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ENABLED_ASC',
  GeolocationUserSettingsConnectionAverageEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ENABLED_DESC',
  GeolocationUserSettingsConnectionAverageIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ID_ASC',
  GeolocationUserSettingsConnectionAverageIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ID_DESC',
  GeolocationUserSettingsConnectionAverageRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionAverageRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionAverageTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionAverageTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionCountAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_COUNT_ASC',
  GeolocationUserSettingsConnectionCountDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_COUNT_DESC',
  GeolocationUserSettingsConnectionDistinctCountCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionDistinctCountCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionDistinctCountEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  GeolocationUserSettingsConnectionDistinctCountEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  GeolocationUserSettingsConnectionDistinctCountIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionMaxCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionMaxCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionMaxCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionMaxCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionMaxEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ENABLED_ASC',
  GeolocationUserSettingsConnectionMaxEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ENABLED_DESC',
  GeolocationUserSettingsConnectionMaxIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ID_ASC',
  GeolocationUserSettingsConnectionMaxIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ID_DESC',
  GeolocationUserSettingsConnectionMaxRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionMaxRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionMaxTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionMaxTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionMinCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionMinCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionMinCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionMinCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionMinEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ENABLED_ASC',
  GeolocationUserSettingsConnectionMinEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ENABLED_DESC',
  GeolocationUserSettingsConnectionMinIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ID_ASC',
  GeolocationUserSettingsConnectionMinIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ID_DESC',
  GeolocationUserSettingsConnectionMinRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionMinRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionMinTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionMinTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionStddevPopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionStddevPopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsConnectionStddevPopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsConnectionStddevPopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionStddevSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionStddevSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsConnectionStddevSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsConnectionStddevSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionSumCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionSumCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionSumCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionSumCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionSumEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ENABLED_ASC',
  GeolocationUserSettingsConnectionSumEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ENABLED_DESC',
  GeolocationUserSettingsConnectionSumIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ID_ASC',
  GeolocationUserSettingsConnectionSumIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ID_DESC',
  GeolocationUserSettingsConnectionSumRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionSumRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionSumTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionSumTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionVariancePopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionVariancePopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsConnectionVariancePopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsConnectionVariancePopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionVarianceSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionVarianceSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsConnectionVarianceSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsConnectionVarianceSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationsConnectionArrayAggActiveAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_ACTIVE_ASC',
  IntegrationsConnectionArrayAggActiveDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_ACTIVE_DESC',
  IntegrationsConnectionArrayAggCompanyIdAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  IntegrationsConnectionArrayAggCompanyIdDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  IntegrationsConnectionArrayAggCreatedAtAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  IntegrationsConnectionArrayAggCreatedAtDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  IntegrationsConnectionArrayAggCreatedByAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  IntegrationsConnectionArrayAggCreatedByDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  IntegrationsConnectionArrayAggIdAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  IntegrationsConnectionArrayAggIdDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  IntegrationsConnectionArrayAggProviderAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_PROVIDER_ASC',
  IntegrationsConnectionArrayAggProviderConfigAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionArrayAggProviderConfigDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionArrayAggProviderDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_PROVIDER_DESC',
  IntegrationsConnectionArrayAggUuidAsc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_UUID_ASC',
  IntegrationsConnectionArrayAggUuidDesc = 'INTEGRATIONS_CONNECTION_ARRAY_AGG_UUID_DESC',
  IntegrationsConnectionAverageActiveAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_ACTIVE_ASC',
  IntegrationsConnectionAverageActiveDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_ACTIVE_DESC',
  IntegrationsConnectionAverageCompanyIdAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  IntegrationsConnectionAverageCompanyIdDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  IntegrationsConnectionAverageCreatedAtAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  IntegrationsConnectionAverageCreatedAtDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  IntegrationsConnectionAverageCreatedByAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  IntegrationsConnectionAverageCreatedByDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  IntegrationsConnectionAverageIdAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_ID_ASC',
  IntegrationsConnectionAverageIdDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_ID_DESC',
  IntegrationsConnectionAverageProviderAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_PROVIDER_ASC',
  IntegrationsConnectionAverageProviderConfigAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionAverageProviderConfigDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionAverageProviderDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_PROVIDER_DESC',
  IntegrationsConnectionAverageUuidAsc = 'INTEGRATIONS_CONNECTION_AVERAGE_UUID_ASC',
  IntegrationsConnectionAverageUuidDesc = 'INTEGRATIONS_CONNECTION_AVERAGE_UUID_DESC',
  IntegrationsConnectionCountAsc = 'INTEGRATIONS_CONNECTION_COUNT_ASC',
  IntegrationsConnectionCountDesc = 'INTEGRATIONS_CONNECTION_COUNT_DESC',
  IntegrationsConnectionDistinctCountActiveAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_ACTIVE_ASC',
  IntegrationsConnectionDistinctCountActiveDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_ACTIVE_DESC',
  IntegrationsConnectionDistinctCountCompanyIdAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  IntegrationsConnectionDistinctCountCompanyIdDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  IntegrationsConnectionDistinctCountCreatedAtAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  IntegrationsConnectionDistinctCountCreatedAtDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  IntegrationsConnectionDistinctCountCreatedByAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  IntegrationsConnectionDistinctCountCreatedByDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  IntegrationsConnectionDistinctCountIdAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  IntegrationsConnectionDistinctCountIdDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  IntegrationsConnectionDistinctCountProviderAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_PROVIDER_ASC',
  IntegrationsConnectionDistinctCountProviderConfigAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionDistinctCountProviderConfigDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionDistinctCountProviderDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_PROVIDER_DESC',
  IntegrationsConnectionDistinctCountUuidAsc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  IntegrationsConnectionDistinctCountUuidDesc = 'INTEGRATIONS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  IntegrationsConnectionMaxActiveAsc = 'INTEGRATIONS_CONNECTION_MAX_ACTIVE_ASC',
  IntegrationsConnectionMaxActiveDesc = 'INTEGRATIONS_CONNECTION_MAX_ACTIVE_DESC',
  IntegrationsConnectionMaxCompanyIdAsc = 'INTEGRATIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  IntegrationsConnectionMaxCompanyIdDesc = 'INTEGRATIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  IntegrationsConnectionMaxCreatedAtAsc = 'INTEGRATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  IntegrationsConnectionMaxCreatedAtDesc = 'INTEGRATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  IntegrationsConnectionMaxCreatedByAsc = 'INTEGRATIONS_CONNECTION_MAX_CREATED_BY_ASC',
  IntegrationsConnectionMaxCreatedByDesc = 'INTEGRATIONS_CONNECTION_MAX_CREATED_BY_DESC',
  IntegrationsConnectionMaxIdAsc = 'INTEGRATIONS_CONNECTION_MAX_ID_ASC',
  IntegrationsConnectionMaxIdDesc = 'INTEGRATIONS_CONNECTION_MAX_ID_DESC',
  IntegrationsConnectionMaxProviderAsc = 'INTEGRATIONS_CONNECTION_MAX_PROVIDER_ASC',
  IntegrationsConnectionMaxProviderConfigAsc = 'INTEGRATIONS_CONNECTION_MAX_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionMaxProviderConfigDesc = 'INTEGRATIONS_CONNECTION_MAX_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionMaxProviderDesc = 'INTEGRATIONS_CONNECTION_MAX_PROVIDER_DESC',
  IntegrationsConnectionMaxUuidAsc = 'INTEGRATIONS_CONNECTION_MAX_UUID_ASC',
  IntegrationsConnectionMaxUuidDesc = 'INTEGRATIONS_CONNECTION_MAX_UUID_DESC',
  IntegrationsConnectionMinActiveAsc = 'INTEGRATIONS_CONNECTION_MIN_ACTIVE_ASC',
  IntegrationsConnectionMinActiveDesc = 'INTEGRATIONS_CONNECTION_MIN_ACTIVE_DESC',
  IntegrationsConnectionMinCompanyIdAsc = 'INTEGRATIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  IntegrationsConnectionMinCompanyIdDesc = 'INTEGRATIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  IntegrationsConnectionMinCreatedAtAsc = 'INTEGRATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  IntegrationsConnectionMinCreatedAtDesc = 'INTEGRATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  IntegrationsConnectionMinCreatedByAsc = 'INTEGRATIONS_CONNECTION_MIN_CREATED_BY_ASC',
  IntegrationsConnectionMinCreatedByDesc = 'INTEGRATIONS_CONNECTION_MIN_CREATED_BY_DESC',
  IntegrationsConnectionMinIdAsc = 'INTEGRATIONS_CONNECTION_MIN_ID_ASC',
  IntegrationsConnectionMinIdDesc = 'INTEGRATIONS_CONNECTION_MIN_ID_DESC',
  IntegrationsConnectionMinProviderAsc = 'INTEGRATIONS_CONNECTION_MIN_PROVIDER_ASC',
  IntegrationsConnectionMinProviderConfigAsc = 'INTEGRATIONS_CONNECTION_MIN_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionMinProviderConfigDesc = 'INTEGRATIONS_CONNECTION_MIN_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionMinProviderDesc = 'INTEGRATIONS_CONNECTION_MIN_PROVIDER_DESC',
  IntegrationsConnectionMinUuidAsc = 'INTEGRATIONS_CONNECTION_MIN_UUID_ASC',
  IntegrationsConnectionMinUuidDesc = 'INTEGRATIONS_CONNECTION_MIN_UUID_DESC',
  IntegrationsConnectionStddevPopulationActiveAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_ACTIVE_ASC',
  IntegrationsConnectionStddevPopulationActiveDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_ACTIVE_DESC',
  IntegrationsConnectionStddevPopulationCompanyIdAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  IntegrationsConnectionStddevPopulationCompanyIdDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  IntegrationsConnectionStddevPopulationCreatedAtAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  IntegrationsConnectionStddevPopulationCreatedAtDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  IntegrationsConnectionStddevPopulationCreatedByAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  IntegrationsConnectionStddevPopulationCreatedByDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  IntegrationsConnectionStddevPopulationIdAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  IntegrationsConnectionStddevPopulationIdDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  IntegrationsConnectionStddevPopulationProviderAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_PROVIDER_ASC',
  IntegrationsConnectionStddevPopulationProviderConfigAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionStddevPopulationProviderConfigDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionStddevPopulationProviderDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_PROVIDER_DESC',
  IntegrationsConnectionStddevPopulationUuidAsc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  IntegrationsConnectionStddevPopulationUuidDesc = 'INTEGRATIONS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  IntegrationsConnectionStddevSampleActiveAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_ACTIVE_ASC',
  IntegrationsConnectionStddevSampleActiveDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_ACTIVE_DESC',
  IntegrationsConnectionStddevSampleCompanyIdAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  IntegrationsConnectionStddevSampleCompanyIdDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  IntegrationsConnectionStddevSampleCreatedAtAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  IntegrationsConnectionStddevSampleCreatedAtDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  IntegrationsConnectionStddevSampleCreatedByAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  IntegrationsConnectionStddevSampleCreatedByDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  IntegrationsConnectionStddevSampleIdAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  IntegrationsConnectionStddevSampleIdDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  IntegrationsConnectionStddevSampleProviderAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ASC',
  IntegrationsConnectionStddevSampleProviderConfigAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionStddevSampleProviderConfigDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionStddevSampleProviderDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_PROVIDER_DESC',
  IntegrationsConnectionStddevSampleUuidAsc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  IntegrationsConnectionStddevSampleUuidDesc = 'INTEGRATIONS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  IntegrationsConnectionSumActiveAsc = 'INTEGRATIONS_CONNECTION_SUM_ACTIVE_ASC',
  IntegrationsConnectionSumActiveDesc = 'INTEGRATIONS_CONNECTION_SUM_ACTIVE_DESC',
  IntegrationsConnectionSumCompanyIdAsc = 'INTEGRATIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  IntegrationsConnectionSumCompanyIdDesc = 'INTEGRATIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  IntegrationsConnectionSumCreatedAtAsc = 'INTEGRATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  IntegrationsConnectionSumCreatedAtDesc = 'INTEGRATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  IntegrationsConnectionSumCreatedByAsc = 'INTEGRATIONS_CONNECTION_SUM_CREATED_BY_ASC',
  IntegrationsConnectionSumCreatedByDesc = 'INTEGRATIONS_CONNECTION_SUM_CREATED_BY_DESC',
  IntegrationsConnectionSumIdAsc = 'INTEGRATIONS_CONNECTION_SUM_ID_ASC',
  IntegrationsConnectionSumIdDesc = 'INTEGRATIONS_CONNECTION_SUM_ID_DESC',
  IntegrationsConnectionSumProviderAsc = 'INTEGRATIONS_CONNECTION_SUM_PROVIDER_ASC',
  IntegrationsConnectionSumProviderConfigAsc = 'INTEGRATIONS_CONNECTION_SUM_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionSumProviderConfigDesc = 'INTEGRATIONS_CONNECTION_SUM_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionSumProviderDesc = 'INTEGRATIONS_CONNECTION_SUM_PROVIDER_DESC',
  IntegrationsConnectionSumUuidAsc = 'INTEGRATIONS_CONNECTION_SUM_UUID_ASC',
  IntegrationsConnectionSumUuidDesc = 'INTEGRATIONS_CONNECTION_SUM_UUID_DESC',
  IntegrationsConnectionVariancePopulationActiveAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_ACTIVE_ASC',
  IntegrationsConnectionVariancePopulationActiveDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_ACTIVE_DESC',
  IntegrationsConnectionVariancePopulationCompanyIdAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  IntegrationsConnectionVariancePopulationCompanyIdDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  IntegrationsConnectionVariancePopulationCreatedAtAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  IntegrationsConnectionVariancePopulationCreatedAtDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  IntegrationsConnectionVariancePopulationCreatedByAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  IntegrationsConnectionVariancePopulationCreatedByDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  IntegrationsConnectionVariancePopulationIdAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  IntegrationsConnectionVariancePopulationIdDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  IntegrationsConnectionVariancePopulationProviderAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ASC',
  IntegrationsConnectionVariancePopulationProviderConfigAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionVariancePopulationProviderConfigDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionVariancePopulationProviderDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_PROVIDER_DESC',
  IntegrationsConnectionVariancePopulationUuidAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  IntegrationsConnectionVariancePopulationUuidDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  IntegrationsConnectionVarianceSampleActiveAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_ACTIVE_ASC',
  IntegrationsConnectionVarianceSampleActiveDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_ACTIVE_DESC',
  IntegrationsConnectionVarianceSampleCompanyIdAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  IntegrationsConnectionVarianceSampleCompanyIdDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  IntegrationsConnectionVarianceSampleCreatedAtAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IntegrationsConnectionVarianceSampleCreatedAtDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IntegrationsConnectionVarianceSampleCreatedByAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  IntegrationsConnectionVarianceSampleCreatedByDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  IntegrationsConnectionVarianceSampleIdAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  IntegrationsConnectionVarianceSampleIdDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  IntegrationsConnectionVarianceSampleProviderAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ASC',
  IntegrationsConnectionVarianceSampleProviderConfigAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_CONFIG_ASC',
  IntegrationsConnectionVarianceSampleProviderConfigDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_CONFIG_DESC',
  IntegrationsConnectionVarianceSampleProviderDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_DESC',
  IntegrationsConnectionVarianceSampleUuidAsc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  IntegrationsConnectionVarianceSampleUuidDesc = 'INTEGRATIONS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  JurisdictionsConnectionArrayAggAddressAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  JurisdictionsConnectionArrayAggAddressDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  JurisdictionsConnectionArrayAggCompanyIdAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  JurisdictionsConnectionArrayAggCompanyIdDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  JurisdictionsConnectionArrayAggCreatedAtAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  JurisdictionsConnectionArrayAggCreatedAtDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  JurisdictionsConnectionArrayAggCreatedByAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  JurisdictionsConnectionArrayAggCreatedByDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  JurisdictionsConnectionArrayAggEmailsAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_EMAILS_ASC',
  JurisdictionsConnectionArrayAggEmailsDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_EMAILS_DESC',
  JurisdictionsConnectionArrayAggFeesAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_FEES_ASC',
  JurisdictionsConnectionArrayAggFeesDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_FEES_DESC',
  JurisdictionsConnectionArrayAggIdAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  JurisdictionsConnectionArrayAggIdDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  JurisdictionsConnectionArrayAggIsPrivateAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_IS_PRIVATE_ASC',
  JurisdictionsConnectionArrayAggIsPrivateDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_IS_PRIVATE_DESC',
  JurisdictionsConnectionArrayAggNameAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_NAME_ASC',
  JurisdictionsConnectionArrayAggNameDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_NAME_DESC',
  JurisdictionsConnectionArrayAggNotesAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_NOTES_ASC',
  JurisdictionsConnectionArrayAggNotesDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_NOTES_DESC',
  JurisdictionsConnectionArrayAggPhonesAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_PHONES_ASC',
  JurisdictionsConnectionArrayAggPhonesDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_PHONES_DESC',
  JurisdictionsConnectionArrayAggPointOfContactAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionArrayAggPointOfContactDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionArrayAggPortalAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_PORTAL_ASC',
  JurisdictionsConnectionArrayAggPortalDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_PORTAL_DESC',
  JurisdictionsConnectionArrayAggSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionArrayAggSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionArrayAggTurnaroundAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_TURNAROUND_ASC',
  JurisdictionsConnectionArrayAggTurnaroundDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_TURNAROUND_DESC',
  JurisdictionsConnectionArrayAggTypeAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  JurisdictionsConnectionArrayAggTypeDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  JurisdictionsConnectionArrayAggUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  JurisdictionsConnectionArrayAggUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  JurisdictionsConnectionArrayAggUuidAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_UUID_ASC',
  JurisdictionsConnectionArrayAggUuidDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_UUID_DESC',
  JurisdictionsConnectionArrayAggWebsiteAsc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_WEBSITE_ASC',
  JurisdictionsConnectionArrayAggWebsiteDesc = 'JURISDICTIONS_CONNECTION_ARRAY_AGG_WEBSITE_DESC',
  JurisdictionsConnectionAverageAddressAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_ADDRESS_ASC',
  JurisdictionsConnectionAverageAddressDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_ADDRESS_DESC',
  JurisdictionsConnectionAverageCompanyIdAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  JurisdictionsConnectionAverageCompanyIdDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  JurisdictionsConnectionAverageCreatedAtAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  JurisdictionsConnectionAverageCreatedAtDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  JurisdictionsConnectionAverageCreatedByAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  JurisdictionsConnectionAverageCreatedByDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  JurisdictionsConnectionAverageEmailsAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_EMAILS_ASC',
  JurisdictionsConnectionAverageEmailsDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_EMAILS_DESC',
  JurisdictionsConnectionAverageFeesAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_FEES_ASC',
  JurisdictionsConnectionAverageFeesDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_FEES_DESC',
  JurisdictionsConnectionAverageIdAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_ID_ASC',
  JurisdictionsConnectionAverageIdDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_ID_DESC',
  JurisdictionsConnectionAverageIsPrivateAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_IS_PRIVATE_ASC',
  JurisdictionsConnectionAverageIsPrivateDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_IS_PRIVATE_DESC',
  JurisdictionsConnectionAverageNameAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_NAME_ASC',
  JurisdictionsConnectionAverageNameDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_NAME_DESC',
  JurisdictionsConnectionAverageNotesAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_NOTES_ASC',
  JurisdictionsConnectionAverageNotesDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_NOTES_DESC',
  JurisdictionsConnectionAveragePhonesAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_PHONES_ASC',
  JurisdictionsConnectionAveragePhonesDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_PHONES_DESC',
  JurisdictionsConnectionAveragePointOfContactAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionAveragePointOfContactDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionAveragePortalAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_PORTAL_ASC',
  JurisdictionsConnectionAveragePortalDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_PORTAL_DESC',
  JurisdictionsConnectionAverageSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionAverageSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionAverageTurnaroundAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_TURNAROUND_ASC',
  JurisdictionsConnectionAverageTurnaroundDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_TURNAROUND_DESC',
  JurisdictionsConnectionAverageTypeAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_TYPE_ASC',
  JurisdictionsConnectionAverageTypeDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_TYPE_DESC',
  JurisdictionsConnectionAverageUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  JurisdictionsConnectionAverageUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  JurisdictionsConnectionAverageUuidAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_UUID_ASC',
  JurisdictionsConnectionAverageUuidDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_UUID_DESC',
  JurisdictionsConnectionAverageWebsiteAsc = 'JURISDICTIONS_CONNECTION_AVERAGE_WEBSITE_ASC',
  JurisdictionsConnectionAverageWebsiteDesc = 'JURISDICTIONS_CONNECTION_AVERAGE_WEBSITE_DESC',
  JurisdictionsConnectionCountAsc = 'JURISDICTIONS_CONNECTION_COUNT_ASC',
  JurisdictionsConnectionCountDesc = 'JURISDICTIONS_CONNECTION_COUNT_DESC',
  JurisdictionsConnectionDistinctCountAddressAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  JurisdictionsConnectionDistinctCountAddressDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  JurisdictionsConnectionDistinctCountCompanyIdAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  JurisdictionsConnectionDistinctCountCompanyIdDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  JurisdictionsConnectionDistinctCountCreatedAtAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  JurisdictionsConnectionDistinctCountCreatedAtDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  JurisdictionsConnectionDistinctCountCreatedByAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  JurisdictionsConnectionDistinctCountCreatedByDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  JurisdictionsConnectionDistinctCountEmailsAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_EMAILS_ASC',
  JurisdictionsConnectionDistinctCountEmailsDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_EMAILS_DESC',
  JurisdictionsConnectionDistinctCountFeesAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_FEES_ASC',
  JurisdictionsConnectionDistinctCountFeesDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_FEES_DESC',
  JurisdictionsConnectionDistinctCountIdAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  JurisdictionsConnectionDistinctCountIdDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  JurisdictionsConnectionDistinctCountIsPrivateAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_IS_PRIVATE_ASC',
  JurisdictionsConnectionDistinctCountIsPrivateDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_IS_PRIVATE_DESC',
  JurisdictionsConnectionDistinctCountNameAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  JurisdictionsConnectionDistinctCountNameDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  JurisdictionsConnectionDistinctCountNotesAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  JurisdictionsConnectionDistinctCountNotesDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  JurisdictionsConnectionDistinctCountPhonesAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_PHONES_ASC',
  JurisdictionsConnectionDistinctCountPhonesDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_PHONES_DESC',
  JurisdictionsConnectionDistinctCountPointOfContactAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionDistinctCountPointOfContactDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionDistinctCountPortalAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_PORTAL_ASC',
  JurisdictionsConnectionDistinctCountPortalDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_PORTAL_DESC',
  JurisdictionsConnectionDistinctCountSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionDistinctCountSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionDistinctCountTurnaroundAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_TURNAROUND_ASC',
  JurisdictionsConnectionDistinctCountTurnaroundDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_TURNAROUND_DESC',
  JurisdictionsConnectionDistinctCountTypeAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  JurisdictionsConnectionDistinctCountTypeDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  JurisdictionsConnectionDistinctCountUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  JurisdictionsConnectionDistinctCountUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  JurisdictionsConnectionDistinctCountUuidAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  JurisdictionsConnectionDistinctCountUuidDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  JurisdictionsConnectionDistinctCountWebsiteAsc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_WEBSITE_ASC',
  JurisdictionsConnectionDistinctCountWebsiteDesc = 'JURISDICTIONS_CONNECTION_DISTINCT_COUNT_WEBSITE_DESC',
  JurisdictionsConnectionMaxAddressAsc = 'JURISDICTIONS_CONNECTION_MAX_ADDRESS_ASC',
  JurisdictionsConnectionMaxAddressDesc = 'JURISDICTIONS_CONNECTION_MAX_ADDRESS_DESC',
  JurisdictionsConnectionMaxCompanyIdAsc = 'JURISDICTIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  JurisdictionsConnectionMaxCompanyIdDesc = 'JURISDICTIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  JurisdictionsConnectionMaxCreatedAtAsc = 'JURISDICTIONS_CONNECTION_MAX_CREATED_AT_ASC',
  JurisdictionsConnectionMaxCreatedAtDesc = 'JURISDICTIONS_CONNECTION_MAX_CREATED_AT_DESC',
  JurisdictionsConnectionMaxCreatedByAsc = 'JURISDICTIONS_CONNECTION_MAX_CREATED_BY_ASC',
  JurisdictionsConnectionMaxCreatedByDesc = 'JURISDICTIONS_CONNECTION_MAX_CREATED_BY_DESC',
  JurisdictionsConnectionMaxEmailsAsc = 'JURISDICTIONS_CONNECTION_MAX_EMAILS_ASC',
  JurisdictionsConnectionMaxEmailsDesc = 'JURISDICTIONS_CONNECTION_MAX_EMAILS_DESC',
  JurisdictionsConnectionMaxFeesAsc = 'JURISDICTIONS_CONNECTION_MAX_FEES_ASC',
  JurisdictionsConnectionMaxFeesDesc = 'JURISDICTIONS_CONNECTION_MAX_FEES_DESC',
  JurisdictionsConnectionMaxIdAsc = 'JURISDICTIONS_CONNECTION_MAX_ID_ASC',
  JurisdictionsConnectionMaxIdDesc = 'JURISDICTIONS_CONNECTION_MAX_ID_DESC',
  JurisdictionsConnectionMaxIsPrivateAsc = 'JURISDICTIONS_CONNECTION_MAX_IS_PRIVATE_ASC',
  JurisdictionsConnectionMaxIsPrivateDesc = 'JURISDICTIONS_CONNECTION_MAX_IS_PRIVATE_DESC',
  JurisdictionsConnectionMaxNameAsc = 'JURISDICTIONS_CONNECTION_MAX_NAME_ASC',
  JurisdictionsConnectionMaxNameDesc = 'JURISDICTIONS_CONNECTION_MAX_NAME_DESC',
  JurisdictionsConnectionMaxNotesAsc = 'JURISDICTIONS_CONNECTION_MAX_NOTES_ASC',
  JurisdictionsConnectionMaxNotesDesc = 'JURISDICTIONS_CONNECTION_MAX_NOTES_DESC',
  JurisdictionsConnectionMaxPhonesAsc = 'JURISDICTIONS_CONNECTION_MAX_PHONES_ASC',
  JurisdictionsConnectionMaxPhonesDesc = 'JURISDICTIONS_CONNECTION_MAX_PHONES_DESC',
  JurisdictionsConnectionMaxPointOfContactAsc = 'JURISDICTIONS_CONNECTION_MAX_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionMaxPointOfContactDesc = 'JURISDICTIONS_CONNECTION_MAX_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionMaxPortalAsc = 'JURISDICTIONS_CONNECTION_MAX_PORTAL_ASC',
  JurisdictionsConnectionMaxPortalDesc = 'JURISDICTIONS_CONNECTION_MAX_PORTAL_DESC',
  JurisdictionsConnectionMaxSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_MAX_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionMaxSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_MAX_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionMaxTurnaroundAsc = 'JURISDICTIONS_CONNECTION_MAX_TURNAROUND_ASC',
  JurisdictionsConnectionMaxTurnaroundDesc = 'JURISDICTIONS_CONNECTION_MAX_TURNAROUND_DESC',
  JurisdictionsConnectionMaxTypeAsc = 'JURISDICTIONS_CONNECTION_MAX_TYPE_ASC',
  JurisdictionsConnectionMaxTypeDesc = 'JURISDICTIONS_CONNECTION_MAX_TYPE_DESC',
  JurisdictionsConnectionMaxUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  JurisdictionsConnectionMaxUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  JurisdictionsConnectionMaxUuidAsc = 'JURISDICTIONS_CONNECTION_MAX_UUID_ASC',
  JurisdictionsConnectionMaxUuidDesc = 'JURISDICTIONS_CONNECTION_MAX_UUID_DESC',
  JurisdictionsConnectionMaxWebsiteAsc = 'JURISDICTIONS_CONNECTION_MAX_WEBSITE_ASC',
  JurisdictionsConnectionMaxWebsiteDesc = 'JURISDICTIONS_CONNECTION_MAX_WEBSITE_DESC',
  JurisdictionsConnectionMinAddressAsc = 'JURISDICTIONS_CONNECTION_MIN_ADDRESS_ASC',
  JurisdictionsConnectionMinAddressDesc = 'JURISDICTIONS_CONNECTION_MIN_ADDRESS_DESC',
  JurisdictionsConnectionMinCompanyIdAsc = 'JURISDICTIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  JurisdictionsConnectionMinCompanyIdDesc = 'JURISDICTIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  JurisdictionsConnectionMinCreatedAtAsc = 'JURISDICTIONS_CONNECTION_MIN_CREATED_AT_ASC',
  JurisdictionsConnectionMinCreatedAtDesc = 'JURISDICTIONS_CONNECTION_MIN_CREATED_AT_DESC',
  JurisdictionsConnectionMinCreatedByAsc = 'JURISDICTIONS_CONNECTION_MIN_CREATED_BY_ASC',
  JurisdictionsConnectionMinCreatedByDesc = 'JURISDICTIONS_CONNECTION_MIN_CREATED_BY_DESC',
  JurisdictionsConnectionMinEmailsAsc = 'JURISDICTIONS_CONNECTION_MIN_EMAILS_ASC',
  JurisdictionsConnectionMinEmailsDesc = 'JURISDICTIONS_CONNECTION_MIN_EMAILS_DESC',
  JurisdictionsConnectionMinFeesAsc = 'JURISDICTIONS_CONNECTION_MIN_FEES_ASC',
  JurisdictionsConnectionMinFeesDesc = 'JURISDICTIONS_CONNECTION_MIN_FEES_DESC',
  JurisdictionsConnectionMinIdAsc = 'JURISDICTIONS_CONNECTION_MIN_ID_ASC',
  JurisdictionsConnectionMinIdDesc = 'JURISDICTIONS_CONNECTION_MIN_ID_DESC',
  JurisdictionsConnectionMinIsPrivateAsc = 'JURISDICTIONS_CONNECTION_MIN_IS_PRIVATE_ASC',
  JurisdictionsConnectionMinIsPrivateDesc = 'JURISDICTIONS_CONNECTION_MIN_IS_PRIVATE_DESC',
  JurisdictionsConnectionMinNameAsc = 'JURISDICTIONS_CONNECTION_MIN_NAME_ASC',
  JurisdictionsConnectionMinNameDesc = 'JURISDICTIONS_CONNECTION_MIN_NAME_DESC',
  JurisdictionsConnectionMinNotesAsc = 'JURISDICTIONS_CONNECTION_MIN_NOTES_ASC',
  JurisdictionsConnectionMinNotesDesc = 'JURISDICTIONS_CONNECTION_MIN_NOTES_DESC',
  JurisdictionsConnectionMinPhonesAsc = 'JURISDICTIONS_CONNECTION_MIN_PHONES_ASC',
  JurisdictionsConnectionMinPhonesDesc = 'JURISDICTIONS_CONNECTION_MIN_PHONES_DESC',
  JurisdictionsConnectionMinPointOfContactAsc = 'JURISDICTIONS_CONNECTION_MIN_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionMinPointOfContactDesc = 'JURISDICTIONS_CONNECTION_MIN_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionMinPortalAsc = 'JURISDICTIONS_CONNECTION_MIN_PORTAL_ASC',
  JurisdictionsConnectionMinPortalDesc = 'JURISDICTIONS_CONNECTION_MIN_PORTAL_DESC',
  JurisdictionsConnectionMinSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_MIN_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionMinSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_MIN_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionMinTurnaroundAsc = 'JURISDICTIONS_CONNECTION_MIN_TURNAROUND_ASC',
  JurisdictionsConnectionMinTurnaroundDesc = 'JURISDICTIONS_CONNECTION_MIN_TURNAROUND_DESC',
  JurisdictionsConnectionMinTypeAsc = 'JURISDICTIONS_CONNECTION_MIN_TYPE_ASC',
  JurisdictionsConnectionMinTypeDesc = 'JURISDICTIONS_CONNECTION_MIN_TYPE_DESC',
  JurisdictionsConnectionMinUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  JurisdictionsConnectionMinUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  JurisdictionsConnectionMinUuidAsc = 'JURISDICTIONS_CONNECTION_MIN_UUID_ASC',
  JurisdictionsConnectionMinUuidDesc = 'JURISDICTIONS_CONNECTION_MIN_UUID_DESC',
  JurisdictionsConnectionMinWebsiteAsc = 'JURISDICTIONS_CONNECTION_MIN_WEBSITE_ASC',
  JurisdictionsConnectionMinWebsiteDesc = 'JURISDICTIONS_CONNECTION_MIN_WEBSITE_DESC',
  JurisdictionsConnectionStddevPopulationAddressAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  JurisdictionsConnectionStddevPopulationAddressDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  JurisdictionsConnectionStddevPopulationCompanyIdAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  JurisdictionsConnectionStddevPopulationCompanyIdDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  JurisdictionsConnectionStddevPopulationCreatedAtAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  JurisdictionsConnectionStddevPopulationCreatedAtDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  JurisdictionsConnectionStddevPopulationCreatedByAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  JurisdictionsConnectionStddevPopulationCreatedByDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  JurisdictionsConnectionStddevPopulationEmailsAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_EMAILS_ASC',
  JurisdictionsConnectionStddevPopulationEmailsDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_EMAILS_DESC',
  JurisdictionsConnectionStddevPopulationFeesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_FEES_ASC',
  JurisdictionsConnectionStddevPopulationFeesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_FEES_DESC',
  JurisdictionsConnectionStddevPopulationIdAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  JurisdictionsConnectionStddevPopulationIdDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  JurisdictionsConnectionStddevPopulationIsPrivateAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_IS_PRIVATE_ASC',
  JurisdictionsConnectionStddevPopulationIsPrivateDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_IS_PRIVATE_DESC',
  JurisdictionsConnectionStddevPopulationNameAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  JurisdictionsConnectionStddevPopulationNameDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  JurisdictionsConnectionStddevPopulationNotesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  JurisdictionsConnectionStddevPopulationNotesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  JurisdictionsConnectionStddevPopulationPhonesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_PHONES_ASC',
  JurisdictionsConnectionStddevPopulationPhonesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_PHONES_DESC',
  JurisdictionsConnectionStddevPopulationPointOfContactAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionStddevPopulationPointOfContactDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionStddevPopulationPortalAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_PORTAL_ASC',
  JurisdictionsConnectionStddevPopulationPortalDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_PORTAL_DESC',
  JurisdictionsConnectionStddevPopulationSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionStddevPopulationSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionStddevPopulationTurnaroundAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_TURNAROUND_ASC',
  JurisdictionsConnectionStddevPopulationTurnaroundDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_TURNAROUND_DESC',
  JurisdictionsConnectionStddevPopulationTypeAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  JurisdictionsConnectionStddevPopulationTypeDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  JurisdictionsConnectionStddevPopulationUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  JurisdictionsConnectionStddevPopulationUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  JurisdictionsConnectionStddevPopulationUuidAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  JurisdictionsConnectionStddevPopulationUuidDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  JurisdictionsConnectionStddevPopulationWebsiteAsc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_WEBSITE_ASC',
  JurisdictionsConnectionStddevPopulationWebsiteDesc = 'JURISDICTIONS_CONNECTION_STDDEV_POPULATION_WEBSITE_DESC',
  JurisdictionsConnectionStddevSampleAddressAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  JurisdictionsConnectionStddevSampleAddressDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  JurisdictionsConnectionStddevSampleCompanyIdAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  JurisdictionsConnectionStddevSampleCompanyIdDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  JurisdictionsConnectionStddevSampleCreatedAtAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  JurisdictionsConnectionStddevSampleCreatedAtDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  JurisdictionsConnectionStddevSampleCreatedByAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  JurisdictionsConnectionStddevSampleCreatedByDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  JurisdictionsConnectionStddevSampleEmailsAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_EMAILS_ASC',
  JurisdictionsConnectionStddevSampleEmailsDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_EMAILS_DESC',
  JurisdictionsConnectionStddevSampleFeesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_FEES_ASC',
  JurisdictionsConnectionStddevSampleFeesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_FEES_DESC',
  JurisdictionsConnectionStddevSampleIdAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  JurisdictionsConnectionStddevSampleIdDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  JurisdictionsConnectionStddevSampleIsPrivateAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_IS_PRIVATE_ASC',
  JurisdictionsConnectionStddevSampleIsPrivateDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_IS_PRIVATE_DESC',
  JurisdictionsConnectionStddevSampleNameAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  JurisdictionsConnectionStddevSampleNameDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  JurisdictionsConnectionStddevSampleNotesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  JurisdictionsConnectionStddevSampleNotesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  JurisdictionsConnectionStddevSamplePhonesAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_PHONES_ASC',
  JurisdictionsConnectionStddevSamplePhonesDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_PHONES_DESC',
  JurisdictionsConnectionStddevSamplePointOfContactAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionStddevSamplePointOfContactDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionStddevSamplePortalAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_PORTAL_ASC',
  JurisdictionsConnectionStddevSamplePortalDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_PORTAL_DESC',
  JurisdictionsConnectionStddevSampleSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionStddevSampleSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionStddevSampleTurnaroundAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_TURNAROUND_ASC',
  JurisdictionsConnectionStddevSampleTurnaroundDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_TURNAROUND_DESC',
  JurisdictionsConnectionStddevSampleTypeAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  JurisdictionsConnectionStddevSampleTypeDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  JurisdictionsConnectionStddevSampleUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  JurisdictionsConnectionStddevSampleUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  JurisdictionsConnectionStddevSampleUuidAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  JurisdictionsConnectionStddevSampleUuidDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  JurisdictionsConnectionStddevSampleWebsiteAsc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_WEBSITE_ASC',
  JurisdictionsConnectionStddevSampleWebsiteDesc = 'JURISDICTIONS_CONNECTION_STDDEV_SAMPLE_WEBSITE_DESC',
  JurisdictionsConnectionSumAddressAsc = 'JURISDICTIONS_CONNECTION_SUM_ADDRESS_ASC',
  JurisdictionsConnectionSumAddressDesc = 'JURISDICTIONS_CONNECTION_SUM_ADDRESS_DESC',
  JurisdictionsConnectionSumCompanyIdAsc = 'JURISDICTIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  JurisdictionsConnectionSumCompanyIdDesc = 'JURISDICTIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  JurisdictionsConnectionSumCreatedAtAsc = 'JURISDICTIONS_CONNECTION_SUM_CREATED_AT_ASC',
  JurisdictionsConnectionSumCreatedAtDesc = 'JURISDICTIONS_CONNECTION_SUM_CREATED_AT_DESC',
  JurisdictionsConnectionSumCreatedByAsc = 'JURISDICTIONS_CONNECTION_SUM_CREATED_BY_ASC',
  JurisdictionsConnectionSumCreatedByDesc = 'JURISDICTIONS_CONNECTION_SUM_CREATED_BY_DESC',
  JurisdictionsConnectionSumEmailsAsc = 'JURISDICTIONS_CONNECTION_SUM_EMAILS_ASC',
  JurisdictionsConnectionSumEmailsDesc = 'JURISDICTIONS_CONNECTION_SUM_EMAILS_DESC',
  JurisdictionsConnectionSumFeesAsc = 'JURISDICTIONS_CONNECTION_SUM_FEES_ASC',
  JurisdictionsConnectionSumFeesDesc = 'JURISDICTIONS_CONNECTION_SUM_FEES_DESC',
  JurisdictionsConnectionSumIdAsc = 'JURISDICTIONS_CONNECTION_SUM_ID_ASC',
  JurisdictionsConnectionSumIdDesc = 'JURISDICTIONS_CONNECTION_SUM_ID_DESC',
  JurisdictionsConnectionSumIsPrivateAsc = 'JURISDICTIONS_CONNECTION_SUM_IS_PRIVATE_ASC',
  JurisdictionsConnectionSumIsPrivateDesc = 'JURISDICTIONS_CONNECTION_SUM_IS_PRIVATE_DESC',
  JurisdictionsConnectionSumNameAsc = 'JURISDICTIONS_CONNECTION_SUM_NAME_ASC',
  JurisdictionsConnectionSumNameDesc = 'JURISDICTIONS_CONNECTION_SUM_NAME_DESC',
  JurisdictionsConnectionSumNotesAsc = 'JURISDICTIONS_CONNECTION_SUM_NOTES_ASC',
  JurisdictionsConnectionSumNotesDesc = 'JURISDICTIONS_CONNECTION_SUM_NOTES_DESC',
  JurisdictionsConnectionSumPhonesAsc = 'JURISDICTIONS_CONNECTION_SUM_PHONES_ASC',
  JurisdictionsConnectionSumPhonesDesc = 'JURISDICTIONS_CONNECTION_SUM_PHONES_DESC',
  JurisdictionsConnectionSumPointOfContactAsc = 'JURISDICTIONS_CONNECTION_SUM_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionSumPointOfContactDesc = 'JURISDICTIONS_CONNECTION_SUM_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionSumPortalAsc = 'JURISDICTIONS_CONNECTION_SUM_PORTAL_ASC',
  JurisdictionsConnectionSumPortalDesc = 'JURISDICTIONS_CONNECTION_SUM_PORTAL_DESC',
  JurisdictionsConnectionSumSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_SUM_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionSumSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_SUM_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionSumTurnaroundAsc = 'JURISDICTIONS_CONNECTION_SUM_TURNAROUND_ASC',
  JurisdictionsConnectionSumTurnaroundDesc = 'JURISDICTIONS_CONNECTION_SUM_TURNAROUND_DESC',
  JurisdictionsConnectionSumTypeAsc = 'JURISDICTIONS_CONNECTION_SUM_TYPE_ASC',
  JurisdictionsConnectionSumTypeDesc = 'JURISDICTIONS_CONNECTION_SUM_TYPE_DESC',
  JurisdictionsConnectionSumUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  JurisdictionsConnectionSumUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  JurisdictionsConnectionSumUuidAsc = 'JURISDICTIONS_CONNECTION_SUM_UUID_ASC',
  JurisdictionsConnectionSumUuidDesc = 'JURISDICTIONS_CONNECTION_SUM_UUID_DESC',
  JurisdictionsConnectionSumWebsiteAsc = 'JURISDICTIONS_CONNECTION_SUM_WEBSITE_ASC',
  JurisdictionsConnectionSumWebsiteDesc = 'JURISDICTIONS_CONNECTION_SUM_WEBSITE_DESC',
  JurisdictionsConnectionVariancePopulationAddressAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  JurisdictionsConnectionVariancePopulationAddressDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  JurisdictionsConnectionVariancePopulationCompanyIdAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  JurisdictionsConnectionVariancePopulationCompanyIdDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  JurisdictionsConnectionVariancePopulationCreatedAtAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  JurisdictionsConnectionVariancePopulationCreatedAtDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  JurisdictionsConnectionVariancePopulationCreatedByAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  JurisdictionsConnectionVariancePopulationCreatedByDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  JurisdictionsConnectionVariancePopulationEmailsAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_EMAILS_ASC',
  JurisdictionsConnectionVariancePopulationEmailsDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_EMAILS_DESC',
  JurisdictionsConnectionVariancePopulationFeesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_FEES_ASC',
  JurisdictionsConnectionVariancePopulationFeesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_FEES_DESC',
  JurisdictionsConnectionVariancePopulationIdAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  JurisdictionsConnectionVariancePopulationIdDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  JurisdictionsConnectionVariancePopulationIsPrivateAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_IS_PRIVATE_ASC',
  JurisdictionsConnectionVariancePopulationIsPrivateDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_IS_PRIVATE_DESC',
  JurisdictionsConnectionVariancePopulationNameAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  JurisdictionsConnectionVariancePopulationNameDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  JurisdictionsConnectionVariancePopulationNotesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  JurisdictionsConnectionVariancePopulationNotesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  JurisdictionsConnectionVariancePopulationPhonesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_PHONES_ASC',
  JurisdictionsConnectionVariancePopulationPhonesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_PHONES_DESC',
  JurisdictionsConnectionVariancePopulationPointOfContactAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionVariancePopulationPointOfContactDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionVariancePopulationPortalAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_PORTAL_ASC',
  JurisdictionsConnectionVariancePopulationPortalDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_PORTAL_DESC',
  JurisdictionsConnectionVariancePopulationSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionVariancePopulationSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionVariancePopulationTurnaroundAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_TURNAROUND_ASC',
  JurisdictionsConnectionVariancePopulationTurnaroundDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_TURNAROUND_DESC',
  JurisdictionsConnectionVariancePopulationTypeAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  JurisdictionsConnectionVariancePopulationTypeDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  JurisdictionsConnectionVariancePopulationUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  JurisdictionsConnectionVariancePopulationUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  JurisdictionsConnectionVariancePopulationUuidAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  JurisdictionsConnectionVariancePopulationUuidDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  JurisdictionsConnectionVariancePopulationWebsiteAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_WEBSITE_ASC',
  JurisdictionsConnectionVariancePopulationWebsiteDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_POPULATION_WEBSITE_DESC',
  JurisdictionsConnectionVarianceSampleAddressAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  JurisdictionsConnectionVarianceSampleAddressDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  JurisdictionsConnectionVarianceSampleCompanyIdAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  JurisdictionsConnectionVarianceSampleCompanyIdDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  JurisdictionsConnectionVarianceSampleCreatedAtAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  JurisdictionsConnectionVarianceSampleCreatedAtDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  JurisdictionsConnectionVarianceSampleCreatedByAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  JurisdictionsConnectionVarianceSampleCreatedByDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  JurisdictionsConnectionVarianceSampleEmailsAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_EMAILS_ASC',
  JurisdictionsConnectionVarianceSampleEmailsDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_EMAILS_DESC',
  JurisdictionsConnectionVarianceSampleFeesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_FEES_ASC',
  JurisdictionsConnectionVarianceSampleFeesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_FEES_DESC',
  JurisdictionsConnectionVarianceSampleIdAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  JurisdictionsConnectionVarianceSampleIdDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  JurisdictionsConnectionVarianceSampleIsPrivateAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_IS_PRIVATE_ASC',
  JurisdictionsConnectionVarianceSampleIsPrivateDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_IS_PRIVATE_DESC',
  JurisdictionsConnectionVarianceSampleNameAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  JurisdictionsConnectionVarianceSampleNameDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  JurisdictionsConnectionVarianceSampleNotesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  JurisdictionsConnectionVarianceSampleNotesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  JurisdictionsConnectionVarianceSamplePhonesAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_PHONES_ASC',
  JurisdictionsConnectionVarianceSamplePhonesDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_PHONES_DESC',
  JurisdictionsConnectionVarianceSamplePointOfContactAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_POINT_OF_CONTACT_ASC',
  JurisdictionsConnectionVarianceSamplePointOfContactDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_POINT_OF_CONTACT_DESC',
  JurisdictionsConnectionVarianceSamplePortalAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_PORTAL_ASC',
  JurisdictionsConnectionVarianceSamplePortalDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_PORTAL_DESC',
  JurisdictionsConnectionVarianceSampleSubmissionMethodAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_SUBMISSION_METHOD_ASC',
  JurisdictionsConnectionVarianceSampleSubmissionMethodDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_SUBMISSION_METHOD_DESC',
  JurisdictionsConnectionVarianceSampleTurnaroundAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_TURNAROUND_ASC',
  JurisdictionsConnectionVarianceSampleTurnaroundDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_TURNAROUND_DESC',
  JurisdictionsConnectionVarianceSampleTypeAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  JurisdictionsConnectionVarianceSampleTypeDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  JurisdictionsConnectionVarianceSampleUpdatedAtAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  JurisdictionsConnectionVarianceSampleUpdatedAtDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  JurisdictionsConnectionVarianceSampleUuidAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  JurisdictionsConnectionVarianceSampleUuidDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  JurisdictionsConnectionVarianceSampleWebsiteAsc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_WEBSITE_ASC',
  JurisdictionsConnectionVarianceSampleWebsiteDesc = 'JURISDICTIONS_CONNECTION_VARIANCE_SAMPLE_WEBSITE_DESC',
  LogoUrlAsc = 'LOGO_URL_ASC',
  LogoUrlDesc = 'LOGO_URL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegedTasksConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksConnectionAverageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksConnectionAverageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksConnectionAverageUidAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksConnectionAverageUidDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionCountAsc = 'PRIVILEGED_TASKS_CONNECTION_COUNT_ASC',
  PrivilegedTasksConnectionCountDesc = 'PRIVILEGED_TASKS_CONNECTION_COUNT_DESC',
  PrivilegedTasksConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksConnectionMaxIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksConnectionMaxIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksConnectionMaxUidAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksConnectionMaxUidDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionMinAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksConnectionMinAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionMinFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksConnectionMinFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksConnectionMinIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksConnectionMinIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionMinPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksConnectionMinPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksConnectionMinStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksConnectionMinStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionMinTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksConnectionMinTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksConnectionMinUidAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksConnectionMinUidDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionSumAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksConnectionSumAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionSumFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksConnectionSumFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksConnectionSumIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksConnectionSumIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionSumPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksConnectionSumPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksConnectionSumStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksConnectionSumStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionSumTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksConnectionSumTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksConnectionSumUidAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksConnectionSumUidDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  ProjectsConnectionArrayAggAccountStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsConnectionArrayAggAccountStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsConnectionArrayAggAccountTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsConnectionArrayAggAccountTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsConnectionArrayAggAddressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsConnectionArrayAggAddressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionArrayAggAddressPrettyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsConnectionArrayAggAddressPrettyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsConnectionArrayAggAhjAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsConnectionArrayAggAhjDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsConnectionArrayAggBlueprintIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsConnectionArrayAggBlueprintIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsConnectionArrayAggCityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsConnectionArrayAggCityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsConnectionArrayAggCompanyIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsConnectionArrayAggCompanyIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsConnectionArrayAggCompletionDateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsConnectionArrayAggCompletionDateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsConnectionArrayAggCompletionTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsConnectionArrayAggCompletionTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionArrayAggConfidenceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsConnectionArrayAggConfidenceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsConnectionArrayAggCreatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsConnectionArrayAggCreatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsConnectionArrayAggCreatedByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggCreatedByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsConnectionArrayAggDealSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsConnectionArrayAggDealSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsConnectionArrayAggDealValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsConnectionArrayAggDealValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionArrayAggDescriptionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsConnectionArrayAggDescriptionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsConnectionArrayAggFreshProjectAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsConnectionArrayAggFreshProjectDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsConnectionArrayAggGeoLocationAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsConnectionArrayAggGeoLocationDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionArrayAggIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsConnectionArrayAggIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsConnectionArrayAggImageUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsConnectionArrayAggImageUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsConnectionArrayAggIsActiveAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsConnectionArrayAggIsActiveDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsConnectionArrayAggIsCompletedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsConnectionArrayAggIsCompletedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsConnectionArrayAggLastActivityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsConnectionArrayAggLastActivityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggOverdueByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsConnectionArrayAggOverdueByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsConnectionArrayAggOwnerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsConnectionArrayAggOwnerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsConnectionArrayAggParentProjectIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionArrayAggParentProjectIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionArrayAggPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsConnectionArrayAggPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionArrayAggProgressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsConnectionArrayAggProgressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionArrayAggProjectSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsConnectionArrayAggProjectSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsConnectionArrayAggProjectValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsConnectionArrayAggProjectValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggRequestStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsConnectionArrayAggRequestStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsConnectionArrayAggRevenueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsConnectionArrayAggRevenueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsConnectionArrayAggSalesRepIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsConnectionArrayAggSalesRepIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSourceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsConnectionArrayAggSourceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsConnectionArrayAggStageIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsConnectionArrayAggStageIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsConnectionArrayAggStateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsConnectionArrayAggStateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsConnectionArrayAggStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsConnectionArrayAggStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsConnectionArrayAggStreetAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsConnectionArrayAggStreetDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsConnectionArrayAggTitleAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsConnectionArrayAggTitleDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsConnectionArrayAggTotalCostAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsConnectionArrayAggTotalCostDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalPriceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsConnectionArrayAggTotalPriceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsConnectionArrayAggTradesAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsConnectionArrayAggTradesDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsConnectionArrayAggTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsConnectionArrayAggTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsConnectionArrayAggUidAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsConnectionArrayAggUidDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsConnectionArrayAggUpdatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsConnectionArrayAggUpdatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsConnectionArrayAggZipcodeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsConnectionArrayAggZipcodeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsConnectionAverageAccountStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionAverageAccountStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionAverageAccountTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionAverageAccountTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionAverageAddressAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsConnectionAverageAddressDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsConnectionAverageAddressNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionAverageAddressNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionAverageAddressPrettyAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionAverageAddressPrettyDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionAverageAhjAsc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsConnectionAverageAhjDesc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsConnectionAverageBlueprintIdAsc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsConnectionAverageBlueprintIdDesc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsConnectionAverageCityAsc = 'PROJECTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsConnectionAverageCityDesc = 'PROJECTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsConnectionAverageCompanyIdAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsConnectionAverageCompanyIdDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsConnectionAverageCompletionDateAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsConnectionAverageCompletionDateDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsConnectionAverageCompletionTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsConnectionAverageCompletionTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsConnectionAverageCompletionTimeInSAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionAverageCompletionTimeInSDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionAverageConfidenceAsc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsConnectionAverageConfidenceDesc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsConnectionAverageCreatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsConnectionAverageCreatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsConnectionAverageCreatedByAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsConnectionAverageCreatedByContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionAverageCreatedByContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionAverageCreatedByDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsConnectionAverageCreatedByTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionAverageCreatedByTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionAverageDealSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsConnectionAverageDealSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsConnectionAverageDealValueAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsConnectionAverageDealValueDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsConnectionAverageDealValueWeightedAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionAverageDealValueWeightedDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionAverageDescriptionAsc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsConnectionAverageDescriptionDesc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsConnectionAverageFreshProjectAsc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsConnectionAverageFreshProjectDesc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsConnectionAverageGeoLocationAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsConnectionAverageGeoLocationDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionAverageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectsConnectionAverageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectsConnectionAverageImageUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsConnectionAverageImageUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsConnectionAverageIsActiveAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsConnectionAverageIsActiveDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsConnectionAverageIsCompletedAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsConnectionAverageIsCompletedDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsConnectionAverageJurisdictionIdAsc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsConnectionAverageJurisdictionIdDesc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsConnectionAverageLastActivityAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsConnectionAverageLastActivityDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageOverdueByAsc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsConnectionAverageOverdueByDesc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsConnectionAverageOwnerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsConnectionAverageOwnerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsConnectionAverageParentProjectIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionAverageParentProjectIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionAveragePhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsConnectionAveragePhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsConnectionAveragePrimaryEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionAveragePrimaryEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionAveragePrimaryPhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsConnectionAveragePrimaryPhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsConnectionAveragePrivilegeAllAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionAveragePrivilegeAllDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionAveragePrivilegeOwnAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionAveragePrivilegeOwnDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionAveragePrivilegeTeamAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionAveragePrivilegeTeamDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionAverageProgressAsc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsConnectionAverageProgressDesc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsConnectionAverageProjectManagerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionAverageProjectManagerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionAverageProjectSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsConnectionAverageProjectSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsConnectionAverageProjectValueAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsConnectionAverageProjectValueDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsConnectionAverageReferrerContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionAverageReferrerContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionAverageRequestStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsConnectionAverageRequestStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsConnectionAverageRevenueAsc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsConnectionAverageRevenueDesc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsConnectionAverageSalesRepIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsConnectionAverageSalesRepIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSourceAsc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsConnectionAverageSourceDesc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsConnectionAverageStageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsConnectionAverageStageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsConnectionAverageStateAsc = 'PROJECTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsConnectionAverageStateDesc = 'PROJECTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsConnectionAverageStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsConnectionAverageStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsConnectionAverageStreetAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsConnectionAverageStreetDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsConnectionAverageStreetViewUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsConnectionAverageStreetViewUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsConnectionAverageTitleAsc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsConnectionAverageTitleDesc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsConnectionAverageTotalCostAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsConnectionAverageTotalCostDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalPriceAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsConnectionAverageTotalPriceDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsConnectionAverageTradesAsc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsConnectionAverageTradesDesc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsConnectionAverageTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsConnectionAverageTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsConnectionAverageUidAsc = 'PROJECTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectsConnectionAverageUidDesc = 'PROJECTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectsConnectionAverageUpdatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsConnectionAverageUpdatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsConnectionAverageZipcodeAsc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsConnectionAverageZipcodeDesc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsConnectionCountAsc = 'PROJECTS_CONNECTION_COUNT_ASC',
  ProjectsConnectionCountDesc = 'PROJECTS_CONNECTION_COUNT_DESC',
  ProjectsConnectionDistinctCountAccountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountAccountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountAccountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountAccountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountAddressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsConnectionDistinctCountAddressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsConnectionDistinctCountAhjAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsConnectionDistinctCountAhjDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsConnectionDistinctCountCityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsConnectionDistinctCountCityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsConnectionDistinctCountCompanyIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsConnectionDistinctCountCompanyIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsConnectionDistinctCountCompletionDateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsConnectionDistinctCountCompletionDateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionDistinctCountConfidenceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsConnectionDistinctCountConfidenceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsConnectionDistinctCountCreatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsConnectionDistinctCountCreatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsConnectionDistinctCountCreatedByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountCreatedByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsConnectionDistinctCountDealSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsConnectionDistinctCountDealSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsConnectionDistinctCountDealValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsConnectionDistinctCountDealValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionDistinctCountDescriptionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsConnectionDistinctCountDescriptionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsConnectionDistinctCountFreshProjectAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsConnectionDistinctCountFreshProjectDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsConnectionDistinctCountGeoLocationAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsConnectionDistinctCountGeoLocationDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionDistinctCountIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsConnectionDistinctCountIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsConnectionDistinctCountImageUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsConnectionDistinctCountImageUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsConnectionDistinctCountIsActiveAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsConnectionDistinctCountIsActiveDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsConnectionDistinctCountIsCompletedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsConnectionDistinctCountIsCompletedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsConnectionDistinctCountLastActivityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsConnectionDistinctCountLastActivityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountOverdueByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsConnectionDistinctCountOverdueByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsConnectionDistinctCountOwnerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsConnectionDistinctCountOwnerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionDistinctCountPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsConnectionDistinctCountPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionDistinctCountProgressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsConnectionDistinctCountProgressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionDistinctCountProjectSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsConnectionDistinctCountProjectSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsConnectionDistinctCountProjectValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsConnectionDistinctCountProjectValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountRequestStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsConnectionDistinctCountRequestStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsConnectionDistinctCountRevenueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsConnectionDistinctCountRevenueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSourceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsConnectionDistinctCountSourceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsConnectionDistinctCountStageIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsConnectionDistinctCountStageIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsConnectionDistinctCountStateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsConnectionDistinctCountStateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsConnectionDistinctCountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountStreetAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsConnectionDistinctCountStreetDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsConnectionDistinctCountTitleAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsConnectionDistinctCountTitleDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsConnectionDistinctCountTotalCostAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsConnectionDistinctCountTotalCostDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalPriceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsConnectionDistinctCountTotalPriceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsConnectionDistinctCountTradesAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsConnectionDistinctCountTradesDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsConnectionDistinctCountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountUidAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsConnectionDistinctCountUidDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsConnectionDistinctCountZipcodeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsConnectionDistinctCountZipcodeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsConnectionMaxAccountStatusAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMaxAccountStatusDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMaxAccountTypeAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMaxAccountTypeDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMaxAddressAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsConnectionMaxAddressDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsConnectionMaxAddressNormalizedAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMaxAddressNormalizedDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMaxAddressPrettyAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMaxAddressPrettyDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMaxAhjAsc = 'PROJECTS_CONNECTION_MAX_AHJ_ASC',
  ProjectsConnectionMaxAhjDesc = 'PROJECTS_CONNECTION_MAX_AHJ_DESC',
  ProjectsConnectionMaxBlueprintIdAsc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsConnectionMaxBlueprintIdDesc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsConnectionMaxCityAsc = 'PROJECTS_CONNECTION_MAX_CITY_ASC',
  ProjectsConnectionMaxCityDesc = 'PROJECTS_CONNECTION_MAX_CITY_DESC',
  ProjectsConnectionMaxCompanyIdAsc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsConnectionMaxCompanyIdDesc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsConnectionMaxCompletionDateAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsConnectionMaxCompletionDateDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsConnectionMaxCompletionTimeAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsConnectionMaxCompletionTimeDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsConnectionMaxCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMaxCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMaxConfidenceAsc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsConnectionMaxConfidenceDesc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsConnectionMaxCreatedAtAsc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsConnectionMaxCreatedAtDesc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsConnectionMaxCreatedByAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsConnectionMaxCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMaxCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMaxCreatedByDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsConnectionMaxCreatedByTypeAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMaxCreatedByTypeDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMaxDealSizeAsc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsConnectionMaxDealSizeDesc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsConnectionMaxDealValueAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsConnectionMaxDealValueDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsConnectionMaxDealValueWeightedAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMaxDealValueWeightedDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMaxDescriptionAsc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsConnectionMaxDescriptionDesc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsConnectionMaxFreshProjectAsc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsConnectionMaxFreshProjectDesc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsConnectionMaxGeoLocationAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsConnectionMaxGeoLocationDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMaxIdAsc = 'PROJECTS_CONNECTION_MAX_ID_ASC',
  ProjectsConnectionMaxIdDesc = 'PROJECTS_CONNECTION_MAX_ID_DESC',
  ProjectsConnectionMaxImageUrlAsc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsConnectionMaxImageUrlDesc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsConnectionMaxIsActiveAsc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsConnectionMaxIsActiveDesc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsConnectionMaxIsCompletedAsc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsConnectionMaxIsCompletedDesc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsConnectionMaxJurisdictionIdAsc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsConnectionMaxJurisdictionIdDesc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsConnectionMaxLastActivityAsc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsConnectionMaxLastActivityDesc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxOverdueByAsc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsConnectionMaxOverdueByDesc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsConnectionMaxOwnerIdAsc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsConnectionMaxOwnerIdDesc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsConnectionMaxParentProjectIdAsc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMaxParentProjectIdDesc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMaxPhoneAsc = 'PROJECTS_CONNECTION_MAX_PHONE_ASC',
  ProjectsConnectionMaxPhoneDesc = 'PROJECTS_CONNECTION_MAX_PHONE_DESC',
  ProjectsConnectionMaxPrimaryEmailAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMaxPrimaryEmailDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMaxPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsConnectionMaxPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsConnectionMaxPrivilegeAllAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMaxPrivilegeAllDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMaxPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMaxPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMaxPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMaxPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMaxProgressAsc = 'PROJECTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsConnectionMaxProgressDesc = 'PROJECTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsConnectionMaxProjectManagerIdAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMaxProjectManagerIdDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMaxProjectSizeAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsConnectionMaxProjectSizeDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsConnectionMaxProjectValueAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsConnectionMaxProjectValueDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsConnectionMaxReferrerContactIdAsc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMaxReferrerContactIdDesc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMaxRequestStatusAsc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsConnectionMaxRequestStatusDesc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsConnectionMaxRevenueAsc = 'PROJECTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectsConnectionMaxRevenueDesc = 'PROJECTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectsConnectionMaxSalesRepIdAsc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsConnectionMaxSalesRepIdDesc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSourceAsc = 'PROJECTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectsConnectionMaxSourceDesc = 'PROJECTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectsConnectionMaxStageIdAsc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsConnectionMaxStageIdDesc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsConnectionMaxStateAsc = 'PROJECTS_CONNECTION_MAX_STATE_ASC',
  ProjectsConnectionMaxStateDesc = 'PROJECTS_CONNECTION_MAX_STATE_DESC',
  ProjectsConnectionMaxStatusAsc = 'PROJECTS_CONNECTION_MAX_STATUS_ASC',
  ProjectsConnectionMaxStatusDesc = 'PROJECTS_CONNECTION_MAX_STATUS_DESC',
  ProjectsConnectionMaxStreetAsc = 'PROJECTS_CONNECTION_MAX_STREET_ASC',
  ProjectsConnectionMaxStreetDesc = 'PROJECTS_CONNECTION_MAX_STREET_DESC',
  ProjectsConnectionMaxStreetViewUrlAsc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsConnectionMaxStreetViewUrlDesc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsConnectionMaxTitleAsc = 'PROJECTS_CONNECTION_MAX_TITLE_ASC',
  ProjectsConnectionMaxTitleDesc = 'PROJECTS_CONNECTION_MAX_TITLE_DESC',
  ProjectsConnectionMaxTotalCostAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsConnectionMaxTotalCostDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalPriceAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsConnectionMaxTotalPriceDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsConnectionMaxTradesAsc = 'PROJECTS_CONNECTION_MAX_TRADES_ASC',
  ProjectsConnectionMaxTradesDesc = 'PROJECTS_CONNECTION_MAX_TRADES_DESC',
  ProjectsConnectionMaxTypeAsc = 'PROJECTS_CONNECTION_MAX_TYPE_ASC',
  ProjectsConnectionMaxTypeDesc = 'PROJECTS_CONNECTION_MAX_TYPE_DESC',
  ProjectsConnectionMaxUidAsc = 'PROJECTS_CONNECTION_MAX_UID_ASC',
  ProjectsConnectionMaxUidDesc = 'PROJECTS_CONNECTION_MAX_UID_DESC',
  ProjectsConnectionMaxUpdatedAtAsc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsConnectionMaxUpdatedAtDesc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsConnectionMaxZipcodeAsc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsConnectionMaxZipcodeDesc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsConnectionMinAccountStatusAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMinAccountStatusDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMinAccountTypeAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMinAccountTypeDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMinAddressAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsConnectionMinAddressDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsConnectionMinAddressNormalizedAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMinAddressNormalizedDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMinAddressPrettyAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMinAddressPrettyDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMinAhjAsc = 'PROJECTS_CONNECTION_MIN_AHJ_ASC',
  ProjectsConnectionMinAhjDesc = 'PROJECTS_CONNECTION_MIN_AHJ_DESC',
  ProjectsConnectionMinBlueprintIdAsc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsConnectionMinBlueprintIdDesc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsConnectionMinCityAsc = 'PROJECTS_CONNECTION_MIN_CITY_ASC',
  ProjectsConnectionMinCityDesc = 'PROJECTS_CONNECTION_MIN_CITY_DESC',
  ProjectsConnectionMinCompanyIdAsc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsConnectionMinCompanyIdDesc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsConnectionMinCompletionDateAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsConnectionMinCompletionDateDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsConnectionMinCompletionTimeAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsConnectionMinCompletionTimeDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsConnectionMinCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMinCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMinConfidenceAsc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsConnectionMinConfidenceDesc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsConnectionMinCreatedAtAsc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsConnectionMinCreatedAtDesc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsConnectionMinCreatedByAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsConnectionMinCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMinCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMinCreatedByDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsConnectionMinCreatedByTypeAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMinCreatedByTypeDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMinDealSizeAsc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsConnectionMinDealSizeDesc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsConnectionMinDealValueAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsConnectionMinDealValueDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsConnectionMinDealValueWeightedAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMinDealValueWeightedDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMinDescriptionAsc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsConnectionMinDescriptionDesc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsConnectionMinFreshProjectAsc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsConnectionMinFreshProjectDesc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsConnectionMinGeoLocationAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsConnectionMinGeoLocationDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsConnectionMinGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMinGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMinHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMinHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMinIdAsc = 'PROJECTS_CONNECTION_MIN_ID_ASC',
  ProjectsConnectionMinIdDesc = 'PROJECTS_CONNECTION_MIN_ID_DESC',
  ProjectsConnectionMinImageUrlAsc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsConnectionMinImageUrlDesc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsConnectionMinIsActiveAsc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsConnectionMinIsActiveDesc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsConnectionMinIsCompletedAsc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsConnectionMinIsCompletedDesc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsConnectionMinJurisdictionIdAsc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsConnectionMinJurisdictionIdDesc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsConnectionMinLastActivityAsc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsConnectionMinLastActivityDesc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinOverdueByAsc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsConnectionMinOverdueByDesc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsConnectionMinOwnerIdAsc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsConnectionMinOwnerIdDesc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsConnectionMinParentProjectIdAsc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMinParentProjectIdDesc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMinPhoneAsc = 'PROJECTS_CONNECTION_MIN_PHONE_ASC',
  ProjectsConnectionMinPhoneDesc = 'PROJECTS_CONNECTION_MIN_PHONE_DESC',
  ProjectsConnectionMinPrimaryEmailAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMinPrimaryEmailDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMinPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsConnectionMinPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsConnectionMinPrivilegeAllAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMinPrivilegeAllDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMinPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMinPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMinPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMinPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMinProgressAsc = 'PROJECTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsConnectionMinProgressDesc = 'PROJECTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsConnectionMinProjectManagerIdAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMinProjectManagerIdDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMinProjectSizeAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsConnectionMinProjectSizeDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsConnectionMinProjectValueAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsConnectionMinProjectValueDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsConnectionMinReferrerContactIdAsc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMinReferrerContactIdDesc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMinRequestStatusAsc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsConnectionMinRequestStatusDesc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsConnectionMinRevenueAsc = 'PROJECTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectsConnectionMinRevenueDesc = 'PROJECTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectsConnectionMinSalesRepIdAsc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsConnectionMinSalesRepIdDesc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSourceAsc = 'PROJECTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectsConnectionMinSourceDesc = 'PROJECTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectsConnectionMinStageIdAsc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsConnectionMinStageIdDesc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsConnectionMinStateAsc = 'PROJECTS_CONNECTION_MIN_STATE_ASC',
  ProjectsConnectionMinStateDesc = 'PROJECTS_CONNECTION_MIN_STATE_DESC',
  ProjectsConnectionMinStatusAsc = 'PROJECTS_CONNECTION_MIN_STATUS_ASC',
  ProjectsConnectionMinStatusDesc = 'PROJECTS_CONNECTION_MIN_STATUS_DESC',
  ProjectsConnectionMinStreetAsc = 'PROJECTS_CONNECTION_MIN_STREET_ASC',
  ProjectsConnectionMinStreetDesc = 'PROJECTS_CONNECTION_MIN_STREET_DESC',
  ProjectsConnectionMinStreetViewUrlAsc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsConnectionMinStreetViewUrlDesc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsConnectionMinTitleAsc = 'PROJECTS_CONNECTION_MIN_TITLE_ASC',
  ProjectsConnectionMinTitleDesc = 'PROJECTS_CONNECTION_MIN_TITLE_DESC',
  ProjectsConnectionMinTotalCostAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsConnectionMinTotalCostDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalPriceAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsConnectionMinTotalPriceDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsConnectionMinTradesAsc = 'PROJECTS_CONNECTION_MIN_TRADES_ASC',
  ProjectsConnectionMinTradesDesc = 'PROJECTS_CONNECTION_MIN_TRADES_DESC',
  ProjectsConnectionMinTypeAsc = 'PROJECTS_CONNECTION_MIN_TYPE_ASC',
  ProjectsConnectionMinTypeDesc = 'PROJECTS_CONNECTION_MIN_TYPE_DESC',
  ProjectsConnectionMinUidAsc = 'PROJECTS_CONNECTION_MIN_UID_ASC',
  ProjectsConnectionMinUidDesc = 'PROJECTS_CONNECTION_MIN_UID_DESC',
  ProjectsConnectionMinUpdatedAtAsc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsConnectionMinUpdatedAtDesc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsConnectionMinZipcodeAsc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsConnectionMinZipcodeDesc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevPopulationAddressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsConnectionStddevPopulationAddressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevPopulationAhjAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsConnectionStddevPopulationAhjDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevPopulationCityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsConnectionStddevPopulationCityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevPopulationConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionStddevPopulationConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionStddevPopulationCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevPopulationDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionStddevPopulationDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionStddevPopulationDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionStddevPopulationDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevPopulationDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionStddevPopulationDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevPopulationIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsConnectionStddevPopulationIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsConnectionStddevPopulationImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionStddevPopulationImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionStddevPopulationIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionStddevPopulationIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevPopulationLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevPopulationLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionStddevPopulationOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevPopulationPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsConnectionStddevPopulationPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevPopulationProgressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsConnectionStddevPopulationProgressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevPopulationProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevPopulationProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevPopulationRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsConnectionStddevPopulationRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSourceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsConnectionStddevPopulationSourceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsConnectionStddevPopulationStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionStddevPopulationStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionStddevPopulationStateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsConnectionStddevPopulationStateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsConnectionStddevPopulationStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsConnectionStddevPopulationStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsConnectionStddevPopulationStreetAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsConnectionStddevPopulationStreetDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevPopulationTitleAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsConnectionStddevPopulationTitleDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsConnectionStddevPopulationTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionStddevPopulationTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevPopulationTradesAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsConnectionStddevPopulationTradesDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsConnectionStddevPopulationTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsConnectionStddevPopulationTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsConnectionStddevPopulationUidAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsConnectionStddevPopulationUidDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionStddevPopulationZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionStddevPopulationZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionStddevSampleAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevSampleAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevSampleAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevSampleAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevSampleAddressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionStddevSampleAddressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevSampleAhjAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsConnectionStddevSampleAhjDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevSampleCityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsConnectionStddevSampleCityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsConnectionStddevSampleCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionStddevSampleCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionStddevSampleCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevSampleCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevSampleConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionStddevSampleConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionStddevSampleCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionStddevSampleCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionStddevSampleCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevSampleDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionStddevSampleDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionStddevSampleDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionStddevSampleDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevSampleDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionStddevSampleDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionStddevSampleFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevSampleFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevSampleGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionStddevSampleGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevSampleIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsConnectionStddevSampleIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsConnectionStddevSampleImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionStddevSampleImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionStddevSampleIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionStddevSampleIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionStddevSampleIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionStddevSampleIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevSampleLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevSampleLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionStddevSampleOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionStddevSampleOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionStddevSampleOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevSamplePhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsConnectionStddevSamplePhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevSampleProgressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionStddevSampleProgressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevSampleProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevSampleProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevSampleProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevSampleProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevSampleRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevSampleRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsConnectionStddevSampleRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSourceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsConnectionStddevSampleSourceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsConnectionStddevSampleStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionStddevSampleStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionStddevSampleStateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsConnectionStddevSampleStateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsConnectionStddevSampleStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsConnectionStddevSampleStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsConnectionStddevSampleStreetAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsConnectionStddevSampleStreetDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevSampleTitleAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsConnectionStddevSampleTitleDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsConnectionStddevSampleTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionStddevSampleTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevSampleTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevSampleTradesAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsConnectionStddevSampleTradesDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsConnectionStddevSampleTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsConnectionStddevSampleTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsConnectionStddevSampleUidAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsConnectionStddevSampleUidDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionStddevSampleZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionStddevSampleZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsConnectionSumAccountStatusAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsConnectionSumAccountStatusDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsConnectionSumAccountTypeAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsConnectionSumAccountTypeDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsConnectionSumAddressAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsConnectionSumAddressDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsConnectionSumAddressNormalizedAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionSumAddressNormalizedDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionSumAddressPrettyAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsConnectionSumAddressPrettyDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsConnectionSumAhjAsc = 'PROJECTS_CONNECTION_SUM_AHJ_ASC',
  ProjectsConnectionSumAhjDesc = 'PROJECTS_CONNECTION_SUM_AHJ_DESC',
  ProjectsConnectionSumBlueprintIdAsc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsConnectionSumBlueprintIdDesc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsConnectionSumCityAsc = 'PROJECTS_CONNECTION_SUM_CITY_ASC',
  ProjectsConnectionSumCityDesc = 'PROJECTS_CONNECTION_SUM_CITY_DESC',
  ProjectsConnectionSumCompanyIdAsc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsConnectionSumCompanyIdDesc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsConnectionSumCompletionDateAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsConnectionSumCompletionDateDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsConnectionSumCompletionTimeAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsConnectionSumCompletionTimeDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsConnectionSumCompletionTimeInSAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionSumCompletionTimeInSDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionSumConfidenceAsc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsConnectionSumConfidenceDesc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsConnectionSumCreatedAtAsc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsConnectionSumCreatedAtDesc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsConnectionSumCreatedByAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsConnectionSumCreatedByContactIdAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionSumCreatedByContactIdDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionSumCreatedByDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsConnectionSumCreatedByTypeAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsConnectionSumCreatedByTypeDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsConnectionSumDealSizeAsc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsConnectionSumDealSizeDesc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsConnectionSumDealValueAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsConnectionSumDealValueDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsConnectionSumDealValueWeightedAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionSumDealValueWeightedDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionSumDescriptionAsc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsConnectionSumDescriptionDesc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsConnectionSumFreshProjectAsc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsConnectionSumFreshProjectDesc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsConnectionSumGeoLocationAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsConnectionSumGeoLocationDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsConnectionSumGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionSumGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionSumHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionSumHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionSumIdAsc = 'PROJECTS_CONNECTION_SUM_ID_ASC',
  ProjectsConnectionSumIdDesc = 'PROJECTS_CONNECTION_SUM_ID_DESC',
  ProjectsConnectionSumImageUrlAsc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsConnectionSumImageUrlDesc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsConnectionSumIsActiveAsc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsConnectionSumIsActiveDesc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsConnectionSumIsCompletedAsc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsConnectionSumIsCompletedDesc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsConnectionSumJurisdictionIdAsc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsConnectionSumJurisdictionIdDesc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsConnectionSumLastActivityAsc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsConnectionSumLastActivityDesc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumOverdueByAsc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsConnectionSumOverdueByDesc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsConnectionSumOwnerIdAsc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsConnectionSumOwnerIdDesc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsConnectionSumParentProjectIdAsc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionSumParentProjectIdDesc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionSumPhoneAsc = 'PROJECTS_CONNECTION_SUM_PHONE_ASC',
  ProjectsConnectionSumPhoneDesc = 'PROJECTS_CONNECTION_SUM_PHONE_DESC',
  ProjectsConnectionSumPrimaryEmailAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsConnectionSumPrimaryEmailDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsConnectionSumPrimaryPhoneAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsConnectionSumPrimaryPhoneDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsConnectionSumPrivilegeAllAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsConnectionSumPrivilegeAllDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsConnectionSumPrivilegeOwnAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsConnectionSumPrivilegeOwnDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsConnectionSumPrivilegeTeamAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionSumPrivilegeTeamDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionSumProgressAsc = 'PROJECTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsConnectionSumProgressDesc = 'PROJECTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsConnectionSumProjectManagerIdAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionSumProjectManagerIdDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionSumProjectSizeAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsConnectionSumProjectSizeDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsConnectionSumProjectValueAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsConnectionSumProjectValueDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsConnectionSumReferrerContactIdAsc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionSumReferrerContactIdDesc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionSumRequestStatusAsc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsConnectionSumRequestStatusDesc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsConnectionSumRevenueAsc = 'PROJECTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectsConnectionSumRevenueDesc = 'PROJECTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectsConnectionSumSalesRepIdAsc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsConnectionSumSalesRepIdDesc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSourceAsc = 'PROJECTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectsConnectionSumSourceDesc = 'PROJECTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectsConnectionSumStageIdAsc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsConnectionSumStageIdDesc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsConnectionSumStateAsc = 'PROJECTS_CONNECTION_SUM_STATE_ASC',
  ProjectsConnectionSumStateDesc = 'PROJECTS_CONNECTION_SUM_STATE_DESC',
  ProjectsConnectionSumStatusAsc = 'PROJECTS_CONNECTION_SUM_STATUS_ASC',
  ProjectsConnectionSumStatusDesc = 'PROJECTS_CONNECTION_SUM_STATUS_DESC',
  ProjectsConnectionSumStreetAsc = 'PROJECTS_CONNECTION_SUM_STREET_ASC',
  ProjectsConnectionSumStreetDesc = 'PROJECTS_CONNECTION_SUM_STREET_DESC',
  ProjectsConnectionSumStreetViewUrlAsc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsConnectionSumStreetViewUrlDesc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsConnectionSumTitleAsc = 'PROJECTS_CONNECTION_SUM_TITLE_ASC',
  ProjectsConnectionSumTitleDesc = 'PROJECTS_CONNECTION_SUM_TITLE_DESC',
  ProjectsConnectionSumTotalCostAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsConnectionSumTotalCostDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalPriceAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsConnectionSumTotalPriceDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsConnectionSumTradesAsc = 'PROJECTS_CONNECTION_SUM_TRADES_ASC',
  ProjectsConnectionSumTradesDesc = 'PROJECTS_CONNECTION_SUM_TRADES_DESC',
  ProjectsConnectionSumTypeAsc = 'PROJECTS_CONNECTION_SUM_TYPE_ASC',
  ProjectsConnectionSumTypeDesc = 'PROJECTS_CONNECTION_SUM_TYPE_DESC',
  ProjectsConnectionSumUidAsc = 'PROJECTS_CONNECTION_SUM_UID_ASC',
  ProjectsConnectionSumUidDesc = 'PROJECTS_CONNECTION_SUM_UID_DESC',
  ProjectsConnectionSumUpdatedAtAsc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsConnectionSumUpdatedAtDesc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsConnectionSumZipcodeAsc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsConnectionSumZipcodeDesc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVariancePopulationAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsConnectionVariancePopulationAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVariancePopulationAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsConnectionVariancePopulationAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionVariancePopulationCityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsConnectionVariancePopulationCityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVariancePopulationConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionVariancePopulationConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionVariancePopulationCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVariancePopulationDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionVariancePopulationDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionVariancePopulationDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionVariancePopulationDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVariancePopulationDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionVariancePopulationDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVariancePopulationIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsConnectionVariancePopulationIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsConnectionVariancePopulationImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionVariancePopulationImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionVariancePopulationIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionVariancePopulationIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionVariancePopulationLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionVariancePopulationLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionVariancePopulationOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVariancePopulationPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsConnectionVariancePopulationPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVariancePopulationProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsConnectionVariancePopulationProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionVariancePopulationProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionVariancePopulationProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionVariancePopulationRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsConnectionVariancePopulationRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsConnectionVariancePopulationSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsConnectionVariancePopulationStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionVariancePopulationStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionVariancePopulationStateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsConnectionVariancePopulationStateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsConnectionVariancePopulationStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsConnectionVariancePopulationStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsConnectionVariancePopulationStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsConnectionVariancePopulationStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionVariancePopulationTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsConnectionVariancePopulationTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsConnectionVariancePopulationTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionVariancePopulationTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionVariancePopulationTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsConnectionVariancePopulationTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsConnectionVariancePopulationTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsConnectionVariancePopulationTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsConnectionVariancePopulationUidAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsConnectionVariancePopulationUidDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionVariancePopulationZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionVariancePopulationZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVarianceSampleAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionVarianceSampleAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVarianceSampleAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsConnectionVarianceSampleAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionVarianceSampleCityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsConnectionVarianceSampleCityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVarianceSampleConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionVarianceSampleConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionVarianceSampleCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVarianceSampleDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionVarianceSampleDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionVarianceSampleDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionVarianceSampleDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVarianceSampleDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionVarianceSampleDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVarianceSampleIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsConnectionVarianceSampleIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsConnectionVarianceSampleImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionVarianceSampleImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionVarianceSampleIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionVarianceSampleIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionVarianceSampleLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionVarianceSampleLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionVarianceSampleOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVarianceSamplePhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsConnectionVarianceSamplePhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVarianceSampleProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionVarianceSampleProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionVarianceSampleProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionVarianceSampleProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionVarianceSampleRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsConnectionVarianceSampleRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsConnectionVarianceSampleSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsConnectionVarianceSampleStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionVarianceSampleStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionVarianceSampleStateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsConnectionVarianceSampleStateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsConnectionVarianceSampleStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsConnectionVarianceSampleStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsConnectionVarianceSampleStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsConnectionVarianceSampleStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionVarianceSampleTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsConnectionVarianceSampleTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsConnectionVarianceSampleTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionVarianceSampleTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionVarianceSampleTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsConnectionVarianceSampleTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsConnectionVarianceSampleTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsConnectionVarianceSampleTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsConnectionVarianceSampleUidAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsConnectionVarianceSampleUidDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionVarianceSampleZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionVarianceSampleZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionArrayAggCityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsConnectionArrayAggCityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionArrayAggIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsConnectionArrayAggIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggStateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsConnectionArrayAggStateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsConnectionArrayAggUidAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsConnectionArrayAggUidDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionAverageAddressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsConnectionAverageAddressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionAverageAhjAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsConnectionAverageAhjDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionAverageCityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsConnectionAverageCityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsConnectionAverageDealValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsConnectionAverageDealValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionAverageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsConnectionAverageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionAveragePhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsConnectionAveragePhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionAverageProductNameAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionAverageProductNameDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionAverageProgressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsConnectionAverageProgressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionAverageRevenueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsConnectionAverageRevenueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSourceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsConnectionAverageSourceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsConnectionAverageStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageStateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsConnectionAverageStateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsConnectionAverageStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsConnectionAverageStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsConnectionAverageStreetAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsConnectionAverageStreetDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsConnectionAverageTextValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsConnectionAverageTextValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTitleAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsConnectionAverageTitleDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionAverageTradesAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsConnectionAverageTradesDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsConnectionAverageTradeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsConnectionAverageTradeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsConnectionAverageTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsConnectionAverageTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsConnectionAverageUidAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsConnectionAverageUidDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsConnectionAverageUserIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsConnectionAverageUserIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsConnectionCountAsc = 'PROJECT_REPORTS_CONNECTION_COUNT_ASC',
  ProjectReportsConnectionCountDesc = 'PROJECT_REPORTS_CONNECTION_COUNT_DESC',
  ProjectReportsConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMaxAddressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsConnectionMaxAddressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMaxAhjAsc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsConnectionMaxAhjDesc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMaxCityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_ASC',
  ProjectReportsConnectionMaxCityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_DESC',
  ProjectReportsConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsConnectionMaxDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsConnectionMaxDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMaxIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_ASC',
  ProjectReportsConnectionMaxIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_DESC',
  ProjectReportsConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsConnectionMaxPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMaxProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMaxProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMaxProgressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsConnectionMaxProgressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMaxRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsConnectionMaxRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSourceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsConnectionMaxSourceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsConnectionMaxStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsConnectionMaxStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsConnectionMaxStateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_ASC',
  ProjectReportsConnectionMaxStateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_DESC',
  ProjectReportsConnectionMaxStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsConnectionMaxStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsConnectionMaxStreetAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_ASC',
  ProjectReportsConnectionMaxStreetDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_DESC',
  ProjectReportsConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsConnectionMaxTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsConnectionMaxTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTitleAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsConnectionMaxTitleDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMaxTradesAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsConnectionMaxTradesDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsConnectionMaxTradeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsConnectionMaxTradeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsConnectionMaxTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsConnectionMaxTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsConnectionMaxUidAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_ASC',
  ProjectReportsConnectionMaxUidDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_DESC',
  ProjectReportsConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsConnectionMaxUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsConnectionMaxUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMinAddressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsConnectionMinAddressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMinAhjAsc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsConnectionMinAhjDesc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMinCityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_ASC',
  ProjectReportsConnectionMinCityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_DESC',
  ProjectReportsConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMinConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsConnectionMinConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsConnectionMinCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMinCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMinDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsConnectionMinDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsConnectionMinDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsConnectionMinDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMinDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsConnectionMinDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMinIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_ASC',
  ProjectReportsConnectionMinIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_DESC',
  ProjectReportsConnectionMinImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsConnectionMinImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsConnectionMinIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsConnectionMinIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMinLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMinLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMinNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMinOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsConnectionMinOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMinPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsConnectionMinPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMinProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMinProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMinProgressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsConnectionMinProgressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsConnectionMinProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsConnectionMinProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMinProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMinProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMinRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsConnectionMinRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSourceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsConnectionMinSourceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsConnectionMinStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsConnectionMinStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsConnectionMinStateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_ASC',
  ProjectReportsConnectionMinStateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_DESC',
  ProjectReportsConnectionMinStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsConnectionMinStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsConnectionMinStreetAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_ASC',
  ProjectReportsConnectionMinStreetDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_DESC',
  ProjectReportsConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMinTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsConnectionMinTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsConnectionMinTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsConnectionMinTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMinTitleAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsConnectionMinTitleDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsConnectionMinTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsConnectionMinTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMinTradesAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsConnectionMinTradesDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsConnectionMinTradeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsConnectionMinTradeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsConnectionMinTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsConnectionMinTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsConnectionMinUidAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_ASC',
  ProjectReportsConnectionMinUidDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_DESC',
  ProjectReportsConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsConnectionMinUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsConnectionMinUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsConnectionMinZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsConnectionMinZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionSumAddressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsConnectionSumAddressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionSumAhjAsc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsConnectionSumAhjDesc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionSumCityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_ASC',
  ProjectReportsConnectionSumCityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_DESC',
  ProjectReportsConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionSumConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsConnectionSumConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsConnectionSumCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionSumCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionSumDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsConnectionSumDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsConnectionSumDealValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsConnectionSumDealValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionSumDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsConnectionSumDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionSumIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_ASC',
  ProjectReportsConnectionSumIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_DESC',
  ProjectReportsConnectionSumImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsConnectionSumImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsConnectionSumIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsConnectionSumIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsConnectionSumLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionSumLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionSumNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionSumOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsConnectionSumOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionSumPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsConnectionSumPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionSumProductNameAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsConnectionSumProductNameDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsConnectionSumProgressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsConnectionSumProgressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsConnectionSumProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsConnectionSumProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsConnectionSumProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsConnectionSumProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsConnectionSumRevenueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsConnectionSumRevenueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSourceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsConnectionSumSourceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsConnectionSumStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsConnectionSumStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsConnectionSumStateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_ASC',
  ProjectReportsConnectionSumStateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_DESC',
  ProjectReportsConnectionSumStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsConnectionSumStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsConnectionSumStreetAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_ASC',
  ProjectReportsConnectionSumStreetDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_DESC',
  ProjectReportsConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionSumTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsConnectionSumTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsConnectionSumTextValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsConnectionSumTextValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionSumTitleAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsConnectionSumTitleDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsConnectionSumTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsConnectionSumTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsConnectionSumTradesAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsConnectionSumTradesDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsConnectionSumTradeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsConnectionSumTradeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsConnectionSumTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsConnectionSumTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsConnectionSumUidAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_ASC',
  ProjectReportsConnectionSumUidDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_DESC',
  ProjectReportsConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsConnectionSumUserIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsConnectionSumUserIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsConnectionSumZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsConnectionSumZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  RemindersConnectionArrayAggAssigneeIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  RemindersConnectionArrayAggAssigneeIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  RemindersConnectionArrayAggCompanyIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  RemindersConnectionArrayAggCompanyIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  RemindersConnectionArrayAggCompletedAtAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  RemindersConnectionArrayAggCompletedAtDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  RemindersConnectionArrayAggCreatedAtAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  RemindersConnectionArrayAggCreatedAtDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  RemindersConnectionArrayAggCreatedByAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  RemindersConnectionArrayAggCreatedByDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  RemindersConnectionArrayAggDescriptionAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  RemindersConnectionArrayAggDescriptionDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  RemindersConnectionArrayAggDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionArrayAggDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionArrayAggDueDateAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  RemindersConnectionArrayAggDueDateDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  RemindersConnectionArrayAggIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_ID_ASC',
  RemindersConnectionArrayAggIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_ID_DESC',
  RemindersConnectionArrayAggIsAppointmentAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_ASC',
  RemindersConnectionArrayAggIsAppointmentDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_DESC',
  RemindersConnectionArrayAggIsCompletedAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  RemindersConnectionArrayAggIsCompletedDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  RemindersConnectionArrayAggJobIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  RemindersConnectionArrayAggJobIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  RemindersConnectionArrayAggPrivilegeAllAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  RemindersConnectionArrayAggPrivilegeAllDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  RemindersConnectionArrayAggPrivilegeAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  RemindersConnectionArrayAggPrivilegeDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  RemindersConnectionArrayAggPrivilegeOwnAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  RemindersConnectionArrayAggPrivilegeOwnDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  RemindersConnectionArrayAggPrivilegeTeamAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  RemindersConnectionArrayAggPrivilegeTeamDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  RemindersConnectionArrayAggProjectIdAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  RemindersConnectionArrayAggProjectIdDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  RemindersConnectionArrayAggTitleAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  RemindersConnectionArrayAggTitleDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  RemindersConnectionArrayAggTypeAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  RemindersConnectionArrayAggTypeDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  RemindersConnectionArrayAggUpdatedAtAsc = 'REMINDERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  RemindersConnectionArrayAggUpdatedAtDesc = 'REMINDERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  RemindersConnectionAverageAssigneeIdAsc = 'REMINDERS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  RemindersConnectionAverageAssigneeIdDesc = 'REMINDERS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  RemindersConnectionAverageCompanyIdAsc = 'REMINDERS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  RemindersConnectionAverageCompanyIdDesc = 'REMINDERS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  RemindersConnectionAverageCompletedAtAsc = 'REMINDERS_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  RemindersConnectionAverageCompletedAtDesc = 'REMINDERS_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  RemindersConnectionAverageCreatedAtAsc = 'REMINDERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  RemindersConnectionAverageCreatedAtDesc = 'REMINDERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  RemindersConnectionAverageCreatedByAsc = 'REMINDERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  RemindersConnectionAverageCreatedByDesc = 'REMINDERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  RemindersConnectionAverageDescriptionAsc = 'REMINDERS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  RemindersConnectionAverageDescriptionDesc = 'REMINDERS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  RemindersConnectionAverageDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionAverageDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionAverageDueDateAsc = 'REMINDERS_CONNECTION_AVERAGE_DUE_DATE_ASC',
  RemindersConnectionAverageDueDateDesc = 'REMINDERS_CONNECTION_AVERAGE_DUE_DATE_DESC',
  RemindersConnectionAverageIdAsc = 'REMINDERS_CONNECTION_AVERAGE_ID_ASC',
  RemindersConnectionAverageIdDesc = 'REMINDERS_CONNECTION_AVERAGE_ID_DESC',
  RemindersConnectionAverageIsAppointmentAsc = 'REMINDERS_CONNECTION_AVERAGE_IS_APPOINTMENT_ASC',
  RemindersConnectionAverageIsAppointmentDesc = 'REMINDERS_CONNECTION_AVERAGE_IS_APPOINTMENT_DESC',
  RemindersConnectionAverageIsCompletedAsc = 'REMINDERS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  RemindersConnectionAverageIsCompletedDesc = 'REMINDERS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  RemindersConnectionAverageJobIdAsc = 'REMINDERS_CONNECTION_AVERAGE_JOB_ID_ASC',
  RemindersConnectionAverageJobIdDesc = 'REMINDERS_CONNECTION_AVERAGE_JOB_ID_DESC',
  RemindersConnectionAveragePrivilegeAllAsc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  RemindersConnectionAveragePrivilegeAllDesc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  RemindersConnectionAveragePrivilegeAsc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  RemindersConnectionAveragePrivilegeDesc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  RemindersConnectionAveragePrivilegeOwnAsc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  RemindersConnectionAveragePrivilegeOwnDesc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  RemindersConnectionAveragePrivilegeTeamAsc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  RemindersConnectionAveragePrivilegeTeamDesc = 'REMINDERS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  RemindersConnectionAverageProjectIdAsc = 'REMINDERS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  RemindersConnectionAverageProjectIdDesc = 'REMINDERS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  RemindersConnectionAverageTitleAsc = 'REMINDERS_CONNECTION_AVERAGE_TITLE_ASC',
  RemindersConnectionAverageTitleDesc = 'REMINDERS_CONNECTION_AVERAGE_TITLE_DESC',
  RemindersConnectionAverageTypeAsc = 'REMINDERS_CONNECTION_AVERAGE_TYPE_ASC',
  RemindersConnectionAverageTypeDesc = 'REMINDERS_CONNECTION_AVERAGE_TYPE_DESC',
  RemindersConnectionAverageUpdatedAtAsc = 'REMINDERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  RemindersConnectionAverageUpdatedAtDesc = 'REMINDERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  RemindersConnectionCountAsc = 'REMINDERS_CONNECTION_COUNT_ASC',
  RemindersConnectionCountDesc = 'REMINDERS_CONNECTION_COUNT_DESC',
  RemindersConnectionDistinctCountAssigneeIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  RemindersConnectionDistinctCountAssigneeIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  RemindersConnectionDistinctCountCompanyIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  RemindersConnectionDistinctCountCompanyIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  RemindersConnectionDistinctCountCompletedAtAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  RemindersConnectionDistinctCountCompletedAtDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  RemindersConnectionDistinctCountCreatedAtAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  RemindersConnectionDistinctCountCreatedAtDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  RemindersConnectionDistinctCountCreatedByAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  RemindersConnectionDistinctCountCreatedByDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  RemindersConnectionDistinctCountDescriptionAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  RemindersConnectionDistinctCountDescriptionDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  RemindersConnectionDistinctCountDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionDistinctCountDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionDistinctCountDueDateAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  RemindersConnectionDistinctCountDueDateDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  RemindersConnectionDistinctCountIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  RemindersConnectionDistinctCountIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  RemindersConnectionDistinctCountIsAppointmentAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_ASC',
  RemindersConnectionDistinctCountIsAppointmentDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_DESC',
  RemindersConnectionDistinctCountIsCompletedAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  RemindersConnectionDistinctCountIsCompletedDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  RemindersConnectionDistinctCountJobIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  RemindersConnectionDistinctCountJobIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  RemindersConnectionDistinctCountPrivilegeAllAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  RemindersConnectionDistinctCountPrivilegeAllDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  RemindersConnectionDistinctCountPrivilegeAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  RemindersConnectionDistinctCountPrivilegeDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  RemindersConnectionDistinctCountPrivilegeOwnAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  RemindersConnectionDistinctCountPrivilegeOwnDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  RemindersConnectionDistinctCountPrivilegeTeamAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  RemindersConnectionDistinctCountPrivilegeTeamDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  RemindersConnectionDistinctCountProjectIdAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  RemindersConnectionDistinctCountProjectIdDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  RemindersConnectionDistinctCountTitleAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  RemindersConnectionDistinctCountTitleDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  RemindersConnectionDistinctCountTypeAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  RemindersConnectionDistinctCountTypeDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  RemindersConnectionDistinctCountUpdatedAtAsc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  RemindersConnectionDistinctCountUpdatedAtDesc = 'REMINDERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  RemindersConnectionMaxAssigneeIdAsc = 'REMINDERS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  RemindersConnectionMaxAssigneeIdDesc = 'REMINDERS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  RemindersConnectionMaxCompanyIdAsc = 'REMINDERS_CONNECTION_MAX_COMPANY_ID_ASC',
  RemindersConnectionMaxCompanyIdDesc = 'REMINDERS_CONNECTION_MAX_COMPANY_ID_DESC',
  RemindersConnectionMaxCompletedAtAsc = 'REMINDERS_CONNECTION_MAX_COMPLETED_AT_ASC',
  RemindersConnectionMaxCompletedAtDesc = 'REMINDERS_CONNECTION_MAX_COMPLETED_AT_DESC',
  RemindersConnectionMaxCreatedAtAsc = 'REMINDERS_CONNECTION_MAX_CREATED_AT_ASC',
  RemindersConnectionMaxCreatedAtDesc = 'REMINDERS_CONNECTION_MAX_CREATED_AT_DESC',
  RemindersConnectionMaxCreatedByAsc = 'REMINDERS_CONNECTION_MAX_CREATED_BY_ASC',
  RemindersConnectionMaxCreatedByDesc = 'REMINDERS_CONNECTION_MAX_CREATED_BY_DESC',
  RemindersConnectionMaxDescriptionAsc = 'REMINDERS_CONNECTION_MAX_DESCRIPTION_ASC',
  RemindersConnectionMaxDescriptionDesc = 'REMINDERS_CONNECTION_MAX_DESCRIPTION_DESC',
  RemindersConnectionMaxDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionMaxDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionMaxDueDateAsc = 'REMINDERS_CONNECTION_MAX_DUE_DATE_ASC',
  RemindersConnectionMaxDueDateDesc = 'REMINDERS_CONNECTION_MAX_DUE_DATE_DESC',
  RemindersConnectionMaxIdAsc = 'REMINDERS_CONNECTION_MAX_ID_ASC',
  RemindersConnectionMaxIdDesc = 'REMINDERS_CONNECTION_MAX_ID_DESC',
  RemindersConnectionMaxIsAppointmentAsc = 'REMINDERS_CONNECTION_MAX_IS_APPOINTMENT_ASC',
  RemindersConnectionMaxIsAppointmentDesc = 'REMINDERS_CONNECTION_MAX_IS_APPOINTMENT_DESC',
  RemindersConnectionMaxIsCompletedAsc = 'REMINDERS_CONNECTION_MAX_IS_COMPLETED_ASC',
  RemindersConnectionMaxIsCompletedDesc = 'REMINDERS_CONNECTION_MAX_IS_COMPLETED_DESC',
  RemindersConnectionMaxJobIdAsc = 'REMINDERS_CONNECTION_MAX_JOB_ID_ASC',
  RemindersConnectionMaxJobIdDesc = 'REMINDERS_CONNECTION_MAX_JOB_ID_DESC',
  RemindersConnectionMaxPrivilegeAllAsc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  RemindersConnectionMaxPrivilegeAllDesc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  RemindersConnectionMaxPrivilegeAsc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_ASC',
  RemindersConnectionMaxPrivilegeDesc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_DESC',
  RemindersConnectionMaxPrivilegeOwnAsc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  RemindersConnectionMaxPrivilegeOwnDesc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  RemindersConnectionMaxPrivilegeTeamAsc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  RemindersConnectionMaxPrivilegeTeamDesc = 'REMINDERS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  RemindersConnectionMaxProjectIdAsc = 'REMINDERS_CONNECTION_MAX_PROJECT_ID_ASC',
  RemindersConnectionMaxProjectIdDesc = 'REMINDERS_CONNECTION_MAX_PROJECT_ID_DESC',
  RemindersConnectionMaxTitleAsc = 'REMINDERS_CONNECTION_MAX_TITLE_ASC',
  RemindersConnectionMaxTitleDesc = 'REMINDERS_CONNECTION_MAX_TITLE_DESC',
  RemindersConnectionMaxTypeAsc = 'REMINDERS_CONNECTION_MAX_TYPE_ASC',
  RemindersConnectionMaxTypeDesc = 'REMINDERS_CONNECTION_MAX_TYPE_DESC',
  RemindersConnectionMaxUpdatedAtAsc = 'REMINDERS_CONNECTION_MAX_UPDATED_AT_ASC',
  RemindersConnectionMaxUpdatedAtDesc = 'REMINDERS_CONNECTION_MAX_UPDATED_AT_DESC',
  RemindersConnectionMinAssigneeIdAsc = 'REMINDERS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  RemindersConnectionMinAssigneeIdDesc = 'REMINDERS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  RemindersConnectionMinCompanyIdAsc = 'REMINDERS_CONNECTION_MIN_COMPANY_ID_ASC',
  RemindersConnectionMinCompanyIdDesc = 'REMINDERS_CONNECTION_MIN_COMPANY_ID_DESC',
  RemindersConnectionMinCompletedAtAsc = 'REMINDERS_CONNECTION_MIN_COMPLETED_AT_ASC',
  RemindersConnectionMinCompletedAtDesc = 'REMINDERS_CONNECTION_MIN_COMPLETED_AT_DESC',
  RemindersConnectionMinCreatedAtAsc = 'REMINDERS_CONNECTION_MIN_CREATED_AT_ASC',
  RemindersConnectionMinCreatedAtDesc = 'REMINDERS_CONNECTION_MIN_CREATED_AT_DESC',
  RemindersConnectionMinCreatedByAsc = 'REMINDERS_CONNECTION_MIN_CREATED_BY_ASC',
  RemindersConnectionMinCreatedByDesc = 'REMINDERS_CONNECTION_MIN_CREATED_BY_DESC',
  RemindersConnectionMinDescriptionAsc = 'REMINDERS_CONNECTION_MIN_DESCRIPTION_ASC',
  RemindersConnectionMinDescriptionDesc = 'REMINDERS_CONNECTION_MIN_DESCRIPTION_DESC',
  RemindersConnectionMinDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionMinDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionMinDueDateAsc = 'REMINDERS_CONNECTION_MIN_DUE_DATE_ASC',
  RemindersConnectionMinDueDateDesc = 'REMINDERS_CONNECTION_MIN_DUE_DATE_DESC',
  RemindersConnectionMinIdAsc = 'REMINDERS_CONNECTION_MIN_ID_ASC',
  RemindersConnectionMinIdDesc = 'REMINDERS_CONNECTION_MIN_ID_DESC',
  RemindersConnectionMinIsAppointmentAsc = 'REMINDERS_CONNECTION_MIN_IS_APPOINTMENT_ASC',
  RemindersConnectionMinIsAppointmentDesc = 'REMINDERS_CONNECTION_MIN_IS_APPOINTMENT_DESC',
  RemindersConnectionMinIsCompletedAsc = 'REMINDERS_CONNECTION_MIN_IS_COMPLETED_ASC',
  RemindersConnectionMinIsCompletedDesc = 'REMINDERS_CONNECTION_MIN_IS_COMPLETED_DESC',
  RemindersConnectionMinJobIdAsc = 'REMINDERS_CONNECTION_MIN_JOB_ID_ASC',
  RemindersConnectionMinJobIdDesc = 'REMINDERS_CONNECTION_MIN_JOB_ID_DESC',
  RemindersConnectionMinPrivilegeAllAsc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  RemindersConnectionMinPrivilegeAllDesc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  RemindersConnectionMinPrivilegeAsc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_ASC',
  RemindersConnectionMinPrivilegeDesc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_DESC',
  RemindersConnectionMinPrivilegeOwnAsc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  RemindersConnectionMinPrivilegeOwnDesc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  RemindersConnectionMinPrivilegeTeamAsc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  RemindersConnectionMinPrivilegeTeamDesc = 'REMINDERS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  RemindersConnectionMinProjectIdAsc = 'REMINDERS_CONNECTION_MIN_PROJECT_ID_ASC',
  RemindersConnectionMinProjectIdDesc = 'REMINDERS_CONNECTION_MIN_PROJECT_ID_DESC',
  RemindersConnectionMinTitleAsc = 'REMINDERS_CONNECTION_MIN_TITLE_ASC',
  RemindersConnectionMinTitleDesc = 'REMINDERS_CONNECTION_MIN_TITLE_DESC',
  RemindersConnectionMinTypeAsc = 'REMINDERS_CONNECTION_MIN_TYPE_ASC',
  RemindersConnectionMinTypeDesc = 'REMINDERS_CONNECTION_MIN_TYPE_DESC',
  RemindersConnectionMinUpdatedAtAsc = 'REMINDERS_CONNECTION_MIN_UPDATED_AT_ASC',
  RemindersConnectionMinUpdatedAtDesc = 'REMINDERS_CONNECTION_MIN_UPDATED_AT_DESC',
  RemindersConnectionStddevPopulationAssigneeIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  RemindersConnectionStddevPopulationAssigneeIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  RemindersConnectionStddevPopulationCompanyIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  RemindersConnectionStddevPopulationCompanyIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  RemindersConnectionStddevPopulationCompletedAtAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  RemindersConnectionStddevPopulationCompletedAtDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  RemindersConnectionStddevPopulationCreatedAtAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  RemindersConnectionStddevPopulationCreatedAtDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  RemindersConnectionStddevPopulationCreatedByAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  RemindersConnectionStddevPopulationCreatedByDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  RemindersConnectionStddevPopulationDescriptionAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  RemindersConnectionStddevPopulationDescriptionDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  RemindersConnectionStddevPopulationDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionStddevPopulationDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionStddevPopulationDueDateAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  RemindersConnectionStddevPopulationDueDateDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  RemindersConnectionStddevPopulationIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  RemindersConnectionStddevPopulationIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  RemindersConnectionStddevPopulationIsAppointmentAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_ASC',
  RemindersConnectionStddevPopulationIsAppointmentDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_DESC',
  RemindersConnectionStddevPopulationIsCompletedAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  RemindersConnectionStddevPopulationIsCompletedDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  RemindersConnectionStddevPopulationJobIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  RemindersConnectionStddevPopulationJobIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  RemindersConnectionStddevPopulationPrivilegeAllAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersConnectionStddevPopulationPrivilegeAllDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersConnectionStddevPopulationPrivilegeAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  RemindersConnectionStddevPopulationPrivilegeDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  RemindersConnectionStddevPopulationPrivilegeOwnAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersConnectionStddevPopulationPrivilegeOwnDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersConnectionStddevPopulationPrivilegeTeamAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersConnectionStddevPopulationPrivilegeTeamDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersConnectionStddevPopulationProjectIdAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  RemindersConnectionStddevPopulationProjectIdDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  RemindersConnectionStddevPopulationTitleAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  RemindersConnectionStddevPopulationTitleDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  RemindersConnectionStddevPopulationTypeAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  RemindersConnectionStddevPopulationTypeDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  RemindersConnectionStddevPopulationUpdatedAtAsc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  RemindersConnectionStddevPopulationUpdatedAtDesc = 'REMINDERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  RemindersConnectionStddevSampleAssigneeIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersConnectionStddevSampleAssigneeIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersConnectionStddevSampleCompanyIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  RemindersConnectionStddevSampleCompanyIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  RemindersConnectionStddevSampleCompletedAtAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  RemindersConnectionStddevSampleCompletedAtDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  RemindersConnectionStddevSampleCreatedAtAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  RemindersConnectionStddevSampleCreatedAtDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  RemindersConnectionStddevSampleCreatedByAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  RemindersConnectionStddevSampleCreatedByDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  RemindersConnectionStddevSampleDescriptionAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  RemindersConnectionStddevSampleDescriptionDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  RemindersConnectionStddevSampleDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionStddevSampleDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionStddevSampleDueDateAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  RemindersConnectionStddevSampleDueDateDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  RemindersConnectionStddevSampleIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  RemindersConnectionStddevSampleIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  RemindersConnectionStddevSampleIsAppointmentAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersConnectionStddevSampleIsAppointmentDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersConnectionStddevSampleIsCompletedAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  RemindersConnectionStddevSampleIsCompletedDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  RemindersConnectionStddevSampleJobIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  RemindersConnectionStddevSampleJobIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  RemindersConnectionStddevSamplePrivilegeAllAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersConnectionStddevSamplePrivilegeAllDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersConnectionStddevSamplePrivilegeAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  RemindersConnectionStddevSamplePrivilegeDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  RemindersConnectionStddevSamplePrivilegeOwnAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersConnectionStddevSamplePrivilegeOwnDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersConnectionStddevSamplePrivilegeTeamAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersConnectionStddevSamplePrivilegeTeamDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersConnectionStddevSampleProjectIdAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  RemindersConnectionStddevSampleProjectIdDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  RemindersConnectionStddevSampleTitleAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  RemindersConnectionStddevSampleTitleDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  RemindersConnectionStddevSampleTypeAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  RemindersConnectionStddevSampleTypeDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  RemindersConnectionStddevSampleUpdatedAtAsc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  RemindersConnectionStddevSampleUpdatedAtDesc = 'REMINDERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  RemindersConnectionSumAssigneeIdAsc = 'REMINDERS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  RemindersConnectionSumAssigneeIdDesc = 'REMINDERS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  RemindersConnectionSumCompanyIdAsc = 'REMINDERS_CONNECTION_SUM_COMPANY_ID_ASC',
  RemindersConnectionSumCompanyIdDesc = 'REMINDERS_CONNECTION_SUM_COMPANY_ID_DESC',
  RemindersConnectionSumCompletedAtAsc = 'REMINDERS_CONNECTION_SUM_COMPLETED_AT_ASC',
  RemindersConnectionSumCompletedAtDesc = 'REMINDERS_CONNECTION_SUM_COMPLETED_AT_DESC',
  RemindersConnectionSumCreatedAtAsc = 'REMINDERS_CONNECTION_SUM_CREATED_AT_ASC',
  RemindersConnectionSumCreatedAtDesc = 'REMINDERS_CONNECTION_SUM_CREATED_AT_DESC',
  RemindersConnectionSumCreatedByAsc = 'REMINDERS_CONNECTION_SUM_CREATED_BY_ASC',
  RemindersConnectionSumCreatedByDesc = 'REMINDERS_CONNECTION_SUM_CREATED_BY_DESC',
  RemindersConnectionSumDescriptionAsc = 'REMINDERS_CONNECTION_SUM_DESCRIPTION_ASC',
  RemindersConnectionSumDescriptionDesc = 'REMINDERS_CONNECTION_SUM_DESCRIPTION_DESC',
  RemindersConnectionSumDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionSumDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionSumDueDateAsc = 'REMINDERS_CONNECTION_SUM_DUE_DATE_ASC',
  RemindersConnectionSumDueDateDesc = 'REMINDERS_CONNECTION_SUM_DUE_DATE_DESC',
  RemindersConnectionSumIdAsc = 'REMINDERS_CONNECTION_SUM_ID_ASC',
  RemindersConnectionSumIdDesc = 'REMINDERS_CONNECTION_SUM_ID_DESC',
  RemindersConnectionSumIsAppointmentAsc = 'REMINDERS_CONNECTION_SUM_IS_APPOINTMENT_ASC',
  RemindersConnectionSumIsAppointmentDesc = 'REMINDERS_CONNECTION_SUM_IS_APPOINTMENT_DESC',
  RemindersConnectionSumIsCompletedAsc = 'REMINDERS_CONNECTION_SUM_IS_COMPLETED_ASC',
  RemindersConnectionSumIsCompletedDesc = 'REMINDERS_CONNECTION_SUM_IS_COMPLETED_DESC',
  RemindersConnectionSumJobIdAsc = 'REMINDERS_CONNECTION_SUM_JOB_ID_ASC',
  RemindersConnectionSumJobIdDesc = 'REMINDERS_CONNECTION_SUM_JOB_ID_DESC',
  RemindersConnectionSumPrivilegeAllAsc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  RemindersConnectionSumPrivilegeAllDesc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  RemindersConnectionSumPrivilegeAsc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_ASC',
  RemindersConnectionSumPrivilegeDesc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_DESC',
  RemindersConnectionSumPrivilegeOwnAsc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  RemindersConnectionSumPrivilegeOwnDesc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  RemindersConnectionSumPrivilegeTeamAsc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  RemindersConnectionSumPrivilegeTeamDesc = 'REMINDERS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  RemindersConnectionSumProjectIdAsc = 'REMINDERS_CONNECTION_SUM_PROJECT_ID_ASC',
  RemindersConnectionSumProjectIdDesc = 'REMINDERS_CONNECTION_SUM_PROJECT_ID_DESC',
  RemindersConnectionSumTitleAsc = 'REMINDERS_CONNECTION_SUM_TITLE_ASC',
  RemindersConnectionSumTitleDesc = 'REMINDERS_CONNECTION_SUM_TITLE_DESC',
  RemindersConnectionSumTypeAsc = 'REMINDERS_CONNECTION_SUM_TYPE_ASC',
  RemindersConnectionSumTypeDesc = 'REMINDERS_CONNECTION_SUM_TYPE_DESC',
  RemindersConnectionSumUpdatedAtAsc = 'REMINDERS_CONNECTION_SUM_UPDATED_AT_ASC',
  RemindersConnectionSumUpdatedAtDesc = 'REMINDERS_CONNECTION_SUM_UPDATED_AT_DESC',
  RemindersConnectionVariancePopulationAssigneeIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  RemindersConnectionVariancePopulationAssigneeIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  RemindersConnectionVariancePopulationCompanyIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  RemindersConnectionVariancePopulationCompanyIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  RemindersConnectionVariancePopulationCompletedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  RemindersConnectionVariancePopulationCompletedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  RemindersConnectionVariancePopulationCreatedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  RemindersConnectionVariancePopulationCreatedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  RemindersConnectionVariancePopulationCreatedByAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  RemindersConnectionVariancePopulationCreatedByDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  RemindersConnectionVariancePopulationDescriptionAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  RemindersConnectionVariancePopulationDescriptionDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  RemindersConnectionVariancePopulationDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionVariancePopulationDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionVariancePopulationDueDateAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  RemindersConnectionVariancePopulationDueDateDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  RemindersConnectionVariancePopulationIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  RemindersConnectionVariancePopulationIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  RemindersConnectionVariancePopulationIsAppointmentAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_ASC',
  RemindersConnectionVariancePopulationIsAppointmentDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_DESC',
  RemindersConnectionVariancePopulationIsCompletedAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  RemindersConnectionVariancePopulationIsCompletedDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  RemindersConnectionVariancePopulationJobIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  RemindersConnectionVariancePopulationJobIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  RemindersConnectionVariancePopulationPrivilegeAllAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersConnectionVariancePopulationPrivilegeAllDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersConnectionVariancePopulationPrivilegeAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  RemindersConnectionVariancePopulationPrivilegeDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  RemindersConnectionVariancePopulationPrivilegeOwnAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersConnectionVariancePopulationPrivilegeOwnDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersConnectionVariancePopulationPrivilegeTeamAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersConnectionVariancePopulationPrivilegeTeamDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersConnectionVariancePopulationProjectIdAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  RemindersConnectionVariancePopulationProjectIdDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  RemindersConnectionVariancePopulationTitleAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  RemindersConnectionVariancePopulationTitleDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  RemindersConnectionVariancePopulationTypeAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  RemindersConnectionVariancePopulationTypeDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  RemindersConnectionVariancePopulationUpdatedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  RemindersConnectionVariancePopulationUpdatedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  RemindersConnectionVarianceSampleAssigneeIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersConnectionVarianceSampleAssigneeIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersConnectionVarianceSampleCompanyIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  RemindersConnectionVarianceSampleCompanyIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  RemindersConnectionVarianceSampleCompletedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  RemindersConnectionVarianceSampleCompletedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  RemindersConnectionVarianceSampleCreatedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RemindersConnectionVarianceSampleCreatedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RemindersConnectionVarianceSampleCreatedByAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  RemindersConnectionVarianceSampleCreatedByDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  RemindersConnectionVarianceSampleDescriptionAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  RemindersConnectionVarianceSampleDescriptionDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  RemindersConnectionVarianceSampleDueDateApproachingJobIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersConnectionVarianceSampleDueDateApproachingJobIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersConnectionVarianceSampleDueDateAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  RemindersConnectionVarianceSampleDueDateDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  RemindersConnectionVarianceSampleIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  RemindersConnectionVarianceSampleIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  RemindersConnectionVarianceSampleIsAppointmentAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersConnectionVarianceSampleIsAppointmentDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersConnectionVarianceSampleIsCompletedAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  RemindersConnectionVarianceSampleIsCompletedDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  RemindersConnectionVarianceSampleJobIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  RemindersConnectionVarianceSampleJobIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  RemindersConnectionVarianceSamplePrivilegeAllAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersConnectionVarianceSamplePrivilegeAllDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersConnectionVarianceSamplePrivilegeAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  RemindersConnectionVarianceSamplePrivilegeDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  RemindersConnectionVarianceSamplePrivilegeOwnAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersConnectionVarianceSamplePrivilegeOwnDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersConnectionVarianceSamplePrivilegeTeamAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersConnectionVarianceSamplePrivilegeTeamDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersConnectionVarianceSampleProjectIdAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  RemindersConnectionVarianceSampleProjectIdDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  RemindersConnectionVarianceSampleTitleAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  RemindersConnectionVarianceSampleTitleDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  RemindersConnectionVarianceSampleTypeAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  RemindersConnectionVarianceSampleTypeDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  RemindersConnectionVarianceSampleUpdatedAtAsc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  RemindersConnectionVarianceSampleUpdatedAtDesc = 'REMINDERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  RolesConnectionArrayAggActiveAsc = 'ROLES_CONNECTION_ARRAY_AGG_ACTIVE_ASC',
  RolesConnectionArrayAggActiveDesc = 'ROLES_CONNECTION_ARRAY_AGG_ACTIVE_DESC',
  RolesConnectionArrayAggCompanyIdAsc = 'ROLES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  RolesConnectionArrayAggCompanyIdDesc = 'ROLES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  RolesConnectionArrayAggCreatedAtAsc = 'ROLES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  RolesConnectionArrayAggCreatedAtDesc = 'ROLES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  RolesConnectionArrayAggCreatedByAsc = 'ROLES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  RolesConnectionArrayAggCreatedByDesc = 'ROLES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  RolesConnectionArrayAggIdAsc = 'ROLES_CONNECTION_ARRAY_AGG_ID_ASC',
  RolesConnectionArrayAggIdDesc = 'ROLES_CONNECTION_ARRAY_AGG_ID_DESC',
  RolesConnectionArrayAggNameAsc = 'ROLES_CONNECTION_ARRAY_AGG_NAME_ASC',
  RolesConnectionArrayAggNameDesc = 'ROLES_CONNECTION_ARRAY_AGG_NAME_DESC',
  RolesConnectionArrayAggSettingsAsc = 'ROLES_CONNECTION_ARRAY_AGG_SETTINGS_ASC',
  RolesConnectionArrayAggSettingsDesc = 'ROLES_CONNECTION_ARRAY_AGG_SETTINGS_DESC',
  RolesConnectionAverageActiveAsc = 'ROLES_CONNECTION_AVERAGE_ACTIVE_ASC',
  RolesConnectionAverageActiveDesc = 'ROLES_CONNECTION_AVERAGE_ACTIVE_DESC',
  RolesConnectionAverageCompanyIdAsc = 'ROLES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  RolesConnectionAverageCompanyIdDesc = 'ROLES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  RolesConnectionAverageCreatedAtAsc = 'ROLES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  RolesConnectionAverageCreatedAtDesc = 'ROLES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  RolesConnectionAverageCreatedByAsc = 'ROLES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  RolesConnectionAverageCreatedByDesc = 'ROLES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  RolesConnectionAverageIdAsc = 'ROLES_CONNECTION_AVERAGE_ID_ASC',
  RolesConnectionAverageIdDesc = 'ROLES_CONNECTION_AVERAGE_ID_DESC',
  RolesConnectionAverageNameAsc = 'ROLES_CONNECTION_AVERAGE_NAME_ASC',
  RolesConnectionAverageNameDesc = 'ROLES_CONNECTION_AVERAGE_NAME_DESC',
  RolesConnectionAverageSettingsAsc = 'ROLES_CONNECTION_AVERAGE_SETTINGS_ASC',
  RolesConnectionAverageSettingsDesc = 'ROLES_CONNECTION_AVERAGE_SETTINGS_DESC',
  RolesConnectionCountAsc = 'ROLES_CONNECTION_COUNT_ASC',
  RolesConnectionCountDesc = 'ROLES_CONNECTION_COUNT_DESC',
  RolesConnectionDistinctCountActiveAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_ACTIVE_ASC',
  RolesConnectionDistinctCountActiveDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_ACTIVE_DESC',
  RolesConnectionDistinctCountCompanyIdAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  RolesConnectionDistinctCountCompanyIdDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  RolesConnectionDistinctCountCreatedAtAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  RolesConnectionDistinctCountCreatedAtDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  RolesConnectionDistinctCountCreatedByAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  RolesConnectionDistinctCountCreatedByDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  RolesConnectionDistinctCountIdAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  RolesConnectionDistinctCountIdDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  RolesConnectionDistinctCountNameAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  RolesConnectionDistinctCountNameDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  RolesConnectionDistinctCountSettingsAsc = 'ROLES_CONNECTION_DISTINCT_COUNT_SETTINGS_ASC',
  RolesConnectionDistinctCountSettingsDesc = 'ROLES_CONNECTION_DISTINCT_COUNT_SETTINGS_DESC',
  RolesConnectionMaxActiveAsc = 'ROLES_CONNECTION_MAX_ACTIVE_ASC',
  RolesConnectionMaxActiveDesc = 'ROLES_CONNECTION_MAX_ACTIVE_DESC',
  RolesConnectionMaxCompanyIdAsc = 'ROLES_CONNECTION_MAX_COMPANY_ID_ASC',
  RolesConnectionMaxCompanyIdDesc = 'ROLES_CONNECTION_MAX_COMPANY_ID_DESC',
  RolesConnectionMaxCreatedAtAsc = 'ROLES_CONNECTION_MAX_CREATED_AT_ASC',
  RolesConnectionMaxCreatedAtDesc = 'ROLES_CONNECTION_MAX_CREATED_AT_DESC',
  RolesConnectionMaxCreatedByAsc = 'ROLES_CONNECTION_MAX_CREATED_BY_ASC',
  RolesConnectionMaxCreatedByDesc = 'ROLES_CONNECTION_MAX_CREATED_BY_DESC',
  RolesConnectionMaxIdAsc = 'ROLES_CONNECTION_MAX_ID_ASC',
  RolesConnectionMaxIdDesc = 'ROLES_CONNECTION_MAX_ID_DESC',
  RolesConnectionMaxNameAsc = 'ROLES_CONNECTION_MAX_NAME_ASC',
  RolesConnectionMaxNameDesc = 'ROLES_CONNECTION_MAX_NAME_DESC',
  RolesConnectionMaxSettingsAsc = 'ROLES_CONNECTION_MAX_SETTINGS_ASC',
  RolesConnectionMaxSettingsDesc = 'ROLES_CONNECTION_MAX_SETTINGS_DESC',
  RolesConnectionMinActiveAsc = 'ROLES_CONNECTION_MIN_ACTIVE_ASC',
  RolesConnectionMinActiveDesc = 'ROLES_CONNECTION_MIN_ACTIVE_DESC',
  RolesConnectionMinCompanyIdAsc = 'ROLES_CONNECTION_MIN_COMPANY_ID_ASC',
  RolesConnectionMinCompanyIdDesc = 'ROLES_CONNECTION_MIN_COMPANY_ID_DESC',
  RolesConnectionMinCreatedAtAsc = 'ROLES_CONNECTION_MIN_CREATED_AT_ASC',
  RolesConnectionMinCreatedAtDesc = 'ROLES_CONNECTION_MIN_CREATED_AT_DESC',
  RolesConnectionMinCreatedByAsc = 'ROLES_CONNECTION_MIN_CREATED_BY_ASC',
  RolesConnectionMinCreatedByDesc = 'ROLES_CONNECTION_MIN_CREATED_BY_DESC',
  RolesConnectionMinIdAsc = 'ROLES_CONNECTION_MIN_ID_ASC',
  RolesConnectionMinIdDesc = 'ROLES_CONNECTION_MIN_ID_DESC',
  RolesConnectionMinNameAsc = 'ROLES_CONNECTION_MIN_NAME_ASC',
  RolesConnectionMinNameDesc = 'ROLES_CONNECTION_MIN_NAME_DESC',
  RolesConnectionMinSettingsAsc = 'ROLES_CONNECTION_MIN_SETTINGS_ASC',
  RolesConnectionMinSettingsDesc = 'ROLES_CONNECTION_MIN_SETTINGS_DESC',
  RolesConnectionStddevPopulationActiveAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_ACTIVE_ASC',
  RolesConnectionStddevPopulationActiveDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_ACTIVE_DESC',
  RolesConnectionStddevPopulationCompanyIdAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  RolesConnectionStddevPopulationCompanyIdDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  RolesConnectionStddevPopulationCreatedAtAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  RolesConnectionStddevPopulationCreatedAtDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  RolesConnectionStddevPopulationCreatedByAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  RolesConnectionStddevPopulationCreatedByDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  RolesConnectionStddevPopulationIdAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  RolesConnectionStddevPopulationIdDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  RolesConnectionStddevPopulationNameAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  RolesConnectionStddevPopulationNameDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  RolesConnectionStddevPopulationSettingsAsc = 'ROLES_CONNECTION_STDDEV_POPULATION_SETTINGS_ASC',
  RolesConnectionStddevPopulationSettingsDesc = 'ROLES_CONNECTION_STDDEV_POPULATION_SETTINGS_DESC',
  RolesConnectionStddevSampleActiveAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_ACTIVE_ASC',
  RolesConnectionStddevSampleActiveDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_ACTIVE_DESC',
  RolesConnectionStddevSampleCompanyIdAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  RolesConnectionStddevSampleCompanyIdDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  RolesConnectionStddevSampleCreatedAtAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  RolesConnectionStddevSampleCreatedAtDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  RolesConnectionStddevSampleCreatedByAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  RolesConnectionStddevSampleCreatedByDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  RolesConnectionStddevSampleIdAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  RolesConnectionStddevSampleIdDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  RolesConnectionStddevSampleNameAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  RolesConnectionStddevSampleNameDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  RolesConnectionStddevSampleSettingsAsc = 'ROLES_CONNECTION_STDDEV_SAMPLE_SETTINGS_ASC',
  RolesConnectionStddevSampleSettingsDesc = 'ROLES_CONNECTION_STDDEV_SAMPLE_SETTINGS_DESC',
  RolesConnectionSumActiveAsc = 'ROLES_CONNECTION_SUM_ACTIVE_ASC',
  RolesConnectionSumActiveDesc = 'ROLES_CONNECTION_SUM_ACTIVE_DESC',
  RolesConnectionSumCompanyIdAsc = 'ROLES_CONNECTION_SUM_COMPANY_ID_ASC',
  RolesConnectionSumCompanyIdDesc = 'ROLES_CONNECTION_SUM_COMPANY_ID_DESC',
  RolesConnectionSumCreatedAtAsc = 'ROLES_CONNECTION_SUM_CREATED_AT_ASC',
  RolesConnectionSumCreatedAtDesc = 'ROLES_CONNECTION_SUM_CREATED_AT_DESC',
  RolesConnectionSumCreatedByAsc = 'ROLES_CONNECTION_SUM_CREATED_BY_ASC',
  RolesConnectionSumCreatedByDesc = 'ROLES_CONNECTION_SUM_CREATED_BY_DESC',
  RolesConnectionSumIdAsc = 'ROLES_CONNECTION_SUM_ID_ASC',
  RolesConnectionSumIdDesc = 'ROLES_CONNECTION_SUM_ID_DESC',
  RolesConnectionSumNameAsc = 'ROLES_CONNECTION_SUM_NAME_ASC',
  RolesConnectionSumNameDesc = 'ROLES_CONNECTION_SUM_NAME_DESC',
  RolesConnectionSumSettingsAsc = 'ROLES_CONNECTION_SUM_SETTINGS_ASC',
  RolesConnectionSumSettingsDesc = 'ROLES_CONNECTION_SUM_SETTINGS_DESC',
  RolesConnectionVariancePopulationActiveAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_ACTIVE_ASC',
  RolesConnectionVariancePopulationActiveDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_ACTIVE_DESC',
  RolesConnectionVariancePopulationCompanyIdAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  RolesConnectionVariancePopulationCompanyIdDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  RolesConnectionVariancePopulationCreatedAtAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  RolesConnectionVariancePopulationCreatedAtDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  RolesConnectionVariancePopulationCreatedByAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  RolesConnectionVariancePopulationCreatedByDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  RolesConnectionVariancePopulationIdAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  RolesConnectionVariancePopulationIdDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  RolesConnectionVariancePopulationNameAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  RolesConnectionVariancePopulationNameDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  RolesConnectionVariancePopulationSettingsAsc = 'ROLES_CONNECTION_VARIANCE_POPULATION_SETTINGS_ASC',
  RolesConnectionVariancePopulationSettingsDesc = 'ROLES_CONNECTION_VARIANCE_POPULATION_SETTINGS_DESC',
  RolesConnectionVarianceSampleActiveAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_ACTIVE_ASC',
  RolesConnectionVarianceSampleActiveDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_ACTIVE_DESC',
  RolesConnectionVarianceSampleCompanyIdAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  RolesConnectionVarianceSampleCompanyIdDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  RolesConnectionVarianceSampleCreatedAtAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RolesConnectionVarianceSampleCreatedAtDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RolesConnectionVarianceSampleCreatedByAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  RolesConnectionVarianceSampleCreatedByDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  RolesConnectionVarianceSampleIdAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  RolesConnectionVarianceSampleIdDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  RolesConnectionVarianceSampleNameAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  RolesConnectionVarianceSampleNameDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  RolesConnectionVarianceSampleSettingsAsc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_SETTINGS_ASC',
  RolesConnectionVarianceSampleSettingsDesc = 'ROLES_CONNECTION_VARIANCE_SAMPLE_SETTINGS_DESC',
  SettingsAsc = 'SETTINGS_ASC',
  SettingsDesc = 'SETTINGS_DESC',
  SmsActivitiesConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesConnectionAverageIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesConnectionAverageIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionAverageStatusAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesConnectionAverageStatusDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesConnectionAverageTextAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesConnectionAverageTextDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesConnectionCountAsc = 'SMS_ACTIVITIES_CONNECTION_COUNT_ASC',
  SmsActivitiesConnectionCountDesc = 'SMS_ACTIVITIES_CONNECTION_COUNT_DESC',
  SmsActivitiesConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesConnectionMaxIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_ID_ASC',
  SmsActivitiesConnectionMaxIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_ID_DESC',
  SmsActivitiesConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionMaxStatusAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesConnectionMaxStatusDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesConnectionMaxTextAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesConnectionMaxTextDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesConnectionMinIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_ID_ASC',
  SmsActivitiesConnectionMinIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_ID_DESC',
  SmsActivitiesConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionMinStatusAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesConnectionMinStatusDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesConnectionMinTextAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesConnectionMinTextDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesConnectionSumIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_ID_ASC',
  SmsActivitiesConnectionSumIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_ID_DESC',
  SmsActivitiesConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionSumStatusAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesConnectionSumStatusDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesConnectionSumTextAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesConnectionSumTextDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  StripeCustomerIdAsc = 'STRIPE_CUSTOMER_ID_ASC',
  StripeCustomerIdDesc = 'STRIPE_CUSTOMER_ID_DESC',
  SubdomainNameAsc = 'SUBDOMAIN_NAME_ASC',
  SubdomainNameDesc = 'SUBDOMAIN_NAME_DESC',
  SystemBudgetsConnectionArrayAggCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SystemBudgetsConnectionArrayAggCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SystemBudgetsConnectionArrayAggCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemBudgetsConnectionArrayAggCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemBudgetsConnectionArrayAggIdAsc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemBudgetsConnectionArrayAggIdDesc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemBudgetsConnectionArrayAggMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionArrayAggMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionArrayAggOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionArrayAggOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_ARRAY_AGG_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionAverageCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SystemBudgetsConnectionAverageCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SystemBudgetsConnectionAverageCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemBudgetsConnectionAverageCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemBudgetsConnectionAverageIdAsc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_ID_ASC',
  SystemBudgetsConnectionAverageIdDesc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_ID_DESC',
  SystemBudgetsConnectionAverageMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionAverageMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionAverageOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionAverageOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_AVERAGE_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionCountAsc = 'SYSTEM_BUDGETS_CONNECTION_COUNT_ASC',
  SystemBudgetsConnectionCountDesc = 'SYSTEM_BUDGETS_CONNECTION_COUNT_DESC',
  SystemBudgetsConnectionDistinctCountCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SystemBudgetsConnectionDistinctCountCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SystemBudgetsConnectionDistinctCountCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemBudgetsConnectionDistinctCountCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemBudgetsConnectionDistinctCountIdAsc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemBudgetsConnectionDistinctCountIdDesc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemBudgetsConnectionDistinctCountMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionDistinctCountMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionDistinctCountOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionDistinctCountOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_DISTINCT_COUNT_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionMaxCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_MAX_COMPANY_ID_ASC',
  SystemBudgetsConnectionMaxCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_MAX_COMPANY_ID_DESC',
  SystemBudgetsConnectionMaxCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_MAX_CREATED_AT_ASC',
  SystemBudgetsConnectionMaxCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_MAX_CREATED_AT_DESC',
  SystemBudgetsConnectionMaxIdAsc = 'SYSTEM_BUDGETS_CONNECTION_MAX_ID_ASC',
  SystemBudgetsConnectionMaxIdDesc = 'SYSTEM_BUDGETS_CONNECTION_MAX_ID_DESC',
  SystemBudgetsConnectionMaxMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_MAX_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionMaxMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_MAX_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionMaxOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_MAX_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionMaxOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_MAX_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionMinCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_MIN_COMPANY_ID_ASC',
  SystemBudgetsConnectionMinCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_MIN_COMPANY_ID_DESC',
  SystemBudgetsConnectionMinCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_MIN_CREATED_AT_ASC',
  SystemBudgetsConnectionMinCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_MIN_CREATED_AT_DESC',
  SystemBudgetsConnectionMinIdAsc = 'SYSTEM_BUDGETS_CONNECTION_MIN_ID_ASC',
  SystemBudgetsConnectionMinIdDesc = 'SYSTEM_BUDGETS_CONNECTION_MIN_ID_DESC',
  SystemBudgetsConnectionMinMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_MIN_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionMinMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_MIN_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionMinOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_MIN_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionMinOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_MIN_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionStddevPopulationCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SystemBudgetsConnectionStddevPopulationCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SystemBudgetsConnectionStddevPopulationCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemBudgetsConnectionStddevPopulationCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemBudgetsConnectionStddevPopulationIdAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemBudgetsConnectionStddevPopulationIdDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemBudgetsConnectionStddevPopulationMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionStddevPopulationMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionStddevPopulationOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionStddevPopulationOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_POPULATION_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionStddevSampleCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SystemBudgetsConnectionStddevSampleCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SystemBudgetsConnectionStddevSampleCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemBudgetsConnectionStddevSampleCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemBudgetsConnectionStddevSampleIdAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemBudgetsConnectionStddevSampleIdDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemBudgetsConnectionStddevSampleMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionStddevSampleMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionStddevSampleOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionStddevSampleOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_STDDEV_SAMPLE_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionSumCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_SUM_COMPANY_ID_ASC',
  SystemBudgetsConnectionSumCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_SUM_COMPANY_ID_DESC',
  SystemBudgetsConnectionSumCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_SUM_CREATED_AT_ASC',
  SystemBudgetsConnectionSumCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_SUM_CREATED_AT_DESC',
  SystemBudgetsConnectionSumIdAsc = 'SYSTEM_BUDGETS_CONNECTION_SUM_ID_ASC',
  SystemBudgetsConnectionSumIdDesc = 'SYSTEM_BUDGETS_CONNECTION_SUM_ID_DESC',
  SystemBudgetsConnectionSumMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_SUM_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionSumMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_SUM_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionSumOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_SUM_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionSumOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_SUM_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionVariancePopulationCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SystemBudgetsConnectionVariancePopulationCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SystemBudgetsConnectionVariancePopulationCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemBudgetsConnectionVariancePopulationCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemBudgetsConnectionVariancePopulationIdAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemBudgetsConnectionVariancePopulationIdDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemBudgetsConnectionVariancePopulationMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionVariancePopulationMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionVariancePopulationOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionVariancePopulationOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_POPULATION_OVERBUDGET_BEHAVIOUR_DESC',
  SystemBudgetsConnectionVarianceSampleCompanyIdAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SystemBudgetsConnectionVarianceSampleCompanyIdDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SystemBudgetsConnectionVarianceSampleCreatedAtAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemBudgetsConnectionVarianceSampleCreatedAtDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemBudgetsConnectionVarianceSampleIdAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemBudgetsConnectionVarianceSampleIdDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemBudgetsConnectionVarianceSampleMonthlyBudgetAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_MONTHLY_BUDGET_ASC',
  SystemBudgetsConnectionVarianceSampleMonthlyBudgetDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_MONTHLY_BUDGET_DESC',
  SystemBudgetsConnectionVarianceSampleOverbudgetBehaviourAsc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_OVERBUDGET_BEHAVIOUR_ASC',
  SystemBudgetsConnectionVarianceSampleOverbudgetBehaviourDesc = 'SYSTEM_BUDGETS_CONNECTION_VARIANCE_SAMPLE_OVERBUDGET_BEHAVIOUR_DESC',
  SystemProfilesConnectionArrayAggCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SystemProfilesConnectionArrayAggCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SystemProfilesConnectionArrayAggCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemProfilesConnectionArrayAggCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemProfilesConnectionArrayAggDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_DEFAULT_ASC',
  SystemProfilesConnectionArrayAggDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_DEFAULT_DESC',
  SystemProfilesConnectionArrayAggIdAsc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemProfilesConnectionArrayAggIdDesc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemProfilesConnectionArrayAggNameAsc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_NAME_ASC',
  SystemProfilesConnectionArrayAggNameDesc = 'SYSTEM_PROFILES_CONNECTION_ARRAY_AGG_NAME_DESC',
  SystemProfilesConnectionAverageCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SystemProfilesConnectionAverageCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SystemProfilesConnectionAverageCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemProfilesConnectionAverageCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemProfilesConnectionAverageDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_DEFAULT_ASC',
  SystemProfilesConnectionAverageDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_DEFAULT_DESC',
  SystemProfilesConnectionAverageIdAsc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_ID_ASC',
  SystemProfilesConnectionAverageIdDesc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_ID_DESC',
  SystemProfilesConnectionAverageNameAsc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_NAME_ASC',
  SystemProfilesConnectionAverageNameDesc = 'SYSTEM_PROFILES_CONNECTION_AVERAGE_NAME_DESC',
  SystemProfilesConnectionCountAsc = 'SYSTEM_PROFILES_CONNECTION_COUNT_ASC',
  SystemProfilesConnectionCountDesc = 'SYSTEM_PROFILES_CONNECTION_COUNT_DESC',
  SystemProfilesConnectionDistinctCountCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SystemProfilesConnectionDistinctCountCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SystemProfilesConnectionDistinctCountCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemProfilesConnectionDistinctCountCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemProfilesConnectionDistinctCountDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_DEFAULT_ASC',
  SystemProfilesConnectionDistinctCountDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_DEFAULT_DESC',
  SystemProfilesConnectionDistinctCountIdAsc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemProfilesConnectionDistinctCountIdDesc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemProfilesConnectionDistinctCountNameAsc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  SystemProfilesConnectionDistinctCountNameDesc = 'SYSTEM_PROFILES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  SystemProfilesConnectionMaxCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_MAX_COMPANY_ID_ASC',
  SystemProfilesConnectionMaxCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_MAX_COMPANY_ID_DESC',
  SystemProfilesConnectionMaxCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_MAX_CREATED_AT_ASC',
  SystemProfilesConnectionMaxCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_MAX_CREATED_AT_DESC',
  SystemProfilesConnectionMaxDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_MAX_DEFAULT_ASC',
  SystemProfilesConnectionMaxDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_MAX_DEFAULT_DESC',
  SystemProfilesConnectionMaxIdAsc = 'SYSTEM_PROFILES_CONNECTION_MAX_ID_ASC',
  SystemProfilesConnectionMaxIdDesc = 'SYSTEM_PROFILES_CONNECTION_MAX_ID_DESC',
  SystemProfilesConnectionMaxNameAsc = 'SYSTEM_PROFILES_CONNECTION_MAX_NAME_ASC',
  SystemProfilesConnectionMaxNameDesc = 'SYSTEM_PROFILES_CONNECTION_MAX_NAME_DESC',
  SystemProfilesConnectionMinCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_MIN_COMPANY_ID_ASC',
  SystemProfilesConnectionMinCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_MIN_COMPANY_ID_DESC',
  SystemProfilesConnectionMinCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_MIN_CREATED_AT_ASC',
  SystemProfilesConnectionMinCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_MIN_CREATED_AT_DESC',
  SystemProfilesConnectionMinDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_MIN_DEFAULT_ASC',
  SystemProfilesConnectionMinDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_MIN_DEFAULT_DESC',
  SystemProfilesConnectionMinIdAsc = 'SYSTEM_PROFILES_CONNECTION_MIN_ID_ASC',
  SystemProfilesConnectionMinIdDesc = 'SYSTEM_PROFILES_CONNECTION_MIN_ID_DESC',
  SystemProfilesConnectionMinNameAsc = 'SYSTEM_PROFILES_CONNECTION_MIN_NAME_ASC',
  SystemProfilesConnectionMinNameDesc = 'SYSTEM_PROFILES_CONNECTION_MIN_NAME_DESC',
  SystemProfilesConnectionStddevPopulationCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SystemProfilesConnectionStddevPopulationCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SystemProfilesConnectionStddevPopulationCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemProfilesConnectionStddevPopulationCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemProfilesConnectionStddevPopulationDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_DEFAULT_ASC',
  SystemProfilesConnectionStddevPopulationDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_DEFAULT_DESC',
  SystemProfilesConnectionStddevPopulationIdAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemProfilesConnectionStddevPopulationIdDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemProfilesConnectionStddevPopulationNameAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  SystemProfilesConnectionStddevPopulationNameDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  SystemProfilesConnectionStddevSampleCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SystemProfilesConnectionStddevSampleCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SystemProfilesConnectionStddevSampleCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemProfilesConnectionStddevSampleCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemProfilesConnectionStddevSampleDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_DEFAULT_ASC',
  SystemProfilesConnectionStddevSampleDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_DEFAULT_DESC',
  SystemProfilesConnectionStddevSampleIdAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemProfilesConnectionStddevSampleIdDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemProfilesConnectionStddevSampleNameAsc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  SystemProfilesConnectionStddevSampleNameDesc = 'SYSTEM_PROFILES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  SystemProfilesConnectionSumCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_SUM_COMPANY_ID_ASC',
  SystemProfilesConnectionSumCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_SUM_COMPANY_ID_DESC',
  SystemProfilesConnectionSumCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_SUM_CREATED_AT_ASC',
  SystemProfilesConnectionSumCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_SUM_CREATED_AT_DESC',
  SystemProfilesConnectionSumDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_SUM_DEFAULT_ASC',
  SystemProfilesConnectionSumDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_SUM_DEFAULT_DESC',
  SystemProfilesConnectionSumIdAsc = 'SYSTEM_PROFILES_CONNECTION_SUM_ID_ASC',
  SystemProfilesConnectionSumIdDesc = 'SYSTEM_PROFILES_CONNECTION_SUM_ID_DESC',
  SystemProfilesConnectionSumNameAsc = 'SYSTEM_PROFILES_CONNECTION_SUM_NAME_ASC',
  SystemProfilesConnectionSumNameDesc = 'SYSTEM_PROFILES_CONNECTION_SUM_NAME_DESC',
  SystemProfilesConnectionVariancePopulationCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SystemProfilesConnectionVariancePopulationCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SystemProfilesConnectionVariancePopulationCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemProfilesConnectionVariancePopulationCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemProfilesConnectionVariancePopulationDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_DEFAULT_ASC',
  SystemProfilesConnectionVariancePopulationDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_DEFAULT_DESC',
  SystemProfilesConnectionVariancePopulationIdAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemProfilesConnectionVariancePopulationIdDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemProfilesConnectionVariancePopulationNameAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  SystemProfilesConnectionVariancePopulationNameDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  SystemProfilesConnectionVarianceSampleCompanyIdAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SystemProfilesConnectionVarianceSampleCompanyIdDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SystemProfilesConnectionVarianceSampleCreatedAtAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemProfilesConnectionVarianceSampleCreatedAtDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemProfilesConnectionVarianceSampleDefaultAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_DEFAULT_ASC',
  SystemProfilesConnectionVarianceSampleDefaultDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_DEFAULT_DESC',
  SystemProfilesConnectionVarianceSampleIdAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemProfilesConnectionVarianceSampleIdDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemProfilesConnectionVarianceSampleNameAsc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  SystemProfilesConnectionVarianceSampleNameDesc = 'SYSTEM_PROFILES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  TaskReportsConnectionArrayAggAddressAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsConnectionArrayAggAddressDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionArrayAggEndDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsConnectionArrayAggEndDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionArrayAggFormsAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsConnectionArrayAggFormsDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsConnectionArrayAggIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsConnectionArrayAggIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsConnectionArrayAggJobIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsConnectionArrayAggJobIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionArrayAggPositionAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsConnectionArrayAggPositionDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsConnectionArrayAggPriorityAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsConnectionArrayAggPriorityDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionArrayAggStartDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsConnectionArrayAggStartDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsConnectionArrayAggStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsConnectionArrayAggStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionArrayAggTitleAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsConnectionArrayAggTitleDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsConnectionArrayAggUidAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsConnectionArrayAggUidDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsConnectionAverageAddressAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsConnectionAverageAddressDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionAverageCompanyIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsConnectionAverageCompanyIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsConnectionAverageCompletionDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsConnectionAverageCompletionDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionAverageCreatedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsConnectionAverageCreatedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsConnectionAverageCreatedByAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsConnectionAverageCreatedByDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsConnectionAverageDescriptionAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsConnectionAverageDescriptionDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionAverageEndDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsConnectionAverageEndDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionAverageFormsAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsConnectionAverageFormsDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsConnectionAverageIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsConnectionAverageIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsConnectionAverageIsArchivedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsConnectionAverageIsArchivedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsConnectionAverageIsColoredAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsConnectionAverageIsColoredDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsConnectionAverageIsCompletedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsConnectionAverageIsCompletedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsConnectionAverageIsFieldAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsConnectionAverageIsFieldDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsConnectionAverageIsTemplateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsConnectionAverageIsTemplateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsConnectionAverageJobIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsConnectionAverageJobIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsConnectionAverageLabelIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsConnectionAverageLabelIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsConnectionAverageLabelNameAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsConnectionAverageLabelNameDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsConnectionAverageModifiedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsConnectionAverageModifiedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionAveragePositionAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsConnectionAveragePositionDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsConnectionAveragePriorityAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsConnectionAveragePriorityDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsConnectionAverageProjectIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsConnectionAverageProjectIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionAverageStartDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsConnectionAverageStartDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsConnectionAverageStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsConnectionAverageStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsConnectionAverageSystemIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsConnectionAverageSystemIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsConnectionAverageTaskIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsConnectionAverageTaskIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionAverageTitleAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsConnectionAverageTitleDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsConnectionAverageUidAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsConnectionAverageUidDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionAverageVisitCountAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsConnectionAverageVisitCountDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsConnectionAverageVisitIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsConnectionAverageVisitIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsConnectionCountAsc = 'TASK_REPORTS_CONNECTION_COUNT_ASC',
  TaskReportsConnectionCountDesc = 'TASK_REPORTS_CONNECTION_COUNT_DESC',
  TaskReportsConnectionDistinctCountAddressAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsConnectionDistinctCountAddressDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionDistinctCountFormsAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsConnectionDistinctCountFormsDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsConnectionDistinctCountIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsConnectionDistinctCountIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionDistinctCountPositionAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsConnectionDistinctCountPositionDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsConnectionDistinctCountStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsConnectionDistinctCountStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionDistinctCountTitleAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsConnectionDistinctCountTitleDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsConnectionDistinctCountUidAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsConnectionDistinctCountUidDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsConnectionMaxAddressAsc = 'TASK_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsConnectionMaxAddressDesc = 'TASK_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsConnectionMaxCompanyIdAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsConnectionMaxCompanyIdDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsConnectionMaxCompletionDateAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsConnectionMaxCompletionDateDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionMaxCreatedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsConnectionMaxCreatedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsConnectionMaxCreatedByAsc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsConnectionMaxCreatedByDesc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsConnectionMaxDescriptionAsc = 'TASK_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsConnectionMaxDescriptionDesc = 'TASK_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMaxEndDateAsc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsConnectionMaxEndDateDesc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionMaxFormsAsc = 'TASK_REPORTS_CONNECTION_MAX_FORMS_ASC',
  TaskReportsConnectionMaxFormsDesc = 'TASK_REPORTS_CONNECTION_MAX_FORMS_DESC',
  TaskReportsConnectionMaxIdAsc = 'TASK_REPORTS_CONNECTION_MAX_ID_ASC',
  TaskReportsConnectionMaxIdDesc = 'TASK_REPORTS_CONNECTION_MAX_ID_DESC',
  TaskReportsConnectionMaxIsArchivedAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsConnectionMaxIsArchivedDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsConnectionMaxIsColoredAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsConnectionMaxIsColoredDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsConnectionMaxIsCompletedAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsConnectionMaxIsCompletedDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsConnectionMaxIsFieldAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsConnectionMaxIsFieldDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsConnectionMaxIsTemplateAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsConnectionMaxIsTemplateDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsConnectionMaxJobIdAsc = 'TASK_REPORTS_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsConnectionMaxJobIdDesc = 'TASK_REPORTS_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsConnectionMaxLabelIdAsc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsConnectionMaxLabelIdDesc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsConnectionMaxLabelNameAsc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsConnectionMaxLabelNameDesc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsConnectionMaxModifiedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsConnectionMaxModifiedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionMaxPositionAsc = 'TASK_REPORTS_CONNECTION_MAX_POSITION_ASC',
  TaskReportsConnectionMaxPositionDesc = 'TASK_REPORTS_CONNECTION_MAX_POSITION_DESC',
  TaskReportsConnectionMaxPriorityAsc = 'TASK_REPORTS_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsConnectionMaxPriorityDesc = 'TASK_REPORTS_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsConnectionMaxProjectIdAsc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsConnectionMaxProjectIdDesc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMaxStartDateAsc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsConnectionMaxStartDateDesc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsConnectionMaxStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_STATUS_ASC',
  TaskReportsConnectionMaxStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_STATUS_DESC',
  TaskReportsConnectionMaxSystemIdAsc = 'TASK_REPORTS_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsConnectionMaxSystemIdDesc = 'TASK_REPORTS_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsConnectionMaxTaskIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsConnectionMaxTaskIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionMaxTitleAsc = 'TASK_REPORTS_CONNECTION_MAX_TITLE_ASC',
  TaskReportsConnectionMaxTitleDesc = 'TASK_REPORTS_CONNECTION_MAX_TITLE_DESC',
  TaskReportsConnectionMaxUidAsc = 'TASK_REPORTS_CONNECTION_MAX_UID_ASC',
  TaskReportsConnectionMaxUidDesc = 'TASK_REPORTS_CONNECTION_MAX_UID_DESC',
  TaskReportsConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionMaxVisitCountAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsConnectionMaxVisitCountDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsConnectionMaxVisitIdAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsConnectionMaxVisitIdDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsConnectionMinAddressAsc = 'TASK_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsConnectionMinAddressDesc = 'TASK_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsConnectionMinAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsConnectionMinAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsConnectionMinCompanyIdAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsConnectionMinCompanyIdDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsConnectionMinCompletionDateAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsConnectionMinCompletionDateDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsConnectionMinCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsConnectionMinCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionMinCreatedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsConnectionMinCreatedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsConnectionMinCreatedByAsc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsConnectionMinCreatedByDesc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsConnectionMinDescriptionAsc = 'TASK_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsConnectionMinDescriptionDesc = 'TASK_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMinEndDateAsc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsConnectionMinEndDateDesc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionMinFormsAsc = 'TASK_REPORTS_CONNECTION_MIN_FORMS_ASC',
  TaskReportsConnectionMinFormsDesc = 'TASK_REPORTS_CONNECTION_MIN_FORMS_DESC',
  TaskReportsConnectionMinIdAsc = 'TASK_REPORTS_CONNECTION_MIN_ID_ASC',
  TaskReportsConnectionMinIdDesc = 'TASK_REPORTS_CONNECTION_MIN_ID_DESC',
  TaskReportsConnectionMinIsArchivedAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsConnectionMinIsArchivedDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsConnectionMinIsColoredAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsConnectionMinIsColoredDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsConnectionMinIsCompletedAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsConnectionMinIsCompletedDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsConnectionMinIsFieldAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsConnectionMinIsFieldDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsConnectionMinIsTemplateAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsConnectionMinIsTemplateDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsConnectionMinJobIdAsc = 'TASK_REPORTS_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsConnectionMinJobIdDesc = 'TASK_REPORTS_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsConnectionMinLabelIdAsc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsConnectionMinLabelIdDesc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsConnectionMinLabelNameAsc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsConnectionMinLabelNameDesc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsConnectionMinModifiedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsConnectionMinModifiedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsConnectionMinNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionMinNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionMinPositionAsc = 'TASK_REPORTS_CONNECTION_MIN_POSITION_ASC',
  TaskReportsConnectionMinPositionDesc = 'TASK_REPORTS_CONNECTION_MIN_POSITION_DESC',
  TaskReportsConnectionMinPriorityAsc = 'TASK_REPORTS_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsConnectionMinPriorityDesc = 'TASK_REPORTS_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsConnectionMinProjectIdAsc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsConnectionMinProjectIdDesc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsConnectionMinProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionMinProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMinStartDateAsc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsConnectionMinStartDateDesc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsConnectionMinStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_STATUS_ASC',
  TaskReportsConnectionMinStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_STATUS_DESC',
  TaskReportsConnectionMinSystemIdAsc = 'TASK_REPORTS_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsConnectionMinSystemIdDesc = 'TASK_REPORTS_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsConnectionMinTaskIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsConnectionMinTaskIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionMinTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsConnectionMinTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionMinTitleAsc = 'TASK_REPORTS_CONNECTION_MIN_TITLE_ASC',
  TaskReportsConnectionMinTitleDesc = 'TASK_REPORTS_CONNECTION_MIN_TITLE_DESC',
  TaskReportsConnectionMinUidAsc = 'TASK_REPORTS_CONNECTION_MIN_UID_ASC',
  TaskReportsConnectionMinUidDesc = 'TASK_REPORTS_CONNECTION_MIN_UID_DESC',
  TaskReportsConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionMinVisitCountAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsConnectionMinVisitCountDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsConnectionMinVisitIdAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsConnectionMinVisitIdDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsConnectionMinVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsConnectionMinVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsConnectionStddevPopulationIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsConnectionStddevPopulationIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsConnectionStddevPopulationUidAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsConnectionStddevPopulationUidDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsConnectionStddevSampleAddressAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsConnectionStddevSampleAddressDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionStddevSampleFormsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsConnectionStddevSampleFormsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsConnectionStddevSampleIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsConnectionStddevSampleIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionStddevSamplePositionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsConnectionStddevSamplePositionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsConnectionStddevSampleStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsConnectionStddevSampleStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionStddevSampleTitleAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsConnectionStddevSampleTitleDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsConnectionStddevSampleUidAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsConnectionStddevSampleUidDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsConnectionSumAddressAsc = 'TASK_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsConnectionSumAddressDesc = 'TASK_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsConnectionSumAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsConnectionSumAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsConnectionSumCompanyIdAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsConnectionSumCompanyIdDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsConnectionSumCompletionDateAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsConnectionSumCompletionDateDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsConnectionSumCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsConnectionSumCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionSumCreatedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsConnectionSumCreatedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsConnectionSumCreatedByAsc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsConnectionSumCreatedByDesc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsConnectionSumDescriptionAsc = 'TASK_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsConnectionSumDescriptionDesc = 'TASK_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionSumEndDateAsc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsConnectionSumEndDateDesc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionSumFormsAsc = 'TASK_REPORTS_CONNECTION_SUM_FORMS_ASC',
  TaskReportsConnectionSumFormsDesc = 'TASK_REPORTS_CONNECTION_SUM_FORMS_DESC',
  TaskReportsConnectionSumIdAsc = 'TASK_REPORTS_CONNECTION_SUM_ID_ASC',
  TaskReportsConnectionSumIdDesc = 'TASK_REPORTS_CONNECTION_SUM_ID_DESC',
  TaskReportsConnectionSumIsArchivedAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsConnectionSumIsArchivedDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsConnectionSumIsColoredAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsConnectionSumIsColoredDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsConnectionSumIsCompletedAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsConnectionSumIsCompletedDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsConnectionSumIsFieldAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsConnectionSumIsFieldDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsConnectionSumIsTemplateAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsConnectionSumIsTemplateDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsConnectionSumJobIdAsc = 'TASK_REPORTS_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsConnectionSumJobIdDesc = 'TASK_REPORTS_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsConnectionSumLabelIdAsc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsConnectionSumLabelIdDesc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsConnectionSumLabelNameAsc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsConnectionSumLabelNameDesc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsConnectionSumModifiedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsConnectionSumModifiedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsConnectionSumNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionSumNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionSumPositionAsc = 'TASK_REPORTS_CONNECTION_SUM_POSITION_ASC',
  TaskReportsConnectionSumPositionDesc = 'TASK_REPORTS_CONNECTION_SUM_POSITION_DESC',
  TaskReportsConnectionSumPriorityAsc = 'TASK_REPORTS_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsConnectionSumPriorityDesc = 'TASK_REPORTS_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsConnectionSumProjectIdAsc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsConnectionSumProjectIdDesc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsConnectionSumProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionSumProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionSumStartDateAsc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsConnectionSumStartDateDesc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsConnectionSumStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_STATUS_ASC',
  TaskReportsConnectionSumStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_STATUS_DESC',
  TaskReportsConnectionSumSystemIdAsc = 'TASK_REPORTS_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsConnectionSumSystemIdDesc = 'TASK_REPORTS_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsConnectionSumTaskIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsConnectionSumTaskIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionSumTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsConnectionSumTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionSumTitleAsc = 'TASK_REPORTS_CONNECTION_SUM_TITLE_ASC',
  TaskReportsConnectionSumTitleDesc = 'TASK_REPORTS_CONNECTION_SUM_TITLE_DESC',
  TaskReportsConnectionSumUidAsc = 'TASK_REPORTS_CONNECTION_SUM_UID_ASC',
  TaskReportsConnectionSumUidDesc = 'TASK_REPORTS_CONNECTION_SUM_UID_DESC',
  TaskReportsConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionSumVisitCountAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsConnectionSumVisitCountDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsConnectionSumVisitIdAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsConnectionSumVisitIdDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsConnectionSumVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsConnectionSumVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsConnectionVariancePopulationIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsConnectionVariancePopulationIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsConnectionVariancePopulationUidAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsConnectionVariancePopulationUidDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsConnectionVarianceSampleIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsConnectionVarianceSampleIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsConnectionVarianceSampleUidAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsConnectionVarianceSampleUidDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TeamsConnectionArrayAggCompanyIdAsc = 'TEAMS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TeamsConnectionArrayAggCompanyIdDesc = 'TEAMS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TeamsConnectionArrayAggCreatedAtAsc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TeamsConnectionArrayAggCreatedAtDesc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TeamsConnectionArrayAggCreatedByAsc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TeamsConnectionArrayAggCreatedByDesc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TeamsConnectionArrayAggDescriptionAsc = 'TEAMS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TeamsConnectionArrayAggDescriptionDesc = 'TEAMS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TeamsConnectionArrayAggIdAsc = 'TEAMS_CONNECTION_ARRAY_AGG_ID_ASC',
  TeamsConnectionArrayAggIdDesc = 'TEAMS_CONNECTION_ARRAY_AGG_ID_DESC',
  TeamsConnectionArrayAggImageUrlAsc = 'TEAMS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  TeamsConnectionArrayAggImageUrlDesc = 'TEAMS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  TeamsConnectionArrayAggNameAsc = 'TEAMS_CONNECTION_ARRAY_AGG_NAME_ASC',
  TeamsConnectionArrayAggNameDesc = 'TEAMS_CONNECTION_ARRAY_AGG_NAME_DESC',
  TeamsConnectionArrayAggPrivilegeAllAsc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  TeamsConnectionArrayAggPrivilegeAllDesc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  TeamsConnectionArrayAggPrivilegeOwnAsc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  TeamsConnectionArrayAggPrivilegeOwnDesc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  TeamsConnectionArrayAggUpdatedAtAsc = 'TEAMS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  TeamsConnectionArrayAggUpdatedAtDesc = 'TEAMS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  TeamsConnectionAverageCompanyIdAsc = 'TEAMS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TeamsConnectionAverageCompanyIdDesc = 'TEAMS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TeamsConnectionAverageCreatedAtAsc = 'TEAMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TeamsConnectionAverageCreatedAtDesc = 'TEAMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TeamsConnectionAverageCreatedByAsc = 'TEAMS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TeamsConnectionAverageCreatedByDesc = 'TEAMS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TeamsConnectionAverageDescriptionAsc = 'TEAMS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TeamsConnectionAverageDescriptionDesc = 'TEAMS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TeamsConnectionAverageIdAsc = 'TEAMS_CONNECTION_AVERAGE_ID_ASC',
  TeamsConnectionAverageIdDesc = 'TEAMS_CONNECTION_AVERAGE_ID_DESC',
  TeamsConnectionAverageImageUrlAsc = 'TEAMS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  TeamsConnectionAverageImageUrlDesc = 'TEAMS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  TeamsConnectionAverageNameAsc = 'TEAMS_CONNECTION_AVERAGE_NAME_ASC',
  TeamsConnectionAverageNameDesc = 'TEAMS_CONNECTION_AVERAGE_NAME_DESC',
  TeamsConnectionAveragePrivilegeAllAsc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  TeamsConnectionAveragePrivilegeAllDesc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  TeamsConnectionAveragePrivilegeOwnAsc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  TeamsConnectionAveragePrivilegeOwnDesc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  TeamsConnectionAverageUpdatedAtAsc = 'TEAMS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  TeamsConnectionAverageUpdatedAtDesc = 'TEAMS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  TeamsConnectionCountAsc = 'TEAMS_CONNECTION_COUNT_ASC',
  TeamsConnectionCountDesc = 'TEAMS_CONNECTION_COUNT_DESC',
  TeamsConnectionDistinctCountCompanyIdAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TeamsConnectionDistinctCountCompanyIdDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TeamsConnectionDistinctCountCreatedAtAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TeamsConnectionDistinctCountCreatedAtDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TeamsConnectionDistinctCountCreatedByAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TeamsConnectionDistinctCountCreatedByDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TeamsConnectionDistinctCountDescriptionAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TeamsConnectionDistinctCountDescriptionDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TeamsConnectionDistinctCountIdAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TeamsConnectionDistinctCountIdDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TeamsConnectionDistinctCountImageUrlAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  TeamsConnectionDistinctCountImageUrlDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  TeamsConnectionDistinctCountNameAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  TeamsConnectionDistinctCountNameDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  TeamsConnectionDistinctCountPrivilegeAllAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  TeamsConnectionDistinctCountPrivilegeAllDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  TeamsConnectionDistinctCountPrivilegeOwnAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  TeamsConnectionDistinctCountPrivilegeOwnDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  TeamsConnectionDistinctCountUpdatedAtAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  TeamsConnectionDistinctCountUpdatedAtDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  TeamsConnectionMaxCompanyIdAsc = 'TEAMS_CONNECTION_MAX_COMPANY_ID_ASC',
  TeamsConnectionMaxCompanyIdDesc = 'TEAMS_CONNECTION_MAX_COMPANY_ID_DESC',
  TeamsConnectionMaxCreatedAtAsc = 'TEAMS_CONNECTION_MAX_CREATED_AT_ASC',
  TeamsConnectionMaxCreatedAtDesc = 'TEAMS_CONNECTION_MAX_CREATED_AT_DESC',
  TeamsConnectionMaxCreatedByAsc = 'TEAMS_CONNECTION_MAX_CREATED_BY_ASC',
  TeamsConnectionMaxCreatedByDesc = 'TEAMS_CONNECTION_MAX_CREATED_BY_DESC',
  TeamsConnectionMaxDescriptionAsc = 'TEAMS_CONNECTION_MAX_DESCRIPTION_ASC',
  TeamsConnectionMaxDescriptionDesc = 'TEAMS_CONNECTION_MAX_DESCRIPTION_DESC',
  TeamsConnectionMaxIdAsc = 'TEAMS_CONNECTION_MAX_ID_ASC',
  TeamsConnectionMaxIdDesc = 'TEAMS_CONNECTION_MAX_ID_DESC',
  TeamsConnectionMaxImageUrlAsc = 'TEAMS_CONNECTION_MAX_IMAGE_URL_ASC',
  TeamsConnectionMaxImageUrlDesc = 'TEAMS_CONNECTION_MAX_IMAGE_URL_DESC',
  TeamsConnectionMaxNameAsc = 'TEAMS_CONNECTION_MAX_NAME_ASC',
  TeamsConnectionMaxNameDesc = 'TEAMS_CONNECTION_MAX_NAME_DESC',
  TeamsConnectionMaxPrivilegeAllAsc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  TeamsConnectionMaxPrivilegeAllDesc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  TeamsConnectionMaxPrivilegeOwnAsc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  TeamsConnectionMaxPrivilegeOwnDesc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  TeamsConnectionMaxUpdatedAtAsc = 'TEAMS_CONNECTION_MAX_UPDATED_AT_ASC',
  TeamsConnectionMaxUpdatedAtDesc = 'TEAMS_CONNECTION_MAX_UPDATED_AT_DESC',
  TeamsConnectionMinCompanyIdAsc = 'TEAMS_CONNECTION_MIN_COMPANY_ID_ASC',
  TeamsConnectionMinCompanyIdDesc = 'TEAMS_CONNECTION_MIN_COMPANY_ID_DESC',
  TeamsConnectionMinCreatedAtAsc = 'TEAMS_CONNECTION_MIN_CREATED_AT_ASC',
  TeamsConnectionMinCreatedAtDesc = 'TEAMS_CONNECTION_MIN_CREATED_AT_DESC',
  TeamsConnectionMinCreatedByAsc = 'TEAMS_CONNECTION_MIN_CREATED_BY_ASC',
  TeamsConnectionMinCreatedByDesc = 'TEAMS_CONNECTION_MIN_CREATED_BY_DESC',
  TeamsConnectionMinDescriptionAsc = 'TEAMS_CONNECTION_MIN_DESCRIPTION_ASC',
  TeamsConnectionMinDescriptionDesc = 'TEAMS_CONNECTION_MIN_DESCRIPTION_DESC',
  TeamsConnectionMinIdAsc = 'TEAMS_CONNECTION_MIN_ID_ASC',
  TeamsConnectionMinIdDesc = 'TEAMS_CONNECTION_MIN_ID_DESC',
  TeamsConnectionMinImageUrlAsc = 'TEAMS_CONNECTION_MIN_IMAGE_URL_ASC',
  TeamsConnectionMinImageUrlDesc = 'TEAMS_CONNECTION_MIN_IMAGE_URL_DESC',
  TeamsConnectionMinNameAsc = 'TEAMS_CONNECTION_MIN_NAME_ASC',
  TeamsConnectionMinNameDesc = 'TEAMS_CONNECTION_MIN_NAME_DESC',
  TeamsConnectionMinPrivilegeAllAsc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  TeamsConnectionMinPrivilegeAllDesc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  TeamsConnectionMinPrivilegeOwnAsc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  TeamsConnectionMinPrivilegeOwnDesc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  TeamsConnectionMinUpdatedAtAsc = 'TEAMS_CONNECTION_MIN_UPDATED_AT_ASC',
  TeamsConnectionMinUpdatedAtDesc = 'TEAMS_CONNECTION_MIN_UPDATED_AT_DESC',
  TeamsConnectionStddevPopulationCompanyIdAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TeamsConnectionStddevPopulationCompanyIdDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TeamsConnectionStddevPopulationCreatedAtAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TeamsConnectionStddevPopulationCreatedAtDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TeamsConnectionStddevPopulationCreatedByAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TeamsConnectionStddevPopulationCreatedByDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TeamsConnectionStddevPopulationDescriptionAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TeamsConnectionStddevPopulationDescriptionDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TeamsConnectionStddevPopulationIdAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TeamsConnectionStddevPopulationIdDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TeamsConnectionStddevPopulationImageUrlAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  TeamsConnectionStddevPopulationImageUrlDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  TeamsConnectionStddevPopulationNameAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  TeamsConnectionStddevPopulationNameDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  TeamsConnectionStddevPopulationPrivilegeAllAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  TeamsConnectionStddevPopulationPrivilegeAllDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  TeamsConnectionStddevPopulationPrivilegeOwnAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  TeamsConnectionStddevPopulationPrivilegeOwnDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  TeamsConnectionStddevPopulationUpdatedAtAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  TeamsConnectionStddevPopulationUpdatedAtDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  TeamsConnectionStddevSampleCompanyIdAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TeamsConnectionStddevSampleCompanyIdDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TeamsConnectionStddevSampleCreatedAtAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TeamsConnectionStddevSampleCreatedAtDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TeamsConnectionStddevSampleCreatedByAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TeamsConnectionStddevSampleCreatedByDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TeamsConnectionStddevSampleDescriptionAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TeamsConnectionStddevSampleDescriptionDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TeamsConnectionStddevSampleIdAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TeamsConnectionStddevSampleIdDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TeamsConnectionStddevSampleImageUrlAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  TeamsConnectionStddevSampleImageUrlDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  TeamsConnectionStddevSampleNameAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  TeamsConnectionStddevSampleNameDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  TeamsConnectionStddevSamplePrivilegeAllAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  TeamsConnectionStddevSamplePrivilegeAllDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  TeamsConnectionStddevSamplePrivilegeOwnAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  TeamsConnectionStddevSamplePrivilegeOwnDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  TeamsConnectionStddevSampleUpdatedAtAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TeamsConnectionStddevSampleUpdatedAtDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TeamsConnectionSumCompanyIdAsc = 'TEAMS_CONNECTION_SUM_COMPANY_ID_ASC',
  TeamsConnectionSumCompanyIdDesc = 'TEAMS_CONNECTION_SUM_COMPANY_ID_DESC',
  TeamsConnectionSumCreatedAtAsc = 'TEAMS_CONNECTION_SUM_CREATED_AT_ASC',
  TeamsConnectionSumCreatedAtDesc = 'TEAMS_CONNECTION_SUM_CREATED_AT_DESC',
  TeamsConnectionSumCreatedByAsc = 'TEAMS_CONNECTION_SUM_CREATED_BY_ASC',
  TeamsConnectionSumCreatedByDesc = 'TEAMS_CONNECTION_SUM_CREATED_BY_DESC',
  TeamsConnectionSumDescriptionAsc = 'TEAMS_CONNECTION_SUM_DESCRIPTION_ASC',
  TeamsConnectionSumDescriptionDesc = 'TEAMS_CONNECTION_SUM_DESCRIPTION_DESC',
  TeamsConnectionSumIdAsc = 'TEAMS_CONNECTION_SUM_ID_ASC',
  TeamsConnectionSumIdDesc = 'TEAMS_CONNECTION_SUM_ID_DESC',
  TeamsConnectionSumImageUrlAsc = 'TEAMS_CONNECTION_SUM_IMAGE_URL_ASC',
  TeamsConnectionSumImageUrlDesc = 'TEAMS_CONNECTION_SUM_IMAGE_URL_DESC',
  TeamsConnectionSumNameAsc = 'TEAMS_CONNECTION_SUM_NAME_ASC',
  TeamsConnectionSumNameDesc = 'TEAMS_CONNECTION_SUM_NAME_DESC',
  TeamsConnectionSumPrivilegeAllAsc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  TeamsConnectionSumPrivilegeAllDesc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  TeamsConnectionSumPrivilegeOwnAsc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  TeamsConnectionSumPrivilegeOwnDesc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  TeamsConnectionSumUpdatedAtAsc = 'TEAMS_CONNECTION_SUM_UPDATED_AT_ASC',
  TeamsConnectionSumUpdatedAtDesc = 'TEAMS_CONNECTION_SUM_UPDATED_AT_DESC',
  TeamsConnectionVariancePopulationCompanyIdAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TeamsConnectionVariancePopulationCompanyIdDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TeamsConnectionVariancePopulationCreatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TeamsConnectionVariancePopulationCreatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TeamsConnectionVariancePopulationCreatedByAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TeamsConnectionVariancePopulationCreatedByDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TeamsConnectionVariancePopulationDescriptionAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TeamsConnectionVariancePopulationDescriptionDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TeamsConnectionVariancePopulationIdAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TeamsConnectionVariancePopulationIdDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TeamsConnectionVariancePopulationImageUrlAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  TeamsConnectionVariancePopulationImageUrlDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  TeamsConnectionVariancePopulationNameAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  TeamsConnectionVariancePopulationNameDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  TeamsConnectionVariancePopulationPrivilegeAllAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  TeamsConnectionVariancePopulationPrivilegeAllDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  TeamsConnectionVariancePopulationPrivilegeOwnAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  TeamsConnectionVariancePopulationPrivilegeOwnDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  TeamsConnectionVariancePopulationUpdatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TeamsConnectionVariancePopulationUpdatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TeamsConnectionVarianceSampleCompanyIdAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TeamsConnectionVarianceSampleCompanyIdDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TeamsConnectionVarianceSampleCreatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TeamsConnectionVarianceSampleCreatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TeamsConnectionVarianceSampleCreatedByAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TeamsConnectionVarianceSampleCreatedByDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TeamsConnectionVarianceSampleDescriptionAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TeamsConnectionVarianceSampleDescriptionDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TeamsConnectionVarianceSampleIdAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TeamsConnectionVarianceSampleIdDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TeamsConnectionVarianceSampleImageUrlAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  TeamsConnectionVarianceSampleImageUrlDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  TeamsConnectionVarianceSampleNameAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  TeamsConnectionVarianceSampleNameDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  TeamsConnectionVarianceSamplePrivilegeAllAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  TeamsConnectionVarianceSamplePrivilegeAllDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  TeamsConnectionVarianceSamplePrivilegeOwnAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  TeamsConnectionVarianceSamplePrivilegeOwnDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  TeamsConnectionVarianceSampleUpdatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TeamsConnectionVarianceSampleUpdatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserSlotAsc = 'USER_SLOT_ASC',
  UserSlotDesc = 'USER_SLOT_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC'
}

export type Company = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actions: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsConnection: ActionsConnection;
  address: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Analytic`. */
  analytics: Array<Analytic>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectCompanyIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportCompanyIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Call`. */
  calls: Array<Call>;
  /** Reads and enables pagination through a set of `Call`. */
  callsConnection: CallsConnection;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItems: Array<CatalogItem>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsConnection: CatalogItemsConnection;
  /** Reads and enables pagination through a set of `CompanyNotificationSetting`. */
  companyNotificationSettings: Array<CompanyNotificationSetting>;
  /** Reads and enables pagination through a set of `CompanyNotificationSetting`. */
  companyNotificationSettingsConnection: CompanyNotificationSettingsConnection;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinations: Array<CompanyNotificationSettingsRoleDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinationsConnection: CompanyNotificationSettingsRoleDestinationsConnection;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinations: Array<CompanyNotificationSettingsSingleDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinationsConnection: CompanyNotificationSettingsSingleDestinationsConnection;
  /** Reads and enables pagination through a set of `CompanyPhone`. */
  companyPhones: Array<CompanyPhone>;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsers: Array<CompanyUser>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCompanyIdAndCreatedByContactId: CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCompanyIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCompanyIdAndReferrerContactId: CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCompanyIdAndReferrerContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  customerPortalEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboards: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsConnection: DashboardsConnection;
  /** Reads and enables pagination through a set of `EmailAccount`. */
  emailAccounts: Array<EmailAccount>;
  emailFooter: Maybe<Scalars['String']['output']>;
  emailHeader: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFolders: Array<FilterFolder>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByDashboardCompanyIdAndFilterFolderId: CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByDashboardCompanyIdAndFilterFolderIdList: Array<FilterFolder>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByFilterCompanyIdAndFilterFolderId: CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByFilterCompanyIdAndFilterFolderIdList: Array<FilterFolder>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersConnection: FilterFoldersConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filters: Array<Filter>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersConnection: FiltersConnection;
  /** Reads and enables pagination through a set of `GeolocationUserSetting`. */
  geolocationUserSettings: Array<GeolocationUserSetting>;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Integration`. */
  integrations: Array<Integration>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictions: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCompanyIdAndJurisdictionId: CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCompanyIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportCompanyIdAndJurisdictionId: CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportCompanyIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsConnection: JurisdictionsConnection;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskCompanyIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportCompanyIdAndFirstLabelIdList: Array<Label>;
  logoUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByFilterCompanyIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskCompanyIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskCompanyIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportCompanyIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportCompanyIdAndCreatedByList: Array<MainUser>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskId: CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportCompanyIdAndTaskId: CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportCompanyIdAndTemplateTaskId: CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportCompanyIdAndParentProjectId: CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportCompanyIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskCompanyIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectCompanyIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportCompanyIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportCompanyIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportCompanyIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskCompanyIdAndProjectId: CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskCompanyIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCompanyIdAndParentProjectId: CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCompanyIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportCompanyIdAndProjectId: CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportCompanyIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderCompanyIdAndProjectId: CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderCompanyIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportCompanyIdAndProjectId: CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportCompanyIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  reminders: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersConnection: RemindersConnection;
  /** Reads and enables pagination through a set of `Role`. */
  roles: Array<Role>;
  settings: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivities: Array<SmsActivity>;
  stripeCustomerId: Maybe<Scalars['String']['output']>;
  subdomainName: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SystemBudget`. */
  systemBudgets: Array<SystemBudget>;
  /** Reads and enables pagination through a set of `SystemProfile`. */
  systemProfiles: Array<SystemProfile>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskCompanyIdAndSystemId: CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teams: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportCompanyIdAndTeamId: CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportCompanyIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsConnection: TeamsConnection;
  /** TZ database name. Look from: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
  timezone: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
  userSlot: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByDashboardCompanyIdAndCreatedBy: CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCompanyIdAndCreatedBy: CompanyUsersByProjectCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCompanyIdAndOwnerId: CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCompanyIdAndProjectManagerId: CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCompanyIdAndSalesRepId: CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndCreatedBy: CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndOwnerId: CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndProjectManagerId: CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndSalesRepId: CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndUserId: CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCompanyIdAndWorkerValue: CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderCompanyIdAndAssigneeId: CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderCompanyIdAndCreatedBy: CompanyUsersByReminderCompanyIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByTeamCompanyIdAndCreatedBy: CompanyUsersByTeamCompanyIdAndCreatedByManyToManyConnection;
  website: Maybe<Scalars['String']['output']>;
};


export type CompanyActionsArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type CompanyActionsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type CompanyAnalyticsArgs = {
  condition: InputMaybe<AnalyticCondition>;
  filter: InputMaybe<AnalyticFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AnalyticsOrderBy>>;
};


export type CompanyBlueprintsByProjectCompanyIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type CompanyBlueprintsByProjectReportCompanyIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type CompanyCallsArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type CompanyCallsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type CompanyCatalogItemsArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CompanyCatalogItemsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsArgs = {
  condition: InputMaybe<CompanyNotificationSettingCondition>;
  filter: InputMaybe<CompanyNotificationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingCondition>;
  filter: InputMaybe<CompanyNotificationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsRoleDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsRoleDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsSingleDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyCompanyNotificationSettingsSingleDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyCompanyPhonesArgs = {
  condition: InputMaybe<CompanyPhoneCondition>;
  filter: InputMaybe<CompanyPhoneFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhonesOrderBy>>;
};


export type CompanyCompanyUsersArgs = {
  condition: InputMaybe<CompanyUserCondition>;
  filter: InputMaybe<CompanyUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUsersOrderBy>>;
};


export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type CompanyContactsByProjectCompanyIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type CompanyContactsByProjectCompanyIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type CompanyDashboardsArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type CompanyDashboardsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};


export type CompanyEmailAccountsArgs = {
  condition: InputMaybe<EmailAccountCondition>;
  filter: InputMaybe<EmailAccountFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountsOrderBy>>;
};


export type CompanyFilterFoldersArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdListArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdListArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFilterFoldersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type CompanyFiltersArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type CompanyFiltersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type CompanyGeolocationUserSettingsArgs = {
  condition: InputMaybe<GeolocationUserSettingCondition>;
  filter: InputMaybe<GeolocationUserSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationUserSettingsOrderBy>>;
};


export type CompanyIntegrationsArgs = {
  condition: InputMaybe<IntegrationCondition>;
  filter: InputMaybe<IntegrationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationsOrderBy>>;
};


export type CompanyJurisdictionsArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyJurisdictionsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type CompanyLabelsByPrivilegedTaskCompanyIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type CompanyLabelsByTaskReportCompanyIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type CompanyMainUsersByFilterCompanyIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type CompanyMainUsersByPrivilegedTaskCompanyIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type CompanyMainUsersByPrivilegedTaskCompanyIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type CompanyMainUsersByTaskReportCompanyIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type CompanyMainUsersByTaskReportCompanyIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CompanyPrivilegedTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type CompanyProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type CompanyProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type CompanyProjectStagesByPrivilegedTaskCompanyIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CompanyProjectStagesByProjectCompanyIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CompanyProjectStagesByProjectReportCompanyIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CompanyProjectStagesByProjectReportCompanyIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CompanyProjectStagesByTaskReportCompanyIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type CompanyProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByProjectCompanyIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByProjectCompanyIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByProjectReportCompanyIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByProjectReportCompanyIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByReminderCompanyIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByReminderCompanyIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByTaskReportCompanyIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsByTaskReportCompanyIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type CompanyRemindersArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type CompanyRemindersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type CompanyRolesArgs = {
  condition: InputMaybe<RoleCondition>;
  filter: InputMaybe<RoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RolesOrderBy>>;
};


export type CompanySmsActivitiesArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type CompanySystemBudgetsArgs = {
  condition: InputMaybe<SystemBudgetCondition>;
  filter: InputMaybe<SystemBudgetFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemBudgetsOrderBy>>;
};


export type CompanySystemProfilesArgs = {
  condition: InputMaybe<SystemProfileCondition>;
  filter: InputMaybe<SystemProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfilesOrderBy>>;
};


export type CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type CompanyTaskReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type CompanyTeamsArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type CompanyTeamsByProjectReportCompanyIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type CompanyTeamsByProjectReportCompanyIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type CompanyTeamsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type CompanyUsersByDashboardCompanyIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectCompanyIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectCompanyIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectCompanyIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectCompanyIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByProjectReportCompanyIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByReminderCompanyIdAndAssigneeIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByReminderCompanyIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type CompanyUsersByTeamCompanyIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `customerPortalEnabled` field. */
  customerPortalEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `emailFooter` field. */
  emailFooter: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailHeader` field. */
  emailHeader: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `logoUrl` field. */
  logoUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `settings` field. */
  settings: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `stripeCustomerId` field. */
  stripeCustomerId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subdomainName` field. */
  subdomainName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userSlot` field. */
  userSlot: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `website` field. */
  website: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type CompanyContactsByProjectCompanyIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’ */
export type CompanyFilter = {
  /** Filter by the object’s `actions` relation. */
  actions: InputMaybe<CompanyToManyActionFilter>;
  /** Some related `actions` exist. */
  actionsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Filter by the object’s `analytics` relation. */
  analytics: InputMaybe<CompanyToManyAnalyticFilter>;
  /** Some related `analytics` exist. */
  analyticsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `calls` relation. */
  calls: InputMaybe<CompanyToManyCallFilter>;
  /** Some related `calls` exist. */
  callsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `catalogItems` relation. */
  catalogItems: InputMaybe<CompanyToManyCatalogItemFilter>;
  /** Some related `catalogItems` exist. */
  catalogItemsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyNotificationSettings` relation. */
  companyNotificationSettings: InputMaybe<CompanyToManyCompanyNotificationSettingFilter>;
  /** Some related `companyNotificationSettings` exist. */
  companyNotificationSettingsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyNotificationSettingsRoleDestinations` relation. */
  companyNotificationSettingsRoleDestinations: InputMaybe<CompanyToManyCompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `companyNotificationSettingsRoleDestinations` exist. */
  companyNotificationSettingsRoleDestinationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyNotificationSettingsSingleDestinations` relation. */
  companyNotificationSettingsSingleDestinations: InputMaybe<CompanyToManyCompanyNotificationSettingsSingleDestinationFilter>;
  /** Some related `companyNotificationSettingsSingleDestinations` exist. */
  companyNotificationSettingsSingleDestinationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyPhones` relation. */
  companyPhones: InputMaybe<CompanyToManyCompanyPhoneFilter>;
  /** Some related `companyPhones` exist. */
  companyPhonesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyUsers` relation. */
  companyUsers: InputMaybe<CompanyToManyCompanyUserFilter>;
  /** Some related `companyUsers` exist. */
  companyUsersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `customerPortalEnabled` field. */
  customerPortalEnabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `dashboards` relation. */
  dashboards: InputMaybe<CompanyToManyDashboardFilter>;
  /** Some related `dashboards` exist. */
  dashboardsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailAccounts` relation. */
  emailAccounts: InputMaybe<CompanyToManyEmailAccountFilter>;
  /** Some related `emailAccounts` exist. */
  emailAccountsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailFooter` field. */
  emailFooter: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailHeader` field. */
  emailHeader: InputMaybe<StringFilter>;
  /** Filter by the object’s `filterFolders` relation. */
  filterFolders: InputMaybe<CompanyToManyFilterFolderFilter>;
  /** Some related `filterFolders` exist. */
  filterFoldersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filters` relation. */
  filters: InputMaybe<CompanyToManyFilterFilter>;
  /** Some related `filters` exist. */
  filtersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `geolocationUserSettings` relation. */
  geolocationUserSettings: InputMaybe<CompanyToManyGeolocationUserSettingFilter>;
  /** Some related `geolocationUserSettings` exist. */
  geolocationUserSettingsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `integrations` relation. */
  integrations: InputMaybe<CompanyToManyIntegrationFilter>;
  /** Some related `integrations` exist. */
  integrationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `jurisdictions` relation. */
  jurisdictions: InputMaybe<CompanyToManyJurisdictionFilter>;
  /** Some related `jurisdictions` exist. */
  jurisdictionsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `logoUrl` field. */
  logoUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `privilegedTasks` relation. */
  privilegedTasks: InputMaybe<CompanyToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasks` exist. */
  privilegedTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReports` relation. */
  projectReports: InputMaybe<CompanyToManyProjectReportFilter>;
  /** Some related `projectReports` exist. */
  projectReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projects` relation. */
  projects: InputMaybe<CompanyToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `reminders` relation. */
  reminders: InputMaybe<CompanyToManyReminderFilter>;
  /** Some related `reminders` exist. */
  remindersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `roles` relation. */
  roles: InputMaybe<CompanyToManyRoleFilter>;
  /** Some related `roles` exist. */
  rolesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `settings` field. */
  settings: InputMaybe<JsonFilter>;
  /** Filter by the object’s `smsActivities` relation. */
  smsActivities: InputMaybe<CompanyToManySmsActivityFilter>;
  /** Some related `smsActivities` exist. */
  smsActivitiesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stripeCustomerId` field. */
  stripeCustomerId: InputMaybe<StringFilter>;
  /** Filter by the object’s `subdomainName` field. */
  subdomainName: InputMaybe<StringFilter>;
  /** Filter by the object’s `systemBudgets` relation. */
  systemBudgets: InputMaybe<CompanyToManySystemBudgetFilter>;
  /** Some related `systemBudgets` exist. */
  systemBudgetsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemProfiles` relation. */
  systemProfiles: InputMaybe<CompanyToManySystemProfileFilter>;
  /** Some related `systemProfiles` exist. */
  systemProfilesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskReports` relation. */
  taskReports: InputMaybe<CompanyToManyTaskReportFilter>;
  /** Some related `taskReports` exist. */
  taskReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teams` relation. */
  teams: InputMaybe<CompanyToManyTeamFilter>;
  /** Some related `teams` exist. */
  teamsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `timezone` field. */
  timezone: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userSlot` field. */
  userSlot: InputMaybe<IntFilter>;
  /** Filter by the object’s `website` field. */
  website: InputMaybe<StringFilter>;
};

/** A connection to a list of `FilterFolder` values, with data from `Dashboard`. */
export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterFolderAggregates>;
  /** A list of edges which contains the `FilterFolder`, info from the `Dashboard`, and the cursor to aid in pagination. */
  edges: Array<CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterFolderAggregates>>;
  /** A list of `FilterFolder` objects. */
  nodes: Array<Maybe<FilterFolder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FilterFolder` values, with data from `Dashboard`. */
export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterFolderGroupBy>;
  having: InputMaybe<FilterFolderHavingInput>;
};

/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboards: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsConnection: DashboardsConnection;
  /** The `FilterFolder` at the end of the edge. */
  node: Maybe<FilterFolder>;
};


/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyEdgeDashboardsArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type CompanyFilterFoldersByDashboardCompanyIdAndFilterFolderIdManyToManyEdgeDashboardsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A connection to a list of `FilterFolder` values, with data from `Filter`. */
export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterFolderAggregates>;
  /** A list of edges which contains the `FilterFolder`, info from the `Filter`, and the cursor to aid in pagination. */
  edges: Array<CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterFolderAggregates>>;
  /** A list of `FilterFolder` objects. */
  nodes: Array<Maybe<FilterFolder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FilterFolder` values, with data from `Filter`. */
export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterFolderGroupBy>;
  having: InputMaybe<FilterFolderHavingInput>;
};

/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Filter`. */
  filters: Array<Filter>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersConnection: FiltersConnection;
  /** The `FilterFolder` at the end of the edge. */
  node: Maybe<FilterFolder>;
};


/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyEdgeFiltersArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type CompanyFilterFoldersByFilterCompanyIdAndFilterFolderIdManyToManyEdgeFiltersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type CompanyJurisdictionsByProjectCompanyIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type CompanyJurisdictionsByProjectReportCompanyIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type CompanyNotificationSetting = Node & {
  /** Reads a single `Company` that is related to this `CompanyNotificationSetting`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId: Array<CompanyNotificationSettingsRoleDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnection: CompanyNotificationSettingsRoleDestinationsConnection;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId: Array<CompanyNotificationSettingsSingleDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnection: CompanyNotificationSettingsSingleDestinationsConnection;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId: Array<CompanyNotificationSettingsTeamDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnection: CompanyNotificationSettingsTeamDestinationsConnection;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  emailEnabled: Maybe<Scalars['Boolean']['output']>;
  emailNotificationSubject: Maybe<Scalars['String']['output']>;
  emailNotificationText: Maybe<Scalars['String']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  entityType: EntityType;
  eventType: EventType;
  inboxEnabled: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  offset1Email: Maybe<Scalars['Boolean']['output']>;
  offset1Sms: Maybe<Scalars['Boolean']['output']>;
  offset1Time: Maybe<Scalars['Int']['output']>;
  offset1Unit: Maybe<NotificationOffsetUnit>;
  offset2Email: Maybe<Scalars['Boolean']['output']>;
  offset2Sms: Maybe<Scalars['Boolean']['output']>;
  offset2Time: Maybe<Scalars['Int']['output']>;
  offset2Unit: Maybe<NotificationOffsetUnit>;
  pushEnabled: Maybe<Scalars['Boolean']['output']>;
  smsEnabled: Maybe<Scalars['Boolean']['output']>;
  smsNotificationText: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdArgs = {
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdArgs = {
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdArgs = {
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingCompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CompanyNotificationSettingArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CompanyNotificationSettingAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CompanyNotificationSettingDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CompanyNotificationSettingMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CompanyNotificationSettingMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CompanyNotificationSettingStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CompanyNotificationSettingStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CompanyNotificationSettingSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CompanyNotificationSettingVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CompanyNotificationSettingVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `CompanyNotificationSetting` object types. */
export type CompanyNotificationSettingAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyNotificationSetting` objects. */
  arrayAgg: InputMaybe<CompanyNotificationSettingArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyNotificationSetting` objects. */
  average: InputMaybe<CompanyNotificationSettingAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyNotificationSetting` objects. */
  distinctCount: InputMaybe<CompanyNotificationSettingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyNotificationSetting` object to be included within the aggregate. */
  filter: InputMaybe<CompanyNotificationSettingFilter>;
  /** Maximum aggregate over matching `CompanyNotificationSetting` objects. */
  max: InputMaybe<CompanyNotificationSettingMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyNotificationSetting` objects. */
  min: InputMaybe<CompanyNotificationSettingMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyNotificationSetting` objects. */
  stddevPopulation: InputMaybe<CompanyNotificationSettingStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyNotificationSetting` objects. */
  stddevSample: InputMaybe<CompanyNotificationSettingStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyNotificationSetting` objects. */
  sum: InputMaybe<CompanyNotificationSettingSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyNotificationSetting` objects. */
  variancePopulation: InputMaybe<CompanyNotificationSettingVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyNotificationSetting` objects. */
  varianceSample: InputMaybe<CompanyNotificationSettingVarianceSampleAggregateFilter>;
};

export type CompanyNotificationSettingArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  emailEnabled: InputMaybe<BooleanListFilter>;
  emailNotificationSubject: InputMaybe<StringListFilter>;
  emailNotificationText: InputMaybe<StringListFilter>;
  enabled: InputMaybe<BooleanListFilter>;
  inboxEnabled: InputMaybe<BooleanListFilter>;
  offset1Email: InputMaybe<BooleanListFilter>;
  offset1Sms: InputMaybe<BooleanListFilter>;
  offset1Time: InputMaybe<IntListFilter>;
  offset2Email: InputMaybe<BooleanListFilter>;
  offset2Sms: InputMaybe<BooleanListFilter>;
  offset2Time: InputMaybe<IntListFilter>;
  pushEnabled: InputMaybe<BooleanListFilter>;
  smsEnabled: InputMaybe<BooleanListFilter>;
  smsNotificationText: InputMaybe<StringListFilter>;
};

export type CompanyNotificationSettingArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of emailEnabled across the matching connection */
  emailEnabled: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of emailNotificationSubject across the matching connection */
  emailNotificationSubject: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of emailNotificationText across the matching connection */
  emailNotificationText: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of enabled across the matching connection */
  enabled: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of inboxEnabled across the matching connection */
  inboxEnabled: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of offset1Email across the matching connection */
  offset1Email: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of offset1Sms across the matching connection */
  offset1Sms: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of offset1Time across the matching connection */
  offset1Time: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of offset2Email across the matching connection */
  offset2Email: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of offset2Sms across the matching connection */
  offset2Sms: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of offset2Time across the matching connection */
  offset2Time: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of pushEnabled across the matching connection */
  pushEnabled: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of smsEnabled across the matching connection */
  smsEnabled: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of smsNotificationText across the matching connection */
  smsNotificationText: Array<Maybe<Scalars['String']['output']>>;
};


export type CompanyNotificationSettingArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesEmailEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesEmailNotificationSubjectArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesEmailNotificationTextArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesInboxEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset1EmailArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset1SmsArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset2EmailArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset2SmsArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesPushEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesSmsEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingArrayAggAggregatesSmsNotificationTextArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  offset1Time: InputMaybe<BigFloatFilter>;
  offset2Time: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingAverageAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingAverageAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

/**
 * A condition to be used against `CompanyNotificationSetting` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyNotificationSettingCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailEnabled` field. */
  emailEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `emailNotificationSubject` field. */
  emailNotificationSubject: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailNotificationText` field. */
  emailNotificationText: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityType>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<EventType>;
  /** Checks for equality with the object’s `inboxEnabled` field. */
  inboxEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `offset1Email` field. */
  offset1Email: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `offset1Sms` field. */
  offset1Sms: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `offset1Time` field. */
  offset1Time: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `offset1Unit` field. */
  offset1Unit: InputMaybe<NotificationOffsetUnit>;
  /** Checks for equality with the object’s `offset2Email` field. */
  offset2Email: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `offset2Sms` field. */
  offset2Sms: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `offset2Time` field. */
  offset2Time: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `offset2Unit` field. */
  offset2Unit: InputMaybe<NotificationOffsetUnit>;
  /** Checks for equality with the object’s `pushEnabled` field. */
  pushEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `smsEnabled` field. */
  smsEnabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `smsNotificationText` field. */
  smsNotificationText: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CompanyNotificationSettingDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  emailEnabled: InputMaybe<BigIntFilter>;
  emailNotificationSubject: InputMaybe<BigIntFilter>;
  emailNotificationText: InputMaybe<BigIntFilter>;
  enabled: InputMaybe<BigIntFilter>;
  entityType: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  inboxEnabled: InputMaybe<BigIntFilter>;
  offset1Email: InputMaybe<BigIntFilter>;
  offset1Sms: InputMaybe<BigIntFilter>;
  offset1Time: InputMaybe<BigIntFilter>;
  offset1Unit: InputMaybe<BigIntFilter>;
  offset2Email: InputMaybe<BigIntFilter>;
  offset2Sms: InputMaybe<BigIntFilter>;
  offset2Time: InputMaybe<BigIntFilter>;
  offset2Unit: InputMaybe<BigIntFilter>;
  pushEnabled: InputMaybe<BigIntFilter>;
  smsEnabled: InputMaybe<BigIntFilter>;
  smsNotificationText: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailEnabled across the matching connection */
  emailEnabled: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailNotificationSubject across the matching connection */
  emailNotificationSubject: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailNotificationText across the matching connection */
  emailNotificationText: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of enabled across the matching connection */
  enabled: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of entityType across the matching connection */
  entityType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of inboxEnabled across the matching connection */
  inboxEnabled: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset1Email across the matching connection */
  offset1Email: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset1Sms across the matching connection */
  offset1Sms: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset1Unit across the matching connection */
  offset1Unit: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset2Email across the matching connection */
  offset2Email: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset2Sms across the matching connection */
  offset2Sms: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of offset2Unit across the matching connection */
  offset2Unit: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of pushEnabled across the matching connection */
  pushEnabled: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of smsEnabled across the matching connection */
  smsEnabled: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of smsNotificationText across the matching connection */
  smsNotificationText: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CompanyNotificationSettingDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEmailEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEmailNotificationSubjectArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEmailNotificationTextArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEntityTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesInboxEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset1EmailArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset1SmsArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset1UnitArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset2EmailArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset2SmsArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesOffset2UnitArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesPushEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesSmsEnabledArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesSmsNotificationTextArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

/** A filter to be used against `CompanyNotificationSetting` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyNotificationSettingFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingToManyCompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId` exist. */
  companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingToManyCompanyNotificationSettingsSingleDestinationFilter>;
  /** Some related `companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId` exist. */
  companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingToManyCompanyNotificationSettingsTeamDestinationFilter>;
  /** Some related `companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId` exist. */
  companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailEnabled` field. */
  emailEnabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `emailNotificationSubject` field. */
  emailNotificationSubject: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailNotificationText` field. */
  emailNotificationText: InputMaybe<StringFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypeFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<EventTypeFilter>;
  /** Filter by the object’s `inboxEnabled` field. */
  inboxEnabled: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyNotificationSettingFilter>;
  /** Filter by the object’s `offset1Email` field. */
  offset1Email: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `offset1Sms` field. */
  offset1Sms: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `offset1Time` field. */
  offset1Time: InputMaybe<IntFilter>;
  /** Filter by the object’s `offset1Unit` field. */
  offset1Unit: InputMaybe<NotificationOffsetUnitFilter>;
  /** Filter by the object’s `offset2Email` field. */
  offset2Email: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `offset2Sms` field. */
  offset2Sms: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `offset2Time` field. */
  offset2Time: InputMaybe<IntFilter>;
  /** Filter by the object’s `offset2Unit` field. */
  offset2Unit: InputMaybe<NotificationOffsetUnitFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyNotificationSettingFilter>>;
  /** Filter by the object’s `pushEnabled` field. */
  pushEnabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `smsEnabled` field. */
  smsEnabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `smsNotificationText` field. */
  smsNotificationText: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  offset1Time: InputMaybe<IntFilter>;
  offset2Time: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['Int']['output']>;
  /** Maximum of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMaxAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMaxAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  offset1Time: InputMaybe<IntFilter>;
  offset2Time: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['Int']['output']>;
  /** Minimum of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMinAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMinAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  offset1Time: InputMaybe<BigFloatFilter>;
  offset2Time: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingStddevPopulationAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingStddevPopulationAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  offset1Time: InputMaybe<BigFloatFilter>;
  offset2Time: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingStddevSampleAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingStddevSampleAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  offset1Time: InputMaybe<BigIntFilter>;
  offset2Time: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of offset1Time across the matching connection */
  offset1Time: Scalars['BigInt']['output'];
  /** Sum of offset2Time across the matching connection */
  offset2Time: Scalars['BigInt']['output'];
};


export type CompanyNotificationSettingSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingSumAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingSumAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

/** A filter to be used against many `CompanyNotificationSettingsRoleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingToManyCompanyNotificationSettingsRoleDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsRoleDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsRoleDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** No related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
};

/** A filter to be used against many `CompanyNotificationSettingsSingleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingToManyCompanyNotificationSettingsSingleDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsSingleDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsSingleDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** No related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** Some related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
};

/** A filter to be used against many `CompanyNotificationSettingsTeamDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingToManyCompanyNotificationSettingsTeamDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsTeamDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsTeamDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** No related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** Some related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
};

export type CompanyNotificationSettingVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  offset1Time: InputMaybe<BigFloatFilter>;
  offset2Time: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingVariancePopulationAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingVariancePopulationAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

export type CompanyNotificationSettingVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  offset1Time: InputMaybe<BigFloatFilter>;
  offset2Time: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of offset1Time across the matching connection */
  offset1Time: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of offset2Time across the matching connection */
  offset2Time: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingVarianceSampleAggregatesOffset1TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


export type CompanyNotificationSettingVarianceSampleAggregatesOffset2TimeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};

/** A connection to a list of `CompanyNotificationSetting` values. */
export type CompanyNotificationSettingsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CompanyNotificationSettingAggregates>;
  /** A list of edges which contains the `CompanyNotificationSetting` and cursor to aid in pagination. */
  edges: Array<CompanyNotificationSettingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CompanyNotificationSettingAggregates>>;
  /** A list of `CompanyNotificationSetting` objects. */
  nodes: Array<Maybe<CompanyNotificationSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyNotificationSetting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CompanyNotificationSetting` values. */
export type CompanyNotificationSettingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyNotificationSettingsGroupBy>;
  having: InputMaybe<CompanyNotificationSettingsHavingInput>;
};

/** A `CompanyNotificationSetting` edge in the connection. */
export type CompanyNotificationSettingsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `CompanyNotificationSetting` at the end of the edge. */
  node: Maybe<CompanyNotificationSetting>;
};

/** Grouping methods for `CompanyNotificationSetting` for usage during aggregation. */
export enum CompanyNotificationSettingsGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EmailEnabled = 'EMAIL_ENABLED',
  EmailNotificationSubject = 'EMAIL_NOTIFICATION_SUBJECT',
  EmailNotificationText = 'EMAIL_NOTIFICATION_TEXT',
  Enabled = 'ENABLED',
  EntityType = 'ENTITY_TYPE',
  EventType = 'EVENT_TYPE',
  InboxEnabled = 'INBOX_ENABLED',
  Offset_1Email = 'OFFSET_1_EMAIL',
  Offset_1Sms = 'OFFSET_1_SMS',
  Offset_1Time = 'OFFSET_1_TIME',
  Offset_1Unit = 'OFFSET_1_UNIT',
  Offset_2Email = 'OFFSET_2_EMAIL',
  Offset_2Sms = 'OFFSET_2_SMS',
  Offset_2Time = 'OFFSET_2_TIME',
  Offset_2Unit = 'OFFSET_2_UNIT',
  PushEnabled = 'PUSH_ENABLED',
  SmsEnabled = 'SMS_ENABLED',
  SmsNotificationText = 'SMS_NOTIFICATION_TEXT',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CompanyNotificationSettingsHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyNotificationSetting` aggregates. */
export type CompanyNotificationSettingsHavingInput = {
  AND: InputMaybe<Array<CompanyNotificationSettingsHavingInput>>;
  OR: InputMaybe<Array<CompanyNotificationSettingsHavingInput>>;
  arrayAgg: InputMaybe<CompanyNotificationSettingsHavingArrayAggInput>;
  average: InputMaybe<CompanyNotificationSettingsHavingAverageInput>;
  distinctCount: InputMaybe<CompanyNotificationSettingsHavingDistinctCountInput>;
  max: InputMaybe<CompanyNotificationSettingsHavingMaxInput>;
  min: InputMaybe<CompanyNotificationSettingsHavingMinInput>;
  stddevPopulation: InputMaybe<CompanyNotificationSettingsHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CompanyNotificationSettingsHavingStddevSampleInput>;
  sum: InputMaybe<CompanyNotificationSettingsHavingSumInput>;
  variancePopulation: InputMaybe<CompanyNotificationSettingsHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CompanyNotificationSettingsHavingVarianceSampleInput>;
};

export type CompanyNotificationSettingsHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  offset1Time: InputMaybe<HavingIntFilter>;
  offset2Time: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `CompanyNotificationSetting`. */
export enum CompanyNotificationSettingsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleTargetAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_TARGET_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleTargetDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_TARGET_DESC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_SINGLE_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_ENTITY_TYPE_AND_EVENT_TYPE_AND_COMPANY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailEnabledAsc = 'EMAIL_ENABLED_ASC',
  EmailEnabledDesc = 'EMAIL_ENABLED_DESC',
  EmailNotificationSubjectAsc = 'EMAIL_NOTIFICATION_SUBJECT_ASC',
  EmailNotificationSubjectDesc = 'EMAIL_NOTIFICATION_SUBJECT_DESC',
  EmailNotificationTextAsc = 'EMAIL_NOTIFICATION_TEXT_ASC',
  EmailNotificationTextDesc = 'EMAIL_NOTIFICATION_TEXT_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  InboxEnabledAsc = 'INBOX_ENABLED_ASC',
  InboxEnabledDesc = 'INBOX_ENABLED_DESC',
  Natural = 'NATURAL',
  Offset_1EmailAsc = 'OFFSET_1_EMAIL_ASC',
  Offset_1EmailDesc = 'OFFSET_1_EMAIL_DESC',
  Offset_1SmsAsc = 'OFFSET_1_SMS_ASC',
  Offset_1SmsDesc = 'OFFSET_1_SMS_DESC',
  Offset_1TimeAsc = 'OFFSET_1_TIME_ASC',
  Offset_1TimeDesc = 'OFFSET_1_TIME_DESC',
  Offset_1UnitAsc = 'OFFSET_1_UNIT_ASC',
  Offset_1UnitDesc = 'OFFSET_1_UNIT_DESC',
  Offset_2EmailAsc = 'OFFSET_2_EMAIL_ASC',
  Offset_2EmailDesc = 'OFFSET_2_EMAIL_DESC',
  Offset_2SmsAsc = 'OFFSET_2_SMS_ASC',
  Offset_2SmsDesc = 'OFFSET_2_SMS_DESC',
  Offset_2TimeAsc = 'OFFSET_2_TIME_ASC',
  Offset_2TimeDesc = 'OFFSET_2_TIME_DESC',
  Offset_2UnitAsc = 'OFFSET_2_UNIT_ASC',
  Offset_2UnitDesc = 'OFFSET_2_UNIT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PushEnabledAsc = 'PUSH_ENABLED_ASC',
  PushEnabledDesc = 'PUSH_ENABLED_DESC',
  SmsEnabledAsc = 'SMS_ENABLED_ASC',
  SmsEnabledDesc = 'SMS_ENABLED_DESC',
  SmsNotificationTextAsc = 'SMS_NOTIFICATION_TEXT_ASC',
  SmsNotificationTextDesc = 'SMS_NOTIFICATION_TEXT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CompanyNotificationSettingsRoleDestination = Node & {
  /** Reads a single `Company` that is related to this `CompanyNotificationSettingsRoleDestination`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads a single `CompanyNotificationSetting` that is related to this `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: Maybe<CompanyNotificationSetting>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  entityType: EntityType;
  eventType: EventType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `CompanyNotificationSettingsRoleDestination`. */
  role: Maybe<Role>;
  roleId: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};

export type CompanyNotificationSettingsRoleDestinationAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CompanyNotificationSettingsRoleDestinationArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CompanyNotificationSettingsRoleDestinationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CompanyNotificationSettingsRoleDestinationDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CompanyNotificationSettingsRoleDestinationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CompanyNotificationSettingsRoleDestinationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CompanyNotificationSettingsRoleDestinationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CompanyNotificationSettingsRoleDestinationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CompanyNotificationSettingsRoleDestinationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CompanyNotificationSettingsRoleDestinationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CompanyNotificationSettingsRoleDestinationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `CompanyNotificationSettingsRoleDestination` object types. */
export type CompanyNotificationSettingsRoleDestinationAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  arrayAgg: InputMaybe<CompanyNotificationSettingsRoleDestinationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  average: InputMaybe<CompanyNotificationSettingsRoleDestinationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  distinctCount: InputMaybe<CompanyNotificationSettingsRoleDestinationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyNotificationSettingsRoleDestination` object to be included within the aggregate. */
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** Maximum aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  max: InputMaybe<CompanyNotificationSettingsRoleDestinationMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  min: InputMaybe<CompanyNotificationSettingsRoleDestinationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  stddevPopulation: InputMaybe<CompanyNotificationSettingsRoleDestinationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  stddevSample: InputMaybe<CompanyNotificationSettingsRoleDestinationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  sum: InputMaybe<CompanyNotificationSettingsRoleDestinationSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  variancePopulation: InputMaybe<CompanyNotificationSettingsRoleDestinationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyNotificationSettingsRoleDestination` objects. */
  varianceSample: InputMaybe<CompanyNotificationSettingsRoleDestinationVarianceSampleAggregateFilter>;
};

export type CompanyNotificationSettingsRoleDestinationArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
};

export type CompanyNotificationSettingsRoleDestinationArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of roleId across the matching connection */
  roleId: Array<Maybe<Scalars['Int']['output']>>;
};


export type CompanyNotificationSettingsRoleDestinationArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationArrayAggAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsRoleDestinationAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of roleId across the matching connection */
  roleId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationAverageAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

/**
 * A condition to be used against `CompanyNotificationSettingsRoleDestination`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyNotificationSettingsRoleDestinationCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityType>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<EventType>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  entityType: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of entityType across the matching connection */
  entityType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of roleId across the matching connection */
  roleId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesEntityTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

/** A filter to be used against `CompanyNotificationSettingsRoleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingsRoleDestinationFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypeFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<EventTypeFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationFilter>>;
  /** Filter by the object’s `role` relation. */
  role: InputMaybe<RoleFilter>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CompanyNotificationSettingsRoleDestination` for usage during aggregation. */
export enum CompanyNotificationSettingsRoleDestinationGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EntityType = 'ENTITY_TYPE',
  EventType = 'EVENT_TYPE',
  RoleId = 'ROLE_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CompanyNotificationSettingsRoleDestinationHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyNotificationSettingsRoleDestination` aggregates. */
export type CompanyNotificationSettingsRoleDestinationHavingInput = {
  AND: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationHavingInput>>;
  OR: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationHavingInput>>;
  arrayAgg: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingArrayAggInput>;
  average: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingAverageInput>;
  distinctCount: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingDistinctCountInput>;
  max: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingMaxInput>;
  min: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingMinInput>;
  stddevPopulation: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingStddevSampleInput>;
  sum: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingSumInput>;
  variancePopulation: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingVarianceSampleInput>;
};

export type CompanyNotificationSettingsRoleDestinationHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  roleId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  roleId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of roleId across the matching connection */
  roleId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMaxAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  roleId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsRoleDestinationMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of roleId across the matching connection */
  roleId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMinAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsRoleDestinationStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of roleId across the matching connection */
  roleId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationStddevPopulationAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsRoleDestinationStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of roleId across the matching connection */
  roleId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationStddevSampleAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsRoleDestinationSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of roleId across the matching connection */
  roleId: Scalars['BigInt']['output'];
};


export type CompanyNotificationSettingsRoleDestinationSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationSumAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsRoleDestinationVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of roleId across the matching connection */
  roleId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationVariancePopulationAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsRoleDestinationVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsRoleDestinationVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of roleId across the matching connection */
  roleId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsRoleDestinationVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsRoleDestinationVarianceSampleAggregatesRoleIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};

/** A connection to a list of `CompanyNotificationSettingsRoleDestination` values. */
export type CompanyNotificationSettingsRoleDestinationsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CompanyNotificationSettingsRoleDestinationAggregates>;
  /** A list of edges which contains the `CompanyNotificationSettingsRoleDestination` and cursor to aid in pagination. */
  edges: Array<CompanyNotificationSettingsRoleDestinationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CompanyNotificationSettingsRoleDestinationAggregates>>;
  /** A list of `CompanyNotificationSettingsRoleDestination` objects. */
  nodes: Array<Maybe<CompanyNotificationSettingsRoleDestination>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyNotificationSettingsRoleDestination` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CompanyNotificationSettingsRoleDestination` values. */
export type CompanyNotificationSettingsRoleDestinationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyNotificationSettingsRoleDestinationGroupBy>;
  having: InputMaybe<CompanyNotificationSettingsRoleDestinationHavingInput>;
};

/** A `CompanyNotificationSettingsRoleDestination` edge in the connection. */
export type CompanyNotificationSettingsRoleDestinationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `CompanyNotificationSettingsRoleDestination` at the end of the edge. */
  node: Maybe<CompanyNotificationSettingsRoleDestination>;
};

/** Methods to use when ordering `CompanyNotificationSettingsRoleDestination`. */
export enum CompanyNotificationSettingsRoleDestinationsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CompanyNotificationSettingsSingleDestination = Node & {
  /** Reads a single `Company` that is related to this `CompanyNotificationSettingsSingleDestination`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads a single `CompanyNotificationSetting` that is related to this `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: Maybe<CompanyNotificationSetting>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  entityType: EntityType;
  eventType: EventType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  target: SingleDestination;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};

export type CompanyNotificationSettingsSingleDestinationAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CompanyNotificationSettingsSingleDestinationArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CompanyNotificationSettingsSingleDestinationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CompanyNotificationSettingsSingleDestinationDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CompanyNotificationSettingsSingleDestinationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CompanyNotificationSettingsSingleDestinationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CompanyNotificationSettingsSingleDestinationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CompanyNotificationSettingsSingleDestinationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CompanyNotificationSettingsSingleDestinationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CompanyNotificationSettingsSingleDestinationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CompanyNotificationSettingsSingleDestinationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `CompanyNotificationSettingsSingleDestination` object types. */
export type CompanyNotificationSettingsSingleDestinationAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  arrayAgg: InputMaybe<CompanyNotificationSettingsSingleDestinationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  average: InputMaybe<CompanyNotificationSettingsSingleDestinationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  distinctCount: InputMaybe<CompanyNotificationSettingsSingleDestinationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyNotificationSettingsSingleDestination` object to be included within the aggregate. */
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** Maximum aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  max: InputMaybe<CompanyNotificationSettingsSingleDestinationMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  min: InputMaybe<CompanyNotificationSettingsSingleDestinationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  stddevPopulation: InputMaybe<CompanyNotificationSettingsSingleDestinationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  stddevSample: InputMaybe<CompanyNotificationSettingsSingleDestinationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  sum: InputMaybe<CompanyNotificationSettingsSingleDestinationSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  variancePopulation: InputMaybe<CompanyNotificationSettingsSingleDestinationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyNotificationSettingsSingleDestination` objects. */
  varianceSample: InputMaybe<CompanyNotificationSettingsSingleDestinationVarianceSampleAggregateFilter>;
};

export type CompanyNotificationSettingsSingleDestinationArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
};

export type CompanyNotificationSettingsSingleDestinationArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
};


export type CompanyNotificationSettingsSingleDestinationArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsSingleDestinationAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

/**
 * A condition to be used against `CompanyNotificationSettingsSingleDestination`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyNotificationSettingsSingleDestinationCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityType>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<EventType>;
  /** Checks for equality with the object’s `target` field. */
  target: InputMaybe<SingleDestination>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  entityType: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  target: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of entityType across the matching connection */
  entityType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of target across the matching connection */
  target: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesEntityTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesTargetArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

/** A filter to be used against `CompanyNotificationSettingsSingleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingsSingleDestinationFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypeFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<EventTypeFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationFilter>>;
  /** Filter by the object’s `target` field. */
  target: InputMaybe<SingleDestinationFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CompanyNotificationSettingsSingleDestination` for usage during aggregation. */
export enum CompanyNotificationSettingsSingleDestinationGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EntityType = 'ENTITY_TYPE',
  EventType = 'EVENT_TYPE',
  Target = 'TARGET',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CompanyNotificationSettingsSingleDestinationHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyNotificationSettingsSingleDestination` aggregates. */
export type CompanyNotificationSettingsSingleDestinationHavingInput = {
  AND: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationHavingInput>>;
  OR: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationHavingInput>>;
  arrayAgg: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingArrayAggInput>;
  average: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingAverageInput>;
  distinctCount: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingDistinctCountInput>;
  max: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingMaxInput>;
  min: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingMinInput>;
  stddevPopulation: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingStddevSampleInput>;
  sum: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingSumInput>;
  variancePopulation: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingVarianceSampleInput>;
};

export type CompanyNotificationSettingsSingleDestinationHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsSingleDestinationMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsSingleDestinationMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsSingleDestinationStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsSingleDestinationStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsSingleDestinationSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
};


export type CompanyNotificationSettingsSingleDestinationSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsSingleDestinationVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsSingleDestinationVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsSingleDestinationVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsSingleDestinationVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};

/** A connection to a list of `CompanyNotificationSettingsSingleDestination` values. */
export type CompanyNotificationSettingsSingleDestinationsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CompanyNotificationSettingsSingleDestinationAggregates>;
  /** A list of edges which contains the `CompanyNotificationSettingsSingleDestination` and cursor to aid in pagination. */
  edges: Array<CompanyNotificationSettingsSingleDestinationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CompanyNotificationSettingsSingleDestinationAggregates>>;
  /** A list of `CompanyNotificationSettingsSingleDestination` objects. */
  nodes: Array<Maybe<CompanyNotificationSettingsSingleDestination>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyNotificationSettingsSingleDestination` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CompanyNotificationSettingsSingleDestination` values. */
export type CompanyNotificationSettingsSingleDestinationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyNotificationSettingsSingleDestinationGroupBy>;
  having: InputMaybe<CompanyNotificationSettingsSingleDestinationHavingInput>;
};

/** A `CompanyNotificationSettingsSingleDestination` edge in the connection. */
export type CompanyNotificationSettingsSingleDestinationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `CompanyNotificationSettingsSingleDestination` at the end of the edge. */
  node: Maybe<CompanyNotificationSettingsSingleDestination>;
};

/** Methods to use when ordering `CompanyNotificationSettingsSingleDestination`. */
export enum CompanyNotificationSettingsSingleDestinationsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TargetAsc = 'TARGET_ASC',
  TargetDesc = 'TARGET_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CompanyNotificationSettingsTeamDestination = Node & {
  companyId: Scalars['Int']['output'];
  /** Reads a single `CompanyNotificationSetting` that is related to this `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: Maybe<CompanyNotificationSetting>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  entityType: EntityType;
  eventType: EventType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Team` that is related to this `CompanyNotificationSettingsTeamDestination`. */
  team: Maybe<Team>;
  teamId: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};

export type CompanyNotificationSettingsTeamDestinationAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<CompanyNotificationSettingsTeamDestinationArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<CompanyNotificationSettingsTeamDestinationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<CompanyNotificationSettingsTeamDestinationDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<CompanyNotificationSettingsTeamDestinationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<CompanyNotificationSettingsTeamDestinationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<CompanyNotificationSettingsTeamDestinationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<CompanyNotificationSettingsTeamDestinationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<CompanyNotificationSettingsTeamDestinationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<CompanyNotificationSettingsTeamDestinationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<CompanyNotificationSettingsTeamDestinationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `CompanyNotificationSettingsTeamDestination` object types. */
export type CompanyNotificationSettingsTeamDestinationAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  arrayAgg: InputMaybe<CompanyNotificationSettingsTeamDestinationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  average: InputMaybe<CompanyNotificationSettingsTeamDestinationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  distinctCount: InputMaybe<CompanyNotificationSettingsTeamDestinationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyNotificationSettingsTeamDestination` object to be included within the aggregate. */
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** Maximum aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  max: InputMaybe<CompanyNotificationSettingsTeamDestinationMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  min: InputMaybe<CompanyNotificationSettingsTeamDestinationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  stddevPopulation: InputMaybe<CompanyNotificationSettingsTeamDestinationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  stddevSample: InputMaybe<CompanyNotificationSettingsTeamDestinationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  sum: InputMaybe<CompanyNotificationSettingsTeamDestinationSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  variancePopulation: InputMaybe<CompanyNotificationSettingsTeamDestinationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyNotificationSettingsTeamDestination` objects. */
  varianceSample: InputMaybe<CompanyNotificationSettingsTeamDestinationVarianceSampleAggregateFilter>;
};

export type CompanyNotificationSettingsTeamDestinationArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
};

export type CompanyNotificationSettingsTeamDestinationArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of teamId across the matching connection */
  teamId: Array<Maybe<Scalars['Int']['output']>>;
};


export type CompanyNotificationSettingsTeamDestinationArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationArrayAggAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsTeamDestinationAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationAverageAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

/**
 * A condition to be used against `CompanyNotificationSettingsTeamDestination`
 * object types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type CompanyNotificationSettingsTeamDestinationCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityType>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<EventType>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  entityType: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of entityType across the matching connection */
  entityType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of teamId across the matching connection */
  teamId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesEntityTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

/** A filter to be used against `CompanyNotificationSettingsTeamDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyNotificationSettingsTeamDestinationFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId` relation. */
  companyNotificationSettingByEntityTypeAndEventTypeAndCompanyId: InputMaybe<CompanyNotificationSettingFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypeFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<EventTypeFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationFilter>>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CompanyNotificationSettingsTeamDestination` for usage during aggregation. */
export enum CompanyNotificationSettingsTeamDestinationGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EntityType = 'ENTITY_TYPE',
  EventType = 'EVENT_TYPE',
  TeamId = 'TEAM_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type CompanyNotificationSettingsTeamDestinationHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CompanyNotificationSettingsTeamDestination` aggregates. */
export type CompanyNotificationSettingsTeamDestinationHavingInput = {
  AND: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationHavingInput>>;
  OR: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationHavingInput>>;
  arrayAgg: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingArrayAggInput>;
  average: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingAverageInput>;
  distinctCount: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingDistinctCountInput>;
  max: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingMaxInput>;
  min: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingMinInput>;
  stddevPopulation: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingStddevPopulationInput>;
  stddevSample: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingStddevSampleInput>;
  sum: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingSumInput>;
  variancePopulation: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingVariancePopulationInput>;
  varianceSample: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingVarianceSampleInput>;
};

export type CompanyNotificationSettingsTeamDestinationHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  teamId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMaxAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  teamId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyNotificationSettingsTeamDestinationMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMinAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsTeamDestinationStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationStddevPopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsTeamDestinationStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationStddevSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

export type CompanyNotificationSettingsTeamDestinationSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of teamId across the matching connection */
  teamId: Scalars['BigInt']['output'];
};


export type CompanyNotificationSettingsTeamDestinationSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationSumAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsTeamDestinationVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationVariancePopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

export type CompanyNotificationSettingsTeamDestinationVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type CompanyNotificationSettingsTeamDestinationVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
};


export type CompanyNotificationSettingsTeamDestinationVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type CompanyNotificationSettingsTeamDestinationVarianceSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};

/** A connection to a list of `CompanyNotificationSettingsTeamDestination` values. */
export type CompanyNotificationSettingsTeamDestinationsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CompanyNotificationSettingsTeamDestinationAggregates>;
  /** A list of edges which contains the `CompanyNotificationSettingsTeamDestination` and cursor to aid in pagination. */
  edges: Array<CompanyNotificationSettingsTeamDestinationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CompanyNotificationSettingsTeamDestinationAggregates>>;
  /** A list of `CompanyNotificationSettingsTeamDestination` objects. */
  nodes: Array<Maybe<CompanyNotificationSettingsTeamDestination>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyNotificationSettingsTeamDestination` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CompanyNotificationSettingsTeamDestination` values. */
export type CompanyNotificationSettingsTeamDestinationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyNotificationSettingsTeamDestinationGroupBy>;
  having: InputMaybe<CompanyNotificationSettingsTeamDestinationHavingInput>;
};

/** A `CompanyNotificationSettingsTeamDestination` edge in the connection. */
export type CompanyNotificationSettingsTeamDestinationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `CompanyNotificationSettingsTeamDestination` at the end of the edge. */
  node: Maybe<CompanyNotificationSettingsTeamDestination>;
};

/** Methods to use when ordering `CompanyNotificationSettingsTeamDestination`. */
export enum CompanyNotificationSettingsTeamDestinationsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type CompanyPhone = Node & {
  alias: Scalars['String']['output'];
  /** Reads a single `Company` that is related to this `CompanyPhone`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `CompanyPhoneUser`. */
  companyPhoneUsers: Array<CompanyPhoneUser>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `CompanyPhone`. */
  createdByUser: Maybe<User>;
  forwardingPhone: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isPermanentlyDeleted: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  phone: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFromCompanyPhoneId: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByToCompanyPhoneId: Array<SmsActivity>;
  twilioSid: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};


export type CompanyPhoneCompanyPhoneUsersArgs = {
  condition: InputMaybe<CompanyPhoneUserCondition>;
  filter: InputMaybe<CompanyPhoneUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhoneUsersOrderBy>>;
};


export type CompanyPhoneSmsActivitiesByFromCompanyPhoneIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type CompanyPhoneSmsActivitiesByToCompanyPhoneIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};

/** A filter to be used against aggregates of `CompanyPhone` object types. */
export type CompanyPhoneAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyPhone` objects. */
  arrayAgg: InputMaybe<CompanyPhoneArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyPhone` objects. */
  average: InputMaybe<CompanyPhoneAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyPhone` objects. */
  distinctCount: InputMaybe<CompanyPhoneDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyPhone` object to be included within the aggregate. */
  filter: InputMaybe<CompanyPhoneFilter>;
  /** Maximum aggregate over matching `CompanyPhone` objects. */
  max: InputMaybe<CompanyPhoneMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyPhone` objects. */
  min: InputMaybe<CompanyPhoneMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyPhone` objects. */
  stddevPopulation: InputMaybe<CompanyPhoneStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyPhone` objects. */
  stddevSample: InputMaybe<CompanyPhoneStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyPhone` objects. */
  sum: InputMaybe<CompanyPhoneSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyPhone` objects. */
  variancePopulation: InputMaybe<CompanyPhoneVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyPhone` objects. */
  varianceSample: InputMaybe<CompanyPhoneVarianceSampleAggregateFilter>;
};

export type CompanyPhoneArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  forwardingPhone: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isPermanentlyDeleted: InputMaybe<BooleanListFilter>;
};

export type CompanyPhoneAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `CompanyPhone` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyPhoneCondition = {
  /** Checks for equality with the object’s `alias` field. */
  alias: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `forwardingPhone` field. */
  forwardingPhone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isPermanentlyDeleted` field. */
  isPermanentlyDeleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `twilioSid` field. */
  twilioSid: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type CompanyPhoneDistinctCountAggregateFilter = {
  alias: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  forwardingPhone: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isPermanentlyDeleted: InputMaybe<BigIntFilter>;
  phone: InputMaybe<BigIntFilter>;
  twilioSid: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `CompanyPhone` object types. All fields are combined with a logical ‘and.’ */
export type CompanyPhoneFilter = {
  /** Filter by the object’s `alias` field. */
  alias: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyPhoneFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyPhoneUsers` relation. */
  companyPhoneUsers: InputMaybe<CompanyPhoneToManyCompanyPhoneUserFilter>;
  /** Some related `companyPhoneUsers` exist. */
  companyPhoneUsersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `forwardingPhone` field. */
  forwardingPhone: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPermanentlyDeleted` field. */
  isPermanentlyDeleted: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyPhoneFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyPhoneFilter>>;
  /** Filter by the object’s `phone` field. */
  phone: InputMaybe<StringFilter>;
  /** Filter by the object’s `smsActivitiesByFromCompanyPhoneId` relation. */
  smsActivitiesByFromCompanyPhoneId: InputMaybe<CompanyPhoneToManySmsActivityFilter>;
  /** Some related `smsActivitiesByFromCompanyPhoneId` exist. */
  smsActivitiesByFromCompanyPhoneIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByToCompanyPhoneId` relation. */
  smsActivitiesByToCompanyPhoneId: InputMaybe<CompanyPhoneToManySmsActivityFilter>;
  /** Some related `smsActivitiesByToCompanyPhoneId` exist. */
  smsActivitiesByToCompanyPhoneIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `twilioSid` field. */
  twilioSid: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyPhoneMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyPhoneMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type CompanyPhoneStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `CompanyPhoneUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyPhoneToManyCompanyPhoneUserFilter = {
  /** Aggregates across related `CompanyPhoneUser` match the filter criteria. */
  aggregates: InputMaybe<CompanyPhoneUserAggregatesFilter>;
  /** Every related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyPhoneUserFilter>;
  /** No related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyPhoneUserFilter>;
  /** Some related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyPhoneUserFilter>;
};

/** A filter to be used against many `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type CompanyPhoneToManySmsActivityFilter = {
  /** Aggregates across related `SmsActivity` match the filter criteria. */
  aggregates: InputMaybe<SmsActivityAggregatesFilter>;
  /** Every related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SmsActivityFilter>;
  /** No related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SmsActivityFilter>;
  /** Some related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SmsActivityFilter>;
};

export type CompanyPhoneUser = Node & {
  canReceiveAll: Scalars['Boolean']['output'];
  canSend: Scalars['Boolean']['output'];
  /** Reads a single `CompanyPhone` that is related to this `CompanyPhoneUser`. */
  companyPhone: Maybe<CompanyPhone>;
  companyPhoneId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `CompanyPhoneUser`. */
  createdByUser: Maybe<MainUser>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `CompanyPhoneUser`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `CompanyPhoneUser` object types. */
export type CompanyPhoneUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyPhoneUser` objects. */
  arrayAgg: InputMaybe<CompanyPhoneUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyPhoneUser` objects. */
  average: InputMaybe<CompanyPhoneUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyPhoneUser` objects. */
  distinctCount: InputMaybe<CompanyPhoneUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyPhoneUser` object to be included within the aggregate. */
  filter: InputMaybe<CompanyPhoneUserFilter>;
  /** Maximum aggregate over matching `CompanyPhoneUser` objects. */
  max: InputMaybe<CompanyPhoneUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyPhoneUser` objects. */
  min: InputMaybe<CompanyPhoneUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyPhoneUser` objects. */
  stddevPopulation: InputMaybe<CompanyPhoneUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyPhoneUser` objects. */
  stddevSample: InputMaybe<CompanyPhoneUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyPhoneUser` objects. */
  sum: InputMaybe<CompanyPhoneUserSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyPhoneUser` objects. */
  variancePopulation: InputMaybe<CompanyPhoneUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyPhoneUser` objects. */
  varianceSample: InputMaybe<CompanyPhoneUserVarianceSampleAggregateFilter>;
};

export type CompanyPhoneUserArrayAggAggregateFilter = {
  canReceiveAll: InputMaybe<BooleanListFilter>;
  canSend: InputMaybe<BooleanListFilter>;
  companyPhoneId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type CompanyPhoneUserAverageAggregateFilter = {
  companyPhoneId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `CompanyPhoneUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyPhoneUserCondition = {
  /** Checks for equality with the object’s `canReceiveAll` field. */
  canReceiveAll: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `canSend` field. */
  canSend: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `companyPhoneId` field. */
  companyPhoneId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyPhoneUserDistinctCountAggregateFilter = {
  canReceiveAll: InputMaybe<BigIntFilter>;
  canSend: InputMaybe<BigIntFilter>;
  companyPhoneId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `CompanyPhoneUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyPhoneUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyPhoneUserFilter>>;
  /** Filter by the object’s `canReceiveAll` field. */
  canReceiveAll: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `canSend` field. */
  canSend: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `companyPhone` relation. */
  companyPhone: InputMaybe<CompanyPhoneFilter>;
  /** Filter by the object’s `companyPhoneId` field. */
  companyPhoneId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not: InputMaybe<CompanyPhoneUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyPhoneUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type CompanyPhoneUserMaxAggregateFilter = {
  companyPhoneId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type CompanyPhoneUserMinAggregateFilter = {
  companyPhoneId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type CompanyPhoneUserStddevPopulationAggregateFilter = {
  companyPhoneId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneUserStddevSampleAggregateFilter = {
  companyPhoneId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneUserSumAggregateFilter = {
  companyPhoneId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type CompanyPhoneUserVariancePopulationAggregateFilter = {
  companyPhoneId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneUserVarianceSampleAggregateFilter = {
  companyPhoneId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `CompanyPhoneUser`. */
export enum CompanyPhoneUsersOrderBy {
  CanReceiveAllAsc = 'CAN_RECEIVE_ALL_ASC',
  CanReceiveAllDesc = 'CAN_RECEIVE_ALL_DESC',
  CanSendAsc = 'CAN_SEND_ASC',
  CanSendDesc = 'CAN_SEND_DESC',
  CompanyPhoneIdAsc = 'COMPANY_PHONE_ID_ASC',
  CompanyPhoneIdDesc = 'COMPANY_PHONE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type CompanyPhoneVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type CompanyPhoneVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `CompanyPhone`. */
export enum CompanyPhonesOrderBy {
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyPhoneUsersConnectionArrayAggCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionArrayAggCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionArrayAggCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionArrayAggCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionArrayAggCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionArrayAggCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionArrayAggCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionArrayAggCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionArrayAggCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionArrayAggCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionArrayAggUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionArrayAggUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionArrayAggUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  CompanyPhoneUsersConnectionArrayAggUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  CompanyPhoneUsersConnectionAverageCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionAverageCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionAverageCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionAverageCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionAverageCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionAverageCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionAverageCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionAverageCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionAverageCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionAverageCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionAverageUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionAverageUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionAverageUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_USER_ID_ASC',
  CompanyPhoneUsersConnectionAverageUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_AVERAGE_USER_ID_DESC',
  CompanyPhoneUsersConnectionCountAsc = 'COMPANY_PHONE_USERS_CONNECTION_COUNT_ASC',
  CompanyPhoneUsersConnectionCountDesc = 'COMPANY_PHONE_USERS_CONNECTION_COUNT_DESC',
  CompanyPhoneUsersConnectionDistinctCountCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionDistinctCountCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionDistinctCountCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionDistinctCountCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionDistinctCountCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionDistinctCountCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionDistinctCountCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionDistinctCountCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionDistinctCountCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionDistinctCountCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionDistinctCountUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionDistinctCountUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionDistinctCountUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  CompanyPhoneUsersConnectionDistinctCountUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  CompanyPhoneUsersConnectionMaxCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionMaxCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionMaxCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionMaxCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionMaxCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionMaxCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionMaxCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionMaxCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionMaxCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionMaxCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionMaxUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionMaxUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionMaxUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_USER_ID_ASC',
  CompanyPhoneUsersConnectionMaxUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_MAX_USER_ID_DESC',
  CompanyPhoneUsersConnectionMinCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionMinCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionMinCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionMinCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionMinCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionMinCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionMinCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionMinCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionMinCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionMinCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionMinUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionMinUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionMinUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_USER_ID_ASC',
  CompanyPhoneUsersConnectionMinUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_MIN_USER_ID_DESC',
  CompanyPhoneUsersConnectionStddevPopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionStddevPopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionStddevPopulationCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionStddevPopulationCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionStddevPopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionStddevPopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionStddevPopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionStddevPopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionStddevPopulationCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionStddevPopulationCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionStddevPopulationUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersConnectionStddevPopulationUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersConnectionStddevSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionStddevSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionStddevSampleCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionStddevSampleCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionStddevSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionStddevSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionStddevSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionStddevSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionStddevSampleCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionStddevSampleCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionStddevSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionStddevSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionStddevSampleUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersConnectionStddevSampleUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyPhoneUsersConnectionSumCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionSumCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionSumCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionSumCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionSumCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionSumCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionSumCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionSumCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionSumCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionSumCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionSumUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionSumUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionSumUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_USER_ID_ASC',
  CompanyPhoneUsersConnectionSumUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_SUM_USER_ID_DESC',
  CompanyPhoneUsersConnectionVariancePopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionVariancePopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionVariancePopulationCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionVariancePopulationCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionVariancePopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionVariancePopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionVariancePopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionVariancePopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionVariancePopulationCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionVariancePopulationCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionVariancePopulationUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersConnectionVariancePopulationUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersConnectionVarianceSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersConnectionVarianceSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersConnectionVarianceSampleCanSendAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersConnectionVarianceSampleCanSendDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersConnectionVarianceSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersConnectionVarianceSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersConnectionVarianceSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersConnectionVarianceSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersConnectionVarianceSampleCreatedByAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersConnectionVarianceSampleCreatedByDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersConnectionVarianceSampleUserIdAsc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersConnectionVarianceSampleUserIdDesc = 'COMPANY_PHONE_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  ForwardingPhoneAsc = 'FORWARDING_PHONE_ASC',
  ForwardingPhoneDesc = 'FORWARDING_PHONE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPermanentlyDeletedAsc = 'IS_PERMANENTLY_DELETED_ASC',
  IsPermanentlyDeletedDesc = 'IS_PERMANENTLY_DELETED_DESC',
  Natural = 'NATURAL',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromCompanyPhoneIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToCompanyPhoneIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_COMPANY_PHONE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TwilioSidAsc = 'TWILIO_SID_ASC',
  TwilioSidDesc = 'TWILIO_SID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type CompanyPrivilegedTasksByPrivilegedTaskCompanyIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type CompanyPrivilegedTasksByTaskReportCompanyIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectReportsByProjectReportCompanyIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type CompanyProjectsByPrivilegedTaskCompanyIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type CompanyProjectsByProjectCompanyIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type CompanyProjectsByProjectReportCompanyIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Reminder`. */
export type CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Reminder`. */
export type CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Reminder`. */
export type CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectIdConnection: RemindersConnection;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyEdgeRemindersByProjectIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type CompanyProjectsByReminderCompanyIdAndProjectIdManyToManyEdgeRemindersByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type CompanyProjectsByTaskReportCompanyIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type CompanySystemsByPrivilegedTaskCompanyIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type CompanyTeamsByProjectReportCompanyIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A filter to be used against many `Action` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyActionFilter = {
  /** Aggregates across related `Action` match the filter criteria. */
  aggregates: InputMaybe<ActionAggregatesFilter>;
  /** Every related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionFilter>;
  /** No related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionFilter>;
  /** Some related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionFilter>;
};

/** A filter to be used against many `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyAnalyticFilter = {
  /** Aggregates across related `Analytic` match the filter criteria. */
  aggregates: InputMaybe<AnalyticAggregatesFilter>;
  /** Every related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AnalyticFilter>;
  /** No related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AnalyticFilter>;
  /** Some related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AnalyticFilter>;
};

/** A filter to be used against many `Call` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCallFilter = {
  /** Aggregates across related `Call` match the filter criteria. */
  aggregates: InputMaybe<CallAggregatesFilter>;
  /** Every related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CallFilter>;
  /** No related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CallFilter>;
  /** Some related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CallFilter>;
};

/** A filter to be used against many `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCatalogItemFilter = {
  /** Aggregates across related `CatalogItem` match the filter criteria. */
  aggregates: InputMaybe<CatalogItemAggregatesFilter>;
  /** Every related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CatalogItemFilter>;
  /** No related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CatalogItemFilter>;
  /** Some related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CatalogItemFilter>;
};

/** A filter to be used against many `CompanyNotificationSetting` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyNotificationSettingFilter = {
  /** Aggregates across related `CompanyNotificationSetting` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingAggregatesFilter>;
  /** Every related `CompanyNotificationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingFilter>;
  /** No related `CompanyNotificationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingFilter>;
  /** Some related `CompanyNotificationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingFilter>;
};

/** A filter to be used against many `CompanyNotificationSettingsRoleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyNotificationSettingsRoleDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsRoleDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsRoleDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** No related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
};

/** A filter to be used against many `CompanyNotificationSettingsSingleDestination` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyNotificationSettingsSingleDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsSingleDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsSingleDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** No related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  /** Some related `CompanyNotificationSettingsSingleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
};

/** A filter to be used against many `CompanyPhone` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyPhoneFilter = {
  /** Aggregates across related `CompanyPhone` match the filter criteria. */
  aggregates: InputMaybe<CompanyPhoneAggregatesFilter>;
  /** Every related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyPhoneFilter>;
  /** No related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyPhoneFilter>;
  /** Some related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyPhoneFilter>;
};

/** A filter to be used against many `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyCompanyUserFilter = {
  /** Aggregates across related `CompanyUser` match the filter criteria. */
  aggregates: InputMaybe<CompanyUserAggregatesFilter>;
  /** Every related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyUserFilter>;
  /** No related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyUserFilter>;
  /** Some related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyUserFilter>;
};

/** A filter to be used against many `Dashboard` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyDashboardFilter = {
  /** Aggregates across related `Dashboard` match the filter criteria. */
  aggregates: InputMaybe<DashboardAggregatesFilter>;
  /** Every related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardFilter>;
  /** No related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardFilter>;
  /** Some related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardFilter>;
};

/** A filter to be used against many `EmailAccount` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyEmailAccountFilter = {
  /** Aggregates across related `EmailAccount` match the filter criteria. */
  aggregates: InputMaybe<EmailAccountAggregatesFilter>;
  /** Every related `EmailAccount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<EmailAccountFilter>;
  /** No related `EmailAccount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<EmailAccountFilter>;
  /** Some related `EmailAccount` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<EmailAccountFilter>;
};

/** A filter to be used against many `Filter` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyFilterFilter = {
  /** Aggregates across related `Filter` match the filter criteria. */
  aggregates: InputMaybe<FilterAggregatesFilter>;
  /** Every related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterFilter>;
  /** No related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterFilter>;
  /** Some related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterFilter>;
};

/** A filter to be used against many `FilterFolder` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyFilterFolderFilter = {
  /** Aggregates across related `FilterFolder` match the filter criteria. */
  aggregates: InputMaybe<FilterFolderAggregatesFilter>;
  /** Every related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterFolderFilter>;
  /** No related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterFolderFilter>;
  /** Some related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterFolderFilter>;
};

/** A filter to be used against many `GeolocationUserSetting` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyGeolocationUserSettingFilter = {
  /** Aggregates across related `GeolocationUserSetting` match the filter criteria. */
  aggregates: InputMaybe<GeolocationUserSettingAggregatesFilter>;
  /** Every related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeolocationUserSettingFilter>;
  /** No related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeolocationUserSettingFilter>;
  /** Some related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeolocationUserSettingFilter>;
};

/** A filter to be used against many `Integration` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyIntegrationFilter = {
  /** Aggregates across related `Integration` match the filter criteria. */
  aggregates: InputMaybe<IntegrationAggregatesFilter>;
  /** Every related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<IntegrationFilter>;
  /** No related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<IntegrationFilter>;
  /** Some related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<IntegrationFilter>;
};

/** A filter to be used against many `Jurisdiction` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyJurisdictionFilter = {
  /** Aggregates across related `Jurisdiction` match the filter criteria. */
  aggregates: InputMaybe<JurisdictionAggregatesFilter>;
  /** Every related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<JurisdictionFilter>;
  /** No related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<JurisdictionFilter>;
  /** Some related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<JurisdictionFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `Reminder` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyReminderFilter = {
  /** Aggregates across related `Reminder` match the filter criteria. */
  aggregates: InputMaybe<ReminderAggregatesFilter>;
  /** Every related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ReminderFilter>;
  /** No related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ReminderFilter>;
  /** Some related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ReminderFilter>;
};

/** A filter to be used against many `Role` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyRoleFilter = {
  /** Aggregates across related `Role` match the filter criteria. */
  aggregates: InputMaybe<RoleAggregatesFilter>;
  /** Every related `Role` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<RoleFilter>;
  /** No related `Role` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<RoleFilter>;
  /** Some related `Role` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<RoleFilter>;
};

/** A filter to be used against many `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManySmsActivityFilter = {
  /** Aggregates across related `SmsActivity` match the filter criteria. */
  aggregates: InputMaybe<SmsActivityAggregatesFilter>;
  /** Every related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SmsActivityFilter>;
  /** No related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SmsActivityFilter>;
  /** Some related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SmsActivityFilter>;
};

/** A filter to be used against many `SystemBudget` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManySystemBudgetFilter = {
  /** Aggregates across related `SystemBudget` match the filter criteria. */
  aggregates: InputMaybe<SystemBudgetAggregatesFilter>;
  /** Every related `SystemBudget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemBudgetFilter>;
  /** No related `SystemBudget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemBudgetFilter>;
  /** Some related `SystemBudget` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemBudgetFilter>;
};

/** A filter to be used against many `SystemProfile` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManySystemProfileFilter = {
  /** Aggregates across related `SystemProfile` match the filter criteria. */
  aggregates: InputMaybe<SystemProfileAggregatesFilter>;
  /** Every related `SystemProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemProfileFilter>;
  /** No related `SystemProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemProfileFilter>;
  /** Some related `SystemProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemProfileFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

/** A filter to be used against many `Team` object types. All fields are combined with a logical ‘and.’ */
export type CompanyToManyTeamFilter = {
  /** Aggregates across related `Team` match the filter criteria. */
  aggregates: InputMaybe<TeamAggregatesFilter>;
  /** Every related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TeamFilter>;
  /** No related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TeamFilter>;
  /** Some related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TeamFilter>;
};

export type CompanyUser = Node & {
  /** Reads a single `Company` that is related to this `CompanyUser`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `CompanyUserRole`. */
  companyUserRoles: Array<CompanyUserRole>;
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  invitationToken: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isCollaborator: Scalars['Boolean']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `CompanyUser`. */
  user: Maybe<MainUser>;
  userId: Maybe<Scalars['Int']['output']>;
};


export type CompanyUserCompanyUserRolesArgs = {
  condition: InputMaybe<CompanyUserRoleCondition>;
  filter: InputMaybe<CompanyUserRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUserRolesOrderBy>>;
};

/** A filter to be used against aggregates of `CompanyUser` object types. */
export type CompanyUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyUser` objects. */
  arrayAgg: InputMaybe<CompanyUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyUser` objects. */
  average: InputMaybe<CompanyUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyUser` objects. */
  distinctCount: InputMaybe<CompanyUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyUser` object to be included within the aggregate. */
  filter: InputMaybe<CompanyUserFilter>;
  /** Maximum aggregate over matching `CompanyUser` objects. */
  max: InputMaybe<CompanyUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyUser` objects. */
  min: InputMaybe<CompanyUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyUser` objects. */
  stddevPopulation: InputMaybe<CompanyUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyUser` objects. */
  stddevSample: InputMaybe<CompanyUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyUser` objects. */
  sum: InputMaybe<CompanyUserSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyUser` objects. */
  variancePopulation: InputMaybe<CompanyUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyUser` objects. */
  varianceSample: InputMaybe<CompanyUserVarianceSampleAggregateFilter>;
};

export type CompanyUserArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isActive: InputMaybe<BooleanListFilter>;
  isCollaborator: InputMaybe<BooleanListFilter>;
  isConfirmed: InputMaybe<BooleanListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type CompanyUserAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `CompanyUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CompanyUserCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `invitationToken` field. */
  invitationToken: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCollaborator` field. */
  isCollaborator: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isConfirmed` field. */
  isConfirmed: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyUserDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  invitationToken: InputMaybe<BigIntFilter>;
  isActive: InputMaybe<BigIntFilter>;
  isCollaborator: InputMaybe<BigIntFilter>;
  isConfirmed: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type CompanyUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyUserFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyUserRoles` relation. */
  companyUserRoles: InputMaybe<CompanyUserToManyCompanyUserRoleFilter>;
  /** Some related `companyUserRoles` exist. */
  companyUserRolesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `invitationToken` field. */
  invitationToken: InputMaybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCollaborator` field. */
  isCollaborator: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isConfirmed` field. */
  isConfirmed: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** A related `user` exists. */
  userExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type CompanyUserMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type CompanyUserMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type CompanyUserRole = Node & {
  /** Reads a single `CompanyUser` that is related to this `CompanyUserRole`. */
  companyUser: Maybe<CompanyUser>;
  companyUserId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `CompanyUserRole`. */
  roles: Maybe<Role>;
  rolesId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `CompanyUserRole` object types. */
export type CompanyUserRoleAggregatesFilter = {
  /** Array aggregation aggregate over matching `CompanyUserRole` objects. */
  arrayAgg: InputMaybe<CompanyUserRoleArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `CompanyUserRole` objects. */
  average: InputMaybe<CompanyUserRoleAverageAggregateFilter>;
  /** Distinct count aggregate over matching `CompanyUserRole` objects. */
  distinctCount: InputMaybe<CompanyUserRoleDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `CompanyUserRole` object to be included within the aggregate. */
  filter: InputMaybe<CompanyUserRoleFilter>;
  /** Maximum aggregate over matching `CompanyUserRole` objects. */
  max: InputMaybe<CompanyUserRoleMaxAggregateFilter>;
  /** Minimum aggregate over matching `CompanyUserRole` objects. */
  min: InputMaybe<CompanyUserRoleMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `CompanyUserRole` objects. */
  stddevPopulation: InputMaybe<CompanyUserRoleStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `CompanyUserRole` objects. */
  stddevSample: InputMaybe<CompanyUserRoleStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `CompanyUserRole` objects. */
  sum: InputMaybe<CompanyUserRoleSumAggregateFilter>;
  /** Population variance aggregate over matching `CompanyUserRole` objects. */
  variancePopulation: InputMaybe<CompanyUserRoleVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `CompanyUserRole` objects. */
  varianceSample: InputMaybe<CompanyUserRoleVarianceSampleAggregateFilter>;
};

export type CompanyUserRoleArrayAggAggregateFilter = {
  companyUserId: InputMaybe<IntListFilter>;
  rolesId: InputMaybe<IntListFilter>;
};

export type CompanyUserRoleAverageAggregateFilter = {
  companyUserId: InputMaybe<BigFloatFilter>;
  rolesId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `CompanyUserRole` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompanyUserRoleCondition = {
  /** Checks for equality with the object’s `companyUserId` field. */
  companyUserId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `rolesId` field. */
  rolesId: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyUserRoleDistinctCountAggregateFilter = {
  companyUserId: InputMaybe<BigIntFilter>;
  rolesId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `CompanyUserRole` object types. All fields are combined with a logical ‘and.’ */
export type CompanyUserRoleFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<CompanyUserRoleFilter>>;
  /** Filter by the object’s `companyUser` relation. */
  companyUser: InputMaybe<CompanyUserFilter>;
  /** Filter by the object’s `companyUserId` field. */
  companyUserId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<CompanyUserRoleFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<CompanyUserRoleFilter>>;
  /** Filter by the object’s `roles` relation. */
  roles: InputMaybe<RoleFilter>;
  /** Filter by the object’s `rolesId` field. */
  rolesId: InputMaybe<IntFilter>;
};

export type CompanyUserRoleMaxAggregateFilter = {
  companyUserId: InputMaybe<IntFilter>;
  rolesId: InputMaybe<IntFilter>;
};

export type CompanyUserRoleMinAggregateFilter = {
  companyUserId: InputMaybe<IntFilter>;
  rolesId: InputMaybe<IntFilter>;
};

export type CompanyUserRoleStddevPopulationAggregateFilter = {
  companyUserId: InputMaybe<BigFloatFilter>;
  rolesId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserRoleStddevSampleAggregateFilter = {
  companyUserId: InputMaybe<BigFloatFilter>;
  rolesId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserRoleSumAggregateFilter = {
  companyUserId: InputMaybe<BigIntFilter>;
  rolesId: InputMaybe<BigIntFilter>;
};

export type CompanyUserRoleVariancePopulationAggregateFilter = {
  companyUserId: InputMaybe<BigFloatFilter>;
  rolesId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserRoleVarianceSampleAggregateFilter = {
  companyUserId: InputMaybe<BigFloatFilter>;
  rolesId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `CompanyUserRole`. */
export enum CompanyUserRolesOrderBy {
  CompanyUserIdAsc = 'COMPANY_USER_ID_ASC',
  CompanyUserIdDesc = 'COMPANY_USER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RolesIdAsc = 'ROLES_ID_ASC',
  RolesIdDesc = 'ROLES_ID_DESC'
}

export type CompanyUserStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `CompanyUserRole` object types. All fields are combined with a logical ‘and.’ */
export type CompanyUserToManyCompanyUserRoleFilter = {
  /** Aggregates across related `CompanyUserRole` match the filter criteria. */
  aggregates: InputMaybe<CompanyUserRoleAggregatesFilter>;
  /** Every related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyUserRoleFilter>;
  /** No related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyUserRoleFilter>;
  /** Some related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyUserRoleFilter>;
};

export type CompanyUserVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type CompanyUserVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `User` values, with data from `Dashboard`. */
export type CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Dashboard`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Dashboard`. */
export type CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Dashboard`. */
export type CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedBy: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedByConnection: DashboardsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Dashboard`. */
export type CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyEdgeDashboardsByCreatedByArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Dashboard`. */
export type CompanyUsersByDashboardCompanyIdAndCreatedByManyToManyEdgeDashboardsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type CompanyUsersByProjectCompanyIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type CompanyUsersByProjectReportCompanyIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeIdConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByReminderCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedBy: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedByConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndCreatedByManyToManyEdgeRemindersByCreatedByArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type CompanyUsersByReminderCompanyIdAndCreatedByManyToManyEdgeRemindersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Team`. */
export type CompanyUsersByTeamCompanyIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Team`, and the cursor to aid in pagination. */
  edges: Array<CompanyUsersByTeamCompanyIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Team`. */
export type CompanyUsersByTeamCompanyIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Team`. */
export type CompanyUsersByTeamCompanyIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Team`. */
  teams: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsConnection: TeamsConnection;
};


/** A `User` edge in the connection, with data from `Team`. */
export type CompanyUsersByTeamCompanyIdAndCreatedByManyToManyEdgeTeamsArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Team`. */
export type CompanyUsersByTeamCompanyIdAndCreatedByManyToManyEdgeTeamsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** Methods to use when ordering `CompanyUser`. */
export enum CompanyUsersOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyUserRolesConnectionArrayAggCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_ARRAY_AGG_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionArrayAggCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_ARRAY_AGG_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionArrayAggRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_ARRAY_AGG_ROLES_ID_ASC',
  CompanyUserRolesConnectionArrayAggRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_ARRAY_AGG_ROLES_ID_DESC',
  CompanyUserRolesConnectionAverageCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_AVERAGE_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionAverageCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_AVERAGE_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionAverageRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_AVERAGE_ROLES_ID_ASC',
  CompanyUserRolesConnectionAverageRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_AVERAGE_ROLES_ID_DESC',
  CompanyUserRolesConnectionCountAsc = 'COMPANY_USER_ROLES_CONNECTION_COUNT_ASC',
  CompanyUserRolesConnectionCountDesc = 'COMPANY_USER_ROLES_CONNECTION_COUNT_DESC',
  CompanyUserRolesConnectionDistinctCountCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_DISTINCT_COUNT_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionDistinctCountCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_DISTINCT_COUNT_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionDistinctCountRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_DISTINCT_COUNT_ROLES_ID_ASC',
  CompanyUserRolesConnectionDistinctCountRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_DISTINCT_COUNT_ROLES_ID_DESC',
  CompanyUserRolesConnectionMaxCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_MAX_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionMaxCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_MAX_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionMaxRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_MAX_ROLES_ID_ASC',
  CompanyUserRolesConnectionMaxRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_MAX_ROLES_ID_DESC',
  CompanyUserRolesConnectionMinCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_MIN_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionMinCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_MIN_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionMinRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_MIN_ROLES_ID_ASC',
  CompanyUserRolesConnectionMinRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_MIN_ROLES_ID_DESC',
  CompanyUserRolesConnectionStddevPopulationCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_POPULATION_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionStddevPopulationCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_POPULATION_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionStddevPopulationRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_POPULATION_ROLES_ID_ASC',
  CompanyUserRolesConnectionStddevPopulationRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_POPULATION_ROLES_ID_DESC',
  CompanyUserRolesConnectionStddevSampleCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_SAMPLE_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionStddevSampleCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_SAMPLE_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionStddevSampleRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_SAMPLE_ROLES_ID_ASC',
  CompanyUserRolesConnectionStddevSampleRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_STDDEV_SAMPLE_ROLES_ID_DESC',
  CompanyUserRolesConnectionSumCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_SUM_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionSumCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_SUM_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionSumRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_SUM_ROLES_ID_ASC',
  CompanyUserRolesConnectionSumRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_SUM_ROLES_ID_DESC',
  CompanyUserRolesConnectionVariancePopulationCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_POPULATION_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionVariancePopulationCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_POPULATION_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionVariancePopulationRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_POPULATION_ROLES_ID_ASC',
  CompanyUserRolesConnectionVariancePopulationRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_POPULATION_ROLES_ID_DESC',
  CompanyUserRolesConnectionVarianceSampleCompanyUserIdAsc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_SAMPLE_COMPANY_USER_ID_ASC',
  CompanyUserRolesConnectionVarianceSampleCompanyUserIdDesc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_SAMPLE_COMPANY_USER_ID_DESC',
  CompanyUserRolesConnectionVarianceSampleRolesIdAsc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLES_ID_ASC',
  CompanyUserRolesConnectionVarianceSampleRolesIdDesc = 'COMPANY_USER_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLES_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvitationTokenAsc = 'INVITATION_TOKEN_ASC',
  InvitationTokenDesc = 'INVITATION_TOKEN_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsCollaboratorAsc = 'IS_COLLABORATOR_ASC',
  IsCollaboratorDesc = 'IS_COLLABORATOR_DESC',
  IsConfirmedAsc = 'IS_CONFIRMED_ASC',
  IsConfirmedDesc = 'IS_CONFIRMED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Consumer = Node & {
  consumerType: CommentCreatedBy;
  /** Reads a single `Contact` that is related to this `Consumer`. */
  contact: Maybe<Contact>;
  contactId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  /** Reads a single `Feed` that is related to this `Consumer`. */
  feed: Maybe<Feed>;
  feedId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  mentioned: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  read: Scalars['Boolean']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `User` that is related to this `Consumer`. */
  user: Maybe<User>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type ConsumerAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ConsumerArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ConsumerAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ConsumerDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ConsumerMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ConsumerMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ConsumerStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ConsumerStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ConsumerSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ConsumerVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ConsumerVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Consumer` object types. */
export type ConsumerAggregatesFilter = {
  /** Array aggregation aggregate over matching `Consumer` objects. */
  arrayAgg: InputMaybe<ConsumerArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Consumer` objects. */
  average: InputMaybe<ConsumerAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Consumer` objects. */
  distinctCount: InputMaybe<ConsumerDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Consumer` object to be included within the aggregate. */
  filter: InputMaybe<ConsumerFilter>;
  /** Maximum aggregate over matching `Consumer` objects. */
  max: InputMaybe<ConsumerMaxAggregateFilter>;
  /** Minimum aggregate over matching `Consumer` objects. */
  min: InputMaybe<ConsumerMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Consumer` objects. */
  stddevPopulation: InputMaybe<ConsumerStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Consumer` objects. */
  stddevSample: InputMaybe<ConsumerStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Consumer` objects. */
  sum: InputMaybe<ConsumerSumAggregateFilter>;
  /** Population variance aggregate over matching `Consumer` objects. */
  variancePopulation: InputMaybe<ConsumerVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Consumer` objects. */
  varianceSample: InputMaybe<ConsumerVarianceSampleAggregateFilter>;
};

export type ConsumerArrayAggAggregateFilter = {
  contactId: InputMaybe<IntListFilter>;
  feedId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  mentioned: InputMaybe<BooleanListFilter>;
  read: InputMaybe<BooleanListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type ConsumerArrayAggAggregates = {
  /** Array aggregation of contactId across the matching connection */
  contactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of feedId across the matching connection */
  feedId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of mentioned across the matching connection */
  mentioned: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of read across the matching connection */
  read: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of userId across the matching connection */
  userId: Array<Maybe<Scalars['Int']['output']>>;
};


export type ConsumerArrayAggAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerArrayAggAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerArrayAggAggregatesMentionedArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerArrayAggAggregatesReadArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerArrayAggAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerAverageAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type ConsumerAverageAggregates = {
  /** Mean average of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type ConsumerAverageAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerAverageAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerAverageAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

/**
 * A condition to be used against `Consumer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ConsumerCondition = {
  /** Checks for equality with the object’s `consumerType` field. */
  consumerType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `mentioned` field. */
  mentioned: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `read` field. */
  read: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type ConsumerDistinctCountAggregateFilter = {
  consumerType: InputMaybe<BigIntFilter>;
  contactId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  feedId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  mentioned: InputMaybe<BigIntFilter>;
  read: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type ConsumerDistinctCountAggregates = {
  /** Distinct count of consumerType across the matching connection */
  consumerType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of feedId across the matching connection */
  feedId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of mentioned across the matching connection */
  mentioned: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of read across the matching connection */
  read: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userId across the matching connection */
  userId: Maybe<Scalars['BigInt']['output']>;
};


export type ConsumerDistinctCountAggregatesConsumerTypeArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesMentionedArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesReadArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerDistinctCountAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A filter to be used against `Consumer` object types. All fields are combined with a logical ‘and.’ */
export type ConsumerFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ConsumerFilter>>;
  /** Filter by the object’s `consumerType` field. */
  consumerType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `contact` relation. */
  contact: InputMaybe<ContactFilter>;
  /** A related `contact` exists. */
  contactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `contactId` field. */
  contactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feed` relation. */
  feed: InputMaybe<FeedFilter>;
  /** A related `feed` exists. */
  feedExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedId` field. */
  feedId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `mentioned` field. */
  mentioned: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<ConsumerFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ConsumerFilter>>;
  /** Filter by the object’s `read` field. */
  read: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

/** Grouping methods for `Consumer` for usage during aggregation. */
export enum ConsumerGroupBy {
  ConsumerType = 'CONSUMER_TYPE',
  ContactId = 'CONTACT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  FeedId = 'FEED_ID',
  Mentioned = 'MENTIONED',
  Read = 'READ',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  UserId = 'USER_ID'
}

export type ConsumerHavingArrayAggInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingAverageInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingDistinctCountInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Consumer` aggregates. */
export type ConsumerHavingInput = {
  AND: InputMaybe<Array<ConsumerHavingInput>>;
  OR: InputMaybe<Array<ConsumerHavingInput>>;
  arrayAgg: InputMaybe<ConsumerHavingArrayAggInput>;
  average: InputMaybe<ConsumerHavingAverageInput>;
  distinctCount: InputMaybe<ConsumerHavingDistinctCountInput>;
  max: InputMaybe<ConsumerHavingMaxInput>;
  min: InputMaybe<ConsumerHavingMinInput>;
  stddevPopulation: InputMaybe<ConsumerHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ConsumerHavingStddevSampleInput>;
  sum: InputMaybe<ConsumerHavingSumInput>;
  variancePopulation: InputMaybe<ConsumerHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ConsumerHavingVarianceSampleInput>;
};

export type ConsumerHavingMaxInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingMinInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingStddevPopulationInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingStddevSampleInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingSumInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingVariancePopulationInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerHavingVarianceSampleInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type ConsumerMaxAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  feedId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type ConsumerMaxAggregates = {
  /** Maximum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of feedId across the matching connection */
  feedId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type ConsumerMaxAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMaxAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMaxAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerMinAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  feedId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type ConsumerMinAggregates = {
  /** Minimum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of feedId across the matching connection */
  feedId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type ConsumerMinAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMinAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerMinAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerStddevPopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type ConsumerStddevPopulationAggregates = {
  /** Population standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type ConsumerStddevPopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevPopulationAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevPopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerStddevSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type ConsumerStddevSampleAggregates = {
  /** Sample standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type ConsumerStddevSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevSampleAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerStddevSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerSumAggregateFilter = {
  contactId: InputMaybe<BigIntFilter>;
  feedId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type ConsumerSumAggregates = {
  /** Sum of contactId across the matching connection */
  contactId: Scalars['BigInt']['output'];
  /** Sum of feedId across the matching connection */
  feedId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt']['output'];
};


export type ConsumerSumAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerSumAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerSumAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerVariancePopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type ConsumerVariancePopulationAggregates = {
  /** Population variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type ConsumerVariancePopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVariancePopulationAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVariancePopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

export type ConsumerVarianceSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type ConsumerVarianceSampleAggregates = {
  /** Sample variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type ConsumerVarianceSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVarianceSampleAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ConsumerVarianceSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `Consumer` values. */
export type ConsumersConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ConsumerAggregates>;
  /** A list of edges which contains the `Consumer` and cursor to aid in pagination. */
  edges: Array<ConsumersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ConsumerAggregates>>;
  /** A list of `Consumer` objects. */
  nodes: Array<Maybe<Consumer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Consumer` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Consumer` values. */
export type ConsumersConnectionGroupedAggregatesArgs = {
  groupBy: Array<ConsumerGroupBy>;
  having: InputMaybe<ConsumerHavingInput>;
};

/** A `Consumer` edge in the connection. */
export type ConsumersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Consumer` at the end of the edge. */
  node: Maybe<Consumer>;
};

/** Methods to use when ordering `Consumer`. */
export enum ConsumersOrderBy {
  ConsumerTypeAsc = 'CONSUMER_TYPE_ASC',
  ConsumerTypeDesc = 'CONSUMER_TYPE_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FeedIdAsc = 'FEED_ID_ASC',
  FeedIdDesc = 'FEED_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MentionedAsc = 'MENTIONED_ASC',
  MentionedDesc = 'MENTIONED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReadAsc = 'READ_ASC',
  ReadDesc = 'READ_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Contact = Node & {
  /** Reads and enables pagination through a set of `ActionAssignee`. */
  actionAssignees: Array<ActionAssignee>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCreatedByContactIdAndActionId: ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCreatedByContactIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCreatedByContactIdAndActionId: ContactActionsByFileCreatedByContactIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCreatedByContactIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectCreatedByContactIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReferrerContactIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCreatedByContactIdAndCallId: ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCreatedByContactIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFromContactId: Array<Call>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFromContactIdConnection: CallsConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByToContactId: Array<Call>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByToContactIdConnection: CallsConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByCreatedByContactIdConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedCreatedByContactIdAndCommentId: ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileCreatedByContactIdAndCommentId: ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectCreatedByContactIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReferrerContactIdAndCompanyIdList: Array<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersConnection: ConsumersConnection;
  /** Reads and enables pagination through a set of `Project`. */
  contactProjects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  contactProjectsConnection: ContactContactProjectsConnectionManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByContactIdAndReferrerContactId: ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByContactIdAndReferrerContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectReferrerContactIdAndCreatedByContactId: ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectReferrerContactIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByReferrerContactId: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByReferrerContactIdConnection: ContactsConnection;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `Contact`. */
  createdByUser: Maybe<User>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedCreatedByContactIdAndEmailMessageIdList: Array<EmailMessage>;
  emails: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesConnection: FeedInboxesConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByConsumerContactIdAndFeedId: ContactFeedsByConsumerContactIdAndFeedIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedCreatedByContactIdAndParentId: ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedInboxContactIdAndFeedId: ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCreatedByContactIdAndFileId: ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCreatedByContactIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCreatedByContactIdAndValueFileId: ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCreatedByContactIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCreatedByContactIdAndFormId: ContactFormsByFileCreatedByContactIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCreatedByContactIdAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRows: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsConnection: ImportRowsConnection;
  invitedCount: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCreatedByContactIdAndJurisdictionId: ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCreatedByContactIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReferrerContactIdAndJurisdictionId: ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReferrerContactIdAndJurisdictionIdList: Array<Jurisdiction>;
  lastInvitedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByFeedInboxContactIdAndUserIdList: Array<MainUser>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  phones: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  portalStatus: Maybe<PortalStatus>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedCreatedByContactIdAndTaskId: ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectContact`. */
  projectContacts: Array<ProjectContact>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileCreatedByContactIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectCreatedByContactIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReferrerContactIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCreatedByContactIdAndProjectId: ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCreatedByContactIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCreatedByContactIdAndProjectId: ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCreatedByContactIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCreatedByContactIdAndParentProjectId: ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCreatedByContactIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReferrerContactIdAndParentProjectId: ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReferrerContactIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
  referralCode: Maybe<Scalars['String']['output']>;
  /** Reads a single `Contact` that is related to this `Contact`. */
  referrerContact: Maybe<Contact>;
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCreatedByContactIdAndReminderId: ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCreatedByContactIdAndReminderIdList: Array<Reminder>;
  searchString: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedCreatedByContactIdAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFromContactId: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByToContactId: Array<SmsActivity>;
  source: CreatedSource;
  status: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedCreatedByContactIdAndSystemId: ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyConnection;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByConsumerContactIdAndUserId: ContactUsersByConsumerContactIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedCreatedByContactIdAndCreatedBy: ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileCreatedByContactIdAndCreatedBy: ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByContactIdAndCreatedBy: ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByContactIdAndOwnerId: ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByContactIdAndProjectManagerId: ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByContactIdAndSalesRepId: ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReferrerContactIdAndCreatedBy: ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReferrerContactIdAndOwnerId: ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReferrerContactIdAndProjectManagerId: ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReferrerContactIdAndSalesRepId: ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyConnection;
};


export type ContactActionAssigneesArgs = {
  condition: InputMaybe<ActionAssigneeCondition>;
  filter: InputMaybe<ActionAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionAssigneesOrderBy>>;
};


export type ContactActionsByFeedCreatedByContactIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ContactActionsByFeedCreatedByContactIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ContactActionsByFileCreatedByContactIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ContactActionsByFileCreatedByContactIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ContactBlueprintsByProjectCreatedByContactIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ContactBlueprintsByProjectReferrerContactIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ContactCallsByFeedCreatedByContactIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCallsByFeedCreatedByContactIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCallsByFromContactIdArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCallsByFromContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCallsByToContactIdArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCallsByToContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ContactCommentsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ContactCommentsByFeedCreatedByContactIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ContactCommentsByFileCreatedByContactIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ContactCompaniesByProjectCreatedByContactIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ContactCompaniesByProjectReferrerContactIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ContactConsumersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};


export type ContactContactProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactContactProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactContactsByReferrerContactIdArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactContactsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactEmailMessagesByFeedCreatedByContactIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type ContactFeedInboxesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type ContactFeedsByConsumerContactIdAndFeedIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ContactFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ContactFeedsByFeedCreatedByContactIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ContactFeedsByFeedInboxContactIdAndFeedIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ContactFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFilesByFeedCreatedByContactIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFilesByFeedCreatedByContactIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFilesByFileCreatedByContactIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFilesByFileCreatedByContactIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ContactFormsByFileCreatedByContactIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ContactFormsByFileCreatedByContactIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ContactImportRowsArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ContactImportRowsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ContactMainUsersByFeedInboxContactIdAndUserIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ContactProjectContactsArgs = {
  condition: InputMaybe<ProjectContactCondition>;
  filter: InputMaybe<ProjectContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectContactsOrderBy>>;
};


export type ContactProjectStagesByFileCreatedByContactIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ContactProjectStagesByProjectCreatedByContactIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ContactProjectStagesByProjectReferrerContactIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ContactProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByFeedCreatedByContactIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByFeedCreatedByContactIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByFileCreatedByContactIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByFileCreatedByContactIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ContactRemindersByFeedCreatedByContactIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type ContactRemindersByFeedCreatedByContactIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ContactSmsActivitiesByFeedCreatedByContactIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ContactSmsActivitiesByFromContactIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ContactSmsActivitiesByToContactIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ContactSystemsByFeedCreatedByContactIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ContactUsersByConsumerContactIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByFeedCreatedByContactIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByFileCreatedByContactIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectCreatedByContactIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectCreatedByContactIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectReferrerContactIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectReferrerContactIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ContactUsersByProjectReferrerContactIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type ContactActionsByFeedCreatedByContactIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type ContactActionsByFileCreatedByContactIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactActionsByFileCreatedByContactIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type ContactActionsByFileCreatedByContactIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type ContactActionsByFileCreatedByContactIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ContactActionsByFileCreatedByContactIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ContactActionsByFileCreatedByContactIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ContactAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ContactArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ContactAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ContactDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ContactMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ContactMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ContactStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ContactStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ContactSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ContactVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ContactVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Contact` object types. */
export type ContactAggregatesFilter = {
  /** Array aggregation aggregate over matching `Contact` objects. */
  arrayAgg: InputMaybe<ContactArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Contact` objects. */
  average: InputMaybe<ContactAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Contact` objects. */
  distinctCount: InputMaybe<ContactDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Contact` object to be included within the aggregate. */
  filter: InputMaybe<ContactFilter>;
  /** Maximum aggregate over matching `Contact` objects. */
  max: InputMaybe<ContactMaxAggregateFilter>;
  /** Minimum aggregate over matching `Contact` objects. */
  min: InputMaybe<ContactMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Contact` objects. */
  stddevPopulation: InputMaybe<ContactStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Contact` objects. */
  stddevSample: InputMaybe<ContactStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Contact` objects. */
  sum: InputMaybe<ContactSumAggregateFilter>;
  /** Population variance aggregate over matching `Contact` objects. */
  variancePopulation: InputMaybe<ContactVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Contact` objects. */
  varianceSample: InputMaybe<ContactVarianceSampleAggregateFilter>;
};

export type ContactArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  invitedCount: InputMaybe<IntListFilter>;
  referrerContactId: InputMaybe<IntListFilter>;
  searchString: InputMaybe<StringListFilter>;
};

export type ContactArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of invitedCount across the matching connection */
  invitedCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of referrerContactId across the matching connection */
  referrerContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of searchString across the matching connection */
  searchString: Array<Maybe<Scalars['String']['output']>>;
};


export type ContactArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactArrayAggAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactArrayAggAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactArrayAggAggregatesSearchStringArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

export type ContactAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  invitedCount: InputMaybe<BigFloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
};

export type ContactAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type ContactAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactAverageAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactAverageAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type ContactCallsByFeedCreatedByContactIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type ContactCommentsByFeedCreatedByContactIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ContactCommentsByFileCreatedByContactIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `Contact` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ContactCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `emails` field. */
  emails: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `invitedCount` field. */
  invitedCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastInvitedAt` field. */
  lastInvitedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phones` field. */
  phones: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `portalStatus` field. */
  portalStatus: InputMaybe<PortalStatus>;
  /** Checks for equality with the object’s `referralCode` field. */
  referralCode: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `searchString` field. */
  searchString: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `source` field. */
  source: InputMaybe<CreatedSource>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Filters list of projects by any of adresses including given string. */
  withAccess: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `Project` values, with data from `ProjectContact`. */
export type ContactContactProjectsConnectionManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectContact`, and the cursor to aid in pagination. */
  edges: Array<ContactContactProjectsConnectionManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectContact`. */
export type ContactContactProjectsConnectionManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectContact`. */
export type ContactContactProjectsConnectionManyToManyEdge = {
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  position: Scalars['Int']['output'];
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectCreatedByContactIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ContactContactsByProjectReferrerContactIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ContactDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  emails: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  invitedCount: InputMaybe<BigIntFilter>;
  lastInvitedAt: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  phones: InputMaybe<BigIntFilter>;
  portalStatus: InputMaybe<BigIntFilter>;
  referralCode: InputMaybe<BigIntFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
  searchString: InputMaybe<BigIntFilter>;
  source: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type ContactDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emails across the matching connection */
  emails: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInvitedAt across the matching connection */
  lastInvitedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phones across the matching connection */
  phones: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of portalStatus across the matching connection */
  portalStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of referralCode across the matching connection */
  referralCode: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchString across the matching connection */
  searchString: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of source across the matching connection */
  source: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ContactDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesEmailsArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesLastInvitedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesPhonesArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesPortalStatusArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesReferralCodeArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesSearchStringArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesSourceArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Consumer`. */
export type ContactFeedsByConsumerContactIdAndFeedIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<ContactFeedsByConsumerContactIdAndFeedIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Consumer`. */
export type ContactFeedsByConsumerContactIdAndFeedIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Consumer`. */
export type ContactFeedsByConsumerContactIdAndFeedIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByFeedIdConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Consumer`. */
export type ContactFeedsByConsumerContactIdAndFeedIdManyToManyEdgeConsumersByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type ContactFeedsByFeedCreatedByContactIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `FeedInbox`. */
export type ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `FeedInbox`, and the cursor to aid in pagination. */
  edges: Array<ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `FeedInbox`. */
export type ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `FeedInbox`. */
export type ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesByFeedIdConnection: FeedInboxesConnection;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `FeedInbox`. */
export type ContactFeedsByFeedInboxContactIdAndFeedIdManyToManyEdgeFeedInboxesByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type ContactFilesByFeedCreatedByContactIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ContactFilesByFileCreatedByContactIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `Contact` object types. All fields are combined with a logical ‘and.’ */
export type ContactFilter = {
  /** Filter by the object’s `actionAssignees` relation. */
  actionAssignees: InputMaybe<ContactToManyActionAssigneeFilter>;
  /** Some related `actionAssignees` exist. */
  actionAssigneesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ContactFilter>>;
  /** Filter by the object’s `callsByFromContactId` relation. */
  callsByFromContactId: InputMaybe<ContactToManyCallFilter>;
  /** Some related `callsByFromContactId` exist. */
  callsByFromContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `callsByToContactId` relation. */
  callsByToContactId: InputMaybe<ContactToManyCallFilter>;
  /** Some related `callsByToContactId` exist. */
  callsByToContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `commentsByCreatedByContactId` relation. */
  commentsByCreatedByContactId: InputMaybe<ContactToManyCommentFilter>;
  /** Some related `commentsByCreatedByContactId` exist. */
  commentsByCreatedByContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `consumers` relation. */
  consumers: InputMaybe<ContactToManyConsumerFilter>;
  /** Some related `consumers` exist. */
  consumersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `contactsByReferrerContactId` relation. */
  contactsByReferrerContactId: InputMaybe<ContactToManyContactFilter>;
  /** Some related `contactsByReferrerContactId` exist. */
  contactsByReferrerContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** Filter by the object’s `emails` field. */
  emails: InputMaybe<StringListFilter>;
  /** Filter by the object’s `feedInboxes` relation. */
  feedInboxes: InputMaybe<ContactToManyFeedInboxFilter>;
  /** Some related `feedInboxes` exist. */
  feedInboxesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedsByCreatedByContactId` relation. */
  feedsByCreatedByContactId: InputMaybe<ContactToManyFeedFilter>;
  /** Some related `feedsByCreatedByContactId` exist. */
  feedsByCreatedByContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filesByCreatedByContactId` relation. */
  filesByCreatedByContactId: InputMaybe<ContactToManyFileFilter>;
  /** Some related `filesByCreatedByContactId` exist. */
  filesByCreatedByContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `importRows` relation. */
  importRows: InputMaybe<ContactToManyImportRowFilter>;
  /** Some related `importRows` exist. */
  importRowsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `invitedCount` field. */
  invitedCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastInvitedAt` field. */
  lastInvitedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ContactFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ContactFilter>>;
  /** Filter by the object’s `phones` field. */
  phones: InputMaybe<StringListFilter>;
  /** Filter by the object’s `portalStatus` field. */
  portalStatus: InputMaybe<PortalStatusFilter>;
  /** Filter by the object’s `projectContacts` relation. */
  projectContacts: InputMaybe<ContactToManyProjectContactFilter>;
  /** Some related `projectContacts` exist. */
  projectContactsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByCreatedByContactId` relation. */
  projectsByCreatedByContactId: InputMaybe<ContactToManyProjectFilter>;
  /** Some related `projectsByCreatedByContactId` exist. */
  projectsByCreatedByContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByReferrerContactId` relation. */
  projectsByReferrerContactId: InputMaybe<ContactToManyProjectFilter>;
  /** Some related `projectsByReferrerContactId` exist. */
  projectsByReferrerContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `referralCode` field. */
  referralCode: InputMaybe<StringFilter>;
  /** Filter by the object’s `referrerContact` relation. */
  referrerContact: InputMaybe<ContactFilter>;
  /** A related `referrerContact` exists. */
  referrerContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `searchString` field. */
  searchString: InputMaybe<StringFilter>;
  /** Filter by the object’s `smsActivitiesByFromContactId` relation. */
  smsActivitiesByFromContactId: InputMaybe<ContactToManySmsActivityFilter>;
  /** Some related `smsActivitiesByFromContactId` exist. */
  smsActivitiesByFromContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByToContactId` relation. */
  smsActivitiesByToContactId: InputMaybe<ContactToManySmsActivityFilter>;
  /** Some related `smsActivitiesByToContactId` exist. */
  smsActivitiesByToContactIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `source` field. */
  source: InputMaybe<CreatedSourceFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type ContactFormsByFileCreatedByContactIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactFormsByFileCreatedByContactIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type ContactFormsByFileCreatedByContactIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type ContactFormsByFileCreatedByContactIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ContactFormsByFileCreatedByContactIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ContactFormsByFileCreatedByContactIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Grouping methods for `Contact` for usage during aggregation. */
export enum ContactGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Emails = 'EMAILS',
  InvitedCount = 'INVITED_COUNT',
  LastInvitedAt = 'LAST_INVITED_AT',
  LastInvitedAtTruncatedToDay = 'LAST_INVITED_AT_TRUNCATED_TO_DAY',
  LastInvitedAtTruncatedToHour = 'LAST_INVITED_AT_TRUNCATED_TO_HOUR',
  LastInvitedAtTruncatedToMonth = 'LAST_INVITED_AT_TRUNCATED_TO_MONTH',
  LastInvitedAtTruncatedToQuarter = 'LAST_INVITED_AT_TRUNCATED_TO_QUARTER',
  LastInvitedAtTruncatedToWeek = 'LAST_INVITED_AT_TRUNCATED_TO_WEEK',
  LastInvitedAtTruncatedToYear = 'LAST_INVITED_AT_TRUNCATED_TO_YEAR',
  Name = 'NAME',
  Phones = 'PHONES',
  PortalStatus = 'PORTAL_STATUS',
  ReferrerContactId = 'REFERRER_CONTACT_ID',
  SearchString = 'SEARCH_STRING',
  Source = 'SOURCE',
  Status = 'STATUS',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ContactHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Contact` aggregates. */
export type ContactHavingInput = {
  AND: InputMaybe<Array<ContactHavingInput>>;
  OR: InputMaybe<Array<ContactHavingInput>>;
  arrayAgg: InputMaybe<ContactHavingArrayAggInput>;
  average: InputMaybe<ContactHavingAverageInput>;
  distinctCount: InputMaybe<ContactHavingDistinctCountInput>;
  max: InputMaybe<ContactHavingMaxInput>;
  min: InputMaybe<ContactHavingMinInput>;
  stddevPopulation: InputMaybe<ContactHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ContactHavingStddevSampleInput>;
  sum: InputMaybe<ContactHavingSumInput>;
  variancePopulation: InputMaybe<ContactHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ContactHavingVarianceSampleInput>;
};

export type ContactHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  invitedCount: InputMaybe<HavingIntFilter>;
  lastInvitedAt: InputMaybe<HavingDatetimeFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectCreatedByContactIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ContactJurisdictionsByProjectReferrerContactIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ContactMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  invitedCount: InputMaybe<IntFilter>;
  lastInvitedAt: InputMaybe<DatetimeFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ContactMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of lastInvitedAt across the matching connection */
  lastInvitedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ContactMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesLastInvitedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

export type ContactMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  invitedCount: InputMaybe<IntFilter>;
  lastInvitedAt: InputMaybe<DatetimeFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ContactMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of lastInvitedAt across the matching connection */
  lastInvitedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ContactMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesLastInvitedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ContactPrivilegedTasksByFeedCreatedByContactIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type ContactProjectsByFeedCreatedByContactIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ContactProjectsByFileCreatedByContactIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectCreatedByContactIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ContactProjectsByProjectReferrerContactIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ContactRemindersByFeedCreatedByContactIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ContactStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  invitedCount: InputMaybe<BigFloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
};

export type ContactStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type ContactStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevPopulationAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevPopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

export type ContactStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  invitedCount: InputMaybe<BigFloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
};

export type ContactStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type ContactStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevSampleAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactStddevSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

export type ContactSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  invitedCount: InputMaybe<BigIntFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
};

export type ContactSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of invitedCount across the matching connection */
  invitedCount: Scalars['BigInt']['output'];
  /** Sum of referrerContactId across the matching connection */
  referrerContactId: Scalars['BigInt']['output'];
};


export type ContactSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactSumAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactSumAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type ContactSystemsByFeedCreatedByContactIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `ActionAssignee` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyActionAssigneeFilter = {
  /** Aggregates across related `ActionAssignee` match the filter criteria. */
  aggregates: InputMaybe<ActionAssigneeAggregatesFilter>;
  /** Every related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionAssigneeFilter>;
  /** No related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionAssigneeFilter>;
  /** Some related `ActionAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionAssigneeFilter>;
};

/** A filter to be used against many `Call` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyCallFilter = {
  /** Aggregates across related `Call` match the filter criteria. */
  aggregates: InputMaybe<CallAggregatesFilter>;
  /** Every related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CallFilter>;
  /** No related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CallFilter>;
  /** Some related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CallFilter>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `Consumer` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyConsumerFilter = {
  /** Aggregates across related `Consumer` match the filter criteria. */
  aggregates: InputMaybe<ConsumerAggregatesFilter>;
  /** Every related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ConsumerFilter>;
  /** No related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ConsumerFilter>;
  /** Some related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ConsumerFilter>;
};

/** A filter to be used against many `Contact` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyContactFilter = {
  /** Aggregates across related `Contact` match the filter criteria. */
  aggregates: InputMaybe<ContactAggregatesFilter>;
  /** Every related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ContactFilter>;
  /** No related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ContactFilter>;
  /** Some related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ContactFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `FeedInbox` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyFeedInboxFilter = {
  /** Aggregates across related `FeedInbox` match the filter criteria. */
  aggregates: InputMaybe<FeedInboxAggregatesFilter>;
  /** Every related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedInboxFilter>;
  /** No related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedInboxFilter>;
  /** Some related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedInboxFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `ImportRow` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyImportRowFilter = {
  /** Aggregates across related `ImportRow` match the filter criteria. */
  aggregates: InputMaybe<ImportRowAggregatesFilter>;
  /** Every related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ImportRowFilter>;
  /** No related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ImportRowFilter>;
  /** Some related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ImportRowFilter>;
};

/** A filter to be used against many `ProjectContact` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyProjectContactFilter = {
  /** Aggregates across related `ProjectContact` match the filter criteria. */
  aggregates: InputMaybe<ProjectContactAggregatesFilter>;
  /** Every related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectContactFilter>;
  /** No related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectContactFilter>;
  /** Some related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectContactFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type ContactToManySmsActivityFilter = {
  /** Aggregates across related `SmsActivity` match the filter criteria. */
  aggregates: InputMaybe<SmsActivityAggregatesFilter>;
  /** Every related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SmsActivityFilter>;
  /** No related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SmsActivityFilter>;
  /** Some related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SmsActivityFilter>;
};

/** A connection to a list of `User` values, with data from `Consumer`. */
export type ContactUsersByConsumerContactIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByConsumerContactIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Consumer`. */
export type ContactUsersByConsumerContactIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Consumer`. */
export type ContactUsersByConsumerContactIdAndUserIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByUserIdConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Consumer`. */
export type ContactUsersByConsumerContactIdAndUserIdManyToManyEdgeConsumersByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type ContactUsersByFeedCreatedByContactIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ContactUsersByFileCreatedByContactIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectCreatedByContactIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ContactUsersByProjectReferrerContactIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ContactVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  invitedCount: InputMaybe<BigFloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
};

export type ContactVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type ContactVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVariancePopulationAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVariancePopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

export type ContactVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  invitedCount: InputMaybe<BigFloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
};

export type ContactVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of invitedCount across the matching connection */
  invitedCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
};


export type ContactVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVarianceSampleAggregatesInvitedCountArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ContactVarianceSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};

/** A connection to a list of `Contact` values. */
export type ContactsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact` and cursor to aid in pagination. */
  edges: Array<ContactsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values. */
export type ContactsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection. */
export type ContactsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};

/** Methods to use when ordering `Contact`. */
export enum ContactsOrderBy {
  ActionAssigneesConnectionArrayAggActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  ActionAssigneesConnectionArrayAggActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  ActionAssigneesConnectionArrayAggContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ActionAssigneesConnectionArrayAggContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ActionAssigneesConnectionAverageActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  ActionAssigneesConnectionAverageActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  ActionAssigneesConnectionAverageContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ActionAssigneesConnectionAverageContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ActionAssigneesConnectionCountAsc = 'ACTION_ASSIGNEES_CONNECTION_COUNT_ASC',
  ActionAssigneesConnectionCountDesc = 'ACTION_ASSIGNEES_CONNECTION_COUNT_DESC',
  ActionAssigneesConnectionDistinctCountActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  ActionAssigneesConnectionDistinctCountActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  ActionAssigneesConnectionDistinctCountContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ActionAssigneesConnectionDistinctCountContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ActionAssigneesConnectionMaxActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MAX_ACTION_ID_ASC',
  ActionAssigneesConnectionMaxActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MAX_ACTION_ID_DESC',
  ActionAssigneesConnectionMaxContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MAX_CONTACT_ID_ASC',
  ActionAssigneesConnectionMaxContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MAX_CONTACT_ID_DESC',
  ActionAssigneesConnectionMinActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MIN_ACTION_ID_ASC',
  ActionAssigneesConnectionMinActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MIN_ACTION_ID_DESC',
  ActionAssigneesConnectionMinContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_MIN_CONTACT_ID_ASC',
  ActionAssigneesConnectionMinContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_MIN_CONTACT_ID_DESC',
  ActionAssigneesConnectionStddevPopulationActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  ActionAssigneesConnectionStddevPopulationActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  ActionAssigneesConnectionStddevPopulationContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ActionAssigneesConnectionStddevPopulationContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ActionAssigneesConnectionStddevSampleActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  ActionAssigneesConnectionStddevSampleActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  ActionAssigneesConnectionStddevSampleContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ActionAssigneesConnectionStddevSampleContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ActionAssigneesConnectionSumActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_SUM_ACTION_ID_ASC',
  ActionAssigneesConnectionSumActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_SUM_ACTION_ID_DESC',
  ActionAssigneesConnectionSumContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_SUM_CONTACT_ID_ASC',
  ActionAssigneesConnectionSumContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_SUM_CONTACT_ID_DESC',
  ActionAssigneesConnectionVariancePopulationActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  ActionAssigneesConnectionVariancePopulationActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  ActionAssigneesConnectionVariancePopulationContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ActionAssigneesConnectionVariancePopulationContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ActionAssigneesConnectionVarianceSampleActionIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  ActionAssigneesConnectionVarianceSampleActionIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  ActionAssigneesConnectionVarianceSampleContactIdAsc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ActionAssigneesConnectionVarianceSampleContactIdDesc = 'ACTION_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionArrayAggCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionArrayAggCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionArrayAggCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CallsByFromContactIdConnectionArrayAggCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CallsByFromContactIdConnectionArrayAggCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CallsByFromContactIdConnectionArrayAggCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CallsByFromContactIdConnectionArrayAggDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_DISPOSITION_ASC',
  CallsByFromContactIdConnectionArrayAggDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_DISPOSITION_DESC',
  CallsByFromContactIdConnectionArrayAggDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionArrayAggDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionArrayAggEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_END_TIME_ASC',
  CallsByFromContactIdConnectionArrayAggEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_END_TIME_DESC',
  CallsByFromContactIdConnectionArrayAggFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionArrayAggFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionArrayAggFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionArrayAggFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionArrayAggIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CallsByFromContactIdConnectionArrayAggIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CallsByFromContactIdConnectionArrayAggIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionArrayAggIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionArrayAggMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionArrayAggMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionArrayAggNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_NOTE_ASC',
  CallsByFromContactIdConnectionArrayAggNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_NOTE_DESC',
  CallsByFromContactIdConnectionArrayAggOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_OUTCOME_ASC',
  CallsByFromContactIdConnectionArrayAggOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_OUTCOME_DESC',
  CallsByFromContactIdConnectionArrayAggProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionArrayAggProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionArrayAggReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_REASON_ASC',
  CallsByFromContactIdConnectionArrayAggReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_REASON_DESC',
  CallsByFromContactIdConnectionArrayAggRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionArrayAggRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionArrayAggStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_START_TIME_ASC',
  CallsByFromContactIdConnectionArrayAggStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_START_TIME_DESC',
  CallsByFromContactIdConnectionArrayAggToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionArrayAggToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionArrayAggToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionArrayAggToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionArrayAggTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionArrayAggTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionArrayAggUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionArrayAggUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionAverageCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionAverageCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionAverageCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CallsByFromContactIdConnectionAverageCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CallsByFromContactIdConnectionAverageCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CallsByFromContactIdConnectionAverageCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CallsByFromContactIdConnectionAverageDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_DISPOSITION_ASC',
  CallsByFromContactIdConnectionAverageDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_DISPOSITION_DESC',
  CallsByFromContactIdConnectionAverageDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionAverageDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionAverageEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_END_TIME_ASC',
  CallsByFromContactIdConnectionAverageEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_END_TIME_DESC',
  CallsByFromContactIdConnectionAverageFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionAverageFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionAverageFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionAverageFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionAverageIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  CallsByFromContactIdConnectionAverageIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  CallsByFromContactIdConnectionAverageIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionAverageIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionAverageMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionAverageMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionAverageNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_NOTE_ASC',
  CallsByFromContactIdConnectionAverageNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_NOTE_DESC',
  CallsByFromContactIdConnectionAverageOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_OUTCOME_ASC',
  CallsByFromContactIdConnectionAverageOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_OUTCOME_DESC',
  CallsByFromContactIdConnectionAverageProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionAverageProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionAverageReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_REASON_ASC',
  CallsByFromContactIdConnectionAverageReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_REASON_DESC',
  CallsByFromContactIdConnectionAverageRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionAverageRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionAverageStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_START_TIME_ASC',
  CallsByFromContactIdConnectionAverageStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_START_TIME_DESC',
  CallsByFromContactIdConnectionAverageToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionAverageToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionAverageToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionAverageToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionAverageTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionAverageTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionAverageUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionAverageUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_COUNT_ASC',
  CallsByFromContactIdConnectionCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_COUNT_DESC',
  CallsByFromContactIdConnectionDistinctCountCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionDistinctCountCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionDistinctCountCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CallsByFromContactIdConnectionDistinctCountCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CallsByFromContactIdConnectionDistinctCountCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CallsByFromContactIdConnectionDistinctCountCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CallsByFromContactIdConnectionDistinctCountDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DISPOSITION_ASC',
  CallsByFromContactIdConnectionDistinctCountDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DISPOSITION_DESC',
  CallsByFromContactIdConnectionDistinctCountDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionDistinctCountDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionDistinctCountEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_END_TIME_ASC',
  CallsByFromContactIdConnectionDistinctCountEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_END_TIME_DESC',
  CallsByFromContactIdConnectionDistinctCountFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionDistinctCountFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionDistinctCountFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionDistinctCountFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionDistinctCountIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CallsByFromContactIdConnectionDistinctCountIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CallsByFromContactIdConnectionDistinctCountIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionDistinctCountIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionDistinctCountMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionDistinctCountMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionDistinctCountNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NOTE_ASC',
  CallsByFromContactIdConnectionDistinctCountNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NOTE_DESC',
  CallsByFromContactIdConnectionDistinctCountOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OUTCOME_ASC',
  CallsByFromContactIdConnectionDistinctCountOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OUTCOME_DESC',
  CallsByFromContactIdConnectionDistinctCountProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionDistinctCountProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionDistinctCountReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REASON_ASC',
  CallsByFromContactIdConnectionDistinctCountReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REASON_DESC',
  CallsByFromContactIdConnectionDistinctCountRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionDistinctCountRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionDistinctCountStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_START_TIME_ASC',
  CallsByFromContactIdConnectionDistinctCountStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_START_TIME_DESC',
  CallsByFromContactIdConnectionDistinctCountToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionDistinctCountToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionDistinctCountToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionDistinctCountToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionDistinctCountTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionDistinctCountTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionDistinctCountUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionDistinctCountUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionMaxCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionMaxCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionMaxCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CallsByFromContactIdConnectionMaxCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CallsByFromContactIdConnectionMaxCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CallsByFromContactIdConnectionMaxCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CallsByFromContactIdConnectionMaxDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_DISPOSITION_ASC',
  CallsByFromContactIdConnectionMaxDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_DISPOSITION_DESC',
  CallsByFromContactIdConnectionMaxDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionMaxDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionMaxEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_END_TIME_ASC',
  CallsByFromContactIdConnectionMaxEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_END_TIME_DESC',
  CallsByFromContactIdConnectionMaxFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionMaxFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionMaxFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionMaxFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionMaxIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  CallsByFromContactIdConnectionMaxIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  CallsByFromContactIdConnectionMaxIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionMaxIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionMaxMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionMaxMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionMaxNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_NOTE_ASC',
  CallsByFromContactIdConnectionMaxNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_NOTE_DESC',
  CallsByFromContactIdConnectionMaxOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_OUTCOME_ASC',
  CallsByFromContactIdConnectionMaxOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_OUTCOME_DESC',
  CallsByFromContactIdConnectionMaxProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionMaxProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionMaxReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_REASON_ASC',
  CallsByFromContactIdConnectionMaxReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_REASON_DESC',
  CallsByFromContactIdConnectionMaxRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionMaxRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionMaxStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_START_TIME_ASC',
  CallsByFromContactIdConnectionMaxStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_START_TIME_DESC',
  CallsByFromContactIdConnectionMaxToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionMaxToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionMaxToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionMaxToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionMaxTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionMaxTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionMaxUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionMaxUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionMinCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionMinCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionMinCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CallsByFromContactIdConnectionMinCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CallsByFromContactIdConnectionMinCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CallsByFromContactIdConnectionMinCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CallsByFromContactIdConnectionMinDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_DISPOSITION_ASC',
  CallsByFromContactIdConnectionMinDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_DISPOSITION_DESC',
  CallsByFromContactIdConnectionMinDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionMinDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionMinEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_END_TIME_ASC',
  CallsByFromContactIdConnectionMinEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_END_TIME_DESC',
  CallsByFromContactIdConnectionMinFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionMinFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionMinFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionMinFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionMinIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  CallsByFromContactIdConnectionMinIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  CallsByFromContactIdConnectionMinIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionMinIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionMinMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionMinMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionMinNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_NOTE_ASC',
  CallsByFromContactIdConnectionMinNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_NOTE_DESC',
  CallsByFromContactIdConnectionMinOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_OUTCOME_ASC',
  CallsByFromContactIdConnectionMinOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_OUTCOME_DESC',
  CallsByFromContactIdConnectionMinProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionMinProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionMinReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_REASON_ASC',
  CallsByFromContactIdConnectionMinReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_REASON_DESC',
  CallsByFromContactIdConnectionMinRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionMinRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionMinStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_START_TIME_ASC',
  CallsByFromContactIdConnectionMinStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_START_TIME_DESC',
  CallsByFromContactIdConnectionMinToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionMinToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionMinToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionMinToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionMinTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionMinTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionMinUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionMinUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionStddevPopulationCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionStddevPopulationCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionStddevPopulationCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CallsByFromContactIdConnectionStddevPopulationCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CallsByFromContactIdConnectionStddevPopulationCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CallsByFromContactIdConnectionStddevPopulationCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CallsByFromContactIdConnectionStddevPopulationDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DISPOSITION_ASC',
  CallsByFromContactIdConnectionStddevPopulationDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DISPOSITION_DESC',
  CallsByFromContactIdConnectionStddevPopulationDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionStddevPopulationDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionStddevPopulationEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_END_TIME_ASC',
  CallsByFromContactIdConnectionStddevPopulationEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_END_TIME_DESC',
  CallsByFromContactIdConnectionStddevPopulationFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionStddevPopulationFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionStddevPopulationFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionStddevPopulationFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionStddevPopulationIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CallsByFromContactIdConnectionStddevPopulationIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CallsByFromContactIdConnectionStddevPopulationIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionStddevPopulationIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionStddevPopulationMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionStddevPopulationMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionStddevPopulationNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NOTE_ASC',
  CallsByFromContactIdConnectionStddevPopulationNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NOTE_DESC',
  CallsByFromContactIdConnectionStddevPopulationOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OUTCOME_ASC',
  CallsByFromContactIdConnectionStddevPopulationOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OUTCOME_DESC',
  CallsByFromContactIdConnectionStddevPopulationProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionStddevPopulationProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionStddevPopulationReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REASON_ASC',
  CallsByFromContactIdConnectionStddevPopulationReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REASON_DESC',
  CallsByFromContactIdConnectionStddevPopulationRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionStddevPopulationRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionStddevPopulationStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_START_TIME_ASC',
  CallsByFromContactIdConnectionStddevPopulationStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_START_TIME_DESC',
  CallsByFromContactIdConnectionStddevPopulationToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionStddevPopulationToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionStddevPopulationToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionStddevPopulationToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionStddevPopulationTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionStddevPopulationTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionStddevPopulationUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionStddevPopulationUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionStddevSampleCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionStddevSampleCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionStddevSampleCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CallsByFromContactIdConnectionStddevSampleCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CallsByFromContactIdConnectionStddevSampleCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CallsByFromContactIdConnectionStddevSampleCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CallsByFromContactIdConnectionStddevSampleDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DISPOSITION_ASC',
  CallsByFromContactIdConnectionStddevSampleDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DISPOSITION_DESC',
  CallsByFromContactIdConnectionStddevSampleDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionStddevSampleDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionStddevSampleEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_END_TIME_ASC',
  CallsByFromContactIdConnectionStddevSampleEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_END_TIME_DESC',
  CallsByFromContactIdConnectionStddevSampleFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionStddevSampleFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionStddevSampleFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionStddevSampleFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionStddevSampleIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CallsByFromContactIdConnectionStddevSampleIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CallsByFromContactIdConnectionStddevSampleIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionStddevSampleIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionStddevSampleMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionStddevSampleMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionStddevSampleNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NOTE_ASC',
  CallsByFromContactIdConnectionStddevSampleNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NOTE_DESC',
  CallsByFromContactIdConnectionStddevSampleOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OUTCOME_ASC',
  CallsByFromContactIdConnectionStddevSampleOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OUTCOME_DESC',
  CallsByFromContactIdConnectionStddevSampleProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionStddevSampleProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionStddevSampleReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REASON_ASC',
  CallsByFromContactIdConnectionStddevSampleReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REASON_DESC',
  CallsByFromContactIdConnectionStddevSampleRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionStddevSampleRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionStddevSampleStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_START_TIME_ASC',
  CallsByFromContactIdConnectionStddevSampleStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_START_TIME_DESC',
  CallsByFromContactIdConnectionStddevSampleToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionStddevSampleToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionStddevSampleToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionStddevSampleToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionStddevSampleTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionStddevSampleTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionStddevSampleUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionStddevSampleUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionSumCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionSumCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionSumCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CallsByFromContactIdConnectionSumCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CallsByFromContactIdConnectionSumCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CallsByFromContactIdConnectionSumCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CallsByFromContactIdConnectionSumDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_DISPOSITION_ASC',
  CallsByFromContactIdConnectionSumDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_DISPOSITION_DESC',
  CallsByFromContactIdConnectionSumDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionSumDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionSumEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_END_TIME_ASC',
  CallsByFromContactIdConnectionSumEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_END_TIME_DESC',
  CallsByFromContactIdConnectionSumFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionSumFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionSumFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionSumFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionSumIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  CallsByFromContactIdConnectionSumIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  CallsByFromContactIdConnectionSumIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionSumIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionSumMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionSumMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionSumNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_NOTE_ASC',
  CallsByFromContactIdConnectionSumNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_NOTE_DESC',
  CallsByFromContactIdConnectionSumOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_OUTCOME_ASC',
  CallsByFromContactIdConnectionSumOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_OUTCOME_DESC',
  CallsByFromContactIdConnectionSumProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionSumProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionSumReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_REASON_ASC',
  CallsByFromContactIdConnectionSumReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_REASON_DESC',
  CallsByFromContactIdConnectionSumRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionSumRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionSumStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_START_TIME_ASC',
  CallsByFromContactIdConnectionSumStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_START_TIME_DESC',
  CallsByFromContactIdConnectionSumToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionSumToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionSumToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionSumToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionSumTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionSumTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionSumUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionSumUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionVariancePopulationCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionVariancePopulationCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionVariancePopulationCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CallsByFromContactIdConnectionVariancePopulationCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CallsByFromContactIdConnectionVariancePopulationCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CallsByFromContactIdConnectionVariancePopulationCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CallsByFromContactIdConnectionVariancePopulationDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DISPOSITION_ASC',
  CallsByFromContactIdConnectionVariancePopulationDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DISPOSITION_DESC',
  CallsByFromContactIdConnectionVariancePopulationDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionVariancePopulationDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionVariancePopulationEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_END_TIME_ASC',
  CallsByFromContactIdConnectionVariancePopulationEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_END_TIME_DESC',
  CallsByFromContactIdConnectionVariancePopulationFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionVariancePopulationFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionVariancePopulationFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionVariancePopulationFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionVariancePopulationIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CallsByFromContactIdConnectionVariancePopulationIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CallsByFromContactIdConnectionVariancePopulationIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionVariancePopulationIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionVariancePopulationMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionVariancePopulationMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionVariancePopulationNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NOTE_ASC',
  CallsByFromContactIdConnectionVariancePopulationNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NOTE_DESC',
  CallsByFromContactIdConnectionVariancePopulationOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OUTCOME_ASC',
  CallsByFromContactIdConnectionVariancePopulationOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OUTCOME_DESC',
  CallsByFromContactIdConnectionVariancePopulationProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionVariancePopulationProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionVariancePopulationReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REASON_ASC',
  CallsByFromContactIdConnectionVariancePopulationReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REASON_DESC',
  CallsByFromContactIdConnectionVariancePopulationRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionVariancePopulationRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionVariancePopulationStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_START_TIME_ASC',
  CallsByFromContactIdConnectionVariancePopulationStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_START_TIME_DESC',
  CallsByFromContactIdConnectionVariancePopulationToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionVariancePopulationToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionVariancePopulationToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionVariancePopulationToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionVariancePopulationTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionVariancePopulationTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionVariancePopulationUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionVariancePopulationUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CallsByFromContactIdConnectionVarianceSampleCompanyIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CallsByFromContactIdConnectionVarianceSampleCompanyIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CallsByFromContactIdConnectionVarianceSampleCreatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CallsByFromContactIdConnectionVarianceSampleCreatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CallsByFromContactIdConnectionVarianceSampleCreatedByAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CallsByFromContactIdConnectionVarianceSampleCreatedByDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CallsByFromContactIdConnectionVarianceSampleDispositionAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_ASC',
  CallsByFromContactIdConnectionVarianceSampleDispositionDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_DESC',
  CallsByFromContactIdConnectionVarianceSampleDurationInMsAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_ASC',
  CallsByFromContactIdConnectionVarianceSampleDurationInMsDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_DESC',
  CallsByFromContactIdConnectionVarianceSampleEndTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_END_TIME_ASC',
  CallsByFromContactIdConnectionVarianceSampleEndTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_END_TIME_DESC',
  CallsByFromContactIdConnectionVarianceSampleFromContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionVarianceSampleFromContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionVarianceSampleFromNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_ASC',
  CallsByFromContactIdConnectionVarianceSampleFromNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_DESC',
  CallsByFromContactIdConnectionVarianceSampleIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CallsByFromContactIdConnectionVarianceSampleIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CallsByFromContactIdConnectionVarianceSampleIsInboundAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_ASC',
  CallsByFromContactIdConnectionVarianceSampleIsInboundDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_DESC',
  CallsByFromContactIdConnectionVarianceSampleMissedCountAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_ASC',
  CallsByFromContactIdConnectionVarianceSampleMissedCountDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_DESC',
  CallsByFromContactIdConnectionVarianceSampleNoteAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NOTE_ASC',
  CallsByFromContactIdConnectionVarianceSampleNoteDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NOTE_DESC',
  CallsByFromContactIdConnectionVarianceSampleOutcomeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OUTCOME_ASC',
  CallsByFromContactIdConnectionVarianceSampleOutcomeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OUTCOME_DESC',
  CallsByFromContactIdConnectionVarianceSampleProjectIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CallsByFromContactIdConnectionVarianceSampleProjectIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CallsByFromContactIdConnectionVarianceSampleReasonAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REASON_ASC',
  CallsByFromContactIdConnectionVarianceSampleReasonDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REASON_DESC',
  CallsByFromContactIdConnectionVarianceSampleRecordingUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_ASC',
  CallsByFromContactIdConnectionVarianceSampleRecordingUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_DESC',
  CallsByFromContactIdConnectionVarianceSampleStartTimeAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_START_TIME_ASC',
  CallsByFromContactIdConnectionVarianceSampleStartTimeDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_START_TIME_DESC',
  CallsByFromContactIdConnectionVarianceSampleToContactIdAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByFromContactIdConnectionVarianceSampleToContactIdDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByFromContactIdConnectionVarianceSampleToNumberAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_ASC',
  CallsByFromContactIdConnectionVarianceSampleToNumberDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_DESC',
  CallsByFromContactIdConnectionVarianceSampleTranscriptUrlAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByFromContactIdConnectionVarianceSampleTranscriptUrlDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByFromContactIdConnectionVarianceSampleUpdatedAtAsc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CallsByFromContactIdConnectionVarianceSampleUpdatedAtDesc = 'CALLS_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CallsByToContactIdConnectionArrayAggCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CallsByToContactIdConnectionArrayAggCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CallsByToContactIdConnectionArrayAggCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CallsByToContactIdConnectionArrayAggCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CallsByToContactIdConnectionArrayAggCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CallsByToContactIdConnectionArrayAggCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CallsByToContactIdConnectionArrayAggDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_DISPOSITION_ASC',
  CallsByToContactIdConnectionArrayAggDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_DISPOSITION_DESC',
  CallsByToContactIdConnectionArrayAggDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionArrayAggDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionArrayAggEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_END_TIME_ASC',
  CallsByToContactIdConnectionArrayAggEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_END_TIME_DESC',
  CallsByToContactIdConnectionArrayAggFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionArrayAggFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionArrayAggFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionArrayAggFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionArrayAggIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CallsByToContactIdConnectionArrayAggIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CallsByToContactIdConnectionArrayAggIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INBOUND_ASC',
  CallsByToContactIdConnectionArrayAggIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INBOUND_DESC',
  CallsByToContactIdConnectionArrayAggMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionArrayAggMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionArrayAggNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_NOTE_ASC',
  CallsByToContactIdConnectionArrayAggNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_NOTE_DESC',
  CallsByToContactIdConnectionArrayAggOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_OUTCOME_ASC',
  CallsByToContactIdConnectionArrayAggOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_OUTCOME_DESC',
  CallsByToContactIdConnectionArrayAggProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CallsByToContactIdConnectionArrayAggProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CallsByToContactIdConnectionArrayAggReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_REASON_ASC',
  CallsByToContactIdConnectionArrayAggReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_REASON_DESC',
  CallsByToContactIdConnectionArrayAggRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_RECORDING_URL_ASC',
  CallsByToContactIdConnectionArrayAggRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_RECORDING_URL_DESC',
  CallsByToContactIdConnectionArrayAggStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_START_TIME_ASC',
  CallsByToContactIdConnectionArrayAggStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_START_TIME_DESC',
  CallsByToContactIdConnectionArrayAggToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionArrayAggToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionArrayAggToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_NUMBER_ASC',
  CallsByToContactIdConnectionArrayAggToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_NUMBER_DESC',
  CallsByToContactIdConnectionArrayAggTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionArrayAggTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionArrayAggUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CallsByToContactIdConnectionArrayAggUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CallsByToContactIdConnectionAverageCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CallsByToContactIdConnectionAverageCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CallsByToContactIdConnectionAverageCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CallsByToContactIdConnectionAverageCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CallsByToContactIdConnectionAverageCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CallsByToContactIdConnectionAverageCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CallsByToContactIdConnectionAverageDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_DISPOSITION_ASC',
  CallsByToContactIdConnectionAverageDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_DISPOSITION_DESC',
  CallsByToContactIdConnectionAverageDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionAverageDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionAverageEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_END_TIME_ASC',
  CallsByToContactIdConnectionAverageEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_END_TIME_DESC',
  CallsByToContactIdConnectionAverageFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionAverageFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionAverageFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionAverageFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionAverageIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  CallsByToContactIdConnectionAverageIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  CallsByToContactIdConnectionAverageIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_IS_INBOUND_ASC',
  CallsByToContactIdConnectionAverageIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_IS_INBOUND_DESC',
  CallsByToContactIdConnectionAverageMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionAverageMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionAverageNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_NOTE_ASC',
  CallsByToContactIdConnectionAverageNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_NOTE_DESC',
  CallsByToContactIdConnectionAverageOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_OUTCOME_ASC',
  CallsByToContactIdConnectionAverageOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_OUTCOME_DESC',
  CallsByToContactIdConnectionAverageProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CallsByToContactIdConnectionAverageProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CallsByToContactIdConnectionAverageReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_REASON_ASC',
  CallsByToContactIdConnectionAverageReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_REASON_DESC',
  CallsByToContactIdConnectionAverageRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_RECORDING_URL_ASC',
  CallsByToContactIdConnectionAverageRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_RECORDING_URL_DESC',
  CallsByToContactIdConnectionAverageStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_START_TIME_ASC',
  CallsByToContactIdConnectionAverageStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_START_TIME_DESC',
  CallsByToContactIdConnectionAverageToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionAverageToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionAverageToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_NUMBER_ASC',
  CallsByToContactIdConnectionAverageToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_NUMBER_DESC',
  CallsByToContactIdConnectionAverageTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionAverageTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionAverageUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CallsByToContactIdConnectionAverageUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CallsByToContactIdConnectionCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_COUNT_ASC',
  CallsByToContactIdConnectionCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_COUNT_DESC',
  CallsByToContactIdConnectionDistinctCountCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CallsByToContactIdConnectionDistinctCountCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CallsByToContactIdConnectionDistinctCountCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CallsByToContactIdConnectionDistinctCountCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CallsByToContactIdConnectionDistinctCountCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CallsByToContactIdConnectionDistinctCountCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CallsByToContactIdConnectionDistinctCountDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DISPOSITION_ASC',
  CallsByToContactIdConnectionDistinctCountDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DISPOSITION_DESC',
  CallsByToContactIdConnectionDistinctCountDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionDistinctCountDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionDistinctCountEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_END_TIME_ASC',
  CallsByToContactIdConnectionDistinctCountEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_END_TIME_DESC',
  CallsByToContactIdConnectionDistinctCountFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionDistinctCountFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionDistinctCountFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionDistinctCountFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionDistinctCountIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CallsByToContactIdConnectionDistinctCountIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CallsByToContactIdConnectionDistinctCountIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INBOUND_ASC',
  CallsByToContactIdConnectionDistinctCountIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INBOUND_DESC',
  CallsByToContactIdConnectionDistinctCountMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionDistinctCountMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionDistinctCountNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NOTE_ASC',
  CallsByToContactIdConnectionDistinctCountNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NOTE_DESC',
  CallsByToContactIdConnectionDistinctCountOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OUTCOME_ASC',
  CallsByToContactIdConnectionDistinctCountOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OUTCOME_DESC',
  CallsByToContactIdConnectionDistinctCountProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CallsByToContactIdConnectionDistinctCountProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CallsByToContactIdConnectionDistinctCountReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REASON_ASC',
  CallsByToContactIdConnectionDistinctCountReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REASON_DESC',
  CallsByToContactIdConnectionDistinctCountRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RECORDING_URL_ASC',
  CallsByToContactIdConnectionDistinctCountRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RECORDING_URL_DESC',
  CallsByToContactIdConnectionDistinctCountStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_START_TIME_ASC',
  CallsByToContactIdConnectionDistinctCountStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_START_TIME_DESC',
  CallsByToContactIdConnectionDistinctCountToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionDistinctCountToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionDistinctCountToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_NUMBER_ASC',
  CallsByToContactIdConnectionDistinctCountToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_NUMBER_DESC',
  CallsByToContactIdConnectionDistinctCountTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionDistinctCountTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionDistinctCountUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CallsByToContactIdConnectionDistinctCountUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CallsByToContactIdConnectionMaxCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CallsByToContactIdConnectionMaxCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CallsByToContactIdConnectionMaxCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CallsByToContactIdConnectionMaxCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CallsByToContactIdConnectionMaxCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CallsByToContactIdConnectionMaxCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CallsByToContactIdConnectionMaxDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_DISPOSITION_ASC',
  CallsByToContactIdConnectionMaxDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_DISPOSITION_DESC',
  CallsByToContactIdConnectionMaxDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionMaxDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionMaxEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_END_TIME_ASC',
  CallsByToContactIdConnectionMaxEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_END_TIME_DESC',
  CallsByToContactIdConnectionMaxFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionMaxFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionMaxFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionMaxFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionMaxIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  CallsByToContactIdConnectionMaxIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  CallsByToContactIdConnectionMaxIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_IS_INBOUND_ASC',
  CallsByToContactIdConnectionMaxIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_IS_INBOUND_DESC',
  CallsByToContactIdConnectionMaxMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionMaxMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionMaxNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_NOTE_ASC',
  CallsByToContactIdConnectionMaxNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_NOTE_DESC',
  CallsByToContactIdConnectionMaxOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_OUTCOME_ASC',
  CallsByToContactIdConnectionMaxOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_OUTCOME_DESC',
  CallsByToContactIdConnectionMaxProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CallsByToContactIdConnectionMaxProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CallsByToContactIdConnectionMaxReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_REASON_ASC',
  CallsByToContactIdConnectionMaxReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_REASON_DESC',
  CallsByToContactIdConnectionMaxRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_RECORDING_URL_ASC',
  CallsByToContactIdConnectionMaxRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_RECORDING_URL_DESC',
  CallsByToContactIdConnectionMaxStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_START_TIME_ASC',
  CallsByToContactIdConnectionMaxStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_START_TIME_DESC',
  CallsByToContactIdConnectionMaxToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionMaxToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionMaxToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_NUMBER_ASC',
  CallsByToContactIdConnectionMaxToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_NUMBER_DESC',
  CallsByToContactIdConnectionMaxTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionMaxTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionMaxUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CallsByToContactIdConnectionMaxUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CallsByToContactIdConnectionMinCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CallsByToContactIdConnectionMinCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CallsByToContactIdConnectionMinCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CallsByToContactIdConnectionMinCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CallsByToContactIdConnectionMinCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CallsByToContactIdConnectionMinCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CallsByToContactIdConnectionMinDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_DISPOSITION_ASC',
  CallsByToContactIdConnectionMinDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_DISPOSITION_DESC',
  CallsByToContactIdConnectionMinDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionMinDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionMinEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_END_TIME_ASC',
  CallsByToContactIdConnectionMinEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_END_TIME_DESC',
  CallsByToContactIdConnectionMinFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionMinFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionMinFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionMinFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionMinIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  CallsByToContactIdConnectionMinIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  CallsByToContactIdConnectionMinIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_IS_INBOUND_ASC',
  CallsByToContactIdConnectionMinIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_IS_INBOUND_DESC',
  CallsByToContactIdConnectionMinMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionMinMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionMinNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_NOTE_ASC',
  CallsByToContactIdConnectionMinNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_NOTE_DESC',
  CallsByToContactIdConnectionMinOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_OUTCOME_ASC',
  CallsByToContactIdConnectionMinOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_OUTCOME_DESC',
  CallsByToContactIdConnectionMinProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CallsByToContactIdConnectionMinProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CallsByToContactIdConnectionMinReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_REASON_ASC',
  CallsByToContactIdConnectionMinReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_REASON_DESC',
  CallsByToContactIdConnectionMinRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_RECORDING_URL_ASC',
  CallsByToContactIdConnectionMinRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_RECORDING_URL_DESC',
  CallsByToContactIdConnectionMinStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_START_TIME_ASC',
  CallsByToContactIdConnectionMinStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_START_TIME_DESC',
  CallsByToContactIdConnectionMinToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionMinToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionMinToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_NUMBER_ASC',
  CallsByToContactIdConnectionMinToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_NUMBER_DESC',
  CallsByToContactIdConnectionMinTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionMinTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionMinUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CallsByToContactIdConnectionMinUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CallsByToContactIdConnectionStddevPopulationCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CallsByToContactIdConnectionStddevPopulationCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CallsByToContactIdConnectionStddevPopulationCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CallsByToContactIdConnectionStddevPopulationCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CallsByToContactIdConnectionStddevPopulationCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CallsByToContactIdConnectionStddevPopulationCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CallsByToContactIdConnectionStddevPopulationDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DISPOSITION_ASC',
  CallsByToContactIdConnectionStddevPopulationDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DISPOSITION_DESC',
  CallsByToContactIdConnectionStddevPopulationDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionStddevPopulationDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionStddevPopulationEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_END_TIME_ASC',
  CallsByToContactIdConnectionStddevPopulationEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_END_TIME_DESC',
  CallsByToContactIdConnectionStddevPopulationFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionStddevPopulationFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionStddevPopulationFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionStddevPopulationFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionStddevPopulationIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CallsByToContactIdConnectionStddevPopulationIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CallsByToContactIdConnectionStddevPopulationIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INBOUND_ASC',
  CallsByToContactIdConnectionStddevPopulationIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INBOUND_DESC',
  CallsByToContactIdConnectionStddevPopulationMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionStddevPopulationMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionStddevPopulationNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NOTE_ASC',
  CallsByToContactIdConnectionStddevPopulationNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NOTE_DESC',
  CallsByToContactIdConnectionStddevPopulationOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OUTCOME_ASC',
  CallsByToContactIdConnectionStddevPopulationOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OUTCOME_DESC',
  CallsByToContactIdConnectionStddevPopulationProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CallsByToContactIdConnectionStddevPopulationProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CallsByToContactIdConnectionStddevPopulationReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REASON_ASC',
  CallsByToContactIdConnectionStddevPopulationReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REASON_DESC',
  CallsByToContactIdConnectionStddevPopulationRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RECORDING_URL_ASC',
  CallsByToContactIdConnectionStddevPopulationRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RECORDING_URL_DESC',
  CallsByToContactIdConnectionStddevPopulationStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_START_TIME_ASC',
  CallsByToContactIdConnectionStddevPopulationStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_START_TIME_DESC',
  CallsByToContactIdConnectionStddevPopulationToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionStddevPopulationToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionStddevPopulationToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_NUMBER_ASC',
  CallsByToContactIdConnectionStddevPopulationToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_NUMBER_DESC',
  CallsByToContactIdConnectionStddevPopulationTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionStddevPopulationTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionStddevPopulationUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CallsByToContactIdConnectionStddevPopulationUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CallsByToContactIdConnectionStddevSampleCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CallsByToContactIdConnectionStddevSampleCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CallsByToContactIdConnectionStddevSampleCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CallsByToContactIdConnectionStddevSampleCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CallsByToContactIdConnectionStddevSampleCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CallsByToContactIdConnectionStddevSampleCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CallsByToContactIdConnectionStddevSampleDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DISPOSITION_ASC',
  CallsByToContactIdConnectionStddevSampleDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DISPOSITION_DESC',
  CallsByToContactIdConnectionStddevSampleDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionStddevSampleDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionStddevSampleEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_END_TIME_ASC',
  CallsByToContactIdConnectionStddevSampleEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_END_TIME_DESC',
  CallsByToContactIdConnectionStddevSampleFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionStddevSampleFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionStddevSampleFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionStddevSampleFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionStddevSampleIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CallsByToContactIdConnectionStddevSampleIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CallsByToContactIdConnectionStddevSampleIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_ASC',
  CallsByToContactIdConnectionStddevSampleIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_DESC',
  CallsByToContactIdConnectionStddevSampleMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionStddevSampleMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionStddevSampleNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NOTE_ASC',
  CallsByToContactIdConnectionStddevSampleNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NOTE_DESC',
  CallsByToContactIdConnectionStddevSampleOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OUTCOME_ASC',
  CallsByToContactIdConnectionStddevSampleOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OUTCOME_DESC',
  CallsByToContactIdConnectionStddevSampleProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CallsByToContactIdConnectionStddevSampleProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CallsByToContactIdConnectionStddevSampleReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REASON_ASC',
  CallsByToContactIdConnectionStddevSampleReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REASON_DESC',
  CallsByToContactIdConnectionStddevSampleRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_ASC',
  CallsByToContactIdConnectionStddevSampleRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_DESC',
  CallsByToContactIdConnectionStddevSampleStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_START_TIME_ASC',
  CallsByToContactIdConnectionStddevSampleStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_START_TIME_DESC',
  CallsByToContactIdConnectionStddevSampleToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionStddevSampleToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionStddevSampleToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_ASC',
  CallsByToContactIdConnectionStddevSampleToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_DESC',
  CallsByToContactIdConnectionStddevSampleTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionStddevSampleTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionStddevSampleUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CallsByToContactIdConnectionStddevSampleUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CallsByToContactIdConnectionSumCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CallsByToContactIdConnectionSumCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CallsByToContactIdConnectionSumCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CallsByToContactIdConnectionSumCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CallsByToContactIdConnectionSumCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CallsByToContactIdConnectionSumCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CallsByToContactIdConnectionSumDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_DISPOSITION_ASC',
  CallsByToContactIdConnectionSumDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_DISPOSITION_DESC',
  CallsByToContactIdConnectionSumDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionSumDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionSumEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_END_TIME_ASC',
  CallsByToContactIdConnectionSumEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_END_TIME_DESC',
  CallsByToContactIdConnectionSumFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionSumFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionSumFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionSumFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionSumIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  CallsByToContactIdConnectionSumIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  CallsByToContactIdConnectionSumIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_IS_INBOUND_ASC',
  CallsByToContactIdConnectionSumIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_IS_INBOUND_DESC',
  CallsByToContactIdConnectionSumMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionSumMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionSumNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_NOTE_ASC',
  CallsByToContactIdConnectionSumNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_NOTE_DESC',
  CallsByToContactIdConnectionSumOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_OUTCOME_ASC',
  CallsByToContactIdConnectionSumOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_OUTCOME_DESC',
  CallsByToContactIdConnectionSumProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CallsByToContactIdConnectionSumProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CallsByToContactIdConnectionSumReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_REASON_ASC',
  CallsByToContactIdConnectionSumReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_REASON_DESC',
  CallsByToContactIdConnectionSumRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_RECORDING_URL_ASC',
  CallsByToContactIdConnectionSumRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_RECORDING_URL_DESC',
  CallsByToContactIdConnectionSumStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_START_TIME_ASC',
  CallsByToContactIdConnectionSumStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_START_TIME_DESC',
  CallsByToContactIdConnectionSumToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionSumToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionSumToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_NUMBER_ASC',
  CallsByToContactIdConnectionSumToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_NUMBER_DESC',
  CallsByToContactIdConnectionSumTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionSumTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionSumUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CallsByToContactIdConnectionSumUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CallsByToContactIdConnectionVariancePopulationCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CallsByToContactIdConnectionVariancePopulationCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CallsByToContactIdConnectionVariancePopulationCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CallsByToContactIdConnectionVariancePopulationCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CallsByToContactIdConnectionVariancePopulationCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CallsByToContactIdConnectionVariancePopulationCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CallsByToContactIdConnectionVariancePopulationDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DISPOSITION_ASC',
  CallsByToContactIdConnectionVariancePopulationDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DISPOSITION_DESC',
  CallsByToContactIdConnectionVariancePopulationDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionVariancePopulationDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionVariancePopulationEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_END_TIME_ASC',
  CallsByToContactIdConnectionVariancePopulationEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_END_TIME_DESC',
  CallsByToContactIdConnectionVariancePopulationFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionVariancePopulationFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionVariancePopulationFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionVariancePopulationFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionVariancePopulationIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CallsByToContactIdConnectionVariancePopulationIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CallsByToContactIdConnectionVariancePopulationIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_ASC',
  CallsByToContactIdConnectionVariancePopulationIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_DESC',
  CallsByToContactIdConnectionVariancePopulationMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionVariancePopulationMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionVariancePopulationNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NOTE_ASC',
  CallsByToContactIdConnectionVariancePopulationNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NOTE_DESC',
  CallsByToContactIdConnectionVariancePopulationOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OUTCOME_ASC',
  CallsByToContactIdConnectionVariancePopulationOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OUTCOME_DESC',
  CallsByToContactIdConnectionVariancePopulationProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CallsByToContactIdConnectionVariancePopulationProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CallsByToContactIdConnectionVariancePopulationReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REASON_ASC',
  CallsByToContactIdConnectionVariancePopulationReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REASON_DESC',
  CallsByToContactIdConnectionVariancePopulationRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_ASC',
  CallsByToContactIdConnectionVariancePopulationRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_DESC',
  CallsByToContactIdConnectionVariancePopulationStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_START_TIME_ASC',
  CallsByToContactIdConnectionVariancePopulationStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_START_TIME_DESC',
  CallsByToContactIdConnectionVariancePopulationToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionVariancePopulationToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionVariancePopulationToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_ASC',
  CallsByToContactIdConnectionVariancePopulationToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_DESC',
  CallsByToContactIdConnectionVariancePopulationTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionVariancePopulationTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionVariancePopulationUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CallsByToContactIdConnectionVariancePopulationUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CallsByToContactIdConnectionVarianceSampleCompanyIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CallsByToContactIdConnectionVarianceSampleCompanyIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CallsByToContactIdConnectionVarianceSampleCreatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CallsByToContactIdConnectionVarianceSampleCreatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CallsByToContactIdConnectionVarianceSampleCreatedByAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CallsByToContactIdConnectionVarianceSampleCreatedByDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CallsByToContactIdConnectionVarianceSampleDispositionAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_ASC',
  CallsByToContactIdConnectionVarianceSampleDispositionDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_DESC',
  CallsByToContactIdConnectionVarianceSampleDurationInMsAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_ASC',
  CallsByToContactIdConnectionVarianceSampleDurationInMsDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_DESC',
  CallsByToContactIdConnectionVarianceSampleEndTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_END_TIME_ASC',
  CallsByToContactIdConnectionVarianceSampleEndTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_END_TIME_DESC',
  CallsByToContactIdConnectionVarianceSampleFromContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByToContactIdConnectionVarianceSampleFromContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByToContactIdConnectionVarianceSampleFromNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_ASC',
  CallsByToContactIdConnectionVarianceSampleFromNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_DESC',
  CallsByToContactIdConnectionVarianceSampleIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CallsByToContactIdConnectionVarianceSampleIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CallsByToContactIdConnectionVarianceSampleIsInboundAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_ASC',
  CallsByToContactIdConnectionVarianceSampleIsInboundDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_DESC',
  CallsByToContactIdConnectionVarianceSampleMissedCountAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_ASC',
  CallsByToContactIdConnectionVarianceSampleMissedCountDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_DESC',
  CallsByToContactIdConnectionVarianceSampleNoteAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NOTE_ASC',
  CallsByToContactIdConnectionVarianceSampleNoteDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NOTE_DESC',
  CallsByToContactIdConnectionVarianceSampleOutcomeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OUTCOME_ASC',
  CallsByToContactIdConnectionVarianceSampleOutcomeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OUTCOME_DESC',
  CallsByToContactIdConnectionVarianceSampleProjectIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CallsByToContactIdConnectionVarianceSampleProjectIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CallsByToContactIdConnectionVarianceSampleReasonAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REASON_ASC',
  CallsByToContactIdConnectionVarianceSampleReasonDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REASON_DESC',
  CallsByToContactIdConnectionVarianceSampleRecordingUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_ASC',
  CallsByToContactIdConnectionVarianceSampleRecordingUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_DESC',
  CallsByToContactIdConnectionVarianceSampleStartTimeAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_START_TIME_ASC',
  CallsByToContactIdConnectionVarianceSampleStartTimeDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_START_TIME_DESC',
  CallsByToContactIdConnectionVarianceSampleToContactIdAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByToContactIdConnectionVarianceSampleToContactIdDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByToContactIdConnectionVarianceSampleToNumberAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_ASC',
  CallsByToContactIdConnectionVarianceSampleToNumberDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_DESC',
  CallsByToContactIdConnectionVarianceSampleTranscriptUrlAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByToContactIdConnectionVarianceSampleTranscriptUrlDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByToContactIdConnectionVarianceSampleUpdatedAtAsc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CallsByToContactIdConnectionVarianceSampleUpdatedAtDesc = 'CALLS_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsByCreatedByContactIdConnectionArrayAggYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsByCreatedByContactIdConnectionArrayAggYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsByCreatedByContactIdConnectionAverageAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionAverageAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionAverageCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionAverageCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionAverageCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionAverageFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionAverageIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionAverageParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionAveragePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionAveragePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionAveragePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionAveragePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionAverageScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionAverageSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionAverageTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionAverageTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionAverageTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionAverageXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_X_ASC',
  CommentsByCreatedByContactIdConnectionAverageXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_X_DESC',
  CommentsByCreatedByContactIdConnectionAverageYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_Y_ASC',
  CommentsByCreatedByContactIdConnectionAverageYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_Y_DESC',
  CommentsByCreatedByContactIdConnectionCountAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_ASC',
  CommentsByCreatedByContactIdConnectionCountDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsByCreatedByContactIdConnectionDistinctCountYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsByCreatedByContactIdConnectionDistinctCountYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsByCreatedByContactIdConnectionMaxAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionMaxAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionMaxCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionMaxCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMaxCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionMaxFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionMaxIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionMaxParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMaxPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMaxPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionMaxScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionMaxSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionMaxTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionMaxTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionMaxTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMaxXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_X_ASC',
  CommentsByCreatedByContactIdConnectionMaxXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_X_DESC',
  CommentsByCreatedByContactIdConnectionMaxYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_Y_ASC',
  CommentsByCreatedByContactIdConnectionMaxYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_Y_DESC',
  CommentsByCreatedByContactIdConnectionMinAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionMinAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionMinAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionMinCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionMinCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMinCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMinCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionMinFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionMinIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionMinParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMinPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMinPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionMinScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionMinSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionMinTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionMinTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionMinTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionMinUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionMinUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionMinXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_X_ASC',
  CommentsByCreatedByContactIdConnectionMinXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_X_DESC',
  CommentsByCreatedByContactIdConnectionMinYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_Y_ASC',
  CommentsByCreatedByContactIdConnectionMinYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_Y_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsByCreatedByContactIdConnectionStddevPopulationYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsByCreatedByContactIdConnectionStddevPopulationYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsByCreatedByContactIdConnectionStddevSampleYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsByCreatedByContactIdConnectionStddevSampleYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsByCreatedByContactIdConnectionSumAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionSumAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionSumAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionSumCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionSumCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionSumCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionSumCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionSumFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionSumIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionSumParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionSumPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionSumPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionSumScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionSumSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionSumTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionSumTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionSumTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionSumUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionSumUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionSumXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_X_ASC',
  CommentsByCreatedByContactIdConnectionSumXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_X_DESC',
  CommentsByCreatedByContactIdConnectionSumYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_Y_ASC',
  CommentsByCreatedByContactIdConnectionSumYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_Y_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsByCreatedByContactIdConnectionVariancePopulationYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsByCreatedByContactIdConnectionVariancePopulationYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleXAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleXDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsByCreatedByContactIdConnectionVarianceSampleYAsc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsByCreatedByContactIdConnectionVarianceSampleYDesc = 'COMMENTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ConsumersConnectionArrayAggConsumerTypeAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_ASC',
  ConsumersConnectionArrayAggConsumerTypeDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_DESC',
  ConsumersConnectionArrayAggContactIdAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ConsumersConnectionArrayAggContactIdDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ConsumersConnectionArrayAggCreatedAtAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ConsumersConnectionArrayAggCreatedAtDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ConsumersConnectionArrayAggFeedIdAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  ConsumersConnectionArrayAggFeedIdDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  ConsumersConnectionArrayAggIdAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_ID_ASC',
  ConsumersConnectionArrayAggIdDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_ID_DESC',
  ConsumersConnectionArrayAggMentionedAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_MENTIONED_ASC',
  ConsumersConnectionArrayAggMentionedDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_MENTIONED_DESC',
  ConsumersConnectionArrayAggReadAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_READ_ASC',
  ConsumersConnectionArrayAggReadDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_READ_DESC',
  ConsumersConnectionArrayAggUpdatedAtAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ConsumersConnectionArrayAggUpdatedAtDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ConsumersConnectionArrayAggUserIdAsc = 'CONSUMERS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ConsumersConnectionArrayAggUserIdDesc = 'CONSUMERS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ConsumersConnectionAverageConsumerTypeAsc = 'CONSUMERS_CONNECTION_AVERAGE_CONSUMER_TYPE_ASC',
  ConsumersConnectionAverageConsumerTypeDesc = 'CONSUMERS_CONNECTION_AVERAGE_CONSUMER_TYPE_DESC',
  ConsumersConnectionAverageContactIdAsc = 'CONSUMERS_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ConsumersConnectionAverageContactIdDesc = 'CONSUMERS_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ConsumersConnectionAverageCreatedAtAsc = 'CONSUMERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ConsumersConnectionAverageCreatedAtDesc = 'CONSUMERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ConsumersConnectionAverageFeedIdAsc = 'CONSUMERS_CONNECTION_AVERAGE_FEED_ID_ASC',
  ConsumersConnectionAverageFeedIdDesc = 'CONSUMERS_CONNECTION_AVERAGE_FEED_ID_DESC',
  ConsumersConnectionAverageIdAsc = 'CONSUMERS_CONNECTION_AVERAGE_ID_ASC',
  ConsumersConnectionAverageIdDesc = 'CONSUMERS_CONNECTION_AVERAGE_ID_DESC',
  ConsumersConnectionAverageMentionedAsc = 'CONSUMERS_CONNECTION_AVERAGE_MENTIONED_ASC',
  ConsumersConnectionAverageMentionedDesc = 'CONSUMERS_CONNECTION_AVERAGE_MENTIONED_DESC',
  ConsumersConnectionAverageReadAsc = 'CONSUMERS_CONNECTION_AVERAGE_READ_ASC',
  ConsumersConnectionAverageReadDesc = 'CONSUMERS_CONNECTION_AVERAGE_READ_DESC',
  ConsumersConnectionAverageUpdatedAtAsc = 'CONSUMERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ConsumersConnectionAverageUpdatedAtDesc = 'CONSUMERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ConsumersConnectionAverageUserIdAsc = 'CONSUMERS_CONNECTION_AVERAGE_USER_ID_ASC',
  ConsumersConnectionAverageUserIdDesc = 'CONSUMERS_CONNECTION_AVERAGE_USER_ID_DESC',
  ConsumersConnectionCountAsc = 'CONSUMERS_CONNECTION_COUNT_ASC',
  ConsumersConnectionCountDesc = 'CONSUMERS_CONNECTION_COUNT_DESC',
  ConsumersConnectionDistinctCountConsumerTypeAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_ASC',
  ConsumersConnectionDistinctCountConsumerTypeDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_DESC',
  ConsumersConnectionDistinctCountContactIdAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ConsumersConnectionDistinctCountContactIdDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ConsumersConnectionDistinctCountCreatedAtAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ConsumersConnectionDistinctCountCreatedAtDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ConsumersConnectionDistinctCountFeedIdAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  ConsumersConnectionDistinctCountFeedIdDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  ConsumersConnectionDistinctCountIdAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ConsumersConnectionDistinctCountIdDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ConsumersConnectionDistinctCountMentionedAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_MENTIONED_ASC',
  ConsumersConnectionDistinctCountMentionedDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_MENTIONED_DESC',
  ConsumersConnectionDistinctCountReadAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_READ_ASC',
  ConsumersConnectionDistinctCountReadDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_READ_DESC',
  ConsumersConnectionDistinctCountUpdatedAtAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConsumersConnectionDistinctCountUpdatedAtDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConsumersConnectionDistinctCountUserIdAsc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ConsumersConnectionDistinctCountUserIdDesc = 'CONSUMERS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ConsumersConnectionMaxConsumerTypeAsc = 'CONSUMERS_CONNECTION_MAX_CONSUMER_TYPE_ASC',
  ConsumersConnectionMaxConsumerTypeDesc = 'CONSUMERS_CONNECTION_MAX_CONSUMER_TYPE_DESC',
  ConsumersConnectionMaxContactIdAsc = 'CONSUMERS_CONNECTION_MAX_CONTACT_ID_ASC',
  ConsumersConnectionMaxContactIdDesc = 'CONSUMERS_CONNECTION_MAX_CONTACT_ID_DESC',
  ConsumersConnectionMaxCreatedAtAsc = 'CONSUMERS_CONNECTION_MAX_CREATED_AT_ASC',
  ConsumersConnectionMaxCreatedAtDesc = 'CONSUMERS_CONNECTION_MAX_CREATED_AT_DESC',
  ConsumersConnectionMaxFeedIdAsc = 'CONSUMERS_CONNECTION_MAX_FEED_ID_ASC',
  ConsumersConnectionMaxFeedIdDesc = 'CONSUMERS_CONNECTION_MAX_FEED_ID_DESC',
  ConsumersConnectionMaxIdAsc = 'CONSUMERS_CONNECTION_MAX_ID_ASC',
  ConsumersConnectionMaxIdDesc = 'CONSUMERS_CONNECTION_MAX_ID_DESC',
  ConsumersConnectionMaxMentionedAsc = 'CONSUMERS_CONNECTION_MAX_MENTIONED_ASC',
  ConsumersConnectionMaxMentionedDesc = 'CONSUMERS_CONNECTION_MAX_MENTIONED_DESC',
  ConsumersConnectionMaxReadAsc = 'CONSUMERS_CONNECTION_MAX_READ_ASC',
  ConsumersConnectionMaxReadDesc = 'CONSUMERS_CONNECTION_MAX_READ_DESC',
  ConsumersConnectionMaxUpdatedAtAsc = 'CONSUMERS_CONNECTION_MAX_UPDATED_AT_ASC',
  ConsumersConnectionMaxUpdatedAtDesc = 'CONSUMERS_CONNECTION_MAX_UPDATED_AT_DESC',
  ConsumersConnectionMaxUserIdAsc = 'CONSUMERS_CONNECTION_MAX_USER_ID_ASC',
  ConsumersConnectionMaxUserIdDesc = 'CONSUMERS_CONNECTION_MAX_USER_ID_DESC',
  ConsumersConnectionMinConsumerTypeAsc = 'CONSUMERS_CONNECTION_MIN_CONSUMER_TYPE_ASC',
  ConsumersConnectionMinConsumerTypeDesc = 'CONSUMERS_CONNECTION_MIN_CONSUMER_TYPE_DESC',
  ConsumersConnectionMinContactIdAsc = 'CONSUMERS_CONNECTION_MIN_CONTACT_ID_ASC',
  ConsumersConnectionMinContactIdDesc = 'CONSUMERS_CONNECTION_MIN_CONTACT_ID_DESC',
  ConsumersConnectionMinCreatedAtAsc = 'CONSUMERS_CONNECTION_MIN_CREATED_AT_ASC',
  ConsumersConnectionMinCreatedAtDesc = 'CONSUMERS_CONNECTION_MIN_CREATED_AT_DESC',
  ConsumersConnectionMinFeedIdAsc = 'CONSUMERS_CONNECTION_MIN_FEED_ID_ASC',
  ConsumersConnectionMinFeedIdDesc = 'CONSUMERS_CONNECTION_MIN_FEED_ID_DESC',
  ConsumersConnectionMinIdAsc = 'CONSUMERS_CONNECTION_MIN_ID_ASC',
  ConsumersConnectionMinIdDesc = 'CONSUMERS_CONNECTION_MIN_ID_DESC',
  ConsumersConnectionMinMentionedAsc = 'CONSUMERS_CONNECTION_MIN_MENTIONED_ASC',
  ConsumersConnectionMinMentionedDesc = 'CONSUMERS_CONNECTION_MIN_MENTIONED_DESC',
  ConsumersConnectionMinReadAsc = 'CONSUMERS_CONNECTION_MIN_READ_ASC',
  ConsumersConnectionMinReadDesc = 'CONSUMERS_CONNECTION_MIN_READ_DESC',
  ConsumersConnectionMinUpdatedAtAsc = 'CONSUMERS_CONNECTION_MIN_UPDATED_AT_ASC',
  ConsumersConnectionMinUpdatedAtDesc = 'CONSUMERS_CONNECTION_MIN_UPDATED_AT_DESC',
  ConsumersConnectionMinUserIdAsc = 'CONSUMERS_CONNECTION_MIN_USER_ID_ASC',
  ConsumersConnectionMinUserIdDesc = 'CONSUMERS_CONNECTION_MIN_USER_ID_DESC',
  ConsumersConnectionStddevPopulationConsumerTypeAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersConnectionStddevPopulationConsumerTypeDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersConnectionStddevPopulationContactIdAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ConsumersConnectionStddevPopulationContactIdDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ConsumersConnectionStddevPopulationCreatedAtAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ConsumersConnectionStddevPopulationCreatedAtDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ConsumersConnectionStddevPopulationFeedIdAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  ConsumersConnectionStddevPopulationFeedIdDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  ConsumersConnectionStddevPopulationIdAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ConsumersConnectionStddevPopulationIdDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ConsumersConnectionStddevPopulationMentionedAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_MENTIONED_ASC',
  ConsumersConnectionStddevPopulationMentionedDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_MENTIONED_DESC',
  ConsumersConnectionStddevPopulationReadAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_READ_ASC',
  ConsumersConnectionStddevPopulationReadDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_READ_DESC',
  ConsumersConnectionStddevPopulationUpdatedAtAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConsumersConnectionStddevPopulationUpdatedAtDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConsumersConnectionStddevPopulationUserIdAsc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ConsumersConnectionStddevPopulationUserIdDesc = 'CONSUMERS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ConsumersConnectionStddevSampleConsumerTypeAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersConnectionStddevSampleConsumerTypeDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersConnectionStddevSampleContactIdAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ConsumersConnectionStddevSampleContactIdDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ConsumersConnectionStddevSampleCreatedAtAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConsumersConnectionStddevSampleCreatedAtDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConsumersConnectionStddevSampleFeedIdAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  ConsumersConnectionStddevSampleFeedIdDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  ConsumersConnectionStddevSampleIdAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ConsumersConnectionStddevSampleIdDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ConsumersConnectionStddevSampleMentionedAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_MENTIONED_ASC',
  ConsumersConnectionStddevSampleMentionedDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_MENTIONED_DESC',
  ConsumersConnectionStddevSampleReadAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  ConsumersConnectionStddevSampleReadDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  ConsumersConnectionStddevSampleUpdatedAtAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConsumersConnectionStddevSampleUpdatedAtDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConsumersConnectionStddevSampleUserIdAsc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ConsumersConnectionStddevSampleUserIdDesc = 'CONSUMERS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ConsumersConnectionSumConsumerTypeAsc = 'CONSUMERS_CONNECTION_SUM_CONSUMER_TYPE_ASC',
  ConsumersConnectionSumConsumerTypeDesc = 'CONSUMERS_CONNECTION_SUM_CONSUMER_TYPE_DESC',
  ConsumersConnectionSumContactIdAsc = 'CONSUMERS_CONNECTION_SUM_CONTACT_ID_ASC',
  ConsumersConnectionSumContactIdDesc = 'CONSUMERS_CONNECTION_SUM_CONTACT_ID_DESC',
  ConsumersConnectionSumCreatedAtAsc = 'CONSUMERS_CONNECTION_SUM_CREATED_AT_ASC',
  ConsumersConnectionSumCreatedAtDesc = 'CONSUMERS_CONNECTION_SUM_CREATED_AT_DESC',
  ConsumersConnectionSumFeedIdAsc = 'CONSUMERS_CONNECTION_SUM_FEED_ID_ASC',
  ConsumersConnectionSumFeedIdDesc = 'CONSUMERS_CONNECTION_SUM_FEED_ID_DESC',
  ConsumersConnectionSumIdAsc = 'CONSUMERS_CONNECTION_SUM_ID_ASC',
  ConsumersConnectionSumIdDesc = 'CONSUMERS_CONNECTION_SUM_ID_DESC',
  ConsumersConnectionSumMentionedAsc = 'CONSUMERS_CONNECTION_SUM_MENTIONED_ASC',
  ConsumersConnectionSumMentionedDesc = 'CONSUMERS_CONNECTION_SUM_MENTIONED_DESC',
  ConsumersConnectionSumReadAsc = 'CONSUMERS_CONNECTION_SUM_READ_ASC',
  ConsumersConnectionSumReadDesc = 'CONSUMERS_CONNECTION_SUM_READ_DESC',
  ConsumersConnectionSumUpdatedAtAsc = 'CONSUMERS_CONNECTION_SUM_UPDATED_AT_ASC',
  ConsumersConnectionSumUpdatedAtDesc = 'CONSUMERS_CONNECTION_SUM_UPDATED_AT_DESC',
  ConsumersConnectionSumUserIdAsc = 'CONSUMERS_CONNECTION_SUM_USER_ID_ASC',
  ConsumersConnectionSumUserIdDesc = 'CONSUMERS_CONNECTION_SUM_USER_ID_DESC',
  ConsumersConnectionVariancePopulationConsumerTypeAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersConnectionVariancePopulationConsumerTypeDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersConnectionVariancePopulationContactIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ConsumersConnectionVariancePopulationContactIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ConsumersConnectionVariancePopulationCreatedAtAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConsumersConnectionVariancePopulationCreatedAtDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConsumersConnectionVariancePopulationFeedIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  ConsumersConnectionVariancePopulationFeedIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  ConsumersConnectionVariancePopulationIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ConsumersConnectionVariancePopulationIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ConsumersConnectionVariancePopulationMentionedAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_MENTIONED_ASC',
  ConsumersConnectionVariancePopulationMentionedDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_MENTIONED_DESC',
  ConsumersConnectionVariancePopulationReadAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  ConsumersConnectionVariancePopulationReadDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  ConsumersConnectionVariancePopulationUpdatedAtAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConsumersConnectionVariancePopulationUpdatedAtDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConsumersConnectionVariancePopulationUserIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ConsumersConnectionVariancePopulationUserIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ConsumersConnectionVarianceSampleConsumerTypeAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersConnectionVarianceSampleConsumerTypeDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersConnectionVarianceSampleContactIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ConsumersConnectionVarianceSampleContactIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ConsumersConnectionVarianceSampleCreatedAtAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConsumersConnectionVarianceSampleCreatedAtDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConsumersConnectionVarianceSampleFeedIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  ConsumersConnectionVarianceSampleFeedIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  ConsumersConnectionVarianceSampleIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ConsumersConnectionVarianceSampleIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ConsumersConnectionVarianceSampleMentionedAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_MENTIONED_ASC',
  ConsumersConnectionVarianceSampleMentionedDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_MENTIONED_DESC',
  ConsumersConnectionVarianceSampleReadAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  ConsumersConnectionVarianceSampleReadDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  ConsumersConnectionVarianceSampleUpdatedAtAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConsumersConnectionVarianceSampleUpdatedAtDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ConsumersConnectionVarianceSampleUserIdAsc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ConsumersConnectionVarianceSampleUserIdDesc = 'CONSUMERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ContactsByReferrerContactIdConnectionArrayAggCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionArrayAggCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionArrayAggCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionArrayAggCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionArrayAggCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionArrayAggCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionArrayAggEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionArrayAggEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionArrayAggIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ContactsByReferrerContactIdConnectionArrayAggIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ContactsByReferrerContactIdConnectionArrayAggInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionArrayAggInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionArrayAggLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionArrayAggLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionArrayAggNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  ContactsByReferrerContactIdConnectionArrayAggNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  ContactsByReferrerContactIdConnectionArrayAggPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONES_ASC',
  ContactsByReferrerContactIdConnectionArrayAggPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONES_DESC',
  ContactsByReferrerContactIdConnectionArrayAggPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionArrayAggPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionArrayAggReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionArrayAggReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionArrayAggReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionArrayAggReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionArrayAggSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionArrayAggSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionArrayAggSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionArrayAggSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionArrayAggStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ContactsByReferrerContactIdConnectionArrayAggStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ContactsByReferrerContactIdConnectionArrayAggTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ContactsByReferrerContactIdConnectionArrayAggTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ContactsByReferrerContactIdConnectionArrayAggUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionArrayAggUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionAverageCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionAverageCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionAverageCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionAverageCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionAverageCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionAverageCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionAverageEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionAverageEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionAverageIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  ContactsByReferrerContactIdConnectionAverageIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  ContactsByReferrerContactIdConnectionAverageInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionAverageInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionAverageLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionAverageLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionAverageNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_NAME_ASC',
  ContactsByReferrerContactIdConnectionAverageNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_NAME_DESC',
  ContactsByReferrerContactIdConnectionAveragePhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PHONES_ASC',
  ContactsByReferrerContactIdConnectionAveragePhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PHONES_DESC',
  ContactsByReferrerContactIdConnectionAveragePortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionAveragePortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionAverageReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionAverageReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionAverageReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionAverageReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionAverageSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionAverageSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionAverageSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionAverageSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionAverageStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ContactsByReferrerContactIdConnectionAverageStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ContactsByReferrerContactIdConnectionAverageTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ContactsByReferrerContactIdConnectionAverageTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ContactsByReferrerContactIdConnectionAverageUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionAverageUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_COUNT_ASC',
  ContactsByReferrerContactIdConnectionCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_COUNT_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONES_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONES_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ContactsByReferrerContactIdConnectionDistinctCountUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionDistinctCountUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionMaxCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionMaxCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionMaxCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionMaxCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionMaxCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionMaxCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionMaxEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionMaxEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionMaxIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  ContactsByReferrerContactIdConnectionMaxIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  ContactsByReferrerContactIdConnectionMaxInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionMaxInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionMaxLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionMaxLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionMaxNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_NAME_ASC',
  ContactsByReferrerContactIdConnectionMaxNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_NAME_DESC',
  ContactsByReferrerContactIdConnectionMaxPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PHONES_ASC',
  ContactsByReferrerContactIdConnectionMaxPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PHONES_DESC',
  ContactsByReferrerContactIdConnectionMaxPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionMaxPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionMaxReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionMaxReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionMaxReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionMaxReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionMaxSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionMaxSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionMaxSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionMaxSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionMaxStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATUS_ASC',
  ContactsByReferrerContactIdConnectionMaxStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATUS_DESC',
  ContactsByReferrerContactIdConnectionMaxTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TITLE_ASC',
  ContactsByReferrerContactIdConnectionMaxTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TITLE_DESC',
  ContactsByReferrerContactIdConnectionMaxUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionMaxUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionMinCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionMinCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionMinCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionMinCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionMinCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionMinCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionMinEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionMinEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionMinIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  ContactsByReferrerContactIdConnectionMinIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  ContactsByReferrerContactIdConnectionMinInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionMinInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionMinLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionMinLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionMinNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_NAME_ASC',
  ContactsByReferrerContactIdConnectionMinNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_NAME_DESC',
  ContactsByReferrerContactIdConnectionMinPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PHONES_ASC',
  ContactsByReferrerContactIdConnectionMinPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PHONES_DESC',
  ContactsByReferrerContactIdConnectionMinPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionMinPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionMinReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionMinReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionMinReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionMinReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionMinSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionMinSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionMinSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionMinSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionMinStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATUS_ASC',
  ContactsByReferrerContactIdConnectionMinStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATUS_DESC',
  ContactsByReferrerContactIdConnectionMinTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TITLE_ASC',
  ContactsByReferrerContactIdConnectionMinTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TITLE_DESC',
  ContactsByReferrerContactIdConnectionMinUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionMinUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONES_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONES_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ContactsByReferrerContactIdConnectionStddevPopulationUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevPopulationUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ContactsByReferrerContactIdConnectionStddevSamplePhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONES_ASC',
  ContactsByReferrerContactIdConnectionStddevSamplePhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONES_DESC',
  ContactsByReferrerContactIdConnectionStddevSamplePortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionStddevSamplePortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ContactsByReferrerContactIdConnectionStddevSampleUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionStddevSampleUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionSumCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionSumCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionSumCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionSumCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionSumCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionSumCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionSumEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionSumEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionSumIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  ContactsByReferrerContactIdConnectionSumIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  ContactsByReferrerContactIdConnectionSumInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionSumInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionSumLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionSumLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionSumNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_NAME_ASC',
  ContactsByReferrerContactIdConnectionSumNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_NAME_DESC',
  ContactsByReferrerContactIdConnectionSumPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PHONES_ASC',
  ContactsByReferrerContactIdConnectionSumPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PHONES_DESC',
  ContactsByReferrerContactIdConnectionSumPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionSumPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionSumReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionSumReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionSumReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionSumReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionSumSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionSumSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionSumSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionSumSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionSumStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATUS_ASC',
  ContactsByReferrerContactIdConnectionSumStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATUS_DESC',
  ContactsByReferrerContactIdConnectionSumTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TITLE_ASC',
  ContactsByReferrerContactIdConnectionSumTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TITLE_DESC',
  ContactsByReferrerContactIdConnectionSumUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionSumUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationPhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONES_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationPhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONES_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationPortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationPortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ContactsByReferrerContactIdConnectionVariancePopulationUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionVariancePopulationUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleCompanyIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleCompanyIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleCreatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleCreatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleCreatedByAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleCreatedByDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleEmailsAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EMAILS_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleEmailsDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EMAILS_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleInvitedCountAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_INVITED_COUNT_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleInvitedCountDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_INVITED_COUNT_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleLastInvitedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INVITED_AT_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleLastInvitedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INVITED_AT_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleNameAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleNameDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ContactsByReferrerContactIdConnectionVarianceSamplePhonesAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONES_ASC',
  ContactsByReferrerContactIdConnectionVarianceSamplePhonesDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONES_DESC',
  ContactsByReferrerContactIdConnectionVarianceSamplePortalStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PORTAL_STATUS_ASC',
  ContactsByReferrerContactIdConnectionVarianceSamplePortalStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PORTAL_STATUS_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleReferralCodeAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRAL_CODE_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleReferralCodeDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRAL_CODE_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleReferrerContactIdAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleReferrerContactIdDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleSearchStringAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_STRING_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleSearchStringDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_STRING_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleSourceAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleSourceDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleStatusAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleStatusDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleTitleAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleTitleDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ContactsByReferrerContactIdConnectionVarianceSampleUpdatedAtAsc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ContactsByReferrerContactIdConnectionVarianceSampleUpdatedAtDesc = 'CONTACTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmailsAsc = 'EMAILS_ASC',
  EmailsDesc = 'EMAILS_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionAverageCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionAverageCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionAverageEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionAverageEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionAverageEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionAverageFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionAveragePayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionAveragePayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionAverageProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionAverageRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionAverageRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionAverageRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionAverageRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionAverageSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionAverageSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionMaxCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionMaxCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionMaxEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionMaxEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionMaxEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionMaxFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionMaxPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionMaxProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMaxRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionMaxRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionMaxRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionMaxRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionMaxSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionMaxSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionMinCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionMinCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMinCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMinCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionMinEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionMinEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionMinEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionMinFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionMinPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionMinProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMinRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMinRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionMinRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionMinRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionMinRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionMinSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionMinSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionSumCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionSumCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionSumCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionSumCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionSumEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionSumEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionSumEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionSumFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionSumPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionSumProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionSumRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionSumRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionSumRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionSumRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionSumRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionSumSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionSumSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByContactIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByCreatedByContactIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FeedInboxesConnectionArrayAggContactIdAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  FeedInboxesConnectionArrayAggContactIdDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  FeedInboxesConnectionArrayAggCreatedAtAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedInboxesConnectionArrayAggCreatedAtDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedInboxesConnectionArrayAggEntityTypeAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  FeedInboxesConnectionArrayAggEntityTypeDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  FeedInboxesConnectionArrayAggEventTypeAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedInboxesConnectionArrayAggEventTypeDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedInboxesConnectionArrayAggFeedIdAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  FeedInboxesConnectionArrayAggFeedIdDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  FeedInboxesConnectionArrayAggIdAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedInboxesConnectionArrayAggIdDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedInboxesConnectionArrayAggReadAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_READ_ASC',
  FeedInboxesConnectionArrayAggReadDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_READ_DESC',
  FeedInboxesConnectionArrayAggReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionArrayAggReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionArrayAggUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FeedInboxesConnectionArrayAggUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FeedInboxesConnectionArrayAggUserIdAsc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FeedInboxesConnectionArrayAggUserIdDesc = 'FEED_INBOXES_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FeedInboxesConnectionAverageContactIdAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  FeedInboxesConnectionAverageContactIdDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  FeedInboxesConnectionAverageCreatedAtAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedInboxesConnectionAverageCreatedAtDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedInboxesConnectionAverageEntityTypeAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  FeedInboxesConnectionAverageEntityTypeDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  FeedInboxesConnectionAverageEventTypeAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedInboxesConnectionAverageEventTypeDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedInboxesConnectionAverageFeedIdAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_FEED_ID_ASC',
  FeedInboxesConnectionAverageFeedIdDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_FEED_ID_DESC',
  FeedInboxesConnectionAverageIdAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_ID_ASC',
  FeedInboxesConnectionAverageIdDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_ID_DESC',
  FeedInboxesConnectionAverageReadAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_READ_ASC',
  FeedInboxesConnectionAverageReadDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_READ_DESC',
  FeedInboxesConnectionAverageReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionAverageReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionAverageUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FeedInboxesConnectionAverageUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FeedInboxesConnectionAverageUserIdAsc = 'FEED_INBOXES_CONNECTION_AVERAGE_USER_ID_ASC',
  FeedInboxesConnectionAverageUserIdDesc = 'FEED_INBOXES_CONNECTION_AVERAGE_USER_ID_DESC',
  FeedInboxesConnectionCountAsc = 'FEED_INBOXES_CONNECTION_COUNT_ASC',
  FeedInboxesConnectionCountDesc = 'FEED_INBOXES_CONNECTION_COUNT_DESC',
  FeedInboxesConnectionDistinctCountContactIdAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  FeedInboxesConnectionDistinctCountContactIdDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  FeedInboxesConnectionDistinctCountCreatedAtAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedInboxesConnectionDistinctCountCreatedAtDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedInboxesConnectionDistinctCountEntityTypeAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  FeedInboxesConnectionDistinctCountEntityTypeDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  FeedInboxesConnectionDistinctCountEventTypeAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedInboxesConnectionDistinctCountEventTypeDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedInboxesConnectionDistinctCountFeedIdAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  FeedInboxesConnectionDistinctCountFeedIdDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  FeedInboxesConnectionDistinctCountIdAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedInboxesConnectionDistinctCountIdDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedInboxesConnectionDistinctCountReadAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_READ_ASC',
  FeedInboxesConnectionDistinctCountReadDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_READ_DESC',
  FeedInboxesConnectionDistinctCountReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionDistinctCountReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionDistinctCountUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FeedInboxesConnectionDistinctCountUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FeedInboxesConnectionDistinctCountUserIdAsc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FeedInboxesConnectionDistinctCountUserIdDesc = 'FEED_INBOXES_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FeedInboxesConnectionMaxContactIdAsc = 'FEED_INBOXES_CONNECTION_MAX_CONTACT_ID_ASC',
  FeedInboxesConnectionMaxContactIdDesc = 'FEED_INBOXES_CONNECTION_MAX_CONTACT_ID_DESC',
  FeedInboxesConnectionMaxCreatedAtAsc = 'FEED_INBOXES_CONNECTION_MAX_CREATED_AT_ASC',
  FeedInboxesConnectionMaxCreatedAtDesc = 'FEED_INBOXES_CONNECTION_MAX_CREATED_AT_DESC',
  FeedInboxesConnectionMaxEntityTypeAsc = 'FEED_INBOXES_CONNECTION_MAX_ENTITY_TYPE_ASC',
  FeedInboxesConnectionMaxEntityTypeDesc = 'FEED_INBOXES_CONNECTION_MAX_ENTITY_TYPE_DESC',
  FeedInboxesConnectionMaxEventTypeAsc = 'FEED_INBOXES_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedInboxesConnectionMaxEventTypeDesc = 'FEED_INBOXES_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedInboxesConnectionMaxFeedIdAsc = 'FEED_INBOXES_CONNECTION_MAX_FEED_ID_ASC',
  FeedInboxesConnectionMaxFeedIdDesc = 'FEED_INBOXES_CONNECTION_MAX_FEED_ID_DESC',
  FeedInboxesConnectionMaxIdAsc = 'FEED_INBOXES_CONNECTION_MAX_ID_ASC',
  FeedInboxesConnectionMaxIdDesc = 'FEED_INBOXES_CONNECTION_MAX_ID_DESC',
  FeedInboxesConnectionMaxReadAsc = 'FEED_INBOXES_CONNECTION_MAX_READ_ASC',
  FeedInboxesConnectionMaxReadDesc = 'FEED_INBOXES_CONNECTION_MAX_READ_DESC',
  FeedInboxesConnectionMaxReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_MAX_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionMaxReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_MAX_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionMaxUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_MAX_UPDATED_AT_ASC',
  FeedInboxesConnectionMaxUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_MAX_UPDATED_AT_DESC',
  FeedInboxesConnectionMaxUserIdAsc = 'FEED_INBOXES_CONNECTION_MAX_USER_ID_ASC',
  FeedInboxesConnectionMaxUserIdDesc = 'FEED_INBOXES_CONNECTION_MAX_USER_ID_DESC',
  FeedInboxesConnectionMinContactIdAsc = 'FEED_INBOXES_CONNECTION_MIN_CONTACT_ID_ASC',
  FeedInboxesConnectionMinContactIdDesc = 'FEED_INBOXES_CONNECTION_MIN_CONTACT_ID_DESC',
  FeedInboxesConnectionMinCreatedAtAsc = 'FEED_INBOXES_CONNECTION_MIN_CREATED_AT_ASC',
  FeedInboxesConnectionMinCreatedAtDesc = 'FEED_INBOXES_CONNECTION_MIN_CREATED_AT_DESC',
  FeedInboxesConnectionMinEntityTypeAsc = 'FEED_INBOXES_CONNECTION_MIN_ENTITY_TYPE_ASC',
  FeedInboxesConnectionMinEntityTypeDesc = 'FEED_INBOXES_CONNECTION_MIN_ENTITY_TYPE_DESC',
  FeedInboxesConnectionMinEventTypeAsc = 'FEED_INBOXES_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedInboxesConnectionMinEventTypeDesc = 'FEED_INBOXES_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedInboxesConnectionMinFeedIdAsc = 'FEED_INBOXES_CONNECTION_MIN_FEED_ID_ASC',
  FeedInboxesConnectionMinFeedIdDesc = 'FEED_INBOXES_CONNECTION_MIN_FEED_ID_DESC',
  FeedInboxesConnectionMinIdAsc = 'FEED_INBOXES_CONNECTION_MIN_ID_ASC',
  FeedInboxesConnectionMinIdDesc = 'FEED_INBOXES_CONNECTION_MIN_ID_DESC',
  FeedInboxesConnectionMinReadAsc = 'FEED_INBOXES_CONNECTION_MIN_READ_ASC',
  FeedInboxesConnectionMinReadDesc = 'FEED_INBOXES_CONNECTION_MIN_READ_DESC',
  FeedInboxesConnectionMinReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_MIN_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionMinReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_MIN_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionMinUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_MIN_UPDATED_AT_ASC',
  FeedInboxesConnectionMinUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_MIN_UPDATED_AT_DESC',
  FeedInboxesConnectionMinUserIdAsc = 'FEED_INBOXES_CONNECTION_MIN_USER_ID_ASC',
  FeedInboxesConnectionMinUserIdDesc = 'FEED_INBOXES_CONNECTION_MIN_USER_ID_DESC',
  FeedInboxesConnectionStddevPopulationContactIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  FeedInboxesConnectionStddevPopulationContactIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  FeedInboxesConnectionStddevPopulationCreatedAtAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedInboxesConnectionStddevPopulationCreatedAtDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedInboxesConnectionStddevPopulationEntityTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesConnectionStddevPopulationEntityTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesConnectionStddevPopulationEventTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesConnectionStddevPopulationEventTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesConnectionStddevPopulationFeedIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  FeedInboxesConnectionStddevPopulationFeedIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  FeedInboxesConnectionStddevPopulationIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedInboxesConnectionStddevPopulationIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedInboxesConnectionStddevPopulationReadAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_READ_ASC',
  FeedInboxesConnectionStddevPopulationReadDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_READ_DESC',
  FeedInboxesConnectionStddevPopulationReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionStddevPopulationReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionStddevPopulationUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FeedInboxesConnectionStddevPopulationUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FeedInboxesConnectionStddevPopulationUserIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FeedInboxesConnectionStddevPopulationUserIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FeedInboxesConnectionStddevSampleContactIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesConnectionStddevSampleContactIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesConnectionStddevSampleCreatedAtAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedInboxesConnectionStddevSampleCreatedAtDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedInboxesConnectionStddevSampleEntityTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesConnectionStddevSampleEntityTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesConnectionStddevSampleEventTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesConnectionStddevSampleEventTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesConnectionStddevSampleFeedIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  FeedInboxesConnectionStddevSampleFeedIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  FeedInboxesConnectionStddevSampleIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedInboxesConnectionStddevSampleIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedInboxesConnectionStddevSampleReadAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  FeedInboxesConnectionStddevSampleReadDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  FeedInboxesConnectionStddevSampleReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionStddevSampleReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionStddevSampleUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesConnectionStddevSampleUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesConnectionStddevSampleUserIdAsc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FeedInboxesConnectionStddevSampleUserIdDesc = 'FEED_INBOXES_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FeedInboxesConnectionSumContactIdAsc = 'FEED_INBOXES_CONNECTION_SUM_CONTACT_ID_ASC',
  FeedInboxesConnectionSumContactIdDesc = 'FEED_INBOXES_CONNECTION_SUM_CONTACT_ID_DESC',
  FeedInboxesConnectionSumCreatedAtAsc = 'FEED_INBOXES_CONNECTION_SUM_CREATED_AT_ASC',
  FeedInboxesConnectionSumCreatedAtDesc = 'FEED_INBOXES_CONNECTION_SUM_CREATED_AT_DESC',
  FeedInboxesConnectionSumEntityTypeAsc = 'FEED_INBOXES_CONNECTION_SUM_ENTITY_TYPE_ASC',
  FeedInboxesConnectionSumEntityTypeDesc = 'FEED_INBOXES_CONNECTION_SUM_ENTITY_TYPE_DESC',
  FeedInboxesConnectionSumEventTypeAsc = 'FEED_INBOXES_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedInboxesConnectionSumEventTypeDesc = 'FEED_INBOXES_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedInboxesConnectionSumFeedIdAsc = 'FEED_INBOXES_CONNECTION_SUM_FEED_ID_ASC',
  FeedInboxesConnectionSumFeedIdDesc = 'FEED_INBOXES_CONNECTION_SUM_FEED_ID_DESC',
  FeedInboxesConnectionSumIdAsc = 'FEED_INBOXES_CONNECTION_SUM_ID_ASC',
  FeedInboxesConnectionSumIdDesc = 'FEED_INBOXES_CONNECTION_SUM_ID_DESC',
  FeedInboxesConnectionSumReadAsc = 'FEED_INBOXES_CONNECTION_SUM_READ_ASC',
  FeedInboxesConnectionSumReadDesc = 'FEED_INBOXES_CONNECTION_SUM_READ_DESC',
  FeedInboxesConnectionSumReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_SUM_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionSumReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_SUM_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionSumUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_SUM_UPDATED_AT_ASC',
  FeedInboxesConnectionSumUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_SUM_UPDATED_AT_DESC',
  FeedInboxesConnectionSumUserIdAsc = 'FEED_INBOXES_CONNECTION_SUM_USER_ID_ASC',
  FeedInboxesConnectionSumUserIdDesc = 'FEED_INBOXES_CONNECTION_SUM_USER_ID_DESC',
  FeedInboxesConnectionVariancePopulationContactIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  FeedInboxesConnectionVariancePopulationContactIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  FeedInboxesConnectionVariancePopulationCreatedAtAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedInboxesConnectionVariancePopulationCreatedAtDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedInboxesConnectionVariancePopulationEntityTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesConnectionVariancePopulationEntityTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesConnectionVariancePopulationEventTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesConnectionVariancePopulationEventTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesConnectionVariancePopulationFeedIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  FeedInboxesConnectionVariancePopulationFeedIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  FeedInboxesConnectionVariancePopulationIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedInboxesConnectionVariancePopulationIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedInboxesConnectionVariancePopulationReadAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  FeedInboxesConnectionVariancePopulationReadDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  FeedInboxesConnectionVariancePopulationReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionVariancePopulationReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionVariancePopulationUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FeedInboxesConnectionVariancePopulationUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FeedInboxesConnectionVariancePopulationUserIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FeedInboxesConnectionVariancePopulationUserIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FeedInboxesConnectionVarianceSampleContactIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesConnectionVarianceSampleContactIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesConnectionVarianceSampleCreatedAtAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedInboxesConnectionVarianceSampleCreatedAtDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedInboxesConnectionVarianceSampleEntityTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesConnectionVarianceSampleEntityTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesConnectionVarianceSampleEventTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesConnectionVarianceSampleEventTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesConnectionVarianceSampleFeedIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  FeedInboxesConnectionVarianceSampleFeedIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  FeedInboxesConnectionVarianceSampleIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedInboxesConnectionVarianceSampleIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedInboxesConnectionVarianceSampleReadAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  FeedInboxesConnectionVarianceSampleReadDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  FeedInboxesConnectionVarianceSampleReceiverTypeAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesConnectionVarianceSampleReceiverTypeDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesConnectionVarianceSampleUpdatedAtAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesConnectionVarianceSampleUpdatedAtDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesConnectionVarianceSampleUserIdAsc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FeedInboxesConnectionVarianceSampleUserIdDesc = 'FEED_INBOXES_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionArrayAggAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionArrayAggDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionArrayAggDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionArrayAggFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionArrayAggIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionArrayAggIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionArrayAggIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionArrayAggMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionArrayAggMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionArrayAggModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionArrayAggModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionArrayAggNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByCreatedByContactIdConnectionArrayAggNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByCreatedByContactIdConnectionArrayAggOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionArrayAggOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionArrayAggProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionArrayAggProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionArrayAggSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionArrayAggSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionArrayAggStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByCreatedByContactIdConnectionArrayAggTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByCreatedByContactIdConnectionArrayAggUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionArrayAggUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionArrayAggUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByCreatedByContactIdConnectionArrayAggValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionArrayAggValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionAverageAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionAverageCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionAverageCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionAverageCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionAverageCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionAverageCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionAverageCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionAverageDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionAverageDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionAverageFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionAverageIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionAverageIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionAverageIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionAverageMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionAverageMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionAverageModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionAverageModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionAverageNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_NAME_ASC',
  FilesByCreatedByContactIdConnectionAverageNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_NAME_DESC',
  FilesByCreatedByContactIdConnectionAverageOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionAverageOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionAveragePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionAverageProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionAverageProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionAverageSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionAverageSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionAverageSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionAverageStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionAverageTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByCreatedByContactIdConnectionAverageTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByCreatedByContactIdConnectionAverageUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionAverageUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionAverageUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UUID_ASC',
  FilesByCreatedByContactIdConnectionAverageUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UUID_DESC',
  FilesByCreatedByContactIdConnectionAverageValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionAverageValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionCountAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_ASC',
  FilesByCreatedByContactIdConnectionCountDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByCreatedByContactIdConnectionDistinctCountValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionDistinctCountValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionMaxAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionMaxCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionMaxCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionMaxCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionMaxCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionMaxCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionMaxCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionMaxDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionMaxDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionMaxFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionMaxIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionMaxIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionMaxIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionMaxMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionMaxMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionMaxModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionMaxModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionMaxNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_NAME_ASC',
  FilesByCreatedByContactIdConnectionMaxNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_NAME_DESC',
  FilesByCreatedByContactIdConnectionMaxOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionMaxOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionMaxPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionMaxProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionMaxProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionMaxSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionMaxSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionMaxSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionMaxStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionMaxTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_ASC',
  FilesByCreatedByContactIdConnectionMaxTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_DESC',
  FilesByCreatedByContactIdConnectionMaxUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionMaxUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionMaxUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UUID_ASC',
  FilesByCreatedByContactIdConnectionMaxUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UUID_DESC',
  FilesByCreatedByContactIdConnectionMaxValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionMaxValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionMinActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionMinActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionMinActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionMinActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionMinAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionMinAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionMinCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionMinCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionMinCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionMinCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionMinCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionMinCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionMinCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionMinCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionMinCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionMinCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionMinCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionMinCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionMinDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionMinDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionMinFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionMinFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionMinIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  FilesByCreatedByContactIdConnectionMinIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  FilesByCreatedByContactIdConnectionMinIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionMinIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionMinIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionMinIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionMinMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionMinMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionMinModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionMinModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionMinNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_NAME_ASC',
  FilesByCreatedByContactIdConnectionMinNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_NAME_DESC',
  FilesByCreatedByContactIdConnectionMinOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionMinOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionMinPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionMinPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionMinPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionMinPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionMinPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionMinPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionMinPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionMinPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionMinProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionMinProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionMinProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionMinProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionMinProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionMinProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionMinSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionMinSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionMinSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionMinSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionMinStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionMinStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionMinTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_ASC',
  FilesByCreatedByContactIdConnectionMinTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_DESC',
  FilesByCreatedByContactIdConnectionMinUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionMinUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionMinUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UUID_ASC',
  FilesByCreatedByContactIdConnectionMinUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UUID_DESC',
  FilesByCreatedByContactIdConnectionMinValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionMinValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByCreatedByContactIdConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByCreatedByContactIdConnectionStddevSampleValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionStddevSampleValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionSumActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionSumActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionSumActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionSumActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionSumAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionSumAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionSumCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionSumCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionSumCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionSumCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionSumCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionSumCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionSumCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionSumCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionSumCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionSumCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionSumCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionSumCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionSumDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionSumDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionSumFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionSumFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionSumIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  FilesByCreatedByContactIdConnectionSumIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  FilesByCreatedByContactIdConnectionSumIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionSumIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionSumIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionSumIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionSumMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionSumMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionSumModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionSumModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionSumNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_NAME_ASC',
  FilesByCreatedByContactIdConnectionSumNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_NAME_DESC',
  FilesByCreatedByContactIdConnectionSumOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionSumOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionSumPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionSumPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionSumPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionSumPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionSumPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionSumPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionSumPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionSumPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionSumProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionSumProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionSumProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionSumProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionSumProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionSumProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionSumSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionSumSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionSumSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionSumSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionSumStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionSumStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionSumTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_ASC',
  FilesByCreatedByContactIdConnectionSumTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_DESC',
  FilesByCreatedByContactIdConnectionSumUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionSumUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionSumUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UUID_ASC',
  FilesByCreatedByContactIdConnectionSumUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UUID_DESC',
  FilesByCreatedByContactIdConnectionSumValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionSumValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByCreatedByContactIdConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleActionIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleActionIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCommentIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCommentIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleFormIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleFormIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleIsFormAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleIsFormDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleMetaDataAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleMetaDataDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleModifiedByAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleModifiedByDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleNameAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleNameDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleOriginAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleOriginDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByContactIdConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleProjectIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleStageIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleStageIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleTypeAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleTypeDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleUuidAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleUuidDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByCreatedByContactIdConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByCreatedByContactIdConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportRowsConnectionArrayAggAccountIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ACCOUNT_ID_ASC',
  ImportRowsConnectionArrayAggAccountIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ACCOUNT_ID_DESC',
  ImportRowsConnectionArrayAggContactIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ImportRowsConnectionArrayAggContactIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ImportRowsConnectionArrayAggCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportRowsConnectionArrayAggCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportRowsConnectionArrayAggEndedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportRowsConnectionArrayAggEndedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportRowsConnectionArrayAggErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportRowsConnectionArrayAggErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportRowsConnectionArrayAggErrorStackAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportRowsConnectionArrayAggErrorStackDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportRowsConnectionArrayAggGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionArrayAggGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionArrayAggIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportRowsConnectionArrayAggIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportRowsConnectionArrayAggImportDataAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportRowsConnectionArrayAggImportDataDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportRowsConnectionArrayAggImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionArrayAggImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionArrayAggImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionArrayAggImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionArrayAggIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionArrayAggIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionArrayAggIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionArrayAggMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionArrayAggMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionArrayAggProjectIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ImportRowsConnectionArrayAggProjectIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ImportRowsConnectionArrayAggReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionArrayAggReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionArrayAggResultDataAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_RESULT_DATA_ASC',
  ImportRowsConnectionArrayAggResultDataDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_RESULT_DATA_DESC',
  ImportRowsConnectionArrayAggStartedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ImportRowsConnectionArrayAggStartedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ImportRowsConnectionArrayAggStatusAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportRowsConnectionArrayAggStatusDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportRowsConnectionArrayAggUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportRowsConnectionArrayAggUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportRowsConnectionArrayAggUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionArrayAggUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionAverageAccountIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ACCOUNT_ID_ASC',
  ImportRowsConnectionAverageAccountIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ACCOUNT_ID_DESC',
  ImportRowsConnectionAverageContactIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ImportRowsConnectionAverageContactIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ImportRowsConnectionAverageCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportRowsConnectionAverageCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportRowsConnectionAverageEndedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportRowsConnectionAverageEndedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportRowsConnectionAverageErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionAverageErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionAverageErrorStackAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportRowsConnectionAverageErrorStackDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportRowsConnectionAverageGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionAverageGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionAverageIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ID_ASC',
  ImportRowsConnectionAverageIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ID_DESC',
  ImportRowsConnectionAverageImportDataAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportRowsConnectionAverageImportDataDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportRowsConnectionAverageImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionAverageImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionAverageImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionAverageImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionAverageIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionAverageIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionAverageIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionAverageIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionAverageIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionAverageIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionAverageMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionAverageMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionAverageProjectIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ImportRowsConnectionAverageProjectIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ImportRowsConnectionAverageReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionAverageReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionAverageResultDataAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_RESULT_DATA_ASC',
  ImportRowsConnectionAverageResultDataDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_RESULT_DATA_DESC',
  ImportRowsConnectionAverageStartedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ImportRowsConnectionAverageStartedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ImportRowsConnectionAverageStatusAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STATUS_ASC',
  ImportRowsConnectionAverageStatusDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STATUS_DESC',
  ImportRowsConnectionAverageUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportRowsConnectionAverageUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportRowsConnectionAverageUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionAverageUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionCountAsc = 'IMPORT_ROWS_CONNECTION_COUNT_ASC',
  ImportRowsConnectionCountDesc = 'IMPORT_ROWS_CONNECTION_COUNT_DESC',
  ImportRowsConnectionDistinctCountAccountIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_ASC',
  ImportRowsConnectionDistinctCountAccountIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_DESC',
  ImportRowsConnectionDistinctCountContactIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ImportRowsConnectionDistinctCountContactIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ImportRowsConnectionDistinctCountCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportRowsConnectionDistinctCountCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportRowsConnectionDistinctCountEndedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportRowsConnectionDistinctCountEndedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportRowsConnectionDistinctCountErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportRowsConnectionDistinctCountErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportRowsConnectionDistinctCountErrorStackAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportRowsConnectionDistinctCountErrorStackDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportRowsConnectionDistinctCountGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionDistinctCountGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionDistinctCountIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportRowsConnectionDistinctCountIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportRowsConnectionDistinctCountImportDataAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportRowsConnectionDistinctCountImportDataDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportRowsConnectionDistinctCountImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionDistinctCountImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionDistinctCountImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionDistinctCountImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionDistinctCountIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionDistinctCountIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionDistinctCountIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionDistinctCountMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionDistinctCountMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionDistinctCountProjectIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ImportRowsConnectionDistinctCountProjectIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ImportRowsConnectionDistinctCountReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionDistinctCountReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionDistinctCountResultDataAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_RESULT_DATA_ASC',
  ImportRowsConnectionDistinctCountResultDataDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_RESULT_DATA_DESC',
  ImportRowsConnectionDistinctCountStartedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ImportRowsConnectionDistinctCountStartedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ImportRowsConnectionDistinctCountStatusAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportRowsConnectionDistinctCountStatusDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportRowsConnectionDistinctCountUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportRowsConnectionDistinctCountUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportRowsConnectionDistinctCountUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionDistinctCountUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionMaxAccountIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_ACCOUNT_ID_ASC',
  ImportRowsConnectionMaxAccountIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_ACCOUNT_ID_DESC',
  ImportRowsConnectionMaxContactIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_CONTACT_ID_ASC',
  ImportRowsConnectionMaxContactIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_CONTACT_ID_DESC',
  ImportRowsConnectionMaxCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_CREATED_AT_ASC',
  ImportRowsConnectionMaxCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_CREATED_AT_DESC',
  ImportRowsConnectionMaxEndedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_ENDED_AT_ASC',
  ImportRowsConnectionMaxEndedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_ENDED_AT_DESC',
  ImportRowsConnectionMaxErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportRowsConnectionMaxErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportRowsConnectionMaxErrorStackAsc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportRowsConnectionMaxErrorStackDesc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportRowsConnectionMaxGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_MAX_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionMaxGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_MAX_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionMaxIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_ID_ASC',
  ImportRowsConnectionMaxIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_ID_DESC',
  ImportRowsConnectionMaxImportDataAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportRowsConnectionMaxImportDataDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportRowsConnectionMaxImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionMaxImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionMaxImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionMaxImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionMaxIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionMaxIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionMaxIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionMaxIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionMaxIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionMaxIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionMaxMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_MAX_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionMaxMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_MAX_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionMaxProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_PROJECT_ID_ASC',
  ImportRowsConnectionMaxProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_PROJECT_ID_DESC',
  ImportRowsConnectionMaxReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionMaxReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionMaxResultDataAsc = 'IMPORT_ROWS_CONNECTION_MAX_RESULT_DATA_ASC',
  ImportRowsConnectionMaxResultDataDesc = 'IMPORT_ROWS_CONNECTION_MAX_RESULT_DATA_DESC',
  ImportRowsConnectionMaxStartedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_STARTED_AT_ASC',
  ImportRowsConnectionMaxStartedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_STARTED_AT_DESC',
  ImportRowsConnectionMaxStatusAsc = 'IMPORT_ROWS_CONNECTION_MAX_STATUS_ASC',
  ImportRowsConnectionMaxStatusDesc = 'IMPORT_ROWS_CONNECTION_MAX_STATUS_DESC',
  ImportRowsConnectionMaxUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportRowsConnectionMaxUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportRowsConnectionMaxUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionMaxUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionMinAccountIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_ACCOUNT_ID_ASC',
  ImportRowsConnectionMinAccountIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_ACCOUNT_ID_DESC',
  ImportRowsConnectionMinContactIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_CONTACT_ID_ASC',
  ImportRowsConnectionMinContactIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_CONTACT_ID_DESC',
  ImportRowsConnectionMinCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_CREATED_AT_ASC',
  ImportRowsConnectionMinCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_CREATED_AT_DESC',
  ImportRowsConnectionMinEndedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_ENDED_AT_ASC',
  ImportRowsConnectionMinEndedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_ENDED_AT_DESC',
  ImportRowsConnectionMinErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportRowsConnectionMinErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportRowsConnectionMinErrorStackAsc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportRowsConnectionMinErrorStackDesc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportRowsConnectionMinGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_MIN_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionMinGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_MIN_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionMinIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_ID_ASC',
  ImportRowsConnectionMinIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_ID_DESC',
  ImportRowsConnectionMinImportDataAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportRowsConnectionMinImportDataDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportRowsConnectionMinImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionMinImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionMinImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionMinImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionMinIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionMinIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionMinIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionMinIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionMinIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionMinIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionMinMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_MIN_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionMinMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_MIN_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionMinProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_PROJECT_ID_ASC',
  ImportRowsConnectionMinProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_PROJECT_ID_DESC',
  ImportRowsConnectionMinReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionMinReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionMinResultDataAsc = 'IMPORT_ROWS_CONNECTION_MIN_RESULT_DATA_ASC',
  ImportRowsConnectionMinResultDataDesc = 'IMPORT_ROWS_CONNECTION_MIN_RESULT_DATA_DESC',
  ImportRowsConnectionMinStartedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_STARTED_AT_ASC',
  ImportRowsConnectionMinStartedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_STARTED_AT_DESC',
  ImportRowsConnectionMinStatusAsc = 'IMPORT_ROWS_CONNECTION_MIN_STATUS_ASC',
  ImportRowsConnectionMinStatusDesc = 'IMPORT_ROWS_CONNECTION_MIN_STATUS_DESC',
  ImportRowsConnectionMinUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportRowsConnectionMinUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportRowsConnectionMinUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionMinUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionStddevPopulationAccountIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsConnectionStddevPopulationAccountIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsConnectionStddevPopulationContactIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ImportRowsConnectionStddevPopulationContactIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ImportRowsConnectionStddevPopulationCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportRowsConnectionStddevPopulationCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportRowsConnectionStddevPopulationEndedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportRowsConnectionStddevPopulationEndedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportRowsConnectionStddevPopulationErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsConnectionStddevPopulationErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsConnectionStddevPopulationErrorStackAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportRowsConnectionStddevPopulationErrorStackDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportRowsConnectionStddevPopulationGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionStddevPopulationGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionStddevPopulationIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportRowsConnectionStddevPopulationIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportRowsConnectionStddevPopulationImportDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportRowsConnectionStddevPopulationImportDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportRowsConnectionStddevPopulationImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionStddevPopulationImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionStddevPopulationImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionStddevPopulationImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionStddevPopulationIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionStddevPopulationMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionStddevPopulationProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ImportRowsConnectionStddevPopulationProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ImportRowsConnectionStddevPopulationReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionStddevPopulationReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionStddevPopulationResultDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_RESULT_DATA_ASC',
  ImportRowsConnectionStddevPopulationResultDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_RESULT_DATA_DESC',
  ImportRowsConnectionStddevPopulationStartedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ImportRowsConnectionStddevPopulationStartedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ImportRowsConnectionStddevPopulationStatusAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportRowsConnectionStddevPopulationStatusDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportRowsConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportRowsConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportRowsConnectionStddevPopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionStddevPopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionStddevSampleAccountIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsConnectionStddevSampleAccountIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsConnectionStddevSampleContactIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ImportRowsConnectionStddevSampleContactIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ImportRowsConnectionStddevSampleCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportRowsConnectionStddevSampleCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportRowsConnectionStddevSampleEndedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportRowsConnectionStddevSampleEndedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportRowsConnectionStddevSampleErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionStddevSampleErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionStddevSampleErrorStackAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportRowsConnectionStddevSampleErrorStackDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportRowsConnectionStddevSampleGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionStddevSampleGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionStddevSampleIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportRowsConnectionStddevSampleIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportRowsConnectionStddevSampleImportDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsConnectionStddevSampleImportDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsConnectionStddevSampleImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionStddevSampleImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionStddevSampleImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionStddevSampleImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionStddevSampleIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionStddevSampleIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionStddevSampleIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionStddevSampleMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionStddevSampleMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionStddevSampleProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ImportRowsConnectionStddevSampleProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ImportRowsConnectionStddevSampleReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionStddevSampleReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionStddevSampleResultDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_ASC',
  ImportRowsConnectionStddevSampleResultDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_DESC',
  ImportRowsConnectionStddevSampleStartedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ImportRowsConnectionStddevSampleStartedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ImportRowsConnectionStddevSampleStatusAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportRowsConnectionStddevSampleStatusDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportRowsConnectionStddevSampleUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportRowsConnectionStddevSampleUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportRowsConnectionStddevSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionStddevSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionSumAccountIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_ACCOUNT_ID_ASC',
  ImportRowsConnectionSumAccountIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_ACCOUNT_ID_DESC',
  ImportRowsConnectionSumContactIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_CONTACT_ID_ASC',
  ImportRowsConnectionSumContactIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_CONTACT_ID_DESC',
  ImportRowsConnectionSumCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_CREATED_AT_ASC',
  ImportRowsConnectionSumCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_CREATED_AT_DESC',
  ImportRowsConnectionSumEndedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_ENDED_AT_ASC',
  ImportRowsConnectionSumEndedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_ENDED_AT_DESC',
  ImportRowsConnectionSumErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportRowsConnectionSumErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportRowsConnectionSumErrorStackAsc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportRowsConnectionSumErrorStackDesc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportRowsConnectionSumGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_SUM_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionSumGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_SUM_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionSumIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_ID_ASC',
  ImportRowsConnectionSumIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_ID_DESC',
  ImportRowsConnectionSumImportDataAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportRowsConnectionSumImportDataDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportRowsConnectionSumImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionSumImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionSumImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionSumImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionSumIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionSumIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionSumIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionSumIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionSumIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionSumIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionSumMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_SUM_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionSumMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_SUM_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionSumProjectIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_PROJECT_ID_ASC',
  ImportRowsConnectionSumProjectIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_PROJECT_ID_DESC',
  ImportRowsConnectionSumReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionSumReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionSumResultDataAsc = 'IMPORT_ROWS_CONNECTION_SUM_RESULT_DATA_ASC',
  ImportRowsConnectionSumResultDataDesc = 'IMPORT_ROWS_CONNECTION_SUM_RESULT_DATA_DESC',
  ImportRowsConnectionSumStartedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_STARTED_AT_ASC',
  ImportRowsConnectionSumStartedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_STARTED_AT_DESC',
  ImportRowsConnectionSumStatusAsc = 'IMPORT_ROWS_CONNECTION_SUM_STATUS_ASC',
  ImportRowsConnectionSumStatusDesc = 'IMPORT_ROWS_CONNECTION_SUM_STATUS_DESC',
  ImportRowsConnectionSumUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportRowsConnectionSumUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportRowsConnectionSumUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionSumUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionVariancePopulationAccountIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsConnectionVariancePopulationAccountIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsConnectionVariancePopulationContactIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ImportRowsConnectionVariancePopulationContactIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ImportRowsConnectionVariancePopulationCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportRowsConnectionVariancePopulationCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportRowsConnectionVariancePopulationEndedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportRowsConnectionVariancePopulationEndedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportRowsConnectionVariancePopulationErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsConnectionVariancePopulationErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsConnectionVariancePopulationErrorStackAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportRowsConnectionVariancePopulationErrorStackDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportRowsConnectionVariancePopulationGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionVariancePopulationGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionVariancePopulationIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportRowsConnectionVariancePopulationIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportRowsConnectionVariancePopulationImportDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportRowsConnectionVariancePopulationImportDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportRowsConnectionVariancePopulationImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionVariancePopulationImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionVariancePopulationImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionVariancePopulationImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionVariancePopulationIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionVariancePopulationMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionVariancePopulationProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ImportRowsConnectionVariancePopulationProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ImportRowsConnectionVariancePopulationReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionVariancePopulationReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionVariancePopulationResultDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_ASC',
  ImportRowsConnectionVariancePopulationResultDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_DESC',
  ImportRowsConnectionVariancePopulationStartedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ImportRowsConnectionVariancePopulationStartedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ImportRowsConnectionVariancePopulationStatusAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportRowsConnectionVariancePopulationStatusDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportRowsConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportRowsConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportRowsConnectionVariancePopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionVariancePopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionVarianceSampleAccountIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsConnectionVarianceSampleAccountIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsConnectionVarianceSampleContactIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ImportRowsConnectionVarianceSampleContactIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ImportRowsConnectionVarianceSampleCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportRowsConnectionVarianceSampleCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportRowsConnectionVarianceSampleEndedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportRowsConnectionVarianceSampleEndedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportRowsConnectionVarianceSampleErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionVarianceSampleErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionVarianceSampleErrorStackAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportRowsConnectionVarianceSampleErrorStackDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportRowsConnectionVarianceSampleGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionVarianceSampleGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionVarianceSampleIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportRowsConnectionVarianceSampleIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportRowsConnectionVarianceSampleImportDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsConnectionVarianceSampleImportDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsConnectionVarianceSampleImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionVarianceSampleImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionVarianceSampleImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionVarianceSampleImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionVarianceSampleIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionVarianceSampleMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionVarianceSampleProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ImportRowsConnectionVarianceSampleProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ImportRowsConnectionVarianceSampleReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionVarianceSampleReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionVarianceSampleResultDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_ASC',
  ImportRowsConnectionVarianceSampleResultDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_DESC',
  ImportRowsConnectionVarianceSampleStartedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ImportRowsConnectionVarianceSampleStartedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ImportRowsConnectionVarianceSampleStatusAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportRowsConnectionVarianceSampleStatusDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportRowsConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportRowsConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ImportRowsConnectionVarianceSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionVarianceSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_DESC',
  InvitedCountAsc = 'INVITED_COUNT_ASC',
  InvitedCountDesc = 'INVITED_COUNT_DESC',
  LastInvitedAtAsc = 'LAST_INVITED_AT_ASC',
  LastInvitedAtDesc = 'LAST_INVITED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PhonesAsc = 'PHONES_ASC',
  PhonesDesc = 'PHONES_DESC',
  PortalStatusAsc = 'PORTAL_STATUS_ASC',
  PortalStatusDesc = 'PORTAL_STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionAverageAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionAverageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionAverageProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionAverageProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionAverageRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionAverageStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionAverageStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionAverageStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionAverageTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionAverageUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByCreatedByContactIdConnectionAverageUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByCreatedByContactIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionMaxAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionMaxIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionMaxProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionMaxProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMaxRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMaxStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMaxStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionMaxStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionMaxTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMaxUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByCreatedByContactIdConnectionMaxUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByCreatedByContactIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMinAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionMinAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionMinAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionMinAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionMinCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionMinConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionMinConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMinDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionMinDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionMinDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionMinDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionMinDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionMinDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionMinIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionMinImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionMinIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionMinIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionMinOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionMinOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionMinPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionMinProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionMinProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionMinProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionMinProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMinRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionMinRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionMinSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionMinStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionMinStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionMinStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionMinStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionMinStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionMinStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionMinStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionMinStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionMinTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionMinTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionMinTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionMinTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionMinTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionMinTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionMinTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionMinTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionMinUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByCreatedByContactIdConnectionMinUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByCreatedByContactIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionMinZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionMinZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionSumAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionSumAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionSumAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionSumAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionSumCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionSumConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionSumConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionSumDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionSumDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionSumDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionSumDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionSumDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionSumDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionSumIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionSumImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionSumIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionSumIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionSumOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionSumOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionSumPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionSumProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionSumProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionSumProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionSumProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionSumRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionSumRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionSumSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionSumStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionSumStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionSumStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionSumStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionSumStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionSumStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionSumStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionSumStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionSumTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionSumTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionSumTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionSumTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionSumTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionSumTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionSumTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionSumTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionSumUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByCreatedByContactIdConnectionSumUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByCreatedByContactIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionSumZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionSumZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByCreatedByContactIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionAverageAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionAverageAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionAverageAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionAverageAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByReferrerContactIdConnectionAverageCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByReferrerContactIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionAverageDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionAverageDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionAverageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionAveragePhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionAveragePhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionAverageProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionAverageProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionAverageRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionAverageRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionAverageSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionAverageStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionAverageStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionAverageStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByReferrerContactIdConnectionAverageStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByReferrerContactIdConnectionAverageStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionAverageStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionAverageStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByReferrerContactIdConnectionAverageStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByReferrerContactIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionAverageTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionAverageTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionAverageTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionAverageTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionAverageTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionAverageTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionAverageUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByReferrerContactIdConnectionAverageUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByReferrerContactIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMaxAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionMaxAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionMaxAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionMaxAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByReferrerContactIdConnectionMaxCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByReferrerContactIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionMaxDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionMaxDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionMaxIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionMaxPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionMaxProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionMaxProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMaxRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionMaxRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionMaxSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionMaxStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionMaxStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionMaxStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByReferrerContactIdConnectionMaxStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByReferrerContactIdConnectionMaxStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMaxStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMaxStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByReferrerContactIdConnectionMaxStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByReferrerContactIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionMaxTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionMaxTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionMaxTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionMaxTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionMaxTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMaxTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMaxUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByReferrerContactIdConnectionMaxUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByReferrerContactIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMinAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionMinAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionMinAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionMinAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByReferrerContactIdConnectionMinCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByReferrerContactIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionMinConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionMinConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionMinCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMinDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionMinDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionMinDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionMinDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionMinDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionMinDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionMinIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionMinImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionMinIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionMinIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionMinLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionMinOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionMinOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionMinPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionMinProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionMinProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionMinProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionMinProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMinRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionMinRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionMinSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionMinStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionMinStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionMinStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByReferrerContactIdConnectionMinStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByReferrerContactIdConnectionMinStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionMinStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionMinStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByReferrerContactIdConnectionMinStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByReferrerContactIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionMinTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionMinTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionMinTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionMinTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionMinTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionMinTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionMinTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionMinTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionMinUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByReferrerContactIdConnectionMinUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByReferrerContactIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionMinZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionMinZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionSumAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionSumAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionSumAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionSumAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByReferrerContactIdConnectionSumCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByReferrerContactIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionSumConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionSumConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionSumCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionSumDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionSumDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionSumDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionSumDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionSumDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionSumDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionSumIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionSumImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionSumIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionSumIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionSumLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionSumOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionSumOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionSumPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionSumProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionSumProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionSumProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionSumProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionSumRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionSumRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionSumSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionSumStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionSumStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionSumStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByReferrerContactIdConnectionSumStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByReferrerContactIdConnectionSumStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionSumStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionSumStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByReferrerContactIdConnectionSumStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByReferrerContactIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionSumTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionSumTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionSumTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionSumTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionSumTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionSumTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionSumTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionSumTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionSumUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByReferrerContactIdConnectionSumUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByReferrerContactIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionSumZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionSumZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByReferrerContactIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByReferrerContactIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_REFERRER_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectContactsConnectionArrayAggContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ProjectContactsConnectionArrayAggContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ProjectContactsConnectionArrayAggCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectContactsConnectionArrayAggCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectContactsConnectionArrayAggPositionAsc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ProjectContactsConnectionArrayAggPositionDesc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ProjectContactsConnectionArrayAggProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectContactsConnectionArrayAggProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectContactsConnectionAverageContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ProjectContactsConnectionAverageContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ProjectContactsConnectionAverageCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectContactsConnectionAverageCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectContactsConnectionAveragePositionAsc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_POSITION_ASC',
  ProjectContactsConnectionAveragePositionDesc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_POSITION_DESC',
  ProjectContactsConnectionAverageProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectContactsConnectionAverageProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectContactsConnectionCountAsc = 'PROJECT_CONTACTS_CONNECTION_COUNT_ASC',
  ProjectContactsConnectionCountDesc = 'PROJECT_CONTACTS_CONNECTION_COUNT_DESC',
  ProjectContactsConnectionDistinctCountContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ProjectContactsConnectionDistinctCountContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ProjectContactsConnectionDistinctCountCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectContactsConnectionDistinctCountCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectContactsConnectionDistinctCountPositionAsc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ProjectContactsConnectionDistinctCountPositionDesc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ProjectContactsConnectionDistinctCountProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectContactsConnectionDistinctCountProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectContactsConnectionMaxContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_MAX_CONTACT_ID_ASC',
  ProjectContactsConnectionMaxContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_MAX_CONTACT_ID_DESC',
  ProjectContactsConnectionMaxCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectContactsConnectionMaxCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectContactsConnectionMaxPositionAsc = 'PROJECT_CONTACTS_CONNECTION_MAX_POSITION_ASC',
  ProjectContactsConnectionMaxPositionDesc = 'PROJECT_CONTACTS_CONNECTION_MAX_POSITION_DESC',
  ProjectContactsConnectionMaxProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectContactsConnectionMaxProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectContactsConnectionMinContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_MIN_CONTACT_ID_ASC',
  ProjectContactsConnectionMinContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_MIN_CONTACT_ID_DESC',
  ProjectContactsConnectionMinCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectContactsConnectionMinCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectContactsConnectionMinPositionAsc = 'PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC',
  ProjectContactsConnectionMinPositionDesc = 'PROJECT_CONTACTS_CONNECTION_MIN_POSITION_DESC',
  ProjectContactsConnectionMinProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectContactsConnectionMinProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectContactsConnectionStddevPopulationContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ProjectContactsConnectionStddevPopulationContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ProjectContactsConnectionStddevPopulationCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectContactsConnectionStddevPopulationCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectContactsConnectionStddevPopulationPositionAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ProjectContactsConnectionStddevPopulationPositionDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ProjectContactsConnectionStddevPopulationProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectContactsConnectionStddevPopulationProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectContactsConnectionStddevSampleContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ProjectContactsConnectionStddevSampleContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ProjectContactsConnectionStddevSampleCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectContactsConnectionStddevSampleCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectContactsConnectionStddevSamplePositionAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ProjectContactsConnectionStddevSamplePositionDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ProjectContactsConnectionStddevSampleProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectContactsConnectionStddevSampleProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectContactsConnectionSumContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_SUM_CONTACT_ID_ASC',
  ProjectContactsConnectionSumContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_SUM_CONTACT_ID_DESC',
  ProjectContactsConnectionSumCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectContactsConnectionSumCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectContactsConnectionSumPositionAsc = 'PROJECT_CONTACTS_CONNECTION_SUM_POSITION_ASC',
  ProjectContactsConnectionSumPositionDesc = 'PROJECT_CONTACTS_CONNECTION_SUM_POSITION_DESC',
  ProjectContactsConnectionSumProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectContactsConnectionSumProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectContactsConnectionVariancePopulationContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ProjectContactsConnectionVariancePopulationContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ProjectContactsConnectionVariancePopulationCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectContactsConnectionVariancePopulationCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectContactsConnectionVariancePopulationPositionAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ProjectContactsConnectionVariancePopulationPositionDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ProjectContactsConnectionVariancePopulationProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectContactsConnectionVariancePopulationProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectContactsConnectionVarianceSampleContactIdAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ProjectContactsConnectionVarianceSampleContactIdDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ProjectContactsConnectionVarianceSampleCreatedAtAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectContactsConnectionVarianceSampleCreatedAtDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectContactsConnectionVarianceSamplePositionAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ProjectContactsConnectionVarianceSamplePositionDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ProjectContactsConnectionVarianceSampleProjectIdAsc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectContactsConnectionVarianceSampleProjectIdDesc = 'PROJECT_CONTACTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ReferralCodeAsc = 'REFERRAL_CODE_ASC',
  ReferralCodeDesc = 'REFERRAL_CODE_DESC',
  ReferrerContactIdAsc = 'REFERRER_CONTACT_ID_ASC',
  ReferrerContactIdDesc = 'REFERRER_CONTACT_ID_DESC',
  SearchStringAsc = 'SEARCH_STRING_ASC',
  SearchStringDesc = 'SEARCH_STRING_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByFromContactIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromContactIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByToContactIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToContactIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToContactIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_CONTACT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum CreatedSource {
  Internal = 'INTERNAL',
  Portal = 'PORTAL',
  SignupPublic = 'SIGNUP_PUBLIC',
  SignupReferral = 'SIGNUP_REFERRAL',
  SubmitReferral = 'SUBMIT_REFERRAL'
}

/** A filter to be used against CreatedSource fields. All fields are combined with a logical ‘and.’ */
export type CreatedSourceFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<CreatedSource>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<CreatedSource>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<CreatedSource>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<CreatedSource>;
  /** Included in the specified list. */
  in: InputMaybe<Array<CreatedSource>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<CreatedSource>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<CreatedSource>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<CreatedSource>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<CreatedSource>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<CreatedSource>>;
};

export type Dashboard = Node & {
  /** Reads and enables pagination through a set of `Analytic`. */
  analyticsByDashboardId: Array<Analytic>;
  /** Reads a single `Company` that is related to this `Dashboard`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `Dashboard`. */
  createdByUser: Maybe<User>;
  customization: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `DashboardSharedRole`. */
  dashboardSharedRolesByDashboardId: Array<DashboardSharedRole>;
  /** Reads and enables pagination through a set of `DashboardSharedTeam`. */
  dashboardSharedTeamsByDashboardId: Array<DashboardSharedTeam>;
  /** Reads and enables pagination through a set of `DashboardSharedUser`. */
  dashboardSharedUsersByDashboardId: Array<DashboardSharedUser>;
  description: Maybe<Scalars['String']['output']>;
  emoji: Maybe<Scalars['String']['output']>;
  /** Reads a single `FilterFolder` that is related to this `Dashboard`. */
  filterFolder: Maybe<FilterFolder>;
  filterFolderId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  layout: Maybe<Scalars['JSON']['output']>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  privilege: Maybe<Scalars['String']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByDashboardSharedTeamDashboardIdAndTeamId: DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByDashboardSharedTeamDashboardIdAndTeamIdList: Array<Team>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type DashboardAnalyticsByDashboardIdArgs = {
  condition: InputMaybe<AnalyticCondition>;
  filter: InputMaybe<AnalyticFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AnalyticsOrderBy>>;
};


export type DashboardDashboardSharedRolesByDashboardIdArgs = {
  condition: InputMaybe<DashboardSharedRoleCondition>;
  filter: InputMaybe<DashboardSharedRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedRolesOrderBy>>;
};


export type DashboardDashboardSharedTeamsByDashboardIdArgs = {
  condition: InputMaybe<DashboardSharedTeamCondition>;
  filter: InputMaybe<DashboardSharedTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedTeamsOrderBy>>;
};


export type DashboardDashboardSharedUsersByDashboardIdArgs = {
  condition: InputMaybe<DashboardSharedUserCondition>;
  filter: InputMaybe<DashboardSharedUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedUsersOrderBy>>;
};


export type DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type DashboardAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<DashboardArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<DashboardAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<DashboardDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<DashboardMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<DashboardMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<DashboardStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<DashboardStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<DashboardSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<DashboardVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<DashboardVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Dashboard` object types. */
export type DashboardAggregatesFilter = {
  /** Array aggregation aggregate over matching `Dashboard` objects. */
  arrayAgg: InputMaybe<DashboardArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Dashboard` objects. */
  average: InputMaybe<DashboardAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Dashboard` objects. */
  distinctCount: InputMaybe<DashboardDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Dashboard` object to be included within the aggregate. */
  filter: InputMaybe<DashboardFilter>;
  /** Maximum aggregate over matching `Dashboard` objects. */
  max: InputMaybe<DashboardMaxAggregateFilter>;
  /** Minimum aggregate over matching `Dashboard` objects. */
  min: InputMaybe<DashboardMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Dashboard` objects. */
  stddevPopulation: InputMaybe<DashboardStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Dashboard` objects. */
  stddevSample: InputMaybe<DashboardStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Dashboard` objects. */
  sum: InputMaybe<DashboardSumAggregateFilter>;
  /** Population variance aggregate over matching `Dashboard` objects. */
  variancePopulation: InputMaybe<DashboardVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Dashboard` objects. */
  varianceSample: InputMaybe<DashboardVarianceSampleAggregateFilter>;
};

export type DashboardArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  emoji: InputMaybe<StringListFilter>;
  filterFolderId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  privilege: InputMaybe<StringListFilter>;
  scope: InputMaybe<StringListFilter>;
};

export type DashboardArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of emoji across the matching connection */
  emoji: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of filterFolderId across the matching connection */
  filterFolderId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of privilege across the matching connection */
  privilege: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of scope across the matching connection */
  scope: Array<Maybe<Scalars['String']['output']>>;
};


export type DashboardArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesEmojiArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardArrayAggAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type DashboardAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type DashboardAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardAverageAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

/**
 * A condition to be used against `Dashboard` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DashboardCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `customization` field. */
  customization: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emoji` field. */
  emoji: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `filterFolderId` field. */
  filterFolderId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `layout` field. */
  layout: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilege` field. */
  privilege: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type DashboardDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  customization: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  emoji: InputMaybe<BigIntFilter>;
  filterFolderId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  layout: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  privilege: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type DashboardDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of customization across the matching connection */
  customization: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emoji across the matching connection */
  emoji: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of layout across the matching connection */
  layout: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilege across the matching connection */
  privilege: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scope across the matching connection */
  scope: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type DashboardDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesCustomizationArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesEmojiArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesLayoutArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A filter to be used against `Dashboard` object types. All fields are combined with a logical ‘and.’ */
export type DashboardFilter = {
  /** Filter by the object’s `analyticsByDashboardId` relation. */
  analyticsByDashboardId: InputMaybe<DashboardToManyAnalyticFilter>;
  /** Some related `analyticsByDashboardId` exist. */
  analyticsByDashboardIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<DashboardFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `customization` field. */
  customization: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dashboardSharedRolesByDashboardId` relation. */
  dashboardSharedRolesByDashboardId: InputMaybe<DashboardToManyDashboardSharedRoleFilter>;
  /** Some related `dashboardSharedRolesByDashboardId` exist. */
  dashboardSharedRolesByDashboardIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboardSharedTeamsByDashboardId` relation. */
  dashboardSharedTeamsByDashboardId: InputMaybe<DashboardToManyDashboardSharedTeamFilter>;
  /** Some related `dashboardSharedTeamsByDashboardId` exist. */
  dashboardSharedTeamsByDashboardIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboardSharedUsersByDashboardId` relation. */
  dashboardSharedUsersByDashboardId: InputMaybe<DashboardToManyDashboardSharedUserFilter>;
  /** Some related `dashboardSharedUsersByDashboardId` exist. */
  dashboardSharedUsersByDashboardIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `emoji` field. */
  emoji: InputMaybe<StringFilter>;
  /** Filter by the object’s `filterFolder` relation. */
  filterFolder: InputMaybe<FilterFolderFilter>;
  /** A related `filterFolder` exists. */
  filterFolderExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterFolderId` field. */
  filterFolderId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `layout` field. */
  layout: InputMaybe<JsonFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<DashboardFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<DashboardFilter>>;
  /** Filter by the object’s `privilege` field. */
  privilege: InputMaybe<StringFilter>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Dashboard` for usage during aggregation. */
export enum DashboardGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Customization = 'CUSTOMIZATION',
  Description = 'DESCRIPTION',
  Emoji = 'EMOJI',
  FilterFolderId = 'FILTER_FOLDER_ID',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  Layout = 'LAYOUT',
  Name = 'NAME',
  Privilege = 'PRIVILEGE',
  Scope = 'SCOPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type DashboardHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Dashboard` aggregates. */
export type DashboardHavingInput = {
  AND: InputMaybe<Array<DashboardHavingInput>>;
  OR: InputMaybe<Array<DashboardHavingInput>>;
  arrayAgg: InputMaybe<DashboardHavingArrayAggInput>;
  average: InputMaybe<DashboardHavingAverageInput>;
  distinctCount: InputMaybe<DashboardHavingDistinctCountInput>;
  max: InputMaybe<DashboardHavingMaxInput>;
  min: InputMaybe<DashboardHavingMinInput>;
  stddevPopulation: InputMaybe<DashboardHavingStddevPopulationInput>;
  stddevSample: InputMaybe<DashboardHavingStddevSampleInput>;
  sum: InputMaybe<DashboardHavingSumInput>;
  variancePopulation: InputMaybe<DashboardHavingVariancePopulationInput>;
  varianceSample: InputMaybe<DashboardHavingVarianceSampleInput>;
};

export type DashboardHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type DashboardMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  filterFolderId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type DashboardMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type DashboardMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMaxAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  filterFolderId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type DashboardMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type DashboardMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMinAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardSharedRole = Node & {
  /** Reads a single `Dashboard` that is related to this `DashboardSharedRole`. */
  dashboard: Maybe<Dashboard>;
  dashboardId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `DashboardSharedRole`. */
  role: Maybe<Role>;
  roleId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `DashboardSharedRole` object types. */
export type DashboardSharedRoleAggregatesFilter = {
  /** Array aggregation aggregate over matching `DashboardSharedRole` objects. */
  arrayAgg: InputMaybe<DashboardSharedRoleArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `DashboardSharedRole` objects. */
  average: InputMaybe<DashboardSharedRoleAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DashboardSharedRole` objects. */
  distinctCount: InputMaybe<DashboardSharedRoleDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DashboardSharedRole` object to be included within the aggregate. */
  filter: InputMaybe<DashboardSharedRoleFilter>;
  /** Maximum aggregate over matching `DashboardSharedRole` objects. */
  max: InputMaybe<DashboardSharedRoleMaxAggregateFilter>;
  /** Minimum aggregate over matching `DashboardSharedRole` objects. */
  min: InputMaybe<DashboardSharedRoleMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DashboardSharedRole` objects. */
  stddevPopulation: InputMaybe<DashboardSharedRoleStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DashboardSharedRole` objects. */
  stddevSample: InputMaybe<DashboardSharedRoleStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DashboardSharedRole` objects. */
  sum: InputMaybe<DashboardSharedRoleSumAggregateFilter>;
  /** Population variance aggregate over matching `DashboardSharedRole` objects. */
  variancePopulation: InputMaybe<DashboardSharedRoleVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DashboardSharedRole` objects. */
  varianceSample: InputMaybe<DashboardSharedRoleVarianceSampleAggregateFilter>;
};

export type DashboardSharedRoleArrayAggAggregateFilter = {
  dashboardId: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
};

export type DashboardSharedRoleAverageAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `DashboardSharedRole` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DashboardSharedRoleCondition = {
  /** Checks for equality with the object’s `dashboardId` field. */
  dashboardId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardSharedRoleDistinctCountAggregateFilter = {
  dashboardId: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `DashboardSharedRole` object types. All fields are combined with a logical ‘and.’ */
export type DashboardSharedRoleFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<DashboardSharedRoleFilter>>;
  /** Filter by the object’s `dashboard` relation. */
  dashboard: InputMaybe<DashboardFilter>;
  /** Filter by the object’s `dashboardId` field. */
  dashboardId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<DashboardSharedRoleFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<DashboardSharedRoleFilter>>;
  /** Filter by the object’s `role` relation. */
  role: InputMaybe<RoleFilter>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
};

export type DashboardSharedRoleMaxAggregateFilter = {
  dashboardId: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
};

export type DashboardSharedRoleMinAggregateFilter = {
  dashboardId: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
};

export type DashboardSharedRoleStddevPopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedRoleStddevSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedRoleSumAggregateFilter = {
  dashboardId: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
};

export type DashboardSharedRoleVariancePopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedRoleVarianceSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `DashboardSharedRole`. */
export enum DashboardSharedRolesOrderBy {
  DashboardIdAsc = 'DASHBOARD_ID_ASC',
  DashboardIdDesc = 'DASHBOARD_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC'
}

export type DashboardSharedTeam = Node & {
  /** Reads a single `Dashboard` that is related to this `DashboardSharedTeam`. */
  dashboard: Maybe<Dashboard>;
  dashboardId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Team` that is related to this `DashboardSharedTeam`. */
  team: Maybe<Team>;
  teamId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `DashboardSharedTeam` object types. */
export type DashboardSharedTeamAggregatesFilter = {
  /** Array aggregation aggregate over matching `DashboardSharedTeam` objects. */
  arrayAgg: InputMaybe<DashboardSharedTeamArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `DashboardSharedTeam` objects. */
  average: InputMaybe<DashboardSharedTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DashboardSharedTeam` objects. */
  distinctCount: InputMaybe<DashboardSharedTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DashboardSharedTeam` object to be included within the aggregate. */
  filter: InputMaybe<DashboardSharedTeamFilter>;
  /** Maximum aggregate over matching `DashboardSharedTeam` objects. */
  max: InputMaybe<DashboardSharedTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `DashboardSharedTeam` objects. */
  min: InputMaybe<DashboardSharedTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DashboardSharedTeam` objects. */
  stddevPopulation: InputMaybe<DashboardSharedTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DashboardSharedTeam` objects. */
  stddevSample: InputMaybe<DashboardSharedTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DashboardSharedTeam` objects. */
  sum: InputMaybe<DashboardSharedTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `DashboardSharedTeam` objects. */
  variancePopulation: InputMaybe<DashboardSharedTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DashboardSharedTeam` objects. */
  varianceSample: InputMaybe<DashboardSharedTeamVarianceSampleAggregateFilter>;
};

export type DashboardSharedTeamArrayAggAggregateFilter = {
  dashboardId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
};

export type DashboardSharedTeamAverageAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `DashboardSharedTeam` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DashboardSharedTeamCondition = {
  /** Checks for equality with the object’s `dashboardId` field. */
  dashboardId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardSharedTeamDistinctCountAggregateFilter = {
  dashboardId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `DashboardSharedTeam` object types. All fields are combined with a logical ‘and.’ */
export type DashboardSharedTeamFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<DashboardSharedTeamFilter>>;
  /** Filter by the object’s `dashboard` relation. */
  dashboard: InputMaybe<DashboardFilter>;
  /** Filter by the object’s `dashboardId` field. */
  dashboardId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<DashboardSharedTeamFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<DashboardSharedTeamFilter>>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
};

export type DashboardSharedTeamMaxAggregateFilter = {
  dashboardId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type DashboardSharedTeamMinAggregateFilter = {
  dashboardId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type DashboardSharedTeamStddevPopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedTeamStddevSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedTeamSumAggregateFilter = {
  dashboardId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

export type DashboardSharedTeamVariancePopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedTeamVarianceSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `DashboardSharedTeam`. */
export enum DashboardSharedTeamsOrderBy {
  DashboardIdAsc = 'DASHBOARD_ID_ASC',
  DashboardIdDesc = 'DASHBOARD_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type DashboardSharedUser = Node & {
  createdAt: Scalars['Datetime']['output'];
  /** Reads a single `Dashboard` that is related to this `DashboardSharedUser`. */
  dashboard: Maybe<Dashboard>;
  dashboardId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `DashboardSharedUser`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `DashboardSharedUser` object types. */
export type DashboardSharedUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `DashboardSharedUser` objects. */
  arrayAgg: InputMaybe<DashboardSharedUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `DashboardSharedUser` objects. */
  average: InputMaybe<DashboardSharedUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DashboardSharedUser` objects. */
  distinctCount: InputMaybe<DashboardSharedUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DashboardSharedUser` object to be included within the aggregate. */
  filter: InputMaybe<DashboardSharedUserFilter>;
  /** Maximum aggregate over matching `DashboardSharedUser` objects. */
  max: InputMaybe<DashboardSharedUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `DashboardSharedUser` objects. */
  min: InputMaybe<DashboardSharedUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DashboardSharedUser` objects. */
  stddevPopulation: InputMaybe<DashboardSharedUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DashboardSharedUser` objects. */
  stddevSample: InputMaybe<DashboardSharedUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DashboardSharedUser` objects. */
  sum: InputMaybe<DashboardSharedUserSumAggregateFilter>;
  /** Population variance aggregate over matching `DashboardSharedUser` objects. */
  variancePopulation: InputMaybe<DashboardSharedUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DashboardSharedUser` objects. */
  varianceSample: InputMaybe<DashboardSharedUserVarianceSampleAggregateFilter>;
};

export type DashboardSharedUserArrayAggAggregateFilter = {
  dashboardId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type DashboardSharedUserAverageAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `DashboardSharedUser` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DashboardSharedUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `dashboardId` field. */
  dashboardId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardSharedUserDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  dashboardId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `DashboardSharedUser` object types. All fields are combined with a logical ‘and.’ */
export type DashboardSharedUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<DashboardSharedUserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dashboard` relation. */
  dashboard: InputMaybe<DashboardFilter>;
  /** Filter by the object’s `dashboardId` field. */
  dashboardId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<DashboardSharedUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<DashboardSharedUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type DashboardSharedUserMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  dashboardId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type DashboardSharedUserMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  dashboardId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type DashboardSharedUserStddevPopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedUserStddevSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedUserSumAggregateFilter = {
  dashboardId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type DashboardSharedUserVariancePopulationAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type DashboardSharedUserVarianceSampleAggregateFilter = {
  dashboardId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `DashboardSharedUser`. */
export enum DashboardSharedUsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DashboardIdAsc = 'DASHBOARD_ID_ASC',
  DashboardIdDesc = 'DASHBOARD_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type DashboardStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type DashboardStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type DashboardStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevPopulationAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type DashboardStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type DashboardStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevSampleAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  filterFolderId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type DashboardSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of filterFolderId across the matching connection */
  filterFolderId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type DashboardSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardSumAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `DashboardSharedTeam`. */
export type DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `DashboardSharedTeam`, and the cursor to aid in pagination. */
  edges: Array<DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `DashboardSharedTeam`. */
export type DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `DashboardSharedTeam`. */
export type DashboardTeamsByDashboardSharedTeamDashboardIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
};

/** A filter to be used against many `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type DashboardToManyAnalyticFilter = {
  /** Aggregates across related `Analytic` match the filter criteria. */
  aggregates: InputMaybe<AnalyticAggregatesFilter>;
  /** Every related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AnalyticFilter>;
  /** No related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AnalyticFilter>;
  /** Some related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AnalyticFilter>;
};

/** A filter to be used against many `DashboardSharedRole` object types. All fields are combined with a logical ‘and.’ */
export type DashboardToManyDashboardSharedRoleFilter = {
  /** Aggregates across related `DashboardSharedRole` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedRoleAggregatesFilter>;
  /** Every related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedRoleFilter>;
  /** No related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedRoleFilter>;
  /** Some related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedRoleFilter>;
};

/** A filter to be used against many `DashboardSharedTeam` object types. All fields are combined with a logical ‘and.’ */
export type DashboardToManyDashboardSharedTeamFilter = {
  /** Aggregates across related `DashboardSharedTeam` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedTeamAggregatesFilter>;
  /** Every related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedTeamFilter>;
  /** No related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedTeamFilter>;
  /** Some related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedTeamFilter>;
};

/** A filter to be used against many `DashboardSharedUser` object types. All fields are combined with a logical ‘and.’ */
export type DashboardToManyDashboardSharedUserFilter = {
  /** Aggregates across related `DashboardSharedUser` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedUserAggregatesFilter>;
  /** Every related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedUserFilter>;
  /** No related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedUserFilter>;
  /** Some related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedUserFilter>;
};

export type DashboardVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type DashboardVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type DashboardVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVariancePopulationAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

export type DashboardVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type DashboardVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type DashboardVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVarianceSampleAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type DashboardVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A connection to a list of `Dashboard` values. */
export type DashboardsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<DashboardAggregates>;
  /** A list of edges which contains the `Dashboard` and cursor to aid in pagination. */
  edges: Array<DashboardsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<DashboardAggregates>>;
  /** A list of `Dashboard` objects. */
  nodes: Array<Maybe<Dashboard>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Dashboard` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Dashboard` values. */
export type DashboardsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DashboardGroupBy>;
  having: InputMaybe<DashboardHavingInput>;
};

/** A `Dashboard` edge in the connection. */
export type DashboardsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Dashboard` at the end of the edge. */
  node: Maybe<Dashboard>;
};

/** Methods to use when ordering `Dashboard`. */
export enum DashboardsOrderBy {
  AnalyticsByDashboardIdConnectionArrayAggArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ARGS_ASC',
  AnalyticsByDashboardIdConnectionArrayAggArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ARGS_DESC',
  AnalyticsByDashboardIdConnectionArrayAggCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionArrayAggCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionArrayAggCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionArrayAggCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionArrayAggCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionArrayAggCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionArrayAggDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionArrayAggDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionArrayAggIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  AnalyticsByDashboardIdConnectionArrayAggIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  AnalyticsByDashboardIdConnectionArrayAggSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionArrayAggSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionArrayAggTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  AnalyticsByDashboardIdConnectionArrayAggTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  AnalyticsByDashboardIdConnectionArrayAggTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  AnalyticsByDashboardIdConnectionArrayAggTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  AnalyticsByDashboardIdConnectionArrayAggUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionArrayAggUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionAverageArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ARGS_ASC',
  AnalyticsByDashboardIdConnectionAverageArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ARGS_DESC',
  AnalyticsByDashboardIdConnectionAverageCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionAverageCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionAverageCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionAverageCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionAverageCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionAverageCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionAverageDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionAverageDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionAverageIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ID_ASC',
  AnalyticsByDashboardIdConnectionAverageIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ID_DESC',
  AnalyticsByDashboardIdConnectionAverageSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionAverageSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionAverageTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TITLE_ASC',
  AnalyticsByDashboardIdConnectionAverageTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TITLE_DESC',
  AnalyticsByDashboardIdConnectionAverageTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TYPE_ASC',
  AnalyticsByDashboardIdConnectionAverageTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TYPE_DESC',
  AnalyticsByDashboardIdConnectionAverageUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionAverageUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionCountAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_COUNT_ASC',
  AnalyticsByDashboardIdConnectionCountDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_COUNT_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ARGS_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ARGS_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  AnalyticsByDashboardIdConnectionDistinctCountUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionDistinctCountUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionMaxArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_ARGS_ASC',
  AnalyticsByDashboardIdConnectionMaxArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_ARGS_DESC',
  AnalyticsByDashboardIdConnectionMaxCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionMaxCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionMaxCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionMaxCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionMaxCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionMaxCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionMaxDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionMaxDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionMaxIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_ID_ASC',
  AnalyticsByDashboardIdConnectionMaxIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_ID_DESC',
  AnalyticsByDashboardIdConnectionMaxSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionMaxSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionMaxTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_TITLE_ASC',
  AnalyticsByDashboardIdConnectionMaxTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_TITLE_DESC',
  AnalyticsByDashboardIdConnectionMaxTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_TYPE_ASC',
  AnalyticsByDashboardIdConnectionMaxTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_TYPE_DESC',
  AnalyticsByDashboardIdConnectionMaxUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionMaxUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionMinArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_ARGS_ASC',
  AnalyticsByDashboardIdConnectionMinArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_ARGS_DESC',
  AnalyticsByDashboardIdConnectionMinCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionMinCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionMinCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionMinCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionMinCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionMinCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionMinDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionMinDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionMinIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_ID_ASC',
  AnalyticsByDashboardIdConnectionMinIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_ID_DESC',
  AnalyticsByDashboardIdConnectionMinSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionMinSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionMinTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_TITLE_ASC',
  AnalyticsByDashboardIdConnectionMinTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_TITLE_DESC',
  AnalyticsByDashboardIdConnectionMinTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_TYPE_ASC',
  AnalyticsByDashboardIdConnectionMinTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_TYPE_DESC',
  AnalyticsByDashboardIdConnectionMinUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionMinUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ARGS_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ARGS_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  AnalyticsByDashboardIdConnectionStddevPopulationUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionStddevPopulationUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ARGS_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ARGS_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  AnalyticsByDashboardIdConnectionStddevSampleUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionStddevSampleUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionSumArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_ARGS_ASC',
  AnalyticsByDashboardIdConnectionSumArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_ARGS_DESC',
  AnalyticsByDashboardIdConnectionSumCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionSumCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionSumCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionSumCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionSumCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionSumCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionSumDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionSumDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionSumIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_ID_ASC',
  AnalyticsByDashboardIdConnectionSumIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_ID_DESC',
  AnalyticsByDashboardIdConnectionSumSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionSumSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionSumTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_TITLE_ASC',
  AnalyticsByDashboardIdConnectionSumTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_TITLE_DESC',
  AnalyticsByDashboardIdConnectionSumTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_TYPE_ASC',
  AnalyticsByDashboardIdConnectionSumTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_TYPE_DESC',
  AnalyticsByDashboardIdConnectionSumUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionSumUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ARGS_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ARGS_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  AnalyticsByDashboardIdConnectionVariancePopulationUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionVariancePopulationUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleArgsAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ARGS_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleArgsDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ARGS_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleCompanyIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleCompanyIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleCreatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleCreatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleCreatedByAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleCreatedByDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleDashboardIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleDashboardIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleIdAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleIdDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleSubtitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleSubtitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleTitleAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleTitleDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleTypeAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleTypeDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  AnalyticsByDashboardIdConnectionVarianceSampleUpdatedAtAsc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnalyticsByDashboardIdConnectionVarianceSampleUpdatedAtDesc = 'ANALYTICS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CustomizationAsc = 'CUSTOMIZATION_ASC',
  CustomizationDesc = 'CUSTOMIZATION_DESC',
  DashboardSharedRolesByDashboardIdConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionArrayAggRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionArrayAggRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionAverageRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionAverageRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionCountAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_COUNT_ASC',
  DashboardSharedRolesByDashboardIdConnectionCountDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_COUNT_DESC',
  DashboardSharedRolesByDashboardIdConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionDistinctCountRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionDistinctCountRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionMaxRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MAX_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionMaxRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MAX_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionMinRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MIN_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionMinRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_MIN_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionStddevPopulationRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionStddevPopulationRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionStddevSampleRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionStddevSampleRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionSumRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_SUM_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionSumRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_SUM_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionVariancePopulationRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionVariancePopulationRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedRolesByDashboardIdConnectionVarianceSampleRoleIdAsc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  DashboardSharedRolesByDashboardIdConnectionVarianceSampleRoleIdDesc = 'DASHBOARD_SHARED_ROLES_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionArrayAggTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionArrayAggTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionAverageTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionAverageTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionCountAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_COUNT_ASC',
  DashboardSharedTeamsByDashboardIdConnectionCountDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_COUNT_DESC',
  DashboardSharedTeamsByDashboardIdConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionDistinctCountTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionDistinctCountTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionMaxTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MAX_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionMaxTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MAX_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionMinTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MIN_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionMinTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_MIN_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionStddevPopulationTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionStddevPopulationTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionStddevSampleTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionStddevSampleTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionSumTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_SUM_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionSumTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_SUM_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionVariancePopulationTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionVariancePopulationTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByDashboardIdConnectionVarianceSampleTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  DashboardSharedTeamsByDashboardIdConnectionVarianceSampleTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionArrayAggUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionAverageCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionAverageCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionAverageUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionAverageUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionAverageUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionAverageUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionCountAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_COUNT_ASC',
  DashboardSharedUsersByDashboardIdConnectionCountDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_COUNT_DESC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionDistinctCountUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionMaxCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionMaxCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionMaxUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionMaxUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionMaxUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionMaxUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MAX_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionMinCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionMinCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionMinUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionMinUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionMinUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionMinUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_MIN_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevPopulationUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionStddevSampleUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionSumCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionSumCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionSumUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionSumUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionSumUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionSumUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_SUM_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionVariancePopulationUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  DashboardSharedUsersByDashboardIdConnectionVarianceSampleUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_DASHBOARD_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EmojiAsc = 'EMOJI_ASC',
  EmojiDesc = 'EMOJI_DESC',
  FilterFolderIdAsc = 'FILTER_FOLDER_ID_ASC',
  FilterFolderIdDesc = 'FILTER_FOLDER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LayoutAsc = 'LAYOUT_ASC',
  LayoutDesc = 'LAYOUT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegeAsc = 'PRIVILEGE_ASC',
  PrivilegeDesc = 'PRIVILEGE_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

/** Methods to use when ordering `Dictionary`. */
export enum DictionariesOrderBy {
  CatalogItemsByCategoryIdConnectionArrayAggCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CODE_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CODE_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_COST_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_COST_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_SKU_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_SKU_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionArrayAggUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionArrayAggUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionAverageCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionAverageCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionAverageCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CODE_ASC',
  CatalogItemsByCategoryIdConnectionAverageCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CODE_DESC',
  CatalogItemsByCategoryIdConnectionAverageCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionAverageCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionAverageCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_COST_ASC',
  CatalogItemsByCategoryIdConnectionAverageCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_COST_DESC',
  CatalogItemsByCategoryIdConnectionAverageCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionAverageCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionAverageCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionAverageCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionAverageDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionAverageDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionAverageDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionAverageDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionAverageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionAverageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionAverageImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionAverageImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionAverageIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionAverageIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionAverageIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionAverageIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionAverageManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionAverageManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionAverageMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionAverageMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionAverageNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_NAME_ASC',
  CatalogItemsByCategoryIdConnectionAverageNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_NAME_DESC',
  CatalogItemsByCategoryIdConnectionAveragePriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionAveragePriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionAverageSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_SKU_ASC',
  CatalogItemsByCategoryIdConnectionAverageSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_SKU_DESC',
  CatalogItemsByCategoryIdConnectionAverageSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionAverageSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionAverageTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionAverageTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionAverageUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionAverageUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionCountAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_COUNT_ASC',
  CatalogItemsByCategoryIdConnectionCountDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_COUNT_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CODE_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CODE_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_COST_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_COST_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_SKU_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_SKU_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionDistinctCountUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionDistinctCountUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionMaxCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionMaxCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionMaxCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CODE_ASC',
  CatalogItemsByCategoryIdConnectionMaxCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CODE_DESC',
  CatalogItemsByCategoryIdConnectionMaxCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionMaxCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionMaxCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_COST_ASC',
  CatalogItemsByCategoryIdConnectionMaxCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_COST_DESC',
  CatalogItemsByCategoryIdConnectionMaxCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionMaxCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionMaxCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionMaxCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionMaxDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionMaxDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionMaxDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionMaxDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionMaxIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_ID_ASC',
  CatalogItemsByCategoryIdConnectionMaxIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_ID_DESC',
  CatalogItemsByCategoryIdConnectionMaxImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionMaxImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionMaxIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionMaxIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionMaxIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionMaxIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionMaxManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionMaxManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionMaxMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionMaxMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionMaxNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_NAME_ASC',
  CatalogItemsByCategoryIdConnectionMaxNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_NAME_DESC',
  CatalogItemsByCategoryIdConnectionMaxPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionMaxPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionMaxSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_SKU_ASC',
  CatalogItemsByCategoryIdConnectionMaxSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_SKU_DESC',
  CatalogItemsByCategoryIdConnectionMaxSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionMaxSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionMaxTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionMaxTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionMaxUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionMaxUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionMinCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionMinCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionMinCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CODE_ASC',
  CatalogItemsByCategoryIdConnectionMinCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CODE_DESC',
  CatalogItemsByCategoryIdConnectionMinCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionMinCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionMinCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_COST_ASC',
  CatalogItemsByCategoryIdConnectionMinCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_COST_DESC',
  CatalogItemsByCategoryIdConnectionMinCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionMinCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionMinCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionMinCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionMinDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionMinDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionMinDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionMinDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionMinIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_ID_ASC',
  CatalogItemsByCategoryIdConnectionMinIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_ID_DESC',
  CatalogItemsByCategoryIdConnectionMinImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionMinImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionMinIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionMinIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionMinIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionMinIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionMinManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionMinManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionMinMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionMinMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionMinNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_NAME_ASC',
  CatalogItemsByCategoryIdConnectionMinNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_NAME_DESC',
  CatalogItemsByCategoryIdConnectionMinPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionMinPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionMinSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_SKU_ASC',
  CatalogItemsByCategoryIdConnectionMinSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_SKU_DESC',
  CatalogItemsByCategoryIdConnectionMinSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionMinSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionMinTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionMinTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionMinUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionMinUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CODE_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CODE_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_COST_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_COST_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_SKU_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_SKU_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionStddevPopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionStddevPopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CODE_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CODE_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_COST_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_COST_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  CatalogItemsByCategoryIdConnectionStddevSamplePriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionStddevSamplePriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_SKU_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_SKU_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionStddevSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionStddevSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionSumCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionSumCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionSumCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CODE_ASC',
  CatalogItemsByCategoryIdConnectionSumCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CODE_DESC',
  CatalogItemsByCategoryIdConnectionSumCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionSumCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionSumCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_COST_ASC',
  CatalogItemsByCategoryIdConnectionSumCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_COST_DESC',
  CatalogItemsByCategoryIdConnectionSumCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionSumCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionSumCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionSumCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionSumDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionSumDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionSumDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionSumDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionSumIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_ID_ASC',
  CatalogItemsByCategoryIdConnectionSumIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_ID_DESC',
  CatalogItemsByCategoryIdConnectionSumImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionSumImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionSumIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionSumIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionSumIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionSumIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionSumManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionSumManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionSumMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionSumMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionSumNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_NAME_ASC',
  CatalogItemsByCategoryIdConnectionSumNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_NAME_DESC',
  CatalogItemsByCategoryIdConnectionSumPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionSumPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionSumSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_SKU_ASC',
  CatalogItemsByCategoryIdConnectionSumSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_SKU_DESC',
  CatalogItemsByCategoryIdConnectionSumSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionSumSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionSumTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionSumTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionSumUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionSumUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CODE_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CODE_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_COST_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_COST_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationPriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationPriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_SKU_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_SKU_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionVariancePopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionVariancePopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCodeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CODE_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCodeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CODE_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCostAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_COST_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCostDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_COST_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCreatedByAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleCreatedByDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleDatasheetAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleDatasheetDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleDescriptionAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleDescriptionDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleImageIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleImageIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIsCustomAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleIsCustomDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleManufacturerAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleManufacturerDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleNameAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleNameDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSamplePriceAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSamplePriceDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleSkuAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_SKU_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleSkuDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_SKU_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleSpecAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleSpecDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleTypeAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleTypeDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CatalogItemsByCategoryIdConnectionVarianceSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByCategoryIdConnectionVarianceSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_CATEGORY_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ParentCodeAsc = 'PARENT_CODE_ASC',
  ParentCodeDesc = 'PARENT_CODE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type Dictionary = Node & {
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByCategoryId: Array<CatalogItem>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByCategoryIdConnection: CatalogItemsConnection;
  code: Scalars['String']['output'];
  createdAt: Scalars['Datetime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  parentCode: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
  value: Scalars['String']['output'];
};


export type DictionaryCatalogItemsByCategoryIdArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type DictionaryCatalogItemsByCategoryIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};

/**
 * A condition to be used against `Dictionary` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DictionaryCondition = {
  /** Checks for equality with the object’s `code` field. */
  code: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `parentCode` field. */
  parentCode: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `Dictionary` object types. All fields are combined with a logical ‘and.’ */
export type DictionaryFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<DictionaryFilter>>;
  /** Filter by the object’s `catalogItemsByCategoryId` relation. */
  catalogItemsByCategoryId: InputMaybe<DictionaryToManyCatalogItemFilter>;
  /** Some related `catalogItemsByCategoryId` exist. */
  catalogItemsByCategoryIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `code` field. */
  code: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<DictionaryFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<DictionaryFilter>>;
  /** Filter by the object’s `parentCode` field. */
  parentCode: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value: InputMaybe<StringFilter>;
};

/** A filter to be used against many `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type DictionaryToManyCatalogItemFilter = {
  /** Aggregates across related `CatalogItem` match the filter criteria. */
  aggregates: InputMaybe<CatalogItemAggregatesFilter>;
  /** Every related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CatalogItemFilter>;
  /** No related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CatalogItemFilter>;
  /** Some related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CatalogItemFilter>;
};

export type EmailAccount = Node & {
  alias: Maybe<Scalars['String']['output']>;
  /** Reads a single `Company` that is related to this `EmailAccount`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `EmailAccountUser`. */
  emailAccountUsers: Array<EmailAccountUser>;
  emailAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  nylasAccountId: Scalars['String']['output'];
  nylasClientId: Maybe<Scalars['String']['output']>;
  nylasProvider: Maybe<Scalars['String']['output']>;
  nylasStatus: Maybe<EmailAccountStatus>;
  nylasV2AccountId: Maybe<Scalars['String']['output']>;
  nylasV2ClientId: Maybe<Scalars['String']['output']>;
  provider: Maybe<Scalars['String']['output']>;
  status: EmailAccountStatus;
  statusSince: Scalars['Datetime']['output'];
};


export type EmailAccountEmailAccountUsersArgs = {
  condition: InputMaybe<EmailAccountUserCondition>;
  filter: InputMaybe<EmailAccountUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountUsersOrderBy>>;
};

/** A filter to be used against aggregates of `EmailAccount` object types. */
export type EmailAccountAggregatesFilter = {
  /** Array aggregation aggregate over matching `EmailAccount` objects. */
  arrayAgg: InputMaybe<EmailAccountArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `EmailAccount` objects. */
  average: InputMaybe<EmailAccountAverageAggregateFilter>;
  /** Distinct count aggregate over matching `EmailAccount` objects. */
  distinctCount: InputMaybe<EmailAccountDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `EmailAccount` object to be included within the aggregate. */
  filter: InputMaybe<EmailAccountFilter>;
  /** Maximum aggregate over matching `EmailAccount` objects. */
  max: InputMaybe<EmailAccountMaxAggregateFilter>;
  /** Minimum aggregate over matching `EmailAccount` objects. */
  min: InputMaybe<EmailAccountMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `EmailAccount` objects. */
  stddevPopulation: InputMaybe<EmailAccountStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EmailAccount` objects. */
  stddevSample: InputMaybe<EmailAccountStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `EmailAccount` objects. */
  sum: InputMaybe<EmailAccountSumAggregateFilter>;
  /** Population variance aggregate over matching `EmailAccount` objects. */
  variancePopulation: InputMaybe<EmailAccountVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EmailAccount` objects. */
  varianceSample: InputMaybe<EmailAccountVarianceSampleAggregateFilter>;
};

export type EmailAccountArrayAggAggregateFilter = {
  alias: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  emailAddress: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  nylasAccountId: InputMaybe<StringListFilter>;
  nylasClientId: InputMaybe<StringListFilter>;
  nylasProvider: InputMaybe<StringListFilter>;
  nylasV2AccountId: InputMaybe<StringListFilter>;
  nylasV2ClientId: InputMaybe<StringListFilter>;
  provider: InputMaybe<StringListFilter>;
};

export type EmailAccountAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `EmailAccount` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailAccountCondition = {
  /** Checks for equality with the object’s `alias` field. */
  alias: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailAddress` field. */
  emailAddress: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `nylasAccountId` field. */
  nylasAccountId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasClientId` field. */
  nylasClientId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasProvider` field. */
  nylasProvider: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasStatus` field. */
  nylasStatus: InputMaybe<EmailAccountStatus>;
  /** Checks for equality with the object’s `nylasV2AccountId` field. */
  nylasV2AccountId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasV2ClientId` field. */
  nylasV2ClientId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `provider` field. */
  provider: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<EmailAccountStatus>;
  /** Checks for equality with the object’s `statusSince` field. */
  statusSince: InputMaybe<Scalars['Datetime']['input']>;
};

export type EmailAccountDistinctCountAggregateFilter = {
  alias: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  emailAddress: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  nylasAccountId: InputMaybe<BigIntFilter>;
  nylasClientId: InputMaybe<BigIntFilter>;
  nylasProvider: InputMaybe<BigIntFilter>;
  nylasStatus: InputMaybe<BigIntFilter>;
  nylasV2AccountId: InputMaybe<BigIntFilter>;
  nylasV2ClientId: InputMaybe<BigIntFilter>;
  provider: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  statusSince: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `EmailAccount` object types. All fields are combined with a logical ‘and.’ */
export type EmailAccountFilter = {
  /** Filter by the object’s `alias` field. */
  alias: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<EmailAccountFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailAccountUsers` relation. */
  emailAccountUsers: InputMaybe<EmailAccountToManyEmailAccountUserFilter>;
  /** Some related `emailAccountUsers` exist. */
  emailAccountUsersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailAddress` field. */
  emailAddress: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<EmailAccountFilter>;
  /** Filter by the object’s `nylasAccountId` field. */
  nylasAccountId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasClientId` field. */
  nylasClientId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasProvider` field. */
  nylasProvider: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasStatus` field. */
  nylasStatus: InputMaybe<EmailAccountStatusFilter>;
  /** Filter by the object’s `nylasV2AccountId` field. */
  nylasV2AccountId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasV2ClientId` field. */
  nylasV2ClientId: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<EmailAccountFilter>>;
  /** Filter by the object’s `provider` field. */
  provider: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<EmailAccountStatusFilter>;
  /** Filter by the object’s `statusSince` field. */
  statusSince: InputMaybe<DatetimeFilter>;
};

export type EmailAccountMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  statusSince: InputMaybe<DatetimeFilter>;
};

export type EmailAccountMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  statusSince: InputMaybe<DatetimeFilter>;
};

export enum EmailAccountStatus {
  Invalid = 'INVALID',
  Synced = 'SYNCED',
  Syncing = 'SYNCING'
}

/** A filter to be used against EmailAccountStatus fields. All fields are combined with a logical ‘and.’ */
export type EmailAccountStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<EmailAccountStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<EmailAccountStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<EmailAccountStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<EmailAccountStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<EmailAccountStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<EmailAccountStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<EmailAccountStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<EmailAccountStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<EmailAccountStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<EmailAccountStatus>>;
};

export type EmailAccountStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type EmailAccountStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type EmailAccountSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `EmailAccountUser` object types. All fields are combined with a logical ‘and.’ */
export type EmailAccountToManyEmailAccountUserFilter = {
  /** Aggregates across related `EmailAccountUser` match the filter criteria. */
  aggregates: InputMaybe<EmailAccountUserAggregatesFilter>;
  /** Every related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<EmailAccountUserFilter>;
  /** No related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<EmailAccountUserFilter>;
  /** Some related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<EmailAccountUserFilter>;
};

export type EmailAccountUser = Node & {
  canReceiveAll: Scalars['Boolean']['output'];
  canSend: Scalars['Boolean']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `EmailAccountUser`. */
  createdByUser: Maybe<MainUser>;
  /** Reads a single `EmailAccount` that is related to this `EmailAccountUser`. */
  emailAccount: Maybe<EmailAccount>;
  emailAccountId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `EmailAccountUser`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `EmailAccountUser` object types. */
export type EmailAccountUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `EmailAccountUser` objects. */
  arrayAgg: InputMaybe<EmailAccountUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `EmailAccountUser` objects. */
  average: InputMaybe<EmailAccountUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `EmailAccountUser` objects. */
  distinctCount: InputMaybe<EmailAccountUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `EmailAccountUser` object to be included within the aggregate. */
  filter: InputMaybe<EmailAccountUserFilter>;
  /** Maximum aggregate over matching `EmailAccountUser` objects. */
  max: InputMaybe<EmailAccountUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `EmailAccountUser` objects. */
  min: InputMaybe<EmailAccountUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `EmailAccountUser` objects. */
  stddevPopulation: InputMaybe<EmailAccountUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EmailAccountUser` objects. */
  stddevSample: InputMaybe<EmailAccountUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `EmailAccountUser` objects. */
  sum: InputMaybe<EmailAccountUserSumAggregateFilter>;
  /** Population variance aggregate over matching `EmailAccountUser` objects. */
  variancePopulation: InputMaybe<EmailAccountUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EmailAccountUser` objects. */
  varianceSample: InputMaybe<EmailAccountUserVarianceSampleAggregateFilter>;
};

export type EmailAccountUserArrayAggAggregateFilter = {
  canReceiveAll: InputMaybe<BooleanListFilter>;
  canSend: InputMaybe<BooleanListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  emailAccountId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type EmailAccountUserAverageAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  emailAccountId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `EmailAccountUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailAccountUserCondition = {
  /** Checks for equality with the object’s `canReceiveAll` field. */
  canReceiveAll: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `canSend` field. */
  canSend: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `emailAccountId` field. */
  emailAccountId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type EmailAccountUserDistinctCountAggregateFilter = {
  canReceiveAll: InputMaybe<BigIntFilter>;
  canSend: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  emailAccountId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `EmailAccountUser` object types. All fields are combined with a logical ‘and.’ */
export type EmailAccountUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<EmailAccountUserFilter>>;
  /** Filter by the object’s `canReceiveAll` field. */
  canReceiveAll: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `canSend` field. */
  canSend: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailAccount` relation. */
  emailAccount: InputMaybe<EmailAccountFilter>;
  /** Filter by the object’s `emailAccountId` field. */
  emailAccountId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<EmailAccountUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<EmailAccountUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type EmailAccountUserMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  emailAccountId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type EmailAccountUserMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  emailAccountId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type EmailAccountUserStddevPopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  emailAccountId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type EmailAccountUserStddevSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  emailAccountId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type EmailAccountUserSumAggregateFilter = {
  createdBy: InputMaybe<BigIntFilter>;
  emailAccountId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type EmailAccountUserVariancePopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  emailAccountId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type EmailAccountUserVarianceSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  emailAccountId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `EmailAccountUser`. */
export enum EmailAccountUsersOrderBy {
  CanReceiveAllAsc = 'CAN_RECEIVE_ALL_ASC',
  CanReceiveAllDesc = 'CAN_RECEIVE_ALL_DESC',
  CanSendAsc = 'CAN_SEND_ASC',
  CanSendDesc = 'CAN_SEND_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmailAccountIdAsc = 'EMAIL_ACCOUNT_ID_ASC',
  EmailAccountIdDesc = 'EMAIL_ACCOUNT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type EmailAccountVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type EmailAccountVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `EmailAccount`. */
export enum EmailAccountsOrderBy {
  AliasAsc = 'ALIAS_ASC',
  AliasDesc = 'ALIAS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAccountUsersConnectionArrayAggCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionArrayAggCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionArrayAggCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  EmailAccountUsersConnectionArrayAggCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  EmailAccountUsersConnectionArrayAggCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  EmailAccountUsersConnectionArrayAggCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  EmailAccountUsersConnectionArrayAggCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  EmailAccountUsersConnectionArrayAggCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  EmailAccountUsersConnectionArrayAggEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionArrayAggEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionArrayAggUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  EmailAccountUsersConnectionArrayAggUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  EmailAccountUsersConnectionArrayAggUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  EmailAccountUsersConnectionArrayAggUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  EmailAccountUsersConnectionAverageCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionAverageCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionAverageCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CAN_SEND_ASC',
  EmailAccountUsersConnectionAverageCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CAN_SEND_DESC',
  EmailAccountUsersConnectionAverageCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  EmailAccountUsersConnectionAverageCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  EmailAccountUsersConnectionAverageCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  EmailAccountUsersConnectionAverageCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  EmailAccountUsersConnectionAverageEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionAverageEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionAverageUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  EmailAccountUsersConnectionAverageUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  EmailAccountUsersConnectionAverageUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_USER_ID_ASC',
  EmailAccountUsersConnectionAverageUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_AVERAGE_USER_ID_DESC',
  EmailAccountUsersConnectionCountAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_COUNT_ASC',
  EmailAccountUsersConnectionCountDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_COUNT_DESC',
  EmailAccountUsersConnectionDistinctCountCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionDistinctCountCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionDistinctCountCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  EmailAccountUsersConnectionDistinctCountCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  EmailAccountUsersConnectionDistinctCountCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  EmailAccountUsersConnectionDistinctCountCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  EmailAccountUsersConnectionDistinctCountCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  EmailAccountUsersConnectionDistinctCountCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  EmailAccountUsersConnectionDistinctCountEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionDistinctCountEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionDistinctCountUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmailAccountUsersConnectionDistinctCountUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmailAccountUsersConnectionDistinctCountUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  EmailAccountUsersConnectionDistinctCountUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  EmailAccountUsersConnectionMaxCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionMaxCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionMaxCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CAN_SEND_ASC',
  EmailAccountUsersConnectionMaxCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CAN_SEND_DESC',
  EmailAccountUsersConnectionMaxCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CREATED_AT_ASC',
  EmailAccountUsersConnectionMaxCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CREATED_AT_DESC',
  EmailAccountUsersConnectionMaxCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CREATED_BY_ASC',
  EmailAccountUsersConnectionMaxCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_CREATED_BY_DESC',
  EmailAccountUsersConnectionMaxEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionMaxEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionMaxUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_UPDATED_AT_ASC',
  EmailAccountUsersConnectionMaxUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_UPDATED_AT_DESC',
  EmailAccountUsersConnectionMaxUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_USER_ID_ASC',
  EmailAccountUsersConnectionMaxUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MAX_USER_ID_DESC',
  EmailAccountUsersConnectionMinCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionMinCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionMinCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CAN_SEND_ASC',
  EmailAccountUsersConnectionMinCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CAN_SEND_DESC',
  EmailAccountUsersConnectionMinCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CREATED_AT_ASC',
  EmailAccountUsersConnectionMinCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CREATED_AT_DESC',
  EmailAccountUsersConnectionMinCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CREATED_BY_ASC',
  EmailAccountUsersConnectionMinCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_CREATED_BY_DESC',
  EmailAccountUsersConnectionMinEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionMinEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionMinUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_UPDATED_AT_ASC',
  EmailAccountUsersConnectionMinUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_UPDATED_AT_DESC',
  EmailAccountUsersConnectionMinUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_USER_ID_ASC',
  EmailAccountUsersConnectionMinUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_MIN_USER_ID_DESC',
  EmailAccountUsersConnectionStddevPopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionStddevPopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionStddevPopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersConnectionStddevPopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersConnectionStddevPopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersConnectionStddevPopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersConnectionStddevPopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersConnectionStddevPopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersConnectionStddevPopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionStddevPopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionStddevPopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersConnectionStddevPopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersConnectionStddevPopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  EmailAccountUsersConnectionStddevPopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  EmailAccountUsersConnectionStddevSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionStddevSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionStddevSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersConnectionStddevSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersConnectionStddevSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersConnectionStddevSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersConnectionStddevSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersConnectionStddevSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersConnectionStddevSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionStddevSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionStddevSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersConnectionStddevSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersConnectionStddevSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  EmailAccountUsersConnectionStddevSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  EmailAccountUsersConnectionSumCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionSumCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionSumCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CAN_SEND_ASC',
  EmailAccountUsersConnectionSumCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CAN_SEND_DESC',
  EmailAccountUsersConnectionSumCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CREATED_AT_ASC',
  EmailAccountUsersConnectionSumCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CREATED_AT_DESC',
  EmailAccountUsersConnectionSumCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CREATED_BY_ASC',
  EmailAccountUsersConnectionSumCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_CREATED_BY_DESC',
  EmailAccountUsersConnectionSumEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionSumEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionSumUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_UPDATED_AT_ASC',
  EmailAccountUsersConnectionSumUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_UPDATED_AT_DESC',
  EmailAccountUsersConnectionSumUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_USER_ID_ASC',
  EmailAccountUsersConnectionSumUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_SUM_USER_ID_DESC',
  EmailAccountUsersConnectionVariancePopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionVariancePopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionVariancePopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersConnectionVariancePopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersConnectionVariancePopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersConnectionVariancePopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersConnectionVariancePopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersConnectionVariancePopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersConnectionVariancePopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionVariancePopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionVariancePopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersConnectionVariancePopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersConnectionVariancePopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  EmailAccountUsersConnectionVariancePopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  EmailAccountUsersConnectionVarianceSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersConnectionVarianceSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersConnectionVarianceSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersConnectionVarianceSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersConnectionVarianceSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersConnectionVarianceSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersConnectionVarianceSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersConnectionVarianceSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersConnectionVarianceSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersConnectionVarianceSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersConnectionVarianceSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersConnectionVarianceSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersConnectionVarianceSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  EmailAccountUsersConnectionVarianceSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  EmailAddressAsc = 'EMAIL_ADDRESS_ASC',
  EmailAddressDesc = 'EMAIL_ADDRESS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NylasAccountIdAsc = 'NYLAS_ACCOUNT_ID_ASC',
  NylasAccountIdDesc = 'NYLAS_ACCOUNT_ID_DESC',
  NylasClientIdAsc = 'NYLAS_CLIENT_ID_ASC',
  NylasClientIdDesc = 'NYLAS_CLIENT_ID_DESC',
  NylasProviderAsc = 'NYLAS_PROVIDER_ASC',
  NylasProviderDesc = 'NYLAS_PROVIDER_DESC',
  NylasStatusAsc = 'NYLAS_STATUS_ASC',
  NylasStatusDesc = 'NYLAS_STATUS_DESC',
  NylasV2AccountIdAsc = 'NYLAS_V2_ACCOUNT_ID_ASC',
  NylasV2AccountIdDesc = 'NYLAS_V2_ACCOUNT_ID_DESC',
  NylasV2ClientIdAsc = 'NYLAS_V2_CLIENT_ID_ASC',
  NylasV2ClientIdDesc = 'NYLAS_V2_CLIENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderDesc = 'PROVIDER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StatusSinceAsc = 'STATUS_SINCE_ASC',
  StatusSinceDesc = 'STATUS_SINCE_DESC'
}

export type EmailMessage = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedEmailMessageIdAndActionId: EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedEmailMessageIdAndActionIdList: Array<Action>;
  attachments: Scalars['JSON']['output'];
  bcc: Array<Maybe<Scalars['String']['output']>>;
  body: Scalars['String']['output'];
  bodyCleaned: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedEmailMessageIdAndCallId: EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedEmailMessageIdAndCallIdList: Array<Call>;
  cc: Array<Maybe<Scalars['String']['output']>>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedEmailMessageIdAndCommentId: EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedEmailMessageIdAndCreatedByContactId: EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedEmailMessageIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  emailAccountId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedEmailMessageIdAndParentId: EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedEmailMessageIdAndFileId: EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedEmailMessageIdAndFileIdList: Array<File>;
  from: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isOutbox: Maybe<Scalars['Boolean']['output']>;
  isScheduled: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  nylasMessageId: Scalars['String']['output'];
  nylasThreadId: Maybe<Scalars['String']['output']>;
  nylasV2MessageId: Maybe<Scalars['String']['output']>;
  nylasV2ThreadId: Maybe<Scalars['String']['output']>;
  originProjectId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedEmailMessageIdAndTaskId: EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedEmailMessageIdAndProjectId: EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedEmailMessageIdAndProjectIdList: Array<Project>;
  receivedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedEmailMessageIdAndReminderId: EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedEmailMessageIdAndReminderIdList: Array<Reminder>;
  scheduledAt: Maybe<Scalars['Datetime']['output']>;
  sendJobId: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedEmailMessageIdAndSmsActivityIdList: Array<SmsActivity>;
  subject: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedEmailMessageIdAndSystemId: EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyConnection;
  to: Array<Maybe<Scalars['String']['output']>>;
  tracking: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedEmailMessageIdAndCreatedBy: EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyConnection;
};


export type EmailMessageActionsByFeedEmailMessageIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type EmailMessageActionsByFeedEmailMessageIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type EmailMessageCallsByFeedEmailMessageIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type EmailMessageCallsByFeedEmailMessageIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type EmailMessageCommentsByFeedEmailMessageIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type EmailMessageFeedsByFeedEmailMessageIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type EmailMessageFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type EmailMessageFilesByFeedEmailMessageIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type EmailMessageFilesByFeedEmailMessageIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type EmailMessageSmsActivitiesByFeedEmailMessageIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type EmailMessageSystemsByFeedEmailMessageIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type EmailMessageUsersByFeedEmailMessageIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type EmailMessageActionsByFeedEmailMessageIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type EmailMessageCallsByFeedEmailMessageIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type EmailMessageCommentsByFeedEmailMessageIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/**
 * A condition to be used against `EmailMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailMessageCondition = {
  /** Checks for equality with the object’s `attachments` field. */
  attachments: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `bcc` field. */
  bcc: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `body` field. */
  body: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bodyCleaned` field. */
  bodyCleaned: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `cc` field. */
  cc: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `emailAccountId` field. */
  emailAccountId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `from` field. */
  from: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isOutbox` field. */
  isOutbox: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isScheduled` field. */
  isScheduled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `nylasMessageId` field. */
  nylasMessageId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasThreadId` field. */
  nylasThreadId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasV2MessageId` field. */
  nylasV2MessageId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasV2ThreadId` field. */
  nylasV2ThreadId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `originProjectId` field. */
  originProjectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `receivedAt` field. */
  receivedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `scheduledAt` field. */
  scheduledAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `sendJobId` field. */
  sendJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subject` field. */
  subject: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `to` field. */
  to: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `tracking` field. */
  tracking: InputMaybe<Scalars['JSON']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type EmailMessageContactsByFeedEmailMessageIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type EmailMessageFeedsByFeedEmailMessageIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type EmailMessageFilesByFeedEmailMessageIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `EmailMessage` object types. All fields are combined with a logical ‘and.’ */
export type EmailMessageFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<EmailMessageFilter>>;
  /** Filter by the object’s `attachments` field. */
  attachments: InputMaybe<JsonFilter>;
  /** Filter by the object’s `bcc` field. */
  bcc: InputMaybe<StringListFilter>;
  /** Filter by the object’s `body` field. */
  body: InputMaybe<StringFilter>;
  /** Filter by the object’s `bodyCleaned` field. */
  bodyCleaned: InputMaybe<StringFilter>;
  /** Filter by the object’s `cc` field. */
  cc: InputMaybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `emailAccountId` field. */
  emailAccountId: InputMaybe<IntFilter>;
  /** Filter by the object’s `feeds` relation. */
  feeds: InputMaybe<EmailMessageToManyFeedFilter>;
  /** Some related `feeds` exist. */
  feedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `from` field. */
  from: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isOutbox` field. */
  isOutbox: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isScheduled` field. */
  isScheduled: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<EmailMessageFilter>;
  /** Filter by the object’s `nylasMessageId` field. */
  nylasMessageId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasThreadId` field. */
  nylasThreadId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasV2MessageId` field. */
  nylasV2MessageId: InputMaybe<StringFilter>;
  /** Filter by the object’s `nylasV2ThreadId` field. */
  nylasV2ThreadId: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<EmailMessageFilter>>;
  /** Filter by the object’s `originProjectId` field. */
  originProjectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `receivedAt` field. */
  receivedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `scheduledAt` field. */
  scheduledAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sendJobId` field. */
  sendJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `subject` field. */
  subject: InputMaybe<StringFilter>;
  /** Filter by the object’s `to` field. */
  to: InputMaybe<StringListFilter>;
  /** Filter by the object’s `tracking` field. */
  tracking: InputMaybe<JsonFilter>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type EmailMessagePrivilegedTasksByFeedEmailMessageIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type EmailMessageProjectsByFeedEmailMessageIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type EmailMessageRemindersByFeedEmailMessageIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type EmailMessageSystemsByFeedEmailMessageIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type EmailMessageToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type EmailMessageUsersByFeedEmailMessageIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** Methods to use when ordering `EmailMessage`. */
export enum EmailMessagesOrderBy {
  AttachmentsAsc = 'ATTACHMENTS_ASC',
  AttachmentsDesc = 'ATTACHMENTS_DESC',
  BccAsc = 'BCC_ASC',
  BccDesc = 'BCC_DESC',
  BodyAsc = 'BODY_ASC',
  BodyCleanedAsc = 'BODY_CLEANED_ASC',
  BodyCleanedDesc = 'BODY_CLEANED_DESC',
  BodyDesc = 'BODY_DESC',
  CcAsc = 'CC_ASC',
  CcDesc = 'CC_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmailAccountIdAsc = 'EMAIL_ACCOUNT_ID_ASC',
  EmailAccountIdDesc = 'EMAIL_ACCOUNT_ID_DESC',
  FeedsConnectionArrayAggActionIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsConnectionArrayAggActionIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsConnectionArrayAggCallIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsConnectionArrayAggCallIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsConnectionArrayAggCommentAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsConnectionArrayAggCommentDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsConnectionArrayAggCommentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsConnectionArrayAggCommentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsConnectionArrayAggCompanyIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsConnectionArrayAggCompanyIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsConnectionArrayAggCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsConnectionArrayAggCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsConnectionArrayAggCreatedByAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionArrayAggCreatedByDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsConnectionArrayAggCreatedByTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsConnectionArrayAggCreatedByTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggEmailMessageIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionArrayAggEmailMessageIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionArrayAggEventAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsConnectionArrayAggEventDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsConnectionArrayAggEventTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsConnectionArrayAggEventTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsConnectionArrayAggFileIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsConnectionArrayAggFileIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsConnectionArrayAggIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsConnectionArrayAggIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsConnectionArrayAggParentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsConnectionArrayAggParentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsConnectionArrayAggPayloadAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsConnectionArrayAggPayloadDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsConnectionArrayAggProjectIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsConnectionArrayAggProjectIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsConnectionArrayAggReminderIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsConnectionArrayAggReminderIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsConnectionArrayAggRetriedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsConnectionArrayAggRetriedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsConnectionArrayAggRetryableAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsConnectionArrayAggRetryableDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsConnectionArrayAggRetryCountAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsConnectionArrayAggRetryCountDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsConnectionArrayAggSearchTextAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsConnectionArrayAggSearchTextDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsConnectionArrayAggSmsActivityIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionArrayAggSmsActivityIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionArrayAggSystemIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsConnectionArrayAggSystemIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsConnectionArrayAggTaskIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsConnectionArrayAggTaskIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionArrayAggWorkflowIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsConnectionArrayAggWorkflowIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsConnectionAverageActionIdAsc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsConnectionAverageActionIdDesc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsConnectionAverageCallIdAsc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsConnectionAverageCallIdDesc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsConnectionAverageCommentAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsConnectionAverageCommentDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsConnectionAverageCommentIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsConnectionAverageCommentIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsConnectionAverageCompanyIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsConnectionAverageCompanyIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsConnectionAverageCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsConnectionAverageCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsConnectionAverageCreatedByAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsConnectionAverageCreatedByContactIdAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionAverageCreatedByContactIdDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionAverageCreatedByDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsConnectionAverageCreatedByTypeAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsConnectionAverageCreatedByTypeDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsConnectionAverageEmailMessageIdAsc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionAverageEmailMessageIdDesc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionAverageEventAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsConnectionAverageEventDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsConnectionAverageEventTypeAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsConnectionAverageEventTypeDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsConnectionAverageFileIdAsc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsConnectionAverageFileIdDesc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsConnectionAverageIdAsc = 'FEEDS_CONNECTION_AVERAGE_ID_ASC',
  FeedsConnectionAverageIdDesc = 'FEEDS_CONNECTION_AVERAGE_ID_DESC',
  FeedsConnectionAverageParentIdAsc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsConnectionAverageParentIdDesc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsConnectionAveragePayloadAsc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsConnectionAveragePayloadDesc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsConnectionAverageProjectIdAsc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsConnectionAverageProjectIdDesc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsConnectionAverageReminderIdAsc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsConnectionAverageReminderIdDesc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsConnectionAverageRetriedAtAsc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsConnectionAverageRetriedAtDesc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsConnectionAverageRetryableAsc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsConnectionAverageRetryableDesc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsConnectionAverageRetryCountAsc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsConnectionAverageRetryCountDesc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsConnectionAverageSearchTextAsc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsConnectionAverageSearchTextDesc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsConnectionAverageSmsActivityIdAsc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionAverageSmsActivityIdDesc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionAverageSystemIdAsc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsConnectionAverageSystemIdDesc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsConnectionAverageTaskIdAsc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsConnectionAverageTaskIdDesc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsConnectionAverageVirtualCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionAverageVirtualCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionAverageWorkflowIdAsc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsConnectionAverageWorkflowIdDesc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsConnectionCountAsc = 'FEEDS_CONNECTION_COUNT_ASC',
  FeedsConnectionCountDesc = 'FEEDS_CONNECTION_COUNT_DESC',
  FeedsConnectionDistinctCountActionIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsConnectionDistinctCountActionIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsConnectionDistinctCountCallIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsConnectionDistinctCountCallIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsConnectionDistinctCountCommentAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsConnectionDistinctCountCommentDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsConnectionDistinctCountCommentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsConnectionDistinctCountCommentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsConnectionDistinctCountCompanyIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsConnectionDistinctCountCompanyIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsConnectionDistinctCountCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsConnectionDistinctCountCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsConnectionDistinctCountCreatedByAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionDistinctCountCreatedByDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionDistinctCountEventAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsConnectionDistinctCountEventDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsConnectionDistinctCountEventTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsConnectionDistinctCountEventTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsConnectionDistinctCountFileIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsConnectionDistinctCountFileIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsConnectionDistinctCountIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsConnectionDistinctCountIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsConnectionDistinctCountParentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsConnectionDistinctCountParentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsConnectionDistinctCountPayloadAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsConnectionDistinctCountPayloadDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsConnectionDistinctCountProjectIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsConnectionDistinctCountProjectIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsConnectionDistinctCountReminderIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsConnectionDistinctCountReminderIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsConnectionDistinctCountRetriedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsConnectionDistinctCountRetriedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsConnectionDistinctCountRetryableAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsConnectionDistinctCountRetryableDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsConnectionDistinctCountRetryCountAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsConnectionDistinctCountRetryCountDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsConnectionDistinctCountSearchTextAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsConnectionDistinctCountSearchTextDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionDistinctCountSystemIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsConnectionDistinctCountSystemIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsConnectionDistinctCountTaskIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsConnectionDistinctCountTaskIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionDistinctCountWorkflowIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsConnectionDistinctCountWorkflowIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsConnectionMaxActionIdAsc = 'FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsConnectionMaxActionIdDesc = 'FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsConnectionMaxCallIdAsc = 'FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  FeedsConnectionMaxCallIdDesc = 'FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  FeedsConnectionMaxCommentAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ASC',
  FeedsConnectionMaxCommentDesc = 'FEEDS_CONNECTION_MAX_COMMENT_DESC',
  FeedsConnectionMaxCommentIdAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsConnectionMaxCommentIdDesc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsConnectionMaxCompanyIdAsc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsConnectionMaxCompanyIdDesc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsConnectionMaxCreatedAtAsc = 'FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsConnectionMaxCreatedAtDesc = 'FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsConnectionMaxCreatedByAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsConnectionMaxCreatedByContactIdAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMaxCreatedByContactIdDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMaxCreatedByDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsConnectionMaxCreatedByTypeAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsConnectionMaxCreatedByTypeDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsConnectionMaxEmailMessageIdAsc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMaxEmailMessageIdDesc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMaxEventAsc = 'FEEDS_CONNECTION_MAX_EVENT_ASC',
  FeedsConnectionMaxEventDesc = 'FEEDS_CONNECTION_MAX_EVENT_DESC',
  FeedsConnectionMaxEventTypeAsc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsConnectionMaxEventTypeDesc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsConnectionMaxFileIdAsc = 'FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  FeedsConnectionMaxFileIdDesc = 'FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  FeedsConnectionMaxIdAsc = 'FEEDS_CONNECTION_MAX_ID_ASC',
  FeedsConnectionMaxIdDesc = 'FEEDS_CONNECTION_MAX_ID_DESC',
  FeedsConnectionMaxParentIdAsc = 'FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsConnectionMaxParentIdDesc = 'FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsConnectionMaxPayloadAsc = 'FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsConnectionMaxPayloadDesc = 'FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsConnectionMaxProjectIdAsc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsConnectionMaxProjectIdDesc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsConnectionMaxReminderIdAsc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsConnectionMaxReminderIdDesc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsConnectionMaxRetriedAtAsc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsConnectionMaxRetriedAtDesc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsConnectionMaxRetryableAsc = 'FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsConnectionMaxRetryableDesc = 'FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsConnectionMaxRetryCountAsc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsConnectionMaxRetryCountDesc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsConnectionMaxSearchTextAsc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsConnectionMaxSearchTextDesc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsConnectionMaxSmsActivityIdAsc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMaxSmsActivityIdDesc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMaxSystemIdAsc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsConnectionMaxSystemIdDesc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsConnectionMaxTaskIdAsc = 'FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  FeedsConnectionMaxTaskIdDesc = 'FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  FeedsConnectionMaxVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMaxVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMaxWorkflowIdAsc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsConnectionMaxWorkflowIdDesc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsConnectionMinActionIdAsc = 'FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsConnectionMinActionIdDesc = 'FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsConnectionMinCallIdAsc = 'FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  FeedsConnectionMinCallIdDesc = 'FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  FeedsConnectionMinCommentAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ASC',
  FeedsConnectionMinCommentDesc = 'FEEDS_CONNECTION_MIN_COMMENT_DESC',
  FeedsConnectionMinCommentIdAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsConnectionMinCommentIdDesc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsConnectionMinCompanyIdAsc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsConnectionMinCompanyIdDesc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsConnectionMinCreatedAtAsc = 'FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsConnectionMinCreatedAtDesc = 'FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsConnectionMinCreatedByAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsConnectionMinCreatedByContactIdAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMinCreatedByContactIdDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMinCreatedByDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsConnectionMinCreatedByTypeAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsConnectionMinCreatedByTypeDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsConnectionMinEmailMessageIdAsc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMinEmailMessageIdDesc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMinEventAsc = 'FEEDS_CONNECTION_MIN_EVENT_ASC',
  FeedsConnectionMinEventDesc = 'FEEDS_CONNECTION_MIN_EVENT_DESC',
  FeedsConnectionMinEventTypeAsc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsConnectionMinEventTypeDesc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsConnectionMinFileIdAsc = 'FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  FeedsConnectionMinFileIdDesc = 'FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  FeedsConnectionMinIdAsc = 'FEEDS_CONNECTION_MIN_ID_ASC',
  FeedsConnectionMinIdDesc = 'FEEDS_CONNECTION_MIN_ID_DESC',
  FeedsConnectionMinParentIdAsc = 'FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsConnectionMinParentIdDesc = 'FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsConnectionMinPayloadAsc = 'FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsConnectionMinPayloadDesc = 'FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsConnectionMinProjectIdAsc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsConnectionMinProjectIdDesc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsConnectionMinReminderIdAsc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsConnectionMinReminderIdDesc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsConnectionMinRetriedAtAsc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsConnectionMinRetriedAtDesc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsConnectionMinRetryableAsc = 'FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsConnectionMinRetryableDesc = 'FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsConnectionMinRetryCountAsc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsConnectionMinRetryCountDesc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsConnectionMinSearchTextAsc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsConnectionMinSearchTextDesc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsConnectionMinSmsActivityIdAsc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMinSmsActivityIdDesc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMinSystemIdAsc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsConnectionMinSystemIdDesc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsConnectionMinTaskIdAsc = 'FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  FeedsConnectionMinTaskIdDesc = 'FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  FeedsConnectionMinVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMinVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMinWorkflowIdAsc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsConnectionMinWorkflowIdDesc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsConnectionStddevPopulationActionIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsConnectionStddevPopulationActionIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsConnectionStddevPopulationCallIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsConnectionStddevPopulationCallIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsConnectionStddevPopulationCommentAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsConnectionStddevPopulationCommentDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsConnectionStddevPopulationCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionStddevPopulationCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionStddevPopulationCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionStddevPopulationCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionStddevPopulationCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevPopulationCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevPopulationEventAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsConnectionStddevPopulationEventDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsConnectionStddevPopulationEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionStddevPopulationEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionStddevPopulationFileIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsConnectionStddevPopulationFileIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsConnectionStddevPopulationIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsConnectionStddevPopulationIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsConnectionStddevPopulationParentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsConnectionStddevPopulationParentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsConnectionStddevPopulationPayloadAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsConnectionStddevPopulationPayloadDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsConnectionStddevPopulationProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionStddevPopulationProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionStddevPopulationReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionStddevPopulationReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionStddevPopulationRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionStddevPopulationRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionStddevPopulationRetryableAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsConnectionStddevPopulationRetryableDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsConnectionStddevPopulationRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionStddevPopulationRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionStddevPopulationSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionStddevPopulationSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevPopulationSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionStddevPopulationSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionStddevPopulationTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsConnectionStddevPopulationTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionStddevSampleActionIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionStddevSampleActionIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionStddevSampleCallIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsConnectionStddevSampleCallIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsConnectionStddevSampleCommentAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsConnectionStddevSampleCommentDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsConnectionStddevSampleCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionStddevSampleCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionStddevSampleCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionStddevSampleCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionStddevSampleCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionStddevSampleCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionStddevSampleCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevSampleCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevSampleEventAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsConnectionStddevSampleEventDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsConnectionStddevSampleEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionStddevSampleEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionStddevSampleFileIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsConnectionStddevSampleFileIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsConnectionStddevSampleIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsConnectionStddevSampleIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsConnectionStddevSampleParentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionStddevSampleParentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionStddevSamplePayloadAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionStddevSamplePayloadDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionStddevSampleProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionStddevSampleProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionStddevSampleReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionStddevSampleReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionStddevSampleRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionStddevSampleRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionStddevSampleRetryableAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionStddevSampleRetryableDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionStddevSampleRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionStddevSampleRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionStddevSampleSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionStddevSampleSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevSampleSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionStddevSampleSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionStddevSampleTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsConnectionStddevSampleTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevSampleWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionStddevSampleWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsConnectionSumActionIdAsc = 'FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsConnectionSumActionIdDesc = 'FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsConnectionSumCallIdAsc = 'FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  FeedsConnectionSumCallIdDesc = 'FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  FeedsConnectionSumCommentAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ASC',
  FeedsConnectionSumCommentDesc = 'FEEDS_CONNECTION_SUM_COMMENT_DESC',
  FeedsConnectionSumCommentIdAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsConnectionSumCommentIdDesc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsConnectionSumCompanyIdAsc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsConnectionSumCompanyIdDesc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsConnectionSumCreatedAtAsc = 'FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsConnectionSumCreatedAtDesc = 'FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsConnectionSumCreatedByAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsConnectionSumCreatedByContactIdAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionSumCreatedByContactIdDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionSumCreatedByDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsConnectionSumCreatedByTypeAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsConnectionSumCreatedByTypeDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsConnectionSumEmailMessageIdAsc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionSumEmailMessageIdDesc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionSumEventAsc = 'FEEDS_CONNECTION_SUM_EVENT_ASC',
  FeedsConnectionSumEventDesc = 'FEEDS_CONNECTION_SUM_EVENT_DESC',
  FeedsConnectionSumEventTypeAsc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsConnectionSumEventTypeDesc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsConnectionSumFileIdAsc = 'FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  FeedsConnectionSumFileIdDesc = 'FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  FeedsConnectionSumIdAsc = 'FEEDS_CONNECTION_SUM_ID_ASC',
  FeedsConnectionSumIdDesc = 'FEEDS_CONNECTION_SUM_ID_DESC',
  FeedsConnectionSumParentIdAsc = 'FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsConnectionSumParentIdDesc = 'FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsConnectionSumPayloadAsc = 'FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsConnectionSumPayloadDesc = 'FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsConnectionSumProjectIdAsc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsConnectionSumProjectIdDesc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsConnectionSumReminderIdAsc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsConnectionSumReminderIdDesc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsConnectionSumRetriedAtAsc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsConnectionSumRetriedAtDesc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsConnectionSumRetryableAsc = 'FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsConnectionSumRetryableDesc = 'FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsConnectionSumRetryCountAsc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsConnectionSumRetryCountDesc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsConnectionSumSearchTextAsc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsConnectionSumSearchTextDesc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsConnectionSumSmsActivityIdAsc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionSumSmsActivityIdDesc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionSumSystemIdAsc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsConnectionSumSystemIdDesc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsConnectionSumTaskIdAsc = 'FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  FeedsConnectionSumTaskIdDesc = 'FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  FeedsConnectionSumVirtualCreatedAtAsc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionSumVirtualCreatedAtDesc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionSumWorkflowIdAsc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsConnectionSumWorkflowIdDesc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsConnectionVariancePopulationActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsConnectionVariancePopulationActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsConnectionVariancePopulationCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsConnectionVariancePopulationCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsConnectionVariancePopulationCommentAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsConnectionVariancePopulationCommentDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsConnectionVariancePopulationCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionVariancePopulationCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionVariancePopulationCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionVariancePopulationCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionVariancePopulationCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVariancePopulationCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVariancePopulationEventAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsConnectionVariancePopulationEventDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsConnectionVariancePopulationEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionVariancePopulationEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionVariancePopulationFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsConnectionVariancePopulationFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsConnectionVariancePopulationIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsConnectionVariancePopulationIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsConnectionVariancePopulationParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsConnectionVariancePopulationParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsConnectionVariancePopulationPayloadAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsConnectionVariancePopulationPayloadDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsConnectionVariancePopulationProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionVariancePopulationProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionVariancePopulationReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionVariancePopulationReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionVariancePopulationRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionVariancePopulationRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionVariancePopulationRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsConnectionVariancePopulationRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsConnectionVariancePopulationRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionVariancePopulationRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionVariancePopulationSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionVariancePopulationSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVariancePopulationSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionVariancePopulationSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionVariancePopulationTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsConnectionVariancePopulationTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionVarianceSampleActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionVarianceSampleActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionVarianceSampleCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsConnectionVarianceSampleCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsConnectionVarianceSampleCommentAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsConnectionVarianceSampleCommentDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsConnectionVarianceSampleCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionVarianceSampleCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionVarianceSampleCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionVarianceSampleCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionVarianceSampleCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVarianceSampleCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVarianceSampleEventAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsConnectionVarianceSampleEventDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsConnectionVarianceSampleEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionVarianceSampleEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionVarianceSampleFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsConnectionVarianceSampleFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsConnectionVarianceSampleIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsConnectionVarianceSampleIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsConnectionVarianceSampleParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionVarianceSampleParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionVarianceSamplePayloadAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionVarianceSamplePayloadDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionVarianceSampleProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionVarianceSampleProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionVarianceSampleReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionVarianceSampleReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionVarianceSampleRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionVarianceSampleRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionVarianceSampleRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionVarianceSampleRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionVarianceSampleRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionVarianceSampleRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionVarianceSampleSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionVarianceSampleSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVarianceSampleSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionVarianceSampleSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionVarianceSampleTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsConnectionVarianceSampleTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FromAsc = 'FROM_ASC',
  FromDesc = 'FROM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsOutboxAsc = 'IS_OUTBOX_ASC',
  IsOutboxDesc = 'IS_OUTBOX_DESC',
  IsScheduledAsc = 'IS_SCHEDULED_ASC',
  IsScheduledDesc = 'IS_SCHEDULED_DESC',
  Natural = 'NATURAL',
  NylasMessageIdAsc = 'NYLAS_MESSAGE_ID_ASC',
  NylasMessageIdDesc = 'NYLAS_MESSAGE_ID_DESC',
  NylasThreadIdAsc = 'NYLAS_THREAD_ID_ASC',
  NylasThreadIdDesc = 'NYLAS_THREAD_ID_DESC',
  NylasV2MessageIdAsc = 'NYLAS_V2_MESSAGE_ID_ASC',
  NylasV2MessageIdDesc = 'NYLAS_V2_MESSAGE_ID_DESC',
  NylasV2ThreadIdAsc = 'NYLAS_V2_THREAD_ID_ASC',
  NylasV2ThreadIdDesc = 'NYLAS_V2_THREAD_ID_DESC',
  OriginProjectIdAsc = 'ORIGIN_PROJECT_ID_ASC',
  OriginProjectIdDesc = 'ORIGIN_PROJECT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceivedAtAsc = 'RECEIVED_AT_ASC',
  ReceivedAtDesc = 'RECEIVED_AT_DESC',
  ScheduledAtAsc = 'SCHEDULED_AT_ASC',
  ScheduledAtDesc = 'SCHEDULED_AT_DESC',
  SendJobIdAsc = 'SEND_JOB_ID_ASC',
  SendJobIdDesc = 'SEND_JOB_ID_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  ToAsc = 'TO_ASC',
  ToDesc = 'TO_DESC',
  TrackingAsc = 'TRACKING_ASC',
  TrackingDesc = 'TRACKING_DESC'
}

export type EmailTemplate = {
  body: Maybe<Scalars['String']['output']>;
  subject: Maybe<Scalars['String']['output']>;
};

export enum EntityType {
  Action = 'ACTION',
  Client = 'CLIENT',
  Communication = 'COMMUNICATION',
  Fleet = 'FLEET',
  Geolocation = 'GEOLOCATION',
  Project = 'PROJECT',
  Reminder = 'REMINDER',
  Request = 'REQUEST',
  WorkOrder = 'WORK_ORDER'
}

/** A filter to be used against EntityType fields. All fields are combined with a logical ‘and.’ */
export type EntityTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<EntityType>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<EntityType>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<EntityType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<EntityType>;
  /** Included in the specified list. */
  in: InputMaybe<Array<EntityType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<EntityType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<EntityType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<EntityType>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<EntityType>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<EntityType>>;
};

export enum EventType {
  ActionSentToReview = 'ACTION_SENT_TO_REVIEW',
  AssigneeAddedOrUpdated = 'ASSIGNEE_ADDED_OR_UPDATED',
  BudgetExceeded = 'BUDGET_EXCEEDED',
  ClientCreated = 'CLIENT_CREATED',
  ClientOwnerUpdated = 'CLIENT_OWNER_UPDATED',
  CollaboratorsAddedOrUpdated = 'COLLABORATORS_ADDED_OR_UPDATED',
  EmailReceived = 'EMAIL_RECEIVED',
  FutureRequestAppointmentScheduled = 'FUTURE_REQUEST_APPOINTMENT_SCHEDULED',
  FutureVisitScheduled = 'FUTURE_VISIT_SCHEDULED',
  Geofencing = 'GEOFENCING',
  GeofencingAutoClockInOut = 'GEOFENCING_AUTO_CLOCK_IN_OUT',
  GeofencingRemindClockInOut = 'GEOFENCING_REMIND_CLOCK_IN_OUT',
  MentionsAnywhere = 'MENTIONS_ANYWHERE',
  PortalActionAssignedToContact = 'PORTAL_ACTION_ASSIGNED_TO_CONTACT',
  PortalActionDueDateApproaching = 'PORTAL_ACTION_DUE_DATE_APPROACHING',
  PortalAppointmentDueDateApproaching = 'PORTAL_APPOINTMENT_DUE_DATE_APPROACHING',
  PortalArrived = 'PORTAL_ARRIVED',
  PortalMessagesReceived = 'PORTAL_MESSAGES_RECEIVED',
  PortalMessageReceived = 'PORTAL_MESSAGE_RECEIVED',
  PortalOnTheWay = 'PORTAL_ON_THE_WAY',
  PortalVisitDueDateApproaching = 'PORTAL_VISIT_DUE_DATE_APPROACHING',
  PortalVisitScheduled = 'PORTAL_VISIT_SCHEDULED',
  ProjectCreated = 'PROJECT_CREATED',
  ProjectManagerUpdated = 'PROJECT_MANAGER_UPDATED',
  ProjectOwnerUpdated = 'PROJECT_OWNER_UPDATED',
  ReferralSubmitted = 'REFERRAL_SUBMITTED',
  ReminderAssignedOrReassigned = 'REMINDER_ASSIGNED_OR_REASSIGNED',
  ReminderOverdue = 'REMINDER_OVERDUE',
  ReminderRescheduled = 'REMINDER_RESCHEDULED',
  RequestAppointmentScheduled = 'REQUEST_APPOINTMENT_SCHEDULED',
  RequestCreated = 'REQUEST_CREATED',
  RequestCreatedInPortal = 'REQUEST_CREATED_IN_PORTAL',
  RequestOwnerUpdated = 'REQUEST_OWNER_UPDATED',
  RequestPmUpdated = 'REQUEST_PM_UPDATED',
  SalesRepAssignedOrUpdated = 'SALES_REP_ASSIGNED_OR_UPDATED',
  SalesRepUpdated = 'SALES_REP_UPDATED',
  SmsReceived = 'SMS_RECEIVED',
  TodaysVisitScheduled = 'TODAYS_VISIT_SCHEDULED'
}

/** A filter to be used against EventType fields. All fields are combined with a logical ‘and.’ */
export type EventTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<EventType>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<EventType>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<EventType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<EventType>;
  /** Included in the specified list. */
  in: InputMaybe<Array<EventType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<EventType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<EventType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<EventType>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<EventType>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<EventType>>;
};

export type FavoriteProject = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `FavoriteProject`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `FavoriteProject`. */
  worker: Maybe<User>;
  workerId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `FavoriteProject` object types. */
export type FavoriteProjectAggregatesFilter = {
  /** Array aggregation aggregate over matching `FavoriteProject` objects. */
  arrayAgg: InputMaybe<FavoriteProjectArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FavoriteProject` objects. */
  average: InputMaybe<FavoriteProjectAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FavoriteProject` objects. */
  distinctCount: InputMaybe<FavoriteProjectDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FavoriteProject` object to be included within the aggregate. */
  filter: InputMaybe<FavoriteProjectFilter>;
  /** Maximum aggregate over matching `FavoriteProject` objects. */
  max: InputMaybe<FavoriteProjectMaxAggregateFilter>;
  /** Minimum aggregate over matching `FavoriteProject` objects. */
  min: InputMaybe<FavoriteProjectMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FavoriteProject` objects. */
  stddevPopulation: InputMaybe<FavoriteProjectStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FavoriteProject` objects. */
  stddevSample: InputMaybe<FavoriteProjectStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FavoriteProject` objects. */
  sum: InputMaybe<FavoriteProjectSumAggregateFilter>;
  /** Population variance aggregate over matching `FavoriteProject` objects. */
  variancePopulation: InputMaybe<FavoriteProjectVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FavoriteProject` objects. */
  varianceSample: InputMaybe<FavoriteProjectVarianceSampleAggregateFilter>;
};

export type FavoriteProjectArrayAggAggregateFilter = {
  projectId: InputMaybe<IntListFilter>;
  workerId: InputMaybe<IntListFilter>;
};

export type FavoriteProjectAverageAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `FavoriteProject` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FavoriteProjectCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `workerId` field. */
  workerId: InputMaybe<Scalars['Int']['input']>;
};

export type FavoriteProjectDistinctCountAggregateFilter = {
  projectId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `FavoriteProject` object types. All fields are combined with a logical ‘and.’ */
export type FavoriteProjectFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FavoriteProjectFilter>>;
  /** Negates the expression. */
  not: InputMaybe<FavoriteProjectFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FavoriteProjectFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `worker` relation. */
  worker: InputMaybe<UserFilter>;
  /** Filter by the object’s `workerId` field. */
  workerId: InputMaybe<IntFilter>;
};

export type FavoriteProjectMaxAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type FavoriteProjectMinAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type FavoriteProjectStddevPopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type FavoriteProjectStddevSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type FavoriteProjectSumAggregateFilter = {
  projectId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

export type FavoriteProjectVariancePopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type FavoriteProjectVarianceSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `FavoriteProject`. */
export enum FavoriteProjectsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  WorkerIdAsc = 'WORKER_ID_ASC',
  WorkerIdDesc = 'WORKER_ID_DESC'
}

export type Feed = {
  /** Reads a single `Action` that is related to this `Feed`. */
  action: Maybe<Action>;
  actionId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedParentIdAndActionId: FeedActionsByFeedParentIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedParentIdAndActionIdList: Array<Action>;
  /** Reads a single `Call` that is related to this `Feed`. */
  call: Maybe<Call>;
  callId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedParentIdAndCallId: FeedCallsByFeedParentIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedParentIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  comment: Maybe<Scalars['String']['output']>;
  commentId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedParentIdAndCommentId: FeedCommentsByFeedParentIdAndCommentIdManyToManyConnection;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByFeedIdConnection: ConsumersConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByConsumerFeedIdAndContactId: FeedContactsByConsumerFeedIdAndContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByConsumerFeedIdAndContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedInboxFeedIdAndContactId: FeedContactsByFeedInboxFeedIdAndContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedInboxFeedIdAndContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedParentIdAndCreatedByContactId: FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedParentIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `Feed`. */
  createdByContact: Maybe<Contact>;
  createdByContactId: Maybe<Scalars['Int']['output']>;
  createdByType: Maybe<CommentCreatedBy>;
  /** Reads a single `User` that is related to this `Feed`. */
  createdByUser: Maybe<User>;
  /** Reads a single `EmailMessage` that is related to this `Feed`. */
  emailMessage: Maybe<EmailMessage>;
  emailMessageId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedParentIdAndEmailMessageIdList: Array<EmailMessage>;
  event: Maybe<Scalars['String']['output']>;
  eventType: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesByFeedIdConnection: FeedInboxesConnection;
  /** Reads a single `File` that is related to this `Feed`. */
  file: Maybe<File>;
  fileId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedParentIdAndFileId: FeedFilesByFeedParentIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedParentIdAndFileIdList: Array<File>;
  id: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByFeedInboxFeedIdAndUserIdList: Array<MainUser>;
  /** Reads a single `Feed` that is related to this `Feed`. */
  parent: Maybe<Feed>;
  parentId: Maybe<Scalars['Int']['output']>;
  payload: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedParentIdAndTaskId: FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `Feed`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedParentIdAndProjectId: FeedProjectsByFeedParentIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedParentIdAndProjectIdList: Array<Project>;
  /** Reads a single `Comment` that is related to this `Feed`. */
  relatedComment: Maybe<Comment>;
  /** Reads a single `Reminder` that is related to this `Feed`. */
  reminder: Maybe<Reminder>;
  reminderId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedParentIdAndReminderId: FeedRemindersByFeedParentIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedParentIdAndReminderIdList: Array<Reminder>;
  retriedAt: Maybe<Scalars['Datetime']['output']>;
  retryCount: Maybe<Scalars['Int']['output']>;
  retryable: Maybe<Scalars['Boolean']['output']>;
  searchText: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedParentIdAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads a single `SmsActivity` that is related to this `Feed`. */
  smsActivity: Maybe<SmsActivity>;
  smsActivityId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `System` that is related to this `Feed`. */
  system: Maybe<System>;
  systemId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedParentIdAndSystemId: FeedSystemsByFeedParentIdAndSystemIdManyToManyConnection;
  /** Reads a single `PrivilegedTask` that is related to this `Feed`. */
  task: Maybe<PrivilegedTask>;
  taskId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByConsumerFeedIdAndUserId: FeedUsersByConsumerFeedIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedParentIdAndCreatedBy: FeedUsersByFeedParentIdAndCreatedByManyToManyConnection;
  virtualCreatedAt: Maybe<Scalars['Datetime']['output']>;
  workflowId: Maybe<Scalars['Int']['output']>;
};


export type FeedActionsByFeedParentIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type FeedActionsByFeedParentIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type FeedCallsByFeedParentIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type FeedCallsByFeedParentIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type FeedChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedCommentsByFeedParentIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type FeedConsumersByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};


export type FeedContactsByConsumerFeedIdAndContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedContactsByConsumerFeedIdAndContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedContactsByFeedInboxFeedIdAndContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedContactsByFeedInboxFeedIdAndContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedContactsByFeedParentIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedContactsByFeedParentIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FeedEmailMessagesByFeedParentIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type FeedFeedInboxesByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedFilesByFeedParentIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type FeedFilesByFeedParentIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FeedMainUsersByFeedInboxFeedIdAndUserIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type FeedPrivilegedTasksByFeedParentIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type FeedProjectsByFeedParentIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FeedProjectsByFeedParentIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FeedRemindersByFeedParentIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type FeedRemindersByFeedParentIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type FeedSmsActivitiesByFeedParentIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type FeedSystemsByFeedParentIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type FeedUsersByConsumerFeedIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type FeedUsersByFeedParentIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type FeedActionsByFeedParentIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedActionsByFeedParentIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type FeedActionsByFeedParentIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type FeedActionsByFeedParentIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type FeedActionsByFeedParentIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FeedArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FeedAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FeedDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FeedMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FeedMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FeedStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FeedStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FeedSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FeedVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FeedVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Feed` object types. */
export type FeedAggregatesFilter = {
  /** Array aggregation aggregate over matching `Feed` objects. */
  arrayAgg: InputMaybe<FeedArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Feed` objects. */
  average: InputMaybe<FeedAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Feed` objects. */
  distinctCount: InputMaybe<FeedDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Feed` object to be included within the aggregate. */
  filter: InputMaybe<FeedFilter>;
  /** Maximum aggregate over matching `Feed` objects. */
  max: InputMaybe<FeedMaxAggregateFilter>;
  /** Minimum aggregate over matching `Feed` objects. */
  min: InputMaybe<FeedMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Feed` objects. */
  stddevPopulation: InputMaybe<FeedStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Feed` objects. */
  stddevSample: InputMaybe<FeedStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Feed` objects. */
  sum: InputMaybe<FeedSumAggregateFilter>;
  /** Population variance aggregate over matching `Feed` objects. */
  variancePopulation: InputMaybe<FeedVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Feed` objects. */
  varianceSample: InputMaybe<FeedVarianceSampleAggregateFilter>;
};

export type FeedArrayAggAggregateFilter = {
  actionId: InputMaybe<IntListFilter>;
  callId: InputMaybe<IntListFilter>;
  comment: InputMaybe<StringListFilter>;
  commentId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  createdByContactId: InputMaybe<IntListFilter>;
  emailMessageId: InputMaybe<IntListFilter>;
  fileId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  parentId: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  reminderId: InputMaybe<IntListFilter>;
  retryCount: InputMaybe<IntListFilter>;
  retryable: InputMaybe<BooleanListFilter>;
  searchText: InputMaybe<StringListFilter>;
  smsActivityId: InputMaybe<IntListFilter>;
  systemId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
  workflowId: InputMaybe<IntListFilter>;
};

export type FeedArrayAggAggregates = {
  /** Array aggregation of actionId across the matching connection */
  actionId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of callId across the matching connection */
  callId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of comment across the matching connection */
  comment: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of commentId across the matching connection */
  commentId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdByContactId across the matching connection */
  createdByContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of emailMessageId across the matching connection */
  emailMessageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of fileId across the matching connection */
  fileId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of parentId across the matching connection */
  parentId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of reminderId across the matching connection */
  reminderId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of retryCount across the matching connection */
  retryCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of retryable across the matching connection */
  retryable: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of searchText across the matching connection */
  searchText: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of smsActivityId across the matching connection */
  smsActivityId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of workflowId across the matching connection */
  workflowId: Array<Maybe<Scalars['Int']['output']>>;
};


export type FeedArrayAggAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCommentArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesRetryableArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesSearchTextArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedArrayAggAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedAverageAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  callId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  emailMessageId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  reminderId: InputMaybe<BigFloatFilter>;
  retryCount: InputMaybe<BigFloatFilter>;
  smsActivityId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  workflowId: InputMaybe<BigFloatFilter>;
};

export type FeedAverageAggregates = {
  /** Mean average of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of callId across the matching connection */
  callId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedAverageAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedAverageAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type FeedCallsByFeedParentIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedCallsByFeedParentIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type FeedCallsByFeedParentIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type FeedCallsByFeedParentIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type FeedCallsByFeedParentIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type FeedCommentsByFeedParentIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedCommentsByFeedParentIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type FeedCommentsByFeedParentIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type FeedCommentsByFeedParentIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type FeedCommentsByFeedParentIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A condition to be used against `Feed` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeedCondition = {
  /** Checks for equality with the object’s `actionId` field. */
  actionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `callId` field. */
  callId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `comment` field. */
  comment: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `commentId` field. */
  commentId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `emailMessageId` field. */
  emailMessageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `event` field. */
  event: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `payload` field. */
  payload: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `reminderId` field. */
  reminderId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retriedAt` field. */
  retriedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `retryCount` field. */
  retryCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retryable` field. */
  retryable: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `searchText` field. */
  searchText: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `smsActivityId` field. */
  smsActivityId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `virtualCreatedAt` field. */
  virtualCreatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `workflowId` field. */
  workflowId: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Consumer`. */
export type FeedContactsByConsumerFeedIdAndContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<FeedContactsByConsumerFeedIdAndContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Consumer`. */
export type FeedContactsByConsumerFeedIdAndContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Consumer`. */
export type FeedContactsByConsumerFeedIdAndContactIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Consumer`. */
export type FeedContactsByConsumerFeedIdAndContactIdManyToManyEdgeConsumersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `FeedInbox`. */
export type FeedContactsByFeedInboxFeedIdAndContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `FeedInbox`, and the cursor to aid in pagination. */
  edges: Array<FeedContactsByFeedInboxFeedIdAndContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `FeedInbox`. */
export type FeedContactsByFeedInboxFeedIdAndContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `FeedInbox`. */
export type FeedContactsByFeedInboxFeedIdAndContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesConnection: FeedInboxesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `FeedInbox`. */
export type FeedContactsByFeedInboxFeedIdAndContactIdManyToManyEdgeFeedInboxesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type FeedContactsByFeedParentIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedDistinctCountAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  callId: InputMaybe<BigIntFilter>;
  comment: InputMaybe<BigIntFilter>;
  commentId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  createdByType: InputMaybe<BigIntFilter>;
  emailMessageId: InputMaybe<BigIntFilter>;
  event: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  payload: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  reminderId: InputMaybe<BigIntFilter>;
  retriedAt: InputMaybe<BigIntFilter>;
  retryCount: InputMaybe<BigIntFilter>;
  retryable: InputMaybe<BigIntFilter>;
  searchText: InputMaybe<BigIntFilter>;
  smsActivityId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  virtualCreatedAt: InputMaybe<BigIntFilter>;
  workflowId: InputMaybe<BigIntFilter>;
};

export type FeedDistinctCountAggregates = {
  /** Distinct count of actionId across the matching connection */
  actionId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of callId across the matching connection */
  callId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of comment across the matching connection */
  comment: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of commentId across the matching connection */
  commentId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByType across the matching connection */
  createdByType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of event across the matching connection */
  event: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileId across the matching connection */
  fileId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentId across the matching connection */
  parentId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of payload across the matching connection */
  payload: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of retriedAt across the matching connection */
  retriedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of retryable across the matching connection */
  retryable: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchText across the matching connection */
  searchText: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of virtualCreatedAt across the matching connection */
  virtualCreatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigInt']['output']>;
};


export type FeedDistinctCountAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCommentArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesCreatedByTypeArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesEventArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesPayloadArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesRetriedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesRetryableArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesSearchTextArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesVirtualCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedDistinctCountAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type FeedFilesByFeedParentIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedFilesByFeedParentIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type FeedFilesByFeedParentIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type FeedFilesByFeedParentIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type FeedFilesByFeedParentIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FeedFilter = {
  /** Filter by the object’s `action` relation. */
  action: InputMaybe<ActionFilter>;
  /** A related `action` exists. */
  actionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `actionId` field. */
  actionId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FeedFilter>>;
  /** Filter by the object’s `call` relation. */
  call: InputMaybe<CallFilter>;
  /** A related `call` exists. */
  callExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `callId` field. */
  callId: InputMaybe<IntFilter>;
  /** Filter by the object’s `childFeeds` relation. */
  childFeeds: InputMaybe<FeedToManyFeedFilter>;
  /** Some related `childFeeds` exist. */
  childFeedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `comment` field. */
  comment: InputMaybe<StringFilter>;
  /** Filter by the object’s `commentId` field. */
  commentId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `consumersByFeedId` relation. */
  consumersByFeedId: InputMaybe<FeedToManyConsumerFilter>;
  /** Some related `consumersByFeedId` exist. */
  consumersByFeedIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByContact` relation. */
  createdByContact: InputMaybe<ContactFilter>;
  /** A related `createdByContact` exists. */
  createdByContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailMessage` relation. */
  emailMessage: InputMaybe<EmailMessageFilter>;
  /** A related `emailMessage` exists. */
  emailMessageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailMessageId` field. */
  emailMessageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `event` field. */
  event: InputMaybe<StringFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedInboxesByFeedId` relation. */
  feedInboxesByFeedId: InputMaybe<FeedToManyFeedInboxFilter>;
  /** Some related `feedInboxesByFeedId` exist. */
  feedInboxesByFeedIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `file` relation. */
  file: InputMaybe<FileFilter>;
  /** A related `file` exists. */
  fileExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fileId` field. */
  fileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FeedFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FeedFilter>>;
  /** Filter by the object’s `parent` relation. */
  parent: InputMaybe<FeedFilter>;
  /** A related `parent` exists. */
  parentExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentId` field. */
  parentId: InputMaybe<IntFilter>;
  /** Filter by the object’s `payload` field. */
  payload: InputMaybe<JsonFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `relatedComment` relation. */
  relatedComment: InputMaybe<CommentFilter>;
  /** A related `relatedComment` exists. */
  relatedCommentExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `reminder` relation. */
  reminder: InputMaybe<ReminderFilter>;
  /** A related `reminder` exists. */
  reminderExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `reminderId` field. */
  reminderId: InputMaybe<IntFilter>;
  /** Filter by the object’s `retriedAt` field. */
  retriedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `retryCount` field. */
  retryCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `retryable` field. */
  retryable: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `searchText` field. */
  searchText: InputMaybe<StringFilter>;
  /** Filter by the object’s `smsActivity` relation. */
  smsActivity: InputMaybe<SmsActivityFilter>;
  /** A related `smsActivity` exists. */
  smsActivityExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivityId` field. */
  smsActivityId: InputMaybe<IntFilter>;
  /** Filter by the object’s `system` relation. */
  system: InputMaybe<SystemFilter>;
  /** A related `system` exists. */
  systemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `virtualCreatedAt` field. */
  virtualCreatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `workflowId` field. */
  workflowId: InputMaybe<IntFilter>;
};

/** Grouping methods for `Feed` for usage during aggregation. */
export enum FeedGroupBy {
  ActionId = 'ACTION_ID',
  CallId = 'CALL_ID',
  Comment = 'COMMENT',
  CommentId = 'COMMENT_ID',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedByContactId = 'CREATED_BY_CONTACT_ID',
  CreatedByType = 'CREATED_BY_TYPE',
  EmailMessageId = 'EMAIL_MESSAGE_ID',
  Event = 'EVENT',
  EventType = 'EVENT_TYPE',
  FileId = 'FILE_ID',
  Id = 'ID',
  ParentId = 'PARENT_ID',
  Payload = 'PAYLOAD',
  ProjectId = 'PROJECT_ID',
  ReminderId = 'REMINDER_ID',
  RetriedAt = 'RETRIED_AT',
  RetriedAtTruncatedToDay = 'RETRIED_AT_TRUNCATED_TO_DAY',
  RetriedAtTruncatedToHour = 'RETRIED_AT_TRUNCATED_TO_HOUR',
  RetriedAtTruncatedToMonth = 'RETRIED_AT_TRUNCATED_TO_MONTH',
  RetriedAtTruncatedToQuarter = 'RETRIED_AT_TRUNCATED_TO_QUARTER',
  RetriedAtTruncatedToWeek = 'RETRIED_AT_TRUNCATED_TO_WEEK',
  RetriedAtTruncatedToYear = 'RETRIED_AT_TRUNCATED_TO_YEAR',
  Retryable = 'RETRYABLE',
  RetryCount = 'RETRY_COUNT',
  SearchText = 'SEARCH_TEXT',
  SmsActivityId = 'SMS_ACTIVITY_ID',
  SystemId = 'SYSTEM_ID',
  TaskId = 'TASK_ID',
  VirtualCreatedAt = 'VIRTUAL_CREATED_AT',
  VirtualCreatedAtTruncatedToDay = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_DAY',
  VirtualCreatedAtTruncatedToHour = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_HOUR',
  VirtualCreatedAtTruncatedToMonth = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_MONTH',
  VirtualCreatedAtTruncatedToQuarter = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_QUARTER',
  VirtualCreatedAtTruncatedToWeek = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_WEEK',
  VirtualCreatedAtTruncatedToYear = 'VIRTUAL_CREATED_AT_TRUNCATED_TO_YEAR',
  WorkflowId = 'WORKFLOW_ID'
}

export type FeedHavingArrayAggInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingAverageInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingDistinctCountInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Feed` aggregates. */
export type FeedHavingInput = {
  AND: InputMaybe<Array<FeedHavingInput>>;
  OR: InputMaybe<Array<FeedHavingInput>>;
  arrayAgg: InputMaybe<FeedHavingArrayAggInput>;
  average: InputMaybe<FeedHavingAverageInput>;
  distinctCount: InputMaybe<FeedHavingDistinctCountInput>;
  max: InputMaybe<FeedHavingMaxInput>;
  min: InputMaybe<FeedHavingMinInput>;
  stddevPopulation: InputMaybe<FeedHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FeedHavingStddevSampleInput>;
  sum: InputMaybe<FeedHavingSumInput>;
  variancePopulation: InputMaybe<FeedHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FeedHavingVarianceSampleInput>;
};

export type FeedHavingMaxInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingMinInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingStddevPopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingStddevSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingSumInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingVariancePopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedHavingVarianceSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  callId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  emailMessageId: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  reminderId: InputMaybe<HavingIntFilter>;
  retriedAt: InputMaybe<HavingDatetimeFilter>;
  retryCount: InputMaybe<HavingIntFilter>;
  smsActivityId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  virtualCreatedAt: InputMaybe<HavingDatetimeFilter>;
  workflowId: InputMaybe<HavingIntFilter>;
};

export type FeedInbox = Node & {
  /** Reads a single `Contact` that is related to this `FeedInbox`. */
  contact: Maybe<Contact>;
  contactId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  entityType: EntityType;
  eventType: EventType;
  /** Reads a single `Feed` that is related to this `FeedInbox`. */
  feed: Maybe<Feed>;
  feedId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  read: Scalars['Boolean']['output'];
  receiverType: CommentCreatedBy;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `FeedInbox`. */
  user: Maybe<MainUser>;
  userId: Maybe<Scalars['Int']['output']>;
};

export type FeedInboxAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FeedInboxArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FeedInboxAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FeedInboxDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FeedInboxMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FeedInboxMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FeedInboxStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FeedInboxStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FeedInboxSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FeedInboxVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FeedInboxVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `FeedInbox` object types. */
export type FeedInboxAggregatesFilter = {
  /** Array aggregation aggregate over matching `FeedInbox` objects. */
  arrayAgg: InputMaybe<FeedInboxArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FeedInbox` objects. */
  average: InputMaybe<FeedInboxAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FeedInbox` objects. */
  distinctCount: InputMaybe<FeedInboxDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FeedInbox` object to be included within the aggregate. */
  filter: InputMaybe<FeedInboxFilter>;
  /** Maximum aggregate over matching `FeedInbox` objects. */
  max: InputMaybe<FeedInboxMaxAggregateFilter>;
  /** Minimum aggregate over matching `FeedInbox` objects. */
  min: InputMaybe<FeedInboxMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FeedInbox` objects. */
  stddevPopulation: InputMaybe<FeedInboxStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FeedInbox` objects. */
  stddevSample: InputMaybe<FeedInboxStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FeedInbox` objects. */
  sum: InputMaybe<FeedInboxSumAggregateFilter>;
  /** Population variance aggregate over matching `FeedInbox` objects. */
  variancePopulation: InputMaybe<FeedInboxVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FeedInbox` objects. */
  varianceSample: InputMaybe<FeedInboxVarianceSampleAggregateFilter>;
};

export type FeedInboxArrayAggAggregateFilter = {
  contactId: InputMaybe<IntListFilter>;
  feedId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  read: InputMaybe<BooleanListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type FeedInboxArrayAggAggregates = {
  /** Array aggregation of contactId across the matching connection */
  contactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of feedId across the matching connection */
  feedId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of read across the matching connection */
  read: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of userId across the matching connection */
  userId: Array<Maybe<Scalars['Int']['output']>>;
};


export type FeedInboxArrayAggAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxArrayAggAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxArrayAggAggregatesReadArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxArrayAggAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxAverageAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FeedInboxAverageAggregates = {
  /** Mean average of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedInboxAverageAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxAverageAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxAverageAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/**
 * A condition to be used against `FeedInbox` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedInboxCondition = {
  /** Checks for equality with the object’s `contactId` field. */
  contactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `entityType` field. */
  entityType: InputMaybe<EntityType>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType: InputMaybe<EventType>;
  /** Checks for equality with the object’s `feedId` field. */
  feedId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `read` field. */
  read: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `receiverType` field. */
  receiverType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type FeedInboxDistinctCountAggregateFilter = {
  contactId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  entityType: InputMaybe<BigIntFilter>;
  eventType: InputMaybe<BigIntFilter>;
  feedId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  read: InputMaybe<BigIntFilter>;
  receiverType: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type FeedInboxDistinctCountAggregates = {
  /** Distinct count of contactId across the matching connection */
  contactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of entityType across the matching connection */
  entityType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventType across the matching connection */
  eventType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of feedId across the matching connection */
  feedId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of read across the matching connection */
  read: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of receiverType across the matching connection */
  receiverType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userId across the matching connection */
  userId: Maybe<Scalars['BigInt']['output']>;
};


export type FeedInboxDistinctCountAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesEntityTypeArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesEventTypeArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesReadArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesReceiverTypeArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxDistinctCountAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A filter to be used against `FeedInbox` object types. All fields are combined with a logical ‘and.’ */
export type FeedInboxFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FeedInboxFilter>>;
  /** Filter by the object’s `contact` relation. */
  contact: InputMaybe<ContactFilter>;
  /** A related `contact` exists. */
  contactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `contactId` field. */
  contactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `entityType` field. */
  entityType: InputMaybe<EntityTypeFilter>;
  /** Filter by the object’s `eventType` field. */
  eventType: InputMaybe<EventTypeFilter>;
  /** Filter by the object’s `feed` relation. */
  feed: InputMaybe<FeedFilter>;
  /** A related `feed` exists. */
  feedExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedId` field. */
  feedId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FeedInboxFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FeedInboxFilter>>;
  /** Filter by the object’s `read` field. */
  read: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `receiverType` field. */
  receiverType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** A related `user` exists. */
  userExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

/** Grouping methods for `FeedInbox` for usage during aggregation. */
export enum FeedInboxGroupBy {
  ContactId = 'CONTACT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EntityType = 'ENTITY_TYPE',
  EventType = 'EVENT_TYPE',
  FeedId = 'FEED_ID',
  Read = 'READ',
  ReceiverType = 'RECEIVER_TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  UserId = 'USER_ID'
}

export type FeedInboxHavingArrayAggInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingAverageInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingDistinctCountInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `FeedInbox` aggregates. */
export type FeedInboxHavingInput = {
  AND: InputMaybe<Array<FeedInboxHavingInput>>;
  OR: InputMaybe<Array<FeedInboxHavingInput>>;
  arrayAgg: InputMaybe<FeedInboxHavingArrayAggInput>;
  average: InputMaybe<FeedInboxHavingAverageInput>;
  distinctCount: InputMaybe<FeedInboxHavingDistinctCountInput>;
  max: InputMaybe<FeedInboxHavingMaxInput>;
  min: InputMaybe<FeedInboxHavingMinInput>;
  stddevPopulation: InputMaybe<FeedInboxHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FeedInboxHavingStddevSampleInput>;
  sum: InputMaybe<FeedInboxHavingSumInput>;
  variancePopulation: InputMaybe<FeedInboxHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FeedInboxHavingVarianceSampleInput>;
};

export type FeedInboxHavingMaxInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingMinInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingStddevPopulationInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingStddevSampleInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingSumInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingVariancePopulationInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxHavingVarianceSampleInput = {
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  feedId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type FeedInboxMaxAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  feedId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FeedInboxMaxAggregates = {
  /** Maximum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of feedId across the matching connection */
  feedId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type FeedInboxMaxAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMaxAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMaxAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxMinAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  feedId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FeedInboxMinAggregates = {
  /** Minimum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of feedId across the matching connection */
  feedId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type FeedInboxMinAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMinAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxMinAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxStddevPopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FeedInboxStddevPopulationAggregates = {
  /** Population standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedInboxStddevPopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevPopulationAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevPopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxStddevSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FeedInboxStddevSampleAggregates = {
  /** Sample standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedInboxStddevSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevSampleAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxStddevSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxSumAggregateFilter = {
  contactId: InputMaybe<BigIntFilter>;
  feedId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type FeedInboxSumAggregates = {
  /** Sum of contactId across the matching connection */
  contactId: Scalars['BigInt']['output'];
  /** Sum of feedId across the matching connection */
  feedId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt']['output'];
};


export type FeedInboxSumAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxSumAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxSumAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxVariancePopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FeedInboxVariancePopulationAggregates = {
  /** Population variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedInboxVariancePopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVariancePopulationAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVariancePopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

export type FeedInboxVarianceSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  feedId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FeedInboxVarianceSampleAggregates = {
  /** Sample variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of feedId across the matching connection */
  feedId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedInboxVarianceSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVarianceSampleAggregatesFeedIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type FeedInboxVarianceSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A connection to a list of `FeedInbox` values. */
export type FeedInboxesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedInboxAggregates>;
  /** A list of edges which contains the `FeedInbox` and cursor to aid in pagination. */
  edges: Array<FeedInboxesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedInboxAggregates>>;
  /** A list of `FeedInbox` objects. */
  nodes: Array<Maybe<FeedInbox>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedInbox` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FeedInbox` values. */
export type FeedInboxesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedInboxGroupBy>;
  having: InputMaybe<FeedInboxHavingInput>;
};

/** A `FeedInbox` edge in the connection. */
export type FeedInboxesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `FeedInbox` at the end of the edge. */
  node: Maybe<FeedInbox>;
};

/** Methods to use when ordering `FeedInbox`. */
export enum FeedInboxesOrderBy {
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EntityTypeAsc = 'ENTITY_TYPE_ASC',
  EntityTypeDesc = 'ENTITY_TYPE_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  FeedIdAsc = 'FEED_ID_ASC',
  FeedIdDesc = 'FEED_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReadAsc = 'READ_ASC',
  ReadDesc = 'READ_DESC',
  ReceiverTypeAsc = 'RECEIVER_TYPE_ASC',
  ReceiverTypeDesc = 'RECEIVER_TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type FeedMaxAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  callId: InputMaybe<IntFilter>;
  commentId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  emailMessageId: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  reminderId: InputMaybe<IntFilter>;
  retriedAt: InputMaybe<DatetimeFilter>;
  retryCount: InputMaybe<IntFilter>;
  smsActivityId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  virtualCreatedAt: InputMaybe<DatetimeFilter>;
  workflowId: InputMaybe<IntFilter>;
};

export type FeedMaxAggregates = {
  /** Maximum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Maximum of callId across the matching connection */
  callId: Maybe<Scalars['Int']['output']>;
  /** Maximum of commentId across the matching connection */
  commentId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of reminderId across the matching connection */
  reminderId: Maybe<Scalars['Int']['output']>;
  /** Maximum of retriedAt across the matching connection */
  retriedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of retryCount across the matching connection */
  retryCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['Int']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of virtualCreatedAt across the matching connection */
  virtualCreatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of workflowId across the matching connection */
  workflowId: Maybe<Scalars['Int']['output']>;
};


export type FeedMaxAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesRetriedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesVirtualCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMaxAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedMinAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  callId: InputMaybe<IntFilter>;
  commentId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  emailMessageId: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  reminderId: InputMaybe<IntFilter>;
  retriedAt: InputMaybe<DatetimeFilter>;
  retryCount: InputMaybe<IntFilter>;
  smsActivityId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  virtualCreatedAt: InputMaybe<DatetimeFilter>;
  workflowId: InputMaybe<IntFilter>;
};

export type FeedMinAggregates = {
  /** Minimum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Minimum of callId across the matching connection */
  callId: Maybe<Scalars['Int']['output']>;
  /** Minimum of commentId across the matching connection */
  commentId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of reminderId across the matching connection */
  reminderId: Maybe<Scalars['Int']['output']>;
  /** Minimum of retriedAt across the matching connection */
  retriedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of retryCount across the matching connection */
  retryCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['Int']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of virtualCreatedAt across the matching connection */
  virtualCreatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of workflowId across the matching connection */
  workflowId: Maybe<Scalars['Int']['output']>;
};


export type FeedMinAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesRetriedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesVirtualCreatedAtArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedMinAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type FeedPrivilegedTasksByFeedParentIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type FeedProjectsByFeedParentIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedProjectsByFeedParentIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type FeedProjectsByFeedParentIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type FeedProjectsByFeedParentIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type FeedProjectsByFeedParentIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type FeedRemindersByFeedParentIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedRemindersByFeedParentIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type FeedRemindersByFeedParentIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type FeedRemindersByFeedParentIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type FeedRemindersByFeedParentIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedStddevPopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  callId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  emailMessageId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  reminderId: InputMaybe<BigFloatFilter>;
  retryCount: InputMaybe<BigFloatFilter>;
  smsActivityId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  workflowId: InputMaybe<BigFloatFilter>;
};

export type FeedStddevPopulationAggregates = {
  /** Population standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of callId across the matching connection */
  callId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedStddevPopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevPopulationAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedStddevSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  callId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  emailMessageId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  reminderId: InputMaybe<BigFloatFilter>;
  retryCount: InputMaybe<BigFloatFilter>;
  smsActivityId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  workflowId: InputMaybe<BigFloatFilter>;
};

export type FeedStddevSampleAggregates = {
  /** Sample standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of callId across the matching connection */
  callId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedStddevSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedStddevSampleAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedSumAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  callId: InputMaybe<BigIntFilter>;
  commentId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  emailMessageId: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  reminderId: InputMaybe<BigIntFilter>;
  retryCount: InputMaybe<BigIntFilter>;
  smsActivityId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  workflowId: InputMaybe<BigIntFilter>;
};

export type FeedSumAggregates = {
  /** Sum of actionId across the matching connection */
  actionId: Scalars['BigInt']['output'];
  /** Sum of callId across the matching connection */
  callId: Scalars['BigInt']['output'];
  /** Sum of commentId across the matching connection */
  commentId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of createdByContactId across the matching connection */
  createdByContactId: Scalars['BigInt']['output'];
  /** Sum of emailMessageId across the matching connection */
  emailMessageId: Scalars['BigInt']['output'];
  /** Sum of fileId across the matching connection */
  fileId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of reminderId across the matching connection */
  reminderId: Scalars['BigInt']['output'];
  /** Sum of retryCount across the matching connection */
  retryCount: Scalars['BigInt']['output'];
  /** Sum of smsActivityId across the matching connection */
  smsActivityId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
  /** Sum of workflowId across the matching connection */
  workflowId: Scalars['BigInt']['output'];
};


export type FeedSumAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedSumAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type FeedSystemsByFeedParentIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedSystemsByFeedParentIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type FeedSystemsByFeedParentIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type FeedSystemsByFeedParentIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type FeedSystemsByFeedParentIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Consumer` object types. All fields are combined with a logical ‘and.’ */
export type FeedToManyConsumerFilter = {
  /** Aggregates across related `Consumer` match the filter criteria. */
  aggregates: InputMaybe<ConsumerAggregatesFilter>;
  /** Every related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ConsumerFilter>;
  /** No related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ConsumerFilter>;
  /** Some related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ConsumerFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FeedToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `FeedInbox` object types. All fields are combined with a logical ‘and.’ */
export type FeedToManyFeedInboxFilter = {
  /** Aggregates across related `FeedInbox` match the filter criteria. */
  aggregates: InputMaybe<FeedInboxAggregatesFilter>;
  /** Every related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedInboxFilter>;
  /** No related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedInboxFilter>;
  /** Some related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedInboxFilter>;
};

/** A connection to a list of `User` values, with data from `Consumer`. */
export type FeedUsersByConsumerFeedIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<FeedUsersByConsumerFeedIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Consumer`. */
export type FeedUsersByConsumerFeedIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Consumer`. */
export type FeedUsersByConsumerFeedIdAndUserIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByUserIdConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Consumer`. */
export type FeedUsersByConsumerFeedIdAndUserIdManyToManyEdgeConsumersByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type FeedUsersByFeedParentIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FeedUsersByFeedParentIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type FeedUsersByFeedParentIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type FeedUsersByFeedParentIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type FeedUsersByFeedParentIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedVariancePopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  callId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  emailMessageId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  reminderId: InputMaybe<BigFloatFilter>;
  retryCount: InputMaybe<BigFloatFilter>;
  smsActivityId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  workflowId: InputMaybe<BigFloatFilter>;
};

export type FeedVariancePopulationAggregates = {
  /** Population variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of callId across the matching connection */
  callId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedVariancePopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVariancePopulationAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

export type FeedVarianceSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  callId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  emailMessageId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  reminderId: InputMaybe<BigFloatFilter>;
  retryCount: InputMaybe<BigFloatFilter>;
  smsActivityId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  workflowId: InputMaybe<BigFloatFilter>;
};

export type FeedVarianceSampleAggregates = {
  /** Sample variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of callId across the matching connection */
  callId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of emailMessageId across the matching connection */
  emailMessageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of reminderId across the matching connection */
  reminderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of retryCount across the matching connection */
  retryCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of smsActivityId across the matching connection */
  smsActivityId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of workflowId across the matching connection */
  workflowId: Maybe<Scalars['BigFloat']['output']>;
};


export type FeedVarianceSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesCallIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesEmailMessageIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesReminderIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesRetryCountArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesSmsActivityIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};


export type FeedVarianceSampleAggregatesWorkflowIdArgs = {
  orderBy: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Feed` values. */
export type FeedsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed` and cursor to aid in pagination. */
  edges: Array<FeedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values. */
export type FeedsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection. */
export type FeedsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};

/** Methods to use when ordering `Feed`. */
export enum FeedsOrderBy {
  ActionIdAsc = 'ACTION_ID_ASC',
  ActionIdDesc = 'ACTION_ID_DESC',
  CallIdAsc = 'CALL_ID_ASC',
  CallIdDesc = 'CALL_ID_DESC',
  ChildFeedsConnectionArrayAggActionIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  ChildFeedsConnectionArrayAggActionIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  ChildFeedsConnectionArrayAggCallIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  ChildFeedsConnectionArrayAggCallIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  ChildFeedsConnectionArrayAggCommentAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  ChildFeedsConnectionArrayAggCommentDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  ChildFeedsConnectionArrayAggCommentIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  ChildFeedsConnectionArrayAggCommentIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  ChildFeedsConnectionArrayAggCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ChildFeedsConnectionArrayAggCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ChildFeedsConnectionArrayAggCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ChildFeedsConnectionArrayAggCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ChildFeedsConnectionArrayAggCreatedByAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ChildFeedsConnectionArrayAggCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionArrayAggCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionArrayAggCreatedByDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ChildFeedsConnectionArrayAggCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionArrayAggCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionArrayAggEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionArrayAggEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionArrayAggEventAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  ChildFeedsConnectionArrayAggEventDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  ChildFeedsConnectionArrayAggEventTypeAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  ChildFeedsConnectionArrayAggEventTypeDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  ChildFeedsConnectionArrayAggFileIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  ChildFeedsConnectionArrayAggFileIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  ChildFeedsConnectionArrayAggIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  ChildFeedsConnectionArrayAggIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  ChildFeedsConnectionArrayAggParentIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  ChildFeedsConnectionArrayAggParentIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  ChildFeedsConnectionArrayAggPayloadAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  ChildFeedsConnectionArrayAggPayloadDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  ChildFeedsConnectionArrayAggProjectIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ChildFeedsConnectionArrayAggProjectIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ChildFeedsConnectionArrayAggReminderIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  ChildFeedsConnectionArrayAggReminderIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  ChildFeedsConnectionArrayAggRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  ChildFeedsConnectionArrayAggRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  ChildFeedsConnectionArrayAggRetryableAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  ChildFeedsConnectionArrayAggRetryableDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  ChildFeedsConnectionArrayAggRetryCountAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  ChildFeedsConnectionArrayAggRetryCountDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  ChildFeedsConnectionArrayAggSearchTextAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  ChildFeedsConnectionArrayAggSearchTextDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  ChildFeedsConnectionArrayAggSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionArrayAggSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionArrayAggSystemIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  ChildFeedsConnectionArrayAggSystemIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  ChildFeedsConnectionArrayAggTaskIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  ChildFeedsConnectionArrayAggTaskIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  ChildFeedsConnectionArrayAggVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionArrayAggVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionArrayAggWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  ChildFeedsConnectionArrayAggWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  ChildFeedsConnectionAverageActionIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  ChildFeedsConnectionAverageActionIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  ChildFeedsConnectionAverageCallIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  ChildFeedsConnectionAverageCallIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  ChildFeedsConnectionAverageCommentAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  ChildFeedsConnectionAverageCommentDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  ChildFeedsConnectionAverageCommentIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  ChildFeedsConnectionAverageCommentIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  ChildFeedsConnectionAverageCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ChildFeedsConnectionAverageCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ChildFeedsConnectionAverageCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ChildFeedsConnectionAverageCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ChildFeedsConnectionAverageCreatedByAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ChildFeedsConnectionAverageCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionAverageCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionAverageCreatedByDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ChildFeedsConnectionAverageCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionAverageCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionAverageEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionAverageEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionAverageEventAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  ChildFeedsConnectionAverageEventDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  ChildFeedsConnectionAverageEventTypeAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  ChildFeedsConnectionAverageEventTypeDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  ChildFeedsConnectionAverageFileIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  ChildFeedsConnectionAverageFileIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  ChildFeedsConnectionAverageIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_ID_ASC',
  ChildFeedsConnectionAverageIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_ID_DESC',
  ChildFeedsConnectionAverageParentIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  ChildFeedsConnectionAverageParentIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  ChildFeedsConnectionAveragePayloadAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  ChildFeedsConnectionAveragePayloadDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  ChildFeedsConnectionAverageProjectIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ChildFeedsConnectionAverageProjectIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ChildFeedsConnectionAverageReminderIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  ChildFeedsConnectionAverageReminderIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  ChildFeedsConnectionAverageRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  ChildFeedsConnectionAverageRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  ChildFeedsConnectionAverageRetryableAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  ChildFeedsConnectionAverageRetryableDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  ChildFeedsConnectionAverageRetryCountAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  ChildFeedsConnectionAverageRetryCountDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  ChildFeedsConnectionAverageSearchTextAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  ChildFeedsConnectionAverageSearchTextDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  ChildFeedsConnectionAverageSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionAverageSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionAverageSystemIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  ChildFeedsConnectionAverageSystemIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  ChildFeedsConnectionAverageTaskIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  ChildFeedsConnectionAverageTaskIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  ChildFeedsConnectionAverageVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionAverageVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionAverageWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  ChildFeedsConnectionAverageWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  ChildFeedsConnectionCountAsc = 'CHILD_FEEDS_CONNECTION_COUNT_ASC',
  ChildFeedsConnectionCountDesc = 'CHILD_FEEDS_CONNECTION_COUNT_DESC',
  ChildFeedsConnectionDistinctCountActionIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  ChildFeedsConnectionDistinctCountActionIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  ChildFeedsConnectionDistinctCountCallIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  ChildFeedsConnectionDistinctCountCallIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  ChildFeedsConnectionDistinctCountCommentAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  ChildFeedsConnectionDistinctCountCommentDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  ChildFeedsConnectionDistinctCountCommentIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  ChildFeedsConnectionDistinctCountCommentIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  ChildFeedsConnectionDistinctCountCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ChildFeedsConnectionDistinctCountCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ChildFeedsConnectionDistinctCountCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ChildFeedsConnectionDistinctCountCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ChildFeedsConnectionDistinctCountCreatedByAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ChildFeedsConnectionDistinctCountCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionDistinctCountCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionDistinctCountCreatedByDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ChildFeedsConnectionDistinctCountCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionDistinctCountCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionDistinctCountEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionDistinctCountEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionDistinctCountEventAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  ChildFeedsConnectionDistinctCountEventDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  ChildFeedsConnectionDistinctCountEventTypeAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  ChildFeedsConnectionDistinctCountEventTypeDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  ChildFeedsConnectionDistinctCountFileIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  ChildFeedsConnectionDistinctCountFileIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  ChildFeedsConnectionDistinctCountIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ChildFeedsConnectionDistinctCountIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ChildFeedsConnectionDistinctCountParentIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  ChildFeedsConnectionDistinctCountParentIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  ChildFeedsConnectionDistinctCountPayloadAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  ChildFeedsConnectionDistinctCountPayloadDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  ChildFeedsConnectionDistinctCountProjectIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ChildFeedsConnectionDistinctCountProjectIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ChildFeedsConnectionDistinctCountReminderIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  ChildFeedsConnectionDistinctCountReminderIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  ChildFeedsConnectionDistinctCountRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  ChildFeedsConnectionDistinctCountRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  ChildFeedsConnectionDistinctCountRetryableAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  ChildFeedsConnectionDistinctCountRetryableDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  ChildFeedsConnectionDistinctCountRetryCountAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  ChildFeedsConnectionDistinctCountRetryCountDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  ChildFeedsConnectionDistinctCountSearchTextAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  ChildFeedsConnectionDistinctCountSearchTextDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  ChildFeedsConnectionDistinctCountSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionDistinctCountSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionDistinctCountSystemIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  ChildFeedsConnectionDistinctCountSystemIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  ChildFeedsConnectionDistinctCountTaskIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  ChildFeedsConnectionDistinctCountTaskIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  ChildFeedsConnectionDistinctCountVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionDistinctCountVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionDistinctCountWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  ChildFeedsConnectionDistinctCountWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  ChildFeedsConnectionMaxActionIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  ChildFeedsConnectionMaxActionIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  ChildFeedsConnectionMaxCallIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  ChildFeedsConnectionMaxCallIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  ChildFeedsConnectionMaxCommentAsc = 'CHILD_FEEDS_CONNECTION_MAX_COMMENT_ASC',
  ChildFeedsConnectionMaxCommentDesc = 'CHILD_FEEDS_CONNECTION_MAX_COMMENT_DESC',
  ChildFeedsConnectionMaxCommentIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  ChildFeedsConnectionMaxCommentIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  ChildFeedsConnectionMaxCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  ChildFeedsConnectionMaxCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  ChildFeedsConnectionMaxCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  ChildFeedsConnectionMaxCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  ChildFeedsConnectionMaxCreatedByAsc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  ChildFeedsConnectionMaxCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionMaxCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionMaxCreatedByDesc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  ChildFeedsConnectionMaxCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionMaxCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionMaxEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionMaxEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionMaxEventAsc = 'CHILD_FEEDS_CONNECTION_MAX_EVENT_ASC',
  ChildFeedsConnectionMaxEventDesc = 'CHILD_FEEDS_CONNECTION_MAX_EVENT_DESC',
  ChildFeedsConnectionMaxEventTypeAsc = 'CHILD_FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  ChildFeedsConnectionMaxEventTypeDesc = 'CHILD_FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  ChildFeedsConnectionMaxFileIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  ChildFeedsConnectionMaxFileIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  ChildFeedsConnectionMaxIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_ID_ASC',
  ChildFeedsConnectionMaxIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_ID_DESC',
  ChildFeedsConnectionMaxParentIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  ChildFeedsConnectionMaxParentIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  ChildFeedsConnectionMaxPayloadAsc = 'CHILD_FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  ChildFeedsConnectionMaxPayloadDesc = 'CHILD_FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  ChildFeedsConnectionMaxProjectIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  ChildFeedsConnectionMaxProjectIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  ChildFeedsConnectionMaxReminderIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  ChildFeedsConnectionMaxReminderIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  ChildFeedsConnectionMaxRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  ChildFeedsConnectionMaxRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  ChildFeedsConnectionMaxRetryableAsc = 'CHILD_FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  ChildFeedsConnectionMaxRetryableDesc = 'CHILD_FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  ChildFeedsConnectionMaxRetryCountAsc = 'CHILD_FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  ChildFeedsConnectionMaxRetryCountDesc = 'CHILD_FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  ChildFeedsConnectionMaxSearchTextAsc = 'CHILD_FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  ChildFeedsConnectionMaxSearchTextDesc = 'CHILD_FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  ChildFeedsConnectionMaxSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionMaxSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionMaxSystemIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  ChildFeedsConnectionMaxSystemIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  ChildFeedsConnectionMaxTaskIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  ChildFeedsConnectionMaxTaskIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  ChildFeedsConnectionMaxVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionMaxVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionMaxWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  ChildFeedsConnectionMaxWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  ChildFeedsConnectionMinActionIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  ChildFeedsConnectionMinActionIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  ChildFeedsConnectionMinCallIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  ChildFeedsConnectionMinCallIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  ChildFeedsConnectionMinCommentAsc = 'CHILD_FEEDS_CONNECTION_MIN_COMMENT_ASC',
  ChildFeedsConnectionMinCommentDesc = 'CHILD_FEEDS_CONNECTION_MIN_COMMENT_DESC',
  ChildFeedsConnectionMinCommentIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  ChildFeedsConnectionMinCommentIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  ChildFeedsConnectionMinCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  ChildFeedsConnectionMinCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  ChildFeedsConnectionMinCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  ChildFeedsConnectionMinCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  ChildFeedsConnectionMinCreatedByAsc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  ChildFeedsConnectionMinCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionMinCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionMinCreatedByDesc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  ChildFeedsConnectionMinCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionMinCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionMinEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionMinEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionMinEventAsc = 'CHILD_FEEDS_CONNECTION_MIN_EVENT_ASC',
  ChildFeedsConnectionMinEventDesc = 'CHILD_FEEDS_CONNECTION_MIN_EVENT_DESC',
  ChildFeedsConnectionMinEventTypeAsc = 'CHILD_FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  ChildFeedsConnectionMinEventTypeDesc = 'CHILD_FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  ChildFeedsConnectionMinFileIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  ChildFeedsConnectionMinFileIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  ChildFeedsConnectionMinIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_ID_ASC',
  ChildFeedsConnectionMinIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_ID_DESC',
  ChildFeedsConnectionMinParentIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  ChildFeedsConnectionMinParentIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  ChildFeedsConnectionMinPayloadAsc = 'CHILD_FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  ChildFeedsConnectionMinPayloadDesc = 'CHILD_FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  ChildFeedsConnectionMinProjectIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  ChildFeedsConnectionMinProjectIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  ChildFeedsConnectionMinReminderIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  ChildFeedsConnectionMinReminderIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  ChildFeedsConnectionMinRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  ChildFeedsConnectionMinRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  ChildFeedsConnectionMinRetryableAsc = 'CHILD_FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  ChildFeedsConnectionMinRetryableDesc = 'CHILD_FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  ChildFeedsConnectionMinRetryCountAsc = 'CHILD_FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  ChildFeedsConnectionMinRetryCountDesc = 'CHILD_FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  ChildFeedsConnectionMinSearchTextAsc = 'CHILD_FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  ChildFeedsConnectionMinSearchTextDesc = 'CHILD_FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  ChildFeedsConnectionMinSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionMinSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionMinSystemIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  ChildFeedsConnectionMinSystemIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  ChildFeedsConnectionMinTaskIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  ChildFeedsConnectionMinTaskIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  ChildFeedsConnectionMinVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionMinVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionMinWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  ChildFeedsConnectionMinWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  ChildFeedsConnectionStddevPopulationActionIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  ChildFeedsConnectionStddevPopulationActionIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  ChildFeedsConnectionStddevPopulationCallIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  ChildFeedsConnectionStddevPopulationCallIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  ChildFeedsConnectionStddevPopulationCommentAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  ChildFeedsConnectionStddevPopulationCommentDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  ChildFeedsConnectionStddevPopulationCommentIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  ChildFeedsConnectionStddevPopulationCommentIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  ChildFeedsConnectionStddevPopulationCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ChildFeedsConnectionStddevPopulationCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ChildFeedsConnectionStddevPopulationCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ChildFeedsConnectionStddevPopulationCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ChildFeedsConnectionStddevPopulationCreatedByAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ChildFeedsConnectionStddevPopulationCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionStddevPopulationCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionStddevPopulationCreatedByDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ChildFeedsConnectionStddevPopulationCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionStddevPopulationCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionStddevPopulationEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionStddevPopulationEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionStddevPopulationEventAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  ChildFeedsConnectionStddevPopulationEventDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  ChildFeedsConnectionStddevPopulationEventTypeAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  ChildFeedsConnectionStddevPopulationEventTypeDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  ChildFeedsConnectionStddevPopulationFileIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  ChildFeedsConnectionStddevPopulationFileIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  ChildFeedsConnectionStddevPopulationIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ChildFeedsConnectionStddevPopulationIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ChildFeedsConnectionStddevPopulationParentIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  ChildFeedsConnectionStddevPopulationParentIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  ChildFeedsConnectionStddevPopulationPayloadAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  ChildFeedsConnectionStddevPopulationPayloadDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  ChildFeedsConnectionStddevPopulationProjectIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ChildFeedsConnectionStddevPopulationProjectIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ChildFeedsConnectionStddevPopulationReminderIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  ChildFeedsConnectionStddevPopulationReminderIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  ChildFeedsConnectionStddevPopulationRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  ChildFeedsConnectionStddevPopulationRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  ChildFeedsConnectionStddevPopulationRetryableAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  ChildFeedsConnectionStddevPopulationRetryableDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  ChildFeedsConnectionStddevPopulationRetryCountAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  ChildFeedsConnectionStddevPopulationRetryCountDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  ChildFeedsConnectionStddevPopulationSearchTextAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  ChildFeedsConnectionStddevPopulationSearchTextDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  ChildFeedsConnectionStddevPopulationSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionStddevPopulationSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionStddevPopulationSystemIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  ChildFeedsConnectionStddevPopulationSystemIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  ChildFeedsConnectionStddevPopulationTaskIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  ChildFeedsConnectionStddevPopulationTaskIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  ChildFeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionStddevPopulationWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  ChildFeedsConnectionStddevPopulationWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  ChildFeedsConnectionStddevSampleActionIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  ChildFeedsConnectionStddevSampleActionIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  ChildFeedsConnectionStddevSampleCallIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  ChildFeedsConnectionStddevSampleCallIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  ChildFeedsConnectionStddevSampleCommentAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  ChildFeedsConnectionStddevSampleCommentDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  ChildFeedsConnectionStddevSampleCommentIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  ChildFeedsConnectionStddevSampleCommentIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  ChildFeedsConnectionStddevSampleCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ChildFeedsConnectionStddevSampleCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ChildFeedsConnectionStddevSampleCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ChildFeedsConnectionStddevSampleCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ChildFeedsConnectionStddevSampleCreatedByAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ChildFeedsConnectionStddevSampleCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionStddevSampleCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionStddevSampleCreatedByDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ChildFeedsConnectionStddevSampleCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionStddevSampleCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionStddevSampleEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionStddevSampleEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionStddevSampleEventAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  ChildFeedsConnectionStddevSampleEventDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  ChildFeedsConnectionStddevSampleEventTypeAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  ChildFeedsConnectionStddevSampleEventTypeDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  ChildFeedsConnectionStddevSampleFileIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  ChildFeedsConnectionStddevSampleFileIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  ChildFeedsConnectionStddevSampleIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ChildFeedsConnectionStddevSampleIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ChildFeedsConnectionStddevSampleParentIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  ChildFeedsConnectionStddevSampleParentIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  ChildFeedsConnectionStddevSamplePayloadAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  ChildFeedsConnectionStddevSamplePayloadDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  ChildFeedsConnectionStddevSampleProjectIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ChildFeedsConnectionStddevSampleProjectIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ChildFeedsConnectionStddevSampleReminderIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  ChildFeedsConnectionStddevSampleReminderIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  ChildFeedsConnectionStddevSampleRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  ChildFeedsConnectionStddevSampleRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  ChildFeedsConnectionStddevSampleRetryableAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  ChildFeedsConnectionStddevSampleRetryableDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  ChildFeedsConnectionStddevSampleRetryCountAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  ChildFeedsConnectionStddevSampleRetryCountDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  ChildFeedsConnectionStddevSampleSearchTextAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  ChildFeedsConnectionStddevSampleSearchTextDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  ChildFeedsConnectionStddevSampleSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionStddevSampleSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionStddevSampleSystemIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  ChildFeedsConnectionStddevSampleSystemIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  ChildFeedsConnectionStddevSampleTaskIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  ChildFeedsConnectionStddevSampleTaskIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  ChildFeedsConnectionStddevSampleVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionStddevSampleVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionStddevSampleWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  ChildFeedsConnectionStddevSampleWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  ChildFeedsConnectionSumActionIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  ChildFeedsConnectionSumActionIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  ChildFeedsConnectionSumCallIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  ChildFeedsConnectionSumCallIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  ChildFeedsConnectionSumCommentAsc = 'CHILD_FEEDS_CONNECTION_SUM_COMMENT_ASC',
  ChildFeedsConnectionSumCommentDesc = 'CHILD_FEEDS_CONNECTION_SUM_COMMENT_DESC',
  ChildFeedsConnectionSumCommentIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  ChildFeedsConnectionSumCommentIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  ChildFeedsConnectionSumCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  ChildFeedsConnectionSumCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  ChildFeedsConnectionSumCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  ChildFeedsConnectionSumCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  ChildFeedsConnectionSumCreatedByAsc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  ChildFeedsConnectionSumCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionSumCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionSumCreatedByDesc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  ChildFeedsConnectionSumCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionSumCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionSumEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionSumEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionSumEventAsc = 'CHILD_FEEDS_CONNECTION_SUM_EVENT_ASC',
  ChildFeedsConnectionSumEventDesc = 'CHILD_FEEDS_CONNECTION_SUM_EVENT_DESC',
  ChildFeedsConnectionSumEventTypeAsc = 'CHILD_FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  ChildFeedsConnectionSumEventTypeDesc = 'CHILD_FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  ChildFeedsConnectionSumFileIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  ChildFeedsConnectionSumFileIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  ChildFeedsConnectionSumIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_ID_ASC',
  ChildFeedsConnectionSumIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_ID_DESC',
  ChildFeedsConnectionSumParentIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  ChildFeedsConnectionSumParentIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  ChildFeedsConnectionSumPayloadAsc = 'CHILD_FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  ChildFeedsConnectionSumPayloadDesc = 'CHILD_FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  ChildFeedsConnectionSumProjectIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  ChildFeedsConnectionSumProjectIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  ChildFeedsConnectionSumReminderIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  ChildFeedsConnectionSumReminderIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  ChildFeedsConnectionSumRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  ChildFeedsConnectionSumRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  ChildFeedsConnectionSumRetryableAsc = 'CHILD_FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  ChildFeedsConnectionSumRetryableDesc = 'CHILD_FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  ChildFeedsConnectionSumRetryCountAsc = 'CHILD_FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  ChildFeedsConnectionSumRetryCountDesc = 'CHILD_FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  ChildFeedsConnectionSumSearchTextAsc = 'CHILD_FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  ChildFeedsConnectionSumSearchTextDesc = 'CHILD_FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  ChildFeedsConnectionSumSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionSumSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionSumSystemIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  ChildFeedsConnectionSumSystemIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  ChildFeedsConnectionSumTaskIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  ChildFeedsConnectionSumTaskIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  ChildFeedsConnectionSumVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionSumVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionSumWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  ChildFeedsConnectionSumWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  ChildFeedsConnectionVariancePopulationActionIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  ChildFeedsConnectionVariancePopulationActionIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  ChildFeedsConnectionVariancePopulationCallIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  ChildFeedsConnectionVariancePopulationCallIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  ChildFeedsConnectionVariancePopulationCommentAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  ChildFeedsConnectionVariancePopulationCommentDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  ChildFeedsConnectionVariancePopulationCommentIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  ChildFeedsConnectionVariancePopulationCommentIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  ChildFeedsConnectionVariancePopulationCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ChildFeedsConnectionVariancePopulationCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ChildFeedsConnectionVariancePopulationCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ChildFeedsConnectionVariancePopulationCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ChildFeedsConnectionVariancePopulationCreatedByAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ChildFeedsConnectionVariancePopulationCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionVariancePopulationCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionVariancePopulationCreatedByDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ChildFeedsConnectionVariancePopulationCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionVariancePopulationCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionVariancePopulationEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionVariancePopulationEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionVariancePopulationEventAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  ChildFeedsConnectionVariancePopulationEventDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  ChildFeedsConnectionVariancePopulationEventTypeAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  ChildFeedsConnectionVariancePopulationEventTypeDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  ChildFeedsConnectionVariancePopulationFileIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  ChildFeedsConnectionVariancePopulationFileIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  ChildFeedsConnectionVariancePopulationIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ChildFeedsConnectionVariancePopulationIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ChildFeedsConnectionVariancePopulationParentIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  ChildFeedsConnectionVariancePopulationParentIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  ChildFeedsConnectionVariancePopulationPayloadAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  ChildFeedsConnectionVariancePopulationPayloadDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  ChildFeedsConnectionVariancePopulationProjectIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ChildFeedsConnectionVariancePopulationProjectIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ChildFeedsConnectionVariancePopulationReminderIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  ChildFeedsConnectionVariancePopulationReminderIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  ChildFeedsConnectionVariancePopulationRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  ChildFeedsConnectionVariancePopulationRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  ChildFeedsConnectionVariancePopulationRetryableAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  ChildFeedsConnectionVariancePopulationRetryableDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  ChildFeedsConnectionVariancePopulationRetryCountAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  ChildFeedsConnectionVariancePopulationRetryCountDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  ChildFeedsConnectionVariancePopulationSearchTextAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  ChildFeedsConnectionVariancePopulationSearchTextDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  ChildFeedsConnectionVariancePopulationSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionVariancePopulationSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionVariancePopulationSystemIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  ChildFeedsConnectionVariancePopulationSystemIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  ChildFeedsConnectionVariancePopulationTaskIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  ChildFeedsConnectionVariancePopulationTaskIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  ChildFeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionVariancePopulationWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  ChildFeedsConnectionVariancePopulationWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  ChildFeedsConnectionVarianceSampleActionIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  ChildFeedsConnectionVarianceSampleActionIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  ChildFeedsConnectionVarianceSampleCallIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  ChildFeedsConnectionVarianceSampleCallIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  ChildFeedsConnectionVarianceSampleCommentAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  ChildFeedsConnectionVarianceSampleCommentDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  ChildFeedsConnectionVarianceSampleCommentIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  ChildFeedsConnectionVarianceSampleCommentIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  ChildFeedsConnectionVarianceSampleCompanyIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ChildFeedsConnectionVarianceSampleCompanyIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ChildFeedsConnectionVarianceSampleCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ChildFeedsConnectionVarianceSampleCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ChildFeedsConnectionVarianceSampleCreatedByAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ChildFeedsConnectionVarianceSampleCreatedByContactIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ChildFeedsConnectionVarianceSampleCreatedByContactIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ChildFeedsConnectionVarianceSampleCreatedByDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ChildFeedsConnectionVarianceSampleCreatedByTypeAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ChildFeedsConnectionVarianceSampleCreatedByTypeDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ChildFeedsConnectionVarianceSampleEmailMessageIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  ChildFeedsConnectionVarianceSampleEmailMessageIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  ChildFeedsConnectionVarianceSampleEventAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  ChildFeedsConnectionVarianceSampleEventDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  ChildFeedsConnectionVarianceSampleEventTypeAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  ChildFeedsConnectionVarianceSampleEventTypeDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  ChildFeedsConnectionVarianceSampleFileIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  ChildFeedsConnectionVarianceSampleFileIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  ChildFeedsConnectionVarianceSampleIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ChildFeedsConnectionVarianceSampleIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ChildFeedsConnectionVarianceSampleParentIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ChildFeedsConnectionVarianceSampleParentIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  ChildFeedsConnectionVarianceSamplePayloadAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  ChildFeedsConnectionVarianceSamplePayloadDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  ChildFeedsConnectionVarianceSampleProjectIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ChildFeedsConnectionVarianceSampleProjectIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ChildFeedsConnectionVarianceSampleReminderIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  ChildFeedsConnectionVarianceSampleReminderIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  ChildFeedsConnectionVarianceSampleRetriedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  ChildFeedsConnectionVarianceSampleRetriedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  ChildFeedsConnectionVarianceSampleRetryableAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  ChildFeedsConnectionVarianceSampleRetryableDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  ChildFeedsConnectionVarianceSampleRetryCountAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  ChildFeedsConnectionVarianceSampleRetryCountDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  ChildFeedsConnectionVarianceSampleSearchTextAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  ChildFeedsConnectionVarianceSampleSearchTextDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  ChildFeedsConnectionVarianceSampleSmsActivityIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  ChildFeedsConnectionVarianceSampleSmsActivityIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  ChildFeedsConnectionVarianceSampleSystemIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  ChildFeedsConnectionVarianceSampleSystemIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  ChildFeedsConnectionVarianceSampleTaskIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  ChildFeedsConnectionVarianceSampleTaskIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  ChildFeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  ChildFeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  ChildFeedsConnectionVarianceSampleWorkflowIdAsc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  ChildFeedsConnectionVarianceSampleWorkflowIdDesc = 'CHILD_FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  CommentAsc = 'COMMENT_ASC',
  CommentDesc = 'COMMENT_DESC',
  CommentIdAsc = 'COMMENT_ID_ASC',
  CommentIdDesc = 'COMMENT_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ConsumersByFeedIdConnectionArrayAggConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionArrayAggConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionArrayAggContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionArrayAggContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionArrayAggCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionArrayAggCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionArrayAggFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  ConsumersByFeedIdConnectionArrayAggFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  ConsumersByFeedIdConnectionArrayAggIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ConsumersByFeedIdConnectionArrayAggIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ConsumersByFeedIdConnectionArrayAggMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_MENTIONED_ASC',
  ConsumersByFeedIdConnectionArrayAggMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_MENTIONED_DESC',
  ConsumersByFeedIdConnectionArrayAggReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_READ_ASC',
  ConsumersByFeedIdConnectionArrayAggReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_READ_DESC',
  ConsumersByFeedIdConnectionArrayAggUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionArrayAggUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionArrayAggUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ConsumersByFeedIdConnectionArrayAggUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ConsumersByFeedIdConnectionAverageConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionAverageConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionAverageContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionAverageContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionAverageCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionAverageCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionAverageFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_FEED_ID_ASC',
  ConsumersByFeedIdConnectionAverageFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_FEED_ID_DESC',
  ConsumersByFeedIdConnectionAverageIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_ID_ASC',
  ConsumersByFeedIdConnectionAverageIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_ID_DESC',
  ConsumersByFeedIdConnectionAverageMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_MENTIONED_ASC',
  ConsumersByFeedIdConnectionAverageMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_MENTIONED_DESC',
  ConsumersByFeedIdConnectionAverageReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_READ_ASC',
  ConsumersByFeedIdConnectionAverageReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_READ_DESC',
  ConsumersByFeedIdConnectionAverageUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionAverageUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionAverageUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ConsumersByFeedIdConnectionAverageUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ConsumersByFeedIdConnectionCountAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_COUNT_ASC',
  ConsumersByFeedIdConnectionCountDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_COUNT_DESC',
  ConsumersByFeedIdConnectionDistinctCountConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionDistinctCountConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionDistinctCountContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionDistinctCountContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionDistinctCountCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionDistinctCountCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionDistinctCountFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  ConsumersByFeedIdConnectionDistinctCountFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  ConsumersByFeedIdConnectionDistinctCountIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ConsumersByFeedIdConnectionDistinctCountIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ConsumersByFeedIdConnectionDistinctCountMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_MENTIONED_ASC',
  ConsumersByFeedIdConnectionDistinctCountMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_MENTIONED_DESC',
  ConsumersByFeedIdConnectionDistinctCountReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_READ_ASC',
  ConsumersByFeedIdConnectionDistinctCountReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_READ_DESC',
  ConsumersByFeedIdConnectionDistinctCountUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionDistinctCountUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionDistinctCountUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ConsumersByFeedIdConnectionDistinctCountUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ConsumersByFeedIdConnectionMaxConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionMaxConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionMaxContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionMaxContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionMaxCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionMaxCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionMaxFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_FEED_ID_ASC',
  ConsumersByFeedIdConnectionMaxFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_FEED_ID_DESC',
  ConsumersByFeedIdConnectionMaxIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_ID_ASC',
  ConsumersByFeedIdConnectionMaxIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_ID_DESC',
  ConsumersByFeedIdConnectionMaxMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_MENTIONED_ASC',
  ConsumersByFeedIdConnectionMaxMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_MENTIONED_DESC',
  ConsumersByFeedIdConnectionMaxReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_READ_ASC',
  ConsumersByFeedIdConnectionMaxReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_READ_DESC',
  ConsumersByFeedIdConnectionMaxUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionMaxUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionMaxUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_USER_ID_ASC',
  ConsumersByFeedIdConnectionMaxUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MAX_USER_ID_DESC',
  ConsumersByFeedIdConnectionMinConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionMinConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionMinContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionMinContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionMinCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionMinCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionMinFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_FEED_ID_ASC',
  ConsumersByFeedIdConnectionMinFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_FEED_ID_DESC',
  ConsumersByFeedIdConnectionMinIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_ID_ASC',
  ConsumersByFeedIdConnectionMinIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_ID_DESC',
  ConsumersByFeedIdConnectionMinMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_MENTIONED_ASC',
  ConsumersByFeedIdConnectionMinMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_MENTIONED_DESC',
  ConsumersByFeedIdConnectionMinReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_READ_ASC',
  ConsumersByFeedIdConnectionMinReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_READ_DESC',
  ConsumersByFeedIdConnectionMinUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionMinUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionMinUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_USER_ID_ASC',
  ConsumersByFeedIdConnectionMinUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_MIN_USER_ID_DESC',
  ConsumersByFeedIdConnectionStddevPopulationConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionStddevPopulationConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionStddevPopulationContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionStddevPopulationContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionStddevPopulationCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionStddevPopulationCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionStddevPopulationFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  ConsumersByFeedIdConnectionStddevPopulationFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  ConsumersByFeedIdConnectionStddevPopulationIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ConsumersByFeedIdConnectionStddevPopulationIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ConsumersByFeedIdConnectionStddevPopulationMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_MENTIONED_ASC',
  ConsumersByFeedIdConnectionStddevPopulationMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_MENTIONED_DESC',
  ConsumersByFeedIdConnectionStddevPopulationReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_READ_ASC',
  ConsumersByFeedIdConnectionStddevPopulationReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_READ_DESC',
  ConsumersByFeedIdConnectionStddevPopulationUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionStddevPopulationUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionStddevPopulationUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ConsumersByFeedIdConnectionStddevPopulationUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ConsumersByFeedIdConnectionStddevSampleConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionStddevSampleConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionStddevSampleContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionStddevSampleContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionStddevSampleCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionStddevSampleCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionStddevSampleFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  ConsumersByFeedIdConnectionStddevSampleFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  ConsumersByFeedIdConnectionStddevSampleIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ConsumersByFeedIdConnectionStddevSampleIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ConsumersByFeedIdConnectionStddevSampleMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_MENTIONED_ASC',
  ConsumersByFeedIdConnectionStddevSampleMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_MENTIONED_DESC',
  ConsumersByFeedIdConnectionStddevSampleReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  ConsumersByFeedIdConnectionStddevSampleReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  ConsumersByFeedIdConnectionStddevSampleUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionStddevSampleUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionStddevSampleUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ConsumersByFeedIdConnectionStddevSampleUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ConsumersByFeedIdConnectionSumConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionSumConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionSumContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionSumContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionSumCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionSumCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionSumFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_FEED_ID_ASC',
  ConsumersByFeedIdConnectionSumFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_FEED_ID_DESC',
  ConsumersByFeedIdConnectionSumIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_ID_ASC',
  ConsumersByFeedIdConnectionSumIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_ID_DESC',
  ConsumersByFeedIdConnectionSumMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_MENTIONED_ASC',
  ConsumersByFeedIdConnectionSumMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_MENTIONED_DESC',
  ConsumersByFeedIdConnectionSumReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_READ_ASC',
  ConsumersByFeedIdConnectionSumReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_READ_DESC',
  ConsumersByFeedIdConnectionSumUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionSumUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionSumUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_USER_ID_ASC',
  ConsumersByFeedIdConnectionSumUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_SUM_USER_ID_DESC',
  ConsumersByFeedIdConnectionVariancePopulationConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionVariancePopulationConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionVariancePopulationContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionVariancePopulationContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionVariancePopulationCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionVariancePopulationCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionVariancePopulationFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  ConsumersByFeedIdConnectionVariancePopulationFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  ConsumersByFeedIdConnectionVariancePopulationIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ConsumersByFeedIdConnectionVariancePopulationIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ConsumersByFeedIdConnectionVariancePopulationMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_MENTIONED_ASC',
  ConsumersByFeedIdConnectionVariancePopulationMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_MENTIONED_DESC',
  ConsumersByFeedIdConnectionVariancePopulationReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  ConsumersByFeedIdConnectionVariancePopulationReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  ConsumersByFeedIdConnectionVariancePopulationUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionVariancePopulationUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionVariancePopulationUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ConsumersByFeedIdConnectionVariancePopulationUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ConsumersByFeedIdConnectionVarianceSampleConsumerTypeAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersByFeedIdConnectionVarianceSampleConsumerTypeDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersByFeedIdConnectionVarianceSampleContactIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ConsumersByFeedIdConnectionVarianceSampleContactIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ConsumersByFeedIdConnectionVarianceSampleCreatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConsumersByFeedIdConnectionVarianceSampleCreatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConsumersByFeedIdConnectionVarianceSampleFeedIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  ConsumersByFeedIdConnectionVarianceSampleFeedIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  ConsumersByFeedIdConnectionVarianceSampleIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ConsumersByFeedIdConnectionVarianceSampleIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ConsumersByFeedIdConnectionVarianceSampleMentionedAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_MENTIONED_ASC',
  ConsumersByFeedIdConnectionVarianceSampleMentionedDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_MENTIONED_DESC',
  ConsumersByFeedIdConnectionVarianceSampleReadAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  ConsumersByFeedIdConnectionVarianceSampleReadDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  ConsumersByFeedIdConnectionVarianceSampleUpdatedAtAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConsumersByFeedIdConnectionVarianceSampleUpdatedAtDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ConsumersByFeedIdConnectionVarianceSampleUserIdAsc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ConsumersByFeedIdConnectionVarianceSampleUserIdDesc = 'CONSUMERS_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByContactIdAsc = 'CREATED_BY_CONTACT_ID_ASC',
  CreatedByContactIdDesc = 'CREATED_BY_CONTACT_ID_DESC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedByTypeAsc = 'CREATED_BY_TYPE_ASC',
  CreatedByTypeDesc = 'CREATED_BY_TYPE_DESC',
  EmailMessageIdAsc = 'EMAIL_MESSAGE_ID_ASC',
  EmailMessageIdDesc = 'EMAIL_MESSAGE_ID_DESC',
  EventAsc = 'EVENT_ASC',
  EventDesc = 'EVENT_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionArrayAggContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionArrayAggContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionArrayAggCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionArrayAggCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionArrayAggEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionArrayAggEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionArrayAggEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionArrayAggEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionArrayAggFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionArrayAggFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionArrayAggIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedInboxesByFeedIdConnectionArrayAggIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedInboxesByFeedIdConnectionArrayAggReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_READ_ASC',
  FeedInboxesByFeedIdConnectionArrayAggReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_READ_DESC',
  FeedInboxesByFeedIdConnectionArrayAggReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionArrayAggReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionArrayAggUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionArrayAggUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionArrayAggUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionArrayAggUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionAverageContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionAverageContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionAverageCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionAverageCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionAverageEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionAverageEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionAverageEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionAverageEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionAverageFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionAverageFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionAverageIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedInboxesByFeedIdConnectionAverageIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedInboxesByFeedIdConnectionAverageReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_READ_ASC',
  FeedInboxesByFeedIdConnectionAverageReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_READ_DESC',
  FeedInboxesByFeedIdConnectionAverageReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionAverageReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionAverageUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionAverageUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionAverageUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionAverageUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionCountAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_COUNT_ASC',
  FeedInboxesByFeedIdConnectionCountDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_COUNT_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_READ_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_READ_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionDistinctCountUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionDistinctCountUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionMaxContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionMaxContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionMaxCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionMaxCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionMaxEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMaxEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMaxEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMaxEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMaxFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionMaxFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionMaxIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_ID_ASC',
  FeedInboxesByFeedIdConnectionMaxIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_ID_DESC',
  FeedInboxesByFeedIdConnectionMaxReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_READ_ASC',
  FeedInboxesByFeedIdConnectionMaxReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_READ_DESC',
  FeedInboxesByFeedIdConnectionMaxReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMaxReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMaxUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionMaxUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionMaxUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionMaxUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MAX_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionMinContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionMinContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionMinCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionMinCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionMinEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMinEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMinEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMinEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMinFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionMinFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionMinIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_ID_ASC',
  FeedInboxesByFeedIdConnectionMinIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_ID_DESC',
  FeedInboxesByFeedIdConnectionMinReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_READ_ASC',
  FeedInboxesByFeedIdConnectionMinReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_READ_DESC',
  FeedInboxesByFeedIdConnectionMinReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionMinReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionMinUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionMinUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionMinUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionMinUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_MIN_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_READ_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_READ_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionStddevPopulationUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevPopulationUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionStddevSampleUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionStddevSampleUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionSumContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionSumContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionSumCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionSumCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionSumEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionSumEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionSumEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionSumEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionSumFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionSumFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionSumIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_ID_ASC',
  FeedInboxesByFeedIdConnectionSumIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_ID_DESC',
  FeedInboxesByFeedIdConnectionSumReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_READ_ASC',
  FeedInboxesByFeedIdConnectionSumReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_READ_DESC',
  FeedInboxesByFeedIdConnectionSumReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionSumReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionSumUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionSumUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionSumUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionSumUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_SUM_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionVariancePopulationUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionVariancePopulationUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleContactIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleContactIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleCreatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleCreatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleEntityTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleEntityTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleEventTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleEventTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleFeedIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleFeedIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleReadAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleReadDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleReceiverTypeAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleReceiverTypeDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleUpdatedAtAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleUpdatedAtDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesByFeedIdConnectionVarianceSampleUserIdAsc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FeedInboxesByFeedIdConnectionVarianceSampleUserIdDesc = 'FEED_INBOXES_BY_FEED_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ReminderIdAsc = 'REMINDER_ID_ASC',
  ReminderIdDesc = 'REMINDER_ID_DESC',
  RetriedAtAsc = 'RETRIED_AT_ASC',
  RetriedAtDesc = 'RETRIED_AT_DESC',
  RetryableAsc = 'RETRYABLE_ASC',
  RetryableDesc = 'RETRYABLE_DESC',
  RetryCountAsc = 'RETRY_COUNT_ASC',
  RetryCountDesc = 'RETRY_COUNT_DESC',
  SearchTextAsc = 'SEARCH_TEXT_ASC',
  SearchTextDesc = 'SEARCH_TEXT_DESC',
  SmsActivityIdAsc = 'SMS_ACTIVITY_ID_ASC',
  SmsActivityIdDesc = 'SMS_ACTIVITY_ID_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  VirtualCreatedAtAsc = 'VIRTUAL_CREATED_AT_ASC',
  VirtualCreatedAtDesc = 'VIRTUAL_CREATED_AT_DESC',
  WorkflowIdAsc = 'WORKFLOW_ID_ASC',
  WorkflowIdDesc = 'WORKFLOW_ID_DESC'
}

export type File = Node & {
  /** Reads a single `Action` that is related to this `File`. */
  action: Maybe<Action>;
  actionId: Maybe<Scalars['Int']['output']>;
  actionItemId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedFileIdAndActionId: FileActionsByFeedFileIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedFileIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileValueFileIdAndActionId: FileActionsByFileValueFileIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileValueFileIdAndActionIdList: Array<Action>;
  annotations: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedFileIdAndCallId: FileCallsByFeedFileIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedFileIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByImageId: Array<CatalogItem>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByImageIdConnection: CatalogItemsConnection;
  /** Reads a single `Comment` that is related to this `File`. */
  comment: Maybe<Comment>;
  commentId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedFileIdAndCommentId: FileCommentsByFeedFileIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileIdConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileValueFileIdAndCommentId: FileCommentsByFileValueFileIdAndCommentIdManyToManyConnection;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedFileIdAndCreatedByContactId: FileContactsByFeedFileIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedFileIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileValueFileIdAndCreatedByContactId: FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileValueFileIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `File`. */
  createdByContact: Maybe<Contact>;
  createdByContactId: Maybe<Scalars['Int']['output']>;
  createdByType: Maybe<CommentCreatedBy>;
  /** Reads a single `User` that is related to this `File`. */
  createdByUser: Maybe<User>;
  downloadUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedFileIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedFileIdAndParentId: FileFeedsByFeedFileIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** Reads a single `Form` that is related to this `File`. */
  form: Maybe<Form>;
  formId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileId: Array<Form>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileIdConnection: FormsConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileValueFileIdAndFormId: FileFormsByFileValueFileIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileValueFileIdAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  isArchived: Maybe<Scalars['Boolean']['output']>;
  isForm: Maybe<Scalars['Boolean']['output']>;
  metaData: Maybe<Scalars['JSON']['output']>;
  modifiedBy: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  origin: Maybe<FileOrigin>;
  privilege: Maybe<Scalars['String']['output']>;
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  privilegeTeam: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByCommentFileIdAndTaskId: FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedFileIdAndTaskId: FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyConnection;
  processingStatus: Maybe<Scalars['String']['output']>;
  /** Reads a single `Project` that is related to this `File`. */
  project: Maybe<Project>;
  projectColumnValueFileId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `ProjectPropertyValueFile` that is related to this `File`. */
  projectColumnValueFileProjectColumnValue: Maybe<ProjectPropertyValueFile>;
  projectColumnValueId: Maybe<Scalars['Int']['output']>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectPropertyFileReference`. */
  projectPropertyFileReferencesByFileId: Array<ProjectPropertyFileReference>;
  /** Reads and enables pagination through a set of `ProjectPropertyValueFile`. */
  projectPropertyValueFilesByFileId: Array<ProjectPropertyValueFile>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValues: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesConnection: FileProjectPropertyValuesConnectionManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileValueFileIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFormFileIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentFileIdAndProjectId: FileProjectsByCommentFileIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentFileIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedFileIdAndProjectId: FileProjectsByFeedFileIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedFileIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileValueFileIdAndProjectId: FileProjectsByFileValueFileIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileValueFileIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedFileIdAndReminderId: FileRemindersByFeedFileIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedFileIdAndReminderIdList: Array<Reminder>;
  sharedWithPortal: Maybe<Scalars['Boolean']['output']>;
  sizeInBytes: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedFileIdAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads a single `ProjectStage` that is related to this `File`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByCommentFileIdAndSystemId: FileSystemsByCommentFileIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedFileIdAndSystemId: FileSystemsByFeedFileIdAndSystemIdManyToManyConnection;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedFileIdAndCreatedBy: FileUsersByFeedFileIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileValueFileIdAndCreatedBy: FileUsersByFileValueFileIdAndCreatedByManyToManyConnection;
  uuid: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `File` that is related to this `File`. */
  valueFile: Maybe<File>;
  valueFileId: Maybe<Scalars['Int']['output']>;
};


export type FileActionsByFeedFileIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type FileActionsByFeedFileIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type FileActionsByFileValueFileIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type FileActionsByFileValueFileIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type FileCallsByFeedFileIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type FileCallsByFeedFileIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type FileCatalogItemsByImageIdArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type FileCatalogItemsByImageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type FileCommentsByFeedFileIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type FileCommentsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type FileCommentsByFileValueFileIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type FileContactsByFeedFileIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FileContactsByFeedFileIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FileContactsByFileValueFileIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FileContactsByFileValueFileIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FileEmailMessagesByFeedFileIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type FileFeedsByFeedFileIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type FileFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type FileFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type FileFormsByFileIdArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FileFormsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type FileFormsByFileValueFileIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type FileFormsByFileValueFileIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FilePrivilegedTasksByCommentFileIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type FilePrivilegedTasksByFeedFileIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type FileProjectPropertyFileReferencesByFileIdArgs = {
  condition: InputMaybe<ProjectPropertyFileReferenceCondition>;
  filter: InputMaybe<ProjectPropertyFileReferenceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyFileReferencesOrderBy>>;
};


export type FileProjectPropertyValueFilesByFileIdArgs = {
  condition: InputMaybe<ProjectPropertyValueFileCondition>;
  filter: InputMaybe<ProjectPropertyValueFileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValueFilesOrderBy>>;
};


export type FileProjectPropertyValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type FileProjectPropertyValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type FileProjectStagesByFileValueFileIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type FileProjectStagesByFormFileIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type FileProjectsByCommentFileIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileProjectsByCommentFileIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileProjectsByFeedFileIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileProjectsByFeedFileIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileProjectsByFileValueFileIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileProjectsByFileValueFileIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FileRemindersByFeedFileIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type FileRemindersByFeedFileIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type FileSmsActivitiesByFeedFileIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type FileSystemsByCommentFileIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type FileSystemsByFeedFileIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type FileUsersByFeedFileIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type FileUsersByFileValueFileIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type FileActionsByFeedFileIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileActionsByFeedFileIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type FileActionsByFeedFileIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type FileActionsByFeedFileIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type FileActionsByFeedFileIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type FileActionsByFileValueFileIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileActionsByFileValueFileIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type FileActionsByFileValueFileIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type FileActionsByFileValueFileIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type FileActionsByFileValueFileIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type FileActionsByFileValueFileIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FileAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FileArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FileDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FileVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `File` object types. */
export type FileAggregatesFilter = {
  /** Array aggregation aggregate over matching `File` objects. */
  arrayAgg: InputMaybe<FileArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `File` objects. */
  average: InputMaybe<FileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `File` objects. */
  distinctCount: InputMaybe<FileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `File` object to be included within the aggregate. */
  filter: InputMaybe<FileFilter>;
  /** Maximum aggregate over matching `File` objects. */
  max: InputMaybe<FileMaxAggregateFilter>;
  /** Minimum aggregate over matching `File` objects. */
  min: InputMaybe<FileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `File` objects. */
  stddevPopulation: InputMaybe<FileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `File` objects. */
  stddevSample: InputMaybe<FileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `File` objects. */
  sum: InputMaybe<FileSumAggregateFilter>;
  /** Population variance aggregate over matching `File` objects. */
  variancePopulation: InputMaybe<FileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `File` objects. */
  varianceSample: InputMaybe<FileVarianceSampleAggregateFilter>;
};

export type FileArrayAggAggregateFilter = {
  actionId: InputMaybe<IntListFilter>;
  actionItemId: InputMaybe<IntListFilter>;
  commentId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  createdByContactId: InputMaybe<IntListFilter>;
  formId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isArchived: InputMaybe<BooleanListFilter>;
  isForm: InputMaybe<BooleanListFilter>;
  modifiedBy: InputMaybe<IntListFilter>;
  name: InputMaybe<StringListFilter>;
  privilege: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
  privilegeTeam: InputMaybe<StringListFilter>;
  projectColumnValueFileId: InputMaybe<IntListFilter>;
  projectColumnValueId: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  sharedWithPortal: InputMaybe<BooleanListFilter>;
  sizeInBytes: InputMaybe<IntListFilter>;
  stageId: InputMaybe<IntListFilter>;
  valueFileId: InputMaybe<IntListFilter>;
};

export type FileArrayAggAggregates = {
  /** Array aggregation of actionId across the matching connection */
  actionId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of actionItemId across the matching connection */
  actionItemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of commentId across the matching connection */
  commentId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdByContactId across the matching connection */
  createdByContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of formId across the matching connection */
  formId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isArchived across the matching connection */
  isArchived: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isForm across the matching connection */
  isForm: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of modifiedBy across the matching connection */
  modifiedBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilege across the matching connection */
  privilege: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeTeam across the matching connection */
  privilegeTeam: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectColumnValueId across the matching connection */
  projectColumnValueId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of sharedWithPortal across the matching connection */
  sharedWithPortal: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of sizeInBytes across the matching connection */
  sizeInBytes: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of valueFileId across the matching connection */
  valueFileId: Array<Maybe<Scalars['Int']['output']>>;
};


export type FileArrayAggAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesIsFormArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesSharedWithPortalArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileArrayAggAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export type FileAverageAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  actionItemId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  modifiedBy: InputMaybe<BigFloatFilter>;
  projectColumnValueFileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  sizeInBytes: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  valueFileId: InputMaybe<BigFloatFilter>;
};

export type FileAverageAggregates = {
  /** Mean average of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigFloat']['output']>;
};


export type FileAverageAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileAverageAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type FileCallsByFeedFileIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileCallsByFeedFileIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type FileCallsByFeedFileIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type FileCallsByFeedFileIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type FileCallsByFeedFileIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type FileCommentsByFeedFileIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileCommentsByFeedFileIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type FileCommentsByFeedFileIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type FileCommentsByFeedFileIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type FileCommentsByFeedFileIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type FileCommentsByFileValueFileIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileCommentsByFileValueFileIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type FileCommentsByFileValueFileIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type FileCommentsByFileValueFileIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type FileCommentsByFileValueFileIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type FileCommentsByFileValueFileIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `actionId` field. */
  actionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `actionItemId` field. */
  actionItemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `annotations` field. */
  annotations: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `commentId` field. */
  commentId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `downloadUrl` field. */
  downloadUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `formId` field. */
  formId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isForm` field. */
  isForm: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `metaData` field. */
  metaData: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `modifiedBy` field. */
  modifiedBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `origin` field. */
  origin: InputMaybe<FileOrigin>;
  /** Checks for equality with the object’s `privilege` field. */
  privilege: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processingStatus` field. */
  processingStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectColumnValueFileId` field. */
  projectColumnValueFileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectColumnValueId` field. */
  projectColumnValueId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `sharedWithPortal` field. */
  sharedWithPortal: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `sizeInBytes` field. */
  sizeInBytes: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `valueFileId` field. */
  valueFileId: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type FileContactsByFeedFileIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileContactsByFeedFileIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type FileContactsByFeedFileIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type FileContactsByFeedFileIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type FileContactsByFeedFileIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type FileContactsByFileValueFileIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FileDistinctCountAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  actionItemId: InputMaybe<BigIntFilter>;
  annotations: InputMaybe<BigIntFilter>;
  commentId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  createdByType: InputMaybe<BigIntFilter>;
  downloadUrl: InputMaybe<BigIntFilter>;
  formId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isArchived: InputMaybe<BigIntFilter>;
  isForm: InputMaybe<BigIntFilter>;
  metaData: InputMaybe<BigIntFilter>;
  modifiedBy: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  origin: InputMaybe<BigIntFilter>;
  privilege: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  privilegeTeam: InputMaybe<BigIntFilter>;
  processingStatus: InputMaybe<BigIntFilter>;
  projectColumnValueFileId: InputMaybe<BigIntFilter>;
  projectColumnValueId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  sharedWithPortal: InputMaybe<BigIntFilter>;
  sizeInBytes: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
  valueFileId: InputMaybe<BigIntFilter>;
};

export type FileDistinctCountAggregates = {
  /** Distinct count of actionId across the matching connection */
  actionId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of annotations across the matching connection */
  annotations: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of commentId across the matching connection */
  commentId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByType across the matching connection */
  createdByType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of downloadUrl across the matching connection */
  downloadUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of formId across the matching connection */
  formId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isArchived across the matching connection */
  isArchived: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isForm across the matching connection */
  isForm: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of metaData across the matching connection */
  metaData: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of origin across the matching connection */
  origin: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilege across the matching connection */
  privilege: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeTeam across the matching connection */
  privilegeTeam: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of processingStatus across the matching connection */
  processingStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sharedWithPortal across the matching connection */
  sharedWithPortal: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigInt']['output']>;
};


export type FileDistinctCountAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesAnnotationsArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesCreatedByTypeArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesDownloadUrlArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesIsFormArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesMetaDataArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesOriginArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesProcessingStatusArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesSharedWithPortalArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileDistinctCountAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type FileFeedsByFeedFileIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileFeedsByFeedFileIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type FileFeedsByFeedFileIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type FileFeedsByFeedFileIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type FileFeedsByFeedFileIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `File` object types. All fields are combined with a logical ‘and.’ */
export type FileFilter = {
  /** Filter by the object’s `action` relation. */
  action: InputMaybe<ActionFilter>;
  /** A related `action` exists. */
  actionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `actionId` field. */
  actionId: InputMaybe<IntFilter>;
  /** Filter by the object’s `actionItemId` field. */
  actionItemId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `annotations` field. */
  annotations: InputMaybe<JsonFilter>;
  /** Filter by the object’s `catalogItemsByImageId` relation. */
  catalogItemsByImageId: InputMaybe<FileToManyCatalogItemFilter>;
  /** Some related `catalogItemsByImageId` exist. */
  catalogItemsByImageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `comment` relation. */
  comment: InputMaybe<CommentFilter>;
  /** A related `comment` exists. */
  commentExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `commentId` field. */
  commentId: InputMaybe<IntFilter>;
  /** Filter by the object’s `commentsByFileId` relation. */
  commentsByFileId: InputMaybe<FileToManyCommentFilter>;
  /** Some related `commentsByFileId` exist. */
  commentsByFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByContact` relation. */
  createdByContact: InputMaybe<ContactFilter>;
  /** A related `createdByContact` exists. */
  createdByContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `downloadUrl` field. */
  downloadUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedsByFileId` relation. */
  feedsByFileId: InputMaybe<FileToManyFeedFilter>;
  /** Some related `feedsByFileId` exist. */
  feedsByFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filesByValueFileId` relation. */
  filesByValueFileId: InputMaybe<FileToManyFileFilter>;
  /** Some related `filesByValueFileId` exist. */
  filesByValueFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `form` relation. */
  form: InputMaybe<FormFilter>;
  /** A related `form` exists. */
  formExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `formId` field. */
  formId: InputMaybe<IntFilter>;
  /** Filter by the object’s `formsByFileId` relation. */
  formsByFileId: InputMaybe<FileToManyFormFilter>;
  /** Some related `formsByFileId` exist. */
  formsByFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isForm` field. */
  isForm: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metaData` field. */
  metaData: InputMaybe<JsonFilter>;
  /** Filter by the object’s `modifiedBy` field. */
  modifiedBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<FileFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FileFilter>>;
  /** Filter by the object’s `origin` field. */
  origin: InputMaybe<FileOriginFilter>;
  /** Filter by the object’s `privilege` field. */
  privilege: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<StringFilter>;
  /** Filter by the object’s `processingStatus` field. */
  processingStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectColumnValueFileId` field. */
  projectColumnValueFileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectColumnValueFileProjectColumnValue` relation. */
  projectColumnValueFileProjectColumnValue: InputMaybe<ProjectPropertyValueFileFilter>;
  /** A related `projectColumnValueFileProjectColumnValue` exists. */
  projectColumnValueFileProjectColumnValueExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectColumnValueId` field. */
  projectColumnValueId: InputMaybe<IntFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectPropertyFileReferencesByFileId` relation. */
  projectPropertyFileReferencesByFileId: InputMaybe<FileToManyProjectPropertyFileReferenceFilter>;
  /** Some related `projectPropertyFileReferencesByFileId` exist. */
  projectPropertyFileReferencesByFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertyValueFilesByFileId` relation. */
  projectPropertyValueFilesByFileId: InputMaybe<FileToManyProjectPropertyValueFileFilter>;
  /** Some related `projectPropertyValueFilesByFileId` exist. */
  projectPropertyValueFilesByFileIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `sharedWithPortal` field. */
  sharedWithPortal: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `sizeInBytes` field. */
  sizeInBytes: InputMaybe<IntFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
  /** Filter by the object’s `valueFile` relation. */
  valueFile: InputMaybe<FileFilter>;
  /** A related `valueFile` exists. */
  valueFileExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `valueFileId` field. */
  valueFileId: InputMaybe<IntFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type FileFormsByFileValueFileIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileFormsByFileValueFileIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type FileFormsByFileValueFileIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type FileFormsByFileValueFileIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type FileFormsByFileValueFileIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type FileFormsByFileValueFileIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Grouping methods for `File` for usage during aggregation. */
export enum FileGroupBy {
  ActionId = 'ACTION_ID',
  ActionItemId = 'ACTION_ITEM_ID',
  Annotations = 'ANNOTATIONS',
  CommentId = 'COMMENT_ID',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedByContactId = 'CREATED_BY_CONTACT_ID',
  CreatedByType = 'CREATED_BY_TYPE',
  DownloadUrl = 'DOWNLOAD_URL',
  FormId = 'FORM_ID',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IsArchived = 'IS_ARCHIVED',
  IsForm = 'IS_FORM',
  MetaData = 'META_DATA',
  ModifiedBy = 'MODIFIED_BY',
  Name = 'NAME',
  Origin = 'ORIGIN',
  Privilege = 'PRIVILEGE',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  PrivilegeTeam = 'PRIVILEGE_TEAM',
  ProcessingStatus = 'PROCESSING_STATUS',
  ProjectColumnValueFileId = 'PROJECT_COLUMN_VALUE_FILE_ID',
  ProjectColumnValueId = 'PROJECT_COLUMN_VALUE_ID',
  ProjectId = 'PROJECT_ID',
  SharedWithPortal = 'SHARED_WITH_PORTAL',
  SizeInBytes = 'SIZE_IN_BYTES',
  StageId = 'STAGE_ID',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  Uuid = 'UUID',
  ValueFileId = 'VALUE_FILE_ID'
}

export type FileHavingArrayAggInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingAverageInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingDistinctCountInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `File` aggregates. */
export type FileHavingInput = {
  AND: InputMaybe<Array<FileHavingInput>>;
  OR: InputMaybe<Array<FileHavingInput>>;
  arrayAgg: InputMaybe<FileHavingArrayAggInput>;
  average: InputMaybe<FileHavingAverageInput>;
  distinctCount: InputMaybe<FileHavingDistinctCountInput>;
  max: InputMaybe<FileHavingMaxInput>;
  min: InputMaybe<FileHavingMinInput>;
  stddevPopulation: InputMaybe<FileHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FileHavingStddevSampleInput>;
  sum: InputMaybe<FileHavingSumInput>;
  variancePopulation: InputMaybe<FileHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FileHavingVarianceSampleInput>;
};

export type FileHavingMaxInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingMinInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingStddevPopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingStddevSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingSumInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingVariancePopulationInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileHavingVarianceSampleInput = {
  actionId: InputMaybe<HavingIntFilter>;
  actionItemId: InputMaybe<HavingIntFilter>;
  commentId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedBy: InputMaybe<HavingIntFilter>;
  projectColumnValueFileId: InputMaybe<HavingIntFilter>;
  projectColumnValueId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  sizeInBytes: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  valueFileId: InputMaybe<HavingIntFilter>;
};

export type FileMaxAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  actionItemId: InputMaybe<IntFilter>;
  commentId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  formId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedBy: InputMaybe<IntFilter>;
  projectColumnValueFileId: InputMaybe<IntFilter>;
  projectColumnValueId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  sizeInBytes: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  valueFileId: InputMaybe<IntFilter>;
};

export type FileMaxAggregates = {
  /** Maximum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Maximum of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of commentId across the matching connection */
  commentId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of formId across the matching connection */
  formId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['Int']['output']>;
};


export type FileMaxAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMaxAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export type FileMinAggregateFilter = {
  actionId: InputMaybe<IntFilter>;
  actionItemId: InputMaybe<IntFilter>;
  commentId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  formId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedBy: InputMaybe<IntFilter>;
  projectColumnValueFileId: InputMaybe<IntFilter>;
  projectColumnValueId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  sizeInBytes: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  valueFileId: InputMaybe<IntFilter>;
};

export type FileMinAggregates = {
  /** Minimum of actionId across the matching connection */
  actionId: Maybe<Scalars['Int']['output']>;
  /** Minimum of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of commentId across the matching connection */
  commentId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of formId across the matching connection */
  formId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['Int']['output']>;
};


export type FileMinAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileMinAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export enum FileOrigin {
  Action = 'ACTION',
  Other = 'OTHER'
}

/** A filter to be used against FileOrigin fields. All fields are combined with a logical ‘and.’ */
export type FileOriginFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<FileOrigin>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<FileOrigin>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<FileOrigin>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<FileOrigin>;
  /** Included in the specified list. */
  in: InputMaybe<Array<FileOrigin>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<FileOrigin>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<FileOrigin>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<FileOrigin>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<FileOrigin>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<FileOrigin>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByTaskIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type FilePrivilegedTasksByCommentFileIdAndTaskIdManyToManyEdgeCommentsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type FilePrivilegedTasksByFeedFileIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `ProjectPropertyValue` values, with data from `ProjectPropertyValueFile`. */
export type FileProjectPropertyValuesConnectionManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectPropertyValueAggregates>;
  /** A list of edges which contains the `ProjectPropertyValue`, info from the `ProjectPropertyValueFile`, and the cursor to aid in pagination. */
  edges: Array<FileProjectPropertyValuesConnectionManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectPropertyValueAggregates>>;
  /** A list of `ProjectPropertyValue` objects. */
  nodes: Array<Maybe<ProjectPropertyValue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectPropertyValue` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectPropertyValue` values, with data from `ProjectPropertyValueFile`. */
export type FileProjectPropertyValuesConnectionManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectPropertyValueGroupBy>;
  having: InputMaybe<ProjectPropertyValueHavingInput>;
};

/** A `ProjectPropertyValue` edge in the connection, with data from `ProjectPropertyValueFile`. */
export type FileProjectPropertyValuesConnectionManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectPropertyValue` at the end of the edge. */
  node: Maybe<ProjectPropertyValue>;
};

/** A connection to a list of `Project` values, with data from `Comment`. */
export type FileProjectsByCommentFileIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<FileProjectsByCommentFileIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Comment`. */
export type FileProjectsByCommentFileIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Comment`. */
export type FileProjectsByCommentFileIdAndProjectIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByProjectIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Comment`. */
export type FileProjectsByCommentFileIdAndProjectIdManyToManyEdgeCommentsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type FileProjectsByFeedFileIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileProjectsByFeedFileIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type FileProjectsByFeedFileIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type FileProjectsByFeedFileIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type FileProjectsByFeedFileIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type FileProjectsByFileValueFileIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileProjectsByFileValueFileIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type FileProjectsByFileValueFileIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type FileProjectsByFileValueFileIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type FileProjectsByFileValueFileIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type FileProjectsByFileValueFileIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type FileRemindersByFeedFileIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileRemindersByFeedFileIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type FileRemindersByFeedFileIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type FileRemindersByFeedFileIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type FileRemindersByFeedFileIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type FileStddevPopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  actionItemId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  modifiedBy: InputMaybe<BigFloatFilter>;
  projectColumnValueFileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  sizeInBytes: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  valueFileId: InputMaybe<BigFloatFilter>;
};

export type FileStddevPopulationAggregates = {
  /** Population standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigFloat']['output']>;
};


export type FileStddevPopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevPopulationAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export type FileStddevSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  actionItemId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  modifiedBy: InputMaybe<BigFloatFilter>;
  projectColumnValueFileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  sizeInBytes: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  valueFileId: InputMaybe<BigFloatFilter>;
};

export type FileStddevSampleAggregates = {
  /** Sample standard deviation of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigFloat']['output']>;
};


export type FileStddevSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileStddevSampleAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export type FileSumAggregateFilter = {
  actionId: InputMaybe<BigIntFilter>;
  actionItemId: InputMaybe<BigIntFilter>;
  commentId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  formId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  modifiedBy: InputMaybe<BigIntFilter>;
  projectColumnValueFileId: InputMaybe<BigIntFilter>;
  projectColumnValueId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  sizeInBytes: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  valueFileId: InputMaybe<BigIntFilter>;
};

export type FileSumAggregates = {
  /** Sum of actionId across the matching connection */
  actionId: Scalars['BigInt']['output'];
  /** Sum of actionItemId across the matching connection */
  actionItemId: Scalars['BigInt']['output'];
  /** Sum of commentId across the matching connection */
  commentId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of createdByContactId across the matching connection */
  createdByContactId: Scalars['BigInt']['output'];
  /** Sum of formId across the matching connection */
  formId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of modifiedBy across the matching connection */
  modifiedBy: Scalars['BigInt']['output'];
  /** Sum of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Scalars['BigInt']['output'];
  /** Sum of projectColumnValueId across the matching connection */
  projectColumnValueId: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of sizeInBytes across the matching connection */
  sizeInBytes: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
  /** Sum of valueFileId across the matching connection */
  valueFileId: Scalars['BigInt']['output'];
};


export type FileSumAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileSumAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Comment`. */
export type FileSystemsByCommentFileIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<FileSystemsByCommentFileIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Comment`. */
export type FileSystemsByCommentFileIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Comment`. */
export type FileSystemsByCommentFileIdAndSystemIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsBySystemIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Comment`. */
export type FileSystemsByCommentFileIdAndSystemIdManyToManyEdgeCommentsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type FileSystemsByFeedFileIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileSystemsByFeedFileIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type FileSystemsByFeedFileIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type FileSystemsByFeedFileIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type FileSystemsByFeedFileIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyCatalogItemFilter = {
  /** Aggregates across related `CatalogItem` match the filter criteria. */
  aggregates: InputMaybe<CatalogItemAggregatesFilter>;
  /** Every related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CatalogItemFilter>;
  /** No related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CatalogItemFilter>;
  /** Some related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CatalogItemFilter>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `Form` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyFormFilter = {
  /** Aggregates across related `Form` match the filter criteria. */
  aggregates: InputMaybe<FormAggregatesFilter>;
  /** Every related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormFilter>;
  /** No related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormFilter>;
  /** Some related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormFilter>;
};

/** A filter to be used against many `ProjectPropertyFileReference` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyProjectPropertyFileReferenceFilter = {
  /** Aggregates across related `ProjectPropertyFileReference` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyFileReferenceAggregatesFilter>;
  /** Every related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** No related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** Some related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyFileReferenceFilter>;
};

/** A filter to be used against many `ProjectPropertyValueFile` object types. All fields are combined with a logical ‘and.’ */
export type FileToManyProjectPropertyValueFileFilter = {
  /** Aggregates across related `ProjectPropertyValueFile` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueFileAggregatesFilter>;
  /** Every related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFileFilter>;
  /** No related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFileFilter>;
  /** Some related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFileFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type FileUsersByFeedFileIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<FileUsersByFeedFileIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type FileUsersByFeedFileIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type FileUsersByFeedFileIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type FileUsersByFeedFileIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type FileUsersByFileValueFileIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FileUsersByFileValueFileIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type FileUsersByFileValueFileIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type FileUsersByFileValueFileIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type FileUsersByFileValueFileIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type FileUsersByFileValueFileIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FileVariancePopulationAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  actionItemId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  modifiedBy: InputMaybe<BigFloatFilter>;
  projectColumnValueFileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  sizeInBytes: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  valueFileId: InputMaybe<BigFloatFilter>;
};

export type FileVariancePopulationAggregates = {
  /** Population variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigFloat']['output']>;
};


export type FileVariancePopulationAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVariancePopulationAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

export type FileVarianceSampleAggregateFilter = {
  actionId: InputMaybe<BigFloatFilter>;
  actionItemId: InputMaybe<BigFloatFilter>;
  commentId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  modifiedBy: InputMaybe<BigFloatFilter>;
  projectColumnValueFileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  sizeInBytes: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  valueFileId: InputMaybe<BigFloatFilter>;
};

export type FileVarianceSampleAggregates = {
  /** Sample variance of actionId across the matching connection */
  actionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of actionItemId across the matching connection */
  actionItemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of commentId across the matching connection */
  commentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of modifiedBy across the matching connection */
  modifiedBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectColumnValueFileId across the matching connection */
  projectColumnValueFileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectColumnValueId across the matching connection */
  projectColumnValueId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of sizeInBytes across the matching connection */
  sizeInBytes: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of valueFileId across the matching connection */
  valueFileId: Maybe<Scalars['BigFloat']['output']>;
};


export type FileVarianceSampleAggregatesActionIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesActionItemIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesCommentIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesModifiedByArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesProjectColumnValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesProjectColumnValueIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesSizeInBytesArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FileVarianceSampleAggregatesValueFileIdArgs = {
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values. */
export type FilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  ActionIdAsc = 'ACTION_ID_ASC',
  ActionIdDesc = 'ACTION_ID_DESC',
  ActionItemIdAsc = 'ACTION_ITEM_ID_ASC',
  ActionItemIdDesc = 'ACTION_ITEM_ID_DESC',
  AnnotationsAsc = 'ANNOTATIONS_ASC',
  AnnotationsDesc = 'ANNOTATIONS_DESC',
  CatalogItemsByImageIdConnectionArrayAggCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionArrayAggCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionArrayAggCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CODE_ASC',
  CatalogItemsByImageIdConnectionArrayAggCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CODE_DESC',
  CatalogItemsByImageIdConnectionArrayAggCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionArrayAggCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionArrayAggCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_COST_ASC',
  CatalogItemsByImageIdConnectionArrayAggCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_COST_DESC',
  CatalogItemsByImageIdConnectionArrayAggCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionArrayAggCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionArrayAggCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionArrayAggCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionArrayAggDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionArrayAggDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionArrayAggDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionArrayAggDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionArrayAggIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CatalogItemsByImageIdConnectionArrayAggIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CatalogItemsByImageIdConnectionArrayAggImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionArrayAggImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionArrayAggIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionArrayAggIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionArrayAggIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionArrayAggIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionArrayAggManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionArrayAggManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionArrayAggMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionArrayAggMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionArrayAggNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  CatalogItemsByImageIdConnectionArrayAggNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  CatalogItemsByImageIdConnectionArrayAggPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_PRICE_ASC',
  CatalogItemsByImageIdConnectionArrayAggPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_PRICE_DESC',
  CatalogItemsByImageIdConnectionArrayAggSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_SKU_ASC',
  CatalogItemsByImageIdConnectionArrayAggSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_SKU_DESC',
  CatalogItemsByImageIdConnectionArrayAggSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_SPEC_ASC',
  CatalogItemsByImageIdConnectionArrayAggSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_SPEC_DESC',
  CatalogItemsByImageIdConnectionArrayAggTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CatalogItemsByImageIdConnectionArrayAggTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CatalogItemsByImageIdConnectionArrayAggUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionArrayAggUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionAverageCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionAverageCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionAverageCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CODE_ASC',
  CatalogItemsByImageIdConnectionAverageCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CODE_DESC',
  CatalogItemsByImageIdConnectionAverageCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionAverageCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionAverageCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_COST_ASC',
  CatalogItemsByImageIdConnectionAverageCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_COST_DESC',
  CatalogItemsByImageIdConnectionAverageCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionAverageCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionAverageCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionAverageCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionAverageDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionAverageDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionAverageDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionAverageDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionAverageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  CatalogItemsByImageIdConnectionAverageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  CatalogItemsByImageIdConnectionAverageImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionAverageImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionAverageIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionAverageIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionAverageIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionAverageIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionAverageManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionAverageManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionAverageMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionAverageMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionAverageNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_NAME_ASC',
  CatalogItemsByImageIdConnectionAverageNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_NAME_DESC',
  CatalogItemsByImageIdConnectionAveragePriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_PRICE_ASC',
  CatalogItemsByImageIdConnectionAveragePriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_PRICE_DESC',
  CatalogItemsByImageIdConnectionAverageSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_SKU_ASC',
  CatalogItemsByImageIdConnectionAverageSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_SKU_DESC',
  CatalogItemsByImageIdConnectionAverageSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_SPEC_ASC',
  CatalogItemsByImageIdConnectionAverageSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_SPEC_DESC',
  CatalogItemsByImageIdConnectionAverageTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CatalogItemsByImageIdConnectionAverageTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CatalogItemsByImageIdConnectionAverageUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionAverageUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionCountAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_COUNT_ASC',
  CatalogItemsByImageIdConnectionCountDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_COUNT_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CODE_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CODE_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_COST_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_COST_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionDistinctCountCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionDistinctCountCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionDistinctCountDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionDistinctCountDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionDistinctCountDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionDistinctCountDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionDistinctCountIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CatalogItemsByImageIdConnectionDistinctCountIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CatalogItemsByImageIdConnectionDistinctCountImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionDistinctCountImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionDistinctCountIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionDistinctCountIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionDistinctCountIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionDistinctCountIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionDistinctCountManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionDistinctCountManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionDistinctCountMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionDistinctCountMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionDistinctCountNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  CatalogItemsByImageIdConnectionDistinctCountNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  CatalogItemsByImageIdConnectionDistinctCountPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_PRICE_ASC',
  CatalogItemsByImageIdConnectionDistinctCountPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_PRICE_DESC',
  CatalogItemsByImageIdConnectionDistinctCountSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_SKU_ASC',
  CatalogItemsByImageIdConnectionDistinctCountSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_SKU_DESC',
  CatalogItemsByImageIdConnectionDistinctCountSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_SPEC_ASC',
  CatalogItemsByImageIdConnectionDistinctCountSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_SPEC_DESC',
  CatalogItemsByImageIdConnectionDistinctCountTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CatalogItemsByImageIdConnectionDistinctCountTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CatalogItemsByImageIdConnectionDistinctCountUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionDistinctCountUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionMaxCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionMaxCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionMaxCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CODE_ASC',
  CatalogItemsByImageIdConnectionMaxCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CODE_DESC',
  CatalogItemsByImageIdConnectionMaxCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionMaxCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionMaxCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_COST_ASC',
  CatalogItemsByImageIdConnectionMaxCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_COST_DESC',
  CatalogItemsByImageIdConnectionMaxCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionMaxCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionMaxCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionMaxCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionMaxDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionMaxDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionMaxDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionMaxDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionMaxIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_ID_ASC',
  CatalogItemsByImageIdConnectionMaxIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_ID_DESC',
  CatalogItemsByImageIdConnectionMaxImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionMaxImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionMaxIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionMaxIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionMaxIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionMaxIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionMaxManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionMaxManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionMaxMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionMaxMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionMaxNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_NAME_ASC',
  CatalogItemsByImageIdConnectionMaxNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_NAME_DESC',
  CatalogItemsByImageIdConnectionMaxPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_PRICE_ASC',
  CatalogItemsByImageIdConnectionMaxPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_PRICE_DESC',
  CatalogItemsByImageIdConnectionMaxSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_SKU_ASC',
  CatalogItemsByImageIdConnectionMaxSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_SKU_DESC',
  CatalogItemsByImageIdConnectionMaxSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_SPEC_ASC',
  CatalogItemsByImageIdConnectionMaxSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_SPEC_DESC',
  CatalogItemsByImageIdConnectionMaxTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_TYPE_ASC',
  CatalogItemsByImageIdConnectionMaxTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_TYPE_DESC',
  CatalogItemsByImageIdConnectionMaxUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionMaxUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionMinCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionMinCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionMinCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CODE_ASC',
  CatalogItemsByImageIdConnectionMinCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CODE_DESC',
  CatalogItemsByImageIdConnectionMinCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionMinCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionMinCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_COST_ASC',
  CatalogItemsByImageIdConnectionMinCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_COST_DESC',
  CatalogItemsByImageIdConnectionMinCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionMinCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionMinCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionMinCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionMinDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionMinDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionMinDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionMinDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionMinIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_ID_ASC',
  CatalogItemsByImageIdConnectionMinIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_ID_DESC',
  CatalogItemsByImageIdConnectionMinImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionMinImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionMinIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionMinIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionMinIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionMinIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionMinManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionMinManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionMinMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionMinMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionMinNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_NAME_ASC',
  CatalogItemsByImageIdConnectionMinNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_NAME_DESC',
  CatalogItemsByImageIdConnectionMinPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_PRICE_ASC',
  CatalogItemsByImageIdConnectionMinPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_PRICE_DESC',
  CatalogItemsByImageIdConnectionMinSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_SKU_ASC',
  CatalogItemsByImageIdConnectionMinSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_SKU_DESC',
  CatalogItemsByImageIdConnectionMinSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_SPEC_ASC',
  CatalogItemsByImageIdConnectionMinSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_SPEC_DESC',
  CatalogItemsByImageIdConnectionMinTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_TYPE_ASC',
  CatalogItemsByImageIdConnectionMinTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_TYPE_DESC',
  CatalogItemsByImageIdConnectionMinUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionMinUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CODE_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CODE_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_COST_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_COST_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_PRICE_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_PRICE_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_SKU_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_SKU_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_SPEC_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_SPEC_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CatalogItemsByImageIdConnectionStddevPopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionStddevPopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CODE_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CODE_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_COST_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_COST_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionStddevSampleCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionStddevSampleCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionStddevSampleDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionStddevSampleDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionStddevSampleDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionStddevSampleDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionStddevSampleIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CatalogItemsByImageIdConnectionStddevSampleIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CatalogItemsByImageIdConnectionStddevSampleImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionStddevSampleImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionStddevSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionStddevSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionStddevSampleIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionStddevSampleIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionStddevSampleManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionStddevSampleManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionStddevSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionStddevSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionStddevSampleNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  CatalogItemsByImageIdConnectionStddevSampleNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  CatalogItemsByImageIdConnectionStddevSamplePriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_PRICE_ASC',
  CatalogItemsByImageIdConnectionStddevSamplePriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_PRICE_DESC',
  CatalogItemsByImageIdConnectionStddevSampleSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_SKU_ASC',
  CatalogItemsByImageIdConnectionStddevSampleSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_SKU_DESC',
  CatalogItemsByImageIdConnectionStddevSampleSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_SPEC_ASC',
  CatalogItemsByImageIdConnectionStddevSampleSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_SPEC_DESC',
  CatalogItemsByImageIdConnectionStddevSampleTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CatalogItemsByImageIdConnectionStddevSampleTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CatalogItemsByImageIdConnectionStddevSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionStddevSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionSumCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionSumCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionSumCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CODE_ASC',
  CatalogItemsByImageIdConnectionSumCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CODE_DESC',
  CatalogItemsByImageIdConnectionSumCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionSumCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionSumCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_COST_ASC',
  CatalogItemsByImageIdConnectionSumCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_COST_DESC',
  CatalogItemsByImageIdConnectionSumCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionSumCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionSumCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionSumCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionSumDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionSumDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionSumDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionSumDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionSumIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_ID_ASC',
  CatalogItemsByImageIdConnectionSumIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_ID_DESC',
  CatalogItemsByImageIdConnectionSumImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionSumImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionSumIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionSumIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionSumIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionSumIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionSumManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionSumManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionSumMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionSumMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionSumNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_NAME_ASC',
  CatalogItemsByImageIdConnectionSumNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_NAME_DESC',
  CatalogItemsByImageIdConnectionSumPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_PRICE_ASC',
  CatalogItemsByImageIdConnectionSumPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_PRICE_DESC',
  CatalogItemsByImageIdConnectionSumSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_SKU_ASC',
  CatalogItemsByImageIdConnectionSumSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_SKU_DESC',
  CatalogItemsByImageIdConnectionSumSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_SPEC_ASC',
  CatalogItemsByImageIdConnectionSumSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_SPEC_DESC',
  CatalogItemsByImageIdConnectionSumTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_TYPE_ASC',
  CatalogItemsByImageIdConnectionSumTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_TYPE_DESC',
  CatalogItemsByImageIdConnectionSumUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionSumUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CODE_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CODE_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_COST_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_COST_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationPriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_PRICE_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationPriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_PRICE_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_SKU_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_SKU_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_SPEC_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_SPEC_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CatalogItemsByImageIdConnectionVariancePopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionVariancePopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCodeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CODE_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCodeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CODE_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCostAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_COST_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCostDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_COST_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleCreatedByAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleCreatedByDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleDatasheetAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleDatasheetDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_DATASHEET_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleDescriptionAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleDescriptionDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleImageIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleImageIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleIsCustomAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleIsCustomDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleManufacturerAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleManufacturerDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleNameAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleNameDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  CatalogItemsByImageIdConnectionVarianceSamplePriceAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_ASC',
  CatalogItemsByImageIdConnectionVarianceSamplePriceDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRICE_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleSkuAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_SKU_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleSkuDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_SKU_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleSpecAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleSpecDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_SPEC_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleTypeAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleTypeDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CatalogItemsByImageIdConnectionVarianceSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByImageIdConnectionVarianceSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_IMAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByFileIdConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsByFileIdConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsByFileIdConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionArrayAggCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsByFileIdConnectionArrayAggCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsByFileIdConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsByFileIdConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsByFileIdConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsByFileIdConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsByFileIdConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsByFileIdConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsByFileIdConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionArrayAggFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsByFileIdConnectionArrayAggFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsByFileIdConnectionArrayAggIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsByFileIdConnectionArrayAggIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsByFileIdConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsByFileIdConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsByFileIdConnectionArrayAggParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsByFileIdConnectionArrayAggParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsByFileIdConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsByFileIdConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsByFileIdConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsByFileIdConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsByFileIdConnectionArrayAggScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsByFileIdConnectionArrayAggScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsByFileIdConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsByFileIdConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsByFileIdConnectionArrayAggTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsByFileIdConnectionArrayAggTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsByFileIdConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsByFileIdConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsByFileIdConnectionArrayAggXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsByFileIdConnectionArrayAggXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsByFileIdConnectionArrayAggYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsByFileIdConnectionArrayAggYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsByFileIdConnectionAverageAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsByFileIdConnectionAverageAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsByFileIdConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionAverageCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsByFileIdConnectionAverageCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsByFileIdConnectionAverageCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsByFileIdConnectionAverageCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsByFileIdConnectionAverageCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsByFileIdConnectionAverageCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsByFileIdConnectionAverageCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsByFileIdConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionAverageCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsByFileIdConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionAverageFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsByFileIdConnectionAverageFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsByFileIdConnectionAverageIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ID_ASC',
  CommentsByFileIdConnectionAverageIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_ID_DESC',
  CommentsByFileIdConnectionAverageIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsByFileIdConnectionAverageIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsByFileIdConnectionAverageParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsByFileIdConnectionAverageParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsByFileIdConnectionAveragePinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsByFileIdConnectionAveragePinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsByFileIdConnectionAveragePreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionAveragePreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionAverageProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsByFileIdConnectionAverageProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsByFileIdConnectionAverageScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsByFileIdConnectionAverageScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsByFileIdConnectionAverageSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionAverageSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionAverageTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsByFileIdConnectionAverageTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsByFileIdConnectionAverageTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsByFileIdConnectionAverageTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsByFileIdConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsByFileIdConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsByFileIdConnectionAverageXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_X_ASC',
  CommentsByFileIdConnectionAverageXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_X_DESC',
  CommentsByFileIdConnectionAverageYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_Y_ASC',
  CommentsByFileIdConnectionAverageYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_AVERAGE_Y_DESC',
  CommentsByFileIdConnectionCountAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_COUNT_ASC',
  CommentsByFileIdConnectionCountDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_COUNT_DESC',
  CommentsByFileIdConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsByFileIdConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsByFileIdConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionDistinctCountCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsByFileIdConnectionDistinctCountCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsByFileIdConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsByFileIdConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsByFileIdConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsByFileIdConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsByFileIdConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsByFileIdConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsByFileIdConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsByFileIdConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsByFileIdConnectionDistinctCountIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsByFileIdConnectionDistinctCountIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsByFileIdConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsByFileIdConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsByFileIdConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsByFileIdConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsByFileIdConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsByFileIdConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsByFileIdConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsByFileIdConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsByFileIdConnectionDistinctCountScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsByFileIdConnectionDistinctCountScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsByFileIdConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsByFileIdConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsByFileIdConnectionDistinctCountTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsByFileIdConnectionDistinctCountTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsByFileIdConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsByFileIdConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsByFileIdConnectionDistinctCountXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsByFileIdConnectionDistinctCountXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsByFileIdConnectionDistinctCountYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsByFileIdConnectionDistinctCountYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsByFileIdConnectionMaxAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsByFileIdConnectionMaxAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsByFileIdConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionMaxCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_COMMENT_ASC',
  CommentsByFileIdConnectionMaxCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_COMMENT_DESC',
  CommentsByFileIdConnectionMaxCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsByFileIdConnectionMaxCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsByFileIdConnectionMaxCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsByFileIdConnectionMaxCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsByFileIdConnectionMaxCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsByFileIdConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionMaxCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsByFileIdConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionMaxFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_ASC',
  CommentsByFileIdConnectionMaxFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_DESC',
  CommentsByFileIdConnectionMaxIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ID_ASC',
  CommentsByFileIdConnectionMaxIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_ID_DESC',
  CommentsByFileIdConnectionMaxIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsByFileIdConnectionMaxIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsByFileIdConnectionMaxParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsByFileIdConnectionMaxParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsByFileIdConnectionMaxPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsByFileIdConnectionMaxPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsByFileIdConnectionMaxPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionMaxPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionMaxProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsByFileIdConnectionMaxProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsByFileIdConnectionMaxScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_SCOPE_ASC',
  CommentsByFileIdConnectionMaxScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_SCOPE_DESC',
  CommentsByFileIdConnectionMaxSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionMaxSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionMaxTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_TASK_ID_ASC',
  CommentsByFileIdConnectionMaxTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_TASK_ID_DESC',
  CommentsByFileIdConnectionMaxTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_TYPE_ASC',
  CommentsByFileIdConnectionMaxTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_TYPE_DESC',
  CommentsByFileIdConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsByFileIdConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsByFileIdConnectionMaxXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_X_ASC',
  CommentsByFileIdConnectionMaxXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_X_DESC',
  CommentsByFileIdConnectionMaxYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_Y_ASC',
  CommentsByFileIdConnectionMaxYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MAX_Y_DESC',
  CommentsByFileIdConnectionMinAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsByFileIdConnectionMinAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsByFileIdConnectionMinAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionMinAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionMinCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_COMMENT_ASC',
  CommentsByFileIdConnectionMinCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_COMMENT_DESC',
  CommentsByFileIdConnectionMinCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsByFileIdConnectionMinCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsByFileIdConnectionMinCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsByFileIdConnectionMinCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsByFileIdConnectionMinCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsByFileIdConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionMinCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsByFileIdConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionMinFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_ASC',
  CommentsByFileIdConnectionMinFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_DESC',
  CommentsByFileIdConnectionMinIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ID_ASC',
  CommentsByFileIdConnectionMinIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_ID_DESC',
  CommentsByFileIdConnectionMinIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsByFileIdConnectionMinIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsByFileIdConnectionMinParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsByFileIdConnectionMinParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsByFileIdConnectionMinPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsByFileIdConnectionMinPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsByFileIdConnectionMinPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionMinPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionMinProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsByFileIdConnectionMinProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsByFileIdConnectionMinScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_SCOPE_ASC',
  CommentsByFileIdConnectionMinScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_SCOPE_DESC',
  CommentsByFileIdConnectionMinSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionMinSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionMinTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_TASK_ID_ASC',
  CommentsByFileIdConnectionMinTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_TASK_ID_DESC',
  CommentsByFileIdConnectionMinTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_TYPE_ASC',
  CommentsByFileIdConnectionMinTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_TYPE_DESC',
  CommentsByFileIdConnectionMinUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsByFileIdConnectionMinUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsByFileIdConnectionMinXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_X_ASC',
  CommentsByFileIdConnectionMinXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_X_DESC',
  CommentsByFileIdConnectionMinYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_Y_ASC',
  CommentsByFileIdConnectionMinYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_MIN_Y_DESC',
  CommentsByFileIdConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsByFileIdConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsByFileIdConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsByFileIdConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsByFileIdConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsByFileIdConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsByFileIdConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsByFileIdConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsByFileIdConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsByFileIdConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsByFileIdConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsByFileIdConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsByFileIdConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsByFileIdConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsByFileIdConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsByFileIdConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsByFileIdConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsByFileIdConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsByFileIdConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsByFileIdConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsByFileIdConnectionStddevPopulationXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsByFileIdConnectionStddevPopulationXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsByFileIdConnectionStddevPopulationYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsByFileIdConnectionStddevPopulationYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsByFileIdConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsByFileIdConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsByFileIdConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionStddevSampleCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsByFileIdConnectionStddevSampleCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsByFileIdConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsByFileIdConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsByFileIdConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsByFileIdConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsByFileIdConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsByFileIdConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsByFileIdConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsByFileIdConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsByFileIdConnectionStddevSampleIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsByFileIdConnectionStddevSampleIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsByFileIdConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsByFileIdConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsByFileIdConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsByFileIdConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsByFileIdConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsByFileIdConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsByFileIdConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsByFileIdConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsByFileIdConnectionStddevSampleScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsByFileIdConnectionStddevSampleScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsByFileIdConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsByFileIdConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsByFileIdConnectionStddevSampleTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsByFileIdConnectionStddevSampleTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsByFileIdConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsByFileIdConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsByFileIdConnectionStddevSampleXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsByFileIdConnectionStddevSampleXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsByFileIdConnectionStddevSampleYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsByFileIdConnectionStddevSampleYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsByFileIdConnectionSumAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsByFileIdConnectionSumAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsByFileIdConnectionSumAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionSumAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionSumCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_COMMENT_ASC',
  CommentsByFileIdConnectionSumCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_COMMENT_DESC',
  CommentsByFileIdConnectionSumCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsByFileIdConnectionSumCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsByFileIdConnectionSumCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsByFileIdConnectionSumCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsByFileIdConnectionSumCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsByFileIdConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionSumCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsByFileIdConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionSumFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_ASC',
  CommentsByFileIdConnectionSumFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_DESC',
  CommentsByFileIdConnectionSumIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ID_ASC',
  CommentsByFileIdConnectionSumIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_ID_DESC',
  CommentsByFileIdConnectionSumIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsByFileIdConnectionSumIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsByFileIdConnectionSumParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsByFileIdConnectionSumParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsByFileIdConnectionSumPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsByFileIdConnectionSumPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsByFileIdConnectionSumPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionSumPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionSumProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsByFileIdConnectionSumProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsByFileIdConnectionSumScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_SCOPE_ASC',
  CommentsByFileIdConnectionSumScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_SCOPE_DESC',
  CommentsByFileIdConnectionSumSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionSumSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionSumTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_TASK_ID_ASC',
  CommentsByFileIdConnectionSumTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_TASK_ID_DESC',
  CommentsByFileIdConnectionSumTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_TYPE_ASC',
  CommentsByFileIdConnectionSumTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_TYPE_DESC',
  CommentsByFileIdConnectionSumUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsByFileIdConnectionSumUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsByFileIdConnectionSumXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_X_ASC',
  CommentsByFileIdConnectionSumXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_X_DESC',
  CommentsByFileIdConnectionSumYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_Y_ASC',
  CommentsByFileIdConnectionSumYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_SUM_Y_DESC',
  CommentsByFileIdConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsByFileIdConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsByFileIdConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsByFileIdConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsByFileIdConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsByFileIdConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsByFileIdConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsByFileIdConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsByFileIdConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsByFileIdConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsByFileIdConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsByFileIdConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsByFileIdConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsByFileIdConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsByFileIdConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsByFileIdConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsByFileIdConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsByFileIdConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsByFileIdConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsByFileIdConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsByFileIdConnectionVariancePopulationXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsByFileIdConnectionVariancePopulationXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsByFileIdConnectionVariancePopulationYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsByFileIdConnectionVariancePopulationYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsByFileIdConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsByFileIdConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsByFileIdConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsByFileIdConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsByFileIdConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsByFileIdConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsByFileIdConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsByFileIdConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsByFileIdConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByFileIdConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByFileIdConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsByFileIdConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsByFileIdConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsByFileIdConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsByFileIdConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsByFileIdConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByFileIdConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsByFileIdConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsByFileIdConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsByFileIdConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsByFileIdConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsByFileIdConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsByFileIdConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsByFileIdConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByFileIdConnectionVarianceSampleXAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsByFileIdConnectionVarianceSampleXDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsByFileIdConnectionVarianceSampleYAsc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsByFileIdConnectionVarianceSampleYDesc = 'COMMENTS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CommentIdAsc = 'COMMENT_ID_ASC',
  CommentIdDesc = 'COMMENT_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByContactIdAsc = 'CREATED_BY_CONTACT_ID_ASC',
  CreatedByContactIdDesc = 'CREATED_BY_CONTACT_ID_DESC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedByTypeAsc = 'CREATED_BY_TYPE_ASC',
  CreatedByTypeDesc = 'CREATED_BY_TYPE_DESC',
  DownloadUrlAsc = 'DOWNLOAD_URL_ASC',
  DownloadUrlDesc = 'DOWNLOAD_URL_DESC',
  FeedsByFileIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByFileIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByFileIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByFileIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByFileIdConnectionArrayAggCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByFileIdConnectionArrayAggCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByFileIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByFileIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByFileIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByFileIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByFileIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByFileIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByFileIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByFileIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByFileIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionArrayAggEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByFileIdConnectionArrayAggEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByFileIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByFileIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByFileIdConnectionArrayAggIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByFileIdConnectionArrayAggIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByFileIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByFileIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByFileIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByFileIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByFileIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByFileIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByFileIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByFileIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByFileIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByFileIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByFileIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByFileIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByFileIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByFileIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByFileIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionAverageActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByFileIdConnectionAverageActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByFileIdConnectionAverageCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByFileIdConnectionAverageCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByFileIdConnectionAverageCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByFileIdConnectionAverageCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByFileIdConnectionAverageCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByFileIdConnectionAverageCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByFileIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByFileIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByFileIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByFileIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByFileIdConnectionAverageCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByFileIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionAverageCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByFileIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionAverageEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByFileIdConnectionAverageEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByFileIdConnectionAverageEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionAverageEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionAverageFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByFileIdConnectionAverageFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByFileIdConnectionAverageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsByFileIdConnectionAverageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsByFileIdConnectionAverageParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByFileIdConnectionAverageParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByFileIdConnectionAveragePayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByFileIdConnectionAveragePayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByFileIdConnectionAverageProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByFileIdConnectionAverageProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByFileIdConnectionAverageReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByFileIdConnectionAverageReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByFileIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByFileIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByFileIdConnectionAverageRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByFileIdConnectionAverageRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByFileIdConnectionAverageRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionAverageRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionAverageSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionAverageSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionAverageSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionAverageSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionAverageTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByFileIdConnectionAverageTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByFileIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_COUNT_ASC',
  FeedsByFileIdConnectionCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_COUNT_DESC',
  FeedsByFileIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByFileIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByFileIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByFileIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByFileIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByFileIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByFileIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByFileIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByFileIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByFileIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByFileIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByFileIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByFileIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByFileIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByFileIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionDistinctCountEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByFileIdConnectionDistinctCountEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByFileIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByFileIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByFileIdConnectionDistinctCountIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByFileIdConnectionDistinctCountIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByFileIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByFileIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByFileIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByFileIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByFileIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByFileIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByFileIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByFileIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByFileIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByFileIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByFileIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByFileIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByFileIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByFileIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByFileIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionMaxActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByFileIdConnectionMaxActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByFileIdConnectionMaxCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByFileIdConnectionMaxCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByFileIdConnectionMaxCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsByFileIdConnectionMaxCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsByFileIdConnectionMaxCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByFileIdConnectionMaxCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByFileIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByFileIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByFileIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByFileIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByFileIdConnectionMaxCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByFileIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionMaxCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByFileIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionMaxEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsByFileIdConnectionMaxEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsByFileIdConnectionMaxEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionMaxEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionMaxFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByFileIdConnectionMaxFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByFileIdConnectionMaxIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_ID_ASC',
  FeedsByFileIdConnectionMaxIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_ID_DESC',
  FeedsByFileIdConnectionMaxParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByFileIdConnectionMaxParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByFileIdConnectionMaxPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByFileIdConnectionMaxPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByFileIdConnectionMaxProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByFileIdConnectionMaxProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByFileIdConnectionMaxReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByFileIdConnectionMaxReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByFileIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByFileIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByFileIdConnectionMaxRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByFileIdConnectionMaxRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByFileIdConnectionMaxRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionMaxRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionMaxSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionMaxSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionMaxSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionMaxSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionMaxTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByFileIdConnectionMaxTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByFileIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionMinActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByFileIdConnectionMinActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByFileIdConnectionMinCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByFileIdConnectionMinCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByFileIdConnectionMinCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsByFileIdConnectionMinCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsByFileIdConnectionMinCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByFileIdConnectionMinCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByFileIdConnectionMinCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByFileIdConnectionMinCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByFileIdConnectionMinCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByFileIdConnectionMinCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByFileIdConnectionMinCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByFileIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionMinCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByFileIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionMinEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsByFileIdConnectionMinEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsByFileIdConnectionMinEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionMinEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionMinFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByFileIdConnectionMinFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByFileIdConnectionMinIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_ID_ASC',
  FeedsByFileIdConnectionMinIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_ID_DESC',
  FeedsByFileIdConnectionMinParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByFileIdConnectionMinParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByFileIdConnectionMinPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByFileIdConnectionMinPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByFileIdConnectionMinProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByFileIdConnectionMinProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByFileIdConnectionMinReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByFileIdConnectionMinReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByFileIdConnectionMinRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByFileIdConnectionMinRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByFileIdConnectionMinRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByFileIdConnectionMinRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByFileIdConnectionMinRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionMinRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionMinSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionMinSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionMinSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionMinSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionMinTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByFileIdConnectionMinTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByFileIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByFileIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByFileIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByFileIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByFileIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByFileIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByFileIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByFileIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByFileIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByFileIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByFileIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByFileIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByFileIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByFileIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByFileIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByFileIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByFileIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByFileIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByFileIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByFileIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByFileIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByFileIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByFileIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByFileIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByFileIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByFileIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByFileIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByFileIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByFileIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByFileIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionStddevSampleEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByFileIdConnectionStddevSampleEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByFileIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByFileIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByFileIdConnectionStddevSampleIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByFileIdConnectionStddevSampleIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByFileIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByFileIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByFileIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByFileIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByFileIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByFileIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByFileIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByFileIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByFileIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByFileIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByFileIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByFileIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByFileIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByFileIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByFileIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionSumActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByFileIdConnectionSumActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByFileIdConnectionSumCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByFileIdConnectionSumCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByFileIdConnectionSumCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsByFileIdConnectionSumCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsByFileIdConnectionSumCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByFileIdConnectionSumCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByFileIdConnectionSumCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByFileIdConnectionSumCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByFileIdConnectionSumCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByFileIdConnectionSumCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByFileIdConnectionSumCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByFileIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionSumCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByFileIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionSumEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsByFileIdConnectionSumEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsByFileIdConnectionSumEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionSumEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionSumFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByFileIdConnectionSumFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByFileIdConnectionSumIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_ID_ASC',
  FeedsByFileIdConnectionSumIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_ID_DESC',
  FeedsByFileIdConnectionSumParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByFileIdConnectionSumParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByFileIdConnectionSumPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByFileIdConnectionSumPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByFileIdConnectionSumProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByFileIdConnectionSumProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByFileIdConnectionSumReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByFileIdConnectionSumReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByFileIdConnectionSumRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByFileIdConnectionSumRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByFileIdConnectionSumRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByFileIdConnectionSumRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByFileIdConnectionSumRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionSumRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionSumSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionSumSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionSumSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionSumSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionSumTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByFileIdConnectionSumTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByFileIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByFileIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByFileIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByFileIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByFileIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByFileIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByFileIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByFileIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByFileIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByFileIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByFileIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByFileIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByFileIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByFileIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByFileIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByFileIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByFileIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByFileIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByFileIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByFileIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByFileIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByFileIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByFileIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByFileIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByFileIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByFileIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByFileIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByFileIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByFileIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByFileIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByFileIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByFileIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByFileIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByFileIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByFileIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByFileIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByFileIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByFileIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByFileIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByFileIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByFileIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByFileIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByFileIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FilesByValueFileIdConnectionArrayAggActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByValueFileIdConnectionArrayAggActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByValueFileIdConnectionArrayAggActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionArrayAggActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionArrayAggAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionArrayAggAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionArrayAggCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionArrayAggCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionArrayAggCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionArrayAggCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionArrayAggCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByValueFileIdConnectionArrayAggCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByValueFileIdConnectionArrayAggCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByValueFileIdConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionArrayAggCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByValueFileIdConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionArrayAggDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionArrayAggDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionArrayAggFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByValueFileIdConnectionArrayAggFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByValueFileIdConnectionArrayAggIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByValueFileIdConnectionArrayAggIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByValueFileIdConnectionArrayAggIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionArrayAggIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionArrayAggIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByValueFileIdConnectionArrayAggIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByValueFileIdConnectionArrayAggMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByValueFileIdConnectionArrayAggMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByValueFileIdConnectionArrayAggModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionArrayAggModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionArrayAggNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByValueFileIdConnectionArrayAggNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByValueFileIdConnectionArrayAggOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByValueFileIdConnectionArrayAggOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByValueFileIdConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionArrayAggPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionArrayAggPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionArrayAggProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionArrayAggProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionArrayAggProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionArrayAggProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionArrayAggSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionArrayAggSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionArrayAggStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByValueFileIdConnectionArrayAggStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByValueFileIdConnectionArrayAggTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByValueFileIdConnectionArrayAggTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByValueFileIdConnectionArrayAggUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionArrayAggUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionArrayAggUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByValueFileIdConnectionArrayAggUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByValueFileIdConnectionArrayAggValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionArrayAggValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionAverageActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByValueFileIdConnectionAverageActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByValueFileIdConnectionAverageActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionAverageActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionAverageAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionAverageAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionAverageCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionAverageCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionAverageCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionAverageCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionAverageCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByValueFileIdConnectionAverageCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByValueFileIdConnectionAverageCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByValueFileIdConnectionAverageCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionAverageCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionAverageCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByValueFileIdConnectionAverageCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionAverageCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionAverageDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionAverageDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionAverageFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByValueFileIdConnectionAverageFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByValueFileIdConnectionAverageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ID_ASC',
  FilesByValueFileIdConnectionAverageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ID_DESC',
  FilesByValueFileIdConnectionAverageIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionAverageIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionAverageIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByValueFileIdConnectionAverageIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByValueFileIdConnectionAverageMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByValueFileIdConnectionAverageMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByValueFileIdConnectionAverageModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionAverageModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionAverageNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_NAME_ASC',
  FilesByValueFileIdConnectionAverageNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_NAME_DESC',
  FilesByValueFileIdConnectionAverageOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByValueFileIdConnectionAverageOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByValueFileIdConnectionAveragePrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionAveragePrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionAveragePrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionAveragePrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionAveragePrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionAveragePrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionAveragePrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionAveragePrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionAverageProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionAverageProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionAverageProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionAverageProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionAverageSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionAverageSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionAverageSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionAverageSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionAverageStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByValueFileIdConnectionAverageStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByValueFileIdConnectionAverageTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByValueFileIdConnectionAverageTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByValueFileIdConnectionAverageUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionAverageUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionAverageUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_UUID_ASC',
  FilesByValueFileIdConnectionAverageUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_UUID_DESC',
  FilesByValueFileIdConnectionAverageValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionAverageValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionCountAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_COUNT_ASC',
  FilesByValueFileIdConnectionCountDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_COUNT_DESC',
  FilesByValueFileIdConnectionDistinctCountActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionDistinctCountAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionDistinctCountCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByValueFileIdConnectionDistinctCountCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByValueFileIdConnectionDistinctCountCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByValueFileIdConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByValueFileIdConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionDistinctCountFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionDistinctCountIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionDistinctCountIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByValueFileIdConnectionDistinctCountIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByValueFileIdConnectionDistinctCountMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByValueFileIdConnectionDistinctCountMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByValueFileIdConnectionDistinctCountModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionDistinctCountModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionDistinctCountNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByValueFileIdConnectionDistinctCountNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByValueFileIdConnectionDistinctCountOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByValueFileIdConnectionDistinctCountOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionDistinctCountStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByValueFileIdConnectionDistinctCountTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByValueFileIdConnectionDistinctCountTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByValueFileIdConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionDistinctCountUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByValueFileIdConnectionDistinctCountUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByValueFileIdConnectionDistinctCountValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionDistinctCountValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionMaxActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByValueFileIdConnectionMaxActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByValueFileIdConnectionMaxActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionMaxActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionMaxAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionMaxAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionMaxCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionMaxCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionMaxCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionMaxCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionMaxCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByValueFileIdConnectionMaxCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByValueFileIdConnectionMaxCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByValueFileIdConnectionMaxCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionMaxCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionMaxCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByValueFileIdConnectionMaxCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionMaxCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionMaxDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionMaxDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionMaxFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_FORM_ID_ASC',
  FilesByValueFileIdConnectionMaxFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_FORM_ID_DESC',
  FilesByValueFileIdConnectionMaxIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ID_ASC',
  FilesByValueFileIdConnectionMaxIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ID_DESC',
  FilesByValueFileIdConnectionMaxIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionMaxIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionMaxIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_IS_FORM_ASC',
  FilesByValueFileIdConnectionMaxIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_IS_FORM_DESC',
  FilesByValueFileIdConnectionMaxMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_META_DATA_ASC',
  FilesByValueFileIdConnectionMaxMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_META_DATA_DESC',
  FilesByValueFileIdConnectionMaxModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionMaxModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionMaxNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_NAME_ASC',
  FilesByValueFileIdConnectionMaxNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_NAME_DESC',
  FilesByValueFileIdConnectionMaxOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ORIGIN_ASC',
  FilesByValueFileIdConnectionMaxOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_ORIGIN_DESC',
  FilesByValueFileIdConnectionMaxPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionMaxPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionMaxPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionMaxPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionMaxPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionMaxPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionMaxPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionMaxPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionMaxProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionMaxProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionMaxProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionMaxProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionMaxSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionMaxSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionMaxSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionMaxSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionMaxStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByValueFileIdConnectionMaxStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByValueFileIdConnectionMaxTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_TYPE_ASC',
  FilesByValueFileIdConnectionMaxTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_TYPE_DESC',
  FilesByValueFileIdConnectionMaxUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionMaxUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionMaxUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_UUID_ASC',
  FilesByValueFileIdConnectionMaxUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_UUID_DESC',
  FilesByValueFileIdConnectionMaxValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionMaxValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionMinActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByValueFileIdConnectionMinActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByValueFileIdConnectionMinActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionMinActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionMinAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionMinAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionMinCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionMinCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionMinCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionMinCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionMinCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByValueFileIdConnectionMinCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByValueFileIdConnectionMinCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByValueFileIdConnectionMinCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionMinCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionMinCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByValueFileIdConnectionMinCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionMinCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionMinDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionMinDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionMinFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_FORM_ID_ASC',
  FilesByValueFileIdConnectionMinFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_FORM_ID_DESC',
  FilesByValueFileIdConnectionMinIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ID_ASC',
  FilesByValueFileIdConnectionMinIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ID_DESC',
  FilesByValueFileIdConnectionMinIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionMinIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionMinIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_IS_FORM_ASC',
  FilesByValueFileIdConnectionMinIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_IS_FORM_DESC',
  FilesByValueFileIdConnectionMinMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_META_DATA_ASC',
  FilesByValueFileIdConnectionMinMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_META_DATA_DESC',
  FilesByValueFileIdConnectionMinModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionMinModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionMinNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_NAME_ASC',
  FilesByValueFileIdConnectionMinNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_NAME_DESC',
  FilesByValueFileIdConnectionMinOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ORIGIN_ASC',
  FilesByValueFileIdConnectionMinOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_ORIGIN_DESC',
  FilesByValueFileIdConnectionMinPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionMinPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionMinPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionMinPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionMinPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionMinPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionMinPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionMinPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionMinProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionMinProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionMinProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionMinProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionMinProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionMinProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionMinSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionMinSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionMinSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionMinSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionMinStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByValueFileIdConnectionMinStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByValueFileIdConnectionMinTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_TYPE_ASC',
  FilesByValueFileIdConnectionMinTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_TYPE_DESC',
  FilesByValueFileIdConnectionMinUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionMinUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionMinUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_UUID_ASC',
  FilesByValueFileIdConnectionMinUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_UUID_DESC',
  FilesByValueFileIdConnectionMinValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionMinValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionStddevPopulationCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByValueFileIdConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionStddevPopulationFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionStddevPopulationIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByValueFileIdConnectionStddevPopulationIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByValueFileIdConnectionStddevPopulationMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByValueFileIdConnectionStddevPopulationMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByValueFileIdConnectionStddevPopulationModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionStddevPopulationModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionStddevPopulationNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByValueFileIdConnectionStddevPopulationNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByValueFileIdConnectionStddevPopulationOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByValueFileIdConnectionStddevPopulationOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionStddevPopulationStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByValueFileIdConnectionStddevPopulationTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByValueFileIdConnectionStddevPopulationTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByValueFileIdConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionStddevPopulationUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByValueFileIdConnectionStddevPopulationUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByValueFileIdConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionStddevSampleAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionStddevSampleCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByValueFileIdConnectionStddevSampleCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByValueFileIdConnectionStddevSampleCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByValueFileIdConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByValueFileIdConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionStddevSampleFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionStddevSampleIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionStddevSampleIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByValueFileIdConnectionStddevSampleIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByValueFileIdConnectionStddevSampleMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByValueFileIdConnectionStddevSampleMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByValueFileIdConnectionStddevSampleModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionStddevSampleModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionStddevSampleNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByValueFileIdConnectionStddevSampleNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByValueFileIdConnectionStddevSampleOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByValueFileIdConnectionStddevSampleOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionStddevSampleStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByValueFileIdConnectionStddevSampleTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByValueFileIdConnectionStddevSampleTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByValueFileIdConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionStddevSampleUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByValueFileIdConnectionStddevSampleUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByValueFileIdConnectionStddevSampleValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionStddevSampleValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionSumActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByValueFileIdConnectionSumActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByValueFileIdConnectionSumActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionSumActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionSumAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionSumAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionSumCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionSumCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionSumCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionSumCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionSumCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByValueFileIdConnectionSumCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByValueFileIdConnectionSumCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByValueFileIdConnectionSumCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionSumCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionSumCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByValueFileIdConnectionSumCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionSumCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionSumDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionSumDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionSumFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_FORM_ID_ASC',
  FilesByValueFileIdConnectionSumFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_FORM_ID_DESC',
  FilesByValueFileIdConnectionSumIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ID_ASC',
  FilesByValueFileIdConnectionSumIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ID_DESC',
  FilesByValueFileIdConnectionSumIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionSumIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionSumIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_IS_FORM_ASC',
  FilesByValueFileIdConnectionSumIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_IS_FORM_DESC',
  FilesByValueFileIdConnectionSumMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_META_DATA_ASC',
  FilesByValueFileIdConnectionSumMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_META_DATA_DESC',
  FilesByValueFileIdConnectionSumModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionSumModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionSumNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_NAME_ASC',
  FilesByValueFileIdConnectionSumNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_NAME_DESC',
  FilesByValueFileIdConnectionSumOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ORIGIN_ASC',
  FilesByValueFileIdConnectionSumOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_ORIGIN_DESC',
  FilesByValueFileIdConnectionSumPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionSumPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionSumPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionSumPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionSumPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionSumPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionSumPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionSumPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionSumProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionSumProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionSumProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionSumProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionSumProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionSumProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionSumSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionSumSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionSumSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionSumSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionSumStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByValueFileIdConnectionSumStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByValueFileIdConnectionSumTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_TYPE_ASC',
  FilesByValueFileIdConnectionSumTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_TYPE_DESC',
  FilesByValueFileIdConnectionSumUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionSumUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionSumUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_UUID_ASC',
  FilesByValueFileIdConnectionSumUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_UUID_DESC',
  FilesByValueFileIdConnectionSumValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionSumValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionVariancePopulationCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByValueFileIdConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionVariancePopulationFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionVariancePopulationIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByValueFileIdConnectionVariancePopulationIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByValueFileIdConnectionVariancePopulationMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByValueFileIdConnectionVariancePopulationMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByValueFileIdConnectionVariancePopulationModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionVariancePopulationModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionVariancePopulationNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByValueFileIdConnectionVariancePopulationNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByValueFileIdConnectionVariancePopulationOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByValueFileIdConnectionVariancePopulationOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionVariancePopulationStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByValueFileIdConnectionVariancePopulationTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByValueFileIdConnectionVariancePopulationTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByValueFileIdConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionVariancePopulationUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByValueFileIdConnectionVariancePopulationUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByValueFileIdConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleActionIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleActionIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByValueFileIdConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByValueFileIdConnectionVarianceSampleCommentIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleCommentIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByValueFileIdConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByValueFileIdConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByValueFileIdConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByValueFileIdConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByValueFileIdConnectionVarianceSampleFormIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleFormIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByValueFileIdConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByValueFileIdConnectionVarianceSampleIsFormAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByValueFileIdConnectionVarianceSampleIsFormDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByValueFileIdConnectionVarianceSampleMetaDataAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByValueFileIdConnectionVarianceSampleMetaDataDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByValueFileIdConnectionVarianceSampleModifiedByAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByValueFileIdConnectionVarianceSampleModifiedByDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByValueFileIdConnectionVarianceSampleNameAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByValueFileIdConnectionVarianceSampleNameDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByValueFileIdConnectionVarianceSampleOriginAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByValueFileIdConnectionVarianceSampleOriginDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByValueFileIdConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByValueFileIdConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByValueFileIdConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByValueFileIdConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleProjectIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleProjectIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByValueFileIdConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByValueFileIdConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByValueFileIdConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByValueFileIdConnectionVarianceSampleStageIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleStageIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByValueFileIdConnectionVarianceSampleTypeAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByValueFileIdConnectionVarianceSampleTypeDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByValueFileIdConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByValueFileIdConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByValueFileIdConnectionVarianceSampleUuidAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByValueFileIdConnectionVarianceSampleUuidDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByValueFileIdConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByValueFileIdConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_VALUE_FILE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FormsByFileIdConnectionArrayAggCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FormsByFileIdConnectionArrayAggCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FormsByFileIdConnectionArrayAggCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FormsByFileIdConnectionArrayAggCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FormsByFileIdConnectionArrayAggCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FormsByFileIdConnectionArrayAggCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FormsByFileIdConnectionArrayAggDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  FormsByFileIdConnectionArrayAggDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  FormsByFileIdConnectionArrayAggFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FormsByFileIdConnectionArrayAggFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FormsByFileIdConnectionArrayAggIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FormsByFileIdConnectionArrayAggIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FormsByFileIdConnectionArrayAggIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  FormsByFileIdConnectionArrayAggIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  FormsByFileIdConnectionArrayAggIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionArrayAggIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionArrayAggMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_MEMBERS_ASC',
  FormsByFileIdConnectionArrayAggMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_MEMBERS_DESC',
  FormsByFileIdConnectionArrayAggNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FormsByFileIdConnectionArrayAggNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FormsByFileIdConnectionArrayAggOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_OLD_FIELDS_ASC',
  FormsByFileIdConnectionArrayAggOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_OLD_FIELDS_DESC',
  FormsByFileIdConnectionArrayAggPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionArrayAggPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionArrayAggPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FormsByFileIdConnectionArrayAggPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FormsByFileIdConnectionArrayAggPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionArrayAggPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionArrayAggPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionArrayAggPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionArrayAggStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FormsByFileIdConnectionArrayAggStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FormsByFileIdConnectionArrayAggTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionArrayAggTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionArrayAggUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FormsByFileIdConnectionArrayAggUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FormsByFileIdConnectionAverageCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FormsByFileIdConnectionAverageCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FormsByFileIdConnectionAverageCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FormsByFileIdConnectionAverageCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FormsByFileIdConnectionAverageCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FormsByFileIdConnectionAverageCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FormsByFileIdConnectionAverageDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  FormsByFileIdConnectionAverageDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  FormsByFileIdConnectionAverageFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FormsByFileIdConnectionAverageFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FormsByFileIdConnectionAverageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_ID_ASC',
  FormsByFileIdConnectionAverageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_ID_DESC',
  FormsByFileIdConnectionAverageIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  FormsByFileIdConnectionAverageIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  FormsByFileIdConnectionAverageIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionAverageIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionAverageMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_MEMBERS_ASC',
  FormsByFileIdConnectionAverageMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_MEMBERS_DESC',
  FormsByFileIdConnectionAverageNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_NAME_ASC',
  FormsByFileIdConnectionAverageNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_NAME_DESC',
  FormsByFileIdConnectionAverageOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_OLD_FIELDS_ASC',
  FormsByFileIdConnectionAverageOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_OLD_FIELDS_DESC',
  FormsByFileIdConnectionAveragePrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionAveragePrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionAveragePrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FormsByFileIdConnectionAveragePrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FormsByFileIdConnectionAveragePrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionAveragePrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionAveragePrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionAveragePrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionAverageStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FormsByFileIdConnectionAverageStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FormsByFileIdConnectionAverageTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionAverageTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionAverageUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FormsByFileIdConnectionAverageUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FormsByFileIdConnectionCountAsc = 'FORMS_BY_FILE_ID_CONNECTION_COUNT_ASC',
  FormsByFileIdConnectionCountDesc = 'FORMS_BY_FILE_ID_CONNECTION_COUNT_DESC',
  FormsByFileIdConnectionDistinctCountCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FormsByFileIdConnectionDistinctCountCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FormsByFileIdConnectionDistinctCountCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FormsByFileIdConnectionDistinctCountCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FormsByFileIdConnectionDistinctCountCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FormsByFileIdConnectionDistinctCountCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FormsByFileIdConnectionDistinctCountDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  FormsByFileIdConnectionDistinctCountDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  FormsByFileIdConnectionDistinctCountFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FormsByFileIdConnectionDistinctCountFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FormsByFileIdConnectionDistinctCountIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FormsByFileIdConnectionDistinctCountIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FormsByFileIdConnectionDistinctCountIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  FormsByFileIdConnectionDistinctCountIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  FormsByFileIdConnectionDistinctCountIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionDistinctCountIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionDistinctCountMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_MEMBERS_ASC',
  FormsByFileIdConnectionDistinctCountMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_MEMBERS_DESC',
  FormsByFileIdConnectionDistinctCountNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FormsByFileIdConnectionDistinctCountNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FormsByFileIdConnectionDistinctCountOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_OLD_FIELDS_ASC',
  FormsByFileIdConnectionDistinctCountOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_OLD_FIELDS_DESC',
  FormsByFileIdConnectionDistinctCountPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionDistinctCountPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionDistinctCountPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FormsByFileIdConnectionDistinctCountPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FormsByFileIdConnectionDistinctCountPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionDistinctCountPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionDistinctCountPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionDistinctCountPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionDistinctCountStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FormsByFileIdConnectionDistinctCountStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FormsByFileIdConnectionDistinctCountTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionDistinctCountTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionDistinctCountUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FormsByFileIdConnectionDistinctCountUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FormsByFileIdConnectionMaxCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FormsByFileIdConnectionMaxCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FormsByFileIdConnectionMaxCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FormsByFileIdConnectionMaxCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FormsByFileIdConnectionMaxCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FormsByFileIdConnectionMaxCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FormsByFileIdConnectionMaxDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  FormsByFileIdConnectionMaxDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  FormsByFileIdConnectionMaxFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_ASC',
  FormsByFileIdConnectionMaxFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_FILE_ID_DESC',
  FormsByFileIdConnectionMaxIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_ID_ASC',
  FormsByFileIdConnectionMaxIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_ID_DESC',
  FormsByFileIdConnectionMaxIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  FormsByFileIdConnectionMaxIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  FormsByFileIdConnectionMaxIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionMaxIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionMaxMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_MEMBERS_ASC',
  FormsByFileIdConnectionMaxMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_MEMBERS_DESC',
  FormsByFileIdConnectionMaxNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_NAME_ASC',
  FormsByFileIdConnectionMaxNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_NAME_DESC',
  FormsByFileIdConnectionMaxOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_OLD_FIELDS_ASC',
  FormsByFileIdConnectionMaxOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_OLD_FIELDS_DESC',
  FormsByFileIdConnectionMaxPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionMaxPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionMaxPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FormsByFileIdConnectionMaxPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FormsByFileIdConnectionMaxPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionMaxPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionMaxPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionMaxPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionMaxStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FormsByFileIdConnectionMaxStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FormsByFileIdConnectionMaxTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionMaxTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionMaxUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FormsByFileIdConnectionMaxUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FormsByFileIdConnectionMinCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FormsByFileIdConnectionMinCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FormsByFileIdConnectionMinCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FormsByFileIdConnectionMinCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FormsByFileIdConnectionMinCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FormsByFileIdConnectionMinCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FormsByFileIdConnectionMinDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  FormsByFileIdConnectionMinDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  FormsByFileIdConnectionMinFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_ASC',
  FormsByFileIdConnectionMinFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_FILE_ID_DESC',
  FormsByFileIdConnectionMinIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_ID_ASC',
  FormsByFileIdConnectionMinIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_ID_DESC',
  FormsByFileIdConnectionMinIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  FormsByFileIdConnectionMinIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  FormsByFileIdConnectionMinIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionMinIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionMinMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_MEMBERS_ASC',
  FormsByFileIdConnectionMinMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_MEMBERS_DESC',
  FormsByFileIdConnectionMinNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_NAME_ASC',
  FormsByFileIdConnectionMinNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_NAME_DESC',
  FormsByFileIdConnectionMinOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_OLD_FIELDS_ASC',
  FormsByFileIdConnectionMinOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_OLD_FIELDS_DESC',
  FormsByFileIdConnectionMinPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionMinPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionMinPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FormsByFileIdConnectionMinPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FormsByFileIdConnectionMinPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionMinPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionMinPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionMinPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionMinStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FormsByFileIdConnectionMinStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FormsByFileIdConnectionMinTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionMinTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionMinUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FormsByFileIdConnectionMinUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FormsByFileIdConnectionStddevPopulationCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FormsByFileIdConnectionStddevPopulationCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FormsByFileIdConnectionStddevPopulationCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FormsByFileIdConnectionStddevPopulationCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FormsByFileIdConnectionStddevPopulationCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FormsByFileIdConnectionStddevPopulationCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FormsByFileIdConnectionStddevPopulationDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  FormsByFileIdConnectionStddevPopulationDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  FormsByFileIdConnectionStddevPopulationFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FormsByFileIdConnectionStddevPopulationFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FormsByFileIdConnectionStddevPopulationIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FormsByFileIdConnectionStddevPopulationIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FormsByFileIdConnectionStddevPopulationIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  FormsByFileIdConnectionStddevPopulationIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  FormsByFileIdConnectionStddevPopulationIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionStddevPopulationIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionStddevPopulationMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_MEMBERS_ASC',
  FormsByFileIdConnectionStddevPopulationMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_MEMBERS_DESC',
  FormsByFileIdConnectionStddevPopulationNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FormsByFileIdConnectionStddevPopulationNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FormsByFileIdConnectionStddevPopulationOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_OLD_FIELDS_ASC',
  FormsByFileIdConnectionStddevPopulationOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_OLD_FIELDS_DESC',
  FormsByFileIdConnectionStddevPopulationPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionStddevPopulationPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionStddevPopulationPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FormsByFileIdConnectionStddevPopulationPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FormsByFileIdConnectionStddevPopulationPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionStddevPopulationPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionStddevPopulationPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionStddevPopulationPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionStddevPopulationStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FormsByFileIdConnectionStddevPopulationStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FormsByFileIdConnectionStddevPopulationTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionStddevPopulationTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionStddevPopulationUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FormsByFileIdConnectionStddevPopulationUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FormsByFileIdConnectionStddevSampleCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FormsByFileIdConnectionStddevSampleCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FormsByFileIdConnectionStddevSampleCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FormsByFileIdConnectionStddevSampleCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FormsByFileIdConnectionStddevSampleCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FormsByFileIdConnectionStddevSampleCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FormsByFileIdConnectionStddevSampleDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  FormsByFileIdConnectionStddevSampleDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  FormsByFileIdConnectionStddevSampleFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FormsByFileIdConnectionStddevSampleFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FormsByFileIdConnectionStddevSampleIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FormsByFileIdConnectionStddevSampleIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FormsByFileIdConnectionStddevSampleIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  FormsByFileIdConnectionStddevSampleIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  FormsByFileIdConnectionStddevSampleIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionStddevSampleIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionStddevSampleMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_MEMBERS_ASC',
  FormsByFileIdConnectionStddevSampleMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_MEMBERS_DESC',
  FormsByFileIdConnectionStddevSampleNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FormsByFileIdConnectionStddevSampleNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FormsByFileIdConnectionStddevSampleOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_OLD_FIELDS_ASC',
  FormsByFileIdConnectionStddevSampleOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_OLD_FIELDS_DESC',
  FormsByFileIdConnectionStddevSamplePrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionStddevSamplePrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionStddevSamplePrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FormsByFileIdConnectionStddevSamplePrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FormsByFileIdConnectionStddevSamplePrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionStddevSamplePrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionStddevSamplePrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionStddevSamplePrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionStddevSampleStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FormsByFileIdConnectionStddevSampleStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FormsByFileIdConnectionStddevSampleTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionStddevSampleTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionStddevSampleUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FormsByFileIdConnectionStddevSampleUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FormsByFileIdConnectionSumCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FormsByFileIdConnectionSumCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FormsByFileIdConnectionSumCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FormsByFileIdConnectionSumCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FormsByFileIdConnectionSumCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FormsByFileIdConnectionSumCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FormsByFileIdConnectionSumDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  FormsByFileIdConnectionSumDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  FormsByFileIdConnectionSumFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_ASC',
  FormsByFileIdConnectionSumFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_FILE_ID_DESC',
  FormsByFileIdConnectionSumIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_ID_ASC',
  FormsByFileIdConnectionSumIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_ID_DESC',
  FormsByFileIdConnectionSumIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  FormsByFileIdConnectionSumIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  FormsByFileIdConnectionSumIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionSumIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionSumMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_MEMBERS_ASC',
  FormsByFileIdConnectionSumMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_MEMBERS_DESC',
  FormsByFileIdConnectionSumNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_NAME_ASC',
  FormsByFileIdConnectionSumNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_NAME_DESC',
  FormsByFileIdConnectionSumOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_OLD_FIELDS_ASC',
  FormsByFileIdConnectionSumOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_OLD_FIELDS_DESC',
  FormsByFileIdConnectionSumPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionSumPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionSumPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FormsByFileIdConnectionSumPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FormsByFileIdConnectionSumPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionSumPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionSumPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionSumPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionSumStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FormsByFileIdConnectionSumStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FormsByFileIdConnectionSumTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionSumTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionSumUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FormsByFileIdConnectionSumUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FormsByFileIdConnectionVariancePopulationCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FormsByFileIdConnectionVariancePopulationCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FormsByFileIdConnectionVariancePopulationCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FormsByFileIdConnectionVariancePopulationCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FormsByFileIdConnectionVariancePopulationCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FormsByFileIdConnectionVariancePopulationCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FormsByFileIdConnectionVariancePopulationDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  FormsByFileIdConnectionVariancePopulationDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  FormsByFileIdConnectionVariancePopulationFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FormsByFileIdConnectionVariancePopulationFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FormsByFileIdConnectionVariancePopulationIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FormsByFileIdConnectionVariancePopulationIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FormsByFileIdConnectionVariancePopulationIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  FormsByFileIdConnectionVariancePopulationIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  FormsByFileIdConnectionVariancePopulationIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionVariancePopulationIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionVariancePopulationMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_MEMBERS_ASC',
  FormsByFileIdConnectionVariancePopulationMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_MEMBERS_DESC',
  FormsByFileIdConnectionVariancePopulationNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FormsByFileIdConnectionVariancePopulationNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FormsByFileIdConnectionVariancePopulationOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_OLD_FIELDS_ASC',
  FormsByFileIdConnectionVariancePopulationOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_OLD_FIELDS_DESC',
  FormsByFileIdConnectionVariancePopulationPrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionVariancePopulationPrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionVariancePopulationPrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FormsByFileIdConnectionVariancePopulationPrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FormsByFileIdConnectionVariancePopulationPrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionVariancePopulationPrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionVariancePopulationPrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionVariancePopulationPrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionVariancePopulationStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FormsByFileIdConnectionVariancePopulationStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FormsByFileIdConnectionVariancePopulationTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionVariancePopulationTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionVariancePopulationUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FormsByFileIdConnectionVariancePopulationUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FormsByFileIdConnectionVarianceSampleCompanyIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FormsByFileIdConnectionVarianceSampleCompanyIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FormsByFileIdConnectionVarianceSampleCreatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FormsByFileIdConnectionVarianceSampleCreatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FormsByFileIdConnectionVarianceSampleCreatedByAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FormsByFileIdConnectionVarianceSampleCreatedByDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FormsByFileIdConnectionVarianceSampleDescriptionAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  FormsByFileIdConnectionVarianceSampleDescriptionDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  FormsByFileIdConnectionVarianceSampleFileIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FormsByFileIdConnectionVarianceSampleFileIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FormsByFileIdConnectionVarianceSampleIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FormsByFileIdConnectionVarianceSampleIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FormsByFileIdConnectionVarianceSampleIsCompletedAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  FormsByFileIdConnectionVarianceSampleIsCompletedDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  FormsByFileIdConnectionVarianceSampleIsTemplateAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  FormsByFileIdConnectionVarianceSampleIsTemplateDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  FormsByFileIdConnectionVarianceSampleMembersAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_MEMBERS_ASC',
  FormsByFileIdConnectionVarianceSampleMembersDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_MEMBERS_DESC',
  FormsByFileIdConnectionVarianceSampleNameAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FormsByFileIdConnectionVarianceSampleNameDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FormsByFileIdConnectionVarianceSampleOldFieldsAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_OLD_FIELDS_ASC',
  FormsByFileIdConnectionVarianceSampleOldFieldsDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_OLD_FIELDS_DESC',
  FormsByFileIdConnectionVarianceSamplePrivilegeAllAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FormsByFileIdConnectionVarianceSamplePrivilegeAllDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FormsByFileIdConnectionVarianceSamplePrivilegeAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FormsByFileIdConnectionVarianceSamplePrivilegeDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FormsByFileIdConnectionVarianceSamplePrivilegeOwnAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FormsByFileIdConnectionVarianceSamplePrivilegeOwnDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FormsByFileIdConnectionVarianceSamplePrivilegeTeamAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FormsByFileIdConnectionVarianceSamplePrivilegeTeamDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FormsByFileIdConnectionVarianceSampleStageIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FormsByFileIdConnectionVarianceSampleStageIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FormsByFileIdConnectionVarianceSampleTemplateIdAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  FormsByFileIdConnectionVarianceSampleTemplateIdDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  FormsByFileIdConnectionVarianceSampleUpdatedAtAsc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FormsByFileIdConnectionVarianceSampleUpdatedAtDesc = 'FORMS_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FormIdAsc = 'FORM_ID_ASC',
  FormIdDesc = 'FORM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsFormAsc = 'IS_FORM_ASC',
  IsFormDesc = 'IS_FORM_DESC',
  MetaDataAsc = 'META_DATA_ASC',
  MetaDataDesc = 'META_DATA_DESC',
  ModifiedByAsc = 'MODIFIED_BY_ASC',
  ModifiedByDesc = 'MODIFIED_BY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeAsc = 'PRIVILEGE_ASC',
  PrivilegeDesc = 'PRIVILEGE_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  PrivilegeTeamAsc = 'PRIVILEGE_TEAM_ASC',
  PrivilegeTeamDesc = 'PRIVILEGE_TEAM_DESC',
  ProcessingStatusAsc = 'PROCESSING_STATUS_ASC',
  ProcessingStatusDesc = 'PROCESSING_STATUS_DESC',
  ProjectColumnValueFileIdAsc = 'PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectColumnValueFileIdDesc = 'PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectColumnValueIdAsc = 'PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectColumnValueIdDesc = 'PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionArrayAggIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionAverageIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionCountAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_COUNT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionCountDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_COUNT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionDistinctCountIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMaxIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MAX_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMinColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMinColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMinCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMinCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMinFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMinFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionMinIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionMinIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_MIN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionStddevSampleIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionSumColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionSumColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionSumCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionSumCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionSumFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionSumFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionSumIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionSumIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_SUM_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertyFileReferencesByFileIdConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionArrayAggFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionArrayAggFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionArrayAggProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionArrayAggProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionAverageFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionAverageFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionAverageProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionAverageProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionCountAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_COUNT_ASC',
  ProjectPropertyValueFilesByFileIdConnectionCountDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_COUNT_DESC',
  ProjectPropertyValueFilesByFileIdConnectionDistinctCountFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionDistinctCountFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionDistinctCountProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionDistinctCountProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionMaxFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MAX_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionMaxFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MAX_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionMaxProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionMaxProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionMinFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MIN_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionMinFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MIN_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionMinProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionMinProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionStddevPopulationFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionStddevPopulationFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionStddevPopulationProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionStddevPopulationProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionStddevSampleFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionStddevSampleFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionStddevSampleProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionStddevSampleProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionSumFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_SUM_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionSumFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_SUM_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionSumProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionSumProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionVariancePopulationFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionVariancePopulationFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionVariancePopulationProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionVariancePopulationProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionVarianceSampleFileIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionVarianceSampleFileIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  ProjectPropertyValueFilesByFileIdConnectionVarianceSampleProjectColumnValueIdAsc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectPropertyValueFilesByFileIdConnectionVarianceSampleProjectColumnValueIdDesc = 'PROJECT_PROPERTY_VALUE_FILES_BY_FILE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  SharedWithPortalAsc = 'SHARED_WITH_PORTAL_ASC',
  SharedWithPortalDesc = 'SHARED_WITH_PORTAL_DESC',
  SizeInBytesAsc = 'SIZE_IN_BYTES_ASC',
  SizeInBytesDesc = 'SIZE_IN_BYTES_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  ValueFileIdAsc = 'VALUE_FILE_ID_ASC',
  ValueFileIdDesc = 'VALUE_FILE_ID_DESC'
}

export type Filter = Node & {
  /** Reads a single `Company` that is related to this `Filter`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  conditions: Maybe<Scalars['JSON']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `Filter`. */
  createdByUser: Maybe<MainUser>;
  emoji: Maybe<Scalars['String']['output']>;
  /** Reads a single `FilterFolder` that is related to this `Filter`. */
  filterFolder: Maybe<FilterFolder>;
  filterFolderId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `FilterToRole`. */
  filterToRolesByFilterId: Array<FilterToRole>;
  /** Reads and enables pagination through a set of `FilterToTeam`. */
  filterToTeamsByFilterId: Array<FilterToTeam>;
  /** Reads and enables pagination through a set of `FilterToUser`. */
  filterToUsersByFilterId: Array<FilterToUser>;
  id: Scalars['Int']['output'];
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByFilterToUserFilterIdAndUserIdList: Array<MainUser>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Role`. */
  rolesByFilterToRoleFilterIdAndRoleIdList: Array<Role>;
  scope: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByFilterToTeamFilterIdAndTeamId: FilterTeamsByFilterToTeamFilterIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByFilterToTeamFilterIdAndTeamIdList: Array<Team>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FilterFilterToRolesByFilterIdArgs = {
  condition: InputMaybe<FilterToRoleCondition>;
  filter: InputMaybe<FilterToRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToRolesOrderBy>>;
};


export type FilterFilterToTeamsByFilterIdArgs = {
  condition: InputMaybe<FilterToTeamCondition>;
  filter: InputMaybe<FilterToTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToTeamsOrderBy>>;
};


export type FilterFilterToUsersByFilterIdArgs = {
  condition: InputMaybe<FilterToUserCondition>;
  filter: InputMaybe<FilterToUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToUsersOrderBy>>;
};


export type FilterMainUsersByFilterToUserFilterIdAndUserIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type FilterRolesByFilterToRoleFilterIdAndRoleIdListArgs = {
  condition: InputMaybe<RoleCondition>;
  filter: InputMaybe<RoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RolesOrderBy>>;
};


export type FilterTeamsByFilterToTeamFilterIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type FilterTeamsByFilterToTeamFilterIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type FilterAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FilterArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FilterAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FilterDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FilterMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FilterMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FilterStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FilterStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FilterSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FilterVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FilterVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Filter` object types. */
export type FilterAggregatesFilter = {
  /** Array aggregation aggregate over matching `Filter` objects. */
  arrayAgg: InputMaybe<FilterArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Filter` objects. */
  average: InputMaybe<FilterAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Filter` objects. */
  distinctCount: InputMaybe<FilterDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Filter` object to be included within the aggregate. */
  filter: InputMaybe<FilterFilter>;
  /** Maximum aggregate over matching `Filter` objects. */
  max: InputMaybe<FilterMaxAggregateFilter>;
  /** Minimum aggregate over matching `Filter` objects. */
  min: InputMaybe<FilterMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Filter` objects. */
  stddevPopulation: InputMaybe<FilterStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Filter` objects. */
  stddevSample: InputMaybe<FilterStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Filter` objects. */
  sum: InputMaybe<FilterSumAggregateFilter>;
  /** Population variance aggregate over matching `Filter` objects. */
  variancePopulation: InputMaybe<FilterVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Filter` objects. */
  varianceSample: InputMaybe<FilterVarianceSampleAggregateFilter>;
};

export type FilterArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  filterFolderId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isDefault: InputMaybe<BooleanListFilter>;
};

export type FilterArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of filterFolderId across the matching connection */
  filterFolderId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isDefault across the matching connection */
  isDefault: Array<Maybe<Scalars['Boolean']['output']>>;
};


export type FilterArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterArrayAggAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterArrayAggAggregatesIsDefaultArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterAverageAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

/** A condition to be used against `Filter` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FilterCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `conditions` field. */
  conditions: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `emoji` field. */
  emoji: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `filterFolderId` field. */
  filterFolderId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isDefault` field. */
  isDefault: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type FilterDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  conditions: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  emoji: InputMaybe<BigIntFilter>;
  filterFolderId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isDefault: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type FilterDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of conditions across the matching connection */
  conditions: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emoji across the matching connection */
  emoji: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isDefault across the matching connection */
  isDefault: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scope across the matching connection */
  scope: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type FilterDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesConditionsArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesEmojiArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesIsDefaultArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesScopeArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

/** A filter to be used against `Filter` object types. All fields are combined with a logical ‘and.’ */
export type FilterFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FilterFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `conditions` field. */
  conditions: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emoji` field. */
  emoji: InputMaybe<StringFilter>;
  /** Filter by the object’s `filterFolder` relation. */
  filterFolder: InputMaybe<FilterFolderFilter>;
  /** A related `filterFolder` exists. */
  filterFolderExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterFolderId` field. */
  filterFolderId: InputMaybe<IntFilter>;
  /** Filter by the object’s `filterToRolesByFilterId` relation. */
  filterToRolesByFilterId: InputMaybe<FilterToManyFilterToRoleFilter>;
  /** Some related `filterToRolesByFilterId` exist. */
  filterToRolesByFilterIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterToTeamsByFilterId` relation. */
  filterToTeamsByFilterId: InputMaybe<FilterToManyFilterToTeamFilter>;
  /** Some related `filterToTeamsByFilterId` exist. */
  filterToTeamsByFilterIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterToUsersByFilterId` relation. */
  filterToUsersByFilterId: InputMaybe<FilterToManyFilterToUserFilter>;
  /** Some related `filterToUsersByFilterId` exist. */
  filterToUsersByFilterIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isDefault` field. */
  isDefault: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<FilterFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FilterFilter>>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FilterFolder = Node & {
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDashboardFilterFolderIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFilterFilterFolderIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `FilterFolder`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `FilterFolder`. */
  createdByUser: Maybe<MainUser>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboards: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsConnection: DashboardsConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filters: Array<Filter>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersConnection: FiltersConnection;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByFilterFilterFolderIdAndCreatedByList: Array<MainUser>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByDashboardFilterFolderIdAndCreatedBy: FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyConnection;
  uuid: Scalars['UUID']['output'];
};


export type FilterFolderCompaniesByDashboardFilterFolderIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type FilterFolderCompaniesByFilterFilterFolderIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type FilterFolderDashboardsArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type FilterFolderDashboardsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};


export type FilterFolderFiltersArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterFolderFiltersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterFolderMainUsersByFilterFilterFolderIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type FilterFolderAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FilterFolderArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FilterFolderAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FilterFolderDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FilterFolderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FilterFolderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FilterFolderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FilterFolderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FilterFolderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FilterFolderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FilterFolderVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `FilterFolder` object types. */
export type FilterFolderAggregatesFilter = {
  /** Array aggregation aggregate over matching `FilterFolder` objects. */
  arrayAgg: InputMaybe<FilterFolderArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FilterFolder` objects. */
  average: InputMaybe<FilterFolderAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FilterFolder` objects. */
  distinctCount: InputMaybe<FilterFolderDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FilterFolder` object to be included within the aggregate. */
  filter: InputMaybe<FilterFolderFilter>;
  /** Maximum aggregate over matching `FilterFolder` objects. */
  max: InputMaybe<FilterFolderMaxAggregateFilter>;
  /** Minimum aggregate over matching `FilterFolder` objects. */
  min: InputMaybe<FilterFolderMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FilterFolder` objects. */
  stddevPopulation: InputMaybe<FilterFolderStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FilterFolder` objects. */
  stddevSample: InputMaybe<FilterFolderStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FilterFolder` objects. */
  sum: InputMaybe<FilterFolderSumAggregateFilter>;
  /** Population variance aggregate over matching `FilterFolder` objects. */
  variancePopulation: InputMaybe<FilterFolderVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FilterFolder` objects. */
  varianceSample: InputMaybe<FilterFolderVarianceSampleAggregateFilter>;
};

export type FilterFolderArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  name: InputMaybe<StringListFilter>;
};

export type FilterFolderArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
};


export type FilterFolderArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterFolderAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterFolderAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

/**
 * A condition to be used against `FilterFolder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FilterFolderCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
};

export type FilterFolderDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
};

export type FilterFolderDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
};


export type FilterFolderDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

/** A filter to be used against `FilterFolder` object types. All fields are combined with a logical ‘and.’ */
export type FilterFolderFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FilterFolderFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboards` relation. */
  dashboards: InputMaybe<FilterFolderToManyDashboardFilter>;
  /** Some related `dashboards` exist. */
  dashboardsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filters` relation. */
  filters: InputMaybe<FilterFolderToManyFilterFilter>;
  /** Some related `filters` exist. */
  filtersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<FilterFolderFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FilterFolderFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
};

/** Grouping methods for `FilterFolder` for usage during aggregation. */
export enum FilterFolderGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type FilterFolderHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `FilterFolder` aggregates. */
export type FilterFolderHavingInput = {
  AND: InputMaybe<Array<FilterFolderHavingInput>>;
  OR: InputMaybe<Array<FilterFolderHavingInput>>;
  arrayAgg: InputMaybe<FilterFolderHavingArrayAggInput>;
  average: InputMaybe<FilterFolderHavingAverageInput>;
  distinctCount: InputMaybe<FilterFolderHavingDistinctCountInput>;
  max: InputMaybe<FilterFolderHavingMaxInput>;
  min: InputMaybe<FilterFolderHavingMinInput>;
  stddevPopulation: InputMaybe<FilterFolderHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FilterFolderHavingStddevSampleInput>;
  sum: InputMaybe<FilterFolderHavingSumInput>;
  variancePopulation: InputMaybe<FilterFolderHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FilterFolderHavingVarianceSampleInput>;
};

export type FilterFolderHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterFolderMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FilterFolderMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FilterFolderMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FilterFolderMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FilterFolderMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterFolderStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterFolderStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterFolderStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterFolderStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type FilterFolderSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type FilterFolderSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

/** A filter to be used against many `Dashboard` object types. All fields are combined with a logical ‘and.’ */
export type FilterFolderToManyDashboardFilter = {
  /** Aggregates across related `Dashboard` match the filter criteria. */
  aggregates: InputMaybe<DashboardAggregatesFilter>;
  /** Every related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardFilter>;
  /** No related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardFilter>;
  /** Some related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardFilter>;
};

/** A filter to be used against many `Filter` object types. All fields are combined with a logical ‘and.’ */
export type FilterFolderToManyFilterFilter = {
  /** Aggregates across related `Filter` match the filter criteria. */
  aggregates: InputMaybe<FilterAggregatesFilter>;
  /** Every related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterFilter>;
  /** No related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterFilter>;
  /** Some related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterFilter>;
};

/** A connection to a list of `User` values, with data from `Dashboard`. */
export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Dashboard`, and the cursor to aid in pagination. */
  edges: Array<FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Dashboard`. */
export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Dashboard`. */
export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedBy: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedByConnection: DashboardsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Dashboard`. */
export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyEdgeDashboardsByCreatedByArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Dashboard`. */
export type FilterFolderUsersByDashboardFilterFolderIdAndCreatedByManyToManyEdgeDashboardsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};

export type FilterFolderVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterFolderVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterFolderVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

export type FilterFolderVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterFolderVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterFolderVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type FilterFolderVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};

/** A connection to a list of `FilterFolder` values. */
export type FilterFoldersConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterFolderAggregates>;
  /** A list of edges which contains the `FilterFolder` and cursor to aid in pagination. */
  edges: Array<FilterFoldersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterFolderAggregates>>;
  /** A list of `FilterFolder` objects. */
  nodes: Array<Maybe<FilterFolder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FilterFolder` values. */
export type FilterFoldersConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterFolderGroupBy>;
  having: InputMaybe<FilterFolderHavingInput>;
};

/** A `FilterFolder` edge in the connection. */
export type FilterFoldersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `FilterFolder` at the end of the edge. */
  node: Maybe<FilterFolder>;
};

/** Methods to use when ordering `FilterFolder`. */
export enum FilterFoldersOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DashboardsConnectionArrayAggCompanyIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  DashboardsConnectionArrayAggCompanyIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  DashboardsConnectionArrayAggCreatedAtAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DashboardsConnectionArrayAggCreatedAtDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DashboardsConnectionArrayAggCreatedByAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  DashboardsConnectionArrayAggCreatedByDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  DashboardsConnectionArrayAggCustomizationAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CUSTOMIZATION_ASC',
  DashboardsConnectionArrayAggCustomizationDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_CUSTOMIZATION_DESC',
  DashboardsConnectionArrayAggDescriptionAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  DashboardsConnectionArrayAggDescriptionDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  DashboardsConnectionArrayAggEmojiAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  DashboardsConnectionArrayAggEmojiDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  DashboardsConnectionArrayAggFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionArrayAggFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionArrayAggIdAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_ID_ASC',
  DashboardsConnectionArrayAggIdDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_ID_DESC',
  DashboardsConnectionArrayAggLayoutAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_LAYOUT_ASC',
  DashboardsConnectionArrayAggLayoutDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_LAYOUT_DESC',
  DashboardsConnectionArrayAggNameAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_NAME_ASC',
  DashboardsConnectionArrayAggNameDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_NAME_DESC',
  DashboardsConnectionArrayAggPrivilegeAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  DashboardsConnectionArrayAggPrivilegeDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  DashboardsConnectionArrayAggScopeAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  DashboardsConnectionArrayAggScopeDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  DashboardsConnectionArrayAggUpdatedAtAsc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  DashboardsConnectionArrayAggUpdatedAtDesc = 'DASHBOARDS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  DashboardsConnectionAverageCompanyIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  DashboardsConnectionAverageCompanyIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  DashboardsConnectionAverageCreatedAtAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DashboardsConnectionAverageCreatedAtDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DashboardsConnectionAverageCreatedByAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  DashboardsConnectionAverageCreatedByDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  DashboardsConnectionAverageCustomizationAsc = 'DASHBOARDS_CONNECTION_AVERAGE_CUSTOMIZATION_ASC',
  DashboardsConnectionAverageCustomizationDesc = 'DASHBOARDS_CONNECTION_AVERAGE_CUSTOMIZATION_DESC',
  DashboardsConnectionAverageDescriptionAsc = 'DASHBOARDS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  DashboardsConnectionAverageDescriptionDesc = 'DASHBOARDS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  DashboardsConnectionAverageEmojiAsc = 'DASHBOARDS_CONNECTION_AVERAGE_EMOJI_ASC',
  DashboardsConnectionAverageEmojiDesc = 'DASHBOARDS_CONNECTION_AVERAGE_EMOJI_DESC',
  DashboardsConnectionAverageFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionAverageFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionAverageIdAsc = 'DASHBOARDS_CONNECTION_AVERAGE_ID_ASC',
  DashboardsConnectionAverageIdDesc = 'DASHBOARDS_CONNECTION_AVERAGE_ID_DESC',
  DashboardsConnectionAverageLayoutAsc = 'DASHBOARDS_CONNECTION_AVERAGE_LAYOUT_ASC',
  DashboardsConnectionAverageLayoutDesc = 'DASHBOARDS_CONNECTION_AVERAGE_LAYOUT_DESC',
  DashboardsConnectionAverageNameAsc = 'DASHBOARDS_CONNECTION_AVERAGE_NAME_ASC',
  DashboardsConnectionAverageNameDesc = 'DASHBOARDS_CONNECTION_AVERAGE_NAME_DESC',
  DashboardsConnectionAveragePrivilegeAsc = 'DASHBOARDS_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  DashboardsConnectionAveragePrivilegeDesc = 'DASHBOARDS_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  DashboardsConnectionAverageScopeAsc = 'DASHBOARDS_CONNECTION_AVERAGE_SCOPE_ASC',
  DashboardsConnectionAverageScopeDesc = 'DASHBOARDS_CONNECTION_AVERAGE_SCOPE_DESC',
  DashboardsConnectionAverageUpdatedAtAsc = 'DASHBOARDS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  DashboardsConnectionAverageUpdatedAtDesc = 'DASHBOARDS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  DashboardsConnectionCountAsc = 'DASHBOARDS_CONNECTION_COUNT_ASC',
  DashboardsConnectionCountDesc = 'DASHBOARDS_CONNECTION_COUNT_DESC',
  DashboardsConnectionDistinctCountCompanyIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  DashboardsConnectionDistinctCountCompanyIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  DashboardsConnectionDistinctCountCreatedAtAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DashboardsConnectionDistinctCountCreatedAtDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DashboardsConnectionDistinctCountCreatedByAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  DashboardsConnectionDistinctCountCreatedByDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  DashboardsConnectionDistinctCountCustomizationAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_ASC',
  DashboardsConnectionDistinctCountCustomizationDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_DESC',
  DashboardsConnectionDistinctCountDescriptionAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  DashboardsConnectionDistinctCountDescriptionDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  DashboardsConnectionDistinctCountEmojiAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  DashboardsConnectionDistinctCountEmojiDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  DashboardsConnectionDistinctCountFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionDistinctCountFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionDistinctCountIdAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  DashboardsConnectionDistinctCountIdDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  DashboardsConnectionDistinctCountLayoutAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_LAYOUT_ASC',
  DashboardsConnectionDistinctCountLayoutDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_LAYOUT_DESC',
  DashboardsConnectionDistinctCountNameAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  DashboardsConnectionDistinctCountNameDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  DashboardsConnectionDistinctCountPrivilegeAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  DashboardsConnectionDistinctCountPrivilegeDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  DashboardsConnectionDistinctCountScopeAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  DashboardsConnectionDistinctCountScopeDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  DashboardsConnectionDistinctCountUpdatedAtAsc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  DashboardsConnectionDistinctCountUpdatedAtDesc = 'DASHBOARDS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  DashboardsConnectionMaxCompanyIdAsc = 'DASHBOARDS_CONNECTION_MAX_COMPANY_ID_ASC',
  DashboardsConnectionMaxCompanyIdDesc = 'DASHBOARDS_CONNECTION_MAX_COMPANY_ID_DESC',
  DashboardsConnectionMaxCreatedAtAsc = 'DASHBOARDS_CONNECTION_MAX_CREATED_AT_ASC',
  DashboardsConnectionMaxCreatedAtDesc = 'DASHBOARDS_CONNECTION_MAX_CREATED_AT_DESC',
  DashboardsConnectionMaxCreatedByAsc = 'DASHBOARDS_CONNECTION_MAX_CREATED_BY_ASC',
  DashboardsConnectionMaxCreatedByDesc = 'DASHBOARDS_CONNECTION_MAX_CREATED_BY_DESC',
  DashboardsConnectionMaxCustomizationAsc = 'DASHBOARDS_CONNECTION_MAX_CUSTOMIZATION_ASC',
  DashboardsConnectionMaxCustomizationDesc = 'DASHBOARDS_CONNECTION_MAX_CUSTOMIZATION_DESC',
  DashboardsConnectionMaxDescriptionAsc = 'DASHBOARDS_CONNECTION_MAX_DESCRIPTION_ASC',
  DashboardsConnectionMaxDescriptionDesc = 'DASHBOARDS_CONNECTION_MAX_DESCRIPTION_DESC',
  DashboardsConnectionMaxEmojiAsc = 'DASHBOARDS_CONNECTION_MAX_EMOJI_ASC',
  DashboardsConnectionMaxEmojiDesc = 'DASHBOARDS_CONNECTION_MAX_EMOJI_DESC',
  DashboardsConnectionMaxFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionMaxFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionMaxIdAsc = 'DASHBOARDS_CONNECTION_MAX_ID_ASC',
  DashboardsConnectionMaxIdDesc = 'DASHBOARDS_CONNECTION_MAX_ID_DESC',
  DashboardsConnectionMaxLayoutAsc = 'DASHBOARDS_CONNECTION_MAX_LAYOUT_ASC',
  DashboardsConnectionMaxLayoutDesc = 'DASHBOARDS_CONNECTION_MAX_LAYOUT_DESC',
  DashboardsConnectionMaxNameAsc = 'DASHBOARDS_CONNECTION_MAX_NAME_ASC',
  DashboardsConnectionMaxNameDesc = 'DASHBOARDS_CONNECTION_MAX_NAME_DESC',
  DashboardsConnectionMaxPrivilegeAsc = 'DASHBOARDS_CONNECTION_MAX_PRIVILEGE_ASC',
  DashboardsConnectionMaxPrivilegeDesc = 'DASHBOARDS_CONNECTION_MAX_PRIVILEGE_DESC',
  DashboardsConnectionMaxScopeAsc = 'DASHBOARDS_CONNECTION_MAX_SCOPE_ASC',
  DashboardsConnectionMaxScopeDesc = 'DASHBOARDS_CONNECTION_MAX_SCOPE_DESC',
  DashboardsConnectionMaxUpdatedAtAsc = 'DASHBOARDS_CONNECTION_MAX_UPDATED_AT_ASC',
  DashboardsConnectionMaxUpdatedAtDesc = 'DASHBOARDS_CONNECTION_MAX_UPDATED_AT_DESC',
  DashboardsConnectionMinCompanyIdAsc = 'DASHBOARDS_CONNECTION_MIN_COMPANY_ID_ASC',
  DashboardsConnectionMinCompanyIdDesc = 'DASHBOARDS_CONNECTION_MIN_COMPANY_ID_DESC',
  DashboardsConnectionMinCreatedAtAsc = 'DASHBOARDS_CONNECTION_MIN_CREATED_AT_ASC',
  DashboardsConnectionMinCreatedAtDesc = 'DASHBOARDS_CONNECTION_MIN_CREATED_AT_DESC',
  DashboardsConnectionMinCreatedByAsc = 'DASHBOARDS_CONNECTION_MIN_CREATED_BY_ASC',
  DashboardsConnectionMinCreatedByDesc = 'DASHBOARDS_CONNECTION_MIN_CREATED_BY_DESC',
  DashboardsConnectionMinCustomizationAsc = 'DASHBOARDS_CONNECTION_MIN_CUSTOMIZATION_ASC',
  DashboardsConnectionMinCustomizationDesc = 'DASHBOARDS_CONNECTION_MIN_CUSTOMIZATION_DESC',
  DashboardsConnectionMinDescriptionAsc = 'DASHBOARDS_CONNECTION_MIN_DESCRIPTION_ASC',
  DashboardsConnectionMinDescriptionDesc = 'DASHBOARDS_CONNECTION_MIN_DESCRIPTION_DESC',
  DashboardsConnectionMinEmojiAsc = 'DASHBOARDS_CONNECTION_MIN_EMOJI_ASC',
  DashboardsConnectionMinEmojiDesc = 'DASHBOARDS_CONNECTION_MIN_EMOJI_DESC',
  DashboardsConnectionMinFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionMinFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionMinIdAsc = 'DASHBOARDS_CONNECTION_MIN_ID_ASC',
  DashboardsConnectionMinIdDesc = 'DASHBOARDS_CONNECTION_MIN_ID_DESC',
  DashboardsConnectionMinLayoutAsc = 'DASHBOARDS_CONNECTION_MIN_LAYOUT_ASC',
  DashboardsConnectionMinLayoutDesc = 'DASHBOARDS_CONNECTION_MIN_LAYOUT_DESC',
  DashboardsConnectionMinNameAsc = 'DASHBOARDS_CONNECTION_MIN_NAME_ASC',
  DashboardsConnectionMinNameDesc = 'DASHBOARDS_CONNECTION_MIN_NAME_DESC',
  DashboardsConnectionMinPrivilegeAsc = 'DASHBOARDS_CONNECTION_MIN_PRIVILEGE_ASC',
  DashboardsConnectionMinPrivilegeDesc = 'DASHBOARDS_CONNECTION_MIN_PRIVILEGE_DESC',
  DashboardsConnectionMinScopeAsc = 'DASHBOARDS_CONNECTION_MIN_SCOPE_ASC',
  DashboardsConnectionMinScopeDesc = 'DASHBOARDS_CONNECTION_MIN_SCOPE_DESC',
  DashboardsConnectionMinUpdatedAtAsc = 'DASHBOARDS_CONNECTION_MIN_UPDATED_AT_ASC',
  DashboardsConnectionMinUpdatedAtDesc = 'DASHBOARDS_CONNECTION_MIN_UPDATED_AT_DESC',
  DashboardsConnectionStddevPopulationCompanyIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  DashboardsConnectionStddevPopulationCompanyIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  DashboardsConnectionStddevPopulationCreatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DashboardsConnectionStddevPopulationCreatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DashboardsConnectionStddevPopulationCreatedByAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  DashboardsConnectionStddevPopulationCreatedByDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  DashboardsConnectionStddevPopulationCustomizationAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_ASC',
  DashboardsConnectionStddevPopulationCustomizationDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_DESC',
  DashboardsConnectionStddevPopulationDescriptionAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  DashboardsConnectionStddevPopulationDescriptionDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  DashboardsConnectionStddevPopulationEmojiAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  DashboardsConnectionStddevPopulationEmojiDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  DashboardsConnectionStddevPopulationFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionStddevPopulationFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionStddevPopulationIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  DashboardsConnectionStddevPopulationIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  DashboardsConnectionStddevPopulationLayoutAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_LAYOUT_ASC',
  DashboardsConnectionStddevPopulationLayoutDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_LAYOUT_DESC',
  DashboardsConnectionStddevPopulationNameAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  DashboardsConnectionStddevPopulationNameDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  DashboardsConnectionStddevPopulationPrivilegeAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  DashboardsConnectionStddevPopulationPrivilegeDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  DashboardsConnectionStddevPopulationScopeAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  DashboardsConnectionStddevPopulationScopeDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  DashboardsConnectionStddevPopulationUpdatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  DashboardsConnectionStddevPopulationUpdatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  DashboardsConnectionStddevSampleCompanyIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DashboardsConnectionStddevSampleCompanyIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DashboardsConnectionStddevSampleCreatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DashboardsConnectionStddevSampleCreatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DashboardsConnectionStddevSampleCreatedByAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  DashboardsConnectionStddevSampleCreatedByDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  DashboardsConnectionStddevSampleCustomizationAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsConnectionStddevSampleCustomizationDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsConnectionStddevSampleDescriptionAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DashboardsConnectionStddevSampleDescriptionDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DashboardsConnectionStddevSampleEmojiAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  DashboardsConnectionStddevSampleEmojiDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  DashboardsConnectionStddevSampleFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionStddevSampleFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionStddevSampleIdAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  DashboardsConnectionStddevSampleIdDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  DashboardsConnectionStddevSampleLayoutAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_LAYOUT_ASC',
  DashboardsConnectionStddevSampleLayoutDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_LAYOUT_DESC',
  DashboardsConnectionStddevSampleNameAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  DashboardsConnectionStddevSampleNameDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  DashboardsConnectionStddevSamplePrivilegeAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  DashboardsConnectionStddevSamplePrivilegeDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  DashboardsConnectionStddevSampleScopeAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  DashboardsConnectionStddevSampleScopeDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  DashboardsConnectionStddevSampleUpdatedAtAsc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DashboardsConnectionStddevSampleUpdatedAtDesc = 'DASHBOARDS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DashboardsConnectionSumCompanyIdAsc = 'DASHBOARDS_CONNECTION_SUM_COMPANY_ID_ASC',
  DashboardsConnectionSumCompanyIdDesc = 'DASHBOARDS_CONNECTION_SUM_COMPANY_ID_DESC',
  DashboardsConnectionSumCreatedAtAsc = 'DASHBOARDS_CONNECTION_SUM_CREATED_AT_ASC',
  DashboardsConnectionSumCreatedAtDesc = 'DASHBOARDS_CONNECTION_SUM_CREATED_AT_DESC',
  DashboardsConnectionSumCreatedByAsc = 'DASHBOARDS_CONNECTION_SUM_CREATED_BY_ASC',
  DashboardsConnectionSumCreatedByDesc = 'DASHBOARDS_CONNECTION_SUM_CREATED_BY_DESC',
  DashboardsConnectionSumCustomizationAsc = 'DASHBOARDS_CONNECTION_SUM_CUSTOMIZATION_ASC',
  DashboardsConnectionSumCustomizationDesc = 'DASHBOARDS_CONNECTION_SUM_CUSTOMIZATION_DESC',
  DashboardsConnectionSumDescriptionAsc = 'DASHBOARDS_CONNECTION_SUM_DESCRIPTION_ASC',
  DashboardsConnectionSumDescriptionDesc = 'DASHBOARDS_CONNECTION_SUM_DESCRIPTION_DESC',
  DashboardsConnectionSumEmojiAsc = 'DASHBOARDS_CONNECTION_SUM_EMOJI_ASC',
  DashboardsConnectionSumEmojiDesc = 'DASHBOARDS_CONNECTION_SUM_EMOJI_DESC',
  DashboardsConnectionSumFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionSumFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionSumIdAsc = 'DASHBOARDS_CONNECTION_SUM_ID_ASC',
  DashboardsConnectionSumIdDesc = 'DASHBOARDS_CONNECTION_SUM_ID_DESC',
  DashboardsConnectionSumLayoutAsc = 'DASHBOARDS_CONNECTION_SUM_LAYOUT_ASC',
  DashboardsConnectionSumLayoutDesc = 'DASHBOARDS_CONNECTION_SUM_LAYOUT_DESC',
  DashboardsConnectionSumNameAsc = 'DASHBOARDS_CONNECTION_SUM_NAME_ASC',
  DashboardsConnectionSumNameDesc = 'DASHBOARDS_CONNECTION_SUM_NAME_DESC',
  DashboardsConnectionSumPrivilegeAsc = 'DASHBOARDS_CONNECTION_SUM_PRIVILEGE_ASC',
  DashboardsConnectionSumPrivilegeDesc = 'DASHBOARDS_CONNECTION_SUM_PRIVILEGE_DESC',
  DashboardsConnectionSumScopeAsc = 'DASHBOARDS_CONNECTION_SUM_SCOPE_ASC',
  DashboardsConnectionSumScopeDesc = 'DASHBOARDS_CONNECTION_SUM_SCOPE_DESC',
  DashboardsConnectionSumUpdatedAtAsc = 'DASHBOARDS_CONNECTION_SUM_UPDATED_AT_ASC',
  DashboardsConnectionSumUpdatedAtDesc = 'DASHBOARDS_CONNECTION_SUM_UPDATED_AT_DESC',
  DashboardsConnectionVariancePopulationCompanyIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DashboardsConnectionVariancePopulationCompanyIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DashboardsConnectionVariancePopulationCreatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DashboardsConnectionVariancePopulationCreatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DashboardsConnectionVariancePopulationCreatedByAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  DashboardsConnectionVariancePopulationCreatedByDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  DashboardsConnectionVariancePopulationCustomizationAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_ASC',
  DashboardsConnectionVariancePopulationCustomizationDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_DESC',
  DashboardsConnectionVariancePopulationDescriptionAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DashboardsConnectionVariancePopulationDescriptionDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DashboardsConnectionVariancePopulationEmojiAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  DashboardsConnectionVariancePopulationEmojiDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  DashboardsConnectionVariancePopulationFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionVariancePopulationFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionVariancePopulationIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  DashboardsConnectionVariancePopulationIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  DashboardsConnectionVariancePopulationLayoutAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_LAYOUT_ASC',
  DashboardsConnectionVariancePopulationLayoutDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_LAYOUT_DESC',
  DashboardsConnectionVariancePopulationNameAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  DashboardsConnectionVariancePopulationNameDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  DashboardsConnectionVariancePopulationPrivilegeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  DashboardsConnectionVariancePopulationPrivilegeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  DashboardsConnectionVariancePopulationScopeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  DashboardsConnectionVariancePopulationScopeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  DashboardsConnectionVariancePopulationUpdatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DashboardsConnectionVariancePopulationUpdatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DashboardsConnectionVarianceSampleCompanyIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DashboardsConnectionVarianceSampleCompanyIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DashboardsConnectionVarianceSampleCreatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DashboardsConnectionVarianceSampleCreatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DashboardsConnectionVarianceSampleCreatedByAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  DashboardsConnectionVarianceSampleCreatedByDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  DashboardsConnectionVarianceSampleCustomizationAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsConnectionVarianceSampleCustomizationDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsConnectionVarianceSampleDescriptionAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DashboardsConnectionVarianceSampleDescriptionDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DashboardsConnectionVarianceSampleEmojiAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  DashboardsConnectionVarianceSampleEmojiDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  DashboardsConnectionVarianceSampleFilterFolderIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsConnectionVarianceSampleFilterFolderIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsConnectionVarianceSampleIdAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  DashboardsConnectionVarianceSampleIdDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  DashboardsConnectionVarianceSampleLayoutAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_LAYOUT_ASC',
  DashboardsConnectionVarianceSampleLayoutDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_LAYOUT_DESC',
  DashboardsConnectionVarianceSampleNameAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  DashboardsConnectionVarianceSampleNameDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  DashboardsConnectionVarianceSamplePrivilegeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  DashboardsConnectionVarianceSamplePrivilegeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  DashboardsConnectionVarianceSampleScopeAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  DashboardsConnectionVarianceSampleScopeDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  DashboardsConnectionVarianceSampleUpdatedAtAsc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DashboardsConnectionVarianceSampleUpdatedAtDesc = 'DASHBOARDS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FiltersConnectionArrayAggCompanyIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FiltersConnectionArrayAggCompanyIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FiltersConnectionArrayAggConditionsAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CONDITIONS_ASC',
  FiltersConnectionArrayAggConditionsDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CONDITIONS_DESC',
  FiltersConnectionArrayAggCreatedAtAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FiltersConnectionArrayAggCreatedAtDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FiltersConnectionArrayAggCreatedByAsc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FiltersConnectionArrayAggCreatedByDesc = 'FILTERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FiltersConnectionArrayAggEmojiAsc = 'FILTERS_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  FiltersConnectionArrayAggEmojiDesc = 'FILTERS_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  FiltersConnectionArrayAggFilterFolderIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  FiltersConnectionArrayAggFilterFolderIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  FiltersConnectionArrayAggIdAsc = 'FILTERS_CONNECTION_ARRAY_AGG_ID_ASC',
  FiltersConnectionArrayAggIdDesc = 'FILTERS_CONNECTION_ARRAY_AGG_ID_DESC',
  FiltersConnectionArrayAggIsDefaultAsc = 'FILTERS_CONNECTION_ARRAY_AGG_IS_DEFAULT_ASC',
  FiltersConnectionArrayAggIsDefaultDesc = 'FILTERS_CONNECTION_ARRAY_AGG_IS_DEFAULT_DESC',
  FiltersConnectionArrayAggNameAsc = 'FILTERS_CONNECTION_ARRAY_AGG_NAME_ASC',
  FiltersConnectionArrayAggNameDesc = 'FILTERS_CONNECTION_ARRAY_AGG_NAME_DESC',
  FiltersConnectionArrayAggScopeAsc = 'FILTERS_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  FiltersConnectionArrayAggScopeDesc = 'FILTERS_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  FiltersConnectionArrayAggTypeAsc = 'FILTERS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FiltersConnectionArrayAggTypeDesc = 'FILTERS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FiltersConnectionArrayAggUpdatedAtAsc = 'FILTERS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FiltersConnectionArrayAggUpdatedAtDesc = 'FILTERS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FiltersConnectionAverageCompanyIdAsc = 'FILTERS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FiltersConnectionAverageCompanyIdDesc = 'FILTERS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FiltersConnectionAverageConditionsAsc = 'FILTERS_CONNECTION_AVERAGE_CONDITIONS_ASC',
  FiltersConnectionAverageConditionsDesc = 'FILTERS_CONNECTION_AVERAGE_CONDITIONS_DESC',
  FiltersConnectionAverageCreatedAtAsc = 'FILTERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FiltersConnectionAverageCreatedAtDesc = 'FILTERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FiltersConnectionAverageCreatedByAsc = 'FILTERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FiltersConnectionAverageCreatedByDesc = 'FILTERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FiltersConnectionAverageEmojiAsc = 'FILTERS_CONNECTION_AVERAGE_EMOJI_ASC',
  FiltersConnectionAverageEmojiDesc = 'FILTERS_CONNECTION_AVERAGE_EMOJI_DESC',
  FiltersConnectionAverageFilterFolderIdAsc = 'FILTERS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionAverageFilterFolderIdDesc = 'FILTERS_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionAverageIdAsc = 'FILTERS_CONNECTION_AVERAGE_ID_ASC',
  FiltersConnectionAverageIdDesc = 'FILTERS_CONNECTION_AVERAGE_ID_DESC',
  FiltersConnectionAverageIsDefaultAsc = 'FILTERS_CONNECTION_AVERAGE_IS_DEFAULT_ASC',
  FiltersConnectionAverageIsDefaultDesc = 'FILTERS_CONNECTION_AVERAGE_IS_DEFAULT_DESC',
  FiltersConnectionAverageNameAsc = 'FILTERS_CONNECTION_AVERAGE_NAME_ASC',
  FiltersConnectionAverageNameDesc = 'FILTERS_CONNECTION_AVERAGE_NAME_DESC',
  FiltersConnectionAverageScopeAsc = 'FILTERS_CONNECTION_AVERAGE_SCOPE_ASC',
  FiltersConnectionAverageScopeDesc = 'FILTERS_CONNECTION_AVERAGE_SCOPE_DESC',
  FiltersConnectionAverageTypeAsc = 'FILTERS_CONNECTION_AVERAGE_TYPE_ASC',
  FiltersConnectionAverageTypeDesc = 'FILTERS_CONNECTION_AVERAGE_TYPE_DESC',
  FiltersConnectionAverageUpdatedAtAsc = 'FILTERS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FiltersConnectionAverageUpdatedAtDesc = 'FILTERS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FiltersConnectionCountAsc = 'FILTERS_CONNECTION_COUNT_ASC',
  FiltersConnectionCountDesc = 'FILTERS_CONNECTION_COUNT_DESC',
  FiltersConnectionDistinctCountCompanyIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FiltersConnectionDistinctCountCompanyIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FiltersConnectionDistinctCountConditionsAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CONDITIONS_ASC',
  FiltersConnectionDistinctCountConditionsDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CONDITIONS_DESC',
  FiltersConnectionDistinctCountCreatedAtAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FiltersConnectionDistinctCountCreatedAtDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FiltersConnectionDistinctCountCreatedByAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FiltersConnectionDistinctCountCreatedByDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FiltersConnectionDistinctCountEmojiAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  FiltersConnectionDistinctCountEmojiDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  FiltersConnectionDistinctCountFilterFolderIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  FiltersConnectionDistinctCountFilterFolderIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  FiltersConnectionDistinctCountIdAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FiltersConnectionDistinctCountIdDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FiltersConnectionDistinctCountIsDefaultAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_ASC',
  FiltersConnectionDistinctCountIsDefaultDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_DESC',
  FiltersConnectionDistinctCountNameAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FiltersConnectionDistinctCountNameDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FiltersConnectionDistinctCountScopeAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  FiltersConnectionDistinctCountScopeDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  FiltersConnectionDistinctCountTypeAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FiltersConnectionDistinctCountTypeDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FiltersConnectionDistinctCountUpdatedAtAsc = 'FILTERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FiltersConnectionDistinctCountUpdatedAtDesc = 'FILTERS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FiltersConnectionMaxCompanyIdAsc = 'FILTERS_CONNECTION_MAX_COMPANY_ID_ASC',
  FiltersConnectionMaxCompanyIdDesc = 'FILTERS_CONNECTION_MAX_COMPANY_ID_DESC',
  FiltersConnectionMaxConditionsAsc = 'FILTERS_CONNECTION_MAX_CONDITIONS_ASC',
  FiltersConnectionMaxConditionsDesc = 'FILTERS_CONNECTION_MAX_CONDITIONS_DESC',
  FiltersConnectionMaxCreatedAtAsc = 'FILTERS_CONNECTION_MAX_CREATED_AT_ASC',
  FiltersConnectionMaxCreatedAtDesc = 'FILTERS_CONNECTION_MAX_CREATED_AT_DESC',
  FiltersConnectionMaxCreatedByAsc = 'FILTERS_CONNECTION_MAX_CREATED_BY_ASC',
  FiltersConnectionMaxCreatedByDesc = 'FILTERS_CONNECTION_MAX_CREATED_BY_DESC',
  FiltersConnectionMaxEmojiAsc = 'FILTERS_CONNECTION_MAX_EMOJI_ASC',
  FiltersConnectionMaxEmojiDesc = 'FILTERS_CONNECTION_MAX_EMOJI_DESC',
  FiltersConnectionMaxFilterFolderIdAsc = 'FILTERS_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  FiltersConnectionMaxFilterFolderIdDesc = 'FILTERS_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  FiltersConnectionMaxIdAsc = 'FILTERS_CONNECTION_MAX_ID_ASC',
  FiltersConnectionMaxIdDesc = 'FILTERS_CONNECTION_MAX_ID_DESC',
  FiltersConnectionMaxIsDefaultAsc = 'FILTERS_CONNECTION_MAX_IS_DEFAULT_ASC',
  FiltersConnectionMaxIsDefaultDesc = 'FILTERS_CONNECTION_MAX_IS_DEFAULT_DESC',
  FiltersConnectionMaxNameAsc = 'FILTERS_CONNECTION_MAX_NAME_ASC',
  FiltersConnectionMaxNameDesc = 'FILTERS_CONNECTION_MAX_NAME_DESC',
  FiltersConnectionMaxScopeAsc = 'FILTERS_CONNECTION_MAX_SCOPE_ASC',
  FiltersConnectionMaxScopeDesc = 'FILTERS_CONNECTION_MAX_SCOPE_DESC',
  FiltersConnectionMaxTypeAsc = 'FILTERS_CONNECTION_MAX_TYPE_ASC',
  FiltersConnectionMaxTypeDesc = 'FILTERS_CONNECTION_MAX_TYPE_DESC',
  FiltersConnectionMaxUpdatedAtAsc = 'FILTERS_CONNECTION_MAX_UPDATED_AT_ASC',
  FiltersConnectionMaxUpdatedAtDesc = 'FILTERS_CONNECTION_MAX_UPDATED_AT_DESC',
  FiltersConnectionMinCompanyIdAsc = 'FILTERS_CONNECTION_MIN_COMPANY_ID_ASC',
  FiltersConnectionMinCompanyIdDesc = 'FILTERS_CONNECTION_MIN_COMPANY_ID_DESC',
  FiltersConnectionMinConditionsAsc = 'FILTERS_CONNECTION_MIN_CONDITIONS_ASC',
  FiltersConnectionMinConditionsDesc = 'FILTERS_CONNECTION_MIN_CONDITIONS_DESC',
  FiltersConnectionMinCreatedAtAsc = 'FILTERS_CONNECTION_MIN_CREATED_AT_ASC',
  FiltersConnectionMinCreatedAtDesc = 'FILTERS_CONNECTION_MIN_CREATED_AT_DESC',
  FiltersConnectionMinCreatedByAsc = 'FILTERS_CONNECTION_MIN_CREATED_BY_ASC',
  FiltersConnectionMinCreatedByDesc = 'FILTERS_CONNECTION_MIN_CREATED_BY_DESC',
  FiltersConnectionMinEmojiAsc = 'FILTERS_CONNECTION_MIN_EMOJI_ASC',
  FiltersConnectionMinEmojiDesc = 'FILTERS_CONNECTION_MIN_EMOJI_DESC',
  FiltersConnectionMinFilterFolderIdAsc = 'FILTERS_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  FiltersConnectionMinFilterFolderIdDesc = 'FILTERS_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  FiltersConnectionMinIdAsc = 'FILTERS_CONNECTION_MIN_ID_ASC',
  FiltersConnectionMinIdDesc = 'FILTERS_CONNECTION_MIN_ID_DESC',
  FiltersConnectionMinIsDefaultAsc = 'FILTERS_CONNECTION_MIN_IS_DEFAULT_ASC',
  FiltersConnectionMinIsDefaultDesc = 'FILTERS_CONNECTION_MIN_IS_DEFAULT_DESC',
  FiltersConnectionMinNameAsc = 'FILTERS_CONNECTION_MIN_NAME_ASC',
  FiltersConnectionMinNameDesc = 'FILTERS_CONNECTION_MIN_NAME_DESC',
  FiltersConnectionMinScopeAsc = 'FILTERS_CONNECTION_MIN_SCOPE_ASC',
  FiltersConnectionMinScopeDesc = 'FILTERS_CONNECTION_MIN_SCOPE_DESC',
  FiltersConnectionMinTypeAsc = 'FILTERS_CONNECTION_MIN_TYPE_ASC',
  FiltersConnectionMinTypeDesc = 'FILTERS_CONNECTION_MIN_TYPE_DESC',
  FiltersConnectionMinUpdatedAtAsc = 'FILTERS_CONNECTION_MIN_UPDATED_AT_ASC',
  FiltersConnectionMinUpdatedAtDesc = 'FILTERS_CONNECTION_MIN_UPDATED_AT_DESC',
  FiltersConnectionStddevPopulationCompanyIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FiltersConnectionStddevPopulationCompanyIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FiltersConnectionStddevPopulationConditionsAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CONDITIONS_ASC',
  FiltersConnectionStddevPopulationConditionsDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CONDITIONS_DESC',
  FiltersConnectionStddevPopulationCreatedAtAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FiltersConnectionStddevPopulationCreatedAtDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FiltersConnectionStddevPopulationCreatedByAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FiltersConnectionStddevPopulationCreatedByDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FiltersConnectionStddevPopulationEmojiAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  FiltersConnectionStddevPopulationEmojiDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  FiltersConnectionStddevPopulationFilterFolderIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersConnectionStddevPopulationFilterFolderIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersConnectionStddevPopulationIdAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FiltersConnectionStddevPopulationIdDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FiltersConnectionStddevPopulationIsDefaultAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_ASC',
  FiltersConnectionStddevPopulationIsDefaultDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_DESC',
  FiltersConnectionStddevPopulationNameAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FiltersConnectionStddevPopulationNameDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FiltersConnectionStddevPopulationScopeAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  FiltersConnectionStddevPopulationScopeDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  FiltersConnectionStddevPopulationTypeAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FiltersConnectionStddevPopulationTypeDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FiltersConnectionStddevPopulationUpdatedAtAsc = 'FILTERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FiltersConnectionStddevPopulationUpdatedAtDesc = 'FILTERS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FiltersConnectionStddevSampleCompanyIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FiltersConnectionStddevSampleCompanyIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FiltersConnectionStddevSampleConditionsAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CONDITIONS_ASC',
  FiltersConnectionStddevSampleConditionsDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CONDITIONS_DESC',
  FiltersConnectionStddevSampleCreatedAtAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FiltersConnectionStddevSampleCreatedAtDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FiltersConnectionStddevSampleCreatedByAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FiltersConnectionStddevSampleCreatedByDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FiltersConnectionStddevSampleEmojiAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  FiltersConnectionStddevSampleEmojiDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  FiltersConnectionStddevSampleFilterFolderIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionStddevSampleFilterFolderIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionStddevSampleIdAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FiltersConnectionStddevSampleIdDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FiltersConnectionStddevSampleIsDefaultAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_ASC',
  FiltersConnectionStddevSampleIsDefaultDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_DESC',
  FiltersConnectionStddevSampleNameAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FiltersConnectionStddevSampleNameDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FiltersConnectionStddevSampleScopeAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  FiltersConnectionStddevSampleScopeDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  FiltersConnectionStddevSampleTypeAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FiltersConnectionStddevSampleTypeDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FiltersConnectionStddevSampleUpdatedAtAsc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FiltersConnectionStddevSampleUpdatedAtDesc = 'FILTERS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FiltersConnectionSumCompanyIdAsc = 'FILTERS_CONNECTION_SUM_COMPANY_ID_ASC',
  FiltersConnectionSumCompanyIdDesc = 'FILTERS_CONNECTION_SUM_COMPANY_ID_DESC',
  FiltersConnectionSumConditionsAsc = 'FILTERS_CONNECTION_SUM_CONDITIONS_ASC',
  FiltersConnectionSumConditionsDesc = 'FILTERS_CONNECTION_SUM_CONDITIONS_DESC',
  FiltersConnectionSumCreatedAtAsc = 'FILTERS_CONNECTION_SUM_CREATED_AT_ASC',
  FiltersConnectionSumCreatedAtDesc = 'FILTERS_CONNECTION_SUM_CREATED_AT_DESC',
  FiltersConnectionSumCreatedByAsc = 'FILTERS_CONNECTION_SUM_CREATED_BY_ASC',
  FiltersConnectionSumCreatedByDesc = 'FILTERS_CONNECTION_SUM_CREATED_BY_DESC',
  FiltersConnectionSumEmojiAsc = 'FILTERS_CONNECTION_SUM_EMOJI_ASC',
  FiltersConnectionSumEmojiDesc = 'FILTERS_CONNECTION_SUM_EMOJI_DESC',
  FiltersConnectionSumFilterFolderIdAsc = 'FILTERS_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  FiltersConnectionSumFilterFolderIdDesc = 'FILTERS_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  FiltersConnectionSumIdAsc = 'FILTERS_CONNECTION_SUM_ID_ASC',
  FiltersConnectionSumIdDesc = 'FILTERS_CONNECTION_SUM_ID_DESC',
  FiltersConnectionSumIsDefaultAsc = 'FILTERS_CONNECTION_SUM_IS_DEFAULT_ASC',
  FiltersConnectionSumIsDefaultDesc = 'FILTERS_CONNECTION_SUM_IS_DEFAULT_DESC',
  FiltersConnectionSumNameAsc = 'FILTERS_CONNECTION_SUM_NAME_ASC',
  FiltersConnectionSumNameDesc = 'FILTERS_CONNECTION_SUM_NAME_DESC',
  FiltersConnectionSumScopeAsc = 'FILTERS_CONNECTION_SUM_SCOPE_ASC',
  FiltersConnectionSumScopeDesc = 'FILTERS_CONNECTION_SUM_SCOPE_DESC',
  FiltersConnectionSumTypeAsc = 'FILTERS_CONNECTION_SUM_TYPE_ASC',
  FiltersConnectionSumTypeDesc = 'FILTERS_CONNECTION_SUM_TYPE_DESC',
  FiltersConnectionSumUpdatedAtAsc = 'FILTERS_CONNECTION_SUM_UPDATED_AT_ASC',
  FiltersConnectionSumUpdatedAtDesc = 'FILTERS_CONNECTION_SUM_UPDATED_AT_DESC',
  FiltersConnectionVariancePopulationCompanyIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FiltersConnectionVariancePopulationCompanyIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FiltersConnectionVariancePopulationConditionsAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CONDITIONS_ASC',
  FiltersConnectionVariancePopulationConditionsDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CONDITIONS_DESC',
  FiltersConnectionVariancePopulationCreatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FiltersConnectionVariancePopulationCreatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FiltersConnectionVariancePopulationCreatedByAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FiltersConnectionVariancePopulationCreatedByDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FiltersConnectionVariancePopulationEmojiAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  FiltersConnectionVariancePopulationEmojiDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  FiltersConnectionVariancePopulationFilterFolderIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersConnectionVariancePopulationFilterFolderIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersConnectionVariancePopulationIdAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FiltersConnectionVariancePopulationIdDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FiltersConnectionVariancePopulationIsDefaultAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_ASC',
  FiltersConnectionVariancePopulationIsDefaultDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_DESC',
  FiltersConnectionVariancePopulationNameAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FiltersConnectionVariancePopulationNameDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FiltersConnectionVariancePopulationScopeAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  FiltersConnectionVariancePopulationScopeDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  FiltersConnectionVariancePopulationTypeAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FiltersConnectionVariancePopulationTypeDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FiltersConnectionVariancePopulationUpdatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FiltersConnectionVariancePopulationUpdatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FiltersConnectionVarianceSampleCompanyIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FiltersConnectionVarianceSampleCompanyIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FiltersConnectionVarianceSampleConditionsAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_ASC',
  FiltersConnectionVarianceSampleConditionsDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_DESC',
  FiltersConnectionVarianceSampleCreatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FiltersConnectionVarianceSampleCreatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FiltersConnectionVarianceSampleCreatedByAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FiltersConnectionVarianceSampleCreatedByDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FiltersConnectionVarianceSampleEmojiAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  FiltersConnectionVarianceSampleEmojiDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  FiltersConnectionVarianceSampleFilterFolderIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersConnectionVarianceSampleFilterFolderIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersConnectionVarianceSampleIdAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FiltersConnectionVarianceSampleIdDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FiltersConnectionVarianceSampleIsDefaultAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_ASC',
  FiltersConnectionVarianceSampleIsDefaultDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_DESC',
  FiltersConnectionVarianceSampleNameAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FiltersConnectionVarianceSampleNameDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FiltersConnectionVarianceSampleScopeAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  FiltersConnectionVarianceSampleScopeDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  FiltersConnectionVarianceSampleTypeAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FiltersConnectionVarianceSampleTypeDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FiltersConnectionVarianceSampleUpdatedAtAsc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FiltersConnectionVarianceSampleUpdatedAtDesc = 'FILTERS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

/** Grouping methods for `Filter` for usage during aggregation. */
export enum FilterGroupBy {
  CompanyId = 'COMPANY_ID',
  Conditions = 'CONDITIONS',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Emoji = 'EMOJI',
  FilterFolderId = 'FILTER_FOLDER_ID',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IsDefault = 'IS_DEFAULT',
  Name = 'NAME',
  Scope = 'SCOPE',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type FilterHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Filter` aggregates. */
export type FilterHavingInput = {
  AND: InputMaybe<Array<FilterHavingInput>>;
  OR: InputMaybe<Array<FilterHavingInput>>;
  arrayAgg: InputMaybe<FilterHavingArrayAggInput>;
  average: InputMaybe<FilterHavingAverageInput>;
  distinctCount: InputMaybe<FilterHavingDistinctCountInput>;
  max: InputMaybe<FilterHavingMaxInput>;
  min: InputMaybe<FilterHavingMinInput>;
  stddevPopulation: InputMaybe<FilterHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FilterHavingStddevSampleInput>;
  sum: InputMaybe<FilterHavingSumInput>;
  variancePopulation: InputMaybe<FilterHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FilterHavingVarianceSampleInput>;
};

export type FilterHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  filterFolderId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FilterMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  filterFolderId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FilterMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FilterMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMaxAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  filterFolderId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FilterMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FilterMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMinAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevPopulationAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevSampleAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  filterFolderId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type FilterSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of filterFolderId across the matching connection */
  filterFolderId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type FilterSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterSumAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `FilterToTeam`. */
export type FilterTeamsByFilterToTeamFilterIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `FilterToTeam`, and the cursor to aid in pagination. */
  edges: Array<FilterTeamsByFilterToTeamFilterIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `FilterToTeam`. */
export type FilterTeamsByFilterToTeamFilterIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `FilterToTeam`. */
export type FilterTeamsByFilterToTeamFilterIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
};

/** A filter to be used against many `FilterToRole` object types. All fields are combined with a logical ‘and.’ */
export type FilterToManyFilterToRoleFilter = {
  /** Aggregates across related `FilterToRole` match the filter criteria. */
  aggregates: InputMaybe<FilterToRoleAggregatesFilter>;
  /** Every related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToRoleFilter>;
  /** No related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToRoleFilter>;
  /** Some related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToRoleFilter>;
};

/** A filter to be used against many `FilterToTeam` object types. All fields are combined with a logical ‘and.’ */
export type FilterToManyFilterToTeamFilter = {
  /** Aggregates across related `FilterToTeam` match the filter criteria. */
  aggregates: InputMaybe<FilterToTeamAggregatesFilter>;
  /** Every related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToTeamFilter>;
  /** No related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToTeamFilter>;
  /** Some related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToTeamFilter>;
};

/** A filter to be used against many `FilterToUser` object types. All fields are combined with a logical ‘and.’ */
export type FilterToManyFilterToUserFilter = {
  /** Aggregates across related `FilterToUser` match the filter criteria. */
  aggregates: InputMaybe<FilterToUserAggregatesFilter>;
  /** Every related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToUserFilter>;
  /** No related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToUserFilter>;
  /** Some related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToUserFilter>;
};

export type FilterToRole = Node & {
  /** Reads a single `Filter` that is related to this `FilterToRole`. */
  filter: Maybe<Filter>;
  filterId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `FilterToRole`. */
  role: Maybe<Role>;
  roleId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `FilterToRole` object types. */
export type FilterToRoleAggregatesFilter = {
  /** Array aggregation aggregate over matching `FilterToRole` objects. */
  arrayAgg: InputMaybe<FilterToRoleArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FilterToRole` objects. */
  average: InputMaybe<FilterToRoleAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FilterToRole` objects. */
  distinctCount: InputMaybe<FilterToRoleDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FilterToRole` object to be included within the aggregate. */
  filter: InputMaybe<FilterToRoleFilter>;
  /** Maximum aggregate over matching `FilterToRole` objects. */
  max: InputMaybe<FilterToRoleMaxAggregateFilter>;
  /** Minimum aggregate over matching `FilterToRole` objects. */
  min: InputMaybe<FilterToRoleMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FilterToRole` objects. */
  stddevPopulation: InputMaybe<FilterToRoleStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FilterToRole` objects. */
  stddevSample: InputMaybe<FilterToRoleStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FilterToRole` objects. */
  sum: InputMaybe<FilterToRoleSumAggregateFilter>;
  /** Population variance aggregate over matching `FilterToRole` objects. */
  variancePopulation: InputMaybe<FilterToRoleVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FilterToRole` objects. */
  varianceSample: InputMaybe<FilterToRoleVarianceSampleAggregateFilter>;
};

export type FilterToRoleArrayAggAggregateFilter = {
  filterId: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
};

export type FilterToRoleAverageAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `FilterToRole` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FilterToRoleCondition = {
  /** Checks for equality with the object’s `filterId` field. */
  filterId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
};

export type FilterToRoleDistinctCountAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `FilterToRole` object types. All fields are combined with a logical ‘and.’ */
export type FilterToRoleFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FilterToRoleFilter>>;
  /** Filter by the object’s `filter` relation. */
  filter: InputMaybe<FilterFilter>;
  /** Filter by the object’s `filterId` field. */
  filterId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FilterToRoleFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FilterToRoleFilter>>;
  /** Filter by the object’s `role` relation. */
  role: InputMaybe<RoleFilter>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
};

export type FilterToRoleMaxAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
};

export type FilterToRoleMinAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
};

export type FilterToRoleStddevPopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type FilterToRoleStddevSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type FilterToRoleSumAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
};

export type FilterToRoleVariancePopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

export type FilterToRoleVarianceSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `FilterToRole`. */
export enum FilterToRolesOrderBy {
  FilterIdAsc = 'FILTER_ID_ASC',
  FilterIdDesc = 'FILTER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC'
}

export type FilterToTeam = Node & {
  /** Reads a single `Filter` that is related to this `FilterToTeam`. */
  filter: Maybe<Filter>;
  filterId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Team` that is related to this `FilterToTeam`. */
  team: Maybe<Team>;
  teamId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `FilterToTeam` object types. */
export type FilterToTeamAggregatesFilter = {
  /** Array aggregation aggregate over matching `FilterToTeam` objects. */
  arrayAgg: InputMaybe<FilterToTeamArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FilterToTeam` objects. */
  average: InputMaybe<FilterToTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FilterToTeam` objects. */
  distinctCount: InputMaybe<FilterToTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FilterToTeam` object to be included within the aggregate. */
  filter: InputMaybe<FilterToTeamFilter>;
  /** Maximum aggregate over matching `FilterToTeam` objects. */
  max: InputMaybe<FilterToTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `FilterToTeam` objects. */
  min: InputMaybe<FilterToTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FilterToTeam` objects. */
  stddevPopulation: InputMaybe<FilterToTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FilterToTeam` objects. */
  stddevSample: InputMaybe<FilterToTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FilterToTeam` objects. */
  sum: InputMaybe<FilterToTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `FilterToTeam` objects. */
  variancePopulation: InputMaybe<FilterToTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FilterToTeam` objects. */
  varianceSample: InputMaybe<FilterToTeamVarianceSampleAggregateFilter>;
};

export type FilterToTeamArrayAggAggregateFilter = {
  filterId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
};

export type FilterToTeamAverageAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `FilterToTeam` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FilterToTeamCondition = {
  /** Checks for equality with the object’s `filterId` field. */
  filterId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
};

export type FilterToTeamDistinctCountAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `FilterToTeam` object types. All fields are combined with a logical ‘and.’ */
export type FilterToTeamFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FilterToTeamFilter>>;
  /** Filter by the object’s `filter` relation. */
  filter: InputMaybe<FilterFilter>;
  /** Filter by the object’s `filterId` field. */
  filterId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FilterToTeamFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FilterToTeamFilter>>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
};

export type FilterToTeamMaxAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type FilterToTeamMinAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type FilterToTeamStddevPopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type FilterToTeamStddevSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type FilterToTeamSumAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

export type FilterToTeamVariancePopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type FilterToTeamVarianceSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `FilterToTeam`. */
export enum FilterToTeamsOrderBy {
  FilterIdAsc = 'FILTER_ID_ASC',
  FilterIdDesc = 'FILTER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type FilterToUser = Node & {
  /** Reads a single `Filter` that is related to this `FilterToUser`. */
  filter: Maybe<Filter>;
  filterId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `MainUser` that is related to this `FilterToUser`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `FilterToUser` object types. */
export type FilterToUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `FilterToUser` objects. */
  arrayAgg: InputMaybe<FilterToUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FilterToUser` objects. */
  average: InputMaybe<FilterToUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FilterToUser` objects. */
  distinctCount: InputMaybe<FilterToUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FilterToUser` object to be included within the aggregate. */
  filter: InputMaybe<FilterToUserFilter>;
  /** Maximum aggregate over matching `FilterToUser` objects. */
  max: InputMaybe<FilterToUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `FilterToUser` objects. */
  min: InputMaybe<FilterToUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FilterToUser` objects. */
  stddevPopulation: InputMaybe<FilterToUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FilterToUser` objects. */
  stddevSample: InputMaybe<FilterToUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FilterToUser` objects. */
  sum: InputMaybe<FilterToUserSumAggregateFilter>;
  /** Population variance aggregate over matching `FilterToUser` objects. */
  variancePopulation: InputMaybe<FilterToUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FilterToUser` objects. */
  varianceSample: InputMaybe<FilterToUserVarianceSampleAggregateFilter>;
};

export type FilterToUserArrayAggAggregateFilter = {
  filterId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type FilterToUserAverageAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `FilterToUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FilterToUserCondition = {
  /** Checks for equality with the object’s `filterId` field. */
  filterId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type FilterToUserDistinctCountAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `FilterToUser` object types. All fields are combined with a logical ‘and.’ */
export type FilterToUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FilterToUserFilter>>;
  /** Filter by the object’s `filter` relation. */
  filter: InputMaybe<FilterFilter>;
  /** Filter by the object’s `filterId` field. */
  filterId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FilterToUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FilterToUserFilter>>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type FilterToUserMaxAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FilterToUserMinAggregateFilter = {
  filterId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FilterToUserStddevPopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FilterToUserStddevSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FilterToUserSumAggregateFilter = {
  filterId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type FilterToUserVariancePopulationAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FilterToUserVarianceSampleAggregateFilter = {
  filterId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `FilterToUser`. */
export enum FilterToUsersOrderBy {
  FilterIdAsc = 'FILTER_ID_ASC',
  FilterIdDesc = 'FILTER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type FilterVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVariancePopulationAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

export type FilterVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  filterFolderId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type FilterVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of filterFolderId across the matching connection */
  filterFolderId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type FilterVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVarianceSampleAggregatesFilterFolderIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type FilterVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};

/** A connection to a list of `Filter` values. */
export type FiltersConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterAggregates>;
  /** A list of edges which contains the `Filter` and cursor to aid in pagination. */
  edges: Array<FiltersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterAggregates>>;
  /** A list of `Filter` objects. */
  nodes: Array<Maybe<Filter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Filter` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Filter` values. */
export type FiltersConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterGroupBy>;
  having: InputMaybe<FilterHavingInput>;
};

/** A `Filter` edge in the connection. */
export type FiltersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Filter` at the end of the edge. */
  node: Maybe<Filter>;
};

/** Methods to use when ordering `Filter`. */
export enum FiltersOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ConditionsAsc = 'CONDITIONS_ASC',
  ConditionsDesc = 'CONDITIONS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmojiAsc = 'EMOJI_ASC',
  EmojiDesc = 'EMOJI_DESC',
  FilterFolderIdAsc = 'FILTER_FOLDER_ID_ASC',
  FilterFolderIdDesc = 'FILTER_FOLDER_ID_DESC',
  FilterToRolesByFilterIdConnectionArrayAggFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionArrayAggFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionArrayAggRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionArrayAggRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionAverageFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionAverageFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionAverageRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionAverageRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionCountAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_COUNT_ASC',
  FilterToRolesByFilterIdConnectionCountDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_COUNT_DESC',
  FilterToRolesByFilterIdConnectionDistinctCountFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionDistinctCountFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionDistinctCountRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionDistinctCountRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionMaxFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionMaxFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionMaxRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MAX_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionMaxRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MAX_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionMinFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionMinFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionMinRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MIN_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionMinRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_MIN_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionStddevPopulationRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionStddevPopulationRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionStddevSampleFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionStddevSampleFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionStddevSampleRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionStddevSampleRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionSumFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionSumFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionSumRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_SUM_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionSumRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_SUM_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionVariancePopulationRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionVariancePopulationRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  FilterToRolesByFilterIdConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToRolesByFilterIdConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToRolesByFilterIdConnectionVarianceSampleRoleIdAsc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  FilterToRolesByFilterIdConnectionVarianceSampleRoleIdDesc = 'FILTER_TO_ROLES_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  FilterToTeamsByFilterIdConnectionArrayAggFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionArrayAggFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionArrayAggTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionArrayAggTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionAverageFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionAverageFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionAverageTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionAverageTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionCountAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_COUNT_ASC',
  FilterToTeamsByFilterIdConnectionCountDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_COUNT_DESC',
  FilterToTeamsByFilterIdConnectionDistinctCountFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionDistinctCountFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionDistinctCountTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionDistinctCountTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionMaxFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionMaxFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionMaxTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionMaxTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionMinFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionMinFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionMinTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionMinTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionStddevPopulationTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionStddevPopulationTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionStddevSampleFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionStddevSampleFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionStddevSampleTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionStddevSampleTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionSumFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionSumFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionSumTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionSumTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionVariancePopulationTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionVariancePopulationTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  FilterToTeamsByFilterIdConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToTeamsByFilterIdConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToTeamsByFilterIdConnectionVarianceSampleTeamIdAsc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  FilterToTeamsByFilterIdConnectionVarianceSampleTeamIdDesc = 'FILTER_TO_TEAMS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  FilterToUsersByFilterIdConnectionArrayAggFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionArrayAggFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionArrayAggUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionArrayAggUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionAverageFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionAverageFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionAverageUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionAverageUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionCountAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_COUNT_ASC',
  FilterToUsersByFilterIdConnectionCountDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_COUNT_DESC',
  FilterToUsersByFilterIdConnectionDistinctCountFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionDistinctCountFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionDistinctCountUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionDistinctCountUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionMaxFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionMaxFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionMaxUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MAX_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionMaxUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MAX_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionMinFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionMinFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionMinUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MIN_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionMinUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_MIN_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionStddevPopulationUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionStddevPopulationUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionStddevSampleFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionStddevSampleFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionStddevSampleUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionStddevSampleUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionSumFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionSumFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionSumUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_SUM_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionSumUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_SUM_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionVariancePopulationUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionVariancePopulationUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FilterToUsersByFilterIdConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToUsersByFilterIdConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToUsersByFilterIdConnectionVarianceSampleUserIdAsc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FilterToUsersByFilterIdConnectionVarianceSampleUserIdDesc = 'FILTER_TO_USERS_BY_FILTER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDefaultAsc = 'IS_DEFAULT_ASC',
  IsDefaultDesc = 'IS_DEFAULT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['Float']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['Float']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** A filter to be used against Float List fields. All fields are combined with a logical ‘and.’ */
export type FloatListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan: InputMaybe<Scalars['Float']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan: InputMaybe<Scalars['Float']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo: InputMaybe<Scalars['Float']['input']>;
  /** Contained by the specified list of values. */
  containedBy: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Contains the specified list of values. */
  contains: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type Form = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileFormIdAndActionId: FormActionsByFileFormIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileFormIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileFormIdAndCommentId: FormCommentsByFileFormIdAndCommentIdManyToManyConnection;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileFormIdAndCreatedByContactId: FormContactsByFileFormIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileFormIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** Reads a single `File` that is related to this `Form`. */
  file: Maybe<File>;
  fileId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileFormIdAndValueFileId: FormFilesByFileFormIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileFormIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** Reads and enables pagination through a set of `FormAssignee`. */
  formAssignees: Array<FormAssignee>;
  /** Reads and enables pagination through a set of `FormLayout`. */
  formLayouts: Array<FormLayout>;
  /** Reads and enables pagination through a set of `FormLayout`. */
  formLayoutsConnection: FormLayoutsConnection;
  id: Scalars['Int']['output'];
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  isTemplate: Maybe<Scalars['Boolean']['output']>;
  members: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  oldFields: Maybe<Scalars['JSON']['output']>;
  privilege: Maybe<Scalars['String']['output']>;
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  privilegeTeam: Maybe<Scalars['String']['output']>;
  progress: Maybe<FormProgress>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembersByFormAssigneeFormIdAndProjectMemberIdList: Array<ProjectMember>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileFormIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileFormIdAndProjectId: FormProjectsByFileFormIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileFormIdAndProjectIdList: Array<Project>;
  /** Reads a single `ProjectStage` that is related to this `Form`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  templateId: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileFormIdAndCreatedBy: FormUsersByFileFormIdAndCreatedByManyToManyConnection;
};


export type FormActionsByFileFormIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type FormActionsByFileFormIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type FormCommentsByFileFormIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type FormContactsByFileFormIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type FormContactsByFileFormIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type FormFilesByFileFormIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type FormFilesByFileFormIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FormFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type FormFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type FormFormAssigneesArgs = {
  condition: InputMaybe<FormAssigneeCondition>;
  filter: InputMaybe<FormAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormAssigneesOrderBy>>;
};


export type FormFormLayoutsArgs = {
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormFormLayoutsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormProjectMembersByFormAssigneeFormIdAndProjectMemberIdListArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type FormProjectStagesByFileFormIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type FormProjectsByFileFormIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FormProjectsByFileFormIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type FormUsersByFileFormIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type FormActionsByFileFormIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormActionsByFileFormIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type FormActionsByFileFormIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type FormActionsByFileFormIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type FormActionsByFileFormIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type FormActionsByFileFormIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FormAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FormArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FormAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FormDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FormMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FormMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FormStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FormStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FormSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FormVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FormVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Form` object types. */
export type FormAggregatesFilter = {
  /** Array aggregation aggregate over matching `Form` objects. */
  arrayAgg: InputMaybe<FormArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Form` objects. */
  average: InputMaybe<FormAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Form` objects. */
  distinctCount: InputMaybe<FormDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Form` object to be included within the aggregate. */
  filter: InputMaybe<FormFilter>;
  /** Maximum aggregate over matching `Form` objects. */
  max: InputMaybe<FormMaxAggregateFilter>;
  /** Minimum aggregate over matching `Form` objects. */
  min: InputMaybe<FormMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Form` objects. */
  stddevPopulation: InputMaybe<FormStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Form` objects. */
  stddevSample: InputMaybe<FormStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Form` objects. */
  sum: InputMaybe<FormSumAggregateFilter>;
  /** Population variance aggregate over matching `Form` objects. */
  variancePopulation: InputMaybe<FormVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Form` objects. */
  varianceSample: InputMaybe<FormVarianceSampleAggregateFilter>;
};

export type FormArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  fileId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  isTemplate: InputMaybe<BooleanListFilter>;
  name: InputMaybe<StringListFilter>;
  privilege: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
  privilegeTeam: InputMaybe<StringListFilter>;
  stageId: InputMaybe<IntListFilter>;
  templateId: InputMaybe<IntListFilter>;
};

export type FormArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of fileId across the matching connection */
  fileId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isTemplate across the matching connection */
  isTemplate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilege across the matching connection */
  privilege: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeTeam across the matching connection */
  privilegeTeam: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateId across the matching connection */
  templateId: Array<Maybe<Scalars['Int']['output']>>;
};


export type FormArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormArrayAggAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormAssignee = Node & {
  /** Reads a single `Form` that is related to this `FormAssignee`. */
  form: Maybe<Form>;
  formId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `ProjectMember` that is related to this `FormAssignee`. */
  projectMember: Maybe<ProjectMember>;
  projectMemberId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `FormAssignee`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `FormAssignee` object types. */
export type FormAssigneeAggregatesFilter = {
  /** Array aggregation aggregate over matching `FormAssignee` objects. */
  arrayAgg: InputMaybe<FormAssigneeArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FormAssignee` objects. */
  average: InputMaybe<FormAssigneeAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FormAssignee` objects. */
  distinctCount: InputMaybe<FormAssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FormAssignee` object to be included within the aggregate. */
  filter: InputMaybe<FormAssigneeFilter>;
  /** Maximum aggregate over matching `FormAssignee` objects. */
  max: InputMaybe<FormAssigneeMaxAggregateFilter>;
  /** Minimum aggregate over matching `FormAssignee` objects. */
  min: InputMaybe<FormAssigneeMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FormAssignee` objects. */
  stddevPopulation: InputMaybe<FormAssigneeStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FormAssignee` objects. */
  stddevSample: InputMaybe<FormAssigneeStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FormAssignee` objects. */
  sum: InputMaybe<FormAssigneeSumAggregateFilter>;
  /** Population variance aggregate over matching `FormAssignee` objects. */
  variancePopulation: InputMaybe<FormAssigneeVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FormAssignee` objects. */
  varianceSample: InputMaybe<FormAssigneeVarianceSampleAggregateFilter>;
};

export type FormAssigneeArrayAggAggregateFilter = {
  formId: InputMaybe<IntListFilter>;
  projectMemberId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type FormAssigneeAverageAggregateFilter = {
  formId: InputMaybe<BigFloatFilter>;
  projectMemberId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `FormAssignee` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FormAssigneeCondition = {
  /** Checks for equality with the object’s `formId` field. */
  formId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectMemberId` field. */
  projectMemberId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type FormAssigneeDistinctCountAggregateFilter = {
  formId: InputMaybe<BigIntFilter>;
  projectMemberId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `FormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type FormAssigneeFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FormAssigneeFilter>>;
  /** Filter by the object’s `form` relation. */
  form: InputMaybe<FormFilter>;
  /** Filter by the object’s `formId` field. */
  formId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<FormAssigneeFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FormAssigneeFilter>>;
  /** Filter by the object’s `projectMember` relation. */
  projectMember: InputMaybe<ProjectMemberFilter>;
  /** A related `projectMember` exists. */
  projectMemberExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectMemberId` field. */
  projectMemberId: InputMaybe<IntFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type FormAssigneeMaxAggregateFilter = {
  formId: InputMaybe<IntFilter>;
  projectMemberId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FormAssigneeMinAggregateFilter = {
  formId: InputMaybe<IntFilter>;
  projectMemberId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type FormAssigneeStddevPopulationAggregateFilter = {
  formId: InputMaybe<BigFloatFilter>;
  projectMemberId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FormAssigneeStddevSampleAggregateFilter = {
  formId: InputMaybe<BigFloatFilter>;
  projectMemberId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FormAssigneeSumAggregateFilter = {
  formId: InputMaybe<BigIntFilter>;
  projectMemberId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type FormAssigneeVariancePopulationAggregateFilter = {
  formId: InputMaybe<BigFloatFilter>;
  projectMemberId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type FormAssigneeVarianceSampleAggregateFilter = {
  formId: InputMaybe<BigFloatFilter>;
  projectMemberId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `FormAssignee`. */
export enum FormAssigneesOrderBy {
  FormIdAsc = 'FORM_ID_ASC',
  FormIdDesc = 'FORM_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectMemberIdAsc = 'PROJECT_MEMBER_ID_ASC',
  ProjectMemberIdDesc = 'PROJECT_MEMBER_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type FormAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  templateId: InputMaybe<BigFloatFilter>;
};

export type FormAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateId across the matching connection */
  templateId: Maybe<Scalars['BigFloat']['output']>;
};


export type FormAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormAverageAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormAverageAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type FormCommentsByFileFormIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormCommentsByFileFormIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type FormCommentsByFileFormIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type FormCommentsByFileFormIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type FormCommentsByFileFormIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type FormCommentsByFileFormIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `Form` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FormCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `members` field. */
  members: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `oldFields` field. */
  oldFields: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `privilege` field. */
  privilege: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type FormContactsByFileFormIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormContactsByFileFormIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type FormContactsByFileFormIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type FormContactsByFileFormIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type FormContactsByFileFormIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type FormContactsByFileFormIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FormDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  isTemplate: InputMaybe<BigIntFilter>;
  members: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  oldFields: InputMaybe<BigIntFilter>;
  privilege: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  privilegeTeam: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  templateId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type FormDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileId across the matching connection */
  fileId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of members across the matching connection */
  members: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of oldFields across the matching connection */
  oldFields: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilege across the matching connection */
  privilege: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeTeam across the matching connection */
  privilegeTeam: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type FormDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesMembersArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesOldFieldsArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type FormFilesByFileFormIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormFilesByFileFormIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type FormFilesByFileFormIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type FormFilesByFileFormIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type FormFilesByFileFormIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type FormFilesByFileFormIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `Form` object types. All fields are combined with a logical ‘and.’ */
export type FormFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FormFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `file` relation. */
  file: InputMaybe<FileFilter>;
  /** A related `file` exists. */
  fileExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fileId` field. */
  fileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `filesByFormId` relation. */
  filesByFormId: InputMaybe<FormToManyFileFilter>;
  /** Some related `filesByFormId` exist. */
  filesByFormIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `formAssignees` relation. */
  formAssignees: InputMaybe<FormToManyFormAssigneeFilter>;
  /** Some related `formAssignees` exist. */
  formAssigneesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `formLayouts` relation. */
  formLayouts: InputMaybe<FormToManyFormLayoutFilter>;
  /** Some related `formLayouts` exist. */
  formLayoutsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `members` field. */
  members: InputMaybe<IntListFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<FormFilter>;
  /** Filter by the object’s `oldFields` field. */
  oldFields: InputMaybe<JsonFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FormFilter>>;
  /** Filter by the object’s `privilege` field. */
  privilege: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<StringFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `templateId` field. */
  templateId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Form` for usage during aggregation. */
export enum FormGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  FileId = 'FILE_ID',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IsCompleted = 'IS_COMPLETED',
  IsTemplate = 'IS_TEMPLATE',
  Members = 'MEMBERS',
  Name = 'NAME',
  OldFields = 'OLD_FIELDS',
  Privilege = 'PRIVILEGE',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  PrivilegeTeam = 'PRIVILEGE_TEAM',
  StageId = 'STAGE_ID',
  TemplateId = 'TEMPLATE_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type FormHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Form` aggregates. */
export type FormHavingInput = {
  AND: InputMaybe<Array<FormHavingInput>>;
  OR: InputMaybe<Array<FormHavingInput>>;
  arrayAgg: InputMaybe<FormHavingArrayAggInput>;
  average: InputMaybe<FormHavingAverageInput>;
  distinctCount: InputMaybe<FormHavingDistinctCountInput>;
  max: InputMaybe<FormHavingMaxInput>;
  min: InputMaybe<FormHavingMinInput>;
  stddevPopulation: InputMaybe<FormHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FormHavingStddevSampleInput>;
  sum: InputMaybe<FormHavingSumInput>;
  variancePopulation: InputMaybe<FormHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FormHavingVarianceSampleInput>;
};

export type FormHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  fileId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  templateId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayout = Node & {
  /** Reads and enables pagination through a set of `FormLayout`. */
  childFormLayouts: Array<FormLayout>;
  /** Reads and enables pagination through a set of `FormLayout`. */
  childFormLayoutsConnection: FormLayoutsConnection;
  /** Reads a single `ProjectProperty` that is related to this `FormLayout`. */
  column: Maybe<ProjectProperty>;
  columnId: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `Form` that is related to this `FormLayout`. */
  form: Maybe<Form>;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `FormLayout` that is related to this `FormLayout`. */
  parent: Maybe<FormLayout>;
  parentId: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FormLayoutChildFormLayoutsArgs = {
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutChildFormLayoutsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<FormLayoutArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<FormLayoutAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<FormLayoutDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<FormLayoutMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<FormLayoutMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<FormLayoutStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<FormLayoutStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<FormLayoutSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<FormLayoutVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<FormLayoutVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `FormLayout` object types. */
export type FormLayoutAggregatesFilter = {
  /** Array aggregation aggregate over matching `FormLayout` objects. */
  arrayAgg: InputMaybe<FormLayoutArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `FormLayout` objects. */
  average: InputMaybe<FormLayoutAverageAggregateFilter>;
  /** Distinct count aggregate over matching `FormLayout` objects. */
  distinctCount: InputMaybe<FormLayoutDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FormLayout` object to be included within the aggregate. */
  filter: InputMaybe<FormLayoutFilter>;
  /** Maximum aggregate over matching `FormLayout` objects. */
  max: InputMaybe<FormLayoutMaxAggregateFilter>;
  /** Minimum aggregate over matching `FormLayout` objects. */
  min: InputMaybe<FormLayoutMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `FormLayout` objects. */
  stddevPopulation: InputMaybe<FormLayoutStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `FormLayout` objects. */
  stddevSample: InputMaybe<FormLayoutStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `FormLayout` objects. */
  sum: InputMaybe<FormLayoutSumAggregateFilter>;
  /** Population variance aggregate over matching `FormLayout` objects. */
  variancePopulation: InputMaybe<FormLayoutVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `FormLayout` objects. */
  varianceSample: InputMaybe<FormLayoutVarianceSampleAggregateFilter>;
};

export type FormLayoutArrayAggAggregateFilter = {
  columnId: InputMaybe<IntListFilter>;
  formId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  name: InputMaybe<StringListFilter>;
  parentId: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  type: InputMaybe<StringListFilter>;
};

export type FormLayoutArrayAggAggregates = {
  /** Array aggregation of columnId across the matching connection */
  columnId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of formId across the matching connection */
  formId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of parentId across the matching connection */
  parentId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
};


export type FormLayoutArrayAggAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutAverageAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type FormLayoutAverageAggregates = {
  /** Mean average of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type FormLayoutAverageAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutAverageAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutAverageAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

/**
 * A condition to be used against `FormLayout` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FormLayoutCondition = {
  /** Checks for equality with the object’s `columnId` field. */
  columnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `formId` field. */
  formId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type FormLayoutDistinctCountAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  formId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type FormLayoutDistinctCountAggregates = {
  /** Distinct count of columnId across the matching connection */
  columnId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of formId across the matching connection */
  formId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentId across the matching connection */
  parentId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type FormLayoutDistinctCountAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

/** A filter to be used against `FormLayout` object types. All fields are combined with a logical ‘and.’ */
export type FormLayoutFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<FormLayoutFilter>>;
  /** Filter by the object’s `childFormLayouts` relation. */
  childFormLayouts: InputMaybe<FormLayoutToManyFormLayoutFilter>;
  /** Some related `childFormLayouts` exist. */
  childFormLayoutsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `column` relation. */
  column: InputMaybe<ProjectPropertyFilter>;
  /** A related `column` exists. */
  columnExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `columnId` field. */
  columnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `form` relation. */
  form: InputMaybe<FormFilter>;
  /** Filter by the object’s `formId` field. */
  formId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<FormLayoutFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<FormLayoutFilter>>;
  /** Filter by the object’s `parent` relation. */
  parent: InputMaybe<FormLayoutFilter>;
  /** A related `parent` exists. */
  parentExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentId` field. */
  parentId: InputMaybe<IntFilter>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `FormLayout` for usage during aggregation. */
export enum FormLayoutGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  FormId = 'FORM_ID',
  Name = 'NAME',
  ParentId = 'PARENT_ID',
  Position = 'POSITION',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type FormLayoutHavingArrayAggInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingAverageInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingDistinctCountInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `FormLayout` aggregates. */
export type FormLayoutHavingInput = {
  AND: InputMaybe<Array<FormLayoutHavingInput>>;
  OR: InputMaybe<Array<FormLayoutHavingInput>>;
  arrayAgg: InputMaybe<FormLayoutHavingArrayAggInput>;
  average: InputMaybe<FormLayoutHavingAverageInput>;
  distinctCount: InputMaybe<FormLayoutHavingDistinctCountInput>;
  max: InputMaybe<FormLayoutHavingMaxInput>;
  min: InputMaybe<FormLayoutHavingMinInput>;
  stddevPopulation: InputMaybe<FormLayoutHavingStddevPopulationInput>;
  stddevSample: InputMaybe<FormLayoutHavingStddevSampleInput>;
  sum: InputMaybe<FormLayoutHavingSumInput>;
  variancePopulation: InputMaybe<FormLayoutHavingVariancePopulationInput>;
  varianceSample: InputMaybe<FormLayoutHavingVarianceSampleInput>;
};

export type FormLayoutHavingMaxInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingMinInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingStddevPopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingStddevSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingSumInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingVariancePopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutHavingVarianceSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  formId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  parentId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type FormLayoutMaxAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  formId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FormLayoutMaxAggregates = {
  /** Maximum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of formId across the matching connection */
  formId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FormLayoutMaxAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutMinAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  formId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  parentId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FormLayoutMinAggregates = {
  /** Minimum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of formId across the matching connection */
  formId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of parentId across the matching connection */
  parentId: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FormLayoutMinAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutStddevPopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type FormLayoutStddevPopulationAggregates = {
  /** Population standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type FormLayoutStddevPopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevPopulationAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevPopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutStddevSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type FormLayoutStddevSampleAggregates = {
  /** Sample standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type FormLayoutStddevSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevSampleAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutSumAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  formId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  parentId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
};

export type FormLayoutSumAggregates = {
  /** Sum of columnId across the matching connection */
  columnId: Scalars['BigInt']['output'];
  /** Sum of formId across the matching connection */
  formId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
};


export type FormLayoutSumAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutSumAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutSumAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

/** A filter to be used against many `FormLayout` object types. All fields are combined with a logical ‘and.’ */
export type FormLayoutToManyFormLayoutFilter = {
  /** Aggregates across related `FormLayout` match the filter criteria. */
  aggregates: InputMaybe<FormLayoutAggregatesFilter>;
  /** Every related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormLayoutFilter>;
  /** No related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormLayoutFilter>;
  /** Some related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormLayoutFilter>;
};

export type FormLayoutVariancePopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type FormLayoutVariancePopulationAggregates = {
  /** Population variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type FormLayoutVariancePopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVariancePopulationAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVariancePopulationAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

export type FormLayoutVarianceSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  formId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  parentId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
};

export type FormLayoutVarianceSampleAggregates = {
  /** Sample variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of formId across the matching connection */
  formId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of parentId across the matching connection */
  parentId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
};


export type FormLayoutVarianceSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVarianceSampleAggregatesFormIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVarianceSampleAggregatesParentIdArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type FormLayoutVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};

/** A connection to a list of `FormLayout` values. */
export type FormLayoutsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormLayoutAggregates>;
  /** A list of edges which contains the `FormLayout` and cursor to aid in pagination. */
  edges: Array<FormLayoutsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormLayoutAggregates>>;
  /** A list of `FormLayout` objects. */
  nodes: Array<Maybe<FormLayout>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FormLayout` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FormLayout` values. */
export type FormLayoutsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormLayoutGroupBy>;
  having: InputMaybe<FormLayoutHavingInput>;
};

/** A `FormLayout` edge in the connection. */
export type FormLayoutsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `FormLayout` at the end of the edge. */
  node: Maybe<FormLayout>;
};

/** Methods to use when ordering `FormLayout`. */
export enum FormLayoutsOrderBy {
  ChildFormLayoutsConnectionArrayAggColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionArrayAggColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionArrayAggCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ChildFormLayoutsConnectionArrayAggCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ChildFormLayoutsConnectionArrayAggFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  ChildFormLayoutsConnectionArrayAggFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  ChildFormLayoutsConnectionArrayAggIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ChildFormLayoutsConnectionArrayAggIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ChildFormLayoutsConnectionArrayAggNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_NAME_ASC',
  ChildFormLayoutsConnectionArrayAggNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_NAME_DESC',
  ChildFormLayoutsConnectionArrayAggParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  ChildFormLayoutsConnectionArrayAggParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  ChildFormLayoutsConnectionArrayAggPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ChildFormLayoutsConnectionArrayAggPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ChildFormLayoutsConnectionArrayAggTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ChildFormLayoutsConnectionArrayAggTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ChildFormLayoutsConnectionArrayAggUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionArrayAggUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionAverageColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionAverageColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionAverageCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ChildFormLayoutsConnectionAverageCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ChildFormLayoutsConnectionAverageFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_FORM_ID_ASC',
  ChildFormLayoutsConnectionAverageFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_FORM_ID_DESC',
  ChildFormLayoutsConnectionAverageIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_ID_ASC',
  ChildFormLayoutsConnectionAverageIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_ID_DESC',
  ChildFormLayoutsConnectionAverageNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_NAME_ASC',
  ChildFormLayoutsConnectionAverageNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_NAME_DESC',
  ChildFormLayoutsConnectionAverageParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  ChildFormLayoutsConnectionAverageParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  ChildFormLayoutsConnectionAveragePositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_POSITION_ASC',
  ChildFormLayoutsConnectionAveragePositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_POSITION_DESC',
  ChildFormLayoutsConnectionAverageTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_TYPE_ASC',
  ChildFormLayoutsConnectionAverageTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_TYPE_DESC',
  ChildFormLayoutsConnectionAverageUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionAverageUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionCountAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_COUNT_ASC',
  ChildFormLayoutsConnectionCountDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_COUNT_DESC',
  ChildFormLayoutsConnectionDistinctCountColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionDistinctCountColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionDistinctCountCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ChildFormLayoutsConnectionDistinctCountCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ChildFormLayoutsConnectionDistinctCountFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  ChildFormLayoutsConnectionDistinctCountFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  ChildFormLayoutsConnectionDistinctCountIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ChildFormLayoutsConnectionDistinctCountIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ChildFormLayoutsConnectionDistinctCountNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ChildFormLayoutsConnectionDistinctCountNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ChildFormLayoutsConnectionDistinctCountParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  ChildFormLayoutsConnectionDistinctCountParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  ChildFormLayoutsConnectionDistinctCountPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ChildFormLayoutsConnectionDistinctCountPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ChildFormLayoutsConnectionDistinctCountTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ChildFormLayoutsConnectionDistinctCountTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ChildFormLayoutsConnectionDistinctCountUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionDistinctCountUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionMaxColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionMaxColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionMaxCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_CREATED_AT_ASC',
  ChildFormLayoutsConnectionMaxCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_CREATED_AT_DESC',
  ChildFormLayoutsConnectionMaxFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_FORM_ID_ASC',
  ChildFormLayoutsConnectionMaxFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_FORM_ID_DESC',
  ChildFormLayoutsConnectionMaxIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_ID_ASC',
  ChildFormLayoutsConnectionMaxIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_ID_DESC',
  ChildFormLayoutsConnectionMaxNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_NAME_ASC',
  ChildFormLayoutsConnectionMaxNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_NAME_DESC',
  ChildFormLayoutsConnectionMaxParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_PARENT_ID_ASC',
  ChildFormLayoutsConnectionMaxParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_PARENT_ID_DESC',
  ChildFormLayoutsConnectionMaxPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_POSITION_ASC',
  ChildFormLayoutsConnectionMaxPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_POSITION_DESC',
  ChildFormLayoutsConnectionMaxTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_TYPE_ASC',
  ChildFormLayoutsConnectionMaxTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_TYPE_DESC',
  ChildFormLayoutsConnectionMaxUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionMaxUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionMinColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionMinColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionMinCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_CREATED_AT_ASC',
  ChildFormLayoutsConnectionMinCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_CREATED_AT_DESC',
  ChildFormLayoutsConnectionMinFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_FORM_ID_ASC',
  ChildFormLayoutsConnectionMinFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_FORM_ID_DESC',
  ChildFormLayoutsConnectionMinIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_ID_ASC',
  ChildFormLayoutsConnectionMinIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_ID_DESC',
  ChildFormLayoutsConnectionMinNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_NAME_ASC',
  ChildFormLayoutsConnectionMinNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_NAME_DESC',
  ChildFormLayoutsConnectionMinParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_PARENT_ID_ASC',
  ChildFormLayoutsConnectionMinParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_PARENT_ID_DESC',
  ChildFormLayoutsConnectionMinPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_POSITION_ASC',
  ChildFormLayoutsConnectionMinPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_POSITION_DESC',
  ChildFormLayoutsConnectionMinTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_TYPE_ASC',
  ChildFormLayoutsConnectionMinTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_TYPE_DESC',
  ChildFormLayoutsConnectionMinUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionMinUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionStddevPopulationColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionStddevPopulationColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionStddevPopulationCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ChildFormLayoutsConnectionStddevPopulationCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ChildFormLayoutsConnectionStddevPopulationFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  ChildFormLayoutsConnectionStddevPopulationFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  ChildFormLayoutsConnectionStddevPopulationIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ChildFormLayoutsConnectionStddevPopulationIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ChildFormLayoutsConnectionStddevPopulationNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ChildFormLayoutsConnectionStddevPopulationNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ChildFormLayoutsConnectionStddevPopulationParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  ChildFormLayoutsConnectionStddevPopulationParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  ChildFormLayoutsConnectionStddevPopulationPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ChildFormLayoutsConnectionStddevPopulationPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ChildFormLayoutsConnectionStddevPopulationTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ChildFormLayoutsConnectionStddevPopulationTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ChildFormLayoutsConnectionStddevPopulationUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionStddevPopulationUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionStddevSampleColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionStddevSampleColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionStddevSampleCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ChildFormLayoutsConnectionStddevSampleCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ChildFormLayoutsConnectionStddevSampleFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  ChildFormLayoutsConnectionStddevSampleFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  ChildFormLayoutsConnectionStddevSampleIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ChildFormLayoutsConnectionStddevSampleIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ChildFormLayoutsConnectionStddevSampleNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ChildFormLayoutsConnectionStddevSampleNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ChildFormLayoutsConnectionStddevSampleParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  ChildFormLayoutsConnectionStddevSampleParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  ChildFormLayoutsConnectionStddevSamplePositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ChildFormLayoutsConnectionStddevSamplePositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ChildFormLayoutsConnectionStddevSampleTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ChildFormLayoutsConnectionStddevSampleTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ChildFormLayoutsConnectionStddevSampleUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionStddevSampleUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionSumColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionSumColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionSumCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_CREATED_AT_ASC',
  ChildFormLayoutsConnectionSumCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_CREATED_AT_DESC',
  ChildFormLayoutsConnectionSumFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_FORM_ID_ASC',
  ChildFormLayoutsConnectionSumFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_FORM_ID_DESC',
  ChildFormLayoutsConnectionSumIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_ID_ASC',
  ChildFormLayoutsConnectionSumIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_ID_DESC',
  ChildFormLayoutsConnectionSumNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_NAME_ASC',
  ChildFormLayoutsConnectionSumNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_NAME_DESC',
  ChildFormLayoutsConnectionSumParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_PARENT_ID_ASC',
  ChildFormLayoutsConnectionSumParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_PARENT_ID_DESC',
  ChildFormLayoutsConnectionSumPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_POSITION_ASC',
  ChildFormLayoutsConnectionSumPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_POSITION_DESC',
  ChildFormLayoutsConnectionSumTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_TYPE_ASC',
  ChildFormLayoutsConnectionSumTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_TYPE_DESC',
  ChildFormLayoutsConnectionSumUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionSumUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionVariancePopulationColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionVariancePopulationColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionVariancePopulationCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ChildFormLayoutsConnectionVariancePopulationCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ChildFormLayoutsConnectionVariancePopulationFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  ChildFormLayoutsConnectionVariancePopulationFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  ChildFormLayoutsConnectionVariancePopulationIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ChildFormLayoutsConnectionVariancePopulationIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ChildFormLayoutsConnectionVariancePopulationNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ChildFormLayoutsConnectionVariancePopulationNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ChildFormLayoutsConnectionVariancePopulationParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  ChildFormLayoutsConnectionVariancePopulationParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  ChildFormLayoutsConnectionVariancePopulationPositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ChildFormLayoutsConnectionVariancePopulationPositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ChildFormLayoutsConnectionVariancePopulationTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ChildFormLayoutsConnectionVariancePopulationTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ChildFormLayoutsConnectionVariancePopulationUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionVariancePopulationUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ChildFormLayoutsConnectionVarianceSampleColumnIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ChildFormLayoutsConnectionVarianceSampleColumnIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ChildFormLayoutsConnectionVarianceSampleCreatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ChildFormLayoutsConnectionVarianceSampleCreatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ChildFormLayoutsConnectionVarianceSampleFormIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  ChildFormLayoutsConnectionVarianceSampleFormIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  ChildFormLayoutsConnectionVarianceSampleIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ChildFormLayoutsConnectionVarianceSampleIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ChildFormLayoutsConnectionVarianceSampleNameAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ChildFormLayoutsConnectionVarianceSampleNameDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ChildFormLayoutsConnectionVarianceSampleParentIdAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ChildFormLayoutsConnectionVarianceSampleParentIdDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  ChildFormLayoutsConnectionVarianceSamplePositionAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ChildFormLayoutsConnectionVarianceSamplePositionDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ChildFormLayoutsConnectionVarianceSampleTypeAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ChildFormLayoutsConnectionVarianceSampleTypeDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ChildFormLayoutsConnectionVarianceSampleUpdatedAtAsc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ChildFormLayoutsConnectionVarianceSampleUpdatedAtDesc = 'CHILD_FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ColumnIdAsc = 'COLUMN_ID_ASC',
  ColumnIdDesc = 'COLUMN_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FormIdAsc = 'FORM_ID_ASC',
  FormIdDesc = 'FORM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type FormMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  templateId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FormMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateId across the matching connection */
  templateId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FormMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  templateId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type FormMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of fileId across the matching connection */
  fileId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateId across the matching connection */
  templateId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type FormMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormProgress = {
  filled: Maybe<Scalars['Int']['output']>;
  progress: Maybe<Scalars['Float']['output']>;
  total: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type FormProjectsByFileFormIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormProjectsByFileFormIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type FormProjectsByFileFormIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type FormProjectsByFileFormIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type FormProjectsByFileFormIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type FormProjectsByFileFormIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FormStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  templateId: InputMaybe<BigFloatFilter>;
};

export type FormStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateId across the matching connection */
  templateId: Maybe<Scalars['BigFloat']['output']>;
};


export type FormStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevPopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevPopulationAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  templateId: InputMaybe<BigFloatFilter>;
};

export type FormStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateId across the matching connection */
  templateId: Maybe<Scalars['BigFloat']['output']>;
};


export type FormStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormStddevSampleAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  templateId: InputMaybe<BigIntFilter>;
};

export type FormSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of fileId across the matching connection */
  fileId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
  /** Sum of templateId across the matching connection */
  templateId: Scalars['BigInt']['output'];
};


export type FormSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormSumAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormSumAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type FormToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `FormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type FormToManyFormAssigneeFilter = {
  /** Aggregates across related `FormAssignee` match the filter criteria. */
  aggregates: InputMaybe<FormAssigneeAggregatesFilter>;
  /** Every related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormAssigneeFilter>;
  /** No related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormAssigneeFilter>;
  /** Some related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormAssigneeFilter>;
};

/** A filter to be used against many `FormLayout` object types. All fields are combined with a logical ‘and.’ */
export type FormToManyFormLayoutFilter = {
  /** Aggregates across related `FormLayout` match the filter criteria. */
  aggregates: InputMaybe<FormLayoutAggregatesFilter>;
  /** Every related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormLayoutFilter>;
  /** No related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormLayoutFilter>;
  /** Some related `FormLayout` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormLayoutFilter>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type FormUsersByFileFormIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<FormUsersByFileFormIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type FormUsersByFileFormIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type FormUsersByFileFormIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type FormUsersByFileFormIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type FormUsersByFileFormIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FormVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  templateId: InputMaybe<BigFloatFilter>;
};

export type FormVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateId across the matching connection */
  templateId: Maybe<Scalars['BigFloat']['output']>;
};


export type FormVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVariancePopulationAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVariancePopulationAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

export type FormVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  templateId: InputMaybe<BigFloatFilter>;
};

export type FormVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of fileId across the matching connection */
  fileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateId across the matching connection */
  templateId: Maybe<Scalars['BigFloat']['output']>;
};


export type FormVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVarianceSampleAggregatesFileIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type FormVarianceSampleAggregatesTemplateIdArgs = {
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};

/** A connection to a list of `Form` values. */
export type FormsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form` and cursor to aid in pagination. */
  edges: Array<FormsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values. */
export type FormsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection. */
export type FormsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};

/** Methods to use when ordering `Form`. */
export enum FormsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FilesByFormIdConnectionArrayAggActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByFormIdConnectionArrayAggActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByFormIdConnectionArrayAggActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionArrayAggActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionArrayAggAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByFormIdConnectionArrayAggAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByFormIdConnectionArrayAggCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByFormIdConnectionArrayAggCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByFormIdConnectionArrayAggCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByFormIdConnectionArrayAggCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByFormIdConnectionArrayAggCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByFormIdConnectionArrayAggCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByFormIdConnectionArrayAggCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByFormIdConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionArrayAggCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByFormIdConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionArrayAggDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionArrayAggDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionArrayAggFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByFormIdConnectionArrayAggFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByFormIdConnectionArrayAggIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByFormIdConnectionArrayAggIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByFormIdConnectionArrayAggIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionArrayAggIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionArrayAggIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByFormIdConnectionArrayAggIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByFormIdConnectionArrayAggMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByFormIdConnectionArrayAggMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByFormIdConnectionArrayAggModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByFormIdConnectionArrayAggModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByFormIdConnectionArrayAggNameAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByFormIdConnectionArrayAggNameDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByFormIdConnectionArrayAggOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByFormIdConnectionArrayAggOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByFormIdConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionArrayAggPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByFormIdConnectionArrayAggPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByFormIdConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionArrayAggProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionArrayAggProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionArrayAggProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByFormIdConnectionArrayAggProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByFormIdConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionArrayAggSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionArrayAggSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionArrayAggStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByFormIdConnectionArrayAggStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByFormIdConnectionArrayAggTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByFormIdConnectionArrayAggTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByFormIdConnectionArrayAggUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByFormIdConnectionArrayAggUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByFormIdConnectionArrayAggUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByFormIdConnectionArrayAggUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByFormIdConnectionArrayAggValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionArrayAggValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionAverageActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByFormIdConnectionAverageActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByFormIdConnectionAverageActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionAverageActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionAverageAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByFormIdConnectionAverageAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByFormIdConnectionAverageCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByFormIdConnectionAverageCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByFormIdConnectionAverageCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByFormIdConnectionAverageCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByFormIdConnectionAverageCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByFormIdConnectionAverageCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByFormIdConnectionAverageCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByFormIdConnectionAverageCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionAverageCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionAverageCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByFormIdConnectionAverageCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionAverageCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionAverageDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionAverageDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionAverageFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByFormIdConnectionAverageFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByFormIdConnectionAverageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ID_ASC',
  FilesByFormIdConnectionAverageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ID_DESC',
  FilesByFormIdConnectionAverageIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionAverageIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionAverageIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByFormIdConnectionAverageIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByFormIdConnectionAverageMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByFormIdConnectionAverageMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByFormIdConnectionAverageModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByFormIdConnectionAverageModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByFormIdConnectionAverageNameAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_NAME_ASC',
  FilesByFormIdConnectionAverageNameDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_NAME_DESC',
  FilesByFormIdConnectionAverageOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByFormIdConnectionAverageOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByFormIdConnectionAveragePrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionAveragePrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionAveragePrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByFormIdConnectionAveragePrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByFormIdConnectionAveragePrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionAveragePrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionAveragePrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionAveragePrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionAverageProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionAverageProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionAverageProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByFormIdConnectionAverageProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByFormIdConnectionAverageSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionAverageSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionAverageSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionAverageSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionAverageStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByFormIdConnectionAverageStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByFormIdConnectionAverageTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByFormIdConnectionAverageTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByFormIdConnectionAverageUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByFormIdConnectionAverageUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByFormIdConnectionAverageUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_UUID_ASC',
  FilesByFormIdConnectionAverageUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_UUID_DESC',
  FilesByFormIdConnectionAverageValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionAverageValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionCountAsc = 'FILES_BY_FORM_ID_CONNECTION_COUNT_ASC',
  FilesByFormIdConnectionCountDesc = 'FILES_BY_FORM_ID_CONNECTION_COUNT_DESC',
  FilesByFormIdConnectionDistinctCountActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByFormIdConnectionDistinctCountActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByFormIdConnectionDistinctCountActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionDistinctCountActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionDistinctCountAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByFormIdConnectionDistinctCountAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByFormIdConnectionDistinctCountCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByFormIdConnectionDistinctCountCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByFormIdConnectionDistinctCountCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByFormIdConnectionDistinctCountCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByFormIdConnectionDistinctCountCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByFormIdConnectionDistinctCountCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByFormIdConnectionDistinctCountCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByFormIdConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionDistinctCountCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByFormIdConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionDistinctCountFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByFormIdConnectionDistinctCountFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByFormIdConnectionDistinctCountIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByFormIdConnectionDistinctCountIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByFormIdConnectionDistinctCountIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionDistinctCountIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionDistinctCountIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByFormIdConnectionDistinctCountIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByFormIdConnectionDistinctCountMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByFormIdConnectionDistinctCountMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByFormIdConnectionDistinctCountModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByFormIdConnectionDistinctCountModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByFormIdConnectionDistinctCountNameAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByFormIdConnectionDistinctCountNameDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByFormIdConnectionDistinctCountOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByFormIdConnectionDistinctCountOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByFormIdConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionDistinctCountPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByFormIdConnectionDistinctCountPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByFormIdConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionDistinctCountProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByFormIdConnectionDistinctCountProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByFormIdConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionDistinctCountStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByFormIdConnectionDistinctCountStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByFormIdConnectionDistinctCountTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByFormIdConnectionDistinctCountTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByFormIdConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByFormIdConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByFormIdConnectionDistinctCountUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByFormIdConnectionDistinctCountUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByFormIdConnectionDistinctCountValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionDistinctCountValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionMaxActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByFormIdConnectionMaxActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByFormIdConnectionMaxActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionMaxActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionMaxAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByFormIdConnectionMaxAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByFormIdConnectionMaxCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByFormIdConnectionMaxCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByFormIdConnectionMaxCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByFormIdConnectionMaxCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByFormIdConnectionMaxCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByFormIdConnectionMaxCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByFormIdConnectionMaxCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByFormIdConnectionMaxCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionMaxCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionMaxCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByFormIdConnectionMaxCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionMaxCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionMaxDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionMaxDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionMaxFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_FORM_ID_ASC',
  FilesByFormIdConnectionMaxFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_FORM_ID_DESC',
  FilesByFormIdConnectionMaxIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ID_ASC',
  FilesByFormIdConnectionMaxIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ID_DESC',
  FilesByFormIdConnectionMaxIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionMaxIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionMaxIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_IS_FORM_ASC',
  FilesByFormIdConnectionMaxIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_IS_FORM_DESC',
  FilesByFormIdConnectionMaxMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_META_DATA_ASC',
  FilesByFormIdConnectionMaxMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_META_DATA_DESC',
  FilesByFormIdConnectionMaxModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByFormIdConnectionMaxModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByFormIdConnectionMaxNameAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_NAME_ASC',
  FilesByFormIdConnectionMaxNameDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_NAME_DESC',
  FilesByFormIdConnectionMaxOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ORIGIN_ASC',
  FilesByFormIdConnectionMaxOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_ORIGIN_DESC',
  FilesByFormIdConnectionMaxPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionMaxPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionMaxPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByFormIdConnectionMaxPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByFormIdConnectionMaxPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionMaxPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionMaxPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionMaxPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionMaxProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionMaxProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionMaxProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByFormIdConnectionMaxProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByFormIdConnectionMaxSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionMaxSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionMaxSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionMaxSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionMaxStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByFormIdConnectionMaxStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByFormIdConnectionMaxTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_TYPE_ASC',
  FilesByFormIdConnectionMaxTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_TYPE_DESC',
  FilesByFormIdConnectionMaxUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByFormIdConnectionMaxUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByFormIdConnectionMaxUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_UUID_ASC',
  FilesByFormIdConnectionMaxUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_UUID_DESC',
  FilesByFormIdConnectionMaxValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionMaxValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionMinActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByFormIdConnectionMinActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByFormIdConnectionMinActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionMinActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionMinAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByFormIdConnectionMinAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByFormIdConnectionMinCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByFormIdConnectionMinCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByFormIdConnectionMinCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByFormIdConnectionMinCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByFormIdConnectionMinCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByFormIdConnectionMinCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByFormIdConnectionMinCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByFormIdConnectionMinCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionMinCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionMinCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByFormIdConnectionMinCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionMinCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionMinDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionMinDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionMinFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_FORM_ID_ASC',
  FilesByFormIdConnectionMinFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_FORM_ID_DESC',
  FilesByFormIdConnectionMinIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ID_ASC',
  FilesByFormIdConnectionMinIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ID_DESC',
  FilesByFormIdConnectionMinIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionMinIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionMinIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_IS_FORM_ASC',
  FilesByFormIdConnectionMinIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_IS_FORM_DESC',
  FilesByFormIdConnectionMinMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_META_DATA_ASC',
  FilesByFormIdConnectionMinMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_META_DATA_DESC',
  FilesByFormIdConnectionMinModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByFormIdConnectionMinModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByFormIdConnectionMinNameAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_NAME_ASC',
  FilesByFormIdConnectionMinNameDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_NAME_DESC',
  FilesByFormIdConnectionMinOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ORIGIN_ASC',
  FilesByFormIdConnectionMinOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_ORIGIN_DESC',
  FilesByFormIdConnectionMinPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionMinPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionMinPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByFormIdConnectionMinPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByFormIdConnectionMinPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionMinPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionMinPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionMinPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionMinProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionMinProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionMinProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionMinProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionMinProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByFormIdConnectionMinProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByFormIdConnectionMinSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionMinSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionMinSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionMinSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionMinStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByFormIdConnectionMinStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByFormIdConnectionMinTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_TYPE_ASC',
  FilesByFormIdConnectionMinTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_TYPE_DESC',
  FilesByFormIdConnectionMinUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByFormIdConnectionMinUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByFormIdConnectionMinUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_UUID_ASC',
  FilesByFormIdConnectionMinUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_UUID_DESC',
  FilesByFormIdConnectionMinValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionMinValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionStddevPopulationActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByFormIdConnectionStddevPopulationActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByFormIdConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByFormIdConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByFormIdConnectionStddevPopulationCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByFormIdConnectionStddevPopulationCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByFormIdConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByFormIdConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByFormIdConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByFormIdConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByFormIdConnectionStddevPopulationCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByFormIdConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionStddevPopulationCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByFormIdConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionStddevPopulationFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByFormIdConnectionStddevPopulationFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByFormIdConnectionStddevPopulationIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByFormIdConnectionStddevPopulationIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByFormIdConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionStddevPopulationIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByFormIdConnectionStddevPopulationIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByFormIdConnectionStddevPopulationMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByFormIdConnectionStddevPopulationMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByFormIdConnectionStddevPopulationModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByFormIdConnectionStddevPopulationModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByFormIdConnectionStddevPopulationNameAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByFormIdConnectionStddevPopulationNameDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByFormIdConnectionStddevPopulationOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByFormIdConnectionStddevPopulationOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByFormIdConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByFormIdConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByFormIdConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionStddevPopulationProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByFormIdConnectionStddevPopulationProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByFormIdConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionStddevPopulationStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByFormIdConnectionStddevPopulationStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByFormIdConnectionStddevPopulationTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByFormIdConnectionStddevPopulationTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByFormIdConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByFormIdConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByFormIdConnectionStddevPopulationUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByFormIdConnectionStddevPopulationUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByFormIdConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionStddevSampleActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByFormIdConnectionStddevSampleActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByFormIdConnectionStddevSampleActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionStddevSampleActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionStddevSampleAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByFormIdConnectionStddevSampleAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByFormIdConnectionStddevSampleCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByFormIdConnectionStddevSampleCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByFormIdConnectionStddevSampleCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByFormIdConnectionStddevSampleCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByFormIdConnectionStddevSampleCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByFormIdConnectionStddevSampleCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByFormIdConnectionStddevSampleCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByFormIdConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionStddevSampleCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByFormIdConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionStddevSampleFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByFormIdConnectionStddevSampleFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByFormIdConnectionStddevSampleIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByFormIdConnectionStddevSampleIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByFormIdConnectionStddevSampleIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionStddevSampleIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionStddevSampleIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByFormIdConnectionStddevSampleIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByFormIdConnectionStddevSampleMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByFormIdConnectionStddevSampleMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByFormIdConnectionStddevSampleModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByFormIdConnectionStddevSampleModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByFormIdConnectionStddevSampleNameAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByFormIdConnectionStddevSampleNameDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByFormIdConnectionStddevSampleOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByFormIdConnectionStddevSampleOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByFormIdConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionStddevSamplePrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByFormIdConnectionStddevSamplePrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByFormIdConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionStddevSampleProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByFormIdConnectionStddevSampleProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByFormIdConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionStddevSampleStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByFormIdConnectionStddevSampleStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByFormIdConnectionStddevSampleTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByFormIdConnectionStddevSampleTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByFormIdConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByFormIdConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByFormIdConnectionStddevSampleUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByFormIdConnectionStddevSampleUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByFormIdConnectionStddevSampleValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionStddevSampleValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionSumActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByFormIdConnectionSumActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByFormIdConnectionSumActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionSumActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionSumAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByFormIdConnectionSumAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByFormIdConnectionSumCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByFormIdConnectionSumCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByFormIdConnectionSumCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByFormIdConnectionSumCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByFormIdConnectionSumCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByFormIdConnectionSumCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByFormIdConnectionSumCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByFormIdConnectionSumCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionSumCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionSumCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByFormIdConnectionSumCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionSumCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionSumDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionSumDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionSumFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_FORM_ID_ASC',
  FilesByFormIdConnectionSumFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_FORM_ID_DESC',
  FilesByFormIdConnectionSumIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ID_ASC',
  FilesByFormIdConnectionSumIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ID_DESC',
  FilesByFormIdConnectionSumIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionSumIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionSumIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_IS_FORM_ASC',
  FilesByFormIdConnectionSumIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_IS_FORM_DESC',
  FilesByFormIdConnectionSumMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_META_DATA_ASC',
  FilesByFormIdConnectionSumMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_META_DATA_DESC',
  FilesByFormIdConnectionSumModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByFormIdConnectionSumModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByFormIdConnectionSumNameAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_NAME_ASC',
  FilesByFormIdConnectionSumNameDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_NAME_DESC',
  FilesByFormIdConnectionSumOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ORIGIN_ASC',
  FilesByFormIdConnectionSumOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_ORIGIN_DESC',
  FilesByFormIdConnectionSumPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionSumPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionSumPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByFormIdConnectionSumPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByFormIdConnectionSumPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionSumPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionSumPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionSumPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionSumProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionSumProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionSumProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionSumProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionSumProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByFormIdConnectionSumProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByFormIdConnectionSumSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionSumSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionSumSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionSumSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionSumStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByFormIdConnectionSumStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByFormIdConnectionSumTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_TYPE_ASC',
  FilesByFormIdConnectionSumTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_TYPE_DESC',
  FilesByFormIdConnectionSumUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByFormIdConnectionSumUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByFormIdConnectionSumUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_UUID_ASC',
  FilesByFormIdConnectionSumUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_UUID_DESC',
  FilesByFormIdConnectionSumValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionSumValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionVariancePopulationActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByFormIdConnectionVariancePopulationActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByFormIdConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByFormIdConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByFormIdConnectionVariancePopulationCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByFormIdConnectionVariancePopulationCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByFormIdConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByFormIdConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByFormIdConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByFormIdConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByFormIdConnectionVariancePopulationCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByFormIdConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionVariancePopulationCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByFormIdConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionVariancePopulationFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByFormIdConnectionVariancePopulationFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByFormIdConnectionVariancePopulationIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByFormIdConnectionVariancePopulationIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByFormIdConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionVariancePopulationIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByFormIdConnectionVariancePopulationIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByFormIdConnectionVariancePopulationMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByFormIdConnectionVariancePopulationMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByFormIdConnectionVariancePopulationModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByFormIdConnectionVariancePopulationModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByFormIdConnectionVariancePopulationNameAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByFormIdConnectionVariancePopulationNameDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByFormIdConnectionVariancePopulationOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByFormIdConnectionVariancePopulationOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByFormIdConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByFormIdConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByFormIdConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionVariancePopulationProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByFormIdConnectionVariancePopulationProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByFormIdConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionVariancePopulationStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByFormIdConnectionVariancePopulationStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByFormIdConnectionVariancePopulationTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByFormIdConnectionVariancePopulationTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByFormIdConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByFormIdConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByFormIdConnectionVariancePopulationUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByFormIdConnectionVariancePopulationUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByFormIdConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionVarianceSampleActionIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByFormIdConnectionVarianceSampleActionIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByFormIdConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByFormIdConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByFormIdConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByFormIdConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByFormIdConnectionVarianceSampleCommentIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByFormIdConnectionVarianceSampleCommentIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByFormIdConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByFormIdConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByFormIdConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByFormIdConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByFormIdConnectionVarianceSampleCreatedByAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByFormIdConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByFormIdConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByFormIdConnectionVarianceSampleCreatedByDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByFormIdConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByFormIdConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByFormIdConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByFormIdConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByFormIdConnectionVarianceSampleFormIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByFormIdConnectionVarianceSampleFormIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByFormIdConnectionVarianceSampleIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByFormIdConnectionVarianceSampleIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByFormIdConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByFormIdConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByFormIdConnectionVarianceSampleIsFormAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByFormIdConnectionVarianceSampleIsFormDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByFormIdConnectionVarianceSampleMetaDataAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByFormIdConnectionVarianceSampleMetaDataDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByFormIdConnectionVarianceSampleModifiedByAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByFormIdConnectionVarianceSampleModifiedByDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByFormIdConnectionVarianceSampleNameAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByFormIdConnectionVarianceSampleNameDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByFormIdConnectionVarianceSampleOriginAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByFormIdConnectionVarianceSampleOriginDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByFormIdConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByFormIdConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByFormIdConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByFormIdConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByFormIdConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByFormIdConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByFormIdConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByFormIdConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByFormIdConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByFormIdConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByFormIdConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByFormIdConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByFormIdConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByFormIdConnectionVarianceSampleProjectIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByFormIdConnectionVarianceSampleProjectIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByFormIdConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByFormIdConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByFormIdConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByFormIdConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByFormIdConnectionVarianceSampleStageIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByFormIdConnectionVarianceSampleStageIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByFormIdConnectionVarianceSampleTypeAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByFormIdConnectionVarianceSampleTypeDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByFormIdConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByFormIdConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByFormIdConnectionVarianceSampleUuidAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByFormIdConnectionVarianceSampleUuidDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByFormIdConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByFormIdConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_FORM_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  FormAssigneesConnectionArrayAggFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FormAssigneesConnectionArrayAggFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FormAssigneesConnectionArrayAggProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionArrayAggProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionArrayAggUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FormAssigneesConnectionArrayAggUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FormAssigneesConnectionAverageFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_FORM_ID_ASC',
  FormAssigneesConnectionAverageFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_FORM_ID_DESC',
  FormAssigneesConnectionAverageProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionAverageProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionAverageUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_USER_ID_ASC',
  FormAssigneesConnectionAverageUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_USER_ID_DESC',
  FormAssigneesConnectionCountAsc = 'FORM_ASSIGNEES_CONNECTION_COUNT_ASC',
  FormAssigneesConnectionCountDesc = 'FORM_ASSIGNEES_CONNECTION_COUNT_DESC',
  FormAssigneesConnectionDistinctCountFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FormAssigneesConnectionDistinctCountFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FormAssigneesConnectionDistinctCountProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionDistinctCountProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionDistinctCountUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FormAssigneesConnectionDistinctCountUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FormAssigneesConnectionMaxFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_FORM_ID_ASC',
  FormAssigneesConnectionMaxFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_FORM_ID_DESC',
  FormAssigneesConnectionMaxProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionMaxProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionMaxUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_USER_ID_ASC',
  FormAssigneesConnectionMaxUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_USER_ID_DESC',
  FormAssigneesConnectionMinFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_FORM_ID_ASC',
  FormAssigneesConnectionMinFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_FORM_ID_DESC',
  FormAssigneesConnectionMinProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionMinProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionMinUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_USER_ID_ASC',
  FormAssigneesConnectionMinUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_USER_ID_DESC',
  FormAssigneesConnectionStddevPopulationFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FormAssigneesConnectionStddevPopulationFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FormAssigneesConnectionStddevPopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionStddevPopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionStddevPopulationUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FormAssigneesConnectionStddevPopulationUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FormAssigneesConnectionStddevSampleFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FormAssigneesConnectionStddevSampleFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FormAssigneesConnectionStddevSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionStddevSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionStddevSampleUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FormAssigneesConnectionStddevSampleUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FormAssigneesConnectionSumFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_FORM_ID_ASC',
  FormAssigneesConnectionSumFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_FORM_ID_DESC',
  FormAssigneesConnectionSumProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionSumProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionSumUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_USER_ID_ASC',
  FormAssigneesConnectionSumUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_USER_ID_DESC',
  FormAssigneesConnectionVariancePopulationFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FormAssigneesConnectionVariancePopulationFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FormAssigneesConnectionVariancePopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionVariancePopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionVariancePopulationUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FormAssigneesConnectionVariancePopulationUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FormAssigneesConnectionVarianceSampleFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FormAssigneesConnectionVarianceSampleFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FormAssigneesConnectionVarianceSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionVarianceSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionVarianceSampleUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FormAssigneesConnectionVarianceSampleUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  FormLayoutsConnectionArrayAggColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  FormLayoutsConnectionArrayAggColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  FormLayoutsConnectionArrayAggCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FormLayoutsConnectionArrayAggCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FormLayoutsConnectionArrayAggFormIdAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FormLayoutsConnectionArrayAggFormIdDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FormLayoutsConnectionArrayAggIdAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_ID_ASC',
  FormLayoutsConnectionArrayAggIdDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_ID_DESC',
  FormLayoutsConnectionArrayAggNameAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_NAME_ASC',
  FormLayoutsConnectionArrayAggNameDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_NAME_DESC',
  FormLayoutsConnectionArrayAggParentIdAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FormLayoutsConnectionArrayAggParentIdDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FormLayoutsConnectionArrayAggPositionAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  FormLayoutsConnectionArrayAggPositionDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  FormLayoutsConnectionArrayAggTypeAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FormLayoutsConnectionArrayAggTypeDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FormLayoutsConnectionArrayAggUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FormLayoutsConnectionArrayAggUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FormLayoutsConnectionAverageColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  FormLayoutsConnectionAverageColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  FormLayoutsConnectionAverageCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FormLayoutsConnectionAverageCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FormLayoutsConnectionAverageFormIdAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_FORM_ID_ASC',
  FormLayoutsConnectionAverageFormIdDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_FORM_ID_DESC',
  FormLayoutsConnectionAverageIdAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_ID_ASC',
  FormLayoutsConnectionAverageIdDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_ID_DESC',
  FormLayoutsConnectionAverageNameAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_NAME_ASC',
  FormLayoutsConnectionAverageNameDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_NAME_DESC',
  FormLayoutsConnectionAverageParentIdAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FormLayoutsConnectionAverageParentIdDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FormLayoutsConnectionAveragePositionAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_POSITION_ASC',
  FormLayoutsConnectionAveragePositionDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_POSITION_DESC',
  FormLayoutsConnectionAverageTypeAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_TYPE_ASC',
  FormLayoutsConnectionAverageTypeDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_TYPE_DESC',
  FormLayoutsConnectionAverageUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FormLayoutsConnectionAverageUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FormLayoutsConnectionCountAsc = 'FORM_LAYOUTS_CONNECTION_COUNT_ASC',
  FormLayoutsConnectionCountDesc = 'FORM_LAYOUTS_CONNECTION_COUNT_DESC',
  FormLayoutsConnectionDistinctCountColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  FormLayoutsConnectionDistinctCountColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  FormLayoutsConnectionDistinctCountCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FormLayoutsConnectionDistinctCountCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FormLayoutsConnectionDistinctCountFormIdAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FormLayoutsConnectionDistinctCountFormIdDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FormLayoutsConnectionDistinctCountIdAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FormLayoutsConnectionDistinctCountIdDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FormLayoutsConnectionDistinctCountNameAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FormLayoutsConnectionDistinctCountNameDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FormLayoutsConnectionDistinctCountParentIdAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FormLayoutsConnectionDistinctCountParentIdDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FormLayoutsConnectionDistinctCountPositionAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  FormLayoutsConnectionDistinctCountPositionDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  FormLayoutsConnectionDistinctCountTypeAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FormLayoutsConnectionDistinctCountTypeDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FormLayoutsConnectionDistinctCountUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FormLayoutsConnectionDistinctCountUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FormLayoutsConnectionMaxColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_MAX_COLUMN_ID_ASC',
  FormLayoutsConnectionMaxColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_MAX_COLUMN_ID_DESC',
  FormLayoutsConnectionMaxCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_MAX_CREATED_AT_ASC',
  FormLayoutsConnectionMaxCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_MAX_CREATED_AT_DESC',
  FormLayoutsConnectionMaxFormIdAsc = 'FORM_LAYOUTS_CONNECTION_MAX_FORM_ID_ASC',
  FormLayoutsConnectionMaxFormIdDesc = 'FORM_LAYOUTS_CONNECTION_MAX_FORM_ID_DESC',
  FormLayoutsConnectionMaxIdAsc = 'FORM_LAYOUTS_CONNECTION_MAX_ID_ASC',
  FormLayoutsConnectionMaxIdDesc = 'FORM_LAYOUTS_CONNECTION_MAX_ID_DESC',
  FormLayoutsConnectionMaxNameAsc = 'FORM_LAYOUTS_CONNECTION_MAX_NAME_ASC',
  FormLayoutsConnectionMaxNameDesc = 'FORM_LAYOUTS_CONNECTION_MAX_NAME_DESC',
  FormLayoutsConnectionMaxParentIdAsc = 'FORM_LAYOUTS_CONNECTION_MAX_PARENT_ID_ASC',
  FormLayoutsConnectionMaxParentIdDesc = 'FORM_LAYOUTS_CONNECTION_MAX_PARENT_ID_DESC',
  FormLayoutsConnectionMaxPositionAsc = 'FORM_LAYOUTS_CONNECTION_MAX_POSITION_ASC',
  FormLayoutsConnectionMaxPositionDesc = 'FORM_LAYOUTS_CONNECTION_MAX_POSITION_DESC',
  FormLayoutsConnectionMaxTypeAsc = 'FORM_LAYOUTS_CONNECTION_MAX_TYPE_ASC',
  FormLayoutsConnectionMaxTypeDesc = 'FORM_LAYOUTS_CONNECTION_MAX_TYPE_DESC',
  FormLayoutsConnectionMaxUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_MAX_UPDATED_AT_ASC',
  FormLayoutsConnectionMaxUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_MAX_UPDATED_AT_DESC',
  FormLayoutsConnectionMinColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_MIN_COLUMN_ID_ASC',
  FormLayoutsConnectionMinColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_MIN_COLUMN_ID_DESC',
  FormLayoutsConnectionMinCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_MIN_CREATED_AT_ASC',
  FormLayoutsConnectionMinCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_MIN_CREATED_AT_DESC',
  FormLayoutsConnectionMinFormIdAsc = 'FORM_LAYOUTS_CONNECTION_MIN_FORM_ID_ASC',
  FormLayoutsConnectionMinFormIdDesc = 'FORM_LAYOUTS_CONNECTION_MIN_FORM_ID_DESC',
  FormLayoutsConnectionMinIdAsc = 'FORM_LAYOUTS_CONNECTION_MIN_ID_ASC',
  FormLayoutsConnectionMinIdDesc = 'FORM_LAYOUTS_CONNECTION_MIN_ID_DESC',
  FormLayoutsConnectionMinNameAsc = 'FORM_LAYOUTS_CONNECTION_MIN_NAME_ASC',
  FormLayoutsConnectionMinNameDesc = 'FORM_LAYOUTS_CONNECTION_MIN_NAME_DESC',
  FormLayoutsConnectionMinParentIdAsc = 'FORM_LAYOUTS_CONNECTION_MIN_PARENT_ID_ASC',
  FormLayoutsConnectionMinParentIdDesc = 'FORM_LAYOUTS_CONNECTION_MIN_PARENT_ID_DESC',
  FormLayoutsConnectionMinPositionAsc = 'FORM_LAYOUTS_CONNECTION_MIN_POSITION_ASC',
  FormLayoutsConnectionMinPositionDesc = 'FORM_LAYOUTS_CONNECTION_MIN_POSITION_DESC',
  FormLayoutsConnectionMinTypeAsc = 'FORM_LAYOUTS_CONNECTION_MIN_TYPE_ASC',
  FormLayoutsConnectionMinTypeDesc = 'FORM_LAYOUTS_CONNECTION_MIN_TYPE_DESC',
  FormLayoutsConnectionMinUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_MIN_UPDATED_AT_ASC',
  FormLayoutsConnectionMinUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_MIN_UPDATED_AT_DESC',
  FormLayoutsConnectionStddevPopulationColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  FormLayoutsConnectionStddevPopulationColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  FormLayoutsConnectionStddevPopulationCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FormLayoutsConnectionStddevPopulationCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FormLayoutsConnectionStddevPopulationFormIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FormLayoutsConnectionStddevPopulationFormIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FormLayoutsConnectionStddevPopulationIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FormLayoutsConnectionStddevPopulationIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FormLayoutsConnectionStddevPopulationNameAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FormLayoutsConnectionStddevPopulationNameDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FormLayoutsConnectionStddevPopulationParentIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FormLayoutsConnectionStddevPopulationParentIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FormLayoutsConnectionStddevPopulationPositionAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  FormLayoutsConnectionStddevPopulationPositionDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  FormLayoutsConnectionStddevPopulationTypeAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FormLayoutsConnectionStddevPopulationTypeDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FormLayoutsConnectionStddevPopulationUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FormLayoutsConnectionStddevPopulationUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FormLayoutsConnectionStddevSampleColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  FormLayoutsConnectionStddevSampleColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  FormLayoutsConnectionStddevSampleCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FormLayoutsConnectionStddevSampleCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FormLayoutsConnectionStddevSampleFormIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FormLayoutsConnectionStddevSampleFormIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FormLayoutsConnectionStddevSampleIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FormLayoutsConnectionStddevSampleIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FormLayoutsConnectionStddevSampleNameAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FormLayoutsConnectionStddevSampleNameDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FormLayoutsConnectionStddevSampleParentIdAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FormLayoutsConnectionStddevSampleParentIdDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FormLayoutsConnectionStddevSamplePositionAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  FormLayoutsConnectionStddevSamplePositionDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  FormLayoutsConnectionStddevSampleTypeAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FormLayoutsConnectionStddevSampleTypeDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FormLayoutsConnectionStddevSampleUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FormLayoutsConnectionStddevSampleUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FormLayoutsConnectionSumColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_SUM_COLUMN_ID_ASC',
  FormLayoutsConnectionSumColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_SUM_COLUMN_ID_DESC',
  FormLayoutsConnectionSumCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_SUM_CREATED_AT_ASC',
  FormLayoutsConnectionSumCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_SUM_CREATED_AT_DESC',
  FormLayoutsConnectionSumFormIdAsc = 'FORM_LAYOUTS_CONNECTION_SUM_FORM_ID_ASC',
  FormLayoutsConnectionSumFormIdDesc = 'FORM_LAYOUTS_CONNECTION_SUM_FORM_ID_DESC',
  FormLayoutsConnectionSumIdAsc = 'FORM_LAYOUTS_CONNECTION_SUM_ID_ASC',
  FormLayoutsConnectionSumIdDesc = 'FORM_LAYOUTS_CONNECTION_SUM_ID_DESC',
  FormLayoutsConnectionSumNameAsc = 'FORM_LAYOUTS_CONNECTION_SUM_NAME_ASC',
  FormLayoutsConnectionSumNameDesc = 'FORM_LAYOUTS_CONNECTION_SUM_NAME_DESC',
  FormLayoutsConnectionSumParentIdAsc = 'FORM_LAYOUTS_CONNECTION_SUM_PARENT_ID_ASC',
  FormLayoutsConnectionSumParentIdDesc = 'FORM_LAYOUTS_CONNECTION_SUM_PARENT_ID_DESC',
  FormLayoutsConnectionSumPositionAsc = 'FORM_LAYOUTS_CONNECTION_SUM_POSITION_ASC',
  FormLayoutsConnectionSumPositionDesc = 'FORM_LAYOUTS_CONNECTION_SUM_POSITION_DESC',
  FormLayoutsConnectionSumTypeAsc = 'FORM_LAYOUTS_CONNECTION_SUM_TYPE_ASC',
  FormLayoutsConnectionSumTypeDesc = 'FORM_LAYOUTS_CONNECTION_SUM_TYPE_DESC',
  FormLayoutsConnectionSumUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_SUM_UPDATED_AT_ASC',
  FormLayoutsConnectionSumUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_SUM_UPDATED_AT_DESC',
  FormLayoutsConnectionVariancePopulationColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  FormLayoutsConnectionVariancePopulationColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  FormLayoutsConnectionVariancePopulationCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FormLayoutsConnectionVariancePopulationCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FormLayoutsConnectionVariancePopulationFormIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FormLayoutsConnectionVariancePopulationFormIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FormLayoutsConnectionVariancePopulationIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FormLayoutsConnectionVariancePopulationIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FormLayoutsConnectionVariancePopulationNameAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FormLayoutsConnectionVariancePopulationNameDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FormLayoutsConnectionVariancePopulationParentIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FormLayoutsConnectionVariancePopulationParentIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FormLayoutsConnectionVariancePopulationPositionAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  FormLayoutsConnectionVariancePopulationPositionDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  FormLayoutsConnectionVariancePopulationTypeAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FormLayoutsConnectionVariancePopulationTypeDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FormLayoutsConnectionVariancePopulationUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FormLayoutsConnectionVariancePopulationUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FormLayoutsConnectionVarianceSampleColumnIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  FormLayoutsConnectionVarianceSampleColumnIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  FormLayoutsConnectionVarianceSampleCreatedAtAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FormLayoutsConnectionVarianceSampleCreatedAtDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FormLayoutsConnectionVarianceSampleFormIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FormLayoutsConnectionVarianceSampleFormIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FormLayoutsConnectionVarianceSampleIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FormLayoutsConnectionVarianceSampleIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FormLayoutsConnectionVarianceSampleNameAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FormLayoutsConnectionVarianceSampleNameDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FormLayoutsConnectionVarianceSampleParentIdAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FormLayoutsConnectionVarianceSampleParentIdDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FormLayoutsConnectionVarianceSamplePositionAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  FormLayoutsConnectionVarianceSamplePositionDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  FormLayoutsConnectionVarianceSampleTypeAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FormLayoutsConnectionVarianceSampleTypeDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FormLayoutsConnectionVarianceSampleUpdatedAtAsc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FormLayoutsConnectionVarianceSampleUpdatedAtDesc = 'FORM_LAYOUTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsTemplateAsc = 'IS_TEMPLATE_ASC',
  IsTemplateDesc = 'IS_TEMPLATE_DESC',
  MembersAsc = 'MEMBERS_ASC',
  MembersDesc = 'MEMBERS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OldFieldsAsc = 'OLD_FIELDS_ASC',
  OldFieldsDesc = 'OLD_FIELDS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeAsc = 'PRIVILEGE_ASC',
  PrivilegeDesc = 'PRIVILEGE_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  PrivilegeTeamAsc = 'PRIVILEGE_TEAM_ASC',
  PrivilegeTeamDesc = 'PRIVILEGE_TEAM_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type GeoEstimation = Node & {
  createdAt: Scalars['Datetime']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  /** Reads a single `Geolocation` that is related to this `GeoEstimation`. */
  location: Maybe<Geolocation>;
  locationId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Task` that is related to this `GeoEstimation`. */
  task: Maybe<Task>;
  taskId: Scalars['Int']['output'];
};

export type GeoEstimationAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<GeoEstimationArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<GeoEstimationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<GeoEstimationDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<GeoEstimationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<GeoEstimationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<GeoEstimationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<GeoEstimationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<GeoEstimationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<GeoEstimationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<GeoEstimationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `GeoEstimation` object types. */
export type GeoEstimationAggregatesFilter = {
  /** Array aggregation aggregate over matching `GeoEstimation` objects. */
  arrayAgg: InputMaybe<GeoEstimationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `GeoEstimation` objects. */
  average: InputMaybe<GeoEstimationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `GeoEstimation` objects. */
  distinctCount: InputMaybe<GeoEstimationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `GeoEstimation` object to be included within the aggregate. */
  filter: InputMaybe<GeoEstimationFilter>;
  /** Maximum aggregate over matching `GeoEstimation` objects. */
  max: InputMaybe<GeoEstimationMaxAggregateFilter>;
  /** Minimum aggregate over matching `GeoEstimation` objects. */
  min: InputMaybe<GeoEstimationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `GeoEstimation` objects. */
  stddevPopulation: InputMaybe<GeoEstimationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `GeoEstimation` objects. */
  stddevSample: InputMaybe<GeoEstimationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `GeoEstimation` objects. */
  sum: InputMaybe<GeoEstimationSumAggregateFilter>;
  /** Population variance aggregate over matching `GeoEstimation` objects. */
  variancePopulation: InputMaybe<GeoEstimationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `GeoEstimation` objects. */
  varianceSample: InputMaybe<GeoEstimationVarianceSampleAggregateFilter>;
};

export type GeoEstimationArrayAggAggregateFilter = {
  duration: InputMaybe<FloatListFilter>;
  id: InputMaybe<IntListFilter>;
  locationId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type GeoEstimationArrayAggAggregates = {
  /** Array aggregation of duration across the matching connection */
  duration: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of locationId across the matching connection */
  locationId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
};


export type GeoEstimationArrayAggAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationArrayAggAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationAverageAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  locationId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type GeoEstimationAverageAggregates = {
  /** Mean average of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of locationId across the matching connection */
  locationId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeoEstimationAverageAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationAverageAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

/**
 * A condition to be used against `GeoEstimation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GeoEstimationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `duration` field. */
  duration: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
};

export type GeoEstimationDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  duration: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  locationId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type GeoEstimationDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of duration across the matching connection */
  duration: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of locationId across the matching connection */
  locationId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
};


export type GeoEstimationDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationDistinctCountAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationDistinctCountAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

/** A filter to be used against `GeoEstimation` object types. All fields are combined with a logical ‘and.’ */
export type GeoEstimationFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<GeoEstimationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `location` relation. */
  location: InputMaybe<GeolocationFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<GeoEstimationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<GeoEstimationFilter>>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
};

/** Grouping methods for `GeoEstimation` for usage during aggregation. */
export enum GeoEstimationGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Duration = 'DURATION',
  LocationId = 'LOCATION_ID',
  TaskId = 'TASK_ID'
}

export type GeoEstimationHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `GeoEstimation` aggregates. */
export type GeoEstimationHavingInput = {
  AND: InputMaybe<Array<GeoEstimationHavingInput>>;
  OR: InputMaybe<Array<GeoEstimationHavingInput>>;
  arrayAgg: InputMaybe<GeoEstimationHavingArrayAggInput>;
  average: InputMaybe<GeoEstimationHavingAverageInput>;
  distinctCount: InputMaybe<GeoEstimationHavingDistinctCountInput>;
  max: InputMaybe<GeoEstimationHavingMaxInput>;
  min: InputMaybe<GeoEstimationHavingMinInput>;
  stddevPopulation: InputMaybe<GeoEstimationHavingStddevPopulationInput>;
  stddevSample: InputMaybe<GeoEstimationHavingStddevSampleInput>;
  sum: InputMaybe<GeoEstimationHavingSumInput>;
  variancePopulation: InputMaybe<GeoEstimationHavingVariancePopulationInput>;
  varianceSample: InputMaybe<GeoEstimationHavingVarianceSampleInput>;
};

export type GeoEstimationHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  duration: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  locationId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type GeoEstimationMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  locationId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type GeoEstimationMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of locationId across the matching connection */
  locationId: Maybe<Scalars['Int']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
};


export type GeoEstimationMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMaxAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMaxAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  locationId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type GeoEstimationMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of locationId across the matching connection */
  locationId: Maybe<Scalars['Int']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
};


export type GeoEstimationMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMinAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMinAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationStddevPopulationAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  locationId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type GeoEstimationStddevPopulationAggregates = {
  /** Population standard deviation of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of locationId across the matching connection */
  locationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeoEstimationStddevPopulationAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevPopulationAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationStddevSampleAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  locationId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type GeoEstimationStddevSampleAggregates = {
  /** Sample standard deviation of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of locationId across the matching connection */
  locationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeoEstimationStddevSampleAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevSampleAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationSumAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigIntFilter>;
  locationId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type GeoEstimationSumAggregates = {
  /** Sum of duration across the matching connection */
  duration: Scalars['Float']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of locationId across the matching connection */
  locationId: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
};


export type GeoEstimationSumAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationSumAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationVariancePopulationAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  locationId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type GeoEstimationVariancePopulationAggregates = {
  /** Population variance of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of locationId across the matching connection */
  locationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeoEstimationVariancePopulationAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVariancePopulationAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeoEstimationVarianceSampleAggregateFilter = {
  duration: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  locationId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type GeoEstimationVarianceSampleAggregates = {
  /** Sample variance of duration across the matching connection */
  duration: Maybe<Scalars['Float']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of locationId across the matching connection */
  locationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeoEstimationVarianceSampleAggregatesDurationArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVarianceSampleAggregatesLocationIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeoEstimationVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

/** A connection to a list of `GeoEstimation` values. */
export type GeoEstimationsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<GeoEstimationAggregates>;
  /** A list of edges which contains the `GeoEstimation` and cursor to aid in pagination. */
  edges: Array<GeoEstimationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<GeoEstimationAggregates>>;
  /** A list of `GeoEstimation` objects. */
  nodes: Array<Maybe<GeoEstimation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeoEstimation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `GeoEstimation` values. */
export type GeoEstimationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GeoEstimationGroupBy>;
  having: InputMaybe<GeoEstimationHavingInput>;
};

/** A `GeoEstimation` edge in the connection. */
export type GeoEstimationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `GeoEstimation` at the end of the edge. */
  node: Maybe<GeoEstimation>;
};

/** Methods to use when ordering `GeoEstimation`. */
export enum GeoEstimationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC'
}

/** All geography XY types implement this interface */
export type GeographyGeometry = {
  /** Converts the object to GeoJSON */
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

export type GeographyGeometryCollection = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  geometries: Maybe<Array<Maybe<GeographyGeometry>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyGeometryCollectionM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  geometries: Maybe<Array<Maybe<GeographyGeometryM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyGeometryCollectionZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  geometries: Maybe<Array<Maybe<GeographyGeometryZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyGeometryCollectionZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  geometries: Maybe<Array<Maybe<GeographyGeometryZm>>>;
  srid: Scalars['Int']['output'];
};

/** All geography XYM types implement this interface */
export type GeographyGeometryM = {
  /** Converts the object to GeoJSON */
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

/** All geography XYZ types implement this interface */
export type GeographyGeometryZ = {
  /** Converts the object to GeoJSON */
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

/** All geography XYZM types implement this interface */
export type GeographyGeometryZm = {
  /** Converts the object to GeoJSON */
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

/** All geography types implement this interface */
export type GeographyInterface = {
  /** Converts the object to GeoJSON */
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

/** A filter to be used against GeographyInterface fields. All fields are combined with a logical ‘and.’ */
export type GeographyInterfaceFilter = {
  /** 2D bounding box intersects the specified geometry's 2D bounding box. */
  bboxIntersects2D: InputMaybe<Scalars['GeoJSON']['input']>;
  /** No point is outside the specified geometry. */
  coveredBy: InputMaybe<Scalars['GeoJSON']['input']>;
  /** No point in the specified geometry is outside. */
  covers: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Coordinates and coordinate order are the same as specified geometry. */
  exactlyEquals: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They share any portion of space in 2D. */
  intersects: InputMaybe<Scalars['GeoJSON']['input']>;
};

export type GeographyLineString = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPoint>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyLineStringM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyLineStringZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyLineStringZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointZm>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiLineString = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  lines: Maybe<Array<Maybe<GeographyLineString>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiLineStringM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  lines: Maybe<Array<Maybe<GeographyLineStringM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiLineStringZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  lines: Maybe<Array<Maybe<GeographyLineStringZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiLineStringZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  lines: Maybe<Array<Maybe<GeographyLineStringZm>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPoint = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPoint>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPointM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPointZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPointZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  points: Maybe<Array<Maybe<GeographyPointZm>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPolygon = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  polygons: Maybe<Array<Maybe<GeographyPolygon>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPolygonM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  polygons: Maybe<Array<Maybe<GeographyPolygonM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPolygonZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  polygons: Maybe<Array<Maybe<GeographyPolygonZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyMultiPolygonZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  polygons: Maybe<Array<Maybe<GeographyPolygonZm>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyPoint = GeographyGeometry & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  srid: Scalars['Int']['output'];
};

export type GeographyPointM = GeographyGeometryM & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  srid: Scalars['Int']['output'];
};

export type GeographyPointZ = GeographyGeometryZ & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  height: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  srid: Scalars['Int']['output'];
};

export type GeographyPointZm = GeographyGeometryZm & GeographyInterface & {
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  height: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  srid: Scalars['Int']['output'];
};

export type GeographyPolygon = GeographyGeometry & GeographyInterface & {
  exterior: Maybe<GeographyLineString>;
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  interiors: Maybe<Array<Maybe<GeographyLineString>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyPolygonM = GeographyGeometryM & GeographyInterface & {
  exterior: Maybe<GeographyLineStringM>;
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  interiors: Maybe<Array<Maybe<GeographyLineStringM>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyPolygonZ = GeographyGeometryZ & GeographyInterface & {
  exterior: Maybe<GeographyLineStringZ>;
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  interiors: Maybe<Array<Maybe<GeographyLineStringZ>>>;
  srid: Scalars['Int']['output'];
};

export type GeographyPolygonZm = GeographyGeometryZm & GeographyInterface & {
  exterior: Maybe<GeographyLineStringZm>;
  geojson: Maybe<Scalars['GeoJSON']['output']>;
  interiors: Maybe<Array<Maybe<GeographyLineStringZm>>>;
  srid: Scalars['Int']['output'];
};

export type Geolocation = Node & {
  accuracy: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  deviceId: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `GeoEstimation`. */
  geoEstimationsByLocationId: Array<GeoEstimation>;
  /** Reads and enables pagination through a set of `GeoEstimation`. */
  geoEstimationsByLocationIdConnection: GeoEstimationsConnection;
  heading: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  raw: Scalars['JSON']['output'];
  source: Scalars['String']['output'];
  speed: Maybe<Scalars['Float']['output']>;
  speedAccuracy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Task` that is related to this `Geolocation`. */
  task: Maybe<Task>;
  taskId: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['Datetime']['output'];
  /** Reads a single `MainUser` that is related to this `Geolocation`. */
  user: Maybe<MainUser>;
  userId: Scalars['Int']['output'];
  uuid: Scalars['UUID']['output'];
  version: Maybe<Scalars['String']['output']>;
};


export type GeolocationGeoEstimationsByLocationIdArgs = {
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type GeolocationGeoEstimationsByLocationIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoEstimationsOrderBy>>;
};

export type GeolocationAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<GeolocationArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<GeolocationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<GeolocationDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<GeolocationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<GeolocationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<GeolocationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<GeolocationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<GeolocationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<GeolocationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<GeolocationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Geolocation` object types. */
export type GeolocationAggregatesFilter = {
  /** Array aggregation aggregate over matching `Geolocation` objects. */
  arrayAgg: InputMaybe<GeolocationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Geolocation` objects. */
  average: InputMaybe<GeolocationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Geolocation` objects. */
  distinctCount: InputMaybe<GeolocationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Geolocation` object to be included within the aggregate. */
  filter: InputMaybe<GeolocationFilter>;
  /** Maximum aggregate over matching `Geolocation` objects. */
  max: InputMaybe<GeolocationMaxAggregateFilter>;
  /** Minimum aggregate over matching `Geolocation` objects. */
  min: InputMaybe<GeolocationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Geolocation` objects. */
  stddevPopulation: InputMaybe<GeolocationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Geolocation` objects. */
  stddevSample: InputMaybe<GeolocationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Geolocation` objects. */
  sum: InputMaybe<GeolocationSumAggregateFilter>;
  /** Population variance aggregate over matching `Geolocation` objects. */
  variancePopulation: InputMaybe<GeolocationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Geolocation` objects. */
  varianceSample: InputMaybe<GeolocationVarianceSampleAggregateFilter>;
};

export type GeolocationArrayAggAggregateFilter = {
  accuracy: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  deviceId: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  source: InputMaybe<StringListFilter>;
  speedAccuracy: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
  version: InputMaybe<StringListFilter>;
};

export type GeolocationArrayAggAggregates = {
  /** Array aggregation of accuracy across the matching connection */
  accuracy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of deviceId across the matching connection */
  deviceId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of heading across the matching connection */
  heading: Point;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of latitude across the matching connection */
  latitude: Point;
  /** Array aggregation of longitude across the matching connection */
  longitude: Point;
  /** Array aggregation of source across the matching connection */
  source: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of speed across the matching connection */
  speed: Point;
  /** Array aggregation of speedAccuracy across the matching connection */
  speedAccuracy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of userId across the matching connection */
  userId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of version across the matching connection */
  version: Array<Maybe<Scalars['String']['output']>>;
};


export type GeolocationArrayAggAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesDeviceIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesSourceArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationArrayAggAggregatesVersionArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationAverageAggregateFilter = {
  accuracy: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type GeolocationAverageAggregates = {
  /** Mean average of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Mean average of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Mean average of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Mean average of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeolocationAverageAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationAverageAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

/**
 * A condition to be used against `Geolocation` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GeolocationCondition = {
  /** Checks for equality with the object’s `accuracy` field. */
  accuracy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deviceId` field. */
  deviceId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `heading` field. */
  heading: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `latitude` field. */
  latitude: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `longitude` field. */
  longitude: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `raw` field. */
  raw: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `source` field. */
  source: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `speed` field. */
  speed: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `speedAccuracy` field. */
  speedAccuracy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `version` field. */
  version: InputMaybe<Scalars['String']['input']>;
};

export type GeolocationDistinctCountAggregateFilter = {
  accuracy: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  deviceId: InputMaybe<BigIntFilter>;
  heading: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  latitude: InputMaybe<BigIntFilter>;
  longitude: InputMaybe<BigIntFilter>;
  raw: InputMaybe<BigIntFilter>;
  source: InputMaybe<BigIntFilter>;
  speed: InputMaybe<BigIntFilter>;
  speedAccuracy: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  timestamp: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
  version: InputMaybe<BigIntFilter>;
};

export type GeolocationDistinctCountAggregates = {
  /** Distinct count of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of deviceId across the matching connection */
  deviceId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of heading across the matching connection */
  heading: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of latitude across the matching connection */
  latitude: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of longitude across the matching connection */
  longitude: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of raw across the matching connection */
  raw: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of source across the matching connection */
  source: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of speed across the matching connection */
  speed: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timestamp across the matching connection */
  timestamp: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userId across the matching connection */
  userId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of version across the matching connection */
  version: Maybe<Scalars['BigInt']['output']>;
};


export type GeolocationDistinctCountAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesDeviceIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesRawArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesSourceArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesTimestampArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationDistinctCountAggregatesVersionArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

/** A filter to be used against `Geolocation` object types. All fields are combined with a logical ‘and.’ */
export type GeolocationFilter = {
  /** Filter by the object’s `accuracy` field. */
  accuracy: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<GeolocationFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deviceId` field. */
  deviceId: InputMaybe<StringFilter>;
  /** Filter by the object’s `geoEstimationsByLocationId` relation. */
  geoEstimationsByLocationId: InputMaybe<GeolocationToManyGeoEstimationFilter>;
  /** Some related `geoEstimationsByLocationId` exist. */
  geoEstimationsByLocationIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `heading` field. */
  heading: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `latitude` field. */
  latitude: InputMaybe<FloatFilter>;
  /** Filter by the object’s `longitude` field. */
  longitude: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not: InputMaybe<GeolocationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<GeolocationFilter>>;
  /** Filter by the object’s `raw` field. */
  raw: InputMaybe<JsonFilter>;
  /** Filter by the object’s `source` field. */
  source: InputMaybe<StringFilter>;
  /** Filter by the object’s `speed` field. */
  speed: InputMaybe<FloatFilter>;
  /** Filter by the object’s `speedAccuracy` field. */
  speedAccuracy: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `timestamp` field. */
  timestamp: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
  /** Filter by the object’s `version` field. */
  version: InputMaybe<StringFilter>;
};

/** Grouping methods for `Geolocation` for usage during aggregation. */
export enum GeolocationGroupBy {
  Accuracy = 'ACCURACY',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  DeviceId = 'DEVICE_ID',
  Heading = 'HEADING',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  Raw = 'RAW',
  Source = 'SOURCE',
  Speed = 'SPEED',
  SpeedAccuracy = 'SPEED_ACCURACY',
  TaskId = 'TASK_ID',
  Timestamp = 'TIMESTAMP',
  TimestampTruncatedToDay = 'TIMESTAMP_TRUNCATED_TO_DAY',
  TimestampTruncatedToHour = 'TIMESTAMP_TRUNCATED_TO_HOUR',
  TimestampTruncatedToMonth = 'TIMESTAMP_TRUNCATED_TO_MONTH',
  TimestampTruncatedToQuarter = 'TIMESTAMP_TRUNCATED_TO_QUARTER',
  TimestampTruncatedToWeek = 'TIMESTAMP_TRUNCATED_TO_WEEK',
  TimestampTruncatedToYear = 'TIMESTAMP_TRUNCATED_TO_YEAR',
  UserId = 'USER_ID',
  Version = 'VERSION'
}

export type GeolocationHavingArrayAggInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingAverageInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingDistinctCountInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Geolocation` aggregates. */
export type GeolocationHavingInput = {
  AND: InputMaybe<Array<GeolocationHavingInput>>;
  OR: InputMaybe<Array<GeolocationHavingInput>>;
  arrayAgg: InputMaybe<GeolocationHavingArrayAggInput>;
  average: InputMaybe<GeolocationHavingAverageInput>;
  distinctCount: InputMaybe<GeolocationHavingDistinctCountInput>;
  max: InputMaybe<GeolocationHavingMaxInput>;
  min: InputMaybe<GeolocationHavingMinInput>;
  stddevPopulation: InputMaybe<GeolocationHavingStddevPopulationInput>;
  stddevSample: InputMaybe<GeolocationHavingStddevSampleInput>;
  sum: InputMaybe<GeolocationHavingSumInput>;
  variancePopulation: InputMaybe<GeolocationHavingVariancePopulationInput>;
  varianceSample: InputMaybe<GeolocationHavingVarianceSampleInput>;
};

export type GeolocationHavingMaxInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingMinInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingStddevPopulationInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingStddevSampleInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingSumInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingVariancePopulationInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationHavingVarianceSampleInput = {
  accuracy: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  heading: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  latitude: InputMaybe<HavingFloatFilter>;
  longitude: InputMaybe<HavingFloatFilter>;
  speed: InputMaybe<HavingFloatFilter>;
  speedAccuracy: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  timestamp: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
};

export type GeolocationMaxAggregateFilter = {
  accuracy: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  timestamp: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type GeolocationMaxAggregates = {
  /** Maximum of accuracy across the matching connection */
  accuracy: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Maximum of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Maximum of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Maximum of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['Int']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of timestamp across the matching connection */
  timestamp: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type GeolocationMaxAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesTimestampArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMaxAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationMinAggregateFilter = {
  accuracy: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  timestamp: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
};

export type GeolocationMinAggregates = {
  /** Minimum of accuracy across the matching connection */
  accuracy: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Minimum of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Minimum of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Minimum of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['Int']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of timestamp across the matching connection */
  timestamp: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
};


export type GeolocationMinAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesTimestampArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationMinAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationStddevPopulationAggregateFilter = {
  accuracy: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type GeolocationStddevPopulationAggregates = {
  /** Population standard deviation of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeolocationStddevPopulationAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevPopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationStddevSampleAggregateFilter = {
  accuracy: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type GeolocationStddevSampleAggregates = {
  /** Sample standard deviation of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeolocationStddevSampleAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationStddevSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationSumAggregateFilter = {
  accuracy: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigIntFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type GeolocationSumAggregates = {
  /** Sum of accuracy across the matching connection */
  accuracy: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of heading across the matching connection */
  heading: Scalars['Float']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of latitude across the matching connection */
  latitude: Scalars['Float']['output'];
  /** Sum of longitude across the matching connection */
  longitude: Scalars['Float']['output'];
  /** Sum of speed across the matching connection */
  speed: Scalars['Float']['output'];
  /** Sum of speedAccuracy across the matching connection */
  speedAccuracy: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt']['output'];
};


export type GeolocationSumAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationSumAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

/** A filter to be used against many `GeoEstimation` object types. All fields are combined with a logical ‘and.’ */
export type GeolocationToManyGeoEstimationFilter = {
  /** Aggregates across related `GeoEstimation` match the filter criteria. */
  aggregates: InputMaybe<GeoEstimationAggregatesFilter>;
  /** Every related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeoEstimationFilter>;
  /** No related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeoEstimationFilter>;
  /** Some related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeoEstimationFilter>;
};

export type GeolocationUserSetting = Node & {
  /** Reads a single `Company` that is related to this `GeolocationUserSetting`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `GeolocationUserSetting`. */
  role: Maybe<Role>;
  roleId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Team` that is related to this `GeolocationUserSetting`. */
  team: Maybe<Team>;
  teamId: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against aggregates of `GeolocationUserSetting` object types. */
export type GeolocationUserSettingAggregatesFilter = {
  /** Array aggregation aggregate over matching `GeolocationUserSetting` objects. */
  arrayAgg: InputMaybe<GeolocationUserSettingArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `GeolocationUserSetting` objects. */
  average: InputMaybe<GeolocationUserSettingAverageAggregateFilter>;
  /** Distinct count aggregate over matching `GeolocationUserSetting` objects. */
  distinctCount: InputMaybe<GeolocationUserSettingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `GeolocationUserSetting` object to be included within the aggregate. */
  filter: InputMaybe<GeolocationUserSettingFilter>;
  /** Maximum aggregate over matching `GeolocationUserSetting` objects. */
  max: InputMaybe<GeolocationUserSettingMaxAggregateFilter>;
  /** Minimum aggregate over matching `GeolocationUserSetting` objects. */
  min: InputMaybe<GeolocationUserSettingMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `GeolocationUserSetting` objects. */
  stddevPopulation: InputMaybe<GeolocationUserSettingStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `GeolocationUserSetting` objects. */
  stddevSample: InputMaybe<GeolocationUserSettingStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `GeolocationUserSetting` objects. */
  sum: InputMaybe<GeolocationUserSettingSumAggregateFilter>;
  /** Population variance aggregate over matching `GeolocationUserSetting` objects. */
  variancePopulation: InputMaybe<GeolocationUserSettingVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `GeolocationUserSetting` objects. */
  varianceSample: InputMaybe<GeolocationUserSettingVarianceSampleAggregateFilter>;
};

export type GeolocationUserSettingArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  enabled: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
};

export type GeolocationUserSettingAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `GeolocationUserSetting` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type GeolocationUserSettingCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
};

export type GeolocationUserSettingDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  enabled: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `GeolocationUserSetting` object types. All fields are combined with a logical ‘and.’ */
export type GeolocationUserSettingFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<GeolocationUserSettingFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<GeolocationUserSettingFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<GeolocationUserSettingFilter>>;
  /** Filter by the object’s `role` relation. */
  role: InputMaybe<RoleFilter>;
  /** A related `role` exists. */
  roleExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** A related `team` exists. */
  teamExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
};

export type GeolocationUserSettingMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type GeolocationUserSettingMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type GeolocationUserSettingStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type GeolocationUserSettingStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type GeolocationUserSettingSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

export type GeolocationUserSettingVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type GeolocationUserSettingVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `GeolocationUserSetting`. */
export enum GeolocationUserSettingsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type GeolocationVariancePopulationAggregateFilter = {
  accuracy: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type GeolocationVariancePopulationAggregates = {
  /** Population variance of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Population variance of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Population variance of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Population variance of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeolocationVariancePopulationAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVariancePopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

export type GeolocationVarianceSampleAggregateFilter = {
  accuracy: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  heading: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  latitude: InputMaybe<FloatFilter>;
  longitude: InputMaybe<FloatFilter>;
  speed: InputMaybe<FloatFilter>;
  speedAccuracy: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type GeolocationVarianceSampleAggregates = {
  /** Sample variance of accuracy across the matching connection */
  accuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of heading across the matching connection */
  heading: Maybe<Scalars['Float']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of latitude across the matching connection */
  latitude: Maybe<Scalars['Float']['output']>;
  /** Sample variance of longitude across the matching connection */
  longitude: Maybe<Scalars['Float']['output']>;
  /** Sample variance of speed across the matching connection */
  speed: Maybe<Scalars['Float']['output']>;
  /** Sample variance of speedAccuracy across the matching connection */
  speedAccuracy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
};


export type GeolocationVarianceSampleAggregatesAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesHeadingArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesLatitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesLongitudeArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesSpeedArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesSpeedAccuracyArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type GeolocationVarianceSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};

/** A connection to a list of `Geolocation` values. */
export type GeolocationsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<GeolocationAggregates>;
  /** A list of edges which contains the `Geolocation` and cursor to aid in pagination. */
  edges: Array<GeolocationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<GeolocationAggregates>>;
  /** A list of `Geolocation` objects. */
  nodes: Array<Maybe<Geolocation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Geolocation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Geolocation` values. */
export type GeolocationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GeolocationGroupBy>;
  having: InputMaybe<GeolocationHavingInput>;
};

/** A `Geolocation` edge in the connection. */
export type GeolocationsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Geolocation` at the end of the edge. */
  node: Maybe<Geolocation>;
};

/** Methods to use when ordering `Geolocation`. */
export enum GeolocationsOrderBy {
  AccuracyAsc = 'ACCURACY_ASC',
  AccuracyDesc = 'ACCURACY_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeviceIdAsc = 'DEVICE_ID_ASC',
  DeviceIdDesc = 'DEVICE_ID_DESC',
  GeoEstimationsByLocationIdConnectionArrayAggCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionArrayAggCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionArrayAggDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionArrayAggDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionArrayAggIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  GeoEstimationsByLocationIdConnectionArrayAggIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  GeoEstimationsByLocationIdConnectionArrayAggLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionArrayAggLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionArrayAggTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionArrayAggTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionAverageCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionAverageCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionAverageDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionAverageDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionAverageIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_ID_ASC',
  GeoEstimationsByLocationIdConnectionAverageIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_ID_DESC',
  GeoEstimationsByLocationIdConnectionAverageLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionAverageLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionAverageTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionAverageTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionCountAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_COUNT_ASC',
  GeoEstimationsByLocationIdConnectionCountDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_COUNT_DESC',
  GeoEstimationsByLocationIdConnectionDistinctCountCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionDistinctCountCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionDistinctCountDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionDistinctCountDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionDistinctCountIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeoEstimationsByLocationIdConnectionDistinctCountIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeoEstimationsByLocationIdConnectionDistinctCountLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionDistinctCountLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionDistinctCountTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionDistinctCountTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionMaxCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionMaxCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionMaxDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionMaxDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionMaxIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_ID_ASC',
  GeoEstimationsByLocationIdConnectionMaxIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_ID_DESC',
  GeoEstimationsByLocationIdConnectionMaxLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionMaxLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionMaxTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionMaxTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MAX_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionMinCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionMinCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionMinDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionMinDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionMinIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_ID_ASC',
  GeoEstimationsByLocationIdConnectionMinIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_ID_DESC',
  GeoEstimationsByLocationIdConnectionMinLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionMinLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionMinTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionMinTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_MIN_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevPopulationCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionStddevPopulationCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionStddevPopulationDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionStddevPopulationDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionStddevPopulationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevPopulationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevPopulationLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevPopulationLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevPopulationTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevPopulationTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevSampleCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionStddevSampleCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionStddevSampleDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionStddevSampleDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionStddevSampleIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevSampleIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevSampleLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevSampleLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionStddevSampleTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionStddevSampleTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionSumCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionSumCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionSumDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionSumDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionSumIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_ID_ASC',
  GeoEstimationsByLocationIdConnectionSumIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_ID_DESC',
  GeoEstimationsByLocationIdConnectionSumLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionSumLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionSumTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionSumTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_SUM_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionVariancePopulationCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionVariancePopulationCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionVariancePopulationDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionVariancePopulationDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionVariancePopulationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionVariancePopulationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionVariancePopulationLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionVariancePopulationLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionVariancePopulationTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionVariancePopulationTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  GeoEstimationsByLocationIdConnectionVarianceSampleCreatedAtAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeoEstimationsByLocationIdConnectionVarianceSampleCreatedAtDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeoEstimationsByLocationIdConnectionVarianceSampleDurationAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_ASC',
  GeoEstimationsByLocationIdConnectionVarianceSampleDurationDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_DURATION_DESC',
  GeoEstimationsByLocationIdConnectionVarianceSampleIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeoEstimationsByLocationIdConnectionVarianceSampleIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeoEstimationsByLocationIdConnectionVarianceSampleLocationIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_LOCATION_ID_ASC',
  GeoEstimationsByLocationIdConnectionVarianceSampleLocationIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_LOCATION_ID_DESC',
  GeoEstimationsByLocationIdConnectionVarianceSampleTaskIdAsc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  GeoEstimationsByLocationIdConnectionVarianceSampleTaskIdDesc = 'GEO_ESTIMATIONS_BY_LOCATION_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  HeadingAsc = 'HEADING_ASC',
  HeadingDesc = 'HEADING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LatitudeAsc = 'LATITUDE_ASC',
  LatitudeDesc = 'LATITUDE_DESC',
  LongitudeAsc = 'LONGITUDE_ASC',
  LongitudeDesc = 'LONGITUDE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RawAsc = 'RAW_ASC',
  RawDesc = 'RAW_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  SpeedAccuracyAsc = 'SPEED_ACCURACY_ASC',
  SpeedAccuracyDesc = 'SPEED_ACCURACY_DESC',
  SpeedAsc = 'SPEED_ASC',
  SpeedDesc = 'SPEED_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

export type HavingBigfloatFilter = {
  equalTo: InputMaybe<Scalars['BigFloat']['input']>;
  greaterThan: InputMaybe<Scalars['BigFloat']['input']>;
  greaterThanOrEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
  lessThan: InputMaybe<Scalars['BigFloat']['input']>;
  lessThanOrEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
  notEqualTo: InputMaybe<Scalars['BigFloat']['input']>;
};

export type HavingBigintFilter = {
  equalTo: InputMaybe<Scalars['BigInt']['input']>;
  greaterThan: InputMaybe<Scalars['BigInt']['input']>;
  greaterThanOrEqualTo: InputMaybe<Scalars['BigInt']['input']>;
  lessThan: InputMaybe<Scalars['BigInt']['input']>;
  lessThanOrEqualTo: InputMaybe<Scalars['BigInt']['input']>;
  notEqualTo: InputMaybe<Scalars['BigInt']['input']>;
};

export type HavingDatetimeFilter = {
  equalTo: InputMaybe<Scalars['Datetime']['input']>;
  greaterThan: InputMaybe<Scalars['Datetime']['input']>;
  greaterThanOrEqualTo: InputMaybe<Scalars['Datetime']['input']>;
  lessThan: InputMaybe<Scalars['Datetime']['input']>;
  lessThanOrEqualTo: InputMaybe<Scalars['Datetime']['input']>;
  notEqualTo: InputMaybe<Scalars['Datetime']['input']>;
};

export type HavingFloatFilter = {
  equalTo: InputMaybe<Scalars['Float']['input']>;
  greaterThan: InputMaybe<Scalars['Float']['input']>;
  greaterThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  lessThan: InputMaybe<Scalars['Float']['input']>;
  lessThanOrEqualTo: InputMaybe<Scalars['Float']['input']>;
  notEqualTo: InputMaybe<Scalars['Float']['input']>;
};

export type HavingIntFilter = {
  equalTo: InputMaybe<Scalars['Int']['input']>;
  greaterThan: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  lessThan: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  notEqualTo: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `ImportHistory` values. */
export type ImportHistoriesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ImportHistoryAggregates>;
  /** A list of edges which contains the `ImportHistory` and cursor to aid in pagination. */
  edges: Array<ImportHistoriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ImportHistoryAggregates>>;
  /** A list of `ImportHistory` objects. */
  nodes: Array<Maybe<ImportHistory>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ImportHistory` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ImportHistory` values. */
export type ImportHistoriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ImportHistoryGroupBy>;
  having: InputMaybe<ImportHistoryHavingInput>;
};

/** A `ImportHistory` edge in the connection. */
export type ImportHistoriesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ImportHistory` at the end of the edge. */
  node: Maybe<ImportHistory>;
};

/** Methods to use when ordering `ImportHistory`. */
export enum ImportHistoriesOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedColumnsAsc = 'CREATED_COLUMNS_ASC',
  CreatedColumnsDesc = 'CREATED_COLUMNS_DESC',
  EndedAtAsc = 'ENDED_AT_ASC',
  EndedAtDesc = 'ENDED_AT_DESC',
  ErrorMessageAsc = 'ERROR_MESSAGE_ASC',
  ErrorMessageDesc = 'ERROR_MESSAGE_DESC',
  ErrorStackAsc = 'ERROR_STACK_ASC',
  ErrorStackDesc = 'ERROR_STACK_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  GroupedProjectsAsc = 'GROUPED_PROJECTS_ASC',
  GroupedProjectsDesc = 'GROUPED_PROJECTS_DESC',
  GroupByColumnIdAsc = 'GROUP_BY_COLUMN_ID_ASC',
  GroupByColumnIdDesc = 'GROUP_BY_COLUMN_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportDataAsc = 'IMPORT_DATA_ASC',
  ImportDataDesc = 'IMPORT_DATA_DESC',
  ImportRowsConnectionArrayAggAccountIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ACCOUNT_ID_ASC',
  ImportRowsConnectionArrayAggAccountIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ACCOUNT_ID_DESC',
  ImportRowsConnectionArrayAggContactIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ImportRowsConnectionArrayAggContactIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ImportRowsConnectionArrayAggCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportRowsConnectionArrayAggCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportRowsConnectionArrayAggEndedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportRowsConnectionArrayAggEndedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportRowsConnectionArrayAggErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportRowsConnectionArrayAggErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportRowsConnectionArrayAggErrorStackAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportRowsConnectionArrayAggErrorStackDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportRowsConnectionArrayAggGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionArrayAggGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionArrayAggIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportRowsConnectionArrayAggIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportRowsConnectionArrayAggImportDataAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportRowsConnectionArrayAggImportDataDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportRowsConnectionArrayAggImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionArrayAggImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionArrayAggImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionArrayAggImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionArrayAggIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionArrayAggIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionArrayAggIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionArrayAggIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionArrayAggMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionArrayAggMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionArrayAggProjectIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ImportRowsConnectionArrayAggProjectIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ImportRowsConnectionArrayAggReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionArrayAggReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionArrayAggResultDataAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_RESULT_DATA_ASC',
  ImportRowsConnectionArrayAggResultDataDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_RESULT_DATA_DESC',
  ImportRowsConnectionArrayAggStartedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ImportRowsConnectionArrayAggStartedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ImportRowsConnectionArrayAggStatusAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportRowsConnectionArrayAggStatusDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportRowsConnectionArrayAggUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportRowsConnectionArrayAggUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportRowsConnectionArrayAggUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionArrayAggUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionAverageAccountIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ACCOUNT_ID_ASC',
  ImportRowsConnectionAverageAccountIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ACCOUNT_ID_DESC',
  ImportRowsConnectionAverageContactIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ImportRowsConnectionAverageContactIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ImportRowsConnectionAverageCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportRowsConnectionAverageCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportRowsConnectionAverageEndedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportRowsConnectionAverageEndedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportRowsConnectionAverageErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionAverageErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionAverageErrorStackAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportRowsConnectionAverageErrorStackDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportRowsConnectionAverageGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionAverageGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionAverageIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ID_ASC',
  ImportRowsConnectionAverageIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_ID_DESC',
  ImportRowsConnectionAverageImportDataAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportRowsConnectionAverageImportDataDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportRowsConnectionAverageImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionAverageImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionAverageImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionAverageImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionAverageIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionAverageIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionAverageIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionAverageIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionAverageIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionAverageIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionAverageMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionAverageMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionAverageProjectIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ImportRowsConnectionAverageProjectIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ImportRowsConnectionAverageReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionAverageReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionAverageResultDataAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_RESULT_DATA_ASC',
  ImportRowsConnectionAverageResultDataDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_RESULT_DATA_DESC',
  ImportRowsConnectionAverageStartedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ImportRowsConnectionAverageStartedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ImportRowsConnectionAverageStatusAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STATUS_ASC',
  ImportRowsConnectionAverageStatusDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_STATUS_DESC',
  ImportRowsConnectionAverageUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportRowsConnectionAverageUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportRowsConnectionAverageUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionAverageUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_AVERAGE_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionCountAsc = 'IMPORT_ROWS_CONNECTION_COUNT_ASC',
  ImportRowsConnectionCountDesc = 'IMPORT_ROWS_CONNECTION_COUNT_DESC',
  ImportRowsConnectionDistinctCountAccountIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_ASC',
  ImportRowsConnectionDistinctCountAccountIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_DESC',
  ImportRowsConnectionDistinctCountContactIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ImportRowsConnectionDistinctCountContactIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ImportRowsConnectionDistinctCountCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportRowsConnectionDistinctCountCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportRowsConnectionDistinctCountEndedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportRowsConnectionDistinctCountEndedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportRowsConnectionDistinctCountErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportRowsConnectionDistinctCountErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportRowsConnectionDistinctCountErrorStackAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportRowsConnectionDistinctCountErrorStackDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportRowsConnectionDistinctCountGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionDistinctCountGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionDistinctCountIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportRowsConnectionDistinctCountIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportRowsConnectionDistinctCountImportDataAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportRowsConnectionDistinctCountImportDataDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportRowsConnectionDistinctCountImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionDistinctCountImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionDistinctCountImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionDistinctCountImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionDistinctCountIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionDistinctCountIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionDistinctCountIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionDistinctCountIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionDistinctCountMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionDistinctCountMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionDistinctCountProjectIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ImportRowsConnectionDistinctCountProjectIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ImportRowsConnectionDistinctCountReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionDistinctCountReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionDistinctCountResultDataAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_RESULT_DATA_ASC',
  ImportRowsConnectionDistinctCountResultDataDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_RESULT_DATA_DESC',
  ImportRowsConnectionDistinctCountStartedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ImportRowsConnectionDistinctCountStartedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ImportRowsConnectionDistinctCountStatusAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportRowsConnectionDistinctCountStatusDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportRowsConnectionDistinctCountUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportRowsConnectionDistinctCountUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportRowsConnectionDistinctCountUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionDistinctCountUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionMaxAccountIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_ACCOUNT_ID_ASC',
  ImportRowsConnectionMaxAccountIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_ACCOUNT_ID_DESC',
  ImportRowsConnectionMaxContactIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_CONTACT_ID_ASC',
  ImportRowsConnectionMaxContactIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_CONTACT_ID_DESC',
  ImportRowsConnectionMaxCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_CREATED_AT_ASC',
  ImportRowsConnectionMaxCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_CREATED_AT_DESC',
  ImportRowsConnectionMaxEndedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_ENDED_AT_ASC',
  ImportRowsConnectionMaxEndedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_ENDED_AT_DESC',
  ImportRowsConnectionMaxErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportRowsConnectionMaxErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportRowsConnectionMaxErrorStackAsc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportRowsConnectionMaxErrorStackDesc = 'IMPORT_ROWS_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportRowsConnectionMaxGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_MAX_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionMaxGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_MAX_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionMaxIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_ID_ASC',
  ImportRowsConnectionMaxIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_ID_DESC',
  ImportRowsConnectionMaxImportDataAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportRowsConnectionMaxImportDataDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportRowsConnectionMaxImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionMaxImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionMaxImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionMaxImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_MAX_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionMaxIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionMaxIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionMaxIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionMaxIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionMaxIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_MAX_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionMaxIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_MAX_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionMaxMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_MAX_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionMaxMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_MAX_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionMaxProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_PROJECT_ID_ASC',
  ImportRowsConnectionMaxProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_PROJECT_ID_DESC',
  ImportRowsConnectionMaxReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MAX_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionMaxReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MAX_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionMaxResultDataAsc = 'IMPORT_ROWS_CONNECTION_MAX_RESULT_DATA_ASC',
  ImportRowsConnectionMaxResultDataDesc = 'IMPORT_ROWS_CONNECTION_MAX_RESULT_DATA_DESC',
  ImportRowsConnectionMaxStartedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_STARTED_AT_ASC',
  ImportRowsConnectionMaxStartedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_STARTED_AT_DESC',
  ImportRowsConnectionMaxStatusAsc = 'IMPORT_ROWS_CONNECTION_MAX_STATUS_ASC',
  ImportRowsConnectionMaxStatusDesc = 'IMPORT_ROWS_CONNECTION_MAX_STATUS_DESC',
  ImportRowsConnectionMaxUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportRowsConnectionMaxUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportRowsConnectionMaxUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionMaxUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_MAX_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionMinAccountIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_ACCOUNT_ID_ASC',
  ImportRowsConnectionMinAccountIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_ACCOUNT_ID_DESC',
  ImportRowsConnectionMinContactIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_CONTACT_ID_ASC',
  ImportRowsConnectionMinContactIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_CONTACT_ID_DESC',
  ImportRowsConnectionMinCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_CREATED_AT_ASC',
  ImportRowsConnectionMinCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_CREATED_AT_DESC',
  ImportRowsConnectionMinEndedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_ENDED_AT_ASC',
  ImportRowsConnectionMinEndedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_ENDED_AT_DESC',
  ImportRowsConnectionMinErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportRowsConnectionMinErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportRowsConnectionMinErrorStackAsc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportRowsConnectionMinErrorStackDesc = 'IMPORT_ROWS_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportRowsConnectionMinGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_MIN_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionMinGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_MIN_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionMinIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_ID_ASC',
  ImportRowsConnectionMinIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_ID_DESC',
  ImportRowsConnectionMinImportDataAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportRowsConnectionMinImportDataDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportRowsConnectionMinImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionMinImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionMinImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionMinImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_MIN_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionMinIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionMinIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionMinIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionMinIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionMinIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_MIN_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionMinIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_MIN_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionMinMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_MIN_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionMinMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_MIN_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionMinProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_PROJECT_ID_ASC',
  ImportRowsConnectionMinProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_PROJECT_ID_DESC',
  ImportRowsConnectionMinReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_MIN_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionMinReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_MIN_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionMinResultDataAsc = 'IMPORT_ROWS_CONNECTION_MIN_RESULT_DATA_ASC',
  ImportRowsConnectionMinResultDataDesc = 'IMPORT_ROWS_CONNECTION_MIN_RESULT_DATA_DESC',
  ImportRowsConnectionMinStartedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_STARTED_AT_ASC',
  ImportRowsConnectionMinStartedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_STARTED_AT_DESC',
  ImportRowsConnectionMinStatusAsc = 'IMPORT_ROWS_CONNECTION_MIN_STATUS_ASC',
  ImportRowsConnectionMinStatusDesc = 'IMPORT_ROWS_CONNECTION_MIN_STATUS_DESC',
  ImportRowsConnectionMinUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportRowsConnectionMinUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportRowsConnectionMinUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionMinUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_MIN_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionStddevPopulationAccountIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsConnectionStddevPopulationAccountIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsConnectionStddevPopulationContactIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ImportRowsConnectionStddevPopulationContactIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ImportRowsConnectionStddevPopulationCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportRowsConnectionStddevPopulationCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportRowsConnectionStddevPopulationEndedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportRowsConnectionStddevPopulationEndedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportRowsConnectionStddevPopulationErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsConnectionStddevPopulationErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsConnectionStddevPopulationErrorStackAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportRowsConnectionStddevPopulationErrorStackDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportRowsConnectionStddevPopulationGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionStddevPopulationGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionStddevPopulationIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportRowsConnectionStddevPopulationIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportRowsConnectionStddevPopulationImportDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportRowsConnectionStddevPopulationImportDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportRowsConnectionStddevPopulationImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionStddevPopulationImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionStddevPopulationImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionStddevPopulationImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionStddevPopulationIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionStddevPopulationIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionStddevPopulationMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionStddevPopulationMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionStddevPopulationProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ImportRowsConnectionStddevPopulationProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ImportRowsConnectionStddevPopulationReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionStddevPopulationReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionStddevPopulationResultDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_RESULT_DATA_ASC',
  ImportRowsConnectionStddevPopulationResultDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_RESULT_DATA_DESC',
  ImportRowsConnectionStddevPopulationStartedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ImportRowsConnectionStddevPopulationStartedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ImportRowsConnectionStddevPopulationStatusAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportRowsConnectionStddevPopulationStatusDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportRowsConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportRowsConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportRowsConnectionStddevPopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionStddevPopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionStddevSampleAccountIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsConnectionStddevSampleAccountIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsConnectionStddevSampleContactIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ImportRowsConnectionStddevSampleContactIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ImportRowsConnectionStddevSampleCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportRowsConnectionStddevSampleCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportRowsConnectionStddevSampleEndedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportRowsConnectionStddevSampleEndedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportRowsConnectionStddevSampleErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionStddevSampleErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionStddevSampleErrorStackAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportRowsConnectionStddevSampleErrorStackDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportRowsConnectionStddevSampleGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionStddevSampleGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionStddevSampleIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportRowsConnectionStddevSampleIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportRowsConnectionStddevSampleImportDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsConnectionStddevSampleImportDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsConnectionStddevSampleImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionStddevSampleImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionStddevSampleImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionStddevSampleImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionStddevSampleIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionStddevSampleIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionStddevSampleIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionStddevSampleIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionStddevSampleMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionStddevSampleMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionStddevSampleProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ImportRowsConnectionStddevSampleProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ImportRowsConnectionStddevSampleReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionStddevSampleReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionStddevSampleResultDataAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_ASC',
  ImportRowsConnectionStddevSampleResultDataDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_DESC',
  ImportRowsConnectionStddevSampleStartedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ImportRowsConnectionStddevSampleStartedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ImportRowsConnectionStddevSampleStatusAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportRowsConnectionStddevSampleStatusDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportRowsConnectionStddevSampleUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportRowsConnectionStddevSampleUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportRowsConnectionStddevSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionStddevSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionSumAccountIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_ACCOUNT_ID_ASC',
  ImportRowsConnectionSumAccountIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_ACCOUNT_ID_DESC',
  ImportRowsConnectionSumContactIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_CONTACT_ID_ASC',
  ImportRowsConnectionSumContactIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_CONTACT_ID_DESC',
  ImportRowsConnectionSumCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_CREATED_AT_ASC',
  ImportRowsConnectionSumCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_CREATED_AT_DESC',
  ImportRowsConnectionSumEndedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_ENDED_AT_ASC',
  ImportRowsConnectionSumEndedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_ENDED_AT_DESC',
  ImportRowsConnectionSumErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportRowsConnectionSumErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportRowsConnectionSumErrorStackAsc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportRowsConnectionSumErrorStackDesc = 'IMPORT_ROWS_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportRowsConnectionSumGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_SUM_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionSumGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_SUM_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionSumIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_ID_ASC',
  ImportRowsConnectionSumIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_ID_DESC',
  ImportRowsConnectionSumImportDataAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportRowsConnectionSumImportDataDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportRowsConnectionSumImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionSumImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionSumImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionSumImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_SUM_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionSumIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionSumIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionSumIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionSumIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionSumIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_SUM_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionSumIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_SUM_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionSumMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_SUM_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionSumMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_SUM_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionSumProjectIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_PROJECT_ID_ASC',
  ImportRowsConnectionSumProjectIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_PROJECT_ID_DESC',
  ImportRowsConnectionSumReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_SUM_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionSumReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_SUM_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionSumResultDataAsc = 'IMPORT_ROWS_CONNECTION_SUM_RESULT_DATA_ASC',
  ImportRowsConnectionSumResultDataDesc = 'IMPORT_ROWS_CONNECTION_SUM_RESULT_DATA_DESC',
  ImportRowsConnectionSumStartedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_STARTED_AT_ASC',
  ImportRowsConnectionSumStartedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_STARTED_AT_DESC',
  ImportRowsConnectionSumStatusAsc = 'IMPORT_ROWS_CONNECTION_SUM_STATUS_ASC',
  ImportRowsConnectionSumStatusDesc = 'IMPORT_ROWS_CONNECTION_SUM_STATUS_DESC',
  ImportRowsConnectionSumUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportRowsConnectionSumUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportRowsConnectionSumUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionSumUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_SUM_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionVariancePopulationAccountIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsConnectionVariancePopulationAccountIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsConnectionVariancePopulationContactIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ImportRowsConnectionVariancePopulationContactIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ImportRowsConnectionVariancePopulationCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportRowsConnectionVariancePopulationCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportRowsConnectionVariancePopulationEndedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportRowsConnectionVariancePopulationEndedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportRowsConnectionVariancePopulationErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsConnectionVariancePopulationErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsConnectionVariancePopulationErrorStackAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportRowsConnectionVariancePopulationErrorStackDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportRowsConnectionVariancePopulationGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionVariancePopulationGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionVariancePopulationIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportRowsConnectionVariancePopulationIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportRowsConnectionVariancePopulationImportDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportRowsConnectionVariancePopulationImportDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportRowsConnectionVariancePopulationImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionVariancePopulationImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionVariancePopulationImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionVariancePopulationImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionVariancePopulationIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionVariancePopulationIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionVariancePopulationMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionVariancePopulationMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionVariancePopulationProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ImportRowsConnectionVariancePopulationProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ImportRowsConnectionVariancePopulationReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionVariancePopulationReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionVariancePopulationResultDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_ASC',
  ImportRowsConnectionVariancePopulationResultDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_DESC',
  ImportRowsConnectionVariancePopulationStartedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ImportRowsConnectionVariancePopulationStartedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ImportRowsConnectionVariancePopulationStatusAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportRowsConnectionVariancePopulationStatusDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportRowsConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportRowsConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportRowsConnectionVariancePopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionVariancePopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsConnectionVarianceSampleAccountIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsConnectionVarianceSampleAccountIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsConnectionVarianceSampleContactIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ImportRowsConnectionVarianceSampleContactIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ImportRowsConnectionVarianceSampleCreatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportRowsConnectionVarianceSampleCreatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportRowsConnectionVarianceSampleEndedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportRowsConnectionVarianceSampleEndedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportRowsConnectionVarianceSampleErrorMessageAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsConnectionVarianceSampleErrorMessageDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsConnectionVarianceSampleErrorStackAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportRowsConnectionVarianceSampleErrorStackDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportRowsConnectionVarianceSampleGroupedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsConnectionVarianceSampleGroupedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsConnectionVarianceSampleIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportRowsConnectionVarianceSampleIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportRowsConnectionVarianceSampleImportDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsConnectionVarianceSampleImportDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsConnectionVarianceSampleImportHistoryIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsConnectionVarianceSampleImportHistoryIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsConnectionVarianceSampleImportRowNumberAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsConnectionVarianceSampleImportRowNumberDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsConnectionVarianceSampleIsAccountCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsAccountCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleIsContactCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsContactCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleIsProjectCreatedAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsConnectionVarianceSampleIsProjectCreatedDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsConnectionVarianceSampleMatchedByValueAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsConnectionVarianceSampleMatchedByValueDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsConnectionVarianceSampleProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ImportRowsConnectionVarianceSampleProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ImportRowsConnectionVarianceSampleReplacedProjectIdAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsConnectionVarianceSampleReplacedProjectIdDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsConnectionVarianceSampleResultDataAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_ASC',
  ImportRowsConnectionVarianceSampleResultDataDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_DESC',
  ImportRowsConnectionVarianceSampleStartedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ImportRowsConnectionVarianceSampleStartedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ImportRowsConnectionVarianceSampleStatusAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportRowsConnectionVarianceSampleStatusDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportRowsConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportRowsConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ImportRowsConnectionVarianceSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsConnectionVarianceSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  MatchColumnIdAsc = 'MATCH_COLUMN_ID_ASC',
  MatchColumnIdDesc = 'MATCH_COLUMN_ID_DESC',
  MatchFoundStrategyAsc = 'MATCH_FOUND_STRATEGY_ASC',
  MatchFoundStrategyDesc = 'MATCH_FOUND_STRATEGY_DESC',
  MatchNotFoundStrategyAsc = 'MATCH_NOT_FOUND_STRATEGY_ASC',
  MatchNotFoundStrategyDesc = 'MATCH_NOT_FOUND_STRATEGY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TotalRowsAsc = 'TOTAL_ROWS_ASC',
  TotalRowsDesc = 'TOTAL_ROWS_DESC',
  TransformedProjectsAsc = 'TRANSFORMED_PROJECTS_ASC',
  TransformedProjectsDesc = 'TRANSFORMED_PROJECTS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ImportHistory = Node & {
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `ImportHistory`. */
  createdByUser: Maybe<MainUser>;
  createdColumns: Maybe<Scalars['JSON']['output']>;
  endedAt: Maybe<Scalars['Datetime']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  errorStack: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  /** Reads a single `ProjectProperty` that is related to this `ImportHistory`. */
  groupByColumn: Maybe<ProjectProperty>;
  groupByColumnId: Maybe<Scalars['Int']['output']>;
  groupedProjects: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  importData: Scalars['JSON']['output'];
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRows: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsConnection: ImportRowsConnection;
  jobId: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `ProjectProperty` that is related to this `ImportHistory`. */
  matchColumn: Maybe<ProjectProperty>;
  matchColumnId: Maybe<Scalars['Int']['output']>;
  matchFoundStrategy: Maybe<ImportMatchFoundStrategy>;
  matchNotFoundStrategy: Maybe<ImportMatchNotFoundStrategy>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: ImportHistoryStatus;
  totalRows: Scalars['Int']['output'];
  transformedProjects: Maybe<Scalars['JSON']['output']>;
  type: ImportType;
  updatedAt: Scalars['Datetime']['output'];
};


export type ImportHistoryImportRowsArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportHistoryImportRowsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportHistoryAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ImportHistoryArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ImportHistoryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ImportHistoryDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ImportHistoryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ImportHistoryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ImportHistoryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ImportHistoryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ImportHistorySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ImportHistoryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ImportHistoryVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ImportHistory` object types. */
export type ImportHistoryAggregatesFilter = {
  /** Array aggregation aggregate over matching `ImportHistory` objects. */
  arrayAgg: InputMaybe<ImportHistoryArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ImportHistory` objects. */
  average: InputMaybe<ImportHistoryAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ImportHistory` objects. */
  distinctCount: InputMaybe<ImportHistoryDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ImportHistory` object to be included within the aggregate. */
  filter: InputMaybe<ImportHistoryFilter>;
  /** Maximum aggregate over matching `ImportHistory` objects. */
  max: InputMaybe<ImportHistoryMaxAggregateFilter>;
  /** Minimum aggregate over matching `ImportHistory` objects. */
  min: InputMaybe<ImportHistoryMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ImportHistory` objects. */
  stddevPopulation: InputMaybe<ImportHistoryStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ImportHistory` objects. */
  stddevSample: InputMaybe<ImportHistoryStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ImportHistory` objects. */
  sum: InputMaybe<ImportHistorySumAggregateFilter>;
  /** Population variance aggregate over matching `ImportHistory` objects. */
  variancePopulation: InputMaybe<ImportHistoryVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ImportHistory` objects. */
  varianceSample: InputMaybe<ImportHistoryVarianceSampleAggregateFilter>;
};

export type ImportHistoryArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  errorMessage: InputMaybe<StringListFilter>;
  errorStack: InputMaybe<StringListFilter>;
  fileName: InputMaybe<StringListFilter>;
  groupByColumnId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  matchColumnId: InputMaybe<IntListFilter>;
  totalRows: InputMaybe<IntListFilter>;
};

export type ImportHistoryArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of errorMessage across the matching connection */
  errorMessage: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of errorStack across the matching connection */
  errorStack: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of fileName across the matching connection */
  fileName: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of groupByColumnId across the matching connection */
  groupByColumnId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of matchColumnId across the matching connection */
  matchColumnId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of totalRows across the matching connection */
  totalRows: Array<Maybe<Scalars['Int']['output']>>;
};


export type ImportHistoryArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesErrorMessageArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesErrorStackArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesFileNameArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryArrayAggAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export type ImportHistoryAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  groupByColumnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  matchColumnId: InputMaybe<BigFloatFilter>;
  totalRows: InputMaybe<BigFloatFilter>;
};

export type ImportHistoryAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportHistoryAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryAverageAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryAverageAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryAverageAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

/**
 * A condition to be used against `ImportHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ImportHistoryCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdColumns` field. */
  createdColumns: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `endedAt` field. */
  endedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `errorStack` field. */
  errorStack: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fileName` field. */
  fileName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `groupByColumnId` field. */
  groupByColumnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `groupedProjects` field. */
  groupedProjects: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `importData` field. */
  importData: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `matchColumnId` field. */
  matchColumnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `matchFoundStrategy` field. */
  matchFoundStrategy: InputMaybe<ImportMatchFoundStrategy>;
  /** Checks for equality with the object’s `matchNotFoundStrategy` field. */
  matchNotFoundStrategy: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ImportHistoryStatus>;
  /** Checks for equality with the object’s `totalRows` field. */
  totalRows: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `transformedProjects` field. */
  transformedProjects: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<ImportType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type ImportHistoryDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdColumns: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<BigIntFilter>;
  errorMessage: InputMaybe<BigIntFilter>;
  errorStack: InputMaybe<BigIntFilter>;
  fileName: InputMaybe<BigIntFilter>;
  groupByColumnId: InputMaybe<BigIntFilter>;
  groupedProjects: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  importData: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  matchColumnId: InputMaybe<BigIntFilter>;
  matchFoundStrategy: InputMaybe<BigIntFilter>;
  matchNotFoundStrategy: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  totalRows: InputMaybe<BigIntFilter>;
  transformedProjects: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type ImportHistoryDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdColumns across the matching connection */
  createdColumns: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endedAt across the matching connection */
  endedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of errorMessage across the matching connection */
  errorMessage: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of errorStack across the matching connection */
  errorStack: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fileName across the matching connection */
  fileName: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of groupedProjects across the matching connection */
  groupedProjects: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of importData across the matching connection */
  importData: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of matchFoundStrategy across the matching connection */
  matchFoundStrategy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of matchNotFoundStrategy across the matching connection */
  matchNotFoundStrategy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of transformedProjects across the matching connection */
  transformedProjects: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ImportHistoryDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesCreatedColumnsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesErrorMessageArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesErrorStackArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesFileNameArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesGroupedProjectsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesImportDataArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesMatchFoundStrategyArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesMatchNotFoundStrategyArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesTransformedProjectsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

/** A filter to be used against `ImportHistory` object types. All fields are combined with a logical ‘and.’ */
export type ImportHistoryFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ImportHistoryFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdColumns` field. */
  createdColumns: InputMaybe<JsonFilter>;
  /** Filter by the object’s `endedAt` field. */
  endedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `errorMessage` field. */
  errorMessage: InputMaybe<StringFilter>;
  /** Filter by the object’s `errorStack` field. */
  errorStack: InputMaybe<StringFilter>;
  /** Filter by the object’s `fileName` field. */
  fileName: InputMaybe<StringFilter>;
  /** Filter by the object’s `groupByColumn` relation. */
  groupByColumn: InputMaybe<ProjectPropertyFilter>;
  /** A related `groupByColumn` exists. */
  groupByColumnExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `groupByColumnId` field. */
  groupByColumnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `groupedProjects` field. */
  groupedProjects: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `importData` field. */
  importData: InputMaybe<JsonFilter>;
  /** Filter by the object’s `importRows` relation. */
  importRows: InputMaybe<ImportHistoryToManyImportRowFilter>;
  /** Some related `importRows` exist. */
  importRowsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<UuidFilter>;
  /** Filter by the object’s `matchColumn` relation. */
  matchColumn: InputMaybe<ProjectPropertyFilter>;
  /** A related `matchColumn` exists. */
  matchColumnExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `matchColumnId` field. */
  matchColumnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `matchFoundStrategy` field. */
  matchFoundStrategy: InputMaybe<ImportMatchFoundStrategyFilter>;
  /** Filter by the object’s `matchNotFoundStrategy` field. */
  matchNotFoundStrategy: InputMaybe<ImportMatchNotFoundStrategyFilter>;
  /** Negates the expression. */
  not: InputMaybe<ImportHistoryFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ImportHistoryFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ImportHistoryStatusFilter>;
  /** Filter by the object’s `totalRows` field. */
  totalRows: InputMaybe<IntFilter>;
  /** Filter by the object’s `transformedProjects` field. */
  transformedProjects: InputMaybe<JsonFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<ImportTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ImportHistory` for usage during aggregation. */
export enum ImportHistoryGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedColumns = 'CREATED_COLUMNS',
  EndedAt = 'ENDED_AT',
  EndedAtTruncatedToDay = 'ENDED_AT_TRUNCATED_TO_DAY',
  EndedAtTruncatedToHour = 'ENDED_AT_TRUNCATED_TO_HOUR',
  EndedAtTruncatedToMonth = 'ENDED_AT_TRUNCATED_TO_MONTH',
  EndedAtTruncatedToQuarter = 'ENDED_AT_TRUNCATED_TO_QUARTER',
  EndedAtTruncatedToWeek = 'ENDED_AT_TRUNCATED_TO_WEEK',
  EndedAtTruncatedToYear = 'ENDED_AT_TRUNCATED_TO_YEAR',
  ErrorMessage = 'ERROR_MESSAGE',
  ErrorStack = 'ERROR_STACK',
  FileName = 'FILE_NAME',
  GroupedProjects = 'GROUPED_PROJECTS',
  GroupByColumnId = 'GROUP_BY_COLUMN_ID',
  ImportData = 'IMPORT_DATA',
  JobId = 'JOB_ID',
  MatchColumnId = 'MATCH_COLUMN_ID',
  MatchFoundStrategy = 'MATCH_FOUND_STRATEGY',
  MatchNotFoundStrategy = 'MATCH_NOT_FOUND_STRATEGY',
  Status = 'STATUS',
  TotalRows = 'TOTAL_ROWS',
  TransformedProjects = 'TRANSFORMED_PROJECTS',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ImportHistoryHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ImportHistory` aggregates. */
export type ImportHistoryHavingInput = {
  AND: InputMaybe<Array<ImportHistoryHavingInput>>;
  OR: InputMaybe<Array<ImportHistoryHavingInput>>;
  arrayAgg: InputMaybe<ImportHistoryHavingArrayAggInput>;
  average: InputMaybe<ImportHistoryHavingAverageInput>;
  distinctCount: InputMaybe<ImportHistoryHavingDistinctCountInput>;
  max: InputMaybe<ImportHistoryHavingMaxInput>;
  min: InputMaybe<ImportHistoryHavingMinInput>;
  stddevPopulation: InputMaybe<ImportHistoryHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ImportHistoryHavingStddevSampleInput>;
  sum: InputMaybe<ImportHistoryHavingSumInput>;
  variancePopulation: InputMaybe<ImportHistoryHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ImportHistoryHavingVarianceSampleInput>;
};

export type ImportHistoryHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  groupByColumnId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  matchColumnId: InputMaybe<HavingIntFilter>;
  totalRows: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportHistoryMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  groupByColumnId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  matchColumnId: InputMaybe<IntFilter>;
  totalRows: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ImportHistoryMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalRows across the matching connection */
  totalRows: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ImportHistoryMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export type ImportHistoryMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  groupByColumnId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  matchColumnId: InputMaybe<IntFilter>;
  totalRows: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ImportHistoryMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalRows across the matching connection */
  totalRows: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ImportHistoryMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export enum ImportHistoryStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  PartiallySuccess = 'PARTIALLY_SUCCESS',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Undoing = 'UNDOING',
  Undone = 'UNDONE'
}

/** A filter to be used against ImportHistoryStatus fields. All fields are combined with a logical ‘and.’ */
export type ImportHistoryStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ImportHistoryStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ImportHistoryStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ImportHistoryStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ImportHistoryStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ImportHistoryStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ImportHistoryStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ImportHistoryStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ImportHistoryStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ImportHistoryStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ImportHistoryStatus>>;
};

export type ImportHistoryStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  groupByColumnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  matchColumnId: InputMaybe<BigFloatFilter>;
  totalRows: InputMaybe<BigFloatFilter>;
};

export type ImportHistoryStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportHistoryStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevPopulationAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevPopulationAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevPopulationAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export type ImportHistoryStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  groupByColumnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  matchColumnId: InputMaybe<BigFloatFilter>;
  totalRows: InputMaybe<BigFloatFilter>;
};

export type ImportHistoryStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportHistoryStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevSampleAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevSampleAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryStddevSampleAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export type ImportHistorySumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  groupByColumnId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  matchColumnId: InputMaybe<BigIntFilter>;
  totalRows: InputMaybe<BigIntFilter>;
};

export type ImportHistorySumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of groupByColumnId across the matching connection */
  groupByColumnId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of matchColumnId across the matching connection */
  matchColumnId: Scalars['BigInt']['output'];
  /** Sum of totalRows across the matching connection */
  totalRows: Scalars['BigInt']['output'];
};


export type ImportHistorySumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistorySumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistorySumAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistorySumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistorySumAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistorySumAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

/** A filter to be used against many `ImportRow` object types. All fields are combined with a logical ‘and.’ */
export type ImportHistoryToManyImportRowFilter = {
  /** Aggregates across related `ImportRow` match the filter criteria. */
  aggregates: InputMaybe<ImportRowAggregatesFilter>;
  /** Every related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ImportRowFilter>;
  /** No related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ImportRowFilter>;
  /** Some related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ImportRowFilter>;
};

export type ImportHistoryVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  groupByColumnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  matchColumnId: InputMaybe<BigFloatFilter>;
  totalRows: InputMaybe<BigFloatFilter>;
};

export type ImportHistoryVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportHistoryVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVariancePopulationAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVariancePopulationAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVariancePopulationAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export type ImportHistoryVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  groupByColumnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  matchColumnId: InputMaybe<BigFloatFilter>;
  totalRows: InputMaybe<BigFloatFilter>;
};

export type ImportHistoryVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of groupByColumnId across the matching connection */
  groupByColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of matchColumnId across the matching connection */
  matchColumnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalRows across the matching connection */
  totalRows: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportHistoryVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVarianceSampleAggregatesGroupByColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVarianceSampleAggregatesMatchColumnIdArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ImportHistoryVarianceSampleAggregatesTotalRowsArgs = {
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};

export enum ImportMatchFoundStrategy {
  Enrich = 'ENRICH',
  Replace = 'REPLACE',
  Skip = 'SKIP'
}

/** A filter to be used against ImportMatchFoundStrategy fields. All fields are combined with a logical ‘and.’ */
export type ImportMatchFoundStrategyFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ImportMatchFoundStrategy>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ImportMatchFoundStrategy>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ImportMatchFoundStrategy>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ImportMatchFoundStrategy>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ImportMatchFoundStrategy>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ImportMatchFoundStrategy>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ImportMatchFoundStrategy>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ImportMatchFoundStrategy>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ImportMatchFoundStrategy>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ImportMatchFoundStrategy>>;
};

export enum ImportMatchNotFoundStrategy {
  Create = 'CREATE',
  Skip = 'SKIP'
}

/** A filter to be used against ImportMatchNotFoundStrategy fields. All fields are combined with a logical ‘and.’ */
export type ImportMatchNotFoundStrategyFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ImportMatchNotFoundStrategy>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ImportMatchNotFoundStrategy>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ImportMatchNotFoundStrategy>>;
};

export type ImportRow = Node & {
  /** Reads a single `Project` that is related to this `ImportRow`. */
  account: Maybe<Project>;
  accountId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `ImportRow`. */
  contact: Maybe<Contact>;
  contactId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  endedAt: Maybe<Scalars['Datetime']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  errorStack: Maybe<Scalars['String']['output']>;
  groupedByValue: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  importData: Maybe<Scalars['JSON']['output']>;
  /** Reads a single `ImportHistory` that is related to this `ImportRow`. */
  importHistory: Maybe<ImportHistory>;
  importHistoryId: Scalars['Int']['output'];
  importRowNumber: Scalars['Int']['output'];
  isAccountCreated: Scalars['Boolean']['output'];
  isContactCreated: Scalars['Boolean']['output'];
  isProjectCreated: Scalars['Boolean']['output'];
  matchedByValue: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `ImportRow`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  replacedProjectId: Maybe<Scalars['Int']['output']>;
  resultData: Maybe<Scalars['JSON']['output']>;
  startedAt: Maybe<Scalars['Datetime']['output']>;
  status: ImportRowStatus;
  updatedAt: Scalars['Datetime']['output'];
  updatedProperties: Scalars['JSON']['output'];
};

export type ImportRowAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ImportRowArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ImportRowAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ImportRowDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ImportRowMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ImportRowMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ImportRowStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ImportRowStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ImportRowSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ImportRowVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ImportRowVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ImportRow` object types. */
export type ImportRowAggregatesFilter = {
  /** Array aggregation aggregate over matching `ImportRow` objects. */
  arrayAgg: InputMaybe<ImportRowArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ImportRow` objects. */
  average: InputMaybe<ImportRowAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ImportRow` objects. */
  distinctCount: InputMaybe<ImportRowDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ImportRow` object to be included within the aggregate. */
  filter: InputMaybe<ImportRowFilter>;
  /** Maximum aggregate over matching `ImportRow` objects. */
  max: InputMaybe<ImportRowMaxAggregateFilter>;
  /** Minimum aggregate over matching `ImportRow` objects. */
  min: InputMaybe<ImportRowMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ImportRow` objects. */
  stddevPopulation: InputMaybe<ImportRowStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ImportRow` objects. */
  stddevSample: InputMaybe<ImportRowStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ImportRow` objects. */
  sum: InputMaybe<ImportRowSumAggregateFilter>;
  /** Population variance aggregate over matching `ImportRow` objects. */
  variancePopulation: InputMaybe<ImportRowVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ImportRow` objects. */
  varianceSample: InputMaybe<ImportRowVarianceSampleAggregateFilter>;
};

export type ImportRowArrayAggAggregateFilter = {
  accountId: InputMaybe<IntListFilter>;
  contactId: InputMaybe<IntListFilter>;
  errorMessage: InputMaybe<StringListFilter>;
  errorStack: InputMaybe<StringListFilter>;
  groupedByValue: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  importHistoryId: InputMaybe<IntListFilter>;
  importRowNumber: InputMaybe<IntListFilter>;
  isAccountCreated: InputMaybe<BooleanListFilter>;
  isContactCreated: InputMaybe<BooleanListFilter>;
  isProjectCreated: InputMaybe<BooleanListFilter>;
  matchedByValue: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
  replacedProjectId: InputMaybe<IntListFilter>;
};

export type ImportRowArrayAggAggregates = {
  /** Array aggregation of accountId across the matching connection */
  accountId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of contactId across the matching connection */
  contactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of errorMessage across the matching connection */
  errorMessage: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of errorStack across the matching connection */
  errorStack: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of groupedByValue across the matching connection */
  groupedByValue: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of importHistoryId across the matching connection */
  importHistoryId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of importRowNumber across the matching connection */
  importRowNumber: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isAccountCreated across the matching connection */
  isAccountCreated: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isContactCreated across the matching connection */
  isContactCreated: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isProjectCreated across the matching connection */
  isProjectCreated: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of matchedByValue across the matching connection */
  matchedByValue: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of replacedProjectId across the matching connection */
  replacedProjectId: Array<Maybe<Scalars['Int']['output']>>;
};


export type ImportRowArrayAggAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesErrorMessageArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesErrorStackArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesGroupedByValueArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesIsAccountCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesIsContactCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesIsProjectCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesMatchedByValueArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowArrayAggAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowAverageAggregateFilter = {
  accountId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  importHistoryId: InputMaybe<BigFloatFilter>;
  importRowNumber: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  replacedProjectId: InputMaybe<BigFloatFilter>;
};

export type ImportRowAverageAggregates = {
  /** Mean average of accountId across the matching connection */
  accountId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportRowAverageAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowAverageAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

/**
 * A condition to be used against `ImportRow` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ImportRowCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endedAt` field. */
  endedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `errorStack` field. */
  errorStack: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `groupedByValue` field. */
  groupedByValue: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `importData` field. */
  importData: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `importHistoryId` field. */
  importHistoryId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `importRowNumber` field. */
  importRowNumber: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isAccountCreated` field. */
  isAccountCreated: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isContactCreated` field. */
  isContactCreated: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isProjectCreated` field. */
  isProjectCreated: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `matchedByValue` field. */
  matchedByValue: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `replacedProjectId` field. */
  replacedProjectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resultData` field. */
  resultData: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ImportRowStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updatedProperties` field. */
  updatedProperties: InputMaybe<Scalars['JSON']['input']>;
};

export type ImportRowDistinctCountAggregateFilter = {
  accountId: InputMaybe<BigIntFilter>;
  contactId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<BigIntFilter>;
  errorMessage: InputMaybe<BigIntFilter>;
  errorStack: InputMaybe<BigIntFilter>;
  groupedByValue: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  importData: InputMaybe<BigIntFilter>;
  importHistoryId: InputMaybe<BigIntFilter>;
  importRowNumber: InputMaybe<BigIntFilter>;
  isAccountCreated: InputMaybe<BigIntFilter>;
  isContactCreated: InputMaybe<BigIntFilter>;
  isProjectCreated: InputMaybe<BigIntFilter>;
  matchedByValue: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  replacedProjectId: InputMaybe<BigIntFilter>;
  resultData: InputMaybe<BigIntFilter>;
  startedAt: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  updatedProperties: InputMaybe<BigIntFilter>;
};

export type ImportRowDistinctCountAggregates = {
  /** Distinct count of accountId across the matching connection */
  accountId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endedAt across the matching connection */
  endedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of errorMessage across the matching connection */
  errorMessage: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of errorStack across the matching connection */
  errorStack: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of groupedByValue across the matching connection */
  groupedByValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of importData across the matching connection */
  importData: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isAccountCreated across the matching connection */
  isAccountCreated: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isContactCreated across the matching connection */
  isContactCreated: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isProjectCreated across the matching connection */
  isProjectCreated: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of matchedByValue across the matching connection */
  matchedByValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of resultData across the matching connection */
  resultData: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedProperties across the matching connection */
  updatedProperties: Maybe<Scalars['BigInt']['output']>;
};


export type ImportRowDistinctCountAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesErrorMessageArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesErrorStackArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesGroupedByValueArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesImportDataArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesIsAccountCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesIsContactCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesIsProjectCreatedArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesMatchedByValueArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesResultDataArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowDistinctCountAggregatesUpdatedPropertiesArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

/** A filter to be used against `ImportRow` object types. All fields are combined with a logical ‘and.’ */
export type ImportRowFilter = {
  /** Filter by the object’s `account` relation. */
  account: InputMaybe<ProjectFilter>;
  /** A related `account` exists. */
  accountExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `accountId` field. */
  accountId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ImportRowFilter>>;
  /** Filter by the object’s `contact` relation. */
  contact: InputMaybe<ContactFilter>;
  /** A related `contact` exists. */
  contactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `contactId` field. */
  contactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endedAt` field. */
  endedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `errorMessage` field. */
  errorMessage: InputMaybe<StringFilter>;
  /** Filter by the object’s `errorStack` field. */
  errorStack: InputMaybe<StringFilter>;
  /** Filter by the object’s `groupedByValue` field. */
  groupedByValue: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `importData` field. */
  importData: InputMaybe<JsonFilter>;
  /** Filter by the object’s `importHistory` relation. */
  importHistory: InputMaybe<ImportHistoryFilter>;
  /** Filter by the object’s `importHistoryId` field. */
  importHistoryId: InputMaybe<IntFilter>;
  /** Filter by the object’s `importRowNumber` field. */
  importRowNumber: InputMaybe<IntFilter>;
  /** Filter by the object’s `isAccountCreated` field. */
  isAccountCreated: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isContactCreated` field. */
  isContactCreated: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isProjectCreated` field. */
  isProjectCreated: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `matchedByValue` field. */
  matchedByValue: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ImportRowFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ImportRowFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `replacedProjectId` field. */
  replacedProjectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `resultData` field. */
  resultData: InputMaybe<JsonFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ImportRowStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedProperties` field. */
  updatedProperties: InputMaybe<JsonFilter>;
};

/** Grouping methods for `ImportRow` for usage during aggregation. */
export enum ImportRowGroupBy {
  AccountId = 'ACCOUNT_ID',
  ContactId = 'CONTACT_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  EndedAt = 'ENDED_AT',
  EndedAtTruncatedToDay = 'ENDED_AT_TRUNCATED_TO_DAY',
  EndedAtTruncatedToHour = 'ENDED_AT_TRUNCATED_TO_HOUR',
  EndedAtTruncatedToMonth = 'ENDED_AT_TRUNCATED_TO_MONTH',
  EndedAtTruncatedToQuarter = 'ENDED_AT_TRUNCATED_TO_QUARTER',
  EndedAtTruncatedToWeek = 'ENDED_AT_TRUNCATED_TO_WEEK',
  EndedAtTruncatedToYear = 'ENDED_AT_TRUNCATED_TO_YEAR',
  ErrorMessage = 'ERROR_MESSAGE',
  ErrorStack = 'ERROR_STACK',
  GroupedByValue = 'GROUPED_BY_VALUE',
  ImportData = 'IMPORT_DATA',
  ImportHistoryId = 'IMPORT_HISTORY_ID',
  ImportRowNumber = 'IMPORT_ROW_NUMBER',
  IsAccountCreated = 'IS_ACCOUNT_CREATED',
  IsContactCreated = 'IS_CONTACT_CREATED',
  IsProjectCreated = 'IS_PROJECT_CREATED',
  MatchedByValue = 'MATCHED_BY_VALUE',
  ProjectId = 'PROJECT_ID',
  ReplacedProjectId = 'REPLACED_PROJECT_ID',
  ResultData = 'RESULT_DATA',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  StartedAtTruncatedToMonth = 'STARTED_AT_TRUNCATED_TO_MONTH',
  StartedAtTruncatedToQuarter = 'STARTED_AT_TRUNCATED_TO_QUARTER',
  StartedAtTruncatedToWeek = 'STARTED_AT_TRUNCATED_TO_WEEK',
  StartedAtTruncatedToYear = 'STARTED_AT_TRUNCATED_TO_YEAR',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  UpdatedProperties = 'UPDATED_PROPERTIES'
}

export type ImportRowHavingArrayAggInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingAverageInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingDistinctCountInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ImportRow` aggregates. */
export type ImportRowHavingInput = {
  AND: InputMaybe<Array<ImportRowHavingInput>>;
  OR: InputMaybe<Array<ImportRowHavingInput>>;
  arrayAgg: InputMaybe<ImportRowHavingArrayAggInput>;
  average: InputMaybe<ImportRowHavingAverageInput>;
  distinctCount: InputMaybe<ImportRowHavingDistinctCountInput>;
  max: InputMaybe<ImportRowHavingMaxInput>;
  min: InputMaybe<ImportRowHavingMinInput>;
  stddevPopulation: InputMaybe<ImportRowHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ImportRowHavingStddevSampleInput>;
  sum: InputMaybe<ImportRowHavingSumInput>;
  variancePopulation: InputMaybe<ImportRowHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ImportRowHavingVarianceSampleInput>;
};

export type ImportRowHavingMaxInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingMinInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingStddevPopulationInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingStddevSampleInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingSumInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingVariancePopulationInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowHavingVarianceSampleInput = {
  accountId: InputMaybe<HavingIntFilter>;
  contactId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  importHistoryId: InputMaybe<HavingIntFilter>;
  importRowNumber: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  replacedProjectId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ImportRowMaxAggregateFilter = {
  accountId: InputMaybe<IntFilter>;
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  importHistoryId: InputMaybe<IntFilter>;
  importRowNumber: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  replacedProjectId: InputMaybe<IntFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ImportRowMaxAggregates = {
  /** Maximum of accountId across the matching connection */
  accountId: Maybe<Scalars['Int']['output']>;
  /** Maximum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['Int']['output']>;
  /** Maximum of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ImportRowMaxAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowMinAggregateFilter = {
  accountId: InputMaybe<IntFilter>;
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  importHistoryId: InputMaybe<IntFilter>;
  importRowNumber: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  replacedProjectId: InputMaybe<IntFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ImportRowMinAggregates = {
  /** Minimum of accountId across the matching connection */
  accountId: Maybe<Scalars['Int']['output']>;
  /** Minimum of contactId across the matching connection */
  contactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['Int']['output']>;
  /** Minimum of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ImportRowMinAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export enum ImportRowStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Replaced = 'REPLACED',
  Skipped = 'SKIPPED',
  Undoing = 'UNDOING',
  Undone = 'UNDONE',
  Updated = 'UPDATED'
}

/** A filter to be used against ImportRowStatus fields. All fields are combined with a logical ‘and.’ */
export type ImportRowStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ImportRowStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ImportRowStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ImportRowStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ImportRowStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ImportRowStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ImportRowStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ImportRowStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ImportRowStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ImportRowStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ImportRowStatus>>;
};

export type ImportRowStddevPopulationAggregateFilter = {
  accountId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  importHistoryId: InputMaybe<BigFloatFilter>;
  importRowNumber: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  replacedProjectId: InputMaybe<BigFloatFilter>;
};

export type ImportRowStddevPopulationAggregates = {
  /** Population standard deviation of accountId across the matching connection */
  accountId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportRowStddevPopulationAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevPopulationAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowStddevSampleAggregateFilter = {
  accountId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  importHistoryId: InputMaybe<BigFloatFilter>;
  importRowNumber: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  replacedProjectId: InputMaybe<BigFloatFilter>;
};

export type ImportRowStddevSampleAggregates = {
  /** Sample standard deviation of accountId across the matching connection */
  accountId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportRowStddevSampleAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowStddevSampleAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowSumAggregateFilter = {
  accountId: InputMaybe<BigIntFilter>;
  contactId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  importHistoryId: InputMaybe<BigIntFilter>;
  importRowNumber: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  replacedProjectId: InputMaybe<BigIntFilter>;
};

export type ImportRowSumAggregates = {
  /** Sum of accountId across the matching connection */
  accountId: Scalars['BigInt']['output'];
  /** Sum of contactId across the matching connection */
  contactId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of importHistoryId across the matching connection */
  importHistoryId: Scalars['BigInt']['output'];
  /** Sum of importRowNumber across the matching connection */
  importRowNumber: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of replacedProjectId across the matching connection */
  replacedProjectId: Scalars['BigInt']['output'];
};


export type ImportRowSumAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowSumAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowVariancePopulationAggregateFilter = {
  accountId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  importHistoryId: InputMaybe<BigFloatFilter>;
  importRowNumber: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  replacedProjectId: InputMaybe<BigFloatFilter>;
};

export type ImportRowVariancePopulationAggregates = {
  /** Population variance of accountId across the matching connection */
  accountId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportRowVariancePopulationAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVariancePopulationAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

export type ImportRowVarianceSampleAggregateFilter = {
  accountId: InputMaybe<BigFloatFilter>;
  contactId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  importHistoryId: InputMaybe<BigFloatFilter>;
  importRowNumber: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  replacedProjectId: InputMaybe<BigFloatFilter>;
};

export type ImportRowVarianceSampleAggregates = {
  /** Sample variance of accountId across the matching connection */
  accountId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of contactId across the matching connection */
  contactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of importHistoryId across the matching connection */
  importHistoryId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of importRowNumber across the matching connection */
  importRowNumber: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of replacedProjectId across the matching connection */
  replacedProjectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ImportRowVarianceSampleAggregatesAccountIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesContactIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesImportHistoryIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesImportRowNumberArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ImportRowVarianceSampleAggregatesReplacedProjectIdArgs = {
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};

/** A connection to a list of `ImportRow` values. */
export type ImportRowsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ImportRowAggregates>;
  /** A list of edges which contains the `ImportRow` and cursor to aid in pagination. */
  edges: Array<ImportRowsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ImportRowAggregates>>;
  /** A list of `ImportRow` objects. */
  nodes: Array<Maybe<ImportRow>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ImportRow` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ImportRow` values. */
export type ImportRowsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ImportRowGroupBy>;
  having: InputMaybe<ImportRowHavingInput>;
};

/** A `ImportRow` edge in the connection. */
export type ImportRowsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ImportRow` at the end of the edge. */
  node: Maybe<ImportRow>;
};

/** Methods to use when ordering `ImportRow`. */
export enum ImportRowsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EndedAtAsc = 'ENDED_AT_ASC',
  EndedAtDesc = 'ENDED_AT_DESC',
  ErrorMessageAsc = 'ERROR_MESSAGE_ASC',
  ErrorMessageDesc = 'ERROR_MESSAGE_DESC',
  ErrorStackAsc = 'ERROR_STACK_ASC',
  ErrorStackDesc = 'ERROR_STACK_DESC',
  GroupedByValueAsc = 'GROUPED_BY_VALUE_ASC',
  GroupedByValueDesc = 'GROUPED_BY_VALUE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportDataAsc = 'IMPORT_DATA_ASC',
  ImportDataDesc = 'IMPORT_DATA_DESC',
  ImportHistoryIdAsc = 'IMPORT_HISTORY_ID_ASC',
  ImportHistoryIdDesc = 'IMPORT_HISTORY_ID_DESC',
  ImportRowNumberAsc = 'IMPORT_ROW_NUMBER_ASC',
  ImportRowNumberDesc = 'IMPORT_ROW_NUMBER_DESC',
  IsAccountCreatedAsc = 'IS_ACCOUNT_CREATED_ASC',
  IsAccountCreatedDesc = 'IS_ACCOUNT_CREATED_DESC',
  IsContactCreatedAsc = 'IS_CONTACT_CREATED_ASC',
  IsContactCreatedDesc = 'IS_CONTACT_CREATED_DESC',
  IsProjectCreatedAsc = 'IS_PROJECT_CREATED_ASC',
  IsProjectCreatedDesc = 'IS_PROJECT_CREATED_DESC',
  MatchedByValueAsc = 'MATCHED_BY_VALUE_ASC',
  MatchedByValueDesc = 'MATCHED_BY_VALUE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ReplacedProjectIdAsc = 'REPLACED_PROJECT_ID_ASC',
  ReplacedProjectIdDesc = 'REPLACED_PROJECT_ID_DESC',
  ResultDataAsc = 'RESULT_DATA_ASC',
  ResultDataDesc = 'RESULT_DATA_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedPropertiesAsc = 'UPDATED_PROPERTIES_ASC',
  UpdatedPropertiesDesc = 'UPDATED_PROPERTIES_DESC'
}

export enum ImportType {
  Deal = 'DEAL',
  Project = 'PROJECT'
}

/** A filter to be used against ImportType fields. All fields are combined with a logical ‘and.’ */
export type ImportTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ImportType>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ImportType>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ImportType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ImportType>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ImportType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ImportType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ImportType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ImportType>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ImportType>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ImportType>>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** A filter to be used against Int List fields. All fields are combined with a logical ‘and.’ */
export type IntListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan: InputMaybe<Scalars['Int']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan: InputMaybe<Scalars['Int']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo: InputMaybe<Scalars['Int']['input']>;
  /** Contained by the specified list of values. */
  containedBy: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Contains the specified list of values. */
  contains: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type Integration = Node & {
  active: Scalars['Boolean']['output'];
  /** Reads a single `Company` that is related to this `Integration`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `Integration`. */
  createdByUser: Maybe<MainUser>;
  id: Scalars['Int']['output'];
  /** Reads a single `IntegrationStat` that is related to this `Integration`. */
  integrationStat: Maybe<IntegrationStat>;
  /** Reads and enables pagination through a set of `IntegrationSystemLog`. */
  integrationSystemLogsConnection: IntegrationSystemLogsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySystemIntegrationIdAndProjectId: IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySystemIntegrationIdAndProjectIdList: Array<Project>;
  provider: IntegrationProvider;
  providerConfig: Scalars['JSON']['output'];
  /** Reads and enables pagination through a set of `SystemProfileConfig`. */
  systemProfileConfigs: Array<SystemProfileConfig>;
  /** Reads and enables pagination through a set of `SystemProfile`. */
  systemProfilesBySystemIntegrationIdAndProfileIdList: Array<SystemProfile>;
  /** Reads and enables pagination through a set of `System`. */
  systemsConnection: SystemsConnection;
  uuid: Scalars['UUID']['output'];
};


export type IntegrationIntegrationSystemLogsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<IntegrationSystemLogCondition>;
  filter: InputMaybe<IntegrationSystemLogFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationProjectsBySystemIntegrationIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type IntegrationProjectsBySystemIntegrationIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type IntegrationSystemProfileConfigsArgs = {
  condition: InputMaybe<SystemProfileConfigCondition>;
  filter: InputMaybe<SystemProfileConfigFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfileConfigsOrderBy>>;
};


export type IntegrationSystemProfilesBySystemIntegrationIdAndProfileIdListArgs = {
  condition: InputMaybe<SystemProfileCondition>;
  filter: InputMaybe<SystemProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfilesOrderBy>>;
};


export type IntegrationSystemsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};

/** A filter to be used against aggregates of `Integration` object types. */
export type IntegrationAggregatesFilter = {
  /** Array aggregation aggregate over matching `Integration` objects. */
  arrayAgg: InputMaybe<IntegrationArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Integration` objects. */
  average: InputMaybe<IntegrationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Integration` objects. */
  distinctCount: InputMaybe<IntegrationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Integration` object to be included within the aggregate. */
  filter: InputMaybe<IntegrationFilter>;
  /** Maximum aggregate over matching `Integration` objects. */
  max: InputMaybe<IntegrationMaxAggregateFilter>;
  /** Minimum aggregate over matching `Integration` objects. */
  min: InputMaybe<IntegrationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Integration` objects. */
  stddevPopulation: InputMaybe<IntegrationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Integration` objects. */
  stddevSample: InputMaybe<IntegrationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Integration` objects. */
  sum: InputMaybe<IntegrationSumAggregateFilter>;
  /** Population variance aggregate over matching `Integration` objects. */
  variancePopulation: InputMaybe<IntegrationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Integration` objects. */
  varianceSample: InputMaybe<IntegrationVarianceSampleAggregateFilter>;
};

export type IntegrationArrayAggAggregateFilter = {
  active: InputMaybe<BooleanListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type IntegrationAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `Integration` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IntegrationCondition = {
  /** Checks for equality with the object’s `active` field. */
  active: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `provider` field. */
  provider: InputMaybe<IntegrationProvider>;
  /** Checks for equality with the object’s `providerConfig` field. */
  providerConfig: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
};

export type IntegrationDistinctCountAggregateFilter = {
  active: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  provider: InputMaybe<BigIntFilter>;
  providerConfig: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Integration` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationFilter = {
  /** Filter by the object’s `active` field. */
  active: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<IntegrationFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `integrationStat` relation. */
  integrationStat: InputMaybe<IntegrationStatFilter>;
  /** A related `integrationStat` exists. */
  integrationStatExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `integrationSystemLogs` relation. */
  integrationSystemLogs: InputMaybe<IntegrationToManyIntegrationSystemLogFilter>;
  /** Some related `integrationSystemLogs` exist. */
  integrationSystemLogsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not: InputMaybe<IntegrationFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<IntegrationFilter>>;
  /** Filter by the object’s `provider` field. */
  provider: InputMaybe<IntegrationProviderFilter>;
  /** Filter by the object’s `providerConfig` field. */
  providerConfig: InputMaybe<JsonFilter>;
  /** Filter by the object’s `systemProfileConfigs` relation. */
  systemProfileConfigs: InputMaybe<IntegrationToManySystemProfileConfigFilter>;
  /** Some related `systemProfileConfigs` exist. */
  systemProfileConfigsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systems` relation. */
  systems: InputMaybe<IntegrationToManySystemFilter>;
  /** Some related `systems` exist. */
  systemsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
};

export type IntegrationMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

export type IntegrationMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

/** A connection to a list of `Project` values, with data from `System`. */
export type IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `System`, and the cursor to aid in pagination. */
  edges: Array<IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `System`. */
export type IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `System`. */
export type IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByProjectIdConnection: SystemsConnection;
};


/** A `Project` edge in the connection, with data from `System`. */
export type IntegrationProjectsBySystemIntegrationIdAndProjectIdManyToManyEdgeSystemsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};

export enum IntegrationProvider {
  Enfake = 'ENFAKE',
  Enphase = 'ENPHASE',
  LunarEdge = 'LUNAR_EDGE',
  SolarEdge = 'SOLAR_EDGE'
}

/** A filter to be used against IntegrationProvider fields. All fields are combined with a logical ‘and.’ */
export type IntegrationProviderFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<IntegrationProvider>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<IntegrationProvider>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<IntegrationProvider>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<IntegrationProvider>;
  /** Included in the specified list. */
  in: InputMaybe<Array<IntegrationProvider>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<IntegrationProvider>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<IntegrationProvider>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<IntegrationProvider>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<IntegrationProvider>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<IntegrationProvider>>;
};

export type IntegrationStat = Node & {
  averageMonthlyNew: Maybe<Scalars['BigFloat']['output']>;
  /** Reads a single `Integration` that is related to this `IntegrationStat`. */
  integration: Maybe<Integration>;
  integrationId: Scalars['Int']['output'];
  monitored: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  total: Maybe<Scalars['BigInt']['output']>;
};

/**
 * A condition to be used against `IntegrationStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IntegrationStatCondition = {
  /** Checks for equality with the object’s `averageMonthlyNew` field. */
  averageMonthlyNew: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `monitored` field. */
  monitored: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `total` field. */
  total: InputMaybe<Scalars['BigInt']['input']>;
};

/** A filter to be used against `IntegrationStat` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationStatFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<IntegrationStatFilter>>;
  /** Filter by the object’s `averageMonthlyNew` field. */
  averageMonthlyNew: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `integration` relation. */
  integration: InputMaybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `integrationId` field. */
  integrationId: InputMaybe<IntFilter>;
  /** Filter by the object’s `monitored` field. */
  monitored: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not: InputMaybe<IntegrationStatFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<IntegrationStatFilter>>;
  /** Filter by the object’s `total` field. */
  total: InputMaybe<BigIntFilter>;
};

/** Methods to use when ordering `IntegrationStat`. */
export enum IntegrationStatsOrderBy {
  AverageMonthlyNewAsc = 'AVERAGE_MONTHLY_NEW_ASC',
  AverageMonthlyNewDesc = 'AVERAGE_MONTHLY_NEW_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  MonitoredAsc = 'MONITORED_ASC',
  MonitoredDesc = 'MONITORED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC'
}

export type IntegrationStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type IntegrationStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type IntegrationSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type IntegrationSystemLog = Node & {
  createdAt: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['Int']['output'];
  /** Reads a single `Integration` that is related to this `IntegrationSystemLog`. */
  integration: Maybe<Integration>;
  integrationId: Maybe<Scalars['Int']['output']>;
  label: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: Maybe<SystemLogStatus>;
  systemId: Maybe<Scalars['Int']['output']>;
  thread: Maybe<Scalars['String']['output']>;
};

export type IntegrationSystemLogAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<IntegrationSystemLogArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<IntegrationSystemLogAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<IntegrationSystemLogDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<IntegrationSystemLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<IntegrationSystemLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<IntegrationSystemLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<IntegrationSystemLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<IntegrationSystemLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<IntegrationSystemLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<IntegrationSystemLogVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `IntegrationSystemLog` object types. */
export type IntegrationSystemLogAggregatesFilter = {
  /** Array aggregation aggregate over matching `IntegrationSystemLog` objects. */
  arrayAgg: InputMaybe<IntegrationSystemLogArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `IntegrationSystemLog` objects. */
  average: InputMaybe<IntegrationSystemLogAverageAggregateFilter>;
  /** Distinct count aggregate over matching `IntegrationSystemLog` objects. */
  distinctCount: InputMaybe<IntegrationSystemLogDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `IntegrationSystemLog` object to be included within the aggregate. */
  filter: InputMaybe<IntegrationSystemLogFilter>;
  /** Maximum aggregate over matching `IntegrationSystemLog` objects. */
  max: InputMaybe<IntegrationSystemLogMaxAggregateFilter>;
  /** Minimum aggregate over matching `IntegrationSystemLog` objects. */
  min: InputMaybe<IntegrationSystemLogMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `IntegrationSystemLog` objects. */
  stddevPopulation: InputMaybe<IntegrationSystemLogStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `IntegrationSystemLog` objects. */
  stddevSample: InputMaybe<IntegrationSystemLogStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `IntegrationSystemLog` objects. */
  sum: InputMaybe<IntegrationSystemLogSumAggregateFilter>;
  /** Population variance aggregate over matching `IntegrationSystemLog` objects. */
  variancePopulation: InputMaybe<IntegrationSystemLogVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `IntegrationSystemLog` objects. */
  varianceSample: InputMaybe<IntegrationSystemLogVarianceSampleAggregateFilter>;
};

export type IntegrationSystemLogArrayAggAggregateFilter = {
  id: InputMaybe<IntListFilter>;
  integrationId: InputMaybe<IntListFilter>;
  label: InputMaybe<StringListFilter>;
  message: InputMaybe<StringListFilter>;
  systemId: InputMaybe<IntListFilter>;
  thread: InputMaybe<StringListFilter>;
};

export type IntegrationSystemLogArrayAggAggregates = {
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of integrationId across the matching connection */
  integrationId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of label across the matching connection */
  label: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of message across the matching connection */
  message: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of thread across the matching connection */
  thread: Array<Maybe<Scalars['String']['output']>>;
};


export type IntegrationSystemLogArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogArrayAggAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogArrayAggAggregatesLabelArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogArrayAggAggregatesMessageArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogArrayAggAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type IntegrationSystemLogAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type IntegrationSystemLogAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogAverageAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

/**
 * A condition to be used against `IntegrationSystemLog` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IntegrationSystemLogCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `message` field. */
  message: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<SystemLogStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `thread` field. */
  thread: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationSystemLogDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  integrationId: InputMaybe<BigIntFilter>;
  label: InputMaybe<BigIntFilter>;
  message: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  thread: InputMaybe<BigIntFilter>;
};

export type IntegrationSystemLogDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of label across the matching connection */
  label: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of message across the matching connection */
  message: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of thread across the matching connection */
  thread: Maybe<Scalars['BigInt']['output']>;
};


export type IntegrationSystemLogDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesLabelArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesMessageArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogDistinctCountAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

/** A filter to be used against `IntegrationSystemLog` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationSystemLogFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<IntegrationSystemLogFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `integration` relation. */
  integration: InputMaybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `integrationId` field. */
  integrationId: InputMaybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label: InputMaybe<StringFilter>;
  /** Filter by the object’s `message` field. */
  message: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<IntegrationSystemLogFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<IntegrationSystemLogFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<SystemLogStatusFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `thread` field. */
  thread: InputMaybe<StringFilter>;
};

/** Grouping methods for `IntegrationSystemLog` for usage during aggregation. */
export enum IntegrationSystemLogGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IntegrationId = 'INTEGRATION_ID',
  Label = 'LABEL',
  Message = 'MESSAGE',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  Thread = 'THREAD'
}

export type IntegrationSystemLogHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `IntegrationSystemLog` aggregates. */
export type IntegrationSystemLogHavingInput = {
  AND: InputMaybe<Array<IntegrationSystemLogHavingInput>>;
  OR: InputMaybe<Array<IntegrationSystemLogHavingInput>>;
  arrayAgg: InputMaybe<IntegrationSystemLogHavingArrayAggInput>;
  average: InputMaybe<IntegrationSystemLogHavingAverageInput>;
  distinctCount: InputMaybe<IntegrationSystemLogHavingDistinctCountInput>;
  max: InputMaybe<IntegrationSystemLogHavingMaxInput>;
  min: InputMaybe<IntegrationSystemLogHavingMinInput>;
  stddevPopulation: InputMaybe<IntegrationSystemLogHavingStddevPopulationInput>;
  stddevSample: InputMaybe<IntegrationSystemLogHavingStddevSampleInput>;
  sum: InputMaybe<IntegrationSystemLogHavingSumInput>;
  variancePopulation: InputMaybe<IntegrationSystemLogHavingVariancePopulationInput>;
  varianceSample: InputMaybe<IntegrationSystemLogHavingVarianceSampleInput>;
};

export type IntegrationSystemLogHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type IntegrationSystemLogMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  integrationId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type IntegrationSystemLogMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of integrationId across the matching connection */
  integrationId: Maybe<Scalars['Int']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type IntegrationSystemLogMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMaxAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  integrationId: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type IntegrationSystemLogMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of integrationId across the matching connection */
  integrationId: Maybe<Scalars['Int']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type IntegrationSystemLogMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMinAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type IntegrationSystemLogStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type IntegrationSystemLogStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogStddevPopulationAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type IntegrationSystemLogStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type IntegrationSystemLogStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogStddevSampleAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  integrationId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
};

export type IntegrationSystemLogSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
};


export type IntegrationSystemLogSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogSumAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type IntegrationSystemLogVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type IntegrationSystemLogVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogVariancePopulationAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

export type IntegrationSystemLogVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type IntegrationSystemLogVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type IntegrationSystemLogVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogVarianceSampleAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


export type IntegrationSystemLogVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};

/** A connection to a list of `IntegrationSystemLog` values. */
export type IntegrationSystemLogsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<IntegrationSystemLogAggregates>;
  /** A list of edges which contains the `IntegrationSystemLog` and cursor to aid in pagination. */
  edges: Array<IntegrationSystemLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<IntegrationSystemLogAggregates>>;
  /** A list of `IntegrationSystemLog` objects. */
  nodes: Array<Maybe<IntegrationSystemLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IntegrationSystemLog` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `IntegrationSystemLog` values. */
export type IntegrationSystemLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationSystemLogGroupBy>;
  having: InputMaybe<IntegrationSystemLogHavingInput>;
};

/** A `IntegrationSystemLog` edge in the connection. */
export type IntegrationSystemLogsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `IntegrationSystemLog` at the end of the edge. */
  node: Maybe<IntegrationSystemLog>;
};

/** Methods to use when ordering `IntegrationSystemLog`. */
export enum IntegrationSystemLogsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  ThreadAsc = 'THREAD_ASC',
  ThreadDesc = 'THREAD_DESC'
}

/** A filter to be used against many `IntegrationSystemLog` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManyIntegrationSystemLogFilter = {
  /** Aggregates across related `IntegrationSystemLog` match the filter criteria. */
  aggregates: InputMaybe<IntegrationSystemLogAggregatesFilter>;
  /** Every related `IntegrationSystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<IntegrationSystemLogFilter>;
  /** No related `IntegrationSystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<IntegrationSystemLogFilter>;
  /** Some related `IntegrationSystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<IntegrationSystemLogFilter>;
};

/** A filter to be used against many `System` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManySystemFilter = {
  /** Aggregates across related `System` match the filter criteria. */
  aggregates: InputMaybe<SystemAggregatesFilter>;
  /** Every related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemFilter>;
  /** No related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemFilter>;
  /** Some related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemFilter>;
};

/** A filter to be used against many `SystemProfileConfig` object types. All fields are combined with a logical ‘and.’ */
export type IntegrationToManySystemProfileConfigFilter = {
  /** Aggregates across related `SystemProfileConfig` match the filter criteria. */
  aggregates: InputMaybe<SystemProfileConfigAggregatesFilter>;
  /** Every related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemProfileConfigFilter>;
  /** No related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemProfileConfigFilter>;
  /** Some related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemProfileConfigFilter>;
};

export type IntegrationVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type IntegrationVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Integration`. */
export enum IntegrationsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationSystemLogsConnectionArrayAggCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionArrayAggCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionArrayAggIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_ID_ASC',
  IntegrationSystemLogsConnectionArrayAggIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_ID_DESC',
  IntegrationSystemLogsConnectionArrayAggIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionArrayAggIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionArrayAggLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_LABEL_ASC',
  IntegrationSystemLogsConnectionArrayAggLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_LABEL_DESC',
  IntegrationSystemLogsConnectionArrayAggMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_MESSAGE_ASC',
  IntegrationSystemLogsConnectionArrayAggMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_MESSAGE_DESC',
  IntegrationSystemLogsConnectionArrayAggStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  IntegrationSystemLogsConnectionArrayAggStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  IntegrationSystemLogsConnectionArrayAggSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionArrayAggSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionArrayAggThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_THREAD_ASC',
  IntegrationSystemLogsConnectionArrayAggThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_ARRAY_AGG_THREAD_DESC',
  IntegrationSystemLogsConnectionAverageCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionAverageCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionAverageIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_ID_ASC',
  IntegrationSystemLogsConnectionAverageIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_ID_DESC',
  IntegrationSystemLogsConnectionAverageIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionAverageIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionAverageLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_LABEL_ASC',
  IntegrationSystemLogsConnectionAverageLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_LABEL_DESC',
  IntegrationSystemLogsConnectionAverageMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_MESSAGE_ASC',
  IntegrationSystemLogsConnectionAverageMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_MESSAGE_DESC',
  IntegrationSystemLogsConnectionAverageStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_STATUS_ASC',
  IntegrationSystemLogsConnectionAverageStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_STATUS_DESC',
  IntegrationSystemLogsConnectionAverageSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionAverageSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionAverageThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_THREAD_ASC',
  IntegrationSystemLogsConnectionAverageThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_AVERAGE_THREAD_DESC',
  IntegrationSystemLogsConnectionCountAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_COUNT_ASC',
  IntegrationSystemLogsConnectionCountDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_COUNT_DESC',
  IntegrationSystemLogsConnectionDistinctCountCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionDistinctCountCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionDistinctCountIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  IntegrationSystemLogsConnectionDistinctCountIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  IntegrationSystemLogsConnectionDistinctCountIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionDistinctCountIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionDistinctCountLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_LABEL_ASC',
  IntegrationSystemLogsConnectionDistinctCountLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_LABEL_DESC',
  IntegrationSystemLogsConnectionDistinctCountMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_MESSAGE_ASC',
  IntegrationSystemLogsConnectionDistinctCountMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_MESSAGE_DESC',
  IntegrationSystemLogsConnectionDistinctCountStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  IntegrationSystemLogsConnectionDistinctCountStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  IntegrationSystemLogsConnectionDistinctCountSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionDistinctCountSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionDistinctCountThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_THREAD_ASC',
  IntegrationSystemLogsConnectionDistinctCountThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_DISTINCT_COUNT_THREAD_DESC',
  IntegrationSystemLogsConnectionMaxCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionMaxCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionMaxIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_ID_ASC',
  IntegrationSystemLogsConnectionMaxIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_ID_DESC',
  IntegrationSystemLogsConnectionMaxIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionMaxIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionMaxLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_LABEL_ASC',
  IntegrationSystemLogsConnectionMaxLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_LABEL_DESC',
  IntegrationSystemLogsConnectionMaxMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_MESSAGE_ASC',
  IntegrationSystemLogsConnectionMaxMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_MESSAGE_DESC',
  IntegrationSystemLogsConnectionMaxStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_STATUS_ASC',
  IntegrationSystemLogsConnectionMaxStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_STATUS_DESC',
  IntegrationSystemLogsConnectionMaxSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionMaxSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionMaxThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_THREAD_ASC',
  IntegrationSystemLogsConnectionMaxThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MAX_THREAD_DESC',
  IntegrationSystemLogsConnectionMinCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionMinCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionMinIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_ID_ASC',
  IntegrationSystemLogsConnectionMinIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_ID_DESC',
  IntegrationSystemLogsConnectionMinIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionMinIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionMinLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_LABEL_ASC',
  IntegrationSystemLogsConnectionMinLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_LABEL_DESC',
  IntegrationSystemLogsConnectionMinMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_MESSAGE_ASC',
  IntegrationSystemLogsConnectionMinMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_MESSAGE_DESC',
  IntegrationSystemLogsConnectionMinStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_STATUS_ASC',
  IntegrationSystemLogsConnectionMinStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_STATUS_DESC',
  IntegrationSystemLogsConnectionMinSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionMinSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionMinThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_THREAD_ASC',
  IntegrationSystemLogsConnectionMinThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_MIN_THREAD_DESC',
  IntegrationSystemLogsConnectionStddevPopulationCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionStddevPopulationCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionStddevPopulationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  IntegrationSystemLogsConnectionStddevPopulationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  IntegrationSystemLogsConnectionStddevPopulationIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionStddevPopulationIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionStddevPopulationLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_LABEL_ASC',
  IntegrationSystemLogsConnectionStddevPopulationLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_LABEL_DESC',
  IntegrationSystemLogsConnectionStddevPopulationMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_MESSAGE_ASC',
  IntegrationSystemLogsConnectionStddevPopulationMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_MESSAGE_DESC',
  IntegrationSystemLogsConnectionStddevPopulationStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  IntegrationSystemLogsConnectionStddevPopulationStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  IntegrationSystemLogsConnectionStddevPopulationSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionStddevPopulationSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionStddevPopulationThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_THREAD_ASC',
  IntegrationSystemLogsConnectionStddevPopulationThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_POPULATION_THREAD_DESC',
  IntegrationSystemLogsConnectionStddevSampleCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionStddevSampleCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionStddevSampleIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  IntegrationSystemLogsConnectionStddevSampleIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  IntegrationSystemLogsConnectionStddevSampleIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionStddevSampleIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionStddevSampleLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_LABEL_ASC',
  IntegrationSystemLogsConnectionStddevSampleLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_LABEL_DESC',
  IntegrationSystemLogsConnectionStddevSampleMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_MESSAGE_ASC',
  IntegrationSystemLogsConnectionStddevSampleMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_MESSAGE_DESC',
  IntegrationSystemLogsConnectionStddevSampleStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  IntegrationSystemLogsConnectionStddevSampleStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  IntegrationSystemLogsConnectionStddevSampleSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionStddevSampleSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionStddevSampleThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_THREAD_ASC',
  IntegrationSystemLogsConnectionStddevSampleThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_STDDEV_SAMPLE_THREAD_DESC',
  IntegrationSystemLogsConnectionSumCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionSumCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionSumIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_ID_ASC',
  IntegrationSystemLogsConnectionSumIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_ID_DESC',
  IntegrationSystemLogsConnectionSumIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionSumIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionSumLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_LABEL_ASC',
  IntegrationSystemLogsConnectionSumLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_LABEL_DESC',
  IntegrationSystemLogsConnectionSumMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_MESSAGE_ASC',
  IntegrationSystemLogsConnectionSumMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_MESSAGE_DESC',
  IntegrationSystemLogsConnectionSumStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_STATUS_ASC',
  IntegrationSystemLogsConnectionSumStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_STATUS_DESC',
  IntegrationSystemLogsConnectionSumSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionSumSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionSumThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_THREAD_ASC',
  IntegrationSystemLogsConnectionSumThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_SUM_THREAD_DESC',
  IntegrationSystemLogsConnectionVariancePopulationCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionVariancePopulationCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionVariancePopulationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  IntegrationSystemLogsConnectionVariancePopulationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  IntegrationSystemLogsConnectionVariancePopulationIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionVariancePopulationIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionVariancePopulationLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_LABEL_ASC',
  IntegrationSystemLogsConnectionVariancePopulationLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_LABEL_DESC',
  IntegrationSystemLogsConnectionVariancePopulationMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_MESSAGE_ASC',
  IntegrationSystemLogsConnectionVariancePopulationMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_MESSAGE_DESC',
  IntegrationSystemLogsConnectionVariancePopulationStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  IntegrationSystemLogsConnectionVariancePopulationStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  IntegrationSystemLogsConnectionVariancePopulationSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionVariancePopulationSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionVariancePopulationThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_THREAD_ASC',
  IntegrationSystemLogsConnectionVariancePopulationThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_POPULATION_THREAD_DESC',
  IntegrationSystemLogsConnectionVarianceSampleCreatedAtAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IntegrationSystemLogsConnectionVarianceSampleCreatedAtDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IntegrationSystemLogsConnectionVarianceSampleIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  IntegrationSystemLogsConnectionVarianceSampleIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  IntegrationSystemLogsConnectionVarianceSampleIntegrationIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationSystemLogsConnectionVarianceSampleIntegrationIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationSystemLogsConnectionVarianceSampleLabelAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_LABEL_ASC',
  IntegrationSystemLogsConnectionVarianceSampleLabelDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_LABEL_DESC',
  IntegrationSystemLogsConnectionVarianceSampleMessageAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_MESSAGE_ASC',
  IntegrationSystemLogsConnectionVarianceSampleMessageDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_MESSAGE_DESC',
  IntegrationSystemLogsConnectionVarianceSampleStatusAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  IntegrationSystemLogsConnectionVarianceSampleStatusDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  IntegrationSystemLogsConnectionVarianceSampleSystemIdAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  IntegrationSystemLogsConnectionVarianceSampleSystemIdDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  IntegrationSystemLogsConnectionVarianceSampleThreadAsc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_THREAD_ASC',
  IntegrationSystemLogsConnectionVarianceSampleThreadDesc = 'INTEGRATION_SYSTEM_LOGS_CONNECTION_VARIANCE_SAMPLE_THREAD_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderConfigAsc = 'PROVIDER_CONFIG_ASC',
  ProviderConfigDesc = 'PROVIDER_CONFIG_DESC',
  ProviderDesc = 'PROVIDER_DESC',
  SystemsConnectionArrayAggAddressAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  SystemsConnectionArrayAggAddressCityAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_CITY_ASC',
  SystemsConnectionArrayAggAddressCityDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_CITY_DESC',
  SystemsConnectionArrayAggAddressDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  SystemsConnectionArrayAggAddressForcedAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_ASC',
  SystemsConnectionArrayAggAddressForcedDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_DESC',
  SystemsConnectionArrayAggAddressNormalizedAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionArrayAggAddressNormalizedDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionArrayAggAddressStateAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STATE_ASC',
  SystemsConnectionArrayAggAddressStateDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STATE_DESC',
  SystemsConnectionArrayAggAddressStreetAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STREET_ASC',
  SystemsConnectionArrayAggAddressStreetDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STREET_DESC',
  SystemsConnectionArrayAggAddressZipAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_ASC',
  SystemsConnectionArrayAggAddressZipDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_DESC',
  SystemsConnectionArrayAggClientTypeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CLIENT_TYPE_ASC',
  SystemsConnectionArrayAggClientTypeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CLIENT_TYPE_DESC',
  SystemsConnectionArrayAggConnectionTypeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_ASC',
  SystemsConnectionArrayAggConnectionTypeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_DESC',
  SystemsConnectionArrayAggCreatedAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemsConnectionArrayAggCreatedAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemsConnectionArrayAggIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemsConnectionArrayAggIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemsConnectionArrayAggInstalledAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INSTALLED_AT_ASC',
  SystemsConnectionArrayAggInstalledAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INSTALLED_AT_DESC',
  SystemsConnectionArrayAggIntegrationIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  SystemsConnectionArrayAggIntegrationIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  SystemsConnectionArrayAggLastReportAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_ASC',
  SystemsConnectionArrayAggLastReportAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_DESC',
  SystemsConnectionArrayAggMonitoredAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_MONITORED_ASC',
  SystemsConnectionArrayAggMonitoredDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_MONITORED_DESC',
  SystemsConnectionArrayAggNameAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NAME_ASC',
  SystemsConnectionArrayAggNameDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NAME_DESC',
  SystemsConnectionArrayAggNotesAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NOTES_ASC',
  SystemsConnectionArrayAggNotesDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NOTES_DESC',
  SystemsConnectionArrayAggNumberAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NUMBER_ASC',
  SystemsConnectionArrayAggNumberDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NUMBER_DESC',
  SystemsConnectionArrayAggOperationalAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_ASC',
  SystemsConnectionArrayAggOperationalAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_DESC',
  SystemsConnectionArrayAggOperationStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATION_STATUS_ASC',
  SystemsConnectionArrayAggOperationStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATION_STATUS_DESC',
  SystemsConnectionArrayAggProfileIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemsConnectionArrayAggProfileIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemsConnectionArrayAggProfileOverridesAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_ASC',
  SystemsConnectionArrayAggProfileOverridesDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_DESC',
  SystemsConnectionArrayAggProjectIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SystemsConnectionArrayAggProjectIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SystemsConnectionArrayAggProviderIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_ID_ASC',
  SystemsConnectionArrayAggProviderIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_ID_DESC',
  SystemsConnectionArrayAggProviderStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_ASC',
  SystemsConnectionArrayAggProviderStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_DESC',
  SystemsConnectionArrayAggSizeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_SIZE_ASC',
  SystemsConnectionArrayAggSizeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_SIZE_DESC',
  SystemsConnectionArrayAggStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SystemsConnectionArrayAggStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SystemsConnectionArrayAggTimezoneAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_TIMEZONE_ASC',
  SystemsConnectionArrayAggTimezoneDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_TIMEZONE_DESC',
  SystemsConnectionArrayAggUuidAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_UUID_ASC',
  SystemsConnectionArrayAggUuidDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_UUID_DESC',
  SystemsConnectionAverageAddressAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ASC',
  SystemsConnectionAverageAddressCityAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_CITY_ASC',
  SystemsConnectionAverageAddressCityDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_CITY_DESC',
  SystemsConnectionAverageAddressDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_DESC',
  SystemsConnectionAverageAddressForcedAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_FORCED_ASC',
  SystemsConnectionAverageAddressForcedDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_FORCED_DESC',
  SystemsConnectionAverageAddressNormalizedAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionAverageAddressNormalizedDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionAverageAddressStateAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STATE_ASC',
  SystemsConnectionAverageAddressStateDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STATE_DESC',
  SystemsConnectionAverageAddressStreetAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STREET_ASC',
  SystemsConnectionAverageAddressStreetDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STREET_DESC',
  SystemsConnectionAverageAddressZipAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ZIP_ASC',
  SystemsConnectionAverageAddressZipDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ZIP_DESC',
  SystemsConnectionAverageClientTypeAsc = 'SYSTEMS_CONNECTION_AVERAGE_CLIENT_TYPE_ASC',
  SystemsConnectionAverageClientTypeDesc = 'SYSTEMS_CONNECTION_AVERAGE_CLIENT_TYPE_DESC',
  SystemsConnectionAverageConnectionTypeAsc = 'SYSTEMS_CONNECTION_AVERAGE_CONNECTION_TYPE_ASC',
  SystemsConnectionAverageConnectionTypeDesc = 'SYSTEMS_CONNECTION_AVERAGE_CONNECTION_TYPE_DESC',
  SystemsConnectionAverageCreatedAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemsConnectionAverageCreatedAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemsConnectionAverageIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_ID_ASC',
  SystemsConnectionAverageIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_ID_DESC',
  SystemsConnectionAverageInstalledAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_INSTALLED_AT_ASC',
  SystemsConnectionAverageInstalledAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_INSTALLED_AT_DESC',
  SystemsConnectionAverageIntegrationIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  SystemsConnectionAverageIntegrationIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  SystemsConnectionAverageLastReportAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_LAST_REPORT_AT_ASC',
  SystemsConnectionAverageLastReportAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_LAST_REPORT_AT_DESC',
  SystemsConnectionAverageMonitoredAsc = 'SYSTEMS_CONNECTION_AVERAGE_MONITORED_ASC',
  SystemsConnectionAverageMonitoredDesc = 'SYSTEMS_CONNECTION_AVERAGE_MONITORED_DESC',
  SystemsConnectionAverageNameAsc = 'SYSTEMS_CONNECTION_AVERAGE_NAME_ASC',
  SystemsConnectionAverageNameDesc = 'SYSTEMS_CONNECTION_AVERAGE_NAME_DESC',
  SystemsConnectionAverageNotesAsc = 'SYSTEMS_CONNECTION_AVERAGE_NOTES_ASC',
  SystemsConnectionAverageNotesDesc = 'SYSTEMS_CONNECTION_AVERAGE_NOTES_DESC',
  SystemsConnectionAverageNumberAsc = 'SYSTEMS_CONNECTION_AVERAGE_NUMBER_ASC',
  SystemsConnectionAverageNumberDesc = 'SYSTEMS_CONNECTION_AVERAGE_NUMBER_DESC',
  SystemsConnectionAverageOperationalAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATIONAL_AT_ASC',
  SystemsConnectionAverageOperationalAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATIONAL_AT_DESC',
  SystemsConnectionAverageOperationStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATION_STATUS_ASC',
  SystemsConnectionAverageOperationStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATION_STATUS_DESC',
  SystemsConnectionAverageProfileIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemsConnectionAverageProfileIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemsConnectionAverageProfileOverridesAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionAverageProfileOverridesDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionAverageProjectIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SystemsConnectionAverageProjectIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SystemsConnectionAverageProviderIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_ID_ASC',
  SystemsConnectionAverageProviderIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_ID_DESC',
  SystemsConnectionAverageProviderStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_STATUS_ASC',
  SystemsConnectionAverageProviderStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_STATUS_DESC',
  SystemsConnectionAverageSizeAsc = 'SYSTEMS_CONNECTION_AVERAGE_SIZE_ASC',
  SystemsConnectionAverageSizeDesc = 'SYSTEMS_CONNECTION_AVERAGE_SIZE_DESC',
  SystemsConnectionAverageStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_STATUS_ASC',
  SystemsConnectionAverageStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_STATUS_DESC',
  SystemsConnectionAverageTimezoneAsc = 'SYSTEMS_CONNECTION_AVERAGE_TIMEZONE_ASC',
  SystemsConnectionAverageTimezoneDesc = 'SYSTEMS_CONNECTION_AVERAGE_TIMEZONE_DESC',
  SystemsConnectionAverageUuidAsc = 'SYSTEMS_CONNECTION_AVERAGE_UUID_ASC',
  SystemsConnectionAverageUuidDesc = 'SYSTEMS_CONNECTION_AVERAGE_UUID_DESC',
  SystemsConnectionCountAsc = 'SYSTEMS_CONNECTION_COUNT_ASC',
  SystemsConnectionCountDesc = 'SYSTEMS_CONNECTION_COUNT_DESC',
  SystemsConnectionDistinctCountAddressAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  SystemsConnectionDistinctCountAddressCityAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_ASC',
  SystemsConnectionDistinctCountAddressCityDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_DESC',
  SystemsConnectionDistinctCountAddressDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  SystemsConnectionDistinctCountAddressForcedAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_ASC',
  SystemsConnectionDistinctCountAddressForcedDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_DESC',
  SystemsConnectionDistinctCountAddressNormalizedAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionDistinctCountAddressNormalizedDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionDistinctCountAddressStateAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_ASC',
  SystemsConnectionDistinctCountAddressStateDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_DESC',
  SystemsConnectionDistinctCountAddressStreetAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_ASC',
  SystemsConnectionDistinctCountAddressStreetDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_DESC',
  SystemsConnectionDistinctCountAddressZipAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_ASC',
  SystemsConnectionDistinctCountAddressZipDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_DESC',
  SystemsConnectionDistinctCountClientTypeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_ASC',
  SystemsConnectionDistinctCountClientTypeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_DESC',
  SystemsConnectionDistinctCountConnectionTypeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_ASC',
  SystemsConnectionDistinctCountConnectionTypeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_DESC',
  SystemsConnectionDistinctCountCreatedAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemsConnectionDistinctCountCreatedAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemsConnectionDistinctCountIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemsConnectionDistinctCountIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemsConnectionDistinctCountInstalledAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_ASC',
  SystemsConnectionDistinctCountInstalledAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_DESC',
  SystemsConnectionDistinctCountIntegrationIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  SystemsConnectionDistinctCountIntegrationIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  SystemsConnectionDistinctCountLastReportAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_ASC',
  SystemsConnectionDistinctCountLastReportAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_DESC',
  SystemsConnectionDistinctCountMonitoredAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_MONITORED_ASC',
  SystemsConnectionDistinctCountMonitoredDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_MONITORED_DESC',
  SystemsConnectionDistinctCountNameAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  SystemsConnectionDistinctCountNameDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  SystemsConnectionDistinctCountNotesAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  SystemsConnectionDistinctCountNotesDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  SystemsConnectionDistinctCountNumberAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NUMBER_ASC',
  SystemsConnectionDistinctCountNumberDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NUMBER_DESC',
  SystemsConnectionDistinctCountOperationalAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_ASC',
  SystemsConnectionDistinctCountOperationalAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_DESC',
  SystemsConnectionDistinctCountOperationStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_ASC',
  SystemsConnectionDistinctCountOperationStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_DESC',
  SystemsConnectionDistinctCountProfileIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemsConnectionDistinctCountProfileIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemsConnectionDistinctCountProfileOverridesAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_ASC',
  SystemsConnectionDistinctCountProfileOverridesDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_DESC',
  SystemsConnectionDistinctCountProjectIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SystemsConnectionDistinctCountProjectIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SystemsConnectionDistinctCountProviderIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_ASC',
  SystemsConnectionDistinctCountProviderIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_DESC',
  SystemsConnectionDistinctCountProviderStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_ASC',
  SystemsConnectionDistinctCountProviderStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_DESC',
  SystemsConnectionDistinctCountSizeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_SIZE_ASC',
  SystemsConnectionDistinctCountSizeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_SIZE_DESC',
  SystemsConnectionDistinctCountStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SystemsConnectionDistinctCountStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SystemsConnectionDistinctCountTimezoneAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_TIMEZONE_ASC',
  SystemsConnectionDistinctCountTimezoneDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_TIMEZONE_DESC',
  SystemsConnectionDistinctCountUuidAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  SystemsConnectionDistinctCountUuidDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  SystemsConnectionMaxAddressAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ASC',
  SystemsConnectionMaxAddressCityAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_CITY_ASC',
  SystemsConnectionMaxAddressCityDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_CITY_DESC',
  SystemsConnectionMaxAddressDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_DESC',
  SystemsConnectionMaxAddressForcedAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_FORCED_ASC',
  SystemsConnectionMaxAddressForcedDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_FORCED_DESC',
  SystemsConnectionMaxAddressNormalizedAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionMaxAddressNormalizedDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionMaxAddressStateAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STATE_ASC',
  SystemsConnectionMaxAddressStateDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STATE_DESC',
  SystemsConnectionMaxAddressStreetAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STREET_ASC',
  SystemsConnectionMaxAddressStreetDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STREET_DESC',
  SystemsConnectionMaxAddressZipAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ZIP_ASC',
  SystemsConnectionMaxAddressZipDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ZIP_DESC',
  SystemsConnectionMaxClientTypeAsc = 'SYSTEMS_CONNECTION_MAX_CLIENT_TYPE_ASC',
  SystemsConnectionMaxClientTypeDesc = 'SYSTEMS_CONNECTION_MAX_CLIENT_TYPE_DESC',
  SystemsConnectionMaxConnectionTypeAsc = 'SYSTEMS_CONNECTION_MAX_CONNECTION_TYPE_ASC',
  SystemsConnectionMaxConnectionTypeDesc = 'SYSTEMS_CONNECTION_MAX_CONNECTION_TYPE_DESC',
  SystemsConnectionMaxCreatedAtAsc = 'SYSTEMS_CONNECTION_MAX_CREATED_AT_ASC',
  SystemsConnectionMaxCreatedAtDesc = 'SYSTEMS_CONNECTION_MAX_CREATED_AT_DESC',
  SystemsConnectionMaxIdAsc = 'SYSTEMS_CONNECTION_MAX_ID_ASC',
  SystemsConnectionMaxIdDesc = 'SYSTEMS_CONNECTION_MAX_ID_DESC',
  SystemsConnectionMaxInstalledAtAsc = 'SYSTEMS_CONNECTION_MAX_INSTALLED_AT_ASC',
  SystemsConnectionMaxInstalledAtDesc = 'SYSTEMS_CONNECTION_MAX_INSTALLED_AT_DESC',
  SystemsConnectionMaxIntegrationIdAsc = 'SYSTEMS_CONNECTION_MAX_INTEGRATION_ID_ASC',
  SystemsConnectionMaxIntegrationIdDesc = 'SYSTEMS_CONNECTION_MAX_INTEGRATION_ID_DESC',
  SystemsConnectionMaxLastReportAtAsc = 'SYSTEMS_CONNECTION_MAX_LAST_REPORT_AT_ASC',
  SystemsConnectionMaxLastReportAtDesc = 'SYSTEMS_CONNECTION_MAX_LAST_REPORT_AT_DESC',
  SystemsConnectionMaxMonitoredAsc = 'SYSTEMS_CONNECTION_MAX_MONITORED_ASC',
  SystemsConnectionMaxMonitoredDesc = 'SYSTEMS_CONNECTION_MAX_MONITORED_DESC',
  SystemsConnectionMaxNameAsc = 'SYSTEMS_CONNECTION_MAX_NAME_ASC',
  SystemsConnectionMaxNameDesc = 'SYSTEMS_CONNECTION_MAX_NAME_DESC',
  SystemsConnectionMaxNotesAsc = 'SYSTEMS_CONNECTION_MAX_NOTES_ASC',
  SystemsConnectionMaxNotesDesc = 'SYSTEMS_CONNECTION_MAX_NOTES_DESC',
  SystemsConnectionMaxNumberAsc = 'SYSTEMS_CONNECTION_MAX_NUMBER_ASC',
  SystemsConnectionMaxNumberDesc = 'SYSTEMS_CONNECTION_MAX_NUMBER_DESC',
  SystemsConnectionMaxOperationalAtAsc = 'SYSTEMS_CONNECTION_MAX_OPERATIONAL_AT_ASC',
  SystemsConnectionMaxOperationalAtDesc = 'SYSTEMS_CONNECTION_MAX_OPERATIONAL_AT_DESC',
  SystemsConnectionMaxOperationStatusAsc = 'SYSTEMS_CONNECTION_MAX_OPERATION_STATUS_ASC',
  SystemsConnectionMaxOperationStatusDesc = 'SYSTEMS_CONNECTION_MAX_OPERATION_STATUS_DESC',
  SystemsConnectionMaxProfileIdAsc = 'SYSTEMS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemsConnectionMaxProfileIdDesc = 'SYSTEMS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemsConnectionMaxProfileOverridesAsc = 'SYSTEMS_CONNECTION_MAX_PROFILE_OVERRIDES_ASC',
  SystemsConnectionMaxProfileOverridesDesc = 'SYSTEMS_CONNECTION_MAX_PROFILE_OVERRIDES_DESC',
  SystemsConnectionMaxProjectIdAsc = 'SYSTEMS_CONNECTION_MAX_PROJECT_ID_ASC',
  SystemsConnectionMaxProjectIdDesc = 'SYSTEMS_CONNECTION_MAX_PROJECT_ID_DESC',
  SystemsConnectionMaxProviderIdAsc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_ID_ASC',
  SystemsConnectionMaxProviderIdDesc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_ID_DESC',
  SystemsConnectionMaxProviderStatusAsc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_STATUS_ASC',
  SystemsConnectionMaxProviderStatusDesc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_STATUS_DESC',
  SystemsConnectionMaxSizeAsc = 'SYSTEMS_CONNECTION_MAX_SIZE_ASC',
  SystemsConnectionMaxSizeDesc = 'SYSTEMS_CONNECTION_MAX_SIZE_DESC',
  SystemsConnectionMaxStatusAsc = 'SYSTEMS_CONNECTION_MAX_STATUS_ASC',
  SystemsConnectionMaxStatusDesc = 'SYSTEMS_CONNECTION_MAX_STATUS_DESC',
  SystemsConnectionMaxTimezoneAsc = 'SYSTEMS_CONNECTION_MAX_TIMEZONE_ASC',
  SystemsConnectionMaxTimezoneDesc = 'SYSTEMS_CONNECTION_MAX_TIMEZONE_DESC',
  SystemsConnectionMaxUuidAsc = 'SYSTEMS_CONNECTION_MAX_UUID_ASC',
  SystemsConnectionMaxUuidDesc = 'SYSTEMS_CONNECTION_MAX_UUID_DESC',
  SystemsConnectionMinAddressAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ASC',
  SystemsConnectionMinAddressCityAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_CITY_ASC',
  SystemsConnectionMinAddressCityDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_CITY_DESC',
  SystemsConnectionMinAddressDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_DESC',
  SystemsConnectionMinAddressForcedAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_FORCED_ASC',
  SystemsConnectionMinAddressForcedDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_FORCED_DESC',
  SystemsConnectionMinAddressNormalizedAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionMinAddressNormalizedDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionMinAddressStateAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STATE_ASC',
  SystemsConnectionMinAddressStateDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STATE_DESC',
  SystemsConnectionMinAddressStreetAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STREET_ASC',
  SystemsConnectionMinAddressStreetDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STREET_DESC',
  SystemsConnectionMinAddressZipAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ZIP_ASC',
  SystemsConnectionMinAddressZipDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ZIP_DESC',
  SystemsConnectionMinClientTypeAsc = 'SYSTEMS_CONNECTION_MIN_CLIENT_TYPE_ASC',
  SystemsConnectionMinClientTypeDesc = 'SYSTEMS_CONNECTION_MIN_CLIENT_TYPE_DESC',
  SystemsConnectionMinConnectionTypeAsc = 'SYSTEMS_CONNECTION_MIN_CONNECTION_TYPE_ASC',
  SystemsConnectionMinConnectionTypeDesc = 'SYSTEMS_CONNECTION_MIN_CONNECTION_TYPE_DESC',
  SystemsConnectionMinCreatedAtAsc = 'SYSTEMS_CONNECTION_MIN_CREATED_AT_ASC',
  SystemsConnectionMinCreatedAtDesc = 'SYSTEMS_CONNECTION_MIN_CREATED_AT_DESC',
  SystemsConnectionMinIdAsc = 'SYSTEMS_CONNECTION_MIN_ID_ASC',
  SystemsConnectionMinIdDesc = 'SYSTEMS_CONNECTION_MIN_ID_DESC',
  SystemsConnectionMinInstalledAtAsc = 'SYSTEMS_CONNECTION_MIN_INSTALLED_AT_ASC',
  SystemsConnectionMinInstalledAtDesc = 'SYSTEMS_CONNECTION_MIN_INSTALLED_AT_DESC',
  SystemsConnectionMinIntegrationIdAsc = 'SYSTEMS_CONNECTION_MIN_INTEGRATION_ID_ASC',
  SystemsConnectionMinIntegrationIdDesc = 'SYSTEMS_CONNECTION_MIN_INTEGRATION_ID_DESC',
  SystemsConnectionMinLastReportAtAsc = 'SYSTEMS_CONNECTION_MIN_LAST_REPORT_AT_ASC',
  SystemsConnectionMinLastReportAtDesc = 'SYSTEMS_CONNECTION_MIN_LAST_REPORT_AT_DESC',
  SystemsConnectionMinMonitoredAsc = 'SYSTEMS_CONNECTION_MIN_MONITORED_ASC',
  SystemsConnectionMinMonitoredDesc = 'SYSTEMS_CONNECTION_MIN_MONITORED_DESC',
  SystemsConnectionMinNameAsc = 'SYSTEMS_CONNECTION_MIN_NAME_ASC',
  SystemsConnectionMinNameDesc = 'SYSTEMS_CONNECTION_MIN_NAME_DESC',
  SystemsConnectionMinNotesAsc = 'SYSTEMS_CONNECTION_MIN_NOTES_ASC',
  SystemsConnectionMinNotesDesc = 'SYSTEMS_CONNECTION_MIN_NOTES_DESC',
  SystemsConnectionMinNumberAsc = 'SYSTEMS_CONNECTION_MIN_NUMBER_ASC',
  SystemsConnectionMinNumberDesc = 'SYSTEMS_CONNECTION_MIN_NUMBER_DESC',
  SystemsConnectionMinOperationalAtAsc = 'SYSTEMS_CONNECTION_MIN_OPERATIONAL_AT_ASC',
  SystemsConnectionMinOperationalAtDesc = 'SYSTEMS_CONNECTION_MIN_OPERATIONAL_AT_DESC',
  SystemsConnectionMinOperationStatusAsc = 'SYSTEMS_CONNECTION_MIN_OPERATION_STATUS_ASC',
  SystemsConnectionMinOperationStatusDesc = 'SYSTEMS_CONNECTION_MIN_OPERATION_STATUS_DESC',
  SystemsConnectionMinProfileIdAsc = 'SYSTEMS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemsConnectionMinProfileIdDesc = 'SYSTEMS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemsConnectionMinProfileOverridesAsc = 'SYSTEMS_CONNECTION_MIN_PROFILE_OVERRIDES_ASC',
  SystemsConnectionMinProfileOverridesDesc = 'SYSTEMS_CONNECTION_MIN_PROFILE_OVERRIDES_DESC',
  SystemsConnectionMinProjectIdAsc = 'SYSTEMS_CONNECTION_MIN_PROJECT_ID_ASC',
  SystemsConnectionMinProjectIdDesc = 'SYSTEMS_CONNECTION_MIN_PROJECT_ID_DESC',
  SystemsConnectionMinProviderIdAsc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_ID_ASC',
  SystemsConnectionMinProviderIdDesc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_ID_DESC',
  SystemsConnectionMinProviderStatusAsc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_STATUS_ASC',
  SystemsConnectionMinProviderStatusDesc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_STATUS_DESC',
  SystemsConnectionMinSizeAsc = 'SYSTEMS_CONNECTION_MIN_SIZE_ASC',
  SystemsConnectionMinSizeDesc = 'SYSTEMS_CONNECTION_MIN_SIZE_DESC',
  SystemsConnectionMinStatusAsc = 'SYSTEMS_CONNECTION_MIN_STATUS_ASC',
  SystemsConnectionMinStatusDesc = 'SYSTEMS_CONNECTION_MIN_STATUS_DESC',
  SystemsConnectionMinTimezoneAsc = 'SYSTEMS_CONNECTION_MIN_TIMEZONE_ASC',
  SystemsConnectionMinTimezoneDesc = 'SYSTEMS_CONNECTION_MIN_TIMEZONE_DESC',
  SystemsConnectionMinUuidAsc = 'SYSTEMS_CONNECTION_MIN_UUID_ASC',
  SystemsConnectionMinUuidDesc = 'SYSTEMS_CONNECTION_MIN_UUID_DESC',
  SystemsConnectionStddevPopulationAddressAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  SystemsConnectionStddevPopulationAddressCityAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_ASC',
  SystemsConnectionStddevPopulationAddressCityDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_DESC',
  SystemsConnectionStddevPopulationAddressDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  SystemsConnectionStddevPopulationAddressForcedAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_ASC',
  SystemsConnectionStddevPopulationAddressForcedDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_DESC',
  SystemsConnectionStddevPopulationAddressNormalizedAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionStddevPopulationAddressNormalizedDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionStddevPopulationAddressStateAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_ASC',
  SystemsConnectionStddevPopulationAddressStateDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_DESC',
  SystemsConnectionStddevPopulationAddressStreetAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_ASC',
  SystemsConnectionStddevPopulationAddressStreetDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_DESC',
  SystemsConnectionStddevPopulationAddressZipAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_ASC',
  SystemsConnectionStddevPopulationAddressZipDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_DESC',
  SystemsConnectionStddevPopulationClientTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_ASC',
  SystemsConnectionStddevPopulationClientTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_DESC',
  SystemsConnectionStddevPopulationConnectionTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_ASC',
  SystemsConnectionStddevPopulationConnectionTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_DESC',
  SystemsConnectionStddevPopulationCreatedAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemsConnectionStddevPopulationCreatedAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemsConnectionStddevPopulationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemsConnectionStddevPopulationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemsConnectionStddevPopulationInstalledAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_ASC',
  SystemsConnectionStddevPopulationInstalledAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_DESC',
  SystemsConnectionStddevPopulationIntegrationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  SystemsConnectionStddevPopulationIntegrationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  SystemsConnectionStddevPopulationLastReportAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_ASC',
  SystemsConnectionStddevPopulationLastReportAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_DESC',
  SystemsConnectionStddevPopulationMonitoredAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_MONITORED_ASC',
  SystemsConnectionStddevPopulationMonitoredDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_MONITORED_DESC',
  SystemsConnectionStddevPopulationNameAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  SystemsConnectionStddevPopulationNameDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  SystemsConnectionStddevPopulationNotesAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  SystemsConnectionStddevPopulationNotesDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  SystemsConnectionStddevPopulationNumberAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NUMBER_ASC',
  SystemsConnectionStddevPopulationNumberDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NUMBER_DESC',
  SystemsConnectionStddevPopulationOperationalAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_ASC',
  SystemsConnectionStddevPopulationOperationalAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_DESC',
  SystemsConnectionStddevPopulationOperationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_ASC',
  SystemsConnectionStddevPopulationOperationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_DESC',
  SystemsConnectionStddevPopulationProfileIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemsConnectionStddevPopulationProfileIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemsConnectionStddevPopulationProfileOverridesAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsConnectionStddevPopulationProfileOverridesDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsConnectionStddevPopulationProjectIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SystemsConnectionStddevPopulationProjectIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SystemsConnectionStddevPopulationProviderIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_ASC',
  SystemsConnectionStddevPopulationProviderIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_DESC',
  SystemsConnectionStddevPopulationProviderStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_ASC',
  SystemsConnectionStddevPopulationProviderStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_DESC',
  SystemsConnectionStddevPopulationSizeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_SIZE_ASC',
  SystemsConnectionStddevPopulationSizeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_SIZE_DESC',
  SystemsConnectionStddevPopulationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SystemsConnectionStddevPopulationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SystemsConnectionStddevPopulationTimezoneAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_TIMEZONE_ASC',
  SystemsConnectionStddevPopulationTimezoneDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_TIMEZONE_DESC',
  SystemsConnectionStddevPopulationUuidAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  SystemsConnectionStddevPopulationUuidDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  SystemsConnectionStddevSampleAddressAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  SystemsConnectionStddevSampleAddressCityAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_ASC',
  SystemsConnectionStddevSampleAddressCityDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_DESC',
  SystemsConnectionStddevSampleAddressDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  SystemsConnectionStddevSampleAddressForcedAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsConnectionStddevSampleAddressForcedDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsConnectionStddevSampleAddressNormalizedAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionStddevSampleAddressNormalizedDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionStddevSampleAddressStateAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_ASC',
  SystemsConnectionStddevSampleAddressStateDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_DESC',
  SystemsConnectionStddevSampleAddressStreetAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_ASC',
  SystemsConnectionStddevSampleAddressStreetDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_DESC',
  SystemsConnectionStddevSampleAddressZipAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsConnectionStddevSampleAddressZipDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsConnectionStddevSampleClientTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_ASC',
  SystemsConnectionStddevSampleClientTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_DESC',
  SystemsConnectionStddevSampleConnectionTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsConnectionStddevSampleConnectionTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsConnectionStddevSampleCreatedAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemsConnectionStddevSampleCreatedAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemsConnectionStddevSampleIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemsConnectionStddevSampleIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemsConnectionStddevSampleInstalledAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_ASC',
  SystemsConnectionStddevSampleInstalledAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_DESC',
  SystemsConnectionStddevSampleIntegrationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  SystemsConnectionStddevSampleIntegrationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  SystemsConnectionStddevSampleLastReportAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsConnectionStddevSampleLastReportAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsConnectionStddevSampleMonitoredAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_MONITORED_ASC',
  SystemsConnectionStddevSampleMonitoredDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_MONITORED_DESC',
  SystemsConnectionStddevSampleNameAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  SystemsConnectionStddevSampleNameDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  SystemsConnectionStddevSampleNotesAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  SystemsConnectionStddevSampleNotesDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  SystemsConnectionStddevSampleNumberAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NUMBER_ASC',
  SystemsConnectionStddevSampleNumberDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NUMBER_DESC',
  SystemsConnectionStddevSampleOperationalAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsConnectionStddevSampleOperationalAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsConnectionStddevSampleOperationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_ASC',
  SystemsConnectionStddevSampleOperationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_DESC',
  SystemsConnectionStddevSampleProfileIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemsConnectionStddevSampleProfileIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemsConnectionStddevSampleProfileOverridesAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionStddevSampleProfileOverridesDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionStddevSampleProjectIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SystemsConnectionStddevSampleProjectIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SystemsConnectionStddevSampleProviderIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_ASC',
  SystemsConnectionStddevSampleProviderIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_DESC',
  SystemsConnectionStddevSampleProviderStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsConnectionStddevSampleProviderStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsConnectionStddevSampleSizeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_SIZE_ASC',
  SystemsConnectionStddevSampleSizeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_SIZE_DESC',
  SystemsConnectionStddevSampleStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SystemsConnectionStddevSampleStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SystemsConnectionStddevSampleTimezoneAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_TIMEZONE_ASC',
  SystemsConnectionStddevSampleTimezoneDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_TIMEZONE_DESC',
  SystemsConnectionStddevSampleUuidAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  SystemsConnectionStddevSampleUuidDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  SystemsConnectionSumAddressAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ASC',
  SystemsConnectionSumAddressCityAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_CITY_ASC',
  SystemsConnectionSumAddressCityDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_CITY_DESC',
  SystemsConnectionSumAddressDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_DESC',
  SystemsConnectionSumAddressForcedAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_FORCED_ASC',
  SystemsConnectionSumAddressForcedDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_FORCED_DESC',
  SystemsConnectionSumAddressNormalizedAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionSumAddressNormalizedDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionSumAddressStateAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STATE_ASC',
  SystemsConnectionSumAddressStateDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STATE_DESC',
  SystemsConnectionSumAddressStreetAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STREET_ASC',
  SystemsConnectionSumAddressStreetDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STREET_DESC',
  SystemsConnectionSumAddressZipAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ZIP_ASC',
  SystemsConnectionSumAddressZipDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ZIP_DESC',
  SystemsConnectionSumClientTypeAsc = 'SYSTEMS_CONNECTION_SUM_CLIENT_TYPE_ASC',
  SystemsConnectionSumClientTypeDesc = 'SYSTEMS_CONNECTION_SUM_CLIENT_TYPE_DESC',
  SystemsConnectionSumConnectionTypeAsc = 'SYSTEMS_CONNECTION_SUM_CONNECTION_TYPE_ASC',
  SystemsConnectionSumConnectionTypeDesc = 'SYSTEMS_CONNECTION_SUM_CONNECTION_TYPE_DESC',
  SystemsConnectionSumCreatedAtAsc = 'SYSTEMS_CONNECTION_SUM_CREATED_AT_ASC',
  SystemsConnectionSumCreatedAtDesc = 'SYSTEMS_CONNECTION_SUM_CREATED_AT_DESC',
  SystemsConnectionSumIdAsc = 'SYSTEMS_CONNECTION_SUM_ID_ASC',
  SystemsConnectionSumIdDesc = 'SYSTEMS_CONNECTION_SUM_ID_DESC',
  SystemsConnectionSumInstalledAtAsc = 'SYSTEMS_CONNECTION_SUM_INSTALLED_AT_ASC',
  SystemsConnectionSumInstalledAtDesc = 'SYSTEMS_CONNECTION_SUM_INSTALLED_AT_DESC',
  SystemsConnectionSumIntegrationIdAsc = 'SYSTEMS_CONNECTION_SUM_INTEGRATION_ID_ASC',
  SystemsConnectionSumIntegrationIdDesc = 'SYSTEMS_CONNECTION_SUM_INTEGRATION_ID_DESC',
  SystemsConnectionSumLastReportAtAsc = 'SYSTEMS_CONNECTION_SUM_LAST_REPORT_AT_ASC',
  SystemsConnectionSumLastReportAtDesc = 'SYSTEMS_CONNECTION_SUM_LAST_REPORT_AT_DESC',
  SystemsConnectionSumMonitoredAsc = 'SYSTEMS_CONNECTION_SUM_MONITORED_ASC',
  SystemsConnectionSumMonitoredDesc = 'SYSTEMS_CONNECTION_SUM_MONITORED_DESC',
  SystemsConnectionSumNameAsc = 'SYSTEMS_CONNECTION_SUM_NAME_ASC',
  SystemsConnectionSumNameDesc = 'SYSTEMS_CONNECTION_SUM_NAME_DESC',
  SystemsConnectionSumNotesAsc = 'SYSTEMS_CONNECTION_SUM_NOTES_ASC',
  SystemsConnectionSumNotesDesc = 'SYSTEMS_CONNECTION_SUM_NOTES_DESC',
  SystemsConnectionSumNumberAsc = 'SYSTEMS_CONNECTION_SUM_NUMBER_ASC',
  SystemsConnectionSumNumberDesc = 'SYSTEMS_CONNECTION_SUM_NUMBER_DESC',
  SystemsConnectionSumOperationalAtAsc = 'SYSTEMS_CONNECTION_SUM_OPERATIONAL_AT_ASC',
  SystemsConnectionSumOperationalAtDesc = 'SYSTEMS_CONNECTION_SUM_OPERATIONAL_AT_DESC',
  SystemsConnectionSumOperationStatusAsc = 'SYSTEMS_CONNECTION_SUM_OPERATION_STATUS_ASC',
  SystemsConnectionSumOperationStatusDesc = 'SYSTEMS_CONNECTION_SUM_OPERATION_STATUS_DESC',
  SystemsConnectionSumProfileIdAsc = 'SYSTEMS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemsConnectionSumProfileIdDesc = 'SYSTEMS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemsConnectionSumProfileOverridesAsc = 'SYSTEMS_CONNECTION_SUM_PROFILE_OVERRIDES_ASC',
  SystemsConnectionSumProfileOverridesDesc = 'SYSTEMS_CONNECTION_SUM_PROFILE_OVERRIDES_DESC',
  SystemsConnectionSumProjectIdAsc = 'SYSTEMS_CONNECTION_SUM_PROJECT_ID_ASC',
  SystemsConnectionSumProjectIdDesc = 'SYSTEMS_CONNECTION_SUM_PROJECT_ID_DESC',
  SystemsConnectionSumProviderIdAsc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_ID_ASC',
  SystemsConnectionSumProviderIdDesc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_ID_DESC',
  SystemsConnectionSumProviderStatusAsc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_STATUS_ASC',
  SystemsConnectionSumProviderStatusDesc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_STATUS_DESC',
  SystemsConnectionSumSizeAsc = 'SYSTEMS_CONNECTION_SUM_SIZE_ASC',
  SystemsConnectionSumSizeDesc = 'SYSTEMS_CONNECTION_SUM_SIZE_DESC',
  SystemsConnectionSumStatusAsc = 'SYSTEMS_CONNECTION_SUM_STATUS_ASC',
  SystemsConnectionSumStatusDesc = 'SYSTEMS_CONNECTION_SUM_STATUS_DESC',
  SystemsConnectionSumTimezoneAsc = 'SYSTEMS_CONNECTION_SUM_TIMEZONE_ASC',
  SystemsConnectionSumTimezoneDesc = 'SYSTEMS_CONNECTION_SUM_TIMEZONE_DESC',
  SystemsConnectionSumUuidAsc = 'SYSTEMS_CONNECTION_SUM_UUID_ASC',
  SystemsConnectionSumUuidDesc = 'SYSTEMS_CONNECTION_SUM_UUID_DESC',
  SystemsConnectionVariancePopulationAddressAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  SystemsConnectionVariancePopulationAddressCityAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_ASC',
  SystemsConnectionVariancePopulationAddressCityDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_DESC',
  SystemsConnectionVariancePopulationAddressDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  SystemsConnectionVariancePopulationAddressForcedAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_ASC',
  SystemsConnectionVariancePopulationAddressForcedDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_DESC',
  SystemsConnectionVariancePopulationAddressNormalizedAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionVariancePopulationAddressNormalizedDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionVariancePopulationAddressStateAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_ASC',
  SystemsConnectionVariancePopulationAddressStateDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_DESC',
  SystemsConnectionVariancePopulationAddressStreetAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_ASC',
  SystemsConnectionVariancePopulationAddressStreetDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_DESC',
  SystemsConnectionVariancePopulationAddressZipAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_ASC',
  SystemsConnectionVariancePopulationAddressZipDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_DESC',
  SystemsConnectionVariancePopulationClientTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_ASC',
  SystemsConnectionVariancePopulationClientTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_DESC',
  SystemsConnectionVariancePopulationConnectionTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_ASC',
  SystemsConnectionVariancePopulationConnectionTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_DESC',
  SystemsConnectionVariancePopulationCreatedAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemsConnectionVariancePopulationCreatedAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemsConnectionVariancePopulationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemsConnectionVariancePopulationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemsConnectionVariancePopulationInstalledAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_ASC',
  SystemsConnectionVariancePopulationInstalledAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_DESC',
  SystemsConnectionVariancePopulationIntegrationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  SystemsConnectionVariancePopulationIntegrationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  SystemsConnectionVariancePopulationLastReportAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_ASC',
  SystemsConnectionVariancePopulationLastReportAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_DESC',
  SystemsConnectionVariancePopulationMonitoredAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_MONITORED_ASC',
  SystemsConnectionVariancePopulationMonitoredDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_MONITORED_DESC',
  SystemsConnectionVariancePopulationNameAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  SystemsConnectionVariancePopulationNameDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  SystemsConnectionVariancePopulationNotesAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  SystemsConnectionVariancePopulationNotesDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  SystemsConnectionVariancePopulationNumberAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NUMBER_ASC',
  SystemsConnectionVariancePopulationNumberDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NUMBER_DESC',
  SystemsConnectionVariancePopulationOperationalAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_ASC',
  SystemsConnectionVariancePopulationOperationalAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_DESC',
  SystemsConnectionVariancePopulationOperationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_ASC',
  SystemsConnectionVariancePopulationOperationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_DESC',
  SystemsConnectionVariancePopulationProfileIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemsConnectionVariancePopulationProfileIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemsConnectionVariancePopulationProfileOverridesAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsConnectionVariancePopulationProfileOverridesDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsConnectionVariancePopulationProjectIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SystemsConnectionVariancePopulationProjectIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SystemsConnectionVariancePopulationProviderIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_ASC',
  SystemsConnectionVariancePopulationProviderIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_DESC',
  SystemsConnectionVariancePopulationProviderStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_ASC',
  SystemsConnectionVariancePopulationProviderStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_DESC',
  SystemsConnectionVariancePopulationSizeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_SIZE_ASC',
  SystemsConnectionVariancePopulationSizeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_SIZE_DESC',
  SystemsConnectionVariancePopulationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SystemsConnectionVariancePopulationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SystemsConnectionVariancePopulationTimezoneAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_TIMEZONE_ASC',
  SystemsConnectionVariancePopulationTimezoneDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_TIMEZONE_DESC',
  SystemsConnectionVariancePopulationUuidAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  SystemsConnectionVariancePopulationUuidDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  SystemsConnectionVarianceSampleAddressAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  SystemsConnectionVarianceSampleAddressCityAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_ASC',
  SystemsConnectionVarianceSampleAddressCityDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_DESC',
  SystemsConnectionVarianceSampleAddressDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  SystemsConnectionVarianceSampleAddressForcedAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsConnectionVarianceSampleAddressForcedDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsConnectionVarianceSampleAddressNormalizedAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionVarianceSampleAddressNormalizedDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionVarianceSampleAddressStateAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_ASC',
  SystemsConnectionVarianceSampleAddressStateDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_DESC',
  SystemsConnectionVarianceSampleAddressStreetAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_ASC',
  SystemsConnectionVarianceSampleAddressStreetDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_DESC',
  SystemsConnectionVarianceSampleAddressZipAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsConnectionVarianceSampleAddressZipDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsConnectionVarianceSampleClientTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_ASC',
  SystemsConnectionVarianceSampleClientTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_DESC',
  SystemsConnectionVarianceSampleConnectionTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsConnectionVarianceSampleConnectionTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsConnectionVarianceSampleCreatedAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemsConnectionVarianceSampleCreatedAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemsConnectionVarianceSampleIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemsConnectionVarianceSampleIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemsConnectionVarianceSampleInstalledAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_ASC',
  SystemsConnectionVarianceSampleInstalledAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_DESC',
  SystemsConnectionVarianceSampleIntegrationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  SystemsConnectionVarianceSampleIntegrationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  SystemsConnectionVarianceSampleLastReportAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsConnectionVarianceSampleLastReportAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsConnectionVarianceSampleMonitoredAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_MONITORED_ASC',
  SystemsConnectionVarianceSampleMonitoredDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_MONITORED_DESC',
  SystemsConnectionVarianceSampleNameAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  SystemsConnectionVarianceSampleNameDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  SystemsConnectionVarianceSampleNotesAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  SystemsConnectionVarianceSampleNotesDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  SystemsConnectionVarianceSampleNumberAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NUMBER_ASC',
  SystemsConnectionVarianceSampleNumberDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NUMBER_DESC',
  SystemsConnectionVarianceSampleOperationalAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsConnectionVarianceSampleOperationalAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsConnectionVarianceSampleOperationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_ASC',
  SystemsConnectionVarianceSampleOperationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_DESC',
  SystemsConnectionVarianceSampleProfileIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemsConnectionVarianceSampleProfileIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  SystemsConnectionVarianceSampleProfileOverridesAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionVarianceSampleProfileOverridesDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionVarianceSampleProjectIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SystemsConnectionVarianceSampleProjectIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SystemsConnectionVarianceSampleProviderIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_ASC',
  SystemsConnectionVarianceSampleProviderIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_DESC',
  SystemsConnectionVarianceSampleProviderStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsConnectionVarianceSampleProviderStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsConnectionVarianceSampleSizeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_SIZE_ASC',
  SystemsConnectionVarianceSampleSizeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_SIZE_DESC',
  SystemsConnectionVarianceSampleStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SystemsConnectionVarianceSampleStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SystemsConnectionVarianceSampleTimezoneAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_ASC',
  SystemsConnectionVarianceSampleTimezoneDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_DESC',
  SystemsConnectionVarianceSampleUuidAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  SystemsConnectionVarianceSampleUuidDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  SystemProfileConfigsConnectionArrayAggEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  SystemProfileConfigsConnectionArrayAggEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  SystemProfileConfigsConnectionArrayAggFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_FREQUENCY_ASC',
  SystemProfileConfigsConnectionArrayAggFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_FREQUENCY_DESC',
  SystemProfileConfigsConnectionArrayAggIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionArrayAggIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionArrayAggMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_METRICS_ASC',
  SystemProfileConfigsConnectionArrayAggMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_METRICS_DESC',
  SystemProfileConfigsConnectionArrayAggProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionArrayAggProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionAverageEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_ENABLED_ASC',
  SystemProfileConfigsConnectionAverageEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_ENABLED_DESC',
  SystemProfileConfigsConnectionAverageFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionAverageFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionAverageIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionAverageIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionAverageMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_METRICS_ASC',
  SystemProfileConfigsConnectionAverageMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_METRICS_DESC',
  SystemProfileConfigsConnectionAverageProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionAverageProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionCountAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_COUNT_ASC',
  SystemProfileConfigsConnectionCountDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_COUNT_DESC',
  SystemProfileConfigsConnectionDistinctCountEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  SystemProfileConfigsConnectionDistinctCountEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  SystemProfileConfigsConnectionDistinctCountFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_FREQUENCY_ASC',
  SystemProfileConfigsConnectionDistinctCountFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_FREQUENCY_DESC',
  SystemProfileConfigsConnectionDistinctCountIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionDistinctCountIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionDistinctCountMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_METRICS_ASC',
  SystemProfileConfigsConnectionDistinctCountMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_METRICS_DESC',
  SystemProfileConfigsConnectionDistinctCountProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionDistinctCountProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionMaxEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_ENABLED_ASC',
  SystemProfileConfigsConnectionMaxEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_ENABLED_DESC',
  SystemProfileConfigsConnectionMaxFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_FREQUENCY_ASC',
  SystemProfileConfigsConnectionMaxFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_FREQUENCY_DESC',
  SystemProfileConfigsConnectionMaxIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionMaxIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionMaxMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_METRICS_ASC',
  SystemProfileConfigsConnectionMaxMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_METRICS_DESC',
  SystemProfileConfigsConnectionMaxProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionMaxProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionMinEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_ENABLED_ASC',
  SystemProfileConfigsConnectionMinEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_ENABLED_DESC',
  SystemProfileConfigsConnectionMinFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_FREQUENCY_ASC',
  SystemProfileConfigsConnectionMinFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_FREQUENCY_DESC',
  SystemProfileConfigsConnectionMinIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionMinIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionMinMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_METRICS_ASC',
  SystemProfileConfigsConnectionMinMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_METRICS_DESC',
  SystemProfileConfigsConnectionMinProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionMinProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionStddevPopulationEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  SystemProfileConfigsConnectionStddevPopulationEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  SystemProfileConfigsConnectionStddevPopulationFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_FREQUENCY_ASC',
  SystemProfileConfigsConnectionStddevPopulationFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_FREQUENCY_DESC',
  SystemProfileConfigsConnectionStddevPopulationIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionStddevPopulationIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionStddevPopulationMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_METRICS_ASC',
  SystemProfileConfigsConnectionStddevPopulationMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_METRICS_DESC',
  SystemProfileConfigsConnectionStddevPopulationProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionStddevPopulationProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionStddevSampleEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  SystemProfileConfigsConnectionStddevSampleEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  SystemProfileConfigsConnectionStddevSampleFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionStddevSampleFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionStddevSampleIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionStddevSampleIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionStddevSampleMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_METRICS_ASC',
  SystemProfileConfigsConnectionStddevSampleMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_METRICS_DESC',
  SystemProfileConfigsConnectionStddevSampleProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionStddevSampleProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionSumEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_ENABLED_ASC',
  SystemProfileConfigsConnectionSumEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_ENABLED_DESC',
  SystemProfileConfigsConnectionSumFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_FREQUENCY_ASC',
  SystemProfileConfigsConnectionSumFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_FREQUENCY_DESC',
  SystemProfileConfigsConnectionSumIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionSumIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionSumMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_METRICS_ASC',
  SystemProfileConfigsConnectionSumMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_METRICS_DESC',
  SystemProfileConfigsConnectionSumProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionSumProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionVariancePopulationEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  SystemProfileConfigsConnectionVariancePopulationEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  SystemProfileConfigsConnectionVariancePopulationFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_FREQUENCY_ASC',
  SystemProfileConfigsConnectionVariancePopulationFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_FREQUENCY_DESC',
  SystemProfileConfigsConnectionVariancePopulationIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionVariancePopulationIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionVariancePopulationMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_METRICS_ASC',
  SystemProfileConfigsConnectionVariancePopulationMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_METRICS_DESC',
  SystemProfileConfigsConnectionVariancePopulationProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionVariancePopulationProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionVarianceSampleEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  SystemProfileConfigsConnectionVarianceSampleEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  SystemProfileConfigsConnectionVarianceSampleFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionVarianceSampleFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionVarianceSampleIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionVarianceSampleIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionVarianceSampleMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_METRICS_ASC',
  SystemProfileConfigsConnectionVarianceSampleMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_METRICS_DESC',
  SystemProfileConfigsConnectionVarianceSampleProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionVarianceSampleProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  /** A quantity of days. */
  days: Maybe<Scalars['Int']['output']>;
  /** A quantity of hours. */
  hours: Maybe<Scalars['Int']['output']>;
  /** A quantity of minutes. */
  minutes: Maybe<Scalars['Int']['output']>;
  /** A quantity of months. */
  months: Maybe<Scalars['Int']['output']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds: Maybe<Scalars['Float']['output']>;
  /** A quantity of years. */
  years: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of hours. */
  hours: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of minutes. */
  minutes: InputMaybe<Scalars['Int']['input']>;
  /** A quantity of months. */
  months: InputMaybe<Scalars['Int']['input']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds: InputMaybe<Scalars['Float']['input']>;
  /** A quantity of years. */
  years: InputMaybe<Scalars['Int']['input']>;
};

export type Invoice = Node & {
  amount: Maybe<Scalars['BigFloat']['output']>;
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `Invoice`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['Int']['output'];
  isBill: Maybe<Scalars['Boolean']['output']>;
  issueDate: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `Invoice`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  status: Maybe<InvoiceStatus>;
  title: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};

export type InvoiceAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<InvoiceArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<InvoiceAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<InvoiceDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<InvoiceMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<InvoiceMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<InvoiceStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<InvoiceStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<InvoiceSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<InvoiceVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<InvoiceVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Invoice` object types. */
export type InvoiceAggregatesFilter = {
  /** Array aggregation aggregate over matching `Invoice` objects. */
  arrayAgg: InputMaybe<InvoiceArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Invoice` objects. */
  average: InputMaybe<InvoiceAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Invoice` objects. */
  distinctCount: InputMaybe<InvoiceDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Invoice` object to be included within the aggregate. */
  filter: InputMaybe<InvoiceFilter>;
  /** Maximum aggregate over matching `Invoice` objects. */
  max: InputMaybe<InvoiceMaxAggregateFilter>;
  /** Minimum aggregate over matching `Invoice` objects. */
  min: InputMaybe<InvoiceMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Invoice` objects. */
  stddevPopulation: InputMaybe<InvoiceStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Invoice` objects. */
  stddevSample: InputMaybe<InvoiceStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Invoice` objects. */
  sum: InputMaybe<InvoiceSumAggregateFilter>;
  /** Population variance aggregate over matching `Invoice` objects. */
  variancePopulation: InputMaybe<InvoiceVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Invoice` objects. */
  varianceSample: InputMaybe<InvoiceVarianceSampleAggregateFilter>;
};

export type InvoiceArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isBill: InputMaybe<BooleanListFilter>;
  projectId: InputMaybe<IntListFilter>;
  title: InputMaybe<StringListFilter>;
  uid: InputMaybe<IntListFilter>;
};

export type InvoiceArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isBill across the matching connection */
  isBill: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of title across the matching connection */
  title: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
};


export type InvoiceArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesIsBillArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceAverageAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type InvoiceAverageAggregates = {
  /** Mean average of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type InvoiceAverageAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

/** A condition to be used against `Invoice` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type InvoiceCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `amountPaid` field. */
  amountPaid: InputMaybe<Scalars['BigFloat']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isBill` field. */
  isBill: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `issueDate` field. */
  issueDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<InvoiceStatus>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type InvoiceDistinctCountAggregateFilter = {
  amount: InputMaybe<BigIntFilter>;
  amountPaid: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  dueDate: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isBill: InputMaybe<BigIntFilter>;
  issueDate: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type InvoiceDistinctCountAggregates = {
  /** Distinct count of amount across the matching connection */
  amount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dueDate across the matching connection */
  dueDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isBill across the matching connection */
  isBill: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of issueDate across the matching connection */
  issueDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type InvoiceDistinctCountAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesIsBillArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesIssueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

/** A filter to be used against `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceFilter = {
  /** Filter by the object’s `amount` field. */
  amount: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `amountPaid` field. */
  amountPaid: InputMaybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<InvoiceFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isBill` field. */
  isBill: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `issueDate` field. */
  issueDate: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not: InputMaybe<InvoiceFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<InvoiceFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<InvoiceStatusFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Invoice` for usage during aggregation. */
export enum InvoiceGroupBy {
  Amount = 'AMOUNT',
  AmountPaid = 'AMOUNT_PAID',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  DueDate = 'DUE_DATE',
  DueDateTruncatedToDay = 'DUE_DATE_TRUNCATED_TO_DAY',
  DueDateTruncatedToHour = 'DUE_DATE_TRUNCATED_TO_HOUR',
  DueDateTruncatedToMonth = 'DUE_DATE_TRUNCATED_TO_MONTH',
  DueDateTruncatedToQuarter = 'DUE_DATE_TRUNCATED_TO_QUARTER',
  DueDateTruncatedToWeek = 'DUE_DATE_TRUNCATED_TO_WEEK',
  DueDateTruncatedToYear = 'DUE_DATE_TRUNCATED_TO_YEAR',
  IssueDate = 'ISSUE_DATE',
  IssueDateTruncatedToDay = 'ISSUE_DATE_TRUNCATED_TO_DAY',
  IssueDateTruncatedToHour = 'ISSUE_DATE_TRUNCATED_TO_HOUR',
  IssueDateTruncatedToMonth = 'ISSUE_DATE_TRUNCATED_TO_MONTH',
  IssueDateTruncatedToQuarter = 'ISSUE_DATE_TRUNCATED_TO_QUARTER',
  IssueDateTruncatedToWeek = 'ISSUE_DATE_TRUNCATED_TO_WEEK',
  IssueDateTruncatedToYear = 'ISSUE_DATE_TRUNCATED_TO_YEAR',
  IsBill = 'IS_BILL',
  ProjectId = 'PROJECT_ID',
  Status = 'STATUS',
  Title = 'TITLE',
  Uid = 'UID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type InvoiceHavingArrayAggInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingAverageInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingDistinctCountInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Invoice` aggregates. */
export type InvoiceHavingInput = {
  AND: InputMaybe<Array<InvoiceHavingInput>>;
  OR: InputMaybe<Array<InvoiceHavingInput>>;
  arrayAgg: InputMaybe<InvoiceHavingArrayAggInput>;
  average: InputMaybe<InvoiceHavingAverageInput>;
  distinctCount: InputMaybe<InvoiceHavingDistinctCountInput>;
  max: InputMaybe<InvoiceHavingMaxInput>;
  min: InputMaybe<InvoiceHavingMinInput>;
  stddevPopulation: InputMaybe<InvoiceHavingStddevPopulationInput>;
  stddevSample: InputMaybe<InvoiceHavingStddevSampleInput>;
  sum: InputMaybe<InvoiceHavingSumInput>;
  variancePopulation: InputMaybe<InvoiceHavingVariancePopulationInput>;
  varianceSample: InputMaybe<InvoiceHavingVarianceSampleInput>;
};

export type InvoiceHavingMaxInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingMinInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingStddevPopulationInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingStddevSampleInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingSumInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingVariancePopulationInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceHavingVarianceSampleInput = {
  amount: InputMaybe<HavingBigfloatFilter>;
  amountPaid: InputMaybe<HavingBigfloatFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  issueDate: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceMaxAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  issueDate: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type InvoiceMaxAggregates = {
  /** Maximum of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Maximum of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of issueDate across the matching connection */
  issueDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type InvoiceMaxAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesIssueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceMinAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  issueDate: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type InvoiceMinAggregates = {
  /** Minimum of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Minimum of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of issueDate across the matching connection */
  issueDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type InvoiceMinAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesIssueDateArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID'
}

/** A filter to be used against InvoiceStatus fields. All fields are combined with a logical ‘and.’ */
export type InvoiceStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<InvoiceStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<InvoiceStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<InvoiceStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<InvoiceStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<InvoiceStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<InvoiceStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<InvoiceStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<InvoiceStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<InvoiceStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<InvoiceStatus>>;
};

export type InvoiceStddevPopulationAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type InvoiceStddevPopulationAggregates = {
  /** Population standard deviation of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type InvoiceStddevPopulationAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceStddevSampleAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type InvoiceStddevSampleAggregates = {
  /** Sample standard deviation of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type InvoiceStddevSampleAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceSumAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
};

export type InvoiceSumAggregates = {
  /** Sum of amount across the matching connection */
  amount: Scalars['BigFloat']['output'];
  /** Sum of amountPaid across the matching connection */
  amountPaid: Scalars['BigFloat']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
};


export type InvoiceSumAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceVariancePopulationAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type InvoiceVariancePopulationAggregates = {
  /** Population variance of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type InvoiceVariancePopulationAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

export type InvoiceVarianceSampleAggregateFilter = {
  amount: InputMaybe<BigFloatFilter>;
  amountPaid: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type InvoiceVarianceSampleAggregates = {
  /** Sample variance of amount across the matching connection */
  amount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of amountPaid across the matching connection */
  amountPaid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type InvoiceVarianceSampleAggregatesAmountArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesAmountPaidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type InvoiceVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};

/** A connection to a list of `Invoice` values. */
export type InvoicesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<InvoiceAggregates>;
  /** A list of edges which contains the `Invoice` and cursor to aid in pagination. */
  edges: Array<InvoicesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<InvoiceAggregates>>;
  /** A list of `Invoice` objects. */
  nodes: Array<Maybe<Invoice>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Invoice` values. */
export type InvoicesConnectionGroupedAggregatesArgs = {
  groupBy: Array<InvoiceGroupBy>;
  having: InputMaybe<InvoiceHavingInput>;
};

/** A `Invoice` edge in the connection. */
export type InvoicesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Invoice` at the end of the edge. */
  node: Maybe<Invoice>;
};

/** Methods to use when ordering `Invoice`. */
export enum InvoicesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountPaidAsc = 'AMOUNT_PAID_ASC',
  AmountPaidDesc = 'AMOUNT_PAID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DueDateAsc = 'DUE_DATE_ASC',
  DueDateDesc = 'DUE_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IssueDateAsc = 'ISSUE_DATE_ASC',
  IssueDateDesc = 'ISSUE_DATE_DESC',
  IsBillAsc = 'IS_BILL_ASC',
  IsBillDesc = 'IS_BILL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type Job = Node & {
  completedon: Maybe<Scalars['Datetime']['output']>;
  createdon: Scalars['Datetime']['output'];
  data: Maybe<Scalars['JSON']['output']>;
  expirein: Interval;
  id: Scalars['UUID']['output'];
  keepuntil: Scalars['Datetime']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  onComplete: Scalars['Boolean']['output'];
  output: Maybe<Scalars['JSON']['output']>;
  priority: Scalars['Int']['output'];
  retrybackoff: Scalars['Boolean']['output'];
  retrycount: Scalars['Int']['output'];
  retrydelay: Scalars['Int']['output'];
  retrylimit: Scalars['Int']['output'];
  singletonkey: Maybe<Scalars['String']['output']>;
  singletonon: Maybe<Scalars['Datetime']['output']>;
  startafter: Scalars['Datetime']['output'];
  startedon: Maybe<Scalars['Datetime']['output']>;
  state: JobState;
};

/** A condition to be used against `Job` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type JobCondition = {
  /** Checks for equality with the object’s `completedon` field. */
  completedon: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdon` field. */
  createdon: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `data` field. */
  data: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `expirein` field. */
  expirein: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `keepuntil` field. */
  keepuntil: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `onComplete` field. */
  onComplete: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `output` field. */
  output: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retrybackoff` field. */
  retrybackoff: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `retrycount` field. */
  retrycount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retrydelay` field. */
  retrydelay: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `retrylimit` field. */
  retrylimit: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `singletonkey` field. */
  singletonkey: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `singletonon` field. */
  singletonon: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startafter` field. */
  startafter: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startedon` field. */
  startedon: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state: InputMaybe<JobState>;
};

/** A filter to be used against `Job` object types. All fields are combined with a logical ‘and.’ */
export type JobFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `completedon` field. */
  completedon: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdon` field. */
  createdon: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `data` field. */
  data: InputMaybe<JsonFilter>;
  /** Filter by the object’s `expirein` field. */
  expirein: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<UuidFilter>;
  /** Filter by the object’s `keepuntil` field. */
  keepuntil: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<JobFilter>;
  /** Filter by the object’s `onComplete` field. */
  onComplete: InputMaybe<BooleanFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `output` field. */
  output: InputMaybe<JsonFilter>;
  /** Filter by the object’s `priority` field. */
  priority: InputMaybe<IntFilter>;
  /** Filter by the object’s `retrybackoff` field. */
  retrybackoff: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `retrycount` field. */
  retrycount: InputMaybe<IntFilter>;
  /** Filter by the object’s `retrydelay` field. */
  retrydelay: InputMaybe<IntFilter>;
  /** Filter by the object’s `retrylimit` field. */
  retrylimit: InputMaybe<IntFilter>;
  /** Filter by the object’s `singletonkey` field. */
  singletonkey: InputMaybe<StringFilter>;
  /** Filter by the object’s `singletonon` field. */
  singletonon: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startafter` field. */
  startafter: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedon` field. */
  startedon: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `state` field. */
  state: InputMaybe<JobStateFilter>;
};

export enum JobState {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Retry = 'RETRY'
}

/** A filter to be used against JobState fields. All fields are combined with a logical ‘and.’ */
export type JobStateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<JobState>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<JobState>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<JobState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<JobState>;
  /** Included in the specified list. */
  in: InputMaybe<Array<JobState>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<JobState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<JobState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<JobState>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<JobState>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<JobState>>;
};

/** Methods to use when ordering `Job`. */
export enum JobsOrderBy {
  CompletedonAsc = 'COMPLETEDON_ASC',
  CompletedonDesc = 'COMPLETEDON_DESC',
  CreatedonAsc = 'CREATEDON_ASC',
  CreatedonDesc = 'CREATEDON_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  ExpireinAsc = 'EXPIREIN_ASC',
  ExpireinDesc = 'EXPIREIN_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeepuntilAsc = 'KEEPUNTIL_ASC',
  KeepuntilDesc = 'KEEPUNTIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OnCompleteAsc = 'ON_COMPLETE_ASC',
  OnCompleteDesc = 'ON_COMPLETE_DESC',
  OutputAsc = 'OUTPUT_ASC',
  OutputDesc = 'OUTPUT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  RetrybackoffAsc = 'RETRYBACKOFF_ASC',
  RetrybackoffDesc = 'RETRYBACKOFF_DESC',
  RetrycountAsc = 'RETRYCOUNT_ASC',
  RetrycountDesc = 'RETRYCOUNT_DESC',
  RetrydelayAsc = 'RETRYDELAY_ASC',
  RetrydelayDesc = 'RETRYDELAY_DESC',
  RetrylimitAsc = 'RETRYLIMIT_ASC',
  RetrylimitDesc = 'RETRYLIMIT_DESC',
  SingletonkeyAsc = 'SINGLETONKEY_ASC',
  SingletonkeyDesc = 'SINGLETONKEY_DESC',
  SingletononAsc = 'SINGLETONON_ASC',
  SingletononDesc = 'SINGLETONON_DESC',
  StartafterAsc = 'STARTAFTER_ASC',
  StartafterDesc = 'STARTAFTER_DESC',
  StartedonAsc = 'STARTEDON_ASC',
  StartedonDesc = 'STARTEDON_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
}

export type Jurisdiction = Node & {
  address: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectJurisdictionIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportJurisdictionIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectJurisdictionIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportJurisdictionIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `Jurisdiction`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectJurisdictionIdAndCreatedByContactId: JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectJurisdictionIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectJurisdictionIdAndReferrerContactId: JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectJurisdictionIdAndReferrerContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `Jurisdiction`. */
  createdByUser: Maybe<User>;
  emails: Array<Maybe<Scalars['String']['output']>>;
  fees: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  phones: Array<Maybe<Scalars['String']['output']>>;
  pointOfContact: Maybe<Scalars['String']['output']>;
  portal: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportJurisdictionIdAndParentProjectId: JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportJurisdictionIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectJurisdictionIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportJurisdictionIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportJurisdictionIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectJurisdictionIdAndParentProjectId: JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectJurisdictionIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportJurisdictionIdAndProjectId: JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportJurisdictionIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFromJurisdictionId: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByToJurisdictionId: Array<SmsActivity>;
  submissionMethod: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportJurisdictionIdAndTeamId: JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportJurisdictionIdAndTeamIdList: Array<Team>;
  turnaround: Maybe<Scalars['Int']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectJurisdictionIdAndCreatedBy: JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectJurisdictionIdAndOwnerId: JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectJurisdictionIdAndProjectManagerId: JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectJurisdictionIdAndSalesRepId: JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndCreatedBy: JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndOwnerId: JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndProjectManagerId: JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndSalesRepId: JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndUserId: JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportJurisdictionIdAndWorkerValue: JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyConnection;
  uuid: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};


export type JurisdictionBlueprintsByProjectJurisdictionIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type JurisdictionBlueprintsByProjectReportJurisdictionIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type JurisdictionCompaniesByProjectJurisdictionIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type JurisdictionCompaniesByProjectReportJurisdictionIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type JurisdictionProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type JurisdictionProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type JurisdictionProjectStagesByProjectJurisdictionIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type JurisdictionProjectStagesByProjectReportJurisdictionIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type JurisdictionProjectStagesByProjectReportJurisdictionIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type JurisdictionProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type JurisdictionSmsActivitiesByFromJurisdictionIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type JurisdictionSmsActivitiesByToJurisdictionIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type JurisdictionAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<JurisdictionArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<JurisdictionAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<JurisdictionDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<JurisdictionMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<JurisdictionMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<JurisdictionStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<JurisdictionStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<JurisdictionSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<JurisdictionVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<JurisdictionVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Jurisdiction` object types. */
export type JurisdictionAggregatesFilter = {
  /** Array aggregation aggregate over matching `Jurisdiction` objects. */
  arrayAgg: InputMaybe<JurisdictionArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Jurisdiction` objects. */
  average: InputMaybe<JurisdictionAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Jurisdiction` objects. */
  distinctCount: InputMaybe<JurisdictionDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Jurisdiction` object to be included within the aggregate. */
  filter: InputMaybe<JurisdictionFilter>;
  /** Maximum aggregate over matching `Jurisdiction` objects. */
  max: InputMaybe<JurisdictionMaxAggregateFilter>;
  /** Minimum aggregate over matching `Jurisdiction` objects. */
  min: InputMaybe<JurisdictionMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Jurisdiction` objects. */
  stddevPopulation: InputMaybe<JurisdictionStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Jurisdiction` objects. */
  stddevSample: InputMaybe<JurisdictionStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Jurisdiction` objects. */
  sum: InputMaybe<JurisdictionSumAggregateFilter>;
  /** Population variance aggregate over matching `Jurisdiction` objects. */
  variancePopulation: InputMaybe<JurisdictionVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Jurisdiction` objects. */
  varianceSample: InputMaybe<JurisdictionVarianceSampleAggregateFilter>;
};

export type JurisdictionArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  fees: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isPrivate: InputMaybe<BooleanListFilter>;
  name: InputMaybe<StringListFilter>;
  notes: InputMaybe<StringListFilter>;
  pointOfContact: InputMaybe<StringListFilter>;
  portal: InputMaybe<StringListFilter>;
  submissionMethod: InputMaybe<StringListFilter>;
  turnaround: InputMaybe<IntListFilter>;
  type: InputMaybe<StringListFilter>;
  uuid: InputMaybe<StringListFilter>;
  website: InputMaybe<StringListFilter>;
};

export type JurisdictionArrayAggAggregates = {
  /** Array aggregation of address across the matching connection */
  address: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of fees across the matching connection */
  fees: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isPrivate across the matching connection */
  isPrivate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of notes across the matching connection */
  notes: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of pointOfContact across the matching connection */
  pointOfContact: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of portal across the matching connection */
  portal: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of submissionMethod across the matching connection */
  submissionMethod: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of turnaround across the matching connection */
  turnaround: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of uuid across the matching connection */
  uuid: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of website across the matching connection */
  website: Array<Maybe<Scalars['String']['output']>>;
};


export type JurisdictionArrayAggAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesFeesArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesIsPrivateArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesNotesArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesPointOfContactArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesPortalArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesSubmissionMethodArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionArrayAggAggregatesWebsiteArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

export type JurisdictionAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  turnaround: InputMaybe<BigFloatFilter>;
};

export type JurisdictionAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigFloat']['output']>;
};


export type JurisdictionAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionAverageAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

/**
 * A condition to be used against `Jurisdiction` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type JurisdictionCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `emails` field. */
  emails: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `fees` field. */
  fees: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isPrivate` field. */
  isPrivate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phones` field. */
  phones: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `pointOfContact` field. */
  pointOfContact: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `portal` field. */
  portal: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `submissionMethod` field. */
  submissionMethod: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `turnaround` field. */
  turnaround: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `website` field. */
  website: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type JurisdictionContactsByProjectJurisdictionIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type JurisdictionDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  emails: InputMaybe<BigIntFilter>;
  fees: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isPrivate: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  notes: InputMaybe<BigIntFilter>;
  phones: InputMaybe<BigIntFilter>;
  pointOfContact: InputMaybe<BigIntFilter>;
  portal: InputMaybe<BigIntFilter>;
  submissionMethod: InputMaybe<BigIntFilter>;
  turnaround: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
  website: InputMaybe<BigIntFilter>;
};

export type JurisdictionDistinctCountAggregates = {
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emails across the matching connection */
  emails: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of fees across the matching connection */
  fees: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isPrivate across the matching connection */
  isPrivate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of notes across the matching connection */
  notes: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phones across the matching connection */
  phones: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of pointOfContact across the matching connection */
  pointOfContact: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of portal across the matching connection */
  portal: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of submissionMethod across the matching connection */
  submissionMethod: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of website across the matching connection */
  website: Maybe<Scalars['BigInt']['output']>;
};


export type JurisdictionDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesEmailsArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesFeesArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesIsPrivateArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesNotesArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesPhonesArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesPointOfContactArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesPortalArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesSubmissionMethodArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionDistinctCountAggregatesWebsiteArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

/** A filter to be used against `Jurisdiction` object types. All fields are combined with a logical ‘and.’ */
export type JurisdictionFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<JurisdictionFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** Filter by the object’s `emails` field. */
  emails: InputMaybe<StringListFilter>;
  /** Filter by the object’s `fees` field. */
  fees: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPrivate` field. */
  isPrivate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<JurisdictionFilter>;
  /** Filter by the object’s `notes` field. */
  notes: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<JurisdictionFilter>>;
  /** Filter by the object’s `phones` field. */
  phones: InputMaybe<StringListFilter>;
  /** Filter by the object’s `pointOfContact` field. */
  pointOfContact: InputMaybe<StringFilter>;
  /** Filter by the object’s `portal` field. */
  portal: InputMaybe<StringFilter>;
  /** Filter by the object’s `projectReports` relation. */
  projectReports: InputMaybe<JurisdictionToManyProjectReportFilter>;
  /** Some related `projectReports` exist. */
  projectReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projects` relation. */
  projects: InputMaybe<JurisdictionToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByFromJurisdictionId` relation. */
  smsActivitiesByFromJurisdictionId: InputMaybe<JurisdictionToManySmsActivityFilter>;
  /** Some related `smsActivitiesByFromJurisdictionId` exist. */
  smsActivitiesByFromJurisdictionIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByToJurisdictionId` relation. */
  smsActivitiesByToJurisdictionId: InputMaybe<JurisdictionToManySmsActivityFilter>;
  /** Some related `smsActivitiesByToJurisdictionId` exist. */
  smsActivitiesByToJurisdictionIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `submissionMethod` field. */
  submissionMethod: InputMaybe<StringFilter>;
  /** Filter by the object’s `turnaround` field. */
  turnaround: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<StringFilter>;
  /** Filter by the object’s `website` field. */
  website: InputMaybe<StringFilter>;
};

/** Grouping methods for `Jurisdiction` for usage during aggregation. */
export enum JurisdictionGroupBy {
  Address = 'ADDRESS',
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Emails = 'EMAILS',
  Fees = 'FEES',
  IsPrivate = 'IS_PRIVATE',
  Name = 'NAME',
  Notes = 'NOTES',
  Phones = 'PHONES',
  PointOfContact = 'POINT_OF_CONTACT',
  Portal = 'PORTAL',
  SubmissionMethod = 'SUBMISSION_METHOD',
  Turnaround = 'TURNAROUND',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  Uuid = 'UUID',
  Website = 'WEBSITE'
}

export type JurisdictionHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Jurisdiction` aggregates. */
export type JurisdictionHavingInput = {
  AND: InputMaybe<Array<JurisdictionHavingInput>>;
  OR: InputMaybe<Array<JurisdictionHavingInput>>;
  arrayAgg: InputMaybe<JurisdictionHavingArrayAggInput>;
  average: InputMaybe<JurisdictionHavingAverageInput>;
  distinctCount: InputMaybe<JurisdictionHavingDistinctCountInput>;
  max: InputMaybe<JurisdictionHavingMaxInput>;
  min: InputMaybe<JurisdictionHavingMinInput>;
  stddevPopulation: InputMaybe<JurisdictionHavingStddevPopulationInput>;
  stddevSample: InputMaybe<JurisdictionHavingStddevSampleInput>;
  sum: InputMaybe<JurisdictionHavingSumInput>;
  variancePopulation: InputMaybe<JurisdictionHavingVariancePopulationInput>;
  varianceSample: InputMaybe<JurisdictionHavingVarianceSampleInput>;
};

export type JurisdictionHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  turnaround: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type JurisdictionMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  turnaround: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type JurisdictionMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of turnaround across the matching connection */
  turnaround: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type JurisdictionMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMaxAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

export type JurisdictionMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  turnaround: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type JurisdictionMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of turnaround across the matching connection */
  turnaround: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type JurisdictionMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMinAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectReportsByProjectReportJurisdictionIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type JurisdictionProjectsByProjectJurisdictionIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionProjectsByProjectReportJurisdictionIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type JurisdictionStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  turnaround: InputMaybe<BigFloatFilter>;
};

export type JurisdictionStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigFloat']['output']>;
};


export type JurisdictionStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevPopulationAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

export type JurisdictionStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  turnaround: InputMaybe<BigFloatFilter>;
};

export type JurisdictionStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigFloat']['output']>;
};


export type JurisdictionStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionStddevSampleAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

export type JurisdictionSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  turnaround: InputMaybe<BigIntFilter>;
};

export type JurisdictionSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of turnaround across the matching connection */
  turnaround: Scalars['BigInt']['output'];
};


export type JurisdictionSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionSumAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionTeamsByProjectReportJurisdictionIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type JurisdictionToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type JurisdictionToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type JurisdictionToManySmsActivityFilter = {
  /** Aggregates across related `SmsActivity` match the filter criteria. */
  aggregates: InputMaybe<SmsActivityAggregatesFilter>;
  /** Every related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SmsActivityFilter>;
  /** No related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SmsActivityFilter>;
  /** Some related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SmsActivityFilter>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type JurisdictionUsersByProjectJurisdictionIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type JurisdictionUsersByProjectReportJurisdictionIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type JurisdictionVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  turnaround: InputMaybe<BigFloatFilter>;
};

export type JurisdictionVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigFloat']['output']>;
};


export type JurisdictionVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVariancePopulationAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

export type JurisdictionVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  turnaround: InputMaybe<BigFloatFilter>;
};

export type JurisdictionVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of turnaround across the matching connection */
  turnaround: Maybe<Scalars['BigFloat']['output']>;
};


export type JurisdictionVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type JurisdictionVarianceSampleAggregatesTurnaroundArgs = {
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values. */
export type JurisdictionsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction` and cursor to aid in pagination. */
  edges: Array<JurisdictionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values. */
export type JurisdictionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection. */
export type JurisdictionsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
};

/** Methods to use when ordering `Jurisdiction`. */
export enum JurisdictionsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  EmailsAsc = 'EMAILS_ASC',
  EmailsDesc = 'EMAILS_DESC',
  FeesAsc = 'FEES_ASC',
  FeesDesc = 'FEES_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPrivateAsc = 'IS_PRIVATE_ASC',
  IsPrivateDesc = 'IS_PRIVATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PhonesAsc = 'PHONES_ASC',
  PhonesDesc = 'PHONES_DESC',
  PointOfContactAsc = 'POINT_OF_CONTACT_ASC',
  PointOfContactDesc = 'POINT_OF_CONTACT_DESC',
  PortalAsc = 'PORTAL_ASC',
  PortalDesc = 'PORTAL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectsConnectionArrayAggAccountStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsConnectionArrayAggAccountStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsConnectionArrayAggAccountTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsConnectionArrayAggAccountTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsConnectionArrayAggAddressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsConnectionArrayAggAddressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionArrayAggAddressPrettyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsConnectionArrayAggAddressPrettyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsConnectionArrayAggAhjAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsConnectionArrayAggAhjDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsConnectionArrayAggBlueprintIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsConnectionArrayAggBlueprintIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsConnectionArrayAggCityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsConnectionArrayAggCityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsConnectionArrayAggCompanyIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsConnectionArrayAggCompanyIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsConnectionArrayAggCompletionDateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsConnectionArrayAggCompletionDateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsConnectionArrayAggCompletionTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsConnectionArrayAggCompletionTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionArrayAggConfidenceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsConnectionArrayAggConfidenceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsConnectionArrayAggCreatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsConnectionArrayAggCreatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsConnectionArrayAggCreatedByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggCreatedByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsConnectionArrayAggDealSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsConnectionArrayAggDealSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsConnectionArrayAggDealValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsConnectionArrayAggDealValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionArrayAggDescriptionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsConnectionArrayAggDescriptionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsConnectionArrayAggFreshProjectAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsConnectionArrayAggFreshProjectDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsConnectionArrayAggGeoLocationAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsConnectionArrayAggGeoLocationDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionArrayAggIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsConnectionArrayAggIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsConnectionArrayAggImageUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsConnectionArrayAggImageUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsConnectionArrayAggIsActiveAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsConnectionArrayAggIsActiveDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsConnectionArrayAggIsCompletedAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsConnectionArrayAggIsCompletedDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsConnectionArrayAggLastActivityAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsConnectionArrayAggLastActivityDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionArrayAggOverdueByAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsConnectionArrayAggOverdueByDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsConnectionArrayAggOwnerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsConnectionArrayAggOwnerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsConnectionArrayAggParentProjectIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionArrayAggParentProjectIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionArrayAggPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsConnectionArrayAggPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionArrayAggProgressAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsConnectionArrayAggProgressDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionArrayAggProjectSizeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsConnectionArrayAggProjectSizeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsConnectionArrayAggProjectValueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsConnectionArrayAggProjectValueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionArrayAggRequestStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsConnectionArrayAggRequestStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsConnectionArrayAggRevenueAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsConnectionArrayAggRevenueDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsConnectionArrayAggSalesRepIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsConnectionArrayAggSalesRepIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionArrayAggSourceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsConnectionArrayAggSourceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsConnectionArrayAggStageIdAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsConnectionArrayAggStageIdDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsConnectionArrayAggStateAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsConnectionArrayAggStateDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsConnectionArrayAggStatusAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsConnectionArrayAggStatusDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsConnectionArrayAggStreetAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsConnectionArrayAggStreetDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsConnectionArrayAggTitleAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsConnectionArrayAggTitleDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsConnectionArrayAggTotalCostAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsConnectionArrayAggTotalCostDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionArrayAggTotalPriceAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsConnectionArrayAggTotalPriceDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsConnectionArrayAggTradesAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsConnectionArrayAggTradesDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsConnectionArrayAggTypeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsConnectionArrayAggTypeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsConnectionArrayAggUidAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsConnectionArrayAggUidDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsConnectionArrayAggUpdatedAtAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsConnectionArrayAggUpdatedAtDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsConnectionArrayAggZipcodeAsc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsConnectionArrayAggZipcodeDesc = 'PROJECTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsConnectionAverageAccountStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionAverageAccountStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionAverageAccountTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionAverageAccountTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionAverageAddressAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsConnectionAverageAddressDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsConnectionAverageAddressNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionAverageAddressNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionAverageAddressPrettyAsc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionAverageAddressPrettyDesc = 'PROJECTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionAverageAhjAsc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsConnectionAverageAhjDesc = 'PROJECTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsConnectionAverageBlueprintIdAsc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsConnectionAverageBlueprintIdDesc = 'PROJECTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsConnectionAverageCityAsc = 'PROJECTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsConnectionAverageCityDesc = 'PROJECTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsConnectionAverageCompanyIdAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsConnectionAverageCompanyIdDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsConnectionAverageCompletionDateAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsConnectionAverageCompletionDateDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsConnectionAverageCompletionTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsConnectionAverageCompletionTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsConnectionAverageCompletionTimeInSAsc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionAverageCompletionTimeInSDesc = 'PROJECTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionAverageConfidenceAsc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsConnectionAverageConfidenceDesc = 'PROJECTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsConnectionAverageCreatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsConnectionAverageCreatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsConnectionAverageCreatedByAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsConnectionAverageCreatedByContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionAverageCreatedByContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionAverageCreatedByDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsConnectionAverageCreatedByTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionAverageCreatedByTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionAverageDealSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsConnectionAverageDealSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsConnectionAverageDealValueAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsConnectionAverageDealValueDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsConnectionAverageDealValueWeightedAsc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionAverageDealValueWeightedDesc = 'PROJECTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionAverageDescriptionAsc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsConnectionAverageDescriptionDesc = 'PROJECTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsConnectionAverageFreshProjectAsc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsConnectionAverageFreshProjectDesc = 'PROJECTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsConnectionAverageGeoLocationAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsConnectionAverageGeoLocationDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionAverageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectsConnectionAverageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectsConnectionAverageImageUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsConnectionAverageImageUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsConnectionAverageIsActiveAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsConnectionAverageIsActiveDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsConnectionAverageIsCompletedAsc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsConnectionAverageIsCompletedDesc = 'PROJECTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsConnectionAverageJurisdictionIdAsc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsConnectionAverageJurisdictionIdDesc = 'PROJECTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsConnectionAverageLastActivityAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsConnectionAverageLastActivityDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionAverageOverdueByAsc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsConnectionAverageOverdueByDesc = 'PROJECTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsConnectionAverageOwnerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsConnectionAverageOwnerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsConnectionAverageParentProjectIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionAverageParentProjectIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionAveragePhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsConnectionAveragePhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsConnectionAveragePrimaryEmailAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionAveragePrimaryEmailDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionAveragePrimaryPhoneAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsConnectionAveragePrimaryPhoneDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsConnectionAveragePrivilegeAllAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionAveragePrivilegeAllDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionAveragePrivilegeOwnAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionAveragePrivilegeOwnDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionAveragePrivilegeTeamAsc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionAveragePrivilegeTeamDesc = 'PROJECTS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionAverageProgressAsc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsConnectionAverageProgressDesc = 'PROJECTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsConnectionAverageProjectManagerIdAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionAverageProjectManagerIdDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionAverageProjectSizeAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsConnectionAverageProjectSizeDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsConnectionAverageProjectValueAsc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsConnectionAverageProjectValueDesc = 'PROJECTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsConnectionAverageReferrerContactIdAsc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionAverageReferrerContactIdDesc = 'PROJECTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionAverageRequestStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsConnectionAverageRequestStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsConnectionAverageRevenueAsc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsConnectionAverageRevenueDesc = 'PROJECTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsConnectionAverageSalesRepIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsConnectionAverageSalesRepIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionAverageSourceAsc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsConnectionAverageSourceDesc = 'PROJECTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsConnectionAverageStageIdAsc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsConnectionAverageStageIdDesc = 'PROJECTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsConnectionAverageStateAsc = 'PROJECTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsConnectionAverageStateDesc = 'PROJECTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsConnectionAverageStatusAsc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsConnectionAverageStatusDesc = 'PROJECTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsConnectionAverageStreetAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsConnectionAverageStreetDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsConnectionAverageStreetViewUrlAsc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsConnectionAverageStreetViewUrlDesc = 'PROJECTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsConnectionAverageTitleAsc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsConnectionAverageTitleDesc = 'PROJECTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsConnectionAverageTotalCostAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsConnectionAverageTotalCostDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionAverageTotalPriceAsc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsConnectionAverageTotalPriceDesc = 'PROJECTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsConnectionAverageTradesAsc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsConnectionAverageTradesDesc = 'PROJECTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsConnectionAverageTypeAsc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsConnectionAverageTypeDesc = 'PROJECTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsConnectionAverageUidAsc = 'PROJECTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectsConnectionAverageUidDesc = 'PROJECTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectsConnectionAverageUpdatedAtAsc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsConnectionAverageUpdatedAtDesc = 'PROJECTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsConnectionAverageZipcodeAsc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsConnectionAverageZipcodeDesc = 'PROJECTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsConnectionCountAsc = 'PROJECTS_CONNECTION_COUNT_ASC',
  ProjectsConnectionCountDesc = 'PROJECTS_CONNECTION_COUNT_DESC',
  ProjectsConnectionDistinctCountAccountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountAccountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountAccountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountAccountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountAddressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsConnectionDistinctCountAddressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsConnectionDistinctCountAhjAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsConnectionDistinctCountAhjDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsConnectionDistinctCountCityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsConnectionDistinctCountCityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsConnectionDistinctCountCompanyIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsConnectionDistinctCountCompanyIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsConnectionDistinctCountCompletionDateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsConnectionDistinctCountCompletionDateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionDistinctCountConfidenceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsConnectionDistinctCountConfidenceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsConnectionDistinctCountCreatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsConnectionDistinctCountCreatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsConnectionDistinctCountCreatedByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountCreatedByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsConnectionDistinctCountDealSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsConnectionDistinctCountDealSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsConnectionDistinctCountDealValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsConnectionDistinctCountDealValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionDistinctCountDescriptionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsConnectionDistinctCountDescriptionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsConnectionDistinctCountFreshProjectAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsConnectionDistinctCountFreshProjectDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsConnectionDistinctCountGeoLocationAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsConnectionDistinctCountGeoLocationDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionDistinctCountIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsConnectionDistinctCountIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsConnectionDistinctCountImageUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsConnectionDistinctCountImageUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsConnectionDistinctCountIsActiveAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsConnectionDistinctCountIsActiveDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsConnectionDistinctCountIsCompletedAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsConnectionDistinctCountIsCompletedDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsConnectionDistinctCountLastActivityAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsConnectionDistinctCountLastActivityDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionDistinctCountOverdueByAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsConnectionDistinctCountOverdueByDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsConnectionDistinctCountOwnerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsConnectionDistinctCountOwnerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionDistinctCountPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsConnectionDistinctCountPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionDistinctCountProgressAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsConnectionDistinctCountProgressDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionDistinctCountProjectSizeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsConnectionDistinctCountProjectSizeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsConnectionDistinctCountProjectValueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsConnectionDistinctCountProjectValueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionDistinctCountRequestStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsConnectionDistinctCountRequestStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsConnectionDistinctCountRevenueAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsConnectionDistinctCountRevenueDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionDistinctCountSourceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsConnectionDistinctCountSourceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsConnectionDistinctCountStageIdAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsConnectionDistinctCountStageIdDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsConnectionDistinctCountStateAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsConnectionDistinctCountStateDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsConnectionDistinctCountStatusAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsConnectionDistinctCountStatusDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsConnectionDistinctCountStreetAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsConnectionDistinctCountStreetDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsConnectionDistinctCountTitleAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsConnectionDistinctCountTitleDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsConnectionDistinctCountTotalCostAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsConnectionDistinctCountTotalCostDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionDistinctCountTotalPriceAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsConnectionDistinctCountTotalPriceDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsConnectionDistinctCountTradesAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsConnectionDistinctCountTradesDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsConnectionDistinctCountTypeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsConnectionDistinctCountTypeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsConnectionDistinctCountUidAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsConnectionDistinctCountUidDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsConnectionDistinctCountZipcodeAsc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsConnectionDistinctCountZipcodeDesc = 'PROJECTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsConnectionMaxAccountStatusAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMaxAccountStatusDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMaxAccountTypeAsc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMaxAccountTypeDesc = 'PROJECTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMaxAddressAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsConnectionMaxAddressDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsConnectionMaxAddressNormalizedAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMaxAddressNormalizedDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMaxAddressPrettyAsc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMaxAddressPrettyDesc = 'PROJECTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMaxAhjAsc = 'PROJECTS_CONNECTION_MAX_AHJ_ASC',
  ProjectsConnectionMaxAhjDesc = 'PROJECTS_CONNECTION_MAX_AHJ_DESC',
  ProjectsConnectionMaxBlueprintIdAsc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsConnectionMaxBlueprintIdDesc = 'PROJECTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsConnectionMaxCityAsc = 'PROJECTS_CONNECTION_MAX_CITY_ASC',
  ProjectsConnectionMaxCityDesc = 'PROJECTS_CONNECTION_MAX_CITY_DESC',
  ProjectsConnectionMaxCompanyIdAsc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsConnectionMaxCompanyIdDesc = 'PROJECTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsConnectionMaxCompletionDateAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsConnectionMaxCompletionDateDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsConnectionMaxCompletionTimeAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsConnectionMaxCompletionTimeDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsConnectionMaxCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMaxCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMaxConfidenceAsc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsConnectionMaxConfidenceDesc = 'PROJECTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsConnectionMaxCreatedAtAsc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsConnectionMaxCreatedAtDesc = 'PROJECTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsConnectionMaxCreatedByAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsConnectionMaxCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMaxCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMaxCreatedByDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsConnectionMaxCreatedByTypeAsc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMaxCreatedByTypeDesc = 'PROJECTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMaxDealSizeAsc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsConnectionMaxDealSizeDesc = 'PROJECTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsConnectionMaxDealValueAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsConnectionMaxDealValueDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsConnectionMaxDealValueWeightedAsc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMaxDealValueWeightedDesc = 'PROJECTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMaxDescriptionAsc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsConnectionMaxDescriptionDesc = 'PROJECTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsConnectionMaxFreshProjectAsc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsConnectionMaxFreshProjectDesc = 'PROJECTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsConnectionMaxGeoLocationAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsConnectionMaxGeoLocationDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMaxIdAsc = 'PROJECTS_CONNECTION_MAX_ID_ASC',
  ProjectsConnectionMaxIdDesc = 'PROJECTS_CONNECTION_MAX_ID_DESC',
  ProjectsConnectionMaxImageUrlAsc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsConnectionMaxImageUrlDesc = 'PROJECTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsConnectionMaxIsActiveAsc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsConnectionMaxIsActiveDesc = 'PROJECTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsConnectionMaxIsCompletedAsc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsConnectionMaxIsCompletedDesc = 'PROJECTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsConnectionMaxJurisdictionIdAsc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsConnectionMaxJurisdictionIdDesc = 'PROJECTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsConnectionMaxLastActivityAsc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsConnectionMaxLastActivityDesc = 'PROJECTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMaxOverdueByAsc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsConnectionMaxOverdueByDesc = 'PROJECTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsConnectionMaxOwnerIdAsc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsConnectionMaxOwnerIdDesc = 'PROJECTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsConnectionMaxParentProjectIdAsc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMaxParentProjectIdDesc = 'PROJECTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMaxPhoneAsc = 'PROJECTS_CONNECTION_MAX_PHONE_ASC',
  ProjectsConnectionMaxPhoneDesc = 'PROJECTS_CONNECTION_MAX_PHONE_DESC',
  ProjectsConnectionMaxPrimaryEmailAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMaxPrimaryEmailDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMaxPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsConnectionMaxPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsConnectionMaxPrivilegeAllAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMaxPrivilegeAllDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMaxPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMaxPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMaxPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMaxPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMaxProgressAsc = 'PROJECTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsConnectionMaxProgressDesc = 'PROJECTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsConnectionMaxProjectManagerIdAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMaxProjectManagerIdDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMaxProjectSizeAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsConnectionMaxProjectSizeDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsConnectionMaxProjectValueAsc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsConnectionMaxProjectValueDesc = 'PROJECTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsConnectionMaxReferrerContactIdAsc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMaxReferrerContactIdDesc = 'PROJECTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMaxRequestStatusAsc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsConnectionMaxRequestStatusDesc = 'PROJECTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsConnectionMaxRevenueAsc = 'PROJECTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectsConnectionMaxRevenueDesc = 'PROJECTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectsConnectionMaxSalesRepIdAsc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsConnectionMaxSalesRepIdDesc = 'PROJECTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMaxSourceAsc = 'PROJECTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectsConnectionMaxSourceDesc = 'PROJECTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectsConnectionMaxStageIdAsc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsConnectionMaxStageIdDesc = 'PROJECTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsConnectionMaxStateAsc = 'PROJECTS_CONNECTION_MAX_STATE_ASC',
  ProjectsConnectionMaxStateDesc = 'PROJECTS_CONNECTION_MAX_STATE_DESC',
  ProjectsConnectionMaxStatusAsc = 'PROJECTS_CONNECTION_MAX_STATUS_ASC',
  ProjectsConnectionMaxStatusDesc = 'PROJECTS_CONNECTION_MAX_STATUS_DESC',
  ProjectsConnectionMaxStreetAsc = 'PROJECTS_CONNECTION_MAX_STREET_ASC',
  ProjectsConnectionMaxStreetDesc = 'PROJECTS_CONNECTION_MAX_STREET_DESC',
  ProjectsConnectionMaxStreetViewUrlAsc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsConnectionMaxStreetViewUrlDesc = 'PROJECTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsConnectionMaxTitleAsc = 'PROJECTS_CONNECTION_MAX_TITLE_ASC',
  ProjectsConnectionMaxTitleDesc = 'PROJECTS_CONNECTION_MAX_TITLE_DESC',
  ProjectsConnectionMaxTotalCostAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsConnectionMaxTotalCostDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMaxTotalPriceAsc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsConnectionMaxTotalPriceDesc = 'PROJECTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsConnectionMaxTradesAsc = 'PROJECTS_CONNECTION_MAX_TRADES_ASC',
  ProjectsConnectionMaxTradesDesc = 'PROJECTS_CONNECTION_MAX_TRADES_DESC',
  ProjectsConnectionMaxTypeAsc = 'PROJECTS_CONNECTION_MAX_TYPE_ASC',
  ProjectsConnectionMaxTypeDesc = 'PROJECTS_CONNECTION_MAX_TYPE_DESC',
  ProjectsConnectionMaxUidAsc = 'PROJECTS_CONNECTION_MAX_UID_ASC',
  ProjectsConnectionMaxUidDesc = 'PROJECTS_CONNECTION_MAX_UID_DESC',
  ProjectsConnectionMaxUpdatedAtAsc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsConnectionMaxUpdatedAtDesc = 'PROJECTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsConnectionMaxZipcodeAsc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsConnectionMaxZipcodeDesc = 'PROJECTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsConnectionMinAccountStatusAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsConnectionMinAccountStatusDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsConnectionMinAccountTypeAsc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsConnectionMinAccountTypeDesc = 'PROJECTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsConnectionMinAddressAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsConnectionMinAddressDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsConnectionMinAddressNormalizedAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionMinAddressNormalizedDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionMinAddressPrettyAsc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsConnectionMinAddressPrettyDesc = 'PROJECTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsConnectionMinAhjAsc = 'PROJECTS_CONNECTION_MIN_AHJ_ASC',
  ProjectsConnectionMinAhjDesc = 'PROJECTS_CONNECTION_MIN_AHJ_DESC',
  ProjectsConnectionMinBlueprintIdAsc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsConnectionMinBlueprintIdDesc = 'PROJECTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsConnectionMinCityAsc = 'PROJECTS_CONNECTION_MIN_CITY_ASC',
  ProjectsConnectionMinCityDesc = 'PROJECTS_CONNECTION_MIN_CITY_DESC',
  ProjectsConnectionMinCompanyIdAsc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsConnectionMinCompanyIdDesc = 'PROJECTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsConnectionMinCompletionDateAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsConnectionMinCompletionDateDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsConnectionMinCompletionTimeAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsConnectionMinCompletionTimeDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsConnectionMinCompletionTimeInSAsc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionMinCompletionTimeInSDesc = 'PROJECTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionMinConfidenceAsc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsConnectionMinConfidenceDesc = 'PROJECTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsConnectionMinCreatedAtAsc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsConnectionMinCreatedAtDesc = 'PROJECTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsConnectionMinCreatedByAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsConnectionMinCreatedByContactIdAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionMinCreatedByContactIdDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionMinCreatedByDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsConnectionMinCreatedByTypeAsc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsConnectionMinCreatedByTypeDesc = 'PROJECTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsConnectionMinDealSizeAsc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsConnectionMinDealSizeDesc = 'PROJECTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsConnectionMinDealValueAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsConnectionMinDealValueDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsConnectionMinDealValueWeightedAsc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionMinDealValueWeightedDesc = 'PROJECTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionMinDescriptionAsc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsConnectionMinDescriptionDesc = 'PROJECTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsConnectionMinFreshProjectAsc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsConnectionMinFreshProjectDesc = 'PROJECTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsConnectionMinGeoLocationAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsConnectionMinGeoLocationDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsConnectionMinGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionMinGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionMinHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionMinHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionMinIdAsc = 'PROJECTS_CONNECTION_MIN_ID_ASC',
  ProjectsConnectionMinIdDesc = 'PROJECTS_CONNECTION_MIN_ID_DESC',
  ProjectsConnectionMinImageUrlAsc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsConnectionMinImageUrlDesc = 'PROJECTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsConnectionMinIsActiveAsc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsConnectionMinIsActiveDesc = 'PROJECTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsConnectionMinIsCompletedAsc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsConnectionMinIsCompletedDesc = 'PROJECTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsConnectionMinJurisdictionIdAsc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsConnectionMinJurisdictionIdDesc = 'PROJECTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsConnectionMinLastActivityAsc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsConnectionMinLastActivityDesc = 'PROJECTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionMinOverdueByAsc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsConnectionMinOverdueByDesc = 'PROJECTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsConnectionMinOwnerIdAsc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsConnectionMinOwnerIdDesc = 'PROJECTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsConnectionMinParentProjectIdAsc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionMinParentProjectIdDesc = 'PROJECTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionMinPhoneAsc = 'PROJECTS_CONNECTION_MIN_PHONE_ASC',
  ProjectsConnectionMinPhoneDesc = 'PROJECTS_CONNECTION_MIN_PHONE_DESC',
  ProjectsConnectionMinPrimaryEmailAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsConnectionMinPrimaryEmailDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsConnectionMinPrimaryPhoneAsc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsConnectionMinPrimaryPhoneDesc = 'PROJECTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsConnectionMinPrivilegeAllAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsConnectionMinPrivilegeAllDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsConnectionMinPrivilegeOwnAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsConnectionMinPrivilegeOwnDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsConnectionMinPrivilegeTeamAsc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionMinPrivilegeTeamDesc = 'PROJECTS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionMinProgressAsc = 'PROJECTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsConnectionMinProgressDesc = 'PROJECTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsConnectionMinProjectManagerIdAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionMinProjectManagerIdDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionMinProjectSizeAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsConnectionMinProjectSizeDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsConnectionMinProjectValueAsc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsConnectionMinProjectValueDesc = 'PROJECTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsConnectionMinReferrerContactIdAsc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionMinReferrerContactIdDesc = 'PROJECTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionMinRequestStatusAsc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsConnectionMinRequestStatusDesc = 'PROJECTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsConnectionMinRevenueAsc = 'PROJECTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectsConnectionMinRevenueDesc = 'PROJECTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectsConnectionMinSalesRepIdAsc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsConnectionMinSalesRepIdDesc = 'PROJECTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionMinSourceAsc = 'PROJECTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectsConnectionMinSourceDesc = 'PROJECTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectsConnectionMinStageIdAsc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsConnectionMinStageIdDesc = 'PROJECTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsConnectionMinStateAsc = 'PROJECTS_CONNECTION_MIN_STATE_ASC',
  ProjectsConnectionMinStateDesc = 'PROJECTS_CONNECTION_MIN_STATE_DESC',
  ProjectsConnectionMinStatusAsc = 'PROJECTS_CONNECTION_MIN_STATUS_ASC',
  ProjectsConnectionMinStatusDesc = 'PROJECTS_CONNECTION_MIN_STATUS_DESC',
  ProjectsConnectionMinStreetAsc = 'PROJECTS_CONNECTION_MIN_STREET_ASC',
  ProjectsConnectionMinStreetDesc = 'PROJECTS_CONNECTION_MIN_STREET_DESC',
  ProjectsConnectionMinStreetViewUrlAsc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsConnectionMinStreetViewUrlDesc = 'PROJECTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsConnectionMinTitleAsc = 'PROJECTS_CONNECTION_MIN_TITLE_ASC',
  ProjectsConnectionMinTitleDesc = 'PROJECTS_CONNECTION_MIN_TITLE_DESC',
  ProjectsConnectionMinTotalCostAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsConnectionMinTotalCostDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionMinTotalPriceAsc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsConnectionMinTotalPriceDesc = 'PROJECTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsConnectionMinTradesAsc = 'PROJECTS_CONNECTION_MIN_TRADES_ASC',
  ProjectsConnectionMinTradesDesc = 'PROJECTS_CONNECTION_MIN_TRADES_DESC',
  ProjectsConnectionMinTypeAsc = 'PROJECTS_CONNECTION_MIN_TYPE_ASC',
  ProjectsConnectionMinTypeDesc = 'PROJECTS_CONNECTION_MIN_TYPE_DESC',
  ProjectsConnectionMinUidAsc = 'PROJECTS_CONNECTION_MIN_UID_ASC',
  ProjectsConnectionMinUidDesc = 'PROJECTS_CONNECTION_MIN_UID_DESC',
  ProjectsConnectionMinUpdatedAtAsc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsConnectionMinUpdatedAtDesc = 'PROJECTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsConnectionMinZipcodeAsc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsConnectionMinZipcodeDesc = 'PROJECTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevPopulationAddressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsConnectionStddevPopulationAddressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevPopulationAhjAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsConnectionStddevPopulationAhjDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevPopulationCityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsConnectionStddevPopulationCityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevPopulationConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionStddevPopulationConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionStddevPopulationCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevPopulationDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionStddevPopulationDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionStddevPopulationDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionStddevPopulationDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevPopulationDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionStddevPopulationDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevPopulationIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsConnectionStddevPopulationIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsConnectionStddevPopulationImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionStddevPopulationImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionStddevPopulationIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionStddevPopulationIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevPopulationLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevPopulationLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevPopulationOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionStddevPopulationOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevPopulationPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsConnectionStddevPopulationPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevPopulationProgressAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsConnectionStddevPopulationProgressDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevPopulationProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevPopulationProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevPopulationRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsConnectionStddevPopulationRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevPopulationSourceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsConnectionStddevPopulationSourceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsConnectionStddevPopulationStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionStddevPopulationStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionStddevPopulationStateAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsConnectionStddevPopulationStateDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsConnectionStddevPopulationStatusAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsConnectionStddevPopulationStatusDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsConnectionStddevPopulationStreetAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsConnectionStddevPopulationStreetDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevPopulationTitleAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsConnectionStddevPopulationTitleDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsConnectionStddevPopulationTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionStddevPopulationTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevPopulationTradesAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsConnectionStddevPopulationTradesDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsConnectionStddevPopulationTypeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsConnectionStddevPopulationTypeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsConnectionStddevPopulationUidAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsConnectionStddevPopulationUidDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionStddevPopulationZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionStddevPopulationZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionStddevSampleAccountStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionStddevSampleAccountStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionStddevSampleAccountTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionStddevSampleAccountTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionStddevSampleAddressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionStddevSampleAddressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionStddevSampleAhjAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsConnectionStddevSampleAhjDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionStddevSampleCityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsConnectionStddevSampleCityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsConnectionStddevSampleCompanyIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionStddevSampleCompanyIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionStddevSampleCompletionDateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionStddevSampleCompletionDateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionStddevSampleConfidenceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionStddevSampleConfidenceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionStddevSampleCreatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionStddevSampleCreatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionStddevSampleCreatedByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleCreatedByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionStddevSampleDealSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionStddevSampleDealSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionStddevSampleDealValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionStddevSampleDealValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionStddevSampleDescriptionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionStddevSampleDescriptionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionStddevSampleFreshProjectAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionStddevSampleFreshProjectDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionStddevSampleGeoLocationAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionStddevSampleGeoLocationDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionStddevSampleIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsConnectionStddevSampleIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsConnectionStddevSampleImageUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionStddevSampleImageUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionStddevSampleIsActiveAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionStddevSampleIsActiveDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionStddevSampleIsCompletedAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionStddevSampleIsCompletedDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionStddevSampleLastActivityAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionStddevSampleLastActivityDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionStddevSampleOverdueByAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionStddevSampleOverdueByDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionStddevSampleOwnerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionStddevSampleOwnerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionStddevSamplePhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsConnectionStddevSamplePhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionStddevSampleProgressAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionStddevSampleProgressDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionStddevSampleProjectSizeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionStddevSampleProjectSizeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionStddevSampleProjectValueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionStddevSampleProjectValueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionStddevSampleRequestStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionStddevSampleRequestStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionStddevSampleRevenueAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsConnectionStddevSampleRevenueDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionStddevSampleSourceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsConnectionStddevSampleSourceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsConnectionStddevSampleStageIdAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionStddevSampleStageIdDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionStddevSampleStateAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsConnectionStddevSampleStateDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsConnectionStddevSampleStatusAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsConnectionStddevSampleStatusDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsConnectionStddevSampleStreetAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsConnectionStddevSampleStreetDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionStddevSampleTitleAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsConnectionStddevSampleTitleDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsConnectionStddevSampleTotalCostAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionStddevSampleTotalCostDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionStddevSampleTotalPriceAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionStddevSampleTotalPriceDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionStddevSampleTradesAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsConnectionStddevSampleTradesDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsConnectionStddevSampleTypeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsConnectionStddevSampleTypeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsConnectionStddevSampleUidAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsConnectionStddevSampleUidDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionStddevSampleZipcodeAsc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionStddevSampleZipcodeDesc = 'PROJECTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsConnectionSumAccountStatusAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsConnectionSumAccountStatusDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsConnectionSumAccountTypeAsc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsConnectionSumAccountTypeDesc = 'PROJECTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsConnectionSumAddressAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsConnectionSumAddressDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsConnectionSumAddressNormalizedAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionSumAddressNormalizedDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionSumAddressPrettyAsc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsConnectionSumAddressPrettyDesc = 'PROJECTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsConnectionSumAhjAsc = 'PROJECTS_CONNECTION_SUM_AHJ_ASC',
  ProjectsConnectionSumAhjDesc = 'PROJECTS_CONNECTION_SUM_AHJ_DESC',
  ProjectsConnectionSumBlueprintIdAsc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsConnectionSumBlueprintIdDesc = 'PROJECTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsConnectionSumCityAsc = 'PROJECTS_CONNECTION_SUM_CITY_ASC',
  ProjectsConnectionSumCityDesc = 'PROJECTS_CONNECTION_SUM_CITY_DESC',
  ProjectsConnectionSumCompanyIdAsc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsConnectionSumCompanyIdDesc = 'PROJECTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsConnectionSumCompletionDateAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsConnectionSumCompletionDateDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsConnectionSumCompletionTimeAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsConnectionSumCompletionTimeDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsConnectionSumCompletionTimeInSAsc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionSumCompletionTimeInSDesc = 'PROJECTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionSumConfidenceAsc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsConnectionSumConfidenceDesc = 'PROJECTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsConnectionSumCreatedAtAsc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsConnectionSumCreatedAtDesc = 'PROJECTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsConnectionSumCreatedByAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsConnectionSumCreatedByContactIdAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionSumCreatedByContactIdDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionSumCreatedByDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsConnectionSumCreatedByTypeAsc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsConnectionSumCreatedByTypeDesc = 'PROJECTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsConnectionSumDealSizeAsc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsConnectionSumDealSizeDesc = 'PROJECTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsConnectionSumDealValueAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsConnectionSumDealValueDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsConnectionSumDealValueWeightedAsc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionSumDealValueWeightedDesc = 'PROJECTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionSumDescriptionAsc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsConnectionSumDescriptionDesc = 'PROJECTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsConnectionSumFreshProjectAsc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsConnectionSumFreshProjectDesc = 'PROJECTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsConnectionSumGeoLocationAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsConnectionSumGeoLocationDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsConnectionSumGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionSumGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionSumHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionSumHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionSumIdAsc = 'PROJECTS_CONNECTION_SUM_ID_ASC',
  ProjectsConnectionSumIdDesc = 'PROJECTS_CONNECTION_SUM_ID_DESC',
  ProjectsConnectionSumImageUrlAsc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsConnectionSumImageUrlDesc = 'PROJECTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsConnectionSumIsActiveAsc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsConnectionSumIsActiveDesc = 'PROJECTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsConnectionSumIsCompletedAsc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsConnectionSumIsCompletedDesc = 'PROJECTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsConnectionSumJurisdictionIdAsc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsConnectionSumJurisdictionIdDesc = 'PROJECTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsConnectionSumLastActivityAsc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsConnectionSumLastActivityDesc = 'PROJECTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionSumOverdueByAsc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsConnectionSumOverdueByDesc = 'PROJECTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsConnectionSumOwnerIdAsc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsConnectionSumOwnerIdDesc = 'PROJECTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsConnectionSumParentProjectIdAsc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionSumParentProjectIdDesc = 'PROJECTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionSumPhoneAsc = 'PROJECTS_CONNECTION_SUM_PHONE_ASC',
  ProjectsConnectionSumPhoneDesc = 'PROJECTS_CONNECTION_SUM_PHONE_DESC',
  ProjectsConnectionSumPrimaryEmailAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsConnectionSumPrimaryEmailDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsConnectionSumPrimaryPhoneAsc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsConnectionSumPrimaryPhoneDesc = 'PROJECTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsConnectionSumPrivilegeAllAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsConnectionSumPrivilegeAllDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsConnectionSumPrivilegeOwnAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsConnectionSumPrivilegeOwnDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsConnectionSumPrivilegeTeamAsc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionSumPrivilegeTeamDesc = 'PROJECTS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionSumProgressAsc = 'PROJECTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsConnectionSumProgressDesc = 'PROJECTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsConnectionSumProjectManagerIdAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionSumProjectManagerIdDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionSumProjectSizeAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsConnectionSumProjectSizeDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsConnectionSumProjectValueAsc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsConnectionSumProjectValueDesc = 'PROJECTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsConnectionSumReferrerContactIdAsc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionSumReferrerContactIdDesc = 'PROJECTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionSumRequestStatusAsc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsConnectionSumRequestStatusDesc = 'PROJECTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsConnectionSumRevenueAsc = 'PROJECTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectsConnectionSumRevenueDesc = 'PROJECTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectsConnectionSumSalesRepIdAsc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsConnectionSumSalesRepIdDesc = 'PROJECTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionSumSourceAsc = 'PROJECTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectsConnectionSumSourceDesc = 'PROJECTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectsConnectionSumStageIdAsc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsConnectionSumStageIdDesc = 'PROJECTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsConnectionSumStateAsc = 'PROJECTS_CONNECTION_SUM_STATE_ASC',
  ProjectsConnectionSumStateDesc = 'PROJECTS_CONNECTION_SUM_STATE_DESC',
  ProjectsConnectionSumStatusAsc = 'PROJECTS_CONNECTION_SUM_STATUS_ASC',
  ProjectsConnectionSumStatusDesc = 'PROJECTS_CONNECTION_SUM_STATUS_DESC',
  ProjectsConnectionSumStreetAsc = 'PROJECTS_CONNECTION_SUM_STREET_ASC',
  ProjectsConnectionSumStreetDesc = 'PROJECTS_CONNECTION_SUM_STREET_DESC',
  ProjectsConnectionSumStreetViewUrlAsc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsConnectionSumStreetViewUrlDesc = 'PROJECTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsConnectionSumTitleAsc = 'PROJECTS_CONNECTION_SUM_TITLE_ASC',
  ProjectsConnectionSumTitleDesc = 'PROJECTS_CONNECTION_SUM_TITLE_DESC',
  ProjectsConnectionSumTotalCostAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsConnectionSumTotalCostDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionSumTotalPriceAsc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsConnectionSumTotalPriceDesc = 'PROJECTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsConnectionSumTradesAsc = 'PROJECTS_CONNECTION_SUM_TRADES_ASC',
  ProjectsConnectionSumTradesDesc = 'PROJECTS_CONNECTION_SUM_TRADES_DESC',
  ProjectsConnectionSumTypeAsc = 'PROJECTS_CONNECTION_SUM_TYPE_ASC',
  ProjectsConnectionSumTypeDesc = 'PROJECTS_CONNECTION_SUM_TYPE_DESC',
  ProjectsConnectionSumUidAsc = 'PROJECTS_CONNECTION_SUM_UID_ASC',
  ProjectsConnectionSumUidDesc = 'PROJECTS_CONNECTION_SUM_UID_DESC',
  ProjectsConnectionSumUpdatedAtAsc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsConnectionSumUpdatedAtDesc = 'PROJECTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsConnectionSumZipcodeAsc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsConnectionSumZipcodeDesc = 'PROJECTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVariancePopulationAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsConnectionVariancePopulationAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVariancePopulationAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsConnectionVariancePopulationAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsConnectionVariancePopulationCityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsConnectionVariancePopulationCityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVariancePopulationConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsConnectionVariancePopulationConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsConnectionVariancePopulationCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVariancePopulationDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsConnectionVariancePopulationDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsConnectionVariancePopulationDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsConnectionVariancePopulationDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVariancePopulationDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsConnectionVariancePopulationDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVariancePopulationIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsConnectionVariancePopulationIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsConnectionVariancePopulationImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsConnectionVariancePopulationImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsConnectionVariancePopulationIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsConnectionVariancePopulationIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsConnectionVariancePopulationLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsConnectionVariancePopulationLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVariancePopulationOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsConnectionVariancePopulationOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVariancePopulationPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsConnectionVariancePopulationPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVariancePopulationProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsConnectionVariancePopulationProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsConnectionVariancePopulationProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsConnectionVariancePopulationProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsConnectionVariancePopulationRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsConnectionVariancePopulationRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVariancePopulationSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsConnectionVariancePopulationSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsConnectionVariancePopulationStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsConnectionVariancePopulationStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsConnectionVariancePopulationStateAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsConnectionVariancePopulationStateDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsConnectionVariancePopulationStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsConnectionVariancePopulationStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsConnectionVariancePopulationStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsConnectionVariancePopulationStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsConnectionVariancePopulationTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsConnectionVariancePopulationTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsConnectionVariancePopulationTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsConnectionVariancePopulationTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsConnectionVariancePopulationTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsConnectionVariancePopulationTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsConnectionVariancePopulationTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsConnectionVariancePopulationTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsConnectionVariancePopulationUidAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsConnectionVariancePopulationUidDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsConnectionVariancePopulationZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsConnectionVariancePopulationZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsConnectionVarianceSampleAddressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsConnectionVarianceSampleAddressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsConnectionVarianceSampleAhjAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsConnectionVarianceSampleAhjDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsConnectionVarianceSampleCityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsConnectionVarianceSampleCityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsConnectionVarianceSampleConfidenceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsConnectionVarianceSampleConfidenceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsConnectionVarianceSampleCreatedByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleCreatedByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsConnectionVarianceSampleDealSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsConnectionVarianceSampleDealSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsConnectionVarianceSampleDealValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsConnectionVarianceSampleDealValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsConnectionVarianceSampleDescriptionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsConnectionVarianceSampleDescriptionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsConnectionVarianceSampleIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsConnectionVarianceSampleIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsConnectionVarianceSampleImageUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsConnectionVarianceSampleImageUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsConnectionVarianceSampleIsActiveAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsConnectionVarianceSampleIsActiveDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsConnectionVarianceSampleLastActivityAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsConnectionVarianceSampleLastActivityDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsConnectionVarianceSampleOverdueByAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsConnectionVarianceSampleOverdueByDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsConnectionVarianceSamplePhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsConnectionVarianceSamplePhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsConnectionVarianceSampleProgressAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsConnectionVarianceSampleProgressDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsConnectionVarianceSampleProjectValueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsConnectionVarianceSampleProjectValueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsConnectionVarianceSampleRevenueAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsConnectionVarianceSampleRevenueDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsConnectionVarianceSampleSourceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsConnectionVarianceSampleSourceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsConnectionVarianceSampleStageIdAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsConnectionVarianceSampleStageIdDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsConnectionVarianceSampleStateAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsConnectionVarianceSampleStateDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsConnectionVarianceSampleStatusAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsConnectionVarianceSampleStatusDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsConnectionVarianceSampleStreetAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsConnectionVarianceSampleStreetDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsConnectionVarianceSampleTitleAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsConnectionVarianceSampleTitleDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsConnectionVarianceSampleTotalCostAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsConnectionVarianceSampleTotalCostDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsConnectionVarianceSampleTradesAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsConnectionVarianceSampleTradesDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsConnectionVarianceSampleTypeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsConnectionVarianceSampleTypeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsConnectionVarianceSampleUidAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsConnectionVarianceSampleUidDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsConnectionVarianceSampleZipcodeAsc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsConnectionVarianceSampleZipcodeDesc = 'PROJECTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionArrayAggCityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsConnectionArrayAggCityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionArrayAggIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsConnectionArrayAggIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggStateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsConnectionArrayAggStateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsConnectionArrayAggUidAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsConnectionArrayAggUidDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionAverageAddressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsConnectionAverageAddressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionAverageAhjAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsConnectionAverageAhjDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionAverageCityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsConnectionAverageCityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsConnectionAverageDealValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsConnectionAverageDealValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionAverageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsConnectionAverageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionAveragePhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsConnectionAveragePhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionAverageProductNameAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionAverageProductNameDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionAverageProgressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsConnectionAverageProgressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionAverageRevenueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsConnectionAverageRevenueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSourceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsConnectionAverageSourceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsConnectionAverageStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageStateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsConnectionAverageStateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsConnectionAverageStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsConnectionAverageStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsConnectionAverageStreetAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsConnectionAverageStreetDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsConnectionAverageTextValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsConnectionAverageTextValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTitleAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsConnectionAverageTitleDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionAverageTradesAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsConnectionAverageTradesDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsConnectionAverageTradeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsConnectionAverageTradeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsConnectionAverageTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsConnectionAverageTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsConnectionAverageUidAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsConnectionAverageUidDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsConnectionAverageUserIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsConnectionAverageUserIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsConnectionCountAsc = 'PROJECT_REPORTS_CONNECTION_COUNT_ASC',
  ProjectReportsConnectionCountDesc = 'PROJECT_REPORTS_CONNECTION_COUNT_DESC',
  ProjectReportsConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMaxAddressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsConnectionMaxAddressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMaxAhjAsc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsConnectionMaxAhjDesc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMaxCityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_ASC',
  ProjectReportsConnectionMaxCityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_DESC',
  ProjectReportsConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsConnectionMaxDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsConnectionMaxDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMaxIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_ASC',
  ProjectReportsConnectionMaxIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_DESC',
  ProjectReportsConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsConnectionMaxPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMaxProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMaxProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMaxProgressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsConnectionMaxProgressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMaxRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsConnectionMaxRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSourceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsConnectionMaxSourceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsConnectionMaxStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsConnectionMaxStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsConnectionMaxStateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_ASC',
  ProjectReportsConnectionMaxStateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_DESC',
  ProjectReportsConnectionMaxStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsConnectionMaxStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsConnectionMaxStreetAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_ASC',
  ProjectReportsConnectionMaxStreetDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_DESC',
  ProjectReportsConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsConnectionMaxTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsConnectionMaxTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTitleAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsConnectionMaxTitleDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMaxTradesAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsConnectionMaxTradesDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsConnectionMaxTradeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsConnectionMaxTradeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsConnectionMaxTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsConnectionMaxTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsConnectionMaxUidAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_ASC',
  ProjectReportsConnectionMaxUidDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_DESC',
  ProjectReportsConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsConnectionMaxUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsConnectionMaxUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMinAddressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsConnectionMinAddressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMinAhjAsc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsConnectionMinAhjDesc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMinCityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_ASC',
  ProjectReportsConnectionMinCityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_DESC',
  ProjectReportsConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMinConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsConnectionMinConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsConnectionMinCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMinCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMinDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsConnectionMinDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsConnectionMinDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsConnectionMinDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMinDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsConnectionMinDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMinIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_ASC',
  ProjectReportsConnectionMinIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_DESC',
  ProjectReportsConnectionMinImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsConnectionMinImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsConnectionMinIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsConnectionMinIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMinLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMinLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMinNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMinOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsConnectionMinOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMinPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsConnectionMinPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMinProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMinProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMinProgressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsConnectionMinProgressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsConnectionMinProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsConnectionMinProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMinProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMinProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMinRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsConnectionMinRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSourceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsConnectionMinSourceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsConnectionMinStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsConnectionMinStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsConnectionMinStateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_ASC',
  ProjectReportsConnectionMinStateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_DESC',
  ProjectReportsConnectionMinStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsConnectionMinStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsConnectionMinStreetAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_ASC',
  ProjectReportsConnectionMinStreetDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_DESC',
  ProjectReportsConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMinTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsConnectionMinTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsConnectionMinTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsConnectionMinTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMinTitleAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsConnectionMinTitleDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsConnectionMinTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsConnectionMinTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMinTradesAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsConnectionMinTradesDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsConnectionMinTradeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsConnectionMinTradeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsConnectionMinTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsConnectionMinTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsConnectionMinUidAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_ASC',
  ProjectReportsConnectionMinUidDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_DESC',
  ProjectReportsConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsConnectionMinUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsConnectionMinUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsConnectionMinZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsConnectionMinZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionSumAddressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsConnectionSumAddressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionSumAhjAsc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsConnectionSumAhjDesc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionSumCityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_ASC',
  ProjectReportsConnectionSumCityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_DESC',
  ProjectReportsConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionSumConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsConnectionSumConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsConnectionSumCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionSumCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionSumDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsConnectionSumDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsConnectionSumDealValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsConnectionSumDealValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionSumDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsConnectionSumDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionSumIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_ASC',
  ProjectReportsConnectionSumIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_DESC',
  ProjectReportsConnectionSumImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsConnectionSumImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsConnectionSumIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsConnectionSumIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsConnectionSumLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionSumLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionSumNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionSumOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsConnectionSumOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionSumPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsConnectionSumPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionSumProductNameAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsConnectionSumProductNameDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsConnectionSumProgressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsConnectionSumProgressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsConnectionSumProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsConnectionSumProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsConnectionSumProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsConnectionSumProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsConnectionSumRevenueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsConnectionSumRevenueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSourceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsConnectionSumSourceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsConnectionSumStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsConnectionSumStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsConnectionSumStateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_ASC',
  ProjectReportsConnectionSumStateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_DESC',
  ProjectReportsConnectionSumStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsConnectionSumStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsConnectionSumStreetAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_ASC',
  ProjectReportsConnectionSumStreetDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_DESC',
  ProjectReportsConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionSumTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsConnectionSumTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsConnectionSumTextValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsConnectionSumTextValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionSumTitleAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsConnectionSumTitleDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsConnectionSumTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsConnectionSumTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsConnectionSumTradesAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsConnectionSumTradesDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsConnectionSumTradeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsConnectionSumTradeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsConnectionSumTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsConnectionSumTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsConnectionSumUidAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_ASC',
  ProjectReportsConnectionSumUidDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_DESC',
  ProjectReportsConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsConnectionSumUserIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsConnectionSumUserIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsConnectionSumZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsConnectionSumZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromJurisdictionIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToJurisdictionIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_JURISDICTION_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SubmissionMethodAsc = 'SUBMISSION_METHOD_ASC',
  SubmissionMethodDesc = 'SUBMISSION_METHOD_DESC',
  TurnaroundAsc = 'TURNAROUND_ASC',
  TurnaroundDesc = 'TURNAROUND_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC'
}

export type Label = Node & {
  color: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskFirstLabelIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportFirstLabelIdAndCompanyIdList: Array<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `Label`. */
  createdByUser: Maybe<User>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskFirstLabelIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskFirstLabelIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportFirstLabelIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportFirstLabelIdAndCreatedByList: Array<MainUser>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFirstLabelIdConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskId: LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportFirstLabelIdAndTaskId: LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportFirstLabelIdAndTemplateTaskId: LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskFirstLabelIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportFirstLabelIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskFirstLabelIdAndProjectId: LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskFirstLabelIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportFirstLabelIdAndProjectId: LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportFirstLabelIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskFirstLabelIdAndSystemId: LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  taskLabels: Array<TaskLabel>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByFirstLabelIdConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `Task`. */
  tasksByFirstLabelIdConnection: TasksConnection;
  updatedAt: Scalars['Datetime']['output'];
};


export type LabelCompaniesByPrivilegedTaskFirstLabelIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type LabelCompaniesByTaskReportFirstLabelIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type LabelMainUsersByPrivilegedTaskFirstLabelIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type LabelMainUsersByPrivilegedTaskFirstLabelIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type LabelMainUsersByTaskReportFirstLabelIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type LabelMainUsersByTaskReportFirstLabelIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type LabelPrivilegedTasksByFirstLabelIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type LabelProjectStagesByPrivilegedTaskFirstLabelIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type LabelProjectStagesByTaskReportFirstLabelIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type LabelTaskLabelsArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};


export type LabelTaskReportsByFirstLabelIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type LabelTasksByFirstLabelIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskCondition>;
  filter: InputMaybe<TaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};

/** A filter to be used against aggregates of `Label` object types. */
export type LabelAggregatesFilter = {
  /** Array aggregation aggregate over matching `Label` objects. */
  arrayAgg: InputMaybe<LabelArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Label` objects. */
  average: InputMaybe<LabelAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Label` objects. */
  distinctCount: InputMaybe<LabelDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Label` object to be included within the aggregate. */
  filter: InputMaybe<LabelFilter>;
  /** Maximum aggregate over matching `Label` objects. */
  max: InputMaybe<LabelMaxAggregateFilter>;
  /** Minimum aggregate over matching `Label` objects. */
  min: InputMaybe<LabelMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Label` objects. */
  stddevPopulation: InputMaybe<LabelStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Label` objects. */
  stddevSample: InputMaybe<LabelStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Label` objects. */
  sum: InputMaybe<LabelSumAggregateFilter>;
  /** Population variance aggregate over matching `Label` objects. */
  variancePopulation: InputMaybe<LabelVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Label` objects. */
  varianceSample: InputMaybe<LabelVarianceSampleAggregateFilter>;
};

export type LabelArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isActive: InputMaybe<BooleanListFilter>;
};

export type LabelAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** A condition to be used against `Label` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LabelCondition = {
  /** Checks for equality with the object’s `color` field. */
  color: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type LabelDistinctCountAggregateFilter = {
  color: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isActive: InputMaybe<BigIntFilter>;
  label: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Label` object types. All fields are combined with a logical ‘and.’ */
export type LabelFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<LabelFilter>>;
  /** Filter by the object’s `color` field. */
  color: InputMaybe<StringFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<LabelFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<LabelFilter>>;
  /** Filter by the object’s `privilegedTasksByFirstLabelId` relation. */
  privilegedTasksByFirstLabelId: InputMaybe<LabelToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksByFirstLabelId` exist. */
  privilegedTasksByFirstLabelIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskLabels` relation. */
  taskLabels: InputMaybe<LabelToManyTaskLabelFilter>;
  /** Some related `taskLabels` exist. */
  taskLabelsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskReportsByFirstLabelId` relation. */
  taskReportsByFirstLabelId: InputMaybe<LabelToManyTaskReportFilter>;
  /** Some related `taskReportsByFirstLabelId` exist. */
  taskReportsByFirstLabelIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tasksByFirstLabelId` relation. */
  tasksByFirstLabelId: InputMaybe<LabelToManyTaskFilter>;
  /** Some related `tasksByFirstLabelId` exist. */
  tasksByFirstLabelIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type LabelMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type LabelMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type LabelPrivilegedTasksByPrivilegedTaskFirstLabelIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type LabelPrivilegedTasksByTaskReportFirstLabelIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type LabelProjectsByPrivilegedTaskFirstLabelIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type LabelProjectsByTaskReportFirstLabelIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type LabelStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type LabelStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type LabelSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type LabelSystemsByPrivilegedTaskFirstLabelIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type LabelToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type LabelToManyTaskFilter = {
  /** Aggregates across related `Task` match the filter criteria. */
  aggregates: InputMaybe<TaskAggregatesFilter>;
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TaskLabel` object types. All fields are combined with a logical ‘and.’ */
export type LabelToManyTaskLabelFilter = {
  /** Aggregates across related `TaskLabel` match the filter criteria. */
  aggregates: InputMaybe<TaskLabelAggregatesFilter>;
  /** Every related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskLabelFilter>;
  /** No related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskLabelFilter>;
  /** Some related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskLabelFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type LabelToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

export type LabelVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type LabelVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Label`. */
export enum LabelsOrderBy {
  ColorAsc = 'COLOR_ASC',
  ColorDesc = 'COLOR_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionCountAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionCountDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByFirstLabelIdConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionArrayAggAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionArrayAggAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionArrayAggAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionArrayAggCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionArrayAggCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionArrayAggCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionArrayAggCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionArrayAggCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionArrayAggCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionArrayAggCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionArrayAggCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionArrayAggCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionArrayAggCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionArrayAggCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionArrayAggDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionArrayAggDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionArrayAggEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionArrayAggEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionArrayAggEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TasksByFirstLabelIdConnectionArrayAggEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TasksByFirstLabelIdConnectionArrayAggFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TasksByFirstLabelIdConnectionArrayAggFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TasksByFirstLabelIdConnectionArrayAggIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionArrayAggIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionArrayAggIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionArrayAggIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionArrayAggIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionArrayAggIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionArrayAggIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionArrayAggIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionArrayAggIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionArrayAggIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionArrayAggJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionArrayAggModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionArrayAggNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TasksByFirstLabelIdConnectionArrayAggPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TasksByFirstLabelIdConnectionArrayAggPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionArrayAggPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionArrayAggProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionArrayAggStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionArrayAggStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TasksByFirstLabelIdConnectionArrayAggStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TasksByFirstLabelIdConnectionArrayAggStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TasksByFirstLabelIdConnectionArrayAggStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TasksByFirstLabelIdConnectionArrayAggSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionArrayAggTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionArrayAggTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionArrayAggTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TasksByFirstLabelIdConnectionArrayAggTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TasksByFirstLabelIdConnectionArrayAggUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TasksByFirstLabelIdConnectionArrayAggUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TasksByFirstLabelIdConnectionArrayAggVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionArrayAggVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionAverageAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionAverageAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionAverageAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionAverageAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionAverageCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionAverageCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionAverageCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionAverageCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionAverageCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionAverageCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionAverageCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionAverageCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionAverageCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionAverageCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionAverageCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionAverageCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionAverageCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionAverageCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionAverageDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionAverageDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionAverageEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionAverageEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionAverageEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TasksByFirstLabelIdConnectionAverageEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TasksByFirstLabelIdConnectionAverageFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionAverageFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionAverageFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TasksByFirstLabelIdConnectionAverageFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TasksByFirstLabelIdConnectionAverageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_ASC',
  TasksByFirstLabelIdConnectionAverageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_DESC',
  TasksByFirstLabelIdConnectionAverageIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionAverageIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionAverageIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionAverageIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionAverageIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionAverageIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionAverageIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionAverageIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionAverageIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionAverageIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionAverageJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionAverageJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionAverageModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionAverageModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionAverageNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionAverageNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionAveragePositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TasksByFirstLabelIdConnectionAveragePositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TasksByFirstLabelIdConnectionAveragePriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionAveragePriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionAverageProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionAverageProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionAverageProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionAverageProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionAverageStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionAverageStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionAverageStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TasksByFirstLabelIdConnectionAverageStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TasksByFirstLabelIdConnectionAverageStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TasksByFirstLabelIdConnectionAverageStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TasksByFirstLabelIdConnectionAverageSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionAverageSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionAverageTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionAverageTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionAverageTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionAverageTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionAverageTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TasksByFirstLabelIdConnectionAverageTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TasksByFirstLabelIdConnectionAverageUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_ASC',
  TasksByFirstLabelIdConnectionAverageUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_DESC',
  TasksByFirstLabelIdConnectionAverageVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionAverageVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionCountAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_ASC',
  TasksByFirstLabelIdConnectionCountDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_DESC',
  TasksByFirstLabelIdConnectionDistinctCountAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionDistinctCountAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionDistinctCountAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionDistinctCountCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionDistinctCountCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionDistinctCountDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionDistinctCountDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionDistinctCountEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionDistinctCountEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionDistinctCountEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TasksByFirstLabelIdConnectionDistinctCountEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TasksByFirstLabelIdConnectionDistinctCountFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TasksByFirstLabelIdConnectionDistinctCountFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionDistinctCountIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionDistinctCountIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionDistinctCountJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionDistinctCountModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionDistinctCountNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TasksByFirstLabelIdConnectionDistinctCountPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TasksByFirstLabelIdConnectionDistinctCountPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionDistinctCountPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionDistinctCountProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionDistinctCountStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionDistinctCountStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TasksByFirstLabelIdConnectionDistinctCountStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TasksByFirstLabelIdConnectionDistinctCountStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TasksByFirstLabelIdConnectionDistinctCountStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TasksByFirstLabelIdConnectionDistinctCountSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionDistinctCountTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TasksByFirstLabelIdConnectionDistinctCountTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TasksByFirstLabelIdConnectionDistinctCountUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TasksByFirstLabelIdConnectionDistinctCountUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TasksByFirstLabelIdConnectionDistinctCountVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionDistinctCountVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionMaxAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionMaxAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionMaxAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionMaxAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionMaxCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionMaxCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionMaxCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionMaxCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionMaxCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionMaxCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionMaxCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionMaxCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionMaxCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionMaxCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionMaxCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionMaxCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionMaxCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionMaxCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionMaxDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionMaxDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionMaxEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionMaxEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionMaxEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ASC',
  TasksByFirstLabelIdConnectionMaxEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_DESC',
  TasksByFirstLabelIdConnectionMaxFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionMaxFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionMaxFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_ASC',
  TasksByFirstLabelIdConnectionMaxFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_DESC',
  TasksByFirstLabelIdConnectionMaxIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_ASC',
  TasksByFirstLabelIdConnectionMaxIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_DESC',
  TasksByFirstLabelIdConnectionMaxIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionMaxIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionMaxIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionMaxIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionMaxIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionMaxIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionMaxIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionMaxIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionMaxIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionMaxIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionMaxJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionMaxJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionMaxModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionMaxModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionMaxNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionMaxNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionMaxPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_ASC',
  TasksByFirstLabelIdConnectionMaxPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_DESC',
  TasksByFirstLabelIdConnectionMaxPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionMaxPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionMaxProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionMaxProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionMaxProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionMaxProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionMaxStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionMaxStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionMaxStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ASC',
  TasksByFirstLabelIdConnectionMaxStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_DESC',
  TasksByFirstLabelIdConnectionMaxStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_ASC',
  TasksByFirstLabelIdConnectionMaxStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_DESC',
  TasksByFirstLabelIdConnectionMaxSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionMaxSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionMaxTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionMaxTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionMaxTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionMaxTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionMaxTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_ASC',
  TasksByFirstLabelIdConnectionMaxTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_DESC',
  TasksByFirstLabelIdConnectionMaxUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_ASC',
  TasksByFirstLabelIdConnectionMaxUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_DESC',
  TasksByFirstLabelIdConnectionMaxVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionMaxVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionMinAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionMinAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionMinAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionMinAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionMinCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionMinCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionMinCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionMinCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionMinCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionMinCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionMinCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionMinCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionMinCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionMinCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionMinCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionMinCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionMinCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionMinCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionMinDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionMinDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionMinEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionMinEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionMinEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ASC',
  TasksByFirstLabelIdConnectionMinEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_DESC',
  TasksByFirstLabelIdConnectionMinFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionMinFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionMinFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_ASC',
  TasksByFirstLabelIdConnectionMinFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_DESC',
  TasksByFirstLabelIdConnectionMinIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_ASC',
  TasksByFirstLabelIdConnectionMinIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_DESC',
  TasksByFirstLabelIdConnectionMinIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionMinIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionMinIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionMinIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionMinIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionMinIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionMinIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionMinIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionMinIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionMinIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionMinJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionMinJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionMinModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionMinModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionMinNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionMinNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionMinPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_ASC',
  TasksByFirstLabelIdConnectionMinPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_DESC',
  TasksByFirstLabelIdConnectionMinPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionMinPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionMinProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionMinProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionMinProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionMinProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionMinStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionMinStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionMinStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ASC',
  TasksByFirstLabelIdConnectionMinStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_DESC',
  TasksByFirstLabelIdConnectionMinStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_ASC',
  TasksByFirstLabelIdConnectionMinStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_DESC',
  TasksByFirstLabelIdConnectionMinSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionMinSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionMinTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionMinTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionMinTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionMinTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionMinTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_ASC',
  TasksByFirstLabelIdConnectionMinTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_DESC',
  TasksByFirstLabelIdConnectionMinUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_ASC',
  TasksByFirstLabelIdConnectionMinUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_DESC',
  TasksByFirstLabelIdConnectionMinVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionMinVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TasksByFirstLabelIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionStddevSampleAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionStddevSampleAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionStddevSampleAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionStddevSampleCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionStddevSampleCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionStddevSampleDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionStddevSampleDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionStddevSampleEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionStddevSampleEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionStddevSampleEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevSampleEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevSampleFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TasksByFirstLabelIdConnectionStddevSampleFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionStddevSampleIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionStddevSampleIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionStddevSampleJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionStddevSampleModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionStddevSampleNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSamplePositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TasksByFirstLabelIdConnectionStddevSamplePositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TasksByFirstLabelIdConnectionStddevSamplePriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionStddevSamplePriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionStddevSampleProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionStddevSampleStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionStddevSampleStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TasksByFirstLabelIdConnectionStddevSampleStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TasksByFirstLabelIdConnectionStddevSampleStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TasksByFirstLabelIdConnectionStddevSampleStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TasksByFirstLabelIdConnectionStddevSampleSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionStddevSampleTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TasksByFirstLabelIdConnectionStddevSampleTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TasksByFirstLabelIdConnectionStddevSampleUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TasksByFirstLabelIdConnectionStddevSampleUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TasksByFirstLabelIdConnectionStddevSampleVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionStddevSampleVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionSumAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionSumAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionSumAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionSumAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionSumCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionSumCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionSumCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionSumCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionSumCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionSumCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionSumCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionSumCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionSumCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionSumCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionSumCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionSumCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionSumCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionSumCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionSumDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionSumDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionSumEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionSumEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionSumEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ASC',
  TasksByFirstLabelIdConnectionSumEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_DESC',
  TasksByFirstLabelIdConnectionSumFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionSumFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionSumFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_ASC',
  TasksByFirstLabelIdConnectionSumFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_DESC',
  TasksByFirstLabelIdConnectionSumIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_ASC',
  TasksByFirstLabelIdConnectionSumIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_DESC',
  TasksByFirstLabelIdConnectionSumIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionSumIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionSumIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionSumIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionSumIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionSumIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionSumIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionSumIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionSumIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionSumIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionSumJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionSumJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionSumModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionSumModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionSumNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionSumNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionSumPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_ASC',
  TasksByFirstLabelIdConnectionSumPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_DESC',
  TasksByFirstLabelIdConnectionSumPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionSumPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionSumProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionSumProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionSumProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionSumProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionSumStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionSumStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionSumStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ASC',
  TasksByFirstLabelIdConnectionSumStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_DESC',
  TasksByFirstLabelIdConnectionSumStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_ASC',
  TasksByFirstLabelIdConnectionSumStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_DESC',
  TasksByFirstLabelIdConnectionSumSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionSumSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionSumTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionSumTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionSumTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionSumTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionSumTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_ASC',
  TasksByFirstLabelIdConnectionSumTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_DESC',
  TasksByFirstLabelIdConnectionSumUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_ASC',
  TasksByFirstLabelIdConnectionSumUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_DESC',
  TasksByFirstLabelIdConnectionSumVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionSumVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationPositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationPositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationPriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationPriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TasksByFirstLabelIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleAddressAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleAddressDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleAssigneeIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleAssigneeIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCalculatedStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCalculatedStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCompanyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCompanyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionTimeAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionTimeDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCreatedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCreatedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleCreatedByAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleCreatedByDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleDescriptionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleDescriptionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleEndDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleEndDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleEndDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleEndDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleFirstLabelIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleFirstLabelIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleFormsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleFormsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIsArchivedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIsArchivedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIsColoredAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIsColoredDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIsCompletedAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIsCompletedDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIsFieldAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIsFieldDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleIsTemplateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleIsTemplateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleJobIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleJobIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleModifiedAtAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleModifiedAtDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleNextVisitIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleNextVisitIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSamplePositionAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TasksByFirstLabelIdConnectionVarianceSamplePositionDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TasksByFirstLabelIdConnectionVarianceSamplePriorityAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TasksByFirstLabelIdConnectionVarianceSamplePriorityDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleProjectIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleProjectIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleProjectStageIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleProjectStageIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleStartDateAllDayAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleStartDateAllDayDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleStartDateAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleStartDateDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleStatusAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleStatusDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleSystemIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleSystemIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleTitleAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleTitleDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleUidAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleUidDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TasksByFirstLabelIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksByFirstLabelIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASKS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskLabelsConnectionArrayAggLabelIdAsc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskLabelsConnectionArrayAggLabelIdDesc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskLabelsConnectionArrayAggTaskIdAsc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskLabelsConnectionArrayAggTaskIdDesc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskLabelsConnectionAverageLabelIdAsc = 'TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskLabelsConnectionAverageLabelIdDesc = 'TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskLabelsConnectionAverageTaskIdAsc = 'TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskLabelsConnectionAverageTaskIdDesc = 'TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskLabelsConnectionCountAsc = 'TASK_LABELS_CONNECTION_COUNT_ASC',
  TaskLabelsConnectionCountDesc = 'TASK_LABELS_CONNECTION_COUNT_DESC',
  TaskLabelsConnectionDistinctCountLabelIdAsc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskLabelsConnectionDistinctCountLabelIdDesc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskLabelsConnectionDistinctCountTaskIdAsc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskLabelsConnectionDistinctCountTaskIdDesc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskLabelsConnectionMaxLabelIdAsc = 'TASK_LABELS_CONNECTION_MAX_LABEL_ID_ASC',
  TaskLabelsConnectionMaxLabelIdDesc = 'TASK_LABELS_CONNECTION_MAX_LABEL_ID_DESC',
  TaskLabelsConnectionMaxTaskIdAsc = 'TASK_LABELS_CONNECTION_MAX_TASK_ID_ASC',
  TaskLabelsConnectionMaxTaskIdDesc = 'TASK_LABELS_CONNECTION_MAX_TASK_ID_DESC',
  TaskLabelsConnectionMinLabelIdAsc = 'TASK_LABELS_CONNECTION_MIN_LABEL_ID_ASC',
  TaskLabelsConnectionMinLabelIdDesc = 'TASK_LABELS_CONNECTION_MIN_LABEL_ID_DESC',
  TaskLabelsConnectionMinTaskIdAsc = 'TASK_LABELS_CONNECTION_MIN_TASK_ID_ASC',
  TaskLabelsConnectionMinTaskIdDesc = 'TASK_LABELS_CONNECTION_MIN_TASK_ID_DESC',
  TaskLabelsConnectionStddevPopulationLabelIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskLabelsConnectionStddevPopulationLabelIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskLabelsConnectionStddevPopulationTaskIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskLabelsConnectionStddevPopulationTaskIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskLabelsConnectionStddevSampleLabelIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskLabelsConnectionStddevSampleLabelIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskLabelsConnectionStddevSampleTaskIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskLabelsConnectionStddevSampleTaskIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskLabelsConnectionSumLabelIdAsc = 'TASK_LABELS_CONNECTION_SUM_LABEL_ID_ASC',
  TaskLabelsConnectionSumLabelIdDesc = 'TASK_LABELS_CONNECTION_SUM_LABEL_ID_DESC',
  TaskLabelsConnectionSumTaskIdAsc = 'TASK_LABELS_CONNECTION_SUM_TASK_ID_ASC',
  TaskLabelsConnectionSumTaskIdDesc = 'TASK_LABELS_CONNECTION_SUM_TASK_ID_DESC',
  TaskLabelsConnectionVariancePopulationLabelIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskLabelsConnectionVariancePopulationLabelIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskLabelsConnectionVariancePopulationTaskIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskLabelsConnectionVariancePopulationTaskIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskLabelsConnectionVarianceSampleLabelIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskLabelsConnectionVarianceSampleLabelIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskLabelsConnectionVarianceSampleTaskIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskLabelsConnectionVarianceSampleTaskIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAveragePositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionAveragePositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionAverageTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionAverageUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionMaxPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMaxTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionMaxUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_UID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMinAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionMinAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionMinEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMinEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionMinFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionMinIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionMinJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionMinPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionMinPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionMinPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionMinStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMinStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionMinStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMinStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionMinTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionMinTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionMinUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_ASC',
  TaskReportsByFirstLabelIdConnectionMinUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_UID_DESC',
  TaskReportsByFirstLabelIdConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionSumAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionSumAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionSumEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionSumEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionSumFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionSumIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionSumJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionSumPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionSumPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionSumPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionSumStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionSumStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionSumStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionSumStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionSumTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionSumTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionSumUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_ASC',
  TaskReportsByFirstLabelIdConnectionSumUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_UID_DESC',
  TaskReportsByFirstLabelIdConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByFirstLabelIdConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_FIRST_LABEL_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type LineItem = Node & {
  /** Reads a single `CatalogItem` that is related to this `LineItem`. */
  catalogItem: Maybe<CatalogItem>;
  catalogItemId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  /** Reads a single `MainUser` that is related to this `LineItem`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Reads a single `MainUser` that is related to this `LineItem`. */
  mainUserByUpdatedBy: Maybe<MainUser>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `LineItem`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  quantity: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalPrice: Scalars['Float']['output'];
  unitCost: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  updatedAt: Scalars['Datetime']['output'];
  updatedBy: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `LineItem` object types. */
export type LineItemAggregatesFilter = {
  /** Array aggregation aggregate over matching `LineItem` objects. */
  arrayAgg: InputMaybe<LineItemArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `LineItem` objects. */
  average: InputMaybe<LineItemAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LineItem` objects. */
  distinctCount: InputMaybe<LineItemDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LineItem` object to be included within the aggregate. */
  filter: InputMaybe<LineItemFilter>;
  /** Maximum aggregate over matching `LineItem` objects. */
  max: InputMaybe<LineItemMaxAggregateFilter>;
  /** Minimum aggregate over matching `LineItem` objects. */
  min: InputMaybe<LineItemMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LineItem` objects. */
  stddevPopulation: InputMaybe<LineItemStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LineItem` objects. */
  stddevSample: InputMaybe<LineItemStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LineItem` objects. */
  sum: InputMaybe<LineItemSumAggregateFilter>;
  /** Population variance aggregate over matching `LineItem` objects. */
  variancePopulation: InputMaybe<LineItemVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LineItem` objects. */
  varianceSample: InputMaybe<LineItemVarianceSampleAggregateFilter>;
};

export type LineItemArrayAggAggregateFilter = {
  catalogItemId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  quantity: InputMaybe<FloatListFilter>;
  revenue: InputMaybe<FloatListFilter>;
  totalCost: InputMaybe<FloatListFilter>;
  totalPrice: InputMaybe<FloatListFilter>;
  unitCost: InputMaybe<FloatListFilter>;
  unitPrice: InputMaybe<FloatListFilter>;
  updatedBy: InputMaybe<IntListFilter>;
};

export type LineItemAverageAggregateFilter = {
  catalogItemId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `LineItem` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LineItemCondition = {
  /** Checks for equality with the object’s `catalogItemId` field. */
  catalogItemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `quantity` field. */
  quantity: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalCost` field. */
  totalCost: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalPrice` field. */
  totalPrice: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `unitCost` field. */
  unitCost: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `unitPrice` field. */
  unitPrice: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy: InputMaybe<Scalars['Int']['input']>;
};

export type LineItemDistinctCountAggregateFilter = {
  catalogItemId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  quantity: InputMaybe<BigIntFilter>;
  revenue: InputMaybe<BigIntFilter>;
  totalCost: InputMaybe<BigIntFilter>;
  totalPrice: InputMaybe<BigIntFilter>;
  unitCost: InputMaybe<BigIntFilter>;
  unitPrice: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  updatedBy: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `LineItem` object types. All fields are combined with a logical ‘and.’ */
export type LineItemFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<LineItemFilter>>;
  /** Filter by the object’s `catalogItem` relation. */
  catalogItem: InputMaybe<CatalogItemFilter>;
  /** A related `catalogItem` exists. */
  catalogItemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `catalogItemId` field. */
  catalogItemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `mainUserByUpdatedBy` relation. */
  mainUserByUpdatedBy: InputMaybe<MainUserFilter>;
  /** Negates the expression. */
  not: InputMaybe<LineItemFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<LineItemFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `quantity` field. */
  quantity: InputMaybe<FloatFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalCost` field. */
  totalCost: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalPrice` field. */
  totalPrice: InputMaybe<FloatFilter>;
  /** Filter by the object’s `unitCost` field. */
  unitCost: InputMaybe<FloatFilter>;
  /** Filter by the object’s `unitPrice` field. */
  unitPrice: InputMaybe<FloatFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy: InputMaybe<IntFilter>;
};

export type LineItemMaxAggregateFilter = {
  catalogItemId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  updatedBy: InputMaybe<IntFilter>;
};

export type LineItemMinAggregateFilter = {
  catalogItemId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  updatedBy: InputMaybe<IntFilter>;
};

export type LineItemStddevPopulationAggregateFilter = {
  catalogItemId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigFloatFilter>;
};

export type LineItemStddevSampleAggregateFilter = {
  catalogItemId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigFloatFilter>;
};

export type LineItemSumAggregateFilter = {
  catalogItemId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigIntFilter>;
};

export type LineItemVariancePopulationAggregateFilter = {
  catalogItemId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigFloatFilter>;
};

export type LineItemVarianceSampleAggregateFilter = {
  catalogItemId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  quantity: InputMaybe<FloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  unitCost: InputMaybe<FloatFilter>;
  unitPrice: InputMaybe<FloatFilter>;
  updatedBy: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `LineItem`. */
export enum LineItemsOrderBy {
  CatalogItemIdAsc = 'CATALOG_ITEM_ID_ASC',
  CatalogItemIdDesc = 'CATALOG_ITEM_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  QuantityAsc = 'QUANTITY_ASC',
  QuantityDesc = 'QUANTITY_DESC',
  RevenueAsc = 'REVENUE_ASC',
  RevenueDesc = 'REVENUE_DESC',
  TotalCostAsc = 'TOTAL_COST_ASC',
  TotalCostDesc = 'TOTAL_COST_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  UnitCostAsc = 'UNIT_COST_ASC',
  UnitCostDesc = 'UNIT_COST_DESC',
  UnitPriceAsc = 'UNIT_PRICE_ASC',
  UnitPriceDesc = 'UNIT_PRICE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC'
}

export type MainUser = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByCreatedBy: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByCreatedByConnection: ActionsConnection;
  /** Reads and enables pagination through a set of `Analytic`. */
  analyticsByCreatedBy: Array<Analytic>;
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByUserId: Array<Assignee>;
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByCreatedBy: Array<Call>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByCreatedByConnection: CallsConnection;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByCreatedBy: Array<CatalogItem>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsByCreatedByConnection: CatalogItemsConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByCreatedByConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByFilterCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskAssigneeIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportAssigneeIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `CompanyPhoneUser`. */
  companyPhoneUsersByCreatedBy: Array<CompanyPhoneUser>;
  /** Reads and enables pagination through a set of `CompanyPhoneUser`. */
  companyPhoneUsersByUserId: Array<CompanyPhoneUser>;
  /** Reads and enables pagination through a set of `CompanyUser`. */
  companyUsersByUserId: Array<CompanyUser>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedInboxUserIdAndContactId: MainUserContactsByFeedInboxUserIdAndContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedInboxUserIdAndContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `DashboardSharedUser`. */
  dashboardSharedUsersByUserId: Array<DashboardSharedUser>;
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `EmailAccountUser`. */
  emailAccountUsersByCreatedBy: Array<EmailAccountUser>;
  /** Reads and enables pagination through a set of `EmailAccountUser`. */
  emailAccountUsersByUserId: Array<EmailAccountUser>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesByUserIdConnection: FeedInboxesConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedInboxUserIdAndFeedId: MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByCreatedBy: Array<FilterFolder>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByCreatedByConnection: FilterFoldersConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByFilterCreatedByAndFilterFolderId: MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByFilterCreatedByAndFilterFolderIdList: Array<FilterFolder>;
  /** Reads and enables pagination through a set of `FilterToUser`. */
  filterToUsersByUserId: Array<FilterToUser>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByCreatedBy: Array<Filter>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByCreatedByConnection: FiltersConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToUserUserIdAndFilterId: MainUserFiltersByFilterToUserUserIdAndFilterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToUserUserIdAndFilterIdList: Array<Filter>;
  firstName: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `FormAssignee`. */
  formAssigneesByUserId: Array<FormAssignee>;
  /** Reads and enables pagination through a set of `Geolocation`. */
  geolocationsByUserId: Array<Geolocation>;
  /** Reads and enables pagination through a set of `Geolocation`. */
  geolocationsByUserIdConnection: GeolocationsConnection;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByCreatedBy: Array<ImportHistory>;
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByCreatedByConnection: ImportHistoriesConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsByCreatedBy: Array<Integration>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByCreatedBy: Array<Invoice>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByCreatedByConnection: InvoicesConnection;
  isApiAccessUser: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskAssigneeIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskCreatedByAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportAssigneeIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportCreatedByAndFirstLabelIdList: Array<Label>;
  lastName: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LineItem`. */
  lineItemsByCreatedBy: Array<LineItem>;
  /** Reads and enables pagination through a set of `LineItem`. */
  lineItemsByUpdatedBy: Array<LineItem>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskAssigneeIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskCreatedByAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportAssigneeIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportCreatedByAndAssigneeIdList: Array<MainUser>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  password: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByAssigneeIdConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByCreatedByConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskId: MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskId: MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportAssigneeIdAndTaskId: MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportAssigneeIdAndTemplateTaskId: MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportCreatedByAndTaskId: MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportCreatedByAndTemplateTaskId: MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskAssigneeIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskCreatedByAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportAssigneeIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportCreatedByAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByInvoiceCreatedByAndProjectId: MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByInvoiceCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskAssigneeIdAndProjectId: MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskAssigneeIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskCreatedByAndProjectId: MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportAssigneeIdAndProjectId: MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportAssigneeIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportCreatedByAndProjectId: MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportCreatedByAndProjectIdList: Array<Project>;
  resetPasswordExpires: Maybe<Scalars['Datetime']['output']>;
  resetPasswordToken: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskAssigneeIdAndSystemId: MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskCreatedByAndSystemId: MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByAssigneeIdConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByCreatedByConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByCreatedBy: Array<TaskVisit>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByCreatedByConnection: TaskVisitsConnection;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `UserGeolocationSetting`. */
  userGeolocationSettingsByUserId: Array<UserGeolocationSetting>;
};


export type MainUserActionsByCreatedByArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type MainUserActionsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type MainUserAnalyticsByCreatedByArgs = {
  condition: InputMaybe<AnalyticCondition>;
  filter: InputMaybe<AnalyticFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AnalyticsOrderBy>>;
};


export type MainUserAssigneesByUserIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};


export type MainUserCallsByCreatedByArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type MainUserCallsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type MainUserCatalogItemsByCreatedByArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type MainUserCatalogItemsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};


export type MainUserCommentsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type MainUserCompaniesByFilterCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type MainUserCompaniesByPrivilegedTaskAssigneeIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type MainUserCompaniesByPrivilegedTaskCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type MainUserCompaniesByTaskReportAssigneeIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type MainUserCompaniesByTaskReportCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type MainUserCompanyPhoneUsersByCreatedByArgs = {
  condition: InputMaybe<CompanyPhoneUserCondition>;
  filter: InputMaybe<CompanyPhoneUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhoneUsersOrderBy>>;
};


export type MainUserCompanyPhoneUsersByUserIdArgs = {
  condition: InputMaybe<CompanyPhoneUserCondition>;
  filter: InputMaybe<CompanyPhoneUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhoneUsersOrderBy>>;
};


export type MainUserCompanyUsersByUserIdArgs = {
  condition: InputMaybe<CompanyUserCondition>;
  filter: InputMaybe<CompanyUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUsersOrderBy>>;
};


export type MainUserContactsByFeedInboxUserIdAndContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type MainUserContactsByFeedInboxUserIdAndContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type MainUserDashboardSharedUsersByUserIdArgs = {
  condition: InputMaybe<DashboardSharedUserCondition>;
  filter: InputMaybe<DashboardSharedUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedUsersOrderBy>>;
};


export type MainUserEmailAccountUsersByCreatedByArgs = {
  condition: InputMaybe<EmailAccountUserCondition>;
  filter: InputMaybe<EmailAccountUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountUsersOrderBy>>;
};


export type MainUserEmailAccountUsersByUserIdArgs = {
  condition: InputMaybe<EmailAccountUserCondition>;
  filter: InputMaybe<EmailAccountUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountUsersOrderBy>>;
};


export type MainUserFeedInboxesByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};


export type MainUserFeedsByFeedInboxUserIdAndFeedIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type MainUserFilterFoldersByCreatedByArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type MainUserFilterFoldersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdListArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type MainUserFilterToUsersByUserIdArgs = {
  condition: InputMaybe<FilterToUserCondition>;
  filter: InputMaybe<FilterToUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToUsersOrderBy>>;
};


export type MainUserFiltersByCreatedByArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type MainUserFiltersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type MainUserFiltersByFilterToUserUserIdAndFilterIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type MainUserFiltersByFilterToUserUserIdAndFilterIdListArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type MainUserFormAssigneesByUserIdArgs = {
  condition: InputMaybe<FormAssigneeCondition>;
  filter: InputMaybe<FormAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormAssigneesOrderBy>>;
};


export type MainUserGeolocationsByUserIdArgs = {
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type MainUserGeolocationsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type MainUserImportHistoriesByCreatedByArgs = {
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type MainUserImportHistoriesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type MainUserIntegrationsByCreatedByArgs = {
  condition: InputMaybe<IntegrationCondition>;
  filter: InputMaybe<IntegrationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationsOrderBy>>;
};


export type MainUserInvoicesByCreatedByArgs = {
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type MainUserInvoicesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>;
};


export type MainUserLabelsByPrivilegedTaskAssigneeIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type MainUserLabelsByPrivilegedTaskCreatedByAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type MainUserLabelsByTaskReportAssigneeIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type MainUserLabelsByTaskReportCreatedByAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type MainUserLineItemsByCreatedByArgs = {
  condition: InputMaybe<LineItemCondition>;
  filter: InputMaybe<LineItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LineItemsOrderBy>>;
};


export type MainUserLineItemsByUpdatedByArgs = {
  condition: InputMaybe<LineItemCondition>;
  filter: InputMaybe<LineItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LineItemsOrderBy>>;
};


export type MainUserMainUsersByPrivilegedTaskAssigneeIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type MainUserMainUsersByPrivilegedTaskCreatedByAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type MainUserMainUsersByTaskReportAssigneeIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type MainUserMainUsersByTaskReportCreatedByAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type MainUserPrivilegedTasksByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type MainUserProjectStagesByPrivilegedTaskAssigneeIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type MainUserProjectStagesByPrivilegedTaskCreatedByAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type MainUserProjectStagesByTaskReportAssigneeIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type MainUserProjectStagesByTaskReportCreatedByAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type MainUserProjectsByInvoiceCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByInvoiceCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByTaskReportCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserProjectsByTaskReportCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type MainUserTaskReportsByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type MainUserTaskReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type MainUserTaskVisitsByCreatedByArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type MainUserTaskVisitsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type MainUserUserGeolocationSettingsByUserIdArgs = {
  condition: InputMaybe<UserGeolocationSettingCondition>;
  filter: InputMaybe<UserGeolocationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UserGeolocationSettingsOrderBy>>;
};

/**
 * A condition to be used against `MainUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MainUserCondition = {
  /** Checks for equality with the object’s `avatarUrl` field. */
  avatarUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isApiAccessUser` field. */
  isApiAccessUser: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `password` field. */
  password: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `resetPasswordExpires` field. */
  resetPasswordExpires: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `resetPasswordToken` field. */
  resetPasswordToken: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `FeedInbox`. */
export type MainUserContactsByFeedInboxUserIdAndContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `FeedInbox`, and the cursor to aid in pagination. */
  edges: Array<MainUserContactsByFeedInboxUserIdAndContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `FeedInbox`. */
export type MainUserContactsByFeedInboxUserIdAndContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `FeedInbox`. */
export type MainUserContactsByFeedInboxUserIdAndContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesConnection: FeedInboxesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `FeedInbox`. */
export type MainUserContactsByFeedInboxUserIdAndContactIdManyToManyEdgeFeedInboxesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `FeedInbox`. */
export type MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `FeedInbox`, and the cursor to aid in pagination. */
  edges: Array<MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `FeedInbox`. */
export type MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `FeedInbox`. */
export type MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesByFeedIdConnection: FeedInboxesConnection;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `FeedInbox`. */
export type MainUserFeedsByFeedInboxUserIdAndFeedIdManyToManyEdgeFeedInboxesByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};

/** A filter to be used against `MainUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserFilter = {
  /** Filter by the object’s `actionsByCreatedBy` relation. */
  actionsByCreatedBy: InputMaybe<MainUserToManyActionFilter>;
  /** Some related `actionsByCreatedBy` exist. */
  actionsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `analyticsByCreatedBy` relation. */
  analyticsByCreatedBy: InputMaybe<MainUserToManyAnalyticFilter>;
  /** Some related `analyticsByCreatedBy` exist. */
  analyticsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<MainUserFilter>>;
  /** Filter by the object’s `assigneesByUserId` relation. */
  assigneesByUserId: InputMaybe<MainUserToManyAssigneeFilter>;
  /** Some related `assigneesByUserId` exist. */
  assigneesByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `avatarUrl` field. */
  avatarUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `callsByCreatedBy` relation. */
  callsByCreatedBy: InputMaybe<MainUserToManyCallFilter>;
  /** Some related `callsByCreatedBy` exist. */
  callsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `catalogItemsByCreatedBy` relation. */
  catalogItemsByCreatedBy: InputMaybe<MainUserToManyCatalogItemFilter>;
  /** Some related `catalogItemsByCreatedBy` exist. */
  catalogItemsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `commentsByCreatedBy` relation. */
  commentsByCreatedBy: InputMaybe<MainUserToManyCommentFilter>;
  /** Some related `commentsByCreatedBy` exist. */
  commentsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyPhoneUsersByCreatedBy` relation. */
  companyPhoneUsersByCreatedBy: InputMaybe<MainUserToManyCompanyPhoneUserFilter>;
  /** Some related `companyPhoneUsersByCreatedBy` exist. */
  companyPhoneUsersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyPhoneUsersByUserId` relation. */
  companyPhoneUsersByUserId: InputMaybe<MainUserToManyCompanyPhoneUserFilter>;
  /** Some related `companyPhoneUsersByUserId` exist. */
  companyPhoneUsersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyUsersByUserId` relation. */
  companyUsersByUserId: InputMaybe<MainUserToManyCompanyUserFilter>;
  /** Some related `companyUsersByUserId` exist. */
  companyUsersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dashboardSharedUsersByUserId` relation. */
  dashboardSharedUsersByUserId: InputMaybe<MainUserToManyDashboardSharedUserFilter>;
  /** Some related `dashboardSharedUsersByUserId` exist. */
  dashboardSharedUsersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `email` field. */
  email: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailAccountUsersByCreatedBy` relation. */
  emailAccountUsersByCreatedBy: InputMaybe<MainUserToManyEmailAccountUserFilter>;
  /** Some related `emailAccountUsersByCreatedBy` exist. */
  emailAccountUsersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `emailAccountUsersByUserId` relation. */
  emailAccountUsersByUserId: InputMaybe<MainUserToManyEmailAccountUserFilter>;
  /** Some related `emailAccountUsersByUserId` exist. */
  emailAccountUsersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedInboxesByUserId` relation. */
  feedInboxesByUserId: InputMaybe<MainUserToManyFeedInboxFilter>;
  /** Some related `feedInboxesByUserId` exist. */
  feedInboxesByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterFoldersByCreatedBy` relation. */
  filterFoldersByCreatedBy: InputMaybe<MainUserToManyFilterFolderFilter>;
  /** Some related `filterFoldersByCreatedBy` exist. */
  filterFoldersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterToUsersByUserId` relation. */
  filterToUsersByUserId: InputMaybe<MainUserToManyFilterToUserFilter>;
  /** Some related `filterToUsersByUserId` exist. */
  filterToUsersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filtersByCreatedBy` relation. */
  filtersByCreatedBy: InputMaybe<MainUserToManyFilterFilter>;
  /** Some related `filtersByCreatedBy` exist. */
  filtersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstName` field. */
  firstName: InputMaybe<StringFilter>;
  /** Filter by the object’s `formAssigneesByUserId` relation. */
  formAssigneesByUserId: InputMaybe<MainUserToManyFormAssigneeFilter>;
  /** Some related `formAssigneesByUserId` exist. */
  formAssigneesByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `geolocationsByUserId` relation. */
  geolocationsByUserId: InputMaybe<MainUserToManyGeolocationFilter>;
  /** Some related `geolocationsByUserId` exist. */
  geolocationsByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `importHistoriesByCreatedBy` relation. */
  importHistoriesByCreatedBy: InputMaybe<MainUserToManyImportHistoryFilter>;
  /** Some related `importHistoriesByCreatedBy` exist. */
  importHistoriesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `integrationsByCreatedBy` relation. */
  integrationsByCreatedBy: InputMaybe<MainUserToManyIntegrationFilter>;
  /** Some related `integrationsByCreatedBy` exist. */
  integrationsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `invoicesByCreatedBy` relation. */
  invoicesByCreatedBy: InputMaybe<MainUserToManyInvoiceFilter>;
  /** Some related `invoicesByCreatedBy` exist. */
  invoicesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `isApiAccessUser` field. */
  isApiAccessUser: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineItemsByCreatedBy` relation. */
  lineItemsByCreatedBy: InputMaybe<MainUserToManyLineItemFilter>;
  /** Some related `lineItemsByCreatedBy` exist. */
  lineItemsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lineItemsByUpdatedBy` relation. */
  lineItemsByUpdatedBy: InputMaybe<MainUserToManyLineItemFilter>;
  /** Some related `lineItemsByUpdatedBy` exist. */
  lineItemsByUpdatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not: InputMaybe<MainUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<MainUserFilter>>;
  /** Filter by the object’s `password` field. */
  password: InputMaybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  phone: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegedTasksByAssigneeId` relation. */
  privilegedTasksByAssigneeId: InputMaybe<MainUserToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksByAssigneeId` exist. */
  privilegedTasksByAssigneeIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `privilegedTasksByCreatedBy` relation. */
  privilegedTasksByCreatedBy: InputMaybe<MainUserToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksByCreatedBy` exist. */
  privilegedTasksByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `resetPasswordExpires` field. */
  resetPasswordExpires: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `resetPasswordToken` field. */
  resetPasswordToken: InputMaybe<StringFilter>;
  /** Filter by the object’s `taskReportsByAssigneeId` relation. */
  taskReportsByAssigneeId: InputMaybe<MainUserToManyTaskReportFilter>;
  /** Some related `taskReportsByAssigneeId` exist. */
  taskReportsByAssigneeIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskReportsByCreatedBy` relation. */
  taskReportsByCreatedBy: InputMaybe<MainUserToManyTaskReportFilter>;
  /** Some related `taskReportsByCreatedBy` exist. */
  taskReportsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskVisitsByCreatedBy` relation. */
  taskVisitsByCreatedBy: InputMaybe<MainUserToManyTaskVisitFilter>;
  /** Some related `taskVisitsByCreatedBy` exist. */
  taskVisitsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userGeolocationSettingsByUserId` relation. */
  userGeolocationSettingsByUserId: InputMaybe<MainUserToManyUserGeolocationSettingFilter>;
  /** Some related `userGeolocationSettingsByUserId` exist. */
  userGeolocationSettingsByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `FilterFolder` values, with data from `Filter`. */
export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterFolderAggregates>;
  /** A list of edges which contains the `FilterFolder`, info from the `Filter`, and the cursor to aid in pagination. */
  edges: Array<MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterFolderAggregates>>;
  /** A list of `FilterFolder` objects. */
  nodes: Array<Maybe<FilterFolder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FilterFolder` values, with data from `Filter`. */
export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterFolderGroupBy>;
  having: InputMaybe<FilterFolderHavingInput>;
};

/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Filter`. */
  filters: Array<Filter>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersConnection: FiltersConnection;
  /** The `FilterFolder` at the end of the edge. */
  node: Maybe<FilterFolder>;
};


/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyEdgeFiltersArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


/** A `FilterFolder` edge in the connection, with data from `Filter`. */
export type MainUserFilterFoldersByFilterCreatedByAndFilterFolderIdManyToManyEdgeFiltersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};

/** A connection to a list of `Filter` values, with data from `FilterToUser`. */
export type MainUserFiltersByFilterToUserUserIdAndFilterIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterAggregates>;
  /** A list of edges which contains the `Filter`, info from the `FilterToUser`, and the cursor to aid in pagination. */
  edges: Array<MainUserFiltersByFilterToUserUserIdAndFilterIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterAggregates>>;
  /** A list of `Filter` objects. */
  nodes: Array<Maybe<Filter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Filter` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Filter` values, with data from `FilterToUser`. */
export type MainUserFiltersByFilterToUserUserIdAndFilterIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterGroupBy>;
  having: InputMaybe<FilterHavingInput>;
};

/** A `Filter` edge in the connection, with data from `FilterToUser`. */
export type MainUserFiltersByFilterToUserUserIdAndFilterIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Filter` at the end of the edge. */
  node: Maybe<Filter>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskAssigneeIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserPrivilegedTasksByPrivilegedTaskCreatedByAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportAssigneeIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type MainUserPrivilegedTasksByTaskReportCreatedByAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Invoice`. */
export type MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Invoice`. */
export type MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Invoice`. */
export type MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProjectId: Array<Invoice>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProjectIdConnection: InvoicesConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Invoice`. */
export type MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyEdgeInvoicesByProjectIdArgs = {
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Invoice`. */
export type MainUserProjectsByInvoiceCreatedByAndProjectIdManyToManyEdgeInvoicesByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskAssigneeIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserProjectsByPrivilegedTaskCreatedByAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportAssigneeIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type MainUserProjectsByTaskReportCreatedByAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskAssigneeIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type MainUserSystemsByPrivilegedTaskCreatedByAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A filter to be used against many `Action` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyActionFilter = {
  /** Aggregates across related `Action` match the filter criteria. */
  aggregates: InputMaybe<ActionAggregatesFilter>;
  /** Every related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionFilter>;
  /** No related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionFilter>;
  /** Some related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionFilter>;
};

/** A filter to be used against many `Analytic` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyAnalyticFilter = {
  /** Aggregates across related `Analytic` match the filter criteria. */
  aggregates: InputMaybe<AnalyticAggregatesFilter>;
  /** Every related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AnalyticFilter>;
  /** No related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AnalyticFilter>;
  /** Some related `Analytic` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AnalyticFilter>;
};

/** A filter to be used against many `Assignee` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyAssigneeFilter = {
  /** Aggregates across related `Assignee` match the filter criteria. */
  aggregates: InputMaybe<AssigneeAggregatesFilter>;
  /** Every related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AssigneeFilter>;
  /** No related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AssigneeFilter>;
  /** Some related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AssigneeFilter>;
};

/** A filter to be used against many `Call` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyCallFilter = {
  /** Aggregates across related `Call` match the filter criteria. */
  aggregates: InputMaybe<CallAggregatesFilter>;
  /** Every related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CallFilter>;
  /** No related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CallFilter>;
  /** Some related `Call` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CallFilter>;
};

/** A filter to be used against many `CatalogItem` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyCatalogItemFilter = {
  /** Aggregates across related `CatalogItem` match the filter criteria. */
  aggregates: InputMaybe<CatalogItemAggregatesFilter>;
  /** Every related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CatalogItemFilter>;
  /** No related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CatalogItemFilter>;
  /** Some related `CatalogItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CatalogItemFilter>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `CompanyPhoneUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyCompanyPhoneUserFilter = {
  /** Aggregates across related `CompanyPhoneUser` match the filter criteria. */
  aggregates: InputMaybe<CompanyPhoneUserAggregatesFilter>;
  /** Every related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyPhoneUserFilter>;
  /** No related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyPhoneUserFilter>;
  /** Some related `CompanyPhoneUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyPhoneUserFilter>;
};

/** A filter to be used against many `CompanyUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyCompanyUserFilter = {
  /** Aggregates across related `CompanyUser` match the filter criteria. */
  aggregates: InputMaybe<CompanyUserAggregatesFilter>;
  /** Every related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyUserFilter>;
  /** No related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyUserFilter>;
  /** Some related `CompanyUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyUserFilter>;
};

/** A filter to be used against many `DashboardSharedUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyDashboardSharedUserFilter = {
  /** Aggregates across related `DashboardSharedUser` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedUserAggregatesFilter>;
  /** Every related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedUserFilter>;
  /** No related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedUserFilter>;
  /** Some related `DashboardSharedUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedUserFilter>;
};

/** A filter to be used against many `EmailAccountUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyEmailAccountUserFilter = {
  /** Aggregates across related `EmailAccountUser` match the filter criteria. */
  aggregates: InputMaybe<EmailAccountUserAggregatesFilter>;
  /** Every related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<EmailAccountUserFilter>;
  /** No related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<EmailAccountUserFilter>;
  /** Some related `EmailAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<EmailAccountUserFilter>;
};

/** A filter to be used against many `FeedInbox` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyFeedInboxFilter = {
  /** Aggregates across related `FeedInbox` match the filter criteria. */
  aggregates: InputMaybe<FeedInboxAggregatesFilter>;
  /** Every related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedInboxFilter>;
  /** No related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedInboxFilter>;
  /** Some related `FeedInbox` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedInboxFilter>;
};

/** A filter to be used against many `Filter` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyFilterFilter = {
  /** Aggregates across related `Filter` match the filter criteria. */
  aggregates: InputMaybe<FilterAggregatesFilter>;
  /** Every related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterFilter>;
  /** No related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterFilter>;
  /** Some related `Filter` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterFilter>;
};

/** A filter to be used against many `FilterFolder` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyFilterFolderFilter = {
  /** Aggregates across related `FilterFolder` match the filter criteria. */
  aggregates: InputMaybe<FilterFolderAggregatesFilter>;
  /** Every related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterFolderFilter>;
  /** No related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterFolderFilter>;
  /** Some related `FilterFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterFolderFilter>;
};

/** A filter to be used against many `FilterToUser` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyFilterToUserFilter = {
  /** Aggregates across related `FilterToUser` match the filter criteria. */
  aggregates: InputMaybe<FilterToUserAggregatesFilter>;
  /** Every related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToUserFilter>;
  /** No related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToUserFilter>;
  /** Some related `FilterToUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToUserFilter>;
};

/** A filter to be used against many `FormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyFormAssigneeFilter = {
  /** Aggregates across related `FormAssignee` match the filter criteria. */
  aggregates: InputMaybe<FormAssigneeAggregatesFilter>;
  /** Every related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormAssigneeFilter>;
  /** No related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormAssigneeFilter>;
  /** Some related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormAssigneeFilter>;
};

/** A filter to be used against many `Geolocation` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyGeolocationFilter = {
  /** Aggregates across related `Geolocation` match the filter criteria. */
  aggregates: InputMaybe<GeolocationAggregatesFilter>;
  /** Every related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeolocationFilter>;
  /** No related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeolocationFilter>;
  /** Some related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeolocationFilter>;
};

/** A filter to be used against many `ImportHistory` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyImportHistoryFilter = {
  /** Aggregates across related `ImportHistory` match the filter criteria. */
  aggregates: InputMaybe<ImportHistoryAggregatesFilter>;
  /** Every related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ImportHistoryFilter>;
  /** No related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ImportHistoryFilter>;
  /** Some related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ImportHistoryFilter>;
};

/** A filter to be used against many `Integration` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyIntegrationFilter = {
  /** Aggregates across related `Integration` match the filter criteria. */
  aggregates: InputMaybe<IntegrationAggregatesFilter>;
  /** Every related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<IntegrationFilter>;
  /** No related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<IntegrationFilter>;
  /** Some related `Integration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<IntegrationFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyInvoiceFilter = {
  /** Aggregates across related `Invoice` match the filter criteria. */
  aggregates: InputMaybe<InvoiceAggregatesFilter>;
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<InvoiceFilter>;
};

/** A filter to be used against many `LineItem` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyLineItemFilter = {
  /** Aggregates across related `LineItem` match the filter criteria. */
  aggregates: InputMaybe<LineItemAggregatesFilter>;
  /** Every related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<LineItemFilter>;
  /** No related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<LineItemFilter>;
  /** Some related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<LineItemFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

/** A filter to be used against many `TaskVisit` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyTaskVisitFilter = {
  /** Aggregates across related `TaskVisit` match the filter criteria. */
  aggregates: InputMaybe<TaskVisitAggregatesFilter>;
  /** Every related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskVisitFilter>;
  /** No related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskVisitFilter>;
  /** Some related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskVisitFilter>;
};

/** A filter to be used against many `UserGeolocationSetting` object types. All fields are combined with a logical ‘and.’ */
export type MainUserToManyUserGeolocationSettingFilter = {
  /** Aggregates across related `UserGeolocationSetting` match the filter criteria. */
  aggregates: InputMaybe<UserGeolocationSettingAggregatesFilter>;
  /** Every related `UserGeolocationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<UserGeolocationSettingFilter>;
  /** No related `UserGeolocationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<UserGeolocationSettingFilter>;
  /** Some related `UserGeolocationSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<UserGeolocationSettingFilter>;
};

/** Methods to use when ordering `MainUser`. */
export enum MainUsersOrderBy {
  ActionsByCreatedByConnectionArrayAggActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionArrayAggActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionArrayAggAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionArrayAggAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionArrayAggCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionArrayAggCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionArrayAggCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionArrayAggCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionArrayAggCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ActionsByCreatedByConnectionArrayAggCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ActionsByCreatedByConnectionArrayAggCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ActionsByCreatedByConnectionArrayAggCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ActionsByCreatedByConnectionArrayAggDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionArrayAggDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionArrayAggDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  ActionsByCreatedByConnectionArrayAggDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  ActionsByCreatedByConnectionArrayAggIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ActionsByCreatedByConnectionArrayAggIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ActionsByCreatedByConnectionArrayAggIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionArrayAggIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionArrayAggJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ActionsByCreatedByConnectionArrayAggJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ActionsByCreatedByConnectionArrayAggProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionArrayAggProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionArrayAggProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionArrayAggProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionArrayAggStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ActionsByCreatedByConnectionArrayAggStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ActionsByCreatedByConnectionArrayAggTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ActionsByCreatedByConnectionArrayAggTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ActionsByCreatedByConnectionArrayAggTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ActionsByCreatedByConnectionArrayAggTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ActionsByCreatedByConnectionArrayAggUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionArrayAggUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionArrayAggUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_ASC',
  ActionsByCreatedByConnectionArrayAggUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_DESC',
  ActionsByCreatedByConnectionAverageActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionAverageActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionAverageAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionAverageAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionAverageCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionAverageCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionAverageCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionAverageCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionAverageCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ActionsByCreatedByConnectionAverageCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ActionsByCreatedByConnectionAverageCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ActionsByCreatedByConnectionAverageCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ActionsByCreatedByConnectionAverageDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionAverageDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionAverageDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_ASC',
  ActionsByCreatedByConnectionAverageDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_DESC',
  ActionsByCreatedByConnectionAverageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ActionsByCreatedByConnectionAverageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ActionsByCreatedByConnectionAverageIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionAverageIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionAverageJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  ActionsByCreatedByConnectionAverageJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  ActionsByCreatedByConnectionAverageProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionAverageProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionAverageProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionAverageProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionAverageStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  ActionsByCreatedByConnectionAverageStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  ActionsByCreatedByConnectionAverageTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  ActionsByCreatedByConnectionAverageTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  ActionsByCreatedByConnectionAverageTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  ActionsByCreatedByConnectionAverageTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  ActionsByCreatedByConnectionAverageUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionAverageUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionAverageUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_ASC',
  ActionsByCreatedByConnectionAverageUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_DESC',
  ActionsByCreatedByConnectionCountAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ActionsByCreatedByConnectionCountDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ActionsByCreatedByConnectionDistinctCountActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionDistinctCountAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionDistinctCountCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionDistinctCountCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionDistinctCountCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ActionsByCreatedByConnectionDistinctCountCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ActionsByCreatedByConnectionDistinctCountCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ActionsByCreatedByConnectionDistinctCountCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ActionsByCreatedByConnectionDistinctCountDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionDistinctCountDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionDistinctCountDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  ActionsByCreatedByConnectionDistinctCountDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  ActionsByCreatedByConnectionDistinctCountIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionDistinctCountIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionDistinctCountJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionDistinctCountProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionDistinctCountStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ActionsByCreatedByConnectionDistinctCountStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ActionsByCreatedByConnectionDistinctCountTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ActionsByCreatedByConnectionDistinctCountTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ActionsByCreatedByConnectionDistinctCountTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ActionsByCreatedByConnectionDistinctCountTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ActionsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionDistinctCountUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  ActionsByCreatedByConnectionDistinctCountUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  ActionsByCreatedByConnectionMaxActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionMaxActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionMaxAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionMaxAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionMaxCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionMaxCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionMaxCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionMaxCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionMaxCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ActionsByCreatedByConnectionMaxCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ActionsByCreatedByConnectionMaxCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ActionsByCreatedByConnectionMaxCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ActionsByCreatedByConnectionMaxDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionMaxDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionMaxDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_ASC',
  ActionsByCreatedByConnectionMaxDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_DESC',
  ActionsByCreatedByConnectionMaxIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ActionsByCreatedByConnectionMaxIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ActionsByCreatedByConnectionMaxIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionMaxIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionMaxJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  ActionsByCreatedByConnectionMaxJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  ActionsByCreatedByConnectionMaxProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionMaxProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionMaxProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionMaxProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionMaxStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  ActionsByCreatedByConnectionMaxStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  ActionsByCreatedByConnectionMaxTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  ActionsByCreatedByConnectionMaxTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  ActionsByCreatedByConnectionMaxTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  ActionsByCreatedByConnectionMaxTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  ActionsByCreatedByConnectionMaxUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionMaxUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionMaxUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_ASC',
  ActionsByCreatedByConnectionMaxUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_DESC',
  ActionsByCreatedByConnectionMinActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionMinActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionMinAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionMinAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionMinCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionMinCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionMinCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionMinCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionMinCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ActionsByCreatedByConnectionMinCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ActionsByCreatedByConnectionMinCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ActionsByCreatedByConnectionMinCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ActionsByCreatedByConnectionMinDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionMinDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionMinDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_ASC',
  ActionsByCreatedByConnectionMinDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_DESC',
  ActionsByCreatedByConnectionMinIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ActionsByCreatedByConnectionMinIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ActionsByCreatedByConnectionMinIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionMinIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionMinJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  ActionsByCreatedByConnectionMinJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  ActionsByCreatedByConnectionMinProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionMinProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionMinProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionMinProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionMinStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  ActionsByCreatedByConnectionMinStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  ActionsByCreatedByConnectionMinTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  ActionsByCreatedByConnectionMinTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  ActionsByCreatedByConnectionMinTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  ActionsByCreatedByConnectionMinTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  ActionsByCreatedByConnectionMinUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionMinUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionMinUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_ASC',
  ActionsByCreatedByConnectionMinUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_DESC',
  ActionsByCreatedByConnectionStddevPopulationActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionStddevPopulationAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionStddevPopulationCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ActionsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ActionsByCreatedByConnectionStddevPopulationCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ActionsByCreatedByConnectionStddevPopulationCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ActionsByCreatedByConnectionStddevPopulationDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionStddevPopulationDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionStddevPopulationDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  ActionsByCreatedByConnectionStddevPopulationDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  ActionsByCreatedByConnectionStddevPopulationIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionStddevPopulationIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionStddevPopulationJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionStddevPopulationProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionStddevPopulationStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ActionsByCreatedByConnectionStddevPopulationStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ActionsByCreatedByConnectionStddevPopulationTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ActionsByCreatedByConnectionStddevPopulationTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ActionsByCreatedByConnectionStddevPopulationTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ActionsByCreatedByConnectionStddevPopulationTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ActionsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionStddevPopulationUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  ActionsByCreatedByConnectionStddevPopulationUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  ActionsByCreatedByConnectionStddevSampleActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionStddevSampleAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionStddevSampleCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionStddevSampleCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionStddevSampleCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ActionsByCreatedByConnectionStddevSampleCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ActionsByCreatedByConnectionStddevSampleCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ActionsByCreatedByConnectionStddevSampleCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ActionsByCreatedByConnectionStddevSampleDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionStddevSampleDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionStddevSampleDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  ActionsByCreatedByConnectionStddevSampleDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  ActionsByCreatedByConnectionStddevSampleIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionStddevSampleIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionStddevSampleJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionStddevSampleProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionStddevSampleStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ActionsByCreatedByConnectionStddevSampleStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ActionsByCreatedByConnectionStddevSampleTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ActionsByCreatedByConnectionStddevSampleTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ActionsByCreatedByConnectionStddevSampleTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ActionsByCreatedByConnectionStddevSampleTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ActionsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionStddevSampleUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  ActionsByCreatedByConnectionStddevSampleUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  ActionsByCreatedByConnectionSumActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionSumActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionSumAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionSumAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionSumCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionSumCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionSumCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionSumCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionSumCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ActionsByCreatedByConnectionSumCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ActionsByCreatedByConnectionSumCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ActionsByCreatedByConnectionSumCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ActionsByCreatedByConnectionSumDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionSumDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionSumDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_ASC',
  ActionsByCreatedByConnectionSumDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_DESC',
  ActionsByCreatedByConnectionSumIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ActionsByCreatedByConnectionSumIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ActionsByCreatedByConnectionSumIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionSumIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionSumJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  ActionsByCreatedByConnectionSumJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  ActionsByCreatedByConnectionSumProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionSumProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionSumProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionSumProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionSumStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  ActionsByCreatedByConnectionSumStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  ActionsByCreatedByConnectionSumTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  ActionsByCreatedByConnectionSumTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  ActionsByCreatedByConnectionSumTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  ActionsByCreatedByConnectionSumTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  ActionsByCreatedByConnectionSumUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionSumUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionSumUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_ASC',
  ActionsByCreatedByConnectionSumUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_DESC',
  ActionsByCreatedByConnectionVariancePopulationActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionVariancePopulationAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionVariancePopulationCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ActionsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ActionsByCreatedByConnectionVariancePopulationCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ActionsByCreatedByConnectionVariancePopulationCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ActionsByCreatedByConnectionVariancePopulationDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionVariancePopulationDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionVariancePopulationDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  ActionsByCreatedByConnectionVariancePopulationDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  ActionsByCreatedByConnectionVariancePopulationIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionVariancePopulationIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionVariancePopulationJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionVariancePopulationProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionVariancePopulationStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ActionsByCreatedByConnectionVariancePopulationStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ActionsByCreatedByConnectionVariancePopulationTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ActionsByCreatedByConnectionVariancePopulationTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ActionsByCreatedByConnectionVariancePopulationTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ActionsByCreatedByConnectionVariancePopulationTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ActionsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionVariancePopulationUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  ActionsByCreatedByConnectionVariancePopulationUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  ActionsByCreatedByConnectionVarianceSampleActionTemplateIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleActionTemplateIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleAssignAllContactsAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByCreatedByConnectionVarianceSampleAssignAllContactsDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleCompletedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  ActionsByCreatedByConnectionVarianceSampleCompletedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  ActionsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ActionsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ActionsByCreatedByConnectionVarianceSampleCreatedByAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ActionsByCreatedByConnectionVarianceSampleCreatedByDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ActionsByCreatedByConnectionVarianceSampleDescriptionAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ActionsByCreatedByConnectionVarianceSampleDescriptionDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ActionsByCreatedByConnectionVarianceSampleDueDateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  ActionsByCreatedByConnectionVarianceSampleDueDateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  ActionsByCreatedByConnectionVarianceSampleIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleIsTemplateAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  ActionsByCreatedByConnectionVarianceSampleIsTemplateDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  ActionsByCreatedByConnectionVarianceSampleJobIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleJobIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleProjectIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleProjectIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleProjectStageIdAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsByCreatedByConnectionVarianceSampleProjectStageIdDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsByCreatedByConnectionVarianceSampleStatusAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ActionsByCreatedByConnectionVarianceSampleStatusDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ActionsByCreatedByConnectionVarianceSampleTitleAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ActionsByCreatedByConnectionVarianceSampleTitleDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ActionsByCreatedByConnectionVarianceSampleTypeAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ActionsByCreatedByConnectionVarianceSampleTypeDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ActionsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ActionsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ActionsByCreatedByConnectionVarianceSampleUuidAsc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  ActionsByCreatedByConnectionVarianceSampleUuidDesc = 'ACTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  AnalyticsByCreatedByConnectionArrayAggArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ARGS_ASC',
  AnalyticsByCreatedByConnectionArrayAggArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ARGS_DESC',
  AnalyticsByCreatedByConnectionArrayAggCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionArrayAggCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionArrayAggCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionArrayAggCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionArrayAggCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionArrayAggCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionArrayAggDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionArrayAggDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionArrayAggIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  AnalyticsByCreatedByConnectionArrayAggIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  AnalyticsByCreatedByConnectionArrayAggSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionArrayAggSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionArrayAggTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  AnalyticsByCreatedByConnectionArrayAggTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  AnalyticsByCreatedByConnectionArrayAggTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  AnalyticsByCreatedByConnectionArrayAggTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  AnalyticsByCreatedByConnectionArrayAggUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionArrayAggUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionAverageArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_ARGS_ASC',
  AnalyticsByCreatedByConnectionAverageArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_ARGS_DESC',
  AnalyticsByCreatedByConnectionAverageCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionAverageCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionAverageCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionAverageCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionAverageCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionAverageCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionAverageDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionAverageDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionAverageIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  AnalyticsByCreatedByConnectionAverageIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  AnalyticsByCreatedByConnectionAverageSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionAverageSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionAverageTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  AnalyticsByCreatedByConnectionAverageTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  AnalyticsByCreatedByConnectionAverageTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  AnalyticsByCreatedByConnectionAverageTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  AnalyticsByCreatedByConnectionAverageUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionAverageUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionCountAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  AnalyticsByCreatedByConnectionCountDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  AnalyticsByCreatedByConnectionDistinctCountArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ARGS_ASC',
  AnalyticsByCreatedByConnectionDistinctCountArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ARGS_DESC',
  AnalyticsByCreatedByConnectionDistinctCountCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionDistinctCountCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionDistinctCountCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionDistinctCountCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionDistinctCountCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionDistinctCountCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionDistinctCountDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionDistinctCountDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionDistinctCountIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  AnalyticsByCreatedByConnectionDistinctCountIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  AnalyticsByCreatedByConnectionDistinctCountSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionDistinctCountSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionDistinctCountTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  AnalyticsByCreatedByConnectionDistinctCountTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  AnalyticsByCreatedByConnectionDistinctCountTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  AnalyticsByCreatedByConnectionDistinctCountTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  AnalyticsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionMaxArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_ARGS_ASC',
  AnalyticsByCreatedByConnectionMaxArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_ARGS_DESC',
  AnalyticsByCreatedByConnectionMaxCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionMaxCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionMaxCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionMaxCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionMaxCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionMaxCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionMaxDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionMaxDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionMaxIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  AnalyticsByCreatedByConnectionMaxIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  AnalyticsByCreatedByConnectionMaxSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionMaxSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionMaxTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  AnalyticsByCreatedByConnectionMaxTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  AnalyticsByCreatedByConnectionMaxTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  AnalyticsByCreatedByConnectionMaxTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  AnalyticsByCreatedByConnectionMaxUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionMaxUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionMinArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_ARGS_ASC',
  AnalyticsByCreatedByConnectionMinArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_ARGS_DESC',
  AnalyticsByCreatedByConnectionMinCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionMinCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionMinCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionMinCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionMinCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionMinCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionMinDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionMinDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionMinIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  AnalyticsByCreatedByConnectionMinIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  AnalyticsByCreatedByConnectionMinSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionMinSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionMinTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  AnalyticsByCreatedByConnectionMinTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  AnalyticsByCreatedByConnectionMinTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  AnalyticsByCreatedByConnectionMinTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  AnalyticsByCreatedByConnectionMinUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionMinUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ARGS_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ARGS_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  AnalyticsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionStddevSampleArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ARGS_ASC',
  AnalyticsByCreatedByConnectionStddevSampleArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ARGS_DESC',
  AnalyticsByCreatedByConnectionStddevSampleCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionStddevSampleCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionStddevSampleCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionStddevSampleCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionStddevSampleCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionStddevSampleCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionStddevSampleDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionStddevSampleDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionStddevSampleIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  AnalyticsByCreatedByConnectionStddevSampleIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  AnalyticsByCreatedByConnectionStddevSampleSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionStddevSampleSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionStddevSampleTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  AnalyticsByCreatedByConnectionStddevSampleTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  AnalyticsByCreatedByConnectionStddevSampleTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  AnalyticsByCreatedByConnectionStddevSampleTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  AnalyticsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionSumArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_ARGS_ASC',
  AnalyticsByCreatedByConnectionSumArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_ARGS_DESC',
  AnalyticsByCreatedByConnectionSumCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionSumCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionSumCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionSumCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionSumCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionSumCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionSumDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionSumDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionSumIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  AnalyticsByCreatedByConnectionSumIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  AnalyticsByCreatedByConnectionSumSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionSumSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionSumTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  AnalyticsByCreatedByConnectionSumTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  AnalyticsByCreatedByConnectionSumTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  AnalyticsByCreatedByConnectionSumTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  AnalyticsByCreatedByConnectionSumUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionSumUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ARGS_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ARGS_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  AnalyticsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleArgsAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ARGS_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleArgsDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ARGS_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleCreatedByAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleCreatedByDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleDashboardIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleDashboardIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleIdAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleIdDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleSubtitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleSubtitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SUBTITLE_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleTitleAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleTitleDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleTypeAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleTypeDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  AnalyticsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnalyticsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'ANALYTICS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  AssigneesByUserIdConnectionArrayAggProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionArrayAggProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionArrayAggTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  AssigneesByUserIdConnectionArrayAggTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  AssigneesByUserIdConnectionArrayAggUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  AssigneesByUserIdConnectionArrayAggUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  AssigneesByUserIdConnectionAverageProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionAverageProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionAverageTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  AssigneesByUserIdConnectionAverageTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  AssigneesByUserIdConnectionAverageUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  AssigneesByUserIdConnectionAverageUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  AssigneesByUserIdConnectionCountAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_COUNT_ASC',
  AssigneesByUserIdConnectionCountDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_COUNT_DESC',
  AssigneesByUserIdConnectionDistinctCountProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionDistinctCountProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionDistinctCountTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  AssigneesByUserIdConnectionDistinctCountTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  AssigneesByUserIdConnectionDistinctCountUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  AssigneesByUserIdConnectionDistinctCountUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  AssigneesByUserIdConnectionMaxProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionMaxProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionMaxTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_TASK_ID_ASC',
  AssigneesByUserIdConnectionMaxTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_TASK_ID_DESC',
  AssigneesByUserIdConnectionMaxUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  AssigneesByUserIdConnectionMaxUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  AssigneesByUserIdConnectionMinProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionMinProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionMinTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_TASK_ID_ASC',
  AssigneesByUserIdConnectionMinTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_TASK_ID_DESC',
  AssigneesByUserIdConnectionMinUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  AssigneesByUserIdConnectionMinUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  AssigneesByUserIdConnectionStddevPopulationProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionStddevPopulationProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionStddevPopulationTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  AssigneesByUserIdConnectionStddevPopulationTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  AssigneesByUserIdConnectionStddevPopulationUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  AssigneesByUserIdConnectionStddevPopulationUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  AssigneesByUserIdConnectionStddevSampleProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionStddevSampleProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionStddevSampleTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  AssigneesByUserIdConnectionStddevSampleTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  AssigneesByUserIdConnectionStddevSampleUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  AssigneesByUserIdConnectionStddevSampleUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  AssigneesByUserIdConnectionSumProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionSumProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionSumTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_TASK_ID_ASC',
  AssigneesByUserIdConnectionSumTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_TASK_ID_DESC',
  AssigneesByUserIdConnectionSumUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  AssigneesByUserIdConnectionSumUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  AssigneesByUserIdConnectionVariancePopulationProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionVariancePopulationProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionVariancePopulationTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  AssigneesByUserIdConnectionVariancePopulationTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  AssigneesByUserIdConnectionVariancePopulationUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  AssigneesByUserIdConnectionVariancePopulationUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  AssigneesByUserIdConnectionVarianceSampleProjectMemberIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByUserIdConnectionVarianceSampleProjectMemberIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByUserIdConnectionVarianceSampleTaskIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  AssigneesByUserIdConnectionVarianceSampleTaskIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  AssigneesByUserIdConnectionVarianceSampleUserIdAsc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  AssigneesByUserIdConnectionVarianceSampleUserIdDesc = 'ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  AvatarUrlAsc = 'AVATAR_URL_ASC',
  AvatarUrlDesc = 'AVATAR_URL_DESC',
  CallsByCreatedByConnectionArrayAggCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CallsByCreatedByConnectionArrayAggCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CallsByCreatedByConnectionArrayAggCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CallsByCreatedByConnectionArrayAggCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CallsByCreatedByConnectionArrayAggCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CallsByCreatedByConnectionArrayAggCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CallsByCreatedByConnectionArrayAggDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DISPOSITION_ASC',
  CallsByCreatedByConnectionArrayAggDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DISPOSITION_DESC',
  CallsByCreatedByConnectionArrayAggDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionArrayAggDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionArrayAggEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_TIME_ASC',
  CallsByCreatedByConnectionArrayAggEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_TIME_DESC',
  CallsByCreatedByConnectionArrayAggFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionArrayAggFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionArrayAggFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionArrayAggFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionArrayAggIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  CallsByCreatedByConnectionArrayAggIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  CallsByCreatedByConnectionArrayAggIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_INBOUND_ASC',
  CallsByCreatedByConnectionArrayAggIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_INBOUND_DESC',
  CallsByCreatedByConnectionArrayAggMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionArrayAggMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionArrayAggNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTE_ASC',
  CallsByCreatedByConnectionArrayAggNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTE_DESC',
  CallsByCreatedByConnectionArrayAggOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OUTCOME_ASC',
  CallsByCreatedByConnectionArrayAggOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OUTCOME_DESC',
  CallsByCreatedByConnectionArrayAggProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CallsByCreatedByConnectionArrayAggProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CallsByCreatedByConnectionArrayAggReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REASON_ASC',
  CallsByCreatedByConnectionArrayAggReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REASON_DESC',
  CallsByCreatedByConnectionArrayAggRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RECORDING_URL_ASC',
  CallsByCreatedByConnectionArrayAggRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RECORDING_URL_DESC',
  CallsByCreatedByConnectionArrayAggStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_TIME_ASC',
  CallsByCreatedByConnectionArrayAggStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_TIME_DESC',
  CallsByCreatedByConnectionArrayAggToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionArrayAggToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionArrayAggToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_NUMBER_ASC',
  CallsByCreatedByConnectionArrayAggToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_NUMBER_DESC',
  CallsByCreatedByConnectionArrayAggTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionArrayAggTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionArrayAggUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CallsByCreatedByConnectionArrayAggUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CallsByCreatedByConnectionAverageCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CallsByCreatedByConnectionAverageCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CallsByCreatedByConnectionAverageCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CallsByCreatedByConnectionAverageCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CallsByCreatedByConnectionAverageCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CallsByCreatedByConnectionAverageCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CallsByCreatedByConnectionAverageDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_DISPOSITION_ASC',
  CallsByCreatedByConnectionAverageDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_DISPOSITION_DESC',
  CallsByCreatedByConnectionAverageDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionAverageDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionAverageEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_END_TIME_ASC',
  CallsByCreatedByConnectionAverageEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_END_TIME_DESC',
  CallsByCreatedByConnectionAverageFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionAverageFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionAverageFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionAverageFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionAverageIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  CallsByCreatedByConnectionAverageIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  CallsByCreatedByConnectionAverageIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_INBOUND_ASC',
  CallsByCreatedByConnectionAverageIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_INBOUND_DESC',
  CallsByCreatedByConnectionAverageMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionAverageMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionAverageNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_NOTE_ASC',
  CallsByCreatedByConnectionAverageNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_NOTE_DESC',
  CallsByCreatedByConnectionAverageOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_OUTCOME_ASC',
  CallsByCreatedByConnectionAverageOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_OUTCOME_DESC',
  CallsByCreatedByConnectionAverageProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CallsByCreatedByConnectionAverageProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CallsByCreatedByConnectionAverageReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_REASON_ASC',
  CallsByCreatedByConnectionAverageReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_REASON_DESC',
  CallsByCreatedByConnectionAverageRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_RECORDING_URL_ASC',
  CallsByCreatedByConnectionAverageRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_RECORDING_URL_DESC',
  CallsByCreatedByConnectionAverageStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_START_TIME_ASC',
  CallsByCreatedByConnectionAverageStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_START_TIME_DESC',
  CallsByCreatedByConnectionAverageToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionAverageToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionAverageToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TO_NUMBER_ASC',
  CallsByCreatedByConnectionAverageToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TO_NUMBER_DESC',
  CallsByCreatedByConnectionAverageTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionAverageTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionAverageUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CallsByCreatedByConnectionAverageUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CallsByCreatedByConnectionCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  CallsByCreatedByConnectionCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  CallsByCreatedByConnectionDistinctCountCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CallsByCreatedByConnectionDistinctCountCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CallsByCreatedByConnectionDistinctCountCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CallsByCreatedByConnectionDistinctCountCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CallsByCreatedByConnectionDistinctCountCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CallsByCreatedByConnectionDistinctCountCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CallsByCreatedByConnectionDistinctCountDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DISPOSITION_ASC',
  CallsByCreatedByConnectionDistinctCountDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DISPOSITION_DESC',
  CallsByCreatedByConnectionDistinctCountDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionDistinctCountDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionDistinctCountEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_TIME_ASC',
  CallsByCreatedByConnectionDistinctCountEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_TIME_DESC',
  CallsByCreatedByConnectionDistinctCountFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionDistinctCountFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionDistinctCountFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionDistinctCountFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionDistinctCountIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CallsByCreatedByConnectionDistinctCountIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CallsByCreatedByConnectionDistinctCountIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_INBOUND_ASC',
  CallsByCreatedByConnectionDistinctCountIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_INBOUND_DESC',
  CallsByCreatedByConnectionDistinctCountMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionDistinctCountMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionDistinctCountNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTE_ASC',
  CallsByCreatedByConnectionDistinctCountNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTE_DESC',
  CallsByCreatedByConnectionDistinctCountOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OUTCOME_ASC',
  CallsByCreatedByConnectionDistinctCountOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OUTCOME_DESC',
  CallsByCreatedByConnectionDistinctCountProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CallsByCreatedByConnectionDistinctCountProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CallsByCreatedByConnectionDistinctCountReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REASON_ASC',
  CallsByCreatedByConnectionDistinctCountReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REASON_DESC',
  CallsByCreatedByConnectionDistinctCountRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RECORDING_URL_ASC',
  CallsByCreatedByConnectionDistinctCountRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RECORDING_URL_DESC',
  CallsByCreatedByConnectionDistinctCountStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_TIME_ASC',
  CallsByCreatedByConnectionDistinctCountStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_TIME_DESC',
  CallsByCreatedByConnectionDistinctCountToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionDistinctCountToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionDistinctCountToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_NUMBER_ASC',
  CallsByCreatedByConnectionDistinctCountToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_NUMBER_DESC',
  CallsByCreatedByConnectionDistinctCountTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionDistinctCountTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CallsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CallsByCreatedByConnectionMaxCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  CallsByCreatedByConnectionMaxCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  CallsByCreatedByConnectionMaxCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  CallsByCreatedByConnectionMaxCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  CallsByCreatedByConnectionMaxCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  CallsByCreatedByConnectionMaxCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  CallsByCreatedByConnectionMaxDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_DISPOSITION_ASC',
  CallsByCreatedByConnectionMaxDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_DISPOSITION_DESC',
  CallsByCreatedByConnectionMaxDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionMaxDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionMaxEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_END_TIME_ASC',
  CallsByCreatedByConnectionMaxEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_END_TIME_DESC',
  CallsByCreatedByConnectionMaxFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionMaxFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionMaxFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionMaxFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionMaxIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  CallsByCreatedByConnectionMaxIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  CallsByCreatedByConnectionMaxIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_IS_INBOUND_ASC',
  CallsByCreatedByConnectionMaxIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_IS_INBOUND_DESC',
  CallsByCreatedByConnectionMaxMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionMaxMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionMaxNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_NOTE_ASC',
  CallsByCreatedByConnectionMaxNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_NOTE_DESC',
  CallsByCreatedByConnectionMaxOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_OUTCOME_ASC',
  CallsByCreatedByConnectionMaxOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_OUTCOME_DESC',
  CallsByCreatedByConnectionMaxProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  CallsByCreatedByConnectionMaxProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  CallsByCreatedByConnectionMaxReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_REASON_ASC',
  CallsByCreatedByConnectionMaxReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_REASON_DESC',
  CallsByCreatedByConnectionMaxRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_RECORDING_URL_ASC',
  CallsByCreatedByConnectionMaxRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_RECORDING_URL_DESC',
  CallsByCreatedByConnectionMaxStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_START_TIME_ASC',
  CallsByCreatedByConnectionMaxStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_START_TIME_DESC',
  CallsByCreatedByConnectionMaxToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionMaxToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionMaxToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TO_NUMBER_ASC',
  CallsByCreatedByConnectionMaxToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TO_NUMBER_DESC',
  CallsByCreatedByConnectionMaxTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionMaxTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionMaxUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  CallsByCreatedByConnectionMaxUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  CallsByCreatedByConnectionMinCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  CallsByCreatedByConnectionMinCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  CallsByCreatedByConnectionMinCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  CallsByCreatedByConnectionMinCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  CallsByCreatedByConnectionMinCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  CallsByCreatedByConnectionMinCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  CallsByCreatedByConnectionMinDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_DISPOSITION_ASC',
  CallsByCreatedByConnectionMinDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_DISPOSITION_DESC',
  CallsByCreatedByConnectionMinDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionMinDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionMinEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_END_TIME_ASC',
  CallsByCreatedByConnectionMinEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_END_TIME_DESC',
  CallsByCreatedByConnectionMinFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionMinFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionMinFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionMinFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionMinIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  CallsByCreatedByConnectionMinIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  CallsByCreatedByConnectionMinIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_IS_INBOUND_ASC',
  CallsByCreatedByConnectionMinIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_IS_INBOUND_DESC',
  CallsByCreatedByConnectionMinMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionMinMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionMinNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_NOTE_ASC',
  CallsByCreatedByConnectionMinNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_NOTE_DESC',
  CallsByCreatedByConnectionMinOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_OUTCOME_ASC',
  CallsByCreatedByConnectionMinOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_OUTCOME_DESC',
  CallsByCreatedByConnectionMinProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  CallsByCreatedByConnectionMinProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  CallsByCreatedByConnectionMinReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_REASON_ASC',
  CallsByCreatedByConnectionMinReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_REASON_DESC',
  CallsByCreatedByConnectionMinRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_RECORDING_URL_ASC',
  CallsByCreatedByConnectionMinRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_RECORDING_URL_DESC',
  CallsByCreatedByConnectionMinStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_START_TIME_ASC',
  CallsByCreatedByConnectionMinStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_START_TIME_DESC',
  CallsByCreatedByConnectionMinToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionMinToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionMinToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TO_NUMBER_ASC',
  CallsByCreatedByConnectionMinToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TO_NUMBER_DESC',
  CallsByCreatedByConnectionMinTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionMinTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionMinUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  CallsByCreatedByConnectionMinUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  CallsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CallsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CallsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CallsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CallsByCreatedByConnectionStddevPopulationCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CallsByCreatedByConnectionStddevPopulationCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CallsByCreatedByConnectionStddevPopulationDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DISPOSITION_ASC',
  CallsByCreatedByConnectionStddevPopulationDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DISPOSITION_DESC',
  CallsByCreatedByConnectionStddevPopulationDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionStddevPopulationDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionStddevPopulationEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_TIME_ASC',
  CallsByCreatedByConnectionStddevPopulationEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_TIME_DESC',
  CallsByCreatedByConnectionStddevPopulationFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionStddevPopulationFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionStddevPopulationFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionStddevPopulationFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionStddevPopulationIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CallsByCreatedByConnectionStddevPopulationIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CallsByCreatedByConnectionStddevPopulationIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_INBOUND_ASC',
  CallsByCreatedByConnectionStddevPopulationIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_INBOUND_DESC',
  CallsByCreatedByConnectionStddevPopulationMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionStddevPopulationMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionStddevPopulationNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTE_ASC',
  CallsByCreatedByConnectionStddevPopulationNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTE_DESC',
  CallsByCreatedByConnectionStddevPopulationOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OUTCOME_ASC',
  CallsByCreatedByConnectionStddevPopulationOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OUTCOME_DESC',
  CallsByCreatedByConnectionStddevPopulationProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CallsByCreatedByConnectionStddevPopulationProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CallsByCreatedByConnectionStddevPopulationReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REASON_ASC',
  CallsByCreatedByConnectionStddevPopulationReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REASON_DESC',
  CallsByCreatedByConnectionStddevPopulationRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RECORDING_URL_ASC',
  CallsByCreatedByConnectionStddevPopulationRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RECORDING_URL_DESC',
  CallsByCreatedByConnectionStddevPopulationStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_TIME_ASC',
  CallsByCreatedByConnectionStddevPopulationStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_TIME_DESC',
  CallsByCreatedByConnectionStddevPopulationToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionStddevPopulationToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionStddevPopulationToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_NUMBER_ASC',
  CallsByCreatedByConnectionStddevPopulationToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_NUMBER_DESC',
  CallsByCreatedByConnectionStddevPopulationTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionStddevPopulationTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CallsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CallsByCreatedByConnectionStddevSampleCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CallsByCreatedByConnectionStddevSampleCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CallsByCreatedByConnectionStddevSampleCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CallsByCreatedByConnectionStddevSampleCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CallsByCreatedByConnectionStddevSampleCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CallsByCreatedByConnectionStddevSampleCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CallsByCreatedByConnectionStddevSampleDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DISPOSITION_ASC',
  CallsByCreatedByConnectionStddevSampleDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DISPOSITION_DESC',
  CallsByCreatedByConnectionStddevSampleDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionStddevSampleDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionStddevSampleEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_TIME_ASC',
  CallsByCreatedByConnectionStddevSampleEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_TIME_DESC',
  CallsByCreatedByConnectionStddevSampleFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionStddevSampleFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionStddevSampleFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionStddevSampleFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionStddevSampleIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CallsByCreatedByConnectionStddevSampleIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CallsByCreatedByConnectionStddevSampleIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_ASC',
  CallsByCreatedByConnectionStddevSampleIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_INBOUND_DESC',
  CallsByCreatedByConnectionStddevSampleMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionStddevSampleMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionStddevSampleNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTE_ASC',
  CallsByCreatedByConnectionStddevSampleNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTE_DESC',
  CallsByCreatedByConnectionStddevSampleOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OUTCOME_ASC',
  CallsByCreatedByConnectionStddevSampleOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OUTCOME_DESC',
  CallsByCreatedByConnectionStddevSampleProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CallsByCreatedByConnectionStddevSampleProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CallsByCreatedByConnectionStddevSampleReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REASON_ASC',
  CallsByCreatedByConnectionStddevSampleReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REASON_DESC',
  CallsByCreatedByConnectionStddevSampleRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_ASC',
  CallsByCreatedByConnectionStddevSampleRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RECORDING_URL_DESC',
  CallsByCreatedByConnectionStddevSampleStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_TIME_ASC',
  CallsByCreatedByConnectionStddevSampleStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_TIME_DESC',
  CallsByCreatedByConnectionStddevSampleToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionStddevSampleToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionStddevSampleToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_ASC',
  CallsByCreatedByConnectionStddevSampleToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_NUMBER_DESC',
  CallsByCreatedByConnectionStddevSampleTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionStddevSampleTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CallsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CallsByCreatedByConnectionSumCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  CallsByCreatedByConnectionSumCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  CallsByCreatedByConnectionSumCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  CallsByCreatedByConnectionSumCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  CallsByCreatedByConnectionSumCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  CallsByCreatedByConnectionSumCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  CallsByCreatedByConnectionSumDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_DISPOSITION_ASC',
  CallsByCreatedByConnectionSumDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_DISPOSITION_DESC',
  CallsByCreatedByConnectionSumDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionSumDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionSumEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_END_TIME_ASC',
  CallsByCreatedByConnectionSumEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_END_TIME_DESC',
  CallsByCreatedByConnectionSumFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionSumFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionSumFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionSumFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionSumIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  CallsByCreatedByConnectionSumIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  CallsByCreatedByConnectionSumIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_IS_INBOUND_ASC',
  CallsByCreatedByConnectionSumIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_IS_INBOUND_DESC',
  CallsByCreatedByConnectionSumMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionSumMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionSumNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_NOTE_ASC',
  CallsByCreatedByConnectionSumNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_NOTE_DESC',
  CallsByCreatedByConnectionSumOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_OUTCOME_ASC',
  CallsByCreatedByConnectionSumOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_OUTCOME_DESC',
  CallsByCreatedByConnectionSumProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  CallsByCreatedByConnectionSumProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  CallsByCreatedByConnectionSumReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_REASON_ASC',
  CallsByCreatedByConnectionSumReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_REASON_DESC',
  CallsByCreatedByConnectionSumRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_RECORDING_URL_ASC',
  CallsByCreatedByConnectionSumRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_RECORDING_URL_DESC',
  CallsByCreatedByConnectionSumStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_START_TIME_ASC',
  CallsByCreatedByConnectionSumStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_START_TIME_DESC',
  CallsByCreatedByConnectionSumToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionSumToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionSumToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TO_NUMBER_ASC',
  CallsByCreatedByConnectionSumToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TO_NUMBER_DESC',
  CallsByCreatedByConnectionSumTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionSumTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionSumUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  CallsByCreatedByConnectionSumUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  CallsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CallsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CallsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CallsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CallsByCreatedByConnectionVariancePopulationCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CallsByCreatedByConnectionVariancePopulationCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CallsByCreatedByConnectionVariancePopulationDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DISPOSITION_ASC',
  CallsByCreatedByConnectionVariancePopulationDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DISPOSITION_DESC',
  CallsByCreatedByConnectionVariancePopulationDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionVariancePopulationDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionVariancePopulationEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_TIME_ASC',
  CallsByCreatedByConnectionVariancePopulationEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_TIME_DESC',
  CallsByCreatedByConnectionVariancePopulationFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionVariancePopulationFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionVariancePopulationFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionVariancePopulationFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionVariancePopulationIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CallsByCreatedByConnectionVariancePopulationIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CallsByCreatedByConnectionVariancePopulationIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_ASC',
  CallsByCreatedByConnectionVariancePopulationIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_INBOUND_DESC',
  CallsByCreatedByConnectionVariancePopulationMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionVariancePopulationMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionVariancePopulationNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTE_ASC',
  CallsByCreatedByConnectionVariancePopulationNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTE_DESC',
  CallsByCreatedByConnectionVariancePopulationOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OUTCOME_ASC',
  CallsByCreatedByConnectionVariancePopulationOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OUTCOME_DESC',
  CallsByCreatedByConnectionVariancePopulationProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CallsByCreatedByConnectionVariancePopulationProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CallsByCreatedByConnectionVariancePopulationReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REASON_ASC',
  CallsByCreatedByConnectionVariancePopulationReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REASON_DESC',
  CallsByCreatedByConnectionVariancePopulationRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_ASC',
  CallsByCreatedByConnectionVariancePopulationRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RECORDING_URL_DESC',
  CallsByCreatedByConnectionVariancePopulationStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_TIME_ASC',
  CallsByCreatedByConnectionVariancePopulationStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_TIME_DESC',
  CallsByCreatedByConnectionVariancePopulationToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionVariancePopulationToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionVariancePopulationToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_ASC',
  CallsByCreatedByConnectionVariancePopulationToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_NUMBER_DESC',
  CallsByCreatedByConnectionVariancePopulationTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionVariancePopulationTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CallsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CallsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CallsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CallsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CallsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CallsByCreatedByConnectionVarianceSampleCreatedByAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CallsByCreatedByConnectionVarianceSampleCreatedByDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CallsByCreatedByConnectionVarianceSampleDispositionAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_ASC',
  CallsByCreatedByConnectionVarianceSampleDispositionDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DISPOSITION_DESC',
  CallsByCreatedByConnectionVarianceSampleDurationInMsAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_ASC',
  CallsByCreatedByConnectionVarianceSampleDurationInMsDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DURATION_IN_MS_DESC',
  CallsByCreatedByConnectionVarianceSampleEndTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_TIME_ASC',
  CallsByCreatedByConnectionVarianceSampleEndTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_TIME_DESC',
  CallsByCreatedByConnectionVarianceSampleFromContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  CallsByCreatedByConnectionVarianceSampleFromContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  CallsByCreatedByConnectionVarianceSampleFromNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_ASC',
  CallsByCreatedByConnectionVarianceSampleFromNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_NUMBER_DESC',
  CallsByCreatedByConnectionVarianceSampleIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CallsByCreatedByConnectionVarianceSampleIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CallsByCreatedByConnectionVarianceSampleIsInboundAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_ASC',
  CallsByCreatedByConnectionVarianceSampleIsInboundDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_INBOUND_DESC',
  CallsByCreatedByConnectionVarianceSampleMissedCountAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_ASC',
  CallsByCreatedByConnectionVarianceSampleMissedCountDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MISSED_COUNT_DESC',
  CallsByCreatedByConnectionVarianceSampleNoteAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTE_ASC',
  CallsByCreatedByConnectionVarianceSampleNoteDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTE_DESC',
  CallsByCreatedByConnectionVarianceSampleOutcomeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OUTCOME_ASC',
  CallsByCreatedByConnectionVarianceSampleOutcomeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OUTCOME_DESC',
  CallsByCreatedByConnectionVarianceSampleProjectIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CallsByCreatedByConnectionVarianceSampleProjectIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CallsByCreatedByConnectionVarianceSampleReasonAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REASON_ASC',
  CallsByCreatedByConnectionVarianceSampleReasonDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REASON_DESC',
  CallsByCreatedByConnectionVarianceSampleRecordingUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_ASC',
  CallsByCreatedByConnectionVarianceSampleRecordingUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RECORDING_URL_DESC',
  CallsByCreatedByConnectionVarianceSampleStartTimeAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_TIME_ASC',
  CallsByCreatedByConnectionVarianceSampleStartTimeDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_TIME_DESC',
  CallsByCreatedByConnectionVarianceSampleToContactIdAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  CallsByCreatedByConnectionVarianceSampleToContactIdDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  CallsByCreatedByConnectionVarianceSampleToNumberAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_ASC',
  CallsByCreatedByConnectionVarianceSampleToNumberDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_NUMBER_DESC',
  CallsByCreatedByConnectionVarianceSampleTranscriptUrlAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_ASC',
  CallsByCreatedByConnectionVarianceSampleTranscriptUrlDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRANSCRIPT_URL_DESC',
  CallsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CallsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'CALLS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CODE_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CODE_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COST_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COST_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionArrayAggCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionArrayAggCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionArrayAggDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionArrayAggDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionArrayAggDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionArrayAggDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionArrayAggIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  CatalogItemsByCreatedByConnectionArrayAggIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  CatalogItemsByCreatedByConnectionArrayAggImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionArrayAggImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionArrayAggIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionArrayAggIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionArrayAggIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionArrayAggIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionArrayAggManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionArrayAggManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionArrayAggMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionArrayAggMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionArrayAggNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  CatalogItemsByCreatedByConnectionArrayAggNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  CatalogItemsByCreatedByConnectionArrayAggPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRICE_ASC',
  CatalogItemsByCreatedByConnectionArrayAggPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRICE_DESC',
  CatalogItemsByCreatedByConnectionArrayAggSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SKU_ASC',
  CatalogItemsByCreatedByConnectionArrayAggSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SKU_DESC',
  CatalogItemsByCreatedByConnectionArrayAggSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SPEC_ASC',
  CatalogItemsByCreatedByConnectionArrayAggSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SPEC_DESC',
  CatalogItemsByCreatedByConnectionArrayAggTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CatalogItemsByCreatedByConnectionArrayAggTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CatalogItemsByCreatedByConnectionArrayAggUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionArrayAggUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionAverageCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionAverageCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionAverageCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CODE_ASC',
  CatalogItemsByCreatedByConnectionAverageCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CODE_DESC',
  CatalogItemsByCreatedByConnectionAverageCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionAverageCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionAverageCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_COST_ASC',
  CatalogItemsByCreatedByConnectionAverageCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_COST_DESC',
  CatalogItemsByCreatedByConnectionAverageCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionAverageCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionAverageCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionAverageCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionAverageDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionAverageDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionAverageDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionAverageDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionAverageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionAverageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionAverageImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionAverageImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionAverageIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionAverageIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionAverageIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionAverageIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionAverageManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionAverageManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionAverageMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionAverageMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionAverageNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  CatalogItemsByCreatedByConnectionAverageNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  CatalogItemsByCreatedByConnectionAveragePriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_PRICE_ASC',
  CatalogItemsByCreatedByConnectionAveragePriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_PRICE_DESC',
  CatalogItemsByCreatedByConnectionAverageSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_SKU_ASC',
  CatalogItemsByCreatedByConnectionAverageSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_SKU_DESC',
  CatalogItemsByCreatedByConnectionAverageSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_SPEC_ASC',
  CatalogItemsByCreatedByConnectionAverageSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_SPEC_DESC',
  CatalogItemsByCreatedByConnectionAverageTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  CatalogItemsByCreatedByConnectionAverageTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  CatalogItemsByCreatedByConnectionAverageUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionAverageUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionCountAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  CatalogItemsByCreatedByConnectionCountDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CODE_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CODE_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COST_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COST_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRICE_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRICE_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SKU_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SKU_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SPEC_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SPEC_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CatalogItemsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionMaxCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionMaxCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionMaxCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CODE_ASC',
  CatalogItemsByCreatedByConnectionMaxCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CODE_DESC',
  CatalogItemsByCreatedByConnectionMaxCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionMaxCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionMaxCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_COST_ASC',
  CatalogItemsByCreatedByConnectionMaxCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_COST_DESC',
  CatalogItemsByCreatedByConnectionMaxCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionMaxCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionMaxCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionMaxCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionMaxDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionMaxDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionMaxDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionMaxDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionMaxIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  CatalogItemsByCreatedByConnectionMaxIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  CatalogItemsByCreatedByConnectionMaxImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionMaxImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionMaxIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionMaxIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionMaxIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionMaxIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionMaxManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionMaxManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionMaxMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionMaxMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionMaxNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  CatalogItemsByCreatedByConnectionMaxNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  CatalogItemsByCreatedByConnectionMaxPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_PRICE_ASC',
  CatalogItemsByCreatedByConnectionMaxPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_PRICE_DESC',
  CatalogItemsByCreatedByConnectionMaxSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_SKU_ASC',
  CatalogItemsByCreatedByConnectionMaxSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_SKU_DESC',
  CatalogItemsByCreatedByConnectionMaxSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_SPEC_ASC',
  CatalogItemsByCreatedByConnectionMaxSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_SPEC_DESC',
  CatalogItemsByCreatedByConnectionMaxTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  CatalogItemsByCreatedByConnectionMaxTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  CatalogItemsByCreatedByConnectionMaxUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionMaxUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionMinCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionMinCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionMinCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CODE_ASC',
  CatalogItemsByCreatedByConnectionMinCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CODE_DESC',
  CatalogItemsByCreatedByConnectionMinCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionMinCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionMinCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_COST_ASC',
  CatalogItemsByCreatedByConnectionMinCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_COST_DESC',
  CatalogItemsByCreatedByConnectionMinCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionMinCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionMinCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionMinCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionMinDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionMinDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionMinDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionMinDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionMinIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  CatalogItemsByCreatedByConnectionMinIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  CatalogItemsByCreatedByConnectionMinImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionMinImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionMinIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionMinIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionMinIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionMinIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionMinManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionMinManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionMinMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionMinMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionMinNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  CatalogItemsByCreatedByConnectionMinNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  CatalogItemsByCreatedByConnectionMinPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_PRICE_ASC',
  CatalogItemsByCreatedByConnectionMinPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_PRICE_DESC',
  CatalogItemsByCreatedByConnectionMinSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_SKU_ASC',
  CatalogItemsByCreatedByConnectionMinSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_SKU_DESC',
  CatalogItemsByCreatedByConnectionMinSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_SPEC_ASC',
  CatalogItemsByCreatedByConnectionMinSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_SPEC_DESC',
  CatalogItemsByCreatedByConnectionMinTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  CatalogItemsByCreatedByConnectionMinTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  CatalogItemsByCreatedByConnectionMinUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionMinUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CODE_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CODE_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COST_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COST_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRICE_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRICE_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SKU_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SKU_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SPEC_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SPEC_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CatalogItemsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CODE_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CODE_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COST_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COST_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  CatalogItemsByCreatedByConnectionStddevSamplePriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRICE_ASC',
  CatalogItemsByCreatedByConnectionStddevSamplePriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRICE_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SKU_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SKU_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SPEC_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SPEC_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CatalogItemsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionSumCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionSumCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionSumCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CODE_ASC',
  CatalogItemsByCreatedByConnectionSumCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CODE_DESC',
  CatalogItemsByCreatedByConnectionSumCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionSumCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionSumCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_COST_ASC',
  CatalogItemsByCreatedByConnectionSumCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_COST_DESC',
  CatalogItemsByCreatedByConnectionSumCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionSumCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionSumCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionSumCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionSumDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionSumDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionSumDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionSumDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionSumIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  CatalogItemsByCreatedByConnectionSumIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  CatalogItemsByCreatedByConnectionSumImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionSumImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionSumIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionSumIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionSumIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionSumIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionSumManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionSumManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionSumMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionSumMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionSumNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  CatalogItemsByCreatedByConnectionSumNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  CatalogItemsByCreatedByConnectionSumPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_PRICE_ASC',
  CatalogItemsByCreatedByConnectionSumPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_PRICE_DESC',
  CatalogItemsByCreatedByConnectionSumSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_SKU_ASC',
  CatalogItemsByCreatedByConnectionSumSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_SKU_DESC',
  CatalogItemsByCreatedByConnectionSumSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_SPEC_ASC',
  CatalogItemsByCreatedByConnectionSumSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_SPEC_DESC',
  CatalogItemsByCreatedByConnectionSumTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  CatalogItemsByCreatedByConnectionSumTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  CatalogItemsByCreatedByConnectionSumUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionSumUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CODE_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CODE_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COST_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COST_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationPriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRICE_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationPriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRICE_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SKU_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SKU_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SPEC_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SPEC_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CatalogItemsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCategoryIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCategoryIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CATEGORY_ID_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCodeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CODE_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCodeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CODE_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCostAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COST_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCostDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COST_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleCreatedByAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleCreatedByDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleDatasheetAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DATASHEET_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleDatasheetDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DATASHEET_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleDescriptionAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleDescriptionDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleImageIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleImageIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_ID_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleIsArchivedAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleIsArchivedDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleIsCustomAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleIsCustomDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_CUSTOM_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleManufacturerAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleManufacturerDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MANUFACTURER_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleMasterItemIdAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleMasterItemIdDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MASTER_ITEM_ID_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleNameAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleNameDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  CatalogItemsByCreatedByConnectionVarianceSamplePriceAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRICE_ASC',
  CatalogItemsByCreatedByConnectionVarianceSamplePriceDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRICE_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleSkuAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SKU_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleSkuDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SKU_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleSpecAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SPEC_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleSpecDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SPEC_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleTypeAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleTypeDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CatalogItemsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CatalogItemsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'CATALOG_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionArrayAggCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsByCreatedByConnectionArrayAggCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsByCreatedByConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsByCreatedByConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsByCreatedByConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsByCreatedByConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsByCreatedByConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionArrayAggFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsByCreatedByConnectionArrayAggFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsByCreatedByConnectionArrayAggIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsByCreatedByConnectionArrayAggIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsByCreatedByConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsByCreatedByConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsByCreatedByConnectionArrayAggParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsByCreatedByConnectionArrayAggParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsByCreatedByConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsByCreatedByConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsByCreatedByConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionArrayAggScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsByCreatedByConnectionArrayAggScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsByCreatedByConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsByCreatedByConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsByCreatedByConnectionArrayAggTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsByCreatedByConnectionArrayAggTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsByCreatedByConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionArrayAggXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsByCreatedByConnectionArrayAggXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsByCreatedByConnectionArrayAggYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsByCreatedByConnectionArrayAggYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsByCreatedByConnectionAverageAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionAverageAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionAverageCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsByCreatedByConnectionAverageCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsByCreatedByConnectionAverageCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionAverageCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionAverageCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsByCreatedByConnectionAverageCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsByCreatedByConnectionAverageCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsByCreatedByConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionAverageCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsByCreatedByConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionAverageFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsByCreatedByConnectionAverageFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsByCreatedByConnectionAverageIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  CommentsByCreatedByConnectionAverageIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  CommentsByCreatedByConnectionAverageIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsByCreatedByConnectionAverageIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsByCreatedByConnectionAverageParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsByCreatedByConnectionAverageParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsByCreatedByConnectionAveragePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsByCreatedByConnectionAveragePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsByCreatedByConnectionAveragePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionAveragePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionAverageProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionAverageProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionAverageScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsByCreatedByConnectionAverageScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsByCreatedByConnectionAverageSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionAverageSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionAverageTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsByCreatedByConnectionAverageTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsByCreatedByConnectionAverageTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsByCreatedByConnectionAverageTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsByCreatedByConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionAverageXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_X_ASC',
  CommentsByCreatedByConnectionAverageXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_X_DESC',
  CommentsByCreatedByConnectionAverageYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_Y_ASC',
  CommentsByCreatedByConnectionAverageYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_AVERAGE_Y_DESC',
  CommentsByCreatedByConnectionCountAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  CommentsByCreatedByConnectionCountDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  CommentsByCreatedByConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsByCreatedByConnectionDistinctCountCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsByCreatedByConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsByCreatedByConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsByCreatedByConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsByCreatedByConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsByCreatedByConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsByCreatedByConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsByCreatedByConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsByCreatedByConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsByCreatedByConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsByCreatedByConnectionDistinctCountScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsByCreatedByConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsByCreatedByConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsByCreatedByConnectionDistinctCountTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsByCreatedByConnectionDistinctCountTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionDistinctCountXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsByCreatedByConnectionDistinctCountXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsByCreatedByConnectionDistinctCountYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsByCreatedByConnectionDistinctCountYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsByCreatedByConnectionMaxAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionMaxAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionMaxCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ASC',
  CommentsByCreatedByConnectionMaxCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_DESC',
  CommentsByCreatedByConnectionMaxCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionMaxCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionMaxCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsByCreatedByConnectionMaxCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsByCreatedByConnectionMaxCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsByCreatedByConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionMaxCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsByCreatedByConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionMaxFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_FILE_ID_ASC',
  CommentsByCreatedByConnectionMaxFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_FILE_ID_DESC',
  CommentsByCreatedByConnectionMaxIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  CommentsByCreatedByConnectionMaxIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  CommentsByCreatedByConnectionMaxIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsByCreatedByConnectionMaxIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsByCreatedByConnectionMaxParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsByCreatedByConnectionMaxParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsByCreatedByConnectionMaxPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsByCreatedByConnectionMaxPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsByCreatedByConnectionMaxPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionMaxPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionMaxProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionMaxProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionMaxScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_ASC',
  CommentsByCreatedByConnectionMaxScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_DESC',
  CommentsByCreatedByConnectionMaxSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionMaxSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionMaxTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  CommentsByCreatedByConnectionMaxTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  CommentsByCreatedByConnectionMaxTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  CommentsByCreatedByConnectionMaxTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  CommentsByCreatedByConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionMaxXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_X_ASC',
  CommentsByCreatedByConnectionMaxXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_X_DESC',
  CommentsByCreatedByConnectionMaxYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_Y_ASC',
  CommentsByCreatedByConnectionMaxYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MAX_Y_DESC',
  CommentsByCreatedByConnectionMinAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionMinAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionMinAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionMinAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionMinCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ASC',
  CommentsByCreatedByConnectionMinCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_DESC',
  CommentsByCreatedByConnectionMinCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionMinCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionMinCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsByCreatedByConnectionMinCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsByCreatedByConnectionMinCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsByCreatedByConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionMinCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsByCreatedByConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionMinFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_FILE_ID_ASC',
  CommentsByCreatedByConnectionMinFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_FILE_ID_DESC',
  CommentsByCreatedByConnectionMinIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  CommentsByCreatedByConnectionMinIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  CommentsByCreatedByConnectionMinIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsByCreatedByConnectionMinIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsByCreatedByConnectionMinParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsByCreatedByConnectionMinParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsByCreatedByConnectionMinPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsByCreatedByConnectionMinPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsByCreatedByConnectionMinPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionMinPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionMinProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionMinProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionMinScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_ASC',
  CommentsByCreatedByConnectionMinScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_DESC',
  CommentsByCreatedByConnectionMinSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionMinSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionMinTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  CommentsByCreatedByConnectionMinTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  CommentsByCreatedByConnectionMinTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  CommentsByCreatedByConnectionMinTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  CommentsByCreatedByConnectionMinUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionMinUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionMinXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_X_ASC',
  CommentsByCreatedByConnectionMinXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_X_DESC',
  CommentsByCreatedByConnectionMinYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_Y_ASC',
  CommentsByCreatedByConnectionMinYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_MIN_Y_DESC',
  CommentsByCreatedByConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsByCreatedByConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsByCreatedByConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsByCreatedByConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsByCreatedByConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsByCreatedByConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsByCreatedByConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsByCreatedByConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsByCreatedByConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsByCreatedByConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsByCreatedByConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionStddevPopulationXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsByCreatedByConnectionStddevPopulationXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsByCreatedByConnectionStddevPopulationYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsByCreatedByConnectionStddevPopulationYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsByCreatedByConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsByCreatedByConnectionStddevSampleCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsByCreatedByConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsByCreatedByConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsByCreatedByConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsByCreatedByConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsByCreatedByConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsByCreatedByConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsByCreatedByConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsByCreatedByConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsByCreatedByConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsByCreatedByConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsByCreatedByConnectionStddevSampleScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsByCreatedByConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsByCreatedByConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsByCreatedByConnectionStddevSampleTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsByCreatedByConnectionStddevSampleTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionStddevSampleXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsByCreatedByConnectionStddevSampleXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsByCreatedByConnectionStddevSampleYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsByCreatedByConnectionStddevSampleYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsByCreatedByConnectionSumAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionSumAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionSumAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionSumAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionSumCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ASC',
  CommentsByCreatedByConnectionSumCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_DESC',
  CommentsByCreatedByConnectionSumCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionSumCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionSumCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsByCreatedByConnectionSumCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsByCreatedByConnectionSumCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsByCreatedByConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionSumCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsByCreatedByConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionSumFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_FILE_ID_ASC',
  CommentsByCreatedByConnectionSumFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_FILE_ID_DESC',
  CommentsByCreatedByConnectionSumIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  CommentsByCreatedByConnectionSumIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  CommentsByCreatedByConnectionSumIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsByCreatedByConnectionSumIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsByCreatedByConnectionSumParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsByCreatedByConnectionSumParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsByCreatedByConnectionSumPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsByCreatedByConnectionSumPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsByCreatedByConnectionSumPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionSumPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionSumProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionSumProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionSumScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_ASC',
  CommentsByCreatedByConnectionSumScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_DESC',
  CommentsByCreatedByConnectionSumSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionSumSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionSumTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  CommentsByCreatedByConnectionSumTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  CommentsByCreatedByConnectionSumTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  CommentsByCreatedByConnectionSumTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  CommentsByCreatedByConnectionSumUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionSumUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionSumXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_X_ASC',
  CommentsByCreatedByConnectionSumXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_X_DESC',
  CommentsByCreatedByConnectionSumYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_Y_ASC',
  CommentsByCreatedByConnectionSumYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_SUM_Y_DESC',
  CommentsByCreatedByConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsByCreatedByConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsByCreatedByConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsByCreatedByConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsByCreatedByConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsByCreatedByConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsByCreatedByConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsByCreatedByConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsByCreatedByConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsByCreatedByConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsByCreatedByConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionVariancePopulationXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsByCreatedByConnectionVariancePopulationXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsByCreatedByConnectionVariancePopulationYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsByCreatedByConnectionVariancePopulationYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsByCreatedByConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsByCreatedByConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsByCreatedByConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsByCreatedByConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByCreatedByConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByCreatedByConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsByCreatedByConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsByCreatedByConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsByCreatedByConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsByCreatedByConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsByCreatedByConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByCreatedByConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsByCreatedByConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsByCreatedByConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsByCreatedByConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsByCreatedByConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsByCreatedByConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByCreatedByConnectionVarianceSampleXAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsByCreatedByConnectionVarianceSampleXDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsByCreatedByConnectionVarianceSampleYAsc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsByCreatedByConnectionVarianceSampleYDesc = 'COMMENTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionArrayAggUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionAverageUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionAverageUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionCountAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  CompanyPhoneUsersByCreatedByConnectionCountDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionDistinctCountUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionMaxUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionMaxUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionMinUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionMinUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevPopulationUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionStddevSampleUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionSumUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionSumUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionVariancePopulationUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCanSendAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCanSendDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCreatedByAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleCreatedByDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleUserIdAsc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersByCreatedByConnectionVarianceSampleUserIdDesc = 'COMPANY_PHONE_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionArrayAggUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionArrayAggUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionAverageUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionAverageUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionCountAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  CompanyPhoneUsersByUserIdConnectionCountDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionDistinctCountUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionMaxUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionMaxUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionMinCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionMinCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionMinCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionMinCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionMinCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionMinCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionMinCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionMinCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionMinCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionMinCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionMinUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionMinUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionMinUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionMinUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevPopulationUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionStddevSampleUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionSumCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionSumCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionSumCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionSumCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionSumCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionSumCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionSumCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionSumCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionSumCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionSumCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionSumUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionSumUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionSumUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionSumUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionVariancePopulationUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCanReceiveAllAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCanReceiveAllDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCanSendAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCanSendDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCompanyPhoneIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCompanyPhoneIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_PHONE_ID_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCreatedByAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleCreatedByDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleUserIdAsc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyPhoneUsersByUserIdConnectionVarianceSampleUserIdDesc = 'COMPANY_PHONE_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CompanyUsersByUserIdConnectionArrayAggCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionArrayAggCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionArrayAggCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionArrayAggCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionArrayAggIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CompanyUsersByUserIdConnectionArrayAggIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CompanyUsersByUserIdConnectionArrayAggInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionArrayAggInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionArrayAggIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionArrayAggIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionArrayAggIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionArrayAggIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionArrayAggIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionArrayAggIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionArrayAggUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  CompanyUsersByUserIdConnectionArrayAggUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  CompanyUsersByUserIdConnectionAverageCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionAverageCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionAverageCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionAverageCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionAverageIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  CompanyUsersByUserIdConnectionAverageIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  CompanyUsersByUserIdConnectionAverageInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionAverageInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionAverageIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionAverageIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionAverageIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionAverageIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionAverageIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionAverageIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionAverageUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionAverageUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionAverageUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  CompanyUsersByUserIdConnectionAverageUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  CompanyUsersByUserIdConnectionCountAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  CompanyUsersByUserIdConnectionCountDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  CompanyUsersByUserIdConnectionDistinctCountCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionDistinctCountCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionDistinctCountIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CompanyUsersByUserIdConnectionDistinctCountIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CompanyUsersByUserIdConnectionDistinctCountInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionDistinctCountInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionDistinctCountIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionDistinctCountIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionDistinctCountIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionDistinctCountIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionDistinctCountIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionDistinctCountIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionDistinctCountUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  CompanyUsersByUserIdConnectionDistinctCountUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  CompanyUsersByUserIdConnectionMaxCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionMaxCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionMaxCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionMaxCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionMaxIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  CompanyUsersByUserIdConnectionMaxIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  CompanyUsersByUserIdConnectionMaxInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionMaxInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionMaxIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionMaxIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionMaxIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionMaxIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionMaxIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionMaxIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionMaxUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionMaxUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionMaxUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  CompanyUsersByUserIdConnectionMaxUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  CompanyUsersByUserIdConnectionMinCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionMinCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionMinCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionMinCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionMinIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  CompanyUsersByUserIdConnectionMinIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  CompanyUsersByUserIdConnectionMinInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionMinInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionMinIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionMinIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionMinIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionMinIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionMinIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionMinIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionMinUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionMinUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionMinUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  CompanyUsersByUserIdConnectionMinUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionStddevPopulationUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  CompanyUsersByUserIdConnectionStddevPopulationUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  CompanyUsersByUserIdConnectionStddevSampleCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionStddevSampleCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionStddevSampleIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CompanyUsersByUserIdConnectionStddevSampleIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CompanyUsersByUserIdConnectionStddevSampleInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionStddevSampleInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionStddevSampleIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionStddevSampleIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionStddevSampleIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionStddevSampleIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionStddevSampleIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionStddevSampleIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionStddevSampleUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  CompanyUsersByUserIdConnectionStddevSampleUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  CompanyUsersByUserIdConnectionSumCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionSumCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionSumCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionSumCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionSumIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  CompanyUsersByUserIdConnectionSumIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  CompanyUsersByUserIdConnectionSumInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionSumInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionSumIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionSumIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionSumIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionSumIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionSumIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionSumIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionSumUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionSumUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionSumUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  CompanyUsersByUserIdConnectionSumUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionVariancePopulationUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  CompanyUsersByUserIdConnectionVariancePopulationUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleCompanyIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleCompanyIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleInvitationTokenAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_INVITATION_TOKEN_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleInvitationTokenDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_INVITATION_TOKEN_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleIsActiveAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleIsActiveDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleIsCollaboratorAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLLABORATOR_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleIsCollaboratorDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLLABORATOR_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleIsConfirmedAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONFIRMED_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleIsConfirmedDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONFIRMED_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUsersByUserIdConnectionVarianceSampleUserIdAsc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  CompanyUsersByUserIdConnectionVarianceSampleUserIdDesc = 'COMPANY_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionArrayAggCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionArrayAggCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionArrayAggUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionArrayAggUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionArrayAggUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionArrayAggUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionAverageCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionAverageCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionAverageUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionAverageUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionAverageUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionAverageUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionCountAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  DashboardSharedUsersByUserIdConnectionCountDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  DashboardSharedUsersByUserIdConnectionDistinctCountCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionDistinctCountCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionDistinctCountUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionDistinctCountUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionDistinctCountUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionDistinctCountUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionMaxCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionMaxCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionMaxUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionMaxUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionMaxUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionMaxUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionMinCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionMinCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionMinUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionMinUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionMinUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionMinUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionStddevPopulationUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionStddevSampleCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionStddevSampleCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionStddevSampleUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionStddevSampleUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionStddevSampleUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionStddevSampleUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionSumCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionSumCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionSumUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionSumUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionSumUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionSumUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionVariancePopulationUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleCreatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleCreatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleUpdatedAtAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleUpdatedAtDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleUserIdAsc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  DashboardSharedUsersByUserIdConnectionVarianceSampleUserIdDesc = 'DASHBOARD_SHARED_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionArrayAggUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionArrayAggUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionAverageCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionAverageCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionAverageCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionAverageCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionAverageCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionAverageCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionAverageCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionAverageCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionAverageEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionAverageEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionAverageUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionAverageUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionAverageUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionAverageUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionCountAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  EmailAccountUsersByCreatedByConnectionCountDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionDistinctCountUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionDistinctCountUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionMaxCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionMaxCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionMaxCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionMaxCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionMaxCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionMaxCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionMaxCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionMaxCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionMaxEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionMaxEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionMaxUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionMaxUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionMaxUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionMaxUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionMinCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionMinCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionMinCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionMinCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionMinCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionMinCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionMinCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionMinCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionMinEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionMinEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionMinUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionMinUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionMinUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionMinUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionStddevPopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionStddevSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionStddevSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionSumCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionSumCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionSumCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionSumCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionSumCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionSumCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionSumCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionSumCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionSumEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionSumEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionSumUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionSumUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionSumUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionSumUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionVariancePopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  EmailAccountUsersByCreatedByConnectionVarianceSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionArrayAggUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionArrayAggUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionAverageCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionAverageCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionAverageCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionAverageCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionAverageCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionAverageCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionAverageCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionAverageCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionAverageEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionAverageEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionAverageUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionAverageUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionAverageUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionAverageUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionCountAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  EmailAccountUsersByUserIdConnectionCountDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionDistinctCountUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionDistinctCountUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionMaxCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionMaxCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionMaxCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionMaxCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionMaxCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionMaxCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionMaxCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionMaxCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionMaxEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionMaxEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionMaxUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionMaxUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionMaxUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionMaxUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionMinCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionMinCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionMinCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionMinCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionMinCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionMinCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionMinCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionMinCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionMinEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionMinEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionMinUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionMinUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionMinUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionMinUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionStddevPopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionStddevPopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionStddevSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionStddevSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionSumCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionSumCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionSumCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionSumCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionSumCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionSumCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionSumCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionSumCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionSumEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionSumEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionSumUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionSumUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionSumUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionSumUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionVariancePopulationUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionVariancePopulationUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCanReceiveAllAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCanReceiveAllDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_RECEIVE_ALL_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCanSendAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCanSendDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CAN_SEND_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCreatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCreatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCreatedByAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleCreatedByDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleEmailAccountIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleEmailAccountIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_ACCOUNT_ID_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleUpdatedAtAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleUpdatedAtDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmailAccountUsersByUserIdConnectionVarianceSampleUserIdAsc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  EmailAccountUsersByUserIdConnectionVarianceSampleUserIdDesc = 'EMAIL_ACCOUNT_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FeedInboxesByUserIdConnectionArrayAggContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionArrayAggContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionArrayAggCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionArrayAggCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionArrayAggEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionArrayAggEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionArrayAggEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionArrayAggEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionArrayAggFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionArrayAggFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionArrayAggIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedInboxesByUserIdConnectionArrayAggIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedInboxesByUserIdConnectionArrayAggReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_READ_ASC',
  FeedInboxesByUserIdConnectionArrayAggReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_READ_DESC',
  FeedInboxesByUserIdConnectionArrayAggReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionArrayAggReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionArrayAggUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionArrayAggUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionArrayAggUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FeedInboxesByUserIdConnectionArrayAggUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FeedInboxesByUserIdConnectionAverageContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionAverageContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionAverageCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionAverageCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionAverageEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionAverageEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionAverageEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionAverageEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionAverageFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionAverageFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionAverageIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedInboxesByUserIdConnectionAverageIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedInboxesByUserIdConnectionAverageReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_READ_ASC',
  FeedInboxesByUserIdConnectionAverageReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_READ_DESC',
  FeedInboxesByUserIdConnectionAverageReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionAverageReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionAverageUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionAverageUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionAverageUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  FeedInboxesByUserIdConnectionAverageUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  FeedInboxesByUserIdConnectionCountAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_COUNT_ASC',
  FeedInboxesByUserIdConnectionCountDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_COUNT_DESC',
  FeedInboxesByUserIdConnectionDistinctCountContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionDistinctCountContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionDistinctCountCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionDistinctCountCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionDistinctCountEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionDistinctCountEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionDistinctCountEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionDistinctCountEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionDistinctCountFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionDistinctCountFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionDistinctCountIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedInboxesByUserIdConnectionDistinctCountIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedInboxesByUserIdConnectionDistinctCountReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_READ_ASC',
  FeedInboxesByUserIdConnectionDistinctCountReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_READ_DESC',
  FeedInboxesByUserIdConnectionDistinctCountReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionDistinctCountReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionDistinctCountUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionDistinctCountUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionDistinctCountUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FeedInboxesByUserIdConnectionDistinctCountUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FeedInboxesByUserIdConnectionMaxContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionMaxContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionMaxCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionMaxCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionMaxEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionMaxEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionMaxEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionMaxEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionMaxFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionMaxFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionMaxIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  FeedInboxesByUserIdConnectionMaxIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  FeedInboxesByUserIdConnectionMaxReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_READ_ASC',
  FeedInboxesByUserIdConnectionMaxReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_READ_DESC',
  FeedInboxesByUserIdConnectionMaxReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionMaxReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionMaxUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionMaxUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionMaxUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  FeedInboxesByUserIdConnectionMaxUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  FeedInboxesByUserIdConnectionMinContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionMinContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionMinCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionMinCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionMinEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionMinEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionMinEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionMinEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionMinFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionMinFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionMinIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  FeedInboxesByUserIdConnectionMinIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  FeedInboxesByUserIdConnectionMinReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_READ_ASC',
  FeedInboxesByUserIdConnectionMinReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_READ_DESC',
  FeedInboxesByUserIdConnectionMinReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionMinReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionMinUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionMinUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionMinUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  FeedInboxesByUserIdConnectionMinUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_READ_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_READ_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionStddevPopulationUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FeedInboxesByUserIdConnectionStddevPopulationUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FeedInboxesByUserIdConnectionStddevSampleContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionStddevSampleContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionStddevSampleCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionStddevSampleCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionStddevSampleEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevSampleEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevSampleEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevSampleEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevSampleFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionStddevSampleFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionStddevSampleIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedInboxesByUserIdConnectionStddevSampleIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedInboxesByUserIdConnectionStddevSampleReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  FeedInboxesByUserIdConnectionStddevSampleReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  FeedInboxesByUserIdConnectionStddevSampleReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionStddevSampleReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionStddevSampleUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionStddevSampleUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionStddevSampleUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FeedInboxesByUserIdConnectionStddevSampleUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FeedInboxesByUserIdConnectionSumContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionSumContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionSumCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionSumCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionSumEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionSumEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionSumEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionSumEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionSumFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionSumFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionSumIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  FeedInboxesByUserIdConnectionSumIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  FeedInboxesByUserIdConnectionSumReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_READ_ASC',
  FeedInboxesByUserIdConnectionSumReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_READ_DESC',
  FeedInboxesByUserIdConnectionSumReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionSumReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionSumUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionSumUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionSumUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  FeedInboxesByUserIdConnectionSumUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionVariancePopulationUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FeedInboxesByUserIdConnectionVariancePopulationUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleContactIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleContactIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleCreatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleCreatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleEntityTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleEntityTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleEventTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleEventTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleFeedIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleFeedIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleReadAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleReadDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleReceiverTypeAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleReceiverTypeDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_RECEIVER_TYPE_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleUpdatedAtAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleUpdatedAtDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FeedInboxesByUserIdConnectionVarianceSampleUserIdAsc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FeedInboxesByUserIdConnectionVarianceSampleUserIdDesc = 'FEED_INBOXES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  FiltersByCreatedByConnectionArrayAggCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionArrayAggCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionArrayAggConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONDITIONS_ASC',
  FiltersByCreatedByConnectionArrayAggConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONDITIONS_DESC',
  FiltersByCreatedByConnectionArrayAggCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FiltersByCreatedByConnectionArrayAggCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FiltersByCreatedByConnectionArrayAggCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FiltersByCreatedByConnectionArrayAggCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FiltersByCreatedByConnectionArrayAggEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  FiltersByCreatedByConnectionArrayAggEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  FiltersByCreatedByConnectionArrayAggFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionArrayAggFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionArrayAggIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  FiltersByCreatedByConnectionArrayAggIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  FiltersByCreatedByConnectionArrayAggIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionArrayAggIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionArrayAggNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  FiltersByCreatedByConnectionArrayAggNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  FiltersByCreatedByConnectionArrayAggScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  FiltersByCreatedByConnectionArrayAggScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  FiltersByCreatedByConnectionArrayAggTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FiltersByCreatedByConnectionArrayAggTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FiltersByCreatedByConnectionArrayAggUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionArrayAggUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionAverageCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionAverageCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionAverageConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CONDITIONS_ASC',
  FiltersByCreatedByConnectionAverageConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CONDITIONS_DESC',
  FiltersByCreatedByConnectionAverageCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FiltersByCreatedByConnectionAverageCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FiltersByCreatedByConnectionAverageCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FiltersByCreatedByConnectionAverageCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FiltersByCreatedByConnectionAverageEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_EMOJI_ASC',
  FiltersByCreatedByConnectionAverageEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_EMOJI_DESC',
  FiltersByCreatedByConnectionAverageFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionAverageFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionAverageIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  FiltersByCreatedByConnectionAverageIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  FiltersByCreatedByConnectionAverageIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionAverageIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionAverageNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  FiltersByCreatedByConnectionAverageNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  FiltersByCreatedByConnectionAverageScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_ASC',
  FiltersByCreatedByConnectionAverageScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_DESC',
  FiltersByCreatedByConnectionAverageTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  FiltersByCreatedByConnectionAverageTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  FiltersByCreatedByConnectionAverageUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionAverageUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionCountAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  FiltersByCreatedByConnectionCountDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  FiltersByCreatedByConnectionDistinctCountCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionDistinctCountCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionDistinctCountConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONDITIONS_ASC',
  FiltersByCreatedByConnectionDistinctCountConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONDITIONS_DESC',
  FiltersByCreatedByConnectionDistinctCountCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FiltersByCreatedByConnectionDistinctCountCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FiltersByCreatedByConnectionDistinctCountCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FiltersByCreatedByConnectionDistinctCountCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FiltersByCreatedByConnectionDistinctCountEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  FiltersByCreatedByConnectionDistinctCountEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  FiltersByCreatedByConnectionDistinctCountFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionDistinctCountFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionDistinctCountIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FiltersByCreatedByConnectionDistinctCountIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FiltersByCreatedByConnectionDistinctCountIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionDistinctCountIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionDistinctCountNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FiltersByCreatedByConnectionDistinctCountNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FiltersByCreatedByConnectionDistinctCountScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  FiltersByCreatedByConnectionDistinctCountScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  FiltersByCreatedByConnectionDistinctCountTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FiltersByCreatedByConnectionDistinctCountTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FiltersByCreatedByConnectionDistinctCountUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionDistinctCountUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionMaxCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionMaxCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionMaxConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CONDITIONS_ASC',
  FiltersByCreatedByConnectionMaxConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CONDITIONS_DESC',
  FiltersByCreatedByConnectionMaxCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  FiltersByCreatedByConnectionMaxCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  FiltersByCreatedByConnectionMaxCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  FiltersByCreatedByConnectionMaxCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  FiltersByCreatedByConnectionMaxEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_EMOJI_ASC',
  FiltersByCreatedByConnectionMaxEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_EMOJI_DESC',
  FiltersByCreatedByConnectionMaxFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionMaxFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionMaxIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  FiltersByCreatedByConnectionMaxIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  FiltersByCreatedByConnectionMaxIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionMaxIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionMaxNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  FiltersByCreatedByConnectionMaxNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  FiltersByCreatedByConnectionMaxScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_ASC',
  FiltersByCreatedByConnectionMaxScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_DESC',
  FiltersByCreatedByConnectionMaxTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  FiltersByCreatedByConnectionMaxTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  FiltersByCreatedByConnectionMaxUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionMaxUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionMinCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionMinCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionMinConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CONDITIONS_ASC',
  FiltersByCreatedByConnectionMinConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CONDITIONS_DESC',
  FiltersByCreatedByConnectionMinCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  FiltersByCreatedByConnectionMinCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  FiltersByCreatedByConnectionMinCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  FiltersByCreatedByConnectionMinCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  FiltersByCreatedByConnectionMinEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_EMOJI_ASC',
  FiltersByCreatedByConnectionMinEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_EMOJI_DESC',
  FiltersByCreatedByConnectionMinFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionMinFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionMinIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  FiltersByCreatedByConnectionMinIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  FiltersByCreatedByConnectionMinIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionMinIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionMinNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  FiltersByCreatedByConnectionMinNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  FiltersByCreatedByConnectionMinScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_ASC',
  FiltersByCreatedByConnectionMinScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_DESC',
  FiltersByCreatedByConnectionMinTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  FiltersByCreatedByConnectionMinTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  FiltersByCreatedByConnectionMinUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionMinUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionStddevPopulationCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionStddevPopulationCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionStddevPopulationConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONDITIONS_ASC',
  FiltersByCreatedByConnectionStddevPopulationConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONDITIONS_DESC',
  FiltersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FiltersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FiltersByCreatedByConnectionStddevPopulationCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FiltersByCreatedByConnectionStddevPopulationCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FiltersByCreatedByConnectionStddevPopulationEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  FiltersByCreatedByConnectionStddevPopulationEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  FiltersByCreatedByConnectionStddevPopulationFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionStddevPopulationFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionStddevPopulationIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FiltersByCreatedByConnectionStddevPopulationIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FiltersByCreatedByConnectionStddevPopulationIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionStddevPopulationIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionStddevPopulationNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FiltersByCreatedByConnectionStddevPopulationNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FiltersByCreatedByConnectionStddevPopulationScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  FiltersByCreatedByConnectionStddevPopulationScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  FiltersByCreatedByConnectionStddevPopulationTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FiltersByCreatedByConnectionStddevPopulationTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FiltersByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionStddevSampleCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionStddevSampleCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionStddevSampleConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONDITIONS_ASC',
  FiltersByCreatedByConnectionStddevSampleConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONDITIONS_DESC',
  FiltersByCreatedByConnectionStddevSampleCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FiltersByCreatedByConnectionStddevSampleCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FiltersByCreatedByConnectionStddevSampleCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FiltersByCreatedByConnectionStddevSampleCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FiltersByCreatedByConnectionStddevSampleEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  FiltersByCreatedByConnectionStddevSampleEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  FiltersByCreatedByConnectionStddevSampleFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionStddevSampleFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionStddevSampleIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FiltersByCreatedByConnectionStddevSampleIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FiltersByCreatedByConnectionStddevSampleIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionStddevSampleIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionStddevSampleNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FiltersByCreatedByConnectionStddevSampleNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FiltersByCreatedByConnectionStddevSampleScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  FiltersByCreatedByConnectionStddevSampleScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  FiltersByCreatedByConnectionStddevSampleTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FiltersByCreatedByConnectionStddevSampleTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FiltersByCreatedByConnectionStddevSampleUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionStddevSampleUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionSumCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionSumCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionSumConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CONDITIONS_ASC',
  FiltersByCreatedByConnectionSumConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CONDITIONS_DESC',
  FiltersByCreatedByConnectionSumCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  FiltersByCreatedByConnectionSumCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  FiltersByCreatedByConnectionSumCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  FiltersByCreatedByConnectionSumCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  FiltersByCreatedByConnectionSumEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_EMOJI_ASC',
  FiltersByCreatedByConnectionSumEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_EMOJI_DESC',
  FiltersByCreatedByConnectionSumFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionSumFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionSumIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  FiltersByCreatedByConnectionSumIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  FiltersByCreatedByConnectionSumIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionSumIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionSumNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  FiltersByCreatedByConnectionSumNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  FiltersByCreatedByConnectionSumScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_ASC',
  FiltersByCreatedByConnectionSumScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_DESC',
  FiltersByCreatedByConnectionSumTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  FiltersByCreatedByConnectionSumTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  FiltersByCreatedByConnectionSumUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionSumUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionVariancePopulationCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionVariancePopulationCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionVariancePopulationConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONDITIONS_ASC',
  FiltersByCreatedByConnectionVariancePopulationConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONDITIONS_DESC',
  FiltersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FiltersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FiltersByCreatedByConnectionVariancePopulationCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FiltersByCreatedByConnectionVariancePopulationCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FiltersByCreatedByConnectionVariancePopulationEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  FiltersByCreatedByConnectionVariancePopulationEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  FiltersByCreatedByConnectionVariancePopulationFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionVariancePopulationFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionVariancePopulationIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FiltersByCreatedByConnectionVariancePopulationIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FiltersByCreatedByConnectionVariancePopulationIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionVariancePopulationIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionVariancePopulationNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FiltersByCreatedByConnectionVariancePopulationNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FiltersByCreatedByConnectionVariancePopulationScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  FiltersByCreatedByConnectionVariancePopulationScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  FiltersByCreatedByConnectionVariancePopulationTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FiltersByCreatedByConnectionVariancePopulationTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FiltersByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FiltersByCreatedByConnectionVarianceSampleCompanyIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FiltersByCreatedByConnectionVarianceSampleCompanyIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FiltersByCreatedByConnectionVarianceSampleConditionsAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_ASC',
  FiltersByCreatedByConnectionVarianceSampleConditionsDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONDITIONS_DESC',
  FiltersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FiltersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FiltersByCreatedByConnectionVarianceSampleCreatedByAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FiltersByCreatedByConnectionVarianceSampleCreatedByDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FiltersByCreatedByConnectionVarianceSampleEmojiAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  FiltersByCreatedByConnectionVarianceSampleEmojiDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  FiltersByCreatedByConnectionVarianceSampleFilterFolderIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  FiltersByCreatedByConnectionVarianceSampleFilterFolderIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  FiltersByCreatedByConnectionVarianceSampleIdAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FiltersByCreatedByConnectionVarianceSampleIdDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FiltersByCreatedByConnectionVarianceSampleIsDefaultAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_ASC',
  FiltersByCreatedByConnectionVarianceSampleIsDefaultDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_DEFAULT_DESC',
  FiltersByCreatedByConnectionVarianceSampleNameAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FiltersByCreatedByConnectionVarianceSampleNameDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FiltersByCreatedByConnectionVarianceSampleScopeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  FiltersByCreatedByConnectionVarianceSampleScopeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  FiltersByCreatedByConnectionVarianceSampleTypeAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FiltersByCreatedByConnectionVarianceSampleTypeDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FiltersByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FiltersByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'FILTERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionArrayAggCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionArrayAggCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionArrayAggCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionArrayAggCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionArrayAggCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionArrayAggCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionArrayAggIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  FilterFoldersByCreatedByConnectionArrayAggIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  FilterFoldersByCreatedByConnectionArrayAggNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilterFoldersByCreatedByConnectionArrayAggNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilterFoldersByCreatedByConnectionArrayAggUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionArrayAggUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionArrayAggUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilterFoldersByCreatedByConnectionArrayAggUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilterFoldersByCreatedByConnectionAverageCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionAverageCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionAverageCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionAverageCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionAverageCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionAverageCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionAverageIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  FilterFoldersByCreatedByConnectionAverageIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  FilterFoldersByCreatedByConnectionAverageNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  FilterFoldersByCreatedByConnectionAverageNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  FilterFoldersByCreatedByConnectionAverageUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionAverageUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionAverageUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_ASC',
  FilterFoldersByCreatedByConnectionAverageUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_DESC',
  FilterFoldersByCreatedByConnectionCountAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  FilterFoldersByCreatedByConnectionCountDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionDistinctCountUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilterFoldersByCreatedByConnectionDistinctCountUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilterFoldersByCreatedByConnectionMaxCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionMaxCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionMaxCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionMaxCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionMaxCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionMaxCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionMaxIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  FilterFoldersByCreatedByConnectionMaxIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  FilterFoldersByCreatedByConnectionMaxNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  FilterFoldersByCreatedByConnectionMaxNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  FilterFoldersByCreatedByConnectionMaxUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionMaxUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionMaxUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_UUID_ASC',
  FilterFoldersByCreatedByConnectionMaxUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MAX_UUID_DESC',
  FilterFoldersByCreatedByConnectionMinCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionMinCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionMinCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionMinCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionMinCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionMinCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionMinIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  FilterFoldersByCreatedByConnectionMinIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  FilterFoldersByCreatedByConnectionMinNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  FilterFoldersByCreatedByConnectionMinNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  FilterFoldersByCreatedByConnectionMinUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionMinUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionMinUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_UUID_ASC',
  FilterFoldersByCreatedByConnectionMinUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_MIN_UUID_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionStddevPopulationUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilterFoldersByCreatedByConnectionStddevPopulationUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionStddevSampleUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilterFoldersByCreatedByConnectionStddevSampleUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilterFoldersByCreatedByConnectionSumCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionSumCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionSumCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionSumCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionSumCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionSumCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionSumIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  FilterFoldersByCreatedByConnectionSumIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  FilterFoldersByCreatedByConnectionSumNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  FilterFoldersByCreatedByConnectionSumNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  FilterFoldersByCreatedByConnectionSumUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionSumUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionSumUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_UUID_ASC',
  FilterFoldersByCreatedByConnectionSumUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_SUM_UUID_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionVariancePopulationUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilterFoldersByCreatedByConnectionVariancePopulationUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleCompanyIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleCompanyIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleCreatedByAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleCreatedByDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleIdAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleIdDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleNameAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleNameDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilterFoldersByCreatedByConnectionVarianceSampleUuidAsc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilterFoldersByCreatedByConnectionVarianceSampleUuidDesc = 'FILTER_FOLDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilterToUsersByUserIdConnectionArrayAggFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionArrayAggFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionArrayAggUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FilterToUsersByUserIdConnectionArrayAggUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FilterToUsersByUserIdConnectionAverageFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionAverageFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionAverageUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  FilterToUsersByUserIdConnectionAverageUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  FilterToUsersByUserIdConnectionCountAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  FilterToUsersByUserIdConnectionCountDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  FilterToUsersByUserIdConnectionDistinctCountFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionDistinctCountFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionDistinctCountUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FilterToUsersByUserIdConnectionDistinctCountUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FilterToUsersByUserIdConnectionMaxFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionMaxFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionMaxUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  FilterToUsersByUserIdConnectionMaxUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  FilterToUsersByUserIdConnectionMinFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionMinFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionMinUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  FilterToUsersByUserIdConnectionMinUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  FilterToUsersByUserIdConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionStddevPopulationUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FilterToUsersByUserIdConnectionStddevPopulationUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FilterToUsersByUserIdConnectionStddevSampleFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionStddevSampleFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionStddevSampleUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FilterToUsersByUserIdConnectionStddevSampleUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FilterToUsersByUserIdConnectionSumFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionSumFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionSumUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  FilterToUsersByUserIdConnectionSumUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  FilterToUsersByUserIdConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionVariancePopulationUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FilterToUsersByUserIdConnectionVariancePopulationUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FilterToUsersByUserIdConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToUsersByUserIdConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToUsersByUserIdConnectionVarianceSampleUserIdAsc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FilterToUsersByUserIdConnectionVarianceSampleUserIdDesc = 'FILTER_TO_USERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  FormAssigneesByUserIdConnectionArrayAggFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionArrayAggFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionArrayAggProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionArrayAggProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionArrayAggUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FormAssigneesByUserIdConnectionArrayAggUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FormAssigneesByUserIdConnectionAverageFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionAverageFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionAverageProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionAverageProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionAverageUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  FormAssigneesByUserIdConnectionAverageUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  FormAssigneesByUserIdConnectionCountAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_COUNT_ASC',
  FormAssigneesByUserIdConnectionCountDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_COUNT_DESC',
  FormAssigneesByUserIdConnectionDistinctCountFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionDistinctCountFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionDistinctCountProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionDistinctCountProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionDistinctCountUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FormAssigneesByUserIdConnectionDistinctCountUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FormAssigneesByUserIdConnectionMaxFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionMaxFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionMaxProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionMaxProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionMaxUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  FormAssigneesByUserIdConnectionMaxUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  FormAssigneesByUserIdConnectionMinFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionMinFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionMinProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionMinProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionMinUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  FormAssigneesByUserIdConnectionMinUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  FormAssigneesByUserIdConnectionStddevPopulationFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionStddevPopulationFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionStddevPopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionStddevPopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionStddevPopulationUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FormAssigneesByUserIdConnectionStddevPopulationUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FormAssigneesByUserIdConnectionStddevSampleFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionStddevSampleFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionStddevSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionStddevSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionStddevSampleUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FormAssigneesByUserIdConnectionStddevSampleUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FormAssigneesByUserIdConnectionSumFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionSumFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionSumProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionSumProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionSumUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  FormAssigneesByUserIdConnectionSumUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  FormAssigneesByUserIdConnectionVariancePopulationFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionVariancePopulationFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionVariancePopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionVariancePopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionVariancePopulationUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FormAssigneesByUserIdConnectionVariancePopulationUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FormAssigneesByUserIdConnectionVarianceSampleFormIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FormAssigneesByUserIdConnectionVarianceSampleFormIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FormAssigneesByUserIdConnectionVarianceSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesByUserIdConnectionVarianceSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesByUserIdConnectionVarianceSampleUserIdAsc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FormAssigneesByUserIdConnectionVarianceSampleUserIdDesc = 'FORM_ASSIGNEES_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCURACY_ASC',
  GeolocationsByUserIdConnectionArrayAggAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCURACY_DESC',
  GeolocationsByUserIdConnectionArrayAggCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionArrayAggCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionArrayAggCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionArrayAggDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionArrayAggDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_HEADING_ASC',
  GeolocationsByUserIdConnectionArrayAggHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_HEADING_DESC',
  GeolocationsByUserIdConnectionArrayAggIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  GeolocationsByUserIdConnectionArrayAggIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_LATITUDE_ASC',
  GeolocationsByUserIdConnectionArrayAggLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_LATITUDE_DESC',
  GeolocationsByUserIdConnectionArrayAggLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionArrayAggLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionArrayAggRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_RAW_ASC',
  GeolocationsByUserIdConnectionArrayAggRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_RAW_DESC',
  GeolocationsByUserIdConnectionArrayAggSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  GeolocationsByUserIdConnectionArrayAggSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  GeolocationsByUserIdConnectionArrayAggSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionArrayAggSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionArrayAggSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SPEED_ASC',
  GeolocationsByUserIdConnectionArrayAggSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_SPEED_DESC',
  GeolocationsByUserIdConnectionArrayAggTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  GeolocationsByUserIdConnectionArrayAggTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionArrayAggTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionArrayAggUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  GeolocationsByUserIdConnectionArrayAggUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  GeolocationsByUserIdConnectionArrayAggUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  GeolocationsByUserIdConnectionArrayAggUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  GeolocationsByUserIdConnectionArrayAggVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_VERSION_ASC',
  GeolocationsByUserIdConnectionArrayAggVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_ARRAY_AGG_VERSION_DESC',
  GeolocationsByUserIdConnectionAverageAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_ACCURACY_ASC',
  GeolocationsByUserIdConnectionAverageAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_ACCURACY_DESC',
  GeolocationsByUserIdConnectionAverageCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionAverageCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionAverageCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionAverageCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionAverageDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionAverageDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionAverageHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_HEADING_ASC',
  GeolocationsByUserIdConnectionAverageHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_HEADING_DESC',
  GeolocationsByUserIdConnectionAverageIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  GeolocationsByUserIdConnectionAverageIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  GeolocationsByUserIdConnectionAverageLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_LATITUDE_ASC',
  GeolocationsByUserIdConnectionAverageLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_LATITUDE_DESC',
  GeolocationsByUserIdConnectionAverageLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionAverageLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionAverageRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_RAW_ASC',
  GeolocationsByUserIdConnectionAverageRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_RAW_DESC',
  GeolocationsByUserIdConnectionAverageSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  GeolocationsByUserIdConnectionAverageSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  GeolocationsByUserIdConnectionAverageSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionAverageSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionAverageSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SPEED_ASC',
  GeolocationsByUserIdConnectionAverageSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_SPEED_DESC',
  GeolocationsByUserIdConnectionAverageTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  GeolocationsByUserIdConnectionAverageTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  GeolocationsByUserIdConnectionAverageTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionAverageTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionAverageUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  GeolocationsByUserIdConnectionAverageUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  GeolocationsByUserIdConnectionAverageUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_UUID_ASC',
  GeolocationsByUserIdConnectionAverageUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_UUID_DESC',
  GeolocationsByUserIdConnectionAverageVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_VERSION_ASC',
  GeolocationsByUserIdConnectionAverageVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_AVERAGE_VERSION_DESC',
  GeolocationsByUserIdConnectionCountAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_COUNT_ASC',
  GeolocationsByUserIdConnectionCountDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_COUNT_DESC',
  GeolocationsByUserIdConnectionDistinctCountAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCURACY_ASC',
  GeolocationsByUserIdConnectionDistinctCountAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCURACY_DESC',
  GeolocationsByUserIdConnectionDistinctCountCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionDistinctCountCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionDistinctCountCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionDistinctCountCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionDistinctCountDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionDistinctCountDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionDistinctCountHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_HEADING_ASC',
  GeolocationsByUserIdConnectionDistinctCountHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_HEADING_DESC',
  GeolocationsByUserIdConnectionDistinctCountIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeolocationsByUserIdConnectionDistinctCountIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeolocationsByUserIdConnectionDistinctCountLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LATITUDE_ASC',
  GeolocationsByUserIdConnectionDistinctCountLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LATITUDE_DESC',
  GeolocationsByUserIdConnectionDistinctCountLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionDistinctCountLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionDistinctCountRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_RAW_ASC',
  GeolocationsByUserIdConnectionDistinctCountRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_RAW_DESC',
  GeolocationsByUserIdConnectionDistinctCountSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  GeolocationsByUserIdConnectionDistinctCountSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  GeolocationsByUserIdConnectionDistinctCountSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionDistinctCountSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionDistinctCountSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SPEED_ASC',
  GeolocationsByUserIdConnectionDistinctCountSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SPEED_DESC',
  GeolocationsByUserIdConnectionDistinctCountTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  GeolocationsByUserIdConnectionDistinctCountTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  GeolocationsByUserIdConnectionDistinctCountTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionDistinctCountTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionDistinctCountUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  GeolocationsByUserIdConnectionDistinctCountUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  GeolocationsByUserIdConnectionDistinctCountUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  GeolocationsByUserIdConnectionDistinctCountUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  GeolocationsByUserIdConnectionDistinctCountVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_VERSION_ASC',
  GeolocationsByUserIdConnectionDistinctCountVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_VERSION_DESC',
  GeolocationsByUserIdConnectionMaxAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_ACCURACY_ASC',
  GeolocationsByUserIdConnectionMaxAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_ACCURACY_DESC',
  GeolocationsByUserIdConnectionMaxCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionMaxCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionMaxCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionMaxCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionMaxDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionMaxDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionMaxHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_HEADING_ASC',
  GeolocationsByUserIdConnectionMaxHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_HEADING_DESC',
  GeolocationsByUserIdConnectionMaxIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  GeolocationsByUserIdConnectionMaxIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  GeolocationsByUserIdConnectionMaxLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_LATITUDE_ASC',
  GeolocationsByUserIdConnectionMaxLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_LATITUDE_DESC',
  GeolocationsByUserIdConnectionMaxLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionMaxLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionMaxRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_RAW_ASC',
  GeolocationsByUserIdConnectionMaxRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_RAW_DESC',
  GeolocationsByUserIdConnectionMaxSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SOURCE_ASC',
  GeolocationsByUserIdConnectionMaxSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SOURCE_DESC',
  GeolocationsByUserIdConnectionMaxSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionMaxSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionMaxSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SPEED_ASC',
  GeolocationsByUserIdConnectionMaxSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_SPEED_DESC',
  GeolocationsByUserIdConnectionMaxTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_TASK_ID_ASC',
  GeolocationsByUserIdConnectionMaxTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_TASK_ID_DESC',
  GeolocationsByUserIdConnectionMaxTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionMaxTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionMaxUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  GeolocationsByUserIdConnectionMaxUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  GeolocationsByUserIdConnectionMaxUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_UUID_ASC',
  GeolocationsByUserIdConnectionMaxUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_UUID_DESC',
  GeolocationsByUserIdConnectionMaxVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_VERSION_ASC',
  GeolocationsByUserIdConnectionMaxVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MAX_VERSION_DESC',
  GeolocationsByUserIdConnectionMinAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_ACCURACY_ASC',
  GeolocationsByUserIdConnectionMinAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_ACCURACY_DESC',
  GeolocationsByUserIdConnectionMinCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionMinCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionMinCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionMinCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionMinDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionMinDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionMinHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_HEADING_ASC',
  GeolocationsByUserIdConnectionMinHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_HEADING_DESC',
  GeolocationsByUserIdConnectionMinIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  GeolocationsByUserIdConnectionMinIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  GeolocationsByUserIdConnectionMinLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_LATITUDE_ASC',
  GeolocationsByUserIdConnectionMinLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_LATITUDE_DESC',
  GeolocationsByUserIdConnectionMinLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionMinLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionMinRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_RAW_ASC',
  GeolocationsByUserIdConnectionMinRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_RAW_DESC',
  GeolocationsByUserIdConnectionMinSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SOURCE_ASC',
  GeolocationsByUserIdConnectionMinSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SOURCE_DESC',
  GeolocationsByUserIdConnectionMinSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionMinSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionMinSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SPEED_ASC',
  GeolocationsByUserIdConnectionMinSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_SPEED_DESC',
  GeolocationsByUserIdConnectionMinTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_TASK_ID_ASC',
  GeolocationsByUserIdConnectionMinTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_TASK_ID_DESC',
  GeolocationsByUserIdConnectionMinTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionMinTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionMinUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  GeolocationsByUserIdConnectionMinUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  GeolocationsByUserIdConnectionMinUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_UUID_ASC',
  GeolocationsByUserIdConnectionMinUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_UUID_DESC',
  GeolocationsByUserIdConnectionMinVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_VERSION_ASC',
  GeolocationsByUserIdConnectionMinVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_MIN_VERSION_DESC',
  GeolocationsByUserIdConnectionStddevPopulationAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCURACY_ASC',
  GeolocationsByUserIdConnectionStddevPopulationAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCURACY_DESC',
  GeolocationsByUserIdConnectionStddevPopulationCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionStddevPopulationCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionStddevPopulationDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_HEADING_ASC',
  GeolocationsByUserIdConnectionStddevPopulationHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_HEADING_DESC',
  GeolocationsByUserIdConnectionStddevPopulationIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LATITUDE_ASC',
  GeolocationsByUserIdConnectionStddevPopulationLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LATITUDE_DESC',
  GeolocationsByUserIdConnectionStddevPopulationLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionStddevPopulationLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionStddevPopulationRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_RAW_ASC',
  GeolocationsByUserIdConnectionStddevPopulationRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_RAW_DESC',
  GeolocationsByUserIdConnectionStddevPopulationSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  GeolocationsByUserIdConnectionStddevPopulationSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  GeolocationsByUserIdConnectionStddevPopulationSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionStddevPopulationSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionStddevPopulationSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SPEED_ASC',
  GeolocationsByUserIdConnectionStddevPopulationSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SPEED_DESC',
  GeolocationsByUserIdConnectionStddevPopulationTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionStddevPopulationTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionStddevPopulationUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  GeolocationsByUserIdConnectionStddevPopulationUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  GeolocationsByUserIdConnectionStddevPopulationVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_VERSION_ASC',
  GeolocationsByUserIdConnectionStddevPopulationVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_VERSION_DESC',
  GeolocationsByUserIdConnectionStddevSampleAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCURACY_ASC',
  GeolocationsByUserIdConnectionStddevSampleAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCURACY_DESC',
  GeolocationsByUserIdConnectionStddevSampleCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionStddevSampleCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionStddevSampleCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionStddevSampleCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionStddevSampleDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionStddevSampleDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionStddevSampleHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_HEADING_ASC',
  GeolocationsByUserIdConnectionStddevSampleHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_HEADING_DESC',
  GeolocationsByUserIdConnectionStddevSampleIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeolocationsByUserIdConnectionStddevSampleIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeolocationsByUserIdConnectionStddevSampleLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LATITUDE_ASC',
  GeolocationsByUserIdConnectionStddevSampleLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LATITUDE_DESC',
  GeolocationsByUserIdConnectionStddevSampleLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionStddevSampleLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionStddevSampleRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_RAW_ASC',
  GeolocationsByUserIdConnectionStddevSampleRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_RAW_DESC',
  GeolocationsByUserIdConnectionStddevSampleSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  GeolocationsByUserIdConnectionStddevSampleSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  GeolocationsByUserIdConnectionStddevSampleSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionStddevSampleSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionStddevSampleSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SPEED_ASC',
  GeolocationsByUserIdConnectionStddevSampleSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SPEED_DESC',
  GeolocationsByUserIdConnectionStddevSampleTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  GeolocationsByUserIdConnectionStddevSampleTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  GeolocationsByUserIdConnectionStddevSampleTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionStddevSampleTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionStddevSampleUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  GeolocationsByUserIdConnectionStddevSampleUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  GeolocationsByUserIdConnectionStddevSampleUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  GeolocationsByUserIdConnectionStddevSampleUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  GeolocationsByUserIdConnectionStddevSampleVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_VERSION_ASC',
  GeolocationsByUserIdConnectionStddevSampleVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_VERSION_DESC',
  GeolocationsByUserIdConnectionSumAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_ACCURACY_ASC',
  GeolocationsByUserIdConnectionSumAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_ACCURACY_DESC',
  GeolocationsByUserIdConnectionSumCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionSumCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionSumCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionSumCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionSumDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionSumDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionSumHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_HEADING_ASC',
  GeolocationsByUserIdConnectionSumHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_HEADING_DESC',
  GeolocationsByUserIdConnectionSumIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  GeolocationsByUserIdConnectionSumIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  GeolocationsByUserIdConnectionSumLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_LATITUDE_ASC',
  GeolocationsByUserIdConnectionSumLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_LATITUDE_DESC',
  GeolocationsByUserIdConnectionSumLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionSumLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionSumRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_RAW_ASC',
  GeolocationsByUserIdConnectionSumRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_RAW_DESC',
  GeolocationsByUserIdConnectionSumSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SOURCE_ASC',
  GeolocationsByUserIdConnectionSumSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SOURCE_DESC',
  GeolocationsByUserIdConnectionSumSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionSumSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionSumSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SPEED_ASC',
  GeolocationsByUserIdConnectionSumSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_SPEED_DESC',
  GeolocationsByUserIdConnectionSumTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_TASK_ID_ASC',
  GeolocationsByUserIdConnectionSumTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_TASK_ID_DESC',
  GeolocationsByUserIdConnectionSumTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionSumTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionSumUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  GeolocationsByUserIdConnectionSumUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  GeolocationsByUserIdConnectionSumUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_UUID_ASC',
  GeolocationsByUserIdConnectionSumUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_UUID_DESC',
  GeolocationsByUserIdConnectionSumVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_VERSION_ASC',
  GeolocationsByUserIdConnectionSumVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_SUM_VERSION_DESC',
  GeolocationsByUserIdConnectionVariancePopulationAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCURACY_ASC',
  GeolocationsByUserIdConnectionVariancePopulationAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCURACY_DESC',
  GeolocationsByUserIdConnectionVariancePopulationCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionVariancePopulationCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionVariancePopulationDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_HEADING_ASC',
  GeolocationsByUserIdConnectionVariancePopulationHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_HEADING_DESC',
  GeolocationsByUserIdConnectionVariancePopulationIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LATITUDE_ASC',
  GeolocationsByUserIdConnectionVariancePopulationLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LATITUDE_DESC',
  GeolocationsByUserIdConnectionVariancePopulationLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionVariancePopulationLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionVariancePopulationRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_RAW_ASC',
  GeolocationsByUserIdConnectionVariancePopulationRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_RAW_DESC',
  GeolocationsByUserIdConnectionVariancePopulationSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  GeolocationsByUserIdConnectionVariancePopulationSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  GeolocationsByUserIdConnectionVariancePopulationSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionVariancePopulationSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionVariancePopulationSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SPEED_ASC',
  GeolocationsByUserIdConnectionVariancePopulationSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SPEED_DESC',
  GeolocationsByUserIdConnectionVariancePopulationTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionVariancePopulationTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionVariancePopulationUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  GeolocationsByUserIdConnectionVariancePopulationUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  GeolocationsByUserIdConnectionVariancePopulationVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_VERSION_ASC',
  GeolocationsByUserIdConnectionVariancePopulationVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_VERSION_DESC',
  GeolocationsByUserIdConnectionVarianceSampleAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCURACY_ASC',
  GeolocationsByUserIdConnectionVarianceSampleAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCURACY_DESC',
  GeolocationsByUserIdConnectionVarianceSampleCompanyIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleCompanyIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleCreatedAtAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeolocationsByUserIdConnectionVarianceSampleCreatedAtDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeolocationsByUserIdConnectionVarianceSampleDeviceIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEVICE_ID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleDeviceIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEVICE_ID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleHeadingAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_HEADING_ASC',
  GeolocationsByUserIdConnectionVarianceSampleHeadingDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_HEADING_DESC',
  GeolocationsByUserIdConnectionVarianceSampleIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleLatitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LATITUDE_ASC',
  GeolocationsByUserIdConnectionVarianceSampleLatitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LATITUDE_DESC',
  GeolocationsByUserIdConnectionVarianceSampleLongitudeAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LONGITUDE_ASC',
  GeolocationsByUserIdConnectionVarianceSampleLongitudeDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LONGITUDE_DESC',
  GeolocationsByUserIdConnectionVarianceSampleRawAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_RAW_ASC',
  GeolocationsByUserIdConnectionVarianceSampleRawDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_RAW_DESC',
  GeolocationsByUserIdConnectionVarianceSampleSourceAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  GeolocationsByUserIdConnectionVarianceSampleSourceDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  GeolocationsByUserIdConnectionVarianceSampleSpeedAccuracyAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SPEED_ACCURACY_ASC',
  GeolocationsByUserIdConnectionVarianceSampleSpeedAccuracyDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SPEED_ACCURACY_DESC',
  GeolocationsByUserIdConnectionVarianceSampleSpeedAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SPEED_ASC',
  GeolocationsByUserIdConnectionVarianceSampleSpeedDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SPEED_DESC',
  GeolocationsByUserIdConnectionVarianceSampleTaskIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleTaskIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleTimestampAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  GeolocationsByUserIdConnectionVarianceSampleTimestampDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  GeolocationsByUserIdConnectionVarianceSampleUserIdAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleUserIdDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleUuidAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  GeolocationsByUserIdConnectionVarianceSampleUuidDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  GeolocationsByUserIdConnectionVarianceSampleVersionAsc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_VERSION_ASC',
  GeolocationsByUserIdConnectionVarianceSampleVersionDesc = 'GEOLOCATIONS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_VERSION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionArrayAggUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionArrayAggUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionAverageCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionAverageCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionAverageCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionAverageCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionAverageCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionAverageCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionAverageCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionAverageCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionAverageEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionAverageEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionAverageErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionAverageErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionAverageErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionAverageErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionAverageFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionAverageFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionAverageGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionAverageGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionAverageGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionAverageGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionAverageIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ImportHistoriesByCreatedByConnectionAverageIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ImportHistoriesByCreatedByConnectionAverageImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionAverageImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionAverageJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionAverageJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionAverageMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionAverageMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionAverageMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionAverageMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionAverageMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionAverageMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionAverageStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionAverageStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionAverageTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionAverageTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionAverageTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionAverageTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionAverageTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionAverageTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionAverageUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionAverageUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionCountAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ImportHistoriesByCreatedByConnectionCountDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMaxCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionMaxCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionMaxCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMaxCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMaxCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionMaxCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionMaxCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionMaxCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionMaxEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMaxEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMaxErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionMaxErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionMaxErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionMaxErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionMaxFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionMaxFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionMaxGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionMaxGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionMaxGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionMaxGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionMaxIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ImportHistoriesByCreatedByConnectionMaxIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ImportHistoriesByCreatedByConnectionMaxImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionMaxImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionMaxJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionMaxJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionMaxMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionMaxMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionMaxMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionMaxMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionMaxMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionMaxMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionMaxStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionMaxStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionMaxTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionMaxTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionMaxTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionMaxTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionMaxTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionMaxTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionMaxUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMaxUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMinCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionMinCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionMinCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMinCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMinCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionMinCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionMinCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionMinCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionMinEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMinEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionMinErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionMinErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionMinErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionMinErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionMinFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionMinFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionMinGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionMinGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionMinGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionMinGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionMinIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ImportHistoriesByCreatedByConnectionMinIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ImportHistoriesByCreatedByConnectionMinImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionMinImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionMinJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionMinJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionMinMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionMinMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionMinMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionMinMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionMinMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionMinMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionMinStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionMinStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionMinTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionMinTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionMinTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionMinTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionMinTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionMinTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionMinUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionMinUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionSumCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionSumCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionSumCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionSumCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionSumCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionSumCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionSumCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionSumCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionSumEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionSumEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionSumErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionSumErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionSumErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionSumErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionSumFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionSumFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionSumGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionSumGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionSumGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionSumGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionSumIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ImportHistoriesByCreatedByConnectionSumIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ImportHistoriesByCreatedByConnectionSumImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionSumImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionSumJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionSumJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionSumMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionSumMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionSumMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionSumMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionSumMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionSumMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionSumStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionSumStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionSumTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionSumTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionSumTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionSumTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionSumTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionSumTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionSumUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionSumUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleFileNameAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleFileNameDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleImportDataAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleImportDataDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleJobIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleJobIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleStatusAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleStatusDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTypeAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleTypeDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ImportHistoriesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IntegrationsByCreatedByConnectionArrayAggActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionArrayAggActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionArrayAggCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionArrayAggCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionArrayAggCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionArrayAggCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionArrayAggCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionArrayAggCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionArrayAggIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  IntegrationsByCreatedByConnectionArrayAggIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  IntegrationsByCreatedByConnectionArrayAggProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionArrayAggProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionArrayAggProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionArrayAggProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionArrayAggUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_ASC',
  IntegrationsByCreatedByConnectionArrayAggUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_DESC',
  IntegrationsByCreatedByConnectionAverageActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionAverageActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionAverageCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionAverageCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionAverageCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionAverageCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionAverageCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionAverageCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionAverageIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  IntegrationsByCreatedByConnectionAverageIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  IntegrationsByCreatedByConnectionAverageProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionAverageProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionAverageProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionAverageProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionAverageUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_ASC',
  IntegrationsByCreatedByConnectionAverageUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_DESC',
  IntegrationsByCreatedByConnectionCountAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  IntegrationsByCreatedByConnectionCountDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  IntegrationsByCreatedByConnectionDistinctCountActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionDistinctCountActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionDistinctCountCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionDistinctCountCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionDistinctCountCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionDistinctCountCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionDistinctCountCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionDistinctCountCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionDistinctCountIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  IntegrationsByCreatedByConnectionDistinctCountIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  IntegrationsByCreatedByConnectionDistinctCountProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionDistinctCountProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionDistinctCountProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionDistinctCountProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionDistinctCountUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  IntegrationsByCreatedByConnectionDistinctCountUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  IntegrationsByCreatedByConnectionMaxActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionMaxActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionMaxCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionMaxCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionMaxCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionMaxCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionMaxCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionMaxCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionMaxIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  IntegrationsByCreatedByConnectionMaxIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  IntegrationsByCreatedByConnectionMaxProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionMaxProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionMaxProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionMaxProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionMaxUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_ASC',
  IntegrationsByCreatedByConnectionMaxUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_DESC',
  IntegrationsByCreatedByConnectionMinActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionMinActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionMinCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionMinCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionMinCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionMinCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionMinCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionMinCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionMinIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  IntegrationsByCreatedByConnectionMinIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  IntegrationsByCreatedByConnectionMinProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionMinProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionMinProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionMinProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionMinUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_ASC',
  IntegrationsByCreatedByConnectionMinUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionStddevPopulationUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  IntegrationsByCreatedByConnectionStddevPopulationUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  IntegrationsByCreatedByConnectionStddevSampleActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionStddevSampleActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionStddevSampleCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionStddevSampleCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionStddevSampleCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionStddevSampleCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionStddevSampleCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionStddevSampleCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionStddevSampleIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  IntegrationsByCreatedByConnectionStddevSampleIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  IntegrationsByCreatedByConnectionStddevSampleProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionStddevSampleProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionStddevSampleProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionStddevSampleProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionStddevSampleUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  IntegrationsByCreatedByConnectionStddevSampleUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  IntegrationsByCreatedByConnectionSumActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionSumActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionSumCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionSumCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionSumCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionSumCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionSumCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionSumCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionSumIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  IntegrationsByCreatedByConnectionSumIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  IntegrationsByCreatedByConnectionSumProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionSumProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionSumProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionSumProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionSumUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_ASC',
  IntegrationsByCreatedByConnectionSumUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionVariancePopulationUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  IntegrationsByCreatedByConnectionVariancePopulationUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleActiveAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTIVE_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleActiveDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTIVE_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleCreatedByAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleCreatedByDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleIdAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleIdDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleProviderAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleProviderConfigAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROVIDER_CONFIG_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleProviderConfigDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROVIDER_CONFIG_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleProviderDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROVIDER_DESC',
  IntegrationsByCreatedByConnectionVarianceSampleUuidAsc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  IntegrationsByCreatedByConnectionVarianceSampleUuidDesc = 'INTEGRATIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  InvoicesByCreatedByConnectionArrayAggAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AMOUNT_ASC',
  InvoicesByCreatedByConnectionArrayAggAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AMOUNT_DESC',
  InvoicesByCreatedByConnectionArrayAggAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionArrayAggAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionArrayAggCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionArrayAggCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionArrayAggCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionArrayAggCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionArrayAggCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionArrayAggCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionArrayAggDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionArrayAggDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionArrayAggDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionArrayAggDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionArrayAggIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  InvoicesByCreatedByConnectionArrayAggIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  InvoicesByCreatedByConnectionArrayAggIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionArrayAggIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionArrayAggIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_BILL_ASC',
  InvoicesByCreatedByConnectionArrayAggIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_BILL_DESC',
  InvoicesByCreatedByConnectionArrayAggProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionArrayAggProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionArrayAggStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  InvoicesByCreatedByConnectionArrayAggStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  InvoicesByCreatedByConnectionArrayAggTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  InvoicesByCreatedByConnectionArrayAggTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  InvoicesByCreatedByConnectionArrayAggUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  InvoicesByCreatedByConnectionArrayAggUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  InvoicesByCreatedByConnectionArrayAggUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionArrayAggUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionAverageAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_AMOUNT_ASC',
  InvoicesByCreatedByConnectionAverageAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_AMOUNT_DESC',
  InvoicesByCreatedByConnectionAverageAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionAverageAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionAverageCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionAverageCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionAverageCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionAverageCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionAverageCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionAverageCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionAverageDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionAverageDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionAverageDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionAverageDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionAverageIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  InvoicesByCreatedByConnectionAverageIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  InvoicesByCreatedByConnectionAverageIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionAverageIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionAverageIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_BILL_ASC',
  InvoicesByCreatedByConnectionAverageIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_BILL_DESC',
  InvoicesByCreatedByConnectionAverageProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionAverageProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionAverageStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  InvoicesByCreatedByConnectionAverageStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  InvoicesByCreatedByConnectionAverageTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  InvoicesByCreatedByConnectionAverageTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  InvoicesByCreatedByConnectionAverageUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  InvoicesByCreatedByConnectionAverageUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  InvoicesByCreatedByConnectionAverageUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionAverageUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionCountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  InvoicesByCreatedByConnectionCountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  InvoicesByCreatedByConnectionDistinctCountAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AMOUNT_ASC',
  InvoicesByCreatedByConnectionDistinctCountAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AMOUNT_DESC',
  InvoicesByCreatedByConnectionDistinctCountAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionDistinctCountAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionDistinctCountCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionDistinctCountCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionDistinctCountCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionDistinctCountCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionDistinctCountCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionDistinctCountCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionDistinctCountDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionDistinctCountDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionDistinctCountDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionDistinctCountDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionDistinctCountIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  InvoicesByCreatedByConnectionDistinctCountIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  InvoicesByCreatedByConnectionDistinctCountIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionDistinctCountIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionDistinctCountIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_BILL_ASC',
  InvoicesByCreatedByConnectionDistinctCountIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_BILL_DESC',
  InvoicesByCreatedByConnectionDistinctCountProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionDistinctCountProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionDistinctCountStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  InvoicesByCreatedByConnectionDistinctCountStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  InvoicesByCreatedByConnectionDistinctCountTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  InvoicesByCreatedByConnectionDistinctCountTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  InvoicesByCreatedByConnectionDistinctCountUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  InvoicesByCreatedByConnectionDistinctCountUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  InvoicesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionMaxAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_AMOUNT_ASC',
  InvoicesByCreatedByConnectionMaxAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_AMOUNT_DESC',
  InvoicesByCreatedByConnectionMaxAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionMaxAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionMaxCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionMaxCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionMaxCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionMaxCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionMaxCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionMaxCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionMaxDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionMaxDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionMaxDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionMaxDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionMaxIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  InvoicesByCreatedByConnectionMaxIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  InvoicesByCreatedByConnectionMaxIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionMaxIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionMaxIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_IS_BILL_ASC',
  InvoicesByCreatedByConnectionMaxIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_IS_BILL_DESC',
  InvoicesByCreatedByConnectionMaxProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionMaxProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionMaxStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  InvoicesByCreatedByConnectionMaxStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  InvoicesByCreatedByConnectionMaxTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  InvoicesByCreatedByConnectionMaxTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  InvoicesByCreatedByConnectionMaxUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  InvoicesByCreatedByConnectionMaxUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  InvoicesByCreatedByConnectionMaxUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionMaxUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionMinAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_AMOUNT_ASC',
  InvoicesByCreatedByConnectionMinAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_AMOUNT_DESC',
  InvoicesByCreatedByConnectionMinAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionMinAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionMinCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionMinCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionMinCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionMinCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionMinCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionMinCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionMinDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionMinDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionMinDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionMinDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionMinIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  InvoicesByCreatedByConnectionMinIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  InvoicesByCreatedByConnectionMinIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionMinIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionMinIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_IS_BILL_ASC',
  InvoicesByCreatedByConnectionMinIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_IS_BILL_DESC',
  InvoicesByCreatedByConnectionMinProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionMinProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionMinStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  InvoicesByCreatedByConnectionMinStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  InvoicesByCreatedByConnectionMinTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  InvoicesByCreatedByConnectionMinTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  InvoicesByCreatedByConnectionMinUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  InvoicesByCreatedByConnectionMinUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  InvoicesByCreatedByConnectionMinUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionMinUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionStddevPopulationAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AMOUNT_ASC',
  InvoicesByCreatedByConnectionStddevPopulationAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AMOUNT_DESC',
  InvoicesByCreatedByConnectionStddevPopulationAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionStddevPopulationAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionStddevPopulationCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionStddevPopulationCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionStddevPopulationDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionStddevPopulationDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionStddevPopulationDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionStddevPopulationDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionStddevPopulationIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  InvoicesByCreatedByConnectionStddevPopulationIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  InvoicesByCreatedByConnectionStddevPopulationIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionStddevPopulationIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionStddevPopulationIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_BILL_ASC',
  InvoicesByCreatedByConnectionStddevPopulationIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_BILL_DESC',
  InvoicesByCreatedByConnectionStddevPopulationProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionStddevPopulationProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionStddevPopulationStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  InvoicesByCreatedByConnectionStddevPopulationStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  InvoicesByCreatedByConnectionStddevPopulationTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  InvoicesByCreatedByConnectionStddevPopulationTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  InvoicesByCreatedByConnectionStddevPopulationUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  InvoicesByCreatedByConnectionStddevPopulationUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  InvoicesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionStddevSampleAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AMOUNT_ASC',
  InvoicesByCreatedByConnectionStddevSampleAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AMOUNT_DESC',
  InvoicesByCreatedByConnectionStddevSampleAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionStddevSampleAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionStddevSampleCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionStddevSampleCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionStddevSampleCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionStddevSampleCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionStddevSampleCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionStddevSampleCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionStddevSampleDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionStddevSampleDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionStddevSampleDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionStddevSampleDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionStddevSampleIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  InvoicesByCreatedByConnectionStddevSampleIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  InvoicesByCreatedByConnectionStddevSampleIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionStddevSampleIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionStddevSampleIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_BILL_ASC',
  InvoicesByCreatedByConnectionStddevSampleIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_BILL_DESC',
  InvoicesByCreatedByConnectionStddevSampleProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionStddevSampleProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionStddevSampleStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  InvoicesByCreatedByConnectionStddevSampleStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  InvoicesByCreatedByConnectionStddevSampleTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  InvoicesByCreatedByConnectionStddevSampleTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  InvoicesByCreatedByConnectionStddevSampleUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  InvoicesByCreatedByConnectionStddevSampleUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  InvoicesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionSumAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_AMOUNT_ASC',
  InvoicesByCreatedByConnectionSumAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_AMOUNT_DESC',
  InvoicesByCreatedByConnectionSumAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionSumAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionSumCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionSumCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionSumCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionSumCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionSumCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionSumCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionSumDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionSumDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionSumDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionSumDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionSumIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  InvoicesByCreatedByConnectionSumIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  InvoicesByCreatedByConnectionSumIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionSumIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionSumIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_IS_BILL_ASC',
  InvoicesByCreatedByConnectionSumIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_IS_BILL_DESC',
  InvoicesByCreatedByConnectionSumProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionSumProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionSumStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  InvoicesByCreatedByConnectionSumStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  InvoicesByCreatedByConnectionSumTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  InvoicesByCreatedByConnectionSumTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  InvoicesByCreatedByConnectionSumUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  InvoicesByCreatedByConnectionSumUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  InvoicesByCreatedByConnectionSumUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionSumUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionVariancePopulationAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AMOUNT_ASC',
  InvoicesByCreatedByConnectionVariancePopulationAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AMOUNT_DESC',
  InvoicesByCreatedByConnectionVariancePopulationAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionVariancePopulationAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionVariancePopulationCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionVariancePopulationCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionVariancePopulationDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionVariancePopulationDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionVariancePopulationDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionVariancePopulationDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionVariancePopulationIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  InvoicesByCreatedByConnectionVariancePopulationIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  InvoicesByCreatedByConnectionVariancePopulationIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionVariancePopulationIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionVariancePopulationIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_BILL_ASC',
  InvoicesByCreatedByConnectionVariancePopulationIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_BILL_DESC',
  InvoicesByCreatedByConnectionVariancePopulationProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionVariancePopulationProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionVariancePopulationStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  InvoicesByCreatedByConnectionVariancePopulationStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  InvoicesByCreatedByConnectionVariancePopulationTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  InvoicesByCreatedByConnectionVariancePopulationTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  InvoicesByCreatedByConnectionVariancePopulationUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  InvoicesByCreatedByConnectionVariancePopulationUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  InvoicesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  InvoicesByCreatedByConnectionVarianceSampleAmountAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AMOUNT_ASC',
  InvoicesByCreatedByConnectionVarianceSampleAmountDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AMOUNT_DESC',
  InvoicesByCreatedByConnectionVarianceSampleAmountPaidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AMOUNT_PAID_ASC',
  InvoicesByCreatedByConnectionVarianceSampleAmountPaidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AMOUNT_PAID_DESC',
  InvoicesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  InvoicesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  InvoicesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  InvoicesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  InvoicesByCreatedByConnectionVarianceSampleCreatedByAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  InvoicesByCreatedByConnectionVarianceSampleCreatedByDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  InvoicesByCreatedByConnectionVarianceSampleDescriptionAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  InvoicesByCreatedByConnectionVarianceSampleDescriptionDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  InvoicesByCreatedByConnectionVarianceSampleDueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  InvoicesByCreatedByConnectionVarianceSampleDueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  InvoicesByCreatedByConnectionVarianceSampleIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  InvoicesByCreatedByConnectionVarianceSampleIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  InvoicesByCreatedByConnectionVarianceSampleIssueDateAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ISSUE_DATE_ASC',
  InvoicesByCreatedByConnectionVarianceSampleIssueDateDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ISSUE_DATE_DESC',
  InvoicesByCreatedByConnectionVarianceSampleIsBillAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_BILL_ASC',
  InvoicesByCreatedByConnectionVarianceSampleIsBillDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_BILL_DESC',
  InvoicesByCreatedByConnectionVarianceSampleProjectIdAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  InvoicesByCreatedByConnectionVarianceSampleProjectIdDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  InvoicesByCreatedByConnectionVarianceSampleStatusAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  InvoicesByCreatedByConnectionVarianceSampleStatusDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  InvoicesByCreatedByConnectionVarianceSampleTitleAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  InvoicesByCreatedByConnectionVarianceSampleTitleDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  InvoicesByCreatedByConnectionVarianceSampleUidAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  InvoicesByCreatedByConnectionVarianceSampleUidDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  InvoicesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  InvoicesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'INVOICES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IsApiAccessUserAsc = 'IS_API_ACCESS_USER_ASC',
  IsApiAccessUserDesc = 'IS_API_ACCESS_USER_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LineItemsByCreatedByConnectionArrayAggCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionArrayAggCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionArrayAggCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionArrayAggCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionArrayAggCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionArrayAggCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionArrayAggDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionArrayAggDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionArrayAggIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  LineItemsByCreatedByConnectionArrayAggIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  LineItemsByCreatedByConnectionArrayAggProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionArrayAggProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionArrayAggQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_QUANTITY_ASC',
  LineItemsByCreatedByConnectionArrayAggQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_QUANTITY_DESC',
  LineItemsByCreatedByConnectionArrayAggRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  LineItemsByCreatedByConnectionArrayAggRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  LineItemsByCreatedByConnectionArrayAggTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionArrayAggTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionArrayAggTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionArrayAggTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionArrayAggUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionArrayAggUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionArrayAggUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionArrayAggUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionArrayAggUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionArrayAggUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionArrayAggUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionArrayAggUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionAverageCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionAverageCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionAverageCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionAverageCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionAverageCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionAverageCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionAverageDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionAverageDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionAverageIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  LineItemsByCreatedByConnectionAverageIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  LineItemsByCreatedByConnectionAverageProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionAverageProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionAverageQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_QUANTITY_ASC',
  LineItemsByCreatedByConnectionAverageQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_QUANTITY_DESC',
  LineItemsByCreatedByConnectionAverageRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_ASC',
  LineItemsByCreatedByConnectionAverageRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_DESC',
  LineItemsByCreatedByConnectionAverageTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionAverageTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionAverageTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionAverageTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionAverageUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionAverageUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionAverageUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionAverageUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionAverageUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionAverageUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionAverageUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionAverageUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionCountAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  LineItemsByCreatedByConnectionCountDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  LineItemsByCreatedByConnectionDistinctCountCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionDistinctCountCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionDistinctCountCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionDistinctCountCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionDistinctCountCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionDistinctCountCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionDistinctCountDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionDistinctCountDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionDistinctCountIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  LineItemsByCreatedByConnectionDistinctCountIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  LineItemsByCreatedByConnectionDistinctCountProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionDistinctCountProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionDistinctCountQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_QUANTITY_ASC',
  LineItemsByCreatedByConnectionDistinctCountQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_QUANTITY_DESC',
  LineItemsByCreatedByConnectionDistinctCountRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  LineItemsByCreatedByConnectionDistinctCountRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  LineItemsByCreatedByConnectionDistinctCountTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionDistinctCountTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionDistinctCountTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionDistinctCountTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionDistinctCountUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionDistinctCountUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionDistinctCountUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionDistinctCountUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionDistinctCountUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionDistinctCountUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionMaxCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionMaxCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionMaxCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionMaxCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionMaxCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionMaxCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionMaxDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionMaxDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionMaxIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  LineItemsByCreatedByConnectionMaxIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  LineItemsByCreatedByConnectionMaxProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionMaxProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionMaxQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_QUANTITY_ASC',
  LineItemsByCreatedByConnectionMaxQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_QUANTITY_DESC',
  LineItemsByCreatedByConnectionMaxRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_ASC',
  LineItemsByCreatedByConnectionMaxRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_DESC',
  LineItemsByCreatedByConnectionMaxTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionMaxTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionMaxTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionMaxTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionMaxUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionMaxUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionMaxUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionMaxUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionMaxUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionMaxUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionMaxUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionMaxUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionMinCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionMinCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionMinCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionMinCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionMinCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionMinCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionMinDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionMinDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionMinIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  LineItemsByCreatedByConnectionMinIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  LineItemsByCreatedByConnectionMinProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionMinProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionMinQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_QUANTITY_ASC',
  LineItemsByCreatedByConnectionMinQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_QUANTITY_DESC',
  LineItemsByCreatedByConnectionMinRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_ASC',
  LineItemsByCreatedByConnectionMinRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_DESC',
  LineItemsByCreatedByConnectionMinTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionMinTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionMinTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionMinTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionMinUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionMinUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionMinUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionMinUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionMinUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionMinUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionMinUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionMinUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionStddevPopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionStddevPopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionStddevPopulationCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionStddevPopulationCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionStddevPopulationDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionStddevPopulationDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionStddevPopulationIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  LineItemsByCreatedByConnectionStddevPopulationIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  LineItemsByCreatedByConnectionStddevPopulationProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionStddevPopulationProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionStddevPopulationQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_QUANTITY_ASC',
  LineItemsByCreatedByConnectionStddevPopulationQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_QUANTITY_DESC',
  LineItemsByCreatedByConnectionStddevPopulationRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  LineItemsByCreatedByConnectionStddevPopulationRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  LineItemsByCreatedByConnectionStddevPopulationTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionStddevPopulationTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionStddevPopulationTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionStddevPopulationTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionStddevPopulationUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionStddevPopulationUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionStddevPopulationUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionStddevPopulationUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionStddevPopulationUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionStddevPopulationUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionStddevSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionStddevSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionStddevSampleCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionStddevSampleCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionStddevSampleCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionStddevSampleCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionStddevSampleDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionStddevSampleDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionStddevSampleIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  LineItemsByCreatedByConnectionStddevSampleIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  LineItemsByCreatedByConnectionStddevSampleProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionStddevSampleProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionStddevSampleQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_QUANTITY_ASC',
  LineItemsByCreatedByConnectionStddevSampleQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_QUANTITY_DESC',
  LineItemsByCreatedByConnectionStddevSampleRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  LineItemsByCreatedByConnectionStddevSampleRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  LineItemsByCreatedByConnectionStddevSampleTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionStddevSampleTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionStddevSampleTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionStddevSampleTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionStddevSampleUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionStddevSampleUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionStddevSampleUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionStddevSampleUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionStddevSampleUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionStddevSampleUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionSumCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionSumCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionSumCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionSumCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionSumCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionSumCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionSumDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionSumDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionSumIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  LineItemsByCreatedByConnectionSumIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  LineItemsByCreatedByConnectionSumProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionSumProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionSumQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_QUANTITY_ASC',
  LineItemsByCreatedByConnectionSumQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_QUANTITY_DESC',
  LineItemsByCreatedByConnectionSumRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_ASC',
  LineItemsByCreatedByConnectionSumRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_DESC',
  LineItemsByCreatedByConnectionSumTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionSumTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionSumTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionSumTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionSumUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionSumUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionSumUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionSumUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionSumUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionSumUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionSumUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionSumUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionVariancePopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionVariancePopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionVariancePopulationCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionVariancePopulationCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionVariancePopulationDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionVariancePopulationDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionVariancePopulationIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  LineItemsByCreatedByConnectionVariancePopulationIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  LineItemsByCreatedByConnectionVariancePopulationProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionVariancePopulationProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionVariancePopulationQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_QUANTITY_ASC',
  LineItemsByCreatedByConnectionVariancePopulationQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_QUANTITY_DESC',
  LineItemsByCreatedByConnectionVariancePopulationRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  LineItemsByCreatedByConnectionVariancePopulationRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  LineItemsByCreatedByConnectionVariancePopulationTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionVariancePopulationTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionVariancePopulationTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionVariancePopulationTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionVariancePopulationUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionVariancePopulationUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionVariancePopulationUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionVariancePopulationUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionVariancePopulationUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionVariancePopulationUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_DESC',
  LineItemsByCreatedByConnectionVarianceSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByCreatedByConnectionVarianceSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LineItemsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LineItemsByCreatedByConnectionVarianceSampleCreatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  LineItemsByCreatedByConnectionVarianceSampleCreatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  LineItemsByCreatedByConnectionVarianceSampleDescriptionAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  LineItemsByCreatedByConnectionVarianceSampleDescriptionDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  LineItemsByCreatedByConnectionVarianceSampleIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  LineItemsByCreatedByConnectionVarianceSampleIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  LineItemsByCreatedByConnectionVarianceSampleProjectIdAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  LineItemsByCreatedByConnectionVarianceSampleProjectIdDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  LineItemsByCreatedByConnectionVarianceSampleQuantityAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_QUANTITY_ASC',
  LineItemsByCreatedByConnectionVarianceSampleQuantityDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_QUANTITY_DESC',
  LineItemsByCreatedByConnectionVarianceSampleRevenueAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  LineItemsByCreatedByConnectionVarianceSampleRevenueDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  LineItemsByCreatedByConnectionVarianceSampleTotalCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  LineItemsByCreatedByConnectionVarianceSampleTotalCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  LineItemsByCreatedByConnectionVarianceSampleTotalPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByCreatedByConnectionVarianceSampleTotalPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByCreatedByConnectionVarianceSampleUnitCostAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_ASC',
  LineItemsByCreatedByConnectionVarianceSampleUnitCostDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_DESC',
  LineItemsByCreatedByConnectionVarianceSampleUnitPriceAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByCreatedByConnectionVarianceSampleUnitPriceDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LineItemsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LineItemsByCreatedByConnectionVarianceSampleUpdatedByAsc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_ASC',
  LineItemsByCreatedByConnectionVarianceSampleUpdatedByDesc = 'LINE_ITEMS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionArrayAggCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionArrayAggCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionArrayAggCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionArrayAggCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionArrayAggCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionArrayAggCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionArrayAggDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionArrayAggDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionArrayAggIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  LineItemsByUpdatedByConnectionArrayAggIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  LineItemsByUpdatedByConnectionArrayAggProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionArrayAggProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionArrayAggQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionArrayAggQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionArrayAggRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  LineItemsByUpdatedByConnectionArrayAggRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  LineItemsByUpdatedByConnectionArrayAggTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionArrayAggTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionArrayAggTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionArrayAggTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionArrayAggUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionArrayAggUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionArrayAggUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionArrayAggUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionArrayAggUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionArrayAggUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionArrayAggUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionArrayAggUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_ARRAY_AGG_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionAverageCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionAverageCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionAverageCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionAverageCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionAverageCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionAverageCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionAverageDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionAverageDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionAverageIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_ID_ASC',
  LineItemsByUpdatedByConnectionAverageIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_ID_DESC',
  LineItemsByUpdatedByConnectionAverageProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionAverageProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionAverageQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionAverageQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionAverageRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_REVENUE_ASC',
  LineItemsByUpdatedByConnectionAverageRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_REVENUE_DESC',
  LineItemsByUpdatedByConnectionAverageTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionAverageTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionAverageTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionAverageTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionAverageUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionAverageUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionAverageUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionAverageUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionAverageUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionAverageUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionAverageUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionAverageUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_AVERAGE_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionCountAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_COUNT_ASC',
  LineItemsByUpdatedByConnectionCountDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_COUNT_DESC',
  LineItemsByUpdatedByConnectionDistinctCountCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionDistinctCountCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionDistinctCountCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionDistinctCountCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionDistinctCountCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionDistinctCountCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionDistinctCountDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionDistinctCountDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionDistinctCountIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  LineItemsByUpdatedByConnectionDistinctCountIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  LineItemsByUpdatedByConnectionDistinctCountProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionDistinctCountProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionDistinctCountQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionDistinctCountQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionDistinctCountRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  LineItemsByUpdatedByConnectionDistinctCountRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  LineItemsByUpdatedByConnectionDistinctCountTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionDistinctCountTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionDistinctCountTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionDistinctCountTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionDistinctCountUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionDistinctCountUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionDistinctCountUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionDistinctCountUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionDistinctCountUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionDistinctCountUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionDistinctCountUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionDistinctCountUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionMaxCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionMaxCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionMaxCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionMaxCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionMaxCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionMaxCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionMaxDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionMaxDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionMaxIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_ID_ASC',
  LineItemsByUpdatedByConnectionMaxIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_ID_DESC',
  LineItemsByUpdatedByConnectionMaxProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionMaxProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionMaxQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionMaxQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionMaxRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_REVENUE_ASC',
  LineItemsByUpdatedByConnectionMaxRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_REVENUE_DESC',
  LineItemsByUpdatedByConnectionMaxTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionMaxTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionMaxTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionMaxTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionMaxUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionMaxUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionMaxUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionMaxUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionMaxUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionMaxUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionMaxUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionMaxUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MAX_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionMinCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionMinCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionMinCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionMinCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionMinCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionMinCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionMinDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionMinDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionMinIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_ID_ASC',
  LineItemsByUpdatedByConnectionMinIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_ID_DESC',
  LineItemsByUpdatedByConnectionMinProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionMinProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionMinQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionMinQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionMinRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_REVENUE_ASC',
  LineItemsByUpdatedByConnectionMinRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_REVENUE_DESC',
  LineItemsByUpdatedByConnectionMinTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionMinTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionMinTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionMinTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionMinUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionMinUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionMinUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionMinUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionMinUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionMinUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionMinUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionMinUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_MIN_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionStddevPopulationUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionStddevPopulationUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionStddevSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionStddevSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionStddevSampleCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionStddevSampleCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionStddevSampleCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionStddevSampleCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionStddevSampleDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionStddevSampleDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionStddevSampleIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  LineItemsByUpdatedByConnectionStddevSampleIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  LineItemsByUpdatedByConnectionStddevSampleProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionStddevSampleProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionStddevSampleQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionStddevSampleQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionStddevSampleRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  LineItemsByUpdatedByConnectionStddevSampleRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  LineItemsByUpdatedByConnectionStddevSampleTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionStddevSampleTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionStddevSampleTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionStddevSampleTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionStddevSampleUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionStddevSampleUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionStddevSampleUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionStddevSampleUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionStddevSampleUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionStddevSampleUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionStddevSampleUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionStddevSampleUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionSumCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionSumCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionSumCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionSumCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionSumCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionSumCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionSumDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionSumDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionSumIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_ID_ASC',
  LineItemsByUpdatedByConnectionSumIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_ID_DESC',
  LineItemsByUpdatedByConnectionSumProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionSumProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionSumQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionSumQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionSumRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_REVENUE_ASC',
  LineItemsByUpdatedByConnectionSumRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_REVENUE_DESC',
  LineItemsByUpdatedByConnectionSumTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionSumTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionSumTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionSumTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionSumUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionSumUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionSumUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionSumUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionSumUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionSumUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionSumUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionSumUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_SUM_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionVariancePopulationUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionVariancePopulationUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleCreatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleCreatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleCreatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleCreatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleDescriptionAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleDescriptionDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleProjectIdAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleProjectIdDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleQuantityAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_QUANTITY_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleQuantityDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_QUANTITY_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleRevenueAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleRevenueDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleTotalCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleTotalCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleTotalPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleTotalPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleUnitCostAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleUnitCostDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleUnitPriceAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleUnitPriceDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleUpdatedAtAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleUpdatedAtDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LineItemsByUpdatedByConnectionVarianceSampleUpdatedByAsc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_ASC',
  LineItemsByUpdatedByConnectionVarianceSampleUpdatedByDesc = 'LINE_ITEMS_BY_UPDATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_DESC',
  Natural = 'NATURAL',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionCountAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_COUNT_ASC',
  PrivilegedTasksByAssigneeIdConnectionCountDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_COUNT_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByAssigneeIdConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksByCreatedByConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksByCreatedByConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksByCreatedByConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionCountAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  PrivilegedTasksByCreatedByConnectionCountDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksByCreatedByConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksByCreatedByConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksByCreatedByConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksByCreatedByConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksByCreatedByConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksByCreatedByConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksByCreatedByConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksByCreatedByConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByCreatedByConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  ResetPasswordExpiresAsc = 'RESET_PASSWORD_EXPIRES_ASC',
  ResetPasswordExpiresDesc = 'RESET_PASSWORD_EXPIRES_DESC',
  ResetPasswordTokenAsc = 'RESET_PASSWORD_TOKEN_ASC',
  ResetPasswordTokenDesc = 'RESET_PASSWORD_TOKEN_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionAverageAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionAverageAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionAverageFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionAverageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionAveragePositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionAveragePositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionAverageStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionAverageStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionAverageTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionAverageTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionAverageUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByAssigneeIdConnectionAverageUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByAssigneeIdConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMaxAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionMaxAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionMaxFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionMaxIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionMaxPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMaxStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMaxStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMaxTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionMaxTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionMaxUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_UID_ASC',
  TaskReportsByAssigneeIdConnectionMaxUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_UID_DESC',
  TaskReportsByAssigneeIdConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMinAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionMinAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionMinEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMinEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionMinFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionMinIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionMinJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionMinPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionMinPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionMinPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionMinStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMinStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionMinStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMinStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionMinTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionMinTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionMinUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_UID_ASC',
  TaskReportsByAssigneeIdConnectionMinUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_UID_DESC',
  TaskReportsByAssigneeIdConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionSumAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionSumAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionSumEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionSumEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionSumFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionSumIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionSumJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionSumPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionSumPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionSumPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionSumStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionSumStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionSumStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionSumStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionSumTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionSumTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionSumUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_UID_ASC',
  TaskReportsByAssigneeIdConnectionSumUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_UID_DESC',
  TaskReportsByAssigneeIdConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByAssigneeIdConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByCreatedByConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByCreatedByConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByCreatedByConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByCreatedByConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByCreatedByConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByCreatedByConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByCreatedByConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByCreatedByConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByCreatedByConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByCreatedByConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByCreatedByConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByCreatedByConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByCreatedByConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByCreatedByConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionAverageAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionAverageAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByCreatedByConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByCreatedByConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionAverageFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByCreatedByConnectionAverageFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByCreatedByConnectionAverageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByCreatedByConnectionAverageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByCreatedByConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionAveragePositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByCreatedByConnectionAveragePositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByCreatedByConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByCreatedByConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByCreatedByConnectionAverageStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByCreatedByConnectionAverageStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByCreatedByConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionAverageTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByCreatedByConnectionAverageTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByCreatedByConnectionAverageUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByCreatedByConnectionAverageUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByCreatedByConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  TaskReportsByCreatedByConnectionCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByCreatedByConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByCreatedByConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByCreatedByConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByCreatedByConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionMaxAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionMaxAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByCreatedByConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByCreatedByConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionMaxFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByCreatedByConnectionMaxFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByCreatedByConnectionMaxIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  TaskReportsByCreatedByConnectionMaxIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  TaskReportsByCreatedByConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionMaxPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByCreatedByConnectionMaxPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByCreatedByConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByCreatedByConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByCreatedByConnectionMaxStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByCreatedByConnectionMaxStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByCreatedByConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionMaxTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByCreatedByConnectionMaxTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByCreatedByConnectionMaxUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  TaskReportsByCreatedByConnectionMaxUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  TaskReportsByCreatedByConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionMinAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionMinAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionMinEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByCreatedByConnectionMinEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByCreatedByConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionMinFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByCreatedByConnectionMinFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByCreatedByConnectionMinIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  TaskReportsByCreatedByConnectionMinIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  TaskReportsByCreatedByConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionMinJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionMinJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionMinPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByCreatedByConnectionMinPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByCreatedByConnectionMinPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionMinPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionMinStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByCreatedByConnectionMinStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByCreatedByConnectionMinStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByCreatedByConnectionMinStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByCreatedByConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionMinTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByCreatedByConnectionMinTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByCreatedByConnectionMinUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  TaskReportsByCreatedByConnectionMinUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  TaskReportsByCreatedByConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByCreatedByConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByCreatedByConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByCreatedByConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionSumAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionSumAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionSumEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByCreatedByConnectionSumEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByCreatedByConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionSumFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByCreatedByConnectionSumFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByCreatedByConnectionSumIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  TaskReportsByCreatedByConnectionSumIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  TaskReportsByCreatedByConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionSumJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionSumJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionSumPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByCreatedByConnectionSumPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByCreatedByConnectionSumPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionSumPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionSumStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByCreatedByConnectionSumStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByCreatedByConnectionSumStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByCreatedByConnectionSumStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByCreatedByConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionSumTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByCreatedByConnectionSumTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByCreatedByConnectionSumUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  TaskReportsByCreatedByConnectionSumUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  TaskReportsByCreatedByConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByCreatedByConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByCreatedByConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByCreatedByConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByCreatedByConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionArrayAggCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionArrayAggCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionArrayAggCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionArrayAggCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionArrayAggCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionArrayAggCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionArrayAggDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionArrayAggDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionArrayAggEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionArrayAggEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionArrayAggEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionArrayAggEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionArrayAggIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskVisitsByCreatedByConnectionArrayAggIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskVisitsByCreatedByConnectionArrayAggIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionArrayAggIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionArrayAggJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionArrayAggJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionArrayAggPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskVisitsByCreatedByConnectionArrayAggPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskVisitsByCreatedByConnectionArrayAggStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionArrayAggStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionArrayAggStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionArrayAggStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionArrayAggTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionArrayAggTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionArrayAggUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionArrayAggUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionAverageCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionAverageCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionAverageCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionAverageCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionAverageCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionAverageCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionAverageDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionAverageDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionAverageEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionAverageEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionAverageEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionAverageEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionAverageIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  TaskVisitsByCreatedByConnectionAverageIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  TaskVisitsByCreatedByConnectionAverageIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionAverageIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionAverageJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionAverageJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionAveragePositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  TaskVisitsByCreatedByConnectionAveragePositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  TaskVisitsByCreatedByConnectionAverageStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionAverageStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionAverageStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionAverageStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionAverageTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionAverageTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionAverageUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionAverageUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionCountAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  TaskVisitsByCreatedByConnectionCountDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionMaxCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionMaxCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionMaxCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionMaxCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionMaxCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionMaxCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionMaxDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionMaxDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionMaxEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionMaxEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionMaxEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionMaxEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionMaxIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  TaskVisitsByCreatedByConnectionMaxIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  TaskVisitsByCreatedByConnectionMaxIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionMaxIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionMaxJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionMaxJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionMaxPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  TaskVisitsByCreatedByConnectionMaxPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  TaskVisitsByCreatedByConnectionMaxStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionMaxStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionMaxStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionMaxStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionMaxTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionMaxTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionMaxUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionMaxUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionMinCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionMinCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionMinCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionMinCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionMinCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionMinCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionMinDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionMinDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionMinEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionMinEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionMinEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionMinEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionMinIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  TaskVisitsByCreatedByConnectionMinIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  TaskVisitsByCreatedByConnectionMinIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionMinIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionMinJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionMinJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionMinPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  TaskVisitsByCreatedByConnectionMinPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  TaskVisitsByCreatedByConnectionMinStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionMinStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionMinStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionMinStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionMinTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionMinTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionMinUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionMinUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevSamplePositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskVisitsByCreatedByConnectionStddevSamplePositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionSumCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionSumCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionSumCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionSumCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionSumCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionSumCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionSumDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionSumDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionSumEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionSumEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionSumEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionSumEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionSumIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  TaskVisitsByCreatedByConnectionSumIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  TaskVisitsByCreatedByConnectionSumIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionSumIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionSumJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionSumJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionSumPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  TaskVisitsByCreatedByConnectionSumPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  TaskVisitsByCreatedByConnectionSumStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionSumStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionSumStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionSumStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionSumTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionSumTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionSumUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionSumUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationPositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationPositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleCompletedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleCompletedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleCreatedByAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleCreatedByDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleDescriptionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleDescriptionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleEndDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleEndDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleEndDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleEndDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleIsCompletedAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleIsCompletedDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleJobIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleJobIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskVisitsByCreatedByConnectionVarianceSamplePositionAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskVisitsByCreatedByConnectionVarianceSamplePositionDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleStartDateAllDayAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleStartDateAllDayDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleStartDateAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleStartDateDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleTaskIdAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleTaskIdDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskVisitsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'TASK_VISITS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionArrayAggUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionArrayAggUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionAverageUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionAverageUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionCountAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_COUNT_ASC',
  UserGeolocationSettingsByUserIdConnectionCountDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_COUNT_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionDistinctCountUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMaxUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMaxUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMinCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMinCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMinCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionMinCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionMinEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionMinEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionMinIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMinIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMinRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMinRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMinTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMinTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionMinUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionMinUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevPopulationUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionStddevSampleUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionSumCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionSumCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionSumCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionSumCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionSumEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionSumEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionSumIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionSumIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionSumRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionSumRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionSumTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionSumTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionSumUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionSumUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVariancePopulationUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleCompanyIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleCompanyIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleCreatedAtAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleCreatedAtDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleEnabledAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleEnabledDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleRoleIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleRoleIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleTeamIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleTeamIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleUserIdAsc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  UserGeolocationSettingsByUserIdConnectionVarianceSampleUserIdDesc = 'USER_GEOLOCATION_SETTINGS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type NormAddy = {
  address: Maybe<Scalars['Int']['output']>;
  addressAlphanumeric: Maybe<Scalars['String']['output']>;
  internal: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  parsed: Maybe<Scalars['Boolean']['output']>;
  postdirabbrev: Maybe<Scalars['String']['output']>;
  predirabbrev: Maybe<Scalars['String']['output']>;
  stateabbrev: Maybe<Scalars['String']['output']>;
  streetname: Maybe<Scalars['String']['output']>;
  streettypeabbrev: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['String']['output']>;
  zip4: Maybe<Scalars['String']['output']>;
};

/** A filter to be used against `NormAddy` object types. All fields are combined with a logical ‘and.’ */
export type NormAddyFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<IntFilter>;
  /** Filter by the object’s `addressAlphanumeric` field. */
  addressAlphanumeric: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<NormAddyFilter>>;
  /** Filter by the object’s `internal` field. */
  internal: InputMaybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<NormAddyFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<NormAddyFilter>>;
  /** Filter by the object’s `parsed` field. */
  parsed: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `postdirabbrev` field. */
  postdirabbrev: InputMaybe<StringFilter>;
  /** Filter by the object’s `predirabbrev` field. */
  predirabbrev: InputMaybe<StringFilter>;
  /** Filter by the object’s `stateabbrev` field. */
  stateabbrev: InputMaybe<StringFilter>;
  /** Filter by the object’s `streetname` field. */
  streetname: InputMaybe<StringFilter>;
  /** Filter by the object’s `streettypeabbrev` field. */
  streettypeabbrev: InputMaybe<StringFilter>;
  /** Filter by the object’s `zip` field. */
  zip: InputMaybe<StringFilter>;
  /** Filter by the object’s `zip4` field. */
  zip4: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `NormAddy` */
export type NormAddyInput = {
  address: InputMaybe<Scalars['Int']['input']>;
  addressAlphanumeric: InputMaybe<Scalars['String']['input']>;
  internal: InputMaybe<Scalars['String']['input']>;
  location: InputMaybe<Scalars['String']['input']>;
  parsed: InputMaybe<Scalars['Boolean']['input']>;
  postdirabbrev: InputMaybe<Scalars['String']['input']>;
  predirabbrev: InputMaybe<Scalars['String']['input']>;
  stateabbrev: InputMaybe<Scalars['String']['input']>;
  streetname: InputMaybe<Scalars['String']['input']>;
  streettypeabbrev: InputMaybe<Scalars['String']['input']>;
  zip: InputMaybe<Scalars['String']['input']>;
  zip4: InputMaybe<Scalars['String']['input']>;
};

export type NotificationEntityDecisionTree = {
  description: Maybe<Scalars['String']['output']>;
  entityType: Maybe<EntityType>;
  events: Maybe<Array<Maybe<NotificationEventDecisionTree>>>;
  title: Maybe<Scalars['String']['output']>;
};

export type NotificationEventDecisionTree = {
  always: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  eventType: Maybe<EventType>;
  receiver: Maybe<ReceiverSettingsDecisionTree>;
  title: Maybe<Scalars['String']['output']>;
};

export enum NotificationOffsetUnit {
  Days = 'DAYS',
  Hours = 'HOURS'
}

/** A filter to be used against NotificationOffsetUnit fields. All fields are combined with a logical ‘and.’ */
export type NotificationOffsetUnitFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<NotificationOffsetUnit>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<NotificationOffsetUnit>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<NotificationOffsetUnit>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<NotificationOffsetUnit>;
  /** Included in the specified list. */
  in: InputMaybe<Array<NotificationOffsetUnit>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<NotificationOffsetUnit>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<NotificationOffsetUnit>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<NotificationOffsetUnit>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<NotificationOffsetUnit>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<NotificationOffsetUnit>>;
};

export enum OverbudgetBehaviour {
  None = 'NONE',
  StopAll = 'STOP_ALL',
  StopExceptWhitelist = 'STOP_EXCEPT_WHITELIST'
}

/** A filter to be used against OverbudgetBehaviour fields. All fields are combined with a logical ‘and.’ */
export type OverbudgetBehaviourFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<OverbudgetBehaviour>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<OverbudgetBehaviour>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<OverbudgetBehaviour>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<OverbudgetBehaviour>;
  /** Included in the specified list. */
  in: InputMaybe<Array<OverbudgetBehaviour>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<OverbudgetBehaviour>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<OverbudgetBehaviour>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<OverbudgetBehaviour>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<OverbudgetBehaviour>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<OverbudgetBehaviour>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['Cursor']['output']>;
};

export type Point = {
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type PortalNotificationEntityDecisionTree = {
  description: Maybe<Scalars['String']['output']>;
  entityType: Maybe<EntityType>;
  events: Maybe<Array<Maybe<PortalNotificationEventDecisionTree>>>;
  title: Maybe<Scalars['String']['output']>;
};

export type PortalNotificationEventDecisionTree = {
  always: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emailTemplate: Maybe<EmailTemplate>;
  eventType: Maybe<EventType>;
  offset1Email: Maybe<Scalars['Boolean']['output']>;
  offset1Sms: Maybe<Scalars['Boolean']['output']>;
  offset1Time: Maybe<Scalars['Int']['output']>;
  offset1Unit: Maybe<NotificationOffsetUnit>;
  offset2Email: Maybe<Scalars['Boolean']['output']>;
  offset2Sms: Maybe<Scalars['Boolean']['output']>;
  offset2Time: Maybe<Scalars['Int']['output']>;
  offset2Unit: Maybe<NotificationOffsetUnit>;
  scheduled: Maybe<Scalars['Boolean']['output']>;
  smsTemplate: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  tokens: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum PortalStatus {
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  Disabled = 'DISABLED',
  Invited = 'INVITED'
}

/** A filter to be used against PortalStatus fields. All fields are combined with a logical ‘and.’ */
export type PortalStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<PortalStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<PortalStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<PortalStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<PortalStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<PortalStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<PortalStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<PortalStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<PortalStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<PortalStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<PortalStatus>>;
};

export type PrivilegedTask = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedTaskIdAndActionId: PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedTaskIdAndActionIdList: Array<Action>;
  address: Maybe<Scalars['String']['output']>;
  /** Reads a single `MainUser` that is related to this `PrivilegedTask`. */
  assignee: Maybe<MainUser>;
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  calculatedStatus: Maybe<TaskStatus>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedTaskIdAndCallId: PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedTaskIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedTaskIdAndCommentId: PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByTaskIdConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskTemplateTaskIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportTaskIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportTemplateTaskIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `PrivilegedTask`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedTaskIdAndCreatedByContactId: PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedTaskIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `PrivilegedTask`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedTaskIdAndEmailMessageIdList: Array<EmailMessage>;
  endDate: Maybe<Scalars['Datetime']['output']>;
  endDateAllDay: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedTaskIdAndParentId: PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentTaskIdAndFileId: PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentTaskIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedTaskIdAndFileId: PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedTaskIdAndFileIdList: Array<File>;
  /** Reads a single `Label` that is related to this `PrivilegedTask`. */
  firstLabel: Maybe<Label>;
  firstLabelId: Maybe<Scalars['Int']['output']>;
  forms: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id: Scalars['Int']['output'];
  isArchived: Maybe<Scalars['Boolean']['output']>;
  isColored: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  isField: Maybe<Scalars['Boolean']['output']>;
  isTemplate: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskTemplateTaskIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportTaskIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportTemplateTaskIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskTemplateTaskIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskTemplateTaskIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportTaskIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportTaskIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportTemplateTaskIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportTemplateTaskIdAndCreatedByList: Array<MainUser>;
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Maybe<Scalars['Int']['output']>;
  priority: Maybe<Scalars['Int']['output']>;
  privilege: Maybe<Scalars['String']['output']>;
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  privilegeTeam: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `BlueprintTask`. */
  privilegedBlueprintTask: Array<BlueprintTask>;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  privilegedTaskLabels: Array<TaskLabel>;
  /** Reads and enables pagination through a set of `SubTask`. */
  privilegedTaskSubtasks: Array<SubTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportTaskIdAndTemplateTaskId: PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportTemplateTaskIdAndTaskId: PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
  /** Reads a single `Project` that is related to this `PrivilegedTask`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembersByAssigneeTaskIdAndProjectMemberIdList: Array<ProjectMember>;
  /** Reads a single `ProjectStage` that is related to this `PrivilegedTask`. */
  projectStage: Maybe<ProjectStage>;
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskTemplateTaskIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportTaskIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportTemplateTaskIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentTaskIdAndProjectId: PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentTaskIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedTaskIdAndProjectId: PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedTaskIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskTemplateTaskIdAndProjectId: PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskTemplateTaskIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportTaskIdAndProjectId: PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportTaskIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportTemplateTaskIdAndProjectId: PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportTemplateTaskIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedTaskIdAndReminderId: PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedTaskIdAndReminderIdList: Array<Reminder>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedTaskIdAndSmsActivityIdList: Array<SmsActivity>;
  startDate: Maybe<Scalars['Datetime']['output']>;
  startDateAllDay: Maybe<Scalars['Boolean']['output']>;
  status: Maybe<TaskStatus>;
  /** Reads a single `System` that is related to this `PrivilegedTask`. */
  system: Maybe<System>;
  systemId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByCommentTaskIdAndSystemId: PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedTaskIdAndSystemId: PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskTemplateTaskIdAndSystemId: PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByAssigneeTaskIdAndTaskId: PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskLabelTaskIdAndTaskId: PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskVisitTaskIdAndTaskId: PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
  taskStatus: Maybe<TaskStatusEntity>;
  /** Reads and enables pagination through a set of `TaskStatusTimeline`. */
  taskStatusTimelinesByTaskId: Array<TaskStatusTimeline>;
  /** Reads and enables pagination through a set of `TaskStatusTimeline`. */
  taskStatusTimelinesByTaskIdConnection: TaskStatusTimelinesConnection;
  /** Reads and enables pagination through a set of `TaskStatusUpdate`. */
  taskStatusUpdatesByTaskId: Array<TaskStatusUpdate>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskId: Array<TaskVisit>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskIdConnection: TaskVisitsConnection;
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  templatePropertyIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Reads a single `PrivilegedTask` that is related to this `PrivilegedTask`. */
  templateTask: Maybe<PrivilegedTask>;
  templateTaskId: Maybe<Scalars['Int']['output']>;
  title: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedTaskIdAndCreatedBy: PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyConnection;
  virtualProperties: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


export type PrivilegedTaskActionsByFeedTaskIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type PrivilegedTaskActionsByFeedTaskIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type PrivilegedTaskAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};


export type PrivilegedTaskCallsByFeedTaskIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type PrivilegedTaskCallsByFeedTaskIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type PrivilegedTaskCommentsByFeedTaskIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type PrivilegedTaskCommentsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type PrivilegedTaskCompaniesByPrivilegedTaskTemplateTaskIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type PrivilegedTaskCompaniesByTaskReportTaskIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type PrivilegedTaskCompaniesByTaskReportTemplateTaskIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type PrivilegedTaskEmailMessagesByFeedTaskIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type PrivilegedTaskFeedsByFeedTaskIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type PrivilegedTaskFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type PrivilegedTaskFilesByCommentTaskIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type PrivilegedTaskFilesByCommentTaskIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type PrivilegedTaskFilesByFeedTaskIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type PrivilegedTaskFilesByFeedTaskIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type PrivilegedTaskLabelsByPrivilegedTaskTemplateTaskIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type PrivilegedTaskLabelsByTaskReportTaskIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type PrivilegedTaskLabelsByTaskReportTemplateTaskIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type PrivilegedTaskMainUsersByPrivilegedTaskTemplateTaskIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskMainUsersByPrivilegedTaskTemplateTaskIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskMainUsersByTaskReportTaskIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskMainUsersByTaskReportTaskIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskMainUsersByTaskReportTemplateTaskIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskMainUsersByTaskReportTemplateTaskIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type PrivilegedTaskPrivilegedBlueprintTaskArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};


export type PrivilegedTaskPrivilegedTaskLabelsArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};


export type PrivilegedTaskPrivilegedTaskSubtasksArgs = {
  condition: InputMaybe<SubTaskCondition>;
  filter: InputMaybe<SubTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SubTasksOrderBy>>;
};


export type PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskPrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskProjectMembersByAssigneeTaskIdAndProjectMemberIdListArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type PrivilegedTaskProjectStagesByPrivilegedTaskTemplateTaskIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type PrivilegedTaskProjectStagesByTaskReportTaskIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type PrivilegedTaskProjectStagesByTaskReportTemplateTaskIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type PrivilegedTaskSmsActivitiesByFeedTaskIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type PrivilegedTaskSystemsByCommentTaskIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type PrivilegedTaskSystemsByFeedTaskIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type PrivilegedTaskTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type PrivilegedTaskTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type PrivilegedTaskTaskStatusTimelinesByTaskIdArgs = {
  condition: InputMaybe<TaskStatusTimelineCondition>;
  filter: InputMaybe<TaskStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type PrivilegedTaskTaskStatusTimelinesByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskStatusTimelineCondition>;
  filter: InputMaybe<TaskStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type PrivilegedTaskTaskStatusUpdatesByTaskIdArgs = {
  condition: InputMaybe<TaskStatusUpdateCondition>;
  filter: InputMaybe<TaskStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskStatusUpdatesOrderBy>>;
};


export type PrivilegedTaskTaskVisitsByTaskIdArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type PrivilegedTaskTaskVisitsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type PrivilegedTaskUsersByFeedTaskIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskActionsByFeedTaskIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type PrivilegedTaskAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<PrivilegedTaskArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<PrivilegedTaskAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<PrivilegedTaskDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<PrivilegedTaskMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<PrivilegedTaskMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<PrivilegedTaskStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<PrivilegedTaskStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<PrivilegedTaskSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<PrivilegedTaskVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<PrivilegedTaskVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `PrivilegedTask` object types. */
export type PrivilegedTaskAggregatesFilter = {
  /** Array aggregation aggregate over matching `PrivilegedTask` objects. */
  arrayAgg: InputMaybe<PrivilegedTaskArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `PrivilegedTask` objects. */
  average: InputMaybe<PrivilegedTaskAverageAggregateFilter>;
  /** Distinct count aggregate over matching `PrivilegedTask` objects. */
  distinctCount: InputMaybe<PrivilegedTaskDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `PrivilegedTask` object to be included within the aggregate. */
  filter: InputMaybe<PrivilegedTaskFilter>;
  /** Maximum aggregate over matching `PrivilegedTask` objects. */
  max: InputMaybe<PrivilegedTaskMaxAggregateFilter>;
  /** Minimum aggregate over matching `PrivilegedTask` objects. */
  min: InputMaybe<PrivilegedTaskMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `PrivilegedTask` objects. */
  stddevPopulation: InputMaybe<PrivilegedTaskStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `PrivilegedTask` objects. */
  stddevSample: InputMaybe<PrivilegedTaskStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `PrivilegedTask` objects. */
  sum: InputMaybe<PrivilegedTaskSumAggregateFilter>;
  /** Population variance aggregate over matching `PrivilegedTask` objects. */
  variancePopulation: InputMaybe<PrivilegedTaskVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `PrivilegedTask` objects. */
  varianceSample: InputMaybe<PrivilegedTaskVarianceSampleAggregateFilter>;
};

export type PrivilegedTaskArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  assigneeId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  endDateAllDay: InputMaybe<BooleanListFilter>;
  firstLabelId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isArchived: InputMaybe<BooleanListFilter>;
  isColored: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  isField: InputMaybe<BooleanListFilter>;
  isTemplate: InputMaybe<BooleanListFilter>;
  jobId: InputMaybe<StringListFilter>;
  nextVisitId: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  priority: InputMaybe<IntListFilter>;
  privilege: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
  privilegeTeam: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
  projectStageId: InputMaybe<IntListFilter>;
  startDateAllDay: InputMaybe<BooleanListFilter>;
  systemId: InputMaybe<IntListFilter>;
  templateAssigneePropertyId: InputMaybe<IntListFilter>;
  templateTaskId: InputMaybe<IntListFilter>;
  uid: InputMaybe<IntListFilter>;
};

export type PrivilegedTaskArrayAggAggregates = {
  /** Array aggregation of address across the matching connection */
  address: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of assigneeId across the matching connection */
  assigneeId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of endDateAllDay across the matching connection */
  endDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of firstLabelId across the matching connection */
  firstLabelId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isArchived across the matching connection */
  isArchived: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isColored across the matching connection */
  isColored: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isField across the matching connection */
  isField: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isTemplate across the matching connection */
  isTemplate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jobId across the matching connection */
  jobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of nextVisitId across the matching connection */
  nextVisitId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of priority across the matching connection */
  priority: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of privilege across the matching connection */
  privilege: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeTeam across the matching connection */
  privilegeTeam: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectStageId across the matching connection */
  projectStageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of startDateAllDay across the matching connection */
  startDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateTaskId across the matching connection */
  templateTaskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
};


export type PrivilegedTaskArrayAggAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type PrivilegedTaskAverageAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type PrivilegedTaskAverageAggregates = {
  /** Mean average of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type PrivilegedTaskAverageAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskCallsByFeedTaskIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskCommentsByFeedTaskIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/**
 * A condition to be used against `PrivilegedTask` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PrivilegedTaskCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completionDate` field. */
  completionDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `forms` field. */
  forms: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isColored` field. */
  isColored: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isField` field. */
  isField: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `privilege` field. */
  privilege: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectStageId` field. */
  projectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskContactsByFeedTaskIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type PrivilegedTaskDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  assigneeId: InputMaybe<BigIntFilter>;
  calculatedStatus: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionDate: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  endDate: InputMaybe<BigIntFilter>;
  endDateAllDay: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  forms: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isArchived: InputMaybe<BigIntFilter>;
  isColored: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  isField: InputMaybe<BigIntFilter>;
  isTemplate: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  modifiedAt: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  privilege: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  privilegeTeam: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  startDate: InputMaybe<BigIntFilter>;
  startDateAllDay: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templatePropertyIds: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  virtualProperties: InputMaybe<BigIntFilter>;
};

export type PrivilegedTaskDistinctCountAggregates = {
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calculatedStatus across the matching connection */
  calculatedStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionDate across the matching connection */
  completionDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDate across the matching connection */
  endDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDateAllDay across the matching connection */
  endDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forms across the matching connection */
  forms: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isArchived across the matching connection */
  isArchived: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isColored across the matching connection */
  isColored: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isField across the matching connection */
  isField: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilege across the matching connection */
  privilege: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeTeam across the matching connection */
  privilegeTeam: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDate across the matching connection */
  startDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDateAllDay across the matching connection */
  startDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templatePropertyIds across the matching connection */
  templatePropertyIds: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of virtualProperties across the matching connection */
  virtualProperties: Maybe<Scalars['BigInt']['output']>;
};


export type PrivilegedTaskDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesCalculatedStatusArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesFormsArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesTemplatePropertyIdsArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskDistinctCountAggregatesVirtualPropertiesArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskFeedsByFeedTaskIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Comment`. */
export type PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Comment`. */
export type PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskFilesByCommentTaskIdAndFileIdManyToManyEdgeCommentsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskFilesByFeedTaskIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<PrivilegedTaskFilter>>;
  /** Filter by the object’s `assignee` relation. */
  assignee: InputMaybe<MainUserFilter>;
  /** A related `assignee` exists. */
  assigneeExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId: InputMaybe<IntFilter>;
  /** Filter by the object’s `assigneesByTaskId` relation. */
  assigneesByTaskId: InputMaybe<PrivilegedTaskToManyAssigneeFilter>;
  /** Some related `assigneesByTaskId` exist. */
  assigneesByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `commentsByTaskId` relation. */
  commentsByTaskId: InputMaybe<PrivilegedTaskToManyCommentFilter>;
  /** Some related `commentsByTaskId` exist. */
  commentsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completionDate` field. */
  completionDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `feedsByTaskId` relation. */
  feedsByTaskId: InputMaybe<PrivilegedTaskToManyFeedFilter>;
  /** Some related `feedsByTaskId` exist. */
  feedsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstLabel` relation. */
  firstLabel: InputMaybe<LabelFilter>;
  /** A related `firstLabel` exists. */
  firstLabelExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<IntFilter>;
  /** Filter by the object’s `forms` field. */
  forms: InputMaybe<IntListFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isColored` field. */
  isColored: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isField` field. */
  isField: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<PrivilegedTaskFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<PrivilegedTaskFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `priority` field. */
  priority: InputMaybe<IntFilter>;
  /** Filter by the object’s `privilege` field. */
  privilege: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegedBlueprintTask` relation. */
  privilegedBlueprintTask: InputMaybe<PrivilegedTaskToManyBlueprintTaskFilter>;
  /** Some related `privilegedBlueprintTask` exist. */
  privilegedBlueprintTaskExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `privilegedTaskLabels` relation. */
  privilegedTaskLabels: InputMaybe<PrivilegedTaskToManyTaskLabelFilter>;
  /** Some related `privilegedTaskLabels` exist. */
  privilegedTaskLabelsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `privilegedTaskSubtasks` relation. */
  privilegedTaskSubtasks: InputMaybe<PrivilegedTaskToManySubTaskFilter>;
  /** Some related `privilegedTaskSubtasks` exist. */
  privilegedTaskSubtasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `privilegedTasksByTemplateTaskId` relation. */
  privilegedTasksByTemplateTaskId: InputMaybe<PrivilegedTaskToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksByTemplateTaskId` exist. */
  privilegedTasksByTemplateTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectStage` relation. */
  projectStage: InputMaybe<ProjectStageFilter>;
  /** A related `projectStage` exists. */
  projectStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageId` field. */
  projectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `system` relation. */
  system: InputMaybe<SystemFilter>;
  /** A related `system` exists. */
  systemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskReportsByTaskId` relation. */
  taskReportsByTaskId: InputMaybe<PrivilegedTaskToManyTaskReportFilter>;
  /** Some related `taskReportsByTaskId` exist. */
  taskReportsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskReportsByTemplateTaskId` relation. */
  taskReportsByTemplateTaskId: InputMaybe<PrivilegedTaskToManyTaskReportFilter>;
  /** Some related `taskReportsByTemplateTaskId` exist. */
  taskReportsByTemplateTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskStatusTimelinesByTaskId` relation. */
  taskStatusTimelinesByTaskId: InputMaybe<PrivilegedTaskToManyTaskStatusTimelineFilter>;
  /** Some related `taskStatusTimelinesByTaskId` exist. */
  taskStatusTimelinesByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskStatusUpdatesByTaskId` relation. */
  taskStatusUpdatesByTaskId: InputMaybe<PrivilegedTaskToManyTaskStatusUpdateFilter>;
  /** Some related `taskStatusUpdatesByTaskId` exist. */
  taskStatusUpdatesByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskVisitsByTaskId` relation. */
  taskVisitsByTaskId: InputMaybe<PrivilegedTaskToManyTaskVisitFilter>;
  /** Some related `taskVisitsByTaskId` exist. */
  taskVisitsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<IntListFilter>;
  /** Filter by the object’s `templateTask` relation. */
  templateTask: InputMaybe<PrivilegedTaskFilter>;
  /** A related `templateTask` exists. */
  templateTaskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<StringListFilter>;
};

/** Grouping methods for `PrivilegedTask` for usage during aggregation. */
export enum PrivilegedTaskGroupBy {
  Address = 'ADDRESS',
  AssigneeId = 'ASSIGNEE_ID',
  CalculatedStatus = 'CALCULATED_STATUS',
  CompanyId = 'COMPANY_ID',
  CompletionDate = 'COMPLETION_DATE',
  CompletionDateTruncatedToDay = 'COMPLETION_DATE_TRUNCATED_TO_DAY',
  CompletionDateTruncatedToHour = 'COMPLETION_DATE_TRUNCATED_TO_HOUR',
  CompletionDateTruncatedToMonth = 'COMPLETION_DATE_TRUNCATED_TO_MONTH',
  CompletionDateTruncatedToQuarter = 'COMPLETION_DATE_TRUNCATED_TO_QUARTER',
  CompletionDateTruncatedToWeek = 'COMPLETION_DATE_TRUNCATED_TO_WEEK',
  CompletionDateTruncatedToYear = 'COMPLETION_DATE_TRUNCATED_TO_YEAR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  EndDate = 'END_DATE',
  EndDateAllDay = 'END_DATE_ALL_DAY',
  EndDateTruncatedToDay = 'END_DATE_TRUNCATED_TO_DAY',
  EndDateTruncatedToHour = 'END_DATE_TRUNCATED_TO_HOUR',
  EndDateTruncatedToMonth = 'END_DATE_TRUNCATED_TO_MONTH',
  EndDateTruncatedToQuarter = 'END_DATE_TRUNCATED_TO_QUARTER',
  EndDateTruncatedToWeek = 'END_DATE_TRUNCATED_TO_WEEK',
  EndDateTruncatedToYear = 'END_DATE_TRUNCATED_TO_YEAR',
  FirstLabelId = 'FIRST_LABEL_ID',
  Forms = 'FORMS',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IsArchived = 'IS_ARCHIVED',
  IsColored = 'IS_COLORED',
  IsCompleted = 'IS_COMPLETED',
  IsField = 'IS_FIELD',
  IsTemplate = 'IS_TEMPLATE',
  JobId = 'JOB_ID',
  ModifiedAt = 'MODIFIED_AT',
  ModifiedAtTruncatedToDay = 'MODIFIED_AT_TRUNCATED_TO_DAY',
  ModifiedAtTruncatedToHour = 'MODIFIED_AT_TRUNCATED_TO_HOUR',
  ModifiedAtTruncatedToMonth = 'MODIFIED_AT_TRUNCATED_TO_MONTH',
  ModifiedAtTruncatedToQuarter = 'MODIFIED_AT_TRUNCATED_TO_QUARTER',
  ModifiedAtTruncatedToWeek = 'MODIFIED_AT_TRUNCATED_TO_WEEK',
  ModifiedAtTruncatedToYear = 'MODIFIED_AT_TRUNCATED_TO_YEAR',
  NextVisitId = 'NEXT_VISIT_ID',
  Position = 'POSITION',
  Priority = 'PRIORITY',
  Privilege = 'PRIVILEGE',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  PrivilegeTeam = 'PRIVILEGE_TEAM',
  ProjectId = 'PROJECT_ID',
  ProjectStageId = 'PROJECT_STAGE_ID',
  StartDate = 'START_DATE',
  StartDateAllDay = 'START_DATE_ALL_DAY',
  StartDateTruncatedToDay = 'START_DATE_TRUNCATED_TO_DAY',
  StartDateTruncatedToHour = 'START_DATE_TRUNCATED_TO_HOUR',
  StartDateTruncatedToMonth = 'START_DATE_TRUNCATED_TO_MONTH',
  StartDateTruncatedToQuarter = 'START_DATE_TRUNCATED_TO_QUARTER',
  StartDateTruncatedToWeek = 'START_DATE_TRUNCATED_TO_WEEK',
  StartDateTruncatedToYear = 'START_DATE_TRUNCATED_TO_YEAR',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  TemplateAssigneePropertyId = 'TEMPLATE_ASSIGNEE_PROPERTY_ID',
  TemplatePropertyIds = 'TEMPLATE_PROPERTY_IDS',
  TemplateTaskId = 'TEMPLATE_TASK_ID',
  Title = 'TITLE',
  Uid = 'UID',
  VirtualProperties = 'VIRTUAL_PROPERTIES'
}

export type PrivilegedTaskHavingArrayAggInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingAverageInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingDistinctCountInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

/** Conditions for `PrivilegedTask` aggregates. */
export type PrivilegedTaskHavingInput = {
  AND: InputMaybe<Array<PrivilegedTaskHavingInput>>;
  OR: InputMaybe<Array<PrivilegedTaskHavingInput>>;
  arrayAgg: InputMaybe<PrivilegedTaskHavingArrayAggInput>;
  average: InputMaybe<PrivilegedTaskHavingAverageInput>;
  distinctCount: InputMaybe<PrivilegedTaskHavingDistinctCountInput>;
  max: InputMaybe<PrivilegedTaskHavingMaxInput>;
  min: InputMaybe<PrivilegedTaskHavingMinInput>;
  stddevPopulation: InputMaybe<PrivilegedTaskHavingStddevPopulationInput>;
  stddevSample: InputMaybe<PrivilegedTaskHavingStddevSampleInput>;
  sum: InputMaybe<PrivilegedTaskHavingSumInput>;
  variancePopulation: InputMaybe<PrivilegedTaskHavingVariancePopulationInput>;
  varianceSample: InputMaybe<PrivilegedTaskHavingVarianceSampleInput>;
};

export type PrivilegedTaskHavingMaxInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingMinInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingStddevPopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingStddevSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingSumInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingVariancePopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskHavingVarianceSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type PrivilegedTaskMaxAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
};

export type PrivilegedTaskMaxAggregates = {
  /** Maximum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
};


export type PrivilegedTaskMaxAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type PrivilegedTaskMinAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
};

export type PrivilegedTaskMinAggregates = {
  /** Minimum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
};


export type PrivilegedTaskMinAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTaskIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskPrivilegedTasksByTaskReportTemplateTaskIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Comment`. */
export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Comment`. */
export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByProjectIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskProjectsByCommentTaskIdAndProjectIdManyToManyEdgeCommentsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskProjectsByFeedTaskIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type PrivilegedTaskProjectsByPrivilegedTaskTemplateTaskIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTaskIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type PrivilegedTaskProjectsByTaskReportTemplateTaskIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskRemindersByFeedTaskIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type PrivilegedTaskStddevPopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type PrivilegedTaskStddevPopulationAggregates = {
  /** Population standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type PrivilegedTaskStddevPopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type PrivilegedTaskStddevSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type PrivilegedTaskStddevSampleAggregates = {
  /** Sample standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type PrivilegedTaskStddevSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type PrivilegedTaskSumAggregateFilter = {
  assigneeId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
};

export type PrivilegedTaskSumAggregates = {
  /** Sum of assigneeId across the matching connection */
  assigneeId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of firstLabelId across the matching connection */
  firstLabelId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of nextVisitId across the matching connection */
  nextVisitId: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
  /** Sum of priority across the matching connection */
  priority: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of projectStageId across the matching connection */
  projectStageId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
  /** Sum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Scalars['BigInt']['output'];
  /** Sum of templateTaskId across the matching connection */
  templateTaskId: Scalars['BigInt']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
};


export type PrivilegedTaskSumAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Comment`. */
export type PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Comment`. */
export type PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsBySystemIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Comment`. */
export type PrivilegedTaskSystemsByCommentTaskIdAndSystemIdManyToManyEdgeCommentsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskSystemsByFeedTaskIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type PrivilegedTaskSystemsByPrivilegedTaskTemplateTaskIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `TaskReport` values, with data from `Assignee`. */
export type PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskReportAggregates>;
  /** A list of edges which contains the `TaskReport`, info from the `Assignee`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskReportAggregates>>;
  /** A list of `TaskReport` objects. */
  nodes: Array<Maybe<TaskReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskReport` values, with data from `Assignee`. */
export type PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskReportGroupBy>;
  having: InputMaybe<TaskReportHavingInput>;
};

/** A `TaskReport` edge in the connection, with data from `Assignee`. */
export type PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskReport` at the end of the edge. */
  node: Maybe<TaskReport>;
};


/** A `TaskReport` edge in the connection, with data from `Assignee`. */
export type PrivilegedTaskTaskReportsByAssigneeTaskIdAndTaskIdManyToManyEdgeAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};

/** A connection to a list of `TaskReport` values, with data from `TaskLabel`. */
export type PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskReportAggregates>;
  /** A list of edges which contains the `TaskReport`, info from the `TaskLabel`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskReportAggregates>>;
  /** A list of `TaskReport` objects. */
  nodes: Array<Maybe<TaskReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskReport` values, with data from `TaskLabel`. */
export type PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskReportGroupBy>;
  having: InputMaybe<TaskReportHavingInput>;
};

/** A `TaskReport` edge in the connection, with data from `TaskLabel`. */
export type PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskReport` at the end of the edge. */
  node: Maybe<TaskReport>;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  taskLabelsByTaskId: Array<TaskLabel>;
};


/** A `TaskReport` edge in the connection, with data from `TaskLabel`. */
export type PrivilegedTaskTaskReportsByTaskLabelTaskIdAndTaskIdManyToManyEdgeTaskLabelsByTaskIdArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};

/** A connection to a list of `TaskReport` values, with data from `TaskVisit`. */
export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskReportAggregates>;
  /** A list of edges which contains the `TaskReport`, info from the `TaskVisit`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskReportAggregates>>;
  /** A list of `TaskReport` objects. */
  nodes: Array<Maybe<TaskReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskReport` values, with data from `TaskVisit`. */
export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskReportGroupBy>;
  having: InputMaybe<TaskReportHavingInput>;
};

/** A `TaskReport` edge in the connection, with data from `TaskVisit`. */
export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskReport` at the end of the edge. */
  node: Maybe<TaskReport>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskId: Array<TaskVisit>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskIdConnection: TaskVisitsConnection;
};


/** A `TaskReport` edge in the connection, with data from `TaskVisit`. */
export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyEdgeTaskVisitsByTaskIdArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


/** A `TaskReport` edge in the connection, with data from `TaskVisit`. */
export type PrivilegedTaskTaskReportsByTaskVisitTaskIdAndTaskIdManyToManyEdgeTaskVisitsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};

/** A filter to be used against many `Assignee` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyAssigneeFilter = {
  /** Aggregates across related `Assignee` match the filter criteria. */
  aggregates: InputMaybe<AssigneeAggregatesFilter>;
  /** Every related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AssigneeFilter>;
  /** No related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AssigneeFilter>;
  /** Some related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AssigneeFilter>;
};

/** A filter to be used against many `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyBlueprintTaskFilter = {
  /** Aggregates across related `BlueprintTask` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTaskAggregatesFilter>;
  /** Every related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTaskFilter>;
  /** No related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTaskFilter>;
  /** Some related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTaskFilter>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `SubTask` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManySubTaskFilter = {
  /** Aggregates across related `SubTask` match the filter criteria. */
  aggregates: InputMaybe<SubTaskAggregatesFilter>;
  /** Every related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SubTaskFilter>;
  /** No related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SubTaskFilter>;
  /** Some related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SubTaskFilter>;
};

/** A filter to be used against many `TaskLabel` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyTaskLabelFilter = {
  /** Aggregates across related `TaskLabel` match the filter criteria. */
  aggregates: InputMaybe<TaskLabelAggregatesFilter>;
  /** Every related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskLabelFilter>;
  /** No related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskLabelFilter>;
  /** Some related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskLabelFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

/** A filter to be used against many `TaskStatusTimeline` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyTaskStatusTimelineFilter = {
  /** Aggregates across related `TaskStatusTimeline` match the filter criteria. */
  aggregates: InputMaybe<TaskStatusTimelineAggregatesFilter>;
  /** Every related `TaskStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskStatusTimelineFilter>;
  /** No related `TaskStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskStatusTimelineFilter>;
  /** Some related `TaskStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskStatusTimelineFilter>;
};

/** A filter to be used against many `TaskStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyTaskStatusUpdateFilter = {
  /** Aggregates across related `TaskStatusUpdate` match the filter criteria. */
  aggregates: InputMaybe<TaskStatusUpdateAggregatesFilter>;
  /** Every related `TaskStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskStatusUpdateFilter>;
  /** No related `TaskStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskStatusUpdateFilter>;
  /** Some related `TaskStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskStatusUpdateFilter>;
};

/** A filter to be used against many `TaskVisit` object types. All fields are combined with a logical ‘and.’ */
export type PrivilegedTaskToManyTaskVisitFilter = {
  /** Aggregates across related `TaskVisit` match the filter criteria. */
  aggregates: InputMaybe<TaskVisitAggregatesFilter>;
  /** Every related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskVisitFilter>;
  /** No related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskVisitFilter>;
  /** Some related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskVisitFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type PrivilegedTaskUsersByFeedTaskIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type PrivilegedTaskVariancePopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type PrivilegedTaskVariancePopulationAggregates = {
  /** Population variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type PrivilegedTaskVariancePopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type PrivilegedTaskVarianceSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type PrivilegedTaskVarianceSampleAggregates = {
  /** Sample variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type PrivilegedTaskVarianceSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type PrivilegedTaskVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values. */
export type PrivilegedTasksConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask` and cursor to aid in pagination. */
  edges: Array<PrivilegedTasksEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values. */
export type PrivilegedTasksConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection. */
export type PrivilegedTasksEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};

/** Methods to use when ordering `PrivilegedTask`. */
export enum PrivilegedTasksOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AssigneesByTaskIdConnectionArrayAggProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionArrayAggTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  AssigneesByTaskIdConnectionArrayAggUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  AssigneesByTaskIdConnectionAverageProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionAverageProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionAverageTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionAverageTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionAverageUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  AssigneesByTaskIdConnectionAverageUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  AssigneesByTaskIdConnectionCountAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_COUNT_ASC',
  AssigneesByTaskIdConnectionCountDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_COUNT_DESC',
  AssigneesByTaskIdConnectionDistinctCountProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionDistinctCountTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  AssigneesByTaskIdConnectionDistinctCountUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  AssigneesByTaskIdConnectionMaxProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionMaxProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionMaxTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  AssigneesByTaskIdConnectionMaxTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  AssigneesByTaskIdConnectionMaxUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_USER_ID_ASC',
  AssigneesByTaskIdConnectionMaxUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_USER_ID_DESC',
  AssigneesByTaskIdConnectionMinProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionMinProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionMinTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  AssigneesByTaskIdConnectionMinTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  AssigneesByTaskIdConnectionMinUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_USER_ID_ASC',
  AssigneesByTaskIdConnectionMinUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_USER_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  AssigneesByTaskIdConnectionSumProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionSumProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionSumTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  AssigneesByTaskIdConnectionSumTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  AssigneesByTaskIdConnectionSumUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_USER_ID_ASC',
  AssigneesByTaskIdConnectionSumUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_USER_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  AssigneeIdAsc = 'ASSIGNEE_ID_ASC',
  AssigneeIdDesc = 'ASSIGNEE_ID_DESC',
  CalculatedStatusAsc = 'CALCULATED_STATUS_ASC',
  CalculatedStatusDesc = 'CALCULATED_STATUS_DESC',
  CommentsByTaskIdConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionArrayAggCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsByTaskIdConnectionArrayAggCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsByTaskIdConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsByTaskIdConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsByTaskIdConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsByTaskIdConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsByTaskIdConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionArrayAggFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsByTaskIdConnectionArrayAggFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsByTaskIdConnectionArrayAggIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsByTaskIdConnectionArrayAggIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsByTaskIdConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsByTaskIdConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsByTaskIdConnectionArrayAggParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsByTaskIdConnectionArrayAggParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsByTaskIdConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsByTaskIdConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsByTaskIdConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionArrayAggScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsByTaskIdConnectionArrayAggScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsByTaskIdConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsByTaskIdConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsByTaskIdConnectionArrayAggTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsByTaskIdConnectionArrayAggTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsByTaskIdConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionArrayAggXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsByTaskIdConnectionArrayAggXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsByTaskIdConnectionArrayAggYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsByTaskIdConnectionArrayAggYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsByTaskIdConnectionAverageAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionAverageAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionAverageCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsByTaskIdConnectionAverageCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsByTaskIdConnectionAverageCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionAverageCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionAverageCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsByTaskIdConnectionAverageCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsByTaskIdConnectionAverageCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsByTaskIdConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionAverageCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsByTaskIdConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionAverageFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsByTaskIdConnectionAverageFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsByTaskIdConnectionAverageIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  CommentsByTaskIdConnectionAverageIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  CommentsByTaskIdConnectionAverageIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsByTaskIdConnectionAverageIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsByTaskIdConnectionAverageParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsByTaskIdConnectionAverageParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsByTaskIdConnectionAveragePinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsByTaskIdConnectionAveragePinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsByTaskIdConnectionAveragePreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionAveragePreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionAverageProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionAverageProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionAverageScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsByTaskIdConnectionAverageScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsByTaskIdConnectionAverageSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionAverageSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionAverageTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsByTaskIdConnectionAverageTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsByTaskIdConnectionAverageTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsByTaskIdConnectionAverageTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsByTaskIdConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionAverageXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_X_ASC',
  CommentsByTaskIdConnectionAverageXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_X_DESC',
  CommentsByTaskIdConnectionAverageYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_Y_ASC',
  CommentsByTaskIdConnectionAverageYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_AVERAGE_Y_DESC',
  CommentsByTaskIdConnectionCountAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  CommentsByTaskIdConnectionCountDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  CommentsByTaskIdConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsByTaskIdConnectionDistinctCountCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsByTaskIdConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsByTaskIdConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsByTaskIdConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsByTaskIdConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsByTaskIdConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsByTaskIdConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsByTaskIdConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsByTaskIdConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsByTaskIdConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsByTaskIdConnectionDistinctCountScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsByTaskIdConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsByTaskIdConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsByTaskIdConnectionDistinctCountTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsByTaskIdConnectionDistinctCountTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsByTaskIdConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionDistinctCountXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsByTaskIdConnectionDistinctCountXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsByTaskIdConnectionDistinctCountYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsByTaskIdConnectionDistinctCountYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsByTaskIdConnectionMaxAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionMaxAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionMaxCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_COMMENT_ASC',
  CommentsByTaskIdConnectionMaxCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_COMMENT_DESC',
  CommentsByTaskIdConnectionMaxCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionMaxCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionMaxCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsByTaskIdConnectionMaxCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsByTaskIdConnectionMaxCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsByTaskIdConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionMaxCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsByTaskIdConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionMaxFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_FILE_ID_ASC',
  CommentsByTaskIdConnectionMaxFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_FILE_ID_DESC',
  CommentsByTaskIdConnectionMaxIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  CommentsByTaskIdConnectionMaxIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  CommentsByTaskIdConnectionMaxIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsByTaskIdConnectionMaxIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsByTaskIdConnectionMaxParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsByTaskIdConnectionMaxParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsByTaskIdConnectionMaxPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsByTaskIdConnectionMaxPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsByTaskIdConnectionMaxPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionMaxPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionMaxProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionMaxProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionMaxScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_SCOPE_ASC',
  CommentsByTaskIdConnectionMaxScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_SCOPE_DESC',
  CommentsByTaskIdConnectionMaxSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionMaxSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionMaxTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  CommentsByTaskIdConnectionMaxTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  CommentsByTaskIdConnectionMaxTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_TYPE_ASC',
  CommentsByTaskIdConnectionMaxTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_TYPE_DESC',
  CommentsByTaskIdConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionMaxXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_X_ASC',
  CommentsByTaskIdConnectionMaxXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_X_DESC',
  CommentsByTaskIdConnectionMaxYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_Y_ASC',
  CommentsByTaskIdConnectionMaxYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MAX_Y_DESC',
  CommentsByTaskIdConnectionMinAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionMinAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionMinAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionMinAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionMinCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_COMMENT_ASC',
  CommentsByTaskIdConnectionMinCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_COMMENT_DESC',
  CommentsByTaskIdConnectionMinCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionMinCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionMinCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsByTaskIdConnectionMinCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsByTaskIdConnectionMinCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsByTaskIdConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionMinCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsByTaskIdConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionMinFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_FILE_ID_ASC',
  CommentsByTaskIdConnectionMinFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_FILE_ID_DESC',
  CommentsByTaskIdConnectionMinIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  CommentsByTaskIdConnectionMinIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  CommentsByTaskIdConnectionMinIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsByTaskIdConnectionMinIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsByTaskIdConnectionMinParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsByTaskIdConnectionMinParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsByTaskIdConnectionMinPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsByTaskIdConnectionMinPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsByTaskIdConnectionMinPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionMinPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionMinProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionMinProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionMinScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_SCOPE_ASC',
  CommentsByTaskIdConnectionMinScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_SCOPE_DESC',
  CommentsByTaskIdConnectionMinSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionMinSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionMinTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  CommentsByTaskIdConnectionMinTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  CommentsByTaskIdConnectionMinTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_TYPE_ASC',
  CommentsByTaskIdConnectionMinTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_TYPE_DESC',
  CommentsByTaskIdConnectionMinUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionMinUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionMinXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_X_ASC',
  CommentsByTaskIdConnectionMinXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_X_DESC',
  CommentsByTaskIdConnectionMinYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_Y_ASC',
  CommentsByTaskIdConnectionMinYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_MIN_Y_DESC',
  CommentsByTaskIdConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsByTaskIdConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsByTaskIdConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsByTaskIdConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsByTaskIdConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsByTaskIdConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsByTaskIdConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsByTaskIdConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsByTaskIdConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsByTaskIdConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsByTaskIdConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsByTaskIdConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsByTaskIdConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsByTaskIdConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionStddevPopulationXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsByTaskIdConnectionStddevPopulationXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsByTaskIdConnectionStddevPopulationYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsByTaskIdConnectionStddevPopulationYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsByTaskIdConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsByTaskIdConnectionStddevSampleCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsByTaskIdConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsByTaskIdConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsByTaskIdConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsByTaskIdConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsByTaskIdConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsByTaskIdConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsByTaskIdConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsByTaskIdConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsByTaskIdConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsByTaskIdConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsByTaskIdConnectionStddevSampleScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsByTaskIdConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsByTaskIdConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsByTaskIdConnectionStddevSampleTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsByTaskIdConnectionStddevSampleTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsByTaskIdConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionStddevSampleXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsByTaskIdConnectionStddevSampleXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsByTaskIdConnectionStddevSampleYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsByTaskIdConnectionStddevSampleYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsByTaskIdConnectionSumAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionSumAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionSumAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionSumAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionSumCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_COMMENT_ASC',
  CommentsByTaskIdConnectionSumCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_COMMENT_DESC',
  CommentsByTaskIdConnectionSumCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionSumCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionSumCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsByTaskIdConnectionSumCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsByTaskIdConnectionSumCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsByTaskIdConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionSumCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsByTaskIdConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionSumFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_FILE_ID_ASC',
  CommentsByTaskIdConnectionSumFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_FILE_ID_DESC',
  CommentsByTaskIdConnectionSumIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  CommentsByTaskIdConnectionSumIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  CommentsByTaskIdConnectionSumIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsByTaskIdConnectionSumIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsByTaskIdConnectionSumParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsByTaskIdConnectionSumParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsByTaskIdConnectionSumPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsByTaskIdConnectionSumPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsByTaskIdConnectionSumPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionSumPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionSumProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionSumProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionSumScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_SCOPE_ASC',
  CommentsByTaskIdConnectionSumScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_SCOPE_DESC',
  CommentsByTaskIdConnectionSumSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionSumSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionSumTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  CommentsByTaskIdConnectionSumTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  CommentsByTaskIdConnectionSumTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_TYPE_ASC',
  CommentsByTaskIdConnectionSumTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_TYPE_DESC',
  CommentsByTaskIdConnectionSumUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionSumUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionSumXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_X_ASC',
  CommentsByTaskIdConnectionSumXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_X_DESC',
  CommentsByTaskIdConnectionSumYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_Y_ASC',
  CommentsByTaskIdConnectionSumYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_SUM_Y_DESC',
  CommentsByTaskIdConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsByTaskIdConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsByTaskIdConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsByTaskIdConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsByTaskIdConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsByTaskIdConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsByTaskIdConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsByTaskIdConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsByTaskIdConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsByTaskIdConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsByTaskIdConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsByTaskIdConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsByTaskIdConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsByTaskIdConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionVariancePopulationXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsByTaskIdConnectionVariancePopulationXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsByTaskIdConnectionVariancePopulationYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsByTaskIdConnectionVariancePopulationYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsByTaskIdConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsByTaskIdConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsByTaskIdConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsByTaskIdConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsByTaskIdConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsByTaskIdConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByTaskIdConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByTaskIdConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsByTaskIdConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsByTaskIdConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsByTaskIdConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsByTaskIdConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsByTaskIdConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByTaskIdConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsByTaskIdConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsByTaskIdConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsByTaskIdConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsByTaskIdConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsByTaskIdConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsByTaskIdConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsByTaskIdConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByTaskIdConnectionVarianceSampleXAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsByTaskIdConnectionVarianceSampleXDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsByTaskIdConnectionVarianceSampleYAsc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsByTaskIdConnectionVarianceSampleYDesc = 'COMMENTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletionDateAsc = 'COMPLETION_DATE_ASC',
  CompletionDateDesc = 'COMPLETION_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndDateAllDayAsc = 'END_DATE_ALL_DAY_ASC',
  EndDateAllDayDesc = 'END_DATE_ALL_DAY_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  FeedsByTaskIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByTaskIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByTaskIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByTaskIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByTaskIdConnectionArrayAggCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByTaskIdConnectionArrayAggCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByTaskIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByTaskIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByTaskIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByTaskIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByTaskIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionArrayAggEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByTaskIdConnectionArrayAggEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByTaskIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByTaskIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByTaskIdConnectionArrayAggIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByTaskIdConnectionArrayAggIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByTaskIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByTaskIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByTaskIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByTaskIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByTaskIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByTaskIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByTaskIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByTaskIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByTaskIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionAverageActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByTaskIdConnectionAverageActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByTaskIdConnectionAverageCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByTaskIdConnectionAverageCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByTaskIdConnectionAverageCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByTaskIdConnectionAverageCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByTaskIdConnectionAverageCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionAverageCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByTaskIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByTaskIdConnectionAverageCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByTaskIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionAverageCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByTaskIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionAverageEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByTaskIdConnectionAverageEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByTaskIdConnectionAverageEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionAverageEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionAverageFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByTaskIdConnectionAverageFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByTaskIdConnectionAverageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsByTaskIdConnectionAverageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsByTaskIdConnectionAverageParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByTaskIdConnectionAverageParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByTaskIdConnectionAveragePayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByTaskIdConnectionAveragePayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByTaskIdConnectionAverageProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionAverageProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionAverageReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionAverageReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionAverageRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByTaskIdConnectionAverageRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByTaskIdConnectionAverageRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionAverageRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionAverageSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionAverageSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionAverageSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionAverageSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionAverageTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByTaskIdConnectionAverageTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByTaskIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  FeedsByTaskIdConnectionCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  FeedsByTaskIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByTaskIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByTaskIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByTaskIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByTaskIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByTaskIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByTaskIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByTaskIdConnectionDistinctCountEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByTaskIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByTaskIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByTaskIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByTaskIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByTaskIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByTaskIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionMaxActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByTaskIdConnectionMaxActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByTaskIdConnectionMaxCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByTaskIdConnectionMaxCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByTaskIdConnectionMaxCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsByTaskIdConnectionMaxCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsByTaskIdConnectionMaxCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionMaxCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByTaskIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByTaskIdConnectionMaxCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByTaskIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionMaxCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByTaskIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionMaxEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsByTaskIdConnectionMaxEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsByTaskIdConnectionMaxEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionMaxEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionMaxFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByTaskIdConnectionMaxFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByTaskIdConnectionMaxIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  FeedsByTaskIdConnectionMaxIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  FeedsByTaskIdConnectionMaxParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByTaskIdConnectionMaxParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByTaskIdConnectionMaxPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByTaskIdConnectionMaxPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByTaskIdConnectionMaxProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionMaxProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionMaxReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionMaxReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionMaxRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByTaskIdConnectionMaxRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByTaskIdConnectionMaxRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionMaxRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionMaxSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionMaxSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionMaxSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionMaxSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionMaxTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByTaskIdConnectionMaxTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByTaskIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionMinActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByTaskIdConnectionMinActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByTaskIdConnectionMinCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByTaskIdConnectionMinCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByTaskIdConnectionMinCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsByTaskIdConnectionMinCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsByTaskIdConnectionMinCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionMinCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionMinCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionMinCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionMinCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByTaskIdConnectionMinCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByTaskIdConnectionMinCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByTaskIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionMinCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByTaskIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionMinEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsByTaskIdConnectionMinEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsByTaskIdConnectionMinEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionMinEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionMinFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByTaskIdConnectionMinFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByTaskIdConnectionMinIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  FeedsByTaskIdConnectionMinIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  FeedsByTaskIdConnectionMinParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByTaskIdConnectionMinParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByTaskIdConnectionMinPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByTaskIdConnectionMinPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByTaskIdConnectionMinProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionMinProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionMinReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionMinReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionMinRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionMinRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionMinRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByTaskIdConnectionMinRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByTaskIdConnectionMinRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionMinRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionMinSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionMinSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionMinSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionMinSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionMinTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByTaskIdConnectionMinTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByTaskIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByTaskIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByTaskIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByTaskIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByTaskIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByTaskIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByTaskIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByTaskIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByTaskIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByTaskIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByTaskIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByTaskIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByTaskIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByTaskIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByTaskIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByTaskIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByTaskIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByTaskIdConnectionStddevSampleEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByTaskIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByTaskIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByTaskIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByTaskIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByTaskIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByTaskIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByTaskIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionSumActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByTaskIdConnectionSumActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByTaskIdConnectionSumCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByTaskIdConnectionSumCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByTaskIdConnectionSumCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsByTaskIdConnectionSumCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsByTaskIdConnectionSumCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionSumCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionSumCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionSumCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionSumCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByTaskIdConnectionSumCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByTaskIdConnectionSumCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByTaskIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionSumCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByTaskIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionSumEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsByTaskIdConnectionSumEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsByTaskIdConnectionSumEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionSumEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionSumFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByTaskIdConnectionSumFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByTaskIdConnectionSumIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  FeedsByTaskIdConnectionSumIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  FeedsByTaskIdConnectionSumParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByTaskIdConnectionSumParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByTaskIdConnectionSumPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByTaskIdConnectionSumPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByTaskIdConnectionSumProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionSumProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionSumReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionSumReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionSumRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionSumRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionSumRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByTaskIdConnectionSumRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByTaskIdConnectionSumRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionSumRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionSumSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionSumSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionSumSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionSumSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionSumTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByTaskIdConnectionSumTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByTaskIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByTaskIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByTaskIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByTaskIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByTaskIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByTaskIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByTaskIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByTaskIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByTaskIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByTaskIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByTaskIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByTaskIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByTaskIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByTaskIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByTaskIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByTaskIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByTaskIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByTaskIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByTaskIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByTaskIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByTaskIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByTaskIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByTaskIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByTaskIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByTaskIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByTaskIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByTaskIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByTaskIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByTaskIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByTaskIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByTaskIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByTaskIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByTaskIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByTaskIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByTaskIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FirstLabelIdAsc = 'FIRST_LABEL_ID_ASC',
  FirstLabelIdDesc = 'FIRST_LABEL_ID_DESC',
  FormsAsc = 'FORMS_ASC',
  FormsDesc = 'FORMS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsColoredAsc = 'IS_COLORED_ASC',
  IsColoredDesc = 'IS_COLORED_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsFieldAsc = 'IS_FIELD_ASC',
  IsFieldDesc = 'IS_FIELD_DESC',
  IsTemplateAsc = 'IS_TEMPLATE_ASC',
  IsTemplateDesc = 'IS_TEMPLATE_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  Natural = 'NATURAL',
  NextVisitIdAsc = 'NEXT_VISIT_ID_ASC',
  NextVisitIdDesc = 'NEXT_VISIT_ID_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionArrayAggUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionArrayAggUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionAverageBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionAverageBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionAverageCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionAverageCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionAverageCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionAverageCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionAverageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionAverageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionAverageSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionAverageSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionAverageTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionAverageTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionAverageUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionAverageUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionCountAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_COUNT_ASC',
  PrivilegedBlueprintTaskConnectionCountDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_COUNT_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionDistinctCountUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionDistinctCountUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionMaxBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionMaxBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionMaxCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionMaxCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionMaxCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionMaxCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionMaxIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_ID_ASC',
  PrivilegedBlueprintTaskConnectionMaxIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_ID_DESC',
  PrivilegedBlueprintTaskConnectionMaxSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionMaxSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionMaxTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionMaxTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionMaxUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionMaxUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MAX_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionMinBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionMinBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionMinCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionMinCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionMinCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionMinCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionMinIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_ID_ASC',
  PrivilegedBlueprintTaskConnectionMinIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_ID_DESC',
  PrivilegedBlueprintTaskConnectionMinSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionMinSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionMinTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionMinTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionMinUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionMinUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_MIN_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevPopulationUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionStddevPopulationUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionStddevSampleUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionStddevSampleUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionSumBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionSumBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionSumCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionSumCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionSumCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionSumCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionSumIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_ID_ASC',
  PrivilegedBlueprintTaskConnectionSumIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_ID_DESC',
  PrivilegedBlueprintTaskConnectionSumSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionSumSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionSumTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionSumTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionSumUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionSumUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_SUM_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionVariancePopulationUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionVariancePopulationUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleBlueprintProjectStageIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleBlueprintProjectStageIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleSliceIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleSliceIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleTaskIdAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleTaskIdDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  PrivilegedBlueprintTaskConnectionVarianceSampleUpdatedAtAsc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PrivilegedBlueprintTaskConnectionVarianceSampleUpdatedAtDesc = 'PRIVILEGED_BLUEPRINT_TASK_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionCountAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_COUNT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionCountDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_COUNT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByTemplateTaskIdConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTaskLabelsConnectionArrayAggLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionArrayAggLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionArrayAggTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionArrayAggTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionAverageLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionAverageLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionAverageTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionAverageTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionCountAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_COUNT_ASC',
  PrivilegedTaskLabelsConnectionCountDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_COUNT_DESC',
  PrivilegedTaskLabelsConnectionDistinctCountLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionDistinctCountLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionDistinctCountTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionDistinctCountTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionMaxLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MAX_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionMaxLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MAX_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionMaxTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MAX_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionMaxTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MAX_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionMinLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MIN_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionMinLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MIN_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionMinTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MIN_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionMinTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_MIN_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionStddevPopulationLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionStddevPopulationLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionStddevPopulationTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionStddevPopulationTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionStddevSampleLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionStddevSampleLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionStddevSampleTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionStddevSampleTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionSumLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_SUM_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionSumLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_SUM_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionSumTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_SUM_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionSumTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_SUM_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionVariancePopulationLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionVariancePopulationLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionVariancePopulationTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionVariancePopulationTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  PrivilegedTaskLabelsConnectionVarianceSampleLabelIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  PrivilegedTaskLabelsConnectionVarianceSampleLabelIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  PrivilegedTaskLabelsConnectionVarianceSampleTaskIdAsc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  PrivilegedTaskLabelsConnectionVarianceSampleTaskIdDesc = 'PRIVILEGED_TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_DATE_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_DATE_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionArrayAggUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionArrayAggUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionAverageCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionAverageCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionAverageDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_DATE_ASC',
  PrivilegedTaskSubtasksConnectionAverageDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_DATE_DESC',
  PrivilegedTaskSubtasksConnectionAverageDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionAverageDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionAverageIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTaskSubtasksConnectionAverageIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTaskSubtasksConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionAveragePositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionAveragePositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionAverageTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionAverageTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionAverageUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionAverageUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionCountAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_COUNT_ASC',
  PrivilegedTaskSubtasksConnectionCountDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_COUNT_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_DATE_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_DATE_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionDistinctCountUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionDistinctCountUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionMaxCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionMaxCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionMaxDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_DATE_ASC',
  PrivilegedTaskSubtasksConnectionMaxDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_DATE_DESC',
  PrivilegedTaskSubtasksConnectionMaxDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionMaxDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionMaxIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_ID_ASC',
  PrivilegedTaskSubtasksConnectionMaxIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_ID_DESC',
  PrivilegedTaskSubtasksConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionMaxPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionMaxPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionMaxTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionMaxTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionMaxUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionMaxUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MAX_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionMinCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionMinCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionMinCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionMinCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionMinCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionMinCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionMinDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_DATE_ASC',
  PrivilegedTaskSubtasksConnectionMinDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_DATE_DESC',
  PrivilegedTaskSubtasksConnectionMinDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionMinDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionMinIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_ID_ASC',
  PrivilegedTaskSubtasksConnectionMinIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_ID_DESC',
  PrivilegedTaskSubtasksConnectionMinIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionMinIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionMinPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionMinPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionMinTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionMinTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionMinUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionMinUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_MIN_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_DATE_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_DATE_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevPopulationUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionStddevPopulationUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_DATE_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_DATE_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionStddevSampleUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionStddevSampleUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionSumCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionSumCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionSumCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionSumCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionSumCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionSumCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionSumDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_DATE_ASC',
  PrivilegedTaskSubtasksConnectionSumDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_DATE_DESC',
  PrivilegedTaskSubtasksConnectionSumDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionSumDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionSumIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_ID_ASC',
  PrivilegedTaskSubtasksConnectionSumIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_ID_DESC',
  PrivilegedTaskSubtasksConnectionSumIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionSumIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionSumPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionSumPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionSumTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionSumTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionSumUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionSumUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_SUM_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_DATE_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_DATE_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionVariancePopulationUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionVariancePopulationUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleDateAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_DATE_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleDateDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_DATE_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleDetailAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_DETAIL_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleDetailDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_DETAIL_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleTaskIdAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleTaskIdDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  PrivilegedTaskSubtasksConnectionVarianceSampleUpdatedAtAsc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PrivilegedTaskSubtasksConnectionVarianceSampleUpdatedAtDesc = 'PRIVILEGED_TASK_SUBTASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeAsc = 'PRIVILEGE_ASC',
  PrivilegeDesc = 'PRIVILEGE_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  PrivilegeTeamAsc = 'PRIVILEGE_TEAM_ASC',
  PrivilegeTeamDesc = 'PRIVILEGE_TEAM_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectStageIdAsc = 'PROJECT_STAGE_ID_ASC',
  ProjectStageIdDesc = 'PROJECT_STAGE_ID_DESC',
  StartDateAllDayAsc = 'START_DATE_ALL_DAY_ASC',
  StartDateAllDayDesc = 'START_DATE_ALL_DAY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByTaskIdConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByTaskIdConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByTaskIdConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByTaskIdConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByTaskIdConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByTaskIdConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByTaskIdConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByTaskIdConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByTaskIdConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByTaskIdConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByTaskIdConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByTaskIdConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByTaskIdConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByTaskIdConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionAverageAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionAverageAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByTaskIdConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByTaskIdConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionAverageFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByTaskIdConnectionAverageFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByTaskIdConnectionAverageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByTaskIdConnectionAverageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByTaskIdConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionAveragePositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByTaskIdConnectionAveragePositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByTaskIdConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByTaskIdConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByTaskIdConnectionAverageStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByTaskIdConnectionAverageStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByTaskIdConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionAverageTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByTaskIdConnectionAverageTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByTaskIdConnectionAverageUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByTaskIdConnectionAverageUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByTaskIdConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskReportsByTaskIdConnectionCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByTaskIdConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByTaskIdConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByTaskIdConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByTaskIdConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionMaxAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionMaxAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByTaskIdConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByTaskIdConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionMaxFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByTaskIdConnectionMaxFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByTaskIdConnectionMaxIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  TaskReportsByTaskIdConnectionMaxIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  TaskReportsByTaskIdConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionMaxPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByTaskIdConnectionMaxPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByTaskIdConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByTaskIdConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByTaskIdConnectionMaxStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByTaskIdConnectionMaxStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByTaskIdConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionMaxTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByTaskIdConnectionMaxTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByTaskIdConnectionMaxUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_UID_ASC',
  TaskReportsByTaskIdConnectionMaxUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_UID_DESC',
  TaskReportsByTaskIdConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionMinAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionMinAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionMinEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByTaskIdConnectionMinEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByTaskIdConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionMinFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByTaskIdConnectionMinFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByTaskIdConnectionMinIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  TaskReportsByTaskIdConnectionMinIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  TaskReportsByTaskIdConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionMinJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionMinJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionMinPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByTaskIdConnectionMinPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByTaskIdConnectionMinPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionMinPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionMinStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByTaskIdConnectionMinStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByTaskIdConnectionMinStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByTaskIdConnectionMinStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByTaskIdConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionMinTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByTaskIdConnectionMinTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByTaskIdConnectionMinUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_UID_ASC',
  TaskReportsByTaskIdConnectionMinUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_UID_DESC',
  TaskReportsByTaskIdConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByTaskIdConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByTaskIdConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByTaskIdConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionSumAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionSumAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionSumEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByTaskIdConnectionSumEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByTaskIdConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionSumFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByTaskIdConnectionSumFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByTaskIdConnectionSumIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  TaskReportsByTaskIdConnectionSumIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  TaskReportsByTaskIdConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionSumJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionSumJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionSumPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByTaskIdConnectionSumPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByTaskIdConnectionSumPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionSumPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionSumStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByTaskIdConnectionSumStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByTaskIdConnectionSumStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByTaskIdConnectionSumStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByTaskIdConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionSumTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByTaskIdConnectionSumTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByTaskIdConnectionSumUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_UID_ASC',
  TaskReportsByTaskIdConnectionSumUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_UID_DESC',
  TaskReportsByTaskIdConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByTaskIdConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByTaskIdConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByTaskIdConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByTaskIdConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAveragePositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionAveragePositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionMinEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionMinPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionMinPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionMinPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionMinStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionMinTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionMinUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionSumEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionSumPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionSumPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionSumPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionSumStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionSumTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionSumUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByTemplateTaskIdConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_TEMPLATE_TASK_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionAverageTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionAverageTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionMaxTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionMaxTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionMinTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionMinTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionSumTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionSumTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleCountAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleCountDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleEndedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleEndedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleIsEndedAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleIsEndedDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleSpentTimeAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleSpentTimeDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleStartedAtAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleStartedAtDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleStatusAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleStatusDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskStatusTimelinesByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_STATUS_TIMELINES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionAverageCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionAverageCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionAverageIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionAverageIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionAverageStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionAverageStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionAverageTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionAverageTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionCountAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskStatusUpdatesByTaskIdConnectionCountDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionMaxCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionMaxCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionMaxIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionMaxIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionMaxStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionMaxStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionMaxTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionMaxTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionMinCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionMinCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionMinIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionMinIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionMinStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionMinStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionMinTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionMinTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionSumCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionSumCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionSumIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionSumIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionSumStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionSumStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionSumTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionSumTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleCreatedAtAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleCreatedAtDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleStatusAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleStatusDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskStatusUpdatesByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_STATUS_UPDATES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionArrayAggDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionArrayAggIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionArrayAggIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionArrayAggJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskVisitsByTaskIdConnectionArrayAggPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionAverageCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionAverageDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionAverageDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionAverageEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionAverageEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionAverageEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionAverageEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionAverageIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskVisitsByTaskIdConnectionAverageIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionAverageIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionAverageJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionAveragePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionAveragePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionAverageStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionAverageStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionAverageStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionAverageStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionAverageTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionAverageUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionCountAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskVisitsByTaskIdConnectionCountDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionMaxCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionMaxDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionMaxDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionMaxEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMaxEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMaxEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionMaxEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionMaxIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionMaxIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionMaxJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_POSITION_ASC',
  TaskVisitsByTaskIdConnectionMaxPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_POSITION_DESC',
  TaskVisitsByTaskIdConnectionMaxStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMaxStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMaxStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionMaxStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionMaxTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionMinCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionMinDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionMinDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionMinEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMinEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMinEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionMinEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionMinIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  TaskVisitsByTaskIdConnectionMinIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  TaskVisitsByTaskIdConnectionMinIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionMinIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionMinJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionMinJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionMinPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_POSITION_ASC',
  TaskVisitsByTaskIdConnectionMinPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_POSITION_DESC',
  TaskVisitsByTaskIdConnectionMinStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMinStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMinStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionMinStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionMinTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionMinTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionMinUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSamplePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionStddevSamplePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionSumCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionSumDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionSumDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionSumEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionSumEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionSumEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionSumEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionSumIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  TaskVisitsByTaskIdConnectionSumIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  TaskVisitsByTaskIdConnectionSumIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionSumIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionSumJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionSumJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionSumPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_POSITION_ASC',
  TaskVisitsByTaskIdConnectionSumPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_POSITION_DESC',
  TaskVisitsByTaskIdConnectionSumStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionSumStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionSumStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionSumStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionSumTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionSumTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionSumUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSamplePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionVarianceSamplePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TemplateAssigneePropertyIdAsc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TemplateAssigneePropertyIdDesc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TemplatePropertyIdsAsc = 'TEMPLATE_PROPERTY_IDS_ASC',
  TemplatePropertyIdsDesc = 'TEMPLATE_PROPERTY_IDS_DESC',
  TemplateTaskIdAsc = 'TEMPLATE_TASK_ID_ASC',
  TemplateTaskIdDesc = 'TEMPLATE_TASK_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  VirtualPropertiesAsc = 'VIRTUAL_PROPERTIES_ASC',
  VirtualPropertiesDesc = 'VIRTUAL_PROPERTIES_DESC'
}

export type Project = Node & {
  accountStatus: Maybe<Scalars['String']['output']>;
  accountType: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedProjectIdAndActionId: ProjectActionsByFeedProjectIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedProjectIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileProjectIdAndActionId: ProjectActionsByFileProjectIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileProjectIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByProjectId: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByProjectIdConnection: ActionsConnection;
  address: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  addressNormalized: Maybe<NormAddy>;
  addressPretty: Maybe<Scalars['String']['output']>;
  ahj: Maybe<Scalars['String']['output']>;
  /** Reads a single `Blueprint` that is related to this `Project`. */
  blueprint: Maybe<Blueprint>;
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectParentProjectIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportProjectIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectStageTimelineProjectIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedProjectIdAndCallId: ProjectCallsByFeedProjectIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedProjectIdAndCallIdList: Array<Call>;
  city: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedProjectIdAndCommentId: ProjectCommentsByFeedProjectIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileProjectIdAndCommentId: ProjectCommentsByFileProjectIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByProjectIdConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskProjectIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectParentProjectIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportProjectIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByReminderProjectIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportProjectIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `Project`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  completionDate: Maybe<Scalars['Datetime']['output']>;
  completionTime: Maybe<Interval>;
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  confidence: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedProjectIdAndCreatedByContactId: ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedProjectIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileProjectIdAndCreatedByContactId: ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileProjectIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectParentProjectIdAndCreatedByContactId: ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectParentProjectIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectParentProjectIdAndReferrerContactId: ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectParentProjectIdAndReferrerContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `Project`. */
  createdByContact: Maybe<Contact>;
  createdByContactId: Maybe<Scalars['Int']['output']>;
  createdByType: Maybe<CommentCreatedBy>;
  /** Reads a single `User` that is related to this `Project`. */
  createdByUser: Maybe<User>;
  dealSize: Maybe<Scalars['Float']['output']>;
  dealValue: Maybe<Scalars['Float']['output']>;
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedProjectIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `FavoriteProject`. */
  favoriteProjects: Array<FavoriteProject>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedProjectIdAndParentId: ProjectFeedsByFeedProjectIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentProjectIdAndFileId: ProjectFilesByCommentProjectIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentProjectIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedProjectIdAndFileId: ProjectFilesByFeedProjectIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedProjectIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileProjectIdAndValueFileId: ProjectFilesByFileProjectIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileProjectIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileProjectIdAndFormId: ProjectFormsByFileProjectIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileProjectIdAndFormIdList: Array<Form>;
  freshProject: Maybe<Scalars['Boolean']['output']>;
  geoLocation: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  geoLocationGeography: Maybe<GeographyInterface>;
  geoLocationNormalized: Maybe<Point>;
  homeOwnerEmail: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByAccountId: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByAccountIdConnection: ImportRowsConnection;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByProjectId: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByProjectIdConnection: ImportRowsConnection;
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsBySystemProjectIdAndIntegrationIdList: Array<Integration>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProjectId: Array<Invoice>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProjectIdConnection: InvoicesConnection;
  isActive: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Jurisdiction` that is related to this `Project`. */
  jurisdiction: Maybe<Jurisdiction>;
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectParentProjectIdAndJurisdictionId: ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectParentProjectIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportProjectIdAndJurisdictionId: ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportProjectIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskProjectIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportProjectIdAndFirstLabelIdList: Array<Label>;
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  lastInboundCallDisposition: Maybe<CallDisposition>;
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundCallDisposition: Maybe<CallDisposition>;
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `LineItem`. */
  lineItemsByProjectId: Array<LineItem>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByInvoiceProjectIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskProjectIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskProjectIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportProjectIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportProjectIdAndCreatedByList: Array<MainUser>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Reads a single `User` that is related to this `Project`. */
  owner: Maybe<User>;
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Project` that is related to this `Project`. */
  parentProject: Maybe<Project>;
  parentProjectId: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  primaryEmail: Maybe<Scalars['String']['output']>;
  primaryPhone: Maybe<Scalars['String']['output']>;
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  privilegeTeam: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByCommentProjectIdAndTaskId: ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedProjectIdAndTaskId: ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskId: ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportProjectIdAndTaskId: ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportProjectIdAndTemplateTaskId: ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyConnection;
  progress: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectAddress`. */
  projectAddressesByProjectId: Array<ProjectAddress>;
  /** Reads and enables pagination through a set of `Contact`. */
  projectContacts: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  projectContactsConnection: ProjectProjectContactsConnectionManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectContact`. */
  projectContactsIndirect: Array<ProjectContact>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
  /** Reads a single `User` that is related to this `Project`. */
  projectManager: Maybe<User>;
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembers: Array<ProjectMember>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValues: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValuesConnection: ProjectPropertyValuesConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectPropertyValueProjectIdAndProjectId: ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectPropertyValueProjectIdAndProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportProjectIdAndParentProjectId: ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportProjectIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStageUpdateProjectIdAndProjectId: ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStageUpdateProjectIdAndProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStatusTimelineProjectIdAndProjectId: ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStatusTimelineProjectIdAndProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStatusUpdateProjectIdAndProjectId: ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectStatusUpdateProjectIdAndProjectIdList: Array<ProjectReport>;
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectId: Array<ProjectStageTimeline>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectIdConnection: ProjectStageTimelinesConnection;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdates: Array<ProjectStageUpdate>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesConnection: ProjectStageUpdatesConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileProjectIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskProjectIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectParentProjectIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportProjectIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportProjectIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectStageTimelineProjectIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByTaskReportProjectIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimeline: Array<ProjectStatusTimeline>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimelineConnection: ProjectStatusTimelinesConnection;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdates: Array<ProjectStatusUpdate>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdatesConnection: ProjectStatusUpdatesConnection;
  /** Reads and enables pagination through a set of `ProjectTrade`. */
  projectTradesByProjectId: Array<ProjectTrade>;
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByImportRowAccountIdAndProjectId: ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByImportRowAccountIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByImportRowProjectIdAndAccountId: ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByImportRowProjectIdAndAccountIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
  /** Reads a single `Contact` that is related to this `Project`. */
  referrerContact: Maybe<Contact>;
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedProjectIdAndReminderId: ProjectRemindersByFeedProjectIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedProjectIdAndReminderIdList: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectIdConnection: RemindersConnection;
  requestStatus: Maybe<ProjectRequestStatus>;
  revenue: Maybe<Scalars['Float']['output']>;
  /** Reads a single `User` that is related to this `Project`. */
  salesRep: Maybe<User>;
  salesRepId: Maybe<Scalars['Int']['output']>;
  slaRedViolationJobId: Maybe<Scalars['String']['output']>;
  slaYellowViolationJobId: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedProjectIdAndSmsActivityIdList: Array<SmsActivity>;
  source: Maybe<CreatedSource>;
  /** Reads a single `ProjectStage` that is related to this `Project`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  state: Maybe<Scalars['String']['output']>;
  status: Maybe<ProjectStatus>;
  street: Maybe<Scalars['String']['output']>;
  streetViewUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SystemProfile`. */
  systemProfilesBySystemProjectIdAndProfileIdList: Array<SystemProfile>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByCommentProjectIdAndSystemId: ProjectSystemsByCommentProjectIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedProjectIdAndSystemId: ProjectSystemsByFeedProjectIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskProjectIdAndSystemId: ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `System`. */
  systemsByProjectIdConnection: SystemsConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `Task`. */
  tasksConnection: TasksConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectMemberProjectIdAndTeamId: ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectMemberProjectIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportProjectIdAndTeamId: ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportProjectIdAndTeamIdList: Array<Team>;
  title: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Float']['output']>;
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Scalars['Float']['output']>;
  trades: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedProjectIdAndCreatedBy: ProjectUsersByFeedProjectIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByFileProjectIdAndCreatedBy: ProjectUsersByFileProjectIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectParentProjectIdAndCreatedBy: ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectParentProjectIdAndOwnerId: ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectParentProjectIdAndProjectManagerId: ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectParentProjectIdAndSalesRepId: ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndCreatedBy: ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndOwnerId: ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndProjectManagerId: ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndSalesRepId: ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndUserId: ProjectUsersByProjectReportProjectIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectIdAndWorkerValue: ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderProjectIdAndAssigneeId: ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderProjectIdAndCreatedBy: ProjectUsersByReminderProjectIdAndCreatedByManyToManyConnection;
  zipcode: Maybe<Scalars['String']['output']>;
};


export type ProjectActionsByFeedProjectIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectActionsByFeedProjectIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectActionsByFileProjectIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectActionsByFileProjectIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectActionsByProjectIdArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectActionsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectBlueprintsByProjectParentProjectIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectBlueprintsByProjectReportProjectIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectBlueprintsByProjectStageTimelineProjectIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectCallsByFeedProjectIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ProjectCallsByFeedProjectIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ProjectCommentsByFeedProjectIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ProjectCommentsByFileProjectIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ProjectCommentsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ProjectCompaniesByPrivilegedTaskProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectCompaniesByProjectParentProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectCompaniesByProjectReportProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectCompaniesByReminderProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectCompaniesByTaskReportProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectContactsByFeedProjectIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByFeedProjectIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByFileProjectIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByFileProjectIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectEmailMessagesByFeedProjectIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type ProjectFavoriteProjectsArgs = {
  condition: InputMaybe<FavoriteProjectCondition>;
  filter: InputMaybe<FavoriteProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FavoriteProjectsOrderBy>>;
};


export type ProjectFeedsByFeedProjectIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ProjectFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ProjectFilesByCommentProjectIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFilesByCommentProjectIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFilesByFeedProjectIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFilesByFeedProjectIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFilesByFileProjectIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFilesByFileProjectIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectFormsByFileProjectIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectFormsByFileProjectIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectImportRowsByAccountIdArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ProjectImportRowsByAccountIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ProjectImportRowsByProjectIdArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ProjectImportRowsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};


export type ProjectIntegrationsBySystemProjectIdAndIntegrationIdListArgs = {
  condition: InputMaybe<IntegrationCondition>;
  filter: InputMaybe<IntegrationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationsOrderBy>>;
};


export type ProjectInvoicesByProjectIdArgs = {
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


export type ProjectInvoicesByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>;
};


export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectLabelsByPrivilegedTaskProjectIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type ProjectLabelsByTaskReportProjectIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type ProjectLineItemsByProjectIdArgs = {
  condition: InputMaybe<LineItemCondition>;
  filter: InputMaybe<LineItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LineItemsOrderBy>>;
};


export type ProjectMainUsersByInvoiceProjectIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectMainUsersByPrivilegedTaskProjectIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectMainUsersByPrivilegedTaskProjectIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectMainUsersByTaskReportProjectIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectMainUsersByTaskReportProjectIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectPrivilegedTasksByCommentProjectIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectPrivilegedTasksByFeedProjectIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectPrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectProjectAddressesByProjectIdArgs = {
  condition: InputMaybe<ProjectAddressCondition>;
  filter: InputMaybe<ProjectAddressFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectAddressesOrderBy>>;
};


export type ProjectProjectContactsArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectProjectContactsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectProjectContactsIndirectArgs = {
  condition: InputMaybe<ProjectContactCondition>;
  filter: InputMaybe<ProjectContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectContactsOrderBy>>;
};


export type ProjectProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectProjectMembersArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type ProjectProjectPropertiesValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectProjectPropertiesValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectProjectStageTimelinesByProjectIdArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectProjectStageTimelinesByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectProjectStageUpdatesArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectProjectStageUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectProjectStagesByFileProjectIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByPrivilegedTaskProjectIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByProjectParentProjectIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByProjectReportProjectIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByProjectReportProjectIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByProjectStageTimelineProjectIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStagesByTaskReportProjectIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectProjectStatusTimelineArgs = {
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectProjectStatusTimelineConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectProjectStatusUpdatesArgs = {
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectProjectStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectProjectTradesByProjectIdArgs = {
  condition: InputMaybe<ProjectTradeCondition>;
  filter: InputMaybe<ProjectTradeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectTradesOrderBy>>;
};


export type ProjectProjectsByImportRowAccountIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectProjectsByImportRowAccountIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectProjectsByImportRowProjectIdAndAccountIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectProjectsByImportRowProjectIdAndAccountIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectRemindersByFeedProjectIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type ProjectRemindersByFeedProjectIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ProjectRemindersByProjectIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ProjectRemindersByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type ProjectSmsActivitiesByFeedProjectIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ProjectSystemProfilesBySystemProjectIdAndProfileIdListArgs = {
  condition: InputMaybe<SystemProfileCondition>;
  filter: InputMaybe<SystemProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfilesOrderBy>>;
};


export type ProjectSystemsByCommentProjectIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ProjectSystemsByFeedProjectIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ProjectSystemsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ProjectTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type ProjectTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskCondition>;
  filter: InputMaybe<TaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};


export type ProjectTeamsByProjectMemberProjectIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectTeamsByProjectMemberProjectIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectTeamsByProjectReportProjectIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectTeamsByProjectReportProjectIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectUsersByFeedProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByFileProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectParentProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectParentProjectIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectParentProjectIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByProjectReportProjectIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByReminderProjectIdAndAssigneeIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectUsersByReminderProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type ProjectActionsByFeedProjectIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectActionsByFeedProjectIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type ProjectActionsByFeedProjectIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type ProjectActionsByFeedProjectIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type ProjectActionsByFeedProjectIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type ProjectActionsByFileProjectIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectActionsByFileProjectIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type ProjectActionsByFileProjectIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type ProjectActionsByFileProjectIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ProjectActionsByFileProjectIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ProjectActionsByFileProjectIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ProjectAddress = {
  address: Maybe<Scalars['String']['output']>;
  /** Reads a single `Project` that is related to this `ProjectAddress`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against aggregates of `ProjectAddress` object types. */
export type ProjectAddressAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectAddress` objects. */
  arrayAgg: InputMaybe<ProjectAddressArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectAddress` objects. */
  average: InputMaybe<ProjectAddressAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectAddress` objects. */
  distinctCount: InputMaybe<ProjectAddressDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectAddress` object to be included within the aggregate. */
  filter: InputMaybe<ProjectAddressFilter>;
  /** Maximum aggregate over matching `ProjectAddress` objects. */
  max: InputMaybe<ProjectAddressMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectAddress` objects. */
  min: InputMaybe<ProjectAddressMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectAddress` objects. */
  stddevPopulation: InputMaybe<ProjectAddressStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectAddress` objects. */
  stddevSample: InputMaybe<ProjectAddressStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectAddress` objects. */
  sum: InputMaybe<ProjectAddressSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectAddress` objects. */
  variancePopulation: InputMaybe<ProjectAddressVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectAddress` objects. */
  varianceSample: InputMaybe<ProjectAddressVarianceSampleAggregateFilter>;
};

export type ProjectAddressArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
};

export type ProjectAddressAverageAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectAddress` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectAddressCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectAddressDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectAddress` object types. All fields are combined with a logical ‘and.’ */
export type ProjectAddressFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectAddressFilter>>;
  /** Negates the expression. */
  not: InputMaybe<ProjectAddressFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectAddressFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
};

export type ProjectAddressMaxAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
};

export type ProjectAddressMinAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
};

export type ProjectAddressStddevPopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectAddressStddevSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectAddressSumAggregateFilter = {
  projectId: InputMaybe<BigIntFilter>;
};

export type ProjectAddressVariancePopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectAddressVarianceSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ProjectAddress`. */
export enum ProjectAddressesOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

export type ProjectAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Project` object types. */
export type ProjectAggregatesFilter = {
  /** Array aggregation aggregate over matching `Project` objects. */
  arrayAgg: InputMaybe<ProjectArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Project` objects. */
  average: InputMaybe<ProjectAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Project` objects. */
  distinctCount: InputMaybe<ProjectDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Project` object to be included within the aggregate. */
  filter: InputMaybe<ProjectFilter>;
  /** Maximum aggregate over matching `Project` objects. */
  max: InputMaybe<ProjectMaxAggregateFilter>;
  /** Minimum aggregate over matching `Project` objects. */
  min: InputMaybe<ProjectMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Project` objects. */
  stddevPopulation: InputMaybe<ProjectStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Project` objects. */
  stddevSample: InputMaybe<ProjectStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Project` objects. */
  sum: InputMaybe<ProjectSumAggregateFilter>;
  /** Population variance aggregate over matching `Project` objects. */
  variancePopulation: InputMaybe<ProjectVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Project` objects. */
  varianceSample: InputMaybe<ProjectVarianceSampleAggregateFilter>;
};

export type ProjectArrayAggAggregateFilter = {
  accountStatus: InputMaybe<StringListFilter>;
  addressPretty: InputMaybe<StringListFilter>;
  blueprintId: InputMaybe<IntListFilter>;
  city: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  confidence: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  createdByContactId: InputMaybe<IntListFilter>;
  dealSize: InputMaybe<FloatListFilter>;
  dealValue: InputMaybe<FloatListFilter>;
  freshProject: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  imageUrl: InputMaybe<StringListFilter>;
  isActive: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  jurisdictionId: InputMaybe<IntListFilter>;
  ownerId: InputMaybe<IntListFilter>;
  parentProjectId: InputMaybe<IntListFilter>;
  primaryEmail: InputMaybe<StringListFilter>;
  primaryPhone: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
  privilegeTeam: InputMaybe<StringListFilter>;
  progress: InputMaybe<IntListFilter>;
  projectManagerId: InputMaybe<IntListFilter>;
  projectSize: InputMaybe<FloatListFilter>;
  projectValue: InputMaybe<FloatListFilter>;
  referrerContactId: InputMaybe<IntListFilter>;
  revenue: InputMaybe<FloatListFilter>;
  salesRepId: InputMaybe<IntListFilter>;
  slaRedViolationJobId: InputMaybe<StringListFilter>;
  slaYellowViolationJobId: InputMaybe<StringListFilter>;
  stageId: InputMaybe<IntListFilter>;
  state: InputMaybe<StringListFilter>;
  street: InputMaybe<StringListFilter>;
  streetViewUrl: InputMaybe<StringListFilter>;
  totalCost: InputMaybe<FloatListFilter>;
  totalInboundCallsCount: InputMaybe<IntListFilter>;
  totalOutboundCallsCount: InputMaybe<IntListFilter>;
  totalPrice: InputMaybe<FloatListFilter>;
  type: InputMaybe<StringListFilter>;
  uid: InputMaybe<IntListFilter>;
  zipcode: InputMaybe<StringListFilter>;
};

export type ProjectArrayAggAggregates = {
  /** Array aggregation of accountStatus across the matching connection */
  accountStatus: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressPretty across the matching connection */
  addressPretty: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of blueprintId across the matching connection */
  blueprintId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of city across the matching connection */
  city: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of confidence across the matching connection */
  confidence: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdByContactId across the matching connection */
  createdByContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of dealSize across the matching connection */
  dealSize: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of dealValue across the matching connection */
  dealValue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of dealValueWeighted across the matching connection */
  dealValueWeighted: Point;
  /** Array aggregation of freshProject across the matching connection */
  freshProject: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of geoLocationNormalized across the matching connection */
  geoLocationNormalized: Scalars['String']['output'];
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of imageUrl across the matching connection */
  imageUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of isActive across the matching connection */
  isActive: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jurisdictionId across the matching connection */
  jurisdictionId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of overdueBy across the matching connection */
  overdueBy: Point;
  /** Array aggregation of ownerId across the matching connection */
  ownerId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of parentProjectId across the matching connection */
  parentProjectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of primaryEmail across the matching connection */
  primaryEmail: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of primaryPhone across the matching connection */
  primaryPhone: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeTeam across the matching connection */
  privilegeTeam: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of progress across the matching connection */
  progress: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectManagerId across the matching connection */
  projectManagerId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectSize across the matching connection */
  projectSize: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of projectValue across the matching connection */
  projectValue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of referrerContactId across the matching connection */
  referrerContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of revenue across the matching connection */
  revenue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of salesRepId across the matching connection */
  salesRepId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of slaRedViolationJobId across the matching connection */
  slaRedViolationJobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of slaYellowViolationJobId across the matching connection */
  slaYellowViolationJobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of state across the matching connection */
  state: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of street across the matching connection */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of streetViewUrl across the matching connection */
  streetViewUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of totalCost across the matching connection */
  totalCost: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of totalPrice across the matching connection */
  totalPrice: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of zipcode across the matching connection */
  zipcode: Array<Maybe<Scalars['String']['output']>>;
};


export type ProjectArrayAggAggregatesAccountStatusArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesAddressPrettyArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesCityArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesFreshProjectArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesGeoLocationNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesIsActiveArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesPrimaryEmailArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesPrimaryPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesSlaRedViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesSlaYellowViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesStateArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesStreetArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesStreetViewUrlArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectArrayAggAggregatesZipcodeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectAverageAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type ProjectAverageAggregates = {
  /** Mean average of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Mean average of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Mean average of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Mean average of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Mean average of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Mean average of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectAverageAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type ProjectCallsByFeedProjectIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectCallsByFeedProjectIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type ProjectCallsByFeedProjectIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type ProjectCallsByFeedProjectIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type ProjectCallsByFeedProjectIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ProjectColumnAccess = Node & {
  /** Reads a single `ProjectProperty` that is related to this `ProjectColumnAccess`. */
  column: Maybe<ProjectProperty>;
  columnId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Role` that is related to this `ProjectColumnAccess`. */
  role: Maybe<Role>;
  roleId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Team` that is related to this `ProjectColumnAccess`. */
  team: Maybe<Team>;
  teamId: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

/** A filter to be used against aggregates of `ProjectColumnAccess` object types. */
export type ProjectColumnAccessAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectColumnAccess` objects. */
  arrayAgg: InputMaybe<ProjectColumnAccessArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectColumnAccess` objects. */
  average: InputMaybe<ProjectColumnAccessAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectColumnAccess` objects. */
  distinctCount: InputMaybe<ProjectColumnAccessDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectColumnAccess` object to be included within the aggregate. */
  filter: InputMaybe<ProjectColumnAccessFilter>;
  /** Maximum aggregate over matching `ProjectColumnAccess` objects. */
  max: InputMaybe<ProjectColumnAccessMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectColumnAccess` objects. */
  min: InputMaybe<ProjectColumnAccessMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectColumnAccess` objects. */
  stddevPopulation: InputMaybe<ProjectColumnAccessStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectColumnAccess` objects. */
  stddevSample: InputMaybe<ProjectColumnAccessStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectColumnAccess` objects. */
  sum: InputMaybe<ProjectColumnAccessSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectColumnAccess` objects. */
  variancePopulation: InputMaybe<ProjectColumnAccessVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectColumnAccess` objects. */
  varianceSample: InputMaybe<ProjectColumnAccessVarianceSampleAggregateFilter>;
};

export type ProjectColumnAccessArrayAggAggregateFilter = {
  columnId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
  type: InputMaybe<StringListFilter>;
};

export type ProjectColumnAccessAverageAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectColumnAccess` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectColumnAccessCondition = {
  /** Checks for equality with the object’s `columnId` field. */
  columnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
};

export type ProjectColumnAccessDistinctCountAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectColumnAccess` object types. All fields are combined with a logical ‘and.’ */
export type ProjectColumnAccessFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectColumnAccessFilter>>;
  /** Filter by the object’s `column` relation. */
  column: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `columnId` field. */
  columnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectColumnAccessFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectColumnAccessFilter>>;
  /** Filter by the object’s `role` relation. */
  role: InputMaybe<RoleFilter>;
  /** A related `role` exists. */
  roleExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** A related `team` exists. */
  teamExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
};

export type ProjectColumnAccessMaxAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type ProjectColumnAccessMinAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type ProjectColumnAccessStddevPopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectColumnAccessStddevSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectColumnAccessSumAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

export type ProjectColumnAccessVariancePopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectColumnAccessVarianceSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ProjectColumnAccess`. */
export enum ProjectColumnAccessesOrderBy {
  ColumnIdAsc = 'COLUMN_ID_ASC',
  ColumnIdDesc = 'COLUMN_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export enum ProjectColumnVisibility {
  Hidden = 'HIDDEN',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

/** A filter to be used against ProjectColumnVisibility fields. All fields are combined with a logical ‘and.’ */
export type ProjectColumnVisibilityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ProjectColumnVisibility>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ProjectColumnVisibility>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ProjectColumnVisibility>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ProjectColumnVisibility>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ProjectColumnVisibility>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ProjectColumnVisibility>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ProjectColumnVisibility>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ProjectColumnVisibility>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ProjectColumnVisibility>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ProjectColumnVisibility>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ProjectCommentsByFeedProjectIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectCommentsByFeedProjectIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ProjectCommentsByFeedProjectIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type ProjectCommentsByFeedProjectIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type ProjectCommentsByFeedProjectIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type ProjectCommentsByFileProjectIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectCommentsByFileProjectIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type ProjectCommentsByFileProjectIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectCommentsByFileProjectIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectCommentsByFileProjectIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectCommentsByFileProjectIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `accountStatus` field. */
  accountStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accountType` field. */
  accountType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filters list of projects by any of adresses including given string. */
  addressLike: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyInput>;
  /** Checks for equality with the object’s `addressPretty` field. */
  addressPretty: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ahj` field. */
  ahj: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blueprintId` field. */
  blueprintId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `city` field. */
  city: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completionDate` field. */
  completionDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `confidence` field. */
  confidence: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `dealSize` field. */
  dealSize: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `dealValue` field. */
  dealValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `dealValueWeighted` field. */
  dealValueWeighted: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `freshProject` field. */
  freshProject: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `geoLocation` field. */
  geoLocation: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `geoLocationGeography` field. */
  geoLocationGeography: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Checks for equality with the object’s `geoLocationNormalized` field. */
  geoLocationNormalized: InputMaybe<PointInput>;
  /** Checks for equality with the object’s `homeOwnerEmail` field. */
  homeOwnerEmail: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  imageUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jurisdictionId` field. */
  jurisdictionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastActivity` field. */
  lastActivity: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundCallDisposition` field. */
  lastInboundCallDisposition: InputMaybe<CallDisposition>;
  /** Checks for equality with the object’s `lastInboundCallTime` field. */
  lastInboundCallTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundEmailTime` field. */
  lastInboundEmailTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundSmsTime` field. */
  lastInboundSmsTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundCallDisposition` field. */
  lastOutboundCallDisposition: InputMaybe<CallDisposition>;
  /** Checks for equality with the object’s `lastOutboundCallTime` field. */
  lastOutboundCallTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundEmailTime` field. */
  lastOutboundEmailTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundSmsTime` field. */
  lastOutboundSmsTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Orders list of projects by value of given additional property. Must be used together with PROPERTY_VALUE_* order. */
  orderByProjectProperty: InputMaybe<Scalars['Int']['input']>;
  /** Orders list of projects by value of given additional property. Must be used together with PROPERTY_VALUE_*_1 order. */
  orderByProjectProperty1: InputMaybe<Scalars['Int']['input']>;
  /** Orders list of projects by value of given additional property. Must be used together with PROPERTY_VALUE_*_2 order. */
  orderByProjectProperty2: InputMaybe<Scalars['Int']['input']>;
  /** Orders list of projects by value of given additional property. Must be used together with PROPERTY_VALUE_*_3 order. */
  orderByProjectProperty3: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `overdueBy` field. */
  overdueBy: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `parentProjectId` field. */
  parentProjectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `primaryEmail` field. */
  primaryEmail: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `primaryPhone` field. */
  primaryPhone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `progress` field. */
  progress: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectManagerId` field. */
  projectManagerId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectSize` field. */
  projectSize: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `projectValue` field. */
  projectValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `requestStatus` field. */
  requestStatus: InputMaybe<ProjectRequestStatus>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `salesRepId` field. */
  salesRepId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `slaRedViolationJobId` field. */
  slaRedViolationJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slaYellowViolationJobId` field. */
  slaYellowViolationJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `source` field. */
  source: InputMaybe<CreatedSource>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ProjectStatus>;
  /** Checks for equality with the object’s `street` field. */
  street: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `streetViewUrl` field. */
  streetViewUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalCost` field. */
  totalCost: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalInboundCallsCount` field. */
  totalInboundCallsCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalOutboundCallsCount` field. */
  totalOutboundCallsCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPrice` field. */
  totalPrice: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `trades` field. */
  trades: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filters list of projects by any of adresses including given string. */
  tradesLike: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `zipcode` field. */
  zipcode: InputMaybe<Scalars['String']['input']>;
};

export type ProjectContact = Node & {
  /** Reads a single `Contact` that is related to this `ProjectContact`. */
  contact: Maybe<Contact>;
  contactId: Scalars['Int']['output'];
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  /** Reads a single `Project` that is related to this `ProjectContact`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `ProjectContact` object types. */
export type ProjectContactAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectContact` objects. */
  arrayAgg: InputMaybe<ProjectContactArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectContact` objects. */
  average: InputMaybe<ProjectContactAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectContact` objects. */
  distinctCount: InputMaybe<ProjectContactDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectContact` object to be included within the aggregate. */
  filter: InputMaybe<ProjectContactFilter>;
  /** Maximum aggregate over matching `ProjectContact` objects. */
  max: InputMaybe<ProjectContactMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectContact` objects. */
  min: InputMaybe<ProjectContactMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectContact` objects. */
  stddevPopulation: InputMaybe<ProjectContactStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectContact` objects. */
  stddevSample: InputMaybe<ProjectContactStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectContact` objects. */
  sum: InputMaybe<ProjectContactSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectContact` objects. */
  variancePopulation: InputMaybe<ProjectContactVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectContact` objects. */
  varianceSample: InputMaybe<ProjectContactVarianceSampleAggregateFilter>;
};

export type ProjectContactArrayAggAggregateFilter = {
  contactId: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
};

export type ProjectContactAverageAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectContact` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectContactCondition = {
  /** Checks for equality with the object’s `contactId` field. */
  contactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectContactDistinctCountAggregateFilter = {
  contactId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectContact` object types. All fields are combined with a logical ‘and.’ */
export type ProjectContactFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectContactFilter>>;
  /** Filter by the object’s `contact` relation. */
  contact: InputMaybe<ContactFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectContactFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectContactFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
};

export type ProjectContactMaxAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  position: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
};

export type ProjectContactMinAggregateFilter = {
  contactId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  position: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
};

export type ProjectContactStddevPopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectContactStddevSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectContactSumAggregateFilter = {
  contactId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
};

export type ProjectContactVariancePopulationAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectContactVarianceSampleAggregateFilter = {
  contactId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type ProjectContactsByFeedProjectIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectContactsByFileProjectIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectContactsByProjectParentProjectIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** Methods to use when ordering `ProjectContact`. */
export enum ProjectContactsOrderBy {
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

export type ProjectDistinctCountAggregateFilter = {
  accountStatus: InputMaybe<BigIntFilter>;
  accountType: InputMaybe<BigIntFilter>;
  address: InputMaybe<BigIntFilter>;
  addressNormalized: InputMaybe<BigIntFilter>;
  addressPretty: InputMaybe<BigIntFilter>;
  ahj: InputMaybe<BigIntFilter>;
  blueprintId: InputMaybe<BigIntFilter>;
  city: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionDate: InputMaybe<BigIntFilter>;
  completionTime: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  createdByType: InputMaybe<BigIntFilter>;
  dealSize: InputMaybe<BigIntFilter>;
  dealValue: InputMaybe<BigIntFilter>;
  dealValueWeighted: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  freshProject: InputMaybe<BigIntFilter>;
  geoLocation: InputMaybe<BigIntFilter>;
  geoLocationGeography: InputMaybe<BigIntFilter>;
  geoLocationNormalized: InputMaybe<BigIntFilter>;
  homeOwnerEmail: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  imageUrl: InputMaybe<BigIntFilter>;
  isActive: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  jurisdictionId: InputMaybe<BigIntFilter>;
  lastActivity: InputMaybe<BigIntFilter>;
  lastInboundCallDisposition: InputMaybe<BigIntFilter>;
  lastInboundCallTime: InputMaybe<BigIntFilter>;
  lastInboundEmailTime: InputMaybe<BigIntFilter>;
  lastInboundSmsTime: InputMaybe<BigIntFilter>;
  lastOutboundCallDisposition: InputMaybe<BigIntFilter>;
  lastOutboundCallTime: InputMaybe<BigIntFilter>;
  lastOutboundEmailTime: InputMaybe<BigIntFilter>;
  lastOutboundSmsTime: InputMaybe<BigIntFilter>;
  overdueBy: InputMaybe<BigIntFilter>;
  ownerId: InputMaybe<BigIntFilter>;
  parentProjectId: InputMaybe<BigIntFilter>;
  phone: InputMaybe<BigIntFilter>;
  primaryEmail: InputMaybe<BigIntFilter>;
  primaryPhone: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  privilegeTeam: InputMaybe<BigIntFilter>;
  progress: InputMaybe<BigIntFilter>;
  projectManagerId: InputMaybe<BigIntFilter>;
  projectSize: InputMaybe<BigIntFilter>;
  projectValue: InputMaybe<BigIntFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
  requestStatus: InputMaybe<BigIntFilter>;
  revenue: InputMaybe<BigIntFilter>;
  salesRepId: InputMaybe<BigIntFilter>;
  slaRedViolationJobId: InputMaybe<BigIntFilter>;
  slaYellowViolationJobId: InputMaybe<BigIntFilter>;
  source: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  state: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  street: InputMaybe<BigIntFilter>;
  streetViewUrl: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  totalCost: InputMaybe<BigIntFilter>;
  totalInboundCallsCount: InputMaybe<BigIntFilter>;
  totalOutboundCallsCount: InputMaybe<BigIntFilter>;
  totalPrice: InputMaybe<BigIntFilter>;
  trades: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  zipcode: InputMaybe<BigIntFilter>;
};

export type ProjectDistinctCountAggregates = {
  /** Distinct count of accountStatus across the matching connection */
  accountStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of accountType across the matching connection */
  accountType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressNormalized across the matching connection */
  addressNormalized: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressPretty across the matching connection */
  addressPretty: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of ahj across the matching connection */
  ahj: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of city across the matching connection */
  city: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionDate across the matching connection */
  completionDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTime across the matching connection */
  completionTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of confidence across the matching connection */
  confidence: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByType across the matching connection */
  createdByType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealSize across the matching connection */
  dealSize: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealValue across the matching connection */
  dealValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of freshProject across the matching connection */
  freshProject: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocation across the matching connection */
  geoLocation: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocationGeography across the matching connection */
  geoLocationGeography: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocationNormalized across the matching connection */
  geoLocationNormalized: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of homeOwnerEmail across the matching connection */
  homeOwnerEmail: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of imageUrl across the matching connection */
  imageUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isActive across the matching connection */
  isActive: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundCallDisposition across the matching connection */
  lastInboundCallDisposition: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundCallDisposition across the matching connection */
  lastOutboundCallDisposition: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phone across the matching connection */
  phone: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of primaryEmail across the matching connection */
  primaryEmail: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of primaryPhone across the matching connection */
  primaryPhone: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeTeam across the matching connection */
  privilegeTeam: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of progress across the matching connection */
  progress: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectSize across the matching connection */
  projectSize: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectValue across the matching connection */
  projectValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of requestStatus across the matching connection */
  requestStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of revenue across the matching connection */
  revenue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slaRedViolationJobId across the matching connection */
  slaRedViolationJobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slaYellowViolationJobId across the matching connection */
  slaYellowViolationJobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of source across the matching connection */
  source: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of street across the matching connection */
  street: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of streetViewUrl across the matching connection */
  streetViewUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalCost across the matching connection */
  totalCost: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of trades across the matching connection */
  trades: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of zipcode across the matching connection */
  zipcode: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectDistinctCountAggregatesAccountStatusArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesAccountTypeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesAddressNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesAddressPrettyArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesAhjArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCityArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCompletionTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesCreatedByTypeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesFreshProjectArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesGeoLocationArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesGeoLocationGeographyArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesGeoLocationNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesHomeOwnerEmailArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesIsActiveArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastInboundCallDispositionArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastOutboundCallDispositionArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPrimaryEmailArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPrimaryPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesRequestStatusArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesSlaRedViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesSlaYellowViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesSourceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesStateArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesStreetArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesStreetViewUrlArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTradesArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectDistinctCountAggregatesZipcodeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ProjectFeedsByFeedProjectIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectFeedsByFeedProjectIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ProjectFeedsByFeedProjectIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type ProjectFeedsByFeedProjectIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type ProjectFeedsByFeedProjectIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Comment`. */
export type ProjectFilesByCommentProjectIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<ProjectFilesByCommentProjectIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Comment`. */
export type ProjectFilesByCommentProjectIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Comment`. */
export type ProjectFilesByCommentProjectIdAndFileIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Comment`. */
export type ProjectFilesByCommentProjectIdAndFileIdManyToManyEdgeCommentsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type ProjectFilesByFeedProjectIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectFilesByFeedProjectIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type ProjectFilesByFeedProjectIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type ProjectFilesByFeedProjectIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type ProjectFilesByFeedProjectIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type ProjectFilesByFileProjectIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectFilesByFileProjectIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type ProjectFilesByFileProjectIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type ProjectFilesByFileProjectIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ProjectFilesByFileProjectIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ProjectFilesByFileProjectIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `accountStatus` field. */
  accountStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountType` field. */
  accountType: InputMaybe<StringFilter>;
  /** Filter by the object’s `actionsByProjectId` relation. */
  actionsByProjectId: InputMaybe<ProjectToManyActionFilter>;
  /** Some related `actionsByProjectId` exist. */
  actionsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringListFilter>;
  /** Filter by the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyFilter>;
  /** Filter by the object’s `addressPretty` field. */
  addressPretty: InputMaybe<StringFilter>;
  /** Filter by the object’s `ahj` field. */
  ahj: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `blueprint` relation. */
  blueprint: InputMaybe<BlueprintFilter>;
  /** A related `blueprint` exists. */
  blueprintExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintId` field. */
  blueprintId: InputMaybe<IntFilter>;
  /** Filter by the object’s `city` field. */
  city: InputMaybe<StringFilter>;
  /** Filter by the object’s `commentsByProjectId` relation. */
  commentsByProjectId: InputMaybe<ProjectToManyCommentFilter>;
  /** Some related `commentsByProjectId` exist. */
  commentsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completionDate` field. */
  completionDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `confidence` field. */
  confidence: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByContact` relation. */
  createdByContact: InputMaybe<ContactFilter>;
  /** A related `createdByContact` exists. */
  createdByContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dealSize` field. */
  dealSize: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealValue` field. */
  dealValue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealValueWeighted` field. */
  dealValueWeighted: InputMaybe<FloatFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `favoriteProjects` relation. */
  favoriteProjects: InputMaybe<ProjectToManyFavoriteProjectFilter>;
  /** Some related `favoriteProjects` exist. */
  favoriteProjectsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedsByProjectId` relation. */
  feedsByProjectId: InputMaybe<ProjectToManyFeedFilter>;
  /** Some related `feedsByProjectId` exist. */
  feedsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `freshProject` field. */
  freshProject: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `geoLocation` field. */
  geoLocation: InputMaybe<StringListFilter>;
  /** Filter by the object’s `geoLocationGeography` field. */
  geoLocationGeography: InputMaybe<GeographyInterfaceFilter>;
  /** Filter by the object’s `homeOwnerEmail` field. */
  homeOwnerEmail: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `imageUrl` field. */
  imageUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `importRowsByAccountId` relation. */
  importRowsByAccountId: InputMaybe<ProjectToManyImportRowFilter>;
  /** Some related `importRowsByAccountId` exist. */
  importRowsByAccountIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `importRowsByProjectId` relation. */
  importRowsByProjectId: InputMaybe<ProjectToManyImportRowFilter>;
  /** Some related `importRowsByProjectId` exist. */
  importRowsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `invoicesByProjectId` relation. */
  invoicesByProjectId: InputMaybe<ProjectToManyInvoiceFilter>;
  /** Some related `invoicesByProjectId` exist. */
  invoicesByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `isActive` field. */
  isActive: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jurisdiction` relation. */
  jurisdiction: InputMaybe<JurisdictionFilter>;
  /** A related `jurisdiction` exists. */
  jurisdictionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `jurisdictionId` field. */
  jurisdictionId: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastActivity` field. */
  lastActivity: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundCallDisposition` field. */
  lastInboundCallDisposition: InputMaybe<CallDispositionFilter>;
  /** Filter by the object’s `lastInboundCallTime` field. */
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundEmailTime` field. */
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundSmsTime` field. */
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundCallDisposition` field. */
  lastOutboundCallDisposition: InputMaybe<CallDispositionFilter>;
  /** Filter by the object’s `lastOutboundCallTime` field. */
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundEmailTime` field. */
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundSmsTime` field. */
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lineItemsByProjectId` relation. */
  lineItemsByProjectId: InputMaybe<ProjectToManyLineItemFilter>;
  /** Some related `lineItemsByProjectId` exist. */
  lineItemsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not: InputMaybe<ProjectFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `overdueBy` field. */
  overdueBy: InputMaybe<FloatFilter>;
  /** Filter by the object’s `owner` relation. */
  owner: InputMaybe<UserFilter>;
  /** A related `owner` exists. */
  ownerExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `ownerId` field. */
  ownerId: InputMaybe<IntFilter>;
  /** Filter by the object’s `parentProject` relation. */
  parentProject: InputMaybe<ProjectFilter>;
  /** A related `parentProject` exists. */
  parentProjectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentProjectId` field. */
  parentProjectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `phone` field. */
  phone: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryEmail` field. */
  primaryEmail: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryPhone` field. */
  primaryPhone: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegedTasksByProjectId` relation. */
  privilegedTasksByProjectId: InputMaybe<ProjectToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksByProjectId` exist. */
  privilegedTasksByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `progress` field. */
  progress: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectAddressesByProjectId` relation. */
  projectAddressesByProjectId: InputMaybe<ProjectToManyProjectAddressFilter>;
  /** Some related `projectAddressesByProjectId` exist. */
  projectAddressesByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectContactsIndirect` relation. */
  projectContactsIndirect: InputMaybe<ProjectToManyProjectContactFilter>;
  /** Some related `projectContactsIndirect` exist. */
  projectContactsIndirectExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectFiles` relation. */
  projectFiles: InputMaybe<ProjectToManyFileFilter>;
  /** Some related `projectFiles` exist. */
  projectFilesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectManager` relation. */
  projectManager: InputMaybe<UserFilter>;
  /** A related `projectManager` exists. */
  projectManagerExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectManagerId` field. */
  projectManagerId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectMembers` relation. */
  projectMembers: InputMaybe<ProjectToManyProjectMemberFilter>;
  /** Some related `projectMembers` exist. */
  projectMembersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertiesValues` relation. */
  projectPropertiesValues: InputMaybe<ProjectToManyProjectPropertyValueFilter>;
  /** Some related `projectPropertiesValues` exist. */
  projectPropertiesValuesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByProjectId` relation. */
  projectReportsByProjectId: InputMaybe<ProjectToManyProjectReportFilter>;
  /** Some related `projectReportsByProjectId` exist. */
  projectReportsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectSize` field. */
  projectSize: InputMaybe<FloatFilter>;
  /** Filter by the object’s `projectStageTimelinesByProjectId` relation. */
  projectStageTimelinesByProjectId: InputMaybe<ProjectToManyProjectStageTimelineFilter>;
  /** Some related `projectStageTimelinesByProjectId` exist. */
  projectStageTimelinesByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageUpdates` relation. */
  projectStageUpdates: InputMaybe<ProjectToManyProjectStageUpdateFilter>;
  /** Some related `projectStageUpdates` exist. */
  projectStageUpdatesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStatusTimeline` relation. */
  projectStatusTimeline: InputMaybe<ProjectToManyProjectStatusTimelineFilter>;
  /** Some related `projectStatusTimeline` exist. */
  projectStatusTimelineExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStatusUpdates` relation. */
  projectStatusUpdates: InputMaybe<ProjectToManyProjectStatusUpdateFilter>;
  /** Some related `projectStatusUpdates` exist. */
  projectStatusUpdatesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectTradesByProjectId` relation. */
  projectTradesByProjectId: InputMaybe<ProjectToManyProjectTradeFilter>;
  /** Some related `projectTradesByProjectId` exist. */
  projectTradesByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectValue` field. */
  projectValue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `projectsByParentProjectId` relation. */
  projectsByParentProjectId: InputMaybe<ProjectToManyProjectFilter>;
  /** Some related `projectsByParentProjectId` exist. */
  projectsByParentProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `referrerContact` relation. */
  referrerContact: InputMaybe<ContactFilter>;
  /** A related `referrerContact` exists. */
  referrerContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `remindersByProjectId` relation. */
  remindersByProjectId: InputMaybe<ProjectToManyReminderFilter>;
  /** Some related `remindersByProjectId` exist. */
  remindersByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `requestStatus` field. */
  requestStatus: InputMaybe<ProjectRequestStatusFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `salesRep` relation. */
  salesRep: InputMaybe<UserFilter>;
  /** A related `salesRep` exists. */
  salesRepExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `salesRepId` field. */
  salesRepId: InputMaybe<IntFilter>;
  /** Filter by the object’s `slaRedViolationJobId` field. */
  slaRedViolationJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `slaYellowViolationJobId` field. */
  slaYellowViolationJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source: InputMaybe<CreatedSourceFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ProjectStatusFilter>;
  /** Filter by the object’s `street` field. */
  street: InputMaybe<StringFilter>;
  /** Filter by the object’s `streetViewUrl` field. */
  streetViewUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `systemsByProjectId` relation. */
  systemsByProjectId: InputMaybe<ProjectToManySystemFilter>;
  /** Some related `systemsByProjectId` exist. */
  systemsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskReportsByProjectId` relation. */
  taskReportsByProjectId: InputMaybe<ProjectToManyTaskReportFilter>;
  /** Some related `taskReportsByProjectId` exist. */
  taskReportsByProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tasks` relation. */
  tasks: InputMaybe<ProjectToManyTaskFilter>;
  /** Some related `tasks` exist. */
  tasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalCost` field. */
  totalCost: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalInboundCallsCount` field. */
  totalInboundCallsCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalOutboundCallsCount` field. */
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPrice` field. */
  totalPrice: InputMaybe<FloatFilter>;
  /** Filter by the object’s `trades` field. */
  trades: InputMaybe<StringListFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `zipcode` field. */
  zipcode: InputMaybe<StringFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type ProjectFormsByFileProjectIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectFormsByFileProjectIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type ProjectFormsByFileProjectIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type ProjectFormsByFileProjectIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ProjectFormsByFileProjectIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ProjectFormsByFileProjectIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** Grouping methods for `Project` for usage during aggregation. */
export enum ProjectGroupBy {
  AccountStatus = 'ACCOUNT_STATUS',
  AccountType = 'ACCOUNT_TYPE',
  Address = 'ADDRESS',
  AddressNormalized = 'ADDRESS_NORMALIZED',
  AddressPretty = 'ADDRESS_PRETTY',
  Ahj = 'AHJ',
  BlueprintId = 'BLUEPRINT_ID',
  City = 'CITY',
  CompanyId = 'COMPANY_ID',
  CompletionDate = 'COMPLETION_DATE',
  CompletionDateTruncatedToDay = 'COMPLETION_DATE_TRUNCATED_TO_DAY',
  CompletionDateTruncatedToHour = 'COMPLETION_DATE_TRUNCATED_TO_HOUR',
  CompletionDateTruncatedToMonth = 'COMPLETION_DATE_TRUNCATED_TO_MONTH',
  CompletionDateTruncatedToQuarter = 'COMPLETION_DATE_TRUNCATED_TO_QUARTER',
  CompletionDateTruncatedToWeek = 'COMPLETION_DATE_TRUNCATED_TO_WEEK',
  CompletionDateTruncatedToYear = 'COMPLETION_DATE_TRUNCATED_TO_YEAR',
  CompletionTime = 'COMPLETION_TIME',
  CompletionTimeInS = 'COMPLETION_TIME_IN_S',
  Confidence = 'CONFIDENCE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedByContactId = 'CREATED_BY_CONTACT_ID',
  CreatedByType = 'CREATED_BY_TYPE',
  DealSize = 'DEAL_SIZE',
  DealValue = 'DEAL_VALUE',
  DealValueWeighted = 'DEAL_VALUE_WEIGHTED',
  Description = 'DESCRIPTION',
  FreshProject = 'FRESH_PROJECT',
  GeoLocation = 'GEO_LOCATION',
  GeoLocationGeography = 'GEO_LOCATION_GEOGRAPHY',
  GeoLocationNormalized = 'GEO_LOCATION_NORMALIZED',
  HomeOwnerEmail = 'HOME_OWNER_EMAIL',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  ImageUrl = 'IMAGE_URL',
  IsActive = 'IS_ACTIVE',
  IsCompleted = 'IS_COMPLETED',
  JurisdictionId = 'JURISDICTION_ID',
  LastActivity = 'LAST_ACTIVITY',
  LastActivityTruncatedToDay = 'LAST_ACTIVITY_TRUNCATED_TO_DAY',
  LastActivityTruncatedToHour = 'LAST_ACTIVITY_TRUNCATED_TO_HOUR',
  LastActivityTruncatedToMonth = 'LAST_ACTIVITY_TRUNCATED_TO_MONTH',
  LastActivityTruncatedToQuarter = 'LAST_ACTIVITY_TRUNCATED_TO_QUARTER',
  LastActivityTruncatedToWeek = 'LAST_ACTIVITY_TRUNCATED_TO_WEEK',
  LastActivityTruncatedToYear = 'LAST_ACTIVITY_TRUNCATED_TO_YEAR',
  LastInboundCallDisposition = 'LAST_INBOUND_CALL_DISPOSITION',
  LastInboundCallTime = 'LAST_INBOUND_CALL_TIME',
  LastInboundCallTimeTruncatedToDay = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_DAY',
  LastInboundCallTimeTruncatedToHour = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_HOUR',
  LastInboundCallTimeTruncatedToMonth = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_MONTH',
  LastInboundCallTimeTruncatedToQuarter = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_QUARTER',
  LastInboundCallTimeTruncatedToWeek = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_WEEK',
  LastInboundCallTimeTruncatedToYear = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_YEAR',
  LastInboundEmailTime = 'LAST_INBOUND_EMAIL_TIME',
  LastInboundEmailTimeTruncatedToDay = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_DAY',
  LastInboundEmailTimeTruncatedToHour = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_HOUR',
  LastInboundEmailTimeTruncatedToMonth = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_MONTH',
  LastInboundEmailTimeTruncatedToQuarter = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_QUARTER',
  LastInboundEmailTimeTruncatedToWeek = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_WEEK',
  LastInboundEmailTimeTruncatedToYear = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_YEAR',
  LastInboundSmsTime = 'LAST_INBOUND_SMS_TIME',
  LastInboundSmsTimeTruncatedToDay = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_DAY',
  LastInboundSmsTimeTruncatedToHour = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_HOUR',
  LastInboundSmsTimeTruncatedToMonth = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_MONTH',
  LastInboundSmsTimeTruncatedToQuarter = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_QUARTER',
  LastInboundSmsTimeTruncatedToWeek = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_WEEK',
  LastInboundSmsTimeTruncatedToYear = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_YEAR',
  LastOutboundCallDisposition = 'LAST_OUTBOUND_CALL_DISPOSITION',
  LastOutboundCallTime = 'LAST_OUTBOUND_CALL_TIME',
  LastOutboundCallTimeTruncatedToDay = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_DAY',
  LastOutboundCallTimeTruncatedToHour = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_HOUR',
  LastOutboundCallTimeTruncatedToMonth = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_MONTH',
  LastOutboundCallTimeTruncatedToQuarter = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundCallTimeTruncatedToWeek = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_WEEK',
  LastOutboundCallTimeTruncatedToYear = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_YEAR',
  LastOutboundEmailTime = 'LAST_OUTBOUND_EMAIL_TIME',
  LastOutboundEmailTimeTruncatedToDay = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_DAY',
  LastOutboundEmailTimeTruncatedToHour = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_HOUR',
  LastOutboundEmailTimeTruncatedToMonth = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_MONTH',
  LastOutboundEmailTimeTruncatedToQuarter = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundEmailTimeTruncatedToWeek = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_WEEK',
  LastOutboundEmailTimeTruncatedToYear = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_YEAR',
  LastOutboundSmsTime = 'LAST_OUTBOUND_SMS_TIME',
  LastOutboundSmsTimeTruncatedToDay = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_DAY',
  LastOutboundSmsTimeTruncatedToHour = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_HOUR',
  LastOutboundSmsTimeTruncatedToMonth = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_MONTH',
  LastOutboundSmsTimeTruncatedToQuarter = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundSmsTimeTruncatedToWeek = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_WEEK',
  LastOutboundSmsTimeTruncatedToYear = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_YEAR',
  OverdueBy = 'OVERDUE_BY',
  OwnerId = 'OWNER_ID',
  ParentProjectId = 'PARENT_PROJECT_ID',
  Phone = 'PHONE',
  PrimaryEmail = 'PRIMARY_EMAIL',
  PrimaryPhone = 'PRIMARY_PHONE',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  PrivilegeTeam = 'PRIVILEGE_TEAM',
  Progress = 'PROGRESS',
  ProjectManagerId = 'PROJECT_MANAGER_ID',
  ProjectSize = 'PROJECT_SIZE',
  ProjectValue = 'PROJECT_VALUE',
  ReferrerContactId = 'REFERRER_CONTACT_ID',
  RequestStatus = 'REQUEST_STATUS',
  Revenue = 'REVENUE',
  SalesRepId = 'SALES_REP_ID',
  SlaRedViolationJobId = 'SLA_RED_VIOLATION_JOB_ID',
  SlaYellowViolationJobId = 'SLA_YELLOW_VIOLATION_JOB_ID',
  Source = 'SOURCE',
  StageId = 'STAGE_ID',
  State = 'STATE',
  Status = 'STATUS',
  Street = 'STREET',
  StreetViewUrl = 'STREET_VIEW_URL',
  Title = 'TITLE',
  TotalCost = 'TOTAL_COST',
  TotalInboundCallsCount = 'TOTAL_INBOUND_CALLS_COUNT',
  TotalOutboundCallsCount = 'TOTAL_OUTBOUND_CALLS_COUNT',
  TotalPrice = 'TOTAL_PRICE',
  Trades = 'TRADES',
  Type = 'TYPE',
  Uid = 'UID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  Zipcode = 'ZIPCODE'
}

export type ProjectHavingArrayAggInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingAverageInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingDistinctCountInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Project` aggregates. */
export type ProjectHavingInput = {
  AND: InputMaybe<Array<ProjectHavingInput>>;
  OR: InputMaybe<Array<ProjectHavingInput>>;
  arrayAgg: InputMaybe<ProjectHavingArrayAggInput>;
  average: InputMaybe<ProjectHavingAverageInput>;
  distinctCount: InputMaybe<ProjectHavingDistinctCountInput>;
  max: InputMaybe<ProjectHavingMaxInput>;
  min: InputMaybe<ProjectHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectHavingStddevSampleInput>;
  sum: InputMaybe<ProjectHavingSumInput>;
  variancePopulation: InputMaybe<ProjectHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectHavingVarianceSampleInput>;
};

export type ProjectHavingMaxInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingMinInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingStddevPopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingStddevSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingSumInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingVariancePopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectHavingVarianceSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectJurisdictionsByProjectParentProjectIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectJurisdictionsByProjectReportProjectIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectMaxAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  jurisdictionId: InputMaybe<IntFilter>;
  lastActivity: InputMaybe<DatetimeFilter>;
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<IntFilter>;
  parentProjectId: InputMaybe<IntFilter>;
  progress: InputMaybe<IntFilter>;
  projectManagerId: InputMaybe<IntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<IntFilter>;
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectMaxAggregates = {
  /** Maximum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of confidence across the matching connection */
  confidence: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Maximum of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Maximum of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Maximum of ownerId across the matching connection */
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Maximum of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of progress across the matching connection */
  progress: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Maximum of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Maximum of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Maximum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectMaxAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectMember = Node & {
  /** Reads and enables pagination through a set of `Assignee`. */
  assignees: Array<Assignee>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `ProjectMember`. */
  createdByUser: Maybe<User>;
  /** Reads and enables pagination through a set of `FormAssignee`. */
  formAssignees: Array<FormAssignee>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFormAssigneeProjectMemberIdAndFormId: ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFormAssigneeProjectMemberIdAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `ProjectMember`. */
  member: Maybe<User>;
  memberId: Scalars['Int']['output'];
  memberType: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByAssigneeProjectMemberIdAndTaskId: ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `ProjectMember`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByAssigneeProjectMemberIdAndTaskId: ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyConnection;
  /** Reads a single `Team` that is related to this `ProjectMember`. */
  team: Maybe<Team>;
  teamId: Maybe<Scalars['Int']['output']>;
};


export type ProjectMemberAssigneesArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};


export type ProjectMemberFormAssigneesArgs = {
  condition: InputMaybe<FormAssigneeCondition>;
  filter: InputMaybe<FormAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormAssigneesOrderBy>>;
};


export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A filter to be used against aggregates of `ProjectMember` object types. */
export type ProjectMemberAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectMember` objects. */
  arrayAgg: InputMaybe<ProjectMemberArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectMember` objects. */
  average: InputMaybe<ProjectMemberAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectMember` objects. */
  distinctCount: InputMaybe<ProjectMemberDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectMember` object to be included within the aggregate. */
  filter: InputMaybe<ProjectMemberFilter>;
  /** Maximum aggregate over matching `ProjectMember` objects. */
  max: InputMaybe<ProjectMemberMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectMember` objects. */
  min: InputMaybe<ProjectMemberMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectMember` objects. */
  stddevPopulation: InputMaybe<ProjectMemberStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectMember` objects. */
  stddevSample: InputMaybe<ProjectMemberStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectMember` objects. */
  sum: InputMaybe<ProjectMemberSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectMember` objects. */
  variancePopulation: InputMaybe<ProjectMemberVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectMember` objects. */
  varianceSample: InputMaybe<ProjectMemberVarianceSampleAggregateFilter>;
};

export type ProjectMemberArrayAggAggregateFilter = {
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  memberId: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
};

export type ProjectMemberAverageAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  memberId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectMemberCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `memberType` field. */
  memberType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectMemberDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  memberId: InputMaybe<BigIntFilter>;
  memberType: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectMember` object types. All fields are combined with a logical ‘and.’ */
export type ProjectMemberFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectMemberFilter>>;
  /** Filter by the object’s `assignees` relation. */
  assignees: InputMaybe<ProjectMemberToManyAssigneeFilter>;
  /** Some related `assignees` exist. */
  assigneesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `formAssignees` relation. */
  formAssignees: InputMaybe<ProjectMemberToManyFormAssigneeFilter>;
  /** Some related `formAssignees` exist. */
  formAssigneesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `member` relation. */
  member: InputMaybe<UserFilter>;
  /** Filter by the object’s `memberId` field. */
  memberId: InputMaybe<IntFilter>;
  /** Filter by the object’s `memberType` field. */
  memberType: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectMemberFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectMemberFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** A related `team` exists. */
  teamExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
};

/** A connection to a list of `Form` values, with data from `FormAssignee`. */
export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `FormAssignee`, and the cursor to aid in pagination. */
  edges: Array<ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `FormAssignee`. */
export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `FormAssignee`. */
export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `FormAssignee`. */
  formAssignees: Array<FormAssignee>;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `FormAssignee`. */
export type ProjectMemberFormsByFormAssigneeProjectMemberIdAndFormIdManyToManyEdgeFormAssigneesArgs = {
  condition: InputMaybe<FormAssigneeCondition>;
  filter: InputMaybe<FormAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormAssigneesOrderBy>>;
};

export type ProjectMemberMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  memberId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

export type ProjectMemberMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  memberId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Assignee`. */
export type ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Assignee`, and the cursor to aid in pagination. */
  edges: Array<ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Assignee`. */
export type ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Assignee`. */
export type ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Assignee`. */
export type ProjectMemberPrivilegedTasksByAssigneeProjectMemberIdAndTaskIdManyToManyEdgeAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};

export type ProjectMemberStddevPopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  memberId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectMemberStddevSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  memberId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectMemberSumAggregateFilter = {
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  memberId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `TaskReport` values, with data from `Assignee`. */
export type ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskReportAggregates>;
  /** A list of edges which contains the `TaskReport`, info from the `Assignee`, and the cursor to aid in pagination. */
  edges: Array<ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskReportAggregates>>;
  /** A list of `TaskReport` objects. */
  nodes: Array<Maybe<TaskReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskReport` values, with data from `Assignee`. */
export type ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskReportGroupBy>;
  having: InputMaybe<TaskReportHavingInput>;
};

/** A `TaskReport` edge in the connection, with data from `Assignee`. */
export type ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskReport` at the end of the edge. */
  node: Maybe<TaskReport>;
};


/** A `TaskReport` edge in the connection, with data from `Assignee`. */
export type ProjectMemberTaskReportsByAssigneeProjectMemberIdAndTaskIdManyToManyEdgeAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};

/** A filter to be used against many `Assignee` object types. All fields are combined with a logical ‘and.’ */
export type ProjectMemberToManyAssigneeFilter = {
  /** Aggregates across related `Assignee` match the filter criteria. */
  aggregates: InputMaybe<AssigneeAggregatesFilter>;
  /** Every related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AssigneeFilter>;
  /** No related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AssigneeFilter>;
  /** Some related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AssigneeFilter>;
};

/** A filter to be used against many `FormAssignee` object types. All fields are combined with a logical ‘and.’ */
export type ProjectMemberToManyFormAssigneeFilter = {
  /** Aggregates across related `FormAssignee` match the filter criteria. */
  aggregates: InputMaybe<FormAssigneeAggregatesFilter>;
  /** Every related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormAssigneeFilter>;
  /** No related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormAssigneeFilter>;
  /** Some related `FormAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormAssigneeFilter>;
};

export type ProjectMemberVariancePopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  memberId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

export type ProjectMemberVarianceSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  memberId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ProjectMember`. */
export enum ProjectMembersOrderBy {
  AssigneesConnectionArrayAggProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionArrayAggProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionArrayAggTaskIdAsc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  AssigneesConnectionArrayAggTaskIdDesc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  AssigneesConnectionArrayAggUserIdAsc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  AssigneesConnectionArrayAggUserIdDesc = 'ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  AssigneesConnectionAverageProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionAverageProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionAverageTaskIdAsc = 'ASSIGNEES_CONNECTION_AVERAGE_TASK_ID_ASC',
  AssigneesConnectionAverageTaskIdDesc = 'ASSIGNEES_CONNECTION_AVERAGE_TASK_ID_DESC',
  AssigneesConnectionAverageUserIdAsc = 'ASSIGNEES_CONNECTION_AVERAGE_USER_ID_ASC',
  AssigneesConnectionAverageUserIdDesc = 'ASSIGNEES_CONNECTION_AVERAGE_USER_ID_DESC',
  AssigneesConnectionCountAsc = 'ASSIGNEES_CONNECTION_COUNT_ASC',
  AssigneesConnectionCountDesc = 'ASSIGNEES_CONNECTION_COUNT_DESC',
  AssigneesConnectionDistinctCountProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionDistinctCountProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionDistinctCountTaskIdAsc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  AssigneesConnectionDistinctCountTaskIdDesc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  AssigneesConnectionDistinctCountUserIdAsc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  AssigneesConnectionDistinctCountUserIdDesc = 'ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  AssigneesConnectionMaxProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionMaxProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionMaxTaskIdAsc = 'ASSIGNEES_CONNECTION_MAX_TASK_ID_ASC',
  AssigneesConnectionMaxTaskIdDesc = 'ASSIGNEES_CONNECTION_MAX_TASK_ID_DESC',
  AssigneesConnectionMaxUserIdAsc = 'ASSIGNEES_CONNECTION_MAX_USER_ID_ASC',
  AssigneesConnectionMaxUserIdDesc = 'ASSIGNEES_CONNECTION_MAX_USER_ID_DESC',
  AssigneesConnectionMinProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionMinProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionMinTaskIdAsc = 'ASSIGNEES_CONNECTION_MIN_TASK_ID_ASC',
  AssigneesConnectionMinTaskIdDesc = 'ASSIGNEES_CONNECTION_MIN_TASK_ID_DESC',
  AssigneesConnectionMinUserIdAsc = 'ASSIGNEES_CONNECTION_MIN_USER_ID_ASC',
  AssigneesConnectionMinUserIdDesc = 'ASSIGNEES_CONNECTION_MIN_USER_ID_DESC',
  AssigneesConnectionStddevPopulationProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionStddevPopulationProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionStddevPopulationTaskIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  AssigneesConnectionStddevPopulationTaskIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  AssigneesConnectionStddevPopulationUserIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  AssigneesConnectionStddevPopulationUserIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  AssigneesConnectionStddevSampleProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionStddevSampleProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionStddevSampleTaskIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  AssigneesConnectionStddevSampleTaskIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  AssigneesConnectionStddevSampleUserIdAsc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  AssigneesConnectionStddevSampleUserIdDesc = 'ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  AssigneesConnectionSumProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionSumProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionSumTaskIdAsc = 'ASSIGNEES_CONNECTION_SUM_TASK_ID_ASC',
  AssigneesConnectionSumTaskIdDesc = 'ASSIGNEES_CONNECTION_SUM_TASK_ID_DESC',
  AssigneesConnectionSumUserIdAsc = 'ASSIGNEES_CONNECTION_SUM_USER_ID_ASC',
  AssigneesConnectionSumUserIdDesc = 'ASSIGNEES_CONNECTION_SUM_USER_ID_DESC',
  AssigneesConnectionVariancePopulationProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionVariancePopulationProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionVariancePopulationTaskIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  AssigneesConnectionVariancePopulationTaskIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  AssigneesConnectionVariancePopulationUserIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  AssigneesConnectionVariancePopulationUserIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  AssigneesConnectionVarianceSampleProjectMemberIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesConnectionVarianceSampleProjectMemberIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesConnectionVarianceSampleTaskIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  AssigneesConnectionVarianceSampleTaskIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  AssigneesConnectionVarianceSampleUserIdAsc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  AssigneesConnectionVarianceSampleUserIdDesc = 'ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FormAssigneesConnectionArrayAggFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FormAssigneesConnectionArrayAggFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FormAssigneesConnectionArrayAggProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionArrayAggProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionArrayAggUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  FormAssigneesConnectionArrayAggUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  FormAssigneesConnectionAverageFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_FORM_ID_ASC',
  FormAssigneesConnectionAverageFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_FORM_ID_DESC',
  FormAssigneesConnectionAverageProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionAverageProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionAverageUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_USER_ID_ASC',
  FormAssigneesConnectionAverageUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_AVERAGE_USER_ID_DESC',
  FormAssigneesConnectionCountAsc = 'FORM_ASSIGNEES_CONNECTION_COUNT_ASC',
  FormAssigneesConnectionCountDesc = 'FORM_ASSIGNEES_CONNECTION_COUNT_DESC',
  FormAssigneesConnectionDistinctCountFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FormAssigneesConnectionDistinctCountFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FormAssigneesConnectionDistinctCountProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionDistinctCountProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionDistinctCountUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  FormAssigneesConnectionDistinctCountUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  FormAssigneesConnectionMaxFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_FORM_ID_ASC',
  FormAssigneesConnectionMaxFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_FORM_ID_DESC',
  FormAssigneesConnectionMaxProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionMaxProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionMaxUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_MAX_USER_ID_ASC',
  FormAssigneesConnectionMaxUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_MAX_USER_ID_DESC',
  FormAssigneesConnectionMinFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_FORM_ID_ASC',
  FormAssigneesConnectionMinFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_FORM_ID_DESC',
  FormAssigneesConnectionMinProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionMinProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionMinUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_MIN_USER_ID_ASC',
  FormAssigneesConnectionMinUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_MIN_USER_ID_DESC',
  FormAssigneesConnectionStddevPopulationFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FormAssigneesConnectionStddevPopulationFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FormAssigneesConnectionStddevPopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionStddevPopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionStddevPopulationUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  FormAssigneesConnectionStddevPopulationUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  FormAssigneesConnectionStddevSampleFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FormAssigneesConnectionStddevSampleFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FormAssigneesConnectionStddevSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionStddevSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionStddevSampleUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  FormAssigneesConnectionStddevSampleUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  FormAssigneesConnectionSumFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_FORM_ID_ASC',
  FormAssigneesConnectionSumFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_FORM_ID_DESC',
  FormAssigneesConnectionSumProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionSumProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionSumUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_SUM_USER_ID_ASC',
  FormAssigneesConnectionSumUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_SUM_USER_ID_DESC',
  FormAssigneesConnectionVariancePopulationFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FormAssigneesConnectionVariancePopulationFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FormAssigneesConnectionVariancePopulationProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionVariancePopulationProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionVariancePopulationUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  FormAssigneesConnectionVariancePopulationUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  FormAssigneesConnectionVarianceSampleFormIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FormAssigneesConnectionVarianceSampleFormIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FormAssigneesConnectionVarianceSampleProjectMemberIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  FormAssigneesConnectionVarianceSampleProjectMemberIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  FormAssigneesConnectionVarianceSampleUserIdAsc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  FormAssigneesConnectionVarianceSampleUserIdDesc = 'FORM_ASSIGNEES_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MemberIdAsc = 'MEMBER_ID_ASC',
  MemberIdDesc = 'MEMBER_ID_DESC',
  MemberTypeAsc = 'MEMBER_TYPE_ASC',
  MemberTypeDesc = 'MEMBER_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export type ProjectMinAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  jurisdictionId: InputMaybe<IntFilter>;
  lastActivity: InputMaybe<DatetimeFilter>;
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<IntFilter>;
  parentProjectId: InputMaybe<IntFilter>;
  progress: InputMaybe<IntFilter>;
  projectManagerId: InputMaybe<IntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<IntFilter>;
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectMinAggregates = {
  /** Minimum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of confidence across the matching connection */
  confidence: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Minimum of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Minimum of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Minimum of ownerId across the matching connection */
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Minimum of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of progress across the matching connection */
  progress: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Minimum of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Minimum of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Minimum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectMinAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByTaskIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type ProjectPrivilegedTasksByCommentProjectIdAndTaskIdManyToManyEdgeCommentsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ProjectPrivilegedTasksByFeedProjectIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectPrivilegedTasksByPrivilegedTaskProjectIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectPrivilegedTasksByTaskReportProjectIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `ProjectContact`. */
export type ProjectProjectContactsConnectionManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `ProjectContact`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectContactsConnectionManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `ProjectContact`. */
export type ProjectProjectContactsConnectionManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `ProjectContact`. */
export type ProjectProjectContactsConnectionManyToManyEdge = {
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  position: Scalars['Int']['output'];
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectPropertyValue`. */
export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectPropertyValue`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectPropertyValue`. */
export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValues: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValuesConnection: ProjectPropertyValuesConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdgeProjectPropertiesValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectProjectReportsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdgeProjectPropertiesValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectProjectReportsByProjectReportProjectIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectStageUpdate`. */
export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectStageUpdate`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectStageUpdate`. */
export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdates: Array<ProjectStageUpdate>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesConnection: ProjectStageUpdatesConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdgeProjectStageUpdatesArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectProjectReportsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdgeProjectStageUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectStatusTimeline`. */
export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectStatusTimeline`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectStatusTimeline`. */
export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimeline: Array<ProjectStatusTimeline>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimelineConnection: ProjectStatusTimelinesConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdgeProjectStatusTimelineArgs = {
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectProjectReportsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdgeProjectStatusTimelineConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectStatusUpdate`. */
export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectStatusUpdate`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectStatusUpdate`. */
export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdates: Array<ProjectStatusUpdate>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdatesConnection: ProjectStatusUpdatesConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdgeProjectStatusUpdatesArgs = {
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectProjectReportsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdgeProjectStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ImportRow`. */
export type ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ImportRow`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ImportRow`. */
export type ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByProjectId: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByProjectIdConnection: ImportRowsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyEdgeImportRowsByProjectIdArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowAccountIdAndProjectIdManyToManyEdgeImportRowsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ImportRow`. */
export type ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ImportRow`, and the cursor to aid in pagination. */
  edges: Array<ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ImportRow`. */
export type ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByAccountId: Array<ImportRow>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsByAccountIdConnection: ImportRowsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyEdgeImportRowsByAccountIdArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ImportRow`. */
export type ProjectProjectsByImportRowProjectIdAndAccountIdManyToManyEdgeImportRowsByAccountIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};

/** Methods to use when ordering `ProjectProperty`. */
export enum ProjectPropertiesOrderBy {
  AdditionalAsc = 'ADDITIONAL_ASC',
  AdditionalDesc = 'ADDITIONAL_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionArrayAggUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionArrayAggUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionAverageColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionAverageColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionAverageCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionAverageCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionAverageCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionAverageCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionAverageGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionAverageGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionAverageIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ColumnToGroupsByColumnIdConnectionAverageIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ColumnToGroupsByColumnIdConnectionAveragePositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionAveragePositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionAverageScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionAverageScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionAverageUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionAverageUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionCountAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_COUNT_ASC',
  ColumnToGroupsByColumnIdConnectionCountDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_COUNT_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionDistinctCountUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionDistinctCountUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionMaxColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMaxColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMaxCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMaxCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMaxCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionMaxCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionMaxGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMaxGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMaxIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMaxIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMaxPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionMaxPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionMaxScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionMaxScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionMaxUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionMaxUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionMinColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMinColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMinCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMinCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMinCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionMinCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionMinGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMinGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMinIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionMinIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionMinPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionMinPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionMinScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionMinScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionMinUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionMinUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionStddevPopulationUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSamplePositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSamplePositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionStddevSampleUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionStddevSampleUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionSumColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionSumColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionSumCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionSumCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionSumCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionSumCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionSumGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionSumGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionSumIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ColumnToGroupsByColumnIdConnectionSumIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ColumnToGroupsByColumnIdConnectionSumPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionSumPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionSumScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionSumScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionSumUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionSumUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationPositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationPositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionVariancePopulationUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleColumnIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleColumnIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleCompanyIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleCompanyIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleCreatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleCreatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleGroupIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleGroupIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleIdAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleIdDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSamplePositionAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSamplePositionDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleScopeAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleScopeDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleUpdatedAtAsc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ColumnToGroupsByColumnIdConnectionVarianceSampleUpdatedAtDesc = 'COLUMN_TO_GROUPS_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DisplayValuePathAsc = 'DISPLAY_VALUE_PATH_ASC',
  DisplayValuePathDesc = 'DISPLAY_VALUE_PATH_DESC',
  ExternalVisibilityAsc = 'EXTERNAL_VISIBILITY_ASC',
  ExternalVisibilityDesc = 'EXTERNAL_VISIBILITY_DESC',
  IconUrlAsc = 'ICON_URL_ASC',
  IconUrlDesc = 'ICON_URL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionArrayAggUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionAverageUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionAverageUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionCountAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_COUNT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionCountDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_COUNT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionDistinctCountUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMaxUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMaxUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionMinUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionMinUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionStddevSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionSumUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionSumUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleFileNameAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleFileNameDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleImportDataAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleImportDataDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleJobIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleJobIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleStatusAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleStatusDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTypeAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleTypeDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByGroupByColumnIdConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_GROUP_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionArrayAggUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionAverageUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionAverageUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionCountAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_COUNT_ASC',
  ImportHistoriesByMatchColumnIdConnectionCountDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_COUNT_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionDistinctCountUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionMaxUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMaxUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionMinUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionMinUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionStddevSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionSumUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionSumUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCompanyIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCompanyIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedByAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedByDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedColumnsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleCreatedColumnsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_COLUMNS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleEndedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleEndedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleErrorMessageAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleErrorMessageDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleErrorStackAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleErrorStackDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleFileNameAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleFileNameDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_NAME_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleGroupedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleGroupedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleGroupByColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleGroupByColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_GROUP_BY_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleImportDataAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleImportDataDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleJobIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleJobIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchColumnIdAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchColumnIdDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_COLUMN_ID_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchNotFoundStrategyAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleMatchNotFoundStrategyDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_MATCH_NOT_FOUND_STRATEGY_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleStatusAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleStatusDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTotalRowsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTotalRowsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_ROWS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTransformedProjectsAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTransformedProjectsDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TRANSFORMED_PROJECTS_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTypeAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleTypeDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportHistoriesByMatchColumnIdConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_HISTORIES_BY_MATCH_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  InternalVisibilityAsc = 'INTERNAL_VISIBILITY_ASC',
  InternalVisibilityDesc = 'INTERNAL_VISIBILITY_DESC',
  IsAdditionalAsc = 'IS_ADDITIONAL_ASC',
  IsAdditionalDesc = 'IS_ADDITIONAL_DESC',
  IsNewRecordAsc = 'IS_NEW_RECORD_ASC',
  IsNewRecordDesc = 'IS_NEW_RECORD_DESC',
  IsReadOnlyAsc = 'IS_READ_ONLY_ASC',
  IsReadOnlyDesc = 'IS_READ_ONLY_DESC',
  IsRequiredAsc = 'IS_REQUIRED_ASC',
  IsRequiredDesc = 'IS_REQUIRED_DESC',
  KeyNameAsc = 'KEY_NAME_ASC',
  KeyNameDesc = 'KEY_NAME_DESC',
  MappedNameAsc = 'MAPPED_NAME_ASC',
  MappedNameDesc = 'MAPPED_NAME_DESC',
  MultipleAsc = 'MULTIPLE_ASC',
  MultipleDesc = 'MULTIPLE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  ObjectNameAsc = 'OBJECT_NAME_ASC',
  ObjectNameDesc = 'OBJECT_NAME_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionArrayAggTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionAverageColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionAverageColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionAverageIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionAverageIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionAverageRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionAverageRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionAverageTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionAverageTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionAverageTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionAverageTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionCountAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_COUNT_ASC',
  ProjectColumnAccessesByColumnIdConnectionCountDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_COUNT_DESC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionDistinctCountTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionMaxColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMaxColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMaxIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMaxIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMaxRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMaxRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMaxTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMaxTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMaxTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionMaxTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionMinColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMinColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMinIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMinIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMinRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMinRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMinTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionMinTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionMinTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionMinTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevPopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionStddevSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionSumColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionSumColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionSumIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionSumIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionSumRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionSumRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionSumTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionSumTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionSumTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionSumTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionVariancePopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesByColumnIdConnectionVarianceSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectColumnIdAsc = 'PROJECT_COLUMN_ID_ASC',
  ProjectColumnIdDesc = 'PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionArrayAggAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  ProjectPropertiesConnectionArrayAggAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  ProjectPropertiesConnectionArrayAggCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectPropertiesConnectionArrayAggCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectPropertiesConnectionArrayAggCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectPropertiesConnectionArrayAggCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectPropertiesConnectionArrayAggCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectPropertiesConnectionArrayAggCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectPropertiesConnectionArrayAggDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionArrayAggDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionArrayAggExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionArrayAggExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionArrayAggIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ICON_URL_ASC',
  ProjectPropertiesConnectionArrayAggIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ICON_URL_DESC',
  ProjectPropertiesConnectionArrayAggIdAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertiesConnectionArrayAggIdDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertiesConnectionArrayAggInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionArrayAggInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionArrayAggIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionArrayAggIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionArrayAggIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionArrayAggIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionArrayAggIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionArrayAggIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionArrayAggIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionArrayAggIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionArrayAggKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_KEY_NAME_ASC',
  ProjectPropertiesConnectionArrayAggKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_KEY_NAME_DESC',
  ProjectPropertiesConnectionArrayAggMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionArrayAggMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionArrayAggMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_MULTIPLE_ASC',
  ProjectPropertiesConnectionArrayAggMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_MULTIPLE_DESC',
  ProjectPropertiesConnectionArrayAggNameAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_NAME_ASC',
  ProjectPropertiesConnectionArrayAggNameDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_NAME_DESC',
  ProjectPropertiesConnectionArrayAggNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_NOTES_ASC',
  ProjectPropertiesConnectionArrayAggNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_NOTES_DESC',
  ProjectPropertiesConnectionArrayAggObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionArrayAggObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionArrayAggPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ProjectPropertiesConnectionArrayAggPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ProjectPropertiesConnectionArrayAggProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionArrayAggProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionArrayAggScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ProjectPropertiesConnectionArrayAggScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ProjectPropertiesConnectionArrayAggSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SHARED_ASC',
  ProjectPropertiesConnectionArrayAggSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SHARED_DESC',
  ProjectPropertiesConnectionArrayAggSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionArrayAggSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionArrayAggTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionArrayAggTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionArrayAggTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectPropertiesConnectionArrayAggTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectPropertiesConnectionArrayAggUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectPropertiesConnectionArrayAggUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectPropertiesConnectionArrayAggVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_VIRTUAL_ASC',
  ProjectPropertiesConnectionArrayAggVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_VIRTUAL_DESC',
  ProjectPropertiesConnectionArrayAggVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionArrayAggVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_ARRAY_AGG_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionAverageAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  ProjectPropertiesConnectionAverageAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  ProjectPropertiesConnectionAverageCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectPropertiesConnectionAverageCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectPropertiesConnectionAverageCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectPropertiesConnectionAverageCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectPropertiesConnectionAverageCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectPropertiesConnectionAverageCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectPropertiesConnectionAverageDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionAverageDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionAverageExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionAverageExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionAverageIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ICON_URL_ASC',
  ProjectPropertiesConnectionAverageIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ICON_URL_DESC',
  ProjectPropertiesConnectionAverageIdAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertiesConnectionAverageIdDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertiesConnectionAverageInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionAverageInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionAverageIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionAverageIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionAverageIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionAverageIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionAverageIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionAverageIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionAverageIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionAverageIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionAverageKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_KEY_NAME_ASC',
  ProjectPropertiesConnectionAverageKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_KEY_NAME_DESC',
  ProjectPropertiesConnectionAverageMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionAverageMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionAverageMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_MULTIPLE_ASC',
  ProjectPropertiesConnectionAverageMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_MULTIPLE_DESC',
  ProjectPropertiesConnectionAverageNameAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_NAME_ASC',
  ProjectPropertiesConnectionAverageNameDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_NAME_DESC',
  ProjectPropertiesConnectionAverageNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_NOTES_ASC',
  ProjectPropertiesConnectionAverageNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_NOTES_DESC',
  ProjectPropertiesConnectionAverageObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionAverageObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionAveragePositionAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_POSITION_ASC',
  ProjectPropertiesConnectionAveragePositionDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_POSITION_DESC',
  ProjectPropertiesConnectionAverageProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionAverageProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionAverageScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SCOPE_ASC',
  ProjectPropertiesConnectionAverageScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SCOPE_DESC',
  ProjectPropertiesConnectionAverageSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SHARED_ASC',
  ProjectPropertiesConnectionAverageSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SHARED_DESC',
  ProjectPropertiesConnectionAverageSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionAverageSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionAverageTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionAverageTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionAverageTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectPropertiesConnectionAverageTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectPropertiesConnectionAverageUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectPropertiesConnectionAverageUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectPropertiesConnectionAverageVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_VIRTUAL_ASC',
  ProjectPropertiesConnectionAverageVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_VIRTUAL_DESC',
  ProjectPropertiesConnectionAverageVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionAverageVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_AVERAGE_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionCountAsc = 'PROJECT_PROPERTIES_CONNECTION_COUNT_ASC',
  ProjectPropertiesConnectionCountDesc = 'PROJECT_PROPERTIES_CONNECTION_COUNT_DESC',
  ProjectPropertiesConnectionDistinctCountAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  ProjectPropertiesConnectionDistinctCountAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  ProjectPropertiesConnectionDistinctCountCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectPropertiesConnectionDistinctCountCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectPropertiesConnectionDistinctCountCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectPropertiesConnectionDistinctCountCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectPropertiesConnectionDistinctCountCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectPropertiesConnectionDistinctCountCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectPropertiesConnectionDistinctCountDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionDistinctCountDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionDistinctCountExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionDistinctCountExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionDistinctCountIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ICON_URL_ASC',
  ProjectPropertiesConnectionDistinctCountIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ICON_URL_DESC',
  ProjectPropertiesConnectionDistinctCountIdAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertiesConnectionDistinctCountIdDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertiesConnectionDistinctCountInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionDistinctCountInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionDistinctCountIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionDistinctCountIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionDistinctCountIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionDistinctCountIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionDistinctCountIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionDistinctCountIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionDistinctCountIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionDistinctCountIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionDistinctCountKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_KEY_NAME_ASC',
  ProjectPropertiesConnectionDistinctCountKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_KEY_NAME_DESC',
  ProjectPropertiesConnectionDistinctCountMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionDistinctCountMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionDistinctCountMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_MULTIPLE_ASC',
  ProjectPropertiesConnectionDistinctCountMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_MULTIPLE_DESC',
  ProjectPropertiesConnectionDistinctCountNameAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ProjectPropertiesConnectionDistinctCountNameDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ProjectPropertiesConnectionDistinctCountNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  ProjectPropertiesConnectionDistinctCountNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  ProjectPropertiesConnectionDistinctCountObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionDistinctCountObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionDistinctCountPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ProjectPropertiesConnectionDistinctCountPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ProjectPropertiesConnectionDistinctCountProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionDistinctCountProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionDistinctCountScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ProjectPropertiesConnectionDistinctCountScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ProjectPropertiesConnectionDistinctCountSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SHARED_ASC',
  ProjectPropertiesConnectionDistinctCountSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SHARED_DESC',
  ProjectPropertiesConnectionDistinctCountSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionDistinctCountSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionDistinctCountTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionDistinctCountTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionDistinctCountTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectPropertiesConnectionDistinctCountTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectPropertiesConnectionDistinctCountUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectPropertiesConnectionDistinctCountUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectPropertiesConnectionDistinctCountVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_VIRTUAL_ASC',
  ProjectPropertiesConnectionDistinctCountVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_VIRTUAL_DESC',
  ProjectPropertiesConnectionDistinctCountVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionDistinctCountVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_DISTINCT_COUNT_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionMaxAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ADDITIONAL_ASC',
  ProjectPropertiesConnectionMaxAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ADDITIONAL_DESC',
  ProjectPropertiesConnectionMaxCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectPropertiesConnectionMaxCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectPropertiesConnectionMaxCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectPropertiesConnectionMaxCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectPropertiesConnectionMaxCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectPropertiesConnectionMaxCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectPropertiesConnectionMaxDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionMaxDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionMaxExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionMaxExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionMaxIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ICON_URL_ASC',
  ProjectPropertiesConnectionMaxIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ICON_URL_DESC',
  ProjectPropertiesConnectionMaxIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ID_ASC',
  ProjectPropertiesConnectionMaxIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_ID_DESC',
  ProjectPropertiesConnectionMaxInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionMaxInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionMaxIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionMaxIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionMaxIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionMaxIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionMaxIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionMaxIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionMaxIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionMaxIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionMaxKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_KEY_NAME_ASC',
  ProjectPropertiesConnectionMaxKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_KEY_NAME_DESC',
  ProjectPropertiesConnectionMaxMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionMaxMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionMaxMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_MULTIPLE_ASC',
  ProjectPropertiesConnectionMaxMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_MULTIPLE_DESC',
  ProjectPropertiesConnectionMaxNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_NAME_ASC',
  ProjectPropertiesConnectionMaxNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_NAME_DESC',
  ProjectPropertiesConnectionMaxNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_NOTES_ASC',
  ProjectPropertiesConnectionMaxNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_NOTES_DESC',
  ProjectPropertiesConnectionMaxObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionMaxObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionMaxPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_POSITION_ASC',
  ProjectPropertiesConnectionMaxPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_POSITION_DESC',
  ProjectPropertiesConnectionMaxProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionMaxProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionMaxScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SCOPE_ASC',
  ProjectPropertiesConnectionMaxScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SCOPE_DESC',
  ProjectPropertiesConnectionMaxSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SHARED_ASC',
  ProjectPropertiesConnectionMaxSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SHARED_DESC',
  ProjectPropertiesConnectionMaxSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionMaxSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionMaxTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionMaxTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionMaxTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_TYPE_ASC',
  ProjectPropertiesConnectionMaxTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_TYPE_DESC',
  ProjectPropertiesConnectionMaxUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectPropertiesConnectionMaxUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectPropertiesConnectionMaxVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_VIRTUAL_ASC',
  ProjectPropertiesConnectionMaxVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_VIRTUAL_DESC',
  ProjectPropertiesConnectionMaxVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MAX_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionMaxVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MAX_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionMinAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ADDITIONAL_ASC',
  ProjectPropertiesConnectionMinAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ADDITIONAL_DESC',
  ProjectPropertiesConnectionMinCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectPropertiesConnectionMinCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectPropertiesConnectionMinCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectPropertiesConnectionMinCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectPropertiesConnectionMinCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectPropertiesConnectionMinCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectPropertiesConnectionMinDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionMinDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionMinExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionMinExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionMinIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ICON_URL_ASC',
  ProjectPropertiesConnectionMinIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ICON_URL_DESC',
  ProjectPropertiesConnectionMinIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ID_ASC',
  ProjectPropertiesConnectionMinIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_ID_DESC',
  ProjectPropertiesConnectionMinInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionMinInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionMinIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionMinIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionMinIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionMinIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionMinIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionMinIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionMinIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionMinIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionMinKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_KEY_NAME_ASC',
  ProjectPropertiesConnectionMinKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_KEY_NAME_DESC',
  ProjectPropertiesConnectionMinMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionMinMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionMinMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_MULTIPLE_ASC',
  ProjectPropertiesConnectionMinMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_MULTIPLE_DESC',
  ProjectPropertiesConnectionMinNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_NAME_ASC',
  ProjectPropertiesConnectionMinNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_NAME_DESC',
  ProjectPropertiesConnectionMinNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_NOTES_ASC',
  ProjectPropertiesConnectionMinNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_NOTES_DESC',
  ProjectPropertiesConnectionMinObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionMinObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionMinPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_POSITION_ASC',
  ProjectPropertiesConnectionMinPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_POSITION_DESC',
  ProjectPropertiesConnectionMinProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionMinProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionMinScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SCOPE_ASC',
  ProjectPropertiesConnectionMinScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SCOPE_DESC',
  ProjectPropertiesConnectionMinSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SHARED_ASC',
  ProjectPropertiesConnectionMinSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SHARED_DESC',
  ProjectPropertiesConnectionMinSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionMinSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionMinTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionMinTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionMinTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_TYPE_ASC',
  ProjectPropertiesConnectionMinTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_TYPE_DESC',
  ProjectPropertiesConnectionMinUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectPropertiesConnectionMinUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectPropertiesConnectionMinVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_VIRTUAL_ASC',
  ProjectPropertiesConnectionMinVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_VIRTUAL_DESC',
  ProjectPropertiesConnectionMinVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_MIN_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionMinVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_MIN_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionStddevPopulationAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  ProjectPropertiesConnectionStddevPopulationAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  ProjectPropertiesConnectionStddevPopulationCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectPropertiesConnectionStddevPopulationCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectPropertiesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectPropertiesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectPropertiesConnectionStddevPopulationCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectPropertiesConnectionStddevPopulationCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectPropertiesConnectionStddevPopulationDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionStddevPopulationDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionStddevPopulationExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionStddevPopulationExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionStddevPopulationIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ICON_URL_ASC',
  ProjectPropertiesConnectionStddevPopulationIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ICON_URL_DESC',
  ProjectPropertiesConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertiesConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertiesConnectionStddevPopulationInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionStddevPopulationInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionStddevPopulationIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionStddevPopulationIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionStddevPopulationIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionStddevPopulationIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionStddevPopulationIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionStddevPopulationIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionStddevPopulationIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionStddevPopulationIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionStddevPopulationKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_KEY_NAME_ASC',
  ProjectPropertiesConnectionStddevPopulationKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_KEY_NAME_DESC',
  ProjectPropertiesConnectionStddevPopulationMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionStddevPopulationMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionStddevPopulationMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_MULTIPLE_ASC',
  ProjectPropertiesConnectionStddevPopulationMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_MULTIPLE_DESC',
  ProjectPropertiesConnectionStddevPopulationNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ProjectPropertiesConnectionStddevPopulationNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ProjectPropertiesConnectionStddevPopulationNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  ProjectPropertiesConnectionStddevPopulationNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  ProjectPropertiesConnectionStddevPopulationObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionStddevPopulationObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionStddevPopulationPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ProjectPropertiesConnectionStddevPopulationPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ProjectPropertiesConnectionStddevPopulationProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionStddevPopulationProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionStddevPopulationScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ProjectPropertiesConnectionStddevPopulationScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ProjectPropertiesConnectionStddevPopulationSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SHARED_ASC',
  ProjectPropertiesConnectionStddevPopulationSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SHARED_DESC',
  ProjectPropertiesConnectionStddevPopulationSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionStddevPopulationSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionStddevPopulationTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionStddevPopulationTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionStddevPopulationTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectPropertiesConnectionStddevPopulationTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectPropertiesConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectPropertiesConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectPropertiesConnectionStddevPopulationVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_VIRTUAL_ASC',
  ProjectPropertiesConnectionStddevPopulationVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_VIRTUAL_DESC',
  ProjectPropertiesConnectionStddevPopulationVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionStddevPopulationVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_POPULATION_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionStddevSampleAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  ProjectPropertiesConnectionStddevSampleAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  ProjectPropertiesConnectionStddevSampleCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectPropertiesConnectionStddevSampleCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectPropertiesConnectionStddevSampleCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectPropertiesConnectionStddevSampleCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectPropertiesConnectionStddevSampleCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectPropertiesConnectionStddevSampleCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectPropertiesConnectionStddevSampleDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionStddevSampleDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionStddevSampleExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionStddevSampleExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionStddevSampleIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ICON_URL_ASC',
  ProjectPropertiesConnectionStddevSampleIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ICON_URL_DESC',
  ProjectPropertiesConnectionStddevSampleIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertiesConnectionStddevSampleIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertiesConnectionStddevSampleInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionStddevSampleInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionStddevSampleIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionStddevSampleIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionStddevSampleIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionStddevSampleIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionStddevSampleIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionStddevSampleIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionStddevSampleIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionStddevSampleIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionStddevSampleKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_KEY_NAME_ASC',
  ProjectPropertiesConnectionStddevSampleKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_KEY_NAME_DESC',
  ProjectPropertiesConnectionStddevSampleMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionStddevSampleMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionStddevSampleMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_MULTIPLE_ASC',
  ProjectPropertiesConnectionStddevSampleMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_MULTIPLE_DESC',
  ProjectPropertiesConnectionStddevSampleNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ProjectPropertiesConnectionStddevSampleNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ProjectPropertiesConnectionStddevSampleNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  ProjectPropertiesConnectionStddevSampleNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  ProjectPropertiesConnectionStddevSampleObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionStddevSampleObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionStddevSamplePositionAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ProjectPropertiesConnectionStddevSamplePositionDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ProjectPropertiesConnectionStddevSampleProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionStddevSampleProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionStddevSampleScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ProjectPropertiesConnectionStddevSampleScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ProjectPropertiesConnectionStddevSampleSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SHARED_ASC',
  ProjectPropertiesConnectionStddevSampleSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SHARED_DESC',
  ProjectPropertiesConnectionStddevSampleSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionStddevSampleSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionStddevSampleTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionStddevSampleTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionStddevSampleTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectPropertiesConnectionStddevSampleTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectPropertiesConnectionStddevSampleUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectPropertiesConnectionStddevSampleUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectPropertiesConnectionStddevSampleVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_VIRTUAL_ASC',
  ProjectPropertiesConnectionStddevSampleVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_VIRTUAL_DESC',
  ProjectPropertiesConnectionStddevSampleVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionStddevSampleVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_STDDEV_SAMPLE_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionSumAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ADDITIONAL_ASC',
  ProjectPropertiesConnectionSumAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ADDITIONAL_DESC',
  ProjectPropertiesConnectionSumCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectPropertiesConnectionSumCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectPropertiesConnectionSumCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectPropertiesConnectionSumCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectPropertiesConnectionSumCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectPropertiesConnectionSumCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectPropertiesConnectionSumDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionSumDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionSumExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionSumExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionSumIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ICON_URL_ASC',
  ProjectPropertiesConnectionSumIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ICON_URL_DESC',
  ProjectPropertiesConnectionSumIdAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ID_ASC',
  ProjectPropertiesConnectionSumIdDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_ID_DESC',
  ProjectPropertiesConnectionSumInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionSumInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionSumIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionSumIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionSumIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionSumIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionSumIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionSumIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionSumIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionSumIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionSumKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_KEY_NAME_ASC',
  ProjectPropertiesConnectionSumKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_KEY_NAME_DESC',
  ProjectPropertiesConnectionSumMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionSumMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionSumMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_MULTIPLE_ASC',
  ProjectPropertiesConnectionSumMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_MULTIPLE_DESC',
  ProjectPropertiesConnectionSumNameAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_NAME_ASC',
  ProjectPropertiesConnectionSumNameDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_NAME_DESC',
  ProjectPropertiesConnectionSumNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_NOTES_ASC',
  ProjectPropertiesConnectionSumNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_NOTES_DESC',
  ProjectPropertiesConnectionSumObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionSumObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionSumPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_POSITION_ASC',
  ProjectPropertiesConnectionSumPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_POSITION_DESC',
  ProjectPropertiesConnectionSumProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionSumProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionSumScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SCOPE_ASC',
  ProjectPropertiesConnectionSumScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SCOPE_DESC',
  ProjectPropertiesConnectionSumSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SHARED_ASC',
  ProjectPropertiesConnectionSumSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SHARED_DESC',
  ProjectPropertiesConnectionSumSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionSumSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionSumTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionSumTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionSumTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_TYPE_ASC',
  ProjectPropertiesConnectionSumTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_TYPE_DESC',
  ProjectPropertiesConnectionSumUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectPropertiesConnectionSumUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectPropertiesConnectionSumVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_VIRTUAL_ASC',
  ProjectPropertiesConnectionSumVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_VIRTUAL_DESC',
  ProjectPropertiesConnectionSumVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_SUM_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionSumVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_SUM_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionVariancePopulationAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  ProjectPropertiesConnectionVariancePopulationAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  ProjectPropertiesConnectionVariancePopulationCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectPropertiesConnectionVariancePopulationCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectPropertiesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectPropertiesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectPropertiesConnectionVariancePopulationCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectPropertiesConnectionVariancePopulationCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectPropertiesConnectionVariancePopulationDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionVariancePopulationDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionVariancePopulationExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionVariancePopulationExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionVariancePopulationIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ICON_URL_ASC',
  ProjectPropertiesConnectionVariancePopulationIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ICON_URL_DESC',
  ProjectPropertiesConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertiesConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertiesConnectionVariancePopulationInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionVariancePopulationInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionVariancePopulationIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionVariancePopulationIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionVariancePopulationIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionVariancePopulationIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionVariancePopulationIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionVariancePopulationIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionVariancePopulationIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionVariancePopulationIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionVariancePopulationKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_KEY_NAME_ASC',
  ProjectPropertiesConnectionVariancePopulationKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_KEY_NAME_DESC',
  ProjectPropertiesConnectionVariancePopulationMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionVariancePopulationMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionVariancePopulationMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_MULTIPLE_ASC',
  ProjectPropertiesConnectionVariancePopulationMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_MULTIPLE_DESC',
  ProjectPropertiesConnectionVariancePopulationNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ProjectPropertiesConnectionVariancePopulationNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ProjectPropertiesConnectionVariancePopulationNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  ProjectPropertiesConnectionVariancePopulationNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  ProjectPropertiesConnectionVariancePopulationObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionVariancePopulationObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionVariancePopulationPositionAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ProjectPropertiesConnectionVariancePopulationPositionDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ProjectPropertiesConnectionVariancePopulationProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionVariancePopulationProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionVariancePopulationScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ProjectPropertiesConnectionVariancePopulationScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ProjectPropertiesConnectionVariancePopulationSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SHARED_ASC',
  ProjectPropertiesConnectionVariancePopulationSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SHARED_DESC',
  ProjectPropertiesConnectionVariancePopulationSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionVariancePopulationSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionVariancePopulationTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionVariancePopulationTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionVariancePopulationTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectPropertiesConnectionVariancePopulationTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectPropertiesConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectPropertiesConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectPropertiesConnectionVariancePopulationVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_VIRTUAL_ASC',
  ProjectPropertiesConnectionVariancePopulationVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_VIRTUAL_DESC',
  ProjectPropertiesConnectionVariancePopulationVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionVariancePopulationVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_POPULATION_VIRTUAL_TYPE_DESC',
  ProjectPropertiesConnectionVarianceSampleAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  ProjectPropertiesConnectionVarianceSampleAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  ProjectPropertiesConnectionVarianceSampleCompanyIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectPropertiesConnectionVarianceSampleCompanyIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectPropertiesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectPropertiesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectPropertiesConnectionVarianceSampleCreatedByAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectPropertiesConnectionVarianceSampleCreatedByDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectPropertiesConnectionVarianceSampleDisplayValuePathAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesConnectionVarianceSampleDisplayValuePathDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesConnectionVarianceSampleExternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionVarianceSampleExternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionVarianceSampleIconUrlAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ICON_URL_ASC',
  ProjectPropertiesConnectionVarianceSampleIconUrlDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ICON_URL_DESC',
  ProjectPropertiesConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertiesConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertiesConnectionVarianceSampleInternalVisibilityAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesConnectionVarianceSampleInternalVisibilityDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesConnectionVarianceSampleIsAdditionalAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_ADDITIONAL_ASC',
  ProjectPropertiesConnectionVarianceSampleIsAdditionalDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_ADDITIONAL_DESC',
  ProjectPropertiesConnectionVarianceSampleIsNewRecordAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_NEW_RECORD_ASC',
  ProjectPropertiesConnectionVarianceSampleIsNewRecordDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_NEW_RECORD_DESC',
  ProjectPropertiesConnectionVarianceSampleIsReadOnlyAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_READ_ONLY_ASC',
  ProjectPropertiesConnectionVarianceSampleIsReadOnlyDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_READ_ONLY_DESC',
  ProjectPropertiesConnectionVarianceSampleIsRequiredAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_REQUIRED_ASC',
  ProjectPropertiesConnectionVarianceSampleIsRequiredDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_IS_REQUIRED_DESC',
  ProjectPropertiesConnectionVarianceSampleKeyNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_KEY_NAME_ASC',
  ProjectPropertiesConnectionVarianceSampleKeyNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_KEY_NAME_DESC',
  ProjectPropertiesConnectionVarianceSampleMappedNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_MAPPED_NAME_ASC',
  ProjectPropertiesConnectionVarianceSampleMappedNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_MAPPED_NAME_DESC',
  ProjectPropertiesConnectionVarianceSampleMultipleAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_MULTIPLE_ASC',
  ProjectPropertiesConnectionVarianceSampleMultipleDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_MULTIPLE_DESC',
  ProjectPropertiesConnectionVarianceSampleNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ProjectPropertiesConnectionVarianceSampleNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ProjectPropertiesConnectionVarianceSampleNotesAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  ProjectPropertiesConnectionVarianceSampleNotesDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  ProjectPropertiesConnectionVarianceSampleObjectNameAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_OBJECT_NAME_ASC',
  ProjectPropertiesConnectionVarianceSampleObjectNameDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_OBJECT_NAME_DESC',
  ProjectPropertiesConnectionVarianceSamplePositionAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ProjectPropertiesConnectionVarianceSamplePositionDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ProjectPropertiesConnectionVarianceSampleProjectColumnIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesConnectionVarianceSampleProjectColumnIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesConnectionVarianceSampleScopeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ProjectPropertiesConnectionVarianceSampleScopeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ProjectPropertiesConnectionVarianceSampleSharedAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SHARED_ASC',
  ProjectPropertiesConnectionVarianceSampleSharedDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SHARED_DESC',
  ProjectPropertiesConnectionVarianceSampleSharingTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SHARING_TYPE_ASC',
  ProjectPropertiesConnectionVarianceSampleSharingTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_SHARING_TYPE_DESC',
  ProjectPropertiesConnectionVarianceSampleTaskTemplateIdAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesConnectionVarianceSampleTaskTemplateIdDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesConnectionVarianceSampleTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectPropertiesConnectionVarianceSampleTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectPropertiesConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectPropertiesConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectPropertiesConnectionVarianceSampleVirtualAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_ASC',
  ProjectPropertiesConnectionVarianceSampleVirtualDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_DESC',
  ProjectPropertiesConnectionVarianceSampleVirtualTypeAsc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesConnectionVarianceSampleVirtualTypeDesc = 'PROJECT_PROPERTIES_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_TYPE_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionArrayAggIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionAverageIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionCountAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_COUNT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionCountDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_COUNT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionDistinctCountIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMaxIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionMinIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionStddevSampleIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionSumIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleFileIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleFileIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertyFileReferencesByColumnIdConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTY_FILE_REFERENCES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionArrayAggWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionAverageWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionAverageWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionCountAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_COUNT_ASC',
  ProjectPropertyValuesByColumnIdConnectionCountDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_COUNT_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMaxWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMaxWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionMinWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionMinWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionSumWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionSumWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByColumnIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_COLUMN_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  SharedAsc = 'SHARED_ASC',
  SharedDesc = 'SHARED_DESC',
  SharingTypeAsc = 'SHARING_TYPE_ASC',
  SharingTypeDesc = 'SHARING_TYPE_DESC',
  TaskTemplateIdAsc = 'TASK_TEMPLATE_ID_ASC',
  TaskTemplateIdDesc = 'TASK_TEMPLATE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VirtualAsc = 'VIRTUAL_ASC',
  VirtualDesc = 'VIRTUAL_DESC',
  VirtualTypeAsc = 'VIRTUAL_TYPE_ASC',
  VirtualTypeDesc = 'VIRTUAL_TYPE_DESC'
}

export type ProjectProperty = Node & {
  additional: Scalars['JSON']['output'];
  /** Reads and enables pagination through a set of `ColumnToGroup`. */
  columnToGroupsByColumnId: Array<ColumnToGroup>;
  /** Reads and enables pagination through a set of `ColumnToGroup`. */
  columnToGroupsByColumnIdConnection: ColumnToGroupsConnection;
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `ProjectProperty`. */
  createdByUser: Maybe<User>;
  displayValuePath: Maybe<Scalars['String']['output']>;
  externalVisibility: ProjectColumnVisibility;
  /** Reads a single `FormLayout` that is related to this `ProjectProperty`. */
  formLayoutByColumnId: Maybe<FormLayout>;
  /**
   * Reads and enables pagination through a set of `FormLayout`.
   * @deprecated Please use formLayoutByColumnId instead
   */
  formLayoutsByColumnIdConnection: FormLayoutsConnection;
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByGroupByColumnId: Array<ImportHistory>;
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByGroupByColumnIdConnection: ImportHistoriesConnection;
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByMatchColumnId: Array<ImportHistory>;
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesByMatchColumnIdConnection: ImportHistoriesConnection;
  internalVisibility: ProjectColumnVisibility;
  isAdditional: Scalars['Boolean']['output'];
  isNewRecord: Scalars['Boolean']['output'];
  isReadOnly: Maybe<Scalars['Boolean']['output']>;
  isRequired: Scalars['Boolean']['output'];
  keyName: Maybe<Scalars['String']['output']>;
  mappedName: Scalars['String']['output'];
  multiple: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  objectName: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  /** Reads a single `ProjectProperty` that is related to this `ProjectProperty`. */
  projectColumn: Maybe<ProjectProperty>;
  /** Reads and enables pagination through a set of `ProjectColumnAccess`. */
  projectColumnAccessesByColumnId: Array<ProjectColumnAccess>;
  projectColumnId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectProperty`. */
  projectProperties: Array<ProjectProperty>;
  /** Reads and enables pagination through a set of `ProjectPropertyFileReference`. */
  projectPropertyFileReferencesByColumnId: Array<ProjectPropertyFileReference>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesByColumnId: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesByColumnIdConnection: ProjectPropertyValuesConnection;
  scope: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shared: Maybe<Scalars['Boolean']['output']>;
  sharingType: Maybe<Scalars['String']['output']>;
  taskTemplateId: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
  virtual: Scalars['Boolean']['output'];
  virtualType: Maybe<Scalars['String']['output']>;
};


export type ProjectPropertyColumnToGroupsByColumnIdArgs = {
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ProjectPropertyColumnToGroupsByColumnIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


export type ProjectPropertyFormLayoutsByColumnIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormLayoutsOrderBy>>;
};


export type ProjectPropertyImportHistoriesByGroupByColumnIdArgs = {
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ProjectPropertyImportHistoriesByGroupByColumnIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ProjectPropertyImportHistoriesByMatchColumnIdArgs = {
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ProjectPropertyImportHistoriesByMatchColumnIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


export type ProjectPropertyProjectColumnAccessesByColumnIdArgs = {
  condition: InputMaybe<ProjectColumnAccessCondition>;
  filter: InputMaybe<ProjectColumnAccessFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectColumnAccessesOrderBy>>;
};


export type ProjectPropertyProjectPropertiesArgs = {
  condition: InputMaybe<ProjectPropertyCondition>;
  filter: InputMaybe<ProjectPropertyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertiesOrderBy>>;
};


export type ProjectPropertyProjectPropertyFileReferencesByColumnIdArgs = {
  condition: InputMaybe<ProjectPropertyFileReferenceCondition>;
  filter: InputMaybe<ProjectPropertyFileReferenceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyFileReferencesOrderBy>>;
};


export type ProjectPropertyProjectPropertyValuesByColumnIdArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyProjectPropertyValuesByColumnIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/** A filter to be used against aggregates of `ProjectProperty` object types. */
export type ProjectPropertyAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectProperty` objects. */
  arrayAgg: InputMaybe<ProjectPropertyArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectProperty` objects. */
  average: InputMaybe<ProjectPropertyAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectProperty` objects. */
  distinctCount: InputMaybe<ProjectPropertyDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectProperty` object to be included within the aggregate. */
  filter: InputMaybe<ProjectPropertyFilter>;
  /** Maximum aggregate over matching `ProjectProperty` objects. */
  max: InputMaybe<ProjectPropertyMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectProperty` objects. */
  min: InputMaybe<ProjectPropertyMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectProperty` objects. */
  stddevPopulation: InputMaybe<ProjectPropertyStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectProperty` objects. */
  stddevSample: InputMaybe<ProjectPropertyStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectProperty` objects. */
  sum: InputMaybe<ProjectPropertySumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectProperty` objects. */
  variancePopulation: InputMaybe<ProjectPropertyVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectProperty` objects. */
  varianceSample: InputMaybe<ProjectPropertyVarianceSampleAggregateFilter>;
};

export type ProjectPropertyArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  displayValuePath: InputMaybe<StringListFilter>;
  iconUrl: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isAdditional: InputMaybe<BooleanListFilter>;
  isNewRecord: InputMaybe<BooleanListFilter>;
  isReadOnly: InputMaybe<BooleanListFilter>;
  isRequired: InputMaybe<BooleanListFilter>;
  keyName: InputMaybe<StringListFilter>;
  multiple: InputMaybe<BooleanListFilter>;
  notes: InputMaybe<StringListFilter>;
  objectName: InputMaybe<StringListFilter>;
  position: InputMaybe<IntListFilter>;
  projectColumnId: InputMaybe<IntListFilter>;
  shared: InputMaybe<BooleanListFilter>;
  sharingType: InputMaybe<StringListFilter>;
  taskTemplateId: InputMaybe<IntListFilter>;
  virtual: InputMaybe<BooleanListFilter>;
  virtualType: InputMaybe<StringListFilter>;
};

export type ProjectPropertyAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectColumnId: InputMaybe<BigFloatFilter>;
  taskTemplateId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectProperty` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectPropertyCondition = {
  /** Checks for equality with the object’s `additional` field. */
  additional: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `displayValuePath` field. */
  displayValuePath: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `externalVisibility` field. */
  externalVisibility: InputMaybe<ProjectColumnVisibility>;
  /** Checks for equality with the object’s `iconUrl` field. */
  iconUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `internalVisibility` field. */
  internalVisibility: InputMaybe<ProjectColumnVisibility>;
  /** Checks for equality with the object’s `isAdditional` field. */
  isAdditional: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isNewRecord` field. */
  isNewRecord: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isReadOnly` field. */
  isReadOnly: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isRequired` field. */
  isRequired: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `keyName` field. */
  keyName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `mappedName` field. */
  mappedName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `multiple` field. */
  multiple: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `objectName` field. */
  objectName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectColumnId` field. */
  projectColumnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `shared` field. */
  shared: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `sharingType` field. */
  sharingType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `taskTemplateId` field. */
  taskTemplateId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `virtual` field. */
  virtual: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `virtualType` field. */
  virtualType: InputMaybe<Scalars['String']['input']>;
};

export type ProjectPropertyDistinctCountAggregateFilter = {
  additional: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  displayValuePath: InputMaybe<BigIntFilter>;
  externalVisibility: InputMaybe<BigIntFilter>;
  iconUrl: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  internalVisibility: InputMaybe<BigIntFilter>;
  isAdditional: InputMaybe<BigIntFilter>;
  isNewRecord: InputMaybe<BigIntFilter>;
  isReadOnly: InputMaybe<BigIntFilter>;
  isRequired: InputMaybe<BigIntFilter>;
  keyName: InputMaybe<BigIntFilter>;
  mappedName: InputMaybe<BigIntFilter>;
  multiple: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  notes: InputMaybe<BigIntFilter>;
  objectName: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectColumnId: InputMaybe<BigIntFilter>;
  scope: InputMaybe<BigIntFilter>;
  shared: InputMaybe<BigIntFilter>;
  sharingType: InputMaybe<BigIntFilter>;
  taskTemplateId: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  virtual: InputMaybe<BigIntFilter>;
  virtualType: InputMaybe<BigIntFilter>;
};

export type ProjectPropertyFileReference = Node & {
  /** Reads a single `ProjectProperty` that is related to this `ProjectPropertyFileReference`. */
  column: Maybe<ProjectProperty>;
  columnId: Scalars['Int']['output'];
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `File` that is related to this `ProjectPropertyFileReference`. */
  file: Maybe<File>;
  fileId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/** A filter to be used against aggregates of `ProjectPropertyFileReference` object types. */
export type ProjectPropertyFileReferenceAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectPropertyFileReference` objects. */
  arrayAgg: InputMaybe<ProjectPropertyFileReferenceArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectPropertyFileReference` objects. */
  average: InputMaybe<ProjectPropertyFileReferenceAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectPropertyFileReference` objects. */
  distinctCount: InputMaybe<ProjectPropertyFileReferenceDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectPropertyFileReference` object to be included within the aggregate. */
  filter: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** Maximum aggregate over matching `ProjectPropertyFileReference` objects. */
  max: InputMaybe<ProjectPropertyFileReferenceMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectPropertyFileReference` objects. */
  min: InputMaybe<ProjectPropertyFileReferenceMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectPropertyFileReference` objects. */
  stddevPopulation: InputMaybe<ProjectPropertyFileReferenceStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectPropertyFileReference` objects. */
  stddevSample: InputMaybe<ProjectPropertyFileReferenceStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectPropertyFileReference` objects. */
  sum: InputMaybe<ProjectPropertyFileReferenceSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectPropertyFileReference` objects. */
  variancePopulation: InputMaybe<ProjectPropertyFileReferenceVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectPropertyFileReference` objects. */
  varianceSample: InputMaybe<ProjectPropertyFileReferenceVarianceSampleAggregateFilter>;
};

export type ProjectPropertyFileReferenceArrayAggAggregateFilter = {
  columnId: InputMaybe<IntListFilter>;
  fileId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type ProjectPropertyFileReferenceAverageAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectPropertyFileReference` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectPropertyFileReferenceCondition = {
  /** Checks for equality with the object’s `columnId` field. */
  columnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectPropertyFileReferenceDistinctCountAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectPropertyFileReference` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyFileReferenceFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectPropertyFileReferenceFilter>>;
  /** Filter by the object’s `column` relation. */
  column: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `columnId` field. */
  columnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `file` relation. */
  file: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectPropertyFileReferenceFilter>>;
};

export type ProjectPropertyFileReferenceMaxAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

export type ProjectPropertyFileReferenceMinAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  fileId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

export type ProjectPropertyFileReferenceStddevPopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyFileReferenceStddevSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyFileReferenceSumAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  fileId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type ProjectPropertyFileReferenceVariancePopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyFileReferenceVarianceSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  fileId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ProjectPropertyFileReference`. */
export enum ProjectPropertyFileReferencesOrderBy {
  ColumnIdAsc = 'COLUMN_ID_ASC',
  ColumnIdDesc = 'COLUMN_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against `ProjectProperty` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyFilter = {
  /** Filter by the object’s `additional` field. */
  additional: InputMaybe<JsonFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectPropertyFilter>>;
  /** Filter by the object’s `columnToGroupsByColumnId` relation. */
  columnToGroupsByColumnId: InputMaybe<ProjectPropertyToManyColumnToGroupFilter>;
  /** Some related `columnToGroupsByColumnId` exist. */
  columnToGroupsByColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `displayValuePath` field. */
  displayValuePath: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalVisibility` field. */
  externalVisibility: InputMaybe<ProjectColumnVisibilityFilter>;
  /** Filter by the object’s `formLayoutByColumnId` relation. */
  formLayoutByColumnId: InputMaybe<FormLayoutFilter>;
  /** A related `formLayoutByColumnId` exists. */
  formLayoutByColumnIdExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `iconUrl` field. */
  iconUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `importHistoriesByGroupByColumnId` relation. */
  importHistoriesByGroupByColumnId: InputMaybe<ProjectPropertyToManyImportHistoryFilter>;
  /** Some related `importHistoriesByGroupByColumnId` exist. */
  importHistoriesByGroupByColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `importHistoriesByMatchColumnId` relation. */
  importHistoriesByMatchColumnId: InputMaybe<ProjectPropertyToManyImportHistoryFilter>;
  /** Some related `importHistoriesByMatchColumnId` exist. */
  importHistoriesByMatchColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `internalVisibility` field. */
  internalVisibility: InputMaybe<ProjectColumnVisibilityFilter>;
  /** Filter by the object’s `isAdditional` field. */
  isAdditional: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNewRecord` field. */
  isNewRecord: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isReadOnly` field. */
  isReadOnly: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isRequired` field. */
  isRequired: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `keyName` field. */
  keyName: InputMaybe<StringFilter>;
  /** Filter by the object’s `mappedName` field. */
  mappedName: InputMaybe<StringFilter>;
  /** Filter by the object’s `multiple` field. */
  multiple: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `notes` field. */
  notes: InputMaybe<StringFilter>;
  /** Filter by the object’s `objectName` field. */
  objectName: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectPropertyFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectColumn` relation. */
  projectColumn: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `projectColumnAccessesByColumnId` relation. */
  projectColumnAccessesByColumnId: InputMaybe<ProjectPropertyToManyProjectColumnAccessFilter>;
  /** Some related `projectColumnAccessesByColumnId` exist. */
  projectColumnAccessesByColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** A related `projectColumn` exists. */
  projectColumnExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectColumnId` field. */
  projectColumnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectProperties` relation. */
  projectProperties: InputMaybe<ProjectPropertyToManyProjectPropertyFilter>;
  /** Some related `projectProperties` exist. */
  projectPropertiesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertyFileReferencesByColumnId` relation. */
  projectPropertyFileReferencesByColumnId: InputMaybe<ProjectPropertyToManyProjectPropertyFileReferenceFilter>;
  /** Some related `projectPropertyFileReferencesByColumnId` exist. */
  projectPropertyFileReferencesByColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertyValuesByColumnId` relation. */
  projectPropertyValuesByColumnId: InputMaybe<ProjectPropertyToManyProjectPropertyValueFilter>;
  /** Some related `projectPropertyValuesByColumnId` exist. */
  projectPropertyValuesByColumnIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringListFilter>;
  /** Filter by the object’s `shared` field. */
  shared: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `sharingType` field. */
  sharingType: InputMaybe<StringFilter>;
  /** Filter by the object’s `taskTemplateId` field. */
  taskTemplateId: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `virtual` field. */
  virtual: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `virtualType` field. */
  virtualType: InputMaybe<StringFilter>;
};

export type ProjectPropertyMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  projectColumnId: InputMaybe<IntFilter>;
  taskTemplateId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectPropertyMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  projectColumnId: InputMaybe<IntFilter>;
  taskTemplateId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectPropertyStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectColumnId: InputMaybe<BigFloatFilter>;
  taskTemplateId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectColumnId: InputMaybe<BigFloatFilter>;
  taskTemplateId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertySumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  projectColumnId: InputMaybe<BigIntFilter>;
  taskTemplateId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `ColumnToGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyColumnToGroupFilter = {
  /** Aggregates across related `ColumnToGroup` match the filter criteria. */
  aggregates: InputMaybe<ColumnToGroupAggregatesFilter>;
  /** Every related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ColumnToGroupFilter>;
  /** No related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ColumnToGroupFilter>;
  /** Some related `ColumnToGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ColumnToGroupFilter>;
};

/** A filter to be used against many `ImportHistory` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyImportHistoryFilter = {
  /** Aggregates across related `ImportHistory` match the filter criteria. */
  aggregates: InputMaybe<ImportHistoryAggregatesFilter>;
  /** Every related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ImportHistoryFilter>;
  /** No related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ImportHistoryFilter>;
  /** Some related `ImportHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ImportHistoryFilter>;
};

/** A filter to be used against many `ProjectColumnAccess` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyProjectColumnAccessFilter = {
  /** Aggregates across related `ProjectColumnAccess` match the filter criteria. */
  aggregates: InputMaybe<ProjectColumnAccessAggregatesFilter>;
  /** Every related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectColumnAccessFilter>;
  /** No related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectColumnAccessFilter>;
  /** Some related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectColumnAccessFilter>;
};

/** A filter to be used against many `ProjectPropertyFileReference` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyProjectPropertyFileReferenceFilter = {
  /** Aggregates across related `ProjectPropertyFileReference` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyFileReferenceAggregatesFilter>;
  /** Every related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** No related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyFileReferenceFilter>;
  /** Some related `ProjectPropertyFileReference` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyFileReferenceFilter>;
};

/** A filter to be used against many `ProjectProperty` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyProjectPropertyFilter = {
  /** Aggregates across related `ProjectProperty` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyAggregatesFilter>;
  /** Every related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyFilter>;
  /** No related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyFilter>;
  /** Some related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyFilter>;
};

/** A filter to be used against many `ProjectPropertyValue` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyToManyProjectPropertyValueFilter = {
  /** Aggregates across related `ProjectPropertyValue` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueAggregatesFilter>;
  /** Every related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFilter>;
  /** No related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFilter>;
  /** Some related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFilter>;
};

export type ProjectPropertyValue = Node & {
  /** Reads a single `ProjectProperty` that is related to this `ProjectPropertyValue`. */
  column: Maybe<ProjectProperty>;
  columnId: Scalars['Int']['output'];
  dateValue: Maybe<Scalars['Datetime']['output']>;
  dateValueAllDay: Maybe<Scalars['Boolean']['output']>;
  dropdownValue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Reads and enables pagination through a set of `ProjectPropertyValueFile`. */
  fileValuesIndirect: Array<ProjectPropertyValueFile>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: ProjectPropertyValueFilesConnectionManyToManyConnection;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Reads a single `Project` that is related to this `ProjectPropertyValue`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `ProjectReport` that is related to this `ProjectPropertyValue`. */
  projectReportId: Maybe<ProjectReport>;
  textValue: Maybe<Scalars['String']['output']>;
  /** Reads a single `User` that is related to this `ProjectPropertyValue`. */
  userByWorkerValue: Maybe<User>;
  workerValue: Maybe<Scalars['Int']['output']>;
};


export type ProjectPropertyValueFileValuesIndirectArgs = {
  condition: InputMaybe<ProjectPropertyValueFileCondition>;
  filter: InputMaybe<ProjectPropertyValueFileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValueFilesOrderBy>>;
};


export type ProjectPropertyValueFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectPropertyValueFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type ProjectPropertyValueAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectPropertyValueArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectPropertyValueAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectPropertyValueDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectPropertyValueMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectPropertyValueMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectPropertyValueStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectPropertyValueStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectPropertyValueSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectPropertyValueVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectPropertyValueVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectPropertyValue` object types. */
export type ProjectPropertyValueAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectPropertyValue` objects. */
  arrayAgg: InputMaybe<ProjectPropertyValueArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectPropertyValue` objects. */
  average: InputMaybe<ProjectPropertyValueAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectPropertyValue` objects. */
  distinctCount: InputMaybe<ProjectPropertyValueDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectPropertyValue` object to be included within the aggregate. */
  filter: InputMaybe<ProjectPropertyValueFilter>;
  /** Maximum aggregate over matching `ProjectPropertyValue` objects. */
  max: InputMaybe<ProjectPropertyValueMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectPropertyValue` objects. */
  min: InputMaybe<ProjectPropertyValueMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectPropertyValue` objects. */
  stddevPopulation: InputMaybe<ProjectPropertyValueStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectPropertyValue` objects. */
  stddevSample: InputMaybe<ProjectPropertyValueStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectPropertyValue` objects. */
  sum: InputMaybe<ProjectPropertyValueSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectPropertyValue` objects. */
  variancePopulation: InputMaybe<ProjectPropertyValueVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectPropertyValue` objects. */
  varianceSample: InputMaybe<ProjectPropertyValueVarianceSampleAggregateFilter>;
};

export type ProjectPropertyValueArrayAggAggregateFilter = {
  columnId: InputMaybe<IntListFilter>;
  dateValueAllDay: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  textValue: InputMaybe<StringListFilter>;
  workerValue: InputMaybe<IntListFilter>;
};

export type ProjectPropertyValueArrayAggAggregates = {
  /** Array aggregation of columnId across the matching connection */
  columnId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of dateValueAllDay across the matching connection */
  dateValueAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of numericValue across the matching connection */
  numericValue: Point;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of textValue across the matching connection */
  textValue: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of workerValue across the matching connection */
  workerValue: Array<Maybe<Scalars['Int']['output']>>;
};


export type ProjectPropertyValueArrayAggAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesDateValueAllDayArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesTextValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueArrayAggAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueAverageAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueAverageAggregates = {
  /** Mean average of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectPropertyValueAverageAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueAverageAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueAverageAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/**
 * A condition to be used against `ProjectPropertyValue` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectPropertyValueCondition = {
  /** Checks for equality with the object’s `columnId` field. */
  columnId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `dateValue` field. */
  dateValue: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `dateValueAllDay` field. */
  dateValueAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `dropdownValue` field. */
  dropdownValue: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `numericValue` field. */
  numericValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `textValue` field. */
  textValue: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `workerValue` field. */
  workerValue: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectPropertyValueDistinctCountAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  dateValue: InputMaybe<BigIntFilter>;
  dateValueAllDay: InputMaybe<BigIntFilter>;
  dropdownValue: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  numericValue: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  textValue: InputMaybe<BigIntFilter>;
  workerValue: InputMaybe<BigIntFilter>;
};

export type ProjectPropertyValueDistinctCountAggregates = {
  /** Distinct count of columnId across the matching connection */
  columnId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dateValue across the matching connection */
  dateValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dateValueAllDay across the matching connection */
  dateValueAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dropdownValue across the matching connection */
  dropdownValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of numericValue across the matching connection */
  numericValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of textValue across the matching connection */
  textValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectPropertyValueDistinctCountAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesDateValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesDateValueAllDayArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesDropdownValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesTextValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueDistinctCountAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueFile = Node & {
  /** Reads a single `File` that is related to this `ProjectPropertyValueFile`. */
  file: Maybe<File>;
  fileId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `File`. */
  filesByProjectColumnValueFileIdAndProjectColumnValueId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByProjectColumnValueFileIdAndProjectColumnValueIdConnection: FilesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `ProjectPropertyValue` that is related to this `ProjectPropertyValueFile`. */
  projectColumnValue: Maybe<ProjectPropertyValue>;
  projectColumnValueId: Scalars['Int']['output'];
};


export type ProjectPropertyValueFileFilesByProjectColumnValueFileIdAndProjectColumnValueIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectPropertyValueFileFilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against aggregates of `ProjectPropertyValueFile` object types. */
export type ProjectPropertyValueFileAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectPropertyValueFile` objects. */
  arrayAgg: InputMaybe<ProjectPropertyValueFileArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectPropertyValueFile` objects. */
  average: InputMaybe<ProjectPropertyValueFileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectPropertyValueFile` objects. */
  distinctCount: InputMaybe<ProjectPropertyValueFileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectPropertyValueFile` object to be included within the aggregate. */
  filter: InputMaybe<ProjectPropertyValueFileFilter>;
  /** Maximum aggregate over matching `ProjectPropertyValueFile` objects. */
  max: InputMaybe<ProjectPropertyValueFileMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectPropertyValueFile` objects. */
  min: InputMaybe<ProjectPropertyValueFileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectPropertyValueFile` objects. */
  stddevPopulation: InputMaybe<ProjectPropertyValueFileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectPropertyValueFile` objects. */
  stddevSample: InputMaybe<ProjectPropertyValueFileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectPropertyValueFile` objects. */
  sum: InputMaybe<ProjectPropertyValueFileSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectPropertyValueFile` objects. */
  variancePopulation: InputMaybe<ProjectPropertyValueFileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectPropertyValueFile` objects. */
  varianceSample: InputMaybe<ProjectPropertyValueFileVarianceSampleAggregateFilter>;
};

export type ProjectPropertyValueFileArrayAggAggregateFilter = {
  fileId: InputMaybe<IntListFilter>;
  projectColumnValueId: InputMaybe<IntListFilter>;
};

export type ProjectPropertyValueFileAverageAggregateFilter = {
  fileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectPropertyValueFile` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectPropertyValueFileCondition = {
  /** Checks for equality with the object’s `fileId` field. */
  fileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectColumnValueId` field. */
  projectColumnValueId: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectPropertyValueFileDistinctCountAggregateFilter = {
  fileId: InputMaybe<BigIntFilter>;
  projectColumnValueId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectPropertyValueFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyValueFileFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectPropertyValueFileFilter>>;
  /** Filter by the object’s `file` relation. */
  file: InputMaybe<FileFilter>;
  /** Filter by the object’s `fileId` field. */
  fileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `filesByProjectColumnValueFileIdAndProjectColumnValueId` relation. */
  filesByProjectColumnValueFileIdAndProjectColumnValueId: InputMaybe<ProjectPropertyValueFileToManyFileFilter>;
  /** Some related `filesByProjectColumnValueFileIdAndProjectColumnValueId` exist. */
  filesByProjectColumnValueFileIdAndProjectColumnValueIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Negates the expression. */
  not: InputMaybe<ProjectPropertyValueFileFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectPropertyValueFileFilter>>;
  /** Filter by the object’s `projectColumnValue` relation. */
  projectColumnValue: InputMaybe<ProjectPropertyValueFilter>;
  /** Filter by the object’s `projectColumnValueId` field. */
  projectColumnValueId: InputMaybe<IntFilter>;
};

export type ProjectPropertyValueFileMaxAggregateFilter = {
  fileId: InputMaybe<IntFilter>;
  projectColumnValueId: InputMaybe<IntFilter>;
};

export type ProjectPropertyValueFileMinAggregateFilter = {
  fileId: InputMaybe<IntFilter>;
  projectColumnValueId: InputMaybe<IntFilter>;
};

export type ProjectPropertyValueFileStddevPopulationAggregateFilter = {
  fileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueFileStddevSampleAggregateFilter = {
  fileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueFileSumAggregateFilter = {
  fileId: InputMaybe<BigIntFilter>;
  projectColumnValueId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyValueFileToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

export type ProjectPropertyValueFileVariancePopulationAggregateFilter = {
  fileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueFileVarianceSampleAggregateFilter = {
  fileId: InputMaybe<BigFloatFilter>;
  projectColumnValueId: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `File` values, with data from `ProjectPropertyValueFile`. */
export type ProjectPropertyValueFilesConnectionManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `ProjectPropertyValueFile`, and the cursor to aid in pagination. */
  edges: Array<ProjectPropertyValueFilesConnectionManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `ProjectPropertyValueFile`. */
export type ProjectPropertyValueFilesConnectionManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `ProjectPropertyValueFile`. */
export type ProjectPropertyValueFilesConnectionManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};

/** Methods to use when ordering `ProjectPropertyValueFile`. */
export enum ProjectPropertyValueFilesOrderBy {
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionArrayAggValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAveragePrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionAverageValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionCountAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_COUNT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionCountDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_COUNT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionDistinctCountValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMaxValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionMinValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionStddevSampleValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionSumValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleActionIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleActionIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCommentIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCommentIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleFormIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleFormIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIsFormAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleIsFormDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleMetaDataAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleMetaDataDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleModifiedByAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleModifiedByDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleNameAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleNameDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleOriginAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleOriginDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleProjectIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleStageIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleStageIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleTypeAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleTypeDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleUuidAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleUuidDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByProjectColumnValueFileIdAndProjectColumnValueIdConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_PROJECT_COLUMN_VALUE_FILE_ID_AND_PROJECT_COLUMN_VALUE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectColumnValueIdAsc = 'PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectColumnValueIdDesc = 'PROJECT_COLUMN_VALUE_ID_DESC'
}

/** A filter to be used against `ProjectPropertyValue` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyValueFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectPropertyValueFilter>>;
  /** Filter by the object’s `column` relation. */
  column: InputMaybe<ProjectPropertyFilter>;
  /** Filter by the object’s `columnId` field. */
  columnId: InputMaybe<IntFilter>;
  /** Filter by the object’s `dateValue` field. */
  dateValue: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateValueAllDay` field. */
  dateValueAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `dropdownValue` field. */
  dropdownValue: InputMaybe<StringListFilter>;
  /** Filter by the object’s `fileValuesIndirect` relation. */
  fileValuesIndirect: InputMaybe<ProjectPropertyValueToManyProjectPropertyValueFileFilter>;
  /** Some related `fileValuesIndirect` exist. */
  fileValuesIndirectExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectPropertyValueFilter>;
  /** Filter by the object’s `numericValue` field. */
  numericValue: InputMaybe<FloatFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectPropertyValueFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectReportId` relation. */
  projectReportId: InputMaybe<ProjectReportFilter>;
  /** A related `projectReportId` exists. */
  projectReportIdExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `textValue` field. */
  textValue: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByWorkerValue` relation. */
  userByWorkerValue: InputMaybe<UserFilter>;
  /** A related `userByWorkerValue` exists. */
  userByWorkerValueExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `workerValue` field. */
  workerValue: InputMaybe<IntFilter>;
};

/** Grouping methods for `ProjectPropertyValue` for usage during aggregation. */
export enum ProjectPropertyValueGroupBy {
  ColumnId = 'COLUMN_ID',
  DateValue = 'DATE_VALUE',
  DateValueAllDay = 'DATE_VALUE_ALL_DAY',
  DateValueTruncatedToDay = 'DATE_VALUE_TRUNCATED_TO_DAY',
  DateValueTruncatedToHour = 'DATE_VALUE_TRUNCATED_TO_HOUR',
  DateValueTruncatedToMonth = 'DATE_VALUE_TRUNCATED_TO_MONTH',
  DateValueTruncatedToQuarter = 'DATE_VALUE_TRUNCATED_TO_QUARTER',
  DateValueTruncatedToWeek = 'DATE_VALUE_TRUNCATED_TO_WEEK',
  DateValueTruncatedToYear = 'DATE_VALUE_TRUNCATED_TO_YEAR',
  DropdownValue = 'DROPDOWN_VALUE',
  NumericValue = 'NUMERIC_VALUE',
  ProjectId = 'PROJECT_ID',
  TextValue = 'TEXT_VALUE',
  WorkerValue = 'WORKER_VALUE'
}

export type ProjectPropertyValueHavingArrayAggInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingAverageInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingDistinctCountInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectPropertyValue` aggregates. */
export type ProjectPropertyValueHavingInput = {
  AND: InputMaybe<Array<ProjectPropertyValueHavingInput>>;
  OR: InputMaybe<Array<ProjectPropertyValueHavingInput>>;
  arrayAgg: InputMaybe<ProjectPropertyValueHavingArrayAggInput>;
  average: InputMaybe<ProjectPropertyValueHavingAverageInput>;
  distinctCount: InputMaybe<ProjectPropertyValueHavingDistinctCountInput>;
  max: InputMaybe<ProjectPropertyValueHavingMaxInput>;
  min: InputMaybe<ProjectPropertyValueHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectPropertyValueHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectPropertyValueHavingStddevSampleInput>;
  sum: InputMaybe<ProjectPropertyValueHavingSumInput>;
  variancePopulation: InputMaybe<ProjectPropertyValueHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectPropertyValueHavingVarianceSampleInput>;
};

export type ProjectPropertyValueHavingMaxInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingMinInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingStddevPopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingStddevSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingSumInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingVariancePopulationInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueHavingVarianceSampleInput = {
  columnId: InputMaybe<HavingIntFilter>;
  dateValue: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectPropertyValueMaxAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  dateValue: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<IntFilter>;
  workerValue: InputMaybe<IntFilter>;
};

export type ProjectPropertyValueMaxAggregates = {
  /** Maximum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Maximum of dateValue across the matching connection */
  dateValue: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of workerValue across the matching connection */
  workerValue: Maybe<Scalars['Int']['output']>;
};


export type ProjectPropertyValueMaxAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMaxAggregatesDateValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMaxAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMaxAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueMinAggregateFilter = {
  columnId: InputMaybe<IntFilter>;
  dateValue: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<IntFilter>;
  workerValue: InputMaybe<IntFilter>;
};

export type ProjectPropertyValueMinAggregates = {
  /** Minimum of columnId across the matching connection */
  columnId: Maybe<Scalars['Int']['output']>;
  /** Minimum of dateValue across the matching connection */
  dateValue: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of workerValue across the matching connection */
  workerValue: Maybe<Scalars['Int']['output']>;
};


export type ProjectPropertyValueMinAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMinAggregatesDateValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMinAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueMinAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueStddevPopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueStddevPopulationAggregates = {
  /** Population standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectPropertyValueStddevPopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevPopulationAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevPopulationAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueStddevSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueStddevSampleAggregates = {
  /** Sample standard deviation of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectPropertyValueStddevSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevSampleAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueStddevSampleAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueSumAggregateFilter = {
  columnId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigIntFilter>;
  workerValue: InputMaybe<BigIntFilter>;
};

export type ProjectPropertyValueSumAggregates = {
  /** Sum of columnId across the matching connection */
  columnId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of numericValue across the matching connection */
  numericValue: Scalars['Float']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of workerValue across the matching connection */
  workerValue: Scalars['BigInt']['output'];
};


export type ProjectPropertyValueSumAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueSumAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueSumAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/** A filter to be used against many `ProjectPropertyValueFile` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPropertyValueToManyProjectPropertyValueFileFilter = {
  /** Aggregates across related `ProjectPropertyValueFile` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueFileAggregatesFilter>;
  /** Every related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFileFilter>;
  /** No related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFileFilter>;
  /** Some related `ProjectPropertyValueFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFileFilter>;
};

export type ProjectPropertyValueVariancePopulationAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueVariancePopulationAggregates = {
  /** Population variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectPropertyValueVariancePopulationAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVariancePopulationAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVariancePopulationAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

export type ProjectPropertyValueVarianceSampleAggregateFilter = {
  columnId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyValueVarianceSampleAggregates = {
  /** Sample variance of columnId across the matching connection */
  columnId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectPropertyValueVarianceSampleAggregatesColumnIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVarianceSampleAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectPropertyValueVarianceSampleAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/** A connection to a list of `ProjectPropertyValue` values. */
export type ProjectPropertyValuesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectPropertyValueAggregates>;
  /** A list of edges which contains the `ProjectPropertyValue` and cursor to aid in pagination. */
  edges: Array<ProjectPropertyValuesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectPropertyValueAggregates>>;
  /** A list of `ProjectPropertyValue` objects. */
  nodes: Array<Maybe<ProjectPropertyValue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectPropertyValue` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectPropertyValue` values. */
export type ProjectPropertyValuesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectPropertyValueGroupBy>;
  having: InputMaybe<ProjectPropertyValueHavingInput>;
};

/** A `ProjectPropertyValue` edge in the connection. */
export type ProjectPropertyValuesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectPropertyValue` at the end of the edge. */
  node: Maybe<ProjectPropertyValue>;
};

/** Methods to use when ordering `ProjectPropertyValue`. */
export enum ProjectPropertyValuesOrderBy {
  ColumnIdAsc = 'COLUMN_ID_ASC',
  ColumnIdDesc = 'COLUMN_ID_DESC',
  DateValueAllDayAsc = 'DATE_VALUE_ALL_DAY_ASC',
  DateValueAllDayDesc = 'DATE_VALUE_ALL_DAY_DESC',
  DateValueAsc = 'DATE_VALUE_ASC',
  DateValueDesc = 'DATE_VALUE_DESC',
  DropdownValueAsc = 'DROPDOWN_VALUE_ASC',
  DropdownValueDesc = 'DROPDOWN_VALUE_DESC',
  FileValuesIndirectConnectionArrayAggFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FileValuesIndirectConnectionArrayAggFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FileValuesIndirectConnectionArrayAggProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionArrayAggProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionAverageFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_AVERAGE_FILE_ID_ASC',
  FileValuesIndirectConnectionAverageFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_AVERAGE_FILE_ID_DESC',
  FileValuesIndirectConnectionAverageProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionAverageProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionCountAsc = 'FILE_VALUES_INDIRECT_CONNECTION_COUNT_ASC',
  FileValuesIndirectConnectionCountDesc = 'FILE_VALUES_INDIRECT_CONNECTION_COUNT_DESC',
  FileValuesIndirectConnectionDistinctCountFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FileValuesIndirectConnectionDistinctCountFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FileValuesIndirectConnectionDistinctCountProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionDistinctCountProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionMaxFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_MAX_FILE_ID_ASC',
  FileValuesIndirectConnectionMaxFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_MAX_FILE_ID_DESC',
  FileValuesIndirectConnectionMaxProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionMaxProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionMinFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_MIN_FILE_ID_ASC',
  FileValuesIndirectConnectionMinFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_MIN_FILE_ID_DESC',
  FileValuesIndirectConnectionMinProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionMinProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionStddevPopulationFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FileValuesIndirectConnectionStddevPopulationFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FileValuesIndirectConnectionStddevPopulationProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionStddevPopulationProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionStddevSampleFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FileValuesIndirectConnectionStddevSampleFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FileValuesIndirectConnectionStddevSampleProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionStddevSampleProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionSumFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_SUM_FILE_ID_ASC',
  FileValuesIndirectConnectionSumFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_SUM_FILE_ID_DESC',
  FileValuesIndirectConnectionSumProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionSumProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionVariancePopulationFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FileValuesIndirectConnectionVariancePopulationFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FileValuesIndirectConnectionVariancePopulationProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionVariancePopulationProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FileValuesIndirectConnectionVarianceSampleFileIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FileValuesIndirectConnectionVarianceSampleFileIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FileValuesIndirectConnectionVarianceSampleProjectColumnValueIdAsc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FileValuesIndirectConnectionVarianceSampleProjectColumnValueIdDesc = 'FILE_VALUES_INDIRECT_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NumericValueAsc = 'NUMERIC_VALUE_ASC',
  NumericValueDesc = 'NUMERIC_VALUE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  TextValueAsc = 'TEXT_VALUE_ASC',
  TextValueDesc = 'TEXT_VALUE_DESC',
  WorkerValueAsc = 'WORKER_VALUE_ASC',
  WorkerValueDesc = 'WORKER_VALUE_DESC'
}

export type ProjectPropertyVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectColumnId: InputMaybe<BigFloatFilter>;
  taskTemplateId: InputMaybe<BigFloatFilter>;
};

export type ProjectPropertyVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  projectColumnId: InputMaybe<BigFloatFilter>;
  taskTemplateId: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ProjectRemindersByFeedProjectIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectRemindersByFeedProjectIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type ProjectRemindersByFeedProjectIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ProjectRemindersByFeedProjectIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type ProjectRemindersByFeedProjectIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ProjectReport = Node & {
  accountStatus: Maybe<Scalars['String']['output']>;
  accountType: Maybe<Scalars['String']['output']>;
  address: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  addressNormalized: Maybe<NormAddy>;
  addressPretty: Maybe<Scalars['String']['output']>;
  ahj: Maybe<Scalars['String']['output']>;
  /** Reads a single `Blueprint` that is related to this `ProjectReport`. */
  blueprint: Maybe<Blueprint>;
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportParentProjectIdAndBlueprintIdList: Array<Blueprint>;
  city: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportParentProjectIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `ProjectReport`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  completionDate: Maybe<Scalars['Datetime']['output']>;
  completionTime: Maybe<Interval>;
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  confidence: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  createdByContactId: Maybe<Scalars['Int']['output']>;
  createdByType: Maybe<CommentCreatedBy>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  createdByUser: Maybe<User>;
  dealSize: Maybe<Scalars['Float']['output']>;
  dealValue: Maybe<Scalars['Float']['output']>;
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  description: Maybe<Scalars['String']['output']>;
  dropdownValue: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  freshProject: Maybe<Scalars['Boolean']['output']>;
  geoLocation: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  geoLocationGeography: Maybe<GeographyInterface>;
  geoLocationNormalized: Maybe<Point>;
  homeOwnerEmail: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Jurisdiction` that is related to this `ProjectReport`. */
  jurisdiction: Maybe<Jurisdiction>;
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportParentProjectIdAndJurisdictionId: ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportParentProjectIdAndJurisdictionIdList: Array<Jurisdiction>;
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  lastInboundCallDisposition: Maybe<CallDisposition>;
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundCallDisposition: Maybe<CallDisposition>;
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  numericValue: Maybe<Scalars['Float']['output']>;
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  owner: Maybe<User>;
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `ProjectReport` that is related to this `ProjectReport`. */
  parentProject: Maybe<ProjectReport>;
  parentProjectId: Maybe<Scalars['Int']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  primaryEmail: Maybe<Scalars['String']['output']>;
  primaryPhone: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  progress: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Project` that is related to this `ProjectReport`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  projectManager: Maybe<User>;
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValues: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValuesConnection: ProjectPropertyValuesConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdates: Array<ProjectStageUpdate>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesConnection: ProjectStageUpdatesConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportParentProjectIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportParentProjectIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimeline: Array<ProjectStatusTimeline>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimelineConnection: ProjectStatusTimelinesConnection;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdates: Array<ProjectStatusUpdate>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdatesConnection: ProjectStatusUpdatesConnection;
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectPropertyValueProjectIdAndProjectId: ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectPropertyValueProjectIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportParentProjectIdAndProjectId: ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportParentProjectIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageUpdateProjectIdAndProjectId: ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageUpdateProjectIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStatusTimelineProjectIdAndProjectId: ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStatusTimelineProjectIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStatusUpdateProjectIdAndProjectId: ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStatusUpdateProjectIdAndProjectIdList: Array<Project>;
  referrerContactId: Maybe<Scalars['Int']['output']>;
  requestStatus: Maybe<ProjectRequestStatus>;
  revenue: Maybe<Scalars['Float']['output']>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  salesRep: Maybe<User>;
  salesRepId: Maybe<Scalars['Int']['output']>;
  slaRedViolationJobId: Maybe<Scalars['String']['output']>;
  slaYellowViolationJobId: Maybe<Scalars['String']['output']>;
  source: Maybe<CreatedSource>;
  /** Reads a single `ProjectStage` that is related to this `ProjectReport`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  state: Maybe<Scalars['String']['output']>;
  status: Maybe<ProjectStatus>;
  street: Maybe<Scalars['String']['output']>;
  streetViewUrl: Maybe<Scalars['String']['output']>;
  /** Reads a single `Team` that is related to this `ProjectReport`. */
  team: Maybe<Team>;
  teamId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportParentProjectIdAndTeamId: ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportParentProjectIdAndTeamIdList: Array<Team>;
  textValue: Maybe<Scalars['String']['output']>;
  /** Reads a single `ProjectStage` that is related to this `ProjectReport`. */
  timelineStage: Maybe<ProjectStage>;
  timelineStageEndedAt: Maybe<Scalars['Datetime']['output']>;
  timelineStageId: Maybe<Scalars['Int']['output']>;
  timelineStageIsEnded: Maybe<Scalars['Boolean']['output']>;
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  timelineStageStartedAt: Maybe<Scalars['Datetime']['output']>;
  timelineStatus: Maybe<Scalars['String']['output']>;
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  timelineStatusIsEnded: Maybe<Scalars['Boolean']['output']>;
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  title: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Float']['output']>;
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  totalPrice: Maybe<Scalars['Float']['output']>;
  trade: Maybe<Scalars['String']['output']>;
  trades: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  user: Maybe<User>;
  /** Reads a single `User` that is related to this `ProjectReport`. */
  userByWorkerValue: Maybe<User>;
  userId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndCreatedBy: ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndOwnerId: ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndProjectManagerId: ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndSalesRepId: ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndUserId: ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportParentProjectIdAndWorkerValue: ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyConnection;
  workerValue: Maybe<Scalars['Int']['output']>;
  zipcode: Maybe<Scalars['String']['output']>;
};


export type ProjectReportBlueprintsByProjectReportParentProjectIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectReportCompaniesByProjectReportParentProjectIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectReportProjectPropertiesValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectReportProjectPropertiesValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type ProjectReportProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportProjectStageUpdatesArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectReportProjectStageUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectReportProjectStagesByProjectReportParentProjectIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectReportProjectStagesByProjectReportParentProjectIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectReportProjectStatusTimelineArgs = {
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectReportProjectStatusTimelineConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectReportProjectStatusUpdatesArgs = {
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectReportProjectStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type ProjectReportAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectReportArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectReportAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectReportDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectReportMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectReportMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectReportStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectReportStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectReportSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectReportVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectReportVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectReport` object types. */
export type ProjectReportAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectReport` objects. */
  arrayAgg: InputMaybe<ProjectReportArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectReport` objects. */
  average: InputMaybe<ProjectReportAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectReport` objects. */
  distinctCount: InputMaybe<ProjectReportDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectReport` object to be included within the aggregate. */
  filter: InputMaybe<ProjectReportFilter>;
  /** Maximum aggregate over matching `ProjectReport` objects. */
  max: InputMaybe<ProjectReportMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectReport` objects. */
  min: InputMaybe<ProjectReportMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectReport` objects. */
  stddevPopulation: InputMaybe<ProjectReportStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectReport` objects. */
  stddevSample: InputMaybe<ProjectReportStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectReport` objects. */
  sum: InputMaybe<ProjectReportSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectReport` objects. */
  variancePopulation: InputMaybe<ProjectReportVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectReport` objects. */
  varianceSample: InputMaybe<ProjectReportVarianceSampleAggregateFilter>;
};

export type ProjectReportArrayAggAggregateFilter = {
  accountStatus: InputMaybe<StringListFilter>;
  addressPretty: InputMaybe<StringListFilter>;
  blueprintId: InputMaybe<IntListFilter>;
  city: InputMaybe<StringListFilter>;
  companyId: InputMaybe<IntListFilter>;
  confidence: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  createdByContactId: InputMaybe<IntListFilter>;
  dealSize: InputMaybe<FloatListFilter>;
  dealValue: InputMaybe<FloatListFilter>;
  freshProject: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  imageUrl: InputMaybe<StringListFilter>;
  isActive: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  jurisdictionId: InputMaybe<IntListFilter>;
  ownerId: InputMaybe<IntListFilter>;
  parentProjectId: InputMaybe<IntListFilter>;
  primaryEmail: InputMaybe<StringListFilter>;
  primaryPhone: InputMaybe<StringListFilter>;
  productName: InputMaybe<StringListFilter>;
  progress: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  projectManagerId: InputMaybe<IntListFilter>;
  projectSize: InputMaybe<FloatListFilter>;
  projectValue: InputMaybe<FloatListFilter>;
  referrerContactId: InputMaybe<IntListFilter>;
  revenue: InputMaybe<FloatListFilter>;
  salesRepId: InputMaybe<IntListFilter>;
  slaRedViolationJobId: InputMaybe<StringListFilter>;
  slaYellowViolationJobId: InputMaybe<StringListFilter>;
  stageId: InputMaybe<IntListFilter>;
  state: InputMaybe<StringListFilter>;
  street: InputMaybe<StringListFilter>;
  streetViewUrl: InputMaybe<StringListFilter>;
  teamId: InputMaybe<IntListFilter>;
  textValue: InputMaybe<StringListFilter>;
  timelineStageId: InputMaybe<IntListFilter>;
  timelineStageIsEnded: InputMaybe<BooleanListFilter>;
  timelineStatus: InputMaybe<StringListFilter>;
  timelineStatusIsEnded: InputMaybe<BooleanListFilter>;
  totalCost: InputMaybe<FloatListFilter>;
  totalInboundCallsCount: InputMaybe<IntListFilter>;
  totalOutboundCallsCount: InputMaybe<IntListFilter>;
  totalPrice: InputMaybe<FloatListFilter>;
  trade: InputMaybe<StringListFilter>;
  type: InputMaybe<StringListFilter>;
  uid: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
  workerValue: InputMaybe<IntListFilter>;
  zipcode: InputMaybe<StringListFilter>;
};

export type ProjectReportArrayAggAggregates = {
  /** Array aggregation of accountStatus across the matching connection */
  accountStatus: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressPretty across the matching connection */
  addressPretty: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of blueprintId across the matching connection */
  blueprintId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of city across the matching connection */
  city: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of confidence across the matching connection */
  confidence: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdByContactId across the matching connection */
  createdByContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of dealSize across the matching connection */
  dealSize: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of dealValue across the matching connection */
  dealValue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of dealValueWeighted across the matching connection */
  dealValueWeighted: Point;
  /** Array aggregation of freshProject across the matching connection */
  freshProject: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of geoLocationNormalized across the matching connection */
  geoLocationNormalized: Scalars['String']['output'];
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of imageUrl across the matching connection */
  imageUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of isActive across the matching connection */
  isActive: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jurisdictionId across the matching connection */
  jurisdictionId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of numericValue across the matching connection */
  numericValue: Point;
  /** Array aggregation of overdueBy across the matching connection */
  overdueBy: Point;
  /** Array aggregation of ownerId across the matching connection */
  ownerId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of parentProjectId across the matching connection */
  parentProjectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of primaryEmail across the matching connection */
  primaryEmail: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of primaryPhone across the matching connection */
  primaryPhone: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of productName across the matching connection */
  productName: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of progress across the matching connection */
  progress: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectManagerId across the matching connection */
  projectManagerId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectSize across the matching connection */
  projectSize: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of projectValue across the matching connection */
  projectValue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of referrerContactId across the matching connection */
  referrerContactId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of revenue across the matching connection */
  revenue: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of salesRepId across the matching connection */
  salesRepId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of slaRedViolationJobId across the matching connection */
  slaRedViolationJobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of slaYellowViolationJobId across the matching connection */
  slaYellowViolationJobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of state across the matching connection */
  state: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of street across the matching connection */
  street: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of streetViewUrl across the matching connection */
  streetViewUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of teamId across the matching connection */
  teamId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of textValue across the matching connection */
  textValue: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of timelineStageId across the matching connection */
  timelineStageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of timelineStageIsEnded across the matching connection */
  timelineStageIsEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Point;
  /** Array aggregation of timelineStatus across the matching connection */
  timelineStatus: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of timelineStatusIsEnded across the matching connection */
  timelineStatusIsEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Point;
  /** Array aggregation of totalCost across the matching connection */
  totalCost: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of totalPrice across the matching connection */
  totalPrice: Array<Maybe<Scalars['Float']['output']>>;
  /** Array aggregation of trade across the matching connection */
  trade: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of userId across the matching connection */
  userId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of workerValue across the matching connection */
  workerValue: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of zipcode across the matching connection */
  zipcode: Array<Maybe<Scalars['String']['output']>>;
};


export type ProjectReportArrayAggAggregatesAccountStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesAddressPrettyArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesCityArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesFreshProjectArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesGeoLocationNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesIsActiveArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesPrimaryEmailArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesPrimaryPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProductNameArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesSlaRedViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesSlaYellowViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesStateArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesStreetArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesStreetViewUrlArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTextValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStageIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStatusIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTradeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportArrayAggAggregatesZipcodeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportAverageAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  timelineStageId: InputMaybe<BigFloatFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectReportAverageAggregates = {
  /** Mean average of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Mean average of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Mean average of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Mean average of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Mean average of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Mean average of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Mean average of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Mean average of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Mean average of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectReportAverageAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportAverageAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/**
 * A condition to be used against `ProjectReport` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectReportCondition = {
  /** Checks for equality with the object’s `accountStatus` field. */
  accountStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `accountType` field. */
  accountType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filters list of projects by any of adresses including given string. */
  addressLike: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyInput>;
  /** Checks for equality with the object’s `addressPretty` field. */
  addressPretty: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ahj` field. */
  ahj: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `blueprintId` field. */
  blueprintId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `city` field. */
  city: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completionDate` field. */
  completionDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `confidence` field. */
  confidence: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedBy>;
  /** Checks for equality with the object’s `dealSize` field. */
  dealSize: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `dealValue` field. */
  dealValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `dealValueWeighted` field. */
  dealValueWeighted: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `dropdownValue` field. */
  dropdownValue: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Specify status to be excluded */
  excludedTimelineStatuses: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks for equality with the object’s `freshProject` field. */
  freshProject: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `geoLocation` field. */
  geoLocation: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `geoLocationGeography` field. */
  geoLocationGeography: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Checks for equality with the object’s `geoLocationNormalized` field. */
  geoLocationNormalized: InputMaybe<PointInput>;
  /** Checks for equality with the object’s `homeOwnerEmail` field. */
  homeOwnerEmail: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  imageUrl: InputMaybe<Scalars['String']['input']>;
  /** Specify status to be excluded */
  includedTimelineStatuses: InputMaybe<Array<Scalars['String']['input']>>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jurisdictionId` field. */
  jurisdictionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastActivity` field. */
  lastActivity: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundCallDisposition` field. */
  lastInboundCallDisposition: InputMaybe<CallDisposition>;
  /** Checks for equality with the object’s `lastInboundCallTime` field. */
  lastInboundCallTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundEmailTime` field. */
  lastInboundEmailTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastInboundSmsTime` field. */
  lastInboundSmsTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundCallDisposition` field. */
  lastOutboundCallDisposition: InputMaybe<CallDisposition>;
  /** Checks for equality with the object’s `lastOutboundCallTime` field. */
  lastOutboundCallTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundEmailTime` field. */
  lastOutboundEmailTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastOutboundSmsTime` field. */
  lastOutboundSmsTime: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `numericValue` field. */
  numericValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `overdueBy` field. */
  overdueBy: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `parentProjectId` field. */
  parentProjectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `primaryEmail` field. */
  primaryEmail: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `primaryPhone` field. */
  primaryPhone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `productName` field. */
  productName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `progress` field. */
  progress: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectManagerId` field. */
  projectManagerId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectSize` field. */
  projectSize: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `projectValue` field. */
  projectValue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `requestStatus` field. */
  requestStatus: InputMaybe<ProjectRequestStatus>;
  /** Checks for equality with the object’s `revenue` field. */
  revenue: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `salesRepId` field. */
  salesRepId: InputMaybe<Scalars['Int']['input']>;
  /** Select projects duplicated via its line items that related to with the specified category id. If specified, use productName property to get the value. */
  selectCategory: InputMaybe<Scalars['Int']['input']>;
  /** Select project with specified dropdown property value. If specified, use textValue property to get the value. */
  selectDropdownProperty: InputMaybe<Scalars['Int']['input']>;
  /** Select project with specified numeric property value. If specified, use numericValue property to get the value. */
  selectNumericProperty: InputMaybe<Scalars['Int']['input']>;
  /** Select project with specified person property value. If specified, use workerValue property to get the value. */
  selectPersonProperty: InputMaybe<Scalars['Int']['input']>;
  /** Select project with specified team value. When specified, always limit the team result to exactly 1 team either by filter or teamConnection, otherwise there will be duplicated results. If specified, use teamId property to get the value. */
  selectTeamIdBy: InputMaybe<TeamIdBy>;
  /** Select project with specified user value. If specified, use userId property to get the value. */
  selectUserIdBy: InputMaybe<UserIdBy>;
  /** Checks for equality with the object’s `slaRedViolationJobId` field. */
  slaRedViolationJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slaYellowViolationJobId` field. */
  slaYellowViolationJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `source` field. */
  source: InputMaybe<CreatedSource>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `state` field. */
  state: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<ProjectStatus>;
  /** Checks for equality with the object’s `street` field. */
  street: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `streetViewUrl` field. */
  streetViewUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `textValue` field. */
  textValue: InputMaybe<Scalars['String']['input']>;
  /** Filter project timeline where stages ended date is lower or equal with provided value */
  timelineEndedEndDate: InputMaybe<Scalars['String']['input']>;
  /** Filter project timeline where stages ended date is greater or equal with provided value */
  timelineEndedStartDate: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timelineStageEndedAt` field. */
  timelineStageEndedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `timelineStageId` field. */
  timelineStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `timelineStageIsEnded` field. */
  timelineStageIsEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `timelineStageSpentTime` field. */
  timelineStageSpentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `timelineStageStartedAt` field. */
  timelineStageStartedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Filter project timeline where stages started date is lower or equal with provided value */
  timelineStartedEndDate: InputMaybe<Scalars['String']['input']>;
  /** Filter project timeline where stages started date is greater or equal with provided value */
  timelineStartedStartDate: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timelineStatus` field. */
  timelineStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timelineStatusEndedAt` field. */
  timelineStatusEndedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `timelineStatusIsEnded` field. */
  timelineStatusIsEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `timelineStatusSpentTime` field. */
  timelineStatusSpentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `timelineStatusStartedAt` field. */
  timelineStatusStartedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalCost` field. */
  totalCost: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `totalInboundCallsCount` field. */
  totalInboundCallsCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalOutboundCallsCount` field. */
  totalOutboundCallsCount: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `totalPrice` field. */
  totalPrice: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `trade` field. */
  trade: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `trades` field. */
  trades: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Select project with array field trade as each row. When specified, always limit the team result to exactly 1 trade either by filter or grouping, otherwise there will be duplicated results. If specified, use trade property to get the value. */
  tradesAsSeparate: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters list of projects by any of adresses including given string. */
  tradesLike: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
  /** Select project with its timeline. When specified, always limit the timeline result to exactly 1 timeline_stage_id either by filter/group or projectStageConnection, otherwise there will be duplicated results. If specified, use timeline_stage_id, timeline_stage_spent_time property to get the value. */
  withStageTimeline: InputMaybe<Scalars['Boolean']['input']>;
  /** Select project with its status timeline. When specified, always limit the timeline result to exactly 1 timeline_status either by filter/group or projectStageConnection, otherwise there will be duplicated results. If specified, use timeline_status, timeline_status_spent_time property to get the value. */
  withStatusTimeline: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `workerValue` field. */
  workerValue: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `zipcode` field. */
  zipcode: InputMaybe<Scalars['String']['input']>;
};

export type ProjectReportDistinctCountAggregateFilter = {
  accountStatus: InputMaybe<BigIntFilter>;
  accountType: InputMaybe<BigIntFilter>;
  address: InputMaybe<BigIntFilter>;
  addressNormalized: InputMaybe<BigIntFilter>;
  addressPretty: InputMaybe<BigIntFilter>;
  ahj: InputMaybe<BigIntFilter>;
  blueprintId: InputMaybe<BigIntFilter>;
  city: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionDate: InputMaybe<BigIntFilter>;
  completionTime: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  createdByType: InputMaybe<BigIntFilter>;
  dealSize: InputMaybe<BigIntFilter>;
  dealValue: InputMaybe<BigIntFilter>;
  dealValueWeighted: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  dropdownValue: InputMaybe<BigIntFilter>;
  freshProject: InputMaybe<BigIntFilter>;
  geoLocation: InputMaybe<BigIntFilter>;
  geoLocationGeography: InputMaybe<BigIntFilter>;
  geoLocationNormalized: InputMaybe<BigIntFilter>;
  homeOwnerEmail: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  imageUrl: InputMaybe<BigIntFilter>;
  isActive: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  jurisdictionId: InputMaybe<BigIntFilter>;
  lastActivity: InputMaybe<BigIntFilter>;
  lastInboundCallDisposition: InputMaybe<BigIntFilter>;
  lastInboundCallTime: InputMaybe<BigIntFilter>;
  lastInboundEmailTime: InputMaybe<BigIntFilter>;
  lastInboundSmsTime: InputMaybe<BigIntFilter>;
  lastOutboundCallDisposition: InputMaybe<BigIntFilter>;
  lastOutboundCallTime: InputMaybe<BigIntFilter>;
  lastOutboundEmailTime: InputMaybe<BigIntFilter>;
  lastOutboundSmsTime: InputMaybe<BigIntFilter>;
  numericValue: InputMaybe<BigIntFilter>;
  overdueBy: InputMaybe<BigIntFilter>;
  ownerId: InputMaybe<BigIntFilter>;
  parentProjectId: InputMaybe<BigIntFilter>;
  phone: InputMaybe<BigIntFilter>;
  primaryEmail: InputMaybe<BigIntFilter>;
  primaryPhone: InputMaybe<BigIntFilter>;
  productName: InputMaybe<BigIntFilter>;
  progress: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectManagerId: InputMaybe<BigIntFilter>;
  projectSize: InputMaybe<BigIntFilter>;
  projectValue: InputMaybe<BigIntFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
  requestStatus: InputMaybe<BigIntFilter>;
  revenue: InputMaybe<BigIntFilter>;
  salesRepId: InputMaybe<BigIntFilter>;
  slaRedViolationJobId: InputMaybe<BigIntFilter>;
  slaYellowViolationJobId: InputMaybe<BigIntFilter>;
  source: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  state: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  street: InputMaybe<BigIntFilter>;
  streetViewUrl: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  textValue: InputMaybe<BigIntFilter>;
  timelineStageEndedAt: InputMaybe<BigIntFilter>;
  timelineStageId: InputMaybe<BigIntFilter>;
  timelineStageIsEnded: InputMaybe<BigIntFilter>;
  timelineStageSpentTime: InputMaybe<BigIntFilter>;
  timelineStageStartedAt: InputMaybe<BigIntFilter>;
  timelineStatus: InputMaybe<BigIntFilter>;
  timelineStatusEndedAt: InputMaybe<BigIntFilter>;
  timelineStatusIsEnded: InputMaybe<BigIntFilter>;
  timelineStatusSpentTime: InputMaybe<BigIntFilter>;
  timelineStatusStartedAt: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  totalCost: InputMaybe<BigIntFilter>;
  totalInboundCallsCount: InputMaybe<BigIntFilter>;
  totalOutboundCallsCount: InputMaybe<BigIntFilter>;
  totalPrice: InputMaybe<BigIntFilter>;
  trade: InputMaybe<BigIntFilter>;
  trades: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
  workerValue: InputMaybe<BigIntFilter>;
  zipcode: InputMaybe<BigIntFilter>;
};

export type ProjectReportDistinctCountAggregates = {
  /** Distinct count of accountStatus across the matching connection */
  accountStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of accountType across the matching connection */
  accountType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressNormalized across the matching connection */
  addressNormalized: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressPretty across the matching connection */
  addressPretty: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of ahj across the matching connection */
  ahj: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of city across the matching connection */
  city: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionDate across the matching connection */
  completionDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTime across the matching connection */
  completionTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of confidence across the matching connection */
  confidence: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdByType across the matching connection */
  createdByType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealSize across the matching connection */
  dealSize: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealValue across the matching connection */
  dealValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dropdownValue across the matching connection */
  dropdownValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of freshProject across the matching connection */
  freshProject: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocation across the matching connection */
  geoLocation: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocationGeography across the matching connection */
  geoLocationGeography: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of geoLocationNormalized across the matching connection */
  geoLocationNormalized: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of homeOwnerEmail across the matching connection */
  homeOwnerEmail: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of imageUrl across the matching connection */
  imageUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isActive across the matching connection */
  isActive: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundCallDisposition across the matching connection */
  lastInboundCallDisposition: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundCallDisposition across the matching connection */
  lastOutboundCallDisposition: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of numericValue across the matching connection */
  numericValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phone across the matching connection */
  phone: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of primaryEmail across the matching connection */
  primaryEmail: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of primaryPhone across the matching connection */
  primaryPhone: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of productName across the matching connection */
  productName: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of progress across the matching connection */
  progress: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectSize across the matching connection */
  projectSize: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectValue across the matching connection */
  projectValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of requestStatus across the matching connection */
  requestStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of revenue across the matching connection */
  revenue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slaRedViolationJobId across the matching connection */
  slaRedViolationJobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slaYellowViolationJobId across the matching connection */
  slaYellowViolationJobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of source across the matching connection */
  source: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of street across the matching connection */
  street: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of streetViewUrl across the matching connection */
  streetViewUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of teamId across the matching connection */
  teamId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of textValue across the matching connection */
  textValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStageEndedAt across the matching connection */
  timelineStageEndedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStageIsEnded across the matching connection */
  timelineStageIsEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStageStartedAt across the matching connection */
  timelineStageStartedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatus across the matching connection */
  timelineStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusIsEnded across the matching connection */
  timelineStatusIsEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalCost across the matching connection */
  totalCost: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of trade across the matching connection */
  trade: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of trades across the matching connection */
  trades: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userId across the matching connection */
  userId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of zipcode across the matching connection */
  zipcode: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectReportDistinctCountAggregatesAccountStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesAccountTypeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesAddressNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesAddressPrettyArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesAhjArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCityArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCompletionTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesCreatedByTypeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesDropdownValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesFreshProjectArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesGeoLocationArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesGeoLocationGeographyArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesGeoLocationNormalizedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesHomeOwnerEmailArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesIsActiveArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastInboundCallDispositionArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastOutboundCallDispositionArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesPrimaryEmailArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesPrimaryPhoneArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProductNameArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesRequestStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesSlaRedViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesSlaYellowViolationJobIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesSourceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesStateArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesStreetArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesStreetViewUrlArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTextValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStageEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStageIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStageStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStatusArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStatusIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTradeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTradesArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportDistinctCountAggregatesZipcodeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A filter to be used against `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportFilter = {
  /** Filter by the object’s `accountStatus` field. */
  accountStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `accountType` field. */
  accountType: InputMaybe<StringFilter>;
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringListFilter>;
  /** Filter by the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyFilter>;
  /** Filter by the object’s `addressPretty` field. */
  addressPretty: InputMaybe<StringFilter>;
  /** Filter by the object’s `ahj` field. */
  ahj: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectReportFilter>>;
  /** Filter by the object’s `blueprint` relation. */
  blueprint: InputMaybe<BlueprintFilter>;
  /** A related `blueprint` exists. */
  blueprintExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintId` field. */
  blueprintId: InputMaybe<IntFilter>;
  /** Filter by the object’s `city` field. */
  city: InputMaybe<StringFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completionDate` field. */
  completionDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `confidence` field. */
  confidence: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByContactId` field. */
  createdByContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByType` field. */
  createdByType: InputMaybe<CommentCreatedByFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dealSize` field. */
  dealSize: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealValue` field. */
  dealValue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `dealValueWeighted` field. */
  dealValueWeighted: InputMaybe<FloatFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `dropdownValue` field. */
  dropdownValue: InputMaybe<StringListFilter>;
  /** Filter by the object’s `freshProject` field. */
  freshProject: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `geoLocation` field. */
  geoLocation: InputMaybe<StringListFilter>;
  /** Filter by the object’s `geoLocationGeography` field. */
  geoLocationGeography: InputMaybe<GeographyInterfaceFilter>;
  /** Filter by the object’s `homeOwnerEmail` field. */
  homeOwnerEmail: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `imageUrl` field. */
  imageUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jurisdiction` relation. */
  jurisdiction: InputMaybe<JurisdictionFilter>;
  /** A related `jurisdiction` exists. */
  jurisdictionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `jurisdictionId` field. */
  jurisdictionId: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastActivity` field. */
  lastActivity: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundCallDisposition` field. */
  lastInboundCallDisposition: InputMaybe<CallDispositionFilter>;
  /** Filter by the object’s `lastInboundCallTime` field. */
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundEmailTime` field. */
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastInboundSmsTime` field. */
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundCallDisposition` field. */
  lastOutboundCallDisposition: InputMaybe<CallDispositionFilter>;
  /** Filter by the object’s `lastOutboundCallTime` field. */
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundEmailTime` field. */
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOutboundSmsTime` field. */
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectReportFilter>;
  /** Filter by the object’s `numericValue` field. */
  numericValue: InputMaybe<FloatFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectReportFilter>>;
  /** Filter by the object’s `overdueBy` field. */
  overdueBy: InputMaybe<FloatFilter>;
  /** Filter by the object’s `owner` relation. */
  owner: InputMaybe<UserFilter>;
  /** A related `owner` exists. */
  ownerExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `ownerId` field. */
  ownerId: InputMaybe<IntFilter>;
  /** Filter by the object’s `parentProject` relation. */
  parentProject: InputMaybe<ProjectReportFilter>;
  /** A related `parentProject` exists. */
  parentProjectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `parentProjectId` field. */
  parentProjectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `phone` field. */
  phone: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryEmail` field. */
  primaryEmail: InputMaybe<StringFilter>;
  /** Filter by the object’s `primaryPhone` field. */
  primaryPhone: InputMaybe<StringFilter>;
  /** Filter by the object’s `productName` field. */
  productName: InputMaybe<StringFilter>;
  /** Filter by the object’s `progress` field. */
  progress: InputMaybe<IntFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectManager` relation. */
  projectManager: InputMaybe<UserFilter>;
  /** A related `projectManager` exists. */
  projectManagerExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectManagerId` field. */
  projectManagerId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectPropertiesValues` relation. */
  projectPropertiesValues: InputMaybe<ProjectReportToManyProjectPropertyValueFilter>;
  /** Some related `projectPropertiesValues` exist. */
  projectPropertiesValuesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByParentProjectId` relation. */
  projectReportsByParentProjectId: InputMaybe<ProjectReportToManyProjectReportFilter>;
  /** Some related `projectReportsByParentProjectId` exist. */
  projectReportsByParentProjectIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectSize` field. */
  projectSize: InputMaybe<FloatFilter>;
  /** Filter by the object’s `projectStageUpdates` relation. */
  projectStageUpdates: InputMaybe<ProjectReportToManyProjectStageUpdateFilter>;
  /** Some related `projectStageUpdates` exist. */
  projectStageUpdatesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStatusTimeline` relation. */
  projectStatusTimeline: InputMaybe<ProjectReportToManyProjectStatusTimelineFilter>;
  /** Some related `projectStatusTimeline` exist. */
  projectStatusTimelineExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStatusUpdates` relation. */
  projectStatusUpdates: InputMaybe<ProjectReportToManyProjectStatusUpdateFilter>;
  /** Some related `projectStatusUpdates` exist. */
  projectStatusUpdatesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectValue` field. */
  projectValue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `referrerContactId` field. */
  referrerContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `requestStatus` field. */
  requestStatus: InputMaybe<ProjectRequestStatusFilter>;
  /** Filter by the object’s `revenue` field. */
  revenue: InputMaybe<FloatFilter>;
  /** Filter by the object’s `salesRep` relation. */
  salesRep: InputMaybe<UserFilter>;
  /** A related `salesRep` exists. */
  salesRepExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `salesRepId` field. */
  salesRepId: InputMaybe<IntFilter>;
  /** Filter by the object’s `slaRedViolationJobId` field. */
  slaRedViolationJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `slaYellowViolationJobId` field. */
  slaYellowViolationJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source: InputMaybe<CreatedSourceFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `state` field. */
  state: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<ProjectStatusFilter>;
  /** Filter by the object’s `street` field. */
  street: InputMaybe<StringFilter>;
  /** Filter by the object’s `streetViewUrl` field. */
  streetViewUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** A related `team` exists. */
  teamExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
  /** Filter by the object’s `textValue` field. */
  textValue: InputMaybe<StringFilter>;
  /** Filter by the object’s `timelineStage` relation. */
  timelineStage: InputMaybe<ProjectStageFilter>;
  /** Filter by the object’s `timelineStageEndedAt` field. */
  timelineStageEndedAt: InputMaybe<DatetimeFilter>;
  /** A related `timelineStage` exists. */
  timelineStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `timelineStageId` field. */
  timelineStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `timelineStageIsEnded` field. */
  timelineStageIsEnded: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `timelineStageSpentTime` field. */
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `timelineStageStartedAt` field. */
  timelineStageStartedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `timelineStatus` field. */
  timelineStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `timelineStatusEndedAt` field. */
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `timelineStatusIsEnded` field. */
  timelineStatusIsEnded: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `timelineStatusSpentTime` field. */
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `timelineStatusStartedAt` field. */
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalCost` field. */
  totalCost: InputMaybe<FloatFilter>;
  /** Filter by the object’s `totalInboundCallsCount` field. */
  totalInboundCallsCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalOutboundCallsCount` field. */
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalPrice` field. */
  totalPrice: InputMaybe<FloatFilter>;
  /** Filter by the object’s `trade` field. */
  trade: InputMaybe<StringFilter>;
  /** Filter by the object’s `trades` field. */
  trades: InputMaybe<StringListFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<UserFilter>;
  /** Filter by the object’s `userByWorkerValue` relation. */
  userByWorkerValue: InputMaybe<UserFilter>;
  /** A related `userByWorkerValue` exists. */
  userByWorkerValueExists: InputMaybe<Scalars['Boolean']['input']>;
  /** A related `user` exists. */
  userExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
  /** Filter by the object’s `workerValue` field. */
  workerValue: InputMaybe<IntFilter>;
  /** Filter by the object’s `zipcode` field. */
  zipcode: InputMaybe<StringFilter>;
};

/** Grouping methods for `ProjectReport` for usage during aggregation. */
export enum ProjectReportGroupBy {
  AccountStatus = 'ACCOUNT_STATUS',
  AccountType = 'ACCOUNT_TYPE',
  Address = 'ADDRESS',
  AddressNormalized = 'ADDRESS_NORMALIZED',
  AddressPretty = 'ADDRESS_PRETTY',
  Ahj = 'AHJ',
  BlueprintId = 'BLUEPRINT_ID',
  City = 'CITY',
  CompanyId = 'COMPANY_ID',
  CompletionDate = 'COMPLETION_DATE',
  CompletionDateTruncatedToDay = 'COMPLETION_DATE_TRUNCATED_TO_DAY',
  CompletionDateTruncatedToHour = 'COMPLETION_DATE_TRUNCATED_TO_HOUR',
  CompletionDateTruncatedToMonth = 'COMPLETION_DATE_TRUNCATED_TO_MONTH',
  CompletionDateTruncatedToQuarter = 'COMPLETION_DATE_TRUNCATED_TO_QUARTER',
  CompletionDateTruncatedToWeek = 'COMPLETION_DATE_TRUNCATED_TO_WEEK',
  CompletionDateTruncatedToYear = 'COMPLETION_DATE_TRUNCATED_TO_YEAR',
  CompletionTime = 'COMPLETION_TIME',
  CompletionTimeInS = 'COMPLETION_TIME_IN_S',
  Confidence = 'CONFIDENCE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  CreatedByContactId = 'CREATED_BY_CONTACT_ID',
  CreatedByType = 'CREATED_BY_TYPE',
  DealSize = 'DEAL_SIZE',
  DealValue = 'DEAL_VALUE',
  DealValueWeighted = 'DEAL_VALUE_WEIGHTED',
  Description = 'DESCRIPTION',
  DropdownValue = 'DROPDOWN_VALUE',
  FreshProject = 'FRESH_PROJECT',
  GeoLocation = 'GEO_LOCATION',
  GeoLocationGeography = 'GEO_LOCATION_GEOGRAPHY',
  GeoLocationNormalized = 'GEO_LOCATION_NORMALIZED',
  HomeOwnerEmail = 'HOME_OWNER_EMAIL',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  ImageUrl = 'IMAGE_URL',
  IsActive = 'IS_ACTIVE',
  IsCompleted = 'IS_COMPLETED',
  JurisdictionId = 'JURISDICTION_ID',
  LastActivity = 'LAST_ACTIVITY',
  LastActivityTruncatedToDay = 'LAST_ACTIVITY_TRUNCATED_TO_DAY',
  LastActivityTruncatedToHour = 'LAST_ACTIVITY_TRUNCATED_TO_HOUR',
  LastActivityTruncatedToMonth = 'LAST_ACTIVITY_TRUNCATED_TO_MONTH',
  LastActivityTruncatedToQuarter = 'LAST_ACTIVITY_TRUNCATED_TO_QUARTER',
  LastActivityTruncatedToWeek = 'LAST_ACTIVITY_TRUNCATED_TO_WEEK',
  LastActivityTruncatedToYear = 'LAST_ACTIVITY_TRUNCATED_TO_YEAR',
  LastInboundCallDisposition = 'LAST_INBOUND_CALL_DISPOSITION',
  LastInboundCallTime = 'LAST_INBOUND_CALL_TIME',
  LastInboundCallTimeTruncatedToDay = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_DAY',
  LastInboundCallTimeTruncatedToHour = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_HOUR',
  LastInboundCallTimeTruncatedToMonth = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_MONTH',
  LastInboundCallTimeTruncatedToQuarter = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_QUARTER',
  LastInboundCallTimeTruncatedToWeek = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_WEEK',
  LastInboundCallTimeTruncatedToYear = 'LAST_INBOUND_CALL_TIME_TRUNCATED_TO_YEAR',
  LastInboundEmailTime = 'LAST_INBOUND_EMAIL_TIME',
  LastInboundEmailTimeTruncatedToDay = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_DAY',
  LastInboundEmailTimeTruncatedToHour = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_HOUR',
  LastInboundEmailTimeTruncatedToMonth = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_MONTH',
  LastInboundEmailTimeTruncatedToQuarter = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_QUARTER',
  LastInboundEmailTimeTruncatedToWeek = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_WEEK',
  LastInboundEmailTimeTruncatedToYear = 'LAST_INBOUND_EMAIL_TIME_TRUNCATED_TO_YEAR',
  LastInboundSmsTime = 'LAST_INBOUND_SMS_TIME',
  LastInboundSmsTimeTruncatedToDay = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_DAY',
  LastInboundSmsTimeTruncatedToHour = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_HOUR',
  LastInboundSmsTimeTruncatedToMonth = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_MONTH',
  LastInboundSmsTimeTruncatedToQuarter = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_QUARTER',
  LastInboundSmsTimeTruncatedToWeek = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_WEEK',
  LastInboundSmsTimeTruncatedToYear = 'LAST_INBOUND_SMS_TIME_TRUNCATED_TO_YEAR',
  LastOutboundCallDisposition = 'LAST_OUTBOUND_CALL_DISPOSITION',
  LastOutboundCallTime = 'LAST_OUTBOUND_CALL_TIME',
  LastOutboundCallTimeTruncatedToDay = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_DAY',
  LastOutboundCallTimeTruncatedToHour = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_HOUR',
  LastOutboundCallTimeTruncatedToMonth = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_MONTH',
  LastOutboundCallTimeTruncatedToQuarter = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundCallTimeTruncatedToWeek = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_WEEK',
  LastOutboundCallTimeTruncatedToYear = 'LAST_OUTBOUND_CALL_TIME_TRUNCATED_TO_YEAR',
  LastOutboundEmailTime = 'LAST_OUTBOUND_EMAIL_TIME',
  LastOutboundEmailTimeTruncatedToDay = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_DAY',
  LastOutboundEmailTimeTruncatedToHour = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_HOUR',
  LastOutboundEmailTimeTruncatedToMonth = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_MONTH',
  LastOutboundEmailTimeTruncatedToQuarter = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundEmailTimeTruncatedToWeek = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_WEEK',
  LastOutboundEmailTimeTruncatedToYear = 'LAST_OUTBOUND_EMAIL_TIME_TRUNCATED_TO_YEAR',
  LastOutboundSmsTime = 'LAST_OUTBOUND_SMS_TIME',
  LastOutboundSmsTimeTruncatedToDay = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_DAY',
  LastOutboundSmsTimeTruncatedToHour = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_HOUR',
  LastOutboundSmsTimeTruncatedToMonth = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_MONTH',
  LastOutboundSmsTimeTruncatedToQuarter = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_QUARTER',
  LastOutboundSmsTimeTruncatedToWeek = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_WEEK',
  LastOutboundSmsTimeTruncatedToYear = 'LAST_OUTBOUND_SMS_TIME_TRUNCATED_TO_YEAR',
  NumericValue = 'NUMERIC_VALUE',
  OverdueBy = 'OVERDUE_BY',
  OwnerId = 'OWNER_ID',
  ParentProjectId = 'PARENT_PROJECT_ID',
  Phone = 'PHONE',
  PrimaryEmail = 'PRIMARY_EMAIL',
  PrimaryPhone = 'PRIMARY_PHONE',
  ProductName = 'PRODUCT_NAME',
  Progress = 'PROGRESS',
  ProjectId = 'PROJECT_ID',
  ProjectManagerId = 'PROJECT_MANAGER_ID',
  ProjectSize = 'PROJECT_SIZE',
  ProjectValue = 'PROJECT_VALUE',
  ReferrerContactId = 'REFERRER_CONTACT_ID',
  RequestStatus = 'REQUEST_STATUS',
  Revenue = 'REVENUE',
  SalesRepId = 'SALES_REP_ID',
  SlaRedViolationJobId = 'SLA_RED_VIOLATION_JOB_ID',
  SlaYellowViolationJobId = 'SLA_YELLOW_VIOLATION_JOB_ID',
  Source = 'SOURCE',
  StageId = 'STAGE_ID',
  State = 'STATE',
  Status = 'STATUS',
  Street = 'STREET',
  StreetViewUrl = 'STREET_VIEW_URL',
  TeamId = 'TEAM_ID',
  TextValue = 'TEXT_VALUE',
  TimelineStageEndedAt = 'TIMELINE_STAGE_ENDED_AT',
  TimelineStageEndedAtTruncatedToDay = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_DAY',
  TimelineStageEndedAtTruncatedToHour = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_HOUR',
  TimelineStageEndedAtTruncatedToMonth = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_MONTH',
  TimelineStageEndedAtTruncatedToQuarter = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_QUARTER',
  TimelineStageEndedAtTruncatedToWeek = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_WEEK',
  TimelineStageEndedAtTruncatedToYear = 'TIMELINE_STAGE_ENDED_AT_TRUNCATED_TO_YEAR',
  TimelineStageId = 'TIMELINE_STAGE_ID',
  TimelineStageIsEnded = 'TIMELINE_STAGE_IS_ENDED',
  TimelineStageSpentTime = 'TIMELINE_STAGE_SPENT_TIME',
  TimelineStageStartedAt = 'TIMELINE_STAGE_STARTED_AT',
  TimelineStageStartedAtTruncatedToDay = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_DAY',
  TimelineStageStartedAtTruncatedToHour = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_HOUR',
  TimelineStageStartedAtTruncatedToMonth = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_MONTH',
  TimelineStageStartedAtTruncatedToQuarter = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_QUARTER',
  TimelineStageStartedAtTruncatedToWeek = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_WEEK',
  TimelineStageStartedAtTruncatedToYear = 'TIMELINE_STAGE_STARTED_AT_TRUNCATED_TO_YEAR',
  TimelineStatus = 'TIMELINE_STATUS',
  TimelineStatusEndedAt = 'TIMELINE_STATUS_ENDED_AT',
  TimelineStatusEndedAtTruncatedToDay = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_DAY',
  TimelineStatusEndedAtTruncatedToHour = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_HOUR',
  TimelineStatusEndedAtTruncatedToMonth = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_MONTH',
  TimelineStatusEndedAtTruncatedToQuarter = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_QUARTER',
  TimelineStatusEndedAtTruncatedToWeek = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_WEEK',
  TimelineStatusEndedAtTruncatedToYear = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_YEAR',
  TimelineStatusIsEnded = 'TIMELINE_STATUS_IS_ENDED',
  TimelineStatusSpentTime = 'TIMELINE_STATUS_SPENT_TIME',
  TimelineStatusStartedAt = 'TIMELINE_STATUS_STARTED_AT',
  TimelineStatusStartedAtTruncatedToDay = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_DAY',
  TimelineStatusStartedAtTruncatedToHour = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_HOUR',
  TimelineStatusStartedAtTruncatedToMonth = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_MONTH',
  TimelineStatusStartedAtTruncatedToQuarter = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_QUARTER',
  TimelineStatusStartedAtTruncatedToWeek = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_WEEK',
  TimelineStatusStartedAtTruncatedToYear = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_YEAR',
  Title = 'TITLE',
  TotalCost = 'TOTAL_COST',
  TotalInboundCallsCount = 'TOTAL_INBOUND_CALLS_COUNT',
  TotalOutboundCallsCount = 'TOTAL_OUTBOUND_CALLS_COUNT',
  TotalPrice = 'TOTAL_PRICE',
  Trade = 'TRADE',
  Trades = 'TRADES',
  Type = 'TYPE',
  Uid = 'UID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR',
  UserId = 'USER_ID',
  WorkerValue = 'WORKER_VALUE',
  Zipcode = 'ZIPCODE'
}

export type ProjectReportHavingArrayAggInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingAverageInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingDistinctCountInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectReport` aggregates. */
export type ProjectReportHavingInput = {
  AND: InputMaybe<Array<ProjectReportHavingInput>>;
  OR: InputMaybe<Array<ProjectReportHavingInput>>;
  arrayAgg: InputMaybe<ProjectReportHavingArrayAggInput>;
  average: InputMaybe<ProjectReportHavingAverageInput>;
  distinctCount: InputMaybe<ProjectReportHavingDistinctCountInput>;
  max: InputMaybe<ProjectReportHavingMaxInput>;
  min: InputMaybe<ProjectReportHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectReportHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectReportHavingStddevSampleInput>;
  sum: InputMaybe<ProjectReportHavingSumInput>;
  variancePopulation: InputMaybe<ProjectReportHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectReportHavingVarianceSampleInput>;
};

export type ProjectReportHavingMaxInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingMinInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingStddevPopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingStddevSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingSumInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingVariancePopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

export type ProjectReportHavingVarianceSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  confidence: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  createdByContactId: InputMaybe<HavingIntFilter>;
  dealSize: InputMaybe<HavingFloatFilter>;
  dealValue: InputMaybe<HavingFloatFilter>;
  dealValueWeighted: InputMaybe<HavingFloatFilter>;
  id: InputMaybe<HavingIntFilter>;
  jurisdictionId: InputMaybe<HavingIntFilter>;
  lastActivity: InputMaybe<HavingDatetimeFilter>;
  lastInboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastInboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundCallTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<HavingDatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<HavingDatetimeFilter>;
  numericValue: InputMaybe<HavingFloatFilter>;
  overdueBy: InputMaybe<HavingFloatFilter>;
  ownerId: InputMaybe<HavingIntFilter>;
  parentProjectId: InputMaybe<HavingIntFilter>;
  progress: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectManagerId: InputMaybe<HavingIntFilter>;
  projectSize: InputMaybe<HavingFloatFilter>;
  projectValue: InputMaybe<HavingFloatFilter>;
  referrerContactId: InputMaybe<HavingIntFilter>;
  revenue: InputMaybe<HavingFloatFilter>;
  salesRepId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  teamId: InputMaybe<HavingIntFilter>;
  timelineStageEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStageId: InputMaybe<HavingIntFilter>;
  timelineStageSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStageStartedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  totalCost: InputMaybe<HavingFloatFilter>;
  totalInboundCallsCount: InputMaybe<HavingIntFilter>;
  totalOutboundCallsCount: InputMaybe<HavingIntFilter>;
  totalPrice: InputMaybe<HavingFloatFilter>;
  uid: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
  userId: InputMaybe<HavingIntFilter>;
  workerValue: InputMaybe<HavingIntFilter>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportJurisdictionsByProjectReportParentProjectIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportMaxAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  jurisdictionId: InputMaybe<IntFilter>;
  lastActivity: InputMaybe<DatetimeFilter>;
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<IntFilter>;
  parentProjectId: InputMaybe<IntFilter>;
  progress: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectManagerId: InputMaybe<IntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
  timelineStageEndedAt: InputMaybe<DatetimeFilter>;
  timelineStageId: InputMaybe<IntFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStageStartedAt: InputMaybe<DatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<IntFilter>;
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
  workerValue: InputMaybe<IntFilter>;
};

export type ProjectReportMaxAggregates = {
  /** Maximum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of confidence across the matching connection */
  confidence: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Maximum of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Maximum of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Maximum of ownerId across the matching connection */
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Maximum of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of progress across the matching connection */
  progress: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Maximum of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Maximum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Maximum of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Maximum of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Maximum of timelineStageEndedAt across the matching connection */
  timelineStageEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of timelineStageStartedAt across the matching connection */
  timelineStageStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Maximum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
  /** Maximum of workerValue across the matching connection */
  workerValue: Maybe<Scalars['Int']['output']>;
};


export type ProjectReportMaxAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStageEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStageStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMaxAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportMinAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  confidence: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  createdByContactId: InputMaybe<IntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<IntFilter>;
  jurisdictionId: InputMaybe<IntFilter>;
  lastActivity: InputMaybe<DatetimeFilter>;
  lastInboundCallTime: InputMaybe<DatetimeFilter>;
  lastInboundEmailTime: InputMaybe<DatetimeFilter>;
  lastInboundSmsTime: InputMaybe<DatetimeFilter>;
  lastOutboundCallTime: InputMaybe<DatetimeFilter>;
  lastOutboundEmailTime: InputMaybe<DatetimeFilter>;
  lastOutboundSmsTime: InputMaybe<DatetimeFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<IntFilter>;
  parentProjectId: InputMaybe<IntFilter>;
  progress: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectManagerId: InputMaybe<IntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<IntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
  timelineStageEndedAt: InputMaybe<DatetimeFilter>;
  timelineStageId: InputMaybe<IntFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStageStartedAt: InputMaybe<DatetimeFilter>;
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<IntFilter>;
  totalOutboundCallsCount: InputMaybe<IntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
  userId: InputMaybe<IntFilter>;
  workerValue: InputMaybe<IntFilter>;
};

export type ProjectReportMinAggregates = {
  /** Minimum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of confidence across the matching connection */
  confidence: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Minimum of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['Int']['output']>;
  /** Minimum of lastActivity across the matching connection */
  lastActivity: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundCallTime across the matching connection */
  lastInboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundEmailTime across the matching connection */
  lastInboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastInboundSmsTime across the matching connection */
  lastInboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundCallTime across the matching connection */
  lastOutboundCallTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundEmailTime across the matching connection */
  lastOutboundEmailTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of lastOutboundSmsTime across the matching connection */
  lastOutboundSmsTime: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Minimum of ownerId across the matching connection */
  ownerId: Maybe<Scalars['Int']['output']>;
  /** Minimum of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of progress across the matching connection */
  progress: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Minimum of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Minimum of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['Int']['output']>;
  /** Minimum of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Minimum of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Minimum of timelineStageEndedAt across the matching connection */
  timelineStageEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of timelineStageStartedAt across the matching connection */
  timelineStageStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Minimum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of userId across the matching connection */
  userId: Maybe<Scalars['Int']['output']>;
  /** Minimum of workerValue across the matching connection */
  workerValue: Maybe<Scalars['Int']['output']>;
};


export type ProjectReportMinAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastActivityArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastInboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastInboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastInboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastOutboundCallTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastOutboundEmailTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesLastOutboundSmsTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStageEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStageStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportMinAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectPropertyValue`. */
export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectPropertyValue`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectPropertyValue`. */
export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValues: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertiesValuesConnection: ProjectPropertyValuesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdgeProjectPropertiesValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectPropertyValue`. */
export type ProjectReportProjectsByProjectPropertyValueProjectIdAndProjectIdManyToManyEdgeProjectPropertiesValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportProjectsByProjectReportParentProjectIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectStageUpdate`. */
export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectStageUpdate`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectStageUpdate`. */
export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdates: Array<ProjectStageUpdate>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesConnection: ProjectStageUpdatesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdgeProjectStageUpdatesArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectStageUpdate`. */
export type ProjectReportProjectsByProjectStageUpdateProjectIdAndProjectIdManyToManyEdgeProjectStageUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectStatusTimeline`. */
export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectStatusTimeline`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectStatusTimeline`. */
export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimeline: Array<ProjectStatusTimeline>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimelineConnection: ProjectStatusTimelinesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdgeProjectStatusTimelineArgs = {
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectStatusTimeline`. */
export type ProjectReportProjectsByProjectStatusTimelineProjectIdAndProjectIdManyToManyEdgeProjectStatusTimelineConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectStatusUpdate`. */
export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectStatusUpdate`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectStatusUpdate`. */
export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdates: Array<ProjectStatusUpdate>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdatesConnection: ProjectStatusUpdatesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdgeProjectStatusUpdatesArgs = {
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectStatusUpdate`. */
export type ProjectReportProjectsByProjectStatusUpdateProjectIdAndProjectIdManyToManyEdgeProjectStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectReportStddevPopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  timelineStageId: InputMaybe<BigFloatFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectReportStddevPopulationAggregates = {
  /** Population standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectReportStddevPopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevPopulationAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportStddevSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  timelineStageId: InputMaybe<BigFloatFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectReportStddevSampleAggregates = {
  /** Sample standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectReportStddevSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportStddevSampleAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportSumAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigIntFilter>;
  jurisdictionId: InputMaybe<BigIntFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigIntFilter>;
  parentProjectId: InputMaybe<BigIntFilter>;
  progress: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectManagerId: InputMaybe<BigIntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  timelineStageId: InputMaybe<BigIntFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigIntFilter>;
  totalOutboundCallsCount: InputMaybe<BigIntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
  workerValue: InputMaybe<BigIntFilter>;
};

export type ProjectReportSumAggregates = {
  /** Sum of blueprintId across the matching connection */
  blueprintId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of completionTimeInS across the matching connection */
  completionTimeInS: Scalars['BigFloat']['output'];
  /** Sum of confidence across the matching connection */
  confidence: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of createdByContactId across the matching connection */
  createdByContactId: Scalars['BigInt']['output'];
  /** Sum of dealSize across the matching connection */
  dealSize: Scalars['Float']['output'];
  /** Sum of dealValue across the matching connection */
  dealValue: Scalars['Float']['output'];
  /** Sum of dealValueWeighted across the matching connection */
  dealValueWeighted: Scalars['Float']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of jurisdictionId across the matching connection */
  jurisdictionId: Scalars['BigInt']['output'];
  /** Sum of numericValue across the matching connection */
  numericValue: Scalars['Float']['output'];
  /** Sum of overdueBy across the matching connection */
  overdueBy: Scalars['Float']['output'];
  /** Sum of ownerId across the matching connection */
  ownerId: Scalars['BigInt']['output'];
  /** Sum of parentProjectId across the matching connection */
  parentProjectId: Scalars['BigInt']['output'];
  /** Sum of progress across the matching connection */
  progress: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of projectManagerId across the matching connection */
  projectManagerId: Scalars['BigInt']['output'];
  /** Sum of projectSize across the matching connection */
  projectSize: Scalars['Float']['output'];
  /** Sum of projectValue across the matching connection */
  projectValue: Scalars['Float']['output'];
  /** Sum of referrerContactId across the matching connection */
  referrerContactId: Scalars['BigInt']['output'];
  /** Sum of revenue across the matching connection */
  revenue: Scalars['Float']['output'];
  /** Sum of salesRepId across the matching connection */
  salesRepId: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
  /** Sum of teamId across the matching connection */
  teamId: Scalars['BigInt']['output'];
  /** Sum of timelineStageId across the matching connection */
  timelineStageId: Scalars['BigInt']['output'];
  /** Sum of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Scalars['Float']['output'];
  /** Sum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Scalars['Float']['output'];
  /** Sum of totalCost across the matching connection */
  totalCost: Scalars['Float']['output'];
  /** Sum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Scalars['BigInt']['output'];
  /** Sum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Scalars['BigInt']['output'];
  /** Sum of totalPrice across the matching connection */
  totalPrice: Scalars['Float']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt']['output'];
  /** Sum of workerValue across the matching connection */
  workerValue: Scalars['BigInt']['output'];
};


export type ProjectReportSumAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportSumAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportTeamsByProjectReportParentProjectIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A filter to be used against many `ProjectPropertyValue` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportToManyProjectPropertyValueFilter = {
  /** Aggregates across related `ProjectPropertyValue` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueAggregatesFilter>;
  /** Every related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFilter>;
  /** No related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFilter>;
  /** Some related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `ProjectStageUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportToManyProjectStageUpdateFilter = {
  /** Aggregates across related `ProjectStageUpdate` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageUpdateAggregatesFilter>;
  /** Every related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageUpdateFilter>;
  /** No related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageUpdateFilter>;
  /** Some related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageUpdateFilter>;
};

/** A filter to be used against many `ProjectStatusTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportToManyProjectStatusTimelineFilter = {
  /** Aggregates across related `ProjectStatusTimeline` match the filter criteria. */
  aggregates: InputMaybe<ProjectStatusTimelineAggregatesFilter>;
  /** Every related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStatusTimelineFilter>;
  /** No related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStatusTimelineFilter>;
  /** Some related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStatusTimelineFilter>;
};

/** A filter to be used against many `ProjectStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReportToManyProjectStatusUpdateFilter = {
  /** Aggregates across related `ProjectStatusUpdate` match the filter criteria. */
  aggregates: InputMaybe<ProjectStatusUpdateAggregatesFilter>;
  /** Every related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStatusUpdateFilter>;
  /** No related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStatusUpdateFilter>;
  /** Some related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStatusUpdateFilter>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectReportUsersByProjectReportParentProjectIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportVariancePopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  timelineStageId: InputMaybe<BigFloatFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectReportVariancePopulationAggregates = {
  /** Population variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Population variance of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Population variance of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Population variance of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Population variance of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population variance of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population variance of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Population variance of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectReportVariancePopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVariancePopulationAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectReportVarianceSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  numericValue: InputMaybe<FloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  timelineStageId: InputMaybe<BigFloatFilter>;
  timelineStageSpentTime: InputMaybe<FloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
  workerValue: InputMaybe<BigFloatFilter>;
};

export type ProjectReportVarianceSampleAggregates = {
  /** Sample variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Sample variance of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of numericValue across the matching connection */
  numericValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Sample variance of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Sample variance of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of timelineStageId across the matching connection */
  timelineStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of timelineStageSpentTime across the matching connection */
  timelineStageSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample variance of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample variance of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Sample variance of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of userId across the matching connection */
  userId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of workerValue across the matching connection */
  workerValue: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectReportVarianceSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesNumericValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTimelineStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTimelineStageSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesUserIdArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectReportVarianceSampleAggregatesWorkerValueArgs = {
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values. */
export type ProjectReportsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport` and cursor to aid in pagination. */
  edges: Array<ProjectReportsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values. */
export type ProjectReportsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection. */
export type ProjectReportsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
};

/** Methods to use when ordering `ProjectReport`. */
export enum ProjectReportsOrderBy {
  AccountStatusAsc = 'ACCOUNT_STATUS_ASC',
  AccountStatusDesc = 'ACCOUNT_STATUS_DESC',
  AccountTypeAsc = 'ACCOUNT_TYPE_ASC',
  AccountTypeDesc = 'ACCOUNT_TYPE_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AddressNormalizedAsc = 'ADDRESS_NORMALIZED_ASC',
  AddressNormalizedDesc = 'ADDRESS_NORMALIZED_DESC',
  AddressPrettyAsc = 'ADDRESS_PRETTY_ASC',
  AddressPrettyDesc = 'ADDRESS_PRETTY_DESC',
  AhjAsc = 'AHJ_ASC',
  AhjDesc = 'AHJ_DESC',
  BlueprintIdAsc = 'BLUEPRINT_ID_ASC',
  BlueprintIdDesc = 'BLUEPRINT_ID_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletionDateAsc = 'COMPLETION_DATE_ASC',
  CompletionDateDesc = 'COMPLETION_DATE_DESC',
  CompletionTimeAsc = 'COMPLETION_TIME_ASC',
  CompletionTimeDesc = 'COMPLETION_TIME_DESC',
  CompletionTimeInSAsc = 'COMPLETION_TIME_IN_S_ASC',
  CompletionTimeInSDesc = 'COMPLETION_TIME_IN_S_DESC',
  ConfidenceAsc = 'CONFIDENCE_ASC',
  ConfidenceDesc = 'CONFIDENCE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByContactIdAsc = 'CREATED_BY_CONTACT_ID_ASC',
  CreatedByContactIdDesc = 'CREATED_BY_CONTACT_ID_DESC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedByTypeAsc = 'CREATED_BY_TYPE_ASC',
  CreatedByTypeDesc = 'CREATED_BY_TYPE_DESC',
  DealSizeAsc = 'DEAL_SIZE_ASC',
  DealSizeDesc = 'DEAL_SIZE_DESC',
  DealValueAsc = 'DEAL_VALUE_ASC',
  DealValueDesc = 'DEAL_VALUE_DESC',
  DealValueWeightedAsc = 'DEAL_VALUE_WEIGHTED_ASC',
  DealValueWeightedDesc = 'DEAL_VALUE_WEIGHTED_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DropdownValueAsc = 'DROPDOWN_VALUE_ASC',
  DropdownValueDesc = 'DROPDOWN_VALUE_DESC',
  FreshProjectAsc = 'FRESH_PROJECT_ASC',
  FreshProjectDesc = 'FRESH_PROJECT_DESC',
  GeoLocationAsc = 'GEO_LOCATION_ASC',
  GeoLocationDesc = 'GEO_LOCATION_DESC',
  GeoLocationGeographyAsc = 'GEO_LOCATION_GEOGRAPHY_ASC',
  GeoLocationGeographyDesc = 'GEO_LOCATION_GEOGRAPHY_DESC',
  GeoLocationNormalizedAsc = 'GEO_LOCATION_NORMALIZED_ASC',
  GeoLocationNormalizedDesc = 'GEO_LOCATION_NORMALIZED_DESC',
  HomeOwnerEmailAsc = 'HOME_OWNER_EMAIL_ASC',
  HomeOwnerEmailDesc = 'HOME_OWNER_EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  JurisdictionIdAsc = 'JURISDICTION_ID_ASC',
  JurisdictionIdDesc = 'JURISDICTION_ID_DESC',
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  LastActivityDesc = 'LAST_ACTIVITY_DESC',
  LastInboundCallDispositionAsc = 'LAST_INBOUND_CALL_DISPOSITION_ASC',
  LastInboundCallDispositionDesc = 'LAST_INBOUND_CALL_DISPOSITION_DESC',
  LastInboundCallTimeAsc = 'LAST_INBOUND_CALL_TIME_ASC',
  LastInboundCallTimeDesc = 'LAST_INBOUND_CALL_TIME_DESC',
  LastInboundEmailTimeAsc = 'LAST_INBOUND_EMAIL_TIME_ASC',
  LastInboundEmailTimeDesc = 'LAST_INBOUND_EMAIL_TIME_DESC',
  LastInboundSmsTimeAsc = 'LAST_INBOUND_SMS_TIME_ASC',
  LastInboundSmsTimeDesc = 'LAST_INBOUND_SMS_TIME_DESC',
  LastOutboundCallDispositionAsc = 'LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  LastOutboundCallDispositionDesc = 'LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  LastOutboundCallTimeAsc = 'LAST_OUTBOUND_CALL_TIME_ASC',
  LastOutboundCallTimeDesc = 'LAST_OUTBOUND_CALL_TIME_DESC',
  LastOutboundEmailTimeAsc = 'LAST_OUTBOUND_EMAIL_TIME_ASC',
  LastOutboundEmailTimeDesc = 'LAST_OUTBOUND_EMAIL_TIME_DESC',
  LastOutboundSmsTimeAsc = 'LAST_OUTBOUND_SMS_TIME_ASC',
  LastOutboundSmsTimeDesc = 'LAST_OUTBOUND_SMS_TIME_DESC',
  Natural = 'NATURAL',
  NumericValueAsc = 'NUMERIC_VALUE_ASC',
  NumericValueDesc = 'NUMERIC_VALUE_DESC',
  OverdueByAsc = 'OVERDUE_BY_ASC',
  OverdueByDesc = 'OVERDUE_BY_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  ParentProjectIdAsc = 'PARENT_PROJECT_ID_ASC',
  ParentProjectIdDesc = 'PARENT_PROJECT_ID_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryEmailAsc = 'PRIMARY_EMAIL_ASC',
  PrimaryEmailDesc = 'PRIMARY_EMAIL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrimaryPhoneAsc = 'PRIMARY_PHONE_ASC',
  PrimaryPhoneDesc = 'PRIMARY_PHONE_DESC',
  ProductNameAsc = 'PRODUCT_NAME_ASC',
  ProductNameDesc = 'PRODUCT_NAME_DESC',
  ProgressAsc = 'PROGRESS_ASC',
  ProgressDesc = 'PROGRESS_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectManagerIdAsc = 'PROJECT_MANAGER_ID_ASC',
  ProjectManagerIdDesc = 'PROJECT_MANAGER_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionAverageColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionAverageDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionAverageDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionAverageDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertiesValuesConnectionAverageIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertiesValuesConnectionAverageNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionAverageProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionAverageTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionCountAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_COUNT_ASC',
  ProjectPropertiesValuesConnectionCountDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_COUNT_DESC',
  ProjectPropertiesValuesConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionMaxColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionMaxDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionMaxDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionMaxDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_ID_ASC',
  ProjectPropertiesValuesConnectionMaxIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_ID_DESC',
  ProjectPropertiesValuesConnectionMaxNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionMaxProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionMaxTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionMinColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionMinDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionMinDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionMinDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_ID_ASC',
  ProjectPropertiesValuesConnectionMinIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_ID_DESC',
  ProjectPropertiesValuesConnectionMinNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionMinProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionMinTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionSumColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionSumDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionSumDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionSumDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_ID_ASC',
  ProjectPropertiesValuesConnectionSumIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_ID_DESC',
  ProjectPropertiesValuesConnectionSumNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionSumProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionSumTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByParentProjectIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByParentProjectIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByParentProjectIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByParentProjectIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByParentProjectIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectSizeAsc = 'PROJECT_SIZE_ASC',
  ProjectSizeDesc = 'PROJECT_SIZE_DESC',
  ProjectStageUpdatesConnectionArrayAggCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionArrayAggCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionArrayAggIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectStageUpdatesConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionAverageCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionAverageCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionAverageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_ID_ASC',
  ProjectStageUpdatesConnectionAverageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_ID_DESC',
  ProjectStageUpdatesConnectionAverageProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionAverageProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionAverageStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionAverageStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionCountAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_COUNT_ASC',
  ProjectStageUpdatesConnectionCountDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_COUNT_DESC',
  ProjectStageUpdatesConnectionDistinctCountCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionDistinctCountCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionDistinctCountIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectStageUpdatesConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionMaxCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionMaxCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionMaxIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_ID_ASC',
  ProjectStageUpdatesConnectionMaxIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_ID_DESC',
  ProjectStageUpdatesConnectionMaxProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionMaxProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionMaxStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionMaxStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionMinCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionMinCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionMinIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_ID_ASC',
  ProjectStageUpdatesConnectionMinIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_ID_DESC',
  ProjectStageUpdatesConnectionMinProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionMinProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionMinStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionMinStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionStddevPopulationIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionStddevSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionStddevSampleIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionSumCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionSumCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionSumIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_ID_ASC',
  ProjectStageUpdatesConnectionSumIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_ID_DESC',
  ProjectStageUpdatesConnectionSumProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionSumProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionSumStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionSumStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionVariancePopulationIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionVarianceSampleIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectStatusTimelineConnectionArrayAggCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_COUNT_ASC',
  ProjectStatusTimelineConnectionArrayAggCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_COUNT_DESC',
  ProjectStatusTimelineConnectionArrayAggEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionArrayAggEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionArrayAggIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionArrayAggIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionArrayAggProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionArrayAggProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionArrayAggSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionArrayAggSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionArrayAggStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionArrayAggStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionArrayAggStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectStatusTimelineConnectionArrayAggStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectStatusTimelineConnectionAverageCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_COUNT_ASC',
  ProjectStatusTimelineConnectionAverageCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_COUNT_DESC',
  ProjectStatusTimelineConnectionAverageEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionAverageEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionAverageIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionAverageIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionAverageProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionAverageProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionAverageSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionAverageSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionAverageStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionAverageStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionAverageStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectStatusTimelineConnectionAverageStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectStatusTimelineConnectionCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_COUNT_ASC',
  ProjectStatusTimelineConnectionCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_COUNT_DESC',
  ProjectStatusTimelineConnectionDistinctCountCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  ProjectStatusTimelineConnectionDistinctCountCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  ProjectStatusTimelineConnectionDistinctCountEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionDistinctCountEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionDistinctCountIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionDistinctCountIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionDistinctCountProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionDistinctCountProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionDistinctCountSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionDistinctCountSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionDistinctCountStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionDistinctCountStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionDistinctCountStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectStatusTimelineConnectionDistinctCountStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectStatusTimelineConnectionMaxCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_COUNT_ASC',
  ProjectStatusTimelineConnectionMaxCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_COUNT_DESC',
  ProjectStatusTimelineConnectionMaxEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionMaxEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionMaxIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionMaxIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionMaxProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionMaxProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionMaxSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionMaxSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionMaxStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionMaxStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionMaxStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STATUS_ASC',
  ProjectStatusTimelineConnectionMaxStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STATUS_DESC',
  ProjectStatusTimelineConnectionMinCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_COUNT_ASC',
  ProjectStatusTimelineConnectionMinCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_COUNT_DESC',
  ProjectStatusTimelineConnectionMinEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionMinEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionMinIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionMinIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionMinProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionMinProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionMinSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionMinSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionMinStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionMinStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionMinStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STATUS_ASC',
  ProjectStatusTimelineConnectionMinStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STATUS_DESC',
  ProjectStatusTimelineConnectionStddevPopulationCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionStddevPopulationIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionStddevPopulationProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionStddevPopulationProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionStddevPopulationSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionStddevPopulationSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionStddevPopulationStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectStatusTimelineConnectionStddevPopulationStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectStatusTimelineConnectionStddevSampleCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  ProjectStatusTimelineConnectionStddevSampleCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  ProjectStatusTimelineConnectionStddevSampleEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionStddevSampleEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionStddevSampleIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionStddevSampleIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionStddevSampleProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionStddevSampleProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionStddevSampleSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionStddevSampleSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionStddevSampleStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionStddevSampleStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionStddevSampleStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectStatusTimelineConnectionStddevSampleStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectStatusTimelineConnectionSumCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_COUNT_ASC',
  ProjectStatusTimelineConnectionSumCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_COUNT_DESC',
  ProjectStatusTimelineConnectionSumEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionSumEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionSumIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionSumIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionSumProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionSumProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionSumSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionSumSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionSumStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionSumStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionSumStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STATUS_ASC',
  ProjectStatusTimelineConnectionSumStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STATUS_DESC',
  ProjectStatusTimelineConnectionVariancePopulationCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionVariancePopulationIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionVariancePopulationProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionVariancePopulationProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionVariancePopulationSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionVariancePopulationSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionVariancePopulationStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectStatusTimelineConnectionVariancePopulationStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectStatusTimelineConnectionVarianceSampleCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionVarianceSampleIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionVarianceSampleProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionVarianceSampleProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionVarianceSampleSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionVarianceSampleSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionVarianceSampleStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectStatusTimelineConnectionVarianceSampleStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectStatusUpdatesConnectionArrayAggCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionArrayAggCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionArrayAggIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectStatusUpdatesConnectionArrayAggIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectStatusUpdatesConnectionArrayAggProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionArrayAggProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionArrayAggStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectStatusUpdatesConnectionArrayAggStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectStatusUpdatesConnectionAverageCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionAverageCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionAverageIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_ID_ASC',
  ProjectStatusUpdatesConnectionAverageIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_ID_DESC',
  ProjectStatusUpdatesConnectionAverageProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionAverageProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionAverageStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectStatusUpdatesConnectionAverageStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectStatusUpdatesConnectionCountAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_COUNT_ASC',
  ProjectStatusUpdatesConnectionCountDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_COUNT_DESC',
  ProjectStatusUpdatesConnectionDistinctCountCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionDistinctCountCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionDistinctCountIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectStatusUpdatesConnectionDistinctCountIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectStatusUpdatesConnectionDistinctCountProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionDistinctCountProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionDistinctCountStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectStatusUpdatesConnectionDistinctCountStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectStatusUpdatesConnectionMaxCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionMaxCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionMaxIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_ID_ASC',
  ProjectStatusUpdatesConnectionMaxIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_ID_DESC',
  ProjectStatusUpdatesConnectionMaxProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionMaxProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionMaxStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_STATUS_ASC',
  ProjectStatusUpdatesConnectionMaxStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_STATUS_DESC',
  ProjectStatusUpdatesConnectionMinCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionMinCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionMinIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_ID_ASC',
  ProjectStatusUpdatesConnectionMinIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_ID_DESC',
  ProjectStatusUpdatesConnectionMinProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionMinProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionMinStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_STATUS_ASC',
  ProjectStatusUpdatesConnectionMinStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_STATUS_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectStatusUpdatesConnectionStddevSampleCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionStddevSampleCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionStddevSampleIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectStatusUpdatesConnectionStddevSampleIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectStatusUpdatesConnectionStddevSampleProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionStddevSampleProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionStddevSampleStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectStatusUpdatesConnectionStddevSampleStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectStatusUpdatesConnectionSumCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionSumCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionSumIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_ID_ASC',
  ProjectStatusUpdatesConnectionSumIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_ID_DESC',
  ProjectStatusUpdatesConnectionSumProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionSumProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionSumStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_STATUS_ASC',
  ProjectStatusUpdatesConnectionSumStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_STATUS_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectValueAsc = 'PROJECT_VALUE_ASC',
  ProjectValueDesc = 'PROJECT_VALUE_DESC',
  ReferrerContactIdAsc = 'REFERRER_CONTACT_ID_ASC',
  ReferrerContactIdDesc = 'REFERRER_CONTACT_ID_DESC',
  RequestStatusAsc = 'REQUEST_STATUS_ASC',
  RequestStatusDesc = 'REQUEST_STATUS_DESC',
  RevenueAsc = 'REVENUE_ASC',
  RevenueDesc = 'REVENUE_DESC',
  SalesRepIdAsc = 'SALES_REP_ID_ASC',
  SalesRepIdDesc = 'SALES_REP_ID_DESC',
  SlaRedViolationJobIdAsc = 'SLA_RED_VIOLATION_JOB_ID_ASC',
  SlaRedViolationJobIdDesc = 'SLA_RED_VIOLATION_JOB_ID_DESC',
  SlaYellowViolationJobIdAsc = 'SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  SlaYellowViolationJobIdDesc = 'SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  StreetViewUrlAsc = 'STREET_VIEW_URL_ASC',
  StreetViewUrlDesc = 'STREET_VIEW_URL_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  TextValueAsc = 'TEXT_VALUE_ASC',
  TextValueDesc = 'TEXT_VALUE_DESC',
  TimelineStageEndedAtAsc = 'TIMELINE_STAGE_ENDED_AT_ASC',
  TimelineStageEndedAtDesc = 'TIMELINE_STAGE_ENDED_AT_DESC',
  TimelineStageIdAsc = 'TIMELINE_STAGE_ID_ASC',
  TimelineStageIdDesc = 'TIMELINE_STAGE_ID_DESC',
  TimelineStageIsEndedAsc = 'TIMELINE_STAGE_IS_ENDED_ASC',
  TimelineStageIsEndedDesc = 'TIMELINE_STAGE_IS_ENDED_DESC',
  TimelineStageSpentTimeAsc = 'TIMELINE_STAGE_SPENT_TIME_ASC',
  TimelineStageSpentTimeDesc = 'TIMELINE_STAGE_SPENT_TIME_DESC',
  TimelineStageStartedAtAsc = 'TIMELINE_STAGE_STARTED_AT_ASC',
  TimelineStageStartedAtDesc = 'TIMELINE_STAGE_STARTED_AT_DESC',
  TimelineStatusAsc = 'TIMELINE_STATUS_ASC',
  TimelineStatusDesc = 'TIMELINE_STATUS_DESC',
  TimelineStatusEndedAtAsc = 'TIMELINE_STATUS_ENDED_AT_ASC',
  TimelineStatusEndedAtDesc = 'TIMELINE_STATUS_ENDED_AT_DESC',
  TimelineStatusIsEndedAsc = 'TIMELINE_STATUS_IS_ENDED_ASC',
  TimelineStatusIsEndedDesc = 'TIMELINE_STATUS_IS_ENDED_DESC',
  TimelineStatusSpentTimeAsc = 'TIMELINE_STATUS_SPENT_TIME_ASC',
  TimelineStatusSpentTimeDesc = 'TIMELINE_STATUS_SPENT_TIME_DESC',
  TimelineStatusStartedAtAsc = 'TIMELINE_STATUS_STARTED_AT_ASC',
  TimelineStatusStartedAtDesc = 'TIMELINE_STATUS_STARTED_AT_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TotalCostAsc = 'TOTAL_COST_ASC',
  TotalCostDesc = 'TOTAL_COST_DESC',
  TotalInboundCallsCountAsc = 'TOTAL_INBOUND_CALLS_COUNT_ASC',
  TotalInboundCallsCountDesc = 'TOTAL_INBOUND_CALLS_COUNT_DESC',
  TotalOutboundCallsCountAsc = 'TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  TotalOutboundCallsCountDesc = 'TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  TradesAsc = 'TRADES_ASC',
  TradesDesc = 'TRADES_DESC',
  TradeAsc = 'TRADE_ASC',
  TradeDesc = 'TRADE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WorkerValueAsc = 'WORKER_VALUE_ASC',
  WorkerValueDesc = 'WORKER_VALUE_DESC',
  ZipcodeAsc = 'ZIPCODE_ASC',
  ZipcodeDesc = 'ZIPCODE_DESC'
}

export enum ProjectRequestStatus {
  AppointmentCompleted = 'APPOINTMENT_COMPLETED',
  AppointmentScheduled = 'APPOINTMENT_SCHEDULED',
  New = 'NEW',
  Overdue = 'OVERDUE'
}

/** A filter to be used against ProjectRequestStatus fields. All fields are combined with a logical ‘and.’ */
export type ProjectRequestStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ProjectRequestStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ProjectRequestStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ProjectRequestStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ProjectRequestStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ProjectRequestStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ProjectRequestStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ProjectRequestStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ProjectRequestStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ProjectRequestStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ProjectRequestStatus>>;
};

export type ProjectStage = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actions: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileStageIdAndActionId: ProjectStageActionsByFileStageIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileStageIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsConnection: ActionsConnection;
  /** Reads and enables pagination through a set of `BlueprintProjectStage`. */
  blueprintProjectStages: Array<BlueprintProjectStage>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportStageIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportTimelineStageIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectStageIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectStageTimelineStageIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `ColumnGroup`. */
  columnGroupsByStageId: Array<ColumnGroup>;
  /** Reads and enables pagination through a set of `ColumnGroup`. */
  columnGroupsByStageIdConnection: ColumnGroupsConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileStageIdAndCommentId: ProjectStageCommentsByFileStageIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskProjectStageIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportStageIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportTimelineStageIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectStageIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTaskReportProjectStageIdAndCompanyIdList: Array<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileStageIdAndCreatedByContactId: ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileStageIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectStageIdAndCreatedByContactId: ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectStageIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectStageIdAndReferrerContactId: ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectStageIdAndReferrerContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  description: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileStageIdAndValueFileId: ProjectStageFilesByFileStageIdAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileStageIdAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormStageIdAndFileId: ProjectStageFilesByFormStageIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormStageIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByStageId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByStageIdConnection: FilesConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileStageIdAndFormId: ProjectStageFormsByFileStageIdAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileStageIdAndFormIdList: Array<Form>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByStageId: Array<Form>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByStageIdConnection: FormsConnection;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportStageIdAndJurisdictionId: ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportStageIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportTimelineStageIdAndJurisdictionId: ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectStageIdAndJurisdictionId: ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectStageIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskProjectStageIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByTaskReportProjectStageIdAndFirstLabelIdList: Array<Label>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskProjectStageIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskProjectStageIdAndCreatedByList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportProjectStageIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByTaskReportProjectStageIdAndCreatedByList: Array<MainUser>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskId: ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportProjectStageIdAndTaskId: ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskReportProjectStageIdAndTemplateTaskId: ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksConnection: PrivilegedTasksConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportStageIdAndParentProjectId: ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportStageIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportTimelineStageIdAndParentProjectId: ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportTimelineStageIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByStageId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByStageIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByTimelineStageId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByTimelineStageIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByStageId: Array<ProjectStageTimeline>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByStageIdConnection: ProjectStageTimelinesConnection;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesByStageId: Array<ProjectStageUpdate>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesByStageIdConnection: ProjectStageUpdatesConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportStageIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportTimelineStageIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileStageIdAndProjectId: ProjectStageProjectsByFileStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileStageIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskProjectStageIdAndProjectId: ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskProjectStageIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportStageIdAndProjectId: ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportStageIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportTimelineStageIdAndProjectId: ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportTimelineStageIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageIdAndParentProjectId: ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageTimelineStageIdAndProjectId: ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectStageTimelineStageIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByStageId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByStageIdConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportProjectStageIdAndProjectId: ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByTaskReportProjectStageIdAndProjectIdList: Array<Project>;
  redSla: Maybe<Scalars['Int']['output']>;
  scope: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `System`. */
  systemsByPrivilegedTaskProjectStageIdAndSystemId: ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsConnection: TaskReportsConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportStageIdAndTeamId: ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportStageIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportTimelineStageIdAndTeamId: ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportTimelineStageIdAndTeamIdList: Array<Team>;
  type: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByFileStageIdAndCreatedBy: ProjectStageUsersByFileStageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndCreatedBy: ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndOwnerId: ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndProjectManagerId: ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndSalesRepId: ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndUserId: ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportStageIdAndWorkerValue: ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndCreatedBy: ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndOwnerId: ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndProjectManagerId: ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndSalesRepId: ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndUserId: ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTimelineStageIdAndWorkerValue: ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectStageIdAndCreatedBy: ProjectStageUsersByProjectStageIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectStageIdAndOwnerId: ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectStageIdAndProjectManagerId: ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectStageIdAndSalesRepId: ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyConnection;
  yellowSla: Maybe<Scalars['Int']['output']>;
};


export type ProjectStageActionsArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectStageActionsByFileStageIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectStageActionsByFileStageIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectStageActionsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ProjectStageBlueprintProjectStagesArgs = {
  condition: InputMaybe<BlueprintProjectStageCondition>;
  filter: InputMaybe<BlueprintProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintProjectStagesOrderBy>>;
};


export type ProjectStageBlueprintsByProjectReportStageIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectStageBlueprintsByProjectReportTimelineStageIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectStageBlueprintsByProjectStageIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectStageBlueprintsByProjectStageTimelineStageIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type ProjectStageColumnGroupsByStageIdArgs = {
  condition: InputMaybe<ColumnGroupCondition>;
  filter: InputMaybe<ColumnGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ProjectStageColumnGroupsByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ColumnGroupCondition>;
  filter: InputMaybe<ColumnGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


export type ProjectStageCommentsByFileStageIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ProjectStageCompaniesByPrivilegedTaskProjectStageIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectStageCompaniesByProjectReportStageIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectStageCompaniesByProjectReportTimelineStageIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectStageCompaniesByProjectStageIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectStageCompaniesByTaskReportProjectStageIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type ProjectStageContactsByFileStageIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageContactsByFileStageIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageContactsByProjectStageIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageContactsByProjectStageIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ProjectStageFilesByFileStageIdAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFilesByFileStageIdAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFilesByFormStageIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFilesByFormStageIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFilesByStageIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFilesByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ProjectStageFormsByFileStageIdAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectStageFormsByFileStageIdAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectStageFormsByStageIdArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectStageFormsByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type ProjectStageLabelsByPrivilegedTaskProjectStageIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type ProjectStageLabelsByTaskReportProjectStageIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type ProjectStageMainUsersByPrivilegedTaskProjectStageIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectStageMainUsersByPrivilegedTaskProjectStageIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectStageMainUsersByTaskReportProjectStageIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectStageMainUsersByTaskReportProjectStageIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectStagePrivilegedTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByStageIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByTimelineStageIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectReportsByTimelineStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type ProjectStageProjectStageTimelinesByStageIdArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageProjectStageTimelinesByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageProjectStageUpdatesByStageIdArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageProjectStageUpdatesByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageProjectStagesByProjectReportStageIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectStageProjectStagesByProjectReportTimelineStageIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type ProjectStageProjectsByFileStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByFileStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectReportStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectReportStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectStageIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectStageIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByStageIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByStageIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ProjectStageTaskReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type ProjectStageTeamsByProjectReportStageIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectStageTeamsByProjectReportStageIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type ProjectStageUsersByFileStageIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportStageIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectStageIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectStageIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectStageIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type ProjectStageUsersByProjectStageIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type ProjectStageActionsByFileStageIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageActionsByFileStageIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type ProjectStageActionsByFileStageIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type ProjectStageActionsByFileStageIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ProjectStageActionsByFileStageIdAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type ProjectStageActionsByFileStageIdAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type ProjectStageCommentsByFileStageIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageCommentsByFileStageIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type ProjectStageCommentsByFileStageIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectStageCommentsByFileStageIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectStageCommentsByFileStageIdAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type ProjectStageCommentsByFileStageIdAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/**
 * A condition to be used against `ProjectStage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectStageCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `redSla` field. */
  redSla: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scope` field. */
  scope: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `yellowSla` field. */
  yellowSla: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type ProjectStageContactsByFileStageIdAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type ProjectStageContactsByProjectStageIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type ProjectStageFilesByFileStageIdAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageFilesByFileStageIdAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type ProjectStageFilesByFileStageIdAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type ProjectStageFilesByFileStageIdAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ProjectStageFilesByFileStageIdAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type ProjectStageFilesByFileStageIdAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Form`. */
export type ProjectStageFilesByFormStageIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Form`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageFilesByFormStageIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Form`. */
export type ProjectStageFilesByFormStageIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Form`. */
export type ProjectStageFilesByFormStageIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileId: Array<Form>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileIdConnection: FormsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Form`. */
export type ProjectStageFilesByFormStageIdAndFileIdManyToManyEdgeFormsByFileIdArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


/** A `File` edge in the connection, with data from `Form`. */
export type ProjectStageFilesByFormStageIdAndFileIdManyToManyEdgeFormsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};

/** A filter to be used against `ProjectStage` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageFilter = {
  /** Filter by the object’s `actions` relation. */
  actions: InputMaybe<ProjectStageToManyActionFilter>;
  /** Some related `actions` exist. */
  actionsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectStageFilter>>;
  /** Filter by the object’s `blueprintProjectStages` relation. */
  blueprintProjectStages: InputMaybe<ProjectStageToManyBlueprintProjectStageFilter>;
  /** Some related `blueprintProjectStages` exist. */
  blueprintProjectStagesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `columnGroupsByStageId` relation. */
  columnGroupsByStageId: InputMaybe<ProjectStageToManyColumnGroupFilter>;
  /** Some related `columnGroupsByStageId` exist. */
  columnGroupsByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `filesByStageId` relation. */
  filesByStageId: InputMaybe<ProjectStageToManyFileFilter>;
  /** Some related `filesByStageId` exist. */
  filesByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `formsByStageId` relation. */
  formsByStageId: InputMaybe<ProjectStageToManyFormFilter>;
  /** Some related `formsByStageId` exist. */
  formsByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectStageFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectStageFilter>>;
  /** Filter by the object’s `privilegedTasks` relation. */
  privilegedTasks: InputMaybe<ProjectStageToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasks` exist. */
  privilegedTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByStageId` relation. */
  projectReportsByStageId: InputMaybe<ProjectStageToManyProjectReportFilter>;
  /** Some related `projectReportsByStageId` exist. */
  projectReportsByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByTimelineStageId` relation. */
  projectReportsByTimelineStageId: InputMaybe<ProjectStageToManyProjectReportFilter>;
  /** Some related `projectReportsByTimelineStageId` exist. */
  projectReportsByTimelineStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageTimelinesByStageId` relation. */
  projectStageTimelinesByStageId: InputMaybe<ProjectStageToManyProjectStageTimelineFilter>;
  /** Some related `projectStageTimelinesByStageId` exist. */
  projectStageTimelinesByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageUpdatesByStageId` relation. */
  projectStageUpdatesByStageId: InputMaybe<ProjectStageToManyProjectStageUpdateFilter>;
  /** Some related `projectStageUpdatesByStageId` exist. */
  projectStageUpdatesByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByStageId` relation. */
  projectsByStageId: InputMaybe<ProjectStageToManyProjectFilter>;
  /** Some related `projectsByStageId` exist. */
  projectsByStageIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `redSla` field. */
  redSla: InputMaybe<IntFilter>;
  /** Filter by the object’s `scope` field. */
  scope: InputMaybe<StringFilter>;
  /** Filter by the object’s `taskReports` relation. */
  taskReports: InputMaybe<ProjectStageToManyTaskReportFilter>;
  /** Some related `taskReports` exist. */
  taskReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `yellowSla` field. */
  yellowSla: InputMaybe<IntFilter>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type ProjectStageFormsByFileStageIdAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageFormsByFileStageIdAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type ProjectStageFormsByFileStageIdAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type ProjectStageFormsByFileStageIdAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ProjectStageFormsByFileStageIdAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type ProjectStageFormsByFileStageIdAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportStageIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageJurisdictionsByProjectReportTimelineStageIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type ProjectStageJurisdictionsByProjectStageIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStagePrivilegedTasksByPrivilegedTaskProjectStageIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTaskIdManyToManyEdgeTaskReportsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByTemplateTaskIdConnection: TaskReportsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskReport`. */
export type ProjectStagePrivilegedTasksByTaskReportProjectStageIdAndTemplateTaskIdManyToManyEdgeTaskReportsByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportStageIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectReportsByProjectReportTimelineStageIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type ProjectStageProjectsByFileStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByFileStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type ProjectStageProjectsByFileStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type ProjectStageProjectsByFileStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ProjectStageProjectsByFileStageIdAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type ProjectStageProjectsByFileStageIdAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStageProjectsByPrivilegedTaskProjectStageIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportStageIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageProjectsByProjectReportTimelineStageIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type ProjectStageProjectsByProjectStageIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectStageTimeline`. */
export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectStageTimeline`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectStageTimeline`. */
export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectId: Array<ProjectStageTimeline>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesByProjectIdConnection: ProjectStageTimelinesConnection;
};


/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyEdgeProjectStageTimelinesByProjectIdArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectStageTimeline`. */
export type ProjectStageProjectsByProjectStageTimelineStageIdAndProjectIdManyToManyEdgeProjectStageTimelinesByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `TaskReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `TaskReport`. */
export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `TaskReport`. */
export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsByProjectIdConnection: TaskReportsConnection;
};


/** A `Project` edge in the connection, with data from `TaskReport`. */
export type ProjectStageProjectsByTaskReportProjectStageIdAndProjectIdManyToManyEdgeTaskReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectStageSystemsByPrivilegedTaskProjectStageIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportStageIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageTeamsByProjectReportTimelineStageIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type ProjectStageTimeline = {
  /** Reads a single `Blueprint` that is related to this `ProjectStageTimeline`. */
  blueprint: Maybe<Blueprint>;
  blueprintId: Maybe<Scalars['Int']['output']>;
  count: Maybe<Scalars['BigInt']['output']>;
  endedAt: Maybe<Scalars['Datetime']['output']>;
  isEnded: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Project` that is related to this `ProjectStageTimeline`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Reads a single `ProjectStage` that is related to this `ProjectStageTimeline`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
  startedAt: Maybe<Scalars['Datetime']['output']>;
};

export type ProjectStageTimelineAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectStageTimelineArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectStageTimelineAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectStageTimelineDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectStageTimelineMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectStageTimelineMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectStageTimelineStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectStageTimelineStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectStageTimelineSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectStageTimelineVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectStageTimelineVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectStageTimeline` object types. */
export type ProjectStageTimelineAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectStageTimeline` objects. */
  arrayAgg: InputMaybe<ProjectStageTimelineArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectStageTimeline` objects. */
  average: InputMaybe<ProjectStageTimelineAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectStageTimeline` objects. */
  distinctCount: InputMaybe<ProjectStageTimelineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectStageTimeline` object to be included within the aggregate. */
  filter: InputMaybe<ProjectStageTimelineFilter>;
  /** Maximum aggregate over matching `ProjectStageTimeline` objects. */
  max: InputMaybe<ProjectStageTimelineMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectStageTimeline` objects. */
  min: InputMaybe<ProjectStageTimelineMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectStageTimeline` objects. */
  stddevPopulation: InputMaybe<ProjectStageTimelineStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectStageTimeline` objects. */
  stddevSample: InputMaybe<ProjectStageTimelineStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectStageTimeline` objects. */
  sum: InputMaybe<ProjectStageTimelineSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectStageTimeline` objects. */
  variancePopulation: InputMaybe<ProjectStageTimelineVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectStageTimeline` objects. */
  varianceSample: InputMaybe<ProjectStageTimelineVarianceSampleAggregateFilter>;
};

export type ProjectStageTimelineArrayAggAggregateFilter = {
  blueprintId: InputMaybe<IntListFilter>;
  isEnded: InputMaybe<BooleanListFilter>;
  projectId: InputMaybe<IntListFilter>;
  stageId: InputMaybe<IntListFilter>;
};

export type ProjectStageTimelineArrayAggAggregates = {
  /** Array aggregation of blueprintId across the matching connection */
  blueprintId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isEnded across the matching connection */
  isEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of spentTime across the matching connection */
  spentTime: Point;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
};


export type ProjectStageTimelineArrayAggAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineArrayAggAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineArrayAggAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineAverageAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageTimelineAverageAggregates = {
  /** Mean average of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageTimelineAverageAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineAverageAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineAverageAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

/**
 * A condition to be used against `ProjectStageTimeline` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectStageTimelineCondition = {
  /** Checks for equality with the object’s `blueprintId` field. */
  blueprintId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `count` field. */
  count: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `endedAt` field. */
  endedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `isEnded` field. */
  isEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `spentTime` field. */
  spentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type ProjectStageTimelineDistinctCountAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<BigIntFilter>;
  isEnded: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  spentTime: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  startedAt: InputMaybe<BigIntFilter>;
};

export type ProjectStageTimelineDistinctCountAggregates = {
  /** Distinct count of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endedAt across the matching connection */
  endedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isEnded across the matching connection */
  isEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of spentTime across the matching connection */
  spentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectStageTimelineDistinctCountAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineDistinctCountAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

/** A filter to be used against `ProjectStageTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageTimelineFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectStageTimelineFilter>>;
  /** Filter by the object’s `blueprint` relation. */
  blueprint: InputMaybe<BlueprintFilter>;
  /** A related `blueprint` exists. */
  blueprintExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintId` field. */
  blueprintId: InputMaybe<IntFilter>;
  /** Filter by the object’s `count` field. */
  count: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `endedAt` field. */
  endedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `isEnded` field. */
  isEnded: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectStageTimelineFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectStageTimelineFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `spentTime` field. */
  spentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ProjectStageTimeline` for usage during aggregation. */
export enum ProjectStageTimelineGroupBy {
  BlueprintId = 'BLUEPRINT_ID',
  Count = 'COUNT',
  EndedAt = 'ENDED_AT',
  EndedAtTruncatedToDay = 'ENDED_AT_TRUNCATED_TO_DAY',
  EndedAtTruncatedToHour = 'ENDED_AT_TRUNCATED_TO_HOUR',
  EndedAtTruncatedToMonth = 'ENDED_AT_TRUNCATED_TO_MONTH',
  EndedAtTruncatedToQuarter = 'ENDED_AT_TRUNCATED_TO_QUARTER',
  EndedAtTruncatedToWeek = 'ENDED_AT_TRUNCATED_TO_WEEK',
  EndedAtTruncatedToYear = 'ENDED_AT_TRUNCATED_TO_YEAR',
  IsEnded = 'IS_ENDED',
  ProjectId = 'PROJECT_ID',
  SpentTime = 'SPENT_TIME',
  StageId = 'STAGE_ID',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  StartedAtTruncatedToMonth = 'STARTED_AT_TRUNCATED_TO_MONTH',
  StartedAtTruncatedToQuarter = 'STARTED_AT_TRUNCATED_TO_QUARTER',
  StartedAtTruncatedToWeek = 'STARTED_AT_TRUNCATED_TO_WEEK',
  StartedAtTruncatedToYear = 'STARTED_AT_TRUNCATED_TO_YEAR'
}

export type ProjectStageTimelineHavingArrayAggInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingAverageInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingDistinctCountInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ProjectStageTimeline` aggregates. */
export type ProjectStageTimelineHavingInput = {
  AND: InputMaybe<Array<ProjectStageTimelineHavingInput>>;
  OR: InputMaybe<Array<ProjectStageTimelineHavingInput>>;
  arrayAgg: InputMaybe<ProjectStageTimelineHavingArrayAggInput>;
  average: InputMaybe<ProjectStageTimelineHavingAverageInput>;
  distinctCount: InputMaybe<ProjectStageTimelineHavingDistinctCountInput>;
  max: InputMaybe<ProjectStageTimelineHavingMaxInput>;
  min: InputMaybe<ProjectStageTimelineHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectStageTimelineHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectStageTimelineHavingStddevSampleInput>;
  sum: InputMaybe<ProjectStageTimelineHavingSumInput>;
  variancePopulation: InputMaybe<ProjectStageTimelineHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectStageTimelineHavingVarianceSampleInput>;
};

export type ProjectStageTimelineHavingMaxInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingMinInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingStddevPopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingStddevSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingSumInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingVariancePopulationInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineHavingVarianceSampleInput = {
  blueprintId: InputMaybe<HavingIntFilter>;
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  stageId: InputMaybe<HavingIntFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStageTimelineMaxAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<IntFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectStageTimelineMaxAggregates = {
  /** Maximum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Maximum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectStageTimelineMaxAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMaxAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineMinAggregateFilter = {
  blueprintId: InputMaybe<IntFilter>;
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<IntFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectStageTimelineMinAggregates = {
  /** Minimum of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['Int']['output']>;
  /** Minimum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectStageTimelineMinAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineMinAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineStddevPopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageTimelineStddevPopulationAggregates = {
  /** Population standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageTimelineStddevPopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevPopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevPopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineStddevSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageTimelineStddevSampleAggregates = {
  /** Sample standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageTimelineStddevSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineSumAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigIntFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigIntFilter>;
};

export type ProjectStageTimelineSumAggregates = {
  /** Sum of blueprintId across the matching connection */
  blueprintId: Scalars['BigInt']['output'];
  /** Sum of count across the matching connection */
  count: Scalars['BigFloat']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of spentTime across the matching connection */
  spentTime: Scalars['Float']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
};


export type ProjectStageTimelineSumAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineSumAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineSumAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineVariancePopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageTimelineVariancePopulationAggregates = {
  /** Population variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageTimelineVariancePopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVariancePopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVariancePopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

export type ProjectStageTimelineVarianceSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageTimelineVarianceSampleAggregates = {
  /** Sample variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageTimelineVarianceSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVarianceSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVarianceSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


export type ProjectStageTimelineVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};

/** A connection to a list of `ProjectStageTimeline` values. */
export type ProjectStageTimelinesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectStageTimelineAggregates>;
  /** A list of edges which contains the `ProjectStageTimeline` and cursor to aid in pagination. */
  edges: Array<ProjectStageTimelinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectStageTimelineAggregates>>;
  /** A list of `ProjectStageTimeline` objects. */
  nodes: Array<Maybe<ProjectStageTimeline>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectStageTimeline` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectStageTimeline` values. */
export type ProjectStageTimelinesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectStageTimelineGroupBy>;
  having: InputMaybe<ProjectStageTimelineHavingInput>;
};

/** A `ProjectStageTimeline` edge in the connection. */
export type ProjectStageTimelinesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectStageTimeline` at the end of the edge. */
  node: Maybe<ProjectStageTimeline>;
};

/** Methods to use when ordering `ProjectStageTimeline`. */
export enum ProjectStageTimelinesOrderBy {
  BlueprintIdAsc = 'BLUEPRINT_ID_ASC',
  BlueprintIdDesc = 'BLUEPRINT_ID_DESC',
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  EndedAtAsc = 'ENDED_AT_ASC',
  EndedAtDesc = 'ENDED_AT_DESC',
  IsEndedAsc = 'IS_ENDED_ASC',
  IsEndedDesc = 'IS_ENDED_DESC',
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  SpentTimeAsc = 'SPENT_TIME_ASC',
  SpentTimeDesc = 'SPENT_TIME_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC'
}

/** A filter to be used against many `Action` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyActionFilter = {
  /** Aggregates across related `Action` match the filter criteria. */
  aggregates: InputMaybe<ActionAggregatesFilter>;
  /** Every related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionFilter>;
  /** No related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionFilter>;
  /** Some related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionFilter>;
};

/** A filter to be used against many `BlueprintProjectStage` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyBlueprintProjectStageFilter = {
  /** Aggregates across related `BlueprintProjectStage` match the filter criteria. */
  aggregates: InputMaybe<BlueprintProjectStageAggregatesFilter>;
  /** Every related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintProjectStageFilter>;
  /** No related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintProjectStageFilter>;
  /** Some related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintProjectStageFilter>;
};

/** A filter to be used against many `ColumnGroup` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyColumnGroupFilter = {
  /** Aggregates across related `ColumnGroup` match the filter criteria. */
  aggregates: InputMaybe<ColumnGroupAggregatesFilter>;
  /** Every related `ColumnGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ColumnGroupFilter>;
  /** No related `ColumnGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ColumnGroupFilter>;
  /** Some related `ColumnGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ColumnGroupFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `Form` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyFormFilter = {
  /** Aggregates across related `Form` match the filter criteria. */
  aggregates: InputMaybe<FormAggregatesFilter>;
  /** Every related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FormFilter>;
  /** No related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FormFilter>;
  /** Some related `Form` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FormFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `ProjectStageTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyProjectStageTimelineFilter = {
  /** Aggregates across related `ProjectStageTimeline` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageTimelineAggregatesFilter>;
  /** Every related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageTimelineFilter>;
  /** No related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageTimelineFilter>;
  /** Some related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageTimelineFilter>;
};

/** A filter to be used against many `ProjectStageUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyProjectStageUpdateFilter = {
  /** Aggregates across related `ProjectStageUpdate` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageUpdateAggregatesFilter>;
  /** Every related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageUpdateFilter>;
  /** No related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageUpdateFilter>;
  /** Some related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageUpdateFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

export type ProjectStageUpdate = Node & {
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `ProjectStageUpdate`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  /** Reads a single `ProjectReport` that is related to this `ProjectStageUpdate`. */
  projectReportId: Maybe<ProjectReport>;
  /** Reads a single `ProjectStage` that is related to this `ProjectStageUpdate`. */
  stage: Maybe<ProjectStage>;
  stageId: Maybe<Scalars['Int']['output']>;
};

export type ProjectStageUpdateAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectStageUpdateArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectStageUpdateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectStageUpdateDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectStageUpdateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectStageUpdateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectStageUpdateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectStageUpdateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectStageUpdateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectStageUpdateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectStageUpdateVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectStageUpdate` object types. */
export type ProjectStageUpdateAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectStageUpdate` objects. */
  arrayAgg: InputMaybe<ProjectStageUpdateArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectStageUpdate` objects. */
  average: InputMaybe<ProjectStageUpdateAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectStageUpdate` objects. */
  distinctCount: InputMaybe<ProjectStageUpdateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectStageUpdate` object to be included within the aggregate. */
  filter: InputMaybe<ProjectStageUpdateFilter>;
  /** Maximum aggregate over matching `ProjectStageUpdate` objects. */
  max: InputMaybe<ProjectStageUpdateMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectStageUpdate` objects. */
  min: InputMaybe<ProjectStageUpdateMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectStageUpdate` objects. */
  stddevPopulation: InputMaybe<ProjectStageUpdateStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectStageUpdate` objects. */
  stddevSample: InputMaybe<ProjectStageUpdateStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectStageUpdate` objects. */
  sum: InputMaybe<ProjectStageUpdateSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectStageUpdate` objects. */
  variancePopulation: InputMaybe<ProjectStageUpdateVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectStageUpdate` objects. */
  varianceSample: InputMaybe<ProjectStageUpdateVarianceSampleAggregateFilter>;
};

export type ProjectStageUpdateArrayAggAggregateFilter = {
  id: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  stageId: InputMaybe<IntListFilter>;
};

export type ProjectStageUpdateArrayAggAggregates = {
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of stageId across the matching connection */
  stageId: Array<Maybe<Scalars['Int']['output']>>;
};


export type ProjectStageUpdateArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateArrayAggAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageUpdateAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageUpdateAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateAverageAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

/**
 * A condition to be used against `ProjectStageUpdate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectStageUpdateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `stageId` field. */
  stageId: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectStageUpdateDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
};

export type ProjectStageUpdateDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stageId across the matching connection */
  stageId: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectStageUpdateDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateDistinctCountAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

/** A filter to be used against `ProjectStageUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStageUpdateFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectStageUpdateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectStageUpdateFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectStageUpdateFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectReportId` relation. */
  projectReportId: InputMaybe<ProjectReportFilter>;
  /** Filter by the object’s `stage` relation. */
  stage: InputMaybe<ProjectStageFilter>;
  /** A related `stage` exists. */
  stageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `stageId` field. */
  stageId: InputMaybe<IntFilter>;
};

/** Grouping methods for `ProjectStageUpdate` for usage during aggregation. */
export enum ProjectStageUpdateGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  ProjectId = 'PROJECT_ID',
  StageId = 'STAGE_ID'
}

export type ProjectStageUpdateHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectStageUpdate` aggregates. */
export type ProjectStageUpdateHavingInput = {
  AND: InputMaybe<Array<ProjectStageUpdateHavingInput>>;
  OR: InputMaybe<Array<ProjectStageUpdateHavingInput>>;
  arrayAgg: InputMaybe<ProjectStageUpdateHavingArrayAggInput>;
  average: InputMaybe<ProjectStageUpdateHavingAverageInput>;
  distinctCount: InputMaybe<ProjectStageUpdateHavingDistinctCountInput>;
  max: InputMaybe<ProjectStageUpdateHavingMaxInput>;
  min: InputMaybe<ProjectStageUpdateHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectStageUpdateHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectStageUpdateHavingStddevSampleInput>;
  sum: InputMaybe<ProjectStageUpdateHavingSumInput>;
  variancePopulation: InputMaybe<ProjectStageUpdateHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectStageUpdateHavingVarianceSampleInput>;
};

export type ProjectStageUpdateHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  stageId: InputMaybe<HavingIntFilter>;
};

export type ProjectStageUpdateMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
};

export type ProjectStageUpdateMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
};


export type ProjectStageUpdateMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMaxAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  stageId: InputMaybe<IntFilter>;
};

export type ProjectStageUpdateMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of stageId across the matching connection */
  stageId: Maybe<Scalars['Int']['output']>;
};


export type ProjectStageUpdateMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateMinAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageUpdateStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageUpdateStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageUpdateStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageUpdateStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
};

export type ProjectStageUpdateSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
};


export type ProjectStageUpdateSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageUpdateVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageUpdateVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

export type ProjectStageUpdateVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
};

export type ProjectStageUpdateVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStageUpdateVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


export type ProjectStageUpdateVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};

/** A connection to a list of `ProjectStageUpdate` values. */
export type ProjectStageUpdatesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectStageUpdateAggregates>;
  /** A list of edges which contains the `ProjectStageUpdate` and cursor to aid in pagination. */
  edges: Array<ProjectStageUpdatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectStageUpdateAggregates>>;
  /** A list of `ProjectStageUpdate` objects. */
  nodes: Array<Maybe<ProjectStageUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectStageUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectStageUpdate` values. */
export type ProjectStageUpdatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectStageUpdateGroupBy>;
  having: InputMaybe<ProjectStageUpdateHavingInput>;
};

/** A `ProjectStageUpdate` edge in the connection. */
export type ProjectStageUpdatesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectStageUpdate` at the end of the edge. */
  node: Maybe<ProjectStageUpdate>;
};

/** Methods to use when ordering `ProjectStageUpdate`. */
export enum ProjectStageUpdatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC'
}

/** A connection to a list of `User` values, with data from `File`. */
export type ProjectStageUsersByFileStageIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByFileStageIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type ProjectStageUsersByFileStageIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type ProjectStageUsersByFileStageIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ProjectStageUsersByFileStageIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ProjectStageUsersByFileStageIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportStageIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectStageUsersByProjectReportTimelineStageIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectStageIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectStageUsersByProjectStageIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** Methods to use when ordering `ProjectStage`. */
export enum ProjectStagesOrderBy {
  ActionsConnectionArrayAggActionTemplateIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionArrayAggActionTemplateIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionArrayAggAssignAllContactsAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionArrayAggAssignAllContactsDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionArrayAggCompanyIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ActionsConnectionArrayAggCompanyIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ActionsConnectionArrayAggCompletedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  ActionsConnectionArrayAggCompletedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  ActionsConnectionArrayAggCreatedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ActionsConnectionArrayAggCreatedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ActionsConnectionArrayAggCreatedByAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ActionsConnectionArrayAggCreatedByDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ActionsConnectionArrayAggDescriptionAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ActionsConnectionArrayAggDescriptionDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ActionsConnectionArrayAggDueDateAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  ActionsConnectionArrayAggDueDateDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  ActionsConnectionArrayAggIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  ActionsConnectionArrayAggIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  ActionsConnectionArrayAggIsTemplateAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  ActionsConnectionArrayAggIsTemplateDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  ActionsConnectionArrayAggJobIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ActionsConnectionArrayAggJobIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ActionsConnectionArrayAggProjectIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ActionsConnectionArrayAggProjectIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ActionsConnectionArrayAggProjectStageIdAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  ActionsConnectionArrayAggProjectStageIdDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  ActionsConnectionArrayAggStatusAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ActionsConnectionArrayAggStatusDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ActionsConnectionArrayAggTitleAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ActionsConnectionArrayAggTitleDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ActionsConnectionArrayAggTypeAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ActionsConnectionArrayAggTypeDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ActionsConnectionArrayAggUpdatedAtAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ActionsConnectionArrayAggUpdatedAtDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ActionsConnectionArrayAggUuidAsc = 'ACTIONS_CONNECTION_ARRAY_AGG_UUID_ASC',
  ActionsConnectionArrayAggUuidDesc = 'ACTIONS_CONNECTION_ARRAY_AGG_UUID_DESC',
  ActionsConnectionAverageActionTemplateIdAsc = 'ACTIONS_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionAverageActionTemplateIdDesc = 'ACTIONS_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionAverageAssignAllContactsAsc = 'ACTIONS_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionAverageAssignAllContactsDesc = 'ACTIONS_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionAverageCompanyIdAsc = 'ACTIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ActionsConnectionAverageCompanyIdDesc = 'ACTIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ActionsConnectionAverageCompletedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  ActionsConnectionAverageCompletedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  ActionsConnectionAverageCreatedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ActionsConnectionAverageCreatedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ActionsConnectionAverageCreatedByAsc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ActionsConnectionAverageCreatedByDesc = 'ACTIONS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ActionsConnectionAverageDescriptionAsc = 'ACTIONS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ActionsConnectionAverageDescriptionDesc = 'ACTIONS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ActionsConnectionAverageDueDateAsc = 'ACTIONS_CONNECTION_AVERAGE_DUE_DATE_ASC',
  ActionsConnectionAverageDueDateDesc = 'ACTIONS_CONNECTION_AVERAGE_DUE_DATE_DESC',
  ActionsConnectionAverageIdAsc = 'ACTIONS_CONNECTION_AVERAGE_ID_ASC',
  ActionsConnectionAverageIdDesc = 'ACTIONS_CONNECTION_AVERAGE_ID_DESC',
  ActionsConnectionAverageIsTemplateAsc = 'ACTIONS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  ActionsConnectionAverageIsTemplateDesc = 'ACTIONS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  ActionsConnectionAverageJobIdAsc = 'ACTIONS_CONNECTION_AVERAGE_JOB_ID_ASC',
  ActionsConnectionAverageJobIdDesc = 'ACTIONS_CONNECTION_AVERAGE_JOB_ID_DESC',
  ActionsConnectionAverageProjectIdAsc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ActionsConnectionAverageProjectIdDesc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ActionsConnectionAverageProjectStageIdAsc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionAverageProjectStageIdDesc = 'ACTIONS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionAverageStatusAsc = 'ACTIONS_CONNECTION_AVERAGE_STATUS_ASC',
  ActionsConnectionAverageStatusDesc = 'ACTIONS_CONNECTION_AVERAGE_STATUS_DESC',
  ActionsConnectionAverageTitleAsc = 'ACTIONS_CONNECTION_AVERAGE_TITLE_ASC',
  ActionsConnectionAverageTitleDesc = 'ACTIONS_CONNECTION_AVERAGE_TITLE_DESC',
  ActionsConnectionAverageTypeAsc = 'ACTIONS_CONNECTION_AVERAGE_TYPE_ASC',
  ActionsConnectionAverageTypeDesc = 'ACTIONS_CONNECTION_AVERAGE_TYPE_DESC',
  ActionsConnectionAverageUpdatedAtAsc = 'ACTIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ActionsConnectionAverageUpdatedAtDesc = 'ACTIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ActionsConnectionAverageUuidAsc = 'ACTIONS_CONNECTION_AVERAGE_UUID_ASC',
  ActionsConnectionAverageUuidDesc = 'ACTIONS_CONNECTION_AVERAGE_UUID_DESC',
  ActionsConnectionCountAsc = 'ACTIONS_CONNECTION_COUNT_ASC',
  ActionsConnectionCountDesc = 'ACTIONS_CONNECTION_COUNT_DESC',
  ActionsConnectionDistinctCountActionTemplateIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionDistinctCountActionTemplateIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionDistinctCountAssignAllContactsAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionDistinctCountAssignAllContactsDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionDistinctCountCompanyIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ActionsConnectionDistinctCountCompanyIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ActionsConnectionDistinctCountCompletedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  ActionsConnectionDistinctCountCompletedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  ActionsConnectionDistinctCountCreatedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ActionsConnectionDistinctCountCreatedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ActionsConnectionDistinctCountCreatedByAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ActionsConnectionDistinctCountCreatedByDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ActionsConnectionDistinctCountDescriptionAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ActionsConnectionDistinctCountDescriptionDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ActionsConnectionDistinctCountDueDateAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  ActionsConnectionDistinctCountDueDateDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  ActionsConnectionDistinctCountIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ActionsConnectionDistinctCountIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ActionsConnectionDistinctCountIsTemplateAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  ActionsConnectionDistinctCountIsTemplateDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  ActionsConnectionDistinctCountJobIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ActionsConnectionDistinctCountJobIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ActionsConnectionDistinctCountProjectIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ActionsConnectionDistinctCountProjectIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ActionsConnectionDistinctCountProjectStageIdAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  ActionsConnectionDistinctCountProjectStageIdDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  ActionsConnectionDistinctCountStatusAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ActionsConnectionDistinctCountStatusDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ActionsConnectionDistinctCountTitleAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ActionsConnectionDistinctCountTitleDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ActionsConnectionDistinctCountTypeAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ActionsConnectionDistinctCountTypeDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ActionsConnectionDistinctCountUpdatedAtAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ActionsConnectionDistinctCountUpdatedAtDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ActionsConnectionDistinctCountUuidAsc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  ActionsConnectionDistinctCountUuidDesc = 'ACTIONS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  ActionsConnectionMaxActionTemplateIdAsc = 'ACTIONS_CONNECTION_MAX_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionMaxActionTemplateIdDesc = 'ACTIONS_CONNECTION_MAX_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionMaxAssignAllContactsAsc = 'ACTIONS_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionMaxAssignAllContactsDesc = 'ACTIONS_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionMaxCompanyIdAsc = 'ACTIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  ActionsConnectionMaxCompanyIdDesc = 'ACTIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  ActionsConnectionMaxCompletedAtAsc = 'ACTIONS_CONNECTION_MAX_COMPLETED_AT_ASC',
  ActionsConnectionMaxCompletedAtDesc = 'ACTIONS_CONNECTION_MAX_COMPLETED_AT_DESC',
  ActionsConnectionMaxCreatedAtAsc = 'ACTIONS_CONNECTION_MAX_CREATED_AT_ASC',
  ActionsConnectionMaxCreatedAtDesc = 'ACTIONS_CONNECTION_MAX_CREATED_AT_DESC',
  ActionsConnectionMaxCreatedByAsc = 'ACTIONS_CONNECTION_MAX_CREATED_BY_ASC',
  ActionsConnectionMaxCreatedByDesc = 'ACTIONS_CONNECTION_MAX_CREATED_BY_DESC',
  ActionsConnectionMaxDescriptionAsc = 'ACTIONS_CONNECTION_MAX_DESCRIPTION_ASC',
  ActionsConnectionMaxDescriptionDesc = 'ACTIONS_CONNECTION_MAX_DESCRIPTION_DESC',
  ActionsConnectionMaxDueDateAsc = 'ACTIONS_CONNECTION_MAX_DUE_DATE_ASC',
  ActionsConnectionMaxDueDateDesc = 'ACTIONS_CONNECTION_MAX_DUE_DATE_DESC',
  ActionsConnectionMaxIdAsc = 'ACTIONS_CONNECTION_MAX_ID_ASC',
  ActionsConnectionMaxIdDesc = 'ACTIONS_CONNECTION_MAX_ID_DESC',
  ActionsConnectionMaxIsTemplateAsc = 'ACTIONS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  ActionsConnectionMaxIsTemplateDesc = 'ACTIONS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  ActionsConnectionMaxJobIdAsc = 'ACTIONS_CONNECTION_MAX_JOB_ID_ASC',
  ActionsConnectionMaxJobIdDesc = 'ACTIONS_CONNECTION_MAX_JOB_ID_DESC',
  ActionsConnectionMaxProjectIdAsc = 'ACTIONS_CONNECTION_MAX_PROJECT_ID_ASC',
  ActionsConnectionMaxProjectIdDesc = 'ACTIONS_CONNECTION_MAX_PROJECT_ID_DESC',
  ActionsConnectionMaxProjectStageIdAsc = 'ACTIONS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  ActionsConnectionMaxProjectStageIdDesc = 'ACTIONS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  ActionsConnectionMaxStatusAsc = 'ACTIONS_CONNECTION_MAX_STATUS_ASC',
  ActionsConnectionMaxStatusDesc = 'ACTIONS_CONNECTION_MAX_STATUS_DESC',
  ActionsConnectionMaxTitleAsc = 'ACTIONS_CONNECTION_MAX_TITLE_ASC',
  ActionsConnectionMaxTitleDesc = 'ACTIONS_CONNECTION_MAX_TITLE_DESC',
  ActionsConnectionMaxTypeAsc = 'ACTIONS_CONNECTION_MAX_TYPE_ASC',
  ActionsConnectionMaxTypeDesc = 'ACTIONS_CONNECTION_MAX_TYPE_DESC',
  ActionsConnectionMaxUpdatedAtAsc = 'ACTIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  ActionsConnectionMaxUpdatedAtDesc = 'ACTIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  ActionsConnectionMaxUuidAsc = 'ACTIONS_CONNECTION_MAX_UUID_ASC',
  ActionsConnectionMaxUuidDesc = 'ACTIONS_CONNECTION_MAX_UUID_DESC',
  ActionsConnectionMinActionTemplateIdAsc = 'ACTIONS_CONNECTION_MIN_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionMinActionTemplateIdDesc = 'ACTIONS_CONNECTION_MIN_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionMinAssignAllContactsAsc = 'ACTIONS_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionMinAssignAllContactsDesc = 'ACTIONS_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionMinCompanyIdAsc = 'ACTIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  ActionsConnectionMinCompanyIdDesc = 'ACTIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  ActionsConnectionMinCompletedAtAsc = 'ACTIONS_CONNECTION_MIN_COMPLETED_AT_ASC',
  ActionsConnectionMinCompletedAtDesc = 'ACTIONS_CONNECTION_MIN_COMPLETED_AT_DESC',
  ActionsConnectionMinCreatedAtAsc = 'ACTIONS_CONNECTION_MIN_CREATED_AT_ASC',
  ActionsConnectionMinCreatedAtDesc = 'ACTIONS_CONNECTION_MIN_CREATED_AT_DESC',
  ActionsConnectionMinCreatedByAsc = 'ACTIONS_CONNECTION_MIN_CREATED_BY_ASC',
  ActionsConnectionMinCreatedByDesc = 'ACTIONS_CONNECTION_MIN_CREATED_BY_DESC',
  ActionsConnectionMinDescriptionAsc = 'ACTIONS_CONNECTION_MIN_DESCRIPTION_ASC',
  ActionsConnectionMinDescriptionDesc = 'ACTIONS_CONNECTION_MIN_DESCRIPTION_DESC',
  ActionsConnectionMinDueDateAsc = 'ACTIONS_CONNECTION_MIN_DUE_DATE_ASC',
  ActionsConnectionMinDueDateDesc = 'ACTIONS_CONNECTION_MIN_DUE_DATE_DESC',
  ActionsConnectionMinIdAsc = 'ACTIONS_CONNECTION_MIN_ID_ASC',
  ActionsConnectionMinIdDesc = 'ACTIONS_CONNECTION_MIN_ID_DESC',
  ActionsConnectionMinIsTemplateAsc = 'ACTIONS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  ActionsConnectionMinIsTemplateDesc = 'ACTIONS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  ActionsConnectionMinJobIdAsc = 'ACTIONS_CONNECTION_MIN_JOB_ID_ASC',
  ActionsConnectionMinJobIdDesc = 'ACTIONS_CONNECTION_MIN_JOB_ID_DESC',
  ActionsConnectionMinProjectIdAsc = 'ACTIONS_CONNECTION_MIN_PROJECT_ID_ASC',
  ActionsConnectionMinProjectIdDesc = 'ACTIONS_CONNECTION_MIN_PROJECT_ID_DESC',
  ActionsConnectionMinProjectStageIdAsc = 'ACTIONS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  ActionsConnectionMinProjectStageIdDesc = 'ACTIONS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  ActionsConnectionMinStatusAsc = 'ACTIONS_CONNECTION_MIN_STATUS_ASC',
  ActionsConnectionMinStatusDesc = 'ACTIONS_CONNECTION_MIN_STATUS_DESC',
  ActionsConnectionMinTitleAsc = 'ACTIONS_CONNECTION_MIN_TITLE_ASC',
  ActionsConnectionMinTitleDesc = 'ACTIONS_CONNECTION_MIN_TITLE_DESC',
  ActionsConnectionMinTypeAsc = 'ACTIONS_CONNECTION_MIN_TYPE_ASC',
  ActionsConnectionMinTypeDesc = 'ACTIONS_CONNECTION_MIN_TYPE_DESC',
  ActionsConnectionMinUpdatedAtAsc = 'ACTIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  ActionsConnectionMinUpdatedAtDesc = 'ACTIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  ActionsConnectionMinUuidAsc = 'ACTIONS_CONNECTION_MIN_UUID_ASC',
  ActionsConnectionMinUuidDesc = 'ACTIONS_CONNECTION_MIN_UUID_DESC',
  ActionsConnectionStddevPopulationActionTemplateIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionStddevPopulationActionTemplateIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionStddevPopulationAssignAllContactsAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionStddevPopulationAssignAllContactsDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionStddevPopulationCompanyIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ActionsConnectionStddevPopulationCompanyIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ActionsConnectionStddevPopulationCompletedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  ActionsConnectionStddevPopulationCompletedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  ActionsConnectionStddevPopulationCreatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ActionsConnectionStddevPopulationCreatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ActionsConnectionStddevPopulationCreatedByAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ActionsConnectionStddevPopulationCreatedByDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ActionsConnectionStddevPopulationDescriptionAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ActionsConnectionStddevPopulationDescriptionDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ActionsConnectionStddevPopulationDueDateAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  ActionsConnectionStddevPopulationDueDateDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  ActionsConnectionStddevPopulationIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ActionsConnectionStddevPopulationIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ActionsConnectionStddevPopulationIsTemplateAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  ActionsConnectionStddevPopulationIsTemplateDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  ActionsConnectionStddevPopulationJobIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ActionsConnectionStddevPopulationJobIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ActionsConnectionStddevPopulationProjectIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ActionsConnectionStddevPopulationProjectIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ActionsConnectionStddevPopulationProjectStageIdAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsConnectionStddevPopulationProjectStageIdDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsConnectionStddevPopulationStatusAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ActionsConnectionStddevPopulationStatusDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ActionsConnectionStddevPopulationTitleAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ActionsConnectionStddevPopulationTitleDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ActionsConnectionStddevPopulationTypeAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ActionsConnectionStddevPopulationTypeDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ActionsConnectionStddevPopulationUpdatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ActionsConnectionStddevPopulationUpdatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ActionsConnectionStddevPopulationUuidAsc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  ActionsConnectionStddevPopulationUuidDesc = 'ACTIONS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  ActionsConnectionStddevSampleActionTemplateIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionStddevSampleActionTemplateIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionStddevSampleAssignAllContactsAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionStddevSampleAssignAllContactsDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionStddevSampleCompanyIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ActionsConnectionStddevSampleCompanyIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ActionsConnectionStddevSampleCompletedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  ActionsConnectionStddevSampleCompletedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  ActionsConnectionStddevSampleCreatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ActionsConnectionStddevSampleCreatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ActionsConnectionStddevSampleCreatedByAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ActionsConnectionStddevSampleCreatedByDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ActionsConnectionStddevSampleDescriptionAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ActionsConnectionStddevSampleDescriptionDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ActionsConnectionStddevSampleDueDateAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  ActionsConnectionStddevSampleDueDateDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  ActionsConnectionStddevSampleIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ActionsConnectionStddevSampleIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ActionsConnectionStddevSampleIsTemplateAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  ActionsConnectionStddevSampleIsTemplateDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  ActionsConnectionStddevSampleJobIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ActionsConnectionStddevSampleJobIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ActionsConnectionStddevSampleProjectIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ActionsConnectionStddevSampleProjectIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ActionsConnectionStddevSampleProjectStageIdAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionStddevSampleProjectStageIdDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionStddevSampleStatusAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ActionsConnectionStddevSampleStatusDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ActionsConnectionStddevSampleTitleAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ActionsConnectionStddevSampleTitleDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ActionsConnectionStddevSampleTypeAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ActionsConnectionStddevSampleTypeDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ActionsConnectionStddevSampleUpdatedAtAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ActionsConnectionStddevSampleUpdatedAtDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ActionsConnectionStddevSampleUuidAsc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  ActionsConnectionStddevSampleUuidDesc = 'ACTIONS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  ActionsConnectionSumActionTemplateIdAsc = 'ACTIONS_CONNECTION_SUM_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionSumActionTemplateIdDesc = 'ACTIONS_CONNECTION_SUM_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionSumAssignAllContactsAsc = 'ACTIONS_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionSumAssignAllContactsDesc = 'ACTIONS_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionSumCompanyIdAsc = 'ACTIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  ActionsConnectionSumCompanyIdDesc = 'ACTIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  ActionsConnectionSumCompletedAtAsc = 'ACTIONS_CONNECTION_SUM_COMPLETED_AT_ASC',
  ActionsConnectionSumCompletedAtDesc = 'ACTIONS_CONNECTION_SUM_COMPLETED_AT_DESC',
  ActionsConnectionSumCreatedAtAsc = 'ACTIONS_CONNECTION_SUM_CREATED_AT_ASC',
  ActionsConnectionSumCreatedAtDesc = 'ACTIONS_CONNECTION_SUM_CREATED_AT_DESC',
  ActionsConnectionSumCreatedByAsc = 'ACTIONS_CONNECTION_SUM_CREATED_BY_ASC',
  ActionsConnectionSumCreatedByDesc = 'ACTIONS_CONNECTION_SUM_CREATED_BY_DESC',
  ActionsConnectionSumDescriptionAsc = 'ACTIONS_CONNECTION_SUM_DESCRIPTION_ASC',
  ActionsConnectionSumDescriptionDesc = 'ACTIONS_CONNECTION_SUM_DESCRIPTION_DESC',
  ActionsConnectionSumDueDateAsc = 'ACTIONS_CONNECTION_SUM_DUE_DATE_ASC',
  ActionsConnectionSumDueDateDesc = 'ACTIONS_CONNECTION_SUM_DUE_DATE_DESC',
  ActionsConnectionSumIdAsc = 'ACTIONS_CONNECTION_SUM_ID_ASC',
  ActionsConnectionSumIdDesc = 'ACTIONS_CONNECTION_SUM_ID_DESC',
  ActionsConnectionSumIsTemplateAsc = 'ACTIONS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  ActionsConnectionSumIsTemplateDesc = 'ACTIONS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  ActionsConnectionSumJobIdAsc = 'ACTIONS_CONNECTION_SUM_JOB_ID_ASC',
  ActionsConnectionSumJobIdDesc = 'ACTIONS_CONNECTION_SUM_JOB_ID_DESC',
  ActionsConnectionSumProjectIdAsc = 'ACTIONS_CONNECTION_SUM_PROJECT_ID_ASC',
  ActionsConnectionSumProjectIdDesc = 'ACTIONS_CONNECTION_SUM_PROJECT_ID_DESC',
  ActionsConnectionSumProjectStageIdAsc = 'ACTIONS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  ActionsConnectionSumProjectStageIdDesc = 'ACTIONS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  ActionsConnectionSumStatusAsc = 'ACTIONS_CONNECTION_SUM_STATUS_ASC',
  ActionsConnectionSumStatusDesc = 'ACTIONS_CONNECTION_SUM_STATUS_DESC',
  ActionsConnectionSumTitleAsc = 'ACTIONS_CONNECTION_SUM_TITLE_ASC',
  ActionsConnectionSumTitleDesc = 'ACTIONS_CONNECTION_SUM_TITLE_DESC',
  ActionsConnectionSumTypeAsc = 'ACTIONS_CONNECTION_SUM_TYPE_ASC',
  ActionsConnectionSumTypeDesc = 'ACTIONS_CONNECTION_SUM_TYPE_DESC',
  ActionsConnectionSumUpdatedAtAsc = 'ACTIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  ActionsConnectionSumUpdatedAtDesc = 'ACTIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  ActionsConnectionSumUuidAsc = 'ACTIONS_CONNECTION_SUM_UUID_ASC',
  ActionsConnectionSumUuidDesc = 'ACTIONS_CONNECTION_SUM_UUID_DESC',
  ActionsConnectionVariancePopulationActionTemplateIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionVariancePopulationActionTemplateIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionVariancePopulationAssignAllContactsAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionVariancePopulationAssignAllContactsDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionVariancePopulationCompanyIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ActionsConnectionVariancePopulationCompanyIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ActionsConnectionVariancePopulationCompletedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  ActionsConnectionVariancePopulationCompletedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  ActionsConnectionVariancePopulationCreatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ActionsConnectionVariancePopulationCreatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ActionsConnectionVariancePopulationCreatedByAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ActionsConnectionVariancePopulationCreatedByDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ActionsConnectionVariancePopulationDescriptionAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ActionsConnectionVariancePopulationDescriptionDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ActionsConnectionVariancePopulationDueDateAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  ActionsConnectionVariancePopulationDueDateDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  ActionsConnectionVariancePopulationIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ActionsConnectionVariancePopulationIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ActionsConnectionVariancePopulationIsTemplateAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  ActionsConnectionVariancePopulationIsTemplateDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  ActionsConnectionVariancePopulationJobIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ActionsConnectionVariancePopulationJobIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ActionsConnectionVariancePopulationProjectIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ActionsConnectionVariancePopulationProjectIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ActionsConnectionVariancePopulationProjectStageIdAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsConnectionVariancePopulationProjectStageIdDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsConnectionVariancePopulationStatusAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ActionsConnectionVariancePopulationStatusDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ActionsConnectionVariancePopulationTitleAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ActionsConnectionVariancePopulationTitleDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ActionsConnectionVariancePopulationTypeAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ActionsConnectionVariancePopulationTypeDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ActionsConnectionVariancePopulationUpdatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ActionsConnectionVariancePopulationUpdatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ActionsConnectionVariancePopulationUuidAsc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  ActionsConnectionVariancePopulationUuidDesc = 'ACTIONS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  ActionsConnectionVarianceSampleActionTemplateIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsConnectionVarianceSampleActionTemplateIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsConnectionVarianceSampleAssignAllContactsAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsConnectionVarianceSampleAssignAllContactsDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsConnectionVarianceSampleCompanyIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ActionsConnectionVarianceSampleCompanyIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ActionsConnectionVarianceSampleCompletedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  ActionsConnectionVarianceSampleCompletedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  ActionsConnectionVarianceSampleCreatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ActionsConnectionVarianceSampleCreatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ActionsConnectionVarianceSampleCreatedByAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ActionsConnectionVarianceSampleCreatedByDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ActionsConnectionVarianceSampleDescriptionAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ActionsConnectionVarianceSampleDescriptionDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ActionsConnectionVarianceSampleDueDateAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  ActionsConnectionVarianceSampleDueDateDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  ActionsConnectionVarianceSampleIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ActionsConnectionVarianceSampleIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ActionsConnectionVarianceSampleIsTemplateAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  ActionsConnectionVarianceSampleIsTemplateDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  ActionsConnectionVarianceSampleJobIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ActionsConnectionVarianceSampleJobIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ActionsConnectionVarianceSampleProjectIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ActionsConnectionVarianceSampleProjectIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ActionsConnectionVarianceSampleProjectStageIdAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsConnectionVarianceSampleProjectStageIdDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsConnectionVarianceSampleStatusAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ActionsConnectionVarianceSampleStatusDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ActionsConnectionVarianceSampleTitleAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ActionsConnectionVarianceSampleTitleDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ActionsConnectionVarianceSampleTypeAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ActionsConnectionVarianceSampleTypeDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ActionsConnectionVarianceSampleUpdatedAtAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ActionsConnectionVarianceSampleUpdatedAtDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ActionsConnectionVarianceSampleUuidAsc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  ActionsConnectionVarianceSampleUuidDesc = 'ACTIONS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  BlueprintProjectStagesConnectionArrayAggBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionArrayAggCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionArrayAggCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionArrayAggIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_POSITION_ASC',
  BlueprintProjectStagesConnectionArrayAggPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_POSITION_DESC',
  BlueprintProjectStagesConnectionArrayAggProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionArrayAggProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionAverageBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionAverageBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionAverageCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionAverageCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionAverageCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionAverageCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionAverageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_ID_ASC',
  BlueprintProjectStagesConnectionAverageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_ID_DESC',
  BlueprintProjectStagesConnectionAveragePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_POSITION_ASC',
  BlueprintProjectStagesConnectionAveragePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_POSITION_DESC',
  BlueprintProjectStagesConnectionAverageProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionAverageProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionAverageUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionAverageUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionCountAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_COUNT_ASC',
  BlueprintProjectStagesConnectionCountDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_COUNT_DESC',
  BlueprintProjectStagesConnectionDistinctCountBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionDistinctCountIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  BlueprintProjectStagesConnectionDistinctCountPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  BlueprintProjectStagesConnectionDistinctCountProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionDistinctCountProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionMaxBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionMaxBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionMaxCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionMaxCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionMaxCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionMaxCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionMaxIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_ID_ASC',
  BlueprintProjectStagesConnectionMaxIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_ID_DESC',
  BlueprintProjectStagesConnectionMaxPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_POSITION_ASC',
  BlueprintProjectStagesConnectionMaxPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_POSITION_DESC',
  BlueprintProjectStagesConnectionMaxProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionMaxProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionMaxUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionMaxUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionMinBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionMinBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionMinCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionMinCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionMinCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionMinCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionMinIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_ID_ASC',
  BlueprintProjectStagesConnectionMinIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_ID_DESC',
  BlueprintProjectStagesConnectionMinPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_POSITION_ASC',
  BlueprintProjectStagesConnectionMinPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_POSITION_DESC',
  BlueprintProjectStagesConnectionMinProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionMinProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionMinUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionMinUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevPopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionStddevPopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  BlueprintProjectStagesConnectionStddevPopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  BlueprintProjectStagesConnectionStddevPopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionStddevPopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionStddevSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintProjectStagesConnectionStddevSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesConnectionStddevSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesConnectionStddevSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionStddevSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionSumBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionSumBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionSumCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionSumCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionSumCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionSumCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionSumIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_ID_ASC',
  BlueprintProjectStagesConnectionSumIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_ID_DESC',
  BlueprintProjectStagesConnectionSumPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_POSITION_ASC',
  BlueprintProjectStagesConnectionSumPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_POSITION_DESC',
  BlueprintProjectStagesConnectionSumProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionSumProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionSumUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionSumUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionVariancePopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionVariancePopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  BlueprintProjectStagesConnectionVariancePopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  BlueprintProjectStagesConnectionVariancePopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionVariancePopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesConnectionVarianceSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesConnectionVarianceSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesConnectionVarianceSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesConnectionVarianceSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesConnectionVarianceSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionArrayAggCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionArrayAggCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionArrayAggCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionArrayAggCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionArrayAggCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionArrayAggCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionArrayAggIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ColumnGroupsByStageIdConnectionArrayAggIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ColumnGroupsByStageIdConnectionArrayAggNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  ColumnGroupsByStageIdConnectionArrayAggNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  ColumnGroupsByStageIdConnectionArrayAggPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ColumnGroupsByStageIdConnectionArrayAggPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ColumnGroupsByStageIdConnectionArrayAggScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionArrayAggScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionArrayAggStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionArrayAggStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionArrayAggUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionArrayAggUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionAverageCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionAverageCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionAverageCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionAverageCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionAverageCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionAverageCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionAverageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionAverageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionAverageNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_ASC',
  ColumnGroupsByStageIdConnectionAverageNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_DESC',
  ColumnGroupsByStageIdConnectionAveragePositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_POSITION_ASC',
  ColumnGroupsByStageIdConnectionAveragePositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_POSITION_DESC',
  ColumnGroupsByStageIdConnectionAverageScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionAverageScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionAverageStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionAverageStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionAverageUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionAverageUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionCountAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  ColumnGroupsByStageIdConnectionCountDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionDistinctCountUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionDistinctCountUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionMaxCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionMaxCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionMaxCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionMaxCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionMaxCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionMaxCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionMaxIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  ColumnGroupsByStageIdConnectionMaxIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  ColumnGroupsByStageIdConnectionMaxNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_NAME_ASC',
  ColumnGroupsByStageIdConnectionMaxNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_NAME_DESC',
  ColumnGroupsByStageIdConnectionMaxPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_POSITION_ASC',
  ColumnGroupsByStageIdConnectionMaxPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_POSITION_DESC',
  ColumnGroupsByStageIdConnectionMaxScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionMaxScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionMaxStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionMaxStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionMaxUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionMaxUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionMinCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionMinCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionMinCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionMinCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionMinCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionMinCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionMinIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  ColumnGroupsByStageIdConnectionMinIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  ColumnGroupsByStageIdConnectionMinNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_NAME_ASC',
  ColumnGroupsByStageIdConnectionMinNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_NAME_DESC',
  ColumnGroupsByStageIdConnectionMinPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_POSITION_ASC',
  ColumnGroupsByStageIdConnectionMinPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_POSITION_DESC',
  ColumnGroupsByStageIdConnectionMinScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionMinScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionMinStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionMinStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionMinUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionMinUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevPopulationUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionStddevPopulationUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ColumnGroupsByStageIdConnectionStddevSamplePositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ColumnGroupsByStageIdConnectionStddevSamplePositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionStddevSampleUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionStddevSampleUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionSumCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionSumCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionSumCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionSumCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionSumCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionSumCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionSumIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  ColumnGroupsByStageIdConnectionSumIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  ColumnGroupsByStageIdConnectionSumNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_NAME_ASC',
  ColumnGroupsByStageIdConnectionSumNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_NAME_DESC',
  ColumnGroupsByStageIdConnectionSumPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_POSITION_ASC',
  ColumnGroupsByStageIdConnectionSumPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_POSITION_DESC',
  ColumnGroupsByStageIdConnectionSumScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionSumScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionSumStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionSumStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionSumUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionSumUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationPositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationPositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionVariancePopulationUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionVariancePopulationUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleCompanyIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleCompanyIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleCreatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleCreatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleCreatedByAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleCreatedByDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleNameAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleNameDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ColumnGroupsByStageIdConnectionVarianceSamplePositionAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ColumnGroupsByStageIdConnectionVarianceSamplePositionDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleScopeAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleScopeDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleStageIdAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleStageIdDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ColumnGroupsByStageIdConnectionVarianceSampleUpdatedAtAsc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ColumnGroupsByStageIdConnectionVarianceSampleUpdatedAtDesc = 'COLUMN_GROUPS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FilesByStageIdConnectionArrayAggActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByStageIdConnectionArrayAggActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByStageIdConnectionArrayAggActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionArrayAggActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionArrayAggAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByStageIdConnectionArrayAggAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByStageIdConnectionArrayAggCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByStageIdConnectionArrayAggCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByStageIdConnectionArrayAggCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByStageIdConnectionArrayAggCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByStageIdConnectionArrayAggCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByStageIdConnectionArrayAggCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByStageIdConnectionArrayAggCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByStageIdConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionArrayAggCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByStageIdConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionArrayAggDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionArrayAggDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionArrayAggFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByStageIdConnectionArrayAggFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByStageIdConnectionArrayAggIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByStageIdConnectionArrayAggIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByStageIdConnectionArrayAggIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionArrayAggIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionArrayAggIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByStageIdConnectionArrayAggIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByStageIdConnectionArrayAggMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByStageIdConnectionArrayAggMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByStageIdConnectionArrayAggModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByStageIdConnectionArrayAggModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByStageIdConnectionArrayAggNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByStageIdConnectionArrayAggNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByStageIdConnectionArrayAggOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByStageIdConnectionArrayAggOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByStageIdConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionArrayAggPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByStageIdConnectionArrayAggPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByStageIdConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionArrayAggProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionArrayAggProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionArrayAggProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByStageIdConnectionArrayAggProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByStageIdConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionArrayAggSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionArrayAggSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionArrayAggStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByStageIdConnectionArrayAggStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByStageIdConnectionArrayAggTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByStageIdConnectionArrayAggTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByStageIdConnectionArrayAggUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByStageIdConnectionArrayAggUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByStageIdConnectionArrayAggUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByStageIdConnectionArrayAggUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByStageIdConnectionArrayAggValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionArrayAggValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionAverageActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByStageIdConnectionAverageActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByStageIdConnectionAverageActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionAverageActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionAverageAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByStageIdConnectionAverageAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByStageIdConnectionAverageCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByStageIdConnectionAverageCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByStageIdConnectionAverageCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByStageIdConnectionAverageCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByStageIdConnectionAverageCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByStageIdConnectionAverageCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByStageIdConnectionAverageCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByStageIdConnectionAverageCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionAverageCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionAverageCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByStageIdConnectionAverageCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionAverageCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionAverageDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionAverageDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionAverageFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByStageIdConnectionAverageFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByStageIdConnectionAverageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  FilesByStageIdConnectionAverageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  FilesByStageIdConnectionAverageIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionAverageIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionAverageIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByStageIdConnectionAverageIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByStageIdConnectionAverageMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByStageIdConnectionAverageMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByStageIdConnectionAverageModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByStageIdConnectionAverageModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByStageIdConnectionAverageNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_ASC',
  FilesByStageIdConnectionAverageNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_DESC',
  FilesByStageIdConnectionAverageOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByStageIdConnectionAverageOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByStageIdConnectionAveragePrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionAveragePrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionAveragePrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByStageIdConnectionAveragePrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByStageIdConnectionAveragePrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionAveragePrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionAveragePrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionAveragePrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionAverageProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionAverageProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionAverageProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByStageIdConnectionAverageProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByStageIdConnectionAverageSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionAverageSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionAverageSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionAverageSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionAverageStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByStageIdConnectionAverageStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByStageIdConnectionAverageTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByStageIdConnectionAverageTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByStageIdConnectionAverageUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByStageIdConnectionAverageUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByStageIdConnectionAverageUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_UUID_ASC',
  FilesByStageIdConnectionAverageUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_UUID_DESC',
  FilesByStageIdConnectionAverageValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionAverageValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionCountAsc = 'FILES_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  FilesByStageIdConnectionCountDesc = 'FILES_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  FilesByStageIdConnectionDistinctCountActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByStageIdConnectionDistinctCountActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByStageIdConnectionDistinctCountActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionDistinctCountActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionDistinctCountAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByStageIdConnectionDistinctCountAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByStageIdConnectionDistinctCountCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByStageIdConnectionDistinctCountCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByStageIdConnectionDistinctCountCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByStageIdConnectionDistinctCountCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByStageIdConnectionDistinctCountCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByStageIdConnectionDistinctCountCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByStageIdConnectionDistinctCountCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByStageIdConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionDistinctCountCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByStageIdConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionDistinctCountFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByStageIdConnectionDistinctCountFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByStageIdConnectionDistinctCountIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByStageIdConnectionDistinctCountIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByStageIdConnectionDistinctCountIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionDistinctCountIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionDistinctCountIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByStageIdConnectionDistinctCountIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByStageIdConnectionDistinctCountMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByStageIdConnectionDistinctCountMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByStageIdConnectionDistinctCountModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByStageIdConnectionDistinctCountModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByStageIdConnectionDistinctCountNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByStageIdConnectionDistinctCountNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByStageIdConnectionDistinctCountOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByStageIdConnectionDistinctCountOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByStageIdConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionDistinctCountPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByStageIdConnectionDistinctCountPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByStageIdConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionDistinctCountProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByStageIdConnectionDistinctCountProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByStageIdConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionDistinctCountStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByStageIdConnectionDistinctCountStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByStageIdConnectionDistinctCountTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByStageIdConnectionDistinctCountTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByStageIdConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByStageIdConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByStageIdConnectionDistinctCountUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByStageIdConnectionDistinctCountUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByStageIdConnectionDistinctCountValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionDistinctCountValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionMaxActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByStageIdConnectionMaxActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByStageIdConnectionMaxActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionMaxActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionMaxAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByStageIdConnectionMaxAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByStageIdConnectionMaxCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByStageIdConnectionMaxCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByStageIdConnectionMaxCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByStageIdConnectionMaxCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByStageIdConnectionMaxCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByStageIdConnectionMaxCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByStageIdConnectionMaxCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByStageIdConnectionMaxCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionMaxCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionMaxCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByStageIdConnectionMaxCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionMaxCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionMaxDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionMaxDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionMaxFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_FORM_ID_ASC',
  FilesByStageIdConnectionMaxFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_FORM_ID_DESC',
  FilesByStageIdConnectionMaxIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  FilesByStageIdConnectionMaxIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  FilesByStageIdConnectionMaxIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionMaxIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionMaxIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_IS_FORM_ASC',
  FilesByStageIdConnectionMaxIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_IS_FORM_DESC',
  FilesByStageIdConnectionMaxMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_META_DATA_ASC',
  FilesByStageIdConnectionMaxMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_META_DATA_DESC',
  FilesByStageIdConnectionMaxModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByStageIdConnectionMaxModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByStageIdConnectionMaxNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_NAME_ASC',
  FilesByStageIdConnectionMaxNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_NAME_DESC',
  FilesByStageIdConnectionMaxOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ORIGIN_ASC',
  FilesByStageIdConnectionMaxOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_ORIGIN_DESC',
  FilesByStageIdConnectionMaxPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionMaxPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionMaxPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByStageIdConnectionMaxPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByStageIdConnectionMaxPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionMaxPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionMaxPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionMaxPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionMaxProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionMaxProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionMaxProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByStageIdConnectionMaxProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByStageIdConnectionMaxSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionMaxSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionMaxSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionMaxSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionMaxStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByStageIdConnectionMaxStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByStageIdConnectionMaxTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_TYPE_ASC',
  FilesByStageIdConnectionMaxTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_TYPE_DESC',
  FilesByStageIdConnectionMaxUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByStageIdConnectionMaxUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByStageIdConnectionMaxUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_UUID_ASC',
  FilesByStageIdConnectionMaxUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_UUID_DESC',
  FilesByStageIdConnectionMaxValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionMaxValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionMinActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByStageIdConnectionMinActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByStageIdConnectionMinActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionMinActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionMinAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByStageIdConnectionMinAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByStageIdConnectionMinCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByStageIdConnectionMinCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByStageIdConnectionMinCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByStageIdConnectionMinCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByStageIdConnectionMinCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByStageIdConnectionMinCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByStageIdConnectionMinCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByStageIdConnectionMinCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionMinCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionMinCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByStageIdConnectionMinCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionMinCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionMinDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionMinDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionMinFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_FORM_ID_ASC',
  FilesByStageIdConnectionMinFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_FORM_ID_DESC',
  FilesByStageIdConnectionMinIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  FilesByStageIdConnectionMinIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  FilesByStageIdConnectionMinIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionMinIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionMinIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_IS_FORM_ASC',
  FilesByStageIdConnectionMinIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_IS_FORM_DESC',
  FilesByStageIdConnectionMinMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_META_DATA_ASC',
  FilesByStageIdConnectionMinMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_META_DATA_DESC',
  FilesByStageIdConnectionMinModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByStageIdConnectionMinModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByStageIdConnectionMinNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_NAME_ASC',
  FilesByStageIdConnectionMinNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_NAME_DESC',
  FilesByStageIdConnectionMinOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ORIGIN_ASC',
  FilesByStageIdConnectionMinOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_ORIGIN_DESC',
  FilesByStageIdConnectionMinPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionMinPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionMinPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByStageIdConnectionMinPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByStageIdConnectionMinPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionMinPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionMinPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionMinPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionMinProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionMinProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionMinProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionMinProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionMinProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByStageIdConnectionMinProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByStageIdConnectionMinSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionMinSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionMinSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionMinSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionMinStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByStageIdConnectionMinStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByStageIdConnectionMinTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_TYPE_ASC',
  FilesByStageIdConnectionMinTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_TYPE_DESC',
  FilesByStageIdConnectionMinUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByStageIdConnectionMinUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByStageIdConnectionMinUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_UUID_ASC',
  FilesByStageIdConnectionMinUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_UUID_DESC',
  FilesByStageIdConnectionMinValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionMinValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionStddevPopulationActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByStageIdConnectionStddevPopulationActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByStageIdConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByStageIdConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByStageIdConnectionStddevPopulationCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByStageIdConnectionStddevPopulationCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByStageIdConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByStageIdConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByStageIdConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByStageIdConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByStageIdConnectionStddevPopulationCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByStageIdConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionStddevPopulationCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByStageIdConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionStddevPopulationFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByStageIdConnectionStddevPopulationFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByStageIdConnectionStddevPopulationIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByStageIdConnectionStddevPopulationIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByStageIdConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionStddevPopulationIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByStageIdConnectionStddevPopulationIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByStageIdConnectionStddevPopulationMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByStageIdConnectionStddevPopulationMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByStageIdConnectionStddevPopulationModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByStageIdConnectionStddevPopulationModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByStageIdConnectionStddevPopulationNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByStageIdConnectionStddevPopulationNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByStageIdConnectionStddevPopulationOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByStageIdConnectionStddevPopulationOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByStageIdConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByStageIdConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByStageIdConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionStddevPopulationProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByStageIdConnectionStddevPopulationProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByStageIdConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionStddevPopulationStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByStageIdConnectionStddevPopulationStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByStageIdConnectionStddevPopulationTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByStageIdConnectionStddevPopulationTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByStageIdConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByStageIdConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByStageIdConnectionStddevPopulationUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByStageIdConnectionStddevPopulationUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByStageIdConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionStddevSampleActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByStageIdConnectionStddevSampleActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByStageIdConnectionStddevSampleActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionStddevSampleActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionStddevSampleAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByStageIdConnectionStddevSampleAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByStageIdConnectionStddevSampleCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByStageIdConnectionStddevSampleCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByStageIdConnectionStddevSampleCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByStageIdConnectionStddevSampleCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByStageIdConnectionStddevSampleCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByStageIdConnectionStddevSampleCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByStageIdConnectionStddevSampleCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByStageIdConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionStddevSampleCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByStageIdConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionStddevSampleFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByStageIdConnectionStddevSampleFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByStageIdConnectionStddevSampleIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByStageIdConnectionStddevSampleIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByStageIdConnectionStddevSampleIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionStddevSampleIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionStddevSampleIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByStageIdConnectionStddevSampleIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByStageIdConnectionStddevSampleMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByStageIdConnectionStddevSampleMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByStageIdConnectionStddevSampleModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByStageIdConnectionStddevSampleModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByStageIdConnectionStddevSampleNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByStageIdConnectionStddevSampleNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByStageIdConnectionStddevSampleOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByStageIdConnectionStddevSampleOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByStageIdConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionStddevSamplePrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByStageIdConnectionStddevSamplePrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByStageIdConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionStddevSampleProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByStageIdConnectionStddevSampleProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByStageIdConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionStddevSampleStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByStageIdConnectionStddevSampleStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByStageIdConnectionStddevSampleTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByStageIdConnectionStddevSampleTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByStageIdConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByStageIdConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByStageIdConnectionStddevSampleUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByStageIdConnectionStddevSampleUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByStageIdConnectionStddevSampleValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionStddevSampleValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionSumActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByStageIdConnectionSumActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByStageIdConnectionSumActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionSumActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionSumAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByStageIdConnectionSumAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByStageIdConnectionSumCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByStageIdConnectionSumCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByStageIdConnectionSumCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByStageIdConnectionSumCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByStageIdConnectionSumCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByStageIdConnectionSumCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByStageIdConnectionSumCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByStageIdConnectionSumCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionSumCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionSumCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByStageIdConnectionSumCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionSumCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionSumDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionSumDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionSumFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_FORM_ID_ASC',
  FilesByStageIdConnectionSumFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_FORM_ID_DESC',
  FilesByStageIdConnectionSumIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  FilesByStageIdConnectionSumIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  FilesByStageIdConnectionSumIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionSumIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionSumIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_IS_FORM_ASC',
  FilesByStageIdConnectionSumIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_IS_FORM_DESC',
  FilesByStageIdConnectionSumMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_META_DATA_ASC',
  FilesByStageIdConnectionSumMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_META_DATA_DESC',
  FilesByStageIdConnectionSumModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByStageIdConnectionSumModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByStageIdConnectionSumNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_NAME_ASC',
  FilesByStageIdConnectionSumNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_NAME_DESC',
  FilesByStageIdConnectionSumOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ORIGIN_ASC',
  FilesByStageIdConnectionSumOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_ORIGIN_DESC',
  FilesByStageIdConnectionSumPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionSumPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionSumPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByStageIdConnectionSumPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByStageIdConnectionSumPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionSumPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionSumPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionSumPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionSumProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionSumProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionSumProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionSumProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionSumProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByStageIdConnectionSumProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByStageIdConnectionSumSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionSumSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionSumSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionSumSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionSumStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByStageIdConnectionSumStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByStageIdConnectionSumTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_TYPE_ASC',
  FilesByStageIdConnectionSumTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_TYPE_DESC',
  FilesByStageIdConnectionSumUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByStageIdConnectionSumUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByStageIdConnectionSumUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_UUID_ASC',
  FilesByStageIdConnectionSumUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_UUID_DESC',
  FilesByStageIdConnectionSumValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionSumValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionVariancePopulationActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByStageIdConnectionVariancePopulationActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByStageIdConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByStageIdConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByStageIdConnectionVariancePopulationCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByStageIdConnectionVariancePopulationCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByStageIdConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByStageIdConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByStageIdConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByStageIdConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByStageIdConnectionVariancePopulationCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByStageIdConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionVariancePopulationCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByStageIdConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionVariancePopulationFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByStageIdConnectionVariancePopulationFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByStageIdConnectionVariancePopulationIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByStageIdConnectionVariancePopulationIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByStageIdConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionVariancePopulationIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByStageIdConnectionVariancePopulationIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByStageIdConnectionVariancePopulationMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByStageIdConnectionVariancePopulationMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByStageIdConnectionVariancePopulationModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByStageIdConnectionVariancePopulationModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByStageIdConnectionVariancePopulationNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByStageIdConnectionVariancePopulationNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByStageIdConnectionVariancePopulationOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByStageIdConnectionVariancePopulationOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByStageIdConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByStageIdConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByStageIdConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionVariancePopulationProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByStageIdConnectionVariancePopulationProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByStageIdConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionVariancePopulationStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByStageIdConnectionVariancePopulationStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByStageIdConnectionVariancePopulationTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByStageIdConnectionVariancePopulationTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByStageIdConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByStageIdConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByStageIdConnectionVariancePopulationUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByStageIdConnectionVariancePopulationUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByStageIdConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionVarianceSampleActionIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByStageIdConnectionVarianceSampleActionIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByStageIdConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByStageIdConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByStageIdConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByStageIdConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByStageIdConnectionVarianceSampleCommentIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByStageIdConnectionVarianceSampleCommentIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByStageIdConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByStageIdConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByStageIdConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByStageIdConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByStageIdConnectionVarianceSampleCreatedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByStageIdConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByStageIdConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByStageIdConnectionVarianceSampleCreatedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByStageIdConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByStageIdConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByStageIdConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByStageIdConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByStageIdConnectionVarianceSampleFormIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByStageIdConnectionVarianceSampleFormIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByStageIdConnectionVarianceSampleIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByStageIdConnectionVarianceSampleIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByStageIdConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByStageIdConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByStageIdConnectionVarianceSampleIsFormAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByStageIdConnectionVarianceSampleIsFormDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByStageIdConnectionVarianceSampleMetaDataAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByStageIdConnectionVarianceSampleMetaDataDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByStageIdConnectionVarianceSampleModifiedByAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByStageIdConnectionVarianceSampleModifiedByDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByStageIdConnectionVarianceSampleNameAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByStageIdConnectionVarianceSampleNameDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByStageIdConnectionVarianceSampleOriginAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByStageIdConnectionVarianceSampleOriginDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByStageIdConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByStageIdConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByStageIdConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByStageIdConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByStageIdConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByStageIdConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByStageIdConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByStageIdConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByStageIdConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByStageIdConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByStageIdConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByStageIdConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByStageIdConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByStageIdConnectionVarianceSampleProjectIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByStageIdConnectionVarianceSampleProjectIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByStageIdConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByStageIdConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByStageIdConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByStageIdConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByStageIdConnectionVarianceSampleStageIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByStageIdConnectionVarianceSampleStageIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByStageIdConnectionVarianceSampleTypeAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByStageIdConnectionVarianceSampleTypeDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByStageIdConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByStageIdConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByStageIdConnectionVarianceSampleUuidAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByStageIdConnectionVarianceSampleUuidDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByStageIdConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByStageIdConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FormsByStageIdConnectionArrayAggCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FormsByStageIdConnectionArrayAggCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FormsByStageIdConnectionArrayAggCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FormsByStageIdConnectionArrayAggCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FormsByStageIdConnectionArrayAggCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FormsByStageIdConnectionArrayAggCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FormsByStageIdConnectionArrayAggDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  FormsByStageIdConnectionArrayAggDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  FormsByStageIdConnectionArrayAggFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FormsByStageIdConnectionArrayAggFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FormsByStageIdConnectionArrayAggIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FormsByStageIdConnectionArrayAggIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FormsByStageIdConnectionArrayAggIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  FormsByStageIdConnectionArrayAggIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  FormsByStageIdConnectionArrayAggIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionArrayAggIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionArrayAggMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_MEMBERS_ASC',
  FormsByStageIdConnectionArrayAggMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_MEMBERS_DESC',
  FormsByStageIdConnectionArrayAggNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  FormsByStageIdConnectionArrayAggNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  FormsByStageIdConnectionArrayAggOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OLD_FIELDS_ASC',
  FormsByStageIdConnectionArrayAggOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OLD_FIELDS_DESC',
  FormsByStageIdConnectionArrayAggPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionArrayAggPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionArrayAggPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FormsByStageIdConnectionArrayAggPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FormsByStageIdConnectionArrayAggPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionArrayAggPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionArrayAggPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionArrayAggPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionArrayAggStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FormsByStageIdConnectionArrayAggStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FormsByStageIdConnectionArrayAggTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionArrayAggTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionArrayAggUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FormsByStageIdConnectionArrayAggUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FormsByStageIdConnectionAverageCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FormsByStageIdConnectionAverageCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FormsByStageIdConnectionAverageCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FormsByStageIdConnectionAverageCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FormsByStageIdConnectionAverageCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FormsByStageIdConnectionAverageCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FormsByStageIdConnectionAverageDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  FormsByStageIdConnectionAverageDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  FormsByStageIdConnectionAverageFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FormsByStageIdConnectionAverageFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FormsByStageIdConnectionAverageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  FormsByStageIdConnectionAverageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  FormsByStageIdConnectionAverageIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  FormsByStageIdConnectionAverageIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  FormsByStageIdConnectionAverageIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionAverageIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionAverageMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_MEMBERS_ASC',
  FormsByStageIdConnectionAverageMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_MEMBERS_DESC',
  FormsByStageIdConnectionAverageNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_ASC',
  FormsByStageIdConnectionAverageNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_NAME_DESC',
  FormsByStageIdConnectionAverageOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_OLD_FIELDS_ASC',
  FormsByStageIdConnectionAverageOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_OLD_FIELDS_DESC',
  FormsByStageIdConnectionAveragePrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionAveragePrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionAveragePrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FormsByStageIdConnectionAveragePrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FormsByStageIdConnectionAveragePrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionAveragePrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionAveragePrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionAveragePrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionAverageStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FormsByStageIdConnectionAverageStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FormsByStageIdConnectionAverageTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionAverageTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionAverageUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FormsByStageIdConnectionAverageUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FormsByStageIdConnectionCountAsc = 'FORMS_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  FormsByStageIdConnectionCountDesc = 'FORMS_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  FormsByStageIdConnectionDistinctCountCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FormsByStageIdConnectionDistinctCountCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FormsByStageIdConnectionDistinctCountCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FormsByStageIdConnectionDistinctCountCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FormsByStageIdConnectionDistinctCountCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FormsByStageIdConnectionDistinctCountCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FormsByStageIdConnectionDistinctCountDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  FormsByStageIdConnectionDistinctCountDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  FormsByStageIdConnectionDistinctCountFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FormsByStageIdConnectionDistinctCountFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FormsByStageIdConnectionDistinctCountIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FormsByStageIdConnectionDistinctCountIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FormsByStageIdConnectionDistinctCountIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  FormsByStageIdConnectionDistinctCountIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  FormsByStageIdConnectionDistinctCountIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionDistinctCountIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionDistinctCountMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_MEMBERS_ASC',
  FormsByStageIdConnectionDistinctCountMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_MEMBERS_DESC',
  FormsByStageIdConnectionDistinctCountNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FormsByStageIdConnectionDistinctCountNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FormsByStageIdConnectionDistinctCountOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OLD_FIELDS_ASC',
  FormsByStageIdConnectionDistinctCountOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OLD_FIELDS_DESC',
  FormsByStageIdConnectionDistinctCountPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionDistinctCountPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionDistinctCountPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FormsByStageIdConnectionDistinctCountPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FormsByStageIdConnectionDistinctCountPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionDistinctCountPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionDistinctCountPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionDistinctCountPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionDistinctCountStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FormsByStageIdConnectionDistinctCountStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FormsByStageIdConnectionDistinctCountTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionDistinctCountTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionDistinctCountUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FormsByStageIdConnectionDistinctCountUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FormsByStageIdConnectionMaxCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FormsByStageIdConnectionMaxCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FormsByStageIdConnectionMaxCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FormsByStageIdConnectionMaxCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FormsByStageIdConnectionMaxCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FormsByStageIdConnectionMaxCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FormsByStageIdConnectionMaxDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  FormsByStageIdConnectionMaxDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  FormsByStageIdConnectionMaxFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_FILE_ID_ASC',
  FormsByStageIdConnectionMaxFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_FILE_ID_DESC',
  FormsByStageIdConnectionMaxIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  FormsByStageIdConnectionMaxIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  FormsByStageIdConnectionMaxIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  FormsByStageIdConnectionMaxIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  FormsByStageIdConnectionMaxIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionMaxIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionMaxMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_MEMBERS_ASC',
  FormsByStageIdConnectionMaxMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_MEMBERS_DESC',
  FormsByStageIdConnectionMaxNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_NAME_ASC',
  FormsByStageIdConnectionMaxNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_NAME_DESC',
  FormsByStageIdConnectionMaxOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_OLD_FIELDS_ASC',
  FormsByStageIdConnectionMaxOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_OLD_FIELDS_DESC',
  FormsByStageIdConnectionMaxPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionMaxPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionMaxPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  FormsByStageIdConnectionMaxPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  FormsByStageIdConnectionMaxPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionMaxPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionMaxPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionMaxPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionMaxStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  FormsByStageIdConnectionMaxStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  FormsByStageIdConnectionMaxTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionMaxTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionMaxUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  FormsByStageIdConnectionMaxUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  FormsByStageIdConnectionMinCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FormsByStageIdConnectionMinCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FormsByStageIdConnectionMinCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FormsByStageIdConnectionMinCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FormsByStageIdConnectionMinCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FormsByStageIdConnectionMinCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FormsByStageIdConnectionMinDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  FormsByStageIdConnectionMinDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  FormsByStageIdConnectionMinFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_FILE_ID_ASC',
  FormsByStageIdConnectionMinFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_FILE_ID_DESC',
  FormsByStageIdConnectionMinIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  FormsByStageIdConnectionMinIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  FormsByStageIdConnectionMinIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  FormsByStageIdConnectionMinIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  FormsByStageIdConnectionMinIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionMinIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionMinMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_MEMBERS_ASC',
  FormsByStageIdConnectionMinMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_MEMBERS_DESC',
  FormsByStageIdConnectionMinNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_NAME_ASC',
  FormsByStageIdConnectionMinNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_NAME_DESC',
  FormsByStageIdConnectionMinOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_OLD_FIELDS_ASC',
  FormsByStageIdConnectionMinOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_OLD_FIELDS_DESC',
  FormsByStageIdConnectionMinPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionMinPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionMinPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  FormsByStageIdConnectionMinPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  FormsByStageIdConnectionMinPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionMinPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionMinPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionMinPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionMinStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  FormsByStageIdConnectionMinStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  FormsByStageIdConnectionMinTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionMinTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionMinUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  FormsByStageIdConnectionMinUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  FormsByStageIdConnectionStddevPopulationCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FormsByStageIdConnectionStddevPopulationCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FormsByStageIdConnectionStddevPopulationCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FormsByStageIdConnectionStddevPopulationCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FormsByStageIdConnectionStddevPopulationCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FormsByStageIdConnectionStddevPopulationCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FormsByStageIdConnectionStddevPopulationDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  FormsByStageIdConnectionStddevPopulationDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  FormsByStageIdConnectionStddevPopulationFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FormsByStageIdConnectionStddevPopulationFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FormsByStageIdConnectionStddevPopulationIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FormsByStageIdConnectionStddevPopulationIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FormsByStageIdConnectionStddevPopulationIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  FormsByStageIdConnectionStddevPopulationIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  FormsByStageIdConnectionStddevPopulationIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionStddevPopulationIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionStddevPopulationMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_MEMBERS_ASC',
  FormsByStageIdConnectionStddevPopulationMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_MEMBERS_DESC',
  FormsByStageIdConnectionStddevPopulationNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FormsByStageIdConnectionStddevPopulationNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FormsByStageIdConnectionStddevPopulationOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OLD_FIELDS_ASC',
  FormsByStageIdConnectionStddevPopulationOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OLD_FIELDS_DESC',
  FormsByStageIdConnectionStddevPopulationPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionStddevPopulationPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionStddevPopulationPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FormsByStageIdConnectionStddevPopulationPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FormsByStageIdConnectionStddevPopulationPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionStddevPopulationPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionStddevPopulationPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionStddevPopulationPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionStddevPopulationStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FormsByStageIdConnectionStddevPopulationStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FormsByStageIdConnectionStddevPopulationTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionStddevPopulationTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionStddevPopulationUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FormsByStageIdConnectionStddevPopulationUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FormsByStageIdConnectionStddevSampleCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FormsByStageIdConnectionStddevSampleCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FormsByStageIdConnectionStddevSampleCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FormsByStageIdConnectionStddevSampleCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FormsByStageIdConnectionStddevSampleCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FormsByStageIdConnectionStddevSampleCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FormsByStageIdConnectionStddevSampleDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  FormsByStageIdConnectionStddevSampleDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  FormsByStageIdConnectionStddevSampleFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FormsByStageIdConnectionStddevSampleFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FormsByStageIdConnectionStddevSampleIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FormsByStageIdConnectionStddevSampleIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FormsByStageIdConnectionStddevSampleIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  FormsByStageIdConnectionStddevSampleIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  FormsByStageIdConnectionStddevSampleIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionStddevSampleIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionStddevSampleMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_MEMBERS_ASC',
  FormsByStageIdConnectionStddevSampleMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_MEMBERS_DESC',
  FormsByStageIdConnectionStddevSampleNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FormsByStageIdConnectionStddevSampleNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FormsByStageIdConnectionStddevSampleOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OLD_FIELDS_ASC',
  FormsByStageIdConnectionStddevSampleOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OLD_FIELDS_DESC',
  FormsByStageIdConnectionStddevSamplePrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionStddevSamplePrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionStddevSamplePrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FormsByStageIdConnectionStddevSamplePrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FormsByStageIdConnectionStddevSamplePrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionStddevSamplePrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionStddevSamplePrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionStddevSamplePrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionStddevSampleStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FormsByStageIdConnectionStddevSampleStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FormsByStageIdConnectionStddevSampleTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionStddevSampleTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionStddevSampleUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FormsByStageIdConnectionStddevSampleUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FormsByStageIdConnectionSumCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FormsByStageIdConnectionSumCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FormsByStageIdConnectionSumCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FormsByStageIdConnectionSumCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FormsByStageIdConnectionSumCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FormsByStageIdConnectionSumCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FormsByStageIdConnectionSumDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  FormsByStageIdConnectionSumDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  FormsByStageIdConnectionSumFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_FILE_ID_ASC',
  FormsByStageIdConnectionSumFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_FILE_ID_DESC',
  FormsByStageIdConnectionSumIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  FormsByStageIdConnectionSumIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  FormsByStageIdConnectionSumIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  FormsByStageIdConnectionSumIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  FormsByStageIdConnectionSumIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionSumIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionSumMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_MEMBERS_ASC',
  FormsByStageIdConnectionSumMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_MEMBERS_DESC',
  FormsByStageIdConnectionSumNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_NAME_ASC',
  FormsByStageIdConnectionSumNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_NAME_DESC',
  FormsByStageIdConnectionSumOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_OLD_FIELDS_ASC',
  FormsByStageIdConnectionSumOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_OLD_FIELDS_DESC',
  FormsByStageIdConnectionSumPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionSumPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionSumPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  FormsByStageIdConnectionSumPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  FormsByStageIdConnectionSumPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionSumPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionSumPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionSumPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionSumStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  FormsByStageIdConnectionSumStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  FormsByStageIdConnectionSumTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionSumTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionSumUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  FormsByStageIdConnectionSumUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  FormsByStageIdConnectionVariancePopulationCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FormsByStageIdConnectionVariancePopulationCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FormsByStageIdConnectionVariancePopulationCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FormsByStageIdConnectionVariancePopulationCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FormsByStageIdConnectionVariancePopulationCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FormsByStageIdConnectionVariancePopulationCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FormsByStageIdConnectionVariancePopulationDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  FormsByStageIdConnectionVariancePopulationDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  FormsByStageIdConnectionVariancePopulationFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FormsByStageIdConnectionVariancePopulationFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FormsByStageIdConnectionVariancePopulationIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FormsByStageIdConnectionVariancePopulationIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FormsByStageIdConnectionVariancePopulationIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  FormsByStageIdConnectionVariancePopulationIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  FormsByStageIdConnectionVariancePopulationIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionVariancePopulationIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionVariancePopulationMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_MEMBERS_ASC',
  FormsByStageIdConnectionVariancePopulationMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_MEMBERS_DESC',
  FormsByStageIdConnectionVariancePopulationNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FormsByStageIdConnectionVariancePopulationNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FormsByStageIdConnectionVariancePopulationOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OLD_FIELDS_ASC',
  FormsByStageIdConnectionVariancePopulationOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OLD_FIELDS_DESC',
  FormsByStageIdConnectionVariancePopulationPrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionVariancePopulationPrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionVariancePopulationPrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FormsByStageIdConnectionVariancePopulationPrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FormsByStageIdConnectionVariancePopulationPrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionVariancePopulationPrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionVariancePopulationPrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionVariancePopulationPrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionVariancePopulationStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FormsByStageIdConnectionVariancePopulationStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FormsByStageIdConnectionVariancePopulationTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionVariancePopulationTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionVariancePopulationUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FormsByStageIdConnectionVariancePopulationUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FormsByStageIdConnectionVarianceSampleCompanyIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FormsByStageIdConnectionVarianceSampleCompanyIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FormsByStageIdConnectionVarianceSampleCreatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FormsByStageIdConnectionVarianceSampleCreatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FormsByStageIdConnectionVarianceSampleCreatedByAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FormsByStageIdConnectionVarianceSampleCreatedByDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FormsByStageIdConnectionVarianceSampleDescriptionAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  FormsByStageIdConnectionVarianceSampleDescriptionDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  FormsByStageIdConnectionVarianceSampleFileIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FormsByStageIdConnectionVarianceSampleFileIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FormsByStageIdConnectionVarianceSampleIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FormsByStageIdConnectionVarianceSampleIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FormsByStageIdConnectionVarianceSampleIsCompletedAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  FormsByStageIdConnectionVarianceSampleIsCompletedDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  FormsByStageIdConnectionVarianceSampleIsTemplateAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  FormsByStageIdConnectionVarianceSampleIsTemplateDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  FormsByStageIdConnectionVarianceSampleMembersAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_MEMBERS_ASC',
  FormsByStageIdConnectionVarianceSampleMembersDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_MEMBERS_DESC',
  FormsByStageIdConnectionVarianceSampleNameAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FormsByStageIdConnectionVarianceSampleNameDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FormsByStageIdConnectionVarianceSampleOldFieldsAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OLD_FIELDS_ASC',
  FormsByStageIdConnectionVarianceSampleOldFieldsDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OLD_FIELDS_DESC',
  FormsByStageIdConnectionVarianceSamplePrivilegeAllAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FormsByStageIdConnectionVarianceSamplePrivilegeAllDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FormsByStageIdConnectionVarianceSamplePrivilegeAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FormsByStageIdConnectionVarianceSamplePrivilegeDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FormsByStageIdConnectionVarianceSamplePrivilegeOwnAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FormsByStageIdConnectionVarianceSamplePrivilegeOwnDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FormsByStageIdConnectionVarianceSamplePrivilegeTeamAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FormsByStageIdConnectionVarianceSamplePrivilegeTeamDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FormsByStageIdConnectionVarianceSampleStageIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FormsByStageIdConnectionVarianceSampleStageIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FormsByStageIdConnectionVarianceSampleTemplateIdAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  FormsByStageIdConnectionVarianceSampleTemplateIdDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  FormsByStageIdConnectionVarianceSampleUpdatedAtAsc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FormsByStageIdConnectionVarianceSampleUpdatedAtDesc = 'FORMS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegedTasksConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksConnectionAverageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksConnectionAverageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksConnectionAverageUidAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksConnectionAverageUidDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionCountAsc = 'PRIVILEGED_TASKS_CONNECTION_COUNT_ASC',
  PrivilegedTasksConnectionCountDesc = 'PRIVILEGED_TASKS_CONNECTION_COUNT_DESC',
  PrivilegedTasksConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksConnectionMaxIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksConnectionMaxIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksConnectionMaxUidAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksConnectionMaxUidDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionMinAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksConnectionMinAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionMinFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksConnectionMinFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksConnectionMinIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksConnectionMinIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionMinPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksConnectionMinPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksConnectionMinStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksConnectionMinStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionMinTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksConnectionMinTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksConnectionMinUidAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksConnectionMinUidDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionSumAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksConnectionSumAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionSumFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksConnectionSumFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksConnectionSumIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksConnectionSumIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionSumPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksConnectionSumPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksConnectionSumStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksConnectionSumStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionSumTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksConnectionSumTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksConnectionSumUidAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksConnectionSumUidDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  ProjectsByStageIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByStageIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByStageIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByStageIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByStageIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionArrayAggCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByStageIdConnectionArrayAggCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByStageIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByStageIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByStageIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByStageIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByStageIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionArrayAggIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByStageIdConnectionArrayAggIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByStageIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByStageIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByStageIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByStageIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByStageIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByStageIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByStageIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByStageIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByStageIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByStageIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByStageIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByStageIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByStageIdConnectionArrayAggStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByStageIdConnectionArrayAggStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByStageIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByStageIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByStageIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByStageIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByStageIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByStageIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByStageIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByStageIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByStageIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByStageIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByStageIdConnectionArrayAggUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByStageIdConnectionArrayAggUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByStageIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByStageIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByStageIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionAverageAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByStageIdConnectionAverageAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByStageIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionAverageAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByStageIdConnectionAverageAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByStageIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionAverageCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByStageIdConnectionAverageCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByStageIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByStageIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByStageIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByStageIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByStageIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionAverageDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionAverageDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionAverageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByStageIdConnectionAverageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByStageIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByStageIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByStageIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionAveragePhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByStageIdConnectionAveragePhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByStageIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionAverageProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByStageIdConnectionAverageProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByStageIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionAverageRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByStageIdConnectionAverageRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByStageIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionAverageSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByStageIdConnectionAverageSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByStageIdConnectionAverageStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByStageIdConnectionAverageStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByStageIdConnectionAverageStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByStageIdConnectionAverageStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByStageIdConnectionAverageStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByStageIdConnectionAverageStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByStageIdConnectionAverageStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByStageIdConnectionAverageStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByStageIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionAverageTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByStageIdConnectionAverageTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByStageIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionAverageTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByStageIdConnectionAverageTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByStageIdConnectionAverageTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByStageIdConnectionAverageTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByStageIdConnectionAverageUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByStageIdConnectionAverageUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByStageIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByStageIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByStageIdConnectionCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  ProjectsByStageIdConnectionCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  ProjectsByStageIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByStageIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByStageIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByStageIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByStageIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByStageIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByStageIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByStageIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByStageIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByStageIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByStageIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByStageIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByStageIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByStageIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByStageIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByStageIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByStageIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByStageIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByStageIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByStageIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByStageIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByStageIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByStageIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByStageIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByStageIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByStageIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByStageIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByStageIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByStageIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByStageIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByStageIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByStageIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByStageIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByStageIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByStageIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByStageIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByStageIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionMaxAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByStageIdConnectionMaxAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByStageIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionMaxAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByStageIdConnectionMaxAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByStageIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionMaxCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByStageIdConnectionMaxCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByStageIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByStageIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByStageIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByStageIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByStageIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionMaxDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionMaxDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionMaxIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByStageIdConnectionMaxIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByStageIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByStageIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByStageIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionMaxPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByStageIdConnectionMaxPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByStageIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionMaxProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByStageIdConnectionMaxProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByStageIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionMaxRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByStageIdConnectionMaxRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByStageIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionMaxSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByStageIdConnectionMaxSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByStageIdConnectionMaxStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByStageIdConnectionMaxStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByStageIdConnectionMaxStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByStageIdConnectionMaxStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByStageIdConnectionMaxStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByStageIdConnectionMaxStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByStageIdConnectionMaxStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByStageIdConnectionMaxStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByStageIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionMaxTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByStageIdConnectionMaxTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByStageIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionMaxTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByStageIdConnectionMaxTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByStageIdConnectionMaxTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByStageIdConnectionMaxTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByStageIdConnectionMaxUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByStageIdConnectionMaxUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByStageIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByStageIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByStageIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionMinAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByStageIdConnectionMinAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByStageIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionMinAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByStageIdConnectionMinAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByStageIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionMinCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByStageIdConnectionMinCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByStageIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionMinConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionMinConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByStageIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByStageIdConnectionMinCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByStageIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionMinCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByStageIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionMinDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionMinDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionMinDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionMinDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionMinDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionMinDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionMinIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByStageIdConnectionMinIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByStageIdConnectionMinImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionMinImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionMinIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionMinIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionMinLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionMinLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionMinOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionMinOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByStageIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByStageIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionMinPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByStageIdConnectionMinPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByStageIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionMinProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByStageIdConnectionMinProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByStageIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionMinProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionMinProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionMinRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByStageIdConnectionMinRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByStageIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionMinSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByStageIdConnectionMinSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByStageIdConnectionMinStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByStageIdConnectionMinStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByStageIdConnectionMinStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByStageIdConnectionMinStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByStageIdConnectionMinStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByStageIdConnectionMinStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByStageIdConnectionMinStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByStageIdConnectionMinStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByStageIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionMinTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByStageIdConnectionMinTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByStageIdConnectionMinTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionMinTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionMinTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByStageIdConnectionMinTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByStageIdConnectionMinTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByStageIdConnectionMinTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByStageIdConnectionMinUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByStageIdConnectionMinUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByStageIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionMinZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByStageIdConnectionMinZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByStageIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByStageIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByStageIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByStageIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByStageIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByStageIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByStageIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByStageIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByStageIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByStageIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByStageIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByStageIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByStageIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByStageIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByStageIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByStageIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByStageIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByStageIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByStageIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByStageIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByStageIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByStageIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByStageIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByStageIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByStageIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByStageIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByStageIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByStageIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByStageIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByStageIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByStageIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByStageIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByStageIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByStageIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByStageIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByStageIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByStageIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByStageIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByStageIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByStageIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByStageIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByStageIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByStageIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByStageIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByStageIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByStageIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByStageIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByStageIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByStageIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByStageIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByStageIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByStageIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByStageIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByStageIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByStageIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByStageIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByStageIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByStageIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByStageIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByStageIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByStageIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByStageIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByStageIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByStageIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByStageIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByStageIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByStageIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByStageIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByStageIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByStageIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionSumAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByStageIdConnectionSumAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByStageIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionSumAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByStageIdConnectionSumAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByStageIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionSumCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByStageIdConnectionSumCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByStageIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionSumConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionSumConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByStageIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByStageIdConnectionSumCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByStageIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionSumCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByStageIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionSumDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionSumDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionSumDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionSumDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionSumDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionSumDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionSumIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByStageIdConnectionSumIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByStageIdConnectionSumImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionSumImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionSumIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionSumIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionSumLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionSumLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionSumOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionSumOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByStageIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByStageIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionSumPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByStageIdConnectionSumPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByStageIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionSumProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByStageIdConnectionSumProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByStageIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionSumProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionSumProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionSumRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByStageIdConnectionSumRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByStageIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionSumSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByStageIdConnectionSumSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByStageIdConnectionSumStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByStageIdConnectionSumStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByStageIdConnectionSumStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByStageIdConnectionSumStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByStageIdConnectionSumStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByStageIdConnectionSumStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByStageIdConnectionSumStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByStageIdConnectionSumStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByStageIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionSumTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByStageIdConnectionSumTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByStageIdConnectionSumTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionSumTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionSumTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByStageIdConnectionSumTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByStageIdConnectionSumTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByStageIdConnectionSumTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByStageIdConnectionSumUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByStageIdConnectionSumUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByStageIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionSumZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByStageIdConnectionSumZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByStageIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByStageIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByStageIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByStageIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByStageIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByStageIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByStageIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByStageIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByStageIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByStageIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByStageIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByStageIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByStageIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByStageIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByStageIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByStageIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByStageIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByStageIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByStageIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByStageIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByStageIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByStageIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByStageIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByStageIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByStageIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByStageIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByStageIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByStageIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByStageIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByStageIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByStageIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByStageIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByStageIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByStageIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByStageIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByStageIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByStageIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByStageIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByStageIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByStageIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByStageIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByStageIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByStageIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByStageIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByStageIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByStageIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByStageIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByStageIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByStageIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByStageIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByStageIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByStageIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByStageIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByStageIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByStageIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByStageIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByStageIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByStageIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByStageIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByStageIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByStageIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByStageIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByStageIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByStageIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByStageIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByStageIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByStageIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByStageIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByStageIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByStageIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByStageIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByStageIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByStageIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByStageIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByStageIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByStageIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByStageIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByStageIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByStageIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByStageIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByStageIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByStageIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByStageIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByStageIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByStageIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByStageIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByStageIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByStageIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByStageIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByStageIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByStageIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByStageIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByStageIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByStageIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByStageIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByStageIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByStageIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByStageIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByStageIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByStageIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByStageIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByStageIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByStageIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByStageIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByStageIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByStageIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByStageIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByStageIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByStageIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByStageIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByStageIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByStageIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByStageIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByStageIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByStageIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByStageIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByStageIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByStageIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByStageIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByStageIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByStageIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByStageIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByStageIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByStageIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByStageIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByStageIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByStageIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByStageIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByStageIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByStageIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByStageIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByStageIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByStageIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByStageIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByStageIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByStageIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByStageIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByStageIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByStageIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByStageIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByStageIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByStageIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByStageIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByStageIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByStageIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByStageIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByStageIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByStageIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByStageIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByStageIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByStageIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByStageIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByStageIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByStageIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByStageIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByStageIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByStageIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByStageIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByStageIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByStageIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByStageIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByStageIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByStageIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByStageIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByStageIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByStageIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByStageIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByStageIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByStageIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByStageIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByStageIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByStageIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByStageIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByStageIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByStageIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByStageIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByStageIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByStageIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByStageIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByStageIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByStageIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByStageIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByStageIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByStageIdConnectionCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByStageIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByStageIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByStageIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByStageIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByStageIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByStageIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByStageIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByStageIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByStageIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByStageIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByStageIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByStageIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByStageIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByStageIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByStageIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByStageIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByStageIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByStageIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByStageIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByStageIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByStageIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByStageIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByStageIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByStageIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByStageIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByStageIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByStageIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByStageIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByStageIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByStageIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByStageIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByStageIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByStageIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByStageIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByStageIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByStageIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByStageIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByStageIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByStageIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByStageIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByStageIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByStageIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByStageIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByStageIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByStageIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByStageIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByStageIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByStageIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByStageIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByStageIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByStageIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByStageIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByStageIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByStageIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByStageIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByStageIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByStageIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByStageIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByStageIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByStageIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByStageIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByStageIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByStageIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByStageIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByStageIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByStageIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByStageIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByStageIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByStageIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByStageIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByStageIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByStageIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByStageIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByStageIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByStageIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByStageIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByStageIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByStageIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByStageIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByStageIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByStageIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByStageIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByStageIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByStageIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByStageIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByStageIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByStageIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByStageIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByStageIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByStageIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByStageIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByStageIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByStageIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByStageIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByStageIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByStageIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByStageIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByStageIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByStageIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByStageIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByStageIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByStageIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByStageIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByStageIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByStageIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByStageIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByStageIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByStageIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByStageIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByStageIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByStageIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByTimelineStageIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_TIMELINE_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionArrayAggStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionArrayAggStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionAverageStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionAverageStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionDistinctCountStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMaxStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionMaxStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MAX_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionMinBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMinBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMinCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionMinCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionMinEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionMinEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionMinIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionMinIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionMinProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMinProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMinSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionMinSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionMinStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionMinStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionMinStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionMinStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_MIN_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevPopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionStddevSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionSumBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionSumBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionSumCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionSumCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionSumEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionSumEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionSumIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionSumIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionSumProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionSumProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionSumSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionSumSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionSumStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionSumStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionSumStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionSumStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_SUM_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionVariancePopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleCountAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleCountDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesByStageIdConnectionVarianceSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionArrayAggCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionArrayAggCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionArrayAggIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionArrayAggIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionAverageCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionAverageCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionAverageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionAverageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionAverageProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionAverageProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionAverageStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionAverageStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionCountAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_COUNT_ASC',
  ProjectStageUpdatesByStageIdConnectionCountDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_COUNT_DESC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMaxCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionMaxCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionMaxIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMaxIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMaxProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMaxProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMaxStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMaxStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMinCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionMinCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionMinIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMinIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMinProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMinProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionMinStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionMinStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionSumCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionSumCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionSumIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionSumIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionSumProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionSumProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionSumStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionSumStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesByStageIdConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_BY_STAGE_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  RedSlaAsc = 'RED_SLA_ASC',
  RedSlaDesc = 'RED_SLA_DESC',
  ScopeAsc = 'SCOPE_ASC',
  ScopeDesc = 'SCOPE_DESC',
  TaskReportsConnectionArrayAggAddressAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsConnectionArrayAggAddressDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionArrayAggEndDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsConnectionArrayAggEndDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionArrayAggFormsAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsConnectionArrayAggFormsDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsConnectionArrayAggIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsConnectionArrayAggIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsConnectionArrayAggJobIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsConnectionArrayAggJobIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionArrayAggPositionAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsConnectionArrayAggPositionDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsConnectionArrayAggPriorityAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsConnectionArrayAggPriorityDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionArrayAggStartDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsConnectionArrayAggStartDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsConnectionArrayAggStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsConnectionArrayAggStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionArrayAggTitleAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsConnectionArrayAggTitleDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsConnectionArrayAggUidAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsConnectionArrayAggUidDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsConnectionAverageAddressAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsConnectionAverageAddressDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionAverageCompanyIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsConnectionAverageCompanyIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsConnectionAverageCompletionDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsConnectionAverageCompletionDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionAverageCreatedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsConnectionAverageCreatedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsConnectionAverageCreatedByAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsConnectionAverageCreatedByDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsConnectionAverageDescriptionAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsConnectionAverageDescriptionDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionAverageEndDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsConnectionAverageEndDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionAverageFormsAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsConnectionAverageFormsDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsConnectionAverageIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsConnectionAverageIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsConnectionAverageIsArchivedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsConnectionAverageIsArchivedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsConnectionAverageIsColoredAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsConnectionAverageIsColoredDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsConnectionAverageIsCompletedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsConnectionAverageIsCompletedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsConnectionAverageIsFieldAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsConnectionAverageIsFieldDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsConnectionAverageIsTemplateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsConnectionAverageIsTemplateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsConnectionAverageJobIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsConnectionAverageJobIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsConnectionAverageLabelIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsConnectionAverageLabelIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsConnectionAverageLabelNameAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsConnectionAverageLabelNameDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsConnectionAverageModifiedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsConnectionAverageModifiedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionAveragePositionAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsConnectionAveragePositionDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsConnectionAveragePriorityAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsConnectionAveragePriorityDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsConnectionAverageProjectIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsConnectionAverageProjectIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionAverageStartDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsConnectionAverageStartDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsConnectionAverageStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsConnectionAverageStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsConnectionAverageSystemIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsConnectionAverageSystemIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsConnectionAverageTaskIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsConnectionAverageTaskIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionAverageTitleAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsConnectionAverageTitleDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsConnectionAverageUidAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsConnectionAverageUidDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionAverageVisitCountAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsConnectionAverageVisitCountDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsConnectionAverageVisitIdAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsConnectionAverageVisitIdDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsConnectionCountAsc = 'TASK_REPORTS_CONNECTION_COUNT_ASC',
  TaskReportsConnectionCountDesc = 'TASK_REPORTS_CONNECTION_COUNT_DESC',
  TaskReportsConnectionDistinctCountAddressAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsConnectionDistinctCountAddressDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionDistinctCountFormsAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsConnectionDistinctCountFormsDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsConnectionDistinctCountIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsConnectionDistinctCountIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionDistinctCountPositionAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsConnectionDistinctCountPositionDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsConnectionDistinctCountStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsConnectionDistinctCountStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionDistinctCountTitleAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsConnectionDistinctCountTitleDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsConnectionDistinctCountUidAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsConnectionDistinctCountUidDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsConnectionMaxAddressAsc = 'TASK_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsConnectionMaxAddressDesc = 'TASK_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsConnectionMaxCompanyIdAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsConnectionMaxCompanyIdDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsConnectionMaxCompletionDateAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsConnectionMaxCompletionDateDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionMaxCreatedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsConnectionMaxCreatedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsConnectionMaxCreatedByAsc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsConnectionMaxCreatedByDesc = 'TASK_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsConnectionMaxDescriptionAsc = 'TASK_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsConnectionMaxDescriptionDesc = 'TASK_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMaxEndDateAsc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsConnectionMaxEndDateDesc = 'TASK_REPORTS_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionMaxFormsAsc = 'TASK_REPORTS_CONNECTION_MAX_FORMS_ASC',
  TaskReportsConnectionMaxFormsDesc = 'TASK_REPORTS_CONNECTION_MAX_FORMS_DESC',
  TaskReportsConnectionMaxIdAsc = 'TASK_REPORTS_CONNECTION_MAX_ID_ASC',
  TaskReportsConnectionMaxIdDesc = 'TASK_REPORTS_CONNECTION_MAX_ID_DESC',
  TaskReportsConnectionMaxIsArchivedAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsConnectionMaxIsArchivedDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsConnectionMaxIsColoredAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsConnectionMaxIsColoredDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsConnectionMaxIsCompletedAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsConnectionMaxIsCompletedDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsConnectionMaxIsFieldAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsConnectionMaxIsFieldDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsConnectionMaxIsTemplateAsc = 'TASK_REPORTS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsConnectionMaxIsTemplateDesc = 'TASK_REPORTS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsConnectionMaxJobIdAsc = 'TASK_REPORTS_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsConnectionMaxJobIdDesc = 'TASK_REPORTS_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsConnectionMaxLabelIdAsc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsConnectionMaxLabelIdDesc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsConnectionMaxLabelNameAsc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsConnectionMaxLabelNameDesc = 'TASK_REPORTS_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsConnectionMaxModifiedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsConnectionMaxModifiedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionMaxPositionAsc = 'TASK_REPORTS_CONNECTION_MAX_POSITION_ASC',
  TaskReportsConnectionMaxPositionDesc = 'TASK_REPORTS_CONNECTION_MAX_POSITION_DESC',
  TaskReportsConnectionMaxPriorityAsc = 'TASK_REPORTS_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsConnectionMaxPriorityDesc = 'TASK_REPORTS_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsConnectionMaxProjectIdAsc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsConnectionMaxProjectIdDesc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMaxStartDateAsc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsConnectionMaxStartDateDesc = 'TASK_REPORTS_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsConnectionMaxStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_STATUS_ASC',
  TaskReportsConnectionMaxStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_STATUS_DESC',
  TaskReportsConnectionMaxSystemIdAsc = 'TASK_REPORTS_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsConnectionMaxSystemIdDesc = 'TASK_REPORTS_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsConnectionMaxTaskIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsConnectionMaxTaskIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionMaxTitleAsc = 'TASK_REPORTS_CONNECTION_MAX_TITLE_ASC',
  TaskReportsConnectionMaxTitleDesc = 'TASK_REPORTS_CONNECTION_MAX_TITLE_DESC',
  TaskReportsConnectionMaxUidAsc = 'TASK_REPORTS_CONNECTION_MAX_UID_ASC',
  TaskReportsConnectionMaxUidDesc = 'TASK_REPORTS_CONNECTION_MAX_UID_DESC',
  TaskReportsConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionMaxVisitCountAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsConnectionMaxVisitCountDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsConnectionMaxVisitIdAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsConnectionMaxVisitIdDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsConnectionMinAddressAsc = 'TASK_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsConnectionMinAddressDesc = 'TASK_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsConnectionMinAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsConnectionMinAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsConnectionMinCompanyIdAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsConnectionMinCompanyIdDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsConnectionMinCompletionDateAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsConnectionMinCompletionDateDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsConnectionMinCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsConnectionMinCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionMinCreatedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsConnectionMinCreatedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsConnectionMinCreatedByAsc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsConnectionMinCreatedByDesc = 'TASK_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsConnectionMinDescriptionAsc = 'TASK_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsConnectionMinDescriptionDesc = 'TASK_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMinEndDateAsc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsConnectionMinEndDateDesc = 'TASK_REPORTS_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionMinFormsAsc = 'TASK_REPORTS_CONNECTION_MIN_FORMS_ASC',
  TaskReportsConnectionMinFormsDesc = 'TASK_REPORTS_CONNECTION_MIN_FORMS_DESC',
  TaskReportsConnectionMinIdAsc = 'TASK_REPORTS_CONNECTION_MIN_ID_ASC',
  TaskReportsConnectionMinIdDesc = 'TASK_REPORTS_CONNECTION_MIN_ID_DESC',
  TaskReportsConnectionMinIsArchivedAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsConnectionMinIsArchivedDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsConnectionMinIsColoredAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsConnectionMinIsColoredDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsConnectionMinIsCompletedAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsConnectionMinIsCompletedDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsConnectionMinIsFieldAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsConnectionMinIsFieldDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsConnectionMinIsTemplateAsc = 'TASK_REPORTS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsConnectionMinIsTemplateDesc = 'TASK_REPORTS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsConnectionMinJobIdAsc = 'TASK_REPORTS_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsConnectionMinJobIdDesc = 'TASK_REPORTS_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsConnectionMinLabelIdAsc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsConnectionMinLabelIdDesc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsConnectionMinLabelNameAsc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsConnectionMinLabelNameDesc = 'TASK_REPORTS_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsConnectionMinModifiedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsConnectionMinModifiedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsConnectionMinNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionMinNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionMinPositionAsc = 'TASK_REPORTS_CONNECTION_MIN_POSITION_ASC',
  TaskReportsConnectionMinPositionDesc = 'TASK_REPORTS_CONNECTION_MIN_POSITION_DESC',
  TaskReportsConnectionMinPriorityAsc = 'TASK_REPORTS_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsConnectionMinPriorityDesc = 'TASK_REPORTS_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsConnectionMinProjectIdAsc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsConnectionMinProjectIdDesc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsConnectionMinProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionMinProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionMinStartDateAsc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsConnectionMinStartDateDesc = 'TASK_REPORTS_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsConnectionMinStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_STATUS_ASC',
  TaskReportsConnectionMinStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_STATUS_DESC',
  TaskReportsConnectionMinSystemIdAsc = 'TASK_REPORTS_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsConnectionMinSystemIdDesc = 'TASK_REPORTS_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsConnectionMinTaskIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsConnectionMinTaskIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionMinTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsConnectionMinTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionMinTitleAsc = 'TASK_REPORTS_CONNECTION_MIN_TITLE_ASC',
  TaskReportsConnectionMinTitleDesc = 'TASK_REPORTS_CONNECTION_MIN_TITLE_DESC',
  TaskReportsConnectionMinUidAsc = 'TASK_REPORTS_CONNECTION_MIN_UID_ASC',
  TaskReportsConnectionMinUidDesc = 'TASK_REPORTS_CONNECTION_MIN_UID_DESC',
  TaskReportsConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionMinVisitCountAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsConnectionMinVisitCountDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsConnectionMinVisitIdAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsConnectionMinVisitIdDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsConnectionMinVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsConnectionMinVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsConnectionStddevPopulationIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsConnectionStddevPopulationIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsConnectionStddevPopulationUidAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsConnectionStddevPopulationUidDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsConnectionStddevSampleAddressAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsConnectionStddevSampleAddressDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionStddevSampleFormsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsConnectionStddevSampleFormsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsConnectionStddevSampleIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsConnectionStddevSampleIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionStddevSamplePositionAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsConnectionStddevSamplePositionDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsConnectionStddevSampleStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsConnectionStddevSampleStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionStddevSampleTitleAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsConnectionStddevSampleTitleDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsConnectionStddevSampleUidAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsConnectionStddevSampleUidDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsConnectionSumAddressAsc = 'TASK_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsConnectionSumAddressDesc = 'TASK_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsConnectionSumAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsConnectionSumAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsConnectionSumCompanyIdAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsConnectionSumCompanyIdDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsConnectionSumCompletionDateAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsConnectionSumCompletionDateDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsConnectionSumCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsConnectionSumCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionSumCreatedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsConnectionSumCreatedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsConnectionSumCreatedByAsc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsConnectionSumCreatedByDesc = 'TASK_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsConnectionSumDescriptionAsc = 'TASK_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsConnectionSumDescriptionDesc = 'TASK_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionSumEndDateAsc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsConnectionSumEndDateDesc = 'TASK_REPORTS_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionSumFormsAsc = 'TASK_REPORTS_CONNECTION_SUM_FORMS_ASC',
  TaskReportsConnectionSumFormsDesc = 'TASK_REPORTS_CONNECTION_SUM_FORMS_DESC',
  TaskReportsConnectionSumIdAsc = 'TASK_REPORTS_CONNECTION_SUM_ID_ASC',
  TaskReportsConnectionSumIdDesc = 'TASK_REPORTS_CONNECTION_SUM_ID_DESC',
  TaskReportsConnectionSumIsArchivedAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsConnectionSumIsArchivedDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsConnectionSumIsColoredAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsConnectionSumIsColoredDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsConnectionSumIsCompletedAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsConnectionSumIsCompletedDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsConnectionSumIsFieldAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsConnectionSumIsFieldDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsConnectionSumIsTemplateAsc = 'TASK_REPORTS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsConnectionSumIsTemplateDesc = 'TASK_REPORTS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsConnectionSumJobIdAsc = 'TASK_REPORTS_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsConnectionSumJobIdDesc = 'TASK_REPORTS_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsConnectionSumLabelIdAsc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsConnectionSumLabelIdDesc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsConnectionSumLabelNameAsc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsConnectionSumLabelNameDesc = 'TASK_REPORTS_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsConnectionSumModifiedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsConnectionSumModifiedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsConnectionSumNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionSumNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionSumPositionAsc = 'TASK_REPORTS_CONNECTION_SUM_POSITION_ASC',
  TaskReportsConnectionSumPositionDesc = 'TASK_REPORTS_CONNECTION_SUM_POSITION_DESC',
  TaskReportsConnectionSumPriorityAsc = 'TASK_REPORTS_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsConnectionSumPriorityDesc = 'TASK_REPORTS_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsConnectionSumProjectIdAsc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsConnectionSumProjectIdDesc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsConnectionSumProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionSumProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionSumStartDateAsc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsConnectionSumStartDateDesc = 'TASK_REPORTS_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsConnectionSumStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_STATUS_ASC',
  TaskReportsConnectionSumStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_STATUS_DESC',
  TaskReportsConnectionSumSystemIdAsc = 'TASK_REPORTS_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsConnectionSumSystemIdDesc = 'TASK_REPORTS_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsConnectionSumTaskIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsConnectionSumTaskIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionSumTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsConnectionSumTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionSumTitleAsc = 'TASK_REPORTS_CONNECTION_SUM_TITLE_ASC',
  TaskReportsConnectionSumTitleDesc = 'TASK_REPORTS_CONNECTION_SUM_TITLE_DESC',
  TaskReportsConnectionSumUidAsc = 'TASK_REPORTS_CONNECTION_SUM_UID_ASC',
  TaskReportsConnectionSumUidDesc = 'TASK_REPORTS_CONNECTION_SUM_UID_DESC',
  TaskReportsConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionSumVisitCountAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsConnectionSumVisitCountDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsConnectionSumVisitIdAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsConnectionSumVisitIdDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsConnectionSumVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsConnectionSumVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsConnectionVariancePopulationIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsConnectionVariancePopulationIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsConnectionVariancePopulationUidAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsConnectionVariancePopulationUidDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsConnectionVarianceSampleIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsConnectionVarianceSampleIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsConnectionVarianceSampleUidAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsConnectionVarianceSampleUidDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  YellowSlaAsc = 'YELLOW_SLA_ASC',
  YellowSlaDesc = 'YELLOW_SLA_DESC'
}

export enum ProjectStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  OnHold = 'ON_HOLD'
}

/** A filter to be used against ProjectStatus fields. All fields are combined with a logical ‘and.’ */
export type ProjectStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<ProjectStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<ProjectStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<ProjectStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<ProjectStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<ProjectStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<ProjectStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<ProjectStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<ProjectStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<ProjectStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<ProjectStatus>>;
};

export type ProjectStatusTimeline = {
  count: Maybe<Scalars['BigInt']['output']>;
  endedAt: Maybe<Scalars['Datetime']['output']>;
  isEnded: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Project` that is related to this `ProjectStatusTimeline`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `ProjectReport` that is related to this `ProjectStatusTimeline`. */
  projectReportId: Maybe<ProjectReport>;
  spentTime: Maybe<Scalars['Float']['output']>;
  startedAt: Maybe<Scalars['Datetime']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type ProjectStatusTimelineAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectStatusTimelineArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectStatusTimelineAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectStatusTimelineDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectStatusTimelineMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectStatusTimelineMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectStatusTimelineStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectStatusTimelineStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectStatusTimelineSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectStatusTimelineVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectStatusTimelineVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectStatusTimeline` object types. */
export type ProjectStatusTimelineAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectStatusTimeline` objects. */
  arrayAgg: InputMaybe<ProjectStatusTimelineArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectStatusTimeline` objects. */
  average: InputMaybe<ProjectStatusTimelineAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectStatusTimeline` objects. */
  distinctCount: InputMaybe<ProjectStatusTimelineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectStatusTimeline` object to be included within the aggregate. */
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  /** Maximum aggregate over matching `ProjectStatusTimeline` objects. */
  max: InputMaybe<ProjectStatusTimelineMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectStatusTimeline` objects. */
  min: InputMaybe<ProjectStatusTimelineMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectStatusTimeline` objects. */
  stddevPopulation: InputMaybe<ProjectStatusTimelineStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectStatusTimeline` objects. */
  stddevSample: InputMaybe<ProjectStatusTimelineStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectStatusTimeline` objects. */
  sum: InputMaybe<ProjectStatusTimelineSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectStatusTimeline` objects. */
  variancePopulation: InputMaybe<ProjectStatusTimelineVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectStatusTimeline` objects. */
  varianceSample: InputMaybe<ProjectStatusTimelineVarianceSampleAggregateFilter>;
};

export type ProjectStatusTimelineArrayAggAggregateFilter = {
  isEnded: InputMaybe<BooleanListFilter>;
  projectId: InputMaybe<IntListFilter>;
  status: InputMaybe<StringListFilter>;
};

export type ProjectStatusTimelineArrayAggAggregates = {
  /** Array aggregation of isEnded across the matching connection */
  isEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of spentTime across the matching connection */
  spentTime: Point;
  /** Array aggregation of status across the matching connection */
  status: Array<Maybe<Scalars['String']['output']>>;
};


export type ProjectStatusTimelineArrayAggAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineArrayAggAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineArrayAggAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineAverageAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineAverageAggregates = {
  /** Mean average of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
};


export type ProjectStatusTimelineAverageAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineAverageAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

/**
 * A condition to be used against `ProjectStatusTimeline` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectStatusTimelineCondition = {
  /** Checks for equality with the object’s `count` field. */
  count: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `endedAt` field. */
  endedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `isEnded` field. */
  isEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `spentTime` field. */
  spentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<Scalars['String']['input']>;
};

export type ProjectStatusTimelineDistinctCountAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<BigIntFilter>;
  isEnded: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  spentTime: InputMaybe<BigIntFilter>;
  startedAt: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
};

export type ProjectStatusTimelineDistinctCountAggregates = {
  /** Distinct count of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endedAt across the matching connection */
  endedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isEnded across the matching connection */
  isEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of spentTime across the matching connection */
  spentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectStatusTimelineDistinctCountAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

/** A filter to be used against `ProjectStatusTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStatusTimelineFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectStatusTimelineFilter>>;
  /** Filter by the object’s `count` field. */
  count: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `endedAt` field. */
  endedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `isEnded` field. */
  isEnded: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectStatusTimelineFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectStatusTimelineFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectReportId` relation. */
  projectReportId: InputMaybe<ProjectReportFilter>;
  /** A related `projectReportId` exists. */
  projectReportIdExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `spentTime` field. */
  spentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<StringFilter>;
};

/** Grouping methods for `ProjectStatusTimeline` for usage during aggregation. */
export enum ProjectStatusTimelineGroupBy {
  Count = 'COUNT',
  EndedAt = 'ENDED_AT',
  EndedAtTruncatedToDay = 'ENDED_AT_TRUNCATED_TO_DAY',
  EndedAtTruncatedToHour = 'ENDED_AT_TRUNCATED_TO_HOUR',
  EndedAtTruncatedToMonth = 'ENDED_AT_TRUNCATED_TO_MONTH',
  EndedAtTruncatedToQuarter = 'ENDED_AT_TRUNCATED_TO_QUARTER',
  EndedAtTruncatedToWeek = 'ENDED_AT_TRUNCATED_TO_WEEK',
  EndedAtTruncatedToYear = 'ENDED_AT_TRUNCATED_TO_YEAR',
  IsEnded = 'IS_ENDED',
  ProjectId = 'PROJECT_ID',
  SpentTime = 'SPENT_TIME',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  StartedAtTruncatedToMonth = 'STARTED_AT_TRUNCATED_TO_MONTH',
  StartedAtTruncatedToQuarter = 'STARTED_AT_TRUNCATED_TO_QUARTER',
  StartedAtTruncatedToWeek = 'STARTED_AT_TRUNCATED_TO_WEEK',
  StartedAtTruncatedToYear = 'STARTED_AT_TRUNCATED_TO_YEAR',
  Status = 'STATUS'
}

export type ProjectStatusTimelineHavingArrayAggInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingAverageInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingDistinctCountInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ProjectStatusTimeline` aggregates. */
export type ProjectStatusTimelineHavingInput = {
  AND: InputMaybe<Array<ProjectStatusTimelineHavingInput>>;
  OR: InputMaybe<Array<ProjectStatusTimelineHavingInput>>;
  arrayAgg: InputMaybe<ProjectStatusTimelineHavingArrayAggInput>;
  average: InputMaybe<ProjectStatusTimelineHavingAverageInput>;
  distinctCount: InputMaybe<ProjectStatusTimelineHavingDistinctCountInput>;
  max: InputMaybe<ProjectStatusTimelineHavingMaxInput>;
  min: InputMaybe<ProjectStatusTimelineHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectStatusTimelineHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectStatusTimelineHavingStddevSampleInput>;
  sum: InputMaybe<ProjectStatusTimelineHavingSumInput>;
  variancePopulation: InputMaybe<ProjectStatusTimelineHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectStatusTimelineHavingVarianceSampleInput>;
};

export type ProjectStatusTimelineHavingMaxInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingMinInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingStddevPopulationInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingStddevSampleInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingSumInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingVariancePopulationInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineHavingVarianceSampleInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ProjectStatusTimelineMaxAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  spentTime: InputMaybe<FloatFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectStatusTimelineMaxAggregates = {
  /** Maximum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectStatusTimelineMaxAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMaxAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMaxAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMaxAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineMinAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  projectId: InputMaybe<IntFilter>;
  spentTime: InputMaybe<FloatFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
};

export type ProjectStatusTimelineMinAggregates = {
  /** Minimum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ProjectStatusTimelineMinAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMinAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMinAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineMinAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineStddevPopulationAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineStddevPopulationAggregates = {
  /** Population standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
};


export type ProjectStatusTimelineStddevPopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineStddevPopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineStddevSampleAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineStddevSampleAggregates = {
  /** Sample standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
};


export type ProjectStatusTimelineStddevSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineStddevSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineSumAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigIntFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineSumAggregates = {
  /** Sum of count across the matching connection */
  count: Scalars['BigFloat']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of spentTime across the matching connection */
  spentTime: Scalars['Float']['output'];
};


export type ProjectStatusTimelineSumAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineSumAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineVariancePopulationAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineVariancePopulationAggregates = {
  /** Population variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
};


export type ProjectStatusTimelineVariancePopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineVariancePopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

export type ProjectStatusTimelineVarianceSampleAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
};

export type ProjectStatusTimelineVarianceSampleAggregates = {
  /** Sample variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
};


export type ProjectStatusTimelineVarianceSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


export type ProjectStatusTimelineVarianceSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};

/** A connection to a list of `ProjectStatusTimeline` values. */
export type ProjectStatusTimelinesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectStatusTimelineAggregates>;
  /** A list of edges which contains the `ProjectStatusTimeline` and cursor to aid in pagination. */
  edges: Array<ProjectStatusTimelinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectStatusTimelineAggregates>>;
  /** A list of `ProjectStatusTimeline` objects. */
  nodes: Array<Maybe<ProjectStatusTimeline>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectStatusTimeline` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectStatusTimeline` values. */
export type ProjectStatusTimelinesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectStatusTimelineGroupBy>;
  having: InputMaybe<ProjectStatusTimelineHavingInput>;
};

/** A `ProjectStatusTimeline` edge in the connection. */
export type ProjectStatusTimelinesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectStatusTimeline` at the end of the edge. */
  node: Maybe<ProjectStatusTimeline>;
};

/** Methods to use when ordering `ProjectStatusTimeline`. */
export enum ProjectStatusTimelinesOrderBy {
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  EndedAtAsc = 'ENDED_AT_ASC',
  EndedAtDesc = 'ENDED_AT_DESC',
  IsEndedAsc = 'IS_ENDED_ASC',
  IsEndedDesc = 'IS_ENDED_DESC',
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  SpentTimeAsc = 'SPENT_TIME_ASC',
  SpentTimeDesc = 'SPENT_TIME_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export type ProjectStatusUpdate = Node & {
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Project` that is related to this `ProjectStatusUpdate`. */
  project: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  /** Reads a single `ProjectReport` that is related to this `ProjectStatusUpdate`. */
  projectReportId: Maybe<ProjectReport>;
  status: Scalars['String']['output'];
};

export type ProjectStatusUpdateAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ProjectStatusUpdateArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ProjectStatusUpdateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ProjectStatusUpdateDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ProjectStatusUpdateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ProjectStatusUpdateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ProjectStatusUpdateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ProjectStatusUpdateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ProjectStatusUpdateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ProjectStatusUpdateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ProjectStatusUpdateVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ProjectStatusUpdate` object types. */
export type ProjectStatusUpdateAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectStatusUpdate` objects. */
  arrayAgg: InputMaybe<ProjectStatusUpdateArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectStatusUpdate` objects. */
  average: InputMaybe<ProjectStatusUpdateAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectStatusUpdate` objects. */
  distinctCount: InputMaybe<ProjectStatusUpdateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectStatusUpdate` object to be included within the aggregate. */
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  /** Maximum aggregate over matching `ProjectStatusUpdate` objects. */
  max: InputMaybe<ProjectStatusUpdateMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectStatusUpdate` objects. */
  min: InputMaybe<ProjectStatusUpdateMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectStatusUpdate` objects. */
  stddevPopulation: InputMaybe<ProjectStatusUpdateStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectStatusUpdate` objects. */
  stddevSample: InputMaybe<ProjectStatusUpdateStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectStatusUpdate` objects. */
  sum: InputMaybe<ProjectStatusUpdateSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectStatusUpdate` objects. */
  variancePopulation: InputMaybe<ProjectStatusUpdateVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectStatusUpdate` objects. */
  varianceSample: InputMaybe<ProjectStatusUpdateVarianceSampleAggregateFilter>;
};

export type ProjectStatusUpdateArrayAggAggregateFilter = {
  id: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  status: InputMaybe<StringListFilter>;
};

export type ProjectStatusUpdateArrayAggAggregates = {
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of status across the matching connection */
  status: Array<Maybe<Scalars['String']['output']>>;
};


export type ProjectStatusUpdateArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateArrayAggAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectStatusUpdateAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStatusUpdateAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

/**
 * A condition to be used against `ProjectStatusUpdate` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectStatusUpdateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<Scalars['String']['input']>;
};

export type ProjectStatusUpdateDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
};

export type ProjectStatusUpdateDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
};


export type ProjectStatusUpdateDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

/** A filter to be used against `ProjectStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectStatusUpdateFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectStatusUpdateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<ProjectStatusUpdateFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectStatusUpdateFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectReportId` relation. */
  projectReportId: InputMaybe<ProjectReportFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<StringFilter>;
};

/** Grouping methods for `ProjectStatusUpdate` for usage during aggregation. */
export enum ProjectStatusUpdateGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  ProjectId = 'PROJECT_ID',
  Status = 'STATUS'
}

export type ProjectStatusUpdateHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ProjectStatusUpdate` aggregates. */
export type ProjectStatusUpdateHavingInput = {
  AND: InputMaybe<Array<ProjectStatusUpdateHavingInput>>;
  OR: InputMaybe<Array<ProjectStatusUpdateHavingInput>>;
  arrayAgg: InputMaybe<ProjectStatusUpdateHavingArrayAggInput>;
  average: InputMaybe<ProjectStatusUpdateHavingAverageInput>;
  distinctCount: InputMaybe<ProjectStatusUpdateHavingDistinctCountInput>;
  max: InputMaybe<ProjectStatusUpdateHavingMaxInput>;
  min: InputMaybe<ProjectStatusUpdateHavingMinInput>;
  stddevPopulation: InputMaybe<ProjectStatusUpdateHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ProjectStatusUpdateHavingStddevSampleInput>;
  sum: InputMaybe<ProjectStatusUpdateHavingSumInput>;
  variancePopulation: InputMaybe<ProjectStatusUpdateHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ProjectStatusUpdateHavingVarianceSampleInput>;
};

export type ProjectStatusUpdateHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
};

export type ProjectStatusUpdateMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
};

export type ProjectStatusUpdateMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
};


export type ProjectStatusUpdateMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
};

export type ProjectStatusUpdateMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
};


export type ProjectStatusUpdateMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectStatusUpdateStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStatusUpdateStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectStatusUpdateStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStatusUpdateStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
};

export type ProjectStatusUpdateSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
};


export type ProjectStatusUpdateSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectStatusUpdateVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStatusUpdateVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

export type ProjectStatusUpdateVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectStatusUpdateVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStatusUpdateVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


export type ProjectStatusUpdateVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};

/** A connection to a list of `ProjectStatusUpdate` values. */
export type ProjectStatusUpdatesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectStatusUpdateAggregates>;
  /** A list of edges which contains the `ProjectStatusUpdate` and cursor to aid in pagination. */
  edges: Array<ProjectStatusUpdatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectStatusUpdateAggregates>>;
  /** A list of `ProjectStatusUpdate` objects. */
  nodes: Array<Maybe<ProjectStatusUpdate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectStatusUpdate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectStatusUpdate` values. */
export type ProjectStatusUpdatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectStatusUpdateGroupBy>;
  having: InputMaybe<ProjectStatusUpdateHavingInput>;
};

/** A `ProjectStatusUpdate` edge in the connection. */
export type ProjectStatusUpdatesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectStatusUpdate` at the end of the edge. */
  node: Maybe<ProjectStatusUpdate>;
};

/** Methods to use when ordering `ProjectStatusUpdate`. */
export enum ProjectStatusUpdatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

export type ProjectStddevPopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type ProjectStddevPopulationAggregates = {
  /** Population standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStddevPopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectStddevSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type ProjectStddevSampleAggregates = {
  /** Sample standard deviation of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectStddevSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectSumAggregateFilter = {
  blueprintId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  createdByContactId: InputMaybe<BigIntFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigIntFilter>;
  jurisdictionId: InputMaybe<BigIntFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigIntFilter>;
  parentProjectId: InputMaybe<BigIntFilter>;
  progress: InputMaybe<BigIntFilter>;
  projectManagerId: InputMaybe<BigIntFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigIntFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigIntFilter>;
  stageId: InputMaybe<BigIntFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigIntFilter>;
  totalOutboundCallsCount: InputMaybe<BigIntFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigIntFilter>;
};

export type ProjectSumAggregates = {
  /** Sum of blueprintId across the matching connection */
  blueprintId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of completionTimeInS across the matching connection */
  completionTimeInS: Scalars['BigFloat']['output'];
  /** Sum of confidence across the matching connection */
  confidence: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of createdByContactId across the matching connection */
  createdByContactId: Scalars['BigInt']['output'];
  /** Sum of dealSize across the matching connection */
  dealSize: Scalars['Float']['output'];
  /** Sum of dealValue across the matching connection */
  dealValue: Scalars['Float']['output'];
  /** Sum of dealValueWeighted across the matching connection */
  dealValueWeighted: Scalars['Float']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of jurisdictionId across the matching connection */
  jurisdictionId: Scalars['BigInt']['output'];
  /** Sum of overdueBy across the matching connection */
  overdueBy: Scalars['Float']['output'];
  /** Sum of ownerId across the matching connection */
  ownerId: Scalars['BigInt']['output'];
  /** Sum of parentProjectId across the matching connection */
  parentProjectId: Scalars['BigInt']['output'];
  /** Sum of progress across the matching connection */
  progress: Scalars['BigInt']['output'];
  /** Sum of projectManagerId across the matching connection */
  projectManagerId: Scalars['BigInt']['output'];
  /** Sum of projectSize across the matching connection */
  projectSize: Scalars['Float']['output'];
  /** Sum of projectValue across the matching connection */
  projectValue: Scalars['Float']['output'];
  /** Sum of referrerContactId across the matching connection */
  referrerContactId: Scalars['BigInt']['output'];
  /** Sum of revenue across the matching connection */
  revenue: Scalars['Float']['output'];
  /** Sum of salesRepId across the matching connection */
  salesRepId: Scalars['BigInt']['output'];
  /** Sum of stageId across the matching connection */
  stageId: Scalars['BigInt']['output'];
  /** Sum of totalCost across the matching connection */
  totalCost: Scalars['Float']['output'];
  /** Sum of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Scalars['BigInt']['output'];
  /** Sum of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Scalars['BigInt']['output'];
  /** Sum of totalPrice across the matching connection */
  totalPrice: Scalars['Float']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
};


export type ProjectSumAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Comment`. */
export type ProjectSystemsByCommentProjectIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<ProjectSystemsByCommentProjectIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Comment`. */
export type ProjectSystemsByCommentProjectIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Comment`. */
export type ProjectSystemsByCommentProjectIdAndSystemIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsBySystemIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Comment`. */
export type ProjectSystemsByCommentProjectIdAndSystemIdManyToManyEdgeCommentsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type ProjectSystemsByFeedProjectIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectSystemsByFeedProjectIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type ProjectSystemsByFeedProjectIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type ProjectSystemsByFeedProjectIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type ProjectSystemsByFeedProjectIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `PrivilegedTask`. */
export type ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
};


/** A `System` edge in the connection, with data from `PrivilegedTask`. */
export type ProjectSystemsByPrivilegedTaskProjectIdAndSystemIdManyToManyEdgePrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectMember`. */
export type ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectMember`, and the cursor to aid in pagination. */
  edges: Array<ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectMember`. */
export type ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectMember`. */
export type ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembers: Array<ProjectMember>;
};


/** A `Team` edge in the connection, with data from `ProjectMember`. */
export type ProjectTeamsByProjectMemberProjectIdAndTeamIdManyToManyEdgeProjectMembersArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type ProjectTeamsByProjectReportProjectIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A filter to be used against many `Action` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyActionFilter = {
  /** Aggregates across related `Action` match the filter criteria. */
  aggregates: InputMaybe<ActionAggregatesFilter>;
  /** Every related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ActionFilter>;
  /** No related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ActionFilter>;
  /** Some related `Action` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ActionFilter>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `FavoriteProject` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFavoriteProjectFilter = {
  /** Aggregates across related `FavoriteProject` match the filter criteria. */
  aggregates: InputMaybe<FavoriteProjectAggregatesFilter>;
  /** Every related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FavoriteProjectFilter>;
  /** No related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FavoriteProjectFilter>;
  /** Some related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FavoriteProjectFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `ImportRow` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyImportRowFilter = {
  /** Aggregates across related `ImportRow` match the filter criteria. */
  aggregates: InputMaybe<ImportRowAggregatesFilter>;
  /** Every related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ImportRowFilter>;
  /** No related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ImportRowFilter>;
  /** Some related `ImportRow` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ImportRowFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyInvoiceFilter = {
  /** Aggregates across related `Invoice` match the filter criteria. */
  aggregates: InputMaybe<InvoiceAggregatesFilter>;
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<InvoiceFilter>;
};

/** A filter to be used against many `LineItem` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyLineItemFilter = {
  /** Aggregates across related `LineItem` match the filter criteria. */
  aggregates: InputMaybe<LineItemAggregatesFilter>;
  /** Every related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<LineItemFilter>;
  /** No related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<LineItemFilter>;
  /** Some related `LineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<LineItemFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `ProjectAddress` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectAddressFilter = {
  /** Aggregates across related `ProjectAddress` match the filter criteria. */
  aggregates: InputMaybe<ProjectAddressAggregatesFilter>;
  /** Every related `ProjectAddress` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectAddressFilter>;
  /** No related `ProjectAddress` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectAddressFilter>;
  /** Some related `ProjectAddress` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectAddressFilter>;
};

/** A filter to be used against many `ProjectContact` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectContactFilter = {
  /** Aggregates across related `ProjectContact` match the filter criteria. */
  aggregates: InputMaybe<ProjectContactAggregatesFilter>;
  /** Every related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectContactFilter>;
  /** No related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectContactFilter>;
  /** Some related `ProjectContact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectContactFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectMember` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectMemberFilter = {
  /** Aggregates across related `ProjectMember` match the filter criteria. */
  aggregates: InputMaybe<ProjectMemberAggregatesFilter>;
  /** Every related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectMemberFilter>;
  /** No related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectMemberFilter>;
  /** Some related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectMemberFilter>;
};

/** A filter to be used against many `ProjectPropertyValue` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectPropertyValueFilter = {
  /** Aggregates across related `ProjectPropertyValue` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueAggregatesFilter>;
  /** Every related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFilter>;
  /** No related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFilter>;
  /** Some related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `ProjectStageTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectStageTimelineFilter = {
  /** Aggregates across related `ProjectStageTimeline` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageTimelineAggregatesFilter>;
  /** Every related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageTimelineFilter>;
  /** No related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageTimelineFilter>;
  /** Some related `ProjectStageTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageTimelineFilter>;
};

/** A filter to be used against many `ProjectStageUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectStageUpdateFilter = {
  /** Aggregates across related `ProjectStageUpdate` match the filter criteria. */
  aggregates: InputMaybe<ProjectStageUpdateAggregatesFilter>;
  /** Every related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStageUpdateFilter>;
  /** No related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStageUpdateFilter>;
  /** Some related `ProjectStageUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStageUpdateFilter>;
};

/** A filter to be used against many `ProjectStatusTimeline` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectStatusTimelineFilter = {
  /** Aggregates across related `ProjectStatusTimeline` match the filter criteria. */
  aggregates: InputMaybe<ProjectStatusTimelineAggregatesFilter>;
  /** Every related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStatusTimelineFilter>;
  /** No related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStatusTimelineFilter>;
  /** Some related `ProjectStatusTimeline` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStatusTimelineFilter>;
};

/** A filter to be used against many `ProjectStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectStatusUpdateFilter = {
  /** Aggregates across related `ProjectStatusUpdate` match the filter criteria. */
  aggregates: InputMaybe<ProjectStatusUpdateAggregatesFilter>;
  /** Every related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectStatusUpdateFilter>;
  /** No related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectStatusUpdateFilter>;
  /** Some related `ProjectStatusUpdate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectStatusUpdateFilter>;
};

/** A filter to be used against many `ProjectTrade` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectTradeFilter = {
  /** Aggregates across related `ProjectTrade` match the filter criteria. */
  aggregates: InputMaybe<ProjectTradeAggregatesFilter>;
  /** Every related `ProjectTrade` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectTradeFilter>;
  /** No related `ProjectTrade` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectTradeFilter>;
  /** Some related `ProjectTrade` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectTradeFilter>;
};

/** A filter to be used against many `Reminder` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyReminderFilter = {
  /** Aggregates across related `Reminder` match the filter criteria. */
  aggregates: InputMaybe<ReminderAggregatesFilter>;
  /** Every related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ReminderFilter>;
  /** No related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ReminderFilter>;
  /** Some related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ReminderFilter>;
};

/** A filter to be used against many `System` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManySystemFilter = {
  /** Aggregates across related `System` match the filter criteria. */
  aggregates: InputMaybe<SystemAggregatesFilter>;
  /** Every related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemFilter>;
  /** No related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemFilter>;
  /** Some related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyTaskFilter = {
  /** Aggregates across related `Task` match the filter criteria. */
  aggregates: InputMaybe<TaskAggregatesFilter>;
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyTaskReportFilter = {
  /** Aggregates across related `TaskReport` match the filter criteria. */
  aggregates: InputMaybe<TaskReportAggregatesFilter>;
  /** Every related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskReportFilter>;
  /** No related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskReportFilter>;
  /** Some related `TaskReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskReportFilter>;
};

export type ProjectTrade = {
  /** Reads a single `Project` that is related to this `ProjectTrade`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  trade: Maybe<Scalars['String']['output']>;
};

/** A filter to be used against aggregates of `ProjectTrade` object types. */
export type ProjectTradeAggregatesFilter = {
  /** Array aggregation aggregate over matching `ProjectTrade` objects. */
  arrayAgg: InputMaybe<ProjectTradeArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `ProjectTrade` objects. */
  average: InputMaybe<ProjectTradeAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ProjectTrade` objects. */
  distinctCount: InputMaybe<ProjectTradeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProjectTrade` object to be included within the aggregate. */
  filter: InputMaybe<ProjectTradeFilter>;
  /** Maximum aggregate over matching `ProjectTrade` objects. */
  max: InputMaybe<ProjectTradeMaxAggregateFilter>;
  /** Minimum aggregate over matching `ProjectTrade` objects. */
  min: InputMaybe<ProjectTradeMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ProjectTrade` objects. */
  stddevPopulation: InputMaybe<ProjectTradeStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ProjectTrade` objects. */
  stddevSample: InputMaybe<ProjectTradeStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ProjectTrade` objects. */
  sum: InputMaybe<ProjectTradeSumAggregateFilter>;
  /** Population variance aggregate over matching `ProjectTrade` objects. */
  variancePopulation: InputMaybe<ProjectTradeVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ProjectTrade` objects. */
  varianceSample: InputMaybe<ProjectTradeVarianceSampleAggregateFilter>;
};

export type ProjectTradeArrayAggAggregateFilter = {
  projectId: InputMaybe<IntListFilter>;
  trade: InputMaybe<StringListFilter>;
};

export type ProjectTradeAverageAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `ProjectTrade` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectTradeCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `trade` field. */
  trade: InputMaybe<Scalars['String']['input']>;
};

export type ProjectTradeDistinctCountAggregateFilter = {
  projectId: InputMaybe<BigIntFilter>;
  trade: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `ProjectTrade` object types. All fields are combined with a logical ‘and.’ */
export type ProjectTradeFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ProjectTradeFilter>>;
  /** Negates the expression. */
  not: InputMaybe<ProjectTradeFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ProjectTradeFilter>>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `trade` field. */
  trade: InputMaybe<StringFilter>;
};

export type ProjectTradeMaxAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
};

export type ProjectTradeMinAggregateFilter = {
  projectId: InputMaybe<IntFilter>;
};

export type ProjectTradeStddevPopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectTradeStddevSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectTradeSumAggregateFilter = {
  projectId: InputMaybe<BigIntFilter>;
};

export type ProjectTradeVariancePopulationAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

export type ProjectTradeVarianceSampleAggregateFilter = {
  projectId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `ProjectTrade`. */
export enum ProjectTradesOrderBy {
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  TradeAsc = 'TRADE_ASC',
  TradeDesc = 'TRADE_DESC'
}

/** A connection to a list of `User` values, with data from `Feed`. */
export type ProjectUsersByFeedProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByFeedProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type ProjectUsersByFeedProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type ProjectUsersByFeedProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type ProjectUsersByFeedProjectIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `File`. */
export type ProjectUsersByFileProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByFileProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `File`. */
export type ProjectUsersByFileProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `File`. */
export type ProjectUsersByFileProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ProjectUsersByFileProjectIdAndCreatedByManyToManyEdgeFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `User` edge in the connection, with data from `File`. */
export type ProjectUsersByFileProjectIdAndCreatedByManyToManyEdgeFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type ProjectUsersByProjectParentProjectIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type ProjectUsersByProjectReportProjectIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeIdConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<ProjectUsersByReminderProjectIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedBy: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedByConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndCreatedByManyToManyEdgeRemindersByCreatedByArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type ProjectUsersByReminderProjectIdAndCreatedByManyToManyEdgeRemindersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

export type ProjectVariancePopulationAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type ProjectVariancePopulationAggregates = {
  /** Population variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Population variance of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Population variance of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Population variance of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Population variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Population variance of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Population variance of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectVariancePopulationAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

export type ProjectVarianceSampleAggregateFilter = {
  blueprintId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  confidence: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  createdByContactId: InputMaybe<BigFloatFilter>;
  dealSize: InputMaybe<FloatFilter>;
  dealValue: InputMaybe<FloatFilter>;
  dealValueWeighted: InputMaybe<FloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  jurisdictionId: InputMaybe<BigFloatFilter>;
  overdueBy: InputMaybe<FloatFilter>;
  ownerId: InputMaybe<BigFloatFilter>;
  parentProjectId: InputMaybe<BigFloatFilter>;
  progress: InputMaybe<BigFloatFilter>;
  projectManagerId: InputMaybe<BigFloatFilter>;
  projectSize: InputMaybe<FloatFilter>;
  projectValue: InputMaybe<FloatFilter>;
  referrerContactId: InputMaybe<BigFloatFilter>;
  revenue: InputMaybe<FloatFilter>;
  salesRepId: InputMaybe<BigFloatFilter>;
  stageId: InputMaybe<BigFloatFilter>;
  totalCost: InputMaybe<FloatFilter>;
  totalInboundCallsCount: InputMaybe<BigFloatFilter>;
  totalOutboundCallsCount: InputMaybe<BigFloatFilter>;
  totalPrice: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type ProjectVarianceSampleAggregates = {
  /** Sample variance of blueprintId across the matching connection */
  blueprintId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of confidence across the matching connection */
  confidence: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdByContactId across the matching connection */
  createdByContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of dealSize across the matching connection */
  dealSize: Maybe<Scalars['Float']['output']>;
  /** Sample variance of dealValue across the matching connection */
  dealValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of dealValueWeighted across the matching connection */
  dealValueWeighted: Maybe<Scalars['Float']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of jurisdictionId across the matching connection */
  jurisdictionId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of overdueBy across the matching connection */
  overdueBy: Maybe<Scalars['Float']['output']>;
  /** Sample variance of ownerId across the matching connection */
  ownerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of parentProjectId across the matching connection */
  parentProjectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of progress across the matching connection */
  progress: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectManagerId across the matching connection */
  projectManagerId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectSize across the matching connection */
  projectSize: Maybe<Scalars['Float']['output']>;
  /** Sample variance of projectValue across the matching connection */
  projectValue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of referrerContactId across the matching connection */
  referrerContactId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of revenue across the matching connection */
  revenue: Maybe<Scalars['Float']['output']>;
  /** Sample variance of salesRepId across the matching connection */
  salesRepId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of stageId across the matching connection */
  stageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalCost across the matching connection */
  totalCost: Maybe<Scalars['Float']['output']>;
  /** Sample variance of totalInboundCallsCount across the matching connection */
  totalInboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalOutboundCallsCount across the matching connection */
  totalOutboundCallsCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalPrice across the matching connection */
  totalPrice: Maybe<Scalars['Float']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type ProjectVarianceSampleAggregatesBlueprintIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesConfidenceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesCreatedByContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesDealSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesDealValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesDealValueWeightedArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesJurisdictionIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesOverdueByArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesOwnerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesParentProjectIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesProgressArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesProjectManagerIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesProjectSizeArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesProjectValueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesReferrerContactIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesRevenueArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesSalesRepIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesStageIdArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesTotalCostArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesTotalInboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesTotalOutboundCallsCountArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesTotalPriceArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ProjectVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values. */
export type ProjectsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  AccountStatusAsc = 'ACCOUNT_STATUS_ASC',
  AccountStatusDesc = 'ACCOUNT_STATUS_DESC',
  AccountTypeAsc = 'ACCOUNT_TYPE_ASC',
  AccountTypeDesc = 'ACCOUNT_TYPE_DESC',
  ActionsByProjectIdConnectionArrayAggActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionArrayAggActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionArrayAggAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionArrayAggAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionArrayAggCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionArrayAggCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionArrayAggCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionArrayAggCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionArrayAggCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ActionsByProjectIdConnectionArrayAggCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ActionsByProjectIdConnectionArrayAggCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ActionsByProjectIdConnectionArrayAggCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ActionsByProjectIdConnectionArrayAggDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionArrayAggDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionArrayAggDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  ActionsByProjectIdConnectionArrayAggDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  ActionsByProjectIdConnectionArrayAggIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ActionsByProjectIdConnectionArrayAggIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ActionsByProjectIdConnectionArrayAggIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionArrayAggIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionArrayAggJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  ActionsByProjectIdConnectionArrayAggJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  ActionsByProjectIdConnectionArrayAggProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionArrayAggProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionArrayAggProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionArrayAggProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionArrayAggStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ActionsByProjectIdConnectionArrayAggStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ActionsByProjectIdConnectionArrayAggTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ActionsByProjectIdConnectionArrayAggTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ActionsByProjectIdConnectionArrayAggTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ActionsByProjectIdConnectionArrayAggTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ActionsByProjectIdConnectionArrayAggUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionArrayAggUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionArrayAggUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  ActionsByProjectIdConnectionArrayAggUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  ActionsByProjectIdConnectionAverageActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionAverageActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionAverageAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionAverageAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionAverageCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionAverageCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionAverageCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionAverageCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionAverageCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ActionsByProjectIdConnectionAverageCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ActionsByProjectIdConnectionAverageCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ActionsByProjectIdConnectionAverageCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ActionsByProjectIdConnectionAverageDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionAverageDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionAverageDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_ASC',
  ActionsByProjectIdConnectionAverageDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_DESC',
  ActionsByProjectIdConnectionAverageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  ActionsByProjectIdConnectionAverageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  ActionsByProjectIdConnectionAverageIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionAverageIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionAverageJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  ActionsByProjectIdConnectionAverageJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  ActionsByProjectIdConnectionAverageProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionAverageProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionAverageProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionAverageProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionAverageStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ActionsByProjectIdConnectionAverageStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ActionsByProjectIdConnectionAverageTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ActionsByProjectIdConnectionAverageTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ActionsByProjectIdConnectionAverageTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ActionsByProjectIdConnectionAverageTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ActionsByProjectIdConnectionAverageUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionAverageUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionAverageUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_UUID_ASC',
  ActionsByProjectIdConnectionAverageUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_AVERAGE_UUID_DESC',
  ActionsByProjectIdConnectionCountAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ActionsByProjectIdConnectionCountDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ActionsByProjectIdConnectionDistinctCountActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionDistinctCountAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionDistinctCountCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionDistinctCountCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionDistinctCountCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ActionsByProjectIdConnectionDistinctCountCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ActionsByProjectIdConnectionDistinctCountCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ActionsByProjectIdConnectionDistinctCountCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ActionsByProjectIdConnectionDistinctCountDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionDistinctCountDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionDistinctCountDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  ActionsByProjectIdConnectionDistinctCountDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  ActionsByProjectIdConnectionDistinctCountIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionDistinctCountIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionDistinctCountJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionDistinctCountProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionDistinctCountStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ActionsByProjectIdConnectionDistinctCountStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ActionsByProjectIdConnectionDistinctCountTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ActionsByProjectIdConnectionDistinctCountTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ActionsByProjectIdConnectionDistinctCountTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ActionsByProjectIdConnectionDistinctCountTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ActionsByProjectIdConnectionDistinctCountUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionDistinctCountUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionDistinctCountUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  ActionsByProjectIdConnectionDistinctCountUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  ActionsByProjectIdConnectionMaxActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionMaxActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionMaxAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionMaxAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionMaxCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionMaxCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionMaxCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionMaxCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionMaxCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ActionsByProjectIdConnectionMaxCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ActionsByProjectIdConnectionMaxCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ActionsByProjectIdConnectionMaxCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ActionsByProjectIdConnectionMaxDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionMaxDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionMaxDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_ASC',
  ActionsByProjectIdConnectionMaxDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_DESC',
  ActionsByProjectIdConnectionMaxIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  ActionsByProjectIdConnectionMaxIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  ActionsByProjectIdConnectionMaxIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionMaxIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionMaxJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_ASC',
  ActionsByProjectIdConnectionMaxJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_DESC',
  ActionsByProjectIdConnectionMaxProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionMaxProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionMaxProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionMaxProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionMaxStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  ActionsByProjectIdConnectionMaxStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  ActionsByProjectIdConnectionMaxTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  ActionsByProjectIdConnectionMaxTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  ActionsByProjectIdConnectionMaxTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  ActionsByProjectIdConnectionMaxTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  ActionsByProjectIdConnectionMaxUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionMaxUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionMaxUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_UUID_ASC',
  ActionsByProjectIdConnectionMaxUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MAX_UUID_DESC',
  ActionsByProjectIdConnectionMinActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionMinActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionMinAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionMinAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionMinCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionMinCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionMinCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionMinCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionMinCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ActionsByProjectIdConnectionMinCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ActionsByProjectIdConnectionMinCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ActionsByProjectIdConnectionMinCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ActionsByProjectIdConnectionMinDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionMinDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionMinDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_ASC',
  ActionsByProjectIdConnectionMinDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_DESC',
  ActionsByProjectIdConnectionMinIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  ActionsByProjectIdConnectionMinIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  ActionsByProjectIdConnectionMinIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionMinIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionMinJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_ASC',
  ActionsByProjectIdConnectionMinJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_DESC',
  ActionsByProjectIdConnectionMinProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionMinProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionMinProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionMinProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionMinStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  ActionsByProjectIdConnectionMinStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  ActionsByProjectIdConnectionMinTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  ActionsByProjectIdConnectionMinTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  ActionsByProjectIdConnectionMinTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  ActionsByProjectIdConnectionMinTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  ActionsByProjectIdConnectionMinUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionMinUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionMinUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_UUID_ASC',
  ActionsByProjectIdConnectionMinUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_MIN_UUID_DESC',
  ActionsByProjectIdConnectionStddevPopulationActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionStddevPopulationAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionStddevPopulationCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionStddevPopulationCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ActionsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ActionsByProjectIdConnectionStddevPopulationCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ActionsByProjectIdConnectionStddevPopulationCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ActionsByProjectIdConnectionStddevPopulationDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionStddevPopulationDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionStddevPopulationDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  ActionsByProjectIdConnectionStddevPopulationDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  ActionsByProjectIdConnectionStddevPopulationIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionStddevPopulationIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionStddevPopulationJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionStddevPopulationProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionStddevPopulationStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ActionsByProjectIdConnectionStddevPopulationStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ActionsByProjectIdConnectionStddevPopulationTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ActionsByProjectIdConnectionStddevPopulationTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ActionsByProjectIdConnectionStddevPopulationTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ActionsByProjectIdConnectionStddevPopulationTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ActionsByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionStddevPopulationUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  ActionsByProjectIdConnectionStddevPopulationUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  ActionsByProjectIdConnectionStddevSampleActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionStddevSampleAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionStddevSampleCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionStddevSampleCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionStddevSampleCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ActionsByProjectIdConnectionStddevSampleCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ActionsByProjectIdConnectionStddevSampleCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ActionsByProjectIdConnectionStddevSampleCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ActionsByProjectIdConnectionStddevSampleDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionStddevSampleDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionStddevSampleDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  ActionsByProjectIdConnectionStddevSampleDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  ActionsByProjectIdConnectionStddevSampleIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionStddevSampleIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionStddevSampleJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionStddevSampleProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionStddevSampleStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ActionsByProjectIdConnectionStddevSampleStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ActionsByProjectIdConnectionStddevSampleTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ActionsByProjectIdConnectionStddevSampleTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ActionsByProjectIdConnectionStddevSampleTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ActionsByProjectIdConnectionStddevSampleTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ActionsByProjectIdConnectionStddevSampleUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionStddevSampleUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionStddevSampleUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  ActionsByProjectIdConnectionStddevSampleUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  ActionsByProjectIdConnectionSumActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionSumActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionSumAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionSumAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionSumCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionSumCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionSumCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionSumCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionSumCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ActionsByProjectIdConnectionSumCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ActionsByProjectIdConnectionSumCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ActionsByProjectIdConnectionSumCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ActionsByProjectIdConnectionSumDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionSumDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionSumDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_ASC',
  ActionsByProjectIdConnectionSumDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_DESC',
  ActionsByProjectIdConnectionSumIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  ActionsByProjectIdConnectionSumIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  ActionsByProjectIdConnectionSumIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionSumIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionSumJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_ASC',
  ActionsByProjectIdConnectionSumJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_DESC',
  ActionsByProjectIdConnectionSumProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionSumProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionSumProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionSumProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionSumStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  ActionsByProjectIdConnectionSumStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  ActionsByProjectIdConnectionSumTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  ActionsByProjectIdConnectionSumTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  ActionsByProjectIdConnectionSumTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  ActionsByProjectIdConnectionSumTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  ActionsByProjectIdConnectionSumUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionSumUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionSumUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_UUID_ASC',
  ActionsByProjectIdConnectionSumUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_SUM_UUID_DESC',
  ActionsByProjectIdConnectionVariancePopulationActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionVariancePopulationAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionVariancePopulationCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionVariancePopulationCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ActionsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ActionsByProjectIdConnectionVariancePopulationCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ActionsByProjectIdConnectionVariancePopulationCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ActionsByProjectIdConnectionVariancePopulationDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionVariancePopulationDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionVariancePopulationDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  ActionsByProjectIdConnectionVariancePopulationDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  ActionsByProjectIdConnectionVariancePopulationIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionVariancePopulationIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionVariancePopulationJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionVariancePopulationProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionVariancePopulationStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ActionsByProjectIdConnectionVariancePopulationStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ActionsByProjectIdConnectionVariancePopulationTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ActionsByProjectIdConnectionVariancePopulationTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ActionsByProjectIdConnectionVariancePopulationTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ActionsByProjectIdConnectionVariancePopulationTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ActionsByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionVariancePopulationUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  ActionsByProjectIdConnectionVariancePopulationUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  ActionsByProjectIdConnectionVarianceSampleActionTemplateIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleActionTemplateIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_TEMPLATE_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleAssignAllContactsAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_ASC',
  ActionsByProjectIdConnectionVarianceSampleAssignAllContactsDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGN_ALL_CONTACTS_DESC',
  ActionsByProjectIdConnectionVarianceSampleCompanyIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleCompanyIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleCompletedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  ActionsByProjectIdConnectionVarianceSampleCompletedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  ActionsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ActionsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ActionsByProjectIdConnectionVarianceSampleCreatedByAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ActionsByProjectIdConnectionVarianceSampleCreatedByDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ActionsByProjectIdConnectionVarianceSampleDescriptionAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ActionsByProjectIdConnectionVarianceSampleDescriptionDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ActionsByProjectIdConnectionVarianceSampleDueDateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  ActionsByProjectIdConnectionVarianceSampleDueDateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  ActionsByProjectIdConnectionVarianceSampleIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleIsTemplateAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  ActionsByProjectIdConnectionVarianceSampleIsTemplateDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  ActionsByProjectIdConnectionVarianceSampleJobIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleJobIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleProjectIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleProjectIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleProjectStageIdAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  ActionsByProjectIdConnectionVarianceSampleProjectStageIdDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  ActionsByProjectIdConnectionVarianceSampleStatusAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ActionsByProjectIdConnectionVarianceSampleStatusDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ActionsByProjectIdConnectionVarianceSampleTitleAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ActionsByProjectIdConnectionVarianceSampleTitleDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ActionsByProjectIdConnectionVarianceSampleTypeAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ActionsByProjectIdConnectionVarianceSampleTypeDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ActionsByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ActionsByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ActionsByProjectIdConnectionVarianceSampleUuidAsc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  ActionsByProjectIdConnectionVarianceSampleUuidDesc = 'ACTIONS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AddressNormalizedAsc = 'ADDRESS_NORMALIZED_ASC',
  AddressNormalizedDesc = 'ADDRESS_NORMALIZED_DESC',
  AddressPrettyAsc = 'ADDRESS_PRETTY_ASC',
  AddressPrettyDesc = 'ADDRESS_PRETTY_DESC',
  AhjAsc = 'AHJ_ASC',
  AhjDesc = 'AHJ_DESC',
  BlueprintIdAsc = 'BLUEPRINT_ID_ASC',
  BlueprintIdDesc = 'BLUEPRINT_ID_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CommentsByProjectIdConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionArrayAggCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsByProjectIdConnectionArrayAggCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsByProjectIdConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsByProjectIdConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsByProjectIdConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsByProjectIdConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsByProjectIdConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionArrayAggFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsByProjectIdConnectionArrayAggFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsByProjectIdConnectionArrayAggIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsByProjectIdConnectionArrayAggIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsByProjectIdConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsByProjectIdConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsByProjectIdConnectionArrayAggParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsByProjectIdConnectionArrayAggParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsByProjectIdConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsByProjectIdConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsByProjectIdConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionArrayAggScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsByProjectIdConnectionArrayAggScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsByProjectIdConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsByProjectIdConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsByProjectIdConnectionArrayAggTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsByProjectIdConnectionArrayAggTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsByProjectIdConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionArrayAggXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsByProjectIdConnectionArrayAggXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsByProjectIdConnectionArrayAggYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsByProjectIdConnectionArrayAggYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsByProjectIdConnectionAverageAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionAverageAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionAverageCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsByProjectIdConnectionAverageCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsByProjectIdConnectionAverageCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionAverageCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionAverageCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsByProjectIdConnectionAverageCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsByProjectIdConnectionAverageCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsByProjectIdConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionAverageCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsByProjectIdConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionAverageFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsByProjectIdConnectionAverageFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsByProjectIdConnectionAverageIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  CommentsByProjectIdConnectionAverageIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  CommentsByProjectIdConnectionAverageIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsByProjectIdConnectionAverageIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsByProjectIdConnectionAverageParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsByProjectIdConnectionAverageParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsByProjectIdConnectionAveragePinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsByProjectIdConnectionAveragePinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsByProjectIdConnectionAveragePreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionAveragePreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionAverageProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionAverageProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionAverageScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsByProjectIdConnectionAverageScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsByProjectIdConnectionAverageSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionAverageSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionAverageTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsByProjectIdConnectionAverageTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsByProjectIdConnectionAverageTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsByProjectIdConnectionAverageTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsByProjectIdConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionAverageXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_X_ASC',
  CommentsByProjectIdConnectionAverageXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_X_DESC',
  CommentsByProjectIdConnectionAverageYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_Y_ASC',
  CommentsByProjectIdConnectionAverageYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_AVERAGE_Y_DESC',
  CommentsByProjectIdConnectionCountAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  CommentsByProjectIdConnectionCountDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  CommentsByProjectIdConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsByProjectIdConnectionDistinctCountCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsByProjectIdConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsByProjectIdConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsByProjectIdConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsByProjectIdConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsByProjectIdConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsByProjectIdConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsByProjectIdConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsByProjectIdConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsByProjectIdConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsByProjectIdConnectionDistinctCountScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsByProjectIdConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsByProjectIdConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsByProjectIdConnectionDistinctCountTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsByProjectIdConnectionDistinctCountTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsByProjectIdConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionDistinctCountXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsByProjectIdConnectionDistinctCountXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsByProjectIdConnectionDistinctCountYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsByProjectIdConnectionDistinctCountYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsByProjectIdConnectionMaxAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionMaxAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionMaxCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_ASC',
  CommentsByProjectIdConnectionMaxCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_DESC',
  CommentsByProjectIdConnectionMaxCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionMaxCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionMaxCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsByProjectIdConnectionMaxCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsByProjectIdConnectionMaxCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsByProjectIdConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionMaxCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsByProjectIdConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionMaxFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_FILE_ID_ASC',
  CommentsByProjectIdConnectionMaxFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_FILE_ID_DESC',
  CommentsByProjectIdConnectionMaxIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  CommentsByProjectIdConnectionMaxIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  CommentsByProjectIdConnectionMaxIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsByProjectIdConnectionMaxIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsByProjectIdConnectionMaxParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsByProjectIdConnectionMaxParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsByProjectIdConnectionMaxPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsByProjectIdConnectionMaxPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsByProjectIdConnectionMaxPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionMaxPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionMaxProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionMaxProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionMaxScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_SCOPE_ASC',
  CommentsByProjectIdConnectionMaxScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_SCOPE_DESC',
  CommentsByProjectIdConnectionMaxSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionMaxSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionMaxTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_ASC',
  CommentsByProjectIdConnectionMaxTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_DESC',
  CommentsByProjectIdConnectionMaxTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  CommentsByProjectIdConnectionMaxTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  CommentsByProjectIdConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionMaxXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_X_ASC',
  CommentsByProjectIdConnectionMaxXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_X_DESC',
  CommentsByProjectIdConnectionMaxYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_Y_ASC',
  CommentsByProjectIdConnectionMaxYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MAX_Y_DESC',
  CommentsByProjectIdConnectionMinAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionMinAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionMinAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionMinAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionMinCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_ASC',
  CommentsByProjectIdConnectionMinCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_DESC',
  CommentsByProjectIdConnectionMinCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionMinCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionMinCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsByProjectIdConnectionMinCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsByProjectIdConnectionMinCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsByProjectIdConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionMinCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsByProjectIdConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionMinFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_FILE_ID_ASC',
  CommentsByProjectIdConnectionMinFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_FILE_ID_DESC',
  CommentsByProjectIdConnectionMinIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  CommentsByProjectIdConnectionMinIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  CommentsByProjectIdConnectionMinIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsByProjectIdConnectionMinIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsByProjectIdConnectionMinParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsByProjectIdConnectionMinParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsByProjectIdConnectionMinPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsByProjectIdConnectionMinPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsByProjectIdConnectionMinPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionMinPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionMinProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionMinProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionMinScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_SCOPE_ASC',
  CommentsByProjectIdConnectionMinScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_SCOPE_DESC',
  CommentsByProjectIdConnectionMinSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionMinSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionMinTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_ASC',
  CommentsByProjectIdConnectionMinTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_DESC',
  CommentsByProjectIdConnectionMinTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  CommentsByProjectIdConnectionMinTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  CommentsByProjectIdConnectionMinUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionMinUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionMinXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_X_ASC',
  CommentsByProjectIdConnectionMinXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_X_DESC',
  CommentsByProjectIdConnectionMinYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_Y_ASC',
  CommentsByProjectIdConnectionMinYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_MIN_Y_DESC',
  CommentsByProjectIdConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsByProjectIdConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsByProjectIdConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsByProjectIdConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsByProjectIdConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsByProjectIdConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsByProjectIdConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsByProjectIdConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsByProjectIdConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsByProjectIdConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsByProjectIdConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsByProjectIdConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionStddevPopulationXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsByProjectIdConnectionStddevPopulationXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsByProjectIdConnectionStddevPopulationYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsByProjectIdConnectionStddevPopulationYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsByProjectIdConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsByProjectIdConnectionStddevSampleCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsByProjectIdConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsByProjectIdConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsByProjectIdConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsByProjectIdConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsByProjectIdConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsByProjectIdConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsByProjectIdConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsByProjectIdConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsByProjectIdConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsByProjectIdConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsByProjectIdConnectionStddevSampleScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsByProjectIdConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsByProjectIdConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsByProjectIdConnectionStddevSampleTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsByProjectIdConnectionStddevSampleTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsByProjectIdConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionStddevSampleXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsByProjectIdConnectionStddevSampleXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsByProjectIdConnectionStddevSampleYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsByProjectIdConnectionStddevSampleYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsByProjectIdConnectionSumAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionSumAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionSumAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionSumAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionSumCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_ASC',
  CommentsByProjectIdConnectionSumCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_DESC',
  CommentsByProjectIdConnectionSumCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionSumCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionSumCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsByProjectIdConnectionSumCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsByProjectIdConnectionSumCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsByProjectIdConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionSumCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsByProjectIdConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionSumFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_FILE_ID_ASC',
  CommentsByProjectIdConnectionSumFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_FILE_ID_DESC',
  CommentsByProjectIdConnectionSumIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  CommentsByProjectIdConnectionSumIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  CommentsByProjectIdConnectionSumIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsByProjectIdConnectionSumIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsByProjectIdConnectionSumParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsByProjectIdConnectionSumParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsByProjectIdConnectionSumPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsByProjectIdConnectionSumPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsByProjectIdConnectionSumPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionSumPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionSumProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionSumProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionSumScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_SCOPE_ASC',
  CommentsByProjectIdConnectionSumScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_SCOPE_DESC',
  CommentsByProjectIdConnectionSumSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionSumSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionSumTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_ASC',
  CommentsByProjectIdConnectionSumTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_DESC',
  CommentsByProjectIdConnectionSumTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  CommentsByProjectIdConnectionSumTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  CommentsByProjectIdConnectionSumUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionSumUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionSumXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_X_ASC',
  CommentsByProjectIdConnectionSumXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_X_DESC',
  CommentsByProjectIdConnectionSumYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_Y_ASC',
  CommentsByProjectIdConnectionSumYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_SUM_Y_DESC',
  CommentsByProjectIdConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsByProjectIdConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsByProjectIdConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsByProjectIdConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsByProjectIdConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsByProjectIdConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsByProjectIdConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsByProjectIdConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsByProjectIdConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsByProjectIdConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsByProjectIdConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsByProjectIdConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionVariancePopulationXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsByProjectIdConnectionVariancePopulationXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsByProjectIdConnectionVariancePopulationYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsByProjectIdConnectionVariancePopulationYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsByProjectIdConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsByProjectIdConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsByProjectIdConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsByProjectIdConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsByProjectIdConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsByProjectIdConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsByProjectIdConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsByProjectIdConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsByProjectIdConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsByProjectIdConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsByProjectIdConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsByProjectIdConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsByProjectIdConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsByProjectIdConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsByProjectIdConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsByProjectIdConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsByProjectIdConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsByProjectIdConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsByProjectIdConnectionVarianceSampleXAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsByProjectIdConnectionVarianceSampleXDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsByProjectIdConnectionVarianceSampleYAsc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsByProjectIdConnectionVarianceSampleYDesc = 'COMMENTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletionDateAsc = 'COMPLETION_DATE_ASC',
  CompletionDateDesc = 'COMPLETION_DATE_DESC',
  CompletionTimeAsc = 'COMPLETION_TIME_ASC',
  CompletionTimeDesc = 'COMPLETION_TIME_DESC',
  CompletionTimeInSAsc = 'COMPLETION_TIME_IN_S_ASC',
  CompletionTimeInSDesc = 'COMPLETION_TIME_IN_S_DESC',
  ConfidenceAsc = 'CONFIDENCE_ASC',
  ConfidenceDesc = 'CONFIDENCE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByContactIdAsc = 'CREATED_BY_CONTACT_ID_ASC',
  CreatedByContactIdDesc = 'CREATED_BY_CONTACT_ID_DESC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedByTypeAsc = 'CREATED_BY_TYPE_ASC',
  CreatedByTypeDesc = 'CREATED_BY_TYPE_DESC',
  DealSizeAsc = 'DEAL_SIZE_ASC',
  DealSizeDesc = 'DEAL_SIZE_DESC',
  DealValueAsc = 'DEAL_VALUE_ASC',
  DealValueDesc = 'DEAL_VALUE_DESC',
  DealValueWeightedAsc = 'DEAL_VALUE_WEIGHTED_ASC',
  DealValueWeightedDesc = 'DEAL_VALUE_WEIGHTED_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FavoriteProjectsConnectionArrayAggProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FavoriteProjectsConnectionArrayAggProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FavoriteProjectsConnectionArrayAggWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  FavoriteProjectsConnectionArrayAggWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  FavoriteProjectsConnectionAverageProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionAverageProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionAverageWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_WORKER_ID_ASC',
  FavoriteProjectsConnectionAverageWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_WORKER_ID_DESC',
  FavoriteProjectsConnectionCountAsc = 'FAVORITE_PROJECTS_CONNECTION_COUNT_ASC',
  FavoriteProjectsConnectionCountDesc = 'FAVORITE_PROJECTS_CONNECTION_COUNT_DESC',
  FavoriteProjectsConnectionDistinctCountProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FavoriteProjectsConnectionDistinctCountProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FavoriteProjectsConnectionDistinctCountWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  FavoriteProjectsConnectionDistinctCountWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  FavoriteProjectsConnectionMaxProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MAX_PROJECT_ID_ASC',
  FavoriteProjectsConnectionMaxProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MAX_PROJECT_ID_DESC',
  FavoriteProjectsConnectionMaxWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MAX_WORKER_ID_ASC',
  FavoriteProjectsConnectionMaxWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MAX_WORKER_ID_DESC',
  FavoriteProjectsConnectionMinProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MIN_PROJECT_ID_ASC',
  FavoriteProjectsConnectionMinProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MIN_PROJECT_ID_DESC',
  FavoriteProjectsConnectionMinWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MIN_WORKER_ID_ASC',
  FavoriteProjectsConnectionMinWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MIN_WORKER_ID_DESC',
  FavoriteProjectsConnectionStddevPopulationProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FavoriteProjectsConnectionStddevPopulationProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FavoriteProjectsConnectionStddevPopulationWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  FavoriteProjectsConnectionStddevPopulationWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  FavoriteProjectsConnectionStddevSampleProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionStddevSampleProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionStddevSampleWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  FavoriteProjectsConnectionStddevSampleWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  FavoriteProjectsConnectionSumProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_SUM_PROJECT_ID_ASC',
  FavoriteProjectsConnectionSumProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_SUM_PROJECT_ID_DESC',
  FavoriteProjectsConnectionSumWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_SUM_WORKER_ID_ASC',
  FavoriteProjectsConnectionSumWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_SUM_WORKER_ID_DESC',
  FavoriteProjectsConnectionVariancePopulationProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FavoriteProjectsConnectionVariancePopulationProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FavoriteProjectsConnectionVariancePopulationWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  FavoriteProjectsConnectionVariancePopulationWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  FavoriteProjectsConnectionVarianceSampleProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionVarianceSampleProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionVarianceSampleWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  FavoriteProjectsConnectionVarianceSampleWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC',
  FeedsByProjectIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByProjectIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByProjectIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByProjectIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByProjectIdConnectionArrayAggCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByProjectIdConnectionArrayAggCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByProjectIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByProjectIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByProjectIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByProjectIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByProjectIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionArrayAggEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByProjectIdConnectionArrayAggEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByProjectIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByProjectIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByProjectIdConnectionArrayAggIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByProjectIdConnectionArrayAggIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByProjectIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByProjectIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByProjectIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByProjectIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByProjectIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByProjectIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByProjectIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByProjectIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByProjectIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionAverageActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByProjectIdConnectionAverageActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByProjectIdConnectionAverageCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByProjectIdConnectionAverageCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByProjectIdConnectionAverageCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByProjectIdConnectionAverageCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByProjectIdConnectionAverageCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionAverageCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByProjectIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByProjectIdConnectionAverageCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByProjectIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionAverageCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByProjectIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionAverageEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByProjectIdConnectionAverageEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByProjectIdConnectionAverageEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionAverageEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionAverageFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByProjectIdConnectionAverageFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByProjectIdConnectionAverageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsByProjectIdConnectionAverageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsByProjectIdConnectionAverageParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByProjectIdConnectionAverageParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByProjectIdConnectionAveragePayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByProjectIdConnectionAveragePayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByProjectIdConnectionAverageProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionAverageProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionAverageReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionAverageReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionAverageRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByProjectIdConnectionAverageRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByProjectIdConnectionAverageRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionAverageRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionAverageSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionAverageSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionAverageSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionAverageSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionAverageTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByProjectIdConnectionAverageTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByProjectIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  FeedsByProjectIdConnectionCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  FeedsByProjectIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByProjectIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByProjectIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByProjectIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByProjectIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByProjectIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByProjectIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByProjectIdConnectionDistinctCountEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByProjectIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByProjectIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByProjectIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByProjectIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByProjectIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByProjectIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionMaxActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByProjectIdConnectionMaxActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByProjectIdConnectionMaxCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByProjectIdConnectionMaxCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByProjectIdConnectionMaxCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsByProjectIdConnectionMaxCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsByProjectIdConnectionMaxCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionMaxCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByProjectIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByProjectIdConnectionMaxCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByProjectIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionMaxCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByProjectIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionMaxEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsByProjectIdConnectionMaxEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsByProjectIdConnectionMaxEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionMaxEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionMaxFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByProjectIdConnectionMaxFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByProjectIdConnectionMaxIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  FeedsByProjectIdConnectionMaxIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  FeedsByProjectIdConnectionMaxParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByProjectIdConnectionMaxParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByProjectIdConnectionMaxPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByProjectIdConnectionMaxPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByProjectIdConnectionMaxProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionMaxProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionMaxReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionMaxReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionMaxRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByProjectIdConnectionMaxRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByProjectIdConnectionMaxRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionMaxRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionMaxSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionMaxSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionMaxSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionMaxSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionMaxTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByProjectIdConnectionMaxTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByProjectIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionMinActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByProjectIdConnectionMinActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByProjectIdConnectionMinCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByProjectIdConnectionMinCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByProjectIdConnectionMinCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsByProjectIdConnectionMinCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsByProjectIdConnectionMinCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionMinCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionMinCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionMinCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionMinCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByProjectIdConnectionMinCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByProjectIdConnectionMinCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByProjectIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionMinCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByProjectIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionMinEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsByProjectIdConnectionMinEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsByProjectIdConnectionMinEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionMinEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionMinFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByProjectIdConnectionMinFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByProjectIdConnectionMinIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  FeedsByProjectIdConnectionMinIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  FeedsByProjectIdConnectionMinParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByProjectIdConnectionMinParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByProjectIdConnectionMinPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByProjectIdConnectionMinPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByProjectIdConnectionMinProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionMinProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionMinReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionMinReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionMinRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionMinRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionMinRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByProjectIdConnectionMinRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByProjectIdConnectionMinRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionMinRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionMinSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionMinSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionMinSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionMinSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionMinTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByProjectIdConnectionMinTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByProjectIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByProjectIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByProjectIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByProjectIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByProjectIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByProjectIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByProjectIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByProjectIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByProjectIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByProjectIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByProjectIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByProjectIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByProjectIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByProjectIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByProjectIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByProjectIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByProjectIdConnectionStddevSampleEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByProjectIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByProjectIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByProjectIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByProjectIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByProjectIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByProjectIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByProjectIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionSumActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByProjectIdConnectionSumActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByProjectIdConnectionSumCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByProjectIdConnectionSumCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByProjectIdConnectionSumCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsByProjectIdConnectionSumCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsByProjectIdConnectionSumCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionSumCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionSumCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionSumCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionSumCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByProjectIdConnectionSumCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByProjectIdConnectionSumCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByProjectIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionSumCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByProjectIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionSumEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsByProjectIdConnectionSumEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsByProjectIdConnectionSumEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionSumEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionSumFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByProjectIdConnectionSumFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByProjectIdConnectionSumIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  FeedsByProjectIdConnectionSumIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  FeedsByProjectIdConnectionSumParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByProjectIdConnectionSumParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByProjectIdConnectionSumPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByProjectIdConnectionSumPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByProjectIdConnectionSumProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionSumProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionSumReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionSumReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionSumRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionSumRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionSumRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByProjectIdConnectionSumRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByProjectIdConnectionSumRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionSumRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionSumSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionSumSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionSumSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionSumSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionSumTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByProjectIdConnectionSumTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByProjectIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByProjectIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByProjectIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByProjectIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByProjectIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByProjectIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByProjectIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByProjectIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByProjectIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByProjectIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByProjectIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByProjectIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByProjectIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByProjectIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByProjectIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByProjectIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByProjectIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByProjectIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByProjectIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByProjectIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByProjectIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByProjectIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByProjectIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByProjectIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByProjectIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByProjectIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByProjectIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByProjectIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByProjectIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByProjectIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByProjectIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByProjectIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByProjectIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FreshProjectAsc = 'FRESH_PROJECT_ASC',
  FreshProjectDesc = 'FRESH_PROJECT_DESC',
  GeoLocationAsc = 'GEO_LOCATION_ASC',
  GeoLocationDesc = 'GEO_LOCATION_DESC',
  GeoLocationGeographyAsc = 'GEO_LOCATION_GEOGRAPHY_ASC',
  GeoLocationGeographyDesc = 'GEO_LOCATION_GEOGRAPHY_DESC',
  GeoLocationNormalizedAsc = 'GEO_LOCATION_NORMALIZED_ASC',
  GeoLocationNormalizedDesc = 'GEO_LOCATION_NORMALIZED_DESC',
  HomeOwnerEmailAsc = 'HOME_OWNER_EMAIL_ASC',
  HomeOwnerEmailDesc = 'HOME_OWNER_EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  ImportRowsByAccountIdConnectionArrayAggAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionArrayAggCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionArrayAggEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionArrayAggEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionArrayAggErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionArrayAggErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionArrayAggErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionArrayAggErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionArrayAggGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionArrayAggGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionArrayAggIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionArrayAggImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionArrayAggImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionArrayAggImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionArrayAggIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionArrayAggIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionArrayAggIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionArrayAggIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionArrayAggIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionArrayAggIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionArrayAggMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionArrayAggMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionArrayAggProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionArrayAggReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionArrayAggResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionArrayAggResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionArrayAggStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionArrayAggStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionArrayAggStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportRowsByAccountIdConnectionArrayAggStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportRowsByAccountIdConnectionArrayAggUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionArrayAggUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionArrayAggUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionArrayAggUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionAverageAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionAverageAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionAverageContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionAverageContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionAverageCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionAverageCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionAverageEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionAverageEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionAverageErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionAverageErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionAverageErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionAverageErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionAverageGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionAverageGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionAverageIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ID_ASC',
  ImportRowsByAccountIdConnectionAverageIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_ID_DESC',
  ImportRowsByAccountIdConnectionAverageImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionAverageImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionAverageImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionAverageImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionAverageImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionAverageImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionAverageIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionAverageIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionAverageIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionAverageIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionAverageIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionAverageIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionAverageMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionAverageMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionAverageProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionAverageProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionAverageReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionAverageReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionAverageResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionAverageResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionAverageStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionAverageStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionAverageStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ImportRowsByAccountIdConnectionAverageStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ImportRowsByAccountIdConnectionAverageUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionAverageUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionAverageUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionAverageUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_AVERAGE_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionCountAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_COUNT_ASC',
  ImportRowsByAccountIdConnectionCountDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_COUNT_DESC',
  ImportRowsByAccountIdConnectionDistinctCountAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionDistinctCountCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionDistinctCountEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionDistinctCountEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionDistinctCountErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionDistinctCountErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionDistinctCountErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionDistinctCountErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionDistinctCountGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionDistinctCountGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionDistinctCountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionDistinctCountImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionDistinctCountImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionDistinctCountImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionDistinctCountIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionDistinctCountIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionDistinctCountIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionDistinctCountIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionDistinctCountIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionDistinctCountIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionDistinctCountMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionDistinctCountMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionDistinctCountProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionDistinctCountReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionDistinctCountResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionDistinctCountResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionDistinctCountStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionDistinctCountStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionDistinctCountStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportRowsByAccountIdConnectionDistinctCountStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportRowsByAccountIdConnectionDistinctCountUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionDistinctCountUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionDistinctCountUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionDistinctCountUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionMaxAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionMaxAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionMaxContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionMaxContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionMaxCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionMaxCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionMaxEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionMaxEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionMaxErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionMaxErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionMaxErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionMaxErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionMaxGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionMaxGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionMaxIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ID_ASC',
  ImportRowsByAccountIdConnectionMaxIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_ID_DESC',
  ImportRowsByAccountIdConnectionMaxImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionMaxImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionMaxImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionMaxImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionMaxImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionMaxImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionMaxIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMaxIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMaxIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMaxIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMaxIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMaxIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMaxMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionMaxMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionMaxProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionMaxProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionMaxReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionMaxReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionMaxResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionMaxResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionMaxStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionMaxStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionMaxStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_STATUS_ASC',
  ImportRowsByAccountIdConnectionMaxStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_STATUS_DESC',
  ImportRowsByAccountIdConnectionMaxUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionMaxUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionMaxUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionMaxUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MAX_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionMinAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionMinAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionMinContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionMinContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionMinCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionMinCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionMinEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionMinEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionMinErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionMinErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionMinErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionMinErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionMinGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionMinGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionMinIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ID_ASC',
  ImportRowsByAccountIdConnectionMinIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_ID_DESC',
  ImportRowsByAccountIdConnectionMinImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionMinImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionMinImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionMinImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionMinImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionMinImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionMinIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMinIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMinIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMinIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMinIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionMinIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionMinMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionMinMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionMinProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionMinProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionMinReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionMinReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionMinResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionMinResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionMinStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionMinStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionMinStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_STATUS_ASC',
  ImportRowsByAccountIdConnectionMinStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_STATUS_DESC',
  ImportRowsByAccountIdConnectionMinUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionMinUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionMinUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionMinUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_MIN_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevPopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionStddevPopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionStddevSampleAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevSampleCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevSampleEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevSampleEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevSampleErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionStddevSampleErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionStddevSampleErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionStddevSampleErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionStddevSampleGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionStddevSampleGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionStddevSampleIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionStddevSampleImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionStddevSampleImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionStddevSampleImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionStddevSampleIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevSampleIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevSampleIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevSampleIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevSampleIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionStddevSampleIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionStddevSampleMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionStddevSampleMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionStddevSampleProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionStddevSampleReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionStddevSampleResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionStddevSampleResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionStddevSampleStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevSampleStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevSampleStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportRowsByAccountIdConnectionStddevSampleStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportRowsByAccountIdConnectionStddevSampleUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionStddevSampleUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionStddevSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionStddevSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionSumAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionSumAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionSumContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionSumContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionSumCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionSumCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionSumEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionSumEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionSumErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionSumErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionSumErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionSumErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionSumGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionSumGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionSumIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ID_ASC',
  ImportRowsByAccountIdConnectionSumIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_ID_DESC',
  ImportRowsByAccountIdConnectionSumImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionSumImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionSumImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionSumImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionSumImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionSumImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionSumIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionSumIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionSumIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionSumIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionSumIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionSumIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionSumMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionSumMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionSumProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionSumProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionSumReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionSumReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionSumResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionSumResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionSumStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionSumStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionSumStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_STATUS_ASC',
  ImportRowsByAccountIdConnectionSumStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_STATUS_DESC',
  ImportRowsByAccountIdConnectionSumUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionSumUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionSumUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionSumUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_SUM_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionVariancePopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionVariancePopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleAccountIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleAccountIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleContactIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleContactIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleCreatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleCreatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleEndedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleEndedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleErrorMessageAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleErrorMessageDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleErrorStackAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleErrorStackDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleGroupedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleGroupedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleImportDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleImportDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleImportHistoryIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleImportHistoryIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleImportRowNumberAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleImportRowNumberDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleIsAccountCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleIsAccountCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleIsContactCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleIsContactCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleIsProjectCreatedAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleIsProjectCreatedDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleMatchedByValueAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleMatchedByValueDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleReplacedProjectIdAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleReplacedProjectIdDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleResultDataAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleResultDataDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleStartedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleStartedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleStatusAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleStatusDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ImportRowsByAccountIdConnectionVarianceSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsByAccountIdConnectionVarianceSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_ACCOUNT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionArrayAggAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionArrayAggCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionArrayAggEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionArrayAggEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionArrayAggErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionArrayAggErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionArrayAggErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionArrayAggErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionArrayAggGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionArrayAggGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionArrayAggIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionArrayAggImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionArrayAggImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionArrayAggImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionArrayAggIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionArrayAggIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionArrayAggIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionArrayAggIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionArrayAggIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionArrayAggIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionArrayAggMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionArrayAggMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionArrayAggProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionArrayAggReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionArrayAggResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionArrayAggResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionArrayAggStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionArrayAggStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionArrayAggStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ImportRowsByProjectIdConnectionArrayAggStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ImportRowsByProjectIdConnectionArrayAggUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionArrayAggUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionArrayAggUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionArrayAggUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionAverageAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionAverageAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionAverageContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionAverageContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionAverageCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionAverageCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionAverageEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionAverageEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionAverageErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionAverageErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionAverageErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionAverageErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionAverageGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionAverageGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionAverageIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  ImportRowsByProjectIdConnectionAverageIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  ImportRowsByProjectIdConnectionAverageImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionAverageImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionAverageImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionAverageImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionAverageImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionAverageImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionAverageIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionAverageIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionAverageIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionAverageIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionAverageIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionAverageIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionAverageMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionAverageMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionAverageProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionAverageProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionAverageReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionAverageReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionAverageResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionAverageResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionAverageStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionAverageStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionAverageStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ImportRowsByProjectIdConnectionAverageStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ImportRowsByProjectIdConnectionAverageUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionAverageUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionAverageUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionAverageUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionCountAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ImportRowsByProjectIdConnectionCountDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ImportRowsByProjectIdConnectionDistinctCountAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionDistinctCountCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionDistinctCountEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionDistinctCountEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionDistinctCountErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionDistinctCountErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionDistinctCountErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionDistinctCountErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionDistinctCountGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionDistinctCountGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionDistinctCountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionDistinctCountImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionDistinctCountImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionDistinctCountImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionDistinctCountIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionDistinctCountIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionDistinctCountIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionDistinctCountIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionDistinctCountIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionDistinctCountIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionDistinctCountMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionDistinctCountMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionDistinctCountProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionDistinctCountReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionDistinctCountResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionDistinctCountResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionDistinctCountStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionDistinctCountStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionDistinctCountStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ImportRowsByProjectIdConnectionDistinctCountStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ImportRowsByProjectIdConnectionDistinctCountUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionDistinctCountUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionDistinctCountUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionDistinctCountUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionMaxAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionMaxAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionMaxContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionMaxContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionMaxCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionMaxCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionMaxEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionMaxEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionMaxErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionMaxErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionMaxErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionMaxErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionMaxGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionMaxGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionMaxIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  ImportRowsByProjectIdConnectionMaxIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  ImportRowsByProjectIdConnectionMaxImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionMaxImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionMaxImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionMaxImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionMaxImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionMaxImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionMaxIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMaxIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMaxIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMaxIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMaxIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMaxIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMaxMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionMaxMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionMaxProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionMaxProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionMaxReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionMaxReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionMaxResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionMaxResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionMaxStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionMaxStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionMaxStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  ImportRowsByProjectIdConnectionMaxStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  ImportRowsByProjectIdConnectionMaxUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionMaxUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionMaxUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionMaxUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionMinAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionMinAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionMinContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionMinContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionMinCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionMinCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionMinEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionMinEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionMinErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionMinErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionMinErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionMinErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionMinGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionMinGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionMinIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  ImportRowsByProjectIdConnectionMinIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  ImportRowsByProjectIdConnectionMinImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionMinImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionMinImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionMinImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionMinImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionMinImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionMinIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMinIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMinIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMinIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMinIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionMinIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionMinMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionMinMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionMinProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionMinProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionMinReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionMinReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionMinResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionMinResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionMinStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionMinStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionMinStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  ImportRowsByProjectIdConnectionMinStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  ImportRowsByProjectIdConnectionMinUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionMinUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionMinUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionMinUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevPopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionStddevPopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionStddevSampleAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevSampleCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevSampleEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevSampleEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevSampleErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionStddevSampleErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionStddevSampleErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionStddevSampleErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionStddevSampleGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionStddevSampleGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionStddevSampleIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionStddevSampleImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionStddevSampleImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionStddevSampleImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionStddevSampleIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevSampleIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevSampleIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevSampleIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevSampleIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionStddevSampleIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionStddevSampleMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionStddevSampleMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionStddevSampleProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionStddevSampleReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionStddevSampleResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionStddevSampleResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionStddevSampleStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevSampleStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevSampleStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ImportRowsByProjectIdConnectionStddevSampleStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ImportRowsByProjectIdConnectionStddevSampleUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionStddevSampleUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionStddevSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionStddevSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionSumAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionSumAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionSumContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionSumContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionSumCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionSumCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionSumEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionSumEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionSumErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionSumErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionSumErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionSumErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionSumGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionSumGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionSumIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  ImportRowsByProjectIdConnectionSumIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  ImportRowsByProjectIdConnectionSumImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionSumImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionSumImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionSumImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionSumImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionSumImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionSumIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionSumIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionSumIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionSumIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionSumIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionSumIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionSumMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionSumMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionSumProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionSumProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionSumReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionSumReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionSumResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionSumResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionSumStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionSumStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionSumStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  ImportRowsByProjectIdConnectionSumStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  ImportRowsByProjectIdConnectionSumUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionSumUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionSumUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionSumUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionVariancePopulationUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionVariancePopulationUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_PROPERTIES_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleAccountIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleAccountIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleContactIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleContactIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleEndedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleEndedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleErrorMessageAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleErrorMessageDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleErrorStackAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleErrorStackDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ERROR_STACK_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleGroupedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleGroupedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GROUPED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleImportDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleImportDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_DATA_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleImportHistoryIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleImportHistoryIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_HISTORY_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleImportRowNumberAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleImportRowNumberDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMPORT_ROW_NUMBER_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleIsAccountCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleIsAccountCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACCOUNT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleIsContactCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleIsContactCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_CONTACT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleIsProjectCreatedAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleIsProjectCreatedDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_PROJECT_CREATED_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleMatchedByValueAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleMatchedByValueDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MATCHED_BY_VALUE_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleReplacedProjectIdAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleReplacedProjectIdDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REPLACED_PROJECT_ID_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleResultDataAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleResultDataDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_RESULT_DATA_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleStartedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleStartedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleStatusAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleStatusDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ImportRowsByProjectIdConnectionVarianceSampleUpdatedPropertiesAsc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_ASC',
  ImportRowsByProjectIdConnectionVarianceSampleUpdatedPropertiesDesc = 'IMPORT_ROWS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_PROPERTIES_DESC',
  InvoicesByProjectIdConnectionArrayAggAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AMOUNT_ASC',
  InvoicesByProjectIdConnectionArrayAggAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AMOUNT_DESC',
  InvoicesByProjectIdConnectionArrayAggAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionArrayAggAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionArrayAggCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionArrayAggCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionArrayAggCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionArrayAggCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionArrayAggCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionArrayAggCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionArrayAggDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionArrayAggDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionArrayAggDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionArrayAggDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionArrayAggIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  InvoicesByProjectIdConnectionArrayAggIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  InvoicesByProjectIdConnectionArrayAggIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionArrayAggIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionArrayAggIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_BILL_ASC',
  InvoicesByProjectIdConnectionArrayAggIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_BILL_DESC',
  InvoicesByProjectIdConnectionArrayAggProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionArrayAggProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionArrayAggStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  InvoicesByProjectIdConnectionArrayAggStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  InvoicesByProjectIdConnectionArrayAggTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  InvoicesByProjectIdConnectionArrayAggTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  InvoicesByProjectIdConnectionArrayAggUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  InvoicesByProjectIdConnectionArrayAggUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  InvoicesByProjectIdConnectionArrayAggUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionArrayAggUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionAverageAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_AMOUNT_ASC',
  InvoicesByProjectIdConnectionAverageAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_AMOUNT_DESC',
  InvoicesByProjectIdConnectionAverageAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionAverageAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionAverageCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionAverageCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionAverageCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionAverageCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionAverageCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionAverageCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionAverageDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionAverageDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionAverageDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionAverageDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionAverageIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  InvoicesByProjectIdConnectionAverageIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  InvoicesByProjectIdConnectionAverageIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionAverageIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionAverageIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_BILL_ASC',
  InvoicesByProjectIdConnectionAverageIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_BILL_DESC',
  InvoicesByProjectIdConnectionAverageProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionAverageProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionAverageStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  InvoicesByProjectIdConnectionAverageStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  InvoicesByProjectIdConnectionAverageTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  InvoicesByProjectIdConnectionAverageTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  InvoicesByProjectIdConnectionAverageUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  InvoicesByProjectIdConnectionAverageUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  InvoicesByProjectIdConnectionAverageUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionAverageUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionCountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  InvoicesByProjectIdConnectionCountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  InvoicesByProjectIdConnectionDistinctCountAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AMOUNT_ASC',
  InvoicesByProjectIdConnectionDistinctCountAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AMOUNT_DESC',
  InvoicesByProjectIdConnectionDistinctCountAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionDistinctCountAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionDistinctCountCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionDistinctCountCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionDistinctCountCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionDistinctCountCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionDistinctCountCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionDistinctCountCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionDistinctCountDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionDistinctCountDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionDistinctCountDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionDistinctCountDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionDistinctCountIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  InvoicesByProjectIdConnectionDistinctCountIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  InvoicesByProjectIdConnectionDistinctCountIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionDistinctCountIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionDistinctCountIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_BILL_ASC',
  InvoicesByProjectIdConnectionDistinctCountIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_BILL_DESC',
  InvoicesByProjectIdConnectionDistinctCountProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionDistinctCountProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionDistinctCountStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  InvoicesByProjectIdConnectionDistinctCountStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  InvoicesByProjectIdConnectionDistinctCountTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  InvoicesByProjectIdConnectionDistinctCountTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  InvoicesByProjectIdConnectionDistinctCountUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  InvoicesByProjectIdConnectionDistinctCountUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  InvoicesByProjectIdConnectionDistinctCountUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionDistinctCountUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionMaxAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_AMOUNT_ASC',
  InvoicesByProjectIdConnectionMaxAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_AMOUNT_DESC',
  InvoicesByProjectIdConnectionMaxAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionMaxAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionMaxCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionMaxCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionMaxCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionMaxCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionMaxCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionMaxCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionMaxDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionMaxDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionMaxDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionMaxDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionMaxIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  InvoicesByProjectIdConnectionMaxIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  InvoicesByProjectIdConnectionMaxIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionMaxIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionMaxIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_IS_BILL_ASC',
  InvoicesByProjectIdConnectionMaxIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_IS_BILL_DESC',
  InvoicesByProjectIdConnectionMaxProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionMaxProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionMaxStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  InvoicesByProjectIdConnectionMaxStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  InvoicesByProjectIdConnectionMaxTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  InvoicesByProjectIdConnectionMaxTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  InvoicesByProjectIdConnectionMaxUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  InvoicesByProjectIdConnectionMaxUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  InvoicesByProjectIdConnectionMaxUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionMaxUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionMinAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_AMOUNT_ASC',
  InvoicesByProjectIdConnectionMinAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_AMOUNT_DESC',
  InvoicesByProjectIdConnectionMinAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionMinAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionMinCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionMinCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionMinCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionMinCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionMinCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionMinCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionMinDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionMinDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionMinDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionMinDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionMinIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  InvoicesByProjectIdConnectionMinIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  InvoicesByProjectIdConnectionMinIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionMinIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionMinIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_IS_BILL_ASC',
  InvoicesByProjectIdConnectionMinIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_IS_BILL_DESC',
  InvoicesByProjectIdConnectionMinProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionMinProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionMinStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  InvoicesByProjectIdConnectionMinStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  InvoicesByProjectIdConnectionMinTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  InvoicesByProjectIdConnectionMinTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  InvoicesByProjectIdConnectionMinUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  InvoicesByProjectIdConnectionMinUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  InvoicesByProjectIdConnectionMinUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionMinUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionStddevPopulationAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AMOUNT_ASC',
  InvoicesByProjectIdConnectionStddevPopulationAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AMOUNT_DESC',
  InvoicesByProjectIdConnectionStddevPopulationAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionStddevPopulationAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionStddevPopulationCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionStddevPopulationCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionStddevPopulationCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionStddevPopulationCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionStddevPopulationCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionStddevPopulationCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionStddevPopulationDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionStddevPopulationDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionStddevPopulationDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionStddevPopulationDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionStddevPopulationIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  InvoicesByProjectIdConnectionStddevPopulationIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  InvoicesByProjectIdConnectionStddevPopulationIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionStddevPopulationIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionStddevPopulationIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_BILL_ASC',
  InvoicesByProjectIdConnectionStddevPopulationIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_BILL_DESC',
  InvoicesByProjectIdConnectionStddevPopulationProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionStddevPopulationProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionStddevPopulationStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  InvoicesByProjectIdConnectionStddevPopulationStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  InvoicesByProjectIdConnectionStddevPopulationTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  InvoicesByProjectIdConnectionStddevPopulationTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  InvoicesByProjectIdConnectionStddevPopulationUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  InvoicesByProjectIdConnectionStddevPopulationUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  InvoicesByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionStddevSampleAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AMOUNT_ASC',
  InvoicesByProjectIdConnectionStddevSampleAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AMOUNT_DESC',
  InvoicesByProjectIdConnectionStddevSampleAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionStddevSampleAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionStddevSampleCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionStddevSampleCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionStddevSampleCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionStddevSampleCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionStddevSampleCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionStddevSampleCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionStddevSampleDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionStddevSampleDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionStddevSampleDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionStddevSampleDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionStddevSampleIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  InvoicesByProjectIdConnectionStddevSampleIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  InvoicesByProjectIdConnectionStddevSampleIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionStddevSampleIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionStddevSampleIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_BILL_ASC',
  InvoicesByProjectIdConnectionStddevSampleIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_BILL_DESC',
  InvoicesByProjectIdConnectionStddevSampleProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionStddevSampleProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionStddevSampleStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  InvoicesByProjectIdConnectionStddevSampleStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  InvoicesByProjectIdConnectionStddevSampleTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  InvoicesByProjectIdConnectionStddevSampleTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  InvoicesByProjectIdConnectionStddevSampleUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  InvoicesByProjectIdConnectionStddevSampleUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  InvoicesByProjectIdConnectionStddevSampleUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionStddevSampleUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionSumAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_AMOUNT_ASC',
  InvoicesByProjectIdConnectionSumAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_AMOUNT_DESC',
  InvoicesByProjectIdConnectionSumAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionSumAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionSumCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionSumCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionSumCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionSumCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionSumCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionSumCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionSumDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionSumDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionSumDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionSumDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionSumIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  InvoicesByProjectIdConnectionSumIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  InvoicesByProjectIdConnectionSumIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionSumIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionSumIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_IS_BILL_ASC',
  InvoicesByProjectIdConnectionSumIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_IS_BILL_DESC',
  InvoicesByProjectIdConnectionSumProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionSumProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionSumStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  InvoicesByProjectIdConnectionSumStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  InvoicesByProjectIdConnectionSumTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  InvoicesByProjectIdConnectionSumTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  InvoicesByProjectIdConnectionSumUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  InvoicesByProjectIdConnectionSumUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  InvoicesByProjectIdConnectionSumUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionSumUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionVariancePopulationAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AMOUNT_ASC',
  InvoicesByProjectIdConnectionVariancePopulationAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AMOUNT_DESC',
  InvoicesByProjectIdConnectionVariancePopulationAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionVariancePopulationAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionVariancePopulationCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionVariancePopulationCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionVariancePopulationCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionVariancePopulationCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionVariancePopulationCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionVariancePopulationCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionVariancePopulationDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionVariancePopulationDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionVariancePopulationDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionVariancePopulationDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionVariancePopulationIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  InvoicesByProjectIdConnectionVariancePopulationIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  InvoicesByProjectIdConnectionVariancePopulationIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionVariancePopulationIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionVariancePopulationIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_BILL_ASC',
  InvoicesByProjectIdConnectionVariancePopulationIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_BILL_DESC',
  InvoicesByProjectIdConnectionVariancePopulationProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionVariancePopulationProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionVariancePopulationStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  InvoicesByProjectIdConnectionVariancePopulationStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  InvoicesByProjectIdConnectionVariancePopulationTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  InvoicesByProjectIdConnectionVariancePopulationTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  InvoicesByProjectIdConnectionVariancePopulationUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  InvoicesByProjectIdConnectionVariancePopulationUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  InvoicesByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  InvoicesByProjectIdConnectionVarianceSampleAmountAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AMOUNT_ASC',
  InvoicesByProjectIdConnectionVarianceSampleAmountDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AMOUNT_DESC',
  InvoicesByProjectIdConnectionVarianceSampleAmountPaidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AMOUNT_PAID_ASC',
  InvoicesByProjectIdConnectionVarianceSampleAmountPaidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AMOUNT_PAID_DESC',
  InvoicesByProjectIdConnectionVarianceSampleCompanyIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  InvoicesByProjectIdConnectionVarianceSampleCompanyIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  InvoicesByProjectIdConnectionVarianceSampleCreatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  InvoicesByProjectIdConnectionVarianceSampleCreatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  InvoicesByProjectIdConnectionVarianceSampleCreatedByAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  InvoicesByProjectIdConnectionVarianceSampleCreatedByDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  InvoicesByProjectIdConnectionVarianceSampleDescriptionAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  InvoicesByProjectIdConnectionVarianceSampleDescriptionDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  InvoicesByProjectIdConnectionVarianceSampleDueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  InvoicesByProjectIdConnectionVarianceSampleDueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  InvoicesByProjectIdConnectionVarianceSampleIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  InvoicesByProjectIdConnectionVarianceSampleIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  InvoicesByProjectIdConnectionVarianceSampleIssueDateAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ISSUE_DATE_ASC',
  InvoicesByProjectIdConnectionVarianceSampleIssueDateDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ISSUE_DATE_DESC',
  InvoicesByProjectIdConnectionVarianceSampleIsBillAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_BILL_ASC',
  InvoicesByProjectIdConnectionVarianceSampleIsBillDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_BILL_DESC',
  InvoicesByProjectIdConnectionVarianceSampleProjectIdAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  InvoicesByProjectIdConnectionVarianceSampleProjectIdDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  InvoicesByProjectIdConnectionVarianceSampleStatusAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  InvoicesByProjectIdConnectionVarianceSampleStatusDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  InvoicesByProjectIdConnectionVarianceSampleTitleAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  InvoicesByProjectIdConnectionVarianceSampleTitleDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  InvoicesByProjectIdConnectionVarianceSampleUidAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  InvoicesByProjectIdConnectionVarianceSampleUidDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  InvoicesByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  InvoicesByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'INVOICES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  JurisdictionIdAsc = 'JURISDICTION_ID_ASC',
  JurisdictionIdDesc = 'JURISDICTION_ID_DESC',
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  LastActivityDesc = 'LAST_ACTIVITY_DESC',
  LastInboundCallDispositionAsc = 'LAST_INBOUND_CALL_DISPOSITION_ASC',
  LastInboundCallDispositionDesc = 'LAST_INBOUND_CALL_DISPOSITION_DESC',
  LastInboundCallTimeAsc = 'LAST_INBOUND_CALL_TIME_ASC',
  LastInboundCallTimeDesc = 'LAST_INBOUND_CALL_TIME_DESC',
  LastInboundEmailTimeAsc = 'LAST_INBOUND_EMAIL_TIME_ASC',
  LastInboundEmailTimeDesc = 'LAST_INBOUND_EMAIL_TIME_DESC',
  LastInboundSmsTimeAsc = 'LAST_INBOUND_SMS_TIME_ASC',
  LastInboundSmsTimeDesc = 'LAST_INBOUND_SMS_TIME_DESC',
  LastOutboundCallDispositionAsc = 'LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  LastOutboundCallDispositionDesc = 'LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  LastOutboundCallTimeAsc = 'LAST_OUTBOUND_CALL_TIME_ASC',
  LastOutboundCallTimeDesc = 'LAST_OUTBOUND_CALL_TIME_DESC',
  LastOutboundEmailTimeAsc = 'LAST_OUTBOUND_EMAIL_TIME_ASC',
  LastOutboundEmailTimeDesc = 'LAST_OUTBOUND_EMAIL_TIME_DESC',
  LastOutboundSmsTimeAsc = 'LAST_OUTBOUND_SMS_TIME_ASC',
  LastOutboundSmsTimeDesc = 'LAST_OUTBOUND_SMS_TIME_DESC',
  LineItemsByProjectIdConnectionArrayAggCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionArrayAggCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionArrayAggCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionArrayAggCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionArrayAggCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionArrayAggCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionArrayAggDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionArrayAggDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionArrayAggIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  LineItemsByProjectIdConnectionArrayAggIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  LineItemsByProjectIdConnectionArrayAggProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionArrayAggProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionArrayAggQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_QUANTITY_ASC',
  LineItemsByProjectIdConnectionArrayAggQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_QUANTITY_DESC',
  LineItemsByProjectIdConnectionArrayAggRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  LineItemsByProjectIdConnectionArrayAggRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  LineItemsByProjectIdConnectionArrayAggTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionArrayAggTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionArrayAggTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionArrayAggTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionArrayAggUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionArrayAggUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionArrayAggUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionArrayAggUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionArrayAggUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionArrayAggUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionArrayAggUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionArrayAggUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionAverageCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionAverageCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionAverageCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionAverageCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionAverageCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionAverageCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionAverageDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionAverageDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionAverageIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  LineItemsByProjectIdConnectionAverageIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  LineItemsByProjectIdConnectionAverageProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionAverageProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionAverageQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_QUANTITY_ASC',
  LineItemsByProjectIdConnectionAverageQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_QUANTITY_DESC',
  LineItemsByProjectIdConnectionAverageRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  LineItemsByProjectIdConnectionAverageRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  LineItemsByProjectIdConnectionAverageTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionAverageTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionAverageTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionAverageTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionAverageUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionAverageUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionAverageUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionAverageUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionAverageUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionAverageUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionAverageUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionAverageUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionCountAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  LineItemsByProjectIdConnectionCountDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  LineItemsByProjectIdConnectionDistinctCountCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionDistinctCountCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionDistinctCountCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionDistinctCountCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionDistinctCountCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionDistinctCountCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionDistinctCountDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionDistinctCountDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionDistinctCountIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  LineItemsByProjectIdConnectionDistinctCountIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  LineItemsByProjectIdConnectionDistinctCountProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionDistinctCountProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionDistinctCountQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_QUANTITY_ASC',
  LineItemsByProjectIdConnectionDistinctCountQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_QUANTITY_DESC',
  LineItemsByProjectIdConnectionDistinctCountRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  LineItemsByProjectIdConnectionDistinctCountRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  LineItemsByProjectIdConnectionDistinctCountTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionDistinctCountTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionDistinctCountTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionDistinctCountTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionDistinctCountUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionDistinctCountUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionDistinctCountUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionDistinctCountUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionDistinctCountUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionDistinctCountUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionDistinctCountUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionDistinctCountUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionMaxCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionMaxCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionMaxCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionMaxCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionMaxCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionMaxCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionMaxDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionMaxDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionMaxIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  LineItemsByProjectIdConnectionMaxIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  LineItemsByProjectIdConnectionMaxProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionMaxProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionMaxQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_QUANTITY_ASC',
  LineItemsByProjectIdConnectionMaxQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_QUANTITY_DESC',
  LineItemsByProjectIdConnectionMaxRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_REVENUE_ASC',
  LineItemsByProjectIdConnectionMaxRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_REVENUE_DESC',
  LineItemsByProjectIdConnectionMaxTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionMaxTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionMaxTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionMaxTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionMaxUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionMaxUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionMaxUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionMaxUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionMaxUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionMaxUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionMaxUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionMaxUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionMinCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionMinCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionMinCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionMinCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionMinCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionMinCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionMinDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionMinDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionMinIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  LineItemsByProjectIdConnectionMinIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  LineItemsByProjectIdConnectionMinProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionMinProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionMinQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_QUANTITY_ASC',
  LineItemsByProjectIdConnectionMinQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_QUANTITY_DESC',
  LineItemsByProjectIdConnectionMinRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_REVENUE_ASC',
  LineItemsByProjectIdConnectionMinRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_REVENUE_DESC',
  LineItemsByProjectIdConnectionMinTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionMinTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionMinTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionMinTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionMinUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionMinUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionMinUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionMinUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionMinUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionMinUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionMinUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionMinUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionStddevPopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionStddevPopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionStddevPopulationCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionStddevPopulationCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionStddevPopulationDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionStddevPopulationDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionStddevPopulationIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  LineItemsByProjectIdConnectionStddevPopulationIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  LineItemsByProjectIdConnectionStddevPopulationProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionStddevPopulationProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionStddevPopulationQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_QUANTITY_ASC',
  LineItemsByProjectIdConnectionStddevPopulationQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_QUANTITY_DESC',
  LineItemsByProjectIdConnectionStddevPopulationRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  LineItemsByProjectIdConnectionStddevPopulationRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  LineItemsByProjectIdConnectionStddevPopulationTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionStddevPopulationTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionStddevPopulationTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionStddevPopulationTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionStddevPopulationUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionStddevPopulationUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionStddevPopulationUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionStddevPopulationUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionStddevPopulationUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionStddevPopulationUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionStddevSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionStddevSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionStddevSampleCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionStddevSampleCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionStddevSampleCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionStddevSampleCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionStddevSampleDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionStddevSampleDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionStddevSampleIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  LineItemsByProjectIdConnectionStddevSampleIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  LineItemsByProjectIdConnectionStddevSampleProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionStddevSampleProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionStddevSampleQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_QUANTITY_ASC',
  LineItemsByProjectIdConnectionStddevSampleQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_QUANTITY_DESC',
  LineItemsByProjectIdConnectionStddevSampleRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  LineItemsByProjectIdConnectionStddevSampleRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  LineItemsByProjectIdConnectionStddevSampleTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionStddevSampleTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionStddevSampleTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionStddevSampleTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionStddevSampleUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionStddevSampleUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionStddevSampleUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionStddevSampleUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionStddevSampleUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionStddevSampleUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionStddevSampleUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionStddevSampleUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionSumCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionSumCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionSumCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionSumCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionSumCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionSumCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionSumDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionSumDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionSumIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  LineItemsByProjectIdConnectionSumIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  LineItemsByProjectIdConnectionSumProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionSumProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionSumQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_QUANTITY_ASC',
  LineItemsByProjectIdConnectionSumQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_QUANTITY_DESC',
  LineItemsByProjectIdConnectionSumRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_REVENUE_ASC',
  LineItemsByProjectIdConnectionSumRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_REVENUE_DESC',
  LineItemsByProjectIdConnectionSumTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionSumTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionSumTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionSumTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionSumUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionSumUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionSumUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionSumUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionSumUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionSumUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionSumUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionSumUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionVariancePopulationCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionVariancePopulationCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionVariancePopulationCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionVariancePopulationCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionVariancePopulationDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionVariancePopulationDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionVariancePopulationIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  LineItemsByProjectIdConnectionVariancePopulationIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  LineItemsByProjectIdConnectionVariancePopulationProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionVariancePopulationProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionVariancePopulationQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_QUANTITY_ASC',
  LineItemsByProjectIdConnectionVariancePopulationQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_QUANTITY_DESC',
  LineItemsByProjectIdConnectionVariancePopulationRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  LineItemsByProjectIdConnectionVariancePopulationRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  LineItemsByProjectIdConnectionVariancePopulationTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionVariancePopulationTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionVariancePopulationTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionVariancePopulationTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionVariancePopulationUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionVariancePopulationUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionVariancePopulationUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionVariancePopulationUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionVariancePopulationUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionVariancePopulationUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_BY_DESC',
  LineItemsByProjectIdConnectionVarianceSampleCatalogItemIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_ASC',
  LineItemsByProjectIdConnectionVarianceSampleCatalogItemIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CATALOG_ITEM_ID_DESC',
  LineItemsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LineItemsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LineItemsByProjectIdConnectionVarianceSampleCreatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  LineItemsByProjectIdConnectionVarianceSampleCreatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  LineItemsByProjectIdConnectionVarianceSampleDescriptionAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  LineItemsByProjectIdConnectionVarianceSampleDescriptionDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  LineItemsByProjectIdConnectionVarianceSampleIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  LineItemsByProjectIdConnectionVarianceSampleIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  LineItemsByProjectIdConnectionVarianceSampleProjectIdAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  LineItemsByProjectIdConnectionVarianceSampleProjectIdDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  LineItemsByProjectIdConnectionVarianceSampleQuantityAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_QUANTITY_ASC',
  LineItemsByProjectIdConnectionVarianceSampleQuantityDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_QUANTITY_DESC',
  LineItemsByProjectIdConnectionVarianceSampleRevenueAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  LineItemsByProjectIdConnectionVarianceSampleRevenueDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  LineItemsByProjectIdConnectionVarianceSampleTotalCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  LineItemsByProjectIdConnectionVarianceSampleTotalCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  LineItemsByProjectIdConnectionVarianceSampleTotalPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  LineItemsByProjectIdConnectionVarianceSampleTotalPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  LineItemsByProjectIdConnectionVarianceSampleUnitCostAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_ASC',
  LineItemsByProjectIdConnectionVarianceSampleUnitCostDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UNIT_COST_DESC',
  LineItemsByProjectIdConnectionVarianceSampleUnitPriceAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_ASC',
  LineItemsByProjectIdConnectionVarianceSampleUnitPriceDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UNIT_PRICE_DESC',
  LineItemsByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LineItemsByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LineItemsByProjectIdConnectionVarianceSampleUpdatedByAsc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_ASC',
  LineItemsByProjectIdConnectionVarianceSampleUpdatedByDesc = 'LINE_ITEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_BY_DESC',
  Natural = 'NATURAL',
  OverdueByAsc = 'OVERDUE_BY_ASC',
  OverdueByDesc = 'OVERDUE_BY_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  ParentProjectIdAsc = 'PARENT_PROJECT_ID_ASC',
  ParentProjectIdDesc = 'PARENT_PROJECT_ID_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryEmailAsc = 'PRIMARY_EMAIL_ASC',
  PrimaryEmailDesc = 'PRIMARY_EMAIL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrimaryPhoneAsc = 'PRIMARY_PHONE_ASC',
  PrimaryPhoneDesc = 'PRIMARY_PHONE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksByProjectIdConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksByProjectIdConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksByProjectIdConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionCountAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  PrivilegedTasksByProjectIdConnectionCountDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksByProjectIdConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksByProjectIdConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksByProjectIdConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksByProjectIdConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksByProjectIdConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksByProjectIdConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksByProjectIdConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksByProjectIdConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksByProjectIdConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  PrivilegeTeamAsc = 'PRIVILEGE_TEAM_ASC',
  PrivilegeTeamDesc = 'PRIVILEGE_TEAM_DESC',
  ProgressAsc = 'PROGRESS_ASC',
  ProgressDesc = 'PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByParentProjectIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByParentProjectIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionArrayAggIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByParentProjectIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByParentProjectIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByParentProjectIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByParentProjectIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByParentProjectIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByParentProjectIdConnectionArrayAggUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByParentProjectIdConnectionArrayAggUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByParentProjectIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionAverageAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionAverageAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionAverageAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByParentProjectIdConnectionAverageAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByParentProjectIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByParentProjectIdConnectionAverageCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByParentProjectIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionAverageDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionAverageDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionAverageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionAveragePhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByParentProjectIdConnectionAveragePhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByParentProjectIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionAverageProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionAverageProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionAverageRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionAverageRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionAverageSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionAverageStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionAverageStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionAverageStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByParentProjectIdConnectionAverageStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByParentProjectIdConnectionAverageStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByParentProjectIdConnectionAverageStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByParentProjectIdConnectionAverageStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByParentProjectIdConnectionAverageStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByParentProjectIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionAverageTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByParentProjectIdConnectionAverageTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByParentProjectIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionAverageTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByParentProjectIdConnectionAverageTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByParentProjectIdConnectionAverageTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByParentProjectIdConnectionAverageTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByParentProjectIdConnectionAverageUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByParentProjectIdConnectionAverageUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByParentProjectIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectsByParentProjectIdConnectionCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMaxAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionMaxAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionMaxAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByParentProjectIdConnectionMaxAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByParentProjectIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByParentProjectIdConnectionMaxCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByParentProjectIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionMaxDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionMaxDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionMaxIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByParentProjectIdConnectionMaxPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByParentProjectIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionMaxProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionMaxProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMaxRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionMaxRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionMaxSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionMaxStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionMaxStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionMaxStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByParentProjectIdConnectionMaxStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByParentProjectIdConnectionMaxStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMaxStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMaxStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByParentProjectIdConnectionMaxStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByParentProjectIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionMaxTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByParentProjectIdConnectionMaxTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByParentProjectIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionMaxTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByParentProjectIdConnectionMaxTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByParentProjectIdConnectionMaxTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMaxTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMaxUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByParentProjectIdConnectionMaxUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByParentProjectIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMinAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionMinAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionMinAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByParentProjectIdConnectionMinAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByParentProjectIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionMinCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByParentProjectIdConnectionMinCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByParentProjectIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionMinConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionMinConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionMinCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionMinCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMinDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionMinDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionMinDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionMinDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionMinDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionMinDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionMinIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByParentProjectIdConnectionMinIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByParentProjectIdConnectionMinImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionMinImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionMinIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionMinIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionMinLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionMinLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionMinOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionMinOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionMinPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByParentProjectIdConnectionMinPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByParentProjectIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionMinProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionMinProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionMinProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionMinProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMinRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionMinRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionMinSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionMinSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionMinStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionMinStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionMinStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByParentProjectIdConnectionMinStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByParentProjectIdConnectionMinStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByParentProjectIdConnectionMinStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByParentProjectIdConnectionMinStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByParentProjectIdConnectionMinStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByParentProjectIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionMinTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByParentProjectIdConnectionMinTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByParentProjectIdConnectionMinTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionMinTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionMinTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByParentProjectIdConnectionMinTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByParentProjectIdConnectionMinTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByParentProjectIdConnectionMinTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByParentProjectIdConnectionMinUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByParentProjectIdConnectionMinUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByParentProjectIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionMinZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionMinZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionSumAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionSumAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionSumAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByParentProjectIdConnectionSumAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByParentProjectIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionSumCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByParentProjectIdConnectionSumCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByParentProjectIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionSumConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionSumConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionSumCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionSumCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionSumDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionSumDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionSumDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionSumDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionSumDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionSumDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionSumIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByParentProjectIdConnectionSumIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByParentProjectIdConnectionSumImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionSumImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionSumIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionSumIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionSumLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionSumLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionSumOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionSumOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionSumPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByParentProjectIdConnectionSumPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByParentProjectIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionSumProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionSumProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionSumProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionSumProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionSumRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionSumRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionSumSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionSumSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionSumStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionSumStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionSumStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByParentProjectIdConnectionSumStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByParentProjectIdConnectionSumStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByParentProjectIdConnectionSumStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByParentProjectIdConnectionSumStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByParentProjectIdConnectionSumStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByParentProjectIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionSumTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByParentProjectIdConnectionSumTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByParentProjectIdConnectionSumTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionSumTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionSumTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByParentProjectIdConnectionSumTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByParentProjectIdConnectionSumTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByParentProjectIdConnectionSumTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByParentProjectIdConnectionSumUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByParentProjectIdConnectionSumUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByParentProjectIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionSumZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionSumZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByParentProjectIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByParentProjectIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByParentProjectIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_PARENT_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectAddressesByProjectIdConnectionArrayAggAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionArrayAggAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionArrayAggProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionArrayAggProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionAverageAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionAverageAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionAverageProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionAverageProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionCountAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectAddressesByProjectIdConnectionCountDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectAddressesByProjectIdConnectionDistinctCountAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionDistinctCountAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionDistinctCountProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionDistinctCountProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionMaxAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionMaxAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionMaxProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionMaxProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionMinAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionMinAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionMinProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionMinProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionStddevPopulationAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionStddevPopulationAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionStddevSampleAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionStddevSampleAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionStddevSampleProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionStddevSampleProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionSumAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionSumAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionSumProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionSumProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionVariancePopulationAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionVariancePopulationAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectAddressesByProjectIdConnectionVarianceSampleAddressAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectAddressesByProjectIdConnectionVarianceSampleAddressDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectAddressesByProjectIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectAddressesByProjectIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_ADDRESSES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionArrayAggContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionArrayAggContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionArrayAggCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionArrayAggCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionArrayAggPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ProjectContactsIndirectConnectionArrayAggPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ProjectContactsIndirectConnectionArrayAggProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionArrayAggProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionAverageContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionAverageContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionAverageCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionAverageCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionAveragePositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_POSITION_ASC',
  ProjectContactsIndirectConnectionAveragePositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_POSITION_DESC',
  ProjectContactsIndirectConnectionAverageProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionAverageProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionCountAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_COUNT_ASC',
  ProjectContactsIndirectConnectionCountDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_COUNT_DESC',
  ProjectContactsIndirectConnectionDistinctCountContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionDistinctCountContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionDistinctCountCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionDistinctCountCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionDistinctCountPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ProjectContactsIndirectConnectionDistinctCountPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ProjectContactsIndirectConnectionDistinctCountProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionDistinctCountProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionMaxContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionMaxContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionMaxCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionMaxCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionMaxPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_POSITION_ASC',
  ProjectContactsIndirectConnectionMaxPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_POSITION_DESC',
  ProjectContactsIndirectConnectionMaxProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionMaxProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionMinContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionMinContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionMinCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionMinCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionMinPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_POSITION_ASC',
  ProjectContactsIndirectConnectionMinPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_POSITION_DESC',
  ProjectContactsIndirectConnectionMinProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionMinProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionStddevPopulationContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionStddevPopulationContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionStddevPopulationCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionStddevPopulationCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionStddevPopulationPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ProjectContactsIndirectConnectionStddevPopulationPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ProjectContactsIndirectConnectionStddevPopulationProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionStddevPopulationProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionStddevSampleContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionStddevSampleContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionStddevSampleCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionStddevSampleCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionStddevSamplePositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ProjectContactsIndirectConnectionStddevSamplePositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ProjectContactsIndirectConnectionStddevSampleProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionStddevSampleProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionSumContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionSumContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionSumCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionSumCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionSumPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_POSITION_ASC',
  ProjectContactsIndirectConnectionSumPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_POSITION_DESC',
  ProjectContactsIndirectConnectionSumProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionSumProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionVariancePopulationContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionVariancePopulationContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionVariancePopulationCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionVariancePopulationCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionVariancePopulationPositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ProjectContactsIndirectConnectionVariancePopulationPositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ProjectContactsIndirectConnectionVariancePopulationProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionVariancePopulationProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectContactsIndirectConnectionVarianceSampleContactIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ProjectContactsIndirectConnectionVarianceSampleContactIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ProjectContactsIndirectConnectionVarianceSampleCreatedAtAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectContactsIndirectConnectionVarianceSampleCreatedAtDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectContactsIndirectConnectionVarianceSamplePositionAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ProjectContactsIndirectConnectionVarianceSamplePositionDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ProjectContactsIndirectConnectionVarianceSampleProjectIdAsc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectContactsIndirectConnectionVarianceSampleProjectIdDesc = 'PROJECT_CONTACTS_INDIRECT_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectFilesConnectionArrayAggActionIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  ProjectFilesConnectionArrayAggActionIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  ProjectFilesConnectionArrayAggActionItemIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionArrayAggActionItemIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionArrayAggAnnotationsAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  ProjectFilesConnectionArrayAggAnnotationsDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  ProjectFilesConnectionArrayAggCommentIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  ProjectFilesConnectionArrayAggCommentIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  ProjectFilesConnectionArrayAggCompanyIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectFilesConnectionArrayAggCompanyIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectFilesConnectionArrayAggCreatedAtAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectFilesConnectionArrayAggCreatedAtDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectFilesConnectionArrayAggCreatedByAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectFilesConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionArrayAggCreatedByDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectFilesConnectionArrayAggCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionArrayAggCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionArrayAggDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionArrayAggDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionArrayAggFormIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  ProjectFilesConnectionArrayAggFormIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  ProjectFilesConnectionArrayAggIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectFilesConnectionArrayAggIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectFilesConnectionArrayAggIsArchivedAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  ProjectFilesConnectionArrayAggIsArchivedDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  ProjectFilesConnectionArrayAggIsFormAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  ProjectFilesConnectionArrayAggIsFormDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  ProjectFilesConnectionArrayAggMetaDataAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  ProjectFilesConnectionArrayAggMetaDataDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  ProjectFilesConnectionArrayAggModifiedByAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  ProjectFilesConnectionArrayAggModifiedByDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  ProjectFilesConnectionArrayAggNameAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_NAME_ASC',
  ProjectFilesConnectionArrayAggNameDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_NAME_DESC',
  ProjectFilesConnectionArrayAggOriginAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  ProjectFilesConnectionArrayAggOriginDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  ProjectFilesConnectionArrayAggPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionArrayAggPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionArrayAggPrivilegeAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  ProjectFilesConnectionArrayAggPrivilegeDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  ProjectFilesConnectionArrayAggPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionArrayAggPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionArrayAggPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionArrayAggPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionArrayAggProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionArrayAggProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionArrayAggProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionArrayAggProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionArrayAggProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionArrayAggProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionArrayAggProjectIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectFilesConnectionArrayAggProjectIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectFilesConnectionArrayAggSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionArrayAggSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionArrayAggSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionArrayAggSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionArrayAggStageIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectFilesConnectionArrayAggStageIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectFilesConnectionArrayAggTypeAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectFilesConnectionArrayAggTypeDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectFilesConnectionArrayAggUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectFilesConnectionArrayAggUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectFilesConnectionArrayAggUuidAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_UUID_ASC',
  ProjectFilesConnectionArrayAggUuidDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_UUID_DESC',
  ProjectFilesConnectionArrayAggValueFileIdAsc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionArrayAggValueFileIdDesc = 'PROJECT_FILES_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionAverageActionIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_ACTION_ID_ASC',
  ProjectFilesConnectionAverageActionIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_ACTION_ID_DESC',
  ProjectFilesConnectionAverageActionItemIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionAverageActionItemIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionAverageAnnotationsAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  ProjectFilesConnectionAverageAnnotationsDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  ProjectFilesConnectionAverageCommentIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  ProjectFilesConnectionAverageCommentIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  ProjectFilesConnectionAverageCompanyIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectFilesConnectionAverageCompanyIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectFilesConnectionAverageCreatedAtAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectFilesConnectionAverageCreatedAtDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectFilesConnectionAverageCreatedByAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectFilesConnectionAverageCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionAverageCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionAverageCreatedByDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectFilesConnectionAverageCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionAverageCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionAverageDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionAverageDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionAverageFormIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_FORM_ID_ASC',
  ProjectFilesConnectionAverageFormIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_FORM_ID_DESC',
  ProjectFilesConnectionAverageIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_ID_ASC',
  ProjectFilesConnectionAverageIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_ID_DESC',
  ProjectFilesConnectionAverageIsArchivedAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  ProjectFilesConnectionAverageIsArchivedDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  ProjectFilesConnectionAverageIsFormAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_IS_FORM_ASC',
  ProjectFilesConnectionAverageIsFormDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_IS_FORM_DESC',
  ProjectFilesConnectionAverageMetaDataAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_META_DATA_ASC',
  ProjectFilesConnectionAverageMetaDataDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_META_DATA_DESC',
  ProjectFilesConnectionAverageModifiedByAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  ProjectFilesConnectionAverageModifiedByDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  ProjectFilesConnectionAverageNameAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_NAME_ASC',
  ProjectFilesConnectionAverageNameDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_NAME_DESC',
  ProjectFilesConnectionAverageOriginAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_ORIGIN_ASC',
  ProjectFilesConnectionAverageOriginDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_ORIGIN_DESC',
  ProjectFilesConnectionAveragePrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionAveragePrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionAveragePrivilegeAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  ProjectFilesConnectionAveragePrivilegeDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  ProjectFilesConnectionAveragePrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionAveragePrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionAveragePrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionAveragePrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionAverageProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionAverageProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionAverageProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionAverageProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionAverageProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionAverageProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionAverageProjectIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectFilesConnectionAverageProjectIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectFilesConnectionAverageSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionAverageSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionAverageSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionAverageSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionAverageStageIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectFilesConnectionAverageStageIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectFilesConnectionAverageTypeAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectFilesConnectionAverageTypeDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectFilesConnectionAverageUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectFilesConnectionAverageUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectFilesConnectionAverageUuidAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_UUID_ASC',
  ProjectFilesConnectionAverageUuidDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_UUID_DESC',
  ProjectFilesConnectionAverageValueFileIdAsc = 'PROJECT_FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionAverageValueFileIdDesc = 'PROJECT_FILES_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionCountAsc = 'PROJECT_FILES_CONNECTION_COUNT_ASC',
  ProjectFilesConnectionCountDesc = 'PROJECT_FILES_CONNECTION_COUNT_DESC',
  ProjectFilesConnectionDistinctCountActionIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  ProjectFilesConnectionDistinctCountActionIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  ProjectFilesConnectionDistinctCountActionItemIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionDistinctCountActionItemIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionDistinctCountAnnotationsAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  ProjectFilesConnectionDistinctCountAnnotationsDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  ProjectFilesConnectionDistinctCountCommentIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  ProjectFilesConnectionDistinctCountCommentIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  ProjectFilesConnectionDistinctCountCompanyIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectFilesConnectionDistinctCountCompanyIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectFilesConnectionDistinctCountCreatedAtAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectFilesConnectionDistinctCountCreatedAtDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectFilesConnectionDistinctCountCreatedByAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectFilesConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionDistinctCountCreatedByDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectFilesConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionDistinctCountDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionDistinctCountDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionDistinctCountFormIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  ProjectFilesConnectionDistinctCountFormIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  ProjectFilesConnectionDistinctCountIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectFilesConnectionDistinctCountIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectFilesConnectionDistinctCountIsArchivedAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  ProjectFilesConnectionDistinctCountIsArchivedDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  ProjectFilesConnectionDistinctCountIsFormAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  ProjectFilesConnectionDistinctCountIsFormDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  ProjectFilesConnectionDistinctCountMetaDataAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  ProjectFilesConnectionDistinctCountMetaDataDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  ProjectFilesConnectionDistinctCountModifiedByAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  ProjectFilesConnectionDistinctCountModifiedByDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  ProjectFilesConnectionDistinctCountNameAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ProjectFilesConnectionDistinctCountNameDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ProjectFilesConnectionDistinctCountOriginAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  ProjectFilesConnectionDistinctCountOriginDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  ProjectFilesConnectionDistinctCountPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionDistinctCountPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionDistinctCountPrivilegeAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  ProjectFilesConnectionDistinctCountPrivilegeDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  ProjectFilesConnectionDistinctCountPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionDistinctCountPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionDistinctCountPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionDistinctCountPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionDistinctCountProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionDistinctCountProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionDistinctCountProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionDistinctCountProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionDistinctCountProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionDistinctCountProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionDistinctCountProjectIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectFilesConnectionDistinctCountProjectIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectFilesConnectionDistinctCountSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionDistinctCountSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionDistinctCountSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionDistinctCountSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionDistinctCountStageIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectFilesConnectionDistinctCountStageIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectFilesConnectionDistinctCountTypeAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectFilesConnectionDistinctCountTypeDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectFilesConnectionDistinctCountUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectFilesConnectionDistinctCountUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectFilesConnectionDistinctCountUuidAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  ProjectFilesConnectionDistinctCountUuidDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  ProjectFilesConnectionDistinctCountValueFileIdAsc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionDistinctCountValueFileIdDesc = 'PROJECT_FILES_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionMaxActionIdAsc = 'PROJECT_FILES_CONNECTION_MAX_ACTION_ID_ASC',
  ProjectFilesConnectionMaxActionIdDesc = 'PROJECT_FILES_CONNECTION_MAX_ACTION_ID_DESC',
  ProjectFilesConnectionMaxActionItemIdAsc = 'PROJECT_FILES_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionMaxActionItemIdDesc = 'PROJECT_FILES_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionMaxAnnotationsAsc = 'PROJECT_FILES_CONNECTION_MAX_ANNOTATIONS_ASC',
  ProjectFilesConnectionMaxAnnotationsDesc = 'PROJECT_FILES_CONNECTION_MAX_ANNOTATIONS_DESC',
  ProjectFilesConnectionMaxCommentIdAsc = 'PROJECT_FILES_CONNECTION_MAX_COMMENT_ID_ASC',
  ProjectFilesConnectionMaxCommentIdDesc = 'PROJECT_FILES_CONNECTION_MAX_COMMENT_ID_DESC',
  ProjectFilesConnectionMaxCompanyIdAsc = 'PROJECT_FILES_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectFilesConnectionMaxCompanyIdDesc = 'PROJECT_FILES_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectFilesConnectionMaxCreatedAtAsc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectFilesConnectionMaxCreatedAtDesc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectFilesConnectionMaxCreatedByAsc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectFilesConnectionMaxCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionMaxCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionMaxCreatedByDesc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectFilesConnectionMaxCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionMaxCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionMaxDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionMaxDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionMaxFormIdAsc = 'PROJECT_FILES_CONNECTION_MAX_FORM_ID_ASC',
  ProjectFilesConnectionMaxFormIdDesc = 'PROJECT_FILES_CONNECTION_MAX_FORM_ID_DESC',
  ProjectFilesConnectionMaxIdAsc = 'PROJECT_FILES_CONNECTION_MAX_ID_ASC',
  ProjectFilesConnectionMaxIdDesc = 'PROJECT_FILES_CONNECTION_MAX_ID_DESC',
  ProjectFilesConnectionMaxIsArchivedAsc = 'PROJECT_FILES_CONNECTION_MAX_IS_ARCHIVED_ASC',
  ProjectFilesConnectionMaxIsArchivedDesc = 'PROJECT_FILES_CONNECTION_MAX_IS_ARCHIVED_DESC',
  ProjectFilesConnectionMaxIsFormAsc = 'PROJECT_FILES_CONNECTION_MAX_IS_FORM_ASC',
  ProjectFilesConnectionMaxIsFormDesc = 'PROJECT_FILES_CONNECTION_MAX_IS_FORM_DESC',
  ProjectFilesConnectionMaxMetaDataAsc = 'PROJECT_FILES_CONNECTION_MAX_META_DATA_ASC',
  ProjectFilesConnectionMaxMetaDataDesc = 'PROJECT_FILES_CONNECTION_MAX_META_DATA_DESC',
  ProjectFilesConnectionMaxModifiedByAsc = 'PROJECT_FILES_CONNECTION_MAX_MODIFIED_BY_ASC',
  ProjectFilesConnectionMaxModifiedByDesc = 'PROJECT_FILES_CONNECTION_MAX_MODIFIED_BY_DESC',
  ProjectFilesConnectionMaxNameAsc = 'PROJECT_FILES_CONNECTION_MAX_NAME_ASC',
  ProjectFilesConnectionMaxNameDesc = 'PROJECT_FILES_CONNECTION_MAX_NAME_DESC',
  ProjectFilesConnectionMaxOriginAsc = 'PROJECT_FILES_CONNECTION_MAX_ORIGIN_ASC',
  ProjectFilesConnectionMaxOriginDesc = 'PROJECT_FILES_CONNECTION_MAX_ORIGIN_DESC',
  ProjectFilesConnectionMaxPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionMaxPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionMaxPrivilegeAsc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_ASC',
  ProjectFilesConnectionMaxPrivilegeDesc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_DESC',
  ProjectFilesConnectionMaxPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionMaxPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionMaxPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionMaxPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionMaxProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionMaxProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionMaxProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionMaxProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionMaxProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionMaxProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionMaxProjectIdAsc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectFilesConnectionMaxProjectIdDesc = 'PROJECT_FILES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectFilesConnectionMaxSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionMaxSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionMaxSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionMaxSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionMaxStageIdAsc = 'PROJECT_FILES_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectFilesConnectionMaxStageIdDesc = 'PROJECT_FILES_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectFilesConnectionMaxTypeAsc = 'PROJECT_FILES_CONNECTION_MAX_TYPE_ASC',
  ProjectFilesConnectionMaxTypeDesc = 'PROJECT_FILES_CONNECTION_MAX_TYPE_DESC',
  ProjectFilesConnectionMaxUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectFilesConnectionMaxUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectFilesConnectionMaxUuidAsc = 'PROJECT_FILES_CONNECTION_MAX_UUID_ASC',
  ProjectFilesConnectionMaxUuidDesc = 'PROJECT_FILES_CONNECTION_MAX_UUID_DESC',
  ProjectFilesConnectionMaxValueFileIdAsc = 'PROJECT_FILES_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionMaxValueFileIdDesc = 'PROJECT_FILES_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionMinActionIdAsc = 'PROJECT_FILES_CONNECTION_MIN_ACTION_ID_ASC',
  ProjectFilesConnectionMinActionIdDesc = 'PROJECT_FILES_CONNECTION_MIN_ACTION_ID_DESC',
  ProjectFilesConnectionMinActionItemIdAsc = 'PROJECT_FILES_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionMinActionItemIdDesc = 'PROJECT_FILES_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionMinAnnotationsAsc = 'PROJECT_FILES_CONNECTION_MIN_ANNOTATIONS_ASC',
  ProjectFilesConnectionMinAnnotationsDesc = 'PROJECT_FILES_CONNECTION_MIN_ANNOTATIONS_DESC',
  ProjectFilesConnectionMinCommentIdAsc = 'PROJECT_FILES_CONNECTION_MIN_COMMENT_ID_ASC',
  ProjectFilesConnectionMinCommentIdDesc = 'PROJECT_FILES_CONNECTION_MIN_COMMENT_ID_DESC',
  ProjectFilesConnectionMinCompanyIdAsc = 'PROJECT_FILES_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectFilesConnectionMinCompanyIdDesc = 'PROJECT_FILES_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectFilesConnectionMinCreatedAtAsc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectFilesConnectionMinCreatedAtDesc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectFilesConnectionMinCreatedByAsc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectFilesConnectionMinCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionMinCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionMinCreatedByDesc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectFilesConnectionMinCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionMinCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionMinDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionMinDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionMinFormIdAsc = 'PROJECT_FILES_CONNECTION_MIN_FORM_ID_ASC',
  ProjectFilesConnectionMinFormIdDesc = 'PROJECT_FILES_CONNECTION_MIN_FORM_ID_DESC',
  ProjectFilesConnectionMinIdAsc = 'PROJECT_FILES_CONNECTION_MIN_ID_ASC',
  ProjectFilesConnectionMinIdDesc = 'PROJECT_FILES_CONNECTION_MIN_ID_DESC',
  ProjectFilesConnectionMinIsArchivedAsc = 'PROJECT_FILES_CONNECTION_MIN_IS_ARCHIVED_ASC',
  ProjectFilesConnectionMinIsArchivedDesc = 'PROJECT_FILES_CONNECTION_MIN_IS_ARCHIVED_DESC',
  ProjectFilesConnectionMinIsFormAsc = 'PROJECT_FILES_CONNECTION_MIN_IS_FORM_ASC',
  ProjectFilesConnectionMinIsFormDesc = 'PROJECT_FILES_CONNECTION_MIN_IS_FORM_DESC',
  ProjectFilesConnectionMinMetaDataAsc = 'PROJECT_FILES_CONNECTION_MIN_META_DATA_ASC',
  ProjectFilesConnectionMinMetaDataDesc = 'PROJECT_FILES_CONNECTION_MIN_META_DATA_DESC',
  ProjectFilesConnectionMinModifiedByAsc = 'PROJECT_FILES_CONNECTION_MIN_MODIFIED_BY_ASC',
  ProjectFilesConnectionMinModifiedByDesc = 'PROJECT_FILES_CONNECTION_MIN_MODIFIED_BY_DESC',
  ProjectFilesConnectionMinNameAsc = 'PROJECT_FILES_CONNECTION_MIN_NAME_ASC',
  ProjectFilesConnectionMinNameDesc = 'PROJECT_FILES_CONNECTION_MIN_NAME_DESC',
  ProjectFilesConnectionMinOriginAsc = 'PROJECT_FILES_CONNECTION_MIN_ORIGIN_ASC',
  ProjectFilesConnectionMinOriginDesc = 'PROJECT_FILES_CONNECTION_MIN_ORIGIN_DESC',
  ProjectFilesConnectionMinPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionMinPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionMinPrivilegeAsc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_ASC',
  ProjectFilesConnectionMinPrivilegeDesc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_DESC',
  ProjectFilesConnectionMinPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionMinPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionMinPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionMinPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionMinProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionMinProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionMinProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionMinProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionMinProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionMinProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionMinProjectIdAsc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectFilesConnectionMinProjectIdDesc = 'PROJECT_FILES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectFilesConnectionMinSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionMinSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionMinSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionMinSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionMinStageIdAsc = 'PROJECT_FILES_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectFilesConnectionMinStageIdDesc = 'PROJECT_FILES_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectFilesConnectionMinTypeAsc = 'PROJECT_FILES_CONNECTION_MIN_TYPE_ASC',
  ProjectFilesConnectionMinTypeDesc = 'PROJECT_FILES_CONNECTION_MIN_TYPE_DESC',
  ProjectFilesConnectionMinUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectFilesConnectionMinUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectFilesConnectionMinUuidAsc = 'PROJECT_FILES_CONNECTION_MIN_UUID_ASC',
  ProjectFilesConnectionMinUuidDesc = 'PROJECT_FILES_CONNECTION_MIN_UUID_DESC',
  ProjectFilesConnectionMinValueFileIdAsc = 'PROJECT_FILES_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionMinValueFileIdDesc = 'PROJECT_FILES_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionStddevPopulationActionIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  ProjectFilesConnectionStddevPopulationActionIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  ProjectFilesConnectionStddevPopulationActionItemIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionStddevPopulationActionItemIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionStddevPopulationAnnotationsAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  ProjectFilesConnectionStddevPopulationAnnotationsDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  ProjectFilesConnectionStddevPopulationCommentIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  ProjectFilesConnectionStddevPopulationCommentIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  ProjectFilesConnectionStddevPopulationCompanyIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectFilesConnectionStddevPopulationCompanyIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectFilesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectFilesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectFilesConnectionStddevPopulationCreatedByAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectFilesConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionStddevPopulationCreatedByDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectFilesConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionStddevPopulationDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionStddevPopulationDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionStddevPopulationFormIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  ProjectFilesConnectionStddevPopulationFormIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  ProjectFilesConnectionStddevPopulationIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectFilesConnectionStddevPopulationIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectFilesConnectionStddevPopulationIsArchivedAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  ProjectFilesConnectionStddevPopulationIsArchivedDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  ProjectFilesConnectionStddevPopulationIsFormAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  ProjectFilesConnectionStddevPopulationIsFormDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  ProjectFilesConnectionStddevPopulationMetaDataAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  ProjectFilesConnectionStddevPopulationMetaDataDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  ProjectFilesConnectionStddevPopulationModifiedByAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  ProjectFilesConnectionStddevPopulationModifiedByDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  ProjectFilesConnectionStddevPopulationNameAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ProjectFilesConnectionStddevPopulationNameDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ProjectFilesConnectionStddevPopulationOriginAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  ProjectFilesConnectionStddevPopulationOriginDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  ProjectFilesConnectionStddevPopulationPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionStddevPopulationPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionStddevPopulationPrivilegeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  ProjectFilesConnectionStddevPopulationPrivilegeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  ProjectFilesConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionStddevPopulationProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionStddevPopulationProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionStddevPopulationProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionStddevPopulationProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionStddevPopulationProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionStddevPopulationProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionStddevPopulationProjectIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectFilesConnectionStddevPopulationProjectIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectFilesConnectionStddevPopulationSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionStddevPopulationSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionStddevPopulationSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionStddevPopulationSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionStddevPopulationStageIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectFilesConnectionStddevPopulationStageIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectFilesConnectionStddevPopulationTypeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectFilesConnectionStddevPopulationTypeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectFilesConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectFilesConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectFilesConnectionStddevPopulationUuidAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  ProjectFilesConnectionStddevPopulationUuidDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  ProjectFilesConnectionStddevPopulationValueFileIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionStddevPopulationValueFileIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionStddevSampleActionIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  ProjectFilesConnectionStddevSampleActionIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  ProjectFilesConnectionStddevSampleActionItemIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionStddevSampleActionItemIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionStddevSampleAnnotationsAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  ProjectFilesConnectionStddevSampleAnnotationsDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  ProjectFilesConnectionStddevSampleCommentIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  ProjectFilesConnectionStddevSampleCommentIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  ProjectFilesConnectionStddevSampleCompanyIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectFilesConnectionStddevSampleCompanyIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectFilesConnectionStddevSampleCreatedAtAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectFilesConnectionStddevSampleCreatedAtDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectFilesConnectionStddevSampleCreatedByAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectFilesConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionStddevSampleCreatedByDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectFilesConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionStddevSampleDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionStddevSampleDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionStddevSampleFormIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  ProjectFilesConnectionStddevSampleFormIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  ProjectFilesConnectionStddevSampleIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectFilesConnectionStddevSampleIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectFilesConnectionStddevSampleIsArchivedAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  ProjectFilesConnectionStddevSampleIsArchivedDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  ProjectFilesConnectionStddevSampleIsFormAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  ProjectFilesConnectionStddevSampleIsFormDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  ProjectFilesConnectionStddevSampleMetaDataAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  ProjectFilesConnectionStddevSampleMetaDataDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  ProjectFilesConnectionStddevSampleModifiedByAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  ProjectFilesConnectionStddevSampleModifiedByDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  ProjectFilesConnectionStddevSampleNameAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ProjectFilesConnectionStddevSampleNameDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ProjectFilesConnectionStddevSampleOriginAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  ProjectFilesConnectionStddevSampleOriginDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  ProjectFilesConnectionStddevSamplePrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionStddevSamplePrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionStddevSamplePrivilegeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  ProjectFilesConnectionStddevSamplePrivilegeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  ProjectFilesConnectionStddevSamplePrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionStddevSamplePrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionStddevSamplePrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionStddevSamplePrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionStddevSampleProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionStddevSampleProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionStddevSampleProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionStddevSampleProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionStddevSampleProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionStddevSampleProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionStddevSampleProjectIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectFilesConnectionStddevSampleProjectIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectFilesConnectionStddevSampleSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionStddevSampleSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionStddevSampleSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionStddevSampleSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionStddevSampleStageIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectFilesConnectionStddevSampleStageIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectFilesConnectionStddevSampleTypeAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectFilesConnectionStddevSampleTypeDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectFilesConnectionStddevSampleUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectFilesConnectionStddevSampleUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectFilesConnectionStddevSampleUuidAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  ProjectFilesConnectionStddevSampleUuidDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  ProjectFilesConnectionStddevSampleValueFileIdAsc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionStddevSampleValueFileIdDesc = 'PROJECT_FILES_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionSumActionIdAsc = 'PROJECT_FILES_CONNECTION_SUM_ACTION_ID_ASC',
  ProjectFilesConnectionSumActionIdDesc = 'PROJECT_FILES_CONNECTION_SUM_ACTION_ID_DESC',
  ProjectFilesConnectionSumActionItemIdAsc = 'PROJECT_FILES_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionSumActionItemIdDesc = 'PROJECT_FILES_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionSumAnnotationsAsc = 'PROJECT_FILES_CONNECTION_SUM_ANNOTATIONS_ASC',
  ProjectFilesConnectionSumAnnotationsDesc = 'PROJECT_FILES_CONNECTION_SUM_ANNOTATIONS_DESC',
  ProjectFilesConnectionSumCommentIdAsc = 'PROJECT_FILES_CONNECTION_SUM_COMMENT_ID_ASC',
  ProjectFilesConnectionSumCommentIdDesc = 'PROJECT_FILES_CONNECTION_SUM_COMMENT_ID_DESC',
  ProjectFilesConnectionSumCompanyIdAsc = 'PROJECT_FILES_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectFilesConnectionSumCompanyIdDesc = 'PROJECT_FILES_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectFilesConnectionSumCreatedAtAsc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectFilesConnectionSumCreatedAtDesc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectFilesConnectionSumCreatedByAsc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectFilesConnectionSumCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionSumCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionSumCreatedByDesc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectFilesConnectionSumCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionSumCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionSumDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionSumDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionSumFormIdAsc = 'PROJECT_FILES_CONNECTION_SUM_FORM_ID_ASC',
  ProjectFilesConnectionSumFormIdDesc = 'PROJECT_FILES_CONNECTION_SUM_FORM_ID_DESC',
  ProjectFilesConnectionSumIdAsc = 'PROJECT_FILES_CONNECTION_SUM_ID_ASC',
  ProjectFilesConnectionSumIdDesc = 'PROJECT_FILES_CONNECTION_SUM_ID_DESC',
  ProjectFilesConnectionSumIsArchivedAsc = 'PROJECT_FILES_CONNECTION_SUM_IS_ARCHIVED_ASC',
  ProjectFilesConnectionSumIsArchivedDesc = 'PROJECT_FILES_CONNECTION_SUM_IS_ARCHIVED_DESC',
  ProjectFilesConnectionSumIsFormAsc = 'PROJECT_FILES_CONNECTION_SUM_IS_FORM_ASC',
  ProjectFilesConnectionSumIsFormDesc = 'PROJECT_FILES_CONNECTION_SUM_IS_FORM_DESC',
  ProjectFilesConnectionSumMetaDataAsc = 'PROJECT_FILES_CONNECTION_SUM_META_DATA_ASC',
  ProjectFilesConnectionSumMetaDataDesc = 'PROJECT_FILES_CONNECTION_SUM_META_DATA_DESC',
  ProjectFilesConnectionSumModifiedByAsc = 'PROJECT_FILES_CONNECTION_SUM_MODIFIED_BY_ASC',
  ProjectFilesConnectionSumModifiedByDesc = 'PROJECT_FILES_CONNECTION_SUM_MODIFIED_BY_DESC',
  ProjectFilesConnectionSumNameAsc = 'PROJECT_FILES_CONNECTION_SUM_NAME_ASC',
  ProjectFilesConnectionSumNameDesc = 'PROJECT_FILES_CONNECTION_SUM_NAME_DESC',
  ProjectFilesConnectionSumOriginAsc = 'PROJECT_FILES_CONNECTION_SUM_ORIGIN_ASC',
  ProjectFilesConnectionSumOriginDesc = 'PROJECT_FILES_CONNECTION_SUM_ORIGIN_DESC',
  ProjectFilesConnectionSumPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionSumPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionSumPrivilegeAsc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_ASC',
  ProjectFilesConnectionSumPrivilegeDesc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_DESC',
  ProjectFilesConnectionSumPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionSumPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionSumPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionSumPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionSumProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionSumProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionSumProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionSumProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionSumProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionSumProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionSumProjectIdAsc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectFilesConnectionSumProjectIdDesc = 'PROJECT_FILES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectFilesConnectionSumSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionSumSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionSumSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionSumSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionSumStageIdAsc = 'PROJECT_FILES_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectFilesConnectionSumStageIdDesc = 'PROJECT_FILES_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectFilesConnectionSumTypeAsc = 'PROJECT_FILES_CONNECTION_SUM_TYPE_ASC',
  ProjectFilesConnectionSumTypeDesc = 'PROJECT_FILES_CONNECTION_SUM_TYPE_DESC',
  ProjectFilesConnectionSumUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectFilesConnectionSumUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectFilesConnectionSumUuidAsc = 'PROJECT_FILES_CONNECTION_SUM_UUID_ASC',
  ProjectFilesConnectionSumUuidDesc = 'PROJECT_FILES_CONNECTION_SUM_UUID_DESC',
  ProjectFilesConnectionSumValueFileIdAsc = 'PROJECT_FILES_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionSumValueFileIdDesc = 'PROJECT_FILES_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionVariancePopulationActionIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  ProjectFilesConnectionVariancePopulationActionIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  ProjectFilesConnectionVariancePopulationActionItemIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionVariancePopulationActionItemIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionVariancePopulationAnnotationsAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  ProjectFilesConnectionVariancePopulationAnnotationsDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  ProjectFilesConnectionVariancePopulationCommentIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  ProjectFilesConnectionVariancePopulationCommentIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  ProjectFilesConnectionVariancePopulationCompanyIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectFilesConnectionVariancePopulationCompanyIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectFilesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectFilesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectFilesConnectionVariancePopulationCreatedByAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectFilesConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionVariancePopulationCreatedByDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectFilesConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionVariancePopulationDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionVariancePopulationDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionVariancePopulationFormIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  ProjectFilesConnectionVariancePopulationFormIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  ProjectFilesConnectionVariancePopulationIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectFilesConnectionVariancePopulationIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectFilesConnectionVariancePopulationIsArchivedAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  ProjectFilesConnectionVariancePopulationIsArchivedDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  ProjectFilesConnectionVariancePopulationIsFormAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  ProjectFilesConnectionVariancePopulationIsFormDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  ProjectFilesConnectionVariancePopulationMetaDataAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  ProjectFilesConnectionVariancePopulationMetaDataDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  ProjectFilesConnectionVariancePopulationModifiedByAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  ProjectFilesConnectionVariancePopulationModifiedByDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  ProjectFilesConnectionVariancePopulationNameAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ProjectFilesConnectionVariancePopulationNameDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ProjectFilesConnectionVariancePopulationOriginAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  ProjectFilesConnectionVariancePopulationOriginDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  ProjectFilesConnectionVariancePopulationPrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionVariancePopulationPrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionVariancePopulationPrivilegeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  ProjectFilesConnectionVariancePopulationPrivilegeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  ProjectFilesConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionVariancePopulationProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionVariancePopulationProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionVariancePopulationProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionVariancePopulationProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionVariancePopulationProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionVariancePopulationProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionVariancePopulationProjectIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectFilesConnectionVariancePopulationProjectIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectFilesConnectionVariancePopulationSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionVariancePopulationSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionVariancePopulationSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionVariancePopulationSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionVariancePopulationStageIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectFilesConnectionVariancePopulationStageIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectFilesConnectionVariancePopulationTypeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectFilesConnectionVariancePopulationTypeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectFilesConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectFilesConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectFilesConnectionVariancePopulationUuidAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  ProjectFilesConnectionVariancePopulationUuidDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  ProjectFilesConnectionVariancePopulationValueFileIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionVariancePopulationValueFileIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionVarianceSampleActionIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  ProjectFilesConnectionVarianceSampleActionIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  ProjectFilesConnectionVarianceSampleActionItemIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  ProjectFilesConnectionVarianceSampleActionItemIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  ProjectFilesConnectionVarianceSampleAnnotationsAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  ProjectFilesConnectionVarianceSampleAnnotationsDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  ProjectFilesConnectionVarianceSampleCommentIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  ProjectFilesConnectionVarianceSampleCommentIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  ProjectFilesConnectionVarianceSampleCompanyIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectFilesConnectionVarianceSampleCompanyIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectFilesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectFilesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectFilesConnectionVarianceSampleCreatedByAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectFilesConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectFilesConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectFilesConnectionVarianceSampleCreatedByDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectFilesConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectFilesConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectFilesConnectionVarianceSampleDownloadUrlAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  ProjectFilesConnectionVarianceSampleDownloadUrlDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  ProjectFilesConnectionVarianceSampleFormIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  ProjectFilesConnectionVarianceSampleFormIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  ProjectFilesConnectionVarianceSampleIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectFilesConnectionVarianceSampleIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectFilesConnectionVarianceSampleIsArchivedAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  ProjectFilesConnectionVarianceSampleIsArchivedDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  ProjectFilesConnectionVarianceSampleIsFormAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  ProjectFilesConnectionVarianceSampleIsFormDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  ProjectFilesConnectionVarianceSampleMetaDataAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  ProjectFilesConnectionVarianceSampleMetaDataDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  ProjectFilesConnectionVarianceSampleModifiedByAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  ProjectFilesConnectionVarianceSampleModifiedByDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  ProjectFilesConnectionVarianceSampleNameAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ProjectFilesConnectionVarianceSampleNameDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ProjectFilesConnectionVarianceSampleOriginAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  ProjectFilesConnectionVarianceSampleOriginDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  ProjectFilesConnectionVarianceSamplePrivilegeAllAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectFilesConnectionVarianceSamplePrivilegeAllDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectFilesConnectionVarianceSamplePrivilegeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  ProjectFilesConnectionVarianceSamplePrivilegeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  ProjectFilesConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectFilesConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectFilesConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectFilesConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectFilesConnectionVarianceSampleProcessingStatusAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  ProjectFilesConnectionVarianceSampleProcessingStatusDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  ProjectFilesConnectionVarianceSampleProjectColumnValueFileIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionVarianceSampleProjectColumnValueFileIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  ProjectFilesConnectionVarianceSampleProjectColumnValueIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  ProjectFilesConnectionVarianceSampleProjectColumnValueIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  ProjectFilesConnectionVarianceSampleProjectIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectFilesConnectionVarianceSampleProjectIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectFilesConnectionVarianceSampleSharedWithPortalAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  ProjectFilesConnectionVarianceSampleSharedWithPortalDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  ProjectFilesConnectionVarianceSampleSizeInBytesAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  ProjectFilesConnectionVarianceSampleSizeInBytesDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  ProjectFilesConnectionVarianceSampleStageIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectFilesConnectionVarianceSampleStageIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectFilesConnectionVarianceSampleTypeAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectFilesConnectionVarianceSampleTypeDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectFilesConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectFilesConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectFilesConnectionVarianceSampleUuidAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  ProjectFilesConnectionVarianceSampleUuidDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  ProjectFilesConnectionVarianceSampleValueFileIdAsc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  ProjectFilesConnectionVarianceSampleValueFileIdDesc = 'PROJECT_FILES_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  ProjectManagerIdAsc = 'PROJECT_MANAGER_ID_ASC',
  ProjectManagerIdDesc = 'PROJECT_MANAGER_ID_DESC',
  ProjectMembersConnectionArrayAggCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectMembersConnectionArrayAggCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectMembersConnectionArrayAggCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectMembersConnectionArrayAggCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectMembersConnectionArrayAggIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectMembersConnectionArrayAggIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectMembersConnectionArrayAggMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_ID_ASC',
  ProjectMembersConnectionArrayAggMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_ID_DESC',
  ProjectMembersConnectionArrayAggMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_TYPE_ASC',
  ProjectMembersConnectionArrayAggMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_TYPE_DESC',
  ProjectMembersConnectionArrayAggProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectMembersConnectionArrayAggProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectMembersConnectionArrayAggTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectMembersConnectionArrayAggTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectMembersConnectionAverageCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectMembersConnectionAverageCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectMembersConnectionAverageCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectMembersConnectionAverageCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectMembersConnectionAverageIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_ID_ASC',
  ProjectMembersConnectionAverageIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_ID_DESC',
  ProjectMembersConnectionAverageMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_ID_ASC',
  ProjectMembersConnectionAverageMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_ID_DESC',
  ProjectMembersConnectionAverageMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionAverageMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionAverageProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectMembersConnectionAverageProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectMembersConnectionAverageTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectMembersConnectionAverageTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectMembersConnectionCountAsc = 'PROJECT_MEMBERS_CONNECTION_COUNT_ASC',
  ProjectMembersConnectionCountDesc = 'PROJECT_MEMBERS_CONNECTION_COUNT_DESC',
  ProjectMembersConnectionDistinctCountCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectMembersConnectionDistinctCountCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectMembersConnectionDistinctCountCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectMembersConnectionDistinctCountCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectMembersConnectionDistinctCountIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectMembersConnectionDistinctCountIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectMembersConnectionDistinctCountMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_ID_ASC',
  ProjectMembersConnectionDistinctCountMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_ID_DESC',
  ProjectMembersConnectionDistinctCountMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_ASC',
  ProjectMembersConnectionDistinctCountMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_DESC',
  ProjectMembersConnectionDistinctCountProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectMembersConnectionDistinctCountProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectMembersConnectionDistinctCountTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectMembersConnectionDistinctCountTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectMembersConnectionMaxCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectMembersConnectionMaxCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectMembersConnectionMaxCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectMembersConnectionMaxCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectMembersConnectionMaxIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_ID_ASC',
  ProjectMembersConnectionMaxIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_ID_DESC',
  ProjectMembersConnectionMaxMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_ID_ASC',
  ProjectMembersConnectionMaxMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_ID_DESC',
  ProjectMembersConnectionMaxMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_TYPE_ASC',
  ProjectMembersConnectionMaxMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_TYPE_DESC',
  ProjectMembersConnectionMaxProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectMembersConnectionMaxProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectMembersConnectionMaxTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectMembersConnectionMaxTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectMembersConnectionMinCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectMembersConnectionMinCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectMembersConnectionMinCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectMembersConnectionMinCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectMembersConnectionMinIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_ID_ASC',
  ProjectMembersConnectionMinIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_ID_DESC',
  ProjectMembersConnectionMinMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_ID_ASC',
  ProjectMembersConnectionMinMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_ID_DESC',
  ProjectMembersConnectionMinMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_TYPE_ASC',
  ProjectMembersConnectionMinMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_TYPE_DESC',
  ProjectMembersConnectionMinProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectMembersConnectionMinProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectMembersConnectionMinTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectMembersConnectionMinTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectMembersConnectionStddevPopulationCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectMembersConnectionStddevPopulationCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectMembersConnectionStddevPopulationCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectMembersConnectionStddevPopulationCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectMembersConnectionStddevPopulationIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectMembersConnectionStddevPopulationIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectMembersConnectionStddevPopulationMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_ID_ASC',
  ProjectMembersConnectionStddevPopulationMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_ID_DESC',
  ProjectMembersConnectionStddevPopulationMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersConnectionStddevPopulationMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersConnectionStddevPopulationProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectMembersConnectionStddevPopulationProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectMembersConnectionStddevPopulationTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectMembersConnectionStddevPopulationTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectMembersConnectionStddevSampleCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectMembersConnectionStddevSampleCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectMembersConnectionStddevSampleCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectMembersConnectionStddevSampleCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectMembersConnectionStddevSampleIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectMembersConnectionStddevSampleIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectMembersConnectionStddevSampleMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersConnectionStddevSampleMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersConnectionStddevSampleMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionStddevSampleMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionStddevSampleProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersConnectionStddevSampleProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersConnectionStddevSampleTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectMembersConnectionStddevSampleTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectMembersConnectionSumCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectMembersConnectionSumCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectMembersConnectionSumCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectMembersConnectionSumCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectMembersConnectionSumIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_ID_ASC',
  ProjectMembersConnectionSumIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_ID_DESC',
  ProjectMembersConnectionSumMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_ID_ASC',
  ProjectMembersConnectionSumMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_ID_DESC',
  ProjectMembersConnectionSumMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_TYPE_ASC',
  ProjectMembersConnectionSumMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_TYPE_DESC',
  ProjectMembersConnectionSumProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectMembersConnectionSumProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectMembersConnectionSumTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectMembersConnectionSumTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectMembersConnectionVariancePopulationCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectMembersConnectionVariancePopulationCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectMembersConnectionVariancePopulationCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectMembersConnectionVariancePopulationCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectMembersConnectionVariancePopulationIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectMembersConnectionVariancePopulationIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectMembersConnectionVariancePopulationMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_ASC',
  ProjectMembersConnectionVariancePopulationMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_DESC',
  ProjectMembersConnectionVariancePopulationMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersConnectionVariancePopulationMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersConnectionVariancePopulationProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectMembersConnectionVariancePopulationProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectMembersConnectionVariancePopulationTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectMembersConnectionVariancePopulationTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectMembersConnectionVarianceSampleCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectMembersConnectionVarianceSampleCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectMembersConnectionVarianceSampleCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectMembersConnectionVarianceSampleCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectMembersConnectionVarianceSampleIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectMembersConnectionVarianceSampleIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectMembersConnectionVarianceSampleMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersConnectionVarianceSampleMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersConnectionVarianceSampleMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionVarianceSampleMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionVarianceSampleProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersConnectionVarianceSampleProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersConnectionVarianceSampleTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectMembersConnectionVarianceSampleTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionArrayAggDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionArrayAggProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionArrayAggTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionArrayAggWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionArrayAggWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionAverageColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionAverageDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionAverageDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionAverageDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertiesValuesConnectionAverageIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertiesValuesConnectionAverageNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionAverageProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionAverageTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionAverageWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionAverageWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionCountAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_COUNT_ASC',
  ProjectPropertiesValuesConnectionCountDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_COUNT_DESC',
  ProjectPropertiesValuesConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionDistinctCountProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionDistinctCountTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionDistinctCountWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionDistinctCountWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionMaxColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionMaxDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionMaxDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionMaxDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_ID_ASC',
  ProjectPropertiesValuesConnectionMaxIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_ID_DESC',
  ProjectPropertiesValuesConnectionMaxNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionMaxProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionMaxTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionMaxWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionMaxWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionMinColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionMinDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionMinDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionMinDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_ID_ASC',
  ProjectPropertiesValuesConnectionMinIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_ID_DESC',
  ProjectPropertiesValuesConnectionMinNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionMinProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionMinTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionMinWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionMinWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevPopulationWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevPopulationWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionStddevSampleProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionStddevSampleTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionStddevSampleWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionStddevSampleWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionSumColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionSumDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionSumDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionSumDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_ID_ASC',
  ProjectPropertiesValuesConnectionSumIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_ID_DESC',
  ProjectPropertiesValuesConnectionSumNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionSumProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionSumTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionSumWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionSumWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionVariancePopulationWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionVariancePopulationWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAllDayAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAllDayDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDateValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleDropdownValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleDropdownValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleNumericValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleNumericValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleProjectIdAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleProjectIdDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleTextValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleTextValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertiesValuesConnectionVarianceSampleWorkerValueAsc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertiesValuesConnectionVarianceSampleWorkerValueDesc = 'PROJECT_PROPERTIES_VALUES_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByProjectIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByProjectIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByProjectIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByProjectIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByProjectIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByProjectIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByProjectIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByProjectIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByProjectIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByProjectIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByProjectIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByProjectIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByProjectIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByProjectIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByProjectIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByProjectIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByProjectIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByProjectIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByProjectIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByProjectIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByProjectIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByProjectIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByProjectIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByProjectIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByProjectIdConnectionCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByProjectIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByProjectIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByProjectIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByProjectIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByProjectIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByProjectIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByProjectIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByProjectIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByProjectIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByProjectIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByProjectIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByProjectIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByProjectIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByProjectIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByProjectIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByProjectIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByProjectIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByProjectIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByProjectIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByProjectIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByProjectIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByProjectIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByProjectIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByProjectIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByProjectIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByProjectIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByProjectIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByProjectIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByProjectIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByProjectIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByProjectIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByProjectIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByProjectIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByProjectIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByProjectIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByProjectIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByProjectIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByProjectIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByProjectIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByProjectIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByProjectIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByProjectIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByProjectIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByProjectIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByProjectIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByProjectIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByProjectIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByProjectIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByProjectIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByProjectIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByProjectIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByProjectIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByProjectIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByProjectIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByProjectIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByProjectIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByProjectIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByProjectIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByProjectIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByProjectIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByProjectIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByProjectIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByProjectIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByProjectIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByProjectIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByProjectIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByProjectIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByProjectIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectSizeAsc = 'PROJECT_SIZE_ASC',
  ProjectSizeDesc = 'PROJECT_SIZE_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionArrayAggStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionAverageStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionAverageStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionDistinctCountStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMaxStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMaxStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MAX_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionMinStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionMinStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_MIN_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevPopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionStddevSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionSumStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionSumStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_SUM_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVariancePopulationStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleCountAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleCountDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleEndedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleEndedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleIsEndedAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleIsEndedDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleSpentTimeAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleSpentTimeDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleStartedAtAsc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ProjectStageTimelinesByProjectIdConnectionVarianceSampleStartedAtDesc = 'PROJECT_STAGE_TIMELINES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ProjectStageUpdatesConnectionArrayAggCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionArrayAggCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionArrayAggIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectStageUpdatesConnectionArrayAggProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionArrayAggStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionArrayAggStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionAverageCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionAverageCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionAverageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_ID_ASC',
  ProjectStageUpdatesConnectionAverageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_ID_DESC',
  ProjectStageUpdatesConnectionAverageProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionAverageProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionAverageStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionAverageStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionCountAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_COUNT_ASC',
  ProjectStageUpdatesConnectionCountDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_COUNT_DESC',
  ProjectStageUpdatesConnectionDistinctCountCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionDistinctCountCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionDistinctCountIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectStageUpdatesConnectionDistinctCountProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionDistinctCountStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionDistinctCountStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionMaxCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionMaxCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionMaxIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_ID_ASC',
  ProjectStageUpdatesConnectionMaxIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_ID_DESC',
  ProjectStageUpdatesConnectionMaxProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionMaxProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionMaxStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionMaxStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionMinCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionMinCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionMinIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_ID_ASC',
  ProjectStageUpdatesConnectionMinIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_ID_DESC',
  ProjectStageUpdatesConnectionMinProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionMinProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionMinStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionMinStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionStddevPopulationIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionStddevPopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionStddevPopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionStddevSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionStddevSampleIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionStddevSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionStddevSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionSumCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionSumCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionSumIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_ID_ASC',
  ProjectStageUpdatesConnectionSumIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_ID_DESC',
  ProjectStageUpdatesConnectionSumProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionSumProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionSumStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionSumStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionVariancePopulationIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionVariancePopulationStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionVariancePopulationStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectStageUpdatesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectStageUpdatesConnectionVarianceSampleIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleProjectIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleProjectIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStageUpdatesConnectionVarianceSampleStageIdAsc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectStageUpdatesConnectionVarianceSampleStageIdDesc = 'PROJECT_STAGE_UPDATES_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectStatusTimelineConnectionArrayAggCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_COUNT_ASC',
  ProjectStatusTimelineConnectionArrayAggCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_COUNT_DESC',
  ProjectStatusTimelineConnectionArrayAggEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionArrayAggEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionArrayAggIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionArrayAggIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionArrayAggProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionArrayAggProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionArrayAggSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionArrayAggSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionArrayAggStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionArrayAggStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionArrayAggStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectStatusTimelineConnectionArrayAggStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectStatusTimelineConnectionAverageCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_COUNT_ASC',
  ProjectStatusTimelineConnectionAverageCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_COUNT_DESC',
  ProjectStatusTimelineConnectionAverageEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionAverageEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionAverageIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionAverageIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionAverageProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionAverageProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionAverageSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionAverageSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionAverageStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionAverageStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionAverageStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectStatusTimelineConnectionAverageStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectStatusTimelineConnectionCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_COUNT_ASC',
  ProjectStatusTimelineConnectionCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_COUNT_DESC',
  ProjectStatusTimelineConnectionDistinctCountCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_COUNT_ASC',
  ProjectStatusTimelineConnectionDistinctCountCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_COUNT_DESC',
  ProjectStatusTimelineConnectionDistinctCountEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionDistinctCountEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionDistinctCountIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionDistinctCountIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionDistinctCountProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionDistinctCountProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionDistinctCountSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionDistinctCountSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionDistinctCountStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionDistinctCountStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionDistinctCountStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectStatusTimelineConnectionDistinctCountStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectStatusTimelineConnectionMaxCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_COUNT_ASC',
  ProjectStatusTimelineConnectionMaxCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_COUNT_DESC',
  ProjectStatusTimelineConnectionMaxEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionMaxEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionMaxIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionMaxIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionMaxProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionMaxProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionMaxSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionMaxSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionMaxStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionMaxStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionMaxStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STATUS_ASC',
  ProjectStatusTimelineConnectionMaxStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MAX_STATUS_DESC',
  ProjectStatusTimelineConnectionMinCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_COUNT_ASC',
  ProjectStatusTimelineConnectionMinCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_COUNT_DESC',
  ProjectStatusTimelineConnectionMinEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionMinEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionMinIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionMinIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionMinProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionMinProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionMinSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionMinSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionMinStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionMinStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionMinStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STATUS_ASC',
  ProjectStatusTimelineConnectionMinStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_MIN_STATUS_DESC',
  ProjectStatusTimelineConnectionStddevPopulationCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_COUNT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_COUNT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionStddevPopulationIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionStddevPopulationProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionStddevPopulationProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionStddevPopulationSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionStddevPopulationSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionStddevPopulationStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionStddevPopulationStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionStddevPopulationStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectStatusTimelineConnectionStddevPopulationStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectStatusTimelineConnectionStddevSampleCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_COUNT_ASC',
  ProjectStatusTimelineConnectionStddevSampleCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_COUNT_DESC',
  ProjectStatusTimelineConnectionStddevSampleEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionStddevSampleEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionStddevSampleIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionStddevSampleIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionStddevSampleProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionStddevSampleProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionStddevSampleSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionStddevSampleSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionStddevSampleStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionStddevSampleStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionStddevSampleStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectStatusTimelineConnectionStddevSampleStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectStatusTimelineConnectionSumCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_COUNT_ASC',
  ProjectStatusTimelineConnectionSumCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_COUNT_DESC',
  ProjectStatusTimelineConnectionSumEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionSumEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionSumIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionSumIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionSumProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionSumProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionSumSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionSumSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionSumStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionSumStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionSumStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STATUS_ASC',
  ProjectStatusTimelineConnectionSumStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_SUM_STATUS_DESC',
  ProjectStatusTimelineConnectionVariancePopulationCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_COUNT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_COUNT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionVariancePopulationIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionVariancePopulationProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionVariancePopulationProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionVariancePopulationSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionVariancePopulationSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionVariancePopulationStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionVariancePopulationStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionVariancePopulationStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectStatusTimelineConnectionVariancePopulationStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectStatusTimelineConnectionVarianceSampleCountAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_COUNT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleCountDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_COUNT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleEndedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleEndedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_ENDED_AT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleIsEndedAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_ASC',
  ProjectStatusTimelineConnectionVarianceSampleIsEndedDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_IS_ENDED_DESC',
  ProjectStatusTimelineConnectionVarianceSampleProjectIdAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusTimelineConnectionVarianceSampleProjectIdDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusTimelineConnectionVarianceSampleSpentTimeAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_ASC',
  ProjectStatusTimelineConnectionVarianceSampleSpentTimeDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_SPENT_TIME_DESC',
  ProjectStatusTimelineConnectionVarianceSampleStartedAtAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_ASC',
  ProjectStatusTimelineConnectionVarianceSampleStartedAtDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STARTED_AT_DESC',
  ProjectStatusTimelineConnectionVarianceSampleStatusAsc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectStatusTimelineConnectionVarianceSampleStatusDesc = 'PROJECT_STATUS_TIMELINE_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectStatusUpdatesConnectionArrayAggCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionArrayAggCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionArrayAggIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectStatusUpdatesConnectionArrayAggIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectStatusUpdatesConnectionArrayAggProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionArrayAggProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionArrayAggStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectStatusUpdatesConnectionArrayAggStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectStatusUpdatesConnectionAverageCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionAverageCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionAverageIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_ID_ASC',
  ProjectStatusUpdatesConnectionAverageIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_ID_DESC',
  ProjectStatusUpdatesConnectionAverageProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionAverageProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionAverageStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectStatusUpdatesConnectionAverageStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectStatusUpdatesConnectionCountAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_COUNT_ASC',
  ProjectStatusUpdatesConnectionCountDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_COUNT_DESC',
  ProjectStatusUpdatesConnectionDistinctCountCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionDistinctCountCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionDistinctCountIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectStatusUpdatesConnectionDistinctCountIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectStatusUpdatesConnectionDistinctCountProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionDistinctCountProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionDistinctCountStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectStatusUpdatesConnectionDistinctCountStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectStatusUpdatesConnectionMaxCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionMaxCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionMaxIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_ID_ASC',
  ProjectStatusUpdatesConnectionMaxIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_ID_DESC',
  ProjectStatusUpdatesConnectionMaxProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionMaxProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionMaxStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_STATUS_ASC',
  ProjectStatusUpdatesConnectionMaxStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MAX_STATUS_DESC',
  ProjectStatusUpdatesConnectionMinCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionMinCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionMinIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_ID_ASC',
  ProjectStatusUpdatesConnectionMinIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_ID_DESC',
  ProjectStatusUpdatesConnectionMinProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionMinProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionMinStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_STATUS_ASC',
  ProjectStatusUpdatesConnectionMinStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_MIN_STATUS_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionStddevPopulationStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectStatusUpdatesConnectionStddevPopulationStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectStatusUpdatesConnectionStddevSampleCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionStddevSampleCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionStddevSampleIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectStatusUpdatesConnectionStddevSampleIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectStatusUpdatesConnectionStddevSampleProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionStddevSampleProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionStddevSampleStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectStatusUpdatesConnectionStddevSampleStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectStatusUpdatesConnectionSumCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionSumCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionSumIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_ID_ASC',
  ProjectStatusUpdatesConnectionSumIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_ID_DESC',
  ProjectStatusUpdatesConnectionSumProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionSumProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionSumStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_STATUS_ASC',
  ProjectStatusUpdatesConnectionSumStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_SUM_STATUS_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionVariancePopulationStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectStatusUpdatesConnectionVariancePopulationStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleCreatedAtAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleCreatedAtDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleProjectIdAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleProjectIdDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectStatusUpdatesConnectionVarianceSampleStatusAsc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectStatusUpdatesConnectionVarianceSampleStatusDesc = 'PROJECT_STATUS_UPDATES_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectTradesByProjectIdConnectionArrayAggProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionArrayAggProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionArrayAggTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectTradesByProjectIdConnectionArrayAggTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectTradesByProjectIdConnectionAverageProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionAverageProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionAverageTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectTradesByProjectIdConnectionAverageTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectTradesByProjectIdConnectionCountAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  ProjectTradesByProjectIdConnectionCountDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  ProjectTradesByProjectIdConnectionDistinctCountProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionDistinctCountProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionDistinctCountTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectTradesByProjectIdConnectionDistinctCountTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectTradesByProjectIdConnectionMaxProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionMaxProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionMaxTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectTradesByProjectIdConnectionMaxTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectTradesByProjectIdConnectionMinProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionMinProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionMinTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectTradesByProjectIdConnectionMinTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectTradesByProjectIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionStddevPopulationTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectTradesByProjectIdConnectionStddevPopulationTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectTradesByProjectIdConnectionStddevSampleProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionStddevSampleProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionStddevSampleTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectTradesByProjectIdConnectionStddevSampleTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectTradesByProjectIdConnectionSumProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionSumProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionSumTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectTradesByProjectIdConnectionSumTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectTradesByProjectIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionVariancePopulationTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectTradesByProjectIdConnectionVariancePopulationTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectTradesByProjectIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectTradesByProjectIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectTradesByProjectIdConnectionVarianceSampleTradeAsc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectTradesByProjectIdConnectionVarianceSampleTradeDesc = 'PROJECT_TRADES_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectValueAsc = 'PROJECT_VALUE_ASC',
  ProjectValueDesc = 'PROJECT_VALUE_DESC',
  PropertyValueDate_1Asc = 'PROPERTY_VALUE_DATE_1_ASC',
  PropertyValueDate_1Desc = 'PROPERTY_VALUE_DATE_1_DESC',
  PropertyValueDate_2Asc = 'PROPERTY_VALUE_DATE_2_ASC',
  PropertyValueDate_2Desc = 'PROPERTY_VALUE_DATE_2_DESC',
  PropertyValueDate_3Asc = 'PROPERTY_VALUE_DATE_3_ASC',
  PropertyValueDate_3Desc = 'PROPERTY_VALUE_DATE_3_DESC',
  PropertyValueDateAsc = 'PROPERTY_VALUE_DATE_ASC',
  PropertyValueDateDesc = 'PROPERTY_VALUE_DATE_DESC',
  PropertyValueNumeric_1Asc = 'PROPERTY_VALUE_NUMERIC_1_ASC',
  PropertyValueNumeric_1Desc = 'PROPERTY_VALUE_NUMERIC_1_DESC',
  PropertyValueNumeric_2Asc = 'PROPERTY_VALUE_NUMERIC_2_ASC',
  PropertyValueNumeric_2Desc = 'PROPERTY_VALUE_NUMERIC_2_DESC',
  PropertyValueNumeric_3Asc = 'PROPERTY_VALUE_NUMERIC_3_ASC',
  PropertyValueNumeric_3Desc = 'PROPERTY_VALUE_NUMERIC_3_DESC',
  PropertyValueNumericAsc = 'PROPERTY_VALUE_NUMERIC_ASC',
  PropertyValueNumericDesc = 'PROPERTY_VALUE_NUMERIC_DESC',
  PropertyValueText_1Asc = 'PROPERTY_VALUE_TEXT_1_ASC',
  PropertyValueText_1Desc = 'PROPERTY_VALUE_TEXT_1_DESC',
  PropertyValueText_2Asc = 'PROPERTY_VALUE_TEXT_2_ASC',
  PropertyValueText_2Desc = 'PROPERTY_VALUE_TEXT_2_DESC',
  PropertyValueText_3Asc = 'PROPERTY_VALUE_TEXT_3_ASC',
  PropertyValueText_3Desc = 'PROPERTY_VALUE_TEXT_3_DESC',
  PropertyValueTextAsc = 'PROPERTY_VALUE_TEXT_ASC',
  PropertyValueTextDesc = 'PROPERTY_VALUE_TEXT_DESC',
  PropertyValueWorker_1Asc = 'PROPERTY_VALUE_WORKER_1_ASC',
  PropertyValueWorker_1Desc = 'PROPERTY_VALUE_WORKER_1_DESC',
  PropertyValueWorker_2Asc = 'PROPERTY_VALUE_WORKER_2_ASC',
  PropertyValueWorker_2Desc = 'PROPERTY_VALUE_WORKER_2_DESC',
  PropertyValueWorker_3Asc = 'PROPERTY_VALUE_WORKER_3_ASC',
  PropertyValueWorker_3Desc = 'PROPERTY_VALUE_WORKER_3_DESC',
  PropertyValueWorkerAsc = 'PROPERTY_VALUE_WORKER_ASC',
  PropertyValueWorkerDesc = 'PROPERTY_VALUE_WORKER_DESC',
  ReferrerContactIdAsc = 'REFERRER_CONTACT_ID_ASC',
  ReferrerContactIdDesc = 'REFERRER_CONTACT_ID_DESC',
  RemindersByProjectIdConnectionArrayAggAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionArrayAggAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionArrayAggCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionArrayAggCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionArrayAggCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionArrayAggCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionArrayAggCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  RemindersByProjectIdConnectionArrayAggCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  RemindersByProjectIdConnectionArrayAggCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  RemindersByProjectIdConnectionArrayAggCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  RemindersByProjectIdConnectionArrayAggDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionArrayAggDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionArrayAggDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionArrayAggDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionArrayAggDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  RemindersByProjectIdConnectionArrayAggDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  RemindersByProjectIdConnectionArrayAggIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  RemindersByProjectIdConnectionArrayAggIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  RemindersByProjectIdConnectionArrayAggIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionArrayAggIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionArrayAggIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionArrayAggIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionArrayAggJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  RemindersByProjectIdConnectionArrayAggJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  RemindersByProjectIdConnectionArrayAggPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionArrayAggPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionArrayAggPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionArrayAggPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionArrayAggPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionArrayAggPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionArrayAggPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionArrayAggPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionArrayAggProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionArrayAggProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionArrayAggTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  RemindersByProjectIdConnectionArrayAggTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  RemindersByProjectIdConnectionArrayAggTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  RemindersByProjectIdConnectionArrayAggTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  RemindersByProjectIdConnectionArrayAggUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionArrayAggUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionAverageAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionAverageAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionAverageCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionAverageCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionAverageCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionAverageCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionAverageCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  RemindersByProjectIdConnectionAverageCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  RemindersByProjectIdConnectionAverageCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  RemindersByProjectIdConnectionAverageCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  RemindersByProjectIdConnectionAverageDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionAverageDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionAverageDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionAverageDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionAverageDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_ASC',
  RemindersByProjectIdConnectionAverageDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_DUE_DATE_DESC',
  RemindersByProjectIdConnectionAverageIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  RemindersByProjectIdConnectionAverageIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  RemindersByProjectIdConnectionAverageIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionAverageIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionAverageIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionAverageIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionAverageJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  RemindersByProjectIdConnectionAverageJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  RemindersByProjectIdConnectionAveragePrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionAveragePrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionAveragePrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionAveragePrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionAveragePrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionAveragePrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionAveragePrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionAveragePrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionAverageProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionAverageProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionAverageTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  RemindersByProjectIdConnectionAverageTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  RemindersByProjectIdConnectionAverageTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_ASC',
  RemindersByProjectIdConnectionAverageTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_TYPE_DESC',
  RemindersByProjectIdConnectionAverageUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionAverageUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionCountAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  RemindersByProjectIdConnectionCountDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  RemindersByProjectIdConnectionDistinctCountAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionDistinctCountCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionDistinctCountCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  RemindersByProjectIdConnectionDistinctCountCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  RemindersByProjectIdConnectionDistinctCountCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  RemindersByProjectIdConnectionDistinctCountCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  RemindersByProjectIdConnectionDistinctCountDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionDistinctCountDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionDistinctCountDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  RemindersByProjectIdConnectionDistinctCountDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  RemindersByProjectIdConnectionDistinctCountIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionDistinctCountIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionDistinctCountIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionDistinctCountIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionDistinctCountJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionDistinctCountPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionDistinctCountProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionDistinctCountProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionDistinctCountTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  RemindersByProjectIdConnectionDistinctCountTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  RemindersByProjectIdConnectionDistinctCountTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  RemindersByProjectIdConnectionDistinctCountTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  RemindersByProjectIdConnectionDistinctCountUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionDistinctCountUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionMaxAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionMaxAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionMaxCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionMaxCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionMaxCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionMaxCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionMaxCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  RemindersByProjectIdConnectionMaxCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  RemindersByProjectIdConnectionMaxCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  RemindersByProjectIdConnectionMaxCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  RemindersByProjectIdConnectionMaxDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionMaxDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionMaxDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionMaxDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionMaxDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_ASC',
  RemindersByProjectIdConnectionMaxDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_DUE_DATE_DESC',
  RemindersByProjectIdConnectionMaxIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  RemindersByProjectIdConnectionMaxIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  RemindersByProjectIdConnectionMaxIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionMaxIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionMaxIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionMaxIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionMaxJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_ASC',
  RemindersByProjectIdConnectionMaxJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_DESC',
  RemindersByProjectIdConnectionMaxPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionMaxPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionMaxPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionMaxPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionMaxPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionMaxPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionMaxPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionMaxPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionMaxProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionMaxProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionMaxTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  RemindersByProjectIdConnectionMaxTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  RemindersByProjectIdConnectionMaxTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_ASC',
  RemindersByProjectIdConnectionMaxTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_TYPE_DESC',
  RemindersByProjectIdConnectionMaxUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionMaxUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionMinAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionMinAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionMinCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionMinCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionMinCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionMinCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionMinCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  RemindersByProjectIdConnectionMinCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  RemindersByProjectIdConnectionMinCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  RemindersByProjectIdConnectionMinCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  RemindersByProjectIdConnectionMinDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionMinDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionMinDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionMinDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionMinDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_ASC',
  RemindersByProjectIdConnectionMinDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_DUE_DATE_DESC',
  RemindersByProjectIdConnectionMinIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  RemindersByProjectIdConnectionMinIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  RemindersByProjectIdConnectionMinIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionMinIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionMinIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionMinIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionMinJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_ASC',
  RemindersByProjectIdConnectionMinJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_DESC',
  RemindersByProjectIdConnectionMinPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionMinPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionMinPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionMinPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionMinPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionMinPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionMinPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionMinPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionMinProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionMinProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionMinTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  RemindersByProjectIdConnectionMinTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  RemindersByProjectIdConnectionMinTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_ASC',
  RemindersByProjectIdConnectionMinTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_TYPE_DESC',
  RemindersByProjectIdConnectionMinUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionMinUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionStddevPopulationAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionStddevPopulationCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionStddevPopulationCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  RemindersByProjectIdConnectionStddevPopulationCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  RemindersByProjectIdConnectionStddevPopulationCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  RemindersByProjectIdConnectionStddevPopulationCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  RemindersByProjectIdConnectionStddevPopulationDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionStddevPopulationDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionStddevPopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  RemindersByProjectIdConnectionStddevPopulationDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  RemindersByProjectIdConnectionStddevPopulationIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionStddevPopulationIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionStddevPopulationIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionStddevPopulationIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionStddevPopulationJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionStddevPopulationPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionStddevPopulationProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionStddevPopulationProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionStddevPopulationTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  RemindersByProjectIdConnectionStddevPopulationTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  RemindersByProjectIdConnectionStddevPopulationTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  RemindersByProjectIdConnectionStddevPopulationTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  RemindersByProjectIdConnectionStddevPopulationUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionStddevPopulationUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionStddevSampleAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionStddevSampleCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionStddevSampleCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionStddevSampleCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionStddevSampleCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  RemindersByProjectIdConnectionStddevSampleCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  RemindersByProjectIdConnectionStddevSampleCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  RemindersByProjectIdConnectionStddevSampleCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  RemindersByProjectIdConnectionStddevSampleDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionStddevSampleDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionStddevSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionStddevSampleDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  RemindersByProjectIdConnectionStddevSampleDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  RemindersByProjectIdConnectionStddevSampleIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  RemindersByProjectIdConnectionStddevSampleIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionStddevSampleIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionStddevSampleIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionStddevSampleIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionStddevSampleJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionStddevSamplePrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionStddevSampleProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionStddevSampleProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionStddevSampleTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  RemindersByProjectIdConnectionStddevSampleTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  RemindersByProjectIdConnectionStddevSampleTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  RemindersByProjectIdConnectionStddevSampleTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  RemindersByProjectIdConnectionStddevSampleUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionStddevSampleUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionSumAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionSumAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionSumCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionSumCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionSumCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionSumCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionSumCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  RemindersByProjectIdConnectionSumCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  RemindersByProjectIdConnectionSumCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  RemindersByProjectIdConnectionSumCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  RemindersByProjectIdConnectionSumDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionSumDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionSumDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionSumDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionSumDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_ASC',
  RemindersByProjectIdConnectionSumDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_DUE_DATE_DESC',
  RemindersByProjectIdConnectionSumIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  RemindersByProjectIdConnectionSumIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  RemindersByProjectIdConnectionSumIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionSumIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionSumIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionSumIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionSumJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_ASC',
  RemindersByProjectIdConnectionSumJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_DESC',
  RemindersByProjectIdConnectionSumPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionSumPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionSumPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionSumPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionSumPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionSumPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionSumPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionSumPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionSumProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionSumProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionSumTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  RemindersByProjectIdConnectionSumTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  RemindersByProjectIdConnectionSumTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_ASC',
  RemindersByProjectIdConnectionSumTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_TYPE_DESC',
  RemindersByProjectIdConnectionSumUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionSumUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionVariancePopulationAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionVariancePopulationCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionVariancePopulationCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  RemindersByProjectIdConnectionVariancePopulationCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  RemindersByProjectIdConnectionVariancePopulationCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  RemindersByProjectIdConnectionVariancePopulationCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  RemindersByProjectIdConnectionVariancePopulationDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionVariancePopulationDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionVariancePopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  RemindersByProjectIdConnectionVariancePopulationDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  RemindersByProjectIdConnectionVariancePopulationIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionVariancePopulationIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionVariancePopulationIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionVariancePopulationIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionVariancePopulationJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionVariancePopulationPrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionVariancePopulationProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionVariancePopulationProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionVariancePopulationTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  RemindersByProjectIdConnectionVariancePopulationTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  RemindersByProjectIdConnectionVariancePopulationTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  RemindersByProjectIdConnectionVariancePopulationTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  RemindersByProjectIdConnectionVariancePopulationUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionVariancePopulationUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  RemindersByProjectIdConnectionVarianceSampleAssigneeIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleAssigneeIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByProjectIdConnectionVarianceSampleCompanyIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleCompanyIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  RemindersByProjectIdConnectionVarianceSampleCompletedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  RemindersByProjectIdConnectionVarianceSampleCompletedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  RemindersByProjectIdConnectionVarianceSampleCreatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RemindersByProjectIdConnectionVarianceSampleCreatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RemindersByProjectIdConnectionVarianceSampleCreatedByAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  RemindersByProjectIdConnectionVarianceSampleCreatedByDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  RemindersByProjectIdConnectionVarianceSampleDescriptionAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  RemindersByProjectIdConnectionVarianceSampleDescriptionDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  RemindersByProjectIdConnectionVarianceSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByProjectIdConnectionVarianceSampleDueDateAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  RemindersByProjectIdConnectionVarianceSampleDueDateDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  RemindersByProjectIdConnectionVarianceSampleIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  RemindersByProjectIdConnectionVarianceSampleIsAppointmentAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByProjectIdConnectionVarianceSampleIsAppointmentDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByProjectIdConnectionVarianceSampleIsCompletedAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  RemindersByProjectIdConnectionVarianceSampleIsCompletedDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  RemindersByProjectIdConnectionVarianceSampleJobIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleJobIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeAllAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeAllDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeOwnAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeOwnDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeTeamAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByProjectIdConnectionVarianceSamplePrivilegeTeamDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByProjectIdConnectionVarianceSampleProjectIdAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  RemindersByProjectIdConnectionVarianceSampleProjectIdDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  RemindersByProjectIdConnectionVarianceSampleTitleAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  RemindersByProjectIdConnectionVarianceSampleTitleDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  RemindersByProjectIdConnectionVarianceSampleTypeAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  RemindersByProjectIdConnectionVarianceSampleTypeDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  RemindersByProjectIdConnectionVarianceSampleUpdatedAtAsc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  RemindersByProjectIdConnectionVarianceSampleUpdatedAtDesc = 'REMINDERS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  RequestStatusAsc = 'REQUEST_STATUS_ASC',
  RequestStatusDesc = 'REQUEST_STATUS_DESC',
  RevenueAsc = 'REVENUE_ASC',
  RevenueDesc = 'REVENUE_DESC',
  SalesRepIdAsc = 'SALES_REP_ID_ASC',
  SalesRepIdDesc = 'SALES_REP_ID_DESC',
  SlaRedViolationJobIdAsc = 'SLA_RED_VIOLATION_JOB_ID_ASC',
  SlaRedViolationJobIdDesc = 'SLA_RED_VIOLATION_JOB_ID_DESC',
  SlaYellowViolationJobIdAsc = 'SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  SlaYellowViolationJobIdDesc = 'SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  StageIdAsc = 'STAGE_ID_ASC',
  StageIdDesc = 'STAGE_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StreetAsc = 'STREET_ASC',
  StreetDesc = 'STREET_DESC',
  StreetViewUrlAsc = 'STREET_VIEW_URL_ASC',
  StreetViewUrlDesc = 'STREET_VIEW_URL_DESC',
  SystemsByProjectIdConnectionArrayAggAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  SystemsByProjectIdConnectionArrayAggAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionArrayAggAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionArrayAggAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  SystemsByProjectIdConnectionArrayAggAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionArrayAggAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionArrayAggAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionArrayAggAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionArrayAggAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionArrayAggAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionArrayAggAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionArrayAggAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionArrayAggAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionArrayAggAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionArrayAggClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionArrayAggClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionArrayAggConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionArrayAggConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionArrayAggCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemsByProjectIdConnectionArrayAggCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemsByProjectIdConnectionArrayAggIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemsByProjectIdConnectionArrayAggIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemsByProjectIdConnectionArrayAggInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionArrayAggInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionArrayAggIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionArrayAggIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionArrayAggLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionArrayAggLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionArrayAggMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MONITORED_ASC',
  SystemsByProjectIdConnectionArrayAggMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MONITORED_DESC',
  SystemsByProjectIdConnectionArrayAggNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NAME_ASC',
  SystemsByProjectIdConnectionArrayAggNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NAME_DESC',
  SystemsByProjectIdConnectionArrayAggNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NOTES_ASC',
  SystemsByProjectIdConnectionArrayAggNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NOTES_DESC',
  SystemsByProjectIdConnectionArrayAggNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMBER_ASC',
  SystemsByProjectIdConnectionArrayAggNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NUMBER_DESC',
  SystemsByProjectIdConnectionArrayAggOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionArrayAggOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionArrayAggOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionArrayAggOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionArrayAggProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionArrayAggProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionArrayAggProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionArrayAggProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionArrayAggProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionArrayAggProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionArrayAggProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionArrayAggProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionArrayAggProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionArrayAggProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionArrayAggSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SIZE_ASC',
  SystemsByProjectIdConnectionArrayAggSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SIZE_DESC',
  SystemsByProjectIdConnectionArrayAggStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SystemsByProjectIdConnectionArrayAggStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SystemsByProjectIdConnectionArrayAggTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMEZONE_ASC',
  SystemsByProjectIdConnectionArrayAggTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMEZONE_DESC',
  SystemsByProjectIdConnectionArrayAggUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UUID_ASC',
  SystemsByProjectIdConnectionArrayAggUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UUID_DESC',
  SystemsByProjectIdConnectionAverageAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  SystemsByProjectIdConnectionAverageAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionAverageAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionAverageAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  SystemsByProjectIdConnectionAverageAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionAverageAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionAverageAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionAverageAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionAverageAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionAverageAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionAverageAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionAverageAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionAverageAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionAverageAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionAverageClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionAverageClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionAverageConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionAverageConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionAverageCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemsByProjectIdConnectionAverageCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemsByProjectIdConnectionAverageIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  SystemsByProjectIdConnectionAverageIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  SystemsByProjectIdConnectionAverageInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionAverageInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionAverageIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionAverageIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionAverageLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionAverageLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionAverageMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_MONITORED_ASC',
  SystemsByProjectIdConnectionAverageMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_MONITORED_DESC',
  SystemsByProjectIdConnectionAverageNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NAME_ASC',
  SystemsByProjectIdConnectionAverageNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NAME_DESC',
  SystemsByProjectIdConnectionAverageNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NOTES_ASC',
  SystemsByProjectIdConnectionAverageNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NOTES_DESC',
  SystemsByProjectIdConnectionAverageNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NUMBER_ASC',
  SystemsByProjectIdConnectionAverageNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_NUMBER_DESC',
  SystemsByProjectIdConnectionAverageOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionAverageOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionAverageOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionAverageOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionAverageProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionAverageProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionAverageProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionAverageProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionAverageProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionAverageProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionAverageProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionAverageProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionAverageProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionAverageProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionAverageSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_SIZE_ASC',
  SystemsByProjectIdConnectionAverageSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_SIZE_DESC',
  SystemsByProjectIdConnectionAverageStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SystemsByProjectIdConnectionAverageStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SystemsByProjectIdConnectionAverageTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMEZONE_ASC',
  SystemsByProjectIdConnectionAverageTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMEZONE_DESC',
  SystemsByProjectIdConnectionAverageUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UUID_ASC',
  SystemsByProjectIdConnectionAverageUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_AVERAGE_UUID_DESC',
  SystemsByProjectIdConnectionCountAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  SystemsByProjectIdConnectionCountDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionDistinctCountAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionDistinctCountAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionDistinctCountClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionDistinctCountClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionDistinctCountConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionDistinctCountConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionDistinctCountCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemsByProjectIdConnectionDistinctCountCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemsByProjectIdConnectionDistinctCountIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemsByProjectIdConnectionDistinctCountIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemsByProjectIdConnectionDistinctCountInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionDistinctCountInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionDistinctCountIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionDistinctCountIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionDistinctCountLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionDistinctCountLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionDistinctCountMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MONITORED_ASC',
  SystemsByProjectIdConnectionDistinctCountMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MONITORED_DESC',
  SystemsByProjectIdConnectionDistinctCountNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  SystemsByProjectIdConnectionDistinctCountNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  SystemsByProjectIdConnectionDistinctCountNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  SystemsByProjectIdConnectionDistinctCountNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  SystemsByProjectIdConnectionDistinctCountNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMBER_ASC',
  SystemsByProjectIdConnectionDistinctCountNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NUMBER_DESC',
  SystemsByProjectIdConnectionDistinctCountOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionDistinctCountOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionDistinctCountOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionDistinctCountOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionDistinctCountProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionDistinctCountProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionDistinctCountProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionDistinctCountProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionDistinctCountProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionDistinctCountProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionDistinctCountProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionDistinctCountProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionDistinctCountProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionDistinctCountProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionDistinctCountSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SIZE_ASC',
  SystemsByProjectIdConnectionDistinctCountSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SIZE_DESC',
  SystemsByProjectIdConnectionDistinctCountStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SystemsByProjectIdConnectionDistinctCountStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SystemsByProjectIdConnectionDistinctCountTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMEZONE_ASC',
  SystemsByProjectIdConnectionDistinctCountTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMEZONE_DESC',
  SystemsByProjectIdConnectionDistinctCountUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  SystemsByProjectIdConnectionDistinctCountUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  SystemsByProjectIdConnectionMaxAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  SystemsByProjectIdConnectionMaxAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionMaxAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionMaxAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  SystemsByProjectIdConnectionMaxAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionMaxAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionMaxAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionMaxAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionMaxAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionMaxAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionMaxAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionMaxAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionMaxAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionMaxAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionMaxClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionMaxClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionMaxConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionMaxConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionMaxCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SystemsByProjectIdConnectionMaxCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SystemsByProjectIdConnectionMaxIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  SystemsByProjectIdConnectionMaxIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  SystemsByProjectIdConnectionMaxInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionMaxInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionMaxIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionMaxIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionMaxLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionMaxLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionMaxMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_MONITORED_ASC',
  SystemsByProjectIdConnectionMaxMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_MONITORED_DESC',
  SystemsByProjectIdConnectionMaxNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NAME_ASC',
  SystemsByProjectIdConnectionMaxNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NAME_DESC',
  SystemsByProjectIdConnectionMaxNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NOTES_ASC',
  SystemsByProjectIdConnectionMaxNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NOTES_DESC',
  SystemsByProjectIdConnectionMaxNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NUMBER_ASC',
  SystemsByProjectIdConnectionMaxNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_NUMBER_DESC',
  SystemsByProjectIdConnectionMaxOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionMaxOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionMaxOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionMaxOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionMaxProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionMaxProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionMaxProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionMaxProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionMaxProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionMaxProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionMaxProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionMaxProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionMaxProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionMaxProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionMaxSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_SIZE_ASC',
  SystemsByProjectIdConnectionMaxSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_SIZE_DESC',
  SystemsByProjectIdConnectionMaxStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  SystemsByProjectIdConnectionMaxStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  SystemsByProjectIdConnectionMaxTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_TIMEZONE_ASC',
  SystemsByProjectIdConnectionMaxTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_TIMEZONE_DESC',
  SystemsByProjectIdConnectionMaxUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_UUID_ASC',
  SystemsByProjectIdConnectionMaxUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MAX_UUID_DESC',
  SystemsByProjectIdConnectionMinAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  SystemsByProjectIdConnectionMinAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionMinAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionMinAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  SystemsByProjectIdConnectionMinAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionMinAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionMinAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionMinAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionMinAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionMinAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionMinAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionMinAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionMinAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionMinAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionMinClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionMinClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionMinConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionMinConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionMinCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SystemsByProjectIdConnectionMinCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SystemsByProjectIdConnectionMinIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  SystemsByProjectIdConnectionMinIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  SystemsByProjectIdConnectionMinInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionMinInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionMinIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionMinIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionMinLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionMinLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionMinMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_MONITORED_ASC',
  SystemsByProjectIdConnectionMinMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_MONITORED_DESC',
  SystemsByProjectIdConnectionMinNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NAME_ASC',
  SystemsByProjectIdConnectionMinNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NAME_DESC',
  SystemsByProjectIdConnectionMinNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NOTES_ASC',
  SystemsByProjectIdConnectionMinNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NOTES_DESC',
  SystemsByProjectIdConnectionMinNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NUMBER_ASC',
  SystemsByProjectIdConnectionMinNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_NUMBER_DESC',
  SystemsByProjectIdConnectionMinOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionMinOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionMinOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionMinOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionMinProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionMinProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionMinProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionMinProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionMinProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionMinProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionMinProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionMinProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionMinProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionMinProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionMinSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_SIZE_ASC',
  SystemsByProjectIdConnectionMinSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_SIZE_DESC',
  SystemsByProjectIdConnectionMinStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  SystemsByProjectIdConnectionMinStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  SystemsByProjectIdConnectionMinTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_TIMEZONE_ASC',
  SystemsByProjectIdConnectionMinTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_TIMEZONE_DESC',
  SystemsByProjectIdConnectionMinUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_UUID_ASC',
  SystemsByProjectIdConnectionMinUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_MIN_UUID_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionStddevPopulationAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionStddevPopulationAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionStddevPopulationClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionStddevPopulationClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionStddevPopulationConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionStddevPopulationConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemsByProjectIdConnectionStddevPopulationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemsByProjectIdConnectionStddevPopulationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemsByProjectIdConnectionStddevPopulationInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionStddevPopulationInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionStddevPopulationIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionStddevPopulationIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionStddevPopulationLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionStddevPopulationLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionStddevPopulationMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MONITORED_ASC',
  SystemsByProjectIdConnectionStddevPopulationMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MONITORED_DESC',
  SystemsByProjectIdConnectionStddevPopulationNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  SystemsByProjectIdConnectionStddevPopulationNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  SystemsByProjectIdConnectionStddevPopulationNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  SystemsByProjectIdConnectionStddevPopulationNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  SystemsByProjectIdConnectionStddevPopulationNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMBER_ASC',
  SystemsByProjectIdConnectionStddevPopulationNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NUMBER_DESC',
  SystemsByProjectIdConnectionStddevPopulationOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionStddevPopulationOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionStddevPopulationOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionStddevPopulationOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionStddevPopulationProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionStddevPopulationProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionStddevPopulationProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionStddevPopulationProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionStddevPopulationProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionStddevPopulationProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionStddevPopulationProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionStddevPopulationProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionStddevPopulationProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionStddevPopulationProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionStddevPopulationSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SIZE_ASC',
  SystemsByProjectIdConnectionStddevPopulationSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SIZE_DESC',
  SystemsByProjectIdConnectionStddevPopulationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SystemsByProjectIdConnectionStddevPopulationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SystemsByProjectIdConnectionStddevPopulationTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMEZONE_ASC',
  SystemsByProjectIdConnectionStddevPopulationTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMEZONE_DESC',
  SystemsByProjectIdConnectionStddevPopulationUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  SystemsByProjectIdConnectionStddevPopulationUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionStddevSampleAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionStddevSampleAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionStddevSampleClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionStddevSampleClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionStddevSampleConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionStddevSampleConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionStddevSampleCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemsByProjectIdConnectionStddevSampleCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemsByProjectIdConnectionStddevSampleIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemsByProjectIdConnectionStddevSampleIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemsByProjectIdConnectionStddevSampleInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionStddevSampleInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionStddevSampleIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionStddevSampleIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionStddevSampleLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionStddevSampleLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionStddevSampleMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MONITORED_ASC',
  SystemsByProjectIdConnectionStddevSampleMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MONITORED_DESC',
  SystemsByProjectIdConnectionStddevSampleNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  SystemsByProjectIdConnectionStddevSampleNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  SystemsByProjectIdConnectionStddevSampleNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  SystemsByProjectIdConnectionStddevSampleNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  SystemsByProjectIdConnectionStddevSampleNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMBER_ASC',
  SystemsByProjectIdConnectionStddevSampleNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NUMBER_DESC',
  SystemsByProjectIdConnectionStddevSampleOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionStddevSampleOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionStddevSampleOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionStddevSampleOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionStddevSampleProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionStddevSampleProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionStddevSampleProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionStddevSampleProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionStddevSampleProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionStddevSampleProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionStddevSampleProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionStddevSampleProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionStddevSampleProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionStddevSampleProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionStddevSampleSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SIZE_ASC',
  SystemsByProjectIdConnectionStddevSampleSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SIZE_DESC',
  SystemsByProjectIdConnectionStddevSampleStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SystemsByProjectIdConnectionStddevSampleStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SystemsByProjectIdConnectionStddevSampleTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMEZONE_ASC',
  SystemsByProjectIdConnectionStddevSampleTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMEZONE_DESC',
  SystemsByProjectIdConnectionStddevSampleUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  SystemsByProjectIdConnectionStddevSampleUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  SystemsByProjectIdConnectionSumAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  SystemsByProjectIdConnectionSumAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionSumAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionSumAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  SystemsByProjectIdConnectionSumAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionSumAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionSumAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionSumAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionSumAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionSumAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionSumAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionSumAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionSumAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionSumAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionSumClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionSumClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionSumConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionSumConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionSumCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SystemsByProjectIdConnectionSumCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SystemsByProjectIdConnectionSumIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  SystemsByProjectIdConnectionSumIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  SystemsByProjectIdConnectionSumInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionSumInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionSumIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionSumIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionSumLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionSumLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionSumMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_MONITORED_ASC',
  SystemsByProjectIdConnectionSumMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_MONITORED_DESC',
  SystemsByProjectIdConnectionSumNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NAME_ASC',
  SystemsByProjectIdConnectionSumNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NAME_DESC',
  SystemsByProjectIdConnectionSumNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NOTES_ASC',
  SystemsByProjectIdConnectionSumNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NOTES_DESC',
  SystemsByProjectIdConnectionSumNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NUMBER_ASC',
  SystemsByProjectIdConnectionSumNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_NUMBER_DESC',
  SystemsByProjectIdConnectionSumOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionSumOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionSumOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionSumOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionSumProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionSumProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionSumProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionSumProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionSumProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionSumProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionSumProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionSumProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionSumProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionSumProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionSumSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_SIZE_ASC',
  SystemsByProjectIdConnectionSumSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_SIZE_DESC',
  SystemsByProjectIdConnectionSumStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  SystemsByProjectIdConnectionSumStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  SystemsByProjectIdConnectionSumTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_TIMEZONE_ASC',
  SystemsByProjectIdConnectionSumTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_TIMEZONE_DESC',
  SystemsByProjectIdConnectionSumUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_UUID_ASC',
  SystemsByProjectIdConnectionSumUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_SUM_UUID_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionVariancePopulationAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionVariancePopulationAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionVariancePopulationClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionVariancePopulationClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionVariancePopulationConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionVariancePopulationConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemsByProjectIdConnectionVariancePopulationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemsByProjectIdConnectionVariancePopulationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemsByProjectIdConnectionVariancePopulationInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionVariancePopulationInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionVariancePopulationIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionVariancePopulationIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionVariancePopulationLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionVariancePopulationLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionVariancePopulationMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MONITORED_ASC',
  SystemsByProjectIdConnectionVariancePopulationMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MONITORED_DESC',
  SystemsByProjectIdConnectionVariancePopulationNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  SystemsByProjectIdConnectionVariancePopulationNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  SystemsByProjectIdConnectionVariancePopulationNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  SystemsByProjectIdConnectionVariancePopulationNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  SystemsByProjectIdConnectionVariancePopulationNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMBER_ASC',
  SystemsByProjectIdConnectionVariancePopulationNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NUMBER_DESC',
  SystemsByProjectIdConnectionVariancePopulationOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionVariancePopulationOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionVariancePopulationOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionVariancePopulationOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionVariancePopulationProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionVariancePopulationProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionVariancePopulationProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionVariancePopulationProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionVariancePopulationProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionVariancePopulationProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionVariancePopulationProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionVariancePopulationProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionVariancePopulationProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionVariancePopulationProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionVariancePopulationSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SIZE_ASC',
  SystemsByProjectIdConnectionVariancePopulationSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SIZE_DESC',
  SystemsByProjectIdConnectionVariancePopulationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SystemsByProjectIdConnectionVariancePopulationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SystemsByProjectIdConnectionVariancePopulationTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMEZONE_ASC',
  SystemsByProjectIdConnectionVariancePopulationTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMEZONE_DESC',
  SystemsByProjectIdConnectionVariancePopulationUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  SystemsByProjectIdConnectionVariancePopulationUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressCityAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressCityDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressForcedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressForcedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressNormalizedAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressNormalizedDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressStateAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressStateDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressStreetAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressStreetDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_DESC',
  SystemsByProjectIdConnectionVarianceSampleAddressZipAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsByProjectIdConnectionVarianceSampleAddressZipDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsByProjectIdConnectionVarianceSampleClientTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_ASC',
  SystemsByProjectIdConnectionVarianceSampleClientTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_DESC',
  SystemsByProjectIdConnectionVarianceSampleConnectionTypeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsByProjectIdConnectionVarianceSampleConnectionTypeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemsByProjectIdConnectionVarianceSampleIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemsByProjectIdConnectionVarianceSampleIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemsByProjectIdConnectionVarianceSampleInstalledAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_ASC',
  SystemsByProjectIdConnectionVarianceSampleInstalledAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_DESC',
  SystemsByProjectIdConnectionVarianceSampleIntegrationIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  SystemsByProjectIdConnectionVarianceSampleIntegrationIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  SystemsByProjectIdConnectionVarianceSampleLastReportAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsByProjectIdConnectionVarianceSampleLastReportAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsByProjectIdConnectionVarianceSampleMonitoredAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MONITORED_ASC',
  SystemsByProjectIdConnectionVarianceSampleMonitoredDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MONITORED_DESC',
  SystemsByProjectIdConnectionVarianceSampleNameAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  SystemsByProjectIdConnectionVarianceSampleNameDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  SystemsByProjectIdConnectionVarianceSampleNotesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  SystemsByProjectIdConnectionVarianceSampleNotesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  SystemsByProjectIdConnectionVarianceSampleNumberAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMBER_ASC',
  SystemsByProjectIdConnectionVarianceSampleNumberDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NUMBER_DESC',
  SystemsByProjectIdConnectionVarianceSampleOperationalAtAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsByProjectIdConnectionVarianceSampleOperationalAtDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsByProjectIdConnectionVarianceSampleOperationStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_ASC',
  SystemsByProjectIdConnectionVarianceSampleOperationStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_DESC',
  SystemsByProjectIdConnectionVarianceSampleProfileIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemsByProjectIdConnectionVarianceSampleProfileIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  SystemsByProjectIdConnectionVarianceSampleProfileOverridesAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsByProjectIdConnectionVarianceSampleProfileOverridesDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsByProjectIdConnectionVarianceSampleProjectIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SystemsByProjectIdConnectionVarianceSampleProjectIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SystemsByProjectIdConnectionVarianceSampleProviderIdAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_ASC',
  SystemsByProjectIdConnectionVarianceSampleProviderIdDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_DESC',
  SystemsByProjectIdConnectionVarianceSampleProviderStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsByProjectIdConnectionVarianceSampleProviderStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsByProjectIdConnectionVarianceSampleSizeAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_ASC',
  SystemsByProjectIdConnectionVarianceSampleSizeDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SIZE_DESC',
  SystemsByProjectIdConnectionVarianceSampleStatusAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SystemsByProjectIdConnectionVarianceSampleStatusDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SystemsByProjectIdConnectionVarianceSampleTimezoneAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_ASC',
  SystemsByProjectIdConnectionVarianceSampleTimezoneDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_DESC',
  SystemsByProjectIdConnectionVarianceSampleUuidAsc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  SystemsByProjectIdConnectionVarianceSampleUuidDesc = 'SYSTEMS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  TasksConnectionArrayAggAddressAsc = 'TASKS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TasksConnectionArrayAggAddressDesc = 'TASKS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TasksConnectionArrayAggAssigneeIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TasksConnectionArrayAggAssigneeIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TasksConnectionArrayAggCalculatedStatusAsc = 'TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TasksConnectionArrayAggCalculatedStatusDesc = 'TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TasksConnectionArrayAggCompanyIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TasksConnectionArrayAggCompanyIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TasksConnectionArrayAggCompletionDateAsc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TasksConnectionArrayAggCompletionDateDesc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TasksConnectionArrayAggCompletionTimeAsc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TasksConnectionArrayAggCompletionTimeDesc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TasksConnectionArrayAggCompletionTimeInSAsc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionArrayAggCompletionTimeInSDesc = 'TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionArrayAggCreatedAtAsc = 'TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TasksConnectionArrayAggCreatedAtDesc = 'TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TasksConnectionArrayAggCreatedByAsc = 'TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TasksConnectionArrayAggCreatedByDesc = 'TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TasksConnectionArrayAggDescriptionAsc = 'TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TasksConnectionArrayAggDescriptionDesc = 'TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TasksConnectionArrayAggEndDateAllDayAsc = 'TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TasksConnectionArrayAggEndDateAllDayDesc = 'TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TasksConnectionArrayAggEndDateAsc = 'TASKS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TasksConnectionArrayAggEndDateDesc = 'TASKS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TasksConnectionArrayAggFirstLabelIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TasksConnectionArrayAggFirstLabelIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TasksConnectionArrayAggFormsAsc = 'TASKS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TasksConnectionArrayAggFormsDesc = 'TASKS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TasksConnectionArrayAggIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  TasksConnectionArrayAggIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  TasksConnectionArrayAggIsArchivedAsc = 'TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TasksConnectionArrayAggIsArchivedDesc = 'TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TasksConnectionArrayAggIsColoredAsc = 'TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TasksConnectionArrayAggIsColoredDesc = 'TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TasksConnectionArrayAggIsCompletedAsc = 'TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TasksConnectionArrayAggIsCompletedDesc = 'TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TasksConnectionArrayAggIsFieldAsc = 'TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TasksConnectionArrayAggIsFieldDesc = 'TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TasksConnectionArrayAggIsTemplateAsc = 'TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TasksConnectionArrayAggIsTemplateDesc = 'TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TasksConnectionArrayAggJobIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TasksConnectionArrayAggJobIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TasksConnectionArrayAggModifiedAtAsc = 'TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TasksConnectionArrayAggModifiedAtDesc = 'TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TasksConnectionArrayAggNextVisitIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TasksConnectionArrayAggNextVisitIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TasksConnectionArrayAggPositionAsc = 'TASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TasksConnectionArrayAggPositionDesc = 'TASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TasksConnectionArrayAggPriorityAsc = 'TASKS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TasksConnectionArrayAggPriorityDesc = 'TASKS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TasksConnectionArrayAggProjectIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TasksConnectionArrayAggProjectIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TasksConnectionArrayAggProjectStageIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TasksConnectionArrayAggProjectStageIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TasksConnectionArrayAggStartDateAllDayAsc = 'TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TasksConnectionArrayAggStartDateAllDayDesc = 'TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TasksConnectionArrayAggStartDateAsc = 'TASKS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TasksConnectionArrayAggStartDateDesc = 'TASKS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TasksConnectionArrayAggStatusAsc = 'TASKS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TasksConnectionArrayAggStatusDesc = 'TASKS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TasksConnectionArrayAggSystemIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TasksConnectionArrayAggSystemIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TasksConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionArrayAggTemplatePropertyIdsAsc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionArrayAggTemplatePropertyIdsDesc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionArrayAggTemplateTaskIdAsc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TasksConnectionArrayAggTemplateTaskIdDesc = 'TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TasksConnectionArrayAggTitleAsc = 'TASKS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TasksConnectionArrayAggTitleDesc = 'TASKS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TasksConnectionArrayAggUidAsc = 'TASKS_CONNECTION_ARRAY_AGG_UID_ASC',
  TasksConnectionArrayAggUidDesc = 'TASKS_CONNECTION_ARRAY_AGG_UID_DESC',
  TasksConnectionArrayAggVirtualPropertiesAsc = 'TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionArrayAggVirtualPropertiesDesc = 'TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionAverageAddressAsc = 'TASKS_CONNECTION_AVERAGE_ADDRESS_ASC',
  TasksConnectionAverageAddressDesc = 'TASKS_CONNECTION_AVERAGE_ADDRESS_DESC',
  TasksConnectionAverageAssigneeIdAsc = 'TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TasksConnectionAverageAssigneeIdDesc = 'TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TasksConnectionAverageCalculatedStatusAsc = 'TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TasksConnectionAverageCalculatedStatusDesc = 'TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TasksConnectionAverageCompanyIdAsc = 'TASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TasksConnectionAverageCompanyIdDesc = 'TASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TasksConnectionAverageCompletionDateAsc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TasksConnectionAverageCompletionDateDesc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TasksConnectionAverageCompletionTimeAsc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TasksConnectionAverageCompletionTimeDesc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TasksConnectionAverageCompletionTimeInSAsc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionAverageCompletionTimeInSDesc = 'TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionAverageCreatedAtAsc = 'TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TasksConnectionAverageCreatedAtDesc = 'TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TasksConnectionAverageCreatedByAsc = 'TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TasksConnectionAverageCreatedByDesc = 'TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TasksConnectionAverageDescriptionAsc = 'TASKS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TasksConnectionAverageDescriptionDesc = 'TASKS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TasksConnectionAverageEndDateAllDayAsc = 'TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TasksConnectionAverageEndDateAllDayDesc = 'TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TasksConnectionAverageEndDateAsc = 'TASKS_CONNECTION_AVERAGE_END_DATE_ASC',
  TasksConnectionAverageEndDateDesc = 'TASKS_CONNECTION_AVERAGE_END_DATE_DESC',
  TasksConnectionAverageFirstLabelIdAsc = 'TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TasksConnectionAverageFirstLabelIdDesc = 'TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TasksConnectionAverageFormsAsc = 'TASKS_CONNECTION_AVERAGE_FORMS_ASC',
  TasksConnectionAverageFormsDesc = 'TASKS_CONNECTION_AVERAGE_FORMS_DESC',
  TasksConnectionAverageIdAsc = 'TASKS_CONNECTION_AVERAGE_ID_ASC',
  TasksConnectionAverageIdDesc = 'TASKS_CONNECTION_AVERAGE_ID_DESC',
  TasksConnectionAverageIsArchivedAsc = 'TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TasksConnectionAverageIsArchivedDesc = 'TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TasksConnectionAverageIsColoredAsc = 'TASKS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TasksConnectionAverageIsColoredDesc = 'TASKS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TasksConnectionAverageIsCompletedAsc = 'TASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TasksConnectionAverageIsCompletedDesc = 'TASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TasksConnectionAverageIsFieldAsc = 'TASKS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TasksConnectionAverageIsFieldDesc = 'TASKS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TasksConnectionAverageIsTemplateAsc = 'TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TasksConnectionAverageIsTemplateDesc = 'TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TasksConnectionAverageJobIdAsc = 'TASKS_CONNECTION_AVERAGE_JOB_ID_ASC',
  TasksConnectionAverageJobIdDesc = 'TASKS_CONNECTION_AVERAGE_JOB_ID_DESC',
  TasksConnectionAverageModifiedAtAsc = 'TASKS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TasksConnectionAverageModifiedAtDesc = 'TASKS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TasksConnectionAverageNextVisitIdAsc = 'TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TasksConnectionAverageNextVisitIdDesc = 'TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TasksConnectionAveragePositionAsc = 'TASKS_CONNECTION_AVERAGE_POSITION_ASC',
  TasksConnectionAveragePositionDesc = 'TASKS_CONNECTION_AVERAGE_POSITION_DESC',
  TasksConnectionAveragePriorityAsc = 'TASKS_CONNECTION_AVERAGE_PRIORITY_ASC',
  TasksConnectionAveragePriorityDesc = 'TASKS_CONNECTION_AVERAGE_PRIORITY_DESC',
  TasksConnectionAverageProjectIdAsc = 'TASKS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TasksConnectionAverageProjectIdDesc = 'TASKS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TasksConnectionAverageProjectStageIdAsc = 'TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TasksConnectionAverageProjectStageIdDesc = 'TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TasksConnectionAverageStartDateAllDayAsc = 'TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TasksConnectionAverageStartDateAllDayDesc = 'TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TasksConnectionAverageStartDateAsc = 'TASKS_CONNECTION_AVERAGE_START_DATE_ASC',
  TasksConnectionAverageStartDateDesc = 'TASKS_CONNECTION_AVERAGE_START_DATE_DESC',
  TasksConnectionAverageStatusAsc = 'TASKS_CONNECTION_AVERAGE_STATUS_ASC',
  TasksConnectionAverageStatusDesc = 'TASKS_CONNECTION_AVERAGE_STATUS_DESC',
  TasksConnectionAverageSystemIdAsc = 'TASKS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TasksConnectionAverageSystemIdDesc = 'TASKS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TasksConnectionAverageTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionAverageTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionAverageTemplatePropertyIdsAsc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionAverageTemplatePropertyIdsDesc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionAverageTemplateTaskIdAsc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TasksConnectionAverageTemplateTaskIdDesc = 'TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TasksConnectionAverageTitleAsc = 'TASKS_CONNECTION_AVERAGE_TITLE_ASC',
  TasksConnectionAverageTitleDesc = 'TASKS_CONNECTION_AVERAGE_TITLE_DESC',
  TasksConnectionAverageUidAsc = 'TASKS_CONNECTION_AVERAGE_UID_ASC',
  TasksConnectionAverageUidDesc = 'TASKS_CONNECTION_AVERAGE_UID_DESC',
  TasksConnectionAverageVirtualPropertiesAsc = 'TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionAverageVirtualPropertiesDesc = 'TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionCountAsc = 'TASKS_CONNECTION_COUNT_ASC',
  TasksConnectionCountDesc = 'TASKS_CONNECTION_COUNT_DESC',
  TasksConnectionDistinctCountAddressAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TasksConnectionDistinctCountAddressDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TasksConnectionDistinctCountAssigneeIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TasksConnectionDistinctCountAssigneeIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TasksConnectionDistinctCountCalculatedStatusAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TasksConnectionDistinctCountCalculatedStatusDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TasksConnectionDistinctCountCompanyIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TasksConnectionDistinctCountCompanyIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TasksConnectionDistinctCountCompletionDateAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TasksConnectionDistinctCountCompletionDateDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TasksConnectionDistinctCountCompletionTimeAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TasksConnectionDistinctCountCompletionTimeDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TasksConnectionDistinctCountCompletionTimeInSAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionDistinctCountCompletionTimeInSDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionDistinctCountCreatedAtAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TasksConnectionDistinctCountCreatedAtDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TasksConnectionDistinctCountCreatedByAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TasksConnectionDistinctCountCreatedByDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TasksConnectionDistinctCountDescriptionAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TasksConnectionDistinctCountDescriptionDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TasksConnectionDistinctCountEndDateAllDayAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TasksConnectionDistinctCountEndDateAllDayDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TasksConnectionDistinctCountEndDateAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TasksConnectionDistinctCountEndDateDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TasksConnectionDistinctCountFirstLabelIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TasksConnectionDistinctCountFirstLabelIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TasksConnectionDistinctCountFormsAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TasksConnectionDistinctCountFormsDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TasksConnectionDistinctCountIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TasksConnectionDistinctCountIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TasksConnectionDistinctCountIsArchivedAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TasksConnectionDistinctCountIsArchivedDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TasksConnectionDistinctCountIsColoredAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TasksConnectionDistinctCountIsColoredDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TasksConnectionDistinctCountIsCompletedAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TasksConnectionDistinctCountIsCompletedDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TasksConnectionDistinctCountIsFieldAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TasksConnectionDistinctCountIsFieldDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TasksConnectionDistinctCountIsTemplateAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TasksConnectionDistinctCountIsTemplateDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TasksConnectionDistinctCountJobIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TasksConnectionDistinctCountJobIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TasksConnectionDistinctCountModifiedAtAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TasksConnectionDistinctCountModifiedAtDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TasksConnectionDistinctCountNextVisitIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TasksConnectionDistinctCountNextVisitIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TasksConnectionDistinctCountPositionAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TasksConnectionDistinctCountPositionDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TasksConnectionDistinctCountPriorityAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TasksConnectionDistinctCountPriorityDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TasksConnectionDistinctCountProjectIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TasksConnectionDistinctCountProjectIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TasksConnectionDistinctCountProjectStageIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TasksConnectionDistinctCountProjectStageIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TasksConnectionDistinctCountStartDateAllDayAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TasksConnectionDistinctCountStartDateAllDayDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TasksConnectionDistinctCountStartDateAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TasksConnectionDistinctCountStartDateDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TasksConnectionDistinctCountStatusAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TasksConnectionDistinctCountStatusDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TasksConnectionDistinctCountSystemIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TasksConnectionDistinctCountSystemIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TasksConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionDistinctCountTemplatePropertyIdsAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionDistinctCountTemplatePropertyIdsDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionDistinctCountTemplateTaskIdAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TasksConnectionDistinctCountTemplateTaskIdDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TasksConnectionDistinctCountTitleAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TasksConnectionDistinctCountTitleDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TasksConnectionDistinctCountUidAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TasksConnectionDistinctCountUidDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TasksConnectionDistinctCountVirtualPropertiesAsc = 'TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionDistinctCountVirtualPropertiesDesc = 'TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionMaxAddressAsc = 'TASKS_CONNECTION_MAX_ADDRESS_ASC',
  TasksConnectionMaxAddressDesc = 'TASKS_CONNECTION_MAX_ADDRESS_DESC',
  TasksConnectionMaxAssigneeIdAsc = 'TASKS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TasksConnectionMaxAssigneeIdDesc = 'TASKS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TasksConnectionMaxCalculatedStatusAsc = 'TASKS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TasksConnectionMaxCalculatedStatusDesc = 'TASKS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TasksConnectionMaxCompanyIdAsc = 'TASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  TasksConnectionMaxCompanyIdDesc = 'TASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  TasksConnectionMaxCompletionDateAsc = 'TASKS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TasksConnectionMaxCompletionDateDesc = 'TASKS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TasksConnectionMaxCompletionTimeAsc = 'TASKS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TasksConnectionMaxCompletionTimeDesc = 'TASKS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TasksConnectionMaxCompletionTimeInSAsc = 'TASKS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionMaxCompletionTimeInSDesc = 'TASKS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionMaxCreatedAtAsc = 'TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  TasksConnectionMaxCreatedAtDesc = 'TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  TasksConnectionMaxCreatedByAsc = 'TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  TasksConnectionMaxCreatedByDesc = 'TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  TasksConnectionMaxDescriptionAsc = 'TASKS_CONNECTION_MAX_DESCRIPTION_ASC',
  TasksConnectionMaxDescriptionDesc = 'TASKS_CONNECTION_MAX_DESCRIPTION_DESC',
  TasksConnectionMaxEndDateAllDayAsc = 'TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TasksConnectionMaxEndDateAllDayDesc = 'TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TasksConnectionMaxEndDateAsc = 'TASKS_CONNECTION_MAX_END_DATE_ASC',
  TasksConnectionMaxEndDateDesc = 'TASKS_CONNECTION_MAX_END_DATE_DESC',
  TasksConnectionMaxFirstLabelIdAsc = 'TASKS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TasksConnectionMaxFirstLabelIdDesc = 'TASKS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TasksConnectionMaxFormsAsc = 'TASKS_CONNECTION_MAX_FORMS_ASC',
  TasksConnectionMaxFormsDesc = 'TASKS_CONNECTION_MAX_FORMS_DESC',
  TasksConnectionMaxIdAsc = 'TASKS_CONNECTION_MAX_ID_ASC',
  TasksConnectionMaxIdDesc = 'TASKS_CONNECTION_MAX_ID_DESC',
  TasksConnectionMaxIsArchivedAsc = 'TASKS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TasksConnectionMaxIsArchivedDesc = 'TASKS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TasksConnectionMaxIsColoredAsc = 'TASKS_CONNECTION_MAX_IS_COLORED_ASC',
  TasksConnectionMaxIsColoredDesc = 'TASKS_CONNECTION_MAX_IS_COLORED_DESC',
  TasksConnectionMaxIsCompletedAsc = 'TASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  TasksConnectionMaxIsCompletedDesc = 'TASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  TasksConnectionMaxIsFieldAsc = 'TASKS_CONNECTION_MAX_IS_FIELD_ASC',
  TasksConnectionMaxIsFieldDesc = 'TASKS_CONNECTION_MAX_IS_FIELD_DESC',
  TasksConnectionMaxIsTemplateAsc = 'TASKS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TasksConnectionMaxIsTemplateDesc = 'TASKS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TasksConnectionMaxJobIdAsc = 'TASKS_CONNECTION_MAX_JOB_ID_ASC',
  TasksConnectionMaxJobIdDesc = 'TASKS_CONNECTION_MAX_JOB_ID_DESC',
  TasksConnectionMaxModifiedAtAsc = 'TASKS_CONNECTION_MAX_MODIFIED_AT_ASC',
  TasksConnectionMaxModifiedAtDesc = 'TASKS_CONNECTION_MAX_MODIFIED_AT_DESC',
  TasksConnectionMaxNextVisitIdAsc = 'TASKS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TasksConnectionMaxNextVisitIdDesc = 'TASKS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TasksConnectionMaxPositionAsc = 'TASKS_CONNECTION_MAX_POSITION_ASC',
  TasksConnectionMaxPositionDesc = 'TASKS_CONNECTION_MAX_POSITION_DESC',
  TasksConnectionMaxPriorityAsc = 'TASKS_CONNECTION_MAX_PRIORITY_ASC',
  TasksConnectionMaxPriorityDesc = 'TASKS_CONNECTION_MAX_PRIORITY_DESC',
  TasksConnectionMaxProjectIdAsc = 'TASKS_CONNECTION_MAX_PROJECT_ID_ASC',
  TasksConnectionMaxProjectIdDesc = 'TASKS_CONNECTION_MAX_PROJECT_ID_DESC',
  TasksConnectionMaxProjectStageIdAsc = 'TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TasksConnectionMaxProjectStageIdDesc = 'TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TasksConnectionMaxStartDateAllDayAsc = 'TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TasksConnectionMaxStartDateAllDayDesc = 'TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TasksConnectionMaxStartDateAsc = 'TASKS_CONNECTION_MAX_START_DATE_ASC',
  TasksConnectionMaxStartDateDesc = 'TASKS_CONNECTION_MAX_START_DATE_DESC',
  TasksConnectionMaxStatusAsc = 'TASKS_CONNECTION_MAX_STATUS_ASC',
  TasksConnectionMaxStatusDesc = 'TASKS_CONNECTION_MAX_STATUS_DESC',
  TasksConnectionMaxSystemIdAsc = 'TASKS_CONNECTION_MAX_SYSTEM_ID_ASC',
  TasksConnectionMaxSystemIdDesc = 'TASKS_CONNECTION_MAX_SYSTEM_ID_DESC',
  TasksConnectionMaxTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionMaxTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionMaxTemplatePropertyIdsAsc = 'TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionMaxTemplatePropertyIdsDesc = 'TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionMaxTemplateTaskIdAsc = 'TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TasksConnectionMaxTemplateTaskIdDesc = 'TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TasksConnectionMaxTitleAsc = 'TASKS_CONNECTION_MAX_TITLE_ASC',
  TasksConnectionMaxTitleDesc = 'TASKS_CONNECTION_MAX_TITLE_DESC',
  TasksConnectionMaxUidAsc = 'TASKS_CONNECTION_MAX_UID_ASC',
  TasksConnectionMaxUidDesc = 'TASKS_CONNECTION_MAX_UID_DESC',
  TasksConnectionMaxVirtualPropertiesAsc = 'TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionMaxVirtualPropertiesDesc = 'TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionMinAddressAsc = 'TASKS_CONNECTION_MIN_ADDRESS_ASC',
  TasksConnectionMinAddressDesc = 'TASKS_CONNECTION_MIN_ADDRESS_DESC',
  TasksConnectionMinAssigneeIdAsc = 'TASKS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TasksConnectionMinAssigneeIdDesc = 'TASKS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TasksConnectionMinCalculatedStatusAsc = 'TASKS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TasksConnectionMinCalculatedStatusDesc = 'TASKS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TasksConnectionMinCompanyIdAsc = 'TASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  TasksConnectionMinCompanyIdDesc = 'TASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  TasksConnectionMinCompletionDateAsc = 'TASKS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TasksConnectionMinCompletionDateDesc = 'TASKS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TasksConnectionMinCompletionTimeAsc = 'TASKS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TasksConnectionMinCompletionTimeDesc = 'TASKS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TasksConnectionMinCompletionTimeInSAsc = 'TASKS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionMinCompletionTimeInSDesc = 'TASKS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionMinCreatedAtAsc = 'TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  TasksConnectionMinCreatedAtDesc = 'TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  TasksConnectionMinCreatedByAsc = 'TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  TasksConnectionMinCreatedByDesc = 'TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  TasksConnectionMinDescriptionAsc = 'TASKS_CONNECTION_MIN_DESCRIPTION_ASC',
  TasksConnectionMinDescriptionDesc = 'TASKS_CONNECTION_MIN_DESCRIPTION_DESC',
  TasksConnectionMinEndDateAllDayAsc = 'TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TasksConnectionMinEndDateAllDayDesc = 'TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TasksConnectionMinEndDateAsc = 'TASKS_CONNECTION_MIN_END_DATE_ASC',
  TasksConnectionMinEndDateDesc = 'TASKS_CONNECTION_MIN_END_DATE_DESC',
  TasksConnectionMinFirstLabelIdAsc = 'TASKS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TasksConnectionMinFirstLabelIdDesc = 'TASKS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TasksConnectionMinFormsAsc = 'TASKS_CONNECTION_MIN_FORMS_ASC',
  TasksConnectionMinFormsDesc = 'TASKS_CONNECTION_MIN_FORMS_DESC',
  TasksConnectionMinIdAsc = 'TASKS_CONNECTION_MIN_ID_ASC',
  TasksConnectionMinIdDesc = 'TASKS_CONNECTION_MIN_ID_DESC',
  TasksConnectionMinIsArchivedAsc = 'TASKS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TasksConnectionMinIsArchivedDesc = 'TASKS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TasksConnectionMinIsColoredAsc = 'TASKS_CONNECTION_MIN_IS_COLORED_ASC',
  TasksConnectionMinIsColoredDesc = 'TASKS_CONNECTION_MIN_IS_COLORED_DESC',
  TasksConnectionMinIsCompletedAsc = 'TASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  TasksConnectionMinIsCompletedDesc = 'TASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  TasksConnectionMinIsFieldAsc = 'TASKS_CONNECTION_MIN_IS_FIELD_ASC',
  TasksConnectionMinIsFieldDesc = 'TASKS_CONNECTION_MIN_IS_FIELD_DESC',
  TasksConnectionMinIsTemplateAsc = 'TASKS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TasksConnectionMinIsTemplateDesc = 'TASKS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TasksConnectionMinJobIdAsc = 'TASKS_CONNECTION_MIN_JOB_ID_ASC',
  TasksConnectionMinJobIdDesc = 'TASKS_CONNECTION_MIN_JOB_ID_DESC',
  TasksConnectionMinModifiedAtAsc = 'TASKS_CONNECTION_MIN_MODIFIED_AT_ASC',
  TasksConnectionMinModifiedAtDesc = 'TASKS_CONNECTION_MIN_MODIFIED_AT_DESC',
  TasksConnectionMinNextVisitIdAsc = 'TASKS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TasksConnectionMinNextVisitIdDesc = 'TASKS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TasksConnectionMinPositionAsc = 'TASKS_CONNECTION_MIN_POSITION_ASC',
  TasksConnectionMinPositionDesc = 'TASKS_CONNECTION_MIN_POSITION_DESC',
  TasksConnectionMinPriorityAsc = 'TASKS_CONNECTION_MIN_PRIORITY_ASC',
  TasksConnectionMinPriorityDesc = 'TASKS_CONNECTION_MIN_PRIORITY_DESC',
  TasksConnectionMinProjectIdAsc = 'TASKS_CONNECTION_MIN_PROJECT_ID_ASC',
  TasksConnectionMinProjectIdDesc = 'TASKS_CONNECTION_MIN_PROJECT_ID_DESC',
  TasksConnectionMinProjectStageIdAsc = 'TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TasksConnectionMinProjectStageIdDesc = 'TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TasksConnectionMinStartDateAllDayAsc = 'TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TasksConnectionMinStartDateAllDayDesc = 'TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TasksConnectionMinStartDateAsc = 'TASKS_CONNECTION_MIN_START_DATE_ASC',
  TasksConnectionMinStartDateDesc = 'TASKS_CONNECTION_MIN_START_DATE_DESC',
  TasksConnectionMinStatusAsc = 'TASKS_CONNECTION_MIN_STATUS_ASC',
  TasksConnectionMinStatusDesc = 'TASKS_CONNECTION_MIN_STATUS_DESC',
  TasksConnectionMinSystemIdAsc = 'TASKS_CONNECTION_MIN_SYSTEM_ID_ASC',
  TasksConnectionMinSystemIdDesc = 'TASKS_CONNECTION_MIN_SYSTEM_ID_DESC',
  TasksConnectionMinTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionMinTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionMinTemplatePropertyIdsAsc = 'TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionMinTemplatePropertyIdsDesc = 'TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionMinTemplateTaskIdAsc = 'TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TasksConnectionMinTemplateTaskIdDesc = 'TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TasksConnectionMinTitleAsc = 'TASKS_CONNECTION_MIN_TITLE_ASC',
  TasksConnectionMinTitleDesc = 'TASKS_CONNECTION_MIN_TITLE_DESC',
  TasksConnectionMinUidAsc = 'TASKS_CONNECTION_MIN_UID_ASC',
  TasksConnectionMinUidDesc = 'TASKS_CONNECTION_MIN_UID_DESC',
  TasksConnectionMinVirtualPropertiesAsc = 'TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionMinVirtualPropertiesDesc = 'TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionStddevPopulationAddressAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TasksConnectionStddevPopulationAddressDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TasksConnectionStddevPopulationAssigneeIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TasksConnectionStddevPopulationAssigneeIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TasksConnectionStddevPopulationCalculatedStatusAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TasksConnectionStddevPopulationCalculatedStatusDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TasksConnectionStddevPopulationCompanyIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TasksConnectionStddevPopulationCompanyIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TasksConnectionStddevPopulationCompletionDateAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TasksConnectionStddevPopulationCompletionDateDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TasksConnectionStddevPopulationCompletionTimeAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TasksConnectionStddevPopulationCompletionTimeDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TasksConnectionStddevPopulationCompletionTimeInSAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionStddevPopulationCompletionTimeInSDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionStddevPopulationCreatedAtAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TasksConnectionStddevPopulationCreatedAtDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TasksConnectionStddevPopulationCreatedByAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TasksConnectionStddevPopulationCreatedByDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TasksConnectionStddevPopulationDescriptionAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TasksConnectionStddevPopulationDescriptionDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TasksConnectionStddevPopulationEndDateAllDayAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksConnectionStddevPopulationEndDateAllDayDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksConnectionStddevPopulationEndDateAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TasksConnectionStddevPopulationEndDateDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TasksConnectionStddevPopulationFirstLabelIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TasksConnectionStddevPopulationFirstLabelIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TasksConnectionStddevPopulationFormsAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TasksConnectionStddevPopulationFormsDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TasksConnectionStddevPopulationIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TasksConnectionStddevPopulationIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TasksConnectionStddevPopulationIsArchivedAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TasksConnectionStddevPopulationIsArchivedDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TasksConnectionStddevPopulationIsColoredAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TasksConnectionStddevPopulationIsColoredDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TasksConnectionStddevPopulationIsCompletedAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TasksConnectionStddevPopulationIsCompletedDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TasksConnectionStddevPopulationIsFieldAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TasksConnectionStddevPopulationIsFieldDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TasksConnectionStddevPopulationIsTemplateAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TasksConnectionStddevPopulationIsTemplateDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TasksConnectionStddevPopulationJobIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TasksConnectionStddevPopulationJobIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TasksConnectionStddevPopulationModifiedAtAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TasksConnectionStddevPopulationModifiedAtDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TasksConnectionStddevPopulationNextVisitIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TasksConnectionStddevPopulationNextVisitIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TasksConnectionStddevPopulationPositionAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TasksConnectionStddevPopulationPositionDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TasksConnectionStddevPopulationPriorityAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TasksConnectionStddevPopulationPriorityDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TasksConnectionStddevPopulationProjectIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TasksConnectionStddevPopulationProjectIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TasksConnectionStddevPopulationProjectStageIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksConnectionStddevPopulationProjectStageIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksConnectionStddevPopulationStartDateAllDayAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksConnectionStddevPopulationStartDateAllDayDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksConnectionStddevPopulationStartDateAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TasksConnectionStddevPopulationStartDateDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TasksConnectionStddevPopulationStatusAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TasksConnectionStddevPopulationStatusDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TasksConnectionStddevPopulationSystemIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TasksConnectionStddevPopulationSystemIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TasksConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionStddevPopulationTemplateTaskIdAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksConnectionStddevPopulationTemplateTaskIdDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksConnectionStddevPopulationTitleAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TasksConnectionStddevPopulationTitleDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TasksConnectionStddevPopulationUidAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TasksConnectionStddevPopulationUidDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TasksConnectionStddevPopulationVirtualPropertiesAsc = 'TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionStddevPopulationVirtualPropertiesDesc = 'TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionStddevSampleAddressAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TasksConnectionStddevSampleAddressDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TasksConnectionStddevSampleAssigneeIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TasksConnectionStddevSampleAssigneeIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TasksConnectionStddevSampleCalculatedStatusAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TasksConnectionStddevSampleCalculatedStatusDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TasksConnectionStddevSampleCompanyIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TasksConnectionStddevSampleCompanyIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TasksConnectionStddevSampleCompletionDateAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TasksConnectionStddevSampleCompletionDateDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TasksConnectionStddevSampleCompletionTimeAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TasksConnectionStddevSampleCompletionTimeDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TasksConnectionStddevSampleCompletionTimeInSAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionStddevSampleCompletionTimeInSDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionStddevSampleCreatedAtAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TasksConnectionStddevSampleCreatedAtDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TasksConnectionStddevSampleCreatedByAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TasksConnectionStddevSampleCreatedByDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TasksConnectionStddevSampleDescriptionAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TasksConnectionStddevSampleDescriptionDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TasksConnectionStddevSampleEndDateAllDayAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksConnectionStddevSampleEndDateAllDayDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksConnectionStddevSampleEndDateAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TasksConnectionStddevSampleEndDateDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TasksConnectionStddevSampleFirstLabelIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksConnectionStddevSampleFirstLabelIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksConnectionStddevSampleFormsAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TasksConnectionStddevSampleFormsDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TasksConnectionStddevSampleIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TasksConnectionStddevSampleIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TasksConnectionStddevSampleIsArchivedAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TasksConnectionStddevSampleIsArchivedDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TasksConnectionStddevSampleIsColoredAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TasksConnectionStddevSampleIsColoredDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TasksConnectionStddevSampleIsCompletedAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TasksConnectionStddevSampleIsCompletedDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TasksConnectionStddevSampleIsFieldAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TasksConnectionStddevSampleIsFieldDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TasksConnectionStddevSampleIsTemplateAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TasksConnectionStddevSampleIsTemplateDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TasksConnectionStddevSampleJobIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TasksConnectionStddevSampleJobIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TasksConnectionStddevSampleModifiedAtAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TasksConnectionStddevSampleModifiedAtDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TasksConnectionStddevSampleNextVisitIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksConnectionStddevSampleNextVisitIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksConnectionStddevSamplePositionAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TasksConnectionStddevSamplePositionDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TasksConnectionStddevSamplePriorityAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TasksConnectionStddevSamplePriorityDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TasksConnectionStddevSampleProjectIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TasksConnectionStddevSampleProjectIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TasksConnectionStddevSampleProjectStageIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksConnectionStddevSampleProjectStageIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksConnectionStddevSampleStartDateAllDayAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksConnectionStddevSampleStartDateAllDayDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksConnectionStddevSampleStartDateAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TasksConnectionStddevSampleStartDateDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TasksConnectionStddevSampleStatusAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TasksConnectionStddevSampleStatusDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TasksConnectionStddevSampleSystemIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TasksConnectionStddevSampleSystemIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TasksConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionStddevSampleTemplatePropertyIdsAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionStddevSampleTemplatePropertyIdsDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionStddevSampleTemplateTaskIdAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksConnectionStddevSampleTemplateTaskIdDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksConnectionStddevSampleTitleAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TasksConnectionStddevSampleTitleDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TasksConnectionStddevSampleUidAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TasksConnectionStddevSampleUidDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TasksConnectionStddevSampleVirtualPropertiesAsc = 'TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionStddevSampleVirtualPropertiesDesc = 'TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionSumAddressAsc = 'TASKS_CONNECTION_SUM_ADDRESS_ASC',
  TasksConnectionSumAddressDesc = 'TASKS_CONNECTION_SUM_ADDRESS_DESC',
  TasksConnectionSumAssigneeIdAsc = 'TASKS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TasksConnectionSumAssigneeIdDesc = 'TASKS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TasksConnectionSumCalculatedStatusAsc = 'TASKS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TasksConnectionSumCalculatedStatusDesc = 'TASKS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TasksConnectionSumCompanyIdAsc = 'TASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  TasksConnectionSumCompanyIdDesc = 'TASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  TasksConnectionSumCompletionDateAsc = 'TASKS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TasksConnectionSumCompletionDateDesc = 'TASKS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TasksConnectionSumCompletionTimeAsc = 'TASKS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TasksConnectionSumCompletionTimeDesc = 'TASKS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TasksConnectionSumCompletionTimeInSAsc = 'TASKS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionSumCompletionTimeInSDesc = 'TASKS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionSumCreatedAtAsc = 'TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  TasksConnectionSumCreatedAtDesc = 'TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  TasksConnectionSumCreatedByAsc = 'TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  TasksConnectionSumCreatedByDesc = 'TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  TasksConnectionSumDescriptionAsc = 'TASKS_CONNECTION_SUM_DESCRIPTION_ASC',
  TasksConnectionSumDescriptionDesc = 'TASKS_CONNECTION_SUM_DESCRIPTION_DESC',
  TasksConnectionSumEndDateAllDayAsc = 'TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TasksConnectionSumEndDateAllDayDesc = 'TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TasksConnectionSumEndDateAsc = 'TASKS_CONNECTION_SUM_END_DATE_ASC',
  TasksConnectionSumEndDateDesc = 'TASKS_CONNECTION_SUM_END_DATE_DESC',
  TasksConnectionSumFirstLabelIdAsc = 'TASKS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TasksConnectionSumFirstLabelIdDesc = 'TASKS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TasksConnectionSumFormsAsc = 'TASKS_CONNECTION_SUM_FORMS_ASC',
  TasksConnectionSumFormsDesc = 'TASKS_CONNECTION_SUM_FORMS_DESC',
  TasksConnectionSumIdAsc = 'TASKS_CONNECTION_SUM_ID_ASC',
  TasksConnectionSumIdDesc = 'TASKS_CONNECTION_SUM_ID_DESC',
  TasksConnectionSumIsArchivedAsc = 'TASKS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TasksConnectionSumIsArchivedDesc = 'TASKS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TasksConnectionSumIsColoredAsc = 'TASKS_CONNECTION_SUM_IS_COLORED_ASC',
  TasksConnectionSumIsColoredDesc = 'TASKS_CONNECTION_SUM_IS_COLORED_DESC',
  TasksConnectionSumIsCompletedAsc = 'TASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  TasksConnectionSumIsCompletedDesc = 'TASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  TasksConnectionSumIsFieldAsc = 'TASKS_CONNECTION_SUM_IS_FIELD_ASC',
  TasksConnectionSumIsFieldDesc = 'TASKS_CONNECTION_SUM_IS_FIELD_DESC',
  TasksConnectionSumIsTemplateAsc = 'TASKS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TasksConnectionSumIsTemplateDesc = 'TASKS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TasksConnectionSumJobIdAsc = 'TASKS_CONNECTION_SUM_JOB_ID_ASC',
  TasksConnectionSumJobIdDesc = 'TASKS_CONNECTION_SUM_JOB_ID_DESC',
  TasksConnectionSumModifiedAtAsc = 'TASKS_CONNECTION_SUM_MODIFIED_AT_ASC',
  TasksConnectionSumModifiedAtDesc = 'TASKS_CONNECTION_SUM_MODIFIED_AT_DESC',
  TasksConnectionSumNextVisitIdAsc = 'TASKS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TasksConnectionSumNextVisitIdDesc = 'TASKS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TasksConnectionSumPositionAsc = 'TASKS_CONNECTION_SUM_POSITION_ASC',
  TasksConnectionSumPositionDesc = 'TASKS_CONNECTION_SUM_POSITION_DESC',
  TasksConnectionSumPriorityAsc = 'TASKS_CONNECTION_SUM_PRIORITY_ASC',
  TasksConnectionSumPriorityDesc = 'TASKS_CONNECTION_SUM_PRIORITY_DESC',
  TasksConnectionSumProjectIdAsc = 'TASKS_CONNECTION_SUM_PROJECT_ID_ASC',
  TasksConnectionSumProjectIdDesc = 'TASKS_CONNECTION_SUM_PROJECT_ID_DESC',
  TasksConnectionSumProjectStageIdAsc = 'TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TasksConnectionSumProjectStageIdDesc = 'TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TasksConnectionSumStartDateAllDayAsc = 'TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TasksConnectionSumStartDateAllDayDesc = 'TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TasksConnectionSumStartDateAsc = 'TASKS_CONNECTION_SUM_START_DATE_ASC',
  TasksConnectionSumStartDateDesc = 'TASKS_CONNECTION_SUM_START_DATE_DESC',
  TasksConnectionSumStatusAsc = 'TASKS_CONNECTION_SUM_STATUS_ASC',
  TasksConnectionSumStatusDesc = 'TASKS_CONNECTION_SUM_STATUS_DESC',
  TasksConnectionSumSystemIdAsc = 'TASKS_CONNECTION_SUM_SYSTEM_ID_ASC',
  TasksConnectionSumSystemIdDesc = 'TASKS_CONNECTION_SUM_SYSTEM_ID_DESC',
  TasksConnectionSumTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionSumTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionSumTemplatePropertyIdsAsc = 'TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionSumTemplatePropertyIdsDesc = 'TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionSumTemplateTaskIdAsc = 'TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TasksConnectionSumTemplateTaskIdDesc = 'TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TasksConnectionSumTitleAsc = 'TASKS_CONNECTION_SUM_TITLE_ASC',
  TasksConnectionSumTitleDesc = 'TASKS_CONNECTION_SUM_TITLE_DESC',
  TasksConnectionSumUidAsc = 'TASKS_CONNECTION_SUM_UID_ASC',
  TasksConnectionSumUidDesc = 'TASKS_CONNECTION_SUM_UID_DESC',
  TasksConnectionSumVirtualPropertiesAsc = 'TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionSumVirtualPropertiesDesc = 'TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionVariancePopulationAddressAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TasksConnectionVariancePopulationAddressDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TasksConnectionVariancePopulationAssigneeIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TasksConnectionVariancePopulationAssigneeIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TasksConnectionVariancePopulationCalculatedStatusAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TasksConnectionVariancePopulationCalculatedStatusDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TasksConnectionVariancePopulationCompanyIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TasksConnectionVariancePopulationCompanyIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TasksConnectionVariancePopulationCompletionDateAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TasksConnectionVariancePopulationCompletionDateDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TasksConnectionVariancePopulationCompletionTimeAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TasksConnectionVariancePopulationCompletionTimeDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TasksConnectionVariancePopulationCompletionTimeInSAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionVariancePopulationCompletionTimeInSDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionVariancePopulationCreatedAtAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TasksConnectionVariancePopulationCreatedAtDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TasksConnectionVariancePopulationCreatedByAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TasksConnectionVariancePopulationCreatedByDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TasksConnectionVariancePopulationDescriptionAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TasksConnectionVariancePopulationDescriptionDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TasksConnectionVariancePopulationEndDateAllDayAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksConnectionVariancePopulationEndDateAllDayDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksConnectionVariancePopulationEndDateAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TasksConnectionVariancePopulationEndDateDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TasksConnectionVariancePopulationFirstLabelIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TasksConnectionVariancePopulationFirstLabelIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TasksConnectionVariancePopulationFormsAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TasksConnectionVariancePopulationFormsDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TasksConnectionVariancePopulationIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TasksConnectionVariancePopulationIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TasksConnectionVariancePopulationIsArchivedAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TasksConnectionVariancePopulationIsArchivedDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TasksConnectionVariancePopulationIsColoredAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TasksConnectionVariancePopulationIsColoredDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TasksConnectionVariancePopulationIsCompletedAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TasksConnectionVariancePopulationIsCompletedDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TasksConnectionVariancePopulationIsFieldAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TasksConnectionVariancePopulationIsFieldDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TasksConnectionVariancePopulationIsTemplateAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TasksConnectionVariancePopulationIsTemplateDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TasksConnectionVariancePopulationJobIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TasksConnectionVariancePopulationJobIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TasksConnectionVariancePopulationModifiedAtAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TasksConnectionVariancePopulationModifiedAtDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TasksConnectionVariancePopulationNextVisitIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TasksConnectionVariancePopulationNextVisitIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TasksConnectionVariancePopulationPositionAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TasksConnectionVariancePopulationPositionDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TasksConnectionVariancePopulationPriorityAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TasksConnectionVariancePopulationPriorityDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TasksConnectionVariancePopulationProjectIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TasksConnectionVariancePopulationProjectIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TasksConnectionVariancePopulationProjectStageIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksConnectionVariancePopulationProjectStageIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksConnectionVariancePopulationStartDateAllDayAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksConnectionVariancePopulationStartDateAllDayDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksConnectionVariancePopulationStartDateAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TasksConnectionVariancePopulationStartDateDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TasksConnectionVariancePopulationStatusAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TasksConnectionVariancePopulationStatusDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TasksConnectionVariancePopulationSystemIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TasksConnectionVariancePopulationSystemIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TasksConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionVariancePopulationTemplateTaskIdAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksConnectionVariancePopulationTemplateTaskIdDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksConnectionVariancePopulationTitleAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TasksConnectionVariancePopulationTitleDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TasksConnectionVariancePopulationUidAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TasksConnectionVariancePopulationUidDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TasksConnectionVariancePopulationVirtualPropertiesAsc = 'TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionVariancePopulationVirtualPropertiesDesc = 'TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksConnectionVarianceSampleAddressAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TasksConnectionVarianceSampleAddressDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TasksConnectionVarianceSampleAssigneeIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TasksConnectionVarianceSampleAssigneeIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TasksConnectionVarianceSampleCalculatedStatusAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TasksConnectionVarianceSampleCalculatedStatusDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TasksConnectionVarianceSampleCompanyIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TasksConnectionVarianceSampleCompanyIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TasksConnectionVarianceSampleCompletionDateAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TasksConnectionVarianceSampleCompletionDateDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TasksConnectionVarianceSampleCompletionTimeAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TasksConnectionVarianceSampleCompletionTimeDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TasksConnectionVarianceSampleCompletionTimeInSAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksConnectionVarianceSampleCompletionTimeInSDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksConnectionVarianceSampleCreatedAtAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TasksConnectionVarianceSampleCreatedAtDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TasksConnectionVarianceSampleCreatedByAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TasksConnectionVarianceSampleCreatedByDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TasksConnectionVarianceSampleDescriptionAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TasksConnectionVarianceSampleDescriptionDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TasksConnectionVarianceSampleEndDateAllDayAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksConnectionVarianceSampleEndDateAllDayDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksConnectionVarianceSampleEndDateAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TasksConnectionVarianceSampleEndDateDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TasksConnectionVarianceSampleFirstLabelIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksConnectionVarianceSampleFirstLabelIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksConnectionVarianceSampleFormsAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TasksConnectionVarianceSampleFormsDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TasksConnectionVarianceSampleIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TasksConnectionVarianceSampleIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TasksConnectionVarianceSampleIsArchivedAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TasksConnectionVarianceSampleIsArchivedDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TasksConnectionVarianceSampleIsColoredAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TasksConnectionVarianceSampleIsColoredDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TasksConnectionVarianceSampleIsCompletedAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TasksConnectionVarianceSampleIsCompletedDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TasksConnectionVarianceSampleIsFieldAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TasksConnectionVarianceSampleIsFieldDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TasksConnectionVarianceSampleIsTemplateAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TasksConnectionVarianceSampleIsTemplateDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TasksConnectionVarianceSampleJobIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TasksConnectionVarianceSampleJobIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TasksConnectionVarianceSampleModifiedAtAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TasksConnectionVarianceSampleModifiedAtDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TasksConnectionVarianceSampleNextVisitIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksConnectionVarianceSampleNextVisitIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksConnectionVarianceSamplePositionAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TasksConnectionVarianceSamplePositionDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TasksConnectionVarianceSamplePriorityAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TasksConnectionVarianceSamplePriorityDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TasksConnectionVarianceSampleProjectIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TasksConnectionVarianceSampleProjectIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TasksConnectionVarianceSampleProjectStageIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksConnectionVarianceSampleProjectStageIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksConnectionVarianceSampleStartDateAllDayAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksConnectionVarianceSampleStartDateAllDayDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksConnectionVarianceSampleStartDateAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TasksConnectionVarianceSampleStartDateDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TasksConnectionVarianceSampleStatusAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TasksConnectionVarianceSampleStatusDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TasksConnectionVarianceSampleSystemIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TasksConnectionVarianceSampleSystemIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TasksConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksConnectionVarianceSampleTemplateTaskIdAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksConnectionVarianceSampleTemplateTaskIdDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksConnectionVarianceSampleTitleAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TasksConnectionVarianceSampleTitleDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TasksConnectionVarianceSampleUidAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TasksConnectionVarianceSampleUidDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TasksConnectionVarianceSampleVirtualPropertiesAsc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksConnectionVarianceSampleVirtualPropertiesDesc = 'TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionArrayAggAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionArrayAggAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionArrayAggAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionArrayAggCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionArrayAggCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionArrayAggCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionArrayAggCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionArrayAggCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionArrayAggCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionArrayAggCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionArrayAggCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionArrayAggCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionArrayAggCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionArrayAggCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionArrayAggDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionArrayAggDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionArrayAggEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionArrayAggEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionArrayAggEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskReportsByProjectIdConnectionArrayAggEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskReportsByProjectIdConnectionArrayAggFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TaskReportsByProjectIdConnectionArrayAggFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TaskReportsByProjectIdConnectionArrayAggIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionArrayAggIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionArrayAggIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionArrayAggIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionArrayAggIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionArrayAggIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionArrayAggIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionArrayAggIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionArrayAggIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionArrayAggIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionArrayAggJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionArrayAggLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionArrayAggModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionArrayAggModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionArrayAggNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskReportsByProjectIdConnectionArrayAggPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskReportsByProjectIdConnectionArrayAggPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionArrayAggPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionArrayAggProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionArrayAggStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionArrayAggStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskReportsByProjectIdConnectionArrayAggStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskReportsByProjectIdConnectionArrayAggStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TaskReportsByProjectIdConnectionArrayAggStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TaskReportsByProjectIdConnectionArrayAggSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionArrayAggTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionArrayAggTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionArrayAggTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionArrayAggTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TaskReportsByProjectIdConnectionArrayAggTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TaskReportsByProjectIdConnectionArrayAggUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  TaskReportsByProjectIdConnectionArrayAggUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  TaskReportsByProjectIdConnectionArrayAggVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionArrayAggVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionArrayAggVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionArrayAggVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionArrayAggVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionArrayAggVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionArrayAggVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionArrayAggVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_ARRAY_AGG_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionAverageAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionAverageAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionAverageAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionAverageAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionAverageCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionAverageCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionAverageCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionAverageCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionAverageCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionAverageCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionAverageCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionAverageCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionAverageCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionAverageCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionAverageCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionAverageCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionAverageCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionAverageCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionAverageDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionAverageDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionAverageEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionAverageEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionAverageEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskReportsByProjectIdConnectionAverageEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskReportsByProjectIdConnectionAverageFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionAverageFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionAverageFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FORMS_ASC',
  TaskReportsByProjectIdConnectionAverageFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_FORMS_DESC',
  TaskReportsByProjectIdConnectionAverageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskReportsByProjectIdConnectionAverageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskReportsByProjectIdConnectionAverageIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionAverageIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionAverageIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionAverageIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionAverageIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionAverageIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionAverageIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionAverageIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionAverageIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionAverageIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionAverageJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionAverageJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionAverageLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionAverageLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionAverageLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionAverageLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionAverageModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionAverageModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionAverageNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionAverageNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionAveragePositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskReportsByProjectIdConnectionAveragePositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskReportsByProjectIdConnectionAveragePriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionAveragePriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionAverageProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionAverageProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionAverageProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionAverageProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionAverageStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionAverageStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionAverageStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskReportsByProjectIdConnectionAverageStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskReportsByProjectIdConnectionAverageStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_ASC',
  TaskReportsByProjectIdConnectionAverageStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_STATUS_DESC',
  TaskReportsByProjectIdConnectionAverageSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionAverageSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionAverageTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionAverageTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionAverageTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionAverageTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionAverageTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionAverageTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionAverageTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionAverageTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionAverageTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionAverageTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_ASC',
  TaskReportsByProjectIdConnectionAverageTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_TITLE_DESC',
  TaskReportsByProjectIdConnectionAverageUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_ASC',
  TaskReportsByProjectIdConnectionAverageUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_UID_DESC',
  TaskReportsByProjectIdConnectionAverageVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionAverageVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionAverageVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionAverageVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionAverageVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionAverageVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionAverageVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionAverageVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_AVERAGE_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_COUNT_ASC',
  TaskReportsByProjectIdConnectionCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_COUNT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionDistinctCountCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionDistinctCountDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionDistinctCountDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionDistinctCountEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskReportsByProjectIdConnectionDistinctCountFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionDistinctCountIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionDistinctCountJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionDistinctCountLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionDistinctCountModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionDistinctCountModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskReportsByProjectIdConnectionDistinctCountPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskReportsByProjectIdConnectionDistinctCountPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionDistinctCountPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionDistinctCountProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionDistinctCountStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionDistinctCountStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TaskReportsByProjectIdConnectionDistinctCountUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionDistinctCountVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionDistinctCountVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionDistinctCountVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionDistinctCountVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionDistinctCountVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionDistinctCountVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionDistinctCountVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_DISTINCT_COUNT_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionMaxAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionMaxAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionMaxAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionMaxAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionMaxCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionMaxCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionMaxCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionMaxCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionMaxCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionMaxCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionMaxCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionMaxCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionMaxCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionMaxCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionMaxCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionMaxCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionMaxCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionMaxCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionMaxDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionMaxDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionMaxEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionMaxEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionMaxEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskReportsByProjectIdConnectionMaxEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskReportsByProjectIdConnectionMaxFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionMaxFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionMaxFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FORMS_ASC',
  TaskReportsByProjectIdConnectionMaxFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_FORMS_DESC',
  TaskReportsByProjectIdConnectionMaxIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ID_ASC',
  TaskReportsByProjectIdConnectionMaxIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_ID_DESC',
  TaskReportsByProjectIdConnectionMaxIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionMaxIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionMaxIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionMaxIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionMaxIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionMaxIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionMaxIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionMaxIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionMaxIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionMaxIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionMaxJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionMaxJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionMaxLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionMaxLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionMaxLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionMaxLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionMaxModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionMaxModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionMaxNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionMaxNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionMaxPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_POSITION_ASC',
  TaskReportsByProjectIdConnectionMaxPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_POSITION_DESC',
  TaskReportsByProjectIdConnectionMaxPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionMaxPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionMaxProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionMaxProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionMaxProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionMaxProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionMaxStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionMaxStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionMaxStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskReportsByProjectIdConnectionMaxStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskReportsByProjectIdConnectionMaxStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_ASC',
  TaskReportsByProjectIdConnectionMaxStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_STATUS_DESC',
  TaskReportsByProjectIdConnectionMaxSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionMaxSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionMaxTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionMaxTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionMaxTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionMaxTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionMaxTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionMaxTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionMaxTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionMaxTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionMaxTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionMaxTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_ASC',
  TaskReportsByProjectIdConnectionMaxTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_TITLE_DESC',
  TaskReportsByProjectIdConnectionMaxUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UID_ASC',
  TaskReportsByProjectIdConnectionMaxUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_UID_DESC',
  TaskReportsByProjectIdConnectionMaxVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionMaxVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionMaxVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionMaxVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionMaxVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionMaxVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionMaxVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionMaxVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MAX_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionMinAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionMinAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionMinAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionMinAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionMinCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionMinCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionMinCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionMinCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionMinCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionMinCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionMinCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionMinCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionMinCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionMinCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionMinCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionMinCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionMinCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionMinCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionMinDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionMinDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionMinEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionMinEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionMinEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskReportsByProjectIdConnectionMinEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskReportsByProjectIdConnectionMinFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionMinFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionMinFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FORMS_ASC',
  TaskReportsByProjectIdConnectionMinFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_FORMS_DESC',
  TaskReportsByProjectIdConnectionMinIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ID_ASC',
  TaskReportsByProjectIdConnectionMinIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_ID_DESC',
  TaskReportsByProjectIdConnectionMinIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionMinIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionMinIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionMinIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionMinIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionMinIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionMinIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionMinIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionMinIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionMinIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionMinJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionMinJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionMinLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionMinLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionMinLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionMinLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionMinModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionMinModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionMinNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionMinNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionMinPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_POSITION_ASC',
  TaskReportsByProjectIdConnectionMinPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_POSITION_DESC',
  TaskReportsByProjectIdConnectionMinPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionMinPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionMinProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionMinProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionMinProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionMinProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionMinStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionMinStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionMinStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskReportsByProjectIdConnectionMinStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskReportsByProjectIdConnectionMinStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_ASC',
  TaskReportsByProjectIdConnectionMinStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_STATUS_DESC',
  TaskReportsByProjectIdConnectionMinSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionMinSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionMinTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionMinTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionMinTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionMinTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionMinTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionMinTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionMinTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionMinTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionMinTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionMinTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionMinTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionMinTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionMinTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionMinTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionMinTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionMinTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionMinTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionMinTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionMinTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_ASC',
  TaskReportsByProjectIdConnectionMinTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_TITLE_DESC',
  TaskReportsByProjectIdConnectionMinUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UID_ASC',
  TaskReportsByProjectIdConnectionMinUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_UID_DESC',
  TaskReportsByProjectIdConnectionMinVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionMinVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionMinVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionMinVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionMinVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionMinVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionMinVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionMinVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_MIN_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevPopulationVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevSampleCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionStddevSampleCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionStddevSampleDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionStddevSampleDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionStddevSampleEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionStddevSampleIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionStddevSampleLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionStddevSampleModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevSampleModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevSampleNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSamplePositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskReportsByProjectIdConnectionStddevSamplePositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskReportsByProjectIdConnectionStddevSamplePriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionStddevSamplePriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionStddevSampleProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionStddevSampleStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionStddevSampleTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TaskReportsByProjectIdConnectionStddevSampleUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionStddevSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionStddevSampleVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionStddevSampleVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionStddevSampleVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionStddevSampleVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionStddevSampleVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionStddevSampleVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_STDDEV_SAMPLE_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionSumAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionSumAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionSumAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionSumAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionSumCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionSumCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionSumCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionSumCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionSumCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionSumCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionSumCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionSumCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionSumCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionSumCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionSumCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionSumCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionSumCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionSumCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionSumDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionSumDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionSumEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionSumEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionSumEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskReportsByProjectIdConnectionSumEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskReportsByProjectIdConnectionSumFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionSumFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionSumFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FORMS_ASC',
  TaskReportsByProjectIdConnectionSumFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_FORMS_DESC',
  TaskReportsByProjectIdConnectionSumIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ID_ASC',
  TaskReportsByProjectIdConnectionSumIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_ID_DESC',
  TaskReportsByProjectIdConnectionSumIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionSumIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionSumIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionSumIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionSumIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionSumIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionSumIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionSumIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionSumIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionSumIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionSumJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionSumJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionSumLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionSumLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionSumLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionSumLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionSumModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionSumModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionSumNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionSumNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionSumPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_POSITION_ASC',
  TaskReportsByProjectIdConnectionSumPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_POSITION_DESC',
  TaskReportsByProjectIdConnectionSumPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionSumPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionSumProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionSumProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionSumProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionSumProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionSumStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionSumStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionSumStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskReportsByProjectIdConnectionSumStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskReportsByProjectIdConnectionSumStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_ASC',
  TaskReportsByProjectIdConnectionSumStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_STATUS_DESC',
  TaskReportsByProjectIdConnectionSumSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionSumSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionSumTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionSumTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionSumTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionSumTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionSumTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionSumTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionSumTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionSumTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionSumTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionSumTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionSumTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionSumTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionSumTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionSumTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionSumTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionSumTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionSumTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionSumTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionSumTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_ASC',
  TaskReportsByProjectIdConnectionSumTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_TITLE_DESC',
  TaskReportsByProjectIdConnectionSumUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UID_ASC',
  TaskReportsByProjectIdConnectionSumUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_UID_DESC',
  TaskReportsByProjectIdConnectionSumVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionSumVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionSumVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionSumVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionSumVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionSumVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionSumVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionSumVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_SUM_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationPositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationPositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationPriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationPriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionVariancePopulationVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_POPULATION_VISIT_START_DATE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleAddressAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleAddressDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleAssigneeIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleAssigneeIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCalculatedStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCalculatedStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCompanyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCompanyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionTimeInSAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCompletionTimeInSDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCreatedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCreatedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleCreatedByAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleCreatedByDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleDescriptionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleDescriptionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleEndDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleEndDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleFirstLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleFirstLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleFormsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleFormsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIsArchivedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIsArchivedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIsColoredAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIsColoredDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIsCompletedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIsCompletedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIsFieldAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIsFieldDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleIsTemplateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleIsTemplateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleJobIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleJobIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleLabelIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleLabelIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleLabelNameAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleLabelNameDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_NAME_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleModifiedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleModifiedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleNextVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleNextVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSamplePositionAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskReportsByProjectIdConnectionVarianceSamplePositionDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskReportsByProjectIdConnectionVarianceSamplePriorityAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TaskReportsByProjectIdConnectionVarianceSamplePriorityDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleProjectIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleProjectIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleProjectStageIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleProjectStageIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleSystemIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleSystemIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplateTaskIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTemplateTaskIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleTitleAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleTitleDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleUidAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleUidDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleVirtualPropertiesAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleVirtualPropertiesDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitCountAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitCountDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_COUNT_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitIdAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitIdDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_ID_DESC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitStartDateAsc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_ASC',
  TaskReportsByProjectIdConnectionVarianceSampleVisitStartDateDesc = 'TASK_REPORTS_BY_PROJECT_ID_CONNECTION_VARIANCE_SAMPLE_VISIT_START_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TotalCostAsc = 'TOTAL_COST_ASC',
  TotalCostDesc = 'TOTAL_COST_DESC',
  TotalInboundCallsCountAsc = 'TOTAL_INBOUND_CALLS_COUNT_ASC',
  TotalInboundCallsCountDesc = 'TOTAL_INBOUND_CALLS_COUNT_DESC',
  TotalOutboundCallsCountAsc = 'TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  TotalOutboundCallsCountDesc = 'TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  TradesAsc = 'TRADES_ASC',
  TradesDesc = 'TRADES_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ZipcodeAsc = 'ZIPCODE_ASC',
  ZipcodeDesc = 'ZIPCODE_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  action: Maybe<Action>;
  actionAssignee: Maybe<ActionAssignee>;
  /** Reads a single `ActionAssignee` using its globally unique `ID`. */
  actionAssigneeByNodeId: Maybe<ActionAssignee>;
  /** Reads a set of `ActionAssignee`. */
  actionAssignees: Maybe<Array<ActionAssignee>>;
  /** Reads a single `Action` using its globally unique `ID`. */
  actionByNodeId: Maybe<Action>;
  actionStatusUpdate: Maybe<ActionStatusUpdate>;
  /** Reads a single `ActionStatusUpdate` using its globally unique `ID`. */
  actionStatusUpdateByNodeId: Maybe<ActionStatusUpdate>;
  /** Reads a set of `ActionStatusUpdate`. */
  actionStatusUpdates: Maybe<Array<ActionStatusUpdate>>;
  /** Reads and enables pagination through a set of `ActionStatusUpdate`. */
  actionStatusUpdatesConnection: Maybe<ActionStatusUpdatesConnection>;
  /** Reads a set of `Action`. */
  actions: Maybe<Array<Action>>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsConnection: Maybe<ActionsConnection>;
  analytic: Maybe<Analytic>;
  /** Reads a single `Analytic` using its globally unique `ID`. */
  analyticByNodeId: Maybe<Analytic>;
  /** Reads a set of `Analytic`. */
  analytics: Maybe<Array<Analytic>>;
  assignee: Maybe<Assignee>;
  /** Reads a single `Assignee` using its globally unique `ID`. */
  assigneeByNodeId: Maybe<Assignee>;
  /** Reads a set of `Assignee`. */
  assignees: Maybe<Array<Assignee>>;
  blueprint: Maybe<Blueprint>;
  /** Reads a single `Blueprint` using its globally unique `ID`. */
  blueprintByNodeId: Maybe<Blueprint>;
  blueprintProjectStage: Maybe<BlueprintProjectStage>;
  /** Reads a single `BlueprintProjectStage` using its globally unique `ID`. */
  blueprintProjectStageByNodeId: Maybe<BlueprintProjectStage>;
  /** Reads a set of `BlueprintProjectStage`. */
  blueprintProjectStages: Maybe<Array<BlueprintProjectStage>>;
  blueprintTask: Maybe<BlueprintTask>;
  /** Reads a single `BlueprintTask` using its globally unique `ID`. */
  blueprintTaskByNodeId: Maybe<BlueprintTask>;
  /** Reads a set of `BlueprintTask`. */
  blueprintTasks: Maybe<Array<BlueprintTask>>;
  /** Reads a set of `BlueprintTimeslouse`. */
  blueprintTimeslice: Maybe<Array<BlueprintTimeslouse>>;
  blueprintTimeslouse: Maybe<BlueprintTimeslouse>;
  /** Reads a single `BlueprintTimeslouse` using its globally unique `ID`. */
  blueprintTimeslouseByNodeId: Maybe<BlueprintTimeslouse>;
  /** Reads a set of `Blueprint`. */
  blueprints: Maybe<Array<Blueprint>>;
  call: Maybe<Call>;
  /** Reads a single `Call` using its globally unique `ID`. */
  callByNodeId: Maybe<Call>;
  /** Reads a set of `Call`. */
  calls: Maybe<Array<Call>>;
  /** Reads and enables pagination through a set of `Call`. */
  callsConnection: Maybe<CallsConnection>;
  catalogItem: Maybe<CatalogItem>;
  /** Reads a single `CatalogItem` using its globally unique `ID`. */
  catalogItemByNodeId: Maybe<CatalogItem>;
  /** Reads a set of `CatalogItem`. */
  catalogItems: Maybe<Array<CatalogItem>>;
  /** Reads and enables pagination through a set of `CatalogItem`. */
  catalogItemsConnection: Maybe<CatalogItemsConnection>;
  columnGroup: Maybe<ColumnGroup>;
  /** Reads a single `ColumnGroup` using its globally unique `ID`. */
  columnGroupByNodeId: Maybe<ColumnGroup>;
  /** Reads a set of `ColumnGroup`. */
  columnGroups: Maybe<Array<ColumnGroup>>;
  /** Reads and enables pagination through a set of `ColumnGroup`. */
  columnGroupsConnection: Maybe<ColumnGroupsConnection>;
  columnToGroup: Maybe<ColumnToGroup>;
  /** Reads a single `ColumnToGroup` using its globally unique `ID`. */
  columnToGroupByNodeId: Maybe<ColumnToGroup>;
  /** Reads a set of `ColumnToGroup`. */
  columnToGroups: Maybe<Array<ColumnToGroup>>;
  /** Reads and enables pagination through a set of `ColumnToGroup`. */
  columnToGroupsConnection: Maybe<ColumnToGroupsConnection>;
  comment: Maybe<Comment>;
  /** Reads a single `Comment` using its globally unique `ID`. */
  commentByNodeId: Maybe<Comment>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsConnection: Maybe<CommentsConnection>;
  /** Reads a set of `Company`. */
  companies: Maybe<Array<Company>>;
  company: Maybe<Company>;
  /** Reads a single `Company` using its globally unique `ID`. */
  companyByNodeId: Maybe<Company>;
  companyNotificationSetting: Maybe<CompanyNotificationSetting>;
  /** Reads a single `CompanyNotificationSetting` using its globally unique `ID`. */
  companyNotificationSettingByNodeId: Maybe<CompanyNotificationSetting>;
  /** Reads a set of `CompanyNotificationSetting`. */
  companyNotificationSettings: Maybe<Array<CompanyNotificationSetting>>;
  /** Reads and enables pagination through a set of `CompanyNotificationSetting`. */
  companyNotificationSettingsConnection: Maybe<CompanyNotificationSettingsConnection>;
  companyNotificationSettingsRoleDestination: Maybe<CompanyNotificationSettingsRoleDestination>;
  /** Reads a single `CompanyNotificationSettingsRoleDestination` using its globally unique `ID`. */
  companyNotificationSettingsRoleDestinationByNodeId: Maybe<CompanyNotificationSettingsRoleDestination>;
  /** Reads a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinations: Maybe<Array<CompanyNotificationSettingsRoleDestination>>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinationsConnection: Maybe<CompanyNotificationSettingsRoleDestinationsConnection>;
  companyNotificationSettingsSingleDestination: Maybe<CompanyNotificationSettingsSingleDestination>;
  /** Reads a single `CompanyNotificationSettingsSingleDestination` using its globally unique `ID`. */
  companyNotificationSettingsSingleDestinationByNodeId: Maybe<CompanyNotificationSettingsSingleDestination>;
  /** Reads a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinations: Maybe<Array<CompanyNotificationSettingsSingleDestination>>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsSingleDestination`. */
  companyNotificationSettingsSingleDestinationsConnection: Maybe<CompanyNotificationSettingsSingleDestinationsConnection>;
  companyNotificationSettingsTeamDestination: Maybe<CompanyNotificationSettingsTeamDestination>;
  /** Reads a single `CompanyNotificationSettingsTeamDestination` using its globally unique `ID`. */
  companyNotificationSettingsTeamDestinationByNodeId: Maybe<CompanyNotificationSettingsTeamDestination>;
  /** Reads a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinations: Maybe<Array<CompanyNotificationSettingsTeamDestination>>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinationsConnection: Maybe<CompanyNotificationSettingsTeamDestinationsConnection>;
  companyPhone: Maybe<CompanyPhone>;
  /** Reads a single `CompanyPhone` using its globally unique `ID`. */
  companyPhoneByNodeId: Maybe<CompanyPhone>;
  companyPhoneUser: Maybe<CompanyPhoneUser>;
  /** Reads a single `CompanyPhoneUser` using its globally unique `ID`. */
  companyPhoneUserByNodeId: Maybe<CompanyPhoneUser>;
  /** Reads a set of `CompanyPhoneUser`. */
  companyPhoneUsers: Maybe<Array<CompanyPhoneUser>>;
  /** Reads a set of `CompanyPhone`. */
  companyPhones: Maybe<Array<CompanyPhone>>;
  companyUser: Maybe<CompanyUser>;
  /** Reads a single `CompanyUser` using its globally unique `ID`. */
  companyUserByNodeId: Maybe<CompanyUser>;
  companyUserRole: Maybe<CompanyUserRole>;
  /** Reads a single `CompanyUserRole` using its globally unique `ID`. */
  companyUserRoleByNodeId: Maybe<CompanyUserRole>;
  /** Reads a set of `CompanyUserRole`. */
  companyUserRoles: Maybe<Array<CompanyUserRole>>;
  /** Reads a set of `CompanyUser`. */
  companyUsers: Maybe<Array<CompanyUser>>;
  consumer: Maybe<Consumer>;
  /** Reads a single `Consumer` using its globally unique `ID`. */
  consumerByNodeId: Maybe<Consumer>;
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersConnection: Maybe<ConsumersConnection>;
  contact: Maybe<Contact>;
  /** Reads a single `Contact` using its globally unique `ID`. */
  contactByNodeId: Maybe<Contact>;
  /** Reads a set of `Contact`. */
  contacts: Maybe<Array<Contact>>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsConnection: Maybe<ContactsConnection>;
  dashboard: Maybe<Dashboard>;
  /** Reads a single `Dashboard` using its globally unique `ID`. */
  dashboardByNodeId: Maybe<Dashboard>;
  dashboardSharedRole: Maybe<DashboardSharedRole>;
  /** Reads a single `DashboardSharedRole` using its globally unique `ID`. */
  dashboardSharedRoleByNodeId: Maybe<DashboardSharedRole>;
  /** Reads a set of `DashboardSharedRole`. */
  dashboardSharedRoles: Maybe<Array<DashboardSharedRole>>;
  dashboardSharedTeam: Maybe<DashboardSharedTeam>;
  /** Reads a single `DashboardSharedTeam` using its globally unique `ID`. */
  dashboardSharedTeamByNodeId: Maybe<DashboardSharedTeam>;
  /** Reads a set of `DashboardSharedTeam`. */
  dashboardSharedTeams: Maybe<Array<DashboardSharedTeam>>;
  dashboardSharedUser: Maybe<DashboardSharedUser>;
  /** Reads a single `DashboardSharedUser` using its globally unique `ID`. */
  dashboardSharedUserByNodeId: Maybe<DashboardSharedUser>;
  /** Reads a set of `DashboardSharedUser`. */
  dashboardSharedUsers: Maybe<Array<DashboardSharedUser>>;
  /** Reads a set of `Dashboard`. */
  dashboards: Maybe<Array<Dashboard>>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsConnection: Maybe<DashboardsConnection>;
  /** Reads a set of `Dictionary`. */
  dictionaries: Maybe<Array<Dictionary>>;
  dictionary: Maybe<Dictionary>;
  /** Reads a single `Dictionary` using its globally unique `ID`. */
  dictionaryByNodeId: Maybe<Dictionary>;
  emailAccount: Maybe<EmailAccount>;
  /** Reads a single `EmailAccount` using its globally unique `ID`. */
  emailAccountByNodeId: Maybe<EmailAccount>;
  emailAccountUser: Maybe<EmailAccountUser>;
  /** Reads a single `EmailAccountUser` using its globally unique `ID`. */
  emailAccountUserByNodeId: Maybe<EmailAccountUser>;
  /** Reads a set of `EmailAccountUser`. */
  emailAccountUsers: Maybe<Array<EmailAccountUser>>;
  /** Reads a set of `EmailAccount`. */
  emailAccounts: Maybe<Array<EmailAccount>>;
  emailMessage: Maybe<EmailMessage>;
  /** Reads a single `EmailMessage` using its globally unique `ID`. */
  emailMessageByNodeId: Maybe<EmailMessage>;
  /** Reads a set of `EmailMessage`. */
  emailMessages: Maybe<Array<EmailMessage>>;
  favoriteProject: Maybe<FavoriteProject>;
  /** Reads a single `FavoriteProject` using its globally unique `ID`. */
  favoriteProjectByNodeId: Maybe<FavoriteProject>;
  /** Reads a set of `FavoriteProject`. */
  favoriteProjects: Maybe<Array<FavoriteProject>>;
  feedInbox: Maybe<FeedInbox>;
  /** Reads a single `FeedInbox` using its globally unique `ID`. */
  feedInboxByNodeId: Maybe<FeedInbox>;
  /** Reads and enables pagination through a set of `FeedInbox`. */
  feedInboxesConnection: Maybe<FeedInboxesConnection>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: Maybe<FeedsConnection>;
  file: Maybe<File>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId: Maybe<File>;
  /** Reads a set of `File`. */
  files: Maybe<Array<File>>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: Maybe<FilesConnection>;
  filter: Maybe<Filter>;
  /** Reads a single `Filter` using its globally unique `ID`. */
  filterByNodeId: Maybe<Filter>;
  filterFolder: Maybe<FilterFolder>;
  /** Reads a single `FilterFolder` using its globally unique `ID`. */
  filterFolderByNodeId: Maybe<FilterFolder>;
  /** Reads a set of `FilterFolder`. */
  filterFolders: Maybe<Array<FilterFolder>>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersConnection: Maybe<FilterFoldersConnection>;
  filterToRole: Maybe<FilterToRole>;
  /** Reads a single `FilterToRole` using its globally unique `ID`. */
  filterToRoleByNodeId: Maybe<FilterToRole>;
  /** Reads a set of `FilterToRole`. */
  filterToRoles: Maybe<Array<FilterToRole>>;
  filterToTeam: Maybe<FilterToTeam>;
  /** Reads a single `FilterToTeam` using its globally unique `ID`. */
  filterToTeamByNodeId: Maybe<FilterToTeam>;
  /** Reads a set of `FilterToTeam`. */
  filterToTeams: Maybe<Array<FilterToTeam>>;
  filterToUser: Maybe<FilterToUser>;
  /** Reads a single `FilterToUser` using its globally unique `ID`. */
  filterToUserByNodeId: Maybe<FilterToUser>;
  /** Reads a set of `FilterToUser`. */
  filterToUsers: Maybe<Array<FilterToUser>>;
  /** Reads a set of `Filter`. */
  filters: Maybe<Array<Filter>>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersConnection: Maybe<FiltersConnection>;
  form: Maybe<Form>;
  formAssignee: Maybe<FormAssignee>;
  /** Reads a single `FormAssignee` using its globally unique `ID`. */
  formAssigneeByNodeId: Maybe<FormAssignee>;
  /** Reads a set of `FormAssignee`. */
  formAssignees: Maybe<Array<FormAssignee>>;
  /** Reads a single `Form` using its globally unique `ID`. */
  formByNodeId: Maybe<Form>;
  formLayout: Maybe<FormLayout>;
  /** Reads a single `FormLayout` using its globally unique `ID`. */
  formLayoutByNodeId: Maybe<FormLayout>;
  /** Reads a set of `FormLayout`. */
  formLayouts: Maybe<Array<FormLayout>>;
  /** Reads and enables pagination through a set of `FormLayout`. */
  formLayoutsConnection: Maybe<FormLayoutsConnection>;
  /** Reads a set of `Form`. */
  forms: Maybe<Array<Form>>;
  /** Reads and enables pagination through a set of `Form`. */
  formsConnection: Maybe<FormsConnection>;
  geoEstimation: Maybe<GeoEstimation>;
  /** Reads a single `GeoEstimation` using its globally unique `ID`. */
  geoEstimationByNodeId: Maybe<GeoEstimation>;
  /** Reads a set of `GeoEstimation`. */
  geoEstimations: Maybe<Array<GeoEstimation>>;
  /** Reads and enables pagination through a set of `GeoEstimation`. */
  geoEstimationsConnection: Maybe<GeoEstimationsConnection>;
  geolocation: Maybe<Geolocation>;
  /** Reads a single `Geolocation` using its globally unique `ID`. */
  geolocationByNodeId: Maybe<Geolocation>;
  geolocationUserSetting: Maybe<GeolocationUserSetting>;
  /** Reads a single `GeolocationUserSetting` using its globally unique `ID`. */
  geolocationUserSettingByNodeId: Maybe<GeolocationUserSetting>;
  /** Reads a set of `Geolocation`. */
  geolocations: Maybe<Array<Geolocation>>;
  /** Reads and enables pagination through a set of `Geolocation`. */
  geolocationsConnection: Maybe<GeolocationsConnection>;
  /** Reads a set of `ImportHistory`. */
  importHistories: Maybe<Array<ImportHistory>>;
  /** Reads and enables pagination through a set of `ImportHistory`. */
  importHistoriesConnection: Maybe<ImportHistoriesConnection>;
  importHistory: Maybe<ImportHistory>;
  /** Reads a single `ImportHistory` using its globally unique `ID`. */
  importHistoryByNodeId: Maybe<ImportHistory>;
  importRow: Maybe<ImportRow>;
  /** Reads a single `ImportRow` using its globally unique `ID`. */
  importRowByNodeId: Maybe<ImportRow>;
  /** Reads a set of `ImportRow`. */
  importRows: Maybe<Array<ImportRow>>;
  /** Reads and enables pagination through a set of `ImportRow`. */
  importRowsConnection: Maybe<ImportRowsConnection>;
  integration: Maybe<Integration>;
  /** Reads a single `Integration` using its globally unique `ID`. */
  integrationByNodeId: Maybe<Integration>;
  integrationStat: Maybe<IntegrationStat>;
  /** Reads a single `IntegrationStat` using its globally unique `ID`. */
  integrationStatByNodeId: Maybe<IntegrationStat>;
  /** Reads a set of `IntegrationStat`. */
  integrationStats: Maybe<Array<IntegrationStat>>;
  integrationSystemLog: Maybe<IntegrationSystemLog>;
  /** Reads a single `IntegrationSystemLog` using its globally unique `ID`. */
  integrationSystemLogByNodeId: Maybe<IntegrationSystemLog>;
  /** Reads and enables pagination through a set of `IntegrationSystemLog`. */
  integrationSystemLogsConnection: Maybe<IntegrationSystemLogsConnection>;
  /** Reads a set of `Integration`. */
  integrations: Maybe<Array<Integration>>;
  invoice: Maybe<Invoice>;
  /** Reads a single `Invoice` using its globally unique `ID`. */
  invoiceByNodeId: Maybe<Invoice>;
  /** Reads a set of `Invoice`. */
  invoices: Maybe<Array<Invoice>>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesConnection: Maybe<InvoicesConnection>;
  job: Maybe<Job>;
  /** Reads a single `Job` using its globally unique `ID`. */
  jobByNodeId: Maybe<Job>;
  /** Reads a set of `Job`. */
  jobs: Maybe<Array<Job>>;
  jurisdiction: Maybe<Jurisdiction>;
  /** Reads a single `Jurisdiction` using its globally unique `ID`. */
  jurisdictionByNodeId: Maybe<Jurisdiction>;
  /** Reads a set of `Jurisdiction`. */
  jurisdictions: Maybe<Array<Jurisdiction>>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsConnection: Maybe<JurisdictionsConnection>;
  label: Maybe<Label>;
  /** Reads a single `Label` using its globally unique `ID`. */
  labelByNodeId: Maybe<Label>;
  /** Reads a set of `Label`. */
  labels: Maybe<Array<Label>>;
  lineItem: Maybe<LineItem>;
  /** Reads a single `LineItem` using its globally unique `ID`. */
  lineItemByNodeId: Maybe<LineItem>;
  /** Reads a set of `LineItem`. */
  lineItems: Maybe<Array<LineItem>>;
  mainUser: Maybe<MainUser>;
  /** Reads a single `MainUser` using its globally unique `ID`. */
  mainUserByNodeId: Maybe<MainUser>;
  /** Reads a set of `MainUser`. */
  mainUsers: Maybe<Array<MainUser>>;
  /** Fetches an object given its globally unique `ID`. */
  node: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  notificationDecisionTree: Maybe<Array<Maybe<NotificationEntityDecisionTree>>>;
  portalNotificationDecisionTree: Maybe<Array<Maybe<PortalNotificationEntityDecisionTree>>>;
  privilegedTask: Maybe<PrivilegedTask>;
  /** Reads a single `PrivilegedTask` using its globally unique `ID`. */
  privilegedTaskByNodeId: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksConnection: Maybe<PrivilegedTasksConnection>;
  project: Maybe<Project>;
  /** Reads a set of `ProjectAddress`. */
  projectAddresses: Maybe<Array<ProjectAddress>>;
  /** Reads a single `Project` using its globally unique `ID`. */
  projectByNodeId: Maybe<Project>;
  projectColumnAccess: Maybe<ProjectColumnAccess>;
  /** Reads a single `ProjectColumnAccess` using its globally unique `ID`. */
  projectColumnAccessByNodeId: Maybe<ProjectColumnAccess>;
  /** Reads a set of `ProjectColumnAccess`. */
  projectColumnAccesses: Maybe<Array<ProjectColumnAccess>>;
  projectContact: Maybe<ProjectContact>;
  /** Reads a single `ProjectContact` using its globally unique `ID`. */
  projectContactByNodeId: Maybe<ProjectContact>;
  /** Reads a set of `ProjectContact`. */
  projectContacts: Maybe<Array<ProjectContact>>;
  projectMember: Maybe<ProjectMember>;
  /** Reads a single `ProjectMember` using its globally unique `ID`. */
  projectMemberByNodeId: Maybe<ProjectMember>;
  /** Reads a set of `ProjectMember`. */
  projectMembers: Maybe<Array<ProjectMember>>;
  /** Reads a set of `ProjectProperty`. */
  projectProperties: Maybe<Array<ProjectProperty>>;
  projectProperty: Maybe<ProjectProperty>;
  /** Reads a single `ProjectProperty` using its globally unique `ID`. */
  projectPropertyByNodeId: Maybe<ProjectProperty>;
  projectPropertyFileReference: Maybe<ProjectPropertyFileReference>;
  /** Reads a single `ProjectPropertyFileReference` using its globally unique `ID`. */
  projectPropertyFileReferenceByNodeId: Maybe<ProjectPropertyFileReference>;
  /** Reads a set of `ProjectPropertyFileReference`. */
  projectPropertyFileReferences: Maybe<Array<ProjectPropertyFileReference>>;
  projectPropertyValue: Maybe<ProjectPropertyValue>;
  /** Reads a single `ProjectPropertyValue` using its globally unique `ID`. */
  projectPropertyValueByNodeId: Maybe<ProjectPropertyValue>;
  projectPropertyValueFile: Maybe<ProjectPropertyValueFile>;
  /** Reads a single `ProjectPropertyValueFile` using its globally unique `ID`. */
  projectPropertyValueFileByNodeId: Maybe<ProjectPropertyValueFile>;
  /** Reads a set of `ProjectPropertyValueFile`. */
  projectPropertyValueFiles: Maybe<Array<ProjectPropertyValueFile>>;
  /** Reads a set of `ProjectPropertyValue`. */
  projectPropertyValues: Maybe<Array<ProjectPropertyValue>>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesConnection: Maybe<ProjectPropertyValuesConnection>;
  projectReport: Maybe<ProjectReport>;
  /** Reads a single `ProjectReport` using its globally unique `ID`. */
  projectReportByNodeId: Maybe<ProjectReport>;
  /** Reads a set of `ProjectReport`. */
  projectReports: Maybe<Array<ProjectReport>>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: Maybe<ProjectReportsConnection>;
  projectStage: Maybe<ProjectStage>;
  /** Reads a single `ProjectStage` using its globally unique `ID`. */
  projectStageByNodeId: Maybe<ProjectStage>;
  /** Reads a set of `ProjectStageTimeline`. */
  projectStageTimelines: Maybe<Array<ProjectStageTimeline>>;
  /** Reads and enables pagination through a set of `ProjectStageTimeline`. */
  projectStageTimelinesConnection: Maybe<ProjectStageTimelinesConnection>;
  projectStageUpdate: Maybe<ProjectStageUpdate>;
  /** Reads a single `ProjectStageUpdate` using its globally unique `ID`. */
  projectStageUpdateByNodeId: Maybe<ProjectStageUpdate>;
  /** Reads a set of `ProjectStageUpdate`. */
  projectStageUpdates: Maybe<Array<ProjectStageUpdate>>;
  /** Reads and enables pagination through a set of `ProjectStageUpdate`. */
  projectStageUpdatesConnection: Maybe<ProjectStageUpdatesConnection>;
  /** Reads a set of `ProjectStage`. */
  projectStages: Maybe<Array<ProjectStage>>;
  /** Reads a set of `ProjectStatusTimeline`. */
  projectStatusTimelines: Maybe<Array<ProjectStatusTimeline>>;
  /** Reads and enables pagination through a set of `ProjectStatusTimeline`. */
  projectStatusTimelinesConnection: Maybe<ProjectStatusTimelinesConnection>;
  projectStatusUpdate: Maybe<ProjectStatusUpdate>;
  /** Reads a single `ProjectStatusUpdate` using its globally unique `ID`. */
  projectStatusUpdateByNodeId: Maybe<ProjectStatusUpdate>;
  /** Reads a set of `ProjectStatusUpdate`. */
  projectStatusUpdates: Maybe<Array<ProjectStatusUpdate>>;
  /** Reads and enables pagination through a set of `ProjectStatusUpdate`. */
  projectStatusUpdatesConnection: Maybe<ProjectStatusUpdatesConnection>;
  /** Reads a set of `ProjectTrade`. */
  projectTrades: Maybe<Array<ProjectTrade>>;
  /** Reads a set of `Project`. */
  projects: Maybe<Array<Project>>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: Maybe<ProjectsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  reminder: Maybe<Reminder>;
  /** Reads a single `Reminder` using its globally unique `ID`. */
  reminderByNodeId: Maybe<Reminder>;
  /** Reads a set of `Reminder`. */
  reminders: Maybe<Array<Reminder>>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersConnection: Maybe<RemindersConnection>;
  role: Maybe<Role>;
  /** Reads a single `Role` using its globally unique `ID`. */
  roleByNodeId: Maybe<Role>;
  /** Reads a set of `Role`. */
  roles: Maybe<Array<Role>>;
  /** Reads a set of `SmsActivity`. */
  smsActivities: Maybe<Array<SmsActivity>>;
  smsActivity: Maybe<SmsActivity>;
  /** Reads a single `SmsActivity` using its globally unique `ID`. */
  smsActivityByNodeId: Maybe<SmsActivity>;
  subTask: Maybe<SubTask>;
  /** Reads a single `SubTask` using its globally unique `ID`. */
  subTaskByNodeId: Maybe<SubTask>;
  /** Reads a set of `SubTask`. */
  subTasks: Maybe<Array<SubTask>>;
  system: Maybe<System>;
  systemAlert: Maybe<SystemAlert>;
  /** Reads a single `SystemAlert` using its globally unique `ID`. */
  systemAlertByNodeId: Maybe<SystemAlert>;
  systemAlertProfile: Maybe<SystemAlertProfile>;
  /** Reads a single `SystemAlertProfile` using its globally unique `ID`. */
  systemAlertProfileByNodeId: Maybe<SystemAlertProfile>;
  /** Reads a set of `SystemAlert`. */
  systemAlerts: Maybe<Array<SystemAlert>>;
  systemBudget: Maybe<SystemBudget>;
  /** Reads a single `SystemBudget` using its globally unique `ID`. */
  systemBudgetByNodeId: Maybe<SystemBudget>;
  systemBudgetProfileWhitelist: Maybe<SystemBudgetProfileWhitelist>;
  /** Reads a single `SystemBudgetProfileWhitelist` using its globally unique `ID`. */
  systemBudgetProfileWhitelistByNodeId: Maybe<SystemBudgetProfileWhitelist>;
  /** Reads a set of `SystemBudget`. */
  systemBudgets: Maybe<Array<SystemBudget>>;
  systemById: Maybe<System>;
  systemByIntegrationIdAndProviderId: Maybe<System>;
  /** Reads a single `System` using its globally unique `ID`. */
  systemByNodeId: Maybe<System>;
  systemByUuid: Maybe<System>;
  systemCluster: Maybe<Array<Maybe<SystemClusterRecord>>>;
  systemLog: Maybe<SystemLog>;
  /** Reads a single `SystemLog` using its globally unique `ID`. */
  systemLogByNodeId: Maybe<SystemLog>;
  /** Reads and enables pagination through a set of `SystemLogThread`. */
  systemLogThreadsConnection: Maybe<SystemLogThreadsConnection>;
  /** Reads and enables pagination through a set of `SystemLog`. */
  systemLogsConnection: Maybe<SystemLogsConnection>;
  systemProfile: Maybe<SystemProfile>;
  /** Reads a single `SystemProfile` using its globally unique `ID`. */
  systemProfileByNodeId: Maybe<SystemProfile>;
  systemProfileConfig: Maybe<SystemProfileConfig>;
  /** Reads a single `SystemProfileConfig` using its globally unique `ID`. */
  systemProfileConfigByNodeId: Maybe<SystemProfileConfig>;
  /** Reads a set of `SystemProfileConfig`. */
  systemProfileConfigs: Maybe<Array<SystemProfileConfig>>;
  /** Reads a set of `SystemProfile`. */
  systemProfiles: Maybe<Array<SystemProfile>>;
  /** Reads and enables pagination through a set of `System`. */
  systemsConnection: Maybe<SystemsConnection>;
  task: Maybe<Task>;
  /** Reads a single `Task` using its globally unique `ID`. */
  taskByNodeId: Maybe<Task>;
  taskLabel: Maybe<TaskLabel>;
  /** Reads a single `TaskLabel` using its globally unique `ID`. */
  taskLabelByNodeId: Maybe<TaskLabel>;
  /** Reads a set of `TaskLabel`. */
  taskLabels: Maybe<Array<TaskLabel>>;
  /** Reads and enables pagination through a set of `TaskReport`. */
  taskReportsConnection: Maybe<TaskReportsConnection>;
  /** Reads a set of `TaskStatusTimeline`. */
  taskStatusTimelines: Maybe<Array<TaskStatusTimeline>>;
  /** Reads and enables pagination through a set of `TaskStatusTimeline`. */
  taskStatusTimelinesConnection: Maybe<TaskStatusTimelinesConnection>;
  taskStatusUpdate: Maybe<TaskStatusUpdate>;
  /** Reads a single `TaskStatusUpdate` using its globally unique `ID`. */
  taskStatusUpdateByNodeId: Maybe<TaskStatusUpdate>;
  /** Reads a set of `TaskStatusUpdate`. */
  taskStatusUpdates: Maybe<Array<TaskStatusUpdate>>;
  taskStatuses: Maybe<Array<Maybe<TaskStatusEntity>>>;
  /** Reads a set of `TaskTemplateUser`. */
  taskTemplateUsers: Maybe<Array<TaskTemplateUser>>;
  taskVisit: Maybe<TaskVisit>;
  /** Reads a single `TaskVisit` using its globally unique `ID`. */
  taskVisitByNodeId: Maybe<TaskVisit>;
  /** Reads a set of `TaskVisit`. */
  taskVisits: Maybe<Array<TaskVisit>>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsConnection: Maybe<TaskVisitsConnection>;
  /** Reads and enables pagination through a set of `Task`. */
  tasksConnection: Maybe<TasksConnection>;
  team: Maybe<Team>;
  /** Reads a single `Team` using its globally unique `ID`. */
  teamByNodeId: Maybe<Team>;
  /** Reads a set of `Team`. */
  teams: Maybe<Array<Team>>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsConnection: Maybe<TeamsConnection>;
  timezones: Maybe<Array<Maybe<Timezone>>>;
  user: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId: Maybe<User>;
  userGeolocationSetting: Maybe<UserGeolocationSetting>;
  /** Reads a single `UserGeolocationSetting` using its globally unique `ID`. */
  userGeolocationSettingByNodeId: Maybe<UserGeolocationSetting>;
  /** Reads a set of `UserGeolocationSetting`. */
  userGeolocationSettings: Maybe<Array<UserGeolocationSetting>>;
  userTeam: Maybe<UserTeam>;
  /** Reads a single `UserTeam` using its globally unique `ID`. */
  userTeamByNodeId: Maybe<UserTeam>;
  /** Reads a set of `UserTeam`. */
  userTeams: Maybe<Array<UserTeam>>;
  /** Reads and enables pagination through a set of `User`. */
  usersConnection: Maybe<UsersConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActionArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionAssigneeArgs = {
  actionId: Scalars['Int']['input'];
  contactId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionAssigneeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionAssigneesArgs = {
  condition: InputMaybe<ActionAssigneeCondition>;
  filter: InputMaybe<ActionAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionAssigneesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionStatusUpdateArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionStatusUpdateByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActionStatusUpdatesArgs = {
  condition: InputMaybe<ActionStatusUpdateCondition>;
  filter: InputMaybe<ActionStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActionStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionStatusUpdateCondition>;
  filter: InputMaybe<ActionStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionStatusUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActionsArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActionsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAnalyticsArgs = {
  condition: InputMaybe<AnalyticCondition>;
  filter: InputMaybe<AnalyticFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AnalyticsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAssigneeArgs = {
  taskId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAssigneeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAssigneesArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintProjectStageArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintProjectStageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintProjectStagesArgs = {
  condition: InputMaybe<BlueprintProjectStageCondition>;
  filter: InputMaybe<BlueprintProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintProjectStagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTaskArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTaskByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTasksArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTimesliceArgs = {
  condition: InputMaybe<BlueprintTimeslouseCondition>;
  filter: InputMaybe<BlueprintTimeslouseFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTimesliceOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTimeslouseArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintTimeslouseByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlueprintsArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCallArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCallByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCallsArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCallsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogItemArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogItemByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogItemsArgs = {
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CatalogItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCatalogItemsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CatalogItemCondition>;
  filter: InputMaybe<CatalogItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CatalogItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnGroupArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnGroupsArgs = {
  condition: InputMaybe<ColumnGroupCondition>;
  filter: InputMaybe<ColumnGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnGroupsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ColumnGroupCondition>;
  filter: InputMaybe<ColumnGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnToGroupArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnToGroupByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnToGroupsArgs = {
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryColumnToGroupsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ColumnToGroupCondition>;
  filter: InputMaybe<ColumnToGroupFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ColumnToGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompaniesArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingArgs = {
  companyId: Scalars['Int']['input'];
  entityType: EntityType;
  eventType: EventType;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsArgs = {
  condition: InputMaybe<CompanyNotificationSettingCondition>;
  filter: InputMaybe<CompanyNotificationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingCondition>;
  filter: InputMaybe<CompanyNotificationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsRoleDestinationArgs = {
  companyId: Scalars['Int']['input'];
  entityType: EntityType;
  eventType: EventType;
  roleId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsRoleDestinationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsRoleDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsRoleDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsSingleDestinationArgs = {
  companyId: Scalars['Int']['input'];
  entityType: EntityType;
  eventType: EventType;
  target: SingleDestination;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsSingleDestinationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsSingleDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsSingleDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsSingleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsSingleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsSingleDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsTeamDestinationArgs = {
  companyId: Scalars['Int']['input'];
  entityType: EntityType;
  eventType: EventType;
  teamId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsTeamDestinationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsTeamDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyNotificationSettingsTeamDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhoneArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhoneByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhoneUserArgs = {
  companyPhoneId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhoneUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhoneUsersArgs = {
  condition: InputMaybe<CompanyPhoneUserCondition>;
  filter: InputMaybe<CompanyPhoneUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhoneUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPhonesArgs = {
  condition: InputMaybe<CompanyPhoneCondition>;
  filter: InputMaybe<CompanyPhoneFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhonesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserRoleArgs = {
  companyUserId: Scalars['Int']['input'];
  rolesId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUserRolesArgs = {
  condition: InputMaybe<CompanyUserRoleCondition>;
  filter: InputMaybe<CompanyUserRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUserRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyUsersArgs = {
  condition: InputMaybe<CompanyUserCondition>;
  filter: InputMaybe<CompanyUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConsumerArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConsumerByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConsumersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedRoleArgs = {
  dashboardId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedRolesArgs = {
  condition: InputMaybe<DashboardSharedRoleCondition>;
  filter: InputMaybe<DashboardSharedRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedTeamArgs = {
  dashboardId: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedTeamByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedTeamsArgs = {
  condition: InputMaybe<DashboardSharedTeamCondition>;
  filter: InputMaybe<DashboardSharedTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedTeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedUserArgs = {
  dashboardId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardSharedUsersArgs = {
  condition: InputMaybe<DashboardSharedUserCondition>;
  filter: InputMaybe<DashboardSharedUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardsArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDashboardsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDictionariesArgs = {
  condition: InputMaybe<DictionaryCondition>;
  filter: InputMaybe<DictionaryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DictionariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDictionaryArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDictionaryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountUserArgs = {
  emailAccountId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountUsersArgs = {
  condition: InputMaybe<EmailAccountUserCondition>;
  filter: InputMaybe<EmailAccountUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAccountsArgs = {
  condition: InputMaybe<EmailAccountCondition>;
  filter: InputMaybe<EmailAccountFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailAccountsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessagesArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteProjectArgs = {
  projectId: Scalars['Int']['input'];
  workerId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteProjectByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteProjectsArgs = {
  condition: InputMaybe<FavoriteProjectCondition>;
  filter: InputMaybe<FavoriteProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FavoriteProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedInboxArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedInboxByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedInboxesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedInboxCondition>;
  filter: InputMaybe<FeedInboxFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedInboxesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterFolderArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterFolderByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterFoldersArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterFoldersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToRoleArgs = {
  filterId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToRolesArgs = {
  condition: InputMaybe<FilterToRoleCondition>;
  filter: InputMaybe<FilterToRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToTeamArgs = {
  filterId: Scalars['Int']['input'];
  teamId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToTeamByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToTeamsArgs = {
  condition: InputMaybe<FilterToTeamCondition>;
  filter: InputMaybe<FilterToTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToTeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToUserArgs = {
  filterId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilterToUsersArgs = {
  condition: InputMaybe<FilterToUserCondition>;
  filter: InputMaybe<FilterToUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFiltersArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFiltersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFormArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormAssigneeArgs = {
  formId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormAssigneeByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormAssigneesArgs = {
  condition: InputMaybe<FormAssigneeCondition>;
  filter: InputMaybe<FormAssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormAssigneesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFormByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormLayoutArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormLayoutByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFormLayoutsArgs = {
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormLayoutsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFormLayoutsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormLayoutCondition>;
  filter: InputMaybe<FormLayoutFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormLayoutsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFormsArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFormsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGeoEstimationArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeoEstimationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeoEstimationsArgs = {
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGeoEstimationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationUserSettingArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationUserSettingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationsArgs = {
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGeolocationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeolocationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryImportHistoriesArgs = {
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryImportHistoriesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportHistoryCondition>;
  filter: InputMaybe<ImportHistoryFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportHistoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryImportHistoryArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryImportHistoryByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryImportRowArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryImportRowByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryImportRowsArgs = {
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ImportRowsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryImportRowsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ImportRowCondition>;
  filter: InputMaybe<ImportRowFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ImportRowsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationStatArgs = {
  integrationId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationStatByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationStatsArgs = {
  condition: InputMaybe<IntegrationStatCondition>;
  filter: InputMaybe<IntegrationStatFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationStatsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSystemLogArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSystemLogByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSystemLogsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<IntegrationSystemLogCondition>;
  filter: InputMaybe<IntegrationSystemLogFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationSystemLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationsArgs = {
  condition: InputMaybe<IntegrationCondition>;
  filter: InputMaybe<IntegrationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoicesArgs = {
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<InvoicesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoicesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<InvoiceCondition>;
  filter: InputMaybe<InvoiceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryJobArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobsArgs = {
  condition: InputMaybe<JobCondition>;
  filter: InputMaybe<JobFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JobsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryJurisdictionArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJurisdictionByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJurisdictionsArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryJurisdictionsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLabelsArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLineItemArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLineItemByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLineItemsArgs = {
  condition: InputMaybe<LineItemCondition>;
  filter: InputMaybe<LineItemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LineItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMainUserArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMainUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMainUsersArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivilegedTaskArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivilegedTaskByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivilegedTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAddressesArgs = {
  condition: InputMaybe<ProjectAddressCondition>;
  filter: InputMaybe<ProjectAddressFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectAddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectColumnAccessArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectColumnAccessByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectColumnAccessesArgs = {
  condition: InputMaybe<ProjectColumnAccessCondition>;
  filter: InputMaybe<ProjectColumnAccessFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectColumnAccessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectContactArgs = {
  contactId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectContactByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectContactsArgs = {
  condition: InputMaybe<ProjectContactCondition>;
  filter: InputMaybe<ProjectContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectContactsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMemberArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMemberByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembersArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertiesArgs = {
  condition: InputMaybe<ProjectPropertyCondition>;
  filter: InputMaybe<ProjectPropertyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyFileReferenceArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyFileReferenceByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyFileReferencesArgs = {
  condition: InputMaybe<ProjectPropertyFileReferenceCondition>;
  filter: InputMaybe<ProjectPropertyFileReferenceFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyFileReferencesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValueArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValueByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValueFileArgs = {
  fileId: Scalars['Int']['input'];
  projectColumnValueId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValueFileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValueFilesArgs = {
  condition: InputMaybe<ProjectPropertyValueFileCondition>;
  filter: InputMaybe<ProjectPropertyValueFileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValueFilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValuesArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPropertyValuesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReportArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReportByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageTimelinesArgs = {
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageTimelinesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageTimelineCondition>;
  filter: InputMaybe<ProjectStageTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageUpdateArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageUpdateByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageUpdatesArgs = {
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStageUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStageUpdateCondition>;
  filter: InputMaybe<ProjectStageUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStageUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStagesArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusTimelinesArgs = {
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusTimelinesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusTimelineCondition>;
  filter: InputMaybe<ProjectStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusUpdateArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusUpdateByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusUpdatesArgs = {
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectStatusUpdatesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectStatusUpdateCondition>;
  filter: InputMaybe<ProjectStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectStatusUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectTradesArgs = {
  condition: InputMaybe<ProjectTradeCondition>;
  filter: InputMaybe<ProjectTradeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectTradesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReminderArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReminderByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRemindersArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRemindersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRolesArgs = {
  condition: InputMaybe<RoleCondition>;
  filter: InputMaybe<RoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySmsActivitiesArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySmsActivityArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySmsActivityByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubTaskArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubTaskByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubTasksArgs = {
  condition: InputMaybe<SubTaskCondition>;
  filter: InputMaybe<SubTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SubTasksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemAlertArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemAlertByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemAlertProfileArgs = {
  alertId: Scalars['Int']['input'];
  profileId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemAlertProfileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemAlertsArgs = {
  condition: InputMaybe<SystemAlertCondition>;
  filter: InputMaybe<SystemAlertFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemAlertsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemBudgetArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemBudgetByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemBudgetProfileWhitelistArgs = {
  budgetId: Scalars['Int']['input'];
  profileId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemBudgetProfileWhitelistByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemBudgetsArgs = {
  condition: InputMaybe<SystemBudgetCondition>;
  filter: InputMaybe<SystemBudgetFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemBudgetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemByIdArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemByIntegrationIdAndProviderIdArgs = {
  integrationId: Scalars['Int']['input'];
  providerId: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemByUuidArgs = {
  uuid: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemClusterArgs = {
  clusterDistance: InputMaybe<Scalars['Float']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  systemIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemLogArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemLogByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemLogThreadsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemLogThreadCondition>;
  filter: InputMaybe<SystemLogThreadFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemLogsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemLogCondition>;
  filter: InputMaybe<SystemLogFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfileArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfileByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfileConfigArgs = {
  integrationId: Scalars['Int']['input'];
  profileId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfileConfigByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfileConfigsArgs = {
  condition: InputMaybe<SystemProfileConfigCondition>;
  filter: InputMaybe<SystemProfileConfigFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfileConfigsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemProfilesArgs = {
  condition: InputMaybe<SystemProfileCondition>;
  filter: InputMaybe<SystemProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySystemsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskLabelArgs = {
  labelId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskLabelByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskLabelsArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskReportCondition>;
  filter: InputMaybe<TaskReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskReportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusTimelinesArgs = {
  condition: InputMaybe<TaskStatusTimelineCondition>;
  filter: InputMaybe<TaskStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusTimelinesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskStatusTimelineCondition>;
  filter: InputMaybe<TaskStatusTimelineFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusUpdateArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusUpdateByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskStatusUpdatesArgs = {
  condition: InputMaybe<TaskStatusUpdateCondition>;
  filter: InputMaybe<TaskStatusUpdateFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskStatusUpdatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskTemplateUsersArgs = {
  condition: InputMaybe<TaskTemplateUserCondition>;
  filter: InputMaybe<TaskTemplateUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskTemplateUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskVisitArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskVisitByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskVisitsArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTaskVisitsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskCondition>;
  filter: InputMaybe<TaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGeolocationSettingArgs = {
  id: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGeolocationSettingByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserGeolocationSettingsArgs = {
  condition: InputMaybe<UserGeolocationSettingCondition>;
  filter: InputMaybe<UserGeolocationSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UserGeolocationSettingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserTeamArgs = {
  teamId: Scalars['Int']['input'];
  workerId: Scalars['Int']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserTeamByNodeIdArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserTeamsArgs = {
  condition: InputMaybe<UserTeamCondition>;
  filter: InputMaybe<UserTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type ReceiverSettingsDecisionTree = {
  assignee: Maybe<Scalars['Boolean']['output']>;
  collaborator: Maybe<Scalars['Boolean']['output']>;
  manager: Maybe<Scalars['Boolean']['output']>;
  mention: Maybe<Scalars['Boolean']['output']>;
  owner: Maybe<Scalars['Boolean']['output']>;
  role: Maybe<Scalars['Boolean']['output']>;
  salesRep: Maybe<Scalars['Boolean']['output']>;
  team: Maybe<Scalars['Boolean']['output']>;
};

export type Reminder = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedReminderIdAndActionId: ReminderActionsByFeedReminderIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedReminderIdAndActionIdList: Array<Action>;
  /** Reads a single `User` that is related to this `Reminder`. */
  assignee: Maybe<User>;
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedReminderIdAndCallId: ReminderCallsByFeedReminderIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedReminderIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedReminderIdAndCommentId: ReminderCommentsByFeedReminderIdAndCommentIdManyToManyConnection;
  /** Reads a single `Company` that is related to this `Reminder`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedReminderIdAndCreatedByContactId: ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedReminderIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `Reminder`. */
  createdByUser: Maybe<User>;
  description: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['Datetime']['output']>;
  dueDateApproachingJobId: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedReminderIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedReminderIdAndParentId: ReminderFeedsByFeedReminderIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedReminderIdAndFileId: ReminderFilesByFeedReminderIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedReminderIdAndFileIdList: Array<File>;
  id: Scalars['Int']['output'];
  isAppointment: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  privilege: Maybe<Scalars['String']['output']>;
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  privilegeTeam: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedReminderIdAndTaskId: ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `Reminder`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedReminderIdAndProjectId: ReminderProjectsByFeedReminderIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedReminderIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedReminderIdAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedReminderIdAndSystemId: ReminderSystemsByFeedReminderIdAndSystemIdManyToManyConnection;
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedReminderIdAndCreatedBy: ReminderUsersByFeedReminderIdAndCreatedByManyToManyConnection;
};


export type ReminderActionsByFeedReminderIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type ReminderActionsByFeedReminderIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type ReminderCallsByFeedReminderIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type ReminderCallsByFeedReminderIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type ReminderCommentsByFeedReminderIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type ReminderContactsByFeedReminderIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type ReminderContactsByFeedReminderIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type ReminderEmailMessagesByFeedReminderIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type ReminderFeedsByFeedReminderIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ReminderFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type ReminderFilesByFeedReminderIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type ReminderFilesByFeedReminderIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type ReminderPrivilegedTasksByFeedReminderIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type ReminderProjectsByFeedReminderIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ReminderProjectsByFeedReminderIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type ReminderSmsActivitiesByFeedReminderIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type ReminderSystemsByFeedReminderIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type ReminderUsersByFeedReminderIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type ReminderActionsByFeedReminderIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderActionsByFeedReminderIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type ReminderActionsByFeedReminderIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type ReminderActionsByFeedReminderIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type ReminderActionsByFeedReminderIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ReminderAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<ReminderArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<ReminderAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<ReminderDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<ReminderMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<ReminderMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<ReminderStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<ReminderStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<ReminderSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<ReminderVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<ReminderVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Reminder` object types. */
export type ReminderAggregatesFilter = {
  /** Array aggregation aggregate over matching `Reminder` objects. */
  arrayAgg: InputMaybe<ReminderArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Reminder` objects. */
  average: InputMaybe<ReminderAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Reminder` objects. */
  distinctCount: InputMaybe<ReminderDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Reminder` object to be included within the aggregate. */
  filter: InputMaybe<ReminderFilter>;
  /** Maximum aggregate over matching `Reminder` objects. */
  max: InputMaybe<ReminderMaxAggregateFilter>;
  /** Minimum aggregate over matching `Reminder` objects. */
  min: InputMaybe<ReminderMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Reminder` objects. */
  stddevPopulation: InputMaybe<ReminderStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Reminder` objects. */
  stddevSample: InputMaybe<ReminderStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Reminder` objects. */
  sum: InputMaybe<ReminderSumAggregateFilter>;
  /** Population variance aggregate over matching `Reminder` objects. */
  variancePopulation: InputMaybe<ReminderVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Reminder` objects. */
  varianceSample: InputMaybe<ReminderVarianceSampleAggregateFilter>;
};

export type ReminderArrayAggAggregateFilter = {
  assigneeId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  dueDateApproachingJobId: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  isAppointment: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  privilege: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
  privilegeTeam: InputMaybe<StringListFilter>;
  projectId: InputMaybe<IntListFilter>;
  type: InputMaybe<StringListFilter>;
};

export type ReminderArrayAggAggregates = {
  /** Array aggregation of assigneeId across the matching connection */
  assigneeId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of dueDateApproachingJobId across the matching connection */
  dueDateApproachingJobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isAppointment across the matching connection */
  isAppointment: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of privilege across the matching connection */
  privilege: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeTeam across the matching connection */
  privilegeTeam: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of type across the matching connection */
  type: Array<Maybe<Scalars['String']['output']>>;
};


export type ReminderArrayAggAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesDueDateApproachingJobIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesIsAppointmentArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderArrayAggAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

export type ReminderAverageAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ReminderAverageAggregates = {
  /** Mean average of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ReminderAverageAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type ReminderCallsByFeedReminderIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderCallsByFeedReminderIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type ReminderCallsByFeedReminderIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type ReminderCallsByFeedReminderIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type ReminderCallsByFeedReminderIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ReminderCommentsByFeedReminderIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderCommentsByFeedReminderIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type ReminderCommentsByFeedReminderIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type ReminderCommentsByFeedReminderIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type ReminderCommentsByFeedReminderIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/**
 * A condition to be used against `Reminder` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ReminderCondition = {
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `dueDateApproachingJobId` field. */
  dueDateApproachingJobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isAppointment` field. */
  isAppointment: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilege` field. */
  privilege: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type ReminderContactsByFeedReminderIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ReminderDistinctCountAggregateFilter = {
  assigneeId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completedAt: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  dueDate: InputMaybe<BigIntFilter>;
  dueDateApproachingJobId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isAppointment: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  privilege: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  privilegeTeam: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  type: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type ReminderDistinctCountAggregates = {
  /** Distinct count of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completedAt across the matching connection */
  completedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dueDate across the matching connection */
  dueDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dueDateApproachingJobId across the matching connection */
  dueDateApproachingJobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isAppointment across the matching connection */
  isAppointment: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilege across the matching connection */
  privilege: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeTeam across the matching connection */
  privilegeTeam: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type ReminderDistinctCountAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesDueDateApproachingJobIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesIsAppointmentArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesPrivilegeArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesPrivilegeTeamArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesTypeArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ReminderFeedsByFeedReminderIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderFeedsByFeedReminderIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type ReminderFeedsByFeedReminderIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type ReminderFeedsByFeedReminderIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type ReminderFeedsByFeedReminderIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type ReminderFilesByFeedReminderIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderFilesByFeedReminderIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type ReminderFilesByFeedReminderIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type ReminderFilesByFeedReminderIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type ReminderFilesByFeedReminderIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `Reminder` object types. All fields are combined with a logical ‘and.’ */
export type ReminderFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<ReminderFilter>>;
  /** Filter by the object’s `assignee` relation. */
  assignee: InputMaybe<UserFilter>;
  /** A related `assignee` exists. */
  assigneeExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId: InputMaybe<IntFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dueDateApproachingJobId` field. */
  dueDateApproachingJobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `feedsByReminderId` relation. */
  feedsByReminderId: InputMaybe<ReminderToManyFeedFilter>;
  /** Some related `feedsByReminderId` exist. */
  feedsByReminderIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isAppointment` field. */
  isAppointment: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<ReminderFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<ReminderFilter>>;
  /** Filter by the object’s `privilege` field. */
  privilege: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeTeam` field. */
  privilegeTeam: InputMaybe<StringFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Reminder` for usage during aggregation. */
export enum ReminderGroupBy {
  AssigneeId = 'ASSIGNEE_ID',
  CompanyId = 'COMPANY_ID',
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CompletedAtTruncatedToMonth = 'COMPLETED_AT_TRUNCATED_TO_MONTH',
  CompletedAtTruncatedToQuarter = 'COMPLETED_AT_TRUNCATED_TO_QUARTER',
  CompletedAtTruncatedToWeek = 'COMPLETED_AT_TRUNCATED_TO_WEEK',
  CompletedAtTruncatedToYear = 'COMPLETED_AT_TRUNCATED_TO_YEAR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  DueDate = 'DUE_DATE',
  DueDateApproachingJobId = 'DUE_DATE_APPROACHING_JOB_ID',
  DueDateTruncatedToDay = 'DUE_DATE_TRUNCATED_TO_DAY',
  DueDateTruncatedToHour = 'DUE_DATE_TRUNCATED_TO_HOUR',
  DueDateTruncatedToMonth = 'DUE_DATE_TRUNCATED_TO_MONTH',
  DueDateTruncatedToQuarter = 'DUE_DATE_TRUNCATED_TO_QUARTER',
  DueDateTruncatedToWeek = 'DUE_DATE_TRUNCATED_TO_WEEK',
  DueDateTruncatedToYear = 'DUE_DATE_TRUNCATED_TO_YEAR',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  IsAppointment = 'IS_APPOINTMENT',
  IsCompleted = 'IS_COMPLETED',
  JobId = 'JOB_ID',
  Privilege = 'PRIVILEGE',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  PrivilegeTeam = 'PRIVILEGE_TEAM',
  ProjectId = 'PROJECT_ID',
  Title = 'TITLE',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type ReminderHavingArrayAggInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingAverageInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingDistinctCountInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Reminder` aggregates. */
export type ReminderHavingInput = {
  AND: InputMaybe<Array<ReminderHavingInput>>;
  OR: InputMaybe<Array<ReminderHavingInput>>;
  arrayAgg: InputMaybe<ReminderHavingArrayAggInput>;
  average: InputMaybe<ReminderHavingAverageInput>;
  distinctCount: InputMaybe<ReminderHavingDistinctCountInput>;
  max: InputMaybe<ReminderHavingMaxInput>;
  min: InputMaybe<ReminderHavingMinInput>;
  stddevPopulation: InputMaybe<ReminderHavingStddevPopulationInput>;
  stddevSample: InputMaybe<ReminderHavingStddevSampleInput>;
  sum: InputMaybe<ReminderHavingSumInput>;
  variancePopulation: InputMaybe<ReminderHavingVariancePopulationInput>;
  varianceSample: InputMaybe<ReminderHavingVarianceSampleInput>;
};

export type ReminderHavingMaxInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingMinInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingStddevPopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingStddevSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingSumInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingVariancePopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderHavingVarianceSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  dueDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type ReminderMaxAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ReminderMaxAggregates = {
  /** Maximum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ReminderMaxAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

export type ReminderMinAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  dueDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type ReminderMinAggregates = {
  /** Minimum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of dueDate across the matching connection */
  dueDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type ReminderMinAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesDueDateArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type ReminderPrivilegedTasksByFeedReminderIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type ReminderProjectsByFeedReminderIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderProjectsByFeedReminderIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type ReminderProjectsByFeedReminderIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type ReminderProjectsByFeedReminderIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type ReminderProjectsByFeedReminderIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ReminderStddevPopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ReminderStddevPopulationAggregates = {
  /** Population standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ReminderStddevPopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

export type ReminderStddevSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ReminderStddevSampleAggregates = {
  /** Sample standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ReminderStddevSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

export type ReminderSumAggregateFilter = {
  assigneeId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
};

export type ReminderSumAggregates = {
  /** Sum of assigneeId across the matching connection */
  assigneeId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
};


export type ReminderSumAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type ReminderSystemsByFeedReminderIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderSystemsByFeedReminderIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type ReminderSystemsByFeedReminderIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type ReminderSystemsByFeedReminderIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type ReminderSystemsByFeedReminderIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type ReminderToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type ReminderUsersByFeedReminderIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<ReminderUsersByFeedReminderIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type ReminderUsersByFeedReminderIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type ReminderUsersByFeedReminderIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type ReminderUsersByFeedReminderIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type ReminderVariancePopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ReminderVariancePopulationAggregates = {
  /** Population variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ReminderVariancePopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

export type ReminderVarianceSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
};

export type ReminderVarianceSampleAggregates = {
  /** Sample variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
};


export type ReminderVarianceSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type ReminderVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Reminder` values. */
export type RemindersConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder` and cursor to aid in pagination. */
  edges: Array<RemindersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values. */
export type RemindersConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection. */
export type RemindersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};

/** Methods to use when ordering `Reminder`. */
export enum RemindersOrderBy {
  AssigneeIdAsc = 'ASSIGNEE_ID_ASC',
  AssigneeIdDesc = 'ASSIGNEE_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DueDateApproachingJobIdAsc = 'DUE_DATE_APPROACHING_JOB_ID_ASC',
  DueDateApproachingJobIdDesc = 'DUE_DATE_APPROACHING_JOB_ID_DESC',
  DueDateAsc = 'DUE_DATE_ASC',
  DueDateDesc = 'DUE_DATE_DESC',
  FeedsByReminderIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByReminderIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByReminderIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByReminderIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByReminderIdConnectionArrayAggCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByReminderIdConnectionArrayAggCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByReminderIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByReminderIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByReminderIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByReminderIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByReminderIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionArrayAggEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByReminderIdConnectionArrayAggEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByReminderIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByReminderIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByReminderIdConnectionArrayAggIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByReminderIdConnectionArrayAggIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByReminderIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByReminderIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByReminderIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByReminderIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByReminderIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByReminderIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByReminderIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByReminderIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByReminderIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionAverageActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByReminderIdConnectionAverageActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByReminderIdConnectionAverageCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByReminderIdConnectionAverageCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByReminderIdConnectionAverageCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByReminderIdConnectionAverageCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByReminderIdConnectionAverageCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionAverageCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByReminderIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByReminderIdConnectionAverageCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByReminderIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionAverageCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByReminderIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionAverageEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByReminderIdConnectionAverageEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByReminderIdConnectionAverageEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionAverageEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionAverageFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByReminderIdConnectionAverageFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByReminderIdConnectionAverageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsByReminderIdConnectionAverageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsByReminderIdConnectionAverageParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByReminderIdConnectionAverageParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByReminderIdConnectionAveragePayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByReminderIdConnectionAveragePayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByReminderIdConnectionAverageProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionAverageProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionAverageReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionAverageReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionAverageRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByReminderIdConnectionAverageRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByReminderIdConnectionAverageRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionAverageRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionAverageSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionAverageSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionAverageSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionAverageSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionAverageTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByReminderIdConnectionAverageTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByReminderIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_COUNT_ASC',
  FeedsByReminderIdConnectionCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_COUNT_DESC',
  FeedsByReminderIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByReminderIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByReminderIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByReminderIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByReminderIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByReminderIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByReminderIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByReminderIdConnectionDistinctCountEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByReminderIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByReminderIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByReminderIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByReminderIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByReminderIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByReminderIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionMaxActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByReminderIdConnectionMaxActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByReminderIdConnectionMaxCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByReminderIdConnectionMaxCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByReminderIdConnectionMaxCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsByReminderIdConnectionMaxCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsByReminderIdConnectionMaxCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionMaxCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByReminderIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByReminderIdConnectionMaxCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByReminderIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionMaxCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByReminderIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionMaxEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsByReminderIdConnectionMaxEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsByReminderIdConnectionMaxEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionMaxEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionMaxFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByReminderIdConnectionMaxFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByReminderIdConnectionMaxIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_ID_ASC',
  FeedsByReminderIdConnectionMaxIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_ID_DESC',
  FeedsByReminderIdConnectionMaxParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByReminderIdConnectionMaxParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByReminderIdConnectionMaxPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByReminderIdConnectionMaxPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByReminderIdConnectionMaxProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionMaxProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionMaxReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionMaxReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionMaxRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByReminderIdConnectionMaxRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByReminderIdConnectionMaxRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionMaxRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionMaxSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionMaxSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionMaxSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionMaxSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionMaxTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByReminderIdConnectionMaxTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByReminderIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionMinActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByReminderIdConnectionMinActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByReminderIdConnectionMinCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByReminderIdConnectionMinCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByReminderIdConnectionMinCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsByReminderIdConnectionMinCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsByReminderIdConnectionMinCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionMinCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionMinCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionMinCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionMinCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByReminderIdConnectionMinCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByReminderIdConnectionMinCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByReminderIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionMinCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByReminderIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionMinEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsByReminderIdConnectionMinEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsByReminderIdConnectionMinEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionMinEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionMinFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByReminderIdConnectionMinFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByReminderIdConnectionMinIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_ID_ASC',
  FeedsByReminderIdConnectionMinIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_ID_DESC',
  FeedsByReminderIdConnectionMinParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByReminderIdConnectionMinParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByReminderIdConnectionMinPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByReminderIdConnectionMinPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByReminderIdConnectionMinProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionMinProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionMinReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionMinReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionMinRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionMinRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionMinRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByReminderIdConnectionMinRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByReminderIdConnectionMinRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionMinRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionMinSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionMinSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionMinSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionMinSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionMinTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByReminderIdConnectionMinTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByReminderIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByReminderIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByReminderIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByReminderIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByReminderIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByReminderIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByReminderIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByReminderIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByReminderIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByReminderIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByReminderIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByReminderIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByReminderIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByReminderIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByReminderIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByReminderIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByReminderIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByReminderIdConnectionStddevSampleEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByReminderIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByReminderIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByReminderIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByReminderIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByReminderIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByReminderIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByReminderIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionSumActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByReminderIdConnectionSumActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByReminderIdConnectionSumCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByReminderIdConnectionSumCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByReminderIdConnectionSumCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsByReminderIdConnectionSumCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsByReminderIdConnectionSumCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionSumCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionSumCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionSumCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionSumCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByReminderIdConnectionSumCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByReminderIdConnectionSumCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByReminderIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionSumCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByReminderIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionSumEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsByReminderIdConnectionSumEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsByReminderIdConnectionSumEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionSumEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionSumFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByReminderIdConnectionSumFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByReminderIdConnectionSumIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_ID_ASC',
  FeedsByReminderIdConnectionSumIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_ID_DESC',
  FeedsByReminderIdConnectionSumParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByReminderIdConnectionSumParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByReminderIdConnectionSumPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByReminderIdConnectionSumPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByReminderIdConnectionSumProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionSumProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionSumReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionSumReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionSumRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionSumRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionSumRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByReminderIdConnectionSumRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByReminderIdConnectionSumRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionSumRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionSumSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionSumSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionSumSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionSumSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionSumTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByReminderIdConnectionSumTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByReminderIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByReminderIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByReminderIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByReminderIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByReminderIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByReminderIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByReminderIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByReminderIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByReminderIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByReminderIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByReminderIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByReminderIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByReminderIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByReminderIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByReminderIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByReminderIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByReminderIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByReminderIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByReminderIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByReminderIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByReminderIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByReminderIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByReminderIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByReminderIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByReminderIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByReminderIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByReminderIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByReminderIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByReminderIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByReminderIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByReminderIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByReminderIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByReminderIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByReminderIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByReminderIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_REMINDER_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsAppointmentAsc = 'IS_APPOINTMENT_ASC',
  IsAppointmentDesc = 'IS_APPOINTMENT_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeAsc = 'PRIVILEGE_ASC',
  PrivilegeDesc = 'PRIVILEGE_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  PrivilegeTeamAsc = 'PRIVILEGE_TEAM_ASC',
  PrivilegeTeamDesc = 'PRIVILEGE_TEAM_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Role = Node & {
  active: Scalars['Boolean']['output'];
  /** Reads a single `Company` that is related to this `Role`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinations: Array<CompanyNotificationSettingsRoleDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsRoleDestination`. */
  companyNotificationSettingsRoleDestinationsConnection: CompanyNotificationSettingsRoleDestinationsConnection;
  /** Reads and enables pagination through a set of `CompanyUserRole`. */
  companyUserRolesByRolesId: Array<CompanyUserRole>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `DashboardSharedRole`. */
  dashboardSharedRoles: Array<DashboardSharedRole>;
  /** Reads and enables pagination through a set of `FilterToRole`. */
  filterToRoles: Array<FilterToRole>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToRoleRoleIdAndFilterId: RoleFiltersByFilterToRoleRoleIdAndFilterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToRoleRoleIdAndFilterIdList: Array<Filter>;
  /** Reads and enables pagination through a set of `GeolocationUserSetting`. */
  geolocationUserSettings: Array<GeolocationUserSetting>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `ProjectColumnAccess`. */
  projectColumnAccesses: Array<ProjectColumnAccess>;
  settings: Scalars['JSON']['output'];
};


export type RoleCompanyNotificationSettingsRoleDestinationsArgs = {
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type RoleCompanyNotificationSettingsRoleDestinationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsRoleDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsRoleDestinationsOrderBy>>;
};


export type RoleCompanyUserRolesByRolesIdArgs = {
  condition: InputMaybe<CompanyUserRoleCondition>;
  filter: InputMaybe<CompanyUserRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyUserRolesOrderBy>>;
};


export type RoleDashboardSharedRolesArgs = {
  condition: InputMaybe<DashboardSharedRoleCondition>;
  filter: InputMaybe<DashboardSharedRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedRolesOrderBy>>;
};


export type RoleFilterToRolesArgs = {
  condition: InputMaybe<FilterToRoleCondition>;
  filter: InputMaybe<FilterToRoleFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToRolesOrderBy>>;
};


export type RoleFiltersByFilterToRoleRoleIdAndFilterIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type RoleFiltersByFilterToRoleRoleIdAndFilterIdListArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type RoleGeolocationUserSettingsArgs = {
  condition: InputMaybe<GeolocationUserSettingCondition>;
  filter: InputMaybe<GeolocationUserSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationUserSettingsOrderBy>>;
};


export type RoleProjectColumnAccessesArgs = {
  condition: InputMaybe<ProjectColumnAccessCondition>;
  filter: InputMaybe<ProjectColumnAccessFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectColumnAccessesOrderBy>>;
};

/** A filter to be used against aggregates of `Role` object types. */
export type RoleAggregatesFilter = {
  /** Array aggregation aggregate over matching `Role` objects. */
  arrayAgg: InputMaybe<RoleArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Role` objects. */
  average: InputMaybe<RoleAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Role` objects. */
  distinctCount: InputMaybe<RoleDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Role` object to be included within the aggregate. */
  filter: InputMaybe<RoleFilter>;
  /** Maximum aggregate over matching `Role` objects. */
  max: InputMaybe<RoleMaxAggregateFilter>;
  /** Minimum aggregate over matching `Role` objects. */
  min: InputMaybe<RoleMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Role` objects. */
  stddevPopulation: InputMaybe<RoleStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Role` objects. */
  stddevSample: InputMaybe<RoleStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Role` objects. */
  sum: InputMaybe<RoleSumAggregateFilter>;
  /** Population variance aggregate over matching `Role` objects. */
  variancePopulation: InputMaybe<RoleVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Role` objects. */
  varianceSample: InputMaybe<RoleVarianceSampleAggregateFilter>;
};

export type RoleArrayAggAggregateFilter = {
  active: InputMaybe<BooleanListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
};

export type RoleAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** A condition to be used against `Role` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RoleCondition = {
  /** Checks for equality with the object’s `active` field. */
  active: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `settings` field. */
  settings: InputMaybe<Scalars['JSON']['input']>;
};

export type RoleDistinctCountAggregateFilter = {
  active: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  settings: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `Role` object types. All fields are combined with a logical ‘and.’ */
export type RoleFilter = {
  /** Filter by the object’s `active` field. */
  active: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingsRoleDestinations` relation. */
  companyNotificationSettingsRoleDestinations: InputMaybe<RoleToManyCompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `companyNotificationSettingsRoleDestinations` exist. */
  companyNotificationSettingsRoleDestinationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyUserRolesByRolesId` relation. */
  companyUserRolesByRolesId: InputMaybe<RoleToManyCompanyUserRoleFilter>;
  /** Some related `companyUserRolesByRolesId` exist. */
  companyUserRolesByRolesIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `dashboardSharedRoles` relation. */
  dashboardSharedRoles: InputMaybe<RoleToManyDashboardSharedRoleFilter>;
  /** Some related `dashboardSharedRoles` exist. */
  dashboardSharedRolesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filterToRoles` relation. */
  filterToRoles: InputMaybe<RoleToManyFilterToRoleFilter>;
  /** Some related `filterToRoles` exist. */
  filterToRolesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `geolocationUserSettings` relation. */
  geolocationUserSettings: InputMaybe<RoleToManyGeolocationUserSettingFilter>;
  /** Some related `geolocationUserSettings` exist. */
  geolocationUserSettingsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<RoleFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `projectColumnAccesses` relation. */
  projectColumnAccesses: InputMaybe<RoleToManyProjectColumnAccessFilter>;
  /** Some related `projectColumnAccesses` exist. */
  projectColumnAccessesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `settings` field. */
  settings: InputMaybe<JsonFilter>;
};

/** A connection to a list of `Filter` values, with data from `FilterToRole`. */
export type RoleFiltersByFilterToRoleRoleIdAndFilterIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterAggregates>;
  /** A list of edges which contains the `Filter`, info from the `FilterToRole`, and the cursor to aid in pagination. */
  edges: Array<RoleFiltersByFilterToRoleRoleIdAndFilterIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterAggregates>>;
  /** A list of `Filter` objects. */
  nodes: Array<Maybe<Filter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Filter` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Filter` values, with data from `FilterToRole`. */
export type RoleFiltersByFilterToRoleRoleIdAndFilterIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterGroupBy>;
  having: InputMaybe<FilterHavingInput>;
};

/** A `Filter` edge in the connection, with data from `FilterToRole`. */
export type RoleFiltersByFilterToRoleRoleIdAndFilterIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Filter` at the end of the edge. */
  node: Maybe<Filter>;
};

export type RoleMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

export type RoleMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
};

export type RoleStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type RoleStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type RoleSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `CompanyNotificationSettingsRoleDestination` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyCompanyNotificationSettingsRoleDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsRoleDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsRoleDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** No related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
  /** Some related `CompanyNotificationSettingsRoleDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsRoleDestinationFilter>;
};

/** A filter to be used against many `CompanyUserRole` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyCompanyUserRoleFilter = {
  /** Aggregates across related `CompanyUserRole` match the filter criteria. */
  aggregates: InputMaybe<CompanyUserRoleAggregatesFilter>;
  /** Every related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyUserRoleFilter>;
  /** No related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyUserRoleFilter>;
  /** Some related `CompanyUserRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyUserRoleFilter>;
};

/** A filter to be used against many `DashboardSharedRole` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyDashboardSharedRoleFilter = {
  /** Aggregates across related `DashboardSharedRole` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedRoleAggregatesFilter>;
  /** Every related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedRoleFilter>;
  /** No related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedRoleFilter>;
  /** Some related `DashboardSharedRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedRoleFilter>;
};

/** A filter to be used against many `FilterToRole` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyFilterToRoleFilter = {
  /** Aggregates across related `FilterToRole` match the filter criteria. */
  aggregates: InputMaybe<FilterToRoleAggregatesFilter>;
  /** Every related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToRoleFilter>;
  /** No related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToRoleFilter>;
  /** Some related `FilterToRole` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToRoleFilter>;
};

/** A filter to be used against many `GeolocationUserSetting` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyGeolocationUserSettingFilter = {
  /** Aggregates across related `GeolocationUserSetting` match the filter criteria. */
  aggregates: InputMaybe<GeolocationUserSettingAggregatesFilter>;
  /** Every related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeolocationUserSettingFilter>;
  /** No related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeolocationUserSettingFilter>;
  /** Some related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeolocationUserSettingFilter>;
};

/** A filter to be used against many `ProjectColumnAccess` object types. All fields are combined with a logical ‘and.’ */
export type RoleToManyProjectColumnAccessFilter = {
  /** Aggregates across related `ProjectColumnAccess` match the filter criteria. */
  aggregates: InputMaybe<ProjectColumnAccessAggregatesFilter>;
  /** Every related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectColumnAccessFilter>;
  /** No related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectColumnAccessFilter>;
  /** Some related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectColumnAccessFilter>;
};

export type RoleVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type RoleVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `Role`. */
export enum RolesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleRoleIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleRoleIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsRoleDestinationsConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_ROLE_DESTINATIONS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyUserRolesByRolesIdConnectionArrayAggCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_ARRAY_AGG_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionArrayAggCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_ARRAY_AGG_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionArrayAggRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_ARRAY_AGG_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionArrayAggRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_ARRAY_AGG_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionAverageCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_AVERAGE_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionAverageCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_AVERAGE_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionAverageRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_AVERAGE_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionAverageRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_AVERAGE_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionCountAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_COUNT_ASC',
  CompanyUserRolesByRolesIdConnectionCountDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_COUNT_DESC',
  CompanyUserRolesByRolesIdConnectionDistinctCountCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_DISTINCT_COUNT_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionDistinctCountCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_DISTINCT_COUNT_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionDistinctCountRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_DISTINCT_COUNT_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionDistinctCountRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_DISTINCT_COUNT_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionMaxCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MAX_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionMaxCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MAX_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionMaxRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MAX_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionMaxRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MAX_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionMinCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MIN_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionMinCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MIN_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionMinRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MIN_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionMinRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_MIN_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionStddevPopulationCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_POPULATION_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionStddevPopulationCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_POPULATION_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionStddevPopulationRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_POPULATION_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionStddevPopulationRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_POPULATION_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionStddevSampleCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionStddevSampleCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionStddevSampleRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_SAMPLE_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionStddevSampleRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_STDDEV_SAMPLE_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionSumCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_SUM_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionSumCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_SUM_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionSumRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_SUM_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionSumRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_SUM_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionVariancePopulationCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionVariancePopulationCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionVariancePopulationRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_POPULATION_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionVariancePopulationRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_POPULATION_ROLES_ID_DESC',
  CompanyUserRolesByRolesIdConnectionVarianceSampleCompanyUserIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_USER_ID_ASC',
  CompanyUserRolesByRolesIdConnectionVarianceSampleCompanyUserIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_USER_ID_DESC',
  CompanyUserRolesByRolesIdConnectionVarianceSampleRolesIdAsc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_SAMPLE_ROLES_ID_ASC',
  CompanyUserRolesByRolesIdConnectionVarianceSampleRolesIdDesc = 'COMPANY_USER_ROLES_BY_ROLES_ID_CONNECTION_VARIANCE_SAMPLE_ROLES_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DashboardSharedRolesConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionArrayAggRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  DashboardSharedRolesConnectionArrayAggRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  DashboardSharedRolesConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionAverageRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_AVERAGE_ROLE_ID_ASC',
  DashboardSharedRolesConnectionAverageRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_AVERAGE_ROLE_ID_DESC',
  DashboardSharedRolesConnectionCountAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_COUNT_ASC',
  DashboardSharedRolesConnectionCountDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_COUNT_DESC',
  DashboardSharedRolesConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionDistinctCountRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  DashboardSharedRolesConnectionDistinctCountRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  DashboardSharedRolesConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionMaxRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MAX_ROLE_ID_ASC',
  DashboardSharedRolesConnectionMaxRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MAX_ROLE_ID_DESC',
  DashboardSharedRolesConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionMinRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MIN_ROLE_ID_ASC',
  DashboardSharedRolesConnectionMinRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_MIN_ROLE_ID_DESC',
  DashboardSharedRolesConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionStddevPopulationRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  DashboardSharedRolesConnectionStddevPopulationRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  DashboardSharedRolesConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionStddevSampleRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  DashboardSharedRolesConnectionStddevSampleRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  DashboardSharedRolesConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionSumRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_SUM_ROLE_ID_ASC',
  DashboardSharedRolesConnectionSumRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_SUM_ROLE_ID_DESC',
  DashboardSharedRolesConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionVariancePopulationRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  DashboardSharedRolesConnectionVariancePopulationRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  DashboardSharedRolesConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedRolesConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedRolesConnectionVarianceSampleRoleIdAsc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  DashboardSharedRolesConnectionVarianceSampleRoleIdDesc = 'DASHBOARD_SHARED_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  FilterToRolesConnectionArrayAggFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToRolesConnectionArrayAggFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToRolesConnectionArrayAggRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  FilterToRolesConnectionArrayAggRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  FilterToRolesConnectionAverageFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToRolesConnectionAverageFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToRolesConnectionAverageRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_AVERAGE_ROLE_ID_ASC',
  FilterToRolesConnectionAverageRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_AVERAGE_ROLE_ID_DESC',
  FilterToRolesConnectionCountAsc = 'FILTER_TO_ROLES_CONNECTION_COUNT_ASC',
  FilterToRolesConnectionCountDesc = 'FILTER_TO_ROLES_CONNECTION_COUNT_DESC',
  FilterToRolesConnectionDistinctCountFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToRolesConnectionDistinctCountFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToRolesConnectionDistinctCountRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  FilterToRolesConnectionDistinctCountRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  FilterToRolesConnectionMaxFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToRolesConnectionMaxFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToRolesConnectionMaxRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_MAX_ROLE_ID_ASC',
  FilterToRolesConnectionMaxRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_MAX_ROLE_ID_DESC',
  FilterToRolesConnectionMinFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToRolesConnectionMinFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToRolesConnectionMinRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_MIN_ROLE_ID_ASC',
  FilterToRolesConnectionMinRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_MIN_ROLE_ID_DESC',
  FilterToRolesConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToRolesConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToRolesConnectionStddevPopulationRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  FilterToRolesConnectionStddevPopulationRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  FilterToRolesConnectionStddevSampleFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToRolesConnectionStddevSampleFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToRolesConnectionStddevSampleRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  FilterToRolesConnectionStddevSampleRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  FilterToRolesConnectionSumFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToRolesConnectionSumFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToRolesConnectionSumRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_SUM_ROLE_ID_ASC',
  FilterToRolesConnectionSumRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_SUM_ROLE_ID_DESC',
  FilterToRolesConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToRolesConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToRolesConnectionVariancePopulationRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  FilterToRolesConnectionVariancePopulationRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  FilterToRolesConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToRolesConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToRolesConnectionVarianceSampleRoleIdAsc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  FilterToRolesConnectionVarianceSampleRoleIdDesc = 'FILTER_TO_ROLES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionArrayAggCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionArrayAggEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  GeolocationUserSettingsConnectionArrayAggEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  GeolocationUserSettingsConnectionArrayAggIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionArrayAggTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionArrayAggTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionAverageCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionAverageCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionAverageCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionAverageCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionAverageEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ENABLED_ASC',
  GeolocationUserSettingsConnectionAverageEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ENABLED_DESC',
  GeolocationUserSettingsConnectionAverageIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ID_ASC',
  GeolocationUserSettingsConnectionAverageIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ID_DESC',
  GeolocationUserSettingsConnectionAverageRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionAverageRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionAverageTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionAverageTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionCountAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_COUNT_ASC',
  GeolocationUserSettingsConnectionCountDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_COUNT_DESC',
  GeolocationUserSettingsConnectionDistinctCountCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionDistinctCountCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionDistinctCountEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  GeolocationUserSettingsConnectionDistinctCountEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  GeolocationUserSettingsConnectionDistinctCountIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionDistinctCountTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionDistinctCountTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionMaxCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionMaxCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionMaxCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionMaxCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionMaxEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ENABLED_ASC',
  GeolocationUserSettingsConnectionMaxEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ENABLED_DESC',
  GeolocationUserSettingsConnectionMaxIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ID_ASC',
  GeolocationUserSettingsConnectionMaxIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ID_DESC',
  GeolocationUserSettingsConnectionMaxRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionMaxRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionMaxTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionMaxTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MAX_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionMinCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionMinCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionMinCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionMinCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionMinEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ENABLED_ASC',
  GeolocationUserSettingsConnectionMinEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ENABLED_DESC',
  GeolocationUserSettingsConnectionMinIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ID_ASC',
  GeolocationUserSettingsConnectionMinIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ID_DESC',
  GeolocationUserSettingsConnectionMinRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionMinRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionMinTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionMinTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_MIN_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionStddevPopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionStddevPopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsConnectionStddevPopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsConnectionStddevPopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevPopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionStddevPopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionStddevSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionStddevSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsConnectionStddevSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsConnectionStddevSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionStddevSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionStddevSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionSumCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionSumCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionSumCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionSumCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionSumEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ENABLED_ASC',
  GeolocationUserSettingsConnectionSumEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ENABLED_DESC',
  GeolocationUserSettingsConnectionSumIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ID_ASC',
  GeolocationUserSettingsConnectionSumIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ID_DESC',
  GeolocationUserSettingsConnectionSumRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionSumRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionSumTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionSumTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_SUM_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionVariancePopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionVariancePopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsConnectionVariancePopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsConnectionVariancePopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionVariancePopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionVariancePopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsConnectionVarianceSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsConnectionVarianceSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsConnectionVarianceSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsConnectionVarianceSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsConnectionVarianceSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsConnectionVarianceSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectColumnAccessesConnectionArrayAggColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectColumnAccessesConnectionArrayAggTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectColumnAccessesConnectionAverageColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionAverageColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionAverageIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ID_ASC',
  ProjectColumnAccessesConnectionAverageIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ID_DESC',
  ProjectColumnAccessesConnectionAverageRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionAverageRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionAverageTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionAverageTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionAverageTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectColumnAccessesConnectionAverageTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectColumnAccessesConnectionCountAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_COUNT_ASC',
  ProjectColumnAccessesConnectionCountDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_COUNT_DESC',
  ProjectColumnAccessesConnectionDistinctCountColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectColumnAccessesConnectionDistinctCountTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectColumnAccessesConnectionMaxColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionMaxColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionMaxIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ID_ASC',
  ProjectColumnAccessesConnectionMaxIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ID_DESC',
  ProjectColumnAccessesConnectionMaxRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionMaxRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionMaxTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionMaxTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionMaxTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TYPE_ASC',
  ProjectColumnAccessesConnectionMaxTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TYPE_DESC',
  ProjectColumnAccessesConnectionMinColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionMinColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionMinIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ID_ASC',
  ProjectColumnAccessesConnectionMinIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ID_DESC',
  ProjectColumnAccessesConnectionMinRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionMinRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionMinTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionMinTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionMinTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TYPE_ASC',
  ProjectColumnAccessesConnectionMinTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TYPE_DESC',
  ProjectColumnAccessesConnectionStddevPopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectColumnAccessesConnectionStddevPopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectColumnAccessesConnectionStddevSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesConnectionStddevSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectColumnAccessesConnectionSumColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionSumColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionSumIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ID_ASC',
  ProjectColumnAccessesConnectionSumIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ID_DESC',
  ProjectColumnAccessesConnectionSumRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionSumRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionSumTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionSumTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionSumTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TYPE_ASC',
  ProjectColumnAccessesConnectionSumTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TYPE_DESC',
  ProjectColumnAccessesConnectionVariancePopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectColumnAccessesConnectionVariancePopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectColumnAccessesConnectionVarianceSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesConnectionVarianceSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  SettingsAsc = 'SETTINGS_ASC',
  SettingsDesc = 'SETTINGS_DESC'
}

export enum SingleDestination {
  Assignee = 'ASSIGNEE',
  Collaborator = 'COLLABORATOR',
  Mentions = 'MENTIONS',
  Owner = 'OWNER',
  ProjectManager = 'PROJECT_MANAGER',
  SalesRep = 'SALES_REP'
}

/** A filter to be used against SingleDestination fields. All fields are combined with a logical ‘and.’ */
export type SingleDestinationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<SingleDestination>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<SingleDestination>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<SingleDestination>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<SingleDestination>;
  /** Included in the specified list. */
  in: InputMaybe<Array<SingleDestination>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<SingleDestination>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<SingleDestination>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<SingleDestination>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<SingleDestination>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<SingleDestination>>;
};

/** Methods to use when ordering `SmsActivity`. */
export enum SmsActivitiesOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FeedsConnectionArrayAggActionIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsConnectionArrayAggActionIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsConnectionArrayAggCallIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsConnectionArrayAggCallIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsConnectionArrayAggCommentAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsConnectionArrayAggCommentDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsConnectionArrayAggCommentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsConnectionArrayAggCommentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsConnectionArrayAggCompanyIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsConnectionArrayAggCompanyIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsConnectionArrayAggCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsConnectionArrayAggCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsConnectionArrayAggCreatedByAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionArrayAggCreatedByDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsConnectionArrayAggCreatedByTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsConnectionArrayAggCreatedByTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsConnectionArrayAggEmailMessageIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionArrayAggEmailMessageIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionArrayAggEventAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsConnectionArrayAggEventDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsConnectionArrayAggEventTypeAsc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsConnectionArrayAggEventTypeDesc = 'FEEDS_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsConnectionArrayAggFileIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsConnectionArrayAggFileIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsConnectionArrayAggIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsConnectionArrayAggIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsConnectionArrayAggParentIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsConnectionArrayAggParentIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsConnectionArrayAggPayloadAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsConnectionArrayAggPayloadDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsConnectionArrayAggProjectIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsConnectionArrayAggProjectIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsConnectionArrayAggReminderIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsConnectionArrayAggReminderIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsConnectionArrayAggRetriedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsConnectionArrayAggRetriedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsConnectionArrayAggRetryableAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsConnectionArrayAggRetryableDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsConnectionArrayAggRetryCountAsc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsConnectionArrayAggRetryCountDesc = 'FEEDS_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsConnectionArrayAggSearchTextAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsConnectionArrayAggSearchTextDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsConnectionArrayAggSmsActivityIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionArrayAggSmsActivityIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionArrayAggSystemIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsConnectionArrayAggSystemIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsConnectionArrayAggTaskIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsConnectionArrayAggTaskIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionArrayAggWorkflowIdAsc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsConnectionArrayAggWorkflowIdDesc = 'FEEDS_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsConnectionAverageActionIdAsc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsConnectionAverageActionIdDesc = 'FEEDS_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsConnectionAverageCallIdAsc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsConnectionAverageCallIdDesc = 'FEEDS_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsConnectionAverageCommentAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsConnectionAverageCommentDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsConnectionAverageCommentIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsConnectionAverageCommentIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsConnectionAverageCompanyIdAsc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsConnectionAverageCompanyIdDesc = 'FEEDS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsConnectionAverageCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsConnectionAverageCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsConnectionAverageCreatedByAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsConnectionAverageCreatedByContactIdAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionAverageCreatedByContactIdDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionAverageCreatedByDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsConnectionAverageCreatedByTypeAsc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsConnectionAverageCreatedByTypeDesc = 'FEEDS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsConnectionAverageEmailMessageIdAsc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionAverageEmailMessageIdDesc = 'FEEDS_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionAverageEventAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsConnectionAverageEventDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsConnectionAverageEventTypeAsc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsConnectionAverageEventTypeDesc = 'FEEDS_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsConnectionAverageFileIdAsc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsConnectionAverageFileIdDesc = 'FEEDS_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsConnectionAverageIdAsc = 'FEEDS_CONNECTION_AVERAGE_ID_ASC',
  FeedsConnectionAverageIdDesc = 'FEEDS_CONNECTION_AVERAGE_ID_DESC',
  FeedsConnectionAverageParentIdAsc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsConnectionAverageParentIdDesc = 'FEEDS_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsConnectionAveragePayloadAsc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsConnectionAveragePayloadDesc = 'FEEDS_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsConnectionAverageProjectIdAsc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsConnectionAverageProjectIdDesc = 'FEEDS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsConnectionAverageReminderIdAsc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsConnectionAverageReminderIdDesc = 'FEEDS_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsConnectionAverageRetriedAtAsc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsConnectionAverageRetriedAtDesc = 'FEEDS_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsConnectionAverageRetryableAsc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsConnectionAverageRetryableDesc = 'FEEDS_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsConnectionAverageRetryCountAsc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsConnectionAverageRetryCountDesc = 'FEEDS_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsConnectionAverageSearchTextAsc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsConnectionAverageSearchTextDesc = 'FEEDS_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsConnectionAverageSmsActivityIdAsc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionAverageSmsActivityIdDesc = 'FEEDS_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionAverageSystemIdAsc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsConnectionAverageSystemIdDesc = 'FEEDS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsConnectionAverageTaskIdAsc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsConnectionAverageTaskIdDesc = 'FEEDS_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsConnectionAverageVirtualCreatedAtAsc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionAverageVirtualCreatedAtDesc = 'FEEDS_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionAverageWorkflowIdAsc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsConnectionAverageWorkflowIdDesc = 'FEEDS_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsConnectionCountAsc = 'FEEDS_CONNECTION_COUNT_ASC',
  FeedsConnectionCountDesc = 'FEEDS_CONNECTION_COUNT_DESC',
  FeedsConnectionDistinctCountActionIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsConnectionDistinctCountActionIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsConnectionDistinctCountCallIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsConnectionDistinctCountCallIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsConnectionDistinctCountCommentAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsConnectionDistinctCountCommentDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsConnectionDistinctCountCommentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsConnectionDistinctCountCommentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsConnectionDistinctCountCompanyIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsConnectionDistinctCountCompanyIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsConnectionDistinctCountCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsConnectionDistinctCountCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsConnectionDistinctCountCreatedByAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionDistinctCountCreatedByDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionDistinctCountEventAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsConnectionDistinctCountEventDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsConnectionDistinctCountEventTypeAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsConnectionDistinctCountEventTypeDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsConnectionDistinctCountFileIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsConnectionDistinctCountFileIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsConnectionDistinctCountIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsConnectionDistinctCountIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsConnectionDistinctCountParentIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsConnectionDistinctCountParentIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsConnectionDistinctCountPayloadAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsConnectionDistinctCountPayloadDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsConnectionDistinctCountProjectIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsConnectionDistinctCountProjectIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsConnectionDistinctCountReminderIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsConnectionDistinctCountReminderIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsConnectionDistinctCountRetriedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsConnectionDistinctCountRetriedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsConnectionDistinctCountRetryableAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsConnectionDistinctCountRetryableDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsConnectionDistinctCountRetryCountAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsConnectionDistinctCountRetryCountDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsConnectionDistinctCountSearchTextAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsConnectionDistinctCountSearchTextDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionDistinctCountSystemIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsConnectionDistinctCountSystemIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsConnectionDistinctCountTaskIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsConnectionDistinctCountTaskIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionDistinctCountWorkflowIdAsc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsConnectionDistinctCountWorkflowIdDesc = 'FEEDS_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsConnectionMaxActionIdAsc = 'FEEDS_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsConnectionMaxActionIdDesc = 'FEEDS_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsConnectionMaxCallIdAsc = 'FEEDS_CONNECTION_MAX_CALL_ID_ASC',
  FeedsConnectionMaxCallIdDesc = 'FEEDS_CONNECTION_MAX_CALL_ID_DESC',
  FeedsConnectionMaxCommentAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ASC',
  FeedsConnectionMaxCommentDesc = 'FEEDS_CONNECTION_MAX_COMMENT_DESC',
  FeedsConnectionMaxCommentIdAsc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsConnectionMaxCommentIdDesc = 'FEEDS_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsConnectionMaxCompanyIdAsc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsConnectionMaxCompanyIdDesc = 'FEEDS_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsConnectionMaxCreatedAtAsc = 'FEEDS_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsConnectionMaxCreatedAtDesc = 'FEEDS_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsConnectionMaxCreatedByAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsConnectionMaxCreatedByContactIdAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMaxCreatedByContactIdDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMaxCreatedByDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsConnectionMaxCreatedByTypeAsc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsConnectionMaxCreatedByTypeDesc = 'FEEDS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsConnectionMaxEmailMessageIdAsc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMaxEmailMessageIdDesc = 'FEEDS_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMaxEventAsc = 'FEEDS_CONNECTION_MAX_EVENT_ASC',
  FeedsConnectionMaxEventDesc = 'FEEDS_CONNECTION_MAX_EVENT_DESC',
  FeedsConnectionMaxEventTypeAsc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsConnectionMaxEventTypeDesc = 'FEEDS_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsConnectionMaxFileIdAsc = 'FEEDS_CONNECTION_MAX_FILE_ID_ASC',
  FeedsConnectionMaxFileIdDesc = 'FEEDS_CONNECTION_MAX_FILE_ID_DESC',
  FeedsConnectionMaxIdAsc = 'FEEDS_CONNECTION_MAX_ID_ASC',
  FeedsConnectionMaxIdDesc = 'FEEDS_CONNECTION_MAX_ID_DESC',
  FeedsConnectionMaxParentIdAsc = 'FEEDS_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsConnectionMaxParentIdDesc = 'FEEDS_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsConnectionMaxPayloadAsc = 'FEEDS_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsConnectionMaxPayloadDesc = 'FEEDS_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsConnectionMaxProjectIdAsc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsConnectionMaxProjectIdDesc = 'FEEDS_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsConnectionMaxReminderIdAsc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsConnectionMaxReminderIdDesc = 'FEEDS_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsConnectionMaxRetriedAtAsc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsConnectionMaxRetriedAtDesc = 'FEEDS_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsConnectionMaxRetryableAsc = 'FEEDS_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsConnectionMaxRetryableDesc = 'FEEDS_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsConnectionMaxRetryCountAsc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsConnectionMaxRetryCountDesc = 'FEEDS_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsConnectionMaxSearchTextAsc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsConnectionMaxSearchTextDesc = 'FEEDS_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsConnectionMaxSmsActivityIdAsc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMaxSmsActivityIdDesc = 'FEEDS_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMaxSystemIdAsc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsConnectionMaxSystemIdDesc = 'FEEDS_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsConnectionMaxTaskIdAsc = 'FEEDS_CONNECTION_MAX_TASK_ID_ASC',
  FeedsConnectionMaxTaskIdDesc = 'FEEDS_CONNECTION_MAX_TASK_ID_DESC',
  FeedsConnectionMaxVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMaxVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMaxWorkflowIdAsc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsConnectionMaxWorkflowIdDesc = 'FEEDS_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsConnectionMinActionIdAsc = 'FEEDS_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsConnectionMinActionIdDesc = 'FEEDS_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsConnectionMinCallIdAsc = 'FEEDS_CONNECTION_MIN_CALL_ID_ASC',
  FeedsConnectionMinCallIdDesc = 'FEEDS_CONNECTION_MIN_CALL_ID_DESC',
  FeedsConnectionMinCommentAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ASC',
  FeedsConnectionMinCommentDesc = 'FEEDS_CONNECTION_MIN_COMMENT_DESC',
  FeedsConnectionMinCommentIdAsc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsConnectionMinCommentIdDesc = 'FEEDS_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsConnectionMinCompanyIdAsc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsConnectionMinCompanyIdDesc = 'FEEDS_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsConnectionMinCreatedAtAsc = 'FEEDS_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsConnectionMinCreatedAtDesc = 'FEEDS_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsConnectionMinCreatedByAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsConnectionMinCreatedByContactIdAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionMinCreatedByContactIdDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionMinCreatedByDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsConnectionMinCreatedByTypeAsc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsConnectionMinCreatedByTypeDesc = 'FEEDS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsConnectionMinEmailMessageIdAsc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionMinEmailMessageIdDesc = 'FEEDS_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionMinEventAsc = 'FEEDS_CONNECTION_MIN_EVENT_ASC',
  FeedsConnectionMinEventDesc = 'FEEDS_CONNECTION_MIN_EVENT_DESC',
  FeedsConnectionMinEventTypeAsc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsConnectionMinEventTypeDesc = 'FEEDS_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsConnectionMinFileIdAsc = 'FEEDS_CONNECTION_MIN_FILE_ID_ASC',
  FeedsConnectionMinFileIdDesc = 'FEEDS_CONNECTION_MIN_FILE_ID_DESC',
  FeedsConnectionMinIdAsc = 'FEEDS_CONNECTION_MIN_ID_ASC',
  FeedsConnectionMinIdDesc = 'FEEDS_CONNECTION_MIN_ID_DESC',
  FeedsConnectionMinParentIdAsc = 'FEEDS_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsConnectionMinParentIdDesc = 'FEEDS_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsConnectionMinPayloadAsc = 'FEEDS_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsConnectionMinPayloadDesc = 'FEEDS_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsConnectionMinProjectIdAsc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsConnectionMinProjectIdDesc = 'FEEDS_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsConnectionMinReminderIdAsc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsConnectionMinReminderIdDesc = 'FEEDS_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsConnectionMinRetriedAtAsc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsConnectionMinRetriedAtDesc = 'FEEDS_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsConnectionMinRetryableAsc = 'FEEDS_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsConnectionMinRetryableDesc = 'FEEDS_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsConnectionMinRetryCountAsc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsConnectionMinRetryCountDesc = 'FEEDS_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsConnectionMinSearchTextAsc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsConnectionMinSearchTextDesc = 'FEEDS_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsConnectionMinSmsActivityIdAsc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionMinSmsActivityIdDesc = 'FEEDS_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionMinSystemIdAsc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsConnectionMinSystemIdDesc = 'FEEDS_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsConnectionMinTaskIdAsc = 'FEEDS_CONNECTION_MIN_TASK_ID_ASC',
  FeedsConnectionMinTaskIdDesc = 'FEEDS_CONNECTION_MIN_TASK_ID_DESC',
  FeedsConnectionMinVirtualCreatedAtAsc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionMinVirtualCreatedAtDesc = 'FEEDS_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionMinWorkflowIdAsc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsConnectionMinWorkflowIdDesc = 'FEEDS_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsConnectionStddevPopulationActionIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsConnectionStddevPopulationActionIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsConnectionStddevPopulationCallIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsConnectionStddevPopulationCallIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsConnectionStddevPopulationCommentAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsConnectionStddevPopulationCommentDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsConnectionStddevPopulationCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionStddevPopulationCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionStddevPopulationCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionStddevPopulationCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionStddevPopulationCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevPopulationCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevPopulationEventAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsConnectionStddevPopulationEventDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsConnectionStddevPopulationEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionStddevPopulationEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionStddevPopulationFileIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsConnectionStddevPopulationFileIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsConnectionStddevPopulationIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsConnectionStddevPopulationIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsConnectionStddevPopulationParentIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsConnectionStddevPopulationParentIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsConnectionStddevPopulationPayloadAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsConnectionStddevPopulationPayloadDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsConnectionStddevPopulationProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionStddevPopulationProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionStddevPopulationReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionStddevPopulationReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionStddevPopulationRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionStddevPopulationRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionStddevPopulationRetryableAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsConnectionStddevPopulationRetryableDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsConnectionStddevPopulationRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionStddevPopulationRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionStddevPopulationSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionStddevPopulationSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevPopulationSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionStddevPopulationSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionStddevPopulationTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsConnectionStddevPopulationTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionStddevSampleActionIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionStddevSampleActionIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionStddevSampleCallIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsConnectionStddevSampleCallIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsConnectionStddevSampleCommentAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsConnectionStddevSampleCommentDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsConnectionStddevSampleCommentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionStddevSampleCommentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionStddevSampleCompanyIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionStddevSampleCompanyIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionStddevSampleCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionStddevSampleCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionStddevSampleCreatedByAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionStddevSampleCreatedByDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionStddevSampleEventAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsConnectionStddevSampleEventDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsConnectionStddevSampleEventTypeAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionStddevSampleEventTypeDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionStddevSampleFileIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsConnectionStddevSampleFileIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsConnectionStddevSampleIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsConnectionStddevSampleIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsConnectionStddevSampleParentIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionStddevSampleParentIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionStddevSamplePayloadAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionStddevSamplePayloadDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionStddevSampleProjectIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionStddevSampleProjectIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionStddevSampleReminderIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionStddevSampleReminderIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionStddevSampleRetriedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionStddevSampleRetriedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionStddevSampleRetryableAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionStddevSampleRetryableDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionStddevSampleRetryCountAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionStddevSampleRetryCountDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionStddevSampleSearchTextAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionStddevSampleSearchTextDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionStddevSampleSystemIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionStddevSampleSystemIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionStddevSampleTaskIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsConnectionStddevSampleTaskIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionStddevSampleWorkflowIdAsc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionStddevSampleWorkflowIdDesc = 'FEEDS_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsConnectionSumActionIdAsc = 'FEEDS_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsConnectionSumActionIdDesc = 'FEEDS_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsConnectionSumCallIdAsc = 'FEEDS_CONNECTION_SUM_CALL_ID_ASC',
  FeedsConnectionSumCallIdDesc = 'FEEDS_CONNECTION_SUM_CALL_ID_DESC',
  FeedsConnectionSumCommentAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ASC',
  FeedsConnectionSumCommentDesc = 'FEEDS_CONNECTION_SUM_COMMENT_DESC',
  FeedsConnectionSumCommentIdAsc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsConnectionSumCommentIdDesc = 'FEEDS_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsConnectionSumCompanyIdAsc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsConnectionSumCompanyIdDesc = 'FEEDS_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsConnectionSumCreatedAtAsc = 'FEEDS_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsConnectionSumCreatedAtDesc = 'FEEDS_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsConnectionSumCreatedByAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsConnectionSumCreatedByContactIdAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionSumCreatedByContactIdDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionSumCreatedByDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsConnectionSumCreatedByTypeAsc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsConnectionSumCreatedByTypeDesc = 'FEEDS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsConnectionSumEmailMessageIdAsc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionSumEmailMessageIdDesc = 'FEEDS_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionSumEventAsc = 'FEEDS_CONNECTION_SUM_EVENT_ASC',
  FeedsConnectionSumEventDesc = 'FEEDS_CONNECTION_SUM_EVENT_DESC',
  FeedsConnectionSumEventTypeAsc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsConnectionSumEventTypeDesc = 'FEEDS_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsConnectionSumFileIdAsc = 'FEEDS_CONNECTION_SUM_FILE_ID_ASC',
  FeedsConnectionSumFileIdDesc = 'FEEDS_CONNECTION_SUM_FILE_ID_DESC',
  FeedsConnectionSumIdAsc = 'FEEDS_CONNECTION_SUM_ID_ASC',
  FeedsConnectionSumIdDesc = 'FEEDS_CONNECTION_SUM_ID_DESC',
  FeedsConnectionSumParentIdAsc = 'FEEDS_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsConnectionSumParentIdDesc = 'FEEDS_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsConnectionSumPayloadAsc = 'FEEDS_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsConnectionSumPayloadDesc = 'FEEDS_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsConnectionSumProjectIdAsc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsConnectionSumProjectIdDesc = 'FEEDS_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsConnectionSumReminderIdAsc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsConnectionSumReminderIdDesc = 'FEEDS_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsConnectionSumRetriedAtAsc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsConnectionSumRetriedAtDesc = 'FEEDS_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsConnectionSumRetryableAsc = 'FEEDS_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsConnectionSumRetryableDesc = 'FEEDS_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsConnectionSumRetryCountAsc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsConnectionSumRetryCountDesc = 'FEEDS_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsConnectionSumSearchTextAsc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsConnectionSumSearchTextDesc = 'FEEDS_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsConnectionSumSmsActivityIdAsc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionSumSmsActivityIdDesc = 'FEEDS_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionSumSystemIdAsc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsConnectionSumSystemIdDesc = 'FEEDS_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsConnectionSumTaskIdAsc = 'FEEDS_CONNECTION_SUM_TASK_ID_ASC',
  FeedsConnectionSumTaskIdDesc = 'FEEDS_CONNECTION_SUM_TASK_ID_DESC',
  FeedsConnectionSumVirtualCreatedAtAsc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionSumVirtualCreatedAtDesc = 'FEEDS_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionSumWorkflowIdAsc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsConnectionSumWorkflowIdDesc = 'FEEDS_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsConnectionVariancePopulationActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsConnectionVariancePopulationActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsConnectionVariancePopulationCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsConnectionVariancePopulationCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsConnectionVariancePopulationCommentAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsConnectionVariancePopulationCommentDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsConnectionVariancePopulationCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsConnectionVariancePopulationCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsConnectionVariancePopulationCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsConnectionVariancePopulationCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsConnectionVariancePopulationCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVariancePopulationCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVariancePopulationEventAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsConnectionVariancePopulationEventDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsConnectionVariancePopulationEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsConnectionVariancePopulationEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsConnectionVariancePopulationFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsConnectionVariancePopulationFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsConnectionVariancePopulationIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsConnectionVariancePopulationIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsConnectionVariancePopulationParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsConnectionVariancePopulationParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsConnectionVariancePopulationPayloadAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsConnectionVariancePopulationPayloadDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsConnectionVariancePopulationProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsConnectionVariancePopulationProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsConnectionVariancePopulationReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsConnectionVariancePopulationReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsConnectionVariancePopulationRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsConnectionVariancePopulationRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsConnectionVariancePopulationRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsConnectionVariancePopulationRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsConnectionVariancePopulationRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsConnectionVariancePopulationRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsConnectionVariancePopulationSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsConnectionVariancePopulationSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVariancePopulationSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsConnectionVariancePopulationSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsConnectionVariancePopulationTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsConnectionVariancePopulationTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsConnectionVarianceSampleActionIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsConnectionVarianceSampleActionIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsConnectionVarianceSampleCallIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsConnectionVarianceSampleCallIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsConnectionVarianceSampleCommentAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsConnectionVarianceSampleCommentDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsConnectionVarianceSampleCommentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsConnectionVarianceSampleCommentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsConnectionVarianceSampleCompanyIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsConnectionVarianceSampleCompanyIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsConnectionVarianceSampleCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleCreatedByAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsConnectionVarianceSampleCreatedByDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsConnectionVarianceSampleEventAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsConnectionVarianceSampleEventDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsConnectionVarianceSampleEventTypeAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsConnectionVarianceSampleEventTypeDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsConnectionVarianceSampleFileIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsConnectionVarianceSampleFileIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsConnectionVarianceSampleIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsConnectionVarianceSampleIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsConnectionVarianceSampleParentIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsConnectionVarianceSampleParentIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsConnectionVarianceSamplePayloadAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsConnectionVarianceSamplePayloadDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsConnectionVarianceSampleProjectIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsConnectionVarianceSampleProjectIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsConnectionVarianceSampleReminderIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsConnectionVarianceSampleReminderIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsConnectionVarianceSampleRetriedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsConnectionVarianceSampleRetriedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsConnectionVarianceSampleRetryableAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsConnectionVarianceSampleRetryableDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsConnectionVarianceSampleRetryCountAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsConnectionVarianceSampleRetryCountDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsConnectionVarianceSampleSearchTextAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsConnectionVarianceSampleSearchTextDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsConnectionVarianceSampleSystemIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsConnectionVarianceSampleSystemIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsConnectionVarianceSampleTaskIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsConnectionVarianceSampleTaskIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FromCompanyPhoneIdAsc = 'FROM_COMPANY_PHONE_ID_ASC',
  FromCompanyPhoneIdDesc = 'FROM_COMPANY_PHONE_ID_DESC',
  FromContactIdAsc = 'FROM_CONTACT_ID_ASC',
  FromContactIdDesc = 'FROM_CONTACT_ID_DESC',
  FromJurisdictionIdAsc = 'FROM_JURISDICTION_ID_ASC',
  FromJurisdictionIdDesc = 'FROM_JURISDICTION_ID_DESC',
  FromPhoneAsc = 'FROM_PHONE_ASC',
  FromPhoneDesc = 'FROM_PHONE_DESC',
  FromUserIdAsc = 'FROM_USER_ID_ASC',
  FromUserIdDesc = 'FROM_USER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsIncomingAsc = 'IS_INCOMING_ASC',
  IsIncomingDesc = 'IS_INCOMING_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ScheduledForAsc = 'SCHEDULED_FOR_ASC',
  ScheduledForDesc = 'SCHEDULED_FOR_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  ToCompanyPhoneIdAsc = 'TO_COMPANY_PHONE_ID_ASC',
  ToCompanyPhoneIdDesc = 'TO_COMPANY_PHONE_ID_DESC',
  ToContactIdAsc = 'TO_CONTACT_ID_ASC',
  ToContactIdDesc = 'TO_CONTACT_ID_DESC',
  ToJurisdictionIdAsc = 'TO_JURISDICTION_ID_ASC',
  ToJurisdictionIdDesc = 'TO_JURISDICTION_ID_DESC',
  ToPhoneAsc = 'TO_PHONE_ASC',
  ToPhoneDesc = 'TO_PHONE_DESC',
  ToUserIdAsc = 'TO_USER_ID_ASC',
  ToUserIdDesc = 'TO_USER_ID_DESC',
  TwilioSidAsc = 'TWILIO_SID_ASC',
  TwilioSidDesc = 'TWILIO_SID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type SmsActivity = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedSmsActivityIdAndActionId: SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedSmsActivityIdAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedSmsActivityIdAndCallId: SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedSmsActivityIdAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedSmsActivityIdAndCommentId: SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyConnection;
  /** Reads a single `Company` that is related to this `SmsActivity`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedSmsActivityIdAndCreatedByContactId: SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedSmsActivityIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `SmsActivity`. */
  createdByUser: Maybe<User>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedSmsActivityIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedSmsActivityIdAndParentId: SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedSmsActivityIdAndFileId: SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedSmsActivityIdAndFileIdList: Array<File>;
  /** Reads a single `CompanyPhone` that is related to this `SmsActivity`. */
  fromCompanyPhone: Maybe<CompanyPhone>;
  fromCompanyPhoneId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `SmsActivity`. */
  fromContact: Maybe<Contact>;
  fromContactId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Jurisdiction` that is related to this `SmsActivity`. */
  fromJurisdiction: Maybe<Jurisdiction>;
  fromJurisdictionId: Maybe<Scalars['Int']['output']>;
  fromPhone: Scalars['String']['output'];
  /** Reads a single `User` that is related to this `SmsActivity`. */
  fromUser: Maybe<User>;
  fromUserId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isIncoming: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedSmsActivityIdAndTaskId: SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyConnection;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedSmsActivityIdAndProjectId: SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedSmsActivityIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedSmsActivityIdAndReminderId: SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedSmsActivityIdAndReminderIdList: Array<Reminder>;
  scheduledFor: Maybe<Scalars['Datetime']['output']>;
  status: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedSmsActivityIdAndSystemId: SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyConnection;
  text: Scalars['String']['output'];
  /** Reads a single `CompanyPhone` that is related to this `SmsActivity`. */
  toCompanyPhone: Maybe<CompanyPhone>;
  toCompanyPhoneId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `SmsActivity`. */
  toContact: Maybe<Contact>;
  toContactId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Jurisdiction` that is related to this `SmsActivity`. */
  toJurisdiction: Maybe<Jurisdiction>;
  toJurisdictionId: Maybe<Scalars['Int']['output']>;
  toPhone: Scalars['String']['output'];
  /** Reads a single `User` that is related to this `SmsActivity`. */
  toUser: Maybe<User>;
  toUserId: Maybe<Scalars['Int']['output']>;
  twilioSid: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedSmsActivityIdAndCreatedBy: SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyConnection;
};


export type SmsActivityActionsByFeedSmsActivityIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type SmsActivityActionsByFeedSmsActivityIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type SmsActivityCallsByFeedSmsActivityIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type SmsActivityCallsByFeedSmsActivityIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type SmsActivityCommentsByFeedSmsActivityIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type SmsActivityEmailMessagesByFeedSmsActivityIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type SmsActivityFeedsByFeedSmsActivityIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type SmsActivityFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type SmsActivityFilesByFeedSmsActivityIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type SmsActivityFilesByFeedSmsActivityIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type SmsActivitySystemsByFeedSmsActivityIdAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type SmsActivityUsersByFeedSmsActivityIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type SmsActivityActionsByFeedSmsActivityIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against aggregates of `SmsActivity` object types. */
export type SmsActivityAggregatesFilter = {
  /** Array aggregation aggregate over matching `SmsActivity` objects. */
  arrayAgg: InputMaybe<SmsActivityArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SmsActivity` objects. */
  average: InputMaybe<SmsActivityAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SmsActivity` objects. */
  distinctCount: InputMaybe<SmsActivityDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SmsActivity` object to be included within the aggregate. */
  filter: InputMaybe<SmsActivityFilter>;
  /** Maximum aggregate over matching `SmsActivity` objects. */
  max: InputMaybe<SmsActivityMaxAggregateFilter>;
  /** Minimum aggregate over matching `SmsActivity` objects. */
  min: InputMaybe<SmsActivityMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SmsActivity` objects. */
  stddevPopulation: InputMaybe<SmsActivityStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SmsActivity` objects. */
  stddevSample: InputMaybe<SmsActivityStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SmsActivity` objects. */
  sum: InputMaybe<SmsActivitySumAggregateFilter>;
  /** Population variance aggregate over matching `SmsActivity` objects. */
  variancePopulation: InputMaybe<SmsActivityVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SmsActivity` objects. */
  varianceSample: InputMaybe<SmsActivityVarianceSampleAggregateFilter>;
};

export type SmsActivityArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  fromCompanyPhoneId: InputMaybe<IntListFilter>;
  fromContactId: InputMaybe<IntListFilter>;
  fromJurisdictionId: InputMaybe<IntListFilter>;
  fromUserId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isIncoming: InputMaybe<BooleanListFilter>;
  projectId: InputMaybe<IntListFilter>;
  toCompanyPhoneId: InputMaybe<IntListFilter>;
  toContactId: InputMaybe<IntListFilter>;
  toJurisdictionId: InputMaybe<IntListFilter>;
  toUserId: InputMaybe<IntListFilter>;
};

export type SmsActivityAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fromCompanyPhoneId: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  fromJurisdictionId: InputMaybe<BigFloatFilter>;
  fromUserId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toCompanyPhoneId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
  toJurisdictionId: InputMaybe<BigFloatFilter>;
  toUserId: InputMaybe<BigFloatFilter>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type SmsActivityCallsByFeedSmsActivityIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type SmsActivityCommentsByFeedSmsActivityIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/**
 * A condition to be used against `SmsActivity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SmsActivityCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `fromCompanyPhoneId` field. */
  fromCompanyPhoneId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `fromContactId` field. */
  fromContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `fromJurisdictionId` field. */
  fromJurisdictionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `fromPhone` field. */
  fromPhone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `fromUserId` field. */
  fromUserId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isIncoming` field. */
  isIncoming: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `scheduledFor` field. */
  scheduledFor: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `text` field. */
  text: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `toCompanyPhoneId` field. */
  toCompanyPhoneId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `toContactId` field. */
  toContactId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `toJurisdictionId` field. */
  toJurisdictionId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `toPhone` field. */
  toPhone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `toUserId` field. */
  toUserId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `twilioSid` field. */
  twilioSid: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type SmsActivityContactsByFeedSmsActivityIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SmsActivityDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  fromCompanyPhoneId: InputMaybe<BigIntFilter>;
  fromContactId: InputMaybe<BigIntFilter>;
  fromJurisdictionId: InputMaybe<BigIntFilter>;
  fromPhone: InputMaybe<BigIntFilter>;
  fromUserId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isIncoming: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  scheduledFor: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  text: InputMaybe<BigIntFilter>;
  toCompanyPhoneId: InputMaybe<BigIntFilter>;
  toContactId: InputMaybe<BigIntFilter>;
  toJurisdictionId: InputMaybe<BigIntFilter>;
  toPhone: InputMaybe<BigIntFilter>;
  toUserId: InputMaybe<BigIntFilter>;
  twilioSid: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type SmsActivityFeedsByFeedSmsActivityIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type SmsActivityFilesByFeedSmsActivityIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type SmsActivityFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SmsActivityFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feeds` relation. */
  feeds: InputMaybe<SmsActivityToManyFeedFilter>;
  /** Some related `feeds` exist. */
  feedsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromCompanyPhone` relation. */
  fromCompanyPhone: InputMaybe<CompanyPhoneFilter>;
  /** A related `fromCompanyPhone` exists. */
  fromCompanyPhoneExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromCompanyPhoneId` field. */
  fromCompanyPhoneId: InputMaybe<IntFilter>;
  /** Filter by the object’s `fromContact` relation. */
  fromContact: InputMaybe<ContactFilter>;
  /** A related `fromContact` exists. */
  fromContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromContactId` field. */
  fromContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `fromJurisdiction` relation. */
  fromJurisdiction: InputMaybe<JurisdictionFilter>;
  /** A related `fromJurisdiction` exists. */
  fromJurisdictionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromJurisdictionId` field. */
  fromJurisdictionId: InputMaybe<IntFilter>;
  /** Filter by the object’s `fromPhone` field. */
  fromPhone: InputMaybe<StringFilter>;
  /** Filter by the object’s `fromUser` relation. */
  fromUser: InputMaybe<UserFilter>;
  /** A related `fromUser` exists. */
  fromUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `fromUserId` field. */
  fromUserId: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isIncoming` field. */
  isIncoming: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<SmsActivityFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SmsActivityFilter>>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `scheduledFor` field. */
  scheduledFor: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<StringFilter>;
  /** Filter by the object’s `text` field. */
  text: InputMaybe<StringFilter>;
  /** Filter by the object’s `toCompanyPhone` relation. */
  toCompanyPhone: InputMaybe<CompanyPhoneFilter>;
  /** A related `toCompanyPhone` exists. */
  toCompanyPhoneExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `toCompanyPhoneId` field. */
  toCompanyPhoneId: InputMaybe<IntFilter>;
  /** Filter by the object’s `toContact` relation. */
  toContact: InputMaybe<ContactFilter>;
  /** A related `toContact` exists. */
  toContactExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `toContactId` field. */
  toContactId: InputMaybe<IntFilter>;
  /** Filter by the object’s `toJurisdiction` relation. */
  toJurisdiction: InputMaybe<JurisdictionFilter>;
  /** A related `toJurisdiction` exists. */
  toJurisdictionExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `toJurisdictionId` field. */
  toJurisdictionId: InputMaybe<IntFilter>;
  /** Filter by the object’s `toPhone` field. */
  toPhone: InputMaybe<StringFilter>;
  /** Filter by the object’s `toUser` relation. */
  toUser: InputMaybe<UserFilter>;
  /** A related `toUser` exists. */
  toUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `toUserId` field. */
  toUserId: InputMaybe<IntFilter>;
  /** Filter by the object’s `twilioSid` field. */
  twilioSid: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type SmsActivityMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  fromCompanyPhoneId: InputMaybe<IntFilter>;
  fromContactId: InputMaybe<IntFilter>;
  fromJurisdictionId: InputMaybe<IntFilter>;
  fromUserId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  scheduledFor: InputMaybe<DatetimeFilter>;
  toCompanyPhoneId: InputMaybe<IntFilter>;
  toContactId: InputMaybe<IntFilter>;
  toJurisdictionId: InputMaybe<IntFilter>;
  toUserId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type SmsActivityMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  fromCompanyPhoneId: InputMaybe<IntFilter>;
  fromContactId: InputMaybe<IntFilter>;
  fromJurisdictionId: InputMaybe<IntFilter>;
  fromUserId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  scheduledFor: InputMaybe<DatetimeFilter>;
  toCompanyPhoneId: InputMaybe<IntFilter>;
  toContactId: InputMaybe<IntFilter>;
  toJurisdictionId: InputMaybe<IntFilter>;
  toUserId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type SmsActivityPrivilegedTasksByFeedSmsActivityIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type SmsActivityProjectsByFeedSmsActivityIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type SmsActivityRemindersByFeedSmsActivityIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SmsActivityStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fromCompanyPhoneId: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  fromJurisdictionId: InputMaybe<BigFloatFilter>;
  fromUserId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toCompanyPhoneId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
  toJurisdictionId: InputMaybe<BigFloatFilter>;
  toUserId: InputMaybe<BigFloatFilter>;
};

export type SmsActivityStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fromCompanyPhoneId: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  fromJurisdictionId: InputMaybe<BigFloatFilter>;
  fromUserId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toCompanyPhoneId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
  toJurisdictionId: InputMaybe<BigFloatFilter>;
  toUserId: InputMaybe<BigFloatFilter>;
};

export type SmsActivitySumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  fromCompanyPhoneId: InputMaybe<BigIntFilter>;
  fromContactId: InputMaybe<BigIntFilter>;
  fromJurisdictionId: InputMaybe<BigIntFilter>;
  fromUserId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  toCompanyPhoneId: InputMaybe<BigIntFilter>;
  toContactId: InputMaybe<BigIntFilter>;
  toJurisdictionId: InputMaybe<BigIntFilter>;
  toUserId: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type SmsActivitySystemsByFeedSmsActivityIdAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type SmsActivityToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type SmsActivityUsersByFeedSmsActivityIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SmsActivityVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fromCompanyPhoneId: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  fromJurisdictionId: InputMaybe<BigFloatFilter>;
  fromUserId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toCompanyPhoneId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
  toJurisdictionId: InputMaybe<BigFloatFilter>;
  toUserId: InputMaybe<BigFloatFilter>;
};

export type SmsActivityVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  fromCompanyPhoneId: InputMaybe<BigFloatFilter>;
  fromContactId: InputMaybe<BigFloatFilter>;
  fromJurisdictionId: InputMaybe<BigFloatFilter>;
  fromUserId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  toCompanyPhoneId: InputMaybe<BigFloatFilter>;
  toContactId: InputMaybe<BigFloatFilter>;
  toJurisdictionId: InputMaybe<BigFloatFilter>;
  toUserId: InputMaybe<BigFloatFilter>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan: InputMaybe<Scalars['String']['input']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than the specified value. */
  anyLessThan: InputMaybe<Scalars['String']['input']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo: InputMaybe<Scalars['String']['input']>;
  /** Contained by the specified list of values. */
  containedBy: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Contains the specified list of values. */
  contains: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Overlaps the specified list of values. */
  overlaps: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubTask = Node & {
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `SubTask`. */
  createdByUser: Maybe<User>;
  date: Maybe<Scalars['Datetime']['output']>;
  detail: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  /** Reads a single `PrivilegedTask` that is related to this `SubTask`. */
  privilegedTaskId: Maybe<PrivilegedTask>;
  /** Reads a single `Task` that is related to this `SubTask`. */
  task: Maybe<Task>;
  taskId: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Datetime']['output'];
};

/** A filter to be used against aggregates of `SubTask` object types. */
export type SubTaskAggregatesFilter = {
  /** Array aggregation aggregate over matching `SubTask` objects. */
  arrayAgg: InputMaybe<SubTaskArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SubTask` objects. */
  average: InputMaybe<SubTaskAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SubTask` objects. */
  distinctCount: InputMaybe<SubTaskDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SubTask` object to be included within the aggregate. */
  filter: InputMaybe<SubTaskFilter>;
  /** Maximum aggregate over matching `SubTask` objects. */
  max: InputMaybe<SubTaskMaxAggregateFilter>;
  /** Minimum aggregate over matching `SubTask` objects. */
  min: InputMaybe<SubTaskMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SubTask` objects. */
  stddevPopulation: InputMaybe<SubTaskStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SubTask` objects. */
  stddevSample: InputMaybe<SubTaskStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SubTask` objects. */
  sum: InputMaybe<SubTaskSumAggregateFilter>;
  /** Population variance aggregate over matching `SubTask` objects. */
  variancePopulation: InputMaybe<SubTaskVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SubTask` objects. */
  varianceSample: InputMaybe<SubTaskVarianceSampleAggregateFilter>;
};

export type SubTaskArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  position: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type SubTaskAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/** A condition to be used against `SubTask` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SubTaskCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `date` field. */
  date: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `detail` field. */
  detail: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type SubTaskDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  date: InputMaybe<BigIntFilter>;
  detail: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SubTask` object types. All fields are combined with a logical ‘and.’ */
export type SubTaskFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SubTaskFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `date` field. */
  date: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `detail` field. */
  detail: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<SubTaskFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SubTaskFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `privilegedTaskId` relation. */
  privilegedTaskId: InputMaybe<PrivilegedTaskFilter>;
  /** A related `privilegedTaskId` exists. */
  privilegedTaskIdExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type SubTaskMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  date: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type SubTaskMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  date: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type SubTaskStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type SubTaskStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type SubTaskSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type SubTaskVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type SubTaskVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SubTask`. */
export enum SubTasksOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  DetailAsc = 'DETAIL_ASC',
  DetailDesc = 'DETAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum SyncFrequency {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH'
}

/** A filter to be used against SyncFrequency fields. All fields are combined with a logical ‘and.’ */
export type SyncFrequencyFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<SyncFrequency>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<SyncFrequency>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<SyncFrequency>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<SyncFrequency>;
  /** Included in the specified list. */
  in: InputMaybe<Array<SyncFrequency>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<SyncFrequency>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<SyncFrequency>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<SyncFrequency>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<SyncFrequency>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<SyncFrequency>>;
};

export type System = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedSystemIdAndActionId: SystemActionsByFeedSystemIdAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedSystemIdAndActionIdList: Array<Action>;
  address: Maybe<Scalars['String']['output']>;
  addressCity: Maybe<Scalars['String']['output']>;
  addressForced: Maybe<Scalars['String']['output']>;
  addressNormalized: Maybe<NormAddy>;
  addressState: Maybe<Scalars['String']['output']>;
  addressStreet: Maybe<Scalars['String']['output']>;
  addressZip: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedSystemIdAndCallId: SystemCallsByFeedSystemIdAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedSystemIdAndCallIdList: Array<Call>;
  clientType: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedSystemIdAndCommentId: SystemCommentsByFeedSystemIdAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsBySystemIdConnection: CommentsConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByPrivilegedTaskSystemIdAndCompanyIdList: Array<Company>;
  connectionType: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedSystemIdAndCreatedByContactId: SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedSystemIdAndCreatedByContactIdList: Array<Contact>;
  createdAt: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedSystemIdAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedSystemIdAndParentId: SystemFeedsByFeedSystemIdAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentSystemIdAndFileId: SystemFilesByCommentSystemIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCommentSystemIdAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedSystemIdAndFileId: SystemFilesByFeedSystemIdAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedSystemIdAndFileIdList: Array<File>;
  id: Scalars['Int']['output'];
  installedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `Integration` that is related to this `System`. */
  integration: Maybe<Integration>;
  integrationId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByPrivilegedTaskSystemIdAndFirstLabelIdList: Array<Label>;
  lastReportAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskSystemIdAndAssigneeIdList: Array<MainUser>;
  /** Reads and enables pagination through a set of `MainUser`. */
  mainUsersByPrivilegedTaskSystemIdAndCreatedByList: Array<MainUser>;
  monitored: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['BigInt']['output']>;
  operationStatus: Maybe<Scalars['String']['output']>;
  operationalAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByCommentSystemIdAndTaskId: SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedSystemIdAndTaskId: SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskId: SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksBySystemIdConnection: PrivilegedTasksConnection;
  /** Reads a single `SystemProfile` that is related to this `System`. */
  profile: Maybe<SystemProfile>;
  profileId: Maybe<Scalars['Int']['output']>;
  profileOverrides: Maybe<Scalars['JSON']['output']>;
  /** Reads a single `Project` that is related to this `System`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByPrivilegedTaskSystemIdAndProjectStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentSystemIdAndProjectId: SystemProjectsByCommentSystemIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCommentSystemIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedSystemIdAndProjectId: SystemProjectsByFeedSystemIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedSystemIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskSystemIdAndProjectId: SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByPrivilegedTaskSystemIdAndProjectIdList: Array<Project>;
  providerId: Scalars['String']['output'];
  providerStatus: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedSystemIdAndReminderId: SystemRemindersByFeedSystemIdAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedSystemIdAndReminderIdList: Array<Reminder>;
  size: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedSystemIdAndSmsActivityIdList: Array<SmsActivity>;
  status: Maybe<SystemStatus>;
  /** Reads and enables pagination through a set of `SystemLogThread`. */
  systemLogThreadsBySystemIdConnection: SystemLogThreadsConnection;
  /** Reads and enables pagination through a set of `SystemLog`. */
  systemLogsBySystemIdConnection: SystemLogsConnection;
  timezone: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByFeedSystemIdAndCreatedBy: SystemUsersByFeedSystemIdAndCreatedByManyToManyConnection;
  uuid: Scalars['UUID']['output'];
};


export type SystemActionsByFeedSystemIdAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type SystemActionsByFeedSystemIdAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type SystemCallsByFeedSystemIdAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type SystemCallsByFeedSystemIdAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type SystemCommentsByFeedSystemIdAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type SystemCommentsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type SystemCompaniesByPrivilegedTaskSystemIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type SystemContactsByFeedSystemIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type SystemContactsByFeedSystemIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type SystemEmailMessagesByFeedSystemIdAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type SystemFeedsByFeedSystemIdAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type SystemFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type SystemFilesByCommentSystemIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type SystemFilesByCommentSystemIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type SystemFilesByFeedSystemIdAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type SystemFilesByFeedSystemIdAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type SystemLabelsByPrivilegedTaskSystemIdAndFirstLabelIdListArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type SystemMainUsersByPrivilegedTaskSystemIdAndAssigneeIdListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type SystemMainUsersByPrivilegedTaskSystemIdAndCreatedByListArgs = {
  condition: InputMaybe<MainUserCondition>;
  filter: InputMaybe<MainUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<MainUsersOrderBy>>;
};


export type SystemPrivilegedTasksByCommentSystemIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type SystemPrivilegedTasksByFeedSystemIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type SystemPrivilegedTasksBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type SystemProjectStagesByPrivilegedTaskSystemIdAndProjectStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type SystemProjectsByCommentSystemIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProjectsByCommentSystemIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProjectsByFeedSystemIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProjectsByFeedSystemIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemRemindersByFeedSystemIdAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type SystemRemindersByFeedSystemIdAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type SystemSmsActivitiesByFeedSystemIdAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type SystemSystemLogThreadsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemLogThreadCondition>;
  filter: InputMaybe<SystemLogThreadFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemSystemLogsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemLogCondition>;
  filter: InputMaybe<SystemLogFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemUsersByFeedSystemIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type SystemActionsByFeedSystemIdAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemActionsByFeedSystemIdAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type SystemActionsByFeedSystemIdAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type SystemActionsByFeedSystemIdAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type SystemActionsByFeedSystemIdAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SystemAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<SystemArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<SystemAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<SystemDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<SystemMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<SystemMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<SystemStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<SystemStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<SystemSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<SystemVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<SystemVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `System` object types. */
export type SystemAggregatesFilter = {
  /** Array aggregation aggregate over matching `System` objects. */
  arrayAgg: InputMaybe<SystemArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `System` objects. */
  average: InputMaybe<SystemAverageAggregateFilter>;
  /** Distinct count aggregate over matching `System` objects. */
  distinctCount: InputMaybe<SystemDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `System` object to be included within the aggregate. */
  filter: InputMaybe<SystemFilter>;
  /** Maximum aggregate over matching `System` objects. */
  max: InputMaybe<SystemMaxAggregateFilter>;
  /** Minimum aggregate over matching `System` objects. */
  min: InputMaybe<SystemMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `System` objects. */
  stddevPopulation: InputMaybe<SystemStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `System` objects. */
  stddevSample: InputMaybe<SystemStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `System` objects. */
  sum: InputMaybe<SystemSumAggregateFilter>;
  /** Population variance aggregate over matching `System` objects. */
  variancePopulation: InputMaybe<SystemVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `System` objects. */
  varianceSample: InputMaybe<SystemVarianceSampleAggregateFilter>;
};

export type SystemAlert = Node & {
  alertConfigId: Scalars['Int']['output'];
  condition: Scalars['JSON']['output'];
  context: Scalars['JSON']['output'];
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  resolvedAt: Maybe<Scalars['Datetime']['output']>;
  status: AlertStatus;
  systemId: Scalars['Int']['output'];
};

/**
 * A condition to be used against `SystemAlert` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SystemAlertCondition = {
  /** Checks for equality with the object’s `alertConfigId` field. */
  alertConfigId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `condition` field. */
  condition: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `context` field. */
  context: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `resolvedAt` field. */
  resolvedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<AlertStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
};

/** A filter to be used against `SystemAlert` object types. All fields are combined with a logical ‘and.’ */
export type SystemAlertFilter = {
  /** Filter by the object’s `alertConfigId` field. */
  alertConfigId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemAlertFilter>>;
  /** Filter by the object’s `condition` field. */
  condition: InputMaybe<JsonFilter>;
  /** Filter by the object’s `context` field. */
  context: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemAlertFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemAlertFilter>>;
  /** Filter by the object’s `resolvedAt` field. */
  resolvedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<AlertStatusFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
};

export type SystemAlertProfile = Node & {
  alertId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `SystemProfile` that is related to this `SystemAlertProfile`. */
  profile: Maybe<SystemProfile>;
  profileId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `SystemAlertProfile` object types. */
export type SystemAlertProfileAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemAlertProfile` objects. */
  arrayAgg: InputMaybe<SystemAlertProfileArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemAlertProfile` objects. */
  average: InputMaybe<SystemAlertProfileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemAlertProfile` objects. */
  distinctCount: InputMaybe<SystemAlertProfileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemAlertProfile` object to be included within the aggregate. */
  filter: InputMaybe<SystemAlertProfileFilter>;
  /** Maximum aggregate over matching `SystemAlertProfile` objects. */
  max: InputMaybe<SystemAlertProfileMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemAlertProfile` objects. */
  min: InputMaybe<SystemAlertProfileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemAlertProfile` objects. */
  stddevPopulation: InputMaybe<SystemAlertProfileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemAlertProfile` objects. */
  stddevSample: InputMaybe<SystemAlertProfileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemAlertProfile` objects. */
  sum: InputMaybe<SystemAlertProfileSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemAlertProfile` objects. */
  variancePopulation: InputMaybe<SystemAlertProfileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemAlertProfile` objects. */
  varianceSample: InputMaybe<SystemAlertProfileVarianceSampleAggregateFilter>;
};

export type SystemAlertProfileArrayAggAggregateFilter = {
  alertId: InputMaybe<IntListFilter>;
  profileId: InputMaybe<IntListFilter>;
};

export type SystemAlertProfileAverageAggregateFilter = {
  alertId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `SystemAlertProfile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SystemAlertProfileCondition = {
  /** Checks for equality with the object’s `alertId` field. */
  alertId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId: InputMaybe<Scalars['Int']['input']>;
};

export type SystemAlertProfileDistinctCountAggregateFilter = {
  alertId: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SystemAlertProfile` object types. All fields are combined with a logical ‘and.’ */
export type SystemAlertProfileFilter = {
  /** Filter by the object’s `alertId` field. */
  alertId: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemAlertProfileFilter>>;
  /** Negates the expression. */
  not: InputMaybe<SystemAlertProfileFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemAlertProfileFilter>>;
  /** Filter by the object’s `profile` relation. */
  profile: InputMaybe<SystemProfileFilter>;
  /** Filter by the object’s `profileId` field. */
  profileId: InputMaybe<IntFilter>;
};

export type SystemAlertProfileMaxAggregateFilter = {
  alertId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemAlertProfileMinAggregateFilter = {
  alertId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemAlertProfileStddevPopulationAggregateFilter = {
  alertId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemAlertProfileStddevSampleAggregateFilter = {
  alertId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemAlertProfileSumAggregateFilter = {
  alertId: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

export type SystemAlertProfileVariancePopulationAggregateFilter = {
  alertId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemAlertProfileVarianceSampleAggregateFilter = {
  alertId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SystemAlertProfile`. */
export enum SystemAlertProfilesOrderBy {
  AlertIdAsc = 'ALERT_ID_ASC',
  AlertIdDesc = 'ALERT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC'
}

/** Methods to use when ordering `SystemAlert`. */
export enum SystemAlertsOrderBy {
  AlertConfigIdAsc = 'ALERT_CONFIG_ID_ASC',
  AlertConfigIdDesc = 'ALERT_CONFIG_ID_DESC',
  ConditionAsc = 'CONDITION_ASC',
  ConditionDesc = 'CONDITION_DESC',
  ContextAsc = 'CONTEXT_ASC',
  ContextDesc = 'CONTEXT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ResolvedAtAsc = 'RESOLVED_AT_ASC',
  ResolvedAtDesc = 'RESOLVED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC'
}

export type SystemArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  addressCity: InputMaybe<StringListFilter>;
  addressForced: InputMaybe<StringListFilter>;
  addressState: InputMaybe<StringListFilter>;
  addressStreet: InputMaybe<StringListFilter>;
  addressZip: InputMaybe<StringListFilter>;
  clientType: InputMaybe<StringListFilter>;
  connectionType: InputMaybe<StringListFilter>;
  id: InputMaybe<IntListFilter>;
  integrationId: InputMaybe<IntListFilter>;
  monitored: InputMaybe<BooleanListFilter>;
  name: InputMaybe<StringListFilter>;
  notes: InputMaybe<StringListFilter>;
  operationStatus: InputMaybe<StringListFilter>;
  profileId: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  providerId: InputMaybe<StringListFilter>;
  providerStatus: InputMaybe<StringListFilter>;
  size: InputMaybe<IntListFilter>;
  status: InputMaybe<SystemStatusListFilter>;
  timezone: InputMaybe<StringListFilter>;
};

export type SystemArrayAggAggregates = {
  /** Array aggregation of address across the matching connection */
  address: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressCity across the matching connection */
  addressCity: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressForced across the matching connection */
  addressForced: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressState across the matching connection */
  addressState: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressStreet across the matching connection */
  addressStreet: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of addressZip across the matching connection */
  addressZip: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of clientType across the matching connection */
  clientType: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of connectionType across the matching connection */
  connectionType: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of integrationId across the matching connection */
  integrationId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of monitored across the matching connection */
  monitored: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of name across the matching connection */
  name: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of notes across the matching connection */
  notes: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of operationStatus across the matching connection */
  operationStatus: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of profileId across the matching connection */
  profileId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of providerId across the matching connection */
  providerId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of providerStatus across the matching connection */
  providerStatus: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of size across the matching connection */
  size: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of status across the matching connection */
  status: Array<Maybe<SystemStatus>>;
  /** Array aggregation of timezone across the matching connection */
  timezone: Array<Maybe<Scalars['String']['output']>>;
};


export type SystemArrayAggAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesAddressCityArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesAddressForcedArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesAddressStateArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesAddressStreetArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesAddressZipArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesClientTypeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesConnectionTypeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesMonitoredArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesNameArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesNotesArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesOperationStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesProviderIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesProviderStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemArrayAggAggregatesTimezoneArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  size: InputMaybe<BigFloatFilter>;
};

export type SystemAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of number across the matching connection */
  number: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of profileId across the matching connection */
  profileId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of size across the matching connection */
  size: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemAverageAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemAverageAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemAverageAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemAverageAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemBudget = Node & {
  /** Reads a single `Company` that is related to this `SystemBudget`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  monthlyBudget: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  overbudgetBehaviour: OverbudgetBehaviour;
  /** Reads and enables pagination through a set of `SystemBudgetProfileWhitelist`. */
  systemBudgetProfileWhitelists: Array<SystemBudgetProfileWhitelist>;
  /** Reads and enables pagination through a set of `SystemProfile`. */
  whitelistProfiles: Array<SystemProfile>;
};


export type SystemBudgetSystemBudgetProfileWhitelistsArgs = {
  condition: InputMaybe<SystemBudgetProfileWhitelistCondition>;
  filter: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemBudgetProfileWhitelistsOrderBy>>;
};


export type SystemBudgetWhitelistProfilesArgs = {
  condition: InputMaybe<SystemProfileCondition>;
  filter: InputMaybe<SystemProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfilesOrderBy>>;
};

/** A filter to be used against aggregates of `SystemBudget` object types. */
export type SystemBudgetAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemBudget` objects. */
  arrayAgg: InputMaybe<SystemBudgetArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemBudget` objects. */
  average: InputMaybe<SystemBudgetAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemBudget` objects. */
  distinctCount: InputMaybe<SystemBudgetDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemBudget` object to be included within the aggregate. */
  filter: InputMaybe<SystemBudgetFilter>;
  /** Maximum aggregate over matching `SystemBudget` objects. */
  max: InputMaybe<SystemBudgetMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemBudget` objects. */
  min: InputMaybe<SystemBudgetMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemBudget` objects. */
  stddevPopulation: InputMaybe<SystemBudgetStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemBudget` objects. */
  stddevSample: InputMaybe<SystemBudgetStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemBudget` objects. */
  sum: InputMaybe<SystemBudgetSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemBudget` objects. */
  variancePopulation: InputMaybe<SystemBudgetVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemBudget` objects. */
  varianceSample: InputMaybe<SystemBudgetVarianceSampleAggregateFilter>;
};

export type SystemBudgetArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  monthlyBudget: InputMaybe<IntListFilter>;
};

export type SystemBudgetAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  monthlyBudget: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `SystemBudget` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SystemBudgetCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `monthlyBudget` field. */
  monthlyBudget: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `overbudgetBehaviour` field. */
  overbudgetBehaviour: InputMaybe<OverbudgetBehaviour>;
};

export type SystemBudgetDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  monthlyBudget: InputMaybe<BigIntFilter>;
  overbudgetBehaviour: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SystemBudget` object types. All fields are combined with a logical ‘and.’ */
export type SystemBudgetFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemBudgetFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `monthlyBudget` field. */
  monthlyBudget: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemBudgetFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemBudgetFilter>>;
  /** Filter by the object’s `overbudgetBehaviour` field. */
  overbudgetBehaviour: InputMaybe<OverbudgetBehaviourFilter>;
  /** Filter by the object’s `systemBudgetProfileWhitelists` relation. */
  systemBudgetProfileWhitelists: InputMaybe<SystemBudgetToManySystemBudgetProfileWhitelistFilter>;
  /** Some related `systemBudgetProfileWhitelists` exist. */
  systemBudgetProfileWhitelistsExist: InputMaybe<Scalars['Boolean']['input']>;
};

export type SystemBudgetMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  monthlyBudget: InputMaybe<IntFilter>;
};

export type SystemBudgetMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  monthlyBudget: InputMaybe<IntFilter>;
};

export type SystemBudgetProfileWhitelist = Node & {
  /** Reads a single `SystemBudget` that is related to this `SystemBudgetProfileWhitelist`. */
  budget: Maybe<SystemBudget>;
  budgetId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `SystemProfile` that is related to this `SystemBudgetProfileWhitelist`. */
  profile: Maybe<SystemProfile>;
  profileId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `SystemBudgetProfileWhitelist` object types. */
export type SystemBudgetProfileWhitelistAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  arrayAgg: InputMaybe<SystemBudgetProfileWhitelistArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  average: InputMaybe<SystemBudgetProfileWhitelistAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  distinctCount: InputMaybe<SystemBudgetProfileWhitelistDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemBudgetProfileWhitelist` object to be included within the aggregate. */
  filter: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** Maximum aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  max: InputMaybe<SystemBudgetProfileWhitelistMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  min: InputMaybe<SystemBudgetProfileWhitelistMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  stddevPopulation: InputMaybe<SystemBudgetProfileWhitelistStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  stddevSample: InputMaybe<SystemBudgetProfileWhitelistStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  sum: InputMaybe<SystemBudgetProfileWhitelistSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  variancePopulation: InputMaybe<SystemBudgetProfileWhitelistVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemBudgetProfileWhitelist` objects. */
  varianceSample: InputMaybe<SystemBudgetProfileWhitelistVarianceSampleAggregateFilter>;
};

export type SystemBudgetProfileWhitelistArrayAggAggregateFilter = {
  budgetId: InputMaybe<IntListFilter>;
  profileId: InputMaybe<IntListFilter>;
};

export type SystemBudgetProfileWhitelistAverageAggregateFilter = {
  budgetId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `SystemBudgetProfileWhitelist` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SystemBudgetProfileWhitelistCondition = {
  /** Checks for equality with the object’s `budgetId` field. */
  budgetId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId: InputMaybe<Scalars['Int']['input']>;
};

export type SystemBudgetProfileWhitelistDistinctCountAggregateFilter = {
  budgetId: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SystemBudgetProfileWhitelist` object types. All fields are combined with a logical ‘and.’ */
export type SystemBudgetProfileWhitelistFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemBudgetProfileWhitelistFilter>>;
  /** Filter by the object’s `budget` relation. */
  budget: InputMaybe<SystemBudgetFilter>;
  /** Filter by the object’s `budgetId` field. */
  budgetId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemBudgetProfileWhitelistFilter>>;
  /** Filter by the object’s `profile` relation. */
  profile: InputMaybe<SystemProfileFilter>;
  /** Filter by the object’s `profileId` field. */
  profileId: InputMaybe<IntFilter>;
};

export type SystemBudgetProfileWhitelistMaxAggregateFilter = {
  budgetId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemBudgetProfileWhitelistMinAggregateFilter = {
  budgetId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemBudgetProfileWhitelistStddevPopulationAggregateFilter = {
  budgetId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetProfileWhitelistStddevSampleAggregateFilter = {
  budgetId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetProfileWhitelistSumAggregateFilter = {
  budgetId: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

export type SystemBudgetProfileWhitelistVariancePopulationAggregateFilter = {
  budgetId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetProfileWhitelistVarianceSampleAggregateFilter = {
  budgetId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SystemBudgetProfileWhitelist`. */
export enum SystemBudgetProfileWhitelistsOrderBy {
  BudgetIdAsc = 'BUDGET_ID_ASC',
  BudgetIdDesc = 'BUDGET_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC'
}

export type SystemBudgetStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  monthlyBudget: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  monthlyBudget: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  monthlyBudget: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `SystemBudgetProfileWhitelist` object types. All fields are combined with a logical ‘and.’ */
export type SystemBudgetToManySystemBudgetProfileWhitelistFilter = {
  /** Aggregates across related `SystemBudgetProfileWhitelist` match the filter criteria. */
  aggregates: InputMaybe<SystemBudgetProfileWhitelistAggregatesFilter>;
  /** Every related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** No related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** Some related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemBudgetProfileWhitelistFilter>;
};

export type SystemBudgetVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  monthlyBudget: InputMaybe<BigFloatFilter>;
};

export type SystemBudgetVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  monthlyBudget: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SystemBudget`. */
export enum SystemBudgetsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MonthlyBudgetAsc = 'MONTHLY_BUDGET_ASC',
  MonthlyBudgetDesc = 'MONTHLY_BUDGET_DESC',
  Natural = 'NATURAL',
  OverbudgetBehaviourAsc = 'OVERBUDGET_BEHAVIOUR_ASC',
  OverbudgetBehaviourDesc = 'OVERBUDGET_BEHAVIOUR_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SystemBudgetProfileWhitelistsConnectionArrayAggBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionArrayAggBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionArrayAggProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionArrayAggProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionAverageBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionAverageBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionAverageProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionAverageProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionCountAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_COUNT_ASC',
  SystemBudgetProfileWhitelistsConnectionCountDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_COUNT_DESC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMaxBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMaxBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMaxProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMaxProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMinBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMinBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMinProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMinProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionSumBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionSumBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionSumProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionSumProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC'
}

/** A connection to a list of `Call` values, with data from `Feed`. */
export type SystemCallsByFeedSystemIdAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemCallsByFeedSystemIdAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type SystemCallsByFeedSystemIdAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type SystemCallsByFeedSystemIdAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type SystemCallsByFeedSystemIdAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** The return type of our `systemClusterConnection` query. */
export type SystemClusterRecord = {
  center: Maybe<Point>;
  cluster: Maybe<Scalars['Int']['output']>;
  clusterSize: Maybe<Scalars['BigInt']['output']>;
  clusterStatuses: Maybe<Array<Maybe<SystemStatus>>>;
  sampleSystemId: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type SystemCommentsByFeedSystemIdAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemCommentsByFeedSystemIdAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type SystemCommentsByFeedSystemIdAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type SystemCommentsByFeedSystemIdAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type SystemCommentsByFeedSystemIdAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A condition to be used against `System` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SystemCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressCity` field. */
  addressCity: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressForced` field. */
  addressForced: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyInput>;
  /** Checks for equality with the object’s `addressState` field. */
  addressState: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressStreet` field. */
  addressStreet: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `addressZip` field. */
  addressZip: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `clientType` field. */
  clientType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `connectionType` field. */
  connectionType: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `installedAt` field. */
  installedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastReportAt` field. */
  lastReportAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `monitored` field. */
  monitored: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `number` field. */
  number: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `operationStatus` field. */
  operationStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `operationalAt` field. */
  operationalAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `profileOverrides` field. */
  profileOverrides: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `providerStatus` field. */
  providerStatus: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `size` field. */
  size: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<SystemStatus>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid: InputMaybe<Scalars['UUID']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type SystemContactsByFeedSystemIdAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SystemDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  addressCity: InputMaybe<BigIntFilter>;
  addressForced: InputMaybe<BigIntFilter>;
  addressNormalized: InputMaybe<BigIntFilter>;
  addressState: InputMaybe<BigIntFilter>;
  addressStreet: InputMaybe<BigIntFilter>;
  addressZip: InputMaybe<BigIntFilter>;
  clientType: InputMaybe<BigIntFilter>;
  connectionType: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  installedAt: InputMaybe<BigIntFilter>;
  integrationId: InputMaybe<BigIntFilter>;
  lastReportAt: InputMaybe<BigIntFilter>;
  monitored: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  notes: InputMaybe<BigIntFilter>;
  number: InputMaybe<BigIntFilter>;
  operationStatus: InputMaybe<BigIntFilter>;
  operationalAt: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
  profileOverrides: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  providerId: InputMaybe<BigIntFilter>;
  providerStatus: InputMaybe<BigIntFilter>;
  size: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  timezone: InputMaybe<BigIntFilter>;
  uuid: InputMaybe<BigIntFilter>;
};

export type SystemDistinctCountAggregates = {
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressCity across the matching connection */
  addressCity: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressForced across the matching connection */
  addressForced: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressNormalized across the matching connection */
  addressNormalized: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressState across the matching connection */
  addressState: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressStreet across the matching connection */
  addressStreet: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of addressZip across the matching connection */
  addressZip: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of clientType across the matching connection */
  clientType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of connectionType across the matching connection */
  connectionType: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of installedAt across the matching connection */
  installedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastReportAt across the matching connection */
  lastReportAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of monitored across the matching connection */
  monitored: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of notes across the matching connection */
  notes: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of number across the matching connection */
  number: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of operationStatus across the matching connection */
  operationStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of operationalAt across the matching connection */
  operationalAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profileId across the matching connection */
  profileId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profileOverrides across the matching connection */
  profileOverrides: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of providerId across the matching connection */
  providerId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of providerStatus across the matching connection */
  providerStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of size across the matching connection */
  size: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timezone across the matching connection */
  timezone: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uuid across the matching connection */
  uuid: Maybe<Scalars['BigInt']['output']>;
};


export type SystemDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressCityArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressForcedArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressNormalizedArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressStateArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressStreetArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesAddressZipArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesClientTypeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesConnectionTypeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesInstalledAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesLastReportAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesMonitoredArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesNotesArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesOperationStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesOperationalAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesProfileOverridesArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesProviderIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesProviderStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesTimezoneArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemDistinctCountAggregatesUuidArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type SystemFeedsByFeedSystemIdAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemFeedsByFeedSystemIdAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type SystemFeedsByFeedSystemIdAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type SystemFeedsByFeedSystemIdAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type SystemFeedsByFeedSystemIdAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Comment`. */
export type SystemFilesByCommentSystemIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<SystemFilesByCommentSystemIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Comment`. */
export type SystemFilesByCommentSystemIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Comment`. */
export type SystemFilesByCommentSystemIdAndFileIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Comment`. */
export type SystemFilesByCommentSystemIdAndFileIdManyToManyEdgeCommentsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type SystemFilesByFeedSystemIdAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemFilesByFeedSystemIdAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type SystemFilesByFeedSystemIdAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type SystemFilesByFeedSystemIdAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type SystemFilesByFeedSystemIdAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A filter to be used against `System` object types. All fields are combined with a logical ‘and.’ */
export type SystemFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressCity` field. */
  addressCity: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressForced` field. */
  addressForced: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressNormalized` field. */
  addressNormalized: InputMaybe<NormAddyFilter>;
  /** Filter by the object’s `addressState` field. */
  addressState: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressStreet` field. */
  addressStreet: InputMaybe<StringFilter>;
  /** Filter by the object’s `addressZip` field. */
  addressZip: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemFilter>>;
  /** Filter by the object’s `clientType` field. */
  clientType: InputMaybe<StringFilter>;
  /** Filter by the object’s `commentsBySystemId` relation. */
  commentsBySystemId: InputMaybe<SystemToManyCommentFilter>;
  /** Some related `commentsBySystemId` exist. */
  commentsBySystemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `connectionType` field. */
  connectionType: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feedsBySystemId` relation. */
  feedsBySystemId: InputMaybe<SystemToManyFeedFilter>;
  /** Some related `feedsBySystemId` exist. */
  feedsBySystemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `installedAt` field. */
  installedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `integration` relation. */
  integration: InputMaybe<IntegrationFilter>;
  /** A related `integration` exists. */
  integrationExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `integrationId` field. */
  integrationId: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastReportAt` field. */
  lastReportAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `monitored` field. */
  monitored: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemFilter>;
  /** Filter by the object’s `notes` field. */
  notes: InputMaybe<StringFilter>;
  /** Filter by the object’s `number` field. */
  number: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `operationStatus` field. */
  operationStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `operationalAt` field. */
  operationalAt: InputMaybe<DatetimeFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemFilter>>;
  /** Filter by the object’s `privilegedTasksBySystemId` relation. */
  privilegedTasksBySystemId: InputMaybe<SystemToManyPrivilegedTaskFilter>;
  /** Some related `privilegedTasksBySystemId` exist. */
  privilegedTasksBySystemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `profile` relation. */
  profile: InputMaybe<SystemProfileFilter>;
  /** A related `profile` exists. */
  profileExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `profileId` field. */
  profileId: InputMaybe<IntFilter>;
  /** Filter by the object’s `profileOverrides` field. */
  profileOverrides: InputMaybe<JsonFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerStatus` field. */
  providerStatus: InputMaybe<StringFilter>;
  /** Filter by the object’s `size` field. */
  size: InputMaybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<SystemStatusFilter>;
  /** Filter by the object’s `systemLogThreadsBySystemId` relation. */
  systemLogThreadsBySystemId: InputMaybe<SystemToManySystemLogThreadFilter>;
  /** Some related `systemLogThreadsBySystemId` exist. */
  systemLogThreadsBySystemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemLogsBySystemId` relation. */
  systemLogsBySystemId: InputMaybe<SystemToManySystemLogFilter>;
  /** Some related `systemLogsBySystemId` exist. */
  systemLogsBySystemIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `timezone` field. */
  timezone: InputMaybe<StringFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid: InputMaybe<UuidFilter>;
};

/** Grouping methods for `System` for usage during aggregation. */
export enum SystemGroupBy {
  Address = 'ADDRESS',
  AddressCity = 'ADDRESS_CITY',
  AddressForced = 'ADDRESS_FORCED',
  AddressNormalized = 'ADDRESS_NORMALIZED',
  AddressState = 'ADDRESS_STATE',
  AddressStreet = 'ADDRESS_STREET',
  AddressZip = 'ADDRESS_ZIP',
  ClientType = 'CLIENT_TYPE',
  ConnectionType = 'CONNECTION_TYPE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  InstalledAt = 'INSTALLED_AT',
  InstalledAtTruncatedToDay = 'INSTALLED_AT_TRUNCATED_TO_DAY',
  InstalledAtTruncatedToHour = 'INSTALLED_AT_TRUNCATED_TO_HOUR',
  InstalledAtTruncatedToMonth = 'INSTALLED_AT_TRUNCATED_TO_MONTH',
  InstalledAtTruncatedToQuarter = 'INSTALLED_AT_TRUNCATED_TO_QUARTER',
  InstalledAtTruncatedToWeek = 'INSTALLED_AT_TRUNCATED_TO_WEEK',
  InstalledAtTruncatedToYear = 'INSTALLED_AT_TRUNCATED_TO_YEAR',
  IntegrationId = 'INTEGRATION_ID',
  LastReportAt = 'LAST_REPORT_AT',
  LastReportAtTruncatedToDay = 'LAST_REPORT_AT_TRUNCATED_TO_DAY',
  LastReportAtTruncatedToHour = 'LAST_REPORT_AT_TRUNCATED_TO_HOUR',
  LastReportAtTruncatedToMonth = 'LAST_REPORT_AT_TRUNCATED_TO_MONTH',
  LastReportAtTruncatedToQuarter = 'LAST_REPORT_AT_TRUNCATED_TO_QUARTER',
  LastReportAtTruncatedToWeek = 'LAST_REPORT_AT_TRUNCATED_TO_WEEK',
  LastReportAtTruncatedToYear = 'LAST_REPORT_AT_TRUNCATED_TO_YEAR',
  Monitored = 'MONITORED',
  Name = 'NAME',
  Notes = 'NOTES',
  Number = 'NUMBER',
  OperationalAt = 'OPERATIONAL_AT',
  OperationalAtTruncatedToDay = 'OPERATIONAL_AT_TRUNCATED_TO_DAY',
  OperationalAtTruncatedToHour = 'OPERATIONAL_AT_TRUNCATED_TO_HOUR',
  OperationalAtTruncatedToMonth = 'OPERATIONAL_AT_TRUNCATED_TO_MONTH',
  OperationalAtTruncatedToQuarter = 'OPERATIONAL_AT_TRUNCATED_TO_QUARTER',
  OperationalAtTruncatedToWeek = 'OPERATIONAL_AT_TRUNCATED_TO_WEEK',
  OperationalAtTruncatedToYear = 'OPERATIONAL_AT_TRUNCATED_TO_YEAR',
  OperationStatus = 'OPERATION_STATUS',
  ProfileId = 'PROFILE_ID',
  ProfileOverrides = 'PROFILE_OVERRIDES',
  ProjectId = 'PROJECT_ID',
  ProviderId = 'PROVIDER_ID',
  ProviderStatus = 'PROVIDER_STATUS',
  Size = 'SIZE',
  Status = 'STATUS',
  Timezone = 'TIMEZONE',
  Uuid = 'UUID'
}

export type SystemHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

/** Conditions for `System` aggregates. */
export type SystemHavingInput = {
  AND: InputMaybe<Array<SystemHavingInput>>;
  OR: InputMaybe<Array<SystemHavingInput>>;
  arrayAgg: InputMaybe<SystemHavingArrayAggInput>;
  average: InputMaybe<SystemHavingAverageInput>;
  distinctCount: InputMaybe<SystemHavingDistinctCountInput>;
  max: InputMaybe<SystemHavingMaxInput>;
  min: InputMaybe<SystemHavingMinInput>;
  stddevPopulation: InputMaybe<SystemHavingStddevPopulationInput>;
  stddevSample: InputMaybe<SystemHavingStddevSampleInput>;
  sum: InputMaybe<SystemHavingSumInput>;
  variancePopulation: InputMaybe<SystemHavingVariancePopulationInput>;
  varianceSample: InputMaybe<SystemHavingVarianceSampleInput>;
};

export type SystemHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  installedAt: InputMaybe<HavingDatetimeFilter>;
  integrationId: InputMaybe<HavingIntFilter>;
  lastReportAt: InputMaybe<HavingDatetimeFilter>;
  number: InputMaybe<HavingBigintFilter>;
  operationalAt: InputMaybe<HavingDatetimeFilter>;
  profileId: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  size: InputMaybe<HavingIntFilter>;
};

export type SystemLog = Node & {
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  message: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: SystemLogStatus;
  /** Reads a single `System` that is related to this `SystemLog`. */
  system: Maybe<System>;
  systemId: Scalars['Int']['output'];
  thread: Scalars['String']['output'];
};

export type SystemLogAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<SystemLogArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<SystemLogAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<SystemLogDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<SystemLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<SystemLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<SystemLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<SystemLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<SystemLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<SystemLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<SystemLogVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SystemLog` object types. */
export type SystemLogAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemLog` objects. */
  arrayAgg: InputMaybe<SystemLogArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemLog` objects. */
  average: InputMaybe<SystemLogAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemLog` objects. */
  distinctCount: InputMaybe<SystemLogDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemLog` object to be included within the aggregate. */
  filter: InputMaybe<SystemLogFilter>;
  /** Maximum aggregate over matching `SystemLog` objects. */
  max: InputMaybe<SystemLogMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemLog` objects. */
  min: InputMaybe<SystemLogMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemLog` objects. */
  stddevPopulation: InputMaybe<SystemLogStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemLog` objects. */
  stddevSample: InputMaybe<SystemLogStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemLog` objects. */
  sum: InputMaybe<SystemLogSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemLog` objects. */
  variancePopulation: InputMaybe<SystemLogVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemLog` objects. */
  varianceSample: InputMaybe<SystemLogVarianceSampleAggregateFilter>;
};

export type SystemLogArrayAggAggregateFilter = {
  id: InputMaybe<IntListFilter>;
  label: InputMaybe<StringListFilter>;
  message: InputMaybe<StringListFilter>;
  systemId: InputMaybe<IntListFilter>;
  thread: InputMaybe<StringListFilter>;
};

export type SystemLogArrayAggAggregates = {
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of label across the matching connection */
  label: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of message across the matching connection */
  message: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of thread across the matching connection */
  thread: Array<Maybe<Scalars['String']['output']>>;
};


export type SystemLogArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogArrayAggAggregatesLabelArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogArrayAggAggregatesMessageArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogArrayAggAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

/**
 * A condition to be used against `SystemLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SystemLogCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `message` field. */
  message: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<SystemLogStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `thread` field. */
  thread: InputMaybe<Scalars['String']['input']>;
};

export type SystemLogDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  label: InputMaybe<BigIntFilter>;
  message: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  thread: InputMaybe<BigIntFilter>;
};

export type SystemLogDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of label across the matching connection */
  label: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of message across the matching connection */
  message: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of thread across the matching connection */
  thread: Maybe<Scalars['BigInt']['output']>;
};


export type SystemLogDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesLabelArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesMessageArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogDistinctCountAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

/** A filter to be used against `SystemLog` object types. All fields are combined with a logical ‘and.’ */
export type SystemLogFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemLogFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `label` field. */
  label: InputMaybe<StringFilter>;
  /** Filter by the object’s `message` field. */
  message: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemLogFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemLogFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<SystemLogStatusFilter>;
  /** Filter by the object’s `system` relation. */
  system: InputMaybe<SystemFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `thread` field. */
  thread: InputMaybe<StringFilter>;
};

/** Grouping methods for `SystemLog` for usage during aggregation. */
export enum SystemLogGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Label = 'LABEL',
  Message = 'MESSAGE',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  Thread = 'THREAD'
}

export type SystemLogHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `SystemLog` aggregates. */
export type SystemLogHavingInput = {
  AND: InputMaybe<Array<SystemLogHavingInput>>;
  OR: InputMaybe<Array<SystemLogHavingInput>>;
  arrayAgg: InputMaybe<SystemLogHavingArrayAggInput>;
  average: InputMaybe<SystemLogHavingAverageInput>;
  distinctCount: InputMaybe<SystemLogHavingDistinctCountInput>;
  max: InputMaybe<SystemLogHavingMaxInput>;
  min: InputMaybe<SystemLogHavingMinInput>;
  stddevPopulation: InputMaybe<SystemLogHavingStddevPopulationInput>;
  stddevSample: InputMaybe<SystemLogHavingStddevSampleInput>;
  sum: InputMaybe<SystemLogHavingSumInput>;
  variancePopulation: InputMaybe<SystemLogHavingVariancePopulationInput>;
  varianceSample: InputMaybe<SystemLogHavingVarianceSampleInput>;
};

export type SystemLogHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type SystemLogMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type SystemLogMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type SystemLogMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type SystemLogMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export enum SystemLogStatus {
  Error = 'ERROR',
  Skipped = 'SKIPPED',
  Success = 'SUCCESS'
}

/** A filter to be used against SystemLogStatus fields. All fields are combined with a logical ‘and.’ */
export type SystemLogStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<SystemLogStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<SystemLogStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<SystemLogStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<SystemLogStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<SystemLogStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<SystemLogStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<SystemLogStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<SystemLogStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<SystemLogStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<SystemLogStatus>>;
};

export type SystemLogStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
};

export type SystemLogSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
};


export type SystemLogSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogThread = {
  createdAt: Scalars['Datetime']['output'];
  events: Scalars['JSON']['output'];
  status: SystemLogStatus;
  /** Reads a single `System` that is related to this `SystemLogThread`. */
  system: Maybe<System>;
  systemId: Scalars['Int']['output'];
  thread: Scalars['String']['output'];
};

export type SystemLogThreadAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<SystemLogThreadArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<SystemLogThreadAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<SystemLogThreadDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<SystemLogThreadMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<SystemLogThreadMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<SystemLogThreadStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<SystemLogThreadStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<SystemLogThreadSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<SystemLogThreadVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<SystemLogThreadVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `SystemLogThread` object types. */
export type SystemLogThreadAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemLogThread` objects. */
  arrayAgg: InputMaybe<SystemLogThreadArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemLogThread` objects. */
  average: InputMaybe<SystemLogThreadAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemLogThread` objects. */
  distinctCount: InputMaybe<SystemLogThreadDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemLogThread` object to be included within the aggregate. */
  filter: InputMaybe<SystemLogThreadFilter>;
  /** Maximum aggregate over matching `SystemLogThread` objects. */
  max: InputMaybe<SystemLogThreadMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemLogThread` objects. */
  min: InputMaybe<SystemLogThreadMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemLogThread` objects. */
  stddevPopulation: InputMaybe<SystemLogThreadStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemLogThread` objects. */
  stddevSample: InputMaybe<SystemLogThreadStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemLogThread` objects. */
  sum: InputMaybe<SystemLogThreadSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemLogThread` objects. */
  variancePopulation: InputMaybe<SystemLogThreadVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemLogThread` objects. */
  varianceSample: InputMaybe<SystemLogThreadVarianceSampleAggregateFilter>;
};

export type SystemLogThreadArrayAggAggregateFilter = {
  systemId: InputMaybe<IntListFilter>;
  thread: InputMaybe<StringListFilter>;
};

export type SystemLogThreadArrayAggAggregates = {
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of thread across the matching connection */
  thread: Array<Maybe<Scalars['String']['output']>>;
};


export type SystemLogThreadArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadArrayAggAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadAverageAggregateFilter = {
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogThreadAverageAggregates = {
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogThreadAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

/**
 * A condition to be used against `SystemLogThread` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SystemLogThreadCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `events` field. */
  events: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<SystemLogStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `thread` field. */
  thread: InputMaybe<Scalars['String']['input']>;
};

export type SystemLogThreadDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  events: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  thread: InputMaybe<BigIntFilter>;
};

export type SystemLogThreadDistinctCountAggregates = {
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of events across the matching connection */
  events: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of thread across the matching connection */
  thread: Maybe<Scalars['BigInt']['output']>;
};


export type SystemLogThreadDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadDistinctCountAggregatesEventsArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadDistinctCountAggregatesThreadArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

/** A filter to be used against `SystemLogThread` object types. All fields are combined with a logical ‘and.’ */
export type SystemLogThreadFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemLogThreadFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemLogThreadFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemLogThreadFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<SystemLogStatusFilter>;
  /** Filter by the object’s `system` relation. */
  system: InputMaybe<SystemFilter>;
  /** A related `system` exists. */
  systemExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `thread` field. */
  thread: InputMaybe<StringFilter>;
};

/** Grouping methods for `SystemLogThread` for usage during aggregation. */
export enum SystemLogThreadGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  Events = 'EVENTS',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  Thread = 'THREAD'
}

export type SystemLogThreadHavingArrayAggInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingAverageInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingDistinctCountInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `SystemLogThread` aggregates. */
export type SystemLogThreadHavingInput = {
  AND: InputMaybe<Array<SystemLogThreadHavingInput>>;
  OR: InputMaybe<Array<SystemLogThreadHavingInput>>;
  arrayAgg: InputMaybe<SystemLogThreadHavingArrayAggInput>;
  average: InputMaybe<SystemLogThreadHavingAverageInput>;
  distinctCount: InputMaybe<SystemLogThreadHavingDistinctCountInput>;
  max: InputMaybe<SystemLogThreadHavingMaxInput>;
  min: InputMaybe<SystemLogThreadHavingMinInput>;
  stddevPopulation: InputMaybe<SystemLogThreadHavingStddevPopulationInput>;
  stddevSample: InputMaybe<SystemLogThreadHavingStddevSampleInput>;
  sum: InputMaybe<SystemLogThreadHavingSumInput>;
  variancePopulation: InputMaybe<SystemLogThreadHavingVariancePopulationInput>;
  varianceSample: InputMaybe<SystemLogThreadHavingVarianceSampleInput>;
};

export type SystemLogThreadHavingMaxInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingMinInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingStddevPopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingStddevSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingSumInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingVariancePopulationInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadHavingVarianceSampleInput = {
  createdAt: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
};

export type SystemLogThreadMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type SystemLogThreadMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type SystemLogThreadMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
};

export type SystemLogThreadMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
};


export type SystemLogThreadMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};


export type SystemLogThreadMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadStddevPopulationAggregateFilter = {
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogThreadStddevPopulationAggregates = {
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogThreadStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadStddevSampleAggregateFilter = {
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogThreadStddevSampleAggregates = {
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogThreadStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadSumAggregateFilter = {
  systemId: InputMaybe<BigIntFilter>;
};

export type SystemLogThreadSumAggregates = {
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
};


export type SystemLogThreadSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadVariancePopulationAggregateFilter = {
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogThreadVariancePopulationAggregates = {
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogThreadVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

export type SystemLogThreadVarianceSampleAggregateFilter = {
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogThreadVarianceSampleAggregates = {
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogThreadVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogThreadsOrderBy>>;
};

/** A connection to a list of `SystemLogThread` values. */
export type SystemLogThreadsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemLogThreadAggregates>;
  /** A list of edges which contains the `SystemLogThread` and cursor to aid in pagination. */
  edges: Array<SystemLogThreadsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemLogThreadAggregates>>;
  /** A list of `SystemLogThread` objects. */
  nodes: Array<Maybe<SystemLogThread>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SystemLogThread` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `SystemLogThread` values. */
export type SystemLogThreadsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemLogThreadGroupBy>;
  having: InputMaybe<SystemLogThreadHavingInput>;
};

/** A `SystemLogThread` edge in the connection. */
export type SystemLogThreadsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `SystemLogThread` at the end of the edge. */
  node: Maybe<SystemLogThread>;
};

/** Methods to use when ordering `SystemLogThread`. */
export enum SystemLogThreadsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EventsAsc = 'EVENTS_ASC',
  EventsDesc = 'EVENTS_DESC',
  Natural = 'NATURAL',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  ThreadAsc = 'THREAD_ASC',
  ThreadDesc = 'THREAD_DESC'
}

export type SystemLogVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

export type SystemLogVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
};

export type SystemLogVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemLogVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};


export type SystemLogVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<SystemLogsOrderBy>>;
};

/** A connection to a list of `SystemLog` values. */
export type SystemLogsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemLogAggregates>;
  /** A list of edges which contains the `SystemLog` and cursor to aid in pagination. */
  edges: Array<SystemLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemLogAggregates>>;
  /** A list of `SystemLog` objects. */
  nodes: Array<Maybe<SystemLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SystemLog` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `SystemLog` values. */
export type SystemLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemLogGroupBy>;
  having: InputMaybe<SystemLogHavingInput>;
};

/** A `SystemLog` edge in the connection. */
export type SystemLogsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `SystemLog` at the end of the edge. */
  node: Maybe<SystemLog>;
};

/** Methods to use when ordering `SystemLog`. */
export enum SystemLogsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  ThreadAsc = 'THREAD_ASC',
  ThreadDesc = 'THREAD_DESC'
}

export type SystemMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  installedAt: InputMaybe<DatetimeFilter>;
  integrationId: InputMaybe<IntFilter>;
  lastReportAt: InputMaybe<DatetimeFilter>;
  number: InputMaybe<BigIntFilter>;
  operationalAt: InputMaybe<DatetimeFilter>;
  profileId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  size: InputMaybe<IntFilter>;
};

export type SystemMaxAggregates = {
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of installedAt across the matching connection */
  installedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of integrationId across the matching connection */
  integrationId: Maybe<Scalars['Int']['output']>;
  /** Maximum of lastReportAt across the matching connection */
  lastReportAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of number across the matching connection */
  number: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of operationalAt across the matching connection */
  operationalAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of profileId across the matching connection */
  profileId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of size across the matching connection */
  size: Maybe<Scalars['Int']['output']>;
};


export type SystemMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesInstalledAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesLastReportAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesOperationalAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMaxAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  installedAt: InputMaybe<DatetimeFilter>;
  integrationId: InputMaybe<IntFilter>;
  lastReportAt: InputMaybe<DatetimeFilter>;
  number: InputMaybe<BigIntFilter>;
  operationalAt: InputMaybe<DatetimeFilter>;
  profileId: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  size: InputMaybe<IntFilter>;
};

export type SystemMinAggregates = {
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of installedAt across the matching connection */
  installedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of integrationId across the matching connection */
  integrationId: Maybe<Scalars['Int']['output']>;
  /** Minimum of lastReportAt across the matching connection */
  lastReportAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of number across the matching connection */
  number: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of operationalAt across the matching connection */
  operationalAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of profileId across the matching connection */
  profileId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of size across the matching connection */
  size: Maybe<Scalars['Int']['output']>;
};


export type SystemMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesInstalledAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesLastReportAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesOperationalAtArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemMinAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Comment`. */
export type SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByTaskIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Comment`. */
export type SystemPrivilegedTasksByCommentSystemIdAndTaskIdManyToManyEdgeCommentsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type SystemPrivilegedTasksByFeedSystemIdAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `PrivilegedTask`. */
export type SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTemplateTaskIdConnection: PrivilegedTasksConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `PrivilegedTask`. */
export type SystemPrivilegedTasksByPrivilegedTaskSystemIdAndTemplateTaskIdManyToManyEdgePrivilegedTasksByTemplateTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

export type SystemProfile = Node & {
  /** Reads a single `Company` that is related to this `SystemProfile`. */
  company: Maybe<Company>;
  companyId: Scalars['Int']['output'];
  createdAt: Scalars['Datetime']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Integration`. */
  integrationsBySystemProfileIdAndIntegrationIdList: Array<Integration>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySystemProfileIdAndProjectId: SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySystemProfileIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `SystemAlertProfile`. */
  systemAlertProfiles: Array<SystemAlertProfile>;
  /** Reads and enables pagination through a set of `SystemBudgetProfileWhitelist`. */
  systemBudgetProfileWhitelists: Array<SystemBudgetProfileWhitelist>;
  /** Reads and enables pagination through a set of `SystemBudget`. */
  systemBudgetsBySystemBudgetProfileWhitelistProfileIdAndBudgetIdList: Array<SystemBudget>;
  /** Reads and enables pagination through a set of `SystemProfileConfig`. */
  systemProfileConfigs: Array<SystemProfileConfig>;
  /** Reads and enables pagination through a set of `System`. */
  systemsConnection: SystemsConnection;
};


export type SystemProfileIntegrationsBySystemProfileIdAndIntegrationIdListArgs = {
  condition: InputMaybe<IntegrationCondition>;
  filter: InputMaybe<IntegrationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<IntegrationsOrderBy>>;
};


export type SystemProfileProjectsBySystemProfileIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProfileProjectsBySystemProfileIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type SystemProfileSystemAlertProfilesArgs = {
  condition: InputMaybe<SystemAlertProfileCondition>;
  filter: InputMaybe<SystemAlertProfileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemAlertProfilesOrderBy>>;
};


export type SystemProfileSystemBudgetProfileWhitelistsArgs = {
  condition: InputMaybe<SystemBudgetProfileWhitelistCondition>;
  filter: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemBudgetProfileWhitelistsOrderBy>>;
};


export type SystemProfileSystemBudgetsBySystemBudgetProfileWhitelistProfileIdAndBudgetIdListArgs = {
  condition: InputMaybe<SystemBudgetCondition>;
  filter: InputMaybe<SystemBudgetFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemBudgetsOrderBy>>;
};


export type SystemProfileSystemProfileConfigsArgs = {
  condition: InputMaybe<SystemProfileConfigCondition>;
  filter: InputMaybe<SystemProfileConfigFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SystemProfileConfigsOrderBy>>;
};


export type SystemProfileSystemsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};

/** A filter to be used against aggregates of `SystemProfile` object types. */
export type SystemProfileAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemProfile` objects. */
  arrayAgg: InputMaybe<SystemProfileArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemProfile` objects. */
  average: InputMaybe<SystemProfileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemProfile` objects. */
  distinctCount: InputMaybe<SystemProfileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemProfile` object to be included within the aggregate. */
  filter: InputMaybe<SystemProfileFilter>;
  /** Maximum aggregate over matching `SystemProfile` objects. */
  max: InputMaybe<SystemProfileMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemProfile` objects. */
  min: InputMaybe<SystemProfileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemProfile` objects. */
  stddevPopulation: InputMaybe<SystemProfileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemProfile` objects. */
  stddevSample: InputMaybe<SystemProfileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemProfile` objects. */
  sum: InputMaybe<SystemProfileSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemProfile` objects. */
  variancePopulation: InputMaybe<SystemProfileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemProfile` objects. */
  varianceSample: InputMaybe<SystemProfileVarianceSampleAggregateFilter>;
};

export type SystemProfileArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  default: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  name: InputMaybe<StringListFilter>;
};

export type SystemProfileAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `SystemProfile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SystemProfileCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `default` field. */
  default: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
};

export type SystemProfileConfig = Node & {
  enabled: Scalars['Boolean']['output'];
  frequency: SyncFrequency;
  /** Reads a single `Integration` that is related to this `SystemProfileConfig`. */
  integration: Maybe<Integration>;
  integrationId: Scalars['Int']['output'];
  metrics: Scalars['JSON']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `SystemProfile` that is related to this `SystemProfileConfig`. */
  profile: Maybe<SystemProfile>;
  profileId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `SystemProfileConfig` object types. */
export type SystemProfileConfigAggregatesFilter = {
  /** Array aggregation aggregate over matching `SystemProfileConfig` objects. */
  arrayAgg: InputMaybe<SystemProfileConfigArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `SystemProfileConfig` objects. */
  average: InputMaybe<SystemProfileConfigAverageAggregateFilter>;
  /** Distinct count aggregate over matching `SystemProfileConfig` objects. */
  distinctCount: InputMaybe<SystemProfileConfigDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `SystemProfileConfig` object to be included within the aggregate. */
  filter: InputMaybe<SystemProfileConfigFilter>;
  /** Maximum aggregate over matching `SystemProfileConfig` objects. */
  max: InputMaybe<SystemProfileConfigMaxAggregateFilter>;
  /** Minimum aggregate over matching `SystemProfileConfig` objects. */
  min: InputMaybe<SystemProfileConfigMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `SystemProfileConfig` objects. */
  stddevPopulation: InputMaybe<SystemProfileConfigStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `SystemProfileConfig` objects. */
  stddevSample: InputMaybe<SystemProfileConfigStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `SystemProfileConfig` objects. */
  sum: InputMaybe<SystemProfileConfigSumAggregateFilter>;
  /** Population variance aggregate over matching `SystemProfileConfig` objects. */
  variancePopulation: InputMaybe<SystemProfileConfigVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `SystemProfileConfig` objects. */
  varianceSample: InputMaybe<SystemProfileConfigVarianceSampleAggregateFilter>;
};

export type SystemProfileConfigArrayAggAggregateFilter = {
  enabled: InputMaybe<BooleanListFilter>;
  integrationId: InputMaybe<IntListFilter>;
  profileId: InputMaybe<IntListFilter>;
};

export type SystemProfileConfigAverageAggregateFilter = {
  integrationId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `SystemProfileConfig` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SystemProfileConfigCondition = {
  /** Checks for equality with the object’s `enabled` field. */
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `frequency` field. */
  frequency: InputMaybe<SyncFrequency>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `metrics` field. */
  metrics: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId: InputMaybe<Scalars['Int']['input']>;
};

export type SystemProfileConfigDistinctCountAggregateFilter = {
  enabled: InputMaybe<BigIntFilter>;
  frequency: InputMaybe<BigIntFilter>;
  integrationId: InputMaybe<BigIntFilter>;
  metrics: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SystemProfileConfig` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileConfigFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemProfileConfigFilter>>;
  /** Filter by the object’s `enabled` field. */
  enabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `frequency` field. */
  frequency: InputMaybe<SyncFrequencyFilter>;
  /** Filter by the object’s `integration` relation. */
  integration: InputMaybe<IntegrationFilter>;
  /** Filter by the object’s `integrationId` field. */
  integrationId: InputMaybe<IntFilter>;
  /** Filter by the object’s `metrics` field. */
  metrics: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemProfileConfigFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemProfileConfigFilter>>;
  /** Filter by the object’s `profile` relation. */
  profile: InputMaybe<SystemProfileFilter>;
  /** Filter by the object’s `profileId` field. */
  profileId: InputMaybe<IntFilter>;
};

export type SystemProfileConfigMaxAggregateFilter = {
  integrationId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemProfileConfigMinAggregateFilter = {
  integrationId: InputMaybe<IntFilter>;
  profileId: InputMaybe<IntFilter>;
};

export type SystemProfileConfigStddevPopulationAggregateFilter = {
  integrationId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemProfileConfigStddevSampleAggregateFilter = {
  integrationId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemProfileConfigSumAggregateFilter = {
  integrationId: InputMaybe<BigIntFilter>;
  profileId: InputMaybe<BigIntFilter>;
};

export type SystemProfileConfigVariancePopulationAggregateFilter = {
  integrationId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

export type SystemProfileConfigVarianceSampleAggregateFilter = {
  integrationId: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SystemProfileConfig`. */
export enum SystemProfileConfigsOrderBy {
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  FrequencyAsc = 'FREQUENCY_ASC',
  FrequencyDesc = 'FREQUENCY_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  MetricsAsc = 'METRICS_ASC',
  MetricsDesc = 'METRICS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC'
}

export type SystemProfileDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  default: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `SystemProfile` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<SystemProfileFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `default` field. */
  default: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<SystemProfileFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<SystemProfileFilter>>;
  /** Filter by the object’s `systemAlertProfiles` relation. */
  systemAlertProfiles: InputMaybe<SystemProfileToManySystemAlertProfileFilter>;
  /** Some related `systemAlertProfiles` exist. */
  systemAlertProfilesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemBudgetProfileWhitelists` relation. */
  systemBudgetProfileWhitelists: InputMaybe<SystemProfileToManySystemBudgetProfileWhitelistFilter>;
  /** Some related `systemBudgetProfileWhitelists` exist. */
  systemBudgetProfileWhitelistsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemProfileConfigs` relation. */
  systemProfileConfigs: InputMaybe<SystemProfileToManySystemProfileConfigFilter>;
  /** Some related `systemProfileConfigs` exist. */
  systemProfileConfigsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systems` relation. */
  systems: InputMaybe<SystemProfileToManySystemFilter>;
  /** Some related `systems` exist. */
  systemsExist: InputMaybe<Scalars['Boolean']['input']>;
};

export type SystemProfileMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
};

export type SystemProfileMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
};

/** A connection to a list of `Project` values, with data from `System`. */
export type SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `System`, and the cursor to aid in pagination. */
  edges: Array<SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `System`. */
export type SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `System`. */
export type SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByProjectIdConnection: SystemsConnection;
};


/** A `Project` edge in the connection, with data from `System`. */
export type SystemProfileProjectsBySystemProfileIdAndProjectIdManyToManyEdgeSystemsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemProfileStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type SystemProfileStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type SystemProfileSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

/** A filter to be used against many `SystemAlertProfile` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileToManySystemAlertProfileFilter = {
  /** Aggregates across related `SystemAlertProfile` match the filter criteria. */
  aggregates: InputMaybe<SystemAlertProfileAggregatesFilter>;
  /** Every related `SystemAlertProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemAlertProfileFilter>;
  /** No related `SystemAlertProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemAlertProfileFilter>;
  /** Some related `SystemAlertProfile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemAlertProfileFilter>;
};

/** A filter to be used against many `SystemBudgetProfileWhitelist` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileToManySystemBudgetProfileWhitelistFilter = {
  /** Aggregates across related `SystemBudgetProfileWhitelist` match the filter criteria. */
  aggregates: InputMaybe<SystemBudgetProfileWhitelistAggregatesFilter>;
  /** Every related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** No related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemBudgetProfileWhitelistFilter>;
  /** Some related `SystemBudgetProfileWhitelist` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemBudgetProfileWhitelistFilter>;
};

/** A filter to be used against many `System` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileToManySystemFilter = {
  /** Aggregates across related `System` match the filter criteria. */
  aggregates: InputMaybe<SystemAggregatesFilter>;
  /** Every related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemFilter>;
  /** No related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemFilter>;
  /** Some related `System` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemFilter>;
};

/** A filter to be used against many `SystemProfileConfig` object types. All fields are combined with a logical ‘and.’ */
export type SystemProfileToManySystemProfileConfigFilter = {
  /** Aggregates across related `SystemProfileConfig` match the filter criteria. */
  aggregates: InputMaybe<SystemProfileConfigAggregatesFilter>;
  /** Every related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemProfileConfigFilter>;
  /** No related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemProfileConfigFilter>;
  /** Some related `SystemProfileConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemProfileConfigFilter>;
};

export type SystemProfileVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type SystemProfileVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `SystemProfile`. */
export enum SystemProfilesOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DefaultAsc = 'DEFAULT_ASC',
  DefaultDesc = 'DEFAULT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SystemsConnectionArrayAggAddressAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  SystemsConnectionArrayAggAddressCityAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_CITY_ASC',
  SystemsConnectionArrayAggAddressCityDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_CITY_DESC',
  SystemsConnectionArrayAggAddressDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  SystemsConnectionArrayAggAddressForcedAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_ASC',
  SystemsConnectionArrayAggAddressForcedDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_FORCED_DESC',
  SystemsConnectionArrayAggAddressNormalizedAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionArrayAggAddressNormalizedDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionArrayAggAddressStateAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STATE_ASC',
  SystemsConnectionArrayAggAddressStateDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STATE_DESC',
  SystemsConnectionArrayAggAddressStreetAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STREET_ASC',
  SystemsConnectionArrayAggAddressStreetDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_STREET_DESC',
  SystemsConnectionArrayAggAddressZipAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_ASC',
  SystemsConnectionArrayAggAddressZipDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ADDRESS_ZIP_DESC',
  SystemsConnectionArrayAggClientTypeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CLIENT_TYPE_ASC',
  SystemsConnectionArrayAggClientTypeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CLIENT_TYPE_DESC',
  SystemsConnectionArrayAggConnectionTypeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_ASC',
  SystemsConnectionArrayAggConnectionTypeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CONNECTION_TYPE_DESC',
  SystemsConnectionArrayAggCreatedAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemsConnectionArrayAggCreatedAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemsConnectionArrayAggIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemsConnectionArrayAggIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemsConnectionArrayAggInstalledAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INSTALLED_AT_ASC',
  SystemsConnectionArrayAggInstalledAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INSTALLED_AT_DESC',
  SystemsConnectionArrayAggIntegrationIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  SystemsConnectionArrayAggIntegrationIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  SystemsConnectionArrayAggLastReportAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_ASC',
  SystemsConnectionArrayAggLastReportAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_LAST_REPORT_AT_DESC',
  SystemsConnectionArrayAggMonitoredAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_MONITORED_ASC',
  SystemsConnectionArrayAggMonitoredDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_MONITORED_DESC',
  SystemsConnectionArrayAggNameAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NAME_ASC',
  SystemsConnectionArrayAggNameDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NAME_DESC',
  SystemsConnectionArrayAggNotesAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NOTES_ASC',
  SystemsConnectionArrayAggNotesDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NOTES_DESC',
  SystemsConnectionArrayAggNumberAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NUMBER_ASC',
  SystemsConnectionArrayAggNumberDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_NUMBER_DESC',
  SystemsConnectionArrayAggOperationalAtAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_ASC',
  SystemsConnectionArrayAggOperationalAtDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATIONAL_AT_DESC',
  SystemsConnectionArrayAggOperationStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATION_STATUS_ASC',
  SystemsConnectionArrayAggOperationStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_OPERATION_STATUS_DESC',
  SystemsConnectionArrayAggProfileIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemsConnectionArrayAggProfileIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemsConnectionArrayAggProfileOverridesAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_ASC',
  SystemsConnectionArrayAggProfileOverridesDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROFILE_OVERRIDES_DESC',
  SystemsConnectionArrayAggProjectIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SystemsConnectionArrayAggProjectIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SystemsConnectionArrayAggProviderIdAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_ID_ASC',
  SystemsConnectionArrayAggProviderIdDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_ID_DESC',
  SystemsConnectionArrayAggProviderStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_ASC',
  SystemsConnectionArrayAggProviderStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_PROVIDER_STATUS_DESC',
  SystemsConnectionArrayAggSizeAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_SIZE_ASC',
  SystemsConnectionArrayAggSizeDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_SIZE_DESC',
  SystemsConnectionArrayAggStatusAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SystemsConnectionArrayAggStatusDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SystemsConnectionArrayAggTimezoneAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_TIMEZONE_ASC',
  SystemsConnectionArrayAggTimezoneDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_TIMEZONE_DESC',
  SystemsConnectionArrayAggUuidAsc = 'SYSTEMS_CONNECTION_ARRAY_AGG_UUID_ASC',
  SystemsConnectionArrayAggUuidDesc = 'SYSTEMS_CONNECTION_ARRAY_AGG_UUID_DESC',
  SystemsConnectionAverageAddressAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ASC',
  SystemsConnectionAverageAddressCityAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_CITY_ASC',
  SystemsConnectionAverageAddressCityDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_CITY_DESC',
  SystemsConnectionAverageAddressDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_DESC',
  SystemsConnectionAverageAddressForcedAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_FORCED_ASC',
  SystemsConnectionAverageAddressForcedDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_FORCED_DESC',
  SystemsConnectionAverageAddressNormalizedAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionAverageAddressNormalizedDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionAverageAddressStateAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STATE_ASC',
  SystemsConnectionAverageAddressStateDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STATE_DESC',
  SystemsConnectionAverageAddressStreetAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STREET_ASC',
  SystemsConnectionAverageAddressStreetDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_STREET_DESC',
  SystemsConnectionAverageAddressZipAsc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ZIP_ASC',
  SystemsConnectionAverageAddressZipDesc = 'SYSTEMS_CONNECTION_AVERAGE_ADDRESS_ZIP_DESC',
  SystemsConnectionAverageClientTypeAsc = 'SYSTEMS_CONNECTION_AVERAGE_CLIENT_TYPE_ASC',
  SystemsConnectionAverageClientTypeDesc = 'SYSTEMS_CONNECTION_AVERAGE_CLIENT_TYPE_DESC',
  SystemsConnectionAverageConnectionTypeAsc = 'SYSTEMS_CONNECTION_AVERAGE_CONNECTION_TYPE_ASC',
  SystemsConnectionAverageConnectionTypeDesc = 'SYSTEMS_CONNECTION_AVERAGE_CONNECTION_TYPE_DESC',
  SystemsConnectionAverageCreatedAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemsConnectionAverageCreatedAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemsConnectionAverageIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_ID_ASC',
  SystemsConnectionAverageIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_ID_DESC',
  SystemsConnectionAverageInstalledAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_INSTALLED_AT_ASC',
  SystemsConnectionAverageInstalledAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_INSTALLED_AT_DESC',
  SystemsConnectionAverageIntegrationIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  SystemsConnectionAverageIntegrationIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  SystemsConnectionAverageLastReportAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_LAST_REPORT_AT_ASC',
  SystemsConnectionAverageLastReportAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_LAST_REPORT_AT_DESC',
  SystemsConnectionAverageMonitoredAsc = 'SYSTEMS_CONNECTION_AVERAGE_MONITORED_ASC',
  SystemsConnectionAverageMonitoredDesc = 'SYSTEMS_CONNECTION_AVERAGE_MONITORED_DESC',
  SystemsConnectionAverageNameAsc = 'SYSTEMS_CONNECTION_AVERAGE_NAME_ASC',
  SystemsConnectionAverageNameDesc = 'SYSTEMS_CONNECTION_AVERAGE_NAME_DESC',
  SystemsConnectionAverageNotesAsc = 'SYSTEMS_CONNECTION_AVERAGE_NOTES_ASC',
  SystemsConnectionAverageNotesDesc = 'SYSTEMS_CONNECTION_AVERAGE_NOTES_DESC',
  SystemsConnectionAverageNumberAsc = 'SYSTEMS_CONNECTION_AVERAGE_NUMBER_ASC',
  SystemsConnectionAverageNumberDesc = 'SYSTEMS_CONNECTION_AVERAGE_NUMBER_DESC',
  SystemsConnectionAverageOperationalAtAsc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATIONAL_AT_ASC',
  SystemsConnectionAverageOperationalAtDesc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATIONAL_AT_DESC',
  SystemsConnectionAverageOperationStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATION_STATUS_ASC',
  SystemsConnectionAverageOperationStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_OPERATION_STATUS_DESC',
  SystemsConnectionAverageProfileIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemsConnectionAverageProfileIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemsConnectionAverageProfileOverridesAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionAverageProfileOverridesDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionAverageProjectIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SystemsConnectionAverageProjectIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SystemsConnectionAverageProviderIdAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_ID_ASC',
  SystemsConnectionAverageProviderIdDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_ID_DESC',
  SystemsConnectionAverageProviderStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_STATUS_ASC',
  SystemsConnectionAverageProviderStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_PROVIDER_STATUS_DESC',
  SystemsConnectionAverageSizeAsc = 'SYSTEMS_CONNECTION_AVERAGE_SIZE_ASC',
  SystemsConnectionAverageSizeDesc = 'SYSTEMS_CONNECTION_AVERAGE_SIZE_DESC',
  SystemsConnectionAverageStatusAsc = 'SYSTEMS_CONNECTION_AVERAGE_STATUS_ASC',
  SystemsConnectionAverageStatusDesc = 'SYSTEMS_CONNECTION_AVERAGE_STATUS_DESC',
  SystemsConnectionAverageTimezoneAsc = 'SYSTEMS_CONNECTION_AVERAGE_TIMEZONE_ASC',
  SystemsConnectionAverageTimezoneDesc = 'SYSTEMS_CONNECTION_AVERAGE_TIMEZONE_DESC',
  SystemsConnectionAverageUuidAsc = 'SYSTEMS_CONNECTION_AVERAGE_UUID_ASC',
  SystemsConnectionAverageUuidDesc = 'SYSTEMS_CONNECTION_AVERAGE_UUID_DESC',
  SystemsConnectionCountAsc = 'SYSTEMS_CONNECTION_COUNT_ASC',
  SystemsConnectionCountDesc = 'SYSTEMS_CONNECTION_COUNT_DESC',
  SystemsConnectionDistinctCountAddressAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  SystemsConnectionDistinctCountAddressCityAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_ASC',
  SystemsConnectionDistinctCountAddressCityDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_CITY_DESC',
  SystemsConnectionDistinctCountAddressDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  SystemsConnectionDistinctCountAddressForcedAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_ASC',
  SystemsConnectionDistinctCountAddressForcedDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_FORCED_DESC',
  SystemsConnectionDistinctCountAddressNormalizedAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionDistinctCountAddressNormalizedDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionDistinctCountAddressStateAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_ASC',
  SystemsConnectionDistinctCountAddressStateDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STATE_DESC',
  SystemsConnectionDistinctCountAddressStreetAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_ASC',
  SystemsConnectionDistinctCountAddressStreetDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_STREET_DESC',
  SystemsConnectionDistinctCountAddressZipAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_ASC',
  SystemsConnectionDistinctCountAddressZipDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ADDRESS_ZIP_DESC',
  SystemsConnectionDistinctCountClientTypeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_ASC',
  SystemsConnectionDistinctCountClientTypeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CLIENT_TYPE_DESC',
  SystemsConnectionDistinctCountConnectionTypeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_ASC',
  SystemsConnectionDistinctCountConnectionTypeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CONNECTION_TYPE_DESC',
  SystemsConnectionDistinctCountCreatedAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemsConnectionDistinctCountCreatedAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemsConnectionDistinctCountIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemsConnectionDistinctCountIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemsConnectionDistinctCountInstalledAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_ASC',
  SystemsConnectionDistinctCountInstalledAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INSTALLED_AT_DESC',
  SystemsConnectionDistinctCountIntegrationIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  SystemsConnectionDistinctCountIntegrationIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  SystemsConnectionDistinctCountLastReportAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_ASC',
  SystemsConnectionDistinctCountLastReportAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_LAST_REPORT_AT_DESC',
  SystemsConnectionDistinctCountMonitoredAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_MONITORED_ASC',
  SystemsConnectionDistinctCountMonitoredDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_MONITORED_DESC',
  SystemsConnectionDistinctCountNameAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  SystemsConnectionDistinctCountNameDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  SystemsConnectionDistinctCountNotesAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  SystemsConnectionDistinctCountNotesDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  SystemsConnectionDistinctCountNumberAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NUMBER_ASC',
  SystemsConnectionDistinctCountNumberDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_NUMBER_DESC',
  SystemsConnectionDistinctCountOperationalAtAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_ASC',
  SystemsConnectionDistinctCountOperationalAtDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATIONAL_AT_DESC',
  SystemsConnectionDistinctCountOperationStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_ASC',
  SystemsConnectionDistinctCountOperationStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_OPERATION_STATUS_DESC',
  SystemsConnectionDistinctCountProfileIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemsConnectionDistinctCountProfileIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemsConnectionDistinctCountProfileOverridesAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_ASC',
  SystemsConnectionDistinctCountProfileOverridesDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROFILE_OVERRIDES_DESC',
  SystemsConnectionDistinctCountProjectIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SystemsConnectionDistinctCountProjectIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SystemsConnectionDistinctCountProviderIdAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_ASC',
  SystemsConnectionDistinctCountProviderIdDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_ID_DESC',
  SystemsConnectionDistinctCountProviderStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_ASC',
  SystemsConnectionDistinctCountProviderStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_PROVIDER_STATUS_DESC',
  SystemsConnectionDistinctCountSizeAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_SIZE_ASC',
  SystemsConnectionDistinctCountSizeDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_SIZE_DESC',
  SystemsConnectionDistinctCountStatusAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SystemsConnectionDistinctCountStatusDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SystemsConnectionDistinctCountTimezoneAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_TIMEZONE_ASC',
  SystemsConnectionDistinctCountTimezoneDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_TIMEZONE_DESC',
  SystemsConnectionDistinctCountUuidAsc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  SystemsConnectionDistinctCountUuidDesc = 'SYSTEMS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  SystemsConnectionMaxAddressAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ASC',
  SystemsConnectionMaxAddressCityAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_CITY_ASC',
  SystemsConnectionMaxAddressCityDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_CITY_DESC',
  SystemsConnectionMaxAddressDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_DESC',
  SystemsConnectionMaxAddressForcedAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_FORCED_ASC',
  SystemsConnectionMaxAddressForcedDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_FORCED_DESC',
  SystemsConnectionMaxAddressNormalizedAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionMaxAddressNormalizedDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionMaxAddressStateAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STATE_ASC',
  SystemsConnectionMaxAddressStateDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STATE_DESC',
  SystemsConnectionMaxAddressStreetAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STREET_ASC',
  SystemsConnectionMaxAddressStreetDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_STREET_DESC',
  SystemsConnectionMaxAddressZipAsc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ZIP_ASC',
  SystemsConnectionMaxAddressZipDesc = 'SYSTEMS_CONNECTION_MAX_ADDRESS_ZIP_DESC',
  SystemsConnectionMaxClientTypeAsc = 'SYSTEMS_CONNECTION_MAX_CLIENT_TYPE_ASC',
  SystemsConnectionMaxClientTypeDesc = 'SYSTEMS_CONNECTION_MAX_CLIENT_TYPE_DESC',
  SystemsConnectionMaxConnectionTypeAsc = 'SYSTEMS_CONNECTION_MAX_CONNECTION_TYPE_ASC',
  SystemsConnectionMaxConnectionTypeDesc = 'SYSTEMS_CONNECTION_MAX_CONNECTION_TYPE_DESC',
  SystemsConnectionMaxCreatedAtAsc = 'SYSTEMS_CONNECTION_MAX_CREATED_AT_ASC',
  SystemsConnectionMaxCreatedAtDesc = 'SYSTEMS_CONNECTION_MAX_CREATED_AT_DESC',
  SystemsConnectionMaxIdAsc = 'SYSTEMS_CONNECTION_MAX_ID_ASC',
  SystemsConnectionMaxIdDesc = 'SYSTEMS_CONNECTION_MAX_ID_DESC',
  SystemsConnectionMaxInstalledAtAsc = 'SYSTEMS_CONNECTION_MAX_INSTALLED_AT_ASC',
  SystemsConnectionMaxInstalledAtDesc = 'SYSTEMS_CONNECTION_MAX_INSTALLED_AT_DESC',
  SystemsConnectionMaxIntegrationIdAsc = 'SYSTEMS_CONNECTION_MAX_INTEGRATION_ID_ASC',
  SystemsConnectionMaxIntegrationIdDesc = 'SYSTEMS_CONNECTION_MAX_INTEGRATION_ID_DESC',
  SystemsConnectionMaxLastReportAtAsc = 'SYSTEMS_CONNECTION_MAX_LAST_REPORT_AT_ASC',
  SystemsConnectionMaxLastReportAtDesc = 'SYSTEMS_CONNECTION_MAX_LAST_REPORT_AT_DESC',
  SystemsConnectionMaxMonitoredAsc = 'SYSTEMS_CONNECTION_MAX_MONITORED_ASC',
  SystemsConnectionMaxMonitoredDesc = 'SYSTEMS_CONNECTION_MAX_MONITORED_DESC',
  SystemsConnectionMaxNameAsc = 'SYSTEMS_CONNECTION_MAX_NAME_ASC',
  SystemsConnectionMaxNameDesc = 'SYSTEMS_CONNECTION_MAX_NAME_DESC',
  SystemsConnectionMaxNotesAsc = 'SYSTEMS_CONNECTION_MAX_NOTES_ASC',
  SystemsConnectionMaxNotesDesc = 'SYSTEMS_CONNECTION_MAX_NOTES_DESC',
  SystemsConnectionMaxNumberAsc = 'SYSTEMS_CONNECTION_MAX_NUMBER_ASC',
  SystemsConnectionMaxNumberDesc = 'SYSTEMS_CONNECTION_MAX_NUMBER_DESC',
  SystemsConnectionMaxOperationalAtAsc = 'SYSTEMS_CONNECTION_MAX_OPERATIONAL_AT_ASC',
  SystemsConnectionMaxOperationalAtDesc = 'SYSTEMS_CONNECTION_MAX_OPERATIONAL_AT_DESC',
  SystemsConnectionMaxOperationStatusAsc = 'SYSTEMS_CONNECTION_MAX_OPERATION_STATUS_ASC',
  SystemsConnectionMaxOperationStatusDesc = 'SYSTEMS_CONNECTION_MAX_OPERATION_STATUS_DESC',
  SystemsConnectionMaxProfileIdAsc = 'SYSTEMS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemsConnectionMaxProfileIdDesc = 'SYSTEMS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemsConnectionMaxProfileOverridesAsc = 'SYSTEMS_CONNECTION_MAX_PROFILE_OVERRIDES_ASC',
  SystemsConnectionMaxProfileOverridesDesc = 'SYSTEMS_CONNECTION_MAX_PROFILE_OVERRIDES_DESC',
  SystemsConnectionMaxProjectIdAsc = 'SYSTEMS_CONNECTION_MAX_PROJECT_ID_ASC',
  SystemsConnectionMaxProjectIdDesc = 'SYSTEMS_CONNECTION_MAX_PROJECT_ID_DESC',
  SystemsConnectionMaxProviderIdAsc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_ID_ASC',
  SystemsConnectionMaxProviderIdDesc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_ID_DESC',
  SystemsConnectionMaxProviderStatusAsc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_STATUS_ASC',
  SystemsConnectionMaxProviderStatusDesc = 'SYSTEMS_CONNECTION_MAX_PROVIDER_STATUS_DESC',
  SystemsConnectionMaxSizeAsc = 'SYSTEMS_CONNECTION_MAX_SIZE_ASC',
  SystemsConnectionMaxSizeDesc = 'SYSTEMS_CONNECTION_MAX_SIZE_DESC',
  SystemsConnectionMaxStatusAsc = 'SYSTEMS_CONNECTION_MAX_STATUS_ASC',
  SystemsConnectionMaxStatusDesc = 'SYSTEMS_CONNECTION_MAX_STATUS_DESC',
  SystemsConnectionMaxTimezoneAsc = 'SYSTEMS_CONNECTION_MAX_TIMEZONE_ASC',
  SystemsConnectionMaxTimezoneDesc = 'SYSTEMS_CONNECTION_MAX_TIMEZONE_DESC',
  SystemsConnectionMaxUuidAsc = 'SYSTEMS_CONNECTION_MAX_UUID_ASC',
  SystemsConnectionMaxUuidDesc = 'SYSTEMS_CONNECTION_MAX_UUID_DESC',
  SystemsConnectionMinAddressAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ASC',
  SystemsConnectionMinAddressCityAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_CITY_ASC',
  SystemsConnectionMinAddressCityDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_CITY_DESC',
  SystemsConnectionMinAddressDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_DESC',
  SystemsConnectionMinAddressForcedAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_FORCED_ASC',
  SystemsConnectionMinAddressForcedDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_FORCED_DESC',
  SystemsConnectionMinAddressNormalizedAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionMinAddressNormalizedDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionMinAddressStateAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STATE_ASC',
  SystemsConnectionMinAddressStateDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STATE_DESC',
  SystemsConnectionMinAddressStreetAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STREET_ASC',
  SystemsConnectionMinAddressStreetDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_STREET_DESC',
  SystemsConnectionMinAddressZipAsc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ZIP_ASC',
  SystemsConnectionMinAddressZipDesc = 'SYSTEMS_CONNECTION_MIN_ADDRESS_ZIP_DESC',
  SystemsConnectionMinClientTypeAsc = 'SYSTEMS_CONNECTION_MIN_CLIENT_TYPE_ASC',
  SystemsConnectionMinClientTypeDesc = 'SYSTEMS_CONNECTION_MIN_CLIENT_TYPE_DESC',
  SystemsConnectionMinConnectionTypeAsc = 'SYSTEMS_CONNECTION_MIN_CONNECTION_TYPE_ASC',
  SystemsConnectionMinConnectionTypeDesc = 'SYSTEMS_CONNECTION_MIN_CONNECTION_TYPE_DESC',
  SystemsConnectionMinCreatedAtAsc = 'SYSTEMS_CONNECTION_MIN_CREATED_AT_ASC',
  SystemsConnectionMinCreatedAtDesc = 'SYSTEMS_CONNECTION_MIN_CREATED_AT_DESC',
  SystemsConnectionMinIdAsc = 'SYSTEMS_CONNECTION_MIN_ID_ASC',
  SystemsConnectionMinIdDesc = 'SYSTEMS_CONNECTION_MIN_ID_DESC',
  SystemsConnectionMinInstalledAtAsc = 'SYSTEMS_CONNECTION_MIN_INSTALLED_AT_ASC',
  SystemsConnectionMinInstalledAtDesc = 'SYSTEMS_CONNECTION_MIN_INSTALLED_AT_DESC',
  SystemsConnectionMinIntegrationIdAsc = 'SYSTEMS_CONNECTION_MIN_INTEGRATION_ID_ASC',
  SystemsConnectionMinIntegrationIdDesc = 'SYSTEMS_CONNECTION_MIN_INTEGRATION_ID_DESC',
  SystemsConnectionMinLastReportAtAsc = 'SYSTEMS_CONNECTION_MIN_LAST_REPORT_AT_ASC',
  SystemsConnectionMinLastReportAtDesc = 'SYSTEMS_CONNECTION_MIN_LAST_REPORT_AT_DESC',
  SystemsConnectionMinMonitoredAsc = 'SYSTEMS_CONNECTION_MIN_MONITORED_ASC',
  SystemsConnectionMinMonitoredDesc = 'SYSTEMS_CONNECTION_MIN_MONITORED_DESC',
  SystemsConnectionMinNameAsc = 'SYSTEMS_CONNECTION_MIN_NAME_ASC',
  SystemsConnectionMinNameDesc = 'SYSTEMS_CONNECTION_MIN_NAME_DESC',
  SystemsConnectionMinNotesAsc = 'SYSTEMS_CONNECTION_MIN_NOTES_ASC',
  SystemsConnectionMinNotesDesc = 'SYSTEMS_CONNECTION_MIN_NOTES_DESC',
  SystemsConnectionMinNumberAsc = 'SYSTEMS_CONNECTION_MIN_NUMBER_ASC',
  SystemsConnectionMinNumberDesc = 'SYSTEMS_CONNECTION_MIN_NUMBER_DESC',
  SystemsConnectionMinOperationalAtAsc = 'SYSTEMS_CONNECTION_MIN_OPERATIONAL_AT_ASC',
  SystemsConnectionMinOperationalAtDesc = 'SYSTEMS_CONNECTION_MIN_OPERATIONAL_AT_DESC',
  SystemsConnectionMinOperationStatusAsc = 'SYSTEMS_CONNECTION_MIN_OPERATION_STATUS_ASC',
  SystemsConnectionMinOperationStatusDesc = 'SYSTEMS_CONNECTION_MIN_OPERATION_STATUS_DESC',
  SystemsConnectionMinProfileIdAsc = 'SYSTEMS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemsConnectionMinProfileIdDesc = 'SYSTEMS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemsConnectionMinProfileOverridesAsc = 'SYSTEMS_CONNECTION_MIN_PROFILE_OVERRIDES_ASC',
  SystemsConnectionMinProfileOverridesDesc = 'SYSTEMS_CONNECTION_MIN_PROFILE_OVERRIDES_DESC',
  SystemsConnectionMinProjectIdAsc = 'SYSTEMS_CONNECTION_MIN_PROJECT_ID_ASC',
  SystemsConnectionMinProjectIdDesc = 'SYSTEMS_CONNECTION_MIN_PROJECT_ID_DESC',
  SystemsConnectionMinProviderIdAsc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_ID_ASC',
  SystemsConnectionMinProviderIdDesc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_ID_DESC',
  SystemsConnectionMinProviderStatusAsc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_STATUS_ASC',
  SystemsConnectionMinProviderStatusDesc = 'SYSTEMS_CONNECTION_MIN_PROVIDER_STATUS_DESC',
  SystemsConnectionMinSizeAsc = 'SYSTEMS_CONNECTION_MIN_SIZE_ASC',
  SystemsConnectionMinSizeDesc = 'SYSTEMS_CONNECTION_MIN_SIZE_DESC',
  SystemsConnectionMinStatusAsc = 'SYSTEMS_CONNECTION_MIN_STATUS_ASC',
  SystemsConnectionMinStatusDesc = 'SYSTEMS_CONNECTION_MIN_STATUS_DESC',
  SystemsConnectionMinTimezoneAsc = 'SYSTEMS_CONNECTION_MIN_TIMEZONE_ASC',
  SystemsConnectionMinTimezoneDesc = 'SYSTEMS_CONNECTION_MIN_TIMEZONE_DESC',
  SystemsConnectionMinUuidAsc = 'SYSTEMS_CONNECTION_MIN_UUID_ASC',
  SystemsConnectionMinUuidDesc = 'SYSTEMS_CONNECTION_MIN_UUID_DESC',
  SystemsConnectionStddevPopulationAddressAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  SystemsConnectionStddevPopulationAddressCityAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_ASC',
  SystemsConnectionStddevPopulationAddressCityDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_CITY_DESC',
  SystemsConnectionStddevPopulationAddressDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  SystemsConnectionStddevPopulationAddressForcedAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_ASC',
  SystemsConnectionStddevPopulationAddressForcedDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_FORCED_DESC',
  SystemsConnectionStddevPopulationAddressNormalizedAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionStddevPopulationAddressNormalizedDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionStddevPopulationAddressStateAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_ASC',
  SystemsConnectionStddevPopulationAddressStateDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STATE_DESC',
  SystemsConnectionStddevPopulationAddressStreetAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_ASC',
  SystemsConnectionStddevPopulationAddressStreetDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_STREET_DESC',
  SystemsConnectionStddevPopulationAddressZipAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_ASC',
  SystemsConnectionStddevPopulationAddressZipDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ADDRESS_ZIP_DESC',
  SystemsConnectionStddevPopulationClientTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_ASC',
  SystemsConnectionStddevPopulationClientTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CLIENT_TYPE_DESC',
  SystemsConnectionStddevPopulationConnectionTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_ASC',
  SystemsConnectionStddevPopulationConnectionTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CONNECTION_TYPE_DESC',
  SystemsConnectionStddevPopulationCreatedAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemsConnectionStddevPopulationCreatedAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemsConnectionStddevPopulationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemsConnectionStddevPopulationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemsConnectionStddevPopulationInstalledAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_ASC',
  SystemsConnectionStddevPopulationInstalledAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INSTALLED_AT_DESC',
  SystemsConnectionStddevPopulationIntegrationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  SystemsConnectionStddevPopulationIntegrationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  SystemsConnectionStddevPopulationLastReportAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_ASC',
  SystemsConnectionStddevPopulationLastReportAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_LAST_REPORT_AT_DESC',
  SystemsConnectionStddevPopulationMonitoredAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_MONITORED_ASC',
  SystemsConnectionStddevPopulationMonitoredDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_MONITORED_DESC',
  SystemsConnectionStddevPopulationNameAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  SystemsConnectionStddevPopulationNameDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  SystemsConnectionStddevPopulationNotesAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  SystemsConnectionStddevPopulationNotesDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  SystemsConnectionStddevPopulationNumberAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NUMBER_ASC',
  SystemsConnectionStddevPopulationNumberDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_NUMBER_DESC',
  SystemsConnectionStddevPopulationOperationalAtAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_ASC',
  SystemsConnectionStddevPopulationOperationalAtDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATIONAL_AT_DESC',
  SystemsConnectionStddevPopulationOperationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_ASC',
  SystemsConnectionStddevPopulationOperationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_OPERATION_STATUS_DESC',
  SystemsConnectionStddevPopulationProfileIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemsConnectionStddevPopulationProfileIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemsConnectionStddevPopulationProfileOverridesAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsConnectionStddevPopulationProfileOverridesDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsConnectionStddevPopulationProjectIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SystemsConnectionStddevPopulationProjectIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SystemsConnectionStddevPopulationProviderIdAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_ASC',
  SystemsConnectionStddevPopulationProviderIdDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_ID_DESC',
  SystemsConnectionStddevPopulationProviderStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_ASC',
  SystemsConnectionStddevPopulationProviderStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_PROVIDER_STATUS_DESC',
  SystemsConnectionStddevPopulationSizeAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_SIZE_ASC',
  SystemsConnectionStddevPopulationSizeDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_SIZE_DESC',
  SystemsConnectionStddevPopulationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SystemsConnectionStddevPopulationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SystemsConnectionStddevPopulationTimezoneAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_TIMEZONE_ASC',
  SystemsConnectionStddevPopulationTimezoneDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_TIMEZONE_DESC',
  SystemsConnectionStddevPopulationUuidAsc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  SystemsConnectionStddevPopulationUuidDesc = 'SYSTEMS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  SystemsConnectionStddevSampleAddressAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  SystemsConnectionStddevSampleAddressCityAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_ASC',
  SystemsConnectionStddevSampleAddressCityDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_CITY_DESC',
  SystemsConnectionStddevSampleAddressDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  SystemsConnectionStddevSampleAddressForcedAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsConnectionStddevSampleAddressForcedDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsConnectionStddevSampleAddressNormalizedAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionStddevSampleAddressNormalizedDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionStddevSampleAddressStateAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_ASC',
  SystemsConnectionStddevSampleAddressStateDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STATE_DESC',
  SystemsConnectionStddevSampleAddressStreetAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_ASC',
  SystemsConnectionStddevSampleAddressStreetDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_STREET_DESC',
  SystemsConnectionStddevSampleAddressZipAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsConnectionStddevSampleAddressZipDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsConnectionStddevSampleClientTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_ASC',
  SystemsConnectionStddevSampleClientTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CLIENT_TYPE_DESC',
  SystemsConnectionStddevSampleConnectionTypeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsConnectionStddevSampleConnectionTypeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsConnectionStddevSampleCreatedAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemsConnectionStddevSampleCreatedAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemsConnectionStddevSampleIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemsConnectionStddevSampleIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemsConnectionStddevSampleInstalledAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_ASC',
  SystemsConnectionStddevSampleInstalledAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INSTALLED_AT_DESC',
  SystemsConnectionStddevSampleIntegrationIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  SystemsConnectionStddevSampleIntegrationIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  SystemsConnectionStddevSampleLastReportAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsConnectionStddevSampleLastReportAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsConnectionStddevSampleMonitoredAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_MONITORED_ASC',
  SystemsConnectionStddevSampleMonitoredDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_MONITORED_DESC',
  SystemsConnectionStddevSampleNameAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  SystemsConnectionStddevSampleNameDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  SystemsConnectionStddevSampleNotesAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  SystemsConnectionStddevSampleNotesDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  SystemsConnectionStddevSampleNumberAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NUMBER_ASC',
  SystemsConnectionStddevSampleNumberDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_NUMBER_DESC',
  SystemsConnectionStddevSampleOperationalAtAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsConnectionStddevSampleOperationalAtDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsConnectionStddevSampleOperationStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_ASC',
  SystemsConnectionStddevSampleOperationStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_OPERATION_STATUS_DESC',
  SystemsConnectionStddevSampleProfileIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemsConnectionStddevSampleProfileIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemsConnectionStddevSampleProfileOverridesAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionStddevSampleProfileOverridesDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionStddevSampleProjectIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SystemsConnectionStddevSampleProjectIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SystemsConnectionStddevSampleProviderIdAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_ASC',
  SystemsConnectionStddevSampleProviderIdDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_ID_DESC',
  SystemsConnectionStddevSampleProviderStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsConnectionStddevSampleProviderStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsConnectionStddevSampleSizeAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_SIZE_ASC',
  SystemsConnectionStddevSampleSizeDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_SIZE_DESC',
  SystemsConnectionStddevSampleStatusAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SystemsConnectionStddevSampleStatusDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SystemsConnectionStddevSampleTimezoneAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_TIMEZONE_ASC',
  SystemsConnectionStddevSampleTimezoneDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_TIMEZONE_DESC',
  SystemsConnectionStddevSampleUuidAsc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  SystemsConnectionStddevSampleUuidDesc = 'SYSTEMS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  SystemsConnectionSumAddressAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ASC',
  SystemsConnectionSumAddressCityAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_CITY_ASC',
  SystemsConnectionSumAddressCityDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_CITY_DESC',
  SystemsConnectionSumAddressDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_DESC',
  SystemsConnectionSumAddressForcedAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_FORCED_ASC',
  SystemsConnectionSumAddressForcedDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_FORCED_DESC',
  SystemsConnectionSumAddressNormalizedAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionSumAddressNormalizedDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionSumAddressStateAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STATE_ASC',
  SystemsConnectionSumAddressStateDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STATE_DESC',
  SystemsConnectionSumAddressStreetAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STREET_ASC',
  SystemsConnectionSumAddressStreetDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_STREET_DESC',
  SystemsConnectionSumAddressZipAsc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ZIP_ASC',
  SystemsConnectionSumAddressZipDesc = 'SYSTEMS_CONNECTION_SUM_ADDRESS_ZIP_DESC',
  SystemsConnectionSumClientTypeAsc = 'SYSTEMS_CONNECTION_SUM_CLIENT_TYPE_ASC',
  SystemsConnectionSumClientTypeDesc = 'SYSTEMS_CONNECTION_SUM_CLIENT_TYPE_DESC',
  SystemsConnectionSumConnectionTypeAsc = 'SYSTEMS_CONNECTION_SUM_CONNECTION_TYPE_ASC',
  SystemsConnectionSumConnectionTypeDesc = 'SYSTEMS_CONNECTION_SUM_CONNECTION_TYPE_DESC',
  SystemsConnectionSumCreatedAtAsc = 'SYSTEMS_CONNECTION_SUM_CREATED_AT_ASC',
  SystemsConnectionSumCreatedAtDesc = 'SYSTEMS_CONNECTION_SUM_CREATED_AT_DESC',
  SystemsConnectionSumIdAsc = 'SYSTEMS_CONNECTION_SUM_ID_ASC',
  SystemsConnectionSumIdDesc = 'SYSTEMS_CONNECTION_SUM_ID_DESC',
  SystemsConnectionSumInstalledAtAsc = 'SYSTEMS_CONNECTION_SUM_INSTALLED_AT_ASC',
  SystemsConnectionSumInstalledAtDesc = 'SYSTEMS_CONNECTION_SUM_INSTALLED_AT_DESC',
  SystemsConnectionSumIntegrationIdAsc = 'SYSTEMS_CONNECTION_SUM_INTEGRATION_ID_ASC',
  SystemsConnectionSumIntegrationIdDesc = 'SYSTEMS_CONNECTION_SUM_INTEGRATION_ID_DESC',
  SystemsConnectionSumLastReportAtAsc = 'SYSTEMS_CONNECTION_SUM_LAST_REPORT_AT_ASC',
  SystemsConnectionSumLastReportAtDesc = 'SYSTEMS_CONNECTION_SUM_LAST_REPORT_AT_DESC',
  SystemsConnectionSumMonitoredAsc = 'SYSTEMS_CONNECTION_SUM_MONITORED_ASC',
  SystemsConnectionSumMonitoredDesc = 'SYSTEMS_CONNECTION_SUM_MONITORED_DESC',
  SystemsConnectionSumNameAsc = 'SYSTEMS_CONNECTION_SUM_NAME_ASC',
  SystemsConnectionSumNameDesc = 'SYSTEMS_CONNECTION_SUM_NAME_DESC',
  SystemsConnectionSumNotesAsc = 'SYSTEMS_CONNECTION_SUM_NOTES_ASC',
  SystemsConnectionSumNotesDesc = 'SYSTEMS_CONNECTION_SUM_NOTES_DESC',
  SystemsConnectionSumNumberAsc = 'SYSTEMS_CONNECTION_SUM_NUMBER_ASC',
  SystemsConnectionSumNumberDesc = 'SYSTEMS_CONNECTION_SUM_NUMBER_DESC',
  SystemsConnectionSumOperationalAtAsc = 'SYSTEMS_CONNECTION_SUM_OPERATIONAL_AT_ASC',
  SystemsConnectionSumOperationalAtDesc = 'SYSTEMS_CONNECTION_SUM_OPERATIONAL_AT_DESC',
  SystemsConnectionSumOperationStatusAsc = 'SYSTEMS_CONNECTION_SUM_OPERATION_STATUS_ASC',
  SystemsConnectionSumOperationStatusDesc = 'SYSTEMS_CONNECTION_SUM_OPERATION_STATUS_DESC',
  SystemsConnectionSumProfileIdAsc = 'SYSTEMS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemsConnectionSumProfileIdDesc = 'SYSTEMS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemsConnectionSumProfileOverridesAsc = 'SYSTEMS_CONNECTION_SUM_PROFILE_OVERRIDES_ASC',
  SystemsConnectionSumProfileOverridesDesc = 'SYSTEMS_CONNECTION_SUM_PROFILE_OVERRIDES_DESC',
  SystemsConnectionSumProjectIdAsc = 'SYSTEMS_CONNECTION_SUM_PROJECT_ID_ASC',
  SystemsConnectionSumProjectIdDesc = 'SYSTEMS_CONNECTION_SUM_PROJECT_ID_DESC',
  SystemsConnectionSumProviderIdAsc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_ID_ASC',
  SystemsConnectionSumProviderIdDesc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_ID_DESC',
  SystemsConnectionSumProviderStatusAsc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_STATUS_ASC',
  SystemsConnectionSumProviderStatusDesc = 'SYSTEMS_CONNECTION_SUM_PROVIDER_STATUS_DESC',
  SystemsConnectionSumSizeAsc = 'SYSTEMS_CONNECTION_SUM_SIZE_ASC',
  SystemsConnectionSumSizeDesc = 'SYSTEMS_CONNECTION_SUM_SIZE_DESC',
  SystemsConnectionSumStatusAsc = 'SYSTEMS_CONNECTION_SUM_STATUS_ASC',
  SystemsConnectionSumStatusDesc = 'SYSTEMS_CONNECTION_SUM_STATUS_DESC',
  SystemsConnectionSumTimezoneAsc = 'SYSTEMS_CONNECTION_SUM_TIMEZONE_ASC',
  SystemsConnectionSumTimezoneDesc = 'SYSTEMS_CONNECTION_SUM_TIMEZONE_DESC',
  SystemsConnectionSumUuidAsc = 'SYSTEMS_CONNECTION_SUM_UUID_ASC',
  SystemsConnectionSumUuidDesc = 'SYSTEMS_CONNECTION_SUM_UUID_DESC',
  SystemsConnectionVariancePopulationAddressAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  SystemsConnectionVariancePopulationAddressCityAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_ASC',
  SystemsConnectionVariancePopulationAddressCityDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_CITY_DESC',
  SystemsConnectionVariancePopulationAddressDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  SystemsConnectionVariancePopulationAddressForcedAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_ASC',
  SystemsConnectionVariancePopulationAddressForcedDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_FORCED_DESC',
  SystemsConnectionVariancePopulationAddressNormalizedAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionVariancePopulationAddressNormalizedDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionVariancePopulationAddressStateAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_ASC',
  SystemsConnectionVariancePopulationAddressStateDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STATE_DESC',
  SystemsConnectionVariancePopulationAddressStreetAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_ASC',
  SystemsConnectionVariancePopulationAddressStreetDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_STREET_DESC',
  SystemsConnectionVariancePopulationAddressZipAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_ASC',
  SystemsConnectionVariancePopulationAddressZipDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ZIP_DESC',
  SystemsConnectionVariancePopulationClientTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_ASC',
  SystemsConnectionVariancePopulationClientTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CLIENT_TYPE_DESC',
  SystemsConnectionVariancePopulationConnectionTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_ASC',
  SystemsConnectionVariancePopulationConnectionTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CONNECTION_TYPE_DESC',
  SystemsConnectionVariancePopulationCreatedAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemsConnectionVariancePopulationCreatedAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemsConnectionVariancePopulationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemsConnectionVariancePopulationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemsConnectionVariancePopulationInstalledAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_ASC',
  SystemsConnectionVariancePopulationInstalledAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INSTALLED_AT_DESC',
  SystemsConnectionVariancePopulationIntegrationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  SystemsConnectionVariancePopulationIntegrationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  SystemsConnectionVariancePopulationLastReportAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_ASC',
  SystemsConnectionVariancePopulationLastReportAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_LAST_REPORT_AT_DESC',
  SystemsConnectionVariancePopulationMonitoredAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_MONITORED_ASC',
  SystemsConnectionVariancePopulationMonitoredDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_MONITORED_DESC',
  SystemsConnectionVariancePopulationNameAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  SystemsConnectionVariancePopulationNameDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  SystemsConnectionVariancePopulationNotesAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  SystemsConnectionVariancePopulationNotesDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  SystemsConnectionVariancePopulationNumberAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NUMBER_ASC',
  SystemsConnectionVariancePopulationNumberDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_NUMBER_DESC',
  SystemsConnectionVariancePopulationOperationalAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_ASC',
  SystemsConnectionVariancePopulationOperationalAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATIONAL_AT_DESC',
  SystemsConnectionVariancePopulationOperationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_ASC',
  SystemsConnectionVariancePopulationOperationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_OPERATION_STATUS_DESC',
  SystemsConnectionVariancePopulationProfileIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemsConnectionVariancePopulationProfileIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemsConnectionVariancePopulationProfileOverridesAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_ASC',
  SystemsConnectionVariancePopulationProfileOverridesDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROFILE_OVERRIDES_DESC',
  SystemsConnectionVariancePopulationProjectIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SystemsConnectionVariancePopulationProjectIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SystemsConnectionVariancePopulationProviderIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_ASC',
  SystemsConnectionVariancePopulationProviderIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_ID_DESC',
  SystemsConnectionVariancePopulationProviderStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_ASC',
  SystemsConnectionVariancePopulationProviderStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_PROVIDER_STATUS_DESC',
  SystemsConnectionVariancePopulationSizeAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_SIZE_ASC',
  SystemsConnectionVariancePopulationSizeDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_SIZE_DESC',
  SystemsConnectionVariancePopulationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SystemsConnectionVariancePopulationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SystemsConnectionVariancePopulationTimezoneAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_TIMEZONE_ASC',
  SystemsConnectionVariancePopulationTimezoneDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_TIMEZONE_DESC',
  SystemsConnectionVariancePopulationUuidAsc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  SystemsConnectionVariancePopulationUuidDesc = 'SYSTEMS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  SystemsConnectionVarianceSampleAddressAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  SystemsConnectionVarianceSampleAddressCityAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_ASC',
  SystemsConnectionVarianceSampleAddressCityDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_CITY_DESC',
  SystemsConnectionVarianceSampleAddressDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  SystemsConnectionVarianceSampleAddressForcedAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_ASC',
  SystemsConnectionVarianceSampleAddressForcedDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_FORCED_DESC',
  SystemsConnectionVarianceSampleAddressNormalizedAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  SystemsConnectionVarianceSampleAddressNormalizedDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  SystemsConnectionVarianceSampleAddressStateAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_ASC',
  SystemsConnectionVarianceSampleAddressStateDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STATE_DESC',
  SystemsConnectionVarianceSampleAddressStreetAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_ASC',
  SystemsConnectionVarianceSampleAddressStreetDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_STREET_DESC',
  SystemsConnectionVarianceSampleAddressZipAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_ASC',
  SystemsConnectionVarianceSampleAddressZipDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ZIP_DESC',
  SystemsConnectionVarianceSampleClientTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_ASC',
  SystemsConnectionVarianceSampleClientTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CLIENT_TYPE_DESC',
  SystemsConnectionVarianceSampleConnectionTypeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_ASC',
  SystemsConnectionVarianceSampleConnectionTypeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CONNECTION_TYPE_DESC',
  SystemsConnectionVarianceSampleCreatedAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemsConnectionVarianceSampleCreatedAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemsConnectionVarianceSampleIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemsConnectionVarianceSampleIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemsConnectionVarianceSampleInstalledAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_ASC',
  SystemsConnectionVarianceSampleInstalledAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INSTALLED_AT_DESC',
  SystemsConnectionVarianceSampleIntegrationIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  SystemsConnectionVarianceSampleIntegrationIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  SystemsConnectionVarianceSampleLastReportAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_ASC',
  SystemsConnectionVarianceSampleLastReportAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_LAST_REPORT_AT_DESC',
  SystemsConnectionVarianceSampleMonitoredAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_MONITORED_ASC',
  SystemsConnectionVarianceSampleMonitoredDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_MONITORED_DESC',
  SystemsConnectionVarianceSampleNameAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  SystemsConnectionVarianceSampleNameDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  SystemsConnectionVarianceSampleNotesAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  SystemsConnectionVarianceSampleNotesDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  SystemsConnectionVarianceSampleNumberAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NUMBER_ASC',
  SystemsConnectionVarianceSampleNumberDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_NUMBER_DESC',
  SystemsConnectionVarianceSampleOperationalAtAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_ASC',
  SystemsConnectionVarianceSampleOperationalAtDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATIONAL_AT_DESC',
  SystemsConnectionVarianceSampleOperationStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_ASC',
  SystemsConnectionVarianceSampleOperationStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_OPERATION_STATUS_DESC',
  SystemsConnectionVarianceSampleProfileIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemsConnectionVarianceSampleProfileIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  SystemsConnectionVarianceSampleProfileOverridesAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_ASC',
  SystemsConnectionVarianceSampleProfileOverridesDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROFILE_OVERRIDES_DESC',
  SystemsConnectionVarianceSampleProjectIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SystemsConnectionVarianceSampleProjectIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SystemsConnectionVarianceSampleProviderIdAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_ASC',
  SystemsConnectionVarianceSampleProviderIdDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_ID_DESC',
  SystemsConnectionVarianceSampleProviderStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_ASC',
  SystemsConnectionVarianceSampleProviderStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_PROVIDER_STATUS_DESC',
  SystemsConnectionVarianceSampleSizeAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_SIZE_ASC',
  SystemsConnectionVarianceSampleSizeDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_SIZE_DESC',
  SystemsConnectionVarianceSampleStatusAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SystemsConnectionVarianceSampleStatusDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SystemsConnectionVarianceSampleTimezoneAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_ASC',
  SystemsConnectionVarianceSampleTimezoneDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_TIMEZONE_DESC',
  SystemsConnectionVarianceSampleUuidAsc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  SystemsConnectionVarianceSampleUuidDesc = 'SYSTEMS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  SystemAlertProfilesConnectionArrayAggAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_ARRAY_AGG_ALERT_ID_ASC',
  SystemAlertProfilesConnectionArrayAggAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_ARRAY_AGG_ALERT_ID_DESC',
  SystemAlertProfilesConnectionArrayAggProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionArrayAggProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionAverageAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_AVERAGE_ALERT_ID_ASC',
  SystemAlertProfilesConnectionAverageAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_AVERAGE_ALERT_ID_DESC',
  SystemAlertProfilesConnectionAverageProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionAverageProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionCountAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_COUNT_ASC',
  SystemAlertProfilesConnectionCountDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_COUNT_DESC',
  SystemAlertProfilesConnectionDistinctCountAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_DISTINCT_COUNT_ALERT_ID_ASC',
  SystemAlertProfilesConnectionDistinctCountAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_DISTINCT_COUNT_ALERT_ID_DESC',
  SystemAlertProfilesConnectionDistinctCountProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionDistinctCountProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionMaxAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MAX_ALERT_ID_ASC',
  SystemAlertProfilesConnectionMaxAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MAX_ALERT_ID_DESC',
  SystemAlertProfilesConnectionMaxProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionMaxProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionMinAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MIN_ALERT_ID_ASC',
  SystemAlertProfilesConnectionMinAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MIN_ALERT_ID_DESC',
  SystemAlertProfilesConnectionMinProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionMinProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionStddevPopulationAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_POPULATION_ALERT_ID_ASC',
  SystemAlertProfilesConnectionStddevPopulationAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_POPULATION_ALERT_ID_DESC',
  SystemAlertProfilesConnectionStddevPopulationProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionStddevPopulationProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionStddevSampleAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_SAMPLE_ALERT_ID_ASC',
  SystemAlertProfilesConnectionStddevSampleAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_SAMPLE_ALERT_ID_DESC',
  SystemAlertProfilesConnectionStddevSampleProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionStddevSampleProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionSumAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_SUM_ALERT_ID_ASC',
  SystemAlertProfilesConnectionSumAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_SUM_ALERT_ID_DESC',
  SystemAlertProfilesConnectionSumProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionSumProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionVariancePopulationAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_POPULATION_ALERT_ID_ASC',
  SystemAlertProfilesConnectionVariancePopulationAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_POPULATION_ALERT_ID_DESC',
  SystemAlertProfilesConnectionVariancePopulationProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionVariancePopulationProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemAlertProfilesConnectionVarianceSampleAlertIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_SAMPLE_ALERT_ID_ASC',
  SystemAlertProfilesConnectionVarianceSampleAlertIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_SAMPLE_ALERT_ID_DESC',
  SystemAlertProfilesConnectionVarianceSampleProfileIdAsc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemAlertProfilesConnectionVarianceSampleProfileIdDesc = 'SYSTEM_ALERT_PROFILES_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionArrayAggBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionArrayAggBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionArrayAggProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionArrayAggProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionAverageBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionAverageBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionAverageProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionAverageProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionCountAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_COUNT_ASC',
  SystemBudgetProfileWhitelistsConnectionCountDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_COUNT_DESC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionDistinctCountProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMaxBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMaxBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMaxProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMaxProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMinBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMinBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionMinProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionMinProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevPopulationProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionStddevSampleProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionSumBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionSumBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionSumProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionSumProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVariancePopulationProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleBudgetIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_BUDGET_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleBudgetIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_BUDGET_ID_DESC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleProfileIdAsc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemBudgetProfileWhitelistsConnectionVarianceSampleProfileIdDesc = 'SYSTEM_BUDGET_PROFILE_WHITELISTS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionArrayAggEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  SystemProfileConfigsConnectionArrayAggEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  SystemProfileConfigsConnectionArrayAggFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_FREQUENCY_ASC',
  SystemProfileConfigsConnectionArrayAggFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_FREQUENCY_DESC',
  SystemProfileConfigsConnectionArrayAggIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionArrayAggIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionArrayAggMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_METRICS_ASC',
  SystemProfileConfigsConnectionArrayAggMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_METRICS_DESC',
  SystemProfileConfigsConnectionArrayAggProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionArrayAggProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_ARRAY_AGG_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionAverageEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_ENABLED_ASC',
  SystemProfileConfigsConnectionAverageEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_ENABLED_DESC',
  SystemProfileConfigsConnectionAverageFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionAverageFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionAverageIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionAverageIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionAverageMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_METRICS_ASC',
  SystemProfileConfigsConnectionAverageMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_METRICS_DESC',
  SystemProfileConfigsConnectionAverageProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionAverageProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_AVERAGE_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionCountAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_COUNT_ASC',
  SystemProfileConfigsConnectionCountDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_COUNT_DESC',
  SystemProfileConfigsConnectionDistinctCountEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  SystemProfileConfigsConnectionDistinctCountEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  SystemProfileConfigsConnectionDistinctCountFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_FREQUENCY_ASC',
  SystemProfileConfigsConnectionDistinctCountFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_FREQUENCY_DESC',
  SystemProfileConfigsConnectionDistinctCountIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionDistinctCountIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionDistinctCountMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_METRICS_ASC',
  SystemProfileConfigsConnectionDistinctCountMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_METRICS_DESC',
  SystemProfileConfigsConnectionDistinctCountProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionDistinctCountProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_DISTINCT_COUNT_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionMaxEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_ENABLED_ASC',
  SystemProfileConfigsConnectionMaxEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_ENABLED_DESC',
  SystemProfileConfigsConnectionMaxFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_FREQUENCY_ASC',
  SystemProfileConfigsConnectionMaxFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_FREQUENCY_DESC',
  SystemProfileConfigsConnectionMaxIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionMaxIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionMaxMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_METRICS_ASC',
  SystemProfileConfigsConnectionMaxMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_METRICS_DESC',
  SystemProfileConfigsConnectionMaxProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionMaxProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MAX_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionMinEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_ENABLED_ASC',
  SystemProfileConfigsConnectionMinEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_ENABLED_DESC',
  SystemProfileConfigsConnectionMinFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_FREQUENCY_ASC',
  SystemProfileConfigsConnectionMinFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_FREQUENCY_DESC',
  SystemProfileConfigsConnectionMinIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionMinIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionMinMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_METRICS_ASC',
  SystemProfileConfigsConnectionMinMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_METRICS_DESC',
  SystemProfileConfigsConnectionMinProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionMinProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_MIN_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionStddevPopulationEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  SystemProfileConfigsConnectionStddevPopulationEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  SystemProfileConfigsConnectionStddevPopulationFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_FREQUENCY_ASC',
  SystemProfileConfigsConnectionStddevPopulationFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_FREQUENCY_DESC',
  SystemProfileConfigsConnectionStddevPopulationIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionStddevPopulationIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionStddevPopulationMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_METRICS_ASC',
  SystemProfileConfigsConnectionStddevPopulationMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_METRICS_DESC',
  SystemProfileConfigsConnectionStddevPopulationProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionStddevPopulationProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_POPULATION_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionStddevSampleEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  SystemProfileConfigsConnectionStddevSampleEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  SystemProfileConfigsConnectionStddevSampleFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionStddevSampleFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionStddevSampleIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionStddevSampleIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionStddevSampleMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_METRICS_ASC',
  SystemProfileConfigsConnectionStddevSampleMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_METRICS_DESC',
  SystemProfileConfigsConnectionStddevSampleProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionStddevSampleProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_STDDEV_SAMPLE_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionSumEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_ENABLED_ASC',
  SystemProfileConfigsConnectionSumEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_ENABLED_DESC',
  SystemProfileConfigsConnectionSumFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_FREQUENCY_ASC',
  SystemProfileConfigsConnectionSumFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_FREQUENCY_DESC',
  SystemProfileConfigsConnectionSumIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionSumIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionSumMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_METRICS_ASC',
  SystemProfileConfigsConnectionSumMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_METRICS_DESC',
  SystemProfileConfigsConnectionSumProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionSumProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_SUM_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionVariancePopulationEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  SystemProfileConfigsConnectionVariancePopulationEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  SystemProfileConfigsConnectionVariancePopulationFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_FREQUENCY_ASC',
  SystemProfileConfigsConnectionVariancePopulationFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_FREQUENCY_DESC',
  SystemProfileConfigsConnectionVariancePopulationIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionVariancePopulationIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionVariancePopulationMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_METRICS_ASC',
  SystemProfileConfigsConnectionVariancePopulationMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_METRICS_DESC',
  SystemProfileConfigsConnectionVariancePopulationProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionVariancePopulationProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_POPULATION_PROFILE_ID_DESC',
  SystemProfileConfigsConnectionVarianceSampleEnabledAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  SystemProfileConfigsConnectionVarianceSampleEnabledDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  SystemProfileConfigsConnectionVarianceSampleFrequencyAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_FREQUENCY_ASC',
  SystemProfileConfigsConnectionVarianceSampleFrequencyDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_FREQUENCY_DESC',
  SystemProfileConfigsConnectionVarianceSampleIntegrationIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  SystemProfileConfigsConnectionVarianceSampleIntegrationIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  SystemProfileConfigsConnectionVarianceSampleMetricsAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_METRICS_ASC',
  SystemProfileConfigsConnectionVarianceSampleMetricsDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_METRICS_DESC',
  SystemProfileConfigsConnectionVarianceSampleProfileIdAsc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  SystemProfileConfigsConnectionVarianceSampleProfileIdDesc = 'SYSTEM_PROFILE_CONFIGS_CONNECTION_VARIANCE_SAMPLE_PROFILE_ID_DESC'
}

/** A connection to a list of `Project` values, with data from `Comment`. */
export type SystemProjectsByCommentSystemIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Comment`, and the cursor to aid in pagination. */
  edges: Array<SystemProjectsByCommentSystemIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Comment`. */
export type SystemProjectsByCommentSystemIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Comment`. */
export type SystemProjectsByCommentSystemIdAndProjectIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByProjectIdConnection: CommentsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Comment`. */
export type SystemProjectsByCommentSystemIdAndProjectIdManyToManyEdgeCommentsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type SystemProjectsByFeedSystemIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemProjectsByFeedSystemIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type SystemProjectsByFeedSystemIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type SystemProjectsByFeedSystemIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type SystemProjectsByFeedSystemIdAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `PrivilegedTask`, and the cursor to aid in pagination. */
  edges: Array<SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `PrivilegedTask`. */
export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByProjectIdConnection: PrivilegedTasksConnection;
};


/** A `Project` edge in the connection, with data from `PrivilegedTask`. */
export type SystemProjectsByPrivilegedTaskSystemIdAndProjectIdManyToManyEdgePrivilegedTasksByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type SystemRemindersByFeedSystemIdAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemRemindersByFeedSystemIdAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type SystemRemindersByFeedSystemIdAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type SystemRemindersByFeedSystemIdAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type SystemRemindersByFeedSystemIdAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export enum SystemStatus {
  Error = 'ERROR',
  Normal = 'NORMAL',
  Warning = 'WARNING'
}

/** A filter to be used against SystemStatus fields. All fields are combined with a logical ‘and.’ */
export type SystemStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<SystemStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<SystemStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<SystemStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<SystemStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<SystemStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<SystemStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<SystemStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<SystemStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<SystemStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<SystemStatus>>;
};

/** A filter to be used against SystemStatus List fields. All fields are combined with a logical ‘and.’ */
export type SystemStatusListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo: InputMaybe<SystemStatus>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan: InputMaybe<SystemStatus>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo: InputMaybe<SystemStatus>;
  /** Any array item is less than the specified value. */
  anyLessThan: InputMaybe<SystemStatus>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo: InputMaybe<SystemStatus>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo: InputMaybe<SystemStatus>;
  /** Contained by the specified list of values. */
  containedBy: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Contains the specified list of values. */
  contains: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Array<InputMaybe<SystemStatus>>>;
  /** Overlaps the specified list of values. */
  overlaps: InputMaybe<Array<InputMaybe<SystemStatus>>>;
};

export type SystemStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  size: InputMaybe<BigFloatFilter>;
};

export type SystemStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of number across the matching connection */
  number: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of profileId across the matching connection */
  profileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of size across the matching connection */
  size: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevPopulationAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevPopulationAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevPopulationAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevPopulationAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  size: InputMaybe<BigFloatFilter>;
};

export type SystemStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of number across the matching connection */
  number: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of profileId across the matching connection */
  profileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of size across the matching connection */
  size: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevSampleAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevSampleAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevSampleAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemStddevSampleAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  integrationId: InputMaybe<BigIntFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  size: InputMaybe<BigIntFilter>;
};

export type SystemSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt']['output'];
  /** Sum of number across the matching connection */
  number: Scalars['BigFloat']['output'];
  /** Sum of profileId across the matching connection */
  profileId: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of size across the matching connection */
  size: Scalars['BigInt']['output'];
};


export type SystemSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemSumAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemSumAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemSumAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemSumAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

/** A filter to be used against many `Comment` object types. All fields are combined with a logical ‘and.’ */
export type SystemToManyCommentFilter = {
  /** Aggregates across related `Comment` match the filter criteria. */
  aggregates: InputMaybe<CommentAggregatesFilter>;
  /** Every related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CommentFilter>;
  /** No related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CommentFilter>;
  /** Some related `Comment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CommentFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type SystemToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `PrivilegedTask` object types. All fields are combined with a logical ‘and.’ */
export type SystemToManyPrivilegedTaskFilter = {
  /** Aggregates across related `PrivilegedTask` match the filter criteria. */
  aggregates: InputMaybe<PrivilegedTaskAggregatesFilter>;
  /** Every related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<PrivilegedTaskFilter>;
  /** No related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<PrivilegedTaskFilter>;
  /** Some related `PrivilegedTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<PrivilegedTaskFilter>;
};

/** A filter to be used against many `SystemLog` object types. All fields are combined with a logical ‘and.’ */
export type SystemToManySystemLogFilter = {
  /** Aggregates across related `SystemLog` match the filter criteria. */
  aggregates: InputMaybe<SystemLogAggregatesFilter>;
  /** Every related `SystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemLogFilter>;
  /** No related `SystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemLogFilter>;
  /** Some related `SystemLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemLogFilter>;
};

/** A filter to be used against many `SystemLogThread` object types. All fields are combined with a logical ‘and.’ */
export type SystemToManySystemLogThreadFilter = {
  /** Aggregates across related `SystemLogThread` match the filter criteria. */
  aggregates: InputMaybe<SystemLogThreadAggregatesFilter>;
  /** Every related `SystemLogThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SystemLogThreadFilter>;
  /** No related `SystemLogThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SystemLogThreadFilter>;
  /** Some related `SystemLogThread` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SystemLogThreadFilter>;
};

/** A connection to a list of `User` values, with data from `Feed`. */
export type SystemUsersByFeedSystemIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<SystemUsersByFeedSystemIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Feed`. */
export type SystemUsersByFeedSystemIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Feed`. */
export type SystemUsersByFeedSystemIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};


/** A `User` edge in the connection, with data from `Feed`. */
export type SystemUsersByFeedSystemIdAndCreatedByManyToManyEdgeFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type SystemVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  size: InputMaybe<BigFloatFilter>;
};

export type SystemVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of number across the matching connection */
  number: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of profileId across the matching connection */
  profileId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of size across the matching connection */
  size: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVariancePopulationAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVariancePopulationAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVariancePopulationAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVariancePopulationAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

export type SystemVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  integrationId: InputMaybe<BigFloatFilter>;
  number: InputMaybe<BigFloatFilter>;
  profileId: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  size: InputMaybe<BigFloatFilter>;
};

export type SystemVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of number across the matching connection */
  number: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of profileId across the matching connection */
  profileId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of size across the matching connection */
  size: Maybe<Scalars['BigFloat']['output']>;
};


export type SystemVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVarianceSampleAggregatesIntegrationIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVarianceSampleAggregatesNumberArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVarianceSampleAggregatesProfileIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};


export type SystemVarianceSampleAggregatesSizeArgs = {
  orderBy: InputMaybe<Array<SystemsOrderBy>>;
};

/** A connection to a list of `System` values. */
export type SystemsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System` and cursor to aid in pagination. */
  edges: Array<SystemsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values. */
export type SystemsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection. */
export type SystemsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};

/** Methods to use when ordering `System`. */
export enum SystemsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressCityAsc = 'ADDRESS_CITY_ASC',
  AddressCityDesc = 'ADDRESS_CITY_DESC',
  AddressDesc = 'ADDRESS_DESC',
  AddressForcedAsc = 'ADDRESS_FORCED_ASC',
  AddressForcedDesc = 'ADDRESS_FORCED_DESC',
  AddressNormalizedAsc = 'ADDRESS_NORMALIZED_ASC',
  AddressNormalizedDesc = 'ADDRESS_NORMALIZED_DESC',
  AddressStateAsc = 'ADDRESS_STATE_ASC',
  AddressStateDesc = 'ADDRESS_STATE_DESC',
  AddressStreetAsc = 'ADDRESS_STREET_ASC',
  AddressStreetDesc = 'ADDRESS_STREET_DESC',
  AddressZipAsc = 'ADDRESS_ZIP_ASC',
  AddressZipDesc = 'ADDRESS_ZIP_DESC',
  ClientTypeAsc = 'CLIENT_TYPE_ASC',
  ClientTypeDesc = 'CLIENT_TYPE_DESC',
  CommentsBySystemIdConnectionArrayAggAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionArrayAggAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionArrayAggAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionArrayAggAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionArrayAggCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  CommentsBySystemIdConnectionArrayAggCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  CommentsBySystemIdConnectionArrayAggCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionArrayAggCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionArrayAggCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CommentsBySystemIdConnectionArrayAggCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CommentsBySystemIdConnectionArrayAggCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CommentsBySystemIdConnectionArrayAggCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionArrayAggCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionArrayAggCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CommentsBySystemIdConnectionArrayAggCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionArrayAggCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionArrayAggFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  CommentsBySystemIdConnectionArrayAggFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  CommentsBySystemIdConnectionArrayAggIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  CommentsBySystemIdConnectionArrayAggIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  CommentsBySystemIdConnectionArrayAggIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_PINNED_ASC',
  CommentsBySystemIdConnectionArrayAggIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_PINNED_DESC',
  CommentsBySystemIdConnectionArrayAggParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  CommentsBySystemIdConnectionArrayAggParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  CommentsBySystemIdConnectionArrayAggPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PINNED_AT_ASC',
  CommentsBySystemIdConnectionArrayAggPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PINNED_AT_DESC',
  CommentsBySystemIdConnectionArrayAggPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionArrayAggPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionArrayAggProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionArrayAggProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionArrayAggScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  CommentsBySystemIdConnectionArrayAggScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  CommentsBySystemIdConnectionArrayAggSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionArrayAggSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionArrayAggTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  CommentsBySystemIdConnectionArrayAggTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  CommentsBySystemIdConnectionArrayAggTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  CommentsBySystemIdConnectionArrayAggTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  CommentsBySystemIdConnectionArrayAggUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionArrayAggUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionArrayAggXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_X_ASC',
  CommentsBySystemIdConnectionArrayAggXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_X_DESC',
  CommentsBySystemIdConnectionArrayAggYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_Y_ASC',
  CommentsBySystemIdConnectionArrayAggYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_Y_DESC',
  CommentsBySystemIdConnectionAverageAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionAverageAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionAverageAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionAverageAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionAverageCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  CommentsBySystemIdConnectionAverageCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  CommentsBySystemIdConnectionAverageCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionAverageCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionAverageCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CommentsBySystemIdConnectionAverageCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CommentsBySystemIdConnectionAverageCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CommentsBySystemIdConnectionAverageCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionAverageCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionAverageCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CommentsBySystemIdConnectionAverageCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionAverageCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionAverageFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  CommentsBySystemIdConnectionAverageFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  CommentsBySystemIdConnectionAverageIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_ASC',
  CommentsBySystemIdConnectionAverageIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_DESC',
  CommentsBySystemIdConnectionAverageIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_PINNED_ASC',
  CommentsBySystemIdConnectionAverageIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_PINNED_DESC',
  CommentsBySystemIdConnectionAverageParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  CommentsBySystemIdConnectionAverageParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  CommentsBySystemIdConnectionAveragePinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PINNED_AT_ASC',
  CommentsBySystemIdConnectionAveragePinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PINNED_AT_DESC',
  CommentsBySystemIdConnectionAveragePreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionAveragePreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionAverageProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionAverageProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionAverageScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SCOPE_ASC',
  CommentsBySystemIdConnectionAverageScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SCOPE_DESC',
  CommentsBySystemIdConnectionAverageSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionAverageSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionAverageTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  CommentsBySystemIdConnectionAverageTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  CommentsBySystemIdConnectionAverageTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TYPE_ASC',
  CommentsBySystemIdConnectionAverageTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TYPE_DESC',
  CommentsBySystemIdConnectionAverageUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionAverageUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionAverageXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_X_ASC',
  CommentsBySystemIdConnectionAverageXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_X_DESC',
  CommentsBySystemIdConnectionAverageYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_Y_ASC',
  CommentsBySystemIdConnectionAverageYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_AVERAGE_Y_DESC',
  CommentsBySystemIdConnectionCountAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_COUNT_ASC',
  CommentsBySystemIdConnectionCountDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_COUNT_DESC',
  CommentsBySystemIdConnectionDistinctCountAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionDistinctCountAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionDistinctCountAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  CommentsBySystemIdConnectionDistinctCountCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  CommentsBySystemIdConnectionDistinctCountCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CommentsBySystemIdConnectionDistinctCountCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CommentsBySystemIdConnectionDistinctCountCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CommentsBySystemIdConnectionDistinctCountCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CommentsBySystemIdConnectionDistinctCountCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionDistinctCountCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionDistinctCountFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_ASC',
  CommentsBySystemIdConnectionDistinctCountIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_PINNED_DESC',
  CommentsBySystemIdConnectionDistinctCountParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_ASC',
  CommentsBySystemIdConnectionDistinctCountPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PINNED_AT_DESC',
  CommentsBySystemIdConnectionDistinctCountPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  CommentsBySystemIdConnectionDistinctCountScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  CommentsBySystemIdConnectionDistinctCountSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  CommentsBySystemIdConnectionDistinctCountTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  CommentsBySystemIdConnectionDistinctCountTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  CommentsBySystemIdConnectionDistinctCountTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  CommentsBySystemIdConnectionDistinctCountUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionDistinctCountUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionDistinctCountXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_X_ASC',
  CommentsBySystemIdConnectionDistinctCountXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_X_DESC',
  CommentsBySystemIdConnectionDistinctCountYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_Y_ASC',
  CommentsBySystemIdConnectionDistinctCountYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_Y_DESC',
  CommentsBySystemIdConnectionMaxAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionMaxAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionMaxAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionMaxAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionMaxCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_ASC',
  CommentsBySystemIdConnectionMaxCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_DESC',
  CommentsBySystemIdConnectionMaxCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionMaxCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionMaxCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CommentsBySystemIdConnectionMaxCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CommentsBySystemIdConnectionMaxCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_ASC',
  CommentsBySystemIdConnectionMaxCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionMaxCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionMaxCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_DESC',
  CommentsBySystemIdConnectionMaxCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionMaxCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionMaxFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_FILE_ID_ASC',
  CommentsBySystemIdConnectionMaxFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_FILE_ID_DESC',
  CommentsBySystemIdConnectionMaxIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ID_ASC',
  CommentsBySystemIdConnectionMaxIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_ID_DESC',
  CommentsBySystemIdConnectionMaxIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_IS_PINNED_ASC',
  CommentsBySystemIdConnectionMaxIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_IS_PINNED_DESC',
  CommentsBySystemIdConnectionMaxParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PARENT_ID_ASC',
  CommentsBySystemIdConnectionMaxParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PARENT_ID_DESC',
  CommentsBySystemIdConnectionMaxPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PINNED_AT_ASC',
  CommentsBySystemIdConnectionMaxPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PINNED_AT_DESC',
  CommentsBySystemIdConnectionMaxPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionMaxPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionMaxProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionMaxProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionMaxScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_SCOPE_ASC',
  CommentsBySystemIdConnectionMaxScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_SCOPE_DESC',
  CommentsBySystemIdConnectionMaxSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionMaxSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionMaxTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_TASK_ID_ASC',
  CommentsBySystemIdConnectionMaxTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_TASK_ID_DESC',
  CommentsBySystemIdConnectionMaxTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_TYPE_ASC',
  CommentsBySystemIdConnectionMaxTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_TYPE_DESC',
  CommentsBySystemIdConnectionMaxUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionMaxUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionMaxXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_X_ASC',
  CommentsBySystemIdConnectionMaxXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_X_DESC',
  CommentsBySystemIdConnectionMaxYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_Y_ASC',
  CommentsBySystemIdConnectionMaxYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MAX_Y_DESC',
  CommentsBySystemIdConnectionMinAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionMinAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionMinAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionMinAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionMinCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_ASC',
  CommentsBySystemIdConnectionMinCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_DESC',
  CommentsBySystemIdConnectionMinCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionMinCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionMinCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CommentsBySystemIdConnectionMinCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CommentsBySystemIdConnectionMinCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_ASC',
  CommentsBySystemIdConnectionMinCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionMinCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionMinCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_DESC',
  CommentsBySystemIdConnectionMinCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionMinCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionMinFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_FILE_ID_ASC',
  CommentsBySystemIdConnectionMinFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_FILE_ID_DESC',
  CommentsBySystemIdConnectionMinIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ID_ASC',
  CommentsBySystemIdConnectionMinIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_ID_DESC',
  CommentsBySystemIdConnectionMinIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_IS_PINNED_ASC',
  CommentsBySystemIdConnectionMinIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_IS_PINNED_DESC',
  CommentsBySystemIdConnectionMinParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PARENT_ID_ASC',
  CommentsBySystemIdConnectionMinParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PARENT_ID_DESC',
  CommentsBySystemIdConnectionMinPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PINNED_AT_ASC',
  CommentsBySystemIdConnectionMinPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PINNED_AT_DESC',
  CommentsBySystemIdConnectionMinPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionMinPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionMinProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionMinProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionMinScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_SCOPE_ASC',
  CommentsBySystemIdConnectionMinScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_SCOPE_DESC',
  CommentsBySystemIdConnectionMinSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionMinSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionMinTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_TASK_ID_ASC',
  CommentsBySystemIdConnectionMinTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_TASK_ID_DESC',
  CommentsBySystemIdConnectionMinTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_TYPE_ASC',
  CommentsBySystemIdConnectionMinTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_TYPE_DESC',
  CommentsBySystemIdConnectionMinUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionMinUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionMinXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_X_ASC',
  CommentsBySystemIdConnectionMinXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_X_DESC',
  CommentsBySystemIdConnectionMinYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_Y_ASC',
  CommentsBySystemIdConnectionMinYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_MIN_Y_DESC',
  CommentsBySystemIdConnectionStddevPopulationAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionStddevPopulationAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionStddevPopulationAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  CommentsBySystemIdConnectionStddevPopulationCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  CommentsBySystemIdConnectionStddevPopulationCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CommentsBySystemIdConnectionStddevPopulationCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionStddevPopulationCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionStddevPopulationFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_ASC',
  CommentsBySystemIdConnectionStddevPopulationIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_PINNED_DESC',
  CommentsBySystemIdConnectionStddevPopulationParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_ASC',
  CommentsBySystemIdConnectionStddevPopulationPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PINNED_AT_DESC',
  CommentsBySystemIdConnectionStddevPopulationPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  CommentsBySystemIdConnectionStddevPopulationScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  CommentsBySystemIdConnectionStddevPopulationSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  CommentsBySystemIdConnectionStddevPopulationTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  CommentsBySystemIdConnectionStddevPopulationTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  CommentsBySystemIdConnectionStddevPopulationTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  CommentsBySystemIdConnectionStddevPopulationUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionStddevPopulationUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionStddevPopulationXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_X_ASC',
  CommentsBySystemIdConnectionStddevPopulationXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_X_DESC',
  CommentsBySystemIdConnectionStddevPopulationYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_Y_ASC',
  CommentsBySystemIdConnectionStddevPopulationYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_Y_DESC',
  CommentsBySystemIdConnectionStddevSampleAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionStddevSampleAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionStddevSampleAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  CommentsBySystemIdConnectionStddevSampleCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  CommentsBySystemIdConnectionStddevSampleCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CommentsBySystemIdConnectionStddevSampleCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CommentsBySystemIdConnectionStddevSampleCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CommentsBySystemIdConnectionStddevSampleCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CommentsBySystemIdConnectionStddevSampleCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionStddevSampleCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionStddevSampleFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_ASC',
  CommentsBySystemIdConnectionStddevSampleIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_PINNED_DESC',
  CommentsBySystemIdConnectionStddevSampleParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  CommentsBySystemIdConnectionStddevSamplePinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_ASC',
  CommentsBySystemIdConnectionStddevSamplePinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PINNED_AT_DESC',
  CommentsBySystemIdConnectionStddevSamplePreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionStddevSamplePreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  CommentsBySystemIdConnectionStddevSampleScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  CommentsBySystemIdConnectionStddevSampleSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  CommentsBySystemIdConnectionStddevSampleTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  CommentsBySystemIdConnectionStddevSampleTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  CommentsBySystemIdConnectionStddevSampleTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  CommentsBySystemIdConnectionStddevSampleUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionStddevSampleUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionStddevSampleXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_X_ASC',
  CommentsBySystemIdConnectionStddevSampleXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_X_DESC',
  CommentsBySystemIdConnectionStddevSampleYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_Y_ASC',
  CommentsBySystemIdConnectionStddevSampleYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_Y_DESC',
  CommentsBySystemIdConnectionSumAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionSumAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionSumAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionSumAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionSumCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_ASC',
  CommentsBySystemIdConnectionSumCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_DESC',
  CommentsBySystemIdConnectionSumCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionSumCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionSumCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CommentsBySystemIdConnectionSumCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CommentsBySystemIdConnectionSumCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_ASC',
  CommentsBySystemIdConnectionSumCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionSumCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionSumCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_DESC',
  CommentsBySystemIdConnectionSumCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionSumCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionSumFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_FILE_ID_ASC',
  CommentsBySystemIdConnectionSumFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_FILE_ID_DESC',
  CommentsBySystemIdConnectionSumIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ID_ASC',
  CommentsBySystemIdConnectionSumIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_ID_DESC',
  CommentsBySystemIdConnectionSumIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_IS_PINNED_ASC',
  CommentsBySystemIdConnectionSumIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_IS_PINNED_DESC',
  CommentsBySystemIdConnectionSumParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PARENT_ID_ASC',
  CommentsBySystemIdConnectionSumParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PARENT_ID_DESC',
  CommentsBySystemIdConnectionSumPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PINNED_AT_ASC',
  CommentsBySystemIdConnectionSumPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PINNED_AT_DESC',
  CommentsBySystemIdConnectionSumPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionSumPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionSumProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionSumProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionSumScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_SCOPE_ASC',
  CommentsBySystemIdConnectionSumScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_SCOPE_DESC',
  CommentsBySystemIdConnectionSumSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionSumSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionSumTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_TASK_ID_ASC',
  CommentsBySystemIdConnectionSumTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_TASK_ID_DESC',
  CommentsBySystemIdConnectionSumTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_TYPE_ASC',
  CommentsBySystemIdConnectionSumTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_TYPE_DESC',
  CommentsBySystemIdConnectionSumUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionSumUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionSumXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_X_ASC',
  CommentsBySystemIdConnectionSumXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_X_DESC',
  CommentsBySystemIdConnectionSumYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_Y_ASC',
  CommentsBySystemIdConnectionSumYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_SUM_Y_DESC',
  CommentsBySystemIdConnectionVariancePopulationAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionVariancePopulationAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionVariancePopulationAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  CommentsBySystemIdConnectionVariancePopulationCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  CommentsBySystemIdConnectionVariancePopulationCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CommentsBySystemIdConnectionVariancePopulationCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionVariancePopulationCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionVariancePopulationFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_ASC',
  CommentsBySystemIdConnectionVariancePopulationIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_PINNED_DESC',
  CommentsBySystemIdConnectionVariancePopulationParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationPinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_ASC',
  CommentsBySystemIdConnectionVariancePopulationPinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PINNED_AT_DESC',
  CommentsBySystemIdConnectionVariancePopulationPreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationPreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  CommentsBySystemIdConnectionVariancePopulationScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  CommentsBySystemIdConnectionVariancePopulationSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  CommentsBySystemIdConnectionVariancePopulationTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  CommentsBySystemIdConnectionVariancePopulationTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  CommentsBySystemIdConnectionVariancePopulationTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  CommentsBySystemIdConnectionVariancePopulationUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionVariancePopulationUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionVariancePopulationXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_X_ASC',
  CommentsBySystemIdConnectionVariancePopulationXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_X_DESC',
  CommentsBySystemIdConnectionVariancePopulationYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_Y_ASC',
  CommentsBySystemIdConnectionVariancePopulationYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_Y_DESC',
  CommentsBySystemIdConnectionVarianceSampleAdditionalAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  CommentsBySystemIdConnectionVarianceSampleAdditionalDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  CommentsBySystemIdConnectionVarianceSampleAnnotationIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleAnnotationIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleCommentAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  CommentsBySystemIdConnectionVarianceSampleCommentDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  CommentsBySystemIdConnectionVarianceSampleCompanyIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleCompanyIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleCreatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CommentsBySystemIdConnectionVarianceSampleCreatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByContactIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByContactIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  CommentsBySystemIdConnectionVarianceSampleCreatedByTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  CommentsBySystemIdConnectionVarianceSampleFileIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleFileIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleIsPinnedAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_ASC',
  CommentsBySystemIdConnectionVarianceSampleIsPinnedDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_PINNED_DESC',
  CommentsBySystemIdConnectionVarianceSampleParentIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleParentIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommentsBySystemIdConnectionVarianceSamplePinnedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_ASC',
  CommentsBySystemIdConnectionVarianceSamplePinnedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PINNED_AT_DESC',
  CommentsBySystemIdConnectionVarianceSamplePreviousIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_ASC',
  CommentsBySystemIdConnectionVarianceSamplePreviousIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PREVIOUS_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleProjectIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleProjectIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleScopeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  CommentsBySystemIdConnectionVarianceSampleScopeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  CommentsBySystemIdConnectionVarianceSampleSystemIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleSystemIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleTaskIdAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  CommentsBySystemIdConnectionVarianceSampleTaskIdDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  CommentsBySystemIdConnectionVarianceSampleTypeAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  CommentsBySystemIdConnectionVarianceSampleTypeDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  CommentsBySystemIdConnectionVarianceSampleUpdatedAtAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CommentsBySystemIdConnectionVarianceSampleUpdatedAtDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CommentsBySystemIdConnectionVarianceSampleXAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_X_ASC',
  CommentsBySystemIdConnectionVarianceSampleXDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_X_DESC',
  CommentsBySystemIdConnectionVarianceSampleYAsc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_Y_ASC',
  CommentsBySystemIdConnectionVarianceSampleYDesc = 'COMMENTS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_Y_DESC',
  ConnectionTypeAsc = 'CONNECTION_TYPE_ASC',
  ConnectionTypeDesc = 'CONNECTION_TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FeedsBySystemIdConnectionArrayAggActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsBySystemIdConnectionArrayAggActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsBySystemIdConnectionArrayAggCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsBySystemIdConnectionArrayAggCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsBySystemIdConnectionArrayAggCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsBySystemIdConnectionArrayAggCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsBySystemIdConnectionArrayAggCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionArrayAggCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsBySystemIdConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsBySystemIdConnectionArrayAggCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsBySystemIdConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionArrayAggCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsBySystemIdConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionArrayAggEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsBySystemIdConnectionArrayAggEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsBySystemIdConnectionArrayAggEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionArrayAggEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionArrayAggFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsBySystemIdConnectionArrayAggFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsBySystemIdConnectionArrayAggIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsBySystemIdConnectionArrayAggIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsBySystemIdConnectionArrayAggParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsBySystemIdConnectionArrayAggParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsBySystemIdConnectionArrayAggPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsBySystemIdConnectionArrayAggPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsBySystemIdConnectionArrayAggProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionArrayAggProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionArrayAggReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionArrayAggReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionArrayAggRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsBySystemIdConnectionArrayAggRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsBySystemIdConnectionArrayAggRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionArrayAggRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionArrayAggSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionArrayAggSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionArrayAggSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionArrayAggSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionArrayAggTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsBySystemIdConnectionArrayAggTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsBySystemIdConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionAverageActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsBySystemIdConnectionAverageActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsBySystemIdConnectionAverageCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsBySystemIdConnectionAverageCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsBySystemIdConnectionAverageCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsBySystemIdConnectionAverageCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsBySystemIdConnectionAverageCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionAverageCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionAverageCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionAverageCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionAverageCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsBySystemIdConnectionAverageCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsBySystemIdConnectionAverageCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsBySystemIdConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionAverageCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsBySystemIdConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionAverageEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsBySystemIdConnectionAverageEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsBySystemIdConnectionAverageEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionAverageEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionAverageFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsBySystemIdConnectionAverageFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsBySystemIdConnectionAverageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_ASC',
  FeedsBySystemIdConnectionAverageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_DESC',
  FeedsBySystemIdConnectionAverageParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsBySystemIdConnectionAverageParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsBySystemIdConnectionAveragePayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsBySystemIdConnectionAveragePayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsBySystemIdConnectionAverageProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionAverageProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionAverageReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionAverageReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionAverageRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionAverageRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionAverageRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsBySystemIdConnectionAverageRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsBySystemIdConnectionAverageRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionAverageRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionAverageSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionAverageSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionAverageSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionAverageSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionAverageTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsBySystemIdConnectionAverageTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsBySystemIdConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionAverageWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionAverageWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_COUNT_ASC',
  FeedsBySystemIdConnectionCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_COUNT_DESC',
  FeedsBySystemIdConnectionDistinctCountActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsBySystemIdConnectionDistinctCountCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsBySystemIdConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsBySystemIdConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsBySystemIdConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsBySystemIdConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsBySystemIdConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsBySystemIdConnectionDistinctCountEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsBySystemIdConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionDistinctCountFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsBySystemIdConnectionDistinctCountPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsBySystemIdConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionDistinctCountRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsBySystemIdConnectionDistinctCountRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsBySystemIdConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsBySystemIdConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionMaxActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsBySystemIdConnectionMaxActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsBySystemIdConnectionMaxCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CALL_ID_ASC',
  FeedsBySystemIdConnectionMaxCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CALL_ID_DESC',
  FeedsBySystemIdConnectionMaxCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_ASC',
  FeedsBySystemIdConnectionMaxCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_DESC',
  FeedsBySystemIdConnectionMaxCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionMaxCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionMaxCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionMaxCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionMaxCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsBySystemIdConnectionMaxCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsBySystemIdConnectionMaxCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsBySystemIdConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionMaxCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsBySystemIdConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionMaxEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EVENT_ASC',
  FeedsBySystemIdConnectionMaxEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EVENT_DESC',
  FeedsBySystemIdConnectionMaxEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionMaxEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionMaxFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_FILE_ID_ASC',
  FeedsBySystemIdConnectionMaxFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_FILE_ID_DESC',
  FeedsBySystemIdConnectionMaxIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_ID_ASC',
  FeedsBySystemIdConnectionMaxIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_ID_DESC',
  FeedsBySystemIdConnectionMaxParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsBySystemIdConnectionMaxParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsBySystemIdConnectionMaxPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsBySystemIdConnectionMaxPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsBySystemIdConnectionMaxProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionMaxProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionMaxReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionMaxReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionMaxRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionMaxRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionMaxRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsBySystemIdConnectionMaxRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsBySystemIdConnectionMaxRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionMaxRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionMaxSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionMaxSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionMaxSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionMaxSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionMaxTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_TASK_ID_ASC',
  FeedsBySystemIdConnectionMaxTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_TASK_ID_DESC',
  FeedsBySystemIdConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionMaxWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionMaxWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionMinActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsBySystemIdConnectionMinActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsBySystemIdConnectionMinCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CALL_ID_ASC',
  FeedsBySystemIdConnectionMinCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CALL_ID_DESC',
  FeedsBySystemIdConnectionMinCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_ASC',
  FeedsBySystemIdConnectionMinCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_DESC',
  FeedsBySystemIdConnectionMinCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionMinCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionMinCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionMinCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionMinCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsBySystemIdConnectionMinCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsBySystemIdConnectionMinCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsBySystemIdConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionMinCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsBySystemIdConnectionMinCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionMinCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionMinEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionMinEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionMinEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EVENT_ASC',
  FeedsBySystemIdConnectionMinEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EVENT_DESC',
  FeedsBySystemIdConnectionMinEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionMinEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionMinFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_FILE_ID_ASC',
  FeedsBySystemIdConnectionMinFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_FILE_ID_DESC',
  FeedsBySystemIdConnectionMinIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_ID_ASC',
  FeedsBySystemIdConnectionMinIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_ID_DESC',
  FeedsBySystemIdConnectionMinParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsBySystemIdConnectionMinParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsBySystemIdConnectionMinPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsBySystemIdConnectionMinPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsBySystemIdConnectionMinProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionMinProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionMinReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionMinReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionMinRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionMinRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionMinRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsBySystemIdConnectionMinRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsBySystemIdConnectionMinRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionMinRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionMinSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionMinSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionMinSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionMinSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionMinSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionMinSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionMinTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_TASK_ID_ASC',
  FeedsBySystemIdConnectionMinTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_TASK_ID_DESC',
  FeedsBySystemIdConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionMinWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionMinWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsBySystemIdConnectionStddevPopulationCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsBySystemIdConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsBySystemIdConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsBySystemIdConnectionStddevPopulationEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsBySystemIdConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsBySystemIdConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsBySystemIdConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsBySystemIdConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsBySystemIdConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsBySystemIdConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsBySystemIdConnectionStddevSampleCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsBySystemIdConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsBySystemIdConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsBySystemIdConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsBySystemIdConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsBySystemIdConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsBySystemIdConnectionStddevSampleEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsBySystemIdConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionStddevSampleFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsBySystemIdConnectionStddevSamplePayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsBySystemIdConnectionStddevSamplePayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsBySystemIdConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionStddevSampleRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsBySystemIdConnectionStddevSampleRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsBySystemIdConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsBySystemIdConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionSumActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsBySystemIdConnectionSumActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsBySystemIdConnectionSumCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CALL_ID_ASC',
  FeedsBySystemIdConnectionSumCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CALL_ID_DESC',
  FeedsBySystemIdConnectionSumCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_ASC',
  FeedsBySystemIdConnectionSumCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_DESC',
  FeedsBySystemIdConnectionSumCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionSumCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionSumCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionSumCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionSumCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsBySystemIdConnectionSumCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsBySystemIdConnectionSumCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsBySystemIdConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionSumCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsBySystemIdConnectionSumCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionSumCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionSumEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionSumEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionSumEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EVENT_ASC',
  FeedsBySystemIdConnectionSumEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EVENT_DESC',
  FeedsBySystemIdConnectionSumEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionSumEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionSumFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_FILE_ID_ASC',
  FeedsBySystemIdConnectionSumFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_FILE_ID_DESC',
  FeedsBySystemIdConnectionSumIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_ID_ASC',
  FeedsBySystemIdConnectionSumIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_ID_DESC',
  FeedsBySystemIdConnectionSumParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsBySystemIdConnectionSumParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsBySystemIdConnectionSumPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsBySystemIdConnectionSumPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsBySystemIdConnectionSumProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionSumProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionSumReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionSumReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionSumRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionSumRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionSumRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsBySystemIdConnectionSumRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsBySystemIdConnectionSumRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionSumRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionSumSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionSumSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionSumSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionSumSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionSumSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionSumSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionSumTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_TASK_ID_ASC',
  FeedsBySystemIdConnectionSumTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_TASK_ID_DESC',
  FeedsBySystemIdConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionSumWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionSumWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsBySystemIdConnectionVariancePopulationCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsBySystemIdConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsBySystemIdConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsBySystemIdConnectionVariancePopulationEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsBySystemIdConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsBySystemIdConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsBySystemIdConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsBySystemIdConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsBySystemIdConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsBySystemIdConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleCommentAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsBySystemIdConnectionVarianceSampleCommentDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsBySystemIdConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsBySystemIdConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsBySystemIdConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsBySystemIdConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleEventAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsBySystemIdConnectionVarianceSampleEventDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsBySystemIdConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsBySystemIdConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsBySystemIdConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsBySystemIdConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsBySystemIdConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsBySystemIdConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsBySystemIdConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsBySystemIdConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsBySystemIdConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsBySystemIdConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsBySystemIdConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsBySystemIdConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsBySystemIdConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsBySystemIdConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsBySystemIdConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsBySystemIdConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsBySystemIdConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsBySystemIdConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InstalledAtAsc = 'INSTALLED_AT_ASC',
  InstalledAtDesc = 'INSTALLED_AT_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  LastReportAtAsc = 'LAST_REPORT_AT_ASC',
  LastReportAtDesc = 'LAST_REPORT_AT_DESC',
  MonitoredAsc = 'MONITORED_ASC',
  MonitoredDesc = 'MONITORED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  OperationalAtAsc = 'OPERATIONAL_AT_ASC',
  OperationalAtDesc = 'OPERATIONAL_AT_DESC',
  OperationStatusAsc = 'OPERATION_STATUS_ASC',
  OperationStatusDesc = 'OPERATION_STATUS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  PrivilegedTasksBySystemIdConnectionArrayAggVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionArrayAggVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionAverageAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionAverageAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionAverageAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionAverageCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionAverageCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionAverageCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionAverageCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionAverageCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionAverageCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionAverageCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionAverageDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionAverageDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionAverageEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionAverageEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionAverageEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionAverageEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionAverageFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionAverageFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionAverageIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionAverageIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionAverageJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionAverageModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionAverageNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionAveragePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionAverageProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionAverageStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionAverageStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionAverageStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionAverageStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionAverageStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionAverageSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionAverageTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionAverageTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionAverageTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionAverageUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_UID_ASC',
  PrivilegedTasksBySystemIdConnectionAverageUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_UID_DESC',
  PrivilegedTasksBySystemIdConnectionAverageVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionAverageVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionCountAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_COUNT_ASC',
  PrivilegedTasksBySystemIdConnectionCountDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_COUNT_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  PrivilegedTasksBySystemIdConnectionDistinctCountVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionDistinctCountVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionMaxAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionMaxAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionMaxAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionMaxCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionMaxCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionMaxCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionMaxCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionMaxCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionMaxDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionMaxDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionMaxEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionMaxEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionMaxEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionMaxFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionMaxIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionMaxModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionMaxNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionMaxPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionMaxProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionMaxStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionMaxStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionMaxStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionMaxSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionMaxTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionMaxTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionMaxTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionMaxUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_UID_ASC',
  PrivilegedTasksBySystemIdConnectionMaxUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_UID_DESC',
  PrivilegedTasksBySystemIdConnectionMaxVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionMaxVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionMinAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionMinAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionMinAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionMinCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionMinCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMinCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMinCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionMinCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionMinCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionMinCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionMinDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionMinDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionMinEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionMinEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionMinEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMinEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMinFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionMinFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionMinIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionMinIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionMinIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionMinIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionMinIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionMinIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionMinIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionMinIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionMinIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionMinIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionMinJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionMinModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionMinNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionMinPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionMinPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionMinPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionMinPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionMinProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionMinStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionMinStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionMinStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionMinStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionMinStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionMinSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionMinTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionMinTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionMinTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionMinTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionMinTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionMinUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_UID_ASC',
  PrivilegedTasksBySystemIdConnectionMinUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_UID_DESC',
  PrivilegedTasksBySystemIdConnectionMinVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionMinVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionStddevPopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  PrivilegedTasksBySystemIdConnectionStddevSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionStddevSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionSumAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionSumAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionSumAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionSumCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionSumCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionSumCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionSumCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionSumCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionSumCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionSumCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionSumDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionSumDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionSumEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionSumEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionSumEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionSumEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionSumFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionSumFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionSumIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionSumIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionSumIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionSumIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionSumIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionSumIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionSumIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionSumIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionSumIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionSumIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionSumJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionSumModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionSumNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionSumPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionSumPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionSumPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionSumPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionSumProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionSumStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionSumStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionSumStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionSumStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionSumStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionSumSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionSumTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionSumTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionSumTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionSumTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionSumTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionSumUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_UID_ASC',
  PrivilegedTasksBySystemIdConnectionSumUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_UID_DESC',
  PrivilegedTasksBySystemIdConnectionSumVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionSumVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationPrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionVariancePopulationVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleAddressAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleAddressDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleAssigneeIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleAssigneeIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCalculatedStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCalculatedStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCompanyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCompanyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCompletionDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCompletionDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCreatedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCreatedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCreatedByAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleCreatedByDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleDescriptionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleDescriptionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleEndDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleEndDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleEndDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleEndDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleFirstLabelIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleFirstLabelIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleFormsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleFormsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsArchivedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsArchivedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsColoredAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsColoredDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsCompletedAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsCompletedDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsFieldAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsFieldDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsTemplateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleIsTemplateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleJobIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleJobIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleModifiedAtAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleModifiedAtDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleNextVisitIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleNextVisitIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePositionAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePositionDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePriorityAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePriorityDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeAllAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeAllDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeOwnAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeOwnDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeTeamAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSamplePrivilegeTeamDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleProjectIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleProjectIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleProjectStageIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleProjectStageIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStartDateAllDayAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStartDateAllDayDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStartDateAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStartDateDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStatusAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleStatusDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleSystemIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleSystemIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplatePropertyIdsAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplatePropertyIdsDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplateTaskIdAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTemplateTaskIdDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTitleAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleTitleDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleUidAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleUidDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleVirtualPropertiesAsc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  PrivilegedTasksBySystemIdConnectionVarianceSampleVirtualPropertiesDesc = 'PRIVILEGED_TASKS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC',
  ProfileOverridesAsc = 'PROFILE_OVERRIDES_ASC',
  ProfileOverridesDesc = 'PROFILE_OVERRIDES_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProviderIdAsc = 'PROVIDER_ID_ASC',
  ProviderIdDesc = 'PROVIDER_ID_DESC',
  ProviderStatusAsc = 'PROVIDER_STATUS_ASC',
  ProviderStatusDesc = 'PROVIDER_STATUS_DESC',
  SizeAsc = 'SIZE_ASC',
  SizeDesc = 'SIZE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemLogsBySystemIdConnectionArrayAggCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionArrayAggCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionArrayAggIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SystemLogsBySystemIdConnectionArrayAggIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SystemLogsBySystemIdConnectionArrayAggLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_LABEL_ASC',
  SystemLogsBySystemIdConnectionArrayAggLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_LABEL_DESC',
  SystemLogsBySystemIdConnectionArrayAggMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionArrayAggMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionArrayAggStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SystemLogsBySystemIdConnectionArrayAggStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SystemLogsBySystemIdConnectionArrayAggSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionArrayAggSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionArrayAggThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_THREAD_ASC',
  SystemLogsBySystemIdConnectionArrayAggThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_THREAD_DESC',
  SystemLogsBySystemIdConnectionAverageCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionAverageCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionAverageIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_ASC',
  SystemLogsBySystemIdConnectionAverageIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_ID_DESC',
  SystemLogsBySystemIdConnectionAverageLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_LABEL_ASC',
  SystemLogsBySystemIdConnectionAverageLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_LABEL_DESC',
  SystemLogsBySystemIdConnectionAverageMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionAverageMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionAverageStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SystemLogsBySystemIdConnectionAverageStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SystemLogsBySystemIdConnectionAverageSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionAverageSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionAverageThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_THREAD_ASC',
  SystemLogsBySystemIdConnectionAverageThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_AVERAGE_THREAD_DESC',
  SystemLogsBySystemIdConnectionCountAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_COUNT_ASC',
  SystemLogsBySystemIdConnectionCountDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_COUNT_DESC',
  SystemLogsBySystemIdConnectionDistinctCountCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionDistinctCountCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionDistinctCountIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SystemLogsBySystemIdConnectionDistinctCountIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SystemLogsBySystemIdConnectionDistinctCountLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_LABEL_ASC',
  SystemLogsBySystemIdConnectionDistinctCountLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_LABEL_DESC',
  SystemLogsBySystemIdConnectionDistinctCountMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionDistinctCountMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionDistinctCountStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SystemLogsBySystemIdConnectionDistinctCountStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SystemLogsBySystemIdConnectionDistinctCountSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionDistinctCountSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionDistinctCountThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_THREAD_ASC',
  SystemLogsBySystemIdConnectionDistinctCountThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_THREAD_DESC',
  SystemLogsBySystemIdConnectionMaxCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionMaxCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionMaxIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_ID_ASC',
  SystemLogsBySystemIdConnectionMaxIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_ID_DESC',
  SystemLogsBySystemIdConnectionMaxLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_LABEL_ASC',
  SystemLogsBySystemIdConnectionMaxLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_LABEL_DESC',
  SystemLogsBySystemIdConnectionMaxMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionMaxMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionMaxStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_ASC',
  SystemLogsBySystemIdConnectionMaxStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_DESC',
  SystemLogsBySystemIdConnectionMaxSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionMaxSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionMaxThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_THREAD_ASC',
  SystemLogsBySystemIdConnectionMaxThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MAX_THREAD_DESC',
  SystemLogsBySystemIdConnectionMinCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionMinCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionMinIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_ID_ASC',
  SystemLogsBySystemIdConnectionMinIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_ID_DESC',
  SystemLogsBySystemIdConnectionMinLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_LABEL_ASC',
  SystemLogsBySystemIdConnectionMinLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_LABEL_DESC',
  SystemLogsBySystemIdConnectionMinMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionMinMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionMinStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_ASC',
  SystemLogsBySystemIdConnectionMinStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_DESC',
  SystemLogsBySystemIdConnectionMinSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionMinSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionMinThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_THREAD_ASC',
  SystemLogsBySystemIdConnectionMinThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_MIN_THREAD_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_LABEL_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_LABEL_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionStddevPopulationThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_THREAD_ASC',
  SystemLogsBySystemIdConnectionStddevPopulationThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_THREAD_DESC',
  SystemLogsBySystemIdConnectionStddevSampleCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionStddevSampleCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionStddevSampleIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SystemLogsBySystemIdConnectionStddevSampleIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SystemLogsBySystemIdConnectionStddevSampleLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ASC',
  SystemLogsBySystemIdConnectionStddevSampleLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_LABEL_DESC',
  SystemLogsBySystemIdConnectionStddevSampleMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionStddevSampleMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionStddevSampleStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SystemLogsBySystemIdConnectionStddevSampleStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SystemLogsBySystemIdConnectionStddevSampleSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionStddevSampleSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionStddevSampleThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_THREAD_ASC',
  SystemLogsBySystemIdConnectionStddevSampleThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_THREAD_DESC',
  SystemLogsBySystemIdConnectionSumCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionSumCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionSumIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_ID_ASC',
  SystemLogsBySystemIdConnectionSumIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_ID_DESC',
  SystemLogsBySystemIdConnectionSumLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_LABEL_ASC',
  SystemLogsBySystemIdConnectionSumLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_LABEL_DESC',
  SystemLogsBySystemIdConnectionSumMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionSumMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionSumStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_ASC',
  SystemLogsBySystemIdConnectionSumStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_DESC',
  SystemLogsBySystemIdConnectionSumSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionSumSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionSumThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_THREAD_ASC',
  SystemLogsBySystemIdConnectionSumThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_SUM_THREAD_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_LABEL_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionVariancePopulationThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_THREAD_ASC',
  SystemLogsBySystemIdConnectionVariancePopulationThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_THREAD_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleCreatedAtAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleCreatedAtDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleLabelAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleLabelDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleMessageAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_MESSAGE_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleMessageDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_MESSAGE_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleStatusAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleStatusDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleSystemIdAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleSystemIdDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  SystemLogsBySystemIdConnectionVarianceSampleThreadAsc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_THREAD_ASC',
  SystemLogsBySystemIdConnectionVarianceSampleThreadDesc = 'SYSTEM_LOGS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionArrayAggCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionArrayAggCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionArrayAggEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionArrayAggEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionArrayAggStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionArrayAggStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionArrayAggSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionArrayAggSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionArrayAggThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionArrayAggThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_ARRAY_AGG_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionAverageCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionAverageCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionAverageEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionAverageEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionAverageStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionAverageStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionAverageSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionAverageSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionAverageThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionAverageThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_AVERAGE_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionCountAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_COUNT_ASC',
  SystemLogThreadsBySystemIdConnectionCountDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_COUNT_DESC',
  SystemLogThreadsBySystemIdConnectionDistinctCountCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionDistinctCountCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionDistinctCountEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionDistinctCountEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionDistinctCountStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionDistinctCountStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionDistinctCountSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionDistinctCountSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionDistinctCountThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionDistinctCountThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_DISTINCT_COUNT_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionMaxCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionMaxCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionMaxEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionMaxEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionMaxStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionMaxStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionMaxSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionMaxSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionMaxThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionMaxThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MAX_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionMinCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionMinCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionMinEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionMinEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionMinStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionMinStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionMinSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionMinSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionMinThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionMinThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_MIN_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionStddevPopulationThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_POPULATION_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionStddevSampleCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionStddevSampleCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionStddevSampleEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionStddevSampleEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionStddevSampleStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionStddevSampleStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionStddevSampleSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionStddevSampleSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionStddevSampleThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionStddevSampleThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_STDDEV_SAMPLE_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionSumCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionSumCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionSumEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionSumEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionSumStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionSumStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionSumSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionSumSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionSumThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionSumThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_SUM_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionVariancePopulationThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_POPULATION_THREAD_DESC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleCreatedAtAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleCreatedAtDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleEventsAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENTS_ASC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleEventsDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_EVENTS_DESC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleStatusAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleStatusDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleSystemIdAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleSystemIdDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleThreadAsc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_THREAD_ASC',
  SystemLogThreadsBySystemIdConnectionVarianceSampleThreadDesc = 'SYSTEM_LOG_THREADS_BY_SYSTEM_ID_CONNECTION_VARIANCE_SAMPLE_THREAD_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

export type Task = Node & {
  address: Maybe<Scalars['String']['output']>;
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `BlueprintTask`. */
  blueprintTasks: Array<BlueprintTask>;
  calculatedStatus: TaskStatus;
  companyId: Scalars['Int']['output'];
  completionDate: Maybe<Scalars['Datetime']['output']>;
  completionTime: Maybe<Interval>;
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `Task`. */
  createdByUser: Maybe<User>;
  /** Reads and enables pagination through a set of `Task`. */
  derivedTasksConnection: TasksConnection;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['Datetime']['output']>;
  endDateAllDay: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Label` that is related to this `Task`. */
  firstLabel: Maybe<Label>;
  firstLabelId: Maybe<Scalars['Int']['output']>;
  forms: Array<Maybe<Scalars['Int']['output']>>;
  /** Reads and enables pagination through a set of `GeoEstimation`. */
  geoEstimations: Array<GeoEstimation>;
  /** Reads and enables pagination through a set of `GeoEstimation`. */
  geoEstimationsConnection: GeoEstimationsConnection;
  /** Reads and enables pagination through a set of `Geolocation`. */
  geolocations: Array<Geolocation>;
  /** Reads and enables pagination through a set of `Geolocation`. */
  geolocationsConnection: GeolocationsConnection;
  id: Scalars['Int']['output'];
  isArchived: Scalars['Boolean']['output'];
  isColored: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  isField: Maybe<Scalars['Boolean']['output']>;
  isTemplate: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['String']['output']>;
  modifiedAt: Scalars['Datetime']['output'];
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  priority: Scalars['Int']['output'];
  /** Reads a single `Project` that is related to this `Task`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  projectStageId: Maybe<Scalars['Int']['output']>;
  startDate: Maybe<Scalars['Datetime']['output']>;
  startDateAllDay: Maybe<Scalars['Boolean']['output']>;
  status: TaskStatus;
  /** Reads and enables pagination through a set of `SubTask`. */
  subTasks: Array<SubTask>;
  systemId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  taskLabels: Array<TaskLabel>;
  /** Reads and enables pagination through a set of `TaskTemplateUser`. */
  taskTemplateUsers: Array<TaskTemplateUser>;
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  templatePropertyIds: Array<Maybe<Scalars['Int']['output']>>;
  /** Reads a single `Task` that is related to this `Task`. */
  templateTask: Maybe<Task>;
  templateTaskId: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  uid: Maybe<Scalars['Int']['output']>;
  virtualProperties: Array<Maybe<Scalars['String']['output']>>;
};


export type TaskBlueprintTasksArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};


export type TaskDerivedTasksConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskCondition>;
  filter: InputMaybe<TaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskGeoEstimationsArgs = {
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type TaskGeoEstimationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeoEstimationCondition>;
  filter: InputMaybe<GeoEstimationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoEstimationsOrderBy>>;
};


export type TaskGeolocationsArgs = {
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type TaskGeolocationsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<GeolocationCondition>;
  filter: InputMaybe<GeolocationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeolocationsOrderBy>>;
};


export type TaskSubTasksArgs = {
  condition: InputMaybe<SubTaskCondition>;
  filter: InputMaybe<SubTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SubTasksOrderBy>>;
};


export type TaskTaskLabelsArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};


export type TaskTaskTemplateUsersArgs = {
  condition: InputMaybe<TaskTemplateUserCondition>;
  filter: InputMaybe<TaskTemplateUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskTemplateUsersOrderBy>>;
};

export type TaskAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<TaskArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<TaskAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<TaskDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<TaskMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<TaskMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<TaskStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<TaskStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<TaskSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<TaskVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<TaskVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Task` object types. */
export type TaskAggregatesFilter = {
  /** Array aggregation aggregate over matching `Task` objects. */
  arrayAgg: InputMaybe<TaskArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Task` objects. */
  average: InputMaybe<TaskAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Task` objects. */
  distinctCount: InputMaybe<TaskDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Task` object to be included within the aggregate. */
  filter: InputMaybe<TaskFilter>;
  /** Maximum aggregate over matching `Task` objects. */
  max: InputMaybe<TaskMaxAggregateFilter>;
  /** Minimum aggregate over matching `Task` objects. */
  min: InputMaybe<TaskMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Task` objects. */
  stddevPopulation: InputMaybe<TaskStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Task` objects. */
  stddevSample: InputMaybe<TaskStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Task` objects. */
  sum: InputMaybe<TaskSumAggregateFilter>;
  /** Population variance aggregate over matching `Task` objects. */
  variancePopulation: InputMaybe<TaskVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Task` objects. */
  varianceSample: InputMaybe<TaskVarianceSampleAggregateFilter>;
};

export type TaskArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  assigneeId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  endDateAllDay: InputMaybe<BooleanListFilter>;
  firstLabelId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isArchived: InputMaybe<BooleanListFilter>;
  isColored: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  isField: InputMaybe<BooleanListFilter>;
  isTemplate: InputMaybe<BooleanListFilter>;
  jobId: InputMaybe<StringListFilter>;
  nextVisitId: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  priority: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  projectStageId: InputMaybe<IntListFilter>;
  startDateAllDay: InputMaybe<BooleanListFilter>;
  systemId: InputMaybe<IntListFilter>;
  templateAssigneePropertyId: InputMaybe<IntListFilter>;
  templateTaskId: InputMaybe<IntListFilter>;
  uid: InputMaybe<IntListFilter>;
};

export type TaskArrayAggAggregates = {
  /** Array aggregation of address across the matching connection */
  address: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of assigneeId across the matching connection */
  assigneeId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of endDateAllDay across the matching connection */
  endDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of firstLabelId across the matching connection */
  firstLabelId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isArchived across the matching connection */
  isArchived: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isColored across the matching connection */
  isColored: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isField across the matching connection */
  isField: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isTemplate across the matching connection */
  isTemplate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jobId across the matching connection */
  jobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of nextVisitId across the matching connection */
  nextVisitId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of priority across the matching connection */
  priority: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectStageId across the matching connection */
  projectStageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of startDateAllDay across the matching connection */
  startDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateTaskId across the matching connection */
  templateTaskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
};


export type TaskArrayAggAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskAverageAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type TaskAverageAggregates = {
  /** Mean average of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskAverageAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

/** A condition to be used against `Task` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TaskCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completionDate` field. */
  completionDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `forms` field. */
  forms: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isColored` field. */
  isColored: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isField` field. */
  isField: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectStageId` field. */
  projectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TaskDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  assigneeId: InputMaybe<BigIntFilter>;
  calculatedStatus: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionDate: InputMaybe<BigIntFilter>;
  completionTime: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  endDate: InputMaybe<BigIntFilter>;
  endDateAllDay: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  forms: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isArchived: InputMaybe<BigIntFilter>;
  isColored: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  isField: InputMaybe<BigIntFilter>;
  isTemplate: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  modifiedAt: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  startDate: InputMaybe<BigIntFilter>;
  startDateAllDay: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templatePropertyIds: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  virtualProperties: InputMaybe<BigIntFilter>;
};

export type TaskDistinctCountAggregates = {
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calculatedStatus across the matching connection */
  calculatedStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionDate across the matching connection */
  completionDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTime across the matching connection */
  completionTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDate across the matching connection */
  endDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDateAllDay across the matching connection */
  endDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forms across the matching connection */
  forms: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isArchived across the matching connection */
  isArchived: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isColored across the matching connection */
  isColored: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isField across the matching connection */
  isField: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDate across the matching connection */
  startDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDateAllDay across the matching connection */
  startDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templatePropertyIds across the matching connection */
  templatePropertyIds: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of virtualProperties across the matching connection */
  virtualProperties: Maybe<Scalars['BigInt']['output']>;
};


export type TaskDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCalculatedStatusArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCompletionTimeArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesFormsArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesTemplatePropertyIdsArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskDistinctCountAggregatesVirtualPropertiesArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

/** A filter to be used against `Task` object types. All fields are combined with a logical ‘and.’ */
export type TaskFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskFilter>>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId: InputMaybe<IntFilter>;
  /** Filter by the object’s `blueprintTasks` relation. */
  blueprintTasks: InputMaybe<TaskToManyBlueprintTaskFilter>;
  /** Some related `blueprintTasks` exist. */
  blueprintTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completionDate` field. */
  completionDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** Filter by the object’s `derivedTasks` relation. */
  derivedTasks: InputMaybe<TaskToManyTaskFilter>;
  /** Some related `derivedTasks` exist. */
  derivedTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `firstLabel` relation. */
  firstLabel: InputMaybe<LabelFilter>;
  /** A related `firstLabel` exists. */
  firstLabelExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<IntFilter>;
  /** Filter by the object’s `forms` field. */
  forms: InputMaybe<IntListFilter>;
  /** Filter by the object’s `geoEstimations` relation. */
  geoEstimations: InputMaybe<TaskToManyGeoEstimationFilter>;
  /** Some related `geoEstimations` exist. */
  geoEstimationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `geolocations` relation. */
  geolocations: InputMaybe<TaskToManyGeolocationFilter>;
  /** Some related `geolocations` exist. */
  geolocationsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isColored` field. */
  isColored: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isField` field. */
  isField: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `priority` field. */
  priority: InputMaybe<IntFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectStageId` field. */
  projectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `subTasks` relation. */
  subTasks: InputMaybe<TaskToManySubTaskFilter>;
  /** Some related `subTasks` exist. */
  subTasksExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskLabels` relation. */
  taskLabels: InputMaybe<TaskToManyTaskLabelFilter>;
  /** Some related `taskLabels` exist. */
  taskLabelsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskTemplateUsers` relation. */
  taskTemplateUsers: InputMaybe<TaskToManyTaskTemplateUserFilter>;
  /** Some related `taskTemplateUsers` exist. */
  taskTemplateUsersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<IntListFilter>;
  /** Filter by the object’s `templateTask` relation. */
  templateTask: InputMaybe<TaskFilter>;
  /** A related `templateTask` exists. */
  templateTaskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<StringListFilter>;
};

/** Grouping methods for `Task` for usage during aggregation. */
export enum TaskGroupBy {
  Address = 'ADDRESS',
  AssigneeId = 'ASSIGNEE_ID',
  CalculatedStatus = 'CALCULATED_STATUS',
  CompanyId = 'COMPANY_ID',
  CompletionDate = 'COMPLETION_DATE',
  CompletionDateTruncatedToDay = 'COMPLETION_DATE_TRUNCATED_TO_DAY',
  CompletionDateTruncatedToHour = 'COMPLETION_DATE_TRUNCATED_TO_HOUR',
  CompletionDateTruncatedToMonth = 'COMPLETION_DATE_TRUNCATED_TO_MONTH',
  CompletionDateTruncatedToQuarter = 'COMPLETION_DATE_TRUNCATED_TO_QUARTER',
  CompletionDateTruncatedToWeek = 'COMPLETION_DATE_TRUNCATED_TO_WEEK',
  CompletionDateTruncatedToYear = 'COMPLETION_DATE_TRUNCATED_TO_YEAR',
  CompletionTime = 'COMPLETION_TIME',
  CompletionTimeInS = 'COMPLETION_TIME_IN_S',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  EndDate = 'END_DATE',
  EndDateAllDay = 'END_DATE_ALL_DAY',
  EndDateTruncatedToDay = 'END_DATE_TRUNCATED_TO_DAY',
  EndDateTruncatedToHour = 'END_DATE_TRUNCATED_TO_HOUR',
  EndDateTruncatedToMonth = 'END_DATE_TRUNCATED_TO_MONTH',
  EndDateTruncatedToQuarter = 'END_DATE_TRUNCATED_TO_QUARTER',
  EndDateTruncatedToWeek = 'END_DATE_TRUNCATED_TO_WEEK',
  EndDateTruncatedToYear = 'END_DATE_TRUNCATED_TO_YEAR',
  FirstLabelId = 'FIRST_LABEL_ID',
  Forms = 'FORMS',
  IsArchived = 'IS_ARCHIVED',
  IsColored = 'IS_COLORED',
  IsCompleted = 'IS_COMPLETED',
  IsField = 'IS_FIELD',
  IsTemplate = 'IS_TEMPLATE',
  JobId = 'JOB_ID',
  ModifiedAt = 'MODIFIED_AT',
  ModifiedAtTruncatedToDay = 'MODIFIED_AT_TRUNCATED_TO_DAY',
  ModifiedAtTruncatedToHour = 'MODIFIED_AT_TRUNCATED_TO_HOUR',
  ModifiedAtTruncatedToMonth = 'MODIFIED_AT_TRUNCATED_TO_MONTH',
  ModifiedAtTruncatedToQuarter = 'MODIFIED_AT_TRUNCATED_TO_QUARTER',
  ModifiedAtTruncatedToWeek = 'MODIFIED_AT_TRUNCATED_TO_WEEK',
  ModifiedAtTruncatedToYear = 'MODIFIED_AT_TRUNCATED_TO_YEAR',
  NextVisitId = 'NEXT_VISIT_ID',
  Position = 'POSITION',
  Priority = 'PRIORITY',
  ProjectId = 'PROJECT_ID',
  ProjectStageId = 'PROJECT_STAGE_ID',
  StartDate = 'START_DATE',
  StartDateAllDay = 'START_DATE_ALL_DAY',
  StartDateTruncatedToDay = 'START_DATE_TRUNCATED_TO_DAY',
  StartDateTruncatedToHour = 'START_DATE_TRUNCATED_TO_HOUR',
  StartDateTruncatedToMonth = 'START_DATE_TRUNCATED_TO_MONTH',
  StartDateTruncatedToQuarter = 'START_DATE_TRUNCATED_TO_QUARTER',
  StartDateTruncatedToWeek = 'START_DATE_TRUNCATED_TO_WEEK',
  StartDateTruncatedToYear = 'START_DATE_TRUNCATED_TO_YEAR',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  TemplateAssigneePropertyId = 'TEMPLATE_ASSIGNEE_PROPERTY_ID',
  TemplatePropertyIds = 'TEMPLATE_PROPERTY_IDS',
  TemplateTaskId = 'TEMPLATE_TASK_ID',
  Title = 'TITLE',
  Uid = 'UID',
  VirtualProperties = 'VIRTUAL_PROPERTIES'
}

export type TaskHavingArrayAggInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingAverageInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingDistinctCountInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Task` aggregates. */
export type TaskHavingInput = {
  AND: InputMaybe<Array<TaskHavingInput>>;
  OR: InputMaybe<Array<TaskHavingInput>>;
  arrayAgg: InputMaybe<TaskHavingArrayAggInput>;
  average: InputMaybe<TaskHavingAverageInput>;
  distinctCount: InputMaybe<TaskHavingDistinctCountInput>;
  max: InputMaybe<TaskHavingMaxInput>;
  min: InputMaybe<TaskHavingMinInput>;
  stddevPopulation: InputMaybe<TaskHavingStddevPopulationInput>;
  stddevSample: InputMaybe<TaskHavingStddevSampleInput>;
  sum: InputMaybe<TaskHavingSumInput>;
  variancePopulation: InputMaybe<TaskHavingVariancePopulationInput>;
  varianceSample: InputMaybe<TaskHavingVarianceSampleInput>;
};

export type TaskHavingMaxInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingMinInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingStddevPopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingStddevSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingSumInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingVariancePopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskHavingVarianceSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  uid: InputMaybe<HavingIntFilter>;
};

export type TaskLabel = Node & {
  /** Reads a single `Label` that is related to this `TaskLabel`. */
  label: Maybe<Label>;
  labelId: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PrivilegedTask` that is related to this `TaskLabel`. */
  privilegedTaskId: Maybe<PrivilegedTask>;
  /** Reads a single `Task` that is related to this `TaskLabel`. */
  task: Maybe<Task>;
  taskId: Scalars['Int']['output'];
  /** Reads a single `TaskReport` that is related to this `TaskLabel`. */
  task_report_id: Maybe<TaskReport>;
};

/** A filter to be used against aggregates of `TaskLabel` object types. */
export type TaskLabelAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskLabel` objects. */
  arrayAgg: InputMaybe<TaskLabelArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskLabel` objects. */
  average: InputMaybe<TaskLabelAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskLabel` objects. */
  distinctCount: InputMaybe<TaskLabelDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskLabel` object to be included within the aggregate. */
  filter: InputMaybe<TaskLabelFilter>;
  /** Maximum aggregate over matching `TaskLabel` objects. */
  max: InputMaybe<TaskLabelMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskLabel` objects. */
  min: InputMaybe<TaskLabelMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskLabel` objects. */
  stddevPopulation: InputMaybe<TaskLabelStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskLabel` objects. */
  stddevSample: InputMaybe<TaskLabelStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskLabel` objects. */
  sum: InputMaybe<TaskLabelSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskLabel` objects. */
  variancePopulation: InputMaybe<TaskLabelVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskLabel` objects. */
  varianceSample: InputMaybe<TaskLabelVarianceSampleAggregateFilter>;
};

export type TaskLabelArrayAggAggregateFilter = {
  labelId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type TaskLabelAverageAggregateFilter = {
  labelId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `TaskLabel` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaskLabelCondition = {
  /** Checks for equality with the object’s `labelId` field. */
  labelId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
};

export type TaskLabelDistinctCountAggregateFilter = {
  labelId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `TaskLabel` object types. All fields are combined with a logical ‘and.’ */
export type TaskLabelFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskLabelFilter>>;
  /** Filter by the object’s `label` relation. */
  label: InputMaybe<LabelFilter>;
  /** Filter by the object’s `labelId` field. */
  labelId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskLabelFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskLabelFilter>>;
  /** Filter by the object’s `privilegedTaskId` relation. */
  privilegedTaskId: InputMaybe<PrivilegedTaskFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task_report_id` relation. */
  task_report_id: InputMaybe<TaskReportFilter>;
};

export type TaskLabelMaxAggregateFilter = {
  labelId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskLabelMinAggregateFilter = {
  labelId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskLabelStddevPopulationAggregateFilter = {
  labelId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskLabelStddevSampleAggregateFilter = {
  labelId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskLabelSumAggregateFilter = {
  labelId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type TaskLabelVariancePopulationAggregateFilter = {
  labelId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskLabelVarianceSampleAggregateFilter = {
  labelId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `TaskLabel`. */
export enum TaskLabelsOrderBy {
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC'
}

export type TaskMaxAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
};

export type TaskMaxAggregates = {
  /** Maximum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
};


export type TaskMaxAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskMinAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  uid: InputMaybe<IntFilter>;
};

export type TaskMinAggregates = {
  /** Minimum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
};


export type TaskMinAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskReport = {
  address: Maybe<Scalars['String']['output']>;
  /** Reads a single `MainUser` that is related to this `TaskReport`. */
  assignee: Maybe<MainUser>;
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  calculatedStatus: Maybe<TaskStatus>;
  /** Reads a single `Company` that is related to this `TaskReport`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  completionDate: Maybe<Scalars['Datetime']['output']>;
  completionTime: Maybe<Interval>;
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `TaskReport`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['Datetime']['output']>;
  endDateAllDay: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Label` that is related to this `TaskReport`. */
  firstLabel: Maybe<Label>;
  firstLabelId: Maybe<Scalars['Int']['output']>;
  forms: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id: Maybe<Scalars['Int']['output']>;
  isArchived: Maybe<Scalars['Boolean']['output']>;
  isColored: Maybe<Scalars['Boolean']['output']>;
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  isField: Maybe<Scalars['Boolean']['output']>;
  isTemplate: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['String']['output']>;
  labelId: Maybe<Scalars['Int']['output']>;
  labelName: Maybe<Scalars['String']['output']>;
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  nextVisitId: Maybe<Scalars['Int']['output']>;
  position: Maybe<Scalars['Int']['output']>;
  priority: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByAssigneeTaskIdAndTaskId: TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskLabelTaskIdAndTaskId: TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByTaskVisitTaskIdAndTaskId: TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyConnection;
  /** Reads a single `Project` that is related to this `TaskReport`. */
  project: Maybe<Project>;
  projectId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembersByAssigneeTaskIdAndProjectMemberIdList: Array<ProjectMember>;
  /** Reads a single `ProjectStage` that is related to this `TaskReport`. */
  projectStage: Maybe<ProjectStage>;
  projectStageId: Maybe<Scalars['Int']['output']>;
  startDate: Maybe<Scalars['Datetime']['output']>;
  startDateAllDay: Maybe<Scalars['Boolean']['output']>;
  status: Maybe<TaskStatus>;
  systemId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `PrivilegedTask` that is related to this `TaskReport`. */
  task: Maybe<PrivilegedTask>;
  taskId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  taskLabelsByTaskId: Array<TaskLabel>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskId: Array<TaskVisit>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskIdConnection: TaskVisitsConnection;
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  templatePropertyIds: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Reads a single `PrivilegedTask` that is related to this `TaskReport`. */
  templateTask: Maybe<PrivilegedTask>;
  templateTaskId: Maybe<Scalars['Int']['output']>;
  timelineStatus: Maybe<TaskStatus>;
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  timelineStatusIsEnded: Maybe<Scalars['Boolean']['output']>;
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  title: Maybe<Scalars['String']['output']>;
  uid: Maybe<Scalars['Int']['output']>;
  virtualProperties: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visitCount: Maybe<Scalars['BigInt']['output']>;
  visitId: Maybe<Scalars['Int']['output']>;
  visitStartDate: Maybe<Scalars['Datetime']['output']>;
};


export type TaskReportAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};


export type TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type TaskReportProjectMembersByAssigneeTaskIdAndProjectMemberIdListArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type TaskReportTaskLabelsByTaskIdArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};


export type TaskReportTaskVisitsByTaskIdArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskReportTaskVisitsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskReportAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<TaskReportArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<TaskReportAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<TaskReportDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<TaskReportMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<TaskReportMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<TaskReportStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<TaskReportStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<TaskReportSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<TaskReportVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<TaskReportVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `TaskReport` object types. */
export type TaskReportAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskReport` objects. */
  arrayAgg: InputMaybe<TaskReportArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskReport` objects. */
  average: InputMaybe<TaskReportAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskReport` objects. */
  distinctCount: InputMaybe<TaskReportDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskReport` object to be included within the aggregate. */
  filter: InputMaybe<TaskReportFilter>;
  /** Maximum aggregate over matching `TaskReport` objects. */
  max: InputMaybe<TaskReportMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskReport` objects. */
  min: InputMaybe<TaskReportMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskReport` objects. */
  stddevPopulation: InputMaybe<TaskReportStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskReport` objects. */
  stddevSample: InputMaybe<TaskReportStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskReport` objects. */
  sum: InputMaybe<TaskReportSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskReport` objects. */
  variancePopulation: InputMaybe<TaskReportVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskReport` objects. */
  varianceSample: InputMaybe<TaskReportVarianceSampleAggregateFilter>;
};

export type TaskReportArrayAggAggregateFilter = {
  address: InputMaybe<StringListFilter>;
  assigneeId: InputMaybe<IntListFilter>;
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  endDateAllDay: InputMaybe<BooleanListFilter>;
  firstLabelId: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  isArchived: InputMaybe<BooleanListFilter>;
  isColored: InputMaybe<BooleanListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  isField: InputMaybe<BooleanListFilter>;
  isTemplate: InputMaybe<BooleanListFilter>;
  jobId: InputMaybe<StringListFilter>;
  labelId: InputMaybe<IntListFilter>;
  nextVisitId: InputMaybe<IntListFilter>;
  position: InputMaybe<IntListFilter>;
  priority: InputMaybe<IntListFilter>;
  projectId: InputMaybe<IntListFilter>;
  projectStageId: InputMaybe<IntListFilter>;
  startDateAllDay: InputMaybe<BooleanListFilter>;
  systemId: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
  templateAssigneePropertyId: InputMaybe<IntListFilter>;
  templateTaskId: InputMaybe<IntListFilter>;
  timelineStatusIsEnded: InputMaybe<BooleanListFilter>;
  uid: InputMaybe<IntListFilter>;
  visitId: InputMaybe<IntListFilter>;
};

export type TaskReportArrayAggAggregates = {
  /** Array aggregation of address across the matching connection */
  address: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of assigneeId across the matching connection */
  assigneeId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of endDateAllDay across the matching connection */
  endDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of firstLabelId across the matching connection */
  firstLabelId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isArchived across the matching connection */
  isArchived: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isColored across the matching connection */
  isColored: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isField across the matching connection */
  isField: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of isTemplate across the matching connection */
  isTemplate: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jobId across the matching connection */
  jobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of labelId across the matching connection */
  labelId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of nextVisitId across the matching connection */
  nextVisitId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of priority across the matching connection */
  priority: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectId across the matching connection */
  projectId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of projectStageId across the matching connection */
  projectStageId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of startDateAllDay across the matching connection */
  startDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of systemId across the matching connection */
  systemId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of templateTaskId across the matching connection */
  templateTaskId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of timelineStatusIsEnded across the matching connection */
  timelineStatusIsEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Point;
  /** Array aggregation of uid across the matching connection */
  uid: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of visitId across the matching connection */
  visitId: Array<Maybe<Scalars['Int']['output']>>;
};


export type TaskReportArrayAggAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesTimelineStatusIsEndedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportArrayAggAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type TaskReportAverageAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  labelId: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigFloatFilter>;
};

export type TaskReportAverageAggregates = {
  /** Mean average of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of labelId across the matching connection */
  labelId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Mean average of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of visitId across the matching connection */
  visitId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskReportAverageAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportAverageAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

/**
 * A condition to be used against `TaskReport` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaskReportCondition = {
  /** Checks for equality with the object’s `address` field. */
  address: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `completionDate` field. */
  completionDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `forms` field. */
  forms: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isColored` field. */
  isColored: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isField` field. */
  isField: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTemplate` field. */
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `labelId` field. */
  labelId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `labelName` field. */
  labelName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `projectStageId` field. */
  projectStageId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** Checks for equality with the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<Scalars['Int']['input']>;
  /** Filter task  timeline where stages ended date is lower or equal with provided value */
  timelineEndedEndDate: InputMaybe<Scalars['String']['input']>;
  /** Filter task timeline where stages ended date is greater or equal with provided value */
  timelineEndedStartDate: InputMaybe<Scalars['String']['input']>;
  /** Filter task timeline where stages started date is lower or equal with provided value */
  timelineStartedEndDate: InputMaybe<Scalars['String']['input']>;
  /** Filter task timeline where stages started date is greater or equal with provided value */
  timelineStartedStartDate: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timelineStatus` field. */
  timelineStatus: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `timelineStatusEndedAt` field. */
  timelineStatusEndedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `timelineStatusIsEnded` field. */
  timelineStatusIsEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `timelineStatusSpentTime` field. */
  timelineStatusSpentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `timelineStatusStartedAt` field. */
  timelineStatusStartedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `uid` field. */
  uid: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Checks for equality with the object’s `visitCount` field. */
  visitCount: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `visitId` field. */
  visitId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `visitStartDate` field. */
  visitStartDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Select tasks with its status timeline. When specified, always limit the timeline result to exactly 1 labelId either by filter/group otherwise there will be duplicated results. If specified, use labelId, labelName property to get the value. */
  withLabels: InputMaybe<Scalars['Boolean']['input']>;
  /** Select tasks with its status timeline. When specified, always limit the timeline result to exactly 1 timeline_status either by filter/group otherwise there will be duplicated results. If specified, use timeline_status, timeline_status_spent_time property to get the value. */
  withStatusTimeline: InputMaybe<Scalars['Boolean']['input']>;
  /** Select tasks with its status timeline. When specified, always limit the timeline result to exactly 1 labelId either by filter/group otherwise there will be duplicated results. If specified, use visitId, visitStartDate, labelName property to get the value. */
  withVisits: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskReportDistinctCountAggregateFilter = {
  address: InputMaybe<BigIntFilter>;
  assigneeId: InputMaybe<BigIntFilter>;
  calculatedStatus: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionDate: InputMaybe<BigIntFilter>;
  completionTime: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  endDate: InputMaybe<BigIntFilter>;
  endDateAllDay: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  forms: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isArchived: InputMaybe<BigIntFilter>;
  isColored: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  isField: InputMaybe<BigIntFilter>;
  isTemplate: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  labelId: InputMaybe<BigIntFilter>;
  labelName: InputMaybe<BigIntFilter>;
  modifiedAt: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  startDate: InputMaybe<BigIntFilter>;
  startDateAllDay: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templatePropertyIds: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  timelineStatus: InputMaybe<BigIntFilter>;
  timelineStatusEndedAt: InputMaybe<BigIntFilter>;
  timelineStatusIsEnded: InputMaybe<BigIntFilter>;
  timelineStatusSpentTime: InputMaybe<BigIntFilter>;
  timelineStatusStartedAt: InputMaybe<BigIntFilter>;
  title: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
  virtualProperties: InputMaybe<BigIntFilter>;
  visitCount: InputMaybe<BigIntFilter>;
  visitId: InputMaybe<BigIntFilter>;
  visitStartDate: InputMaybe<BigIntFilter>;
};

export type TaskReportDistinctCountAggregates = {
  /** Distinct count of address across the matching connection */
  address: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calculatedStatus across the matching connection */
  calculatedStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionDate across the matching connection */
  completionDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTime across the matching connection */
  completionTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDate across the matching connection */
  endDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDateAllDay across the matching connection */
  endDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forms across the matching connection */
  forms: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isArchived across the matching connection */
  isArchived: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isColored across the matching connection */
  isColored: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isField across the matching connection */
  isField: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTemplate across the matching connection */
  isTemplate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of labelId across the matching connection */
  labelId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of labelName across the matching connection */
  labelName: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectId across the matching connection */
  projectId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDate across the matching connection */
  startDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDateAllDay across the matching connection */
  startDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of systemId across the matching connection */
  systemId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templatePropertyIds across the matching connection */
  templatePropertyIds: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatus across the matching connection */
  timelineStatus: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusIsEnded across the matching connection */
  timelineStatusIsEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of uid across the matching connection */
  uid: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of virtualProperties across the matching connection */
  virtualProperties: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of visitId across the matching connection */
  visitId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of visitStartDate across the matching connection */
  visitStartDate: Maybe<Scalars['BigInt']['output']>;
};


export type TaskReportDistinctCountAggregatesAddressArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCalculatedStatusArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCompletionTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesFormsArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIsArchivedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIsColoredArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIsFieldArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesIsTemplateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesLabelNameArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTemplatePropertyIdsArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTimelineStatusArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTimelineStatusIsEndedArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesTitleArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesVirtualPropertiesArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportDistinctCountAggregatesVisitStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A filter to be used against `TaskReport` object types. All fields are combined with a logical ‘and.’ */
export type TaskReportFilter = {
  /** Filter by the object’s `address` field. */
  address: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskReportFilter>>;
  /** Filter by the object’s `assignee` relation. */
  assignee: InputMaybe<MainUserFilter>;
  /** A related `assignee` exists. */
  assigneeExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId: InputMaybe<IntFilter>;
  /** Filter by the object’s `assigneesByTaskId` relation. */
  assigneesByTaskId: InputMaybe<TaskReportToManyAssigneeFilter>;
  /** Some related `assigneesByTaskId` exist. */
  assigneesByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `calculatedStatus` field. */
  calculatedStatus: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `completionDate` field. */
  completionDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `completionTime` field. */
  completionTime: InputMaybe<IntervalFilter>;
  /** Filter by the object’s `completionTimeInS` field. */
  completionTimeInS: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `firstLabel` relation. */
  firstLabel: InputMaybe<LabelFilter>;
  /** A related `firstLabel` exists. */
  firstLabelExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstLabelId` field. */
  firstLabelId: InputMaybe<IntFilter>;
  /** Filter by the object’s `forms` field. */
  forms: InputMaybe<IntListFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isColored` field. */
  isColored: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isField` field. */
  isField: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTemplate` field. */
  isTemplate: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Filter by the object’s `labelId` field. */
  labelId: InputMaybe<IntFilter>;
  /** Filter by the object’s `labelName` field. */
  labelName: InputMaybe<StringFilter>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `nextVisitId` field. */
  nextVisitId: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskReportFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskReportFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `priority` field. */
  priority: InputMaybe<IntFilter>;
  /** Filter by the object’s `project` relation. */
  project: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectId` field. */
  projectId: InputMaybe<IntFilter>;
  /** Filter by the object’s `projectStage` relation. */
  projectStage: InputMaybe<ProjectStageFilter>;
  /** A related `projectStage` exists. */
  projectStageExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectStageId` field. */
  projectStageId: InputMaybe<IntFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `taskLabelsByTaskId` relation. */
  taskLabelsByTaskId: InputMaybe<TaskReportToManyTaskLabelFilter>;
  /** Some related `taskLabelsByTaskId` exist. */
  taskLabelsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskVisitsByTaskId` relation. */
  taskVisitsByTaskId: InputMaybe<TaskReportToManyTaskVisitFilter>;
  /** Some related `taskVisitsByTaskId` exist. */
  taskVisitsByTaskIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateAssigneePropertyId` field. */
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `templatePropertyIds` field. */
  templatePropertyIds: InputMaybe<IntListFilter>;
  /** Filter by the object’s `templateTask` relation. */
  templateTask: InputMaybe<PrivilegedTaskFilter>;
  /** A related `templateTask` exists. */
  templateTaskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `templateTaskId` field. */
  templateTaskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `timelineStatus` field. */
  timelineStatus: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `timelineStatusEndedAt` field. */
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `timelineStatusIsEnded` field. */
  timelineStatusIsEnded: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `timelineStatusSpentTime` field. */
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `timelineStatusStartedAt` field. */
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `title` field. */
  title: InputMaybe<StringFilter>;
  /** Filter by the object’s `uid` field. */
  uid: InputMaybe<IntFilter>;
  /** Filter by the object’s `virtualProperties` field. */
  virtualProperties: InputMaybe<StringListFilter>;
  /** Filter by the object’s `visitCount` field. */
  visitCount: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `visitId` field. */
  visitId: InputMaybe<IntFilter>;
  /** Filter by the object’s `visitStartDate` field. */
  visitStartDate: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `TaskReport` for usage during aggregation. */
export enum TaskReportGroupBy {
  Address = 'ADDRESS',
  AssigneeId = 'ASSIGNEE_ID',
  CalculatedStatus = 'CALCULATED_STATUS',
  CompanyId = 'COMPANY_ID',
  CompletionDate = 'COMPLETION_DATE',
  CompletionDateTruncatedToDay = 'COMPLETION_DATE_TRUNCATED_TO_DAY',
  CompletionDateTruncatedToHour = 'COMPLETION_DATE_TRUNCATED_TO_HOUR',
  CompletionDateTruncatedToMonth = 'COMPLETION_DATE_TRUNCATED_TO_MONTH',
  CompletionDateTruncatedToQuarter = 'COMPLETION_DATE_TRUNCATED_TO_QUARTER',
  CompletionDateTruncatedToWeek = 'COMPLETION_DATE_TRUNCATED_TO_WEEK',
  CompletionDateTruncatedToYear = 'COMPLETION_DATE_TRUNCATED_TO_YEAR',
  CompletionTime = 'COMPLETION_TIME',
  CompletionTimeInS = 'COMPLETION_TIME_IN_S',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  EndDate = 'END_DATE',
  EndDateAllDay = 'END_DATE_ALL_DAY',
  EndDateTruncatedToDay = 'END_DATE_TRUNCATED_TO_DAY',
  EndDateTruncatedToHour = 'END_DATE_TRUNCATED_TO_HOUR',
  EndDateTruncatedToMonth = 'END_DATE_TRUNCATED_TO_MONTH',
  EndDateTruncatedToQuarter = 'END_DATE_TRUNCATED_TO_QUARTER',
  EndDateTruncatedToWeek = 'END_DATE_TRUNCATED_TO_WEEK',
  EndDateTruncatedToYear = 'END_DATE_TRUNCATED_TO_YEAR',
  FirstLabelId = 'FIRST_LABEL_ID',
  Forms = 'FORMS',
  Id = 'ID',
  IsArchived = 'IS_ARCHIVED',
  IsColored = 'IS_COLORED',
  IsCompleted = 'IS_COMPLETED',
  IsField = 'IS_FIELD',
  IsTemplate = 'IS_TEMPLATE',
  JobId = 'JOB_ID',
  LabelId = 'LABEL_ID',
  LabelName = 'LABEL_NAME',
  ModifiedAt = 'MODIFIED_AT',
  ModifiedAtTruncatedToDay = 'MODIFIED_AT_TRUNCATED_TO_DAY',
  ModifiedAtTruncatedToHour = 'MODIFIED_AT_TRUNCATED_TO_HOUR',
  ModifiedAtTruncatedToMonth = 'MODIFIED_AT_TRUNCATED_TO_MONTH',
  ModifiedAtTruncatedToQuarter = 'MODIFIED_AT_TRUNCATED_TO_QUARTER',
  ModifiedAtTruncatedToWeek = 'MODIFIED_AT_TRUNCATED_TO_WEEK',
  ModifiedAtTruncatedToYear = 'MODIFIED_AT_TRUNCATED_TO_YEAR',
  NextVisitId = 'NEXT_VISIT_ID',
  Position = 'POSITION',
  Priority = 'PRIORITY',
  ProjectId = 'PROJECT_ID',
  ProjectStageId = 'PROJECT_STAGE_ID',
  StartDate = 'START_DATE',
  StartDateAllDay = 'START_DATE_ALL_DAY',
  StartDateTruncatedToDay = 'START_DATE_TRUNCATED_TO_DAY',
  StartDateTruncatedToHour = 'START_DATE_TRUNCATED_TO_HOUR',
  StartDateTruncatedToMonth = 'START_DATE_TRUNCATED_TO_MONTH',
  StartDateTruncatedToQuarter = 'START_DATE_TRUNCATED_TO_QUARTER',
  StartDateTruncatedToWeek = 'START_DATE_TRUNCATED_TO_WEEK',
  StartDateTruncatedToYear = 'START_DATE_TRUNCATED_TO_YEAR',
  Status = 'STATUS',
  SystemId = 'SYSTEM_ID',
  TaskId = 'TASK_ID',
  TemplateAssigneePropertyId = 'TEMPLATE_ASSIGNEE_PROPERTY_ID',
  TemplatePropertyIds = 'TEMPLATE_PROPERTY_IDS',
  TemplateTaskId = 'TEMPLATE_TASK_ID',
  TimelineStatus = 'TIMELINE_STATUS',
  TimelineStatusEndedAt = 'TIMELINE_STATUS_ENDED_AT',
  TimelineStatusEndedAtTruncatedToDay = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_DAY',
  TimelineStatusEndedAtTruncatedToHour = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_HOUR',
  TimelineStatusEndedAtTruncatedToMonth = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_MONTH',
  TimelineStatusEndedAtTruncatedToQuarter = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_QUARTER',
  TimelineStatusEndedAtTruncatedToWeek = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_WEEK',
  TimelineStatusEndedAtTruncatedToYear = 'TIMELINE_STATUS_ENDED_AT_TRUNCATED_TO_YEAR',
  TimelineStatusIsEnded = 'TIMELINE_STATUS_IS_ENDED',
  TimelineStatusSpentTime = 'TIMELINE_STATUS_SPENT_TIME',
  TimelineStatusStartedAt = 'TIMELINE_STATUS_STARTED_AT',
  TimelineStatusStartedAtTruncatedToDay = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_DAY',
  TimelineStatusStartedAtTruncatedToHour = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_HOUR',
  TimelineStatusStartedAtTruncatedToMonth = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_MONTH',
  TimelineStatusStartedAtTruncatedToQuarter = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_QUARTER',
  TimelineStatusStartedAtTruncatedToWeek = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_WEEK',
  TimelineStatusStartedAtTruncatedToYear = 'TIMELINE_STATUS_STARTED_AT_TRUNCATED_TO_YEAR',
  Title = 'TITLE',
  Uid = 'UID',
  VirtualProperties = 'VIRTUAL_PROPERTIES',
  VisitCount = 'VISIT_COUNT',
  VisitId = 'VISIT_ID',
  VisitStartDate = 'VISIT_START_DATE',
  VisitStartDateTruncatedToDay = 'VISIT_START_DATE_TRUNCATED_TO_DAY',
  VisitStartDateTruncatedToHour = 'VISIT_START_DATE_TRUNCATED_TO_HOUR',
  VisitStartDateTruncatedToMonth = 'VISIT_START_DATE_TRUNCATED_TO_MONTH',
  VisitStartDateTruncatedToQuarter = 'VISIT_START_DATE_TRUNCATED_TO_QUARTER',
  VisitStartDateTruncatedToWeek = 'VISIT_START_DATE_TRUNCATED_TO_WEEK',
  VisitStartDateTruncatedToYear = 'VISIT_START_DATE_TRUNCATED_TO_YEAR'
}

export type TaskReportHavingArrayAggInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingAverageInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingDistinctCountInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `TaskReport` aggregates. */
export type TaskReportHavingInput = {
  AND: InputMaybe<Array<TaskReportHavingInput>>;
  OR: InputMaybe<Array<TaskReportHavingInput>>;
  arrayAgg: InputMaybe<TaskReportHavingArrayAggInput>;
  average: InputMaybe<TaskReportHavingAverageInput>;
  distinctCount: InputMaybe<TaskReportHavingDistinctCountInput>;
  max: InputMaybe<TaskReportHavingMaxInput>;
  min: InputMaybe<TaskReportHavingMinInput>;
  stddevPopulation: InputMaybe<TaskReportHavingStddevPopulationInput>;
  stddevSample: InputMaybe<TaskReportHavingStddevSampleInput>;
  sum: InputMaybe<TaskReportHavingSumInput>;
  variancePopulation: InputMaybe<TaskReportHavingVariancePopulationInput>;
  varianceSample: InputMaybe<TaskReportHavingVarianceSampleInput>;
};

export type TaskReportHavingMaxInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingMinInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingStddevPopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingStddevSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingSumInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingVariancePopulationInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportHavingVarianceSampleInput = {
  assigneeId: InputMaybe<HavingIntFilter>;
  companyId: InputMaybe<HavingIntFilter>;
  completionDate: InputMaybe<HavingDatetimeFilter>;
  completionTimeInS: InputMaybe<HavingBigintFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  firstLabelId: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  labelId: InputMaybe<HavingIntFilter>;
  modifiedAt: InputMaybe<HavingDatetimeFilter>;
  nextVisitId: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  priority: InputMaybe<HavingIntFilter>;
  projectId: InputMaybe<HavingIntFilter>;
  projectStageId: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  systemId: InputMaybe<HavingIntFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  templateAssigneePropertyId: InputMaybe<HavingIntFilter>;
  templateTaskId: InputMaybe<HavingIntFilter>;
  timelineStatusEndedAt: InputMaybe<HavingDatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<HavingFloatFilter>;
  timelineStatusStartedAt: InputMaybe<HavingDatetimeFilter>;
  uid: InputMaybe<HavingIntFilter>;
  visitCount: InputMaybe<HavingBigintFilter>;
  visitId: InputMaybe<HavingIntFilter>;
  visitStartDate: InputMaybe<HavingDatetimeFilter>;
};

export type TaskReportMaxAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  labelId: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  uid: InputMaybe<IntFilter>;
  visitCount: InputMaybe<BigIntFilter>;
  visitId: InputMaybe<IntFilter>;
  visitStartDate: InputMaybe<DatetimeFilter>;
};

export type TaskReportMaxAggregates = {
  /** Maximum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of labelId across the matching connection */
  labelId: Maybe<Scalars['Int']['output']>;
  /** Maximum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Maximum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Maximum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Maximum of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of visitId across the matching connection */
  visitId: Maybe<Scalars['Int']['output']>;
  /** Maximum of visitStartDate across the matching connection */
  visitStartDate: Maybe<Scalars['Datetime']['output']>;
};


export type TaskReportMaxAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMaxAggregatesVisitStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type TaskReportMinAggregateFilter = {
  assigneeId: InputMaybe<IntFilter>;
  companyId: InputMaybe<IntFilter>;
  completionDate: InputMaybe<DatetimeFilter>;
  completionTimeInS: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  firstLabelId: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  labelId: InputMaybe<IntFilter>;
  modifiedAt: InputMaybe<DatetimeFilter>;
  nextVisitId: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  priority: InputMaybe<IntFilter>;
  projectId: InputMaybe<IntFilter>;
  projectStageId: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  systemId: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
  templateAssigneePropertyId: InputMaybe<IntFilter>;
  templateTaskId: InputMaybe<IntFilter>;
  timelineStatusEndedAt: InputMaybe<DatetimeFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  timelineStatusStartedAt: InputMaybe<DatetimeFilter>;
  uid: InputMaybe<IntFilter>;
  visitCount: InputMaybe<BigIntFilter>;
  visitId: InputMaybe<IntFilter>;
  visitStartDate: InputMaybe<DatetimeFilter>;
};

export type TaskReportMinAggregates = {
  /** Minimum of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['Int']['output']>;
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of completionDate across the matching connection */
  completionDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of labelId across the matching connection */
  labelId: Maybe<Scalars['Int']['output']>;
  /** Minimum of modifiedAt across the matching connection */
  modifiedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of priority across the matching connection */
  priority: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectId across the matching connection */
  projectId: Maybe<Scalars['Int']['output']>;
  /** Minimum of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['Int']['output']>;
  /** Minimum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of systemId across the matching connection */
  systemId: Maybe<Scalars['Int']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of timelineStatusEndedAt across the matching connection */
  timelineStatusEndedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of timelineStatusStartedAt across the matching connection */
  timelineStatusStartedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of uid across the matching connection */
  uid: Maybe<Scalars['Int']['output']>;
  /** Minimum of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of visitId across the matching connection */
  visitId: Maybe<Scalars['Int']['output']>;
  /** Minimum of visitStartDate across the matching connection */
  visitStartDate: Maybe<Scalars['Datetime']['output']>;
};


export type TaskReportMinAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesCompletionDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesModifiedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTimelineStatusEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesTimelineStatusStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportMinAggregatesVisitStartDateArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Assignee`. */
export type TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Assignee`, and the cursor to aid in pagination. */
  edges: Array<TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Assignee`. */
export type TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Assignee`. */
export type TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Assignee`. */
  assigneesByTaskId: Array<Assignee>;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Assignee`. */
export type TaskReportPrivilegedTasksByAssigneeTaskIdAndTaskIdManyToManyEdgeAssigneesByTaskIdArgs = {
  condition: InputMaybe<AssigneeCondition>;
  filter: InputMaybe<AssigneeFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<AssigneesOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskLabel`. */
export type TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskLabel`, and the cursor to aid in pagination. */
  edges: Array<TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskLabel`. */
export type TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskLabel`. */
export type TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskLabel`. */
  privilegedTaskLabels: Array<TaskLabel>;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskLabel`. */
export type TaskReportPrivilegedTasksByTaskLabelTaskIdAndTaskIdManyToManyEdgePrivilegedTaskLabelsArgs = {
  condition: InputMaybe<TaskLabelCondition>;
  filter: InputMaybe<TaskLabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskLabelsOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `TaskVisit`. */
export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `TaskVisit`, and the cursor to aid in pagination. */
  edges: Array<TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `TaskVisit`. */
export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `TaskVisit`. */
export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskId: Array<TaskVisit>;
  /** Reads and enables pagination through a set of `TaskVisit`. */
  taskVisitsByTaskIdConnection: TaskVisitsConnection;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskVisit`. */
export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyEdgeTaskVisitsByTaskIdArgs = {
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


/** A `PrivilegedTask` edge in the connection, with data from `TaskVisit`. */
export type TaskReportPrivilegedTasksByTaskVisitTaskIdAndTaskIdManyToManyEdgeTaskVisitsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskVisitCondition>;
  filter: InputMaybe<TaskVisitFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskReportStddevPopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  labelId: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigFloatFilter>;
};

export type TaskReportStddevPopulationAggregates = {
  /** Population standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of labelId across the matching connection */
  labelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of visitId across the matching connection */
  visitId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskReportStddevPopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevPopulationAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type TaskReportStddevSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  labelId: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigFloatFilter>;
};

export type TaskReportStddevSampleAggregates = {
  /** Sample standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of labelId across the matching connection */
  labelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of visitId across the matching connection */
  visitId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskReportStddevSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportStddevSampleAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type TaskReportSumAggregateFilter = {
  assigneeId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  labelId: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigIntFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigIntFilter>;
};

export type TaskReportSumAggregates = {
  /** Sum of assigneeId across the matching connection */
  assigneeId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of completionTimeInS across the matching connection */
  completionTimeInS: Scalars['BigFloat']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of firstLabelId across the matching connection */
  firstLabelId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of labelId across the matching connection */
  labelId: Scalars['BigInt']['output'];
  /** Sum of nextVisitId across the matching connection */
  nextVisitId: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
  /** Sum of priority across the matching connection */
  priority: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of projectStageId across the matching connection */
  projectStageId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
  /** Sum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Scalars['BigInt']['output'];
  /** Sum of templateTaskId across the matching connection */
  templateTaskId: Scalars['BigInt']['output'];
  /** Sum of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Scalars['Float']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
  /** Sum of visitCount across the matching connection */
  visitCount: Scalars['BigFloat']['output'];
  /** Sum of visitId across the matching connection */
  visitId: Scalars['BigInt']['output'];
};


export type TaskReportSumAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportSumAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A filter to be used against many `Assignee` object types. All fields are combined with a logical ‘and.’ */
export type TaskReportToManyAssigneeFilter = {
  /** Aggregates across related `Assignee` match the filter criteria. */
  aggregates: InputMaybe<AssigneeAggregatesFilter>;
  /** Every related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<AssigneeFilter>;
  /** No related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<AssigneeFilter>;
  /** Some related `Assignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<AssigneeFilter>;
};

/** A filter to be used against many `TaskLabel` object types. All fields are combined with a logical ‘and.’ */
export type TaskReportToManyTaskLabelFilter = {
  /** Aggregates across related `TaskLabel` match the filter criteria. */
  aggregates: InputMaybe<TaskLabelAggregatesFilter>;
  /** Every related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskLabelFilter>;
  /** No related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskLabelFilter>;
  /** Some related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskLabelFilter>;
};

/** A filter to be used against many `TaskVisit` object types. All fields are combined with a logical ‘and.’ */
export type TaskReportToManyTaskVisitFilter = {
  /** Aggregates across related `TaskVisit` match the filter criteria. */
  aggregates: InputMaybe<TaskVisitAggregatesFilter>;
  /** Every related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskVisitFilter>;
  /** No related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskVisitFilter>;
  /** Some related `TaskVisit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskVisitFilter>;
};

export type TaskReportVariancePopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  labelId: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigFloatFilter>;
};

export type TaskReportVariancePopulationAggregates = {
  /** Population variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of labelId across the matching connection */
  labelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of visitId across the matching connection */
  visitId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskReportVariancePopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVariancePopulationAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

export type TaskReportVarianceSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  labelId: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  timelineStatusSpentTime: InputMaybe<FloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
  visitCount: InputMaybe<BigFloatFilter>;
  visitId: InputMaybe<BigFloatFilter>;
};

export type TaskReportVarianceSampleAggregates = {
  /** Sample variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of labelId across the matching connection */
  labelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of timelineStatusSpentTime across the matching connection */
  timelineStatusSpentTime: Maybe<Scalars['Float']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of visitCount across the matching connection */
  visitCount: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of visitId across the matching connection */
  visitId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskReportVarianceSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesLabelIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesTimelineStatusSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesVisitCountArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};


export type TaskReportVarianceSampleAggregatesVisitIdArgs = {
  orderBy: InputMaybe<Array<TaskReportsOrderBy>>;
};

/** A connection to a list of `TaskReport` values. */
export type TaskReportsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskReportAggregates>;
  /** A list of edges which contains the `TaskReport` and cursor to aid in pagination. */
  edges: Array<TaskReportsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskReportAggregates>>;
  /** A list of `TaskReport` objects. */
  nodes: Array<Maybe<TaskReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskReport` values. */
export type TaskReportsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskReportGroupBy>;
  having: InputMaybe<TaskReportHavingInput>;
};

/** A `TaskReport` edge in the connection. */
export type TaskReportsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskReport` at the end of the edge. */
  node: Maybe<TaskReport>;
};

/** Methods to use when ordering `TaskReport`. */
export enum TaskReportsOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AssigneesByTaskIdConnectionArrayAggProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionArrayAggTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  AssigneesByTaskIdConnectionArrayAggUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  AssigneesByTaskIdConnectionArrayAggUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  AssigneesByTaskIdConnectionAverageProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionAverageProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionAverageTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionAverageTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionAverageUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  AssigneesByTaskIdConnectionAverageUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  AssigneesByTaskIdConnectionCountAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_COUNT_ASC',
  AssigneesByTaskIdConnectionCountDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_COUNT_DESC',
  AssigneesByTaskIdConnectionDistinctCountProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionDistinctCountTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  AssigneesByTaskIdConnectionDistinctCountUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  AssigneesByTaskIdConnectionDistinctCountUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  AssigneesByTaskIdConnectionMaxProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionMaxProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionMaxTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  AssigneesByTaskIdConnectionMaxTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  AssigneesByTaskIdConnectionMaxUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_USER_ID_ASC',
  AssigneesByTaskIdConnectionMaxUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MAX_USER_ID_DESC',
  AssigneesByTaskIdConnectionMinProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionMinProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionMinTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  AssigneesByTaskIdConnectionMinTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  AssigneesByTaskIdConnectionMinUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_USER_ID_ASC',
  AssigneesByTaskIdConnectionMinUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_MIN_USER_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  AssigneesByTaskIdConnectionStddevPopulationUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  AssigneesByTaskIdConnectionStddevPopulationUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionStddevSampleUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  AssigneesByTaskIdConnectionStddevSampleUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  AssigneesByTaskIdConnectionSumProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionSumProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionSumTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  AssigneesByTaskIdConnectionSumTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  AssigneesByTaskIdConnectionSumUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_USER_ID_ASC',
  AssigneesByTaskIdConnectionSumUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_SUM_USER_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  AssigneesByTaskIdConnectionVariancePopulationUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  AssigneesByTaskIdConnectionVariancePopulationUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleProjectMemberIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleProjectMemberIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MEMBER_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleTaskIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleTaskIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  AssigneesByTaskIdConnectionVarianceSampleUserIdAsc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  AssigneesByTaskIdConnectionVarianceSampleUserIdDesc = 'ASSIGNEES_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  AssigneeIdAsc = 'ASSIGNEE_ID_ASC',
  AssigneeIdDesc = 'ASSIGNEE_ID_DESC',
  CalculatedStatusAsc = 'CALCULATED_STATUS_ASC',
  CalculatedStatusDesc = 'CALCULATED_STATUS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletionDateAsc = 'COMPLETION_DATE_ASC',
  CompletionDateDesc = 'COMPLETION_DATE_DESC',
  CompletionTimeAsc = 'COMPLETION_TIME_ASC',
  CompletionTimeDesc = 'COMPLETION_TIME_DESC',
  CompletionTimeInSAsc = 'COMPLETION_TIME_IN_S_ASC',
  CompletionTimeInSDesc = 'COMPLETION_TIME_IN_S_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndDateAllDayAsc = 'END_DATE_ALL_DAY_ASC',
  EndDateAllDayDesc = 'END_DATE_ALL_DAY_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  FirstLabelIdAsc = 'FIRST_LABEL_ID_ASC',
  FirstLabelIdDesc = 'FIRST_LABEL_ID_DESC',
  FormsAsc = 'FORMS_ASC',
  FormsDesc = 'FORMS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsColoredAsc = 'IS_COLORED_ASC',
  IsColoredDesc = 'IS_COLORED_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsFieldAsc = 'IS_FIELD_ASC',
  IsFieldDesc = 'IS_FIELD_DESC',
  IsTemplateAsc = 'IS_TEMPLATE_ASC',
  IsTemplateDesc = 'IS_TEMPLATE_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  LabelIdAsc = 'LABEL_ID_ASC',
  LabelIdDesc = 'LABEL_ID_DESC',
  LabelNameAsc = 'LABEL_NAME_ASC',
  LabelNameDesc = 'LABEL_NAME_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  Natural = 'NATURAL',
  NextVisitIdAsc = 'NEXT_VISIT_ID_ASC',
  NextVisitIdDesc = 'NEXT_VISIT_ID_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectStageIdAsc = 'PROJECT_STAGE_ID_ASC',
  ProjectStageIdDesc = 'PROJECT_STAGE_ID_DESC',
  StartDateAllDayAsc = 'START_DATE_ALL_DAY_ASC',
  StartDateAllDayDesc = 'START_DATE_ALL_DAY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionArrayAggLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionArrayAggLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionAverageLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionAverageLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionAverageTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionAverageTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionCountAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskLabelsByTaskIdConnectionCountDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskLabelsByTaskIdConnectionDistinctCountLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionDistinctCountLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionMaxLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MAX_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionMaxLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MAX_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionMaxTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionMaxTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionMinLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MIN_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionMinLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MIN_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionMinTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionMinTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionStddevPopulationLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionStddevPopulationLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionStddevSampleLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionStddevSampleLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionSumLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_SUM_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionSumLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_SUM_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionSumTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionSumTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionVariancePopulationLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionVariancePopulationLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskLabelsByTaskIdConnectionVarianceSampleLabelIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskLabelsByTaskIdConnectionVarianceSampleLabelIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskLabelsByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskLabelsByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_LABELS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionArrayAggDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionArrayAggEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionArrayAggIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionArrayAggIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionArrayAggJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TaskVisitsByTaskIdConnectionArrayAggPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionArrayAggStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionArrayAggTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionArrayAggTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionArrayAggUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionArrayAggUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionAverageCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionAverageCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionAverageDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionAverageDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionAverageEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionAverageEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionAverageEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionAverageEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionAverageIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_ID_DESC',
  TaskVisitsByTaskIdConnectionAverageIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionAverageIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionAverageJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionAveragePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionAveragePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionAverageStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionAverageStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionAverageStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionAverageStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionAverageTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionAverageTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionAverageUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionAverageUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionCountAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_COUNT_ASC',
  TaskVisitsByTaskIdConnectionCountDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_COUNT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionDistinctCountUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionDistinctCountUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMaxCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionMaxCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionMaxDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionMaxDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionMaxEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMaxEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMaxEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionMaxEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionMaxIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionMaxIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionMaxJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_POSITION_ASC',
  TaskVisitsByTaskIdConnectionMaxPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_POSITION_DESC',
  TaskVisitsByTaskIdConnectionMaxStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMaxStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMaxStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionMaxStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionMaxTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionMaxTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionMaxUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMaxUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionMinCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionMinCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionMinDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionMinDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionMinEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMinEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMinEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionMinEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionMinIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_ID_ASC',
  TaskVisitsByTaskIdConnectionMinIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_ID_DESC',
  TaskVisitsByTaskIdConnectionMinIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionMinIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionMinJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionMinJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionMinPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_POSITION_ASC',
  TaskVisitsByTaskIdConnectionMinPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_POSITION_DESC',
  TaskVisitsByTaskIdConnectionMinStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionMinStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionMinStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionMinStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionMinTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionMinTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionMinUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionMinUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevPopulationUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevPopulationUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSamplePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionStddevSamplePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionStddevSampleUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionStddevSampleUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionSumCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionSumCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionSumDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionSumDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionSumEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionSumEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionSumEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionSumEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionSumIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_ID_ASC',
  TaskVisitsByTaskIdConnectionSumIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_ID_DESC',
  TaskVisitsByTaskIdConnectionSumIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionSumIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionSumJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionSumJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionSumPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_POSITION_ASC',
  TaskVisitsByTaskIdConnectionSumPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_POSITION_DESC',
  TaskVisitsByTaskIdConnectionSumStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionSumStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionSumStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionSumStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionSumTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionSumTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionSumUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionSumUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationPositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationPositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionVariancePopulationUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVariancePopulationUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCompletedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCompletedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedByAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleCreatedByDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleDescriptionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleDescriptionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleEndDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleIsCompletedAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleIsCompletedDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleJobIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleJobIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSamplePositionAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TaskVisitsByTaskIdConnectionVarianceSamplePositionDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAllDayAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAllDayDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleStartDateDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleTaskIdAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleTaskIdDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskVisitsByTaskIdConnectionVarianceSampleUpdatedAtAsc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TaskVisitsByTaskIdConnectionVarianceSampleUpdatedAtDesc = 'TASK_VISITS_BY_TASK_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TemplateAssigneePropertyIdAsc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TemplateAssigneePropertyIdDesc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TemplatePropertyIdsAsc = 'TEMPLATE_PROPERTY_IDS_ASC',
  TemplatePropertyIdsDesc = 'TEMPLATE_PROPERTY_IDS_DESC',
  TemplateTaskIdAsc = 'TEMPLATE_TASK_ID_ASC',
  TemplateTaskIdDesc = 'TEMPLATE_TASK_ID_DESC',
  TimelineStatusAsc = 'TIMELINE_STATUS_ASC',
  TimelineStatusDesc = 'TIMELINE_STATUS_DESC',
  TimelineStatusEndedAtAsc = 'TIMELINE_STATUS_ENDED_AT_ASC',
  TimelineStatusEndedAtDesc = 'TIMELINE_STATUS_ENDED_AT_DESC',
  TimelineStatusIsEndedAsc = 'TIMELINE_STATUS_IS_ENDED_ASC',
  TimelineStatusIsEndedDesc = 'TIMELINE_STATUS_IS_ENDED_DESC',
  TimelineStatusSpentTimeAsc = 'TIMELINE_STATUS_SPENT_TIME_ASC',
  TimelineStatusSpentTimeDesc = 'TIMELINE_STATUS_SPENT_TIME_DESC',
  TimelineStatusStartedAtAsc = 'TIMELINE_STATUS_STARTED_AT_ASC',
  TimelineStatusStartedAtDesc = 'TIMELINE_STATUS_STARTED_AT_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  VirtualPropertiesAsc = 'VIRTUAL_PROPERTIES_ASC',
  VirtualPropertiesDesc = 'VIRTUAL_PROPERTIES_DESC',
  VisitCountAsc = 'VISIT_COUNT_ASC',
  VisitCountDesc = 'VISIT_COUNT_DESC',
  VisitIdAsc = 'VISIT_ID_ASC',
  VisitIdDesc = 'VISIT_ID_DESC',
  VisitStartDateAsc = 'VISIT_START_DATE_ASC',
  VisitStartDateDesc = 'VISIT_START_DATE_DESC'
}

export enum TaskStatus {
  Assigned = 'ASSIGNED',
  Cancelled = 'CANCELLED',
  ChangesRequested = 'CHANGES_REQUESTED',
  ClockedIn = 'CLOCKED_IN',
  ClockedOut = 'CLOCKED_OUT',
  Completed = 'COMPLETED',
  Dispatched = 'DISPATCHED',
  OnTheWay = 'ON_THE_WAY',
  Queued = 'QUEUED',
  Review = 'REVIEW',
  Scheduled = 'SCHEDULED',
  Waiting = 'WAITING',
  Working = 'WORKING'
}

export type TaskStatusEntity = {
  calculatedStatus: Maybe<TaskStatus>;
  fieldOnly: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<TaskStatus>;
  isAutomatic: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  officeOnly: Maybe<Scalars['Boolean']['output']>;
  possibleTransitions: Maybe<Array<Maybe<TaskStatusEntity>>>;
};

/** A filter to be used against TaskStatus fields. All fields are combined with a logical ‘and.’ */
export type TaskStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<TaskStatus>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<TaskStatus>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<TaskStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<TaskStatus>;
  /** Included in the specified list. */
  in: InputMaybe<Array<TaskStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<TaskStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<TaskStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<TaskStatus>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<TaskStatus>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<TaskStatus>>;
};

export type TaskStatusTimeline = {
  count: Maybe<Scalars['BigInt']['output']>;
  endedAt: Maybe<Scalars['Datetime']['output']>;
  isEnded: Maybe<Scalars['Boolean']['output']>;
  spentTime: Maybe<Scalars['Float']['output']>;
  startedAt: Maybe<Scalars['Datetime']['output']>;
  status: Maybe<TaskStatus>;
  /** Reads a single `PrivilegedTask` that is related to this `TaskStatusTimeline`. */
  task: Maybe<PrivilegedTask>;
  taskId: Maybe<Scalars['Int']['output']>;
  taskStatus: Maybe<TaskStatusEntity>;
};

export type TaskStatusTimelineAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<TaskStatusTimelineArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<TaskStatusTimelineAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<TaskStatusTimelineDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<TaskStatusTimelineMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<TaskStatusTimelineMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<TaskStatusTimelineStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<TaskStatusTimelineStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<TaskStatusTimelineSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<TaskStatusTimelineVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<TaskStatusTimelineVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `TaskStatusTimeline` object types. */
export type TaskStatusTimelineAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskStatusTimeline` objects. */
  arrayAgg: InputMaybe<TaskStatusTimelineArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskStatusTimeline` objects. */
  average: InputMaybe<TaskStatusTimelineAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskStatusTimeline` objects. */
  distinctCount: InputMaybe<TaskStatusTimelineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskStatusTimeline` object to be included within the aggregate. */
  filter: InputMaybe<TaskStatusTimelineFilter>;
  /** Maximum aggregate over matching `TaskStatusTimeline` objects. */
  max: InputMaybe<TaskStatusTimelineMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskStatusTimeline` objects. */
  min: InputMaybe<TaskStatusTimelineMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskStatusTimeline` objects. */
  stddevPopulation: InputMaybe<TaskStatusTimelineStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskStatusTimeline` objects. */
  stddevSample: InputMaybe<TaskStatusTimelineStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskStatusTimeline` objects. */
  sum: InputMaybe<TaskStatusTimelineSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskStatusTimeline` objects. */
  variancePopulation: InputMaybe<TaskStatusTimelineVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskStatusTimeline` objects. */
  varianceSample: InputMaybe<TaskStatusTimelineVarianceSampleAggregateFilter>;
};

export type TaskStatusTimelineArrayAggAggregateFilter = {
  isEnded: InputMaybe<BooleanListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type TaskStatusTimelineArrayAggAggregates = {
  /** Array aggregation of isEnded across the matching connection */
  isEnded: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of spentTime across the matching connection */
  spentTime: Point;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
};


export type TaskStatusTimelineArrayAggAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineArrayAggAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineAverageAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusTimelineAverageAggregates = {
  /** Mean average of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStatusTimelineAverageAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineAverageAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

/**
 * A condition to be used against `TaskStatusTimeline` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskStatusTimelineCondition = {
  /** Checks for equality with the object’s `count` field. */
  count: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `endedAt` field. */
  endedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `isEnded` field. */
  isEnded: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `spentTime` field. */
  spentTime: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
};

export type TaskStatusTimelineDistinctCountAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<BigIntFilter>;
  isEnded: InputMaybe<BigIntFilter>;
  spentTime: InputMaybe<BigIntFilter>;
  startedAt: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type TaskStatusTimelineDistinctCountAggregates = {
  /** Distinct count of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endedAt across the matching connection */
  endedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isEnded across the matching connection */
  isEnded: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of spentTime across the matching connection */
  spentTime: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
};


export type TaskStatusTimelineDistinctCountAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesIsEndedArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesStatusArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

/** A filter to be used against `TaskStatusTimeline` object types. All fields are combined with a logical ‘and.’ */
export type TaskStatusTimelineFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskStatusTimelineFilter>>;
  /** Filter by the object’s `count` field. */
  count: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `endedAt` field. */
  endedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `isEnded` field. */
  isEnded: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskStatusTimelineFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskStatusTimelineFilter>>;
  /** Filter by the object’s `spentTime` field. */
  spentTime: InputMaybe<FloatFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
};

/** Grouping methods for `TaskStatusTimeline` for usage during aggregation. */
export enum TaskStatusTimelineGroupBy {
  Count = 'COUNT',
  EndedAt = 'ENDED_AT',
  EndedAtTruncatedToDay = 'ENDED_AT_TRUNCATED_TO_DAY',
  EndedAtTruncatedToHour = 'ENDED_AT_TRUNCATED_TO_HOUR',
  EndedAtTruncatedToMonth = 'ENDED_AT_TRUNCATED_TO_MONTH',
  EndedAtTruncatedToQuarter = 'ENDED_AT_TRUNCATED_TO_QUARTER',
  EndedAtTruncatedToWeek = 'ENDED_AT_TRUNCATED_TO_WEEK',
  EndedAtTruncatedToYear = 'ENDED_AT_TRUNCATED_TO_YEAR',
  IsEnded = 'IS_ENDED',
  SpentTime = 'SPENT_TIME',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  StartedAtTruncatedToMonth = 'STARTED_AT_TRUNCATED_TO_MONTH',
  StartedAtTruncatedToQuarter = 'STARTED_AT_TRUNCATED_TO_QUARTER',
  StartedAtTruncatedToWeek = 'STARTED_AT_TRUNCATED_TO_WEEK',
  StartedAtTruncatedToYear = 'STARTED_AT_TRUNCATED_TO_YEAR',
  Status = 'STATUS',
  TaskId = 'TASK_ID'
}

export type TaskStatusTimelineHavingArrayAggInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingAverageInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingDistinctCountInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `TaskStatusTimeline` aggregates. */
export type TaskStatusTimelineHavingInput = {
  AND: InputMaybe<Array<TaskStatusTimelineHavingInput>>;
  OR: InputMaybe<Array<TaskStatusTimelineHavingInput>>;
  arrayAgg: InputMaybe<TaskStatusTimelineHavingArrayAggInput>;
  average: InputMaybe<TaskStatusTimelineHavingAverageInput>;
  distinctCount: InputMaybe<TaskStatusTimelineHavingDistinctCountInput>;
  max: InputMaybe<TaskStatusTimelineHavingMaxInput>;
  min: InputMaybe<TaskStatusTimelineHavingMinInput>;
  stddevPopulation: InputMaybe<TaskStatusTimelineHavingStddevPopulationInput>;
  stddevSample: InputMaybe<TaskStatusTimelineHavingStddevSampleInput>;
  sum: InputMaybe<TaskStatusTimelineHavingSumInput>;
  variancePopulation: InputMaybe<TaskStatusTimelineHavingVariancePopulationInput>;
  varianceSample: InputMaybe<TaskStatusTimelineHavingVarianceSampleInput>;
};

export type TaskStatusTimelineHavingMaxInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingMinInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingStddevPopulationInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingStddevSampleInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingSumInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingVariancePopulationInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineHavingVarianceSampleInput = {
  count: InputMaybe<HavingBigintFilter>;
  endedAt: InputMaybe<HavingDatetimeFilter>;
  spentTime: InputMaybe<HavingFloatFilter>;
  startedAt: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
};

export type TaskStatusTimelineMaxAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  spentTime: InputMaybe<FloatFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskStatusTimelineMaxAggregates = {
  /** Maximum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Maximum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Maximum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
};


export type TaskStatusTimelineMaxAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMaxAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMaxAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMaxAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineMinAggregateFilter = {
  count: InputMaybe<BigIntFilter>;
  endedAt: InputMaybe<DatetimeFilter>;
  spentTime: InputMaybe<FloatFilter>;
  startedAt: InputMaybe<DatetimeFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskStatusTimelineMinAggregates = {
  /** Minimum of count across the matching connection */
  count: Maybe<Scalars['BigInt']['output']>;
  /** Minimum of endedAt across the matching connection */
  endedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Minimum of startedAt across the matching connection */
  startedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
};


export type TaskStatusTimelineMinAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMinAggregatesEndedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMinAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMinAggregatesStartedAtArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineStddevPopulationAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusTimelineStddevPopulationAggregates = {
  /** Population standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStatusTimelineStddevPopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineStddevPopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineStddevSampleAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusTimelineStddevSampleAggregates = {
  /** Sample standard deviation of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStatusTimelineStddevSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineStddevSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineSumAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type TaskStatusTimelineSumAggregates = {
  /** Sum of count across the matching connection */
  count: Scalars['BigFloat']['output'];
  /** Sum of spentTime across the matching connection */
  spentTime: Scalars['Float']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
};


export type TaskStatusTimelineSumAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineSumAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineVariancePopulationAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusTimelineVariancePopulationAggregates = {
  /** Population variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStatusTimelineVariancePopulationAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineVariancePopulationAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

export type TaskStatusTimelineVarianceSampleAggregateFilter = {
  count: InputMaybe<BigFloatFilter>;
  spentTime: InputMaybe<FloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusTimelineVarianceSampleAggregates = {
  /** Sample variance of count across the matching connection */
  count: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of spentTime across the matching connection */
  spentTime: Maybe<Scalars['Float']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStatusTimelineVarianceSampleAggregatesCountArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineVarianceSampleAggregatesSpentTimeArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};


export type TaskStatusTimelineVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskStatusTimelinesOrderBy>>;
};

/** A connection to a list of `TaskStatusTimeline` values. */
export type TaskStatusTimelinesConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskStatusTimelineAggregates>;
  /** A list of edges which contains the `TaskStatusTimeline` and cursor to aid in pagination. */
  edges: Array<TaskStatusTimelinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskStatusTimelineAggregates>>;
  /** A list of `TaskStatusTimeline` objects. */
  nodes: Array<Maybe<TaskStatusTimeline>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskStatusTimeline` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskStatusTimeline` values. */
export type TaskStatusTimelinesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskStatusTimelineGroupBy>;
  having: InputMaybe<TaskStatusTimelineHavingInput>;
};

/** A `TaskStatusTimeline` edge in the connection. */
export type TaskStatusTimelinesEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskStatusTimeline` at the end of the edge. */
  node: Maybe<TaskStatusTimeline>;
};

/** Methods to use when ordering `TaskStatusTimeline`. */
export enum TaskStatusTimelinesOrderBy {
  CountAsc = 'COUNT_ASC',
  CountDesc = 'COUNT_DESC',
  EndedAtAsc = 'ENDED_AT_ASC',
  EndedAtDesc = 'ENDED_AT_DESC',
  IsEndedAsc = 'IS_ENDED_ASC',
  IsEndedDesc = 'IS_ENDED_DESC',
  Natural = 'NATURAL',
  SpentTimeAsc = 'SPENT_TIME_ASC',
  SpentTimeDesc = 'SPENT_TIME_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC'
}

export type TaskStatusUpdate = Node & {
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: TaskStatus;
  /** Reads a single `PrivilegedTask` that is related to this `TaskStatusUpdate`. */
  task: Maybe<PrivilegedTask>;
  taskId: Scalars['Int']['output'];
};

/** A filter to be used against aggregates of `TaskStatusUpdate` object types. */
export type TaskStatusUpdateAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskStatusUpdate` objects. */
  arrayAgg: InputMaybe<TaskStatusUpdateArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskStatusUpdate` objects. */
  average: InputMaybe<TaskStatusUpdateAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskStatusUpdate` objects. */
  distinctCount: InputMaybe<TaskStatusUpdateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskStatusUpdate` object to be included within the aggregate. */
  filter: InputMaybe<TaskStatusUpdateFilter>;
  /** Maximum aggregate over matching `TaskStatusUpdate` objects. */
  max: InputMaybe<TaskStatusUpdateMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskStatusUpdate` objects. */
  min: InputMaybe<TaskStatusUpdateMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskStatusUpdate` objects. */
  stddevPopulation: InputMaybe<TaskStatusUpdateStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskStatusUpdate` objects. */
  stddevSample: InputMaybe<TaskStatusUpdateStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskStatusUpdate` objects. */
  sum: InputMaybe<TaskStatusUpdateSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskStatusUpdate` objects. */
  variancePopulation: InputMaybe<TaskStatusUpdateVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskStatusUpdate` objects. */
  varianceSample: InputMaybe<TaskStatusUpdateVarianceSampleAggregateFilter>;
};

export type TaskStatusUpdateArrayAggAggregateFilter = {
  id: InputMaybe<IntListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type TaskStatusUpdateAverageAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `TaskStatusUpdate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskStatusUpdateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status: InputMaybe<TaskStatus>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
};

export type TaskStatusUpdateDistinctCountAggregateFilter = {
  createdAt: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  status: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `TaskStatusUpdate` object types. All fields are combined with a logical ‘and.’ */
export type TaskStatusUpdateFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskStatusUpdateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskStatusUpdateFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskStatusUpdateFilter>>;
  /** Filter by the object’s `status` field. */
  status: InputMaybe<TaskStatusFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
};

export type TaskStatusUpdateMaxAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskStatusUpdateMinAggregateFilter = {
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  taskId: InputMaybe<IntFilter>;
};

export type TaskStatusUpdateStddevPopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusUpdateStddevSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusUpdateSumAggregateFilter = {
  id: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type TaskStatusUpdateVariancePopulationAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskStatusUpdateVarianceSampleAggregateFilter = {
  id: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `TaskStatusUpdate`. */
export enum TaskStatusUpdatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC'
}

export type TaskStddevPopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type TaskStddevPopulationAggregates = {
  /** Population standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStddevPopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevPopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskStddevSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type TaskStddevSampleAggregates = {
  /** Sample standard deviation of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskStddevSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskStddevSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskSumAggregateFilter = {
  assigneeId: InputMaybe<BigIntFilter>;
  companyId: InputMaybe<BigIntFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  firstLabelId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  nextVisitId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  priority: InputMaybe<BigIntFilter>;
  projectId: InputMaybe<BigIntFilter>;
  projectStageId: InputMaybe<BigIntFilter>;
  systemId: InputMaybe<BigIntFilter>;
  templateAssigneePropertyId: InputMaybe<BigIntFilter>;
  templateTaskId: InputMaybe<BigIntFilter>;
  uid: InputMaybe<BigIntFilter>;
};

export type TaskSumAggregates = {
  /** Sum of assigneeId across the matching connection */
  assigneeId: Scalars['BigInt']['output'];
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of completionTimeInS across the matching connection */
  completionTimeInS: Scalars['BigFloat']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of firstLabelId across the matching connection */
  firstLabelId: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of nextVisitId across the matching connection */
  nextVisitId: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
  /** Sum of priority across the matching connection */
  priority: Scalars['BigInt']['output'];
  /** Sum of projectId across the matching connection */
  projectId: Scalars['BigInt']['output'];
  /** Sum of projectStageId across the matching connection */
  projectStageId: Scalars['BigInt']['output'];
  /** Sum of systemId across the matching connection */
  systemId: Scalars['BigInt']['output'];
  /** Sum of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Scalars['BigInt']['output'];
  /** Sum of templateTaskId across the matching connection */
  templateTaskId: Scalars['BigInt']['output'];
  /** Sum of uid across the matching connection */
  uid: Scalars['BigInt']['output'];
};


export type TaskSumAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskSumAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskTemplateUser = {
  /** Reads a single `Task` that is related to this `TaskTemplateUser`. */
  task: Maybe<Task>;
  taskId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `TaskTemplateUser`. */
  worker: Maybe<User>;
  workerId: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against aggregates of `TaskTemplateUser` object types. */
export type TaskTemplateUserAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskTemplateUser` objects. */
  arrayAgg: InputMaybe<TaskTemplateUserArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskTemplateUser` objects. */
  average: InputMaybe<TaskTemplateUserAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskTemplateUser` objects. */
  distinctCount: InputMaybe<TaskTemplateUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskTemplateUser` object to be included within the aggregate. */
  filter: InputMaybe<TaskTemplateUserFilter>;
  /** Maximum aggregate over matching `TaskTemplateUser` objects. */
  max: InputMaybe<TaskTemplateUserMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskTemplateUser` objects. */
  min: InputMaybe<TaskTemplateUserMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskTemplateUser` objects. */
  stddevPopulation: InputMaybe<TaskTemplateUserStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskTemplateUser` objects. */
  stddevSample: InputMaybe<TaskTemplateUserStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskTemplateUser` objects. */
  sum: InputMaybe<TaskTemplateUserSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskTemplateUser` objects. */
  variancePopulation: InputMaybe<TaskTemplateUserVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskTemplateUser` objects. */
  varianceSample: InputMaybe<TaskTemplateUserVarianceSampleAggregateFilter>;
};

export type TaskTemplateUserArrayAggAggregateFilter = {
  taskId: InputMaybe<IntListFilter>;
  workerId: InputMaybe<IntListFilter>;
};

export type TaskTemplateUserAverageAggregateFilter = {
  taskId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `TaskTemplateUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TaskTemplateUserCondition = {
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `workerId` field. */
  workerId: InputMaybe<Scalars['Int']['input']>;
};

export type TaskTemplateUserDistinctCountAggregateFilter = {
  taskId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `TaskTemplateUser` object types. All fields are combined with a logical ‘and.’ */
export type TaskTemplateUserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskTemplateUserFilter>>;
  /** Negates the expression. */
  not: InputMaybe<TaskTemplateUserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskTemplateUserFilter>>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<TaskFilter>;
  /** A related `task` exists. */
  taskExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `worker` relation. */
  worker: InputMaybe<UserFilter>;
  /** A related `worker` exists. */
  workerExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `workerId` field. */
  workerId: InputMaybe<IntFilter>;
};

export type TaskTemplateUserMaxAggregateFilter = {
  taskId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type TaskTemplateUserMinAggregateFilter = {
  taskId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type TaskTemplateUserStddevPopulationAggregateFilter = {
  taskId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type TaskTemplateUserStddevSampleAggregateFilter = {
  taskId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type TaskTemplateUserSumAggregateFilter = {
  taskId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

export type TaskTemplateUserVariancePopulationAggregateFilter = {
  taskId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type TaskTemplateUserVarianceSampleAggregateFilter = {
  taskId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `TaskTemplateUser`. */
export enum TaskTemplateUsersOrderBy {
  Natural = 'NATURAL',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  WorkerIdAsc = 'WORKER_ID_ASC',
  WorkerIdDesc = 'WORKER_ID_DESC'
}

/** A filter to be used against many `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyBlueprintTaskFilter = {
  /** Aggregates across related `BlueprintTask` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTaskAggregatesFilter>;
  /** Every related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTaskFilter>;
  /** No related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTaskFilter>;
  /** Some related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTaskFilter>;
};

/** A filter to be used against many `GeoEstimation` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyGeoEstimationFilter = {
  /** Aggregates across related `GeoEstimation` match the filter criteria. */
  aggregates: InputMaybe<GeoEstimationAggregatesFilter>;
  /** Every related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeoEstimationFilter>;
  /** No related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeoEstimationFilter>;
  /** Some related `GeoEstimation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeoEstimationFilter>;
};

/** A filter to be used against many `Geolocation` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyGeolocationFilter = {
  /** Aggregates across related `Geolocation` match the filter criteria. */
  aggregates: InputMaybe<GeolocationAggregatesFilter>;
  /** Every related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeolocationFilter>;
  /** No related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeolocationFilter>;
  /** Some related `Geolocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeolocationFilter>;
};

/** A filter to be used against many `SubTask` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManySubTaskFilter = {
  /** Aggregates across related `SubTask` match the filter criteria. */
  aggregates: InputMaybe<SubTaskAggregatesFilter>;
  /** Every related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SubTaskFilter>;
  /** No related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SubTaskFilter>;
  /** Some related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SubTaskFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskFilter = {
  /** Aggregates across related `Task` match the filter criteria. */
  aggregates: InputMaybe<TaskAggregatesFilter>;
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TaskLabel` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskLabelFilter = {
  /** Aggregates across related `TaskLabel` match the filter criteria. */
  aggregates: InputMaybe<TaskLabelAggregatesFilter>;
  /** Every related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskLabelFilter>;
  /** No related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskLabelFilter>;
  /** Some related `TaskLabel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskLabelFilter>;
};

/** A filter to be used against many `TaskTemplateUser` object types. All fields are combined with a logical ‘and.’ */
export type TaskToManyTaskTemplateUserFilter = {
  /** Aggregates across related `TaskTemplateUser` match the filter criteria. */
  aggregates: InputMaybe<TaskTemplateUserAggregatesFilter>;
  /** Every related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskTemplateUserFilter>;
  /** No related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskTemplateUserFilter>;
  /** Some related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskTemplateUserFilter>;
};

export type TaskVariancePopulationAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type TaskVariancePopulationAggregates = {
  /** Population variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVariancePopulationAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVariancePopulationAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskVarianceSampleAggregateFilter = {
  assigneeId: InputMaybe<BigFloatFilter>;
  companyId: InputMaybe<BigFloatFilter>;
  completionTimeInS: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  firstLabelId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  nextVisitId: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  priority: InputMaybe<BigFloatFilter>;
  projectId: InputMaybe<BigFloatFilter>;
  projectStageId: InputMaybe<BigFloatFilter>;
  systemId: InputMaybe<BigFloatFilter>;
  templateAssigneePropertyId: InputMaybe<BigFloatFilter>;
  templateTaskId: InputMaybe<BigFloatFilter>;
  uid: InputMaybe<BigFloatFilter>;
};

export type TaskVarianceSampleAggregates = {
  /** Sample variance of assigneeId across the matching connection */
  assigneeId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of completionTimeInS across the matching connection */
  completionTimeInS: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of firstLabelId across the matching connection */
  firstLabelId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of nextVisitId across the matching connection */
  nextVisitId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of priority across the matching connection */
  priority: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectId across the matching connection */
  projectId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of projectStageId across the matching connection */
  projectStageId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of systemId across the matching connection */
  systemId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateAssigneePropertyId across the matching connection */
  templateAssigneePropertyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of templateTaskId across the matching connection */
  templateTaskId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of uid across the matching connection */
  uid: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVarianceSampleAggregatesAssigneeIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesCompletionTimeInSArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesFirstLabelIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesNextVisitIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesPriorityArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesProjectIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesProjectStageIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesSystemIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesTemplateAssigneePropertyIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesTemplateTaskIdArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};


export type TaskVarianceSampleAggregatesUidArgs = {
  orderBy: InputMaybe<Array<TasksOrderBy>>;
};

export type TaskVisit = Node & {
  completedAt: Maybe<Scalars['Datetime']['output']>;
  createdAt: Scalars['Datetime']['output'];
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `TaskVisit`. */
  createdByUser: Maybe<MainUser>;
  description: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['Datetime']['output']>;
  endDateAllDay: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isCompleted: Maybe<Scalars['Boolean']['output']>;
  jobId: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  startDate: Maybe<Scalars['Datetime']['output']>;
  startDateAllDay: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `PrivilegedTask` that is related to this `TaskVisit`. */
  task: Maybe<PrivilegedTask>;
  taskId: Scalars['Int']['output'];
  /** Reads a single `TaskReport` that is related to this `TaskVisit`. */
  task_report_id: Maybe<TaskReport>;
  updatedAt: Scalars['Datetime']['output'];
};

export type TaskVisitAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<TaskVisitArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<TaskVisitAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<TaskVisitDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<TaskVisitMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<TaskVisitMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<TaskVisitStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<TaskVisitStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<TaskVisitSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<TaskVisitVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<TaskVisitVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `TaskVisit` object types. */
export type TaskVisitAggregatesFilter = {
  /** Array aggregation aggregate over matching `TaskVisit` objects. */
  arrayAgg: InputMaybe<TaskVisitArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `TaskVisit` objects. */
  average: InputMaybe<TaskVisitAverageAggregateFilter>;
  /** Distinct count aggregate over matching `TaskVisit` objects. */
  distinctCount: InputMaybe<TaskVisitDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `TaskVisit` object to be included within the aggregate. */
  filter: InputMaybe<TaskVisitFilter>;
  /** Maximum aggregate over matching `TaskVisit` objects. */
  max: InputMaybe<TaskVisitMaxAggregateFilter>;
  /** Minimum aggregate over matching `TaskVisit` objects. */
  min: InputMaybe<TaskVisitMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `TaskVisit` objects. */
  stddevPopulation: InputMaybe<TaskVisitStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `TaskVisit` objects. */
  stddevSample: InputMaybe<TaskVisitStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `TaskVisit` objects. */
  sum: InputMaybe<TaskVisitSumAggregateFilter>;
  /** Population variance aggregate over matching `TaskVisit` objects. */
  variancePopulation: InputMaybe<TaskVisitVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `TaskVisit` objects. */
  varianceSample: InputMaybe<TaskVisitVarianceSampleAggregateFilter>;
};

export type TaskVisitArrayAggAggregateFilter = {
  createdBy: InputMaybe<IntListFilter>;
  description: InputMaybe<StringListFilter>;
  endDateAllDay: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  isCompleted: InputMaybe<BooleanListFilter>;
  jobId: InputMaybe<StringListFilter>;
  position: InputMaybe<IntListFilter>;
  startDateAllDay: InputMaybe<BooleanListFilter>;
  taskId: InputMaybe<IntListFilter>;
};

export type TaskVisitArrayAggAggregates = {
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of description across the matching connection */
  description: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of endDateAllDay across the matching connection */
  endDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of isCompleted across the matching connection */
  isCompleted: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of jobId across the matching connection */
  jobId: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of position across the matching connection */
  position: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of startDateAllDay across the matching connection */
  startDateAllDay: Array<Maybe<Scalars['Boolean']['output']>>;
  /** Array aggregation of taskId across the matching connection */
  taskId: Array<Maybe<Scalars['Int']['output']>>;
};


export type TaskVisitArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitArrayAggAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitAverageAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskVisitAverageAggregates = {
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVisitAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitAverageAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitAverageAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

/**
 * A condition to be used against `TaskVisit` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TaskVisitCondition = {
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `position` field. */
  position: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

export type TaskVisitDistinctCountAggregateFilter = {
  completedAt: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  endDate: InputMaybe<BigIntFilter>;
  endDateAllDay: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  isCompleted: InputMaybe<BigIntFilter>;
  jobId: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  startDate: InputMaybe<BigIntFilter>;
  startDateAllDay: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type TaskVisitDistinctCountAggregates = {
  /** Distinct count of completedAt across the matching connection */
  completedAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDate across the matching connection */
  endDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endDateAllDay across the matching connection */
  endDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isCompleted across the matching connection */
  isCompleted: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of jobId across the matching connection */
  jobId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of position across the matching connection */
  position: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDate across the matching connection */
  startDate: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startDateAllDay across the matching connection */
  startDateAllDay: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taskId across the matching connection */
  taskId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type TaskVisitDistinctCountAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesEndDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesIsCompletedArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesJobIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesStartDateAllDayArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

/** A filter to be used against `TaskVisit` object types. All fields are combined with a logical ‘and.’ */
export type TaskVisitFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TaskVisitFilter>>;
  /** Filter by the object’s `completedAt` field. */
  completedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<MainUserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endDateAllDay` field. */
  endDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<TaskVisitFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TaskVisitFilter>>;
  /** Filter by the object’s `position` field. */
  position: InputMaybe<IntFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startDateAllDay` field. */
  startDateAllDay: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `task` relation. */
  task: InputMaybe<PrivilegedTaskFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId: InputMaybe<IntFilter>;
  /** Filter by the object’s `task_report_id` relation. */
  task_report_id: InputMaybe<TaskReportFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `TaskVisit` for usage during aggregation. */
export enum TaskVisitGroupBy {
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CompletedAtTruncatedToMonth = 'COMPLETED_AT_TRUNCATED_TO_MONTH',
  CompletedAtTruncatedToQuarter = 'COMPLETED_AT_TRUNCATED_TO_QUARTER',
  CompletedAtTruncatedToWeek = 'COMPLETED_AT_TRUNCATED_TO_WEEK',
  CompletedAtTruncatedToYear = 'COMPLETED_AT_TRUNCATED_TO_YEAR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  EndDate = 'END_DATE',
  EndDateAllDay = 'END_DATE_ALL_DAY',
  EndDateTruncatedToDay = 'END_DATE_TRUNCATED_TO_DAY',
  EndDateTruncatedToHour = 'END_DATE_TRUNCATED_TO_HOUR',
  EndDateTruncatedToMonth = 'END_DATE_TRUNCATED_TO_MONTH',
  EndDateTruncatedToQuarter = 'END_DATE_TRUNCATED_TO_QUARTER',
  EndDateTruncatedToWeek = 'END_DATE_TRUNCATED_TO_WEEK',
  EndDateTruncatedToYear = 'END_DATE_TRUNCATED_TO_YEAR',
  IsCompleted = 'IS_COMPLETED',
  JobId = 'JOB_ID',
  Position = 'POSITION',
  StartDate = 'START_DATE',
  StartDateAllDay = 'START_DATE_ALL_DAY',
  StartDateTruncatedToDay = 'START_DATE_TRUNCATED_TO_DAY',
  StartDateTruncatedToHour = 'START_DATE_TRUNCATED_TO_HOUR',
  StartDateTruncatedToMonth = 'START_DATE_TRUNCATED_TO_MONTH',
  StartDateTruncatedToQuarter = 'START_DATE_TRUNCATED_TO_QUARTER',
  StartDateTruncatedToWeek = 'START_DATE_TRUNCATED_TO_WEEK',
  StartDateTruncatedToYear = 'START_DATE_TRUNCATED_TO_YEAR',
  TaskId = 'TASK_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type TaskVisitHavingArrayAggInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingAverageInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingDistinctCountInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `TaskVisit` aggregates. */
export type TaskVisitHavingInput = {
  AND: InputMaybe<Array<TaskVisitHavingInput>>;
  OR: InputMaybe<Array<TaskVisitHavingInput>>;
  arrayAgg: InputMaybe<TaskVisitHavingArrayAggInput>;
  average: InputMaybe<TaskVisitHavingAverageInput>;
  distinctCount: InputMaybe<TaskVisitHavingDistinctCountInput>;
  max: InputMaybe<TaskVisitHavingMaxInput>;
  min: InputMaybe<TaskVisitHavingMinInput>;
  stddevPopulation: InputMaybe<TaskVisitHavingStddevPopulationInput>;
  stddevSample: InputMaybe<TaskVisitHavingStddevSampleInput>;
  sum: InputMaybe<TaskVisitHavingSumInput>;
  variancePopulation: InputMaybe<TaskVisitHavingVariancePopulationInput>;
  varianceSample: InputMaybe<TaskVisitHavingVarianceSampleInput>;
};

export type TaskVisitHavingMaxInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingMinInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingStddevPopulationInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingStddevSampleInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingSumInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingVariancePopulationInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitHavingVarianceSampleInput = {
  completedAt: InputMaybe<HavingDatetimeFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  endDate: InputMaybe<HavingDatetimeFilter>;
  id: InputMaybe<HavingIntFilter>;
  position: InputMaybe<HavingIntFilter>;
  startDate: InputMaybe<HavingDatetimeFilter>;
  taskId: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TaskVisitMaxAggregateFilter = {
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type TaskVisitMaxAggregates = {
  /** Maximum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Maximum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type TaskVisitMaxAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitMinAggregateFilter = {
  completedAt: InputMaybe<DatetimeFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  endDate: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  position: InputMaybe<IntFilter>;
  startDate: InputMaybe<DatetimeFilter>;
  taskId: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type TaskVisitMinAggregates = {
  /** Minimum of completedAt across the matching connection */
  completedAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of endDate across the matching connection */
  endDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of position across the matching connection */
  position: Maybe<Scalars['Int']['output']>;
  /** Minimum of startDate across the matching connection */
  startDate: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of taskId across the matching connection */
  taskId: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type TaskVisitMinAggregatesCompletedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesEndDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesStartDateArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitStddevPopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskVisitStddevPopulationAggregates = {
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVisitStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevPopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevPopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitStddevSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskVisitStddevSampleAggregates = {
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVisitStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitStddevSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitSumAggregateFilter = {
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  position: InputMaybe<BigIntFilter>;
  taskId: InputMaybe<BigIntFilter>;
};

export type TaskVisitSumAggregates = {
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
  /** Sum of position across the matching connection */
  position: Scalars['BigInt']['output'];
  /** Sum of taskId across the matching connection */
  taskId: Scalars['BigInt']['output'];
};


export type TaskVisitSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitSumAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitSumAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitVariancePopulationAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskVisitVariancePopulationAggregates = {
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVisitVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVariancePopulationAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVariancePopulationAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

export type TaskVisitVarianceSampleAggregateFilter = {
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  position: InputMaybe<BigFloatFilter>;
  taskId: InputMaybe<BigFloatFilter>;
};

export type TaskVisitVarianceSampleAggregates = {
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of position across the matching connection */
  position: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taskId across the matching connection */
  taskId: Maybe<Scalars['BigFloat']['output']>;
};


export type TaskVisitVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVarianceSampleAggregatesPositionArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};


export type TaskVisitVarianceSampleAggregatesTaskIdArgs = {
  orderBy: InputMaybe<Array<TaskVisitsOrderBy>>;
};

/** A connection to a list of `TaskVisit` values. */
export type TaskVisitsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskVisitAggregates>;
  /** A list of edges which contains the `TaskVisit` and cursor to aid in pagination. */
  edges: Array<TaskVisitsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskVisitAggregates>>;
  /** A list of `TaskVisit` objects. */
  nodes: Array<Maybe<TaskVisit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TaskVisit` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TaskVisit` values. */
export type TaskVisitsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskVisitGroupBy>;
  having: InputMaybe<TaskVisitHavingInput>;
};

/** A `TaskVisit` edge in the connection. */
export type TaskVisitsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `TaskVisit` at the end of the edge. */
  node: Maybe<TaskVisit>;
};

/** Methods to use when ordering `TaskVisit`. */
export enum TaskVisitsOrderBy {
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndDateAllDayAsc = 'END_DATE_ALL_DAY_ASC',
  EndDateAllDayDesc = 'END_DATE_ALL_DAY_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartDateAllDayAsc = 'START_DATE_ALL_DAY_ASC',
  StartDateAllDayDesc = 'START_DATE_ALL_DAY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  TaskIdAsc = 'TASK_ID_ASC',
  TaskIdDesc = 'TASK_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `Task` values. */
export type TasksConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TaskAggregates>;
  /** A list of edges which contains the `Task` and cursor to aid in pagination. */
  edges: Array<TasksEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TaskAggregates>>;
  /** A list of `Task` objects. */
  nodes: Array<Maybe<Task>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Task` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Task` values. */
export type TasksConnectionGroupedAggregatesArgs = {
  groupBy: Array<TaskGroupBy>;
  having: InputMaybe<TaskHavingInput>;
};

/** A `Task` edge in the connection. */
export type TasksEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Task` at the end of the edge. */
  node: Maybe<Task>;
};

/** Methods to use when ordering `Task`. */
export enum TasksOrderBy {
  AddressAsc = 'ADDRESS_ASC',
  AddressDesc = 'ADDRESS_DESC',
  AssigneeIdAsc = 'ASSIGNEE_ID_ASC',
  AssigneeIdDesc = 'ASSIGNEE_ID_DESC',
  BlueprintTasksConnectionArrayAggBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionArrayAggBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintTasksConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintTasksConnectionArrayAggCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintTasksConnectionArrayAggCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintTasksConnectionArrayAggIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintTasksConnectionArrayAggIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintTasksConnectionArrayAggSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_SLICE_ID_ASC',
  BlueprintTasksConnectionArrayAggSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_SLICE_ID_DESC',
  BlueprintTasksConnectionArrayAggTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  BlueprintTasksConnectionArrayAggTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  BlueprintTasksConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintTasksConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintTasksConnectionAverageBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionAverageBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionAverageCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintTasksConnectionAverageCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintTasksConnectionAverageCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintTasksConnectionAverageCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintTasksConnectionAverageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_ID_ASC',
  BlueprintTasksConnectionAverageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_ID_DESC',
  BlueprintTasksConnectionAverageSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_SLICE_ID_ASC',
  BlueprintTasksConnectionAverageSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_SLICE_ID_DESC',
  BlueprintTasksConnectionAverageTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_TASK_ID_ASC',
  BlueprintTasksConnectionAverageTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_TASK_ID_DESC',
  BlueprintTasksConnectionAverageUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintTasksConnectionAverageUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintTasksConnectionCountAsc = 'BLUEPRINT_TASKS_CONNECTION_COUNT_ASC',
  BlueprintTasksConnectionCountDesc = 'BLUEPRINT_TASKS_CONNECTION_COUNT_DESC',
  BlueprintTasksConnectionDistinctCountBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionDistinctCountBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintTasksConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintTasksConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintTasksConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintTasksConnectionDistinctCountIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintTasksConnectionDistinctCountIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintTasksConnectionDistinctCountSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_SLICE_ID_ASC',
  BlueprintTasksConnectionDistinctCountSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_SLICE_ID_DESC',
  BlueprintTasksConnectionDistinctCountTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  BlueprintTasksConnectionDistinctCountTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  BlueprintTasksConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintTasksConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintTasksConnectionMaxBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionMaxBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionMaxCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintTasksConnectionMaxCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintTasksConnectionMaxCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintTasksConnectionMaxCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintTasksConnectionMaxIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_ID_ASC',
  BlueprintTasksConnectionMaxIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_ID_DESC',
  BlueprintTasksConnectionMaxSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_SLICE_ID_ASC',
  BlueprintTasksConnectionMaxSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_SLICE_ID_DESC',
  BlueprintTasksConnectionMaxTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_TASK_ID_ASC',
  BlueprintTasksConnectionMaxTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_TASK_ID_DESC',
  BlueprintTasksConnectionMaxUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintTasksConnectionMaxUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintTasksConnectionMinBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionMinBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionMinCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintTasksConnectionMinCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintTasksConnectionMinCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintTasksConnectionMinCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintTasksConnectionMinIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_ID_ASC',
  BlueprintTasksConnectionMinIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_ID_DESC',
  BlueprintTasksConnectionMinSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_SLICE_ID_ASC',
  BlueprintTasksConnectionMinSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_SLICE_ID_DESC',
  BlueprintTasksConnectionMinTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_TASK_ID_ASC',
  BlueprintTasksConnectionMinTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_TASK_ID_DESC',
  BlueprintTasksConnectionMinUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintTasksConnectionMinUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintTasksConnectionStddevPopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionStddevPopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintTasksConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintTasksConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintTasksConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintTasksConnectionStddevPopulationIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintTasksConnectionStddevPopulationIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintTasksConnectionStddevPopulationSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_SLICE_ID_ASC',
  BlueprintTasksConnectionStddevPopulationSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_SLICE_ID_DESC',
  BlueprintTasksConnectionStddevPopulationTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  BlueprintTasksConnectionStddevPopulationTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  BlueprintTasksConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksConnectionStddevSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionStddevSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksConnectionStddevSampleIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintTasksConnectionStddevSampleIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintTasksConnectionStddevSampleSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksConnectionStddevSampleSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksConnectionStddevSampleTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  BlueprintTasksConnectionStddevSampleTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  BlueprintTasksConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintTasksConnectionSumBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionSumBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionSumCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintTasksConnectionSumCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintTasksConnectionSumCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintTasksConnectionSumCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintTasksConnectionSumIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_ID_ASC',
  BlueprintTasksConnectionSumIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_ID_DESC',
  BlueprintTasksConnectionSumSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_SLICE_ID_ASC',
  BlueprintTasksConnectionSumSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_SLICE_ID_DESC',
  BlueprintTasksConnectionSumTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_TASK_ID_ASC',
  BlueprintTasksConnectionSumTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_TASK_ID_DESC',
  BlueprintTasksConnectionSumUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintTasksConnectionSumUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintTasksConnectionVariancePopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionVariancePopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintTasksConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintTasksConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintTasksConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintTasksConnectionVariancePopulationIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintTasksConnectionVariancePopulationIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintTasksConnectionVariancePopulationSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_SLICE_ID_ASC',
  BlueprintTasksConnectionVariancePopulationSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_SLICE_ID_DESC',
  BlueprintTasksConnectionVariancePopulationTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  BlueprintTasksConnectionVariancePopulationTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  BlueprintTasksConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksConnectionVarianceSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksConnectionVarianceSampleIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleSliceIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksConnectionVarianceSampleSliceIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksConnectionVarianceSampleTaskIdAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  BlueprintTasksConnectionVarianceSampleTaskIdDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  BlueprintTasksConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CalculatedStatusAsc = 'CALCULATED_STATUS_ASC',
  CalculatedStatusDesc = 'CALCULATED_STATUS_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompletionDateAsc = 'COMPLETION_DATE_ASC',
  CompletionDateDesc = 'COMPLETION_DATE_DESC',
  CompletionTimeAsc = 'COMPLETION_TIME_ASC',
  CompletionTimeDesc = 'COMPLETION_TIME_DESC',
  CompletionTimeInSAsc = 'COMPLETION_TIME_IN_S_ASC',
  CompletionTimeInSDesc = 'COMPLETION_TIME_IN_S_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DerivedTasksConnectionArrayAggAddressAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  DerivedTasksConnectionArrayAggAddressDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  DerivedTasksConnectionArrayAggAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionArrayAggAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionArrayAggCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionArrayAggCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionArrayAggCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  DerivedTasksConnectionArrayAggCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  DerivedTasksConnectionArrayAggCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  DerivedTasksConnectionArrayAggCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  DerivedTasksConnectionArrayAggCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  DerivedTasksConnectionArrayAggCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  DerivedTasksConnectionArrayAggCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionArrayAggCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionArrayAggCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DerivedTasksConnectionArrayAggCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DerivedTasksConnectionArrayAggCreatedByAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  DerivedTasksConnectionArrayAggCreatedByDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  DerivedTasksConnectionArrayAggDescriptionAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  DerivedTasksConnectionArrayAggDescriptionDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  DerivedTasksConnectionArrayAggEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionArrayAggEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionArrayAggEndDateAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  DerivedTasksConnectionArrayAggEndDateDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  DerivedTasksConnectionArrayAggFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionArrayAggFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionArrayAggFormsAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_FORMS_ASC',
  DerivedTasksConnectionArrayAggFormsDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_FORMS_DESC',
  DerivedTasksConnectionArrayAggIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  DerivedTasksConnectionArrayAggIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  DerivedTasksConnectionArrayAggIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  DerivedTasksConnectionArrayAggIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  DerivedTasksConnectionArrayAggIsColoredAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  DerivedTasksConnectionArrayAggIsColoredDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  DerivedTasksConnectionArrayAggIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  DerivedTasksConnectionArrayAggIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  DerivedTasksConnectionArrayAggIsFieldAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  DerivedTasksConnectionArrayAggIsFieldDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  DerivedTasksConnectionArrayAggIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  DerivedTasksConnectionArrayAggIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  DerivedTasksConnectionArrayAggJobIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  DerivedTasksConnectionArrayAggJobIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  DerivedTasksConnectionArrayAggModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  DerivedTasksConnectionArrayAggModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  DerivedTasksConnectionArrayAggNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionArrayAggNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionArrayAggPositionAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  DerivedTasksConnectionArrayAggPositionDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  DerivedTasksConnectionArrayAggPriorityAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  DerivedTasksConnectionArrayAggPriorityDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  DerivedTasksConnectionArrayAggProjectIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  DerivedTasksConnectionArrayAggProjectIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  DerivedTasksConnectionArrayAggProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionArrayAggProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionArrayAggStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionArrayAggStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionArrayAggStartDateAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  DerivedTasksConnectionArrayAggStartDateDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  DerivedTasksConnectionArrayAggStatusAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  DerivedTasksConnectionArrayAggStatusDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  DerivedTasksConnectionArrayAggSystemIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  DerivedTasksConnectionArrayAggSystemIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  DerivedTasksConnectionArrayAggTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionArrayAggTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionArrayAggTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionArrayAggTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionArrayAggTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionArrayAggTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionArrayAggTitleAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  DerivedTasksConnectionArrayAggTitleDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  DerivedTasksConnectionArrayAggUidAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_UID_ASC',
  DerivedTasksConnectionArrayAggUidDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_UID_DESC',
  DerivedTasksConnectionArrayAggVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionArrayAggVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionAverageAddressAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ADDRESS_ASC',
  DerivedTasksConnectionAverageAddressDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ADDRESS_DESC',
  DerivedTasksConnectionAverageAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionAverageAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionAverageCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionAverageCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionAverageCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  DerivedTasksConnectionAverageCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  DerivedTasksConnectionAverageCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  DerivedTasksConnectionAverageCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  DerivedTasksConnectionAverageCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  DerivedTasksConnectionAverageCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  DerivedTasksConnectionAverageCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionAverageCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionAverageCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DerivedTasksConnectionAverageCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DerivedTasksConnectionAverageCreatedByAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  DerivedTasksConnectionAverageCreatedByDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  DerivedTasksConnectionAverageDescriptionAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  DerivedTasksConnectionAverageDescriptionDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  DerivedTasksConnectionAverageEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionAverageEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionAverageEndDateAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_END_DATE_ASC',
  DerivedTasksConnectionAverageEndDateDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_END_DATE_DESC',
  DerivedTasksConnectionAverageFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionAverageFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionAverageFormsAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_FORMS_ASC',
  DerivedTasksConnectionAverageFormsDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_FORMS_DESC',
  DerivedTasksConnectionAverageIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ID_ASC',
  DerivedTasksConnectionAverageIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_ID_DESC',
  DerivedTasksConnectionAverageIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  DerivedTasksConnectionAverageIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  DerivedTasksConnectionAverageIsColoredAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_COLORED_ASC',
  DerivedTasksConnectionAverageIsColoredDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_COLORED_DESC',
  DerivedTasksConnectionAverageIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  DerivedTasksConnectionAverageIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  DerivedTasksConnectionAverageIsFieldAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_FIELD_ASC',
  DerivedTasksConnectionAverageIsFieldDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_FIELD_DESC',
  DerivedTasksConnectionAverageIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  DerivedTasksConnectionAverageIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  DerivedTasksConnectionAverageJobIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_JOB_ID_ASC',
  DerivedTasksConnectionAverageJobIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_JOB_ID_DESC',
  DerivedTasksConnectionAverageModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  DerivedTasksConnectionAverageModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  DerivedTasksConnectionAverageNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionAverageNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionAveragePositionAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_POSITION_ASC',
  DerivedTasksConnectionAveragePositionDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_POSITION_DESC',
  DerivedTasksConnectionAveragePriorityAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PRIORITY_ASC',
  DerivedTasksConnectionAveragePriorityDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PRIORITY_DESC',
  DerivedTasksConnectionAverageProjectIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  DerivedTasksConnectionAverageProjectIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  DerivedTasksConnectionAverageProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionAverageProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionAverageStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionAverageStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionAverageStartDateAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_START_DATE_ASC',
  DerivedTasksConnectionAverageStartDateDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_START_DATE_DESC',
  DerivedTasksConnectionAverageStatusAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_STATUS_ASC',
  DerivedTasksConnectionAverageStatusDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_STATUS_DESC',
  DerivedTasksConnectionAverageSystemIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  DerivedTasksConnectionAverageSystemIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  DerivedTasksConnectionAverageTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionAverageTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionAverageTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionAverageTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionAverageTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionAverageTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionAverageTitleAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TITLE_ASC',
  DerivedTasksConnectionAverageTitleDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_TITLE_DESC',
  DerivedTasksConnectionAverageUidAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_UID_ASC',
  DerivedTasksConnectionAverageUidDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_UID_DESC',
  DerivedTasksConnectionAverageVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionAverageVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionCountAsc = 'DERIVED_TASKS_CONNECTION_COUNT_ASC',
  DerivedTasksConnectionCountDesc = 'DERIVED_TASKS_CONNECTION_COUNT_DESC',
  DerivedTasksConnectionDistinctCountAddressAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  DerivedTasksConnectionDistinctCountAddressDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  DerivedTasksConnectionDistinctCountAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionDistinctCountAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionDistinctCountCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionDistinctCountCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionDistinctCountCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  DerivedTasksConnectionDistinctCountCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  DerivedTasksConnectionDistinctCountCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  DerivedTasksConnectionDistinctCountCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  DerivedTasksConnectionDistinctCountCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  DerivedTasksConnectionDistinctCountCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  DerivedTasksConnectionDistinctCountCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionDistinctCountCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionDistinctCountCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DerivedTasksConnectionDistinctCountCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DerivedTasksConnectionDistinctCountCreatedByAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  DerivedTasksConnectionDistinctCountCreatedByDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  DerivedTasksConnectionDistinctCountDescriptionAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  DerivedTasksConnectionDistinctCountDescriptionDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  DerivedTasksConnectionDistinctCountEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionDistinctCountEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionDistinctCountEndDateAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  DerivedTasksConnectionDistinctCountEndDateDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  DerivedTasksConnectionDistinctCountFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionDistinctCountFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionDistinctCountFormsAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  DerivedTasksConnectionDistinctCountFormsDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  DerivedTasksConnectionDistinctCountIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  DerivedTasksConnectionDistinctCountIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  DerivedTasksConnectionDistinctCountIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  DerivedTasksConnectionDistinctCountIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  DerivedTasksConnectionDistinctCountIsColoredAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  DerivedTasksConnectionDistinctCountIsColoredDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  DerivedTasksConnectionDistinctCountIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  DerivedTasksConnectionDistinctCountIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  DerivedTasksConnectionDistinctCountIsFieldAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  DerivedTasksConnectionDistinctCountIsFieldDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  DerivedTasksConnectionDistinctCountIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  DerivedTasksConnectionDistinctCountIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  DerivedTasksConnectionDistinctCountJobIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  DerivedTasksConnectionDistinctCountJobIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  DerivedTasksConnectionDistinctCountModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  DerivedTasksConnectionDistinctCountModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  DerivedTasksConnectionDistinctCountNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionDistinctCountNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionDistinctCountPositionAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  DerivedTasksConnectionDistinctCountPositionDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  DerivedTasksConnectionDistinctCountPriorityAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  DerivedTasksConnectionDistinctCountPriorityDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  DerivedTasksConnectionDistinctCountProjectIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  DerivedTasksConnectionDistinctCountProjectIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  DerivedTasksConnectionDistinctCountProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionDistinctCountProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionDistinctCountStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionDistinctCountStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionDistinctCountStartDateAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  DerivedTasksConnectionDistinctCountStartDateDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  DerivedTasksConnectionDistinctCountStatusAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  DerivedTasksConnectionDistinctCountStatusDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  DerivedTasksConnectionDistinctCountSystemIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  DerivedTasksConnectionDistinctCountSystemIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  DerivedTasksConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionDistinctCountTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionDistinctCountTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionDistinctCountTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionDistinctCountTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionDistinctCountTitleAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  DerivedTasksConnectionDistinctCountTitleDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  DerivedTasksConnectionDistinctCountUidAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  DerivedTasksConnectionDistinctCountUidDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  DerivedTasksConnectionDistinctCountVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionDistinctCountVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionMaxAddressAsc = 'DERIVED_TASKS_CONNECTION_MAX_ADDRESS_ASC',
  DerivedTasksConnectionMaxAddressDesc = 'DERIVED_TASKS_CONNECTION_MAX_ADDRESS_DESC',
  DerivedTasksConnectionMaxAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionMaxAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionMaxCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionMaxCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionMaxCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  DerivedTasksConnectionMaxCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  DerivedTasksConnectionMaxCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  DerivedTasksConnectionMaxCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  DerivedTasksConnectionMaxCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  DerivedTasksConnectionMaxCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  DerivedTasksConnectionMaxCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionMaxCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionMaxCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  DerivedTasksConnectionMaxCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  DerivedTasksConnectionMaxCreatedByAsc = 'DERIVED_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  DerivedTasksConnectionMaxCreatedByDesc = 'DERIVED_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  DerivedTasksConnectionMaxDescriptionAsc = 'DERIVED_TASKS_CONNECTION_MAX_DESCRIPTION_ASC',
  DerivedTasksConnectionMaxDescriptionDesc = 'DERIVED_TASKS_CONNECTION_MAX_DESCRIPTION_DESC',
  DerivedTasksConnectionMaxEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionMaxEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionMaxEndDateAsc = 'DERIVED_TASKS_CONNECTION_MAX_END_DATE_ASC',
  DerivedTasksConnectionMaxEndDateDesc = 'DERIVED_TASKS_CONNECTION_MAX_END_DATE_DESC',
  DerivedTasksConnectionMaxFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionMaxFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionMaxFormsAsc = 'DERIVED_TASKS_CONNECTION_MAX_FORMS_ASC',
  DerivedTasksConnectionMaxFormsDesc = 'DERIVED_TASKS_CONNECTION_MAX_FORMS_DESC',
  DerivedTasksConnectionMaxIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_ID_ASC',
  DerivedTasksConnectionMaxIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_ID_DESC',
  DerivedTasksConnectionMaxIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_MAX_IS_ARCHIVED_ASC',
  DerivedTasksConnectionMaxIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_MAX_IS_ARCHIVED_DESC',
  DerivedTasksConnectionMaxIsColoredAsc = 'DERIVED_TASKS_CONNECTION_MAX_IS_COLORED_ASC',
  DerivedTasksConnectionMaxIsColoredDesc = 'DERIVED_TASKS_CONNECTION_MAX_IS_COLORED_DESC',
  DerivedTasksConnectionMaxIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  DerivedTasksConnectionMaxIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  DerivedTasksConnectionMaxIsFieldAsc = 'DERIVED_TASKS_CONNECTION_MAX_IS_FIELD_ASC',
  DerivedTasksConnectionMaxIsFieldDesc = 'DERIVED_TASKS_CONNECTION_MAX_IS_FIELD_DESC',
  DerivedTasksConnectionMaxIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_MAX_IS_TEMPLATE_ASC',
  DerivedTasksConnectionMaxIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_MAX_IS_TEMPLATE_DESC',
  DerivedTasksConnectionMaxJobIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_JOB_ID_ASC',
  DerivedTasksConnectionMaxJobIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_JOB_ID_DESC',
  DerivedTasksConnectionMaxModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_MAX_MODIFIED_AT_ASC',
  DerivedTasksConnectionMaxModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_MAX_MODIFIED_AT_DESC',
  DerivedTasksConnectionMaxNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionMaxNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionMaxPositionAsc = 'DERIVED_TASKS_CONNECTION_MAX_POSITION_ASC',
  DerivedTasksConnectionMaxPositionDesc = 'DERIVED_TASKS_CONNECTION_MAX_POSITION_DESC',
  DerivedTasksConnectionMaxPriorityAsc = 'DERIVED_TASKS_CONNECTION_MAX_PRIORITY_ASC',
  DerivedTasksConnectionMaxPriorityDesc = 'DERIVED_TASKS_CONNECTION_MAX_PRIORITY_DESC',
  DerivedTasksConnectionMaxProjectIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_PROJECT_ID_ASC',
  DerivedTasksConnectionMaxProjectIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_PROJECT_ID_DESC',
  DerivedTasksConnectionMaxProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionMaxProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionMaxStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionMaxStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionMaxStartDateAsc = 'DERIVED_TASKS_CONNECTION_MAX_START_DATE_ASC',
  DerivedTasksConnectionMaxStartDateDesc = 'DERIVED_TASKS_CONNECTION_MAX_START_DATE_DESC',
  DerivedTasksConnectionMaxStatusAsc = 'DERIVED_TASKS_CONNECTION_MAX_STATUS_ASC',
  DerivedTasksConnectionMaxStatusDesc = 'DERIVED_TASKS_CONNECTION_MAX_STATUS_DESC',
  DerivedTasksConnectionMaxSystemIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_SYSTEM_ID_ASC',
  DerivedTasksConnectionMaxSystemIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_SYSTEM_ID_DESC',
  DerivedTasksConnectionMaxTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionMaxTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionMaxTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionMaxTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionMaxTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionMaxTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionMaxTitleAsc = 'DERIVED_TASKS_CONNECTION_MAX_TITLE_ASC',
  DerivedTasksConnectionMaxTitleDesc = 'DERIVED_TASKS_CONNECTION_MAX_TITLE_DESC',
  DerivedTasksConnectionMaxUidAsc = 'DERIVED_TASKS_CONNECTION_MAX_UID_ASC',
  DerivedTasksConnectionMaxUidDesc = 'DERIVED_TASKS_CONNECTION_MAX_UID_DESC',
  DerivedTasksConnectionMaxVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionMaxVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionMinAddressAsc = 'DERIVED_TASKS_CONNECTION_MIN_ADDRESS_ASC',
  DerivedTasksConnectionMinAddressDesc = 'DERIVED_TASKS_CONNECTION_MIN_ADDRESS_DESC',
  DerivedTasksConnectionMinAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionMinAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionMinCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionMinCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionMinCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  DerivedTasksConnectionMinCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  DerivedTasksConnectionMinCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  DerivedTasksConnectionMinCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  DerivedTasksConnectionMinCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  DerivedTasksConnectionMinCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  DerivedTasksConnectionMinCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionMinCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionMinCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  DerivedTasksConnectionMinCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  DerivedTasksConnectionMinCreatedByAsc = 'DERIVED_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  DerivedTasksConnectionMinCreatedByDesc = 'DERIVED_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  DerivedTasksConnectionMinDescriptionAsc = 'DERIVED_TASKS_CONNECTION_MIN_DESCRIPTION_ASC',
  DerivedTasksConnectionMinDescriptionDesc = 'DERIVED_TASKS_CONNECTION_MIN_DESCRIPTION_DESC',
  DerivedTasksConnectionMinEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionMinEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionMinEndDateAsc = 'DERIVED_TASKS_CONNECTION_MIN_END_DATE_ASC',
  DerivedTasksConnectionMinEndDateDesc = 'DERIVED_TASKS_CONNECTION_MIN_END_DATE_DESC',
  DerivedTasksConnectionMinFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionMinFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionMinFormsAsc = 'DERIVED_TASKS_CONNECTION_MIN_FORMS_ASC',
  DerivedTasksConnectionMinFormsDesc = 'DERIVED_TASKS_CONNECTION_MIN_FORMS_DESC',
  DerivedTasksConnectionMinIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_ID_ASC',
  DerivedTasksConnectionMinIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_ID_DESC',
  DerivedTasksConnectionMinIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_MIN_IS_ARCHIVED_ASC',
  DerivedTasksConnectionMinIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_MIN_IS_ARCHIVED_DESC',
  DerivedTasksConnectionMinIsColoredAsc = 'DERIVED_TASKS_CONNECTION_MIN_IS_COLORED_ASC',
  DerivedTasksConnectionMinIsColoredDesc = 'DERIVED_TASKS_CONNECTION_MIN_IS_COLORED_DESC',
  DerivedTasksConnectionMinIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  DerivedTasksConnectionMinIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  DerivedTasksConnectionMinIsFieldAsc = 'DERIVED_TASKS_CONNECTION_MIN_IS_FIELD_ASC',
  DerivedTasksConnectionMinIsFieldDesc = 'DERIVED_TASKS_CONNECTION_MIN_IS_FIELD_DESC',
  DerivedTasksConnectionMinIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_MIN_IS_TEMPLATE_ASC',
  DerivedTasksConnectionMinIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_MIN_IS_TEMPLATE_DESC',
  DerivedTasksConnectionMinJobIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_JOB_ID_ASC',
  DerivedTasksConnectionMinJobIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_JOB_ID_DESC',
  DerivedTasksConnectionMinModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_MIN_MODIFIED_AT_ASC',
  DerivedTasksConnectionMinModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_MIN_MODIFIED_AT_DESC',
  DerivedTasksConnectionMinNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionMinNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionMinPositionAsc = 'DERIVED_TASKS_CONNECTION_MIN_POSITION_ASC',
  DerivedTasksConnectionMinPositionDesc = 'DERIVED_TASKS_CONNECTION_MIN_POSITION_DESC',
  DerivedTasksConnectionMinPriorityAsc = 'DERIVED_TASKS_CONNECTION_MIN_PRIORITY_ASC',
  DerivedTasksConnectionMinPriorityDesc = 'DERIVED_TASKS_CONNECTION_MIN_PRIORITY_DESC',
  DerivedTasksConnectionMinProjectIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_PROJECT_ID_ASC',
  DerivedTasksConnectionMinProjectIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_PROJECT_ID_DESC',
  DerivedTasksConnectionMinProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionMinProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionMinStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionMinStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionMinStartDateAsc = 'DERIVED_TASKS_CONNECTION_MIN_START_DATE_ASC',
  DerivedTasksConnectionMinStartDateDesc = 'DERIVED_TASKS_CONNECTION_MIN_START_DATE_DESC',
  DerivedTasksConnectionMinStatusAsc = 'DERIVED_TASKS_CONNECTION_MIN_STATUS_ASC',
  DerivedTasksConnectionMinStatusDesc = 'DERIVED_TASKS_CONNECTION_MIN_STATUS_DESC',
  DerivedTasksConnectionMinSystemIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_SYSTEM_ID_ASC',
  DerivedTasksConnectionMinSystemIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_SYSTEM_ID_DESC',
  DerivedTasksConnectionMinTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionMinTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionMinTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionMinTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionMinTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionMinTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionMinTitleAsc = 'DERIVED_TASKS_CONNECTION_MIN_TITLE_ASC',
  DerivedTasksConnectionMinTitleDesc = 'DERIVED_TASKS_CONNECTION_MIN_TITLE_DESC',
  DerivedTasksConnectionMinUidAsc = 'DERIVED_TASKS_CONNECTION_MIN_UID_ASC',
  DerivedTasksConnectionMinUidDesc = 'DERIVED_TASKS_CONNECTION_MIN_UID_DESC',
  DerivedTasksConnectionMinVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionMinVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionStddevPopulationAddressAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  DerivedTasksConnectionStddevPopulationAddressDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  DerivedTasksConnectionStddevPopulationAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionStddevPopulationAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionStddevPopulationCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionStddevPopulationCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionStddevPopulationCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  DerivedTasksConnectionStddevPopulationCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  DerivedTasksConnectionStddevPopulationCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  DerivedTasksConnectionStddevPopulationCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  DerivedTasksConnectionStddevPopulationCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  DerivedTasksConnectionStddevPopulationCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  DerivedTasksConnectionStddevPopulationCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionStddevPopulationCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionStddevPopulationCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DerivedTasksConnectionStddevPopulationCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DerivedTasksConnectionStddevPopulationCreatedByAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  DerivedTasksConnectionStddevPopulationCreatedByDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  DerivedTasksConnectionStddevPopulationDescriptionAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  DerivedTasksConnectionStddevPopulationDescriptionDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  DerivedTasksConnectionStddevPopulationEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionStddevPopulationEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionStddevPopulationEndDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  DerivedTasksConnectionStddevPopulationEndDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  DerivedTasksConnectionStddevPopulationFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionStddevPopulationFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionStddevPopulationFormsAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  DerivedTasksConnectionStddevPopulationFormsDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  DerivedTasksConnectionStddevPopulationIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  DerivedTasksConnectionStddevPopulationIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  DerivedTasksConnectionStddevPopulationIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  DerivedTasksConnectionStddevPopulationIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  DerivedTasksConnectionStddevPopulationIsColoredAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  DerivedTasksConnectionStddevPopulationIsColoredDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  DerivedTasksConnectionStddevPopulationIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  DerivedTasksConnectionStddevPopulationIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  DerivedTasksConnectionStddevPopulationIsFieldAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  DerivedTasksConnectionStddevPopulationIsFieldDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  DerivedTasksConnectionStddevPopulationIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  DerivedTasksConnectionStddevPopulationIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  DerivedTasksConnectionStddevPopulationJobIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  DerivedTasksConnectionStddevPopulationJobIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  DerivedTasksConnectionStddevPopulationModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  DerivedTasksConnectionStddevPopulationModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  DerivedTasksConnectionStddevPopulationNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionStddevPopulationNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionStddevPopulationPositionAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  DerivedTasksConnectionStddevPopulationPositionDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  DerivedTasksConnectionStddevPopulationPriorityAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  DerivedTasksConnectionStddevPopulationPriorityDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  DerivedTasksConnectionStddevPopulationProjectIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  DerivedTasksConnectionStddevPopulationProjectIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  DerivedTasksConnectionStddevPopulationProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionStddevPopulationProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionStddevPopulationStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionStddevPopulationStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionStddevPopulationStartDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  DerivedTasksConnectionStddevPopulationStartDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  DerivedTasksConnectionStddevPopulationStatusAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  DerivedTasksConnectionStddevPopulationStatusDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  DerivedTasksConnectionStddevPopulationSystemIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  DerivedTasksConnectionStddevPopulationSystemIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  DerivedTasksConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionStddevPopulationTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionStddevPopulationTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionStddevPopulationTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionStddevPopulationTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionStddevPopulationTitleAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  DerivedTasksConnectionStddevPopulationTitleDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  DerivedTasksConnectionStddevPopulationUidAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  DerivedTasksConnectionStddevPopulationUidDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  DerivedTasksConnectionStddevPopulationVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionStddevPopulationVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionStddevSampleAddressAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  DerivedTasksConnectionStddevSampleAddressDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  DerivedTasksConnectionStddevSampleAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionStddevSampleAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionStddevSampleCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionStddevSampleCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionStddevSampleCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DerivedTasksConnectionStddevSampleCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DerivedTasksConnectionStddevSampleCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  DerivedTasksConnectionStddevSampleCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  DerivedTasksConnectionStddevSampleCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  DerivedTasksConnectionStddevSampleCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  DerivedTasksConnectionStddevSampleCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionStddevSampleCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionStddevSampleCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DerivedTasksConnectionStddevSampleCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DerivedTasksConnectionStddevSampleCreatedByAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  DerivedTasksConnectionStddevSampleCreatedByDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  DerivedTasksConnectionStddevSampleDescriptionAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DerivedTasksConnectionStddevSampleDescriptionDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DerivedTasksConnectionStddevSampleEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionStddevSampleEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionStddevSampleEndDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  DerivedTasksConnectionStddevSampleEndDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  DerivedTasksConnectionStddevSampleFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionStddevSampleFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionStddevSampleFormsAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  DerivedTasksConnectionStddevSampleFormsDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  DerivedTasksConnectionStddevSampleIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  DerivedTasksConnectionStddevSampleIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  DerivedTasksConnectionStddevSampleIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  DerivedTasksConnectionStddevSampleIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  DerivedTasksConnectionStddevSampleIsColoredAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  DerivedTasksConnectionStddevSampleIsColoredDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  DerivedTasksConnectionStddevSampleIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  DerivedTasksConnectionStddevSampleIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  DerivedTasksConnectionStddevSampleIsFieldAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  DerivedTasksConnectionStddevSampleIsFieldDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  DerivedTasksConnectionStddevSampleIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  DerivedTasksConnectionStddevSampleIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  DerivedTasksConnectionStddevSampleJobIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  DerivedTasksConnectionStddevSampleJobIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  DerivedTasksConnectionStddevSampleModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  DerivedTasksConnectionStddevSampleModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  DerivedTasksConnectionStddevSampleNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionStddevSampleNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionStddevSamplePositionAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  DerivedTasksConnectionStddevSamplePositionDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  DerivedTasksConnectionStddevSamplePriorityAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  DerivedTasksConnectionStddevSamplePriorityDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  DerivedTasksConnectionStddevSampleProjectIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  DerivedTasksConnectionStddevSampleProjectIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  DerivedTasksConnectionStddevSampleProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionStddevSampleProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionStddevSampleStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionStddevSampleStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionStddevSampleStartDateAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  DerivedTasksConnectionStddevSampleStartDateDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  DerivedTasksConnectionStddevSampleStatusAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  DerivedTasksConnectionStddevSampleStatusDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  DerivedTasksConnectionStddevSampleSystemIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  DerivedTasksConnectionStddevSampleSystemIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  DerivedTasksConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionStddevSampleTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionStddevSampleTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionStddevSampleTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionStddevSampleTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionStddevSampleTitleAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  DerivedTasksConnectionStddevSampleTitleDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  DerivedTasksConnectionStddevSampleUidAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  DerivedTasksConnectionStddevSampleUidDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  DerivedTasksConnectionStddevSampleVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionStddevSampleVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionSumAddressAsc = 'DERIVED_TASKS_CONNECTION_SUM_ADDRESS_ASC',
  DerivedTasksConnectionSumAddressDesc = 'DERIVED_TASKS_CONNECTION_SUM_ADDRESS_DESC',
  DerivedTasksConnectionSumAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionSumAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionSumCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionSumCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionSumCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  DerivedTasksConnectionSumCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  DerivedTasksConnectionSumCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  DerivedTasksConnectionSumCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  DerivedTasksConnectionSumCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  DerivedTasksConnectionSumCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  DerivedTasksConnectionSumCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionSumCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionSumCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  DerivedTasksConnectionSumCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  DerivedTasksConnectionSumCreatedByAsc = 'DERIVED_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  DerivedTasksConnectionSumCreatedByDesc = 'DERIVED_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  DerivedTasksConnectionSumDescriptionAsc = 'DERIVED_TASKS_CONNECTION_SUM_DESCRIPTION_ASC',
  DerivedTasksConnectionSumDescriptionDesc = 'DERIVED_TASKS_CONNECTION_SUM_DESCRIPTION_DESC',
  DerivedTasksConnectionSumEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionSumEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionSumEndDateAsc = 'DERIVED_TASKS_CONNECTION_SUM_END_DATE_ASC',
  DerivedTasksConnectionSumEndDateDesc = 'DERIVED_TASKS_CONNECTION_SUM_END_DATE_DESC',
  DerivedTasksConnectionSumFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionSumFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionSumFormsAsc = 'DERIVED_TASKS_CONNECTION_SUM_FORMS_ASC',
  DerivedTasksConnectionSumFormsDesc = 'DERIVED_TASKS_CONNECTION_SUM_FORMS_DESC',
  DerivedTasksConnectionSumIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_ID_ASC',
  DerivedTasksConnectionSumIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_ID_DESC',
  DerivedTasksConnectionSumIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_SUM_IS_ARCHIVED_ASC',
  DerivedTasksConnectionSumIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_SUM_IS_ARCHIVED_DESC',
  DerivedTasksConnectionSumIsColoredAsc = 'DERIVED_TASKS_CONNECTION_SUM_IS_COLORED_ASC',
  DerivedTasksConnectionSumIsColoredDesc = 'DERIVED_TASKS_CONNECTION_SUM_IS_COLORED_DESC',
  DerivedTasksConnectionSumIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  DerivedTasksConnectionSumIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  DerivedTasksConnectionSumIsFieldAsc = 'DERIVED_TASKS_CONNECTION_SUM_IS_FIELD_ASC',
  DerivedTasksConnectionSumIsFieldDesc = 'DERIVED_TASKS_CONNECTION_SUM_IS_FIELD_DESC',
  DerivedTasksConnectionSumIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_SUM_IS_TEMPLATE_ASC',
  DerivedTasksConnectionSumIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_SUM_IS_TEMPLATE_DESC',
  DerivedTasksConnectionSumJobIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_JOB_ID_ASC',
  DerivedTasksConnectionSumJobIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_JOB_ID_DESC',
  DerivedTasksConnectionSumModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_SUM_MODIFIED_AT_ASC',
  DerivedTasksConnectionSumModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_SUM_MODIFIED_AT_DESC',
  DerivedTasksConnectionSumNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionSumNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionSumPositionAsc = 'DERIVED_TASKS_CONNECTION_SUM_POSITION_ASC',
  DerivedTasksConnectionSumPositionDesc = 'DERIVED_TASKS_CONNECTION_SUM_POSITION_DESC',
  DerivedTasksConnectionSumPriorityAsc = 'DERIVED_TASKS_CONNECTION_SUM_PRIORITY_ASC',
  DerivedTasksConnectionSumPriorityDesc = 'DERIVED_TASKS_CONNECTION_SUM_PRIORITY_DESC',
  DerivedTasksConnectionSumProjectIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_PROJECT_ID_ASC',
  DerivedTasksConnectionSumProjectIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_PROJECT_ID_DESC',
  DerivedTasksConnectionSumProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionSumProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionSumStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionSumStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionSumStartDateAsc = 'DERIVED_TASKS_CONNECTION_SUM_START_DATE_ASC',
  DerivedTasksConnectionSumStartDateDesc = 'DERIVED_TASKS_CONNECTION_SUM_START_DATE_DESC',
  DerivedTasksConnectionSumStatusAsc = 'DERIVED_TASKS_CONNECTION_SUM_STATUS_ASC',
  DerivedTasksConnectionSumStatusDesc = 'DERIVED_TASKS_CONNECTION_SUM_STATUS_DESC',
  DerivedTasksConnectionSumSystemIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_SYSTEM_ID_ASC',
  DerivedTasksConnectionSumSystemIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_SYSTEM_ID_DESC',
  DerivedTasksConnectionSumTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionSumTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionSumTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionSumTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionSumTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionSumTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionSumTitleAsc = 'DERIVED_TASKS_CONNECTION_SUM_TITLE_ASC',
  DerivedTasksConnectionSumTitleDesc = 'DERIVED_TASKS_CONNECTION_SUM_TITLE_DESC',
  DerivedTasksConnectionSumUidAsc = 'DERIVED_TASKS_CONNECTION_SUM_UID_ASC',
  DerivedTasksConnectionSumUidDesc = 'DERIVED_TASKS_CONNECTION_SUM_UID_DESC',
  DerivedTasksConnectionSumVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionSumVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionVariancePopulationAddressAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  DerivedTasksConnectionVariancePopulationAddressDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  DerivedTasksConnectionVariancePopulationAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionVariancePopulationAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionVariancePopulationCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionVariancePopulationCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionVariancePopulationCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DerivedTasksConnectionVariancePopulationCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DerivedTasksConnectionVariancePopulationCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  DerivedTasksConnectionVariancePopulationCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  DerivedTasksConnectionVariancePopulationCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  DerivedTasksConnectionVariancePopulationCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  DerivedTasksConnectionVariancePopulationCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionVariancePopulationCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionVariancePopulationCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DerivedTasksConnectionVariancePopulationCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DerivedTasksConnectionVariancePopulationCreatedByAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  DerivedTasksConnectionVariancePopulationCreatedByDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  DerivedTasksConnectionVariancePopulationDescriptionAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DerivedTasksConnectionVariancePopulationDescriptionDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DerivedTasksConnectionVariancePopulationEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionVariancePopulationEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionVariancePopulationEndDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  DerivedTasksConnectionVariancePopulationEndDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  DerivedTasksConnectionVariancePopulationFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionVariancePopulationFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionVariancePopulationFormsAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  DerivedTasksConnectionVariancePopulationFormsDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  DerivedTasksConnectionVariancePopulationIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  DerivedTasksConnectionVariancePopulationIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  DerivedTasksConnectionVariancePopulationIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  DerivedTasksConnectionVariancePopulationIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  DerivedTasksConnectionVariancePopulationIsColoredAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  DerivedTasksConnectionVariancePopulationIsColoredDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  DerivedTasksConnectionVariancePopulationIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  DerivedTasksConnectionVariancePopulationIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  DerivedTasksConnectionVariancePopulationIsFieldAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  DerivedTasksConnectionVariancePopulationIsFieldDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  DerivedTasksConnectionVariancePopulationIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  DerivedTasksConnectionVariancePopulationIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  DerivedTasksConnectionVariancePopulationJobIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  DerivedTasksConnectionVariancePopulationJobIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  DerivedTasksConnectionVariancePopulationModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  DerivedTasksConnectionVariancePopulationModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  DerivedTasksConnectionVariancePopulationNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionVariancePopulationNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionVariancePopulationPositionAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  DerivedTasksConnectionVariancePopulationPositionDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  DerivedTasksConnectionVariancePopulationPriorityAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  DerivedTasksConnectionVariancePopulationPriorityDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  DerivedTasksConnectionVariancePopulationProjectIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  DerivedTasksConnectionVariancePopulationProjectIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  DerivedTasksConnectionVariancePopulationProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionVariancePopulationProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionVariancePopulationStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionVariancePopulationStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionVariancePopulationStartDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  DerivedTasksConnectionVariancePopulationStartDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  DerivedTasksConnectionVariancePopulationStatusAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  DerivedTasksConnectionVariancePopulationStatusDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  DerivedTasksConnectionVariancePopulationSystemIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  DerivedTasksConnectionVariancePopulationSystemIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  DerivedTasksConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionVariancePopulationTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionVariancePopulationTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionVariancePopulationTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionVariancePopulationTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionVariancePopulationTitleAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  DerivedTasksConnectionVariancePopulationTitleDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  DerivedTasksConnectionVariancePopulationUidAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  DerivedTasksConnectionVariancePopulationUidDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  DerivedTasksConnectionVariancePopulationVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionVariancePopulationVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  DerivedTasksConnectionVarianceSampleAddressAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  DerivedTasksConnectionVarianceSampleAddressDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  DerivedTasksConnectionVarianceSampleAssigneeIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  DerivedTasksConnectionVarianceSampleAssigneeIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  DerivedTasksConnectionVarianceSampleCalculatedStatusAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  DerivedTasksConnectionVarianceSampleCalculatedStatusDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  DerivedTasksConnectionVarianceSampleCompanyIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DerivedTasksConnectionVarianceSampleCompanyIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DerivedTasksConnectionVarianceSampleCompletionDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  DerivedTasksConnectionVarianceSampleCompletionDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  DerivedTasksConnectionVarianceSampleCompletionTimeAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  DerivedTasksConnectionVarianceSampleCompletionTimeDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  DerivedTasksConnectionVarianceSampleCompletionTimeInSAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  DerivedTasksConnectionVarianceSampleCompletionTimeInSDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  DerivedTasksConnectionVarianceSampleCreatedAtAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DerivedTasksConnectionVarianceSampleCreatedAtDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DerivedTasksConnectionVarianceSampleCreatedByAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  DerivedTasksConnectionVarianceSampleCreatedByDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  DerivedTasksConnectionVarianceSampleDescriptionAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DerivedTasksConnectionVarianceSampleDescriptionDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DerivedTasksConnectionVarianceSampleEndDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionVarianceSampleEndDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionVarianceSampleEndDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  DerivedTasksConnectionVarianceSampleEndDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  DerivedTasksConnectionVarianceSampleFirstLabelIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  DerivedTasksConnectionVarianceSampleFirstLabelIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  DerivedTasksConnectionVarianceSampleFormsAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  DerivedTasksConnectionVarianceSampleFormsDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  DerivedTasksConnectionVarianceSampleIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  DerivedTasksConnectionVarianceSampleIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  DerivedTasksConnectionVarianceSampleIsArchivedAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  DerivedTasksConnectionVarianceSampleIsArchivedDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  DerivedTasksConnectionVarianceSampleIsColoredAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  DerivedTasksConnectionVarianceSampleIsColoredDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  DerivedTasksConnectionVarianceSampleIsCompletedAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  DerivedTasksConnectionVarianceSampleIsCompletedDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  DerivedTasksConnectionVarianceSampleIsFieldAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  DerivedTasksConnectionVarianceSampleIsFieldDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  DerivedTasksConnectionVarianceSampleIsTemplateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  DerivedTasksConnectionVarianceSampleIsTemplateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  DerivedTasksConnectionVarianceSampleJobIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  DerivedTasksConnectionVarianceSampleJobIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  DerivedTasksConnectionVarianceSampleModifiedAtAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  DerivedTasksConnectionVarianceSampleModifiedAtDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  DerivedTasksConnectionVarianceSampleNextVisitIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  DerivedTasksConnectionVarianceSampleNextVisitIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  DerivedTasksConnectionVarianceSamplePositionAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  DerivedTasksConnectionVarianceSamplePositionDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  DerivedTasksConnectionVarianceSamplePriorityAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  DerivedTasksConnectionVarianceSamplePriorityDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  DerivedTasksConnectionVarianceSampleProjectIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  DerivedTasksConnectionVarianceSampleProjectIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  DerivedTasksConnectionVarianceSampleProjectStageIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  DerivedTasksConnectionVarianceSampleProjectStageIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  DerivedTasksConnectionVarianceSampleStartDateAllDayAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  DerivedTasksConnectionVarianceSampleStartDateAllDayDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  DerivedTasksConnectionVarianceSampleStartDateAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  DerivedTasksConnectionVarianceSampleStartDateDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  DerivedTasksConnectionVarianceSampleStatusAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  DerivedTasksConnectionVarianceSampleStatusDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  DerivedTasksConnectionVarianceSampleSystemIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  DerivedTasksConnectionVarianceSampleSystemIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  DerivedTasksConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  DerivedTasksConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  DerivedTasksConnectionVarianceSampleTemplatePropertyIdsAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  DerivedTasksConnectionVarianceSampleTemplatePropertyIdsDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  DerivedTasksConnectionVarianceSampleTemplateTaskIdAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  DerivedTasksConnectionVarianceSampleTemplateTaskIdDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  DerivedTasksConnectionVarianceSampleTitleAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  DerivedTasksConnectionVarianceSampleTitleDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  DerivedTasksConnectionVarianceSampleUidAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  DerivedTasksConnectionVarianceSampleUidDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  DerivedTasksConnectionVarianceSampleVirtualPropertiesAsc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  DerivedTasksConnectionVarianceSampleVirtualPropertiesDesc = 'DERIVED_TASKS_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndDateAllDayAsc = 'END_DATE_ALL_DAY_ASC',
  EndDateAllDayDesc = 'END_DATE_ALL_DAY_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  FirstLabelIdAsc = 'FIRST_LABEL_ID_ASC',
  FirstLabelIdDesc = 'FIRST_LABEL_ID_DESC',
  FormsAsc = 'FORMS_ASC',
  FormsDesc = 'FORMS_DESC',
  GeolocationsConnectionArrayAggAccuracyAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_ACCURACY_ASC',
  GeolocationsConnectionArrayAggAccuracyDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_ACCURACY_DESC',
  GeolocationsConnectionArrayAggCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  GeolocationsConnectionArrayAggCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  GeolocationsConnectionArrayAggCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeolocationsConnectionArrayAggCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeolocationsConnectionArrayAggDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_DEVICE_ID_ASC',
  GeolocationsConnectionArrayAggDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_DEVICE_ID_DESC',
  GeolocationsConnectionArrayAggHeadingAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_HEADING_ASC',
  GeolocationsConnectionArrayAggHeadingDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_HEADING_DESC',
  GeolocationsConnectionArrayAggIdAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  GeolocationsConnectionArrayAggIdDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  GeolocationsConnectionArrayAggLatitudeAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_LATITUDE_ASC',
  GeolocationsConnectionArrayAggLatitudeDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_LATITUDE_DESC',
  GeolocationsConnectionArrayAggLongitudeAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_LONGITUDE_ASC',
  GeolocationsConnectionArrayAggLongitudeDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_LONGITUDE_DESC',
  GeolocationsConnectionArrayAggRawAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_RAW_ASC',
  GeolocationsConnectionArrayAggRawDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_RAW_DESC',
  GeolocationsConnectionArrayAggSourceAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  GeolocationsConnectionArrayAggSourceDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  GeolocationsConnectionArrayAggSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SPEED_ACCURACY_ASC',
  GeolocationsConnectionArrayAggSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SPEED_ACCURACY_DESC',
  GeolocationsConnectionArrayAggSpeedAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SPEED_ASC',
  GeolocationsConnectionArrayAggSpeedDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_SPEED_DESC',
  GeolocationsConnectionArrayAggTaskIdAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  GeolocationsConnectionArrayAggTaskIdDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  GeolocationsConnectionArrayAggTimestampAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_TIMESTAMP_ASC',
  GeolocationsConnectionArrayAggTimestampDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_TIMESTAMP_DESC',
  GeolocationsConnectionArrayAggUserIdAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  GeolocationsConnectionArrayAggUserIdDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  GeolocationsConnectionArrayAggUuidAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_UUID_ASC',
  GeolocationsConnectionArrayAggUuidDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_UUID_DESC',
  GeolocationsConnectionArrayAggVersionAsc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_VERSION_ASC',
  GeolocationsConnectionArrayAggVersionDesc = 'GEOLOCATIONS_CONNECTION_ARRAY_AGG_VERSION_DESC',
  GeolocationsConnectionAverageAccuracyAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_ACCURACY_ASC',
  GeolocationsConnectionAverageAccuracyDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_ACCURACY_DESC',
  GeolocationsConnectionAverageCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  GeolocationsConnectionAverageCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  GeolocationsConnectionAverageCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeolocationsConnectionAverageCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeolocationsConnectionAverageDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_DEVICE_ID_ASC',
  GeolocationsConnectionAverageDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_DEVICE_ID_DESC',
  GeolocationsConnectionAverageHeadingAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_HEADING_ASC',
  GeolocationsConnectionAverageHeadingDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_HEADING_DESC',
  GeolocationsConnectionAverageIdAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_ID_ASC',
  GeolocationsConnectionAverageIdDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_ID_DESC',
  GeolocationsConnectionAverageLatitudeAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_LATITUDE_ASC',
  GeolocationsConnectionAverageLatitudeDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_LATITUDE_DESC',
  GeolocationsConnectionAverageLongitudeAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_LONGITUDE_ASC',
  GeolocationsConnectionAverageLongitudeDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_LONGITUDE_DESC',
  GeolocationsConnectionAverageRawAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_RAW_ASC',
  GeolocationsConnectionAverageRawDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_RAW_DESC',
  GeolocationsConnectionAverageSourceAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SOURCE_ASC',
  GeolocationsConnectionAverageSourceDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SOURCE_DESC',
  GeolocationsConnectionAverageSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SPEED_ACCURACY_ASC',
  GeolocationsConnectionAverageSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SPEED_ACCURACY_DESC',
  GeolocationsConnectionAverageSpeedAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SPEED_ASC',
  GeolocationsConnectionAverageSpeedDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_SPEED_DESC',
  GeolocationsConnectionAverageTaskIdAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_TASK_ID_ASC',
  GeolocationsConnectionAverageTaskIdDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_TASK_ID_DESC',
  GeolocationsConnectionAverageTimestampAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_TIMESTAMP_ASC',
  GeolocationsConnectionAverageTimestampDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_TIMESTAMP_DESC',
  GeolocationsConnectionAverageUserIdAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_USER_ID_ASC',
  GeolocationsConnectionAverageUserIdDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_USER_ID_DESC',
  GeolocationsConnectionAverageUuidAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_UUID_ASC',
  GeolocationsConnectionAverageUuidDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_UUID_DESC',
  GeolocationsConnectionAverageVersionAsc = 'GEOLOCATIONS_CONNECTION_AVERAGE_VERSION_ASC',
  GeolocationsConnectionAverageVersionDesc = 'GEOLOCATIONS_CONNECTION_AVERAGE_VERSION_DESC',
  GeolocationsConnectionCountAsc = 'GEOLOCATIONS_CONNECTION_COUNT_ASC',
  GeolocationsConnectionCountDesc = 'GEOLOCATIONS_CONNECTION_COUNT_DESC',
  GeolocationsConnectionDistinctCountAccuracyAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_ACCURACY_ASC',
  GeolocationsConnectionDistinctCountAccuracyDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_ACCURACY_DESC',
  GeolocationsConnectionDistinctCountCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  GeolocationsConnectionDistinctCountCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  GeolocationsConnectionDistinctCountCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeolocationsConnectionDistinctCountCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeolocationsConnectionDistinctCountDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_DEVICE_ID_ASC',
  GeolocationsConnectionDistinctCountDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_DEVICE_ID_DESC',
  GeolocationsConnectionDistinctCountHeadingAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_HEADING_ASC',
  GeolocationsConnectionDistinctCountHeadingDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_HEADING_DESC',
  GeolocationsConnectionDistinctCountIdAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeolocationsConnectionDistinctCountIdDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeolocationsConnectionDistinctCountLatitudeAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_LATITUDE_ASC',
  GeolocationsConnectionDistinctCountLatitudeDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_LATITUDE_DESC',
  GeolocationsConnectionDistinctCountLongitudeAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_LONGITUDE_ASC',
  GeolocationsConnectionDistinctCountLongitudeDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_LONGITUDE_DESC',
  GeolocationsConnectionDistinctCountRawAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_RAW_ASC',
  GeolocationsConnectionDistinctCountRawDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_RAW_DESC',
  GeolocationsConnectionDistinctCountSourceAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  GeolocationsConnectionDistinctCountSourceDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  GeolocationsConnectionDistinctCountSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SPEED_ACCURACY_ASC',
  GeolocationsConnectionDistinctCountSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SPEED_ACCURACY_DESC',
  GeolocationsConnectionDistinctCountSpeedAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SPEED_ASC',
  GeolocationsConnectionDistinctCountSpeedDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_SPEED_DESC',
  GeolocationsConnectionDistinctCountTaskIdAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  GeolocationsConnectionDistinctCountTaskIdDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  GeolocationsConnectionDistinctCountTimestampAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_TIMESTAMP_ASC',
  GeolocationsConnectionDistinctCountTimestampDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_TIMESTAMP_DESC',
  GeolocationsConnectionDistinctCountUserIdAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  GeolocationsConnectionDistinctCountUserIdDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  GeolocationsConnectionDistinctCountUuidAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  GeolocationsConnectionDistinctCountUuidDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  GeolocationsConnectionDistinctCountVersionAsc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_VERSION_ASC',
  GeolocationsConnectionDistinctCountVersionDesc = 'GEOLOCATIONS_CONNECTION_DISTINCT_COUNT_VERSION_DESC',
  GeolocationsConnectionMaxAccuracyAsc = 'GEOLOCATIONS_CONNECTION_MAX_ACCURACY_ASC',
  GeolocationsConnectionMaxAccuracyDesc = 'GEOLOCATIONS_CONNECTION_MAX_ACCURACY_DESC',
  GeolocationsConnectionMaxCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_MAX_COMPANY_ID_ASC',
  GeolocationsConnectionMaxCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_MAX_COMPANY_ID_DESC',
  GeolocationsConnectionMaxCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  GeolocationsConnectionMaxCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  GeolocationsConnectionMaxDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_MAX_DEVICE_ID_ASC',
  GeolocationsConnectionMaxDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_MAX_DEVICE_ID_DESC',
  GeolocationsConnectionMaxHeadingAsc = 'GEOLOCATIONS_CONNECTION_MAX_HEADING_ASC',
  GeolocationsConnectionMaxHeadingDesc = 'GEOLOCATIONS_CONNECTION_MAX_HEADING_DESC',
  GeolocationsConnectionMaxIdAsc = 'GEOLOCATIONS_CONNECTION_MAX_ID_ASC',
  GeolocationsConnectionMaxIdDesc = 'GEOLOCATIONS_CONNECTION_MAX_ID_DESC',
  GeolocationsConnectionMaxLatitudeAsc = 'GEOLOCATIONS_CONNECTION_MAX_LATITUDE_ASC',
  GeolocationsConnectionMaxLatitudeDesc = 'GEOLOCATIONS_CONNECTION_MAX_LATITUDE_DESC',
  GeolocationsConnectionMaxLongitudeAsc = 'GEOLOCATIONS_CONNECTION_MAX_LONGITUDE_ASC',
  GeolocationsConnectionMaxLongitudeDesc = 'GEOLOCATIONS_CONNECTION_MAX_LONGITUDE_DESC',
  GeolocationsConnectionMaxRawAsc = 'GEOLOCATIONS_CONNECTION_MAX_RAW_ASC',
  GeolocationsConnectionMaxRawDesc = 'GEOLOCATIONS_CONNECTION_MAX_RAW_DESC',
  GeolocationsConnectionMaxSourceAsc = 'GEOLOCATIONS_CONNECTION_MAX_SOURCE_ASC',
  GeolocationsConnectionMaxSourceDesc = 'GEOLOCATIONS_CONNECTION_MAX_SOURCE_DESC',
  GeolocationsConnectionMaxSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_MAX_SPEED_ACCURACY_ASC',
  GeolocationsConnectionMaxSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_MAX_SPEED_ACCURACY_DESC',
  GeolocationsConnectionMaxSpeedAsc = 'GEOLOCATIONS_CONNECTION_MAX_SPEED_ASC',
  GeolocationsConnectionMaxSpeedDesc = 'GEOLOCATIONS_CONNECTION_MAX_SPEED_DESC',
  GeolocationsConnectionMaxTaskIdAsc = 'GEOLOCATIONS_CONNECTION_MAX_TASK_ID_ASC',
  GeolocationsConnectionMaxTaskIdDesc = 'GEOLOCATIONS_CONNECTION_MAX_TASK_ID_DESC',
  GeolocationsConnectionMaxTimestampAsc = 'GEOLOCATIONS_CONNECTION_MAX_TIMESTAMP_ASC',
  GeolocationsConnectionMaxTimestampDesc = 'GEOLOCATIONS_CONNECTION_MAX_TIMESTAMP_DESC',
  GeolocationsConnectionMaxUserIdAsc = 'GEOLOCATIONS_CONNECTION_MAX_USER_ID_ASC',
  GeolocationsConnectionMaxUserIdDesc = 'GEOLOCATIONS_CONNECTION_MAX_USER_ID_DESC',
  GeolocationsConnectionMaxUuidAsc = 'GEOLOCATIONS_CONNECTION_MAX_UUID_ASC',
  GeolocationsConnectionMaxUuidDesc = 'GEOLOCATIONS_CONNECTION_MAX_UUID_DESC',
  GeolocationsConnectionMaxVersionAsc = 'GEOLOCATIONS_CONNECTION_MAX_VERSION_ASC',
  GeolocationsConnectionMaxVersionDesc = 'GEOLOCATIONS_CONNECTION_MAX_VERSION_DESC',
  GeolocationsConnectionMinAccuracyAsc = 'GEOLOCATIONS_CONNECTION_MIN_ACCURACY_ASC',
  GeolocationsConnectionMinAccuracyDesc = 'GEOLOCATIONS_CONNECTION_MIN_ACCURACY_DESC',
  GeolocationsConnectionMinCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_MIN_COMPANY_ID_ASC',
  GeolocationsConnectionMinCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_MIN_COMPANY_ID_DESC',
  GeolocationsConnectionMinCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  GeolocationsConnectionMinCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  GeolocationsConnectionMinDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_MIN_DEVICE_ID_ASC',
  GeolocationsConnectionMinDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_MIN_DEVICE_ID_DESC',
  GeolocationsConnectionMinHeadingAsc = 'GEOLOCATIONS_CONNECTION_MIN_HEADING_ASC',
  GeolocationsConnectionMinHeadingDesc = 'GEOLOCATIONS_CONNECTION_MIN_HEADING_DESC',
  GeolocationsConnectionMinIdAsc = 'GEOLOCATIONS_CONNECTION_MIN_ID_ASC',
  GeolocationsConnectionMinIdDesc = 'GEOLOCATIONS_CONNECTION_MIN_ID_DESC',
  GeolocationsConnectionMinLatitudeAsc = 'GEOLOCATIONS_CONNECTION_MIN_LATITUDE_ASC',
  GeolocationsConnectionMinLatitudeDesc = 'GEOLOCATIONS_CONNECTION_MIN_LATITUDE_DESC',
  GeolocationsConnectionMinLongitudeAsc = 'GEOLOCATIONS_CONNECTION_MIN_LONGITUDE_ASC',
  GeolocationsConnectionMinLongitudeDesc = 'GEOLOCATIONS_CONNECTION_MIN_LONGITUDE_DESC',
  GeolocationsConnectionMinRawAsc = 'GEOLOCATIONS_CONNECTION_MIN_RAW_ASC',
  GeolocationsConnectionMinRawDesc = 'GEOLOCATIONS_CONNECTION_MIN_RAW_DESC',
  GeolocationsConnectionMinSourceAsc = 'GEOLOCATIONS_CONNECTION_MIN_SOURCE_ASC',
  GeolocationsConnectionMinSourceDesc = 'GEOLOCATIONS_CONNECTION_MIN_SOURCE_DESC',
  GeolocationsConnectionMinSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_MIN_SPEED_ACCURACY_ASC',
  GeolocationsConnectionMinSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_MIN_SPEED_ACCURACY_DESC',
  GeolocationsConnectionMinSpeedAsc = 'GEOLOCATIONS_CONNECTION_MIN_SPEED_ASC',
  GeolocationsConnectionMinSpeedDesc = 'GEOLOCATIONS_CONNECTION_MIN_SPEED_DESC',
  GeolocationsConnectionMinTaskIdAsc = 'GEOLOCATIONS_CONNECTION_MIN_TASK_ID_ASC',
  GeolocationsConnectionMinTaskIdDesc = 'GEOLOCATIONS_CONNECTION_MIN_TASK_ID_DESC',
  GeolocationsConnectionMinTimestampAsc = 'GEOLOCATIONS_CONNECTION_MIN_TIMESTAMP_ASC',
  GeolocationsConnectionMinTimestampDesc = 'GEOLOCATIONS_CONNECTION_MIN_TIMESTAMP_DESC',
  GeolocationsConnectionMinUserIdAsc = 'GEOLOCATIONS_CONNECTION_MIN_USER_ID_ASC',
  GeolocationsConnectionMinUserIdDesc = 'GEOLOCATIONS_CONNECTION_MIN_USER_ID_DESC',
  GeolocationsConnectionMinUuidAsc = 'GEOLOCATIONS_CONNECTION_MIN_UUID_ASC',
  GeolocationsConnectionMinUuidDesc = 'GEOLOCATIONS_CONNECTION_MIN_UUID_DESC',
  GeolocationsConnectionMinVersionAsc = 'GEOLOCATIONS_CONNECTION_MIN_VERSION_ASC',
  GeolocationsConnectionMinVersionDesc = 'GEOLOCATIONS_CONNECTION_MIN_VERSION_DESC',
  GeolocationsConnectionStddevPopulationAccuracyAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_ACCURACY_ASC',
  GeolocationsConnectionStddevPopulationAccuracyDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_ACCURACY_DESC',
  GeolocationsConnectionStddevPopulationCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  GeolocationsConnectionStddevPopulationCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  GeolocationsConnectionStddevPopulationCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeolocationsConnectionStddevPopulationCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeolocationsConnectionStddevPopulationDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_DEVICE_ID_ASC',
  GeolocationsConnectionStddevPopulationDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_DEVICE_ID_DESC',
  GeolocationsConnectionStddevPopulationHeadingAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_HEADING_ASC',
  GeolocationsConnectionStddevPopulationHeadingDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_HEADING_DESC',
  GeolocationsConnectionStddevPopulationIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeolocationsConnectionStddevPopulationIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeolocationsConnectionStddevPopulationLatitudeAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_LATITUDE_ASC',
  GeolocationsConnectionStddevPopulationLatitudeDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_LATITUDE_DESC',
  GeolocationsConnectionStddevPopulationLongitudeAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_LONGITUDE_ASC',
  GeolocationsConnectionStddevPopulationLongitudeDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_LONGITUDE_DESC',
  GeolocationsConnectionStddevPopulationRawAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_RAW_ASC',
  GeolocationsConnectionStddevPopulationRawDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_RAW_DESC',
  GeolocationsConnectionStddevPopulationSourceAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  GeolocationsConnectionStddevPopulationSourceDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  GeolocationsConnectionStddevPopulationSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SPEED_ACCURACY_ASC',
  GeolocationsConnectionStddevPopulationSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SPEED_ACCURACY_DESC',
  GeolocationsConnectionStddevPopulationSpeedAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SPEED_ASC',
  GeolocationsConnectionStddevPopulationSpeedDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_SPEED_DESC',
  GeolocationsConnectionStddevPopulationTaskIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  GeolocationsConnectionStddevPopulationTaskIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  GeolocationsConnectionStddevPopulationTimestampAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_TIMESTAMP_ASC',
  GeolocationsConnectionStddevPopulationTimestampDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_TIMESTAMP_DESC',
  GeolocationsConnectionStddevPopulationUserIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  GeolocationsConnectionStddevPopulationUserIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  GeolocationsConnectionStddevPopulationUuidAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  GeolocationsConnectionStddevPopulationUuidDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  GeolocationsConnectionStddevPopulationVersionAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_VERSION_ASC',
  GeolocationsConnectionStddevPopulationVersionDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_POPULATION_VERSION_DESC',
  GeolocationsConnectionStddevSampleAccuracyAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_ACCURACY_ASC',
  GeolocationsConnectionStddevSampleAccuracyDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_ACCURACY_DESC',
  GeolocationsConnectionStddevSampleCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  GeolocationsConnectionStddevSampleCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  GeolocationsConnectionStddevSampleCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeolocationsConnectionStddevSampleCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeolocationsConnectionStddevSampleDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_DEVICE_ID_ASC',
  GeolocationsConnectionStddevSampleDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_DEVICE_ID_DESC',
  GeolocationsConnectionStddevSampleHeadingAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_HEADING_ASC',
  GeolocationsConnectionStddevSampleHeadingDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_HEADING_DESC',
  GeolocationsConnectionStddevSampleIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeolocationsConnectionStddevSampleIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeolocationsConnectionStddevSampleLatitudeAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_LATITUDE_ASC',
  GeolocationsConnectionStddevSampleLatitudeDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_LATITUDE_DESC',
  GeolocationsConnectionStddevSampleLongitudeAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_LONGITUDE_ASC',
  GeolocationsConnectionStddevSampleLongitudeDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_LONGITUDE_DESC',
  GeolocationsConnectionStddevSampleRawAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_RAW_ASC',
  GeolocationsConnectionStddevSampleRawDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_RAW_DESC',
  GeolocationsConnectionStddevSampleSourceAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  GeolocationsConnectionStddevSampleSourceDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  GeolocationsConnectionStddevSampleSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SPEED_ACCURACY_ASC',
  GeolocationsConnectionStddevSampleSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SPEED_ACCURACY_DESC',
  GeolocationsConnectionStddevSampleSpeedAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SPEED_ASC',
  GeolocationsConnectionStddevSampleSpeedDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_SPEED_DESC',
  GeolocationsConnectionStddevSampleTaskIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  GeolocationsConnectionStddevSampleTaskIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  GeolocationsConnectionStddevSampleTimestampAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_TIMESTAMP_ASC',
  GeolocationsConnectionStddevSampleTimestampDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_TIMESTAMP_DESC',
  GeolocationsConnectionStddevSampleUserIdAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  GeolocationsConnectionStddevSampleUserIdDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  GeolocationsConnectionStddevSampleUuidAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  GeolocationsConnectionStddevSampleUuidDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  GeolocationsConnectionStddevSampleVersionAsc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_VERSION_ASC',
  GeolocationsConnectionStddevSampleVersionDesc = 'GEOLOCATIONS_CONNECTION_STDDEV_SAMPLE_VERSION_DESC',
  GeolocationsConnectionSumAccuracyAsc = 'GEOLOCATIONS_CONNECTION_SUM_ACCURACY_ASC',
  GeolocationsConnectionSumAccuracyDesc = 'GEOLOCATIONS_CONNECTION_SUM_ACCURACY_DESC',
  GeolocationsConnectionSumCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_SUM_COMPANY_ID_ASC',
  GeolocationsConnectionSumCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_SUM_COMPANY_ID_DESC',
  GeolocationsConnectionSumCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  GeolocationsConnectionSumCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  GeolocationsConnectionSumDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_SUM_DEVICE_ID_ASC',
  GeolocationsConnectionSumDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_SUM_DEVICE_ID_DESC',
  GeolocationsConnectionSumHeadingAsc = 'GEOLOCATIONS_CONNECTION_SUM_HEADING_ASC',
  GeolocationsConnectionSumHeadingDesc = 'GEOLOCATIONS_CONNECTION_SUM_HEADING_DESC',
  GeolocationsConnectionSumIdAsc = 'GEOLOCATIONS_CONNECTION_SUM_ID_ASC',
  GeolocationsConnectionSumIdDesc = 'GEOLOCATIONS_CONNECTION_SUM_ID_DESC',
  GeolocationsConnectionSumLatitudeAsc = 'GEOLOCATIONS_CONNECTION_SUM_LATITUDE_ASC',
  GeolocationsConnectionSumLatitudeDesc = 'GEOLOCATIONS_CONNECTION_SUM_LATITUDE_DESC',
  GeolocationsConnectionSumLongitudeAsc = 'GEOLOCATIONS_CONNECTION_SUM_LONGITUDE_ASC',
  GeolocationsConnectionSumLongitudeDesc = 'GEOLOCATIONS_CONNECTION_SUM_LONGITUDE_DESC',
  GeolocationsConnectionSumRawAsc = 'GEOLOCATIONS_CONNECTION_SUM_RAW_ASC',
  GeolocationsConnectionSumRawDesc = 'GEOLOCATIONS_CONNECTION_SUM_RAW_DESC',
  GeolocationsConnectionSumSourceAsc = 'GEOLOCATIONS_CONNECTION_SUM_SOURCE_ASC',
  GeolocationsConnectionSumSourceDesc = 'GEOLOCATIONS_CONNECTION_SUM_SOURCE_DESC',
  GeolocationsConnectionSumSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_SUM_SPEED_ACCURACY_ASC',
  GeolocationsConnectionSumSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_SUM_SPEED_ACCURACY_DESC',
  GeolocationsConnectionSumSpeedAsc = 'GEOLOCATIONS_CONNECTION_SUM_SPEED_ASC',
  GeolocationsConnectionSumSpeedDesc = 'GEOLOCATIONS_CONNECTION_SUM_SPEED_DESC',
  GeolocationsConnectionSumTaskIdAsc = 'GEOLOCATIONS_CONNECTION_SUM_TASK_ID_ASC',
  GeolocationsConnectionSumTaskIdDesc = 'GEOLOCATIONS_CONNECTION_SUM_TASK_ID_DESC',
  GeolocationsConnectionSumTimestampAsc = 'GEOLOCATIONS_CONNECTION_SUM_TIMESTAMP_ASC',
  GeolocationsConnectionSumTimestampDesc = 'GEOLOCATIONS_CONNECTION_SUM_TIMESTAMP_DESC',
  GeolocationsConnectionSumUserIdAsc = 'GEOLOCATIONS_CONNECTION_SUM_USER_ID_ASC',
  GeolocationsConnectionSumUserIdDesc = 'GEOLOCATIONS_CONNECTION_SUM_USER_ID_DESC',
  GeolocationsConnectionSumUuidAsc = 'GEOLOCATIONS_CONNECTION_SUM_UUID_ASC',
  GeolocationsConnectionSumUuidDesc = 'GEOLOCATIONS_CONNECTION_SUM_UUID_DESC',
  GeolocationsConnectionSumVersionAsc = 'GEOLOCATIONS_CONNECTION_SUM_VERSION_ASC',
  GeolocationsConnectionSumVersionDesc = 'GEOLOCATIONS_CONNECTION_SUM_VERSION_DESC',
  GeolocationsConnectionVariancePopulationAccuracyAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_ACCURACY_ASC',
  GeolocationsConnectionVariancePopulationAccuracyDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_ACCURACY_DESC',
  GeolocationsConnectionVariancePopulationCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  GeolocationsConnectionVariancePopulationCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  GeolocationsConnectionVariancePopulationCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeolocationsConnectionVariancePopulationCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeolocationsConnectionVariancePopulationDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_DEVICE_ID_ASC',
  GeolocationsConnectionVariancePopulationDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_DEVICE_ID_DESC',
  GeolocationsConnectionVariancePopulationHeadingAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_HEADING_ASC',
  GeolocationsConnectionVariancePopulationHeadingDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_HEADING_DESC',
  GeolocationsConnectionVariancePopulationIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeolocationsConnectionVariancePopulationIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeolocationsConnectionVariancePopulationLatitudeAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_LATITUDE_ASC',
  GeolocationsConnectionVariancePopulationLatitudeDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_LATITUDE_DESC',
  GeolocationsConnectionVariancePopulationLongitudeAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_LONGITUDE_ASC',
  GeolocationsConnectionVariancePopulationLongitudeDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_LONGITUDE_DESC',
  GeolocationsConnectionVariancePopulationRawAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_RAW_ASC',
  GeolocationsConnectionVariancePopulationRawDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_RAW_DESC',
  GeolocationsConnectionVariancePopulationSourceAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  GeolocationsConnectionVariancePopulationSourceDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  GeolocationsConnectionVariancePopulationSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SPEED_ACCURACY_ASC',
  GeolocationsConnectionVariancePopulationSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SPEED_ACCURACY_DESC',
  GeolocationsConnectionVariancePopulationSpeedAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SPEED_ASC',
  GeolocationsConnectionVariancePopulationSpeedDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_SPEED_DESC',
  GeolocationsConnectionVariancePopulationTaskIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  GeolocationsConnectionVariancePopulationTaskIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  GeolocationsConnectionVariancePopulationTimestampAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_TIMESTAMP_ASC',
  GeolocationsConnectionVariancePopulationTimestampDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_TIMESTAMP_DESC',
  GeolocationsConnectionVariancePopulationUserIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  GeolocationsConnectionVariancePopulationUserIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  GeolocationsConnectionVariancePopulationUuidAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  GeolocationsConnectionVariancePopulationUuidDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  GeolocationsConnectionVariancePopulationVersionAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_VERSION_ASC',
  GeolocationsConnectionVariancePopulationVersionDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_POPULATION_VERSION_DESC',
  GeolocationsConnectionVarianceSampleAccuracyAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_ACCURACY_ASC',
  GeolocationsConnectionVarianceSampleAccuracyDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_ACCURACY_DESC',
  GeolocationsConnectionVarianceSampleCompanyIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  GeolocationsConnectionVarianceSampleCompanyIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  GeolocationsConnectionVarianceSampleCreatedAtAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeolocationsConnectionVarianceSampleCreatedAtDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeolocationsConnectionVarianceSampleDeviceIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_DEVICE_ID_ASC',
  GeolocationsConnectionVarianceSampleDeviceIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_DEVICE_ID_DESC',
  GeolocationsConnectionVarianceSampleHeadingAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_HEADING_ASC',
  GeolocationsConnectionVarianceSampleHeadingDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_HEADING_DESC',
  GeolocationsConnectionVarianceSampleIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeolocationsConnectionVarianceSampleIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeolocationsConnectionVarianceSampleLatitudeAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_LATITUDE_ASC',
  GeolocationsConnectionVarianceSampleLatitudeDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_LATITUDE_DESC',
  GeolocationsConnectionVarianceSampleLongitudeAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_LONGITUDE_ASC',
  GeolocationsConnectionVarianceSampleLongitudeDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_LONGITUDE_DESC',
  GeolocationsConnectionVarianceSampleRawAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_RAW_ASC',
  GeolocationsConnectionVarianceSampleRawDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_RAW_DESC',
  GeolocationsConnectionVarianceSampleSourceAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  GeolocationsConnectionVarianceSampleSourceDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  GeolocationsConnectionVarianceSampleSpeedAccuracyAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SPEED_ACCURACY_ASC',
  GeolocationsConnectionVarianceSampleSpeedAccuracyDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SPEED_ACCURACY_DESC',
  GeolocationsConnectionVarianceSampleSpeedAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SPEED_ASC',
  GeolocationsConnectionVarianceSampleSpeedDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_SPEED_DESC',
  GeolocationsConnectionVarianceSampleTaskIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  GeolocationsConnectionVarianceSampleTaskIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  GeolocationsConnectionVarianceSampleTimestampAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_TIMESTAMP_ASC',
  GeolocationsConnectionVarianceSampleTimestampDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_TIMESTAMP_DESC',
  GeolocationsConnectionVarianceSampleUserIdAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  GeolocationsConnectionVarianceSampleUserIdDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  GeolocationsConnectionVarianceSampleUuidAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  GeolocationsConnectionVarianceSampleUuidDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  GeolocationsConnectionVarianceSampleVersionAsc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_VERSION_ASC',
  GeolocationsConnectionVarianceSampleVersionDesc = 'GEOLOCATIONS_CONNECTION_VARIANCE_SAMPLE_VERSION_DESC',
  GeoEstimationsConnectionArrayAggCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeoEstimationsConnectionArrayAggCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeoEstimationsConnectionArrayAggDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_DURATION_ASC',
  GeoEstimationsConnectionArrayAggDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_DURATION_DESC',
  GeoEstimationsConnectionArrayAggIdAsc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_ID_ASC',
  GeoEstimationsConnectionArrayAggIdDesc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_ID_DESC',
  GeoEstimationsConnectionArrayAggLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_LOCATION_ID_ASC',
  GeoEstimationsConnectionArrayAggLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_LOCATION_ID_DESC',
  GeoEstimationsConnectionArrayAggTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  GeoEstimationsConnectionArrayAggTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  GeoEstimationsConnectionAverageCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeoEstimationsConnectionAverageCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeoEstimationsConnectionAverageDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_DURATION_ASC',
  GeoEstimationsConnectionAverageDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_DURATION_DESC',
  GeoEstimationsConnectionAverageIdAsc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_ID_ASC',
  GeoEstimationsConnectionAverageIdDesc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_ID_DESC',
  GeoEstimationsConnectionAverageLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_LOCATION_ID_ASC',
  GeoEstimationsConnectionAverageLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_LOCATION_ID_DESC',
  GeoEstimationsConnectionAverageTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_TASK_ID_ASC',
  GeoEstimationsConnectionAverageTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_AVERAGE_TASK_ID_DESC',
  GeoEstimationsConnectionCountAsc = 'GEO_ESTIMATIONS_CONNECTION_COUNT_ASC',
  GeoEstimationsConnectionCountDesc = 'GEO_ESTIMATIONS_CONNECTION_COUNT_DESC',
  GeoEstimationsConnectionDistinctCountCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeoEstimationsConnectionDistinctCountCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeoEstimationsConnectionDistinctCountDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_DURATION_ASC',
  GeoEstimationsConnectionDistinctCountDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_DURATION_DESC',
  GeoEstimationsConnectionDistinctCountIdAsc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeoEstimationsConnectionDistinctCountIdDesc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeoEstimationsConnectionDistinctCountLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_LOCATION_ID_ASC',
  GeoEstimationsConnectionDistinctCountLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_LOCATION_ID_DESC',
  GeoEstimationsConnectionDistinctCountTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  GeoEstimationsConnectionDistinctCountTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  GeoEstimationsConnectionMaxCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_MAX_CREATED_AT_ASC',
  GeoEstimationsConnectionMaxCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_MAX_CREATED_AT_DESC',
  GeoEstimationsConnectionMaxDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_MAX_DURATION_ASC',
  GeoEstimationsConnectionMaxDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_MAX_DURATION_DESC',
  GeoEstimationsConnectionMaxIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MAX_ID_ASC',
  GeoEstimationsConnectionMaxIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MAX_ID_DESC',
  GeoEstimationsConnectionMaxLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MAX_LOCATION_ID_ASC',
  GeoEstimationsConnectionMaxLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MAX_LOCATION_ID_DESC',
  GeoEstimationsConnectionMaxTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MAX_TASK_ID_ASC',
  GeoEstimationsConnectionMaxTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MAX_TASK_ID_DESC',
  GeoEstimationsConnectionMinCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_MIN_CREATED_AT_ASC',
  GeoEstimationsConnectionMinCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_MIN_CREATED_AT_DESC',
  GeoEstimationsConnectionMinDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_MIN_DURATION_ASC',
  GeoEstimationsConnectionMinDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_MIN_DURATION_DESC',
  GeoEstimationsConnectionMinIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MIN_ID_ASC',
  GeoEstimationsConnectionMinIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MIN_ID_DESC',
  GeoEstimationsConnectionMinLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MIN_LOCATION_ID_ASC',
  GeoEstimationsConnectionMinLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MIN_LOCATION_ID_DESC',
  GeoEstimationsConnectionMinTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_MIN_TASK_ID_ASC',
  GeoEstimationsConnectionMinTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_MIN_TASK_ID_DESC',
  GeoEstimationsConnectionStddevPopulationCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeoEstimationsConnectionStddevPopulationCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeoEstimationsConnectionStddevPopulationDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_DURATION_ASC',
  GeoEstimationsConnectionStddevPopulationDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_DURATION_DESC',
  GeoEstimationsConnectionStddevPopulationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeoEstimationsConnectionStddevPopulationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeoEstimationsConnectionStddevPopulationLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_LOCATION_ID_ASC',
  GeoEstimationsConnectionStddevPopulationLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_LOCATION_ID_DESC',
  GeoEstimationsConnectionStddevPopulationTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  GeoEstimationsConnectionStddevPopulationTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  GeoEstimationsConnectionStddevSampleCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeoEstimationsConnectionStddevSampleCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeoEstimationsConnectionStddevSampleDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_DURATION_ASC',
  GeoEstimationsConnectionStddevSampleDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_DURATION_DESC',
  GeoEstimationsConnectionStddevSampleIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeoEstimationsConnectionStddevSampleIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeoEstimationsConnectionStddevSampleLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_LOCATION_ID_ASC',
  GeoEstimationsConnectionStddevSampleLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_LOCATION_ID_DESC',
  GeoEstimationsConnectionStddevSampleTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  GeoEstimationsConnectionStddevSampleTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  GeoEstimationsConnectionSumCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_SUM_CREATED_AT_ASC',
  GeoEstimationsConnectionSumCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_SUM_CREATED_AT_DESC',
  GeoEstimationsConnectionSumDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_SUM_DURATION_ASC',
  GeoEstimationsConnectionSumDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_SUM_DURATION_DESC',
  GeoEstimationsConnectionSumIdAsc = 'GEO_ESTIMATIONS_CONNECTION_SUM_ID_ASC',
  GeoEstimationsConnectionSumIdDesc = 'GEO_ESTIMATIONS_CONNECTION_SUM_ID_DESC',
  GeoEstimationsConnectionSumLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_SUM_LOCATION_ID_ASC',
  GeoEstimationsConnectionSumLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_SUM_LOCATION_ID_DESC',
  GeoEstimationsConnectionSumTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_SUM_TASK_ID_ASC',
  GeoEstimationsConnectionSumTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_SUM_TASK_ID_DESC',
  GeoEstimationsConnectionVariancePopulationCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeoEstimationsConnectionVariancePopulationCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeoEstimationsConnectionVariancePopulationDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_DURATION_ASC',
  GeoEstimationsConnectionVariancePopulationDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_DURATION_DESC',
  GeoEstimationsConnectionVariancePopulationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeoEstimationsConnectionVariancePopulationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeoEstimationsConnectionVariancePopulationLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_LOCATION_ID_ASC',
  GeoEstimationsConnectionVariancePopulationLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_LOCATION_ID_DESC',
  GeoEstimationsConnectionVariancePopulationTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  GeoEstimationsConnectionVariancePopulationTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  GeoEstimationsConnectionVarianceSampleCreatedAtAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeoEstimationsConnectionVarianceSampleCreatedAtDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeoEstimationsConnectionVarianceSampleDurationAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_DURATION_ASC',
  GeoEstimationsConnectionVarianceSampleDurationDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_DURATION_DESC',
  GeoEstimationsConnectionVarianceSampleIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeoEstimationsConnectionVarianceSampleIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeoEstimationsConnectionVarianceSampleLocationIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_LOCATION_ID_ASC',
  GeoEstimationsConnectionVarianceSampleLocationIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_LOCATION_ID_DESC',
  GeoEstimationsConnectionVarianceSampleTaskIdAsc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  GeoEstimationsConnectionVarianceSampleTaskIdDesc = 'GEO_ESTIMATIONS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsColoredAsc = 'IS_COLORED_ASC',
  IsColoredDesc = 'IS_COLORED_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsFieldAsc = 'IS_FIELD_ASC',
  IsFieldDesc = 'IS_FIELD_DESC',
  IsTemplateAsc = 'IS_TEMPLATE_ASC',
  IsTemplateDesc = 'IS_TEMPLATE_DESC',
  JobIdAsc = 'JOB_ID_ASC',
  JobIdDesc = 'JOB_ID_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  Natural = 'NATURAL',
  NextVisitIdAsc = 'NEXT_VISIT_ID_ASC',
  NextVisitIdDesc = 'NEXT_VISIT_ID_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ProjectStageIdAsc = 'PROJECT_STAGE_ID_ASC',
  ProjectStageIdDesc = 'PROJECT_STAGE_ID_DESC',
  StartDateAllDayAsc = 'START_DATE_ALL_DAY_ASC',
  StartDateAllDayDesc = 'START_DATE_ALL_DAY_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubTasksConnectionArrayAggCompanyIdAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SubTasksConnectionArrayAggCompanyIdDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SubTasksConnectionArrayAggCreatedAtAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SubTasksConnectionArrayAggCreatedAtDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SubTasksConnectionArrayAggCreatedByAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SubTasksConnectionArrayAggCreatedByDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SubTasksConnectionArrayAggDateAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_DATE_ASC',
  SubTasksConnectionArrayAggDateDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_DATE_DESC',
  SubTasksConnectionArrayAggDetailAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_DETAIL_ASC',
  SubTasksConnectionArrayAggDetailDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_DETAIL_DESC',
  SubTasksConnectionArrayAggIdAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_ID_ASC',
  SubTasksConnectionArrayAggIdDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_ID_DESC',
  SubTasksConnectionArrayAggIsCompletedAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  SubTasksConnectionArrayAggIsCompletedDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  SubTasksConnectionArrayAggPositionAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_POSITION_ASC',
  SubTasksConnectionArrayAggPositionDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_POSITION_DESC',
  SubTasksConnectionArrayAggTaskIdAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  SubTasksConnectionArrayAggTaskIdDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  SubTasksConnectionArrayAggUpdatedAtAsc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SubTasksConnectionArrayAggUpdatedAtDesc = 'SUB_TASKS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SubTasksConnectionAverageCompanyIdAsc = 'SUB_TASKS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SubTasksConnectionAverageCompanyIdDesc = 'SUB_TASKS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SubTasksConnectionAverageCreatedAtAsc = 'SUB_TASKS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SubTasksConnectionAverageCreatedAtDesc = 'SUB_TASKS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SubTasksConnectionAverageCreatedByAsc = 'SUB_TASKS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SubTasksConnectionAverageCreatedByDesc = 'SUB_TASKS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SubTasksConnectionAverageDateAsc = 'SUB_TASKS_CONNECTION_AVERAGE_DATE_ASC',
  SubTasksConnectionAverageDateDesc = 'SUB_TASKS_CONNECTION_AVERAGE_DATE_DESC',
  SubTasksConnectionAverageDetailAsc = 'SUB_TASKS_CONNECTION_AVERAGE_DETAIL_ASC',
  SubTasksConnectionAverageDetailDesc = 'SUB_TASKS_CONNECTION_AVERAGE_DETAIL_DESC',
  SubTasksConnectionAverageIdAsc = 'SUB_TASKS_CONNECTION_AVERAGE_ID_ASC',
  SubTasksConnectionAverageIdDesc = 'SUB_TASKS_CONNECTION_AVERAGE_ID_DESC',
  SubTasksConnectionAverageIsCompletedAsc = 'SUB_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  SubTasksConnectionAverageIsCompletedDesc = 'SUB_TASKS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  SubTasksConnectionAveragePositionAsc = 'SUB_TASKS_CONNECTION_AVERAGE_POSITION_ASC',
  SubTasksConnectionAveragePositionDesc = 'SUB_TASKS_CONNECTION_AVERAGE_POSITION_DESC',
  SubTasksConnectionAverageTaskIdAsc = 'SUB_TASKS_CONNECTION_AVERAGE_TASK_ID_ASC',
  SubTasksConnectionAverageTaskIdDesc = 'SUB_TASKS_CONNECTION_AVERAGE_TASK_ID_DESC',
  SubTasksConnectionAverageUpdatedAtAsc = 'SUB_TASKS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SubTasksConnectionAverageUpdatedAtDesc = 'SUB_TASKS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SubTasksConnectionCountAsc = 'SUB_TASKS_CONNECTION_COUNT_ASC',
  SubTasksConnectionCountDesc = 'SUB_TASKS_CONNECTION_COUNT_DESC',
  SubTasksConnectionDistinctCountCompanyIdAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SubTasksConnectionDistinctCountCompanyIdDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SubTasksConnectionDistinctCountCreatedAtAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SubTasksConnectionDistinctCountCreatedAtDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SubTasksConnectionDistinctCountCreatedByAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SubTasksConnectionDistinctCountCreatedByDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SubTasksConnectionDistinctCountDateAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_DATE_ASC',
  SubTasksConnectionDistinctCountDateDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_DATE_DESC',
  SubTasksConnectionDistinctCountDetailAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_DETAIL_ASC',
  SubTasksConnectionDistinctCountDetailDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_DETAIL_DESC',
  SubTasksConnectionDistinctCountIdAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SubTasksConnectionDistinctCountIdDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SubTasksConnectionDistinctCountIsCompletedAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  SubTasksConnectionDistinctCountIsCompletedDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  SubTasksConnectionDistinctCountPositionAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  SubTasksConnectionDistinctCountPositionDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  SubTasksConnectionDistinctCountTaskIdAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  SubTasksConnectionDistinctCountTaskIdDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  SubTasksConnectionDistinctCountUpdatedAtAsc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SubTasksConnectionDistinctCountUpdatedAtDesc = 'SUB_TASKS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SubTasksConnectionMaxCompanyIdAsc = 'SUB_TASKS_CONNECTION_MAX_COMPANY_ID_ASC',
  SubTasksConnectionMaxCompanyIdDesc = 'SUB_TASKS_CONNECTION_MAX_COMPANY_ID_DESC',
  SubTasksConnectionMaxCreatedAtAsc = 'SUB_TASKS_CONNECTION_MAX_CREATED_AT_ASC',
  SubTasksConnectionMaxCreatedAtDesc = 'SUB_TASKS_CONNECTION_MAX_CREATED_AT_DESC',
  SubTasksConnectionMaxCreatedByAsc = 'SUB_TASKS_CONNECTION_MAX_CREATED_BY_ASC',
  SubTasksConnectionMaxCreatedByDesc = 'SUB_TASKS_CONNECTION_MAX_CREATED_BY_DESC',
  SubTasksConnectionMaxDateAsc = 'SUB_TASKS_CONNECTION_MAX_DATE_ASC',
  SubTasksConnectionMaxDateDesc = 'SUB_TASKS_CONNECTION_MAX_DATE_DESC',
  SubTasksConnectionMaxDetailAsc = 'SUB_TASKS_CONNECTION_MAX_DETAIL_ASC',
  SubTasksConnectionMaxDetailDesc = 'SUB_TASKS_CONNECTION_MAX_DETAIL_DESC',
  SubTasksConnectionMaxIdAsc = 'SUB_TASKS_CONNECTION_MAX_ID_ASC',
  SubTasksConnectionMaxIdDesc = 'SUB_TASKS_CONNECTION_MAX_ID_DESC',
  SubTasksConnectionMaxIsCompletedAsc = 'SUB_TASKS_CONNECTION_MAX_IS_COMPLETED_ASC',
  SubTasksConnectionMaxIsCompletedDesc = 'SUB_TASKS_CONNECTION_MAX_IS_COMPLETED_DESC',
  SubTasksConnectionMaxPositionAsc = 'SUB_TASKS_CONNECTION_MAX_POSITION_ASC',
  SubTasksConnectionMaxPositionDesc = 'SUB_TASKS_CONNECTION_MAX_POSITION_DESC',
  SubTasksConnectionMaxTaskIdAsc = 'SUB_TASKS_CONNECTION_MAX_TASK_ID_ASC',
  SubTasksConnectionMaxTaskIdDesc = 'SUB_TASKS_CONNECTION_MAX_TASK_ID_DESC',
  SubTasksConnectionMaxUpdatedAtAsc = 'SUB_TASKS_CONNECTION_MAX_UPDATED_AT_ASC',
  SubTasksConnectionMaxUpdatedAtDesc = 'SUB_TASKS_CONNECTION_MAX_UPDATED_AT_DESC',
  SubTasksConnectionMinCompanyIdAsc = 'SUB_TASKS_CONNECTION_MIN_COMPANY_ID_ASC',
  SubTasksConnectionMinCompanyIdDesc = 'SUB_TASKS_CONNECTION_MIN_COMPANY_ID_DESC',
  SubTasksConnectionMinCreatedAtAsc = 'SUB_TASKS_CONNECTION_MIN_CREATED_AT_ASC',
  SubTasksConnectionMinCreatedAtDesc = 'SUB_TASKS_CONNECTION_MIN_CREATED_AT_DESC',
  SubTasksConnectionMinCreatedByAsc = 'SUB_TASKS_CONNECTION_MIN_CREATED_BY_ASC',
  SubTasksConnectionMinCreatedByDesc = 'SUB_TASKS_CONNECTION_MIN_CREATED_BY_DESC',
  SubTasksConnectionMinDateAsc = 'SUB_TASKS_CONNECTION_MIN_DATE_ASC',
  SubTasksConnectionMinDateDesc = 'SUB_TASKS_CONNECTION_MIN_DATE_DESC',
  SubTasksConnectionMinDetailAsc = 'SUB_TASKS_CONNECTION_MIN_DETAIL_ASC',
  SubTasksConnectionMinDetailDesc = 'SUB_TASKS_CONNECTION_MIN_DETAIL_DESC',
  SubTasksConnectionMinIdAsc = 'SUB_TASKS_CONNECTION_MIN_ID_ASC',
  SubTasksConnectionMinIdDesc = 'SUB_TASKS_CONNECTION_MIN_ID_DESC',
  SubTasksConnectionMinIsCompletedAsc = 'SUB_TASKS_CONNECTION_MIN_IS_COMPLETED_ASC',
  SubTasksConnectionMinIsCompletedDesc = 'SUB_TASKS_CONNECTION_MIN_IS_COMPLETED_DESC',
  SubTasksConnectionMinPositionAsc = 'SUB_TASKS_CONNECTION_MIN_POSITION_ASC',
  SubTasksConnectionMinPositionDesc = 'SUB_TASKS_CONNECTION_MIN_POSITION_DESC',
  SubTasksConnectionMinTaskIdAsc = 'SUB_TASKS_CONNECTION_MIN_TASK_ID_ASC',
  SubTasksConnectionMinTaskIdDesc = 'SUB_TASKS_CONNECTION_MIN_TASK_ID_DESC',
  SubTasksConnectionMinUpdatedAtAsc = 'SUB_TASKS_CONNECTION_MIN_UPDATED_AT_ASC',
  SubTasksConnectionMinUpdatedAtDesc = 'SUB_TASKS_CONNECTION_MIN_UPDATED_AT_DESC',
  SubTasksConnectionStddevPopulationCompanyIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SubTasksConnectionStddevPopulationCompanyIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SubTasksConnectionStddevPopulationCreatedAtAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SubTasksConnectionStddevPopulationCreatedAtDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SubTasksConnectionStddevPopulationCreatedByAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SubTasksConnectionStddevPopulationCreatedByDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SubTasksConnectionStddevPopulationDateAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_DATE_ASC',
  SubTasksConnectionStddevPopulationDateDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_DATE_DESC',
  SubTasksConnectionStddevPopulationDetailAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_DETAIL_ASC',
  SubTasksConnectionStddevPopulationDetailDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_DETAIL_DESC',
  SubTasksConnectionStddevPopulationIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SubTasksConnectionStddevPopulationIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SubTasksConnectionStddevPopulationIsCompletedAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  SubTasksConnectionStddevPopulationIsCompletedDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  SubTasksConnectionStddevPopulationPositionAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  SubTasksConnectionStddevPopulationPositionDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  SubTasksConnectionStddevPopulationTaskIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  SubTasksConnectionStddevPopulationTaskIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  SubTasksConnectionStddevPopulationUpdatedAtAsc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SubTasksConnectionStddevPopulationUpdatedAtDesc = 'SUB_TASKS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SubTasksConnectionStddevSampleCompanyIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SubTasksConnectionStddevSampleCompanyIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SubTasksConnectionStddevSampleCreatedAtAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SubTasksConnectionStddevSampleCreatedAtDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SubTasksConnectionStddevSampleCreatedByAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SubTasksConnectionStddevSampleCreatedByDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SubTasksConnectionStddevSampleDateAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_DATE_ASC',
  SubTasksConnectionStddevSampleDateDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_DATE_DESC',
  SubTasksConnectionStddevSampleDetailAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_DETAIL_ASC',
  SubTasksConnectionStddevSampleDetailDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_DETAIL_DESC',
  SubTasksConnectionStddevSampleIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SubTasksConnectionStddevSampleIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SubTasksConnectionStddevSampleIsCompletedAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  SubTasksConnectionStddevSampleIsCompletedDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  SubTasksConnectionStddevSamplePositionAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  SubTasksConnectionStddevSamplePositionDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  SubTasksConnectionStddevSampleTaskIdAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  SubTasksConnectionStddevSampleTaskIdDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  SubTasksConnectionStddevSampleUpdatedAtAsc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SubTasksConnectionStddevSampleUpdatedAtDesc = 'SUB_TASKS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SubTasksConnectionSumCompanyIdAsc = 'SUB_TASKS_CONNECTION_SUM_COMPANY_ID_ASC',
  SubTasksConnectionSumCompanyIdDesc = 'SUB_TASKS_CONNECTION_SUM_COMPANY_ID_DESC',
  SubTasksConnectionSumCreatedAtAsc = 'SUB_TASKS_CONNECTION_SUM_CREATED_AT_ASC',
  SubTasksConnectionSumCreatedAtDesc = 'SUB_TASKS_CONNECTION_SUM_CREATED_AT_DESC',
  SubTasksConnectionSumCreatedByAsc = 'SUB_TASKS_CONNECTION_SUM_CREATED_BY_ASC',
  SubTasksConnectionSumCreatedByDesc = 'SUB_TASKS_CONNECTION_SUM_CREATED_BY_DESC',
  SubTasksConnectionSumDateAsc = 'SUB_TASKS_CONNECTION_SUM_DATE_ASC',
  SubTasksConnectionSumDateDesc = 'SUB_TASKS_CONNECTION_SUM_DATE_DESC',
  SubTasksConnectionSumDetailAsc = 'SUB_TASKS_CONNECTION_SUM_DETAIL_ASC',
  SubTasksConnectionSumDetailDesc = 'SUB_TASKS_CONNECTION_SUM_DETAIL_DESC',
  SubTasksConnectionSumIdAsc = 'SUB_TASKS_CONNECTION_SUM_ID_ASC',
  SubTasksConnectionSumIdDesc = 'SUB_TASKS_CONNECTION_SUM_ID_DESC',
  SubTasksConnectionSumIsCompletedAsc = 'SUB_TASKS_CONNECTION_SUM_IS_COMPLETED_ASC',
  SubTasksConnectionSumIsCompletedDesc = 'SUB_TASKS_CONNECTION_SUM_IS_COMPLETED_DESC',
  SubTasksConnectionSumPositionAsc = 'SUB_TASKS_CONNECTION_SUM_POSITION_ASC',
  SubTasksConnectionSumPositionDesc = 'SUB_TASKS_CONNECTION_SUM_POSITION_DESC',
  SubTasksConnectionSumTaskIdAsc = 'SUB_TASKS_CONNECTION_SUM_TASK_ID_ASC',
  SubTasksConnectionSumTaskIdDesc = 'SUB_TASKS_CONNECTION_SUM_TASK_ID_DESC',
  SubTasksConnectionSumUpdatedAtAsc = 'SUB_TASKS_CONNECTION_SUM_UPDATED_AT_ASC',
  SubTasksConnectionSumUpdatedAtDesc = 'SUB_TASKS_CONNECTION_SUM_UPDATED_AT_DESC',
  SubTasksConnectionVariancePopulationCompanyIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SubTasksConnectionVariancePopulationCompanyIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SubTasksConnectionVariancePopulationCreatedAtAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SubTasksConnectionVariancePopulationCreatedAtDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SubTasksConnectionVariancePopulationCreatedByAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SubTasksConnectionVariancePopulationCreatedByDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SubTasksConnectionVariancePopulationDateAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_DATE_ASC',
  SubTasksConnectionVariancePopulationDateDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_DATE_DESC',
  SubTasksConnectionVariancePopulationDetailAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_DETAIL_ASC',
  SubTasksConnectionVariancePopulationDetailDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_DETAIL_DESC',
  SubTasksConnectionVariancePopulationIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SubTasksConnectionVariancePopulationIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SubTasksConnectionVariancePopulationIsCompletedAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  SubTasksConnectionVariancePopulationIsCompletedDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  SubTasksConnectionVariancePopulationPositionAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  SubTasksConnectionVariancePopulationPositionDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  SubTasksConnectionVariancePopulationTaskIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  SubTasksConnectionVariancePopulationTaskIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  SubTasksConnectionVariancePopulationUpdatedAtAsc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SubTasksConnectionVariancePopulationUpdatedAtDesc = 'SUB_TASKS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SubTasksConnectionVarianceSampleCompanyIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SubTasksConnectionVarianceSampleCompanyIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SubTasksConnectionVarianceSampleCreatedAtAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SubTasksConnectionVarianceSampleCreatedAtDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SubTasksConnectionVarianceSampleCreatedByAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SubTasksConnectionVarianceSampleCreatedByDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SubTasksConnectionVarianceSampleDateAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_DATE_ASC',
  SubTasksConnectionVarianceSampleDateDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_DATE_DESC',
  SubTasksConnectionVarianceSampleDetailAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_DETAIL_ASC',
  SubTasksConnectionVarianceSampleDetailDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_DETAIL_DESC',
  SubTasksConnectionVarianceSampleIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SubTasksConnectionVarianceSampleIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SubTasksConnectionVarianceSampleIsCompletedAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  SubTasksConnectionVarianceSampleIsCompletedDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  SubTasksConnectionVarianceSamplePositionAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  SubTasksConnectionVarianceSamplePositionDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  SubTasksConnectionVarianceSampleTaskIdAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  SubTasksConnectionVarianceSampleTaskIdDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  SubTasksConnectionVarianceSampleUpdatedAtAsc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SubTasksConnectionVarianceSampleUpdatedAtDesc = 'SUB_TASKS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TaskLabelsConnectionArrayAggLabelIdAsc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_ASC',
  TaskLabelsConnectionArrayAggLabelIdDesc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_LABEL_ID_DESC',
  TaskLabelsConnectionArrayAggTaskIdAsc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskLabelsConnectionArrayAggTaskIdDesc = 'TASK_LABELS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskLabelsConnectionAverageLabelIdAsc = 'TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_ASC',
  TaskLabelsConnectionAverageLabelIdDesc = 'TASK_LABELS_CONNECTION_AVERAGE_LABEL_ID_DESC',
  TaskLabelsConnectionAverageTaskIdAsc = 'TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskLabelsConnectionAverageTaskIdDesc = 'TASK_LABELS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskLabelsConnectionCountAsc = 'TASK_LABELS_CONNECTION_COUNT_ASC',
  TaskLabelsConnectionCountDesc = 'TASK_LABELS_CONNECTION_COUNT_DESC',
  TaskLabelsConnectionDistinctCountLabelIdAsc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_ASC',
  TaskLabelsConnectionDistinctCountLabelIdDesc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_LABEL_ID_DESC',
  TaskLabelsConnectionDistinctCountTaskIdAsc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskLabelsConnectionDistinctCountTaskIdDesc = 'TASK_LABELS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskLabelsConnectionMaxLabelIdAsc = 'TASK_LABELS_CONNECTION_MAX_LABEL_ID_ASC',
  TaskLabelsConnectionMaxLabelIdDesc = 'TASK_LABELS_CONNECTION_MAX_LABEL_ID_DESC',
  TaskLabelsConnectionMaxTaskIdAsc = 'TASK_LABELS_CONNECTION_MAX_TASK_ID_ASC',
  TaskLabelsConnectionMaxTaskIdDesc = 'TASK_LABELS_CONNECTION_MAX_TASK_ID_DESC',
  TaskLabelsConnectionMinLabelIdAsc = 'TASK_LABELS_CONNECTION_MIN_LABEL_ID_ASC',
  TaskLabelsConnectionMinLabelIdDesc = 'TASK_LABELS_CONNECTION_MIN_LABEL_ID_DESC',
  TaskLabelsConnectionMinTaskIdAsc = 'TASK_LABELS_CONNECTION_MIN_TASK_ID_ASC',
  TaskLabelsConnectionMinTaskIdDesc = 'TASK_LABELS_CONNECTION_MIN_TASK_ID_DESC',
  TaskLabelsConnectionStddevPopulationLabelIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_ASC',
  TaskLabelsConnectionStddevPopulationLabelIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_LABEL_ID_DESC',
  TaskLabelsConnectionStddevPopulationTaskIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskLabelsConnectionStddevPopulationTaskIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskLabelsConnectionStddevSampleLabelIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_ASC',
  TaskLabelsConnectionStddevSampleLabelIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_LABEL_ID_DESC',
  TaskLabelsConnectionStddevSampleTaskIdAsc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskLabelsConnectionStddevSampleTaskIdDesc = 'TASK_LABELS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskLabelsConnectionSumLabelIdAsc = 'TASK_LABELS_CONNECTION_SUM_LABEL_ID_ASC',
  TaskLabelsConnectionSumLabelIdDesc = 'TASK_LABELS_CONNECTION_SUM_LABEL_ID_DESC',
  TaskLabelsConnectionSumTaskIdAsc = 'TASK_LABELS_CONNECTION_SUM_TASK_ID_ASC',
  TaskLabelsConnectionSumTaskIdDesc = 'TASK_LABELS_CONNECTION_SUM_TASK_ID_DESC',
  TaskLabelsConnectionVariancePopulationLabelIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_ASC',
  TaskLabelsConnectionVariancePopulationLabelIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_LABEL_ID_DESC',
  TaskLabelsConnectionVariancePopulationTaskIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskLabelsConnectionVariancePopulationTaskIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskLabelsConnectionVarianceSampleLabelIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_ASC',
  TaskLabelsConnectionVarianceSampleLabelIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_LABEL_ID_DESC',
  TaskLabelsConnectionVarianceSampleTaskIdAsc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskLabelsConnectionVarianceSampleTaskIdDesc = 'TASK_LABELS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskTemplateUsersConnectionArrayAggTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskTemplateUsersConnectionArrayAggTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskTemplateUsersConnectionArrayAggWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  TaskTemplateUsersConnectionArrayAggWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  TaskTemplateUsersConnectionAverageTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskTemplateUsersConnectionAverageTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskTemplateUsersConnectionAverageWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionAverageWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_WORKER_ID_DESC',
  TaskTemplateUsersConnectionCountAsc = 'TASK_TEMPLATE_USERS_CONNECTION_COUNT_ASC',
  TaskTemplateUsersConnectionCountDesc = 'TASK_TEMPLATE_USERS_CONNECTION_COUNT_DESC',
  TaskTemplateUsersConnectionDistinctCountTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskTemplateUsersConnectionDistinctCountTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskTemplateUsersConnectionDistinctCountWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  TaskTemplateUsersConnectionDistinctCountWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  TaskTemplateUsersConnectionMaxTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_TASK_ID_ASC',
  TaskTemplateUsersConnectionMaxTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_TASK_ID_DESC',
  TaskTemplateUsersConnectionMaxWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_WORKER_ID_ASC',
  TaskTemplateUsersConnectionMaxWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_WORKER_ID_DESC',
  TaskTemplateUsersConnectionMinTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_TASK_ID_ASC',
  TaskTemplateUsersConnectionMinTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_TASK_ID_DESC',
  TaskTemplateUsersConnectionMinWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_WORKER_ID_ASC',
  TaskTemplateUsersConnectionMinWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_WORKER_ID_DESC',
  TaskTemplateUsersConnectionStddevPopulationTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskTemplateUsersConnectionStddevPopulationTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskTemplateUsersConnectionStddevPopulationWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  TaskTemplateUsersConnectionStddevPopulationWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  TaskTemplateUsersConnectionStddevSampleTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskTemplateUsersConnectionStddevSampleTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskTemplateUsersConnectionStddevSampleWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionStddevSampleWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  TaskTemplateUsersConnectionSumTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_TASK_ID_ASC',
  TaskTemplateUsersConnectionSumTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_TASK_ID_DESC',
  TaskTemplateUsersConnectionSumWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_WORKER_ID_ASC',
  TaskTemplateUsersConnectionSumWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_WORKER_ID_DESC',
  TaskTemplateUsersConnectionVariancePopulationTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskTemplateUsersConnectionVariancePopulationTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskTemplateUsersConnectionVariancePopulationWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  TaskTemplateUsersConnectionVariancePopulationWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  TaskTemplateUsersConnectionVarianceSampleTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskTemplateUsersConnectionVarianceSampleTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskTemplateUsersConnectionVarianceSampleWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionVarianceSampleWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC',
  TemplateAssigneePropertyIdAsc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TemplateAssigneePropertyIdDesc = 'TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TemplatePropertyIdsAsc = 'TEMPLATE_PROPERTY_IDS_ASC',
  TemplatePropertyIdsDesc = 'TEMPLATE_PROPERTY_IDS_DESC',
  TemplateTaskIdAsc = 'TEMPLATE_TASK_ID_ASC',
  TemplateTaskIdDesc = 'TEMPLATE_TASK_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  VirtualPropertiesAsc = 'VIRTUAL_PROPERTIES_ASC',
  VirtualPropertiesDesc = 'VIRTUAL_PROPERTIES_DESC'
}

export type Team = Node & {
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportTeamIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportTeamIdAndCompanyIdList: Array<Company>;
  /** Reads a single `Company` that is related to this `Team`. */
  company: Maybe<Company>;
  companyId: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinationsByTeamId: Array<CompanyNotificationSettingsTeamDestination>;
  /** Reads and enables pagination through a set of `CompanyNotificationSettingsTeamDestination`. */
  companyNotificationSettingsTeamDestinationsByTeamIdConnection: CompanyNotificationSettingsTeamDestinationsConnection;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Reads a single `User` that is related to this `Team`. */
  createdByUser: Maybe<User>;
  /** Reads and enables pagination through a set of `DashboardSharedTeam`. */
  dashboardSharedTeamsByTeamId: Array<DashboardSharedTeam>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByDashboardSharedTeamTeamIdAndDashboardId: TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByDashboardSharedTeamTeamIdAndDashboardIdList: Array<Dashboard>;
  description: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `FilterToTeam`. */
  filterToTeamsByTeamId: Array<FilterToTeam>;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToTeamTeamIdAndFilterId: TeamFiltersByFilterToTeamTeamIdAndFilterIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Filter`. */
  filtersByFilterToTeamTeamIdAndFilterIdList: Array<Filter>;
  /** Reads and enables pagination through a set of `GeolocationUserSetting`. */
  geolocationUserSettingsByTeamId: Array<GeolocationUserSetting>;
  id: Scalars['Int']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportTeamIdAndJurisdictionId: TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportTeamIdAndJurisdictionIdList: Array<Jurisdiction>;
  name: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  privilegeAll: Maybe<Scalars['String']['output']>;
  privilegeOwn: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `ProjectColumnAccess`. */
  projectColumnAccesses: Array<ProjectColumnAccess>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembers: Array<ProjectMember>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportTeamIdAndParentProjectId: TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportTeamIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportTeamIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportTeamIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectMemberTeamIdAndProjectId: TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectMemberTeamIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportTeamIdAndProjectId: TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportTeamIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `User`. */
  teamUsers: Array<User>;
  /** Reads and enables pagination through a set of `User`. */
  teamUsersConnection: TeamTeamUsersConnectionManyToManyConnection;
  updatedAt: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `UserTeam`. */
  userTeamsByTeamId: Array<UserTeam>;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndCreatedBy: TeamUsersByProjectReportTeamIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndOwnerId: TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndProjectManagerId: TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndSalesRepId: TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndUserId: TeamUsersByProjectReportTeamIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportTeamIdAndWorkerValue: TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyConnection;
};


export type TeamBlueprintsByProjectReportTeamIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type TeamCompaniesByProjectReportTeamIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type TeamCompanyNotificationSettingsTeamDestinationsByTeamIdArgs = {
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type TeamCompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CompanyNotificationSettingsTeamDestinationCondition>;
  filter: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyNotificationSettingsTeamDestinationsOrderBy>>;
};


export type TeamDashboardSharedTeamsByTeamIdArgs = {
  condition: InputMaybe<DashboardSharedTeamCondition>;
  filter: InputMaybe<DashboardSharedTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardSharedTeamsOrderBy>>;
};


export type TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};


export type TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdListArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type TeamFilterToTeamsByTeamIdArgs = {
  condition: InputMaybe<FilterToTeamCondition>;
  filter: InputMaybe<FilterToTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterToTeamsOrderBy>>;
};


export type TeamFiltersByFilterToTeamTeamIdAndFilterIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FiltersOrderBy>>;
};


export type TeamFiltersByFilterToTeamTeamIdAndFilterIdListArgs = {
  condition: InputMaybe<FilterCondition>;
  filter: InputMaybe<FilterFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FiltersOrderBy>>;
};


export type TeamGeolocationUserSettingsByTeamIdArgs = {
  condition: InputMaybe<GeolocationUserSettingCondition>;
  filter: InputMaybe<GeolocationUserSettingFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<GeolocationUserSettingsOrderBy>>;
};


export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type TeamProjectColumnAccessesArgs = {
  condition: InputMaybe<ProjectColumnAccessCondition>;
  filter: InputMaybe<ProjectColumnAccessFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectColumnAccessesOrderBy>>;
};


export type TeamProjectMembersArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type TeamProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type TeamProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type TeamProjectStagesByProjectReportTeamIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type TeamProjectStagesByProjectReportTeamIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type TeamProjectsByProjectMemberTeamIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type TeamProjectsByProjectMemberTeamIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type TeamProjectsByProjectReportTeamIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type TeamProjectsByProjectReportTeamIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type TeamTeamUsersArgs = {
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamTeamUsersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUserTeamsByTeamIdArgs = {
  condition: InputMaybe<UserTeamCondition>;
  filter: InputMaybe<UserTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type TeamUsersByProjectReportTeamIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type TeamAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<TeamArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<TeamAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<TeamDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<TeamMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<TeamMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<TeamStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<TeamStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<TeamSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<TeamVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<TeamVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Team` object types. */
export type TeamAggregatesFilter = {
  /** Array aggregation aggregate over matching `Team` objects. */
  arrayAgg: InputMaybe<TeamArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `Team` objects. */
  average: InputMaybe<TeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Team` objects. */
  distinctCount: InputMaybe<TeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Team` object to be included within the aggregate. */
  filter: InputMaybe<TeamFilter>;
  /** Maximum aggregate over matching `Team` objects. */
  max: InputMaybe<TeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `Team` objects. */
  min: InputMaybe<TeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Team` objects. */
  stddevPopulation: InputMaybe<TeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Team` objects. */
  stddevSample: InputMaybe<TeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Team` objects. */
  sum: InputMaybe<TeamSumAggregateFilter>;
  /** Population variance aggregate over matching `Team` objects. */
  variancePopulation: InputMaybe<TeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Team` objects. */
  varianceSample: InputMaybe<TeamVarianceSampleAggregateFilter>;
};

export type TeamArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  createdBy: InputMaybe<IntListFilter>;
  id: InputMaybe<IntListFilter>;
  imageUrl: InputMaybe<StringListFilter>;
  privilegeAll: InputMaybe<StringListFilter>;
  privilegeOwn: InputMaybe<StringListFilter>;
};

export type TeamArrayAggAggregates = {
  /** Array aggregation of companyId across the matching connection */
  companyId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of createdBy across the matching connection */
  createdBy: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of imageUrl across the matching connection */
  imageUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeAll across the matching connection */
  privilegeAll: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of privilegeOwn across the matching connection */
  privilegeOwn: Array<Maybe<Scalars['String']['output']>>;
};


export type TeamArrayAggAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamArrayAggAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamArrayAggAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamArrayAggAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamArrayAggAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type TeamAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type TeamAverageAggregates = {
  /** Mean average of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type TeamAverageAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamAverageAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

/** A condition to be used against `Team` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TeamCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  imageUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Dashboard` values, with data from `DashboardSharedTeam`. */
export type TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<DashboardAggregates>;
  /** A list of edges which contains the `Dashboard`, info from the `DashboardSharedTeam`, and the cursor to aid in pagination. */
  edges: Array<TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<DashboardAggregates>>;
  /** A list of `Dashboard` objects. */
  nodes: Array<Maybe<Dashboard>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Dashboard` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Dashboard` values, with data from `DashboardSharedTeam`. */
export type TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<DashboardGroupBy>;
  having: InputMaybe<DashboardHavingInput>;
};

/** A `Dashboard` edge in the connection, with data from `DashboardSharedTeam`. */
export type TeamDashboardsByDashboardSharedTeamTeamIdAndDashboardIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Dashboard` at the end of the edge. */
  node: Maybe<Dashboard>;
};

export type TeamDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  description: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  imageUrl: InputMaybe<BigIntFilter>;
  name: InputMaybe<BigIntFilter>;
  privilegeAll: InputMaybe<BigIntFilter>;
  privilegeOwn: InputMaybe<BigIntFilter>;
  updatedAt: InputMaybe<BigIntFilter>;
};

export type TeamDistinctCountAggregates = {
  /** Distinct count of companyId across the matching connection */
  companyId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of imageUrl across the matching connection */
  imageUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeAll across the matching connection */
  privilegeAll: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of privilegeOwn across the matching connection */
  privilegeOwn: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['BigInt']['output']>;
};


export type TeamDistinctCountAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesDescriptionArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesImageUrlArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesNameArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesPrivilegeAllArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesPrivilegeOwnArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamDistinctCountAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

/** A filter to be used against `Team` object types. All fields are combined with a logical ‘and.’ */
export type TeamFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `company` relation. */
  company: InputMaybe<CompanyFilter>;
  /** A related `company` exists. */
  companyExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `companyNotificationSettingsTeamDestinationsByTeamId` relation. */
  companyNotificationSettingsTeamDestinationsByTeamId: InputMaybe<TeamToManyCompanyNotificationSettingsTeamDestinationFilter>;
  /** Some related `companyNotificationSettingsTeamDestinationsByTeamId` exist. */
  companyNotificationSettingsTeamDestinationsByTeamIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser: InputMaybe<UserFilter>;
  /** A related `createdByUser` exists. */
  createdByUserExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboardSharedTeamsByTeamId` relation. */
  dashboardSharedTeamsByTeamId: InputMaybe<TeamToManyDashboardSharedTeamFilter>;
  /** Some related `dashboardSharedTeamsByTeamId` exist. */
  dashboardSharedTeamsByTeamIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `description` field. */
  description: InputMaybe<StringFilter>;
  /** Filter by the object’s `filterToTeamsByTeamId` relation. */
  filterToTeamsByTeamId: InputMaybe<TeamToManyFilterToTeamFilter>;
  /** Some related `filterToTeamsByTeamId` exist. */
  filterToTeamsByTeamIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `geolocationUserSettingsByTeamId` relation. */
  geolocationUserSettingsByTeamId: InputMaybe<TeamToManyGeolocationUserSettingFilter>;
  /** Some related `geolocationUserSettingsByTeamId` exist. */
  geolocationUserSettingsByTeamIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `imageUrl` field. */
  imageUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<TeamFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `privilegeAll` field. */
  privilegeAll: InputMaybe<StringFilter>;
  /** Filter by the object’s `privilegeOwn` field. */
  privilegeOwn: InputMaybe<StringFilter>;
  /** Filter by the object’s `projectColumnAccesses` relation. */
  projectColumnAccesses: InputMaybe<TeamToManyProjectColumnAccessFilter>;
  /** Some related `projectColumnAccesses` exist. */
  projectColumnAccessesExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectMembers` relation. */
  projectMembers: InputMaybe<TeamToManyProjectMemberFilter>;
  /** Some related `projectMembers` exist. */
  projectMembersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReports` relation. */
  projectReports: InputMaybe<TeamToManyProjectReportFilter>;
  /** Some related `projectReports` exist. */
  projectReportsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userTeamsByTeamId` relation. */
  userTeamsByTeamId: InputMaybe<TeamToManyUserTeamFilter>;
  /** Some related `userTeamsByTeamId` exist. */
  userTeamsByTeamIdExist: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `Filter` values, with data from `FilterToTeam`. */
export type TeamFiltersByFilterToTeamTeamIdAndFilterIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterAggregates>;
  /** A list of edges which contains the `Filter`, info from the `FilterToTeam`, and the cursor to aid in pagination. */
  edges: Array<TeamFiltersByFilterToTeamTeamIdAndFilterIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterAggregates>>;
  /** A list of `Filter` objects. */
  nodes: Array<Maybe<Filter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Filter` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Filter` values, with data from `FilterToTeam`. */
export type TeamFiltersByFilterToTeamTeamIdAndFilterIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterGroupBy>;
  having: InputMaybe<FilterHavingInput>;
};

/** A `Filter` edge in the connection, with data from `FilterToTeam`. */
export type TeamFiltersByFilterToTeamTeamIdAndFilterIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Filter` at the end of the edge. */
  node: Maybe<Filter>;
};

/** Grouping methods for `Team` for usage during aggregation. */
export enum TeamGroupBy {
  CompanyId = 'COMPANY_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedAtTruncatedToMonth = 'CREATED_AT_TRUNCATED_TO_MONTH',
  CreatedAtTruncatedToQuarter = 'CREATED_AT_TRUNCATED_TO_QUARTER',
  CreatedAtTruncatedToWeek = 'CREATED_AT_TRUNCATED_TO_WEEK',
  CreatedAtTruncatedToYear = 'CREATED_AT_TRUNCATED_TO_YEAR',
  CreatedBy = 'CREATED_BY',
  Description = 'DESCRIPTION',
  Id = 'ID',
  IdProjectColumnValueDropdownValue = 'ID_PROJECT_COLUMN_VALUE_DROPDOWN_VALUE',
  ImageUrl = 'IMAGE_URL',
  Name = 'NAME',
  PrivilegeAll = 'PRIVILEGE_ALL',
  PrivilegeOwn = 'PRIVILEGE_OWN',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UpdatedAtTruncatedToMonth = 'UPDATED_AT_TRUNCATED_TO_MONTH',
  UpdatedAtTruncatedToQuarter = 'UPDATED_AT_TRUNCATED_TO_QUARTER',
  UpdatedAtTruncatedToWeek = 'UPDATED_AT_TRUNCATED_TO_WEEK',
  UpdatedAtTruncatedToYear = 'UPDATED_AT_TRUNCATED_TO_YEAR'
}

export type TeamHavingArrayAggInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingAverageInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingDistinctCountInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Team` aggregates. */
export type TeamHavingInput = {
  AND: InputMaybe<Array<TeamHavingInput>>;
  OR: InputMaybe<Array<TeamHavingInput>>;
  arrayAgg: InputMaybe<TeamHavingArrayAggInput>;
  average: InputMaybe<TeamHavingAverageInput>;
  distinctCount: InputMaybe<TeamHavingDistinctCountInput>;
  max: InputMaybe<TeamHavingMaxInput>;
  min: InputMaybe<TeamHavingMinInput>;
  stddevPopulation: InputMaybe<TeamHavingStddevPopulationInput>;
  stddevSample: InputMaybe<TeamHavingStddevSampleInput>;
  sum: InputMaybe<TeamHavingSumInput>;
  variancePopulation: InputMaybe<TeamHavingVariancePopulationInput>;
  varianceSample: InputMaybe<TeamHavingVarianceSampleInput>;
};

export type TeamHavingMaxInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingMinInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingStddevPopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingStddevSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingSumInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingVariancePopulationInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export type TeamHavingVarianceSampleInput = {
  companyId: InputMaybe<HavingIntFilter>;
  createdAt: InputMaybe<HavingDatetimeFilter>;
  createdBy: InputMaybe<HavingIntFilter>;
  id: InputMaybe<HavingIntFilter>;
  updatedAt: InputMaybe<HavingDatetimeFilter>;
};

export enum TeamIdBy {
  CreatedBy = 'createdBy',
  OwnerId = 'ownerId',
  ProjectManagerId = 'projectManagerId',
  SalesRepId = 'salesRepId',
  WorkerValue = 'workerValue'
}

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type TeamJurisdictionsByProjectReportTeamIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type TeamMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type TeamMaxAggregates = {
  /** Maximum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Maximum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Maximum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Maximum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type TeamMaxAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMaxAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMaxAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMaxAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type TeamMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  createdBy: InputMaybe<IntFilter>;
  id: InputMaybe<IntFilter>;
  updatedAt: InputMaybe<DatetimeFilter>;
};

export type TeamMinAggregates = {
  /** Minimum of companyId across the matching connection */
  companyId: Maybe<Scalars['Int']['output']>;
  /** Minimum of createdAt across the matching connection */
  createdAt: Maybe<Scalars['Datetime']['output']>;
  /** Minimum of createdBy across the matching connection */
  createdBy: Maybe<Scalars['Int']['output']>;
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
  /** Minimum of updatedAt across the matching connection */
  updatedAt: Maybe<Scalars['Datetime']['output']>;
};


export type TeamMinAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMinAggregatesCreatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMinAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamMinAggregatesUpdatedAtArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectReportsByProjectReportTeamIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectMember`. */
export type TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectMember`, and the cursor to aid in pagination. */
  edges: Array<TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectMember`. */
export type TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectMember`. */
export type TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembers: Array<ProjectMember>;
};


/** A `Project` edge in the connection, with data from `ProjectMember`. */
export type TeamProjectsByProjectMemberTeamIdAndProjectIdManyToManyEdgeProjectMembersArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type TeamProjectsByProjectReportTeamIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type TeamStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type TeamStddevPopulationAggregates = {
  /** Population standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type TeamStddevPopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamStddevPopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type TeamStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type TeamStddevSampleAggregates = {
  /** Sample standard deviation of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type TeamStddevSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamStddevSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type TeamSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdBy: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
};

export type TeamSumAggregates = {
  /** Sum of companyId across the matching connection */
  companyId: Scalars['BigInt']['output'];
  /** Sum of createdBy across the matching connection */
  createdBy: Scalars['BigInt']['output'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type TeamSumAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamSumAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `UserTeam`. */
export type TeamTeamUsersConnectionManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `UserTeam`, and the cursor to aid in pagination. */
  edges: Array<TeamTeamUsersConnectionManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `UserTeam`. */
export type TeamTeamUsersConnectionManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `UserTeam`. */
export type TeamTeamUsersConnectionManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};

/** A filter to be used against many `CompanyNotificationSettingsTeamDestination` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyCompanyNotificationSettingsTeamDestinationFilter = {
  /** Aggregates across related `CompanyNotificationSettingsTeamDestination` match the filter criteria. */
  aggregates: InputMaybe<CompanyNotificationSettingsTeamDestinationAggregatesFilter>;
  /** Every related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** No related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
  /** Some related `CompanyNotificationSettingsTeamDestination` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyNotificationSettingsTeamDestinationFilter>;
};

/** A filter to be used against many `DashboardSharedTeam` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyDashboardSharedTeamFilter = {
  /** Aggregates across related `DashboardSharedTeam` match the filter criteria. */
  aggregates: InputMaybe<DashboardSharedTeamAggregatesFilter>;
  /** Every related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardSharedTeamFilter>;
  /** No related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardSharedTeamFilter>;
  /** Some related `DashboardSharedTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardSharedTeamFilter>;
};

/** A filter to be used against many `FilterToTeam` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyFilterToTeamFilter = {
  /** Aggregates across related `FilterToTeam` match the filter criteria. */
  aggregates: InputMaybe<FilterToTeamAggregatesFilter>;
  /** Every related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FilterToTeamFilter>;
  /** No related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FilterToTeamFilter>;
  /** Some related `FilterToTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FilterToTeamFilter>;
};

/** A filter to be used against many `GeolocationUserSetting` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyGeolocationUserSettingFilter = {
  /** Aggregates across related `GeolocationUserSetting` match the filter criteria. */
  aggregates: InputMaybe<GeolocationUserSettingAggregatesFilter>;
  /** Every related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<GeolocationUserSettingFilter>;
  /** No related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<GeolocationUserSettingFilter>;
  /** Some related `GeolocationUserSetting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<GeolocationUserSettingFilter>;
};

/** A filter to be used against many `ProjectColumnAccess` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyProjectColumnAccessFilter = {
  /** Aggregates across related `ProjectColumnAccess` match the filter criteria. */
  aggregates: InputMaybe<ProjectColumnAccessAggregatesFilter>;
  /** Every related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectColumnAccessFilter>;
  /** No related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectColumnAccessFilter>;
  /** Some related `ProjectColumnAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectColumnAccessFilter>;
};

/** A filter to be used against many `ProjectMember` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyProjectMemberFilter = {
  /** Aggregates across related `ProjectMember` match the filter criteria. */
  aggregates: InputMaybe<ProjectMemberAggregatesFilter>;
  /** Every related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectMemberFilter>;
  /** No related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectMemberFilter>;
  /** Some related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectMemberFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `UserTeam` object types. All fields are combined with a logical ‘and.’ */
export type TeamToManyUserTeamFilter = {
  /** Aggregates across related `UserTeam` match the filter criteria. */
  aggregates: InputMaybe<UserTeamAggregatesFilter>;
  /** Every related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<UserTeamFilter>;
  /** No related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<UserTeamFilter>;
  /** Some related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<UserTeamFilter>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type TeamUsersByProjectReportTeamIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

export type TeamVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type TeamVariancePopulationAggregates = {
  /** Population variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type TeamVariancePopulationAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamVariancePopulationAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

export type TeamVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  createdBy: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
};

export type TeamVarianceSampleAggregates = {
  /** Sample variance of companyId across the matching connection */
  companyId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of createdBy across the matching connection */
  createdBy: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type TeamVarianceSampleAggregatesCompanyIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamVarianceSampleAggregatesCreatedByArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type TeamVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};

/** A connection to a list of `Team` values. */
export type TeamsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team` and cursor to aid in pagination. */
  edges: Array<TeamsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values. */
export type TeamsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection. */
export type TeamsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
};

/** Methods to use when ordering `Team`. */
export enum TeamsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionArrayAggUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionAverageUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionCountAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_COUNT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionCountDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_COUNT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionDistinctCountUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMaxUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionMinUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionStddevSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionSumUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleCompanyIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleCompanyIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleCreatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleCreatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleEntityTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleEntityTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleEventTypeAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleEventTypeDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleTeamIdAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleTeamIdDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyNotificationSettingsTeamDestinationsByTeamIdConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_NOTIFICATION_SETTINGS_TEAM_DESTINATIONS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  DashboardSharedTeamsByTeamIdConnectionArrayAggDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionArrayAggDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionArrayAggTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionArrayAggTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionAverageDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionAverageDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionAverageTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionAverageTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionCountAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_ASC',
  DashboardSharedTeamsByTeamIdConnectionCountDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_DESC',
  DashboardSharedTeamsByTeamIdConnectionDistinctCountDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionDistinctCountDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionDistinctCountTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionDistinctCountTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionMaxDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MAX_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionMaxDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MAX_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionMaxTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionMaxTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionMinDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MIN_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionMinDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MIN_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionMinTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionMinTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionStddevPopulationDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionStddevPopulationDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionStddevPopulationTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionStddevPopulationTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionStddevSampleDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionStddevSampleDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionStddevSampleTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionStddevSampleTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionSumDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_SUM_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionSumDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_SUM_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionSumTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionSumTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionVariancePopulationDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionVariancePopulationDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionVariancePopulationTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionVariancePopulationTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionVarianceSampleDashboardIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionVarianceSampleDashboardIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_DASHBOARD_ID_DESC',
  DashboardSharedTeamsByTeamIdConnectionVarianceSampleTeamIdAsc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  DashboardSharedTeamsByTeamIdConnectionVarianceSampleTeamIdDesc = 'DASHBOARD_SHARED_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FilterToTeamsByTeamIdConnectionArrayAggFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionArrayAggFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionArrayAggTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionArrayAggTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionAverageFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionAverageFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionAverageTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionAverageTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionCountAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_ASC',
  FilterToTeamsByTeamIdConnectionCountDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_DESC',
  FilterToTeamsByTeamIdConnectionDistinctCountFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionDistinctCountFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionDistinctCountTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionDistinctCountTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionMaxFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MAX_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionMaxFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MAX_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionMaxTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionMaxTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionMinFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MIN_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionMinFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MIN_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionMinTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionMinTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionStddevPopulationFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionStddevPopulationFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionStddevPopulationTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionStddevPopulationTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionStddevSampleFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionStddevSampleFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionStddevSampleTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionStddevSampleTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionSumFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_SUM_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionSumFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_SUM_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionSumTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionSumTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionVariancePopulationFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionVariancePopulationFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionVariancePopulationTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionVariancePopulationTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  FilterToTeamsByTeamIdConnectionVarianceSampleFilterIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_ASC',
  FilterToTeamsByTeamIdConnectionVarianceSampleFilterIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_FILTER_ID_DESC',
  FilterToTeamsByTeamIdConnectionVarianceSampleTeamIdAsc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  FilterToTeamsByTeamIdConnectionVarianceSampleTeamIdDesc = 'FILTER_TO_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionArrayAggTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionAverageTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionAverageTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionCountAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_COUNT_ASC',
  GeolocationUserSettingsByTeamIdConnectionCountDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_COUNT_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionDistinctCountTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMaxTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMaxTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionMinTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionMinTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevPopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionStddevSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionSumTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionSumTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVariancePopulationTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleCompanyIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleCompanyIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleCreatedAtAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleCreatedAtDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleEnabledAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ENABLED_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleEnabledDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ENABLED_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleRoleIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleRoleIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleTeamIdAsc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  GeolocationUserSettingsByTeamIdConnectionVarianceSampleTeamIdDesc = 'GEOLOCATION_USER_SETTINGS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivilegeAllAsc = 'PRIVILEGE_ALL_ASC',
  PrivilegeAllDesc = 'PRIVILEGE_ALL_DESC',
  PrivilegeOwnAsc = 'PRIVILEGE_OWN_ASC',
  PrivilegeOwnDesc = 'PRIVILEGE_OWN_DESC',
  ProjectColumnAccessesConnectionArrayAggColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionArrayAggTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionArrayAggTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectColumnAccessesConnectionArrayAggTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectColumnAccessesConnectionAverageColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionAverageColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionAverageIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ID_ASC',
  ProjectColumnAccessesConnectionAverageIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ID_DESC',
  ProjectColumnAccessesConnectionAverageRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionAverageRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionAverageTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionAverageTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionAverageTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectColumnAccessesConnectionAverageTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectColumnAccessesConnectionCountAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_COUNT_ASC',
  ProjectColumnAccessesConnectionCountDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_COUNT_DESC',
  ProjectColumnAccessesConnectionDistinctCountColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionDistinctCountTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionDistinctCountTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectColumnAccessesConnectionDistinctCountTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectColumnAccessesConnectionMaxColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionMaxColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionMaxIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ID_ASC',
  ProjectColumnAccessesConnectionMaxIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ID_DESC',
  ProjectColumnAccessesConnectionMaxRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionMaxRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionMaxTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionMaxTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionMaxTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TYPE_ASC',
  ProjectColumnAccessesConnectionMaxTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MAX_TYPE_DESC',
  ProjectColumnAccessesConnectionMinColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionMinColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionMinIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ID_ASC',
  ProjectColumnAccessesConnectionMinIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ID_DESC',
  ProjectColumnAccessesConnectionMinRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionMinRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionMinTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionMinTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionMinTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TYPE_ASC',
  ProjectColumnAccessesConnectionMinTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_MIN_TYPE_DESC',
  ProjectColumnAccessesConnectionStddevPopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionStddevPopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionStddevPopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectColumnAccessesConnectionStddevPopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectColumnAccessesConnectionStddevSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionStddevSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionStddevSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesConnectionStddevSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectColumnAccessesConnectionSumColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionSumColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionSumIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ID_ASC',
  ProjectColumnAccessesConnectionSumIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ID_DESC',
  ProjectColumnAccessesConnectionSumRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionSumRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionSumTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionSumTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionSumTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TYPE_ASC',
  ProjectColumnAccessesConnectionSumTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_SUM_TYPE_DESC',
  ProjectColumnAccessesConnectionVariancePopulationColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionVariancePopulationTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionVariancePopulationTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectColumnAccessesConnectionVariancePopulationTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectColumnAccessesConnectionVarianceSampleColumnIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleColumnIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleRoleIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleRoleIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_ROLE_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleTeamIdAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectColumnAccessesConnectionVarianceSampleTeamIdDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectColumnAccessesConnectionVarianceSampleTypeAsc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectColumnAccessesConnectionVarianceSampleTypeDesc = 'PROJECT_COLUMN_ACCESSES_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectMembersConnectionArrayAggCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectMembersConnectionArrayAggCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectMembersConnectionArrayAggCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectMembersConnectionArrayAggCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectMembersConnectionArrayAggIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectMembersConnectionArrayAggIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectMembersConnectionArrayAggMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_ID_ASC',
  ProjectMembersConnectionArrayAggMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_ID_DESC',
  ProjectMembersConnectionArrayAggMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_TYPE_ASC',
  ProjectMembersConnectionArrayAggMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_MEMBER_TYPE_DESC',
  ProjectMembersConnectionArrayAggProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectMembersConnectionArrayAggProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectMembersConnectionArrayAggTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectMembersConnectionArrayAggTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectMembersConnectionAverageCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectMembersConnectionAverageCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectMembersConnectionAverageCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectMembersConnectionAverageCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectMembersConnectionAverageIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_ID_ASC',
  ProjectMembersConnectionAverageIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_ID_DESC',
  ProjectMembersConnectionAverageMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_ID_ASC',
  ProjectMembersConnectionAverageMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_ID_DESC',
  ProjectMembersConnectionAverageMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionAverageMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionAverageProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectMembersConnectionAverageProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectMembersConnectionAverageTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectMembersConnectionAverageTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectMembersConnectionCountAsc = 'PROJECT_MEMBERS_CONNECTION_COUNT_ASC',
  ProjectMembersConnectionCountDesc = 'PROJECT_MEMBERS_CONNECTION_COUNT_DESC',
  ProjectMembersConnectionDistinctCountCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectMembersConnectionDistinctCountCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectMembersConnectionDistinctCountCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectMembersConnectionDistinctCountCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectMembersConnectionDistinctCountIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectMembersConnectionDistinctCountIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectMembersConnectionDistinctCountMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_ID_ASC',
  ProjectMembersConnectionDistinctCountMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_ID_DESC',
  ProjectMembersConnectionDistinctCountMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_ASC',
  ProjectMembersConnectionDistinctCountMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_DESC',
  ProjectMembersConnectionDistinctCountProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectMembersConnectionDistinctCountProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectMembersConnectionDistinctCountTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectMembersConnectionDistinctCountTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectMembersConnectionMaxCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectMembersConnectionMaxCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectMembersConnectionMaxCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectMembersConnectionMaxCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectMembersConnectionMaxIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_ID_ASC',
  ProjectMembersConnectionMaxIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_ID_DESC',
  ProjectMembersConnectionMaxMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_ID_ASC',
  ProjectMembersConnectionMaxMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_ID_DESC',
  ProjectMembersConnectionMaxMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_TYPE_ASC',
  ProjectMembersConnectionMaxMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_MEMBER_TYPE_DESC',
  ProjectMembersConnectionMaxProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectMembersConnectionMaxProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectMembersConnectionMaxTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectMembersConnectionMaxTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectMembersConnectionMinCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectMembersConnectionMinCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectMembersConnectionMinCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectMembersConnectionMinCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectMembersConnectionMinIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_ID_ASC',
  ProjectMembersConnectionMinIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_ID_DESC',
  ProjectMembersConnectionMinMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_ID_ASC',
  ProjectMembersConnectionMinMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_ID_DESC',
  ProjectMembersConnectionMinMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_TYPE_ASC',
  ProjectMembersConnectionMinMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_MEMBER_TYPE_DESC',
  ProjectMembersConnectionMinProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectMembersConnectionMinProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectMembersConnectionMinTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectMembersConnectionMinTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectMembersConnectionStddevPopulationCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectMembersConnectionStddevPopulationCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectMembersConnectionStddevPopulationCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectMembersConnectionStddevPopulationCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectMembersConnectionStddevPopulationIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectMembersConnectionStddevPopulationIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectMembersConnectionStddevPopulationMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_ID_ASC',
  ProjectMembersConnectionStddevPopulationMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_ID_DESC',
  ProjectMembersConnectionStddevPopulationMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersConnectionStddevPopulationMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersConnectionStddevPopulationProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectMembersConnectionStddevPopulationProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectMembersConnectionStddevPopulationTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectMembersConnectionStddevPopulationTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectMembersConnectionStddevSampleCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectMembersConnectionStddevSampleCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectMembersConnectionStddevSampleCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectMembersConnectionStddevSampleCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectMembersConnectionStddevSampleIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectMembersConnectionStddevSampleIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectMembersConnectionStddevSampleMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersConnectionStddevSampleMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersConnectionStddevSampleMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionStddevSampleMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionStddevSampleProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersConnectionStddevSampleProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersConnectionStddevSampleTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectMembersConnectionStddevSampleTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectMembersConnectionSumCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectMembersConnectionSumCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectMembersConnectionSumCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectMembersConnectionSumCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectMembersConnectionSumIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_ID_ASC',
  ProjectMembersConnectionSumIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_ID_DESC',
  ProjectMembersConnectionSumMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_ID_ASC',
  ProjectMembersConnectionSumMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_ID_DESC',
  ProjectMembersConnectionSumMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_TYPE_ASC',
  ProjectMembersConnectionSumMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_MEMBER_TYPE_DESC',
  ProjectMembersConnectionSumProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectMembersConnectionSumProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectMembersConnectionSumTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectMembersConnectionSumTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectMembersConnectionVariancePopulationCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectMembersConnectionVariancePopulationCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectMembersConnectionVariancePopulationCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectMembersConnectionVariancePopulationCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectMembersConnectionVariancePopulationIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectMembersConnectionVariancePopulationIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectMembersConnectionVariancePopulationMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_ASC',
  ProjectMembersConnectionVariancePopulationMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_DESC',
  ProjectMembersConnectionVariancePopulationMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersConnectionVariancePopulationMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersConnectionVariancePopulationProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectMembersConnectionVariancePopulationProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectMembersConnectionVariancePopulationTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectMembersConnectionVariancePopulationTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectMembersConnectionVarianceSampleCreatedAtAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectMembersConnectionVarianceSampleCreatedAtDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectMembersConnectionVarianceSampleCreatedByAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectMembersConnectionVarianceSampleCreatedByDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectMembersConnectionVarianceSampleIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectMembersConnectionVarianceSampleIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectMembersConnectionVarianceSampleMemberIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersConnectionVarianceSampleMemberIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersConnectionVarianceSampleMemberTypeAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersConnectionVarianceSampleMemberTypeDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersConnectionVarianceSampleProjectIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersConnectionVarianceSampleProjectIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersConnectionVarianceSampleTeamIdAsc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectMembersConnectionVarianceSampleTeamIdDesc = 'PROJECT_MEMBERS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionArrayAggCityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsConnectionArrayAggCityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionArrayAggIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsConnectionArrayAggIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggStateAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsConnectionArrayAggStateDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsConnectionArrayAggUidAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsConnectionArrayAggUidDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionAverageAddressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsConnectionAverageAddressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionAverageAhjAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsConnectionAverageAhjDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionAverageCityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsConnectionAverageCityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsConnectionAverageDealValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsConnectionAverageDealValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionAverageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsConnectionAverageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionAveragePhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsConnectionAveragePhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionAverageProductNameAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionAverageProductNameDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionAverageProgressAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsConnectionAverageProgressDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionAverageRevenueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsConnectionAverageRevenueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionAverageSourceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsConnectionAverageSourceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsConnectionAverageStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageStateAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsConnectionAverageStateDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsConnectionAverageStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsConnectionAverageStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsConnectionAverageStreetAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsConnectionAverageStreetDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsConnectionAverageTextValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsConnectionAverageTextValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionAverageTitleAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsConnectionAverageTitleDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionAverageTradesAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsConnectionAverageTradesDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsConnectionAverageTradeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsConnectionAverageTradeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsConnectionAverageTypeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsConnectionAverageTypeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsConnectionAverageUidAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsConnectionAverageUidDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsConnectionAverageUserIdAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsConnectionAverageUserIdDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsConnectionCountAsc = 'PROJECT_REPORTS_CONNECTION_COUNT_ASC',
  ProjectReportsConnectionCountDesc = 'PROJECT_REPORTS_CONNECTION_COUNT_DESC',
  ProjectReportsConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMaxAddressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsConnectionMaxAddressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMaxAhjAsc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsConnectionMaxAhjDesc = 'PROJECT_REPORTS_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMaxCityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_ASC',
  ProjectReportsConnectionMaxCityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CITY_DESC',
  ProjectReportsConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsConnectionMaxDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsConnectionMaxDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMaxIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_ASC',
  ProjectReportsConnectionMaxIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ID_DESC',
  ProjectReportsConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsConnectionMaxPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMaxProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMaxProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMaxProgressAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsConnectionMaxProgressDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMaxRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsConnectionMaxRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMaxSourceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsConnectionMaxSourceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsConnectionMaxStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsConnectionMaxStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsConnectionMaxStateAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_ASC',
  ProjectReportsConnectionMaxStateDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATE_DESC',
  ProjectReportsConnectionMaxStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsConnectionMaxStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsConnectionMaxStreetAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_ASC',
  ProjectReportsConnectionMaxStreetDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_DESC',
  ProjectReportsConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsConnectionMaxTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsConnectionMaxTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMaxTitleAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsConnectionMaxTitleDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMaxTradesAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsConnectionMaxTradesDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsConnectionMaxTradeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsConnectionMaxTradeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsConnectionMaxTypeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsConnectionMaxTypeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsConnectionMaxUidAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_ASC',
  ProjectReportsConnectionMaxUidDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UID_DESC',
  ProjectReportsConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsConnectionMaxUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsConnectionMaxUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionMinAddressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsConnectionMinAddressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionMinAhjAsc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsConnectionMinAhjDesc = 'PROJECT_REPORTS_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionMinCityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_ASC',
  ProjectReportsConnectionMinCityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CITY_DESC',
  ProjectReportsConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionMinConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsConnectionMinConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsConnectionMinCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionMinCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionMinDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsConnectionMinDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsConnectionMinDealValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsConnectionMinDealValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionMinDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsConnectionMinDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionMinIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_ASC',
  ProjectReportsConnectionMinIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ID_DESC',
  ProjectReportsConnectionMinImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsConnectionMinImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsConnectionMinIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsConnectionMinIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsConnectionMinLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionMinLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionMinNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionMinNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionMinOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsConnectionMinOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionMinPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsConnectionMinPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionMinProductNameAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsConnectionMinProductNameDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsConnectionMinProgressAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsConnectionMinProgressDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsConnectionMinProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsConnectionMinProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsConnectionMinProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsConnectionMinProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsConnectionMinRevenueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsConnectionMinRevenueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionMinSourceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsConnectionMinSourceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsConnectionMinStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsConnectionMinStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsConnectionMinStateAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_ASC',
  ProjectReportsConnectionMinStateDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATE_DESC',
  ProjectReportsConnectionMinStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsConnectionMinStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsConnectionMinStreetAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_ASC',
  ProjectReportsConnectionMinStreetDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_DESC',
  ProjectReportsConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionMinTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsConnectionMinTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsConnectionMinTextValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsConnectionMinTextValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionMinTitleAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsConnectionMinTitleDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsConnectionMinTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsConnectionMinTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsConnectionMinTradesAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsConnectionMinTradesDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsConnectionMinTradeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsConnectionMinTradeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsConnectionMinTypeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsConnectionMinTypeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsConnectionMinUidAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_ASC',
  ProjectReportsConnectionMinUidDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UID_DESC',
  ProjectReportsConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsConnectionMinUserIdAsc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsConnectionMinUserIdDesc = 'PROJECT_REPORTS_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsConnectionMinZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsConnectionMinZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionSumAddressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsConnectionSumAddressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionSumAhjAsc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsConnectionSumAhjDesc = 'PROJECT_REPORTS_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionSumCityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_ASC',
  ProjectReportsConnectionSumCityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CITY_DESC',
  ProjectReportsConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionSumConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsConnectionSumConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsConnectionSumCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionSumCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionSumDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsConnectionSumDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsConnectionSumDealValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsConnectionSumDealValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionSumDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsConnectionSumDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionSumIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_ASC',
  ProjectReportsConnectionSumIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ID_DESC',
  ProjectReportsConnectionSumImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsConnectionSumImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsConnectionSumIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsConnectionSumIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsConnectionSumLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionSumLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionSumNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionSumNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionSumOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsConnectionSumOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionSumPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsConnectionSumPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionSumProductNameAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsConnectionSumProductNameDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsConnectionSumProgressAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsConnectionSumProgressDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsConnectionSumProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsConnectionSumProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsConnectionSumProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsConnectionSumProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsConnectionSumRevenueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsConnectionSumRevenueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionSumSourceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsConnectionSumSourceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsConnectionSumStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsConnectionSumStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsConnectionSumStateAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_ASC',
  ProjectReportsConnectionSumStateDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATE_DESC',
  ProjectReportsConnectionSumStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsConnectionSumStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsConnectionSumStreetAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_ASC',
  ProjectReportsConnectionSumStreetDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_DESC',
  ProjectReportsConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionSumTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsConnectionSumTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsConnectionSumTextValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsConnectionSumTextValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionSumTitleAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsConnectionSumTitleDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsConnectionSumTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsConnectionSumTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsConnectionSumTradesAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsConnectionSumTradesDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsConnectionSumTradeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsConnectionSumTradeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsConnectionSumTypeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsConnectionSumTypeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsConnectionSumUidAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_ASC',
  ProjectReportsConnectionSumUidDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UID_DESC',
  ProjectReportsConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsConnectionSumUserIdAsc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsConnectionSumUserIdDesc = 'PROJECT_REPORTS_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsConnectionSumZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsConnectionSumZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserTeamsByTeamIdConnectionArrayAggTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionArrayAggTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionArrayAggWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionArrayAggWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionAverageTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionAverageTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionAverageWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionAverageWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_AVERAGE_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionCountAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_ASC',
  UserTeamsByTeamIdConnectionCountDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_COUNT_DESC',
  UserTeamsByTeamIdConnectionDistinctCountTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionDistinctCountTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionDistinctCountWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionDistinctCountWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionMaxTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionMaxTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MAX_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionMaxWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MAX_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionMaxWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MAX_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionMinTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionMinTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MIN_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionMinWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MIN_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionMinWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_MIN_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionStddevPopulationTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionStddevPopulationTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionStddevPopulationWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionStddevPopulationWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionStddevSampleTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionStddevSampleTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionStddevSampleWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionStddevSampleWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionSumTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionSumTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_SUM_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionSumWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_SUM_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionSumWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_SUM_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionVariancePopulationTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionVariancePopulationTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionVariancePopulationWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionVariancePopulationWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  UserTeamsByTeamIdConnectionVarianceSampleTeamIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  UserTeamsByTeamIdConnectionVarianceSampleTeamIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  UserTeamsByTeamIdConnectionVarianceSampleWorkerIdAsc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  UserTeamsByTeamIdConnectionVarianceSampleWorkerIdDesc = 'USER_TEAMS_BY_TEAM_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC'
}

export type Timezone = {
  countries: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Maybe<Scalars['String']['output']>;
  utcOffset: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type User = Node & {
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCreatedByAndActionId: UserActionsByFeedCreatedByAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFeedCreatedByAndActionIdList: Array<Action>;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCreatedByAndActionId: UserActionsByFileCreatedByAndActionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Action`. */
  actionsByFileCreatedByAndActionIdList: Array<Action>;
  avatarUrl: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `BlueprintProjectStage`. */
  blueprintProjectStagesByCreatedBy: Array<BlueprintProjectStage>;
  /** Reads and enables pagination through a set of `BlueprintTask`. */
  blueprintTasksByCreatedBy: Array<BlueprintTask>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByCreatedBy: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectCreatedByAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectOwnerIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectProjectManagerIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportCreatedByAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportOwnerIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportProjectManagerIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportSalesRepIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportUserIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectReportWorkerValueAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Blueprint`. */
  blueprintsByProjectSalesRepIdAndBlueprintIdList: Array<Blueprint>;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCreatedByAndCallId: UserCallsByFeedCreatedByAndCallIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Call`. */
  callsByFeedCreatedByAndCallIdList: Array<Call>;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFeedCreatedByAndCommentId: UserCommentsByFeedCreatedByAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Comment`. */
  commentsByFileCreatedByAndCommentId: UserCommentsByFileCreatedByAndCommentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByDashboardCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectOwnerIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectProjectManagerIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportOwnerIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportProjectManagerIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportSalesRepIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportUserIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectReportWorkerValueAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByProjectSalesRepIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByReminderAssigneeIdAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByReminderCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `Company`. */
  companiesByTeamCreatedByAndCompanyIdList: Array<Company>;
  /** Reads and enables pagination through a set of `CompanyPhone`. */
  companyPhonesByCreatedBy: Array<CompanyPhone>;
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByUserIdConnection: ConsumersConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByConsumerUserIdAndContactId: UserContactsByConsumerUserIdAndContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByConsumerUserIdAndContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByCreatedBy: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByCreatedByConnection: ContactsConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCreatedByAndCreatedByContactId: UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFeedCreatedByAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileCreatedByAndCreatedByContactId: UserContactsByFileCreatedByAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByFileCreatedByAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByAndCreatedByContactId: UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByAndReferrerContactId: UserContactsByProjectCreatedByAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectCreatedByAndReferrerContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectOwnerIdAndCreatedByContactId: UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectOwnerIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectOwnerIdAndReferrerContactId: UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectOwnerIdAndReferrerContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectProjectManagerIdAndCreatedByContactId: UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectProjectManagerIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectProjectManagerIdAndReferrerContactId: UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectProjectManagerIdAndReferrerContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectSalesRepIdAndCreatedByContactId: UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectSalesRepIdAndCreatedByContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectSalesRepIdAndReferrerContactId: UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByProjectSalesRepIdAndReferrerContactIdList: Array<Contact>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedBy: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsByCreatedByConnection: DashboardsConnection;
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByFeedCreatedByAndEmailMessageIdList: Array<EmailMessage>;
  /** Reads and enables pagination through a set of `FavoriteProject`. */
  favoriteProjects: Array<FavoriteProject>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByConsumerUserIdAndFeedId: UserFeedsByConsumerUserIdAndFeedIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByConnection: FeedsConnection;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFeedCreatedByAndParentId: UserFeedsByFeedCreatedByAndParentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedBy: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByConnection: FilesConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCreatedByAndFileId: UserFilesByFeedCreatedByAndFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFeedCreatedByAndFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCreatedByAndValueFileId: UserFilesByFileCreatedByAndValueFileIdManyToManyConnection;
  /** Reads and enables pagination through a set of `File`. */
  filesByFileCreatedByAndValueFileIdList: Array<File>;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByDashboardCreatedByAndFilterFolderId: UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `FilterFolder`. */
  filterFoldersByDashboardCreatedByAndFilterFolderIdList: Array<FilterFolder>;
  firstName: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCreatedByAndFormId: UserFormsByFileCreatedByAndFormIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Form`. */
  formsByFileCreatedByAndFormIdList: Array<Form>;
  id: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByCreatedBy: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByCreatedByConnection: JurisdictionsConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCreatedByAndJurisdictionId: UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectCreatedByAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectOwnerIdAndJurisdictionId: UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectOwnerIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectProjectManagerIdAndJurisdictionId: UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectProjectManagerIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportCreatedByAndJurisdictionId: UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportCreatedByAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportOwnerIdAndJurisdictionId: UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportOwnerIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportProjectManagerIdAndJurisdictionId: UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportSalesRepIdAndJurisdictionId: UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportSalesRepIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportUserIdAndJurisdictionId: UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportUserIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportWorkerValueAndJurisdictionId: UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectReportWorkerValueAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectSalesRepIdAndJurisdictionId: UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Jurisdiction`. */
  jurisdictionsByProjectSalesRepIdAndJurisdictionIdList: Array<Jurisdiction>;
  /** Reads and enables pagination through a set of `Label`. */
  labelsByCreatedBy: Array<Label>;
  lastName: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  phone: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PrivilegedTask`. */
  privilegedTasksByFeedCreatedByAndTaskId: UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembersByCreatedBy: Array<ProjectMember>;
  /** Reads and enables pagination through a set of `ProjectMember`. */
  projectMembersByMemberId: Array<ProjectMember>;
  /** Reads and enables pagination through a set of `ProjectProperty`. */
  projectPropertiesByCreatedBy: Array<ProjectProperty>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesByWorkerValue: Array<ProjectPropertyValue>;
  /** Reads and enables pagination through a set of `ProjectPropertyValue`. */
  projectPropertyValuesByWorkerValueConnection: ProjectPropertyValuesConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportCreatedByAndParentProjectId: UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportCreatedByAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportOwnerIdAndParentProjectId: UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportOwnerIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportProjectManagerIdAndParentProjectId: UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportProjectManagerIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportSalesRepIdAndParentProjectId: UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportSalesRepIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportUserIdAndParentProjectId: UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportUserIdAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportWorkerValueAndParentProjectId: UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectReportWorkerValueAndParentProjectIdList: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByFileCreatedByAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectCreatedByAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectOwnerIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectProjectManagerIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportCreatedByAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportCreatedByAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportOwnerIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportOwnerIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportProjectManagerIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportProjectManagerIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportSalesRepIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportSalesRepIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportUserIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportUserIdAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportWorkerValueAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectReportWorkerValueAndTimelineStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `ProjectStage`. */
  projectStagesByProjectSalesRepIdAndStageIdList: Array<ProjectStage>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCreatedByAndProjectId: UserProjectsByFeedCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFeedCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCreatedByAndProjectId: UserProjectsByFileCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByFileCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCreatedByAndParentProjectId: UserProjectsByProjectCreatedByAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectCreatedByAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectOwnerIdAndParentProjectId: UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectOwnerIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectProjectManagerIdAndParentProjectId: UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectProjectManagerIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportCreatedByAndProjectId: UserProjectsByProjectReportCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportOwnerIdAndProjectId: UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportOwnerIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportProjectManagerIdAndProjectId: UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportProjectManagerIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportSalesRepIdAndProjectId: UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportSalesRepIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportUserIdAndProjectId: UserProjectsByProjectReportUserIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportUserIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportWorkerValueAndProjectId: UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectReportWorkerValueAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectSalesRepIdAndParentProjectId: UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectSalesRepIdAndParentProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderAssigneeIdAndProjectId: UserProjectsByReminderAssigneeIdAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderAssigneeIdAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderCreatedByAndProjectId: UserProjectsByReminderCreatedByAndProjectIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReminderCreatedByAndProjectIdList: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeIdConnection: RemindersConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedBy: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedByConnection: RemindersConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCreatedByAndReminderId: UserRemindersByFeedCreatedByAndReminderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByFeedCreatedByAndReminderIdList: Array<Reminder>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByCreatedBy: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFeedCreatedByAndSmsActivityIdList: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByFromUserId: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SmsActivity`. */
  smsActivitiesByToUserId: Array<SmsActivity>;
  /** Reads and enables pagination through a set of `SubTask`. */
  subTasksByCreatedBy: Array<SubTask>;
  /** Reads and enables pagination through a set of `System`. */
  systemsByFeedCreatedByAndSystemId: UserSystemsByFeedCreatedByAndSystemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `TaskTemplateUser`. */
  taskTemplateUsers: Array<TaskTemplateUser>;
  /** Reads and enables pagination through a set of `Task`. */
  tasksByCreatedByConnection: TasksConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teams: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportCreatedByAndTeamId: UserTeamsByProjectReportCreatedByAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportCreatedByAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportOwnerIdAndTeamId: UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportOwnerIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportProjectManagerIdAndTeamId: UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportProjectManagerIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportSalesRepIdAndTeamId: UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportSalesRepIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportUserIdAndTeamId: UserTeamsByProjectReportUserIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportUserIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportWorkerValueAndTeamId: UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByProjectReportWorkerValueAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByUserTeamWorkerIdAndTeamId: UserTeamsByUserTeamWorkerIdAndTeamIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByUserTeamWorkerIdAndTeamIdList: Array<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsConnection: TeamsConnection;
  /** Reads and enables pagination through a set of `UserTeam`. */
  userTeams: Array<UserTeam>;
  /** Reads and enables pagination through a set of `UserTeam`. */
  userTeamsConnection: UserTeamsConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByAndOwnerId: UserUsersByProjectCreatedByAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByAndProjectManagerId: UserUsersByProjectCreatedByAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectCreatedByAndSalesRepId: UserUsersByProjectCreatedByAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectOwnerIdAndCreatedBy: UserUsersByProjectOwnerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectOwnerIdAndProjectManagerId: UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectOwnerIdAndSalesRepId: UserUsersByProjectOwnerIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectProjectManagerIdAndCreatedBy: UserUsersByProjectProjectManagerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectProjectManagerIdAndOwnerId: UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectProjectManagerIdAndSalesRepId: UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCreatedByAndOwnerId: UserUsersByProjectReportCreatedByAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCreatedByAndProjectManagerId: UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCreatedByAndSalesRepId: UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCreatedByAndUserId: UserUsersByProjectReportCreatedByAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportCreatedByAndWorkerValue: UserUsersByProjectReportCreatedByAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportOwnerIdAndCreatedBy: UserUsersByProjectReportOwnerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportOwnerIdAndProjectManagerId: UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportOwnerIdAndSalesRepId: UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportOwnerIdAndUserId: UserUsersByProjectReportOwnerIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportOwnerIdAndWorkerValue: UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectManagerIdAndCreatedBy: UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectManagerIdAndOwnerId: UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectManagerIdAndSalesRepId: UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectManagerIdAndUserId: UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportProjectManagerIdAndWorkerValue: UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportSalesRepIdAndCreatedBy: UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportSalesRepIdAndOwnerId: UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportSalesRepIdAndProjectManagerId: UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportSalesRepIdAndUserId: UserUsersByProjectReportSalesRepIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportSalesRepIdAndWorkerValue: UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportUserIdAndCreatedBy: UserUsersByProjectReportUserIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportUserIdAndOwnerId: UserUsersByProjectReportUserIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportUserIdAndProjectManagerId: UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportUserIdAndSalesRepId: UserUsersByProjectReportUserIdAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportUserIdAndWorkerValue: UserUsersByProjectReportUserIdAndWorkerValueManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportWorkerValueAndCreatedBy: UserUsersByProjectReportWorkerValueAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportWorkerValueAndOwnerId: UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportWorkerValueAndProjectManagerId: UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportWorkerValueAndSalesRepId: UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectReportWorkerValueAndUserId: UserUsersByProjectReportWorkerValueAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectSalesRepIdAndCreatedBy: UserUsersByProjectSalesRepIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectSalesRepIdAndOwnerId: UserUsersByProjectSalesRepIdAndOwnerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByProjectSalesRepIdAndProjectManagerId: UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderAssigneeIdAndCreatedBy: UserUsersByReminderAssigneeIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReminderCreatedByAndAssigneeId: UserUsersByReminderCreatedByAndAssigneeIdManyToManyConnection;
};


export type UserActionsByFeedCreatedByAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type UserActionsByFeedCreatedByAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type UserActionsByFileCreatedByAndActionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActionsOrderBy>>;
};


export type UserActionsByFileCreatedByAndActionIdListArgs = {
  condition: InputMaybe<ActionCondition>;
  filter: InputMaybe<ActionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ActionsOrderBy>>;
};


export type UserBlueprintProjectStagesByCreatedByArgs = {
  condition: InputMaybe<BlueprintProjectStageCondition>;
  filter: InputMaybe<BlueprintProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintProjectStagesOrderBy>>;
};


export type UserBlueprintTasksByCreatedByArgs = {
  condition: InputMaybe<BlueprintTaskCondition>;
  filter: InputMaybe<BlueprintTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintTasksOrderBy>>;
};


export type UserBlueprintsByCreatedByArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectCreatedByAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectOwnerIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectProjectManagerIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportCreatedByAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportOwnerIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportProjectManagerIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportSalesRepIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportUserIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectReportWorkerValueAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserBlueprintsByProjectSalesRepIdAndBlueprintIdListArgs = {
  condition: InputMaybe<BlueprintCondition>;
  filter: InputMaybe<BlueprintFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<BlueprintsOrderBy>>;
};


export type UserCallsByFeedCreatedByAndCallIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CallsOrderBy>>;
};


export type UserCallsByFeedCreatedByAndCallIdListArgs = {
  condition: InputMaybe<CallCondition>;
  filter: InputMaybe<CallFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CallsOrderBy>>;
};


export type UserCommentsByFeedCreatedByAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type UserCommentsByFileCreatedByAndCommentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<CommentCondition>;
  filter: InputMaybe<CommentFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CommentsOrderBy>>;
};


export type UserCompaniesByDashboardCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectOwnerIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectProjectManagerIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportOwnerIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportProjectManagerIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportSalesRepIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportUserIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectReportWorkerValueAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByProjectSalesRepIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByReminderAssigneeIdAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByReminderCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompaniesByTeamCreatedByAndCompanyIdListArgs = {
  condition: InputMaybe<CompanyCondition>;
  filter: InputMaybe<CompanyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompaniesOrderBy>>;
};


export type UserCompanyPhonesByCreatedByArgs = {
  condition: InputMaybe<CompanyPhoneCondition>;
  filter: InputMaybe<CompanyPhoneFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<CompanyPhonesOrderBy>>;
};


export type UserConsumersByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};


export type UserContactsByConsumerUserIdAndContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByConsumerUserIdAndContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByCreatedByArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByFeedCreatedByAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByFeedCreatedByAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByFileCreatedByAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByFileCreatedByAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectCreatedByAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectCreatedByAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectCreatedByAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectCreatedByAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectOwnerIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectOwnerIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectOwnerIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectOwnerIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectProjectManagerIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectProjectManagerIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectSalesRepIdAndCreatedByContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectSalesRepIdAndCreatedByContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectSalesRepIdAndReferrerContactIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserContactsByProjectSalesRepIdAndReferrerContactIdListArgs = {
  condition: InputMaybe<ContactCondition>;
  filter: InputMaybe<ContactFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ContactsOrderBy>>;
};


export type UserDashboardsByCreatedByArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


export type UserDashboardsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};


export type UserEmailMessagesByFeedCreatedByAndEmailMessageIdListArgs = {
  condition: InputMaybe<EmailMessageCondition>;
  filter: InputMaybe<EmailMessageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<EmailMessagesOrderBy>>;
};


export type UserFavoriteProjectsArgs = {
  condition: InputMaybe<FavoriteProjectCondition>;
  filter: InputMaybe<FavoriteProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FavoriteProjectsOrderBy>>;
};


export type UserFeedsByConsumerUserIdAndFeedIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type UserFeedsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type UserFeedsByFeedCreatedByAndParentIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};


export type UserFilesByCreatedByArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilesByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilesByFeedCreatedByAndFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilesByFeedCreatedByAndFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilesByFileCreatedByAndValueFileIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilesByFileCreatedByAndValueFileIdListArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdListArgs = {
  condition: InputMaybe<FilterFolderCondition>;
  filter: InputMaybe<FilterFolderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilterFoldersOrderBy>>;
};


export type UserFormsByFileCreatedByAndFormIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FormsOrderBy>>;
};


export type UserFormsByFileCreatedByAndFormIdListArgs = {
  condition: InputMaybe<FormCondition>;
  filter: InputMaybe<FormFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FormsOrderBy>>;
};


export type UserJurisdictionsByCreatedByArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdListArgs = {
  condition: InputMaybe<JurisdictionCondition>;
  filter: InputMaybe<JurisdictionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<JurisdictionsOrderBy>>;
};


export type UserLabelsByCreatedByArgs = {
  condition: InputMaybe<LabelCondition>;
  filter: InputMaybe<LabelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<LabelsOrderBy>>;
};


export type UserPrivilegedTasksByFeedCreatedByAndTaskIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<PrivilegedTaskCondition>;
  filter: InputMaybe<PrivilegedTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PrivilegedTasksOrderBy>>;
};


export type UserProjectMembersByCreatedByArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type UserProjectMembersByMemberIdArgs = {
  condition: InputMaybe<ProjectMemberCondition>;
  filter: InputMaybe<ProjectMemberFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectMembersOrderBy>>;
};


export type UserProjectPropertiesByCreatedByArgs = {
  condition: InputMaybe<ProjectPropertyCondition>;
  filter: InputMaybe<ProjectPropertyFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertiesOrderBy>>;
};


export type UserProjectPropertyValuesByWorkerValueArgs = {
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type UserProjectPropertyValuesByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectPropertyValueCondition>;
  filter: InputMaybe<ProjectPropertyValueFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectPropertyValuesOrderBy>>;
};


export type UserProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportUserIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportUserIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};


export type UserProjectStagesByFileCreatedByAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectCreatedByAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectOwnerIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectProjectManagerIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportCreatedByAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportCreatedByAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportOwnerIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportOwnerIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportProjectManagerIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportProjectManagerIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportSalesRepIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportSalesRepIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportUserIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportUserIdAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportWorkerValueAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectReportWorkerValueAndTimelineStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectStagesByProjectSalesRepIdAndStageIdListArgs = {
  condition: InputMaybe<ProjectStageCondition>;
  filter: InputMaybe<ProjectStageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectStagesOrderBy>>;
};


export type UserProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByFeedCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByFeedCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByFileCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByFileCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectCreatedByAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectCreatedByAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectOwnerIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectOwnerIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectProjectManagerIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectProjectManagerIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportOwnerIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportOwnerIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportProjectManagerIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportProjectManagerIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportSalesRepIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportSalesRepIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportUserIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportUserIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportWorkerValueAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectReportWorkerValueAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectSalesRepIdAndParentProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByProjectSalesRepIdAndParentProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByReminderAssigneeIdAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByReminderAssigneeIdAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByReminderCreatedByAndProjectIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsByReminderCreatedByAndProjectIdListArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


export type UserRemindersByAssigneeIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserRemindersByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserRemindersByCreatedByArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserRemindersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserRemindersByFeedCreatedByAndReminderIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserRemindersByFeedCreatedByAndReminderIdListArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


export type UserSmsActivitiesByCreatedByArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type UserSmsActivitiesByFeedCreatedByAndSmsActivityIdListArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type UserSmsActivitiesByFromUserIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type UserSmsActivitiesByToUserIdArgs = {
  condition: InputMaybe<SmsActivityCondition>;
  filter: InputMaybe<SmsActivityFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SmsActivitiesOrderBy>>;
};


export type UserSubTasksByCreatedByArgs = {
  condition: InputMaybe<SubTaskCondition>;
  filter: InputMaybe<SubTaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<SubTasksOrderBy>>;
};


export type UserSystemsByFeedCreatedByAndSystemIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<SystemCondition>;
  filter: InputMaybe<SystemFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SystemsOrderBy>>;
};


export type UserTaskTemplateUsersArgs = {
  condition: InputMaybe<TaskTemplateUserCondition>;
  filter: InputMaybe<TaskTemplateUserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TaskTemplateUsersOrderBy>>;
};


export type UserTasksByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TaskCondition>;
  filter: InputMaybe<TaskFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TasksOrderBy>>;
};


export type UserTeamsArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportCreatedByAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportCreatedByAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportOwnerIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportOwnerIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportProjectManagerIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportProjectManagerIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportSalesRepIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportSalesRepIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportUserIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportUserIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportWorkerValueAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByProjectReportWorkerValueAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByUserTeamWorkerIdAndTeamIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsByUserTeamWorkerIdAndTeamIdListArgs = {
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<TeamCondition>;
  filter: InputMaybe<TeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserUserTeamsArgs = {
  condition: InputMaybe<UserTeamCondition>;
  filter: InputMaybe<UserTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserUserTeamsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserTeamCondition>;
  filter: InputMaybe<UserTeamFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserUsersByProjectCreatedByAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectCreatedByAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectCreatedByAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectOwnerIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectOwnerIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectOwnerIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectProjectManagerIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectProjectManagerIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectProjectManagerIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportCreatedByAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportCreatedByAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportCreatedByAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportCreatedByAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportCreatedByAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportOwnerIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportOwnerIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportOwnerIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportOwnerIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportOwnerIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportProjectManagerIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportProjectManagerIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportProjectManagerIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportProjectManagerIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportSalesRepIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportSalesRepIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportSalesRepIdAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportSalesRepIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportUserIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportUserIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportUserIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportUserIdAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportUserIdAndWorkerValueArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportWorkerValueAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportWorkerValueAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportWorkerValueAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportWorkerValueAndSalesRepIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectReportWorkerValueAndUserIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectSalesRepIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectSalesRepIdAndOwnerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByProjectSalesRepIdAndProjectManagerIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByReminderAssigneeIdAndCreatedByArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


export type UserUsersByReminderCreatedByAndAssigneeIdArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<UserCondition>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `Feed`. */
export type UserActionsByFeedCreatedByAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserActionsByFeedCreatedByAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `Feed`. */
export type UserActionsByFeedCreatedByAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `Feed`. */
export type UserActionsByFeedCreatedByAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `Feed`. */
export type UserActionsByFeedCreatedByAndActionIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Action` values, with data from `File`. */
export type UserActionsByFileCreatedByAndActionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ActionAggregates>;
  /** A list of edges which contains the `Action`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserActionsByFileCreatedByAndActionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ActionAggregates>>;
  /** A list of `Action` objects. */
  nodes: Array<Maybe<Action>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Action` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Action` values, with data from `File`. */
export type UserActionsByFileCreatedByAndActionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ActionGroupBy>;
  having: InputMaybe<ActionHavingInput>;
};

/** A `Action` edge in the connection, with data from `File`. */
export type UserActionsByFileCreatedByAndActionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Action` at the end of the edge. */
  node: Maybe<Action>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type UserActionsByFileCreatedByAndActionIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Action` edge in the connection, with data from `File`. */
export type UserActionsByFileCreatedByAndActionIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type UserAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<UserArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<UserAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<UserDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<UserMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<UserMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<UserStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<UserStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<UserSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<UserVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<UserVarianceSampleAggregates>;
};

export type UserArrayAggAggregates = {
  /** Array aggregation of avatarUrl across the matching connection */
  avatarUrl: Array<Maybe<Scalars['String']['output']>>;
  /** Array aggregation of id across the matching connection */
  id: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of phone across the matching connection */
  phone: Array<Maybe<Scalars['String']['output']>>;
};


export type UserArrayAggAggregatesAvatarUrlArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserArrayAggAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserArrayAggAggregatesPhoneArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

export type UserAverageAggregates = {
  /** Mean average of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type UserAverageAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Call` values, with data from `Feed`. */
export type UserCallsByFeedCreatedByAndCallIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CallAggregates>;
  /** A list of edges which contains the `Call`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserCallsByFeedCreatedByAndCallIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CallAggregates>>;
  /** A list of `Call` objects. */
  nodes: Array<Maybe<Call>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Call` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Call` values, with data from `Feed`. */
export type UserCallsByFeedCreatedByAndCallIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CallGroupBy>;
  having: InputMaybe<CallHavingInput>;
};

/** A `Call` edge in the connection, with data from `Feed`. */
export type UserCallsByFeedCreatedByAndCallIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Call` at the end of the edge. */
  node: Maybe<Call>;
};


/** A `Call` edge in the connection, with data from `Feed`. */
export type UserCallsByFeedCreatedByAndCallIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `Feed`. */
export type UserCommentsByFeedCreatedByAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserCommentsByFeedCreatedByAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `Feed`. */
export type UserCommentsByFeedCreatedByAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `Feed`. */
export type UserCommentsByFeedCreatedByAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsConnection: FeedsConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `Feed`. */
export type UserCommentsByFeedCreatedByAndCommentIdManyToManyEdgeFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Comment` values, with data from `File`. */
export type UserCommentsByFileCreatedByAndCommentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<CommentAggregates>;
  /** A list of edges which contains the `Comment`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserCommentsByFileCreatedByAndCommentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<CommentAggregates>>;
  /** A list of `Comment` objects. */
  nodes: Array<Maybe<Comment>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Comment` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Comment` values, with data from `File`. */
export type UserCommentsByFileCreatedByAndCommentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentGroupBy>;
  having: InputMaybe<CommentHavingInput>;
};

/** A `Comment` edge in the connection, with data from `File`. */
export type UserCommentsByFileCreatedByAndCommentIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  files: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesConnection: FilesConnection;
  /** The `Comment` at the end of the edge. */
  node: Maybe<Comment>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type UserCommentsByFileCreatedByAndCommentIdManyToManyEdgeFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Comment` edge in the connection, with data from `File`. */
export type UserCommentsByFileCreatedByAndCommentIdManyToManyEdgeFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `avatarUrl` field. */
  avatarUrl: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phone` field. */
  phone: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `Contact` values, with data from `Consumer`. */
export type UserContactsByConsumerUserIdAndContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByConsumerUserIdAndContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Consumer`. */
export type UserContactsByConsumerUserIdAndContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Consumer`. */
export type UserContactsByConsumerUserIdAndContactIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Consumer`. */
export type UserContactsByConsumerUserIdAndContactIdManyToManyEdgeConsumersConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Feed`. */
export type UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Feed`. */
export type UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Feed`. */
export type UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByCreatedByContactIdConnection: FeedsConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `Feed`. */
export type UserContactsByFeedCreatedByAndCreatedByContactIdManyToManyEdgeFeedsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `File`. */
export type UserContactsByFileCreatedByAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByFileCreatedByAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `File`. */
export type UserContactsByFileCreatedByAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `File`. */
export type UserContactsByFileCreatedByAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByCreatedByContactIdConnection: FilesConnection;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type UserContactsByFileCreatedByAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `File`. */
export type UserContactsByFileCreatedByAndCreatedByContactIdManyToManyEdgeFilesByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectCreatedByAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectCreatedByAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectCreatedByAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectCreatedByAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectOwnerIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectProjectManagerIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndCreatedByContactIdManyToManyEdgeProjectsByCreatedByContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Contact` at the end of the edge. */
  node: Maybe<Contact>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByReferrerContactIdConnection: ProjectsConnection;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Contact` edge in the connection, with data from `Project`. */
export type UserContactsByProjectSalesRepIdAndReferrerContactIdManyToManyEdgeProjectsByReferrerContactIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type UserDistinctCountAggregates = {
  /** Distinct count of avatarUrl across the matching connection */
  avatarUrl: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstName across the matching connection */
  firstName: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastName across the matching connection */
  lastName: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phone across the matching connection */
  phone: Maybe<Scalars['BigInt']['output']>;
};


export type UserDistinctCountAggregatesAvatarUrlArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserDistinctCountAggregatesEmailArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserDistinctCountAggregatesFirstNameArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserDistinctCountAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserDistinctCountAggregatesLastNameArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};


export type UserDistinctCountAggregatesPhoneArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Consumer`. */
export type UserFeedsByConsumerUserIdAndFeedIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Consumer`, and the cursor to aid in pagination. */
  edges: Array<UserFeedsByConsumerUserIdAndFeedIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Consumer`. */
export type UserFeedsByConsumerUserIdAndFeedIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Consumer`. */
export type UserFeedsByConsumerUserIdAndFeedIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Consumer`. */
  consumersByFeedIdConnection: ConsumersConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Consumer`. */
export type UserFeedsByConsumerUserIdAndFeedIdManyToManyEdgeConsumersByFeedIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ConsumerCondition>;
  filter: InputMaybe<ConsumerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ConsumersOrderBy>>;
};

/** A connection to a list of `Feed` values, with data from `Feed`. */
export type UserFeedsByFeedCreatedByAndParentIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FeedAggregates>;
  /** A list of edges which contains the `Feed`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserFeedsByFeedCreatedByAndParentIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FeedAggregates>>;
  /** A list of `Feed` objects. */
  nodes: Array<Maybe<Feed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feed` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Feed` values, with data from `Feed`. */
export type UserFeedsByFeedCreatedByAndParentIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FeedGroupBy>;
  having: InputMaybe<FeedHavingInput>;
};

/** A `Feed` edge in the connection, with data from `Feed`. */
export type UserFeedsByFeedCreatedByAndParentIdManyToManyEdge = {
  /** Reads and enables pagination through a set of `Feed`. */
  childFeedsConnection: FeedsConnection;
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Feed` at the end of the edge. */
  node: Maybe<Feed>;
};


/** A `Feed` edge in the connection, with data from `Feed`. */
export type UserFeedsByFeedCreatedByAndParentIdManyToManyEdgeChildFeedsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `Feed`. */
export type UserFilesByFeedCreatedByAndFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserFilesByFeedCreatedByAndFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `Feed`. */
export type UserFilesByFeedCreatedByAndFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `Feed`. */
export type UserFilesByFeedCreatedByAndFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByFileIdConnection: FeedsConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `Feed`. */
export type UserFilesByFeedCreatedByAndFileIdManyToManyEdgeFeedsByFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `File` values, with data from `File`. */
export type UserFilesByFileCreatedByAndValueFileIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FileAggregates>;
  /** A list of edges which contains the `File`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserFilesByFileCreatedByAndValueFileIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<Maybe<File>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `File` values, with data from `File`. */
export type UserFilesByFileCreatedByAndValueFileIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileGroupBy>;
  having: InputMaybe<FileHavingInput>;
};

/** A `File` edge in the connection, with data from `File`. */
export type UserFilesByFileCreatedByAndValueFileIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByValueFileIdConnection: FilesConnection;
  /** The `File` at the end of the edge. */
  node: Maybe<File>;
};


/** A `File` edge in the connection, with data from `File`. */
export type UserFilesByFileCreatedByAndValueFileIdManyToManyEdgeFilesByValueFileIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `File` edge in the connection, with data from `File`. */
export type UserFilesByFileCreatedByAndValueFileIdManyToManyEdgeFilesByValueFileIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `avatarUrl` field. */
  avatarUrl: InputMaybe<StringFilter>;
  /** Filter by the object’s `blueprintProjectStagesByCreatedBy` relation. */
  blueprintProjectStagesByCreatedBy: InputMaybe<UserToManyBlueprintProjectStageFilter>;
  /** Some related `blueprintProjectStagesByCreatedBy` exist. */
  blueprintProjectStagesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintTasksByCreatedBy` relation. */
  blueprintTasksByCreatedBy: InputMaybe<UserToManyBlueprintTaskFilter>;
  /** Some related `blueprintTasksByCreatedBy` exist. */
  blueprintTasksByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `blueprintsByCreatedBy` relation. */
  blueprintsByCreatedBy: InputMaybe<UserToManyBlueprintFilter>;
  /** Some related `blueprintsByCreatedBy` exist. */
  blueprintsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `companyPhonesByCreatedBy` relation. */
  companyPhonesByCreatedBy: InputMaybe<UserToManyCompanyPhoneFilter>;
  /** Some related `companyPhonesByCreatedBy` exist. */
  companyPhonesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `consumersByUserId` relation. */
  consumersByUserId: InputMaybe<UserToManyConsumerFilter>;
  /** Some related `consumersByUserId` exist. */
  consumersByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `contactsByCreatedBy` relation. */
  contactsByCreatedBy: InputMaybe<UserToManyContactFilter>;
  /** Some related `contactsByCreatedBy` exist. */
  contactsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `dashboardsByCreatedBy` relation. */
  dashboardsByCreatedBy: InputMaybe<UserToManyDashboardFilter>;
  /** Some related `dashboardsByCreatedBy` exist. */
  dashboardsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `email` field. */
  email: InputMaybe<StringFilter>;
  /** Filter by the object’s `favoriteProjects` relation. */
  favoriteProjects: InputMaybe<UserToManyFavoriteProjectFilter>;
  /** Some related `favoriteProjects` exist. */
  favoriteProjectsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `feedsByCreatedBy` relation. */
  feedsByCreatedBy: InputMaybe<UserToManyFeedFilter>;
  /** Some related `feedsByCreatedBy` exist. */
  feedsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `filesByCreatedBy` relation. */
  filesByCreatedBy: InputMaybe<UserToManyFileFilter>;
  /** Some related `filesByCreatedBy` exist. */
  filesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `firstName` field. */
  firstName: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Filter by the object’s `jurisdictionsByCreatedBy` relation. */
  jurisdictionsByCreatedBy: InputMaybe<UserToManyJurisdictionFilter>;
  /** Some related `jurisdictionsByCreatedBy` exist. */
  jurisdictionsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `labelsByCreatedBy` relation. */
  labelsByCreatedBy: InputMaybe<UserToManyLabelFilter>;
  /** Some related `labelsByCreatedBy` exist. */
  labelsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `lastName` field. */
  lastName: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `phone` field. */
  phone: InputMaybe<StringFilter>;
  /** Filter by the object’s `projectMembersByCreatedBy` relation. */
  projectMembersByCreatedBy: InputMaybe<UserToManyProjectMemberFilter>;
  /** Some related `projectMembersByCreatedBy` exist. */
  projectMembersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectMembersByMemberId` relation. */
  projectMembersByMemberId: InputMaybe<UserToManyProjectMemberFilter>;
  /** Some related `projectMembersByMemberId` exist. */
  projectMembersByMemberIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertiesByCreatedBy` relation. */
  projectPropertiesByCreatedBy: InputMaybe<UserToManyProjectPropertyFilter>;
  /** Some related `projectPropertiesByCreatedBy` exist. */
  projectPropertiesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectPropertyValuesByWorkerValue` relation. */
  projectPropertyValuesByWorkerValue: InputMaybe<UserToManyProjectPropertyValueFilter>;
  /** Some related `projectPropertyValuesByWorkerValue` exist. */
  projectPropertyValuesByWorkerValueExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByCreatedBy` relation. */
  projectReportsByCreatedBy: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsByCreatedBy` exist. */
  projectReportsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByOwnerId` relation. */
  projectReportsByOwnerId: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsByOwnerId` exist. */
  projectReportsByOwnerIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByProjectManagerId` relation. */
  projectReportsByProjectManagerId: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsByProjectManagerId` exist. */
  projectReportsByProjectManagerIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsBySalesRepId` relation. */
  projectReportsBySalesRepId: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsBySalesRepId` exist. */
  projectReportsBySalesRepIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByUserId` relation. */
  projectReportsByUserId: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsByUserId` exist. */
  projectReportsByUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectReportsByWorkerValue` relation. */
  projectReportsByWorkerValue: InputMaybe<UserToManyProjectReportFilter>;
  /** Some related `projectReportsByWorkerValue` exist. */
  projectReportsByWorkerValueExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByCreatedBy` relation. */
  projectsByCreatedBy: InputMaybe<UserToManyProjectFilter>;
  /** Some related `projectsByCreatedBy` exist. */
  projectsByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByOwnerId` relation. */
  projectsByOwnerId: InputMaybe<UserToManyProjectFilter>;
  /** Some related `projectsByOwnerId` exist. */
  projectsByOwnerIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsByProjectManagerId` relation. */
  projectsByProjectManagerId: InputMaybe<UserToManyProjectFilter>;
  /** Some related `projectsByProjectManagerId` exist. */
  projectsByProjectManagerIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `projectsBySalesRepId` relation. */
  projectsBySalesRepId: InputMaybe<UserToManyProjectFilter>;
  /** Some related `projectsBySalesRepId` exist. */
  projectsBySalesRepIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `remindersByAssigneeId` relation. */
  remindersByAssigneeId: InputMaybe<UserToManyReminderFilter>;
  /** Some related `remindersByAssigneeId` exist. */
  remindersByAssigneeIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `remindersByCreatedBy` relation. */
  remindersByCreatedBy: InputMaybe<UserToManyReminderFilter>;
  /** Some related `remindersByCreatedBy` exist. */
  remindersByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByCreatedBy` relation. */
  smsActivitiesByCreatedBy: InputMaybe<UserToManySmsActivityFilter>;
  /** Some related `smsActivitiesByCreatedBy` exist. */
  smsActivitiesByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByFromUserId` relation. */
  smsActivitiesByFromUserId: InputMaybe<UserToManySmsActivityFilter>;
  /** Some related `smsActivitiesByFromUserId` exist. */
  smsActivitiesByFromUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `smsActivitiesByToUserId` relation. */
  smsActivitiesByToUserId: InputMaybe<UserToManySmsActivityFilter>;
  /** Some related `smsActivitiesByToUserId` exist. */
  smsActivitiesByToUserIdExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `subTasksByCreatedBy` relation. */
  subTasksByCreatedBy: InputMaybe<UserToManySubTaskFilter>;
  /** Some related `subTasksByCreatedBy` exist. */
  subTasksByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `taskTemplateUsers` relation. */
  taskTemplateUsers: InputMaybe<UserToManyTaskTemplateUserFilter>;
  /** Some related `taskTemplateUsers` exist. */
  taskTemplateUsersExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `tasksByCreatedBy` relation. */
  tasksByCreatedBy: InputMaybe<UserToManyTaskFilter>;
  /** Some related `tasksByCreatedBy` exist. */
  tasksByCreatedByExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `teams` relation. */
  teams: InputMaybe<UserToManyTeamFilter>;
  /** Some related `teams` exist. */
  teamsExist: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userTeams` relation. */
  userTeams: InputMaybe<UserToManyUserTeamFilter>;
  /** Some related `userTeams` exist. */
  userTeamsExist: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of `FilterFolder` values, with data from `Dashboard`. */
export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FilterFolderAggregates>;
  /** A list of edges which contains the `FilterFolder`, info from the `Dashboard`, and the cursor to aid in pagination. */
  edges: Array<UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FilterFolderAggregates>>;
  /** A list of `FilterFolder` objects. */
  nodes: Array<Maybe<FilterFolder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilterFolder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `FilterFolder` values, with data from `Dashboard`. */
export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilterFolderGroupBy>;
  having: InputMaybe<FilterFolderHavingInput>;
};

/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboards: Array<Dashboard>;
  /** Reads and enables pagination through a set of `Dashboard`. */
  dashboardsConnection: DashboardsConnection;
  /** The `FilterFolder` at the end of the edge. */
  node: Maybe<FilterFolder>;
};


/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyEdgeDashboardsArgs = {
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<DashboardsOrderBy>>;
};


/** A `FilterFolder` edge in the connection, with data from `Dashboard`. */
export type UserFilterFoldersByDashboardCreatedByAndFilterFolderIdManyToManyEdgeDashboardsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<DashboardCondition>;
  filter: InputMaybe<DashboardFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DashboardsOrderBy>>;
};

/** A connection to a list of `Form` values, with data from `File`. */
export type UserFormsByFileCreatedByAndFormIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<FormAggregates>;
  /** A list of edges which contains the `Form`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserFormsByFileCreatedByAndFormIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<FormAggregates>>;
  /** A list of `Form` objects. */
  nodes: Array<Maybe<Form>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Form` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Form` values, with data from `File`. */
export type UserFormsByFileCreatedByAndFormIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<FormGroupBy>;
  having: InputMaybe<FormHavingInput>;
};

/** A `Form` edge in the connection, with data from `File`. */
export type UserFormsByFileCreatedByAndFormIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormId: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  filesByFormIdConnection: FilesConnection;
  /** The `Form` at the end of the edge. */
  node: Maybe<Form>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type UserFormsByFileCreatedByAndFormIdManyToManyEdgeFilesByFormIdArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Form` edge in the connection, with data from `File`. */
export type UserFormsByFileCreatedByAndFormIdManyToManyEdgeFilesByFormIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type UserGeolocationSetting = Node & {
  companyId: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['Datetime']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  roleId: Maybe<Scalars['Int']['output']>;
  teamId: Maybe<Scalars['Int']['output']>;
  /** Reads a single `MainUser` that is related to this `UserGeolocationSetting`. */
  user: Maybe<MainUser>;
  userId: Maybe<Scalars['Int']['output']>;
};

/** A filter to be used against aggregates of `UserGeolocationSetting` object types. */
export type UserGeolocationSettingAggregatesFilter = {
  /** Array aggregation aggregate over matching `UserGeolocationSetting` objects. */
  arrayAgg: InputMaybe<UserGeolocationSettingArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `UserGeolocationSetting` objects. */
  average: InputMaybe<UserGeolocationSettingAverageAggregateFilter>;
  /** Distinct count aggregate over matching `UserGeolocationSetting` objects. */
  distinctCount: InputMaybe<UserGeolocationSettingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserGeolocationSetting` object to be included within the aggregate. */
  filter: InputMaybe<UserGeolocationSettingFilter>;
  /** Maximum aggregate over matching `UserGeolocationSetting` objects. */
  max: InputMaybe<UserGeolocationSettingMaxAggregateFilter>;
  /** Minimum aggregate over matching `UserGeolocationSetting` objects. */
  min: InputMaybe<UserGeolocationSettingMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `UserGeolocationSetting` objects. */
  stddevPopulation: InputMaybe<UserGeolocationSettingStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `UserGeolocationSetting` objects. */
  stddevSample: InputMaybe<UserGeolocationSettingStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `UserGeolocationSetting` objects. */
  sum: InputMaybe<UserGeolocationSettingSumAggregateFilter>;
  /** Population variance aggregate over matching `UserGeolocationSetting` objects. */
  variancePopulation: InputMaybe<UserGeolocationSettingVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `UserGeolocationSetting` objects. */
  varianceSample: InputMaybe<UserGeolocationSettingVarianceSampleAggregateFilter>;
};

export type UserGeolocationSettingArrayAggAggregateFilter = {
  companyId: InputMaybe<IntListFilter>;
  enabled: InputMaybe<BooleanListFilter>;
  id: InputMaybe<IntListFilter>;
  roleId: InputMaybe<IntListFilter>;
  teamId: InputMaybe<IntListFilter>;
  userId: InputMaybe<IntListFilter>;
};

export type UserGeolocationSettingAverageAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/**
 * A condition to be used against `UserGeolocationSetting` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UserGeolocationSettingCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `enabled` field. */
  enabled: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId: InputMaybe<Scalars['Int']['input']>;
};

export type UserGeolocationSettingDistinctCountAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  createdAt: InputMaybe<BigIntFilter>;
  enabled: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

/** A filter to be used against `UserGeolocationSetting` object types. All fields are combined with a logical ‘and.’ */
export type UserGeolocationSettingFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<UserGeolocationSettingFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `enabled` field. */
  enabled: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not: InputMaybe<UserGeolocationSettingFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<UserGeolocationSettingFilter>>;
  /** Filter by the object’s `roleId` field. */
  roleId: InputMaybe<IntFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
  /** Filter by the object’s `user` relation. */
  user: InputMaybe<MainUserFilter>;
  /** A related `user` exists. */
  userExists: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter by the object’s `userId` field. */
  userId: InputMaybe<IntFilter>;
};

export type UserGeolocationSettingMaxAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type UserGeolocationSettingMinAggregateFilter = {
  companyId: InputMaybe<IntFilter>;
  createdAt: InputMaybe<DatetimeFilter>;
  id: InputMaybe<IntFilter>;
  roleId: InputMaybe<IntFilter>;
  teamId: InputMaybe<IntFilter>;
  userId: InputMaybe<IntFilter>;
};

export type UserGeolocationSettingStddevPopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type UserGeolocationSettingStddevSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type UserGeolocationSettingSumAggregateFilter = {
  companyId: InputMaybe<BigIntFilter>;
  id: InputMaybe<BigIntFilter>;
  roleId: InputMaybe<BigIntFilter>;
  teamId: InputMaybe<BigIntFilter>;
  userId: InputMaybe<BigIntFilter>;
};

export type UserGeolocationSettingVariancePopulationAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

export type UserGeolocationSettingVarianceSampleAggregateFilter = {
  companyId: InputMaybe<BigFloatFilter>;
  id: InputMaybe<BigFloatFilter>;
  roleId: InputMaybe<BigFloatFilter>;
  teamId: InputMaybe<BigFloatFilter>;
  userId: InputMaybe<BigFloatFilter>;
};

/** Methods to use when ordering `UserGeolocationSetting`. */
export enum UserGeolocationSettingsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EnabledAsc = 'ENABLED_ASC',
  EnabledDesc = 'ENABLED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Grouping methods for `User` for usage during aggregation. */
export enum UserGroupBy {
  AvatarUrl = 'AVATAR_URL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Phone = 'PHONE'
}

export type UserHavingArrayAggInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingAverageInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingDistinctCountInput = {
  id: InputMaybe<HavingIntFilter>;
};

/** Conditions for `User` aggregates. */
export type UserHavingInput = {
  AND: InputMaybe<Array<UserHavingInput>>;
  OR: InputMaybe<Array<UserHavingInput>>;
  arrayAgg: InputMaybe<UserHavingArrayAggInput>;
  average: InputMaybe<UserHavingAverageInput>;
  distinctCount: InputMaybe<UserHavingDistinctCountInput>;
  max: InputMaybe<UserHavingMaxInput>;
  min: InputMaybe<UserHavingMinInput>;
  stddevPopulation: InputMaybe<UserHavingStddevPopulationInput>;
  stddevSample: InputMaybe<UserHavingStddevSampleInput>;
  sum: InputMaybe<UserHavingSumInput>;
  variancePopulation: InputMaybe<UserHavingVariancePopulationInput>;
  varianceSample: InputMaybe<UserHavingVarianceSampleInput>;
};

export type UserHavingMaxInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingMinInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingStddevPopulationInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingStddevSampleInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingSumInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingVariancePopulationInput = {
  id: InputMaybe<HavingIntFilter>;
};

export type UserHavingVarianceSampleInput = {
  id: InputMaybe<HavingIntFilter>;
};

export enum UserIdBy {
  CreatedBy = 'createdBy',
  OwnerId = 'ownerId',
  ProjectManagerId = 'projectManagerId',
  SalesRepId = 'salesRepId',
  WorkerValue = 'workerValue'
}

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectCreatedByAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectOwnerIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectProjectManagerIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportCreatedByAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportOwnerIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportProjectManagerIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportSalesRepIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportUserIdAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `ProjectReport`. */
export type UserJurisdictionsByProjectReportWorkerValueAndJurisdictionIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<JurisdictionAggregates>;
  /** A list of edges which contains the `Jurisdiction`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<JurisdictionAggregates>>;
  /** A list of `Jurisdiction` objects. */
  nodes: Array<Maybe<Jurisdiction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Jurisdiction` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Jurisdiction` values, with data from `Project`. */
export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<JurisdictionGroupBy>;
  having: InputMaybe<JurisdictionHavingInput>;
};

/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Jurisdiction` at the end of the edge. */
  node: Maybe<Jurisdiction>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsConnection: ProjectsConnection;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyEdgeProjectsArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Jurisdiction` edge in the connection, with data from `Project`. */
export type UserJurisdictionsByProjectSalesRepIdAndJurisdictionIdManyToManyEdgeProjectsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

export type UserMaxAggregates = {
  /** Maximum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
};


export type UserMaxAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

export type UserMinAggregates = {
  /** Minimum of id across the matching connection */
  id: Maybe<Scalars['Int']['output']>;
};


export type UserMinAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<PrivilegedTaskAggregates>;
  /** A list of edges which contains the `PrivilegedTask`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<PrivilegedTaskAggregates>>;
  /** A list of `PrivilegedTask` objects. */
  nodes: Array<Maybe<PrivilegedTask>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivilegedTask` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PrivilegedTask` values, with data from `Feed`. */
export type UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<PrivilegedTaskGroupBy>;
  having: InputMaybe<PrivilegedTaskHavingInput>;
};

/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByTaskIdConnection: FeedsConnection;
  /** The `PrivilegedTask` at the end of the edge. */
  node: Maybe<PrivilegedTask>;
};


/** A `PrivilegedTask` edge in the connection, with data from `Feed`. */
export type UserPrivilegedTasksByFeedCreatedByAndTaskIdManyToManyEdgeFeedsByTaskIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportCreatedByAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportOwnerIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportProjectManagerIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportSalesRepIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportUserIdAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectReportAggregates>;
  /** A list of edges which contains the `ProjectReport`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectReportAggregates>>;
  /** A list of `ProjectReport` objects. */
  nodes: Array<Maybe<ProjectReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReport` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ProjectReport` values, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectReportGroupBy>;
  having: InputMaybe<ProjectReportHavingInput>;
};

/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `ProjectReport` at the end of the edge. */
  node: Maybe<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByParentProjectIdConnection: ProjectReportsConnection;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `ProjectReport` edge in the connection, with data from `ProjectReport`. */
export type UserProjectReportsByProjectReportWorkerValueAndParentProjectIdManyToManyEdgeProjectReportsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Feed`. */
export type UserProjectsByFeedCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByFeedCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Feed`. */
export type UserProjectsByFeedCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Feed`. */
export type UserProjectsByFeedCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByProjectIdConnection: FeedsConnection;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
};


/** A `Project` edge in the connection, with data from `Feed`. */
export type UserProjectsByFeedCreatedByAndProjectIdManyToManyEdgeFeedsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `File`. */
export type UserProjectsByFileCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `File`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByFileCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `File`. */
export type UserProjectsByFileCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `File`. */
export type UserProjectsByFileCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `File`. */
  projectFiles: Array<File>;
  /** Reads and enables pagination through a set of `File`. */
  projectFilesConnection: FilesConnection;
};


/** A `Project` edge in the connection, with data from `File`. */
export type UserProjectsByFileCreatedByAndProjectIdManyToManyEdgeProjectFilesArgs = {
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<FilesOrderBy>>;
};


/** A `Project` edge in the connection, with data from `File`. */
export type UserProjectsByFileCreatedByAndProjectIdManyToManyEdgeProjectFilesConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FileCondition>;
  filter: InputMaybe<FileFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectCreatedByAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectCreatedByAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectCreatedByAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectCreatedByAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectCreatedByAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectCreatedByAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectOwnerIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectProjectManagerIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportCreatedByAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportCreatedByAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportOwnerIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportProjectManagerIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportSalesRepIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportUserIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportUserIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportUserIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportUserIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportUserIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportUserIdAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `ProjectReport`. */
export type UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectIdConnection: ProjectReportsConnection;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyEdgeProjectReportsByProjectIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `ProjectReport`. */
export type UserProjectsByProjectReportWorkerValueAndProjectIdManyToManyEdgeProjectReportsByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Project`. */
export type UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByParentProjectIdConnection: ProjectsConnection;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Project`. */
export type UserProjectsByProjectSalesRepIdAndParentProjectIdManyToManyEdgeProjectsByParentProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Reminder`. */
export type UserProjectsByReminderAssigneeIdAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByReminderAssigneeIdAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Reminder`. */
export type UserProjectsByReminderAssigneeIdAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderAssigneeIdAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectIdConnection: RemindersConnection;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderAssigneeIdAndProjectIdManyToManyEdgeRemindersByProjectIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderAssigneeIdAndProjectIdManyToManyEdgeRemindersByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Project` values, with data from `Reminder`. */
export type UserProjectsByReminderCreatedByAndProjectIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ProjectAggregates>;
  /** A list of edges which contains the `Project`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<UserProjectsByReminderCreatedByAndProjectIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ProjectAggregates>>;
  /** A list of `Project` objects. */
  nodes: Array<Maybe<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Project` values, with data from `Reminder`. */
export type UserProjectsByReminderCreatedByAndProjectIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProjectGroupBy>;
  having: InputMaybe<ProjectHavingInput>;
};

/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderCreatedByAndProjectIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Project` at the end of the edge. */
  node: Maybe<Project>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByProjectIdConnection: RemindersConnection;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderCreatedByAndProjectIdManyToManyEdgeRemindersByProjectIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `Project` edge in the connection, with data from `Reminder`. */
export type UserProjectsByReminderCreatedByAndProjectIdManyToManyEdgeRemindersByProjectIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type UserRemindersByFeedCreatedByAndReminderIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<ReminderAggregates>;
  /** A list of edges which contains the `Reminder`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserRemindersByFeedCreatedByAndReminderIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<ReminderAggregates>>;
  /** A list of `Reminder` objects. */
  nodes: Array<Maybe<Reminder>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Reminder` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Reminder` values, with data from `Feed`. */
export type UserRemindersByFeedCreatedByAndReminderIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReminderGroupBy>;
  having: InputMaybe<ReminderHavingInput>;
};

/** A `Reminder` edge in the connection, with data from `Feed`. */
export type UserRemindersByFeedCreatedByAndReminderIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsByReminderIdConnection: FeedsConnection;
  /** The `Reminder` at the end of the edge. */
  node: Maybe<Reminder>;
};


/** A `Reminder` edge in the connection, with data from `Feed`. */
export type UserRemindersByFeedCreatedByAndReminderIdManyToManyEdgeFeedsByReminderIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type UserStddevPopulationAggregates = {
  /** Population standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type UserStddevPopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

export type UserStddevSampleAggregates = {
  /** Sample standard deviation of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type UserStddevSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

export type UserSumAggregates = {
  /** Sum of id across the matching connection */
  id: Scalars['BigInt']['output'];
};


export type UserSumAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `System` values, with data from `Feed`. */
export type UserSystemsByFeedCreatedByAndSystemIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<SystemAggregates>;
  /** A list of edges which contains the `System`, info from the `Feed`, and the cursor to aid in pagination. */
  edges: Array<UserSystemsByFeedCreatedByAndSystemIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<SystemAggregates>>;
  /** A list of `System` objects. */
  nodes: Array<Maybe<System>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `System` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `System` values, with data from `Feed`. */
export type UserSystemsByFeedCreatedByAndSystemIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<SystemGroupBy>;
  having: InputMaybe<SystemHavingInput>;
};

/** A `System` edge in the connection, with data from `Feed`. */
export type UserSystemsByFeedCreatedByAndSystemIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** Reads and enables pagination through a set of `Feed`. */
  feedsBySystemIdConnection: FeedsConnection;
  /** The `System` at the end of the edge. */
  node: Maybe<System>;
};


/** A `System` edge in the connection, with data from `Feed`. */
export type UserSystemsByFeedCreatedByAndSystemIdManyToManyEdgeFeedsBySystemIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<FeedCondition>;
  filter: InputMaybe<FeedFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeedsOrderBy>>;
};

export type UserTeam = Node & {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Team` that is related to this `UserTeam`. */
  team: Maybe<Team>;
  teamId: Scalars['Int']['output'];
  /** Reads a single `User` that is related to this `UserTeam`. */
  worker: Maybe<User>;
  workerId: Scalars['Int']['output'];
};

export type UserTeamAggregates = {
  /** Array aggregation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  arrayAgg: Maybe<UserTeamArrayAggAggregates>;
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average: Maybe<UserTeamAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount: Maybe<UserTeamDistinctCountAggregates>;
  keys: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max: Maybe<UserTeamMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min: Maybe<UserTeamMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation: Maybe<UserTeamStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample: Maybe<UserTeamStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum: Maybe<UserTeamSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation: Maybe<UserTeamVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample: Maybe<UserTeamVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `UserTeam` object types. */
export type UserTeamAggregatesFilter = {
  /** Array aggregation aggregate over matching `UserTeam` objects. */
  arrayAgg: InputMaybe<UserTeamArrayAggAggregateFilter>;
  /** Mean average aggregate over matching `UserTeam` objects. */
  average: InputMaybe<UserTeamAverageAggregateFilter>;
  /** Distinct count aggregate over matching `UserTeam` objects. */
  distinctCount: InputMaybe<UserTeamDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserTeam` object to be included within the aggregate. */
  filter: InputMaybe<UserTeamFilter>;
  /** Maximum aggregate over matching `UserTeam` objects. */
  max: InputMaybe<UserTeamMaxAggregateFilter>;
  /** Minimum aggregate over matching `UserTeam` objects. */
  min: InputMaybe<UserTeamMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `UserTeam` objects. */
  stddevPopulation: InputMaybe<UserTeamStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `UserTeam` objects. */
  stddevSample: InputMaybe<UserTeamStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `UserTeam` objects. */
  sum: InputMaybe<UserTeamSumAggregateFilter>;
  /** Population variance aggregate over matching `UserTeam` objects. */
  variancePopulation: InputMaybe<UserTeamVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `UserTeam` objects. */
  varianceSample: InputMaybe<UserTeamVarianceSampleAggregateFilter>;
};

export type UserTeamArrayAggAggregateFilter = {
  teamId: InputMaybe<IntListFilter>;
  workerId: InputMaybe<IntListFilter>;
};

export type UserTeamArrayAggAggregates = {
  /** Array aggregation of teamId across the matching connection */
  teamId: Array<Maybe<Scalars['Int']['output']>>;
  /** Array aggregation of workerId across the matching connection */
  workerId: Array<Maybe<Scalars['Int']['output']>>;
};


export type UserTeamArrayAggAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamArrayAggAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamAverageAggregateFilter = {
  teamId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type UserTeamAverageAggregates = {
  /** Mean average of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of workerId across the matching connection */
  workerId: Maybe<Scalars['BigFloat']['output']>;
};


export type UserTeamAverageAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamAverageAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

/**
 * A condition to be used against `UserTeam` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserTeamCondition = {
  /** Checks for equality with the object’s `teamId` field. */
  teamId: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `workerId` field. */
  workerId: InputMaybe<Scalars['Int']['input']>;
};

export type UserTeamDistinctCountAggregateFilter = {
  teamId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

export type UserTeamDistinctCountAggregates = {
  /** Distinct count of teamId across the matching connection */
  teamId: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of workerId across the matching connection */
  workerId: Maybe<Scalars['BigInt']['output']>;
};


export type UserTeamDistinctCountAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamDistinctCountAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

/** A filter to be used against `UserTeam` object types. All fields are combined with a logical ‘and.’ */
export type UserTeamFilter = {
  /** Checks for all expressions in this list. */
  and: InputMaybe<Array<UserTeamFilter>>;
  /** Negates the expression. */
  not: InputMaybe<UserTeamFilter>;
  /** Checks for any expressions in this list. */
  or: InputMaybe<Array<UserTeamFilter>>;
  /** Filter by the object’s `team` relation. */
  team: InputMaybe<TeamFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId: InputMaybe<IntFilter>;
  /** Filter by the object’s `worker` relation. */
  worker: InputMaybe<UserFilter>;
  /** Filter by the object’s `workerId` field. */
  workerId: InputMaybe<IntFilter>;
};

/** Grouping methods for `UserTeam` for usage during aggregation. */
export enum UserTeamGroupBy {
  TeamId = 'TEAM_ID',
  WorkerId = 'WORKER_ID'
}

export type UserTeamHavingArrayAggInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingAverageInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingDistinctCountInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

/** Conditions for `UserTeam` aggregates. */
export type UserTeamHavingInput = {
  AND: InputMaybe<Array<UserTeamHavingInput>>;
  OR: InputMaybe<Array<UserTeamHavingInput>>;
  arrayAgg: InputMaybe<UserTeamHavingArrayAggInput>;
  average: InputMaybe<UserTeamHavingAverageInput>;
  distinctCount: InputMaybe<UserTeamHavingDistinctCountInput>;
  max: InputMaybe<UserTeamHavingMaxInput>;
  min: InputMaybe<UserTeamHavingMinInput>;
  stddevPopulation: InputMaybe<UserTeamHavingStddevPopulationInput>;
  stddevSample: InputMaybe<UserTeamHavingStddevSampleInput>;
  sum: InputMaybe<UserTeamHavingSumInput>;
  variancePopulation: InputMaybe<UserTeamHavingVariancePopulationInput>;
  varianceSample: InputMaybe<UserTeamHavingVarianceSampleInput>;
};

export type UserTeamHavingMaxInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingMinInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingStddevPopulationInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingStddevSampleInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingSumInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingVariancePopulationInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamHavingVarianceSampleInput = {
  teamId: InputMaybe<HavingIntFilter>;
  workerId: InputMaybe<HavingIntFilter>;
};

export type UserTeamMaxAggregateFilter = {
  teamId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type UserTeamMaxAggregates = {
  /** Maximum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Maximum of workerId across the matching connection */
  workerId: Maybe<Scalars['Int']['output']>;
};


export type UserTeamMaxAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamMaxAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamMinAggregateFilter = {
  teamId: InputMaybe<IntFilter>;
  workerId: InputMaybe<IntFilter>;
};

export type UserTeamMinAggregates = {
  /** Minimum of teamId across the matching connection */
  teamId: Maybe<Scalars['Int']['output']>;
  /** Minimum of workerId across the matching connection */
  workerId: Maybe<Scalars['Int']['output']>;
};


export type UserTeamMinAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamMinAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamStddevPopulationAggregateFilter = {
  teamId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type UserTeamStddevPopulationAggregates = {
  /** Population standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of workerId across the matching connection */
  workerId: Maybe<Scalars['BigFloat']['output']>;
};


export type UserTeamStddevPopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamStddevPopulationAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamStddevSampleAggregateFilter = {
  teamId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type UserTeamStddevSampleAggregates = {
  /** Sample standard deviation of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of workerId across the matching connection */
  workerId: Maybe<Scalars['BigFloat']['output']>;
};


export type UserTeamStddevSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamStddevSampleAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamSumAggregateFilter = {
  teamId: InputMaybe<BigIntFilter>;
  workerId: InputMaybe<BigIntFilter>;
};

export type UserTeamSumAggregates = {
  /** Sum of teamId across the matching connection */
  teamId: Scalars['BigInt']['output'];
  /** Sum of workerId across the matching connection */
  workerId: Scalars['BigInt']['output'];
};


export type UserTeamSumAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamSumAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamVariancePopulationAggregateFilter = {
  teamId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type UserTeamVariancePopulationAggregates = {
  /** Population variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of workerId across the matching connection */
  workerId: Maybe<Scalars['BigFloat']['output']>;
};


export type UserTeamVariancePopulationAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamVariancePopulationAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

export type UserTeamVarianceSampleAggregateFilter = {
  teamId: InputMaybe<BigFloatFilter>;
  workerId: InputMaybe<BigFloatFilter>;
};

export type UserTeamVarianceSampleAggregates = {
  /** Sample variance of teamId across the matching connection */
  teamId: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of workerId across the matching connection */
  workerId: Maybe<Scalars['BigFloat']['output']>;
};


export type UserTeamVarianceSampleAggregatesTeamIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};


export type UserTeamVarianceSampleAggregatesWorkerIdArgs = {
  orderBy: InputMaybe<Array<UserTeamsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportCreatedByAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportCreatedByAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportCreatedByAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportCreatedByAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportCreatedByAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportCreatedByAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportOwnerIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportProjectManagerIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportSalesRepIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportUserIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportUserIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportUserIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportUserIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportUserIdAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportUserIdAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `ProjectReport`. */
export type UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReports: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsConnection: ProjectReportsConnection;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyEdgeProjectReportsArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `Team` edge in the connection, with data from `ProjectReport`. */
export type UserTeamsByProjectReportWorkerValueAndTeamIdManyToManyEdgeProjectReportsConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `Team` values, with data from `UserTeam`. */
export type UserTeamsByUserTeamWorkerIdAndTeamIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<TeamAggregates>;
  /** A list of edges which contains the `Team`, info from the `UserTeam`, and the cursor to aid in pagination. */
  edges: Array<UserTeamsByUserTeamWorkerIdAndTeamIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<TeamAggregates>>;
  /** A list of `Team` objects. */
  nodes: Array<Maybe<Team>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Team` values, with data from `UserTeam`. */
export type UserTeamsByUserTeamWorkerIdAndTeamIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<TeamGroupBy>;
  having: InputMaybe<TeamHavingInput>;
};

/** A `Team` edge in the connection, with data from `UserTeam`. */
export type UserTeamsByUserTeamWorkerIdAndTeamIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `Team` at the end of the edge. */
  node: Maybe<Team>;
};

/** A connection to a list of `UserTeam` values. */
export type UserTeamsConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserTeamAggregates>;
  /** A list of edges which contains the `UserTeam` and cursor to aid in pagination. */
  edges: Array<UserTeamsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserTeamAggregates>>;
  /** A list of `UserTeam` objects. */
  nodes: Array<Maybe<UserTeam>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserTeam` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `UserTeam` values. */
export type UserTeamsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserTeamGroupBy>;
  having: InputMaybe<UserTeamHavingInput>;
};

/** A `UserTeam` edge in the connection. */
export type UserTeamsEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `UserTeam` at the end of the edge. */
  node: Maybe<UserTeam>;
};

/** Methods to use when ordering `UserTeam`. */
export enum UserTeamsOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  WorkerIdAsc = 'WORKER_ID_ASC',
  WorkerIdDesc = 'WORKER_ID_DESC'
}

/** A filter to be used against many `Blueprint` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyBlueprintFilter = {
  /** Aggregates across related `Blueprint` match the filter criteria. */
  aggregates: InputMaybe<BlueprintAggregatesFilter>;
  /** Every related `Blueprint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintFilter>;
  /** No related `Blueprint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintFilter>;
  /** Some related `Blueprint` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintFilter>;
};

/** A filter to be used against many `BlueprintProjectStage` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyBlueprintProjectStageFilter = {
  /** Aggregates across related `BlueprintProjectStage` match the filter criteria. */
  aggregates: InputMaybe<BlueprintProjectStageAggregatesFilter>;
  /** Every related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintProjectStageFilter>;
  /** No related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintProjectStageFilter>;
  /** Some related `BlueprintProjectStage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintProjectStageFilter>;
};

/** A filter to be used against many `BlueprintTask` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyBlueprintTaskFilter = {
  /** Aggregates across related `BlueprintTask` match the filter criteria. */
  aggregates: InputMaybe<BlueprintTaskAggregatesFilter>;
  /** Every related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<BlueprintTaskFilter>;
  /** No related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<BlueprintTaskFilter>;
  /** Some related `BlueprintTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<BlueprintTaskFilter>;
};

/** A filter to be used against many `CompanyPhone` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCompanyPhoneFilter = {
  /** Aggregates across related `CompanyPhone` match the filter criteria. */
  aggregates: InputMaybe<CompanyPhoneAggregatesFilter>;
  /** Every related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<CompanyPhoneFilter>;
  /** No related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<CompanyPhoneFilter>;
  /** Some related `CompanyPhone` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<CompanyPhoneFilter>;
};

/** A filter to be used against many `Consumer` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyConsumerFilter = {
  /** Aggregates across related `Consumer` match the filter criteria. */
  aggregates: InputMaybe<ConsumerAggregatesFilter>;
  /** Every related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ConsumerFilter>;
  /** No related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ConsumerFilter>;
  /** Some related `Consumer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ConsumerFilter>;
};

/** A filter to be used against many `Contact` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyContactFilter = {
  /** Aggregates across related `Contact` match the filter criteria. */
  aggregates: InputMaybe<ContactAggregatesFilter>;
  /** Every related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ContactFilter>;
  /** No related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ContactFilter>;
  /** Some related `Contact` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ContactFilter>;
};

/** A filter to be used against many `Dashboard` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyDashboardFilter = {
  /** Aggregates across related `Dashboard` match the filter criteria. */
  aggregates: InputMaybe<DashboardAggregatesFilter>;
  /** Every related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<DashboardFilter>;
  /** No related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<DashboardFilter>;
  /** Some related `Dashboard` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<DashboardFilter>;
};

/** A filter to be used against many `FavoriteProject` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyFavoriteProjectFilter = {
  /** Aggregates across related `FavoriteProject` match the filter criteria. */
  aggregates: InputMaybe<FavoriteProjectAggregatesFilter>;
  /** Every related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FavoriteProjectFilter>;
  /** No related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FavoriteProjectFilter>;
  /** Some related `FavoriteProject` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FavoriteProjectFilter>;
};

/** A filter to be used against many `Feed` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyFeedFilter = {
  /** Aggregates across related `Feed` match the filter criteria. */
  aggregates: InputMaybe<FeedAggregatesFilter>;
  /** Every related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FeedFilter>;
  /** No related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FeedFilter>;
  /** Some related `Feed` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FeedFilter>;
};

/** A filter to be used against many `File` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyFileFilter = {
  /** Aggregates across related `File` match the filter criteria. */
  aggregates: InputMaybe<FileAggregatesFilter>;
  /** Every related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<FileFilter>;
  /** No related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<FileFilter>;
  /** Some related `File` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<FileFilter>;
};

/** A filter to be used against many `Jurisdiction` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyJurisdictionFilter = {
  /** Aggregates across related `Jurisdiction` match the filter criteria. */
  aggregates: InputMaybe<JurisdictionAggregatesFilter>;
  /** Every related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<JurisdictionFilter>;
  /** No related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<JurisdictionFilter>;
  /** Some related `Jurisdiction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<JurisdictionFilter>;
};

/** A filter to be used against many `Label` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLabelFilter = {
  /** Aggregates across related `Label` match the filter criteria. */
  aggregates: InputMaybe<LabelAggregatesFilter>;
  /** Every related `Label` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<LabelFilter>;
  /** No related `Label` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<LabelFilter>;
  /** Some related `Label` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<LabelFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectFilter = {
  /** Aggregates across related `Project` match the filter criteria. */
  aggregates: InputMaybe<ProjectAggregatesFilter>;
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectMember` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectMemberFilter = {
  /** Aggregates across related `ProjectMember` match the filter criteria. */
  aggregates: InputMaybe<ProjectMemberAggregatesFilter>;
  /** Every related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectMemberFilter>;
  /** No related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectMemberFilter>;
  /** Some related `ProjectMember` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectMemberFilter>;
};

/** A filter to be used against many `ProjectProperty` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectPropertyFilter = {
  /** Aggregates across related `ProjectProperty` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyAggregatesFilter>;
  /** Every related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyFilter>;
  /** No related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyFilter>;
  /** Some related `ProjectProperty` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyFilter>;
};

/** A filter to be used against many `ProjectPropertyValue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectPropertyValueFilter = {
  /** Aggregates across related `ProjectPropertyValue` match the filter criteria. */
  aggregates: InputMaybe<ProjectPropertyValueAggregatesFilter>;
  /** Every related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectPropertyValueFilter>;
  /** No related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectPropertyValueFilter>;
  /** Some related `ProjectPropertyValue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectPropertyValueFilter>;
};

/** A filter to be used against many `ProjectReport` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectReportFilter = {
  /** Aggregates across related `ProjectReport` match the filter criteria. */
  aggregates: InputMaybe<ProjectReportAggregatesFilter>;
  /** Every related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ProjectReportFilter>;
  /** No related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ProjectReportFilter>;
  /** Some related `ProjectReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ProjectReportFilter>;
};

/** A filter to be used against many `Reminder` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyReminderFilter = {
  /** Aggregates across related `Reminder` match the filter criteria. */
  aggregates: InputMaybe<ReminderAggregatesFilter>;
  /** Every related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<ReminderFilter>;
  /** No related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<ReminderFilter>;
  /** Some related `Reminder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<ReminderFilter>;
};

/** A filter to be used against many `SmsActivity` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySmsActivityFilter = {
  /** Aggregates across related `SmsActivity` match the filter criteria. */
  aggregates: InputMaybe<SmsActivityAggregatesFilter>;
  /** Every related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SmsActivityFilter>;
  /** No related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SmsActivityFilter>;
  /** Some related `SmsActivity` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SmsActivityFilter>;
};

/** A filter to be used against many `SubTask` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySubTaskFilter = {
  /** Aggregates across related `SubTask` match the filter criteria. */
  aggregates: InputMaybe<SubTaskAggregatesFilter>;
  /** Every related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<SubTaskFilter>;
  /** No related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<SubTaskFilter>;
  /** Some related `SubTask` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<SubTaskFilter>;
};

/** A filter to be used against many `Task` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyTaskFilter = {
  /** Aggregates across related `Task` match the filter criteria. */
  aggregates: InputMaybe<TaskAggregatesFilter>;
  /** Every related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskFilter>;
  /** No related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskFilter>;
  /** Some related `Task` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskFilter>;
};

/** A filter to be used against many `TaskTemplateUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyTaskTemplateUserFilter = {
  /** Aggregates across related `TaskTemplateUser` match the filter criteria. */
  aggregates: InputMaybe<TaskTemplateUserAggregatesFilter>;
  /** Every related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TaskTemplateUserFilter>;
  /** No related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TaskTemplateUserFilter>;
  /** Some related `TaskTemplateUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TaskTemplateUserFilter>;
};

/** A filter to be used against many `Team` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyTeamFilter = {
  /** Aggregates across related `Team` match the filter criteria. */
  aggregates: InputMaybe<TeamAggregatesFilter>;
  /** Every related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<TeamFilter>;
  /** No related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<TeamFilter>;
  /** Some related `Team` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<TeamFilter>;
};

/** A filter to be used against many `UserTeam` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserTeamFilter = {
  /** Aggregates across related `UserTeam` match the filter criteria. */
  aggregates: InputMaybe<UserTeamAggregatesFilter>;
  /** Every related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every: InputMaybe<UserTeamFilter>;
  /** No related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none: InputMaybe<UserTeamFilter>;
  /** Some related `UserTeam` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some: InputMaybe<UserTeamFilter>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectCreatedByAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectCreatedByAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectCreatedByAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectCreatedByAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectCreatedByAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectOwnerIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectOwnerIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectOwnerIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectProjectManagerIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsBySalesRepIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectProjectManagerIdAndSalesRepIdManyToManyEdgeProjectsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportCreatedByAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportCreatedByAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportCreatedByAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportCreatedByAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportOwnerIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportOwnerIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportOwnerIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportProjectManagerIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportSalesRepIdAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportSalesRepIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportUserIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportUserIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportUserIdAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndWorkerValueManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportUserIdAndWorkerValueManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndWorkerValueManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndWorkerValueManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValue: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByWorkerValueConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportUserIdAndWorkerValueManyToManyEdgeProjectReportsByWorkerValueConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportWorkerValueAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedBy: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByCreatedByConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndCreatedByManyToManyEdgeProjectReportsByCreatedByArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndCreatedByManyToManyEdgeProjectReportsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByOwnerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndOwnerIdManyToManyEdgeProjectReportsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByProjectManagerIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndProjectManagerIdManyToManyEdgeProjectReportsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsBySalesRepIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndSalesRepIdManyToManyEdgeProjectReportsBySalesRepIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndUserIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `ProjectReport`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectReportWorkerValueAndUserIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndUserIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndUserIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserId: Array<ProjectReport>;
  /** Reads and enables pagination through a set of `ProjectReport`. */
  projectReportsByUserIdConnection: ProjectReportsConnection;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndUserIdManyToManyEdgeProjectReportsByUserIdArgs = {
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectReportsOrderBy>>;
};


/** A `User` edge in the connection, with data from `ProjectReport`. */
export type UserUsersByProjectReportWorkerValueAndUserIdManyToManyEdgeProjectReportsByUserIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectReportCondition>;
  filter: InputMaybe<ProjectReportFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectReportsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectSalesRepIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndCreatedByManyToManyEdgeProjectsByCreatedByArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndCreatedByManyToManyEdgeProjectsByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndOwnerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectSalesRepIdAndOwnerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndOwnerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndOwnerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByOwnerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndOwnerIdManyToManyEdgeProjectsByOwnerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Project`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerId: Array<Project>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByProjectManagerIdConnection: ProjectsConnection;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdArgs = {
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<ProjectsOrderBy>>;
};


/** A `User` edge in the connection, with data from `Project`. */
export type UserUsersByProjectSalesRepIdAndProjectManagerIdManyToManyEdgeProjectsByProjectManagerIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ProjectCondition>;
  filter: InputMaybe<ProjectFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type UserUsersByReminderAssigneeIdAndCreatedByManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReminderAssigneeIdAndCreatedByManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type UserUsersByReminderAssigneeIdAndCreatedByManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderAssigneeIdAndCreatedByManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedBy: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByCreatedByConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderAssigneeIdAndCreatedByManyToManyEdgeRemindersByCreatedByArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderAssigneeIdAndCreatedByManyToManyEdgeRemindersByCreatedByConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

/** A connection to a list of `User` values, with data from `Reminder`. */
export type UserUsersByReminderCreatedByAndAssigneeIdManyToManyConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User`, info from the `Reminder`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReminderCreatedByAndAssigneeIdManyToManyEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values, with data from `Reminder`. */
export type UserUsersByReminderCreatedByAndAssigneeIdManyToManyConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderCreatedByAndAssigneeIdManyToManyEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeId: Array<Reminder>;
  /** Reads and enables pagination through a set of `Reminder`. */
  remindersByAssigneeIdConnection: RemindersConnection;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderCreatedByAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdArgs = {
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<RemindersOrderBy>>;
};


/** A `User` edge in the connection, with data from `Reminder`. */
export type UserUsersByReminderCreatedByAndAssigneeIdManyToManyEdgeRemindersByAssigneeIdConnectionArgs = {
  after: InputMaybe<Scalars['Cursor']['input']>;
  before: InputMaybe<Scalars['Cursor']['input']>;
  condition: InputMaybe<ReminderCondition>;
  filter: InputMaybe<ReminderFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RemindersOrderBy>>;
};

export type UserVariancePopulationAggregates = {
  /** Population variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type UserVariancePopulationAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

export type UserVarianceSampleAggregates = {
  /** Sample variance of id across the matching connection */
  id: Maybe<Scalars['BigFloat']['output']>;
};


export type UserVarianceSampleAggregatesIdArgs = {
  orderBy: InputMaybe<Array<UsersOrderBy>>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates: Maybe<UserAggregates>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `User` values. */
export type UsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having: InputMaybe<UserHavingInput>;
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  AvatarUrlAsc = 'AVATAR_URL_ASC',
  AvatarUrlDesc = 'AVATAR_URL_DESC',
  BlueprintsByCreatedByConnectionArrayAggCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionArrayAggCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionArrayAggCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionArrayAggCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionArrayAggCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionArrayAggCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionArrayAggDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionArrayAggDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionArrayAggIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintsByCreatedByConnectionArrayAggIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintsByCreatedByConnectionArrayAggNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  BlueprintsByCreatedByConnectionArrayAggNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  BlueprintsByCreatedByConnectionArrayAggTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  BlueprintsByCreatedByConnectionArrayAggTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  BlueprintsByCreatedByConnectionArrayAggUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionArrayAggUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionAverageCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionAverageCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionAverageCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionAverageCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionAverageCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionAverageCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionAverageDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionAverageDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionAverageIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  BlueprintsByCreatedByConnectionAverageIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  BlueprintsByCreatedByConnectionAverageNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  BlueprintsByCreatedByConnectionAverageNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  BlueprintsByCreatedByConnectionAverageTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  BlueprintsByCreatedByConnectionAverageTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  BlueprintsByCreatedByConnectionAverageUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionAverageUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionCountAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  BlueprintsByCreatedByConnectionCountDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  BlueprintsByCreatedByConnectionDistinctCountCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionDistinctCountCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionDistinctCountCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionDistinctCountCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionDistinctCountCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionDistinctCountCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionDistinctCountDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionDistinctCountDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionDistinctCountIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintsByCreatedByConnectionDistinctCountIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintsByCreatedByConnectionDistinctCountNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  BlueprintsByCreatedByConnectionDistinctCountNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  BlueprintsByCreatedByConnectionDistinctCountTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  BlueprintsByCreatedByConnectionDistinctCountTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  BlueprintsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionMaxCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionMaxCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionMaxCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionMaxCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionMaxCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionMaxCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionMaxDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionMaxDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionMaxIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  BlueprintsByCreatedByConnectionMaxIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  BlueprintsByCreatedByConnectionMaxNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  BlueprintsByCreatedByConnectionMaxNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  BlueprintsByCreatedByConnectionMaxTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  BlueprintsByCreatedByConnectionMaxTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  BlueprintsByCreatedByConnectionMaxUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionMaxUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionMinCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionMinCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionMinCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionMinCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionMinCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionMinCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionMinDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionMinDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionMinIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  BlueprintsByCreatedByConnectionMinIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  BlueprintsByCreatedByConnectionMinNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  BlueprintsByCreatedByConnectionMinNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  BlueprintsByCreatedByConnectionMinTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  BlueprintsByCreatedByConnectionMinTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  BlueprintsByCreatedByConnectionMinUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionMinUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  BlueprintsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionStddevSampleCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionStddevSampleCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionStddevSampleCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionStddevSampleCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionStddevSampleCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionStddevSampleCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionStddevSampleDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionStddevSampleDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionStddevSampleIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintsByCreatedByConnectionStddevSampleIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintsByCreatedByConnectionStddevSampleNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  BlueprintsByCreatedByConnectionStddevSampleNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  BlueprintsByCreatedByConnectionStddevSampleTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  BlueprintsByCreatedByConnectionStddevSampleTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  BlueprintsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionSumCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionSumCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionSumCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionSumCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionSumCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionSumCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionSumDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionSumDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionSumIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  BlueprintsByCreatedByConnectionSumIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  BlueprintsByCreatedByConnectionSumNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  BlueprintsByCreatedByConnectionSumNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  BlueprintsByCreatedByConnectionSumTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  BlueprintsByCreatedByConnectionSumTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  BlueprintsByCreatedByConnectionSumUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionSumUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  BlueprintsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleCreatedByAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleCreatedByDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleDescriptionAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleDescriptionDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleIdAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleIdDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleNameAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleNameDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleTypeAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleTypeDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  BlueprintsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionAveragePositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionAveragePositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionAverageUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionAverageUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionCountAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  BlueprintProjectStagesByCreatedByConnectionCountDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMaxUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionMaxUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionMinUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionMinUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionSumUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionSumUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationPositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationPositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleBlueprintIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleBlueprintIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSamplePositionAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSamplePositionDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleProjectStageIdAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleProjectStageIdDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintProjectStagesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_PROJECT_STAGES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionArrayAggUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionArrayAggUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionAverageBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionAverageBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionAverageCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionAverageCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionAverageCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionAverageCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionAverageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionAverageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionAverageSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionAverageSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionAverageTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionAverageTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionAverageUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionAverageUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionCountAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  BlueprintTasksByCreatedByConnectionCountDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionDistinctCountUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionDistinctCountUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionMaxBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionMaxBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionMaxCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionMaxCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionMaxCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionMaxCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionMaxIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  BlueprintTasksByCreatedByConnectionMaxIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  BlueprintTasksByCreatedByConnectionMaxSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionMaxSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionMaxTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionMaxTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionMaxUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionMaxUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionMinBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionMinBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionMinCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionMinCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionMinCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionMinCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionMinIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  BlueprintTasksByCreatedByConnectionMinIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  BlueprintTasksByCreatedByConnectionMinSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionMinSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionMinTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionMinTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionMinUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionMinUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionStddevSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionStddevSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionSumBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionSumBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionSumCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionSumCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionSumCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionSumCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionSumIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  BlueprintTasksByCreatedByConnectionSumIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  BlueprintTasksByCreatedByConnectionSumSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionSumSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionSumTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionSumTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionSumUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionSumUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleBlueprintProjectStageIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleBlueprintProjectStageIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_PROJECT_STAGE_ID_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleCreatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleCreatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleCreatedByAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleCreatedByDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleSliceIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleSliceIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLICE_ID_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleTaskIdAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleTaskIdDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  BlueprintTasksByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  BlueprintTasksByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'BLUEPRINT_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionArrayAggUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionArrayAggUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionAverageAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionAverageAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionAverageCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionAverageCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionAverageCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionAverageCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionAverageCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionAverageCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionAverageForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionAverageForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionAverageIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  CompanyPhonesByCreatedByConnectionAverageIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  CompanyPhonesByCreatedByConnectionAverageIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionAverageIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionAveragePhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionAveragePhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionAverageTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionAverageTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionAverageUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionAverageUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionCountAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  CompanyPhonesByCreatedByConnectionCountDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionMaxAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionMaxAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionMaxCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionMaxCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionMaxCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionMaxCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionMaxCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionMaxCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionMaxForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionMaxForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionMaxIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  CompanyPhonesByCreatedByConnectionMaxIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  CompanyPhonesByCreatedByConnectionMaxIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionMaxIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionMaxPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionMaxPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionMaxTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionMaxTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionMaxUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionMaxUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionMinAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionMinAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionMinCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionMinCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionMinCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionMinCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionMinCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionMinCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionMinForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionMinForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionMinIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  CompanyPhonesByCreatedByConnectionMinIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  CompanyPhonesByCreatedByConnectionMinIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionMinIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionMinPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionMinPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionMinTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionMinTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionMinUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionMinUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionStddevSamplePhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionStddevSamplePhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionSumAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionSumAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionSumCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionSumCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionSumCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionSumCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionSumCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionSumCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionSumForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionSumForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionSumIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  CompanyPhonesByCreatedByConnectionSumIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  CompanyPhonesByCreatedByConnectionSumIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionSumIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionSumPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionSumPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionSumTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionSumTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionSumUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionSumUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleAliasAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ALIAS_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleAliasDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ALIAS_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCreatedByAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleCreatedByDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleForwardingPhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORWARDING_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleForwardingPhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORWARDING_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleIdAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleIdDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleIsPermanentlyDeletedAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PERMANENTLY_DELETED_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleIsPermanentlyDeletedDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PERMANENTLY_DELETED_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSamplePhoneAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSamplePhoneDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleTwilioSidAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleTwilioSidDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  CompanyPhonesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  CompanyPhonesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'COMPANY_PHONES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionArrayAggConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionArrayAggConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionArrayAggContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionArrayAggContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionArrayAggCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ConsumersByUserIdConnectionArrayAggCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ConsumersByUserIdConnectionArrayAggFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_FEED_ID_ASC',
  ConsumersByUserIdConnectionArrayAggFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_FEED_ID_DESC',
  ConsumersByUserIdConnectionArrayAggIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ConsumersByUserIdConnectionArrayAggIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ConsumersByUserIdConnectionArrayAggMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_MENTIONED_ASC',
  ConsumersByUserIdConnectionArrayAggMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_MENTIONED_DESC',
  ConsumersByUserIdConnectionArrayAggReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_READ_ASC',
  ConsumersByUserIdConnectionArrayAggReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_READ_DESC',
  ConsumersByUserIdConnectionArrayAggUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionArrayAggUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionArrayAggUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ConsumersByUserIdConnectionArrayAggUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ConsumersByUserIdConnectionAverageConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionAverageConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionAverageContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionAverageContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionAverageCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ConsumersByUserIdConnectionAverageCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ConsumersByUserIdConnectionAverageFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_FEED_ID_ASC',
  ConsumersByUserIdConnectionAverageFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_FEED_ID_DESC',
  ConsumersByUserIdConnectionAverageIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  ConsumersByUserIdConnectionAverageIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  ConsumersByUserIdConnectionAverageMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_MENTIONED_ASC',
  ConsumersByUserIdConnectionAverageMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_MENTIONED_DESC',
  ConsumersByUserIdConnectionAverageReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_READ_ASC',
  ConsumersByUserIdConnectionAverageReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_READ_DESC',
  ConsumersByUserIdConnectionAverageUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionAverageUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionAverageUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ConsumersByUserIdConnectionAverageUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ConsumersByUserIdConnectionCountAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_COUNT_ASC',
  ConsumersByUserIdConnectionCountDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_COUNT_DESC',
  ConsumersByUserIdConnectionDistinctCountConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionDistinctCountConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionDistinctCountContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionDistinctCountContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionDistinctCountCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ConsumersByUserIdConnectionDistinctCountCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ConsumersByUserIdConnectionDistinctCountFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_ASC',
  ConsumersByUserIdConnectionDistinctCountFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FEED_ID_DESC',
  ConsumersByUserIdConnectionDistinctCountIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ConsumersByUserIdConnectionDistinctCountIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ConsumersByUserIdConnectionDistinctCountMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_MENTIONED_ASC',
  ConsumersByUserIdConnectionDistinctCountMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_MENTIONED_DESC',
  ConsumersByUserIdConnectionDistinctCountReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_READ_ASC',
  ConsumersByUserIdConnectionDistinctCountReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_READ_DESC',
  ConsumersByUserIdConnectionDistinctCountUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionDistinctCountUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionDistinctCountUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ConsumersByUserIdConnectionDistinctCountUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ConsumersByUserIdConnectionMaxConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionMaxConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionMaxContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionMaxContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionMaxCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ConsumersByUserIdConnectionMaxCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ConsumersByUserIdConnectionMaxFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_FEED_ID_ASC',
  ConsumersByUserIdConnectionMaxFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_FEED_ID_DESC',
  ConsumersByUserIdConnectionMaxIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  ConsumersByUserIdConnectionMaxIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  ConsumersByUserIdConnectionMaxMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_MENTIONED_ASC',
  ConsumersByUserIdConnectionMaxMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_MENTIONED_DESC',
  ConsumersByUserIdConnectionMaxReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_READ_ASC',
  ConsumersByUserIdConnectionMaxReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_READ_DESC',
  ConsumersByUserIdConnectionMaxUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionMaxUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionMaxUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  ConsumersByUserIdConnectionMaxUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  ConsumersByUserIdConnectionMinConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionMinConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionMinContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionMinContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionMinCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ConsumersByUserIdConnectionMinCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ConsumersByUserIdConnectionMinFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_FEED_ID_ASC',
  ConsumersByUserIdConnectionMinFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_FEED_ID_DESC',
  ConsumersByUserIdConnectionMinIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  ConsumersByUserIdConnectionMinIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  ConsumersByUserIdConnectionMinMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_MENTIONED_ASC',
  ConsumersByUserIdConnectionMinMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_MENTIONED_DESC',
  ConsumersByUserIdConnectionMinReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_READ_ASC',
  ConsumersByUserIdConnectionMinReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_READ_DESC',
  ConsumersByUserIdConnectionMinUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionMinUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionMinUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  ConsumersByUserIdConnectionMinUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  ConsumersByUserIdConnectionStddevPopulationConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionStddevPopulationConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionStddevPopulationContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionStddevPopulationContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionStddevPopulationCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ConsumersByUserIdConnectionStddevPopulationCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ConsumersByUserIdConnectionStddevPopulationFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_ASC',
  ConsumersByUserIdConnectionStddevPopulationFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FEED_ID_DESC',
  ConsumersByUserIdConnectionStddevPopulationIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ConsumersByUserIdConnectionStddevPopulationIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ConsumersByUserIdConnectionStddevPopulationMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_MENTIONED_ASC',
  ConsumersByUserIdConnectionStddevPopulationMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_MENTIONED_DESC',
  ConsumersByUserIdConnectionStddevPopulationReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_READ_ASC',
  ConsumersByUserIdConnectionStddevPopulationReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_READ_DESC',
  ConsumersByUserIdConnectionStddevPopulationUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionStddevPopulationUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionStddevPopulationUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ConsumersByUserIdConnectionStddevPopulationUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ConsumersByUserIdConnectionStddevSampleConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionStddevSampleConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionStddevSampleContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionStddevSampleContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionStddevSampleCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConsumersByUserIdConnectionStddevSampleCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConsumersByUserIdConnectionStddevSampleFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_ASC',
  ConsumersByUserIdConnectionStddevSampleFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FEED_ID_DESC',
  ConsumersByUserIdConnectionStddevSampleIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ConsumersByUserIdConnectionStddevSampleIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ConsumersByUserIdConnectionStddevSampleMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_MENTIONED_ASC',
  ConsumersByUserIdConnectionStddevSampleMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_MENTIONED_DESC',
  ConsumersByUserIdConnectionStddevSampleReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_READ_ASC',
  ConsumersByUserIdConnectionStddevSampleReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_READ_DESC',
  ConsumersByUserIdConnectionStddevSampleUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionStddevSampleUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionStddevSampleUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ConsumersByUserIdConnectionStddevSampleUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ConsumersByUserIdConnectionSumConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionSumConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionSumContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionSumContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionSumCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ConsumersByUserIdConnectionSumCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ConsumersByUserIdConnectionSumFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_FEED_ID_ASC',
  ConsumersByUserIdConnectionSumFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_FEED_ID_DESC',
  ConsumersByUserIdConnectionSumIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  ConsumersByUserIdConnectionSumIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  ConsumersByUserIdConnectionSumMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_MENTIONED_ASC',
  ConsumersByUserIdConnectionSumMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_MENTIONED_DESC',
  ConsumersByUserIdConnectionSumReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_READ_ASC',
  ConsumersByUserIdConnectionSumReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_READ_DESC',
  ConsumersByUserIdConnectionSumUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionSumUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionSumUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  ConsumersByUserIdConnectionSumUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  ConsumersByUserIdConnectionVariancePopulationConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionVariancePopulationConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionVariancePopulationContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionVariancePopulationContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionVariancePopulationCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConsumersByUserIdConnectionVariancePopulationCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConsumersByUserIdConnectionVariancePopulationFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_ASC',
  ConsumersByUserIdConnectionVariancePopulationFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FEED_ID_DESC',
  ConsumersByUserIdConnectionVariancePopulationIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ConsumersByUserIdConnectionVariancePopulationIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ConsumersByUserIdConnectionVariancePopulationMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_MENTIONED_ASC',
  ConsumersByUserIdConnectionVariancePopulationMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_MENTIONED_DESC',
  ConsumersByUserIdConnectionVariancePopulationReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_READ_ASC',
  ConsumersByUserIdConnectionVariancePopulationReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_READ_DESC',
  ConsumersByUserIdConnectionVariancePopulationUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionVariancePopulationUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionVariancePopulationUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ConsumersByUserIdConnectionVariancePopulationUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ConsumersByUserIdConnectionVarianceSampleConsumerTypeAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_ASC',
  ConsumersByUserIdConnectionVarianceSampleConsumerTypeDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONSUMER_TYPE_DESC',
  ConsumersByUserIdConnectionVarianceSampleContactIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ConsumersByUserIdConnectionVarianceSampleContactIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ConsumersByUserIdConnectionVarianceSampleCreatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConsumersByUserIdConnectionVarianceSampleCreatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConsumersByUserIdConnectionVarianceSampleFeedIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_ASC',
  ConsumersByUserIdConnectionVarianceSampleFeedIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FEED_ID_DESC',
  ConsumersByUserIdConnectionVarianceSampleIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ConsumersByUserIdConnectionVarianceSampleIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ConsumersByUserIdConnectionVarianceSampleMentionedAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_MENTIONED_ASC',
  ConsumersByUserIdConnectionVarianceSampleMentionedDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_MENTIONED_DESC',
  ConsumersByUserIdConnectionVarianceSampleReadAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_READ_ASC',
  ConsumersByUserIdConnectionVarianceSampleReadDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_READ_DESC',
  ConsumersByUserIdConnectionVarianceSampleUpdatedAtAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConsumersByUserIdConnectionVarianceSampleUpdatedAtDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ConsumersByUserIdConnectionVarianceSampleUserIdAsc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ConsumersByUserIdConnectionVarianceSampleUserIdDesc = 'CONSUMERS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ContactsByCreatedByConnectionArrayAggCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionArrayAggCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionArrayAggCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ContactsByCreatedByConnectionArrayAggCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ContactsByCreatedByConnectionArrayAggCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ContactsByCreatedByConnectionArrayAggCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ContactsByCreatedByConnectionArrayAggEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAILS_ASC',
  ContactsByCreatedByConnectionArrayAggEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAILS_DESC',
  ContactsByCreatedByConnectionArrayAggIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ContactsByCreatedByConnectionArrayAggIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ContactsByCreatedByConnectionArrayAggInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionArrayAggInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionArrayAggLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionArrayAggLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionArrayAggNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  ContactsByCreatedByConnectionArrayAggNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  ContactsByCreatedByConnectionArrayAggPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONES_ASC',
  ContactsByCreatedByConnectionArrayAggPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONES_DESC',
  ContactsByCreatedByConnectionArrayAggPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionArrayAggPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionArrayAggReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionArrayAggReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionArrayAggReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionArrayAggReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionArrayAggSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionArrayAggSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionArrayAggSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ContactsByCreatedByConnectionArrayAggSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ContactsByCreatedByConnectionArrayAggStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ContactsByCreatedByConnectionArrayAggStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ContactsByCreatedByConnectionArrayAggTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ContactsByCreatedByConnectionArrayAggTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ContactsByCreatedByConnectionArrayAggUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionArrayAggUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionAverageCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionAverageCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionAverageCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ContactsByCreatedByConnectionAverageCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ContactsByCreatedByConnectionAverageCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ContactsByCreatedByConnectionAverageCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ContactsByCreatedByConnectionAverageEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAILS_ASC',
  ContactsByCreatedByConnectionAverageEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAILS_DESC',
  ContactsByCreatedByConnectionAverageIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ContactsByCreatedByConnectionAverageIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ContactsByCreatedByConnectionAverageInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionAverageInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionAverageLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionAverageLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionAverageNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  ContactsByCreatedByConnectionAverageNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  ContactsByCreatedByConnectionAveragePhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONES_ASC',
  ContactsByCreatedByConnectionAveragePhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONES_DESC',
  ContactsByCreatedByConnectionAveragePortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionAveragePortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionAverageReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionAverageReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionAverageReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionAverageReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionAverageSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionAverageSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionAverageSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_ASC',
  ContactsByCreatedByConnectionAverageSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_DESC',
  ContactsByCreatedByConnectionAverageStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  ContactsByCreatedByConnectionAverageStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  ContactsByCreatedByConnectionAverageTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  ContactsByCreatedByConnectionAverageTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  ContactsByCreatedByConnectionAverageUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionAverageUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ContactsByCreatedByConnectionCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ContactsByCreatedByConnectionDistinctCountCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionDistinctCountCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionDistinctCountCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ContactsByCreatedByConnectionDistinctCountCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ContactsByCreatedByConnectionDistinctCountCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ContactsByCreatedByConnectionDistinctCountCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ContactsByCreatedByConnectionDistinctCountEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAILS_ASC',
  ContactsByCreatedByConnectionDistinctCountEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAILS_DESC',
  ContactsByCreatedByConnectionDistinctCountIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ContactsByCreatedByConnectionDistinctCountIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ContactsByCreatedByConnectionDistinctCountInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionDistinctCountInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionDistinctCountLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionDistinctCountLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionDistinctCountNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ContactsByCreatedByConnectionDistinctCountNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ContactsByCreatedByConnectionDistinctCountPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONES_ASC',
  ContactsByCreatedByConnectionDistinctCountPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONES_DESC',
  ContactsByCreatedByConnectionDistinctCountPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionDistinctCountPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionDistinctCountReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionDistinctCountReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionDistinctCountReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionDistinctCountReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionDistinctCountSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionDistinctCountSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionDistinctCountSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ContactsByCreatedByConnectionDistinctCountSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ContactsByCreatedByConnectionDistinctCountStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ContactsByCreatedByConnectionDistinctCountStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ContactsByCreatedByConnectionDistinctCountTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ContactsByCreatedByConnectionDistinctCountTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ContactsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionMaxCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionMaxCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionMaxCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ContactsByCreatedByConnectionMaxCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ContactsByCreatedByConnectionMaxCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ContactsByCreatedByConnectionMaxCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ContactsByCreatedByConnectionMaxEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_EMAILS_ASC',
  ContactsByCreatedByConnectionMaxEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_EMAILS_DESC',
  ContactsByCreatedByConnectionMaxIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ContactsByCreatedByConnectionMaxIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ContactsByCreatedByConnectionMaxInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionMaxInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionMaxLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionMaxLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionMaxNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  ContactsByCreatedByConnectionMaxNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  ContactsByCreatedByConnectionMaxPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_PHONES_ASC',
  ContactsByCreatedByConnectionMaxPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_PHONES_DESC',
  ContactsByCreatedByConnectionMaxPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionMaxPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionMaxReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionMaxReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionMaxReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionMaxReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionMaxSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionMaxSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionMaxSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_ASC',
  ContactsByCreatedByConnectionMaxSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_DESC',
  ContactsByCreatedByConnectionMaxStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  ContactsByCreatedByConnectionMaxStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  ContactsByCreatedByConnectionMaxTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  ContactsByCreatedByConnectionMaxTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  ContactsByCreatedByConnectionMaxUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionMaxUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionMinCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionMinCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionMinCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ContactsByCreatedByConnectionMinCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ContactsByCreatedByConnectionMinCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ContactsByCreatedByConnectionMinCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ContactsByCreatedByConnectionMinEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_EMAILS_ASC',
  ContactsByCreatedByConnectionMinEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_EMAILS_DESC',
  ContactsByCreatedByConnectionMinIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ContactsByCreatedByConnectionMinIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ContactsByCreatedByConnectionMinInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionMinInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionMinLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionMinLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionMinNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  ContactsByCreatedByConnectionMinNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  ContactsByCreatedByConnectionMinPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_PHONES_ASC',
  ContactsByCreatedByConnectionMinPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_PHONES_DESC',
  ContactsByCreatedByConnectionMinPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionMinPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionMinReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionMinReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionMinReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionMinReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionMinSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionMinSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionMinSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_ASC',
  ContactsByCreatedByConnectionMinSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_DESC',
  ContactsByCreatedByConnectionMinStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  ContactsByCreatedByConnectionMinStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  ContactsByCreatedByConnectionMinTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  ContactsByCreatedByConnectionMinTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  ContactsByCreatedByConnectionMinUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionMinUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ContactsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ContactsByCreatedByConnectionStddevPopulationCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ContactsByCreatedByConnectionStddevPopulationCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ContactsByCreatedByConnectionStddevPopulationEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAILS_ASC',
  ContactsByCreatedByConnectionStddevPopulationEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAILS_DESC',
  ContactsByCreatedByConnectionStddevPopulationIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ContactsByCreatedByConnectionStddevPopulationIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ContactsByCreatedByConnectionStddevPopulationInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionStddevPopulationInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionStddevPopulationLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionStddevPopulationLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionStddevPopulationNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ContactsByCreatedByConnectionStddevPopulationNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ContactsByCreatedByConnectionStddevPopulationPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONES_ASC',
  ContactsByCreatedByConnectionStddevPopulationPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONES_DESC',
  ContactsByCreatedByConnectionStddevPopulationPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionStddevPopulationPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionStddevPopulationReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionStddevPopulationReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionStddevPopulationReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionStddevPopulationReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionStddevPopulationSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionStddevPopulationSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionStddevPopulationSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ContactsByCreatedByConnectionStddevPopulationSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ContactsByCreatedByConnectionStddevPopulationStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ContactsByCreatedByConnectionStddevPopulationStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ContactsByCreatedByConnectionStddevPopulationTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ContactsByCreatedByConnectionStddevPopulationTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ContactsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionStddevSampleCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionStddevSampleCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionStddevSampleCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ContactsByCreatedByConnectionStddevSampleCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ContactsByCreatedByConnectionStddevSampleCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ContactsByCreatedByConnectionStddevSampleCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ContactsByCreatedByConnectionStddevSampleEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAILS_ASC',
  ContactsByCreatedByConnectionStddevSampleEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAILS_DESC',
  ContactsByCreatedByConnectionStddevSampleIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ContactsByCreatedByConnectionStddevSampleIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ContactsByCreatedByConnectionStddevSampleInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionStddevSampleInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionStddevSampleLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionStddevSampleLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionStddevSampleNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ContactsByCreatedByConnectionStddevSampleNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ContactsByCreatedByConnectionStddevSamplePhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONES_ASC',
  ContactsByCreatedByConnectionStddevSamplePhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONES_DESC',
  ContactsByCreatedByConnectionStddevSamplePortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionStddevSamplePortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionStddevSampleReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionStddevSampleReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionStddevSampleReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionStddevSampleReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionStddevSampleSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionStddevSampleSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionStddevSampleSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ContactsByCreatedByConnectionStddevSampleSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ContactsByCreatedByConnectionStddevSampleStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ContactsByCreatedByConnectionStddevSampleStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ContactsByCreatedByConnectionStddevSampleTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ContactsByCreatedByConnectionStddevSampleTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ContactsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionSumCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionSumCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionSumCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ContactsByCreatedByConnectionSumCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ContactsByCreatedByConnectionSumCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ContactsByCreatedByConnectionSumCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ContactsByCreatedByConnectionSumEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_EMAILS_ASC',
  ContactsByCreatedByConnectionSumEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_EMAILS_DESC',
  ContactsByCreatedByConnectionSumIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ContactsByCreatedByConnectionSumIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ContactsByCreatedByConnectionSumInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionSumInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionSumLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionSumLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionSumNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  ContactsByCreatedByConnectionSumNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  ContactsByCreatedByConnectionSumPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_PHONES_ASC',
  ContactsByCreatedByConnectionSumPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_PHONES_DESC',
  ContactsByCreatedByConnectionSumPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionSumPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionSumReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionSumReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionSumReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionSumReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionSumSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionSumSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionSumSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_ASC',
  ContactsByCreatedByConnectionSumSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_DESC',
  ContactsByCreatedByConnectionSumStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  ContactsByCreatedByConnectionSumStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  ContactsByCreatedByConnectionSumTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  ContactsByCreatedByConnectionSumTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  ContactsByCreatedByConnectionSumUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionSumUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ContactsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ContactsByCreatedByConnectionVariancePopulationCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ContactsByCreatedByConnectionVariancePopulationCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ContactsByCreatedByConnectionVariancePopulationEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAILS_ASC',
  ContactsByCreatedByConnectionVariancePopulationEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAILS_DESC',
  ContactsByCreatedByConnectionVariancePopulationIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ContactsByCreatedByConnectionVariancePopulationIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ContactsByCreatedByConnectionVariancePopulationInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionVariancePopulationInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionVariancePopulationLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionVariancePopulationLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionVariancePopulationNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ContactsByCreatedByConnectionVariancePopulationNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ContactsByCreatedByConnectionVariancePopulationPhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONES_ASC',
  ContactsByCreatedByConnectionVariancePopulationPhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONES_DESC',
  ContactsByCreatedByConnectionVariancePopulationPortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionVariancePopulationPortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionVariancePopulationReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionVariancePopulationReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionVariancePopulationReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionVariancePopulationReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionVariancePopulationSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionVariancePopulationSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionVariancePopulationSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ContactsByCreatedByConnectionVariancePopulationSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ContactsByCreatedByConnectionVariancePopulationStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ContactsByCreatedByConnectionVariancePopulationStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ContactsByCreatedByConnectionVariancePopulationTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ContactsByCreatedByConnectionVariancePopulationTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ContactsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ContactsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ContactsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ContactsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ContactsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ContactsByCreatedByConnectionVarianceSampleCreatedByAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ContactsByCreatedByConnectionVarianceSampleCreatedByDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ContactsByCreatedByConnectionVarianceSampleEmailsAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAILS_ASC',
  ContactsByCreatedByConnectionVarianceSampleEmailsDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAILS_DESC',
  ContactsByCreatedByConnectionVarianceSampleIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ContactsByCreatedByConnectionVarianceSampleIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ContactsByCreatedByConnectionVarianceSampleInvitedCountAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_INVITED_COUNT_ASC',
  ContactsByCreatedByConnectionVarianceSampleInvitedCountDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_INVITED_COUNT_DESC',
  ContactsByCreatedByConnectionVarianceSampleLastInvitedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INVITED_AT_ASC',
  ContactsByCreatedByConnectionVarianceSampleLastInvitedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INVITED_AT_DESC',
  ContactsByCreatedByConnectionVarianceSampleNameAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ContactsByCreatedByConnectionVarianceSampleNameDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ContactsByCreatedByConnectionVarianceSamplePhonesAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONES_ASC',
  ContactsByCreatedByConnectionVarianceSamplePhonesDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONES_DESC',
  ContactsByCreatedByConnectionVarianceSamplePortalStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PORTAL_STATUS_ASC',
  ContactsByCreatedByConnectionVarianceSamplePortalStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PORTAL_STATUS_DESC',
  ContactsByCreatedByConnectionVarianceSampleReferralCodeAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRAL_CODE_ASC',
  ContactsByCreatedByConnectionVarianceSampleReferralCodeDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRAL_CODE_DESC',
  ContactsByCreatedByConnectionVarianceSampleReferrerContactIdAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ContactsByCreatedByConnectionVarianceSampleReferrerContactIdDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ContactsByCreatedByConnectionVarianceSampleSearchStringAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SEARCH_STRING_ASC',
  ContactsByCreatedByConnectionVarianceSampleSearchStringDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SEARCH_STRING_DESC',
  ContactsByCreatedByConnectionVarianceSampleSourceAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ContactsByCreatedByConnectionVarianceSampleSourceDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ContactsByCreatedByConnectionVarianceSampleStatusAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ContactsByCreatedByConnectionVarianceSampleStatusDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ContactsByCreatedByConnectionVarianceSampleTitleAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ContactsByCreatedByConnectionVarianceSampleTitleDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ContactsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ContactsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'CONTACTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionArrayAggCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionArrayAggCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionArrayAggCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionArrayAggCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionArrayAggCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionArrayAggCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionArrayAggCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionArrayAggCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionArrayAggDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionArrayAggDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionArrayAggEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMOJI_ASC',
  DashboardsByCreatedByConnectionArrayAggEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMOJI_DESC',
  DashboardsByCreatedByConnectionArrayAggFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionArrayAggFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionArrayAggIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  DashboardsByCreatedByConnectionArrayAggIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  DashboardsByCreatedByConnectionArrayAggLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAYOUT_ASC',
  DashboardsByCreatedByConnectionArrayAggLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAYOUT_DESC',
  DashboardsByCreatedByConnectionArrayAggNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  DashboardsByCreatedByConnectionArrayAggNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  DashboardsByCreatedByConnectionArrayAggPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionArrayAggPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionArrayAggScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  DashboardsByCreatedByConnectionArrayAggScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  DashboardsByCreatedByConnectionArrayAggUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionArrayAggUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionAverageCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionAverageCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionAverageCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionAverageCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionAverageCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionAverageCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionAverageCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionAverageCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionAverageDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionAverageDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionAverageEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_EMOJI_ASC',
  DashboardsByCreatedByConnectionAverageEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_EMOJI_DESC',
  DashboardsByCreatedByConnectionAverageFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionAverageFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionAverageIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  DashboardsByCreatedByConnectionAverageIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  DashboardsByCreatedByConnectionAverageLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_LAYOUT_ASC',
  DashboardsByCreatedByConnectionAverageLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_LAYOUT_DESC',
  DashboardsByCreatedByConnectionAverageNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  DashboardsByCreatedByConnectionAverageNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  DashboardsByCreatedByConnectionAveragePrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionAveragePrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionAverageScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_ASC',
  DashboardsByCreatedByConnectionAverageScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_DESC',
  DashboardsByCreatedByConnectionAverageUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionAverageUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionCountAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  DashboardsByCreatedByConnectionCountDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  DashboardsByCreatedByConnectionDistinctCountCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionDistinctCountCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionDistinctCountCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionDistinctCountCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionDistinctCountCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionDistinctCountCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionDistinctCountCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionDistinctCountCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionDistinctCountDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionDistinctCountDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionDistinctCountEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMOJI_ASC',
  DashboardsByCreatedByConnectionDistinctCountEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMOJI_DESC',
  DashboardsByCreatedByConnectionDistinctCountFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionDistinctCountFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionDistinctCountIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  DashboardsByCreatedByConnectionDistinctCountIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  DashboardsByCreatedByConnectionDistinctCountLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAYOUT_ASC',
  DashboardsByCreatedByConnectionDistinctCountLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAYOUT_DESC',
  DashboardsByCreatedByConnectionDistinctCountNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  DashboardsByCreatedByConnectionDistinctCountNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  DashboardsByCreatedByConnectionDistinctCountPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionDistinctCountPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionDistinctCountScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  DashboardsByCreatedByConnectionDistinctCountScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  DashboardsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionMaxCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionMaxCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionMaxCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionMaxCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionMaxCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionMaxCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionMaxCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionMaxCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionMaxDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionMaxDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionMaxEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_EMOJI_ASC',
  DashboardsByCreatedByConnectionMaxEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_EMOJI_DESC',
  DashboardsByCreatedByConnectionMaxFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionMaxFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionMaxIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  DashboardsByCreatedByConnectionMaxIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  DashboardsByCreatedByConnectionMaxLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_LAYOUT_ASC',
  DashboardsByCreatedByConnectionMaxLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_LAYOUT_DESC',
  DashboardsByCreatedByConnectionMaxNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  DashboardsByCreatedByConnectionMaxNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  DashboardsByCreatedByConnectionMaxPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionMaxPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionMaxScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_ASC',
  DashboardsByCreatedByConnectionMaxScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_SCOPE_DESC',
  DashboardsByCreatedByConnectionMaxUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionMaxUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionMinCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionMinCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionMinCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionMinCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionMinCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionMinCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionMinCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionMinCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionMinDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionMinDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionMinEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_EMOJI_ASC',
  DashboardsByCreatedByConnectionMinEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_EMOJI_DESC',
  DashboardsByCreatedByConnectionMinFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionMinFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionMinIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  DashboardsByCreatedByConnectionMinIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  DashboardsByCreatedByConnectionMinLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_LAYOUT_ASC',
  DashboardsByCreatedByConnectionMinLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_LAYOUT_DESC',
  DashboardsByCreatedByConnectionMinNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  DashboardsByCreatedByConnectionMinNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  DashboardsByCreatedByConnectionMinPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionMinPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionMinScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_ASC',
  DashboardsByCreatedByConnectionMinScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_SCOPE_DESC',
  DashboardsByCreatedByConnectionMinUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionMinUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionStddevPopulationCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionStddevPopulationCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionStddevPopulationCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionStddevPopulationCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionStddevPopulationDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionStddevPopulationDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionStddevPopulationEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMOJI_ASC',
  DashboardsByCreatedByConnectionStddevPopulationEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMOJI_DESC',
  DashboardsByCreatedByConnectionStddevPopulationFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionStddevPopulationFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionStddevPopulationIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  DashboardsByCreatedByConnectionStddevPopulationIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  DashboardsByCreatedByConnectionStddevPopulationLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAYOUT_ASC',
  DashboardsByCreatedByConnectionStddevPopulationLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAYOUT_DESC',
  DashboardsByCreatedByConnectionStddevPopulationNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  DashboardsByCreatedByConnectionStddevPopulationNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  DashboardsByCreatedByConnectionStddevPopulationPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionStddevPopulationPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionStddevPopulationScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  DashboardsByCreatedByConnectionStddevPopulationScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  DashboardsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionStddevSampleCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionStddevSampleCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionStddevSampleCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionStddevSampleCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionStddevSampleCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionStddevSampleCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionStddevSampleCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionStddevSampleCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionStddevSampleDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionStddevSampleDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionStddevSampleEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMOJI_ASC',
  DashboardsByCreatedByConnectionStddevSampleEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMOJI_DESC',
  DashboardsByCreatedByConnectionStddevSampleFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionStddevSampleFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionStddevSampleIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  DashboardsByCreatedByConnectionStddevSampleIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  DashboardsByCreatedByConnectionStddevSampleLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAYOUT_ASC',
  DashboardsByCreatedByConnectionStddevSampleLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAYOUT_DESC',
  DashboardsByCreatedByConnectionStddevSampleNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  DashboardsByCreatedByConnectionStddevSampleNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  DashboardsByCreatedByConnectionStddevSamplePrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionStddevSamplePrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionStddevSampleScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  DashboardsByCreatedByConnectionStddevSampleScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  DashboardsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionSumCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionSumCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionSumCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionSumCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionSumCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionSumCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionSumCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionSumCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionSumDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionSumDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionSumEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_EMOJI_ASC',
  DashboardsByCreatedByConnectionSumEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_EMOJI_DESC',
  DashboardsByCreatedByConnectionSumFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionSumFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionSumIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  DashboardsByCreatedByConnectionSumIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  DashboardsByCreatedByConnectionSumLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_LAYOUT_ASC',
  DashboardsByCreatedByConnectionSumLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_LAYOUT_DESC',
  DashboardsByCreatedByConnectionSumNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  DashboardsByCreatedByConnectionSumNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  DashboardsByCreatedByConnectionSumPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionSumPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionSumScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_ASC',
  DashboardsByCreatedByConnectionSumScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_SCOPE_DESC',
  DashboardsByCreatedByConnectionSumUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionSumUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionVariancePopulationCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionVariancePopulationCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionVariancePopulationCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionVariancePopulationCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionVariancePopulationDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionVariancePopulationDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionVariancePopulationEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMOJI_ASC',
  DashboardsByCreatedByConnectionVariancePopulationEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMOJI_DESC',
  DashboardsByCreatedByConnectionVariancePopulationFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionVariancePopulationFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionVariancePopulationIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  DashboardsByCreatedByConnectionVariancePopulationIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  DashboardsByCreatedByConnectionVariancePopulationLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAYOUT_ASC',
  DashboardsByCreatedByConnectionVariancePopulationLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAYOUT_DESC',
  DashboardsByCreatedByConnectionVariancePopulationNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  DashboardsByCreatedByConnectionVariancePopulationNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  DashboardsByCreatedByConnectionVariancePopulationPrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionVariancePopulationPrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionVariancePopulationScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  DashboardsByCreatedByConnectionVariancePopulationScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  DashboardsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  DashboardsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  DashboardsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  DashboardsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  DashboardsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  DashboardsByCreatedByConnectionVarianceSampleCreatedByAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  DashboardsByCreatedByConnectionVarianceSampleCreatedByDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  DashboardsByCreatedByConnectionVarianceSampleCustomizationAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_ASC',
  DashboardsByCreatedByConnectionVarianceSampleCustomizationDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CUSTOMIZATION_DESC',
  DashboardsByCreatedByConnectionVarianceSampleDescriptionAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  DashboardsByCreatedByConnectionVarianceSampleDescriptionDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  DashboardsByCreatedByConnectionVarianceSampleEmojiAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMOJI_ASC',
  DashboardsByCreatedByConnectionVarianceSampleEmojiDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMOJI_DESC',
  DashboardsByCreatedByConnectionVarianceSampleFilterFolderIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_ASC',
  DashboardsByCreatedByConnectionVarianceSampleFilterFolderIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILTER_FOLDER_ID_DESC',
  DashboardsByCreatedByConnectionVarianceSampleIdAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  DashboardsByCreatedByConnectionVarianceSampleIdDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  DashboardsByCreatedByConnectionVarianceSampleLayoutAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAYOUT_ASC',
  DashboardsByCreatedByConnectionVarianceSampleLayoutDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAYOUT_DESC',
  DashboardsByCreatedByConnectionVarianceSampleNameAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  DashboardsByCreatedByConnectionVarianceSampleNameDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  DashboardsByCreatedByConnectionVarianceSamplePrivilegeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  DashboardsByCreatedByConnectionVarianceSamplePrivilegeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  DashboardsByCreatedByConnectionVarianceSampleScopeAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  DashboardsByCreatedByConnectionVarianceSampleScopeDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  DashboardsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  DashboardsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'DASHBOARDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FavoriteProjectsConnectionArrayAggProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FavoriteProjectsConnectionArrayAggProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FavoriteProjectsConnectionArrayAggWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  FavoriteProjectsConnectionArrayAggWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  FavoriteProjectsConnectionAverageProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionAverageProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionAverageWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_WORKER_ID_ASC',
  FavoriteProjectsConnectionAverageWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_AVERAGE_WORKER_ID_DESC',
  FavoriteProjectsConnectionCountAsc = 'FAVORITE_PROJECTS_CONNECTION_COUNT_ASC',
  FavoriteProjectsConnectionCountDesc = 'FAVORITE_PROJECTS_CONNECTION_COUNT_DESC',
  FavoriteProjectsConnectionDistinctCountProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FavoriteProjectsConnectionDistinctCountProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FavoriteProjectsConnectionDistinctCountWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  FavoriteProjectsConnectionDistinctCountWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  FavoriteProjectsConnectionMaxProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MAX_PROJECT_ID_ASC',
  FavoriteProjectsConnectionMaxProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MAX_PROJECT_ID_DESC',
  FavoriteProjectsConnectionMaxWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MAX_WORKER_ID_ASC',
  FavoriteProjectsConnectionMaxWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MAX_WORKER_ID_DESC',
  FavoriteProjectsConnectionMinProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MIN_PROJECT_ID_ASC',
  FavoriteProjectsConnectionMinProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MIN_PROJECT_ID_DESC',
  FavoriteProjectsConnectionMinWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_MIN_WORKER_ID_ASC',
  FavoriteProjectsConnectionMinWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_MIN_WORKER_ID_DESC',
  FavoriteProjectsConnectionStddevPopulationProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FavoriteProjectsConnectionStddevPopulationProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FavoriteProjectsConnectionStddevPopulationWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  FavoriteProjectsConnectionStddevPopulationWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  FavoriteProjectsConnectionStddevSampleProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionStddevSampleProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionStddevSampleWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  FavoriteProjectsConnectionStddevSampleWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  FavoriteProjectsConnectionSumProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_SUM_PROJECT_ID_ASC',
  FavoriteProjectsConnectionSumProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_SUM_PROJECT_ID_DESC',
  FavoriteProjectsConnectionSumWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_SUM_WORKER_ID_ASC',
  FavoriteProjectsConnectionSumWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_SUM_WORKER_ID_DESC',
  FavoriteProjectsConnectionVariancePopulationProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FavoriteProjectsConnectionVariancePopulationProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FavoriteProjectsConnectionVariancePopulationWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  FavoriteProjectsConnectionVariancePopulationWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  FavoriteProjectsConnectionVarianceSampleProjectIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FavoriteProjectsConnectionVarianceSampleProjectIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FavoriteProjectsConnectionVarianceSampleWorkerIdAsc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  FavoriteProjectsConnectionVarianceSampleWorkerIdDesc = 'FAVORITE_PROJECTS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC',
  FeedsByCreatedByConnectionArrayAggActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FeedsByCreatedByConnectionArrayAggActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FeedsByCreatedByConnectionArrayAggCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALL_ID_ASC',
  FeedsByCreatedByConnectionArrayAggCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALL_ID_DESC',
  FeedsByCreatedByConnectionArrayAggCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ASC',
  FeedsByCreatedByConnectionArrayAggCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_DESC',
  FeedsByCreatedByConnectionArrayAggCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionArrayAggCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionArrayAggCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionArrayAggCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionArrayAggCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FeedsByCreatedByConnectionArrayAggCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FeedsByCreatedByConnectionArrayAggCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FeedsByCreatedByConnectionArrayAggCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionArrayAggCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionArrayAggCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FeedsByCreatedByConnectionArrayAggCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionArrayAggCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionArrayAggEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionArrayAggEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionArrayAggEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EVENT_ASC',
  FeedsByCreatedByConnectionArrayAggEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EVENT_DESC',
  FeedsByCreatedByConnectionArrayAggEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionArrayAggEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionArrayAggFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_ID_ASC',
  FeedsByCreatedByConnectionArrayAggFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FILE_ID_DESC',
  FeedsByCreatedByConnectionArrayAggIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  FeedsByCreatedByConnectionArrayAggIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  FeedsByCreatedByConnectionArrayAggParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_ID_ASC',
  FeedsByCreatedByConnectionArrayAggParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_ID_DESC',
  FeedsByCreatedByConnectionArrayAggPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PAYLOAD_ASC',
  FeedsByCreatedByConnectionArrayAggPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PAYLOAD_DESC',
  FeedsByCreatedByConnectionArrayAggProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionArrayAggProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionArrayAggReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionArrayAggReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionArrayAggRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionArrayAggRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionArrayAggRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRYABLE_ASC',
  FeedsByCreatedByConnectionArrayAggRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRYABLE_DESC',
  FeedsByCreatedByConnectionArrayAggRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionArrayAggRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionArrayAggSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionArrayAggSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionArrayAggSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionArrayAggSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionArrayAggSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionArrayAggSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionArrayAggTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  FeedsByCreatedByConnectionArrayAggTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  FeedsByCreatedByConnectionArrayAggVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionArrayAggVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionArrayAggWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionArrayAggWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionAverageActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FeedsByCreatedByConnectionAverageActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FeedsByCreatedByConnectionAverageCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CALL_ID_ASC',
  FeedsByCreatedByConnectionAverageCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CALL_ID_DESC',
  FeedsByCreatedByConnectionAverageCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ASC',
  FeedsByCreatedByConnectionAverageCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_DESC',
  FeedsByCreatedByConnectionAverageCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionAverageCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionAverageCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionAverageCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionAverageCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FeedsByCreatedByConnectionAverageCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FeedsByCreatedByConnectionAverageCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FeedsByCreatedByConnectionAverageCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionAverageCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionAverageCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FeedsByCreatedByConnectionAverageCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionAverageCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionAverageEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionAverageEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionAverageEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EVENT_ASC',
  FeedsByCreatedByConnectionAverageEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EVENT_DESC',
  FeedsByCreatedByConnectionAverageEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionAverageEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionAverageFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_ID_ASC',
  FeedsByCreatedByConnectionAverageFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_FILE_ID_DESC',
  FeedsByCreatedByConnectionAverageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  FeedsByCreatedByConnectionAverageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  FeedsByCreatedByConnectionAverageParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_ID_ASC',
  FeedsByCreatedByConnectionAverageParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_ID_DESC',
  FeedsByCreatedByConnectionAveragePayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PAYLOAD_ASC',
  FeedsByCreatedByConnectionAveragePayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PAYLOAD_DESC',
  FeedsByCreatedByConnectionAverageProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionAverageProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionAverageReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionAverageReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionAverageRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionAverageRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionAverageRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRYABLE_ASC',
  FeedsByCreatedByConnectionAverageRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRYABLE_DESC',
  FeedsByCreatedByConnectionAverageRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionAverageRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionAverageSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionAverageSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionAverageSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionAverageSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionAverageSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionAverageSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionAverageTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  FeedsByCreatedByConnectionAverageTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  FeedsByCreatedByConnectionAverageVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionAverageVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionAverageWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionAverageWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_AVERAGE_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  FeedsByCreatedByConnectionCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  FeedsByCreatedByConnectionDistinctCountActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALL_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALL_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ASC',
  FeedsByCreatedByConnectionDistinctCountCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_DESC',
  FeedsByCreatedByConnectionDistinctCountCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FeedsByCreatedByConnectionDistinctCountCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FeedsByCreatedByConnectionDistinctCountCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FeedsByCreatedByConnectionDistinctCountCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FeedsByCreatedByConnectionDistinctCountCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionDistinctCountCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionDistinctCountEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EVENT_ASC',
  FeedsByCreatedByConnectionDistinctCountEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EVENT_DESC',
  FeedsByCreatedByConnectionDistinctCountEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionDistinctCountEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionDistinctCountFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FILE_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PAYLOAD_ASC',
  FeedsByCreatedByConnectionDistinctCountPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PAYLOAD_DESC',
  FeedsByCreatedByConnectionDistinctCountProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionDistinctCountRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionDistinctCountRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRYABLE_ASC',
  FeedsByCreatedByConnectionDistinctCountRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRYABLE_DESC',
  FeedsByCreatedByConnectionDistinctCountRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionDistinctCountRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionDistinctCountSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionDistinctCountSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionDistinctCountSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  FeedsByCreatedByConnectionDistinctCountVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionDistinctCountVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionDistinctCountWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionDistinctCountWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionMaxActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_ACTION_ID_ASC',
  FeedsByCreatedByConnectionMaxActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_ACTION_ID_DESC',
  FeedsByCreatedByConnectionMaxCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CALL_ID_ASC',
  FeedsByCreatedByConnectionMaxCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CALL_ID_DESC',
  FeedsByCreatedByConnectionMaxCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ASC',
  FeedsByCreatedByConnectionMaxCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_DESC',
  FeedsByCreatedByConnectionMaxCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionMaxCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionMaxCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionMaxCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionMaxCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  FeedsByCreatedByConnectionMaxCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  FeedsByCreatedByConnectionMaxCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  FeedsByCreatedByConnectionMaxCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionMaxCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionMaxCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  FeedsByCreatedByConnectionMaxCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionMaxCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionMaxEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionMaxEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionMaxEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EVENT_ASC',
  FeedsByCreatedByConnectionMaxEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EVENT_DESC',
  FeedsByCreatedByConnectionMaxEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionMaxEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionMaxFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_FILE_ID_ASC',
  FeedsByCreatedByConnectionMaxFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_FILE_ID_DESC',
  FeedsByCreatedByConnectionMaxIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  FeedsByCreatedByConnectionMaxIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  FeedsByCreatedByConnectionMaxParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PARENT_ID_ASC',
  FeedsByCreatedByConnectionMaxParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PARENT_ID_DESC',
  FeedsByCreatedByConnectionMaxPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PAYLOAD_ASC',
  FeedsByCreatedByConnectionMaxPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PAYLOAD_DESC',
  FeedsByCreatedByConnectionMaxProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionMaxProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionMaxReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionMaxReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionMaxRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionMaxRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionMaxRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRYABLE_ASC',
  FeedsByCreatedByConnectionMaxRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRYABLE_DESC',
  FeedsByCreatedByConnectionMaxRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionMaxRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionMaxSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionMaxSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionMaxSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionMaxSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionMaxSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionMaxSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionMaxTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  FeedsByCreatedByConnectionMaxTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  FeedsByCreatedByConnectionMaxVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionMaxVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionMaxWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionMaxWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MAX_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionMinActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_ACTION_ID_ASC',
  FeedsByCreatedByConnectionMinActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_ACTION_ID_DESC',
  FeedsByCreatedByConnectionMinCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CALL_ID_ASC',
  FeedsByCreatedByConnectionMinCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CALL_ID_DESC',
  FeedsByCreatedByConnectionMinCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ASC',
  FeedsByCreatedByConnectionMinCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_DESC',
  FeedsByCreatedByConnectionMinCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionMinCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionMinCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionMinCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionMinCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  FeedsByCreatedByConnectionMinCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  FeedsByCreatedByConnectionMinCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  FeedsByCreatedByConnectionMinCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionMinCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionMinCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  FeedsByCreatedByConnectionMinCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionMinCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionMinEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionMinEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionMinEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EVENT_ASC',
  FeedsByCreatedByConnectionMinEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EVENT_DESC',
  FeedsByCreatedByConnectionMinEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionMinEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionMinFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_FILE_ID_ASC',
  FeedsByCreatedByConnectionMinFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_FILE_ID_DESC',
  FeedsByCreatedByConnectionMinIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  FeedsByCreatedByConnectionMinIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  FeedsByCreatedByConnectionMinParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PARENT_ID_ASC',
  FeedsByCreatedByConnectionMinParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PARENT_ID_DESC',
  FeedsByCreatedByConnectionMinPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PAYLOAD_ASC',
  FeedsByCreatedByConnectionMinPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PAYLOAD_DESC',
  FeedsByCreatedByConnectionMinProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionMinProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionMinReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionMinReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionMinRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionMinRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionMinRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRYABLE_ASC',
  FeedsByCreatedByConnectionMinRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRYABLE_DESC',
  FeedsByCreatedByConnectionMinRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionMinRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionMinSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionMinSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionMinSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionMinSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionMinSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionMinSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionMinTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  FeedsByCreatedByConnectionMinTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  FeedsByCreatedByConnectionMinVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionMinVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionMinWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionMinWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_MIN_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALL_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALL_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ASC',
  FeedsByCreatedByConnectionStddevPopulationCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_DESC',
  FeedsByCreatedByConnectionStddevPopulationCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FeedsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionStddevPopulationCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionStddevPopulationEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EVENT_ASC',
  FeedsByCreatedByConnectionStddevPopulationEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EVENT_DESC',
  FeedsByCreatedByConnectionStddevPopulationEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionStddevPopulationEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionStddevPopulationFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FILE_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PAYLOAD_ASC',
  FeedsByCreatedByConnectionStddevPopulationPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PAYLOAD_DESC',
  FeedsByCreatedByConnectionStddevPopulationProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionStddevPopulationRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionStddevPopulationRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRYABLE_ASC',
  FeedsByCreatedByConnectionStddevPopulationRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRYABLE_DESC',
  FeedsByCreatedByConnectionStddevPopulationRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionStddevPopulationRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionStddevPopulationSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionStddevPopulationSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionStddevPopulationSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  FeedsByCreatedByConnectionStddevPopulationVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionStddevPopulationVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionStddevPopulationWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionStddevPopulationWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALL_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALL_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ASC',
  FeedsByCreatedByConnectionStddevSampleCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_DESC',
  FeedsByCreatedByConnectionStddevSampleCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FeedsByCreatedByConnectionStddevSampleCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FeedsByCreatedByConnectionStddevSampleCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FeedsByCreatedByConnectionStddevSampleCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FeedsByCreatedByConnectionStddevSampleCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionStddevSampleCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionStddevSampleEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EVENT_ASC',
  FeedsByCreatedByConnectionStddevSampleEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EVENT_DESC',
  FeedsByCreatedByConnectionStddevSampleEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionStddevSampleEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionStddevSampleFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FILE_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_ID_DESC',
  FeedsByCreatedByConnectionStddevSamplePayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PAYLOAD_ASC',
  FeedsByCreatedByConnectionStddevSamplePayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PAYLOAD_DESC',
  FeedsByCreatedByConnectionStddevSampleProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionStddevSampleRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionStddevSampleRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRYABLE_ASC',
  FeedsByCreatedByConnectionStddevSampleRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRYABLE_DESC',
  FeedsByCreatedByConnectionStddevSampleRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionStddevSampleRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionStddevSampleSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionStddevSampleSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionStddevSampleSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  FeedsByCreatedByConnectionStddevSampleVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionStddevSampleVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionStddevSampleWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionStddevSampleWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionSumActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_ACTION_ID_ASC',
  FeedsByCreatedByConnectionSumActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_ACTION_ID_DESC',
  FeedsByCreatedByConnectionSumCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CALL_ID_ASC',
  FeedsByCreatedByConnectionSumCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CALL_ID_DESC',
  FeedsByCreatedByConnectionSumCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ASC',
  FeedsByCreatedByConnectionSumCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_DESC',
  FeedsByCreatedByConnectionSumCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionSumCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionSumCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionSumCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionSumCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  FeedsByCreatedByConnectionSumCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  FeedsByCreatedByConnectionSumCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  FeedsByCreatedByConnectionSumCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionSumCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionSumCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  FeedsByCreatedByConnectionSumCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionSumCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionSumEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionSumEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionSumEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EVENT_ASC',
  FeedsByCreatedByConnectionSumEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EVENT_DESC',
  FeedsByCreatedByConnectionSumEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionSumEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionSumFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_FILE_ID_ASC',
  FeedsByCreatedByConnectionSumFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_FILE_ID_DESC',
  FeedsByCreatedByConnectionSumIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  FeedsByCreatedByConnectionSumIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  FeedsByCreatedByConnectionSumParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PARENT_ID_ASC',
  FeedsByCreatedByConnectionSumParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PARENT_ID_DESC',
  FeedsByCreatedByConnectionSumPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PAYLOAD_ASC',
  FeedsByCreatedByConnectionSumPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PAYLOAD_DESC',
  FeedsByCreatedByConnectionSumProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionSumProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionSumReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionSumReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionSumRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionSumRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionSumRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRYABLE_ASC',
  FeedsByCreatedByConnectionSumRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRYABLE_DESC',
  FeedsByCreatedByConnectionSumRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionSumRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionSumSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionSumSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionSumSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionSumSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionSumSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionSumSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionSumTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  FeedsByCreatedByConnectionSumTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  FeedsByCreatedByConnectionSumVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionSumVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionSumWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionSumWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_SUM_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALL_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALL_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ASC',
  FeedsByCreatedByConnectionVariancePopulationCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_DESC',
  FeedsByCreatedByConnectionVariancePopulationCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FeedsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionVariancePopulationCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionVariancePopulationEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EVENT_ASC',
  FeedsByCreatedByConnectionVariancePopulationEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EVENT_DESC',
  FeedsByCreatedByConnectionVariancePopulationEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionVariancePopulationEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionVariancePopulationFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FILE_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationPayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PAYLOAD_ASC',
  FeedsByCreatedByConnectionVariancePopulationPayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PAYLOAD_DESC',
  FeedsByCreatedByConnectionVariancePopulationProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionVariancePopulationRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionVariancePopulationRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRYABLE_ASC',
  FeedsByCreatedByConnectionVariancePopulationRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRYABLE_DESC',
  FeedsByCreatedByConnectionVariancePopulationRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionVariancePopulationRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionVariancePopulationSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionVariancePopulationSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionVariancePopulationSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  FeedsByCreatedByConnectionVariancePopulationVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionVariancePopulationVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionVariancePopulationWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionVariancePopulationWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WORKFLOW_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleActionIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleActionIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleCallIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALL_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleCallIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALL_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleCommentAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ASC',
  FeedsByCreatedByConnectionVarianceSampleCommentDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_DESC',
  FeedsByCreatedByConnectionVarianceSampleCommentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleCommentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FeedsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByContactIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByContactIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FeedsByCreatedByConnectionVarianceSampleCreatedByTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FeedsByCreatedByConnectionVarianceSampleEmailMessageIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleEmailMessageIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleEventAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EVENT_ASC',
  FeedsByCreatedByConnectionVarianceSampleEventDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EVENT_DESC',
  FeedsByCreatedByConnectionVarianceSampleEventTypeAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_ASC',
  FeedsByCreatedByConnectionVarianceSampleEventTypeDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EVENT_TYPE_DESC',
  FeedsByCreatedByConnectionVarianceSampleFileIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleFileIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FILE_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleParentIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleParentIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_ID_DESC',
  FeedsByCreatedByConnectionVarianceSamplePayloadAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_ASC',
  FeedsByCreatedByConnectionVarianceSamplePayloadDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PAYLOAD_DESC',
  FeedsByCreatedByConnectionVarianceSampleProjectIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleProjectIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleReminderIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleReminderIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REMINDER_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleRetriedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_ASC',
  FeedsByCreatedByConnectionVarianceSampleRetriedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRIED_AT_DESC',
  FeedsByCreatedByConnectionVarianceSampleRetryableAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_ASC',
  FeedsByCreatedByConnectionVarianceSampleRetryableDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRYABLE_DESC',
  FeedsByCreatedByConnectionVarianceSampleRetryCountAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_ASC',
  FeedsByCreatedByConnectionVarianceSampleRetryCountDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_RETRY_COUNT_DESC',
  FeedsByCreatedByConnectionVarianceSampleSearchTextAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_ASC',
  FeedsByCreatedByConnectionVarianceSampleSearchTextDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SEARCH_TEXT_DESC',
  FeedsByCreatedByConnectionVarianceSampleSmsActivityIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleSmsActivityIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SMS_ACTIVITY_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleSystemIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleSystemIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleTaskIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleTaskIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  FeedsByCreatedByConnectionVarianceSampleVirtualCreatedAtAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_ASC',
  FeedsByCreatedByConnectionVarianceSampleVirtualCreatedAtDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_CREATED_AT_DESC',
  FeedsByCreatedByConnectionVarianceSampleWorkflowIdAsc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_ASC',
  FeedsByCreatedByConnectionVarianceSampleWorkflowIdDesc = 'FEEDS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WORKFLOW_ID_DESC',
  FilesByCreatedByConnectionArrayAggActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ID_ASC',
  FilesByCreatedByConnectionArrayAggActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ID_DESC',
  FilesByCreatedByConnectionArrayAggActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionArrayAggActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionArrayAggAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionArrayAggAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionArrayAggCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ID_ASC',
  FilesByCreatedByConnectionArrayAggCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMMENT_ID_DESC',
  FilesByCreatedByConnectionArrayAggCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  FilesByCreatedByConnectionArrayAggCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  FilesByCreatedByConnectionArrayAggCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  FilesByCreatedByConnectionArrayAggCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  FilesByCreatedByConnectionArrayAggCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  FilesByCreatedByConnectionArrayAggCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionArrayAggCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionArrayAggCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  FilesByCreatedByConnectionArrayAggCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionArrayAggCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionArrayAggDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionArrayAggDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionArrayAggFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORM_ID_ASC',
  FilesByCreatedByConnectionArrayAggFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORM_ID_DESC',
  FilesByCreatedByConnectionArrayAggIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  FilesByCreatedByConnectionArrayAggIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  FilesByCreatedByConnectionArrayAggIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionArrayAggIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionArrayAggIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FORM_ASC',
  FilesByCreatedByConnectionArrayAggIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FORM_DESC',
  FilesByCreatedByConnectionArrayAggMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_META_DATA_ASC',
  FilesByCreatedByConnectionArrayAggMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_META_DATA_DESC',
  FilesByCreatedByConnectionArrayAggModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionArrayAggModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionArrayAggNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  FilesByCreatedByConnectionArrayAggNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  FilesByCreatedByConnectionArrayAggOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ORIGIN_ASC',
  FilesByCreatedByConnectionArrayAggOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ORIGIN_DESC',
  FilesByCreatedByConnectionArrayAggPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionArrayAggPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionArrayAggPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  FilesByCreatedByConnectionArrayAggPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  FilesByCreatedByConnectionArrayAggPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionArrayAggPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionArrayAggPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionArrayAggPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionArrayAggProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionArrayAggProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionArrayAggProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionArrayAggProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionArrayAggProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionArrayAggProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionArrayAggProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  FilesByCreatedByConnectionArrayAggProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  FilesByCreatedByConnectionArrayAggSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionArrayAggSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionArrayAggSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionArrayAggSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionArrayAggStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  FilesByCreatedByConnectionArrayAggStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  FilesByCreatedByConnectionArrayAggTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  FilesByCreatedByConnectionArrayAggTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  FilesByCreatedByConnectionArrayAggUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  FilesByCreatedByConnectionArrayAggUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  FilesByCreatedByConnectionArrayAggUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_ASC',
  FilesByCreatedByConnectionArrayAggUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_DESC',
  FilesByCreatedByConnectionArrayAggValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionArrayAggValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionAverageActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ID_ASC',
  FilesByCreatedByConnectionAverageActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ID_DESC',
  FilesByCreatedByConnectionAverageActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionAverageActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionAverageAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionAverageAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionAverageCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ID_ASC',
  FilesByCreatedByConnectionAverageCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_COMMENT_ID_DESC',
  FilesByCreatedByConnectionAverageCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  FilesByCreatedByConnectionAverageCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  FilesByCreatedByConnectionAverageCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  FilesByCreatedByConnectionAverageCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  FilesByCreatedByConnectionAverageCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  FilesByCreatedByConnectionAverageCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionAverageCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionAverageCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  FilesByCreatedByConnectionAverageCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionAverageCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionAverageDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionAverageDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionAverageFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_FORM_ID_ASC',
  FilesByCreatedByConnectionAverageFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_FORM_ID_DESC',
  FilesByCreatedByConnectionAverageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  FilesByCreatedByConnectionAverageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  FilesByCreatedByConnectionAverageIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionAverageIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionAverageIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FORM_ASC',
  FilesByCreatedByConnectionAverageIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FORM_DESC',
  FilesByCreatedByConnectionAverageMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_META_DATA_ASC',
  FilesByCreatedByConnectionAverageMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_META_DATA_DESC',
  FilesByCreatedByConnectionAverageModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionAverageModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionAverageNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  FilesByCreatedByConnectionAverageNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  FilesByCreatedByConnectionAverageOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ORIGIN_ASC',
  FilesByCreatedByConnectionAverageOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_ORIGIN_DESC',
  FilesByCreatedByConnectionAveragePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionAveragePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionAveragePrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  FilesByCreatedByConnectionAveragePrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  FilesByCreatedByConnectionAveragePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionAveragePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionAveragePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionAveragePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionAverageProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionAverageProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionAverageProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionAverageProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionAverageProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionAverageProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionAverageProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  FilesByCreatedByConnectionAverageProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  FilesByCreatedByConnectionAverageSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionAverageSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionAverageSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionAverageSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionAverageStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_ASC',
  FilesByCreatedByConnectionAverageStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_DESC',
  FilesByCreatedByConnectionAverageTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  FilesByCreatedByConnectionAverageTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  FilesByCreatedByConnectionAverageUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  FilesByCreatedByConnectionAverageUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  FilesByCreatedByConnectionAverageUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_ASC',
  FilesByCreatedByConnectionAverageUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_DESC',
  FilesByCreatedByConnectionAverageValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionAverageValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_AVERAGE_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionCountAsc = 'FILES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  FilesByCreatedByConnectionCountDesc = 'FILES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  FilesByCreatedByConnectionDistinctCountActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ID_ASC',
  FilesByCreatedByConnectionDistinctCountActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ID_DESC',
  FilesByCreatedByConnectionDistinctCountActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionDistinctCountActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionDistinctCountAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionDistinctCountAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionDistinctCountCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ID_ASC',
  FilesByCreatedByConnectionDistinctCountCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMMENT_ID_DESC',
  FilesByCreatedByConnectionDistinctCountCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  FilesByCreatedByConnectionDistinctCountCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  FilesByCreatedByConnectionDistinctCountCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  FilesByCreatedByConnectionDistinctCountCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  FilesByCreatedByConnectionDistinctCountCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  FilesByCreatedByConnectionDistinctCountCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionDistinctCountCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionDistinctCountCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  FilesByCreatedByConnectionDistinctCountCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionDistinctCountCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionDistinctCountDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionDistinctCountDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionDistinctCountFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORM_ID_ASC',
  FilesByCreatedByConnectionDistinctCountFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORM_ID_DESC',
  FilesByCreatedByConnectionDistinctCountIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  FilesByCreatedByConnectionDistinctCountIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  FilesByCreatedByConnectionDistinctCountIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionDistinctCountIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionDistinctCountIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FORM_ASC',
  FilesByCreatedByConnectionDistinctCountIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FORM_DESC',
  FilesByCreatedByConnectionDistinctCountMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_META_DATA_ASC',
  FilesByCreatedByConnectionDistinctCountMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_META_DATA_DESC',
  FilesByCreatedByConnectionDistinctCountModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionDistinctCountModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionDistinctCountNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  FilesByCreatedByConnectionDistinctCountNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  FilesByCreatedByConnectionDistinctCountOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ORIGIN_ASC',
  FilesByCreatedByConnectionDistinctCountOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ORIGIN_DESC',
  FilesByCreatedByConnectionDistinctCountPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionDistinctCountPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionDistinctCountPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  FilesByCreatedByConnectionDistinctCountPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  FilesByCreatedByConnectionDistinctCountPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionDistinctCountPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionDistinctCountPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionDistinctCountPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionDistinctCountProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionDistinctCountProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionDistinctCountProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionDistinctCountProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionDistinctCountProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionDistinctCountProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionDistinctCountProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  FilesByCreatedByConnectionDistinctCountProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  FilesByCreatedByConnectionDistinctCountSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionDistinctCountSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionDistinctCountSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionDistinctCountSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionDistinctCountStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  FilesByCreatedByConnectionDistinctCountStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  FilesByCreatedByConnectionDistinctCountTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  FilesByCreatedByConnectionDistinctCountTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  FilesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  FilesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  FilesByCreatedByConnectionDistinctCountUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  FilesByCreatedByConnectionDistinctCountUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  FilesByCreatedByConnectionDistinctCountValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionDistinctCountValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionMaxActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ACTION_ID_ASC',
  FilesByCreatedByConnectionMaxActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ACTION_ID_DESC',
  FilesByCreatedByConnectionMaxActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionMaxActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionMaxAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionMaxAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionMaxCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ID_ASC',
  FilesByCreatedByConnectionMaxCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_COMMENT_ID_DESC',
  FilesByCreatedByConnectionMaxCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  FilesByCreatedByConnectionMaxCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  FilesByCreatedByConnectionMaxCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  FilesByCreatedByConnectionMaxCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  FilesByCreatedByConnectionMaxCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  FilesByCreatedByConnectionMaxCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionMaxCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionMaxCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  FilesByCreatedByConnectionMaxCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionMaxCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionMaxDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionMaxDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionMaxFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_FORM_ID_ASC',
  FilesByCreatedByConnectionMaxFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_FORM_ID_DESC',
  FilesByCreatedByConnectionMaxIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  FilesByCreatedByConnectionMaxIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  FilesByCreatedByConnectionMaxIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionMaxIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionMaxIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_IS_FORM_ASC',
  FilesByCreatedByConnectionMaxIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_IS_FORM_DESC',
  FilesByCreatedByConnectionMaxMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_META_DATA_ASC',
  FilesByCreatedByConnectionMaxMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_META_DATA_DESC',
  FilesByCreatedByConnectionMaxModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionMaxModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionMaxNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  FilesByCreatedByConnectionMaxNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  FilesByCreatedByConnectionMaxOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ORIGIN_ASC',
  FilesByCreatedByConnectionMaxOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_ORIGIN_DESC',
  FilesByCreatedByConnectionMaxPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionMaxPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionMaxPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ASC',
  FilesByCreatedByConnectionMaxPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_DESC',
  FilesByCreatedByConnectionMaxPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionMaxPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionMaxPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionMaxPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionMaxProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionMaxProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionMaxProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionMaxProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionMaxProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionMaxProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionMaxProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  FilesByCreatedByConnectionMaxProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  FilesByCreatedByConnectionMaxSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionMaxSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionMaxSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionMaxSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionMaxStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_ASC',
  FilesByCreatedByConnectionMaxStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_DESC',
  FilesByCreatedByConnectionMaxTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  FilesByCreatedByConnectionMaxTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  FilesByCreatedByConnectionMaxUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  FilesByCreatedByConnectionMaxUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  FilesByCreatedByConnectionMaxUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_UUID_ASC',
  FilesByCreatedByConnectionMaxUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_UUID_DESC',
  FilesByCreatedByConnectionMaxValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionMaxValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MAX_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionMinActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ACTION_ID_ASC',
  FilesByCreatedByConnectionMinActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ACTION_ID_DESC',
  FilesByCreatedByConnectionMinActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionMinActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionMinAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionMinAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionMinCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ID_ASC',
  FilesByCreatedByConnectionMinCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_COMMENT_ID_DESC',
  FilesByCreatedByConnectionMinCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  FilesByCreatedByConnectionMinCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  FilesByCreatedByConnectionMinCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  FilesByCreatedByConnectionMinCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  FilesByCreatedByConnectionMinCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  FilesByCreatedByConnectionMinCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionMinCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionMinCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  FilesByCreatedByConnectionMinCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionMinCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionMinDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionMinDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionMinFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_FORM_ID_ASC',
  FilesByCreatedByConnectionMinFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_FORM_ID_DESC',
  FilesByCreatedByConnectionMinIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  FilesByCreatedByConnectionMinIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  FilesByCreatedByConnectionMinIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionMinIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionMinIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_IS_FORM_ASC',
  FilesByCreatedByConnectionMinIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_IS_FORM_DESC',
  FilesByCreatedByConnectionMinMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_META_DATA_ASC',
  FilesByCreatedByConnectionMinMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_META_DATA_DESC',
  FilesByCreatedByConnectionMinModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionMinModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionMinNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  FilesByCreatedByConnectionMinNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  FilesByCreatedByConnectionMinOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ORIGIN_ASC',
  FilesByCreatedByConnectionMinOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_ORIGIN_DESC',
  FilesByCreatedByConnectionMinPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionMinPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionMinPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ASC',
  FilesByCreatedByConnectionMinPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_DESC',
  FilesByCreatedByConnectionMinPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionMinPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionMinPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionMinPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionMinProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionMinProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionMinProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionMinProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionMinProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionMinProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionMinProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  FilesByCreatedByConnectionMinProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  FilesByCreatedByConnectionMinSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionMinSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionMinSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionMinSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionMinStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_ASC',
  FilesByCreatedByConnectionMinStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_DESC',
  FilesByCreatedByConnectionMinTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  FilesByCreatedByConnectionMinTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  FilesByCreatedByConnectionMinUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  FilesByCreatedByConnectionMinUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  FilesByCreatedByConnectionMinUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_UUID_ASC',
  FilesByCreatedByConnectionMinUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_UUID_DESC',
  FilesByCreatedByConnectionMinValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionMinValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_MIN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionStddevPopulationAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionStddevPopulationCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMMENT_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  FilesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  FilesByCreatedByConnectionStddevPopulationCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  FilesByCreatedByConnectionStddevPopulationCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  FilesByCreatedByConnectionStddevPopulationCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionStddevPopulationCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionStddevPopulationDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionStddevPopulationDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionStddevPopulationFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORM_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORM_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionStddevPopulationIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionStddevPopulationIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FORM_ASC',
  FilesByCreatedByConnectionStddevPopulationIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FORM_DESC',
  FilesByCreatedByConnectionStddevPopulationMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_META_DATA_ASC',
  FilesByCreatedByConnectionStddevPopulationMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_META_DATA_DESC',
  FilesByCreatedByConnectionStddevPopulationModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionStddevPopulationModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionStddevPopulationNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  FilesByCreatedByConnectionStddevPopulationNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  FilesByCreatedByConnectionStddevPopulationOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ORIGIN_ASC',
  FilesByCreatedByConnectionStddevPopulationOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ORIGIN_DESC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionStddevPopulationPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionStddevPopulationProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionStddevPopulationProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionStddevPopulationProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionStddevPopulationSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionStddevPopulationSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionStddevPopulationSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionStddevPopulationStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  FilesByCreatedByConnectionStddevPopulationTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  FilesByCreatedByConnectionStddevPopulationTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  FilesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  FilesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  FilesByCreatedByConnectionStddevPopulationUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  FilesByCreatedByConnectionStddevPopulationUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  FilesByCreatedByConnectionStddevPopulationValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionStddevPopulationValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionStddevSampleActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ID_ASC',
  FilesByCreatedByConnectionStddevSampleActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ID_DESC',
  FilesByCreatedByConnectionStddevSampleActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionStddevSampleActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionStddevSampleAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionStddevSampleAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionStddevSampleCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_ASC',
  FilesByCreatedByConnectionStddevSampleCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMMENT_ID_DESC',
  FilesByCreatedByConnectionStddevSampleCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  FilesByCreatedByConnectionStddevSampleCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  FilesByCreatedByConnectionStddevSampleCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  FilesByCreatedByConnectionStddevSampleCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  FilesByCreatedByConnectionStddevSampleCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  FilesByCreatedByConnectionStddevSampleCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionStddevSampleCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionStddevSampleCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  FilesByCreatedByConnectionStddevSampleCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionStddevSampleCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionStddevSampleDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionStddevSampleDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionStddevSampleFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORM_ID_ASC',
  FilesByCreatedByConnectionStddevSampleFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORM_ID_DESC',
  FilesByCreatedByConnectionStddevSampleIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  FilesByCreatedByConnectionStddevSampleIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  FilesByCreatedByConnectionStddevSampleIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionStddevSampleIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionStddevSampleIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FORM_ASC',
  FilesByCreatedByConnectionStddevSampleIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FORM_DESC',
  FilesByCreatedByConnectionStddevSampleMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_META_DATA_ASC',
  FilesByCreatedByConnectionStddevSampleMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_META_DATA_DESC',
  FilesByCreatedByConnectionStddevSampleModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionStddevSampleModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionStddevSampleNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  FilesByCreatedByConnectionStddevSampleNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  FilesByCreatedByConnectionStddevSampleOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ORIGIN_ASC',
  FilesByCreatedByConnectionStddevSampleOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ORIGIN_DESC',
  FilesByCreatedByConnectionStddevSamplePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionStddevSamplePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionStddevSamplePrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  FilesByCreatedByConnectionStddevSamplePrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  FilesByCreatedByConnectionStddevSamplePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionStddevSamplePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionStddevSamplePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionStddevSamplePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionStddevSampleProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionStddevSampleProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionStddevSampleProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionStddevSampleProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionStddevSampleProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionStddevSampleProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionStddevSampleProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  FilesByCreatedByConnectionStddevSampleProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  FilesByCreatedByConnectionStddevSampleSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionStddevSampleSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionStddevSampleSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionStddevSampleSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionStddevSampleStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  FilesByCreatedByConnectionStddevSampleStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  FilesByCreatedByConnectionStddevSampleTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  FilesByCreatedByConnectionStddevSampleTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  FilesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  FilesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  FilesByCreatedByConnectionStddevSampleUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  FilesByCreatedByConnectionStddevSampleUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  FilesByCreatedByConnectionStddevSampleValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionStddevSampleValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionSumActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ACTION_ID_ASC',
  FilesByCreatedByConnectionSumActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ACTION_ID_DESC',
  FilesByCreatedByConnectionSumActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionSumActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionSumAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionSumAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionSumCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ID_ASC',
  FilesByCreatedByConnectionSumCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_COMMENT_ID_DESC',
  FilesByCreatedByConnectionSumCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  FilesByCreatedByConnectionSumCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  FilesByCreatedByConnectionSumCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  FilesByCreatedByConnectionSumCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  FilesByCreatedByConnectionSumCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  FilesByCreatedByConnectionSumCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionSumCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionSumCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  FilesByCreatedByConnectionSumCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionSumCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionSumDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionSumDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionSumFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_FORM_ID_ASC',
  FilesByCreatedByConnectionSumFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_FORM_ID_DESC',
  FilesByCreatedByConnectionSumIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  FilesByCreatedByConnectionSumIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  FilesByCreatedByConnectionSumIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionSumIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionSumIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_IS_FORM_ASC',
  FilesByCreatedByConnectionSumIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_IS_FORM_DESC',
  FilesByCreatedByConnectionSumMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_META_DATA_ASC',
  FilesByCreatedByConnectionSumMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_META_DATA_DESC',
  FilesByCreatedByConnectionSumModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionSumModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionSumNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  FilesByCreatedByConnectionSumNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  FilesByCreatedByConnectionSumOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ORIGIN_ASC',
  FilesByCreatedByConnectionSumOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_ORIGIN_DESC',
  FilesByCreatedByConnectionSumPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionSumPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionSumPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ASC',
  FilesByCreatedByConnectionSumPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_DESC',
  FilesByCreatedByConnectionSumPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionSumPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionSumPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionSumPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionSumProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionSumProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionSumProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionSumProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionSumProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionSumProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionSumProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  FilesByCreatedByConnectionSumProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  FilesByCreatedByConnectionSumSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionSumSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionSumSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionSumSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionSumStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_ASC',
  FilesByCreatedByConnectionSumStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_DESC',
  FilesByCreatedByConnectionSumTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  FilesByCreatedByConnectionSumTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  FilesByCreatedByConnectionSumUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  FilesByCreatedByConnectionSumUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  FilesByCreatedByConnectionSumUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_UUID_ASC',
  FilesByCreatedByConnectionSumUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_UUID_DESC',
  FilesByCreatedByConnectionSumValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionSumValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_SUM_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionVariancePopulationAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionVariancePopulationCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMMENT_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  FilesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  FilesByCreatedByConnectionVariancePopulationCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  FilesByCreatedByConnectionVariancePopulationCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  FilesByCreatedByConnectionVariancePopulationCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionVariancePopulationCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionVariancePopulationDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionVariancePopulationDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionVariancePopulationFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORM_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORM_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionVariancePopulationIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionVariancePopulationIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FORM_ASC',
  FilesByCreatedByConnectionVariancePopulationIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FORM_DESC',
  FilesByCreatedByConnectionVariancePopulationMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_META_DATA_ASC',
  FilesByCreatedByConnectionVariancePopulationMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_META_DATA_DESC',
  FilesByCreatedByConnectionVariancePopulationModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionVariancePopulationModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionVariancePopulationNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  FilesByCreatedByConnectionVariancePopulationNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  FilesByCreatedByConnectionVariancePopulationOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ORIGIN_ASC',
  FilesByCreatedByConnectionVariancePopulationOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ORIGIN_DESC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionVariancePopulationPrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionVariancePopulationProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionVariancePopulationProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionVariancePopulationProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionVariancePopulationSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionVariancePopulationSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionVariancePopulationSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionVariancePopulationStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  FilesByCreatedByConnectionVariancePopulationTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  FilesByCreatedByConnectionVariancePopulationTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  FilesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  FilesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  FilesByCreatedByConnectionVariancePopulationUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  FilesByCreatedByConnectionVariancePopulationUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  FilesByCreatedByConnectionVariancePopulationValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionVariancePopulationValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleActionIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleActionIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleActionItemIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleActionItemIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACTION_ITEM_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleAnnotationsAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_ASC',
  FilesByCreatedByConnectionVarianceSampleAnnotationsDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ANNOTATIONS_DESC',
  FilesByCreatedByConnectionVarianceSampleCommentIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleCommentIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMMENT_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  FilesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  FilesByCreatedByConnectionVarianceSampleCreatedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  FilesByCreatedByConnectionVarianceSampleCreatedByContactIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleCreatedByContactIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleCreatedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  FilesByCreatedByConnectionVarianceSampleCreatedByTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  FilesByCreatedByConnectionVarianceSampleCreatedByTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  FilesByCreatedByConnectionVarianceSampleDownloadUrlAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_ASC',
  FilesByCreatedByConnectionVarianceSampleDownloadUrlDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DOWNLOAD_URL_DESC',
  FilesByCreatedByConnectionVarianceSampleFormIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORM_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleFormIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORM_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleIsArchivedAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  FilesByCreatedByConnectionVarianceSampleIsArchivedDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  FilesByCreatedByConnectionVarianceSampleIsFormAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FORM_ASC',
  FilesByCreatedByConnectionVarianceSampleIsFormDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FORM_DESC',
  FilesByCreatedByConnectionVarianceSampleMetaDataAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_META_DATA_ASC',
  FilesByCreatedByConnectionVarianceSampleMetaDataDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_META_DATA_DESC',
  FilesByCreatedByConnectionVarianceSampleModifiedByAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_ASC',
  FilesByCreatedByConnectionVarianceSampleModifiedByDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_BY_DESC',
  FilesByCreatedByConnectionVarianceSampleNameAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  FilesByCreatedByConnectionVarianceSampleNameDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  FilesByCreatedByConnectionVarianceSampleOriginAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ORIGIN_ASC',
  FilesByCreatedByConnectionVarianceSampleOriginDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ORIGIN_DESC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeAllAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeAllDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeOwnAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeOwnDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeTeamAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  FilesByCreatedByConnectionVarianceSamplePrivilegeTeamDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  FilesByCreatedByConnectionVarianceSampleProcessingStatusAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_ASC',
  FilesByCreatedByConnectionVarianceSampleProcessingStatusDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROCESSING_STATUS_DESC',
  FilesByCreatedByConnectionVarianceSampleProjectColumnValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleProjectColumnValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_FILE_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleProjectColumnValueIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleProjectColumnValueIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_VALUE_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleProjectIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleProjectIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleSharedWithPortalAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_ASC',
  FilesByCreatedByConnectionVarianceSampleSharedWithPortalDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARED_WITH_PORTAL_DESC',
  FilesByCreatedByConnectionVarianceSampleSizeInBytesAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  FilesByCreatedByConnectionVarianceSampleSizeInBytesDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  FilesByCreatedByConnectionVarianceSampleStageIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleStageIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  FilesByCreatedByConnectionVarianceSampleTypeAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  FilesByCreatedByConnectionVarianceSampleTypeDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  FilesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  FilesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  FilesByCreatedByConnectionVarianceSampleUuidAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  FilesByCreatedByConnectionVarianceSampleUuidDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  FilesByCreatedByConnectionVarianceSampleValueFileIdAsc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_ASC',
  FilesByCreatedByConnectionVarianceSampleValueFileIdDesc = 'FILES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VALUE_FILE_ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JurisdictionsByCreatedByConnectionArrayAggAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionArrayAggAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionArrayAggCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionArrayAggCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionArrayAggCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionArrayAggCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionArrayAggCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionArrayAggCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionArrayAggEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionArrayAggEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionArrayAggFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FEES_ASC',
  JurisdictionsByCreatedByConnectionArrayAggFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FEES_DESC',
  JurisdictionsByCreatedByConnectionArrayAggIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  JurisdictionsByCreatedByConnectionArrayAggIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  JurisdictionsByCreatedByConnectionArrayAggIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionArrayAggIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionArrayAggNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  JurisdictionsByCreatedByConnectionArrayAggNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  JurisdictionsByCreatedByConnectionArrayAggNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTES_ASC',
  JurisdictionsByCreatedByConnectionArrayAggNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTES_DESC',
  JurisdictionsByCreatedByConnectionArrayAggPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONES_ASC',
  JurisdictionsByCreatedByConnectionArrayAggPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONES_DESC',
  JurisdictionsByCreatedByConnectionArrayAggPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionArrayAggPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionArrayAggPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionArrayAggPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionArrayAggSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionArrayAggSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionArrayAggTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionArrayAggTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionArrayAggTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  JurisdictionsByCreatedByConnectionArrayAggTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  JurisdictionsByCreatedByConnectionArrayAggUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionArrayAggUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionArrayAggUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_ASC',
  JurisdictionsByCreatedByConnectionArrayAggUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UUID_DESC',
  JurisdictionsByCreatedByConnectionArrayAggWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionArrayAggWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionAverageAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionAverageAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionAverageCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionAverageCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionAverageCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionAverageCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionAverageCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionAverageCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionAverageEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionAverageEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionAverageFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_FEES_ASC',
  JurisdictionsByCreatedByConnectionAverageFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_FEES_DESC',
  JurisdictionsByCreatedByConnectionAverageIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  JurisdictionsByCreatedByConnectionAverageIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  JurisdictionsByCreatedByConnectionAverageIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionAverageIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionAverageNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  JurisdictionsByCreatedByConnectionAverageNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  JurisdictionsByCreatedByConnectionAverageNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_NOTES_ASC',
  JurisdictionsByCreatedByConnectionAverageNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_NOTES_DESC',
  JurisdictionsByCreatedByConnectionAveragePhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONES_ASC',
  JurisdictionsByCreatedByConnectionAveragePhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONES_DESC',
  JurisdictionsByCreatedByConnectionAveragePointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionAveragePointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionAveragePortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionAveragePortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionAverageSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionAverageSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionAverageTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionAverageTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionAverageTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  JurisdictionsByCreatedByConnectionAverageTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  JurisdictionsByCreatedByConnectionAverageUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionAverageUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionAverageUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_ASC',
  JurisdictionsByCreatedByConnectionAverageUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_UUID_DESC',
  JurisdictionsByCreatedByConnectionAverageWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionAverageWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_AVERAGE_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionCountAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  JurisdictionsByCreatedByConnectionCountDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FEES_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FEES_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONES_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONES_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UUID_DESC',
  JurisdictionsByCreatedByConnectionDistinctCountWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionDistinctCountWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionMaxAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionMaxAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionMaxCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionMaxCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionMaxCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionMaxCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionMaxCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionMaxCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionMaxEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionMaxEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionMaxFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_FEES_ASC',
  JurisdictionsByCreatedByConnectionMaxFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_FEES_DESC',
  JurisdictionsByCreatedByConnectionMaxIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  JurisdictionsByCreatedByConnectionMaxIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  JurisdictionsByCreatedByConnectionMaxIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionMaxIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionMaxNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  JurisdictionsByCreatedByConnectionMaxNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  JurisdictionsByCreatedByConnectionMaxNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_NOTES_ASC',
  JurisdictionsByCreatedByConnectionMaxNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_NOTES_DESC',
  JurisdictionsByCreatedByConnectionMaxPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_PHONES_ASC',
  JurisdictionsByCreatedByConnectionMaxPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_PHONES_DESC',
  JurisdictionsByCreatedByConnectionMaxPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionMaxPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionMaxPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionMaxPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionMaxSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionMaxSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionMaxTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionMaxTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionMaxTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  JurisdictionsByCreatedByConnectionMaxTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  JurisdictionsByCreatedByConnectionMaxUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionMaxUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionMaxUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_ASC',
  JurisdictionsByCreatedByConnectionMaxUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_UUID_DESC',
  JurisdictionsByCreatedByConnectionMaxWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionMaxWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MAX_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionMinAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionMinAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionMinCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionMinCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionMinCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionMinCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionMinCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionMinCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionMinEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionMinEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionMinFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_FEES_ASC',
  JurisdictionsByCreatedByConnectionMinFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_FEES_DESC',
  JurisdictionsByCreatedByConnectionMinIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  JurisdictionsByCreatedByConnectionMinIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  JurisdictionsByCreatedByConnectionMinIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionMinIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionMinNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  JurisdictionsByCreatedByConnectionMinNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  JurisdictionsByCreatedByConnectionMinNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_NOTES_ASC',
  JurisdictionsByCreatedByConnectionMinNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_NOTES_DESC',
  JurisdictionsByCreatedByConnectionMinPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_PHONES_ASC',
  JurisdictionsByCreatedByConnectionMinPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_PHONES_DESC',
  JurisdictionsByCreatedByConnectionMinPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionMinPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionMinPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionMinPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionMinSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionMinSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionMinTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionMinTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionMinTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  JurisdictionsByCreatedByConnectionMinTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  JurisdictionsByCreatedByConnectionMinUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionMinUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionMinUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_ASC',
  JurisdictionsByCreatedByConnectionMinUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_UUID_DESC',
  JurisdictionsByCreatedByConnectionMinWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionMinWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_MIN_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FEES_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FEES_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONES_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONES_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UUID_DESC',
  JurisdictionsByCreatedByConnectionStddevPopulationWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionStddevPopulationWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FEES_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FEES_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  JurisdictionsByCreatedByConnectionStddevSamplePhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONES_ASC',
  JurisdictionsByCreatedByConnectionStddevSamplePhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONES_DESC',
  JurisdictionsByCreatedByConnectionStddevSamplePointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionStddevSamplePointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionStddevSamplePortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionStddevSamplePortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UUID_DESC',
  JurisdictionsByCreatedByConnectionStddevSampleWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionStddevSampleWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionSumAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionSumAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionSumCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionSumCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionSumCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionSumCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionSumCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionSumCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionSumEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionSumEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionSumFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_FEES_ASC',
  JurisdictionsByCreatedByConnectionSumFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_FEES_DESC',
  JurisdictionsByCreatedByConnectionSumIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  JurisdictionsByCreatedByConnectionSumIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  JurisdictionsByCreatedByConnectionSumIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionSumIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionSumNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  JurisdictionsByCreatedByConnectionSumNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  JurisdictionsByCreatedByConnectionSumNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_NOTES_ASC',
  JurisdictionsByCreatedByConnectionSumNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_NOTES_DESC',
  JurisdictionsByCreatedByConnectionSumPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_PHONES_ASC',
  JurisdictionsByCreatedByConnectionSumPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_PHONES_DESC',
  JurisdictionsByCreatedByConnectionSumPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionSumPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionSumPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionSumPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionSumSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionSumSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionSumTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionSumTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionSumTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  JurisdictionsByCreatedByConnectionSumTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  JurisdictionsByCreatedByConnectionSumUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionSumUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionSumUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_ASC',
  JurisdictionsByCreatedByConnectionSumUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_UUID_DESC',
  JurisdictionsByCreatedByConnectionSumWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionSumWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_SUM_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FEES_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FEES_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationPhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONES_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationPhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONES_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationPointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationPointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationPortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationPortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UUID_DESC',
  JurisdictionsByCreatedByConnectionVariancePopulationWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionVariancePopulationWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WEBSITE_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleAddressAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleAddressDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleCreatedByAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleCreatedByDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleEmailsAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAILS_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleEmailsDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EMAILS_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleFeesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FEES_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleFeesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FEES_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleIdAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleIdDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleIsPrivateAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PRIVATE_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleIsPrivateDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_PRIVATE_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleNameAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleNameDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleNotesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleNotesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  JurisdictionsByCreatedByConnectionVarianceSamplePhonesAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONES_ASC',
  JurisdictionsByCreatedByConnectionVarianceSamplePhonesDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONES_DESC',
  JurisdictionsByCreatedByConnectionVarianceSamplePointOfContactAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POINT_OF_CONTACT_ASC',
  JurisdictionsByCreatedByConnectionVarianceSamplePointOfContactDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POINT_OF_CONTACT_DESC',
  JurisdictionsByCreatedByConnectionVarianceSamplePortalAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PORTAL_ASC',
  JurisdictionsByCreatedByConnectionVarianceSamplePortalDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PORTAL_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleSubmissionMethodAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SUBMISSION_METHOD_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleSubmissionMethodDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SUBMISSION_METHOD_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleTurnaroundAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TURNAROUND_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleTurnaroundDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TURNAROUND_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleTypeAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleTypeDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleUuidAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleUuidDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UUID_DESC',
  JurisdictionsByCreatedByConnectionVarianceSampleWebsiteAsc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WEBSITE_ASC',
  JurisdictionsByCreatedByConnectionVarianceSampleWebsiteDesc = 'JURISDICTIONS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WEBSITE_DESC',
  LabelsByCreatedByConnectionArrayAggColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COLOR_ASC',
  LabelsByCreatedByConnectionArrayAggColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COLOR_DESC',
  LabelsByCreatedByConnectionArrayAggCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionArrayAggCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionArrayAggCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  LabelsByCreatedByConnectionArrayAggCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  LabelsByCreatedByConnectionArrayAggCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  LabelsByCreatedByConnectionArrayAggCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  LabelsByCreatedByConnectionArrayAggIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  LabelsByCreatedByConnectionArrayAggIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  LabelsByCreatedByConnectionArrayAggIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionArrayAggIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionArrayAggLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_ASC',
  LabelsByCreatedByConnectionArrayAggLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LABEL_DESC',
  LabelsByCreatedByConnectionArrayAggUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionArrayAggUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionAverageColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_COLOR_ASC',
  LabelsByCreatedByConnectionAverageColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_COLOR_DESC',
  LabelsByCreatedByConnectionAverageCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionAverageCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionAverageCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  LabelsByCreatedByConnectionAverageCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  LabelsByCreatedByConnectionAverageCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  LabelsByCreatedByConnectionAverageCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  LabelsByCreatedByConnectionAverageIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  LabelsByCreatedByConnectionAverageIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  LabelsByCreatedByConnectionAverageIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionAverageIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionAverageLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_ASC',
  LabelsByCreatedByConnectionAverageLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_LABEL_DESC',
  LabelsByCreatedByConnectionAverageUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionAverageUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionCountAsc = 'LABELS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  LabelsByCreatedByConnectionCountDesc = 'LABELS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  LabelsByCreatedByConnectionDistinctCountColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COLOR_ASC',
  LabelsByCreatedByConnectionDistinctCountColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COLOR_DESC',
  LabelsByCreatedByConnectionDistinctCountCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionDistinctCountCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionDistinctCountCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  LabelsByCreatedByConnectionDistinctCountCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  LabelsByCreatedByConnectionDistinctCountCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  LabelsByCreatedByConnectionDistinctCountCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  LabelsByCreatedByConnectionDistinctCountIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  LabelsByCreatedByConnectionDistinctCountIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  LabelsByCreatedByConnectionDistinctCountIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionDistinctCountIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionDistinctCountLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_ASC',
  LabelsByCreatedByConnectionDistinctCountLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LABEL_DESC',
  LabelsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionMaxColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_COLOR_ASC',
  LabelsByCreatedByConnectionMaxColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_COLOR_DESC',
  LabelsByCreatedByConnectionMaxCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionMaxCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionMaxCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  LabelsByCreatedByConnectionMaxCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  LabelsByCreatedByConnectionMaxCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  LabelsByCreatedByConnectionMaxCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  LabelsByCreatedByConnectionMaxIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  LabelsByCreatedByConnectionMaxIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  LabelsByCreatedByConnectionMaxIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionMaxIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionMaxLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_LABEL_ASC',
  LabelsByCreatedByConnectionMaxLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_LABEL_DESC',
  LabelsByCreatedByConnectionMaxUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionMaxUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionMinColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_COLOR_ASC',
  LabelsByCreatedByConnectionMinColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_COLOR_DESC',
  LabelsByCreatedByConnectionMinCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionMinCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionMinCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  LabelsByCreatedByConnectionMinCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  LabelsByCreatedByConnectionMinCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  LabelsByCreatedByConnectionMinCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  LabelsByCreatedByConnectionMinIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  LabelsByCreatedByConnectionMinIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  LabelsByCreatedByConnectionMinIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionMinIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionMinLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_LABEL_ASC',
  LabelsByCreatedByConnectionMinLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_LABEL_DESC',
  LabelsByCreatedByConnectionMinUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionMinUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionStddevPopulationColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COLOR_ASC',
  LabelsByCreatedByConnectionStddevPopulationColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COLOR_DESC',
  LabelsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  LabelsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  LabelsByCreatedByConnectionStddevPopulationCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  LabelsByCreatedByConnectionStddevPopulationCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  LabelsByCreatedByConnectionStddevPopulationIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  LabelsByCreatedByConnectionStddevPopulationIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  LabelsByCreatedByConnectionStddevPopulationIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionStddevPopulationIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionStddevPopulationLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_ASC',
  LabelsByCreatedByConnectionStddevPopulationLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LABEL_DESC',
  LabelsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionStddevSampleColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COLOR_ASC',
  LabelsByCreatedByConnectionStddevSampleColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COLOR_DESC',
  LabelsByCreatedByConnectionStddevSampleCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionStddevSampleCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionStddevSampleCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  LabelsByCreatedByConnectionStddevSampleCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  LabelsByCreatedByConnectionStddevSampleCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  LabelsByCreatedByConnectionStddevSampleCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  LabelsByCreatedByConnectionStddevSampleIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  LabelsByCreatedByConnectionStddevSampleIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  LabelsByCreatedByConnectionStddevSampleIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionStddevSampleIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionStddevSampleLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_ASC',
  LabelsByCreatedByConnectionStddevSampleLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LABEL_DESC',
  LabelsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionSumColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_COLOR_ASC',
  LabelsByCreatedByConnectionSumColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_COLOR_DESC',
  LabelsByCreatedByConnectionSumCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionSumCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionSumCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  LabelsByCreatedByConnectionSumCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  LabelsByCreatedByConnectionSumCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  LabelsByCreatedByConnectionSumCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  LabelsByCreatedByConnectionSumIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  LabelsByCreatedByConnectionSumIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  LabelsByCreatedByConnectionSumIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionSumIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionSumLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_LABEL_ASC',
  LabelsByCreatedByConnectionSumLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_LABEL_DESC',
  LabelsByCreatedByConnectionSumUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionSumUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionVariancePopulationColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COLOR_ASC',
  LabelsByCreatedByConnectionVariancePopulationColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COLOR_DESC',
  LabelsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  LabelsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  LabelsByCreatedByConnectionVariancePopulationCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  LabelsByCreatedByConnectionVariancePopulationCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  LabelsByCreatedByConnectionVariancePopulationIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  LabelsByCreatedByConnectionVariancePopulationIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  LabelsByCreatedByConnectionVariancePopulationIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionVariancePopulationIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionVariancePopulationLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_ASC',
  LabelsByCreatedByConnectionVariancePopulationLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LABEL_DESC',
  LabelsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  LabelsByCreatedByConnectionVarianceSampleColorAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COLOR_ASC',
  LabelsByCreatedByConnectionVarianceSampleColorDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COLOR_DESC',
  LabelsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LabelsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LabelsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  LabelsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  LabelsByCreatedByConnectionVarianceSampleCreatedByAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  LabelsByCreatedByConnectionVarianceSampleCreatedByDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  LabelsByCreatedByConnectionVarianceSampleIdAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  LabelsByCreatedByConnectionVarianceSampleIdDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  LabelsByCreatedByConnectionVarianceSampleIsActiveAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  LabelsByCreatedByConnectionVarianceSampleIsActiveDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  LabelsByCreatedByConnectionVarianceSampleLabelAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_ASC',
  LabelsByCreatedByConnectionVarianceSampleLabelDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LABEL_DESC',
  LabelsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  LabelsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'LABELS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  Natural = 'NATURAL',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectsByCreatedByConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionArrayAggAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByCreatedByConnectionArrayAggAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByCreatedByConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionArrayAggAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByCreatedByConnectionArrayAggAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByCreatedByConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByCreatedByConnectionArrayAggCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByCreatedByConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionArrayAggDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionArrayAggDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionArrayAggIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByCreatedByConnectionArrayAggPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByCreatedByConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionArrayAggProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByCreatedByConnectionArrayAggProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByCreatedByConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionArrayAggRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByCreatedByConnectionArrayAggRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByCreatedByConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByCreatedByConnectionArrayAggSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByCreatedByConnectionArrayAggStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionArrayAggStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionArrayAggStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByCreatedByConnectionArrayAggStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByCreatedByConnectionArrayAggStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByCreatedByConnectionArrayAggStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByCreatedByConnectionArrayAggStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByCreatedByConnectionArrayAggStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByCreatedByConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionArrayAggTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByCreatedByConnectionArrayAggTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByCreatedByConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionArrayAggTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByCreatedByConnectionArrayAggTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByCreatedByConnectionArrayAggTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByCreatedByConnectionArrayAggTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByCreatedByConnectionArrayAggUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByCreatedByConnectionArrayAggUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByCreatedByConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionAverageAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionAverageAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionAverageAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionAverageAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionAverageAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByCreatedByConnectionAverageAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByCreatedByConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionAverageAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByCreatedByConnectionAverageAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByCreatedByConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionAverageCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByCreatedByConnectionAverageCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByCreatedByConnectionAverageCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionAverageCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionAverageCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionAverageCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionAverageConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionAverageConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionAverageCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionAverageCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionAverageCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionAverageCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionAverageDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionAverageDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionAverageDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionAverageDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionAverageDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionAverageDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionAverageFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionAverageFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionAverageGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionAverageGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionAverageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByCreatedByConnectionAverageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByCreatedByConnectionAverageImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionAverageImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionAverageIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionAverageIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionAverageIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionAverageIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionAverageLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionAverageLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionAverageOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionAverageOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionAverageOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionAverageOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionAveragePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByCreatedByConnectionAveragePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByCreatedByConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionAverageProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByCreatedByConnectionAverageProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByCreatedByConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionAverageProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionAverageProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionAverageProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionAverageProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionAverageRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionAverageRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionAverageRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByCreatedByConnectionAverageRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByCreatedByConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionAverageSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByCreatedByConnectionAverageSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByCreatedByConnectionAverageStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionAverageStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionAverageStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByCreatedByConnectionAverageStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByCreatedByConnectionAverageStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByCreatedByConnectionAverageStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByCreatedByConnectionAverageStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByCreatedByConnectionAverageStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByCreatedByConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionAverageTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByCreatedByConnectionAverageTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByCreatedByConnectionAverageTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionAverageTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionAverageTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionAverageTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionAverageTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByCreatedByConnectionAverageTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByCreatedByConnectionAverageTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByCreatedByConnectionAverageTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByCreatedByConnectionAverageUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByCreatedByConnectionAverageUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByCreatedByConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionAverageZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionAverageZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ProjectsByCreatedByConnectionCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ProjectsByCreatedByConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionDistinctCountAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByCreatedByConnectionDistinctCountAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByCreatedByConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionDistinctCountAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByCreatedByConnectionDistinctCountAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByCreatedByConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByCreatedByConnectionDistinctCountCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByCreatedByConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionDistinctCountIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByCreatedByConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByCreatedByConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionDistinctCountProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByCreatedByConnectionDistinctCountProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByCreatedByConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByCreatedByConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByCreatedByConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByCreatedByConnectionDistinctCountSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByCreatedByConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionDistinctCountStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByCreatedByConnectionDistinctCountStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByCreatedByConnectionDistinctCountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionDistinctCountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionDistinctCountStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByCreatedByConnectionDistinctCountStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByCreatedByConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionDistinctCountTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByCreatedByConnectionDistinctCountTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByCreatedByConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionDistinctCountTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByCreatedByConnectionDistinctCountTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByCreatedByConnectionDistinctCountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionDistinctCountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionDistinctCountUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByCreatedByConnectionDistinctCountUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionMaxAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionMaxAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionMaxAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionMaxAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionMaxAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByCreatedByConnectionMaxAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByCreatedByConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionMaxAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_AHJ_ASC',
  ProjectsByCreatedByConnectionMaxAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_AHJ_DESC',
  ProjectsByCreatedByConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionMaxCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CITY_ASC',
  ProjectsByCreatedByConnectionMaxCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CITY_DESC',
  ProjectsByCreatedByConnectionMaxCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionMaxCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionMaxCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionMaxCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionMaxConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionMaxConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionMaxCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionMaxCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionMaxCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionMaxCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionMaxDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionMaxDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionMaxDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionMaxDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionMaxDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionMaxDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionMaxFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionMaxFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionMaxGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionMaxGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionMaxIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ProjectsByCreatedByConnectionMaxIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ProjectsByCreatedByConnectionMaxImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionMaxImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionMaxIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionMaxIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionMaxIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionMaxIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionMaxLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionMaxLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionMaxOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionMaxOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionMaxOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionMaxOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionMaxPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PHONE_ASC',
  ProjectsByCreatedByConnectionMaxPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PHONE_DESC',
  ProjectsByCreatedByConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionMaxProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByCreatedByConnectionMaxProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByCreatedByConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionMaxProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionMaxProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionMaxProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionMaxProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionMaxRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionMaxRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionMaxRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByCreatedByConnectionMaxRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByCreatedByConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionMaxSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByCreatedByConnectionMaxSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByCreatedByConnectionMaxStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionMaxStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionMaxStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STATE_ASC',
  ProjectsByCreatedByConnectionMaxStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STATE_DESC',
  ProjectsByCreatedByConnectionMaxStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  ProjectsByCreatedByConnectionMaxStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  ProjectsByCreatedByConnectionMaxStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STREET_ASC',
  ProjectsByCreatedByConnectionMaxStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STREET_DESC',
  ProjectsByCreatedByConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionMaxTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  ProjectsByCreatedByConnectionMaxTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  ProjectsByCreatedByConnectionMaxTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionMaxTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionMaxTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionMaxTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionMaxTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TRADES_ASC',
  ProjectsByCreatedByConnectionMaxTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TRADES_DESC',
  ProjectsByCreatedByConnectionMaxTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  ProjectsByCreatedByConnectionMaxTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  ProjectsByCreatedByConnectionMaxUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  ProjectsByCreatedByConnectionMaxUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  ProjectsByCreatedByConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionMaxZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionMaxZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionMinAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionMinAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionMinAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionMinAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionMinAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByCreatedByConnectionMinAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByCreatedByConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionMinAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionMinAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionMinAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_AHJ_ASC',
  ProjectsByCreatedByConnectionMinAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_AHJ_DESC',
  ProjectsByCreatedByConnectionMinBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionMinBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionMinCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CITY_ASC',
  ProjectsByCreatedByConnectionMinCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CITY_DESC',
  ProjectsByCreatedByConnectionMinCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionMinCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionMinCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionMinCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionMinCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionMinCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionMinConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionMinConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionMinCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionMinCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionMinCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionMinCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionMinDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionMinDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionMinDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionMinDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionMinDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionMinDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionMinFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionMinFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionMinGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionMinGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionMinIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ProjectsByCreatedByConnectionMinIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ProjectsByCreatedByConnectionMinImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionMinImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionMinIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionMinIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionMinIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionMinIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionMinLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionMinLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionMinOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionMinOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionMinOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionMinOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionMinParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionMinParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionMinPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PHONE_ASC',
  ProjectsByCreatedByConnectionMinPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PHONE_DESC',
  ProjectsByCreatedByConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionMinProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByCreatedByConnectionMinProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByCreatedByConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionMinProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionMinProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionMinProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionMinProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionMinRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionMinRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionMinRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByCreatedByConnectionMinRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByCreatedByConnectionMinSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionMinSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionMinSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByCreatedByConnectionMinSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByCreatedByConnectionMinStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionMinStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionMinStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STATE_ASC',
  ProjectsByCreatedByConnectionMinStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STATE_DESC',
  ProjectsByCreatedByConnectionMinStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  ProjectsByCreatedByConnectionMinStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  ProjectsByCreatedByConnectionMinStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STREET_ASC',
  ProjectsByCreatedByConnectionMinStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STREET_DESC',
  ProjectsByCreatedByConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionMinTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  ProjectsByCreatedByConnectionMinTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  ProjectsByCreatedByConnectionMinTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionMinTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionMinTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionMinTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionMinTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TRADES_ASC',
  ProjectsByCreatedByConnectionMinTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TRADES_DESC',
  ProjectsByCreatedByConnectionMinTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  ProjectsByCreatedByConnectionMinTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  ProjectsByCreatedByConnectionMinUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  ProjectsByCreatedByConnectionMinUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  ProjectsByCreatedByConnectionMinUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionMinUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionMinZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionMinZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByCreatedByConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByCreatedByConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionStddevPopulationIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByCreatedByConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByCreatedByConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByCreatedByConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByCreatedByConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByCreatedByConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevPopulationUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByCreatedByConnectionStddevPopulationUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevSampleAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByCreatedByConnectionStddevSampleAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByCreatedByConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionStddevSampleAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByCreatedByConnectionStddevSampleAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByCreatedByConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByCreatedByConnectionStddevSampleCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByCreatedByConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionStddevSampleIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByCreatedByConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByCreatedByConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionStddevSampleProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByCreatedByConnectionStddevSampleProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByCreatedByConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByCreatedByConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByCreatedByConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByCreatedByConnectionStddevSampleSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByCreatedByConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionStddevSampleStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByCreatedByConnectionStddevSampleStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByCreatedByConnectionStddevSampleStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByCreatedByConnectionStddevSampleStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByCreatedByConnectionStddevSampleStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByCreatedByConnectionStddevSampleStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByCreatedByConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionStddevSampleTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByCreatedByConnectionStddevSampleTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByCreatedByConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionStddevSampleTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByCreatedByConnectionStddevSampleTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByCreatedByConnectionStddevSampleTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByCreatedByConnectionStddevSampleTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByCreatedByConnectionStddevSampleUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByCreatedByConnectionStddevSampleUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionSumAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionSumAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionSumAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionSumAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionSumAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByCreatedByConnectionSumAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByCreatedByConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionSumAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionSumAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionSumAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_AHJ_ASC',
  ProjectsByCreatedByConnectionSumAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_AHJ_DESC',
  ProjectsByCreatedByConnectionSumBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionSumBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionSumCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CITY_ASC',
  ProjectsByCreatedByConnectionSumCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CITY_DESC',
  ProjectsByCreatedByConnectionSumCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionSumCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionSumCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionSumCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionSumCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionSumCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionSumConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionSumConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionSumCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionSumCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionSumCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionSumCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionSumDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionSumDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionSumDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionSumDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionSumDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionSumDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionSumFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionSumFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionSumGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionSumGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionSumIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ProjectsByCreatedByConnectionSumIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ProjectsByCreatedByConnectionSumImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionSumImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionSumIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionSumIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionSumIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionSumIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionSumLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionSumLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionSumOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionSumOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionSumOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionSumOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionSumParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionSumParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionSumPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PHONE_ASC',
  ProjectsByCreatedByConnectionSumPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PHONE_DESC',
  ProjectsByCreatedByConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionSumProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByCreatedByConnectionSumProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByCreatedByConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionSumProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionSumProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionSumProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionSumProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionSumRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionSumRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionSumRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByCreatedByConnectionSumRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByCreatedByConnectionSumSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionSumSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionSumSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByCreatedByConnectionSumSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByCreatedByConnectionSumStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionSumStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionSumStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STATE_ASC',
  ProjectsByCreatedByConnectionSumStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STATE_DESC',
  ProjectsByCreatedByConnectionSumStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  ProjectsByCreatedByConnectionSumStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  ProjectsByCreatedByConnectionSumStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STREET_ASC',
  ProjectsByCreatedByConnectionSumStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STREET_DESC',
  ProjectsByCreatedByConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionSumTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  ProjectsByCreatedByConnectionSumTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  ProjectsByCreatedByConnectionSumTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionSumTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionSumTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionSumTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionSumTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TRADES_ASC',
  ProjectsByCreatedByConnectionSumTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TRADES_DESC',
  ProjectsByCreatedByConnectionSumTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  ProjectsByCreatedByConnectionSumTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  ProjectsByCreatedByConnectionSumUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  ProjectsByCreatedByConnectionSumUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  ProjectsByCreatedByConnectionSumUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionSumUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionSumZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionSumZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByCreatedByConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByCreatedByConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionVariancePopulationIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByCreatedByConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByCreatedByConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByCreatedByConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByCreatedByConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByCreatedByConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByCreatedByConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByCreatedByConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByCreatedByConnectionVariancePopulationUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByCreatedByConnectionVariancePopulationUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByCreatedByConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByCreatedByConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByCreatedByConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByCreatedByConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByCreatedByConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByCreatedByConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByCreatedByConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByCreatedByConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByCreatedByConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByCreatedByConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByCreatedByConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByCreatedByConnectionVarianceSampleIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByCreatedByConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByCreatedByConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByCreatedByConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByCreatedByConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByCreatedByConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByCreatedByConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByCreatedByConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByCreatedByConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByCreatedByConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByCreatedByConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByCreatedByConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByCreatedByConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByCreatedByConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByCreatedByConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleStateAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleStateDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByCreatedByConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByCreatedByConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByCreatedByConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByCreatedByConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByCreatedByConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByCreatedByConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByCreatedByConnectionVarianceSampleUidAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByCreatedByConnectionVarianceSampleUidDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByCreatedByConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByCreatedByConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByOwnerIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByOwnerIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByOwnerIdConnectionArrayAggCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByOwnerIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionArrayAggIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByOwnerIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByOwnerIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByOwnerIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByOwnerIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByOwnerIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByOwnerIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionArrayAggStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByOwnerIdConnectionArrayAggStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByOwnerIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByOwnerIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByOwnerIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByOwnerIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByOwnerIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByOwnerIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByOwnerIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByOwnerIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByOwnerIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByOwnerIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByOwnerIdConnectionArrayAggUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByOwnerIdConnectionArrayAggUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByOwnerIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionAverageAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionAverageAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionAverageAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByOwnerIdConnectionAverageAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByOwnerIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionAverageCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByOwnerIdConnectionAverageCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByOwnerIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionAverageDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionAverageDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionAverageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByOwnerIdConnectionAverageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByOwnerIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionAveragePhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByOwnerIdConnectionAveragePhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByOwnerIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionAverageProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionAverageProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionAverageRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByOwnerIdConnectionAverageRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByOwnerIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionAverageSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByOwnerIdConnectionAverageSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByOwnerIdConnectionAverageStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionAverageStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionAverageStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByOwnerIdConnectionAverageStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByOwnerIdConnectionAverageStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByOwnerIdConnectionAverageStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByOwnerIdConnectionAverageStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByOwnerIdConnectionAverageStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByOwnerIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionAverageTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByOwnerIdConnectionAverageTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByOwnerIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionAverageTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByOwnerIdConnectionAverageTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByOwnerIdConnectionAverageTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByOwnerIdConnectionAverageTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByOwnerIdConnectionAverageUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByOwnerIdConnectionAverageUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByOwnerIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_COUNT_ASC',
  ProjectsByOwnerIdConnectionCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_COUNT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByOwnerIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByOwnerIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByOwnerIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByOwnerIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByOwnerIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByOwnerIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByOwnerIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionMaxAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionMaxAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionMaxAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByOwnerIdConnectionMaxAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByOwnerIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionMaxCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByOwnerIdConnectionMaxCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByOwnerIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionMaxDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionMaxDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionMaxIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByOwnerIdConnectionMaxIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByOwnerIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionMaxPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByOwnerIdConnectionMaxPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByOwnerIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionMaxProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionMaxProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionMaxRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByOwnerIdConnectionMaxRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByOwnerIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionMaxSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByOwnerIdConnectionMaxSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByOwnerIdConnectionMaxStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionMaxStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionMaxStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByOwnerIdConnectionMaxStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByOwnerIdConnectionMaxStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByOwnerIdConnectionMaxStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByOwnerIdConnectionMaxStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByOwnerIdConnectionMaxStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByOwnerIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionMaxTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByOwnerIdConnectionMaxTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByOwnerIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionMaxTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByOwnerIdConnectionMaxTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByOwnerIdConnectionMaxTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByOwnerIdConnectionMaxTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByOwnerIdConnectionMaxUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByOwnerIdConnectionMaxUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByOwnerIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionMinAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionMinAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionMinAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByOwnerIdConnectionMinAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByOwnerIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionMinCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByOwnerIdConnectionMinCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByOwnerIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionMinConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionMinConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionMinCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionMinCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionMinDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionMinDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionMinDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionMinDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionMinDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionMinDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionMinIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByOwnerIdConnectionMinIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByOwnerIdConnectionMinImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionMinImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionMinIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionMinIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionMinLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionMinLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionMinOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionMinOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionMinPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByOwnerIdConnectionMinPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByOwnerIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionMinProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionMinProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionMinProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionMinProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionMinRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByOwnerIdConnectionMinRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByOwnerIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionMinSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByOwnerIdConnectionMinSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByOwnerIdConnectionMinStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionMinStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionMinStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByOwnerIdConnectionMinStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByOwnerIdConnectionMinStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByOwnerIdConnectionMinStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByOwnerIdConnectionMinStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByOwnerIdConnectionMinStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByOwnerIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionMinTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByOwnerIdConnectionMinTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByOwnerIdConnectionMinTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionMinTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionMinTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByOwnerIdConnectionMinTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByOwnerIdConnectionMinTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByOwnerIdConnectionMinTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByOwnerIdConnectionMinUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByOwnerIdConnectionMinUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByOwnerIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionMinZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionMinZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByOwnerIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByOwnerIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByOwnerIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByOwnerIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByOwnerIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByOwnerIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByOwnerIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByOwnerIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByOwnerIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByOwnerIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionSumAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionSumAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionSumAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByOwnerIdConnectionSumAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByOwnerIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionSumCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByOwnerIdConnectionSumCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByOwnerIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionSumConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionSumConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionSumCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionSumCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionSumDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionSumDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionSumDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionSumDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionSumDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionSumDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionSumIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByOwnerIdConnectionSumIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByOwnerIdConnectionSumImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionSumImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionSumIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionSumIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionSumLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionSumLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionSumOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionSumOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionSumPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByOwnerIdConnectionSumPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByOwnerIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionSumProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionSumProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionSumProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionSumProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionSumRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByOwnerIdConnectionSumRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByOwnerIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionSumSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByOwnerIdConnectionSumSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByOwnerIdConnectionSumStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionSumStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionSumStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByOwnerIdConnectionSumStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByOwnerIdConnectionSumStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByOwnerIdConnectionSumStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByOwnerIdConnectionSumStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByOwnerIdConnectionSumStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByOwnerIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionSumTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByOwnerIdConnectionSumTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByOwnerIdConnectionSumTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionSumTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionSumTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByOwnerIdConnectionSumTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByOwnerIdConnectionSumTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByOwnerIdConnectionSumTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByOwnerIdConnectionSumUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByOwnerIdConnectionSumUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByOwnerIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionSumZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionSumZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByOwnerIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByOwnerIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByOwnerIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionAverageAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionAverageAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionAverageAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionAverageAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsByProjectManagerIdConnectionAverageCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsByProjectManagerIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionAverageDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionAverageDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionAverageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionAveragePhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionAveragePhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionAverageProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionAverageProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionAverageRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionAverageRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionAverageSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionAverageStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionAverageStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionAverageStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsByProjectManagerIdConnectionAverageStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsByProjectManagerIdConnectionAverageStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionAverageStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionAverageStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsByProjectManagerIdConnectionAverageStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsByProjectManagerIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionAverageTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionAverageTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionAverageTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionAverageTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionAverageTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionAverageTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionAverageUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsByProjectManagerIdConnectionAverageUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsByProjectManagerIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMaxAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionMaxAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionMaxAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionMaxAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsByProjectManagerIdConnectionMaxCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsByProjectManagerIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionMaxDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionMaxDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionMaxIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionMaxPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionMaxProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionMaxProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMaxRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionMaxRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionMaxSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionMaxStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionMaxStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionMaxStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsByProjectManagerIdConnectionMaxStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsByProjectManagerIdConnectionMaxStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMaxStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMaxStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsByProjectManagerIdConnectionMaxStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsByProjectManagerIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionMaxTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionMaxTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionMaxTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionMaxTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionMaxTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMaxTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMaxUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UID_ASC',
  ProjectsByProjectManagerIdConnectionMaxUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UID_DESC',
  ProjectsByProjectManagerIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMinAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionMinAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionMinAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionMinAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsByProjectManagerIdConnectionMinCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsByProjectManagerIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionMinConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionMinConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionMinCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMinDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionMinDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionMinDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionMinDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionMinDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionMinDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionMinIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionMinImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionMinIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionMinIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionMinLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionMinOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionMinOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionMinPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionMinProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionMinProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionMinProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionMinProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMinRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionMinRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionMinSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionMinStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionMinStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionMinStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsByProjectManagerIdConnectionMinStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsByProjectManagerIdConnectionMinStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionMinStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionMinStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsByProjectManagerIdConnectionMinStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsByProjectManagerIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionMinTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionMinTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionMinTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionMinTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionMinTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionMinTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionMinTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionMinTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionMinUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UID_ASC',
  ProjectsByProjectManagerIdConnectionMinUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UID_DESC',
  ProjectsByProjectManagerIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionMinZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionMinZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionSumAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionSumAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionSumAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionSumAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsByProjectManagerIdConnectionSumCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsByProjectManagerIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionSumConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionSumConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionSumCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionSumDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionSumDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionSumDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionSumDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionSumDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionSumDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionSumIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionSumImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionSumIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionSumIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionSumLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionSumOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionSumOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionSumPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionSumProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionSumProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionSumProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionSumProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionSumRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionSumRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionSumSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionSumStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionSumStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionSumStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsByProjectManagerIdConnectionSumStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsByProjectManagerIdConnectionSumStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionSumStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionSumStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsByProjectManagerIdConnectionSumStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsByProjectManagerIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionSumTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionSumTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionSumTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionSumTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionSumTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionSumTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionSumTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionSumTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionSumUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UID_ASC',
  ProjectsByProjectManagerIdConnectionSumUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UID_DESC',
  ProjectsByProjectManagerIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionSumZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionSumZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsByProjectManagerIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsByProjectManagerIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectsBySalesRepIdConnectionArrayAggAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectsBySalesRepIdConnectionArrayAggBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionArrayAggConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionArrayAggDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionArrayAggDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionArrayAggDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionArrayAggFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionArrayAggFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionArrayAggIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionArrayAggImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionArrayAggIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionArrayAggIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionArrayAggJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionArrayAggOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionArrayAggOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionArrayAggOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionArrayAggPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionArrayAggProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionArrayAggProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionArrayAggProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionArrayAggRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionArrayAggRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectsBySalesRepIdConnectionArrayAggStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectsBySalesRepIdConnectionArrayAggStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectsBySalesRepIdConnectionArrayAggStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectsBySalesRepIdConnectionArrayAggStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionArrayAggStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectsBySalesRepIdConnectionArrayAggTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectsBySalesRepIdConnectionArrayAggUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectsBySalesRepIdConnectionArrayAggUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectsBySalesRepIdConnectionArrayAggUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionArrayAggUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionArrayAggZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionArrayAggZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionAverageAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionAverageAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionAverageAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionAverageAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionAverageAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionAverageAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionAverageAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionAverageAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionAverageAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionAverageAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionAverageAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectsBySalesRepIdConnectionAverageAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectsBySalesRepIdConnectionAverageBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectsBySalesRepIdConnectionAverageCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectsBySalesRepIdConnectionAverageCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionAverageCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionAverageCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionAverageCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionAverageConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionAverageConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionAverageCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionAverageCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionAverageCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionAverageCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionAverageCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionAverageCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionAverageDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionAverageDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionAverageDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionAverageDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionAverageDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionAverageDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionAverageDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionAverageDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionAverageFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionAverageFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionAverageHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionAverageHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionAverageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionAverageImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionAverageIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionAverageIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionAverageIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionAverageIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionAverageJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionAverageLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionAverageLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionAverageOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionAverageOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionAverageOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionAveragePhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectsBySalesRepIdConnectionAveragePhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectsBySalesRepIdConnectionAveragePrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionAveragePrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionAveragePrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionAveragePrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionAveragePrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionAverageProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionAverageProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionAverageProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionAverageProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionAverageProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionAverageProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionAverageReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionAverageRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionAverageRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionAverageRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionAverageSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionAverageSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionAverageStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionAverageStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionAverageStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectsBySalesRepIdConnectionAverageStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectsBySalesRepIdConnectionAverageStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectsBySalesRepIdConnectionAverageStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectsBySalesRepIdConnectionAverageStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectsBySalesRepIdConnectionAverageStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectsBySalesRepIdConnectionAverageStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionAverageStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionAverageTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectsBySalesRepIdConnectionAverageTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectsBySalesRepIdConnectionAverageTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionAverageTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionAverageTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionAverageTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionAverageTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectsBySalesRepIdConnectionAverageTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectsBySalesRepIdConnectionAverageTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectsBySalesRepIdConnectionAverageTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectsBySalesRepIdConnectionAverageUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectsBySalesRepIdConnectionAverageUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectsBySalesRepIdConnectionAverageUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionAverageUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionAverageZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionAverageZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_COUNT_ASC',
  ProjectsBySalesRepIdConnectionCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_COUNT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionDistinctCountZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionDistinctCountZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionMaxAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMaxAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMaxAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMaxAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMaxAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionMaxAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionMaxAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionMaxAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionMaxAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionMaxAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionMaxAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectsBySalesRepIdConnectionMaxAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectsBySalesRepIdConnectionMaxBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CITY_ASC',
  ProjectsBySalesRepIdConnectionMaxCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CITY_DESC',
  ProjectsBySalesRepIdConnectionMaxCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionMaxCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionMaxCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionMaxCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionMaxConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionMaxConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionMaxCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionMaxCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionMaxCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionMaxCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionMaxCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMaxCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMaxDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionMaxDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionMaxDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionMaxDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionMaxDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionMaxDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionMaxDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionMaxDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionMaxFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionMaxFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionMaxHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionMaxHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionMaxIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionMaxImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionMaxIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionMaxIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionMaxIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionMaxIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionMaxJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionMaxLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionMaxLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionMaxOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionMaxOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionMaxOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectsBySalesRepIdConnectionMaxPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectsBySalesRepIdConnectionMaxPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionMaxPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionMaxPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionMaxPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionMaxPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionMaxProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionMaxProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionMaxProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionMaxProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionMaxProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionMaxProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionMaxReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMaxRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMaxRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionMaxRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionMaxSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionMaxSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionMaxStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionMaxStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionMaxStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STATE_ASC',
  ProjectsBySalesRepIdConnectionMaxStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STATE_DESC',
  ProjectsBySalesRepIdConnectionMaxStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMaxStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMaxStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_ASC',
  ProjectsBySalesRepIdConnectionMaxStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_DESC',
  ProjectsBySalesRepIdConnectionMaxStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionMaxStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionMaxTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectsBySalesRepIdConnectionMaxTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectsBySalesRepIdConnectionMaxTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionMaxTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionMaxTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionMaxTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionMaxTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectsBySalesRepIdConnectionMaxTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectsBySalesRepIdConnectionMaxTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMaxTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMaxUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_UID_ASC',
  ProjectsBySalesRepIdConnectionMaxUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_UID_DESC',
  ProjectsBySalesRepIdConnectionMaxUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionMaxUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionMaxZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionMaxZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionMinAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMinAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMinAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMinAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMinAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionMinAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionMinAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionMinAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionMinAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionMinAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionMinAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectsBySalesRepIdConnectionMinAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectsBySalesRepIdConnectionMinBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionMinBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionMinCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CITY_ASC',
  ProjectsBySalesRepIdConnectionMinCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CITY_DESC',
  ProjectsBySalesRepIdConnectionMinCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionMinCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionMinCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionMinCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionMinCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionMinCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionMinConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionMinConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionMinCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionMinCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionMinCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionMinCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionMinCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionMinCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionMinCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMinCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMinDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionMinDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionMinDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionMinDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionMinDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionMinDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionMinDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionMinDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionMinFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionMinFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionMinGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionMinGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionMinGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionMinGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionMinGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionMinGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionMinHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionMinHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionMinIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ID_ASC',
  ProjectsBySalesRepIdConnectionMinIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ID_DESC',
  ProjectsBySalesRepIdConnectionMinImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionMinImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionMinIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionMinIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionMinIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionMinIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionMinJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionMinJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionMinLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionMinLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionMinLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionMinLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionMinLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionMinLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionMinOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionMinOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionMinOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionMinOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionMinParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionMinParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionMinPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectsBySalesRepIdConnectionMinPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectsBySalesRepIdConnectionMinPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionMinPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionMinPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionMinPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionMinPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionMinPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionMinPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionMinPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionMinPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionMinPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionMinProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionMinProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionMinProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionMinProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionMinProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionMinProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionMinProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionMinProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionMinReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionMinReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionMinRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMinRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMinRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionMinRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionMinSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionMinSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionMinSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionMinSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionMinStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionMinStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionMinStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STATE_ASC',
  ProjectsBySalesRepIdConnectionMinStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STATE_DESC',
  ProjectsBySalesRepIdConnectionMinStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectsBySalesRepIdConnectionMinStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectsBySalesRepIdConnectionMinStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_ASC',
  ProjectsBySalesRepIdConnectionMinStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_DESC',
  ProjectsBySalesRepIdConnectionMinStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionMinStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionMinTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectsBySalesRepIdConnectionMinTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectsBySalesRepIdConnectionMinTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionMinTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionMinTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionMinTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionMinTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionMinTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionMinTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectsBySalesRepIdConnectionMinTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectsBySalesRepIdConnectionMinTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectsBySalesRepIdConnectionMinTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectsBySalesRepIdConnectionMinUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_UID_ASC',
  ProjectsBySalesRepIdConnectionMinUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_UID_DESC',
  ProjectsBySalesRepIdConnectionMinUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionMinUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionMinZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionMinZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionStddevPopulationZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionStddevPopulationZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionStddevSamplePrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionStddevSampleZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionStddevSampleZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionSumAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionSumAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionSumAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionSumAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionSumAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionSumAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionSumAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionSumAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionSumAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionSumAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionSumAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectsBySalesRepIdConnectionSumAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectsBySalesRepIdConnectionSumBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionSumBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionSumCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CITY_ASC',
  ProjectsBySalesRepIdConnectionSumCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CITY_DESC',
  ProjectsBySalesRepIdConnectionSumCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionSumCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionSumCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionSumCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionSumCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionSumCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionSumConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionSumConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionSumCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionSumCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionSumCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionSumCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionSumCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionSumCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionSumCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionSumCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionSumDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionSumDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionSumDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionSumDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionSumDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionSumDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionSumDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionSumDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionSumFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionSumFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionSumGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionSumGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionSumGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionSumGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionSumGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionSumGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionSumHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionSumHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionSumIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ID_ASC',
  ProjectsBySalesRepIdConnectionSumIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ID_DESC',
  ProjectsBySalesRepIdConnectionSumImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionSumImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionSumIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionSumIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionSumIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionSumIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionSumJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionSumJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionSumLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionSumLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionSumLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionSumLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionSumLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionSumLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionSumOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionSumOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionSumOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionSumOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionSumParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionSumParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionSumPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectsBySalesRepIdConnectionSumPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectsBySalesRepIdConnectionSumPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionSumPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionSumPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionSumPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionSumPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionSumPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionSumPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionSumPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionSumPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionSumPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionSumProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionSumProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionSumProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionSumProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionSumProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionSumProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionSumProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionSumProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionSumReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionSumReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionSumRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionSumRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionSumRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionSumRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionSumSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionSumSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionSumSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionSumSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionSumStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionSumStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionSumStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STATE_ASC',
  ProjectsBySalesRepIdConnectionSumStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STATE_DESC',
  ProjectsBySalesRepIdConnectionSumStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectsBySalesRepIdConnectionSumStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectsBySalesRepIdConnectionSumStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_ASC',
  ProjectsBySalesRepIdConnectionSumStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_DESC',
  ProjectsBySalesRepIdConnectionSumStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionSumStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionSumTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectsBySalesRepIdConnectionSumTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectsBySalesRepIdConnectionSumTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionSumTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionSumTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionSumTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionSumTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionSumTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionSumTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectsBySalesRepIdConnectionSumTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectsBySalesRepIdConnectionSumTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectsBySalesRepIdConnectionSumTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectsBySalesRepIdConnectionSumUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_UID_ASC',
  ProjectsBySalesRepIdConnectionSumUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_UID_DESC',
  ProjectsBySalesRepIdConnectionSumUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionSumUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionSumZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionSumZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationPrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionVariancePopulationZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionVariancePopulationZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAccountStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAccountStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAccountTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAccountTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleAhjAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleAhjDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompanyIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompanyIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionDateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionDateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleConfidenceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleConfidenceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleDescriptionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleDescriptionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleFreshProjectAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleFreshProjectDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleImageUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleImageUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleIsActiveAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleIsActiveDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleIsCompletedAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleIsCompletedDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastActivityAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastActivityDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleOverdueByAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleOverdueByDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleOwnerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleOwnerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeAllAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeAllDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeOwnAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeOwnDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeTeamAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  ProjectsBySalesRepIdConnectionVarianceSamplePrivilegeTeamDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleProgressAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleProgressDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectSizeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectSizeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectValueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleProjectValueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleRequestStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleRequestStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleRevenueAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleRevenueDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleSourceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleSourceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleStageIdAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleStageIdDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleStateAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleStateDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleStatusAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleStatusDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleStreetAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleStreetDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTitleAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTitleDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalCostAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalCostDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalPriceAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTotalPriceDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTradesAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTradesDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleTypeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleTypeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleUidAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleUidDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectsBySalesRepIdConnectionVarianceSampleZipcodeAsc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectsBySalesRepIdConnectionVarianceSampleZipcodeDesc = 'PROJECTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectMembersByCreatedByConnectionArrayAggCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionArrayAggCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionArrayAggCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionArrayAggCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionArrayAggIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectMembersByCreatedByConnectionArrayAggIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectMembersByCreatedByConnectionArrayAggMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionArrayAggMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionArrayAggMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionArrayAggMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionArrayAggProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionArrayAggProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionArrayAggTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionArrayAggTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionAverageCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionAverageCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionAverageCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionAverageCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionAverageIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ProjectMembersByCreatedByConnectionAverageIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ProjectMembersByCreatedByConnectionAverageMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionAverageMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionAverageMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionAverageMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionAverageProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionAverageProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionAverageTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionAverageTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionCountAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ProjectMembersByCreatedByConnectionCountDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionDistinctCountTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionDistinctCountTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionMaxCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionMaxCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionMaxCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionMaxCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionMaxIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ProjectMembersByCreatedByConnectionMaxIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ProjectMembersByCreatedByConnectionMaxMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionMaxMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionMaxMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionMaxMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionMaxProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionMaxProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionMaxTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionMaxTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionMinCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionMinCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionMinCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionMinCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionMinIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ProjectMembersByCreatedByConnectionMinIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ProjectMembersByCreatedByConnectionMinMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionMinMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionMinMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionMinMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionMinProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionMinProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionMinTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionMinTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevPopulationTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevPopulationTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionStddevSampleTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionStddevSampleTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionSumCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionSumCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionSumCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionSumCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionSumIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ProjectMembersByCreatedByConnectionSumIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ProjectMembersByCreatedByConnectionSumMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionSumMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionSumMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionSumMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionSumProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionSumProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionSumTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionSumTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionVariancePopulationTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionVariancePopulationTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleCreatedByAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleCreatedByDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleMemberIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleMemberIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleMemberTypeAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleMemberTypeDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleProjectIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleProjectIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersByCreatedByConnectionVarianceSampleTeamIdAsc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectMembersByCreatedByConnectionVarianceSampleTeamIdDesc = 'PROJECT_MEMBERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionArrayAggCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionArrayAggCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionArrayAggCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionArrayAggCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionArrayAggIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectMembersByMemberIdConnectionArrayAggIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectMembersByMemberIdConnectionArrayAggMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionArrayAggMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionArrayAggMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionArrayAggMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionArrayAggProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionArrayAggProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionArrayAggTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionArrayAggTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionAverageCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionAverageCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionAverageCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionAverageCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionAverageIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectMembersByMemberIdConnectionAverageIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectMembersByMemberIdConnectionAverageMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionAverageMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionAverageMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionAverageMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionAverageProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionAverageProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionAverageTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionAverageTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionCountAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_COUNT_ASC',
  ProjectMembersByMemberIdConnectionCountDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_COUNT_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionDistinctCountTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionDistinctCountTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionMaxCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionMaxCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionMaxCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionMaxCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionMaxIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_ID_ASC',
  ProjectMembersByMemberIdConnectionMaxIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_ID_DESC',
  ProjectMembersByMemberIdConnectionMaxMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionMaxMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionMaxMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionMaxMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionMaxProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionMaxProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionMaxTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionMaxTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionMinCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionMinCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionMinCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionMinCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionMinIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_ID_ASC',
  ProjectMembersByMemberIdConnectionMinIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_ID_DESC',
  ProjectMembersByMemberIdConnectionMinMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionMinMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionMinMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionMinMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionMinProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionMinProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionMinTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionMinTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionStddevSampleTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionStddevSampleTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionSumCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionSumCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionSumCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionSumCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionSumIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_ID_ASC',
  ProjectMembersByMemberIdConnectionSumIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_ID_DESC',
  ProjectMembersByMemberIdConnectionSumMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionSumMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionSumMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionSumMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionSumProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionSumProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionSumTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionSumTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleMemberIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleMemberIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_MEMBER_ID_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleMemberTypeAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleMemberTypeDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_MEMBER_TYPE_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectMembersByMemberIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectMembersByMemberIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_MEMBERS_BY_MEMBER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionArrayAggVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionArrayAggVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionAverageAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionAverageAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionAverageCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionAverageCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionAverageCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionAverageCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionAverageCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionAverageCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionAverageDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionAverageDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionAverageExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionAverageExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionAverageInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionAverageInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionAverageIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionAverageIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionAverageKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionAverageKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionAverageMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionAverageMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionAverageMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionAverageMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionAverageNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionAverageNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionAverageNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionAverageNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionAverageObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionAverageObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionAveragePositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionAveragePositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionAverageProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionAverageProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionAverageScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionAverageScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionAverageSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionAverageSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionAverageSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionAverageSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionAverageTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionAverageTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionAverageTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionAverageTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionAverageUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionAverageUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionAverageVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionAverageVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionAverageVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionAverageVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionCountAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ProjectPropertiesByCreatedByConnectionCountDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionDistinctCountVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionDistinctCountVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMaxAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionMaxAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionMaxCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMaxCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMaxCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionMaxCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionMaxCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionMaxCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionMaxDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionMaxDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionMaxExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionMaxExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMaxInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionMaxInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionMaxIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionMaxIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionMaxKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMaxKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMaxMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMaxMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMaxMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionMaxMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionMaxNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMaxNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMaxNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionMaxNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionMaxObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMaxObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMaxPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionMaxPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionMaxProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMaxProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMaxScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionMaxScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionMaxSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionMaxSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionMaxSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMaxSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMaxTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMaxTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMaxTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMaxTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMaxUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionMaxUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionMaxVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionMaxVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionMaxVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMaxVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMinAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionMinAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionMinCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMinCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMinCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionMinCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionMinCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionMinCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionMinDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionMinDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionMinExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionMinExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionMinIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionMinIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionMinIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMinIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMinInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionMinInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionMinIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionMinIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionMinIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionMinIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionMinIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionMinIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionMinIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionMinIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionMinKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMinKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMinMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMinMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMinMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionMinMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionMinNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMinNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMinNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionMinNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionMinObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionMinObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionMinPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionMinPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionMinProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMinProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMinScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionMinScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionMinSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionMinSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionMinSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMinSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMinTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionMinTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionMinTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMinTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionMinUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionMinUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionMinVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionMinVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionMinVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionMinVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevPopulationVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSamplePositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSamplePositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionStddevSampleVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionStddevSampleVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionSumAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionSumAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionSumCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionSumCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionSumCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionSumCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionSumCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionSumCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionSumDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionSumDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionSumExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionSumExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionSumIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionSumIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionSumIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ProjectPropertiesByCreatedByConnectionSumIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ProjectPropertiesByCreatedByConnectionSumInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionSumInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionSumIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionSumIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionSumIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionSumIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionSumIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionSumIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionSumIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionSumIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionSumKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionSumKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionSumMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionSumMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionSumMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionSumMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionSumNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionSumNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionSumNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionSumNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionSumObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionSumObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionSumPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionSumPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionSumProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionSumProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionSumScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionSumScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionSumSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionSumSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionSumSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionSumSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionSumTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionSumTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionSumTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionSumTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionSumUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionSumUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionSumVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionSumVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionSumVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionSumVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationPositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationPositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVariancePopulationVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCreatedByAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleCreatedByDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleDisplayValuePathAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DISPLAY_VALUE_PATH_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleDisplayValuePathDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DISPLAY_VALUE_PATH_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleExternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EXTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleExternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_EXTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIconUrlAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ICON_URL_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIconUrlDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ICON_URL_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleInternalVisibilityAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_INTERNAL_VISIBILITY_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleInternalVisibilityDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_INTERNAL_VISIBILITY_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsAdditionalAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ADDITIONAL_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsAdditionalDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ADDITIONAL_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsNewRecordAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_NEW_RECORD_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsNewRecordDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_NEW_RECORD_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsReadOnlyAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_READ_ONLY_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsReadOnlyDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_READ_ONLY_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsRequiredAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_REQUIRED_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleIsRequiredDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_REQUIRED_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleKeyNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_KEY_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleKeyNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_KEY_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleMappedNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MAPPED_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleMappedNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MAPPED_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleMultipleAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MULTIPLE_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleMultipleDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MULTIPLE_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleNotesAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTES_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleNotesDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NOTES_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleObjectNameAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OBJECT_NAME_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleObjectNameDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OBJECT_NAME_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSamplePositionAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSamplePositionDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleProjectColumnIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleProjectColumnIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_COLUMN_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleScopeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleScopeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCOPE_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleSharedAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARED_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleSharedDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARED_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleSharingTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARING_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleSharingTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SHARING_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleTaskTemplateIdAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_TEMPLATE_ID_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleTaskTemplateIdDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_TEMPLATE_ID_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleVirtualAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleVirtualDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_DESC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleVirtualTypeAsc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_TYPE_ASC',
  ProjectPropertiesByCreatedByConnectionVarianceSampleVirtualTypeDesc = 'PROJECT_PROPERTIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_TYPE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionArrayAggWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionAverageWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionAverageWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionCountAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_COUNT_ASC',
  ProjectPropertyValuesByWorkerValueConnectionCountDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_COUNT_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionDistinctCountWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMaxWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMaxWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionMinWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionMinWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevPopulationWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionStddevSampleWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionSumWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionSumWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVariancePopulationWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleColumnIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleColumnIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COLUMN_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDateValueAllDayAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDateValueAllDayDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ALL_DAY_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDateValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDateValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDropdownValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleDropdownValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleNumericValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleNumericValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleProjectIdAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleProjectIdDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleTextValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleTextValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleWorkerValueAsc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectPropertyValuesByWorkerValueConnectionVarianceSampleWorkerValueDesc = 'PROJECT_PROPERTY_VALUES_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByCreatedByConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByCreatedByConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByCreatedByConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByCreatedByConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByCreatedByConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByCreatedByConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByCreatedByConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByCreatedByConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByCreatedByConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByCreatedByConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByCreatedByConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByCreatedByConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByCreatedByConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByCreatedByConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByCreatedByConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByCreatedByConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByCreatedByConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByCreatedByConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByCreatedByConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByCreatedByConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByCreatedByConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByCreatedByConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByCreatedByConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByCreatedByConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  ProjectReportsByCreatedByConnectionCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByCreatedByConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByCreatedByConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByCreatedByConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByCreatedByConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByCreatedByConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByCreatedByConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByCreatedByConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByCreatedByConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByCreatedByConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByCreatedByConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByCreatedByConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByCreatedByConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByCreatedByConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByCreatedByConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByCreatedByConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByCreatedByConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  ProjectReportsByCreatedByConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  ProjectReportsByCreatedByConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByCreatedByConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByCreatedByConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionMinCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByCreatedByConnectionMinCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByCreatedByConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionMinIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  ProjectReportsByCreatedByConnectionMinIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  ProjectReportsByCreatedByConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByCreatedByConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByCreatedByConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionMinStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByCreatedByConnectionMinStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByCreatedByConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByCreatedByConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByCreatedByConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByCreatedByConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByCreatedByConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByCreatedByConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByCreatedByConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByCreatedByConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByCreatedByConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByCreatedByConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByCreatedByConnectionMinUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  ProjectReportsByCreatedByConnectionMinUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  ProjectReportsByCreatedByConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByCreatedByConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByCreatedByConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByCreatedByConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByCreatedByConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionSumCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByCreatedByConnectionSumCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByCreatedByConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionSumIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  ProjectReportsByCreatedByConnectionSumIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  ProjectReportsByCreatedByConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByCreatedByConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByCreatedByConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionSumStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByCreatedByConnectionSumStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByCreatedByConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByCreatedByConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByCreatedByConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByCreatedByConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByCreatedByConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByCreatedByConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByCreatedByConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByCreatedByConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByCreatedByConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByCreatedByConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByCreatedByConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByCreatedByConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByCreatedByConnectionSumUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  ProjectReportsByCreatedByConnectionSumUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  ProjectReportsByCreatedByConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByCreatedByConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByCreatedByConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByCreatedByConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByCreatedByConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByOwnerIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByOwnerIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByOwnerIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByOwnerIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByOwnerIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByOwnerIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByOwnerIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByOwnerIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByOwnerIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByOwnerIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByOwnerIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByOwnerIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByOwnerIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByOwnerIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByOwnerIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByOwnerIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByOwnerIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByOwnerIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByOwnerIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByOwnerIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByOwnerIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByOwnerIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByOwnerIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByOwnerIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByOwnerIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByOwnerIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByOwnerIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByOwnerIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByOwnerIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByOwnerIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByOwnerIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByOwnerIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByOwnerIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByOwnerIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_OWNER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByProjectManagerIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_PROJECT_MANAGER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsBySalesRepIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsBySalesRepIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsBySalesRepIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsBySalesRepIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsBySalesRepIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_SALES_REP_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByUserIdConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByUserIdConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByUserIdConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByUserIdConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByUserIdConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByUserIdConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByUserIdConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByUserIdConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByUserIdConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByUserIdConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByUserIdConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByUserIdConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByUserIdConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByUserIdConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByUserIdConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByUserIdConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByUserIdConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByUserIdConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByUserIdConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByUserIdConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByUserIdConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByUserIdConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByUserIdConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByUserIdConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByUserIdConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByUserIdConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByUserIdConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByUserIdConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByUserIdConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByUserIdConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByUserIdConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByUserIdConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByUserIdConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByUserIdConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByUserIdConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByUserIdConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByUserIdConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByUserIdConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByUserIdConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByUserIdConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByUserIdConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByUserIdConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByUserIdConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByUserIdConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByUserIdConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByUserIdConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByUserIdConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByUserIdConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByUserIdConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByUserIdConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByUserIdConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByUserIdConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByUserIdConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByUserIdConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByUserIdConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByUserIdConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_COUNT_ASC',
  ProjectReportsByUserIdConnectionCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_COUNT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByUserIdConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByUserIdConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByUserIdConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByUserIdConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByUserIdConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByUserIdConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByUserIdConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByUserIdConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByUserIdConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByUserIdConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ID_ASC',
  ProjectReportsByUserIdConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ID_DESC',
  ProjectReportsByUserIdConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByUserIdConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByUserIdConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByUserIdConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByUserIdConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByUserIdConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByUserIdConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByUserIdConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByUserIdConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByUserIdConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByUserIdConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByUserIdConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByUserIdConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByUserIdConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByUserIdConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByUserIdConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByUserIdConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByUserIdConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByUserIdConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByUserIdConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByUserIdConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_UID_ASC',
  ProjectReportsByUserIdConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_UID_DESC',
  ProjectReportsByUserIdConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByUserIdConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByUserIdConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByUserIdConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByUserIdConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionMinCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByUserIdConnectionMinCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByUserIdConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionMinIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ID_ASC',
  ProjectReportsByUserIdConnectionMinIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ID_DESC',
  ProjectReportsByUserIdConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByUserIdConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByUserIdConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByUserIdConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByUserIdConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByUserIdConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByUserIdConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionMinStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByUserIdConnectionMinStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByUserIdConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByUserIdConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByUserIdConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByUserIdConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByUserIdConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByUserIdConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByUserIdConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByUserIdConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByUserIdConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByUserIdConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByUserIdConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByUserIdConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByUserIdConnectionMinUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_UID_ASC',
  ProjectReportsByUserIdConnectionMinUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_UID_DESC',
  ProjectReportsByUserIdConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByUserIdConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByUserIdConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByUserIdConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByUserIdConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByUserIdConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByUserIdConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByUserIdConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByUserIdConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByUserIdConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByUserIdConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByUserIdConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByUserIdConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionSumCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByUserIdConnectionSumCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByUserIdConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionSumIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ID_ASC',
  ProjectReportsByUserIdConnectionSumIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ID_DESC',
  ProjectReportsByUserIdConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByUserIdConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByUserIdConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByUserIdConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByUserIdConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByUserIdConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByUserIdConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionSumStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByUserIdConnectionSumStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByUserIdConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByUserIdConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByUserIdConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByUserIdConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByUserIdConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByUserIdConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByUserIdConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByUserIdConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByUserIdConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByUserIdConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByUserIdConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByUserIdConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByUserIdConnectionSumUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_UID_ASC',
  ProjectReportsByUserIdConnectionSumUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_UID_DESC',
  ProjectReportsByUserIdConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByUserIdConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByUserIdConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByUserIdConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByUserIdConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByUserIdConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByUserIdConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByUserIdConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByUserIdConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByUserIdConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_USER_ID_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CITY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CITY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STATE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STATE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STREET_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STREET_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_UID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_UID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionArrayAggZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionArrayAggZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_ARRAY_AGG_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionAverageAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionAverageAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionAverageAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionAverageAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionAverageAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionAverageAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionAverageAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionAverageAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionAverageAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionAverageAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionAverageAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionAverageAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionAverageBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CITY_ASC',
  ProjectReportsByWorkerValueConnectionAverageCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CITY_DESC',
  ProjectReportsByWorkerValueConnectionAverageCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionAverageCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionAverageCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionAverageCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionAverageConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionAverageConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionAverageCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionAverageCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionAverageDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionAverageDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionAverageDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionAverageDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionAverageDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionAverageDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionAverageDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionAverageFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionAverageGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionAverageHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionAverageHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionAverageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionAverageImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionAverageIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionAverageIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionAverageIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionAverageIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionAverageJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionAverageOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionAverageOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionAveragePhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionAveragePhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionAveragePrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionAveragePrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionAveragePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionAveragePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionAverageProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionAverageProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionAverageProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionAverageProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionAverageProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionAverageProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionAverageProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionAverageRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionAverageRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionAverageSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionAverageStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STATE_ASC',
  ProjectReportsByWorkerValueConnectionAverageStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STATE_DESC',
  ProjectReportsByWorkerValueConnectionAverageStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionAverageStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionAverageStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STREET_ASC',
  ProjectReportsByWorkerValueConnectionAverageStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STREET_DESC',
  ProjectReportsByWorkerValueConnectionAverageStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionAverageStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionAverageTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionAverageTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionAverageTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionAverageTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionAverageTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionAverageTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionAverageTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionAverageTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionAverageTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionAverageTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionAverageTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionAverageTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionAverageTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionAverageTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionAverageUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_UID_ASC',
  ProjectReportsByWorkerValueConnectionAverageUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_UID_DESC',
  ProjectReportsByWorkerValueConnectionAverageUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionAverageUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionAverageUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionAverageUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionAverageWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionAverageWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionAverageZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionAverageZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_AVERAGE_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CITY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CITY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STATE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STATE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STREET_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STREET_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_UID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_UID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionDistinctCountZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionDistinctCountZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_DISTINCT_COUNT_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionMaxAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMaxAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMaxAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMaxAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMaxAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionMaxAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionMaxAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionMaxAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionMaxAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionMaxAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionMaxAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionMaxAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionMaxBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CITY_ASC',
  ProjectReportsByWorkerValueConnectionMaxCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CITY_DESC',
  ProjectReportsByWorkerValueConnectionMaxCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionMaxCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionMaxCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionMaxCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionMaxConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionMaxConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionMaxCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMaxCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMaxDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionMaxDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionMaxDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionMaxDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionMaxDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionMaxDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionMaxDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionMaxFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionMaxGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionMaxHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionMaxHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionMaxIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionMaxImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionMaxIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionMaxIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionMaxIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionMaxIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionMaxJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionMaxOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionMaxOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionMaxPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionMaxPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionMaxPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionMaxPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionMaxPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionMaxProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionMaxProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionMaxProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionMaxProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionMaxProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionMaxProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionMaxProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMaxRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMaxRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionMaxSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionMaxStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STATE_ASC',
  ProjectReportsByWorkerValueConnectionMaxStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STATE_DESC',
  ProjectReportsByWorkerValueConnectionMaxStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMaxStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMaxStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STREET_ASC',
  ProjectReportsByWorkerValueConnectionMaxStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STREET_DESC',
  ProjectReportsByWorkerValueConnectionMaxStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionMaxStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionMaxTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionMaxTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionMaxTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionMaxTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionMaxTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionMaxTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionMaxTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionMaxTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionMaxTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionMaxTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionMaxTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionMaxTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionMaxTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMaxTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMaxUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_UID_ASC',
  ProjectReportsByWorkerValueConnectionMaxUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_UID_DESC',
  ProjectReportsByWorkerValueConnectionMaxUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMaxUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMaxUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMaxUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMaxWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMaxWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMaxZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionMaxZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MAX_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionMinAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMinAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMinAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMinAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMinAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionMinAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionMinAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionMinAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionMinAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionMinAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionMinAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionMinAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionMinBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CITY_ASC',
  ProjectReportsByWorkerValueConnectionMinCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CITY_DESC',
  ProjectReportsByWorkerValueConnectionMinCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionMinCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionMinCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionMinCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionMinConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionMinConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionMinCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionMinCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionMinCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMinCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMinDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionMinDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionMinDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionMinDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionMinDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionMinDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionMinDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionMinFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionMinGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionMinHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionMinHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionMinIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionMinImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionMinIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionMinIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionMinIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionMinIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionMinJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionMinLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionMinLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionMinLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionMinLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionMinOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionMinOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionMinPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionMinPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionMinPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionMinPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionMinPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionMinProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionMinProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionMinProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionMinProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionMinProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionMinProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionMinProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMinRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMinRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionMinRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionMinSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionMinSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionMinStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STATE_ASC',
  ProjectReportsByWorkerValueConnectionMinStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STATE_DESC',
  ProjectReportsByWorkerValueConnectionMinStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMinStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMinStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STREET_ASC',
  ProjectReportsByWorkerValueConnectionMinStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STREET_DESC',
  ProjectReportsByWorkerValueConnectionMinStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionMinStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionMinTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionMinTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionMinTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionMinTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionMinTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionMinTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionMinTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionMinTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionMinTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionMinTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionMinTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionMinTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionMinTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionMinTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionMinTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionMinTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionMinUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_UID_ASC',
  ProjectReportsByWorkerValueConnectionMinUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_UID_DESC',
  ProjectReportsByWorkerValueConnectionMinUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionMinUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionMinUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionMinUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionMinWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionMinWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionMinZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionMinZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_MIN_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CITY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CITY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STATE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STATE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STREET_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STREET_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_UID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_UID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevPopulationZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionStddevPopulationZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_POPULATION_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CITY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CITY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSamplePhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSamplePhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionStddevSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionStddevSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STATE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STATE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STREET_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STREET_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionStddevSampleZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionStddevSampleZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_STDDEV_SAMPLE_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionSumAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionSumAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionSumAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionSumAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionSumAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionSumAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionSumAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionSumAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionSumAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionSumAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionSumAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionSumAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionSumBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CITY_ASC',
  ProjectReportsByWorkerValueConnectionSumCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CITY_DESC',
  ProjectReportsByWorkerValueConnectionSumCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionSumCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionSumCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionSumCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionSumConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionSumConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionSumCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionSumCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionSumCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionSumCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionSumDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionSumDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionSumDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionSumDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionSumDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionSumDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionSumDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionSumFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionSumGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionSumHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionSumHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionSumIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionSumImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionSumIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionSumIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionSumIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionSumIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionSumJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionSumLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionSumLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionSumLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionSumLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionSumOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionSumOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionSumPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionSumPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionSumPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionSumPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionSumPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionSumProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionSumProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionSumProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionSumProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionSumProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionSumProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionSumProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionSumRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionSumRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionSumRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionSumSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionSumSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionSumStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STATE_ASC',
  ProjectReportsByWorkerValueConnectionSumStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STATE_DESC',
  ProjectReportsByWorkerValueConnectionSumStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionSumStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionSumStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STREET_ASC',
  ProjectReportsByWorkerValueConnectionSumStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STREET_DESC',
  ProjectReportsByWorkerValueConnectionSumStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionSumStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionSumTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionSumTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionSumTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionSumTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionSumTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionSumTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionSumTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionSumTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionSumTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionSumTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionSumTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionSumTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionSumTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionSumTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionSumTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionSumTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionSumUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_UID_ASC',
  ProjectReportsByWorkerValueConnectionSumUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_UID_DESC',
  ProjectReportsByWorkerValueConnectionSumUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionSumUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionSumUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionSumUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionSumWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionSumWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionSumZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionSumZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_SUM_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CITY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CITY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationPrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STATE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STATE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STREET_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STREET_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVariancePopulationZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionVariancePopulationZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_POPULATION_ZIPCODE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAccountStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAccountStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAccountTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAccountTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ACCOUNT_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressPrettyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAddressPrettyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ADDRESS_PRETTY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAhjAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_AHJ_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleAhjDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_AHJ_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleBlueprintIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleBlueprintIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_BLUEPRINT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CITY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CITY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompanyIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompanyIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionDateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionDateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionTimeInSAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCompletionTimeInSDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleConfidenceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleConfidenceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CONFIDENCE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleCreatedByTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealValueWeightedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDealValueWeightedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DEAL_VALUE_WEIGHTED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDescriptionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDescriptionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDropdownValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleDropdownValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_DROPDOWN_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleFreshProjectAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleFreshProjectDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_FRESH_PROJECT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationGeographyAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationGeographyDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_GEOGRAPHY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationNormalizedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleGeoLocationNormalizedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_GEO_LOCATION_NORMALIZED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleHomeOwnerEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleHomeOwnerEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_HOME_OWNER_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleImageUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleImageUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIsActiveAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIsActiveDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIsCompletedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleIsCompletedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleJurisdictionIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleJurisdictionIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_JURISDICTION_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastActivityAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastActivityDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_ACTIVITY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastInboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_INBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundCallDispositionAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundCallDispositionDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_DISPOSITION_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundCallTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundCallTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_CALL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundEmailTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundEmailTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_EMAIL_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundSmsTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleLastOutboundSmsTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_LAST_OUTBOUND_SMS_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleNumericValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleNumericValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_NUMERIC_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleOverdueByAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleOverdueByDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_OVERDUE_BY_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleOwnerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleOwnerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_OWNER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleParentProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleParentProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PARENT_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePrimaryEmailAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePrimaryEmailDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRIMARY_EMAIL_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePrimaryPhoneAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSamplePrimaryPhoneDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRIMARY_PHONE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProductNameAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProductNameDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PRODUCT_NAME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProgressAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROGRESS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProgressDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROGRESS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectManagerIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectManagerIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_MANAGER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectSizeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectSizeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_SIZE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleProjectValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_PROJECT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleReferrerContactIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleReferrerContactIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REFERRER_CONTACT_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleRequestStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleRequestStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REQUEST_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleRevenueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REVENUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleRevenueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_REVENUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSalesRepIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSalesRepIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SALES_REP_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSlaRedViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSlaRedViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SLA_RED_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSlaYellowViolationJobIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSlaYellowViolationJobIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SLA_YELLOW_VIOLATION_JOB_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSourceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SOURCE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleSourceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_SOURCE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStateAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STATE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStateDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STATE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStreetAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STREET_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStreetDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STREET_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStreetViewUrlAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleStreetViewUrlDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_STREET_VIEW_URL_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTeamIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTeamIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTextValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTextValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TEXT_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStageStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STAGE_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusEndedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusEndedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_ENDED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusIsEndedAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusIsEndedDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_IS_ENDED_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusSpentTimeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusSpentTimeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_SPENT_TIME_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusStartedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTimelineStatusStartedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TIMELINE_STATUS_STARTED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTitleAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTitleDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalCostAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalCostDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_COST_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalInboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalInboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_INBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalOutboundCallsCountAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalOutboundCallsCountDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_OUTBOUND_CALLS_COUNT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalPriceAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTotalPriceDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TOTAL_PRICE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTradesAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TRADES_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTradesDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TRADES_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTradeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TRADE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTradeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TRADE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTypeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleTypeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUidAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUidDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUpdatedAtAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUpdatedAtDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUserIdAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_USER_ID_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleUserIdDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_USER_ID_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleWorkerValueAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleWorkerValueDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_WORKER_VALUE_DESC',
  ProjectReportsByWorkerValueConnectionVarianceSampleZipcodeAsc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_ASC',
  ProjectReportsByWorkerValueConnectionVarianceSampleZipcodeDesc = 'PROJECT_REPORTS_BY_WORKER_VALUE_CONNECTION_VARIANCE_SAMPLE_ZIPCODE_DESC',
  RemindersByAssigneeIdConnectionArrayAggAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionArrayAggCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionArrayAggCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionArrayAggCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionArrayAggCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionArrayAggCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionArrayAggDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionArrayAggDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionArrayAggDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionArrayAggDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionArrayAggIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionArrayAggIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionArrayAggIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionArrayAggIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionArrayAggJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionArrayAggPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionArrayAggProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionArrayAggProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionArrayAggTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_ASC',
  RemindersByAssigneeIdConnectionArrayAggTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TITLE_DESC',
  RemindersByAssigneeIdConnectionArrayAggTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TYPE_ASC',
  RemindersByAssigneeIdConnectionArrayAggTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_TYPE_DESC',
  RemindersByAssigneeIdConnectionArrayAggUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionArrayAggUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionAverageAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionAverageAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionAverageCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionAverageCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionAverageCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionAverageCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionAverageCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionAverageCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionAverageCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionAverageCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionAverageDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionAverageDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionAverageDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionAverageDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionAverageDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionAverageDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionAverageIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_ASC',
  RemindersByAssigneeIdConnectionAverageIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_ID_DESC',
  RemindersByAssigneeIdConnectionAverageIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionAverageIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionAverageIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionAverageIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionAverageJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionAverageJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionAveragePrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionAveragePrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionAveragePrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionAveragePrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionAveragePrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionAveragePrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionAveragePrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionAveragePrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionAverageProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionAverageProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionAverageTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_ASC',
  RemindersByAssigneeIdConnectionAverageTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TITLE_DESC',
  RemindersByAssigneeIdConnectionAverageTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TYPE_ASC',
  RemindersByAssigneeIdConnectionAverageTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_TYPE_DESC',
  RemindersByAssigneeIdConnectionAverageUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionAverageUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionCountAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_COUNT_ASC',
  RemindersByAssigneeIdConnectionCountDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_COUNT_DESC',
  RemindersByAssigneeIdConnectionDistinctCountAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionDistinctCountCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionDistinctCountCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionDistinctCountCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionDistinctCountCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionDistinctCountCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionDistinctCountDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionDistinctCountDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionDistinctCountDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionDistinctCountDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionDistinctCountIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionDistinctCountIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionDistinctCountIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionDistinctCountIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionDistinctCountJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionDistinctCountPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionDistinctCountProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionDistinctCountProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionDistinctCountTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  RemindersByAssigneeIdConnectionDistinctCountTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  RemindersByAssigneeIdConnectionDistinctCountTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  RemindersByAssigneeIdConnectionDistinctCountTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  RemindersByAssigneeIdConnectionDistinctCountUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionDistinctCountUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionMaxAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionMaxAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionMaxCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionMaxCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionMaxCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionMaxCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionMaxCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionMaxCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionMaxCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionMaxCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionMaxDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionMaxDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionMaxDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionMaxDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionMaxDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionMaxDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionMaxIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_ASC',
  RemindersByAssigneeIdConnectionMaxIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_ID_DESC',
  RemindersByAssigneeIdConnectionMaxIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionMaxIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionMaxIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionMaxIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionMaxJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionMaxJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionMaxPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionMaxPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionMaxPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionMaxPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionMaxPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionMaxPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionMaxPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionMaxPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionMaxProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionMaxProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionMaxTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_ASC',
  RemindersByAssigneeIdConnectionMaxTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_TITLE_DESC',
  RemindersByAssigneeIdConnectionMaxTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_TYPE_ASC',
  RemindersByAssigneeIdConnectionMaxTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_TYPE_DESC',
  RemindersByAssigneeIdConnectionMaxUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionMaxUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionMinAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionMinAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionMinCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionMinCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionMinCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionMinCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionMinCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionMinCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionMinCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionMinCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionMinDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionMinDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionMinDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionMinDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionMinDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionMinDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionMinIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_ASC',
  RemindersByAssigneeIdConnectionMinIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_ID_DESC',
  RemindersByAssigneeIdConnectionMinIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionMinIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionMinIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionMinIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionMinJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionMinJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionMinPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionMinPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionMinPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionMinPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionMinPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionMinPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionMinPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionMinPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionMinProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionMinProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionMinTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_ASC',
  RemindersByAssigneeIdConnectionMinTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_TITLE_DESC',
  RemindersByAssigneeIdConnectionMinTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_TYPE_ASC',
  RemindersByAssigneeIdConnectionMinTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_TYPE_DESC',
  RemindersByAssigneeIdConnectionMinUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionMinUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  RemindersByAssigneeIdConnectionStddevPopulationUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevPopulationUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevSampleAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSampleCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSampleCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevSampleCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevSampleCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevSampleCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionStddevSampleCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionStddevSampleCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionStddevSampleDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionStddevSampleDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionStddevSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSampleDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionStddevSampleDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionStddevSampleIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSampleIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionStddevSampleIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionStddevSampleIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionStddevSampleIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionStddevSampleJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionStddevSamplePrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionStddevSampleProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionStddevSampleProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionStddevSampleTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  RemindersByAssigneeIdConnectionStddevSampleTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  RemindersByAssigneeIdConnectionStddevSampleTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  RemindersByAssigneeIdConnectionStddevSampleTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  RemindersByAssigneeIdConnectionStddevSampleUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionStddevSampleUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionSumAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionSumAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionSumCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionSumCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionSumCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionSumCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionSumCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionSumCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionSumCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionSumCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionSumDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionSumDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionSumDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionSumDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionSumDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionSumDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionSumIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_ASC',
  RemindersByAssigneeIdConnectionSumIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_ID_DESC',
  RemindersByAssigneeIdConnectionSumIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionSumIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionSumIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionSumIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionSumJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionSumJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionSumPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionSumPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionSumPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionSumPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionSumPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionSumPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionSumPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionSumPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionSumProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionSumProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionSumTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_ASC',
  RemindersByAssigneeIdConnectionSumTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_TITLE_DESC',
  RemindersByAssigneeIdConnectionSumTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_TYPE_ASC',
  RemindersByAssigneeIdConnectionSumTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_TYPE_DESC',
  RemindersByAssigneeIdConnectionSumUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionSumUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationPrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  RemindersByAssigneeIdConnectionVariancePopulationUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionVariancePopulationUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleAssigneeIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleAssigneeIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleCompanyIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleCompanyIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleCompletedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleCompletedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleCreatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleCreatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleCreatedByAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleCreatedByDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleDescriptionAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleDescriptionDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleDueDateAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleDueDateDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleIsAppointmentAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleIsAppointmentDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleIsCompletedAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleIsCompletedDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleJobIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleJobIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeAllAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeAllDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeOwnAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeOwnDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeTeamAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByAssigneeIdConnectionVarianceSamplePrivilegeTeamDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleProjectIdAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleProjectIdDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleTitleAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleTitleDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleTypeAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleTypeDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  RemindersByAssigneeIdConnectionVarianceSampleUpdatedAtAsc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  RemindersByAssigneeIdConnectionVarianceSampleUpdatedAtDesc = 'REMINDERS_BY_ASSIGNEE_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionArrayAggAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionArrayAggAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionArrayAggCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionArrayAggCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionArrayAggCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionArrayAggCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionArrayAggCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  RemindersByCreatedByConnectionArrayAggCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  RemindersByCreatedByConnectionArrayAggCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  RemindersByCreatedByConnectionArrayAggCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  RemindersByCreatedByConnectionArrayAggDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionArrayAggDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionArrayAggDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionArrayAggDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionArrayAggDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_ASC',
  RemindersByCreatedByConnectionArrayAggDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DUE_DATE_DESC',
  RemindersByCreatedByConnectionArrayAggIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  RemindersByCreatedByConnectionArrayAggIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  RemindersByCreatedByConnectionArrayAggIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionArrayAggIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionArrayAggIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionArrayAggIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionArrayAggJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  RemindersByCreatedByConnectionArrayAggJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  RemindersByCreatedByConnectionArrayAggPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionArrayAggPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionArrayAggPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionArrayAggPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionArrayAggPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionArrayAggPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionArrayAggPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionArrayAggPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionArrayAggProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionArrayAggProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionArrayAggTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  RemindersByCreatedByConnectionArrayAggTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  RemindersByCreatedByConnectionArrayAggTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_ASC',
  RemindersByCreatedByConnectionArrayAggTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TYPE_DESC',
  RemindersByCreatedByConnectionArrayAggUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionArrayAggUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionAverageAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionAverageAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionAverageCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionAverageCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionAverageCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionAverageCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionAverageCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  RemindersByCreatedByConnectionAverageCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  RemindersByCreatedByConnectionAverageCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  RemindersByCreatedByConnectionAverageCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  RemindersByCreatedByConnectionAverageDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionAverageDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionAverageDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionAverageDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionAverageDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_ASC',
  RemindersByCreatedByConnectionAverageDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_DUE_DATE_DESC',
  RemindersByCreatedByConnectionAverageIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  RemindersByCreatedByConnectionAverageIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  RemindersByCreatedByConnectionAverageIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionAverageIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionAverageIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionAverageIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionAverageJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  RemindersByCreatedByConnectionAverageJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  RemindersByCreatedByConnectionAveragePrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionAveragePrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionAveragePrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionAveragePrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionAveragePrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionAveragePrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionAveragePrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionAveragePrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionAverageProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionAverageProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionAverageTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  RemindersByCreatedByConnectionAverageTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  RemindersByCreatedByConnectionAverageTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_ASC',
  RemindersByCreatedByConnectionAverageTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_TYPE_DESC',
  RemindersByCreatedByConnectionAverageUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionAverageUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionCountAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  RemindersByCreatedByConnectionCountDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  RemindersByCreatedByConnectionDistinctCountAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionDistinctCountCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionDistinctCountCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  RemindersByCreatedByConnectionDistinctCountCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  RemindersByCreatedByConnectionDistinctCountCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  RemindersByCreatedByConnectionDistinctCountCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  RemindersByCreatedByConnectionDistinctCountDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionDistinctCountDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionDistinctCountDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_ASC',
  RemindersByCreatedByConnectionDistinctCountDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DUE_DATE_DESC',
  RemindersByCreatedByConnectionDistinctCountIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionDistinctCountIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionDistinctCountIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionDistinctCountIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionDistinctCountJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionDistinctCountPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionDistinctCountProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionDistinctCountProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionDistinctCountTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  RemindersByCreatedByConnectionDistinctCountTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  RemindersByCreatedByConnectionDistinctCountTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_ASC',
  RemindersByCreatedByConnectionDistinctCountTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TYPE_DESC',
  RemindersByCreatedByConnectionDistinctCountUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionDistinctCountUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionMaxAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionMaxAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionMaxCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionMaxCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionMaxCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionMaxCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionMaxCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  RemindersByCreatedByConnectionMaxCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  RemindersByCreatedByConnectionMaxCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  RemindersByCreatedByConnectionMaxCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  RemindersByCreatedByConnectionMaxDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionMaxDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionMaxDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionMaxDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionMaxDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_ASC',
  RemindersByCreatedByConnectionMaxDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_DUE_DATE_DESC',
  RemindersByCreatedByConnectionMaxIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  RemindersByCreatedByConnectionMaxIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  RemindersByCreatedByConnectionMaxIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionMaxIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionMaxIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionMaxIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionMaxJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  RemindersByCreatedByConnectionMaxJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  RemindersByCreatedByConnectionMaxPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionMaxPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionMaxPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionMaxPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionMaxPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionMaxPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionMaxPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionMaxPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionMaxProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionMaxProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionMaxTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  RemindersByCreatedByConnectionMaxTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  RemindersByCreatedByConnectionMaxTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_TYPE_ASC',
  RemindersByCreatedByConnectionMaxTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_TYPE_DESC',
  RemindersByCreatedByConnectionMaxUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionMaxUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionMinAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionMinAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionMinCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionMinCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionMinCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionMinCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionMinCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  RemindersByCreatedByConnectionMinCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  RemindersByCreatedByConnectionMinCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  RemindersByCreatedByConnectionMinCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  RemindersByCreatedByConnectionMinDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionMinDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionMinDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionMinDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionMinDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_ASC',
  RemindersByCreatedByConnectionMinDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_DUE_DATE_DESC',
  RemindersByCreatedByConnectionMinIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  RemindersByCreatedByConnectionMinIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  RemindersByCreatedByConnectionMinIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionMinIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionMinIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionMinIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionMinJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  RemindersByCreatedByConnectionMinJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  RemindersByCreatedByConnectionMinPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionMinPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionMinPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionMinPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionMinPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionMinPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionMinPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionMinPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionMinProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionMinProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionMinTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  RemindersByCreatedByConnectionMinTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  RemindersByCreatedByConnectionMinTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_TYPE_ASC',
  RemindersByCreatedByConnectionMinTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_TYPE_DESC',
  RemindersByCreatedByConnectionMinUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionMinUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionStddevPopulationAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionStddevPopulationCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionStddevPopulationCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  RemindersByCreatedByConnectionStddevPopulationCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  RemindersByCreatedByConnectionStddevPopulationCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  RemindersByCreatedByConnectionStddevPopulationCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  RemindersByCreatedByConnectionStddevPopulationDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionStddevPopulationDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionStddevPopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_ASC',
  RemindersByCreatedByConnectionStddevPopulationDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DUE_DATE_DESC',
  RemindersByCreatedByConnectionStddevPopulationIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionStddevPopulationIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionStddevPopulationIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionStddevPopulationIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionStddevPopulationJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionStddevPopulationPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionStddevPopulationProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionStddevPopulationProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionStddevPopulationTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  RemindersByCreatedByConnectionStddevPopulationTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  RemindersByCreatedByConnectionStddevPopulationTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_ASC',
  RemindersByCreatedByConnectionStddevPopulationTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TYPE_DESC',
  RemindersByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionStddevSampleAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionStddevSampleCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionStddevSampleCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionStddevSampleCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionStddevSampleCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  RemindersByCreatedByConnectionStddevSampleCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  RemindersByCreatedByConnectionStddevSampleCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  RemindersByCreatedByConnectionStddevSampleCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  RemindersByCreatedByConnectionStddevSampleDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionStddevSampleDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionStddevSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionStddevSampleDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_ASC',
  RemindersByCreatedByConnectionStddevSampleDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DUE_DATE_DESC',
  RemindersByCreatedByConnectionStddevSampleIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  RemindersByCreatedByConnectionStddevSampleIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionStddevSampleIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionStddevSampleIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionStddevSampleIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionStddevSampleJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionStddevSamplePrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionStddevSampleProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionStddevSampleProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionStddevSampleTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  RemindersByCreatedByConnectionStddevSampleTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  RemindersByCreatedByConnectionStddevSampleTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_ASC',
  RemindersByCreatedByConnectionStddevSampleTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TYPE_DESC',
  RemindersByCreatedByConnectionStddevSampleUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionStddevSampleUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionSumAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionSumAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionSumCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionSumCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionSumCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionSumCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionSumCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  RemindersByCreatedByConnectionSumCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  RemindersByCreatedByConnectionSumCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  RemindersByCreatedByConnectionSumCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  RemindersByCreatedByConnectionSumDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionSumDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionSumDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionSumDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionSumDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_ASC',
  RemindersByCreatedByConnectionSumDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_DUE_DATE_DESC',
  RemindersByCreatedByConnectionSumIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  RemindersByCreatedByConnectionSumIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  RemindersByCreatedByConnectionSumIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionSumIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionSumIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionSumIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionSumJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  RemindersByCreatedByConnectionSumJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  RemindersByCreatedByConnectionSumPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionSumPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionSumPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionSumPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionSumPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionSumPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionSumPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionSumPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionSumProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionSumProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionSumTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  RemindersByCreatedByConnectionSumTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  RemindersByCreatedByConnectionSumTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_TYPE_ASC',
  RemindersByCreatedByConnectionSumTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_TYPE_DESC',
  RemindersByCreatedByConnectionSumUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionSumUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionVariancePopulationAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionVariancePopulationCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionVariancePopulationCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  RemindersByCreatedByConnectionVariancePopulationCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  RemindersByCreatedByConnectionVariancePopulationCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  RemindersByCreatedByConnectionVariancePopulationCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  RemindersByCreatedByConnectionVariancePopulationDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionVariancePopulationDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionVariancePopulationDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_ASC',
  RemindersByCreatedByConnectionVariancePopulationDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DUE_DATE_DESC',
  RemindersByCreatedByConnectionVariancePopulationIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionVariancePopulationIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionVariancePopulationIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionVariancePopulationIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionVariancePopulationJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionVariancePopulationPrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionVariancePopulationProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionVariancePopulationProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionVariancePopulationTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  RemindersByCreatedByConnectionVariancePopulationTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  RemindersByCreatedByConnectionVariancePopulationTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_ASC',
  RemindersByCreatedByConnectionVariancePopulationTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TYPE_DESC',
  RemindersByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  RemindersByCreatedByConnectionVarianceSampleAssigneeIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleAssigneeIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  RemindersByCreatedByConnectionVarianceSampleCompanyIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleCompanyIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  RemindersByCreatedByConnectionVarianceSampleCompletedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  RemindersByCreatedByConnectionVarianceSampleCompletedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  RemindersByCreatedByConnectionVarianceSampleCreatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  RemindersByCreatedByConnectionVarianceSampleCreatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  RemindersByCreatedByConnectionVarianceSampleCreatedByAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  RemindersByCreatedByConnectionVarianceSampleCreatedByDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  RemindersByCreatedByConnectionVarianceSampleDescriptionAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  RemindersByCreatedByConnectionVarianceSampleDescriptionDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  RemindersByCreatedByConnectionVarianceSampleDueDateApproachingJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleDueDateApproachingJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_APPROACHING_JOB_ID_DESC',
  RemindersByCreatedByConnectionVarianceSampleDueDateAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_ASC',
  RemindersByCreatedByConnectionVarianceSampleDueDateDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DUE_DATE_DESC',
  RemindersByCreatedByConnectionVarianceSampleIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  RemindersByCreatedByConnectionVarianceSampleIsAppointmentAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_ASC',
  RemindersByCreatedByConnectionVarianceSampleIsAppointmentDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_APPOINTMENT_DESC',
  RemindersByCreatedByConnectionVarianceSampleIsCompletedAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  RemindersByCreatedByConnectionVarianceSampleIsCompletedDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  RemindersByCreatedByConnectionVarianceSampleJobIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleJobIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeAllAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeAllDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ASC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_DESC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeOwnAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeOwnDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeTeamAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_ASC',
  RemindersByCreatedByConnectionVarianceSamplePrivilegeTeamDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_TEAM_DESC',
  RemindersByCreatedByConnectionVarianceSampleProjectIdAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  RemindersByCreatedByConnectionVarianceSampleProjectIdDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  RemindersByCreatedByConnectionVarianceSampleTitleAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  RemindersByCreatedByConnectionVarianceSampleTitleDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  RemindersByCreatedByConnectionVarianceSampleTypeAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_ASC',
  RemindersByCreatedByConnectionVarianceSampleTypeDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TYPE_DESC',
  RemindersByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  RemindersByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'REMINDERS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionCountAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  SmsActivitiesByCreatedByConnectionCountDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByFromUserIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByFromUserIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_FROM_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionArrayAggUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionArrayAggUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionAverageCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionAverageCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionAverageCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionAverageCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionAverageFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionAverageFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionAverageFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionAverageIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionAverageProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionAverageScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionAverageStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionAverageStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionAverageTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionAverageTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionAverageToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionAverageToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionAverageToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionAverageToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionAverageTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionAverageTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionAverageUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionAverageUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionCountAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_COUNT_ASC',
  SmsActivitiesByToUserIdConnectionCountDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_COUNT_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionDistinctCountUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionDistinctCountUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionMaxCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionMaxCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionMaxCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionMaxCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionMaxFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionMaxFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionMaxFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionMaxIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionMaxProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionMaxScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionMaxStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionMaxStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionMaxTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionMaxTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionMaxToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionMaxToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionMaxToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionMaxToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionMaxTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionMaxTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionMaxUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionMaxUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MAX_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionMinCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionMinCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionMinCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionMinCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionMinFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionMinFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionMinFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionMinIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionMinProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionMinScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionMinStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionMinStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionMinTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionMinTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionMinToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionMinToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionMinToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionMinToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionMinTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionMinTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionMinUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionMinUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_MIN_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionStddevPopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionStddevPopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionStddevSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionStddevSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionSumCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionSumCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionSumCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionSumCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionSumFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionSumFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionSumFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionSumIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionSumProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionSumScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionSumStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionSumStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionSumTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionSumTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionSumToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionSumToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionSumToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionSumToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionSumTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionSumTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionSumUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionSumUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_SUM_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionVariancePopulationUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionVariancePopulationUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCompanyIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCompanyIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCreatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCreatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCreatedByAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleCreatedByDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleFromUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_FROM_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleIsIncomingAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleIsIncomingDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_IS_INCOMING_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleProjectIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleProjectIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleScheduledForAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleScheduledForDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_SCHEDULED_FOR_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleStatusAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleStatusDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleTextAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleTextDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TEXT_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToCompanyPhoneIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToCompanyPhoneIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_COMPANY_PHONE_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToContactIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToContactIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_CONTACT_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToJurisdictionIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToJurisdictionIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_JURISDICTION_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToPhoneAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToPhoneDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_PHONE_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToUserIdAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleToUserIdDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TO_USER_ID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleTwilioSidAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleTwilioSidDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_TWILIO_SID_DESC',
  SmsActivitiesByToUserIdConnectionVarianceSampleUpdatedAtAsc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SmsActivitiesByToUserIdConnectionVarianceSampleUpdatedAtDesc = 'SMS_ACTIVITIES_BY_TO_USER_ID_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionArrayAggCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionArrayAggCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionArrayAggCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionArrayAggCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionArrayAggCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionArrayAggCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionArrayAggDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DATE_ASC',
  SubTasksByCreatedByConnectionArrayAggDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DATE_DESC',
  SubTasksByCreatedByConnectionArrayAggDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DETAIL_ASC',
  SubTasksByCreatedByConnectionArrayAggDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DETAIL_DESC',
  SubTasksByCreatedByConnectionArrayAggIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  SubTasksByCreatedByConnectionArrayAggIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  SubTasksByCreatedByConnectionArrayAggIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionArrayAggIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionArrayAggPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  SubTasksByCreatedByConnectionArrayAggPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  SubTasksByCreatedByConnectionArrayAggTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  SubTasksByCreatedByConnectionArrayAggTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  SubTasksByCreatedByConnectionArrayAggUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionArrayAggUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionAverageCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionAverageCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionAverageCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionAverageCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionAverageCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionAverageCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionAverageDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DATE_ASC',
  SubTasksByCreatedByConnectionAverageDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DATE_DESC',
  SubTasksByCreatedByConnectionAverageDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DETAIL_ASC',
  SubTasksByCreatedByConnectionAverageDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DETAIL_DESC',
  SubTasksByCreatedByConnectionAverageIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  SubTasksByCreatedByConnectionAverageIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  SubTasksByCreatedByConnectionAverageIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionAverageIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionAveragePositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  SubTasksByCreatedByConnectionAveragePositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  SubTasksByCreatedByConnectionAverageTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_ASC',
  SubTasksByCreatedByConnectionAverageTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TASK_ID_DESC',
  SubTasksByCreatedByConnectionAverageUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionAverageUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionCountAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  SubTasksByCreatedByConnectionCountDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  SubTasksByCreatedByConnectionDistinctCountCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionDistinctCountCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionDistinctCountCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionDistinctCountCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionDistinctCountCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionDistinctCountCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionDistinctCountDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DATE_ASC',
  SubTasksByCreatedByConnectionDistinctCountDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DATE_DESC',
  SubTasksByCreatedByConnectionDistinctCountDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DETAIL_ASC',
  SubTasksByCreatedByConnectionDistinctCountDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DETAIL_DESC',
  SubTasksByCreatedByConnectionDistinctCountIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  SubTasksByCreatedByConnectionDistinctCountIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  SubTasksByCreatedByConnectionDistinctCountIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionDistinctCountIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionDistinctCountPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  SubTasksByCreatedByConnectionDistinctCountPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  SubTasksByCreatedByConnectionDistinctCountTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  SubTasksByCreatedByConnectionDistinctCountTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  SubTasksByCreatedByConnectionDistinctCountUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionDistinctCountUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionMaxCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionMaxCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionMaxCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionMaxCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionMaxCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionMaxCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionMaxDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_DATE_ASC',
  SubTasksByCreatedByConnectionMaxDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_DATE_DESC',
  SubTasksByCreatedByConnectionMaxDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_DETAIL_ASC',
  SubTasksByCreatedByConnectionMaxDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_DETAIL_DESC',
  SubTasksByCreatedByConnectionMaxIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  SubTasksByCreatedByConnectionMaxIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  SubTasksByCreatedByConnectionMaxIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionMaxIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionMaxPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  SubTasksByCreatedByConnectionMaxPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  SubTasksByCreatedByConnectionMaxTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_ASC',
  SubTasksByCreatedByConnectionMaxTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_TASK_ID_DESC',
  SubTasksByCreatedByConnectionMaxUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionMaxUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MAX_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionMinCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionMinCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionMinCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionMinCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionMinCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionMinCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionMinDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_DATE_ASC',
  SubTasksByCreatedByConnectionMinDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_DATE_DESC',
  SubTasksByCreatedByConnectionMinDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_DETAIL_ASC',
  SubTasksByCreatedByConnectionMinDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_DETAIL_DESC',
  SubTasksByCreatedByConnectionMinIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  SubTasksByCreatedByConnectionMinIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  SubTasksByCreatedByConnectionMinIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionMinIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionMinPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  SubTasksByCreatedByConnectionMinPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  SubTasksByCreatedByConnectionMinTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_ASC',
  SubTasksByCreatedByConnectionMinTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_TASK_ID_DESC',
  SubTasksByCreatedByConnectionMinUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionMinUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_MIN_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionStddevPopulationCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionStddevPopulationCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionStddevPopulationCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionStddevPopulationCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionStddevPopulationCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionStddevPopulationCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionStddevPopulationDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DATE_ASC',
  SubTasksByCreatedByConnectionStddevPopulationDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DATE_DESC',
  SubTasksByCreatedByConnectionStddevPopulationDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DETAIL_ASC',
  SubTasksByCreatedByConnectionStddevPopulationDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DETAIL_DESC',
  SubTasksByCreatedByConnectionStddevPopulationIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  SubTasksByCreatedByConnectionStddevPopulationIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  SubTasksByCreatedByConnectionStddevPopulationIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionStddevPopulationIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionStddevPopulationPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  SubTasksByCreatedByConnectionStddevPopulationPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  SubTasksByCreatedByConnectionStddevPopulationTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  SubTasksByCreatedByConnectionStddevPopulationTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  SubTasksByCreatedByConnectionStddevPopulationUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionStddevPopulationUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionStddevSampleCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionStddevSampleCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionStddevSampleCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionStddevSampleCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionStddevSampleCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionStddevSampleCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionStddevSampleDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DATE_ASC',
  SubTasksByCreatedByConnectionStddevSampleDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DATE_DESC',
  SubTasksByCreatedByConnectionStddevSampleDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DETAIL_ASC',
  SubTasksByCreatedByConnectionStddevSampleDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DETAIL_DESC',
  SubTasksByCreatedByConnectionStddevSampleIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  SubTasksByCreatedByConnectionStddevSampleIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  SubTasksByCreatedByConnectionStddevSampleIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionStddevSampleIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionStddevSamplePositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  SubTasksByCreatedByConnectionStddevSamplePositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  SubTasksByCreatedByConnectionStddevSampleTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  SubTasksByCreatedByConnectionStddevSampleTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  SubTasksByCreatedByConnectionStddevSampleUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionStddevSampleUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionSumCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionSumCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionSumCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionSumCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionSumCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionSumCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionSumDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_DATE_ASC',
  SubTasksByCreatedByConnectionSumDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_DATE_DESC',
  SubTasksByCreatedByConnectionSumDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_DETAIL_ASC',
  SubTasksByCreatedByConnectionSumDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_DETAIL_DESC',
  SubTasksByCreatedByConnectionSumIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  SubTasksByCreatedByConnectionSumIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  SubTasksByCreatedByConnectionSumIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionSumIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionSumPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  SubTasksByCreatedByConnectionSumPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  SubTasksByCreatedByConnectionSumTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_ASC',
  SubTasksByCreatedByConnectionSumTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_TASK_ID_DESC',
  SubTasksByCreatedByConnectionSumUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionSumUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_SUM_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionVariancePopulationCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionVariancePopulationCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionVariancePopulationCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionVariancePopulationCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionVariancePopulationCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionVariancePopulationCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionVariancePopulationDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DATE_ASC',
  SubTasksByCreatedByConnectionVariancePopulationDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DATE_DESC',
  SubTasksByCreatedByConnectionVariancePopulationDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DETAIL_ASC',
  SubTasksByCreatedByConnectionVariancePopulationDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DETAIL_DESC',
  SubTasksByCreatedByConnectionVariancePopulationIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  SubTasksByCreatedByConnectionVariancePopulationIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  SubTasksByCreatedByConnectionVariancePopulationIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionVariancePopulationIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionVariancePopulationPositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  SubTasksByCreatedByConnectionVariancePopulationPositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  SubTasksByCreatedByConnectionVariancePopulationTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  SubTasksByCreatedByConnectionVariancePopulationTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  SubTasksByCreatedByConnectionVariancePopulationUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionVariancePopulationUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  SubTasksByCreatedByConnectionVarianceSampleCompanyIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SubTasksByCreatedByConnectionVarianceSampleCompanyIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SubTasksByCreatedByConnectionVarianceSampleCreatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  SubTasksByCreatedByConnectionVarianceSampleCreatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  SubTasksByCreatedByConnectionVarianceSampleCreatedByAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  SubTasksByCreatedByConnectionVarianceSampleCreatedByDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  SubTasksByCreatedByConnectionVarianceSampleDateAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DATE_ASC',
  SubTasksByCreatedByConnectionVarianceSampleDateDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DATE_DESC',
  SubTasksByCreatedByConnectionVarianceSampleDetailAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DETAIL_ASC',
  SubTasksByCreatedByConnectionVarianceSampleDetailDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DETAIL_DESC',
  SubTasksByCreatedByConnectionVarianceSampleIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  SubTasksByCreatedByConnectionVarianceSampleIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  SubTasksByCreatedByConnectionVarianceSampleIsCompletedAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  SubTasksByCreatedByConnectionVarianceSampleIsCompletedDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  SubTasksByCreatedByConnectionVarianceSamplePositionAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  SubTasksByCreatedByConnectionVarianceSamplePositionDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  SubTasksByCreatedByConnectionVarianceSampleTaskIdAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  SubTasksByCreatedByConnectionVarianceSampleTaskIdDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  SubTasksByCreatedByConnectionVarianceSampleUpdatedAtAsc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  SubTasksByCreatedByConnectionVarianceSampleUpdatedAtDesc = 'SUB_TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TasksByCreatedByConnectionArrayAggAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_ASC',
  TasksByCreatedByConnectionArrayAggAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ADDRESS_DESC',
  TasksByCreatedByConnectionArrayAggAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionArrayAggAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionArrayAggCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionArrayAggCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionArrayAggCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TasksByCreatedByConnectionArrayAggCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TasksByCreatedByConnectionArrayAggCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionArrayAggCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionArrayAggCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionArrayAggCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionArrayAggCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionArrayAggCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionArrayAggCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TasksByCreatedByConnectionArrayAggCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TasksByCreatedByConnectionArrayAggCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TasksByCreatedByConnectionArrayAggCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TasksByCreatedByConnectionArrayAggDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TasksByCreatedByConnectionArrayAggDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TasksByCreatedByConnectionArrayAggEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionArrayAggEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionArrayAggEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_ASC',
  TasksByCreatedByConnectionArrayAggEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_END_DATE_DESC',
  TasksByCreatedByConnectionArrayAggFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionArrayAggFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionArrayAggFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_ASC',
  TasksByCreatedByConnectionArrayAggFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_FORMS_DESC',
  TasksByCreatedByConnectionArrayAggIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_ASC',
  TasksByCreatedByConnectionArrayAggIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_ID_DESC',
  TasksByCreatedByConnectionArrayAggIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionArrayAggIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionArrayAggIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_ASC',
  TasksByCreatedByConnectionArrayAggIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COLORED_DESC',
  TasksByCreatedByConnectionArrayAggIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionArrayAggIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionArrayAggIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_ASC',
  TasksByCreatedByConnectionArrayAggIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_FIELD_DESC',
  TasksByCreatedByConnectionArrayAggIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionArrayAggIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionArrayAggJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_ASC',
  TasksByCreatedByConnectionArrayAggJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_JOB_ID_DESC',
  TasksByCreatedByConnectionArrayAggModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionArrayAggModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionArrayAggNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionArrayAggNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionArrayAggPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_ASC',
  TasksByCreatedByConnectionArrayAggPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_POSITION_DESC',
  TasksByCreatedByConnectionArrayAggPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_ASC',
  TasksByCreatedByConnectionArrayAggPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PRIORITY_DESC',
  TasksByCreatedByConnectionArrayAggProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_ASC',
  TasksByCreatedByConnectionArrayAggProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_ID_DESC',
  TasksByCreatedByConnectionArrayAggProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionArrayAggProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionArrayAggStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionArrayAggStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionArrayAggStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_ASC',
  TasksByCreatedByConnectionArrayAggStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_START_DATE_DESC',
  TasksByCreatedByConnectionArrayAggStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_ASC',
  TasksByCreatedByConnectionArrayAggStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_STATUS_DESC',
  TasksByCreatedByConnectionArrayAggSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionArrayAggSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionArrayAggTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionArrayAggTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionArrayAggTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionArrayAggTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionArrayAggTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionArrayAggTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionArrayAggTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_ASC',
  TasksByCreatedByConnectionArrayAggTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_TITLE_DESC',
  TasksByCreatedByConnectionArrayAggUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_ASC',
  TasksByCreatedByConnectionArrayAggUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_UID_DESC',
  TasksByCreatedByConnectionArrayAggVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionArrayAggVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_ARRAY_AGG_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionAverageAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_ASC',
  TasksByCreatedByConnectionAverageAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ADDRESS_DESC',
  TasksByCreatedByConnectionAverageAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionAverageAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionAverageCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionAverageCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionAverageCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TasksByCreatedByConnectionAverageCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TasksByCreatedByConnectionAverageCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionAverageCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionAverageCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionAverageCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionAverageCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionAverageCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionAverageCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TasksByCreatedByConnectionAverageCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TasksByCreatedByConnectionAverageCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TasksByCreatedByConnectionAverageCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TasksByCreatedByConnectionAverageDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TasksByCreatedByConnectionAverageDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TasksByCreatedByConnectionAverageEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionAverageEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionAverageEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_ASC',
  TasksByCreatedByConnectionAverageEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_END_DATE_DESC',
  TasksByCreatedByConnectionAverageFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionAverageFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionAverageFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_ASC',
  TasksByCreatedByConnectionAverageFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_FORMS_DESC',
  TasksByCreatedByConnectionAverageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_ASC',
  TasksByCreatedByConnectionAverageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_ID_DESC',
  TasksByCreatedByConnectionAverageIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionAverageIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionAverageIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_ASC',
  TasksByCreatedByConnectionAverageIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COLORED_DESC',
  TasksByCreatedByConnectionAverageIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionAverageIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionAverageIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_ASC',
  TasksByCreatedByConnectionAverageIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_FIELD_DESC',
  TasksByCreatedByConnectionAverageIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionAverageIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionAverageJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_ASC',
  TasksByCreatedByConnectionAverageJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_JOB_ID_DESC',
  TasksByCreatedByConnectionAverageModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionAverageModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionAverageNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionAverageNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionAveragePositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_ASC',
  TasksByCreatedByConnectionAveragePositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_POSITION_DESC',
  TasksByCreatedByConnectionAveragePriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_ASC',
  TasksByCreatedByConnectionAveragePriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PRIORITY_DESC',
  TasksByCreatedByConnectionAverageProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_ASC',
  TasksByCreatedByConnectionAverageProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_ID_DESC',
  TasksByCreatedByConnectionAverageProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionAverageProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionAverageStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionAverageStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionAverageStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_ASC',
  TasksByCreatedByConnectionAverageStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_START_DATE_DESC',
  TasksByCreatedByConnectionAverageStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_ASC',
  TasksByCreatedByConnectionAverageStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_STATUS_DESC',
  TasksByCreatedByConnectionAverageSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionAverageSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionAverageTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionAverageTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionAverageTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionAverageTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionAverageTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionAverageTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionAverageTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_ASC',
  TasksByCreatedByConnectionAverageTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_TITLE_DESC',
  TasksByCreatedByConnectionAverageUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_ASC',
  TasksByCreatedByConnectionAverageUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_UID_DESC',
  TasksByCreatedByConnectionAverageVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionAverageVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_AVERAGE_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionCountAsc = 'TASKS_BY_CREATED_BY_CONNECTION_COUNT_ASC',
  TasksByCreatedByConnectionCountDesc = 'TASKS_BY_CREATED_BY_CONNECTION_COUNT_DESC',
  TasksByCreatedByConnectionDistinctCountAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_ASC',
  TasksByCreatedByConnectionDistinctCountAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ADDRESS_DESC',
  TasksByCreatedByConnectionDistinctCountAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionDistinctCountAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionDistinctCountCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionDistinctCountCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionDistinctCountCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TasksByCreatedByConnectionDistinctCountCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TasksByCreatedByConnectionDistinctCountCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionDistinctCountCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionDistinctCountCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionDistinctCountCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionDistinctCountCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionDistinctCountCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionDistinctCountCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TasksByCreatedByConnectionDistinctCountCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TasksByCreatedByConnectionDistinctCountCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TasksByCreatedByConnectionDistinctCountCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TasksByCreatedByConnectionDistinctCountDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TasksByCreatedByConnectionDistinctCountDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TasksByCreatedByConnectionDistinctCountEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionDistinctCountEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionDistinctCountEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_ASC',
  TasksByCreatedByConnectionDistinctCountEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_END_DATE_DESC',
  TasksByCreatedByConnectionDistinctCountFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionDistinctCountFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionDistinctCountFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_ASC',
  TasksByCreatedByConnectionDistinctCountFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_FORMS_DESC',
  TasksByCreatedByConnectionDistinctCountIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TasksByCreatedByConnectionDistinctCountIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TasksByCreatedByConnectionDistinctCountIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionDistinctCountIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionDistinctCountIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_ASC',
  TasksByCreatedByConnectionDistinctCountIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COLORED_DESC',
  TasksByCreatedByConnectionDistinctCountIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionDistinctCountIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionDistinctCountIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_ASC',
  TasksByCreatedByConnectionDistinctCountIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_FIELD_DESC',
  TasksByCreatedByConnectionDistinctCountIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionDistinctCountIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionDistinctCountJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_ASC',
  TasksByCreatedByConnectionDistinctCountJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_JOB_ID_DESC',
  TasksByCreatedByConnectionDistinctCountModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionDistinctCountModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionDistinctCountNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionDistinctCountNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionDistinctCountPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_ASC',
  TasksByCreatedByConnectionDistinctCountPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_POSITION_DESC',
  TasksByCreatedByConnectionDistinctCountPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_ASC',
  TasksByCreatedByConnectionDistinctCountPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PRIORITY_DESC',
  TasksByCreatedByConnectionDistinctCountProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_ASC',
  TasksByCreatedByConnectionDistinctCountProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_ID_DESC',
  TasksByCreatedByConnectionDistinctCountProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionDistinctCountProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionDistinctCountStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionDistinctCountStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionDistinctCountStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_ASC',
  TasksByCreatedByConnectionDistinctCountStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_START_DATE_DESC',
  TasksByCreatedByConnectionDistinctCountStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_ASC',
  TasksByCreatedByConnectionDistinctCountStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_STATUS_DESC',
  TasksByCreatedByConnectionDistinctCountSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionDistinctCountSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionDistinctCountTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionDistinctCountTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionDistinctCountTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionDistinctCountTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionDistinctCountTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionDistinctCountTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_ASC',
  TasksByCreatedByConnectionDistinctCountTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_TITLE_DESC',
  TasksByCreatedByConnectionDistinctCountUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_ASC',
  TasksByCreatedByConnectionDistinctCountUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_UID_DESC',
  TasksByCreatedByConnectionDistinctCountVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionDistinctCountVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_DISTINCT_COUNT_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionMaxAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_ASC',
  TasksByCreatedByConnectionMaxAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ADDRESS_DESC',
  TasksByCreatedByConnectionMaxAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionMaxAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionMaxCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionMaxCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionMaxCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_ASC',
  TasksByCreatedByConnectionMaxCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPANY_ID_DESC',
  TasksByCreatedByConnectionMaxCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionMaxCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionMaxCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionMaxCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionMaxCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionMaxCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionMaxCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_ASC',
  TasksByCreatedByConnectionMaxCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_AT_DESC',
  TasksByCreatedByConnectionMaxCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_ASC',
  TasksByCreatedByConnectionMaxCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_CREATED_BY_DESC',
  TasksByCreatedByConnectionMaxDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_ASC',
  TasksByCreatedByConnectionMaxDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_DESCRIPTION_DESC',
  TasksByCreatedByConnectionMaxEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionMaxEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionMaxEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_ASC',
  TasksByCreatedByConnectionMaxEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_END_DATE_DESC',
  TasksByCreatedByConnectionMaxFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionMaxFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionMaxFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_FORMS_ASC',
  TasksByCreatedByConnectionMaxFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_FORMS_DESC',
  TasksByCreatedByConnectionMaxIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_ASC',
  TasksByCreatedByConnectionMaxIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_ID_DESC',
  TasksByCreatedByConnectionMaxIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionMaxIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionMaxIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_ASC',
  TasksByCreatedByConnectionMaxIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COLORED_DESC',
  TasksByCreatedByConnectionMaxIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionMaxIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionMaxIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_ASC',
  TasksByCreatedByConnectionMaxIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_FIELD_DESC',
  TasksByCreatedByConnectionMaxIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionMaxIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionMaxJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_ASC',
  TasksByCreatedByConnectionMaxJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_JOB_ID_DESC',
  TasksByCreatedByConnectionMaxModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionMaxModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionMaxNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionMaxNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionMaxPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_ASC',
  TasksByCreatedByConnectionMaxPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_POSITION_DESC',
  TasksByCreatedByConnectionMaxPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_ASC',
  TasksByCreatedByConnectionMaxPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PRIORITY_DESC',
  TasksByCreatedByConnectionMaxProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_ASC',
  TasksByCreatedByConnectionMaxProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_ID_DESC',
  TasksByCreatedByConnectionMaxProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionMaxProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionMaxStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionMaxStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionMaxStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_ASC',
  TasksByCreatedByConnectionMaxStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_START_DATE_DESC',
  TasksByCreatedByConnectionMaxStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_STATUS_ASC',
  TasksByCreatedByConnectionMaxStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_STATUS_DESC',
  TasksByCreatedByConnectionMaxSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionMaxSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionMaxTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionMaxTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionMaxTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionMaxTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionMaxTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionMaxTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionMaxTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TITLE_ASC',
  TasksByCreatedByConnectionMaxTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_TITLE_DESC',
  TasksByCreatedByConnectionMaxUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_UID_ASC',
  TasksByCreatedByConnectionMaxUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_UID_DESC',
  TasksByCreatedByConnectionMaxVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionMaxVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MAX_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionMinAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_ASC',
  TasksByCreatedByConnectionMinAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ADDRESS_DESC',
  TasksByCreatedByConnectionMinAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionMinAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionMinCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionMinCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionMinCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_ASC',
  TasksByCreatedByConnectionMinCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPANY_ID_DESC',
  TasksByCreatedByConnectionMinCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionMinCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionMinCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionMinCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionMinCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionMinCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionMinCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_ASC',
  TasksByCreatedByConnectionMinCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_AT_DESC',
  TasksByCreatedByConnectionMinCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_ASC',
  TasksByCreatedByConnectionMinCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_CREATED_BY_DESC',
  TasksByCreatedByConnectionMinDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_ASC',
  TasksByCreatedByConnectionMinDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_DESCRIPTION_DESC',
  TasksByCreatedByConnectionMinEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionMinEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionMinEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_ASC',
  TasksByCreatedByConnectionMinEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_END_DATE_DESC',
  TasksByCreatedByConnectionMinFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionMinFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionMinFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_FORMS_ASC',
  TasksByCreatedByConnectionMinFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_FORMS_DESC',
  TasksByCreatedByConnectionMinIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_ASC',
  TasksByCreatedByConnectionMinIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_ID_DESC',
  TasksByCreatedByConnectionMinIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionMinIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionMinIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_ASC',
  TasksByCreatedByConnectionMinIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COLORED_DESC',
  TasksByCreatedByConnectionMinIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionMinIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionMinIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_ASC',
  TasksByCreatedByConnectionMinIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_FIELD_DESC',
  TasksByCreatedByConnectionMinIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionMinIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionMinJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_ASC',
  TasksByCreatedByConnectionMinJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_JOB_ID_DESC',
  TasksByCreatedByConnectionMinModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionMinModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionMinNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionMinNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionMinPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_ASC',
  TasksByCreatedByConnectionMinPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_POSITION_DESC',
  TasksByCreatedByConnectionMinPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_ASC',
  TasksByCreatedByConnectionMinPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PRIORITY_DESC',
  TasksByCreatedByConnectionMinProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_ASC',
  TasksByCreatedByConnectionMinProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_ID_DESC',
  TasksByCreatedByConnectionMinProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionMinProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionMinStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionMinStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionMinStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_ASC',
  TasksByCreatedByConnectionMinStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_START_DATE_DESC',
  TasksByCreatedByConnectionMinStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_STATUS_ASC',
  TasksByCreatedByConnectionMinStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_STATUS_DESC',
  TasksByCreatedByConnectionMinSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionMinSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionMinTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionMinTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionMinTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionMinTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionMinTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionMinTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionMinTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TITLE_ASC',
  TasksByCreatedByConnectionMinTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_TITLE_DESC',
  TasksByCreatedByConnectionMinUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_UID_ASC',
  TasksByCreatedByConnectionMinUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_UID_DESC',
  TasksByCreatedByConnectionMinVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionMinVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_MIN_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionStddevPopulationAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_ASC',
  TasksByCreatedByConnectionStddevPopulationAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ADDRESS_DESC',
  TasksByCreatedByConnectionStddevPopulationAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionStddevPopulationCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionStddevPopulationCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionStddevPopulationCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionStddevPopulationCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionStddevPopulationCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionStddevPopulationCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionStddevPopulationCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionStddevPopulationCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TasksByCreatedByConnectionStddevPopulationCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TasksByCreatedByConnectionStddevPopulationCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TasksByCreatedByConnectionStddevPopulationCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TasksByCreatedByConnectionStddevPopulationDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TasksByCreatedByConnectionStddevPopulationDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TasksByCreatedByConnectionStddevPopulationEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionStddevPopulationEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionStddevPopulationEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_ASC',
  TasksByCreatedByConnectionStddevPopulationEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_END_DATE_DESC',
  TasksByCreatedByConnectionStddevPopulationFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_ASC',
  TasksByCreatedByConnectionStddevPopulationFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_FORMS_DESC',
  TasksByCreatedByConnectionStddevPopulationIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionStddevPopulationIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionStddevPopulationIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_ASC',
  TasksByCreatedByConnectionStddevPopulationIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COLORED_DESC',
  TasksByCreatedByConnectionStddevPopulationIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionStddevPopulationIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionStddevPopulationIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_ASC',
  TasksByCreatedByConnectionStddevPopulationIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_FIELD_DESC',
  TasksByCreatedByConnectionStddevPopulationIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionStddevPopulationIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionStddevPopulationJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_JOB_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionStddevPopulationModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionStddevPopulationNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_ASC',
  TasksByCreatedByConnectionStddevPopulationPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_POSITION_DESC',
  TasksByCreatedByConnectionStddevPopulationPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_ASC',
  TasksByCreatedByConnectionStddevPopulationPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PRIORITY_DESC',
  TasksByCreatedByConnectionStddevPopulationProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionStddevPopulationStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionStddevPopulationStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_ASC',
  TasksByCreatedByConnectionStddevPopulationStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_START_DATE_DESC',
  TasksByCreatedByConnectionStddevPopulationStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_ASC',
  TasksByCreatedByConnectionStddevPopulationStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_STATUS_DESC',
  TasksByCreatedByConnectionStddevPopulationSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionStddevPopulationTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionStddevPopulationTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionStddevPopulationTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionStddevPopulationTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_ASC',
  TasksByCreatedByConnectionStddevPopulationTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_TITLE_DESC',
  TasksByCreatedByConnectionStddevPopulationUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_ASC',
  TasksByCreatedByConnectionStddevPopulationUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_UID_DESC',
  TasksByCreatedByConnectionStddevPopulationVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionStddevPopulationVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionStddevSampleAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_ASC',
  TasksByCreatedByConnectionStddevSampleAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ADDRESS_DESC',
  TasksByCreatedByConnectionStddevSampleAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionStddevSampleAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionStddevSampleCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionStddevSampleCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionStddevSampleCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TasksByCreatedByConnectionStddevSampleCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TasksByCreatedByConnectionStddevSampleCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionStddevSampleCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionStddevSampleCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionStddevSampleCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionStddevSampleCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionStddevSampleCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionStddevSampleCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TasksByCreatedByConnectionStddevSampleCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TasksByCreatedByConnectionStddevSampleCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TasksByCreatedByConnectionStddevSampleCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TasksByCreatedByConnectionStddevSampleDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TasksByCreatedByConnectionStddevSampleDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TasksByCreatedByConnectionStddevSampleEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionStddevSampleEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionStddevSampleEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_ASC',
  TasksByCreatedByConnectionStddevSampleEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_END_DATE_DESC',
  TasksByCreatedByConnectionStddevSampleFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionStddevSampleFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionStddevSampleFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_ASC',
  TasksByCreatedByConnectionStddevSampleFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_FORMS_DESC',
  TasksByCreatedByConnectionStddevSampleIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TasksByCreatedByConnectionStddevSampleIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TasksByCreatedByConnectionStddevSampleIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionStddevSampleIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionStddevSampleIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_ASC',
  TasksByCreatedByConnectionStddevSampleIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COLORED_DESC',
  TasksByCreatedByConnectionStddevSampleIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionStddevSampleIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionStddevSampleIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_ASC',
  TasksByCreatedByConnectionStddevSampleIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_FIELD_DESC',
  TasksByCreatedByConnectionStddevSampleIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionStddevSampleIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionStddevSampleJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_ASC',
  TasksByCreatedByConnectionStddevSampleJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_JOB_ID_DESC',
  TasksByCreatedByConnectionStddevSampleModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionStddevSampleModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionStddevSampleNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionStddevSampleNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionStddevSamplePositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_ASC',
  TasksByCreatedByConnectionStddevSamplePositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_POSITION_DESC',
  TasksByCreatedByConnectionStddevSamplePriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_ASC',
  TasksByCreatedByConnectionStddevSamplePriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PRIORITY_DESC',
  TasksByCreatedByConnectionStddevSampleProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_ASC',
  TasksByCreatedByConnectionStddevSampleProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_ID_DESC',
  TasksByCreatedByConnectionStddevSampleProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionStddevSampleProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionStddevSampleStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionStddevSampleStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionStddevSampleStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_ASC',
  TasksByCreatedByConnectionStddevSampleStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_START_DATE_DESC',
  TasksByCreatedByConnectionStddevSampleStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_ASC',
  TasksByCreatedByConnectionStddevSampleStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_STATUS_DESC',
  TasksByCreatedByConnectionStddevSampleSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionStddevSampleSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionStddevSampleTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionStddevSampleTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionStddevSampleTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionStddevSampleTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionStddevSampleTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionStddevSampleTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_ASC',
  TasksByCreatedByConnectionStddevSampleTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_TITLE_DESC',
  TasksByCreatedByConnectionStddevSampleUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_ASC',
  TasksByCreatedByConnectionStddevSampleUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_UID_DESC',
  TasksByCreatedByConnectionStddevSampleVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionStddevSampleVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_STDDEV_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionSumAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_ASC',
  TasksByCreatedByConnectionSumAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ADDRESS_DESC',
  TasksByCreatedByConnectionSumAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionSumAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionSumCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionSumCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionSumCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_ASC',
  TasksByCreatedByConnectionSumCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPANY_ID_DESC',
  TasksByCreatedByConnectionSumCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionSumCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionSumCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionSumCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionSumCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionSumCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionSumCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_ASC',
  TasksByCreatedByConnectionSumCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_AT_DESC',
  TasksByCreatedByConnectionSumCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_ASC',
  TasksByCreatedByConnectionSumCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_CREATED_BY_DESC',
  TasksByCreatedByConnectionSumDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_ASC',
  TasksByCreatedByConnectionSumDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_DESCRIPTION_DESC',
  TasksByCreatedByConnectionSumEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionSumEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionSumEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_ASC',
  TasksByCreatedByConnectionSumEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_END_DATE_DESC',
  TasksByCreatedByConnectionSumFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionSumFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionSumFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_FORMS_ASC',
  TasksByCreatedByConnectionSumFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_FORMS_DESC',
  TasksByCreatedByConnectionSumIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_ASC',
  TasksByCreatedByConnectionSumIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_ID_DESC',
  TasksByCreatedByConnectionSumIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionSumIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionSumIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_ASC',
  TasksByCreatedByConnectionSumIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COLORED_DESC',
  TasksByCreatedByConnectionSumIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionSumIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionSumIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_ASC',
  TasksByCreatedByConnectionSumIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_FIELD_DESC',
  TasksByCreatedByConnectionSumIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionSumIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionSumJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_ASC',
  TasksByCreatedByConnectionSumJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_JOB_ID_DESC',
  TasksByCreatedByConnectionSumModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionSumModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionSumNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionSumNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionSumPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_ASC',
  TasksByCreatedByConnectionSumPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_POSITION_DESC',
  TasksByCreatedByConnectionSumPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_ASC',
  TasksByCreatedByConnectionSumPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PRIORITY_DESC',
  TasksByCreatedByConnectionSumProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_ASC',
  TasksByCreatedByConnectionSumProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_ID_DESC',
  TasksByCreatedByConnectionSumProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionSumProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionSumStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionSumStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionSumStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_ASC',
  TasksByCreatedByConnectionSumStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_START_DATE_DESC',
  TasksByCreatedByConnectionSumStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_STATUS_ASC',
  TasksByCreatedByConnectionSumStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_STATUS_DESC',
  TasksByCreatedByConnectionSumSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionSumSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionSumTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionSumTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionSumTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionSumTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionSumTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionSumTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionSumTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TITLE_ASC',
  TasksByCreatedByConnectionSumTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_TITLE_DESC',
  TasksByCreatedByConnectionSumUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_UID_ASC',
  TasksByCreatedByConnectionSumUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_UID_DESC',
  TasksByCreatedByConnectionSumVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionSumVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_SUM_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionVariancePopulationAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_ASC',
  TasksByCreatedByConnectionVariancePopulationAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ADDRESS_DESC',
  TasksByCreatedByConnectionVariancePopulationAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionVariancePopulationCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionVariancePopulationCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionVariancePopulationCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionVariancePopulationCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionVariancePopulationCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionVariancePopulationCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionVariancePopulationCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionVariancePopulationCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TasksByCreatedByConnectionVariancePopulationCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TasksByCreatedByConnectionVariancePopulationCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TasksByCreatedByConnectionVariancePopulationCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TasksByCreatedByConnectionVariancePopulationDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TasksByCreatedByConnectionVariancePopulationDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TasksByCreatedByConnectionVariancePopulationEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionVariancePopulationEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionVariancePopulationEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_ASC',
  TasksByCreatedByConnectionVariancePopulationEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_END_DATE_DESC',
  TasksByCreatedByConnectionVariancePopulationFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_ASC',
  TasksByCreatedByConnectionVariancePopulationFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_FORMS_DESC',
  TasksByCreatedByConnectionVariancePopulationIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionVariancePopulationIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionVariancePopulationIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_ASC',
  TasksByCreatedByConnectionVariancePopulationIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COLORED_DESC',
  TasksByCreatedByConnectionVariancePopulationIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionVariancePopulationIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionVariancePopulationIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_ASC',
  TasksByCreatedByConnectionVariancePopulationIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_FIELD_DESC',
  TasksByCreatedByConnectionVariancePopulationIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionVariancePopulationIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionVariancePopulationJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_JOB_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionVariancePopulationModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionVariancePopulationNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationPositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_ASC',
  TasksByCreatedByConnectionVariancePopulationPositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_POSITION_DESC',
  TasksByCreatedByConnectionVariancePopulationPriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_ASC',
  TasksByCreatedByConnectionVariancePopulationPriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PRIORITY_DESC',
  TasksByCreatedByConnectionVariancePopulationProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionVariancePopulationStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionVariancePopulationStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_ASC',
  TasksByCreatedByConnectionVariancePopulationStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_START_DATE_DESC',
  TasksByCreatedByConnectionVariancePopulationStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_ASC',
  TasksByCreatedByConnectionVariancePopulationStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_STATUS_DESC',
  TasksByCreatedByConnectionVariancePopulationSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionVariancePopulationTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionVariancePopulationTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionVariancePopulationTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionVariancePopulationTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_ASC',
  TasksByCreatedByConnectionVariancePopulationTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_TITLE_DESC',
  TasksByCreatedByConnectionVariancePopulationUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_ASC',
  TasksByCreatedByConnectionVariancePopulationUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_UID_DESC',
  TasksByCreatedByConnectionVariancePopulationVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionVariancePopulationVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_POPULATION_VIRTUAL_PROPERTIES_DESC',
  TasksByCreatedByConnectionVarianceSampleAddressAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_ASC',
  TasksByCreatedByConnectionVarianceSampleAddressDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ADDRESS_DESC',
  TasksByCreatedByConnectionVarianceSampleAssigneeIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleAssigneeIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleCalculatedStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_ASC',
  TasksByCreatedByConnectionVarianceSampleCalculatedStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CALCULATED_STATUS_DESC',
  TasksByCreatedByConnectionVarianceSampleCompanyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleCompanyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleCompletionDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_ASC',
  TasksByCreatedByConnectionVarianceSampleCompletionDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_DATE_DESC',
  TasksByCreatedByConnectionVarianceSampleCompletionTimeAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_ASC',
  TasksByCreatedByConnectionVarianceSampleCompletionTimeDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_DESC',
  TasksByCreatedByConnectionVarianceSampleCompletionTimeInSAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_ASC',
  TasksByCreatedByConnectionVarianceSampleCompletionTimeInSDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_COMPLETION_TIME_IN_S_DESC',
  TasksByCreatedByConnectionVarianceSampleCreatedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TasksByCreatedByConnectionVarianceSampleCreatedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TasksByCreatedByConnectionVarianceSampleCreatedByAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TasksByCreatedByConnectionVarianceSampleCreatedByDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TasksByCreatedByConnectionVarianceSampleDescriptionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TasksByCreatedByConnectionVarianceSampleDescriptionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TasksByCreatedByConnectionVarianceSampleEndDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionVarianceSampleEndDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionVarianceSampleEndDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_ASC',
  TasksByCreatedByConnectionVarianceSampleEndDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_END_DATE_DESC',
  TasksByCreatedByConnectionVarianceSampleFirstLabelIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleFirstLabelIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FIRST_LABEL_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleFormsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_ASC',
  TasksByCreatedByConnectionVarianceSampleFormsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_FORMS_DESC',
  TasksByCreatedByConnectionVarianceSampleIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleIsArchivedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_ASC',
  TasksByCreatedByConnectionVarianceSampleIsArchivedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_ARCHIVED_DESC',
  TasksByCreatedByConnectionVarianceSampleIsColoredAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_ASC',
  TasksByCreatedByConnectionVarianceSampleIsColoredDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COLORED_DESC',
  TasksByCreatedByConnectionVarianceSampleIsCompletedAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_ASC',
  TasksByCreatedByConnectionVarianceSampleIsCompletedDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_COMPLETED_DESC',
  TasksByCreatedByConnectionVarianceSampleIsFieldAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_ASC',
  TasksByCreatedByConnectionVarianceSampleIsFieldDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_FIELD_DESC',
  TasksByCreatedByConnectionVarianceSampleIsTemplateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_ASC',
  TasksByCreatedByConnectionVarianceSampleIsTemplateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_IS_TEMPLATE_DESC',
  TasksByCreatedByConnectionVarianceSampleJobIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleJobIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_JOB_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleModifiedAtAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_ASC',
  TasksByCreatedByConnectionVarianceSampleModifiedAtDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_MODIFIED_AT_DESC',
  TasksByCreatedByConnectionVarianceSampleNextVisitIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleNextVisitIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_NEXT_VISIT_ID_DESC',
  TasksByCreatedByConnectionVarianceSamplePositionAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_ASC',
  TasksByCreatedByConnectionVarianceSamplePositionDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_POSITION_DESC',
  TasksByCreatedByConnectionVarianceSamplePriorityAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_ASC',
  TasksByCreatedByConnectionVarianceSamplePriorityDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PRIORITY_DESC',
  TasksByCreatedByConnectionVarianceSampleProjectIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleProjectIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleProjectStageIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleProjectStageIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_PROJECT_STAGE_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleStartDateAllDayAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_ASC',
  TasksByCreatedByConnectionVarianceSampleStartDateAllDayDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ALL_DAY_DESC',
  TasksByCreatedByConnectionVarianceSampleStartDateAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_ASC',
  TasksByCreatedByConnectionVarianceSampleStartDateDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_START_DATE_DESC',
  TasksByCreatedByConnectionVarianceSampleStatusAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_ASC',
  TasksByCreatedByConnectionVarianceSampleStatusDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_STATUS_DESC',
  TasksByCreatedByConnectionVarianceSampleSystemIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleSystemIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_SYSTEM_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleTemplateAssigneePropertyIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_ASSIGNEE_PROPERTY_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleTemplatePropertyIdsAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_ASC',
  TasksByCreatedByConnectionVarianceSampleTemplatePropertyIdsDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_PROPERTY_IDS_DESC',
  TasksByCreatedByConnectionVarianceSampleTemplateTaskIdAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_ASC',
  TasksByCreatedByConnectionVarianceSampleTemplateTaskIdDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TEMPLATE_TASK_ID_DESC',
  TasksByCreatedByConnectionVarianceSampleTitleAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_ASC',
  TasksByCreatedByConnectionVarianceSampleTitleDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_TITLE_DESC',
  TasksByCreatedByConnectionVarianceSampleUidAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_ASC',
  TasksByCreatedByConnectionVarianceSampleUidDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_UID_DESC',
  TasksByCreatedByConnectionVarianceSampleVirtualPropertiesAsc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_ASC',
  TasksByCreatedByConnectionVarianceSampleVirtualPropertiesDesc = 'TASKS_BY_CREATED_BY_CONNECTION_VARIANCE_SAMPLE_VIRTUAL_PROPERTIES_DESC',
  TaskTemplateUsersConnectionArrayAggTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_TASK_ID_ASC',
  TaskTemplateUsersConnectionArrayAggTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_TASK_ID_DESC',
  TaskTemplateUsersConnectionArrayAggWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  TaskTemplateUsersConnectionArrayAggWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  TaskTemplateUsersConnectionAverageTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_TASK_ID_ASC',
  TaskTemplateUsersConnectionAverageTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_TASK_ID_DESC',
  TaskTemplateUsersConnectionAverageWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionAverageWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_AVERAGE_WORKER_ID_DESC',
  TaskTemplateUsersConnectionCountAsc = 'TASK_TEMPLATE_USERS_CONNECTION_COUNT_ASC',
  TaskTemplateUsersConnectionCountDesc = 'TASK_TEMPLATE_USERS_CONNECTION_COUNT_DESC',
  TaskTemplateUsersConnectionDistinctCountTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_TASK_ID_ASC',
  TaskTemplateUsersConnectionDistinctCountTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_TASK_ID_DESC',
  TaskTemplateUsersConnectionDistinctCountWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  TaskTemplateUsersConnectionDistinctCountWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  TaskTemplateUsersConnectionMaxTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_TASK_ID_ASC',
  TaskTemplateUsersConnectionMaxTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_TASK_ID_DESC',
  TaskTemplateUsersConnectionMaxWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_WORKER_ID_ASC',
  TaskTemplateUsersConnectionMaxWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MAX_WORKER_ID_DESC',
  TaskTemplateUsersConnectionMinTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_TASK_ID_ASC',
  TaskTemplateUsersConnectionMinTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_TASK_ID_DESC',
  TaskTemplateUsersConnectionMinWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_WORKER_ID_ASC',
  TaskTemplateUsersConnectionMinWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_MIN_WORKER_ID_DESC',
  TaskTemplateUsersConnectionStddevPopulationTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_TASK_ID_ASC',
  TaskTemplateUsersConnectionStddevPopulationTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_TASK_ID_DESC',
  TaskTemplateUsersConnectionStddevPopulationWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  TaskTemplateUsersConnectionStddevPopulationWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  TaskTemplateUsersConnectionStddevSampleTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_TASK_ID_ASC',
  TaskTemplateUsersConnectionStddevSampleTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_TASK_ID_DESC',
  TaskTemplateUsersConnectionStddevSampleWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionStddevSampleWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  TaskTemplateUsersConnectionSumTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_TASK_ID_ASC',
  TaskTemplateUsersConnectionSumTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_TASK_ID_DESC',
  TaskTemplateUsersConnectionSumWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_WORKER_ID_ASC',
  TaskTemplateUsersConnectionSumWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_SUM_WORKER_ID_DESC',
  TaskTemplateUsersConnectionVariancePopulationTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_TASK_ID_ASC',
  TaskTemplateUsersConnectionVariancePopulationTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_TASK_ID_DESC',
  TaskTemplateUsersConnectionVariancePopulationWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  TaskTemplateUsersConnectionVariancePopulationWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  TaskTemplateUsersConnectionVarianceSampleTaskIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_ASC',
  TaskTemplateUsersConnectionVarianceSampleTaskIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_TASK_ID_DESC',
  TaskTemplateUsersConnectionVarianceSampleWorkerIdAsc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  TaskTemplateUsersConnectionVarianceSampleWorkerIdDesc = 'TASK_TEMPLATE_USERS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC',
  TeamsConnectionArrayAggCompanyIdAsc = 'TEAMS_CONNECTION_ARRAY_AGG_COMPANY_ID_ASC',
  TeamsConnectionArrayAggCompanyIdDesc = 'TEAMS_CONNECTION_ARRAY_AGG_COMPANY_ID_DESC',
  TeamsConnectionArrayAggCreatedAtAsc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_AT_ASC',
  TeamsConnectionArrayAggCreatedAtDesc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_AT_DESC',
  TeamsConnectionArrayAggCreatedByAsc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_BY_ASC',
  TeamsConnectionArrayAggCreatedByDesc = 'TEAMS_CONNECTION_ARRAY_AGG_CREATED_BY_DESC',
  TeamsConnectionArrayAggDescriptionAsc = 'TEAMS_CONNECTION_ARRAY_AGG_DESCRIPTION_ASC',
  TeamsConnectionArrayAggDescriptionDesc = 'TEAMS_CONNECTION_ARRAY_AGG_DESCRIPTION_DESC',
  TeamsConnectionArrayAggIdAsc = 'TEAMS_CONNECTION_ARRAY_AGG_ID_ASC',
  TeamsConnectionArrayAggIdDesc = 'TEAMS_CONNECTION_ARRAY_AGG_ID_DESC',
  TeamsConnectionArrayAggImageUrlAsc = 'TEAMS_CONNECTION_ARRAY_AGG_IMAGE_URL_ASC',
  TeamsConnectionArrayAggImageUrlDesc = 'TEAMS_CONNECTION_ARRAY_AGG_IMAGE_URL_DESC',
  TeamsConnectionArrayAggNameAsc = 'TEAMS_CONNECTION_ARRAY_AGG_NAME_ASC',
  TeamsConnectionArrayAggNameDesc = 'TEAMS_CONNECTION_ARRAY_AGG_NAME_DESC',
  TeamsConnectionArrayAggPrivilegeAllAsc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_ASC',
  TeamsConnectionArrayAggPrivilegeAllDesc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_ALL_DESC',
  TeamsConnectionArrayAggPrivilegeOwnAsc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_ASC',
  TeamsConnectionArrayAggPrivilegeOwnDesc = 'TEAMS_CONNECTION_ARRAY_AGG_PRIVILEGE_OWN_DESC',
  TeamsConnectionArrayAggUpdatedAtAsc = 'TEAMS_CONNECTION_ARRAY_AGG_UPDATED_AT_ASC',
  TeamsConnectionArrayAggUpdatedAtDesc = 'TEAMS_CONNECTION_ARRAY_AGG_UPDATED_AT_DESC',
  TeamsConnectionAverageCompanyIdAsc = 'TEAMS_CONNECTION_AVERAGE_COMPANY_ID_ASC',
  TeamsConnectionAverageCompanyIdDesc = 'TEAMS_CONNECTION_AVERAGE_COMPANY_ID_DESC',
  TeamsConnectionAverageCreatedAtAsc = 'TEAMS_CONNECTION_AVERAGE_CREATED_AT_ASC',
  TeamsConnectionAverageCreatedAtDesc = 'TEAMS_CONNECTION_AVERAGE_CREATED_AT_DESC',
  TeamsConnectionAverageCreatedByAsc = 'TEAMS_CONNECTION_AVERAGE_CREATED_BY_ASC',
  TeamsConnectionAverageCreatedByDesc = 'TEAMS_CONNECTION_AVERAGE_CREATED_BY_DESC',
  TeamsConnectionAverageDescriptionAsc = 'TEAMS_CONNECTION_AVERAGE_DESCRIPTION_ASC',
  TeamsConnectionAverageDescriptionDesc = 'TEAMS_CONNECTION_AVERAGE_DESCRIPTION_DESC',
  TeamsConnectionAverageIdAsc = 'TEAMS_CONNECTION_AVERAGE_ID_ASC',
  TeamsConnectionAverageIdDesc = 'TEAMS_CONNECTION_AVERAGE_ID_DESC',
  TeamsConnectionAverageImageUrlAsc = 'TEAMS_CONNECTION_AVERAGE_IMAGE_URL_ASC',
  TeamsConnectionAverageImageUrlDesc = 'TEAMS_CONNECTION_AVERAGE_IMAGE_URL_DESC',
  TeamsConnectionAverageNameAsc = 'TEAMS_CONNECTION_AVERAGE_NAME_ASC',
  TeamsConnectionAverageNameDesc = 'TEAMS_CONNECTION_AVERAGE_NAME_DESC',
  TeamsConnectionAveragePrivilegeAllAsc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_ALL_ASC',
  TeamsConnectionAveragePrivilegeAllDesc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_ALL_DESC',
  TeamsConnectionAveragePrivilegeOwnAsc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_OWN_ASC',
  TeamsConnectionAveragePrivilegeOwnDesc = 'TEAMS_CONNECTION_AVERAGE_PRIVILEGE_OWN_DESC',
  TeamsConnectionAverageUpdatedAtAsc = 'TEAMS_CONNECTION_AVERAGE_UPDATED_AT_ASC',
  TeamsConnectionAverageUpdatedAtDesc = 'TEAMS_CONNECTION_AVERAGE_UPDATED_AT_DESC',
  TeamsConnectionCountAsc = 'TEAMS_CONNECTION_COUNT_ASC',
  TeamsConnectionCountDesc = 'TEAMS_CONNECTION_COUNT_DESC',
  TeamsConnectionDistinctCountCompanyIdAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_ASC',
  TeamsConnectionDistinctCountCompanyIdDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_COMPANY_ID_DESC',
  TeamsConnectionDistinctCountCreatedAtAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_ASC',
  TeamsConnectionDistinctCountCreatedAtDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_AT_DESC',
  TeamsConnectionDistinctCountCreatedByAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_ASC',
  TeamsConnectionDistinctCountCreatedByDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_CREATED_BY_DESC',
  TeamsConnectionDistinctCountDescriptionAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_ASC',
  TeamsConnectionDistinctCountDescriptionDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_DESCRIPTION_DESC',
  TeamsConnectionDistinctCountIdAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_ID_ASC',
  TeamsConnectionDistinctCountIdDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_ID_DESC',
  TeamsConnectionDistinctCountImageUrlAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_ASC',
  TeamsConnectionDistinctCountImageUrlDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_IMAGE_URL_DESC',
  TeamsConnectionDistinctCountNameAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_NAME_ASC',
  TeamsConnectionDistinctCountNameDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_NAME_DESC',
  TeamsConnectionDistinctCountPrivilegeAllAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_ASC',
  TeamsConnectionDistinctCountPrivilegeAllDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_ALL_DESC',
  TeamsConnectionDistinctCountPrivilegeOwnAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_ASC',
  TeamsConnectionDistinctCountPrivilegeOwnDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_PRIVILEGE_OWN_DESC',
  TeamsConnectionDistinctCountUpdatedAtAsc = 'TEAMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_ASC',
  TeamsConnectionDistinctCountUpdatedAtDesc = 'TEAMS_CONNECTION_DISTINCT_COUNT_UPDATED_AT_DESC',
  TeamsConnectionMaxCompanyIdAsc = 'TEAMS_CONNECTION_MAX_COMPANY_ID_ASC',
  TeamsConnectionMaxCompanyIdDesc = 'TEAMS_CONNECTION_MAX_COMPANY_ID_DESC',
  TeamsConnectionMaxCreatedAtAsc = 'TEAMS_CONNECTION_MAX_CREATED_AT_ASC',
  TeamsConnectionMaxCreatedAtDesc = 'TEAMS_CONNECTION_MAX_CREATED_AT_DESC',
  TeamsConnectionMaxCreatedByAsc = 'TEAMS_CONNECTION_MAX_CREATED_BY_ASC',
  TeamsConnectionMaxCreatedByDesc = 'TEAMS_CONNECTION_MAX_CREATED_BY_DESC',
  TeamsConnectionMaxDescriptionAsc = 'TEAMS_CONNECTION_MAX_DESCRIPTION_ASC',
  TeamsConnectionMaxDescriptionDesc = 'TEAMS_CONNECTION_MAX_DESCRIPTION_DESC',
  TeamsConnectionMaxIdAsc = 'TEAMS_CONNECTION_MAX_ID_ASC',
  TeamsConnectionMaxIdDesc = 'TEAMS_CONNECTION_MAX_ID_DESC',
  TeamsConnectionMaxImageUrlAsc = 'TEAMS_CONNECTION_MAX_IMAGE_URL_ASC',
  TeamsConnectionMaxImageUrlDesc = 'TEAMS_CONNECTION_MAX_IMAGE_URL_DESC',
  TeamsConnectionMaxNameAsc = 'TEAMS_CONNECTION_MAX_NAME_ASC',
  TeamsConnectionMaxNameDesc = 'TEAMS_CONNECTION_MAX_NAME_DESC',
  TeamsConnectionMaxPrivilegeAllAsc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_ALL_ASC',
  TeamsConnectionMaxPrivilegeAllDesc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_ALL_DESC',
  TeamsConnectionMaxPrivilegeOwnAsc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_OWN_ASC',
  TeamsConnectionMaxPrivilegeOwnDesc = 'TEAMS_CONNECTION_MAX_PRIVILEGE_OWN_DESC',
  TeamsConnectionMaxUpdatedAtAsc = 'TEAMS_CONNECTION_MAX_UPDATED_AT_ASC',
  TeamsConnectionMaxUpdatedAtDesc = 'TEAMS_CONNECTION_MAX_UPDATED_AT_DESC',
  TeamsConnectionMinCompanyIdAsc = 'TEAMS_CONNECTION_MIN_COMPANY_ID_ASC',
  TeamsConnectionMinCompanyIdDesc = 'TEAMS_CONNECTION_MIN_COMPANY_ID_DESC',
  TeamsConnectionMinCreatedAtAsc = 'TEAMS_CONNECTION_MIN_CREATED_AT_ASC',
  TeamsConnectionMinCreatedAtDesc = 'TEAMS_CONNECTION_MIN_CREATED_AT_DESC',
  TeamsConnectionMinCreatedByAsc = 'TEAMS_CONNECTION_MIN_CREATED_BY_ASC',
  TeamsConnectionMinCreatedByDesc = 'TEAMS_CONNECTION_MIN_CREATED_BY_DESC',
  TeamsConnectionMinDescriptionAsc = 'TEAMS_CONNECTION_MIN_DESCRIPTION_ASC',
  TeamsConnectionMinDescriptionDesc = 'TEAMS_CONNECTION_MIN_DESCRIPTION_DESC',
  TeamsConnectionMinIdAsc = 'TEAMS_CONNECTION_MIN_ID_ASC',
  TeamsConnectionMinIdDesc = 'TEAMS_CONNECTION_MIN_ID_DESC',
  TeamsConnectionMinImageUrlAsc = 'TEAMS_CONNECTION_MIN_IMAGE_URL_ASC',
  TeamsConnectionMinImageUrlDesc = 'TEAMS_CONNECTION_MIN_IMAGE_URL_DESC',
  TeamsConnectionMinNameAsc = 'TEAMS_CONNECTION_MIN_NAME_ASC',
  TeamsConnectionMinNameDesc = 'TEAMS_CONNECTION_MIN_NAME_DESC',
  TeamsConnectionMinPrivilegeAllAsc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_ALL_ASC',
  TeamsConnectionMinPrivilegeAllDesc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_ALL_DESC',
  TeamsConnectionMinPrivilegeOwnAsc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_OWN_ASC',
  TeamsConnectionMinPrivilegeOwnDesc = 'TEAMS_CONNECTION_MIN_PRIVILEGE_OWN_DESC',
  TeamsConnectionMinUpdatedAtAsc = 'TEAMS_CONNECTION_MIN_UPDATED_AT_ASC',
  TeamsConnectionMinUpdatedAtDesc = 'TEAMS_CONNECTION_MIN_UPDATED_AT_DESC',
  TeamsConnectionStddevPopulationCompanyIdAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_ASC',
  TeamsConnectionStddevPopulationCompanyIdDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_COMPANY_ID_DESC',
  TeamsConnectionStddevPopulationCreatedAtAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_ASC',
  TeamsConnectionStddevPopulationCreatedAtDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_AT_DESC',
  TeamsConnectionStddevPopulationCreatedByAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_ASC',
  TeamsConnectionStddevPopulationCreatedByDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_CREATED_BY_DESC',
  TeamsConnectionStddevPopulationDescriptionAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_ASC',
  TeamsConnectionStddevPopulationDescriptionDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_DESCRIPTION_DESC',
  TeamsConnectionStddevPopulationIdAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_ID_ASC',
  TeamsConnectionStddevPopulationIdDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_ID_DESC',
  TeamsConnectionStddevPopulationImageUrlAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_ASC',
  TeamsConnectionStddevPopulationImageUrlDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_IMAGE_URL_DESC',
  TeamsConnectionStddevPopulationNameAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_NAME_ASC',
  TeamsConnectionStddevPopulationNameDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_NAME_DESC',
  TeamsConnectionStddevPopulationPrivilegeAllAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_ASC',
  TeamsConnectionStddevPopulationPrivilegeAllDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_ALL_DESC',
  TeamsConnectionStddevPopulationPrivilegeOwnAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_ASC',
  TeamsConnectionStddevPopulationPrivilegeOwnDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_PRIVILEGE_OWN_DESC',
  TeamsConnectionStddevPopulationUpdatedAtAsc = 'TEAMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_ASC',
  TeamsConnectionStddevPopulationUpdatedAtDesc = 'TEAMS_CONNECTION_STDDEV_POPULATION_UPDATED_AT_DESC',
  TeamsConnectionStddevSampleCompanyIdAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_ASC',
  TeamsConnectionStddevSampleCompanyIdDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_COMPANY_ID_DESC',
  TeamsConnectionStddevSampleCreatedAtAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_ASC',
  TeamsConnectionStddevSampleCreatedAtDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_AT_DESC',
  TeamsConnectionStddevSampleCreatedByAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_ASC',
  TeamsConnectionStddevSampleCreatedByDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_CREATED_BY_DESC',
  TeamsConnectionStddevSampleDescriptionAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_ASC',
  TeamsConnectionStddevSampleDescriptionDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_DESCRIPTION_DESC',
  TeamsConnectionStddevSampleIdAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_ID_ASC',
  TeamsConnectionStddevSampleIdDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_ID_DESC',
  TeamsConnectionStddevSampleImageUrlAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_ASC',
  TeamsConnectionStddevSampleImageUrlDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_IMAGE_URL_DESC',
  TeamsConnectionStddevSampleNameAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_NAME_ASC',
  TeamsConnectionStddevSampleNameDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_NAME_DESC',
  TeamsConnectionStddevSamplePrivilegeAllAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_ASC',
  TeamsConnectionStddevSamplePrivilegeAllDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_ALL_DESC',
  TeamsConnectionStddevSamplePrivilegeOwnAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_ASC',
  TeamsConnectionStddevSamplePrivilegeOwnDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_PRIVILEGE_OWN_DESC',
  TeamsConnectionStddevSampleUpdatedAtAsc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TeamsConnectionStddevSampleUpdatedAtDesc = 'TEAMS_CONNECTION_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TeamsConnectionSumCompanyIdAsc = 'TEAMS_CONNECTION_SUM_COMPANY_ID_ASC',
  TeamsConnectionSumCompanyIdDesc = 'TEAMS_CONNECTION_SUM_COMPANY_ID_DESC',
  TeamsConnectionSumCreatedAtAsc = 'TEAMS_CONNECTION_SUM_CREATED_AT_ASC',
  TeamsConnectionSumCreatedAtDesc = 'TEAMS_CONNECTION_SUM_CREATED_AT_DESC',
  TeamsConnectionSumCreatedByAsc = 'TEAMS_CONNECTION_SUM_CREATED_BY_ASC',
  TeamsConnectionSumCreatedByDesc = 'TEAMS_CONNECTION_SUM_CREATED_BY_DESC',
  TeamsConnectionSumDescriptionAsc = 'TEAMS_CONNECTION_SUM_DESCRIPTION_ASC',
  TeamsConnectionSumDescriptionDesc = 'TEAMS_CONNECTION_SUM_DESCRIPTION_DESC',
  TeamsConnectionSumIdAsc = 'TEAMS_CONNECTION_SUM_ID_ASC',
  TeamsConnectionSumIdDesc = 'TEAMS_CONNECTION_SUM_ID_DESC',
  TeamsConnectionSumImageUrlAsc = 'TEAMS_CONNECTION_SUM_IMAGE_URL_ASC',
  TeamsConnectionSumImageUrlDesc = 'TEAMS_CONNECTION_SUM_IMAGE_URL_DESC',
  TeamsConnectionSumNameAsc = 'TEAMS_CONNECTION_SUM_NAME_ASC',
  TeamsConnectionSumNameDesc = 'TEAMS_CONNECTION_SUM_NAME_DESC',
  TeamsConnectionSumPrivilegeAllAsc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_ALL_ASC',
  TeamsConnectionSumPrivilegeAllDesc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_ALL_DESC',
  TeamsConnectionSumPrivilegeOwnAsc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_OWN_ASC',
  TeamsConnectionSumPrivilegeOwnDesc = 'TEAMS_CONNECTION_SUM_PRIVILEGE_OWN_DESC',
  TeamsConnectionSumUpdatedAtAsc = 'TEAMS_CONNECTION_SUM_UPDATED_AT_ASC',
  TeamsConnectionSumUpdatedAtDesc = 'TEAMS_CONNECTION_SUM_UPDATED_AT_DESC',
  TeamsConnectionVariancePopulationCompanyIdAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_ASC',
  TeamsConnectionVariancePopulationCompanyIdDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_COMPANY_ID_DESC',
  TeamsConnectionVariancePopulationCreatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_ASC',
  TeamsConnectionVariancePopulationCreatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_AT_DESC',
  TeamsConnectionVariancePopulationCreatedByAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_ASC',
  TeamsConnectionVariancePopulationCreatedByDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_CREATED_BY_DESC',
  TeamsConnectionVariancePopulationDescriptionAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_ASC',
  TeamsConnectionVariancePopulationDescriptionDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_DESCRIPTION_DESC',
  TeamsConnectionVariancePopulationIdAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_ID_ASC',
  TeamsConnectionVariancePopulationIdDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_ID_DESC',
  TeamsConnectionVariancePopulationImageUrlAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_ASC',
  TeamsConnectionVariancePopulationImageUrlDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_IMAGE_URL_DESC',
  TeamsConnectionVariancePopulationNameAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_NAME_ASC',
  TeamsConnectionVariancePopulationNameDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_NAME_DESC',
  TeamsConnectionVariancePopulationPrivilegeAllAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_ASC',
  TeamsConnectionVariancePopulationPrivilegeAllDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_ALL_DESC',
  TeamsConnectionVariancePopulationPrivilegeOwnAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_ASC',
  TeamsConnectionVariancePopulationPrivilegeOwnDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_PRIVILEGE_OWN_DESC',
  TeamsConnectionVariancePopulationUpdatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TeamsConnectionVariancePopulationUpdatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TeamsConnectionVarianceSampleCompanyIdAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  TeamsConnectionVarianceSampleCompanyIdDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  TeamsConnectionVarianceSampleCreatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TeamsConnectionVarianceSampleCreatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TeamsConnectionVarianceSampleCreatedByAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_ASC',
  TeamsConnectionVarianceSampleCreatedByDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_CREATED_BY_DESC',
  TeamsConnectionVarianceSampleDescriptionAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  TeamsConnectionVarianceSampleDescriptionDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  TeamsConnectionVarianceSampleIdAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_ID_ASC',
  TeamsConnectionVarianceSampleIdDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_ID_DESC',
  TeamsConnectionVarianceSampleImageUrlAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  TeamsConnectionVarianceSampleImageUrlDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  TeamsConnectionVarianceSampleNameAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_NAME_ASC',
  TeamsConnectionVarianceSampleNameDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_NAME_DESC',
  TeamsConnectionVarianceSamplePrivilegeAllAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_ASC',
  TeamsConnectionVarianceSamplePrivilegeAllDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_ALL_DESC',
  TeamsConnectionVarianceSamplePrivilegeOwnAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_ASC',
  TeamsConnectionVarianceSamplePrivilegeOwnDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_PRIVILEGE_OWN_DESC',
  TeamsConnectionVarianceSampleUpdatedAtAsc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TeamsConnectionVarianceSampleUpdatedAtDesc = 'TEAMS_CONNECTION_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UserTeamsConnectionArrayAggTeamIdAsc = 'USER_TEAMS_CONNECTION_ARRAY_AGG_TEAM_ID_ASC',
  UserTeamsConnectionArrayAggTeamIdDesc = 'USER_TEAMS_CONNECTION_ARRAY_AGG_TEAM_ID_DESC',
  UserTeamsConnectionArrayAggWorkerIdAsc = 'USER_TEAMS_CONNECTION_ARRAY_AGG_WORKER_ID_ASC',
  UserTeamsConnectionArrayAggWorkerIdDesc = 'USER_TEAMS_CONNECTION_ARRAY_AGG_WORKER_ID_DESC',
  UserTeamsConnectionAverageTeamIdAsc = 'USER_TEAMS_CONNECTION_AVERAGE_TEAM_ID_ASC',
  UserTeamsConnectionAverageTeamIdDesc = 'USER_TEAMS_CONNECTION_AVERAGE_TEAM_ID_DESC',
  UserTeamsConnectionAverageWorkerIdAsc = 'USER_TEAMS_CONNECTION_AVERAGE_WORKER_ID_ASC',
  UserTeamsConnectionAverageWorkerIdDesc = 'USER_TEAMS_CONNECTION_AVERAGE_WORKER_ID_DESC',
  UserTeamsConnectionCountAsc = 'USER_TEAMS_CONNECTION_COUNT_ASC',
  UserTeamsConnectionCountDesc = 'USER_TEAMS_CONNECTION_COUNT_DESC',
  UserTeamsConnectionDistinctCountTeamIdAsc = 'USER_TEAMS_CONNECTION_DISTINCT_COUNT_TEAM_ID_ASC',
  UserTeamsConnectionDistinctCountTeamIdDesc = 'USER_TEAMS_CONNECTION_DISTINCT_COUNT_TEAM_ID_DESC',
  UserTeamsConnectionDistinctCountWorkerIdAsc = 'USER_TEAMS_CONNECTION_DISTINCT_COUNT_WORKER_ID_ASC',
  UserTeamsConnectionDistinctCountWorkerIdDesc = 'USER_TEAMS_CONNECTION_DISTINCT_COUNT_WORKER_ID_DESC',
  UserTeamsConnectionMaxTeamIdAsc = 'USER_TEAMS_CONNECTION_MAX_TEAM_ID_ASC',
  UserTeamsConnectionMaxTeamIdDesc = 'USER_TEAMS_CONNECTION_MAX_TEAM_ID_DESC',
  UserTeamsConnectionMaxWorkerIdAsc = 'USER_TEAMS_CONNECTION_MAX_WORKER_ID_ASC',
  UserTeamsConnectionMaxWorkerIdDesc = 'USER_TEAMS_CONNECTION_MAX_WORKER_ID_DESC',
  UserTeamsConnectionMinTeamIdAsc = 'USER_TEAMS_CONNECTION_MIN_TEAM_ID_ASC',
  UserTeamsConnectionMinTeamIdDesc = 'USER_TEAMS_CONNECTION_MIN_TEAM_ID_DESC',
  UserTeamsConnectionMinWorkerIdAsc = 'USER_TEAMS_CONNECTION_MIN_WORKER_ID_ASC',
  UserTeamsConnectionMinWorkerIdDesc = 'USER_TEAMS_CONNECTION_MIN_WORKER_ID_DESC',
  UserTeamsConnectionStddevPopulationTeamIdAsc = 'USER_TEAMS_CONNECTION_STDDEV_POPULATION_TEAM_ID_ASC',
  UserTeamsConnectionStddevPopulationTeamIdDesc = 'USER_TEAMS_CONNECTION_STDDEV_POPULATION_TEAM_ID_DESC',
  UserTeamsConnectionStddevPopulationWorkerIdAsc = 'USER_TEAMS_CONNECTION_STDDEV_POPULATION_WORKER_ID_ASC',
  UserTeamsConnectionStddevPopulationWorkerIdDesc = 'USER_TEAMS_CONNECTION_STDDEV_POPULATION_WORKER_ID_DESC',
  UserTeamsConnectionStddevSampleTeamIdAsc = 'USER_TEAMS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_ASC',
  UserTeamsConnectionStddevSampleTeamIdDesc = 'USER_TEAMS_CONNECTION_STDDEV_SAMPLE_TEAM_ID_DESC',
  UserTeamsConnectionStddevSampleWorkerIdAsc = 'USER_TEAMS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_ASC',
  UserTeamsConnectionStddevSampleWorkerIdDesc = 'USER_TEAMS_CONNECTION_STDDEV_SAMPLE_WORKER_ID_DESC',
  UserTeamsConnectionSumTeamIdAsc = 'USER_TEAMS_CONNECTION_SUM_TEAM_ID_ASC',
  UserTeamsConnectionSumTeamIdDesc = 'USER_TEAMS_CONNECTION_SUM_TEAM_ID_DESC',
  UserTeamsConnectionSumWorkerIdAsc = 'USER_TEAMS_CONNECTION_SUM_WORKER_ID_ASC',
  UserTeamsConnectionSumWorkerIdDesc = 'USER_TEAMS_CONNECTION_SUM_WORKER_ID_DESC',
  UserTeamsConnectionVariancePopulationTeamIdAsc = 'USER_TEAMS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_ASC',
  UserTeamsConnectionVariancePopulationTeamIdDesc = 'USER_TEAMS_CONNECTION_VARIANCE_POPULATION_TEAM_ID_DESC',
  UserTeamsConnectionVariancePopulationWorkerIdAsc = 'USER_TEAMS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_ASC',
  UserTeamsConnectionVariancePopulationWorkerIdDesc = 'USER_TEAMS_CONNECTION_VARIANCE_POPULATION_WORKER_ID_DESC',
  UserTeamsConnectionVarianceSampleTeamIdAsc = 'USER_TEAMS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_ASC',
  UserTeamsConnectionVarianceSampleTeamIdDesc = 'USER_TEAMS_CONNECTION_VARIANCE_SAMPLE_TEAM_ID_DESC',
  UserTeamsConnectionVarianceSampleWorkerIdAsc = 'USER_TEAMS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_ASC',
  UserTeamsConnectionVarianceSampleWorkerIdDesc = 'USER_TEAMS_CONNECTION_VARIANCE_SAMPLE_WORKER_ID_DESC'
}
